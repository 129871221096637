<template>
    <span class="be-pp-delta">
        <span v-if="delta > 0" class="be-pp-delta__up"></span>
        <span v-else-if="delta < 0" class="be-pp-delta__down"></span>
        &nbsp;
        <deq-percentage :percent="delta"
                        :precision="1"
                        is-proportion
                        is-percentage-points/>
    </span>
</template>

<script>
import DeqPercentage from "@/components/formatters/DeqPercentage";

export default {
    components: {DeqPercentage},
    props: {
        delta: {
            type: Number,
            required: true
        }
    }
}
</script>


<style scoped lang="sass">

.be-pp-delta
    white-space: nowrap

.be-pp-delta__down
    border-top: 0.75em solid var(--be-colour-net-sentiment-decrease)
    border-left: 0.4em solid transparent
    border-right: 0.4em solid transparent
    display: inline-block

.be-pp-delta__up
    border-bottom: 0.75em solid var(--be-colour-net-sentiment-increase)
    border-left: 0.4em solid transparent
    border-right: 0.4em solid transparent
    display: inline-block

</style>