import moment from "moment";
import {isString} from "@/app/utils/StringUtils";

export const DAY_MS = 1000 * 60 * 60 * 24;
export const EXPIRE_WARN_WINDOW_MS = 14 * DAY_MS;


/**
 * Returns True if the supplied expire date is before the current date, or if the expire date
 *     is close to the current date. False if otherwise, or if the dateString is invalid.
 * @param {Profile|String} profileOrDate
 */
export function hasExpiredOrExpiresSoon(profileOrDate) {
    const dateString = isString(profileOrDate) ? profileOrDate : profileOrDate?.tokenExpire;
    var hasExpired = false;
    if (!dateString) return hasExpired;
    try {
        var expireDate = new moment(dateString);
        if (expireDate.isValid()) {
            var expireMs = expireDate.valueOf(),
                nowMs = new Date().getTime();
            hasExpired = expireMs < (nowMs + EXPIRE_WARN_WINDOW_MS);
        }
    } catch (e) {
        console.error("Could not check whether expire date has been reached:", e.message);
    }
    return hasExpired;
}

/**
 * Returns true iff the token has expired.
 */
export function isProfileExpired(profile) {
    if (!profile) throw new Error("No profile provided");
    let e = profile.refreshTokenExpire || profile.tokenExpire;
    return e ? new moment(e).isBefore(moment.now()) : false;
}
