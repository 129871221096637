<template>
    <section class="be-mention-panel">
        <sidebar-component>
            <sidebar-brands :brands="rootBrands" v-model="activeBrandId" :disabled="disabled"/>
        </sidebar-component>


        <div class="be-mention-panel__content">
            <div class="row-fluid head title">
                <h1>Mentions</h1> {{useBrowserCache}}
            </div>

            <mention-panel-toolbar/>

            <mention-item v-for="mention in mentions"
                          :key="mention.id"
                          :mention="mention"/>

        </div>
    </section>
</template>


<script>

import SidebarBrands from "@/components/sidebar/SidebarBrands";
import SidebarComponent from "@/components/sidebar/SidebarComponent";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState, mapActions, mapMutations, mapGetters} from "vuex";
import MentionPanelToolbar from "@/app/toplevel/mentions/components/MentionPanelToolbar";
import {appendFiltersReadably, latestDate, parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import {MentionQLexer} from "@/mentionq/mentionq";
import {getMentions} from "@/data/Grouse";
import MentionItem from "@/components/MentionItem";
import moment from "moment";
import {notifyWithText} from "@/app/framework/notifications/Notifications";

export default {
    store: VuexStore,
    components: {MentionItem, MentionPanelToolbar, SidebarComponent, SidebarBrands},

    props: {
        initialFilter: String
    },

    data() {
        return {
            loadingMentions: false,

            activeBrandId: null,
            mentionFetchPromise: null,    // The current promise fetching mentions. Useful for cancelling calls.

            mentions: []
        }
    },

    computed: {
        disabled() {
            return false; // What things disable this.
        },

        useBrowserCache() {
            const latest = latestDate(this.filter);
            return latest.isBefore(moment());
        },

        ...mapState({
            filter: state => state.mentionPanel.filter,
            rootBrands: state => state.rootBrands
        }),
        ...mapGetters('mentionPanel', {
            filterBrandIds: 'brands'
        })
    },

    watch: {
        filterBrandIds() {
            if (this.filterBrandIds?.length) {
                this.activeBrandId = this.filterBrandIds[0];
            } else {
                this.activeBrandId = null;
            }
        },

        activeBrandId() {
            // Ensure that the filter is updated with the new brand, whenever the brand changes.
            const filter = this.removeBrandFromFilter(this.filter);
            this.setFilter(appendFiltersReadably(filter, `brand isorchildof ${this.activeBrandId}`));
        },

        filter() {
            notifyWithText("Undo", () => this.undoLastFilter(), "<i class='symbol-mentions'></i>");
            this.fetchMentions();
        }
    },

    async created() {
        await this.initialisePanel();
        if (this.initialFilter) {
            this.setFilter(this.initialFilter);
        }
    },

    async mounted() {
        await this.refreshBrands();
    },

    beforeDestroy() {
        if (this.mentionFetchPromise?.cancel) {
            this.mentionFetchPromise.cancel();
        }
    },

    methods: {
        ...mapActions(['refreshBrands']),
        ...mapActions('mentionPanel', {
            initialisePanel: 'initialise',
            setFilter: 'setFilter',
            undoLastFilter: 'undoLastFilter'
        }),

        removeBrandFromFilter(filter) {
            filter = removeNodes(parseFilterString(filter), node => node.operandType === MentionQLexer.BRAND);
            if (filter) return filter.toString();
            return "";
        },

        async fetchMentions() {
            try {
                if (this.mentionFetchPromise?.cancel) {
                    this.mentionFetchPromise.cancel();
                    this.mentionFetchPromise = null;
                }

                this.loadingMentions = true;

                // todo can set browser cache for anything that isn't now now.
                this.mentionFetchPromise = getMentions(this.filter, null, null, null, !this.useBrowserCache) //, orderBy, limit, multiBrand)
                this.mentions = await this.mentionFetchPromise;

                this.mentionFetchPromise = null;
            } finally {
                this.loadingMentions = false;
            }
        }
    }
};
</script>

<style scoped lang="sass">

.be-mention-panel__content
    margin-left: var(--sidebar-size)

</style>