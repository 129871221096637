import _ from 'underscore';

/**
 * Extends the default Backbone view class to have a cache object. This is taken from options on init or created.
 * This is intended to allow views to share data fetched from the server. Also adds an isValid method that checks
 * the model and all of the sub-views of the view (for views that extend Backbone.Marionette.Layout).
 */
Beef.module("View", function() {

    var superConstructor = Backbone.View.prototype.constructor;

    Object.assign(Backbone.View.prototype, {

        constructor: function(options) {
            superConstructor.call(this, options);
            this.cache = options && options.cache || {};
        },

        isValid: function() {
            if (this.model && !this.model.isValid()) return false;
            if (this.regionManager) { // recursively check all sub-views as well
                var regions = Object.values(this.regionManager._regions);
                for (let i = 0; i < regions.length; i++) {
                    var v = regions[i].currentView;
                    if (v && !v.isValid()) return false;
                }
            }
            return true;
        }

    });
});
