<template>
    <div v-if="enabled" class="if-feature">
        <slot/>
    </div>
</template>


<script>
import {features} from "@/app/Features";

export default {
    props: {
        feature: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            enabled: false
        }
    },

    created() {
        this.checkIfEnabled();
    },

    watch: {
        feature() {
            this.checkIfEnabled();
        }
    },

    methods: {
        checkIfEnabled() {
            if (!features[this.feature]) {
                throw new Error(`Unrecognised feature flag [${this.feature}]`); // Fail fast
            }

            try {
                this.enabled = features[this.feature]();
            } catch(e) {
                this.enabled = false;
                throw e;
            }
        }
    }
}
</script>

<style scoped>
.if-feature {
    display: contents;
}
</style>