import { deprecatedTagsStore } from '@/store/deprecated/Stores';
import VuexStore from "@/store/vuex/VuexStore";
import _ from 'underscore';
import {isString} from "@/app/utils/StringUtils";

/**
 * Invoke cb with brand information from the cache on view or the server.
 * {tree: .. brand tree, map: .. brand id -> brand, maxDepth: depth of tree, count: .. number of brands in total}.
 * Each brand in tree has a parent link.
 *
 * @param viewOrCode A view object or an account code. The view object should have accountCode set on its model.
 * @param cb A callback that will be called with the map of brand information.
 * @param cache An optional cache object.
 * @deprecated use the brand store.
 */
export function fetchCachedBrands(viewOrCode, cb, cache) {
    return fetch(viewOrCode, cb, cache, "accounts/${code}/brands/tree", "brands", processBrands);
}

/**
 * Invoke cb with brand information from the cache on view or the server.
 *
 * @param viewOrCode A view object or an account code. The view object should have accountCode set on its model.
 * @param id The id of the brand that should be fetched.
 * @param cb A callback that will be called with the map of brand information.
 * @param cache An optional cache object.
 * @deprecated Needs to be rewritten
 */
export function deprecatedFetchBrand(viewOrCode, id, cb, cache) {
    fetch(viewOrCode, cb, cache, "accounts/${code}/brands/" + id, "brand-" + id, noopProcess);
}


/**
 * Invoke cb with profile information from the cache on view or the server.
 * @param viewOrCode A view object or an account code. The view object should have accountCode set on its model.
 * @param cb A callback that will be called with a map of profile IDs to profiles
 * @param cache An optional cache object.
 * @deprecated Needs to be rewritten using the store pattern
 */
export function deprecatedFetchProfiles(viewOrCode, cb, cache) {
    fetch(viewOrCode, cb, cache, "accounts/${code}/online-profiles", "profiles", processIdArray);
}

/**
 * Invoke cb with a map of tag id -> tag name from the cache on view or the server.
 *
 * @param viewOrCode A view object or an account code. The view object should have accountCode set on its model.
 * @param cb A callback that will be called with the map of brand information.
 * @param cache An optional cache object.
 * @deprecated use the tag store
 */
export function deprecatedFetchTags(viewOrCode, cb, cache) {
    if (!cache) cache = viewOrCode.cache = viewOrCode.cache || {};
    deprecatedTagsStore.refresh(true).then(() => {
        cache["tags"] = deprecatedTagsStore.byId
        cb(deprecatedTagsStore.byId, true)
    })
}

/**
 * Invoke cb with a map of phrase id -> phrase query from the cache on view or the server.
 *
 * @param viewOrCode A view object or an account code. The view object should have accountCode set on its model.
 * @param cb A callback that will be called with the map of brand information.
 * @param cache An optional cache object.
 * @deprecated Needs a new phrase store
 */
export function deprecatedFetchPhrases(viewOrCode, cb, cache) {
    fetch(viewOrCode, cb, cache, "accounts/${code}/phrases/map", "phrases", noopProcess);
}

/**
 * Get id and name pairs for each engage team for the current account from the server.
 *
 * @param view A view object
 * @param cb A callback that will be called with the map of brand information
 * @param cache An optional cache object.
 * @deprecated Needs to use the store pattern
 */
export function deprecatedFetchEngageTeams(view, cb, cache) {
    fetch(view, cb, cache, "/api/engage/teams/map?accountCode=${code}", "engageTeams", noopProcess, Beef.Sync.beefGET);
}

/**
 * Get user and id pairs from the server.
 *
 * @param viewOrCode A view object or an account code. The view object should have accountCode set on its model.
 * @param cb A callback that will be called with the map of brand information.
 * @param cache An optional cache object.
 * @deprecated use the user store
 */
export function deprecatedFetchUsers(viewOrCode, cb, cache) {
    fetch(viewOrCode, cb, cache, "accounts/${code}/users/map", "users", noopProcess);
}





export function prefetchCache() {
    return fetchCachedBrands(VuexStore.state.account.code, function() {}, {});
}


/** A global cache for our data. */
var globalCache = new Map();
var lastUpdated = new Map();

var processBrands = function(tree, accountCode) {
    var map = {};
    var maxDepth = 1;
    var count = 0;
    var addToMap = function(b, parent, depth) {
        count++;
        if (depth > maxDepth) maxDepth = depth;
        map[b.id] = b;
        b.parent = parent;
        b.depth = depth;
        b.accountCode = accountCode;
        if (b.children) for (var i = 0; i < b.children.length; i++) addToMap(b.children[i], b, depth + 1);
    };
    for (var i = 0; i < tree.length; i++) addToMap(tree[i], null, 1);

    return {tree: tree, map: map, maxDepth: maxDepth, count: count};
};


var processIdArray = function(data) {
    var map = {};
    _(data).each(function(d) {
        map[d.id] = d;
    });
    return {list: data, map: map};
};

var processTags = function(data) {
    var map = {};
    _(data).each(function(tag) {
        map[tag.id] = tag;
    });
    _(data).each(function(tag) {
        if (tag.children && tag.children.length) {
            _(tag.children).each(function(id) {
                if (map[id]) {
                    map[id]._parent = tag;
                }
            })
        }
    });
    return map;
};

var noopProcess = function(data) { return data };

// maps endPoint to an array of {cache:.., cb:..} objects waiting for the call to to finish
var busy = {};

var fetch = function(viewOrCode, cb, cache, endPoint, fieldName, processFn, getFn) {
    if (!cache) cache = viewOrCode.cache = viewOrCode.cache || {};
    if (cache[fieldName]) {
        cb(cache[fieldName], false);
        return;
    }

    var ac = viewOrCode ? isString(viewOrCode) ? viewOrCode : viewOrCode.model.getAncestorProperty('accountCode') : null;
    if (endPoint.indexOf("${code}") >= 0) {
        if (!ac) {
            ac = VuexStore.state.account.code;
            if (!ac) {
                cb({});
                return;
            }
        }
        endPoint = endPoint.replace("${code}", ac);
    }

    var refreshOnly = false;
    if (globalCache.get(ac) && globalCache.get(ac)[fieldName]) {
        var last = lastUpdated.get(ac + ":" + fieldName);
        refreshOnly = Date.now() - last > 5 * 60 * 1000;
        Object.assign(cache, globalCache.get(ac));
        if (cache[fieldName]) {
            cb(cache[fieldName], false);
            if (!refreshOnly) return;
        }
    }

    if (refreshOnly) console.debug("Refreshing cache for " + fieldName);


    var pending = {cache: cache, cb: cb};
    var list = busy[endPoint];
    if (list) { // ajax call already in progress
        if (!refreshOnly) list.push(pending);
        return;
    }
    busy[endPoint] = refreshOnly ? [] : [pending];

    return (getFn || Beef.Sync.mashGET)(endPoint, null, function(data){
        var entry = processFn(data, ac);
        var c = globalCache.get(ac) || {};
        c[fieldName] = entry;
        globalCache.set(ac, c);
        lastUpdated.set(ac + ":" + fieldName, Date.now());
        try {
            var list = busy[endPoint];
            for (var i = 0; i < list.length; i++) {
                var pending = list[i];
                pending.cb(pending.cache[fieldName] = entry, true);
            }
        } finally {
            busy[endPoint] = null;
        }
    }, function() { // error callback
        busy[endPoint] = null;
    });
};


