var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"busy\">\n        <span class=\"spinner\"></span>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authors") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":91,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <table class=\"author-table table table-condensed table-bordered table-hover\">\n            <thead>\n            <tr>\n                <td class=\"author\" title=\"Author name and screen name\">Author</td>\n                <td class=\"clickable\" data-order-by=\"mentionCount\" title=\"Number of mentions by the author\">Mentions</td>\n                <td class=\"clickable\" data-order-by=\"totalEngagement\" title=\"Sum of engagement for all mentions\">Engagement</td>\n                <td class=\"clickable\" data-order-by=\"maxFollowerCount\" title=\"The number authors following the author\">Followers</td>\n                <td class=\"clickable\" data-order-by=\"maxFollowingCount\" title=\"The number of authors that the author follows\">Following</td>\n                <td class=\"clickable\" data-order-by=\"maxStatusCount\" title=\"Number of mentions by the author of the lifetime of the account\">Statuses</td>\n                <td class=\"clickable\" data-order-by=\"totalOTS\" title=\"Number of times that mentions from the author may have been seen\">OTS</td>\n                <td class=\"clickable\" data-order-by=\"totalPositive\" title=\"Number of positive mentions\">Positive</td>\n                <td class=\"clickable\" data-order-by=\"totalNegative\" title=\"Number of negative mentions\">Negative</td>\n                <td class=\"clickable\" data-order-by=\"totalSentiment\" title=\"Number of postive mentions less negative mentions\">Net</td>\n            </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authors") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":78,"column":21}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr data-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n                    <td class=\"author name-and-avatar "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"mostRecentMention") : depth0)) != null ? lookupProperty(stack1,"socialNetwork") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"mostRecentMention") : depth0),{"name":"with","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":41,"column":33}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"mentionCount") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":46,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td class=\"engagement\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"totalEngagement") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":49,"column":95}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"maxFollowerCount") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":52,"column":88}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"maxFollowingCount") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":90}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"maxStatusCount") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":58,"column":84}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td class=\"ots\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"totalOTS") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":24},"end":{"line":61,"column":72}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td class=\"sentiment\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"totalPositive") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":64,"column":94}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td class=\"sentiment\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"totalNegative") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":67,"column":93}}})) != null ? stack1 : "")
    + "\n                    </td>\n                    <td class=\"sentiment\">\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"totalSentiment") : depth0),">",0,{"name":"ifcond","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":72,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"totalSentiment") : depth0),"<",0,{"name":"ifcond","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":24},"end":{"line":75,"column":35}}})) != null ? stack1 : "")
    + "                    </td>\n                </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../helpers/withAuthorV4.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"withAuthorV4","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":40,"column":45}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <img class=\"avatar\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\" >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authorProfileLink") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":39,"column":35}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"authorProfileLink") : depth0), depth0))
    + "\" target=\"_blank\">\n                                    <div class=\"name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n                                    <div class=\"screen-name\">&nbsp;&nbsp;"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "</div>\n                                </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span>\n                                    <div class=\"name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n                                    <div class=\"screen-name\">&nbsp;&nbsp;"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "</div>\n                                </span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"mentions\" title=\"Click to view mentions\" href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"mentionsLink") : depth0), depth0))
    + "\">"
    + alias1(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"mentionCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":45,"column":103},"end":{"line":45,"column":132}}}))
    + "</a>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalEngagement") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":49,"column":47},"end":{"line":49,"column":79}}}));
},"16":function(container,depth0,helpers,partials,data) {
    return "0";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"maxFollowerCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":52,"column":48},"end":{"line":52,"column":81}}}));
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"maxFollowingCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":55,"column":49},"end":{"line":55,"column":83}}}));
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"maxStatusCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":58,"column":46},"end":{"line":58,"column":77}}}));
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalOTS") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":61,"column":40},"end":{"line":61,"column":65}}}));
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div class=\"good\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"totalPositive") : depth0), depth0))
    + "</div>";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div class=\"bad\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"totalNegative") : depth0), depth0))
    + "</div>";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"good\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"totalSentiment") : depth0), depth0))
    + " <i class=\"icon-thumbs-up\"></i></div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"bad\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"totalSentiment") : depth0), depth0))
    + " <i class=\"icon-thumbs-down\"></i></div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"errorMsg") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":90,"column":15}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"error-msg text-warning\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"errorMsg") : depth0), depth0))
    + "</div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"chooseBrand") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":89,"column":19}}})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"choose-brand\">Please choose a brand from the sidebar on the left.</div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"no-authors\">No authors found</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"busy") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":92,"column":7}}})) != null ? stack1 : "")
    + "\n\n";
},"useData":true});