/**
 * Logs an update of an individual mention to chervil.
 */
export function logMentionUpdate(account, mentionId, mentionUri, apiUpdate) {
    return fetch("/api/accounts/" + account + "/log", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: mentionId,
            uri: mentionUri,
            apiUpdate: apiUpdate
        })
    }).catch(function(error) {
        console.warn("The was a problem logging this mention change to chervil", error);
    });
}


export function logBulkMentionUpdate(account, filter, apiUpdate) {
    return fetch("/api/accounts/" + account + "/log", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            filter: filter,
            apiUpdate: apiUpdate
        })
    }).catch(function(error) {
        console.warn("The was a problem logging a bulk mention update to chervil", error);
    });
}
