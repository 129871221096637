<template>
    <span class="tag" :data-value="tag.id">
        <span class="tag-text" :class="{'no-close': noClose}">
            <span class="tag-picker-name">{{tag.name}}</span>
        </span>
        <button v-if="!noClose" class="close" tabindex="-1" title="Remove this item" @click="$emit('close')">×</button>
    </span>
</template>

<script>
    export default {
        name: "OldTag",

        props: {
            tag: Object,
            noClose: Boolean
        }
    }
</script>

<style scoped>
    button:focus {
        outline: none;
    }

    .no-close {
        padding-right: 5px !important;
    }
</style>