import DashboardPanel from "./DashboardPanel";
import {getLastSeenDashboard} from "@/app/toplevel/dashboards/DashboardUtilities";
import {setTitle} from "@/app/Beef";

Beef.module("DashboardList").addInitializer(function(startupOptions) {
    function routeCallback(code, id) {
        if (!id) id = getLastSeenDashboard();

        if (!Beef.isAccountCode(code)) {
            Beef.content.show(Beef.view404);
            return
        }

        setTitle("Reports");
        Beef.content.show(new Beef.VuejsView.View({component: DashboardPanel, props: {id: id}}));
    }

    Beef.route(":code/dashboards", "dashboards", routeCallback);
    Beef.route(":code/dashboards/:id", "dashboards", routeCallback);
});