Beef.module("PhraseTune").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/setup/brands/PhraseTune.handlebars"),

        attributes: { class: "phrase-tune" },

        events: {
            "change .words input": "checkboxChanged"
        },

        modelEvents: {
            "change": "render"
        },

        templateHelpers: function() {
            // replace the words array with an array of objects indicating which words are already selected
            var words = this.model.get("words");
            if (words) {
                var a = this.getSelectedTerms();
                var map = {};
                for (var i = 0; i < a.length; i++) map[a[i]] = true;
                var _words = [];
                for (i = 0; i < words.length; i++) {
                    var w = words[i];
                    _words.push({word: w, selected: map[w]});
                }
                words = _words;
            }
            return {
                rootBrand: this.options.rootBrand,
                words: words
            }
        },

        checkboxChanged: function(ev) {
            var $t = $(ev.target);
            $t.closest("label").toggleClass("selected", $t.is(":checked"));
        },

        getSelectedTerms: function() {
            var $sel = this.$el.find(".words input:checked");
            var ans = [];
            for (var i = 0; i < $sel.length; i++) ans.push($($sel[i]).attr("data-word"));
            return ans;
        }
    });

});
