<template>
    <div>
        <h3>What are these mentions about?</h3>
        <with-summary :filter="filter"
                      :state="state"
                      v-slot="{loading: summaryLoading, text}"
                      @summary="setSummary($event)"
                      ref="withSummary">
            <inline-loading-message v-if="summaryLoading">
                Summarising your mentions...
            </inline-loading-message>
            <WithSummaryCitations v-else-if="hasCompleteSummary"
                                  :summary="summary"
                                  :text="text"
                                  @loading="citationLoading = $event"
                                  v-slot="{text: citationText, citations}">
                <div v-if="citations != null">
                    <MentionStatsSummaryLinks @loading="entitiesLoading = $event"
                                              :summary="citations != null ? citationText : text"
                                              :citations="citations"/>
                </div>
                <div v-else class="animated fadeIn">
                    {{ text }}
                </div>

            </WithSummaryCitations>
            <div v-else>
                <no-topics-message>
                    {{  noSummaryMessage }}
                </no-topics-message>
            </div>
        </with-summary>
    </div>
</template>

<script>
import InlineLoadingMessage from "@/components/InlineLoadingMessage.vue";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage.vue";
import WithSummary from "@/app/toplevel/explore/overview/components/summaries/WithSummary.vue";
import MentionStatsSummaryLinks from "@/app/toplevel/mentions/components/MentionStatsSummaryLinks.vue";
import WithSummaryCitations from "@/app/toplevel/mentions/components/WithSummaryCitations.vue";

export default {
    components: {WithSummaryCitations, MentionStatsSummaryLinks, WithSummary, NoTopicsMessage, InlineLoadingMessage, },
    props: {
        filter: String
    },

    data() {
        return {
            summary: null,
            citationLoading: false,
            entitiesLoading: false
        }
    },

    watch: {
        filter() {
            this.citationLoading = false;
            this.entitiesLoading = false;
        }
    },

    computed: {
        hasCompleteSummary() {
            return this.summary && this.summary.algorithm !== 'DEFAULT_TEXT'; // default text is when there was nothing to summarise.
        },

        noSummaryMessage() {
            return this.summary?.summary ?? "No summary available";
        },

        state() {
            if (this.citationLoading) return "Finding references...";
            if (this.entitiesLoading) return "Finding entities...";
            return null;
        }
    },

    methods: {
        async refresh() {
            if (this.$refs.withSummary) {
                await this.$refs.withSummary.refresh();
            }
        },

        setSummary(event) {
            this.summary = event;
        }
    }

};
</script>