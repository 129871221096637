var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img class=\"avatar\" width=\"48px\" height=\"48px\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"stat-box highlight\">\n                    <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"followerCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":22,"column":41},"end":{"line":22,"column":88}}}))
    + "</span> followers\n                    <div class=\"under-stats\">\n                        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"followerArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followerDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":24,"column":43},"end":{"line":24,"column":73}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followerPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":24,"column":75},"end":{"line":24,"column":111}}}))
    + ")\n                        <div class=\"follower-area area-graph\"></div>\n                    </div>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"stat-box highlight\">\n                    <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"likes") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":42,"column":41},"end":{"line":42,"column":80}}}))
    + "</span> likes\n                    <div class=\"under-stats\">\n                        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"likesArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"likesDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":44,"column":40},"end":{"line":44,"column":67}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"likesPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":44,"column":69},"end":{"line":44,"column":102}}}))
    + ")\n                        <div class=\"likes-area area-graph\"></div>\n                    </div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"stat-box highlight\">\n                    <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"subscribers") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":52,"column":41},"end":{"line":52,"column":86}}}))
    + "</span> subscribers\n                    <div class=\"under-stats\">\n                        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"subscribersArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribersDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":54,"column":46},"end":{"line":54,"column":79}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribersPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":54,"column":81},"end":{"line":54,"column":120}}}))
    + ")\n                        <div class=\"likes-area area-graph\"></div>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid multiple\">\n    <div class=\"title multiple\">\n        <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Open "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s profile in a new tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"pictureLink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"display-name\">\n                <div class=\"name\">\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                </div>\n                <div class=\"vanity "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"networkIcon") : depth0), depth0))
    + "\">\n                    "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "\n                </div>\n            </div>\n        </a>\n    </div>\n\n    <div class=\"body multiple\">\n        <div class=\"group1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isTwitter") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isInstagram") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":38,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isFacebook") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isYouTube") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":58,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n</div>\n\n";
},"useData":true});