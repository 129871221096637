<template>
    <section class="expanded-class">
        <section class="expanded-class__description dark-scrollbars dark-scrollbars--visible" tabindex="-1">
            <h1>{{klass.title}}</h1>

            <image-stack v-if="imageStack.length" :images="imageStack"/>

            <markdown-text v-if="klass.expanded"
                           class="expanded-class__markdown"
                           :text="klass.expanded"/>
            <p v-else class="error">
                «No expanded text»
            </p>
        </section>
        <section class="expanded-class__scrolling dark-scrollbars dark-scrollbars--visible" tabindex="-1">
            <hero-metric-description
                    class="expanded-class__hero"
                    :metric="hero"
                    @add="$emit('add-metric', $event)"/>
            <section class="expanded-class__grid">
                <metric-description v-for="m in nonHero"
                                    :key="m.id || m.title"
                                    :metric="m"
                                    tabindex="0"
                                    @add="$emit('add-metric', $event)">
                </metric-description>
            </section>
            <section v-if="additional.length" class="expanded-class__additional">
                <h4>Additional metrics</h4>
                <metric-table :metrics="additional"
                              @add-metric="$emit('add-metric', $event)"/>
            </section>
        </section>
    </section>
</template>

<script>
import {availableMetrics} from './availableMetrics';
import MetricDescription from "./MetricDescription";
import MetricTable from "./MetricTable";
import HeroMetricDescription from "./HeroMetricDescription";
import ImageStack from "../../../../components/ImageStack";
import MarkdownText from "@/components/MarkdownText";

export default {
        name: "ExpandedClass",

        components: {MarkdownText, ImageStack, MetricTable, MetricDescription, HeroMetricDescription },

        props: {
            metricClass: String
        },

        computed: {
            imageStack() {
                if (!this.klass || !this.klass.metrics) return [];
                return this.klass.metrics
                           .filter(m => m.image && !m.hide && !m.additional)
                           .map(m => m.image)
                           .filter((value, index, self) => self.indexOf(value) === index)
                           .slice(0, 3)
                           .reverse();
            },
            klass() {
                return Object.assign({
                    title: "Unknown metrics",
                    description: "Please contact support and let them know how you reached this message"
                }, availableMetrics[this.metricClass])
            },
            hero() {
                if (!this.klass || !this.klass.metrics) return null;
                const hero = this.klass.metrics.find( k => k.hero && !k.hide && !k.additional);
                return hero || this.klass.metrics.find(m => !m.hide && !m.additional);
            },
            nonHero() {
                return this.klass.metrics.filter( k => k !== this.hero && !k.additional && !k.hide );
            },
            additional() {
                return this.klass.metrics.filter( k => k !== this.hero && k.additional && !k.hide );
            }
        }
    };

</script>

<style lang="sass">
    .expanded-class__markdown .markdown-display
        padding: 0
        p, ul
            color: var(--be-colour-text-dark)
            font-family: var(--widget-font)

</style>

<style scoped lang="sass">
    .expanded-class
        margin-right: -20px
        --grid-gap: 30px
        height: 100%
        display: flex
        flex-direction: row
        .error
            color: red !important
            font-variant: small-caps

    .expanded-class__description
        flex: 0 0 auto
        width: 30%
        box-sizing: border-box
        margin: -20px 0 -65px -20px
        padding: 20px
        background: var(--light-background)
        border-right: thin solid var(--light-border-colour)
        overflow-y: auto
        h1
            color: #ddd
            font-size: 2.5em
            line-height: 1em
            margin: 10px 0 30px
        p
            font-family: var(--widget-font)
            color: var(--be-colour-text-dark)

    .expanded-class__scrolling
        flex: 1 1 auto
        padding: 0
        overflow-y: auto
        //noinspection CssInvalidFunction
        background: radial-gradient(var(--light-highlight), rgba(0, 0, 0, 0) 70%)
        &:focus
            outline: none

    .expanded-class__additional
        margin-top: 20px
        padding: 0 20px
        border-top: thin solid var(--light-border-colour)

    .expanded-class__hero
        margin: 20px 0 20px
        padding: 0 40px 30px
        border-bottom: thin solid var(--light-border-colour)


    .expanded-class__grid
        margin-top: 40px
        padding: 0 40px
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        column-gap: var(--grid-gap)
        row-gap: var(--grid-gap)

    @media (max-height: 800px) and (max-width: 1440px)
        .expanded-class__grid
            margin-top: 30px
            padding: 0 30px


    @media (max-height: 800px), (max-width: 1280px)
        .expanded-class__description
            font-size: 0.8rem
            line-height: 1.2rem
            h1
                font-size: 1.8rem
                line-height: 2.0rem

    @media (max-width: 1280px)
        .expanded-class
            --grid-gap: 20px
        .expanded-class__grid
            grid-template-columns: 1fr 1fr

</style>