import Vue from 'vue';
import { getTopicTreeIds } from "@/app/utils/Topics";
import {features} from "@/app/Features";

const themes = Vue.observable({
    title: "Themes",
    description: "Find out what issues are causing shifts in sentiment.",
    expanded:
`We provide a range of tools to categorise conversations to reveal sentiment-bearing 
trends in social media and press sources. This includes our own _topic categorisation_
for root cause analysis, as well as tools such as _word clouds_.`,
    image: require("../assets/themes.png"),
    metrics: [
        {
            id: "topics/trending",
            title: "Trending topics",
            description: "Explore how your topics have been changing over time. See what's been spiking or increasing in volume",
            more: "Highlights topics that have increased significantly in volume, or spiked in volume, compared to the previous time period. This shows how your conversation is changing, and what the root causes of these changes are.",
            image: require("../assets/themes/topic-trends.png"),
            keywords: ['trend', 'trends', 'topic trends'],
            widgetDesc: {
                type: "TrendingWidget",
                caption: "Trending topics"
            },
            hide: true,
            hero: true
        },
        {
            id: "topics/wheel",
            title: "Topic wheel",
            description: "Display your sentiment-bearing conversation categorised according to industry specific topics",
            image: require("../assets/themes/topic-wheel.png"),
            hide: true,
            widgetDesc: {
                type: "TopicWheel"
            }
        },
        {
            id: "topics/cooccurrence",
            title: "Topic co-occurrences",
            description: "Shows how conversations around specific topics relate to one another",
            image: require("../assets/themes/cooccurrence.png"),
            keywords: ["chord", "cooccurrences", "coocur"],
            hide: true,
            widgetDesc: {
                type: "TopicWheel",
                caption: "Topic co-occurrences",
                mode: "chord"
            },
            options: [
                {
                    key: "negative",
                    title: "See negative co-occurrences",
                    tooltip: "Shows only those co-occurrences that have a negative Net Sentiment",
                    action() {
                        return {
                            id: "topics/cooccurrence/negative",
                            widgetDesc: {
                                type: "TopicWheel",
                                caption: "Negative topic co-occurrences",
                                mode: "chord",
                                sentiment: "neg"
                            },
                        }
                    }
                },
                {
                    key: "positive",
                    title: "See positive co-occurrences",
                    tooltip: "Shows only those co-occurrences that have a positive Net Sentiment",
                    action() {
                        return {
                            id: "topics/cooccurrence/positive",
                            widgetDesc: {
                                type: "TopicWheel",
                                caption: "Positive topic co-occurrences",
                                mode: "chord",
                                sentiment: "pos"
                            },
                        }
                    }
                }

            ]
        },
        {
            id: "topics/table",
            hide: true,
            title: "Topic sentiment",
            image: require("../assets/themes/comparison.png"),
            description: "Shows topics occurring in your conversations along with their sentiment",
            more: "See a table comparing topics, volume and sentiment. You can easily add other fields to compare by",
            widgetDesc:  {
                "type": "CompTable",
                "width": 6,
                "height": 4,
                "caption": "Topic sentiment",
                "rows": [
                    {
                        "id": 1,
                        "groupBy": "topic",
                        "limit": 99,
                        "showOther": true,
                        "showUnknown": true,
                        "name": "",
                        "sortByFirstCol": false
                    }
                ],
                "cols": [
                    {
                        "id": 1,
                        "type": "mentionCount",
                        "groupBy": null,
                        "limit": 10,
                        "showOther": true,
                        "showUnknown": true,
                        "name": null,
                        "showPercentage": true,
                        "showBar": true,
                        "showValue": true,
                        "showDelta": true
                    },
                    {
                        "id": 2,
                        "type": "sentiment",
                        "groupBy": null,
                        "limit": 10,
                        "showOther": true,
                        "showUnknown": true,
                        "name": null,
                        "showPercentage": false,
                        "showValue": true,
                        "showBar": false,
                        "showDelta": true,
                        "colour-palette": "be14",
                        "colour-index": 6,
                        "colour-palette-custom": null
                    },
                    {
                        "id": 4,
                        "type": "negative",
                        "groupBy": null,
                        "limit": 10,
                        "showOther": true,
                        "showUnknown": true,
                        "name": null,
                        "showPercentage": false,
                        "showValue": true,
                        "showBar": true,
                        "showDelta": true,
                        "colour-palette": "be14",
                        "colour-index": 6,
                        "colour-palette-custom": null
                    },
                    {
                        "id": 5,
                        "type": "positive",
                        "groupBy": null,
                        "limit": 10,
                        "showOther": true,
                        "showUnknown": true,
                        "name": null,
                        "showPercentage": false,
                        "showValue": true,
                        "showBar": true,
                        "showDelta": true,
                        "colour-palette": "be14",
                        "colour-index": 6,
                        "colour-palette-custom": null
                    }
                ],
                "swapRowsAndCols": false,
            }
        },
        {
            id: "topics/rows",
            title: "Topics",
            image: require("../assets/themes/all-topics.png"),
            description: "See the number of mentions making up each topic",
            hide: true,
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "topic",
                yAxis: "mentionPercent",
                geometry: "rows",
                width: 4,
                height: 6
            },
            options: [
                {
                    key: "parents",
                    title: "Show parent topics",
                    tooltip: "Instead of showing the child topics, shows the parent topics instead, providing a higher-level, coarser breakdown of the conversations",
                    action: metric => {
                        return Object.assign({},
                            metric,
                            {
                                widgetDesc: Object.assign({
                                    caption: "Parent topic volume",
                                    "manual-caption": true,
                                    "hide-parents": false,
                                    "hide-children": true
                                }, metric.widgetDesc)
                            })
                    }
                },
                {
                    key: "positive",
                    title: "Show top positive topics",
                    tooltip: "Show the topics that received the most positive mentions",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                widgetDesc: Object.assign({
                                    caption: "Positive topics",
                                    "manual-caption": true,
                                    filter: "sentiment > 1",
                                    cycleColoursDisabled: true,
                                    "colour-index": 0,
                                    "colour-palette": "be14",
                                }, metric.widgetDesc)
                            })
                    }
                },
                {
                    key: "negative",
                    title: "Show top negative topics",
                    tooltip: "Show the topics that received the most negative mentions",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                widgetDesc: Object.assign({
                                    caption: "Negative topics",
                                    "manual-caption": true,
                                    filter: "sentiment < 0",
                                    cycleColoursDisabled: true,
                                    "colour-index": 3,
                                    "colour-palette": "be14",
                                }, metric.widgetDesc)
                            })
                    }
                }
            ]
        },
        {
            id: "themes/trending-tags",
            title: "Trending tags",
            description: "Choose custom tags to see how they have been changing over time",
            keywords: ['trend', 'trends', 'tag trends'],
            widgetDesc: {
                type: "TrendingWidget",
                caption: "Trending tags",
                dataType: "TAGS"
            },
            additional: true,
        },
        {
            id: "themes/wordcloud",
            title: "Word cloud",
            keywords: ["word", "cloud"],
            description: "Identify the key themes emerging in your conversation",
            image: require("../assets/themes/word-cloud.png"),
            widgetDesc: {
                type: "WordCloud"
            },
            options: [
                {
                    key: "volume",
                    title: "As volume chart",
                    tooltip: "Add a bar chart of frequently used words and their volumes",
                    action() {
                        return {
                            id: "themes/wordcloud/volumes",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "extractWord",
                                yAxis: "mentionCount",
                                geometry: "rows",
                                width: 4,
                                height: 4
                            },
                        }
                    }
                }
            ]
        },
        {
            id: "themes/authorbiocloud",
            title: "Author bio cloud",
            keywords: ["author", "cloud"],
            description: "See a word cloud created from the social media bios of your conversation authors",
            more: "This is produced from only publicly supplied author bios, from Twitter only",
            image: require("../assets/themes/bio.png"),
            widgetDesc: {
                type: "WordCloud",
                "word-choice": "authorBioWord",
                caption: "Author bio cloud"
            },
            options: [
                {
                    key: "volume",
                    title: "As volume chart",
                    tooltip: "Add a bar chart of frequently used words in author bios and their volumes",
                    action() {
                        return {
                            id: "themes/authorbiocloud/volumes",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "authorBioWord",
                                yAxis: "mentionCount",
                                geometry: "rows",
                                width: 4,
                                height: 4
                            },
                        }
                    }
                }
            ]

        },
        {
            id: "themes/hashtagcloud",
            title: "Hashtag cloud",
            keywords: ["hashtag", "cloud"],
            description: "Identify the hashtags being used in your conversation",
            image: require("../assets/themes/hashtags.png"),
            widgetDesc: {
                type: "WordCloud",
                caption: "Hashtag cloud",
                hashtags: true
            }
        },
        {
            id: "themes/attagcloud",
            title: "@name cloud",
            keywords: ["@", "cloud", "name"],
            description: "Identify the authors being mentioned in your conversation",
            image: require("../assets/themes/attags.png"),
            widgetDesc: {
                type: "WordCloud",
                caption: "@name cloud",
                atnames: true
            }
        }
    ]
});

export default themes;

let initPromise = null;

export async function init() {
    if (initPromise) return initPromise;
    return initPromise = initImpl();
}

export async function initImpl() {
    const topics = await getTopicTreeIds();
    if (topics.length) {
        const reference = {
            tooltip: "See the topics that make up your topic wheel",
            type: "TopicReference",
            caption: `Topic reference sheet`,
            width: 8,
            height: 8,
            treeId: topics[0],
            notification: "Added <strong>Topics Reference Sheet</strong>"
        };

        const wheel = themes.metrics.find(m => m.id === "topics/wheel");
        wheel.hide = false;
        wheel.reference = reference;

        const trending = themes.metrics.find(m => m.id === "topics/trending");
        trending.hide = false;
        trending.reference = {...wheel.reference, tooltip: "See the topics that drive your conversations"};

        themes.metrics.find(m => m.id === "topics/cooccurrence").hide = false;
        themes.metrics.find(m => m.id === "topics/rows").hide = false;
        themes.metrics.find(m => m.id === "topics/table").hide = false;


    }
}