<template>
    <widget-settings-dialog class="nomenclature-settings" :model="model" :dto="dto" no-title width="400px" @input="$emit('input', $event)">

        <drop-down-input class="nomenclature-settings__list-dropdown" v-model="selectedList" :options="definitionsList" label="Select a definition list"></drop-down-input>
    </widget-settings-dialog>
</template>

<script>
import WidgetSettingsDialog from "@/dashboards/widgets/WidgetSettingsDialog";
import {getAllSegmentLists} from "@/app/utils/Segments";
import DropDownInput from "@/components/inputs/DropDownInput";
import {definitions} from "@/dashboards/widgets/nomenclature/nomenclature-definitions";

export default {
    name: "NomenclatureSettingsDialog",
    components: {DropDownInput, WidgetSettingsDialog},

    props: {
        model: Object
    },

    data() {
        return {
            dto: {
                attrs: { }  // this gets set by widget-settings-dialog
            },

            selectedList: null,
            definitionsList: []
        }
    },

    async created() {
        let segments = await getAllSegmentLists();

        this.definitionsList = [...segments, ...definitions];

        if (!this.selectedList) this.selectedList = this.definitionsList.at(0)?.id;
    },

    watch: {
        selectedList(v) {
            this.dto.attrs.selectedList = v;
        },
        'dto.attrs.selectedList'(v) {
            this.selectedList = v;
        }
    }
}
</script>

<style scoped lang="sass">

.nomenclature-settings

    ::v-deep .control-form
        min-height: 200px !important

    label
        color: #bbb

    &__btn-group
        display: flex
        margin-bottom: 10px
        .be-button:not(:last-of-type) ::v-deep .btn
            border-top-right-radius: 0
            border-bottom-right-radius: 0
        .be-button + .be-button
            & ::v-deep .btn
                border-top-left-radius: 0
                border-bottom-left-radius: 0

    &__list-dropdown
        width: 300px
        margin-top: 20px



</style>