import ExplorePanel from "@/app/toplevel/explore/ExplorePanel";
import Beef from "@/app/Beef";
import {removeQuotes} from "@/app/utils/StringUtils";

/**
 * Provides routing for the research panel.
 */
Beef.module("ExplorePanel").addInitializer(function() {

    function routeCallback(code, initialActiveView, filter, initialPinnedIds, word, brandId, date) {
        const isAccountCode = Beef.isAccountCode(code);

        if (!isAccountCode) {
            Beef.content.show(Beef.view404);
            return;
        }

        const props = {};
        if (initialActiveView) props.initialActiveView = decodeURIComponent(initialActiveView);
        if (filter) props.filter = removeQuotes(decodeURIComponent(filter));
        if (initialPinnedIds) props.initialPinnedIds = decodeURIComponent(initialPinnedIds).split(',');
        if (word) props.initialWord = decodeURIComponent(word);
        if (brandId) props.initialBrandId = parseInt(brandId);
        if (date) props.initialDate = decodeURIComponent(date);

        Beef.content.show(new Beef.VuejsView.View({component: ExplorePanel, props }));
    }

    function routeCallbackWithWord(code, initialActiveView, word) {
        routeCallback(code, initialActiveView, null, null, word);
    }


    Beef.route(":code/explore", "research", routeCallback);
    Beef.route(":code/explore/:initialActiveView", "research", routeCallback);
    Beef.route(":code/explore/:initialActiveView?word=:word", "research", routeCallbackWithWord);
    Beef.route(":code/explore/:initialActiveView?filter=:filter", "research", routeCallback);
    Beef.route(":code/explore/:initialActiveView?filter=:filter&ids=:initialPinnedIds", "research", routeCallback);
    Beef.route(":code/explore/:initialActiveView?brand=:brandId", (code, initialActiveView, brand) => {
        routeCallback(code, initialActiveView, null, null, null, brand);
    });
    Beef.route(":code/explore/:initialActiveView?date=:date", (code, initialActiveView, date) => {
        routeCallback(code, initialActiveView, null, null, null, null, date);
    });
    Beef.route(":code/explore/:initialActiveView?brand=:brandId&date=:date", (code, initialActiveView, brand, date) => {
        routeCallback(code, initialActiveView, null, null, null, brand, date);
    });

});
