import {render} from "@/dashboards/widgets/commentaryplus/inlineTools/Parser";
import {currentAccountCode} from "@/app/utils/Account";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import EditCountCommandDialog from "@/dashboards/widgets/commentaryplus/customHtmlElements/EditCountCommandDialog.vue";
/**
 * Custom html component for commands in the commentary+ editor
 */
export class CommandEl extends HTMLElement {

    static observedAttributes = ['data-filter', 'data-command', 'data-subfilter', 'data-commandtype', 'data-readonly'];

    constructor() {
        super();
    }

    connectedCallback() {
        this.innerText = '';

        this.wrapper = document.createElement("span");
        this.contentEditable = "false";
        this.appendChild(this.wrapper);

        this.dataset.readonly ??= 'true';
        this.dataset.filter ??= '';

        this.wrapperHasEventListener = false;

        this.parse();
        if (this.dataset.readonly === 'false') {
            this.addEditDialogue();
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.isConnected) return;
        if (name === 'data-readonly') {
            if (this.dataset.readonly === 'false') this.addEditDialogue();
        } else {
            //either the filter or the command has changed
            this.parse();
        }
    }

    parse() {
        if (this.dataset.filter !== '' && this.dataset.command && this.isValidCommand(this.dataset.command)) {
            try {
                let renderResult = render(this.commandWithSubFilter(), this.dataset.filter, currentAccountCode(), {});
                this.wrapper.innerHTML = renderResult.text;

                renderResult.then((updates) => {
                    updates.forEach((html, selector) => {
                        let elementToUpdate = this.wrapper.querySelector(selector);
                        if (elementToUpdate) {
                            elementToUpdate.innerHTML = html;
                            elementToUpdate.classList.remove('text-loading');
                        }
                    });
                    if (this.dataset.readonly === 'true') {
                        renderResult.events(this.wrapper);
                    }
                })
            } catch (e) {
                console.error(e);
            }
        }
    }

    addEditDialogue() {
        if (!this.wrapperHasEventListener) {
            let dialog;
            switch (this.dataset.commandtype) {
                case 'Top':
                case 'Count':
                    dialog = EditCountCommandDialog;
                    break;
                case 'Etc':
                    return;
            }
            this.wrapper.addEventListener('click', () => {

                    let dialogComponent = showDialogComponent(
                        dialog,
                        {
                            commandType: this.dataset.commandtype,
                            command: this.dataset.command,
                            subFilterProp: this.dataset.subfilter
                        }
                    );
                    dialogComponent.$on('command-updated', value => {
                        this.dataset.command = value.command;
                        this.dataset.subfilter = value.subFilter;
                        let event = new CustomEvent("shouldSave", {bubbles: true});
                        this.dispatchEvent(event);
                    });
                    dialogComponent.$on('command-deleted', () => {
                        let event = new CustomEvent("shouldSave", {bubbles: true});
                        this.dispatchEvent(event);
                        this.remove();
                    });
                }
            )
            this.wrapperHasEventListener = true;
        }
    }

    isValidCommand(text) {
        text = text?.trim() ?? '';
        return text.startsWith("{{") && text.endsWith("}}");
    }

    commandWithSubFilter() {
        let commandWithSubFilter = this.dataset.command;
        if (this.dataset.subfilter) {
            commandWithSubFilter = this.dataset.command.substring(0, this.dataset.command.length - 2) + " \"" + this.dataset.subfilter + "\"" + "}}";
        }
        return commandWithSubFilter;
    }

}
customElements.define('command-element', CommandEl);
