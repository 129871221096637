var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"alert\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"warning") || (depth0 != null ? lookupProperty(depth0,"warning") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"warning","hash":{},"data":data,"loc":{"start":{"line":10,"column":27},"end":{"line":10,"column":38}}}) : helper)))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"control-group\" style=\"margin-top: 0\">\n                <a class=\"basic pull-left\">Return to the basic filter editor</a>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n    <div class=\"span12\">\n\n        <div class=\"control-group\">\n            Use this editor to generate advanced filter strings for segmentation of your conversation data.\n            Alternatively, please use our basic filter editor to define your chosen criteria for reporting.\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"warning") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"control-group\">\n            <div class=\"pull-right\">\n                <a class=\"btn btn-small btn-link insert-brand-id big-popup\">Insert Brand ID</a>\n                <a class=\"btn btn-small btn-link insert-profile-id big-popup\">Insert Profile ID</a>\n            </div>\n            <label>Advanced Filter</label>\n            <div class=\"controls\">\n                <textarea name=\"filter\" class=\"span12\"></textarea>\n            </div>\n        </div>\n\n        <div class=\"v4Issues\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noToggle") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});