/*
 * Formats a duration in seconds nicely but accurately e.g. 3.5d, 52.2h, 179m, 28s.
 */
import {formatNumber, formatSeconds} from "@/app/utils/Format";

export default function(seconds, exact, context) {
    if (context == undefined) exact = false; // this happens when we are invoked with only one arg
    var s = formatNumber(seconds, 0) + " s";
    var text = exact ? "<span>" + s + "</span>" : "<span title='" + s + "'>" + formatSeconds(seconds) + "</span>";
    if (seconds === undefined) text = "<span>N/A</span>";
    return new Handlebars.SafeString(text);
}