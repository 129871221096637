/**
 * Select a crowd region.
 */
import VuexStore from "@/store/vuex/VuexStore";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("CrowdRegionPicker").addInitializer(function(startupOptions) {

    this.items = function(q, view, callback) {
        VuexStore.dispatch("crowds/refreshCrowds")
            .then(() => {
                const crowds = VuexStore.state.crowds.crowds;
                const items = {};
                crowds.forEach( c => items[c.id] = c.name );
                callback(items);
            })
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "crowd-region-picker auto-complete-picker" },
        items: this.items
    });

    this.createConverterFactory = function(placeholder) {
        return function(view) {
            var conv;
            return function(direction, value) {
                if (value && value.length > 0) {
                    var binding = this;
                    if (conv) return conv.call(binding, direction, value);
                    VuexStore.dispatch("crowds/refreshCrowds")
                        .then(() => {
                            const crowds = VuexStore.state.crowds.crowds;
                            const items = {};
                            crowds.forEach(c => items[c.id] = c.name);
                            conv = createTagConverter({items: items, placeholder: placeholder});
                            conv.call(binding, direction, value);
                            conv.call(binding, direction, value);
                        });
                } else {
                    $(this.boundEls[0]).text("");
                }
                return value;
            }
        }
    };

    this.converterFactory = this.createConverterFactory("Type the crowd name");

    /**
     * Attach a crowd region picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        VuexStore.dispatch("crowds/refreshCrowds").catch(e => console.warn("Problem refreshing crowds", e));
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
