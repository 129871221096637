<template>
    <div class="beef-tooltip">
        <h1>Private {{network}} mentions</h1>
        <p>
            <strong><deq-number :number="privateCount"/></strong> of these mentions were private
        </p>
    </div>
</template>

<script>

import {formatPlural} from "@/app/utils/Format";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    components: {DeqNumber},
    props: {
        network: String,
        total: Number,
        privateCount: Number
    },
    computed: {
        privatePercent() {
            return this.privateCount / this.total;
        }
    },
    methods: {
        formatPlural
    }
}
</script>