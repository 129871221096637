/**
 * A module for showing a single search job, its phrases, and data as a chart.
 */
import {flattenFilterNodes, parseFilterString} from "@/dashboards/filter/FilterParser";
import _ from 'underscore';
import moment from "moment";
import {setTitle} from "@/app/Beef";
import {LANGUAGES} from "@/app/utils/Language";
import {removeSingleQuotes, splitQuotedString} from "@/app/utils/StringUtils";


Beef.module("SearchJob").addInitializer(function() {

    var pollInterval = 3000;

    // Have to separate the buttons into a region so that they can be updated without rendering the whole
    // container view.
    var SearchJobButtonRegionView = Backbone.Marionette.ItemView.extend({
        template: require("@/historical-search/job/SearchJobButtonRegion.handlebars"),

        templateHelpers: function() {
            var job = this.model.get("job");
            var retrievalDone = job && (job.dataRetrieveCompleteDate || job.dataRetrieveComplete);
            var hasStarted = job && job.searchCommenceDate;
            var hasPhrases = job && job.searchPhrases && (job.searchPhrases.length > 0);
            const hasCustomQuery = typeof job.customQuery === 'string' && job.customQuery.trim().length > 0;

            var canRetrieve = false;
            var canStart = false;
            var canSample = false;
            var searching = isSearchRunning(job);
            var retrieving = isRetrievalRunning(job);
            var sampling = isSampleRunning(job);
            var retrieveTitle;
            var startTitle;
            var sampleTitle;

            if (hasErrors(job)) {
                retrieveTitle = "This search must be reset before you can retrieve mentions";
                startTitle = "This search must be reset before you can start it";
                sampleTitle = "This search must be reset before you can sample";
            } else if (shouldPoll(job)) {
                retrieveTitle = "This search is busy sampling or retrieving mentions";
                startTitle = "This search is busy sampling or retrieving mentions";
                sampleTitle = "This search is busy sampling or retrieving mentions";
            } else if ((hasPhrases || hasCustomQuery) && hasStarted) {
                startTitle = "The filter or phrases must be edited before you can start this search";
                if (searching) {
                    sampleTitle = "A search is currently in progress";
                    retrieveTitle = "A search is currently in progress";
                } else {
                    sampleTitle = "Click to open the sample setup";
                    canSample = true;
                    if (retrievalDone) {
                        retrieveTitle = "You have already retrieved mentions for this search";
                    } else {
                        retrieveTitle = "Click to open the retrieval setup";
                        canRetrieve = true;
                    }
                }
            } else {
                retrieveTitle = "A search must be started before you can retrieve mentions";
                sampleTitle = "A search must be started before you can sample mentions";
                if ((hasPhrases || hasCustomQuery) && !hasStarted) {
                    startTitle = "Click to start this search and view the phrase volume";
                    canStart = true;
                } else {
                    startTitle = "You need to add at least one phrase to start this search";
                }
            }
            return {
                canRetrieve: canRetrieve,
                canStart: canStart,
                canSample: canSample,
                retrieving: retrieving,
                sampling: sampling,
                retrieveTitle: retrieveTitle,
                startTitle: startTitle,
                sampleTitle: sampleTitle
            };
        },

        modelEvents: {
            "change": "render"
        }
    });

    this.View = Backbone.Marionette.Layout.extend({

        attributes: { class: "search-job" },

        regions: {
            chartRegion: ".chart-region",
            buttonRegion: ".button-region",
            mentionRegion: ".mention-region",
            pagesRegion: ".pages-region"
        },

        template: require("@/historical-search/job/SearchJob.handlebars"),

        templateHelpers: function() {
            return {
                filter: this.filterToEnglish()
            };
        },

        events: {
            "click span.add-phrase": "addPhraseClick",
            "click span.add-phrases-from-brand": "addPhrasesFromBrandClick",
            "click td.phrase.editable": "editPhraseClick"
        },

        modelEvents: {
            "change": "render"
        },

        initialize: function() {
            var job = this.model.get("job") || {};
            this.model.set("title", job.name ? job.name : ("Search " + job.id));
            setTitle(job.name ? job.name : "Search");
            this.startPoll();
        },

        onRender: function() {
            var job = this.model.get("job") || {};
            var copy = {
                id: job.id,
                searchRangeStartDate: job.searchRangeStartDate,
                searchRangeEndDate: job.searchRangeEndDate,
                searchCommenceDate: job.searchCommenceDate,
                searchFailureDate: job.searchFailureDate
            };
            var chartModel = new Backbone.Model({ job: copy, accountCode: this.model.get("accountCode"),
                afterAxesUpdate: this.afterAxesUpdate.bind(this) });
            var chartView = new Beef.SearchJobChart.View({ model: chartModel });
            this.chartRegion.show(chartView);

            var buttonModel = new Backbone.Model({ job: job });
            var buttonView = new SearchJobButtonRegionView({ model: buttonModel });
            this.buttonRegion.show(buttonView);

            var paginationModel = new Backbone.Model({offset: 0, limit: 9, page: 1});

            var mentionModel = new Backbone.Model({ job: job, paginationModel: paginationModel });
            var mentionView = new Beef.HistoricalSearch.SampleMentionRegion.View({ model: mentionModel });
            this.mentionRegion.show(mentionView);

            var pagerView = new Beef.Pager.View({ model: paginationModel, fixedTo: mentionView });
            this.pagesRegion.show(pagerView);
        },

        /**
         * Clears the current poller, if any.
         */
        onClose: function() {
            this.clearPoll();
        },

        /**
         * Resumes chart polling. If there is an active poller, the running poll will be stopped and replaced.
         */
        startPoll: function() {
            this.clearPoll();
            var pollerId = setTimeout(this.poll.bind(this), 5000);
            this.model.set({ pollerId: pollerId } , { silent: true });
            if (pollInterval > 1000) {
                this.poll();
            } else {
                console.error("Chart poll timeout too short");
            }
        },

        /**
         * Stops the poller that is currently running, if any.
         */
        clearPoll: function() {
            var pollerId = this.model.get("pollerId");
            if (pollerId) {
                clearTimeout(pollerId);
                this.model.set({ pollerId: null }, { silent: true });
            }
        },

        /**
         * Tries to poll a job. Clears the poller if the current job has no reason to be polled.
         */
        poll: function() {
            var that = this;
            var timesPolled = this.model.get("timesPolled") || 0;
            var job = this.model.get("job");
            if (job && (shouldPoll(job) || isSearchRunning(job) || timesPolled < 1)) {
                this.model.set({ timesPolled: timesPolled + 1, busyPolling: true }, { silent: true });
                var success = function(updatedJob) {
                    var stopPolling = false;
                    if (updatedJob) {
                        if (!shouldPoll(updatedJob)) {
                            stopPolling = true;
                            that.clearProgressBar();
                        } else {
                            that.updateProgressBar(updatedJob);
                        }
                        that.model.set({ job: updatedJob }, { silent: true });
                        if (that.buttonRegion.currentView) {
                            that.buttonRegion.currentView.model.set({ job: updatedJob });
                        }
                        // The job might be returned with errors. Errors should be shown in the chart title.
                        if (that.chartRegion && that.chartRegion.currentView) {
                            that.chartRegion.currentView.refreshTitle(updatedJob);
                            that.chartRegion.currentView.refreshSampleBar(updatedJob);
                        }
                        if (that.mentionRegion && that.mentionRegion.currentView) {
                            that.mentionRegion.currentView.updateJob(updatedJob);
                        }
                        that.trigger("pollComplete", updatedJob);
                    } else {
                        stopPolling = true;
                    }
                    if(!stopPolling) {
                       that.startPoll();
                    }
                };
                var error = function(xhr, status, error) {
                    that.clearPoll();
                    console.error(status + " " + error);
                };
                Beef.SearchJobSync.get(this.model.get("accountCode"), job.id, success, error);
            } else {
                this.clearPoll();
            }
        },

        /**
         * Resets the progress bar.
         */
        clearProgressBar: function() {
            this.updateProgressBar();
        },

        /**
         * Updates the progress bar with the given job's mention retrieval volume.
         */
        updateProgressBar: function(job) {
            // don't rely on the progress reaching 100% since the bucket sum is only an estimate of the number
            // of mentions that will be retrieved
            var percent = 0;
            var bar = this.$el.find(".progress-bar");
            if (job && job.actualRecordCount && job.details && (job.details.length > 0)) {
                var sum = this.sumJobDetails(job);
                percent = (sum > 0) ? (job.actualRecordCount / sum) : 0;
            }
            if (percent > 0 && percent < 1) {
                var pixels = (percent * bar.parent().width());
                bar.animate({ width : pixels + "px" });
            } else if (percent >= 1 && (bar.width(0) > 0)) {
                bar.animate({
                    width : bar.parent().width()
                }, {
                    complete: function() {
                        bar.width(0);
                    }
                });
            } else {
                bar.width(0);
            }
        },

        afterAxesUpdate: function(xDomain) {
            xDomain = xDomain || [];
            if (this.mentionRegion && this.mentionRegion.currentView) {
                this.mentionRegion.currentView.updateDateRange(xDomain[0], xDomain[1]);
            }
        },

        /**
         * Returns the phrase search volume of a job, or 0 if not available.
         */
        sumJobDetails: function(job) {
            var sum = 0;
            if (job && job.details) {
                job.details.forEach(function(bucket) {
                    sum += bucket.count;
                });
            }
            return sum;
        },

        addPhrasesFromBrandClick: function(ev) {
            var job = this.model.get("job");
            if (!job || !job.id) {
                console.error("Cannot add phrases to this search: no search job or id");
                Beef.HistoricalSearch.Message.onUnknownError();
            } else {
                if (job.searchCommenceDate) {
                    var onContinue = function() {
                        this.addPhrasesFromBrand(ev);
                    }.bind(this);
                    Beef.HistoricalSearch.Message.showConfirm(onContinue, "edit", "search");
                } else {
                    this.addPhrasesFromBrand(ev);
                }
            }
        },

        addPhrasesFromBrand: function() {
            var that = this;
            var job = this.model.get("job");
            if (!job) {
                console.error("Cannot add phrases to a null job.");
                Beef.HistoricalSearch.Message.onUnknownError();
                return;
            }
            var accountCode = this.model.get("accountCode");
            var model = new Backbone.Model({accountCode: accountCode, job: job });
            model["accountCode"] = accountCode;
            var view = new Beef.HistoricalSearch.BrandPhrasesPopup.View({ model: model });
            var popup = new Beef.Popup.View({closeOnHide: true, positions: ["center"],
                alwaysMove: true});
            popup.setTarget(this.$el);
            popup.show(view);
            $(".add-brand-phrases-setup > .dialog-body > .dialog-button-bar > .ok").click(function(ev) {
                ev.stopPropagation();
                var phrases = view.getPhrases();
                if (phrases) {
                    var dto = Beef.SearchJobSync.createDTO(job);
                    dto.searchPhrases = dto.searchPhrases.concat(phrases);
                    var success = function(updatedJobs) {
                        popup.close();
                        if (updatedJobs) {
                            var index = findJob(dto.id, updatedJobs);
                            if (index < 0) {
                                Beef.HistoricalSearch.Message.onUnknownError();
                            } else {
                                that.model.set("job", updatedJobs[index]);
                                that.trigger("phraseChanged", updatedJobs[index]);
                            }
                            // Since the original job already exists, only add the jobs that
                            // were newly created.
                            var jobsToAdd = [];
                            for (var i = 0; i < updatedJobs.length; i++) {
                                if (i != index) {
                                    jobsToAdd.push(updatedJobs[i]);
                                }
                            }
                            that.trigger("searchJobsCreated", jobsToAdd);
                        } else {
                            Beef.HistoricalSearch.Message.onUnknownError();
                        }
                    };
                    var error = function(xhr, status, error) {
                        console.error(status + " " + error);
                        Beef.HistoricalSearch.Message.onPhraseError();
                    };
                    Beef.SearchJobSync.clearAndUpdateWithSplit(that.model.get("accountCode"), dto, success, error);
                }
            });
        },

        /**
         * Opens a phrase setup popup for adding a phrase. If the job has already fetched data, a confirmation popup
         * is shown first, since search data will be reset.
         */
        addPhraseClick: function(ev) {
            var job = this.model.get("job");
            if (!job || !job.id) {
                console.error("Cannot add phrases to this search: no search job or id");
                Beef.HistoricalSearch.Message.onUnknownError();
            } else {
                if (job.searchCommenceDate) {
                    var onContinue = function() {
                        this.addPhrase(ev);
                    }.bind(this);
                    Beef.HistoricalSearch.Message.showConfirm(onContinue, "edit", "search");
                } else {
                    this.addPhrase(ev);
                }
            }
        },

        /**
         * Opens a phrase setup popup for adding a phrase. Search data is reset before the edit is saved.
         */
        addPhrase: function() {
            var that = this;
            var job = this.model.get("job");
            if (!job) {
                console.error("Cannot add phrases to a null job.");
                Beef.HistoricalSearch.Message.onUnknownError();
                return;
            }

            var model = new Beef.EditPhrase.Model({ query: "", active: true, editing: false,
                _fromHistoricalSearch: true, _goodnessCheckRequired: false
            });

            var view = new Beef.EditPhrase.View({ model: model, accountCode: this.model.get("accountCode"), brand: {},
                template: require("@/historical-search/job/EditSearchJobPhrase.handlebars")
            });

            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
            popup.setTarget(this.$el);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);

            $(".edit-phrase > .dialog-body > .dialog-button-bar > .ok").click(function(ev) {
                ev.stopPropagation();
                view.addSelectedExclusions();
                var query = view.model.get("query");
                var job = that.model.get("job");
                var dto = Beef.SearchJobSync.createDTO(job);
                if (query) {
                    dto.searchPhrases.push(query);
                }
                var success = function(updatedJob) {
                    popup.close();
                    if (updatedJob) {
                        that.model.set("job", updatedJob);
                        that.trigger("phraseChanged", updatedJob);
                    } else {
                        Beef.HistoricalSearch.Message.onUnknownError();
                    }
                };
                var error = function(xhr, status, error) {
                    console.error(status + " " + error);
                    Beef.HistoricalSearch.Message.onPhraseError();
                };
                Beef.SearchJobSync.clearAndUpdate(that.model.get("accountCode"), dto, success, error);
            });
        },

        /**
         * Opens a phrase setup popup for editing a phrase. If the current search job has already fetched data,
         * a confirmation popup is shown, since data will be reset.
         */
        editPhraseClick: function(ev) {
            var job = this.model.get("job");
            if (!job || !job.id) {
                console.error("Cannot edit the phrases of this search: no search job or id");
                Beef.HistoricalSearch.Message.onUnknownError();
            } else {
                if (job.searchCommenceDate) {
                    var onContinue = function() {
                        this.editPhrase(ev);
                    }.bind(this);
                    Beef.HistoricalSearch.Message.showConfirm(onContinue, "edit", "search");
                } else {
                    this.editPhrase(ev);
                }
            }
        },

        /**
         * Opens a phrase setup popup for editing a phrase. Search data is reset before the edit is saved.
         * Results in a NOP if there is no job set or the current job has no phrases.
         */
        editPhrase: function(ev) {
            var that = this;
            var job = this.model.get("job");
            if (!job) {
                console.error("Cannot add phrases to a null job.");
                return;
            } else if (!job.searchPhrases) {
                console.error("Cannot edit a phrase when there are no phrases");
                return;
            }

            var $tr = $(ev.target).closest("tr");
            var id = $tr.data("phrase");
            var phrase = job.searchPhrases[id];
            var model = new Beef.EditPhrase.Model({ query: phrase, active: true, editing: true,
                _fromHistoricalSearch: true, _goodnessCheckRequired: false
            });

            var view = new Beef.EditPhrase.View({ model: model, accountCode: this.model.get("accountCode"), brand: {},
                template: require("@/historical-search/job/EditSearchJobPhrase.handlebars")
            });

            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
            popup.setTarget(this.$el);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);

            var success = function(updatedJob) {
                popup.close();
                if (updatedJob) {
                    that.model.set("job", updatedJob);
                    that.trigger("phraseChanged", updatedJob);
                } else {
                    Beef.HistoricalSearch.Message.onUnknownError();
                }
            };

            var error = function(xhr, status, error) {
                console.error(status + " " + error);
                popup.close();
                Beef.HistoricalSearch.Message.onPhraseError();
            };

            $(".edit-phrase > .dialog-body > .dialog-button-bar > .ok").click(function(ev) {
                ev.stopPropagation();
                view.addSelectedExclusions();
                var query = view.model.get("query");
                var dto = Beef.SearchJobSync.createDTO(job);
                dto.searchPhrases[id] = query;
                dto.id = that.model.get("job").id;
                Beef.SearchJobSync.clearAndUpdate(that.model.get("accountCode"), dto, success, error);
            });

            $(".edit-phrase > .dialog-body > .dialog-button-bar > .pull-left > .delete").click(function(ev) {
                ev.stopPropagation();
                popup.close();
                var onContinue = function() {
                    var dto = Beef.SearchJobSync.createDTO(job);
                    dto.searchPhrases.splice(id, 1);
                    dto.id = that.model.get("job").id;
                    Beef.SearchJobSync.clearAndUpdate(that.model.get("accountCode"), dto, success, error);
                }.bind(this);
                Beef.HistoricalSearch.Message.showConfirm(onContinue, "delete", "phrase");
            });
        },

        /**
         * Creates a natural language label for a filter.
         */
        filterToEnglish: function() {
            var filter = this.prepareFilter();
            var toAdd = [];
            var i;
            var format = function(date) {
                return moment(date).utc().format("MMMM Do YYYY") + " UTC";
            };
            var job = this.model.get("job") || {};
            var retweetRule = job.retweetRule;
            var text;
            if (retweetRule === "ONLY_TWEETS") {
                text = "Search for mentions";
                toAdd.push("excluding retweets");
            } else if (retweetRule === "ONLY_RETWEETS") {
                text = "Search for retweets";
            } else {
                text = "Search for mentions";
            }
            if (filter.published) {
                toAdd.push("published between " + format(filter.published.start) + " and " + format(filter.published.end));
            }
            if (filter.location) {
                for (i = 0; i < filter.location.length; ++i) {
                    if (filter.location[i] === "Unknown") {
                        filter.location[i] = "an unknown location";
                        break;
                    }
                }
                var locSuffix = filter.location.splice(filter.location.length - 1, 1);
                var locPrefix = (filter.location < 1) ? "" : (filter.location.join(", ") + " or ");
                toAdd.push("from " + locPrefix + locSuffix);
            }
            if (filter.language) {
                for (i = 0; i < filter.language.length; ++i) {
                    if (filter.language[i] === "Unknown") {
                        filter.language[i] = "an unknown language";
                        break;
                    }
                }
                var langSuffix = filter.language.splice(filter.language.length - 1, 1);
                var langPrefix = (filter.language < 1) ? "" : (filter.language.join(", ") + " or ");
                toAdd.push("in " + langPrefix + langSuffix);
            }
            if (toAdd.length > 0) {
                text = text + " " + toAdd.join(", ");
            }
            return text;
        },

        /**
         * Parses the current filter and separates the filter terms into a map.
         */
        prepareFilter: function() {
            var filter = {};
            var job = this.model.get("job") || {};
            var attrs;
            try {
                var root = parseFilterString(job.searchFilter);
                if (root) {
                    attrs = Beef.SearchJobFilter.convertExpToAttrs(flattenFilterNodes(root));
                }
            } catch (e) {
                console.error("Unable to parse filter: " + e.message)
            }

            if (attrs && !attrs.errors) {
                if (attrs.location) {
                    filter.location = _(splitQuotedString(attrs.location)).map(function(d) {
                         return Beef.LocationPicker.getLocationName(removeSingleQuotes(d));
                    });
                }
                if (attrs.language) {
                    filter.language = _(attrs.language.split(' ')).map(function(d) {
                        return LANGUAGES[d];
                    })
                }
            }

            if (job.searchRangeStartDate && job.searchRangeEndDate) {
                filter.published = {
                    start: job.searchRangeStartDate,
                    end: job.searchRangeEndDate
                };
            }
            return filter;
        }
    });

    function shouldPoll(job) {
        return job && !hasErrors(job) && (isRetrievalRunning(job) || isSampleRunning(job));
    }

    function isSearchRunning(job) {
        if (!job) {
            return false;
        }
        if (job.searchCommenceDate && !job.searchCompleteDate) {
            return true;
        }
        return !job.searchComplete;
    }

    function isRetrievalRunning(job) {
        return !!(job && job.dataRetrieveCommenceDate && !job.dataRetrieveCompleteDate && !job.dataRetrieveCompleteDate);
    }

    function isSampleRunning(job) {
        return !!(job && job.samplingInProgress);
    }

    function hasErrors(job) {
        return !!(job && (!!job.dataRetrieveFailureDate || job.dataRetrieveFailureMessage || job.searchFailureDate || job.searchFailureMessage));
    }
    this.hasErrors = hasErrors;

    /**
     * Finds a search job in an array of jobs.
     * @return {Number} The index of the search job. Returns -1 if the id is not found.
     */
    function findJob(id, jobs) {
        jobs = jobs|| [];
        var found = false;
        var index = -1;
        while (!found && (++index < jobs.length)) {
            found = (id === jobs[index].id);
        }
        return found ? index : -1;
    }
});
