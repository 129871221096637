<template>
    <div class="mention-panel__mention-list">
        <mention-item v-for="mention in mentions"
                      :key="mention.id"
                      :mention="mention"/>
    </div>
</template>


<script>
import MentionItem from "@/components/MentionItem";
export default {
    components: {MentionItem},
    props: {
        mentions: {
            type: Array,
            required: true
        }
    }
}
</script>


<style scoped lang="sass">

</style>