<template>
    <dialog-box class="tag-mention-dialog"
                @close="$emit('close')"
                :title="title"
                width="500px"
                modal>

        <div v-if="error" class="deq-reset">
            <i class="symbol-warning"></i>
            {{error}}
        </div>
        <div v-else class="deq-reset">
            <div v-if="canEditMentions">
                <inline-loading-message v-if="running">
                    Tagging <num :number="selectedCount"/> {{formatPlural(selectedCount, 'mention')}}
                </inline-loading-message>
                <div v-else-if="Number.isFinite(selectedCount) && hasRun">
                    <num :number="selectedCount"/> {{formatPlural(selectedCount, 'mention has been', 'mentions have been')}} tagged
                </div>
                <div v-else-if="Number.isFinite(selectedCount) && !tags.length">
                    <num :number="selectedCount"/> {{formatPlural(selectedCount, 'mention has been', 'mentions have been')}}
                    selected for tagging.
                </div>
                <div v-else-if="Number.isFinite(selectedCount) && tags.length">
                    <num :number="selectedCount"/> {{formatPlural(selectedCount, 'mention')}}
                    will be tagged with the following {{formatPlural(tags.length, 'tag')}}:
                </div>
                <inline-loading-message v-else>
                    Counting the mentions to be tagged
                </inline-loading-message>

                <inline-tag-input class="tag-mention-dialog__input" v-model="tags" :disabled="disabled"/>
            </div>
            <div v-else-if="account.inactive" class="deq-callout--muted">
                <i class="symbol-info"></i> This account is inactive, and mentions cannot be edited
            </div>
            <div v-else class="deq-callout--muted">
                You do not have enough permissions to edit mentions.
            </div>

            <div v-if="newTags.length && !hasRun" class="tag-mention-dialog__describe-new">
                <p>The following tags are new. Consider giving them a description.</p>
                <div class="tag-mention-dialog__new-tag-list">
                    <template v-for="tag in newTags">
                        <span :key="'name:' + tag.name">{{tag.name}}</span>
                        <div :key="'desc:' + tag.name" class="deq-text--muted tag-mention-dialog__new-tag-description">
                            <inline-text-input v-if="isEditingDescriptionFor(tag)"
                                               v-model="tag.description"
                                               autofocus
                                               hide-controls
                                               placeholder="Describe your tag and press Enter"
                                               :disabled="disabled"
                                               @close="stopEditingDescriptionFor(tag)"/>
                            <div v-else @click="startEditingDescriptionFor(tag)">- {{ tag.description || "No description" }}</div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-else-if="createdTags.length && hasRun" class="tag-mention-dialog__describe-new">
                <p>The following tags were just created</p>
                <div class="tag-mention-dialog__new-tag-list tag-mention-dialog__new-tag-list--created">
                    <span v-for="tag in createdTags" :key="tag.id">
                        <analyse-link :object="tag" @navigate="$emit('close')"/>
                    </span>
                </div>
            </div>

        </div>

        <template #buttons>
            <div v-if="tags && tags.length"
                 class="tag-mention-dialog__buttons">
                <be-button v-if="hasRun" link @click="undo" tooltip="Undo this tagging operation" class="tag-mention-dialog__undo-button">Undo</be-button>
                <be-button :link="!hasRun" @click="$emit('close')" :tooltip="closeTooltip" keyword="esc">{{ closeLabel }}</be-button>
                <be-button v-if="!hasRun"
                           primary @click="updateMentions()"
                           class="short-animated fadeIn"
                           :disabled="runDisabled">
                    Tag mentions
                </be-button>
            </div>
            <div v-else
                 class="tag-mention-dialog__buttons">
                <be-button link @click="$emit('close')" tooltip="Click to close this dialog" keyword="esc">Close</be-button>
            </div>
        </template>
    </dialog-box>
</template>
<script>


import DialogBox from "@/components/DialogBox";
import BeButton from "@/components/buttons/BeButton";
import VuexStore from "@/store/vuex/VuexStore";
import {count} from "@/data/Grouse";
import {grouse} from "@/store/Services";
import Num from "@/components/formatters/DeqNumber";
import {formatNumber, formatPlural} from "@/app/utils/Format";
import {mapActions, mapGetters, mapState} from "vuex";
import {logBulkMentionUpdate} from "@/data/Chervil";
import {getBrandsInFilter, parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import InlineTagInput from "@/components/inputs/InlineTagInput";
import InlineLoadingMessage from "@/components/InlineLoadingMessage";
import {editMentions} from "@/app/Permissions";
import {notifyWithHtml, notifyWithText, showBusyNotification} from "@/app/framework/notifications/Notifications";
import {escapeHtml} from "@/app/utils/StringUtils";
import {showAskDialog} from "@/app/framework/dialogs/Dialog";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import AnalyseLink from "@/components/AnalyseLink";
import {showTip} from "@/app/help/tips/tips";
import {MentionQLexer} from "@/mentionq/mentionq";

export default {
    components: {AnalyseLink, InlineTextInput, InlineLoadingMessage, InlineTagInput, Num, BeButton, DialogBox},
    store: VuexStore,

    props: {
        title: {
            type: String,
            default: "Tag mentions"
        },

        filter: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            running: false,
            hasRun: false,
            selectedCount: null,
            tags: [],
            canEditMentions: editMentions(),
            error: null,

            busyDialog: null,   // Only used if the dialog has been closed while you're busy tagging items.
            tagIds: [],         // For keeping track of the tags that were added to the mentions
            createdTags: [],    // For keeping track of the tags that were just created by the dialog
            editingDescriptionFor: []
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapGetters(['idToTag']),

        disabled() {
            return this.running || this.hasRun || !!this.error;
        },

        runDisabled() {
            return this.disabled || !this.tags?.length;
        },

        closeLabel() {
            return (this.running || this.hasRun) ? "Close" : "Cancel";
        },

        closeTooltip() {
            return (this.running || this.hasRun) ? "Click to close" : "Click to cancel tagging mentions";
        },

        newTags() {
            return this.tags.filter(t => !t.id);
        }
    },

    watch: {
        filter() {
            this.updateStats();
        }
    },

    async mounted() {
        document.documentElement.addEventListener('keydown', this.escapeHandler, {passive: true, capture: true});
        showTip("glossary:TAG").catch(e => console.warn(e));

        await this.refreshTags();
        await this.updateStats();
    },

    async beforeDestroy() {
        document.documentElement.removeEventListener('keydown', this.escapeHandler, true);

        if (this.running) {
            this.busyDialog = showBusyNotification("Tagging your mentions");
        }
    },

    methods: {
        formatPlural,

        ...mapActions(['createTag', 'refreshTags', 'doesTagExist']),

        escapeHandler(event) {
            if (event.key === 'Escape' && event.target?.tagName !== 'INPUT') {
                this.$emit('close');
            }
        },

        async updateMentions() {
            if (this.running) {
                console.warn("Already tagging mentions");
                return;
            }
            this.tagIds = [];
            try {
                this.running = true;

                for (const tag of this.tags) {
                    // at this point, if the tag doesn't have an ID, a tag with the same name should not exist
                    if (!tag.id) {
                        tag.namespace ??= "tag";
                        let newTag = await this.createTag(tag);
                        this.tagIds.push({id: newTag.id, verified: true});
                        this.createdTags.push(newTag);
                    } else {
                        this.tagIds.push({id: tag.id, verified: true});
                    }
                }

                // grouse only allows one brand to be tagged at a time - split tag requests by brand
                let brands = getBrandsInFilter(this.filter).include;
                let filterWithoutBrands = removeNodes(parseFilterString(this.filter), function(node) {
                    return node.operandType === MentionQLexer.BRAND
                }).toString();
                for (const brandId of brands) {
                    let payloadFilter = `${filterWithoutBrands} AND Brand ISORCHILDOF ${brandId}`;

                    let payload = {
                        addTags: this.tagIds,
                        filter: payloadFilter
                    };

                    await grouse.put(`/v4/accounts/${this.account.code}/mentions`, payload);
                    logBulkMentionUpdate(this.account.code, payloadFilter, payload).catch(e => console.error(e));
                }

                this.hasRun = true;

                notifyWithHtml(`${formatNumber(this.selectedCount)} ${formatPlural(this.selectedCount, 'mention')} were tagged with <strong>${this.tags.map(t => escapeHtml(t.name)).join(', ')}</strong>`,
                    () => this.undo(),
                    "<i class='symbol-tags'></i>");
            } catch(e) {
                console.error(e);
                this.error = "There was an error tagging your mentions. Please try again, and if the problem continues, please contact our support team.";
            } finally {
                this.running = false;
                if (this.busyDialog) {
                    this.busyDialog.close();
                    this.busyDialog = null;
                }
            }
        },

        async undo() {
            if (!this.tagIds?.length) return;

            this.$emit('close');
            const goAhead = await showAskDialog("Undo tagging?", `This will remove ${this.tags.length} ${formatPlural(this.tags.length, 'tag')} from ${formatNumber(this.selectedCount)} ${formatPlural(this.selectedCount, 'mention')}. Proceed?`, true);
            if (goAhead) {
                // grouse only allows one brand to be tagged at a time - split tag requests by brand
                let brands = getBrandsInFilter(this.filter).include;
                let filterWithoutBrands = removeNodes(parseFilterString(this.filter), function(node) {
                    return node.operandType === MentionQLexer.BRAND
                }).toString();
                for (const brandId of brands) {
                    let payloadFilter = `${filterWithoutBrands} AND Brand ISORCHILDOF ${brandId}`;

                    await grouse.put(`/v4/accounts/${this.account.code}/mentions`, {
                        removeTags: this.tagIds,
                        filter: payloadFilter
                    });
                }
                notifyWithText("The tags have been removed", null, "<i class='symbol-mentions'></i>");
            }
        },

        async updateStats() {
            if (!this.filter) return;

            try {
                this.selectedCount = (await count(this.filter))?.mentionCount ?? 0;
            } catch(e) {
                if (e.readyState !== 0) {
                    throw e;
                }
            }
        },

        startEditingDescriptionFor(tag) {
            if (this.disabled) return;
            if (!tag.description) tag.description = "";
            this.editingDescriptionFor.push(tag);
        },

        stopEditingDescriptionFor(tag) {
            if (this.disabled) return;
            this.editingDescriptionFor = this.editingDescriptionFor.filter(t => !Object.is(t, tag));
        },

        isEditingDescriptionFor(tag) {
            if (this.disabled) return false;
            if (this.hasRun) return false;
            return !!this.editingDescriptionFor.find(t => Object.is(t, tag));
        }

    }
};
</script>


<style scoped lang="sass">

.tag-mention-dialog__buttons
    display: flex
    margin: 10px -20px -20px
    justify-content: flex-end
    padding: 10px 20px
    border-top: 1px solid #272727
    background: #333333
    border-bottom-left-radius: 6px
    border-bottom-right-radius: 6px

.tag-mention-dialog__undo-button
    margin-right: auto

.tag-mention-dialog__input
    padding-top: 20px

.tag-mention-dialog__describe-new
    margin-top: 20px
    p
        margin: 0
        padding: 0

.tag-mention-dialog__new-tag-list
    display: grid
    grid-template-columns: auto 1fr
    grid-column-gap: 10px

    *:nth-child(odd)
        margin-left: 20px

    .tag-mention-dialog__new-tag-description
        cursor: pointer
        box-sizing: border-box
        max-width: 100%
        padding-right: 20px


.tag-mention-dialog__new-tag-list--created
    display: flex
    flex-direction: row
    flex-wrap: wrap
    *
        margin-left: 0 !important
        margin-right: 10px



</style>