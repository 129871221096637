import {CustomInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/CustomInlineTool";

export class SummaryInlineTool extends CustomInlineTool {

    surround(range) {
        range.deleteContents();

        range.insertNode(document.createTextNode(" \u200b "));
        const container = document.createElement('command-element');
        container.classList.add('deq-count');
        container.dataset.command = '{{summarise-mentions}}';
        container.dataset.filter = this.filter;
        container.innerText = container.dataset.command;

        range.insertNode(container);
    }

    getBtnName() {
        return "Summary";
    }

    checkState() {
    }

    toolTipText() {
        return "Summarise mentions";
    }

}