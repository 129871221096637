import { adjustedWald } from "../../../app/utils/Util";
import {getTagsInFilter, getTopicsInFilter} from "@/dashboards/filter/FilterParser";
import {deprecatedFetchTags, fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import {extractBrands} from "@/dashboards/filter/BasicFilter";
import _ from 'underscore';
import {once} from "@/app/utils/Functions";
import {errorHelper} from "@/dashboards/DashboardUtils";

/**
 * Displays brands and topics (tags) with sentiment and volume information.
 */
Beef.module("Widget.BrandMatrix").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Brand Matrix",
        description:    "Volume and sentiment for brands and topics (tags)",
        group:          "benchmarking",
        width:          4,
        height:         4
    };

    var defaultOnAttrs = ['show-volume', 'show-sentiment', 'show-legend', 'show-footnote'];

    this.View = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/stats/BrandMatrix.handlebars"),

        attributes: { 'class': 'brand-matrix widget-font widget-height-inner scroll-auto' },

        svgExportDisabled: true,

        modelEvents: {
            "change":   "maybeRefresh"
        },

        initialize: function() {
            var defaults = { };
            for (var i = 0; i < defaultOnAttrs.length; i++) {
                if (!this.model.has(defaultOnAttrs[i])) defaults[defaultOnAttrs[i]] = true;
            }
            if (!this.model.has('sampled-sentiment')) defaults['sampled-sentiment'] = 'auto';
            if (!this.model.get('comparison')) {
                defaults['comparison'] = 'none';
            }
            this.model.set(defaults, {silent: true});

            this.accountCode = this.model.getAncestorProperty('accountCode');
            this.refresh();
        },

        renderImpl: function() {
        },

        render: function() {
            this.calcDerivedStats();
            // bypass the BoundItemView render - we don't need the model binding and so on and just want to repaint
            // our template whenever new data comes in
            Backbone.Marionette.Layout.prototype.render.call(this, arguments);
        },

        onFirstRender: function() {
        },

        maybeRefresh: function() {
            var c = this.model.changed;
            if (c['_effectiveFilter'] || c['sampled-sentiment'] || c['width'] || c['comparison']) {
                this.refresh();
            } else {
                this.render();
            }
        },

        refresh: function() {
            deprecatedFetchTags(this, function(data) {
                this.tagMap = data;

                try {
                    var filter = this.model.get('_effectiveFilter');
                    if (!filter) return;
                    this.model.generalData.set({'_loading': true, _completed: false});

                    var comparison = this.model.get("comparison") || 'none';

                    var series = this.getSeries(filter, comparison);
                    this._data = series;

                    var that = this;
                    this.model.generalData.set("_completed", false);

                    this.model.set('_rows', []);

                    var ss = this.model.get('sampled-sentiment');
                    if (ss == null) ss = 'auto';
                    // change auto to no if comparing tags .. likely there isn't enough data for sampling and if the tags
                    // have been assigned by the crowd then the sentiment is likely all verified anyway
                    if (ss == 'auto') {
                        var attr = series.series[0].compAttributes;
                        if (attr && attr.indexOf('tags') >= 0) ss = 'no';
                    }

                    var getFromGrouse = this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view);

                    var removeCalls = series
                        .series
                        .map(function (s) {
                            return getFromGrouse('/v4/accounts/' + this.accountCode + '/mentions/count',
                                {
                                    filter: s.filter,
                                    select: "mentionCount,totalPositive,totalNegative",
                                    groupBy: "brand,sentimentVerified"
                                })
                                .then(
                                    function (data) {
                                        // merge rows with sentimentVerified: true into non-verified rows and fill in sample sizes
                                        // [{brand: { }, sentimentVerified: .., mentionCount: 456, totalPositive: 123, ..}, ...]
                                        var a = [], brandMap = {};
                                        for (var i = 0; i < data.length; i++) {
                                            var row = data[i];
                                            if (row.sentimentVerified) continue;
                                            a.push(row = {brand: row.brand, mentionCount: row.mentionCount});
                                            brandMap[row.brand.id] = row;
                                        }
                                        for (i = 0; i < data.length; i++) {
                                            row = data[i];
                                            var e = brandMap[row.brand.id];
                                            if (!e) a.push(e = {brand: row.brand, mentionCount: 0}); // there is only verified bucket for brand
                                            if (row.sentimentVerified) {
                                                e.mentionCount += row.mentionCount;
                                                e.sampleSize = row.mentionCount;
                                                e.totalPositive = row.totalPositive;
                                                e.totalNegative = row.totalNegative;
                                            }
                                        }
                                        s._data = a;
                                    }, once(e => errorHelper(that.model, e)));
                        }.bind(this));

                    removeCalls.push(
                        new Promise(function(resolve) {
                            fetchCachedBrands(that, function(data) {
                                that.brandMap = data.map;
                                resolve();
                            });
                        })
                    );

                    Promise.all(removeCalls)
                        .then(function() {
                            that.onCallCompleted();
                            that.render();
                        })
                        .catch(e => errorHelper(that.model, e))
                } catch (e) {
                    errorHelper(this.model, e);
                }
            }.bind(this));
        },

        onCallCompleted: function() {
            this.model.generalData.set({"_loading": false, "_completed": true})
        },

        getSeries: function(filter, comparison) {
            var defaultSeries = {series: [{filter: filter}]};

            switch (comparison) {
                case 'tags':
                    var tags = getTagsInFilter(filter).include;
                    if (!tags.length) {
                        return defaultSeries;
                    } else {
                        return {
                            series: tags.map(function (t) {
                                return {
                                    label: this.tagMap[t].name,
                                    filter: "(" + filter + ") and tag is " + t
                                }
                            }.bind(this))
                        }
                    }

                case 'topics':
                    var topics = getTopicsInFilter(filter).include;
                    if (!topics.length) {
                        return defaultSeries;
                    } else {
                        return {
                            series: topics.map(function (t) {
                                return {
                                    label: this.tagMap[t].name,
                                    filter: "(" + filter + ") and topic is " + t
                                }
                            }.bind(this))
                        }
                    }

                case 'none':
                default:
                    return defaultSeries;
            }
        },

        calcDerivedStats: function(s) {
            if (!this.brandMap) return;
            var series = this._data;
            if (!series) return;

            var numCols = series.series.length;
            var noData = true;
            for (var i = 0; i < numCols; i++) {
                if (series.series[i]._data) {
                    noData = false;
                    break;
                }
            }
            // this renders the old data until the first bit of new data is available to reduce flicking
            if (noData) return;

            // convert the _rows on each series and the brandMap into a simple rows and cols structure for easy
            // rendering
            // [{ label: ..., cols: [{ }, { }, ...]], { label: ... } ]
            var rowMap = {};
            var showVolume = this.model.get('show-volume');
            var showSentiment = this.model.get('show-sentiment');
            var footnote;

            var allSeriesLoaded = true;
            var maxVol = 1;
            for (i = 0; i < numCols; i++) {
                var data = series.series[i]._data;
                if (!data) {
                    allSeriesLoaded = false;
                } else {
                    for (var j = 0; j < data.length; j++) {
                        var v = data[j].mentionCount;
                        if (v > maxVol) maxVol = v;
                    }
                }
            }

            var maxSentimentPercentage = 1;
            for (i = 0; i < numCols; i++) {
                data = series.series[i]._data;
                if (!data) continue;

                var brands = extractBrands(series.series[i].filter);

                for (j = 0; j < data.length; j++) {
                    var d = data[j];
                    var brandId = d.brand.id;
                    if (brands.isBrandExcluded(brandId)) continue;

                    var row = rowMap[brandId];
                    if (!row) {
                        var brand = this.brandMap[brandId];
                        rowMap[brandId] = row = {label: brand ? (brand.shortName || brand.name) : brandId, cols: new Array(numCols)};
                    }
                    row.cols[i] = d;

                    d.showVolume = showVolume;
                    d.showSentiment = showSentiment;

                    d.volume = d.mentionCount;
                    d.volumePercentage = percentage(d.mentionCount, maxVol);
                    d.volumeRadius = round3(percentage(Math.sqrt(d.mentionCount / Math.PI), Math.sqrt(maxVol / Math.PI)));
                    if (d.volumeRadius < 4) d.volumeRadius = 4;
                    d.volumeOffset = round3((100 - d.volumeRadius) / 2);

                    d.volumeFontSize = 10 + Math.floor(d.volumeRadius / 100 * 3);

                    // use JS for this instead of CSS because phantomjs (for image saving) doesn't support transform
                    var circleSz = 50;      // px (must match css)
                    var lineHeight = 15;    // px (must match css)
                    if (d.volumeFontSize * 2.1 < d.volumeRadius / 100 * circleSz) {
                        d.volumeNumCls = "center";
                        d.volumeBottom = (circleSz + lineHeight) / 4 - 1;
                    } else {
                        d.volumeBottom = 25 + (d.volumeRadius / 100) * circleSz / 2  - 3;
                    }

                    if (showSentiment) footnote = "Verified data used for sentiment";

                    var sn = d.sampleSize ? d.sampleSize : d.mentionCount;
                    d.posPercentage = percentage(d.totalPositive, sn);
                    d.negPercentage = percentage(d.totalNegative, sn);

                    if (d.posPercentage > maxSentimentPercentage) maxSentimentPercentage = d.posPercentage;
                    if (d.negPercentage > maxSentimentPercentage) maxSentimentPercentage = d.negPercentage;

                    var aw, moe;
                    if (d.totalPositive) {
                        aw = adjustedWald(d.sampleSize, d.totalPositive);
                        moe = calcMarginOfError(aw.high, aw.low, d.totalPositive);
                        d.posPercentageMOE = percentage(moe, d.sampleSize);
                    }
                    if (d.totalNegative) {
                        aw = adjustedWald(d.sampleSize, d.totalNegative);
                        moe = calcMarginOfError(aw.high, aw.low, d.totalNegative);
                        d.negPercentageMOE = percentage(moe, d.sampleSize);
                    }
                }

                _.each(rowMap, function(row) {
                    if (!row.cols[i]) row.cols[i] = { }
                });
            }

            for (i = 0; i < numCols; i++) {
                data = series.series[i]._data;
                if (!data) continue;
                for (j = 0; j < data.length; j++) {
                    d = data[j];
                    d.posBarSize = percentage(d.posPercentage, maxSentimentPercentage);
                    d.negBarSize = percentage(d.negPercentage, maxSentimentPercentage);
                }
            }

            // extract the rows from the map and sort by label
            var rows = this.model.get('_rows');
            rows.length = 0;
            _.each(rowMap, function(row) { rows.push(row) });
            rows.sort(function(a, b) { return a.label < b.label ? -1 : a.label > b.label ? +1 : 0 });

            if (allSeriesLoaded) {
                Beef.Footnotes.clearFootnotes(this);
                if (footnote && this.model.get('show-footnote')) Beef.Footnotes.addFootnote(this, footnote);
            }
        },

        templateHelpers: function() {
            var series = this._data;

            return {
                noData: function() {
                    if (!series) return true;
                    for (var i = 0; i < series.series.length; i++) if (series.series[i]._data) return false;
                    return true;
                },

                footerColspan: function() {
                    if (this['swap-axis']) return this._rows.length + (series.series.length > 1 ? 1 : 0);
                    return series.series.length + 1;
                },

                hasRowLabels: function() {
                    return !this['swap-axis'] || series.series.length  > 1;
                },

                hasColumnLabels: function() {
                    return this['swap-axis'] || series.series.length  > 1;
                },

                columnLabels: function() {
                    var labels = [], i;
                    if (this['swap-axis']) {
                        var rows = this._rows;
                        for (i = 0; i < rows.length; i++) labels.push(rows[i].label);
                    } else {
                        var series = series.series;
                        for (i = 0; i < series.length; i++) labels.push(series[i].label);
                    }
                    return labels;
                },

                rows: function() {
                    var rows = this._rows;
                    if (rows && rows.length > 0 && this['swap-axis']) {
                        var series = series.series;   // each series becomes a row instead of a col
                        var newRows = [];
                        for (var i = 0; i < series.length; i++) {
                            var newRow = { cols: [] };
                            if (series.length > 1) newRow.label = series[i].label;
                            for (var j = 0; j < rows.length; j++) newRow.cols.push(rows[j].cols[i]);
                            newRows.push(newRow);
                        }
                        rows = newRows;
                    }
                    return rows;
                }
            }
        }
    });

    var percentage = function(a, b) {
        if (!Number.isFinite(a) || !Number.isFinite(b)) return 0;
        return b ? a * 100 / b : 0;
    };

    var round3 = function(v) {
        return v ? Math.floor(v * 1000) / 1000 : v;
    };

    var comparisonPicker = Beef.DynamicPicker.create({
        "none": {name: "Nothing", description: "Do not compare anything"},
        "tags": {name: "Tags", description: "Compare your brands by the tags in your filter"},
        "topics": {name: "Topics", description: "Compare your brands by the topics in your filter"}
    });

    this.SettingsView = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/stats/BrandMatrixSettings.handlebars"),

        attributes: { 'class': 'stats-settings brand-matrix-settings' },

        editAttributes: function() { return defaultOnAttrs.concat('swap-axis', 'sampled-sentiment', 'comparison') },

        events: {
            "change .checkbox": "checkboxChanged",
            "click .nav-tabs a": "tabClicked"
        },

        bindings: function() {
            return {
                'comparison': { converter: comparisonPicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            comparisonPicker.attach(this, ".comparison", "comparison");

            _.each(this.model.attributes, function(value, key){
                if (value) this.$('.' + key).attr('checked', value);
            }, this);
        },

        checkboxChanged: function(ev) {
            var $t = $(ev.target).closest("input");
            var key = $t.attr('class');
            if (key) {
                this.model.set(key, $t.is(":checked"));
            }
        },

        tabClicked: function(ev) {
            ev.preventDefault();
            var $t = $(ev.target).closest("a");
            $t.closest(".nav-tabs").find("li").toggleClass('active', false);
            $t.closest("li").toggleClass('active', true);
            var tab = $t.attr('data-tab');
            this.$('.stats-tabs > div').hide();
            this.$('.' + tab).show();
        },

        showHideCheckboxes: function() {
            var ss = this.model.get('sampled-sentiment');
            this.$('tbody.unless-sampled-sentiment').toggle(ss == "false" || ss == null);
            this.$('tbody.if-sampled-sentiment').toggle(ss == "auto" || ss == "true");
        }
    });

    function calcMarginOfError(high, low, count) {
        return Math.max(count - low, high - count);
    }
});