<template>
    <div v-once class="explore-no-topics-message animated fadeIn">
        <slot>
            <span>No trending topics</span>
        </slot>
    </div>
</template>


<script>
export default {

}
</script>


<style scoped lang="sass">

.explore-no-topics-message
    height: 80%
    width: 100%
    box-sizing: border-box
    display: flex
    place-items: center
    justify-content: center
    font-style: italic
    color: var(--be-colour-muted-text-dark)

</style>