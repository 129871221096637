import Vue from 'vue'

/**
 * Renders a Vuejs component inside a Marionette view.
 */
Beef.module("VuejsView").addInitializer(function(_startupOptions) {

    let lastId = 0

    /**
     * Pass { component: YourVueComponent }.
     */
    this.View = Backbone.Marionette.ItemView.extend({

        initialize: function() {
            this.id = "vuejs-" + (++lastId)
        },

        getTemplate() {
            return () => '<div id="' + (this.id) + '"></div>'
        },

        onRender() {
            const ComponentClass = Vue.extend(this.options.component)
            this.vm = new ComponentClass({propsData: this.options.props})
            setTimeout(() => { this.vm.$mount("#" + this.id) }, 1)
        },

        onClose() {
            this.vm.$destroy()
        }
    });

});

