import {DATE_RANGE, parseDate} from "@/app/utils/Dates";

/**
 * @param {Date} start
 * @param {Date} end
 * @param {boolean} [allowLong]
 */
export function formatPublishedLabel(start, end, allowLong) {
    if (start && end) {
        const bothStart = start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 0 && end.getMinutes() === 0;
        let formatString = bothStart ? 'd MMM yyyy' : 'd MMM yyyy, HH:mm ';

        if (start.getYear() === end.getYear() && start.getMonth() === end.getMonth() && start.getDate() === end.getDate()) {
            // Here, we can munge a lot together.
            if (!bothStart)  {
                return start.toString(formatString) + " - " + end.toString("HH:mm");
            }
            else {
                return start.toString(formatString);
            }
        }

        let message = start.toString(formatString) + ' - ' + end.toString(formatString);
        if (message.length > 39 && !allowLong) {
            formatString = "yyyy/MM/dd HH:mm";
            return start.toString(formatString) + " - " + end.toString(formatString);
        }
        return message;
    }
    else return "";
}

/**
 * Converts a published filter into a published picker label.
 * @param {string} filter A published filter.
 * @param {string} prefix An optional label prefix to override 'Published '.
 * @return {string} A published filter label, or an empty string if the parameter is falsey.
 */
export function publishedToLabel(filter, prefix= "Published ") {
    let label = "";
    if (filter) {
        const a = filter.split("-");
        const startDate = parseDate(a[0]);
        const endDate = parseDate(a[1]);
        label = prefix + formatPublishedLabel(startDate, endDate);
    }
    return label;
}


/**
 * Converts published values from the basic filter to a tag.
 * @deprecated This is to support old backbone code.
 */
export function publishedTagConverter(direction, value, attributeName, model, boundEls, prefix) {
    const fn = DATE_RANGE[value];
    let label;
    if (fn) {
        label = (prefix || 'Published ') + fn().label.toLowerCase();
    } else if(value) {
        label = publishedToLabel(value, prefix);
    } else {
        label = "Select a date range"
    }
    this.boundEls[0].textContent = label;
    return label;
}