import {deprecatedFetchPhrases} from "@/data/DeprecatedBeefCache";
import {escapeExpression} from "@/app/utils/StringUtils";
import {currentAccountCode} from "@/app/utils/Account";
import {parsePhraseString} from "@/app/utils/Phrases";

export default function(phraseId, options) {
    const cache = {};

    let phrases = phraseId.split(" ");
    let span = "";
    phrases.forEach(curPhraseId => {
        let tempSpan = `<span class='phrase' data-id='${curPhraseId}'>${curPhraseId}</span> `;
        deprecatedFetchPhrases(currentAccountCode(), function() {
            const phrase = cache.phrases[curPhraseId] || `«unknown-${curPhraseId}»`;
            const a = parsePhraseString(phrase);
            const tags = [];
            for (let i = 0; i < a.length; i++) {
                const code = a[i];
                const neg = code.charAt(0) === '-';
                const name = escapeExpression(code);
                tags.push("<span class='tag" + (neg ? " tag-neg" : "") + "'>" + name + "</span>");
            }
            // In case this is returned in time.
            tempSpan = `<span class='phrase' data-id='${curPhraseId}'>${tags.join("")}</span> `
        }.bind(this), cache);

        span += tempSpan
    })

    return new Handlebars.SafeString(span);
}