var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"control-group perms control-group-spacer\">\n                    <label>Permission Level</label>\n                    <div class=\"btn-group permissions\">\n                        <button class=\"btn online\" data-value=\"FULL\"\n                                title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"FULL") : stack1)) != null ? lookupProperty(stack1,"descr") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"FULL") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</button>\n                        <button class=\"btn offline\" data-value=\"READ\"\n                                title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"READ") : stack1)) != null ? lookupProperty(stack1,"descr") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"READ") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</button>\n                        <button class=\"btn offline\" data-value=\"REPORTING\"\n                                title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"REPORTING") : stack1)) != null ? lookupProperty(stack1,"descr") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"REPORTING") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</button>\n                        <button class=\"btn offline\" data-value=\"ADMIN\"\n                                title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"ADMIN") : stack1)) != null ? lookupProperty(stack1,"descr") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PERMISSIONS") : depth0)) != null ? lookupProperty(stack1,"ADMIN") : stack1)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</button>\n                    </div>\n                </div>\n\n                <div class=\"control-group control-group-spacer\">\n                    <label>View Private Mentions</label>\n                    <div class=\"btn-group privateMentions\">\n                        <button class=\"btn online\" data-value=\"ALL\"\n                                title=\"Can view all private data\">All</button>\n                        <button class=\"btn offline\" data-value=\"NONE\" style=\"margin-right: 8px\"\n                                title=\"Can only view public mentions\">None</button>\n                        <button class=\"btn online\" data-value=\"DIRECT_MESSAGE\"\n                                title=\"Direct messages\">DMs</button>\n                        <button class=\"btn offline\" data-value=\"CHAT\"\n                                title=\"Chat logs\">Chats</button>\n                        <button class=\"btn offline\" data-value=\"INTERNAL\"\n                                title=\"Internal company data e.g surveys\">Internal</button>\n                        <button class=\"btn offline\" data-value=\"EMAIL\"\n                                title=\"Company emails\">Emails</button>\n                    </div>\n                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"teams") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":60,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"control-group control-group-spacer teams\">\n                    <label>Teams</label>\n                    <section class=\"team-scroller dark-scrollbars\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"teams") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":57,"column":33}}})) != null ? stack1 : "")
    + "                    </section>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label class=\"checkbox\" style=\"margin-left: 16px\">\n                                <input type=\"checkbox\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":70},"end":{"line":54,"column":100}}})) != null ? stack1 : "")
    + ">\n                                <span class=\"name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span><span class=\"description\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</span>\n                            </label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row-fluid\">\n    <div class=\"span12\">\n        <fieldset>\n            <div class=\"row-fluid\">\n                <div class=\"control-group span6\">\n                    <label>First Name</label>\n                    <div class=\"controls\"><input dir=\"auto\" class=\"span12\" type=\"text\" name=\"firstName\"/></div>\n                </div>\n                <div class=\"control-group span6\">\n                    <label>Last Name</label>\n                    <div class=\"controls\"><input dir=\"auto\" class=\"span12\" type=\"text\" name=\"lastName\"/></div>\n                </div>\n            </div>\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":61,"column":26}}})) != null ? stack1 : "")
    + "        </fieldset>\n    </div>\n\n</div>\n\n\n";
},"useData":true});