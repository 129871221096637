/**
 * Base class for stores. Makes sure there is only one instance of an object for a given id.
 * @deprecated
 */
export default class ObjectStore {

    constructor() {
        this.list = null
        this.byId = { }
    }

    init(startupOptions) {
        this.accountCode = startupOptions.account.code
    }

    /**
     * Add an object to the store overwriting any existing object with the same id. Returns the object or the
     * existing object.
     */
    add(o) {
        let e = this.byId[o.id]
        if (e) Object.assign(e, {_loading: false}, o)
        else e = this.byId[o.id] = o
        return e
    }

    indexOf(id) {
        for (let i = 0; i < this.list.length; i++) if (this.list[i].id === id) return i;
        return -1;
    }
}