var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <strong><span class=\"number\">"
    + alias3((lookupProperty(helpers,"tooltipPercent")||(depth0 && lookupProperty(depth0,"tooltipPercent"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),{"name":"tooltipPercent","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":56}}}))
    + "</span> "
    + alias3(((helper = (helper = lookupProperty(helpers,"yName") || (depth0 != null ? lookupProperty(depth0,"yName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"yName","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":73}}}) : helper)))
    + "</strong>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <strong><span class=\"number\">"
    + alias3((lookupProperty(helpers,"tooltipNumber")||(depth0 && lookupProperty(depth0,"tooltipNumber"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),(depth0 != null ? lookupProperty(depth0,"yDecimal") : depth0),{"name":"tooltipNumber","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":64}}}))
    + "</span> "
    + alias3(((helper = (helper = lookupProperty(helpers,"yName") || (depth0 != null ? lookupProperty(depth0,"yName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"yName","hash":{},"data":data,"loc":{"start":{"line":7,"column":72},"end":{"line":7,"column":81}}}) : helper)))
    + "</strong>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    (for "
    + alias4(((helper = (helper = lookupProperty(helpers,"compareName") || (depth0 != null ? lookupProperty(depth0,"compareName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"compareName","hash":{},"data":data,"loc":{"start":{"line":12,"column":9},"end":{"line":12,"column":24}}}) : helper)))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"compare") || (depth0 != null ? lookupProperty(depth0,"compare") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"compare","hash":{},"data":data,"loc":{"start":{"line":12,"column":33},"end":{"line":12,"column":44}}}) : helper)))
    + "</strong>)\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>\n        <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"sizeName") || (depth0 != null ? lookupProperty(depth0,"sizeName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"sizeName","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":28}}}) : helper)))
    + "</strong>\n        is\n        <strong>\n            <span class=\"number\">\n                "
    + alias3((lookupProperty(helpers,"formatSize")||(depth0 && lookupProperty(depth0,"formatSize"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sizeVal") : depth0),{"name":"formatSize","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":38}}}))
    + "\n            </span>\n        </strong>\n    </p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <br>\n        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"yName") || (depth0 != null ? lookupProperty(depth0,"yName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yName","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":25}}}) : helper)))
    + "</strong> is defined as: "
    + alias4(((helper = (helper = lookupProperty(helpers,"yDefinition") || (depth0 != null ? lookupProperty(depth0,"yDefinition") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yDefinition","hash":{},"data":data,"loc":{"start":{"line":30,"column":50},"end":{"line":30,"column":65}}}) : helper)))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--We have two major cases: y-value is a mention count or percent, or something else.-->\n\n<!--Example: 25 mentions are for Thursday, Sept 1, 2019 -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"xIsPercent") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + alias4(((helper = (helper = lookupProperty(helpers,"datePeriod") || (depth0 != null ? lookupProperty(depth0,"datePeriod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"datePeriod","hash":{},"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":10,"column":14}}}) : helper)))
    + " <strong class=\"number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"xVal") || (depth0 != null ? lookupProperty(depth0,"xVal") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"xVal","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":46}}}) : helper)))
    + "</strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"compare") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"yDefinition") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":32,"column":7}}})) != null ? stack1 : "");
},"useData":true});