export default function(tb, l, n, block) {
    var accum = '';
    for(var i = ((l - 1) * tb); i <= n; ++i) {
        if (i > l * tb) {
            accum += block.fn({id: 'more', name: '...'});
            break;
        }
        if (i == ((l - 1) * tb) && i != 0) {
            accum += block.fn({id: 'less', name: '...'});
        } else if (i != 0) accum += block.fn({id: i, name: i});
    }

    return accum;
}
