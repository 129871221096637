var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <label class=\"span12 option\"><input type=\"radio\" name=\"action\" value=\"ENGAGEMENT_CONSOLE\">\n                        <span class=\"action-option\"> Control DataEQ Engage processing</span>\n                    </label>\n                    <div class=\"span12 explanation\">\n                        Select which mentions should be sent to DataEQ Engage, choose team, set priority and\n                        other options.\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row-fluid\">\n    <div class=\"span10\">\n        <div class=\"control-group action-options\">\n            <label>What will this rule be used for?</label>\n\n                <label class=\"span12 option\"><input type=\"radio\" name=\"action\" value=\"MACRO\">\n                    <span class=\"action-option\"> Edit mentions</span>\n                </label>\n                <div class=\"span12 explanation\">\n                    Automatically edit attributes of mentions as they come into the account.<br>\n                    For example, use this option to set your brand's posts as enterprise, add tags to mentions,\n                    or assign a credibility score to your Facebook page.\n                </div>\n\n                <label class=\"span12 option\"><input type=\"radio\" name=\"action\" value=\"CROWD\">\n                    <span class=\"action-option\"> Verify specific mentions through the Crowd</span>\n                </label>\n                <div class=\"span12 explanation\">\n                    Select mentions that will receive crowd verification\n                    (the rest will be automatically sorted by DataEQ's algorithm) – for example, only verify\n                    mentions about a specific brand.\n                </div>\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"sendToEngagementConsole",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":32,"column":30}}})) != null ? stack1 : "")
    + "\n                <label class=\"span12 option\"><input type=\"radio\" name=\"action\" value=\"DISCARD\">\n                    <span class=\"action-option\"> Filter out unwanted mentions</span>\n                </label>\n                <div class=\"span12 explanation\">\n                    Delete mentions that are not relevant to what you're searching for.\n                    For example, if you are only interested in a specific country's conversation, set up a rule to\n                    delete other countries from your account.\n                </div>\n\n                <label class=\"span12 option\"><input type=\"radio\" name=\"action\" value=\"RELEVANT\">\n                    <span class=\"action-option\"> Ensure certain mentions are not deleted</span>\n                </label>\n                <div class=\"span12 explanation\">\n                    Identify mentions are always of interest to you (relevant). DataEQ\n                    will never send this conversation to the trash unless it is sorted by a human or deleted by\n                    another rule. For example, set mentions from your own Twitter handle to always appear in the account.\n                </div>\n        </div>\n        <div class=\"irrelevant-rule control-group\" style=\"display: none;\">\n            <label>Setting relevancy to irrelevant has been deprecated</label>\n            <div class=\"explanation\">\n                This rule isn't compatible with the latest version of DataEQ. To make things simpler, unwanted\n                mentions are now sent to trash. You will have access to these mentions for 3 days before they are\n                permanently deleted. <br><br>\n\n                <b>Click next if you would like to change this to a discard rule.</b>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});