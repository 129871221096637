<template>
    <div class="css-spinner" :style="style">
        <div></div><div></div><div></div><div></div>
    </div>
</template>

<script>
    export default {
        name: "SpinnerComponent",

        props: {
            size: {
                type: Number,
                default: 48
            }
        },

        computed: {
            style() {
                const s = {'--css-spinner-size': this.size + 'px'};
                if (this.size <= 20) {s['--css-wedge-size'] = '3px';}
                return s;
            }
        }
    }
</script>

<style scoped>

</style>