/**
 * Select one or more media options. Fires a change event when the selection is changed. Also provides a model binder
 * converter to display the selected options in an element.
 */
import {VISIBILITY_ITEMS} from "@/app/utils/Visibility";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("VisibilityPicker").addInitializer(function(startupOptions) {

    this.items = VISIBILITY_ITEMS;

    this.View = Beef.Picker.View.extend({
        attributes: { class: "visibility-picker" },
        template: require("@/dashboards/filter/pickers/visibility/VisibilityPicker.handlebars"),
        items: this.items
    });

    this.converter = createTagConverter({
        tooltip: function(code) {
            return VISIBILITY_ITEMS[code] + " visibility";
        },
        items: this.items,
        placeholder: "Visibility"
    });

    /**
     * Attach a visibility picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
