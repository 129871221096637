<template>
    <span class="be-tag"
          :class="{'no-close': noClose, 'be-tag--disabled': disabled, negated}"
          @mouseenter="showTooltip(tooltip, $event)"
          @click="$emit('click', $event)">
        <slot/>
        <span v-if="!noClose"
              @mouseenter="!disabled ? showTooltip(closeTooltip, $event) : null"
              class="be-tag__close"
              @click.stop="closeClicked">×</span>
        </span>
</template>

<script>
import {closeCurrentTooltip, showTooltipText} from "../tooltip/TooltipUtilities";

    export default {
        props: {
            noClose: {
                type: Boolean,
                default: false
            },
            tooltip: String,
            closeTooltip: {
                type: String,
                default: 'Click to remove'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            negated: {          // Tells the tag to take on our "negated" tag colour. Does not add a minus sign.
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                tooltipShown: null
            }
        },

        beforeDestroy() {
            // If we haven't ourselves shown a tooltip, we do not want to
            // cancel showing other people's tooltips.
            this.tooltipShown?.close();
        },

        methods: {
            showTooltip(text, ev) {
                this.$emit('mouseenter', ev);
                if (!text || !text.trim().length) return;
                this.$nextTick(() => {
                    closeCurrentTooltip();
                    this.tooltipShown = showTooltipText(ev.target, text, {
                        onTooltipCreated: tooltip => this.tooltipShown = tooltip
                    });
                })
            },

            closeClicked() {
                if (this.disabled) return;
                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="sass">
    .be-tag
        box-sizing: border-box
        cursor: default
        display: inline-flex
        align-items: center
        background-color: var(--colour-tag-background)
        padding-left: 5px
        border-radius: 3px
        border-left: 1px solid #3a3a3a
        border-top: 1px solid #3a3a3a
        min-width: 30px
        color: white
        &:not(.be-tag--disabled)
            box-shadow: 0px 0px 1px rgba(80, 80, 80, 1), 2px 2px 2px rgba(20, 20, 20, 1)
        &:hover
            .symbol-warning
                color: orange

        &.negated
            color: var(--be-colour-negated)



    .no-close
        padding-right: 5px !important

    .be-tag__close
        outline: none
        transition: color var(--transition-duration)
        padding: 0 3px
        font-weight: 700
        font-size: 16px
        color: #666
        cursor: pointer
        margin-left: auto
        &:hover
            color: var(--be-colour-text-dark__hover)

    .be-tag--disabled .be-tag__close
        color: #444
        cursor: not-allowed



</style>