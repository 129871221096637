var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "open";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <div>\n                        <label>\n                            <input type=\"checkbox\" name=\"dissallowReshares\">\n                            Do not collect reshares\n                        </label>\n\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n    <div class=\"span12\">\n        <div class=\"control-group\">\n            <label>I am only interested in conversation from these locations</label>\n            <div class=\"controls\">\n                <span class=\"location tag-input uneditable-input span12\">\n                    <span name=\"location\"></span>\n                    <input class=\"tag popup-menu\" type=\"text\"/>\n                </span>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"row-fluid\">\n    <div class=\"span12\">\n        <details "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"detailOpen") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":17},"end":{"line":17,"column":46}}})) != null ? stack1 : "")
    + ">\n            <summary>\n                Advanced options\n            </summary>\n            <div style=\"box-sizing: border-box; padding-inline: 1rem\">\n                <div class=\"control-group\">\n                    <label>Author-bio Location</label>\n                    <div class=\"controls\">\n                        <div class=\"author-location\"></div>\n                    </div>\n                </div>\n\n                <div class=\"twitter-sample\" ></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideReshare") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":39,"column":27}}})) != null ? stack1 : "")
    + "\n            </div>\n\n        </details>\n\n    </div>\n</div>\n\n<div class=\"row-fluid\">\n    <div class=\"span12\">\n        <div class=\"controls country-options\">\n            <label>Only apply to</label>\n            <label class=\"checkbox\"><input type=\"checkbox\" name=\"twitter\"> X Posts</label>\n            <label class=\"checkbox\"><input type=\"checkbox\" name=\"webhose\"> News sites &amp; blogs</label>\n        </div>\n        <div class=\"control-group country-options-help\">\n            <label>\n                It is recommended that these boxes should be left ticked or \"Unknown\" should be added to\n                the country list. Mentions from Facebook and other sources often have no country and will be deleted\n                otherwise.\n            </label>\n        </div>\n    </div>\n</div>\n\n\n\n<div class=\"row-fluid\">\n    <div class=\"span12\">\n        <div class=\"control-group control-group-spacer\">\n            <label>I am only interested in conversation in these languages</label>\n            <div class=\"controls\">\n                <span class=\"language tag-input uneditable-input span12\">\n                    <span name=\"language\"></span>\n                    <input class=\"tag popup-menu\" type=\"text\"/>\n                </span>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});