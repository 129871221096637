import { deprecatedBrandsStore } from '@/store/deprecated/Stores';
import SegmentPickerDialog from '@/app/framework/dialogs/segment-picker/SegmentPickerDialog';
import TopicTreePickerDialog from '@/app/framework/dialogs/topic-tree-picker/TopicTreePickerDialog';
import {features} from "@/app/Features";
import {editBrandsAndPhrases, isArchivedAccount, isOps} from "@/app/Permissions";
import VuexStore from "@/store/vuex/VuexStore";
import {notifyWithHtml, notifyWithText} from "@/app/framework/notifications/Notifications";
import _ from 'underscore';
import moment from "moment";
import {setTitle} from "@/app/Beef";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {account} from "@/app/utils/Account";
import EditWorkingHours from "@/setup/brands/EditWorkingHours";

Beef.module("RootBrandSetup").addInitializer(function(startupOptions) {

    var maxRootBrands = startupOptions.account.maxRootBrands;

    /**
     * Display/edit tree for a root brand.
     */
    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/setup/brands/RootBrandSetup.handlebars"),

        attributes: { class: "root-brand-setup section" },

        templateHelpers: function() {

            var brand = this.model.get("brand"),
                hasFeedsOrFilter = (brand.feeds || brand.socialNetworks || brand.filter || brand.mentionFilter) ? true : false;

            return {
                showBottomMargin: function() {
                    return  (features.brandDescription() || hasFeedsOrFilter);
                },
                hasFeedOrFilter: function() {
                    return hasFeedsOrFilter;
                }
            };
        },

        initialize: function() {
            this.accountCode = this.options.accountCode;
        },

        modelEvents: {
            "change:name": "changePageTitle",
            "change:brand": "brandUpdated"
        },

        changePageTitle: function() {
            this.updateTitle();
        },

        events: {
            "click .toggle-branch": "toggleBranch",
            "click .toggle-phrases": "togglePhrases",
            "click .expand-all": "toggleExpandAll",
            "change .phrases .phrase input": "phraseActiveChange",
            "click .edit-brand": "editBrand",
            "click .edit-working-hours": "editWorkingHours",
            "click .unarchive": "unarchiveBrand",
            "click .edit-topic-tree": "editTopicTree",
            "click .edit-segments": "editBrandSegments",
            "click .add-brand": "addBrand",
            "change .showDeleted": "showDeletedChange",
            "change .showInactive": "showInactiveChange",
            "click .edit-phrase": "editPhrase",
            "click a.add-phrase": "addPhrase",
            "click a.generate-phrases": "generatePhrases",
            "click td.warn": "warningHelp",
            "mouseenter td.warn": "warningHelp",
            "mouseleave td.warn": "cancelWarningHelp",
            "click td.vol": "showVolMentions",
            "click td.trash": "showTrashMentions",
            "click .edit-json": "editJson",
            "click .edit-root-brand-description": "editBrandDescription",
            "click .remove-brand-description": "removeBrandDescription",
            "click .remove-imported-brand": "removeImportedBrand",
            "click .edit-colour": "editColour",
        },

        onBeforeRender: function() {
            this.brandMap = {};
            this.segmentMap = {};
            this.phraseMap = {};
            this.maxLevel = 2;
            var brands = this.model.get('brands');
            for (var i = 0; i < brands.length; i++) this.initBrand(brands[i]);

            let curBrand = this.model.get('brand');
            this.initBrandSegments(curBrand);
        },

        initBrandSegments: function (brand) {
            try {
                if (brand.segmentLists) {
                    let brandSegments = JSON.parse(JSON.stringify(brand.segmentLists));
                    brandSegments.sort((a, b) => a.label + a.subtitle > b.label + b.subtitle ? 1 : -1);

                    if (brandSegments) {
                        for (let i = 0; i < brandSegments.length; i++) {
                            let curSegment = brandSegments[i];
                            let segmentType = curSegment.label;

                            if (!this.segmentMap[segmentType]) {
                                this.segmentMap[segmentType] = {
                                    active: [],
                                    inactive: []
                                }
                            }

                            let curList = curSegment.active ? this.segmentMap[segmentType].active : this.segmentMap[segmentType].inactive;

                            if (!curList.includes(curSegment.subtitle)) {
                                let seg = {
                                    subtitle: curSegment.subtitle,
                                    description: curSegment.description
                                }
                                curList.push(seg);
                            }
                        }
                    }
                    this.model.set("segmentMap", this.segmentMap);
                }
            } catch (error) {
                console.error("Unable to initialize brand segments", error);
            }
        },

        initBrand: function(brand) {
            if (brand.id) this.brandMap[brand.id] = brand;
            var _parent = brand._parent;
            if (_parent) {
                brand._level = _parent._level + 1;
                brand._ancestors = _parent._ancestors + " a" + _parent.id;
                brand._classes = "p" + _parent.id + " " + brand._ancestors;
                if (brand._level > this.maxLevel) this.maxLevel = brand._level;
                brand._canEdit = _parent._canEdit;
            } else {
                brand._level = 0;
                brand._ancestors = "";
                brand._canArchive = !brand.importedFromAccount && editBrandsAndPhrases();
                brand._canEdit = !brand.archived && !brand.importedFromAccount && editBrandsAndPhrases();
                brand._canEditTopicTree = brand._canEdit && isOps() || (!brand.importedFromAccount && editBrandsAndPhrases() && isOps() && brand.archived);
                brand._canEditRoot = brand._canEdit || brand.importedFromAccount && editBrandsAndPhrases();
            }
            var children = brand.children, i;
            brand._treeDoesMentionFilter = brand.filter && true;
            brand._treeDoesFeedFilter = (brand.feeds || brand.socialNetworks) && true;
            if (children) {
                for (i = 0; i < children.length; i++) {
                    var c = children[i];
                    c._parent = brand;
                    this.initBrand(c);
                    brand._treeDoesMentionFilter = brand._treeDoesMentionFilter || c._treeDoesMentionFilter;
                    brand._treeDoesFeedFilter = brand._treeDoesFeedFilter || c._treeDoesFeedFilter;
                }
            }
            brand._subBrandsAllowed = brand._level < this.maxLevel;
            var phrases = brand.phrases;
            if (phrases) {
                for (i = 0; i < phrases.length; i++) {
                    var p = phrases[i];
                    p._parent = brand;
                    if (p.id) this.phraseMap[p.id] = p;
                    brand._treeDoesMentionFilter = brand._treeDoesMentionFilter || (p && p.mentionFilter);
                    brand._treeDoesFeedFilter = brand._treeDoesFeedFilter || (p && (p.feeds || p.socialNetworks));
                }
            }
        },

        brandUpdated() {
            notifyWithText("The brand has been updated", null, "<i class='symbol-setup'></i>");
            this.render();
        },

        onRender: function() {
            this.updateTitle();
            setTimeout(() => this.updateSupportProfiles(), 1);
            setTimeout(() => this.updateWorkingHours(), 1);
            setTimeout(() => this.updateOtherProfiles(), 1);
            var model = this.model;
            var $colour = this.$el.find(".brand-colour");
            var accountCode = this.accountCode;
            let that = this
            $colour.spectrum({
                preferredFormat: "hex",
                allowEmpty: true,
                showInput: true,
                clickoutFiresChange: true,
                change: function (colour) {
                    var brand = model.get("brand");
                    var newColour = colour ? colour.toHexString() : "";
                    // Spectrum fires a change when changing colour from empty to empty, for some reason.
                    if (!newColour && !brand.colour) return;
                    Beef.Sync.mashPUT("accounts/" + accountCode + "/brands/" + brand.id,
                        {colour: newColour},
                        function () {
                            brand.colour = newColour;
                            that.updateColour()
                        }).fail(function () {
                        alert("Error updating brand colour");
                        $colour.spectrum("set", brand.colour);
                    });
                }
            });
            this.updateColour()
        },

        updateColour: function() {
            let colour = this.model.get("brand").colour
            this.$el.find(".colour-name").text(colour ? '' : "None")
            this.$el.find(".edit-colour").toggleClass("no-colour", !colour)
        },

        updateTitle: function() {
            var brand = this.model.get('brand');
            this.model.set('title', brand.name);
            setTitle(brand ? ((brand.shortName || brand.name) + ' Setup') : "Brand");
        },

        updateSupportProfiles() {
            let brand = this.model.get('brand')
            if (brand.supportProfileIds && brand.supportProfileIds.length) {
                let conv = Beef.ProfilePicker.converterFactory(this)
                let binding = { boundEls: [".root-brand-setup .support-profiles"] }
                conv.call(binding, null, brand.supportProfileIds.join(' '))
            } else {
                this.$el.find(".support-profiles").text("(none)")
            }
        },

        updateWorkingHours() {
            let brand = this.model.get('brand')
            let brandWorkingHours = VuexStore.getters["workingHours/brandIdToWorkingHours"].get(brand.id);

            if (brandWorkingHours) {
                let range = []

                let days =[{key: "monday", label: "Monday", order: 1}, {key: "tuesday", label: "Tuesday", order: 2},{key: "wednesday", label: "Wednesday", order: 3},
                     {key: "thursday", label: "Thursday", order: 4},{key: "friday", label: "Friday", order: 5},{key: "saturday", label: "Saturday", order: 6},{key: "sunday", label: "Sunday", order: 7}];

                days.forEach(day => {
                    let curDayWorkingHours = brandWorkingHours.weekWorkTimes[day.key];
                    if (curDayWorkingHours) {
                        let newRange = true;
                        let lastRange = range.at(-1);

                        if (lastRange) {
                            if (lastRange.startTime === curDayWorkingHours.startTime && lastRange.endTime === curDayWorkingHours.endTime) {
                                if (day.order - lastRange.days.at(-1)?.order === 1) {
                                    lastRange.days.push(day);
                                    newRange = false;
                                }
                            }
                        }

                        if (newRange) {
                            range.push({
                                startTime: curDayWorkingHours.startTime,
                                endTime: curDayWorkingHours.endTime,
                                days: [day]
                            })
                        }
                    }
                });

                let description = [];
                // build range string
                if (range.length) {
                    range.forEach(curRange => {
                        let is24Hours = curRange.startTime === "00:00:00" && curRange.endTime === "24:00:00"
                        let startTime = curRange.startTime.substring(0, curRange.startTime.length - 3);
                        let endTime = curRange.endTime.substring(0, curRange.endTime.length - 3);

                        if (curRange.days.length === 1) {
                            let day = curRange.days.at(0);
                            description.push(`${day.label} ${is24Hours ? '24 hours' : `${startTime} till ${endTime}`}`);
                        } else {
                            let firstDay = curRange.days.at(0);
                            let lastDay = curRange.days.at(-1);

                            description.push(`${firstDay.label} - ${lastDay.label} ${is24Hours ? '24 hours' : `${startTime} till ${endTime}`}`);
                        }
                    });
                }

                if (brandWorkingHours.holidayCalendar) {
                    description.push(`using holiday calendar: ${brandWorkingHours.holidayCalendar.title}`)
                }

                if (range.length) {
                    this.$el.find(".working-hours").text(description.join(" | "));
                } else {
                    this.$el.find(".working-hours").text("(none)")
                }
            } else {
                this.$el.find(".working-hours").text("(none)")
            }
        },

        updateOtherProfiles() {
            let brand = this.model.get('brand')
            if (brand.otherProfileIds && brand.otherProfileIds.length) {
                let conv = Beef.ProfilePicker.converterFactory(this)
                let binding = { boundEls: [".root-brand-setup .other-profiles"] }
                conv.call(binding, null, brand.otherProfileIds.join(' '))
            } else {
                this.$el.find(".other-profiles").text("(none)")
            }
        },

        toggleBranch: function(ev) {
            var $t = $(ev.target).closest(".toggle-branch");
            var $b = $t.closest("tbody");
            var collapse = !$b.hasClass("children-collapsed");
            $b.toggleClass("children-collapsed", collapse);
            // 'a{id}' selects all our descendants
            // 'p{id}' selects only our children
            // 'ab{id}' selects our add-brand row
            var id = $b.attr("data-brand");
            var $table = $b.parent();
            // when expanding only expand our children and not all of our descendants
            $table.find("tbody." + (collapse ? "a" : "p") + id).toggleClass("branch-collapsed", collapse);
            if (collapse) {
                // make sure all of our descendants know they are collapsed
                var $desc = $table.find("tbody.brand.a" + id);
                $desc.toggleClass("children-collapsed", true);
                $desc.toggleClass("phrases-collapsed", true);
            } else {
                // make sure our add-brand row is expanded
                $table.find("tbody.add-brand.ab" + id).toggleClass("branch-collapsed", false);
                // don't expand descendant add-brand rows or phrases
                $table.find("tbody.add-brand.p" + id).toggleClass("branch-collapsed", true);
                $table.find("tbody.phrases.p" + id).toggleClass("branch-collapsed", true);
            }
        },

        togglePhrases: function(ev) {
            var $t = $(ev.target).closest(".toggle-phrases");
            var $b = $t.closest("tbody");
            var collapse = !$b.hasClass("phrases-collapsed");
            $b.toggleClass("phrases-collapsed", collapse);
            // 'phrases{id}' selects our phrases
            var id = $b.attr("data-brand");
            var $table = $b.parent();
            var sel = "tbody.phrases" + id;
            $table.find(sel).toggleClass("branch-collapsed", collapse);
        },

        toggleExpandAll: function(ev) {
            var $t = $(ev.target).closest(".expand-all");
            var collapse = $t.hasClass("expanded");
            var $brands = this.$('tbody.brand');
            $brands.toggleClass("phrases-collapsed", collapse);
            $brands.toggleClass("children-collapsed", collapse);
            $brands.toggleClass("branch-collapsed", collapse);
            this.$("tbody.phrases").toggleClass("branch-collapsed", collapse);
            $t.toggleClass("expanded", !collapse);

            if (collapse) { // don't collapse the root brand
                var $root = this.$('tbody.brand.level0');
                $root.toggleClass("children-collapsed", false);
                $root.toggleClass("branch-collapsed", false);
                this.$('tbody.brand.level1').toggleClass("branch-collapsed", false);
            }
        },

        phraseActiveChange: function(ev) {
            var $t = $(ev.target);
            var active = $t.is(":checked");
            if (active && account().maxPhrases != null && this.activePhrases() >= account().maxPhrases) {
                $t.attr("checked", null);
                this.phraseLimitReached(ev);
                return;
            }
            if (!editBrandsAndPhrases()) {
                $t.attr("checked", active ? null : "true");
                var msg;
                if (isArchivedAccount()) {
                    msg = "This account has been archived so its brands and phrases may not be edited"
                } else {
                    msg = "You do not have permission to edit brands and phrases for this account";
                }
                window.alert(msg);
                return;
            }
            var phraseId = $t.closest("tr").attr("data-phrase");
            this.updatePhrase(phraseId, { active: active });
            this.phraseMap[phraseId].inactive = !active;
        },

        updatePhrase: function(id, attrs) {
            Beef.Sync.mashPUT("accounts/" + this.accountCode + "/phrases/" + id, attrs);
        },

        editWorkingHours: function() {
            let brand = this.model.get('brand');

            let dialog = showDialog(EditWorkingHours, {brand: brand});
            dialog.$on('update-working-hours', brandId => {
                if (brandId === brand.id) {
                    this.updateWorkingHours();
                }
            });

        },

        editBrand: function(ev) {
            var $t = $(ev.target);
            var $b = $t.closest("tbody.brand");
            if ($b.length == 0) {   // editing root brand through section title click
                $b = this.$el.find("tbody.brand.level0");
            }
            var brandId = $b.attr("data-brand");
            var brand = this.brandMap[brandId];
            if (brand.importedFromAccount) return this.editImportedBrand(brand)

            var parent = brand._parent;

            if ($b.hasClass("deleted")) {
                // Check if the root it belongs to is deleted.
                var root = brand._parent;
                while (root && root._parent) {
                    root = root._parent;
                }

                var undeletedCount = _(this.model.get('brands')).filter(function(b) { return !b.deleted; }).length;
                if ((!parent || root.deleted) && undeletedCount >= maxRootBrands) {
                    Beef.LimitReached.show({target: this.$el}, maxRootBrands, "root brand", "root brands");
                    return;
                }

                this.$el.find(".overlay").show();
                $t.closest("td.edit-brand").html("<span class='spinner'></span>");
                // this endpoint returns the complete brand tree on delete - we could work with just parts and update
                // the DOM but is a lot of bug-prone work just for a rarely used function
                VuexStore.dispatch("refreshBrands", true);
                Beef.Sync.mashPUT("accounts/" + this.accountCode + "/brands/" + brandId +
                        "?returnTree=true" +
                        "&includeDeleted=" + this.model.get('_showDeleted') +
                        "&includeInactive=" + (this.model.get('_showInactive') ? true : false),
                    {deleted: false}, function (data){ this.reset(data); }.bind(this));
                return;
            }

            let _parentSubPhraseMatching = false
            for (let p = brand._parent; p; p = p._parent) {
                if (p.subPhraseMatching) {
                    _parentSubPhraseMatching = true
                    break
                }
            }

            var model = new Beef.EditBrand.Model({
                id: brandId,
                name: brand.name,
                shortName: brand.shortName,
                category: brand.category,
                colour: brand.colour,
                mentionFilter: brand.mentionFilter,
                feeds: brand.feeds,
                socialNetworks: brand.socialNetworks,
                subPhraseMatching: brand.subPhraseMatching,
                _parentSubPhraseMatching: _parentSubPhraseMatching,
                supportProfileIds: brand.supportProfileIds ? brand.supportProfileIds.join(" ") : "",
                otherProfileIds: brand.otherProfileIds ? brand.otherProfileIds.join(" ") : "",
                _level: brand._level,
                _parent: parent ? parent.name : null,
                _parentId: parent ? parent.id : null,
                _grandParent: parent && parent._parent ? parent._parent.name : null
            });
            model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/brands/" + brandId);

            var rootBrand = this.model.get('brand');
            var view = new Beef.EditBrand.View({model: model, cache: this.cache, accountCode: this.accountCode,
                    rootBrands: this.model.get('brands'), onClose: function(ok) {
                if (!ok) return;
                $b.find(".name").text(brand.name = model.get('name'));
                brand.shortName = model.get('shortName');
                brand.category = model.get('category');

                let spm = model.get('subPhraseMatching')
                if (brand.subPhraseMatching != spm) {
                    brand.subPhraseMatching = spm
                    this.model.trigger("change")
                }

                if (rootBrand.id == brand.id) {
                    brand.mentionFilter = model.get('mentionFilter');
                    brand.feeds = model.get('feeds');
                    brand.socialNetworks = model.get('socialNetworks');
                    let a = model.get('supportProfileIds');
                    brand.supportProfileIds = a ? a.split(' ').map(s => parseInt(s)) : null;
                    let b = model.get('otherProfileIds');
                    brand.otherProfileIds = b ? b.split(' ').map(s => parseInt(s)) : null;
                    // trigger change to our model so the root brand menu updates
                    this.model.trigger("change");
                }
            }.bind(this)});

            model.on("destroy", function(){
                var nuke = function(b) {
                    b.deleted = true;
                    if (b.phrases) for (var i = 0; i < b.phrases.length; i++) b.phrases[i].deleted = true;
                    if (b.children) for (i = 0; i < b.children.length; i++) nuke(b.children[i]);
                };
                nuke(brand);

                var $table = $b.parent();

                $b.toggleClass("deleted", true);
                $b.find(".icon-edit").attr("class", "icon-refresh");
                $table.find(".phrases" + brandId).toggleClass("deleted", true);
                $table.find(".phrases" + brandId + " tr.add-phrase").remove();

                $table.find(".a" + brandId).toggleClass("deleted", true);
                $table.find(".brand.a" + brandId + " .icon-edit").attr("class", "icon-refresh");
                $table.find(".add-brand.a" + brandId).remove();
                $table.find(".phrases.a" + brandId + " tr.add-phrase").remove();
            }.bind(this));

            model.on("change:parentId", function(){
                var parentId = model.get("parentId");
                this.showOverlay();
                $b.replaceWith(Handlebars.render(require("@/setup/brands/AddingBrand.handlebars"), model.attributes));
                // wait for the sync before doing refresh so we are sure to see the change to the tree
                model.on("sync", function(){
                    this.refresh(function(){
                        if (!parent) {
                            // the root brand we were displaying has moved under another brand so go to the new
                            // brands root brand
                            var b = this.brandMap[parentId];
                            if (b) {
                                for (; b._parent; b = b._parent);
                                this.model.set("brand", b);
                            }
                        }
                    }.bind(this));
                }.bind(this));
            }.bind(this));

            var that = this;
            model.on("sync", function(model, data) {
                that.model.trigger('change:brand');
                if (data.archived) that.refresh();
                if (deprecatedBrandsStore.list) {
                    VuexStore.dispatch("refreshBrands", true);
                }
            });

            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.$el);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        editImportedBrand: function(brand) {
            var model = new Backbone.Model({
                id: brand.id,
                category: brand.category
            });
            model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/brands/" + brand.id);

            var that = this;
            var view = new Beef.EditImportedBrand.View({model: model, cache: this.cache, onClose:function(ok) {
                if (!ok) return;
                brand.category = model.get('category');
                that.model.trigger("change");
                if (deprecatedBrandsStore.list) deprecatedBrandsStore.refresh()
            }.bind(this)});

            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.$el);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        unarchiveBrand: function(ev) {
            var brandId = $(ev.target).attr("data-brand");
            if (!brandId) return;
            var brand = this.brandMap[brandId];
            if (brand._parent || !brand.archived || !window.confirm("Are you sure you want to un-archive " + brand.name + "?")) return;

            var model = new Backbone.Model({id: brandId, archived: false});
            model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/brands/" + brandId);
            var that = this;
            this.showOverlay();
            model.save(null, {
                error: function() {window.alert("Error unarchiving brand .. please retry") },
                success: function() {
                    that.refresh()
                    if (deprecatedBrandsStore.list) deprecatedBrandsStore.refresh()
                }
            });
        },

        addBrand: function(ev) {
            ev.stopPropagation();   // otherwise we get called twice as body.add-brand also gets the click
            var $b = $(ev.target).closest("tbody");
            var parent = this.brandMap[$b.attr("data-brand")];
            var model = new Beef.EditBrand.Model({
                _level: parent._level + 1,
                _parent: parent.name,
                parent: parent.id,
                _grandParent: parent._parent ? parent._parent.name : null
            });
            model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/brands");

            var $phrases = $b.parent().find(".phrases[data-brand='" + parent.id + "']");

            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.$el);
            var view = new Beef.EditBrand.View({model: model, cache: this.cache, onClose: function(ok) {
                if (!ok) return;
                model.set({_parent: parent, _cid: model.cid}, {silent: true});
                this.initBrand(model.attributes);
                $phrases.after(Handlebars.render(require("@/setup/brands/AddingBrand.handlebars"), model.toJSON()));
            }.bind(this)});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);

            model.on("sync", function(){
                $b.parent().find(".adding-brand[data-id='" + model.cid + "']").remove();
                this.initBrand(model.attributes);
                if (!parent.children) parent.children = [];
                parent.children.push(model.attributes);
                $phrases.after(Handlebars.render(require("@/setup/brands/BrandTree.handlebars"), model.toJSON()));
            }.bind(this));
        },

        editTopicTree: function (ev) {
            var brandId = this.$el.find("tbody.brand.level0").attr("data-brand");
            var brand = this.brandMap[brandId];
            if (brand._parent) return;

            var model = new Backbone.Model({
                id: brand.id,
                name: brand.name,
                topicTrees: brand.topicTrees
            });

            const dialog = showDialog(TopicTreePickerDialog, {
                activeBrandId: model.attributes.id
            });
            dialog.$on('update-brand-topic-trees', data => {
                brand.activeTopicTreeIds = data.activeTopicTreeIds
                brand.oldTopicTreeIds = data.oldTopicTreeIds
                brand.topicTrees = data.topicTrees
                this.model.trigger('change:brand');
            });
        },

        editBrandSegments: function (ev) {
            var brandId = this.$el.find("tbody.brand.level0").attr("data-brand");
            var brand = this.brandMap[brandId];
            if (brand._parent || !brand._canEditTopicTree) return;

            // console.log(model)
            // -> { attributes: {}, get(), set() }

            var model = new Backbone.Model({
                id: brand.id,
                name: brand.name,
                segmentListIds: brand.segmentListIds,
                activeSegmentListIds: brand.activeSegmentListIds,
                segmentLists: brand.segmentLists
            });

            const dialog = showDialog(SegmentPickerDialog, {
                activeBrandId: model.attributes.id
            });
            dialog.$on('update-brand-segments', brandSegments => {
                brand.segmentListIds = brandSegments.segmentListIds;
                brand.activeSegmentListIds = brandSegments.activeSegmentListIds;
                brand.segmentLists = brandSegments.segmentLists;

                this.model.trigger('change:brand');
            });
        },

        showDeletedChange: function(ev) {
            var v = $(ev.target).is(":checked");
            this.model.set("_showDeleted", v);
            this.refresh();
        },

        showInactiveChange: function(ev) {
            var v = $(ev.target).is(":checked");
            this.model.set('_showInactive', v);
            this.refresh();
        },

        showOverlay: function() {
            this.$el.find(".overlay").show();
        },

        refresh: function(callback) {
            this.showOverlay();
            Beef.Sync.mashGET("accounts/" + this.accountCode + "/brands", {
                    includeStats: true,
                    includeDeleted: this.model.get('_showDeleted'),
                    includeInactivePhrases: this.model.get('_showInactive')
                }, function (data){
                    this.reset(data);
                    if (callback) callback();
                }.bind(this));
        },

        reset: function(data) {
            var brand = this.model.get('brand');
            var found;
            for (var i = 0; i < data.length; i++) {
                if (data[i].id == brand.id) {
                    found = data[i];
                    break;
                }
            }
            this.model.set({brand: found, brands: data});
            this.render();
        },

        editPhrase: function(ev) {
            var $t = $(ev.target);
            if ($t.is("input") || $t.is("label")) return;   // active checkbox clicked
            var $tr = $t.closest("tr");
            var phrase = this.phraseMap[$tr.attr("data-phrase")];
            if (!phrase) return;

            if (phrase.deleted && account().maxPhrases != null && this.activePhrases() >= account().maxPhrases) {
                $t.attr("checked", null);
                this.phraseLimitReached(ev);
                return;
            }

            var model = new Beef.EditPhrase.Model({
                id: phrase.id,
                query: phrase.q,
                created: phrase.created,
                active: !phrase.inactive,
                deleted: phrase.deleted,
                mentionFilter: phrase.mentionFilter,
                feeds: phrase.feeds,
                socialNetworks: phrase.socialNetworks,
                approved: phrase.approved,
                approvedBy: phrase.approvedBy,
                _parent: phrase._parent
            });
            model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/phrases/" + phrase.id);

            var view = new Beef.EditPhrase.View({model: model, accountCode: this.accountCode, brand: phrase._parent,
                    cache: this.cache, rootBrands: this.model.get('brands'), onClose:function(ok) {
                if (!ok) return;
                phrase.q = model.get('query');
                phrase.inactive = !model.get('active');
                phrase.deleted = false;
                phrase.mentionFilter = model.get("mentionFilter");
                phrase.feeds = model.get("feeds");
                phrase.socialNetworks = model.get("socialNetworks");
                $tr.replaceWith(Handlebars.render(require("@/setup/brands/Phrase.handlebars"), phrase));
            }.bind(this)});

            model.on("destroy", function(){
                phrase.deleted = true;
                $tr.replaceWith(Handlebars.render(require("@/setup/brands/Phrase.handlebars"), phrase));
            }.bind(this));

            model.on("change:brandId", function(){
                this.showOverlay();
                $tr.replaceWith(Handlebars.render(require("@/setup/brands/AddingPhrase.handlebars"), model.attributes));
                // wait for the sync before doing refresh so we are sure to see the change to the tree
                model.on("sync", function(){ this.refresh(); }.bind(this));
            }.bind(this));

            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.$el);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        activePhrases: function() {
            var c = 0;
            $.each(this.phraseMap, function(key, value) {
                if (!value.inactive && !value.deleted) c = c + 1;
            });
            return c;
        },

        phraseLimitReached: function(ev) {
            Beef.LimitReached.show(ev, account().maxPhrases, "phrase", "phrases");
        },

        addPhrase: function(ev) {
            ev.preventDefault();

            if (account().maxPhrases != null && this.activePhrases() >= account().maxPhrases) {
                this.phraseLimitReached(ev);
                return;
            }

            var $tr = $(ev.target).closest("tr");
            var $tbody = $tr.closest("tbody");
            var brand = this.brandMap[$tbody.attr("data-brand")];
            if (!brand) return;

            var model = new Beef.EditPhrase.Model({
                query: "",
                active: true,
                brandId: brand.id,
                mentionFilter: "",
                feeds: "",
                socialNetworks: ""
            });
            model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/phrases");

            var view = new Beef.EditPhrase.View({model: model, accountCode: this.accountCode, brand: brand,
                    onClose:function(ok) {
                if (!ok) return;
                model.set({_parent: brand, _cid: model.cid}, {silent: true});
                $tr.before(Handlebars.render(require("@/setup/brands/AddingPhrase.handlebars"), model.toJSON()));
            }.bind(this)});

            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.$el);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);

            model.on("sync", function(){
                $tbody.find(".adding-phrase[data-id='" + model.cid + "']").remove();
                var phrase = model.attributes;
                phrase.q = phrase.query;
                phrase.query = null;
                phrase.created = moment().format();
                if (!brand.phrases) brand.phrases = [];
                brand.phrases.push(phrase);
                this.phraseMap[phrase.id] = phrase;
                $tr.before(Handlebars.render(require("@/setup/brands/Phrase.handlebars"), phrase));
                $tbody.prev().find('.toggle-phrases .phrase-length').text(brand.phrases.length);
            }.bind(this));
        },

        generatePhrases: function(ev) {
            ev.preventDefault();

            var $tr = $(ev.target).closest("tr");
            var $tbody = $tr.closest("tbody");
            var brand = this.brandMap[$tbody.attr("data-brand")];
            if (!brand) return;

            var that = this;
            console.log("generatePhrases", this.model);
            Beef.GeneratePhrases.show(brand.id, function(phrases) {
                if (!brand.phrases) brand.phrases = [];
                for (var i = phrases.length - 1; i >= 0; i--) {
                    var phrase = phrases[i];
                    phrase.q = phrase.query;
                    phrase.query = null;
                    phrase._parent = brand;
                    for (var j = 0; j < brand.phrases.length; j++) if (brand.phrases[j].id == phrase.id) break;
                    if (j < brand.phrases.length) {
                        brand.phrases[i] = phrase;
                    } else {
                        brand.phrases.push(phrase);
                        $tr.before(Handlebars.render(require("@/setup/brands/Phrase.handlebars"), phrase));
                    }
                    that.phraseMap[phrase.id] = phrase;
                }
                $tbody.prev().find('.toggle-phrases .phrase-length').text(brand.phrases.length);
            });
        },

        warningHelp: function(ev) {
            var $t = $(ev.target);
            var item = this.phraseMap[$t.closest("tr").attr("data-phrase")];
            if (!item) {
                item = this.brandMap[$t.closest("tbody").attr("data-brand")];
                if (!item) return;
            }

            var model = new Backbone.Model(item);
            var view = new Beef.VolumeWarningHelp.View({model: model, accountCode: this.accountCode});
            view.on("navigate", function(href) {
                Beef.Tooltip.close();
                this.trigger("navigate", href);
            }.bind(this));

            Beef.Tooltip.show({
                view: view,
                positions:["bottom-left", "bottom-right"],
                target: $t,
                delay: ev.type == "click" ? 0 : 400
            });
        },

        // if the warning help tooltip has not yet been displayed (i.e. the delay is still busy) then cancel it
        cancelWarningHelp: function() {
            if (!Beef.Tooltip.isShowing()) Beef.Tooltip.close();
        },

        showVolMentions: function(ev) {
            this.showMentions(ev, false);
        },

        showTrashMentions: function(ev) {
            this.showMentions(ev, true);
        },

        showMentions: function(ev, trash) {
            var brandId, phraseId;
            var $t = $(ev.target);
            var $tbody = $t.closest("tbody");
            if ($tbody.hasClass("brand")) brandId = $tbody.attr("data-brand");
            else phraseId = $t.closest("tr").attr("data-phrase");

            Beef.MentionList.navigateToMentions(this.accountCode,
                Beef.RootBrandSetup.createMentionsFilter(brandId, phraseId, trash));
        },

        editJson: function(ev) {
            ev.preventDefault();

            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
            popup.setTarget($(ev.target));

            var that = this;
            var view = Beef.EditBrandTreeJson.createView(this.accountCode, [this.model.get('brand')],
                function () { that.refresh() });
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        /**
         * Opens up an edit dialog for adding descriptions in multiple languages to a root brand.
         */
        editBrandDescription: function(ev) {
            ev.stopPropagation();

            var brand = this.model.get("brand");    // the root brand
            var languageCode = ev.target.dataset["languageCode"] || ev.target.parentElement.dataset["languageCode"];
            if (!brand || !brand.id) {
                console.error("Trying to edit the brand description of an undefined root brand.");
                return;
            }

            var model = new Beef.EditBrandDescription.Model({
                id: brand.id,
                name: brand.name,
                description: brand.description,
                language: languageCode
            });
            model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/brands/" + brand.id);

            var view = new Beef.EditBrandDescription.View({ model: model, accountCode: this.accountCode, onClose:function(ok, ctx) {
                if (ok) {
                    var _brand = this.model.get("brand");
                    _brand.description = ctx.model.get("description");
                    this.model.trigger("change");
                }
            }.bind(this)});

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["center"],
                alwaysMove: true
            });
            popup.setTarget(this.$el);

            view.on("close", function(){
                popup.hide();
            });

            popup.show(view);
        },

        /**
         * Removes a description from a brand and updates the brand in mash.
         */
        removeBrandDescription: function(ev) {

            ev.stopPropagation();

            var description = {},
                brand = this.model.get("brand"),
                languageCode = ev.target.dataset["languageCode"] || ev.target.parentElement.dataset["languageCode"];

            if (brand && brand.id && brand.description && languageCode) {
                for (var code in brand.description) {
                    if (brand.description.hasOwnProperty(code) && (code !== languageCode)) {
                        description[code] = brand.description[code];
                    }
                }

                brand.description = description;
                var attrs = { id: brand.id, description: description };
                if (window.confirm("Are you sure you want to delete this description?")){
                    this.model.trigger("change");
                    Beef.Sync.mashPUT("accounts/" + this.accountCode + "/brands/" + brand.id, attrs);
                }
            }
        },

        removeImportedBrand: function(ev) {
            ev.preventDefault();
            var brand = this.model.get("brand");
            if (window.confirm("Are you sure you want to remove imported brand " + brand.name + " from this account?")) {
                var brands = this.model.get('brands');
                for (var i = 0; i < brands.length; i++) if (brands[i].id == brand.id) break;
                --i;
                if (i >= brands.length) i = brands.length - 1;
                if (i >= 0) this.model.set({'brand': brands[i]});
                var that = this;
                Beef.Sync.mashCall('DELETE', "accounts/" + this.accountCode + "/brands/" + brand.id, null, function() {
                    that.refresh();
                    VuexStore.dispatch('refreshBrands', true);
                    notifyWithHtml(
                        `Brand <strong>${brand.shortName || brand.name}</strong> removed from the account`,
                        null,
                        '<i class="symbol-setup"></i>'
                    )
                });
            }
        },

        editColour: function (ev) {
            $(ev.currentTarget).find(".brand-colour").spectrum("show");
            // If we don't stop propagation, the click causes the Spectrum colour picker to be closed again.
            ev.stopPropagation();
        }
    });

    /**
     * Create a filter to display mentions for a brand or phrase and optionally only those in the trash.
     */
    this.createMentionsFilter = function(brandId, phraseId, trash) {
        var filter = "Published INTHELAST WEEK and Relevancy " + (trash ? "IS" : "ISNT") + " IRRELEVANT and ";
        if (brandId) filter += "Brand ISORCHILDOF " + brandId;
        else filter += "Phrase IS " + phraseId;
        return filter;
    };

    Handlebars.registerHelper('plural', function(o, options) {
        return new Handlebars.SafeString(o != undefined && o.length == 1 ? "" : "s");
    });
});
