<template>
    <div class="explore-stat-row animated fadeIn">
        <slot/>
    </div>
</template>

<script>
export default {

}
</script>


<style scoped lang="sass">

.explore-stat-row
    display: flex
    flex-wrap: wrap
    min-height: 10em
    width: max-content
    margin: 0 auto
    box-sizing: border-box
    padding: 20px 0

    > *
        width: 150px
        height: 100px
        margin-inline: 15px
        display: grid
        justify-content: center
        align-content: center

        ::v-deep h4
            color: white
            text-align: center

</style>