/**
 * A popup for editing the description of a brand in multiple languages.
 */
Beef.module("EditBrandDescription").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
        validation: {
            language: function(field) {
                if (!field || (field.trim().length === 0)) return "A language is required";
            },
            descriptionText: function(field) {
                if (field && (field.trim().length > 500)) return "Text is longer than 500 characters";
            }
        }
    });

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/setup/brands/EditBrandDescription.handlebars"),

        attributes: {
            class: "edit-brand-description"
        },

        bindings: {
            language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" }
        },

        createBindings: function() {
            // don't do bindings for our whole element or the subform for root brand filtering gets picked up
            // as well and the checkboxes don't work as they have bindings to both models
            return Beef.ModelBinder.createDefaultBindings(this.$el.find('.main-form'));
        },

        initialize: function() {
            Beef.SettingsDialog.View.prototype.initialize.apply(this, arguments);

            var descriptionText,
                language = this.model.get("language");

            if (language) descriptionText = this.model.get("description")[language];

            this.model.set("descriptionText", descriptionText, { silent: true });
            this.model.set("language", language, { silent: true });
        },

        onFirstRender: function() {
            Beef.LanguagePicker.attach(this, ".language", "language", { onlyOne: true });
        },

        onRender: function() {
            Beef.SettingsDialog.View.prototype.onRender.apply(this, arguments);
        },

        /**
         * Prepares the modified attributes for saving back to Mash.
         * @param attrs {Object} Model attributes that might have been modified, e.g. the description text.
         * @return {Object} An object with a brand id and descriptions in different languages.
         */
        prepareForSave: function(attrs) {

            var ans = { id: attrs.id, description: {} },
                description = attrs.description,
                descriptionText = attrs.descriptionText,
                language = attrs.language;

            for (var code in description) {
                if (description.hasOwnProperty(code) && (code !== language)) {
                    ans.description[code] = description[code];
                }
            }

            if (language && descriptionText) ans.description[language] = descriptionText;
            this.model.set("description", ans.description);
            return ans;
        }
    });
});
