// log state types
export const CREATED_STATE = "CREATED";
export const DELETED_STATE = "DELETED";
export const AUTH_STATE = "AUTH";
export const UNAUTH_STATE = "UNAUTH";
export const REFRESH_AUTH_STATE = "AUTH_REFRESHED";

export const profileTypes = {
    facebook: "FACEBOOK_PAGE",
    instagram_business: "INSTAGRAM_BUSINESS",
    instagram_user: "INSTAGRAM_USER",
    twitter: "TWITTER_SCREEN_NAME",
    linkedin: "LINKEDIN_COMPANY",
    linkedin_user: "LINKEDIN_USER",
    telegram: "TELEGRAM_CHANNEL",
    youtube: "YOUTUBE_CHANNEL",
    whatsapp: "WHATSAPP",
    helloPeter: "HELLOPETER",
    trustpilot: "TRUSTPILOT",
    tiktok: "TIKTOK",
    googleBusiness: "GOOGLEBUSINESS",
    csv: "CSV"
};

export const profileCategories = [
    {
        value: "ENTERPRISE",
        label: "Enterprise",
        description: "Enterprise (from your owned brands and competitors)"
    },
    {
        value: "CONSUMER",
        label: "Consumer",
        description: "Consumer (from consumers)"
    },
    {
        value: "PRESS",
        label: "Press",
        description: "Press (from press media and news aggregators)"
    },
    {
        value: "DIRECTORY",
        label: "Directory",
        description: "Directory (from any online classifieds)"
    }
];

export function isSupportedCategory(category) {
    for (const cat of profileCategories) {
        if (cat.value.toLowerCase() === category.toLowerCase()) return true;
    }

    return false;
}

export function socialNetworkToProfileType(socialNetwork) {
    switch (socialNetwork) {
        case "INSTAGRAM":
            return profileTypes.instagram_business;
        case "YOUTUBE":
            return profileTypes.youtube;
        case "FACEBOOK":
            return profileTypes.facebook;
        case "HELLOPETER":
            return profileTypes.helloPeter;
        case "LINKEDIN":
            return profileTypes.linkedin;
        case "TIKTOK":
            return profileTypes.tikTok;
        case "TWITTER":
            return profileTypes.twitter;
        case "TRUSTPILOT":
            return profileTypes.trustpilot;
        case "GOOGLEBUSINESS":
            return profileTypes.googleBusiness;
    }
}

export function getProfileIcon(type) {
    switch (type) {
        case profileTypes.facebook:
            return "symbol-facebook-rect";
        case profileTypes.instagram_business:
        case profileTypes.instagram_user:
            return "symbol-instagram";
        case profileTypes.twitter:
            return "symbol-twitter";
        case profileTypes.linkedin:
        case profileTypes.linkedin_user:
            return "symbol-linkedin-rect";
        case profileTypes.telegram:
            return "symbol-telegram";
        case profileTypes.tiktok:
            return "symbol-tiktok";
        case profileTypes.youtube:
            return "symbol-youtube";
        case profileTypes.whatsapp:
            return "symbol-whatsapp";
        case profileTypes.helloPeter:
            return "symbol-hellopeter";
        case profileTypes.googleBusiness:
            return "symbol-google-full";
        case profileTypes.trustpilot:
            return "symbol-trustpilot";
        case profileTypes.csv:
            return "symbol-csv-doc";
        default:
            return "";
    }
}

export function getProfileAppId(type) {
    switch (type) {
        case profileTypes.facebook:
        case profileTypes.instagram_business:
        case profileTypes.instagram_user:
            return 4;
        case profileTypes.twitter:
            return 41;
        case profileTypes.linkedin:
        case profileTypes.linkedin_user:
            return 42;
        case profileTypes.helloPeter: 
            return 47;
        case profileTypes.googleBusiness: 
            return 49;
        case profileTypes.tiktok:
            return 50;
        default:
            return 0;
    }
}

export function getProfileColor(type) {
    switch (type) {
        case profileTypes.facebook:
            return "var(--colour-facebook)";
        case profileTypes.instagram_business:
        case profileTypes.instagram_user:
            return "var(--colour-instagram)";
        case profileTypes.twitter:
            return "var(--colour-twitter)";
        case profileTypes.tikTok:
            return "var(--colour-tiktok)";
        case profileTypes.linkedin:
        case profileTypes.linkedin_user:
            return "var(--colour-linkedin)";
        case profileTypes.telegram:
            return "var(--colour-telegram)";
        case profileTypes.youtube:
            return "var(--colour-youtube)";
        case profileTypes.whatsapp:
            return "var(--colour-whatsapp)";
        case profileTypes.trustpilot:
            return "var(--colour-trustpilot)";
        default:
            return "";
    }
}

export function prettyProfileType(type) {
    switch (type) {
        case profileTypes.facebook:
            return "Facebook page";
        case profileTypes.instagram_business:
            return "Instagram Business profile";
        case profileTypes.instagram_user:
            return "Instagram User profile";
        case profileTypes.twitter:
            return "X profile";
        case profileTypes.linkedin:
            return "LinkedIn company profile";
        case profileTypes.linkedin_user:
            return "LinkedIn personal profile";
        case profileTypes.telegram:
            return "Telegram channel";
        case profileTypes.tiktok:
            return "TikTok profile";
        case profileTypes.youtube:
            return "Youtube channel";
        case profileTypes.whatsapp:
            return "WhatsApp number";
        case profileTypes.helloPeter:
            return "Hellopeter profile";
        case profileTypes.googleBusiness:
            return "Google Business profile";
        case profileTypes.trustpilot:
            return "Trustpilot site";
        default:
            return "";
    }
}

/**
 * Attempt to extract handle ID from profile lookup
 *
 * @param profileLookup URL
 * @returns {String} a handle ID that will be used to look up the profile
 */
export function extractHandleId(profileLookup) {
    profileLookup = profileLookup.split("?")[0];
    let parts = profileLookup.split("/");
    parts = parts.filter(part => part !== "");
    let lookup = parts[parts.length - 1];
    if (lookup && lookup.charAt(0) === "@") lookup = lookup.substring(1);

    // Some facebook pages use the format "Pagename-handleId" as their identifier, we want to extract the pagename only from these
    if (parts.includes("www.facebook.com")) {
        let splitName = lookup.split("-");
        if (splitName) {
            let handleId = splitName[splitName.length - 1];
            // test if last part (possible handle ID) only contains numbers
            let isHandleId = /^\d+$/.test(handleId);
            if (isHandleId) {
                lookup = lookup.replace(`-${handleId}`, "");
            }
        }
    }

    return lookup;
}


export function getProfileDisplayName(handle, handleId, name, type) {
    if (arguments.length === 1) {
        handleId = handle.handleId;
        name = handle.name;
        handle = handle.handle;
    }
    name = name || handle;
    return handleId === handle ? name : handle;
}