import _ from 'underscore';

/**
 * Select one or more options by typing the code or name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
import {encloseInSingleQuotes, escapeExpression} from "@/app/utils/StringUtils";
import {isFunction} from "@/app/utils/Util";
import {toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";

Beef.module("AutoCompletePicker").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/app/framework/pickers/AutoCompletePicker.handlebars"),

        initialize: function(options) {
            if(!_(options.options.mustSelectFromItems).isUndefined()) {
                this.mustSelectFromItems = options.options.mustSelectFromItems;
            }
            if(!_(options.options.encloseNewItemsInQuotes).isUndefined()) {
                this.encloseNewItemsInQuotes = options.options.encloseNewItemsInQuotes;
            }
            if (!_(options.options.sortFun).isUndefined()) {
                this.sortFun = options.options.sortFun;
            }
            if (!_(options.options.importanceSorter).isUndefined()) {
                this.ImportanceSorter = options.options.importanceSorter;
            }
            if (!_(options.options.searchFilter).isUndefined()) {
                this.searchFilter = options.options.searchFilter;
            }
            this.placeholder = options.options.placeholder;
            this.preserveCase = !!options.options.preserveCase;

            this.$input = $(options.input);
            this.$input.on("keyup", this.inputKeyUpHandler =  this.inputKeyUp.bind(this));
            this.$input.on("keydown", this.inputKeyDownHandler =  this.inputKeyDown.bind(this));
            this.$input.on("change", this.inputChangeHandler =  this.inputChange.bind(this));
            this.model.set('help', this.help);
            this.search();
        },

        onClose: function() {
            this.$input.off("keyup", this.inputKeyUpHandler);
            this.$input.off("keydown", this.inputKeyDownHandler);
            this.$input.off("change", this.inputChangeHandler);
        },

        modelEvents: {
            "change:found" : "render",
            "change:selected": "renderSelected"
        },

        events: {
            "mousedown": "mousedown"
        },

        mousedown: function(ev) {
            var $t = $(ev.target);
            var code = $t.closest('a').attr('data-value');
            if (!code) code = $t.closest('tr').attr('data-value');
            if (code) {
                ev.preventDefault();
                this.select(code);
            }
        },

        shouldPopupBeVisible: function() {
            if (this.help) return true;
            var found = this.model.get('found');
            return found != null && found.length > 0;
        },

        onRender: function() {
            this.renderSelected();
        },

        renderSelected: function() {
            var code = this.model.get('selected');
            var $li = $("li", this.$el);
            $li.toggleClass("active", false);
            if (code) {
                $li.each(function(){
                    var $this = $(this);
                    if ($("a", $this).attr('data-value') == code) {
                        $this.toggleClass("active", true);
                        return false;
                    }
                });
            }
        },

        getInputVal: function() {
            return this.cleanupInput(this.$input.val());
        },

        cleanupInput: function(s) {
            if (this.preserveCase) return s;
            return s.toLowerCase();
        },

        mustSelectFromItems: true,  // set this to false to allow users to enter any non-empty text

        encloseNewItemsInQuotes: false,

        sortFun: null,

        doNotSort: false,

        /** How to get the name out of the items data structure. */
        getName: _.identity,

        /** How to format an item in the items data structure for display. Can return html. */
        nameFormatter: escapeExpression,

        /** Filters out some of the search results from displaying in the autocomplete box. */
        searchFilter: _.identity,

        /** Returns the text to be used when matching against search queries. */
        getSearchText: _.identity,

        /**
         * This is a constructor that will return an object that has a sort method on it.
         * That sort method should sort an array based on some order. The returned results
         * by the autocomplete picker will be the first results of these.
         */
        ImportanceSorter: function() {
            this.sort = function(query, data) {
                var lowerQ = query ? query.toLowerCase() : '';

                var getName;
                if (Array.isArray(data[0].name)) getName = function (d) { return d.name.toString() };
                else getName = function(d) { return d.name };

                data.sort(function(lhs, rhs) {
                    var lowerLhs = getName(lhs).toLowerCase(),
                        lowerRhs = getName(rhs).toLowerCase();

                    if (lowerQ == lowerLhs) return -1;
                    if (lowerQ == lowerRhs) return 1;

                    if (lowerLhs.match("^" + lowerQ)) return -1;
                    if (lowerRhs.match("^" + lowerQ)) return 1;

                    return getName(lhs).localeCompare(getName(rhs));
                });

                return data;
            };
        },

        inputKeyUp: function(ev) {
            if (this.placeholder) {
                var haveValue = this.$input.val() && this.$input.val().length > 0;
                var $placeholder = this.$input.parent().find('.placeholder');
                if (haveValue && $placeholder) {
                    $placeholder.remove();
                } else if (!haveValue && $placeholder.length == 0
                        && $(this.$input.parent().children()[0]).attr('data-value') == "" ) {
                    this.$input.parent().prepend(toPlaceholderHTML(this.placeholder));
                }
            }

            this.search();
            var keyCode = ev.keyCode;
            if (keyCode == 13) {
                var s = this.model.get('selected');
                if (s || this.gobbleNextEnter) {
                    ev.stopPropagation();
                    this.gobbleNextEnter = false;
                }
                if (s) return this.select(s);
                if (!this.mustSelectFromItems) {
                    s = this.getInputVal();
                    if (s.length > 0) {
                        ev.stopPropagation();
                        return this.select(s);
                    }
                }
                ev.preventDefault();
            }
        },

        inputChange: function(ev) {
            var s = this.model.get('selected');
            // the change event comes in before the keyup 13 (enter) event so make sure we gobble that so the
            // dialog doesn't close
            if (s) {
                this.gobbleNextEnter = true;
                return this.select(s);
            }
            if (!this.mustSelectFromItems) {
                s = this.getInputVal();
                if (this.encloseNewItemsInQuotes) s = encloseInSingleQuotes(s);
                if (s.length > 0) {
                    this.gobbleNextEnter = true;
                    return this.select(s);
                }
            }
        },

        inputKeyDown: function(ev) {
            this.search();
            var found, i, selected;
            var keyCode = ev.keyCode;
            if (keyCode === 38) { // up
                found = this.model.get("found");
                if (found && found.length > 0) {
                    selected = this.model.get('selected');
                    if (selected && found.find(function(d) { return d.code === selected})) {
                        for (i = found.length - 1; i > 0; i--) {
                            if (found[i].code == selected) {
                                selected = found[i - 1].code;
                                break;
                            }
                        }
                    } else {
                        selected = found[found.length - 1].code;
                    }
                    this.model.set('selected', selected);
                }
                ev.preventDefault();
            } else if (keyCode === 40) { // down
                found = this.model.get("found");
                if (found && found.length > 0) {
                    selected = this.model.get('selected');
                    if (selected && found.find(function(d) { return d.code === selected})) {
                        for (i = 0; i < found.length - 1; i++) {
                            if (found[i].code === selected) {
                                selected = found[i + 1].code;
                                break;
                            }
                        }
                    } else {
                        selected = found[0].code;
                    }
                    this.model.set('selected', selected);
                }
                ev.preventDefault();
            }
        },

        startDroppedDown: function () {
            return !!this.options.options.startDroppedDown;
        },

        /**
         * This searches items to see if it can find a matching value.
         */
        search: function() {
            var s = this.getInputVal();
            var q = this.model.get('query') || (this.startDroppedDown() ? null : "");

            var getName = this.getName;
            var getSearchText = this.getSearchText;
            var nameFormatter = this.nameFormatter.bind(this);
            if (s != q) {
                var that = this;
                var cb = function(items) {
                    var selected = that.startDroppedDown() ? that.model.get('selected') : undefined;
                    var found = [];
                    var lots = false;
                    var preTakeLimit = 200;
                    var takeLimit = 16;
                    var s = that.getInputVal();
                    if (s.length > 0 || that.startDroppedDown()) {
                        var itemsIsArray = Array.isArray(items);
                        for (var code in items) {
                            var item = items[code];
                            var name = getName(item);
                            var searchText = getSearchText(item);
                            var lcode = code.toLowerCase();
                            if (itemsIsArray) code = name;
                            if (lcode.indexOf(s) >= 0 || searchText.toLowerCase && that.contains(searchText.toLowerCase(), s.toLowerCase())) {
                                var row = {code: code, name: nameFormatter(item), item: item};
                                if (that.searchFilter(row)) {
                                    found.push(row);
                                    if (lcode == s || name == s) selected = code;
                                    if (found.length >= preTakeLimit) break;
                                }
                            }
                        }

                        if (found.length > takeLimit) {
                            // An initial sort to prioritise elements to take

                            var importanceSorter = new that.ImportanceSorter();
                            found = importanceSorter.sort(q, found);

                            found = _(found).take(16);
                            lots = true;
                        }

                        if (found.length == 1) {
                            if (that.mustSelectFromItems) selected = found[0].code;
                        } else if (found.length > 1 && !that.doNotSort) {
                            var comp;
                            if (that.sortFun) {
                                comp = that.sortFun;
                            } else if (Array.isArray(found[0].name)) {
                                comp = function(a, b) { return a.name.toString().localeCompare(b.name.toString()) }
                            } else {
                                comp = function(a, b) { return a.name.localeCompare(b.name) }
                            }
                            found.sort(comp);
                        } else if (found.length === 0 && that.startDroppedDown()) {
                            selected = undefined;
                        } else {
                            if (that.encloseNewItemsInQuotes) selected = encloseInSingleQuotes(s);
                        }
                    }
                    that.model.set('query', s);
                    that.model.set('lots', lots);
                    that.model.set('found', found);
                    that.model.set('selected', selected);
                };

                if (isFunction(this.items)) {
                    this.items(s, this.options.view, cb, this.options ? (this.options.options || {}) : {});
                } else {
                    cb(this.items);
                }
            }
        },

        // if item is a string then see if it contains s, otherwise assume it is an array and test the last element
        contains: function(item, s) {
            if (item.toLowerCase) return item.toLowerCase().indexOf(s) >= 0;
            var last = item[item.length - 1];
            return last !== undefined && last.toLowerCase && last.toLowerCase().indexOf(s) >= 0
        },

        select: function(code) {
            this.$input.val("");
            this.trigger('change', code);
            this.search();
        }
    });
});
