/**
 * Utilities for our general charting system, the Fantastic Chart.
 */

import {adjustedWald, getDefaultTopicViewId} from "@/app/utils/Util";
import {substituteTagParamaters} from "@/app/utils/Tags";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {isVerifiedOnly} from "@/dashboards/filter/BasicFilter";
import {getAccountCurrency} from "@/app/utils/Currency";
import {
    formatMoney,
    formatNumber as beefFormatNumber,
    formatPercentage as beefFormatPercentage,
    formatPlural,
    formatSeconds,
    toSi
} from "@/app/utils/Format";
import _ from 'underscore';
import moment from "moment";
import {capitalise, isString, isUnknown} from "@/app/utils/StringUtils";
import {FANTASTIC_FIELDS} from "@/dashboards/widgets/fantasticchart/fields/Fields";
import AxisOptions from "@/dashboards/widgets/fantasticchart/AxisOptions";
import VuexStore from "@/store/vuex/VuexStore";
import {isDevEnvironment} from "@/app/utils/Account";
import {features} from "@/app/Features";
import {count} from "@/data/Grouse";
import {createEqualityStatement} from "@/dashboards/filter/Generator";
import {sortChartData} from "@/dashboards/widgets/fantasticchart/SortUtils";


/**
 * Creates a getter function for compare fields. This makes sure that the getter is informed
 * that it is getting the 1st or 2nd instance of the field. For example, when comparing tags
 * to tags, the xAxis would tag, compare would be tag, and we need to know which one to fetch.
 */
export function getCompareGetter(compare) {
    const compareField = FANTASTIC_FIELDS[compare] || {};
    return function (d) {
        return (compareField.getter || function (d) {
            return d[compare]
        })(d, true)
    };
}

export function getCompareFilterGetter(compare) {
    const compareField = FANTASTIC_FIELDS[compare] || {};
    return function (d) {
        return (compareField.filterGetter || function (d) {
            return d[compare]
        })(d, true)
    };
}

export function getCompareRawDataGetter(compare) {
    const compareField = FANTASTIC_FIELDS[compare] || {};
    return function (d) {
        return (compareField.rawDataGetter || function () {
            return null
        })(d, true)
    };
}

export function getCompareRawDataSetter(compare) {
    const compareField = FANTASTIC_FIELDS[compare] || {};
    return function (d, data) {
        return (compareField.rawDataSetter || function () {
            return null
        })(d, data, true)
    };
}

export function getDefaultXSortField(xAxis, yAxis) {
    if (!yAxis) return null;

    const xField = FANTASTIC_FIELDS[xAxis];
    if (xField?.sorter) return xField;

    return FANTASTIC_FIELDS[yAxis];
}

export function getDefaultCompareSortField(yAxis, compare) {
    if (!yAxis) return null;

    const compareField = FANTASTIC_FIELDS[compare];
    if (compareField?.sorter) return compareField;

    return FANTASTIC_FIELDS[yAxis];
}

export function getDescriptionGetter(field) {
    const f = FANTASTIC_FIELDS[field] || {};
    return function (d) {
        return (f.descriptionGetter || function () {
            return null
        })(d)
    };
}

export function filterGetterWithIdFactory(field) {
    field = field + ".id";
    return function (d) {
        const val = d[field];
        if (val === "NA") return "UNKNOWN";
        return val;
    }
}

export function filterGetterFactory(field) {
    return function (d) {
        const val = d[field];
        // We do not want to match val === 0, so don't use !val in the statement below
        if (val === null || val === undefined || val === "NA") return "UNKNOWN";
        return val;
    }
}

/**
 * For a data entry, this just returns the field with the exact same name
 * as the argument.
 * @param field
 * @returns {Function}
 */
export function simpleGetterFactory(field) {
    return function (d) {
        const val = d[field];
        if (val === null || val === undefined || val === "NA") return "UNKNOWN";
        return val;
    }
}

export function setterWithIdFactory(field) {
    return function (d, id, value) {
        d[field + ".id"] = id;
        d[field + ".name"] = value;
    }
}

export function simpleSetterFactory(field) {
    return function (d, value) {
        d[field] = value;
    }
}

export function formatPercentage(value, decimals, d, ignore) {
    let result = beefFormatPercentage(value * 100, (decimals || 0));
    if (d && d.moePercent >= 0) result = result + " ±" + beefFormatPercentage(d.moePercent * 100, 0);

    return result;
}

export function formatPercentageTooltip (value) {
    return formatPercentage(value, 1);
}

export function formatNumber(value, d, model) {
    const formatter = (model && (model.get("no-si") || model.get('numberFormat') === 'space'))
        ? beefFormatNumber : toSi;
    return formatter(value); // We do not display an MoE for absolute counts.
}

export function formatDuration(value, ignore, model) {
    let durationFormat = model?.get("durationFormat");
    let hoursThreshold;
    let hours;
    let minutes;

    switch (durationFormat) {
        case "default":
            hoursThreshold = model.get("durationHoursThreshold");
            return formatSeconds(value, false, hoursThreshold);
        case "hours":
            hours = value / (60 * 60);
            return beefFormatNumber(hours, hours < 10 && ((hours * 10 % 10) >= 1) ? 1 : 0) + "h";
        case "minutes":
            minutes = value / 60;
            return beefFormatNumber(minutes, hours < 10 && ((hours * 10 % 10) >= 1) ? 1 : 0) + "m";
        default:
            return formatSeconds(value);
    }
}

export function formatDurationTooltip(value) {
    return formatSeconds(value, true);
}

export function formatResponseTimeDurationTooltip(value) {
    let hours = value / (60 * 60);
    let minutes = value / 60;

    let tooltip = hours >= 1 ? `${beefFormatNumber(hours, hours < 10 && ((hours * 10 % 10) >= 1) ? 1 : 0)} ${formatPlural(hours, 'hour')} / ` : "";
    tooltip += `${beefFormatNumber(minutes, minutes < 10 && ((minutes * 10 % 10) >= 1) ? 1 : 0)} ${formatPlural(minutes, 'minute')}`;

    return tooltip;
}

export function formatDate(dateFormat, value) {
    const m = moment(value);
    const timezone = VuexStore.state.account.timezone;
    let tz = m.tz(timezone);
    if (!tz) tz = m.tz('UTC');
    return tz.format(dateFormat);
}

export function formatEnglishDate(value, coarseness) {
    switch (coarseness) {
        case "hourly":
            return "the hour starting " + moment(value).format('YYYY/MM/DD HH:mm');
        case "daily":
            return moment(value).format('YYYY/MM/DD');
        case "weekly":
            return "the week starting " + moment(value).format('YYYY/MM/DD');
        case "monthly":
            return moment(value).format('MMMM, YYYY');
        case "quarterly":
            return "the quarter starting " + moment(value).format('YYYY/MM/DD');
        case "yearly":
            return moment(value).format('YYYY');
        default:
            return moment(value).format('YYYY/MM/DD');
    }
}

export function formatDecimal(value, d) {
    let result = beefFormatNumber(value, 1);
    if (d && d.moe >= 0) result = result + " ±" + beefFormatNumber(d.moe);
    return result;
}

export function formatSentiment(value) {
    switch (value) {
        case -1:
            return "Negative sentiment";
        case 0:
            return "Neutral sentiment";
        case 1:
            return "Positive sentiment";
        case null:
            return "Unknown sentiment";
    }
}

export function formatSentimentShort(value) {
    switch (value) {
        case -1:
            return "Negative";
        case 0:
            return "Neutral";
        case 1:
            return "Positive";
        default:
            return "Unknown";
    }
}

export function formatCurrency(value, d, si) {
    if (si === undefined) si = true;
    return formatMoney(value, d.aveCurrency || getAccountCurrency(), {si: si})
}


export function formatRelevancy(value) {
    if (value === null || value === undefined) return value;
    return capitalise(value);
}

export function tagAndTopicExtender(d, key, model) {

    const xAxis = model.get("xAxis") || "published";
    const xField = FANTASTIC_FIELDS[xAxis] || {};
    const compare = model.get("compare");
    const compareField = compare && FANTASTIC_FIELDS[compare] || {};
    const language = model.get("language") || "en";

    const xAxisSet = xField.isTag;
    const compareSet = compareField.isTag;

    let prefix;
    if (xAxisSet && !compareSet) {
        prefix = xAxis;
    } else if (!xAxisSet && compareSet) {
        prefix = compare + "2";
    } else if (key === "tag") {
        prefix = xAxis;
    } else {
        prefix = compare + "2";
    }

    const result = {};
    result["_" + prefix] = d;

    if (!d) {
        result[prefix + ".id"] = 'NA';
        result[prefix + ".name"] = 'NONE';
        if (!prefix.startsWith("topic")) result[prefix + ".namespace"] = 'None';
        result[prefix + ".description"] = 'NA';
        if (prefix.startsWith("topic")) {
            result[prefix + ".is_parent"] = 'NA';
            result[prefix + ".parentId"] = 'NA';
        }
    } else {
        result[prefix + ".id"] = d.id;
        result[prefix + ".name"] = d.labels && d.labels[language] || d.name;
        if (prefix !== "topic") result[prefix + ".namespace"] = d.namespace;
        result[prefix + ".description"] = d.descriptions && d.descriptions[language] || "NA";
        if (prefix.startsWith("topic")) {
            result[prefix + ".is_parent"] = !d.leaf;
            result[prefix + ".parentId"] = d?.parent?.id ?? 'NA';
        }
    }

    return result
}

export function tagAndTopicPreProcess(data, ignore) {
    return data.filter(function (d) {
        // this does no filtering if there is no tag2 .. is that right?
        const tag1Topic = !d.tag || (d.tag && d.tag.namespace === "topic");
        const tag2Topic = !d.tag2 || (d.tag2 && d.tag2.namespace === "topic");
        return tag1Topic || tag2Topic;
    })
}

/** Do not include tags with flag NONE_OF_THE_ABOVE (for filtering out 'No Topics') */
export function tagAndTopicPreProcessExclNoneOfTheAbove(data, ignore) {
    return data.filter(function (d) {
        const tag1Topic = d.tag && (d.tag.namespace === "topic" || d.tag.namespace === "topic_tree") && "NONE_OF_THE_ABOVE" !== d.tag.flag;
        const tag2Topic = d.tag2 && (d.tag2.namespace === "topic" || d.tag2.namespace === "topic_tree") && "NONE_OF_THE_ABOVE" !== d.tag2.flag;
        return tag1Topic || tag2Topic;
    })
}

export function tagAndTopicSetterFactory(field) {
    return function (d, id, value, compare) {
        let prefix = field;
        if (compare) prefix = field + "2";
        d[prefix + ".id"] = id;
        d[prefix + ".name"] = value;
    }
}

export function tagAndTopicRawDataGetterFactory(field) {
    return function (d, compare) {
        const prefix = !compare ? field : field + "2";
        return d["_" + prefix];
    }
}

export function tagAndTopicRawDataSetterFactory(field) {
    return function (d, value, compare) {
        let prefix = field;
        if (compare) prefix = field + "2";
        d["_" + prefix] = value;
    }
}

export function tagAndTopicFilterGetterFactory(field) {
    return function (d, compare) {
        const prefix = !compare ? field : field + "2";
        const val = d[prefix + ".id"];
        if (val === "NA") return "UNKNOWN";
        return val;
    }
}

export function tagAndTopicDescriptionGetterFactory(field) {
    return function (d, compare) {
        const prefix = !compare ? field : field + "2";
        const val = d[prefix + ".description"];
        if (val === "NA") return null;
        return substituteTagParamaters(val);
    }
}




/**
 * Provides a way to break down the grouping of data that we want to render.
 */
export function getGroupIterator(data, model) {
    const xAxis = model.get("xAxis") || "published";
    const xAxisField = FANTASTIC_FIELDS[xAxis];
    const compare = model.get("compare");
    const compareField = compare && FANTASTIC_FIELDS[compare];

    const xAxisGetter = xAxisField.filterGetter || xAxisField.getter;
    const compareGetter = compare ? compareField.filterGetter || compareField.getter : null;

    const xAxisIds = new Set();
    const compareIds = new Set();
    const xIdToRow = new Map();
    const compareIdToRow = new Map();

    for (let i = 0; i < data.length; i++) {
        const x = xAxisGetter(data[i]);
        if (!xIdToRow.has(x)) xIdToRow.set(x, new Set());

        xAxisIds.add(x);
        xIdToRow.get(x).add(i);

        if (compare) {
            const cId = compareGetter(data[i]);
            if (!compareIdToRow.has(cId)) compareIdToRow.set(cId, new Set());
            const cArray =  compareIdToRow.get(cId);

            compareIds.add(cId);
            cArray.add(i);
        }
    }

    let xIterator = xAxisIds.entries();
    let compareIterator = compareIds.entries();
    let xId = xIterator.next();

    return {
        // This iterator returns a cross product of all
        // x axis values and the comparison values.
        next: function () {
            let compareValue = null;

            if (compare) {
                let compareId = compareIterator.next();

                if (compareId.done) {
                    compareIterator = compareIds.entries();
                    compareId = compareIterator.next();
                    if (!compareId.done) {
                        xId = xIterator.next();
                    }
                }

                if (!compareId.done) {
                    compareValue = compareId.value[0];
                }
            }

            if (xId.done) return {done: true};

            // Create a value holding the x and compare IDs. Don't add the compare
            // key to the map if there is no compare field.
            const value = {x: xId.value[0]};
            // When there is a comparison, the comparison code will move the x iterator along.
            if (compare) {
                value.compare = compareValue;
                value.rows = _.intersection(Array.from(xIdToRow.get(value.x)), Array.from(compareIdToRow.get(compareValue)));
            } else {
                xId = xIterator.next();
                value.rows = Array.from(xIdToRow.get(value.x));
            }

            return {done: false, value: value};
        },
        xAxisIds: xAxisIds,
        xIdToRow: xIdToRow,
        compareIds: compareIds.size ? compareIds : null,
        compareIdToRow: compareIds.size ? compareIdToRow : null,
        groupSize: (xAxisIds.size || 1) * (compareIds.size || 1),
        [Symbol.iterator]: function() { return this }
    };
}


/**
 * Calculates margin of error for a given data set. Modifies the past data array,
 * but also returns it for convenience.
 *
 * @param groupField The field that we are calculating MoE for.
 * @param data This should be the full data set, including any fields being added.
 * @param model
 */
export function calculateMoE (groupField, data, model) {
    const yAxis = model.get("yAxis") || "mentionCount";

    if (yAxis === "mentionCount" || yAxis === "mentionPercent") {
        const groups = getGroupIterator(data, model);
        let groupIndexes = [_.range(data.length)];

        if (groups.compareIds) {
            groupIndexes = (model.get("compare") === groupField) ? groups.xIdToRow : groups.compareIdToRow;
        }

        groupIndexes.forEach(function(indexes) {
            const total = Array.from(indexes)
                               .map(index => data[index].mentionCount)
                               .reduce((acc, value) =>  acc + value, 0);

            for (const index of indexes) {
                const adjWald = adjustedWald(total, data[index].mentionCount, data.extra.populationSize);
                data[index].moe = adjWald.moe;
                data[index].moePercent = data[index].moe / total;
                data[index].moe_hi = adjWald.high;
                data[index].moe_low = adjWald.low;
            }
        })
    }

    return data;
}



export function getTopicView(model) {
    if (model.get("topicview")) return parseInt(model.get("topicview"));
    const filter = model.get("_effectiveFilter");
    if (filter) {
        let id = getDefaultTopicViewId(filter)
        if (id) return parseInt(id)
    }
    return null;
}



export function getEffectiveFilter(model) {
    let filter = model.get("_effectiveFilter");
    if (!filter) return null;

    let first = model.get('show')[0]

    const xAxis = FANTASTIC_FIELDS[model.get("xAxis")] || {};
    const yAxis = FANTASTIC_FIELDS[first.yAxis] || {};
    const compare = (model.get("compare") && FANTASTIC_FIELDS[model.get("compare")]) || {};
    const size = (model.get("size") && FANTASTIC_FIELDS[model.get("size")]) || {};

    const isSentiment = xAxis.isSentiment || compare.isSentiment || size.isSentiment;
    const useVerified = isSentiment && (!model.has("verified-sentiment") || model.get("verified-sentiment"));
    if (filter && useVerified && !isVerifiedOnly(filter)) {
        filter = appendFiltersReadably(filter, "process is verified");
    }

    if (model.get("xAxis") === "topic" || model.get("compare") === "topic") {
        const view = getTopicView(model);
        if (view) filter = appendFiltersReadably(filter, "tag is " + view);
    }

    if (xAxis.appendForFetch) {
        filter = "(" + filter + ") and (" + xAxis.appendForFetch(model) + ")";
    }

    if (compare.appendForFetch) {
        filter = "(" + filter + ") and (" + compare.appendForFetch(model) + ")";
    }

    return filter;
}



/**
 * Given an object that should be a row that we can count (it has y-axis and size values),
 * this initialises that with appropriate defaults.
 */
export function initialiseRow(model, row) {
    const xAxis = model.get("xAxis") || "published";
    const yAxis = model.get("show")?.at(0)?.yAxis || "mentionCount";
    const size = model.get("size");
    const compare = model.get("compare");

    // Filling in missing x-axis data.
    if (!row.hasOwnProperty(xAxis) && xAxis === "sentiment") {
        row.sentiment = 0;
    }

    if (!row.hasOwnProperty(yAxis)) row[yAxis] = 0;
    if (size && !row.hasOwnProperty(size)) row[size] = 0;
    if (compare && !row.hasOwnProperty(compare)) {
        // Sentiment value can never be null. If we set it to null,
        // later code handling sentiment will have an 'unknown' sentiment field.
        row[compare] = compare === "sentiment" ? 0 : null;
    }

    if ((yAxis === "mentionPercent" || size === "mentionPercent") && !row.hasOwnProperty("mentionCount")) {
        row.mentionCount = 0;
    }
    if ((yAxis === "totalSentimentPercent" || size === "totalSentimentPercent") && !row.hasOwnProperty("totalSentiment")) {
        row.totalSentiment = 0;
    }

    return row;
}



/**
 * Sums the y axis and size display fields for two rows.
 * This destructively updates the lhs object.
 */
export function sumRows(model, lhs, rhs) {
    const yAxis = model.get("yAxis") || "mentionCount";
    const size = model.get("size");

    if (rhs[yAxis]) {
        lhs[yAxis] = (lhs[yAxis] || 0) + rhs[yAxis];
    }

    if (size && rhs[size]) {
        lhs[size] = (lhs[size] || 0) + rhs[size];
    }

    return lhs;
}

/**
 * Calculates online ave score. This is calculated from OTS.
 */
export function calculateOnlineAve(totalOts) {
    return totalOts * 0.15 * 1.5 + 7.5;
}




export function getOther(language) {
    if (!language) return "Others";

    switch (language.toLowerCase()) {
        case 'ar':
            return 'أخرى';
        case 'es':
            return 'Otros';
        case 'en':
        default:
            return 'Others';
    }
}

export function isOther(other) {
    if (!other) return false;
    if (!isString(other)) return false;
    other = other.toLowerCase();

    if (other === 'others') return true;
    if (other === 'other') return true;
    if (other === 'otros') return true;
    if (other === 'أخرى'.toLowerCase()) return true;

    return false;
}


export function getUnknown(language) {
    if (!language) return "Unknown";

    switch (language.toLowerCase()) {
        case 'en':
        default:
            return 'Unknown';
    }
}



/**
 * Formats data according to a particular coarseness / granularity, such as daily, monthly, etc.
 * @param d
 * @param i
 * @param model
 * @returns String
 */
export function fantasticDateFormat(d, i, model) {
    let id;

    if (typeof model === "string") {
        id = model
    } else {
        id = model.get('dateFormat') || 'auto'
    }

    let df = AxisOptions.dateFormats[id]
    if (df) return df.fn.apply(this, arguments)
    console.warn("Unknown dateFormat [" + id + "]");
    return "" + d;
}

export function fantasticDateImportant(d, i, model) {
    let m = moment(d);
    let coarseness = this.getEffectiveCourseness();

    function longFormat(m, i) {
        return i === 0 || (m.date() === 1 && (coarseness !== 'hourly' || (m.hour() === 0 && m.minute() === 0)));
    }

    switch (coarseness) {
        case 'hourly':
            if ((longFormat(m, i) && m.hour() === 0) || i === 0) return true;
            if (m.day() === 1 && m.hour() === 0) return true;
            if (m.hour() === 0) return true;

            return false;
        case 'daily':
            // If things are tight, this is in the second position, and the item
            // in the first position always has a label
            if (i === 1) return false;
            if (longFormat(m, i)) return true;
            if (m.day() === 1) return true;

            return false;
        case 'weekly':
            var next = m.clone().add('days', 6);
            return next.months() !== m.months();
        case 'monthly':
            return m.month() === 0;
        case 'yearly':
            return false;
    }
    console.warn("No coarseness set for ", d, "Maybe not a date?");
    return false;
}

/**
 * @param g
 * @param GROUP
 * @param GROUP_FIELD_GETTER
 * @param model
 * @param data
 * @param [uniqueFootnotes = undefined]
 * @returns {void}
 */
export function calculateBarPercentages(g, GROUP, GROUP_FIELD_GETTER, model, data, uniqueFootnotes ) {
    if (!data) throw new ReferenceError("data: not provided");
    if (!data.extra) throw new ReferenceError("data: does not have the `extra` field. Please add it.");
    data.extra.totals ??= {};

    const footnotes = new Set();
    const topicView = getTopicView(model);
    const X_AXIS = model.get("xAxis");
    const X_AXIS_FIELD = FANTASTIC_FIELDS[X_AXIS] || {};
    const X_AXIS_FILTER_GETTER = X_AXIS_FIELD.filterGetter;
    const COMPARE = model.get("compare");
    const COMPARE_FILTER_GETTER = getCompareFilterGetter(COMPARE);
    const FIRST = model.get('show')[0];

    let mentionOrInteractionCalc = function(countField, percentField) {
        let total = 0;
        if ((X_AXIS === "topic" && COMPARE !== "sentiment" || COMPARE === "topic") && !topicView) {
            if (FIRST.yAxis === percentField) {
                const message = "Unable to determine topic tree, percentages may be wrong";
                if (isDevEnvironment()) console.warn(message);
                footnotes.add(message);
            }
        }

        // We have previously calculated all of the totals.
        if (data.extra.totals.hasOwnProperty(g)) {
            total = data.extra.totals[g];

            for (let i = 0; i < data.length; i++) {
                let d = data[i];
                if (GROUP && g !== GROUP_FIELD_GETTER(d)) continue;
                if (!d.hasOwnProperty(countField) && isDevEnvironment()) console.warn("DEV Warning: chart value", d, " has no count data");
                if (total === 0) d[percentField] = 0;
                else d[percentField] = (d[countField] ?? 0) / total;
            }
            return;
        }

        if ((X_AXIS === "topic" && COMPARE !== "sentiment" || COMPARE === "topic") && topicView) {
            // Here, we need to calculate percentages using the returned topic view, if present.
            let item = data.find(function (d) {
                if (GROUP && g !== GROUP_FIELD_GETTER(d)) return false;
                let getter = X_AXIS === "topic" ? X_AXIS_FILTER_GETTER : COMPARE_FILTER_GETTER;
                return parseInt(getter(d)) === topicView;
            });
            if (!item) {
                console.trace();
                if (isDevEnvironment()) console.warn(`Unable to find topic view counts for topic view ${topicView}`);
                footnotes.add("Selected topic view is not appropriate for this data set, percentages may be wrong");
            }
            total = item ? item[countField] : 0;
        } else {
            for (let i = 0; i < data.length; i++) {
                let d = data[i];
                if (GROUP && g !== GROUP_FIELD_GETTER(d)) continue;
                total += (d[countField] || 0);
            }
        }

        data.extra.totals[g] = total;

        // Finally calculate percentages.
        for (let i = 0; i < data.length; i++) {
            let d = data[i];
            if (GROUP && g !== GROUP_FIELD_GETTER(d)) continue;
            if (total === 0) d[percentField] = 0;
            else d[percentField] = d[countField] / total;
        }
    }

    try {
        if (data[0].hasOwnProperty("mentionCount")) {
            mentionOrInteractionCalc("mentionCount", "mentionPercent");
        }

        if (data[0].hasOwnProperty("interactionCount")) {
            mentionOrInteractionCalc("interactionCount", "interactionPercent");
        }

        if (data[0].hasOwnProperty("sentimentVerifiedCount")) {
            for (let i = 0; i < data.length; i++) {
                let d = data[i];
                if (GROUP && g !== GROUP_FIELD_GETTER(d)) continue;
                if (!d.sentimentVerifiedCount) {
                    d.totalSentimentPercent = 0;
                    d.totalPositivePercent = 0;
                    d.totalNegativePercent = 0;
                    d.sentimentVerifiedPercent = 0;
                } else {
                    let vc = d.sentimentVerifiedCount;
                    d.totalSentimentPercent = d.totalVerifiedSentiment / vc;
                    d.totalPositivePercent = d.totalVerifiedPositive / vc;
                    d.totalNegativePercent = d.totalVerifiedNegative / vc;
                    let mc = d.mentionCount;
                    d.sentimentVerifiedPercent = mc ? vc / mc : 0
                }
            }
        }
    } finally {
        if (uniqueFootnotes && footnotes.size) {
            const present = new Set(uniqueFootnotes);
            footnotes.forEach(note => {
                if (!present.has(note)) uniqueFootnotes.push(note);
            });
        }
    }

}

/**
 * This function is used instead of #reduceDataToMaxItemsOld.
 * The only major difference is that in this function, the sorting is done before reduction.
 */
export async function sortAndReduceDataToMaxItems(model, data, extra, extraFootnotes) {
    if (!data || !data.length) return data;

    const MAX_COMPARISONS = parseInt(model.get("maxComparisons"));
    if (typeof MAX_COMPARISONS !== 'number') {
        console.error("max-comparison value of " + MAX_COMPARISONS + " is not a number");
        return data;
    }
    if (MAX_COMPARISONS <= 0) {
        console.error("Cannot have negative max-comparison value: " + MAX_COMPARISONS);
        return data;
    }

    let xAxis = model.get("xAxis");
    let xField = FANTASTIC_FIELDS[xAxis] || {};
    let xAxisGetter = xField.getter || function (d) { return d[xAxis] };
    let xAxisIdGetter = xField.filterGetter || xAxisGetter;
    let compare = model.get("compare");
    let compareField = compare && FANTASTIC_FIELDS[compare] || {};
    let compareGetter = getCompareGetter(compare);
    let compareIdGetter = getCompareFilterGetter(compare);
    let compareRawDataGetter = getCompareRawDataGetter(compare);
    let compareRawDataSetter = getCompareRawDataSetter(compare);

    let group = null;
    let isCompare = null;
    if (compare) {
        group = compare;
        isCompare = true;
    }

    if (group && compare && (compare === "sentiment" || compare === "topic")) {
        group = xAxis;
        isCompare = false;
    }
    const groupField = group && FANTASTIC_FIELDS[group] || {};
    const groupFieldGetter = group && (isCompare ? getCompareGetter(group) : groupField.getter);
    const groupFieldFilterGetter = group && (isCompare ? getCompareFilterGetter(group) : groupField.filterGetter);

    const groupValues = new Set();

    if (group) {
        for (let i = 0; i < data.length; i++) {
            groupValues.add(groupFieldGetter(data[i]));
        }
    }

    // generate a unique id for each data point (bar or line etc.) for custom settings (e.g. bar colour)
    // these are in this.model.attributes.pointSettings
    data.forEach(d => {
        let p1 = xAxis === "dataSet" ? d['dataSet.id'] : xAxisIdGetter(d);     // e.g. '2020-11' or '140063'
        let p2 = compare === "dataSet" ? d['dataSet.id'] : compareIdGetter(d); // e.g. '140063' (brand id)
        d.id = p1 !== undefined ? p2 !== undefined ? p1 + ":" + p2 : p1 : p2
    });

    // Some x-axis items may be hidden. We remove them now, just before render,
    // so that removing them does not affect calculation of values (such as percentages).
    const hidden = model.get('hidden') ?? [];

    let somethingHidden = false;
    if (hidden.length) {
        const hiddenIds = new Set(hidden.map(d => d.id));
        data = data.filter(d => {
            const hideThis = hiddenIds.has(d.id);
            somethingHidden = somethingHidden || hideThis;
            return !hideThis;
        });
    }

    let show = model.get('show');
    let first = show[0];
    let yAxis = first.yAxis;
    let yField = FANTASTIC_FIELDS[yAxis] || {};
    let yGetter = yField.getter || (d => d[yAxis]);
    let ySetter = yField.setter || ((d, v) => d[yAxis] = v);
    let xGetter = xField.getter || function (d) { return d[xAxis] };
    let xIdGetter = xField.filterGetter || xGetter;

    //-------------------------------------------------------------
    // We want to filter out inappropriate data as decided by our fields
    if (xField.filterData) data = xField.filterData(data, model, extraFootnotes);
    if (compareField.filterData) data = compareField.filterData(data, model, extraFootnotes);

    //-------------------------------------------------------------
    // First we want to control the number of x-axis elements, if the x-axis
    // is not a date (we always show all dates)

    let xValuesToKeep = new Set();
    let compareValuesToKeep = new Set();

    let keep = null;
    let others = {};
    let otherId = {};
    let otherRaw = {};
    let otherCount = {};
    let toAdd = [];
    let showUnknown = !model.get("hideUnknown") && (!xField.hideUnknown || !compareField.hideUnknown);
    let showOther = !model.get("hideOther") && !compareField.otherNotSupported;

    data = customSorter(data, model);
    data.forEach(function (d) {
        xValuesToKeep.add(xIdGetter(d))
        compareValuesToKeep.add(compareGetter(d));
    });

    // noMaxItems indicates that the data for this field should never be reduced: all returned data
    // should always be shown.
    if (!xField.noMaxItems) {
        let maxItems = parseInt(model.get('maxItems'));

        if (xValuesToKeep.size > maxItems) {
            let xValuesToKeepList = [...xValuesToKeep];
            let unknownValue = xValuesToKeepList.find(x => isUnknown(x));
            keep = new Set(xValuesToKeepList.slice(0, maxItems - (showOther ? 1 : 0)).map(keep => "" + keep));

            if (showUnknown) {
                const lastEl = [...keep].at(-1);
                keep.delete(lastEl);
                keep.add(unknownValue);
            }

            // If we don't have to show an aggregate 'Others' category,
            // much of our processing stops here.
            if (showOther) {
                const discard = new Set();
                data.forEach(function (d) {
                    if (!keep.has("" + xIdGetter(d))) discard.add(xIdGetter(d));
                    if (keep.has("" + xIdGetter(d))) return;
                    others[compareGetter(d)] ??=  0;
                    others[compareGetter(d)] += d[yAxis];
                    otherId[compareGetter(d)] = compareIdGetter(d);
                    otherRaw[compareGetter(d)] = compareRawDataGetter(d);
                    if (d.hasOwnProperty("mentionCount")) {
                        otherCount[compareGetter(d)] ??=  0;
                        otherCount[compareGetter(d)] += d.mentionCount;
                    }
                });

                if (xField.getOthersFilter && features.otherCalculationFixes()) {
                    toAdd = [];
                    const OTHER_FILTER = await xField.getOthersFilter(discard);
                    for (const entry of Object.entries(others)) {
                        const select = getSelectParameter(model, false);
                        let filter = getEffectiveFilter(model);
                        if (compare) filter = appendFiltersReadably(filter, createEqualityStatement(compare, otherId[entry[0]]));
                        const otherResults = await count(appendFiltersReadably(filter, OTHER_FILTER), null, [...select]);
                        xField.setter(otherResults, "OTHER", getOther());
                        if (compare) {
                            compareField.setter(otherResults, otherId[entry[0]] || "NA", entry[0], true);
                            if (otherRaw[entry[0]]) compareRawDataSetter(otherResults, otherRaw[entry[0]]);
                        }
                        initialiseRow(model, otherResults);
                        toAdd.push(otherResults);
                    }
                } else {
                    toAdd = Object.entries(others)
                        .map(function (entry) {
                            var result = {};
                            xField.setter(result, "OTHER", getOther());
                            if (compare) {
                                compareField.setter(result, otherId[entry[0]] || "NA", entry[0], true);
                                if (otherRaw[entry[0]]) compareRawDataSetter(result, otherRaw[entry[0]]);
                            }
                            if (otherCount.hasOwnProperty(entry[0])) result.mentionCount = otherCount[entry[0]];
                            result[yAxis] = entry[1];
                            return result;
                        });

                }
            }

            data = data.filter(function (d) {
                return keep.has("" + xIdGetter(d));
            }).concat(toAdd);

        } else if (!showUnknown) {
            data = data.filter(function (d) {
                return !isUnknown(xGetter(d))
            })
        }
        data.extra = extra;
    }

    data.extra = Object.assign({itemsWereHidden: somethingHidden}, extra ?? {});
    if (!compare || compareField.noMaxItems) return postProcessData(data, model, extraFootnotes);

    //-------------------------------------------------------------
    // We want to find the largest comparisons groups, and we need to reduce those as well.

    if (showOther && !compare) {
        compareValuesToKeep.forEach(c => {
            calculateBarPercentages(c, compare, compareGetter, model, data, extraFootnotes);
        });
    }

    // See if we even have enough comparison partitions.
    if (compareValuesToKeep.size <= MAX_COMPARISONS) {
        if (!showUnknown) {
            data = data.filter(function (d) {
                return !isUnknown(compareGetter(d))
            })
        }
        return postProcessData(data, model, extraFootnotes);
    }

    let compareValuesToKeepList = [...compareValuesToKeep];
    let unknownValue = compareValuesToKeepList.find(x => isUnknown(x));
    keep = new Set(compareValuesToKeepList.slice(0, MAX_COMPARISONS - (showOther ? 1 : 0)).map(keep => "" + keep));

    if (showUnknown) {
        const lastEl = [...keep].at(-1);
        keep.delete(lastEl);
        keep.add(unknownValue);
    }

    let result = [];
    others = {};
    let otherToId = {};
    let otherCompareRaw = new Map();
    let otherXRaw = new Map();
    toAdd = [];

    data.forEach(function (d) {
        let val = compareGetter(d);
        if (keep.has(val)) {
            result.push(d);
        } else {
            let x = xGetter(d);
            otherToId[x] = xField.filterGetter(d) || x;
            others[x] = others[x] || 0;
            others[x] += yGetter(d);
            if (xField.rawDataGetter) otherXRaw.set(x, xField.rawDataGetter(d));
            if (compareField.rawDataGetter) otherCompareRaw.set(x, compareField.rawDataGetter(d, true));
            if (d.hasOwnProperty("mentionCount")) {
                otherCount[x] ??=  0;
                otherCount[x] += d.mentionCount;
            }
        }
        return keep.has(compareGetter(d))
    });


    if (!model.get("hideOther")) {
        toAdd = Object.entries(others).map(([key, yVal]) => {
            let result = {};
            xField.setter(result, otherToId[key], key);
            ySetter(result, yVal);
            compareField.setter(result, "OTHER", getOther(), true);
            if (otherXRaw.has(key)) xField.rawDataSetter(result, otherXRaw.get(key));
            if (otherCompareRaw.has(key)) compareField.rawDataSetter(result, otherXRaw.get(key), true);
            if (otherCount.hasOwnProperty(key)) result.mentionCount = otherCount[key];
            return result;
        });
    }

    result = result.concat(toAdd);
    result.extra = Object.assign({itemsWereHidden: somethingHidden}, extra ?? {});

    if (showOther) {
        groupValues.forEach(g => calculateBarPercentages(g, group, groupFieldFilterGetter, model, result, extraFootnotes))
    }
    return postProcessData(result, model, extraFootnotes);
}

/**
 * @deprecated use #sortAndReduceDataToMaxItems instead.
 * Once features.sortingOnFantasticChart() is live, this should be removed.
 */
export async function reduceDataToMaxItemsOld(model, data, extra, extraFootnotes) {
    if (!data || !data.length) return data;

    const MAX_COMPARISONS = parseInt(model.get("maxComparisons"));
    if (typeof MAX_COMPARISONS !== 'number') {
        console.error("max-comparison value of " + MAX_COMPARISONS + " is not a number");
        return data;
    }
    if (MAX_COMPARISONS <= 0) {
        console.error("Cannot have negative max-comparison value: " + MAX_COMPARISONS);
        return data;
    }


    let xAxis = model.get("xAxis");
    let xField = FANTASTIC_FIELDS[xAxis] || {};
    let xAxisGetter = xField.getter || function (d) { return d[xAxis] };
    let xAxisIdGetter = xField.filterGetter || xAxisGetter;
    let compare = model.get("compare");
    let compareField = compare && FANTASTIC_FIELDS[compare] || {};
    let compareGetter = getCompareGetter(compare);
    let compareIdGetter = getCompareFilterGetter(compare);
    let compareRawDataGetter = getCompareRawDataGetter(compare);
    let compareRawDataSetter = getCompareRawDataSetter(compare);

    let group = null;
    let isCompare = null;
    if (compare) {
        group = compare;
        isCompare = true;
    }

    if (group && compare && (compare === "sentiment" || compare === "topic")) {
        group = xAxis;
        isCompare = false;
    }
    const groupField = group && FANTASTIC_FIELDS[group] || {};
    const groupFieldGetter = group && (isCompare ? getCompareGetter(group) : groupField.getter);
    const groupFieldFilterGetter = group && (isCompare ? getCompareFilterGetter(group) : groupField.filterGetter);

    const groupValues = new Set();

    if (group) {
        for (let i = 0; i < data.length; i++) {
            groupValues.add(groupFieldGetter(data[i]));
        }
    }

    // generate a unique id for each data point (bar or line etc.) for custom settings (e.g. bar colour)
    // these are in this.model.attributes.pointSettings
    data.forEach(d => {
        let p1 = xAxis === "dataSet" ? d['dataSet.id'] : xAxisIdGetter(d);     // e.g. '2020-11' or '140063'
        let p2 = compare === "dataSet" ? d['dataSet.id'] : compareIdGetter(d); // e.g. '140063' (brand id)
        d.id = p1 !== undefined ? p2 !== undefined ? p1 + ":" + p2 : p1 : p2
    });

    // Some x-axis items may be hidden. We remove them now, just before render,
    // so that removing them does not affect calculation of values (such as percentages).
    const hidden = model.get('hidden') ?? [];

    let somethingHidden = false;
    if (hidden.length) {
        const hiddenIds = new Set(hidden.map(d => d.id));
        data = data.filter(d => {
            const hideThis = hiddenIds.has(d.id);
            somethingHidden = somethingHidden || hideThis;
            return !hideThis;
        });
    }

    let show = model.get('show');
    let first = show[0];
    let yAxis = first.yAxis;
    let yField = FANTASTIC_FIELDS[yAxis] || {};
    let yGetter = yField.getter || (d => d[yAxis]);
    let ySetter = yField.setter || ((d, v) => d[yAxis] = v);
    let xGetter = xField.getter || function (d) { return d[xAxis] };
    let xIdGetter = xField.filterGetter || xGetter;

    //-------------------------------------------------------------
    // We want to filter out inappropriate data as decided by our fields
    if (xField.filterData) data = xField.filterData(data, model, extraFootnotes);
    if (compareField.filterData) data = compareField.filterData(data, model, extraFootnotes);

    //-------------------------------------------------------------
    // First we want to control the number of x-axis elements, if the x-axis
    // is not a date (we always show all dates)

    let yVals = {};
    let keep = null;
    let others = {};
    let otherId = {};
    let otherRaw = {};
    let otherCount = {};
    let toAdd = [];
    let showUnknown = !model.get("hideUnknown");
    let showOther = !model.get("hideOther") && !compareField.otherNotSupported;

    // noMaxItems indicates that the data for this field should never be reduced: all returned data
    // should always be shown.
    if (!xField.noMaxItems) {
        let maxItems = parseInt(model.get('maxItems'));
        data.forEach(function (d) {
            let val = xIdGetter(d);
            yVals[val] = yVals[val] || 0;
            yVals[val] += Math.abs(d[yAxis]); // We want absolute size, not positiveness.
        });

        if (Object.keys(yVals).length > maxItems) {
            let sorted = Object.entries(yVals)
                .filter(function (d) {
                    // We filter out unknowns here, so that if we do filter them
                    // out, they will by default land in the Other bucket.
                    return showUnknown || !isUnknown(d[0]);
                })
                .sort(function (lhs, rhs) {
                    return Math.abs(rhs[1] || 0) - Math.abs(lhs[1] || 0);
                });

            keep = new Set(sorted.slice(0, maxItems - (showOther ? 1 : 0))
                .map(function (d) {
                    return d[0];
                }));

            // If we don't have to show an aggregate 'Others' category,
            // much of our processing stops here.
            if (showOther) {
                const discard = new Set();
                data.forEach(function (d) {
                    if (!keep.has("" + xIdGetter(d))) discard.add(xIdGetter(d));
                    if (keep.has("" + xIdGetter(d))) return;
                    others[compareGetter(d)] ??=  0;
                    others[compareGetter(d)] += d[yAxis];
                    otherId[compareGetter(d)] = compareIdGetter(d);
                    otherRaw[compareGetter(d)] = compareRawDataGetter(d);
                    if (d.hasOwnProperty("mentionCount")) {
                        otherCount[compareGetter(d)] ??=  0;
                        otherCount[compareGetter(d)] += d.mentionCount;
                    }
                });

                if (xField.getOthersFilter && features.otherCalculationFixes()) {
                    toAdd = [];
                    const OTHER_FILTER = await xField.getOthersFilter(discard);
                    for (const entry of Object.entries(others)) {
                        const select = getSelectParameter(model, false);
                        let filter = getEffectiveFilter(model);
                        if (compare) filter = appendFiltersReadably(filter, createEqualityStatement(compare, otherId[entry[0]]));
                        const otherResults = await count(appendFiltersReadably(filter, OTHER_FILTER), null, [...select]);
                        xField.setter(otherResults, "OTHER", getOther());
                        if (compare) {
                            compareField.setter(otherResults, otherId[entry[0]] || "NA", entry[0], true);
                            if (otherRaw[entry[0]]) compareRawDataSetter(otherResults, otherRaw[entry[0]]);
                        }
                        initialiseRow(model, otherResults);
                        toAdd.push(otherResults);
                    }
                } else {
                    toAdd = Object.entries(others)
                        .map(function (entry) {
                            var result = {};
                            xField.setter(result, "OTHER", getOther());
                            if (compare) {
                                compareField.setter(result, otherId[entry[0]] || "NA", entry[0], true);
                                if (otherRaw[entry[0]]) compareRawDataSetter(result, otherRaw[entry[0]]);
                            }
                            if (otherCount.hasOwnProperty(entry[0])) result.mentionCount = otherCount[entry[0]];
                            result[yAxis] = entry[1];
                            return result;
                        });

                }
            }


            data = data.filter(function (d) {
                return keep.has("" + xIdGetter(d));
            }).concat(toAdd);

        } else if (!showUnknown) {
            data = data.filter(function (d) {
                return !isUnknown(xGetter(d))
            })
        }
        data.extra = extra;
    }

    data.extra = Object.assign({itemsWereHidden: somethingHidden}, extra ?? {});
    if (!compare || compareField.noMaxItems) return postProcessData(data, model, extraFootnotes);

    //-------------------------------------------------------------
    // We want to find the largest comparisons groups, and we need to reduce those as well.

    var comparisons = {};
    data.forEach(function (d) {
        var val = compareGetter(d);
        comparisons[val] = comparisons[val] || 0;
        comparisons[val] += Math.abs(d[yAxis]); // We want absolute size, not positiveness.
    });

    if (showOther && !compare) {
        Object.keys(comparisons).forEach(c => {
            calculateBarPercentages(c, compare, compareGetter, model, data, extraFootnotes);
        });
    }

    // See if we even have enough comparison partitions.
    if (Object.keys(comparisons).length <= MAX_COMPARISONS) {
        if (!showUnknown) {
            data = data.filter(function (d) {
                return !isUnknown(compareGetter(d))
            })
        }
        return postProcessData(data, model, extraFootnotes);
    }

    // Now choose the top maxComparisons
    keep = new Set(Object.entries(comparisons)
        .filter(function (d) {
            // We filter out unknowns here, so that if we do filter them
            // out, they will by default land in the Other bucket.
            return showUnknown || !isUnknown(d[0])
        })
        .sort(function (lhs, rhs) {
            return Math.abs(rhs[1] || 0) - Math.abs(lhs[1] || 0)
        })
        .slice(0, MAX_COMPARISONS - (showOther ? 1 : 0))
        .map(function (d) {
            return d[0]
        }));

    let result = [];
    others = {};
    let otherToId = {};
    let otherCompareRaw = new Map();
    let otherXRaw = new Map();
    toAdd = [];

    data.forEach(function (d) {
        let val = compareGetter(d);
        if (keep.has(val)) {
            result.push(d);
        } else {
            let x = xGetter(d);
            otherToId[x] = xField.filterGetter(d) || x;
            others[x] = others[x] || 0;
            others[x] += yGetter(d);
            if (xField.rawDataGetter) otherXRaw.set(x, xField.rawDataGetter(d));
            if (compareField.rawDataGetter) otherCompareRaw.set(x, compareField.rawDataGetter(d, true));
            if (d.hasOwnProperty("mentionCount")) {
                otherCount[x] ??=  0;
                otherCount[x] += d.mentionCount;
            }
        }
        return keep.has(compareGetter(d))
    });


    if (!model.get("hideOther")) {
        toAdd = Object.entries(others).map(([key, yVal]) => {
            let result = {};
            xField.setter(result, otherToId[key], key);
            ySetter(result, yVal);
            compareField.setter(result, "OTHER", getOther(), true);
            if (otherXRaw.has(key)) xField.rawDataSetter(result, otherXRaw.get(key));
            if (otherCompareRaw.has(key)) compareField.rawDataSetter(result, otherXRaw.get(key), true);
            if (otherCount.hasOwnProperty(key)) result.mentionCount = otherCount[key];
            return result;
        });
    }

    result = result.concat(toAdd);
    result.extra = Object.assign({itemsWereHidden: somethingHidden}, extra ?? {});

    if (showOther) {
        groupValues.forEach(g => calculateBarPercentages(g, group, groupFieldFilterGetter, model, result, extraFootnotes))
    }
    return postProcessData(result, model, extraFootnotes);
}



function postProcessData(data, model, extraFootnotes) {
    const compare = model.get("compare");
    const compareField = compare && FANTASTIC_FIELDS[compare] || {};

    const xAxis = model.get("xAxis");
    const xField = FANTASTIC_FIELDS[xAxis] || {};

    if (xField.postProcess) data = xField.postProcess(data, model, extraFootnotes);
    if (compareField.postProcess) data = compareField.postProcess(data, model, extraFootnotes);

    data = customSorter(data, model);

    return data;
}


function sortData(data, model) {
    let sortOrder = {};
    let xAxis = model.get("xAxis") || "mentionCount";
    let xField = FANTASTIC_FIELDS[xAxis] || {};
    let xGetter = xField.getter || function (d) { return d[xAxis] };

    let show = model.get('show');
    let first = show[0];
    let yAxis = first.yAxis;
    let yField = FANTASTIC_FIELDS[yAxis] || {};
    let yGetter = yField.getter || function (d) { return d[yAxis] };
    let ySorter = xField.isDate ? function () {
        return 0;
    } : function (lhs, rhs, order) {
        order ??= "descending";
        return order === "descending" ? yGetter(rhs) - yGetter(lhs) : yGetter(lhs) - yGetter(rhs);
    };
    let compare = model.get("compare");
    let compareField = compare && FANTASTIC_FIELDS[compare] || {};
    let compareGetter = getCompareGetter(compare);

    let xSorter = function (lhs, rhs, order) {
        let sorter = xField.sorter || function () {
            return 0;
        };

        let lhsX        = xGetter(lhs),
            rhsX        = xGetter(rhs),
            lhsXUnknown = isString(lhsX) && isUnknown(lhsX),
            lhsXOthers  = isString(lhsX) && isOther(lhsX),
            rhsXUnknown = isString(rhsX) && isUnknown(rhsX),
            rhsXOthers  = isString(rhsX) && isOther(rhsX);

        if (lhsXOthers) return 1;
        if (rhsXOthers) return -1;
        if (lhsXUnknown) return 1;
        if (rhsXUnknown) return -1;

        return sorter(lhs, rhs, order);
    };

    let compareSorter = function (lhs, rhs, order) {
        let main = compareField.sorter || function () {
            return 0
        };

        if (compare) {
            let lhsC        = compareGetter(lhs),
                rhsC        = compareGetter(rhs),
                lhsCUnknown = isString(lhsC) && isUnknown(lhsC),
                lhsCOthers  = isString(lhsC) && isOther(lhsC),
                rhsCUnknown = isString(rhsC) && isUnknown(rhsC),
                rhsCOthers  = isString(rhsC) && isOther(rhsC);

            if (lhsCOthers) return 1;
            if (rhsCOthers) return -1;
            if (lhsCUnknown) return 1;
            if (rhsCUnknown) return -1;
        }

        return main(lhs, rhs, order);
    };

    data.forEach(function (d, i) {
        sortOrder[xGetter(d)] = i;
    });

    return data.sort(function (lhs, rhs) {
        return xSorter(lhs, rhs) || compareSorter(lhs, rhs) || ySorter(lhs, rhs);
    });
}

export function customSorter(data, model) {
    let xAxis = model.get("xAxis") || "mentionCount";
    let xField = FANTASTIC_FIELDS[xAxis] || {};

    let show = model.get('show');
    let yAxis = show[0].yAxis;
    let yField = FANTASTIC_FIELDS[yAxis] || {};

    let compare = model.get("compare");
    let compareField = compare && FANTASTIC_FIELDS[compare] || {};

    return sortChartData(data, model.attributes, xField, yField, compareField);
}


/**
 * Calculates the select parameter for a grouse query.
 * @param model
 * @param {boolean} includeX - whether to include the x-axis field in the set of select parameters. Not include x-axis parameter
 *                              essentially restricts the query to what return only what the various y-axes are showing.
 * @return {Set}
 */
export function getSelectParameter(model, includeX = true) {
    const SELECT = new Set();
    const SHOW = model.get('show');
    const getGrouseFields = field => (FANTASTIC_FIELDS[field] && FANTASTIC_FIELDS[field].grouseAlias) || [field];

    // todo each show with own filter needs a separate query
    SHOW.forEach(s => getGrouseFields(s.yAxis).forEach(d => SELECT.add(d)));
    if (model.get("line")) getGrouseFields(model.get("line")).forEach(d => SELECT.add(d));
    if (model.get("size")) getGrouseFields(model.get("size")).forEach(d => SELECT.add(d));

    if (includeX) {
        const X_AXIS = model.get("xAxis");
        const X_FIELD = FANTASTIC_FIELDS[X_AXIS] || {};
        if (X_AXIS !== "published" && !X_FIELD.noSelect) {
            // Select all required fields, except publication dates and brand.
            getGrouseFields(X_AXIS).forEach(d => SELECT.add(d));
        }
    }

    return SELECT;
}


const reviewSet = new Set([
    167447,     // FIVE STAR
    194038,     // TEN STAR
    196490,     // FIVE OPTION
    194051,     // YES NO
    194029,     // SEVEN STAR RATING
]);

/**
 * Indicates whether the given segment list ID is related to our rating tools
 * @param id
 * @return {boolean}
 */
export function idIsRating(id) {
    return reviewSet.has(id);
}

const surveySet = new Set([
    176307,     // Net promoter score
    176323,     // Unsupported customer survey
    190607,     // Survey mention types
    188116,     // Customer effort survey
    194022,     // Survey question types
    176294,     // CSAT
    193919,     // FCR
]);

/**
 * Indicates whether the given segment list ID is related to our surveys
 * @param id
 * @return {boolean}
 */
export function idIsSurvey(id) {
    return surveySet.has(id);
}