<template>
    <section class="beef-tooltip">
        <p>
            There were on average <strong><deq-number :number="topic.weekAverage" :precision="2"/> mentions per day</strong>,
            totalling <strong><deq-number :number="topic.weekTotal"/> {{ formatPlural(topic.weekTotal,'mention')}} overall</strong>.
        </p>
        <p v-if="loading" class="average-tooltip__loading">
            <spinner-component :size="14"/> Counting mentions
        </p>
        <section v-if="counts.length" class="average-tooltip__chart-area">
            <p>
                This chart shows the number of mentions received daily.
            </p>
            <section class="average-tooltip__chart" ref="chart"></section>
        </section>

    </section>
</template>


<script>

import DeqNumber from "@/components/formatters/DeqNumber";
import {count as grouseCount} from "@/data/Grouse";
import * as b3js from "brandseyejs";
import SpinnerComponent from "@/components/SpinnerComponent";
import {formatPlural} from "@/app/utils/Format";


export default {
    components: {DeqNumber, SpinnerComponent},
    props: {
        topic: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            counts: [],
            beChart: null
        }
    },

    watch: {
        counts() {
            this.$nextTick(() => this.renderChart());
        }
    },

    mounted() {
        this.beChart ??= new b3js.chart();
        if (this.topic?.filter) {
            this.loadData();
        }
    },

    beforeDestroy() {
        this.beChart = null;
    },

    methods: {
        formatPlural,
        async loadData() {
            this.loading = true;
            this.counts = [];

            try {
                this.counts = await grouseCount(this.topic.filter, ["published"]);
            } catch(e) {
                console.error("Error fetching daily counts", e);
            } finally {
                this.loading = false;
            }
        },

        renderChart() {
            const chart = this.$refs.chart;

            if (chart && this.beChart) {
                this.beChart
                    .reset()
                    .geometry(b3js.columnChart())
                    .scaleX(b3js.scaleTime())
                    .data(this.counts)
                    .element(chart)
                    .x(d => d.published)
                    .y(d => d.mentionCount)
                    .width(chart.clientWidth)
                    .height(chart.clientHeight)
                    .showXAxis(false)
                    .showLegend(false)
                    .render();
            }

        }
    }
}

</script>

<style scoped lang="sass">

.average-tooltip__chart-area
    display: flex
    p
        margin: auto 0
        text-align: right
        color: var(--be-colour-muted-text-dark)

.average-tooltip__chart
    width: 350px
    height: 120px
    margin: 0 auto
    padding-left: 10px
    flex-grow: 1

.average-tooltip__loading
    color: var(--be-colour-muted-text-dark)

</style>