import { render, staticRenderFns } from "./AttributeEditor.vue?vue&type=template&id=098a2b72&scoped=true"
import script from "./AttributeEditor.vue?vue&type=script&lang=js"
export * from "./AttributeEditor.vue?vue&type=script&lang=js"
import style0 from "./AttributeEditor.vue?vue&type=style&index=0&id=098a2b72&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098a2b72",
  null
  
)

export default component.exports