<template>
    <section class="commentary-plus-display">
        <div ref="editorjs"></div>
    </section>
</template>

<script>

import EditorJS from "@editorjs/editorjs";
import {ParagraphBlock} from "@/dashboards/widgets/commentaryplus/blockTools/ParagraphBlock";
import {currentAccountCode} from "@/app/utils/Account";
import {ListBlock, OrderedListBlock} from "@/dashboards/widgets/commentaryplus/blockTools/ListBlock";
import {CountInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/CountInlineTool";
import {EtcInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/EtcInlineTool";
import {TopInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/TopInlineTool";
import {SummaryInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/SummaryInlineTool";
import MarkdownBlock from "@/dashboards/widgets/commentaryplus/blockTools/MarkdownBlock";
import {IconsInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/IconsInlineTool";
import {
    Header1,
    Header2,
    Header3,
    Header4
} from "@/dashboards/widgets/commentaryplus/blockTools/Header";
import {BlockQuote} from "@/dashboards/widgets/commentaryplus/blockTools/BlockQuote";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import {Delimiter} from "@/dashboards/widgets/commentaryplus/blockTools/Delimiter";
import {ImageBlock} from "@/dashboards/widgets/commentaryplus/blockTools/ImageBlock";
import {FontSize} from "@/dashboards/widgets/commentaryplus/inlineTools/FontSize";
import {FontColor} from "@/dashboards/widgets/commentaryplus/inlineTools/FontColor";
import {getRootBrands} from "@/app/utils/Brands";
import {SummaryBlock} from "@/dashboards/widgets/commentaryplus/blockTools/SummaryBlock";

export default {
    name: "CommentaryPlusEditor",
    components: {},
    props: {
        isReadOnly: Boolean,
        blocks: String,
        filter: String
    },

    async mounted() {
        await this.setupEditor();
    },

    data() {
        return {
            editor: null,
            isInlineToolbarDropdownOpen: false,
            caretState: {
                blockIndex: 0,
                offset: 0
            }
        }
    },

    methods: {

        async setupEditor() {
            if (this.filter && !this.editor) {
                try {
                    const brandColors = new Set(getRootBrands().map((brand) => brand.colour));
                    brandColors.add('black');
                    this.editor = new EditorJS({
                        holder: this.$refs.editorjs,
                        autofocus: true,
                        readOnly: this.isReadOnly,
                        logLevel: 'ERROR',
                        tools: {
                            header: {
                                class: Header1,
                                shortcut: "CMD+SHIFT+H",
                                levels: [2, 3, 4],
                                inlineToolbar: true,
                                tunes: ['alignmentTune'],
                            },
                            header2: {
                                class: Header2,
                                inlineToolbar: true,
                                tunes: ['alignmentTune'],
                            },
                            header3: {
                                class: Header3,
                                inlineToolbar: true,
                                tunes: ['alignmentTune'],
                            },
                            header4: {
                                class: Header4,
                                inlineToolbar: true,
                                tunes: ['alignmentTune'],
                            },
                            list: {
                                class: ListBlock,
                                inlineToolbar: true,
                                tunes: ['alignmentTune']
                            },
                            orderedList: {
                                class: OrderedListBlock,
                                inlineToolbar: true,
                                tunes: ['alignmentTune']
                            },
                            paragraph: {
                                class: ParagraphBlock,
                                inlineToolbar: true,
                                config: {
                                    placeholder: "."
                                },
                                tunes: ['alignmentTune']
                            },
                            markdown: {
                                class: MarkdownBlock,
                                config: {
                                    filter: this.filter,
                                    accountCode: currentAccountCode()
                                },
                                tunes: ['alignmentTune']
                            },
                            blockQuote: {
                                class: BlockQuote,
                                inlineToolbar: true,
                                tunes: ['alignmentTune']
                            },
                            summaryBlock: {
                                class: SummaryBlock
                            },
                            delimiter: {
                                class: Delimiter
                            },
                            image: {
                                class: ImageBlock,
                            },
                            fontSize: {
                                class: FontSize
                            },
                            // fontColor: {
                            //     class: FontColor,
                            //     config: {
                            //         colors: [...brandColors]
                            //     }
                            // },
                            countInline: {
                                class: CountInlineTool,
                                config: {
                                    filter: this.filter
                                }
                            },
                            iconInline: {
                                class: IconsInlineTool,
                                shortcut: 'CMD+Z',
                                config: {
                                    filter: this.filter
                                }
                            },
                            etcInline: {
                                class: EtcInlineTool,
                                config: {
                                    filter: this.filter
                                }
                            },
                            topInline: {
                                class: TopInlineTool,
                                config: {
                                    filter: this.filter
                                }
                            },
                            summaryInline: {
                                class: SummaryInlineTool,
                                config: {
                                    filter: this.filter
                                }
                            },
                            alignmentTune: {
                                class: AlignmentTuneTool,
                                config: {
                                    default: "left",
                                    blocks: {
                                        header: 'left',
                                        list: 'left'
                                    }
                                },
                            },
                        },
                        onChange: async (api, event) => {
                            try {
                                if (!this.isReadOnly) {
                                    await this.save();
                                }
                            } catch (e) {
                                console.error(e);
                            }

                        }
                    });

                } catch (e) {
                    console.error(e);
                }

                await this.editor.isReady;
                if (this.blocks) await this.editor.render(JSON.parse(this.blocks));
                this.updateReadOnly();
                this.updateFilterOnElements(this.filter);

                this.$el.addEventListener("shouldSave", () => {
                    setTimeout(() => this.save(), 500);
                });

                this.$el.addEventListener('keydown', (e) => {
                    if (!this.editor.readonly && e.code === 'Escape') {
                        e.stopPropagation();
                        this.editor.inlineToolbar.close();
                    }
                });

                this.$el.addEventListener('shouldConvertToHeader', async (e) => {

                    let toolName = 'header';
                    let level = 1;

                    switch (e.detail.level) {
                        case 2:
                            toolName = 'header2';
                            level = 2;
                            break;
                        case 3:
                            toolName = 'header3';
                            level = 3;
                            break;
                        case 4:
                            toolName = 'header4';
                            level = 4;
                            break;
                    }
                    const defaultBlockData = await this.editor.blocks.composeBlockData(toolName);
                    const blockDataOverrides = {text: e.detail.data, level: level};
                    const blockData = Object.assign(defaultBlockData, blockDataOverrides);

                    const newBlock = this.editor.blocks.insert(
                        toolName,
                        blockData,
                        undefined,
                        this.editor.blocks.getCurrentBlockIndex(),
                        undefined,
                        false,
                    );


                    this.editor.blocks.delete(this.editor.blocks.getCurrentBlockIndex() + 1);

                })

                // fixing the inline toolbar issue
                if (!this.isReadOnly) {
                    let dropDown = this.$el.getElementsByClassName("ce-inline-toolbar__dropdown")[0];
                    let options = this.$el.getElementsByClassName("ce-conversion-toolbar")[0];
                    if (dropDown) {
                        dropDown.addEventListener("click", () => {
                            if (this.isInlineToolbarDropdownOpen) {
                                options.classList.remove("ce-conversion-toolbar--showed");
                                this.isInlineToolbarDropdownOpen = false;
                            } else {
                                options.classList.add("ce-conversion-toolbar--showed");
                                this.isInlineToolbarDropdownOpen = true;
                            }
                        })

                        window.addEventListener('mouseup', (e) => {
                            if (this.isInlineToolbarDropdownOpen && e.target.closest('div') !== dropDown && !dropDown.contains(e.target.closest('div'))) {
                                options.classList.remove("ce-conversion-toolbar--showed");
                                this.isInlineToolbarDropdownOpen = false;
                            }
                        });
                    }

                    // editojs block tunes alignment tool doesn't dispatch a change, so this is a hack to fix that
                    let inlineTunesButton = this.$el.getElementsByClassName("ce-toolbar__settings-btn")[0];
                    inlineTunesButton.addEventListener('click', async () => {
                        this.$el.getElementsByClassName("ce-popover__custom-content")[0]?.addEventListener('click', async () => {
                            try {
                                if (!this.isReadOnly) {
                                    await this.save();
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        });
                    })
                }

                this.$emit("completed");
            } else {
                console.error("Can not create editor without a filter.");
                // throw new Error("Can not create editor without a filter.")
            }
        },

        updateReadOnly() {
            this.$el.querySelectorAll("command-element")
                .forEach(c => c.dataset.readonly = this.isReadOnly.toString());
        },

        updateFilterOnElements(filter) {
            this.$el.querySelectorAll("command-element")
                .forEach(c => c.dataset.filter = filter);
        },

        async save() {
            let output = await this.editor.save();
            this.$emit('onEditorChange', output);
        },

    },

    watch: {
        filter(newFilter, oldFilter) {

            if (!oldFilter && newFilter) {
                this.setupEditor();
                return;
            }

            this.updateFilterOnElements(this.filter);

            let blocks = this.editor.blocks;
            let count = blocks.getBlocksCount();
            for (let i = 0; i < count; i++) {
                let block = blocks.getBlockByIndex(i);
                if (block.name === "markdown") {
                    block.call("refresh", newFilter);
                    block.dispatchChange();
                }
            }

        }
    },

    computed: {
        attrs() {
            return this.dto.attrs;
        },
    },

    async beforeDestroy() {
        if (this.editor) {
            await this.editor.isReady;
            this.editor.destroy();
        }
    },

}
</script>

<style scoped>

::v-deep .codex-editor--narrow .codex-editor__redactor {
    margin-right: 0;
}

::v-deep .codex-editor--narrow .codex-editor__redactor {
    padding-bottom: 50px !important;
}

::v-deep .ce-inline-toolbar {
    background-color: var(--background-dialog-buttoncontainer);
    border: var(--border-separation);
    border-radius: 3px;
}

::v-deep .ce-conversion-toolbar {
    background-color: var(--background-dialog-buttoncontainer);
    border: var(--border-separation);
    border-radius: 3px;
}

::v-deep .ce-popover {
    background-color: var(--background-dialog-buttoncontainer);
    border: var(--border-separation);
    border-radius: 3px;
}

::v-deep .ce-popover-item__icon {
    background-color: var(--background-dialog-buttoncontainer);
    border: var(--border-separation);
}

::v-deep .ce-conversion-tool__icon {
    background-color: var(--background-dialog-buttoncontainer);
    border: var(--border-separation);
}

::v-deep .ce-block--selected .ce-block__content {
    //background-color: var(--background-dialog-buttoncontainer);
    //border: var(--border-separation);
}

::v-deep .ce-settings {
    position: relative;
}

::v-deep .ce-popover {
    overflow: visible;
}

::v-deep .ce-toolbar__plus {
    //color: whitesmoke;
}

::v-deep .ce-toolbar__settings-btn {
    //color: whitesmoke;
}

::v-deep .ce-inline-toolbar__dropdown:hover {
    background: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .ce-inline-toolbar__dropdown {
    border-right: var(--border-separation);
}

::v-deep .ce-inline-tool:hover {
    background-color: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .ce-conversion-tool:hover {
    background: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .ce-popover-item:hover:not(.ce-popover__item--no-visible-hover) {
    background-color: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .ce-popover-item {
    border-radius: 3px;
    color: white;
}

::v-deep .cdx-search-field {
    border-radius: 3px;
    border: 1px solid #aaaaaa;
    background-color: #414141;
}

::v-deep .cdx-search-field__input {
    color: rgb(160, 160, 160);
}

::v-deep .ce-toolbar__plus:hover {
    background-color: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .ce-toolbar__settings-btn:hover {
    background-color: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .ce-delimiter:before {
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
    height: 30px;
    letter-spacing: .1em;
    border-top: thin dashed var(--be-colour-mid-grey);;
}

::v-deep .ce-inline-tool .selectionList .selection-list-wrapper {
    background: var(--background-dialog-buttoncontainer);
    border: var(--border-separation);
}

::v-deep .ce-inline-tool .selectionList .selection-list-wrapper .selection-list-option {
    border: var(--border-separation);
}

::v-deep .ce-inline-tool .selectionList .selection-list-wrapper .selection-list-option:hover {
    background-color: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .ce-inline-tool .selectionList .selection-list-wrapper .selection-list-option-active {
    background-color: var(--be-filter-tag-hover-colour-subtle);
}

::v-deep .cdx-list {
    color: black;
}

::v-deep .cdx-search-field__input {
    color: white;
}

</style>
