<template>
    <span class="tag-input uneditable-input span12"
          :class="{readonly: readOnly}"
          tabindex="0"
          @click="click($event)"
          ref="value"/>
</template>

<script>
    import {publishedTagConverter} from "@/components/pickers/dates/DateRangePickerUtilities";
    import {showPublishedPickerDialog} from "@/app/framework/dialogs/Dialog";

    /**
     * Edits published date expressions by wrapping the PublishedPicker.
     */
    export default {
        name: "PublishedInput",

        props: {
            options: {
                default: function() { return { } },
                type: Object
            },
            value: String,
            readOnly: Boolean,
            allowHours: {
                type: Boolean,
                default: true
            },
            allowTime: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {

            }
        },

        watch: {
            value() { this.updateDom() }
        },

        mounted() {
            this.updateDom();
        },

        methods: {
            click(event) {
                showPublishedPickerDialog({
                    onSelected: (val) => this.$emit("input", val),
                    date: this.value,
                    allowHours: this.allowHours,
                    allowTime: this.allowTime
                });
            },

            updateDom() {
                let binding = { boundEls: [this.$refs.value] }
                publishedTagConverter.call(binding, null, this.value, null, null, null, this.options.labelPrefix)
            },
        }
    }
</script>

<style scoped>

</style>