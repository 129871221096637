<template>
    <div>
        <slot v-if="!error" :mentions="mentions" :loading="loading">
            <div class="mention-panel-with-mentions__container">
                <loading-message v-if="loading && !mentions.length" message="Fetching mentions..."/>
                <mention-list :mentions="mentions"/>
                <div v-if="loading && mentions.length"
                     class="mention-panel-with-mentions__overlay">
                    <loading-message message="Fetching more mentions..."/>
                </div>
            </div>
        </slot>

        <slot v-if="error" name="error-message" :error="error">
            We're not able to read your mentions at the moment. Please
            try again in a few minutes.
        </slot>
    </div>
</template>

<script>

import {getMentions} from "@/data/Grouse";
import MentionList from "@/app/toplevel/mentions/components/MentionList";
import LoadingMessage from "@/components/LoadingMessage";

export default {
    components: {LoadingMessage, MentionList},
    props: {
        filter: {
            type: String,
            required: true
        },

        numMentions: {
            type: Number,
            default: 30
        },

        orderBy: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            loading: true,
            mentionFetchPromise: null,
            error: null,

            mentions: []
        }
    },

    watch: {
        filter() {
            this.fetchMentions();
        },

        numMentions() {
            this.fetchMentions();
        },

        orderBy() {
            this.fetchMentions();
        }
    },

    mounted() {
        this.fetchMentions();
    },

    beforeDestroy() {
        this.cancelExistingFetch();
    },

    methods: {
        async fetchMentions() {
            if (!this.filter) return;
            try {
                this.loading = true;
                this.error = null;
                this.cancelExistingFetch();

                this.mentionFetchPromise = getMentions(this.filter, this.orderBy, this.numMentions, null, false);
                this.mentions = await this.mentionFetchPromise;
                this.mentionFetchPromise = null;
            } catch(e) {
                console.error(e);
                this.error = e;
                this.loading = false;
                this.mentionFetchPromise = null;
            } finally {
                this.loading = false;
            }
        },

        cancelExistingFetch() {
            if (this.mentionFetchPromise?.cancel) {
                this.mentionFetchPromise.cancel();
                this.mentionFetchPromise = null;
            }
        }
    }
}

</script>


<style scoped lang="sass">

.mention-panel-with-mentions__container
    position: relative
    height: max-content

.mention-panel-with-mentions__overlay
    position: absolute
    inset: 0
    background: var(--body-background-colour)
    z-index: 5
    animation: mention-panel-with-mentions__fadein both 1s

@keyframes mention-panel-with-mentions__fadein
    0%
        opacity: 0
    100%
        opacity: 0.8


</style>