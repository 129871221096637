import {isDebugModeEnabled} from "@/app/Features";
import VuexStore from "@/store/vuex/VuexStore";
import {getBrowserId} from "@/app/utils/Util";
import {grouse} from "@/store/Services";
import {currentAccountCode} from "@/app/utils/Account";


/**
 * These are calls that have been queued up for grouse.
 * We do not want to overload grouse, especially when using
 * http/2 multiplexing.
 *
 * @see grouseFindAndExecute
 * @type {GrouseCallRecord[]}
 */
let grouseCalls = [];

/**
 * The maximum number of concurrent calls to grouse that we are allowing to be made.
 * @type {number}
 */
const MAX_ALLOWED = 12;


/**
 * Ensures that the queued calls are executed.
 * @see grouseCalls
 * @return {Promise<void>}
 */
async function grouseFindAndExecute(record) {
    if (!grouseCalls.length) return;

    let countCalls = () => grouseCalls.reduce((total, current) => current.started && !current.finished ? total + 1: total, 0);
    let running = countCalls();

    // We want to be careful with catching errors inside of the loop,
    // so that we don't go into an infinite loop.
    for (const current of grouseCalls) {
        // If someone has called this function looking to run a particular
        // grouse request (the record), we want to return quickly as soon
        // as that record has a promise associated with it.
        if (record?.promise) return record.promise;
        if (current.started) continue;              // No need to start this.
        if (running >= MAX_ALLOWED) continue;       // Already got enough things running.

        if (!current.task) {
            console.warn("Found grouse record with no task");
            current.started = current.finished = true;
            current.promise = Promise.reject("No task for this grouse call");
            continue;
        }

        current.started = true;
        current.promise = new Promise(current.task)
            .finally(() => {
                current.finished = true
            });

        // All the increments happen synchronously.
        if (++running >= MAX_ALLOWED) {
            await current.promise;
            current.finished = true;
            running = countCalls();
        }
    }

    // If we are here, there are still things in the queue that
    // possibly need running, and if we have a call we are waiting for
    // (see the record argument) then it hasn't yet been kicked off.
    // so let's find arbitrary work we can wait on and then recurse
    // to continue executing any awaiting grouse calls.
    if (running >= MAX_ALLOWED) {
        const stillRunning = grouseCalls.find(record => record.promise && !record.finished);
        if (stillRunning) {
            await stillRunning.promise;
        }
    }

    // Some record keeping cleanup.
    grouseCalls = grouseCalls.filter(record => !record.finished); // Fixed our records.

    // See if we need to recurse.
    if (grouseCalls.find(record => !record.started)) {
        await grouseFindAndExecute(); // Recursively ensure that we have completed everything.
    }
}

class GrouseCallRecord {
    started = false;
    finished = false;
    cancelled = false;
    promise = null;
    task = null;

    constructor(task) {
        this.task = task;
    }
}


/**
 *
 * @param grouseCall {Function}
 * @return {Promise<void>}
 */
async function recordAndExecute(grouseCall) {

    let record = new GrouseCallRecord(grouseCall);

    grouseCalls.push(record);

    return grouseFindAndExecute(record)
        .then(() => {
            if (record.promise) return record.promise;
            else {
                throw new Error("No promise on record for this grouse call");
            }
        });
}



/**
 * Get some JSON data from Grouse and returns a cancellable promise.
 * THIS DOES NOT SET ANY CACHE TOKEN. You need to set this yourself.
 *
 * @param endpoint {string}
 * @param [params = {}] {Object} Url parameters you wish to pass through. Should not be url encoded.
 * @param abortController {AbortController?} If you want to pass in your own abort controller for cancelling requests.
 * @return {Promise<AxiosResponse<any>>}
 */
export function grouseGet(endpoint, params = {}, abortController = new AbortController()) {

    let promise = recordAndExecute((resolve, reject) => {
        try {
            grouse
                .get(endpoint, { params, signal: abortController.signal })
                .then(response => resolve(response.data))
                .catch(e => {
                    reject(e);
                });
        } catch (e) {
            reject(e);
        }
    });

    promise.cancel = () => abortController?.abort();
    return promise;
}

/**
 * Returns a particular mention for an account. This is enough information to show in the MentionItemView as well.
 * This is a cancellable promise, like so: getMention(url).cancel();
 * @param code {String}
 * @param id {String}
 * @param [withCache=true] {Boolean, optional} defaults to true.
 */
export function getMention(code, id, withCache) {
    withCache = withCache === undefined ? true : withCache;


    const url = '/v4/accounts/' + code + "/mentions/" + id;

    const params = {
        useAccountTimeZone: true,
        select: "+combinedHtml,phrases,brand,discardedByRule,region,crowdJobs",
    };

    if (withCache) params.cacheToken = getBrowserId();
    if (isDebugModeEnabled()) params.debug=true;

    return grouseGet(url, params);
}

/**
 * Fetch a list of mentions.
 * @param {FilterString} filter
 * @param {Object?} orderBy
 * @param {Object?} limit
 * @param [multiBrand = false]
 * @param [noCache = false]
 * @returns {Promise<unknown>}
 */
export function getMentions(filter, orderBy, limit= 30, multiBrand = false, noCache = false) {
    const code = VuexStore.state.account.code;
    if (!code) throw new Error("No account code supplied");
    if (!filter) throw new Error("No filter supplied");

    const url = '/v4/accounts/' + code + "/mentions";

    const params = {
        useAccountTimeZone: true,
        filter: filter,
        limit:  limit,
        select: "+combinedHtml,phrases,brand,discardedByRule,region,crowdJobs"
    };

    if (isDebugModeEnabled()) params.debug = true;
    if (!noCache) params.cacheToken = getBrowserId();
    if (multiBrand) params.multiBrand = true;
    if (orderBy && orderBy.length) params.orderBy = orderBy.join(',');

    return grouseGet(url, params);
}




/**
 *
 * @param filter {String}
 * @param groupBy {Array,optional}
 * @param select {Array,optional}
 * @param limit {Number,optional}
 * @param params {Object,optional}
 * @param code {String,optional}
 * @param {boolean} [useCache = true]
 * @returns {Promise<unknown>}
 */
export function count(filter, groupBy, select, limit, params, code, useCache) {
    code ??= currentAccountCode();
    useCache ??= true;

    params = {...params};
    params.filter = filter;
    if (useCache) params.cacheToken = getBrowserId();
    if (isDebugModeEnabled()) params.debug = true;
    if (groupBy && groupBy.length) params.groupBy = groupBy.join(','); //groupBy.map(encodeURIComponent).join(',');
    if (select && select.length) params.select = select.join(','); // select.map(encodeURIComponent).join(',');
    if (limit) params.limit = limit;

    const signal = new AbortController();

    let promise = recordAndExecute((resolve, reject) => {
        grouse.get("/v4/accounts/" + code + "/mentions/count", { params, signal: signal.signal })
            .then(response => resolve(response.data))
            .catch(e => reject(e))
    });

    promise.cancel = () => signal.abort();
    return promise;
}


/**
 * Create a link to a Grouse endpoint.
 */
export function toGrouseLink(endpoint, data, noAuth) {
    // noinspection JSUnresolvedVariable
    const grouseUrl = Beef?.startupOptions?.grouseUrl;

    // proxy via beef if auth is required e.g. CSV download link
    let link = noAuth ? grouseUrl + endpoint : "/api" + endpoint;
    let first = true;
    if (data) {
        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                if (first) {
                    link += "?";
                    first = false;
                } else {
                    link += "&";
                }
                link += key + "=" + encodeURIComponent(value);
            }
        });
    }
    return link;
}

export async function grousePut(endpoint, data = {}) {
    if (isDebugModeEnabled()) data.debug = true;

    const response = await grouse.put(endpoint, data);
    return response.data;
}
