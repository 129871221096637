/*
   Shows an individual, top level panel for naming
   classes of metrics, such as "Customer Experience"
*/

<template>
    <section class="metric-class" @click.stop="$emit('expand', metricClass)"
             ref="target"
             @mouseover="showTooltip"
             @keydown="$emit('keydown', $event)"
             tabindex="0">
        <img :src="metric.image" class="metric-class__image" v-if="metric.image">
        <header>
            <h1 :class="{error: !metric.title}">{{metric.title || `«unrecognised class ${metricClass}»`}}</h1>
        </header>

        <section class="metric_class__content">

            <p :class="{error: !metric.description}">
                {{metric.description || "«no description»"}}
            </p>
        </section>
    </section>
</template>

<script>

    import { availableMetrics } from './availableMetrics';
    import { showTooltipComponent } from "@/components/tooltip/TooltipUtilities";
    import MetricClassTooltip from './MetricClassTooltip';

    export default {
        name: "MetricClass",

        components: { },

        props: {
            metricClass: String
        },

        computed: {
            metric() {
                return availableMetrics[this.metricClass];
            }
        },

        methods: {
            showTooltip() {
                showTooltipComponent(this.$refs.target, MetricClassTooltip,
                    {klass: this.metric},
                    { positions: ['right', 'left']})
            }
        }
    }
</script>

<style scoped lang="sass">

    .metric-class
        --padding: 10px
        --border-radius: 3px
        border-radius: var(--border-radius)
        cursor: pointer
        background: var(--be-colour-light-grey)
        overflow: hidden
        outline: none

        display: grid
        grid-row-start: span 3
        grid-template-rows: auto auto minmax(100px, 1fr)
        row-gap: 0
        column-gap: 0

        transition: background 0.2s

        &:hover, &:focus-visible // Focus is so that the tab key selection is clear
            transition: background 0.1s
            background: #fff

        &:focus-visible
            outline: var(--focus-border)
            outline-width: 3px

    .error
        color: red !important
        font-variant: small-caps

    .metric-class__image
        width: 100%

    .metric_class__content
        padding: 0 var(--padding)
        height: 100%
        border-bottom-left-radius: var(--border-radius)
        border-bottom-right-radius: var(--border-radius)

    h1
        font-size: 1.2rem
        line-height: 1.4em
        margin: auto 0
        padding: var(--padding)
        color: var(--be-colour-text)
        font-weight: bold
        box-sizing: border-box

    //noinspection CssUnknownProperty
    p
        color: var(--be-colour-text)
        font-size: 0.8rem
        line-height: 1.0rem
        font-family: var(--widget-font)
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 5

    .metric-class:hover, .metric-class:focus
        box-shadow: 0 2px 5px rgba(0,0,0,0.4)

    // hidpi displays, like macbooks
    @media (max-height: 900px), (max-width: 1440px)
        h1
            font-size: 1.05rem
            line-height: 1.1em

    //noinspection CssUnknownProperty
    @media (max-height: 800px), (max-width: 1280px)
         p
            -webkit-line-clamp: 3

    @media (max-height: 700px), (max-width: 1279px)
        p
            display: none

    @media (max-width: 900px)
        p
            display: block
        img
            display: none
        .metric-class
            margin-bottom: 10px
            overflow: initial
            .metric_class__content
                height: auto
</style>