import {deprecatedFetchBrand} from "@/data/DeprecatedBeefCache";
import _ from 'underscore';

Beef.module("HistoricalSearch.BrandPhrasesPopup.PhrasePreview").addInitializer(function() {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/historical-search/popup/setup/brand-phrases/PhrasePreview.handlebars"),

        /**
         * Refreshes the phrase preview region to show the phrases from the given brands.
         */
        updatePreview: function(brands) {
            var that = this;
            var jobPhrases = this.model.get("job").searchPhrases || [];
            var cache = this.model.get("cache") || {};
            var preview = this.model.get("preview") || {};
            var brandMap = getIncludeExcludeBrands(brands);
            if (brandMap.includeList.length < 1) {
                this.model.set("phrases", []);
                this.render();
            } else {
                var renderPreview = _.after(brandMap.includeList.length, function(results) {
                    var toAdd = [];
                    _.each(results, function(brandPhrases) {
                        toAdd = toAdd.concat(brandPhrases);
                    });
                    var preview = Beef.HistoricalSearch.RetrievalSetupPopup.buildMergePreview(jobPhrases, toAdd);
                    that.model.set("phrases", preview.toAdd);
                    that.render();
                });
                var results = {};
                var cb = function(data) {
                    results[data.brand.id] = extractPhrases(data.brand, brandMap.excludeMap);
                    renderPreview(results);
                }.bind(this);
                for (var i = 0; i < brandMap.includeList.length; ++i) {
                    deprecatedFetchBrand(this.model.get("accountCode"),
                        brandMap.includeList[i], cb, cache);
                }
                this.model.set("cache", cache);
            }
        }
    });

    /**
     * Separates a list of brands into a brands that should be included and excluded.
     * @param brands A list of brands.
     * @return {{includeList: Array, excludeMap: {}}} A map containing a list of brand ids to include
     *     and a map of brand ids to exclude.
     */
    function getIncludeExcludeBrands(brands) {
        var include = [];
        var exclude = {};
        (brands || []).forEach(function(id) {
            if (id.startsWith('-')) {
                exclude[id.slice(1)] = true;
            } else {
                include.push(id);
            }
        });
        return { includeList: include, excludeMap: exclude };
    }

    /**
     * Extracts the phrases from a brand tree while excluding phrases from brands in the given exclude map.
     * @return {Array} A list of phrases.
     */
    function extractPhrases(brand, exclude) {
        var phrases = [];
        if (!exclude[brand.id]) {
            (brand.phrases || []).forEach(function(phrase) {
                if (!phrase.inactive && !phrase.deleted) phrases.push(phrase.q);
             });
            (brand.children || []).forEach(function(subbrand) {
                phrases = phrases.concat(extractPhrases(subbrand, exclude));
            });
        }
        return phrases;
    }
});
