Beef.module("EditDashboardJson").addInitializer(function(startupOptions) {

    // these are fields that cannot be edited via JSON so leave these out
    var SKIPPED_FIELDS = {};
    ['lastUpdated', 'lastUpdatedBy']
        .forEach(function(d) { SKIPPED_FIELDS[d] = true });

    /**
     * The whole app is reloaded if the user actually makes an edit.
     */
    this.createView = function(endpoint) {
        var m = new Backbone.Model({endpoint: endpoint, state: LOADING, text: 'Loading ...'});
        var view = new View({model: m});
        view.refresh();
        return view;
    };

    // the dialog transitions between these states ('state' in its model)
    var LOADING = 0, EDIT = 1, SAVING = 2;

    var spinner = "<span class='spinner'/>";

    var View = Beef.BoundItemView.extend({

        template: require("@/dashboards/EditDashboardJson.handlebars"),

        attributes: { class: "edit-dashboard-json dialog" },

        events: {
            "click .dialog-title .close":   "close",
            "click .cancel":                "close",
            "click .ok":                    "ok",
            "click .reset":                 'refresh'
        },

        modelEvents: {
            "change": "modelChanged"
        },

        refresh: function() {
            var that = this;
            this.model.set('state', LOADING);
            Beef.Sync.mashGET(this.model.get('endpoint'), null, function(data){
                that.model.set({text: JSON.stringify(data, function(key, value) {
                    return SKIPPED_FIELDS[key] ? undefined : value;
                }, 2), state: EDIT});
            });
        },

        onRender: function() {
            var that = this;
            this.updateControls();
            setTimeout(function() { that.$('textarea')[0].focus(); });
        },

        modelChanged: function() {
            this.updateControls();
        },

        getState: function() {
            return this.model.get('state');
        },

        updateControls: function() {
            var state = this.getState();
            this.$('.ok').html(state == SAVING ? spinner : "Ok");
            this.$('.ok').toggleClass("disabled", state != EDIT);
            this.$('textarea').attr('readonly', state != EDIT ? 'readonly' : null);
        },

        close: function() {
            if (!this.$el[0].parentElement) return; // already closed
            Beef.BoundItemView.prototype.close.call(this);
        },

        ok: function(ev) {
            ev.preventDefault();
            if (this.getState() != EDIT) return;
            var that = this;
            var text = this.model.get('text');
            var endpoint = this.model.get('endpoint');
            var method = "PUT";
            try {
                var r = JSON.parse(text);
                if (!r.id) {    // creating new dashboard
                    method = "POST";
                    endpoint = endpoint.substring(0, endpoint.lastIndexOf('/'));
                }
            } catch (e) {
                window.alert(e);
                return;
            }
            var cb = function(data) {
                // reload the whole app maybe with different path to new dashboard
                if (data.id !== that.model.id) {
                    var path = window.location.pathname;
                    window.location.pathname = path.substring(0, path.lastIndexOf('/') + 1) + data.id;
                } else {
                    window.location.reload();
                }
            };
            this.model.set('state', SAVING);
            Beef.Sync.mashCall(method, endpoint, text, cb)
                .fail(function(xhr, errorType, error){
                    window.alert("Error [" + errorType + "]: " + error);
                    that.model.set('state', EDIT);
                });
        }
    });

});