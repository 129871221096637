/**
 * Published filter option picker.
 */

import _ from 'underscore';
import moment from "moment";
import {DATE_RANGE, isHour, parseDate} from "@/app/utils/Dates";
import {formatPublishedLabel} from "@/components/pickers/dates/DateRangePickerUtilities";

Beef.module("PublishedPicker").addInitializer(function(startupOptions) {

    var hourVariants = [ "hh:mm a", "h:mm", "HHmm", "hhmm a", "h:mm a", "hh:mma", "h:mma", "ha", "h a", "H" ];
    var hourFormats = ["HH:mm"].concat(hourVariants);

    this.View = Beef.Picker.View.extend({
        attributes: { class: "published-picker dialog" },

        template: require("@/dashboards/filter/pickers/published/PublishedPicker.handlebars"),

        regions: {
            startCalRegion: ".start-calendar",
            endCalRegion: ".end-calendar"
        },

        events:Object.assign({
            "click .no-date": "noDate",
            "click .time-button": "toggleTime",
            "mousedown .time-input": "stopPropagation",  // Picker.js captures mouse events to stop unwanted focus, but
            // here we want to allow focus.
            "mousedown .dialog-body": "unfocusTime",
            "keyup .time-input": "timeKeyUp",
            "keyup #from-time": "fromTimeKeyUp",
            "keyup #to-time": "toTimeKeyUp",
            "blur .time-input": "timeBlurred",
            "focus .time-input": "timeFocussed"
        }, Beef.Picker.View.prototype.events),

        initialize: function(options) {
            this.model.set('notRequired', options.notRequired);
            this.model.set('timeDisabled', options.timeDisabled);
            this.model.set('promptText', Object.assign({overall: 'Include conversation published in the last', multiple: 'mentions'}, options.promptText || {}));
            var that = this;
            var calendarOptions = options.calendarOptions ? options.calendarOptions : {};
            this.startCal = new Beef.Calendar.View(calendarOptions);
            this.startCal.model.on('change:date', function() { that.calStartDateChanged() });
            this.endCal = new Beef.Calendar.View(calendarOptions);
            this.endCal.model.on('change:date', function() { that.calEndDateChanged() });
        },

        items: {},

        modelEvents: {
            "change:selection": "updateView"
        },

        onFirstRender: function() {
            if (this.model.get('selection')) this.$('.no-date').attr('checked', false);
            this.startCalRegion.show(this.startCal);
            this.endCalRegion.show(this.endCal);
            this.updateView();
        },

        noDate: function() {
            var v = "";
            if (!this.$('.no-date').attr('checked')) {
                this.model.unset('selection');
            } else {
                v = 'WEEK';
                this.model.set('selection', v);
            }
            this.trigger('change', [v]);
        },

        toggleTime: function(ev) {
            if (ev) ev.preventDefault();

            if (this.getValue() === '24HOURS') {
                var start = this.startCal.model.get('start');
                var end = this.startCal.model.get('end');

                var now = moment();
                start.setHours(now.hours());
                start.setMinutes(now.minutes());

                end.setHours(now.hours());
                end.setMinutes(now.minutes());

                this.setCalendarDates(start, end);

                this.$('#from-time').val(now.format("HH:mm"));
                this.$('#to-time').val(now.format("HH:mm"));
            }

            this.$('.time-display').slideToggle('fast');
            $(".btn.time-button", this.$el).toggleClass("dropup");
        },

        getValue: function() {
            var value = this.model.get('selection');

            // The token and the string are not the same for 24hours,
            // so we need to fiddle a bit.
            if (value == 'TWENTY_FOUR_HRS') value = '24HOURS';
            return value
        },

        updateView: function() {
            if (this.model.get('selection')) this.$('.no-date').attr('checked', false);
            else this.$('.no-date').attr('checked', true);
            // don't bother updating view if we haven't been rendered yet
            if (!this.startCalRegion.currentView) return;

            $(".btn", this.$el).toggleClass("active", false);
            var value = this.getValue();


            var start, end;
            var exact;
            var fn = DATE_RANGE[value];
            if (fn) {
                var range = fn();
                start = range.start;
                end = range.end;
                exact = true;
            } else if (value) {
                var a = value.split("-");
                start = parseDate(a[0]);
                end = parseDate(a[1]);
                value = "CUSTOM";
                exact = !isHour(a[0]) && !isHour(a[1]);
            }

            this.$(".btn[data-value=" + value + "]").toggleClass('active', true);

            if (start == 'today') start = Date.today();
            if (end == 'today') end = Date.today();

            if (exact) {
                let now = moment();
                if (value == '24HOURS' || value == "TODAY") {
                    end.setHours(now.hours());
                    end.setMinutes(now.minutes());

                    if (value == '24HOURS') {
                        start.setHours(now.hours());
                        start.setMinutes(now.minutes());
                    }
                }
                else {
                    end.setHours(23);
                    end.setMinutes(59);
                }
            }

            if (!this.ignoreRedraw) {
                this.setCalendarDates(start, end);
            }

            this.$(".range").text(formatPublishedLabel(start, end, true));

            var duration = moment.duration(moment(end).add(exact ? 'days' : 'minutes', exact ? 0 : 1).diff(moment(start)));
            var qualifier = exact ? "" : "about";
            if (value == 'TODAY') {
                qualifier = "about";
                duration = moment.duration(moment().diff(moment().startOf('day')));
            }
            else if (value == '24HOURS') {
                duration = moment.duration(24, 'hours');
            }
            var message = "";
            if (value == '24HOURS') {
                message = "exactly 24 hours"
            }
            else message = qualifier + " " + duration.humanize();

            this.$(".days").text(message.trim());

            this.$('.from-date').html(start ? start.toString("d MMMM, yyyy") : " an ungiven date");
            this.$('.to-date').html(end ? end.toString("d MMMM, yyyy") : " an ungiven date");

            if (!this.ignoreRedraw) {
                if (!exact && start && end) {
                    this.$('#from-time').val(start.toString("HH:mm"));
                    this.$('#to-time').val(end.toString("HH:mm"));

                    if (!this.$('.time-display').is(':visible')) this.toggleTime();
                }
                else {
                    this.$('#from-time').val('00:00');
                    this.$('#to-time').val('23:59');
                }
            }
        },

        setCalendarDates: function(start, end) {
            this.ignoreCalEvents = true;    // don't react to changed:date events from the calendars
            try {
                this.startCal.model.set('date', start);
                this.startCal.model.set('start', start);
                this.startCal.model.set('end', end);

                this.endCal.model.set('date', end);
                this.endCal.model.set('start', start);
                this.endCal.model.set('end', end);
            } finally {
                this.ignoreCalEvents = false;
            }
        },

        setSelection: function(v) {
            this.model.set('selection', v);
        },

        stopPropagation: function(ev) {
            ev.stopPropagation();
        },

        click: function(ev) {
            var v = $(ev.target).attr('data-value');
            if (!v) return;
            if (v == "CUSTOM") v = this.calcCustomValue();
            this.model.set('selection', v);
            this.trigger('change', [v]);
            if (this.parentPopup) this.parentPopup.hide();
        },

        normaliseTimeValue: function(text) {
            if (text) {
                text = _(text.split(' '))
                    .chain()
                    .map(function (s) {
                        return s.trim();
                    })
                    .filter(function(s) {
                        return s.length != 0;
                    })
                    .value();
                text = text.join(' ');

                text = text
                    .toLowerCase()
                    .replace(/[h\.]/, ':');
            }

            return text;
        },

        timeKeyUp: function(ev) {
            var $field = $(ev.target);
            this.parseNewTime($field);
        },

        fromTimeKeyUp: function(ev) {
            if (ev.keyCode === 13) {
                this.$('#from-time').blur();
                this.$('#to-time').focus();
            }
        },

        toTimeKeyUp: function(ev) {
            if (ev.keyCode === 13) {
                this.$('#to-time').blur();
            }
        },

        parseNewTime: function($field) {
            if (this.$('.no-date').attr('checked')) return;
            var value = this.normaliseTimeValue($field.val());

            var time = moment(value, ["HH:mm"].concat(hourVariants), true);
            if (!time.isValid()) {
                $field.toggleClass('error', true);
            }
            else {
                $field.toggleClass('error', false);

                var isFromTime = $field.attr('id') == 'from-time';
                var start = this.startCal.model.get('start');
                var end = this.startCal.model.get('end');

                var toSet = isFromTime ? start : end;
                toSet.setHours(time.hours());
                toSet.setMinutes(time.minutes());
                this.setCalendarDates(start, end);

                this.ignoreRedraw = true;
                try {
                    this.timeChanged(isFromTime);
                }
                finally {
                    this.ignoreRedraw = false;
                }
            }
        },

        validateTime: function() {
            var $start = this.$('#from-time'),
                $end = this.$('#to-time'),
                $message = this.$('.error-message');

            var start = moment($start.val(), hourFormats, true),
                end = moment($end.val(), hourFormats, true);

            var startValid = true;
            if (!start.isValid()) {
                startValid = false;
                $start.toggleClass('error', true);
            }

            var endValid = true;
            if (!end.isValid()) {
                endValid = false;
                $end.toggleClass('error', true);
            }

            if (!startValid || !endValid) return false;

            start = moment(this.startCal.model.get('start'));
            end = moment(this.startCal.model.get('end'));

            if (start.isAfter(end)) {
                $start.toggleClass('error', true);
                $end.toggleClass('error', true);
                $message.html("The start time begins <em>after</em> the end time.");
                return false;
            }

            $start.toggleClass('error', false);
            $end.toggleClass('error', false);
            $message.empty();
            return true;
        },

        timeBlurred: function(ev) {
            var $field = $(ev.target),
                value = $field.val();
            value = this.normaliseTimeValue(value);

            if (!moment(value, "HH:mm", true).isValid()) {
                var time = moment(value, hourVariants, true);
                if (time.isValid()) $field.val(time.format("HH:mm"));
            }

            this.validateTime();
        },

        timeFocussed: function(ev) {
            // This is placed in a delay since after a input receives focus for a second
            // time, something always unselects the text. This is a hack to prevent that.
            setTimeout(() => {
                var $field = this.$(ev.target);
                $field.select();
            }, 250);
        },

        unfocusTime: function(ev) {
            this.$('#from-time').blur();
            this.$('#to-time').blur();
        },

        timeChanged: function(startChanged) {
            if (this.validateTime()) {
                var v = this.calcCustomValue(startChanged);
                this.model.set('selection', v);
                this.trigger('change', [v]);
            }
        },

        calcCustomValue: function(startChanged, normaliseStart, normaliseEnd) {
            var start = this.startCal.model.get('date');
            if (start) start = moment(start);
            var end = this.endCal.model.get('date');
            if (end) end = moment(end);

            if (normaliseStart) {
                start.hours(0);
                start.minutes(0);
            }

            if (normaliseEnd) {
                end.hours(23);
                end.minutes(59);
            }

            if (!start || !end || start.isAfter(end)) {
                if (startChanged) end = start.clone().hours(23).minutes(59);
                else start = end.clone().startOf('day');
            }

            var format = 'YYYY/MM/DD';
            if (start.hours() != 0 || start.minutes() != 0 || end.hours() != 23 || end.minutes() != 59) {
                format = 'YYYY/MM/DD HH:mm';
            }

            return start.format(format) + "-" + end.format(format);
        },

        calDateChanged: function(startChanged, normaliseStart, normaliseEnd) {
            if (this.ignoreCalEvents) return;
            var v = this.calcCustomValue(startChanged, normaliseStart, normaliseEnd);
            this.model.set('selection', v);
            this.trigger('change', [v]);
        },

        calStartDateChanged: function() {
            this.calDateChanged(true, true, false);
        },

        calEndDateChanged: function() {
            this.calDateChanged(false, false, true);
        }
    });


    /**
     * Attach a picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
