import {getExclusionSuggestions} from "@/app/utils/Phrases";
import {getGoodness} from "@/data/goodness";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";

Beef.module("PhraseGoodness").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/setup/brands/PhraseGoodness.handlebars"),

        attributes: { class: "phrase-goodness" },

        modelEvents: {
            "change": "render"
        },

        events: {
            "click .phrase-mention": "mentionClicked",
            "change .phrase-mention": "checkboxChanged"
        },

        templateHelpers: function() {
            return {
                rootBrand: this.options.rootBrand,
                high: function() { return this.level === "HIGH" },
                medium: function() { return this.level === "MEDIUM" },
                no: function() { return this.level === "NO" },
                ok: function() { return this.level && this.level !== "HIGH" && this.level !== "NO" }
            }
        },

        setQuery: function(query) {
            if (query == this.query) return;
            this.model.clear();
            getGoodness(this.options.rootBrand.id, query)
                .then(data => {
                    this.model.set(Object.assign({message: "No data was returned. WE DON'T KNOW WHAT VOLUMES THIS WILL BRING IN."}, data[0]));
                })
                .catch(e => {
                    console.error(e);
                    notifyUserOfError("There has been an error determining phrase volumes");
                });
        },

        mentionClicked: function(ev) {
            var $t = $(ev.target);
            if ($t.is("a") || $t.is("input")) return;
            ev.preventDefault();
            var $m = $t.closest(".phrase-mention");
            var sel = !$m.hasClass("selected");
            $m.toggleClass("selected", sel);
            $m.find("input").attr("checked", sel ? "true" : null);
        },

        checkboxChanged: function(ev) {
            var $t = $(ev.target);
            $t.closest(".phrase-mention").toggleClass("selected", $t.is(":checked"));
        },

        getExclusionSuggestions: function() {
            const $mentions = this.$el.find(".phrase-mention");
            const mentions = this.model.get("mentions");

            let relevant = [];
            let irrelevant = [];
            for (let i = 0; i < $mentions.length; i++) {
                const m = mentions[i];
                m.relevant = !$($mentions[i]).hasClass("selected");
                if (m.relevant) relevant.push(m);
                else irrelevant.push(m);
            }

            return getExclusionSuggestions(relevant, irrelevant);
        }
    });
});
