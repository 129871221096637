<template>
    <main class="deq-reset">
        <div class="head title">
            <h1>Volume Usage</h1>
        </div>

        <div class="deq-blurb">
            <p>
                Brand volume totals are made up of mentions that were classified as relevant or that were later deleted
                after we receive them. Volume from today is <em>not</em> included in this month's totals.
                To increase your monthly volume limit, you can
                contact your client service person or send a mail to <a href="mailto:support@dataeq.com">support@dataeq.com</a>.
            </p>
        </div>

        <section class="usage-warnings">
            <div v-if="warnExceeded">
                <i class="symbol-warning"></i>
                <p>Account usage for {{moment().utc().format("MMMM")}} has exceeded your monthly limit</p>
            </div>
        </section>

        <section v-if="!isLoading && !noData" class="usage-table">
                    <div class="headings">
                        <div class="brand">Brand</div>
                        <div class="date" v-for="result in tableData.results" :key="result.date">
                            {{formatDate(result.date)}}
                        </div>
                        <div class="limit" v-if="tableData.payPerBrand">Limit</div>
                    </div>
                    <div v-for="brand in tableData.brands" :key="brand.name" class="usage-data">
                        <div class="brands">
                            <div class="brand-name">{{brand.name}}</div>
                        </div>
                        <div class="totals">
                            <TooltipComponent>
                                <div v-for="(dto, index) in brand.data" :key="index"
                                     class="total" :class="{ warn: dto.warn }" @click="onMentionValueClick(brand.id, index)">
                                    {{formatNumber(dto.total)}}
                                </div>
                                <template #tooltip>
                                    Click to see the mentions picked up this month
                                </template>
                            </TooltipComponent>
                        </div>
                        <div v-if="tableData.payPerBrand" class="limits">
                            <div class="limit-value">{{formatNumber(brand.volumeLimit)}}</div>
                        </div>
                    </div>

            <br>
            <p>
                Click on any month's total to see the mentions picked up over that time.
            </p>
        </section>



        <LoadingMessage v-if="isLoading" :message="'Loading Usage'" class="usage-loader" />

        <section v-if="noData">
            <div>No DataEQ usage information is available at this time</div>
        </section>


    </main>
</template>

<script lang="js">
import {mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {setTitle} from "@/app/Beef";
import moment from "moment";
import {rosemary} from "@/store/Services";
import {formatBrandName, formatNumber} from "@/app/utils/Format";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import {currentAccountCode} from "@/app/utils/Account";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import LoadingMessage from "@/components/LoadingMessage.vue";
import {getBrand} from "@/app/utils/Brands";


export default {
    name: "SetupUsage",
    store: VuexStore,

    components: {LoadingMessage, TooltipComponent},

    data() {
        return {
            warnExceeded: false,
            payPerBrand: 0,
            projected: 0,
            // total: 0,
            tableData: {},
            isLoading: false,
            noData: false,

        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState(['rootBrands']),
        ...mapState(['deletedBrands']),
    },


    created() {
    },

    async mounted(){
        this.isLoading = true;
        const code = this.account.code;
        const endpoint = `/v4/accounts/${code}/volume`;
        this.payPerBrand = this.account.payPerBrand;
        const monthCount = 7;

        setTitle("Volume Usage");

        var paramsHistory = {
            groupby: "pickedup[month]",
            from: moment().utc().startOf("month").add(-(monthCount-1), "month").format("YYYY/MM/DD"),
            to: moment().utc().startOf("month").add(1, "month").format("YYYY/MM/DD")
        };

        try {
            const res = await rosemary.get(endpoint, {params: paramsHistory})
            const data = res.data;
            // we only show the limit for the most recent month since we cannot accurately say what the
            // limit was for previous months
            const results = data.results;
            if (results && results.length > 0) {
                results[results.length - 1].mentionsPerMonth = data.mentionsPerMonth;
                const brands = data.brands;
                if (brands) {
                    for (let i = 0; i < brands.length; i++) {
                        const b = brands[i];
                        b.data = [];
                        for (let j = 0; j < results.length; j++) {
                            const total = results[j].totalByBrand[b.id];
                            const dto = { total: total };
                            if (j === results.length - 1 && total > b.volumeLimit) dto.warn = true;
                            b.data.push(dto);
                        }
                    }
                }
            }

            this.tableData = data;

            // remove deleted brands
            this.tableData.brands = this.tableData.brands
                .filter(data => {
                    return !this.deletedBrands.find(delBrand => {
                        return  (delBrand.id === data.id);
                    })
                })
                .filter(data => {
                    return this.account.brands.find(brand => brand.id === data.id);
                })

            //  sort order own brands then competitor then archived
            this.tableData.brands = this.tableData.brands.sort((lhs, rhs) => {
                const lhsBrand = this.account.brands.find(brand => brand.id === lhs.id)
                const rhsBrand = this.account.brands.find(brand => brand.id === rhs.id)

                const getCat = (obj) => {
                    if (obj === "OWN") return -1;
                    if (obj === "COMPETITOR") return 0;
                    return 1;
                }
                return getCat(lhsBrand?.category) - getCat(rhsBrand?.category)
            })

            // move main brand to first in list
            if (this.account.defaultBrand?.id) {
                this.tableData.brands.unshift(this.tableData.brands.splice(this.tableData.brands.findIndex(x => {
                    return x.id === this.account.defaultBrand.id
                }), 1)[0])
            }


            this.tableData.brands.map(brand => {
                brand.name = formatBrandName(getBrand(brand.id))
            })


            this.isLoading = false;
            this.noData = !Object.keys(this.tableData.results).length

        } catch(error) {
            console.warn("An error occurred loading table: ", error)
            this.isLoading = false;
            notifyUserOfError("An error occurred loading table")
        }

    },

    methods: {
        formatNumber,

        moment() {
            return moment
        },

        formatDate(date) {
            const time = moment(date, "YYYY-MM-DD");
            return moment(time).format("MMM 'YY");
        },

        onMentionValueClick(brandId, index) {
            const filter = this.getFilter(brandId, index);
            let uri = `/${currentAccountCode()}/mentions?filter=${encodeURIComponent(filter)}`;
            Beef.router.navigate(uri, {trigger: true});
        },

        getFilter(brand, index) {
            const date = moment(this.tableData.results[index].date);
            const today = moment();
            const monthEnd = date.clone().add({'months': 1});
            const beforeDateMoment = today.isBefore(monthEnd) ? today : monthEnd;
            const afterDate = date.format("YYYY/MM/DD");
            const beforeDate = beforeDateMoment.format("YYYY/MM/DD");

            return `pickedup after '${afterDate}' and pickedup before '${beforeDate}' and brand isorchildof ${brand} and (relevancy isnt irrelevant or crowdverified is true)`;
        },

        /**
         * Adds zero data points to a data set. Points are added to the end of the data set in one day steps.
         * @param data The data set to which zero points are appended.
         * @param start A moment date from where to start adding days (inclusive).
         * @param days The number of days to add.
         */
        fillWithEmptyPoints: function(data, start, days) {
            for (let i = 0; i < days; ++i) {
                data.push({
                    date: start.format("YYYY-MM-DD"),
                    irrelevant: 0,
                    relevant: 0,
                    total: 0,
                    unsorted: 0
                });
                start.add(1, "days");
            }
            return data;
        },

    },

}
</script>

<style scoped lang="sass">

.usage-loader
    --loading-message-delay: 100ms

.headings, .usage-data
    display: flex
    flex-direction: row

.usage-data
    background-color: #333

.brand
    width: 28%
    border: 1px solid black

.date, .limit
    width: 9%
    border: 1px solid black
    border-left: none


.totals
    width: 63%
    display: flex
    flex-direction: row

    .total
        width: 16.5%
        border-bottom: 1px solid black
        border-right: 1px solid black

.limits
    width: 9%

    .limit-value
        border: 1px solid black
        border-left: none
        border-top: none

.brands
    width: 28%
    border: 1px solid black
    border-top: none
    display: flex
    flex-direction: column
    white-space: nowrap
    overflow: hidden

.usage-data
    display: flex
    flex-direction: row

    .limits
        display: flex
        flex-direction: column

.usage-table
    div
        height: 30px

    .headings
        background-color: #222
        div
            text-align: center
        .brand
            text-align: left

    .brands
        div
            text-align: left

    .totals, .limits
        text-align: right

    .total, .limit
        &:hover
            cursor: pointer
            color: var(--be-colour-text-dark__hover)

.date, .limit, .brand, .brand-name, .total, .limit-value
    padding: 5px

.warn
    color: #ff938f

.spinner-container
    margin: 10px
    text-align: center
    position: relative
    width: 100%

.usage-warnings
    div
        display: flex
        flex-direction: row
        gap: 5px

</style>