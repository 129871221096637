/**
 * Renders information for a widget. This information can be taken from the
 * widget's type definition (see the Volumes widget, for instance).
 *
 * The values can also be added manually in the optional description and tooltip fields.
 *
 * @param description Optional. Can be take from the metric's type definition
 * @param tooltip Optional. Can be take from the metric's type definition
 */
import {escapeExpression, isString} from "@/app/utils/StringUtils";

export default function(type, description, tooltip) {
    var t = Beef.WidgetRegistry.typeMap[type];
    if (!t) t = { name: "Bad type [" + type + "]" };
    if ( !isString(description)) description = t.description;
    var hasDesc = isString(description);
    tooltip = isString(tooltip) || t.tooltip || t.description;
    return new Handlebars.SafeString(
        "<tr class='clickable' data-type='" + type + "' title='" + escapeExpression(tooltip) + "'>" +
        "<td" + (hasDesc ? "" : " colspan='2'") + ">" + escapeExpression(t.name) + "</td>" +
        (hasDesc ? "<td>" + escapeExpression(description)  + "</td>" : "") +
        "</tr>");
}