import {isString} from "@/app/utils/StringUtils";

function isUnknown(unknown) {
    if (!unknown) return false;
    if (!isString(unknown)) return false;
    unknown = unknown.toLowerCase();

    return (unknown === 'unknown' || unknown === 'un');
}

function isOther(other) {
    if (!other) return false;
    if (!isString(other)) return false;
    other = other.toLowerCase();

    if (other === 'others') return true;
    if (other === 'other') return true;
    if (other === 'otros') return true;
    return other === 'أخرى'.toLowerCase();
}

export function sortChartData(data, attributes, xField, yField, compareField) {
    if (!data || !data?.length) return [];

    let sortedData = [];
    let buckets = new Map();
    let sortedBuckets = [];

    let xSortByDefault = attributes.xSortByField === "default";
    let compareSortByDefault = attributes.compareBucketSortByField === "default";
    let xSortByField = attributes.xSortByField;
    let xSortOrder = attributes.xSortOrder;
    let compareBucketSortByField = attributes.compareBucketSortByField;
    let compareBucketSortOrder = attributes.compareBucketSortOrder;

    let sort = function(lhs, rhs, order) {
        if (typeof lhs === 'string') lhs = lhs.toLowerCase();
        if (typeof rhs === 'string') rhs = rhs.toLowerCase();

        if (order === "ascending") {
            return lhs > rhs ? 1 : -1
        } else {
            return lhs < rhs ? 1 : -1;
        }
    }

    let xAxis = attributes.xAxis || "mentionCount";
    let xGetter = xField.getter || function (d) { return d[xAxis] };
    let xSorter = function (lhs, rhs, order) {
        let sorter = xField.sorter || function () {
            return 0;
        };

        let lhsX        = xGetter(lhs),
            rhsX        = xGetter(rhs),
            lhsXUnknown = isString(lhsX) && isUnknown(lhsX),
            lhsXOthers  = isString(lhsX) && isOther(lhsX),
            rhsXUnknown = isString(rhsX) && isUnknown(rhsX),
            rhsXOthers  = isString(rhsX) && isOther(rhsX);

        if (lhsXOthers) return 1;
        if (rhsXOthers) return -1;
        if (lhsXUnknown) return 1;
        if (rhsXUnknown) return -1;

        return sorter(lhs, rhs, order);
    };

    let show = attributes.show ?? [];
    let yAxis = show.at(0)?.yAxis;
    let yGetter = yField.getter || function (d) { return d[yAxis] };
    let ySorter = xField.isDate ? function () {
        return 0;
    } : function (lhs, rhs, order) {
        order ??= "descending";
        return sort(yGetter(lhs), yGetter(rhs), order);
    };
    let yFieldDefaultSortOrder = yField.defaultSortOptions?.order ?? "descending";

    let compare = attributes.compare;
    let compareGetter = compareField.getter || function (d) { return d[compare] };

    // if we are sorting by default, first sort the data using field sorters
    if (xSortByDefault) {
        data.sort((lhs, rhs) => {
            return xSorter(lhs, rhs, xSortOrder) || ySorter(lhs, rhs, yFieldDefaultSortOrder);
        });
    }

    // group the data into buckets based on the x field
    data.forEach(d => {
        let key = xGetter(d);
        let bucket = buckets.get(key);
        if (!bucket) {
            let sortValue = xSortByDefault ? yGetter(d) : d[xSortByField]; // the sort value is used to sort the buckets if required
            buckets.set(key, {data: [d], sortValue: sortValue});
        } else {
            bucket.data.push(d);

            if (xSortByDefault) {
                bucket.sortValue += Math.abs(yGetter(d));
            } else {
                if (typeof d[xSortByField] === "string") {
                    bucket.sortValue = d[xSortByField];
                } else {
                    bucket.sortValue += Math.abs(d[xSortByField]);
                }
            }
        }
    });

    // if required, sort the buckets based on the sortValue
    for (const key of buckets.keys()) {
        let bucket = buckets.get(key);
        sortedBuckets.push({key: key, sortValue: bucket.sortValue});
    }

    // only sort based on the sortValue if we are not sorting by default, or the x field does not have it's own sorter
    if (!xSortByDefault || !xField.sorter) {
        sortedBuckets.sort((lhs, rhs) => {
            // ensure that "other" and "unknown" always appears at the end of the chart
            let lhsXUnknown = isString(lhs.key) && isUnknown(lhs.key);
            let lhsXOthers  = isString(lhs.key) && isOther(lhs.key);
            let rhsXUnknown = isString(rhs.key) && isUnknown(rhs.key);
            let rhsXOthers  = isString(rhs.key) && isOther(rhs.key);

            if (lhsXOthers) return 1;
            if (rhsXOthers) return -1;
            if (lhsXUnknown) return 1;
            if (rhsXUnknown) return -1;

            if (yField.isSentiment) {
                // for sentiment data, we put neutral data at the end of the list
                if (lhs.sortValue === 0) return 1;
                if (rhs.sortValue === 0) return -1;
            }

            return sort(lhs.sortValue, rhs.sortValue, xSortOrder);
        });
    }

    // sort the data within each bucket if required and then finally, merge the buckets
    for (const bucket of sortedBuckets) {
        let key = bucket.key;
        let curBucket = buckets.get(key);

        if (compare) {
            curBucket.data.sort((lhs, rhs) => {
                // ensure that "other" and "unknown" always appears at the end
                let lhsC        = compareGetter(lhs),
                    rhsC        = compareGetter(rhs),
                    lhsCUnknown = isString(lhsC) && isUnknown(lhsC),
                    lhsCOthers  = isString(lhsC) && isOther(lhsC),
                    rhsCUnknown = isString(rhsC) && isUnknown(rhsC),
                    rhsCOthers  = isString(rhsC) && isOther(rhsC);

                if (lhsCOthers) return 1;
                if (rhsCOthers) return -1;
                if (lhsCUnknown) return 1;
                if (rhsCUnknown) return -1;

                if (!compareSortByDefault) {
                    return sort(lhs[compareBucketSortByField], rhs[compareBucketSortByField], compareBucketSortOrder)
                } else {
                    return compareField.sorter ? compareField.sorter(lhs, rhs, compareBucketSortOrder) : sort(yGetter(lhs), yGetter(rhs), compareBucketSortOrder);
                }
            });
        }

        sortedData = [...sortedData, ...curBucket.data];
    }

    return sortedData;
}
