<template>
    <section class="dotted-card"
             :class="{'dotted-card--click': $listeners && $listeners.click}"
             tabindex="-1"
             @click="$emit('click', $event)">
        <slot name="default"/>
    </section>
</template>

<script>
    export default {
        name: "DottedCard"
    }
</script>


<style scoped lang="sass">
.dotted-card
    transition-property: border, color
    transition-duration: var(--transition-duration)
    box-sizing: border-box
    width: calc(100% - 20px)
    height: calc(100% - 40px)
    display: flex
    margin: 20px auto
    flex-direction: column
    border: 1px dashed #999
    border-radius: 3px
    justify-content: center
    outline: none

    p
        transition: color var(--transition-duration)
        color: var(--be-colour-muted-text-dark)
        padding: 0 30px
        &:first-child
            padding-top: 10px

    h1, h4
        transition: color var(--transition-duration)
        color: var(--be-colour-text-dark)
        text-align: center

    strong
        color: white
        font-size: calc(1em + 1px)

    ul, ol
        padding: 0 30px
    li
        transition: color var(--transition-duration)
        color: var(--be-colour-muted-text-dark)

    &--click
        cursor: pointer
        &:hover
            border-color: var(--be-colour-text-dark__hover)
            color: var(--be-colour-text-dark__hover)
            h1, h4, p, li
                color: var(--be-colour-text-dark__hover)

    .symbol-warning
        color: red
</style>