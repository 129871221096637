import {
    cleanFilenameParts,
    isFunction,
    isObject,
    isPartiallyVisible,
    shiftElementsDown,
    shiftElementsUp
} from "@/app/utils/Util";
import MetricPickerDialog from '@/app/framework/dialogs/metric-picker/MetricPickerDialog.vue';
import {
    showErrorDialog, showTagMentionDialog,
    showWhenDialog
} from "@/app/framework/dialogs/Dialog";
import WarningIndicator, {checkBrands, checkTags, checkProfiles, checkDeprecatedResponseTimeFields} from "../../components/WarningIndicator";
import {copyToClipboard} from "@/app/Clipboard"
import {
    earliestDate,
    filterDuration,
    findFilterErrors,
    latestDate,
    parseFilterString
} from "@/dashboards/filter/FilterParser";
import {
    notifyUser,
    notifyWithHtml,
    notifyWithText,
    showBusyNotification
} from "@/app/framework/notifications/Notifications";
import {escapeExpression} from "@/app/utils/StringUtils";
import _, {debounce} from 'underscore';
import moment from "moment";
import {
    showDialogComponent as showDialog
} from "@/app/framework/dialogs/DialogUtilities";
import {getEffectiveFilter} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {account, currentAccountCode} from "@/app/utils/Account";
import Vue from "vue";
import CommentaryPlusInMetric from "@/dashboards/widgets/commentaryplus/CommentaryPlusInMetric.vue";
import {features} from "@/app/Features";


/**
 * Container for other widgets. Uses the type attribute of the widget model to select the view to use to render the
 * widget.
 *
 * <p>
 * A widget will have an editable settings area set up for it, and this can be populated with its own
 * private settings, if the Widget has a SettingsView field defining these extra settings.
 *
 * <p>
 * The model has a generalData field that stores information that we do not necessarily want events fired on
 * for the general lifetime of the widget.
 *
 * <p>
 * Widget's will listen for changes on the generalData's _loader field to determine if a loader overlay
 * should be shown.
 *
 * <p>
 * You can easily add a footnote by setting the _footnotes field on generalData. This field should contain
 * an array of items to be displayed as footnotes.
 *
 * <p>
 * You can popup the edit dialog by triggering a 'show:edit' event on generalData.
 *
 * <p>
 * a 'refresh' event is sent from the model when a widget has been forceably asked to refresh itself.
 */
Beef.module("Widget").addInitializer(function(startupOptions) {
    let thisModule = this

    const asEmail = startupOptions.asEmail  // dashboard is being rendered for images for email by trotter

    /**
     * Are we in show as email mode?
     */
    this.isAsEmail = function() {
        return asEmail || document.documentElement.classList.contains("email")
    }

    /**
     * Show all animations be disabled e.g. when rendering to generate images for email?
     */
    this.isDisableAnimation = function() {
        return thisModule.isAsEmail()
    }

    this.View = Beef.BoundItemView.extend({
        tagName: "div",

        attributes: function() {
            var that = this;
            var hiddenTitle = !!that.model.get("hidden-title");
            return { class: "widget light " + (that.getType().class || '') + (hiddenTitle ? " hidden-title " : '')}
        },

        template: require("@/dashboards/widgets/Widget.handlebars"),

        templateHelpers() {
            return {
                isSelector: this.getType().group === 'selector'
            }
        },

        regions: {
            container:  ".widget-container",
            footnotes:  ".widget-footnotes",
            message:    ".widget-message",
            warnings:   ".widget__warning-area"
        },

        events: {
            "click > .title .editable":     "edit",
            "click > .title .menu-trigger": "displayWidgetMenu",
            "click > .title .download-button": "savePNG",
            "mousedown > .title":   "beginDrag"
        },

        modelEvents: {
            "change:type":              "updateWidgetView",
            "change:filter":            "updateFilter",
            "change:width":             "onSizeChange",
            "change:height":            "onSizeChange",
            "change:hidden-title":      "onHiddenTitleChange"
        },

        lastWarningSize: 0,

        initialize: function() {
            this.model.generalData = new Backbone.Model();

            $(document).bind('touchstart', this.touchstart.bind(this));
            this.listenTo(this.model.getSectionModel().getDashboardModel(),
                "change:_scrolling", this.dashboardScrolling, this);
            this.listenTo(this.model.getSectionModel(), "change:filter", this.updateFilter, this);
            this.listenTo(this.model.getSectionModel(), "change:compare", this.updateFilter, this);
            this.listenTo(this.model.generalData, "change:_message", this.showMessage, this);
            this.listenTo(this.model.generalData, "change:_loading", this.onLoadingChanged, this);
            this.listenTo(this.model.generalData, "change:warnings", this.onWarningChanged, this);
            this.listenTo(this.model.generalData, "show:edit", this.edit, this);
            this.listenTo(this.model.generalData, "change:_completed", () => {
                const exporting = !!this.exporting;
                const completed = !!this.model.generalData.get('_completed')

                //console.log("Metric [" + this.model.get('caption') + "] rendering completed")
                this.$('.download-button').toggleClass('disabled', !completed || exporting);
            })

            // in cases where a user changes more than one of these three fields at the same time, we only want to run maybeRefreshComment once, so we debounce the function.
            this.listenTo(this.model, 'change:comment change:commentWidth change:commentFontSize change:commentPlus', debounce(this.maybeRefreshComment, 50));


            // Added to the widgets so that we can easily find the associated model if something
            // selects a widget in the dom.
            this.$el.attr('widget-id', this.model.cid);
            this.updateWarnings();
        },

        render: function() {
            Beef.BoundItemView.prototype.render.call(this);
            // For some reason the model binder doesn't fill in the caption when first rendered. It does find the
            // element in the DOM and set its text but nothing happens? It does work after that i.e. changing the
            // caption (title) using the edit dialog. This bug started after the 10 Sept 2014 upgrade to latest
            // underscore and model binder versions.
            var that = this;
            setTimeout(function() { that.$('.title h5').text(that.model.get('caption')) })

            // call here so metrics like the fantastic charts can allocate space for themselves correctly. No data will be fetched yet since the
            // filter won't be on the model yet.
            this.renderComment(false);
            this.renderCommentPlus();
        },

        maybeRefreshComment() {
            if (this.model.hasChanged("filter") || this.model.hasChanged("_effectiveFilter")) {
                // Do not do anything in this case. Changing the metric's filter will result in a change in the _effectiveFilter, which will fire another change event.
                // Let the _effectiveFilter trigger the re-render.
                return;
            }

            if (this.model.hasChanged("comment")) {
                this.renderComment(true);
            } else if (this.model.hasChanged('commentWidth') || this.model.hasChanged('commentFontSize')) {
                this.renderComment(false);
                this.renderCommentPlus();
            } else if (this.model.hasChanged("commentPlus")) {
                this.renderCommentPlus();
            }
        },

        /**
         * Returns a promise that resolves when the comments have finished rendering and loading any extra data.
         */
        renderComment: async function (fetchData) {
            try {
                if (fetchData) this.model.generalData.set("_commentsComplete", false);

                this.model.generalData.unset('noSpaceForComments');

                let comment = this.model.get("comment");
                if (comment) comment = comment.trim();

                let hasNoComment = !comment || !comment.length;
                this.$(".widget-data-container").toggleClass('no-comment', hasNoComment);
                if (hasNoComment) return;

                let $markdown = this.$('.widget-markdown')
                $markdown[0].classList.forEach(c => {
                    if (c.startsWith("markdown-font-size-")) $markdown[0].classList.remove(c)
                })
                let commentFontSize = this.model.get('commentFontSize') ? this.model.get('commentFontSize') : 14;
                $markdown.toggleClass("markdown-font-size-" + commentFontSize, true);

                let commentWidth = this.model.get('commentWidth')
                $markdown.css('width', commentWidth ? (commentWidth * 104) + "px" : '')

                let width = this.model.get("width");
                if (width <= 4) {
                    Beef.Footnotes.setSpaceForComments(this, false);
                }

                if (fetchData) {
                    let filter = this.model.get("type") === "FantasticChart" ? getEffectiveFilter(this.model) : this.model.get("_effectiveFilter");

                    // if no filter found on widget, use section filter
                    if (!filter) filter = this.model.getSectionModel().get("_effectiveFilter");

                    let commentary = Beef.Markdown.render(comment, filter, currentAccountCode(),
                        {caption: this.model.get("caption"), columns: false});
                    $markdown.html(commentary.text);

                    const updates = await commentary;
                    if(updates.size){
                        updates.forEach( (html, selector) => {
                            this.$(selector).html(html).removeClass('text-loading');
                        });
                        commentary.events(this.$(".markdown-display")[0]);
                    }
                }
            } catch (e) {
                console.warn("Error occurred while trying to display comments: ", e);
            } finally {
                if (fetchData) this.model.generalData.set("_commentsComplete", true);
            }
        },

        renderCommentPlus: async function (){
            if (features.commentaryPlusInWidget()) {
                let commentPlusText = this.model.get("commentPlus");
                let commentaryPlusEl = this.$(".widget-commentary");
                if (!commentPlusText || JSON.parse(commentPlusText).blocks.length === 0){
                    // no commentPlus comment to display
                    // if a comment existed and was deleted, remove it
                    for (const child of commentaryPlusEl[0].children) {
                        child.remove();
                    }
                    commentaryPlusEl.css('width', '0px');
                    return;
                }
                const ComponentClass = Vue.extend(CommentaryPlusInMetric);
                let commentWidth = this.model.get('commentWidth');
                commentaryPlusEl.css('width', commentWidth ? (commentWidth * 104) + "px" : '104px');

                if (this._commentPlusInstance){
                    // destroy current and rebuild
                    this._commentPlusInstance.$destroy();
                    this._commentPlusInstance = new ComponentClass({propsData: {
                            model: this.model,
                            blocks: commentPlusText
                        }});

                    for (const child of commentaryPlusEl[0].children) {
                        child.remove();
                    }
                } else {
                    this._commentPlusInstance = new ComponentClass({propsData: {
                            model: this.model,
                            blocks: commentPlusText
                        }});
                }
                let placeHolder = document.createElement("div");
                commentaryPlusEl[0].appendChild(placeHolder);
                this._commentPlusInstance.$mount(placeHolder);
            }
        },

        closeCommentPlus: function () {
            if (this._commentPlusInstance) {
                this._commentPlusInstance.$destroy();
                this._commentPlusInstance = null;
            }
        },

        getContainedWidget: function() {
            return this.container.currentView;
        },

        showMessage: function() {
            var message = this.model.generalData.get('_message');
            if (message) {
                if (isObject(message)) this.message.show(message);
                else this.$('.widget-message').html(message);
                this.$('.message-area').fadeIn();
                this.$('.widget-data-container').fadeOut();
            } else {
                this.$('.message-area').fadeOut();
                this.$('.widget-data-container').fadeIn();
            }
        },

        onLoadingChanged: function() {
            // we have started loading more data so clear any 'Unable to load data from BrandsEye' messsage
            if (this.model.generalData.get('_message')) {
                this.model.generalData.set('_message', null);
            }
            this.loadingOverlay();
        },

        loadingOverlay: function() {
            if (asEmail) return

            var that = this;
            if (this.timeout) clearTimeout(this.timeout);

            var $o = this.$('.main-overlay');
            var $s = this.$('.spinner-overlay');

            var delayMs =  this.model.generalData.get("_delay") || 200;
            var durationMs =  this.model.generalData.get("_duration") || 750;
            if (this.model.generalData.get("_loading")) {

                // Block input, but keep hidden.
                $o.css({opacity: 0}).show();

                // Display this all after a given time.
                this.timeout = setTimeout(function() {
                    $o.animate({opacity: 0.8}, durationMs);
                    $s.fadeIn(durationMs);
                    that.model.generalData.set("_delay", undefined);
                    that.model.generalData.set("_duration", undefined);
                }, delayMs);
            }
            else {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = null;

                $o.hide();
                $s.stop(); // Stop any animations still in progress.
                $s.fadeOut();
            }
        },

        dashboardScrolling: function() {
            // Having this optional means that most of out charts will remain quickly
            // responsive to mouse input (say, when scrolling down and quickly clicking
            // through on a bar chart).
            if (this.container.currentView && this.container.currentView.blockScrolling) {
                var scrolling = this.model.getSectionModel().getDashboardModel().get('_scrolling');
                if (scrolling) this.$('.main-overlay').css({opacity: 0}).show();
                else this.$('.main-overlay').hide();
            }
        },

        edit: function(ev) {
            if (this.cancelClick) {
                this.cancelClick = false;
                return;
            }

            let type = this.getType()
            if (type.vueSettingsDialog) {
                return showDialog(type.vueSettingsDialog, { model: this.model })
            }

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["center", "right", "left"],
                alwaysMove: true,
                modal: true,
                offsets: { top: -1 }
            });
            popup.setTarget(this.$el);
            var view = new Beef.WidgetSettings.View({model:this.model, cache:this.cache});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        onBeforeRender: function() {
            this.updateWidgetSize();
        },

        onFirstRender: function() {
            if (asEmail) {
                this.$('.main-overlay').remove()
                this.$('.spinner-overlay').remove()
            }
            this.footnotes.show(new Beef.Footnotes.View({model: this.model.generalData}));
            this.updateWarnings();
        },

        onRender: function() {
            this.updateWidgetView();
            if (!this.model.isEditable()) {
                this.$('.edit-graph').removeClass("editable");
                this.$('.edit-graph').prop("title", "Graph");
            }
        },

        async updateWarnings() {
            const warnings = [];
            const dates = await this.checkDateRange();
            const deprecatedResponseTimeFields = await checkDeprecatedResponseTimeFields(this.model.get("show"));
            const tags = await checkTags(this.model.get('filter'));
            const brands = await checkBrands(this.model.get('filter'));
            const profiles = await checkProfiles(this.model.get('filter'));
            dates.forEach(d => warnings.push(d));
            deprecatedResponseTimeFields.forEach(d => warnings.push(d));
            tags.forEach(t => warnings.push(t));
            brands.forEach(b => warnings.push(b));
            profiles.forEach(p => warnings.push(p));

            this.model.generalData.set('warnings', warnings);
        },

        onWarningChanged(object, warnings) {
            warnings = warnings || [];
            if (warnings.length !==  this.lastWarningSize) {
                this.model.collection.trigger("checkwarnings");
                this.lastWarningSize = warnings.length;
            }

            let vue = null;
            if (warnings.length) {
                if (this.warnings.currentView) {
                    vue = this.warnings.currentView.vm;
                } else {
                    const view = new Beef.VuejsView.View({component: WarningIndicator, props: {tooltip: "This metric has warnings"}});
                    this.warnings.show(view);
                    vue = view.vm;
                }
            }
            if (vue) vue.warnings = warnings; // Don't need to create more dom elements if we don't need them
            this.$el.toggleClass("has-warnings", !!warnings.length);
        },

        checkDateRange() {
            const widgetFilter = this.model.get('filter');
            const sectionFilter = this.model.getSectionModel().get('filter');

            // Duration check to make sure there is a date range to consider.
            if(this.model.get('filter') && (filterDuration(widgetFilter)._milliseconds > 0)){
                const widgetEarliest = earliestDate(widgetFilter);
                const widgetLatest = latestDate(widgetFilter); // is never null

                const sectionEarliest = earliestDate(sectionFilter);
                const sectionLatest = latestDate(sectionFilter);

                const dateError = (widgetEarliest != null && widgetEarliest.isBefore(sectionEarliest)) ||
                    (widgetEarliest != null && widgetEarliest.isAfter(sectionLatest)) ||
                    (widgetLatest.isBefore(sectionEarliest)) ||
                    (widgetLatest.isAfter(sectionLatest));

                return dateError
                    ? [{
                        id: 'DATE_WARNING',
                        message: "This metric has a subfilter with a <strong>date</strong> outside of its section's date"
                    }]
                    : []
            }
            return [];
        },

        /**
         * Here the filter has changed, possibly being reloaded when the dashboard loads. We want to ensure
         * that brands and tags that are referenced by the filter and comparison sets still exist.
         */
        updateFilter: function() {
            try {
                this.model.generalData.set('_message', null);

                var filter = this.model.getFilter();

                var ac = this.model.getAncestorProperty('accountCode');
                var nodes = parseFilterString(filter);


                var filterErrors = findFilterErrors(ac, nodes);

                $.when(filterErrors).done(function(errors) {
                    if (errors.length) {
                        var message = "There is a problem with your filter";
                        if (_(errors).find(function(i) { return i.type == "MISSING_BRAND"; })) {
                            message = "A brand used in this filter has been deleted from your account";
                        }
                        else if (_(errors).find(function(i) { return i.type == "MISSING_TAG"; })) {
                            message = "A tag used in this filter has been deleted from your account";
                        }

                        this.model.unset("_effectiveFilter");
                        this.model.generalData.set("_loading", false);
                        this.model.generalData.set("_completed", false);
                        this.model.generalData.set("_message", message);
                        return;
                    }

                    this.model.set({
                        "_effectiveFilter": filter
                    });

                    this.renderComment(true);
                    this.renderCommentPlus();
                }.bind(this));
                this.updateWarnings();
            } catch (error) {
                errorHelper(this.model, error);
            }
        },

        /**
         * Has this widget loaded all its data and finished rendering?
         */
        isRenderingComplete: function() {
            return this.model.generalData.get('_completed');
        },

        updateWidgetView: function(ignoreVisibility) {
            ignoreVisibility = ignoreVisibility? true : false;
            var h = this.$el.height();
            if (h == 0 || this.$el.offset().top == 0) {
                setTimeout(function(){ this.updateWidgetViewImpl(ignoreVisibility) }.bind(this));
            } else {
                this.updateWidgetViewImpl(ignoreVisibility);
            }
        },

        getType: function() {
            var type = this.model.get('type');
            if (type) type = Beef.WidgetRegistry.typeMap[type];
            if (!type) type = unknownWidgetType;
            return type;
        },

        log: function(msg) {
            // this is to help debug "image snapshot taking before rendering complete" bugs
            // let sm = this.model.getSectionModel()
            // console.log(sm.get('title') + " " + this.model.id + ": " + msg)
        },

        updateWidgetViewImpl: function(ignoreVisibility) {
            this.log("updateWidgetViewImpl ignoreVis " + ignoreVisibility + " vis " + isPartiallyVisible(this.$el))
            var view = this.container?.currentView;

            if (!ignoreVisibility && !view && !isPartiallyVisible(this.$el)) {
                // we don't have a view and are not visible so don't create one until we become visible
                if (this.scrollHandler == null) {
                    this.scrollHandler = function(){
                        if (this.$el.closest('html').length) this.updateWidgetViewImpl();
                        else this.removeScrollHandler();
                    }.bind(this);
                    $(window).on('scroll', this.scrollHandler);
                }
                return;
            }
            this.removeScrollHandler();
            this.updateFilter();

            var type = this.getType();
            var caption = null;
            if (!view || type != view.type) {
                if (view && view.type != this.model.get('type')) {
                    var preserve = !!this.model.get('_preserveSettingsOnUpdate');
                    // Here we have a widget that we're replacing.
                    this.container.currentView._deleting = true;
                    this.container.currentView.close();
                    var data = {
                        'chart-type': null,
                        'coarseness': null
                    };
                    if (!preserve) this.model.set(data, {silent: true});
                    this.model.generalData.clear({silent: true});
                    caption = type.name;

                    this.removeClassesStartingWith("widget-");
                    if (type.class) this.$el.toggleClass(type.class, true);
                }

                this.container.show(new type.View({
                    model: this.model,
                    type: type,
                    cache: this.cache
                }));

                this.model.getSectionModel().view.onWidgetViewCreated();
                if (caption && !preserve) this.model.set('caption', caption);
            }

            setTimeout(() => {
                if (this.imageExportDisabled()) {
                    this.$('.download-button').css({display: 'none'});
                }
            })
        },

        removeScrollHandler: function() {
            if (this.scrollHandler) {
                $(window).off("scroll", this.scrollHandler);
                this.scrollHandler = null;
            }
        },

        onClose: function() {
            this.removeScrollHandler();
            this.closeCommentPlus();
        },

        onSizeChange: function() {
            this.updateWidgetSize();

            // This event handler is called when the size attributes on a model
            // are updated. This may cause metrics to be repositioned and resized.
            // Unfortunately, there is no great way yet to get resize events on dom
            // elements resizing. So here we are going to check all the other views
            // to see if they have been resized, and then ask them to redraw themselves
            // if this has happened. This is done deferred, so that if we redraw a lot
            // we don't hold up the events from being handled.
            var cid = this.model.cid;
                this.model.collection.forEach(function(m) {
                    if (m.cid !==  cid) { // This model / view has already been handled
                        if (m.generalData.get("_completed") && m.view && m.view.container && m.view.container.currentView) {
                            setTimeout(function() {
                                var view = m.view;
                                var width = view.$el.width();
                                var height = view.$el.height();
                                var containerWidth = view.container.currentView.$el.width();
                                var containerHeight = view.container.currentView.$el.height();
                                var widgetWidth = parseInt(getComputedStyle(view.$el[0]).getPropertyValue('--widget-width'));
                                var widgetHeight = parseInt(getComputedStyle(view.$el[0]).getPropertyValue('--widget-height'));

                                // This overestimates what we should render, but unfortunately
                                // because of the resizeable final column, I cannot currently think of a way
                                // around this until the ResizeObserver can be used later this year.
                                // This behaves a bit differently on chrome and firefox: chrome allows the container
                                // to grow outside the bounds of the widget itself.
                                if (width !== widgetWidth || height !== widgetHeight || containerWidth > width || containerHeight > height) {
                                    view.container.currentView.render();
                                }
                            });
                        }
                    }
                })

            let comment = this.model.get("comment");
            let width = this.model.get("width");
            this.model.generalData.unset('noSpaceForComments');
            if (comment && width <= 4) {
                Beef.Footnotes.setSpaceForComments(this, false);
            }
        },

        onHiddenTitleChange: function() {
            this.$el.toggleClass('hidden-title', !!this.model.get('hidden-title'));
            this.render();
            if (this.container && this.container.currentView) this.container.currentView.render();
        },

        updateWidgetSize: function() {
            var type = this.model.get('type');
            if (type) type = Beef.WidgetRegistry.typeMap[type];
            if (type) {
                if (!this.model.has('width') && type.width) this.model.set('width', type.width);
                if (!this.model.has('height') && type.height) this.model.set('height', type.height);
            }

            var w = this.model.get('width') || "4";
            this.removeClassesStartingWith("width");
            if (w) this.$el.toggleClass("width" + w, true);

            var h = this.model.get('height') || "4";
            this.removeClassesStartingWith("height");
            if (h) this.$el.toggleClass("height" + h, true);
        },

        removeClassesStartingWith: function(c) {
            var $el = this.$el;
            $.each($el.attr('class').split(/\s+/), function(index, item){
                if (item.indexOf(c) == 0) $el.removeClass(item);
            });
        },

        getMenuTrigger: function() {
            return this.$el.find("> .title .menu-trigger");
        },

        displayWidgetMenu: function() {
            if (this.cancelClick) {
                this.cancelClick = false;
                return;
            }

            var container = this.container;
            var currentView = container.currentView;
            var exporting = this.exporting;
            var that = this;

            this.$el.addClass('title-temporarily-visible');
            Beef.MiniMenu.show({
                model: new Backbone.Model({
                    view:  this,
                    isSelector: this.getType().group == 'selector',
                    csvLink: currentView && currentView.getCsvLink ? currentView.getCsvLink() : null
                }),
                template: require("@/dashboards/widgets/WidgetMenu.handlebars"),
                object: [this, currentView],
                target: this.getMenuTrigger(),
                onClose: function () {
                    that.$el.removeClass('title-temporarily-visible');
                },
                offsets: {right: -4},
                onRender: function() {
                    if(!that.model.isEditable()) {
                        this.$('#edit').addClass("disabled");
                        this.$('#replace').addClass("disabled");
                        this.$('#copy').addClass("disabled");
                        this.$('#move').addClass("disabled");
                        this.$('#delete').addClass("disabled");
                        this.$('#toEnd').addClass("disabled");
                    }
                    if (!container.currentView || !(container.currentView.getCsv || container.currentView.toCSV)) {
                        this.$('#exportCSV').addClass("disabled");
                    }
                    // If the current view has a viewMentions function show a menu item for it. The idea is that this
                    // will open the mention panel in a new tab. See the world map for an example.
                    if (!container.currentView || !container.currentView.viewMentions) {
                        this.$('#viewMentions').addClass("disabled");
                    }
                    if (!container.currentView || (!container.currentView.viewMentions && !container.currentView.exportCSV_V4)) {
                        this.$('#viewAuthors').addClass("disabled");
                    }
                    if (exporting || !container.currentView || container.currentView.imageExportDisabled) {
                        this.$('#saveJPG').addClass("disabled");
                        this.$('#saveSVG').addClass("disabled");
                        this.$('#savePNG').addClass("disabled");
                        this.$('#displayWidget').addClass("disabled");
                        this.$('#exportCSV').addClass("disabled");
                    } else if (that.svgExportDisabled()) {
                        this.$('#saveSVG').addClass("disabled");
                    }
                }});
        },

        imageExportDisabled: function () {
            return (this.container?.currentView && this.container.currentView.imageExportDisabled) || false;
        },

        /**
         * Determines whether SVG export should be disabled. Unless an attribute that disables the export is found,
         * the function will assume that the export is enabled.
         * @return boolean True if SVG export is disabled, otherwise false.
         */
        svgExportDisabled: function () {
            return ((this.container?.currentView && this.container.currentView.svgExportDisabled) || false);
        },

        /** Get a filename for data saved from this Widget. */
        getFilename: function() {
            var caption = this.model.get('caption');
            if (!caption) caption = this.cid;
            return this.model.getSectionModel().view.getFilename() + "-" + cleanFilenameParts([caption]).join("-");
        },

        getImageMarkup: function() {
            if (this.imageExportDisabled()) return null;
            let container = this.$(".widget-data-container");
            let markup = container.prop('outerHTML');
            let font = this.model.getSectionModel().getDashboardModel().get('font')

            let includeTitle = !this.model.get("hidden-title");
            let title = includeTitle ? `<div style="text-align: center"><text style="font-family: var(--widget-font)">${this.model.get("caption")}</text></div>` : "";

            return '<div class="widget save-image dashboard-font-' + font + '" style="position:relative;width:' + container.width() +
                'px;height:' + container.height() + 'px;display:block;">' + title + markup + "</div>";
        },

        savePNG: function() {
            var html = this.getImageMarkup();
            if (!html) return;
            if (this.exporting) return;

            this.exporting = true;
            const $button = this.$('.download-button');
            const popup = showBusyNotification("Creating image…", null, this.getMenuTrigger());
            $button.toggleClass('disabled', true);

            var that = this;
            $.ajax({
                url: "/api/trotter/image",
                type: "POST",
                contentType: "text/plain",
                processData: false,
                data: html,
                success: function(data) {
                    popup.hide();
                    that.exporting = false;
                    $button.toggleClass('disabled', false);
                    if (account().dev) {
                        window.open(`/api/trotter/display/${data.id}`, "_blank")
                    } else {
                        window.location = "/api/trotter/image/" + data.id + "?filename=" +
                            encodeURIComponent(that.getFilename()) + ".png";
                    }
                },
                error: function(e) {
                    console.error("Error exporting metric", e);
                    popup.hide();
                    that.exporting = false;
                    $button.toggleClass('disabled', false);
                    showErrorDialog("We're having a problem exporting this image. Please try again in a moment.");
                }
            });
        },

        exportCSV: function() {
            if (this.container.currentView.exportCSV_V4) {
                if (this.container.currentView.exportCSV_V4()) return;
            }

            if (this.container.currentView.getCsv) {
                this.container.currentView.getCsv();
                return;
            }

            if (this.container.currentView.toCSV) {
                let csv = this.container.currentView.toCSV()
                if (!csv) return

                // prefix with UTF-8 BOM so that Excel + Windows correctly interprets and opens the CSV
                if (csv.charAt(0) !== '\ufeff') csv = '\ufeff' + csv

                let caption = this.model.get('caption') || "csv"

                var link = document.createElement("a")
                link.style = "display: none"
                link.href = window.URL.createObjectURL(new Blob([csv], {type: "text/csv"}))
                link.download = caption + "-" + (moment().format("YYYY-MM-DD-HH[h]mm")) + ".csv"

                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(link.href)
                document.body.removeChild(link)
                return
            }

            if (!this.container.currentView.getCsvEntries) {
                alert('CSV Export not implemented');
                return;
            }
        },

        tagMentions() {
            const title = `Tag mentions for '${this.model.get('caption')}'`;
            const filter = this.model.get('_effectiveFilter');
            showTagMentionDialog(filter, title);
        },


        delete: function() {
            showWhenDialog("Delete a metric?", "Are you sure you want to delete '" + this.model.get('caption') + "'?")
                .then(function() {
                    this.$el.fadeOut({
                        complete: function () {
                            var data = Beef.Sync.cloneModel(this.model);
                            var collection = this.model.collection;
                            var index = collection.indexOf(this.model);
                            this.container.currentView._deleting = true;
                            this.model.destroy();

                            notifyUser({
                                message: "Metric <strong>" + escapeExpression(this.model.get('caption')) + "</strong> has been deleted.",
                                isEscapedHtml: true,
                                icon:  "<i class='symbol-metric'></i>",
                                undo: function() {
                                    var widget = new Beef.Dashboard.WidgetModel(data);
                                    var upper = collection.at(index);
                                    collection.add(widget, {at: index});
                                    var lower = collection.at(index);
                                    collection.owner.save();
                                    collection.trigger("reorder");

                                    if (upper && lower) {
                                        upper.view.$el.before(lower.view.$el);
                                    }
                                    notifyWithHtml("<strong>" + escapeExpression(widget.get('caption')) + "</strong> has been undeleted.");
                                }
                            })
                        }.bind(this)
                    });
                }.bind(this));
        },

        toEnd: function() {
            if (!this.model.isEditable()) return;

            var collection = this.model.collection;
            collection.remove(this.model);
            collection.push(this.model);

            collection.owner.save();
            collection.trigger("reorder");

        },

        copy: function() {
            var collection = this.model.collection;
            var id = collection.max(function(o) { return o.id } );
            let data = JSON.parse(JSON.stringify(this.model.attributes))
            data.id = id == null ? 1 : id.id + 1
            var widget = new Beef.Dashboard.WidgetModel(data);
            collection.add(widget);
            widget.save(null, {action: 'add'});

            var $section = this.$el.closest('.section');
            var $widgets = $('.widget', $section);
            notifyUser({
                message: "Metric <strong>" + escapeExpression(this.model.get('caption')) + "</strong> has been duplicated.",
                isEscapedHtml: true,
                icon:  "<i class='symbol-metric'></i>",
                undo() {
                    collection.remove(widget);
                    collection.owner.save();
                    notifyWithHtml("Duplicate has been removed.", null, "<i class='symbol-metric'></i>");
                }
            });

            $widgets[$widgets.length - 1].scrollIntoView(false);
        },

        copyToClipboard: async function() {
            let data = {...this.model.attributes}
            delete data.id
            await copyToClipboard(JSON.stringify(data, (k, v) => k && k.charAt(0) === '_' ? undefined : v, 2))
            notifyUser({
                message: "Metric copied to clipboard",
                icon:  "<i class='symbol-metric'></i>"
            });
        },

        replace: function() {
            const type = Beef.WidgetRegistry.typeMap[this.model.get('type')];

            if (type.group === "selector") {
                var popup = new Beef.Popup.View({ closeOnHide: true, positions:["center"], plain: true });
                popup.setTarget(this.$el);
                popup.show(new Beef.WidgetTypeChange.View({model: this.model, popup: popup,
                    group: type.group, title: "Select A Replacement " +
                        (type.group == "selector" ? "Drill Down" : "Metric")}));
            } else {
                const dialog = showDialog(MetricPickerDialog);
                dialog.action = "Select a replacement metric";
                dialog.title = "Choose replacement metric";
                dialog.$on('add-metric', widget => {
                    const data = Beef.Sync.cloneModel(this.model);

                    const typeObject = Beef.WidgetRegistry.typeMap[widget.type];
                    const oldType = this.model.get('type');
                    const oldTypeObject = Beef.WidgetRegistry.typeMap[oldType];
                    const oldCaption = this.model.get('caption');
                    if (widget.caption) this.model.set('caption', widget.caption);
                    if (typeObject && typeObject.onReplace) typeObject.onReplace(this.model, widget, oldTypeObject);
                    else this.model.set('type', widget.type);
                    this.model.save();
                    notifyUser({
                            message: `<strong>${escapeExpression(oldCaption)}</strong> has been replaced`,
                            isEscapedHtml: true,
                            undo: () => {
                                this.model.clear({silent: true});
                                this.model.set(data);
                                this.model.save();
                                notifyWithText("Your old metric has been returned.", null, "<i class='symbol-metric'></i>")
                            },
                           icon: "<i class='symbol-metric'></i>"
                        }
                    )

                });
            }
        },

        refresh: function(ev) {
            Beef.Dashboard.clearCache();
            if (this.container.currentView && isFunction(this.container.currentView.refresh)) {
                this.model.trigger('refresh');
                this.container.currentView.refresh();
            }
        },

        //---------------------------------------

        touchstart: function(ev) {
            var title = $(ev.target).closest('.title');
            if (title.length) {
                this.touchWidget = title.closest('.widget');
                this.mousePos = {
                    x: ev.originalEvent.changedTouches[0].pageX,
                    y: ev.originalEvent.changedTouches[0].pageY
                };
                this.boundTouchmove = this.boundTouchmove || this.touchmove.bind(this);
                this.boundTouchend = this.boundTouchend || this.touchend.bind(this);
                $(document).bind('touchmove', this.boundTouchmove);
                $(document).bind('touchend', this.boundTouchend);
            }
        },

        touchmove: function(ev) {
            ev.preventDefault();
            var dx = ev.originalEvent.changedTouches[0].pageX - this.mousePos.x,
                dy = ev.originalEvent.changedTouches[0].pageY - this.mousePos.y;

            this.highlightDropTarget();

            this.touchWidget.toggleClass('dragging', true);
            this.touchWidget.css('transform', 'translate(' + dx + 'px,' + dy + 'px)' )
        },

        touchend: function(ev) {
            try {
                $(document).unbind('touchmove', this.boundTouchmove);
                $(document).unbind('touchend', this.boundTouchend);

                var $drop = $('.drag-target');
                $('.widget').toggleClass('drag-target', false);
                this.performDrop($drop);
            } finally {
                this.touchWidget.toggleClass('dragging', false);
                this.touchWidget.css('transform', 'none' )
                this.touchWidget = null;
            }

        },

        beginDrag: function(ev) {
            if (!this.model.isEditable()) return;
            this.mousePos = {x: ev.screenX, y: ev.screenY};
            this.scrollPos = {x: $(window).scrollLeft(), y: $(window).scrollTop() };
            this.boundMouseMove = this.boundMouseMove || this.mouseMove.bind(this);
            this.boundMouseUp = this.boundMouseUp || this.mouseUp.bind(this);
            $(document).mousemove(this.boundMouseMove);
            $(document).mouseup(this.boundMouseUp);
            $('.widget').css({
                'user-select':          'none',
                '-moz-user-select':     'none',
                '-webkit-user-select':  'none'
            });
        },

        mouseUp: function(ev) {
            this.mousePos = null;
            $(document).unbind('mousemove', this.boundMouseMove);
            $(document).unbind('mouseup', this.boundMouseUp);
            $('.widget').css({
                'user-select':          'text',
                '-moz-user-select':     'text',
                '-webkit-user-select':  'text'
            })
            var $drop = $('.drag-target');
            $('.widget').toggleClass('drag-target', false);

            try {
                // We only want to handle this if things have actually moved.
                if (this.cancelClick) {
                    this.performDrop($drop);
                }
            }
            finally {
                this.$el.css({
                    'transform':    'none'
                });
            }
        },

        /**
         * This method will be called for every mouse move after the user has mouse downed on the title. This
         * can flood the event system with mouse move events, so we throttle this function so that it will at
         * most execute once every pre-determined number of milliseconds.
         */
        mouseMove: _.throttle(function(ev) {
            if (this.mousePos) {
                var scrollX = $(window).scrollLeft(),
                    scrollY = $(window).scrollTop();
                var dx = ev.screenX - this.mousePos.x + (scrollX - this.scrollPos.x),
                    dy = ev.screenY - this.mousePos.y + (scrollY - this.scrollPos.y);

                // Ignore small movements.
                if (dx < 5 && dy < 5) return;

                // Begin the drag.
                this.$el.toggleClass('dragging', true);
                this.highlightDropTarget();

                this.$el.css('transform', 'translate(' + dx + 'px,' + dy + 'px)' )
                this.cancelClick = true;
            }
        }, 50),

        /**
         * Places the drag-target style on the widget that is under our item.
         */
        highlightDropTarget: _.throttle(function() {
            var width  = (this.touchWidget || this.$el).width(),
                height = (this.touchWidget || this.$el).height(),
                offset = (this.touchWidget || this.$el).offset();
            var centre = {x: offset.left + width / 2, y: offset.top + height / 2};

            var cid = this.touchWidget ? this.touchWidget.attr('widget-id') : this.model.cid;
            function filterFunction() {
                var $item = $(this);
                var offset = $item.offset();
                return ($item.attr('widget-id') != cid && centre.x >= offset.left &&
                    centre.x <= (offset.left + $item.width()) &&
                    centre.y >= offset.top && centre.y <= (offset.top + $item.height()));
            }

            var $section = (this.touchWidget || this.$el).closest('.section');
            $('.widget', $section).filter(filterFunction).toggleClass('drag-target', true);
            $('.widget', $section).filter(function() { return !filterFunction.call(this); }).toggleClass('drag-target', false);
        }, 200),

        performDrop: function($drop) {
            var col = this.model.collection;

            if ($drop.length) {
                var cid = $drop.attr('widget-id');
                var dropModel = _(col.models).find(function(m) {
                    return m.cid == cid;
                });

                var currentCid = this.touchWidget ? this.touchWidget.attr('widget-id') : this.model.cid;

                // dropModel might be undefined if the user is attempting to drop something across sections.
                if (!dropModel) return;

                var currentIndex = null;
                _(col.models).find(function(m, i) {
                    if (m.cid == currentCid) currentIndex = i;
                    return Number.isFinite(currentIndex);
                }.bind(this));

                var dropIndex = null;
                _(col.models).find(function(m, i) {
                    if (m.cid == dropModel.cid) dropIndex = i;
                    return Number.isFinite(dropIndex);
                }.bind(this));


                if (currentIndex != dropIndex) {
                    if (currentIndex < dropIndex) {
                        $drop.after(this.touchWidget || this.$el);
                        shiftElementsDown(col.models, currentIndex, dropIndex);
                    }
                    else {
                        $drop.before(this.touchWidget || this.$el);
                        shiftElementsUp(col.models, dropIndex, currentIndex);
                    }

                    var $item = this.touchWidget || this.$el;
                    setTimeout(function() {
                        $item.toggleClass('animated pulse', true);
                        setTimeout(function() {
                            this.$el.toggleClass('dragging', false);
                            $item.toggleClass('animated pulse', false);
                        }.bind(this), 1010) // Need to take the style off so that we can use translate on it again.
                    }.bind(this), 200); // Bit of a delay to not have this widget just 'pop'.

                    col.models[dropIndex] = this.model;
                    col.owner.save();
                    col.trigger("layout");
                }
            }
            else {
                this.$el.toggleClass('dragging', false);
            }
        }
    });


    var unknownWidgetType = {
        name: "Unknown Widget",
        View: Beef.BoundItemView.extend({
            template: require("@/dashboards/widgets/UnknownWidget.handlebars"),

            initialize: function() {
                console.error("Unrecognised metric type: ", this.model.get("type"));
                this.model.generalData.set("_message",
                    "This metric is no longer supported" +
                    "<p class='info'>Please contact support@dataeq.com if you continue to have this problem</p>"
                );
            }
        })
    };
});