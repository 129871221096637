/**
 * Utilities for dealing with ID sets that possibly have children
 */

//---------------------------------------------------

/**
 * Performs an intersection between two sets of items that have children.
 */
export function union(lhs, rhs, getter) {
    if (!lhs && !rhs) return [];
    if (!Array.isArray(lhs)) lhs = lhs[Symbol.iterator] ? Array.from(lhs) : [lhs];
    if (!Array.isArray(rhs)) rhs = rhs[Symbol.iterator] ? Array.from(rhs) : [rhs];

    var all = lhs.concat(rhs).map(function(c) { return c.id ? c : getter(c) });
    var resultIds = new Set();
    var results = [];
    var childrenBeingAdded = new Set();
    all.forEach(function(item) {
        if (item.children) item.children.forEach(function(child) { childrenBeingAdded.add(child.id) })
    });


    while (all.length) {
        var current = all.pop();
        if (!resultIds.has(current.id) && !childrenBeingAdded.has(current.id)) {
            resultIds.add(current.id);
            // If we add a parent, its children are implicitly added.
            if (current.children) current.children.forEach(function(c) { resultIds.add(c.id)});
            results.push(current);
        }
    }

    return results;
}

/**
 * Calculates the intersection between two sets of segments, subtracting the rhs set from the lhs set.
 * Always returns an array. The input can be either individual segment IDs or objects, or arrays of ids / objects.
 * Takes segment children into consideration.
 */
export function difference(lhs, rhs, getter) {
    if (!lhs && !rhs) return [];
    if (!lhs) lhs = [];
    if (!rhs) rhs = [];
    if (!Array.isArray(lhs)) lhs = lhs[Symbol.iterator] ? Array.from(lhs) : [lhs];
    if (!Array.isArray(rhs)) rhs = rhs[Symbol.iterator] ? Array.from(rhs) : [rhs];


    lhs = lhs.map(function(c) { return c.id ? c : getter(c) });
    rhs = rhs.map(function(c) { return c.id ? c : getter(c) });

    var results = [];
    var rhsIds = new Set();
    rhs.forEach(function(r) {
        rhsIds.add(r.id);
        if (r.children) r.children.forEach(function(c) { rhsIds.add(c.id )})
    });

    while (lhs.length) {
        var current = lhs.pop();

        var excludedChildren = current.children &&
            current.children.length &&
            current.children.some(c => rhsIds.has(c.id));

        if (!rhsIds.has(current.id) && !excludedChildren) {
            results.push(current);
        }

        if (excludedChildren && current.children.length) {
            current.children.forEach(function(c) {
                if (!rhsIds.has(c.id)) results.push(c);
            })
        }
    }

    return results;
}

export function intersection(lhs, rhs, getter) {
    getter = getter || (id => {return {id} });
    if (!lhs && !rhs) return [];
    if (!lhs) lhs = [];
    if (!rhs) rhs = [];
    if (!Array.isArray(lhs)) lhs = lhs[Symbol.iterator] ? Array.from(lhs) : [lhs];
    if (!Array.isArray(rhs)) rhs = rhs[Symbol.iterator] ? Array.from(rhs) : [rhs];

    lhs = lhs.map(c => c.id ? c : getter(c));
    rhs = rhs.map(c => c.id ? c : getter(c));

    const results = [];
    const rhsIds = new Set();

    // A recursive function that adds the given elements id to a set,
    // and the IDs of all of its children (and their children);
    const addId = node => {
        rhsIds.add(node.id);
        if (node.children) {
            for (const c of node.children) {
                addId(c);
            }
        }
    };

    rhs.forEach(addId);

    // A recursive function, checking a node to see if it,
    // or its children, are in the collection of rhs IDs.
    const checkChildren = node => {
        if (rhsIds.has(node.id)) {
            results.push(node);
            return;
        }

        if (node.children) {
            for (const c of node.children) {
                checkChildren(c);
            }
        }
    };

    for (const l of lhs) {
        checkChildren(l);
    }

    return results;
}


