<template>
    <drop-down-input v-model="val" :optional="optional" :multiselect="multiselect" :options="OPTIONS"
                     search :label="label" placeholder="Start typing language" :required="required"/>
</template>

<script>
    import DropDownInput from "./DropDownInput"
    import {LANGUAGES} from "@/app/utils/Language";

    export default {
        name: "LanguageInput",

        components: { DropDownInput },

        props: {
            label: String,
            value: [String, Array],
            optional: Boolean,
            multiselect: Boolean,
            required: Boolean
        },

        data() {
            return {
                val: this.value
            }
        },

        watch: {
            value(v) {
                this.val = v
            },

            val(v) {
                this.$emit("input", v)
            }
        },

        created() {
            if (!OPTIONS) {
                OPTIONS = { }
                Object.entries(LANGUAGES).forEach(row => {
                    let o = { id: row[0],  name: row[1] }
                    OPTIONS[o.id] = o
                })
            }
            this.OPTIONS = OPTIONS
        }
    }

    var OPTIONS
</script>

<style scoped>

</style>