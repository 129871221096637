import { MentionQLexer } from '../mentionq/mentionq'
import * as Stores from '../store/deprecated/Stores'
import {grousePut} from "@/data/Grouse";
import {logBulkMentionUpdate} from "@/data/Chervil";
import {parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import {extractBrands} from "@/dashboards/filter/BasicFilter";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import _ from 'underscore';
import {getBrand, getRootBrands} from "@/app/utils/Brands";
import {removeQuotes, splitQuotedString} from "@/app/utils/StringUtils";

Beef.module("EditSelectedMentions").addInitializer(function(startupOptions) {

    var thisModule = this;

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/mentions/EditSelectedMentions.handlebars"),

        attributes: {
            class: "edit-selected-mentions"
        },

        editAttributes: ['relevancy', 'country', 'language', 'media', 'credibility', 'totalSelected'],

        initialize: function(options) {
            Beef.SettingsDialog.View.prototype.initialize.call(this);
            this.accountCode = options.accountCode;
            this.model.accountCode = this.accountCode;
            this.model.set('allBrands', true);
            this.list = options.list;
            this.filter = options.filter;
            this.parent = options.parent;

            // the UI doesn't allow bulk edit unless there is at least one mention so this is safe
            var mention = this.list.at(0);
            this._canEditAttrs = mention.get('_canEditAttrs');
            this._canEditTags = mention.get('_canEditTags');
        },

        templateHelpers: function() {
            var subset = thisModule.getSubsetBrands(this.parent.model, this.filter);
            var brands = "";
            if (subset) {
                for (var i = 0; i < subset.length; i++) {
                    if (i > 0) {
                        if (i == subset.length - 1) brands += " and ";
                        else brands += ", ";
                    }
                    brands += subset[i].name;
                }
            }
            return {
                brands_: brands,
                v4: true,
                _canEditAttrs: this._canEditAttrs,
                _canEditTags: this._canEditTags
            }
        },

        bindings: function() {
            return {
                media: { converter: Beef.MediaPicker.converter, elAttribute: "data-value" },
                credibility: { converter: Beef.CredibilityPicker.converter, elAttribute: "data-value" },
                location: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" },
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
                _tags: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" },
                gender: { converter: Beef.GenderPicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            this.$('.phrases').hide();
            Beef.MediaPicker.attach(this, ".mediaPicker", "media", {onlyOne: true});
            Beef.CredibilityPicker.attach(this, ".credibility", "credibility", {onlyOne: true});
            Beef.LocationPicker.attach(this, ".location", "location", {onlyOne: true, noGroups: true});
            Beef.LanguagePicker.attach(this, ".language", "language", {onlyOne: true});
            Beef.TagPicker.attach(this, "._tags", "_tags", {mustSelectFromItems: false, encloseNewItemsInQuotes: true});
            Beef.TagPicker.attach(this, "._removeTags", "_removeTags", {mustSelectFromItems: true});
            Beef.GenderPicker.attach(this, ".genderPicker", "gender", {onlyOne: true});
        },

        ok: function() {
            if (this.parent.model.get('allSelected') || _(this.parent.model.get('selected')).keys().length != 0) {
                return this.updateMentionsV4();
            }
            this.close();
        },

        showOkSpinner: function() {
            var $ok = this.$('.ok');
            $ok.toggleClass("disabled", true);
            $ok.html("<span class='spinner'></span>");
        },

        hideOkSpinner: function() {
            var $ok = this.$('.ok');
            $ok.toggleClass("disabled", false);
            $ok.text("Ok");
            this.updateProgressMsg('');
        },

        updateProgressMsg: function(msg) {
            this.$('.progress-msg').text(msg);
        },

        updateMentionsV4: function() {
            this.showOkSpinner();

            var filter = this.filter;
            var ids;
            var pm = this.parent.model;
            if (pm.get('allSelected')) {
                ids = _(pm.get('unselected')).keys();
                if (ids.length > 0) filter = "(" + filter + ") and id notin (" + toCommaList(ids) + ")";
            } else {
                ids = _(pm.get('selected')).keys();
                filter = "id in (" + toCommaList(ids) + ")";
            }

            var payload = { filter: filter };
            var model = this.model;
            var add = function(key, alt, fn) {
                var v = model.get(key);
                if (v) {
                    if (fn) v = fn(v);
                    payload[alt || key] = v == "UNKNOWN" ? "" : v;
                }
            };

            add('language');
            add('location', null, function(v) { return removeQuotes(v, "'"); });
            add('media', 'category');
            add('gender');

            var calls = [], a, i, s;
            var that = this;

            var _tags = model.get('_tags');
            if (_tags) {     // e.g. "'oink' 44692" we have to create the ones that start with quotes .. they are new
                a = splitQuotedString(_tags);
                var tags = [], newTags = [];
                for (i = 0; i < a.length; i++) {
                    s = a[i];
                    if (s.charAt(0) == "'") newTags.push(removeQuotes(s, "'").replace(/'/g, "\\'"));
                    else tags.push({id: parseInt(s), verified: true});
                }
                if (newTags.length > 0) {
                    calls.push(function () {
                        that.updateProgressMsg('Creating tag' + (newTags.length == 1 ? "" : "s"));
                        return Stores.deprecatedTagsStore.ensureTagsExist(newTags).then(tagIds => {
                            tagIds.forEach(id => tags.push({id: id, verified: true}))
                        })
                    });
                }
                payload.addTags = tags;
            }

            var _removeTags = model.get('_removeTags');
            if (_removeTags) {     // e.g. "'oink' 44692" we have to create the ones that start with quotes .. they are new
                a = splitQuotedString(_removeTags);
                var removeTags = [];
                for (i = 0; i < a.length; i++) removeTags.push({id: parseInt(a[i])});
                payload.removeTags = removeTags;
            }

            var grouseEndpoint = "/v4/accounts/" + that.accountCode + "/mentions";

            var brands = this.model.get('allBrands') ? thisModule.getSubsetBrands(this.parent.model, this.filter) : null;
            if (brands) {
                var baseFilter = removeNodes(parseFilterString(filter), function(node) {
                    return node.operandType === MentionQLexer.BRAND
                }).toString();
                brands.forEach(function(b) {
                    calls.push(function() {
                        that.updateProgressMsg('Updating ' + b.name);
                        var filter = baseFilter + " and brand isorchildof " + b.id;
                        logBulkMentionUpdate(that.accountCode, filter, payload);
                        return grousePut(grouseEndpoint,
                            Object.assign({}, payload, {filter: filter}))
                    });
                });
            } else {
                calls.push(function() {
                    logBulkMentionUpdate(that.accountCode, payload.filter, payload);
                    return grousePut(grouseEndpoint, payload)
                });
            }

            var errorFn = function(e) {
                that.hideOkSpinner();
                console.error("Error updating mentions", e);
                let errorMessage = "Error updating mentions";
                if (e.status === 422) {
                    errorMessage = e.responseJSON?.error ?? errorMessage;
                    if (errorMessage.toLowerCase().startsWith("invalid tag id")) {
                        errorMessage = "There was an error finding the tags in the account";
                    }
                } else if (e.status === 408) {
                    errorMessage = "This update is taking too long to perform. Try selecting fewer mentions.";
                } else if (e.status === 403) {
                    errorMessage = "You do not have enough permissions to update these mentions.";
                } else if (e.status === 0) {
                    errorMessage = "The filter is too large. Please try shortening it.";
                }
                showErrorDialog(errorMessage);
            };

            var pos = 0;
            var nextCall = function() {
                if (pos < calls.length) {
                    let p = calls[pos++]()
                    if (typeof p.done === "function") p.done(nextCall).fail(errorFn)
                    else p.then(nextCall).catch(errorFn)
                } else {
                    that.parent.clearSelection();
                    that.parent.fetchMentions();
                    that.close();
                }
            };
            nextCall();
        }
    });

    var toCommaList = function(ids) {
        var a = [];
        for (var i = 0; i < ids.length; i++) a.push("'" + ids[i] + "'");
        return a.join(",");
    };

    /**
     * If it is possible to edit mentions for multiple V4 brands at once with the filter and a brand subset is
     * active, then return the brands from the subset. Otherwise return null.
     */
    this.getSubsetBrands = function(pm, filter) {
        var i, b;
        if (!pm.get('allSelected') || _(pm.get('unselected')).keys().length > 0) return null;
        var subset = pm.get('brandSubset');
        if (!subset || subset.length <= 1) return null;

        // filter must include a single root brand with no exclusions etc.
        var fb = extractBrands(filter);
        if (fb.exclude.length > 0 || fb.include.length != 1) return null;
        b = getBrand(fb.include[0]);
        if (!b || b.parent) return null;

        var brands = [];
        var all = getRootBrands();
        if (all.length > 0 && subset && subset.length > 1) {
            for (i = 0; i < all.length; i++) {
                b = all[i];
                if (subset.indexOf(b.id) >= 0) brands.push(b);
            }
        }
        return brands.length > 0 ? brands : null;
    }
});
