var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span tooltip=\"The original language is "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            Unknown language\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"language") : depth0),{"name":"with","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":66},"end":{"line":22,"column":111}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-lang=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header>\n    <h5>Translate</h5>\n    <a class=\"btn close dialog-close\" tooltip=\"Close this dialog (esc)\"><i class=\"symbol-close\"></i></a>\n</header>\n\n<div class=\"body\">\n    <section class=\"translation-dialog__original-language\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"language") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"language") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "    </section>\n\n    <i class=\"icon-switch\"></i>\n\n    <section class=\"translation-dialog__language\">\n        "
    + alias2(__default(require("../../../helpers/dynamic-picker.js")).call(alias1,"language",{"name":"dynamic-picker","hash":{"tooltip":"Choose a language to translate to","text-flow":true},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":96}}}))
    + "\n    </section>\n\n    <section class=\"translation-dialog__original\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"language") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":118}}})) != null ? stack1 : "")
    + ">\n        "
    + alias2(__default(require("../../../helpers/render-emoji.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"original") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":33}}}))
    + "\n    </section>\n\n    <section class=\"translation-dialog__translation\">\n        "
    + alias2(__default(require("../../../helpers/spinner.js")).call(alias1,{"name":"spinner","hash":{},"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":27,"column":19}}}))
    + "\n    </section>\n\n    <section class=\"translation-dialog__suspected-language\">\n\n    </section>\n\n    <a class=\"translation-dialog__google-logo\"\n       href=\"https://translate.google.com\"\n       tooltip=\"Powered by Google Translate\" target=\"_blank\">\n        <img src=\"/static/img/translation/white-regular.png\">\n    </a>\n\n\n\n\n</div>\n\n<div class=\"dialog-button-bar\">\n    <a class=\"ok btn btn-primary\" tooltip=\"Close this dialog (esc)\">Close</a>\n</div>";
},"useData":true});