import _ from 'underscore';

/**
 * Iterates over an Object in increasing order of its keys. In each iteration, access is given to keys
 *     and values through 'this.key' and 'this.value' in each iteration. An 'else' statement can be used to
 *     handle undefined or empty Object instances.
 */
export default function(context, options) {

    if (!context || (Object.keys(context).length < 1)) return options.inverse(this);

    var ret = "",
        entries = [];

    _.each(context, function(value, key) {
        entries.push({ key: key, value: value })
    });

    entries = _.sortBy(entries, "key");

    _.each(entries, function(entry) {
        ret = ret + options.fn(entry);
    });
    return ret;
}