/**
 * Displays a tab for paging
 */

Beef.module("Pagination").addInitializer(function(startupOptions) {

    /**
     * Show pagination. Options must be as follows:
     *
     * {
     *   model: {
     *            total: .. the total number of items in the list
     *            offset: .. the current offset
     *            limit: ..  the current limit
     *            page: .. default starting page
     *          }
     * }
     *
     * Listen to change on View Model to update.
     */

    this.createView = function(options) {
        options.model.set('list', 1);
        options.model.set('totalButtons', 10);

        return new View({model: options.model})
    };

    var View = Backbone.Marionette.ItemView.extend({
        template: require("@/app/Pagination.handlebars"),

        attributes: { class: "beef-pager" },

        events: {
            "click":"click"
        },

        modelEvents: {
            "change": "render"
        },

        getTotalPages: function() {
            return Math.floor((this.model.get('total') - 1) / this.model.get('limit')) + 1;
        },

        templateHelpers: function() {
            return { totalPages: this.getTotalPages() }
        },

        onRender: function() {
            this.$('a[data-id="' + this.model.get('page') + '"]').toggleClass("active", true);
            var totalPages =  this.getTotalPages();
            if (totalPages < 1) {
                this.$('.prev').hide();
                this.$('.next').hide();
            }
            if (this.model.get('page') == 1) {
                this.$('.prev').toggleClass('disabled', true);
            }
            if (this.model.get('page') == totalPages) {
                this.$('.next').toggleClass('disabled', true);
            }
        },

        click: function(ev) {
            ev.preventDefault();
            var page = this.model.get('page');
            var list = this.model.get('list');
            var tb = this.model.get('totalButtons');
            var current = $(ev.target).attr('data-id');

            if (current == 'prev') {
                page = page != 1? page - 1 : page;
                if (list > 1 && page <= (list - 1) * tb) list = list - 1
            } else if (current == 'next') {
                page = page != this.getTotalPages() ? (page + 1) : (page);
                if (page > list * tb) list = list + 1
            } else if (current == 'more') {
                page = (list * tb) + 1;
                list = list + 1;
            } else if (current == 'less') {
                list = list - 1;
                page = (list * tb);
            }
            else if (current != null) { page = parseInt(current); }

            this.model.set({offset: (page - 1) * this.model.get('limit'), list: list, page: page});
        }
    });
});