<template>
    <section class="trending-topic-table" :class="{'trending-topic-table--disabled': disabled}">
        <section class="trending-topic-table__title animated fadeIn delay-400">
            <popup-menu left v-if="$listeners && $listeners.seeTitleMentions"
                        v-model="titleOpen"
                        class="trending-topic-table__title-popup"
                        :class="{'trending-topic-table__title-popup--open': titleOpen}">
                <template #activator>
                    <old-tooltip class="trending-topic-table__title-tooltip" :label="tooltip" right>{{ title }}</old-tooltip>
                </template>

                <section class="mini-menu">
                    <a @click="$emit('seeTitleMentions')">
                        <old-tooltip label="Open a dialog box showing you example mentions" right>See mentions</old-tooltip>
                    </a>
                    <a v-if="$listeners.seeTitleWordcloud" @click="$emit('seeTitleWordcloud')">
                        <old-tooltip label="Open a dialog box showing you a word cloud highlighting frequently used words" right>See wordcloud</old-tooltip>
                    </a>
                    <a v-if="$listeners.seeTitleAuthors" @click="$emit('seeTitleAuthors')">
                        <old-tooltip label="See the authors contributing to these conversations" right>See authors</old-tooltip>
                    </a>
                </section>
            </popup-menu>
            <old-tooltip v-else class="trending-topic-table__title-tooltip" :label="tooltip" right>{{ title }}</old-tooltip>

            <section class="trending-topic-table__title-border"></section>
        </section>
        <section class="trending-topic-table__grid"
                 :class="{'with-average': withAverage,
                 'with-sentiment': withSentiment,
                 'with-description': withDescription,
                 'with-cols': dataCols > 0}"
                 :data-cols="dataCols">
            <span class="trending-topic-table__heading trending-topic-table__heading--topic animated fadeIn delay-400">
                {{ wordType }}
            </span>
            <old-tooltip v-if="withDescription"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     :label="`Describes the ${wordType}`">
                Description
            </old-tooltip>
            <old-tooltip v-if="withSentiment"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     label="The Net Sentiment of these mentions">
                Net %
            </old-tooltip>
            <old-tooltip v-if="withTotal"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     label="The total number of mentions received">
                Volume
            </old-tooltip>
            <old-tooltip v-if="withExpected"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     label="This is daily average volume that we are expecting to see">
                Expected
            </old-tooltip>
            <old-tooltip v-if="withZScore"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     label="Shows how unusual this value is. The further from 0 the value, the more unusual">
                <em>z</em>-score
            </old-tooltip>
            <old-tooltip v-if="withMaximum"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     label="The maximum number of mentions received on any single day">
                Daily max
            </old-tooltip>
            <old-tooltip v-if="withMaxDate"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     label="The day on which the most mentions were received">
                Max date
            </old-tooltip>
            <old-tooltip v-if="withAuthorCount"
                     class="trending-topic-table__heading animated fadeIn delay-400"
                     label="The total number of unique authors posting these mentions">
                Authors
            </old-tooltip>
            <old-tooltip v-if="withAverage"
                     class="trending-topic-table__heading trending-topic-table__heading--span animated fadeIn delay-400"
                     label="The average number of mentions received per day">
                Daily average
            </old-tooltip>
            <template v-for="t in topics">
                <span :key="t.topic.id + '-name'"
                      class="trending-topic-table__name" :class="{'trending-topic-table__name--open': !!t._open}">
                    <popup-menu left v-model="t._open" ignore-activator-click>
                        <template #activator>
                            <span class="trending-topic-table__name-activator"
                                  @click.right.stop.prevent="$set(t, '_open', true)"
                                  @click.left.stop.prevent="$emit('seeMentions', t)"
                                  @mouseenter="showTooltip($event, t)">
                                <rpcs-tag v-if="t.topic.flag" :code="t.topic.flag"></rpcs-tag>
                                <span v-html="withEmoji(t.topic.name)"></span>
                            </span>
                        </template>

                        <section class="mini-menu">
                            <a @click="$emit('seeMentions', t)">
                                <old-tooltip label="Open a dialog box showing you example mentions" right>See mentions</old-tooltip>
                            </a>
                            <a v-if="$listeners && $listeners.seeWordcloud" @click="$emit('seeWordcloud', t)">
                                <old-tooltip label="Open a dialog box showing you a word cloud highlighting frequently used words" right>See wordcloud</old-tooltip>
                            </a>
                            <a v-if="$listeners && $listeners.seeAuthors"
                             @click="$emit('seeAuthors', t)">
                                <old-tooltip label="See the authors contributing to the conversation" right>
                                    See authors
                                </old-tooltip>
                            </a>
                        </section>
                    </popup-menu>

                </span>
                <span :key="t.topic.id + '-description'"
                      v-if="withDescription"
                      class="trending-topic-table__data trending-topic-table__description">
                    {{substituteTagParamaters(t.topic.clientDescription || t.topic.description)}}
                </span>
                <span :key="t.topic.id + '-missing'"
                      v-if="t.max === 0"
                      class="trending-topic-table__none">
                    Did not occur in this time period
                </span>
                <span :key="t.topic.id + '-net'"
                      v-if="withSentiment && !showNotOccur(t)"
                      class="trending-topic-table__data">
                    <net-sentiment v-if="t.net !== null" :net-sentiment="t.net"/>
                </span>
                <span :key="t.topic.id + '-total'"
                      v-if="withTotal && !showNotOccur(t)"
                      class="trending-topic-table__data">
                    <old-tooltip :label="`We received a total of ${formatNumber(t.average, 2)} mentions`">
                        <num :number="t.weekTotal"/>
                    </old-tooltip>
                </span>
                <span :key="t.topic.id + '-monthly'"
                      v-if="withExpected && !showNotOccur(t)"
                      class="trending-topic-table__data">
                    <old-tooltip :label="`We are expecting to only receive, on average, ${formatNumber(t.average, 2)} mentions a day`">
                        <num :number="t.average" :precision="1"/>
                    </old-tooltip>
                </span>
                <span :key="t.topic.id + '-delta'" v-if="withZScore && !showNotOccur(t)" class="trending-topic-table__data">
                    <old-tooltip :label="`Shows how unusual this value is. A z-score close to 0 lets us know that our daily average is close to what we expected to see.`">
                        <num :number="t.delta" :precision="2"/>
                    </old-tooltip>
                </span>
                <span :key="t.topic.id + '-max'"
                      v-if="withMaximum && !showNotOccur(t)"
                      class="trending-topic-table__data">
                    <old-tooltip :label="`On any single day, we received no more than ${formatNumber(t.max)} mentions`">
                        <num :number="t.max"/>
                    </old-tooltip>
                </span>
                <span :key="t.topic.id + '-max-date'"
                      v-if="withMaxDate && !showNotOccur(t)"
                      class="trending-topic-table__data">
                    <old-tooltip :label="`The most mentions were received on ${t.maxDate ? formatDate(t.maxDate, 'dddd, MMMM Do, YYYY') : ''}`">
                        <deq-date v-if="t.maxDate" :date="t.maxDate" :format="'YYYY-MM-DD'"/>
                    </old-tooltip>
                </span>
                <span :key="t.topic.id + '-authors'"
                      v-if="withAuthorCount && !showNotOccur(t)"
                      class="trending-topic-table__data">
                    <old-tooltip :label="` ${formatNumber(t.periodAuthorIdCount)} authors contributed to these mentions`">
                        <num :number="t.periodAuthorIdCount"/>
                    </old-tooltip>
                </span>
                <span :key="t.topic.id + '-average'"
                      v-if="withAverage && !showNotOccur(t)"
                      class="trending-topic-table__weekly trending-topic-table__data">
                    <old-tooltip :label="`We received, on average, ${formatNumber(t.weekAverage, 2)} mentions a day`">
                        <num v-if="t.weekAverage" :number="t.weekAverage" :precision="1"/>
                    </old-tooltip>
                </span>
                <section :key="t.topic.id + '-bar'"
                         v-if="withAverage && !showNotOccur(t)"
                         @mouseenter="showAverageTooltip($event, t)"
                         @click.capture.prevent="showAverageTooltip($event, t, true)"
                         class="trending-topic-table__bar-container">
                    <section class="trending-topic-table__bar" ref="bar"></section>
                </section>
            </template>

            <div v-if="showRestriction" class="trending-topic-table__restriction" @click="showMore">
                <slot name="restriction">
                    <div><old-tooltip label="Click to see more">See more...</old-tooltip></div>
                </slot>
            </div>


        </section>
        <span v-if="withFooter" class="trending-topic-table__footer">
            <be-button link
                       @click="downloadCsv"
                       tooltip="Download this as a CSV">
                CSV
            </be-button>
        </span>
    </section>

</template>

<script>
import './TrendTableSection.css';
import Num from "@/components/formatters/DeqNumber";
import PopupMenu from "@/components/PopupMenu";
import NetSentiment from "@/components/NetSentiment";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import TrendTooltip from "@/app/toplevel/explore/overview/TrendTooltip";
import BeButton from "@/components/buttons/BeButton";
import {getCsvForTrendingTopics} from "@/app/toplevel/explore/overview/ExploreUtilities";
import moment from "moment";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import AverageTooltip from "@/app/toplevel/explore/overview/components/AverageTooltip";
import DeqDate from "@/components/formatters/DeqDate";
import {substituteTagParamaters} from "@/app/utils/Tags";
import RpcsTag from "@/components/tags/RpcsTag";
import {formatDate, formatNumber, toEmojiHtml} from "@/app/utils/Format";

export default {
    components: {OldTooltip, RpcsTag, DeqDate, BeButton, NetSentiment, PopupMenu, Num},

    props: {
        topics: Array,
        title: String,
        tooltip: String,
        allData: Array,
        disabled: {
            type: Boolean,
            default: false
        },
        wordType: {
            type: String,
            default() { return 'Topic' }
        },
        withFooter: {
            type: Boolean,
            default() { return false }
        },
        withSentiment: {
            type: Boolean,
            default() { return true }
        },
        withMaximum: {
            type: Boolean,
            default() { return false }
        },
        withMaxDate: {
            type: Boolean,
            default() { return false }
        },
        withExpected: {
            type: Boolean,
            default() { return false }
        },
        withAverage: {
            type: Boolean,
            default() { return false }
        },
        withZScore: {
            type: Boolean,
            default() { return false }
        },
        withTotal: {
            type: Boolean,
            default() { return false }
        },
        withAuthorCount: {
            type: Boolean,
            default() { return false }
        },
        withDescription: {
            type: Boolean,
            default() { return false }
        },
        showRestriction: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            titleOpen: false
        }
    },

    computed: {
        dataCols() {
            let cols = 0;
            if (this.withExpected) cols++;
            if (this.withZScore) cols++;
            if (this.withTotal) cols++;
            if (this.withMaximum) cols++;
            if (this.withMaxDate) cols++;
            if (this.withAuthorCount) cols++;

            return cols;
        }
    },

    watch: {
        topics() {
            this.updateBarWidths();
        },

        allData() {
            this.updateBarWidths();
        }
    },

    mounted() {
        this.updateBarWidths();
    },

    methods: {
        formatNumber,
        formatDate,
        substituteTagParamaters,

        showNotOccur(tag) {
            return Number.isFinite(tag.max) && tag.max === 0
        },

        showMore() {
            this.$emit('showMore')
        },

        updateBarWidths() {
            if (this.topics.length && this.allData?.length) {
                const max = this.allData.reduce((current, topic) => Math.max(current, topic.weekAverage), 0);
                const ratio = 100 / max;

                if (this.withAverage) {
                    setTimeout(() => {
                        this.topics.forEach( (topic, index) => {
                            if (!this.$refs.bar[index]) return;
                            this.$refs.bar[index].style.width = topic.weekAverage * ratio + "px";
                        })
                    })
                }
            }
        },

        showTooltip(event, t) {
            showTooltipComponent(event.target, TrendTooltip, {topic: t});
        },
        showAverageTooltip(event, t, instant) {
            const options = {};
            if (instant) options.delay = 0;
            showTooltipComponent(event.target, AverageTooltip, {topic: t}, options)
        },
        withEmoji(text) {
            return toEmojiHtml(text);
        },
        downloadCsv() {
            try {
                const data = getCsvForTrendingTopics(this.allData);

                // Create a link for downloading the CSV data.
                let link = document.createElement("a");
                link.style = "display: none";
                link.href = window.URL.createObjectURL(new Blob([data], {type: "text/csv"}));
                link.download = "trending-topics-" + (moment().format("YYYY-MM-DD-HH[h]mm")) + ".csv";

                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            } catch (e) {
                console.error("Error exporting CSV", e);
                showErrorDialog("We've been unable to export the CSV. If this problem continues, please contact our support team.");
            }

        }
    }
}
</script>


<style scoped lang="sass">



</style>