<template>
    <dialog-box modal @close="$emit('input', false)" width="600px" title="Add Tag To Existing Mentions">
        <div>
            <div class="row-fluid">
                <div class="control-group span5">
                    <label>Tags to apply</label>
                    <span class="uneditable-input span12" style="width: 100%"><old-tag v-for="tag in tags" :key="tag.id" :tag="tag" no-close/></span>
                </div>
                <div class="control-group span7">
                    <label>Date range</label>
                    <published-input v-model="published" :read-only="busy"/>
                </div>
            </div>
            <div class="row-fluid" style="margin-top: 10px">
                <div class="control-group span12">
                    <label>Filter</label>
                    <div>
                        <english-filter :filter="filterWithDate" @text="filterAsEnglish = $event"/>
                    </div>
                </div>
            </div>

            <div v-if="tasks" class="row-fluid" style="margin-top: 10px">
                <table class="table table-condensed table-bordered" style="margin-bottom: 0">
                    <tbody>
                    <tr v-for="t in tasks" :key="t.brand.id">
                        <td>{{t.brand.shortName || t.brand.name}}</td>
                        <td>
                            <template v-if="t.done">
                                <span v-if="t.error" class="error">{{t.error}}</span>
                                <span v-else>Tagged {{t.updateCount}} mention(s)</span>
                            </template>
                            <span v-else class="spinner"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="row-fluid" style="margin-top: 10px">
                <div class="control-group span12">
                    <label>Brands that will be updated</label>
                    <div>{{brandList.map(b => b.shortName || b.name).join(", ")}}</div>
                </div>
            </div>

            <preview-mentions-dialog v-if="showMentions" v-model="showMentions" title="Mentions Matching Filter"/>
        </div>
        <template #buttons>
            <a class="btn pull-left" v-if="!done && !busy" @click="viewMentions">View Mentions</a>
            <a class="btn cancel" @click="cancel" v-if="!done">Cancel</a>
            <a class="btn btn-primary" @click="ok" :class="{disabled: busy}">
                <span class="spinner" v-if="busy"/>
                <span v-else>{{done ? 'Close' : 'Start'}}</span>
            </a>
        </template>
    </dialog-box>
</template>

<script>
    import DialogBox from '../../components/DialogBox'
    import EnglishFilter from '../../components/formatters/EnglishFilter'
    import PublishedInput from '../../components/inputs/PublishedInput'
    import PreviewMentionsDialog from '../../components/PreviewMentionsDialog'
    import axios from 'axios'

    import { deprecatedBrandsStore } from "@/store/deprecated/Stores"
    import { grouse, beef } from "@/store/Services"
    import {appendBasicFilters, buildBasicFilter, extractBrands} from "@/dashboards/filter/BasicFilter";
    import {mapState} from "vuex";
    import OldTag from "@/components/tags/OldTag";
    import {currentAccountCode} from "@/app/utils/Account";

    export default {
        name: "TagExistingMentionsDialog",

        components: {OldTag, DialogBox, EnglishFilter, PublishedInput, PreviewMentionsDialog },

        props: {
            value: Boolean,
            tags: Array,
            filter: String
        },

        data() {
            return {
                published: "QUARTER",
                showMentions: null,
                tasks: null,
                cancelSource: null,
                done: false,
                filterAsEnglish: null
            }
        },

        computed: {
            ...mapState(['account']),

            filterWithDate() {
                let df = buildBasicFilter({published: this.published})
                return appendBasicFilters(df, this.filter)
            },

            brandList() {
                let brandIds = extractBrands(this.filterWithDate).include
                if (!brandIds.length) return deprecatedBrandsStore.list
                else return brandIds.map(id => deprecatedBrandsStore.get(id))
            },

            busy() {
                return this.tasks && !this.done
            }
        },

        watch: {
            published() {
                if (this.done && this.tasks) {
                    this.tasks = null
                    this.done = false
                }
            }
        },

        mounted() {
            deprecatedBrandsStore.refresh(true)
        },

        methods: {
            viewMentions() {
                if (this.busy) return
                this.showMentions = this.filterWithDate
            },

            ok() {
                if (this.done) return this.$emit('input', false)
                if (this.tasks) return
                let endpoint = "/v4/accounts/" + currentAccountCode() + "/mentions?timeoutSecs=600"
                this.tasks = []
                this.done = false
                this.cancelSource = axios.CancelToken.source()
                let config = { timeout: 600 * 1000, cancelToken: this.cancelSource.token }

                let tagsToAdd = this.tags.map(t => {
                    return { id: t.id }
                });

                 this.brandList.forEach(b => {
                    let payload = {
                        filter: appendBasicFilters("Brand ISORCHILDOF " + b.id, this.filterWithDate),
                        addTags: tagsToAdd
                    }
                    let task = {
                        brand: b,
                        updateCount: null,
                        error: null,
                        done: false
                    }
                    task.call = grouse.put(endpoint, payload, config)
                        .then(res => task.updateCount = res.data.updateCount)
                        .catch(e => task.error = e)
                        .finally(() => {
                            task.done = true
                            this.onTaskComplete(task)
                        })
                    this.tasks.push(task)
                 })
            },

            async onTaskComplete(task) {
                if (this.tasks.find(t => !t.done)) return
                let descr = "Applied tags [" + this.tags.map(t => t.name) + "] for rule to " + (this.filterAsEnglish || this.filterWithDate);
                await beef.post("/api/accounts/" + currentAccountCode() +"/mash-log", { description: descr })
                this.done = true
                this.cancelSource = null
            },

            cancel() {
                if (!this.tasks) return this.$emit('input', false)
                if (!this.done && this.cancelSource) this.cancelSource.cancel("Cancelled by user")
            }
        }
    }
</script>

<style scoped>
    tr td:first-child {
        white-space: nowrap;
        width: 1px;
    }

    .error {
        color: var(--colour-error);
    }

    td > .spinner {
        vertical-align: -3px;
    }
</style>