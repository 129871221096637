import { render, staticRenderFns } from "./TrendTableSection.vue?vue&type=template&id=40d26284&scoped=true"
import script from "./TrendTableSection.vue?vue&type=script&lang=js"
export * from "./TrendTableSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d26284",
  null
  
)

export default component.exports