/**
 * Drill down by adding/removing days/weeks/months to the filter.
 */

import moment from "moment";

Beef.module("Widget.DateSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Dates",
        description:    "Interactively filter by day, week or month",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.CountSelector.View.extend({

        filterAttribute: "published",

        getGrouping: function() { return this.model.get('grouping') || "DAY" },

        groupby: function() { return "published[" + this.getGrouping() + "]" },

        multiselect: false,

        maxItems: 999999,

        modelEvents:Object.assign({
            "change": "onChange"
        }, Beef.Widget.SelectorWidget.View.prototype.modelEvents),

        onChange: function() {
            var c = this.model.changedAttributes();
            if (c['grouping'] || c['oldest-date-first'] !== undefined) {
                this.clearFilterAttribute();
                this.refresh();
            }
        },

        mapData: function(d) {
            var published = moment(d.published, "YYYY-MM-DD");
            var end;

            var name;
            switch (this.getGrouping()) {
                case "DAY":
                default:
                    name = published.format("ddd DD MMM YYYY");
                    end = published;
                    break;
                case "WEEK":
                    end = published.clone().add('days', 6);
                    var mdiff = published.month() != end.month();
                    name = published.format("ddd DD");
                    if (!mdiff) {
                        name += end.format(" - DD MMM YYYY")
                    } else {
                        name += published.format(" MMM") + end.format(" - DD MMM YY")
                    }
                    break;
                case "MONTH":
                    name = published.format("MMMM YYYY");
                    var daysInMonth = new Date(published.year(), published.month() + 1, 0).getDate();
                    end = published.clone().date(daysInMonth);
                    break;
                case "YEAR":
                    name = published.format("YYYY");
                    end = published.clone().dayOfYear(365 + (isLeapYear(published.year()) ? 1 : 0));
                    break;
            }

            return {
                id: published.format("YYYY/MM/DD") + "-" + end.format("YYYY/MM/DD"),
                name: name,
                count: d.mentionCount,
                title: ''
            };
        },

        compareFn: function(a, b) {
            var ans = a.id < b.id ? +1 : a.id > b.id ? -1 : 0;
            if (this.model.get('oldest-date-first')) ans = -ans;
            return  ans;
        }
    });

    var isLeapYear = function(year) {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    };

    this.SettingsView = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/selectors/DateSelectorSettings.handlebars"),

        editAttributes: function() {
            return ['grouping', 'oldest-date-first']
        },

        events: {
            'click .grouping .btn': 'click',
            "change .oldest-date-first": 'oldestDateFirstChanged'
        },

        click: function(ev) {
            const id = $(ev.target).closest('.btn').attr('data-id');
            if (id) {
                this.model.set('grouping', id);
                this.$(".btn[data-id]").removeClass('active');
                this.$(".btn[data-id='" + id + "']").addClass('active');
            }
        },

        onFirstRender: function() {
            this.$(".btn[data-id='" + (this.model.get('grouping') || 'DAY') + "']").addClass('active');
            this.$('.oldest-date-first').attr('checked', this.model.get('oldest-date-first'));
        },

        oldestDateFirstChanged: function(ev) {
            this.model.set('oldest-date-first', this.$('.oldest-date-first').is(':checked'))
        }
    });

});