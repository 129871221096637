<template>
    <div class="fb-generate-influencer-links">
        <h4>Generate Influencer Links</h4>

        <p>
            Click <span>'Generate Links'</span> to create links for authorising influencer <i
            class="symbol-facebook-rect" style="color: var(--colour-facebook)"></i> <span>Facebook Pages</span> and connected <i
            class="symbol-instagram" style="color: var(--colour-instagram)"></i> <span>Instagram
                Business</span> accounts.
            Once you have sent an influencer the generated link, they must follow the instructions in <a
            href="https://storage.googleapis.com/brandseye-public-files/dataeq-analyse-influencer-auth-1.0.pdf"
            target="_blank">this document</a> in order to make their profiles accessible to DataEQ.
        </p>

        <div class="fb-generate-influencer-links__link-amount-input">
            <h2>How many influencers require authorisation links?</h2>
            <input v-model="linkAmount" type="range" min="1" max="25"/>
            <i class="icon-link"></i>
            <deq-number :number="linkAmount"/>
            {{ formatPlural(linkAmount, "link") }} will be generated
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {formatPlural} from "@/app/utils/Format";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    name: "FbGenerateInfluencerLinks",
    components: {DeqNumber},
    computed: {
        ...mapState('profiles', ['newProfileData']),

        linkAmount: {
            get() {
                return this.newProfileData.influencerProfiles.generateLinkCount
            },
            set(value) {
                this.setInfluencerLinkCount(parseFloat(value))
            }
        }
    },

    methods: {
        formatPlural,
        ...mapMutations('profiles', ['setInfluencerLinkCount'])
    }
}
</script>

<style scoped lang="sass">

.fb-generate-influencer-links
    p
        color: var(--be-colour-text-dark)

        span
            color: white

    &__link-amount-input
        color: var(--be-colour-text-dark)
        margin-top: 40px

        h2
            margin-bottom: 30px

        input
            cursor: pointer
            margin-right: 30px
            text-align: center
            width: 250px

</style>