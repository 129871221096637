import _ from 'underscore';
import accountCode from "@/helpers/accountCode";

/**
 * Low level d3 code to render the topic wheel. This is separate to facilitate quick development and testing using
 * a static HTML file.
 */
export default function beefRenderTopicWheel(domNode, width, height, data, options) {
    options = options || {};
    var owner = options.owner || {};

    // convert data for flat V4 rows into a tree similar to V3
    // Still use the old format when visualising the tree in EditBrandTopicTree
    if (!options.v3) data = buildTreeFromV4Data(data, options);

    var noSelection = options.noSelection;
    const noAnimation = options.noAnimation

    // Firefox has some rendering oddities for this particular widget
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    var radius = (Math.min(width, height) / 2) - 10;

    // this is the position around the circle
    var x = d3.scaleLinear().range([0, 2 * Math.PI]);

    // this is the distance from the centre
    var y = d3.scaleLinear().range([0, radius]);
    // var y = d3.scale.sqrt().range([0, radius]);

    var colors = [];
    var cyclePoint = 14;
    if (data.length % cyclePoint < 3){
        cyclePoint -= 3
    }
    var numColors = Math.min(data.length, cyclePoint);
    var startOffset = 200; // largest block is blue
    var stepSize = 360 / numColors;
    var baseSaturation = 0.55;
    var baseLightness = 0.66;
    var depthSaturationShift = 0.11;
    var horizontalShift = 0.066;

    for (var i = 0; i < numColors ; i++){
        colors.push(d3.hsl(startOffset + i * stepSize, baseSaturation, baseLightness));
    }

    var color = d3.scaleOrdinal().range(colors);

    var cachedColor = function(name) {
        var colorHashMap = window.Beef ? Beef.generalData(accountCode()).get('topicColorMap') : null;
        if(!colorHashMap) {
            colorHashMap = {};
        } else {
            colorHashMap = JSON.parse(colorHashMap);
        }

        var hName = window.hex_md5 ? window.hex_md5(name) : name;
        if(colorHashMap[hName] == undefined) {
            colorHashMap[hName] = color(hName);
            if (window.Beef) Beef.generalData(accountCode()).set('topicColorMap', JSON.stringify(colorHashMap));
        }

        return d3.hsl(colorHashMap[hName]['h'], colorHashMap[hName]['s'], colorHashMap[hName]['l']);
    };

    var innerRadius = function (d) {
        return Math.max(0, y(d.y0));
    };
    var outerRadius = function (d) {
        return Math.max(0, y(d.y1));
    };
    var startAngle = function (d) {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x0)));
    };
    var endAngle = function (d) {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x1)));
    };

    var arc = d3.arc()
        .startAngle(startAngle)
        .endAngle(endAngle)
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    var lang = options.language || 'en';

    var svg = d3.select(domNode).append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("class", 'lang-' + lang);

    var filterId = _.uniqueId('drop-shadow-filter');

    var defs = svg.append('defs');
    var dropShadow = defs.append('filter').attr('id',filterId);

    var offsetOptions = {
        'result': 'offset',
        'in': 'SourceAlpha',
        'dx': 0,
        'dy': 2
    };
    var blurOptions = {
        'result': 'gaussianBlur',
        'in': 'offset',
        'stdDeviation': 7
    };
    var blendOptions = {
        'in': 'SourceGraphic',
        'in2': 'gaussianBlur',
        'mode': 'normal'
    };

    dropShadow.append('feOffset').attr(offsetOptions);
    dropShadow.append('feGaussianBlur').attr(blurOptions);
    dropShadow.append('feBlend').attr(blendOptions);

    var wheel = svg.append("g")
        .attr("transform", "translate(" + width / 2 + "," + (height / 2) + ")");

    var getAngle = function (d) {
        // Offset the angle by 90 deg since the '0' degree axis for arc is Y axis, while
        // for text it is the X axis.
        var thetaDeg = (180 / Math.PI * (arc.startAngle()(d) + arc.endAngle()(d)) / 2 - 90);
        // If we are rotating the text by more than 90 deg, then "flip" it.
        // This is why "text-anchor", "middle" is important, otherwise, this "flip" would
        // a little harder.
        return (thetaDeg > 90) ? thetaDeg - 180 : thetaDeg;
    };

    var distanceAroundCircle = function (d) {
        return (endAngle(d) - startAngle(d)) * innerRadius(d);
    };

    var toTextTransform = function (d) {
        if (d.depth == 0) return "";
        else return "translate(" + arc.centroid(d) + ") rotate(" + getAngle(d) + ")";
    };

    var doesTextFitInSegment = function (d) {
        if (d.depth == 0 && !d.children) return true; // for no topics found message
        return d.lineCount * 10 <= distanceAroundCircle(d)
    };

    var toTextDisplay = function (d) {
        d.doesTextFitInSegment = doesTextFitInSegment(d);
        return d.doesTextFitInSegment ? "block" : "none";
    };

    var toSegmentColor = function (d) {
        if (d.depth == 1) {
            return cachedColor(d.data.tag.name);
        } else if (d.depth > 1) {
            var parentColor = cachedColor(d.parent.data.tag.name);
            var shiftLevels = 4;
            var index = d.parent.children.indexOf(d);
            var mod = index % (shiftLevels * 2);
            var shiftLevel = mod > shiftLevels ? shiftLevels * 2 - mod : mod; // oscillate
            var s = parentColor.s - (d.depth - 1) * depthSaturationShift;
            var l = parentColor.l + shiftLevel * horizontalShift;
            return d3.hsl(parentColor.h, s, l);
        } else { // center
            return d3.hsl('#ddd');
        }
    };

    var toSegmentTextColor = function (d){
        if (d.depth == 1){
            return(toSegmentColor(d).darker(8));
        } else if (d.depth > 1){
            return(toSegmentColor(d.parent).darker(8));
        } else { // center
            return(d3.hsl('#ddd'));
        }
    };

    var tagData = [];

    var getSelectionForTag = function(tag) {
        return wheel.select("[data-id='" + (tag ? tag.id : 0) + "']");
    };

    var unhighlightSegment = function(tag) {
        var sel = getSelectionForTag(tag);
        sel.attr('filter', null);

        if(isFirefox) {
            // Firefox has a bug where it stops rendering certain dom elements if we transition this
            // animation
            sel.interrupt().attr("transform", "scale(1.0)");
        } else {
            sel.interrupt().transition().attr("transform", "scale(1.0)");
        }
    };

    var selection = null;

    var highlightSegment = function(tag) {
        if(isFirefox){
            // on firefox, we interrupt the last segment's animation and return it
            // in to its default position
            wheel.selectAll('g').each(function() {
                var sel = d3.select(this);

                var id = sel.attr('data-id');
                // We don't want to unselect the currently selected segment.
                if (noSelection || selection && selection.id != parseInt(id)) {
                    sel.attr("transform", "scale(1.0)");
                    sel.attr('filter', null);
                }
            })
        }

        var sel = getSelectionForTag(tag);
        sel.attr('filter', 'url(#' + filterId + ')');
        sel.transition().attr("transform", "scale(1.05)");
        var node = sel.node();
        if (node && node.parentNode && node.nextSibling) node.parentNode.appendChild(node);
    };

    var tagsEqual = function(tag1, tag2) {
        return tag1 && tag2 && tag1.id == tag2.id || !tag1 && !tag2;
    };

    var isTagChildOf = function(parent, tag) {
        if (!parent || !tag) return false;
        return parent.children && parent.children.indexOf(tag.id) >= 0;
    };

    var setSelection = function(tag, interactive) {
        var sel = getSelectionForTag(tag);
        var d = null;
        try {
            d = sel.datum();
        } catch (ignore) {
            console.error("TopicWheel.setSelection: tag does not exist: " + JSON.stringify(tag));
        }
        tag = d ? d.data.tag : null;
        if (tag && tag.id == 0) tag = null;    // treat center "topic" as nothing selected
        if (tagsEqual(selection, tag)) return;
        if (selection) {
            // if the new selection is not visible (i.e. not a child or sibling of current) then zoom out
            if (!isTagChildOf(selection, tag) && !(d && d.parent && isTagChildOf(d.parent.data.tag, selection))) {
                zoomSegment(d && d.parent ? d.parent.data.tag : null);
            }
            unhighlightSegment(selection);
        }

        selection = tag;
        if (interactive && options.onSelectionChanged) options.onSelectionChanged.call(this, tag);
        if (d) {
            if (d.children) zoomSegment(d.data.tag);
            else highlightSegment(d.data.tag);
        }
    };

    var buildLegend = function(animate) {
        if (options.hideLegend) return;

        // figure out which legends should be displayed, a quadrant for each legend and its index in that quadrant
        // 0 = top right, 1 = bottom right, 2 = bottom left, 3 = top left
        var qc = {0: 0, 1: 0, 2: 0, 3: 0};
        var data = [];
        for (var i = 0; i < tagData.length; i++) {
            var d = tagData[i];
            if (d.depth == 0 || d.doesTextFitInSegment) continue;

            var mapped = x(d.x0);
            if (mapped < 0 || mapped >= Math.PI * 2) continue;

            d.q = Math.floor(startAngle(d) * 2 / Math.PI);
            d.qi = qc[d.q]++;
            data.push(d);
        }

        var sel = svg.selectAll(".legend").data(data, function (d) { return d.data.tag.id });

        var legend = sel.enter().append("g").classed("legend", true);

        if (false && animate) {
            legend.style("opacity", 0)
                .transition()
                .delay(function(d) { return d.index * 20 })
                .style("opacity", 1.0);
        }

        legend.append("rect").style("fill", toSegmentColor).classed("swatch", true);

        legend.append("text")
            .text(function (d) { return getTagName(d.data.tag, options.language)})
            .each(function(d) { d.legendWidth = this.getComputedTextLength() + 24 });

        legend.insert("rect", ":first-child").classed("bg", true).attr('fill', '#fff');

        legend.on("mouseenter", function(d) {
            d3.select(this).classed('highlighted', true);
            highlightSegment(d.data.tag);
        });

        legend.on("mouseout", function(d) {
            d3.select(this).classed('highlighted', false);
            if (!tagsEqual(d.data.tag, selection)) unhighlightSegment(d.data.tag);
        });

        legend.merge(sel).attr("transform", function (d) {
            var x = d.q >= 2 ? 4 : width - d.legendWidth;
            var y;
            if (d.q == 0 || d.q == 3) y = 4 + d.qi * 20;
            else y = height - 24 - d.qi * 20;
            return "translate(" + x + "," + y + ")"
        });

        legend.select("rect.swatch").attr("width", 12).attr("height", 12).attr('x', 4).attr('y', 4);

        legend.select("text").attr('x', 20).attr('y', 15);

        legend.select("rect.bg").attr("width", function(d) { return d.legendWidth }).attr("height", 20);

        sel.exit().remove();
    };

    var zoomed = false;

    function zoomSegment(tag) {
        var d = getSelectionForTag(tag).datum();
        if (d && d.children) {
            wheel.classed("zoomed", zoomed = d.depth > 0);

            var t = wheel.transition()
                .duration(750)
                .tween("scale", function () {
                    var xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
                        yd = d3.interpolate(y.domain(), [d.y0, 1]),
                        yr = d3.interpolate(y.range(), [d.y0 ? 20 : 0, radius]);
                    return function (t) {
                        x.domain(xd(t));
                        y.domain(yd(t)).range(yr(t));
                    };
                });

            t.selectAll(".slice").attrTween("d", function (d) { return function () { return arc(d) } });

            var labels = t.selectAll(".segment-label");
            labels.attrTween("transform", function (d) { return function () { return toTextTransform(d) } });

            labels.styleTween("display", function (d) {
                return function (t) { return toTextDisplay(d) }
            });

            t.on("end", buildLegend);
        }
    }

    function onSegmentClick(d) {
        setSelection(tagsEqual(d.data.tag, selection) ? null : d.data.tag, true);
    }

    function onSegmentRightClick(d) {
        d3.event.preventDefault();
        if (options.onSegmentRightClick) options.onSegmentRightClick.apply(this, arguments);
    }

    var wrapSegmentLabel = function (d) {
        var g = d3.select(this).append("g");
        var label = getTagName(d.data.tag, options.language);
        var fill = toSegmentTextColor(d)
        //console.log("label " + label)
        var width = outerRadius(d) - innerRadius(d) - 6;
        var words = label.replace(/\//g, " / ").split(/\s+/).reverse(),
            word,
            line = [],
            lineCount = 1,
            text = g.append("text").attr("text-anchor", "middle").attr("fill", fill);
        while (word = words.pop()) {
            line.push(word);
            text.text(line.join(" "));
            if (text.node().getComputedTextLength() > width) {
                line.pop();
                text.text(line.join(" "));
                line = [word];
                text = g.append("text").text(word).attr("y", lineCount + "em").attr("text-anchor", "middle")
                    .attr("fill", fill);;
                ++lineCount;
            }
        }

        d.lineCount = lineCount;

        // center vertically
        var lineHeight = text.node().getBBox().height;
        var dy =  (- lineCount * lineHeight) / 2 + lineHeight * 0.80;
        g.attr("transform", "translate(0," + dy + ")");

        g.attr("display", toTextDisplay(d));
        // g.attr("display", true);
    };

    var entityMap = { "&": "&amp;", "<": "&lt;", ">": "&gt;", '"': '&quot;', "'": '&#39;', "/": '&#x2F;'};

    function escapeHtml(string) {
        return String(string).replace(/[&<>"'\/]/g, function (s) { return entityMap[s] });
    }

    var showTip = function(d) {
        hideTip(null);
        if (d.depth == 0 && !zoomed) return;
        var sel = d3.select(this);
        if (d.depth > 0) highlightSegment(d.data.tag);
        owner.__tip = d3.tip().attr('class', 'topic-wheel-tip lang-' + lang).offset([0, 0])
            .html(function(d) {
                if (d.depth == 0) return "<div class='description'>Click to zoom out</div>";
                return "<div class='name'>" + escapeHtml(getTagName(d.data.tag, options.language)) + "</div>" +
                    (getTagDescription(d.data.tag, options.language)
                        ? "<div class='description'>" + escapeHtml(getTagDescription(d.data.tag, options.language)) + "</div>"
                        : "") +
                    "<div class='help-inline'>(right-click for menu)</div>";
            });
        owner.__tip(sel);
        owner.__tip.show.apply(this, arguments);
    };

    function hideTip(d) {
        if (owner.__tip) {
            owner.__tip.destroy();
            owner.__tip = null;
            if (d && d.data.tag && (!tagsEqual(d.data.tag, selection) || selection.children)) unhighlightSegment(d.data.tag);
        }
    }


    data = {tag: {name: "Topics", id: 0}, children: data};
    if (data.children.length == 0) data.tag.name = "No topics found";

    var root = d3.hierarchy(data);              // convert into D3 Node tree
    root.sum(function(d) { return d.children ? 0 : d.size });  // value is sum of size of its children or its size
    root.sort(function(a, b) { return b.value - a.value });
    d3.partition()(root);                       // layout nodes to fit into square with sizes based on value
    // after this step each node has x0, y0, x1, y1 coordinates in the square

    // assign each node an index in depth first order for the incoming animation
    var setIndex = function(d, index) {
        d.index = ++index;
        if (d.children) for (var i = 0; i < d.children.length; i++) index = setIndex(d.children[i], index);
        return index;
    };
    setIndex(root, 0);

    wheel.on("mouseleave", hideTip);

    var segment = wheel.selectAll(".segment")
        .data(root.descendants())
        .enter()
        .append("g")
        .style("opacity", 0.0)
        .attr('data-id', function(d) { return d.data.tag.id })
        .each(function (d) { tagData.push(d) });

    segment.transition()
        .delay(function(d) {return noAnimation ? 0 : d.index * 20 })
        .style("opacity", 1.0)
        .on("end", function(d) {
            d3.select(this).on("click", onSegmentClick)
                .on("contextmenu", onSegmentRightClick)
                .on('mouseenter', showTip)
                .on('mouseleave', hideTip);
        });

    segment.attr("class", function (d) { return "segment depth" + d.depth + (d.children ? " has-children" : "") });

    segment.append("path")
        .classed("slice", true)
        .attr("d", arc)
        .style("opacity", function(d) { return d.depth == 0 ? 0.15 : null })
        .style("fill", toSegmentColor)
        .style("stroke", "#fff");

    segment.append("g")
        .classed("segment-label", true)
        .attr("transform", toTextTransform)
        .attr("pointer-events", "none")
        .each(wrapSegmentLabel)

    // sort so the longest labels are first so the legend makes best use of the space around the outside of the
    // wheel
    tagData.sort(function (a, b) {
        var na = getTagName(a.data.tag, options.language).length;
        var nb = getTagName(b.data.tag, options.language).length;
        return nb > na ? +1 : nb < na ? -1 : 0;
    });

    buildLegend(true);

    /**
     * Convert the flat V4 rows into a tree structure similar to V3.
     */
    function buildTreeFromV4Data(rows, options) {
        var rowsById = { }, i, row, tag;
        var segmentListIds = options.segmentListIds;
        for (i = 0; i < rows.length; i++) {
            row = rows[i];
            delete row.children; // This is added in the code below, and if we don't delete it, rerendering this chart breaks.
            tag = row.tag;
            if (tag.namespace === 'segment') {
                // when a segment is included in the filter but is not part of the tree then it doesn't have a parent
                // so leave it out
                if (!tag.parent) continue;
                // segments can have topic parents when they are added to a topic view tree
                if (tag.parent.namespace !== 'topic') {
                    // this is a very hacky way to exclude these but all we can do for now
                    if (tag.name.toLowerCase().indexOf("none of the above") >= 0) continue;
                    if (!segmentListIds || segmentListIds.indexOf(tag.parent.id) < 0) continue;
                }
            } else if (tag.namespace != 'topic') {
                continue;
            }
            rowsById[tag.id] = row;
            if (tag.labels && tag.labels[lang]) tag.name = tag.labels[lang];
            if (tag.descriptions && tag.descriptions[lang]) tag.description = tag.descriptions[lang];
            row.size = row.mentionCount;
        }

        var parents = [];
        for (i = 0; i < rows.length; i++) {
            row = rows[i];
            tag = row.tag;
            if (!tag.parent) continue;
            var p = rowsById[tag.parent.id];
            if (!p) rowsById[tag.parent.id] = p = {tag: tag.parent};
            if (!p.children) {
                p.children = [row];
                parents.push(p);
            } else {
                p.children.push(row);
            }
        }

        return parents;
    }

    /** This is an API for callers to use to interact with the wheel. */
    return {
        setSelection: setSelection
    }
}

function getTagName(t, language) {
    if (language != undefined && t['labels'] != undefined && t['labels'][language] != undefined) {
        return t['labels'][language];
    }
    return t.name;
}

function getTagDescription(t, language) {
    if (language != undefined && t['descriptions'] != undefined && t['descriptions'][language]) {
        return t['descriptions'][language];
    }
    return t.description;
}