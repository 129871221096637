/**
 * A simple mechanism for dynamically creating pickers (or for statically creating them, if needed.
 * By default, it will require the user to have one, and only one item selected.
 *
 * Some simple examples.
 *
 * Display a menu created from the map. The keys are codes returned as selected,
 * and the values are the names in the menu.
 *
   var yAxisPicker = Beef.DynamicPicker.create({
        mentionCount: "Mention volume",
        mentionPercent: "Percentage Volume",
        totalEngagement: "Engagement"
   })
 *
 * It's also possible to provide tooltip text, and to disable options:
   var yAxisPicker = Beef.DynamicPicker.create({
        mentionCount: {name: "Mention volume", description: "I am a tooltip", disabled: true }
   })
 *
 * The picker can also choose more than one element, and allow itself to have no element:
   var yAxisPicker = Beef.DynamicPicker.create({
        mentionCount: {name: "Mention volume", description: "I am a tooltip", disabled: true }
   }, {placeholder: "Optional items", onlyOne: false, alwaysOne: false })

 */
import {escapeExpression} from "@/app/utils/StringUtils";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("DynamicPicker").addInitializer(function(startupOptions) {

    this.create = function(items, options) {
        options = Object.assign({}, {
            placeholder: "Placeholder",
            placeholderPersistent: false,
            onlyOne: true,
            alwaysOne: true,
            tooltip: function(code) {
                return items[code] && items[code].description || ''
            },
            textFlow: false
        }, options || {});

        function createConverter(placeholder, placeholderPersistant) {
            return createTagConverter({
                tooltip: options.tooltip,
                items: items,
                noNeg: true,
                cls: options.textFlow ? 'dynamic-picker__dropdown' : 'tag',
                placeholder: options.placeholder,
                placeholderPersistent: placeholderPersistant,
                readOnly: options.alwaysOne && options.onlyOne,
                getName: function(d) {
                    if (!d) return '<UNKNOWN>';
                    return d.name || d
                },
                nameFormatter: function(name, negate, d) {
                    if (!d || !d.icon) return escapeExpression(name);
                    return "<i class='" + d.icon + " icon'></i>" + escapeExpression(name);
                }
            });
        }

        var pickerView = this.View = Beef.Picker.View.extend({
            attributes: { class: "dynamic-picker" },
            template: require("@/app/framework/pickers/dynamicpicker/DynamicPicker.handlebars"),
            items: items,
            index: -1,

            events: Object.assign({
                "click ul li": "liClick",
                "mousemove": "mousemove"
            }, Beef.Picker.View.prototype.events),

            initialize: function(options) {
                options.view.$el.on("keydown", this.keydown.bind(this));
                options.view.$el.on("keyup", this.keyup.bind(this));
            },

            getSelection: function() { return Beef.Picker.View.prototype.getSelection.call(this).sort() },
            getName: function(d) { return d.name || d },
            nameFormatter: function(d) {
                if (!d) return '<UNKNOWN>';
                return d.name || d
            },
            templateHelpers: function() {
                var selection = Beef.Picker.View.prototype.getSelection.call(this).sort();
                var sections = {};
                var itemList = [];

                Object.entries(items)
                    .forEach(function(entry) {
                        var key = entry[0];
                        var value = entry[1];
                        value.key = key;

                        if (value.visible !== undefined && !value.visible) return;

                        var item = {
                            key: key,
                            name: value.name || value,
                            icon: value.icon,
                            checked: selection.includes(key),
                            tooltip: value.description || null,
                            disabled: !!value.disabled,
                            value: value
                        };

                        if (!value.section) {
                            itemList.push(item);
                        } else {
                            var section = sections[value.section];
                            if (!section) section = sections[value.section] = {name: value.section, items: []};
                            section.items.push(item);
                        }
                    });

                var sectionList = Object.values(sections);

                var i = 0;
                itemList.forEach(function(item) { item.value.index = i++ });
                sectionList.forEach(function(s) {
                    s.items.forEach(function(item) { item.value.index = i++})
                });
                

                return {
                    items: itemList,
                    sections: sectionList
                }
            },

            onFirstRender: function() {
                var $active = this.$(".active");

                if ($active.length) {
                    var first = $active[0];
                    setTimeout(function() {
                        first.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    });
                }
            },

            liClick: function(ev) {
                if (ev.target.classList.contains("disabled")) return;

                var code = ev.target.dataset.code;

                if (code) {
                    var changes = {};
                    var result = this.options.alwaysOne ? true : !this.model.get(code);

                    var $item;
                    if(this.options.onlyOne) {
                        for (var a in this.items) {
                            changes[a] = false;
                            $item = this.$('.item-' + a);
                            if ($item.length) {
                                $item[0].classList.remove("active");
                            }
                        }
                    }

                    changes[code] = result;
                    this.model.set(changes);
                    if (result) {
                        ev.target.classList.add("active");
                        if (this.options.onlyOne) {
                            ev.target.classList.add("flash");
                            setTimeout(this.close.bind(this), 600);
                        }
                    }
                }
            },

            keyup: function(ev) {
                if (this.isClosed) return;

                if (ev.keyCode === 13) {
                    ev.stopPropagation();
                    ev.preventDefault();
                }
            },

            keydown: function(ev) {
                if (this.isClosed) return;

                var keys = Object
                    .values(this.items)
                    .filter(function(d) { return d.visible !== false })
                    .sort(function(lhs, rhs) {
                        return lhs.index - rhs.index;
                    })
                    .map(function(item) { return item.key });

                if (ev.keyCode === 13) { // enter
                    if (this.index >= 0 && this.index < keys.length) {
                        var el = this.$('[data-code="' + keys[this.index] + '"]');
                        if (el.length) this.liClick({target: el[0]})
                    }

                    ev.preventDefault();
                    ev.stopPropagation();
                    return;
                }

                // Start off with the first active item as the index to move from.
                if (this.index < 0 && (ev.keyCode === 38 || ev.keyCode === 40)) {
                    var selection = Beef.Picker.View.prototype.getSelection.call(this).sort();
                    if (selection && selection.length) {
                        this.index = keys.indexOf(selection[0]);
                    }
                }

                // Move the currently selected index.
                if (ev.keyCode === 38) { // up
                    this.index--;
                    if (this.index < 0) this.index = 0;
                } else if (ev.keyCode === 40) { // up
                    this.index++;
                    if (this.index >= keys.length) this.index = keys.length - 1;
                }

                // Highlight the index.
                if (ev.keyCode === 38 || ev.keyCode === 40) {
                    ev.preventDefault();
                    ev.stopPropagation();

                    var element;
                    for (var a in this.items) {
                        element = this.$('.item-' + a);
                        if (element.length) element[0].classList.remove("selected");
                    }

                    if (this.index >= 0 && this.index < keys.length) {
                        element = this.$('.item-' + keys[this.index]);
                        if (element.length) {
                            element = element[0];
                            element.classList.add("selected");
                            element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                        }
                    }
                }
            },

            mousemove: function() {
                if (this.index >= 0) {
                    for (var a in this.items) {
                        this.$('.item-' + a)[0].classList.remove("selected");
                    }
                    this.index = -1;
                }

            }
        });

        return {
            items: items,
            View: pickerView,
            createConverter: createConverter,
            converter: createConverter(options.placeholder, options.placeholderPersistant),

            attach: function(view, selector, attribute, opts) {
                opts = Object.assign({}, opts, {onlyOne: options.onlyOne, alwaysOne: options.alwaysOne});
                Beef.Picker.attachPicker(view, selector, attribute, pickerView, opts);
            }
        }

    };
});


