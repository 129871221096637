<template>
    <section v-if="isVisible">
        <slot/>
    </section>
</template>

<script>


import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

export default {
    store: VuexStore,
    computed: {
        ...mapState(['user']),
        isVisible() {
            return this.user.admin && this.user.ops;
        }
    }
}

</script>


<style scoped lang="sass">

</style>