/**
 * Displays information for a single online profile
 */
import {aggregatePage} from "@/data/Snoek";
import {formatNumber} from "@/app/utils/Format";
import _ from 'underscore';
import moment from "moment";
import {getProfileIcon} from "@/app/utils/Profiles";
import {getProfileDisplayName} from "@/setup/profiles/ProfileUtils";

Beef.module("Widgets.TextOnlineProfileItem").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
        isTwitter: function() {
            return this.get('type') === "TWITTER_SCREEN_NAME";
        },

        isFacebook: function() {
            return this.get('type') === "FACEBOOK_PAGE" || this.get('type') === "FACEBOOK_USER";
        },

        isInstagram: function() {
            return this.get('type') === "INSTAGRAM_USER";
        },
        
        isYouTube: function() { 
            return this.get('type') === "YOUTUBE_CHANNEL";
        },

        isLinkedIn: function() {
            return this.get('type') === "LINKEDIN_COMPANY";
        },

        getLink: function() {
            switch (this.attributes.type) {
                case "TWITTER_SCREEN_NAME":
                    return "https://twitter.com/" + this.get('handle');
                case "FACEBOOK_PAGE":
                case "FACEBOOK_USER":
                    return "https://www.facebook.com/" + this.get('handle');
                case "INSTAGRAM_USER":
                    return "http://instagram.com/" + this.get('handle');
                case "YOUTUBE_CHANNEL":
                    return "https://www.youtube.com/channel/" + this.get('handleId');
                case "LINKEDIN_COMPANY":
                    return "https://www.linkedin.com/in/" + this.get("handleId");
                case "TIKTOK":
                    return "https://www.tiktok.com/@" + this.get("handle");
            }
            return "";
        }
    });


    this.View = Backbone.Marionette.ItemView.extend({
        getTemplate: function() {
            var width = this.model.get('width');
            var height = this.model.get('height');

            if (width == 1) {
                return require("@/dashboards/widgets/onlineprofile/text/TextOnlineProfileItemWidth1.handlebars");
            }
            else if (this.collection.length > 1) {
                return require("@/dashboards/widgets/onlineprofile/text/TextOnlineProfileItemMultiple.handlebars");
            }
            return require("@/dashboards/widgets/onlineprofile/text/TextOnlineProfileItem.handlebars");
        },

        tagName: 'div',

        attributes: { class: "text-online-profile-item"},

        events: {
            'click .mentions-count': 'mentionCountClicked',
            'click .engagement': 'engagementClicked',
            'click .ots': 'otsClicked',
            'click .ave': 'aveClicked',
            'click .facebook-posts': 'fbProfileWallPostsClicked',
            'click .facebook-comments': 'fbProfileCommentsClicked',
            'click .facebook-other-posts': 'fbNonProfileWallPostsClicked',
            'click .facebook-other-comments': 'fbNonProfileCommentsClicked'
        },

        modelEvents: {
            'change:width': "render",
            'change:height': "render"
        },

        templateHelpers: function() {
            var type = this.model.get('type');
            var handle = this.model.get('handle');
            var handleId = this.model.get('handleId');
            var name = this.model.get('name');

            var prefix = getProfileIcon(type);
            var displayName = getProfileDisplayName(handle, handleId, name, type);

            var measurements = this.model.get('measurements');

            var followerDelta = 0,
                followingDelta = 0 ,
                statusDelta = 0;
            var followerPercent = 0,
                followingPercent = 0,
                statusPercent = 0;
            if ((this.model.isTwitter() || this.model.isInstagram()) && measurements) {
                followerDelta = measurements.end.followerCount - measurements.start.followerCount;
                followerPercent = Math.abs(followerDelta / measurements.start.followerCount * 100);
                followingDelta = measurements.end.followingCount - measurements.start.followingCount;
                followingPercent = Math.abs(followingDelta / measurements.start.followingCount * 100);
                statusDelta = measurements.end.statusCount - measurements.start.statusCount;
                statusPercent = Math.abs(statusDelta / measurements.start.statusCount * 100);
            }

            var likesDelta = 0,
                likesPercent = 0;
            var checkinsDelta = 0;
            var wereHereDelta = 0;
            var talkingAboutDelta = 0;
            if (this.model.isFacebook() && measurements) {
                likesDelta = measurements.end.likes - measurements.start.likes;
                likesPercent = Math.abs(likesDelta / measurements.start.likes * 100);
                checkinsDelta = measurements.end.checkins - measurements.start.checkins;
                wereHereDelta = measurements.end.wereHereCount - measurements.start.wereHereCount;
                talkingAboutDelta = measurements.end.talkingAboutCount - measurements.start.talkingAboutCount;
            }
            
            var subscribersDelta = 0;
            var subscribersPercent = 0;
            if (this.model.isYouTube() && measurements) {
                subscribersDelta = measurements.end.subscribers - measurements.start.subscribers;
                subscribersPercent = Math.abs(subscribersDelta / measurements.start.subscribers * 100);
                statusDelta = measurements.end.statusCount - measurements.start.statusCount;
                statusPercent = Math.abs(statusDelta / measurements.start.statusCount * 100);
            }

            var authors = this.getAuthorNames();

            var networkIcon = "";
            if (this.model.isTwitter()) networkIcon = "twitter";
            else if (type == "FACEBOOK_USER") networkIcon = "facebook-user";
            else if (type == "FACEBOOK_PAGE") networkIcon = "facebook-page";
            else if (type == "LINKEDIN_COMPANY") networkIcon = "linkedin-company";
            else if (this.model.isInstagram()) networkIcon = "instagram";

            // avatars
            var avatar = this.model.get('pictureLink');
            var failAvatar = Beef.resources["twitter_avatar.png"];
            if (this.model.isTwitter()) avatar = "https://twitter.com/"  + displayName + "/profile_image";
            else if(this.model.isFacebook()){
                avatar = "https://graph.facebook.com/" + handleId + "/picture";
                failAvatar = Beef.resources["facebook_avatar.png"];
            }
            else if (this.model.isInstagram()) failAvatar = Beef.resources["instagram_avatar.jpg"];

            return {
                isTwitter: type == 'TWITTER_SCREEN_NAME',
                isFacebook: this.model.isFacebook(),
                isInstagram: this.model.isInstagram(),
                isYouTube: this.model.isYouTube(),
                isLinkedIn: this.model.isLinkedIn(),
                networkIcon: networkIcon,
                prefix: prefix,
                displayName: displayName,
                avatar: avatar,
                failAvatar: failAvatar,

                followerDelta: Math.abs(followerDelta),
                followerPercent: followerPercent,
                followingDelta: Math.abs(followingDelta),
                followingPercent: followingPercent,
                statusDelta: Math.abs(statusDelta),
                statusPercent: statusPercent,
                followerArrow: this.createArrowFunction(followerDelta),
                followingArrow: this.createArrowFunction(followingDelta),
                statusArrow: this.createArrowFunction(statusDelta),

                likesDelta: Math.abs(likesDelta),
                likesPercent: likesPercent,
                checkinsDelta: Math.abs(checkinsDelta),
                wereHereDelta: Math.abs(wereHereDelta),
                talkingAboutDelta: Math.abs(talkingAboutDelta),
                likesArrow: this.createArrowFunction(likesDelta),
                checkinsArrow: this.createArrowFunction(checkinsDelta),
                wereHereArrow: this.createArrowFunction(wereHereDelta),
                talkingAboutArrow: this.createArrowFunction(talkingAboutDelta),
                likesTrend: this.createTrend(likesDelta),
                talkingAboutTrend: this.createTrend(talkingAboutDelta),
                checkinsTrend: this.createTrend(checkinsDelta),
                wereHereTrend: this.createTrend(wereHereDelta),

                subscribersPercent: subscribersPercent,
                subscribersDelta: Math.abs(subscribersDelta),
                subscribersArrow: this.createArrowFunction(subscribersDelta),

                vanity: function() {
                    var prefix = '';
                    if (this.model.isTwitter()) {
                        prefix = "@";
                    }
                    return prefix + displayName
                }.bind(this),

                link: this.model.getLink(),
                date: measurements ?  new moment(measurements.end.captured).format('ddd, MMM DD, YYYY, HH:mm') : '',
                start: measurements ? new moment(measurements.start.captured).format('ddd, MMM DD, YYYY, HH:mm') : '',
                dateShort: measurements ? new moment(measurements.end.captured).format('YYYY/MM/DD, HH:mm') : '',
                startShort: measurements ? new moment(measurements.start.captured).format('YYYY/MM/DD, HH:mm') : '',

                mentionsUrl:    "/" + this.code + "/mentions?filter=" + encodeURIComponent(this.getMentionFilter())//,
                // engagementUrl:  "/" + this.code + "/mentions?filter=" + Beef.FilterParser.urlEncodeFilter(this.getEngagementFilter(authors)) + "&orderby=engagement desc",
                // otsUrl:         "/" + this.code + "/mentions?filter=" + Beef.FilterParser.urlEncodeFilter(this.getOtsFilter(authors)) + "&orderby=ots desc",
                // aveUrl:         "/" + this.code + "/mentions?filter=" + Beef.FilterParser.urlEncodeFilter(this.getAveFilter(authors)) + "&orderby=ave desc",
                //
                // facebookProfileWallPostsUrl: "/" + this.code + "/mentions?filter=" + Beef.FilterParser.urlEncodeFilter(this.getFacebookProfileWallPosts(authors)),
                // facebookProfileCommentsUrl: "/" + this.code + "/mentions?filter=" + Beef.FilterParser.urlEncodeFilter(this.getFacebookProfileComments(authors)),
                // facebookNonProfileWallPostsUrl: "/" + this.code + "/mentions?filter=" + Beef.FilterParser.urlEncodeFilter(this.getFacebookNonProfileWallPosts()),
                // facebookNonProfileCommentsUrl: "/" + this.code + "/mentions?filter=" + Beef.FilterParser.urlEncodeFilter(this.getFacebookNonProfileComments())
            };
        },

        initialize: function(options) {
            this.code = options.code;
            this.filter = this.model.get('filter');
            this.collection = options.collection;
            if (!this.code) {
                throw new Error("No account code supplied");
            }
            if (!this.filter) {
                throw new Error("No filter was supplied");
            }
        },

        createArrowFunction: function(value) {
            return function() {
                if (value < 0) {
                    return "<i class='arrow down-arrow icon-down-bold'></i>";
                }
                if (value > 0) {
                    return "<i class='arrow up-arrow icon-up-bold'></i>";
                }
                return '';
            };
        },

        createTrend: function(value) {
            return function() {
                if (value < 0) {
                    return "decreased by " + formatNumber(Math.abs(value));
                }
                else if (value > 0) {
                    return "increased by " + formatNumber(Math.abs(value));
                }
                else {
                    return "remain unchanged";
                }
            }
        },

        onRender: function() {
            //
            var width = this.model.get('width'),
                height = this.model.get('height');
            var single = width == 1 && height == 1;
            var twitterInstagram2x2 = width == 2 && height == 2 && (this.model.isTwitter() || this.model.isInstagram());

            this.$el.toggleClass('single', this.collection.length == 1 && (single || twitterInstagram2x2));
            this.$el.removeClass (function (index, className) {
                return (className.match (/(^|\s)w\S+/g) || []).join(' ');
            });
            this.$el.toggleClass('w' + width, true);

            var measurements = this.model.get('measurements');

            if (measurements) {
                if (this.model.get('_measurement-data')) {
                    this.showMeasurements();
                }
                else {
                    var url = Beef.Widget.OnlineProfile.getMeasurementUrl(this.code, this.filter, [this.model.get('id')]);
                    var dataPromise = aggregatePage(url.url, url.params);
                    $.when(dataPromise).done(function(data) {
                        this.model.set("_measurement-data", data);
                        this.showMeasurements();
                    }.bind(this));
                }
            }
        },

        showMeasurements: function() {
            var data = this.model.get('_measurement-data');
            var measurements = this.model.get('measurements');

            function getTime(d) {
                return d.time;
            }

            _(data).each(function(d) {
                d.time = new moment(d.captured).unix();
            });

            if (this.model.isTwitter()) {
                Area(
                    this.$('.follower-area')[0],
                    data,
                    getTime,
                    function(d) { return d.followerCount; }
                );

                Area(
                    this.$('.following-area')[0],
                    data,
                    getTime,
                    function(d) { return d.followingCount; }
                );

                Area(
                    this.$('.status-area')[0],
                    data,
                    getTime,
                    function(d) { return d.statusCount; }
                );
            }

            if (this.model.isFacebook()) {
                if (measurements.end.likes) {
                    Area(
                        this.$('.likes-area')[0],
                        data,
                        getTime,
                        function(d) { return d.likes; }
                    );
                }

                if (measurements.end.checkins) {
                    Area(
                        this.$('.checkins-area')[0],
                        data,
                        getTime,
                        function(d) { return d.checkins; }
                    );
                }

                if (measurements.end.talkingAboutCount) {
                    Area(
                        this.$('.talking-about-area')[0],
                        data,
                        getTime,
                        function(d) { return d.talkingAboutCount; }
                    );
                }

                if (measurements.end.wereHereCount) {
                    Area(
                        this.$('.were-here-area')[0],
                        data,
                        getTime,
                        function(d) { return d.wereHereCount; }
                    );
                }

            }
        },

        //-----------------------------------------

        getAuthorNames: function() {
            var apiNames = this.model.get('apiNames');
            if(apiNames) {
                return _(apiNames).chain()
                    .map(function (n) {
                        return "authorName contains '" + n + "'";
                    })
                    .inject(function (lhs, rhs) {
                        return lhs + " and " + rhs;
                    })
                    .value();
            }
            return "authorName contains '" + (this.model.get("name") || this.model.get("handle") || this.model.get("handleId")) + "'";
        },


        getMentionFilter: function(authors) {
            return "(" + this.filter + ") AND AuthorID IS '" + Beef.AuthorIdPicker.toNiceAuthorId(
                this.model.get('authorId'), this.model.get('handle'), this.model.get('name')) + "'";
        },

        getFacebookProfileWallPosts: function(authors) {
            if (!authors) authors = this.getAuthorNames();
            return "(" + this.filter + ") and (" + authors + ") and (link doesntcontain 'comment')";
        },

        getFacebookProfileComments: function(authors) {
            if (!authors) authors = this.getAuthorNames();
            return "(" + this.filter + ") and (" + authors + ") and (link contains 'comment')";
        },

        getFacebookNonProfileWallPosts: function() {
            return "(" + this.filter + ") and (link doesntcontain 'comment') and (media isnt ENTERPRISE) and (link contains 'http://www.facebook.com/" + this.model.get('handle') +"/posts/')";
        },

        getFacebookNonProfileComments: function() {
            return "(" + this.filter + ") and (link contains 'comment') and (media isnt ENTERPRISE) and (link contains 'http://www.facebook.com/" + this.model.get('handle') +"/posts/')";
        },

        mentionCountClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getMentionFilter();
            Beef.MentionList.navigateToMentions(this.code, filter, null, ev.metaKey);
        },

        fbProfileWallPostsClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getFacebookProfileWallPosts();
            Beef.MentionList.navigateToMentions(this.code, filter, null, ev.metaKey);
        },

        fbProfileCommentsClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getFacebookProfileComments();
            Beef.MentionList.navigateToMentions(this.code, filter, null, ev.metaKey);
        },

        fbNonProfileWallPostsClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getFacebookNonProfileWallPosts();
            Beef.MentionList.navigateToMentions(this.code, filter, null, ev.metaKey);
        },

        fbNonProfileCommentsClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getFacebookNonProfileComments();
            Beef.MentionList.navigateToMentions(this.code, filter, null, ev.metaKey);
        },

        getEngagementFilter: function(authors) {
            if (!authors) authors = this.getAuthorNames();
            return "(" + this.filter + ") and (" + authors + " and engagement > 0)";
        },

        engagementClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getEngagementFilter();
            Beef.MentionList.navigateToMentions(this.code, filter, "engagement desc", ev.metaKey);
        },

        getOtsFilter: function(authors) {
            if (!authors) authors = this.getAuthorNames();
            return "(" + this.filter + ") and (" + authors + ")";
        },

        otsClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getOtsFilter();
            Beef.MentionList.navigateToMentions(this.code, filter, "ots desc", ev.metaKey);
        },


        getAveFilter: function(authors) {
            if (!authors) authors = this.getAuthorNames();
            return "(" + this.filter + ") and (" + authors + ")";
        },

        aveClicked: function(ev) {
            ev.preventDefault();
            var filter = this.getAveFilter();
            Beef.MentionList.navigateToMentions(this.code, filter, "ave desc", ev.metaKey);
        }

    });

    var Area = function (selector, data, x, y) {
        x = x || function (d) {
            return d.x;
        };
        y = y || function (d) {
            return d.y;
        };

        var $main = $(selector);
        var width = $main.width(),
            height = $main.height();

        if (width == 0 && height == 0) console.warn("Area chart given no width/height");

        if (!$('svg', $main).length) {
            $main.html('<svg></svg>');
        }

        var svg = d3.select($main[0]).select('svg');
        svg
            .attr('width', width)
            .attr('height', height)
            .classed('bm', true)
            .classed('chart-sparkline', true);

        var minX = x(_(data).min(x));
        var maxX = x(_(data).max(x));
        var minY = y(_(data).min(y));
        var maxY = y(_(data).max(y));

        var xScale = d3.scaleLinear().domain([minX, maxX]).range([0, width]);
        var yScale = d3.scaleLinear().domain([minY, maxY]).range([height, 0]);

        var area = d3.area()
            .x(function (d) {return xScale(x(d)) })
            .y0(height)
            .y1(function (d) {return yScale(y(d)) });

        var areas = svg.selectAll('.bm-chart-sparkline-area').data([data]);
        areas.exit().remove();
        areas.enter()
            .append('path')
            .classed('bm-chart-sparkline-area', true)
            .merge(areas)
            .attr('d', area)
            .style('opacity', 0)
            .transition()
            .duration(1000)
            .style('opacity', 1.0);
    };
});

