import moment from "moment";
import {isString} from "@/app/utils/StringUtils";
import {account} from "@/app/utils/Account";


/*
 * Formats ISO-8601 dates with a given format (if unspecified defaults to long formatting) in the accounts
 * timezone. Example time string: 2013-03-15T06:12:00.000-0100
 */
export default function(time, format, options) {
    options = Object.assign({
        plainText: false
    }, options && options.hash || {});

    if (!time) return new Handlebars.SafeString("");
    if (!isString(format)) format = "MMMM Do YYYY HH:mm:ss Z";
    var m = moment(time);
    var timezone = account().timezone;
    var tz = m.tz(timezone);
    if (!tz) tz = m.tz('UTC');
    var tooltipTime = "dddd, MMMM Do, YYYY, HH:mm";
    if (options.plainText) return new Handlebars.SafeString(tz.format(format));
    return new Handlebars.SafeString("<span title='" + m.format(tooltipTime) + ", in timezone " + timezone + "'>" + tz.format(format) + "</span>");
}