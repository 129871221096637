<template>
    <i v-if="dots" class="icon-dot-3 op-btn" :title="title" @click="$emit('click', $event)"/>
    <span v-else class="op-btn"><span class="caret"/></span>
</template>

<script>
    export default {
        name: "OptionsButton",

        props: {
            title: String,
            dots: Boolean
        }
    }
</script>

<style scoped>
    .op-btn {
        line-height: 1.45em;
        display: inline-block;
        width: 1.45em;
        height: 1.45em;
        cursor: pointer;
        transition: linear 0.1s;
        border-radius: 50%;
        border: 1px solid transparent;
    }

    .op-btn .caret {
        display: inline-block;
        margin-left: 6px;
        margin-top: 9px;
    }

    .op-btn:hover {
        border-color: var(--be-filter-tag-hover-colour-subtle);
        color: var(--be-filter-tag-hover-colour);
    }

    .op-btn:hover .caret {
        border-top-color: var(--be-filter-tag-hover-colour);
    }

</style>