import _ from 'underscore';
import {isObject} from "@/app/utils/Util";

export function getPositiveSentimentColour(optionalDomElement) {
    optionalDomElement = optionalDomElement || document.documentElement;
    return getComputedStyle(optionalDomElement)
        .getPropertyValue('--be-colour-positive')
        .trim();
}

export function getNegativeSentimentColour(optionalDomElement) {
    optionalDomElement = optionalDomElement || document.documentElement;
    return getComputedStyle(optionalDomElement)
        .getPropertyValue('--be-colour-negative')
        .trim();
}

export function getNeutralSentimentColour(optionalDomElement) {
    optionalDomElement = optionalDomElement || document.documentElement;
    return getComputedStyle(optionalDomElement)
        .getPropertyValue('--be-colour-neutral')
        .trim();
}

const allColours = [
    '#f0f8ff', '#faebd7', '#00ffff', '#7fffd4', '#f0ffff', '#f5f5dc', '#ffe4c4', '#000000', '#ffebcd', '#0000ff', '#8a2be2',
    '#a52a2a', '#deb887', '#5f9ea0', '#7fff00', '#d2691e', '#ff7f50', '#6495ed', '#fff8dc', '#dc143c', '#00ffff', '#00008b',
    '#008b8b', '#b8860b', '#a9a9a9', '#006400', '#bdb76b', '#8b008b', '#556b2f', '#ff8c00', '#9932cc', '#8b0000', '#e9967a',
    '#8fbc8f', '#483d8b', '#2f4f4f', '#00ced1', '#9400d3', '#ff1493', '#00bfff', '#696969', '#1e90ff', '#d19275', '#b22222',
    '#fffaf0', '#228b22', '#ff00ff', '#dcdcdc', '#f8f8ff', '#ffd700', '#daa520', '#808080', '#008000', '#adff2f', '#f0fff0',
    '#ff69b4', '#cd5c5c', '#4b0082', '#fffff0', '#f0e68c', '#e6e6fa', '#fff0f5', '#7cfc00', '#fffacd', '#add8e6', '#f08080',
    '#e0ffff', '#fafad2', '#d3d3d3', '#90ee90', '#ffb6c1', '#ffa07a', '#20b2aa', '#87cefa', '#8470ff', '#778899', '#b0c4de',
    '#ffffe0', '#00ff00', '#32cd32', '#faf0e6', '#ff00ff', '#800000', '#66cdaa', '#0000cd', '#ba55d3', '#9370d8', '#3cb371',
    '#7b68ee', '#00fa9a', '#48d1cc', '#c71585', '#191970', '#f5fffa', '#ffe4e1', '#ffe4b5', '#ffdead', '#000080', '#fdf5e6',
    '#808000', '#6b8e23', '#ffa500', '#ff4500', '#da70d6', '#eee8aa', '#98fb98', '#afeeee', '#d87093', '#ffefd5', '#ffdab9',
    '#cd853f', '#ffc0cb', '#dda0dd', '#b0e0e6', '#800080', '#ff0000', '#bc8f8f', '#4169e1', '#8b4513', '#fa8072', '#f4a460',
    '#2e8b57', '#fff5ee', '#a0522d', '#c0c0c0', '#87ceeb', '#6a5acd', '#708090', '#fffafa', '#00ff7f', '#4682b4', '#d2b48c',
    '#008080', '#d8bfd8', '#ff6347', '#40e0d0', '#ee82ee', '#d02090', '#f5deb3', '#ffffff', '#f5f5f5', '#ffff00', '#9acd32'
];

export const palettes = [
    // original v3 colours from Bianca
    {id: "original",    colours: ['#58B6FF', '#5473BD', '#DF71FC', '#9856B0', '#ff58a3', '#D22D6F', '#fc6a4b', '#E53E39', '#FFD658', '#E8AF44', '#43C278', '#318F58']},

    // Color Brewer stuff from Connie
    {id: "blues9",      colours: ["#08306B", "#08519C", "#2171B5", "#4292C6", "#6BAED6", "#9ECAE1"]},
    {id: "greens9",     colours: ["#00441B", "#006D2C", "#238B45", "#41AB5D", "#74C476", "#A1D9C0"]},
    {id: "RDPU9",       colours: ["#49006A", "#7A0177", "#AE017E", "#DD3497", "#F768A1", "#FA9FB5"]},

    // august 2014 corp colour palettes
    {id: "be14-gray",   colours: ["#636363", "#212121", "#434343", "#969696", "#BCBCBC", "#D5D5D5"]},
    {id: "be14-blue",   colours: ["#2DB8C2", "#0D7880", "#189AA3", "#63D8E0", "#90EEF5", "#B9F1F5"]},
    {id: "be14-red",    colours: ["#ED1C24", "#820005", "#A80A10", "#F74A51", "#F77C80", "#FFBBBE"]},
    {id: "be14",        colours: ["#2FB5C0", "#0d7880", "#214043", "#ED1C24", "#820005", "#212121", "#BCBCBC"]},
    {id: "rpcs",        colours: ["#7050b1", "#f3a930", "#c62c3e", "#f27c41"]},
];

// palette colours arrays keyed by id
const palettesMap = _.reduce(palettes, function(m, p) { m[p.id] = p.colours; return m }, {});

const original = palettesMap.original;

export const defaultCustom = original.slice(0).concat('#8a2be2', '#a52a2a', '#deb887', '#5f9ea0', '#7fff00', '#d2691e');

/**
 * Return an array of colours. Options:
 * colour-palette: id of palette to use or 'custom' for user defined palette
 * colour-palette-custom: palette used for 'custom' option
 * colour-index: optional position in palette to start at i.e. palette is rotated so this colour is at index 0
 */
export function getPalette(options, defaults) {
    var o = toPaletteOptions(options, defaults);
    var pid = o["colour-palette"];
    var p = pid == "custom" ? o["colour-palette-custom"] : palettesMap[pid];
    var i = o["colour-index"];
    if (i > 0) {
        i %= p.length;
        p = p.slice(i).concat(p.slice(0, i));
    }
    return p;
}

/**
 * Return an object with missing/invalid options replaced with defaults.
 */
export function toPaletteOptions(options, defaults) {
    defaults = defaults || {};
    var pid = options["colour-palette"] || defaults["colour-palette"] || "original";
    if (pid != "custom" && !palettesMap[pid]) pid = "original";
    var custom = options["colour-palette-custom"] || defaults["colour-palette-custom"] || defaultCustom;
    var i = options["colour-index"];
    if (i == undefined) i = defaults["colour-index"] || 0;
    if (!Number.isFinite(i) && Number.isNaN(i = parseInt(i))) i = 0;
    return { "colour-palette": pid, "colour-palette-custom": custom,  "colour-index": i };
}

/**
 * Returns the colour brewer 9 sequence green scheme.
 */
export function getGreenScheme() {
    return palettesMap.greens9;
}

/**
 * Convert the old scheme stuff for colours into the options expected by getPalette by modifying attrs. This is
 * called from the constructor of Beef.Dashboard.WidgetModel.
 */
export function schemeToPaletteOptions(attrs) {
    if (attrs['colour-palette']) return;

    var c = attrs['colour-definitions'];
    if (c) {
        attrs['colour-palette-custom'] = c;
        delete attrs['colour-definitions'];
    }

    var mode = attrs['colour-mode'];
    if (mode == "DEFINE") {
        attrs['colour-palette'] = "custom";
        attrs['colour-index'] = 0;
    } else if (mode) {
        var pid;
        var i = attrs['colour-index'];
        if (isObject(i)) {
            var s = parseInt(i.scheme);
            if (s >= 16) {
                pid = "be-2014";
                switch (s % 4) {
                    case 0: /*blue*/    break;
                    case 1: /*red*/     s = 3;  break;
                    case 2: /*gray*/    s = 5;  break;
                    case 3: /*teal*/    s = 2;  break;
                }
                attrs['colour-index'] = s;
            } else if (s >= 12) {
                switch (s) {
                    case 12:    pid = "blues9";     break;
                    case 13:    pid = "greens9";    break;
                    case 14:    pid = "be14-red";   break;
                    case 15:    pid = "RDPU9";      break;
                }
                attrs['colour-index'] = 0;
            } else {
                pid = "original";
                attrs['colour-index'] = s % original.length;
            }
        } else {
            pid = "original";
            attrs['colour-index'] = i % original.length;
        }
        attrs['colour-palette'] = pid;
    }

    delete attrs['colour-mode'];
}

