<template>
    <section class="beef-tooltip">
        <p>
            <strong v-if="sentiment > 0" class="positive-sentiment">Positive<i class="icon-circle"></i></strong>
            <strong v-else-if="sentiment === 0" class="neutral-sentiment">Neutral<i class="icon-circle"></i></strong>
            <strong v-else-if="sentiment < 0" class="negative-sentiment" >Negative<i class="icon-circle"></i></strong>
            sentiment towards <strong>{{formatBrandName(brand)}}</strong>.
        </p>
    </section>
</template>


<script>

import {formatBrandName} from "@/app/utils/Format";

export default {
    props: {
        mention: {
            type: Object,
            required: true
        }
    },

    computed: {
        sentiment() {
            return this.mention?.sentiment ?? null;
        },
        brand() {
            return this.mention?.brand ?? null
        }
    },

    methods: {
        formatBrandName
    }
}
</script>