/**
 * Select one or more social networks by typing the name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 *
 * This list should match the MashSocialNetwork enum in Mash.
 */
import { SOCIAL_NETWORKS } from "@/app/utils/SocialNetworks";
import {features} from "@/app/Features";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("SocialNetworkPicker").addInitializer(function(startupOptions) {
    this.items = SOCIAL_NETWORKS;

    this.View = Beef.Picker.View.extend({
        attributes: { class: "social-network-picker" },
        template: require("@/dashboards/filter/pickers/socialnetwork/SocialNetworkPicker.handlebars"),
        items: this.items,
    });


    this.converter = createTagConverter({
        tooltip: function(code) {
            if (code === "UNKNOWN") return "Mentions from an unknown platforms, including all websites";
            return "Mentions from " + SOCIAL_NETWORKS[code];
        },
        items: this.items,
        placeholder: "Platforms",
        cls: function(d) {
            return "tag social-network";
        },
        nameFormatter: function(name) {
            switch (name.toUpperCase()) {
                case "TWITTER":
                case "𝕏 (TWITTER)":
                    return '<span class="tag__icon twitter"><i class="symbol-twitter"></i></span> X (Twitter)';
                case "FACEBOOK":
                    return '<span class="tag__icon facebook"><i class="symbol-facebook"></i></span> Facebook';
                case "YOUTUBE":
                    return '<span class="tag__icon"><i class="symbol-youtube"></i></span> YouTube';
                case "INSTAGRAM":
                    return '<span class="tag__icon"><i class="symbol-instagram"></i></span> Instagram';
                case "LINKEDIN":
                    return '<span class="tag__icon linkedin"><i class="symbol-linkedin"></i></span> LinkedIn';
                case "TIKTOK":
                    return '<span class="tag__icon tiktok"><i class="symbol-tiktok"></i></span> TikTok';
                case "VK":
                    return '<span class="tag__icon vk"><i class="symbol-vk"></i></span> VK';
                case "TUMBLR":
                    return '<span class="tag__icon"><i class="symbol-tumblr"></i></span> Tumblr';
                case "TELEGRAM":
                    return '<span class="tag__icon telegram"><i class="symbol-telegram"></i></span> Telegram';
                case "WHATSAPP":
                    return '<span class="tag__icon whatsapp"><i class="symbol-whatsapp"></i></span> WhatsApp';
                case "HELLOPETER":
                    return '<span class="tag__icon hellopeter"><i class="symbol-hellopeter"></i></span> Hellopeter';
                case "TRUSTPILOT":
                    return '<span class="tag__icon trustpilot"><i class="symbol-trustpilot"></i></span> Trustpilot';
                case "GOOGLE BUSINESS":
                    return '<span class="tag__icon help"><i class="symbol-google-full"></i></span> Google Business';
            }

            if (name.toUpperCase() === "UNKNOWN" || name.toUpperCase().startsWith("WEBSITES")) {
                return '<span class="tag__icon"><i class="symbol-website"></i></span> ' + name;
            }

            return name;
        }
    });

    /**
     * Attach a social network picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };
});
