/**
 * A popup for editing an existing job or creating a new job.
 */

import moment from "moment";
import {isFunction} from "@/app/utils/Util";
import {publishedToLabel} from "@/components/pickers/dates/DateRangePickerUtilities";


Beef.module("EditSearchJob").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
        validation: {
            name: function(field) {
                if (!field || field.trim().length == 0) {
                    return "Name is required";
                }
            },
            customQuery: function(field) {
                console.log("EditSearchJob validation");
                if(!field) return;
                if(typeof field !== 'string') {
                    return "Custom query must be a string";
                }
                if(field.trim().length > 2048) {
                    return "Custom query may not be longer than 2048 characters";
                }
            }
        }
    });

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/historical-search/job/EditSearchJob.handlebars"),

        attributes: { class: "edit-brand dialog" },

        regions: {
            rootBrandFilterRegion: ".root-brand-filter-region"
        },

        events: Object.assign({
            "click .retweet-rule button": "clickRetweetRule"
        }, Beef.SettingsDialog.View.prototype.events),

        createBindings: function() {
            // don't do bindings for our whole element or the sub form for root brand filtering gets picked up
            // as well and the checkboxes don't work as they have bindings to both models
            return Beef.ModelBinder.createDefaultBindings(this.$el.find(".main-form"));
        },

        templateHelpers: function() {
            return {
                editing: typeof this.model.get("job") === "object"
            };
        },

        initialize: function() {
            // the job will not be set when a new job is being created
            var job = this.model.get("job");
            var start;
            var end;
            if (job) {
                // the start and end dates might have been set to an arbitrary time of the day. broccoli retrieves
                // data for a full day, so the dates must be rounded when shown in the published picker.
                start = job.searchRangeStartDate;
                end = job.searchRangeEndDate;
                var clamped = Beef.SearchJobFilter.roundDates(start, end);
                this.model.set({
                    mentionFilter: job.searchFilter,
                    published: Beef.EditSearchJob.dateToPublished(clamped.start, clamped.end),
                    searchRangeStartDate: start,
                    searchRangeEndDate: end,
                    name: job.name,
                    customQuery: job.customQuery,
                    feeds: null,
                    retweetRule: job.retweetRule || "TWEETS_AND_RETWEETS"
                });
            } else {
                start = moment().utc().subtract(1, "month").subtract(1, "day").startOf("day").toDate();
                end = moment().utc().subtract(1, "day").endOf("day").toDate();
                var published = Beef.EditSearchJob.dateToPublished(start, end);
                this.model.set({
                    published: published,
                    searchRangeStartDate: start,
                    searchRangeEndDate: end,
                    customQuery: null,
                    retweetRule: "TWEETS_AND_RETWEETS"
                });
            }
            Beef.SettingsDialog.View.prototype.initialize.apply(this, arguments);
        },

        onRender: function() {
            Beef.SettingsDialog.View.prototype.onRender.apply(this, arguments);
            if (!this.rootBrandFilterRegion.currentView) {
                var attrs = this.model.attributes;
                var view = Beef.SearchJobFilter.createView(attrs.mentionFilter, attrs.published, attrs.customQuery);
                var that = this;
                view.model.on("change", function() {
                    that.model.set({
                        mentionFilter: view.getFilter()
                    });
                });
                view.model.on("change:published", function() {
                    var range = view.getDateRange();
                    var published = Beef.EditSearchJob.dateToPublished(range.start, range.end);
                    that.model.set({
                        published: published,
                        searchRangeStartDate: range.start,
                        searchRangeEndDate: range.end
                    }, {silent: true});
                    // the picker cannot be re-rendered since it inherits from Beef.BoundItemView.
                    // the label must be manually updated
                    var label = publishedToLabel(published);
                    var picker = view.$el.find(".published");
                    picker.data("value", label);
                    picker.html(label);
                    view.popup.contents.currentView.model.set("selection", published);
                    view.popup.contents.currentView.updateView();
                    that.rootBrandFilterRegion.currentView.model.set({published: published}, {silent: true});
                });
                view.model.on("change:customQuery", function() {
                    that.model.set("customQuery", view.getCustomQuery());
                });
                this.rootBrandFilterRegion.show(view);
            }
        },

        ok: function() {
            if (this.isValid()) {
                var attrs = this.prepareForSave(this.model.getAttrs(this.resolveEditAttributes()));
                attrs.searchFilter = attrs.mentionFilter;
                this.originalModel.set(attrs);
                this.close(true);
                this.trigger("ok", attrs);
            }
        },

        delete: function() {
            this.originalModel.destroy();
            this.close(true);
            if (isFunction(this.options.onDelete)) {
                this.options.onDelete();
            }
        },

        clickRetweetRule: function(ev) {
            var button = $(ev.target);
            var rule = button.attr("data-retweet-rule");
            if (rule) {
                this.model.set("retweetRule", rule);
            }
            var buttons = $(".retweet-rule button");
            buttons.toggleClass("active", false);
            button.toggleClass("active", true);
        }
    });

    /**
     * Converts a date range into a published filter in UTC.
     * @return {String} A published filter, or null if either of the start and end dates are falsey.
     */
    this.dateToPublished = function(start, end) {
        var format = function(d) {
            return moment(d).utc().startOf("minute").format("YYYY/MM/DD HH:mm");
        };
        return (!start || ! end) ? null : format(start) + "-" + format(end);
    };
});
