/* Provides a way to show a loading message while loading other components. */
<template>
    <div class="error-message deq-reset short-animated fadeIn">
        <div class="error-message-icon">
            <i class="symbol-warning"></i>
        </div>
        <div>
            <slot>
                <h1>There has been an error</h1>
                <p>Please try again. If the problem continues, <a href="mailto:support@dataeq.com">please contact our support team</a>.</p>
                <slot name="extra"></slot>
            </slot>
        </div>
    </div>
</template>

<script>

export default {
    props: {
    }
}
</script>

<style scoped lang="sass">
.error-message
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    min-height: 100%

    h1
        margin: 0
        padding: 0

.error-message-icon
    font-size: 2rem
    padding-right: 10px

</style>