import {account} from "@/app/utils/Account";
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";

/**
 * Select one or more gender options. Fires a change event when the selection is changed. Also provides a model binder
 * converter to display the selected options in an element.
 */
Beef.module("TopicViewPicker").addInitializer(function(startupOptions) {

    const thisModule = this


    var idToName = undefined;
    this.items = function() {
        if(idToName === undefined) {
            idToName = {};
            account().topicViews?.forEach(function (v) {
                idToName[v.id] = {id: v.id, name: v.name, tree: false};
            });
            account().topicTrees?.forEach(function (v) {
                idToName[v.id] = {id: v.id, name: v.name, tree: true};
            });
        }

        return idToName;
    };

    this.View = Beef.Picker.View.extend({
        attributes: { class: "topicview-picker" },
        template: require("@/dashboards/filter/pickers/topicview/TopicViewPicker.handlebars"),
        items: this.items()
    });

    var itemLookup = function (code) {
        if(idToName === undefined) {
            idToName = Beef.TopicViewPicker.items();
        }
        var item = idToName[+code];
        return item ? item : code;
    };

    this.converter = createTagConverter({
        items: itemLookup,
        getName: function(v) {
            return v ? v.name : '';
        },
        placeholder: "Select a Topic View"
    });

    this.createConverterFactory = function(placeholder) {
        return function(view) {
            var conv;
            return function(direction, value) {
                value = value? value.toString() : null;
                if (value && value.length > 0) {
                    var binding = this;
                    if (conv) {
                        return conv.call(binding, direction, value);
                    }
                    conv = createTagConverter({
                        items: itemLookup,
                        getName: function(v) {
                            return v ? v.name : '';
                        },
                        placeholder: placeholder
                    });
                    return conv.call(binding, direction, value);
                } else {
                    if (placeholder) $(this.boundEls[0]).html(toPlaceholderHTML(placeholder));
                    else $(this.boundEls[0]).text("");
                }
                return value;
            }
        };
    };

    this.converterFactory = this.createConverterFactory(null);


    /**
     * Attach a picker for topic trees and views. By default shows topics and trees.
     * Give the option "onlyTrees: true" if you want to show only topic trees, and not
     * views.
     */
    this.attach = function(view, selector, attribute, options) {
        options = options || {};

        options["topicViews"] = this.items();
        if (options.onlyTrees) {
            var views = options["topicViews"];
            options["topicViews"] = {};
            Object.keys(views).forEach(function(key) {
                if (views[key].tree) options["topicViews"][key] = views[key];
            })
        }
        
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
