import {MentionQAst} from '@/mentionq/mentionq';
import {flattenFilterNodes, parseFilterString} from "@/dashboards/filter/FilterParser";
import {convertExpToAttrs as importedConvertExpToAttrs} from "@/dashboards/filter/BasicFilter";
import {LANGUAGES} from "@/app/utils/Language";
import {removeQuotes, splitQuotedString} from "@/app/utils/StringUtils";
import Vue from "vue";
import AuthorBioPicker from "@/components/pickers/BackboneStyleAuthorBioPicker/BackboneStyleAuthorBioPicker.vue";
import BackboneStyleSamplePicker from "@/components/pickers/BackboneStyleSamplePicker/BackboneStyleSamplePicker.vue";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {PhraseFilter} from "@/app/utils/Phrases";

/**
 * Editor for the simple mention filters allowed at root brand level.
 */
Beef.module("RootBrandFilter").addInitializer(function(startupOptions) {

    this.createView = function(filter, hideBrandLevel = true) {
        var v = new View({model: new Backbone.Model()});
        v.setFilter(filter);
        v.hideBrandLevel = hideBrandLevel;
        return v;
    };

    var View = Beef.BoundItemView.extend({
        attributes: { class: "root-brand-filter row-fluid" },

        template: require("@/setup/brands/RootBrandFilter.handlebars"),

        templateHelpers: function() {
            return {
                detailOpen: !!this.model.get("authorLocation")?.length || !!this.model.get("sampleRate") || !!this.model.get("dissallowReshares"),
                hideReshare: this.hideBrandLevel
            }
        },

        bindings: function() {
            // noinspection JSUnresolvedReference
            return {
                location: { converter: Beef.LocationPicker.converter, elAttribute: "data-value"},
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            // noinspection JSUnresolvedReference
            Beef.LocationPicker.attach(this, ".location", "location", {onlyCountriesAndGroups: true});
            // noinspection JSUnresolvedReference
            Beef.LanguagePicker.attach(this, ".language", "language");

            //<editor-fold desc="Attaching a vue component to show authorLocation">
            let authorLocation = this.model.get('authorLocation') ?? [];
            const LocationClass = Vue.extend(AuthorBioPicker);
            let instance = new LocationClass({propsData: {value: authorLocation}});
            let dom = this.$(".author-location")[0];
            instance.$mount(dom);
            instance.$on("update", location => {
                this.model.set("authorLocation", [...location]);
                this.model.trigger("change");
            });
            //</editor-fold>

            //<editor-fold desc="Attaching a vue component for sample rate">
            if (!this.hideBrandLevel) {
                let sampleRate = this.model.get('sampleRate') ?? null;
                const SampleClass = Vue.extend(BackboneStyleSamplePicker);
                instance = new SampleClass({propsData: {value: sampleRate}});
                dom = this.$(".twitter-sample")[0];
                instance.$mount(dom);
                instance.$on("update", rate => {
                    this.model.set("sampleRate", rate);
                    this.model.trigger("change");
                })
            }
            //</editor-fold>



        },

        events: {
        },

        modelEvents: {
        },

        onClose: function() {
            if (this.popup) this.popup.close();
        },

        setFilter: function(filter) {
            this.model.set(PhraseFilter.parse(filter));
        },

        getFilter: function() {
            return PhraseFilter.from(this.model.attributes).toString();
        }
    });

    /**
     * Convert the feeds to which conversation filters are applied at root brand level into English. Example output
     *     could be 'For Twitter, news sites & blogs' or 'For all data'.
     * @returns {string} A natural language description of the filter, or an empty string if the filter is empty or
     *     invalid.
     */
    this.conversationFeedFilterToEnglish = function(filter) {
        try {
            var root = parseFilterString(filter);
            if (!root) return "";

            root = flattenFilterNodes(root);

            var attrs = convertExpToAttrs(root);
            if (attrs.errors) return filter;

            var ans = "", feeds = [];
            if (attrs.location) {
                if (attrs.twitter) feeds.push("Twitter");
                if (attrs.moreover || attrs.webhose) feeds.push("news sites & blogs");
                if (feeds.length > 0) ans = "For " + feeds.join(", ");
            }
            if (ans.length < 1) ans = "For all data";
            return ans;
        } catch (e) {
            console.error(e);
            return "";
        }

    };

    /**
     * Convert the conversation filters (language or location) allowed at root brand level into English. Example
     *     output could be 'Only track data from Indonesia, Malaysia or Singapore in English, Javanese or Spanish'.
     * @returns {string} A natural language description of the filter, or an empty string if the filter is empty or
     *     invalid.
     */
    this.conversationFilterToEnglish = function(filter) {
        let root = null;
        try {
            root = parseFilterString(filter);
        } catch (e) {
            console.error(e);
        }

        if (!root) return "";

        root = flattenFilterNodes(root);

        var attrs = convertExpToAttrs(root);
        if (attrs.errors) return filter;

        var ans = (attrs.language || attrs.location) ? "Only track data" : "";

        if (attrs.location) {
            ans += " from ";
            var locations = splitQuotedString(attrs.location),
                locationSubset = (locations.length > 1) ? locations.slice(0, -1) : locations;

            ans += locationSubset
                    .map(function(d) {
                        // noinspection JSUnresolvedReference
                        return Beef.LocationPicker.getLocationName(removeQuotes(d, "'"));
                    })
                    .join(", ");

            if (locations.length > 1) {
                var lastLocation = locations[locations.length - 1];
                // noinspection JSUnresolvedReference
                ans += " or " + Beef.LocationPicker.getLocationName(removeQuotes(lastLocation, "'"));
            }
        }

        if (attrs.language) {
            ans += " in ";
            var languages = attrs.language.split(" "),
                languagesSubset = (languages.length > 1) ? languages.slice(0, -1) : languages;

            ans += languagesSubset
                    .map(function(d) { return LANGUAGES[d]; })
                    .join(", ");

            if (languages.length > 1) {
                var lastLanguage = languages[languages.length - 1];
                ans += " or " + LANGUAGES[lastLanguage];
            }
        }
        return ans;
    };

    /**
     * @deprecated
     */
    var convertExpToAttrs = function(root) {
        var attrs = {};
        if (root) {
            root = flattenFilterNodes(root);
            visit(root, attrs);
            if (attrs.moreover) attrs.webhose = true;
            Object.assign(attrs, importedConvertExpToAttrs(root, {noPublished: true}));
        }

        if (attrs.authorLocation) {
            attrs.authorLocation = splitQuotedString(attrs.authorLocation)
                .map(d => removeQuotes(d));
        }

        attrs.dissallowReshares = attrs.reshareOf === "unknown";

        if (!attrs.location && !attrs.authorLocation && !attrs.dissallowReshares && !attrs.sampleRate) {
            attrs.twitter = true;
            attrs.webhose = true;
        }

        if (attrs.errors) {
            notifyUserOfError("Brand or phrase filters has errors. See javascript console for details.")
            console.error("Brand or phrase filter has errors: ", attrs.errors);
            throw new Error("mentionFilter has errors: " + attrs.errors);
        }

        return attrs;
    };

    /**
     * @deprecated
     */
    var visit = function(node, attrs) {
        if (node.operandType === MentionQAst.FEED && node.operationType === MentionQAst.ISNT) {
            attrs[node.literal] = true;
            node.children = [];
        } else if (node.operandType === MentionQAst.SITE && node.operationType === MentionQAst.ISNT
                && node.literal === 'twitter.com') {
            attrs.twitter = true;
            node.children = [];
        }  else if (node.operandType === MentionQAst.SAMPLE) {
            attrs.sampleRate = node.literal.proportion * 100;
            node.children = [];
        } else if (node.children) {
            var keep = [];
            for (var i = 0; i < node.children.length; i++) {
                var c = node.children[i];
                visit(c, attrs);
                if (!c.children) keep.push(c);
                else if (c.children.length == 1) keep.push(c.children[0]);
                else if (c.children.length > 0) keep.push(c);
            }
            if (keep.length < node.children.length) node.children = keep;
        }
    }
});