<template>
    <div class="deq-reset">
        <div>
            <h1>Accounts</h1>
            <div class="admin__actions">
                <div>
                    <be-button v-if="!showProfileProblems" link @click="showProfileProblems = true">Show profile problems</be-button>
                    <slotted-tag v-else @close="showProfileProblems = false">Showing profile problems</slotted-tag>
                </div>
                <div>
                    <ul>
                        <li><a href="/api/logging/browsers" target="_blank">Browsers CSV</a></li>
                        <li><a href="/api/logging/accounts" target="_blank">Accounts CSV</a></li>
                        <li><a href="/api/logging/users" target="_blank">Users CSV</a></li>
                        <li><a href="/api/logging/accounts/users" target="_blank">Accounts & Users CSV</a></li>
                        <li><a href="/api/logging/accounts/dashboards" target="_blank">Dashboards CSV</a></li>
                        <li><a href="/api/logging/accounts/dashboards/users" target="_blank">Users & Dashes CSV</a></li>
                        <li><a href="/api/logging/accounts/pages/users" target="_blank">Pages CSV</a></li>
                        <li><a href="/api/logging/accounts/campaigns" target="_blank">Campaigns CSV</a></li>
                        <li><a href="/api/logging/accounts/campaigns/users" target="_blank">Campaigns & Users CSV</a></li>
                    </ul>

                </div>
            </div>

            <div class="account-grid" :data-expired="showProfileProblems">

                <div v-for="account in accounts"
                     class="admin__account"
                     :data-has-expired="account.hasProfileProblems"
                     :data-profiles-loading="account.loadingProfiles"
                     :key="account.account.code">
                    <div>
                        <header>
                            <h2>
                                {{ account.account.code }}
                            </h2>
                            <div>
                                {{account.account.name}}
                            </div>
                        </header>
                        <a :href="`/${account.account.code}`" target="_blank">
                            Open
                        </a>
                    </div>

                    <div class="admin__account-body">
                        <inline-loading-message v-if="account.loadingProfiles">Checking profiles</inline-loading-message>
                        <div v-if="account.profiles && account.profiles.length" class="admin__account-profile-stats">
                            <div>
                                <deq-number :number="account.profiles.length"/> profiles
                            </div>
                            <div v-if="account.profilesExpiredCount > 0">
                                <i class="symbol-warning" v-if="account.profilesExpiredCount > 0"></i>
                                <deq-number :number="account.profilesExpiredCount"/> expired
                            </div>
                            <div v-if="account.profilesUnauthedLinkedIn > 0">
                                <i class="symbol-warning"></i>
                                <deq-number :number="account.profilesUnauthedLinkedIn"/> unauthorised linkedin
                            </div>
                            <div v-if="account.profilesTwitterNoDm > 0">
                                <i class="symbol-warning"></i>
                                <deq-number :number="account.profilesTwitterNoDm"/> uuthorised twitter missing DMs
                            </div>
                            <div v-if="account.profilesExpiringSoon > 0">
                                <i class="symbol-warning" v-if="account.profilesExpiringSoon > 0"></i>
                                <deq-number :number="account.profilesExpiringSoon"/> expiring soon
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>

import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex/dist/vuex.esm.browser";
import {mash} from "@/store/Services";
import InlineLoadingMessage from "@/components/InlineLoadingMessage";
import DeqNumber from "@/components/formatters/DeqNumber";
import {Profile} from "@/app/utils/types";
import {hasExpiredOrExpiresSoon, isProfileExpired} from "@/app/popup/token-expire/TokenUtilities";
import BeButton from "@/components/buttons/BeButton";
import SlottedTag from "@/components/tags/SlottedTag";

if (module.hot) window.beWidgetReloaded('AdminPanel');

export default {
    components: {DeqNumber, SlottedTag, BeButton, InlineLoadingMessage},
    store: VuexStore,

    data() {
        return {
            accounts: [],

            showProfileProblems: false
        }
    },

    computed: {
        ...mapState(['user']),
    },

    watch: {
        'user.accounts'() {
            this.updateAccounts();
        }
    },

    mounted() {
        this.updateAccounts();
    },

    methods: {
        async updateAccounts() {
            if (!this.user?.accounts?.length) return;

            const accounts = this.accounts = this.user.accounts
                .filter(a => !a.inactive)
                .map(account => ({account, loadingProfiles: true}));

            const profileParent = new Profile();
            for (const ac of accounts) {
                const {account} = ac;
                const response = await mash.get(`/rest/accounts/${account.code}/online-profiles`);
                ac.profiles = response.data;
                ac.loadingProfiles = false;
                ac.profilesExpiredCount = 0;
                ac.profilesUnauthedLinkedIn = 0;
                ac.profilesTwitterNoDm = 0;
                ac.profilesExpiringSoon = 0;
                ac.profiles.forEach(p => {
                    p.__proto__ = profileParent;
                    if (isProfileExpired(p)) ac.profilesExpiredCount++;
                    if (!isProfileExpired(p) && hasExpiredOrExpiresSoon(p)) ac.profilesExpiringSoon++;
                    if (p.type === "LINKEDIN_COMPANY" && !p.authorized) ac.profilesUnauthedLinkedIn++;
                    if (p.type === "TWITTER_SCREEN_NAME" && p.authorized && !p.directMessagesEnabled) ac.profilesTwitterNoDm++;
                });
                ac.hasProfileProblems = ac.profilesUnauthedLinkedIn + ac.profilesExpiredCount + ac.profilesTwitterNoDm + ac.profilesExpiringSoon > 0;
            }
        }
    }
}

</script>

<style scoped lang="sass">

.admin__account
    padding-block: 10px
    padding-inline: 20px
    border-bottom: thin solid black
    border-inline: thin solid black
    &:first-of-type
        border-top: thin solid black

    header
        display: flex
        align-items: center
        gap: 10px
        h2
            margin: 0
            padding: 0

    .admin__account-body
        margin-top: 5px
        padding-left: 20px

.account-grid
    width: clamp(200px, 1000px, 100vw - 150px)
    margin-inline: auto

    display: grid
    grid-template-columns: 1fr
    grid-auto-rows: auto

.account-grid[data-expired]
    > :not([data-has-expired]):not([data-profiles-loading])
        display: none

.admin__account-profile-stats
    display: flex
    gap: 10px
    > *
        min-width: 100px


.admin__actions
    display: grid
    grid-template-columns: auto 1fr
    grid-column-gap: 100px

    li
        display: inline-block

    li + li:before
        content: '•'
        padding-inline: 10px




</style>