<template>
     <dialog-box class="edit-account-settings" modal title="Edit account settings" @close="close" width="500px">
         <div class="edit-account-settings__settings">
             <h4>Account Name</h4>
             <inline-text-input class="input" v-model="accountName" :disabled="saving" signal-for-all text-required hide-controls></inline-text-input>

             <h4>Timezone</h4>
             <timezone-input class="input" v-model="timezone" :disabled="saving" required/>

             <h4>Location</h4>
             <location-input only-one class="input" v-model="location" :disabled="saving"/>

             <h4>Currency</h4>
             <currency-input class="input" v-model="currency" :disabled="saving" required/>

             <h4>Font</h4>
             <font-input class="input" v-model="font" :disabled="saving"/>

             <h4>Default Brand</h4>
             <div style="display: flex; align-items: center">
                 <slotted-tag no-close v-if="defaultBrand">{{ defaultBrandName }}</slotted-tag>
                 <span v-else>Not set</span>

                 <be-button link :disabled="saving" @click="openBrandPicker"><i class="symbol-edit"></i>Change</be-button>
             </div>
         </div>


         <template #buttons>
             <div class="edit-account-settings__btn-bar">
                 <be-button tooltip="Cancel account settings"
                            link
                            :disabled="saving"
                            keyword="esc"
                            @click="$emit('close')">Cancel</be-button>
                 <be-button tooltip="Save these settings"
                            :disabled="saveBtnDisabled"
                            primary @click="save">
                     <spinner-component v-if="saving" :size="12"></spinner-component>
                     {{ saving ? 'Saving...' : 'Save' }}
                 </be-button>
             </div>
         </template>
     </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import TimezoneInput from "@/components/inputs/TimezoneInput";
import CurrencyInput from "@/components/inputs/CurrencyInput";
import SlottedTag from "@/components/tags/SlottedTag";
import {mapActions, mapGetters, mapState} from "vuex";
import BeButton from "@/components/buttons/BeButton";
import VuexStore from "@/store/vuex/VuexStore";
import FontInput from "@/components/inputs/FontInput";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import LocationInput from "@/components/inputs/LocationInput";
import {mash} from "@/store/Services";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent";

export default {
    name: "EditAccountSettingsDialog",
    components: {
        SpinnerComponent,
        LocationInput,
        FontInput,
        BeButton, SlottedTag, CurrencyInput, TimezoneInput, InlineTextInput, DialogBox},
    store: VuexStore,

    data() {
        return {
            accountName: "",
            timezone: "",
            location: [],
            currency: "",
            font: "",
            defaultBrand: "",
            saving: false
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapGetters(['idToBrand']),

        defaultBrandName() {
            return this.defaultBrand ? this.defaultBrand?.shortName || this.defaultBrand?.name || this.defaultBrand.name : "";
        },

        saveBtnDisabled() {
            let noChanges =
                this.accountName === this.account.name
                && this.timezone === this.account.timezone
                && this.location.at(0)?.id === this.account.country
                && this.currency === this.account.currency
                && this.font === this.account.font
                && this.defaultBrand?.id === this.account.defaultBrand?.id

            let missingRequired = !this.accountName || !this.timezone || !this.currency;

            return noChanges || missingRequired || this.saving;
        }
    },

    created() {
       this.setDefaultBrand(this.account.defaultBrand);

       this.accountName = this.account.name;
       this.timezone = this.account.timezone;
       this.location = [{id: this.account.country}];
       this.currency = this.account.currency;
       this.font = this.account.font;

    },

    methods: {
        ...mapActions(['refreshAccount']),

        openBrandPicker() {
            let selectedIds = this.defaultBrand ? [this.defaultBrand.id] : [];
            let brandPicker = showDialog(BrandPickerDialog, {onlyOne: true, mustHaveOne: false, selectedIds: selectedIds});

            brandPicker.$on('selected', selectedBrand => {
                if (selectedBrand.length > 0) {
                    this.setDefaultBrand(selectedBrand[0]);
                } else {
                    this.defaultBrand = null;
                }

            });
        },

        setDefaultBrand(defaultBrand) {
            if (defaultBrand?.id) {
                this.defaultBrand = this.idToBrand.get(defaultBrand.id);
            }
        },

        async save() {
            try {
                this.saving = true;

                let payload = {
                    name: this.accountName,
                    timezone: this.timezone,
                    font: this.font,
                    defaultBrandId: this.defaultBrand ? this.defaultBrand.id : 0,
                    currency: this.currency,
                    country: this.location.at(0)?.id ?? ""
                }

                await mash.post("/rest/accounts/" + this.account.code, payload);
                await this.refreshAccount(true);

                notifyUser({
                    message: "Account profile updated"
                });

                this.close();
            } catch (e) {
                console.warn("Error updating account profile: ", e);
                showErrorDialog("There was an error updating the account profile details. Please refresh the page and try again or contact DataEQ support.");
            } finally {
                this.saving = false;
            }
        },

        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped lang="sass">

.edit-account-settings

    ::v-deep .dialog-body
        margin: 0
        padding: 0

    ::v-deep .dialog-button-bar
        margin: 0
        padding: 10px
        border-top: var(--border-separation)
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px
        background: var(--background-dialog-buttoncontainer)

    &__settings
        padding: 20px

    &__btn-bar
        display: flex
        flex-direction: row
        justify-content: flex-end

    .input
        width: 80%
        margin-bottom: 20px

</style>