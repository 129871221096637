import { profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef } from "@/store/Services";
import axios from 'axios';
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let managedGoogleBusinessProfilesPromise = null;
let foundValidGoogleBusinessToken = false;
let initializedGoogleBusinessToken = false;
let initializedGoogleBusinessProfiles = false;

export default {
    namespaced: true,

    state: {
        googleBusinessLoginStatus: {
            loggedIn: false, // is the user currently logged in through GoogleBusiness?
            loginInvalidated: false // has the user's GoogleBusiness login been invalidated?
        },
        googleBusinessLoggedInAs: null,

        managedGoogleBusinessProfiles: []
    },

    mutations: {
        setGoogleBusinessLoginStatus: (state, value) => state.googleBusinessLoginStatus = value,
        setGoogleBusinessLoggedInAs: (state, value) => state.googleBusinessLoggedInAs = value,
        setManagedGoogleBusinessProfiles: (state, value) => state.managedGoogleBusinessProfiles = value
    },

    actions: {
        async initGoogleBusinessUserToken({commit, dispatch}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedGoogleBusinessToken) return;

            await dispatch('checkGoogleBusinessTokenValidity');

            // set token to null if no valid tokens are found
            if (foundValidGoogleBusinessToken) {
                initializedGoogleBusinessToken = true;
                foundValidGoogleBusinessToken = false;
            }
        },

        async initializeManagedGoogleBusinessProfiles({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedGoogleBusinessProfiles) return;
            let response = null;
            if (managedGoogleBusinessProfilesPromise) response = await managedGoogleBusinessProfilesPromise;
            else {
                try{
                    managedGoogleBusinessProfilesPromise = beef.get("/api/online-profile/google-business/list");
                    response = await managedGoogleBusinessProfilesPromise;
                }finally{
                    managedGoogleBusinessProfilesPromise= null;
                }
            }
            if (response.status === 200) {
                initializedGoogleBusinessProfiles = true;
                response.data?.forEach(googleBusiness => {
                    googleBusiness.type = profileTypes.googleBusiness;
                })
                commit('setManagedGoogleBusinessProfiles', response.data);
            }
        },
        async checkGoogleBusinessTokenValidity({commit}) {
            try {
                let response = await  axios.get(`/api/online-profile/google-business/check-token`);

                foundValidGoogleBusinessToken = true;

                commit('setGoogleBusinessLoginStatus', {loggedIn: true, loginInvalidated: false});

                let name = `${response.data?.fullName}`;
                commit('setGoogleBusinessLoggedInAs', {name: name, id: response.data?.id});
            } catch (e) {
                console.error("Google Business token invalid",e);
                if (e.response.status === 401) {
                    commit('setGoogleBusinessLoginStatus', {loggedIn: false, loginInvalidated: true});
                } else {
                    commit('setGoogleBusinessLoginStatus', {loggedIn: false, loginInvalidated: false});
                }
            }
        },
    }
};
