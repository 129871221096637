<template>
    <div class="progress-bar">
        <div class="progress-bar__stages">
            <div v-for="stage in stages" :key="stage" :style="`width: ${100/totalStages}%`" class="progress-bar__stage" :class="stage <= curStage ? 'stage-next' : 'stage-prev'" style="height: 2px"/>
        </div>
    </div>

</template>

<script>

export default {
    name: "ProgressBar",

    props: {
        totalStages: {
            type: Number,
            required: true
        },
        curStage: {
            type: Number,
            required: true
        }
    },

    data: function() {
        return {
            stages: []
        }
    },

    created() {
        for (let i = 1; i <= this.totalStages; i++) {
            this.stages.push(i);
        }
    }

}
</script>

<style scoped lang="sass">

.progress-bar

    &__stages
        width: 100%
        display: flex

    &__stage
        background: -webkit-gradient(linear, left top, right top, color-stop(50%, #666), color-stop(50%, #aee15d))
        background-size: 200% 100%
        transition: background-position .25s ease

.stage-next
    background-position: -100% 0

.stage-prev
    background-position: 0 -100%

</style>