/**
 * Defines how a chart is displayed. This will likely work as
 * you immediately need by merely implementing the chart and x fields.
 *
 * <p>
 * The model passed to this needs to specify the chart type to use, as the 'chart-type' model
 * item.
 *
 * <p>
 * This model may fire particular events when the chart is interacted with:
 *
 * <ul>
 *     <li>click:bar — fired when a bar has been clicked. Passed data concerning the bar. Only fired for left clicks. </li>
 *     <li>middle-click:bar — fired when a bar has been middle clicked. </li>
 *     <li>right-click:bar — fired when a bar has been right clicked. </li>
 * </ul>
 */

import * as b3js from 'brandseyejs'
import {getAccountCurrency} from "@/app/utils/Currency";
import {getPalette} from "@/app/utils/Colours";
import {formatDefaultCurrency, formatMoney, formatNumber, formatPercentage, toSi} from "@/app/utils/Format";
import _ from 'underscore';
import moment from "moment";
import {isString, restrictToLength} from "@/app/utils/StringUtils";
import {isFunction, isObject} from "@/app/utils/Util";
import {getOther, isOther} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
import {errorHelper} from "@/dashboards/DashboardUtils";


Beef.ChartView = Beef.ChartViewHelper.extend({

    modelEvents: Object.assign({
        "change:chart-type": "chartTypeChanged"
    }, Beef.ChartViewHelper.prototype.modelEvents),

    initialize: function() {
        Beef.ChartViewHelper.prototype.initialize.call(this);
    },

    renderImpl: function() {
        try {
            if (!this.model.has('chart-type')) return;

            var data = this.model.generalData.get('_data');
            if (!this.chart) this.changeType();

            if (this.preTransform && data) data = this.preTransform(data);

            var y = this.y.bind(this);
            var noMentionsTest = data && _(data).all(function(s) { return s.values.length === 0; });
            var pieChartEmptyTest = data && this.model.get('chart-type') == 'pies' &&
                _(data).all(function(s) {
                    return _(s.values).all(function(d) { return y(d) == 0; })
                });

            // See if we have any data.
            if (noMentionsTest) {
                this.model.generalData.set('_message', this.noDataMessage);
            } else if (this.model.generalData.get('_message') === this.noDataMessage) {
                // Ensure that we blank the no data message.
                this.model.generalData.unset('_message');
            }

            // We're showing a message overlay
            if (this.model.generalData.get('_message')) {
                this.$el.fadeOut();
                this.endOfRender();
                return
            }
            // See if we're a pie chart with nothing to render.
            else if (pieChartEmptyTest) {
                this.$el.fadeOut();
                this.model.generalData.set('_message', 'All the ' + this.tooltipDataType + ' values are zero, and so cannot be shown on a pie chart');
                this.endOfRender();
                return;
            }
            else if (!this.$el.is(':visible')) {
                this.model.generalData.set('_message', null);
                this.$el.fadeIn();
            }

            var language = this.model.get('language') || 'en';
            var labelType = this.model.get('label-type');
            var hideOthers  = this.model.get('hide-others'),
                hideUnknown = this.model.get('hide-unknown'),
                hideLabels  = this.model.get('hide-labels'),
                hideLegend  = this.model.get('hide-legend'),
                maxItems    = this.model.get('max-items'),
                usePercentages = labelType === Beef.Widget.MetricSettings.LabelType.percentage,
                useAve         = labelType === Beef.Widget.MetricSettings.LabelType.ave,
                useOts         = labelType === Beef.Widget.MetricSettings.LabelType.ots,
                useEngagement  = labelType === Beef.Widget.MetricSettings.LabelType.engagement,
                useUniqueAuthors = labelType === Beef.Widget.MetricSettings.LabelType.uniqueAuthors,
                useUniqueSites = labelType === Beef.Widget.MetricSettings.LabelType.uniqueSites,
                useBrandIndex  = labelType === Beef.Widget.MetricSettings.LabelType.brandIndex,
                useXAxisFormatter = !!this.chart.xAxisTickFormat;


            if (labelType === Beef.Widget.MetricSettings.LabelType.credibility) {
                hideOthers = true; // Others does not make sense when showing credibility.
            }

            if ((hideOthers || hideUnknown || maxItems) && !_(data).isUndefined()) {
                data = _(data).map(function(datum) {
                    var data = datum.values;
                    var zeroValues = {count: 0, ave: 0, ots: 0, percentage: 0, credibility: 0};
                    var unknownCount = zeroValues;

                    // If we hide unknown values, we should include them in the 'other' count.
                    if (hideUnknown) {
                        data =   _(data).reject(function(item) {
                            if (!isString(this.x(item))) return false;
                            var lower = this.x(item).toLowerCase();
                            if (lower == 'unknown') unknownCount = Object.assign({}, zeroValues, item); // Ensure that all needed values are initialised.
                            return lower == "unknown";
                        }.bind(this))
                    }

                    if (maxItems && maxItems < data.length) {
                        var takeCount = hideOthers ? maxItems : maxItems - 1;
                        var first = _(data).take(takeCount);

                        if (!hideOthers) {
                            var others = _.chain(data)
                                          .rest(takeCount)
                                          .reduce(function(memo, num) {
                                              return {
                                                  count: memo.count + num.count,
                                                  ave: memo.ave + num.ave,
                                                  ots: memo.ots + num.ots,
                                                  percentage: memo.percentage + num.percentage,
                                                  credibility: memo.credibility + (num.credibility || 0)
                                              };
                                          }, unknownCount).value();

                            var field = this.fieldName;
                            others[field] = getOther(language);
                            data = first.concat([others]);
                        }
                        else {
                            data = first;
                        }
                    }

                    return {
                        key:   datum.key,
                        values: data
                    }
                }.bind(this));
            }

            // For sentiment distributions, we might need to combine some categories together for reduced views.
            if (this.transform) {
                data = this.transform(data);
            }

            // Sort data elements to, for instance, consistently put Other and Unknown to the right
            // of everything else.
            if (data) {
                _(data).each(function(s) {
                    // Chrome does not have a stable sort. Se we need to remember the original sort order
                    // for items.

                    var sortOrder = {};
                    _(s.values).each(function(item, i) {
                        sortOrder[this.x(item)] = i;
                    }.bind(this));

                    s.values.sort(function(lhs, rhs) {
                        var lhsX = this.x(lhs),
                            rhsX = this.x(rhs),
                            lhsUnknown = isString(lhsX) && lhsX.toLowerCase() === 'unknown',
                            lhsOthers = isString(lhsX) &&  isOther(lhsX),
                            rhsUnknown = isString(rhsX) && rhsX.toLowerCase() === 'unknown',
                            rhsOthers = isString(rhsX) && isOther(rhsX);

                        if (lhsOthers) return 1;
                        if (rhsOthers) return -1;
                        if (lhsUnknown) return 1;
                        if (rhsUnknown) return -1;

                        if (this.sort) return this.y(rhs) - this.y(lhs);
                        return sortOrder[this.x(lhs)] - sortOrder[this.x(rhs)];
                    }.bind(this));
                }.bind(this));

                if (this.model.get('chart-type') === Beef.Widget.MetricSettings.ChartType.pies) {
                    if (!this.pieTemporarilyChanged && data.length > 1) {
                        this.changeType('columns');
                        this.pieTemporarilyChanged = true;
                    }
                    else if (this.pieTemporarilyChanged && data.length <= 1){
                        this.changeType('pies');
                        this.pieTemporarilyChanged = false;
                    }
                }
            }

            var w = this.$el.width();
            var h = this.$el.height();
            var duration = 250;
            // We have a problem when animating the resizing of a widget, in that we need the widget to
            // have resized before we can determine the size of the containing chart. Which means that when the chart
            // resizes, it is invariably doing so late (it's animation looks substantially delayed, and the chart
            // may even appear to be hanging 'off' the widget. We fix this by changing the duration of the charts animation
            // to be shorter than the time frame that the widget itself is animating over. During this time we fade the chart
            // out. Below we handle setting the duration appropriately.
            if (data != undefined) {
                if (w != this.chart.width() || h != this.chart.width()) {
                    duration = 250;
                }
            }
            this.chart.width(w).height(h).x(this.x.bind(this)).y(this.y.bind(this));
            this.chart
                .showLabels(!hideLabels)
                .showLegend(!hideLegend);

            this.chart.colours(getPalette(this.model.attributes, this.model.getDashboardModel().attributes));
            this.chart.backgroundColour(Beef.generalData().get('dashboards-is-white') ? '#ffffff' : null);

            // Set extents
            if (isFunction(this.forceY)) this.chart.forceY(this.forceY());
            else this.chart.forceY(this.forceY);

            // Set labels.
            if (useXAxisFormatter && this.chart.xAxisTickFormat) {
                var coarseness = this.model.get("coarseness");
                var fun = this.useXAxisDataFormatter ||
                    (coarseness && ["hourly", "daily", "weekly", "monthly", "yearly"].includes(coarseness) ?
                        this.xAxisDateFormat :
                        this.xAxisTickFormat);
                this.chart.xAxisTickFormat(fun.bind(this));
            }
            if (usePercentages) {
                this.chart.tickFormat(this.percentageTickFormat.bind(this));
                this.chart.labelFormat(this.percentageLabelFormat.bind(this));
                this.chart.labelCompression(this.percentageLabelCompression ? this.percentageLabelCompression.bind(this) : null);
                this.chart.dataAxisLabel(this.percentageDataAxisLabel);
            }
            else if (useAve) {
                this.chart.tickFormat(this.aveTickFormat.bind(this));
                this.chart.labelFormat(this.aveLabelFormat.bind(this));
                this.chart.labelCompression(this.aveLabelCompression ? this.aveLabelCompression.bind(this) : null);
                this.chart.dataAxisLabel(this.aveDataAxisLabel);
            }
            else if (useOts) {
                this.chart.tickFormat(this.otsTickFormat.bind(this));
                this.chart.labelFormat(this.otsLabelFormat.bind(this));
                this.chart.labelCompression(this.otsLabelCompression ? this.otsLabelCompression.bind(this) : null);
                this.chart.dataAxisLabel(this.otsDataAxisLabel);
            }
            else if (useEngagement) {
                this.chart.tickFormat(this.otsTickFormat.bind(this));
                this.chart.labelFormat(this.otsLabelFormat.bind(this));
                this.chart.labelCompression(this.otsLabelCompression ? this.otsLabelCompression.bind(this) : null);
                this.chart.dataAxisLabel(this.engagementDataAxisLabel);
            }
            else if (useUniqueAuthors) {
                this.chart.tickFormat(this.tickFormat.bind(this));
                this.chart.labelFormat(this.labelFormat.bind(this));
                this.chart.labelCompression(this.labelCompression ? this.labelCompression.bind(this) : null);
                this.chart.dataAxisLabel(this.uniqueAuthorsDataAxisLabel);
            }
            else if (useUniqueSites) {
                this.chart.tickFormat(this.tickFormat.bind(this));
                this.chart.labelFormat(this.labelFormat.bind(this));
                this.chart.labelCompression(this.labelCompression ? this.labelCompression.bind(this) : null);
                this.chart.dataAxisLabel(this.uniqueSitesDataAxisLabel);
            }
            else if (useBrandIndex) {
                this.chart.tickFormat(this.tickFormat.bind(this));
                this.chart.labelFormat(this.labelFormat.bind(this));
                this.chart.labelCompression(this.labelCompression ? this.labelCompression.bind(this) : null);
                this.chart.dataAxisLabel(this.brandIndexDataAxisLabel);
            }
            else {
                this.chart.tickFormat(this.tickFormat.bind(this));
                this.chart.labelFormat(this.labelFormat.bind(this));
                this.chart.labelCompression(this.labelCompression ? this.labelCompression.bind(this) : null);
                this.chart.dataAxisLabel(isFunction(this.countDataAxisLabel) ? this.countDataAxisLabel() : this.countDataAxisLabel);
            }

            if (isObject(this.tooltip)) {
                this.chart.tooltip({
                    template: this.tooltip.template,
                    templateHelpers: this.tooltip.templateHelpers,
                    data: _(this.tooltip.data || Beef.Tooltip.chartViewDefaultDataHandler).bind(this)
                })
            }

            this.chart
                .data(data)
                .element(this.$el[0])
                .duration(duration)
                .coarseness(this.model.get('coarseness'))
                .padding(isFunction(this.padding) ? this.padding() : this.padding)
                .xAxisTooltips(this.xAxisTooltips)
                .xAxisOverride(this.xAxisOverride)
                .render();

//        svg.datum(data).transition().duration(100).call(this.chart);
            this.setup();
            this.endOfRender();
            return this;
        } catch (e) {
            errorHelper(this.model, e);
            return this;
        }
    },

    chartTypeChanged: function() {
        this.changeType(this.model.get('chart-type'));
    },

    changeType: function(type) {
        this.$el.empty();
        if (!type) type = this.model.get('chart-type');

        switch(type) {
            case 'rows':
                this.chart = new b3js.BarChart(); break;
            case 'columns':
                this.chart = new b3js.ColumnChart(); break;
            case 'pies':
                this.chart = new b3js.PieChart(); break;
            default:
                return;
        }

        this.setup();
    },

    setup: function() {
        if (this.chart && this.chart.dispatch()) {
            this.chart.dispatch().on('elementClick', function(data) {
                var event = data.e || d3.event;
                switch (event.which) {
                    case 1:
                        if (event.metaKey) this.model.trigger('middle-click:bar', data);
                        else this.model.trigger('click:bar', data);
                        break;
                    case 2:
                        this.model.trigger('middle-click:bar', data);
                        break;
                }
            }.bind(this));

            this.chart.dispatch().on('elementMiddleClick', function(data) {
                this.model.trigger('middle-click:bar', data);
            }.bind(this));

            this.chart.dispatch().on('tooltipShow', function(event) {
                if (isFunction(this.tooltip)) {
                    this.tooltip(event, this.x(event.point));
                    return;
                }

                var tooltipSettings = {
                    template: this.tooltip.template,
                    templateHelpers: this.tooltip.templateHelpers,
                    target: event.e.target,
                    model: this.tooltip.data || Beef.Tooltip.chartViewDefaultDataHandler.bind(this)(this.chart, event)
                };

                if (this.model.get('chart-type') === Beef.Widget.MetricSettings.ChartType.pies) {
                    tooltipSettings.positions = ['top-right', 'top-left'];
                }

                Beef.Tooltip.show(tooltipSettings);
            }.bind(this));

            this.chart.dispatch().on('tooltipHide', function() {
                Beef.Tooltip.close();
            });
        }
    },

    /**
     * The name of the field that is being edited. This is filled in automatically
     * from the ChartItemView.
     */
    fieldName: null,

    /**
     * The message to show when there is no data. This is automatically filled in from
     * ChartItemView
     */
    noDataMessage: null,

    /**
     * Indicates whether the ChartItemView has been closed or not. This is automatically filled in
     * from ChartItemView.
     */
    isClosed: false,

    /*
     * The chart to be drawn.
     */
    chart: null,

    /*
     * A function that will return the x axis data point from a data item.
     */
    x: null,

    /*
     * A function to return the y axis data point from the data item.
     */
    y: function(d) {
        var type = this.model.get('label-type');
        switch (type) {
            case Beef.Widget.MetricSettings.LabelType.percentage:
                return d.percentage || 0;
            case Beef.Widget.MetricSettings.LabelType.ots:
                return d.ots || 0;
            case Beef.Widget.MetricSettings.LabelType.engagement:
                return d.engagement || 0;
            case Beef.Widget.MetricSettings.LabelType.ave:
                return d.ave || 0;
            case Beef.Widget.MetricSettings.LabelType.count:
                return d.count || 0;
            case Beef.Widget.MetricSettings.LabelType.credibility:
                return Number.isFinite(d.credibility) ? d.credibility : 0;
            case Beef.Widget.MetricSettings.LabelType.uniqueAuthors:
                return d.authorNames || 0;
            case Beef.Widget.MetricSettings.LabelType.uniqueSites:
                return d.sites || 0;
            case Beef.Widget.MetricSettings.LabelType.brandIndex:
                return d.brandIndex || 0;
            default:
                console.warn("WARNING: unrecognised data type [" + type + "]");
                return d.count || 0;
        }
    },

    /*
     * Set this to false if the data set should not be sorted.
     */
    sort: true,

    /**
     * We sometimes need to make transformations on the data before we push it to the view. An example of this
     * is when we merge positive sentiment into a single bucket, or negative sentiment into a single bucket.
     */
    transform: null,

    /**
     * Similar to #transform, but happens earlier in the display cycle.
     */
    preTransform: null,

    /*
     * If you would like to not use the default forcing, use the value you would like to use here instead.
     * This value, if a single number, gives the maximum Y value. If an array, gives [min, max].
     * This default implementation only gives a maximum value for percentages.
     */
    forceY: function() {
        var max = -Infinity;
        var data = this.model.generalData.get('_data');
        var usePercentages = this.model.get('label-type') === Beef.Widget.MetricSettings.LabelType.percentage;

        _(data).each(function(s) {
            _(s.values).each(function(d) {
                if (this.y(d) > max) max = this.y(d);
            }.bind(this));
        }.bind(this));
        if (usePercentages) {
            return max != -Infinity ? Math.min(Math.floor((max + 10) / 10) * 10, 100) : null;
        }
        return null;
    },

    countDataAxisLabel:         {long: "Total Mentions", short: "Mentions"},
    percentageDataAxisLabel:    {long: "Percentage of Total Mentions", short: "% of Mentions"},
    otsDataAxisLabel:           {long: "Opportunities-To-See", short: "OTS"},
    engagementDataAxisLabel:    {long: "Engagement", short: "Engagement"},
    aveDataAxisLabel:           {long: "Advert Value Equivalent", short: "AVE"},
    uniqueAuthorsDataAxisLabel: {long: "Unique Authors", short: "Authors"},
    uniqueSitesDataAxisLabel:   {long: "Unique Sites", short: "Sites"},
    brandIndexDataAxisLabel:    {long: "Brand Index", short: "Index"},

    //------------------------------------

    /*
     * Provide a map of extra margin widths to add to the widths determined
     * by the charting library. This may also be a function that returns such a map.
     */
    padding: {
        left:   0,
        right:  0,
        top:    0,
        bottom: 0
    },

    //------------------------------------

    /*
     * Override with a map going from x-axis values to tooltip values.
     */
    xAxisTooltips: null,
    /*
     * If you would like the x-axis to display labels different from those returned
     * from chicken, then provide a map from the chicken labels to the new ones.
     */
    xAxisOverride: null,

    //------------------------------------
    // Handling tooltips

    tooltipVerb: 'are from',    // Such as 'from' (a country) or 'in' (a language).
    tooltipAveOtsVerb: 'from',  // Such as 'from', 'with'.
    tooltipSubject: 'mentions', // Such as 'mentions', or 'sentiment points'
    chartooltipDataType: '',    // Such as 'credibility' (1).
    tooltipObject: '',          // Such as 'countries', 'languages'.


    /**
     * This should provide an object specifying a template and function for producing tooltips.
     * You can customise this by either completely overriding
     * this method (the Volume charts do that), or by customizing the above tooltip related fields, which most of the
     * other metrics do to varying degrees.
     *
     * This can also be a function, rather, and display its own tooltips. See Beef.Tooltip#show.
     */
    tooltip: {
        template: require("@/dashboards/charts/FromTooltip.handlebars"),
        // This function is the default function for setting up data for the default ChartView tooltips.
        // It just returns a model for use by the handlebars template above.
        // When overriding 'tooltip', you can leave 'data' empty and will be given the default handler.
        data: Beef.Tooltip.chartViewDefaultDataHandler
    },

    percentageTickFormat: function(value) {
        return formatPercentage(value);
    },
    percentageLabelFormat: function(value, compression) {
        if (value == 0 && !value.comment) return "0";
        var decimals;
        switch(compression) {
            case 1: decimals = 0; break;
            default: decimals = 1;
        }
        var s = formatPercentage(value, decimals);
        if (value.comment) s += " " + value.comment;
        return s;
    },
    percentageLabelCompression: function(value, rangeband) {
        if (this.model.get('chart-type') !== Beef.Widget.MetricSettings.ChartType.columns) return 0;
        var charToPixelWidth = 8;
        var text = formatPercentage(value, 1);
        if (text.length * charToPixelWidth > rangeband) return 1;
        return 0;
    },

    aveTickFormat: function(value) {
        return formatMoney(value, getAccountCurrency(), {si: true});
    },
    aveLabelFormat: function(value, compression) {
        switch(compression) {
            case 1: return formatDefaultCurrency(value, {si: true});
            case 2: return formatDefaultCurrency(value, {si: true, decimal: false});
            case 3: return formatDefaultCurrency(value, {si: true, decimal: false, showUnit: false});
            default: return formatDefaultCurrency(value);
        }
    },
    aveLabelCompression: function(value, rangeband) {
        if (this.model.get('chart-type') !== Beef.Widget.MetricSettings.ChartType.columns) return 0;
        var charToPixelWidth = 5;
        var text = formatDefaultCurrency(value);
        if (text.length * charToPixelWidth > rangeband * 2.0) return 3;
        if (text.length * charToPixelWidth > rangeband * 1.5) return 2;
        if (text.length * charToPixelWidth > rangeband ) return 1;
        return 0;
    },

    otsTickFormat: function(value) {
        return toSi(value);
    },
    otsLabelFormat: function(value, compression) {
        switch(compression) {
            case 1: return toSi(value);
            case 2: return toSi(value, {decimal: false});
            default: return formatNumber(value);
        }
    },
    otsLabelCompression: function(value, rangeband) {
        if (this.model.get('chart-type') !== Beef.Widget.MetricSettings.ChartType.columns) return 0;
        var charToPixelWidth = 6;
        var text = formatNumber(value);
        if (text.length * charToPixelWidth > rangeband * 1.1) return 2;
        if (text.length * charToPixelWidth > rangeband) return 1;
        return 0;
    },

    xAxisTickFormat: function(text) {
        text = text.toString();
        var restriction = 25;
        if (text.length <= restriction) return text;

        var facebookRegex = /^(\w+)\s+\d+$/;
        var twitterRegex = /^(\w+)\s+\([\w\s]+\)$/;
        var webpage = /^www\.(\w+)\..*$/;

        var match;
        if (match = text.match(facebookRegex)) {
            text = match[1] + '…';
        }
        else if (match = text.match(twitterRegex)) {
            text = match[1] + '…';
        }
        else if (match = text.match(webpage)) {
            text = match[1] + '…';
        }

        return restrictToLength(text, 25);
    },
    xAxisDateFormat: function(d, i) {
        var m = new moment(d);
        var coarseness = this.model.get('coarseness');

        function longFormat (m, i) {
            return i === 0 || (m.date() === 1 && (coarseness !== 'hourly' || (m.hour() == 0 && m.minute() == 0)));
        }

        switch (coarseness) {
            case 'hourly':
                if ((longFormat(m, i) && m.hour() == 0) || i == 0) return m.format("MMM D, HH:mm");
                if (m.day() === 1 && m.hour() == 0) return m.format("ddd DD, HH:mm");
                if (m.hour() == 0) return m.format("ddd D, HH:mm");

                return m.format("HH:mm");
            case 'daily':
                if (longFormat(m, i)) return m.format("MMM DD");
                if (m.day() === 1) return m.format("ddd DD");

                return m.format("DD");
            case 'weekly':
                var next = m.clone().add('days', 6),
                    label = m.format("MMM DD") + '→';
                if (next.months() === m.months()) label += next.format("DD");
                else label += next.format("MMM DD");
                return label;
            case 'monthly':
                if (m.month() == 0) return m.format("MMMM ’YY");
                return m.format("MMMM");
            case 'yearly':
                return m.format("YYYY");
        }
        console.warn("No coarseness set for ", d, "maybe not a date?");
        return this.xAxisTickFormat(d);
    },
    tickFormat: function(value) {
        return toSi(value);
    },
    labelFormat: function(value) {
        var s = formatNumber(value);
        if (value.comment) s += " " + value.comment;
        return s;

    },
    labelCompression: null,


    //------------------------------------

    /**
     * This does nothing except provide an end point to spy on for test.
     */
    endOfRender: function() {
        this.model.generalData.set('_completed', true);
    }
});