import {mash} from "@/store/Services";

let refreshPromise = null;

export default {
    namespaced: true,

    state: () => ({
        users: null
    }),

    mutations: {
        setUsers: (state, value) => state.users = value
    },

    getters: {
        idToUser: (state) => {
            if (!state.users) return new Map();

            const map = new Map();
            for (const user of state.users) {
                map.set(user.id, user);
            }
            return map
        }
    },

    actions: {
        async refreshAccountUsers({state, commit, rootState}, forceRefresh) {
            forceRefresh ??= false;

            if (state.users && !forceRefresh) return;

            if (refreshPromise) {
                await refreshPromise;
                return;
            }

            try {
                refreshPromise = mash.get("/rest/accounts/" + rootState.account.code + "/users");
                const res = await refreshPromise;
                await commit('setUsers', res.data);
            } catch(e) {
                console.error(e);
            } finally {
                refreshPromise = null;
            }
        },

        /** Returns a user object if it exists. The user is looked up via their email address.
         * The address can be in the 'bob@email.com' or 'Bob Smith <bob@email.com>' format.
         */
        getUserByEmail({dispatch, state}, email) {
            let toMatch = email?.trim();
            if (!toMatch) return null;
            if (!state.users?.length) return null;

            if (toMatch.includes('<') && toMatch.includes('>')) {
                toMatch = toMatch.substring(toMatch.indexOf('<') + 1, toMatch.indexOf('>'));
            }

            return state.users.find(u => u.email?.trim().toLowerCase() === toMatch);
        }
    }
}