import { render, staticRenderFns } from "./EditFeed.vue?vue&type=template&id=44995f58&scoped=true"
import script from "./EditFeed.vue?vue&type=script&lang=js"
export * from "./EditFeed.vue?vue&type=script&lang=js"
import style0 from "./EditFeed.vue?vue&type=style&index=0&id=44995f58&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44995f58",
  null
  
)

export default component.exports