import Vue from "vue";
import Vuex from "vuex";
import {beef} from "@/store/Services";

Vue.use(Vuex);


export default {
    namespaced: true,

    state: {
        crowds: null,
    },

    mutations: {
        setCrowds(state, crowds) {
            state.crowds = crowds;
        },
    },

    getters: {

    },

    actions: {
        async refreshCrowds({state, commit}, forceRefresh = false) {
            if (state.crowds !== null && !forceRefresh) return;
            const response = await beef.get("/api/crowds");
            commit("setCrowds", response.data);
        }
    }
};
