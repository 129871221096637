/**
 * Select one or more timezones by typing the code or name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
import {escapeExpression} from "@/app/utils/StringUtils";
import _ from 'underscore';
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("TimezonePicker").addInitializer(function(startupOptions) {

    this.items = {
        "Africa/Abidjan": {code: "Africa/Abidjan", name: "Greenwich Mean Time"},
        "Africa/Accra": {code: "Africa/Accra", name: "Greenwich Mean Time"},
        "Africa/Addis_Ababa": {code: "Africa/Addis_Ababa", name: "East Africa Time"},
        "Africa/Algiers": {code: "Africa/Algiers", name: "Central European Standard Time"},
        "Africa/Asmara": {code: "Africa/Asmara", name: "East Africa Time"},
        "Africa/Asmera": {code: "Africa/Asmera", name: "East Africa Time"},
        "Africa/Bamako": {code: "Africa/Bamako", name: "Greenwich Mean Time"},
        "Africa/Bangui": {code: "Africa/Bangui", name: "West Africa Standard Time"},
        "Africa/Banjul": {code: "Africa/Banjul", name: "Greenwich Mean Time"},
        "Africa/Bissau": {code: "Africa/Bissau", name: "Greenwich Mean Time"},
        "Africa/Blantyre": {code: "Africa/Blantyre", name: "Central Africa Time"},
        "Africa/Brazzaville": {code: "Africa/Brazzaville", name: "West Africa Standard Time"},
        "Africa/Bujumbura": {code: "Africa/Bujumbura", name: "Central Africa Time"},
        "Africa/Cairo": {code: "Africa/Cairo", name: "Eastern European Standard Time"},
        "Africa/Casablanca": {code: "Africa/Casablanca", name: "Western European Standard Time"},
        "Africa/Ceuta": {code: "Africa/Ceuta", name: "Central European Standard Time"},
        "Africa/Conakry": {code: "Africa/Conakry", name: "Greenwich Mean Time"},
        "Africa/Dakar": {code: "Africa/Dakar", name: "Greenwich Mean Time"},
        "Africa/Dar_es_Salaam": {code: "Africa/Dar_es_Salaam", name: "East Africa Time"},
        "Africa/Djibouti": {code: "Africa/Djibouti", name: "East Africa Time"},
        "Africa/Douala": {code: "Africa/Douala", name: "West Africa Standard Time"},
        "Africa/El_Aaiun": {code: "Africa/El_Aaiun", name: "Western European Standard Time"},
        "Africa/Freetown": {code: "Africa/Freetown", name: "Greenwich Mean Time"},
        "Africa/Gaborone": {code: "Africa/Gaborone", name: "Central Africa Time"},
        "Africa/Harare": {code: "Africa/Harare", name: "Central Africa Time"},
        "Africa/Johannesburg": {code: "Africa/Johannesburg", name: "South Africa Standard Time"},
        "Africa/Juba": {code: "Africa/Juba", name: "East Africa Time"},
        "Africa/Kampala": {code: "Africa/Kampala", name: "East Africa Time"},
        "Africa/Khartoum": {code: "Africa/Khartoum", name: "Central Africa Time"},
        "Africa/Kigali": {code: "Africa/Kigali", name: "Central Africa Time"},
        "Africa/Kinshasa": {code: "Africa/Kinshasa", name: "West Africa Standard Time"},
        "Africa/Lagos": {code: "Africa/Lagos", name: "West Africa Standard Time"},
        "Africa/Libreville": {code: "Africa/Libreville", name: "West Africa Standard Time"},
        "Africa/Lome": {code: "Africa/Lome", name: "Greenwich Mean Time"},
        "Africa/Luanda": {code: "Africa/Luanda", name: "West Africa Standard Time"},
        "Africa/Lubumbashi": {code: "Africa/Lubumbashi", name: "Central Africa Time"},
        "Africa/Lusaka": {code: "Africa/Lusaka", name: "Central Africa Time"},
        "Africa/Malabo": {code: "Africa/Malabo", name: "West Africa Standard Time"},
        "Africa/Maputo": {code: "Africa/Maputo", name: "Central Africa Time"},
        "Africa/Maseru": {code: "Africa/Maseru", name: "South Africa Standard Time"},
        "Africa/Mbabane": {code: "Africa/Mbabane", name: "South Africa Standard Time"},
        "Africa/Mogadishu": {code: "Africa/Mogadishu", name: "East Africa Time"},
        "Africa/Monrovia": {code: "Africa/Monrovia", name: "Greenwich Mean Time"},
        "Africa/Nairobi": {code: "Africa/Nairobi", name: "East Africa Time"},
        "Africa/Ndjamena": {code: "Africa/Ndjamena", name: "West Africa Standard Time"},
        "Africa/Niamey": {code: "Africa/Niamey", name: "West Africa Standard Time"},
        "Africa/Nouakchott": {code: "Africa/Nouakchott", name: "Greenwich Mean Time"},
        "Africa/Ouagadougou": {code: "Africa/Ouagadougou", name: "Greenwich Mean Time"},
        "Africa/Porto-Novo": {code: "Africa/Porto-Novo", name: "West Africa Standard Time"},
        "Africa/Sao_Tome": {code: "Africa/Sao_Tome", name: "West Africa Standard Time"},
        "Africa/Timbuktu": {code: "Africa/Timbuktu", name: "Greenwich Mean Time"},
        "Africa/Tripoli": {code: "Africa/Tripoli", name: "Eastern European Standard Time"},
        "Africa/Tunis": {code: "Africa/Tunis", name: "Central European Standard Time"},
        "Africa/Windhoek": {code: "Africa/Windhoek", name: "Central Africa Time"},
        "America/Adak": {code: "America/Adak", name: "Hawaii-Aleutian Standard Time"},
        "America/Anchorage": {code: "America/Anchorage", name: "Alaska Standard Time"},
        "America/Anguilla": {code: "America/Anguilla", name: "Atlantic Standard Time"},
        "America/Antigua": {code: "America/Antigua", name: "Atlantic Standard Time"},
        "America/Araguaina": {code: "America/Araguaina", name: "Brasilia Standard Time"},
        "America/Argentina/Buenos_Aires": {code: "America/Argentina/Buenos_Aires", name: "Argentina Standard Time"},
        "America/Argentina/Catamarca": {code: "America/Argentina/Catamarca", name: "Argentina Standard Time"},
        "America/Argentina/ComodRivadavia": {code: "America/Argentina/ComodRivadavia", name: "Argentina Standard Time"},
        "America/Argentina/Cordoba": {code: "America/Argentina/Cordoba", name: "Argentina Standard Time"},
        "America/Argentina/Jujuy": {code: "America/Argentina/Jujuy", name: "Argentina Standard Time"},
        "America/Argentina/La_Rioja": {code: "America/Argentina/La_Rioja", name: "Argentina Standard Time"},
        "America/Argentina/Mendoza": {code: "America/Argentina/Mendoza", name: "Argentina Standard Time"},
        "America/Argentina/Rio_Gallegos": {code: "America/Argentina/Rio_Gallegos", name: "Argentina Standard Time"},
        "America/Argentina/Salta": {code: "America/Argentina/Salta", name: "Argentina Standard Time"},
        "America/Argentina/San_Juan": {code: "America/Argentina/San_Juan", name: "Argentina Standard Time"},
        "America/Argentina/San_Luis": {code: "America/Argentina/San_Luis", name: "Western Argentina Standard Time"},
        "America/Argentina/Tucuman": {code: "America/Argentina/Tucuman", name: "Argentina Standard Time"},
        "America/Argentina/Ushuaia": {code: "America/Argentina/Ushuaia", name: "Argentina Standard Time"},
        "America/Aruba": {code: "America/Aruba", name: "Atlantic Standard Time"},
        "America/Asuncion": {code: "America/Asuncion", name: "Paraguay Standard Time"},
        "America/Atikokan": {code: "America/Atikokan", name: "Eastern Standard Time"},
        "America/Atka": {code: "America/Atka", name: "Hawaii-Aleutian Standard Time"},
        "America/Bahia": {code: "America/Bahia", name: "Brasilia Standard Time"},
        "America/Bahia_Banderas": {code: "America/Bahia_Banderas", name: "Central Standard Time"},
        "America/Barbados": {code: "America/Barbados", name: "Atlantic Standard Time"},
        "America/Belem": {code: "America/Belem", name: "Brasilia Standard Time"},
        "America/Belize": {code: "America/Belize", name: "Central Standard Time"},
        "America/Blanc-Sablon": {code: "America/Blanc-Sablon", name: "Atlantic Standard Time"},
        "America/Boa_Vista": {code: "America/Boa_Vista", name: "Amazon Standard Time"},
        "America/Bogota": {code: "America/Bogota", name: "Colombia Standard Time"},
        "America/Boise": {code: "America/Boise", name: "Mountain Standard Time"},
        "America/Buenos_Aires": {code: "America/Buenos_Aires", name: "Argentina Standard Time"},
        "America/Cambridge_Bay": {code: "America/Cambridge_Bay", name: "Mountain Standard Time"},
        "America/Campo_Grande": {code: "America/Campo_Grande", name: "Amazon Standard Time"},
        "America/Cancun": {code: "America/Cancun", name: "Eastern Standard Time"},
        "America/Caracas": {code: "America/Caracas", name: "Venezuela Time"},
        "America/Catamarca": {code: "America/Catamarca", name: "Argentina Standard Time"},
        "America/Cayenne": {code: "America/Cayenne", name: "French Guiana Time"},
        "America/Cayman": {code: "America/Cayman", name: "Eastern Standard Time"},
        "America/Chicago": {code: "America/Chicago", name: "Central Standard Time"},
        "America/Chihuahua": {code: "America/Chihuahua", name: "Mexican Pacific Standard Time"},
        "America/Coral_Harbour": {code: "America/Coral_Harbour", name: "Eastern Standard Time"},
        "America/Cordoba": {code: "America/Cordoba", name: "Argentina Standard Time"},
        "America/Costa_Rica": {code: "America/Costa_Rica", name: "Central Standard Time"},
        "America/Creston": {code: "America/Creston", name: "Mountain Standard Time"},
        "America/Cuiaba": {code: "America/Cuiaba", name: "Amazon Standard Time"},
        "America/Curacao": {code: "America/Curacao", name: "Atlantic Standard Time"},
        "America/Danmarkshavn": {code: "America/Danmarkshavn", name: "Greenwich Mean Time"},
        "America/Dawson": {code: "America/Dawson", name: "Pacific Standard Time"},
        "America/Dawson_Creek": {code: "America/Dawson_Creek", name: "Mountain Standard Time"},
        "America/Denver": {code: "America/Denver", name: "Mountain Standard Time"},
        "America/Detroit": {code: "America/Detroit", name: "Eastern Standard Time"},
        "America/Dominica": {code: "America/Dominica", name: "Atlantic Standard Time"},
        "America/Edmonton": {code: "America/Edmonton", name: "Mountain Standard Time"},
        "America/Eirunepe": {code: "America/Eirunepe", name: "Acre Standard Time"},
        "America/El_Salvador": {code: "America/El_Salvador", name: "Central Standard Time"},
        "America/Ensenada": {code: "America/Ensenada", name: "Pacific Standard Time"},
        "America/Fort_Nelson": {code: "America/Fort_Nelson", name: "Mountain Standard Time"},
        "America/Fort_Wayne": {code: "America/Fort_Wayne", name: "Eastern Standard Time"},
        "America/Fortaleza": {code: "America/Fortaleza", name: "Brasilia Standard Time"},
        "America/Glace_Bay": {code: "America/Glace_Bay", name: "Atlantic Standard Time"},
        "America/Godthab": {code: "America/Godthab", name: "West Greenland Standard Time"},
        "America/Goose_Bay": {code: "America/Goose_Bay", name: "Atlantic Standard Time"},
        "America/Grand_Turk": {code: "America/Grand_Turk", name: "Eastern Standard Time"},
        "America/Grenada": {code: "America/Grenada", name: "Atlantic Standard Time"},
        "America/Guadeloupe": {code: "America/Guadeloupe", name: "Atlantic Standard Time"},
        "America/Guatemala": {code: "America/Guatemala", name: "Central Standard Time"},
        "America/Guayaquil": {code: "America/Guayaquil", name: "Ecuador Time"},
        "America/Guyana": {code: "America/Guyana", name: "Guyana Time"},
        "America/Halifax": {code: "America/Halifax", name: "Atlantic Standard Time"},
        "America/Havana": {code: "America/Havana", name: "Cuba Standard Time"},
        "America/Hermosillo": {code: "America/Hermosillo", name: "Mexican Pacific Standard Time"},
        "America/Indiana/Indianapolis": {code: "America/Indiana/Indianapolis", name: "Eastern Standard Time"},
        "America/Indiana/Knox": {code: "America/Indiana/Knox", name: "Central Standard Time"},
        "America/Indiana/Marengo": {code: "America/Indiana/Marengo", name: "Eastern Standard Time"},
        "America/Indiana/Petersburg": {code: "America/Indiana/Petersburg", name: "Eastern Standard Time"},
        "America/Indiana/Tell_City": {code: "America/Indiana/Tell_City", name: "Central Standard Time"},
        "America/Indiana/Vevay": {code: "America/Indiana/Vevay", name: "Eastern Standard Time"},
        "America/Indiana/Vincennes": {code: "America/Indiana/Vincennes", name: "Eastern Standard Time"},
        "America/Indiana/Winamac": {code: "America/Indiana/Winamac", name: "Eastern Standard Time"},
        "America/Indianapolis": {code: "America/Indianapolis", name: "Eastern Standard Time"},
        "America/Inuvik": {code: "America/Inuvik", name: "Mountain Standard Time"},
        "America/Iqaluit": {code: "America/Iqaluit", name: "Eastern Standard Time"},
        "America/Jamaica": {code: "America/Jamaica", name: "Eastern Standard Time"},
        "America/Jujuy": {code: "America/Jujuy", name: "Argentina Standard Time"},
        "America/Juneau": {code: "America/Juneau", name: "Alaska Standard Time"},
        "America/Kentucky/Louisville": {code: "America/Kentucky/Louisville", name: "Eastern Standard Time"},
        "America/Kentucky/Monticello": {code: "America/Kentucky/Monticello", name: "Eastern Standard Time"},
        "America/Knox_IN": {code: "America/Knox_IN", name: "Central Standard Time"},
        "America/Kralendijk": {code: "America/Kralendijk", name: "Atlantic Standard Time"},
        "America/La_Paz": {code: "America/La_Paz", name: "Bolivia Time"},
        "America/Lima": {code: "America/Lima", name: "Peru Standard Time"},
        "America/Los_Angeles": {code: "America/Los_Angeles", name: "Pacific Standard Time"},
        "America/Louisville": {code: "America/Louisville", name: "Eastern Standard Time"},
        "America/Lower_Princes": {code: "America/Lower_Princes", name: "Atlantic Standard Time"},
        "America/Maceio": {code: "America/Maceio", name: "Brasilia Standard Time"},
        "America/Managua": {code: "America/Managua", name: "Central Standard Time"},
        "America/Manaus": {code: "America/Manaus", name: "Amazon Standard Time"},
        "America/Marigot": {code: "America/Marigot", name: "Atlantic Standard Time"},
        "America/Martinique": {code: "America/Martinique", name: "Atlantic Standard Time"},
        "America/Matamoros": {code: "America/Matamoros", name: "Central Standard Time"},
        "America/Mazatlan": {code: "America/Mazatlan", name: "Mexican Pacific Standard Time"},
        "America/Mendoza": {code: "America/Mendoza", name: "Argentina Standard Time"},
        "America/Menominee": {code: "America/Menominee", name: "Central Standard Time"},
        "America/Merida": {code: "America/Merida", name: "Central Standard Time"},
        "America/Metlakatla": {code: "America/Metlakatla", name: "Alaska Standard Time"},
        "America/Mexico_City": {code: "America/Mexico_City", name: "Central Standard Time"},
        "America/Miquelon": {code: "America/Miquelon", name: "St. Pierre & Miquelon Standard Time"},
        "America/Moncton": {code: "America/Moncton", name: "Atlantic Standard Time"},
        "America/Monterrey": {code: "America/Monterrey", name: "Central Standard Time"},
        "America/Montevideo": {code: "America/Montevideo", name: "Uruguay Standard Time"},
        "America/Montreal": {code: "America/Montreal", name: "Eastern Standard Time"},
        "America/Montserrat": {code: "America/Montserrat", name: "Atlantic Standard Time"},
        "America/Nassau": {code: "America/Nassau", name: "Eastern Standard Time"},
        "America/New_York": {code: "America/New_York", name: "Eastern Standard Time"},
        "America/Nipigon": {code: "America/Nipigon", name: "Eastern Standard Time"},
        "America/Nome": {code: "America/Nome", name: "Alaska Standard Time"},
        "America/Noronha": {code: "America/Noronha", name: "Fernando de Noronha Standard Time"},
        "America/North_Dakota/Beulah": {code: "America/North_Dakota/Beulah", name: "Central Standard Time"},
        "America/North_Dakota/Center": {code: "America/North_Dakota/Center", name: "Central Standard Time"},
        "America/North_Dakota/New_Salem": {code: "America/North_Dakota/New_Salem", name: "Central Standard Time"},
        "America/Ojinaga": {code: "America/Ojinaga", name: "Mountain Standard Time"},
        "America/Panama": {code: "America/Panama", name: "Eastern Standard Time"},
        "America/Pangnirtung": {code: "America/Pangnirtung", name: "Eastern Standard Time"},
        "America/Paramaribo": {code: "America/Paramaribo", name: "Suriname Time"},
        "America/Phoenix": {code: "America/Phoenix", name: "Mountain Standard Time"},
        "America/Port-au-Prince": {code: "America/Port-au-Prince", name: "Eastern Standard Time"},
        "America/Port_of_Spain": {code: "America/Port_of_Spain", name: "Atlantic Standard Time"},
        "America/Porto_Acre": {code: "America/Porto_Acre", name: "Acre Standard Time"},
        "America/Porto_Velho": {code: "America/Porto_Velho", name: "Amazon Standard Time"},
        "America/Puerto_Rico": {code: "America/Puerto_Rico", name: "Atlantic Standard Time"},
        "America/Punta_Arenas": {code: "America/Punta_Arenas", name: "Punta Arenas Standard Time"},
        "America/Rainy_River": {code: "America/Rainy_River", name: "Central Standard Time"},
        "America/Rankin_Inlet": {code: "America/Rankin_Inlet", name: "Central Standard Time"},
        "America/Recife": {code: "America/Recife", name: "Brasilia Standard Time"},
        "America/Regina": {code: "America/Regina", name: "Central Standard Time"},
        "America/Resolute": {code: "America/Resolute", name: "Central Standard Time"},
        "America/Rio_Branco": {code: "America/Rio_Branco", name: "Acre Standard Time"},
        "America/Rosario": {code: "America/Rosario", name: "Argentina Standard Time"},
        "America/Santa_Isabel": {code: "America/Santa_Isabel", name: "Northwest Mexico Standard Time"},
        "America/Santarem": {code: "America/Santarem", name: "Brasilia Standard Time"},
        "America/Santiago": {code: "America/Santiago", name: "Chile Standard Time"},
        "America/Santo_Domingo": {code: "America/Santo_Domingo", name: "Atlantic Standard Time"},
        "America/Sao_Paulo": {code: "America/Sao_Paulo", name: "Brasilia Standard Time"},
        "America/Scoresbysund": {code: "America/Scoresbysund", name: "East Greenland Standard Time"},
        "America/Shiprock": {code: "America/Shiprock", name: "Mountain Standard Time"},
        "America/Sitka": {code: "America/Sitka", name: "Alaska Standard Time"},
        "America/St_Barthelemy": {code: "America/St_Barthelemy", name: "Atlantic Standard Time"},
        "America/St_Johns": {code: "America/St_Johns", name: "Newfoundland Standard Time"},
        "America/St_Kitts": {code: "America/St_Kitts", name: "Atlantic Standard Time"},
        "America/St_Lucia": {code: "America/St_Lucia", name: "Atlantic Standard Time"},
        "America/St_Thomas": {code: "America/St_Thomas", name: "Atlantic Standard Time"},
        "America/St_Vincent": {code: "America/St_Vincent", name: "Atlantic Standard Time"},
        "America/Swift_Current": {code: "America/Swift_Current", name: "Central Standard Time"},
        "America/Tegucigalpa": {code: "America/Tegucigalpa", name: "Central Standard Time"},
        "America/Thule": {code: "America/Thule", name: "Atlantic Standard Time"},
        "America/Thunder_Bay": {code: "America/Thunder_Bay", name: "Eastern Standard Time"},
        "America/Tijuana": {code: "America/Tijuana", name: "Pacific Standard Time"},
        "America/Toronto": {code: "America/Toronto", name: "Eastern Standard Time"},
        "America/Tortola": {code: "America/Tortola", name: "Atlantic Standard Time"},
        "America/Vancouver": {code: "America/Vancouver", name: "Pacific Standard Time"},
        "America/Virgin": {code: "America/Virgin", name: "Atlantic Standard Time"},
        "America/Whitehorse": {code: "America/Whitehorse", name: "Pacific Standard Time"},
        "America/Winnipeg": {code: "America/Winnipeg", name: "Central Standard Time"},
        "America/Yakutat": {code: "America/Yakutat", name: "Alaska Standard Time"},
        "America/Yellowknife": {code: "America/Yellowknife", name: "Mountain Standard Time"},
        "Antarctica/Casey": {code: "Antarctica/Casey", name: "Casey Time"},
        "Antarctica/Davis": {code: "Antarctica/Davis", name: "Davis Time"},
        "Antarctica/DumontDUrville": {code: "Antarctica/DumontDUrville", name: "Dumont-d’Urville Time"},
        "Antarctica/Macquarie": {code: "Antarctica/Macquarie", name: "Macquarie Island Time"},
        "Antarctica/Mawson": {code: "Antarctica/Mawson", name: "Mawson Time"},
        "Antarctica/McMurdo": {code: "Antarctica/McMurdo", name: "New Zealand Standard Time"},
        "Antarctica/Palmer": {code: "Antarctica/Palmer", name: "Chile Time"},
        "Antarctica/Rothera": {code: "Antarctica/Rothera", name: "Rothera Time"},
        "Antarctica/South_Pole": {code: "Antarctica/South_Pole", name: "New Zealand Standard Time"},
        "Antarctica/Syowa": {code: "Antarctica/Syowa", name: "Syowa Time"},
        "Antarctica/Troll": {code: "Antarctica/Troll", name: "Greenwich Mean Time"},
        "Antarctica/Vostok": {code: "Antarctica/Vostok", name: "Vostok Time"},
        "Arctic/Longyearbyen": {code: "Arctic/Longyearbyen", name: "Central European Standard Time"},
        "Asia/Aden": {code: "Asia/Aden", name: "Arabian Standard Time"},
        "Asia/Almaty": {code: "Asia/Almaty", name: "East Kazakhstan Time"},
        "Asia/Amman": {code: "Asia/Amman", name: "Eastern European Standard Time"},
        "Asia/Anadyr": {code: "Asia/Anadyr", name: "Anadyr Standard Time"},
        "Asia/Aqtau": {code: "Asia/Aqtau", name: "West Kazakhstan Time"},
        "Asia/Aqtobe": {code: "Asia/Aqtobe", name: "West Kazakhstan Time"},
        "Asia/Ashgabat": {code: "Asia/Ashgabat", name: "Turkmenistan Standard Time"},
        "Asia/Ashkhabad": {code: "Asia/Ashkhabad", name: "Turkmenistan Standard Time"},
        "Asia/Atyrau": {code: "Asia/Atyrau", name: "West Kazakhstan Time"},
        "Asia/Baghdad": {code: "Asia/Baghdad", name: "Arabian Standard Time"},
        "Asia/Bahrain": {code: "Asia/Bahrain", name: "Arabian Standard Time"},
        "Asia/Baku": {code: "Asia/Baku", name: "Azerbaijan Standard Time"},
        "Asia/Bangkok": {code: "Asia/Bangkok", name: "Indochina Time"},
        "Asia/Barnaul": {code: "Asia/Barnaul", name: "Barnaul Standard Time"},
        "Asia/Beirut": {code: "Asia/Beirut", name: "Eastern European Standard Time"},
        "Asia/Bishkek": {code: "Asia/Bishkek", name: "Kyrgyzstan Time"},
        "Asia/Brunei": {code: "Asia/Brunei", name: "Brunei Darussalam Time"},
        "Asia/Calcutta": {code: "Asia/Calcutta", name: "India Standard Time"},
        "Asia/Chita": {code: "Asia/Chita", name: "Yakutsk Standard Time"},
        "Asia/Choibalsan": {code: "Asia/Choibalsan", name: "Choibalsan Standard Time"},
        "Asia/Chongqing": {code: "Asia/Chongqing", name: "China Standard Time"},
        "Asia/Chungking": {code: "Asia/Chungking", name: "China Standard Time"},
        "Asia/Colombo": {code: "Asia/Colombo", name: "India Standard Time"},
        "Asia/Dacca": {code: "Asia/Dacca", name: "Bangladesh Standard Time"},
        "Asia/Damascus": {code: "Asia/Damascus", name: "Eastern European Standard Time"},
        "Asia/Dhaka": {code: "Asia/Dhaka", name: "Bangladesh Standard Time"},
        "Asia/Dili": {code: "Asia/Dili", name: "East Timor Time"},
        "Asia/Dubai": {code: "Asia/Dubai", name: "Gulf Standard Time"},
        "Asia/Dushanbe": {code: "Asia/Dushanbe", name: "Tajikistan Time"},
        "Asia/Famagusta": {code: "Asia/Famagusta", name: "Eastern European Time"},
        "Asia/Gaza": {code: "Asia/Gaza", name: "Eastern European Standard Time"},
        "Asia/Harbin": {code: "Asia/Harbin", name: "China Standard Time"},
        "Asia/Hebron": {code: "Asia/Hebron", name: "Eastern European Standard Time"},
        "Asia/Ho_Chi_Minh": {code: "Asia/Ho_Chi_Minh", name: "Indochina Time"},
        "Asia/Hong_Kong": {code: "Asia/Hong_Kong", name: "Hong Kong Standard Time"},
        "Asia/Hovd": {code: "Asia/Hovd", name: "Hovd Standard Time"},
        "Asia/Irkutsk": {code: "Asia/Irkutsk", name: "Irkutsk Standard Time"},
        "Asia/Istanbul": {code: "Asia/Istanbul", name: "Eastern European Time"},
        "Asia/Jakarta": {code: "Asia/Jakarta", name: "Western Indonesia Time"},
        "Asia/Jayapura": {code: "Asia/Jayapura", name: "Eastern Indonesia Time"},
        "Asia/Jerusalem": {code: "Asia/Jerusalem", name: "Israel Standard Time"},
        "Asia/Kabul": {code: "Asia/Kabul", name: "Afghanistan Time"},
        "Asia/Kamchatka": {code: "Asia/Kamchatka", name: "Petropavlovsk-Kamchatski Standard Time"},
        "Asia/Karachi": {code: "Asia/Karachi", name: "Pakistan Standard Time"},
        "Asia/Kashgar": {code: "Asia/Kashgar", name: "Xinjiang Standard Time"},
        "Asia/Kathmandu": {code: "Asia/Kathmandu", name: "Nepal Time"},
        "Asia/Katmandu": {code: "Asia/Katmandu", name: "Nepal Time"},
        "Asia/Khandyga": {code: "Asia/Khandyga", name: "Yakutsk Standard Time"},
        "Asia/Kolkata": {code: "Asia/Kolkata", name: "India Standard Time"},
        "Asia/Krasnoyarsk": {code: "Asia/Krasnoyarsk", name: "Krasnoyarsk Standard Time"},
        "Asia/Kuala_Lumpur": {code: "Asia/Kuala_Lumpur", name: "Malaysia Time"},
        "Asia/Kuching": {code: "Asia/Kuching", name: "Malaysia Time"},
        "Asia/Kuwait": {code: "Asia/Kuwait", name: "Arabian Standard Time"},
        "Asia/Macao": {code: "Asia/Macao", name: "China Standard Time"},
        "Asia/Macau": {code: "Asia/Macau", name: "China Standard Time"},
        "Asia/Magadan": {code: "Asia/Magadan", name: "Magadan Standard Time"},
        "Asia/Makassar": {code: "Asia/Makassar", name: "Central Indonesia Time"},
        "Asia/Manila": {code: "Asia/Manila", name: "Philippine Standard Time"},
        "Asia/Muscat": {code: "Asia/Muscat", name: "Gulf Standard Time"},
        "Asia/Nicosia": {code: "Asia/Nicosia", name: "Eastern European Standard Time"},
        "Asia/Novokuznetsk": {code: "Asia/Novokuznetsk", name: "Krasnoyarsk Standard Time"},
        "Asia/Novosibirsk": {code: "Asia/Novosibirsk", name: "Novosibirsk Standard Time"},
        "Asia/Omsk": {code: "Asia/Omsk", name: "Omsk Standard Time"},
        "Asia/Oral": {code: "Asia/Oral", name: "West Kazakhstan Time"},
        "Asia/Phnom_Penh": {code: "Asia/Phnom_Penh", name: "Indochina Time"},
        "Asia/Pontianak": {code: "Asia/Pontianak", name: "Western Indonesia Time"},
        "Asia/Pyongyang": {code: "Asia/Pyongyang", name: "Pyongyang Time"},
        "Asia/Qatar": {code: "Asia/Qatar", name: "Arabian Standard Time"},
        "Asia/Qyzylorda": {code: "Asia/Qyzylorda", name: "East Kazakhstan Time"},
        "Asia/Rangoon": {code: "Asia/Rangoon", name: "Myanmar Time"},
        "Asia/Riyadh": {code: "Asia/Riyadh", name: "Arabian Standard Time"},
        "Asia/Saigon": {code: "Asia/Saigon", name: "Indochina Time"},
        "Asia/Sakhalin": {code: "Asia/Sakhalin", name: "Sakhalin Standard Time"},
        "Asia/Samarkand": {code: "Asia/Samarkand", name: "Uzbekistan Standard Time"},
        "Asia/Seoul": {code: "Asia/Seoul", name: "Korean Standard Time"},
        "Asia/Shanghai": {code: "Asia/Shanghai", name: "China Standard Time"},
        "Asia/Singapore": {code: "Asia/Singapore", name: "Singapore Standard Time"},
        "Asia/Srednekolymsk": {code: "Asia/Srednekolymsk", name: "Srednekolymsk Time"},
        "Asia/Taipei": {code: "Asia/Taipei", name: "Taipei Standard Time"},
        "Asia/Tashkent": {code: "Asia/Tashkent", name: "Uzbekistan Standard Time"},
        "Asia/Tbilisi": {code: "Asia/Tbilisi", name: "Georgia Standard Time"},
        "Asia/Tehran": {code: "Asia/Tehran", name: "Iran Standard Time"},
        "Asia/Tel_Aviv": {code: "Asia/Tel_Aviv", name: "Israel Standard Time"},
        "Asia/Thimbu": {code: "Asia/Thimbu", name: "Bhutan Time"},
        "Asia/Thimphu": {code: "Asia/Thimphu", name: "Bhutan Time"},
        "Asia/Tokyo": {code: "Asia/Tokyo", name: "Japan Standard Time"},
        "Asia/Tomsk": {code: "Asia/Tomsk", name: "Tomsk Standard Time"},
        "Asia/Ujung_Pandang": {code: "Asia/Ujung_Pandang", name: "Central Indonesia Time"},
        "Asia/Ulaanbaatar": {code: "Asia/Ulaanbaatar", name: "Ulaanbaatar Standard Time"},
        "Asia/Ulan_Bator": {code: "Asia/Ulan_Bator", name: "Ulaanbaatar Standard Time"},
        "Asia/Urumqi": {code: "Asia/Urumqi", name: "Xinjiang Standard Time"},
        "Asia/Ust-Nera": {code: "Asia/Ust-Nera", name: "Vladivostok Standard Time"},
        "Asia/Vientiane": {code: "Asia/Vientiane", name: "Indochina Time"},
        "Asia/Vladivostok": {code: "Asia/Vladivostok", name: "Vladivostok Standard Time"},
        "Asia/Yakutsk": {code: "Asia/Yakutsk", name: "Yakutsk Standard Time"},
        "Asia/Yangon": {code: "Asia/Yangon", name: "Myanmar Time"},
        "Asia/Yekaterinburg": {code: "Asia/Yekaterinburg", name: "Yekaterinburg Standard Time"},
        "Asia/Yerevan": {code: "Asia/Yerevan", name: "Armenia Standard Time"},
        "Atlantic/Azores": {code: "Atlantic/Azores", name: "Azores Standard Time"},
        "Atlantic/Bermuda": {code: "Atlantic/Bermuda", name: "Atlantic Standard Time"},
        "Atlantic/Canary": {code: "Atlantic/Canary", name: "Western European Standard Time"},
        "Atlantic/Cape_Verde": {code: "Atlantic/Cape_Verde", name: "Cape Verde Standard Time"},
        "Atlantic/Faeroe": {code: "Atlantic/Faeroe", name: "Western European Standard Time"},
        "Atlantic/Faroe": {code: "Atlantic/Faroe", name: "Western European Standard Time"},
        "Atlantic/Jan_Mayen": {code: "Atlantic/Jan_Mayen", name: "Central European Standard Time"},
        "Atlantic/Madeira": {code: "Atlantic/Madeira", name: "Western European Standard Time"},
        "Atlantic/Reykjavik": {code: "Atlantic/Reykjavik", name: "Greenwich Mean Time"},
        "Atlantic/South_Georgia": {code: "Atlantic/South_Georgia", name: "South Georgia Time"},
        "Atlantic/St_Helena": {code: "Atlantic/St_Helena", name: "Greenwich Mean Time"},
        "Atlantic/Stanley": {code: "Atlantic/Stanley", name: "Falkland Islands Standard Time"},
        "Australia/ACT": {code: "Australia/ACT", name: "Australian Eastern Standard Time"},
        "Australia/Adelaide": {code: "Australia/Adelaide", name: "Australian Central Standard Time"},
        "Australia/Brisbane": {code: "Australia/Brisbane", name: "Australian Eastern Standard Time"},
        "Australia/Broken_Hill": {code: "Australia/Broken_Hill", name: "Australian Central Standard Time"},
        "Australia/Canberra": {code: "Australia/Canberra", name: "Australian Eastern Standard Time"},
        "Australia/Currie": {code: "Australia/Currie", name: "Australian Eastern Standard Time"},
        "Australia/Darwin": {code: "Australia/Darwin", name: "Australian Central Standard Time"},
        "Australia/Eucla": {code: "Australia/Eucla", name: "Australian Central Western Standard Time"},
        "Australia/Hobart": {code: "Australia/Hobart", name: "Australian Eastern Standard Time"},
        "Australia/LHI": {code: "Australia/LHI", name: "Lord Howe Standard Time"},
        "Australia/Lindeman": {code: "Australia/Lindeman", name: "Australian Eastern Standard Time"},
        "Australia/Lord_Howe": {code: "Australia/Lord_Howe", name: "Lord Howe Standard Time"},
        "Australia/Melbourne": {code: "Australia/Melbourne", name: "Australian Eastern Standard Time"},
        "Australia/NSW": {code: "Australia/NSW", name: "Australian Eastern Standard Time"},
        "Australia/North": {code: "Australia/North", name: "Australian Central Standard Time"},
        "Australia/Perth": {code: "Australia/Perth", name: "Australian Western Standard Time"},
        "Australia/Queensland": {code: "Australia/Queensland", name: "Australian Eastern Standard Time"},
        "Australia/South": {code: "Australia/South", name: "Australian Central Standard Time"},
        "Australia/Sydney": {code: "Australia/Sydney", name: "Australian Eastern Standard Time"},
        "Australia/Tasmania": {code: "Australia/Tasmania", name: "Australian Eastern Standard Time"},
        "Australia/Victoria": {code: "Australia/Victoria", name: "Australian Eastern Standard Time"},
        "Australia/West": {code: "Australia/West", name: "Australian Western Standard Time"},
        "Australia/Yancowinna": {code: "Australia/Yancowinna", name: "Australian Central Standard Time"},
        "Brazil/Acre": {code: "Brazil/Acre", name: "Acre Standard Time"},
        "Brazil/DeNoronha": {code: "Brazil/DeNoronha", name: "Fernando de Noronha Standard Time"},
        "Brazil/East": {code: "Brazil/East", name: "Brasilia Standard Time"},
        "Brazil/West": {code: "Brazil/West", name: "Amazon Standard Time"},
        "CET": {code: "CET", name: "Central European Time"},
        "CST6CDT": {code: "CST6CDT", name: "Central Standard Time"},
        "Canada/Atlantic": {code: "Canada/Atlantic", name: "Atlantic Standard Time"},
        "Canada/Central": {code: "Canada/Central", name: "Central Standard Time"},
        "Canada/Eastern": {code: "Canada/Eastern", name: "Eastern Standard Time"},
        "Canada/Mountain": {code: "Canada/Mountain", name: "Mountain Standard Time"},
        "Canada/Newfoundland": {code: "Canada/Newfoundland", name: "Newfoundland Standard Time"},
        "Canada/Pacific": {code: "Canada/Pacific", name: "Pacific Standard Time"},
        "Canada/Saskatchewan": {code: "Canada/Saskatchewan", name: "Central Standard Time"},
        "Canada/Yukon": {code: "Canada/Yukon", name: "Pacific Standard Time"},
        "Chile/Continental": {code: "Chile/Continental", name: "Chile Standard Time"},
        "Chile/EasterIsland": {code: "Chile/EasterIsland", name: "Easter Island Standard Time"},
        "Cuba": {code: "Cuba", name: "Cuba Standard Time"},
        "EET": {code: "EET", name: "Eastern European Time"},
        "EST5EDT": {code: "EST5EDT", name: "Eastern Standard Time"},
        "Egypt": {code: "Egypt", name: "Eastern European Standard Time"},
        "Eire": {code: "Eire", name: "Greenwich Mean Time"},
        "Europe/Amsterdam": {code: "Europe/Amsterdam", name: "Central European Standard Time"},
        "Europe/Andorra": {code: "Europe/Andorra", name: "Central European Standard Time"},
        "Europe/Astrakhan": {code: "Europe/Astrakhan", name: "Astrakhan Standard Time"},
        "Europe/Athens": {code: "Europe/Athens", name: "Eastern European Standard Time"},
        "Europe/Belfast": {code: "Europe/Belfast", name: "Greenwich Mean Time"},
        "Europe/Belgrade": {code: "Europe/Belgrade", name: "Central European Standard Time"},
        "Europe/Berlin": {code: "Europe/Berlin", name: "Central European Standard Time"},
        "Europe/Bratislava": {code: "Europe/Bratislava", name: "Central European Standard Time"},
        "Europe/Brussels": {code: "Europe/Brussels", name: "Central European Standard Time"},
        "Europe/Bucharest": {code: "Europe/Bucharest", name: "Eastern European Standard Time"},
        "Europe/Budapest": {code: "Europe/Budapest", name: "Central European Standard Time"},
        "Europe/Busingen": {code: "Europe/Busingen", name: "Central European Standard Time"},
        "Europe/Chisinau": {code: "Europe/Chisinau", name: "Eastern European Standard Time"},
        "Europe/Copenhagen": {code: "Europe/Copenhagen", name: "Central European Standard Time"},
        "Europe/Dublin": {code: "Europe/Dublin", name: "Greenwich Mean Time"},
        "Europe/Gibraltar": {code: "Europe/Gibraltar", name: "Central European Standard Time"},
        "Europe/Guernsey": {code: "Europe/Guernsey", name: "Greenwich Mean Time"},
        "Europe/Helsinki": {code: "Europe/Helsinki", name: "Eastern European Standard Time"},
        "Europe/Isle_of_Man": {code: "Europe/Isle_of_Man", name: "Greenwich Mean Time"},
        "Europe/Istanbul": {code: "Europe/Istanbul", name: "Eastern European Time"},
        "Europe/Jersey": {code: "Europe/Jersey", name: "Greenwich Mean Time"},
        "Europe/Kaliningrad": {code: "Europe/Kaliningrad", name: "Eastern European Standard Time"},
        "Europe/Kiev": {code: "Europe/Kiev", name: "Eastern European Standard Time"},
        "Europe/Kirov": {code: "Europe/Kirov", name: "Kirov Standard Time"},
        "Europe/Lisbon": {code: "Europe/Lisbon", name: "Western European Standard Time"},
        "Europe/Ljubljana": {code: "Europe/Ljubljana", name: "Central European Standard Time"},
        "Europe/London": {code: "Europe/London", name: "Greenwich Mean Time"},
        "Europe/Luxembourg": {code: "Europe/Luxembourg", name: "Central European Standard Time"},
        "Europe/Madrid": {code: "Europe/Madrid", name: "Central European Standard Time"},
        "Europe/Malta": {code: "Europe/Malta", name: "Central European Standard Time"},
        "Europe/Mariehamn": {code: "Europe/Mariehamn", name: "Eastern European Standard Time"},
        "Europe/Minsk": {code: "Europe/Minsk", name: "Moscow Standard Time"},
        "Europe/Monaco": {code: "Europe/Monaco", name: "Central European Standard Time"},
        "Europe/Moscow": {code: "Europe/Moscow", name: "Moscow Standard Time"},
        "Europe/Nicosia": {code: "Europe/Nicosia", name: "Eastern European Standard Time"},
        "Europe/Oslo": {code: "Europe/Oslo", name: "Central European Standard Time"},
        "Europe/Paris": {code: "Europe/Paris", name: "Central European Standard Time"},
        "Europe/Podgorica": {code: "Europe/Podgorica", name: "Central European Standard Time"},
        "Europe/Prague": {code: "Europe/Prague", name: "Central European Standard Time"},
        "Europe/Riga": {code: "Europe/Riga", name: "Eastern European Standard Time"},
        "Europe/Rome": {code: "Europe/Rome", name: "Central European Standard Time"},
        "Europe/Samara": {code: "Europe/Samara", name: "Samara Standard Time"},
        "Europe/San_Marino": {code: "Europe/San_Marino", name: "Central European Standard Time"},
        "Europe/Sarajevo": {code: "Europe/Sarajevo", name: "Central European Standard Time"},
        "Europe/Saratov": {code: "Europe/Saratov", name: "Saratov Standard Time"},
        "Europe/Simferopol": {code: "Europe/Simferopol", name: "Moscow Standard Time"},
        "Europe/Skopje": {code: "Europe/Skopje", name: "Central European Standard Time"},
        "Europe/Sofia": {code: "Europe/Sofia", name: "Eastern European Standard Time"},
        "Europe/Stockholm": {code: "Europe/Stockholm", name: "Central European Standard Time"},
        "Europe/Tallinn": {code: "Europe/Tallinn", name: "Eastern European Standard Time"},
        "Europe/Tirane": {code: "Europe/Tirane", name: "Central European Standard Time"},
        "Europe/Tiraspol": {code: "Europe/Tiraspol", name: "Eastern European Standard Time"},
        "Europe/Ulyanovsk": {code: "Europe/Ulyanovsk", name: "Ulyanovsk Standard Time"},
        "Europe/Uzhgorod": {code: "Europe/Uzhgorod", name: "Eastern European Standard Time"},
        "Europe/Vaduz": {code: "Europe/Vaduz", name: "Central European Standard Time"},
        "Europe/Vatican": {code: "Europe/Vatican", name: "Central European Standard Time"},
        "Europe/Vienna": {code: "Europe/Vienna", name: "Central European Standard Time"},
        "Europe/Vilnius": {code: "Europe/Vilnius", name: "Eastern European Standard Time"},
        "Europe/Volgograd": {code: "Europe/Volgograd", name: "Moscow Standard Time"},
        "Europe/Warsaw": {code: "Europe/Warsaw", name: "Central European Standard Time"},
        "Europe/Zagreb": {code: "Europe/Zagreb", name: "Central European Standard Time"},
        "Europe/Zaporozhye": {code: "Europe/Zaporozhye", name: "Eastern European Standard Time"},
        "Europe/Zurich": {code: "Europe/Zurich", name: "Central European Standard Time"},
        "GB": {code: "GB", name: "Greenwich Mean Time"},
        "GB-Eire": {code: "GB-Eire", name: "Greenwich Mean Time"},
        "GMT": {code: "GMT", name: "Greenwich Mean Time"},
        "GMT0": {code: "GMT0", name: "Greenwich Mean Time"},
        "Greenwich": {code: "Greenwich", name: "Greenwich Mean Time"},
        "Hongkong": {code: "Hongkong", name: "Hong Kong Standard Time"},
        "Iceland": {code: "Iceland", name: "Greenwich Mean Time"},
        "Indian/Antananarivo": {code: "Indian/Antananarivo", name: "East Africa Time"},
        "Indian/Chagos": {code: "Indian/Chagos", name: "Indian Ocean Time"},
        "Indian/Christmas": {code: "Indian/Christmas", name: "Christmas Island Time"},
        "Indian/Cocos": {code: "Indian/Cocos", name: "Cocos Islands Time"},
        "Indian/Comoro": {code: "Indian/Comoro", name: "East Africa Time"},
        "Indian/Kerguelen": {code: "Indian/Kerguelen", name: "French Southern & Antarctic Time"},
        "Indian/Mahe": {code: "Indian/Mahe", name: "Seychelles Time"},
        "Indian/Maldives": {code: "Indian/Maldives", name: "Maldives Time"},
        "Indian/Mauritius": {code: "Indian/Mauritius", name: "Mauritius Standard Time"},
        "Indian/Mayotte": {code: "Indian/Mayotte", name: "East Africa Time"},
        "Indian/Reunion": {code: "Indian/Reunion", name: "Réunion Time"},
        "Iran": {code: "Iran", name: "Iran Standard Time"},
        "Israel": {code: "Israel", name: "Israel Standard Time"},
        "Jamaica": {code: "Jamaica", name: "Eastern Standard Time"},
        "Japan": {code: "Japan", name: "Japan Standard Time"},
        "Kwajalein": {code: "Kwajalein", name: "Marshall Islands Time"},
        "Libya": {code: "Libya", name: "Eastern European Standard Time"},
        "MET": {code: "MET", name: "Middle Europe Time"},
        "MST7MDT": {code: "MST7MDT", name: "Mountain Standard Time"},
        "Mexico/BajaNorte": {code: "Mexico/BajaNorte", name: "Pacific Standard Time"},
        "Mexico/BajaSur": {code: "Mexico/BajaSur", name: "Mexican Pacific Standard Time"},
        "Mexico/General": {code: "Mexico/General", name: "Central Standard Time"},
        "NZ": {code: "NZ", name: "New Zealand Standard Time"},
        "NZ-CHAT": {code: "NZ-CHAT", name: "Chatham Standard Time"},
        "Navajo": {code: "Navajo", name: "Mountain Standard Time"},
        "PRC": {code: "PRC", name: "China Standard Time"},
        "PST8PDT": {code: "PST8PDT", name: "Pacific Standard Time"},
        "Pacific/Apia": {code: "Pacific/Apia", name: "Apia Standard Time"},
        "Pacific/Auckland": {code: "Pacific/Auckland", name: "New Zealand Standard Time"},
        "Pacific/Bougainville": {code: "Pacific/Bougainville", name: "Bougainville Standard Time"},
        "Pacific/Chatham": {code: "Pacific/Chatham", name: "Chatham Standard Time"},
        "Pacific/Chuuk": {code: "Pacific/Chuuk", name: "Chuuk Time"},
        "Pacific/Easter": {code: "Pacific/Easter", name: "Easter Island Standard Time"},
        "Pacific/Efate": {code: "Pacific/Efate", name: "Vanuatu Standard Time"},
        "Pacific/Enderbury": {code: "Pacific/Enderbury", name: "Phoenix Islands Time"},
        "Pacific/Fakaofo": {code: "Pacific/Fakaofo", name: "Tokelau Time"},
        "Pacific/Fiji": {code: "Pacific/Fiji", name: "Fiji Standard Time"},
        "Pacific/Funafuti": {code: "Pacific/Funafuti", name: "Tuvalu Time"},
        "Pacific/Galapagos": {code: "Pacific/Galapagos", name: "Galapagos Time"},
        "Pacific/Gambier": {code: "Pacific/Gambier", name: "Gambier Time"},
        "Pacific/Guadalcanal": {code: "Pacific/Guadalcanal", name: "Solomon Islands Time"},
        "Pacific/Guam": {code: "Pacific/Guam", name: "Chamorro Standard Time"},
        "Pacific/Honolulu": {code: "Pacific/Honolulu", name: "Hawaii Standard Time"},
        "Pacific/Johnston": {code: "Pacific/Johnston", name: "Hawaii-Aleutian Standard Time"},
        "Pacific/Kiritimati": {code: "Pacific/Kiritimati", name: "Line Islands Time"},
        "Pacific/Kosrae": {code: "Pacific/Kosrae", name: "Kosrae Time"},
        "Pacific/Kwajalein": {code: "Pacific/Kwajalein", name: "Marshall Islands Time"},
        "Pacific/Majuro": {code: "Pacific/Majuro", name: "Marshall Islands Time"},
        "Pacific/Marquesas": {code: "Pacific/Marquesas", name: "Marquesas Time"},
        "Pacific/Midway": {code: "Pacific/Midway", name: "Samoa Standard Time"},
        "Pacific/Nauru": {code: "Pacific/Nauru", name: "Nauru Time"},
        "Pacific/Niue": {code: "Pacific/Niue", name: "Niue Time"},
        "Pacific/Norfolk": {code: "Pacific/Norfolk", name: "Norfolk Island Time"},
        "Pacific/Noumea": {code: "Pacific/Noumea", name: "New Caledonia Standard Time"},
        "Pacific/Pago_Pago": {code: "Pacific/Pago_Pago", name: "Samoa Standard Time"},
        "Pacific/Palau": {code: "Pacific/Palau", name: "Palau Time"},
        "Pacific/Pitcairn": {code: "Pacific/Pitcairn", name: "Pitcairn Time"},
        "Pacific/Pohnpei": {code: "Pacific/Pohnpei", name: "Ponape Time"},
        "Pacific/Ponape": {code: "Pacific/Ponape", name: "Ponape Time"},
        "Pacific/Port_Moresby": {code: "Pacific/Port_Moresby", name: "Papua New Guinea Time"},
        "Pacific/Rarotonga": {code: "Pacific/Rarotonga", name: "Cook Islands Standard Time"},
        "Pacific/Saipan": {code: "Pacific/Saipan", name: "Chamorro Standard Time"},
        "Pacific/Samoa": {code: "Pacific/Samoa", name: "Samoa Standard Time"},
        "Pacific/Tahiti": {code: "Pacific/Tahiti", name: "Tahiti Time"},
        "Pacific/Tarawa": {code: "Pacific/Tarawa", name: "Gilbert Islands Time"},
        "Pacific/Tongatapu": {code: "Pacific/Tongatapu", name: "Tonga Standard Time"},
        "Pacific/Truk": {code: "Pacific/Truk", name: "Chuuk Time"},
        "Pacific/Wake": {code: "Pacific/Wake", name: "Wake Island Time"},
        "Pacific/Wallis": {code: "Pacific/Wallis", name: "Wallis & Futuna Time"},
        "Pacific/Yap": {code: "Pacific/Yap", name: "Chuuk Time"},
        "Poland": {code: "Poland", name: "Central European Standard Time"},
        "Portugal": {code: "Portugal", name: "Western European Standard Time"},
        "ROK": {code: "ROK", name: "Korean Standard Time"},
        "Singapore": {code: "Singapore", name: "Singapore Standard Time"},
        "Turkey": {code: "Turkey", name: "Eastern European Time"},
        "UCT": {code: "UCT", name: "Coordinated Universal Time"},
        "US/Alaska": {code: "US/Alaska", name: "Alaska Standard Time"},
        "US/Aleutian": {code: "US/Aleutian", name: "Hawaii-Aleutian Standard Time"},
        "US/Arizona": {code: "US/Arizona", name: "Mountain Standard Time"},
        "US/Central": {code: "US/Central", name: "Central Standard Time"},
        "US/East-Indiana": {code: "US/East-Indiana", name: "Eastern Standard Time"},
        "US/Eastern": {code: "US/Eastern", name: "Eastern Standard Time"},
        "US/Hawaii": {code: "US/Hawaii", name: "Hawaii Standard Time"},
        "US/Indiana-Starke": {code: "US/Indiana-Starke", name: "Central Standard Time"},
        "US/Michigan": {code: "US/Michigan", name: "Eastern Standard Time"},
        "US/Mountain": {code: "US/Mountain", name: "Mountain Standard Time"},
        "US/Pacific": {code: "US/Pacific", name: "Pacific Standard Time"},
        "US/Pacific-New": {code: "US/Pacific-New", name: "Pacific Standard Time"},
        "US/Samoa": {code: "US/Samoa", name: "Samoa Standard Time"},
        "UTC": {code: "UTC", name: "Coordinated Universal Time"},
        "Universal": {code: "Universal", name: "Coordinated Universal Time"},
        "W-SU": {code: "W-SU", name: "Moscow Standard Time"},
        "WET": {code: "WET", name: "Western European Time"},
        "Zulu": {code: "Zulu", name: "Coordinated Universal Time"},
        "EST": {code: "EST", name: "Eastern Standard Time"},
        "HST": {code: "HST", name: "Hawaii Standard Time"},
        "MST": {code: "MST", name: "Mountain Standard Time"},
        "ACT": {code: "ACT", name: "Australian Central Standard Time (Northern Territory)"},
        "AET": {code: "AET", name: "Australian Eastern Standard Time (New South Wales)"},
        "AGT": {code: "AGT", name: "Argentine Time"},
        "ART": {code: "ART", name: "Eastern European Time"},
        "AST": {code: "AST", name: "Alaska Standard Time"},
        "BET": {code: "BET", name: "Brasilia Time"},
        "BST": {code: "BST", name: "Bangladesh Time"},
        "CAT": {code: "CAT", name: "Central African Time"},
        "CNT": {code: "CNT", name: "Newfoundland Standard Time"},
        "CST": {code: "CST", name: "Central Standard Time"},
        "CTT": {code: "CTT", name: "China Standard Time"},
        "EAT": {code: "EAT", name: "Eastern African Time"},
        "ECT": {code: "ECT", name: "Central European Time"},
        "IET": {code: "IET", name: "Eastern Standard Time"},
        "IST": {code: "IST", name: "India Standard Time"},
        "JST": {code: "JST", name: "Japan Standard Time"},
        "MIT": {code: "MIT", name: "West Samoa Standard Time"},
        "NET": {code: "NET", name: "Armenia Time"},
        "NST": {code: "NST", name: "New Zealand Standard Time"},
        "PLT": {code: "PLT", name: "Pakistan Time"},
        "PNT": {code: "PNT", name: "Mountain Standard Time"},
        "PRT": {code: "PRT", name: "Atlantic Standard Time"},
        "PST": {code: "PST", name: "Pacific Standard Time"},
        "SST": {code: "SST", name: "Solomon Is. Time"},
        "VST": {code: "VST", name: "Indochina Time"}
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "timezone-picker auto-complete-picker" },
        items: this.items,
        getName: function(d) {return d.code},
        nameFormatter: function(d) {
            return '<span>' + escapeExpression(d.code) + '&nbsp;</span><span class="timezone-detail">(' + escapeExpression(d.name) + ')</span>';
        }
    });

    this.createConverter = function(placeholder) {
        var idToTagName = {};
        var items = this.items;
        _(Object.keys(items)).each(function(id) {
            idToTagName[id] = items[id];
        });

        return createTagConverter({
            items: idToTagName,
            placeholder: placeholder,
            getName: function(d) { return d ? d.code : ''; },
            // getName: function(d) { return d.code }
        });
    };

    this.converter = this.createConverter("Type part of the timezone name");

    /**
     * Attach a timezone picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
