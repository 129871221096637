var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<li><a data-method=\"sortBy\" data-orderby=\"ave\">AVE</a></li>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li><a data-method=\"sortBy\" data-orderby=\"id\">Date Received</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"published\">Published Date</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"updated\">Last Updated</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"ots\">OTS</a></li>\n"
    + ((stack1 = __default(require("../helpers/ifShowAVE.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ifShowAVE","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":87}}})) != null ? stack1 : "")
    + "\n<li><a data-method=\"sortBy\" data-orderby=\"engagement\">Engagement</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"resharecount\">Reshares</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"replycount\">Replies</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"interactionResponseTime\">Interaction Response Time</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"interactionFollowupResponseTime\">Follow-up Response Time</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"interactionWhResponseTime\">Interaction Response Time (Working Hours)</a></li>\n<li><a data-method=\"sortBy\" data-orderby=\"interactionWhFollowupResponseTime\">Follow-up Response Time (Working Hours)</a></li>\n";
},"useData":true});