import {getOperationalSentimentFilter, getReputationalSentimentFilter} from "@/app/utils/Sentiment";
import {getDefaultBrand} from "@/app/toplevel/explore/overview/ExploreUtilities";
import {getBenchmarkSentimentScore} from "@/app/utils/Sentiment";
import {formatBrandName} from "@/app/utils/Format";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import VuexStore from "@/store/vuex/VuexStore";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";

export async function getPublicOperationalSentimentVolumeType() {
    return {
        type: "FantasticChart",
        caption: "Operational sentiment volume",
        "manual-caption": true,
        xAxis: "sentiment",
        yAxis: "mentionPercent",
        width: 4,
        height: 4,
        geometry: "columns",
        filter: await getOperationalSentimentFilter()
    };
}

export async function getPublicReputationalSentimentVolumeType() {
    return {
        type: "FantasticChart",
        xAxis: "sentiment",
        yAxis: "mentionPercent",
        width: 4,
        height: 4,
        geometry: "columns",
        caption: "Reputational sentiment volume",
        "manual-caption": true,
        filter: await getReputationalSentimentFilter()
    };
}

const net = {
    type: "FantasticChart",
    xAxis: "published",
    width: 8,
    height: 4,
    show: [
        {
            opacity: 50,
            yAxis: "posAndNegPercent",
            geometry: "columns"
        },
        {
            yAxis: "totalSentimentPercent",
            geometry: "lines"
        }
    ]
};

const sentiment = {
    title: "Sentiment",
    description: "See how people feel about you and your competitors.",
    expanded: `
We measure how people feel towards brands, either

- {{positive-icon}}positively,
- {{negative-icon}}negatively, or
- {{neutral-icon}}neutrally, with no strong bias.

The _Net Sentiment_ score combines these values. It gives you a single customer satisfaction 
metric to measure public opinion towards a brand, product, person or concept.
`,
    image: require("../assets/sentiment.png"),
    metrics: [
        {
            id: "sentiment/overall/net",
            title: "Public sentiment",
            image: require("../assets/sentiment/overall.png"),
            description: "Shows the Net Sentiment of public conversations",
            keywords: ["overall", "net"],
            more: "Net Sentiment provides a single customer satisfaction metric to measure public opinion towards a brand, product, person or concept. This includes sentiment towards sub-brands.",
            widgetDesc: Object.assign({}, net, {
                filter: "visibility is public",
                caption: "Public Sentiment",
                'manual-caption': true,
            }),
            options: [
                {
                    key: "donut",
                    title: "As a donut",
                    tooltip: "Break your mentions down into positive, negative and neutral volumes",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/overall/donut",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "sentiment",
                                    yAxis: "mentionPercent",
                                    width: 4,
                                    height: 4,
                                    filter: "visibility is public",
                                    caption: "Sentiment for public mentions",
                                    'manual-caption': true,
                                    "geometry": "pie",
                                    "isDonut": true
                                }
                            });
                    }
                },
                {
                    key: "volume",
                    title: "As volume chart",
                    tooltip: "Break your mentions down into positive, negative and neutral volumes",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/overall/columns",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "sentiment",
                                    yAxis: "mentionPercent",
                                    width: 4,
                                    height: 4,
                                    filter: "visibility is public",
                                    caption: "Sentiment for public mentions",
                                    'manual-caption': true
                                }
                            });
                    }
                },
                {
                    key: "time",
                    title: "As volume over time",
                    tooltip: "See a line chart of your sentiment values over time",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/overall/lines",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "published",
                                    yAxis: "mentionPercent",
                                    compare: "sentiment",
                                    width: 8,
                                    height: 4,
                                    geometry: "lines",
                                    filter: "visibility is public",
                                    caption: "Sentiment for public mentions",
                                    'manual-caption': true,
                                    notification: "Showing <strong>Sentiment over time</strong>"
                                }
                            });
                    }
                },
                {
                    key: "table",
                    title: "As a table",
                    tooltip: "Display our sentiment volumes in a table",
                    action() {
                        return {
                            id: "sentiment/text",
                            widgetDesc: {
                                type: "Text",
                                text: "> # {{positive-icon}} Positive sentiment\n" +
                                    "> {{total positive% 'visibility is public'}}\n" +
                                    "> # {{neutral-icon}} Neutral  sentiment\n" +
                                    "> {{total neutral% 'visibility is public'}}\n" +
                                    "> # {{negative-icon}} Negative  sentiment\n" +
                                    "> {{total negative% 'visibility is public'}}",
                                notification: "Added <strong>Sentiment</strong> as a table"
                            }
                        };
                    }
                },
                {
                    key: "text",
                    title: "As text",
                    tooltip: "Display our sentiment volumes in a table",
                    action() {
                        return {
                            id: "sentiment/text",
                            widgetDesc: {
                                type: "Text",
                                text: "> # Public sentiment\n" +
                                    "> {{total public-net%}}",
                                height: 2,
                                width: 4,
                                notification: "Added <strong>Public sentiment</strong> as a text metric"
                            }
                        };
                    }
                }

            ]
        },
        {
            id: "sentiment/direct",
            image: require("../assets/sentiment/overall.png"),
            title: "Private sentiment",
            description: "See sentiment for your direct messages",
            widgetDesc: Object.assign({}, net, {
                filter: "visibility is DIRECT_MESSAGE",
                caption: "Private sentiment",
                'manual-caption': true
            })
        },
        {
            id: "sentiment/benchmark",
            title: "Benchmark sentiment", // Replaced in the init.
            description: "Shows the Net Sentiment of public conversations, excluding sub-brands", // Replaced in the init
            image: require("../assets/sentiment/operational.png"),
            more: "Benchmark sentiment lets your compare the sentiment of public conversations, excluding your sub-brands, which can be a useful way to compare yourself against your competitors.",
            hide: true,
            hero: true,
            keywords: ["net"],
            widgetDesc: async function () {
                const brand = await getDefaultBrand();
                return {
                    ...net,
                    caption: `Benchmark sentiment for ${formatBrandName(brand)}`,
                    'manual-caption': true,
                    filter: getBenchmarkSentimentScore(brand.id)
                };
            },
            options: [
                {
                    key: "text",
                    title: "As text",
                    action: async metric => {
                        const brand = await getDefaultBrand();
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/benchmark/donut",
                                widgetDesc: {
                                    caption: `Benchmark sentiment for ${formatBrandName(brand)}`,
                                    'manual-caption': true,
                                    type: "Text",
                                    width: 4,
                                    height: 2,
                                    text: `> # Benchmark sentiment 
                                    > {{total benchmark-net%}}`,
                                    'hidden-title': true
                                }
                            });
                    }
                },

                {
                    key: "donut",
                    title: "As a donut",
                    tooltip: "Break your mentions down into positive, negative and neutral benchmarking volumes",
                    action: async metric => {
                        const brand = await getDefaultBrand();
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/benchmark/donut",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "sentiment",
                                    yAxis: "mentionPercent",
                                    width: 4,
                                    height: 4,
                                    filter: getBenchmarkSentimentScore(brand.id),
                                    caption: `Benchmark breakdown for ${formatBrandName(brand)}`,
                                    'manual-caption': true,
                                    "geometry": "pie",
                                    "isDonut": true
                                }
                            });
                    }
                },
                {
                    key: "volume",
                    title: "As volume chart",
                    tooltip: "Break your mentions down into positive, negative and neutral benchmarking volumes",
                    action: async metric => {
                        const brand = await getDefaultBrand();
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/overall/columns",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "sentiment",
                                    yAxis: "mentionPercent",
                                    width: 4,
                                    height: 4,
                                    filter: getBenchmarkSentimentScore(brand.id),
                                    caption: `Benchmark breakdown for ${formatBrandName(brand)}`,
                                    'manual-caption': true
                                }
                            });
                    }
                },
                {
                    key: "choose",
                    title: "Select a brand",
                    tooltip: "Choose a brand to show the Benchmark sentiment towards",
                    action: async () => {
                        return new Promise((resolve, reject) => {
                            const dialog = showDialogComponent(BrandPickerDialog, {onlyOne: true, mustHaveOne: true});
                            try {
                                dialog.$on('selected', brands => {
                                    if (brands.length) { // No need to resolve if nothing is selected.
                                        const brand = brands[0];
                                        resolve({
                                            id: `sentiment/benchmark/${brand.id}`,
                                            widgetDesc: {
                                                ...net,
                                                caption: `Benchmark sentiment for ${formatBrandName(brand)}`,
                                                'manual-caption': true,
                                                filter: getBenchmarkSentimentScore(brand.id)
                                            }
                                        });
                                    }
                                });
                            } catch (e) {
                                console.error(e);
                                reject(e);
                            }
                        });
                    }
                }
            ]
        },
        {
            id: "sentiment/operational/net",
            title: "Operational sentiment",
            description: "See Net Sentiment relating to public customer service experiences",
            image: require("../assets/sentiment/operational.png"),
            more: "Operational sentiment is sentiment from public CX posts. Reputational conversation, including enterprise posts," +
                "are excluded from this metric to ensure a reading of CX performance that is not skewed by marketing and PR efforts.",
            widgetDesc: async function () {
                return Object.assign({},
                    net,
                    {
                        caption: "Operational Net Sentiment",
                        'manual-caption': true,
                        filter: await getOperationalSentimentFilter()
                    });
            },
            options: [
                {
                    key: "donut",
                    title: "As donut",
                    tooltip: "Break your mentions down into operationally positive, negative and neutral volumes",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/operational/donut",
                                caption: "Operational sentiment volume",
                                widgetDesc: Object.assign(
                                    {},
                                    await getPublicOperationalSentimentVolumeType(),
                                    {
                                        geometry: "pie",
                                        isDonut: true
                                    }
                                )
                            });
                    }
                },
                {
                    key: "volume",
                    title: "As volume chart",
                    tooltip: "Break your mentions down into operationally positive, negative and neutral volumes",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/operational/columns",
                                caption: "Operational sentiment volume",
                                widgetDesc: await getPublicOperationalSentimentVolumeType()
                            });
                    }
                }
            ]
        },
        {
            id: "sentiment/reputational/net",
            title: "Reputational sentiment",
            description: "See the Net Sentiment that is not related to a brand's customer service",
            image: require("../assets/sentiment/reputational.png"),
            more: "Reputational sentiment is unrelated to your CX or Enterprise posts, and only from public sources. Operational conversations " +
                "are excluded from this metric to ensure a reading of PR and marketing performance that is not skewed by CX performance.",
            widgetDesc: async function () {
                return Object.assign({},
                    net,
                    {
                        caption: "Reputational Net Sentiment",
                        'manual-caption': true,
                        filter: await getReputationalSentimentFilter()
                    });
            },
            options: [
                {
                    key: "donut",
                    title: "As donut",
                    tooltip: "Break your mentions down into reputationally positive, negative and neutral volumes",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/reputational/columns",
                                caption: "Reputational sentiment volume",
                                widgetDesc: Object.assign(
                                    {},
                                    await getPublicReputationalSentimentVolumeType(),
                                    {
                                        geometry: "pie",
                                        isDonut: true
                                    }
                                )
                            });
                    }
                },
                {
                    key: "volume",
                    title: "As volume chart",
                    tooltip: "Break your mentions down into reputationally positive, negative and neutral volumes",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/reputational/columns",
                                caption: "Reputational sentiment volume",
                                widgetDesc: await getPublicReputationalSentimentVolumeType()
                            });
                    }
                }
            ]
        },
        {
            id: "sentiment/reputational/comparison/lines",
            title: "Operational vs reputational",
            description: "Compare your operational and reputational sentiment performance",
            image: require("../assets/sentiment/operational-vs-reputational.png"),
            more: "This differentiated view splits out operational (CX) sentiment from reputational (PR and marketing) sentiment.",
            widgetDesc: {
                type: "FantasticChart",
                caption: "Operational vs Reputational Sentiment",
                "manual-caption": true,
                xAxis: "published",
                yAxis: "totalSentimentPercent",
                compare: "functionalSentiment",
                width: 8,
                height: 4,
                geometry: "lines"
            }
            ,
            options: [
                {
                    key: "volume",
                    title: "As total Net Sentiment",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "sentiment/reputational/comparison/columns",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "functionalSentiment",
                                    yAxis: "totalSentimentPercent",
                                    width: 4,
                                    height: 4,
                                    filter: "visibility is public"
                                }
                            });
                    }
                }
            ]
        }

    ]
};

export default sentiment;

let initPromise = null;

export async function init() {
    if (initPromise) return initPromise;
    return initPromise = initImpl();
}

export async function initImpl() {
    await VuexStore.dispatch('refreshBrands');
    const benchmark = sentiment.metrics.find(m => m.id === "sentiment/benchmark");
    if (benchmark) {
        const brand = await getDefaultBrand();
        if (brand) {
            benchmark.title = `Benchmark sentiment for ${formatBrandName(brand)}`;
            benchmark.description = `Shows the Net Sentiment of public conversations for ${formatBrandName(brand)}, excluding sub-brands`;
            benchmark.hide = false;

            // Here we want to add some options to make owned brands easy to select,
            // but also don't want a list that's huge.
            const ownedBrands = VuexStore.getters.ownedBrands;
            let numBrandsAdded = 0;
            for (const b of ownedBrands) {
                if (b.id === brand.id) continue;
                if (numBrandsAdded++ >= 3) break;
                benchmark.options = [
                    ...benchmark.options,
                    {
                        key: `choose:${b.id}`,
                        title: `For ${formatBrandName(b)}`,
                        tooltip: `Show benchmark sentiment for ${formatBrandName(b)}`,
                        action: async () => ({
                            id: `sentiment/benchmark/${b.id}`,
                            widgetDesc: {
                                ...net,
                                caption: `Benchmark sentiment for ${formatBrandName(b)}`,
                                'manual-caption': true,
                                filter: getBenchmarkSentimentScore(b.id)
                            }
                        })
                    }];
            }
        }
    }
}