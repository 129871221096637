<template>
    <span v-if="visibleTopics && visibleTopics.length">
        <slot :topics="visibleTopics">
            <p>The most frequently occurring topics are:</p>
            <slotted-tag v-for="data in visibleTopics" :key="data.tag.id"
                         class="be-frequent-topics__tag"
                         no-close
                         @mouseenter="showTooltip($event, data)"
                         @click="showMentions(data)">
                <net-sentiment v-if="data.netSentiment !== null" :net-sentiment="data.netSentiment" no-number/> {{data.tag.name}}
            </slotted-tag>
        </slot>

    </span>
</template>


<script>
import {count} from "@/data/Grouse";
import SlottedTag from "@/components/tags/SlottedTag";
import NetSentiment from "@/components/NetSentiment";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {getTopicTreeIds} from "@/app/utils/Topics";
import {mapState} from "vuex";
import MostFrequentTopicTooltip from "@/app/toplevel/explore/overview/components/MostFrequentTopicTooltip";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import {getTimedLocalstorageCache} from "@/data/Cache";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";

export default {
    components: {NetSentiment, SlottedTag},
    props: {
        filter: {
            type: String,
            required: true
        },

        numTopics: {
            type: Number,
            default: 3
        },

        namespace: {
            type: String,
            default: "topic"
        }
    },

    data() {
        return {
            loading: false,
            topTopics: null
        }
    },

    computed: {
        ...mapState('explorePanel', ['accountHasTopics']),

        visibleTopics() {
            if (this.topTopics === null) return null;
            if (!this.topTopics.length) return [];
            return this.topTopics.slice(0, this.numTopics)
        },

        key() {
            return `frequent-topic:${this.namespace}` + this.filter?.trim()?.toLowerCase();
        },
    },

    watch: {
        filter() {
            this.loadData();
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async getTagSubfilter() {
            switch (this.namespace) {
                case 'topic': {
                    const trees = await getTopicTreeIds();
                    return trees.map(id => `tag is ${id}`).join(' or ');
                }
                default:
                    return null;
            }
        },

        async loadData() {
            if (!this.accountHasTopics) return;
            if (this.loading || !this.filter) return;

            try {
                this.loading = true;
                const topics = await getTimedLocalstorageCache(this.key, async() => {
                    const treeFilter = await this.getTagSubfilter();
                    const filter = treeFilter ? `(${this.filter}) and (${treeFilter})` : this.filter;

                    return await count(filter, ['tag'],
                        ["mentionCount", "totalVerifiedSentiment", "sentimentVerifiedCount"],
                        100, {tagNamespace: this.namespace});
                });

                this.topTopics = topics.filter(t => t.tag.leaf && t.tag.flag !== 'NONE_OF_THE_ABOVE').slice(0, 5);

                this.topTopics.forEach(d => {
                    d.netSentiment = d?.sentimentVerifiedCount ? d.totalVerifiedSentiment / d.sentimentVerifiedCount : null;
                });

                return {
                    topTopics: Array.from(this.topTopics)
                }
            } finally {
                this.loading = false;
            }
        },

        showMentions(data) {
            const filter = appendFiltersReadably(this.filter, `tag is ${data.tag.id}`);
            const title = `Mentions for topic '${data.tag.name}' (by engagement)`;
            showMentions(filter, title, true);
        },

        showTooltip(ev, data) {
            showTooltipComponent(ev.target, MostFrequentTopicTooltip,
                {
                    tag: data.tag,
                    mentionCount: data.mentionCount,
                    net: data.netSentiment
                }
            );
        }
    }

}
</script>


<style scoped lang="sass">

.be-frequent-topics__tag
    cursor: pointer
    color: white
    margin-bottom: 5px
    margin-right: 5px



p
    margin: 0

</style>