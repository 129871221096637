/**
 * This view explicitly delays the rendering of its contents so that it can determine the max width and height that
 * have been allocated to it.
 *
 * <p>
 * You can override this by implementing the renderImpl method. Also, take special care when declaring
 * your own modelEvents to not overwrite the ones declared here.
 *
 * <p>
 * You should also set the contentSelector field with the name of your div containing any content that should be hidden.
 */
Beef.ConstrainedWidgetView = Beef.BoundItemView.extend({

    modelEvents: {
        "change:width":    "widthChanged"
    },

    onRender: function() {
        setTimeout(function() {
            this.$el.css({
                'width': this.$el.width(),
                'overflow': 'auto'
            });
            this.renderImpl();
        }.bind(this), 1);
    },

    renderImpl: function() {
        throw new Error('Not implemented.')
    },

    contentSelector: '.text-value',

    widthChanged: function() {
        var $textValue = this.$el.children();
        var display = $textValue.css('display');
        $textValue.css('display', 'none');

        setTimeout(function() {
            this.$el.css('width', '');
            this.$el.css('width', this.$el.width());
            $textValue.css('display', display);
            $textValue.addClass('animated fadeIn');
        }.bind(this), 350);
    }
});
