/**
 * Displays mention detail in a tooltip on hover for elements with mention-id class and data-value holding the id.
 */
import {getMention} from "@/data/Grouse";
import {currentAccountCode} from "@/app/utils/Account";

Beef.module("MentionIdToolip").addInitializer(function(startupOptions) {

    var tooltip = {
        currentId: null,
        promise: null,
        popup: null,
        $target: null,

        cancel: function() {
            if (this.promise) this.promise.cancel();
            if (this.popup) this.popup.close();
            this.promise = this.popup = this.currentId = this.$target = null;
        },

        show: function($target, accountCode, mentionId) {
            this.cancel();
            var that = this;
            this.promise = getMention(accountCode, mentionId);

            this.promise.then(function (mention) {
                if (!$target.is(":visible")) return tooltip.cancel();
                var m = new Beef.MentionItem.Model(mention);
                var v = new Beef.MentionItem.View({
                    model: m,
                    timeline: true,
                    noSelect: true,
                    notime: true,
                    noView: true
                });
                that.popup = new Beef.Popup.View({
                    closeOnHide: true,
                    extraCls: "mention-id-tooltip"
                });
                that.popup.setTarget($target);
                that.popup.show(v);
                that.currentId = mentionId;
                that.$target = $target;
                setTimeout(timeoutFn, 200);
            }).catch(console.warn);
        }
    };

    var timeoutFn = function() {
        if (!tooltip.$target) return;
        if (tooltip.$target.is(":visible")) setTimeout(timeoutFn, 200);
        else tooltip.cancel();
    };

    $(document).on("mouseover", ".mention-id", function(ev){
        var $t = $(ev.target).closest(".mention-id");
        var id = $t.attr('data-value');
        if (!id || tooltip.currentId == id) return;
        var ac = $t.attr('account') || currentAccountCode();
        if (!ac) return;
        tooltip.show($t, ac, id);
    });

    $(document).on("mouseout", ".mention-id", function(ev){
        tooltip.cancel();
    });
});
