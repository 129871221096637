import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import AllTipsDialog from "@/app/help/tips/AllTipsDialog";

export function showHelp() {
    showDialogComponent(AllTipsDialog);
}

Beef.module("HelpRouting").addInitializer(function() {
    Beef.route("help", "help", () => {
        showDialogComponent(AllTipsDialog);
    });
});