/**
 * Provides information about geometry for the charting framework.
 */
Beef.module("Widget.FantasticChartGeometry").addInitializer(function(startupOptions) {


    this.geometry = {
        "columns": {
            size: false,            // Whether to show the size selector
            labels: true            // Give the option to show or hide labels
        },
        "rows": {
            labels: true
        },
        "lines": {

        },
        "points": {
            size: true              // Whether to show the size selector
        },
        "pie": {
            labels: true
        }
    }

});