/**
 * Base for ReshareOfPicker and ReplyToPicker.
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("ReshareOfReplyToPicker").addInitializer(function(startupOptions) {

    this.View = Beef.Picker.View.extend({

        attributes: { class: "reshare-of-reply-to-picker" },

        bindings: {
            ids: { converter: Beef.MentionPicker.converter, elAttribute: "data-value" }
        },

        events: Object.assign({}, Beef.Picker.View.events, {
            "focus .ids input.tag": "onIdsInputFocus"
        }),

        initialize: function() {
            var view = this.options.view;
            this.model.accountCode = view.model.getAncestorProperty('accountCode');
            this.cache = view.cache = view.cache || {};
            // share cache with owning view so items are cached across invocations
        },

        mentionPredicate: null,

        onFirstRender: function() {
            Beef.MentionPicker.attach(this, ".ids", "ids", {predicate: this.mentionPredicate});
            this.initialFocus();
        },

        initialFocus: function() {
            if (this.model.get('op') == "ids") {
                setTimeout(function() { this.$("input.tag").focus() }.bind(this));
            }
        },

        onIdsInputFocus: function() {
            if (this.model.get('op') != 'ids') this.model.set('op', 'ids');
        },

        change: function() {
            Beef.Picker.View.prototype.change.apply(this, arguments);
            if (this.model.changed.op) {
                if (this.model.get('op') == 'ids') {
                    setTimeout(function() { this.$("input.tag").focus() }.bind(this));
                } else if (this.popup) {
                    this.popup.close();
                    this.popup = null;
                }
            }
        },

        onClose: function(ev) {
            if (this.popup) this.popup.close();
        },

        mousedown: function(ev) {
            if ($(ev.target).closest(".tag-input").length > 0) {
                Beef.Picker.ignoreNextBlur = true; // stop our popup from closing when our inputs get focus
            } else {
                ev.preventDefault();    // this stops stuff on the picker from getting focus
                if (this.popup) this.popup.hide();  // hide language picker - it won't see the event to hide itself
            }
        },

        setSelection: function(values) {
            var op, ids = "";
            if (values) {
                if (values == "unknown" || values == "-unknown") {
                    op = values;
                } else {
                    op = "ids";
                    ids = values;
                }
            } else {
                op = "NA";
            }
            this.model.set({op: op, ids: ids});
        },

        getSelection: function() {
            var op = this.model.get('op');
            var sel = [];
            if (op == "unknown" || op == "-unknown") {
                sel.push(op);
            } else if (op == "ids") {
                var ids = this.model.get('ids');
                if (ids) sel = ids.split(" ");
            }
            return sel;
        },

        isSpecial: function(code) {
            return false;
        },

        keyup: function(ev) {
            // don't let escape go up and close whatever dialog we might be in .. just close our popup instead
            if (ev.keyCode == 27 && this.parentPopup) {
                ev.stopPropagation();
                this.restoreFocusOnClose = true;
                this.parentPopup.hide();
            }
        }
    });

    this.createConverter = function(placeholder, reshare) {
        return createTagConverter({
            items: function(code) {
                if (code == "unknown") return "Is not a " + reshare;
                if (code == "-unknown") return "Is a " + reshare;
                return code;
            },
            placeholder: placeholder,
            placeholderPersistent: function(v) { return v && v != "unknown" && v != "-unknown" },
            noNeg: true,
            cls: "tag mention-id"
        });
    };

});
