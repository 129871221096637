import { isString } from "@/app/utils/StringUtils";

export function getProfileIcon(type) {
    if (!isString(type)) type = type.type;
    if (type.indexOf("FACEBOOK") === 0) return '<i class="symbol-facebook-rect facebook"></i>';
    if (type.indexOf("INSTAGRAM") === 0) return '<i class="symbol-instagram"></i>';
    if (type.indexOf("YOUTUBE") === 0) return '<i class="symbol-youtube"></i>';
    if (type.indexOf("LINKEDIN") === 0) return '<i class="symbol-linkedin-rect"></i>';
    if (type.indexOf("TIKTOK") === 0) return '<i class="symbol-tiktok"></i>';
    if (type.indexOf("TELEGRAM") === 0) return '<i class="symbol-telegram telegram"></i>';
    if (type.indexOf("WHATSAPP") === 0) return '<i class="symbol-whatsapp whatsapp"></i>';
    if (type.indexOf("HELLOPETER") === 0) return '<i class="symbol-hellopeter hellopeter"></i>';
    if (type.indexOf("GOOGLEBUSINESS") === 0) return '<i class="symbol-google-full google-business"></i>';

    return "@";
}
