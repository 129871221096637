<template>
    <add-profile-dialog :profile-type="profileTypes.tiktok"
                        :enable-next-btn="enableNext"
                        :next-btn-text="nextBtnText"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-prev-btn="!saving && curStage > 1"
                        :show-next-btn="!saving"
                        @close="$emit('close')"
                        @prev="previous"
                        @next="next">
        <template #workflow>
            <keep-alive>
                <select-managed-profiles :profile-type="profileTypes.tiktok" v-if="!saving && curStage === addProfileStages.tiktok.managed.SELECT_PROFILES"/>

                <saving-item v-if="saving" :save-complete="saveComplete" :save-error="saveError" :save-error-message="saveErrorMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more <i class="symbol-linkedin-rect"></i> Tiktok profiles</h4>
                    </template>
                </saving-item>
            </keep-alive>
        </template>
    </add-profile-dialog>
</template>

<script>
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import { addProfileMixins } from "@/setup/profiles/add-profile/AddProfileMixins";
import VuexStore from "@/store/vuex/VuexStore";
import SelectManagedProfiles from "@/setup/profiles/add-profile/SelectManagedProfiles";
import SavingItem from "@/setup/SavingItem";

export default {
    name: "TikTokAddProfileDialog",
    store: VuexStore,
    components: { SavingItem, SelectManagedProfiles, AddProfileDialog },
    mixins: [addProfileMixins],

    data: function () {
        return {
            curStage: 1,
            totalStages: 1
        }
    },

    created() {
        this.totalStages = Object.keys(this.addProfileStages.tiktok.managed).length;
    },

    computed: {
        enableNext() {
            return !!this.newProfileData.managedProfiles.profiles?.length;
        },

        nextBtnText() {
            // if we are on the final stage, show "Save" instead of "Next"
            return this.curStage === this.totalStages - 1 ? "Save" : "Next";
        }
    },

    methods: {
        next() {
            this.curStage++;
            if (this.curStage === this.totalStages)  this.saveManagedProfiles(this.profileTypes.tiktok);
        },

        previous() {
            this.curStage--;

            if (this.curStage === 0) {
                this.curStage = 1;
            }
        },

        reset() {
            this.saving = false;
            this.saveComplete = false;
            this.curStage = 1;
            this.clearNewProfiles();
        }
    }
}
</script>

<style scoped>

.continue /deep/ .btn {
    padding: 2px;
}

.error {
    color: var(--colour-error);
}

</style>