import {createDashboard} from "@/app/toplevel/dashboards/BeefModuleDashboardUtilities";
import {getRiskVolumeMetricDesc} from "@/app/framework/dialogs/metric-picker/availableMetrics";

/**
 * Creates a dashboard for quickly exploring topics for the given filter. This
 * is intended to be similar to the explore topics page.
 * @param {FilterString} filter
 * @param {String} title
 * @param {String,optional} category - The folder that dashboard is saved in
 * @return {Promise<void>}
 */
export async function createTopicPanelDashboard(filter, title, category) {
    if (!title) throw new Error("No title given");

    const dashboard = {
        name: title,
        category: category,
        sections: [
            {
                title: "Topics",
                filter: filter,
                widgets: [
                    {
                        "caption": "Commentary",
                        "type": "Text",
                        "width": 12,
                        "height": 1,
                        "hidden-title": true,
                        "text": "# Topic trends\n\nThese are the topics that have unusual volumes."
                    },
                    {
                        "caption": "Trending topics",
                        "name": "TrendingWidget",
                        "type": "TrendingWidget",
                        "width": 8,
                        "height": 5,
                        "withSentiment": true,
                        "withAverage": true,
                        "withMaxDate": true,
                        "dataType": "TOPICS",
                        "hidden-title": true,
                        "maxItems": 15,
                        "showSpiking": true,
                        "showUnusual": true,
                        "showSlightly": true,
                        "showExpected": true,
                        "showNew": false,
                        "showLow": true,
                    },

                    {
                        caption: "Show what mentions?",
                        type: "ShowWhatSelector",
                        width: 2,
                        height: 2
                    },

                    {
                        "caption": "Visibility",
                        "type": "VisibilitySelector",
                        "width": 2,
                        "height": 2,

                    },

                    {
                        "caption": "Most frequently occurring topics",
                        "type": "FantasticChart",
                        "width": 4,
                        "height": 3,
                        "manual-caption": true,
                        "xAxis": "topic",
                        "hideOther": true,
                        "hideParents": true,
                        "hideChildren": false,
                        "xGridLines": false,
                        "yGridLines": false,
                        "maxItems": 3,
                        "show": [
                            {
                                "yAxis": "mentionPercent",
                                "geometry": "rows",
                            }
                        ]
                    }
                ]
            }
        ]
    };

    await createDashboard(dashboard);

}


export async function createRiskPanelDashboard(filter, title, category) {
    if (!title) throw new Error("No title given");

    const riskVolumes = {
        ...getRiskVolumeMetricDesc()?.widgetDesc,
        width: 12,
        height: 2,
        'hidden-title': true,
        axisLabel: "% of Total Mentions"
    };


    const dashboard = {
        name: title,
        category: category,
        sections: [
            {
                title: "Risk",
                filter: filter,
                widgets: [
                    {
                        "caption": "Commentary",
                        "type": "Text",
                        "width": 12,
                        "height": 1,
                        "hidden-title": true,
                        "text": "#Risk trends\n\nThe following risk factors have been trending for {{brand}}.",
                    },

                    riskVolumes,

                    {
                        "caption": "Trending topics",
                        "name": "TrendingWidget",
                        "type": "TrendingWidget",
                        "width": 8,
                        "height": 5,
                        "withSentiment": true,
                        "withAverage": true,
                        "withMaxDate": true,
                        "dataType": "RISK",
                        "hidden-title": true,
                        "maxItems": 15,
                        "showSpiking": true,
                        "showUnusual": true,
                        "showSlightly": true,
                        "showExpected": true,
                        "showNew": false,
                        "showLow": true,
                    },

                    {
                        caption: "Show what mentions?",
                        type: "ShowWhatSelector",
                        width: 2,
                        height: 2
                    },
                    {
                        "caption": "Visibility",
                        "type": "VisibilitySelector",
                        "width": 2,
                        "height": 2,

                    },

                    {
                        "caption": "Most frequently occurring topics",
                        "type": "FantasticChart",
                        "width": 4,
                        "height": 3,
                        "manual-caption": true,
                        "xAxis": "topic",
                        "hideOther": true,
                        "hideParents": true,
                        "hideChildren": false,
                        "xGridLines": false,
                        "yGridLines": false,
                        "maxItems": 3,
                        "show": [
                            {
                                "yAxis": "mentionPercent",
                                "geometry": "rows",
                            }
                        ]
                    }
                ]
            }
        ]
    };

    await createDashboard(dashboard);

}

/**
 *
 * @param {FilterString} filter
 * @param {String} title
 * @param {String, optional} category
 * @return {Promise<void>}
 */
export async function createCxDashboard(filter, title, category) {
    if (!title) throw new Error("No title given");

    const dashboard = {
        name: title,
        category: category,
        sections: [
            {
                title: "Customer Experience Stats",
                filter: filter,
                widgets: [
                    {
                        "caption": "Blank",
                        "type": "BlockSpaceWidget",
                        "width": 1,
                        "height": 2,
                        "hidden-title": true
                    },
                    {
                        "caption": "Percent of verified",
                        "type": "Text",
                        "width": 2,
                        "height": 2,
                        "hidden-title": true,
                        "text": "> {{cx%}} CX\n> ## {{cx%}} of all verified mentions are CX ",
                    },
                    {
                        "caption": "Reshares",
                        "type": "Text",
                        "width": 2,
                        "height": 2,
                        "hidden-title": true,
                        "text": "> {{cx-reshare%}} CX\n> ## CX reshares make up {{cx-reshare%}} of all verified mentions",
                    },
                    {
                        "caption": "Public / Private",
                        "type": "Text",
                        "width": 2,
                        "height": 2,
                        "hidden-title": true,
                        "text": "> {{cx-public%}} public\n> ## \n> {{cx-private%}} private",
                    },
                    {
                        "caption": "Purchase",
                        "type": "Text",
                        "width": 2,
                        "height": 2,
                        "hidden-title": true,
                        "text": "> {{cx-purchase%}} purchase\n> ## Purchase mentions make up {{cx-purchase%}} of all CX mentions",
                    },
                    {
                        "caption": "Cancel",
                        "type": "Text",
                        "width": 2,
                        "height": 2,
                        "hidden-title": true,
                        "text": "> {{cx-cancel%}} cancel\n> ## Cancel mentions make up {{cx-cancel%}} of all CX mentions",
                    },
                    {
                        "caption": "blank",
                        "type": "BlockSpaceWidget",
                        "width": 1,
                        "height": 2,
                        "hidden-title": true
                    }
                ]
            },
            {
                title: "Customer Experience",
                filter: filter,
                widgets: [
                    {
                        "caption": "Commentary",
                        "type": "Text",
                        "width": 12,
                        "height": 1,
                        "hidden-title": true,
                        "text": "#Trending Customer Experience factors\n\nThe following topics have been trending in relation to the brand's Customer Experience.",
                    },

                    {
                        "caption": "Trending topics",
                        "name": "TrendingWidget",
                        "type": "TrendingWidget",
                        "width": 8,
                        "height": 5,
                        "withSentiment": true,
                        "withAverage": true,
                        "withMaxDate": true,
                        "dataType": "TOPICS",
                        "hidden-title": true,
                        "maxItems": 15,
                        "showSpiking": true,
                        "showUnusual": true,
                        "showSlightly": true,
                        "showExpected": true,
                        "showNew": false,
                        "showLow": true,
                    },

                    {
                        "caption": "Customer Experience",
                        "type": "CxSelector",
                        "width": 2,
                        "height": 2,
                    },

                    {
                        "caption": "Visibility",
                        "type": "VisibilitySelector",
                        "width": 2,
                        "height": 2,
                    },

                    {
                        "caption": "Most frequently occurring topics",
                        "type": "FantasticChart",
                        "width": 4,
                        "height": 3,
                        "manual-caption": true,
                        "xAxis": "topic",
                        "hideOther": true,
                        "hideParents": true,
                        "hideChildren": false,
                        "xGridLines": false,
                        "yGridLines": false,
                        "maxItems": 3,
                        "show": [
                            {
                                "yAxis": "mentionPercent",
                                "geometry": "rows",
                            }
                        ]
                    }
                ]
            }
        ]
    };

    await createDashboard(dashboard);

}
