import TagMentionDialog from "@/app/framework/dialogs/tag-mention/TagMentionDialog";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import {getDefaultTargetLanguage} from "@/app/utils/Language";
import DatePickerDialog from "@/app/framework/dialogs/date-picker/DatePickerDialog.vue";
import ProfilePickerDialog from "@/app/framework/dialogs/ProfilePickerDialog.vue";

/**
 * Shows an info dialog, for providing more information (and not error reporting) to a user.
 *  @returns {Promise<boolean>}
 */
export function showInfoDialog(message, title) {
    return showAskDialog(title || 'Info', message, false);
}

/**
 *  A convenient way to pop up an error message for users.
 *  @param {string} message
 *  @param {string?} title
 *  @returns {Promise<boolean>}
 */
export function showErrorDialog(message, title) {
    return showAskDialog(title || 'Error', message, false, 'symbol-warning');
}

/**
 * A convenient way to ask the user a question, and have the next item in the promise #then
 * chain either run or not run, as needed, without using an explicit if statement.
 * @param {string} message
 * @param {string?} title
 * @returns {Promise<boolean>}
 */
export function showWhenDialog(message, title) {
    const emptyPromise = {
        then: function() { return emptyPromise; },
        catch: function() { return emptyPromise },
        finally: function(f) { f(); return emptyPromise; }
    };

    return showAskDialog(title, message, true)
        .then(function (result) {
            if (!result) {
                return emptyPromise; // Swallows the next operation.
            }
        });
}

/**
 * A function that you can use to ask questions of the user.
 * @param {string} title
 * @param {string} message
 * @param {boolean} [cancellable=false] - Whether the user can cancel the action
 * @param {string} [optionalIconClass]
 * @return {Promise<boolean>}
 */
export function showAskDialog(title, message, cancellable, optionalIconClass) {
    return new Promise(function(resolve, reject) {
        try {
            const model = new Backbone.Model({
                title: title || "Missing title",
                message: message || "Missing message",
                cancellable: !!cancellable,
                iconClass: optionalIconClass
            });

            model.on("ok", function() { resolve(true) });
            model.on("cancel", function() { resolve(false) });

            const popup = new Beef.Popup.View({ closeOnHide: true, positions:["center"], plain: true, fixed: true });
            popup.setTarget($("html"));
            const view = new Beef.Dialogs.View({model: model, title: "Best title"});
            popup.show(view);
        } catch(error) {
            console.warn(error);
            reject(error);
        }
    })
}



Beef.module("Dialogs").addInitializer(function(startupOptions) {

    /**
     * This is old and currently only present to support the above, exported, dialog functions.
     * You should probably not be using this if you can avoid it.
     */
    this.View = Beef.BoundItemView.extend({
        tagName: "div",
        attributes: { class: "framework-dialog" },
        template: require("@/app/framework/dialogs/Dialog.handlebars"),

        events: {
            "click .ok": "ok",
            "click .cancel": "cancel",
            "click .close": "cancel"
        },

        initialize: function(options) {
            this.handler = event => {
                if (event.key === 'Enter') this.ok();
                if (event.key === 'Escape') this.cancel();
            };
            document.addEventListener("keypress", this.handler, {capture: true, passive: true})
        },

        templateHelpers: function() {
            let msg = this.model.attributes.message;
            let lines = msg && msg.split ? msg.split("\n") : [];
            return { lines: lines };
        },

        ok: function() {
            this.handled = true;
            this.model.trigger("ok");
            this.close();
        },

        cancel: function() {
            this.close();
        },

        onClose: function() {
            if (!this.handled) {
                this.model.trigger(this.model.get("cancellable") ? "cancel" : "ok");
            }
            if (this.handler) document.removeEventListener("keypress", this.handler, true);
        },
    });
});

/**
 * @param {string} filter
 * @param {string} [title = 'Tag mentions']
 * @return {void}
 */
export function showTagMentionDialog(filter, title) {
    showDialogComponent(TagMentionDialog, {filter, title});
}


/**
 *
 * @param {function} onSelected The function that is called when a selection is made. Past an array of brands.
 * @param showChildren
 * @param includedBrands
 * @param excludedBrands
 * @param useAdvancedFormat
 * @param excludeChildren
 * @param {boolean} [onlyOne=false] Allow only a single brand to be chosen.
 * @returns {void}
 */
export function showBrandPickerDialog({
                                          onSelected,
                                          showChildren = false,
                                          includedBrands = [],
                                          excludedBrands = [],
                                          useAdvancedFormat = false,
                                          excludeChildren = false,
                                          onlyOne = false
                                      } = {}) {

    const includedIds = includedBrands.map(b => b.id);
    const excludedIds = excludedBrands.map(b => b.id);
    const dialog = showDialogComponent(BrandPickerDialog, {
        selectedIds: includedIds,
        showChildren,
        excludedIds,
        useAdvancedFormat,
        excludeChildren,
        onlyOne
    });

    if (onSelected) dialog.$on('selected', onSelected);
}

/**
 * @param {Function<string>} onSelected
 * @param {string} date                     A formatted date string for the basic filter.
 * @param {boolean | undefined} allowHours  Whether the 24Hours flag can be chosen.
 * @param {boolean | undefined} allowTime   Whether time values can be chosen.
 * @param {string | undefined} title        The title to give the dialog
 */
export function showPublishedPickerDialog({
                                              onSelected,
                                              date,
                                              allowHours,
                                              allowTime,
                                              title
                                          }) {
    const dialog = showDialogComponent(DatePickerDialog, {
        date,
        allowHours,
        allowTime,
        title
    });

    dialog.$on('selected', onSelected);
}

/**
 * Shows the translation dialog to translate text with.
 * @param text {string}}
 * @return {Promise<{targetLanguage: string, targetText: string}|null>}
 */
export async function showTranslationDialog(text) {
    const model = new Backbone.Model({ extract: text, language: {id: 'en', name: 'English' }});
    const result = await Beef.Dialogs.translate(model);
    return result ?  result : null;
}

export function showNewProfilePicker(onSelected, previousSelected, allowNegation) {
    const dialog = showDialogComponent(ProfilePickerDialog, {
        previousSelected: previousSelected,
        allowNegation: allowNegation
    });

    dialog.$on('selected', onSelected);
}
