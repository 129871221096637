import SetupPanel from "@/app/toplevel/setup/SetupPanel";
import VuexStore from "@/store/vuex/VuexStore";

let view = null;

/**
 * View etc. for "setup" main menu option.
 */
Beef.module("Setup").addInitializer(function(startupOptions) {
    var routeCallback = function(code, resource, id) {
        if (!Beef.isAccountCode(code)) {
            Beef.content.show(Beef.view404);
            return
        }

        if (!view || view.vm.destroyed) {
            view = new Beef.VuejsView.View({component: SetupPanel, props: {resource, id}});
            Beef.content.show(view);
        } else {
            if (resource === "brands" && id) {
                const brand = VuexStore.getters.idToBrand.get(Number.parseInt(id));
                if (brand) {
                    view.vm.loadBrand(brand);
                }
            } else {
                if (id) view.vm.id = id;
                view.vm.activePage = resource;
            }
        }


    };

    Beef.route(":code/setup", "setup", routeCallback);
    Beef.route(":code/setup/:resource", "setup", routeCallback);
    Beef.route(":code/setup/:resource/:id", "setup", routeCallback);
});
