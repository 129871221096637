<template>
    <i :class="{'icon-right-dir': collapsed, 'icon-down-dir': !collapsed}"></i>
</template>

<script>

export default {
    props: {
        collapsed: {
            type: Boolean,
            default: false
        }
    }
}

</script>