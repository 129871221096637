<template>
    <div class="explore-panel-description">
        <slot/>
    </div>
</template>


<script>
export default {

}
</script>


<style scoped lang="sass">

.explore-panel-description
    max-width: var(--max-page-with)
    color: var(--be-colour-text-dark)

p
    color: var(--be-colour-text-dark)

h4
    color: white

strong
    color: white
    font-size: calc(1em + 0.5px)





</style>