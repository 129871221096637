import {deprecatedMashGet} from "@/app/Mash";
import _ from 'underscore';

Beef.module("EditBrandSegments").addInitializer(function(startupOptions) {

    this.View = Beef.SettingsDialog.View.extend({

        template: require("@/setup/brands/EditBrandSegments.handlebars"),

        attributes: { class: "edit-brand-segments dialog" },

        modelEvents: {
            "change:_list": "render"
        },

        events:Object.assign({
            "change .selected-cb": 'changeSelected',
            "change .active-cb": 'changeActive'
        }, Beef.SettingsDialog.View.prototype.events),

        render: function() {
            if (this.model.get("_list")) {
                Beef.BoundItemView.prototype.render.call(this, arguments);
            } else {
                Backbone.Marionette.Layout.prototype.render.call(this, arguments);
                var that = this;
                var brandName = this.model.get('name');
                deprecatedMashGet('topics/trees/list?type=SEGMENT_LIST', null, function(data) {
                    var selected = that.model.get('segmentListIds') || [];
                    var active = that.model.get('activeSegmentListIds') || [];
                    for (var i = 0; i < data.length; i++) {
                        var row = data[i];
                        row.selected = selected.indexOf(row.id) >= 0;
                        row.active = row.selected && active.indexOf(row.id) >= 0;
                        if (row.description) row.description = row.description.replace(/%brand%/g, brandName)
                    }
                    that.model.set("_list", data);
                });
            }
        },

        changeSelected: function(ev) {
            var row = this.findSegmentList(ev);
            row.selected = ev.target.checked;
            if (row.selected) {
                row.active = true;
                this.$(ev.target).closest("tr").find('.active-cb input[type="checkbox"]')[0].checked = true;
            }
        },

        changeActive: function(ev) {
            this.findSegmentList(ev).active = ev.target.checked;
        },

        findSegmentList: function(ev) {
            var id = parseInt(this.$(ev.target).attr("data-id"));
            var list = this.model.get("_list");
            for (var i = 0; i < list.length; i++) if (list[i].id === id) return list[i];
            return null;
        },

        ok: function() {
            var segmentListIds = [], activeSegmentListIds = [], segmentLists = [];
            var list = this.model.get("_list");
            for (var i = 0; i < list.length; i++) {
                var row = list[i];
                if (row.selected) {
                    segmentListIds.push(row.id);
                    if (row.active) activeSegmentListIds.push(row.id);
                    segmentLists.push(row);
                }
            }
            this.model.set("segmentListIds", segmentListIds);
            this.model.set("activeSegmentListIds", activeSegmentListIds);
            this.model.set("segmentLists", segmentLists);
            Beef.SettingsDialog.View.prototype.ok.call(this, arguments);
        }
    });
});

