/**
 * Select profiles to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
import {features} from "@/app/Features";

Beef.module("IncExProfilePicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/incexprofile/IncExProfilePicker.handlebars"),

        bindings: {
            inc:  { converterFactory: Beef.ProfilePicker.converterFactory, elAttribute: "data-value" },
            ex:  { converterFactory: Beef.ProfilePicker.converterFactory, elAttribute: "data-value" }
        },

        attachPickers: function() {
            // disabled 'select all' due to poor performance when used with 'mentionedprofile' attribute
            Beef.ProfilePicker.attach(this, ".inc", "inc", {
                title: "Include mentions directed at these profiles",
                disabled: { all: true, facebookAll: true, instagramAll: true, twitterAll: true }
            });
            Beef.ProfilePicker.attach(this, ".ex", "ex", {
                title: "Exclude mentions directed at these profiles",
                disabled: { all: true, facebookAll: true, instagramAll: true, twitterAll: true }
            });
        }
    });

    this.converterFactory = Beef.ProfilePicker.createConverterFactory("Directed at");
    this.converterFactoryHasReply = Beef.ProfilePicker.createConverterFactory("Has reply from");

    /**
     * Attach a inc/ex term picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        options = Object.assign({
            includeLabel: "Include mentions directed at these profiles",
            excludeLabel: "Exclude mentions directed at these profiles"
        }, options)
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
