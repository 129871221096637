var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"control-group\" style=\"margin-top: 10px\">\n                    <div class=\"controls\">\n                        <label class=\"checkbox\" title=\"Tick this box if the feed does not return unique URLs for items\">\n                            <input type=\"checkbox\" name=\"includeContentHashInURI\"> Include hash of mention content in the URI\n                        </label>\n                    </div>\n                </div>\n\n                <div class=\"control-group\">\n                    <div class=\"controls\">\n                        <label class=\"checkbox\">\n                            <input type=\"checkbox\" name=\"sendMentionsToAllAccounts\"> Send mentions from this feed to all accounts\n                        </label>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"dialog-title\">\n    <h5>Edit the custom data source</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"row-fluid\">\n        <div class=\"control-group\">\n            <label>Link to RSS Feed</label>\n            <div class=\"controls\">\n                <input type=\"text\" name=\"link\" class=\"span12\" placeholder=\"Enter URL for RSS feed\"/>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row-fluid\">\n        <div class=\"span6\">\n            <div class=\"control-group\">\n                <label>Name</label>\n                <div class=\"controls\">\n                    <input dir=\"auto\" type=\"text\" name=\"name\" class=\"span12\"/>\n                </div>\n            </div>\n            <div class=\"control-group\">\n                <label>Location for mentions from feed (optional)</label>\n                <div class=\"controls\">\n                    <span class=\"location tag-input uneditable-input span12\" tabindex=\"0\">\n                        <span name=\"location\"></span>\n                        <input class=\"tag popup-menu\" type=\"text\"/>\n                    </span>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"span6\" style=\"padding-left: 10px\">\n            <div class=\"control-group\" style=\"margin-top: 21px\">\n                <div class=\"controls\">\n                    <label class=\"checkbox\">\n                        <input type=\"checkbox\" name=\"includeFeedNameInContent\"> Include the name of the feed in each mention\n                    </label>\n                </div>\n            </div>\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":61,"column":26}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\"dialog-button-bar\">\n        <a class=\"delete btn pull-left\">Delete</a>\n        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n\n</div>";
},"useData":true});