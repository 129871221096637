import {mash} from "@/store/Services";
import {Profile, Rule} from "@/app/utils/types";

const RULE_PARENT = new Rule();
let refreshPromise = null;

export const RuleMutations = {

    setRules: (state, rules) => {
        rules?.forEach(r => r.__proto__ = RULE_PARENT);
        state.rules = rules;
    },

    addRule: (state, {value, sort}) => {
        sort ??= false;
        if (state.rules === null) state.rules = [];

        let existingRule = state.rules.find(r => r.id === value.id);
        if (existingRule) {
            console.error(`Rule with ID {} already exists in state rules`);
        } else {
            value.__proto__ = new Rule();
            state.rules.push(value);

            if (sort) state.rules.sort((a,b) =>  a.priority > b.priority ? 1 : -1);
        }
    },
};

export const RuleActions = {

    async refreshRules({commit, rootState}, forceRefresh) {
        if (rootState.rules && !forceRefresh) return;
        if (refreshPromise && !forceRefresh) return refreshPromise;

        const impl = async () => {
            const response = await mash.get("/rest/accounts/" + rootState.account.code + "/rules?notLegacy=true&includeStats=true");
            commit('setRules', response.data);
        };

        return refreshPromise = impl();
    },

    async updateRule({state, commit, rootState}, rule) {
        return mash
            .put(`/rest/accounts/${rootState.account.code}/rules/${rule.id}?notLegacy=true&includeStats=true`, rule)
            .then(res => {
                let updatedRules = state.rules?.map(r => r.id === rule.id ? rule : r).sort((a,b) =>  a.priority > b.priority ? 1 : -1);

                commit('setRules', updatedRules);
            });
    },

    async deleteRule({state, commit, rootState}, ruleId) {
        await mash.delete(`/rest/accounts/${rootState.account.code}/rules/${ruleId}?notLegacy=true&includeStats=true`);
        const updated = state.rules?.filter(r => r.id !== ruleId);
        await commit('setRules', updated);
    },

    async createRule({commit, rootState}, rule) {
        return mash
            .post(`/rest/accounts/${rootState.account.code}/rules?notLegacy=true&includeStats=true`, rule)
            .then(res => {
                let addedRule = {...rule};
                addedRule.priority = res.data.priority;
                addedRule.id = res.data.id;
                if (res.data.macro) {
                    addedRule.attributes = res.data.macro.attributes;
                }

                commit('addRule', {value: addedRule, sort: false});
            });
    }
};