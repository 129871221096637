import { render, staticRenderFns } from "./SelectorMenu.vue?vue&type=template&id=fa226b96&scoped=true"
import script from "./SelectorMenu.vue?vue&type=script&lang=js"
export * from "./SelectorMenu.vue?vue&type=script&lang=js"
import style0 from "./SelectorMenu.vue?vue&type=style&index=0&id=fa226b96&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa226b96",
  null
  
)

export default component.exports