var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <table class=\"table table-bordered table-condensed table-hover\">\n        <thead>\n        <tr>\n            <td class=\"crowd-history-header\">Crowd Verification History</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":73}}})) != null ? stack1 : "")
    + "\n            <td width=\"100%\"></td>\n        </tr>\n        </thead>\n        <tbody>\n        <tr>\n            <td class=\"crowd-history-header\">Received sentiment jobs</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":61}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":45,"column":22}}})) != null ? stack1 : "")
    + "        <tr>\n            <td class=\"crowd-history-header\">Topic jobs</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":48,"column":60}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":8},"end":{"line":76,"column":22}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(__default(require("../../helpers/formatYYYYMMDate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"month") : depth0),{"name":"formatYYYYMMDate","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":59}}}))
    + "</td>";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ratingJobs") : depth0), depth0))
    + "</td>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr title=\"Classified as neutral by our AI and not sent to the crowd\">\n            <td class=\"crowd-history-header\">Screened out by the neutral filter</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":81}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr title=\"A sentiment job received by the crowd that we had previously worked on\">\n            <td class=\"crowd-history-header\">Screened duplicate rating</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":78}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr title=\"A segmentation job received by the crowd that we had previously worked on\">\n            <td class=\"crowd-history-header\">Screened segmentations</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":71}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr title=\"A topic job received by the crowd that we had previously worked on\">\n            <td class=\"crowd-history-header\">Screened duplicate topics</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":34,"column":77}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr>\n            <td class=\"crowd-history-header\">Desktop rating jobs</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":39,"column":68}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr>\n            <td></td>"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":21},"end":{"line":43,"column":56}}})) != null ? stack1 : "")
    + "<td></td>\n        </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"screenedOutByEmotiveClassifier") : depth0), depth0))
    + "</td>";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"screenedRatingsByDupChecker") : depth0), depth0))
    + "</td>";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"screenedByDupChecker") : depth0), depth0))
    + "</td>";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"screenedTopicsByDupChecker") : depth0), depth0))
    + "</td>";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"desktopRatingJobs") : depth0), depth0))
    + "</td>";
},"17":function(container,depth0,helpers,partials,data) {
    return "<td></td>";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"topicJobs") : depth0), depth0))
    + "</td>";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <td></td>"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":21},"end":{"line":54,"column":56}}})) != null ? stack1 : "")
    + "<td></td>\n        </tr>\n        <tr>\n            <td class=\"crowd-history-header\">Desktop rating jobs completed</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":58,"column":77}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr>\n            <td class=\"crowd-history-header\">Desktop rating jobs agreement</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":63,"column":77}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr>\n            <td class=\"crowd-history-header\">Topic jobs completed</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":68,"column":69}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n        <tr>\n            <td class=\"crowd-history-header\">Topic jobs agreement</td>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":73,"column":69}}})) != null ? stack1 : "")
    + "\n            <td></td>\n        </tr>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"desktopRatingJobsCompleted") : depth0), depth0))
    + "</td>";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"desktopRatingJobsAgreement") : depth0), depth0))
    + "</td>";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"topicJobsCompleted") : depth0), depth0))
    + "</td>";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"topicJobsAgreement") : depth0), depth0))
    + "</td>";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":80,"column":4},"end":{"line":84,"column":11}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"spinner empty-spinner\"></span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "        No DataEQ Crowd usage information is available at this time\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"monthly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":85,"column":7}}})) != null ? stack1 : "");
},"useData":true});