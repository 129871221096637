/**
 * Select tags to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
Beef.module("IncExTopicPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/incextopic/IncExTopicPicker.handlebars"),
        items: Beef.TagPicker.items,
        bindings: {
            inc: { converterFactory: Beef.TagPicker.createConverterFactory(null, {noHighlightTopics: true, showWarning: true}), elAttribute: "data-value" },
            ex: { converterFactory: Beef.TagPicker.createConverterFactory(null, {noHighlightTopics: true, showWarning: true}), elAttribute: "data-value" }
        },
        attachPickers: function() {
            var options = {
                searchFilter: Beef.TagPicker.topicChooser,
                showTopicTagDetail: false,
                sortFun: Beef.TagPicker.sortFun
            };

            Beef.TagPicker.attach(this, ".inc", "inc", options);
            Beef.TagPicker.attach(this, ".ex", "ex", options);
        }
    });

    this.converterFactory = Beef.TagPicker.createConverterFactory("Topics", {noHighlightTopics: true, showWarning: true});

    /**
     * Attach the picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        var options = {
            mouseover: function(item, node) { Beef.TagPicker.tooltip(view, item, node); }
        };

        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
