var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"author\">\n                <a class=\"edit-author\"><img class=\"avatar\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\"></a>\n\n                <a class=\"author-link\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\">\n                    <div class=\"name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n                    <div class=\"screen-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "</div>\n                </a>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"OTS") : depth0), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "0";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"engagement") : depth0), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"inner\">\n    <div class=\"inner-content\">\n"
    + ((stack1 = __default(require("../helpers/withMentionAuthor.js")).call(alias1,{"name":"withMentionAuthor","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":12,"column":30}}})) != null ? stack1 : "")
    + "\n        <a class=\"date\" href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\">"
    + alias2(__default(require("../helpers/humanizeDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),true,{"name":"humanizeDate","hash":{},"data":data,"loc":{"start":{"line":14,"column":56},"end":{"line":14,"column":87}}}))
    + "</a>\n\n        <a class=\"edit-link\" title=\"Click to view/edit mention\">\n            <div>\n                <span class=\"ots\" title=\"OTS\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OTS") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":18,"column":46},"end":{"line":18,"column":80}}})) != null ? stack1 : "")
    + "</span>\n            </div>\n            <div class=\"engagement\" title=\"Engagement\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":20,"column":55},"end":{"line":20,"column":103}}})) != null ? stack1 : "")
    + "</div>\n        </a>\n    </div>\n</div>\n\n";
},"useData":true});