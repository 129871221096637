// // Fields are from gammon-lib, documentation mostly from Sorrel.

export const mentionFields = [
    {
        name: "authorBio",
        example: "",
        description: "Author's background or description"
    },
    {
        name: "authorHandle",
        example: "",
        description: "Author's handle or screen name"
    },
    {
        name: "authorHandleId",
        example: "",
        description: "Author's social network ID (e.g. Twitter ID)"
    },
    {
        name: "authorLocation",
        example: "",
        description: "Author's location as received from their network (e.g. Twitter location field)"
    },
    {
        name: "authorName",
        example: "",
        description: "Author's name"
    },
    {
        name: "authorPictureLink",
        example: "",
        description: "Link to author's profile picture"
    },
    {
        name: "authorTimezone",
        example: "",
        description: "Author's timezone as received from their network"
    },
    {
        name: "ave",
        example: "",
        description: "Value of exposure in *aveCurrency* (deprecated)"
    },
    {
        name: "aveCurrency",
        example: "",
        description: "Currency of *ave* (deprecated)"
    },
    {
        name: "aveNote",
        example: "",
        description: "Note about how *ave* was calculated"
    },
    {
        name: "category",
        example: "",
        description: "PRESS, CONSUMER, DIRECTORY or ENTERPRISE"
    },
    {
        name: "categoryNote",
        example: "",
        description: "Note about the *category*"
    },
    {
        name: "city",
        example: "",
        description: "Author's home city"
    },
    {
        name: "content",
        example: "",
        description: "For long mentions this is the complete content, missing for short mentions"
    },
    {
        name: "country",
        example: "",
        description: "Author's home country. Must be a two-letter country code (ISO 3166-1 alpha-2)"
    },
    {
        name: "countryNote",
        example: "",
        description: "Note about the *country*"
    },
    {
        name: "crowdPriority",
        example: "",
        description: "REALTIME, HIGH, NORMAL or LOW"
    },
    {
        name: "extract",
        example: "",
        description: "The interesting part of the mention, for Tweets and other similar sources this is the whole mention, for websites it is generated from text around the search phrases"
    },
    {
        name: "feed",
        example: "",
        description: "The feed that created the mention"
    },
    {
        name: "feedNote",
        example: "",
        description: "Note about the *feed*"
    },
    {
        name: "followerCount",
        example: "",
        description: "The number of accounts following the author"
    },
    {
        name: "followingCount",
        example: "",
        description: "The number of accounts the author is following"
    },
    {
        name: "gender",
        example: "",
        description: "Author's gender"
    },
    {
        name: "genderNote",
        example: "",
        description: "Note about the *gender*"
    },
    {
        name: "generator",
        example: "{\"name\": \"genName\", \"link\": \"gen/link\"}",
        description: "Software the the author used to create the mention (only filled for Tweets)"
    },
    {
        name: "language",
        example: "",
        description: "Language of the mention"
    },
    {
        name: "languageNote",
        example: "",
        description: "Note about the *language*"
    },
    {
        name: "link",
        example: "",
        description: "URL of the mention. Note that mentions with different *uri*'s might have the same *link*. The link is what should be shown to users but click-throughs must go to the *uri*"
    },
    {
        name: "mediaLinks",
        example: "[{\"url\": \"https://jsonformatter.org/\",\"mimeType\": \"text/html\"}]",
        description: "List of images, videos and so on attached to the mention"
    },
    {
        name: "mentionedAuthors",
        example: "[{\"handleId\": 7, \"handle\": \"pete\", \"name\": \"Peter\"}]",
        description: "Author's mentioned in the mention *extract*"
    },
    {
        name: "ots",
        example: "",
        description: "Estimated number of people who may have seen the mention (e.g. follower count for Tweets)"
    },
    {
        name: "otsNote",
        example: "",
        description: "Note about the *ots*"
    },
    {
        name: "postExtract",
        example: "",
        description: "For comments on posts this is the extract of the original post to provide context. For quoted retweets this is the original tweet"
    },
    {
        name: "published",
        example: "",
        description: "When the mention was published"
    },
    // TODO: confirm these are not used anymore
    // {
    //     name: "race",
    //     example: "",
    //     description: "Author's race"
    // },
    // {
    //     name: "raceNote",
    //     example: "",
    //     description: "Note about the *race*"
    // },
    {
        name: "region",
        example: "",
        description: "Author's home region or province (e.g. Western Cape)"
    },
    {
        name: "replyToUri",
        example: "",
        description: "The *uri* of the mention that this mention is replying to, if it is a reply. Note that quoted re-tweets are treated as replies not reshares"
    },
    {
        name: "reshareOfUri",
        example: "",
        description: "The *uri* of the mention that this mention is resharing, if it is a reshare. Note that quoted re-tweets are treated as replies not reshares"
    },
    {
        name: "site",
        example: "",
        description: "Hostname derived from the *link* (preferred) or the *uri* (e.g. twitter.com)"
    },
    {
        name: "statusCount",
        example: "",
        description: "Author's status count when the mention was received"
    },
    {
        name: "tags",
        example: [{
            "id": 200,
            "name": "Dave",
            "namespace": "namespace_for_dave",
            "language": "english",
            "description": "some description for tag",
            "verified": true
        }],
        description: "Tags and topics associated with the mention"
    },
    {
        name: "tagsNote",
        example: "",
        description: "Note about the *tags*"
    },
    {
        name: "title",
        example: "",
        description: "Title if available, mostly only for websites"
    },
    {
        name: "toHandle",
        example: "",
        description: "Handle of account mention is addressed to (for replies and reshares)"
    },
    {
        name: "toHandleId",
        example: "",
        description: "Social nework ID of account mention is addressed to (for replies and reshares)"
    },
    {
        name: "toName",
        example: "",
        description: "Name of the account mention is addressed to (for replies and reshares)"
    },
    {
        name: "uri",
        example: "",
        description: "Unique uri for the mention within this *brand* (see *link*)"
    },
    {
        name: "visibility",
        example: "",
        description: "Direct message or internal comms, missing for public mentions"
    }
];