<template>
    <div class="deq-reset mention-stats">
        <div v-if="loading && mentionCount === null">
            <inline-loading-message class="mention-stats__loading delay-1000 animated fadeIn">
                Examining your mentions
            </inline-loading-message>
        </div>
        <div v-else class="animated fadeIn" :class="{'deq-disabled': loading}">
            <MentionStatsSummary v-if="mentionCount !== null"
                                 :filter="filter"
                                 ref="summaryComponent"
                                 class="mention-stats__summary"/>

            <div class="mention-stats__parent-grid">
                <div class="mention-stats__grid">
                    <template v-if="mentionCount !== null">
                        <tooltip-component>
                            <span class="mention-stats__title">Total mentions</span>
                            <template #tooltip>
                                Your filter matches a total of
                                <strong><num :number="mentionCount"/> {{formatPlural(mentionCount, 'mention')}}</strong>.
                            </template>
                        </tooltip-component>

                        <strong>
                            <num :number="mentionCount"/>
                        </strong>
                    </template>

                    <template v-if="conversationIdCount !== null">
                        <tooltip-with-glossary glossary="glossary:CONVERSATION">
                            <span class="mention-stats__title">Unique conversations</span>
                            <template #tooltip>
                                This filter matches
                                <strong>
                                    <num :number="conversationIdCount"/> {{formatPlural(conversationIdCount, 'conversation')}}
                                </strong>,
                                which are made up of a total of
                                <strong><num :number="mentionCount"/> {{formatPlural(mentionCount, 'mention')}}</strong>.
                            </template>
                        </tooltip-with-glossary>

                        <strong>
                            <num :number="conversationIdCount"/>
                        </strong>
                    </template>

                    <template v-if="authorIdCount > 0">
                        <tooltip-with-glossary glossary="glossary:AUTHOR">
                            <span class="mention-stats__title">Unique authors</span>
                            <template #tooltip>
                                A total of
                                <strong><num :number="authorIdCount"/> {{formatPlural(authorIdCount, 'author')}}</strong>
                                posted <strong><num :number="mentionCount"/> {{formatPlural(mentionCount, 'mention')}}</strong>.
                            </template>
                        </tooltip-with-glossary>

                        <strong>
                            <num :number="authorIdCount"/>
                        </strong>
                    </template>
                </div>

                <div class="mention-stats__grid">
                    <template v-if="twitterCount > 0 && !addendumPresent('𝕏')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is TWITTER', 'Showing mentions from <strong>𝕏</strong>', '𝕏')">
                            from <i class="symbol-twitter"></i>X
                        </be-button>

                        <strong>
                            <num :number="twitterCount"/>
                        </strong>
                    </template>
                    <template v-if="facebookCount > 0 && !addendumPresent('Facebook')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is FACEBOOK', 'Showing mentions from <strong>Facebook</strong>', 'Facebook')">
                            from <i class="symbol-facebook-rect"></i>Facebook
                        </be-button>
                        <strong>
                            <num :number="facebookCount"/>
                        </strong>
                    </template>
                    <template v-if="instagramCount > 0 && !addendumPresent('Instagram')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is INSTAGRAM', 'Showing mentions from <strong>Instagram</strong>', 'Instagram')">
                            from <i class="symbol-instagram"></i>Instagram
                        </be-button>
                        <strong>
                            <num :number="instagramCount"/>
                        </strong>
                    </template>
                    <template v-if="linkedinCount > 0 && !addendumPresent('LinkedIn')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is LINKEDIN', 'Showing mentions from <strong>LinkedIn</strong>', 'LinkedIn')">
                            from <i class="symbol-linkedin-rect"></i>LinkedIn
                        </be-button>
                        <strong>
                            <num :number="linkedinCount"/>
                        </strong>
                    </template>
                    <template v-if="tikTokCount > 0 && !addendumPresent('TikTok')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is TIKTOK', 'Showing mentions from <strong>TikTok</strong>', 'TikTok')">
                            from <i class="symbol-tiktok"></i>TikTok
                        </be-button>
                        <strong>
                            <num :number="tikTokCount"/>
                        </strong>
                    </template>
                    <template v-if="hellopeterCount > 0 && !addendumPresent('Hellopeter')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is HELLOPETER', 'Showing mentions from <strong>Hellopeter</strong>', 'Hellopeter')">
                            from <i class="symbol-hellopeter"></i>Hellopeter
                        </be-button>
                        <strong>
                            <num :number="hellopeterCount"/>
                        </strong>
                    </template>
                    <template v-if="trustpilotCount > 0 && !addendumPresent('Trustpilot')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is TRUSTPILOT', 'Showing mentions from <strong>Trustpilot</strong>', 'Trustpilot')">
                            from <i class="symbol-trustpilot"></i>Trustpilot
                        </be-button>
                        <strong>
                            <num :number="trustpilotCount"/>
                        </strong>
                    </template>
                    <template v-if="googleBusinessCount > 0 && !addendumPresent('GoogleBusiness')">
                        <be-button
                            :disabled="loading"
                            class="mention-stats__title"
                            link
                            @click="
                                updateFilter(
                                    'socialnetwork is GOOGLEBUSINESS',
                                    'Showing mentions from <strong>Google Business</strong>',
                                    'GoogleBusiness'
                                )
                            "
                        >
                            from <i class="symbol-google-full"></i>Google Business
                        </be-button>
                        <strong>
                            <num :number="googleBusinessCount" />
                        </strong>
                    </template>
                    <template v-if="youtubeCount > 0 && !addendumPresent('Youtube')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is YOUTUBE', 'Showing mentions from <strong>Youtube</strong>', 'Youtube')">
                            from <i class="symbol-youtube"></i>Youtube
                        </be-button>
                        <strong>
                            <num :number="youtubeCount"/>
                        </strong>
                    </template>
                    <template v-if="tumblrCount > 0 && !addendumPresent('Tumblr')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is TUMBLR', 'Showing mentions from <strong>Tumblr</strong>', 'Tumblr')">
                            from <i class="symbol-tumblr"></i>Tumblr
                        </be-button>
                        <strong>
                            <num :number="tumblrCount"/>
                        </strong>
                    </template>
                    <template v-if="otherCount > 0 && !addendumPresent('Other social networks')">
                        <be-button class="mention-stats__title"
                                   :disabled="loading"
                                   link @click="updateFilter('socialnetwork isnt TWITTER and socialnetwork isnt FACEBOOK and socialnetwork isnt INSTAGRAM and socialnetwork isnt UNKNOWN and socialnetwork isnt LINKEDIN', 'Showing mentions from <strong>other social media platforms</strong>', 'Other social networks')">
                            from other social
                        </be-button>
                        <strong>
                            <num :number="otherCount"/>
                        </strong>
                    </template>
                    <template v-if="websiteCount > 0 && !addendumPresent('Websites & Blogs')">
                        <be-button  class="mention-stats__title"
                                    :disabled="loading"
                                    link @click="updateFilter('socialnetwork is UNKNOWN', 'Showing mentions from <strong>websites & blogs</strong>', 'Websites & Blogs')">
                            from <i class="symbol-website"></i>Websites
                        </be-button>
                        <strong>
                            <num :number="websiteCount"/>
                        </strong>
                    </template>

                </div>

                <div class="mention-stats__grid">
                    <template v-if="enterpriseCount > 0">
                        <tooltip-with-glossary glossary="glossary:ENTERPRISE">
                            <be-button link
                                       :disabled="loading"
                                       @click="updateFilter(`media is ENTERPRISE`, `Showing <strong>Enterprise mentions</strong>`, 'Enterprise')"
                                       class="mention-stats__title">
                                Enterprise
                            </be-button>

                            <template #tooltip>
                                <p>
                                    Click to see only Enterprise mentions.
                                </p>
                            </template>
                        </tooltip-with-glossary>

                        <strong>
                            <num :number="enterpriseCount"/>
                        </strong>
                    </template>
                    <template v-if="consumerCount > 0">
                        <tooltip-with-glossary glossary="glossary:CONSUMER">
                            <be-button link
                                       :disabled="loading"
                                       @click="updateFilter(`media is CONSUMER`, `Showing <strong>Consumer mentions</strong>`, 'Consumer')"
                                       class="mention-stats__title">
                                Consumer
                            </be-button>

                            <template #tooltip>
                                <p>
                                    Click to see only Consumer mentions.
                                </p>
                            </template>
                        </tooltip-with-glossary>
                        <strong>
                            <num :number="consumerCount"/>
                        </strong>
                    </template>
                    <template v-if="pressCount > 0">
                        <tooltip-with-glossary glossary="glossary:PRESS">
                            <be-button link
                                       :disabled="loading"
                                       @click="updateFilter(`media is PRESS`, `Showing <strong>press mentions</strong>`, 'Press')"
                                       class="mention-stats__title">
                                Press
                            </be-button>

                            <template #tooltip>
                                <p>
                                    Click to see only Press mentions.
                                </p>
                            </template>
                        </tooltip-with-glossary>
                        <strong>
                            <num :number="pressCount"/>
                        </strong>
                    </template>
                </div>

                <div v-if="sortedVisibility.length > 0" class="mention-stats__grid">
                    <template v-for="visibility in sortedVisibility">
                        <be-button :key="'title:' + visibility.id"
                                   link
                                   :disabled="loading"
                                   @click="updateFilter(`visibility is ${visibility.id}`, `Showing <strong>${visibility.label}</strong>`, visibility.label)"
                                   class="mention-stats__title">
                            {{ visibility.label }}
                        </be-button>
                        <strong :key="'value:' + visibility.id">
                            <num :number="visibility.mentionCount"/>
                        </strong>
                    </template>
                </div>

                <div class="mention-stats__grid" v-if="sentimentVerifiedCount">
                    <tooltip-with-glossary glossary="glossary:NET-SENTIMENT">
                        <span class="mention-stats__title">Net sentiment</span>
                    </tooltip-with-glossary>
                    <span>
                        <net-sentiment-calculator :filter="filter"/>
                    </span>
                </div>
                <div v-else-if="sentimentVerifiedCount === 0" class="mention-stats__grid">
                    <span class="mention-stats--span deq-callout--muted">
                        <i class="symbol-info"></i>No verified data
                    </span>
                </div>
            </div>

            <div v-if="addendums.length" class="mention-stats__addendums">
                <h5>Applied sub-filters</h5>
                <div class="mention-stats__addendums-list">
                    <slotted-tag v-for="addendum in addendums"
                                 :key="addendum.label"
                                 :disabled="loading"
                                 @close="removeFilterAddendum(addendum.label)">
                        {{addendum.label}}
                    </slotted-tag>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { features } from "@/app/Features";
import { notifyWithHtml } from "@/app/framework/notifications/Notifications";
import NetSentimentCalculator from "@/app/toplevel/explore/overview/components/NetSentimentCalculator";
import MentionStatsSummary from "@/app/toplevel/mentions/components/MentionStatsSummary.vue";
import { formatPlural } from "@/app/utils/Format";
import InlineLoadingMessage from "@/components/InlineLoadingMessage";
import BeButton from "@/components/buttons/BeButton";
import Number from "@/components/formatters/DeqNumber";
import SlottedTag from "@/components/tags/SlottedTag";
import TooltipComponent from "@/components/tooltip/TooltipComponent";
import TooltipWithGlossary from "@/components/tooltip/TooltipWithGlossary";
import { count } from "@/data/Grouse";
import VuexStore from "@/store/vuex/VuexStore";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex/dist/vuex.esm.browser";

export default {
    name: "MentionStats",
    components: {
        MentionStatsSummary,
        TooltipComponent,
        TooltipWithGlossary, SlottedTag, BeButton, InlineLoadingMessage, NetSentimentCalculator, "Num": Number},
    store: VuexStore,

    data() {
        return {
            features,

            statsPromise: null,
            networksPromise: null,
            visibilityPromise: null,
            mediaPromise: null,

            authorIdCount: null,
            conversationIdCount: null,
            sentimentVerifiedCount: null,
            facebookCount: null,
            instagramCount: null,
            mentionCount: null,
            otherCount: null,
            siteCount: null,
            totalEngagement: null,
            totalOTS: null,
            twitterCount: null,
            linkedinCount: null,
            tikTokCount: null,
            hellopeterCount: null,
            googleBusinessCount: null,
            tumblrCount: null,
            youtubeCount: null,
            trustpilotCount: null,
            websiteCount: null,

            visibility: [],

            enterpriseCount: null,
            consumerCount: null,
            pressCount: null
        }
    },

    computed: {
        ...mapState('mentionPanel', ['previousFilters', 'addendums']),
        ...mapGetters('mentionPanel', ['filter']),

        loading() {
            return !!this.statsPromise || !!this.networksPromise;
        },

        sortedVisibility() {
            if (!this.visibility?.length) return [];
            const addendums = new Set(this.addendums.map(d => d.label));
            return this.visibility
                .map(d => Object.assign({mentionCount: d.mentionCount}, d.visibility || {id: 'PUBLIC', label: "Public mentions"}))
                .filter(d => !addendums.has(d.label) )
                .sort((lhs, rhs) => {
                    if (lhs.id === "PUBLIC") return -1;
                    if (rhs.id === "PUBLIC") return 1;
                    return 0;
                })
        }
    },

    watch: {
        filter() {
            this.calculateCounts();
        }
    },

    created() {
        this.calculateCounts();
    },

    beforeDestroy() {
        if (this.statsPromise) this.statsPromise.cancel();
        if (this.networksPromise) this.networksPromise.cancel();
        if (this.visibilityPromise) this.visibilityPromise.cancel();
        if (this.mediaPromise) this.mediaPromise.cancel();
    },

    methods: {
        formatPlural,

        ...mapMutations('mentionPanel', ['setMentionCount']),
        ...mapActions('mentionPanel', ['setFilter', 'addFilterAddendum', 'removeFilterAddendum']),

        async updateFilter(filter, message, label) {
            const result = await this.addFilterAddendum({label, filter});
            if (result && message) {
                notifyWithHtml(message, () => this.removeFilterAddendum(label), "<i class='symbol-mentions'></i>");
            }
        },

        addendumPresent(label) {
            return this.addendums.find(d => d.label === label);
        },

        async refresh() {
            await Promise.all([this.calculateCounts(), this.$refs.summaryComponent?.refresh()]);
        },

        async calculateCounts() {
            if (this.statsPromise) this.statsPromise.cancel();
            if (this.networksPromise) this.networksPromise.cancel();
            if (this.visibilityPromise) this.visibilityPromise.cancel();

            this.statsPromise = count(this.filter, null,
                ["mention", "authorId", "site","totalOTS","totalEngagement", "conversationIdCount", "sentimentVerifiedCount"], null,
                { excludePrivateMentions: true },
                null,
                false
            );

            this.networksPromise = count(this.filter, ['socialNetwork'], null, null,
                { excludePrivateMentions: true }, null, false);

            this.visibilityPromise = count(this.filter, ['visibility'], null, null,
                { excludePrivateMentions: true }, null, false);

            this.mediaPromise = count(this.filter, ['category'], null, null,
                { excludePrivateMentions: true }, null, false);

            try {
                const [stats, networks, visibility, media] = await Promise.all([this.statsPromise, this.networksPromise, this.visibilityPromise, this.mediaPromise]);
                this.statsPromise = null;
                this.networksPromise = null;
                this.visibilityPromise = null;
                this.mediaPromise = null;

                this.visibility = visibility;

                let twitter = networks.find(d => d.socialNetwork && d.socialNetwork.id === "TWITTER") ?? {mentionCount: 0};
                let facebook = networks.find(d => d.socialNetwork && d.socialNetwork.id === "FACEBOOK") ?? {mentionCount: 0};
                let instagram = networks.find(d => d.socialNetwork && d.socialNetwork.id === "INSTAGRAM") ?? {mentionCount: 0};
                let linkedIn = networks.find(d => d.socialNetwork && d.socialNetwork.id === "LINKEDIN") ?? {mentionCount: 0};
                let tikTok = networks.find(d => d.socialNetwork && d.socialNetwork.id === "TIKTOK") ?? {mentionCount: 0};
                let hellopeter = networks.find(d => d.socialNetwork && d.socialNetwork.id === "HELLOPETER") ?? {mentionCount: 0};
                let tumblr = networks.find(d => d.socialNetwork && d.socialNetwork.id === "TUMBLR") ?? {mentionCount: 0};
                let googleBusiness = networks.find(d => d.socialNetwork && d.socialNetwork.id === "GOOGLEBUSINESS") ?? {mentionCount: 0};
                let youtube = networks.find(d => d.socialNetwork && d.socialNetwork.id === "YOUTUBE") ?? {mentionCount: 0};
                let trustpilot = networks.find(d => d.socialNetwork && d.socialNetwork.id === "TRUSTPILOT") ?? {mentionCount: 0};
                let website = networks.find(d => !d.socialNetwork) ?? {mentionCount: 0};
                let others = networks.map(d => d.mentionCount ?? 0).reduce((lhs, rhs) => lhs + rhs, 0) - twitter.mentionCount - facebook.mentionCount - instagram.mentionCount - website.mentionCount - linkedIn.mentionCount - hellopeter.count - googleBusiness.count - tumblr.mentionCount - youtube.mentionCount;

                this.authorIdCount = stats.authorIdCount ?? 0;
                this.conversationIdCount = stats.conversationIdCount ?? 0;
                this.sentimentVerifiedCount = stats.sentimentVerifiedCount ?? 0;
                this.mentionCount = stats.mentionCount ?? 0;
                this.siteCount = stats.siteCount ?? 0;
                this.totalEngagement = stats.totalEngagement ?? 0;
                this.totalOTS = stats.totalOTS ?? 0;

                this.twitterCount = twitter ? twitter.mentionCount : 0;
                this.facebookCount = facebook ? facebook.mentionCount : 0;
                this.instagramCount = instagram ? instagram.mentionCount : 0;
                this.linkedinCount = linkedIn?.mentionCount ?? 0;
                this.tikTokCount = tikTok?.mentionCount ?? 0;
                this.websiteCount = website.mentionCount ?? 0;
                this.hellopeterCount = hellopeter.mentionCount ?? 0;
                this.googleBusinessCount = googleBusiness.mentionCount ?? 0;
                this.tumblrCount = tumblr.mentionCount ?? 0;
                this.youtubeCount = youtube.mentionCount ?? 0;
                this.trustpilotCount = trustpilot.mentionCount ?? 0;
                this.otherCount = others;
                this.setMentionCount(this.mentionCount);
                this.enterpriseCount = media.find(d => d.category?.id === "ENTERPRISE")?.mentionCount ?? 0;
                this.consumerCount = media.find(d => d.category?.id === "CONSUMER")?.mentionCount ?? 0;
                this.pressCount = media.find(d => d.category?.id === "PRESS")?.mentionCount ?? 0;
            } catch(e) {
                if (e.status !== 422) {
                    console.error(e);
                }

                // Don't show broken data if there was an error.
                this.authorIdCount = null;
                this.conversationIdCount = null;
                this.facebookCount = null;
                this.instagramCount = null;
                this.mentionCount = null;
                this.otherCount = null;
                this.siteCount = null;
                this.totalEngagement = null;
                this.totalOTS = null;
                this.twitterCount = null;
                this.trustpilotCount = null;
                this.linkedinCount = null;
                this.tikTokCount = null;
                this.hellopeterCount = null;
                this.googleBusinessCount = null;
                this.websiteCount = null;
                this.visibility = null;
                this.enterpriseCount = null;
                this.consumerCount = null;
                this.pressCount = null;
            } finally {
                this.statsPromise = null;
                this.networksPromise = null;
                this.visibilityPromise = null;
                this.mediaPromise = null;
            }
        }
    }
}
</script>


<style scoped lang="sass">

.mention-stats
    max-width: 300px
    margin-inline: auto


.mention-stats__loading
    display: block
    text-align: center

.mention-stats__grid
    //margin-inline: auto
    //max-width: 80%
    grid-column: 1 / span 2
    display: grid

    // Firefox supports sub-grid. Chrome might finally soon.
    // First line is fallback for browsers that do not support sub-grid.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=618969
    //noinspection CssOverwrittenProperties
    grid-template-columns: auto minmax(75px, auto)
    //noinspection CssOverwrittenProperties
    //grid-template-columns: subgrid // subgrid seems to be broken in chromium >= 117. See if this works in the future
    justify-content: center
    grid-auto-rows: 1.5em
    column-gap: 10px
    justify-items: baseline

.mention-stats__parent-grid
    display: grid
    grid-template-columns: auto minmax(75px, auto)
    margin-inline: auto
    justify-content: center

.mention-stats__grid + .mention-stats__grid
    margin-top: 20px

.mention-stats__title
    justify-self: right
    white-space: nowrap
    ::v-deep .btn
        padding: 0
        margin: 0 0 2px

.mention-stats__addendums
    margin-top: 20px
    margin-inline: auto
    padding-left: 20px
    max-width: 250px
    h5
        text-align: left
        margin: 0 0 5px
        padding: 0

.mention-stats__addendums-list
    display: flex
    flex-wrap: wrap
    justify-content: left
    > *
        margin-right: 5px
        margin-bottom: 5px

.mention-stats--span
    grid-column: 1 / -1
    align-content: center
    text-align: center


.mention-stats__summary
    display: block
    min-height: 250px
    padding-left: 3ch // to make this look more centred with the off-centre stats section
    margin-bottom: 1rem

</style>