import {showTooltipText} from "@/components/tooltip/TooltipUtilities";

export class CustomInlineTool {
    static get isInline() {
        return true;
    }

    get state() {
        return this._state;
    }

    set state(state) {
        this._state = state;
        this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }

    constructor({api, config}) {
        this.api = api;
        this.button = document.createElement('button');
        this._state = false;
        this.filter = config.filter;

        this.tag = 'COMMAND';
        this.class = 'cdx-marker';
        this.isActionsOpened = false;

        if (this.toolTipText() !== ""){
            this.button.addEventListener('mouseover', () => {
                showTooltipText(this.button, this.toolTipText(), {});
            })
        }

        window.addEventListener('mouseup', (e) => {
            if (this.isActionsOpened && e.target.closest('button') !== this.button){
                this.isActionsOpened = false;
                this.hideActions();
                this.button.classList.remove('commentary-inline-btn-active');
            }
        });
    }

    render() {
        this.button.type = 'button';
        this.button.id = 'btn-' + this.getBtnName();
        this.button.innerText = this.getBtnName();
        this.button.classList.add(this.api.styles.inlineToolButton);
        this.button.classList.add('commentary-inline-btn');

        return this.button;
    }

    surround(range) {
        this.range = range;
        this.isActionsOpened = !this.isActionsOpened;
    }

    checkState(selection) {
        if (this.isActionsOpened) {
            this.showActions();
        } else {
            this.hideActions();
        }
    }

    renderActions() {
        this.commandOptions = document.createElement("div");
        this.commandOptions.classList.add("commentary-inline-actions");

        this.getBtnOptions().forEach((option) => {
            let addCommandBtn = document.createElement("button");
            if (option.innerHtml){
                addCommandBtn.innerHTML = option.innerHtml;
            } else {
                addCommandBtn.innerText = option.text;
            }
            addCommandBtn.type = "button";
            addCommandBtn.classList.add('commentary-inline-action-btns');

            addCommandBtn.addEventListener("click", () => {
                this.range.deleteContents();

                this.range.insertNode(document.createTextNode("\u200b"));
                const container = document.createElement('command-element');
                container.classList.add('deq-count');
                container.dataset.command = option.command;
                container.dataset.filter = this.filter;
                container.dataset.readonly = 'false';
                container.dataset.commandtype = this.getBtnName();

                this.range.insertNode(container);

                this.isActionsOpened = false;

                this.checkState();
            });
            this.commandOptions.appendChild(addCommandBtn);
        });

        return this.commandOptions;
    }

    showActions(mark) {
        this.commandOptions.hidden = false;
        this.button.classList.add('commentary-inline-btn-active');
    }

    hideActions() {
        this.commandOptions.onchange = null;
        this.commandOptions.hidden = true;
    }

    static get sanitize() {
        return {
            'count-element': {
                class: 'deq-count',
                'data-command': true,
                'data-filter': false
            }
        };
    }

    getBtnOptions() {
        return [];
    }

    getBtnName() {
        return "";
    }

    toolTipText() {
        return "";
    }
}
