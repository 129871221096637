<template>
    <dialog-box class="be-mention-dialog"
                @close="$emit('close')"
                :title="title"
                width="clamp(700px, 80vw, 900px)"
                overlay>
        <section v-if="firstLoad && loading" class="be-mention-dialog__container">
            <LoadingMessage class="be-mention-dialog__all-loading">
                Reading your mentions...
            </LoadingMessage>
        </section>
        <section v-else class="be-mention-dialog__container">
            <section class="be-mention-dialog__left-container">
                <section class="be-mention-dialog__mentions-summary-section">
                    <h4>What are these mentions about?</h4>
                    <div class="be-mention-dialog__mentions-summary">
                        <p v-if="notEnoughMentionsToSummarise">
                            Not enough mentions to summarise
                        </p>
                        <WithSummary v-else :filter="filter"/>
                    </div>
                </section>

                <section v-show="!themesLoading" class="be-mentions-dialog__themes">
                    <h4>Themes</h4>
                    <MentionThemes single-theme-select
                                   class="be-mentions-dialog__mention-themes"
                                   :filter="filter"
                                   @loading="themesLoadingHandler($event)"
                                   @select-theme="selectTheme"
                                   @unselect-theme="unselectTheme"/>
                </section>
            </section>
            <section class="be-mention-dialog__right-container">
                <div>
                    <h4>
                        {{ stateTitle }}
                    </h4>

                </div>
                <section class="be-mention-dialog__state-container dark-scrollbars dark-scrollbars--visible">
                    <LoadingMessage v-if="loading">
                        Loading mentions...
                    </LoadingMessage>
                    <section v-else-if="state === 'MENTIONS' && !error" class="be-mention-dialog__example-mentions-container short-animated fadeIn">
                        <div v-for="mention in mentions" :key="mention.id">
                            <short-mention class="be-mention-dialog__example-mention"
                                           :mention="mention"/>
                        </div>
                    </section>
                    <section v-else-if="state === 'WORDCLOUD' && !error" ref="cloud" class="be-mention-dialog__word-cloud-container"/>
                    <section v-if="error" class="be-mention-dialog__error">
                        <h1>{{error}}</h1>
                    </section>
                </section>
                <section class="options">
                    <be-button link v-if="state === 'MENTIONS'" @click="state = 'WORDCLOUD'" :disabled="loading">As word cloud</be-button>
                    <be-button link v-if="state === 'WORDCLOUD'" @click="state = 'MENTIONS'" :disabled="loading">As mentions</be-button>
                </section>
            </section>
        </section>

        <template v-slot:buttons>
            <section class="be-mention-dialog__buttons">
                <be-button link style="margin-left: auto" @click="toAuthors"
                           tooltip="Explore the author's of these mention's in the author panel">
                    See authors
                </be-button>
                <be-button @click="toMentions"
                           @option="option"
                           tooltip="Explore these mentions in the mention's panel"
                           :options="options">
                    See mentions
                </be-button>
            </section>
        </template>

    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import BeButton from "@/components/buttons/BeButton";
import {count, getMentions} from "@/data/Grouse";
import ShortMention from "@/components/mentions/ShortMention";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import {WordCloudChart} from "@/app/utils/WordCloud";
import {showTooltipHtml} from "@/components/tooltip/TooltipUtilities";
import {getLdaTopicsFromCaper} from "@/data/Caper";
import {getPalette} from "@/app/utils/Colours";
import {createSimpleDashboardForFilter} from "@/app/toplevel/dashboards/BeefModuleDashboardUtilities";
import {formatNumber} from "@/app/utils/Format";
import {escapeExpression} from "@/app/utils/StringUtils";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import {editMentions} from "@/app/Permissions";
import {showTagMentionDialog} from "@/app/framework/dialogs/Dialog";
import {mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {isDebugModeEnabled} from "@/app/Features";
import WithSummary from "@/app/toplevel/explore/overview/components/summaries/WithSummary.vue";
import MentionThemes from "@/app/framework/dialogs/mentions/MentionThemes.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";

export default {
    components: {LoadingMessage, MentionThemes, WithSummary, ShortMention, BeButton, DialogBox},
    store: VuexStore,

    props: {
        title: {
            type: String,
            default: "Exploring mentions"
        },
        filter: {
            type: String,
            required: true
        },
        noLda: {
            type: Boolean,
            default: true
        },
        initialState: {
            type: String
        }
    },

    data() {
        return {
            loading: false,
            firstLoad: true,
            themesLoading: false,
            error: null,

            state: this.initialState ?? "MENTIONS",
            mentions: null,
            exampleMentions: [],
            curFilter: this.filter,
            selectedTheme: null,
            words: null,
            cloud: null,

            mentionLimit: 8,

            debug: isDebugModeEnabled()
        }
    },

    computed: {
        ...mapState(['account']),

        options() {
            const menu = [
                {
                    key: "dashboard",
                    title: "Create dashboard",
                    tooltip: "Create a dashboard to examine these mentions closer",
                    action: () => {
                        this.$emit('close');
                        createSimpleDashboardForFilter(this.filter, null, this.title);
                    }
                }
            ];

            if (editMentions()) {
                menu.push({
                    key: "tagging",
                    title: "Tag mentions...",
                    tooltip: "Tag these mentions",
                    action: () => {
                        this.$emit('close');
                        showTagMentionDialog(this.filter);
                    }
                })
            }

            return menu
        },

        stateTitle() {
            if (!this.selectedTheme) {
                return this.state === "MENTIONS" ? "Example mentions" : "Word cloud";
            }
            return this.selectedTheme.title;
        },

        notEnoughMentionsToSummarise() {
            return !this.loading && this.mentions && this.mentions.length === 1;
        }
    },

    watch: {
        async state(state) {
            await this.onStateChange(state);
        },
        loading() {
            if (!this.loading) this.firstLoad = false;
        }
    },

    async mounted() {
        await this.loadData();
        if (this.state === 'WORDCLOUD') this.$nextTick(() => this.showCloud());
    },

    methods: {
        themesLoadingHandler(value) {
            this.themesLoading = value;
        },
        async onStateChange(state) {
            try {
                this.loading = true;

                switch (state) {
                    case "MENTIONS": {
                        const target = this.$refs?.cloud;
                        if (target) target.innerHTML = '';

                        this.loading = true;
                        if (this.selectedTheme) {
                            this.mentions = await this.fetchMentionsFromGrouse(20);
                        } else {
                            this.mentions = this.exampleMentions;
                        }
                        break;
                    }
                    case "WORDCLOUD":
                        this.$nextTick(async () => {
                            this.words = await count(this.curFilter, ["extractWord"], ["mentionCount"], 140, {stopWords: true});
                            this.showCloud()
                        });
                        break;
                    default:
                        console.warn("Invalid state: ", state);
                }
            } catch (e) {
                console.error("Error data for state " + state + ": ", e)
            } finally {
                this.loading = false;
            }
        },

        async loadData() {
            if (this.loading) return;
            if (!this.filter) return;

            try {
                this.loading = true;
                this.error = null;

                switch (this.state) {
                    case "MENTIONS":
                        if (!this.exampleMentions?.length) {
                            if (!this.noLda) {
                                let topics = await this.fetchLdaTopics();
                                if (topics?.length) {
                                    this.exampleMentions = topics;
                                } else {
                                    this.exampleMentions = await this.fetchMentionsFromGrouse();
                                }
                            } else {
                                this.exampleMentions = await this.fetchMentionsFromGrouse();
                            }
                        }
                        this.mentions = this.exampleMentions;
                        break;
                    case "WORDCLOUD":
                        this.words = await count(this.curFilter, ["extractWord"], ["mentionCount"], 140, {stopWords: true});
                        break;
                    default:
                        console.error("Unknown state to load: " + this.state);
                }

            } catch (e) {
                console.error(e);
                this.error = "There was a problem fetching your mentions";
            } finally {
                this.loading = false;
            }
        },

        async fetchLdaTopics() {
            const limit = 8;
            let topics = [];

            try {
                let options = {
                    filter: this.filter,
                    limit: limit,
                    mentionLimit: 2000
                }

                topics = await getLdaTopicsFromCaper(options);
            } catch(e) {
                console.error("Error reading lda topics", e);
            }

            if (topics?.topics?.length <= 5) {
                return [];
            }

            return topics;
        },

        async fetchMentionsFromGrouse(mentionLimit) {
            mentionLimit ??= this.mentionLimit;
            try {
                return await getMentions(this.curFilter, ["totalEngagement desc"], mentionLimit, true)
            } catch (e) {
                console.error("Error fetching mentions", e);
                if (e.status === 422) {
                    this.error = e.responseText.indexOf("BRAND-ERROR") >= 0 ? "Please select a brand" : "There is a problem with your filter";
                    return [];
                }
                switch (e.status) {
                    case 0:
                        this.error = "There was a problem fetching your mentions. You filter may be too long."; break;
                    default:
                        this.error = "There was a problem fetching your mentions";
                }

                return [];
            }
        },

        async selectTheme(theme) {
            if (theme.id === this.selectedTheme?.id) return;

            this.loading = true;
            this.selectedTheme = theme;
            let mentionIds = theme?.mentionIds;

            if (mentionIds?.length) {
                // limit IDs to avoid filter being too long
                if (mentionIds.length > 150) mentionIds = mentionIds.slice(0, 150);

                this.curFilter = theme.title ? `ID IN @'related to ${theme.title.replace(`'`, "")}'(${mentionIds.map(id => `'${id}'`).join(",")})` : `ID IN (${mentionIds.map(id => `'${id}'`).join(",")})`;

                if (this.state === 'WORDCLOUD') {
                    const target = this.$refs?.cloud;
                    if (target) target.innerHTML = '';

                    this.$nextTick(async () => {
                        this.words = await count(this.curFilter, ["extractWord"], ["mentionCount"], 140, {stopWords: true});
                        this.showCloud()
                    });
                } else {
                    this.mentions = await this.fetchMentionsFromGrouse(20);
                }

                this.loading = false;
            }
        },

        async unselectTheme() {
            this.loading = true;
            this.selectedTheme = null;

            this.curFilter = this.filter;

            if (this.state === 'WORDCLOUD') {
                const target = this.$refs?.cloud;
                if (target) target.innerHTML = '';

                this.$nextTick(async () => {
                    this.words = await count(this.curFilter, ["extractWord"], ["mentionCount"], 140, {stopWords: true});
                    this.showCloud()
                });
            } else {
                if (this.exampleMentions.length) {
                    this.mentions = this.exampleMentions;
                } else {
                    this.mentions = await this.fetchMentionsFromGrouse();
                }
            }

            this.loading = false;
        },

        toMentions() {
            this.$emit('close');
            gotoMentionPanel(this.curFilter, "engagement desc");
        },

        toAuthors() {
            this.$emit('close');
            gotoAuthorsPanel(this.curFilter);
        },

        showCloud() {
            const target = this.$refs?.cloud;
            if (!target) return;

            if (!this.cloud) {
                this.cloud = new WordCloudChart();

                const wordToData = {};
                this.words.forEach(d => wordToData[d.extractWord] = d);

                this.cloud.dispatch().on('tooltipShow', (d, node) => {
                    const data = wordToData[d.text];
                    if (!data) return;

                    // If there is no group or group contains only one word, we use the normal tooltip.
                    showTooltipHtml(
                        node,
                        `<strong>${escapeExpression(data.extractWord)}</strong>
                                occurs in
                             <strong><span class="number">${formatNumber(data.mentionCount)}</span> unique mentions</strong>.`
                    )
                });
            }
            target.innerText = '';

            this.cloud
                .element(target)
                .width(target.clientWidth)
                .height(target.clientHeight - 5)
                .x(d => d.extractWord )
                .y(d => d.mentionCount )
                .data(this.words)
                .layout('archimedean')
                .scale('square')
                .minFont(9)
                .maxFont(70)
                .colours(getPalette("RDPU9"))
                .render();
        },

        option(ev) {
            if (ev.action) ev.action();
        }
    }
};
</script>

<style lang="sass">

.be-mention-dialog .word:hover
    cursor: default
</style>


<style scoped lang="sass">



.be-mention-dialog
    &__container
        --_padding: 10px
        height: 70vh
        min-height: 400px

        display: grid
        grid-template-areas: "summary mentions" "themes mentions"
        grid-template-columns: 40% 60%
        grid-template-rows: minmax(10ch, 60%) minmax(10%, 60%)

        p
            color: var(--be-colour-text-dark)

    &__left-container
        display: contents

    &__right-container
        padding: var(--_padding)
        grid-area: mentions
        padding-left: 10px
        display: flex
        flex-direction: column
        border-left: thin solid #666

        .options
            margin-top: 0.5rem
            margin-left: auto

            > *:last-child
                ::v-deep .btn-link
                    padding-right: 0

    &__mentions-summary-section
        grid-area: summary
        padding: var(--_padding) var(--_padding) 0

    &__mentions-summary
        padding: 0 5px
        color: var(--be-colour-text-dark)

        ::v-deep .summary-text
            animation-duration: 400ms
            animation-fill-mode: both
            animation-name: fadeIn

    &__state-container
        --loading-message-delay: 250ms
        overflow-y: auto
        overflow-x: hidden
        padding: 0 5px
        color: var(--be-colour-text-dark)
        height: 100%

    &__example-mentions-container
        display: flex
        flex-direction: column
        row-gap: 5px

    &__example-mention
        border-radius: 4px

    &__word-cloud-container
        width: 100%
        height: 100%
        background: white
        border-radius: 4px
        box-sizing: border-box

    &__buttons
        padding: 10px
        border-top: var(--border-separation)
        background: var(--background-dialog-buttoncontainer)
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px
        display: flex
    //justify-content: flex-end

    &__error
        display: grid
        place-content: center
        height: 100%
        text-align: center
        color: var(--be-colour-warning)

.be-mention-dialog__all-loading
    grid-row: 1 / -1
    grid-column: 1 / -1
    --loading-message-margin-top: 0
    --loading-message-delay: 500ms

.be-mentions-dialog__themes
    grid-area: themes
    display: flex
    flex-direction: column
    padding: var(--_padding)

.be-mentions-dialog__mention-themes
    padding-left: 5px

::v-deep .dialog-body
    padding: 0

::v-deep .dialog-button-bar
    margin: 0
    box-sizing: border-box

::v-deep .inline-loading-message__alignment
    display: flex
    justify-content: center
</style>