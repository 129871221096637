Beef.module("VolumeWarningHelp").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/setup/brands/VolumeWarningHelp.handlebars"),

        attributes: { class: "volume-warning-help dialog" },

        events: {
            "click a": "linkClick"
        },

        initialize: function() {
            this.accountCode = this.options.accountCode;
            for (this.rootBrand = this.model.attributes; this.rootBrand._parent; this.rootBrand = this.rootBrand._parent);
        },

        templateHelpers: function() {
            var vol = this.model.get('volume');
            var dp = vol && vol.total && vol.discard ? dp = Math.floor(vol.discard * 100 / vol.total) : 0;
            var phrase = this.model.get('q');
            var id = this.model.get('id');
            var filter = Beef.RootBrandSetup.createMentionsFilter(phrase ? null : id, phrase ? id : null, false);
            var mentionsLink = "/" + this.accountCode + "/mentions?filter=" + encodeURIComponent(filter);

            return {
                rootBrand: this.rootBrand,
                accountCode: this.accountCode,
                discardPercentage: dp,
                mentionsLink: mentionsLink
            }
        },

        linkClick: function(ev) {
            var href = $(ev.target).closest("a").attr('href');
            if (href) {
                ev.preventDefault();
                this.trigger("navigate", href);
            }
        }
    });

});