var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"author\">\n        <img class=\"avatar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":55}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":106}}})) != null ? stack1 : "")
    + " src=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n\n        <div class=\"content\" title=\"Click to edit the mention\">\n            <div class=\"name\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n            <div class=\"screen-name\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "</div>\n        </div>\n\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " edit-author";
},"4":function(container,depth0,helpers,partials,data) {
    return "title=\"Click to edit the author\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../helpers/withMentionAuthor.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"withMentionAuthor","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":11,"column":22}}})) != null ? stack1 : "");
},"useData":true});