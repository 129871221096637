var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"feed-notify\">\n    <div><span class=\"feed-notify-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"popupMessage") || (depth0 != null ? lookupProperty(depth0,"popupMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"popupMessage","hash":{},"data":data,"loc":{"start":{"line":2,"column":40},"end":{"line":2,"column":56}}}) : helper)))
    + "</span></div>\n    <div>\n        <a href=\"#\" class=\"feed-notify-ack feed-notify-message-yes-option\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"acceptPhrase") || (depth0 != null ? lookupProperty(depth0,"acceptPhrase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acceptPhrase","hash":{},"data":data,"loc":{"start":{"line":4,"column":75},"end":{"line":4,"column":91}}}) : helper)))
    + "</a>\n        <a href=\"#\" class=\"feed-notify-ack feed-notify-message-no-option\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"declinePhrase") || (depth0 != null ? lookupProperty(depth0,"declinePhrase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"declinePhrase","hash":{},"data":data,"loc":{"start":{"line":5,"column":74},"end":{"line":5,"column":91}}}) : helper)))
    + "</a>\n    </div>\n</div>";
},"useData":true});