import VuexStore from "@/store/vuex/VuexStore";

function getKey() {
    const accountCode = VuexStore.state.account.code;
    const user = VuexStore.state.user;
    return accountCode + `analyse:${user.id}:${accountCode}:recent-mentions`;
}

/**
 * Keeps track of recently viewed mentions in local storage.
 */
export function recordRecentMention(model) {
    try {
        let a = [Beef.Sync.cloneModel(model.toJSON())];

        let key = getKey();
        let json = null;
        try {
            json = localStorage.getItem(key);
        } catch (e) {
            console.warn("Error attempting to read recent mentions. Clearing cache", e);
            try {
                localStorage.removeItem(key)
            } catch (ignore) {}
            return;
        }


        if (json) {
            let list = JSON.parse(json);
            let id = a[0].id;
            for (let i = 0; i < list.length && a.length < 30; i++) {
                let m = list[i];
                if (m.id !== id) a.push(m);
            }
        }

        try {
            localStorage.setItem(key, JSON.stringify(a));
        } catch(e) {
            console.warn("error logging recent mentions. Clearing cache.", e);
            try {
                localStorage.removeItem(key)
            } catch (ignore) {}
        }
    } catch(e) {
        console.warn("Error logging recent mentions", e);
    }
}


export function getRecentMentions() {
    try {
        let json = localStorage.getItem(getKey());
        return json ? JSON.parse(json) : [];
    } catch (e) {
        console.warn("Unable to get recent mentions.", e);
        return [];
    }
}

