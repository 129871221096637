var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"machine-sentiment-region\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"crowd-history-region\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<main class=\"deq-reset\">\n"
    + ((stack1 = container.invokePartial(require("../../app/Header.handlebars"),depth0,{"name":"../../app/Header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"crowd-settings-region\"></div>\n\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(alias1,{"name":"ifMashAdmin","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":20}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(alias1,{"name":"ifMashAdmin","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":20}}})) != null ? stack1 : "")
    + "\n</main>\n";
},"usePartial":true,"useData":true});