/**
 * Convert an ZAR value (e.g. AVE) to the currency for the account and format it with currency symbol. The value is
 * rendered approximately (e.g. '$ 1.2m') unless exact is true.
 */
import {getAccountCurrency, getRateFromZar} from "@/app/utils/Currency";
import {formatMoney} from "@/app/utils/Format";

export default function(zar, exact, context) {
    if (context == undefined) exact = false; // this happens when we are invoked with only one arg
    var id = 'zar-value-' + (++zarValueId);
    var text, title = null;
    getRateFromZar(getAccountCurrency(), function(rate) {
        text = formatMoney(zar * rate, getAccountCurrency());
        if (!exact) {
            title = text;
            text = formatMoney(zar * rate, getAccountCurrency(), {si: true});
        }
        var $span = $('#' + id);
        $span.text(text);
        if (title) $span.attr('title', title);
    });
    if (text) {
        text = "<span" + (title ? " title='" + title + "'>" : ">") + text + "</span>";
    } else {
        text = "<span id='" + id + "'>...</span>";
    }
    return new Handlebars.SafeString(text);
}

var zarValueId = 0;
