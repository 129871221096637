/**
 * An editor allowing someone to choose colours for a metric. This gives the user
 * three choices: allow a random colour scheme (the default), choose from some predefined schemes,
 * or completely set your own colours. This makes use of the
 * spectrum colour picker (http://bgrins.github.io/spectrum/).
 */
import {defaultCustom, palettes, toPaletteOptions} from "@/app/utils/Colours";

Beef.module("ColourSettings").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({

        template: require("@/dashboards/ColourSettings.handlebars"),

        attributes: { class: "widget-colour-settings" },

        events: {
            "click .swatch":                'swatchClicked',
            "click .reset-custom":          'resetCustomPalette',
            "click .use-dashboard-colours": 'useDashboardColours',
            "click .edit-custom-as-hex":    'editCustomPaletteAsHex',
            "change .colour-cycle":         'colourCycleChanged'
        },

        modelEvents: {
            "change": "updateView"
        },

        templateHelpers: function() {
            return {
                _palettes: palettes,
                _custom: defaultCustom,
                _useDefaultText: this.options.useDefaultText || 'Use Dashboard Colours',
                _useDefaultTitle: this.options.useDefaultTitle || 'Use colour palette selected in Dashboard Settings'
            }
        },

        swatchClicked: function(ev) {
            var $sw = $(ev.target).closest(".swatch");
            var $p = $sw.closest(".palette");
            this.model.set({
                'colour-palette': $p.attr('data-id'),
                'colour-index': parseInt($sw.attr('data-index'))
            });
        },

        getPaletteOptions: function() {
            var defs = this.options.dashboardModel ? this.options.dashboardModel.attributes : null;
            return toPaletteOptions(this.model.attributes, defs);
        },

        updateView: function(e) {
            var o = this.getPaletteOptions();
            var pid = o['colour-palette'];
            var i = o['colour-index'];
            this.$('.swatch').toggleClass('selected', false);
            this.$('.custom').toggleClass('selected', false);
            if (pid == "custom") this.$('.custom[data-index="' + i + '"]').toggleClass('selected', true);
            else this.$('.palette[data-id="' + pid +'"] .swatch[data-index="' + i + '"]').toggleClass('selected', true);

            var p = o['colour-palette-custom'];
            var len = defaultCustom.length;
            if (p.length < len) p.concat(defaultCustom.slice(p.length));
            else if (p.length > len) p = p.slice(0, len);
            this.$('.colour-definition').each(function(index, obj) { $(obj).spectrum('set', p[index]); });

            this.$('.colour-cycle').attr('checked', !this.model.attributes['colour-cycle-disabled']);
        },

        onFirstRender: function() {
            var that = this;

            this.$('.colour-definition').each(function(index, obj) {
                $(obj).spectrum({
                    showInput: true,
                    clickoutFiresChange: true,
                    show: function() {
                        that.selectCustomColour(index);
                        // Our dialog will pick up mouse down events that are not in its container hierarchy, and then
                        // close the dialog. We want to swallow those unnecessary mousedowns.
                        $('.sp-picker-container').bind('mousedown', function(ev) { ev.stopPropagation(); });
                    },
                    change: function(colour) {
                        that.selectCustomColour(index, colour.toHexString());
                    }
                });
            });

            // Here we want to close the coloured boxes if the user clicks escape.
            $(document).bind('keyup', function(ev) {
                if (ev.keyCode == 27 && that.$el.is(":visible")) { that.$('.colour-definition').spectrum('hide'); }
            });

            this.updateView();
        },

        selectCustomColour: function(index, colour) {
            if (colour) {
                let localDefault = (this.options.dashboardModel ? this.options.dashboardModel.get('colour-palette-custom') : null)
                if (!localDefault) localDefault = defaultCustom
                let cp = this.model.get('colour-palette-custom')
                if (cp) {
                    cp[index] = colour
                    if (arrayEq(cp, localDefault)) cp = null
                } else if (localDefault[index] != colour) {
                    cp = localDefault.slice(0)
                    cp[index] = colour
                }
                this.model.set({ 'colour-palette-custom': cp, 'colour-palette': 'custom', 'colour-index': index })
            } else {
                this.model.set({ 'colour-palette': 'custom', 'colour-index': index })
            }
        },

        onClose: function() {
            this.$('.colour-definition').spectrum('destroy');
            $('.sp-container').remove();
        },

        resetCustomPalette: function(ev) {
            ev.preventDefault();
            if (!window.confirm("Are you sure you want to reset all the colours in the custom palette to defaults?")) return;
            this.model.set('colour-palette-custom', null);
        },

        editCustomPaletteAsHex: function(ev) {
            ev.preventDefault();
            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["bottom-right", "center"] });
            popup.setTarget($(ev.target));

            var text = this.getPaletteOptions()['colour-palette-custom'].join("\n");
            var m = new Backbone.Model({text: text});
            m.save = function() { };
            var that = this;
            m.on("change", function(){ that.setCustomPaletteHexCodes(m.get('text')); });

            var view = new EditHexView({model: m});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        setCustomPaletteHexCodes: function(text) {
            var a = text.split(/\s+/);
            var p = this.getPaletteOptions()['colour-palette-custom'].slice(0);
            for (var i = 0; i < a.length && i < p.length; i++) {
                var c = a[i];
                if (c.match(/#?[0-9a-z][0-9a-z][0-9a-z][0-9a-z]?[0-9a-z]?[0-9a-z]?/i)) {
                    if (c.charAt(0) != '#') c = '#' + c;
                    p[i] = c;
                }
            }
            this.model.set('colour-palette-custom', p);
        },

        useDashboardColours: function(ev) {
            ev.preventDefault();
            // don't set these to undefined or things don't repaint properly
            this.model.set({ 'colour-palette-custom': null, 'colour-palette': null, 'colour-index': null });
        },

        colourCycleChanged: function() {
            this.model.set('colour-cycle-disabled', !this.$('.colour-cycle').is(":checked"));
        }
    });

    var EditHexView = Beef.SettingsDialog.View.extend({
        template: require("@/dashboards/ColourSettingsEditHex.handlebars"),
        attributes: { class: "colour-settings-edit-hex dialog" },
        focusSelector: "textarea"
    });

});

function arrayEq(a, b) {
    if (!a && !b) return true
    if (a && !b || !a && b) return false
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) if (a[i] != b[i]) return false
    return true
}

