<template>
    <tr :class="['account-list__row', account.status, account.inactive ? 'INACTIVE' : '']" @keypress.enter="goDashboard">
        <td class="code"><a :href="dashboardLink" tabindex="-1">{{account.code}}</a></td>
        <td @click="goDashboard" @mouseup.middle="goTab">
            <div class="name">
                <a :href="dashboardLink" @click.stop class="name--constrain" tabindex="-1">
                    {{account.name}}
                    <br>
                    <div v-if="flags.length && user.admin">
                        <slotted-tag no-close v-for="flag in flags" :key="flag" :class="['flag', flag.toUpperCase().replaceAll(' ', '-')]" :tooltip="flagTooltip(flag)">
                            <i v-if="flagIcon(flag)" :class="flagIcon(flag)"></i>
                            {{flag}}
                        </slotted-tag>
                    </div>
                </a>
                <span class="mash-link" v-if="user.admin">
                    <a :href="mashLink" target="_blank" tooltip="Open in mash" @click.stop tabindex="-1">
                        [MASH]
                    </a>
                </span>
            </div>
        </td>
        <td v-if="user.admin" class="detail no-small-screen"><a :href="dashboardLink" tabindex="-1">{{account.region}}</a></td>
        <td v-if="user.admin" class="detail no-small-screen">
            <a :href="dashboardLink" class="detail--constrain" style="max-width: clamp(50px, 10vw, 120px)" tabindex="-1">{{account.vertical}}</a>
        </td>
        <td v-if="user.admin" class="detail no-small-screen"><a :href="dashboardLink" tabindex="-1">{{account.accountType}}</a></td>
        <td v-if="user.admin" class="detail no-small-screen">
            <a :href="dashboardLink" tabindex="-1">
                <old-tooltip :label="account.clientName">
                    {{account.clientCode}}
                    <br>
                    <span class="detail--small detail--constrain">{{account.clientName}}</span>
                </old-tooltip>
            </a>
        </td>
        <td v-if="user.admin" class="detail no-small-screen"><a :href="dashboardLink" :class="[account.status]" tabindex="-1">{{account.status}}</a></td>
        <td v-if="user.admin" class="detail no-small-screen"><a :href="dashboardLink" tabindex="-1">{{clientService}}</a></td>
        <td v-if="user.admin" class="detail no-small-screen">
            <span v-if="account.hasEngage">
                <animated-check/>
            </span>
        </td>
        <td v-if="user.admin" class="detail no-small-screen">
            <span v-if="account.hasReporting">
                <animated-check/>
            </span>
        </td>
        <td v-if="user.admin" class="detail no-small-screen">
            <span v-if="account.hasFacebookDataAuthorisation">
                <animated-check/>
            </span>
        </td>

    </tr>
</template>

<script>
import OldTooltip from "@/components/tooltip/OldTooltip";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import SlottedTag from "@/components/tags/SlottedTag";

export default {
        name: "AccountListRow",
        components: {OldTooltip, SlottedTag, AnimatedCheck},
        props: {
            account: Object,
            user: Object
        },

        methods: {
            goDashboard() {
                window.location = this.dashboardLink;
            },
            goTab() {
                window.open(this.dashboardLink, '_blank');
            },
            flagIcon(flag) {
                switch(flag.toLowerCase()) {
                    case "reporting":
                        return "icon-chart-2";
                    case "no facebook":
                        return "symbol-facebook-rect";
                    default:
                        return null
                }
            },
            flagTooltip(flag) {
                switch (flag) {
                    case "SUSPENDED":
                        return "Suspended account: Clients cannot access this account. The account can still collect data (data collection needs to be turned off separately)";
                    case "ARCHIVED":
                        return "Archived account: Clients can still access this account. Data collection will continue for a month after archiving the account, and then stop.";
                    case "Still collecting":
                        return "Archived accounts still collect data for a month after archival, to help ensure reporting continuity";
                    case "INACTIVE":
                        return "Inactive account: This account has no active products. Clients cannot access this account.";
                    case "Engage":
                        return "This account has been setup to use Engage";
                    case "Reporting":
                        return "This account receives reporting from us";
                    case "Not collecting":
                        return "This account is not collecting mentions";
                    default:
                        return null;
                }
            }
        },

        computed: {
            dashboardLink() {
                return `/${this.account.code}/explore`;
            },
            mashLink: function() {return `https://mash.dataeq.com/account/show/${this.account.code}`;},
            flags: function() {
                const status = [];
                const isArchived = this.account.status === "ARCHIVED";
                const isInactive = !!this.account.inactive;
                if (isInactive) status.push("INACTIVE");
                if (!this.account.findNewMentions && !isArchived && !isInactive) status.push("Not collecting");
                if (this.account.findNewMentions && isArchived) status.push("Still collecting");
                if (this.account.status && this.account.status !== "NORMAL") status.push(this.account.status);
                if (!isInactive) {
                    if (this.account.hasEngage) status.push("Engage");
                    if (this.account.hasReporting) status.push("Reporting");

                    const noFacebook = !this.account.hasFacebookDataAuthorisation;
                    const shouldCheckFacebook = this.account.findNewMentions && (this.account.hasEngage || this.account.accountType === 'PAID' || this.account.accountType === 'PITCH');
                    if (shouldCheckFacebook && noFacebook) status.push("No Facebook");
                }
                return status;
            },
            clientService() {
                if (!this.account || !this.account.clientService) return "«NA»";
                return `${this.account.clientService.firstName} ${this.account.clientService.lastName}`
            }
        }
    }
</script>

<style scoped>

.detail--constrain {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*noinspection CssUnknownProperty*/
    -webkit-line-clamp: 2;
    max-width: clamp(100px, 20vw, 200px);
}

.detail--small {
    font-size: 0.8em;
    color: var(--be-colour-muted-text-dark)
}

.account-list__row {
    --ac-colour: var(--ac-colour-normal);
}

a:not(:hover),
.detail a {
    color: var(--ac-colour-normal);
}


a.SUSPENDED:not(:hover) {
    color: var(--ac-colour-suspended);
}

a.NO-FACEBOOK:not(:hover),
a.NOT-COLLECTING:not(:hover) {
    color: orange;
}

.code a {
    color: var(--ac-colour);
    font-family: var(--number-font-family);
    font-size: 0.95em;
}

.ARCHIVED { --ac-colour: var(--ac-colour-archived); }
.SUSPENDED { --ac-colour: var(--ac-colour-suspended); }
.NOT-COLLECTING { --ac-colour: orange; }
.NO-FACEBOOK { --ac-colour: orange; }
.INACTIVE { --ac-colour: var(--ac-colour-inactive); }

.name {
    display: flex;
    justify-content: space-between;
}

.name--constrain {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /*noinspection CssUnknownProperty*/
    -webkit-line-clamp: 2;
    width: 100%;
    height: 100%;
    max-width: clamp(100px, 20vw, 300px);
}

.INACTIVE .name:not(:hover) {
    color: var(--be-colour-muted-text-dark);
}

.name a {
    color: inherit;
}

.flag {
    font-variant: all-small-caps;
    color: var(--ac-colour);
    margin: 2px;
}

.mash-link:not(:hover) {
    color: var(--ac-colour-normal);
}

.flag,
.mash-link {
    font-variant: all-small-caps;
}

.flag + .flag {
    margin-left: 5px;
}

.mash-link a {
    word-break: keep-all;
}

tr:hover td,
tr:hover.ARCHIVED .code a,
tr:hover.NORMAL .code a {
    color: #aee15d;
    cursor: pointer;
}

tr:focus-visible {
    outline: var(--focus-border);
    outline-offset: -2px;
}

@media (max-width: 800px) {
    .no-small-screen {
        display: none;
    }

    .mash-link {
        display: none;
    }
}
</style>