<template>
    <td style="padding-left: 0" :style="{width: col.hasPrev ? '1px' : null, background: background}" class="clickable" @click="$emit('click')">
        <div style="display: flex; margin-left: 0.3em">
            <div class="comp-table-bar" v-if="bar.leftWidth" :style="{width: bar.leftWidth}">
                <div class="inner" :style="bar.leftInner"></div>
            </div>
            <div class="comp-table-bar" v-if="bar.rightWidth" :style="{width: bar.rightWidth}">
                <div class="inner" :style="bar.rightInner"></div>
            </div>
        </div>
    </td>
</template>

<script>
export default {
    name: "CompTableBar",

    props: {
        row: Object,
        col: Object,
        doc: Object,
        background: {
            required: false,
            default: null,
            type: String
        }
    },

    computed: {
        value() { return this.row.colValues[this.col.id] || { } },

        bar() {
            let v = this.value
            let outerWidth = 4
            let o = { }

            let maxPercentage
            if (this.col.groupBy) {
                // all cols on the chart need to be on the same scale because we are splitting out a col per topic
                // or whatever
                maxPercentage = this.doc.maxPercentage
            } else {
                maxPercentage = this.col.maxPercentage
                // this is so if neg and pos columns are present they use the same scale for bars otherwise a small
                // positive sentiment might look the same as a large negative sentiment etc.
                if (this.col.barScale) this.doc.cols.forEach(c => {
                    if (this.col.barScale === c.barScale) {
                        maxPercentage = Math.max(maxPercentage, c.maxPercentage)
                    }
                })
            }

            if (this.col.maxNegPercentage) {
                let width = v.negPercentage  * 100 / maxPercentage
                o.leftInner = {
                    left: (100 - width) + "%",
                    width: width + "%",
                    backgroundColor: this.col.negColour || this.row.negColour || this.col.colour || this.row.colour ||
                        this.col.defaultColour || "#ccc"
                }
                o.leftWidth = outerWidth + "em"
            }

            if (v.percentage > 0 || this.col.maxPosPercentage) {
                o.rightInner = {
                    width: ((v.posPercentage || v.percentage) * 100 / maxPercentage) + "%",
                    backgroundColor: this.col.posColour || this.row.posColour || this.col.colour || this.row.colour ||
                        this.col.defaultColour || "#ccc"
                }
                o.rightWidth = outerWidth + "em"
            }

            return o
        }
    }
}
</script>

<style>
</style>