/**
 * Displays the author information from a V4 API format mention.
 */
Beef.module("AuthorItemV4").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({

        getTemplate: function() {
            return require("@/authorsV4/AuthorItemV4.handlebars");
        },

        attributes: function() {
            return { class: "author-item-v4" }
        },

        events: {
            // "click .content": "contentClick",
            // "click .show-graph": "showGraph",
            // "click a.edit-link": "edit",
            // "click .edit-author": "editAuthor",
            // "click a.delete-link": "toggleDeleted",
            // "click a.mark-relevant": "markRelevant",
            // "click .mention-reply": "replyClicked",
            // "click .mention-reshare": "reshareClicked",
            // "click .mention-favourite": "favouriteClicked",
            // "click .export-image": "exportImage",
            // "click .follow": "followClicked"
        },

        modelEvents: {
            "change": "render"
        },

        templateHelpers: function() {
            return {}
        }

    });
});