Beef.module("LimitBrandDepth").addInitializer(function(startupOptions) {

    this.show = function(ev) {
        var p = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
        p.setTarget($(ev.target));
        var v = new Beef.LimitBrandDepth.View(
            {model: new Backbone.Model({ title: "Brand Structure Too Deep"})});
        v.on("close", function() { p.hide(); });
        p.show(v);
    };

    this.View = Backbone.Marionette.ItemView.extend({

        template: require("@/setup/brands/LimitBrandDepth.handlebars"),

        attributes: { class: "limit-brand-depth dialog" },

        events: {
            "click .ok": "close",
            "click .close": "close"
        },

        close: function() {
            if (!this.$el[0].parentElement) return; // already closed
            Backbone.Marionette.ItemView.prototype.close.call(this);
        }
    });
});