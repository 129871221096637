import NomenclatureWidgetComponent from "@/dashboards/widgets/nomenclature/NomenclatureWidgetComponent";
import {getAllCxSegmentLists} from "../../../app/utils/Segments";
import NomenclatureSettingsDialog from "@/dashboards/widgets/nomenclature/NomenclatureSettingsDialog";
import VuexStore from "@/store/vuex/VuexStore";
import {getDefinitionsList} from "@/dashboards/widgets/nomenclature/nomenclature-definitions";

Beef.module("Widget.Nomenclature").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Nomenclature",
        description:    "stuff",
        vueSettingsDialog: NomenclatureSettingsDialog,
        height: 4,
        width: 4
    };

    this.View = Beef.BoundItemView.extend({

        cache: {},

        attributes: {
            'class': 'filter-display widget-height-inner'
        },

        modelEvents: {
            "change:_list": "render",
            "change:selectedList": "refresh"
        },

        getTemplate: function() {
            return () => "<div class=\"nomenclature-vue\"></div>";
        },

        regions: {
            list: ".nomenclature-vue"
        },

        svgExportDisabled: false,

        initialize: async function() {
            const defaults = {};
            if (!this.model.has('hidden-title')) {
                defaults['hidden-title'] = true;
            }
            this.model.set(defaults);
        },

        async refresh() {
            const vm = this.list && this.list.currentView && this.list.currentView.vm;
            if (!vm) return;

            let segment = this.model.get("segment");
            if (segment && !this.model.get("selectedList")) {
                this.model.set("selectedList", segment);
                this.model.unset("segment");
            }

            let listId = this.model.get("selectedList");

            if (listId) {
                await VuexStore.dispatch('refreshTags');
                const idToTags = VuexStore.getters.idToTag;

                let list = idToTags.get(listId) ?? getDefinitionsList(listId);

                vm.setList(list);
            } else {
                const cxLists = await getAllCxSegmentLists();
                if (cxLists && cxLists.length) {
                    vm.setList(cxLists[0]) ;
                }
            }

            setTimeout(() => this.model.generalData.set({'_loading': false, '_completed': true}));
        },

        async onFirstRender() {
            const comp = new Beef.VuejsView.View({component: NomenclatureWidgetComponent});
            this.list.show(comp);

            await this.refresh();
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({
        editAttributes: function() {
            return ['show-raw']
        }
    });
});