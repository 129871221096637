import {CustomInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/CustomInlineTool";

export class TopInlineTool extends CustomInlineTool {
    getBtnOptions() {
        return topBtnOptions;
    }

    getBtnName() {
        return "Top";
    }

    toolTipText() {
        return "Add a top metric";
    }

}

export const topBtnOptions = [
    {
        text: "Country",
        command: "{{top country \"country isnt 'UN'\"}}"
    },
    {
        text: "Language",
        command: "{{top language 'language isnt unknown'}}"
    },
    {
        text: "Social network",
        command: "{{top socialNetwork}}"
    },
    {
        text: "Source",
        command: "{{top site}}"
    },
    {
        text: "Sentiment",
        command: "{{top sentiment 'process is verified'}}"
    }
];