var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tag-tooltip\">\n    <h1>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h1>\n    <p>\n        This mention was looked at by the DataEQ Crowd, but it is not\n        related to your <strong>"
    + alias1(__default(require("../../../../helpers/trim.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parent") : depth0),{"name":"trim","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":47}}}))
    + "</strong>.\n    </p>\n</div>\n\n\n\n";
},"useData":true});