/**
 * Nicely renders user information, such as lastEditedBy fields.
 * Also, hides staff member info from clients.
 */
import {isMashAdmin} from "@/app/Permissions";

export default function(user) {
    var email = (!user.system && !user.admin) || isMashAdmin() ? user.email : "";
    var description = getUserDescription(user, "DataEQ Staff");

    return new Handlebars.SafeString(
        "<span class='user-description static-beef-tooltip--trigger'>" +
        description +
        "<span class='static-beef-tooltip'>" +
        "<strong>" + description + "</strong>" +
        (email ? "<div>" + email + "</div>" : "") +
        "</span>" +
        "</span>"
    )
}

var getUserDescription = function(user, def) {
    if (!user || user.system) return def;
    if (user.admin && !isMashAdmin()) return "DataEQ staff";
    if (user.name) return user.name;
    var s = user.firstName;
    if (user.lastName) s = (s ? s + ' ' : '') + user.lastName;
    return s ? s : user.id;
};
