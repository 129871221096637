
import {escapeExpression} from "@/app/utils/StringUtils";
import {isFunction} from "@/app/utils/Util";

/**
 * Draws menu options. The view should be the view that we are drawing the options for. It must
 * also be provided with a method name for something either on the view, or widget it contains.
 * If no such method exists, and it's marked as optional, it won't be displayed in the menu.
 */
export default function(view, options) {
    var out = "";
    var method = options.hash['method'];

    if (!options.hash['optional'] || isFunction(view[method]) || (view.container && view.container.currentView && isFunction(view.container.currentView[method]))) {
        out += '<a id="' + method + '" ';
        if (options.hash['title']) out += "title='" + escapeExpression(options.hash['title']) + "' ";
        out += 'data-method="' + method + '">';
        out += options.fn(this);
        out += "</a>";

        return out;
    } else return "";
}