<template>
    <div class="mention-conversation-toolbar">
        <a class="btn mention-replies-graph" title="Explore these mentions as a graph">
            Graph
        </a>
        <a class="btn mention-replies-menu options popup-menu"
           title="Extra options and functions for viewing this conversation"
           @click.prevent="emitMenuClickedEvent($event)">
            Options <span class="caret"></span>
        </a>
        <a class="btn mention-replies-close" title="Close this view (Esc)" @click.prevent="close()">
            Close
        </a>
    </div>
</template>

<style scoped>
.mention-conversation-toolbar {
    position: absolute;
    right: 120px;
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    z-index: 101;
}
</style>

<script>
export default {
    name: "ConversationToolbar",
    methods: {
        emitMenuClickedEvent: function (event) {
            this.$emit('options-menu-clicked', event);
        },
        close: function () {
            this.$emit('close');
        }
    }
}
</script>