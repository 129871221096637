<template>
    <div class="be-keyboard-key be-tooltip__keyword-styling">
        <slot/>
    </div>
</template>


<script>
export default {
}
</script>


<style scoped lang="sass">

.be-keyboard-key
    display: inline-block
    box-sizing: border-box
    min-width: 28px
    text-align: center
    color: white
    padding-inline: 8px
    padding-bottom: 2px

</style>