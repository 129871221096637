<template>
    <div class="be-button-group">
        <slot>
            <BeButton>Some</BeButton>
            <BeButton>Example</BeButton>
            <BeButton>Buttons</BeButton>
        </slot>
    </div>
</template>


<script>
import BeButton from "@/components/buttons/BeButton.vue";

export default {
    components: {BeButton}
}
</script>


<style scoped lang="sass">
.be-button-group
    display: flex

    .be-button
        &:not(:last-of-type)
            &::v-deep .btn
                border-top-right-radius: 0
                border-bottom-right-radius: 0

    .be-button + .be-button
        &::v-deep .btn
            border-top-left-radius: 0
            border-bottom-left-radius: 0

</style>