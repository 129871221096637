<template>
    <span class="deq-reset short-animated fadeIn">
        <span class="inline-loading-message__alignment">
            <span class="inline-loading-message__message">
                <slot>Loading...</slot>
            </span>
            <spinner-component :size="16"/>
        </span>
    </span>
</template>
<script>

import SpinnerComponent from "@/components/SpinnerComponent";

export default {
    components: {SpinnerComponent}
};

</script>

<style scoped lang="sass">

.inline-loading-message__alignment
    color: var(--be-colour-muted-text-dark)
    display: inline-flex
    align-items: center

.inline-loading-message__message
    padding-right: 5px


</style>