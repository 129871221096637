import _ from 'underscore';

Beef.module("DashboardNav").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({

        attributes: function() {
            return { class: "row-fluid dashboard-nav" }
        },

        template: require("@/dashboards/DashboardNav.handlebars"),

        modelEvents: {
            "change:sections": "render"
        },

        events: {
            "click .sections .btn": "sectionClicked",
            "click .sidebar-contents.expanded": "hideContents"
        },

        onRender: function() {
            var sections = this.model.get('sections');
            var that = this;
            if (sections && sections.models && this.scrollHandler == null) {
                $(window).on('scroll', this.scrollHandler = _.throttle(function(){ that.highlightVisibleSection() },
                    100, {leading: false}));
                sections.on("add", that.render);
                sections.on("remove", that.render);
                sections.on("reorder", that.render);
                sections.on("change:title", that.render);
                Beef.autoAdjustFixedSidebar(this);
            }

            if (!this.hideHandler) {
                this.hideHandler = function() {
                    this.hideContents();
                }.bind(this);
                $(window).on("keyup", this.hideHandler);
            }

            setTimeout(function() { that.highlightVisibleSection() });
        },

        onClose: function() {
            if (this.scrollHandler) {
                $(window).off("scroll", this.scrollHandler);
                this.scrollHandler = null;
            }
            if (this.hideHandler) {
                $(window).off("keyup", this.hideHandler);
                this.hideHandler = null;
            }

        },

        highlightVisibleSection: function() {
            var list = this.$el.closest(".dashboards").find(".section");
            var active =list.last(), i;
            var $window = $(window);
            var docViewTop = $window.scrollTop();
            var docViewBottom = docViewTop + $window.height();
            for (i = 0; i < list.length - 1; i++) {
                var $e = $(list[i]);
                var offset = $e.offset();
                var elemTop = offset.top;
                var elemBottom = elemTop + $e.height();
                if (elemTop >= docViewTop && elemTop <= docViewBottom
                        || elemTop < docViewTop && elemBottom >= docViewBottom) {
                    active = $e;
                    break;
                }
            }
            var items = this.$el.find(".section-nav-item");
            if (active && active.length > 0) {
                var id = active.attr('data-id');
                for (i = 0; i < items.length; i++) {
                    var item = $(items[i]);
                    item.toggleClass("active", item.attr('data-id') == id);
                }
            } else {
                items.toggleClass("active", false);
            }
        },

        hideContents: function(ev) {
            if (ev) ev.stopPropagation();
            $('.sidebar-contents').toggleClass("expanded", false);
        },

        keypress: function(ev) {
        },

        sectionClicked: function(ev) {
            ev.preventDefault();
            var t = $(ev.target).closest(".section-nav-item");
            var dbs = t.closest(".dashboards");
            var s, top;
            if (t.is(":first-child")) {
                s = dbs.find(".dashboard");
                top = s.offset().top - 41; // 41 for header
            } else {
                s = dbs.find(".section-" + t.attr('data-id'));
                top = s.offset().top - 61; // 41 for header + 20 for some space above
            }
            $('html,body').animate({ scrollTop: top }, "fast");
            $('.sidebar-contents').toggleClass("expanded", false);
        }
    });

});