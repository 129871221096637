/**
 * Utility to help generate CSV client side.
 */

import moment from "moment";

export default class CSV {

    constructor() {
        this.lines = []
    }

    /**
     * Add an unquoted field to the line. If s is undefined or null an empty string is appended.
     */
    add(s) {
        if (s === null || s === undefined) s = ''
        if (!this.line) this.line = s
        else this.line += "," + s
        return this
    }

    /**
     * Add all items from something iterable (like an array) to the CSV object
     */
    addLine(iterable) {
        for (const item of iterable) {
            this.quote("" + item);
        }
        this.eol();
        return this;
    }

    /**
     * Add an quoted field to the line. If s is undefined or null an empty string is appended.
     */
    quote(s) {
        s = s === null || s === undefined ? '' :  '"' + s.replace(/"/g, '""') + '"'
        return this.add(s)
    }

    /**
     * End the current line.
     */
    eol() {
        if (this.line) this.lines.push(this.line)
        this.line = null
    }

    toCSV() {
        // include UTF-8 byte order mark so Excel will open the file properly
        return "\ufeff" + this.lines.join("\n")
    }

    toString() {
        return this.toCSV()
    }

    /**
     * Create link with the csv data and 'click' it to download.
     */
    download(name) {
        // The idea here is to create an anchor element for downloading a file. The href encodes the data that
        // we want to download
        let link = document.createElement("a")
        link.style = "display: none"
        link.href = window.URL.createObjectURL(new Blob([this.toCSV()], {type: "text/csv"}))
        link.download = name + "-" + (moment().format("YYYY-MM-DD-HH[h]mm")) + ".csv"

        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
    }
}