<template>
    <widget-settings-dialog :model="model" :dto="dto" @input="$emit('input', $event)" width="875px"
                            class-name="comp-table-settings">
        <template #title-inputs="{options}">
            <drop-down-input v-model="attrs.fontSize" label="Font size" :options="options.fontSize" style="width: 60px"/>
        </template>

        <c-t-col-settings v-for="(c,i) in attrs.cols" :key="c.id" :col="c" :index="i" :attrs="attrs" :dashboard-model="dashboardModel"/>
        <div>
            <a class="btn btn-link" @click="addColumn"><i class='icon-plus'/> Add another column</a>
        </div>

        <c-t-row-settings v-for="(r,i) in attrs.rows" :key="'row-' + r.id" :row="r" :index="i" :attrs="attrs" :model="model"/>
        <div>
            <a class="btn btn-link" @click="addRow"><i class='icon-plus'/> Add another row</a>
        </div>

        <div class="row-fluid">
            <div class="span12">
                <label class="checkbox">
                    <input v-model="attrs.swapRowsAndCols" type="checkbox"> Swap rows and columns
                </label>
            </div>
        </div>

        <div class="row-fluid" v-if="showTopicControls">
            <div class="span12 control-row">
                <topic-view-input v-model="attrs.topicViewId" label="Topic view" optional style="width: 220px"/>
                <language-input v-model="attrs.language" label="Display language" optional style="width: 160px"/>
            </div>
        </div>

        <div class="row-fluid">
            <div class="span12 control-row">
                <drop-down-input v-model="attrs.prevDate" :options="previousDates" optional placeholder="Optional date"
                                 style="width: 170px; flex-grow: 0" :use-activator-width="false" label="Previous period"/>

                <div class="control-group" @click="editPrevFilter">
                    <label>Filter for previous values</label>
                    <div class="controls">
                        <div class="tag-input uneditable-input filter span12">
                            <english-filter v-if="attrs.prevFilter" :filter="attrs.prevFilter" partial/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <dialog-box v-if="showEditPrevFilter" title="Edit Previous Filter" @close="showEditPrevFilter = false" width="740px">
            <filter-editor v-model="filter" no-published no-trash :brand="brand"/>
            <template #buttons>
                <a class="cancel btn" @click="showEditPrevFilter = false">Cancel</a>
                <a class="btn btn-primary" @click="editPrevFilterOk">Ok</a>
            </template>
        </dialog-box>
    </widget-settings-dialog>
</template>

<script>
    import WidgetSettingsDialog from "@/dashboards/widgets/WidgetSettingsDialog";
    import DropDownInput from "@/components/inputs/DropDownInput";
    import CTColSettings from "@/dashboards/widgets/comptable/CTColSettings";
    import CTRowSettings from "@/dashboards/widgets/comptable/CTRowSettings";
    import DateInterval from "@/dashboards/widgets/fantasticchart/DateInterval";
    import DialogBox from "@/components/DialogBox";
    import EnglishFilter from "@/components/formatters/EnglishFilter";
    import FilterEditor from "@/components/inputs/FilterEditor";
    import TopicViewInput from "@/components/inputs/TopicViewInput";
    import LanguageInput from "@/components/inputs/LanguageInput";
    import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
    import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
    import {COLS, GROUPBY_BY_ID, DEFAULT_COLOUR} from "@/dashboards/widgets/comptable/CompTableUtils";

    export default {
        name: "CompTableSettingsDialog",

        components: { WidgetSettingsDialog, DropDownInput, CTColSettings, CTRowSettings, DialogBox, EnglishFilter,
            FilterEditor, TopicViewInput, LanguageInput },

        props: {
            model: Object
        },

        data() {
            return {
                dto: {
                    attrs: { }  // this gets set by widget-settings-dialog
                },
                filter: null,
                showEditPrevFilter: false
            }
        },

        computed: {
            attrs() { return this.dto.attrs },

            dashboardModel() { return this.model.getDashboardModel() },

            sectionFilter() { return this.model.getSectionModel().get('filter') },

            brand() {
                let brand = this.model.getInteractiveFilterModel().get('brand');
                if (!brand) brand = convertFilterToAttrs(this.sectionFilter).brand
                return brand
            },

            dateInterval() {
                let filter = this.sectionFilter

                if (!filter) {
                    if (this.attrs.filter) return new DateInterval(this.attrs.filter)
                    else if (this.model.get("filter")) return new DateInterval(this.model.get("filter"))
                    else return null;
                }

                if (this.attrs.filter) filter = appendFiltersReadably(filter, this.attrs.filter)
                return new DateInterval(filter)
            },

            previousDates() {
                let previousDates = this.dateInterval?.getPreviousDates()?.filter(o => o.id !== "current")
                return previousDates ? previousDates : [];
            },

            showTopicControls() {
                return this.attrs.cols && this.attrs.cols.find(c => c && c.groupBy && (GROUPBY_BY_ID[c.groupBy] || {}).requiresTopicView) ||
                    this.attrs.rows && this.attrs.rows.find(r => r && r.groupBy && (GROUPBY_BY_ID[r.groupBy] || {}).requiresTopicView)
            }
        },

        methods: {
            addColumn() {
                let id = 0
                this.attrs.cols.forEach(c => {
                    id = Math.max(c.id, id)
                    c.groupBy = null
                })
                let c = COLS.find(c => !this.attrs.cols.find(ac => ac.type === c.id))
                this.attrs.cols.push(Object.assign({
                    id: id + 1,
                    type: c ? c.id : "mentionCount",
                    groupBy: null,
                    limit: 10,
                    showOther: true,
                    showUnknown: true,
                    name: null,
                    showPercentage: false,
                    showValue: true,
                    showBar: false,
                    showDelta: true
                }, DEFAULT_COLOUR))
            },

            addRow() {
                let id = 0
                this.attrs.rows.forEach(r => id = Math.max(r.id, id))
                this.attrs.rows.push({
                    id: id + 1,
                    groupBy: null,
                    limit: 10,
                    showOther: true,
                    showUnknown: true,
                    sortByFirstCol: false,
                    sortByName: false,
                    name: "Row " + (id + 1)
                })
            },

            editPrevFilter() {
                this.filter = this.attrs.prevFilter
                this.showEditPrevFilter = true
            },

            editPrevFilterOk() {
                this.showEditPrevFilter = false
                this.attrs.prevFilter = this.filter
            }
        }
    }
</script>

<style scoped>
.comp-table-settings ::v-deep .dialog-body {
    max-height: calc(90vh - 64px);
}
</style>