/**
 * Given a function that takes an arbitrary number of arguments,
 * this returns a version of the function that will only run once.
 *
 * The returned function will not return a value. This is not meant to be
 * a memoisation implementation.
 * @param {Function} func
 * @returns {Function}
 */
export function once(func) {
    let count = 0;
    return function() {
        if (count++ > 0) return;
        func.apply(this, arguments);
    }.bind(this);
}