import * as b3js from "brandseyejs";
import {initialiseRow, simpleGetterFactory, tagAndTopicDescriptionGetterFactory} from "../FantasticUtilities";
import {createGroupExcluding, createSelectExcluding} from "../FantasticDataHelpers";
import DateInterval from "../DateInterval";
import {hasPublished, removePublished} from "@/dashboards/filter/BasicFilter";

export default {

    dataSet: {
        name: "Compare data sets",
        isMultiple: true,
        noLimit: true,
        noMaxItems: true,
        hideOthers: false,
        otherNotSupported: true,    // disables showing 'Other' values even if the xField supports that
        hideUnknown: true,
        // setter: function (d, id, value) {
        //     d.functionalSentiment = parseInt(value)
        // },
        getter: simpleGetterFactory("dataSet.label"),
        rawDataGetter(d) {return d._dataSet;},
        rawDataSetter(d, data) {return d._dataSet = data;},
        scaleX: b3js.scaleDiscrete,
        // tooltipPreposition: 'have',
        // tooltipPrepositionPercent: 'have',
        tooltipComparePreposition: 'for',
        // tooltipMentionsWithVerb: 'related to',
        descriptionGetter: tagAndTopicDescriptionGetterFactory("functionalSentiment"),
        formatTooltipX(d) { return d._label },
        filterGetter() { return null },
        extraFilter(d) { return d._dataSet.filter },
        extendData(d) {
            return {
                "dataSet.id": d.id,
                "dataSet.label": d._label,
                "_dataSet": d
            }
        },
        formatX(d) { return d /*d._label*/ },
        defaultSortOptions: {
            label: "Data set",
            field: "_dataSet.id",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";
            return order === "ascending" ? lhs._dataSet.id - rhs._dataSet.id : rhs._dataSet.id - lhs._dataSet.id
        },
        legendColours(model) {
            let attrs = model.attributes;
            let map = { }
            if (attrs.xAxis === "brand") {
                let n = attrs.dataSets.length
                let base = d3.hcl("#000000")
                attrs.dataSets.forEach((ds, i) => {
                    map[ds._label] = base.brighter(((n - i) / n) * 4).toString()
                })
            }
            return map
        },
        async getData(model, query, groupBy, ignore, ignoreY, getData, dataSetCounter) {
            let dataSets = model.attributes.dataSets
            //console.log("dataSets", dataSets)

            const g = createGroupExcluding(groupBy, ignore, "dataSet");
            const s = createSelectExcluding(query, ignore, "dataSet").join(',');
            const dateInterval = new DateInterval(query.filter)
            const onXAxis = model.attributes.xAxis === "dataSet"

            let data = await Promise.all(dataSets.map((ds,i) => {
                dataSetCounter--;
                let di = ds.date ? dateInterval.calcPreviousDate(ds.date) : null
                ds._label = ds.label || (di ? di.name : "Set " + ds.id)
                ds = {...ds}
                ds.index = i
                ds.count = dataSets.length
                let q = {...query}
                if (di && di.filter || ds.filter) {
                    if (di && di.filter || hasPublished(ds.filter)) {
                        q.filter = removePublished(q.filter)
                    }
                    q.filter = "(" + q.filter + ")"
                    if (di && di.filter) q.filter += " and (" + di.filter + ")"
                    if (ds.filter) q.filter += " and (" + ds.filter + ")"
                }
                if (g.length) q.groupBy = g.join(",")
                else delete q.groupBy
                q.select = s

                //console.log("ds " + ds.label, q)

                let _extraColourFn = onXAxis ? null : brandDataSetColourFn

                return getData(q, g, ignore, ignoreY, dataSetCounter)
                    .then(d => Array.isArray(d) ? d : [d])
                    .then(d => d.map(r => Object.assign({dataSet: ds, _extraColourFn: _extraColourFn}, r)))
                    .then(d => d.length ? d : [initialiseRow(model, {dataSet: ds})])
            }))
            //console.log("data", data)
            return data.flat()
        }
    }
}

/**
 * If d contains a _brand and a _dataSet then use the brand colour and the index of the data set to pick a colour.
 * This makes the brand colour go lighter which each data set when comparing time periods and so on.
 */
function brandDataSetColourFn(d) {
    let brand = d._brand
    if (!brand) return null
    let ds = d._dataSet
    if (!ds) return null
    let bc = brand.colour
    if (!bc) return null
    let hcl = d3.hcl(bc)
    let k = ds.count - ds.index - 1
    if (k) hcl = hcl.brighter((k / ds.count) * 4)
    return hcl
}

