import {substituteTagParamaters} from "@/app/utils/Tags";
import {
    get as getSegment,
    getChannelSegmentList,
    getJourneySegmentList,
    getRiskSegmentList,
    getSegmentList,
    hasChannelSegmentLists,
    hasCxSegmentLists,
    hasMarketConductSegmentLists,
    union as segmentUnion
} from "@/app/utils/Segments";
import ConductFilter from "@/dashboards/filter/ConductFilter";
import SegmentFilter from "@/dashboards/filter/SegmentFilter";
import CxFilter from "@/dashboards/filter/CxFilter";
import {parseFilterString, removeMissingBrands,} from "@/dashboards/filter/FilterParser";
import {buildBasicFilter, convertExpToAttrs, listV4Issues} from "@/dashboards/filter/BasicFilter";
import VuexStore from "@/store/vuex/VuexStore";
import {features} from "@/app/Features";
import {showBrandPickerDialog, showNewProfilePicker, showPublishedPickerDialog} from "@/app/framework/dialogs/Dialog";
import {account} from "@/app/utils/Account";
import {publishedTagConverter} from "@/components/pickers/dates/DateRangePickerUtilities";
import {createBrandTagConverterFactory} from "@/components/pickers/brands/BrandPickerUtilities";
import {splitAtSpaces} from "@/app/utils/StringUtils";

/**
 * Simple filter editor. This cannot handle very complex filters.
 */
Beef.module("BasicFilter").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
        validation: {
            proportion: {
                required:   false,
                range:      [0, 100],
                msg:        "Please enter a percentage between 0 and 100"
            },
            seed: {
                required:   false,
                range:      [1, 2147483648],
                msg:        "Please enter a positive integer"
            }
        }
    });

    this.View = Beef.BoundItemView.extend({
        attributes: { class: "basic-filter row-fluid" },

        template: require("@/dashboards/filter/BasicFilter.handlebars"),

        regions: {
            contentFilterRegion: ".content-filter-region",
            authorBioFilterRegion: ".author-bio-filter-region",
            conductFilterRegion: ".conduct-filter-region",
            riskFilterRegion: ".risk-filter-region",
            channelFilterRegion: ".channel-filter-region",
            cxFilterRegion: ".cx-filter-region"
        },

        conductFilterVue: null,
        riskFilterVue: null,
        channelFilterVue: null,
        cxFilterVue: null,

        initialize: function(options) {
            this.model._showPredictedSentiment = account().showPredictedSentiment;
            var that = this;

            this.listenTo(this.model, 'validated', function(isValid, model, errors) {
                if (isValid) that.updateFilter();
            });
            this.model.set('ok', true);

            this.authorNameOptions = {filterAttrs: {}};

            if (this.options.noSample) this.options.noOptionsTab = true

            let journeys = getJourneySegmentList(true, true);
            let activeJourneys = getJourneySegmentList(true);
            var channels = getChannelSegmentList();
            var risk = getRiskSegmentList();
            const hasConduct = hasMarketConductSegmentLists();
            const hasChannels = hasChannelSegmentLists();
            const hasCx = hasCxSegmentLists();

            this.journeys = journeys?.length ? journeys : [];
            this.activeJourneys = activeJourneys?.length ? activeJourneys : [];

            if (channels) {
                this.channels = channels;
                this.listenTo(this.model, "change:" + channels.id, this.segmentsToView, this);
            }

            if (risk) {
                this.risk = risk;
                this.listenTo(this.model, "change:" + risk.id, this.segmentsToView, this);
            }

            this.hasConduct = hasConduct;
            this.hasChannels = hasChannels;
            this.hasCx = hasCx;
        },

        bindings: function() {
            var ans = {
                interactsWith: { converterFactory: Beef.IncExProfilePicker.converterFactory, elAttribute: "data-value" },
                hasReplyFromProfile: { converterFactory: Beef.IncExProfilePicker.converterFactoryHasReply, elAttribute: "data-value" },
                published: { converter: publishedTagConverter, elAttribute: "data-value" },
                media: { converter: Beef.MediaPicker.converter, elAttribute: "data-value" },
                visibility: { converter: Beef.VisibilityPicker.converter, elAttribute: "data-value" },
                credibility: { converter: Beef.CredibilityPicker.converter, elAttribute: "data-value" },
                sentiment: { converter: Beef.SentimentPicker.converter, elAttribute: "data-value" },
                location: { converter: Beef.IncExLocationPicker.converter, elAttribute: "data-value" },
                language: { converter: Beef.IncExLanguagePicker.converter, elAttribute: "data-value" },
                tags: { converterFactory: Beef.IncExTagPicker.converterFactory, elAttribute: "data-value" },
                topics: { converterFactory: Beef.IncExTopicPicker.converterFactory, elAttribute: "data-value" },
                segments: {
                    converterFactory: Beef.TagPicker.createConverterFactory("Solutions and tag sets (segments)", {noHighlightSegments: true, placeholderPersistant: false, showWarning: true}),
                    elAttribute: "data-value"
                },
                rpcs: { converter: Beef.RpcsPicker.converter, elAttribute: "data-value" },
                socialNetwork: { converter: Beef.SocialNetworkPicker.converter, elAttribute: "data-value" },
                brand: { converterFactory: createBrandTagConverterFactory("Brands"), elAttribute: "data-value" },
                link: { converter: Beef.LinkPicker.converter, elAttribute: "data-value" },
                author: { converter: Beef.IncExAuthorNamePicker.converter, elAttribute: "data-value" },
                conversationId: { converter: Beef.IncExConversationPicker.converter, elAttribute: "data-value" },
                interactionId: { converter: Beef.IncExInteractionPicker.converter, elAttribute: "data-value" },
                content: { converter: Beef.ContainsPicker.converter, elAttribute: "data-value" },
                engagement: { converter: Beef.EngagementPicker.converter, elAttribute: "data-value" },
                replyCount: { converter: Beef.ReplyCountPicker.converter, elAttribute: "data-value" },
                reshareCount: { converter: Beef.ReshareCountPicker.converter, elAttribute: "data-value" },
                interactionResponseTime: { converter: Beef.InteractionResponseTimePicker.converter, elAttribute: "data-value" },
                interactionFirstResponseTime: { converter: Beef.InteractionFirstResponseTimePicker.converter, elAttribute: "data-value" },
                interactionFollowUpResponseTime: { converter: Beef.InteractionFollowUpResponseTimePicker.converter, elAttribute: "data-value" },
                interactionWhResponseTime: { converter: Beef.InteractionResponseTimeWhPicker.converter, elAttribute: "data-value" },
                interactionWhFirstResponseTime: { converter: Beef.InteractionFirstResponseTimeWhPicker.converter, elAttribute: "data-value" },
                interactionWhFollowUpResponseTime: { converter: Beef.InteractionFollowUpResponseTimeWhPicker.converter, elAttribute: "data-value" },
                gender: { converter: Beef.GenderPicker.converter, elAttribute: "data-value" },
                ots: { converter: Beef.OtsPicker.converter, elAttribute: "data-value" },
                reshareOf: { converter: Beef.ReshareOfPicker.converter, elAttribute: "data-value" },
                replyTo: { converter: Beef.ReplyToPicker.converter, elAttribute: "data-value" },
                interactionHasResponse: { converter: Beef.InteractionHasResponsePicker.converter, elAttribute: "data-value" }
            };
            ans.authorId = { converter: Beef.IncExAuthorIdPicker.converter, elAttribute: "data-value" };
            return ans
        },

        templateHelpers: function() {
            var ac = this.model.getAncestorProperty('accountCode');

            return {
                accountCode: ac,
                journey: this.activeJourneys.at(0),
                channels: this.channels,
                channelsNone: this.channels && this.channels.children.find(function(c) { return c.flag === "NONE_OF_THE_ABOVE"}),
                risk: this.risk,
                hasConduct: !!this.hasConduct
            }
        },

        onFirstRender: function() {
            var filter = parseFilterString(this.model.get('filter'));
            var ac = this.model.getAncestorProperty('accountCode');
            var model = this.model;

            if (filter) {
                removeMissingBrands(filter)
                    .then(function(filter) {
                        model.set('filter', filter.toString());
                    });
            }

            Beef.MediaPicker.attach(this, ".media", "media");
            Beef.VisibilityPicker.attach(this, ".visibility", "visibility");
            Beef.CredibilityPicker.attach(this, ".credibility", "credibility");
            Beef.SentimentPicker.attach(this, ".sentiment", "sentiment");
            Beef.IncExLocationPicker.attach(this, ".location", "location");
            Beef.IncExLanguagePicker.attach(this, ".language", "language");
            Beef.IncExTagPicker.attach(this, ".tags", "tags");
            Beef.IncExTopicPicker.attach(this, ".topics", "topics");
            Beef.IncExSegmentPicker.attach(this, ".segments", "segments");
            Beef.LinkPicker.attach(this, ".link", "link");
            Beef.IncExAuthorIdPicker.attach(this, ".authorId", "authorId", this.authorNameOptions);
            Beef.IncExAuthorNamePicker.attach(this, ".author", "author", this.authorNameOptions);
            Beef.IncExConversationPicker.attach(this, ".conversationId", "conversationId");
            Beef.IncExInteractionPicker.attach(this, ".interactionId", "interactionId");
            Beef.ContainsPicker.attach(this, ".content", "content");
            Beef.EngagementPicker.attach(this, ".engagement", "engagement");
            Beef.ReplyCountPicker.attach(this, ".reply-count", "replyCount");
            Beef.ReshareCountPicker.attach(this, ".reshare-count", "reshareCount");
            Beef.InteractionResponseTimePicker.attach(this, ".interaction-response-time", "interactionResponseTime");
            Beef.InteractionFirstResponseTimePicker.attach(this, ".interaction-first-response-time", "interactionFirstResponseTime");
            Beef.InteractionFollowUpResponseTimePicker.attach(this, ".interaction-follow-up-response-time", "interactionFollowUpResponseTime");
            Beef.InteractionResponseTimeWhPicker.attach(this, ".interaction-wh-response-time", "interactionWhResponseTime");
            Beef.InteractionFirstResponseTimeWhPicker.attach(this, ".interaction-wh-first-response-time", "interactionWhFirstResponseTime");
            Beef.InteractionFollowUpResponseTimeWhPicker.attach(this, ".interaction-wh-follow-up-response-time", "interactionWhFollowUpResponseTime");
            Beef.GenderPicker.attach(this, ".gender", "gender");
            Beef.OtsPicker.attach(this, ".ots", "ots");
            Beef.ReshareOfPicker.attach(this, ".reshare-of", "reshareOf");
            Beef.ReplyToPicker.attach(this, ".reply-to", "replyTo");
            Beef.InteractionHasResponsePicker.attach(this, ".interaction-has-response", "interactionHasResponse");
            if (!features.newProfilePicker()){
                Beef.IncExProfilePicker.attach(this, ".interacts-with", "interactsWith");
                Beef.IncExProfilePicker.attach(this, ".hasReplyFromProfile", "hasReplyFromProfile", {
                    includeLabel: "Has a reply from any of these profiles",
                    excludeLabel: "Does not have a reply from any of these profiles"
                });
            }
            Beef.RpcsPicker.attach(this, ".rpcs", "rpcs");
            Beef.SocialNetworkPicker.attach(this, ".social-network", "socialNetwork");

            this.segmentsToView();
            this.trashToView();
            this.verificationToView();
            this.unbiasedSampleToView();
            this.crowdVerifiedToView();
            this.v4IssuesToView();

            this.contentFilterRegion.show(Beef.TextSearch.createView(this.model, 'content'));
            this.authorBioFilterRegion.show(Beef.TextSearch.createView(this.model, 'authorBio'));
            if (this.hasConduct) {
                this.conductFilterVue = new Beef.VuejsView.View({
                    component: ConductFilter,
                    props: {
                        value: splitAtSpaces(this.model.get("conduct") ?? "").map(s => parseInt(s))
                    }
                });
                this.conductFilterRegion.show(this.conductFilterVue);
                this.conductFilterVue.vm.$on('input', ids => {
                    const segments = new Set(ids);
                    this.model.set("conduct", [...segments].map(id => "" + id).join(' '));
                });
            }


            if (this.risk) {
                this.riskFilterVue = new Beef.VuejsView.View({
                    component: SegmentFilter,
                    props: {
                        originalSegments: splitAtSpaces(this.model.get("risk") ?? "").map(s => parseInt(s)),
                        segmentListType: "CONDUCT_LIST",
                        selectAllLabel: "Select all factors",
                        selectAllTooltip: "Click to select all mentions related to any of your Risk factors",
                        allSelectedLabel: "All factors selected",
                        selectNoneLabel: "Select non-Risk mentions",
                        selectNoneTooltip: "Click to select all mentions that are verified to not be related to your Risk factors",
                        noneSelectedLabel: "Non-Risk mentions selected",
                        unselectAllTooltip: "Click to unselect all of your Risk factors"
                    }
                });
                this.riskFilterRegion.show(this.riskFilterVue);
                this.riskFilterVue.vm.$on('input', ids => {
                    const segments = new Set(ids);
                    this.model.set("risk", [...segments].map(id => "" + id).join(' '));
                });
            }

            if (this.hasChannels) {
                this.channelFilterVue = new Beef.VuejsView.View({
                    component: SegmentFilter,
                    props: {
                        originalSegments: splitAtSpaces(this.model.get("channels") ?? "").map(s => parseInt(s)),
                        segmentListType: "CHANNEL_LIST",
                        selectAllLabel: "Select all channels",
                        selectAllTooltip: "Click to select all mentions related to any of your channels",
                        allSelectedLabel: "All channels selected",
                        selectNoneLabel: "Has no channels",
                        selectNoneTooltip: "Click to select all mentions that are verified to not be related to any of your channels",
                        noneSelectedLabel: "Has no channels",
                        unselectAllTooltip: "Click to unselect all of your channels"
                    }
                });
                this.channelFilterRegion.show(this.channelFilterVue);
                this.channelFilterVue.vm.$on('input', ids => {
                    const segments = new Set(ids);
                    this.model.set("channels", [...segments].map(id => "" + id).join(' '));
                });
            }

            if (this.hasCx) {
                let originalJourney = [];
                let originalInteractions = [];

                for (const journey of this.journeys) {
                    let journeySegments = this.model.get(journey.id);
                    let interactionSegments = this.model.get(`${journey.id}:interactions`);

                    if (journeySegments) {
                        originalJourney = [...originalJourney, ...(journeySegments ? splitAtSpaces(journeySegments) : null)?.map(s => parseInt(s)) ?? []];
                    }

                    if (interactionSegments) {
                        originalInteractions = [...originalInteractions, ...(interactionSegments ? splitAtSpaces(interactionSegments) : null)?.map(s => parseInt(s)) ?? [] ]
                    }
                }

                this.cxFilterVue = new Beef.VuejsView.View({
                    component: CxFilter,
                    props: {
                        originalJourney: originalJourney,
                        originalInteractions: originalInteractions,
                    }
                });
                this.cxFilterRegion.show(this.cxFilterVue);

                this.journeys.forEach(journey => {
                    this.cxFilterVue.vm.$on(`${journey.id}`, ids => {
                        const segments = new Set(ids);
                        this.model.set(`${journey.id}`, [...segments].map(id => "" + id).join(' '));
                    });
                    this.cxFilterVue.vm.$on(`${journey.id}:interactions`, ids => {
                        const segments = new Set(ids);
                        this.model.set(`${journey.id}:interactions`, [...segments].map(id => "" + id).join(' '));
                    });
                });
            }

            setTimeout(function() {
                try {
                    var tab = sessionStorage.getItem("beef:basic-filter:last-tab");
                    if (tab && tab.length) {
                        this.selectTab(tab);
                    }
                } catch (e) {
                    console.warn("Unable to select last used tab for basic filter panel", e);
                }
            }.bind(this));

        },

        events: {
            "click .interacts-with":"showInteractsWithProfilePicker",
            "click .hasReplyFromProfile": "showHasReplyFromProfilePicker",
            "click .published": "showDatePicker",
            "click .brand": "showBrand",
            "click a.advanced": "changeToAdvanced",
            "click .verification button": "verificationToModel",
            "click .unbiased-sample button": "unbiasedSampleToModel",
            "click .trash button": "trashToModel",
            "click .phrases input": "togglePhrase",
            "click .ids input": "toggleId",
            "click .ticketId input": "toggleTicketId",
            "click .reshares input": "toggleReshare",
            "click .id-list input": "toggleIdList",
            "click .filter-tab-header": "headerClicked",
            "keyup .proportion": "onProportionEnter",
            "keyup .seed": "onSeedEnter",
            "click .crowd-verified button": "crowdVerifiedToModel",
            "click .more-opts": "showMore",
            "click a": "linkClicked",
            "click [data-segment]": "segmentToModel",
            "click .cx-filter .cx-filter__pick-individual": "cxTogglePickIndividualChannels",
            "click .conduct-filter .conduct-filter__pick-individual": "conductTogglePickIndividualChannels",
            "mouseover [data-segment] .cx-filter__segment-name": "showSegmentTooltip"

        },

        modelEvents: {
            "change:filter": "onFilterChange",
            "change:verification": "verificationToView",
            "change:unbiasedSample": "unbiasedSampleToView",
            "change:trash": "trashToView",
            "change:proportion": "onProportionChange",
            "change:crowdVerified": "crowdVerifiedToView",
            "change:v4Issues": "v4IssuesToView",
            "change:previewBrand": "updateFilter"
        },

        showMore: function (ev) {
            var data_id = $(ev.target).attr("data-id");
            //rename btn
            if($("#"+data_id, this.$el).css('display') == 'none'){
                $(ev.target).html("Fewer options <span class='caret'></span>");
                $(ev.target).toggleClass("dropup");
            }
            else{
                $(ev.target).html("More options <span class='caret'></span>");
                $(ev.target).toggleClass("dropup");
            }

            // show options
            $("#"+data_id, this.$el).toggle();
        },

        linkClicked: function(ev) {
            var href = $(ev.target).closest("a").attr("href");
            if (href && href.charAt(0) == "/") {
                ev.preventDefault();
                Beef.router.navigate(href, {trigger: true});
                this.triggerMethod("navigated");    // Filter.js will close the dialog we are in (if any)
            }
        },

        headerClicked: function(ev) {
            ev.preventDefault();
            var $header = $(ev.target);
            this.selectTab($header.attr('data-tab'));
        },

        selectTab: function(tab) {
            this.$('.filter-tab-header').parent().toggleClass('active', false);
            var $header = this.$('[data-tab="' + tab + '"]');
            if (!$header.length) {
                this.selectTab('general-filter');
                return;
            }
            $header.parent().toggleClass('active', true);

            var $tab = $('.' + tab);
            this.$('.filter-tab').hide();
            try {
                sessionStorage.setItem("beef:basic-filter:last-tab", tab);
            } catch (e) {
                console.warn("Unable to set session storage for last used tab", e);
            }

            $tab.show();
        },

        onProportionEnter: function(ev) {
            if (ev.keyCode == 13) {
                ev.stopPropagation();
                this.$('.seed').focus();
            }
        },

        onSeedEnter: function(ev) {
            if (ev.keyCode == 13) {
                ev.stopPropagation();
                this.$('.proportion').focus();
            }
        },

        togglePhrase: function(ev) {
            var $t = $(ev.target);
            if (ev.target.checked) {
                this.model.set('phrase', $t.attr('data-id'));
            } else {
                this.model.unset('phrase');
            }
            this.updateFilter();
        },

        toggleId: function(ev) {
            var $t = $(ev.target);
            if (ev.target.checked) {
                this.model.set('id', $t.attr('data-id'));
            } else {
                this.model.unset('id');
            }
            this.updateFilter();
        },

        toggleTicketId: function(ev) {
            var $t = $(ev.target);
            if (ev.target.checked) {
                this.model.set('ticketId', $t.attr('data-id'));
            } else {
                this.model.unset('ticketId');
            }
            this.updateFilter();
        },

        toggleReshare: function(ev) {
            var $t = $(ev.target);
            if (ev.target.checked) {
                this.model.set('reshare', $t.attr('data-id'));
            } else {
                this.model.unset('reshare');
            }
            this.updateFilter();
        },

        toggleIdList: function(ev) {
            var idList = this.model.get('idList');
            idList.disabled = ev.target.checked
            this.updateFilter();
        },

        onClose: function() {
            if (this.popup) this.popup.close();
        },

        ok: function() {
            return this.model.get('ok');
        },

        verificationToModel: function(ev) {
            var id = $(ev.target).attr("data-id");
            if (id) this.model.set("verification", id);
        },

        verificationToView: function() {
            this.$el.find(".verification button").toggleClass("active", false);
            this.$el.find(".verification button[data-id='" + this.model.get('verification') + "']").toggleClass("active", true);
        },

        unbiasedSampleToModel: function(ev) {
            var id = $(ev.target).attr("data-id");
            var ans = undefined;
            if (id && id !== 'both') {
                ans = id;
            }
            this.model.set("unbiasedSample", ans);
        },

        unbiasedSampleToView: function() {
            this.$el.find(".unbiased-sample button").toggleClass("active", false);
            var unbiasedSample = this.model.get('unbiasedSample') || 'both';
            this.$el.find(".unbiased-sample button[data-id='" + unbiasedSample + "']").toggleClass("active", true);
        },

        crowdVerifiedToView: function () {
            this.$el.find(".crowd-verified button").toggleClass("active", false);
            var crowdVerified = this.model.get('crowdVerified') || 'all';
            this.$el.find(".crowd-verified button[data-id='" + crowdVerified + "']").toggleClass("active", true);
        },

        crowdVerifiedToModel: function (ev) {
            var id = $(ev.target).attr("data-id");
            var ans = undefined;
            if (id && id !== 'all'){
                ans = id;
            }
            this.model.set("crowdVerified", ans);
        },

        trashToModel: function(ev) {
            var id = $(ev.target).attr("data-id");
            if (id) this.model.set("trash", id);
        },

        trashToView: function() {
            this.$el.find(".trash button").toggleClass("active", false);
            this.$el.find(".trash button[data-id='" + this.model.get('trash') + "']").toggleClass("active", true);
        },

        showSegmentTooltip: function(ev) {
            var node = ev.currentTarget;
            var parent = node.parentElement;
            var segmentId = parseInt(parent.dataset.segment);
            var segment = getSegment(segmentId);

            // Don't need to show tooltips for this.
            if (this.journey && segment && this.journey.id === segment.id) return;
            if (this.risk && segment && this.risk.id === segment.id) return;

            if (segment) {
                Beef.Tooltip.show({
                    text: substituteTagParamaters(segment.clientDescription),
                    target: parent,
                    positions: ['right', 'left'],
                    autoclose: true
                });
            }
        },

        cxTogglePickIndividualChannels: function(ev) {
            var $target = ev.currentTarget;
            $target.classList.toggle("active");

            var $channels = this.$(".cx-filter .cx-filter__channels");
            $channels.toggleClass("cx-filter--show-hidden", $target.classList.contains("active"));
        },

        conductTogglePickIndividualChannels: function(ev) {
            var $target = ev.currentTarget;
            $target.classList.toggle("active");

            var $channels = this.$(".conduct-filter .conduct-filter__channels");
            $channels.toggleClass("cx-filter--show-hidden", $target.classList.contains("active"));
        },

        segmentToModel: function(ev) {
            if (!this.journey && !this.risk) return;

            var journey = this.journey;
            var channels = this.channels;
            var conduct = this.risk;
            var journeyNonAbove = journey && journey.children.find(function(c) { return c.flag === "NONE_OF_THE_ABOVE"; });
            var channelsNonAbove = channels && channels.children.find(function(c) { return c.flag === "NONE_OF_THE_ABOVE"; });
            var conductNonAbove = conduct && conduct.children.find(function(c) { return c.flag === "NONE_OF_THE_ABOVE"; });

            var segmentId = parseInt(ev.currentTarget.dataset.segment);
            var segmentIds = this.model.get("segments")
                ? new Set(splitAtSpaces(this.model.get("segments"))
                              .filter(function(c) { return c !== "&" })
                              .map(function(id) { return parseInt(id)}))
                : new Set();

            var getSegmentString = function() {
                if (segmentIds.size === 0) return "";
                var string = Array.from(segmentIds).join(" ");
                if (segmentIds.size > 1) string = "& " + string;
                return string;
            };


            var clearChannels = function() {
                if (channels) {
                    this.model.unset(channels.id);
                    segmentIds.delete(channels.id);
                    segmentIds.delete(-channels.id);
                    if (channelsNonAbove) {
                        segmentIds.delete(channelsNonAbove.id);
                        segmentIds.delete(-channelsNonAbove.id);
                    }
                }
            }.bind(this);

            if (journey && segmentId === -1) { // user wants to clear out journey related sections
                this.model.unset(journey.id);
                this.model.unset(journey.id + ":interactions");
                segmentIds.delete(journey.id);
                segmentIds.delete(-journey.id);
                if (journeyNonAbove) {
                    segmentIds.delete(journeyNonAbove.id);
                    segmentIds.delete(-journeyNonAbove.id);
                }
                clearChannels();

                this.model.set("segments", getSegmentString());
                return;
            }

            if (conduct && segmentId === -2) { // user wants to clear out conduct related selections
                this.model.unset(conduct.id);
                segmentIds.delete(conduct.id);
                segmentIds.delete(-conduct.id);
                if (conductNonAbove) {
                    segmentIds.delete(conductNonAbove.id);
                    segmentIds.delete(-conductNonAbove.id);
                }
                clearChannels();

                this.model.set("segments", getSegmentString());
                return;
            }

            if (journeyNonAbove && channels && segmentId === journeyNonAbove.id) {
                // We are selecting "no CX", so unselect any channels.
                clearChannels();
            }

            var journeySegments = new Set(journey && this.model.get(journey.id)
                ? splitAtSpaces(this.model.get(journey.id))
                      .filter(function(c) { return c !== "&" })
                      .map(function(id) { return parseInt(id)})
                : []);

            var interactionSegments = new Set(journey && this.model.get(journey.id + ":interactions")
                ? splitAtSpaces(this.model.get(journey.id + ":interactions"))
                      .filter(function(c) { return c !== "&" })
                      .map(function(id) { return parseInt(id)})
                : []);

            var conductSegments = new Set(conduct && this.model.get(conduct.id)
                ? splitAtSpaces(this.model.get(conduct.id))
                      .filter(function(c) { return c !== "&" })
                      .map(function(id) { return parseInt(id)})
                : []);

            var channelSegments = new Set(journey && channels && this.model.get(channels.id)
                ? splitAtSpaces(this.model.get(channels.id))
                      .filter(function(c) { return c !== "&" })
                      .map(function(id) { return parseInt(id)})
                : []);

            var segment = getSegment(segmentId);
            var segmentList = getSegmentList(segmentId);


            var appropriateList = [];
            if (journey && segmentList.id === journey.id) appropriateList = !segment.interaction ? journeySegments : interactionSegments;
            else if (conduct && segmentList.id === conduct.id) appropriateList = conductSegments;
            else appropriateList = channels && channels.id ? channelSegments : [];

            if (journeyNonAbove && segmentList.id === journey.id) {
                segmentIds.delete(journeyNonAbove.id);
                segmentIds.delete(-journeyNonAbove.id);
            }

            if (conductNonAbove && segmentList.id === conduct.id) {
                segmentIds.delete(conductNonAbove.id);
                segmentIds.delete(-conductNonAbove.id);
            }

            if (channels && segmentList.id === channels.id) {
                // If channels have been selected, it's not possible
                // to have channels with no CX. Deselect the no CX.
                if (channelsNonAbove) segmentIds.delete(channelsNonAbove.id);
                if (channelsNonAbove) segmentIds.delete(-channelsNonAbove.id);
                if (segment.flag !== "NONE_OF_THE_ABOVE") {
                    if (journeyNonAbove) segmentIds.delete(journeyNonAbove.id);
                    if (journeyNonAbove) segmentIds.delete(-journeyNonAbove.id);
                }
            }

            // Remove the parent if it is present.
            if (segmentList && segmentList.id !== segmentId) {
                if (segmentIds.has(segmentList.id) && segment.flag !== "NONE_OF_THE_ABOVE") {
                    // We want to make sure that only the children remain in the appropriate list
                    segmentList.children.forEach(function(c) {
                        if (c.flag !== "NONE_OF_THE_ABOVE" && c.interaction === segment.interaction) {
                            appropriateList.add(c.id)
                        }
                    })
                }
                segmentIds.delete(segmentList.id);
                segmentIds.delete(-segmentList.id);
            }

            // Top level gets added to segment IDs.
            if (segmentList.id === segmentId) {
                if (segmentIds.has(segmentId) || segmentIds.has(-segmentId)) {
                    segmentIds.delete(segmentId);
                    segmentIds.delete(-segmentId);
                } else {
                    segmentIds.add(segmentId);
                }
            }

            if (appropriateList.has(segmentId) || appropriateList.has(-segmentId)) {
                appropriateList.delete(segmentId);
                appropriateList.delete(-segmentId);
            } else if (journey && journey.id === segment.id) {
                journeySegments.clear();
                interactionSegments.clear();
            } else if (conduct && conduct.id === segment.id) {
                conductSegments.clear();
            } else {
                var union = segmentUnion(segmentId, appropriateList);
                appropriateList.clear();
                union.forEach(function(s) { appropriateList.add(s.id); });
            }

            // parents are in a different list
            if (segmentList.id === segmentId) {
                appropriateList.delete(segmentId);
                appropriateList.delete(-segmentId);
            }

            // If all channels are selected, lets change those tags to the parent tag
            if (channels && channels.id === segmentList.id) {
                var allChannels = channels.children.every(function(c) {
                    return c.flag === "NONE_OF_THE_ABOVE" || appropriateList.has(c.id);
                });

                if (allChannels) {
                    channels.children.forEach(function(c) {
                        if (c.flag !== "NONE_OF_THE_ABOVE") appropriateList.delete(c.id);
                    });
                    segmentIds.add(channels.id);
                }
            }

            var updates = {segments: getSegmentString()};
            if (journey) {
                updates[journey.id] = Array.from(journeySegments).join(" ");
                updates[journey.id + ":interactions"] = Array.from(interactionSegments).join(" ");
            }
            if (channels) updates[channels.id] = Array.from(channelSegments).join(" ");
            if (conduct) updates[conduct.id] = Array.from(conductSegments).join(" ");
            this.model.set(updates);
        },

        /** Takes CX and Risk / Conduct data in our model and updates the UI to reflect this model. */
        segmentsToView: function() {
            this.$el.find("[data-segment]").toggleClass("active", false);
            this.$el.find("[data-segment]").toggleClass("negative", false);
            this.$el.find("[data-segment]").toggleClass("active--implicit", false);

            var $interactions = this.$el.find(".cx-filter .cx-filter__interactions h5");
            $interactions.toggleClass("active", false);

            var $stages = this.$el.find(".cx-filter .cx-filter__journey h5");
            $stages.toggleClass("active", false);

            var segments = new Set();
            var andSegments = true,
                andInteractionSegments = true;

            var journey = this.journey;
            var channels = this.channels;
            var risk = this.risk;

            var addSegment = function(idString) {
                var values = splitAtSpaces(idString)
                                     .filter(function(id) { return id !== "&" })
                                     .map(function(id) { return parseInt(id) });
                values.forEach(function(id) { segments.add(id) });
                return values;
            };

            if (journey && this.model.get(journey.id)) {
                addSegment(this.model.get(journey.id));
            }
            if (journey && this.model.get(journey.id + ":interactions")) {
                addSegment(this.model.get(journey.id + ":interactions"));
            }
            if (channels && this.model.get(channels.id)) {
                addSegment(this.model.get(channels.id));
            }
            if (risk && this.model.get(risk.id)) {
                addSegment(this.model.get(risk.id));
            }
            if (this.model.get("segments")) {
                var segmentIds = addSegment(this.model.get("segments"));
                andSegments = this.model.get("segments").startsWith("&") || segmentIds.length <= 1;
                andInteractionSegments = !journey || !segmentIds.find(function(id) { return id === journey.id });
            }

            if (journey) {
                var allInteractions = journey.children.filter(function(c) { return c.interaction }).map(function(c) { return c.id });
                var allInteractionsPresent = segments.has(journey.id) || allInteractions.every(function(id) { return segments.has(id) });
                var allStages = journey.children.filter(function(c) { return !c.interaction  && c.flag !== "NONE_OF_THE_ABOVE" }).map(function(c) { return c.id });
                var allStagesPresent = segments.has(journey.id) || allStages.every(function(id) { return segments.has(id) });

                if (allInteractionsPresent) $interactions.toggleClass("active", true);
                if (allStagesPresent) $stages.toggleClass("active", true);
            }

            this.$('.cx-filter').attr('data-and', !!andSegments);
            this.$('.cx-filter').attr('data-interaction-and', !!andInteractionSegments);

            segments.forEach(function(s) {
                var $segment = this.$el.find("[data-segment='" + Math.abs(s) + "']");
                $segment.toggleClass("active", true);
                $segment.toggleClass("negative", s < 0);

                var segment = getSegment(s);
                if (segment && segment.children) {
                    segment.children.forEach(function (c) {
                        if (c.flag !== "NONE_OF_THE_ABOVE") {
                            var $child = this.$el.find("[data-segment='" + c.id + "']");
                            $child.toggleClass("active", true);
                            $child.toggleClass("negative", s < 0);
                            $child.toggleClass("active--implicit", !segments.has(c.id));
                        }
                    }, this);
                }
            }, this);
        },

        v4IssuesToView: function() {
            this.$('.v4Issues').html(Handlebars.render(require("@/dashboards/filter/V4Issues.handlebars"), this.model.attributes));
        },

        onProportionChange: function() {
            var p = this.model.get('proportion');
            if (!isNaN(parseFloat(p))) {
                if (isNaN(parseInt(this.model.get('seed')))) this.model.set('seed', Math.floor(Math.random() * 1000));
            } else if (!p) {
                this.model.set('seed', "");
            }
        },

        updateFilter: function() {
            var attrs = this.model.attributes;

            this.authorNameOptions.filterAttrs = Object.assign({}, attrs);
            var brand = this.options.brand;
            if (attrs.brand) {
                if (brand) brand += " " + attrs.brand;
                else brand = attrs.brand;
            } else if (attrs.previewBrand && !attrs.brand) {
                brand = attrs.previewBrand;
            }
            this.authorNameOptions.filterAttrs.brand = brand;

            this.$('.no-preview-brand-warning').toggle(this.options.previewBrandWarning && !brand);

            var filter = buildBasicFilter(attrs);
            if (filter != this.model.get('filter')) {
                this.ignoreFilterChange = true;
                this.model.set({'filter': filter, v4Issues: listV4Issues(filter)});
                this.ignoreFilterChange = false;
            }
        },

        onFilterChange: function() {
            var filter = this.model.get('filter');
            var ac = this.model.getAncestorProperty('accountCode');

            if (this.ignoreFilterChange) return;

            var attrs, root = null;
            try {
                root = parseFilterString(filter);
            } catch (e) {
                if (e.stack) console.error(e, e.stack);
                else console.error(e)
                attrs = {error: e.toString()};
            }

            if (root) {
                attrs = convertExpToAttrs(root, {noPublished: this.options.noPublished});
                attrs.v4Issues = listV4Issues(filter);
                if (attrs.errors) {
                    if (VuexStore.state.account?.dev) {
                        console.info("The following filter elements were too advanced for the basic filter: " + attrs.errors.join(", "));
                    }
                    attrs = {error: "complex"};
                } else {
                    attrs.error = null;
                }
            }

            this.model.set(attrs);
        },

        changeToAdvanced: function() {
            this.trigger("changeEditor", "advanced");
        },

        getError: function() {
            return this.model.get('error');
        },

        showBrand(event) {
            if (event.target.classList.contains("close")) {
                const tag = event.target.closest('.tag');
                const id = tag.dataset.value;

                const selectedBrands = this.model.get("brand")
                    ? (splitAtSpaces(this.model.get("brand"))
                        ?.filter(bid => bid !== id)
                        ?.join(" ") ?? "")
                    : "";

                this.model.set('brand', selectedBrands);
                return;
            }

            const idToBrand = VuexStore.getters.idToBrand;
            const includedBrands = this.model.get("brand")
                ? (splitAtSpaces(this.model.get("brand"))
                    ?.map(b => Number.parseInt(b))
                    ?.filter(id => id > 0)
                    ?.map(id => idToBrand.get(id)) ?? [])
                : [];
            const excludedBrands = this.model.get("brand")
                ? (splitAtSpaces(this.model.get("brand"))
                    ?.map(b => Number.parseInt(b))
                    ?.filter(id => id < 0)
                    ?.map(id => idToBrand.get(Math.abs(id))) ?? [])
                : [];

            showBrandPickerDialog({
                includedBrands,
                excludedBrands,
                showChildren: true,
                useAdvancedFormat: true,
                excludeChildren: this.model.get("brand")?.startsWith('x ') ?? false,
                onSelected: items => {
                    let idString = items
                        ?.map(b => b.brand.id * (b.excluded ? -1 : 1))
                        ?.join(" ");
                    if (items.length && items[0].excludeChildren) idString = "x " + idString;
                    this.model.set("brand", idString);
                }
            });
        },

        showDatePicker(event) {
            const published = this.model.get("published");

            showPublishedPickerDialog({
                date: published,
                allowHours: true,
                allowTime: true,
                onSelected: date => {
                    this.model.set("published", date);
                }
            });
        },

        showInteractsWithProfilePicker(event){
            if (!features.newProfilePicker()) return;

            showNewProfilePicker((values) => this.model.set('interactsWith', values.join(" ")),
                this.model.get('interactsWith')?.split(" ")
                    .filter(it => !Number.isNaN(parseInt(it)))
                    .map(s => parseInt(s)), true);
        },

        showHasReplyFromProfilePicker(event){
            if (!features.newProfilePicker()) return;

            showNewProfilePicker((values) => this.model.set('hasReplyFromProfile', values.join(" ")),
                this.model.get('hasReplyFromProfile')?.split(" ")
                    .filter(it => !Number.isNaN(parseInt(it)))
                    .map(s => parseInt(s)), true);
        }

    });





});