import axios from 'axios';
import moment from "moment";

let mostRecentVersion;

/**
 * This returns the most recent commit hash for the Analyse source code
 */
export function getMostRecentCommitHash() {
    return mostRecentVersion ?? COMMITHASH;
}

/**
 * Periodically checks for client app version changes when the app is idle (no user interaction for a while)
 * and no popups are visible (to avoid losing edits in a dialog) and not in fullscreen mode (because we can't
 * programmatically get back into fullscreen mode). Reloads the app when a version change is detected.
 */
export function initialiseVersionCheck() {
    // Capture click events. This captures before clicks are passed to children, so cannot be prevented or
    // stopped. It is passive, so has a limited performance penalty, and cannot call preventDefault.
    document.addEventListener('click',
        () => lastClick = moment(),
        {capture: true, passive: true});
    setInterval(checkVersion, 20 * 60 * 1000);  // every 20 minutes
}

const currentVersion = COMMITHASH;
let lastClick = moment();               // The time the user last interacted with the system


function okToReload() {
    if (BigScreen.element) return false;                                                // in fullscreen mode
    if (lastClick.isAfter(moment().subtract(5, 'minutes'))) return false;   // No activity for 5 minutes

    var $p = $('body > .popup:visible');
    for (var i = 0; i < $p.length; i++) if (!$($p[i]).hasClass("disposable-popup")) return false;
    return true;
}

async function checkVersion() {
    try {
        if (!okToReload()) return;
        const response = await axios.get("/version.txt");
        const data = mostRecentVersion = response.data;
        if (data && data !== currentVersion && okToReload()) location.reload();
    } catch(e) {
        console.warn("Error checking version", e);
    }
}



