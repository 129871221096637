/**
 * Select a time representation for the interaction response time.
 */

import {formatSeconds} from "@/app/utils/Format";

Beef.module("InteractionResponseTimePicker").addInitializer(function (_startupOptions) {

    const units = /[a-z]+/i;
    this.View = Beef.IntegerPicker.View.extend({
        attribute: 'Interaction Response Time'
    });

    const parser = function (v) {
        let n = parseFloat(v);
        if (Number.isNaN(n)) return null;
        const i = v.search(units);
        if (i > 0) {
            const c = v.charAt(i);
            if (c === 'd') n *= 60 * 60 * 24;
            else if (c === 'h') n *= 60 * 60;
            else if (c === 'm') n *= 60;
        }
        return n;
    };


    const formatter = function (v) {
        return formatSeconds(v);
    };

    const stdOptions = function () {
        return {
            formatter: formatter,
            parser: parser,
            hint: "Enter a number followed by d (days), h (hours), m (minutes) or s (seconds)",
            defaultGreaterThan: "10m",
            defaultLessThan: "10m",
            defaultMin: "5m",
            defaultMax: "10m"
        };
    };

    this.converter = Beef.IntegerPicker.createConverter("Interaction response time", "Interaction response time", stdOptions());

    this.attach = function (view, selector, attribute, options) {
        options = Object.assign({}, stdOptions(), options);
        Beef.IntegerPicker.attachPicker(view, selector, attribute, this.View, options);
    };
});
