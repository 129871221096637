var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<strong class=\"positive-sentiment\">Positive<i class=\"icon-circle\"></i></strong>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<strong class=\"neutral-sentiment\">Neutral<i class=\"icon-circle\"></i></strong>";
},"5":function(container,depth0,helpers,partials,data) {
    return "<strong class=\"negative-sentiment\" >Negative<i class=\"icon-circle\"></i></strong>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),">",0,{"name":"ifcond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":120}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),"==",0,{"name":"ifcond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":116}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),"<",0,{"name":"ifcond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":118}}})) != null ? stack1 : "")
    + "\n sentiment towards <strong>"
    + alias2(__default(require("../helpers/brandName.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"brand") : depth0),{"name":"brandName","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":46}}}))
    + "</strong>.\n</p>\n<p>"
    + alias2(__default(require("../helpers/renderMentionVerification.js")).call(alias1,{"name":"renderMentionVerification","hash":{},"data":data,"loc":{"start":{"line":6,"column":3},"end":{"line":6,"column":32}}}))
    + ".</p>";
},"useData":true});