import {findLandingPageErrors} from "@/app/toplevel/dashboards/DashboardUtilities";
import {
    checkAccount, checkAccountProfiles, checkBrandCrowdRules,
    checkBrandsForExplore, checkImportedBrands,
    checkMissingBrandCategories, checkNotifications, checkRules, checkSorter,
    consolidateWarnings,
    countUniqueWarnings, sortWarnings
} from "@/store/vuex/dataChecks";
import moment from "moment";

let checkAllPromise = null;

export default {
    namespaced: true,

    state: {
        isChecking: false,
        warnings: null
    },

    mutations: {
        setIsChecking: (state, value) => state.isChecking = !!value,
        setWarnings: (state, value) => state.warnings = value
    },

    getters: {
        uniqueWarningCount: state => countUniqueWarnings(state.warnings),
        volumeWarningCount: state => {
            let warningCount = 0;

            if (!state.warnings) return warningCount;

            state.warnings.forEach(warning => {
                if (warning.id === "BRAND_VOLUME_OVER_LIMIT" || warning.id === "BRAND_VOLUME_OVER_TEMP_LIMIT") warningCount++;
            });

            return warningCount;
        }
    },

    actions: {
        async checkAllHealth({commit, state, dispatch, rootState}, forceRefresh) {
            if (state.warnings && !forceRefresh) return;
            if (checkAllPromise) return checkAllPromise;

            if (!rootState.account?.code) {
                commit('setWarnings', []);
                return;
            }

            async function impl() {
                try {
                    await commit('setIsChecking', true);

                    if (forceRefresh) {
                        await Promise.all([
                            dispatch("refreshBrands", true, {root: true}),
                            dispatch("profiles/refreshProfile", true, {root: true}),
                            dispatch("dashboards/refreshDashboards", true, {root: true})
                        ]);
                    }

                    const account = rootState.account;
                    if (account.inactive) {
                        let description = "This account is inactive";
                        if (account.lastUpdated) {
                            const deletionDate = moment(account.lastUpdated).add(1, 'year');
                            description += " and will be deleted on or after " + deletionDate.format("YYYY-MM-DD");
                        }
                        await commit('setWarnings', [{
                            id: "IS_INACTIVE",
                            description: description
                        }]);
                    } else {
                        let warnings = await checkAccount();
                        await commit('setWarnings', sortWarnings(warnings));

                        warnings = await checkBrandsForExplore();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await checkMissingBrandCategories();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await findLandingPageErrors();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await checkImportedBrands();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await checkSorter();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await checkAccountProfiles();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await checkBrandCrowdRules();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await checkNotifications();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));

                        warnings = await checkRules();
                        await commit('setWarnings', sortWarnings([...state.warnings, ...warnings]));
                    }
                } finally {
                    commit('setWarnings', consolidateWarnings(state.warnings));
                    commit('setIsChecking', false);
                    checkAllPromise = null;
                }
            }

            return checkAllPromise = impl();
        },
    }
}