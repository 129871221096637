import {
    cheat as commentCheat,
    rpcs as rpcsStats,
    sentiment as sentimentStats,
    socialStats,
    topLevelStats
} from "../../../markdown/MarkdownExamples";
import {features} from "@/app/Features";

const utilities = {
    title: "Utilities",
    description: "Little tools to make your life easier",
    expanded:
`Useful tools that don't directly report on your conversations, but allow you to customise your
 dashboards and display them in a more suitable manner.`,
    asButton: "Utilities",
    metrics: [
        {
            id: "comment",
            title: "Commentary",
            description: "Add a text box to include your own comments",
            more: `The commentary box lets you write notes and descriptions in your dashboards. 
                if you want to tell someone what a particular metric does, a commentary box is the tool to use. 
                It can also a range of very flexible statistics — try them out using the <em>Add</em> button.`,
            hero: true,
            image: require("../assets/utilities/comments.png"),
            keywords: ["markdown", "text"],
            widgetDesc: {
                type: "Text",
                notification: "Added a <strong>Commentary</strong> box"
            },
            options: [
                {
                    key: "toplevel",
                    title: "Top level stats",
                    action: metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "comment/toplevel",
                                widgetDesc: {
                                    type: "Text",
                                    width: 2,
                                    height: 2,
                                    text: topLevelStats
                                }
                            })
                    }
                },
                {
                    key: "sentiment",
                    title: "Sentiment",
                    action: metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "comment/sentiment",
                                widgetDesc: {
                                    type: "Text",
                                    width: 2,
                                    height: 2,
                                    text: sentimentStats
                                }
                            })
                    }
                },
                {
                    key: "socialstats",
                    title: "Social stats",
                    action: metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "comment/socialstats",
                                widgetDesc: {
                                    type: "Text",
                                    width: 2,
                                    height: 4,
                                    text: socialStats
                                }
                            })
                    }
                },
                {
                    key: "rpcs",
                    title: "Priority conversations",
                    action: metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "comment/rpcs",
                                widgetDesc: {
                                    type: "Text",
                                    width: 2,
                                    height: 4,
                                    text: rpcsStats
                                }
                            })
                    }
                }


            ],
            reference: {
                type: "Text",
                caption: "Commentary box cookbook",
                width: 6,
                height: 6,
                text: commentCheat,
                tooltip: "Add a cookbook showing the things you can do in a commentary box",
                notification: "Added the <strong>Commentary Box Cookbook</strong>"
            }
        },
        {
            id: "summary",
            title: "Mention Summary",
            description: "Summarises the content of the mentions selected by your filter",
            more: "These summaries are created using our Large Language Model tools",
            image: require("../assets/utilities/comment-summary.webp"),
            keywords: ["markdown", "text", "summary", "mentions", "summarise", "llm", "openai"],
            widgetDesc: {
                type: "Text",
                caption: "Summary",
                width: 4,
                height: 4,
                text: "> ## {{summarise-mentions}}",
                notification: "Added a <strong>Mention Summary</strong>",
                "hidden-title": true
            },
        },

        {
            id: "filter",
            title: "Filter Summary",
            description: "Display a summary of the criteria you're using for this analysis",
            more: "This can be useful while setting up a dashboard to keep track of the filter that you are using",
            image: require("../assets/utilities/filter.png"),
            keywords: ['english'],
            widgetDesc: {
                caption: "English Filter Summary",
                type: "Text",
                width: 4,
                height: 2,
                notification: "Added a <strong>English Filter Summary</strong>",
                "hidden-title": true,
                text: "> ## {{englishFilter}}",
            },
            options: [
                {
                    key: "rawFilter",
                    title: "Raw Filter Summary",
                    action: metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "filter/raw",
                                widgetDesc: {
                                    caption: "Raw Filter Summary",
                                    type: "Text",
                                    width: 4,
                                    height: 2,
                                    notification: "Added a <strong>Raw Filter Summary</strong>",
                                    "hidden-title": true,
                                    text: "> ## {{rawFilter}}",
                                }
                            });
                    }
                }
            ]
        },

        {
            id: "comment++",
            title: "Commentary++",
            description: "A new way to add comments",
            more: "It's better",
            image: require("../assets/utilities/comment-summary.webp"),
            keywords: ["markdown", "text", "summary", "mentions", "summarise", "llm", "openai"],
            widgetDesc: {
                type: "CommentaryPlusWidget",
                caption: "Commentary Plus",
                width: 4,
                height: 4,
                text: "{\"blocks\":[{\"id\":\"oGsFZIgBtc\",\"type\":\"header\",\"data\":{\"text\":\"Heading\",\"level\":2}},{\"id\":\"58RUDEsv8U\",\"type\":\"paragraph\",\"data\":{\"text\":\"Some text\"}}],\"version\":\"2.26.5\"}",
                notification: "Added a <strong>Commentary Plus Box</strong>",
                "hidden-title": true
            },
            hide: true
        },
    ]
};

export default utilities;

export function init() {
    utilities.metrics.find((option) => option.id === "comment++").hide = !features.commentaryPlus();
}
