<template>
    <span>
        <span v-for="(chunk,index) in chunks" :key="index" :class="chunk[1] ? 'highlight' : ''">{{ chunk[0] }}</span>
    </span>
</template>

<script>

/*
    Chunks a string according to a match on a query, adding a highlight class to matching chunks
    Case insensitive
*/
export default {
    props: {
        string: String,
        query: String
    },
    computed: {
        chunks() {
            if (!this.query || !this.string) return [[this.string]]
            const searchChunks = this.string.toLowerCase().split(this.query.toLowerCase());
            const chunks = [];
            let cur = 0;
            searchChunks.forEach((chunk, i, arr) => {
                if (i < arr.length - 1){
                    chunks.push([this.string.slice(cur, cur + chunk.length )]);
                    cur += chunk.length;
                    chunks.push([this.string.slice(cur, cur + this.query.length), true]); // true -> highlight query
                    cur += this.query.length;
                } else {
                    chunks.push([this.string.slice(cur)])
                }
            });
            return chunks
        }
    }
}
</script>