/**
* Formats a user for display. Provides tooltips.
*/
<template>
    <old-tooltip :label="tooltip" class="be-user">{{formatUser(user)}}</old-tooltip>
</template>

<script>
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import {formatUser} from "@/app/utils/Format";
import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
        components: {OldTooltip},
        store: VuexStore,
        props: {
            user: {
                type: Object,
                required: true
            }
        },

        computed: {
            ...mapState({
                currentUser: state => state.user
            }),
            tooltip() {
                if (this.currentUser.id === this.user.id) return `This is you: ${this.currentUser.email}`;
                if (!this.currentUser.admin) return null;
                if (this.user.system) return `${this.user.email}. This is a DataEQ Service.`;
                if (this.user.admin) return `${this.user.email}. They are current a DataEQ staff member.`;
                return this.user.email;
            }
        },

        methods: {
            formatUser
        }
    }
</script>

<style scoped lang="sass">
    .be-user
        display: inline-block
        width: max-content
</style>