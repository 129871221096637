import {encodeFilename, isFunction} from "../../app/utils/Util";
import {earliestDate, latestDate, parseFilterString} from "@/dashboards/filter/FilterParser";
import moment from "moment";
import {defaultCurrency, getAccountCurrency, getRateFromZar} from "@/app/utils/Currency";
import {formatPercentage} from "@/app/utils/Format";
import _ from 'underscore';
import {capitalise, isString, isUnknown} from "@/app/utils/StringUtils";

/**
 * Provides basic and common functionality for displaying charts, including
 * the ability to load data.
 *
 * <p>
 * Important fields to examine is the ChartView field, which defines how the chart should
 * be displayed, and loadFromCount, which makes it easier to load data from chicken.
 *
 * <p>
 * You can implement a new chart by extending this ItemView and implementing the view's
 * refresh field. Refresh should load the data into this view's chartModel.
 *
 * <p>
 * See showLoader() and hideLoader() to show and hide a loading message.
 *
 * <p>
 * getCsvEntries should be implemented to return the urls array of url/name pairs expected by the chicken
 * zip endpoint.
 */
Beef.ChartItemView = Beef.BoundItemView.extend({

    template: require("@/dashboards/charts/ChartItemView.handlebars"),
    regions: {
        chart: "> div.chart"
    },

    //---------------------------

    modelEvents: {
        "change:_series change:max-items": 'refresh',
        "change:hide-others change:hide-unknown": 'setChartData',
        "change:width change:height": "widthHeightUpdated",
        "click:bar": "dataSelected",
        "right-click:bar": "dataSelectedTab",
        "middle-click:bar": "dataSelectedTab"
    },

    //---------------------------

    /**
     * This is a default implementation of refresh. If you can simply use loadSeries by passing a query,
     * merely define the query. The query will also be reused by the default implementation of getCsvEntries.
     */
    refresh: function() {
        if (this.notSupportedInV4) {
            this.model.generalData.set({'_completed': true, '_message': 'This metric is no longer available'});
        } else {
            if (this.query) this.loadSeries(this.query);
        }
    },

    //---------------------------

    onFirstRender: function() {
        if (!this.model.get('chart-type')) this.model.set({'chart-type': this.defaultChartType}, {silent: true});
        if (!this.model.get('label-type')) this.model.set({'label-type': this.defaultLabelType}, {silent: true});
        if (!this.model.has('max-items')) this.model.set({'max-items': this.maxItems}, {silent: true});

        this.setChartData();
        if (!this.model.generalData.has('_data')) this.refresh();

        setTimeout(function() {
            // only display chart when we are in the DOM so we can get width + height of containing element
            if (this.chart) {
                var chart = new this.ChartView({model: this.model});
                chart.fieldName = this.fieldName;
                chart.noDataMessage = this.noDataMessage;
                this.chart.show(chart);
            }
        }.bind(this));
    },

    //---------------------------

    /**
     * Runs various cleanup code for our charts.
     */
    close: function() {
        Beef.BoundItemView.prototype.close.call(this, arguments);
        Beef.Tooltip.close();
    },

    //---------------------------

    showLoader: function() {
        this.model.generalData.set('_loading', true);
    },

    hideLoader: function() {
        this.model.generalData.set('_loading', false);
    },

    //---------------------------

    showEditDialog: function() {
        this.model.generalData.trigger('show:edit');
    },

    //---------------------------

    widthHeightUpdated: function() {
        if (this.sizeUpdateBusy) return; // changing w and h together triggers 2 events so skip the 2nd
        this.sizeUpdateBusy = true;

        // This delay occurs so that we can get the size of the container that the chart is drawn in,
        // and avoid redrawing and reanimating the chart moving multiple times on a widget resize.
        // If you change the delay below, you should change the animation length in Widget.css as well.
        this.chart.$el.css({opacity: 0});
        setTimeout(function() {
            this.sizeUpdateBusy = false;
            if (this.chart) {
                this.chart.currentView.render();
                this.chart.$el.css({opacity: 1});
            }
        }.bind(this), 350);
    },

    //---------------------------

    setChartData: function() {
        this.setFootnotes();
    },

    setFootnotes: function() {
        var data = this.model.generalData.get('_data');
        if (!data || !data[0].values[0]) {
            Beef.Footnotes.clearFootnotes(this);
            return;
        }

        // The key holding the type of media.
        var key = _(data[0].values[0]).chain().keys().find(function (d) {
            var lower = d.toLowerCase();
            return lower !== "count" && lower !== "series";
        }).value();

        function countUnknown() {
            return _(data).reduce(function(memo, s) {
                return memo + _(s.values).reduce(function(memo, item) {
                    if (!isUnknown(item[key])) return memo;
                    return memo + item.count;
                }, 0);
            }, 0)
        }

        var maxItems = this.model.get('max-items');
        function countOthers() {
            var takeCount = maxItems - 1;
            return _(data).reduce(function(memo, s) {
                return _.chain(s.values)
                    .rest(takeCount)
                    .reduce(function(memo, num) {
                        return memo + num.count;
                    }, 0).value();
            }, 0);
        }

        var footnotes = [];
        var total = _(data).reduce(function(memo, s) {
            return memo + _(s.values).reduce(function(memo, item) { return memo + item.count; }, 0);
        }, 0);

        var footnoteCuttoff = 0.1;
        if (this.model.get('hide-others')) {
            var numOther = countOthers();
            let percentage = (numOther / total) * 100;
            if (percentage >= footnoteCuttoff) {
                footnotes.push(formatPercentage(percentage, 1) + " of mentions are from other " + this.pluralCategoryName +
                    " and have not been displayed above.")
            }
        }

        if (this.model.get('hide-unknown')) {
            var numUnknown = countUnknown();
            let percentage = (numUnknown / total) * 100;
            if (percentage >= footnoteCuttoff) {
                footnotes.push(formatPercentage(percentage, 1) + " of mentions were of unknown " + this.categoryName +
                    " and have not been displayed above.")
            }
        }

        Beef.Footnotes.setFootnotes(this, footnotes);
    },

    //---------------------------

    /**
     * This allows data to be read from Chicken's count endpoint.
     * @param query A map of parameters to pass to the chicken endpoint, such as groupby, filter, and so on.
     * @param onComplete An optional function to call when the load is complete.
     */
    loadFromCount: function(query, keys, onComplete) {
        //---------------------------------
        // Some book keeping

        var code = this.model.getAncestorProperty('accountCode');
        var chartData = [];

        var queryArray = Array.isArray(query) ? query : [query];
        var keyArray;
        if (_(keys).isUndefined()) keyArray = null;
        else keyArray = Array.isArray(keys) && !isString(keys) ? keys : [keys];

        var durationLength = function(d) {
            if (this.model.get('coarseness') == 'hourly') {
                var hours = d.asHours();
                var minutes = d.asMinutes() - hours * 60;
                return hours + (minutes > 0 ? 1 : 0);
            }
            return d.asDays();
        }.bind(this);

        // This function is called after all data has been obtained via chicken.
        // Data cleanup and so on happens here.
        var load = function(queryArray) {
            // See if we need to fill in missing dates.
            if (chartData[0].values[0] && chartData[0].values[0].published) {

                // Need to find the length of the longest comparison range. We assume that for shorter ranges, the missing
                // days are at the end. This is not necessarily true, since we could be looking at the first few mentions
                // in an account, but it will otherwise be true. Our UI currently (at June 3, '13) supports only entering
                // start dates.

                var maxDuration = moment.duration(0),
                    preserveHours = this.model.get('coarseness') == 'hourly',
                    startDates = [],
                    endDates = [];
                _(chartData).each(function(d, i) {
                    var tree    = parseFilterString(queryArray[i].filter),
                        start   = earliestDate(tree, preserveHours),
                        end     = latestDate(tree, preserveHours);
                    var duration = moment.duration(end - start);
                    if (durationLength(duration) > durationLength(maxDuration)) maxDuration = duration;
                    startDates[i] = start;
                    endDates[i] = end;
                });

                // first add the missing start and end dates
                _(chartData).each(function(d, i) {

                    // There might be no data, in which case we still want to fill in the missing values.
                    if (d.values.length === 1 && d.values[0].published.toLowerCase() === 'unknown') {
                        d.values = [];
                    }

                    var stepSize = 'days';
                    if (this.model.get('coarseness') == 'hourly') stepSize = 'hours';

                    var start    = startDates[i].clone(),
                        end      = endDates[i].clone().subtract(stepSize, 1),
                        duration = durationLength(moment.duration(end - start));

                    if (duration < durationLength(maxDuration) - 1) {
                        end = end.add(stepSize, (durationLength(maxDuration) - 1 - duration));
                    }

                    var actualStart = d.values.length ? new moment(_(d.values).first().published) : end.clone().add(stepSize, 1),
                        actualEnd   = d.values.length ? new moment(_(d.values).last().published) : end,
                        missingEndDays = durationLength(moment.duration(end.valueOf() - actualEnd.valueOf())),
                        missingStartDays = start ? (durationLength(moment.duration(actualStart.valueOf() - start.valueOf()))) : null;

                    var step = 1;
                    var startOf = 'day';
                    switch (this.model.get('coarseness')) {
                        case Beef.Widget.MetricSettings.Coarseness.hourly:
                            startOf = 'hour';
                            break;
                        case Beef.Widget.MetricSettings.Coarseness.weekly:
                            step = 7;
                            startOf = 'week';
                            missingEndDays -= step;
                            break;
                        case Beef.Widget.MetricSettings.Coarseness.monthly:
                            step = 31;
                            startOf = 'month';
                            missingEndDays -= step;
                            break;
                        case Beef.Widget.MetricSettings.Coarseness.yearly:
                            step = 366;
                            startOf = 'year';
                            missingEndDays -= step;
                            break;
                    }

                    if (start && start.unix() < actualStart.unix()) {
                        let e = start.clone();
                        if (startOf === 'week') e.day(-6); // Go to last Monday. Moment startOf doesn't support week.
                        var newDates = [];
                        for (let i = 0; i < missingStartDays; i += step) {
                            if (startOf !== 'week') e.startOf(startOf);
                            newDates.push({ published: e.format("YYYY-MM-DD HH:mm"), count: 0 });
                            e.add(stepSize, step);
                        }
                        d.values = newDates.concat(d.values);
                    }

                    if (actualEnd.unix() < end.unix()) {
                        let e = actualEnd.clone().add(stepSize, step);
                        for (let i = 0; i < missingEndDays; i += step) {
                            if (startOf !== 'week') e.startOf(startOf);
                            d.values.push({ published: e.format("YYYY-MM-DD HH:mm"), count: 0 });
                            e.add(stepSize, step);
                        }
                    }
                }.bind(this));

                var maxValues = _.chain(_(chartData).pluck('values'))
                    .pluck('length')
                    .max()
                    .value();

                // After adding the start and end days, make sure all have series have the same number of values.
                // Disparities are resolved by pushing dates onto series with less dates than the maximum across series.
                _(chartData).each(function(d, i) {

                    var stepSize = 'days';
                    if (this.model.get('coarseness') == 'hourly') stepSize = 'hours';

                    var start    = startDates[i].clone(),
                        end      = endDates[i].clone().subtract(stepSize, 1),
                        duration = durationLength(moment.duration(end - start));

                    if (duration < durationLength(maxDuration) - 1) {
                        end = end.add(stepSize, (durationLength(maxDuration) - 1 - duration));
                    }

                    var actualEnd   = d.values.length ? new moment(_(d.values).last().published) : end,
                        missingEndValues = maxValues - d.values.length;

                    var step = 1;
                    var startOf = 'day';
                    switch (this.model.get('coarseness')) {
                        case Beef.Widget.MetricSettings.Coarseness.hourly:
                            startOf = 'hour';
                            break;
                        case Beef.Widget.MetricSettings.Coarseness.weekly:
                            step = 7;
                            startOf = 'week';
                            break;
                        case Beef.Widget.MetricSettings.Coarseness.monthly:
                            step = 31;
                            startOf = 'month';
                            break;
                        case Beef.Widget.MetricSettings.Coarseness.yearly:
                            step = 366;
                            startOf = 'year';
                            break;
                    }

                    if (actualEnd.unix() <= end.unix()) {
                        var e = actualEnd.clone().add(stepSize, step);
                        for (let i = 0; i < missingEndValues; ++i) {
                            if (startOf !== 'week') e.startOf(startOf);
                            d.values.push({ published: e.format("YYYY-MM-DD HH:mm"), count: 0 });
                            e.add(stepSize, step);
                        }
                    }
                }.bind(this));
            }

            if (this.transformData) {
                chartData = this.transformData(chartData);
            }

            // See if we need to unify labelling.
            if (this.seriesMatcher) {
                this.seriesMatcher(chartData);
            }

            var complete = function() {
                this.setFootnotes();
                this.hideLoader();
                this.model.generalData.set('_data', chartData);

                if (isFunction(onComplete)) onComplete();
            }.bind(this);

            // Now we need to convert the AVE values into the account values.
            if (getAccountCurrency() === defaultCurrency) {
                complete();
            }
            else {
                getRateFromZar(getAccountCurrency(), function(rate) {
                    if (rate) {
                        _(chartData).each(function(s, i) {
                            for (let i = 0; i < s.values.length; i++) {
                                s.values[i].ave = s.values[i].ave * rate;
                            }
                        });
                    }
                    complete();
                }.bind(this));
            }

        }.bind(this);

        //---------------------------------
        // Begin loading data.

        this.showLoader();
        chartData = this.createRequests(load, queryArray, keyArray, code);  // load will be invoked once all requests complete
    },

    /**
     * Creates requests for each of the queries in the supplied array. By default, each request maps to a single data
     *     object, from which a chart series is created. This function can be overridden if a widget
     *     requires a different "request response" to "chart data" mapping that is not one-to-one.
     * @param load {Function} The function that is called after all the data is loaded.
     * @param queryArray {Object[]} An array with query objects, where each object contains properties such as filter
     *     and groupby.
     * @param keyArray {string[]} An array of chart labels. The size of this array must match the number of data objects
     *     that will be created.
     * @param code {string} The code of the account for whom the data is fetched.
     * @return {Object[]} An array of data objects, each of which will be a series in the resulting chart.
     */
    createRequests: function(load, queryArray, keyArray, code) {
        throw new Error("ChartItemView no longer supported")
    },

    /** This is called when data for a series has been loaded. Override if you need to transform the data in some way. */
    onSeriesLoaded: function(data) {
        return data;
    },

    include: function() {
        // don't put things that pull in sentiment into the default list e.g. brand-index, sentiment-reach
        // this causes extra work in chicken
        return this.options.include || "ave,ots,engagement,percentages";
    },

    /**
     * The default ChartView type. This should be an extension of Beef.ChartView.
     */
    ChartView: null,

    /**
     * The maximum number of items to show.
     */
    maxItems: 8,

    /**
     * The name of the field returned from chicken.
     */
    fieldName: null,

    /**
     * The name of the field that can be used for display purposes, such as 'domain', 'media', 'author'.
     * It should be singular and lower case. This is the category that data is being bucketed in to.
     */
    categoryName: "category",

    /**
     * The plural name of the field that can be used for display purposes, such as 'domains', 'media', 'authors'.
     * It should be plural and lower case. This is the category that data is being bucketed in to.
     */
    pluralCategoryName: "categories",

    /**
     * You can override this to change the default chart type that this metric should be displayed as.
     */
    defaultChartType: "columns",

    /**
     * Override this to set the default label type.
     */
    defaultLabelType: "percentage",

    /**
     * Override this to set a message to show when no data is available.
     */
    noDataMessage: 'Your filter has selected no mentions',

    /**
     * Set this to true if scrolling events should be blocked for this item.
     * Having this default to off means that many of are widgets remain very responsive
     * to mouse input.
     */
    blockScrolling: false,

    //---------------------------

    createQueries: function(query, series) {
        if (isFunction(query)) query = query.call(this);

        if (!series) series = this.model.get('_series').series;

        var appendToFilter = this.appendToFilter.bind(this);

        return _(series).map(function(s) {
            return Object.assign({ filter: appendToFilter(s.filter, s, query) }, query);
        });
    },

    /**
     * Override this if you would like to append extra text to the filter.
     */
    appendToFilter: function(filter, series, query) { return filter },

    /**
     * Adds the filter to the given query. If additions is given, it should be an array,
     * and createQuery will return an array of queries whose each filter is appended with an
     * element from additions. If no filter is given, the one from the model is used.
     */
    createQuery: function(query, additions, filter) {
        if (!filter) filter = this.model.get("_series").filter;

        if (_(query).isUndefined()) {
            return { filter: filter };
        }

        if (additions) {
            return _(additions).map(function(item) {
                return Object.assign(_.clone(query), {filter: "(" + filter + ") AND (" + item + ")"})
            });
        }

        return Object.assign(query, { filter: filter });
    },

    /**
     * Loads series data from the chart's _series model attribute.
     */
    loadSeries: function(query, onComplete) {
        var data = this.model.get('_series');

        if (data) {
            var queries = this.createQueries(query);
            var keys = this.getKeys();

            this.loadFromCount(queries, keys, onComplete);
        }
    },

    /**
     * Useful when reading data from loadFromCount or or loadSeries,
     * and you want to transform the data.
     */
    transformData: function(series) { return series; },

    getKeys: function() {
        var series = this.model.get('_series').series;
        return series.length === 1 ? [capitalise(this.categoryName)] : _(series).pluck('label');
    },

    /**
     * Returns CSV data using a given query, in a similar way that loadSeries will load
     * data from the count endpoint. If you're using loadSeries, then this is the appropriate
     * function to use to determine the CSV data to pull from chicken, as long as the query objects
     * are the same.
     */
    loadCsv: function(query) {
        var queries = this.createQueries(query);
        var keys = this.getKeys();
        var code = this.model.getAncestorProperty('accountCode');
        var base = '/rest/accounts/' + code + '/mentions/count.csv';
        var include = this.include();

        return _(queries).map(function(query, i) {
            var address = base;
            if (_(query).size()) {
                address += '?' + _(query).map(function(value, key) {
                    return key + '=' + encodeURIComponent(value)
                }).join('&');
                if (!query.include) address += '&include=' + include;
                if (!query.count) address += "&count=id,authorName,site";
            }

            return {
                url: address,
                name: encodeFilename(keys[i] + '.csv')
            }
        });
    },

   /**
    * Returns CSV data, for given query, formatted to use Chickens comparison endpoint.
    */
   loadComparisonCsv: function(query) {
       var queries = this.createQueries(query);
       var keys = this.getKeys();
       var code = this.model.getAncestorProperty('accountCode');
       var compareUrl = '/rest/accounts/' + code + '/comparisons/compareWidget.csv';
       var endpoint = '/mentions/count';
       var include = this.include();

       var blob = {endpoint: endpoint, queries:[]};
       var general = {include: include};

       _(queries).each(function(query, i) {
           var queryObj = {label: encodeURIComponent(encodeFilename(keys[i]))};
           if (!query.count && !general.count) {
                   general.count = "id,authorName,site";
           }
           _(query).each(function(value, key){
               // Totally ignoring a label key.
               if(!general[key] && key != "filter" && key != "label"){
                   general[key] = encodeURIComponent(value);
               } else if(key == "filter"){
                   queryObj.filter = encodeURIComponent(value);
               }
           });

           blob.queries.push(queryObj);
       });

       blob.general = general;
       var address = compareUrl + '?blob=' + JSON.stringify(blob);
       var stuff = [];
       var name = encodeFilename(this.model.get('caption')) + 'Comparison.csv';
       stuff.push({url: address, name: name});
       return stuff;
   },

    /**
     * This is a default entry of loadCsv. If your data can be loaded using a simple query object from chicken's count
     * endpoint, then you can set this.query to that object. The default implementation of refresh also will make use of
     * that object.
     */
    getCsvEntries: function(filename) {
        if (this.query) {
            var urls;

            if(this.model.get('_series').series.length > 1) {
                urls = this.loadComparisonCsv(this.query);
            } else {
                urls = this.loadCsv(this.query);
            }

            return {
                filename: filename,
                urls: urls
            }
        }
    },

    //------------------------------------

    /**
     * This should be passed an array of series data. It should find common label names for the
     * data. This default implementation insures that the series have matching attribute labels,
     * and resorts things by frequency across all series.
     */
    //------------------------------------

    seriesMatcher: function(series) {

        var fieldName = this.fieldName;
        if (!fieldName) throw new Error("No fieldname specified");

        // We want to ensure that the different series all have the different data points
        // that the others have, filling in missing ones as necessary.
        var fields = {};
        _(series).each(function(s) {
            _(s.values).each(function(d) {
                fields[d[fieldName]] = true;
            });
        });
        fields = _(fields).keys();

        var length = _(fields).keys().length;

        // first we need to find which are the most frequent across all series.
        var frequencies = {};

        _(series).each(function (s) {
            _(s.values).each(function(item) {
                var n = item[fieldName];
                frequencies[n] = (frequencies[n] || 0) + item.count;
            });
        });

        // Now we need to ensure that the series reflect these frequencies.
        frequencies = _.chain(frequencies)
            .map(function(count, i) {
                var result = { count: count };
                result[fieldName] = i;
                return result;
            })
            .sortBy('count')
            .reverse()
            .take(length)
            .value();

        var values = [];
        var f = null;
        var result = null;
        var valueFieldMap = null;
        var i = null;

        for (var series_i = 0; series_i < series.length; series_i++) {
            values = [];
            valueFieldMap = {};

            for (i = 0; i < series[series_i].values.length; i++) {
                valueFieldMap[series[series_i].values[i][fieldName]] = series[series_i].values[i];
            }

            for (i = 0; i < frequencies.length; i++) {
                f = frequencies[i];
                result = valueFieldMap[f[fieldName]];

                if (result) {
                    values.push(result);
                }
                else {
                    result = { count: 0, percentage: 0, ots: 0, ave: 0 };
                    result[fieldName] = f[fieldName];
                    values.push(result);
                }
            }

            series[series_i].values = values;
        }
    },

    /**
     * This is able to, given a value, return a snippet of a filter for selecting a field.
     */
    selectField: function(value, negative) {
        var field = this.fieldName.toLowerCase();
        var fieldName = this.fieldName;

        var operator = this.selectOperator(field, value, negative);

        switch (field) {
            case "site": fieldName = "link"; break;
        }

        var literal = this.selectLiteral(value);

        if (literal.toString().toLowerCase() === 'unknown') {
            return this.fieldName + (negative ? ' isnt unknown' : ' is unknown');
        }
        switch (field) {
            case "published":
            case "language":
            case "country":
            case "authorname":
            case "site": literal = '"' + value + '"'; break;
        }

        if(field === 'site') {
            if(!negative) return "site is " + literal + " or link contains " + literal;
            else return "site ISNT " + literal + " and link DOESNTCONTAIN " + literal;
        }
        return fieldName + " " + operator + " " + literal;
    },

    /**
     * This works with selectField to determine the literal value to use when selecting a field
     * by interacting with a chart.
     */
    selectLiteral: function(value) {
        return value;
    },

    selectOperator: function(field, value, negative) {
        var operator = null;
        switch (field) {
            case "published": operator = 'on'; break;
            case "language":
            case "country":
            case "gender":
            case "race":
            case "topic":
            case "media": operator = negative ? 'isnt' : 'is'; break;
            case "site":
            case "authorname": operator = negative ? 'doesntcontain' : 'contains'; break;
            case "credibility":
            case "sentiment": operator = negative ? '!=' : '='; break;
            default: throw new Error("Unrecognised field [" + this.fieldName + "]")
        }

        return operator;
    },

    dataSelectedTab: function(e) {
        this.dataSelected(e, true);
    },

    dataSelected: function(e, newTab) {
        if (!this.fieldName) throw new Error("Unknown field name for interaction");

        var data = this.model.generalData.get('_data');
        var othersChosen = e.point[this.fieldName].toString().toLowerCase() === 'others';

        var series = this.model.get('_series');
        var found = series.series[0];
        var foundData = data[0];

        if (this.model.get('chart-type') !== 'pies') {
            found = _(series.series).find(function(d, i) {
                if (data[i].key === e.series.key) {
                    foundData = data[i];
                    return true
                }
                return false;
            });
        }

        var filter;
        if (!othersChosen) filter = this.selectField(e.point[this.fieldName]);
        else {
            filter = [];

            var hideUnknown = this.model.get('hide-unknown'),
                takeCount = this.model.get('max-items') - 1;
            var fieldName = this.fieldName;

            if (hideUnknown) {
                foundData = _(foundData.values).chain()
                        .reject(function(d) { return isUnknown(d[fieldName]); })
                        .take(takeCount)
                        .value();
            }
            else {
                foundData = _(foundData.values).take(takeCount)
            }

            _(foundData).each(function(item) {
                filter.push(this.selectField(item[this.fieldName], true));
            }.bind(this));

            filter = filter.join(' and ');
        }

        if (this.isSampleSentiment && this.isSampleSentiment(data)) {
            filter += ' and unbiasedSample is true';
        }

        Beef.MentionList.navigateToMentions(this.model.getAncestorProperty('accountCode'),
                                          "(" + found.filter + ") and (" + filter + ")", null, newTab);
    }
});
