<template>
    <div @mouseover="showTooltip" ref="target" class="be-tooltip">
        <slot/>
    </div>
</template>

<script>


import {closeCurrentTooltip, showTooltipText} from "@/components/tooltip/TooltipUtilities";

export default {
    name: "OldTooltip",

    props: {
        label: String,
        keyword: {
            type: String,
            default: null
        },
        right: Boolean
    },

    methods: {
        showTooltip() {
            if (!this.label) return;
            const options = {keyword: this.keyword};
            if (this.right) options.positions = ['right', 'left'];
            showTooltipText(this.$refs.target, this.label, options);
        }
    },

    beforeDestroy() {
        closeCurrentTooltip();
    }
}


</script>


<style scoped lang="sass">

.be-tooltip
    box-sizing: border-box
    display: inline-block
    margin: 0
    padding: 0
    width: 100%
    height: 100%

</style>