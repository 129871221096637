<template>
    <div class="training-marker">
        <div class="training-marker__outer">

        </div>

        <div class="training-marker__dot">

        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            const markers = document.querySelectorAll('.training-marker');

            // We want the animation of multiple markers to sync up on the page. If they
            // are made visible at different times, this won't happen, so we reset the animations.
            markers.forEach(m => {
                m.classList.remove('training-marker--animated');
            })

            // $nextTick does not allow the CSS animation system to stop
            // the animation and reset the animation loop.
            setTimeout(() => {
                markers.forEach(m => {
                    m.classList.add('training-marker--animated');
                })
            }, 10)
        }
    }
</script>


<style scoped lang="sass">
    .training-marker
        box-sizing: border-box
        position: relative
        width: 14px
        height: 14px
        display: inline-flex
        &--animated
            .training-marker__outer
                animation: 1s training-marker__pulse ease-in-out both alternate infinite
            .training-marker__dot
                animation: 1s training-marker__dot-pulse ease-in-out both alternate infinite


    .training-marker__outer
        background: white
        border-radius: 50%
        width: 14px
        height: 14px


    .training-marker__dot
        width: 8px
        height: 8px
        background: red
        display: inline-block
        border-radius: 50%
        position: absolute
        left: calc(50% - 4px)
        top: calc(50% - 4px)


    @keyframes training-marker__pulse
        0%
            transform: none
        100%
            transform: scale(0.5)

    @keyframes training-marker__dot-pulse
        0%
            transform: none
        100%
            transform: scale(1.8)
</style>