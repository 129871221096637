var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deq-reset brand-picker__root",attrs:{"data-root-selected":_vm.brand.selected,"tabindex":"-1"}},[_c('tooltip-component',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [(!_vm.brand.selected && !_vm.brand.excluded)?_c('span',[_vm._v(" Click to select this brand ")]):(_vm.brand.excluded)?_c('span',[_vm._v(" This brand has been excluded. Click to undo. ")]):_c('span',[_vm._v(" This brand is selected. Click to deselect it. ")])]},proxy:true}])},[_c('span',{staticClass:"brand-picker__brand",class:{
                    'brand--active': _vm.brand.selected,
                    'deq-disabled': !_vm.brand.visible,
                    'brand--matches': !!_vm.searchText && !!_vm.brand.visible,
                    'brand--excluded': _vm.brand.excluded,
                },attrs:{"tabindex":_vm.brand.visible ? 0 : -1},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.selected(_vm.brand)},"click":function($event){return _vm.selected(_vm.brand)}}},[(_vm.brand.selected && !_vm.brand.excluded)?_c('animated-check',{attrs:{"animated":""}}):_vm._e(),(_vm.allowExcludeRoot && _vm.brand.selected && _vm.brand.excluded)?_c('span',{staticClass:"brand__excluded"}):_vm._e(),_c('span',{staticClass:"brand-picker__brand-name"},[_vm._v(" "+_vm._s(_vm.formatBrandName(_vm.brand.brand, {markArchiveDuplicates: false}))+" ")]),(_vm.allowExcludeRoot && !_vm.brand.excluded)?_c('tooltip-component',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Click to exclude this brand ")]},proxy:true}],null,false,2141475206)},[_c('span',{staticClass:"brand-picker__negate",on:{"click":function($event){$event.stopPropagation();return _vm.negate(_vm.brand)}}})]):_vm._e()],1)]),(_vm.showChildren && _vm.getChildren(_vm.brand).length)?_c('div',[_c('div',{staticClass:"brand-picker__notch"}),_c('div',{staticClass:"brand-picker__border"})]):_vm._e(),(_vm.showChildren && _vm.getChildren(_vm.brand).length)?_c('div',{staticClass:"brand-picker__children dark-scrollbars dark-scrollbars--visible"},_vm._l((_vm.getChildren(_vm.brand)),function(c){return _c('div',{key:c.brand.id,staticClass:"brand-picker__brand brand--child",class:{
                'brand--active': c.selected,
                'deq-disabled': !c.visible,
                'brand--excluded': c.excluded,
                'child--excluded': _vm.excludeChildren,
                'brand--matches': !!_vm.searchText && !!c.visible
             },attrs:{"data-indent":c.indent,"tabindex":c.visible ? 0 : -1},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.selected(c)},"click":function($event){return _vm.selected(c)}}},[_c('tooltip-component',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [(!c.selected && !c.excluded)?_c('span',[_vm._v(" Click to select this sub-brand. ")]):(c.excluded)?_c('span',[_vm._v(" This sub-brand has been excluded. Click to undo. ")]):_c('span',[_vm._v(" This sub-brand has been selected. Click to deselect. ")])]},proxy:true}],null,true)},[(c.selected && !c.excluded)?_c('animated-check',{attrs:{"animated":""}}):_vm._e(),(c.selected && c.excluded)?_c('span',{staticClass:"brand__excluded"}):_vm._e(),_c('span',{staticClass:"brand-picker__brand-name"},[_vm._v(_vm._s(_vm.formatBrandName(c.brand)))])],1),(!c.excluded)?_c('tooltip-component',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Click to exclude this sub-brand ")]},proxy:true}],null,true)},[_c('span',{staticClass:"brand-picker__negate",on:{"click":function($event){$event.stopPropagation();return _vm.negate(c)}}})]):_vm._e(),_c('tooltip-component',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [(!c.expanded)?_c('span',[_vm._v(" Click to see children ")]):_c('span',[_vm._v(" Click to hide children ")])]},proxy:true}],null,true)},[(c.children)?_c('button',{staticClass:"child__expand-button",attrs:{"tabindex":"-1"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.expand(c)}}},[_c('collapsable-caret',{attrs:{"collapsed":!c.expanded}})],1):_vm._e()])],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }