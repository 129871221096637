<template>
    <td style="padding-left: 0; white-space: nowrap" :style="{background: background}" class="comp-table-delta clickable" @click="$emit('click')"
            :class="'comp-table-delta-' + col.type">
        <template v-if="delta">
            <span class="arrow" :class="delta < 0 ? 'down' : 'up'"></span>
            <span>{{formattedDelta}}</span>
        </template>
    </td>
</template>

<script>/**
 * Shows change to previous value (if any).
 */
import {formatPercentage} from "@/app/utils/Format";

export default {
    name: "CompTableDelta",

    props: {
        row: Object,
        col: Object,
        background: {
            required: false,
            default: null,
            type: String
        }
    },

    computed: {
        delta() { return toCellDelta(this.row, this.col) },
        formattedDelta() { return formatCellDelta(this.col, this.delta) }
    }
}

export function toCellDelta(row, col) {
    let cv = row.colValues[col.id]
    if (!cv) return null
    let pv = cv.prev
    if (!pv) return null

    if (col.showPercentage) {
        let delta = cv.percentage - pv.percentage
        if (isNaN(delta)) return null
        return delta
    } else {
        let delta = cv.number - pv.number
        if (isNaN(delta)) return null
        return delta * 100 / pv.number
    }
}

export function formatCellDelta(col, delta) {
    let s = formatPercentage(Math.abs(delta), 1)
    if (col.showPercentage) s = s.replace("%", "\u00A0pp")
    return s
}

</script>

<style scoped>
</style>