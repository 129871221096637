<template>
    <span v-html="text" ref="span" @click="$emit('click', $event)"/>
</template>

<script>
/**
 * Displays a mention filter in English if possible.
 */
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import {toEnglish} from "@/dashboards/filter/FilterToEnglish";

    export default {
        store: VuexStore,
        name: "EnglishFilter",

        props: {
            filter: String,
            partial: Boolean    // filter is partial so dont do no relevancy => 'All mentions (including deleted)' etc.
        },

        data() {
            return {
                text: this.filter
            }
        },

        computed: {
            ...mapState(['account'])
        },

        mounted() {
            this.toEnglish()
        },

        watch: {
            filter() { this.toEnglish() },

            text() {
                this.$nextTick(() => this.$emit('text', this.$refs.span.textContent))
            }
        },

        methods: {
            toEnglish() {
                toEnglish(this.filter, text => this.text = text, cache, {
                    partial: this.partial
                })
            }
        }
    }

    const cache = { }
</script>

<style scoped>

</style>