import Paragraph from "@editorjs/paragraph";

export default class MarkdownBlock {

    constructor({data, api, config, readOnly, block}){
        this.api = api;
        this.readOnly = api.readOnly.isEnabled;
        this.textArea = document.createElement("textarea");
        this.textArea.style.width = "100%";
        this.textArea.style.height = "150px";
        this.display = document.createElement("div");

        this.data = data;
        if (config.filter && config.accountCode){
            this.filter = config.filter;
            this.accountCode = config.accountCode;
        }
    }

    static get toolbox(){
        return {
            title: 'Markdown',
            icon: '<span>M &darr;</span>'
        }
    }

    onPaste(t) {
        this.data = {
            text: t
        };
    }

    render(){
        if (this.readOnly ){
            if (this.data.text && this.filter && this.accountCode){
                try {
                    let renderResult = Beef.Markdown.render(this.data.text, this.filter, this.accountCode,{})
                    renderResult.then((updates) => {
                        updates.forEach( (html, selector) => {
                            let element = document.querySelector(selector);
                            element.innerHTML = html;
                            element.classList.remove('text-loading');
                        });
                        renderResult.events(document.querySelector('.markdown-display'));
                    })
                    this.display.innerHTML = renderResult.text ? renderResult.text : "";
                    return this.display;
                } catch (e) {
                    console.error(e);
                }
            } else {
                this.display.innerHTML = "loading...";
                return this.display;
            }
        } else {
            this.textArea.value = this.data.text ? this.data.text : "";
            return this.textArea;
        }
    }

    refresh(filter){
        this.filter = filter;
        this.display.innerHTML = '';
        this.render();
    }
    save(t) {
        return {
            text: t.value
        }
    }

    static get isReadOnlySupported() {
        return !0;
    }

}