/**
 * Enter one or strings. Fires a change event when the selection is changed. Also provides a model binder converter
 * to display the selected options in an element.
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";
import {removeSingleQuotes} from "@/app/utils/StringUtils";

Beef.module("ContainsPicker").addInitializer(function(startupOptions) {

    // todo add new bits of text to items - maybe also local storage?
    var items = { };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "contains-picker auto-complete-picker" },
        items: items,
        mustSelectFromItems: false
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            items: function(code) { return removeSingleQuotes(code) },
            placeholder: placeholder
        });
    };

    this.converter = this.createConverter(
        "Search mention content for text (press enter after each term)"
    );

    /**
     * Attach the picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, {quoteCodes: "'"});
    };

});
