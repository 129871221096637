export default function(option, objName, fieldName) {
    if (!this[option]) return emptySafeString;

    var showDeltas = this['show-deltas'];
    var exactDeltas = this['show-exact-deltas'];

    var f = Beef.StatFields.get(fieldName);

    var cols = [];
    var series = this._data.series;
    for (var i = 0; i < series.length; i++) {
        var col = {
            exact: this.exact,
            fmt: f.format,
            _deltas: (i > 0 || !series[i].dateComparison) && showDeltas && (series.length > 1),
            _dateComparison: series[i].dateComparison === true
        };
        var o = series[i][objName];
        if (o && o[fieldName] !== undefined) {
            col.value = o[fieldName];
            col.marginOfError = o[fieldName + "Moe"];
            col.valueLow = o[fieldName + "Low"];
            col.valueHi = o[fieldName + "Hi"];
            col.marginOfErrorP = o[fieldName + "MoeP"];
            col.marginOfErrorZ = o[fieldName + "MoeZ"];
            if (o._deltas && o._deltas[fieldName] !== undefined) {
                var d = col.deltas = o._deltas[fieldName];
                d.dir = d.diff < 0 ? 'down' : d.diff > 0 ? 'up' : '';
                var v = f.downIsGood ? -d.diff : d.diff;
                d.status = v < 0 ? 'bad' : v > 0 ? 'good' : '';
                d.exactDeltas = exactDeltas;
            }
        } else {
            if (!this._options.model.generalData.get('_completed')) col._loading = true;
        }
        cols.push(col);
    }

    return new Handlebars.SafeString(require('@/dashboards/widgets/stats/StatsRow.handlebars')({
        label: f.label,
        description: f.description,
        cols: cols
    }));
}

const emptySafeString = new Handlebars.SafeString("");
