/**
 * Render the phrase for a mention as its brand.
 */
export default function(path, options) {
    var a = [], i;
    if (path) {
        for (i = 0; i < path.length; i++) {
            a.push($('<pre>').text(path[i].name).html());
        }
    }
    return new Handlebars.SafeString(a.join(" <span class='delim'> &raquo; </span> "));
}