/**
 * Select one or more credibility options. Fires a change event when the selection is changed. Also provides a model
 * binder converter to display the selected options in an element.
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("SentimentPicker").addInitializer(function(startupOptions) {

    var items = {
        "-1": "Negative",
        "1": "Neutral",
        "2": "Positive",
        "UNKNOWN": "Unknown"
    };
    this.items = items;

    this.sentimentCodeToString = {
        "-5": "Negative",
        "-4": "Negative",
        "-3": "Negative",
        "-2": "Negative",
        "-1": "Negative",
        "1": "Neutral",
        "2": "Positive",
        "3": "Positive",
        "4": "Positive",
        "5": "Positive"
    };

    this.View = Beef.Picker.View.extend({
        attributes: { class: "sentiment-picker" },
        template: require("@/dashboards/filter/pickers/sentiment/SentimentPicker.handlebars"),
        items: items,
        getSelection: function() { return Beef.Picker.View.prototype.getSelection.call(this).sort() }
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            items: items,
            noNeg: true,
            placeholder: placeholder,
            placeholderPersistent: false,
            cls: function(d) { return "tag sentiment" },
            nameFormatter: function(name) {
                var prefix = "";
                switch (name.toLowerCase()) {
                    case 'negative': prefix = "<span class='tag__icon negative-sentiment'><i class='icon-circle'></i></span>"; break;
                    case 'neutral': prefix = "<span class='tag__icon neutral-sentiment'><i class='icon-circle'></i></span> "; break;
                    case 'positive': prefix = "<span class='tag__icon positive-sentiment'><i class='icon-circle'></i></span> "; break;
                }

                return prefix + name;
            },
            tooltip: function(code) {
                var name = Beef.SentimentPicker.sentimentCodeToString[code] || "";
                switch (name.toLowerCase()) {
                    case 'negative': return "Negative sentiment towards the mention's brand";
                    case 'neutral': return "Neutral sentiment towards the mention's brand";
                    case 'positive': return "Positive sentiment towards the mention's brand";
                }
                return name;
            },
        });
    };

    this.converter = this.createConverter("Sentiment");

    /**
     * Attach a sentiment picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
