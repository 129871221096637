<template>
    <div style="display: flex">
        <drop-down-input v-model="date" :options="previousDates" optional placeholder="Date"
                         style="width: 130px; flex-grow: 0" :use-activator-width="false"/>

        <text-input v-model="label" type="text" :placeholder="placeholder"
                    style="width: 120px; flex-grow: 0; margin-left: 4px"/>

        <div style="width: 1px; flex-grow: 1; margin-left: 4px" @click="editFilter">
            <div class="tag-input uneditable-input filter span12" style="">
                <english-filter v-if="dataSet.filter" :filter="dataSet.filter" partial/>
                <span v-else class="placeholder">Click to edit filter</span>
            </div>
        </div>

        <div style="width: 29px">
            <a v-if="showRemove" class="delete btn btn-link" @click="$emit('remove')" title="Remove this data set">
                <i class="icon-trash-1" />
            </a>
        </div>

        <dialog-box v-if="showEditFilter" :title="'Edit ' + dataSet.label + ' Filter'"
                    @close="showEditFilter = false" width="740px">
            <filter-editor v-model="filter" :brand="brand" no-published/>
            <template #buttons>
                <a class="cancel btn" @click="showEditFilter = false">Cancel</a>
                <a class="btn btn-primary" @click="editFilterOk">Ok</a>
            </template>
        </dialog-box>
    </div>
</template>

<script>
    import TextInput from "../../../components/inputs/TextInput"
    import EnglishFilter from "../../../components/formatters/EnglishFilter"
    import FilterEditor from "../../../components/inputs/FilterEditor"
    import DialogBox from "../../../components/DialogBox"
    import DropDownInput from "../../../components/inputs/DropDownInput";

    /**
     * Edits a data set for "Data sets" comparison option.
     */
    export default {
        name: "FCDataSetSettings",

        components: { TextInput, EnglishFilter, FilterEditor, DialogBox, DropDownInput },

        props: {
            dataSet: Object,
            index: Number,
            count: Number,
            brand: String,
            previousDates: Array
        },

        data() {
            return {
                showEditFilter: false,
                filter: null,

                date: this.dataSet.date,
                label: this.dataSet.label
            }
        },

        watch: {
            date() {
                this.$set(this.dataSet, "date", this.date);
            },
            'dataSet.date'() {
                this.date = this.dataSet.date;
            },

            label() {
                this.$set(this.dataSet, "label", this.label);
            },
            'dataSet.label'() {
                this.label = this.dataSet.label;
            }
        },

        computed: {
            showRemove() { return this.count > 1 },

            placeholder() {
                if (this.dataSet.date) {
                    let o = this.previousDates.find(v => v.id === this.dataSet.date)
                    if (o) return o.name
                }
                return "Set " + this.dataSet.id
            }
        },

        methods: {
            onValid(v) {
                this.$emit('valid', v)
            },

            editFilter() {
                this.filter = this.dataSet.filter
                this.showEditFilter = true
            },

            editFilterOk() {
                this.showEditFilter = false
                this.$set(this.dataSet, "filter", this.filter);
            }
        }
    }
</script>

<style scoped>
    .placeholder {
        color: #a0a0a0;
    }

    .delete {
        padding-right: 0;
        margin-top: 2px;
    }

</style>