/**
 * Drill down by adding/removing sentiment to the filter.
 */
Beef.module("Widget.SentimentSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Sentiment",
        description:    "Interactively filter by sentiment",
        width:          1,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        template: require("@/dashboards/widgets/selectors/SentimentSelector.handlebars"),

        filterAttribute: "sentiment",

        refresh: function() {
        },

        renderImpl: function() {
            this.model.generalData.set('_completed', true);
        }
    });
});