import beefRenderResponseTable from './ResponseTableD3';
import "./ResponseTable.css"
import CSV from '../../../app/CSV'
import BrandResponseHelper from './BrandResponseHelper'
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {currentAccountCode} from "@/app/utils/Account";
import {getAllCxSegmentLists} from "@/app/utils/Segments";
import {grouseGet} from "@/data/Grouse";
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {errorHelper} from "@/dashboards/DashboardUtils";

/**
 * Table showing responses rates and times for public mentions.
 */
Beef.module("Widget.ResponseTable").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Priority Conversation Response Time",
        description:    "How quickly does the brand respond to public, online conversation",
        group:          "other",
        width:          6,
        height:         4
    };

    this.View = Beef.BoundItemView.extend({

        attributes: {
            'class': 'response-table widget-font widget-height-inner'
        },

        modelEvents: {
            "change":   "maybeRefresh"
        },

        initialize: function() {
            this.refresh();
        },

        render: function(){
            // Need to clear text before getting width and height, otherwise cannot shrink this widget.
            this.$el.text('')
            this.renderChart()
        },

        renderChart: function() {
            let options = {
                onClick: this.onChartClick,
                //showTooltip: this.showTooltip
            }
            let w = this.$el.width()
            let h = this.$el.height()
            if (this._data) {
                this._d3data = beefRenderResponseTable(this.$el[0], w, h, this._data, options)
            } else {
                this.$el.text('')
                this._d3data = { }
            }
        },

        maybeRefresh: function() {
            var c = this.model.changed;
            var refresh = c['_effectiveFilter'];
            if (refresh || c['supportProfiles'] !== undefined
                    || c['both'] !== undefined || c['direct'] !== undefined || c['indirect'] !== undefined || c['newInteractionResponseTimes'] !== undefined || c['useInteractionWorkingHours'] !== undefined) {
                this.refresh();
            } else {
                if (c['width'] || c['height']) {
                    this.$el.text('');
                    this.$el.css({'max-width': "none"});
                    setTimeout(function() { this.render() }.bind(this), 400);
                }
                else this.render();
            }
        },

        refresh: async function() {
            var filter = this.model.get('_effectiveFilter')
            if (!filter) return

            let brandIds = getBrandsInFilter(filter).include;
            if (brandIds.length > 1) {
                this.model.generalData.set({'_loading': false, _completed: true})
                this.model.generalData.set({_message: "Please ensure that only one brand is selected", _footnotes: [] })
                return
            }

            let helper = new BrandResponseHelper(this)
            await helper.analyzeFilter()
            let cx = await getAllCxSegmentLists();

            if (!cx?.length) return
            let supportProfileIds = helper.supportProfileIds
            if (!supportProfileIds || !supportProfileIds.length) return

            let both = this.model.get('both')
            let direct = this.model.get('direct')
            let indirect = this.model.get('indirect')
            if (!both && !direct && !indirect) direct = indirect = true

            let supportHandles = "(" + supportProfileIds.map(id => "MentionedProfile IS " + id).join(" OR ") + ")"
            let noSupportHandles = supportProfileIds.map(id => "MentionedProfile ISNT " + id).join(" AND ")
            let cxTags = `(${cx.map(cx => `Tag IS ${cx.id} OR Tag IS ${cx.children[cx.children.length - 1]}`).join(" OR ")})`;
            let supportHandlesIn = "(" + supportProfileIds.join(",") + ")"

            let topLevelFilter = filter + " AND Visibility IS PUBLIC AND ReshareOf IS unknown AND ReplyTo IS unknown"
            let cxFilter = topLevelFilter + " AND " + cxTags
            let directFilter = cxFilter + " AND " + supportHandles
            let indirectFilter = cxFilter + " AND " + noSupportHandles

            let tables = []

            if (both) {
                tables.push({ id: "both", label: "Direct & Indirect",
                    // have to have the support handles in the filter so totalHasReply check reply is from handle
                    filter: cxFilter + " AND (" + supportHandles + " OR " + noSupportHandles + ")",
                    clickFilter: cxFilter,
                    rows: [], comment: "Public top level posts with CX segments" })
            }
            if (direct) {
                tables.push({ id: "direct", label: "Direct", filter: directFilter, rows: [], showPercent: true,
                        comment: "Public top level posts with CX segments mentioning brand support handles" })
            }
            if (indirect) {
                tables.push({ id: "indirect", label: "Indirect", filter: indirectFilter, rows: [], showPercent: true,
                    comment: "Public top level posts with CX segments not mentioning brand support handles"})
            }

            let calls = []
            let countEndpoint = '/v4/accounts/' + currentAccountCode() + '/mentions/count'
            let first = true

            let onCallComplete = () => {
                if (first) this.model.generalData.set({'_loading': first = false })
                this.renderChart()
            }

            let useInteractionWorkingHours = this.model.get("useInteractionWorkingHours");

            const fromGrouse = this.model.getSectionModel()
                ? this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view)
                : grouseGet;

            tables.forEach( table => {
                // these must match the cols in each row
                table.cols = ["Distribution", "Response Rate", "Response Time"]

                RPCS.forEach( row => {
                    row = Object.assign({}, row)
                    row.filter = table.filter + " AND " + row.filter
                    row.comment = table.comment + ", with " + row.id + " tag"
                    table.rows.push(row)

                    let params;
                    let mentionsCol = { id: "mentions", label: "Mentions", filter: row.filter }
                    let responseRateCol = {};
                    let responseTimeCol = {};

                    // for new response time methodology, interactionHasResponse and interactionResponseTime are already
                    // calculated using support profiles, no need to filter
                    responseRateCol = { id: "responseRate", label: "Response Rate", filter: row.filter }
                    responseTimeCol = { id: "responseTime", label: "Response Time", filter: row.filter }
                    row.cols = [mentionsCol, responseRateCol, responseTimeCol]

                    let select = useInteractionWorkingHours ? "interactionCount,averageInteractionHasResponse,averageInteractionWhResponseTime" : "interactionCount,averageInteractionHasResponse,averageInteractionResponseTime"

                    params = {
                        filter: row.filter,
                        select: select
                    }

                    calls.push(fromGrouse(countEndpoint, params)
                        .then(res => {
                            let data = res;
                            row.value = mentionsCol.value = data.interactionCount;
                            responseRateCol.value = row.averageHasReply = data.averageInteractionHasResponse;
                            responseTimeCol.value = row.averageFirstReplyTime = useInteractionWorkingHours ? data.averageInteractionWhResponseTime : data.averageInteractionResponseTime;
                            onCallComplete();
                        })
                        .catch(error => {
                            errorHelper(this.model, error);
                        })
                    );

                })

                let params = { filter: table.filter, select: "interactionCount" };

                calls.push(fromGrouse(countEndpoint, params)
                    .then(res => {
                        table.value = res.interactionCount;
                        onCallComplete();
                    })
                    .catch(error => {
                        errorHelper(this.model, error);
                    }));
            })

            this._data = null
            this.render()
            this._data =  { tables: tables }
            await Promise.all(calls)

            if (!this.tablesHaveData(this._data.tables)) {
                this.model.generalData.set({
                    '_message': "No mentions match your filter",
                    _footnotes: []
                });
            } else {
                let footnotes = [
                    `Direct interactions contain mentions that are directed at or mention the brand support handles`,
                    `Calculated from ${this._d3data.sampleSize} public interactions with customer experience segments`
                ]

                if (useInteractionWorkingHours) {
                    footnotes.push("Interaction response times are calculated within working hours")
                }

                this.model.generalData.set({
                    _footnotes: footnotes
                })
            }

            this.model.generalData.set({
                '_loading': false,
                _completed: true
            })
        },

        tablesHaveData: function(tables) {
            for (const table of tables) {
                for (const row of table.rows) {
                    if (row.averageFirstReplyTime || row.averageHasReply || row.value) return true;
                }
            }

            return false;
        },

        setMessage: function(msg) {
            this.model.generalData.set({'_loading': false, _completed: true})
            this.model.generalData.set({_message: msg })
        },

        onChartClick: function(table, row, col) {
            let title = table.label + (row ? " " + row.label : "") + " Mentions" +
                (col && col.id.indexOf("response") === 0 ? " With Reply From Brand" : "")
            showMentions(col ? col.filter : (table.clickFilter || table.filter), title)
        },

        getCsv: function() {
            if (!this._data) return
            let csv = new CSV()
            csv.add("row,mentions,response_rate,response_time_hours,comment,filter").eol()
            this._data.tables.forEach(table => {
                csv.quote(table.label).add(table.value).add().add().quote(table.comment).quote(table.filter).eol()
                table.rows.forEach(row => {
                    csv.quote(table.label + " " + row.label)
                        .add(row.value).add(dec1(row.averageHasReply * 100)).add(dec1(row.averageFirstReplyTime / 3600))
                        .quote(row.comment).quote(row.filter).eol()
                })
            })
            csv.download(this.model.get("caption") || "csv")
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/response/ResponseTableSettings.handlebars"),

        attributes: {
            'class': 'response-table-settings'
        },

        editAttributes: function() {
            return ['supportProfiles', 'direct', 'indirect', 'both', 'newInteractionResponseTimes', 'useInteractionWorkingHours']
        },

        onFirstRender: function() {
            let attr = this.model.attributes;
            if (!attr.direct && !attr.indirect && !attr.both) this.model.set({direct: true, indirect: true})
            Beef.ProfilePicker.attach(this, ".supportProfiles", "supportProfiles");
        },

        bindings: function() {
            return {
                supportProfiles: { converterFactory: Beef.ProfilePicker.createConverterFactory("Profiles"), elAttribute: "data-value" },
            }
        }
    });
});

const RPCS = [
    { id: "risk", label: "Risk", filter: "Tag IS 1" },
    { id: "purchase", label: "Purchase", filter: "Tag IS 2" },
    { id: "cancel", label: "Cancel", filter: "Tag IS 3" },
    { id: "service", label: "Service", filter: "Tag IS 4" },
]

function dec1(v) {
    if (v === null || v === undefined || isNaN(v)) return ""
    return Math.floor(v * 10 + 0.5) / 10
}