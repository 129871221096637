/**
 * Displays OTS information. Could be generalised to display arbitrary textual data.
 */
import {toEnglish} from "@/dashboards/filter/FilterToEnglish";

Beef.module("Widget.FilterDisplay").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Filter Summary",
        description:    "Display a summary of the criteria you're using for this analysis",
        height: 2,
        width: 2
    };

    this.View = Beef.BoundItemView.extend({

        cache: {},

        attributes: {
            'class': 'filter-display widget-height-inner'
        },

        modelEvents: {
            "change:_effectiveFilter": "onRender"
        },

        template: require("@/dashboards/widgets/filterdisplay/FilterDisplay.handlebars"),

        svgExportDisabled: true,

        initialize: function() {
            var defaults = {};
            if (!this.model.has('hidden-title')) {
                defaults['hidden-title'] = true;
            }
            this.model.set(defaults);
        },

        onRender: function() {
            this.model.generalData.set("_completed", false);

            var filter = this.model.get("_effectiveFilter");
            var code = this.model.getAncestorProperty('accountCode');

            toEnglish(filter || "",
                function(english) {
                    english = english ? "> ##" + english : "";
                    var result = Beef.Markdown.render(english, filter, code, {columns: false});
                    this.$(".filter-display--text").html(result.text);

                    this.model.generalData.set('_completed', true);
                }.bind(this), this.cache
            );
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({
        editAttributes: function() {
            return ['show-raw']
        }
    });
});