import beefRenderSearchJobChartD3 from '../chart/SearchJobChartD3'

/**
 * A module for viewing all the search jobs of an account.
 */
Beef.module("HistoricalSearchOverviewList").addInitializer(function() {

    this.View = Backbone.Marionette.ItemView.extend({

        template: require("@/historical-search/overview/HistoricalSearchOverviewList.handlebars"),

        /**
         * Updates the job list to reflect the series that are currently shown in the chart.
         */
        update: function(data) {
            var keys = {};
            var i;
            for (i = 0; i < data.length; ++i) {
                keys[data[i].key] = true;
            }
            this.model.set("keys", keys);
            var jobs = this.model.get("jobs");
            for (i = 0; i < jobs.length; ++i) {
                jobs[i].selected = keys[beefRenderSearchJobChartD3.toKey(jobs[i].id)];
            }
            this.render();
        }
    });
});
