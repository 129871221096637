import {beef} from "@/store/Services";
import {count, count as grouseCount, getMention} from "@/data/Grouse";
import {appendSegmentRestrictions, getAllCxSegmentLists, getAllRiskProductSegmentLists} from "@/app/utils/Segments";
import moment from "moment";
import {appendFiltersReadably, earliestDate, latestDate} from "@/dashboards/filter/FilterParser";
import VuexStore from "@/store/vuex/VuexStore";
import {getTopicTreeIds} from "@/app/utils/Topics";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import {formatBrandName, formatDate, formatNumber, formatPlural} from "@/app/utils/Format";
import {features} from "@/app/Features";
import {getTimedLocalstorageCache} from "@/data/Cache";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import {escapeExpression, escapeHtml} from "@/app/utils/StringUtils";
import {getPauseTips} from "@/app/help/tips/tips";
import {summariseFilter} from "@/app/utils/turducken";

/**
 * Determines a useful default brand to use for the account. If one is set
 * on the account, it uses that. Otherwise it attempts to use an owned brand if one
 * is set, otherwise a root brand.
 */
export async function getDefaultBrand() {
    await VuexStore.dispatch("refreshBrands");

    const account = VuexStore.state.account;
    if (!account) return null;

    if (account.defaultBrand) {
        return VuexStore.getters.idToBrand.get(account.defaultBrand.id);
    }

    const rootBrands = VuexStore.state.rootBrands;
    if (!rootBrands?.length) return null; // There are no brands.

    const ownBrands = rootBrands.filter(b => b.category === "OWN" && !b.archived && !b.deleted);
    return ownBrands?.length ? ownBrands[0] : rootBrands[0];
}


let trendingKeywordPromiseMap = new Map();

export async function loadKeywordTrendsForBrand(brandId, start, end, optionalAdditionalFilter) {
    optionalAdditionalFilter ??= null;
    const key = `${brandId}:${start}:${end}:${optionalAdditionalFilter}`;
    if (trendingKeywordPromiseMap.get(key)) {
        const data = await trendingKeywordPromiseMap.get(key);
        return Array.from(data);
    }
    const trendingKeywordPromise = readKeywordTrendsForBrandImpl(brandId, start, end, optionalAdditionalFilter);
    trendingKeywordPromiseMap.set(key, trendingKeywordPromise);
    return trendingKeywordPromise.then(d => Array.from(d));
}

async function readKeywordTrendsForBrandImpl(brandId, start, end, optionalAdditionalFilter) {
    if (!start || !end) throw new Error("No start or end date supplied");
    let url = `/api/accounts/${VuexStore.state.account.code}/keywords/trending?brandId=${brandId}&start=${start}&end=${end}`;
    if (optionalAdditionalFilter) url += ("&filter=" + encodeURIComponent(optionalAdditionalFilter));
    const response = await beef.get(url);
    return response.data;
}

/**
 * Given a word, this calculates the sentiment for the word in the last week.
 * @param brandId
 * @param word
 * @returns {Promise<number|null>}
 */
export async function fetchSentimentForKeyword(brandId, word) {
    const date = VuexStore.state.explorePanel.date;
    return await getTimedLocalstorageCache(`keyword-sentiment:${brandId}:${word}:${date}`, async () => {
        const filter = `(${VuexStore.getters["explorePanel/dateFilter"]}) and brand isorchildof ${brandId} and content matches '${word}' and relevancy isnt irrelevant and reshareof is unknown and visibility is public and process is verified`;
        const result = await grouseCount(filter, null, ["mentionCount", "totalSentiment"]);
        return result.mentionCount ? result.totalSentiment / result.mentionCount : null;
    })
}

export function getExploreEnterpriseFilter(brandId, optionalDateRange) {
    let filter = `brand isorchildof ${brandId} and media is enterprise and reshareof is unknown and relevancy isnt irrelevant and replyto is unknown and visibility is public`;
    if (optionalDateRange) filter = appendFiltersReadably(filter, optionalDateRange);
    return filter;
}

export function getExplorePressFilter(brandId, optionalDateRange) {
    let filter = `brand isorchildof ${brandId} and relevancy isnt irrelevant and media is PRESS and visibility is public and reshareof is unknown and replyto is unknown`;
    if (optionalDateRange) filter = appendFiltersReadably(filter, optionalDateRange);
    return filter;
}

export function getExploreConsumerFilter(brandId, optionalDateRange) {
    let filter = `brand isorchildof ${brandId} and media is consumer and reshareof is unknown and relevancy isnt irrelevant and replyto is unknown and visibility is public`;
    if (optionalDateRange) filter = appendFiltersReadably(filter, optionalDateRange);
    return filter;
}

/**
 *
 * @param brandId {Number}
 * @param optionalDateRange {String, optional}
 * @param optionalExtraFilter {String,optional}
 * @param {Boolean, optional} [useTree = true]
 * @returns {Promise<String>}
 */
export async function getTrendingTopicFilter(brandId, optionalDateRange, optionalExtraFilter, useTree) {
    useTree ??= true;
    optionalDateRange ??= 'published inthelast week';

    let filter = `brand isorchildof ${brandId} and (${optionalDateRange}) and relevancy isnt irrelevant and process is verified`;
    if (useTree) {
        const ids = await getTopicTreeIds();
        const treeFilter = ids.map(id => `tag is ${id}`).join(' or ');
        filter = appendFiltersReadably(filter, treeFilter);
    }

    if (optionalExtraFilter) filter = appendFiltersReadably(filter, optionalExtraFilter);
    return filter;
}

/**
 * Returns the weekly topic trends for a given brand. Answers for this are cached.
 * @param brandId {Number}
 * @param optionalDateRage {String, optional}
 * @param optionalExtraFilter {String, optional} If you would like to add additional filter terms
 * @param count {Number,optional}
 * @returns {Promise<null|{typicalTopics: unknown[], trendingTopics: unknown[], periodTopics: unknown[]}>}
 */
export async function loadTrendingTopics(brandId, optionalDateRage, optionalExtraFilter, count) {
    optionalDateRage ??= 'published inthelast week';
    const key = `load-trending-topics:${brandId}:${optionalDateRage ?? null}:${optionalExtraFilter ?? null}`;

    return await getTimedLocalstorageCache(key, async () => {
        const ids = await getTopicTreeIds();
        const treeFilter = ids.map(id => `tag is ${id}`).join(' or ');

        const weekFilter = await getTrendingTopicFilter(brandId, optionalDateRage, optionalExtraFilter);
        const earliest = earliestDate(weekFilter);
        const latest = latestDate(weekFilter);
        const longTermEnd = earliest.clone().subtract(1, 'day');
        const fourMonths = longTermEnd.clone().subtract(4, 'months');
        let longTermFilter = `brand isorchildof ${brandId} and relevancy isnt irrelevant and process is verified 
    and published after '${fourMonths.format("YYYY/MM/DD")}' 
    and published before '${longTermEnd.format("YYYY/MM/DD")}'
    and (${treeFilter})`;
        if (optionalExtraFilter) longTermFilter = `(${longTermFilter}) and (${optionalExtraFilter})`;
        const duration = latest.diff(earliest, 'days');
        const longDuration = longTermEnd.diff(fourMonths, 'days');

        return loadRawTopicTrends(weekFilter, longTermFilter, duration, longDuration, count);
    });
}

/**
 * Returns trends for a particular period. None of this is cached.
 * @param {FilterString} periodFilter The period for which you want to locate trends for
 * @param {FilterString} longTermFilter The period that you want to look for "typical" topic volumes in
 * @param duration The number of days in the period filter.
 * @param count Optional number of topics to return
 * @param topicTreeIds Optional list of topic tree IDs to return data towards.
 */
export async function loadRawTopicTrends(periodFilter, longTermFilter, duration, longDuration, count, topicTreeIds) {
    duration ??= 7;
    longDuration ??= 31 * 4;
    count ??= 10;
    topicTreeIds ??= [];
    topicTreeIds = Array.isArray(topicTreeIds) ? topicTreeIds : [topicTreeIds];

    if (topicTreeIds.length) {
        const treeFilter = topicTreeIds.map(id => `tag is ${id}`).join(' or ');
        periodFilter = appendFiltersReadably(periodFilter, treeFilter);
        longTermFilter = appendFiltersReadably(longTermFilter, treeFilter);
    }

    // Calculate long term stats so that we have a baseline to compare against.
    let longTermData = await grouseCount(longTermFilter, ["tag", "published[MONTH]"], ["tag[id,name,flag]", "mentionCount"], null, {tagNamespace: "topic"});
    longTermData = longTermData.filter(r => r.tag.flag !== 'NONE_OF_THE_ABOVE');

    // Calculate average
    const topics = new Map();
    for (const row of longTermData) {
        if (!topics.has(row.tag.id)) {
            topics.set(row.tag.id, { topic: row.tag, average: 0, longStddev: 0, weekAverage: 0, weekTotal: 0, delta: 0, net: null, max: 0 });
        }
        const data = topics.get(row.tag.id);
        data.average += row.mentionCount;
    }




    for (const topic of topics.values()) {
        topic.average = topic.average / longDuration;
    }

    // Calculate stddev
    for (const row of longTermData) {
        const data = topics.get(row.tag.id);
        data.longStddev += Math.pow(row.mentionCount - data.average, 2);
    }

    for (const topic of topics.values()) {
        topic.longStddev = Math.sqrt(topic.longStddev / longDuration);
    }

    // Calculate data from the period of time that the user is interested in.
    let periodResults = await grouseCount(periodFilter, ["tag"],
        ["tag[id,leaf,description,name,flag]", "mentionCount", "sentimentVerifiedCount", "totalVerifiedSentiment"],
        null, {tagNamespace: "topic"});
    periodResults = periodResults.filter(r => r.tag.flag !== 'NONE_OF_THE_ABOVE');

    const otherResults = await grouseCount(periodFilter, ["tag", "published"],
        ["tag[id]", "mentionCount"],
        null, {tagNamespace: "topic"});


    for (const row of otherResults) {
        const topic = topics.get(row.tag.id);
        if (topic && row.mentionCount > topic.max) {
            topic.max = row.mentionCount;
            topic.maxDate = row.published
        }
    }

    for (const row of periodResults) {
        const tag = row.tag;
        if (!tag.leaf) continue;

        const topic = topics.get(tag.id);
        row.average = row.mentionCount / duration;
        if (!topic) continue;

        topic.weekAverage = row.average;
        topic.weekTotal = row.mentionCount;
        topic.delta = topic.longStddev ? (topic.weekAverage - topic.average) / topic.longStddev : 0;
        topic.leaf = true;
        topic.moe = 1.95 * topic.longStddev; // Should really be using a t-score instead of a z-score critical value.
        if (row.sentimentVerifiedCount) topic.net = row.totalVerifiedSentiment / row.sentimentVerifiedCount;
        topic.topic.description = tag.description;
        topic.filter = `(${periodFilter}) and topic is ${tag.id}`;
    }

    for (const topic of topics.values()) {
        if (topic.delta === null) {
            topic.delta = topic.longStddev ? (0 - topic.average) / topic.longStddev : 0;
        }

        // Let's see if something was spiking during the week
        topic.isSpike = topic.max &&
            topic.max >= 10 &&                      // Don't want low volumes to introduce noise.
            topic.max > topic.average + 0.5 * topic.longStddev;
    }

    return {
        trendingTopics: Array.from(topics.values())
            .filter(t => t.weekTotal >= 5)
            .sort((lhs, rhs) => {
                if (lhs.isSpike && !rhs.isSpike) return -1;
                if (rhs.isSpike && !lhs.isSpike) return 1;
                return rhs.delta - lhs.delta
            })
            .splice(0, count),
        typicalTopics: Array.from(topics.values())
            .filter(t => t.leaf)
            .sort((lhs, rhs) => rhs.average - lhs.average)
            .splice(0, count),
        periodTopics: periodResults
            .filter(t => t.tag.leaf)
            .splice(0, count)
    }
}

/**
 * Returns a CSV export of trending topic data.
 * @param topics A list of topic trend data, as provided by #loadTopicTrends
 * @returns {string}
 */
export function getCsvForTrendingTopics(topics) {
    const rows = [['topicId', 'topicName', 'unusualness', 'spiking', 'netSentiment', 'dailyAverage', 'dailyMaximum', 'dailyMaximumDate', 'expectedDailyAverage', 'expectedStddev', 'zscore', 'isNew']];

    if (topics?.length) {
        for (const t of topics) {
            let unusual = 'EXPECTED';
            if (t.delta >= 1) unusual = 'VERY';
            else if (t.delta >= 0.5) unusual = 'SLIGHTLY';
            rows.push([t.topic.id, t.topic.name, unusual, !!t.isSpike, t.net ?? 'NA', t.weekAverage, t.max ?? 'NA', t.maxDate ?? 'NA', t.average, t.stddev ?? 'NA', t.delta, !!t.isNew]);
        }
    }

    // Add the UTF-8 BOM to the header.
    // The UTF-8 BOM is so that Excel + Windows correctly interprets and opens
    // this file.
    return '\ufeff' + rows
        .map(row => row.map(d => `"${("" + d).replace(/"/g, '""')}"`).join(','))
        .join("\n");
}


export async function getTrendingRiskFilter(brandId, optionalDate, optionalAdditionalFilter) {
    const conduct = await getAllRiskProductSegmentLists();
    if (!conduct.length) return null;
    optionalDate ??= 'published inthelast week';

    const conductFragment = conduct.map(c => `segment is ${c.id}`).join(' or ');

    let periodFilter = `brand isorchildof ${brandId} and
            (${optionalDate}) and
            relevancy isnt irrelevant and
            process is verified and
            tag is 1 and
            (${conductFragment})`;

    optionalAdditionalFilter = appendSegmentRestrictions(optionalAdditionalFilter);

    if (optionalAdditionalFilter) periodFilter = `(${periodFilter}) and (${optionalAdditionalFilter})`;
    return periodFilter;
}

export async function loadTrendingRisk(brandId, optionalDate, optionalAdditionalFilter) {
    optionalDate ??= 'published inthelast week';
    const key = `load-trending-risk:${brandId}:${optionalAdditionalFilter ?? null}:${optionalDate ?? null}`;

    return await getTimedLocalstorageCache(key, async () => {
        const conduct = await getAllRiskProductSegmentLists();
        if (!conduct.length) return null;

        const conductFragment = conduct.map(c => `segment is ${c.id}`).join(' or ');

        let periodFilter = await getTrendingRiskFilter(brandId, optionalDate, optionalAdditionalFilter);

        const earliest = earliestDate(periodFilter);
        const latest = latestDate(periodFilter);
        const longtermEnd = earliest.clone().subtract(1, 'day');
        const fourMonths = longtermEnd.clone().subtract(4, 'months');
        const duration = latest.diff(earliest, 'days');
        const longDuration = longtermEnd.diff(fourMonths, 'days');

        let quarterFilter = `brand isorchildof ${brandId} and
            published after '${fourMonths.format("YYYY/MM/DD")}' and published before '${longtermEnd.format("YYYY/MM/DD")}' and 
            relevancy isnt irrelevant and
            process is verified and
            tag is 1 and
            (${conductFragment}) `;
        if (optionalAdditionalFilter) quarterFilter = `(${quarterFilter}) and (${optionalAdditionalFilter})`;

        const resultsPromise = loadRawSegmentTrends(periodFilter, quarterFilter, duration, longDuration);

        // Let's fill in the missing risk that's on this brand.
        await VuexStore.dispatch("refreshBrands");
        await VuexStore.dispatch("refreshTags");
        const idToBrand = VuexStore.getters.idToBrand;
        const idToTag = VuexStore.getters.idToTag;
        const brand = idToBrand.get(brandId);
        const children = new Set();
        if (brand?.segmentLists?.length) {
            brand.segmentLists.forEach(s => {
                if (s.segmentType?.id === "CONDUCT_LIST") {
                    const tag = idToTag.get(s.id);
                    if (tag?.children) {
                        tag.children.forEach(id => {
                            const child = idToTag.get(id);
                            if (child) children.add(child);
                        })
                    }
                }
            })
        }

        const results = await resultsPromise;
        const present = new Set(results.map(r => r?.topic?.id));

        for (const child of children) {
            if (child.flag === "NONE_OF_THE_ABOVE") continue;
            if (!present.has(child.id)) {
                results.push({
                    topic: child,
                    average: 0, longStddev: 0, weekAverage: 0, weekTotal: 0, delta: 0, net: null, max: 0,
                    isNew: moment(child.created).isAfter(fourMonths)
                });
            }
        }

        return results;
    });
}

export async function loadRawSegmentTrends(periodFilter, longTermFilter, duration, longDuration, segmentType) {
    segmentType ??= 'CONDUCT_LIST';
    await VuexStore.dispatch('refreshTags');
    const idToTags = VuexStore.getters.idToTag;
    const data = await loadRawTagTrends(periodFilter, longTermFilter, duration, longDuration, null, 'segment');
    return data.filter(t => {
        // Remove risk that isn't from conduct, like high influence authors.
        const tag = idToTags.get(t.topic.id);
        return tag?.parent?.segmentType?.id === segmentType;
    })
}



export async function loadRawTagTrends(periodFilter, longTermFilter, duration, longDuration, count, namespace) {
    longDuration ??= 4 * 31;
    namespace ??= 'tag';
    await VuexStore.dispatch('refreshTags');
    const idToTag = VuexStore.getters.idToTag;
    const longTermResults = await grouseCount(longTermFilter, ["tag", "published[MONTH]"], ["tag[id,name,flag]", "mentionCount"], null, {tagNamespace: namespace});

    const periodPromise = grouseCount(periodFilter, ["tag"],
        ["tag[id,leaf,flag]", "mentionCount", "sentimentVerifiedCount", "totalVerifiedSentiment"],
        null, {tagNamespace: namespace});

    // Calculate average
    const tags = new Map();
    for (const row of longTermResults) {
        if (!tags.has(row.tag.id)) {
            tags.set(row.tag.id, { topic: idToTag.get(row.tag.id) ?? row.tag, average: 0, longStddev: 0, weekAverage: 0, weekTotal: 0, delta: null, net: null, max: 0 });
        }
        const data = tags.get(row.tag.id);
        data.average += row.mentionCount;
    }

    for (const tag of tags.values()) {
        tag.average = tag.average / longDuration;
    }

    // Calculate stddev
    for (const row of longTermResults) {
        const data = tags.get(row.tag.id);
        data.longStddev += Math.pow(row.mentionCount - data.average, 2);
    }

    for (const tag of tags.values()) {
        tag.longStddev = Math.sqrt(tag.longStddev / longDuration);
    }

    const periodResults = await periodPromise;

    // Need to make sure that tags we didn't pick up in the last while are present in our overall set
    for (const row of periodResults) {
        if (!tags.has(row.tag.id)) {
            tags.set(row.tag.id, { topic: idToTag.get(row.tag.id), average: 0, longStddev: 0, weekAverage: 0, weekTotal: 0, delta: null, net: null, max: 0 });
        }
    }

    const otherResults = await grouseCount(periodFilter, ["tag", "published"],
        ["tag[id,flag]", "mentionCount"],
        null, {tagNamespace: namespace});

    for (const row of otherResults) {
        const tag = tags.get(row.tag.id);
        if (tag && row.mentionCount > tag.max) {
            tag.max = row.mentionCount;
            tag.maxDate = row.published
        }
    }

    for (const row of periodResults) {
        if (!row.tag.leaf) continue;
        const tagStoreItem = idToTag.get(row.tag.id);
        const tag = tags.get(tagStoreItem.id);

        tag.topic = tagStoreItem;
        tag.weekAverage = row.mentionCount / duration;
        tag.weekTotal = row.mentionCount;
        tag.delta = tag.longStddev ? (tag.weekAverage - tag.average) / tag.longStddev : 0;
        tag.leaf = true;
        tag.moe = 1.95 * tag.longStddev; // Should really be using a t-score instead of a z-score critical value.
        tag.net = row.totalVerifiedSentiment / row.sentimentVerifiedCount;

        tag.filter = `(${periodFilter}) and ${namespace} is ${tagStoreItem.id}`;
    }

    // Fill in missing z-scores / delta values for those that did not occur during the week.
    for (const tag of tags.values()) {
        if (tag.delta === null) {
            tag.delta = tag.longStddev ? (0 - tag.average) / tag.longStddev : 0;
        }

        // Let's see if something was spiking during the week
        tag.isSpike = tag.max &&
            tag.max >= 10 &&                      // Don't want low volumes to introduce noise.
            tag.max > tag.average + 0.5 * tag.longStddev;
    }


    let sorted = Array.from(tags.values());

    // We want to look for tags that are new in the time period that we're using. Their stats will
    // be wrong, often showing spikes when there are none.
    try {
        const earliest = earliestDate(longTermFilter);

        for (const s of sorted){
            const tag = s.topic;
            if (moment(tag.created).isAfter(earliest)) {
                s.isNew = true;
                s.isSpike = false;
            }
        }
    } catch (e) {
        console.warn("Unable to determine date ranges for new tags", e);
    }

    sorted = sorted.sort((lhs, rhs) => {
        if (lhs.isNew && !rhs.isNew) return 1;
        if (rhs.isNew && !lhs.isNew) return -1;
        if (lhs.isSpike && !rhs.isSpike) return -1;
        if (rhs.isSpike && !lhs.isSpike) return 1;
        if (lhs.max && !rhs.max) return -1;
        if (!lhs.max && rhs.max) return 1;

        const delta = (rhs.delta ?? 0) - (lhs.delta ?? 0);

        if (lhs.isSpike) { // Here they are both spikes.
            if (!lhs.maxDate && !rhs.maxDate) return delta;
            if (!lhs.maxDate && rhs.maxDate) return 1;
            if (lhs.maxDate && !rhs.maxDate) return -1;
            return moment(rhs.maxDate).diff(lhs.maxDate);
        }

        return delta;
    });

    if (count > 0) {
        sorted = sorted.splice(0, count);
    }

    return sorted;
}

/**
 * Open the explore enterprise panel.
 */
export async function gotoExploreEnterprise(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));

    Beef.router.navigate(`/${VuexStore.state.account.code}/explore/enterprise`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

export async function gotoExplore(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));
    Beef.router.navigate(`/${VuexStore.state.account.code}/explore`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

/**
 * Open the explore press panel.
 */
export async function gotoExplorePress(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));


    Beef.router.navigate(`/${VuexStore.state.account.code}/explore/press`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

export async function gotoExploreConsumer(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));

    Beef.router.navigate(`/${VuexStore.state.account.code}/explore/consumer`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

/**
 * Open the explore risk panel.
 * @param optionalBrand {Object, optional}
 * @param optionalDateRange {String, optional} In our date range format we use in the basic filter.
 */
export async function gotoExploreRisk(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));
    Beef.router.navigate(`/${VuexStore.state.account.code}/explore/risk`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

/**
 * Open the explore CX panel.
 * @param optionalBrand {Object, optional}
 * @param optionalDateRange {String, optional} In our date range format we use in the basic filter.
 */
export async function gotoExploreCx(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));
    Beef.router.navigate(`/${VuexStore.state.account.code}/explore/cx`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

/**
 * Opens the explore trending keyword panel
 */
export async function gotoExploreTrendingKeywords(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));
    Beef.router.navigate(`/${VuexStore.state.account.code}/explore/keyword-trends`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

/**
 * Opens the explore trending topics panel
 */
export async function gotoExploreTrendingTopics(optionalBrand, optionalDateRange) {
    const promises = [];
    if (optionalBrand) promises.push(VuexStore.dispatch('explorePanel/setBrand', optionalBrand));
    if (optionalDateRange) promises.push(VuexStore.dispatch('explorePanel/setDate', optionalDateRange));

    Beef.router.navigate(`/${VuexStore.state.account.code}/explore/topics`, { trigger: true });

    if (promises.length) await Promise.all(promises);
}

// This is used to check the account you're in every hour or so to see if there are
// any new spikes.
let checkSpikingRiskTimer;

/**
 * This allows us to check, for an account, if risk is spiking in the last few days,
 * and to report that to the user.
 */
export async function checkSpikingRisk() {
    try {
        const account = VuexStore.state.account;
        const user = VuexStore.state.user;
        if (!account?.code) return;

        const ROOT_KEY = `safe-cache:checked-risk:${account.code}:${user.id}`;

        if (!checkSpikingRiskTimer) {
            setTimeout(() => {
                sessionStorage.removeItem(ROOT_KEY);
                checkSpikingRiskTimer = null;
                checkSpikingRisk();
            }, 60 * 60 * 1000) // Check every hour
        }

        if (getPauseTips()) return; // Something has asked to pause display of popup info.

        if (sessionStorage.getItem(ROOT_KEY)) return;
        sessionStorage.setItem(ROOT_KEY, "1");

        await VuexStore.dispatch("refreshBrands");
        const ownedBrands = VuexStore.getters.ownedBrands;

        if (!ownedBrands?.length) return;

        for (const brand of ownedBrands) {
            const dateRange = "WEEK";
            const dateFilter = `published inthelast ${dateRange}`;
            if (!brand) return;

            const risks = await loadTrendingRisk(brand.id, dateFilter);
            if (!risks) return;

            const spiking = risks
                .filter(r => r.isSpike)
                .sort((lhs, rhs) => new Date(lhs.maxDate) < new Date(rhs.maxDate));

            if (spiking.length) {
                const name = spiking[0].topic.name;
                const date = spiking[0].maxDate;
                const max = spiking[0].max;
                const LOCAL_KEY = `${ROOT_KEY}:${brand.id}:${date}`;
                if (localStorage.getItem(LOCAL_KEY)) return;
                localStorage.setItem(LOCAL_KEY, "1");

                let message;
                if (moment(date).isSameOrAfter(moment().startOf('day'))) {
                    message = escapeHtml`<strong>${name}</strong> 
                                is spiking for ${formatBrandName(brand)} today, with a volume of 
                                ${formatNumber(max)} ${formatPlural(max, 'mention')}`;
                } else {
                    message = escapeHtml`<strong>${name}</strong>
                                   was spiking for ${formatBrandName(brand)} 
                                   on ${formatDate(date, "D MMMM")} with a volume of ${formatNumber(max)} ${formatPlural(max, 'mention')}`;
                }

                const trendFilter = await getTrendingRiskFilter(brand.id, dateFilter, `segment is ${spiking[0].topic.id}`);
                const {summary} = await summariseFilter(trendFilter);
                const more = escapeExpression(summary);


                notifyUser({
                    message,
                    isEscapedHtml: true,
                    icon: '<be-rpcs-icon code="RISK"></be-rpcs-icon>',
                    longDelay: 120000,
                    action: {
                        name: "Explore",
                        tooltip: "See trends in the explore panel",
                        method: () => { gotoExploreRisk(brand, dateRange) }
                    },
                    more
                });
                return;
            }
        }

        // Nothing has spiked, so now is a good time to clear out local storage
        // But only cleaer it out for this account. Don't want to cause repeat
        // spiking messages for other accounts.
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith(ROOT_KEY)) {
                localStorage.removeItem(key);
            }
        })
    } catch (e) {
        console.warn(e);
    }
}

let checkConsumerMentionsTimer;

/**
 * This looks for non-enterprise posts from the last week that have a fair amount of engagement
 * and are pulling in at least some risk. It notifies the user that this is happening.
 * @param {boolean} [force=false]
 * @return {Promise<void>}
 */
export async function checkSpikingConsumer(force) {
    const account = VuexStore.state.account;
    const user = VuexStore.state.user;
    if (!account?.code) return;

    const ROOT_KEY = `safe-cache:checked-consumer:${account.code}:${user.id}`;

    if (!checkConsumerMentionsTimer) {
        setTimeout(() => {
            sessionStorage.removeItem(ROOT_KEY);
            checkConsumerMentionsTimer = null;
            checkSpikingConsumer();
        }, 60 * 60 * 1000) // Check every hour
    }

    if (getPauseTips()) return; // Something has asked to pause display of popup info.

    if (sessionStorage.getItem(ROOT_KEY) && !force) return;
    sessionStorage.setItem(ROOT_KEY, "1");

    try {
        await VuexStore.dispatch("refreshBrands");
        const ownedBrands = VuexStore.getters.ownedBrands;

        if (!ownedBrands?.length) return;

        for (const brand of ownedBrands) {
            // We want to see which posts have the most graph engagement.
            const TIME = "week";
            const filter = `brand isorchildof ${brand.id} and
                         published inthelast ${TIME} and
                         media is consumer and
                         visibility is public and 
                         tag is 1 and 
                         relevancy isnt irrelevant`;
            const engagingConsumerPosts = await count(filter, ['conversationId'], ["mentionCount"]);


            for (const engagement of engagingConsumerPosts) {
                try {
                    if (engagement.mentionCount < 5) return; // Too few mentions tagged with risk.

                    const LOCAL_KEY = `${ROOT_KEY}:${engagement.conversationId}`;
                    if (localStorage.getItem(LOCAL_KEY) && !force) continue;
                    localStorage.setItem(LOCAL_KEY, "1");

                    const mention = await getMention(account.code, engagement.conversationId);
                    if (mention.category?.id === "ENTERPRISE") continue;
                    if (mention.tombstoned) continue;
                    if (moment(mention.published).isBefore(moment().startOf("day").subtract(1, TIME))) continue;
                    if ((mention.engagement ?? 0) < 20) continue;// Engagement too low

                    const riskOnMention = mention.tags?.find(t => t.flag === "RISK");
                    let indirectRisk = null;
                    if (!riskOnMention) {
                        const riskQuery = await count(`brand isorchildof ${brand.id} and published inthelast month and relevancy isnt irrelevant and visibility is public and conversationId is '${mention.id}' and tag is 1`, ['tag'], ["mentionCount"]);
                        if (riskQuery?.length) {
                            indirectRisk = riskQuery.find(o => o.tag.flag === "RISK")?.tag;
                        }
                    }

                    const media = mention?.category?.label?.toLowerCase() ?? "consumer";
                    const location = mention?.socialNetwork?.label ? escapeHtml`on <strong>${mention?.socialNetwork?.label}</strong>` : "";

                    let message;
                    let authorName = mention.authorName ?? "an anonymous author";
                    if (riskOnMention) {
                        message = `
                                A ${media} post ${location} by <strong>${authorName}</strong>
                                relating to <strong>${escapeExpression(riskOnMention?.name)}</strong> and 
                                involving <strong>${escapeExpression(formatBrandName(brand))}</strong>
                                is gaining a noticeable amount of interaction with an
                                <strong>engagement of <span class="number">${formatNumber(mention.engagement)}</span></strong>
                            `;
                    } else {
                        message = `
                                A ${media} post ${location} by <strong>${authorName}</strong>                                 
                                involving <strong>${escapeExpression(formatBrandName(brand))}</strong>
                                is gaining a noticeable amount of interaction around 
                                <strong>${escapeHtml(indirectRisk?.name)}</strong>
                                 with an 
                                <strong>engagement of <span class="number">${formatNumber(mention.engagement)}</span></strong>
                            `;
                    }

                    const summaryFilter = `brand isorchildof ${brand.id} and relevancy isnt irrelevant and published inthelast ${TIME} and conversationId is '${mention.id}'`;
                    const { summary } = await summariseFilter(summaryFilter);
                    const more = escapeExpression(summary);


                    notifyUser({
                        message,
                        isEscapedHtml: true,
                        icon: '<be-rpcs-icon code="RISK"></be-rpcs-icon>',
                        noDismissTimer: true,
                        action: {
                            name: "See",
                            tooltip: "See this mention",
                            method: () => {
                                gotoMentionPanel(summaryFilter, "published")
                            }
                        },
                        more
                    });
                    return; // Only need to show one at a time — don't need to overwhelm the user.

                } catch(e) {
                    console.warn(`Unable to check mention engagement for mention ${engagement.mentionId}`, e)
                }
            }
        }

        // Nothing has spiked, so now is a good time to clear out local storage
        // But only cleaer it out for this account. Don't want to cause repeat
        // spiking messages for other accounts.
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith(ROOT_KEY)) {
                localStorage.removeItem(key);
            }
        })
    } catch(e) {
        console.warn("Unable to check consumer posts for risk", e);
    }
}

/**
 * Calculates the statistics shown on the explore CX panel.
 * @param {Number} brandId
 * @param {FilterString} dateFilter
 * @param {Function, optional} callback - Called to allow you to track progress. Given two parameters: a count, and a total, to track progress.
 * @param {FilterString, optional} additionalFilter
 * @return {Promise<null|{total, private, cancelCount: (*|number), public, purchaseCount: (*|number), reshares: (*|number)}>}
 */
export async function calculateCxStats(brandId, dateFilter, callback, additionalFilter) {
    const cxSegments = await getAllCxSegmentLists();
    if (!cxSegments?.length) return null;

    const segmentFilter = appendSegmentRestrictions(cxSegments.map(tag => `segment is ${tag.id}`).join(' or '));
    let i = 1;
    const total = 4;

    let generalFilter = "relevancy isnt irrelevant";
    if (brandId) generalFilter += ` and brand isorchildof ${brandId}`;
    if (dateFilter) generalFilter += ` and (${dateFilter})`;
    if (additionalFilter) generalFilter = appendFiltersReadably(generalFilter, additionalFilter);

    // Append segment restrictions.
    const cxStats = await count(`(${generalFilter}) and (${segmentFilter})`, ["socialNetwork", "visibility"]);
    if (callback) callback(i++, total);
    // commit('setInitialisedPercent', 45);
    const cxReshares = await count(`${generalFilter} and tag is 100913`);
    if (callback) callback(i++, total);
    const cxPurchase = await count(`${generalFilter} and tag is 2 and (${segmentFilter})`);
    if (callback) callback(i++, total);
    const cxCancel = await count(`${generalFilter} and tag is 3 and (${segmentFilter})`);
    if (callback) callback(i++, total);

    return {
        total: cxStats.map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0),
        private: cxStats.filter(d => d.visibility).map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0),
        public: cxStats.filter(d => !d.visibility).map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0),
        reshares: cxReshares?.mentionCount ?? 0,
        purchaseCount: cxPurchase?.mentionCount ?? 0,
        cancelCount: cxCancel?.mentionCount ?? 0
    }
}