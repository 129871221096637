import ObjectStore from './ObjectStore'
import { mash } from '../Services'


function handleDeleted(tag) {
    tag.deleted = true;
    tag._loading = false;
    // noinspection EqualityComparisonWithCoercionJS
    if (tag.name == tag.id) {
        tag.name = `deleted:${tag.id}`
    }
}

/**
 * Fetches and caches tags for the selected account.
 * @deprecated
 */
export default class TagStore extends ObjectStore {

    constructor() {
        super()
        this.refreshCall = null
    }

    /**
     * Refresh our tags. Returns promise. This does not start a new fetch if one is already in progress.
     */
    refresh(soft) {
        if (this.refreshCall) return this.refreshCall
        if (this.list && soft) return Promise.resolve(this.list)
        if (!this.accountCode) return Promise.resolve([]);
        return this.refreshCall = mash.get("/rest/accounts/" + this.accountCode + "/tags")
            .then(res => {
                this.list = []
                res.data.forEach(tag => this.list.push(this.add(tag)))
                this.list.forEach(parent => {
                    if (parent.children) parent.children.forEach(cid => {
                        let c = this.byId[cid]
                        if (c) c._parent = parent
                    })
                })
                return this.list
            }).finally(() => this.refreshCall = null)
    }

    /**
     * Lookup a tag by id. If we don't have it already a placeholder tag is returned and it is fetched in the
     * background.
     */
    get(id) {
        let tag = this.byId[id]
        if (!tag) {
            tag = { id: id, name: "" + id, namespace: null, description: null, children: null, ordinal: null, _loading: true }
            this.byId[tag.id] = tag
            this.refresh()
                .then(data => {
                    const t = this.byId[id];
                    if (t._loading) {
                        // This tag has not been found. Mark it as deleted.
                        handleDeleted(t);
                    }
                })
        } else if (tag._loading && this.list && this.list.length) {
            handleDeleted(tag);
        }
        return tag
    }

    /**
     * Returns true if the tag with the given ID exists in this account.
     * @param id
     * @returns {Promise<boolean>}
     */
    async exists(id) {
        await this.refresh(true);
        return !!this.byId[id];
    }

    /**
     * Search for tags by name and optional namespace.
     */
    search(q, namespace) {
        q = q.toLowerCase()
        let ans = []
        return this.list.filter(t => {
            if (namespace && t.namespace !== namespace) return false
            return t.name.toLowerCase().indexOf(q) >= 0
        }).sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? +1 : 0)
    }

    /**
     * Find the tag with the same name (case-sensitive) or null if none.
     */
    getByName(name, namespace) {
        name = name.toLowerCase()
        return this.list.find(t => {
            if (namespace && t.namespace !== namespace) return false
            return name === t.name.toLowerCase()
        })
    }

    /**
     * If any of the tag ids in the array are strings then see if we can find a matching tag and replace the string
     * with its id. Otherwise create new tags. Returns promise that resolves to array of tag ids.
     */
    ensureTagsExist(tagIds, namespace) {
        let toCreate = []
        tagIds.forEach((v,i) => {
            if (typeof v === "string") {
                let t = this.getByName(v, namespace)
                if (t) tagIds[i] = t.id
                else toCreate.push({ name: v, namespace: namespace })
            }
        })
        if (!toCreate.length) return Promise.resolve(tagIds)
        return mash.post("/rest/accounts/" + this.accountCode + "/tags", toCreate)
            .then(res => {
                res.data.forEach(t => {
                    let e = this.add(t)
                    if (Object.is(e, t)) this.list.push(e)
                })
                tagIds.forEach((v,i) => {
                    if (typeof v === "string") {
                        let t = this.getByName(v, namespace)
                        if (t) tagIds[i] = t.id
                        else throw "Tag [" + v + "] not found?"
                    }
                })
                return tagIds
            })
    }

    /**
     * Get the customer experience segment list for the brand or null if none.
     */
    async getCXSegmentList(brand) {
        while (brand.parent) brand = brand.parent;
        if (!brand.segmentListIds || !brand.segmentListIds.length) return null
        await this.refresh(true)
        for (let i = 0; i < brand.segmentListIds.length; i++) {
            let tag = this.get(brand.segmentListIds[i])
            if (tag.segmentType && tag.segmentType.id === "CX_LIST") return tag
        }
        return null
    }

    /**
     * Get the conduct segment list for the brand or null if none.
     */
    async getConductSegmentList(brand) {
        while (brand.parent) brand = brand.parent;
        if (!brand.segmentListIds || !brand.segmentListIds.length) return null
        await this.refresh(true)
        for (let i = 0; i < brand.segmentListIds.length; i++) {
            let tag = this.get(brand.segmentListIds[i])
            if (tag.segmentType && tag.segmentType.id === "CONDUCT_LIST") return tag
        }
        return null
    }
}