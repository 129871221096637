var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row-fluid\">\n"
    + ((stack1 = container.invokePartial(require("../../app/Header.handlebars"),depth0,{"name":"../../app/Header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"feed-loading delayed-message\">\n        <h3>Fetching your custom data sources from DataEQ...</h3>\n        <p>\n            <span class=\"spinner\"></span>\n        </p>\n    </div>\n    <div class=\"feed-error delayed-message\">\n        <h3>There has been a problem fetching your data sources from DataEQ</h3>\n        <p>\n            Please try reloading this page.\n        </p>\n    </div>\n    <div class=\"feed-list\"></div>\n\n    <p>DataEQ can read mentions from the <a href=\"https://en.wikipedia.org/wiki/RSS\" target=\"blank\">RSS feeds</a>\n        provided by many websites and you can add those RSS feeds here. However If you do find a site that\n        we don't appear to be searching please <a href=\"mailto:support@dataeq.com\">let us know</a>.</p>\n\n    <p>Note: you can add Facebook pages and Twitter users in the\n        <a href=\"/"
    + container.escapeExpression(__default(require("../../helpers/accountCode.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"accountCode","hash":{},"data":data,"loc":{"start":{"line":23,"column":18},"end":{"line":23,"column":33}}}))
    + "/setup/online\"><i class=\"icon-comment-empty\"></i>Online Profiles</a> setup section.\n    </p>\n\n</div>\n";
},"usePartial":true,"useData":true});