/**
 * Feature flag's to enable functionality for specific users or groups of users. Test these from handlerbars files
 * using iffeature (see HandlebarsHelpers.js).
 */

import {isAccountInactive, isMashAdmin} from "@/app/Permissions";
import {account, currentAccountCode} from "@/app/utils/Account";
import {deprecatedUserObject} from "@/store/deprecated/Stores";
import VuexStore from "@/store/vuex/VuexStore";
import Vue from 'vue';
import user from "@/helpers/user";

let localFlags = {};
let turnOffAdmin = false;


/** Whether admin settings should be disabled for the given user or not. */
export function shouldTurnOffAdmin(optionalUserOrId) {
    let userId = optionalUserOrId?.id ?? optionalUserOrId ?? deprecatedUserObject.id;
    try {
        return sessionStorage.getItem("beef:user:" + userId + ":noAdmin") === "true";
    } catch (e) {
        console.warn("Error determining noAdmin settings", e);
        return false;
    }
}

export function loadLocalFlags(userId) {
    let features = {};
    try {
        let json = localStorage.getItem("safe-cache:user:" + userId + ":features");
        if (json) features = JSON.parse(json);
    } catch (e) {
        console.warn("Unable to read features from local storage", e);
    }

    return features;
}

/**
 * Debug mode is useful if you want to add extra features to help with testing / debugging.
 * If you are using vue, this is available on user.debugMode as a watchable property.
 */
export function isDebugModeEnabled() {
    return !!localFlags["debugMode"];
}

export function saveLocalFlags(features, userId) {
    userId = userId || deprecatedUserObject.id;

    try {
        localStorage.setItem("safe-cache:user:" + userId + ":features", JSON.stringify(features));
    } catch (e) {
        console.warn("Unable to save features to localstorage", e);
    }

    if (userId === deprecatedUserObject.id) {
        localFlags = features;
    }
}

export function setTurnOffAdmin(value, optionalUserId) {
    var userId = optionalUserId || deprecatedUserObject.id;
    if (userId === deprecatedUserObject.id) {
        VuexStore.commit('setUserAdmin', !value);
        deprecatedUserObject.admin = !value;
    }
    try {
        sessionStorage.setItem("beef:user:" + userId + ":noAdmin", "" + (!!value));
    } catch (e) {
        console.warn("Error saving noAdmin setting", e);
    }

    if (userId === deprecatedUserObject.id) {
        turnOffAdmin = !!value;
    }
}


function getFlag(flagName) {
    return !!(!turnOffAdmin && (deprecatedUserObject?.featureFlags && deprecatedUserObject.featureFlags[flagName] || localFlags[flagName]));
}

function isAdmin() {
    return deprecatedUserObject.admin && !turnOffAdmin;
}

function isOps() {
    return deprecatedUserObject.ops && !turnOffAdmin;
}

/**
 * Ensure that all the data structures for managing feature flags have been initialised.
 */
export function initFeatures() {
    localFlags = loadLocalFlags(deprecatedUserObject.id);
    turnOffAdmin = shouldTurnOffAdmin();
    Vue.set(deprecatedUserObject, 'debugMode', isAdmin() && isDebugModeEnabled());
}

export const features = {
    addBusyPhrases: function () {return getFlag("addBusyPhrases");},
    editBrandJson: function () {return getFlag("editBrandJson") && !isAccountInactive();},

    // Set this to true if a user should be able to ignore brand depth.
    // For example, when a staff member doing account setup needs to reorganise brand structure.
    ignoreBrandDepth: function () {return getFlag("ignoreBrandDepth");},
    brandDescription: function () {return isAdmin();},    // can edit or view root brand description
    historicalSearch: function () {return (isAdmin() || getFlag("historicalSearch"))},
    historicalSearchRetrieve: function () {return getFlag("historicalSearchRetrieve")},

    instagramReviewer: function () {return getFlag("instagramReviewer")},

    // This feature is meant for very light editing of topics on mentions.
    // IT IS NOT MEANT FOR MASS MANAGEMENT OF BAD TOPICS OVER THE LONG TERM.
    // Please complain to any person who wants to do this. Long term, topics for
    // an account MUST be fixed in the Crowd itself.
    editTopicsOnMentions: function () {return getFlag("editTopicsOnMentions")},

    canEditSentient: function () {return isAdmin() || getFlag("canEditSentiment");},

    seeRawSentimentOnCharts: function () {return isAdmin()},
    createCrowdJudgement: function () {return isAdmin() || getFlag("createCrowdJudgement")},
    reportSpamAuthors: function () { return isAdmin() },

    //--------------------------------------------------------------------------
    // Being implemented
    //--------------------------------------------------------------------------

    // Developing new top level mentions panel
    newMentionsPanel: () => getFlag("newMentionsPanel"),

    dragAndDrop: () => getFlag("dragAndDrop"),
    chartSegmentListPercentageChanges: () => getFlag("chartSegmentListPercentageChanges"),
    otherCalculationFixes: () => getFlag("otherCalculationFixes"),

    // Dave Orpen fixing the conversation view.
    newMentionConversationView: () => getFlag("newMentionConversationView"),

    // Note: TikTok is always feature flagged: it can only be turned on per account.
    tikTokProfiles: () => {
        return !!(account()?.hasFeedTikTok)
    },

    //--------------------------------------------------------------------------
    // Test adding summaries to explore

    exploreSummaries: () => {
        if (isAdmin()) return true;
        return testingClients.has(currentAccountCode());
    },

    showCitations: () => getFlag("showCitations"),

    //--------------------------------------------------------------------------
    // todo: To be resolved.
    //--------------------------------------------------------------------------

    // For testing the changes to use dataeq.com domain name.
    // Need to put in a 30x redirect now.
    dataEqNameChange: () => true,       // Testing. Need to see what problems this cause.

    googleManagedBusinessIntegration: () => !!(account()?.hasFeedGoogleReviews) || getFlag("googleManagedBusinessIntegration"),

    newProfilePicker: () => isAdmin(),

    commentaryPlus: () => isAdmin(),

    commentaryPlusInWidget: () => getFlag("commentaryPlusInWidget"),

    upgradeDashBoardsToCommentPlus: () => getFlag("upgradeDashBoardsToCommentPlus"),
    feedsVueOverhaul: () => getFlag("feedsVueOverhaul"),
    importMentionsObjectBugFix: () => getFlag("importMentionsObjectBugFix"),
    topicsVueMigration: () => getFlag("topicsVueMigration"),

};

/**
 * Clients for who all LLM / generative AI testing features are on for.
 * @type {Set<string>}
 */
const testingClients = new Set(
    [
        "BESP43AA", // Spur
        "BEWO32AA", // Burger King
        "BENB11AA", // Namibia breweries
        "BEAB24AA", // Absa
        "BEDC02AA", // Discovery
        "BEVO46AA", // Voxcroft
        "BEVO73AA", // Voxcroft
        "BEVO87AA", // Voxcroft
        "BEVO84AA", // Voxcroft
        "BEVO60AA", // Voxcroft
        "BEVO90AA", // Voxcroft
        "BEVO88AA", // Voxcroft
        "BEVO49AA", // Voxcroft
        "BEVO53AA", // Voxcroft
        "BEAF37AA", // African Bank
        "BESB96AA", // Teneo
        "BEAL65AA", // ALJ
        "BEFO46AA", // Foodics
        "BEOT95AA", // OSN
        "BEFN03AA", // FNB
        "CAPI01AA", // Capitec
    ]
)
