<template>
    <div class="be-dark-card" :class="classes">
        <!-- If no descriptions or popup have been supplied, then we just get the default slot -->
        <slot v-if="!$slots.description && !$slots.popup"/>


        <div v-else class="be-dark-card__overlay">
            <div class="be-dark-card__slot" :class="slotClasses">
                <slot/>
            </div>
            <!-- The description is revealed on hover and replaces the main content -->
            <div class="be-dark-card__description">
                <slot name="description"/>
            </div>

            <!-- the popup is revealed on hover, and sits beside the main content -->
            <div v-if="$slots.popup"
                 class="be-dark-card__popup"
                 :class="popupClasses">
                <div class="beef-tooltip">
                    <slot name="popup"/>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        popupDirection: {
            type: String,
            default: "right",
            validator(value) {
                // The value must match one of these strings
                return ['right', 'left', 'up', 'down'].indexOf(value) !== -1
            }
        }
    },

    computed: {
        classes() {
            const classes = [];
            if (this.disabled) classes.push('be-dark-card--disabled');
            return classes;
        },
        slotClasses() {
            const classes = [];
            if (this.$slots.description) classes.push('be-dark-card__slot--hidden');
            return classes;
        },
        popupClasses() {
            const classes = [];

            switch (this.popupDirection) {
                case "right": classes.push("be-dark-card__popup--right"); break; //
                case "left": classes.push("be-dark-card__popup--left"); break;
                case "up": classes.push("be-dark-card__popup--up"); break;
                case "down": classes.push("be-dark-card__popup--down"); break;
                default:
                    throw new Error("Unrecognised popup direction: " + this.popupDirection);
            }
            return classes;
        }
    }
}
</script>


<style scoped lang="sass">

.be-dark-card
    // Ensure tags remain visible on a darker background
    --colour-tag-background: #3a3a3a

    position: relative
    display: inline-block
    box-sizing: border-box
    padding: 10px
    margin: 5px
    border: thin solid #111
    border-radius: 3px
    background: #222
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4), -2px -2px 2px rgba(100, 100, 100, 0.3)
    color: var(--be-colour-text-dark)
    --base-transition-properties: border, box-shadow, filter, background
    transition-property: var(--base-transition-properties)
    transition-duration: 500ms
    animation: be-dark-card__shadow 2s both

    strong
        color: white
        font-size: calc(1em + 0.5px)

    h5
        font-size: 1em
        color: white
        margin: 0
        padding: 0

    h1
        margin: 0
        padding: 0

    &--disabled
        transition-duration: var(--base-transition-properties), color
        background: #2a2a2a
        color: var(--be-colour-muted-text-dark)
        box-shadow: none
        border: transparent
        filter: grayscale(0.5)
        cursor: default
        animation: none

    .be-dark-card__description
        opacity: 0
        color: var(--be-colour-muted-text-dark)
        transition: opacity 100ms

    .be-dark-card__slot
        transition: opacity 100ms

    .be-dark-card__popup
        opacity: 0
        position: absolute
        z-index: 1
        pointer-events: none
        box-sizing: border-box
        --border-settings: 10px solid transparent

        &--right
            left: 100%
            top: 0
            min-width: 150%
            border-left: var(--border-settings)

        &--down
            left: 0
            top: 100%
            border-top: var(--border-settings)
            min-height: max-content
            width: 100%

        .beef-tooltip
            height: 100%
            width: 100%
            box-sizing: border-box
            max-width: unset


    &:hover
        .be-dark-card__slot--hidden
            opacity: 0
        .be-dark-card__description
            transition: opacity 100ms 150ms
            opacity: 1
        .be-dark-card__popup
            pointer-events: auto
            transition: opacity 250ms 500ms
            opacity: 1


    .be-dark-card__overlay
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: 1fr
        height: 100%
        align-items: center
        .be-dark-card__description,
        .be-dark-card__slot
            grid-column: 1
            grid-row: 1




@keyframes be-dark-card__shadow
    0%
        box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(100, 100, 100, 0)
    100%
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4), -2px -2px 2px rgba(100, 100, 100, 0.3)

</style>
