var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<a href=\"#add-online-profiles\">Add online profiles</a>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<td class=\"edit\">Edit</td>";
},"5":function(container,depth0,helpers,partials,data) {
    return "6";
},"7":function(container,depth0,helpers,partials,data) {
    return "5";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":4},"end":{"line":84,"column":18}}})) != null ? stack1 : "")
    + "\n    <p>\n        Add your own pages, and those of competitors, to ensure they are scanned by DataEQ and track follower growth.\n        You will still need to add appropriate search phrases to assign mentions to the correct brand(s).\n    </p>\n\n    <p>\n        Click in the authorised column to authenticate a profile you manage. This will allow you to reply to\n        mentions from the app, and provide a faster and more reliable feed on Facebook.\n    </p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "        <p>Click one of the buttons below to add a new online profile:</p>\n\n        <div class=\"form-inline add-buttons\">\n            <a class=\"btn\" title=\"Facebook page\" data-type=\"FACEBOOK_PAGE\"><i class=\"symbol-facebook-rect\" data-type=\"FACEBOOK_PAGE\"></i><br>Facebook<br>Page</a>\n            <a class=\"btn\" title=\"Twitter screen name\" data-type=\"TWITTER_SCREEN_NAME\"><i class=\"symbol-twitter\" data-type=\"TWITTER_SCREEN_NAME\"></i><br>Twitter<br>User</a>\n            <a class=\"btn\" title=\"Youtube\" data-type=\"YOUTUBE_CHANNEL\"><i class=\"symbol-youtube\" data-type=\"YOUTUBE_CHANNEL\"></i><br>Youtube<br>Channel</a>\n            <a class=\"btn\" title=\"LinkedIn\" data-type=\"LINKEDIN_COMPANY\"><i class=\"symbol-linkedin-rect\" data-type=\"LINKEDIN_COMPANY\"></i><br>LinkedIn<br>Company</a>\n            <a class=\"btn\" title=\"Telegram\" data-type=\"TELEGRAM_CHANNEL\"><i class=\"symbol-telegram\" data-type=\"TELEGRAM_CHANNEL\"></i><br>Telegram<br>Channel</a>\n            <a class=\"btn\" title=\"WhatsApp\" data-type=\"WHATSAPP_NUMBER\"><i class=\"symbol-whatsapp\" data-type=\"WHATSAPP_NUMBER\"></i><br>WhatsApp<br>Number</a>\n            <a class=\"btn\" title=\"Hellopeter\" data-type=\"HELLOPETER\"><i class=\"symbol-hellopeter\" data-type=\"HELLOPETER\"></i>Hellopeter profile</a>\n            <a class=\"btn\" title=\"GoogleBusiness\" data-type=\"GOOGLEBUSINESS\"><i class=\"symbol-google-full\" data-type=\"GOOGLEBUSINESS\"></i>GoogleBusiness profile</a>\n\n        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":8},"end":{"line":103,"column":22}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "            <p>Click the button below to add a new online profile:</p>\n            <div class=\"form-inline add-buttons\">\n                <a class=\"btn\" title=\"Facebook page\" data-type=\"FACEBOOK_PAGE\"><i class=\"symbol-facebook-rect\"></i><br>Facebook<br>Page</a>\n            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "    <p>\n        <i class=\"symbol-twitter\"></i> For Twitter profiles you can choose to see direct messages sent to or from the profile in your\n        DataEQ account. Edit the profile after authorising it to enable this feature.\n    </p>\n\n    <p>\n        <i class=\"symbol-linkedin-rect\"></i> For LinkedIn company pages, the profile needs to be authorised to collect mentions. Click in the 'Authorised' column and allow DataEQ to read this page.\n    </p>\n\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":32},"end":{"line":157,"column":18}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "\n        <h2>CSV Download and Upload of Online Profiles</h2>\n        <p>\n            <a class=\"btn download-profiles-csv\" title=\"Download all profiles as a CSV file\">Download CSV</a>\n        </p>\n        <p>\n            <a class=\"btn upload-profiles-csv\" title=\"Upload (add) multiple profiles via a CSV file\">Upload CSV</a>\n        </p>\n        <p>\n            The CSV files use the following (case-sensitive) column headings. <em>Note that Instagram and LinkedIn\n            profiles are not validated on upload, only on authorization, so please ensure that the input handle is\n            correct.</em>\n        </p>\n        <dl>\n            <dt>handle</dt>\n            <dd>\n                A handle to look up as a social media handle in upload, or the existing handle in dowload. If a URL is\n                supplied in an upload, we attempt to extract the handle from it.\n            </dd>\n            <dt>type</dt>\n            <dd>\n                A type indicating the social medium. Supported values include <i>FACEBOOK_PAGE</i>,\n                <i>TWITTER_SCREEN_NAME</i>, <i>INSTAGRAM_USER</i>, <i>YOUTUBE_CHANNEL</i>,\n                <i>LINKEDIN_COMPANY</i>, <i>TELEGRAM_CHANNEL</i> and <i>INSTAGRAM_BUSINESS</i>.\n            </dd>\n            <dt>category</dt>\n            <dd>\n                <em>Optional for upload</em>. A (possibly empty) media category for the profile. Supported values\n                include <i>CONSUMER</i>, <i>ENTERPRISE</i>, <i>PRESS</i>, <i>DIRECTORY</i> and <i>UNKNOWN</i>.\n            </dd>\n        </dl>\n    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"busy\">\n    <span class=\"spinner\"></span>\n</div>\n\n<div class=\"loading\">\n\n</div>\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":9,"column":96}}})) != null ? stack1 : "")
    + "\n\n<table class=\"online-presence-table table table-condensed table-bordered table-hover\" style=\"display: none\">\n    <thead>\n    <tr>\n        <td class=\"presence\">\n            <div class=\"toggle\">\n                Online Profile <i class=\"plus icon-down-open\"></i><i class=\"minus icon-up-open\" style=\"display: none;\"></i>\n            </div>\n            <div class=\"profile-types\" style=\"display: none\">\n                <label class=\"checkbox-group\" title=\"Toggle Facebook profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"facebook\" checked> Facebook\n                </label>\n                <label class=\"checkbox-group\" title=\"Toggle X profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"twitter\" checked> X\n                </label>\n                <label class=\"checkbox-group\" title=\"Toggle Instagram profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"instagram\" checked> Instagram\n                </label>\n                <br>\n                <label class=\"checkbox-group\" title=\"Toggle YouTube profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"youtube\" checked> YouTube\n                </label>\n                <label class=\"checkbox-group\" title=\"Toggle LinkedIn profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"linkedin\" checked> LinkedIn\n                </label>\n                <label class=\"checkbox-group\" title=\"Toggle Telegram profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"telegram\" checked> Telegram\n                </label>\n            </div>\n        </td>\n        "
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":40,"column":83}}})) != null ? stack1 : "")
    + "\n        <td class=\"authorise-head\">\n            <div class=\"toggle\">\n                Authorised <i class=\"plus icon-down-open\"></i><i class=\"minus icon-up-open\" style=\"display: none;\"></i>\n            </div>\n            <div class=\"profile-authorized\" style=\"display: none\">\n                <label class=\"checkbox-group\" title=\"Toggle authorised profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"authorized\" checked> Yes\n                </label>\n                <label class=\"checkbox-group\" title=\"Toggle unauthorised profiles\">\n                    <input class=\"checkbox\" type=\"checkbox\" data-attr=\"unauthorized\" checked> No\n                </label>\n            </div>\n        </td>\n        <td>Category</td>\n        <td>Always<br>Relevant</td>\n        <td class=\"stats\" style=\"text-align: center\">Stats</td>\n        <td class=\"brands\" title=\"Brands with search phrases that match the online profile handle\">Brands With Phrases Matching Profile Handle</td>\n        <td title=\"Mentions where the profile is mentioned will be tagged with these tags\">Tags</td>\n    </tr>\n    </thead>\n    <tbody class=\"rows\">\n    <tr class=\"no-online-presences\" style=\"display: none\">\n        <td colspan=\""
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":63,"column":21},"end":{"line":63,"column":80}}})) != null ? stack1 : "")
    + "\">No online profiles found</td>\n    </tr>\n    </tbody>\n</table>\n<a name=\"add-online-profiles\"/>\n<div class=\"options\" style=\"display: none\">\n"
    + ((stack1 = __default(require("../../helpers/unlessfeature.js")).call(alias1,"instagramReviewer",{"name":"unlessfeature","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":4},"end":{"line":95,"column":22}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/iffeature.js")).call(alias1,"instagramReviewer",{"name":"iffeature","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":4},"end":{"line":104,"column":18}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/unlessfeature.js")).call(alias1,"instagramReviewer",{"name":"unlessfeature","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":4},"end":{"line":116,"column":22}}})) != null ? stack1 : "")
    + "\n    <div class=\"form-inline\">\n        <p>\n            <label class=\"checkbox\">\n                <input type=\"checkbox\" class=\"show-deleted\"> Show deleted online profiles\n            </label>\n        </p>\n    </div>\n\n    "
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":4},"end":{"line":157,"column":32}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});