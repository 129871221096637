var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            Select a root brand\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            Add phrases to a root brand\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            Select a date range\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            Confirm mention retrieval\n        ";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0), depth0))
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"BRAND_SELECT") : stack1),{"name":"ifcond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":46,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"PREVIEW") : stack1),{"name":"ifcond","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":12},"end":{"line":113,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"SYNC_AND_DATE_SELECT") : stack1),{"name":"ifcond","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":12},"end":{"line":147,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"CONFIRM") : stack1),{"name":"ifcond","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":12},"end":{"line":151,"column":23}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>\n                    Select a root brand to add your phrases to. If there are existing phrases with the same\n                    positive terms as those in your search, the duplicate phrases won't be added.\n                </p>\n                <p>Click <i>Next</i> to preview any changes that will be made to the brand setup.</p>\n                <div class=\"control-group\">\n                    <div class=\"controls\">\n                    <span class=\"root-brand tag-input uneditable-input span6\" tabindex=\"0\" title=\"Click to select a root brand\">\n                        <span class=\"placeholder muted text "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brand") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":60},"end":{"line":33,"column":88}}})) != null ? stack1 : "")
    + "\">\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":34,"column":79}}})) != null ? stack1 : "")
    + "\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brand") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":89}}})) != null ? stack1 : "")
    + "\n                        </span>\n                    </span>\n                    </div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFilterWarning") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":45,"column":23}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "selected";
},"15":function(container,depth0,helpers,partials,data) {
    return "<span class=\"spinner\"></span>";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"19":function(container,depth0,helpers,partials,data) {
    return "Select a root brand";
},"21":function(container,depth0,helpers,partials,data) {
    return "                    <p class=\"filter-warning\">\n                        <i class=\"icon-attention-1 warn\"></i> The filter of the selected root brand differs from your\n                        search filter. Some of the retrieved mentions might not match this filter.\n                    </p>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"toAdd") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"ifcond","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":78,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"ignored") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"ifcond","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":79,"column":16},"end":{"line":112,"column":27}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"toAdd") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"==",1,{"name":"ifcond","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":49,"column":20},"end":{"line":59,"column":31}}})) != null ? stack1 : "")
    + "                    <table class=\"table table-condensed table-bordered table-hover\">\n                        <tbody class=\"phrases level0 branch-collapsed\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"toAdd") : stack1),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":24},"end":{"line":70,"column":33}}})) != null ? stack1 : "")
    + "                        </tbody>\n                    </table>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>\n                            A phrase will be added to the root brand,\n                            <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>:\n                        </p>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>\n                            A total of "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"toAdd") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " phrases will be added to the root brand,\n                            <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>:\n                        </p>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "                            <tr>\n                                <td colspan=\"1\" class=\"phrase\">\n                                    <div class=\"inner\">\n                                        <div class=\"q\">"
    + container.escapeExpression(__default(require("../../../../helpers/renderPhrase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":66,"column":55},"end":{"line":66,"column":76}}}))
    + "</div>\n                                    </div>\n                                </td>\n                            </tr>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>\n                        No new phrases will be added to the root brand,\n                        <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>.\n                    </p>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"ignored") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"==",1,{"name":"ifcond","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":80,"column":20},"end":{"line":92,"column":31}}})) != null ? stack1 : "")
    + "                    <table class=\"table table-condensed table-bordered table-hover\">\n                        <tbody class=\"phrases level0 branch-collapsed\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"ignored") : stack1),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":24},"end":{"line":103,"column":33}}})) != null ? stack1 : "")
    + "                        </tbody>\n                    </table>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>\n                            A phrase in your search setup has the same positive terms as one or more phrases in the brand,\n                            <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>,\n                            and will not be added. Review the phrase below, and if you are happy, click <i>Next</i> to proceed.\n                        </p>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>\n                            There are "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"ignored") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " phrases in your search setup that have the same positive terms as one or more phrases in the brand,\n                            <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>,\n                            and will not be added. Review these phrases below, and if you are happy, click <i>Next</i> to proceed.\n                        </p>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>\n                        No phrases in your search were found to have the same positive terms as phrases in the brand,\n                        <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>.\n                        Click <i>Next</i> to proceed.\n                    </p>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.program(43, data, 0),"data":data,"loc":{"start":{"line":115,"column":16},"end":{"line":146,"column":23}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"control-group\">\n                        <span class=\"spinner\"></span> <span>Adding phrases .. please wait</span>\n                    </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"toAdd") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"ifcond","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(49, data, 0),"data":data,"loc":{"start":{"line":120,"column":20},"end":{"line":137,"column":31}}})) != null ? stack1 : "")
    + "                    <p>\n                        Now select the date range you'd like to retrieve data for. You can adjust the retrieval\n                        date filter to any dates that fall within the dates of your search. Selecting\n                        a shorter date range will create a new search record and mentions will be retrieved for\n                        that time frame only.\n                    </p>\n                    <p>The current filter will retrieve <span class=\"volume-label\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"volumeLabel") : depth0), depth0))
    + "</span>.</p>\n                    <div id=\"filter-region\"></div>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"toAdd") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"==",1,{"name":"ifcond","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(47, data, 0),"data":data,"loc":{"start":{"line":121,"column":24},"end":{"line":131,"column":35}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>\n                                A phrase was added to the root brand,\n                                <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>.\n                            </p>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>\n                                A total of "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? lookupProperty(stack1,"toAdd") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " phrases were added to the root brand,\n                                <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>.\n                            </p>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>\n                            No new phrases were added to the root brand,\n                            <a target=\"_blank\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandTitle") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"brandUrl") : depth0), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a>.\n                        </p>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>Your search filter will retrieve <span class=\"volume-label\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeLabel") : depth0), depth0))
    + "</span>, "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"filter") : depth0), depth0))
    + ".</p>\n                <p>Click <i>Retrieve</i> to start the retrieval.</p>\n";
},"53":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>\n"
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"BRAND_SELECT") : stack1),{"name":"ifcond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"PREVIEW") : stack1),{"name":"ifcond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"SYNC_AND_DATE_SELECT") : stack1),{"name":"ifcond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),"===",((stack1 = (depth0 != null ? lookupProperty(depth0,"PAGE") : depth0)) != null ? lookupProperty(stack1,"CONFIRM") : stack1),{"name":"ifcond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"page row-fluid\">\n        <div class=\"main-form\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":152,"column":15}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"dialog-button-bar\">\n        <a class=\"previous btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"previousDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":31},"end":{"line":156,"column":70}}})) != null ? stack1 : "")
    + "\">Previous</a>\n        <a class=\"next btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nextDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":27},"end":{"line":157,"column":62}}})) != null ? stack1 : "")
    + "\">Next</a>\n        <a class=\"ok btn btn-primary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"okDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":37},"end":{"line":158,"column":70}}})) != null ? stack1 : "")
    + "\">Retrieve</a>\n    </div>\n</div>\n";
},"useData":true});