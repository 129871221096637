<template>
    <drop-down-input v-model="val" :optional="optional" :options="options" :label="label"/>
</template>

<script>
    import DropDownInput from "./DropDownInput"
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapState} from "vuex";

    export default {
        name: "TopicViewInput",
        store: VuexStore,

        components: { DropDownInput },

        props: {
            label: String,
            value: [String, Array, Number],
            optional: Boolean
        },

        data() {
            return {
                val: this.value
            }
        },

        computed: {
            ...mapState(['account']),
            
            options() {
                let a = []
                this.account.topicTrees.forEach(t => { if (t.active) a.push(t) })
                this.account.topicViews.forEach(t => a.push(t))
                this.account.topicTrees.forEach(t => { if (!t.active) a.push(t) })
                return a
            }
        },

        watch: {
            value(v) { this.val = v },
            val(v) { this.$emit("input", v) }
        }
    }
</script>

<style scoped>

</style>