<template>
    <with-glossary-entry :glossary-id="glossaryId">
        <template v-slot="{entry}">
            <span v-if="entry">{{entry.glossaryTerm}}</span>
        </template>
    </with-glossary-entry>
</template>

<script>
import WithGlossaryEntry from "@/app/help/tips/WithGlossaryEntry";

export default {
    components: {WithGlossaryEntry},
    props: {
        glossaryId: {
            type: String,
            required: true
        }
    },
}
</script>