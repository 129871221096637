import _ from 'underscore';

Beef.module("EditBrandTier").addInitializer(function(startupOptions) {

    this.tiers = {
        ESSENTIALS: {label: 'Essentials', description: 'Clean relevant data without sentiment'},
        SENTIMENT:  {label: 'Sentiment', description: 'Clean relevant data with accurate sentiment'},
        TOPICS:     {label: 'Topics', description: 'Clean relevant data with accurate sentiment and topics'}
    };

    this.Model = Backbone.Model.extend({
        validation: {
            volumeLimit: {required: true, min: 0},
            crowdSamplePercentage: {required: true, min: 0, max: 100},
            crowdTopicPercentage: {required: true, min: 0, max: 100},
            tempCrowdSamplePercentage: {min: 0, max: 100, required: false},
            tempVolumeLimit: {min: 0, required: false},
            reason: { required: true }
        }
    });


    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/setup/crowd/EditBrandTier.handlebars"),

        attributes: { class: "edit-brand-tier dialog" },

        events: Object.assign({}, Beef.SettingsDialog.View.prototype.events, {
            "click .tier button": "tierToModel"
        }),

        modelEvents: {
            "change:tier": "tierToView"
        },

        onFirstRender: function() {
            this.tierToView();
        },

        tierToModel: function(ev) {
            var id = $(ev.target).attr("data-id");
            if (id) this.model.set("tier", id);
        },

        tierToView: function() {
            this.$el.find(".tier button").toggleClass("active", false);
            var tier = this.model.get('tier');
            this.$el.find(".tier button[data-id='" + tier + "']").toggleClass("active", true);
            this.$el.find(".topics").toggle(tier == 'TOPICS')
        }
    });

});