var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"spinner disabled\"></span> Sampling\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        Sample\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a id=\"retrieve-data-button\" title=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"retrieveTitle") : depth0), depth0))
    + "\" class=\"btn options "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"canRetrieve") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":78},"end":{"line":12,"column":120}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"retrieving") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "    </a>\n"
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"job") : depth0),"&&",((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"dataRetrieveCommenceDate") : stack1),{"name":"ifcond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":15}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"spinner disabled\"></span> Retrieving\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            Retrieve\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <p>Mentions retrieved: "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"actualRecordCount") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":21,"column":35},"end":{"line":21,"column":118}}})) != null ? stack1 : "")
    + "</p>\n        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"actualRecordCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":21,"column":64},"end":{"line":21,"column":102}}}));
},"15":function(container,depth0,helpers,partials,data) {
    return "0";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a id=\"start-job-button\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"startTitle") : depth0), depth0))
    + "\" class=\"btn options "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"canStart") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":106}}})) != null ? stack1 : "")
    + "\">\n    <i class=\"icon icon-play-4 "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"canStart") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":70}}})) != null ? stack1 : "")
    + "\"></i> Start\n</a>\n<a id=\"sample-data-button\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sampleTitle") : depth0), depth0))
    + "\" class=\"btn options "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"canSample") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":70},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"sampling") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "</a>\n"
    + ((stack1 = __default(require("../../helpers/iffeature.js")).call(alias3,"historicalSearchRetrieve",{"name":"iffeature","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":24,"column":14}}})) != null ? stack1 : "");
},"useData":true});