import {account} from "@/app/utils/Account";
import {getRootBrands} from "@/app/utils/Brands";

const parentCache = new Map();

/**
 * Returns all the topic parents that a particular child may belong to in this
 * account.
 */
export function getTopicParents(tagOrId, tags) {
    if (!tags) throw new Error("No tags supplied");
    var trees = account().topicTrees;
    if (!trees || !trees.length) return null;

    var tagId = tagOrId.id || tagOrId;

    if (parentCache.has(tagId)) return parentCache.get(tagId);

    var parents = trees
        .map(function (simpleTree) {
            var tree = tags[simpleTree.id];
            if (!tree) return null;

            if (tagId === tree.id) return null;
            var parent = tree.children.find(function (parentId) {
                return parentId === tagId;
            });

            if (parent) return null;

            parent = tree.children.find(function (parentId) {
                var parent = tags[parentId];
                if (!parent || !parent.children) return false;
                return parent.children.find(function (c) {
                    return c === tagId
                })
            });

            if (parent) return tags[parent];
        })
        .filter(function(d) { return !!d });

    parentCache.set(tagId, parents);
    return parents;
}

/**
 * Returns a list of topic tree IDs that are associated with the brands.
 * @returns {Promise<(null|number)[]>}
 */
export async function getTopicTreeIds() {
    const brands = getRootBrands();
    return [...(new Set(brands.filter(b => !b.parent && b.topicTreeId).map(b => b.topicTreeId)))];
}