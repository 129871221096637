/**
 * takes the user to the Email & Notifications panel
 */
import VuexStore from "@/store/vuex/VuexStore";

export function gotoNotifications() {
    const account = VuexStore.state.account;
    Beef?.router?.navigate(`/${account.code}/setup/notifications`, {trigger: true});
}

/**
 * Tests if the user is subscribed to the given function
 */
export function isSubscribed(notification) {
    const user = VuexStore.state.user;
    if (!notification.recipients || !notification.recipients.length) return false;
    const email = user.email.toLowerCase();
    const quoted = `<${email}>`;
    return notification.recipients.some(e => {
        e = e.toLowerCase();
        return e === email || e.includes(quoted);
    })
}