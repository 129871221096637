export const LINK_NAMES = {
    'twitter.com': 'Twitter',
    'facebook.com': 'Facebook',
    'youtube.com': 'YouTube',
    'instagram.com': 'Instagram',
    't.me': 'Telegram',
    'token_print_media': 'Print Media',
    'token_broadcast_media': 'Broadcast Media',
    'other': 'Other sites'
};


//Please always use the token_ prefix to name compound tokens
//this is used in {#link web-app/dashboards/filter/BeefModuleFilterToEnglish.js} module to prettify names
export const COMPOUND_LINKS = {
    'token_broadcast_media': ['video-cache.brandseye.com', 'media-player.brandseye.com'],
    'token_print_media': ['http://media-cache.brandseye.com', 'pressdisplay.com']
};


export const MEDIA_CATEGORIES = {
    CONSUMER: "Consumer",
    ENTERPRISE: "Enterprise",
    PRESS: "Press",
    DIRECTORY: "Directory",
    UNKNOWN: "Unknown"
};