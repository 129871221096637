/**
 * A module for the DataEQ Explore page.
 */
import {features} from "@/app/Features";
import _ from 'underscore';
import {logPageUsed} from "@/app/utils/UserAccessLog";

Beef.module("HistoricalSearchSection").addInitializer(function() {

    var sectionName = "rewind";
    var resources = ["", "jobs"];
    var defaultResource = resources[0];

    var View = Backbone.Marionette.Layout.extend({
        template: require("@/historical-search/HistoricalSearchSection.handlebars"),

        attributes: { class: "row-fluid historical-search-section" },

        regions: {
            jobList: "> .sidebar .job-list",
            content: "> .sidebar-right"
        },

        events: {
            "click .sidebar-actions .add-search-job": "addJob",
            "click .btn.overview": "defaultResourceClicked",
            "click #start-job-button": "startJob",
            "click #retrieve-data-button": "retrieveClick",
            "click #sample-data-button": "sampleClick",
            "click .edit-search-job": "editSearchJobClick",
            "click #edit-job-button": "editSearchJobClick",
            "click #delete-job-button": "deleteJobClick"
        },

        modelEvents: {
            "change": "updateContent"
        },

        onRender: function() {
            var jobListView = new Beef.SearchJobList.View({ model: new Backbone.Model({ accountCode: this.model.get("accountCode") }) });
            jobListView.on("click", this.jobClicked.bind(this));
            this.jobList.show(jobListView);
            logPageUsed("rewind-panel");
        },

        defaultResourceClicked: function(ev) {
            if (ev) {
                ev.preventDefault(); // stop the page from reloading
            }
            this.model.set({ resource: defaultResource });
        },

        jobClicked: function(job) {
            this.model.set({ resource: "jobs", job: job });
        },

        /**
         * Starts a volume search for the current job. Results in a NOP if no job is set on the current model,
         * or if the job has no search phrases.
         */
        startJob: function(ev) {
            var that = this;
            var job = this.model.get("job");
            var jobs = this.model.get("jobs") || [];
            var hasErrors = false;
            if (ev && $(ev.target).hasClass("disabled")) {
                console.log("Search will not start: it is disabled for this search");
            } else if (jobs.length < 1) {
                console.error("Cannot start a search from an empty job list.");
                hasErrors = true;
            } else if (!job) {
                console.error("Cannot start a search with job: " + job);
                hasErrors = true;
            } else if ((!job.searchPhrases || (job.searchPhrases.length < 1)) && (!job.customQuery || job.customQuery.trim() == '')) {
                console.error("Cannot start a search without search phrases or a custom GNIP query.");
                hasErrors = true;
            } else {
                var success = function(startedJob) {
                    if (startedJob) {
                        var index = findJob(startedJob.id, jobs);
                        if (index >= 0) {
                            jobs[index] = startedJob;
                        } else {
                            jobs.push(startedJob);
                        }

                        var listJobs = that.jobList.currentView.model.get("jobs");
                        index = findJob(startedJob.id, listJobs);
                        if (index >= 0) {
                            listJobs[index] = startedJob;
                        } else {
                            listJobs.push(startedJob);
                        }
                        that.jobList.currentView.update(listJobs); // must be done before the updateContent is triggered
                        that.model.set({ job: startedJob, jobs: jobs, resource: "jobs" });
                    } else {
                        console.error("No DTO received when trying to POST a search: " + JSON.stringify(job));
                        Beef.HistoricalSearch.Message.onUnknownError();
                    }
                };

                var error = function(xhr, status, error) {
                    console.error(status + " " + error);
                    Beef.HistoricalSearch.Message.onNetworkError();
                };

                Beef.SearchJobSync.start(this.model.get("accountCode"), job, success, error);
            }
            if (hasErrors) {
                Beef.HistoricalSearch.Message.onStartError();
            }
        },

        /**
         * Opens the retrieval setup popup if the retrieval button is enabled.
         */
        retrieveClick: function(ev) {
            if (ev && !$(ev.target).hasClass("disabled")) {
                this.openRetrievalSetupPopup();
            }
        },

        /**
         * Opens the retrieval setup popup.
         */
        openRetrievalSetupPopup: function() {
            var that = this;
            var chart = null;
            if (this.content && this.content.currentView && this.content.currentView.chartRegion &&
                this.content.currentView.chartRegion.currentView) {
                chart = this.content.currentView.chartRegion.currentView.model.get("chart");
            } else {
                console.error("Cannot open retrieval setup: no chart defined");
                Beef.HistoricalSearch.Message.onUnknownError();
                return;
            }
            var model = new Beef.HistoricalSearch.RetrievalSetupPopup.Model({
                chart: chart,
                accountCode: this.model.get("accountCode"),
                job: this.model.get("job")
            });
            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
            popup.setTarget(this.$el);
            var view = new Beef.HistoricalSearch.RetrievalSetupPopup.View({ model: model });
            view.on("retrievalStarted", function() {
                var createdJob = view.model.get("createdJob");
                if (createdJob) {
                    var jobs = that.model.get("jobs") || [];
                    jobs.push(createdJob);
                    sortJobs(jobs);
                    var listJobs = that.jobList.currentView.model.get("jobs");
                    var index = findJob(createdJob.id, listJobs);
                    if (index >= 0) {
                        listJobs[index] = createdJob;
                    } else {
                        listJobs.push(createdJob);
                    }
                    that.jobList.currentView.update(listJobs); // must be done before the updateContent is triggered
                    that.model.set({ job: createdJob, jobs: jobs, resource: "jobs" });
                } else {
                    var updatedJob = view.model.get("job");
                    if (that.content && that.content.currentView && that.content.currentView.startPoll) {
                        if (updatedJob) {
                            that.content.currentView.model.set({ job: updatedJob }, { silent: true });
                        }
                        that.content.currentView.startPoll();
                    }
                    if (updatedJob) {
                        that.model.set({ job: updatedJob }, { silent: true });
                    }
                }
            });
            popup.show(view);
        },

        /**
         * Opens the sample setup popup if the sample button is enabled.
         */
        sampleClick: function(ev) {
            if (ev && !$(ev.target).hasClass("disabled")) {
                this.openSampleSetupPopup();
            }
        },

        /**
         * Opens the sample setup popup.
         */
        openSampleSetupPopup: function() {
            var that = this;
            var chart = null;
            if (this.content && this.content.currentView && this.content.currentView.chartRegion &&
                this.content.currentView.chartRegion.currentView) {
                chart = this.content.currentView.chartRegion.currentView.model.get("chart");
            } else {
                console.error("Cannot open sample setup: no chart defined");
                Beef.HistoricalSearch.Message.onUnknownError();
                return;
            }
            var model = new Beef.HistoricalSearch.SampleSetupPopup.Model({
                chart: chart,
                accountCode: this.model.get("accountCode"),
                job: this.model.get("job")
            });
            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
            popup.setTarget(this.$el);
            var view = new Beef.HistoricalSearch.SampleSetupPopup.View({ model: model });
            view.on("sampleStarted", function() {
                var updatedJob = view.model.get("job");
                if (that.content && that.content.currentView && that.content.currentView.startPoll) {
                    if (updatedJob) {
                        that.content.currentView.model.set({ job: updatedJob }, { silent: true });
                    }
                    that.content.currentView.startPoll();
                }
                if (updatedJob) {
                    that.model.set({ job: updatedJob }, { silent: true });
                }
            });
            popup.show(view);
        },

        /**
         * Opens a menu for creating a search job and adds it to the job list once it is saved.
         */
        addJob: function(ev) {
            if (ev) ev.preventDefault();  // stop the page from reloading
            var that = this;
            var url = Beef.SearchJobSync.getSyncUrl(this.model.get("accountCode"));
            var model = new Beef.EditSearchJob.Model({});
            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
            popup.setTarget(this.$el);

            var success = function(createdJob) {
                var jobs = that.model.get("jobs") || [];
                jobs.push(createdJob);
                sortJobs(jobs);
                var listJobs = that.jobList.currentView.model.get("jobs");
                var index = findJob(createdJob.id, listJobs);
                if (index >= 0) {
                    listJobs[index] = createdJob;
                } else {
                    listJobs.push(createdJob);
                }
                that.jobList.currentView.update(listJobs); // must be done before the updateContent is triggered
                that.model.set({ job: createdJob, jobs: jobs, resource: "jobs" });
            };

            var error = function(xhr, status, error) {
                console.error(status + " " + error);
                Beef.HistoricalSearch.Message.onNetworkError();
            };

            var view = new Beef.EditSearchJob.View({ model: model, onClose: function(ok) {
                if (ok) {
                    var attrs = Object.assign({ searchFilter: model.get("mentionFilter") }, model.attributes);
                    var job = Beef.SearchJobSync.createDTO(attrs);
                    Beef.Sync.broccoliPOST(url, job, success, error);
                }
            }});

            view.on("close", function(){
                popup.hide();
            });

            popup.show(view);
        },

        /**
         * Opens a confirmation popup and only deletes the current search job from an account if the user confirms.
         */
        deleteJobClick: function() {
            var job = this.model.get("job");
            if (!job || !job.id) {
                console.error("Cannot delete search job: no search job or id");
                Beef.HistoricalSearch.Message.onUnknownError();
            } else {
                var onContinue = function() {
                    this.deleteJob(job.id);
                }.bind(this);
                Beef.HistoricalSearch.Message.showConfirm(onContinue, "delete", "search");
            }
        },

        /**
         * Finds and removes a job from the job list. Results in a NOP if the job is not found.
         */
        deleteJob: function(id) {
            var that = this;
            var jobs = this.model.get("jobs") || [];
            var index = findJob(id, jobs);

            var listJobs = that.jobList.currentView.model.get("jobs");
            var listIndex = findJob(id, listJobs);

            if (index >= 0) {
                var success = function(data) {
                    var jobs = that.model.get("jobs");
                    if (jobs && (jobs.length > 0)) {
                        jobs.splice(index, 1);
                    }
                    if (listIndex >= 0) {
                        listJobs.splice(listIndex, 1);
                        that.jobList.currentView.update(listJobs); // must be done before the updateContent is triggered
                    }
                    that.model.set({ job: null, jobs: jobs, resource: defaultResource });
                };
                var error = function(xhr, status, error) {
                    console.error(status + " " + error);
                    Beef.HistoricalSearch.Message.onNetworkError();
                };
                Beef.SearchJobSync.delete(this.model.get("accountCode"), jobs[index], success, error);
            } else {
                console.error("Could not find search job " + id);
                Beef.HistoricalSearch.Message.onUnknownError();
            }
        },

        /**
         * Opens a search job edit popup. If the job is already fetched phrase volume, a confirmation popup is shown
         * to confirm whether the user wants to continue, since editing the job will cause a reset.
         */
        editSearchJobClick: function(ev) {
            if (ev) ev.stopPropagation();
            var job = this.model.get("job");
            if (!job || !job.id) {
                console.error("No search job or id");
                Beef.HistoricalSearch.Message.onUnknownError();
            } else {
                if (job.searchCommenceDate) {
                    var onConfirm = function() {
                        this.editSearchJob();
                    }.bind(this);
                    Beef.HistoricalSearch.Message.showConfirm(onConfirm, "edit", "search");
                } else {
                    this.editSearchJob();
                }
            }
        },

        /**
         * Opens a popup menu for editing a search job. If the job was edited, the data on the job will be reset.
         */
        editSearchJob: function() {
            var job = this.model.get("job");
            if (!job || !job.id) {
                console.error("No search job or id");
                Beef.HistoricalSearch.Message.onUnknownError();
            } else {
                var that = this;
                var onDelete = this.deleteJobClick.bind(this);
                var model = new Beef.EditSearchJob.Model({ job: job });
                var view = new Beef.EditSearchJob.View({ model: model, onDelete: onDelete, onClose: function(ok) {
                    if (ok) {
                        var success = function(job) {
                            if (job) {
                                var jobs = that.model.get("jobs") || [];
                                var index = findJob(job.id, jobs);
                                if (index >= 0) {
                                    jobs[index] = job;
                                } else {
                                    jobs.push(job);
                                }

                                var listJobs = that.jobList.currentView.model.get("jobs");
                                index = findJob(job.id, listJobs);
                                if (index >= 0) {
                                    listJobs[index] = job;
                                    that.jobList.currentView.update(listJobs); // must be done before the updateContent is triggered
                                }

                                // since object references might be left unchanged, a manual trigger must be fired
                                that.model.set({ job: job, jobs: jobs, resource: "jobs" }, { silent: true });
                                that.model.trigger("change");
                            } else {
                                Beef.HistoricalSearch.Message.onUnknownError();
                            }
                        };

                        var error = function(xhr, status, error) {
                            console.error(status + " " + error);
                            // Show an error message if the filter was too long
                            if (xhr.status == 422) {
                                Beef.HistoricalSearch.Message.onFilterError();
                            } else {
                                Beef.HistoricalSearch.Message.onNetworkError();
                            }
                        };

                        var updatedJob = Beef.SearchJobSync.createDTO(model.attributes);
                        var keys = Object.keys(updatedJob);
                        if (keys && (keys.length > 0)) {
                            // The name field is the only field that does not require a data reset.
                            updatedJob.id = job.id;
                            if ((keys.length === 1) && updatedJob.hasOwnProperty("name")) {
                                Beef.SearchJobSync.update(that.model.get("accountCode"), updatedJob, success, error);
                            } else {
                                Beef.SearchJobSync.clearAndUpdate(that.model.get("accountCode"), updatedJob, success, error);
                            }
                        }
                    }
                }});

                var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
                popup.setTarget(this.$el);
                view.on("close", function(){
                    popup.hide();
                });
                popup.show(view);
            }
        },

        navClick: function(ev) {
            ev.preventDefault();
            this.navigate($(ev.target).attr("href"));
        },

        navigate: function(href) {
            if (href && href.indexOf("jobs") < 0) {
                var resource = href.split('/').pop();
                if (resources.indexOf(resource) < 0) {
                    Beef.router.navigate(href, { trigger: true });
                } else {
                    this.model.set("resource", resource);
                }
            }
        },

        /**
         * Finds and silently updates a job stored on the current model. The sidebar is also refreshed to reflect
         * changes.
         */
        updateJob: function(updatedJob) {
            if (updatedJob) {
                var jobs = this.model.get("jobs") || [];
                var index = findJob(updatedJob.id, jobs);
                if (index >= 0) {
                    jobs[index] = updatedJob;
                } else {
                    jobs.push(updatedJob);
                }
                if (this.jobList && this.jobList.currentView) {
                    var listJobs = this.jobList.currentView.model.get("jobs");
                    index = findJob(updatedJob.id, listJobs);
                    if (index >= 0) {
                        listJobs[index] = updatedJob;
                        this.jobList.currentView.update(listJobs); // must be done before the updateContent is triggered
                        var url = window.location.pathname;
                        this.updateSidebarSelection(url);
                    }
                }
                this.model.set({ jobs: jobs }, { silent: true });
                if (this.model.get("job").id == updatedJob.id) {
                    this.model.set({ job: updatedJob }, { silent: true });
                }
            }
        },

        /**
         * Add additional jobs to the current model. The sidebar is also refreshed to reflect
         * changes.
         */
        addSearchJobs: function(extraJobs) {
            extraJobs = extraJobs || [];
            var jobs = this.model.get("jobs") || [];
            jobs = jobs.concat(extraJobs);
            if (this.jobList && this.jobList.currentView) {
                var listJobs = this.jobList.currentView.model.get("jobs") || [];
                listJobs = listJobs.concat(extraJobs);
                this.jobList.currentView.update(listJobs); // must be done before the updateContent is triggered
            }
            this.model.set({ jobs: jobs }, { silent: true });
        },

        /**
         * Sets all items in the sidebar to inactive. The item whose url matches the given url is set to active.
         */
        updateSidebarSelection: function(url) {
            // give the search job list time to render before indicating selection
            setTimeout(function(){
                this.$el.find("> .sidebar a").closest("div").toggleClass("active", false);
                this.$el.find("> .sidebar a[href='" + url + "']").closest("div").toggleClass("active", true);
            }.bind(this));
        },

        /**
         * Sets a new view in response to a change made to the model. Results in a NOP if jobs have not yet been loaded.
         */
        updateContent: function() {
            var jobs = this.model.get("jobs");
            if (jobs === null) return;    // still loading search jobs. if undefined, then there are no jobs.

            // try to recover jobs or set some default jobs instead of showing an empty list
            if (!this.jobList.currentView.hasJobs()) {
                var ids = this.jobList.currentView.getStoredIds();
                var toAdd = [];
                // first try to recover jobs stored in local storage
                if (ids && (ids.length > 0)) {
                    for (var i = 0; i < ids.length; ++i) {
                        var index = findJob(ids[i], jobs);
                        if (index >= 0) {
                            toAdd.push(jobs[index]);
                        }
                    }
                }
                // if no jobs were recovered, try to show the last 3 completed jobs
                if (toAdd.length < 1) {
                    toAdd = jobs?.filter(function(job) {
                            return !!job["searchCommenceDate"] && !job["searchFailureDate"];
                        }) || [];
                    if (toAdd.length < 1) {
                        toAdd = jobs;
                        sortJobs(toAdd, "id");
                    } else {
                        sortJobs(toAdd, "searchCommenceDate");
                    }
                    toAdd = toAdd.slice(-3);
                }
                this.jobList.currentView.update(toAdd);
            }

            var accountCode = this.model.get("accountCode");
            var currentView = this.content.currentView;
            var id = null;
            var resource = this.model.get("resource");
            var view;
            // the order of the if-else statements must be kept
            if (resource === "jobs") {
                var job = this.model.get("job");
                if (job) {
                    id = job.id;
                    var searchJobModel = new Backbone.Model({ accountCode: accountCode, job: job, jobs: jobs });
                    view = new Beef.SearchJob.View({ model: searchJobModel });
                    view.on("pollComplete", this.updateJob.bind(this));
                    view.on("phraseChanged", this.updateJob.bind(this));
                    view.on("searchJobsCreated", this.addSearchJobs.bind(this));
                    // make sure a viewed job is always shown in the sidebar, e.g. when navigating from url
                    this.jobList.currentView.appendIfNotFound(job);
                } else {
                    view = new Beef.HistoricalSearchOverview.View({ model: new Backbone.Model({
                        accountCode: accountCode, jobListView: this.jobList.currentView
                    })});
                }
            } else if (currentView && (currentView.resource === resource)) {
                view = null;
            } else if (resource === defaultResource) {
                view = new Beef.HistoricalSearchOverview.View({ model: new Backbone.Model({
                        accountCode: accountCode, jobListView: this.jobList.currentView
                })});
            } else {
                view = Beef.view404;
            }

            if (currentView) currentView.resource = resource;

            if (view) {
                view.on("navigate", function(href) {
                    this.navigate(href);
                }.bind(this));
                var url = getLink(accountCode, resource, id);
                this.content.show(view);
                Beef.router.navigate(url, { replace: true });
                this.updateSidebarSelection(url);
            }
        }
    });

    this.View = View;

    /**
     * Creates a link to a resource in the historical search page.
     * @param code An account code. Always required.
     * @param resource The name of the resource.
     * @param id The id of the resource. A resource must be supplied if an id is supplied.
     * @return {String} A link to a resource.
     */
    function getLink(code, resource, id) {
        var link =  '/' + code + '/' + sectionName;
        if (resource) link += '/' + resource;
        if (id) link += '/' + id;
        return link;
    }

    /**
     * Finds a search job in an array of jobs.
     * @return {Number} The index of the search job. Returns -1 if the id is not found.
     */
    function findJob(id, jobs) {
        jobs = jobs|| [];
        var found = false;
        var index = -1;
        while (!found && (++index < jobs.length)) {
            found = (id === jobs[index].id);
        }
        return found ? index : -1;
    }

    /**
     * Sorts search jobs in ascending order by a given property.
     */
    function sortJobs(jobs, property) {
        jobs = jobs || [];
        property = property || "id";
        return _.sortBy(jobs, function(job) {
            return job[property];
        });
    }

    var routeCallback = function(code, resource, id) {
        if (id) id = Number(id);
        // given a resource, strip off any request parameters from the resource.
        if (resource) {
            var i = resource.indexOf('?');
            if (i >= 0) {
                resource = resource.substring(0, i);  // this might "unset" the resource
            }
        }

        if (!resource) {
            resource = defaultResource;
        }

        if (!features.historicalSearch() || !Beef.isAccountCode(code) || (resources.indexOf(resource) < 0)) {
            Beef.content.show(Beef.view404);
        } else {
            var model = new Backbone.Model({ accountCode: code, resource: resource});
            var view = new View({ model: model, accountCode: code });
            Beef.content.show(view);

            var success = function(jobs) {
                var selectedJob = null;
                if (jobs && jobs.length > 0) {
                    jobs = sortJobs(jobs);
                    var index = findJob(id, jobs);
                    selectedJob = (index >= 0) ? jobs[index] : jobs[0];
                } else {
                    jobs = [];
                }
                model.set({ job: selectedJob, jobs: jobs }, { silent: true });
                view.updateContent();
                view.$el.find("> .sidebar .add-search-job").css("visibility", "visible");
            };

            var error = function(xhr, status, error) {
                console.error(status + " " + error);
                Beef.HistoricalSearch.Message.onNetworkError();
            };
            Beef.SearchJobSync.list(code, success, error);
        }
    };
    Beef.route(":code/" + sectionName, sectionName, routeCallback);
    Beef.route(":code/" + sectionName + "/:resource", sectionName, routeCallback);
    Beef.route(":code/" + sectionName + "/:resource/:id", sectionName, routeCallback);
});
