import {showNewProfilePicker, showWhenDialog} from "@/app/framework/dialogs/Dialog";
import VuexStore from "@/store/vuex/VuexStore";
import {notifyWithHtml} from "@/app/framework/notifications/Notifications";
import {encloseInDisplayQuotes, escapeExpression} from "@/app/utils/StringUtils";
import _ from 'underscore';
import {features} from "@/app/Features";

Beef.module("EditBrand").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
        validation: {
            name: function(field) {
                if (!field || field.trim().length == 0) { return "Name is required" }
            }
        },

        toJSON: function() {
            var ans = _.clone(this.attributes)
            let a = ans.supportProfileIds
            ans.supportProfileIds = a ? a.split(' ').map(s => parseInt(s)) : [];
            let b = ans.otherProfileIds
            ans.otherProfileIds = b ? b.split(' ').map(s => parseInt(s)) : [];
            return ans
        }
    });

    var CATEGORY_ITEMS = {
        OWN: { name: "Own Brand", description: "One of your own brands" },
        COMPETITOR: { name: "Competitor", description: "A competitor brand" }
    };
    this.CATEGORY_ITEMS = CATEGORY_ITEMS

    var categoryPicker = Beef.DynamicPicker.create(CATEGORY_ITEMS, { placeholder: "Optional category", alwaysOne: false });
    this.categoryPicker = categoryPicker;

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/setup/brands/EditBrand.handlebars"),

        attributes: { class: "edit-brand dialog" },

        regions: {
            rootBrandFilterRegion: ".root-brand-filter-region"
        },

        bindings: {
            socialNetworks: { converter: Beef.SocialNetworkPicker.converter, elAttribute: "data-value" },
            feeds: { converter: Beef.FeedPicker.converter, elAttribute: "data-value" },
            category: { converter: categoryPicker.converter, elAttribute: "data-value" },
            supportProfileIds: { converterFactory: Beef.ProfilePicker.createConverterFactory("Support Profiles"), elAttribute: "data-value" },
            otherProfileIds: { converterFactory: Beef.ProfilePicker.createConverterFactory("Non-Support Profiles"), elAttribute: "data-value" },
        },

        createBindings: function() {
            // don't do bindings for our whole element or the subform for root brand filtering gets picked up
            // as well and the checkboxes don't work as they have bindings to both models
            return Beef.ModelBinder.createDefaultBindings(this.$el.find('.main-form'));
        },

        events:Object.assign({
            "click .dialog-button-bar .move": "move",
            "click .dialog-button-bar .archive": "archive",
            "click .supportProfileIds": "showSupportProfilePicker",
            "click .otherProfileIds": "showOtherProfilePicker",
        }, Beef.SettingsDialog.View.prototype.events),

        templateHelpers: {
            level0: function() { return this._level == 0 },
            level1: function() { return this._level == 1 },
            level2: function() { return this._level == 2 },
            levelOther: function() { return this._level >= 3 }
        },


        onRender: function() {
            Beef.SettingsDialog.View.prototype.onRender.apply(this, arguments);

            if (this.model.get('_level') == 0 && !this.rootBrandFilterRegion.currentView) {
                var v = Beef.RootBrandFilter.createView(this.model.get('mentionFilter'), false);
                var that = this;
                v.model.on("change", function(){ that.model.set('mentionFilter', v.getFilter()); });
                this.rootBrandFilterRegion.show(v);

                Beef.SocialNetworkPicker.attach(this, ".socialNetworks", "socialNetworks");
                Beef.FeedPicker.attach(this, ".feeds", "feeds");
            }
        },

        onFirstRender: function() {
            categoryPicker.attach(this, ".category", "category");
            if (!features.newProfilePicker()) {
                Beef.ProfilePicker.attach(this, ".supportProfileIds", "supportProfileIds");
                Beef.ProfilePicker.attach(this, ".otherProfileIds", "otherProfileIds");
            }
        },

        delete: function() {
            showWhenDialog("Are you sure you want to delete the brand " + encloseInDisplayQuotes(this.model.get('name')) +
                ", its phrases, all of its sub-brands and all of their phrases?",
                "Delete brand?",
                )
                .then(function() {
                    this.originalModel.destroy();
                    this.close(true);
                    this.originalModel.on("sync", () => {
                        this.model.set('deleted', true);
                        VuexStore.dispatch("refreshBrands", true);
                        notifyWithHtml(
                            `Brand <strong>${escapeExpression(this.model.get('name'))}</strong> has been deleted`,
                            null,
                            "<i class='symbol-setup'></i>"
                        )
                    });
                }.bind(this))
        },

        move: function() {
            if (!this.model.get("_parent")) return;
            var view = new Beef.MoveBrand.View({model: this.originalModel, accountCode: this.options.accountCode,
                cache: this.cache, rootBrands: this.options.rootBrands});
            this.close();

            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.parentPopup.target);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        archive: function() {
            if (this.model.get("_parent") || this.model.get("archived")) return;
            showWhenDialog("Are you sure you want to archive the brand, " + encloseInDisplayQuotes(this.model.get('name')) +
                "? It will not collect mentions while archived.",
                "Archive brand?",)
                .then(function() {
                    this.model.set('archived', true);
                    this.originalModel.on("sync", () => VuexStore.dispatch("refreshBrands", true));
                    this.ok();
                }.bind(this))
        },

        onSaveError: function(model, xhr, options) {
            this.originalModel.trigger("saveError");
            Beef.SettingsDialog.View.prototype.onSaveError.apply(this, arguments);
            this.model.unset('archived');
        },

        showSupportProfilePicker(){
            if (!features.newProfilePicker()) return;
            showNewProfilePicker((values) => this.model.set('supportProfileIds', values.join(" ")),
                this.model.get('supportProfileIds')?.split(" ")
                    .filter(it => !Number.isNaN(parseInt(it)))
                    .map(s => parseInt(s)), false);
        },

        showOtherProfilePicker(){
            if (!features.newProfilePicker()) return;

            showNewProfilePicker((values) => this.model.set('otherProfileIds', values.join(" ")),
                this.model.get('otherProfileIds')?.split(" ")
                    .filter(it => !Number.isNaN(parseInt(it)))
                    .map(s => parseInt(s)), false);
        }
    });

});