import Vue from "vue";
import Vuex from "vuex";
import {mash} from "@/store/Services";
import {Dashboard} from "@/app/utils/types";

Vue.use(Vuex);

let refreshPromise = null;

export default {
    namespaced: true,

    state: {
        dashboards: null,
        refreshing: false,          // Whether we are refreshing the dashboard list. Dashboards may already have been loaded.
        tok: null,
        asEmail: null
    },

    getters: {
        // Whether we are loading dashboards for the very first time.
        loading: state => state.dashboards === null && state.refreshing,

        // Returns the landing page dashboards.
        landingPages: state => state.dashboards?.filter(d => d.type === "LANDING_PAGE"),

        // Useful for getting short descriptions of dashboards from the dashboard list,
        // or for testing if a dashboard with a given ID exists.
        idToDashboard: state => {
            if (!state.dashboards) return new Map();

            const map = new Map();
            for (const dashboard of state.dashboards) {
                map.set(dashboard.id, dashboard);
            }
            return map
        },

        cxDashboard: state => {
            return state?.dashboards?.find(dash => dash.type === "LANDING_PAGE" && dash.name?.toLowerCase()?.trim() === "customer experience")
        },

        riskDashboard: state => {
            return state?.dashboards?.find(dash => dash.type === "LANDING_PAGE" && dash.name?.toLowerCase()?.trim() === "risk")
        }
    },

    mutations: {
        setDashboard: (state, {value, dashboardId}) => {
            let dashboard = state.dashboards.find(d => d.id === dashboardId);
            if (dashboard) {
                if (!value.category) value.category = null;
                Object.assign(dashboard, value);
            }
        },
        setDashboards: (state, value) => {
            const parent = new Dashboard();
            value.forEach(o => o.__proto__ = parent);
            state.dashboards = value;
        },
        setRefreshing: (state, value) => state.refreshing = value,
        setTok: (state, value) => state.tok = value,
        setAsEmail: (state, value) => state.asEmail = value,
        setDashboardCategory: (state, {oldValue, newValue, dashboardIds}) => {
            if (dashboardIds?.length) {
                let idSet = new Set(dashboardIds);
                state.dashboards?.filter(dashboard => idSet.has(dashboard.id))?.forEach(dashboard => {
                    if (dashboard.category === oldValue) dashboard.category = newValue;
                });
            } else {
                state.dashboards?.forEach(dashboard => {
                    if (dashboard.category === oldValue) dashboard.category = newValue;
                });
            }
        },
        setDashboardCategoryPinnedState: (state, {category, pinned, dashboardIds}) => {
            if (dashboardIds?.length) {
                let idSet = new Set(dashboardIds);
                state.dashboards?.filter(dashboard => idSet.has(dashboard.id))?.forEach(dashboard => {
                    if (dashboard.category === category) dashboard.pinned = pinned;
                });
            } else {
                state.dashboards?.forEach(dashboard => {
                    if (dashboard.category === category) dashboard.pinned = pinned;
                });
            }
        },
        setDashboardCategoryPermissions: (state, {category, privacy, readTeamIds, writeTeamIds, dashboardIds}) => {
            if (dashboardIds?.length) {
                let idSet = new Set(dashboardIds);
                state.dashboards?.filter(dashboard => idSet.has(dashboard.id))?.forEach(dashboard => {
                    if (dashboard.category === category) {
                        dashboard.privacy = privacy;
                        dashboard.readTeamIds = readTeamIds;
                        dashboard.writeTeamIds = writeTeamIds;
                    }
                });
            } else {
                state.dashboards?.forEach(dashboard => {
                    if (dashboard.category === category) {
                        dashboard.privacy = privacy;
                        dashboard.readTeamIds = readTeamIds;
                        dashboard.writeTeamIds = writeTeamIds;
                    }
                });
            }
        },
        updateDashboardSettings: (state, {dashboardId, value}) => {
            let dashboard = state.dashboards?.find(d => d.id === dashboardId);
            if (dashboard) {
                dashboard.category = value.category;
                if (value.purchased !== undefined) dashboard.purchased = value.purchased ??= false;
                dashboard.privacy = value.privacy;
                dashboard.readTeamIds = value.readTeamIds ??=[];
                dashboard.writeTeamIds = value.writeTeamIds ??=[];
                dashboard.description = value.description;
            }
        }
    },

    actions: {
        /**
         * @param {boolean} [forceRefresh = false]
         * @return {Promise<null>}
         */
        async refreshDashboards({state, commit, rootState}, forceRefresh) {
            forceRefresh ??= false;

            if (refreshPromise) return refreshPromise;
            if (state.dashboards && !forceRefresh) return;
            try {
                commit('setRefreshing', true);
                refreshPromise = mash.get("/rest/accounts/" + rootState.account.code + "/reports");
                const res = await refreshPromise;
                res.data?.forEach(d => {
                    // the "category" and "pinned" property must be present on dashboard objects in order for
                    // some computed properties for non-category dashboards to be updated correctly
                    if (d.type === "NORMAL") {
                        if (!d.category) d.category = null;
                        if (!d.pinned) d.pinned = false;
                    }
                });
                await commit('setDashboards', res.data);
            } catch(e) {
                console.error(e);
            } finally {
                refreshPromise = null;
                commit('setRefreshing', false);
            }
        },

        /**
         * Get only returns partial information for a dashboard. This returns the full dashboard information.
         */
        async getFullDashboard({rootState}, dashboardId) {
            return mash
                .get("/rest/accounts/" + rootState.account.code + "/reports/" + dashboardId)
                .then(res => res.data);
        },

        async updateDashboard({rootState, commit}, dashboard) {
            return mash
                .put("/rest/accounts/" + rootState.account.code + "/reports/" + dashboard.id, dashboard)
                .then(res => {
                    Object.assign(dashboard, res.data);
                    commit('setDashboard', {value: res.data, dashboardId: dashboard.id});
                });
        },

        async deleteDashboard({state, commit, rootState}, dashboardId) {
            await mash.delete("/rest/accounts/" + rootState.account.code + "/reports/" + dashboardId);
            const updated = state.dashboards?.filter(d => d.id !== dashboardId);
            await commit('setDashboards', updated);
        },

        async createDashboard({state, commit, rootState}, dashboard) {
            const res = await mash.post("/rest/accounts/" + rootState.account.code + "/reports", dashboard);
            const result = res.data;

            await commit('setDashboards', [...(state.dashboards ?? []), result]);
            return Object.assign(dashboard, result);
        },

        /**
         * Updates the category name of all dashboards within the given category
         *
         * @param oldCategory - current name of the category
         * @param newCategory - new name of the category
         * @param dashboardIds - optional array of dashboard IDs. If given, only dashboards within the given list of ID's will be updated.
         */
        async updateDashboardCategory({state, commit, rootState}, {oldCategory, newCategory, dashboardIds}) {
            let payload = {
                folderName: newCategory
            }
            if (dashboardIds) payload.dashboardIds = dashboardIds;

            await mash.put(`/rest/accounts/${rootState.account.code}/reports/folder?folderName=${encodeURIComponent(oldCategory)}`, payload);
            await commit('setDashboardCategory', {oldValue: oldCategory, newValue: newCategory, dashboardIds: dashboardIds});
        },

        /**
         * Deletes all dashboards within the given category.
         *
         * @param category - name of the category
         * @param dashboardIds - array of dashboard IDs. Only dashboards within the given list of ID's will be updated.
         */
        async deleteDashboardCategory({state, commit, rootState}, {category, dashboardIds}) {
            let url = `/rest/accounts/${rootState.account.code}/reports/folder?folderName=${encodeURIComponent(category)}`;
            if (dashboardIds) {
                url += `&dashboardIds=${dashboardIds}`
            }

            await mash.delete(url);

            const updated = state.dashboards?.filter(d => {
                return dashboardIds.indexOf(d.id) === -1;
            });

            await commit('setDashboards', updated);
        },

        /**
         * Undeletes/restores all dashboards within the given category.
         *
         * @param category - name of the category.
         * @param dashboardIds - array of dashboard IDs. only dashboards within the given list of ID's will be restored.
         */
        async restoreDashboardCategory({state, commit, rootState}, {category, dashboardIds}) {
            let url = `/rest/accounts/${rootState.account.code}/reports/folder/restore?folderName=${encodeURIComponent(category)}`;
            let payload = {}

            if (dashboardIds) payload.dashboardIds = dashboardIds;

            await mash.put(url, payload);
        },

        /**
         * Deletes all dashboards within the given list of Ids.
         *
         * @param dashboardIds - array of dashboard IDs. Only dashboards within the given list of ID's will be updated.
         */
        async multiDeleteDashboards({state, commit, rootState}, dashboardIds) {
            let url = `/rest/accounts/${rootState.account.code}/reports/multi-delete?dashboardIds=${dashboardIds}`;

            await mash.delete(url);

            const updated = state.dashboards?.filter(d => {
                return dashboardIds.indexOf(d.id) === -1;
            });

            await commit('setDashboards', updated);
        },

        /**
         * Undeletes/restores all dashboards within the given list of Ids.
         *
         * @param dashboardIds - array of dashboard IDs. only dashboards within the given list of ID's will be restored.
         */
        async restoreDeletedDashboards({state, commit, rootState}, dashboardIds) {
            let url = `/rest/accounts/${rootState.account.code}/reports/restore-deleted`;
            let payload = { dashboardIds: dashboardIds }

            await mash.put(url, payload);
        },

        /**
         * Updates the category permissions of all dashboards within the given category.
         *
         * @param category - name of the category
         * @param privacy - privacy that all dashboards within the category should have
         * @param readTeamIds - list of team ID's with read access. Should be empty if privacy is MASH_ADMIN or OWNER.
         * @param writeTeamIds - list of team ID's with write access. Should be empty if privacy is MASH_ADMIN, MASH_ADMIN_EDIT, OWNER, or OWNER_EDIT.
         * @param dashboardIds - optional array of dashboard IDs. If given, only dashboards within the given list of ID's will be updated.
         */
        async updateDashboardCategoryPermissions({state, commit, rootState}, {category, privacy, readTeamIds, writeTeamIds, dashboardIds}) {
            let payload = {
                privacy: privacy,
                readTeamIds: readTeamIds,
                writeTeamIds: writeTeamIds
            }
            if (dashboardIds) payload.dashboardIds = dashboardIds;

            await mash.put(`/rest/accounts/${rootState.account.code}/reports/folder?folderName=${encodeURIComponent(category)}`, payload);
            await commit('setDashboardCategoryPermissions', {category: category, privacy: privacy, readTeamIds: readTeamIds,
                writeTeamIds: writeTeamIds, dashboardIds: dashboardIds});
        },

        /**
         * Updates the pinned state of all dashboards within the given category.
         *
         * @param category - name of the category
         * @param pinned - true if category should be pinned, false otherwise
         * @param dashboardIds - optional array of dashboard IDs. If given, only dashboards within the given list of ID's will be updated.
         */
        async updateDashboardCategoryPinnedState({commit, rootState}, {category, pinned, dashboardIds}) {
            let payload = {
                pinned: pinned
            }
            if (dashboardIds) payload.dashboardIds = dashboardIds;

            await mash.put(`/rest/accounts/${rootState.account.code}/reports/folder?folderName=${encodeURIComponent(category)}`, payload);
            await commit('setDashboardCategoryPinnedState', {category: category, pinned: pinned, dashboardIds: dashboardIds});
        }
    }


};