/**
 * Displays a dialog giving interaction options for a Mention and its associated conversation.
 */
import {deprecatedFetchUsers} from "@/data/DeprecatedBeefCache";
import _ from 'underscore';
import {isString, removeSingleQuotes, splitQuotedString} from "@/app/utils/StringUtils";

Beef.module("InteractDialog").addInitializer(function(startupOptions) {

    var View = Beef.BoundItemView.extend({
        template: require("@/conversation/InteractDialog.handlebars"),
        attributes: { class: "interact-dialog dialog" },

        events: {
            "click .cancel": "cancelClicked",
            "click .send": "sendClicked",
            "keyup .message": "userTyped",
            "keyup .message-subject": "userTypedSubject",
            "mousedown": "mousedown"
        },

        modelEvents: {
            "change:count": "countChanged",
            "change:profile": "profileChanged",
            "change:emails": "emailsChanged"
        },

        emailPickerPlaceholder: "Enter email addresses here",

        templateHelpers: function() {
            var mention = this.model.get('mention');
            var mentionIds = this.model.get('mentionIds');
            var numMentions = mentionIds ? mentionIds.length : this.model.get('filterTotal');

            var buttonText = "Send";
            if (this.isReshare()) buttonText = "Reshare";
            if (this.isReply()) buttonText = "Reply";

            var buttonTooltip = "Send this mention";
            if (this.isReshare()) buttonTooltip = "Reshare this mention";
            if (this.isReply()) buttonTooltip = "Reply to this mention";
            if (this.isEmail()) buttonTooltip = "Send this email";

            return {
                buttonText: buttonText,
                buttonTooltip: buttonTooltip,
                userEmail: this.getUserEmailAddress(),
                mentionWord: numMentions > 1 ? "mentions" : "mention",
                moreThan50: numMentions > 50,
                numMentions: numMentions
            }
        },

        bindings: function() {
            return {
                profile: { converterFactory: Beef.ProfilePicker.createConverterFactory("Click here to choose an authorized profile"), elAttribute: "data-value" },
                emails: { converterFactory: Beef.EmailPicker.createConverterFactory("Enter email addresses here"), elAttribute: "data-value" }
            }
        },

        //------------------------------

        getMessage: function() {
            return this.model.get('message');
        },

        setMessage: function(text) {
            var shortened = text.trim();
            var length = 0;
            var httpPattern = /(^|\s)http(s?):\/\/[A-Za-z0-9-\.–?&\+%="'/_#]+/gi;

            var http = shortened.match(httpPattern);
            if (http) {
                for (var i = 0; i < http.length; i++) {
                    http[i] = http[i].trim();

                    if (http[i][http[i].length - 1] == '.') {
                        http[i] = http[i].substr(0, http[i].length - 1);
                    }

                    shortened = shortened.replace(http[i], '');
                    if (http[i].indexOf('http:') == 0) {
                        // Twitter shortened URL length for http.
                        length += 22;
                    }
                    if (http[i].indexOf('https:') == 0) {
                        // Twitter shortened URL length for https.
                        length += 23;
                    }
                }
            }

            length += shortened.length;

            var id = this.getStoredMessageId();

            if (this.afterSetup) {
                Beef.generalData(this.model.accountCode).set(id, {
                    text: text,
                    profile: this.model.get('profile')
                });
            }

            this.model.set({
                message: text,
                count: length
            });
            this.$('.message').val(text);
        },

        getSubject: function() {
            return this.$('.message-subject').val();
        },

        //------------------------------

        userTyped: function() {
            this.setMessage(this.$('.message').val());
            this.renderCounterArea();
        },

        userTypedSubject: function() {
            Beef.generalData(this.model.accountCode).set(this.getNamespacedString('last-email-subject'), this.$('.message-subject').val());
        },

        //------------------------------

        isCountFine: function(count) {
            if (!arguments.length) count = this.model.get('count');
            if (this.isTwitter()) {
                return this.isRetweetExact() || count <= 140;
            }

            return true;
        },

        countChanged: function() {
            var count = this.model.get('count');
            var $count = this.$('.count');

            $count.html(count);
            $count.toggleClass('over-limit', !this.isCountFine(count));
            this.updateSendState();

            var title = (count <= 140) ? ("Using " + count + " characters of a maximum of 140") : "You are using more than 140 characters";
            this.$('.counter').prop('title', title);

        },

        profileChanged: function() {
            this.updateSendState();
            this.renderCounterArea();

            var profile = this.model.get('profile');
            var mention = this.model.get('mention') || this.model.get('reshare');
            if (profile) {
                if (mention) {
                    if (Beef.Conversation.isTwitter(mention)) {
                        Beef.generalData(this.model.accountCode).set(this.getNamespacedString('last-twitter-profile'), profile);
                    }

                    if (Beef.Conversation.isFacebook(mention)) {
                        Beef.generalData(this.model.accountCode).set(this.getNamespacedString('last-facebook-profile'), profile);
                    }
                }
                else {
                    Beef.generalData(this.model.accountCode).set(this.getNamespacedString('last-post-profile'), profile);
                }

                if (this.afterSetup) {
                    var settings = Beef.generalData(this.model.accountCode).get(this.getStoredMessageId());
                    if (settings) {
                        settings.profile = profile;
                        Beef.generalData(this.model.accountCode).set(this.getStoredMessageId(), settings);
                    }
                }
            }

            this.renderNetworkLogo();
        },

        emailsChanged: function() {
            this.updateSendState();
            var emailPromise = this.getEmailAddresses();

            var emails = this.model.get('emails');
            Beef.generalData(this.model.accountCode).set(this.getNamespacedString('last-emails'), emails);

            $.when(emailPromise)
                .then(function(emails, nonUser) {
                    this.$('.email-format-warning').css({display: !_(nonUser).every(function(d) { return Beef.EmailPicker.validate(d); }) ? 'block' : 'none'});
                }.bind(this));
        },

        //------------------------------

        updateSendState: function() {
            this.$('.send').toggleClass('disabled', this.isSendDisabled());
        },

        isSendDisabled: function() {
            var count = this.model.get('count');
            var profile = this.model.get('profile');
            var emails = this.model.get('emails');

            return this.currentlySending ||
                (!this.isEmail() && !profile) ||
                (!this.isEmail() && count == 0) ||
                (!this.isCountFine()) ||
                (this.isEmail() && !emails);
        },

        //------------------------------

        getStoredMessageId: function() {
            var id = this.model.get('reshare') ? 'reshare' : "message";
            var mention = this.model.get('mention') || this.model.get('reshare');
            var namespace = this.model.get('namespace');

            if (mention) id = id + ":" + mention.id;
            if (namespace) id = id + ":" + namespace;

            return id;
        },

        getNamespacedString: function(string) {
            var namespace = this.model.get('namespace');
            if (namespace) return namespace + ":" + string;
            return string;
        },

        //------------------------------

        /**
         * Returns true if this is a reshare on twitter whose text is identical to what we determine the reshare text to
         * be (meaning that no one has edited it). We cannot use the exact retweet functionality to reshare the author's
         * own tweets, so that needs to be taken in to account as well.
         */
        isRetweetExact: function() {
            return this.isReshare() && this.isTwitter() &&
                this.getProfileInfo().handleId != this.model.get('reshare').get('author').twitterUserId &&
                this.getMessage().toLowerCase() == this.determineReshareText().toLowerCase();
        },

        /**
         * Renders the counter area.
         */
        renderCounterArea: function() {
            var $counter = this.$('.counter');
            var $exactMessage = this.$('.exact-retweet');

            if (this.isTwitter()) {
                if (!$counter.is(':visible')) {
                    $counter.css({display: 'inherit'});
                    $counter.toggleClass("animated pulse", true);
                }
            }
            else {
                $counter.css({display: 'none'});
                $counter.toggleClass("animated pulse", false);
                $exactMessage.css({display: 'none'});
                return; // We should not change the visibility state of the exactMessage
            }


            if (this.isRetweetExact()) {
                $counter.css({display: 'none'});
                $exactMessage.css({display: 'inherit'});
            }
            else {
                $counter.css({display: 'inherit'});
                $exactMessage.css({display: 'none'});
                this.countChanged();
            }
        },

        determineReshareText: function() {
            var reshare = this.model.get('reshare');
            if (!reshare) throw new Error("Not a reshare");

            if (Beef.Conversation.isTwitter(reshare)) {
                return "RT @" + reshare.get('author').twitterScreenName + " " + reshare.get('title');
            }

            return reshare.get('author').name + " posted: " + reshare.get('extract')
        },

        //------------------------------

        onRender: function() {
            if (!this.firstRenderDone) {
                this.firstRenderDone = true;

                var mention = this.model.get('mention');
                var reshare = this.model.get('reshare');
                var message = null;

                var inProgress = Beef.generalData(this.model.accountCode).get(this.getStoredMessageId());
                if (isString(inProgress)) inProgress = {text: inProgress};

                if (inProgress) {
                    message = inProgress.text;
                }

                if (mention) {
                    if (Beef.Conversation.isTwitter(mention)) {
                        var author = Beef.Conversation.getAuthor(this.model.get('mention'));

                        if (author && !message) {
                            message = author + ' ';

                            var atNames = _(Beef.Conversation.getAtNames(mention)).filter(function(n) { return n != author; });
                            if (atNames.length) {
                                message = message + atNames.join(' ') + ' ';
                            }
                        }
                    }
                }

                if (reshare) {
                    message = this.determineReshareText();
                }

                if (message) {
                    this.setMessage(message);
                }

                var mentionToConsider = mention || reshare;
                var isTwitter = mentionToConsider && Beef.Conversation.isTwitter(mentionToConsider);
                var isFacebook = mentionToConsider && Beef.Conversation.isFacebook(mentionToConsider);
                var title = "Interact using one of these " + (isTwitter ? 'Twitter' : 'facebook') + " profiles";

                var profiles = Beef.user.getNonExpiredAuthorizedProfiles();

                if (isTwitter || isFacebook) {
                    profiles = _(profiles).filter(function(p) {
                        return (isTwitter && this.isTwitter(p)) || (isFacebook && this.isFacebook(p));
                    }.bind(this));
                }


                var lastProfile = inProgress ? inProgress.profile : null;
                if (!lastProfile) {
                    if (isTwitter) {
                        lastProfile = Beef.generalData(this.model.accountCode).get(this.getNamespacedString('last-twitter-profile'));
                    }
                    else if (isFacebook) {
                        lastProfile = Beef.generalData(this.model.accountCode).get(this.getNamespacedString('last-facebook-profile'));
                    }
                    else {
                        lastProfile = Beef.generalData(this.model.accountCode).get(this.getNamespacedString('last-post-profile'));
                    }
                }
                else {
                    if (profiles.length == 1) {
                        this.model.set('profile', profiles[0].id.toString());
                    }
                }

                // This is for setting the last profile for new posts.
                if (lastProfile && !this.model.get('email')) {
                    this.model.set('profile', lastProfile);
                }

                if (this.isEmail()) {
                    this.model.set('emails', Beef.generalData(this.model.accountCode).get(this.getNamespacedString('last-emails')));
                    var lastSubject = Beef.generalData(this.model.accountCode).get(this.getNamespacedString('last-email-subject'));
                    if (lastSubject) {
                        this.$('.message-subject').val(lastSubject);
                    }
                }

                Beef.EmailPicker.attach(this, ".emails", "emails", {placeholder: this.emailPickerPlaceholder});
                Beef.ProfilePicker.attach(this, ".profile", "profile",
                    {
                        onlyOne: true,
                        profiles: profiles,
                        title: title
                    }
                );



                this.updateSendState();
                this.renderNetworkLogo();
                this.countChanged();
                this.renderCounterArea();

                this.afterSetup = true;
            }
        },

        renderNetworkLogo: function() {
            var $icon = this.$('.network-icon');
            var facebookClass = 'icon-facebook-6';
            var twitterClass = 'symbol-twitter';
            var emailClass = 'icon-mail';

            if (this.isTwitter()) {
                $icon.toggleClass(facebookClass, false);
                $icon.toggleClass(emailClass, false);
                $icon.toggleClass(twitterClass, true);
                $icon.prop('title', "This conversation is on Twitter");
            }
            else if (this.isFacebook()) {
                $icon.toggleClass(facebookClass, true);
                $icon.toggleClass(twitterClass, false);
                $icon.toggleClass(emailClass, false);
                $icon.prop('title', "This conversation is on Facebook");
            }
            else if (this.isEmail()) {
                $icon.toggleClass(facebookClass, false);
                $icon.toggleClass(twitterClass, false);
                $icon.toggleClass(emailClass, true);
                $icon.prop('title', "This is an email");
            }
            else {
                $icon.toggleClass(facebookClass, false);
                $icon.toggleClass(twitterClass, false);
                $icon.toggleClass(emailClass, false);
                $icon.prop('title', "No network");
            }
        },

        //------------------------------
        // Event handles

        sendClicked: function(ev) {
            ev.preventDefault();
            if(this.isSendDisabled()) {
                return;
            }

            this.currentlySending = true;
            var profile = parseInt(this.model.get('profile'));
            var profiles = Beef.user.getNonExpiredAuthorizedProfiles();
            var mention = this.model.get('mention');
            var promise = null;

            var $send = this.$('.send'),
                $sendLabel = this.$('.send-label'),
                $sendingLabel = this.$('.sending-label');

            var oldTitle = $send.prop('title');
            $send.prop('title', 'Sending your message');

            $send.toggleClass('send-active', true);
            $sendLabel.css({display: 'none'});
            $sendingLabel.css({display: 'inherit'});

            var onComplete = function() {
                $.when(promise)
                    .then(function() {
                        Beef.generalData(this.model.accountCode).unset(this.getStoredMessageId());
                        Beef.generalData(this.model.accountCode).unset(this.getNamespacedString('last-email-subject'));
                        this.close();
                    }.bind(this))
                    .fail(function() {
                        if (this.isFacebook()) {
                            alert("There was an error sending the message. Please ensure that this profile has permission to post replies on this message, then reload and try again.");
                        }
                        else {
                            alert("There was an error sending the message. Please reload and try again.");
                        }
                    }.bind(this))
                    .always(function() {
                        this.currentlySending = false;
                        $send.toggleClass('send-active', false);
                        $send.prop('title', oldTitle);
                        $sendLabel.css({display: 'inherit'});
                        $sendingLabel.css({display: 'none'});
                    }.bind(this));
            }.bind(this);

            if (mention) {
                promise = Beef.Conversation.replyTo(mention, this.getMessage(), _(profiles).find(function(p) { return p.id == profile; }));
                onComplete();
            }
            else if (this.isEmail()) {
                var emailPromise = this.getEmailAddresses();
                $.when(emailPromise)
                    .then(function(emails) {
                        promise = Beef.Conversation.postEmail({
                                toList: emails,
                                ccList: [Beef.user.get('email')],
                                subject: this.getSubject(),
                                message: this.getMessage(),
                                mentions: this.model.get('mentionIds'),
                                footer: this.model.get('footer'),
                                filter: this.model.get('filter')
                            });
                        onComplete();
                    }.bind(this));
            }
            else if (this.isReshare() && this.isFacebook()) {
                promise = Beef.Conversation.post({
                    profile: _(profiles).find(function(p) { return p.id == profile; }),
                    link: this.model.get('reshare').get('link'),
                    text: this.getMessage()
                });
                onComplete();
            }
            else if (this.isReshare() && this.isRetweetExact()) {
                promise = Beef.Conversation.reshare(this.model.get('reshare'), _(profiles).find(function(p) { return p.id == profile; }));
                onComplete();
            }
            else {
                promise = Beef.Conversation.post({text: this.getMessage(), profile: _(profiles).find(function(p) { return p.id == profile; }) });
                onComplete();
            }
        },

        cancelClicked: function(ev) {
            ev.preventDefault();
            this.close();
        },

        mousedown: function() {
            Beef.Popup.closePopups(this);
        },

        //----------------------------

        /**
         * Returns the user's authorized profile for the ID stored in the model's profile field
         */
        getProfileInfo: function(profile) {
            if (!profile) profile = this.model.get('profile');
            if (!profile) return false;

            profile = parseInt(profile);
            profile = _(Beef.user.getNonExpiredAuthorizedProfiles()).find(function(p) {
                return p.id == profile;
            });

            return profile;
        },

        /**
         * Says whether the currently selected profile is a twitter profile.
         */
        isTwitter: function(profile) {
            profile = profile || this.getProfileInfo();
            return profile && profile.type == "TWITTER_SCREEN_NAME";
        },

        isFacebook: function(profile) {
            profile = profile || this.getProfileInfo();
            return profile && (profile.type == "FACEBOOK_PAGE" || profile.type == "FACEBOOK_USER");
        },

        isEmail: function(profile) {
            return this.model.get('email')
        },

        isReshare: function() {
            return this.model.get('reshare') != null;
        },

        isReply: function() {
            return this.model.get('mention') != null;
        },

        getUserEmailAddress: function() {
            return Beef.user.get('email');
        },

        //----------------------------

        /**
         * Returns a promise that will, on completion, be given a list of email addresses.
         */
        getEmailAddresses: function() {
            var emailDeferred = new $.Deferred();

            var emailData = this.model.get('emails');
            if (!emailData) return [];

            var userIds = [],
                emails = [],
                nonUser = [];
            var dataPoints = splitQuotedString(emailData);

            _(dataPoints).each(function(d) {
                if (d[0] == "'") {
                    var unquotedAddress = removeSingleQuotes(d);
                    emails.push(unquotedAddress);
                    nonUser.push(unquotedAddress);
                }
                else {
                    userIds.push(parseInt(d));
                }
            });

            if (userIds.length) {
                try {
                    deprecatedFetchUsers(this, function(data) {
                        _(userIds).each(function(d) {
                            if (data[d]) {
                                emails.push(data[d]);
                            }
                        });
                        emailDeferred.resolve(emails, nonUser);
                    });
                }
                catch(e) {
                    emailDeferred.reject();
                }
            }
            else {
                emailDeferred.resolve(emails, nonUser);
            }

            return emailDeferred.promise();
        },

        //----------------------------

        close: function() {
            Beef.Popup.closePopups(this);
            Beef.BoundItemView.prototype.close.call(this);
        }

    });

    /**
     * Displays the interact dialog.
     * @param target May be either a view, or a jquery node.
     * @param mention An optional model of a mention to interact with (for instance, when replying).
     * @param mentionIds A list of mention ids for mentions that will be sent in this message.
     * @param accountCode The account code; can be determined from the model, if present
     * @param email Set to true if this should send emails.
     * @param subject An optional subject line for an email.
     * @param message An optional initial message text.
     * @param footer An optional bit of html text that can be inserted as the footer of an email.
     * @param filter A filter to select mentions with - filterTotal must be provided with this.
     * @param filterTotal The total number of mentions that have been selected. Necessary if filter is provided, optional for mentionIds.
     * @param namespace An optional namespace to be used when looking for previous messages. Set this to something sensible
     *                  if edited posts should not conflict with those in other areas of the app.
     */
    this.show = function(options) {
        var target = options.target;
        var model = options.mention || options.reshare;
        var accountCode = options.accountCode ? options.accountCode : model.getAncestorProperty('accountCode');
        var email = options.email || false;

        if (target.$el) target = target.$el;
        this.popup = new Beef.Popup.View();

        if (options.filter && !options.filterTotal) {
            throw new Error("A filterTotal must be provided with filter");
        }

        var title = "Post a new mention";
        if (options.mention) title = "Reply to this mention";
        if (options.reshare) title = "Reshare this mention";
        if (email) title = "Send an email";

        var interactModel = new Backbone.Model({
            mention: options.mention,
            reshare: options.reshare,
            email: email,
            title: title,
            subject: options.subject || "Have a look at these mentions from DataEQ",
            mentionIds: options.mentionIds,
            filter: options.filter,
            filterTotal: options.filterTotal,
            message: options.message,
            footer: options.footer,
            namespace: options.namespace,
            count: 0
        });

        interactModel.accountCode = accountCode;

        var view = new View({
            model: interactModel
        });
        this.popup.setTarget(target);
        this.popup.show(view);
    }

});