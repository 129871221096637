<template>

    <main class="deq-reset">
        <div class="head title">
            <h1>Import Mentions</h1>
        </div>

        <section>
            <be-button @click="onUploadClick" class="">Upload mentions</be-button>

            <div>
                <p>
                    This tool allows you to upload a CSV of mentions and save them to your DataEQ account. The
                    <i>Mention Uploader</i> option under <i>Only read these feeds</i> refers to mentions uploaded in this
                    way.
                </p>

                <p>
                    The first row of the CSV file must give column names for the remaining rows. The columns are
                    interpreted as follows:
                </p>

                <ul>
                    <li>
                        If the column name is one of the (case-sensitive) mention fields shown in the table below, it is
                        interpreted as described there.
                    </li>
                    <li>
                        Otherwise, each value in the column will be applied to the mention in its row as an (unverified)
                        tag in the <i>tag</i> namespace. Tags are created in the account if they don't exist yet, or
                        reused if they do.
                    </li>
                </ul>

                <p>
                    You will have a chance to confirm how columns are being interpreted before importing.
                </p>

                <p>
                    <em>Tip: If you save a CSV from Excel, it will often not preserve special characters or emoji. Try
                        saving to CSV from a program that supports UTF-8 encoding, such as LibreOffice, or Numbers on
                        Mac.</em>
                </p>
            </div>

            <div>
                <h2>Minimum Requirements</h2>

                <p>
                    The CSV should have at least the following columns:
                </p>

                <ul>
                    <li>
                        <i>uri</i> - a globally unique URI to identify this mention - duplicates will be rejected.
                        The URI would typically be a link to the original source of the mention, but it can be
                        constructed artificially, although it has to be in URI format.
                    </li>
                    <li>
                        <i>published</i> - the time and date the mention was published. Many formats are accepted,
                        including all variants of ISO 8601. One safe ISO 8601 format is
                        <i>yyyy-mm-ddThh:mm:ss[.mmm]+0000</i>, for example "2019-05-14T06:36:45+0000". For better
                        compatibility, a space will be replaced with "T". If there is no timezone, it will be set to
                        UTC.
                    </li>
                    <li>
                        <i>content</i> - the mention text.
                    </li>
                </ul>
            </div>

            <div>

                <h2>Visibility</h2>

                <p>
                    If there is no <i>visibility</i> column, all mentions will have the default PUBLIC visibility.
                    Adding a <i>visibility</i> column with all cells under it set to "INTERNAL" (without quotes) will
                    cause the mentions to be handled as sensitive data.
                </p>

                <p>
                    <em>Warning: Unfortunately, it is not possible to set some mentions to PUBLIC by leaving their
                        <i>visibility</i> cell empty, because this would result in <i>visibility</i> being set to an
                        (illegal) empty string rather than being omitted entirely.</em>
                </p>
            </div>

            <div class="mentions-table">
                <h2>Mention Fields</h2>

                <div class="mentions-headings">
                    <div class="mentions-field">
                        Field
                    </div>
                    <div class="mentions-description">
                        Description
                    </div>
                </div>
                <div class="mentions">
                    <div class="mentions-data" v-for="(mention, index) in mentionFields" :key="index">
                        <div class="mentions-field">
                            {{mention.name}}
                        </div>
                        <div class="mentions-description">
                            {{mention.description}}
                            <div v-if="mention.example && user.admin" class="mentions-example">
                                <staff-only-tag />
                                example: {{mention.example}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>

        <br>
        <br>

        <import-mentions-dialog @close="onModalClose" v-if="showUpload"/>
    </main>
</template>

<script>

import ImportMentionsDialog from "@/setup/importmentions/ImportMentionsDialog.vue";
import {mentionFields} from "@/setup/importmentions/mentionFieldsData";
import {mapState} from "vuex";
import StaffOnlyTag from "@/components/tags/StaffOnlyTag.vue";
import BeButton from "@/components/buttons/BeButton.vue";

export default {
    name: "SetupImportMentions",
    components: {BeButton, StaffOnlyTag, ImportMentionsDialog},
    data() {
      return {
          mentionFields: mentionFields,
          showUpload: false,
      }
    },

    mounted() {
        if(!this.user.admin) {
            this.mentionFields = this.mentionFields.filter(field => {
                return field.name !== 'mediaLinks' && field.name !== 'mentionedAuthors'
            })
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState(['user']),
    },

    methods: {
        onUploadClick() {
            this.showUpload = !this.showUpload;
        },

        onModalClose() {
            this.showUpload = false;
        }
    }
}
</script>

<style scoped lang="sass">

.import-mention-setup .refresh
    margin-top: 8px
    margin-left: 8px
    float: right

.be-button
    margin-bottom: 15px

.mentions-headings, .mentions-data
    display: flex
    flex-direction: row

.mentions-headings
    background-color: #222
    border-top: 1px solid black

    .mentions-data
        background-color: #333

.mentions-field, .mentions-description
    border: 1px solid black
    border-top: none
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    overflow: hidden
    padding: 5px



.mentions-field
    width: 20%
    border-right: none


.mentions-description
    width: 80%

    .mentions-example
        margin-top: 10px
        display: flex
        flex-direction: column
        gap: 5px

        .staff-only-tag
            width: 113px
</style>