<template>
    <div class="beef-tooltip">
        <header>
            <h1>
                <net-sentiment v-if="toShowNet" :net-sentiment="toShowNet" no-number/>{{ storeTag.name }}
            </h1>
            <div v-if="parent && parent.namespace === 'topic'"
                 class="topic-tooltip__parent">
                Relates to <strong>{{ parent.name }}</strong>
            </div>
        </header>

        <p v-if="description" class="topic-tooltip__description">
            {{description}}
        </p>

        <p v-if="tag.namespace === 'tag'" class="deq-text--muted">
            <span v-if="storeTag && storeTag.created">
                This custom tag was created on <deq-date :date="tag.created" format="dddd, D MMMM, YYYY"/>.
            </span>
            <span v-if="!storeTag.id" class="deq-callout--muted">
                This will be a newly created tag.
            </span>
        </p>

        <p v-if="Number.isFinite(mentionCount)">
            This topic occurred on
            <strong>
                <num :number="mentionCount"/> {{ formatPlural(mentionCount, 'mention') }}</strong><span v-if="Number.isFinite(toShowNet)">,
                with a <strong>Net sentiment of<net-sentiment :net-sentiment="toShowNet"/></strong>
            </span>
        </p>
        <p v-if="Number.isFinite(dailyAverage)">
            It occurred on average <strong><num :number="dailyAverage" :precision="1"/> times a day</strong>.
        </p>
    </div>
</template>


<script>

import {formatPlural} from "@/app/utils/Format";
import Num from "@/components/formatters/DeqNumber";
import NetSentiment from "@/components/NetSentiment";
import {substituteTagParamaters} from "@/app/utils/Tags";
import {mapActions, mapGetters} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {count} from "@/data/Grouse";
import {getTimedLocalstorageCache} from "@/data/Cache";
import DeqDate from "@/components/formatters/DeqDate";

export default {
    components: {DeqDate, NetSentiment, Num},
    store: VuexStore,
    props: {
        tag: {
            type: Object,
            required: true
        },
        mentionCount: {
            type: Number,
            required: false
        },
        net: {
            type: Number,
            required: false
        },
        dailyAverage: {
            type: Number,
            required: false
        },
        filter: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            storeTag: null,
            toShowNet: this.net
        }
    },

    watch: {
        tag() {
            this.updateStoreTag();
        },
        net() {
            this.toShowNet = this.net;
        }
    },

    async created() {
        await this.updateStoreTag();
        await this.updateNetSentiment();
    },

    computed: {
        ...mapGetters(['idToTag']),

        description() {
            const description = this.storeTag?.description || this.storeTag?.descriptions?.en;
            if (!description) return null;
            return substituteTagParamaters(description);
        },

        parent() {
            return this.storeTag?.parent;
        }
    },

    methods: {
        formatPlural,
        ...mapActions(['refreshTags']),

        async updateStoreTag() {
            this.storeTag = null;
            if (!this.tag) return;

            this.storeTag = this.tag; // Makes loading some data look faster.
            await this.refreshTags();
            this.storeTag = this.idToTag.get(this.tag.id) ?? this.tag; // Fallback if tag isn't in the account (possibly a bug?)
        },

        async updateNetSentiment() {
            if (Number.isFinite(this.net)) {
                this.toShowNet = this.net;
                return;
            }

            if (this.filter) {
                const sentimentData = await getTimedLocalstorageCache("tag-tooltip:" + this.filter, async() => {
                    return await count(this.filter, null, ["totalVerifiedSentiment", "sentimentVerifiedCount"])
                });

                this.toShowNet = sentimentData?.sentimentVerifiedCount
                    ? sentimentData.totalVerifiedSentiment / sentimentData.sentimentVerifiedCount
                    : 0;
            }
        }
    }
}
</script>


<style scoped lang="sass">

header
    padding-bottom: 20px

    h1
        margin: 0
        padding: 0
        line-height: 1.1em

    .topic-tooltip__parent
        color: var(--be-colour-muted-text-dark)

.topic-tooltip__description
    font-style: italic
    margin-block: 0
    padding-block: 0
    padding-bottom: 20px
    em
        font-style: normal


</style>