/**
 * Various bits of example markdown content.
 */

export const intro = '# {{title}}\n\n' +
        'Insert your own opinions, insights and comments on the analysis of your conversation here.\n\n' +
        'You can _italicise_ or **bold** your text. Also, you can insert [links](https://www.dataeq.com), and create lists:\n\n' +
        '- item one\n' +
        '- item two\n\n' +
        'You can highlight information:\n\n' +
        '> ### Mentions received\n' +
        '> {{total mentions}} mentions\n\n' +
        'If you would like to find out more about markdown, have a look at the [markdown guide](https://www.markdownguide.org/).\n\n' +
        'You can see how to display mentions or mention counts in a comment area by loading the comment cookbook from the settings.\n\n' +
        'Enjoy! 😊';

export const cheat = "# The Commentary Box Cookbook\n" +
        "\n" +
        "## Styling text\n" +
        "You can make text _italic_ or **bold**.\n" +
        "\n" +
        "## Social media icons\n" +
        "\n" +
        "- {{x}} X\n" +
        "- {{twitter}} Twitter\n" +
        "- {{tiktok}} TikTok\n" +
        "- {{facebook}} Facebook\n" +
        "- {{instagram}} Instagram\n" +
        "- {{youtube}} YouTube\n" +
        "- {{linkedin}} LinkedIn\n" +
        "- {{website}} Websites\n" +
        "\n" +
        "These icons, like the {{facebook}} one, also appear a different size in paragraph text, but you can override that, like so {{facebook 32}}.\n" +
        "\n" +
        "## Sentiment and priority\n" +
        "\n" +
        "You can add icons for showing sentiment and priority mentions. \n" +
        "\n" +
        "- {{negative-icon}} {{total negative}} mentions.\n" +
        "- {{neutral-icon}} {{total neutral}} mentions.\n" +
        "- {{positive-icon}} {{total positive}} mentions.\n" +
        "- {{risk}} {{total mentions 'tag is 1'}} mentions.\n" +
        "- {{purchase}} {{total mentions 'tag is 2'}} mentions.\n" +
        "- {{cancel}} {{total mentions 'tag is 3'}} mentions.\n" +
        "- {{service}} {{total mentions 'tag is 4'}} mentions.\n" +
        "\n" +
        "\n" +
        "## Quoting values\n" +
        "\n" +
        "You can quote values to highlight them, like so:\n" +
        "\n" +
        "> ### My brand\n" +
        "> {{total mentions}} mentions\n" +
        "\n" +
        "Quoting works very nicely when there are only quotes in a comment box. Try it!\n" +
        "\n" +
        "## Useful text\n" +
        "\n" +
        "- The currently selected brand: {{brand}}\n" +
        "- The currently brand as a possessive: {{brand's}}\n" +
        "- The current metric's caption: {{title}}\n" +
        "\n" +
        `## Summarising mentions
        - Summarise the mentions selected by the metric's filter: {{summarise-mentions}}
        - Use a subfilter for summarising mentions: {{summarise-mentions 'socialnetwork is TWITTER'}} 
        ` +
        "\n## Counting mentions\n" +
        "\n" +
        "- Counting mentions: {{total mentions}}\n" +
        "- Counting mentions with a subfilter: {{total mentions 'socialnetwork is TWITTER'}} X posts\n" +
        "- Engagement: {{total engagement}}\n" +
        "- Net sentiment: {{total net%}}\n" +
        "\n" +
        "Try clicking on the numbers.\n" +
        "\n" +
        "## Showing top results\n" +
        "\n" +
        "- Where most mentions are from: {{top country}}\n" +
        "- Where most mentions are from, excluding unknown: {{top country \"country isnt 'UN'\"}}\n" +
        "- Most common language: {{top language 'language isnt unknown'}}\n" +
        "\n" +
        "## Showing mentions\n" +
        "\n" +
        "With the most engagement: {{mention 'engagement desc'}}\n";


export const socialStats = "> # X\n" +
        "> {{X}} {{total mentions 'socialnetwork is TWITTER'}}\n" +
        "\n" +
        "> # Facebook\n" +
        "> {{facebook}} {{total mentions 'socialnetwork is FACEBOOK'}}\n" +
        "\n" +
        "> # Instagram\n" +
        "> {{instagram}} {{total mentions 'socialnetwork is INSTAGRAM'}}\n";

export const topLevelStats = "> ### Volume\n" +
        "{{total mentions}} mentions\n" +
        "> ### Net sentiment\n" +
        "{{total net%}}";

export const sentiment = "> #### Benchmark: \n" +
    "> {{total benchmark-net%}}\n" +
    "\n" +
    "> #### Positive: \n" +
    "> {{total positive%}}\n" +
    "\n" +
    "> #### Neutral: \n" +
    "> {{total neutral%}}\n" +
    "\n" +
    "> #### Negative: \n" +
    "> {{total negative%}}\n";


export const rpcs = "> ## {{risk}} \n" +
        "> {{total mentions 'tag is 1'}} mentions\n" +
        "> ## {{purchase}} \n" +
        "> {{total mentions 'tag is 2'}} mentions\n" +
        "> ## {{cancel}} \n" +
        "> {{total mentions 'tag is 3'}} mentions\n" +
        "> ## {{service}} \n" +
        "> {{total mentions 'tag is 4'}} mentions\n" +
        "\n";

export const netStats = `> {{total net%}} Net Sentiment

> # Operational (CX)
> {{total net% "ReshareOf IS unknown AND Segment IS 93726"}} Net Sentiment

> # Reputational (non-CX)
> {{total net% "(Segment IS 100916 OR Segment IS 93744)"}} Net Sentiment
`;