<template>
    <section class="beef-tooltip">
        <h4>{{ tag.name }}</h4>
        <p v-if="tag.clientDescription || tag.description">
            {{substituteTagParamaters(tag.clientDescription || tag.description)}}
        </p>
    </section>
</template>



<script>

import {substituteTagParamaters} from "@/app/utils/Tags";

export default {
    props: {
        tag: Object
    },

    methods: {
        substituteTagParamaters
    }
}

</script>