import * as d3 from "d3"
import { VennDiagram } from 'venn.js'
import {cloneDeep} from 'lodash'

/**
 * Low level d3 code to render the Venn diagram.
 */
export default function beefRenderVenn(domNode, width, height, data, options) {
    options = options || {}

    data = cloneDeep(data)

    let margin = { top: 20, right: 20, bottom: 20, left: 20 }

    let svg = ensure(d3.select(domNode), "svg").attr("width", width).attr("height", height)
    width -= margin.left + margin.right
    height -= margin.top + margin.bottom

    let g = ensure(svg, "g", "main").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    let chart = VennDiagram()
        .width(width).height(height)
        .styled(false)
        .wrap(false)
        .padding(4) // Padding provided to provide the extra space needed when highlighting circles
        .orientation((options.orientation || 270) * Math.PI / 180)
        .duration(options.noAnimation ? 0 : 1000)
    let cd
    try {
        cd = chart(g.datum(data.sets))
    } catch (ignore) {
        // if not all sets referenced in intersections are present it throws an error
    }

    if (cd && data.complete) {
        Object.entries(cd.circles).forEach(e => e[1].id = e[0])
        data.sets.forEach(d => {
            let c = cd.circles["" + d.sets]
            if (c) c.percentage = d.percentage
        })

        let circles = Object.values(cd.circles)
        circles.sort((a,b) => b.size - a.size)

        // select circle elements. Sort by size descending so that smaller circles are always on top (raise() function called in correct order, i.e biggest -> smallest)
        let gCircles = g.selectAll(`.venn-circle`).sort((a,b) => d3.descending(a.size, b.size));
        if (options.onClick) {
            gCircles.on("click",options.onClick);
        }

        // hide circle labels at first until they are positioned correctly
        gCircles.each(function(d) {
            let circle = d3.select(this);
            let circleLabel = circle.select("text");

            circleLabel.style("opacity", 0);
        });

        // Try to nicely position circle text labels - venn.js lib will place labels according to where they will fit and not be overlapped. This is good,
        // since we don't have to worry about overlapping labels. However the lib also takes into account intersections when calculating label positions, and since we don't show intersections, we could end up with
        // text labels that aren't centered in a circle when they could be.
        g.transition().delay(1000).on("end", function(d) { // todo: figure out a better way to detect when movement transition is finished
            // create clone of "circles" so we can keep track of positioning + modify data
            let circlesDataClone = JSON.parse(JSON.stringify(circles));
            circlesDataClone.forEach(d => {
                d.shouldCenterText = true;
            });

            gCircles.each(function(d, i1) {
                let circle = d3.select(this);
                let circleLabel = circle.select("text");

                // Risk, Purchase, Cancel, or Service
                let circle1Id = d.sets[0];

                let circle1Data = circlesDataClone.find(c => c.id === circle1Id);

                // place circle on top
                circle.raise();

                // check if we should either center the circle's label or keep venn.js lib's original positioning
                circlesDataClone.forEach((circle2Data, i2) => {
                    // only check circles that will be above current circle
                    if (i2 > i1) {
                        // check if circle above will overlap current circle's text. If it will, keep venn.js lib's original position, otherwise, center the text
                        let circleLabelTextWidth = circleLabel.node().getComputedTextLength();
                        let circle1TextStart = circle1Data.x - (circleLabelTextWidth/2);
                        let circle1TextEnd = circle1Data.x + (circleLabelTextWidth/2);

                        // does above circle overlap current circle's label based on x pos?
                        let xCheckLabelStart = (circle1TextStart >= circle2Data.x - circle2Data.radius && circle1TextStart <= circle2Data.x + circle2Data.radius)
                        let xCheckLabelEnd = (circle1TextEnd >= circle2Data.x - circle2Data.radius && circle1TextEnd <= circle2Data.x + circle2Data.radius)

                        // does above circle overlap current circle's y position?
                        let yCheck = (circle1Data.y >= circle2Data.y - circle2Data.radius && circle1Data.y <= circle2Data.y + circle2Data.radius)

                        if ((xCheckLabelStart || xCheckLabelEnd) && yCheck) {
                            //console.log(`not centering ${circle1Id} because of ${circle2Data.id}`);
                            circle1Data.shouldCenterText = false;
                        }
                    }
                });

                // center the label if it should be centered
                if (circle1Data.shouldCenterText) {
                    circleLabel.attr("x", circle1Data.x);
                    circleLabel.attr("y", circle1Data.y);
                }

                // remove font weight and slightly increase height of circle label to account for percentage
                circleLabel.attr("dy", "-0.15em");
                circleLabel.style('font-weight', 'unset')

                // append percentage to circle label
                let percentage = formatPercent(d.percentage);
                circleLabel.append("tspan")
                    .text(percentage)
                    .style('font-weight', 'unset')
                    .attr("class", "label")
                    .attr("x", function() { return d3.select(this.parentNode).attr("x"); })
                    .attr("dy", "1.2em")

                circleLabel.transition()
                    .duration(300)
                    .style("opacity", 1);
            })
        })
    }

    return data
}

function ensure(parent, tag, cls) {
    let sel = parent.select(tag + (cls ? "." + cls : ""))
    if (sel.empty()) {
        sel = parent.append(tag)
        if (cls) sel.attr("class", cls)
    }
    return sel
}

const dec1 = d3.format(".1f")

function formatPercent(p) { return dec1(p) + "%" }
