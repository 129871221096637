import VuexStore from "@/store/vuex/VuexStore";
import {features} from "@/app/Features";
import {gotoExplore} from "@/app/toplevel/explore/overview/ExploreUtilities";
import {gotoDashboard} from "@/app/toplevel/dashboards/DashboardUtilities";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import {gotoAccountHealth, gotoSetupPanel} from "@/app/toplevel/setup/SetupUtilities";
import {showHelp} from "@/app/help/HelpRouting";
import {undo} from "@/app/framework/notifications/Notifications";
import {areAnyPopupsVisible} from "@/app/framework/dialogs/DialogUtilities";


/** @type {Array<NavigationShortcut>} */
export const NAVIGATION_SHORTCUTS = [];

class NavigationShortcut {
    #key;
    #isValid;
    #action;
    #description;

    get key() {
        return this.#key;
    }

    get description() {
        return this.#description;
    }

    async run() {
        if (!this.#isValid || await this.#isValid()) {
            await this.#action();
        }
    }

    async isValid() {
        if (!this.#isValid) return true;
        return !!(await this.#isValid())
    }

    constructor(key, isValid, action, description) {
        this.#key = key;
        this.#isValid = isValid;
        this.#action = action;
        this.#description = description
    }

    /**
     *
     * @param {string} key
     * @param {Function} action
     * @param {String} description
     * @return {NavigationShortcut}
     */
    static when(key, action, description) {
        let result = new NavigationShortcut(key, null, action, description);
        NAVIGATION_SHORTCUTS.push(result);
        return result;
    }

    /**
     *
     * @param {string} key
     * @param {Function} predicate
     * @param {Function} action
     * @param {String} description
     * @return {NavigationShortcut}
     */
    static whenAnd(key, predicate, action, description) {
        let result = new NavigationShortcut(key, predicate, action, description);
        NAVIGATION_SHORTCUTS.push(result);
        return result;
    }
}

//---------------------------------

NavigationShortcut.when("e", async() => await gotoExplore(), "Go to the Explore Panel");
NavigationShortcut.when("r", () => gotoDashboard(), "Go to the Reporting Panel");
NavigationShortcut.when("m", () => gotoMentionPanel(), "Go to the Mentions Panel");
NavigationShortcut.when("a", () => gotoAuthorsPanel(), "Go to the Authors Panel");
NavigationShortcut.when("s", () => gotoSetupPanel(), "Go to Account Setup");
NavigationShortcut.when("h", () => gotoAccountHealth(), "Go to Account Health");
NavigationShortcut.whenAnd("c", () => !!VuexStore.state.user?.accounts?.length, () => Beef.router.navigate("/accounts", {trigger: true}), "Go to the Accounts Page");

//---------------------------------

let goClicked = false;

/**
 * This function decides whether to navigate based on if a key was pressed.
 * @param {string} key - The key that was pressed after the universal navigation key (G).
 * @return {Promise<void>}
 */
async function possiblyNavigate(key) {
    const account = VuexStore.state.account;
    if (!account.code) return;

    if (goClicked) {   // has someone previously pressed 'g'
        if (key !== 'g') goClicked = false;

        const found = NAVIGATION_SHORTCUTS.find(s => s.key === key);
        if (found) await found.run();
    } else {
        if (key === 'g') goClicked = true;
    }

}

/**
 * Handles general keyboard shortcuts.
 * @return {Promise<void>}
 */
export async function initialiseKeyboardShortcuts() {
    document.addEventListener('keydown', async(event) => {
        if (event.target?.tagName === 'INPUT') return; // quickly fail if the user is typing.
        if (areAnyPopupsVisible()) return;

        if (event.key === '?') {
            showHelp();
            return;
        }

        await possiblyNavigate(event.key);

        if (event.key === 'Undo' || (event.key === 'z' && event.metaKey)) {
            undo();
        }
    }, {capture: true, passive: true});
}