/**
 * Render a number approximately (e.g. 1.2m) unless exact is true.
 */
import {formatNumber, toSi} from "@/app/utils/Format";

export default function(number, exact, context) {
    if (context == undefined) exact = false; // this happens when we are invoked with only one arg
    var text = formatNumber(number);
    if (exact) text = "<span>" + text + "</span>";
    else text = "<span title='" + text + "'>" + toSi(number) + "</span>";
    return new Handlebars.SafeString(text);
}