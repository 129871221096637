import { intro } from "../../../app/framework/markdown/MarkdownExamples";
import CommentSettingsDialog from "./CommentSettingsDialog"

Beef.module("Widget.Text").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Commentary",
        description:    "Add a text box to include your own comments",
        group:          "other",
        vueSettingsDialog: CommentSettingsDialog,
    };

    this.View = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/comment/Comment.handlebars"),
        attributes: {
            class: 'text-display widget-height-inner'
        },

        svgExportDisabled: true,

        modelEvents: {
            "change": "maybeRefresh"
        },

        initialize: function() {
            let attrs = this.model.attributes
            if (!attrs.width) attrs.width = 4
            if (!attrs.height) attrs.height = 4
            if (attrs['hidden-title'] === undefined) attrs['hidden-title'] = true
            if (attrs.fontSize === undefined) attrs.fontSize = 14
            if (attrs.text === undefined) attrs.text = intro
            if (attrs.filter === undefined) attrs.filter = null
        },

        maybeRefresh: function() {
           if (this.model.hasChanged("filter")) {
               // Do not do anything in this case. Changing the metric's filter will result in a change in the _effectiveFilter, which will fire another change event.
               // Let the _effectiveFilter trigger the re-render.
               return;
           }

           if (this.model.hasChanged("text") || this.model.hasChanged("_effectiveFilter") || this.model.hasChanged("fontSize")) {
               this.onRender();
           }
        },

        onRender: function() {
            if (!this.model.get('_effectiveFilter')) return;

            this.model.generalData.set("_completed", false);

            this.$el.css('overflow', 'auto')    // fixes scrolling not working on Safari

            let $markdown = this.$('.markdown')
            $markdown[0].classList.forEach(c => {
                if (c.startsWith("markdown-font-size-")) $markdown[0].classList.remove(c)
            })
            $markdown.toggleClass("markdown-font-size-" + this.model.get('fontSize'), true)

            var filter = this.model.get("_effectiveFilter");
            var result = this.result = Beef.Markdown.render(this.model.get('text'), filter,
                this.model.getAncestorProperty('accountCode'), {caption: this.model.get('caption')});
            this.$('.text').html(result.text);

            var that = this;
            result
                .then(function (updates) {
                    if (updates.size){
                        updates.forEach(function (html, selector) {
                            that.$(selector).html(html).removeClass('text-loading');
                        });
                        result.events(that.$('.markdown-display')[0]);
                    }
                })
                .finally(function () {
                    that.model.generalData.set('_completed', true);
                })
                .catch(console.error);


        },

        onClose: function() {
            if (this.result) {
                this.result.destroy();
                this.result = null;
            }
        }
    });
});