/**
 * A module for creating token-expiry warnings, by showing a popup.
 */
Beef.module("RaceUpsell").addInitializer(function(startupOptions) {

    this.show = function(target) {
        var model = new Backbone.Model({});
        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"]});
        popup.setTarget(target);
        popup.show(new View({ model: model }));
    };

    var View = Backbone.Marionette.ItemView.extend({
        template: require("@/notifications/RaceUpsell.handlebars"),

        attributes: { class: "dialog" },

        events: {
            "click .ok": "ok"
        },

        ok: function() {
            this.parentPopup.hide();
        }
    });
});
