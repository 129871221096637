var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"profile tag-input uneditable-input\">\n                    <span name=\"profile\"></span>\n                </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"user-email-box\">\n                    <span class=\"user-email\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"userEmail") : depth0), depth0))
    + "</span>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-fluid sending-to-box\">\n                <div class=\"sending-to\">\n                    <span class=\"interact-label\">To</span>\n                    <span class=\"emails tag-input uneditable-input\">\n                        <span name=\"emails\"></span>\n                        <input dir=\"auto\" class=\"tag popup-menu\" type=\"text\"/>\n                    </span>\n                </div>\n            </div>\n\n            <div class=\"row-fluid subject-box\">\n                <div class=\"subject\">\n                    <span class=\"interact-label\">Subject</span>\n                    <span class=\"subject-text\">\n                        <input dir=\"auto\" type=\"text\" class=\"message-subject\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subject") : depth0), depth0))
    + "\">\n                    </span>\n                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"numMentions") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row-fluid email-disclaimer\">\n            A copy of this email will be sent to <em>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"userEmail") : depth0), depth0))
    + "</em>.\n        </div>\n\n        <div class=\"row-fluid email-format-warning\">\n            The highlighted email addresses may be incorrectly formatted. You can still send the message, but it might\n            not reach your intended recipient.\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-fluid email-mention-count\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"moreThan50") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":51,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias1(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"numMentions") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":48,"column":48}}}))
    + " "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"mentionWord") : depth0), depth0))
    + " will be sent with this email.\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"numMentions") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":48}}}))
    + " mentions were selected, but a maximum of 50 will be sent with this email.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-body dialog-body-notitle\">\n\n    <div class=\"header-and-message\">\n        <div class=\"row-fluid replying-as\">\n            <span class=\"interact-label\">From</span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":14,"column":23}}})) != null ? stack1 : "")
    + "\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row-fluid\">\n            <textarea class=\"message\" placeholder=\"Type your message here\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</textarea>\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":63,"column":11}}})) != null ? stack1 : "")
    + "\n\n    <div class=\"row-fluid\">\n        <div class=\"dialog-button-bar\">\n            <div class=\"pull-left\">\n                <i class=\"network-icon pull-left\"></i>\n                <span class=\"counter pull-right\">\n                    <span class=\"count\">1</span>/140\n                </span>\n                <span class=\"exact-retweet pull-right\" title=\"This is an exact retweet of the original, so tweet length limits do not apply\">\n                    Exact retweet\n                </span>\n            </div>\n\n            <div class=\"pull-right\">\n                <a class=\"btn cancel\" title=\"Discard this message (esc)\">Cancel</a>\n                <a class=\"btn btn-primary send\" title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"buttonTooltip") : depth0), depth0))
    + "\"><span class=\"send-label\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"buttonText") : depth0), depth0))
    + "</span><span class=\"sending-label\"><span class=\"spinner-dark\"></span>Sending ...</span></a>\n            </div>\n        </div>\n    </div>\n\n\n</div>";
},"useData":true});