var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <td title=\"How many times might this feed have missed data this week?\" class=\"num\">Misses</td>\n        <td></td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <tfoot>\n        <tr>\n            <td colspan=\"6\">\n                <button class=\"btn btn-link add\" title=\"Create new feed\"><i class=\"icon-plus\"></i> Add a new custom data source</button>\n            </td>\n        </tr>\n        </tfoot>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"table table-condensed table-bordered table-hover\">\n    <thead>\n    <tr>\n        <td class=\"name\">Name</td>\n        <td>Link</td>\n        <td>Activity</td>\n        <td title=\"Mentions read this week\" class=\"num\">Vol/Week</td>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":11,"column":22}}})) != null ? stack1 : "")
    + "    </tr>\n    </thead>\n    <tbody class=\"body\"></tbody>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editFeeds",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":23,"column":18}}})) != null ? stack1 : "")
    + "</table>";
},"useData":true});