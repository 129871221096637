<template>
    <div class="mention-conversation-information">
        <h1 class="underlined">
            Conversation with <span class="conversation-author">{{ authorName }}</span>
        </h1>
        <div v-if="loading">
            Loading conversation statistics...
        </div>
        <template v-else>
            <div class="underlined" v-if="error">
                Failed to load conversation statistics. {{ error }}
            </div>
            <div class="underlined" v-else>
                <div class="row-fluid">
                    <div class="span5"
                         title="The date range of the conversation, from first message to last reply, not including reshares">
                        <div class="span5">
                            Conversation active over:
                        </div>
                        <div class="span7">
                            From
                            <DeqDate v-if="firstPublished" :date="firstPublished"/>
                            <span v-else>N / A</span>
                            to
                            <DeqDate v-if="lastPublished" :date="lastPublished"/>
                            <span v-else>N / A</span>
                        </div>
                    </div>

                    <div class="span5"
                         title="The number of unique authors taking part in this conversation (including reshares)">
                        <div class="span5">
                            Number of unique authors:
                        </div>
                        <div class="span7">
                            <DeqNumber :number="authorIdCount" />
                        </div>
                    </div>
                </div>

                <div class="row-fluid" v-if="!selectedMention.isDirectMessage">
                    <div class="span5" title="The date at which the last mention was reshared">
                        <div class="span5">
                            Date of last reshare:
                        </div>
                        <div class="span7">
                            <DeqDate v-if="lastReshare" :date="lastReshare"/>
                            <span v-else>N / A</span>
                        </div>
                    </div>

                    <div class="span5" title="The number of reshares and replies generated by this conversation">
                        <div class="span5">
                            Engagement:
                        </div>
                        <div class="span7">
                            <DeqNumber :number="totalEngagement" />
                        </div>
                    </div>

                </div>

                <div class="row-fluid">
                    <div class="span5"
                         title="The total number of mentions in this conversation, not including reshares">
                        <div class="span5">
                            Total mentions (excluding reshares):
                        </div>
                        <div class="span7 mention-count">
                            <DeqNumber :number="totalMentionsExcludingReshares" />
                        </div>
                    </div>
                </div>

                <div class="row-fluid" v-if="!selectedMention.isDirectMessage">
                    <div class="span5" title="The number of reshares in this conversation">
                        <div class="span5">
                            Total reshares:
                        </div>
                        <div class="span7">
                            <DeqNumber :number="totalReshares" />
                        </div>
                    </div>
                </div>

            </div>
        </template>
    </div>
</template>

<style scoped>
.underlined {
    border-bottom: 1px solid rgb(102, 102, 102);
}

.mention-conversation-information {
    width: 100vw;
    box-sizing: border-box;
    padding-left: 100px;
    padding-right: 100px;
    background: var(--body-background-colour);
    z-index: 100;
    padding-bottom: 10px;
}
</style>

<script>
import {getPrivateConversationStats, getPublicConversationStats} from "@/conversation/utils/GrouseConversationProxy";
import DeqDate from "@/components/formatters/DeqDate";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    name: "ConversationInfo",
    components: {DeqNumber, DeqDate},
    data: function () {
        return {
            loading: false,
            error: null,
            authorIdCount: 0,
            totalMentions: 0,
            totalReshares: 0,
            totalEngagement: 0,
            firstPublished: "",
            lastPublished: "",
            lastReshare: "",
        };
    },
    props: {
        selectedMention: {
            type: Object,
            required: true
        },
        accountCode: {
            type: String,
            required: true
        },
    },
    computed: {
        authorName: function () {
            return this.selectedMention.authorName;
        },
        conversationId: function () {
            return this.selectedMention.conversationId;
        },
        totalMentionsExcludingReshares: function () {
            return this.totalMentions - (this.totalReshares ?? 0);
        }
    },
    methods: {
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            this.error = null;
            try {
                const stats = this.selectedMention.isDirectMessage
                    ? await getPrivateConversationStats(this.accountCode,
                        this.conversationId, this.selectedMention.authorId, this.selectedMention.toId)
                    : await getPublicConversationStats(this.accountCode, this.conversationId);
                this.authorIdCount = stats.authorIdCount;
                this.totalMentions = stats.mentionCount;
                this.totalEngagement = stats.totalEngagement;
                this.totalReshares = stats.totalReshareCount;
                this.firstPublished = stats.firstPublished;
                this.lastPublished = stats.lastPublished;
                this.lastReshare = stats.lastReshare;
            } catch (err) {
                this.error = err;
                console.error(`Error retrieving conversation statistics`, err);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>