<template>
    <add-profile-dialog :profile-type="profileTypes.youtube"
                        :enable-next-btn="enableNext"
                        :next-btn-text="nextBtnText"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-prev-btn="!saving && curStage > 1"
                        :show-next-btn="!saving"
                        @close="$emit('close')"
                        @prev="previous"
                        @next="next">
        <template #workflow>
            <keep-alive>
                <search-profile :profile-type="profileTypes.youtube" v-if="!saving && curStage === addProfileStages.youtube.public.SEARCH_CHANNEL">
                    <template #description>
                        <p>Adding a Youtube Channel will allow you to track publicly posted videos and comments on those videos.</p>
                    </template>
                </search-profile>

                <edit-public-profile v-if="!saving && curStage === addProfileStages.youtube.public.EDIT_CHANNEL"/>

                <saving-item v-if="saving" :save-complete="saveComplete" :save-error="saveError" :save-error-message="saveErrorMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor:pointer;" @click="reset">here</a> to add more <i class="symbol-youtube"></i> Youtube channels</h4>
                    </template>
                </saving-item>
            </keep-alive>
        </template>
    </add-profile-dialog>
</template>

<script>
import VuexStore from "@/store/vuex/VuexStore";
import SearchProfile from "@/setup/profiles/add-profile/SearchProfile";
import EditPublicProfile from "@/setup/profiles/add-profile/EditPublicProfile";
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import SavingItem from "@/setup/SavingItem";


export default {
    name: "YoutubeAddProfileDialog",
    store: VuexStore,
    components: {SavingItem, AddProfileDialog, EditPublicProfile, SearchProfile},
    mixins: [addProfileMixins],

    data: function () {
        return {
            curStage: 1,
            totalStages: 1
        }
    },

    created() {
        this.totalStages = Object.keys(this.addProfileStages.youtube.public).length;
    },

    computed: {
        enableNext() {
            // If we are on the edit stage, we only allow saving if the profile has a category set
            if (this.curStage === this.addProfileStages.youtube.public.EDIT_CHANNEL) {
                return !!this.newProfileData.publicProfile?.media
            }
            // If we are on the search stage, we only enable next if the profile is not currently in the account (check "existing" field)
            if (this.curStage === this.addProfileStages.youtube.public.SEARCH_CHANNEL) {
                return !this.newProfileData.publicProfile?.existing && !!this.newProfileData.publicProfile;
            }
            return !!this.newProfileData.publicProfile;
        },

        nextBtnText() {
            // if we are on the final stage, show "Save" instead of "Next"
            return this.curStage === this.totalStages - 1 ? "Save" : "Next";
        }
    },

    methods: {
        next() {
            this.curStage++;
            if (this.curStage === this.totalStages)  this.savePublicProfile();
        },

        previous() {
            this.curStage--;

            if (this.curStage === 0) {
                this.curStage = 1;
            }
        },

        reset() {
            this.saving = false;
            this.saveComplete = false;
            this.curStage = 1;
            this.clearNewProfiles();
        }
    }
}
</script>

<style scoped lang="sass">

</style>