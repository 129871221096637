<template>
    <dialog-box modal @close="$emit('close')" width="700px" class="add-rule-dialog">
        <template #title>
            <h5>
                <i :class="getRuleIcon(ruleType)"></i> {{ title }}
            </h5>
        </template>

        <progress-bar v-if="showProgressBar" :total-stages="totalStages" :cur-stage="curStage"/>

        <div class="add-rule-dialog__workflow-container">
            <transition :name="curTransitionType">
                <slot name="workflow">

                </slot>
            </transition>
        </div>

        <template #buttons>
            <div class="add-rule-dialog__btn-bar">
                <be-button style="margin-left: auto" link @click="$emit('close')" :disabled="!enableCloseBtn">Close</be-button>
                <be-button class="short-animated fadeIn" @click="previous" v-if="showPrevBtn" :disabled="!enablePrevBtn">Previous</be-button>
                <be-button class="short-animated fadeIn" primary @click="next" v-if="showNextBtn" :disabled="!enableNextBtn">{{ nextBtnText }}</be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import {getRuleIcon, ruleTypes} from "@/setup/rules/RuleUtils";
import ProgressBar from "@/components/ProgressBar";
import BeButton from "@/components/buttons/BeButton";
import {addRuleMixins} from "@/setup/rules/add-rule/AddRuleMixins";

export default {
    name: "AddRuleDialog",
    components: {BeButton, ProgressBar, DialogBox},
    mixins: [addRuleMixins],

    props: {
        totalStages: {
            type: Number,
            required: false
        },
        curStage: {
            type: Number,
            required: true
        },
        showProgressBar: {
            type: Boolean,
            default: true
        },
        enableNextBtn: {
            type: Boolean,
            default: true
        },
        enablePrevBtn: {
            type: Boolean,
            default: true
        },
        nextBtnText: {
            type: String,
            default: "Next"
        },
        showNextBtn: {
            type: Boolean,
            default: true
        },
        showPrevBtn: {
            type: Boolean,
            default: true
        },
        enableCloseBtn: {
            type: Boolean,
            default: true
        },
        ruleType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            transitionType: {
                NEXT: "slide-next",
                PREV: "slide-previous"
            },

            curTransitionType: "slide-next"
        }
    },

    computed: {
        title() {
            switch (this.ruleType) {
                case ruleTypes.edit:
                    return "Edit mentions";
                case ruleTypes.tag:
                    return "Tagging mentions";
                case ruleTypes.crowd:
                    return "Verify specific mentions through the Crowd";
                case ruleTypes.engage:
                    return "Control DataEQ Engage processing";
                case ruleTypes.set_relevant:
                    return "Ensure certain mentions are not deleted";
                case ruleTypes.delete:
                    return "Delete unwanted mentions"
                case ruleTypes.create_crowd_job:
                    return "Create Crowd job"
                default:
                    return "";
            }
        }
    },

    methods: {
        getRuleIcon,

        next() {
            this.curTransitionType = this.transitionType.NEXT;

            this.$emit('next');
        },

        previous() {
            this.curTransitionType = this.transitionType.PREV;

            this.$emit('prev');
        },

        setTransitionToNext() {
            this.curTransitionType = this.transitionType.NEXT;
        },

        setTransitionToPrev() {
            this.curTransitionType = this.transitionType.PREV;
        }
    }
}
</script>

<style scoped lang="sass">

.add-rule-dialog

    &__workflow-container
        height: 535px
        margin-top: 20px

    &__btn-bar
        display: flex
        margin: 10px -20px -20px
        column-gap: 10px
        padding: 10px 20px
        border-top: 1px solid #272727
        background: #333333
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px

.slide-next-enter-active,
.slide-previous-enter-active
    transition: all .25s ease

.slide-next-leave-active,
.slide-previous-leave-active
    transition: none
    opacity: 0

.slide-next-enter
    transform: translateX(30px)
    opacity: 0

.slide-previous-enter
    transform: translateX(-30px)
    opacity: 0

</style>