/**
 * Displays a spinner when AJAX calls take a while + displays AJAX errors in the global error area.
 */
import {logError} from "@/app/utils/Logging";

Beef.module("Ajax").addInitializer(function(startupOptions) {


    this.updateSpinner = function() {
        $('#ajax-busy').toggleClass("off", $.active ? false : true);
    };

    let showGreatEye = function() {
        let theGreatEye = $('#the-great-eye');
        theGreatEye.show();
        theGreatEye.toggleClass("on", true);
    };
    var hideGreatEye = function() { $('#the-great-eye').toggleClass("on", false); };
    var errorTimer;

    $(document).ajaxError(function(e, jqxhr, settings, exception) {
        if (settings && settings.ignoreErrors) return;

        var isBrandError = jqxhr && jqxhr.status === 422 && jqxhr.responseText.includes("BRAND-ERROR");
        var isAbort = jqxhr && jqxhr.status === 0 && jqxhr.statusText === "abort";
        var showEye = !isBrandError && !isAbort;

        if (showEye) {
            if (errorTimer) clearTimeout(errorTimer);
            errorTimer = setTimeout(hideGreatEye, 2000);
            showGreatEye();
            setTimeout(Beef.Ajax.updateSpinner, 300);

            if (!jqxhr.responseText && !jqxhr.status) {
                if (jqxhr.readyState !== 0) { // Was the request actually sent.
                    logError({
                        message: "0 status and response",
                        type: "ajaxError",
                        url: settings.url,
                        requestType: settings.type
                    });
                }
            } else {
                logError({
                    type: "ajaxError",
                    message: jqxhr.statusText + " " + settings.type + " " + settings.url,
                    responseText: jqxhr.responseText ?? '«NONE PROVIDED FROM SERVER»',
                    url: settings.url,
                    requestType: settings.type,
                    status: jqxhr.status ?? '«NONE PROVIDED FROM SERVER»'
                });
            }
        }

    }).ajaxStart(function() {
        setTimeout(Beef.Ajax.updateSpinner, 1000);
    }).ajaxComplete(function() {
        setTimeout(Beef.Ajax.updateSpinner, 300);
    });

    // the spinner is on when the app loads so turn it off when the app has started
    Beef.on("start", function() {
        setTimeout(() => {
            Beef.Ajax.updateSpinner();
        })
    });
});
