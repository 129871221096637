import {deprecatedFetchTags} from "@/data/DeprecatedBeefCache";
import _ from 'underscore';

Beef.module("AutoTagsRequired").addInitializer(function(startupOptions) {

    this.create = function(ruleModel, cache) {
        // var model = new Backbone.Model();
        return new View({model: ruleModel, cache: cache})
    };

    var View = Backbone.Marionette.Layout.extend({
        template: require("@/setup/rules/AutoTagsRequired.handlebars"),

        modelEvents: {
        },

        events: {
            "click .tag button": "removeTag",
            "click .add-tag": "addTag",
            "click .tag-text": "editTag",
            "click .probability": "editTag"
        },

        initialize: function(options) {
            var list = this.model.get('autoTagsRequired');
            this.cache = options.cache;
            if (list) {
                // make a copy of the list as the edit rule dialog original model will be referencing the same array
                this.model.attributes.autoTagsRequired = list = list.slice();
                for (var i = 0; i < list.length; i++) {
                    var row = list[i];
                    row._tag = { id: row.tagId, name: "" + row.tagId};
                }
            }
            this._enabled = false;
            var that = this;
            deprecatedFetchTags(this, function(tags) {
                if (list) {
                    for (var i = 0; i < list.length; i++) {
                        var row = list[i];
                        var tag = tags[row.tagId];
                        if (tag) row._tag = tag;
                    }
                    that.render();
                }
                that._enabled = list || !!_.find(tags, function(tag) { return tag.autoAssign });
            });
        },

        isEnabled: function() {
            return this._enabled && this.model.get('action') !== 'ENGAGEMENT_CONSOLE';
        },

        findTagIndex: function(ev) {
            var tagId = $(ev.target).closest("tr").attr("data-value");
            if (tagId) {
                var list = this.model.get('autoTagsRequired');
                return list ? list.findIndex(function(t) { return t.tagId == tagId }) : -1;
            }
            return -1;
        },

        removeTag: function(ev) {
            ev.preventDefault();
            var i = this.findTagIndex(ev);
            if (i >= 0) {
                var list = this.model.get('autoTagsRequired');
                list.splice(i, 1);
                this.render();
            }
        },

        addTag: function(ev) {
            ev.preventDefault();
            var popup = new Beef.Popup.View({ closeOnHide: true, positions:["center"], alwaysMove: true });
            var model = new TagRequiredModel({probability: 0.5});
            var that = this;
            model.save = function() {
                var row = { tagId: parseInt(this.attributes.tagId), probability: parseFloat(this.attributes.probability) };
                row._tag = that.cache.tags[row.tagId];
                var list = that.model.get('autoTagsRequired');
                if (list) {
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].tagId === row.tagId) {
                            list[i].probability = row.probability;
                            break;
                        }
                    }
                    if (i >= list.length) list.push(row);
                } else {
                    that.model.set('autoTagsRequired', [row]);
                }
                that.render();
            };
            popup.setTarget($(ev.target));
            var view = new EditView({model: model, cache: this.cache});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        editTag: function(ev) {
            ev.preventDefault();
            var index = this.findTagIndex(ev);
            if (index < 0) return;
            var list = this.model.get('autoTagsRequired');
            var popup = new Beef.Popup.View({ closeOnHide: true, positions:["center"], alwaysMove: true });
            var e = list[index];
            var model = new TagRequiredModel({ tagId: "" + e.tagId, probability: e.probability});
            var that = this;
            model.save = function() {
                var row = { tagId: parseInt(this.attributes.tagId), probability: parseFloat(this.attributes.probability) };
                row._tag = that.cache.tags[row.tagId];
                for (var i = 0; i < list.length; i++) {
                    if (list[i].tagId === row.tagId) {
                        list[i].probability = row.probability;
                        if (i != index) list.splice(index, 1);
                        break;
                    }
                }
                that.render();
            };
            model.destroy = function() {
                list.splice(index, 1);
                that.render();
            };
            popup.setTarget($(ev.target));
            var view = new EditView({model: model, cache: this.cache});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        }
    });

    var TagRequiredModel = Backbone.Model.extend({
        validation: {
            tagId: "validateTagId",
            probability: "validateProbability"
        },

        initialize: function(options) {
        },

        validateTagId: function(v) {
            if (!v) return "Please select a tag";
        },

        validateProbability: function(v) {
            if (!v) return "Please enter a probability";
            v = parseFloat(v);
            if (v <= 0 || v > 1 || isNaN(v)) return "Probability must be between 0 and 1";
        },

        save: function() {
        }
    });

    var EditView = Beef.SettingsDialog.View.extend({
        template: require("@/setup/rules/EditAutoTag.handlebars"),

        attributes: { class: "dialog edit-auto-tag-dialog" },

        editAttributes: ['tagId', 'probability'],

        initialize: function(options) {
            Beef.SettingsDialog.View.prototype.initialize.call(this);
        },

        bindings: function() {
            return {
                tagId: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            if (!this.originalModel.get('tagId')) { this.$el.find('.delete').hide(); }

            Beef.TagPicker.attach(this, ".tagId", "tagId", {
                mustSelectFromItems: true,
                searchFilter: Beef.TagPicker.autoAssignChooser,
                startDroppedDown: true,
                noHighlightSegments: true,
                onlyOne: true
            });
        },

        initFocus: function() {
            var $input = this.$(this.model.get('tagId') ? 'input.probability' : '.tagId input');
            if ($input.length > 0) $input[0].focus()
        }
    });
});