import VuexStore from "@/store/vuex/VuexStore";
import {getDefaultBrand} from "@/app/toplevel/explore/overview/ExploreUtilities";
import {beef, bones, mash} from "@/store/Services";
import {findLandingPageErrors} from "@/app/toplevel/dashboards/DashboardUtilities";
import {notifyUser, notifyUserOfError, notifyWithText} from "@/app/framework/notifications/Notifications";
import {
    formatBrandName,
    formatDate,
    formatNumber,
    formatPercentage,
    formatPlural,
    formatUser
} from "@/app/utils/Format";
import moment from "moment";
import {profileTypes} from "@/setup/profiles/ProfileUtils";
import {isProfileExpired} from "@/app/popup/token-expire/TokenUtilities";
import {
    handleFacebookPageAuthLogin,
    handleProfileAuth
} from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import {findAllNodes, getBrandsInFilter, parseFilterString} from "@/dashboards/filter/FilterParser";
import {MentionQLexer} from "@/mentionq/mentionq";
import {checkBrands, checkTags} from "@/components/WarningIndicator";
import {getTimedLocalstorageCache, timedCacheKeyExists} from "@/data/Cache";
import {grouseGet} from "@/data/Grouse";
import {getRuleWarnings} from "@/setup/rules/RuleUtils";


let isShowingError = false;

/**
 * This will check the account for warnings that must be handled and
 * the users notified of.
 * @returns {Promise<void>}
 */
export async function notifyOfWarningsThatMustBeHandled() {
    if (!VuexStore.state.user) return;
    if (!VuexStore.state.account?.code) return;
    if (isShowingError) return;

    let fbAuthRefresh = await refreshFacebookTokenCheck().catch(e => console.error(e));
    if (fbAuthRefresh?.promptRefresh) {
        notifyUser(fbAuthRefresh?.notificationOptions);
    }
    let linkedinAuthRefresh = await refreshLinkedinAuthCheck().catch(e => console.error(e));
    if (linkedinAuthRefresh?.promptRefresh) {
        for (const notification of linkedinAuthRefresh.notificationOptions) {
            notifyUser(notification);
        }
    }

    isShowingError = true; // Ensure this code can be safely called multiple times.

    if (!VuexStore.state.user?.admin) return;

    // -----------------------
    // - Order these by severity. Things that effect data collection should be near the top.
    // - Decide if a warning should stop other warnings from happening.


    const account = VuexStore.state.account;
    if (account.inactive) {
        let description = "This account is inactive";
        if (account.lastUpdated) {
            const deletionDate = moment(account.lastUpdated).add(1, 'year');
            description += " and <strong>will be automatically deleted</strong> on or after " + deletionDate.format("YYYY-MM-DD");
        }
        notifyUserOfError(description, true); // Show this with other errors.
    }

    let warnings = await checkBrandVolumes();
    if (warnings?.length) {
        let volumeWarnings = warnings.filter(w => w.id === "BRAND_VOLUME_OVER_LIMIT");
        let tempVolumeWarnings = warnings.filter(w => w.id === "BRAND_VOLUME_OVER_TEMP_LIMIT");

        if (volumeWarnings?.length) {
            notifyUserOfError(`${volumeWarnings.length} ${formatPlural(volumeWarnings.length, `brand is above its`, 'brands are above their')} volume limit`, true, true);
        }
        if (tempVolumeWarnings?.length) {
            notifyUserOfError(`${tempVolumeWarnings.length} ${formatPlural(tempVolumeWarnings.length, `brand is above its`, 'brands are above their')} temporary volume limit`, true, true);
        }
        return;
    }

    warnings = await checkAccountExpiredProfiles();
    if (warnings?.length) {
        // show warning if hasn't been show in last 3 hours
        let timedProfileExpireKey = "account-health:notifications:expired-profiles:seen"
        if (!timedCacheKeyExists(timedProfileExpireKey)) {
            await getTimedLocalstorageCache(timedProfileExpireKey, () => {return true}, moment().add(3, 'hours').format("YYYY-MM-DD HH:mm"));
            notifyUserOfError(warnings[0].notification ?? warnings[0].description, true, true);
        }
        // return; No return here. Do not want these to stop other errors from firing.
    } else {
        warnings = await checkLinkedinAuthorisation();
        if (warnings?.length) {
            const message = `This account has <strong>unauthorised <i class="symbol-linkedin-rect"></i>LinkedIn profiles</strong>.
                        These profiles will pull in no mentions.`;
            notifyUserOfError(message, true, true);
            // return; No return here. Do not want these to stop other errors from firing.
        }
    }

    warnings = await findLandingPageErrors(true);
    if (warnings?.length) {
        notifyUserOfError(warnings[0].notification ?? warnings[0].description, true, false);
        return;
    }

    warnings = await checkImportedBrands(true);
    if (warnings?.length) {
        notifyUserOfError(warnings[0].notification ?? warnings[0].description, true, false);
        return;
    }
}

/**
 * These tests {@link Account} objects directly for errors. It does not
 * test anything that is not on an account object itself.
 * @return {Promise<*[]>}
 */
export async function checkAccount() {
    await VuexStore.dispatch("refreshAccount");
    /** @type {Account} */
    const account = VuexStore.state.account;
    let warnings = [];

    if (!account.hasFacebookDataAuthorisation) {
        warnings.push({
            id: "NO_FACEBOOK_AUTHORISATION",
            description: "This account is not authorised to collect facebook data"
        })
    }

    warnings = [
        ...warnings
    ];

    return warnings;
}

export async function checkMissingBrandCategories() {
    await VuexStore.dispatch("refreshBrands");
    const rootBrands = VuexStore.state.rootBrands;
    if (!rootBrands?.length) return [];

    const someHaveCategories = rootBrands.some(b => b.category);
    if (!someHaveCategories) return [];

    const warnings = [];
    for (const brand of rootBrands) {
        if (brand.archived || brand.deleted) continue;
        if (!brand.category) {
            warnings.push({
                id: "NO_BRAND_CATEGORY",
                object: brand,
                description: "These brands have not been categorised as OWN or COMPETITOR",
                fix: "Set the category of these brands, such as either OWN or COMPETITOR"
            })
        }
    }

    return warnings;
}

/**
 * Runs tests on imported brands
 * @param {boolean} [stopAtFirst = false]
 * @returns {Promise<*[]>}
 */
export async function checkImportedBrands(stopAtFirst) {
    stopAtFirst ??= false;
    if (!VuexStore.state.user.admin) return [];

    await VuexStore.dispatch("refreshBrands");
    const warnings = [];
    const rootBrands = VuexStore.state.rootBrands;

    if (rootBrands) {
        for (const brand of rootBrands) {
            if (!brand.importedFromAccount) continue;
            const response = await mash.get(`/rest/accounts/${brand.importedFromAccount}`);
            const foreignAccount = response.data;
            if (foreignAccount.inactive) {
                warnings.push({
                    id: "IMPORTED_FROM_INACTIVE",
                    description: "These brands are imported from an inactive account",
                    fix: "Reactivate the accounts the brands are from before they are deleted",
                    object: brand,
                    notification: `Brand <strong>${formatBrandName(brand)}</strong> is imported from an inactive account. Please have this fixed.`
                });
                if (stopAtFirst) break;
            }
        }
    }

    return warnings;
}


async function checkOwnBrands() {
    await VuexStore.dispatch("refreshBrands");

    const warnings = [];
    const rootBrands = VuexStore.state.rootBrands;
    const account = VuexStore.state.account;
    const own = rootBrands.filter(b => b.category === "OWN" && !b.archived && !b.deleted);
    const numActiveBrands = rootBrands.filter(b => !b.archived && !b.deleted);

    if (numActiveBrands.length !== 1 && !account?.defaultBrand) {
        if (!own.length) {
            warnings.push({
                id: "NO_OWN",
                description: "No own brands have been set",
                fix: "Either set own brands (make sure that they are not archived or deleted brands), or ensure that the account has a default brand set."
            })
        } else if (own.length > 1) {
            warnings.push({
                id: "NO_DEFAULT",
                description: "Multiple own brands set, but no default brand",
                fix: "If possible, set one of the own brands as a default brand for the account"
            })
        }
    }

    return warnings;
}

async function checkDefaultBrand() {
    const defaultBrand = await getDefaultBrand();
    if (defaultBrand && !defaultBrand.crowdSamplePercentage) {
        return [{
            id: "INITIAL_BRAND_NO_CROWD",
            object:  defaultBrand,
            description: "The initial brand does not have any Crowd sampling, so will not show sentiment, CX, etc",
            fix: "Set a default brand for the account which does have Crowd sampling (make sure the choice of default brand makes sense for the client as well)"
        }]
    }

    return [];
}

async function checkSomeCrowd() {
    let someHaveCrowd = false;

    await VuexStore.dispatch("refreshBrands");
    const brands = VuexStore.state.rootBrands;

    for (const brand of brands) {
        if (brand.deleted) continue;
        if (brand.archived) continue;

        if (brand.crowdSamplePercentage) {
            someHaveCrowd = true;
            break;
        }
    }

    if (!someHaveCrowd) {
        return [{
            id: "NO_CROWD",
            description: "This account does not have any ongoing Crowd sampling, so there is no sentiment to show"
        }]
    }

    return [];
}

export async function checkBrandNames() {
    await VuexStore.dispatch("refreshBrands");
    const brands = VuexStore.state.rootBrands;

    const warnings = [];
    for (const brand of brands) {
        if (brand.deleted) continue;
        if (brand.archived) continue;

        if (brand.name?.indexOf('(') > -1 && !brand.shortName) {
            warnings.push({
                id: "NO_CLIENT_NAME",
                object: brand,
                description: "These brands do not have client facing names",
                fix: "Add a client facing name in the brand setup. It should not include any Crowd context."
            });
        }
    }

    return warnings;
}

async function checkBrandProfiles() {
    await VuexStore.dispatch("refreshBrands");
    await VuexStore.dispatch("profiles/refreshProfiles");

    const idToProfile = VuexStore.getters["profiles/idToProfile"];
    const brands = VuexStore.state.rootBrands;

    const warnings = [];
    for (const brand of brands) {
        if (brand.deleted) continue;
        if (brand.archived) continue;

        if (brand.category === "OWN" || brand.category === "COMPETITOR") {
            if (!brand.supportProfileIds?.length && !brand.otherProfileIds?.length) {
                if (brand.category === "OWN") {
                    warnings.push({
                        id: "OWN_NO_PROFILES",
                        object: brand,
                        description: "These OWN brands do not have any online profiles associated with them",
                        fix: "Add support and other profiles in the brand setup."
                    });
                } else {
                    warnings.push({
                        id: "COMPETITOR_NO_PROFILES",
                        object: brand,
                        description: "These COMPETITOR brands do not have any associated profiles",
                        fix: "Add support and other profiles in the brand setup."
                    });
                }
            }

            if (brand.supportProfileIds?.length) {
                let supportNotEnterprise = [];
                for (const id of brand.supportProfileIds) {
                    const profile = idToProfile.get(id);
                    if (profile) {
                        if (profile?.media !== "ENTERPRISE") {
                            supportNotEnterprise.push(profile.handle);
                        }
                    } else {
                        warnings.push({
                            id: "PROFILE_NOT_EXIST",
                            description: "The following brands have profiles that do not exist",
                            object: brand,
                            comment: `Support profile with id [${id}] does not exist`,
                            fix: "Remove these profiles and figure out what has happened to them"
                        })
                    }
                }

                if (supportNotEnterprise.length) {
                    warnings.push({
                        id: "SUPPORT_NOT_ENTERPRISE",
                        description: "The following brands have support profiles that are not marked as Enterprise",
                        object: brand,
                        comment: `support profiles: ${supportNotEnterprise.join(', ')}`,
                        fix: "Edit these profiles and set them to Enterprise"
                    })
                }
            }
        }
    }

    return warnings;
}

export async function checkBrandVolumes() {
    const warnings = [];

    try {
        await VuexStore.dispatch("refreshBrands");
        const account = VuexStore.state.account;
        const brands = VuexStore.state.rootBrands;

        let params = {
            groupby: "pickedup[month]",
            from: moment().startOf('month').format("YYYY/MM/DD"),
            to: moment().endOf('month').format("YYYY/MM/DD")
        };

        const response = await grouseGet(`/v4/accounts/${account.code}/volume`, params);
        let brandMonthVolumeUsageMap = response?.results?.at(0)?.totalByBrand ?? {};

        brands.forEach(brand => {
            let warning = getBrandVolumeWarning(brand, brandMonthVolumeUsageMap[brand.id], brand.volumeLimit, brand.tempVolumeLimit);
            if (warning) warnings.push(warning);
        });
    } catch (e) {
        console.warn("Error checking brand volumes: ", e);
    }

    return warnings;
}

export function getBrandVolumeWarning(brand, brandVolumeUsage, volumeLimit, tempVolumeLimit) {
    let warning = null;

    if (!brand || !brandVolumeUsage) return warning;

    if (brand.archived || brand.deleted) return warning;

    if (brandVolumeUsage > volumeLimit) {
        if (!tempVolumeLimit) {
            warning = {
                id: "BRAND_VOLUME_OVER_LIMIT",
                object: brand,
                description: "The following brands are over their volume limit",
                notification: `${brand.name} is has exceeded their monthly volume limit.`
            }
        } else if (brandVolumeUsage > tempVolumeLimit) {
            warning = {
                id: "BRAND_VOLUME_OVER_TEMP_LIMIT",
                object: brand,
                description: "The following brands are over their temporary volume limit",
                notification: `${brand.name} is has exceeded their temporary volume limit.`
            }
        }
    }

    return warning;
}

export async function checkAccountEnterpriseProfilesPhrases() {
    await VuexStore.dispatch("profiles/refreshProfiles");
    const profiles = VuexStore.state.profiles.profiles;

    const warnings = [];

    for (const profile of profiles) {
        if (!profile.deleted) {
            if (profile.media === "ENTERPRISE" && !profile.brands) {
                warnings.push({
                    id: "ENTERPRISE_PROFILES_NO_PHRASES",
                    object: profile,
                    description: "The following enterprise profiles are not phrase matched to any brands",
                    fix: "Phrases should be added for these profiles to ensure that we are collecting all data relevant to them."
                });
            }
        }
    }

    return warnings;
}

export async function checkAccountExpiredProfiles() {
    await VuexStore.dispatch("profiles/refreshProfiles");
    const profiles = VuexStore.state.profiles.profiles;

    const warnings = [];
    let expiredProfileCount = 0;
    for (const profile of profiles) {
        if (profile.authorized && profile.tokenExpire) {
            if (isProfileExpired(profile)) {
                expiredProfileCount++;
            }
        }
    }

    if (expiredProfileCount > 0) {
        warnings.push({
            id: "EXPIRED_PROFILES",
            notification: `This account has <strong>${expiredProfileCount} ${formatPlural(expiredProfileCount, "profile")}</strong> with <strong>expired authorisation</strong>. Please ask clients to reauthorise these profiles 
                                    or unauthorise them if they should no longer be authorised.`
        });
    }

    return warnings;
}

/**
 * check for unauthorised linkedin company profiles
 * @return {Promise<void>}
 */
export async function checkLinkedinAuthorisation() {
    await VuexStore.dispatch("profiles/refreshProfiles");
    const linkedinProfiles = VuexStore.getters["profiles/getLinkedinProfiles"];

    const warnings = [];
    for (const profile of linkedinProfiles) {
        if (!profile.deleted) {
            if (!profile.authorized) {
                warnings.push({
                    id: "UNAUTH_LINKEDIN_PROFILES",
                    object: profile,
                    description: "These LinkedIn company profiles are not authorised and therefore cannot collect data",
                    fix: "Each LinkedIn company profile should be authorised by the user who is an administrator of the profile."
                });
            }
        }
    }

    return warnings;
}

export async function checkAccountProfiles() {
    await VuexStore.dispatch("profiles/refreshProfiles");
    const profiles = VuexStore.state.profiles.profiles;

    let warnings = [];
    for (const profile of profiles) {
        if (profile.deleted) continue;

        // check for profiles with expired authorisation
        if (profile.authorized && profile.tokenExpire) {
            if (isProfileExpired(profile)) {
                let comment;
                if (profile.authorizedBy) {
                    comment = `Authorised by ${profile.userHandleId ? 'influencer' : formatUser(profile.authorizedBy)}. Expired on ${formatDate(profile.tokenExpire, 'DD MMMM YYYY, HH:mm')}`;
                } else {
                    comment = `Expired on ${formatDate(profile.tokenExpire, 'DD MMMM YYYY, HH:mm')}`;
                }
                warnings.push({
                    id: "EXPIRED_PROFILES",
                    object: profile,
                    comment,
                    description: "The authorisation of these profiles has expired, this will affect data collection",
                    fix: "If these profiles should be authorised, they should be reauthorised by the users who are administrators of them. Otherwise, the profiles should be unauthorised."
                });
            }
        }

        // check for unsupported profile types
        if (profile.type === profileTypes.instagram_user) {
            warnings.push({
                id: "UNSUPPORTED_PROFILE_TYPES",
                object: profile,
                description: `We are unable to collect data from these profiles as they are of a type that is no longer supported (${profileTypes.instagram_user}).`,
                fix: `These profiles should be deleted. If data should be collected from these profiles, they need to be re-added to the account as ${profileTypes.instagram_business} profiles and authorised.`
            });
        }

        // check for profiles with no category
        if (!profile.media) {
            warnings.push({
                id: "NO_CATEGORY_PROFILES",
                object: profile,
                description: "These profiles don't have a category set",
                fix: "Set each profile's category."
            });
        }

        // check for authorised Twitter profiles that don't have DM's enabled
        if (profile.type === profileTypes.twitter) {
            if (profile.authorized && !profile.directMessagesEnabled) {
                warnings.push({
                    id: "NO_DM_AUTH_TWITTER_PROFILES",
                    object: profile,
                    description: "These authorised Twitter profiles do not have DM's enabled",
                    fix: "If DM's should not be collected for a Twitter profile, then the profile can be unauthorised. Otherwise, DM's should be enabled."
                });
            }
        }
    }

    warnings = warnings.concat(await checkLinkedinAuthorisation()).concat(await checkAccountEnterpriseProfilesPhrases());
    return warnings;
}

export async function refreshLinkedinAuthCheck() {
    await VuexStore.dispatch("profiles/refreshProfiles");
    const linkedinProfiles = VuexStore.getters["profiles/getLinkedinProfiles"];

    let profilesToReauth = [];

    if (linkedinProfiles) {
        let userAuthorisedLinkedinProfiles = linkedinProfiles.filter(profile => profile.authorized && profile.authorizedById === VuexStore.state.user.id);

        if (userAuthorisedLinkedinProfiles && userAuthorisedLinkedinProfiles?.length > 0) {
            // check if any linkedin profiles that user has authorised is about to expire
            for (const linkedinProfile of userAuthorisedLinkedinProfiles) {
                if (linkedinProfile.tokenExpire) {
                    let monthsTillPageTokenExpire = moment(linkedinProfile.tokenExpire).diff(moment.now(), 'months', true);

                    // if linkedin profile token will expire in less than 0.5 months / have expired already, show the notification
                    if (monthsTillPageTokenExpire < 0.5) {
                        profilesToReauth.push(linkedinProfile);
                    }
                }
            }
        }
    }

    let promptRefresh = profilesToReauth.length > 0;
    let notifications = [];

    if (promptRefresh) {
        for (const linkedinProfile of profilesToReauth) {
            let notification = {
                message: `Authorisation of Linkedin Company profile ${linkedinProfile.name} has expired or will expire soon - this will affect our ability to collect data from it. To refresh the profile's authorisation, click "Refresh auth".`,
                longDelay: true,
                icon: "<i class='symbol-linkedin'></i>",
                action: {
                    name: "Refresh auth",
                    method: async () => {
                        try {
                            handleProfileAuth(linkedinProfile.handleId, linkedinProfile.handle, profileTypes.linkedin, callbackResponse => {
                                if (callbackResponse["auth"] === "success") {
                                    this.triggerFetchProfiles(true);

                                    notifyWithText(`${linkedinProfile.name} successfully reauthorised.`,
                                        null,
                                        "<i class='symbol-linkedin-rect'></i>");

                                } else {
                                    if (callbackResponse["errorCode"] && callbackResponse["errorCode"] === 403) {
                                        notifyUserOfError(`An error occurred while trying to reauthorise profile ${linkedinProfile.name}: you are not an admin of the profile that you are trying to authorise.`);
                                    } else {
                                        notifyUserOfError(`An error occurred while trying to reauthorise profile ${linkedinProfile.name}: ${callbackResponse["message"]}`);
                                    }
                                }
                            }).catch(e => console.error(e));
                        } catch (e) {
                            console.error(`Error occurred while trying to refresh linked profile auth for ${linkedinProfile.handleId}:`, e);

                            notifyUserOfError(`Failed to reauthorise Linkedin Company profile ${linkedinProfile.name}. Please refresh the page and try again or contact support.`);
                        }
                    }
                }
            }

            notifications.push(notification);
        }

    }

    return { promptRefresh: promptRefresh, notificationOptions: notifications }
}

export async function refreshFacebookTokenCheck() {
    await VuexStore.dispatch("userPlatformAuth/initializeFbUserToken");
    const facebookUserToken = VuexStore.state.userPlatformAuth.facebookUserToken;
    const facebookUserTokenInvalid = VuexStore.state.userPlatformAuth.facebookUserTokenInvalid;
    await VuexStore.dispatch("profiles/refreshProfiles");
    const facebookPages = VuexStore.getters["profiles/getFacebookPagesAndInstaProfiles"];

    let promptRefresh = false;

    if (facebookPages) {
        let userAuthorisedFbPages = facebookPages.filter(page => page.authorized && page.authorizedById === VuexStore.state.user.id);

        // check if the user has authorised any Facebook pages/Instagram Business profiles
        if (userAuthorisedFbPages && userAuthorisedFbPages?.length > 0) {

            // check if any facebook pages/instagram business profiles that user has authorised is about to expire
            for (const facebookPage of userAuthorisedFbPages) {
                if (facebookPage.tokenExpire) {
                    let monthsTillPageTokenExpire = moment(facebookPage.tokenExpire).diff(moment.now(), 'months', true);

                    // if facebook page tokens will expire in less than 0.5 months / have expired already, show the notification
                    if (monthsTillPageTokenExpire < 0.5) {
                        promptRefresh = true;
                        break;
                    }
                }
            }

            // if we haven't found profiles that are about to expire, perform checks on their user token
            if (!promptRefresh) {
                // check if user has a valid Facebook user token
                if (facebookUserToken) {
                    // check age of facebook user token
                    let fbUserTokenMonthAge = Math.abs(moment(facebookUserToken.lastUpdated).diff(moment.now(), 'months', true));

                    // if the user's Facebook user token is older than 2.5 months, show notification
                    if (fbUserTokenMonthAge > 2.5) {
                        promptRefresh = true;
                    }
                } else if (facebookUserTokenInvalid) { // if the facebook user token is invalid, show the notification
                    promptRefresh = true;
                }
            }
        }
    }

    let notificationOptions = null;

    if (promptRefresh) {
        notificationOptions = {
            message: `You have Facebook pages/Instagram Business profiles that have expired authorisation or will have their authorisation expire soon - this will affect our ability to collect data from them. To refresh their authorisation, click "Refresh auth".`,
            longDelay: true,
            icon: "<i class='symbol-facebook-rect'></i>",
            action: {
                name: "Refresh auth",
                method: async () => {
                    try {
                        handleFacebookPageAuthLogin(callbackResponse => {
                            if (callbackResponse["auth"] === "success") {
                                notifyWithText("Facebook login successful refreshed!",
                                    null,
                                    "<i class='icon-facebook'></i>");
                            } else {
                                console.error("Error occurred while trying to refresh user Facebook login");
                                notifyWithText("Failed to refresh Facebook login. Please refresh the page and try again or contact support.",
                                    null,
                                    "<i class='icon-facebook'></i>");
                            }
                        }).catch(e => console.error(e));
                    } catch (e) {
                        console.error("Error occurred while trying to refresh user Facebook login:", e);

                        notifyUserOfError("Failed to refresh Facebook login. Please refresh the page and try again or contact support.");
                    }
                }
            }
        }
    }

    return { promptRefresh: promptRefresh, notificationOptions: notificationOptions }
}

export async function checkSorter() {
    await VuexStore.dispatch("refreshBrands");
    const response = await bones.get(`/rest/v1/accounts/${VuexStore.state.account.code}/sorter`);
    const data = response.data;
    // Let's see what brands got sorter errors
    const warnings = [];
    const lastBrands = data.filter(d => moment(d.date).isSameOrAfter(moment().startOf("day")));

    lastBrands
        .filter(d => d.falseIrrelevantRate > 0.0 || d.error)
        .forEach(e => {

            let comment = e.error ? e.error : ` has error rate of ${formatPercentage(e.falseIrrelevantRate * 100, 1)}`;

            warnings.push({
                id: "SORTER_ERROR",
                description: "The following brands have errors with the relevancy predictor",
                comment,
                object: VuexStore.getters.idToBrand.get(e.brandId)
            })
        });

    // If the model is null then the unsorted warning applies
    lastBrands
        .filter(d => !d.model)
        .forEach(e => {
            warnings.push({
                id: "UNSORTED",
                description: "The following brands have unsorted mentions",
                comment: 'No model available, all mentions left UNSORTED',
                object: VuexStore.getters.idToBrand.get(e.brandId)
            })
        });

    return warnings;
}

export async function checkBrandCrowdRules() {
    await VuexStore.dispatch("refreshBrands");
    const brands = VuexStore.state.rootBrands;
    const brandsWithSampling = brands?.filter(b => b.crowdSamplePercentage > 0);
    if (!brandsWithSampling?.length) return [];

    await VuexStore.dispatch("refreshRules");
    const rules = VuexStore.state.rules;
    const crowdRules = rules?.filter(r => r.action === "CROWD" && r.active);

    if (!crowdRules?.length) return [];

    const brandsInCrowdRules = new Set();
    let oneChecksEverything = false;
    for (const rule of crowdRules) {
        const includedBrands = getBrandsInFilter(rule.filter)?.include ?? [];
        if (!includedBrands.length) oneChecksEverything = true;
        includedBrands.forEach(b => brandsInCrowdRules.add(b));
    }

    const warnings = [];
    if (!oneChecksEverything) {
        for (const brand of brandsWithSampling) {
            if (!brandsInCrowdRules.has(brand.id)) {
                warnings.push({
                    id: "BRAND_EXCLUDED_FROM_CROWD",
                    object: brand,
                    comment: "Sampling rate of " + formatPercentage(brand.crowdSamplePercentage),
                    description: "These brands have crowd sampling, but Crowd rules exclude them from going to the Crowd"
                })
            }
        }
    }

    return warnings;
}

export async function checkBrandsForExplore() {
    await VuexStore.dispatch("refreshBrands");

    let warnings = [];
    const rootBrands = VuexStore.state.rootBrands;

    if (!rootBrands?.length) {
        warnings.push({
            id: "NO_BRANDS",
            description: "This account has no brands"
        });

        return warnings;
    }

    warnings = [
        ...warnings,
        ...(await checkSomeCrowd()),
        ...(await checkOwnBrands()),
        ...(await checkDefaultBrand()),
        ...(await checkBrandNames()),
        ...(await checkBrandProfiles()),
    ];

    return consolidateWarnings(warnings);
}

/**
 * Given a list of warnings, this removes all warnings that are handled (subsumed) by other, broader, warnings.
 * @param {any[]} warnings - list of warnings
 * @return {any[]}
 */
export function consolidateWarnings(warnings) {
    if (!warnings?.length) return [];

    // Let them inherit from their prototype parent.
    warnings.forEach(w => {
        const parent = ALL_HEALTH_WARNINGS[w.id];
        if (parent) {
            w.__proto__ = parent;
        }
    });

    const subsumed = new Set(warnings
        .filter(w => {
            if (!ALL_HEALTH_WARNINGS[w.id]) console.error("Unrecognised warning ID", w.id);
            return ALL_HEALTH_WARNINGS[w.id]?.subsumes?.length
        })
        .flatMap(w =>  ALL_HEALTH_WARNINGS[w.id]?.subsumes ?? [])
    );

    return sortWarnings(warnings.filter(w => !subsumed.has(w.id)));
}

/**
 *
 * @param {any[]} warnings
 * @return {any[]}
 */
export function sortWarnings(warnings) {
    function sorter(lhs, rhs) {
        const lhsPriority = ALL_HEALTH_WARNINGS[lhs.id]?.priority ?? Number.MAX_VALUE;
        const rhsPriority = ALL_HEALTH_WARNINGS[rhs.id]?.priority ?? Number.MAX_VALUE;

        return lhsPriority - rhsPriority;
    }

    return warnings.sort(sorter);
}

/**
 * Counts unique warnings based on warning IDs.
 * @param {Object[]} warnings
 * @return {number}
 */
export function countUniqueWarnings(warnings) {
    if (!warnings?.length) return 0;
    const seen = new Set();
    const user = VuexStore.state.user;
    for (const warning of warnings) {
        if (user.admin || ALL_HEALTH_WARNINGS[warning.id].clientVisible) {
            seen.add(warning.id);
        }
    }

    return seen.size;
}

/**
 * Looks for errors in notifications that are NOT volume spike notifications
 */
export async function getNotificationWarnings(notification) {
    const warnings = [];
    const filter = notification.filter;
    if (filter) {
        const tags = await checkTags(filter, (id) => {
            switch (id) {
                case 'MISSING_TAGS':
                    return "This notification filters on <strong>deleted tags</strong>";
                case 'MISSING_TOPICS':
                    return "This notification filters on <strong>deleted topics</strong>";
                case 'MISSING_SEGMENTS':
                    return "This notification filters on <strong>deleted CX or Conduct tags</strong>";
                default:
                    return null;
            }
        });

        const brands = await checkBrands(filter, (id) => {
            if (id === 'MISSING_BRAND') return "This notification filers on <strong>deleted brands</strong>";
            return null;
        });

        const name = (notification.name ?? "").trim().toLowerCase();
        await VuexStore.dispatch('digests/refreshDigests');
        const duplicateName = VuexStore.state.digests.digests
            .find(d => d.id !== notification.id &&
                !notification.externalId &&
                (d.name ?? "").trim().toLowerCase() === name);

        if (duplicateName) {
            warnings.push({
                id: 'DUPLICATE_NAME',
                message: "This notification has <strong>the same name</strong> as another notification"
            });
        }

        tags.forEach(w => warnings.push(w));
        brands.forEach(w => warnings.push(w));
    }

    if (notification.reportId) {
        await VuexStore.dispatch('dashboards/refreshDashboards');

        const idToDashboard = VuexStore.getters["dashboards/idToDashboard"];
        const exists = idToDashboard.has(notification.reportId);
        const item = idToDashboard.get(notification.reportId);

        if (!exists || item.deleted) {
            warnings.push({
                id: 'MISSING_DASHBOARD',
                message: "This notification is using a <strong>deleted dashboard</strong>"
            });
        }
    }

    return warnings;
}

export async function checkVolumeSpikeNotifications(notification, optionalCopyFunction) {
    const getCopy = (id, defaultValue) => optionalCopyFunction ? optionalCopyFunction(id) || defaultValue : defaultValue;
    const warnings = [];
    const filter = notification.filter;
    if (filter) {
        const brands = getBrandsInFilter(filter);
        if (!brands.include || !brands.include.length)
            warnings.push({
                id: 'ALERT_NO_BRAND',
                message: getCopy(
                    'ALERT_NO_BRAND',
                    "This notification is <strong>missing a brand</strong> in its filter"
                )
            });

        const node = parseFilterString(filter);
        const published = findAllNodes(node, function (n) {
            return n.operandType === MentionQLexer.PUBLISHED;
        }) || [];
        if (published.length) {
            warnings.push({
                id: 'ALERT_HAS_PUBLISHED',
                message: getCopy(
                    'ALERT_HAS_PUBLISHED',
                    "This notification has <strong>published date</strong> that should be removed"
                )
            });
        }

        const notificationWarnings = await getNotificationWarnings(notification);
        notificationWarnings.forEach(w => warnings.push(w));

        return warnings;
    }

    return warnings;
}

export async function checkNotifications() {
    await VuexStore.dispatch('digests/refreshDigests');
    await VuexStore.dispatch('dashboards/refreshDashboards');
    const notifications = VuexStore.state.digests.digests;
    const idToDashboard = VuexStore.getters["dashboards/idToDashboard"];

    if (!notifications?.length) return [];

    let warnings = [];

    let nameCount = new Map();
    const cleanName = name => (name ?? "").trim().toLowerCase();

    for (const notification of notifications) {
        if (notification.externalId || !notification.active || notification.deleted) continue;
        const name = cleanName(notification.name);
        nameCount.set(name, (nameCount.get(name) ?? 0) + 1);
    }

    const nameAlreadyWarned = new Set();
    for (const notification of notifications) {
        if (!notification.active) continue;
        if (notification.deleted) continue;

        if (!notification.externalId) {
            const name = cleanName(notification.name);
            if (!nameAlreadyWarned.has(name)) {
                if (nameCount.get(name) > 1) {
                    warnings.push({
                        id: 'notification:DUPLICATE_NAME',
                        description: "These notifications have duplicate names",
                        object: notification,
                        fix: "Change their names so that they are unique"
                    });
                }

                nameAlreadyWarned.add(name);
            }
        }

        if (notification.reportId) {
            const exists = idToDashboard.has(notification.reportId);
            const item = idToDashboard.get(notification.reportId);

            if (!exists || item.deleted) {
                warnings.push({
                    id: 'notification:MISSING_DASHBOARD',
                    object: notification,
                    description: "These notifications use dashboards that have been deleted"
                });
            }
        }

        if (notification.filter) {
            if ((await checkBrands(notification.filter))?.length || (await checkTags(notification.filter))?.length) {
                warnings.push({
                    id:'notification:BAD_FILTER',
                    object: notification,
                    description: "These notifications have broken filters",
                    fix: "Check their filters. Common problems might be filters using deleted brands, tags and topics"
                });
            }
        }
    }

    return warnings;
}

export async function checkRules() {
    await VuexStore.dispatch('refreshRules');
    const rules = VuexStore.state.rules;

    let warnings = [];

    rules?.forEach(rule => {
        let ruleWarnings = getRuleWarnings(rule);
        let ruleFilterWarnings = ruleWarnings?.filter(warning => warning.type === "FILTER_WARNING");

        if (ruleFilterWarnings?.length) {
            warnings.push({
                id:'rule:BAD_FILTER',
                object: rule,
                description: "These rules have filters that will not mention match correctly",
                fix: "Check the filter of these rules in the rules panel. The filter either contains an invalid attribute, or the filter itself is invalid."
            })
        }
    });

    return warnings;
}

// ---------------------------------------------------------------------------------------------------------------------

class AccountWarning {
    /** @type {boolean} */
    hide = false;
    /** @type {number|null} */
    priority = 50;
    /** @type {string|null} */
    description;
    /** @type {Array<string>|null} */
    subsumes;
    /** @type {boolean} */
    clientVisible = false;

    constructor(description) {
        this.description = description ?? null;
    }

    withPriority(priority) {
        this.priority = priority;
        return this;
    }

    doesSubsume(subsumes) {
        this.subsumes = subsumes;
        return this;
    }

    isHidden() {
        this.hide = true;
        return this;
    }

    isClientVisible() {
        this.clientVisible = true;
        return this;
    }
}

const NOTIFICATION_WARNINGS = {
    'notification:DUPLICATE_NAME': new AccountWarning().isHidden().isClientVisible(),
    'notification:MISSING_DASHBOARD': new AccountWarning().isHidden().isClientVisible(),
    'notification:BAD_FILTER': new AccountWarning().isHidden().isClientVisible(),
};

const RULE_WARNINGS = {
    'rule:BAD_FILTER': new AccountWarning("All rule filters are valid").withPriority(10)
}

/**
 * An overview of health checks. They can have the following parameters:
 * @type {Object.<string, AccountWarning>}
 */
export const ALL_HEALTH_WARNINGS = {
    IS_INACTIVE: new AccountWarning().isHidden(),

    NO_FACEBOOK_AUTHORISATION: new AccountWarning("This account has been authorised to collect Facebook data")
        .withPriority(1)
        .isClientVisible(),

    NO_BRANDS: new AccountWarning()
        .isHidden()
        .withPriority(10)
        .isClientVisible(),

    IMPORTED_FROM_INACTIVE: new AccountWarning().isHidden().withPriority(10),
    NO_CLIENT_NAME: new AccountWarning("All brands have appropriate client-facing names"),
    NO_OWN: new AccountWarning("Own brands: There either are OWN brands, or a default brand, to be used"),
    NO_DEFAULT: new AccountWarning("Default brand: There either are OWN brands, or a default brand, to be used"),
    COMPETITOR_NO_PROFILES: new AccountWarning("Competitor profiles have associated support and other profiles"),
    OWN_NO_PROFILES: new AccountWarning("All OWN brands have associated support and other profiles").withPriority(20),
    NO_CROWD: new AccountWarning("Brands in this account are getting Crowd verified data").doesSubsume(["INITIAL_BRAND_NO_CROWD"]).withPriority(20),
    INITIAL_BRAND_NO_CROWD: new AccountWarning().isHidden().withPriority(20),
    PROFILE_NOT_EXIST: new AccountWarning().isHidden(),
    SUPPORT_NOT_ENTERPRISE: new AccountWarning("All support profiles are set to be Enterprise"),
    LANDING_PAGE_ERROR: new AccountWarning("Landing pages have no warnings"),
    NO_BRAND_CATEGORY: new AccountWarning().isHidden(),
    SORTER_ERROR: new AccountWarning("All brands have a well functioning relevancy predictor").withPriority(15),
    UNSORTED: new AccountWarning().isHidden().withPriority(15),
    UNSUPPORTED_PROFILE_TYPES: new AccountWarning("All profiles on the account are of a type that is supported").withPriority(15),
    ENTERPRISE_PROFILES_NO_PHRASES: new AccountWarning("All enterprise profiles on the account are phrase matched to at least one brand.").withPriority(10),

    BRAND_VOLUME_OVER_LIMIT: new AccountWarning().isHidden().withPriority(10),
    BRAND_VOLUME_OVER_TEMP_LIMIT: new AccountWarning().isHidden().withPriority(10),

    UNAUTH_LINKEDIN_PROFILES: new AccountWarning("All LinkedIn company profiles are authorised")
        .withPriority(11)
        .isClientVisible(),

    EXPIRED_PROFILES: new AccountWarning("There are no profiles with expired authorisation")
        .withPriority(2)
        .isClientVisible(),

    NO_CATEGORY_PROFILES: new AccountWarning("All profiles have a category set"),

    NO_DM_AUTH_TWITTER_PROFILES: new AccountWarning("All authorised Twitter profiles have DM's enabled")
        .withPriority(11)
        .isClientVisible(),

    BRAND_EXCLUDED_FROM_CROWD: new AccountWarning().isHidden().withPriority(11),

    ...NOTIFICATION_WARNINGS,
    ...RULE_WARNINGS
};



export const ALL_EXPLORE_WARNINGS = {
    NO_BRANDS: ALL_HEALTH_WARNINGS['NO_BRANDS'],
    NO_CLIENT_NAME: ALL_HEALTH_WARNINGS['NO_CLIENT_NAME'],
    NO_OWN: ALL_HEALTH_WARNINGS['NO_OWN'],
    NO_DEFAULT: ALL_HEALTH_WARNINGS['NO_DEFAULT'],
    COMPETITOR_NO_PROFILES: ALL_HEALTH_WARNINGS['COMPETITOR_NO_PROFILES'],
    OWN_NO_PROFILES: ALL_HEALTH_WARNINGS['OWN_NO_PROFILES'],
    NO_CROWD: ALL_HEALTH_WARNINGS['NO_CROWD'],
    INITIAL_BRAND_NO_CROWD: ALL_HEALTH_WARNINGS['INITIAL_BRAND_NO_CROWD'],
    PROFILE_NOT_EXIST: ALL_HEALTH_WARNINGS['PROFILE_NOT_EXIST'],
    SUPPORT_NOT_ENTERPRISE: ALL_HEALTH_WARNINGS['SUPPORT_NOT_ENTERPRISE'],
};

