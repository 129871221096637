import {appendSegmentRestrictions, getAllCxSegmentLists} from "@/app/utils/Segments";
import VuexStore from "@/store/vuex/VuexStore";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";

/**
 * @param {Number, optional} forBrandId
 * @param {FilterString, optional} forDateFilter
 * @returns {string}
 */
export function getPublicSentimentScore(forBrandId, forDateFilter) {
    let filter = "relevancy isnt irrelevant and visibility is public and (media is consumer or media is press or media is directory or media is unknown)";
    if (forBrandId) filter += ` and brand isorchildof ${forBrandId}`;
    if (forDateFilter) filter = appendFiltersReadably(filter, forDateFilter);
    return filter;
}

/**
 * This is our "main" sentiment measure, in the sense that it does not include sub-brands, is should
 * be comparable across accounts and between dashboards, offline reports, etc.
 * @param {Number} forBrandId
 * @param {FilterString, optional} forDateFilter
 * @returns {string}
 */
export function getBenchmarkSentimentScore(forBrandId, forDateFilter) {
    const base = `(brand is ${forBrandId}) and relevancy isnt irrelevant and visibility is public and (media is consumer or media is press or media is directory or media is unknown)`;
    if (forDateFilter) return base + ` and (${forDateFilter}) `;
    return base;
}

/**
 *
 * @param {Number|null, optional} forBrandId
 * @param {FilterString|null, optional} forDateFilter
 * @returns {string}
 */
export function getDirectMessageSentimentScore(forBrandId, forDateFilter) {
    let filter = `relevancy isnt irrelevant and visibility is direct_message`;
    if (forBrandId) filter += ` and brand isorchildof ${forBrandId}`;
    if (forDateFilter) filter = appendFiltersReadably(filter, forDateFilter);
    return appendSegmentRestrictions(filter);
}


/**
 * Returns a filter fragment for querying operational sentiment.
 *
 * @param forBrandId {Number,optional}
 * @param forDateFilter {String,optional}
 * @param {Boolean} [allowNonPublic = false]
 * @returns {Promise<String>}
 */
export async function getOperationalSentimentFilter(forBrandId, forDateFilter, allowNonPublic) {
    allowNonPublic ??= false;
    const cx = await getAllCxSegmentLists();
    if (!cx.length) {
        throw new Error("This account is not set up to use Customer Experience");
    }

    const segmentFilter = cx.map(tag => `segment is ${tag.id}`).join(' or ');
    let filter = `relevancy isnt irrelevant and (${segmentFilter}) and process is verified`;
    if (!allowNonPublic) filter += " and visibility is public";
    if (forDateFilter) filter += ` and (${forDateFilter})`;
    if (forBrandId) filter += ` and (brand isorchildof ${forBrandId})`;

    return appendSegmentRestrictions(filter);
}

/**
 *
 * @param forBrandId {Number,optional}
 * @param forDateFilter {String,optional}
 * @param {Boolean} [allowNonPublic = false]
 * @returns {Promise<string>}
 */
export async function getReputationalSentimentFilter(forBrandId, forDateFilter, allowNonPublic) {
    allowNonPublic ??= false;
    await VuexStore.dispatch('refreshTags');
    const cx = await getAllCxSegmentLists();
    if (!cx.length) {
        throw new Error("This account is not set up to use Customer Experience");
    }

    const notCx = cx
        .flatMap(tag => tag.children ?? [])
        .map(id => VuexStore.getters.idToTag.get(id))
        .filter(tag => tag && tag.flag === "NONE_OF_THE_ABOVE");
    let notCxFilter = notCx?.map(tag => `segment is ${tag.id}`)?.join(' or ');
    notCxFilter = notCxFilter ? `${notCxFilter} or segment is 100916` : "segment is 100916";

    // Reputational: is a non-cx reshare (100916, a global segment) or is a non-cx post.
    let filter = `relevancy isnt irrelevant and (${notCxFilter}) and process is verified`;
    if (!allowNonPublic) filter += " and visibility is public";

    if (forDateFilter) filter += ` and (${forDateFilter})`;
    if (forBrandId) filter += ` and (brand isorchildof ${forBrandId})`;

    return appendFiltersReadably(filter, "media is consumer or media is press or media is directory or media is unknown");
}