import Vue from "vue";
import {getAllMarketConductSegmentLists, getConductListParent} from "@/app/utils/Segments";
import {substituteTagParamaters} from "@/app/utils/Tags";
import VuexStore from "@/store/vuex/VuexStore";
import {escapeHtml} from "@/app/utils/StringUtils";

const conduct = Vue.observable({
    title: "Market Conduct",
    description: "See data categorised according to a Market Conduct framework",
    expanded:
`*Market Conduct* metrics can be used for compliance analysis or regulatory reporting. Conduct labels are applied to both positive and negative customer feedback.

Mentions can be grouped according to conduct sub-category (e.g. for complaints reporting), or by highlevel conduct outcomes.
`,
    image: require("../assets/conduct.png"),
    hide: true,
    metrics: []
});

export default conduct;

let initPromise = null;

export async function init() {
    // Allow this to be called multiple times.
    if (initPromise) return initPromise;
    initPromise = Promise.all([
        getAllMarketConductSegmentLists(),
        VuexStore.dispatch('refreshBrands'),
        VuexStore.dispatch('refreshTags')
    ]);
    const [conductLists, ignore, ignore2] = await initPromise;
    const brands = VuexStore.state.rootBrands;
    const idToTag = VuexStore.getters.idToTag;

    const activeSegments = new Set();
    brands.forEach(b => b.activeSegmentListIds?.forEach(id => activeSegments.add(id)));

    let allOutcomes = [];
    for (const conductList of conductLists) {
        let conductListParent = await getConductListParent(conductList);
        if (!conductListParent) allOutcomes.push(conductList);
    }

    if (conductLists.length) {
        conduct.hide = false;

        for (const outcome of allOutcomes) {
            const title =  allOutcomes.length > 1
                ? `${outcome.subtitle?.trim()} Market conduct`.trim()
                : "Market conduct";

            // Conduct widget only supports outcomes that have children
            let outcomeHasChildren = outcome.children.some(id => idToTag.get(id)?.children?.length);
            if (outcomeHasChildren) {
                conduct.metrics.push({
                    id: `conduct/stacked/${outcome.id}`,
                    title: title,
                    description: "Show metrics per sub-category, grouped by outcome",
                    more: "Metrics included: volume, Net Sentiment and unique authors",
                    image: require("../assets/conduct/stacked.png"),
                    widgetDesc: {
                        type: "ConductWidget",
                        caption: title,
                        segmentId: outcome.id
                    }
                });
            }
        }

        for (const segment of conductLists) {
            // An account will only have more than two of these if they have multiple copies of TCF for some reason.
            // These then need to be differentiated.
            const title = conductLists.length > 2 ? `${segment.subtitle} ${segment.name.trim()}` : segment.name.trim();
            let conductListParent = await getConductListParent(segment);
            const isOutcomes = !conductListParent;
            conduct.metrics.push({
                id: `conduct:${segment.id}`,
                title: title,
                description: substituteTagParamaters(segment.clientDescription),
                more: isOutcomes
                    ? "Measure sentiment towards each outcome to understand drivers of customer satisfaction and complaints"
                    : "Measure sentiment towards each sub-category to understand drivers of customer satisfaction and complaints",
                keywords: ["conduct", "priority", "rpcs", "tcf"],
                image: isOutcomes ? require("../assets/conduct/outcomes.png") : require("../assets/conduct/sub-categories.png"),
                additional: !isOutcomes && !activeSegments.has(segment.id), // If these are not being actively used, try to clean up the ui a bit.
                widgetDesc: {
                    type: "FantasticChart",
                    xAxis: `${segment.id}`,
                    yAxis: "posAndNegPercent",
                    width: 5,
                    height: 4,
                    geometry: "rows",
                    notification: escapeHtml`Added a <strong>${title}</strong> metric`
                },
                reference: {
                    type: "Nomenclature",
                    caption: escapeHtml`${title} reference sheet`,
                    width: 6,
                    height: 4,
                    selectedList: segment.id
                },
                options: [
                    {
                        key: "volume",
                        title: "As volume chart",
                        tooltip: "Add a bar chart of your volumes",
                        action: async (metric) => {
                            return Object.assign({},
                                metric, {
                                    widgetDesc: {
                                        type: "FantasticChart",
                                        xAxis: `${segment.id}`,
                                        yAxis: "mentionPercent",
                                        width: 5,
                                        height: 4,
                                        geometry: "rows"
                                    }
                                })
                        }
                    }
                ]
            });
        }
    }
}

