<template>
    <section v-if="isVisible">
        <slot/>
    </section>
</template>

<script>

import VuexStore from "@/store/vuex/VuexStore";

export default {
    store: VuexStore,
    computed: {
        isVisible() {
            return this.$store.state.user.admin;
        }
    }
}

</script>


<style scoped lang="sass">

</style>