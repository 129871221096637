<template>
    <span class="be-net-sentiment">
        <i v-if="netSentiment >= 0.0005" class="positive-sentiment icon-circle"></i>
        <i v-else-if="netSentiment <= -0.0005" class="negative-sentiment icon-circle"></i>
        <i v-else class="neutral-sentiment icon-circle"></i>
        <deq-percentage v-if="!noNumber" :percent="netSentiment" is-proportion :precision="1"/>
    </span>
</template>

<script>

import DeqPercentage from "@/components/formatters/DeqPercentage";

/** An easy way to render net sentiment as a stat. */
export default {
    components: {DeqPercentage},

    props: {
        netSentiment: {
            required: true,
            type: Number
        },
        noNumber: {
            type: Boolean,
            default() { return false }
        }
    },
}

</script>
