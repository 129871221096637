var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<td class=\"edit\">Edit</td>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<td class=\"edit\">Reorder</td>";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <tfoot>\n        <tr>\n            <td colspan=\"7\">\n                <button class=\"btn btn-link add\" title=\"Create new rule\"><i class=\"icon-plus\"></i> Add a rule</button>\n            </td>\n        </tr>\n        </tfoot>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"table table-condensed table-bordered table-hover\">\n    <thead>\n    <tr>\n        <td>Rule</td>\n        <td>Action</td>\n        "
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editRules",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":74}}})) != null ? stack1 : "")
    + "\n        <td>24h Vol</td>\n        <td>Week</td>\n        "
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editRules",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":77}}})) != null ? stack1 : "")
    + "\n    </tr>\n    </thead>\n    <tbody class=\"body\"></tbody>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editRules",{"name":"ifallowed","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":21,"column":18}}})) != null ? stack1 : "")
    + "</table>";
},"useData":true});