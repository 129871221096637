/**
 * Displays OTS information. Could be generalised to display arbitrary textual data.
 */
import _ from 'underscore';
import {once} from "@/app/utils/Functions";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {splitUnique} from "@/app/utils/StringUtils";

Beef.module("Widget.TextOnlineProfile").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/onlineprofile/text/TextOnlineProfile.handlebars"),

        //-------------------------------------------

        modelEvents: {
            "change:_effectiveFilter":   "refresh",
            "change:profiles": "refresh",
            "change:width": "widthHeightChanged",
            "change:height": "widthHeightChanged"
        },

        //-------------------------------------------

        imageExportDisabled: true,
        svgExportDisabled: true,

        //-------------------------------------------

        regions: {
            "listRegion": ".online-profile-list-region"
        },

        //-------------------------------------------

        initialize: function() {
            this.accountCode = this.model.getAncestorProperty('accountCode');
            this.listenTo(this.model.generalData, "change:data", this.onRender, this);
            this.refresh();
        },

        //-------------------------------------------

        getCsvEntries: function(filename) {

            var url = "/rest/" + this.getProfileUrl() + ".csv?filter=" + encodeURIComponent(this.getParameters().filter) ;

            return {
                filename: filename,
                urls:     [{
                    url: url,
                    name: 'profiles.csv'
                }]
            }
        },

        getProfiles: function(){
            var profiles = this.model.get('profiles');
            profiles = profiles ?  _(splitUnique(profiles)).map(function(d) { return parseInt(d); }) : [];
            return profiles;
        },

        getProfileUrl: function() {
            var profiles = this.getProfiles();
            var code = this.model.getAncestorProperty('accountCode');
            var profileString = profiles.length ? "[" + profiles.join(',') + "]" : '';

            return "accounts/" + code + "/online-profiles/" + profileString;
        },

        getParameters: function() {
            var profiles = this.getProfiles();
            var parameters = {filter: this.model.get('_effectiveFilter')};

            if(profiles.length == 1){
                parameters.include = "networkInfo";
            }

            return parameters;
        },

        refresh: function() {
            if (!this.model.get('_effectiveFilter')) return;

            var profiles = this.getProfiles();
            if (!profiles || !profiles.length) {
                this.model.generalData.set("_message", "Please select a profile");
                return;
            }


            var that = this;
            this.model.generalData.set('_loading', true);
            this.model.getSectionModel().view.getJsonFromSnoek(this.getProfileUrl(), this.getParameters(), _(function(data) {
                if (data && !Array.isArray(data)) {
                    data = [data];
                }

                that.model.generalData.set('data', data);

                if (data.length === 1) {
                    var profileData = data[0];
                    var callFilter = that.model.get('_effectiveFilter');
                    callFilter = "(" + callFilter + ") AND AuthorID IS '" +
                        Beef.AuthorIdPicker.toNiceAuthorId(profileData.authorId, profileData.handle, profileData.name) + "'";

                    that.model.getSectionModel().view.getJsonFromGrouse('/v4/accounts/' + this.accountCode + '/mentions/count',
                            { filter: callFilter, select: "mentionCount,totalOTS,totalAVE,totalEngagement,totalReshareCount,totalReplyCount" })
                        .then(
                        function (data) {
                            data.count = data.mentionCount;
                            data.ots = data.totalOTS;
                            data.ave = data.totalAVE;
                            data.engagement = data.totalEngagement;
                            data.reshareCount = data.totalReshareCount;
                            data.replyCount = data.totalReplyCount;
                            Object.assign(that.model.generalData.get('data')[0], data);
                            that.model.generalData.set('_loading', false);
                            that.onRender();
                        }, once(e => errorHelper(this.model, e)));
                }
                else {
                    that.model.generalData.set('_loading', false);
                }
            }).bind(this)).fail(error => errorHelper(this.model, error));
        },

        onRender: function() {
            if (!this.model.get('_effectiveFilter')) return;

            if (!this.list) {
                var collection = new Beef.Widgets.TextOnlineProfileList.Collection();
                this.list =  new Beef.Widgets.TextOnlineProfileList.View({
                    collection: collection,
                    code: this.model.getAncestorProperty('accountCode'),
                    filter: this.model.get('_effectiveFilter') || null
                });
                this.listRegion.show(this.list);
            }

            var data = this.model.generalData.get('data');
            var width = this.model.get('width'),
                height = this.model.get('height'),
                filter = this.getParameters().filter;

            this.list.collection.reset(_(data).map(function(d) {
                d.width = width;
                d.height = height;
                d.filter = filter;
                return new Beef.Widgets.TextOnlineProfileItem.Model(d);
            }));
            this.model.generalData.set('_completed', true);
        },

        widthHeightChanged: function() {
            if (this.list) {
                this.list.collection.trigger('redraw', {width: this.model.get('width'), height: this.model.get('height')});

                var width = this.model.get('width');
                var height = this.model.get('height');

                _(this.list.collection.models).each(function(m) {
                    m.set({
                        width: width,
                        height: height
                    })
                });
            }
        }


    });
});