/**
 * Renders a mention as HTML. Additional arguments are converted into an options map passed to MentionItem.View.
 */
export default function(context) {
    var options = {};
    for (var i = 1; i < arguments.length - 1; i++) options[arguments[i]] = true;
    options.model = context instanceof Backbone.Model ? context : new Beef.MentionItem.Model(context);
    var v = new Beef.MentionItem.View(options);
    var cls = v.attributes().class;
    var html = Backbone.Marionette.Renderer.render(v.getTemplate(), v.serializeData());
    return new Handlebars.SafeString("<div class='" + cls + "'>" + html + "</div>");
}
