<template>
    <div class="beef-tooltip">
        <h1>Public {{network}} mentions</h1>
        <p>
            <strong><deq-number :number="publicCount"/></strong> of these mentions were public
        </p>
    </div>
</template>

<script>
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    components: {DeqNumber},
    props: {
        network: String,
        total: Number,
        publicCount: Number
    },
    computed: {
        privatePercent() {
            return this.publicCount / this.total;
        }
    },
}
</script>