/* Provides a way to show a loading message while loading other components. */
<template>
    <div class="loading-message">
        <div class="loading-message__message">
            <slot>
                <h2>{{message}}</h2>
            </slot>
        </div>
        <spinner-component :size="16"/>
    </div>
</template>

<script>
import SpinnerComponent from "@/components/SpinnerComponent";

export default {
        name: "LoadingMessage",
        components: {SpinnerComponent},

        props: {
            message: {
                type: String,
                default() { return "Loading…" }
            }
        }
    }
</script>

<style scoped lang="sass">
.loading-message
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    text-align: center
    margin-top: var(--loading-message-margin-top, 20vh)
    //noinspection CssInvalidFunction
    animation: fadeIn 1s var(--loading-message-delay, 1s) both
    color: var(--be-colour-text-dark)
    .loading-message__message
        margin-block: 0
        margin-inline: auto
        // We want the width of the message to expand not too slowly or quickly.
        // We cannot easily control for the width of the item: if there are large margins,
        // then the animation may seem extremely quick. So we have large margins on the outer
        // element, and the animation on the inner element.
        > *
            max-width: fit-content
            overflow: hidden
            text-align: center
            white-space: nowrap
            animation: loading-message__scroll 750ms var(--loading-message-delay, 1s) both


@keyframes loading-message__scroll
    0%
        opacity: 0
        width: 0
    50%
        opacity: 1
    100%
        width: 100%

</style>