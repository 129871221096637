import {caper} from "@/store/Services";
import {currentAccountCode} from "@/app/utils/Account";

/**
 * Get some JSON data from caper and return a jqXHR instance
 */
export function getLdaTopicsFromCaper(options) {
    options = Object.assign({limit: 7}, options, {
        select: "+combinedHtml,phrases,brand,discardedByRule,region,crowdJobs",
        includeExamples: true
    });

    const params = Object.keys(options)
        .map(function (key) {
            return key + "=" + encodeURIComponent(options[key])
        })
        .join("&");


    return caper.get("/v4/accounts/" + currentAccountCode() + "/mentions/lda-topics?" + params)
        .then(response => response.data);
}