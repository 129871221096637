<template>
    <div class="image-stack" :data-count="images.length" ref="container">
        <img v-for="url in images" :key="url" :src="url" @load="imageLoaded(url)">
    </div>
</template>

<script>
    /**
     * Provides a way to show a collection of images stacked on top of one another,
     * such as what's in the metric picker dialog.
     */
    export default {
        name: "ImageStack",

        props: {
            images: { // An array of image urls
                type: Array,
                required: true,
            }
        },

        data() {
            return {
                loadedUrls: new Set()
            }
        },

        methods: {
            imageLoaded(url) {
                this.loadedUrls.add(url);
                if (this.loadedUrls.size === this.images.length) {
                    this.layout();
                }
            },

            layout() {
                if (this.images.length === 1) return;
                const container = this.$refs.container;
                const offset = parseInt(getComputedStyle(container).getPropertyValue('--image-offset'));
                const references = this.images.map(url => this.$refs.container.querySelector(`img[src='${url}']`));

                const availableWidth = container.clientWidth;
                let remainder = availableWidth;
                let height = 0;
                for (let i = 0; i < this.images.length; i++) {
                    if (i === 0) continue;
                    const q = references[i];
                    const thisOffset = i * offset;
                    q.style.top = `${thisOffset}px`;
                    const thisRemainder = availableWidth - (thisOffset + q.clientWidth);
                    remainder = Math.min(remainder, thisRemainder);
                    const thisHeight = thisOffset + q.clientHeight;
                    height = Math.max(height, thisHeight);
                }

                container.style.height = `${height}px`;
                const remainderOffset = remainder / 2;
                for (let i = 0; i < this.images.length; i++) {
                    const q = references[i];
                    const thisOffset = i * offset + remainderOffset;
                    q.style.left = `${thisOffset}px`;
                    q.style.opacity = 1;
                }
            }
        }
    }
</script>


<style scoped lang="sass">

    .image-stack
        --image-offset: 30px

        position: relative
        height: 250px
        padding-bottom: 20px
        img
            transition-property: left, top, opacity
            transition-duration: var(--transition-duration)
            opacity: 0
            position: absolute
            max-width: 65%
            max-height: 150px
            box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.7)
            border-radius: 3px
        img:nth-of-type(1)
            filter: brightness(90%)
        img:nth-of-type(2)
            filter: brightness(95%)
        img:nth-of-type(3)
            filter: brightness(100%)

        &[data-count="1"]
            height: auto
            img
                filter: none
                opacity: 1
                max-width: 100%
                max-height: 250px
                position: relative
                margin-left: auto
                margin-right: auto
                display: block

        &[data-count="2"]
            --image-offset: 50px
            height: calc(150px + var(--image-offset))
            img:nth-of-type(1)
                filter: brightness(95%)
            img:nth-of-type(2)
                filter: brightness(100%)


        &[data-count="3"]
            height: calc(150px + 2 * var(--image-offset))

    @media (max-height: 800px), (max-width: 1280px)
        .image-stack
            --image-offset: 20px

</style>