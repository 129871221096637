<template>
    <div class="preview-mentions">
        <div class="preview-mentions__brand-list dark-scrollbars dark-scrollbars--visible">
            <brand-list v-model="brandId" :brand-ids="brandIds"/>
        </div>

        <div class="preview-mentions__mentions-list-container dark-scrollbars dark-scrollbars--visible">
            <mention-list v-if="!busy && mentions && mentions.length" :mentions="mentions"/>
            <div v-else class="no-mentions">
                <div v-if="busy">
                    <loading-message class="preview-mentions__loading" message="Fetching mentions..."/>
                </div>
                <div v-else-if="error">{{error}}</div>
                <h2 v-else>No mentions found</h2>
            </div>
        </div>
    </div>
</template>

<script>
    import BrandList from '../components/BrandList'
    import {extractBrands, extractDays} from "@/dashboards/filter/BasicFilter";
    import MentionList from "@/components/MentionList";
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapState} from "vuex";
    import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
    import LoadingMessage from "@/components/LoadingMessage";
    import {grouseGet} from "@/data/Grouse";

    /**
     * Shows some mentions matching a filter with an option to choose a brand.
     */
    export default {
        name: "PreviewMentions",
        store: VuexStore,

        components: {LoadingMessage, BrandList, MentionList },

        props: {
            filter: String
        },

        data() {
            return {
                brandId: null,
                mentions: null,
                error: null,
                busy: true
            }
        },

        created() {
            if (this.brandIds && !this.brandId) this.brandId = this.brandIds.at(0);
        },

        computed: {
            ...mapState(['account']),

            brandIds() {
                if (!this.filter) return null
                let o = extractBrands(this.filter)
                return o.include.length ? o.include : null
            }
        },

        watch: {
            brandId(v) {
                this.refreshMentions()
            }
        },

        methods: {
            async refreshMentions() {
                try {
                    let bf = "brand isorchildof " + this.brandId;
                    if (!extractDays(this.filter)) bf += " and published inthelast week";
                    this.error = null;
                    this.mentions = null;
                    this.busy = true;

                    let params = {
                        filter: appendFiltersReadably(bf, this.filter),
                        select: "+combinedHtml,phrases,brand,discardedByRule,region,crowdJobs"
                    };

                    this.mentions = await grouseGet("/v4/accounts/" + this.account.code + "/mentions", params);
                } catch (e) {
                    this.error = "Error loading mentions: " + e;
                } finally {
                    this.busy = false
                }
            }
        }
    }
</script>

<style scoped lang="sass">

.preview-mentions
    display: grid
    width: 100%
    grid-template-columns: 1fr 2fr

    h2
        color: var(--be-colour-text-dark)

    &__brand-list
        overflow-y: auto
        background: #2c2c2c

    &__mentions-list-container
        overflow-y: auto
        background-color: #fff
        color: rgb(51,51,51)

        .no-mentions
            display: flex
            align-items: center
            justify-content: center
            font-family: var(--widget-font)
            padding: 16px
            color: rgb(51,51,51)

    &__loading
        margin-top: 0



</style>