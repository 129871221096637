/**
 * Select one or more media options. Fires a change event when the selection is changed. Also provides a model binder
 * converter to display the selected options in an element.
 */
import {MEDIA_CATEGORIES} from "@/app/utils/MediaLinks";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("MediaPicker").addInitializer(function(startupOptions) {

    this.items = MEDIA_CATEGORIES;

    this.View = Beef.Picker.View.extend({
        attributes: { class: "media-picker" },
        template: require("@/dashboards/filter/pickers/media/MediaPicker.handlebars"),
        items: this.items
    });

    this.converter = createTagConverter({
        tooltip: function(code) {
            switch (code) {
                case "CONSUMER":    return "Conversation from consumers and other untracked brands";
                case "ENTERPRISE":  return "Conversation from your owned brands and competitors";
                case "PRESS":       return "Conversation from press media and news aggregators";
                case "DIRECTORY":   return "Conversation from any online classifieds";
            }
            return MEDIA_CATEGORIES[code] + " category";
        },
        items: this.items,
        placeholder: "Categories"
    });

    /**
     * Attach a media picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
