/**
 * Extensions to help with model binding.
 */

import moment from "moment";

Beef.module("ModelBinder", function() {

    var lastUpdatedConverter = function(direction, value, binding, model) {
        var json = {
            lastUpdated: moment(value).fromNow(),
            lastUpdatedBy: model.get('lastUpdatedBy')
        };
        return Backbone.Marionette.Renderer.render('app/LastUpdated', json);
    };

    /** Add common converters and so on to bindings. */
    this.update = function(bindings) {
        if (bindings.lastUpdated) {
            bindings.lastUpdated.converter = lastUpdatedConverter;
            bindings.lastUpdated.elAttribute = 'html';
        }
        return bindings;
    }

    /** Create default bindings for el adding common converters. */
    this.createDefaultBindings = function(el) {
        return this.update(Backbone.ModelBinder.createDefaultBindings(el, 'name'));
    };

});
