/**
 * Patches Marionette's CollectionView and CompositeView to remove the 'loading' style from the views element on
 * reset. Also provides a View that can be used as a Marionette 'emptyView'.
 */
Beef.module("Empty", function() {

    var createRemoveLoadingFunc = function(initialEvents) {
        return function() {
            initialEvents.call(this, arguments);
            if (this.collection) {
                var fn = function(){
                    this.$el.toggleClass('loading', false);
                    if (this.$itemViewContainer) this.$itemViewContainer.toggleClass('loading', false);
                };
                this.listenTo(this.collection, "reset", fn, this);
                this.listenTo(this.collection, "sync", fn, this);
            }
        };
    };

    Backbone.Marionette.CollectionView.prototype._initialEvents =
        createRemoveLoadingFunc(Backbone.Marionette.CollectionView.prototype._initialEvents);

    Backbone.Marionette.CompositeView.prototype._initialEvents =
        createRemoveLoadingFunc(Backbone.Marionette.CompositeView.prototype._initialEvents);

    this.View = Backbone.Marionette.ItemView.extend({ template: require("@/app/Empty.handlebars") });

});
