import ObjectStore from './ObjectStore'
import { mash } from '../Services'
import accountCode from "@/helpers/accountCode";

/**
 * Fetches and caches rules for the selected account.
 * @deprecated
 */
export default class RuleStore extends ObjectStore {

    constructor() {
        super()
        this.refreshCall = null
    }

    /**
     * Refresh our rules. Returns promise. This does not start a new fetch if one is already in progress.
     */
    refresh(soft) {
        if (this.refreshCall) return this.refreshCall
        if (this.list && soft) return Promise.resolve(this.list)
        return this.refreshCall = mash.get("/rest/accounts/" + accountCode() + "/rules")
            .then(res => {
                this.list = []
                res.data.forEach(o => this.list.push(this.add(o)))
                return this.list
            }).finally(() => this.refreshCall = null)
    }

    /**
     * Lookup a rule by id. If we don't have it already a placeholder rule is returned and it is fetched in the
     * background.
     */
    get(id) {
        let rule = this.byId[id]
        if (!rule) {
            rule = { id: id, name: "" + id, action: null, filter: null, priority: null, active: null, macro: null }
            this.byId[rule.id] = rule
            this.refresh()
        }
        return rule
    }

    update(rule, payload) {
        return mash.put("/rest/accounts/" + accountCode() + "/rules/" + rule.id, payload)
            .then(res =>  Object.assign(rule, payload))
    }

    delete(ruleId) {
        return mash.delete("/rest/accounts/" + accountCode() + "/rules/" + ruleId)
            .then(res => {
                delete this.byId[ruleId]
                let i = this.list.findIndex(r => r.id === ruleId)
                if (i >= 0) this.list.splice(i, 1)
            })
    }

    create(payload) {
        return mash.post("/rest/accounts/" + accountCode() + "/rules?notLegacy=true", payload)
            .then(res => {
                let rule = res.data;
                this.add(rule)
                let p = rule.priority
                let i = 0
                for (; i < this.list.length && this.list[i].priority < p; i++);
                if (i < this.list.length) this.list.splice(i, 0, rule)
                else this.list.push(rule)
                return rule
            })
    }
}