/**
 * Select an integer representation for engagement values.
 */
Beef.module("EngagementPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IntegerPicker.View.extend({
        attribute: 'Engagement'
    });

    this.converter = Beef.IntegerPicker.createConverter("Engagement");

    this.attach = function(view, selector, attribute, options) {
        Beef.IntegerPicker.attachPicker(view, selector, attribute, this.View, options);
    };

});
