<template>
    <section class="beef-tooltip" ref="tooltip">
        <header>
            <h1>
                <rpcs-tag v-if="topic.topic.flag" :code="topic.topic.flag"/>
                {{ topic.topic.name }}
            </h1>
            <div v-if="showSubtext">
                This relates to your <strong>{{ parent.name }}</strong>
            </div>
        </header>

        <p v-if="description" class="trend-tooltip__description">
            {{ description }}
        </p>
        <p v-if="topic.net">
            Mentions related to this topic have an overall <strong>Net Sentiment of
            <net-sentiment :net-sentiment="topic.net"/>
        </strong>.
        </p>
        <p v-if="isFinite(topic.weekAverage)">
            It occurred a <strong>total of
            <deq-number :number="topic.weekTotal"/>
            times</strong>,
            on <strong>average
            <deq-number :number="topic.weekAverage" :precision="2"/>
            times per day</strong>. We
            were only expecting to see it <strong>
            <deq-number :number="topic.average" :precision="2"/>
            times per day </strong>.
        </p>
        <p v-else>
            It occurred a <strong>total of
            <deq-number :number="topic.weekTotal"/>
            times</strong>.
        </p>
        <p v-if="user.debugMode">
            <slotted-tag no-close>🐛</slotted-tag>
        </p>
        <table v-if="user.debugMode" style="width: 50%;">
            <tr>
                <td>z-score</td>
                <td>
                    <deq-number v-if="Number.isFinite(topic.delta)" :number="topic.delta" :precision="3"/>
                </td>
            </tr>
            <tr>
                <td>Long average</td>
                <td>
                    <deq-number v-if="Number.isFinite(topic.average)" :number="topic.average" :precision="2"/>
                </td>
            </tr>
            <tr>
                <td>long std</td>
                <td>
                    <deq-number v-if="Number.isFinite(topic.longStddev)"
                                :number="topic.longStddev" :precision="3"/>
                </td>
            </tr>
            <tr>
                <td>period std</td>
                <td>
                    <deq-number v-if="Number.isFinite(topic.periodStddev)"
                                :number="topic.periodStddev" :precision="3"/>
                </td>
            </tr>
            <tr>
                <td>period average</td>
                <td>
                    <deq-number v-if="Number.isFinite(topic.weekAverage)" :number="topic.weekAverage" :precision="2"/>
                </td>
            </tr>
            <tr>
                <td>95% CI</td>
                <td>
                    <deq-number v-if="Number.isFinite(topic.moe)" :number="topic.average" :precision="2"/>
                    ±
                    <deq-number v-if="Number.isFinite(topic.moe)" :number="topic.moe" :precision="2"/>
                </td>
            </tr>
        </table>
        <div v-if="shouldFetchSummary">
            <h2>Summary</h2>
            <with-summary :filter="topic.filter" @summary="reposition"></with-summary>
        </div>
    </section>
</template>

<script>

import NetSentiment from "@/components/NetSentiment";
import Number from "@/components/formatters/DeqNumber";
import SlottedTag from "@/components/tags/SlottedTag";
import {substituteTagParamaters} from "@/app/utils/Tags";
import RpcsTag from "@/components/tags/RpcsTag";
import {mapActions, mapGetters, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import DeqNumber from "@/components/formatters/DeqNumber";
import WithSummary from "@/app/toplevel/explore/overview/components/summaries/WithSummary.vue";
import {repositionCurrentTooltip} from "@/components/tooltip/TooltipUtilities";

export default {
    store: VuexStore,
    components: {WithSummary, DeqNumber, RpcsTag, SlottedTag, NetSentiment},
    props: {
        topic: Object
    },
    data() {
        return {
            parent: null,
            shouldFetchSummary: false,
            shouldFetchSummaryTimeout: null
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters(['idToTag']),
        description() {
            if (!this.topic.topic.description) return null;
            return substituteTagParamaters(this.topic.topic.description);
        },
        showSubtext() {
            if (!this.parent) return false;
            return this.parent.namespace === 'topic';
        }
    },
    watch: {
        topic() {
            this.updateParent();
        }
    },
    created() {
        this.updateParent();
    },
    methods: {
        ...mapActions(['refreshTags']),
        async updateParent() {
            this.parent = null;
            if (!this.topic?.topic) return;

            if (this.topic?.topic?.parent) {
                this.parent = this.topic?.topic.parent;
            }

            await this.refreshTags();
            this.parent = this.idToTag.get(this.topic.topic.id)?.parent;
        },

        reposition() {
            this.$nextTick(() => repositionCurrentTooltip());
        },
    },
    mounted() {
        this.$nextTick(() => repositionCurrentTooltip());
        this.shouldFetchSummaryTimeout = setTimeout(() => this.shouldFetchSummary = true, 2000);
    },

    beforeDestroy() {
        clearTimeout(this.shouldFetchSummaryTimeout);
    }
}

</script>

<style scoped lang="sass">

header
    padding-bottom: 20px

    h1
        margin: 0
        padding: 0
        line-height: 1.1em

    div
        color: var(--be-colour-muted-text-dark)


.trend-tooltip__description
    font-style: italic
    margin-block: 0
    padding-block: 0
    padding-bottom: 20px

    em
        font-style: normal
</style>
