import * as b3js from 'brandseyejs'
import {aggregatePage, toSnoekLink} from "@/data/Snoek";
import {formatNumber, formatPercentage} from "@/app/utils/Format";
import _ from 'underscore';
import moment from "moment";
import {getProfileIcon} from "@/app/utils/Profiles";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {getProfileDisplayName} from "@/setup/profiles/ProfileUtils";
import {splitUnique} from "@/app/utils/StringUtils";

Beef.module("Widget.VisualOnlineProfile").addInitializer(function(startupOptions) {

    this.View = Beef.ChartItemView.extend({

        //----------------------------------------------

        modelEvents:Object.assign({
            "change:profiles": "refresh"
        }, Beef.ChartItemView.prototype.modelEvents),

        //----------------------------------------------

        imageExportDisabled: null,

        //----------------------------------------------

        getProfiles: function() {
            var profiles = [];
            var profileAttribute = this.model.get('profiles');

            if (profileAttribute) {
                profiles = _(splitUnique(profileAttribute)).map(function(s) { return parseInt(s); });
            }

            return profiles;
        },

        //----------------------------------------------

        refresh: function() {
            if (!this.model.get('_effectiveFilter')) return;
            var code = this.model.getAncestorProperty('accountCode');
            var filter = this.model.get('_effectiveFilter');
            var profiles = this.getProfiles();
            var that = this;

            if (!profiles || !profiles.length) {
                this.model.generalData.set({
                    '_message': "Please select a profile"
                } );
                return;
            }


            this.model.generalData.set("_loading", true);
            var url = Beef.Widget.OnlineProfile.getMeasurementUrl(code, filter, profiles);
            var dataPromise = aggregatePage(url.url, url.params);

            $.when(dataPromise).done(function(data) {
                that.model.generalData.set('_loading', false);
                if (data.length == 0) {
                    that.model.generalData.set('_message', that.noDataMessage);
                    that.$('svg').remove();
                } else {
                    that.model.generalData.set('_message', null);
                }
                that.model.generalData.set('_data', that.packageData(data));
            }).fail(error => errorHelper(that.model, error));
        },

        /**
         * Given presence data, this packages in a format appropriate for rendering.
         */
        packageData: function(data) {
            if (!data || !data.length) return null;

            // Bucket by id
            var byId = {};
            _(data).each(function(item) {
                var id = item.profile.id;
                var list = byId[id];
                if (!list) list = byId[id] = [];
                item.date = item.captured; // Ensure that we have something in a date field for the line graph
                list.push(item);
            });

            function determineKey(profile) {
                if (profile.type == "TWITTER_SCREEN_NAME") return "@" + profile.handle;
                else if (profile.handle == profile.handleId && profile.name) return profile.name;
                return profile.handle;
            }

            var newById = {};
            for(var id in byId) {
                var list = byId[id];
                if((list.length != 1) || (_(list).first().id)) {
                    newById[id] = list;
                }
            }
            byId = newById;

            var ans = _(byId).map(function (list, id) {
                var profile = _(list).first().profile;
                var first = _(list).last(); // First is first by date, and we store data in descending date.

                _(list).each(function (item) {
                    item.profile = profile;     // Full profile only returned with the first item.

                    item.likesDelta = item.likes - first.likes;
                    item.talkingAboutCountDelta = item.talkingAboutCount - first.talkingAboutCount;
                    item.checkinsDelta = item.checkins - first.checkins;
                    item.wereHereCountDelta = item.wereHereCount - first.wereHereCount;
                    item.subscribersDelta = item.subscribers - first.subscribers;
                    item.subscribedToDelta = item.subscribedTo - first.subscribedTo;
                    item.followerCountDelta = item.followerCount - first.followerCount;
                    item.followingCountDelta = item.followingCount - first.followingCount;
                    item.statusCountDelta = item.statusCount - first.statusCount;

                    if (Number.isNaN(item.likesDelta)) delete item.likesDelta;
                    if (Number.isNaN(item.talkingAboutCountDelta)) delete item.talkingAboutCountDelta;
                    if (Number.isNaN(item.checkinsDelta)) delete item.checkinsDelta;
                    if (Number.isNaN(item.wereHereCountDelta)) delete item.wereHereCountDelta;
                    if (Number.isNaN(item.subscribersDelta)) delete item.subscribersDelta;
                    if (Number.isNaN(item.subscribedToDelta)) delete item.subscribedToDelta;

                    if (Number.isNaN(item.followerCountDelta)) delete item.followerCountDelta;
                    if (Number.isNaN(item.followingCountDelta)) delete item.followingCountDelta;
                    if (Number.isNaN(item.statusCountDelta)) delete item.statusCountDelta;

                });

                var key = determineKey(profile);
                return {
                    key: key,
                    lcLey: key.toLowerCase(),
                    values: list
                }
            });

            ans.sort(function(a, b) { return a.lcLey < b.lcLey ? -1 : +1 });

            return  ans;
        },

        getCsvEntries: function(filename) {
            var code = this.model.getAncestorProperty('accountCode');
            var filter = this.model.get('_effectiveFilter');
            var profiles = this.getProfiles();
            var url = Beef.Widget.OnlineProfile.getMeasurementUrl(code, filter, profiles);
            url.url = "/rest/" + url.url + '.csv?filter="' + encodeURIComponent(url.params.filter) + '"&limit=100000';

            return {
                filename: filename,
                urls:     [{
                    url: url.url,
                    name: 'profiles.csv'
                }]
            }
        },

        exportCSV_V4: function() {
            var code = this.model.getAncestorProperty('accountCode');
            var filter = this.model.get('_effectiveFilter');
            var profiles = this.getProfiles();
            var url = Beef.Widget.OnlineProfile.getMeasurementUrl(code, filter, profiles);
            var link = toSnoekLink(url.url + ".csv", url.params);
            window.open(link);
            return true;
        },

        maxItems: null,

        defaultLabelType: "count",
        categoryName: "captured",
        pluralCategoryName: "captured",
        fieldName: "captured",
        seriesMatcher: null,
        noDataMessage: "Your filter has no profile information available",

        ChartView: Beef.ChartView.extend({
            initialize: function() {
                Beef.ChartView.prototype.initialize.call(this);
                if (!this.model.has('hide-labels')) this.model.set({'hide-labels': true}, {silent: true});
                this.model.set({'chart-type': 'lines'}, {silent: true});
                this.model.set({'coarseness': 'daily'}, {silent: true});
                this.chart = new b3js.LineChart();
            },
            x: function(d) { return d.captured; },
            y: function(d) { return d.likesDelta || d.followerCountDelta || 0 },
            sort: false,
            useXAxisDataFormatter: Beef.ChartView.prototype.xAxisDateFormat,
            countDataAxisLabel: function() {
                var data = this.model.generalData.get('_data');
                var type = "Likes / Followers";
                var forname = '';
                var isOnlyTwitter = true;
                var isOnlyFacebook = true;

                _(data).each(function(d) {
                    if (d.values.length && d.values[0].profile.type != "TWITTER_SCREEN_NAME") {
                        isOnlyTwitter = false;
                    }
                    if (d.values.length && d.values[0].profile.type == "TWITTER_SCREEN_NAME") {
                        isOnlyFacebook = false;
                    }
                });

                if (isOnlyTwitter) type = "Followers";
                if (isOnlyFacebook) type = "Likes";

                if (data && data.length == 1 && data[0].values.length) {
                    var profile = data[0].values[0].profile;
                    var prefix = profile.type == 'TWITTER_SCREEN_NAME' ? '@' : '';

                    forname = " for " + prefix + getProfileDisplayName(
                        profile.handle,
                        profile.handleId,
                        profile.name,
                        profile.type
                    );
                }

                return {
                    long: "Change in " + type + forname,
                    short: "Δ" + type
                }
            },

            tooltip: {
                template: require("@/dashboards/widgets/onlineprofile/visual/VisualOnlineProfileTooltip.handlebars"),
                templateHelpers: function() {
                    var model = this.model;
                    var point = this.model.get('point');
                    var count = this.model.get('count');

                    var type = "";
                    if (point.likesDelta !== undefined) type = 'Likes';
                    if (point.followerCountDelta !== undefined) type = 'Followers';

                    return {
                        status: function() {
                            if (count < 0) {
                                return "are down"
                            }
                            if (count > 0) {
                                return "are up"
                            }
                            return "are unchanged"
                        },

                        arrow: function() {
                            if (count < 0) return 'icon-down-bold profile-down';
                            if (count > 0) return 'icon-up-bold profile-up';
                            return '';
                        },

                        type: type,

                        name: function() {
                            return getProfileDisplayName(
                                point.profile.handle,
                                point.profile.handleId,
                                point.profile.name,
                                point.profile.type
                            );
                        },

                        prefix: function() {
                            return getProfileIcon(point.profile.type);
                        },

                        score: function() {
                            if (count) return formatNumber(Math.abs(count));
                            return "";
                        },

                        total: function() {
                            return formatNumber(point.likes || point.followerCount || 0);
                        }
                    }
                },
                data: function(chart, event) {
                    var m = new moment(event.point.date);
                    var count = this.y(event.point),
                        percent = 0;
                    var data = this.model.generalData.get('_data');
                    var last = _(data[event.point.series || 0].values).last();

                    percent = formatPercentage(count / (last.likes || last.followerCount || 0) * 100, 1);

                    return new Backbone.Model({
                        point: event.point,
                        count: this.y(event.point),
                        percent: percent,
                        date: m.format('ddd, YYYY/MM/DD, HH:mm')
                    });
                }
            }
        })
    });

    //---------------------------------------

});