<template>
    <div class="overview-stats-bar"
         :class="{'overview-stats-bar--small': isSmall}"
         :style="{'--bar-fill-colour': colour, '--height': this.overallPercent + 'px'}"
         @mouseenter="$emit('mouseenter', $event)">

        <div class="overview-stats-bar__bar"
             @click="$emit('public-clicked', $event)"
             @mouseover="showPublicTooltip($event)">




        </div>

        <div tabindex="-1"
             @click.stop="$emit('private-clicked', $event)"
             class="overview-stats-bar__private"
             @mouseover.stop="showPrivateTooltip($event)"
             :style="{'--width': this.privatePercent + '%'}">
        </div>

        <div class="overview-stats-bar__label">
            <slot name="total">
                <deq-number :number="total"/>
            </slot>
            <div v-if="!privatePercent && brandHasDms"
                 class="overview-stats-bar__label-public">
                Public only
            </div>
        </div>

    </div>
</template>

<script>
import {mapState} from "vuex";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import VolumeOverviewChartPublicTooltip
    from "@/app/toplevel/explore/overview/components/VolumeOverviewChartPublicTooltip";
import VolumeOverviewChartPrivateTooltip
    from "@/app/toplevel/explore/overview/components/VolumeOverviewChartPrivateTooltip";
import VolumeOverviewChartTooltip from "@/app/toplevel/explore/overview/components/VolumeOverviewChartTooltip";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    components: {DeqNumber},
    props: {
        colour: {
            type: String,
            default: "grey"
        },
        total: Number,
        publicCount: Number,
        privateCount: Number,
        overall: Number,
        name: String
    },

    computed: {
        ...mapState('explorePanel', [
            'accountHasDms', 'brandHasDms'
        ]),

        privatePercent() {
            if (!this.total || !this.privateCount) return 0;
            return Math.max(this.privateCount / this.total * 100, 5);
        },
        overallPercent() {
            if (!this.overall) return 0;
            return this.total / this.overall * 100 * 1.7;
        },

        isSmall() {
            return this.overallPercent < 20;
        }
    },

    methods: {
        showPrivateTooltip(event) {
            showTooltipComponent(event.target, VolumeOverviewChartPrivateTooltip,
                {network: this.name, total: this.total, privateCount: this.privateCount},
                {positions: ['left']})
        },

        showPublicTooltip(event) {
            if (!this.isSmall) {
                showTooltipComponent(event.target, VolumeOverviewChartPublicTooltip,
                    {network: this.name, total: this.total, publicCount: this.publicCount},
                    {positions: ['left']})
            } else {
                showTooltipComponent(event.target, VolumeOverviewChartTooltip,
                    {network: this.name, total: this.total, privateCount: this.privateCount},
                    {positions: ['left']})
            }

        }
    }
}
</script>


<style scoped lang="sass">
.overview-stats-bar
    position: relative
    grid-column: 1 / span 2
    --bar-fill-colour: red
    width: 100%
    height: var(--height)
    cursor: pointer

.overview-stats-bar__label
    position: absolute
    left: 0
    right: 0
    top: 0
    padding: 0 5px
    display: inline-flex
    align-items: center
    height: 100%
    animation: overview-stats__fade-in 500ms 100ms both
    cursor: pointer
    pointer-events: none
    color: white


    .overview-stats-bar__label-public
        margin-left: auto
        font-size: 0.9em
        font-style: italic
        animation: overview-stats__fade-in 500ms 1000ms both
        color: rgba(200, 200, 200, 0.9)



.overview-stats-bar__bar
    position: relative
    box-sizing: border-box
    border: thin solid var(--bar-fill-colour)
    background: var(--bar-fill-colour)
    color: white
    height: var(--height)
    min-height: 2em
    animation: overview-stats__expand 500ms backwards
    overflow: hidden
    transition: filter var(--transition-duration)

    &:hover
        --transition-duration: 250ms
        filter: brightness(1.2)


.overview-stats-bar:nth-of-type(2) .overview-stats-bar__bar
    animation-delay: 100ms
.overview-stats-bar:nth-of-type(3) .overview-stats-bar__bar
    animation-delay: 200ms
.overview-stats-bar:nth-of-type(4) .overview-stats-bar__bar
    animation-delay: 300ms
.overview-stats-bar:nth-of-type(5) .overview-stats-bar__bar
    animation-delay: 400ms



.overview-stats-bar__private
    position: absolute
    right: 0
    top: 0
    width: var(--width, 0)
    height: 100%
    background: var(--bar-fill-colour)
    box-sizing: border-box
    filter: brightness(0.6)
    animation: overview-stats__fade-in 500ms 1s both
    transition: filter var(--transition-duration)
    &:hover
        --transition-duration: 250ms
        filter: brightness(0.7)


.overview-stats-bar--small
    grid-column: auto
    height: 2em
    font-size: 0.8em

    .overview-stats-bar__private
        display: none

    .overview-stats-bar__label-public
        display: none


@keyframes overview-stats__expand
    0%
        width: 0
        opacity: 0
    25%
        opacity: 1
    100%
        width: 100%


@keyframes overview-stats__fade-in
    0%
        opacity: 0
    100%
        opacity: 1


</style>