<template>
    <div ref="holder"></div>
</template>

<script>
    /**
     * Edit markdown.
     */
    export default {
        name: "MarkdownEditor",

        components: { },

        props: {
            value: String,
            simple: Boolean, // no "Etc", "Count" or "Top" buttons
        },

        mounted() {
            this.model = new Backbone.Model({comment: this.value})
            this.model.on("change", this.modelChanged)
            this.view = new Beef.Markdown.Editor.View({
                model: this.model,
                field: "comment",
                simple: this.simple
            })
            this.view.render()
            this.$refs.holder.append(this.view.el)
        },

        beforeDestroy() {
            this.view.close()
        },

        watch: {
            value(v) {
                this.model.set({comment: v})
            }
        },

        methods: {
            modelChanged() {
                this.$emit('input', this.model.get('comment'))
            },

            focus() {
                this.$el.querySelector("textarea").focus()
            }
        }
    }
</script>

<style scoped>

</style>