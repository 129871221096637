<template>
    <widget-settings-dialog :model="model"
                            :dto="dto"
                            @input="$emit('input', $event)" width="610px"
                            class-name="widget-settings-dialog">
        <section class="widget-settings-dialog__selector">
            <be-button :active="attrs.dataType === 'RISK'" @click="setDataType('RISK')">Risk</be-button>
            <be-button :active="attrs.dataType === 'CX'" @click="setDataType('CX')">CX</be-button>
            <be-button :active="attrs.dataType === 'CHANNELS'" @click="setDataType('CHANNELS')">Channels</be-button>
            <be-button :active="attrs.dataType === 'TOPICS'" @click="setDataType('TOPICS')">Topics</be-button>
            <be-button :active="attrs.dataType === 'TAGS'" @click="setDataType('TAGS')">Tags</be-button>
        </section>

        <label v-if="showMaxItems" class="widget-settings-dialog__max-input">
            <input type="number" v-model="attrs.maxItems"/> Max items
        </label>

        <label>
            <input type="checkbox" v-model="attrs.withDescription"> Show description
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withSentiment"> Show sentiment
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withTotal"> Show total mentions
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withMaximum"> Show daily maximum
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withMaxDate"> Show date of maximum volume
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withExpected"> Show expected value
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withZScore"> Show z-score
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withAverage"> Show daily averages
        </label>

        <section class="widget-settings-dialog__selector">
            <be-button :active="attrs.showSpiking" @click="attrs.showSpiking = !attrs.showSpiking"
                       tooltip="Show items that have had an unusual spike of volume in the filter's time period">
                Spiking
            </be-button>
            <be-button :active="attrs.showUnusual" @click="attrs.showUnusual = !attrs.showUnusual"
                       tooltip="Show items that have had an unusually high amount of volume in the given time period">
                Unusual
            </be-button>
            <be-button :active="attrs.showSlightly" @click="attrs.showSlightly = !attrs.showSlightly"
                       tooltip="Show items that have volumes a bit higher than average in the given time period">
                High
            </be-button>
            <be-button :active="attrs.showExpected" @click="attrs.showExpected = !attrs.showExpected"
                       tooltip="Show items that have volumes close to average in the given time period">
                Expected
            </be-button>
            <be-button :active="attrs.showLow" @click="attrs.showLow = !attrs.showLow"
                       tooltip="Show items that have received below average volumes in the given time period">
                Low
            </be-button>
            <be-button :active="attrs.showNew" @click="attrs.showNew = !attrs.showNew"
                       tooltip="Show items that are new in this time period, and don't have enough data to accurately report on">
                New
            </be-button>
        </section>
    </widget-settings-dialog>
</template>


<script>

import WidgetSettingsDialog from "@/dashboards/widgets/WidgetSettingsDialog";
import BeButton from "@/components/buttons/BeButton";
import {features} from "@/app/Features";

export default {
    components: {BeButton, WidgetSettingsDialog},

    props: {
        model: Object
    },

    data() {
        return {
            dto: {
                attrs: { }  // this gets set by widget-settings-dialog
            },
        }
    },

    computed: {
        attrs() { return this.dto.attrs },
        showMaxItems() {
            switch (this.attrs.dataType) {
                case "TOPICS":
                case "TAGS":
                    return true;
                default:
                    return false;
            }
        }
    },

    methods: {
        setDataType(type) {
            this.attrs.dataType = type;
        }
    }
}

</script>


<style scoped lang="sass">

label
    display: flex
    align-items: flex-start

    input[type='checkbox'],
    input[type='number']
        margin-right: 5px


.widget-settings-dialog__selector
    display: flex
    margin: 5px 0 10px
    .be-button:not(:last-of-type) ::v-deep .btn
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    .be-button + .be-button
        & ::v-deep .btn
            border-top-left-radius: 0
            border-bottom-left-radius: 0


::v-deep .control-form
    min-height: 200px !important

.widget-settings-dialog__max-input
    line-height: 25px



</style>