<template>
    <section class="engaging-mentions">
        <section v-if="loading" class="engaging-mentions__loading">
            <loading-message message="Fetching mentions..."/>
        </section>

        <no-topics-message v-if="error">
            We're not able to read your mentions at the moment. Please
            try again in a few minutes.
        </no-topics-message>

        <engaging-mention-item
            v-for="mention in mentions"
            :mention="mention"
            :key="mention.id"/>

    </section>
</template>


<script>

import EngagingMentionItem from "@/app/toplevel/explore/overview/components/EngagingMentionItem";
import LoadingMessage from "@/components/LoadingMessage";
import {count, getMentions} from "@/data/Grouse";
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";

export default {
    components: {NoTopicsMessage, LoadingMessage, EngagingMentionItem},

    props: {
        filter: {
            type: String,
            required: true
        },
        dateFilter: {
            type: String,
            default: "published inthelast week"
        },
        number: {                                       // The number of mentions to show
            type: Number,
            default: 15
        },
        sortBy: {                                       // What to sort the mentions by
            type: String,
            default() { return 'engagement' }
        },
        value: {
            type: Boolean                               // Used for tracking whether this is loading or not.
        }
    },

    data() {
        return {
            loading: false,
            mentions: [],
            numEnterprise: null,
            averageEngagement: null,

            promiseToCancel: new Set(),
            error: null
        }
    },

    computed: {
        brandFragment() {
            const brands = getBrandsInFilter(this.filter);
            if (!brands?.include?.length) return null
            return brands.include.map(id => `brand isorchildof ${id}`).join(' or ')
        },
    },

    watch: {
        filter() {
            this.loadMentions();
        },
        dateFilter() {
            this.loadMentions();
        },
        loading() {
            this.$emit('input', this.loading);
        }
    },

    created() {
        this.loadMentions();
    },

    beforeDestroy() {
        for (const promise of this.promiseToCancel) {
            try {
                if (promise.cancel) {
                    promise.cancel();
                }
            } catch(e) {
                console.warn("Unable to cancel network comms", e);
            }
        }
    },

    methods: {
        async loadMentions() {
            if (this.loading) {
                console.warn("Already loading engaging mentions");
                return;
            }

            if (!this.filter) {
                return;
            }

            try {
                this.loading = true;
                this.error = null;
                this.mentions = [];
                this.numEnterprise = null;
                this.averageEngagement = null;

                // We want to see which posts have the most graph engagement.
                const filter = `(${this.brandFragment}) and
                         (${this.dateFilter}) and
                         visibility is public and
                         relevancy isnt irrelevant and
                         conversationId in (${this.filter})`;
                const counter = count(filter, ['conversationId'], ["mentionCount"]);
                const overallStats = count(this.filter, null, ["mentionCount", "averageEngagement"]);
                const riskCounter = this.sortBy === "risk"
                    ? count(`(${this.filter}) and tag is 1`, ['conversationId'], ["mentionCount"])
                    : Promise.resolve(null);
                this.promiseToCancel.add(counter);
                this.promiseToCancel.add(overallStats);

                const [graphEngageCount, mainCount, riskStats] = await Promise.all([
                    counter,
                    overallStats,
                    riskCounter
                ]);

                this.promiseToCancel.delete(counter);
                this.promiseToCancel.delete(overallStats);

                if (graphEngageCount.length) {
                    const vals = graphEngageCount.slice(0, this.number * 2);
                    const ids = vals.map(row => `'${row.conversationId}'`);

                    const mentionsPromise = getMentions(`(${this.brandFragment}) and id in (${ids.join(',')}) and relevancy isnt irrelevant and (${this.filter})`);
                    this.promiseToCancel.add(mentionsPromise);
                    const mentions = await mentionsPromise;
                    this.promiseToCancel.delete(mentionsPromise);


                    let sorter = (lhs, rhs) => {
                        const lhsGraphEngagement = vals.find(it => it.conversationId === lhs.id).mentionCount;
                        const rhsGraphEngagement = vals.find(it => it.conversationId === rhs.id).mentionCount;

                        return rhsGraphEngagement - lhsGraphEngagement;
                    };

                    switch (this.sortBy) {
                        case 'engagement': break;
                        case 'risk':
                            sorter = (lhs, rhs) => {
                                const lhsRisk = riskStats.find(it => it.conversationId === lhs.id)?.mentionCount ?? 0;
                                const rhsRisk = riskStats.find(it => it.conversationId === rhs.id)?.mentionCount ?? 0;
                                return rhsRisk - lhsRisk;
                            };
                            break;
                        default:
                            console.warn(`Unable to sort by unknown sort attribute [${this.sortBy}]`);
                    }

                    this.mentions = mentions.sort(sorter).slice(0, this.number);
                }

                this.numEnterprise = mainCount.mentionCount;
                this.averageEngagement = mainCount.averageEngagement;
            } catch(e) {
                this.error = e;
                console.error(e);
            } finally {
                this.loading = false;
            }
        }
    }



}

</script>


<style scoped lang="sass">

.engaging-mentions
    display: flex
    flex-wrap: wrap
    column-gap: 40px
    row-gap: 20px

.engaging-mentions__loading
    margin-inline: auto

.engaging-mention-item
    animation: 250ms fadeIn both
    animation-delay: 400ms

.engaging-mention-item:nth-of-type(1)
    animation-delay: 0ms

.engaging-mention-item:nth-of-type(2)
    animation-delay: 100ms

.engaging-mention-item:nth-of-type(3)
    animation-delay: 200ms

.engaging-mention-item:nth-of-type(4)
    animation-delay: 300ms

@media (max-width: 1550px)
    .engaging-mentions
        column-gap: 10px
        row-gap: 30px


</style>