<template>
    <section class="explore-panel">
        <sidebar-component>
            <sidebar-header>
                <h4>Explore</h4>
            </sidebar-header>

            <sidebar-menu>
                <sidebar-nav-item
                    @click="makeActive('overview')"
                    :active="overviewActive"
                    :href="`/${account.code}/explore`"
                    @mouseenter="showTooltip"
                    data-heading="Overview"
                    data-text="Find out what is trending in your account">
                    Overview
                </sidebar-nav-item>
                <sidebar-nav-item
                    :active="enterpriseActive"
                    :href="`/${account.code}/explore/enterprise`"
                    @click="makeActive('enterprise')"
                    @mouseenter="showTooltip"
                    data-heading="Enterprise mentions"
                    :data-text="`See what Enterprise mentions from ${brandName} have been receiving the most engagement`"
                    indented>
                    Enterprise mentions
                </sidebar-nav-item>
                <sidebar-nav-item
                    indented
                    :active="pressActive"
                    :href="`/${account.code}/explore/press`"
                    @click="makeActive('press')"
                    @mouseenter="showTooltip"
                    data-heading="Press mentions"
                    :data-text="`See the most engaging Press mentions concerning ${brandName}`">
                    Press mentions
                </sidebar-nav-item>
                <sidebar-nav-item
                    indented
                    :active="consumerActive"
                    :href="`/${account.code}/explore/consumer`"
                    @click="makeActive('consumer')"
                    @mouseenter="showTooltip"
                    data-heading="Consumer mentions"
                    :data-text="`See organic consumer mentions relating to ${brandName}`">
                    Consumer mentions
                </sidebar-nav-item>
                <sidebar-nav-item
                    v-if="accountHasRisk"
                    :active="riskActive"
                    :disabled="!brandHasRisk"
                    :href="`/${account.code}/explore/risk`"
                    data-heading="Risk trends"
                    :data-text="riskDescription"
                    @mouseenter="showTooltip"
                    @click="makeActive('risk')">
                    Risk trends
                </sidebar-nav-item>
                <sidebar-nav-item
                    v-if="accountHasCx"
                    :active="cxActive"
                    :disabled="!brandHasCx"
                    :href="`/${account.code}/explore/cx`"
                    data-heading="Customer Experience"
                    :data-text="cxDescription"
                    @mouseenter="showTooltip"
                    @click="makeActive('cx')">
                    Customer experience
                </sidebar-nav-item>
                <sidebar-nav-item
                    v-if="accountHasTopics"
                    :active="topicsActive"
                    :disabled="!brandHasTopics"
                    :href="`/${account.code}/explore/topics`"
                    data-heading="Topic trends"
                    :data-text="topicsDescription"
                    @mouseenter="showTooltip"
                    @click="makeActive('topics')">
                    Topic trends
                </sidebar-nav-item>
                <sidebar-nav-item
                    :active="keywordTrendsActive"
                    :href="`/${account.code}/explore/keyword-trends`"
                    @click="makeActive('keyword-trends')"
                    @mouseenter="showTooltip"
                    data-heading="Keyword trends"
                    :data-text="`See what keywords are driving the conversation in ${brandName}'s mentions`">
                    Keyword trends
                </sidebar-nav-item>
            </sidebar-menu>

            <sidebar-menu position="bottom" >
                <tooltip-component>
                    <sidebar-nav-item :href="`/${account.code}/explore/glossary`"
                                      :active="glossaryActive"
                                      data-heading="Glossary"
                                      data-text="Find out more about Explore"
                                      @click="makeActive('glossary')">
                        Glossary
                    </sidebar-nav-item>
                    <template #tooltip>
                        <h1 style="margin: 0; padding: 0; line-height: 1.5em;">Glossary</h1>
                        <p style="margin: 0; padding: 0; line-height: 1.5em">
                            Find out more about Explore
                        </p>
                    </template>
                </tooltip-component>


                <sidebar-nav-item v-if="user.admin"
                                  :active="setupActive"
                                  @click="makeActive('setup')"
                                  :href="`/${account.code}/explore/setup`">
                    Setup
                    <span v-if="setupWarnings && setupWarnings.length" class="short-animated fadeIn explore-panel__warning">
                        <i class="symbol-warning"></i>
                        <deq-number :number="warningCount"/> {{ formatPlural(warningCount, 'warning')}}
                    </span>
                    <span v-else-if="!setupWarnings" class="explore-panel__warning-loading">
                        <spinner-component :size="14"/> Checking
                    </span>

                </sidebar-nav-item>
            </sidebar-menu>
        </sidebar-component>

        <section class="explore-panel__container">
            <keep-alive>
                <overview-tool v-if="overviewActive"/>
                <explore-enterprise v-if="enterpriseActive"/>
                <explore-press v-if="pressActive"/>
                <explore-cx v-if="cxActive"/>
                <explore-risk v-if="riskActive"/>
                <explore-topics v-if="topicsActive"/>
                <explore-keywords v-if="keywordTrendsActive"/>
                <explore-consumer v-if="consumerActive"/>
                <explore-glossary v-if="glossaryActive"/>
                <explore-setup v-if="setupActive && user.admin"/>
            </keep-alive>
        </section>
    </section>
</template>


<script>

import SidebarComponent from "@/components/sidebar/SidebarComponent";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import SetupTooltip from "@/app/toplevel/setup/SetupTooltip";
import {mapActions, mapGetters, mapState} from "vuex";
import {getDefaultBrand} from "@/app/toplevel/explore/overview/ExploreUtilities";
import VuexStore from "@/store/vuex/VuexStore";
import {showGlossaryTip, showTip} from "@/app/help/tips/tips";
import SidebarNavItem from "@/components/sidebar/SidebarNavItem";
import Number from "@/components/formatters/DeqNumber";
import {formatPlural} from "@/app/utils/Format";
import ExploreSetup from "@/app/toplevel/explore/overview/ExploreSetup";
import ExploreConsumer from "@/app/toplevel/explore/overview/ExploreConsumer";
import OverviewTool from "@/app/toplevel/explore/overview/OverviewTool";
import ExploreEnterprise from "@/app/toplevel/explore/overview/ExploreEnterprise";
import ExplorePress from "@/app/toplevel/explore/overview/ExplorePress";
import ExploreRisk from "@/app/toplevel/explore/overview/ExploreRisk";
import ExploreTopics from "@/app/toplevel/explore/overview/ExploreTopics";
import ExploreKeywords from "@/app/toplevel/explore/overview/ExploreKeywords";
import ExploreCx from "@/app/toplevel/explore/overview/ExploreCx";
import SpinnerComponent from "@/components/SpinnerComponent";
import {resetContentHeight, setTitle} from "@/app/Beef";
import {countUniqueWarnings} from "@/store/vuex/dataChecks";
import {INITIAL_EXPLORE_DATE} from "@/store/vuex/modules/ExploreModule";
import {logPageUsed} from "@/app/utils/UserAccessLog";
import ExploreGlossary from "@/app/toplevel/explore/glossary/ExploreGlossary";
import TooltipComponent from "@/components/tooltip/TooltipComponent";
import DeqNumber from "@/components/formatters/DeqNumber";


export default {
    name: "ExplorePanel",
    store: VuexStore,

    components: {
        DeqNumber,
        SpinnerComponent,
        TooltipComponent,
        ExploreGlossary,
        SidebarNavItem,
        SidebarMenu, SidebarComponent, SidebarHeader,
        ExploreCx,
        ExploreConsumer,
        OverviewTool,
        ExploreEnterprise,
        ExplorePress,
        ExploreRisk,
        ExploreTopics,
        ExploreKeywords,
        ExploreSetup
    },

    props: {
        initialActiveView: {
            type: String,
            default: null
        },
        filter: {
            type: String,
            default: () => null
        },
        initialPinnedIds: Array,
        initialWord: String,
        initialBrandId: Number,
        initialDate: String
    },

    data() {
        return {
            addToHistory: false,
            currentActive: this.initialActiveView,
            destroyed: false
        }
    },

    computed: {
        ...mapState(['user']),
        ...mapState('explorePanel', ['setupWarnings']),

        warningCount() {
            return countUniqueWarnings(this.setupWarnings);
        },

        overviewActive()        { return this.currentActive === 'overview'},
        enterpriseActive()      { return this.currentActive === 'enterprise'},
        pressActive()           { return this.currentActive === 'press'},
        consumerActive()        { return this.currentActive === 'consumer'},
        riskActive()            { return this.currentActive === 'risk' && this.accountHasRisk},
        cxActive()              { return this.currentActive === 'cx' && this.accountHasCx},
        topicsActive()          { return this.currentActive === 'topics' && this.accountHasTopics},
        keywordTrendsActive()   { return this.currentActive === 'keyword-trends'},
        setupActive()           { return this.currentActive === 'setup'},
        glossaryActive()        { return this.currentActive === 'glossary'},
        riskDescription() {
            if (this.brandHasRisk) {
                return `See what new trends there are in online conversations related to ${this.brandName}'s Risk profile`;
            }  else {
                return `${this.brandName} is not on Risk and Reputation`;
            }
        },
        cxDescription() {
            if (this.brandHasRisk) {
                return `See what new trends there are in online conversations related to ${this.brandName}'s Customer Experience`;
            }  else {
                return `${this.brandName} is not on Customer Experience`;
            }
        },
        topicsDescription() {
            if (this.brandHasTopics) {
                return `Explore the trends that relate to ${this.brandName}'s topics`;
            }  else {
                return `${this.brandName} does not use topics`;
            }
        },

        sessionStorageKey() {
            return `analyse:${this.account.code}:${this.user?.id}:explore:last-active`;
        },

        sessionStorageBrandKey() {
            return `analyse:${this.account?.code}:${this.user?.id}:explore:last-brand-id`;
        },

        sessionStorageDateKey() {
            return `analyse:${this.account?.code}:${this.user?.id}:explore:last-date-range`;
        },

        ...mapState(['account', 'user']),
        ...mapGetters(['idToBrand']),

        ...mapState('explorePanel', [
            'brand',
            'brandHasRisk', 'brandHasTopics',
            'accountHasRisk', 'accountHasTopics',
            'accountHasCx', 'brandHasCx',
            'isInitialised', 'date'
        ]),

        ...mapGetters('explorePanel', [
            'brandName'
        ])

    },

    watch: {
        brand() {
            if (this.brand?.id) {
                sessionStorage.setItem(this.sessionStorageBrandKey, "" + this.brand.id);
            } else {
                sessionStorage.removeItem(this.sessionStorageBrandKey);
            }

            this.updateUrl();
        },

        date() {
            if (this.date) {
                sessionStorage.setItem(this.sessionStorageDateKey, this.date);
            } else {
                sessionStorage.removeItem(this.sessionStorageDateKey);
            }

            this.updateUrl();
        }
    },

    created() {
        setTitle("Explore");
        resetContentHeight();
        if (this.initialDate) this.setDate(this.initialDate);
    },

    beforeDestroy() {
        this.destroyed = true;
    },

    async mounted() {
        // Here we want to figure out what explore panel to show. If an initial
        // has been provided, we use that. Otherwise we check session storage, or fall back
        // to overview.
        let initialView = this.initialActiveView;
        if (!initialView) {
            try {
                let storage = sessionStorage.getItem(this.sessionStorageKey);
                if (storage) initialView = storage;
            } catch (e) {
                console.warn(e);
            }
            if (!initialView) initialView = 'overview';
        }

        if (!this.currentActive) this.currentActive = initialView;
        this.handleMain404();

        if (!this.isInitialised) await this.initialise();
        this.makeActive(initialView);

        // Do we have a cached date for when the user ctrl-Rs
        try {
            const date = sessionStorage.getItem(this.sessionStorageDateKey);
            if (date && date !== this.date) await this.$store.dispatch('explorePanel/setDate', date);
        } catch (e) {
            console.warn("Unable to set last cached date", e);
        }

        // Do we have a cached brand for when the user ctrl-Rs
        if (!this.brand) {
            let brand = null;
            try {
                if (this.initialBrandId) brand = this.idToBrand.get(this.initialBrandId);
                else {
                    const idString = sessionStorage.getItem(this.sessionStorageBrandKey);
                    if (idString) {
                        brand = this.idToBrand.get(parseInt(idString));
                    }
                }
            } catch (e) {
                console.warn("Unable to read last cached brand", e);
                sessionStorage.removeItem(this.sessionStorageBrandKey);
            }
            brand ??= await getDefaultBrand();
            await this.setBrand(brand);
        }



        await showTip("EXPLORE_INTRO"); // Show this first.
        await showTip("EXPLORE_NAVIGATE", "EXPLORE_DATE");  // The general explore things to select between showing.
        await showTip("KRAKEN", "RICK");
        await showGlossaryTip();
        await this.checkWarnings();
    },

    methods: {
        formatPlural,

        ...mapActions('explorePanel', ['initialise', 'setBrand', 'checkWarnings', 'setDate']),

        async updateUrl() {
            const params = new URLSearchParams();
            const defaultBrand = await getDefaultBrand();
            if (this.brand?.id && this.brand?.id !== defaultBrand?.id) params.append("brand", "" + this.brand.id);
            if (this.date && this.date !== INITIAL_EXPLORE_DATE) params.append('date', this.date);

            let url = window.location.pathname;
            if (!params.keys().next().done) {
                url += '?' + params;
            }
            history.replaceState(null, '', url);
        },

        handleMain404() {
            switch (this.currentActive) {
                case "overview":
                case "press":
                case "consumer":
                case "cx":
                case "risk":
                case "topics":
                case "keyword-trends":
                case "setup":
                case "glossary":
                case "enterprise": break;
                default:
                    console.error(`Unrecognised explore tool: ${this.currentActive}`);
                    Beef.content.show(Beef.view404);
                    sessionStorage.removeItem(this.sessionStorageKey);          // This now stores a bad item.
            }
        },

        makeActive(item) {
            this.handleMain404();

            if (!this.accountHasRisk && item === "risk") {
                item = "overview";
            }

            if (!this.accountHasTopics && item === "topics") {
                item = "overview";
            }

            if (!this.accountHasCx && item === "cx") {
                item = "overview";
            }

            if (item === "setup" && !this.user.admin) {
                item = "overview";
            }

            this.currentActive = item;
            try {
                if (item) sessionStorage.setItem(this.sessionStorageKey, item);
            } catch (e) {
                console.warn(e);
            }

            logPageUsed(`explore:${item}`);

            let uri = `/${this.account.code}/explore/${item}`;
            const params = [];


            // The very first time that this is called, we've already had the URI changed to /explore/<something>
            // and added to the history. We don't want to to that again, so that the back button continues to work.
            if (params.length) uri += "?" + params.join('&');
            if (!this.destroyed) {
                Beef.router.navigate(uri, {replace: !this.addToHistory});
            }
            this.addToHistory = true;
            this.updateUrl();
        },

        showTooltip(event) {
            const target = event?.currentTarget;
            if (target) {
                const text = target.dataset.text;
                const heading = target.dataset.heading;
                if (!text) return;

                showTooltipComponent(
                    target,
                    SetupTooltip,
                    {
                        text: text,
                        heading: heading,
                        ops: false,
                        staff: false
                    },
                    {positions: ['right']}
                )
            }
        }
    }

}
</script>


<style scoped lang="sass">

.explore-panel__container
    margin-left: var(--sidebar-size)

.explore-panel__warning
    color: red
    font-weight: bold

.explore-panel__warning-loading
    padding-left: 20px
    color: var(--be-colour-muted-text-dark)

</style>