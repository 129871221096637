export function addSidebarHoverFlag(width) {
    const element = document.documentElement;
    element.classList.add("sidebar-hover");
    if (width) element.style.setProperty("--notification-offset", width);
    else element.style.removeProperty('--notification-offset');
}

export function removeSidebarHoverFlag() {
    const element = document.documentElement;
    element.classList.remove("sidebar-hover");
    element.style.removeProperty('--notification-offset');
}