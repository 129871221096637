<template>
    <div class="author-bio-location-picker deq-reset">
        <div class="ablp-active">
            <SlottedTag v-for="word in internalData" :key="word" @close="remove(word)">
                {{word}}
            </SlottedTag>
            <div class="ablp-current" contenteditable="true"
                 ref="current"
                 @keydown="currentKeyDown"
                 @keyup.capture="stopEnter">
            </div>
        </div>
        <div v-if="showPlaceholder" class="ablp-placeholder">
            Type text to search for in an author's location field
        </div>
    </div>
</template>


<script>

import SlottedTag from "@/components/tags/SlottedTag.vue";
import {notifyWithHtml} from "@/app/framework/notifications/Notifications";
import {escapeHtml} from "@/app/utils/StringUtils";

export default  {
    components: {SlottedTag},

    props: {
        value: {
            type: Array,
            default: () => []
        },
        allowNegation: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            internalData: this.value,
            showPlaceholder: true
        }
    },

    mounted() {
        this.checkPlaceholder();
    },

    watch: {
        internalData() {
            this.$emit('update', Array.from(this.internalData));
        }
    },

    methods: {
        checkPlaceholder() {
            const current = this.$refs.current.innerText?.trim() ?? "";
            this.showPlaceholder = !this.internalData.length && !current.length;
        },

        remove(word) {
            this.internalData = this.internalData.filter(p => p !== word);
            this.checkPlaceholder();
        },

        stopEnter(event) {
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
            }

            if (event.key === "Escape") {
                const current = this.$refs.current.innerText;
                this.$refs.current.innerText = "";

                if (!current.length) {
                    this.$refs.current.blur();
                }

                event.preventDefault();
                event.stopPropagation();
            }

            this.checkPlaceholder();
        },

        /** @param {KeyboardEvent} event */
        currentKeyDown(event) {
            const current = this.$refs.current.innerText?.trim() ?? "";

            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();

                let clean = current;
                if (!this.allowNegation && current.startsWith('-')) {
                    notifyWithHtml("We cannot exclude phrases",
                        null, '<i class="symbol-warning"></i>');
                    clean = current.substring(1);
                }

                if (clean.length > 0) {
                    if (!this.internalData.includes(clean)) {
                        this.internalData.push(clean);
                    } else {
                        notifyWithHtml(`Phrase <strong>${escapeHtml(clean)}</strong> was previously added`,
                            null, '<i class="symbol-warning"></i>')
                    }
                    this.$refs.current.innerText = "";
                }
            }

            if (event.key === "Backspace" && current.length === 0) {
                this.internalData = this.internalData.slice(0, -1);
            }
        }
    }

}

</script>


<style lang="sass" scoped>

.author-bio-location-picker
    height: unset
    width: 100%
    box-sizing: border-box

    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr


    min-height: 30px
    padding: 1.5px 5px

    border: 1px solid #aaaaaa
    border-radius: 3px
    background-color: #414141
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025)

    transition: box-shadow 0.2s linear

    .ablp-current
        display: inline-block
        width: auto
        flex-grow: 1

    .ablp-current:focus
        outline: none


.author-bio-location-picker:focus-within
    border: var(--focus-border)
    outline: 0
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(174, 225, 93, 0.6)

    .ablp-placeholder
        display: none

.ablp-active
    grid-column: 1
    grid-row: 1

    width: 100%

    display: flex
    flex-wrap: wrap
    gap: 0.5rem
    align-items: center

.ablp-placeholder
    grid-column: 1
    grid-row: 1
    display: grid
    align-items: center
    color: var(--colour-placeholder)
    pointer-events: none

</style>