<template>
    <dialog-box
        overlay
        class="topic-tree-picker-dialog"
        width="60vw"
        @close="close()"
        v-if="showDialog"
        :title="'Choose Brand Topic Trees'">

        <div class="topic-tree-picker-dialog__container">
            <loading-message v-if="loading"
                             class="topic-tree-picker-dialog__loader"
                             message="Fetching your topic trees"/>

            <div v-if="!loading">

                <p>Topic trees are a means of tagging your brands mentions in order to provide more detailed analysis. Only
                    topic trees checked with "In Account" and "Ask Crowd" will be used to tag future mentions.</p>

                <div class="topic-tree-picker-dialog__tree-filters">
                    <search-input
                        class="search-bar"
                        autofocus
                        placeholder="Search for a topic tree"
                        v-if="!loading"
                        v-model="searchTerm"/>
                </div>

                <div class="topic-tree-picker-dialog__table-container dark-scrollbars dark-scrollbars--visible" v-if="!loading">
                    <table class="table table-condensed table-bordered table-hover">
                        <thead>
                        <tr>
                            <th tooltip="Checked 'In Account' trees will appear on previous mentions they were tagged on">In&nbsp;Account</th>
                            <th>Tree Name</th>
                            <th tooltip="Checked 'Ask Crowd' trees will be tagged on future mentions by the crowd">Ask&nbsp;Crowd</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="tree in filteredTrees" :key="tree.id">
                            <td class="cb">
                                <input :data-id="tree.id" type="checkbox" v-model="tree.inAccount">
                            </td>
                            <td class="tree-name" @click="viewTree(tree)">
                                {{ tree.trimmedLabel }} <i style="float: right" title="View" class="icon-eye" @onclick="viewTree(tree)"></i>
                            </td>
                            <td class="cb">
                                <input :data-id="tree.id" type="checkbox" :disabled="!tree.inAccount" v-model="tree.askCrowd">
                            </td>
                            <td class="tree-desc">{{ tree.visibleDescription }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <template v-slot:buttons>
            <be-button link class="topic-tree-picker-dialog__bottom-button" @click="close">Cancel</be-button>
            <be-button :primary="true" class="topic-tree-picker-dialog__bottom-button" :disabled="saving" @click="save">Save
                <spinner-component :size="11" class="topic-tree-picker-dialog__save-spinner" v-show="saving"></spinner-component>
            </be-button>
        </template>

    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import LoadingMessage from "@/components/LoadingMessage";
import SearchInput from "@/components/inputs/SearchInput";
import {substituteTagParamaters} from "@/app/utils/Tags.js";
import SpinnerComponent from "@/components/SpinnerComponent";
import BeButton from "@/components/buttons/BeButton";
import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
import {showWhenDialog} from "@/app/framework/dialogs/Dialog";
import Vue from "vue";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
    name: "TopicTreePickerDialog",
    store: VuexStore,

    components: {SpinnerComponent, BeButton, DialogBox, LoadingMessage, SearchInput},

    props: {
        activeBrandId: Number
    },

    data() {
        return {
            showDialog: true,
            loading: true,
            saving: false,
            allTopicTrees: [],
            brandTopicTrees: [],
            brand: null,
            searchTerm: ''
        }
    },

    computed: {
        ...mapGetters(['idToBrand']),
        ...mapState('topicTrees', ['availableTopicTrees']),

        filteredTrees() {
            return this.allTopicTrees.filter(tree => this.searchQuery(tree));
        }
    },

    async created() {
        try {
            this.brand = this.idToBrand.get(this.activeBrandId);
            await this.refreshAvailableTopicTrees();
            this.allTopicTrees =  JSON.parse(JSON.stringify(Array.from(this.availableTopicTrees)));
            this.brandTopicTrees = this.brand.topicTrees ? this.brand.topicTrees : [];
            this.initTopicTreeList();
        } catch (e) {
            console.error("Error occurred during creation of topic tree picker dialog", e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('topicTrees', ['refreshAvailableTopicTrees', 'updateTopicTreesForBrand']),
        ...mapMutations(['setBrandTopicTrees']),

        initTopicTreeList() {
            console.log("this.brandTopicTrees", this.brandTopicTrees)
            for (let i = 0; i < this.allTopicTrees.length; i++) {
                let tree = this.allTopicTrees[i];

                let treeInAccount = this.brandTopicTrees.some(t => t.id === tree.id);
                let askCrowd = treeInAccount && this.brandTopicTrees.some(t => t.id === tree.id && t.active);

                // these new properties need to be reactive
                Vue.set(tree, 'inAccount', !!treeInAccount);
                Vue.set(tree, 'askCrowd', !!askCrowd);

                tree.visibleDescription = this.getDescription(tree).trim();
                tree.trimmedLabel = tree.label.trim(); // important for sorting to work
            }

            this.allTopicTrees.sort((a, b) => {
                return (b.inAccount - a.inAccount) || (b.askCrowd - a.askCrowd) || a.trimmedLabel.localeCompare(b.trimmedLabel)
            })
        },

        viewTree(tree) {
            var model = new Backbone.Model({id: this.brand.id, topicTreeId: tree.id, _canEdit: false});
            var view = new Beef.EditBrandTopicTree.View({model: model});
            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.$el);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        getDescription(tree) {
            if (tree.clientDescription) {
                return substituteTagParamaters(tree.clientDescription, [this.brand]).trim();
            } else {
                return substituteTagParamaters(tree.description, [this.brand]);
            }
        },

        searchQuery(tree) {
            let q = this.searchTerm.toLowerCase();
            return tree.trimmedLabel.toLowerCase().includes(q) || tree.visibleDescription.toLowerCase().includes(q)
                || tree.inAccount
        },

        async save() {
            let dto = this.createUpdateDTO()
            let changes = this.hasChanges(dto);
            let error = false;
            let undo

            try {
                this.saving = true;
                if (changes) {
                    await this.updateTopicTreesForBrand({brandId: this.brand.id, dto: dto});

                    undo = {
                        dto: {
                            oldTopicTreeIds: this.brand.oldTopicTreeIds,
                            activeTopicTreeIds: this.brand.activeTopicTreeIds
                        },
                        topicTrees: this.brand.topicTrees
                    }

                    // this matches the topicTrees list on the brand
                    dto.topicTrees = [] // {id: , label: "ZA Banking (current)", active: true, description: "New banking tree"}
                    this.allTopicTrees.forEach(t => {
                        if (t.inAccount) {
                            dto.topicTrees.push({ id: t.id, label: t.label, active: t.askCrowd, description: t.description})
                        }
                    })
                    dto.brandId = this.brand.id

                    this.setBrandTopicTrees(dto);   //update brand in brand store

                    // update backbone model in order to display correct trees on brand setup page
                    this.$emit('update-brand-topic-trees', dto);
                }
            } catch (e) {
                console.error("Error occurred while trying to update brand topic trees", e);
                error = true;
            } finally {
                this.saving = false;

                if (undo && !error) {
                    // We cannot destroy the dialog here because we need access to it in order to emit the event that updates the backbone model
                    // (this.$emit('update-brand-segments', this.oldObject)) on 'undo'
                    this.showDialog = false;

                    // undo button show
                    notifyUser({
                        message: this.brand.name + "'s topic trees have been updated.",
                        undo: async function () {
                            try {
                                await this.updateTopicTreesForBrand({brandId: this.brand.id, dto: undo.dto});

                                //update brand in brand store
                                undo.dto.brandId = this.brand.id
                                undo.dto.topicTrees = undo.topicTrees
                                this.setBrandTopicTrees(undo.dto);

                                // update backbone model in order to display correct trees on brand setup page
                                this.$emit('update-brand-topic-trees', undo.dto);

                                notifyWithText(`${this.brand.name}'s topic tree changes have been undone.`);
                            } catch (e) {
                                console.error("Error occurred while trying to undo brand topic tree changes", e);
                                notifyWithText(`Unable to undo ${this.brand.name}'s topic tree changes due to an error.`);
                            }
                        }.bind(this),
                        onClose: function () {
                            // destroy the dialog soon after the notify popup closes
                            setTimeout(() => this.$emit('close'), 2000);
                        }.bind(this)
                    });
                } else if (changes && error) {
                    notifyWithText(`Unable to save ${this.brand.name}'s topic tree changes due to an error.`);
                    this.$emit('close');
                } else {
                    // No changes were made -> nothing to undo, therefore we can destroy the dialog immediately
                    this.$emit('close');
                }
            }
        },

        createUpdateDTO() {
            let activeTopicTreeIds = [], oldTopicTreeIds = []
            this.allTopicTrees.forEach(t => {
                if (t.askCrowd && t.inAccount) activeTopicTreeIds.push(t.id)
                else if (t.inAccount) oldTopicTreeIds.push(t.id)
            })
            return {activeTopicTreeIds, oldTopicTreeIds}
        },

        hasChanges(dto) {
            dto = dto || this.createUpdateDTO()
            return !arrayEquals(this.brand.activeTopicTreeIds, dto.activeTopicTreeIds)
                || !arrayEquals(this.brand.oldTopicTreeIds, dto.oldTopicTreeIds)
        },

        close() {
            if (this.hasChanges()) {
                showWhenDialog(`You've made changes to ${this.brand.name}'s topic trees, are you sure you want to cancel them?`, "Cancel changes?")
                    .then(function () {
                        this.$emit('close');
                    }.bind(this));
            } else {
                this.$emit('close');
            }
        }
    }
}

function arrayEquals(a, b) {
    return ("" + a) === ("" + b)
}
</script>

<style scoped lang="sass">

.topic-tree-picker-dialog

    &__container
        height: 55vh
        min-height: 400px

    &__loader
        margin-top: 2vh
        animation: none

    &__tree-filters
        margin: 15px 0
        display: flex
        align-items: center

        .search-bar
            width: 70%

        .tree-list-select-container
            margin-left: auto
            display: flex
            align-items: center

            i
                padding-right: 10px

    &__bottom-button
        display: inline-block
        padding: 20px 0 0 10px
        width: 80px

        .save-spinner
            padding-left: 5px

    &__table-container
        width: 100%
        height: 45vh
        min-height: 330px
        overflow-y: auto

        th
            text-align: center
            background: #666
            white-space: nowrap

        td input[type="checkbox"]
            transform: scale(1.2)
            margin-bottom: 5px

        .cb
            text-align: center
            vertical-align: middle
            width: 20px

        .tree-name
            vertical-align: middle
            white-space: nowrap

        .tree-name:hover
            cursor: pointer
            color: #aee15d

        .tree-desc
            vertical-align: middle

@media screen and (max-width: 1580px)
    .topic-tree-picker-dialog
        ::v-deep .modal-container
            width: 75vw !important

        &__tree-filters

            .search-bar
                width: 65%

</style>