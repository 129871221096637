/**
 * Drill down by adding/removing CX related tags
 */
import {getOperationalSentimentFilter, getReputationalSentimentFilter} from "@/app/utils/Sentiment";
import {appendSegmentRestrictions} from "@/app/utils/Segments";
import {showTooltipText} from "@/components/tooltip/TooltipUtilities";

Beef.module("Widget.CxSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Customer Experience",
        width:          2,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        template: require("@/dashboards/widgets/selectors/CxSelector.handlebars"),

        events: Object.assign({}, Beef.Widget.SelectorWidget.View.prototype.events, {
            'mouseenter tr[data-tooltip]': 'showTooltip'
        }),

        filterAttribute: "_cxSelectorSelection",

        refresh() {
        },

        renderImpl() {
            this.model.generalData.set('_completed', true);
        },

        showTooltip(event) {
            showTooltipText(event.currentTarget, event.currentTarget.dataset.tooltip, {
                positions: ["right", "left"]
            })
        },

        async highlightSelectedRows(value, scroll) {
            const rows = this.$el.find('table.selector-widget-tiles .value');
            rows.toggleClass("selected", false);

            if (!value) return;
            const row = this.$el.find(`table.selector-widget-tiles .value[data-value='${value}']`);
            if (row) row.toggleClass("selected", true);
        },

        async tileClicked(event) {
            const value = event.currentTarget.dataset.value;
            const current = this.model.getInteractiveFilterModel().get(this.filterAttribute);

            this.model.getInteractiveFilterModel().set(this.filterAttribute, value !== current ? value : null);

            if (value === current) {
                this.model.getInteractiveFilterModel().set('_cxSelector', null);
                return;
            }

            let filter = value;
            switch (value) {
                case 'all': filter = await getOperationalSentimentFilter(null, null, true); break;
                case '4':   filter = appendSegmentRestrictions("tag is 4 and tag isnt 3 and tag isnt 2"); break;
                case '3':   filter = appendSegmentRestrictions("tag is 3"); break;
                case '2':   filter = appendSegmentRestrictions("tag is 2"); break;
                case 'non':
                    filter = await getReputationalSentimentFilter(null, null, true);
                    break;
            }

            this.model.getInteractiveFilterModel().set('_cxSelector', filter);
        }
    });
});