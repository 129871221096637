<template>
    <div>
        <div v-if="summary && debug && !loading">
            🐛<a :href="`https://turducken.dataeq.com/pages/summaries/${summary.id}`"
                tooltip="Open on turducken"
                target="_blank"> {{summary.id}}</a>
        </div>
        <WithSummaryCard :summary="summary" :state="state" v-slot="{text}">
            <slot :summary="summary" :loading="loading" :text="text">
                <div class="with-summary deq-reset">
                    <div v-if="loading">
                        <inline-loading-message>
                            Summarising your mentions...
                        </inline-loading-message>
                    </div>
                    <div v-else-if="summary" class="summary-text">
                        {{text}}
                    </div>
                    <div v-else>
                        <no-topics-message>No summary available</no-topics-message>
                    </div>
                </div>
            </slot>
        </WithSummaryCard>
    </div>

</template>

<script>

import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage.vue";
import InlineLoadingMessage from "@/components/InlineLoadingMessage.vue";
import { isDebugModeEnabled } from "@/app/Features";
import { summariseFilter } from "@/app/utils/turducken";
import {isDevEnvironment} from "@/app/utils/Account";
import {isMashAdmin} from "@/app/Permissions";
import WithSummaryCard from "@/app/toplevel/explore/overview/components/summaries/WithSummaryCard.vue";

export default {
    components: {WithSummaryCard, InlineLoadingMessage, NoTopicsMessage},

    props: {
        filter: String,
        state: { type: String, required: false }
    },

    data() {
        return {
            loading: false,
            debug: isDebugModeEnabled(),

            summary: null,

            abortController: null,
        }
    },

    watch: {
        async filter() {
            await this.loadSummary();
        }
    },

    async mounted() {
        await this.loadSummary();
    },

    methods: {
        async refresh() {
            return this.loadSummary();
        },

        async loadSummary() {
            try {
                if (this.loading) {
                    if (this.abortController) {
                        this.abortController.abort();
                        this.abortController = null;
                    }
                }

                if (!this.filter) {
                    this.loading = false;
                    return;
                }

                this.loading = true;
                this.summary = null;
                this.abortController = new AbortController();
                this.summary = await summariseFilter(this.filter, this.abortController);
                this.loading = false;
                this.abortController = null;

                this.$emit("summary", this.summary);
            } catch (e) {
                if (e.message === "canceled") return;

                if (e.response?.status === 403 || e.response?.status === 401) {
                    console.error("The user is not authorised to use turducken or the API");
                }

                this.loading = false;
                if (this.abortController) this.abortController.abort();
                this.abortController = null;
                this.summary = null;

                console.error(e);
            }
        }
    }
}

</script>

<style scoped lang="sass">

:where(.with-summary)
    display: contents

</style>