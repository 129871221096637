<template>
    <section>
        <overview-title>Enterprise mentions from </overview-title>
        <loading-message v-if="storeLoading" message="Examining your mentions..."/>
        <overview-description v-else-if="enterpriseCount > 0">
            <h4>
                The most engaging, top-level, Enterprise mentions {{ englishDate }}
            </h4>
            <p>
                The brand posted <strong><deq-number :number="enterpriseCount"/> {{ formatPlural(enterpriseCount, 'mention') }} {{englishDate}}</strong>.
                You can see all of them in the <a @click.prevent="gotoEnterpriseMentions()" :href="enterpriseMentionsLink"><i class="symbol-mentions"></i>Mentions panel</a>,
                or <a @click.prevent="createDashboard" href="#"><i class='symbol-reports'></i>create a dashboard</a> related to these mentions.
            </p>
        </overview-description>
        <no-topics-message v-else-if="enterpriseCount === 0">
            {{ formatBrandName(brand) }} has not posted in this time
        </no-topics-message>

        <engaging-mentions v-if="!storeLoading && brand && enterpriseCount > 0" :filter="filter" :date-filter="dateFilter"/>
    </section>
</template>

<script>
import EngagingMentions from "@/app/toplevel/explore/overview/components/EngagingMentions";
import {mapGetters, mapState} from 'vuex';
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";
import DeqNumber from "@/components/formatters/DeqNumber";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import LoadingMessage from "@/components/LoadingMessage";
import moment from "moment";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {createSimpleDashboardForFilter} from "@/app/toplevel/dashboards/BeefModuleDashboardUtilities";
import {formatBrandName, formatPlural} from "@/app/utils/Format";
import {getExploreEnterpriseFilter} from "@/app/toplevel/explore/overview/ExploreUtilities";

export default {
    components: {DeqNumber, OverviewDescription, NoTopicsMessage, LoadingMessage, OverviewTitle, EngagingMentions},

    computed: {
        ...mapState(['account']),
        ...mapGetters('explorePanel', ['brandName', 'enterpriseCount', 'dateFilter', 'englishDate']),
        ...mapState('explorePanel', {
            brand: state => state.brand,
            storeLoading: state => state.loading
        }),

        enterpriseMentionsLink() {
            if (!this.filter) return "#";
            return `/${this.account.code}/mentions?filter=${encodeURIComponent(this.filter)}&orderby=engagement desc`;
        },

        filter() {
            if (!this.brand) return null;
            return getExploreEnterpriseFilter(this.brand.id, this.dateFilter);
        }
    },

    methods: {
        formatPlural,
        formatBrandName,

        gotoEnterpriseMentions() {
            if (!this.filter) return;
            gotoMentionPanel(this.filter, "engagement desc");
        },

        createDashboard() {
            if (this.filter) {
                createSimpleDashboardForFilter(
                    this.filter,
                    `Exploring enterprise mentions for ${this.brandName} - ${moment().format("YYYY-MM-DD")}`,
                    "Enterprise mentions"
                );
            }
        }
    }

}
</script>



<style scoped lang="sass">

</style>