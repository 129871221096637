import Vue from "vue";
import Vuex from "vuex";
import {mash} from "@/store/Services";

Vue.use(Vuex);

let refreshPromise = null;

export default {
    namespaced: true,

    state: () => ({
        availableTopicTrees: null
    }),

    mutations: {
        setAvailableTopicTrees: (state, value) => {
            state.availableTopicTrees = value
        }
    },

    getters: {

    },

    actions: {
        async refreshAvailableTopicTrees({state, commit}, forceRefresh) {
            forceRefresh ??= false;

            if (refreshPromise) return refreshPromise;
            if (state.availableTopicTrees && !forceRefresh) return;

            try {
                refreshPromise = mash.get("/rest/topics/trees/list?type=TREE");
                const res = await refreshPromise;
                await commit('setAvailableTopicTrees', res.data);
            } catch(e) {
                console.error(e);
            } finally {
                refreshPromise = null;
            }
        },

        async updateTopicTreesForBrand({rootState}, {brandId, dto}) {
            return mash.put("/rest/accounts/" + rootState.account.code + "/brands/" + brandId, dto);
        }
    }
};

export const BrandTopicTreeMutations = {

    setBrandTopicTrees: (state, {brandId, activeTopicTreeIds, oldTopicTreeIds, topicTrees}) => {
        let brand = state.rootBrands.find(b => b.id === brandId)
        if (brand) {
            brand.activeTopicTreeIds = activeTopicTreeIds;
            brand.oldTopicTreeIds = oldTopicTreeIds;
            brand.topicTrees = topicTrees;
        }
    }

};