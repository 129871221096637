<template>
    <be-button link
               :tooltip="tooltip"
               :disabled="disabled"
               @click="showMentions($event)">
        <slot><i class="symbol-mentions"></i>See mentions</slot>
    </be-button>
</template>


<script>
import BeButton from "@/components/buttons/BeButton";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";

export default {
    components: {BeButton},

    props: {
        filter: {
            type: String
        },
        title: {
            type: String
        },
        noLda: {
            type: Boolean,
            default: true
        },

        tooltip: {
            type: String,
            default: () => "Click to see these mentions"
        },
        disabled: {
            type: Boolean,
            default: () => false
        }
    },

    methods: {
        showMentions(event) {
            if (this.filter) {
                showMentions(this.filter, this.title, this.noLda);
            }
            this.$emit('click', event);
        }
    }
};
</script>