var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"mention-item\">\n"
    + ((stack1 = __default(require("../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"reshareOfUri") : depth0),"||",(depth0 != null ? lookupProperty(depth0,"replyToUri") : depth0),{"name":"ifcond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"author\">\n            <img id=\"m"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"avatar\" src=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"twitterMugshot") : depth0), depth0))
    + "\">\n            <a href=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"uri") : depth0), depth0))
    + "\" target=\"_blank\">\n                <div class=\"name\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"twitterName") : depth0), depth0))
    + "</div>\n                <div class=\"screen-name\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"twitterScreenName") : depth0), depth0))
    + "</div>\n            </a>\n        </div>\n        <div class=\"content\">"
    + alias3(__default(require("../../../helpers/render-emoji.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":51}}}))
    + "</div>\n        <div class=\"footer\">\n            <a class=\"date\" href=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"uri") : depth0), depth0))
    + "\" target=\"_blank\">"
    + alias3(__default(require("../../../helpers/humanizeDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishedDate") : depth0),true,{"name":"humanizeDate","hash":{},"data":data,"loc":{"start":{"line":18,"column":64},"end":{"line":18,"column":104}}}))
    + "</a>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"reshare-reply-ribbon\"\n                 title=\"This mention is a reshare of or reply to another mention\">\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"filter") : depth0), depth0))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"mentions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":21,"column":9}}})) != null ? stack1 : "");
},"useData":true});