import { features } from "@/app/Features";
import { deprecatedFetchTags } from "@/data/DeprecatedBeefCache";
import { deprecatedSnoekGetJson } from "@/data/Snoek";
import { profileTypes } from "@/setup/profiles/ProfileUtils";
import moment from "moment";
import _ from 'underscore';


Beef.module("OnlineProfileList").addInitializer(function(startupOptions) {

    var maxOnlineProfiles = startupOptions.account.maxOnlineProfiles;

    /**
     * Merges in latest measurement data from Snoek when the profiles are fetched from Mash.
     * This can be avoided by passing a skipMeasurements argument in the options.
     */
    this.Collection = Backbone.Collection.extend({
        model: Beef.OnlineProfileItem.Model,

        initialize: function(models, options) {
            this.accountCode = options.accountCode;
            this.latestMeasurements = {};
            this.urlRoot = Beef.Sync.toMashUrl('accounts/' + this.accountCode + '/online-profiles');
            this.skipMeasurements = options.skipMeasurements;
        },

        parse: function(data) {
            for (var i = 0; i < data.length; i++) {
                var p = data[i];
                p._accountCode = this.accountCode;
                var m = this.latestMeasurements[p.id];
                if (m) Object.assign(p, m)
            }
            return data;
        },

        fetch: function(includeDeleted) {
            this.url = this.urlRoot + (includeDeleted ? "?includeDeleted=true" : "");
            if (!this.skipMeasurements) {
                deprecatedSnoekGetJson("accounts/" + this.accountCode + "/online-profiles/latest-measurements", null,
                    function(data) {
                        var keys = _(data).keys();
                        for (var i = 0; i < _(data).size(); i++) {
                            delete data[keys[i]].id;
                            delete data[keys[i]].profile;
                        }
                        this.latestMeasurements = data;
                        this.updateItemsWithMeasurements();
                    }.bind(this));
            }
            return Backbone.Collection.prototype.fetch.call(this, {urlRoot: this.urlRoot});
        },

        updateItemsWithMeasurements: function() {
            for (var i = 0; i < this.length; i++) {
                var p = this.at(i);
                var m = this.latestMeasurements[p.id];
                if (m) {
                    delete m.id;
                    delete m.profile;
                    p.set(this.isolateStats(m));
                }
            }
        },

        isolateStats: function(m) {
            if (m.type === "FACEBOOK_PAGE") return {likes: m.likes};
            if (m.type === "INSTAGRAM_USER") return {followerCount: m.followerCount};
            if (m.type === "TWITTER_SCREEN_NAME") return {followerCount: m.followerCount};
            if (m.type === "YOUTUBE_CHANNEL") return {subscribers: m.subscribers};

            return {};
        },

        updateWithSmileyErrors: function() {
        },

        getAccountBrands: function () {
            Beef.Sync.mashGET("accounts/" + this.accountCode + "/brands", { includeStats: true },
                function(data){
                    this.accountBrands = data;
                }.bind(this));
        },

        /**
         * Sets the tags on all items in the collections from the given input.
         * @param tagsById A map of tags by id.
         */
        updateAllTags: function(tagsById) {
            for (var i = 0; i < this.length; i++) {
                this.at(i).updateTags(tagsById);
            }
        }
    });

    this.View = Backbone.Marionette.CompositeView.extend({
        getTemplate: function() {
            switch (this.options.display) {
                case 'short': return require("@/setup/online/OnlineProfileListShort.handlebars");
                case 'full':
                default:      return require("@/setup/online/OnlineProfileList.handlebars");
            }
        },

        itemView: Beef.OnlineProfileItem.View,

        attributes: { class: 'row-fluid online-presence-list' },

        itemViewContainer: ".rows",

        collectionEvents: {
            "sync": "collectionReset",
            "reset": "collectionReset",
            "error": "collectionError"
        },

        events: {
            "click .show-deleted": "showDeletedChanged",
            "click .download-profiles-csv": "bulkProfileDownload",
            "click #external-auth": "generateExternalAuthLink",
            "click .presence .toggle": "toggleProfileTypeCheckbox",
            "click .authorise-head .toggle": "toggleProfileAuthorizedCheckbox",
            "click .online-presence-table thead .checkbox": "updateProfileItemVisibility"
        },

        initialize: function(options) {
            this.eventBus = options.eventBus;
            this.facebookAuthorised = startupOptions.user.facebookAuthorised;
            this.hiddenRows = {};
        },

        templateHelpers: function() {
            return { facebookAuthorised: this.facebookAuthorised };
        },

        itemViewOptions: function() {
            return {
                accountCode: this.collection.accountCode, cache: this.cache,
                display: this.options.display,
                select: this.options.select,
                eventBus: this.eventBus
            }
        },

        onAfterItemAdded: function(view) {
            view.model.view = view;
        },

        isShowDeletedChecked: function() {
            return this.$("input.show-deleted").is(":checked");
        },

        showDeletedChanged: function() {
            this.fetch();
        },

        fetch: function() {
            this.$el.toggleClass("online-presence-list-loading", true);
            this.$('.no-authors').toggle(false);
            var that = this;
            $.when(this.collection.fetch(this.isShowDeletedChecked())).then(function() {
                // only show profiles that should be visible (based on feature flag)
                let profileModelsToRemove = [];
                this.collection?.models?.forEach(profileModel => {
                    if (profileModel.get("type") === profileTypes.trustpilot)
                        profileModelsToRemove.push(profileModel);
                });
                profileModelsToRemove.forEach(profileModel => {
                    this.collection.remove(profileModel);
                });

                this.collection.updateWithSmileyErrors();
                this.collection.getAccountBrands();
                deprecatedFetchTags(this.collection.accountCode, function(tagsById) {
                    that.collection.updateAllTags(tagsById);
                }, this.cache);
            }.bind(this));
        },

        onRender: function() {
            if (this.options.display == "short") {
                // render blank cells after items so each row has 3 cells
                var blanks = (4 - this.collection.length % 4) % 4;
                if (blanks) {
                    var $rows = this.$(".rows");
                    for (; blanks > 0; blanks--) $rows.append("<div class='online-presence-item'></div>")
                }
            }
        },

        collectionReset: function() {
            this.$el.toggleClass("online-presence-list-loading", false);
            this.$('.busy').hide();
            this.$('.online-presence-table').show();
            this.$('.online-presence-table').toggleClass("animated fadeIn", true);
            this.$('.no-online-presences').toggle(this.collection.size() == 0);
            this.$('.options').toggle(true);
        },

        collectionError: function() {
        },


        bulkProfileDownload: function (ev) {
            // Add the UTF-8 BOM to the header so that Excel + Windows correctly interprets and opens this file.
            var csv = '\ufeffhandle,category,type\n';
            this.collection.models.forEach(function (model) {
               csv += '"' + model.get('handle').replace(/"/g, '""') + '",' + (model.get('media') || '') + ',' + model.get('type') + '\n';
            });

            var link = document.createElement("a");
            link.style = "display: none";
            link.href = window.URL.createObjectURL(new Blob([csv], {type: "text/csv"}));
            link.download = "online-profiles-" + this.collection.accountCode + "-" + (moment().format("YYYY-MM-DD-HH[h]mm")) + ".csv";
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        },

        generateExternalAuthLink: function (ev, title, message, message2) {
            ev.preventDefault();
            var showTitle = title ? title: "Instagram Authorisation Link";
            var msg = message ? message : "The link below can be used to add & authorise Instagram profiles from outside DataEQ Analyse.";
            var msg2 = message2 ? message2 : null;

            $.ajax({
                url: "/api/online-profile/createInstagramState",
                data: {
                    code: this.collection.accountCode
                },
                success: function(data) {
                    var link = "https://analyse.dataeq.com/api/online-profile/instagramexternal?state=" + data.state;

                    Beef.GenericMsg.show({title: showTitle, authLink: {msg: msg, link: link, msg2: msg2}});
                }.bind(this)
            });
        },

        /**
         * Shows and hides elements depending on whether a user has logged in with Facebook.
         * @param isAuthorized Set to true if the current logged in user has logged in with Facebook.
         */
        handleLoggedInWithFacebook: function(isAuthorized) {
            this.$('.facebook-user-unauthorized').toggleClass("hidden", isAuthorized);
            this.$('.facebook-user-authorized').toggleClass("hidden", !isAuthorized);
        },

        /**
         * Updates the current view with the new Facebook user token.
         * @param token A sanitized Facebook token object.
         */
        onFacebookLoginSuccess: function(token) {
            this.handleLoggedInWithFacebook(true);
            if (!this.facebookAuthorised) {
                this.facebookAuthorised = [];
            }
            this.facebookAuthorised.push(token);
        },

        toggleProfileTypeCheckbox: function(ev) {
            ev.preventDefault();
            this.$(".presence .toggle .plus").toggle();
            this.$(".presence .toggle .minus").toggle();
            this.$(".presence .profile-types").toggle();
        },

        toggleProfileAuthorizedCheckbox: function(ev) {
            ev.preventDefault();
            this.$(".authorise-head .toggle .plus").toggle();
            this.$(".authorise-head .toggle .minus").toggle();
            this.$(".authorise-head .profile-authorized").toggle();
        },

        /**
         * Toggles the visibility of rows in the profile table.
         * @param ev A click event.
         */
        updateProfileItemVisibility: function(ev) {
            var hiddenRows = this.hiddenRows;
            var attr = ev ? ev.target.dataset.attr : null;
            if (attr) {
                hiddenRows[attr] = !hiddenRows[attr];
            }
            this.$("table.online-presence-table tbody").find("tr.online-presence-item").each(function() {
                var type = this.dataset.type;
                var authorized = this.dataset.authorized;
                var el = $(this);
                if (hiddenRows["facebook"] && (type === "FACEBOOK_PAGE" || type === "FACEBOOK_USER") ) {
                    el.hide();
                } else if (hiddenRows["twitter"] && type === "TWITTER_SCREEN_NAME") {
                    el.hide();
                } else if (hiddenRows["instagram"] && (type === "INSTAGRAM_BUSINESS" || type === "INSTAGRAM_USER")) {
                    el.hide();
                } else if (hiddenRows["linkedin"] && type === "LINKEDIN_COMPANY") {
                    el.hide();
                } else if (hiddenRows["youtube"] && type === "YOUTUBE_CHANNEL") {
                    el.hide();
                } else if (hiddenRows["telegram"] && type === "TELEGRAM_CHANNEL") {
                    el.hide();
                } else if (hiddenRows["authorized"] && authorized === "true") {
                    el.hide();
                } else if (hiddenRows["unauthorized"] && authorized === "false") {
                    el.hide();
                } else {
                    el.show();
                }
            });
        }
    });
});