<template>
    <span class="rpcs-tag">
        <span :class="classes"><span class='be-rpcs__label'></span></span>
    </span>
</template>

<script>
import {isRpcsFlag} from "@/app/utils/Metatags";

export default {
    props: {
        code: {
            type: String,
            required: true,
            validator: function (value) {
                return isRpcsFlag(value);
            }
        },

        full: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        classes() {
            let classes = "be-rpcs be-rpcs--" + this.code.toLowerCase();

            if (this.full) {
                classes += " be-rpcs--full";
            }

            return classes;
        }
    }

}
</script>


<style>

@import "../../app/utils/Metatags.css";


</style>