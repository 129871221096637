<template>
    <section v-if="brand && brandHasCx" class="explore-cx">
        <overview-title>Customer Experience for </overview-title>

        <stat-row>
            <dark-card v-if="cxPercent !== null" popup-direction="down">
                <h4><deq-percentage :percent="cxPercent" is-proportion :precision="1"/> CX</h4>
                <template #popup>
                    <strong>
                        <deq-percentage :percent="cxPercent" is-proportion :precision="1"/>
                        of all verified mentions
                    </strong>

                    were CX
                </template>
            </dark-card>

            <dark-card v-if="cxResharePercent !== null" popup-direction="down">
                <h4><deq-percentage :percent="cxResharePercent" is-proportion :precision="1"/> CX reshares</h4>
                <template #popup>
                    CX reshares make of
                    <strong>
                        <deq-percentage :percent="cxResharePercent" is-proportion :precision="1"/>
                        of all verified mentions
                    </strong>
                </template>
            </dark-card>

            <dark-card v-if="brandHasDms && publicPercent !== null" popup-direction="down">
                <h4><deq-percentage :percent="publicPercent" is-proportion :precision="1"/> public</h4>
                <template #popup v-if="privatePercent !== null">
                    <deq-percentage :percent="publicPercent" is-proportion :precision="1"/> public
                    <br>
                    <deq-percentage :percent="privatePercent" is-proportion :precision="1"/> private
                </template>
            </dark-card>
            <dark-card v-if="!brandHasDms && cxStats" disabled>
                <span class="explore-cx__disabled-text">
                    This brand does not collect <em>direct messages</em>
                </span>
            </dark-card>

            <dark-card v-if="accountHasPurchase && purchasePercent !== null" popup-direction="down">
                <h4><deq-percentage :percent="purchasePercent" :precision="1" is-proportion/> purchase</h4>
                <template #popup>
                    Purchase mentions make up

                    <strong>
                        <deq-percentage :percent="purchasePercent" :precision="1" is-proportion/>
                        of CX mentions
                    </strong>
                </template>
            </dark-card>

            <dark-card v-if="accountHasCancel && cancelPercent !== null" popup-direction="down">
                <h4><deq-percentage :percent="cancelPercent" :precision="1" is-proportion/> cancel</h4>
                <template #popup>
                    Cancellation mentions make up

                    <strong>
                        <deq-percentage :percent="cancelPercent" :precision="1" is-proportion/>
                        of CX mentions
                    </strong>
                </template>
            </dark-card>
        </stat-row>

        <overview-description v-if="brandHasTopics">
            <h4>Trending Customer Experience factors {{trendingForWhat}}</h4>
            <p>
                The following topics have been trending {{englishDate}} in relation
                to {{brandName}}'s Customer Experience.
                You can
                <span v-if="cxDashboard">view your <a :href="dashboardLink" @click.prevent="gotoDashboard()">{{ cxDashboard.name }}</a> dashboard, or </span>
                <a href="#" @click.prevent="createDashboard()"><i class='symbol-reports'></i>create a dashboard</a> to further
                explore this data.
            </p>
        </overview-description>

        <div class="explore-cx__table-section" v-if="brandHasTopics">
            <div class="explore-cx__table">
                <trend-table :topics="topics" with-max-date with-average :disabled="loading"
                             @seeMentions="seeMentions($event)"
                             @seeWordcloud="seeWordcloud($event)"
                             @seeAuthors="seeAuthors($event)"
                             with-footer/>
                <loading-message v-if="loading && !topics.length" message="Reading your trending CX topics..."/>
                <short-loading v-if="loading && topics.length"/>
                <no-topics-message v-if="!loading && !topics.length"/>
                <most-frequent-topic v-if="topicFilter"
                                     :filter="topicFilter"
                                     class="explore-cx__most-frequent animated fadeIn delay-1000"/>
            </div>
            <navigation-controls :show-which-mentions="false"
                                 :initial-show="show"
                                 :initial-visibility="visibility"
                                 show-cx
                                 :disable-non-public-reputation="false"
                                 :disable-non-public-operational="false"
                                 :loading="loading"
                                 :brand="brand"
                                 @show="show = $event"
                                 @visibility="visibility = $event"
                                 @filter="additionalFilter = $event"/>
        </div>

        <overview-description v-if="filter">
            <h4>{{showToLabel}}, with the highest engagement</h4>
            <p>
                <net-sentiment-calculator v-if="filter"
                                          :filter="filter"
                                          v-slot="{netSentiment, delta}">

                    The overall Net Sentiment for these mentions is
                    <strong style="white-space: nowrap">
                        <net-sentiment :net-sentiment="netSentiment"/>
                        &nbsp;<percentage-point-delta v-if="delta !== null" :delta="delta"/>.
                    </strong>
                </net-sentiment-calculator>
                You can see more mentions on the <a @click.prevent="gotoMentions()" :href="mentionPanelLink"><i class="symbol-mentions"></i>Mentions panel</a>.
            </p>
        </overview-description>

        <with-mentions v-if="filter" :filter="filter" :num-mentions="9" :order-by="['engagement desc']">
            <template #error-message>
                <no-topics-message>
                    We're not able to read your mentions at the moment. Please
                    try again in a few minutes.
                </no-topics-message>
            </template>
        </with-mentions>
    </section>
    <section v-else-if="brand">
        <overview-title>Customer Experience for </overview-title>
        <no-topics-message>
            {{formatBrandName(brand)}} does not have <em>Customer Experience</em>
        </no-topics-message>
    </section>
</template>


<script>
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";
import {mapActions, mapGetters, mapState} from "vuex";
import DeqPercentage from "@/components/formatters/DeqPercentage";
import TrendTable from "@/app/toplevel/explore/overview/TrendTable";
import {getTrendingTopicFilter, loadTrendingTopics,} from "@/app/toplevel/explore/overview/ExploreUtilities";
import NavigationControls from "@/app/toplevel/explore/overview/components/NavigationControls";
import MostFrequentTopic from "@/app/toplevel/explore/overview/components/MostFrequentTopic";
import LoadingMessage from "@/components/LoadingMessage";
import ShortLoading from "@/app/toplevel/explore/overview/components/ShortLoading";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import StatRow from "@/app/toplevel/explore/overview/components/StatRow";
import WithMentions from "@/app/toplevel/mentions/components/WithMentions";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import PercentagePointDelta from "@/components/formatters/PercentagePointDelta";
import NetSentiment from "@/components/NetSentiment";
import NetSentimentCalculator from "@/app/toplevel/explore/overview/components/NetSentimentCalculator";
import {formatBrandName} from "@/app/utils/Format";
import {createCxDashboard} from "@/app/toplevel/explore/overview/OverviewDashboards";
import DarkCard from "@/components/cards/DarkCard";
import {showTip} from "@/app/help/tips/tips";
import {gotoDashboard} from "@/app/toplevel/dashboards/DashboardUtilities";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";


export default {
    components: {
        DeqPercentage,
        OverviewDescription,
        DarkCard,
        NetSentimentCalculator,
        NetSentiment,
        PercentagePointDelta,
        WithMentions,
        StatRow,
        NoTopicsMessage,
        ShortLoading,
        LoadingMessage,
        MostFrequentTopic, NavigationControls, TrendTable,
        OverviewTitle
    },

    data() {
        return {
            loading: false,
            topics: [],
            additionalFilter: "tag is 4 and visibility is public",
            topicFilter: null,
            show: 'cx',
            visibility: 'all'
        }
    },

    computed: {
        ...mapState(['account']),

        ...mapState('explorePanel', [
            'brand',
            'cxStats',
            'brandHasTopics',
            'brandHasDms',
            'brandHasCx',
            'accountHasCancel',
            'accountHasPurchase',
            'brandHasDms',
            'isInitialised'
        ]),

        ...mapGetters('explorePanel', [
            'overallCount', 'consumerCount', 'totalVerified', 'dateFilter', 'englishDate', 'brandName'
        ]),


        ...mapGetters('dashboards', ['cxDashboard']),

        trendingForWhat() {
            switch (this.show) {
                case 'service':
                case 'cancel':
                case 'purchase':
                    return " for " + this.show;
                case 'risk':
                case 'conduct':
                    return " related to " + this.show;
                default: return null;
            }
        },

        dashboardLink() {
            if (!this.cxDashboard) return '#';
            return `/${this.account.code}/dashboards/${this.cxDashboard.id}`;
        },

        mentionPanelLink() {
            if (!this.filter) return "#";
            return `/${this.account.code}/mentions?filter=${encodeURIComponent(this.filter)}&orderby=engagement desc`;
        },

        showToLabel() {
            let noun;
            switch(this.show) {
                case 'cx':
                    noun = "Customer Experience"; break;
                case 'rep':
                    noun = "Non-CX"; break;
                case 'purchase':
                    noun = "Purchase-related"; break;
                case 'cancel':
                    noun = "Cancellation-related"; break;
                case 'service':
                    noun = "Service-related"; break;
                default:
                    return this.visibility + ' ' + this.show;
            }

            let post = 'mentions';
            let pre = '';

            if (this.visibility === 'private') post = 'direct mentions';
            if (this.visibility === 'public' && this.show !== 'cx') post = 'public mentions';
            if (this.visibility === 'public' && this.show === 'cx') pre = 'Public';

            return (pre + ' ' + noun + ' ' + post);
        },

        filter() {
            if (!this.brand) return null;
            let filter = `(${this.dateFilter}) and brand isorchildof ${this.brand.id} and relevancy isnt irrelevant`;
            if (this.additionalFilter) filter = appendFiltersReadably(filter, this.additionalFilter);
            return filter;
        },

        cxPercent() {
            if (isFinite(this.cxStats?.total) && this.totalVerified) {
                return this.cxStats.total / this.totalVerified;
            }
            return null;
        },

        cxResharePercent() {
            if (isFinite(this.cxStats?.reshares) && this.totalVerified) {
                return this.cxStats.reshares / this.totalVerified;
            }
            return null;
        },

        publicPercent() {
            if (isFinite(this.cxStats?.total) && isFinite(this.cxStats.public)) {
                return this.cxStats.public / this.cxStats.total;
            }
            return null;
        },

        privatePercent() {
            if (isFinite(this.cxStats?.total) && isFinite(this.cxStats.private)) {
                return this.cxStats.private / this.cxStats.total;
            }
            return null;
        },

        purchasePercent() {
            if (isFinite(this.cxStats?.total) && isFinite(this.cxStats.purchaseCount)) {
                return this.cxStats.purchaseCount / this.cxStats.total;
            }
            return null;
        },

        cancelPercent() {
            if (isFinite(this.cxStats?.total) && isFinite(this.cxStats.cancelCount)) {
                return this.cxStats.cancelCount / this.cxStats.total;
            }
            return null;
        }

    },

    watch: {
        brand() {
            this.loadTopics();
        },
        additionalFilter() {
            this.loadTopics();
        },
        dateFilter() {
            this.loadTopics();
        }
    },

    async mounted() {
        await this.loadTopics();
        await this.refreshDashboards();
        await showTip("EXPLORE_DASHBOARD");
    },

    methods: {
        formatBrandName,

        ...mapActions('dashboards', ['refreshDashboards']),

        gotoDashboard() {
            if (this.cxDashboard) {
                gotoDashboard(this.cxDashboard.id);
            }
        },

        async createDashboard() {
            await createCxDashboard(
                await getTrendingTopicFilter(this.brand.id, this.dateFilter, null, false),
                `Exploring Customer Experience for ${formatBrandName(this.brand)}`,
                "From Explore"
            );
        },

        gotoMentions() {
            if (!this.filter) return;
            gotoMentionPanel(this.filter, ["engagement desc"]);
        },

        async loadTopics() {
            if (!this.brand) return;
            if (this.loading) return;

            try {
                this.loading = true;
                this.topicFilter = null;

                const results = await loadTrendingTopics(this.brand.id, this.dateFilter, this.additionalFilter);

                this.topics = results?.trendingTopics ?? [];
                this.topicFilter = await getTrendingTopicFilter(this.brand.id, this.dateFilter, this.additionalFilter);

                // Don't need a loading signal from here onwards.
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },

        seeMentions(topic) {
            let filter = appendFiltersReadably(this.topicFilter, `topic is ${topic.topic.id}`);
            showMentions(
                filter,
                `Mentions for '${topic.topic.name}', (by engagement)`,
                true
            );
        },

        seeWordcloud(topic) {
            let filter = appendFiltersReadably(this.topicFilter, `topic is ${topic.topic.id}`);
            showWordcloud(
                filter,
                `Mentions for '${topic.topic.name}'`
            );
        },

        seeAuthors(topic) {
            let filter = appendFiltersReadably(this.topicFilter, `topic is ${topic.topic.id}`);
            gotoAuthorsPanel(filter);
        },

    }
}
</script>


<style scoped lang="sass">

.explore-cx
    color: var(--be-colour-text-dark)

.explore-cx__table-section
    width: max-content
    margin: 30px auto
    display: flex
    flex-direction: row
    min-height: 500px

    .explore-cx__table
        width: 800px
        margin-right: 20px

    .explore-cx__most-frequent
        display: block
        margin-top: 10px
        text-align: center

.explore-cx__disabled-text
    text-align: center
    padding: 10px
    margin: 0
    box-sizing: border-box
    color: var(--be-colour-muted-text-dark)
    font-style: italic
    em
        font-style: normal

.explore-stat-block
    width: 150px



</style>