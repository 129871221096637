/**
 * Select one or more gender options. Fires a change event when the selection is changed. Also provides a model binder
 * converter to display the selected options in an element.
 */
import {GENDER_ITEMS} from "@/app/utils/Gender";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("GenderPicker").addInitializer(function(startupOptions) {

    this.items = GENDER_ITEMS;

    this.View = Beef.Picker.View.extend({
        attributes: { class: "gender-picker" },
        template: require("@/dashboards/filter/pickers/gender/GenderPicker.handlebars"),
        items: this.items
    });

    this.converter = createTagConverter({
        tooltip: function(code) {
            if (code == "UNKNOWN" || code == "OTHER") return GENDER_ITEMS[code] + " gender";
            else return GENDER_ITEMS[code];
        },
        items: this.items,
        placeholder: "Gender"
    });

    /**
     * Attach a gender picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
