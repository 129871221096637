import Vue from "vue";
import Vuex from "vuex";
import {mash} from "@/store/Services";
import {Notification} from "@/app/utils/types";

Vue.use(Vuex);

let refreshPromise = null;
let logPromise = null;
let NOTIFICATION_PARENT = new Notification();

export default {
    namespaced: true,

    state: {
        digests: null,
        refreshing: false,

        alertLogs: null
    },

    getters: {
        loading: state => state.refreshing && state.digests === null,
        alerts: state => state.digests?.filter(d => d.type === 'SPIKE_ALERT' && d.externalId),
        riskAlerts: state => state.digests?.filter(d => d.type === 'SPIKE_ALERT' && d.externalId?.endsWith(':risk')),
        idToDigest: state => {
            if (!state.digests?.length) return new Map();
            const map = new Map();
            for (const digest of state.digests) {
                map.set(digest.id, digest);
            }
            return map;
        },
        idToIndex: state => {
            if (!state.digests?.length) return new Map();
            const map = new Map();
            for (let i = 0; i < state.digests.length; i++) {
                map.set(state.digests[i].id, i);
            }
            return map;
        }
    },

    mutations: {
        setDigests: (state, value) => {
            if (value?.length) {
                value.forEach(d => d.__proto__ = NOTIFICATION_PARENT);
            }
            state.digests = value;
        },
        setRefreshing: (state, value) => state.refreshing = value,
        setAlertLogs: (state, value) => state.alertLogs = value,
        updateNotificationFields: (state, {index, fields}) => {
            const notification = state.digests[index];
            Object.keys(fields).forEach(key => {
                if (key === 'id') return;
                if (!notification) return;
                notification[key] = fields[key];
            })
        }
    },

    actions: {
        /**
         * Use this if you want to update just the notification in the store, without updating
         * the server.
         * @param fields An object with a notification id, and the fields that should be updated with their new values.
         */
        async updateNotificationFields({state, commit, getters}, fields) {
            const index = getters.idToIndex.get(fields.id);
            await commit('updateNotificationFields', {index, fields});
            return state.digests[index];
        },

        async refreshDigests({state, commit, rootState}, forceRefresh) {
            forceRefresh ??= false;

            if (refreshPromise) return refreshPromise;
            if (state.digests && !forceRefresh) return;

            try {
                commit('setRefreshing', true);
                refreshPromise = mash.get("/rest/accounts/" + rootState.account.code + "/digests");
                const res = await refreshPromise;
                await commit('setDigests', res.data);
            } catch(e) {
                console.error(e);
            } finally {
                refreshPromise = null;
                commit('setRefreshing', false);
            }
        },

        async createDigest({state, commit, rootState, getters}, notification) {
            const res = await mash.post("/rest/accounts/" + rootState.account.code + "/digests", notification);
            const digest = res.data;
            await commit('setDigests', [digest, ...(state.digests ?? [])]);
            return getters.idToDigest.get(digest.id);
        },

        async updateDigest({state, commit, rootState, getters}, digest) {
            const response = await mash.put("/rest/accounts/" + rootState.account.code + "/digests/" + digest.id, digest);
            await commit('setDigests', state.digests?.map(d => d.id === digest.id ? response.data : d));
            return getters.idToDigest.get(response.data.id);
        },

        async deleteDigest({state, commit, rootState}, digestId) {
            await mash.delete("/rest/accounts/" + rootState.account.code + "/digests/" + digestId);
            await commit('setDigests', state.digests?.filter(d => d.id !== digestId));
        },

        /**
         * Gets the list of logs related to alerts firing. This is useful if you want to know
         * when alerts were fired or not.
         */
        async refreshAlertLogs({state, commit, rootState}, forceRefresh) {
            forceRefresh ??= false;

            if (logPromise) return logPromise;
            if (state.alertLogs && !forceRefresh) return;

            try {
                logPromise = await mash.get(`/rest/accounts/${rootState.account.code}/activity?type=ALERT_DIGEST&limit=500`);
                const res = await logPromise;
                await commit('setAlertLogs', res.data.data);
            } catch(e) {
                console.error(e);
            } finally {
                logPromise = null;
            }
        },

        /**
         * Given an alert notification object, this returns the logs associated with it.
         */
        async getLogsForAlerts({state, dispatch}, notification) {
            if (!notification?.id) return null;
            await dispatch('refreshAlertLogs');
            return state.alertLogs.filter(l => l.metadata?.notificationId === notification.id);
        }
    }


};