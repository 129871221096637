import _ from 'underscore';
import {account} from "@/app/utils/Account";

/**
 * Returns true iff the account has either topic trees and views to display.
 */
export default function(options) {
    let views = account().topicViews;
    let ans = views && !_.isEmpty(views)
    if (!ans) {
        let trees = account().topicTrees;
        ans = trees && !_.isEmpty(trees)
    }
    if (ans) return options.fn(this);
    return options.inverse(this);
}