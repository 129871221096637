import { MentionQLexer, AndNode } from '@/mentionq/mentionq'
import {findAllNodes, parseFilterString} from "@/dashboards/filter/FilterParser";
import _ from 'underscore';

/**
 * Displays OTS information, either as text, or as a histogram.
 */
Beef.module("Widget.OnlineProfile").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Online Profiles",
        description:    "See likes, followers, and so on, for Facebook pages and Twitter accounts",
        group:          "benchmarking",
        width: 4,
        height: 4
    };

    this.View = Beef.Widget.CompositeWidget.View.extend({
        defaultChartType: 'text',
        typeViews: {
            text: 'text',
            lines: 'lines'
        },

        text: function() {
            return new Beef.Widget.TextOnlineProfile.View({model: this.model});
        },

        lines: function() {
            return new Beef.Widget.VisualOnlineProfile.View({model: this.model});
        },

        exportCSV_V4: function() {
            if (this.child.currentView.exportCSV_V4) return this.child.currentView.exportCSV_V4();
            return false;
        }
    });

    //---------------------------------------


    this.SettingsView = Beef.Widget.MetricSettings.View.extend({
        display: {
            profiles: true,
            graphType: {text: true, lines: true},
            show: {colour: true}
        }
    });


    //---------------------------------------

    /**
     * Constructs an url for fetching online-profile measurements from chicken.
     */
    this.getMeasurementUrl = function(code, filter, profiles) {
        if (!profiles) profiles = [];

        var publishedNodes = findAllNodes(parseFilterString(filter), function(node) {
            return node.operandType == MentionQLexer.PUBLISHED;
        });

        filter = _(publishedNodes).reduce(function(lhs, rhs) {
            return new AndNode(lhs, rhs);
        }).toString();

        var params = {
            filter: filter,
            limit: 10000
        };

        return {
            url: 'accounts/' + code + (profiles.length ? '/online-profiles/[' + profiles.join(',') +']/measurements' : '/online-profiles/measurements'),
            params: params
        };
    };


});