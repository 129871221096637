<template>
    <section class="beef-tooltip">
        <h4 v-if="heading">
            <i :class="symbol" v-if="symbol"></i>
            {{heading}}
        </h4>
        <section>
            <ops-only-tag v-if="ops"/>
            <staff-only-tag v-if="staff"/>
        </section>
        <p>{{ text }}</p>
    </section>
</template>

<script>
import OpsOnlyTag from "@/components/tags/OpsOnlyTag";
import StaffOnlyTag from "@/components/tags/StaffOnlyTag";

export default {
    components: {StaffOnlyTag, OpsOnlyTag},
    props: {
        text: String,
        heading: String,
        symbol: String,
        ops: Boolean,
        staff: Boolean
    }
}
</script>