import { getSegmentList } from "../../app/utils/Segments";
import _ from 'underscore';

/**
 * Displays crowd settings.
 */
Beef.module("CrowdSettings").addInitializer(function(startupOptions) {

    this.createView = function(accountCode) {
        var m = new Backbone.Model();
        m.url = Beef.Sync.toMashUrl('accounts/' + accountCode + "?include=brands&rootBrandsOnly=true");
        var v = new View({model: m});
        v.accountCode = accountCode;
        m.fetch();
        return  v;
    };

    var View = Backbone.Marionette.ItemView.extend({
        template: require("@/setup/crowd/CrowdSettings.handlebars"),

        attributes: { class: 'crowd-settings' },

        modelEvents: {
            "change": "render"
        },

        events: {
            "click .editable": "editBrand"
        },

        templateHelpers: function() {
            var brandsWithCrowdQuota = [];
            var otherBrands = [];
            var sharedQuota;
            var brands = this.model.get('brands');
            if (brands) {
                for (var i = 0; i < brands.length; i++) {
                    var b = brands[i];
                    if (b.crowdQuotaPercentage) {
                        brandsWithCrowdQuota.push(b);
                    } else {
                        otherBrands.push(b);
                        sharedQuota = b.crowdQuota;
                    }
                    b._tier = Beef.EditBrandTier.tiers[b.tier];
                    let hasCx = false;
                    let hasConduct = false;

                    if (b.activeSegmentListIds) {
                        for (const id of b.activeSegmentListIds) {
                            const segment = getSegmentList(id);
                            if (segment && segment.segmentType?.id === "CX_LIST") hasCx = true;
                            if (segment && segment.segmentType?.id === "CONDUCT_LIST") {
                                hasConduct = true;
                                break;
                            }
                        }
                    }

                    b._hasCx = hasCx;
                    b._hasConduct = hasConduct;
                }
            }
            return {
                brandsWithCrowdQuota: brandsWithCrowdQuota,
                otherBrands: otherBrands,
                sharedQuota: sharedQuota
            }
        },

        editBrand: function(ev) {
            var id = this.$(ev.target).closest("tr").attr("data-id");
            if (id) {
                var brand = _.find(this.model.attributes.brands, function(b) { return b.id == id });
                if (!brand) return;

                var model, attr = { id: brand.id, name: brand.name };

                if (this.model.attributes.payPerBrand) {
                    attr.tier = brand.tier;
                    attr.volumeLimit = brand.volumeLimit;
                    attr.crowdSamplePercentage = brand.crowdSamplePercentage;
                    attr.crowdTopicPercentage = brand.crowdTopicPercentage;
                    attr.neutralTopics = brand.neutralTopics;
                    attr.tempVolumeLimit = brand.tempVolumeLimit;
                    attr.tempCrowdSamplePercentage = brand.tempCrowdSamplePercentage;
                    attr.reason = '';
                    model = new Beef.EditBrandTier.Model(attr);
                } else {
                    attr.crowdQuotaPercentage = brand.crowdQuotaPercentage;
                    model = new Backbone.Model(attr);
                }

                model.url = Beef.Sync.toMashUrl("accounts/" + this.accountCode + "/brands/" + brand.id);

                var that = this;
                model.on("sync", function() {
                    that.model.fetch()
                });

                var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });

                var view;
                if (this.model.attributes.payPerBrand) {
                    view = new Beef.EditBrandTier.View({model: model, cache: this.cache});
                } else {
                    view = new Beef.EditBrandCrowdQuota.View({model: model, cache: this.cache});
                }
                view.on("close", function() { popup.hide(); });
                popup.setTarget($(ev.target));
                popup.show(view);
            }
        }
    });

});