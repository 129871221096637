import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {filterToDateRange} from "@/dashboards/filter/BasicFilter";
import moment from "moment";

/**
 * This is useful if you would like to isolate data that is serviceable.
 * Serviceable data is:
 *
 *  - Respondable
 *  - Not reshares
 *  - Contains RPCS
 *
 * @param filter {String}
 * @returns {String}
 */
export function appendServiceableRestrictions(filter) {
    return appendFiltersReadably(filter, "reshareOf is unknown and (socialNetwork IS TWITTER OR socialNetwork IS FACEBOOK OR socialNetwork IS INSTAGRAM) and (Tag IS 1 OR Tag IS 2 OR Tag IS 3 OR Tag IS 4)");
}

export const interactionResponseTimeFields = ["interactionCount", "interactionPercent", "interactionHasResponseCount", "averageInteractionHasResponse", "averageInteractionWhFollowupResponseTime",
    "averageInteractionWhFirstResponseTime", "averageInteractionWhResponseTime", "averageInteractionFollowupResponseTime", "averageInteractionFirstResponseTime", "averageInteractionResponseTime", "interactionHasNoResponseCount"];

export const responseTimeCalculationFootnoteWarning = "Interaction data is calculated every 24 hours";

export function showResponseTimeFootnote(field, filter) {
    if (!interactionResponseTimeFields.includes(field)) return false;

    let dateRange = filterToDateRange(filter);
    if (dateRange) {
        if (dateRange.start && dateRange.end) {
            let startDate = dateRange.start === "today" ? moment(new Date(), "DD/MM/YYYY") : moment(dateRange.start, "DD/MM/YYYY");
            let endDate = dateRange.end === "today" ? moment(new Date(), "DD/MM/YYYY") : moment(dateRange.end, "DD/MM/YYYY");
            let today = moment(new Date(), "DD/MM/YYYY");

            return today.isBetween(startDate, endDate, 'days', '[]');
        }
    }

    return false;
}