/**
 * Select one or more feed sources by typing the name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 * Todo: Pull this list from the
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("FeedPicker").addInitializer(function(startupOptions) {

    this.items = {
        TWITTER: 'X',
        TWITTER_SAMPLE: 'X 1% stream',
        TWITTER_DM: 'X DMs',
        TWITTER_ARCHIVE: 'X Explore searches',
        MOREOVER: 'Moreover',
        GOOGLENEWS: 'Google News',
        GOOGLEMAPSREVIEWS: 'Google Business & Maps',
        GOOGLE_QNA: 'Google Business Questions and Answers',
        TWINGLY: 'Twingly',
        FACEBOOKPOSTS: 'Facebook Posts',
        FACEBOOKPAGES: 'Facebook Pages',
        INSTAGRAMTAGS: 'Instagram Tags',
        INSTAGRAMUSERS: 'Instagram Profiles',
        INSTAGRAM_BUSINESS: 'Instagram Business graph API',
        GOOGLEPLUSPOSTS: 'Google+ Posts',
        SALTY: 'Old, Salty, mention uploader (defunct)',
        FONDUE: 'Mention Uploader',
        VKNEWS: 'VK News',
        BOARDREADER: 'Boardreader',
        CUSTOM: 'Custom feeds',
        TUMBLR: 'Tumblr',
        PRESSREADER: 'PressReader.com',
        EMEDIAMONITOR: 'EMediaMonitor.net',
        NOVUS_PRESS: 'Novus News Print Media',
        NOVUS_BROADCAST: 'Novus News Broadcast Media',
        WEBHOSE: 'Webhose',
        YOUTUBE: 'Youtube',
        YOUTUBE_PROFILES: 'Youtube Profiles',
        LINKEDIN: 'LinkedIn Company Posts',
        TELEGRAM: 'Telegram Channels',
        WHATSAPP: 'WhatsApp Messages'
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "feed-source-picker auto-complete-picker" },
        items: this.items,

        select: function(code) {
            this.$input.val("");
            this.trigger('change', code);
            this.selected(code);
            this.search();
        },

        selected: Beef.FeedNotify.attachFeedPickerNotifications()

    });


    this.createConverter = function(placeholder) {
        return createTagConverter({
            tooltip: function(code) { return "The feed, '" + (Beef.FeedPicker.items[code] || code) + "'"; },
            items: this.items,
            placeholder: placeholder
        });
    };

    this.converter = this.createConverter("Start typing the feed name here");

    /**
     * Attach a feed source picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
