var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"dashboard-read-only\"\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editDashboards") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":131}}})) != null ? stack1 : "")
    + "\n            >read only\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"isOps",{"name":"ifallowed","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":36,"column":26}}})) != null ? stack1 : "")
    + "            </span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "title=\"Click 'Dashboard Options' and choose 'Duplicate' if you would like to make changes\"";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <span> - dashboard has been deleted</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"accountInactive") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":35,"column":27}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-link unlock-dashboard\" title=\"Click to edit this dashboard\">unlock</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    / account inactive\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid title hide-in-fullscreen\">\n    <div class=\"buttons-left\">\n        <a class=\"btn btn-three-bar change-dashboard static-beef-tooltip--trigger static-beef-tooltip--right\">\n            <div class=\"static-beef-tooltip\">\n                View a different dashboard\n                <span class=\"static-beef-tooltip__keyboard\">\n                    D\n                </span>\n            </div>\n            <span>\n                <span class=\"three-bar\"></span>\n                <span class=\"three-bar\"></span>\n                <span class=\"three-bar\"></span>\n            </span>\n        </a>\n    </div>\n    <div class=\"warn-area\">\n    </div>\n    <div class=\"buttons\">\n        <a class=\"btn options disabled popup-menu\" title=\"Dashboard Options\">Dashboard Options <span class=\"caret\"></span></a>\n    </div>\n    <h1 class=\"editable edit-dashboard\" title=\"Click to edit the dashboard settings\">\n        <span name=\"name\"></span>\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":38,"column":19}}})) != null ? stack1 : "")
    + "    </h1>\n</div>\n\n<div class=\"sections row-fluid\"></div>\n";
},"useData":true});