/**
 * A module for viewing the mention retrieval filter in the retrieval popup setup.
 */
import {publishedTagConverter} from "@/components/pickers/dates/DateRangePickerUtilities";

Beef.module("HistoricalSearch.RetrievalSetupPopupFilter").addInitializer(function() {

    this.createView = function(published, calendarOptions) {
        calendarOptions = calendarOptions || {};
        var model = new Backbone.Model({ calendarOptions: calendarOptions });
        var view = new View({ model: model });
        view.model.set("published", published);
        return view;
    };

    var View = Beef.BoundItemView.extend({
        attributes: { class: "root-brand-filter row-fluid" },

        template: require("@/historical-search/popup/setup/retrieval/RetrievalSetupPopupFilter.handlebars"),

        bindings: function() {
            return {
                published: { converter: publishedTagConverter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            var calendarOptions = this.model.get("calendarOptions") || {};
            Beef.PublishedPicker.attach(this, ".published", "published", { notRequired: false, timeDisabled: true,
                calendarOptions: calendarOptions
            });
        },

        /**
         * Converts the published filter on this view's model into a clamped date range.
         * @return {{start: Date, end: Date }}
         */
        getDateRange: function() {
            return Beef.SearchJobFilter.toDateRange(this.model.get("published"));
        }
    });
});
