import MentionsDialog from "@/app/framework/dialogs/mentions/MentionsDialog";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {profiles} from "@/store/deprecated/Stores";
import {getMention, toGrouseLink} from "@/data/Grouse";
import {currentAccountCode} from "@/app/utils/Account";
import VuexStore from "@/store/vuex/VuexStore";
import {showBusyNotification} from "@/app/framework/notifications/Notifications";


/**
 *
 * @param {FilterString} filter
 * @param {String,optional} title
 * @param {Boolean,optional} [noLda = false]
 * @return {void}
 */
export function showMentions(filter, title, noLda) {
    show("MENTIONS", filter, title, noLda);
}

/**
 * Shows the "edit mention dialog".
 * @param {string} id
 * @return {Promise<void>}
 */
export async function showEditMentionDialog(id) {
    let popup = new Beef.Popup.View({
        closeOnHide: true,
        positions:["center"],
        alwaysMove: true
    });

    const busy = showBusyNotification("Opening mention...");

    try {
        await Promise.all(
            [VuexStore.dispatch("refreshTags"),
                VuexStore.dispatch("refreshBrands"),
                profiles.refresh(true)
            ]);
        const mention = await getMention(currentAccountCode(), id);
        const model = new Beef.MentionItem.Model(mention);
        model.url = toGrouseLink('/v4/accounts/' + currentAccountCode() + '/mentions/' + id, null, true);

        setTimeout(() => {
            try {
                let view = new Beef.EditMention.View({
                    model: model,
                    cache: {},
                    accountCode: currentAccountCode(),
                    noViewConversation: true
                });
                view.on("close", () => popup.hide());
                popup.setTarget(document.getElementById('content'));
                popup.show(view);
            } finally {
                busy.close();
            }

        }, 0);
    } catch (e) {
        console.error(e);
        busy.close();
    }

}


/**
 *
 * @param {FilterString} filter
 * @param {String,optional} title
 * @param {Boolean,optional} [noLda = false]
 * @return {void}
 */
export function showWordcloud(filter, title, noLda) {
    show("WORDCLOUD", filter, title, noLda);
}

function show(type, filter, title, noLda) {
    if (!filter) throw new Error("A filter must be supplied");

    const DEFAULT_TITLE = "Explore mentions";
    noLda ??= false;
    title ??= DEFAULT_TITLE;

    if (Array.isArray(title)) {
        if (!title.length) title = DEFAULT_TITLE;
        else title = DEFAULT_TITLE + " for " + title.join(" and ");
    }

    showDialog(MentionsDialog, {filter: filter, title: title, noLda: noLda, initialState: type});
}