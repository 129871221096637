/**
 * Select one or more credibility options. Fires a change event when the selection is changed. Also provides a model
 * binder converter to display the selected options in an element.
 */
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";

Beef.module("CredibilityPicker").addInitializer(function(startupOptions) {

    var items = {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        UNKNOWN: "Unknown"
    };

    this.View = Beef.Picker.View.extend({
        attributes: { class: "credibility-picker row-fluid" },
        template: require("@/dashboards/filter/pickers/credibility/CredibilityPicker.handlebars"),
        items: items
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            tooltip: function(credibility) { return "Credibility " + items[credibility]; },
            items: items,
            placeholder: placeholder,
            placeholderPersistent: true
        });
    };

    this.createConverterFactory = function(placeholder, options) {
        options = Object.assign({
            placeholderPersistant: true,
            readOnly: false
        }, options || {});
        return function(view) {
            var conv;
            return function(direction, value) {
                if (Number.isFinite(value)) {
                    var binding = this;
                    if (conv) return conv.call(binding, direction, value);

                    conv = createTagConverter({
                        tooltip: function(credibility) { return "Credibility " + items[credibility]; },
                        items: items,
                        placeholder: placeholder,
                        placeholderPersistent: options.placeholderPersistant,
                        readOnly: options.readOnly
                    });
                    conv.call(binding, direction, value);
                } else {
                    if (placeholder) $(this.boundEls[0]).html(toPlaceholderHTML(placeholder));
                    else $(this.boundEls[0]).text("");
                }
                return value;
            }
        }
    };

    this.converter = this.createConverter("Credibility");

    /**
     * Attach a credibility picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
