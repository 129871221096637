import {
    filterGetterFactory,
    formatDuration,
    formatNumber, formatPercentage, formatPercentageTooltip, formatResponseTimeDurationTooltip, getOther, isOther, simpleGetterFactory,
    simpleSetterFactory
} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
import * as b3js from "brandseyejs";
import {isUnknown} from "@/app/utils/StringUtils";

export default {
    "interactionCount": {
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        name: "Unique Interactions",
        chartName: "Unique interactions",
        yLabel: {long: "Unique interactions", short: "# interactions"},
        defaultSortOptions: {
            label: "Unique interactions",
            field: "interactionCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        extendData(d) {
            return {interactionCount: d}
        },
        getter: simpleGetterFactory("interactionCount"),
        setter: simpleSetterFactory("interactionCount"),
        extraFilterWhenY: () => "interactionId isnt unknown"
    },

    "interactionPercent": {
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticTip.handlebars"),
        name: "Percentage Unique Interactions",
        chartName: "Percentage of unique interactions",
        grouseAlias: ["interactionCount"],
        yLabel: {long: "Percentage of Unique interactions", short: "% of interactions"},
        isPercent: true,
        defaultSortOptions: {
            label: "Unique interactions",
            field: "interactionCount",
            order: "descending"
        },
        formatY: d => formatPercentage(d, 0),
        formatLabel: d => formatPercentage(d, 1),
        formatTooltipY: formatPercentageTooltip,
        getter: simpleGetterFactory("interactionPercent"),
        setter: simpleSetterFactory("interactionPercent"),
        extraFilterWhenY: () => "interactionId isnt unknown"
    },

    "interactionId": {
        name: "Interaction",
        scaleX: b3js.scaleDiscrete,
        noLimit: true,
        getter: simpleGetterFactory("interactionId"),
        setter: simpleSetterFactory("interactionId"),
        filterGetter: (d) => d["interactionId"],
        defaultSortOptions: {
            label: "Unique interactions",
            field: "interactionId",
            order: "descending"
        },
        formatX(d) {
            if (isUnknown(d)) return "No interaction";
            if (isOther(d)) return getOther();

            return d;
        },
        formatTooltipX(interactionId) {
            if (isUnknown(interactionId)) return "no interaction";
            return "interaction " + interactionId;
        }
    },

    "averageInteractionResponseTime": {
        name: "Average interaction response time",
        chartName: "Average interaction response time",
        tooltipArticle: "an",
        yLabel: {long: "Average interaction response time", short: "Avg interaction response time"},
        isDuration: true,
        description: "the duration of time between the customer's first mention and brands last mention. i.e.: the time from when a customer first speaks, until the time that the brand is finished speaking. Represented as an average of the selected data set. ",
        defaultSortOptions: {
            label: "Interaction response time",
            field: "averageInteractionResponseTime",
            order: "descending"
        },
        formatY: formatDuration,
        formatLabel: formatDuration,
        formatTooltipY: formatResponseTimeDurationTooltip,
        filterGetter: filterGetterFactory("averageInteractionResponseTime"),
        getter: simpleGetterFactory("averageInteractionResponseTime"),
        setter: simpleSetterFactory("averageInteractionResponseTime"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true"
    },

    "averageInteractionFirstResponseTime": {
        name: "Average first response time",
        chartName: "Average first response time",
        tooltipArticle: "an",
        yLabel: {long: "Average first response time", short: "Avg first response time"},
        isDuration: true,
        description: "the duration of time between the first customer mention (the start of an interaction) and the first brand response. Represented as an average of the selected data set.",
        defaultSortOptions: {
            label: "First response time",
            field: "averageInteractionFirstResponseTime",
            order: "descending"
        },
        formatY: formatDuration,
        formatLabel: formatDuration,
        formatTooltipY: formatResponseTimeDurationTooltip,
        filterGetter: filterGetterFactory("averageInteractionFirstResponseTime"),
        getter: simpleGetterFactory("averageInteractionFirstResponseTime"),
        setter: simpleSetterFactory("averageInteractionFirstResponseTime"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true"
    },

    "averageInteractionFollowupResponseTime": {
        name: "Average follow-up response time",
        chartName: "Average follow-up response time",
        tooltipArticle: "an",
        yLabel: {long: "Average follow-up response time", short: "Avg follow-up response time"},
        isDuration: true,
        description: "the duration of time between the first and last brand mention (post) in an interaction. Represented as an average of the selected data set.",
        defaultSortOptions: {
            label: "Follow-up response time",
            field: "averageInteractionFollowupResponseTime",
            order: "descending"
        },
        formatY: formatDuration,
        formatLabel: formatDuration,
        formatTooltipY: formatResponseTimeDurationTooltip,
        filterGetter: filterGetterFactory("averageInteractionFollowupResponseTime"),
        getter: simpleGetterFactory("averageInteractionFollowupResponseTime"),
        setter: simpleSetterFactory("averageInteractionFollowupResponseTime"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true AND InteractionFollowUpResponseTime > 0"
    },

    "averageInteractionWhResponseTime": {
        name: "Average interaction response time (working hours)",
        chartName: "Average interaction response time (working hours)",
        tooltipArticle: "an",
        yLabel: {long: "Average interaction response time (working hours)", short: "Avg interaction response time (working hours)"},
        isDuration: true,
        description: "the duration of time between the customer's first mention and brands last mention, taking in to account the customer service teams scheduled working hours.",
        defaultSortOptions: {
            label: "Interaction response time (working hours)",
            field: "averageInteractionWhResponseTime",
            order: "descending"
        },
        formatY: formatDuration,
        formatLabel: formatDuration,
        formatTooltipY: formatResponseTimeDurationTooltip,
        filterGetter: filterGetterFactory("averageInteractionWhResponseTime"),
        getter: simpleGetterFactory("averageInteractionWhResponseTime"),
        setter: simpleSetterFactory("averageInteractionWhResponseTime"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true"
    },

    "averageInteractionWhFirstResponseTime": {
        name: "Average first response time (working hours)",
        chartName: "Average first response time (working hours)",
        tooltipArticle: "an",
        yLabel: {long: "Average first response time (working hours)", short: "Avg first response time (working hours)"},
        isDuration: true,
        description: "the duration of time between the start of an interaction (first customer mention) and the first brand response, taking in to account the customer service teams scheduled working hours.",
        defaultSortOptions: {
            label: "First response time (working hours)",
            field: "averageInteractionWhFirstResponseTime",
            order: "descending"
        },
        formatY: formatDuration,
        formatLabel: formatDuration,
        formatTooltipY: formatResponseTimeDurationTooltip,
        filterGetter: filterGetterFactory("averageInteractionWhFirstResponseTime"),
        getter: simpleGetterFactory("averageInteractionWhFirstResponseTime"),
        setter: simpleSetterFactory("averageInteractionWhFirstResponseTime"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true"
    },

    "averageInteractionWhFollowupResponseTime": {
        name: "Average follow-up response time (working hours)",
        chartName: "Average follow-up response time (working hours)",
        tooltipArticle: "an",
        yLabel: {long: "Average follow-up response time (working hours)", short: "Avg follow-up response time (working hours)"},
        isDuration: true,
        description: "the duration of time between the first and last brand mention (post) in an interaction, taking in to account the customer service teams scheduled working hours.",
        defaultSortOptions: {
            label: "Follow-up response time (working hours)",
            field: "averageInteractionWhFollowupResponseTime",
            order: "descending"
        },
        formatY: formatDuration,
        formatLabel: formatDuration,
        formatTooltipY: formatResponseTimeDurationTooltip,
        filterGetter: filterGetterFactory("averageInteractionWhFollowupResponseTime"),
        getter: simpleGetterFactory("averageInteractionWhFollowupResponseTime"),
        setter: simpleSetterFactory("averageInteractionWhFollowupResponseTime"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true AND InteractionWhFollowUpResponseTime > 0"
    },

    "averageInteractionHasResponse": {
        tooltipArticle: "an",
        name: "Interaction response rate",
        chartName: "Interaction response rate",
        isPercent: true,
        yLabel: {long: "Percentage of interactions replied to", short: "% interactions replied to"},
        description: "the percentage of all interactions responded to by the brand, within a specified data set.",
        defaultSortOptions: {
            label: "Interaction response rate",
            field: "averageInteractionHasResponse",
            order: "descending"
        },
        formatY: d => formatPercentage(d, 0),
        formatLabel: d => formatPercentage(d, 1),
        formatTooltipY: formatPercentageTooltip,
        getter: simpleGetterFactory("averageInteractionHasResponse"),
        setter: simpleSetterFactory("averageInteractionHasResponse"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true"
    },

    "interactionHasResponseCount": {
        tooltipTemplate: require("@/dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"),
        name: "Interactions replied to",
        chartName: "interactions replied to",
        tooltipArticle: "an",
        yLabel: {long: "Interactions replied to", short: "# interactions replied to"},
        description: "the total number of unique interactions responded to, by the brand, within a specific data set.",
        defaultSortOptions: {
            label: "Interactions replied to volume",
            field: "interactionHasResponseCount",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("interactionHasResponseCount"),
        setter: simpleSetterFactory("interactionHasResponseCount"),
        extraFilterWhenY: () => "interactionId isnt unknown AND InteractionHasResponse IS true"
    },
}