import {getRootBrands} from "@/app/utils/Brands";

/**
 * Render a sentiment value in a styled span.
 */
export default function(value, options) {
    const brands = getRootBrands();
    var sentiSpan = new Handlebars.SafeString("<span tabindex='0' class='focusable sentiment sentiment-unknown'>Unknown</span>");

    if (value !== undefined) {
        var name = value;
        if (value < 0) name = "Negative";
        else if (value === 0) name = "Neutral";
        else if (value > 0) name = "Positive";
        var cls = "sentiment-" + (value < 0 ? "neg-" + -value : value);
        sentiSpan = new Handlebars.SafeString("<span tabindex='0' class='focusable sentiment " + cls + "'>" + name + "</span>");
    }

    if (options) {
        var thisBrand = brands?.find(function(brand){ return brand.id == options.brand.id; });
        if(thisBrand && thisBrand.rateSentiment == false) sentiSpan = new Handlebars.SafeString("<span tabindex='0' style='color: #ffa040'>Essentials, no sentiment.</span>");
    }

    return sentiSpan;
}