<template>
    <dialog-box
            @close="maybeClose($event)"
            class="metric-picker-dialog"
            :width="'80vw'"
            overlay
            ref="dialog">

        <template v-slot:title>
            <h5 class="metric-picker__title">
                <span v-if="!subTitle">
                    {{title}}
                </span>
                <span v-else>
                    <a @click="goBack()" tooltip="Go back to the previous options">{{title}}</a> » {{subTitle}}
                </span>
            </h5>
        </template>

        <section class="metric-picker" ref="picker">
            <section v-if="!expanded" class="metric-picker__top-level">
                <section class="metric-search">
                    <search-input class="search-input"
                                  @keydown.esc.stop="clearSearch()"
                                  v-model="searchTerm"
                                  ref="search"
                                  autofocus
                                  @keydown.down.stop="down($event)"
                                  @keydown.up.stop="up($event)"
                                  placeholder="Start typing to search for a metric"
                    />
                    <section class="metric-picker__common-list">
                        <div v-if="commonMetrics.length">
                            <span class="metric-picker__common-list-label">Commonly used:</span>
                            <ul>
                                <li v-for="recent in commonMetrics"
                                    :key="recent.id || recent.title"
                                    @click="addMetric(recent)"
                                    @mouseover="showMetricTooltip($event, recent)">
                                    {{recent.title}}
                                </li>
                            </ul>
                        </div>
                        <div v-if="visibleRecentMetrics.length">
                            <span class="metric-picker__common-list-label">Recent metrics:</span>
                            <ul>
                                <li v-for="recent in visibleRecentMetrics"
                                    :key="recent.id || recent.title"
                                    @click="addMetric(recent)"
                                    @mouseover="showMetricTooltip($event, recent)">
                                    {{recent.title}}
                                </li>
                            </ul>
                        </div>
                    </section>
                </section>

                <header v-if="!showAll && !searchTerm">
                    <h1>{{action}}</h1>
                </header>

                <section class="metric-classes dark-scrollbars dark-scrollbars--visible"
                         ref="classes"
                         v-if="!showAll && !searchTerm" >
                    <metric-class
                            class="metric-picker__class"
                            v-for="metricClass in classesAsCards"
                            :key="metricClass"
                            :metricClass="metricClass"
                            @expand="expand($event)"
                            @keydown.enter.stop="expand(metricClass)"
                    />
                    <section v-if="classesAsImages.length" class="metric-picker__overflow">
                        <mini-metric-class v-for="metricClass in classesAsImages"
                                           :key="metricClass"
                                           :metric-class="metricClass"
                                           @expand="expand(metricClass )"
                                           @keydown.enter.stop="expand(metricClass)">
                        </mini-metric-class>
                    </section>
                    <section class="metric-picker__actions">
                        <be-button v-for="klass in classesAsButtons"
                                   :key="klass.id"
                                   :tooltip="getKlass(klass).description"
                                   tabindex="0"
                                   @click="expand(klass)">

                            {{getKlass(klass).asButton}}
                        </be-button>
                        <be-button @click="goToMetricList()" tooltip="See a single list of all available metrics" tabindex="0">All metrics</be-button>
                        <old-tooltip label="Add a simple graph to your dashboard that you can customise to show the specific data that you want to see"
                                 keyword="enter"
                                 style="height: 100%">
                            <div class="metric-picker__add-blank" tabindex="0"
                                 @click="addBlank()"
                                 @keydown.enter.stop="addBlank()">
                                <span>Add blank graph</span>
                                <span>
                                    <i class="icon-plus"></i>
                                </span>
                            </div>
                        </old-tooltip>
                    </section>
                </section>

                <section v-else class="metric-picker__all-metrics dark-scrollbars dark-scrollbars--visible"
                        tabindex="-1">
                    <section v-if="!visibleClasses.length">
                        <h2>We cannot find any metrics relating to your search term</h2>
                    </section>
                    <section v-for="klass in visibleClasses" :key="klass">
                        <h2>{{getKlass(klass).title}}</h2>
                        <section>
                            <metric-table :metrics="getKlassMetrics(klass)" @add-metric="addMetric($event)"/>
                        </section>
                    </section>
                </section>
            </section>

            <expanded-class v-else :metricClass="expanded" @add-metric="addMetric($event)"/>
        </section>

        <template v-slot:buttons>
            <section class="dialog-buttons">
                <transition name="fade">
                    <be-button v-if="expanded || showAll || searchTerm"
                       @click="goBack()" :link="true" tooltip="Go back to the metrics">« Back</be-button>
                </transition>
                <be-button tooltip="Cancel adding a metric"
                           keyword="esc"
                           :primary="true"
                           @click="$emit('close')">Cancel</be-button>
            </section>
        </template>

    </dialog-box>
</template>

<script>
import DialogBox from '@/components/DialogBox';
import SearchInput from '@/components/inputs/SearchInput';
import OldTooltip from '@/components/tooltip/OldTooltip';
import MetricClass from './MetricClass';
import {availableMetrics, getMetricFromId, MetricTypeError} from './availableMetrics';
import ExpandedClass from "./ExpandedClass";
import MetricTable from "./MetricTable";
import {volumeType} from "./metrics/overviewMetrics";
import BeButton from "@/components/buttons/BeButton";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import MetricTooltip from "./MetricTooltip";
import {showTip} from "@/app/help/tips/tips";
import MiniMetricClass from "@/app/framework/dialogs/metric-picker/MiniMetricClass";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

export default {
        store: VuexStore,
        name: "MetricPickerDialog",
        components: {
            OldTooltip,
            MiniMetricClass, BeButton, MetricTable, DialogBox, MetricClass, ExpandedClass, SearchInput },

        mounted: function() {
            document.defaultView.addEventListener('keydown', this.keyDown);
            if (this.localStorageRecentKey) {
                try {
                    const key = this.localStorageRecentKey;
                    const json = localStorage.getItem(key);
                    this.recentMetrics = json ? JSON.parse(json) : [];
                } catch (e) {
                    console.warn("Unable to load recently used metrics from local storage", e);
                }
            }
            this.$nextTick(() => this.$refs.search.focus());
            showTip("ENTER_METRIC");
            showTip("SEARCH_METRIC");

            if (this.$refs.picker) {
                const styles = getComputedStyle(this.$refs.classes);
                this.rows = styles.getPropertyValue('--num-row');
                this.cols = styles.getPropertyValue('--num-col');
            }
        },

        destroyed: function() {
            document.defaultView.removeEventListener('keydown', this.keyDown);
        },

        props: {
            title: {
                type: String,
                default: "Choose a metric"
            },

            action: {
                type: String,
                default: "What would you like to know about?"
            }
        },

        data() {
            return {
                expanded: null,
                showAll: false,
                searchTerm: null,
                recentMetrics: [],
                commonMetrics: [
                    getMetricFromId("volume/columns"),
                    getMetricFromId("stats/default"),
                    getMetricFromId("sentiment/overall/net"),
                    getMetricFromId("topics/rows"),
                    getMetricFromId("topics/cooccurrence"),
                    getMetricFromId("themes/wordcloud")
                ].filter( m => !!m),
                wordcloudMetric: getMetricFromId("themes/wordcloud"),
                rows: null,
                cols: null
            }
        },

        computed: {
            ...mapState(['user']),

            classes() {
                return Object.keys(availableMetrics)
                    .filter(klass => !availableMetrics[klass].hide);
            },

            classesAsCards() {
                const maximum = this.cols * this.rows;
                let results = this.classes.filter(k => !availableMetrics[k].asButton);

                if (results.length > maximum - 1) results = results.slice(0, maximum - 2);
                return results;
            },

            classesAsButtons() { // For defined buttons, such as the Utility button
                return this.classes.filter(k => availableMetrics[k].asButton);
            },

            classesAsImages() {
                const maximum = this.rows * this.cols;
                const notButtons = this.classes.filter(k => !availableMetrics[k].asButton);
                if (notButtons.length > maximum - 1) {
                    return notButtons.slice(maximum - 2);
                }

                return [];
            },

            visibleRecentMetrics() {
                if (!this.recentMetrics || !this.recentMetrics.length) return [];
                const common = new Set(this.commonMetrics.map(m => m.id));
                return this.recentMetrics
                           .filter(id => !common.has(id))
                           .map(id => this.getMetric(id))
                           .filter(m => !!m && !m.hide);
            },

            subTitle() {
                if (this.searchTerm) return "Searching metrics";
                if (this.showAll) return `All metrics`;
                if (this.expanded) return `${this.metric.title}`;
                return null
            },

            metric() {
                if (!this.expanded) return null;
                return availableMetrics[this.expanded];
            },

            localStorageRecentKey() {
                if (!this.user || !this.user.id) return null;
                return `dataeq:users:${this.user.id}:metrics:recent`;
            },

            visibleClasses() {
                if (!this.searchTerm) return this.classes;
                return this.classes
                    .filter(k => this.getKlassMetrics(k).length > 0)
            }
        },

        methods: {
            expand(metricClass) {
                this.expanded = metricClass;
                this.$nextTick(() => this.$refs.dialog.focus());
            },
            getKlass(klass) {
                return availableMetrics[klass]
            },
            getKlassMetrics(klass) {
                const metrics = (this.getKlass(klass).metrics || []).filter(m => !m.hide && !m.duplicate);
                if (!metrics.length) return [];
                if (!this.searchTerm || !this.searchTerm.trim()) return metrics;

                const search = this.searchTerm.trim().toLowerCase();
                return metrics.filter(m => {
                    const title = (m.title || "").toLowerCase();
                    if (title.includes(search)) return true;
                    if (m.options && m.options.some(o => (o.title || "").toLowerCase().includes(search))) return true;
                    if (m.keywords && m.keywords.find(k => k.includes(search))) return true;
                    return false;
                });
            },

            getMetric(metricId) {
                return getMetricFromId(metricId)
            },

            goBack() {
                this.expanded = null;
                this.showAll = false;
                this.searchTerm = null;
                this.$refs.dialog.focus();
            },

            goToMetricList() {
                this.showAll = true;
                this.$refs.search.focus();
            },

            addBlank() {
                this.addMetric({widgetDesc: Object.assign({
                        notification: "Added a <strong>blank metric</strong> for you to use"
                    }, volumeType)})
            },

            logRecentMetric(metric) {
                if (!metric.id) return;

                // Don't log the common metrics.
                if (this.commonMetrics.find(m => m.id === metric.id)) return;

                const metrics = (this.recentMetrics || []).filter(d => d !== metric.id);
                metrics.unshift(metric.id);
                this.recentMetrics = metrics.slice(0, 5);

                if (this.localStorageRecentKey) {
                    try {
                        const key = this.localStorageRecentKey;
                        localStorage.setItem(key, JSON.stringify(this.recentMetrics || []));
                    } catch (e) {
                        console.warn("Unable to store recently used metrics in local storage", e);
                    }
                }
            },

            async addMetric(metricOrType) {
                let type = metricOrType.widgetDesc || metricOrType;

                try {
                    if (typeof type === 'function') {
                        const result = await type();
                        if (result) {
                            type = result;
                        } else return;
                    }

                    if (!type.type) {
                        console.error("No type info for metric", metricOrType);
                        showErrorDialog("Unable to add this metric. Please contact support.");
                        return;
                    }

                    this.$emit('add-metric', type);
                    this.$emit('close');
                    if (metricOrType.widgetDesc) {
                        // only log the addition of actual metrics
                        this.logRecentMetric(metricOrType);
                    }

                     showTip("DOWNLOAD_GRAPH_AS_IMAGE", "EDIT_METRIC_SHORTCUT");


                } catch (e) {
                    this.$emit('close');
                    if (e instanceof MetricTypeError) {
                        showErrorDialog(e.message);
                    } else {
                        console.error(e);
                        showErrorDialog("We've had a problem adding this metric to your dashboard. If this continues, please contact support.");
                    }
                }
            },

            clearSearch() {
                const wasSearching = !!this.searchTerm;
                this.expanded = null;
                this.showAll = false;
                this.searchTerm = null;
                if (this.$refs && this.$refs.search) this.$refs.search.blur();
                if (this.$refs && this.$refs.dialog) this.$refs.dialog.focus();

                // There was nothing in the search box. Let's consider
                // closing the dialog (escape was clicked).
                if (!wasSearching) this.maybeClose();
            },

            down(ev) {
                if (ev) {
                    ev.stopPropagation();
                    ev.preventDefault();
                }

                if (this.searchTerm || this.showAll) {
                    const items = document.querySelectorAll(".metric-list tr");
                    if (items.length) {
                        items[0].focus();
                    }
                } else if (!this.showAll) {
                    this.showAll = true;
                    this.$nextTick(() => this.down());
                }
            },
            up(ev) {
                if (ev) {
                    ev.stopPropagation();
                    ev.preventDefault();
                }

                if (this.searchTerm || this.showAll) {
                    const items = document.querySelectorAll(".metric-list tr");
                    if (items.length) {
                        items[items.length - 1].focus();
                    }
                } else if (!this.showAll) {
                    this.showAll = true;
                }
            },

            maybeClose(event) {
                const wasDialogCloseIcon = event?.target?.classList?.contains('symbol-close');

                if (wasDialogCloseIcon || !this.expanded && !this.showAll && !this.searchTerm) {
                    this.$emit('close');
                    return;
                }

                this.goBack()
            },

            keyDown(ev) {
                if (ev.keyCode === 13) { // Enter
                    // See if we have something easy to add
                    if (this.searchTerm) {
                        if (this.visibleClasses.length === 1) {
                            if (this.getKlassMetrics(this.visibleClasses[0]).length === 1) {
                                this.addMetric(this.getKlassMetrics(this.visibleClasses[0])[0]);
                                return;
                            }
                        } else {
                            // We have more than one search result. Prompt the user to choose.
                            this.down();
                        }
                    } else { // No search term. Default to adding blank chart for convenience
                        this.addBlank();
                    }
                } else if (ev.key.length === 1 && this.$refs.search) { // Printable
                    this.$refs.search.focus();
                }
            },

            showMetricTooltip(event, metric) {
                showTooltipComponent(event.currentTarget, MetricTooltip, {metric}, {positions: ['left', 'right']})
            }
        }
    }
</script>

<style>
    .metric-picker {
        --light-border-colour: #444;
    }

    .metric-picker-dialog .dialog-body {
        --dark-backgroud: #222;
        --light-background: #333;
        --light-highlight: #333;
        --body-background-colour: var(--dark-backgroud);
    }
</style>

<style scoped lang="scss">
    .metric-picker {
        max-height: 570px;
        height: 65vh;

        @media (max-height: 900px), (max-width: 1440px) {
            height: 70vh;
        }
    }

    h5.metric-picker__title a {
        cursor: pointer;
    }

    h5.metric-picker__title a:hover {
        color: var(--be-colour-text-dark__hover);
    }

    h5.metric-picker__title a:not(:hover) {
        color: inherit;
    }

    h5.metric-picker__title:hover a {
        text-decoration: underline;
    }

    .metric-picker__top-level {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .metric-picker__all-metrics {
        flex: 1 1 auto;
        overflow-y: auto;
    }

    .metric-picker__all-metrics h2 {
        margin-bottom: 0;
    }

    .metric-search {
        flex: 0 0 auto;
    }


    .metric-search .search-input {
        width: 100%;
    }

    header {

        h1 {
            margin: 0;
            color: #ddd;
        }
    }

    .metric-classes {
        --num-col: 5;
        --num-row: 2;
        --row-gap: 30px;
        --col-gap: 30px;
        --grid-width: calc((100% - ((var(--num-col) - 1) * var(--col-gap))) / var(--num-col));
        padding-top: 10px;
        display: grid;
        grid-template-columns: repeat(var(--num-col), var(--grid-width));
        grid-template-rows: repeat(var(--num-row), auto auto minmax(100px, 1fr));
        row-gap: var(--row-gap);
        column-gap: var(--col-gap);
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        box-sizing: border-box;
        //noinspection CssInvalidFunction

        @media (max-height: 800px), (max-width: 1280px) {
            --row-gap: 20px;
            --col-gap: 10px;
        }

        @media (max-height: 700px), (max-width: 1279px) {
            --num-col: 4;
            --num-row: 3
        }

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
        }
    }

    .metric-picker__overflow {
        grid-row-start: span 3;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .metric-picker__overflow > *:not(:first-of-type) {
        margin-top: 8px;
    }

    .metric-picker__actions {
        display: grid;
        grid-template-rows: auto auto 1fr;
        grid-column-start: var(--num-col);
        grid-row-start: span 3;

        @media (max-width: 900px){
            display: flex;
            align-items: center;
            flex-direction: column;
        }

    }

    .metric-picker__actions > * {
        width: 100%;
    }

    .metric-picker__actions > *:not(:first-of-type) {
        padding-top: 10px;
    }

    .metric-picker__add-blank {
        transition-property: border, color;
        transition-duration: var(--transition-duration);
        box-sizing: border-box;
        margin-top: 15px;
        display: flex;
        padding: 3px 0;
        flex-direction: column;
        height: calc(100% - 15px);
        border: 1px dashed #999;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        outline: none;
    }

    .metric-picker__add-blank i {
        transition: color var(--transition-duration);
        font-size: 3em;
        color: #666;

        @media (max-height: 700px), (max-width: 1279px) {
            display: none;
        }
    }

    .metric-picker__add-blank:hover,
    .metric-picker__add-blank:focus {
        border-color: var(--be-colour-text-dark__hover);
        color: var(--be-colour-text-dark__hover);
    }

    .metric-picker__add-blank:hover i,
    .metric-picker__add-blank:focus i {
        color: var(--be-colour-text-dark__hover);
    }



    .metric-picker__common-list {
        margin: 10px 0;
        color: var(--be-colour-muted-text-dark);
    }

    .metric-picker__common-list ul {
        list-style-type: none;
        display: inline-block;
        margin: 0;
    }

    .metric-picker__common-list li {
        cursor: pointer;
        transition: color var(--transition-duration);
        display: inline-block;
        position: relative;
    }

    .metric-picker__common-list li:not(:first-child)::before {
        content: '\e78b';
        color: var(--be-colour-muted-text-dark);

        display: inline-block;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
    }

    .metric-picker__common-list li:hover {
        color: var(--be-colour-text-dark__hover);
    }

    .metric-picker__common-list-label {
        width: 130px;
        display: inline-block;
    }

    .metric-picker__class {
        animation: fadeIn 300ms;
        animation-delay: 100ms;
        animation-fill-mode: both;
    }

    .metric-picker__class:nth-of-type(2) { animation-delay: 125ms }
    .metric-picker__class:nth-of-type(3) { animation-delay: 150ms }
    .metric-picker__class:nth-of-type(4) { animation-delay: 175ms }
    .metric-picker__class:nth-of-type(5) { animation-delay: 200ms }
    .metric-picker__class:nth-of-type(6) { animation-delay: 225ms }
    .metric-picker__class:nth-of-type(7) { animation-delay: 250ms }
    .metric-picker__class:nth-of-type(8) { animation-delay: 275ms }
    .metric-picker__class:nth-of-type(9) { animation-delay: 300ms }
    .metric-picker__class:nth-of-type(10) { animation-delay: 325ms }
    .metric-picker__class:nth-of-type(11) { animation-delay: 350ms }
    .metric-picker__class:nth-of-type(12) { animation-delay: 400ms }
    .metric-picker__class:nth-of-type(13) { animation-delay: 425ms }
    .metric-picker__class:nth-of-type(14) { animation-delay: 450ms }
    .mentions-widget .mention-item:nth-child(n+15) {animation-delay: 475ms;}

    .fade-enter-active, .fade-leave-active {
        transition: opacity var(--transition-duration);
    }

    .fade-leave-active {
        --transition-duration: 100ms;
    }

    @media screen and (prefers-reduced-motion: reduce) {
        .fade-leave-active {
            --transition-duration: 1ms;
        }
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .dialog-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }



</style>