var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"selected-icon\" title=\"Click to select/un-select mention\" >\n        <i class=\"selected-icon-bg icon-circle\"></i>\n        <i class=\"selected-icon-off icon-circle-empty\"></i>\n        <i class=\"selected-icon-on icon-ok-circled-1\"></i>\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"reshare-reply-ribbon"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":97}}})) != null ? stack1 : "")
    + " greyscale\"\n         title=\"This mention is a reshare of or reply to another mention\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " show-graph";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"date\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"notime") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":121}}})) != null ? stack1 : "")
    + "\n        </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/formatAccountDate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"published") : depth0),"MMM Do",{"name":"formatAccountDate","hash":{},"data":data,"loc":{"start":{"line":18,"column":35},"end":{"line":18,"column":75}}}));
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../helpers/humanizeDate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"published") : depth0),true,{"name":"humanizeDate","hash":{},"data":data,"loc":{"start":{"line":18,"column":83},"end":{"line":18,"column":114}}}));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"timeline") : stack1),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":49,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":59,"column":15}}})) != null ? stack1 : "")
    + "\n        <a "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"link") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":11},"end":{"line":61,"column":108}}})) != null ? stack1 : "")
    + ">\n            <div class=\"name\">"
    + alias2(__default(require("../helpers/render-emoji.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":62,"column":30},"end":{"line":62,"column":51}}}))
    + "</div>\n            <div class=\"screen-name\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "</div>\n        </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"followOnTwitter") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"facebook") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"googleplus") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"instagram") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"youtube") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkedin") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"follow greyscale\" target=\"be_twitter\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"followOnTwitter") : depth0), depth0))
    + "\" title=\"Follow on twitter\">\n                    <i class=\"symbol-twitter\"></i>\n                </a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"follow greyscale follow-facebook\" target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\"><i class=\"icon-facebook-3\"></i></a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"follow greyscale follow-googleplus\" target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\"><i class=\"icon-gplus\"></i></a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"follow greyscale follow-instagram\" target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\"><i class=\"icon-instagram\"></i></a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"follow greyscale follow-youtube\" target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\"><i class=\"icon-youtube-1\"></i></a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"follow greyscale follow-linkedin\" target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\"><i class=\"icon-linkedin-4\"></i></a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img id=\"m"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"avatar edit-author\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noView") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":58,"column":19}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img class=\"avatar\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n";
},"30":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img class=\"avatar edit-author\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Open the author's profile in a new tab\"";
},"34":function(container,depth0,helpers,partials,data) {
    return "title=\"Click to view/edit mention\"";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./MentionItemMedia.handlebars"),depth0,{"name":"./MentionItemMedia","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showSentiment") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":78,"column":15}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),">",0,{"name":"ifcond","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":141}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),"<",0,{"name":"ifcond","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":77,"column":142}}})) != null ? stack1 : "")
    + "\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "<div class=\"good\" title=\"Positive, verified sentiment\"><i class=\"icon-thumbs-up\"></i></div>";
},"42":function(container,depth0,helpers,partials,data) {
    return "<div class=\"bad\" title=\"Negative, verified sentiment\"><i class=\"icon-thumbs-down\"></i></div>";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"usePublishFull") : stack1),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":83,"column":12},"end":{"line":88,"column":19}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrint") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":16},"end":{"line":84,"column":199}}})) != null ? stack1 : "")
    + "\n                "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"publishFull") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":85,"column":44}}}))
    + "\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "<p style=\"font-style: italic; font-weight: bold\">This is an OCR transcript and may contain errors. Click the title above to view the original printed image.</p>";
},"48":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"combinedHtml") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":87,"column":16},"end":{"line":87,"column":45}}}))
    + "\n";
},"50":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":90,"column":12},"end":{"line":90,"column":34}}}))
    + "\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"engagement") : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":8},"end":{"line":97,"column":117}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"ots") : stack1),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":8},"end":{"line":98,"column":96}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"ave") : stack1),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":8},"end":{"line":99,"column":93}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"resharecount") : stack1),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":8},"end":{"line":100,"column":119}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"replycount") : stack1),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":8},"end":{"line":101,"column":114}}})) != null ? stack1 : "")
    + "\n";
},"53":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><span class=\"value\">"
    + container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":97,"column":58},"end":{"line":97,"column":85}}}))
    + "</span> engagement</span>";
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><span class=\"value\">"
    + container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"OTS") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":98,"column":51},"end":{"line":98,"column":71}}}))
    + "</span> OTS</span>";
},"57":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><span class=\"value\">"
    + container.escapeExpression(__default(require("../helpers/zarToStat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ave") : depth0),{"name":"zarToStat","hash":{},"data":data,"loc":{"start":{"line":99,"column":51},"end":{"line":99,"column":68}}}))
    + "</span> AVE</span>";
},"59":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><span class=\"value\">"
    + container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"reshareCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":100,"column":60},"end":{"line":100,"column":89}}}))
    + "</span> reshares</span>";
},"61":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span><span class=\"value\">"
    + container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"replyCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":101,"column":58},"end":{"line":101,"column":85}}}))
    + "</span> replies</span>";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <a class=\"date\"\n           "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_isDeletedOnNetwork") : depth0),{"name":"unless","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":11},"end":{"line":105,"column":68}}})) != null ? stack1 : "")
    + "\n           title=\"This mention was published at "
    + alias2(__default(require("../helpers/formatAccountDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),"HH:mm",{"name":"formatAccountDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":106,"column":48},"end":{"line":106,"column":102}}}))
    + " on "
    + alias2(__default(require("../helpers/formatAccountDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),"MMMM Do, YYYY",{"name":"formatAccountDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":106,"column":106},"end":{"line":106,"column":168}}}))
    + "\"\n           target=\"_blank\">\n            "
    + alias2(__default(require("../helpers/humanizeDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"published") : depth0),true,{"name":"humanizeDate","hash":{"plainText":true},"data":data,"loc":{"start":{"line":108,"column":12},"end":{"line":108,"column":58}}}))
    + "\n        </a>\n\n        <span class=\"interactions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allVerified") : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":12},"end":{"line":114,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.program(73, data, 0),"data":data,"loc":{"start":{"line":117,"column":12},"end":{"line":128,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"visibility") : depth0),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":12},"end":{"line":134,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hintPhoto") : depth0),{"name":"if","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":12},"end":{"line":161,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"withMentionAsTweet") : depth0), depth0),{"name":"withMentionAsTweet","hash":{},"fn":container.program(92, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":12},"end":{"line":169,"column":35}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"facebookAuthorized") : depth0),{"name":"if","hash":{},"fn":container.program(97, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":12},"end":{"line":174,"column":19}}})) != null ? stack1 : "")
    + "\n        </span>\n\n        <span class=\"actions\">\n            <span class=\"translation\">\n                <a class=\"mention-translation "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"translationDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(99, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":46},"end":{"line":180,"column":88}}})) != null ? stack1 : "")
    + "\"\n                    "
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"language") : depth0),{"name":"with","hash":{},"fn":container.program(101, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":20},"end":{"line":181,"column":91}}})) != null ? stack1 : "")
    + "\n                   title=\"Translate this mention into "
    + alias2(__default(require("../helpers/defaultLanguageName.js")).call(alias1,{"name":"defaultLanguageName","hash":{},"data":data,"loc":{"start":{"line":182,"column":54},"end":{"line":182,"column":77}}}))
    + "\">\n                    <i class=\"icon-switch\"></i>\n                </a>\n                <a class=\"mention-translation-options\" title=\"Translation options\"><i class=\"icon-down-dir-1\"></i></a>\n            </span>\n\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"editMentions",{"name":"ifallowed","hash":{},"fn":container.program(104, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":12},"end":{"line":202,"column":26}}})) != null ? stack1 : "")
    + "        </span>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\"";
},"66":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"verified\" title=\"Verified data with accurate sentiment\"><i class=\"icon-ok-circle\"></i></span>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"engagement show-graph "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"engagementSize") : depth0), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":67},"end":{"line":118,"column":132}}})) != null ? stack1 : "")
    + "\" href=\"#\"\n                   title=\"This mention is part of a conversation with an engagement of "
    + alias1(__default(require("../helpers/formatNumber.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":119,"column":87},"end":{"line":119,"column":114}}}))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"unless","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":114},"end":{"line":119,"column":223}}})) != null ? stack1 : "")
    + "\">\n                    <i class=\"icon-chat\"></i>\n                    <span class=\"engagement-value\">"
    + alias1(__default(require("../helpers/toSi.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"engagement") : depth0),{"name":"toSi","hash":{},"data":data,"loc":{"start":{"line":121,"column":51},"end":{"line":121,"column":70}}}))
    + "</span>\n                </a>\n";
},"69":function(container,depth0,helpers,partials,data) {
    return "no-conversation-pointer";
},"71":function(container,depth0,helpers,partials,data) {
    return ". Click to see the conversation this mention was a part of.";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"engagement show-graph "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":48},"end":{"line":124,"column":113}}})) != null ? stack1 : "")
    + "\" href=\"#\"\n                   title=\"This mention is part of a conversation"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noViewConversation") : stack1),{"name":"unless","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":64},"end":{"line":125,"column":173}}})) != null ? stack1 : "")
    + "\">\n                    <i class=\"icon-chat adjust-position\"></i>\n                </a>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"icon-email direct-message\"\n                   title=\""
    + container.escapeExpression(__default(require("../helpers/visibilityDescription.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"visibility") : depth0),{"name":"visibilityDescription","hash":{},"data":data,"loc":{"start":{"line":132,"column":26},"end":{"line":132,"column":62}}}))
    + "\">\n                </i>\n";
},"77":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"usePhoto") : depth0),{"name":"unless","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":138,"column":16},"end":{"line":160,"column":27}}})) != null ? stack1 : "");
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasVideo") : depth0),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.program(87, data, 0),"data":data,"loc":{"start":{"line":139,"column":20},"end":{"line":159,"column":27}}})) != null ? stack1 : "");
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"mediaLinks") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"isYoutubeVid") : stack1),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.program(82, data, 0),"data":data,"loc":{"start":{"line":140,"column":24},"end":{"line":147,"column":31}}})) != null ? stack1 : "");
},"80":function(container,depth0,helpers,partials,data) {
    return "                            <a class=\"hint-photo show-graph\"\n                               title=\"This is a YouTube mention, and has video. Click to view it.\">\n                                <i class=\"icon-videocam\"></i>\n                            </a>\n";
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"hint-photo show-graph\" title=\"This mention has video. Click to view it.\"><i class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasAudio") : depth0),{"name":"if","hash":{},"fn":container.program(83, data, 0),"inverse":container.program(85, data, 0),"data":data,"loc":{"start":{"line":146,"column":121},"end":{"line":146,"column":180}}})) != null ? stack1 : "")
    + "\"></i></a>\n";
},"83":function(container,depth0,helpers,partials,data) {
    return "icon-headphones";
},"85":function(container,depth0,helpers,partials,data) {
    return "icon-videocam";
},"87":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasPrint") : depth0),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.program(90, data, 0),"data":data,"loc":{"start":{"line":149,"column":24},"end":{"line":158,"column":31}}})) != null ? stack1 : "");
},"88":function(container,depth0,helpers,partials,data) {
    return "                            <a class=\"hint-photo show-graph\" title=\"This is print media.\">\n                                <i class=\"icon-book-open-1\"></i>\n                            </a>\n";
},"90":function(container,depth0,helpers,partials,data) {
    return "                            <a class=\"hint-photo show-graph\"\n                               title=\"This mention has images. Click to see more.\">\n                                <i class=\"icon-camera\"></i>\n                            </a>\n";
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"directMessage") : depth0),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.program(95, data, 0),"data":data,"loc":{"start":{"line":164,"column":16},"end":{"line":168,"column":23}}})) != null ? stack1 : "");
},"93":function(container,depth0,helpers,partials,data) {
    return "";
},"95":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a target=\"be_twitter\" href=\"https://twitter.com/intent/retweet?tweet_id="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tweetId") : depth0), depth0))
    + "\" title=\"Retweet\" class=\" mention-reshare\"><i class=\"icon-retweet-1\"></i></a>\n                    <a target=\"be_twitter\" href=\"https://twitter.com/intent/favorite?tweet_id="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tweetId") : depth0), depth0))
    + "\" title=\"Like this tweet\" class=\"mention-favourite\"><i class=\"icon-heart\"></i></a>\n";
},"97":function(container,depth0,helpers,partials,data) {
    return "                <a href=\"#\" title=\"Reshare\" class=\"mention-reshare\"><i class=\"icon-retweet-1\"></i></a>\n            <a href=\"#\" title=\"Like\" class=\"mention-favourite\"><i class=\"icon-thumbs-up\"></i></a>\n";
},"99":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"101":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":38},"end":{"line":181,"column":82}}})) != null ? stack1 : "");
},"102":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-last-language=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"";
},"104":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"showShuffle") : stack1),{"name":"if","hash":{},"fn":container.program(105, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":189,"column":16},"end":{"line":191,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"includeExportButton") : stack1),{"name":"if","hash":{},"fn":container.program(107, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":16},"end":{"line":194,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(109, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":16},"end":{"line":201,"column":23}}})) != null ? stack1 : "");
},"105":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"shuffle-mention\" href=\"#\" title=\"Replace mention\"><i class=\"icon-shuffle\"></i></a>\n";
},"107":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"export-image show-graph\" title=\"Save as image (PNG)\"><i class=\"icon-download\"></i></a>\n";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"relevancy") : depth0),"IRRELEVANT",{"name":"ifequal","hash":{},"fn":container.program(110, data, 0),"inverse":container.program(112, data, 0),"data":data,"loc":{"start":{"line":196,"column":20},"end":{"line":200,"column":32}}})) != null ? stack1 : "");
},"110":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"delete-link\" href=\"#\" title=\"Un-delete\"><i class=\"icon-ccw\"></i></a>\n";
},"112":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"delete-link\" href=\"#\" title=\"Delete\"><i class=\"icon-trash-1\"></i></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noSelect") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"reshareOfUri") : depth0),"||",(depth0 != null ? lookupProperty(depth0,"replyToUri") : depth0),{"name":"ifcond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"author\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"timeline") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/withMentionAuthor.js")).call(alias1,{"name":"withMentionAuthor","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":65,"column":26}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"content\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noView") : stack1),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":21},"end":{"line":68,"column":93}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"usePhoto") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":4},"end":{"line":71,"column":11}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"timeline") : stack1),{"name":"unless","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":4},"end":{"line":79,"column":15}}})) != null ? stack1 : "")
    + "\n    <span class=\"mention-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"combinedHtml") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":91,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n</div>\n\n<div class=\"footer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"timeline") : stack1),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(63, data, 0),"data":data,"loc":{"start":{"line":96,"column":4},"end":{"line":204,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});