/**
 * Bases class for country and language selectors.
 */
import {formatNumber, formatPercentage, numTo4Chars} from "@/app/utils/Format";
import _ from 'underscore';
import {isFunction} from "@/app/utils/Util";
import {once} from "@/app/utils/Functions";
import {errorHelper} from "@/dashboards/DashboardUtils";

Beef.module("Widget.CountSelector").addInitializer(function(startupOptions) {

    this.View = Beef.Widget.SelectorWidget.View.extend({

        maxItems: 999,
        blockScrolling: true,
        showPercentage: false,
        tagSelection: false,

        initialize: function(){
            this.listenTo(this.model.getSectionModel(), "change:filter", this.refreshIfFilterChanged, this);
            this.listenTo(this.model.getInteractiveFilterModel(), "change:brand", this.brandChanged, this);
            Beef.Widget.SelectorWidget.View.prototype.initialize.apply(this, arguments);
        },

        brandChanged: function(ifm, value) {
            this.refresh();
        },

        refreshIfFilterChanged: function(data) {
            var f = this.model.getSectionModel().get('filter');
            if (f != this._lastFilter) this.refresh();
        },

        refresh: function() {
            var that = this;
            var code = this.model.getAncestorProperty('accountCode');
            var groupBy = this.groupby || this.filterAttribute;
            if (isFunction(groupBy)) groupBy = groupBy.apply(this);

            var filter = this._lastFilter = this.model.getSectionModel().get('filter');
            var brands = this.model.getInteractiveFilterModel().get('brand');
            if (brands) {
                var sublists = brands.split('/');
                sublists.forEach(function(sublist) {
                    sublist = sublist.trim();
                    if (!sublist) return;

                    var a = sublist.split(' ');
                    if (a.length == 1) {
                        filter += " and brand isorchildof " + a[0];
                    } else {
                        filter += " and (";
                        for (var i = 0; i < a.length; i++) {
                            if (i > 0) filter += " or ";
                            filter += "brand isorchildof " + a[i];
                        }
                        filter += ")";
                    }
                })
            }

            var query = {
                filter: filter,
                groupBy: groupBy,
                limit: 1000
            };

            this.tweakQuery(query);

            this.model.getSectionModel().view.getJsonFromGrouse('/v4/accounts/' + code + '/mentions/count', query)
                .then(function (data) {
                    data = _.take(data, that.maxItems);
                    var items = _.map(data, that.mapData, that);
                    if (that.showPercentage) {
                        that.calculatePercent(items, query);
                    }

                    if (that.tagSelection) {
                        that.tagItems(items);
                    } else {
                        items.sort(that.compareFn.bind(that));
                        that.text(items);
                    }
                }, once(e => errorHelper(this.model, e)));
        },

        calculatePercent: function(items, query) {
            var tot = 0;
            for (var i = 0; i < items.length; i++) tot += items[i].count;
            if (tot > 0) for (i = 0; i < items.length; i++) items[i].percentage = 100 * items[i].count / tot;
        },

        tweakQuery: function(q) {
        },

        tagItems: function(items) {
            console.log("tag items");

            return []; },

        compareFn: function(a, b) {
            if (a.count == b.count) return a.name < b.name ? -1 : a.name > b.name ? +1 : 0;
            return a.count < b.count ? +1 : -1;
        },

        renderRow: function(select, item) {
            if (!item) return;
            if (this.tagSelection && !item.id) {
                select.attr('class', 'value value-noid');
                select.append('td');
                select.select('td')
                    .text(item.name)
                    .attr('title', this.getTooltipText);
            } else {
                select.attr('class', 'value');
                select.attr('data-value', item.id);
                var td = select.append('td').attr('title', this.getTooltipText);
                if (this.cropItemName) td = td.append('div');
                td.text(item.name);
                let text = item.count === null
                    ? ""
                    : (this.showPercentage ? formatPercentage(item.percentage, 1) : numTo4Chars(item.count))
                select.append('td')
                    .attr('class', 'num')
                    .text(text)
                    .attr('title', this.getCountTooltip);
            }
        },

        cropItemName: true,

        getCountTooltip: function(item) {
            var mentionText = item.count == 1 ? "mention" : "mentions";
            var verb = item.count == 1 ? "is" : "are";
            return "There " + verb + " " + formatNumber(item.count) + " " + mentionText + " from " + item.name;
        }
    });
});