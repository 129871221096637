<template>
    <section class="setup-panel-no-brand">
        <dotted-card class="setup-panel-no-brand__card" @click="$emit('click', $event)">
            <h1>
                Add a brand
            </h1>
            <p>
                To make sure you get the most accurate insights from your online monitoring, you'll need to spend some time setting up your account and search phrases. There are 4 easy steps to get started:
            </p>
            <ol>
                <li>
                    Add all your brands and sub-brands
                </li>
                <li>
                    Add your search phrases
                </li>
                <li>
                    Create account rules to manage your conversation
                </li>
                <li>
                    Add any custom data sources that apply
                </li>
            </ol>
        </dotted-card>
    </section>

</template>
<script>
import DottedCard from "@/components/cards/DottedCard";
export default {
    components: {DottedCard}
};
</script>

<style scoped lang="sass">

.setup-panel-no-brand__card
    margin-top: 100px
    width: clamp(500px, 50vw, 750px)

</style>