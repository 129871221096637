<template>
    <div class="comp-table widget-height-inner">
        <table v-if="doc" class="widget-font" :style="{'font-size': attrs.fontSize + 'px'}">
            <template v-if="attrs.swapRowsAndCols">
                <thead v-if="doc.rows">
                <tr>
                    <th></th>
                    <c-t-header v-for="c in rows" :key="c.id" :colspan="rowColspan"
                                :class="{'multicol': rowColspan > 1, 'no-value': !hasColWithValue}"
                                :item="c" :editable="editable" :attrs="attrs" :show-hidden="showHidden"
                                :dashboard-model="dashboardModel"
                                @show-hidden="onShowHiddenChange" @updated="onRowColSettingsChange"/>
                </tr>
                </thead>
                <tbody>
                <tr v-for="c in cols" :key="c.id">
                    <c-t-header :item="c" :editable="editable" :attrs="attrs"
                                :dashboard-model="dashboardModel"
                                :background="rowColColours[c.id]"
                                :show-hidden="showHidden" @show-hidden="onShowHiddenChange" @updated="onRowColSettingsChange"/>
                    <template v-for="r in rows">
                        <comp-table-cell v-if="c.showValue" :row="r" :col="c" :attrs="attrs" :key="r.id" @click="showMentions(r,c)"
                                         :background="rowColColours[c.id] || rowColColours[r.id]"
                                         :editable="editable" @editnote="editNote"/>
                        <td v-else-if="hasColWithValue" :key="r.id"/>
                        <comp-table-bar v-if="c.showBar" :row="r" :col="c" :doc="doc" :key="r.id + '-bar'" :background="rowColColours[c.id] || rowColColours[r.id]" @click="showMentions(r,c)"/>
                        <td v-else-if="hasColWithBars" :key="r.id + '-bar'"/>
                        <comp-table-delta v-if="c.hasPrev" :row="r" :col="c" :key="r.id + '-delta'" :background="rowColColours[c.id] || rowColColours[r.id]" @click="showMentions(r,c)"/>
                        <td v-else-if="hasColWithPrev" :key="r.id + '-delta'"/>
                    </template>
                </tr>
                </tbody>
            </template>

            <template v-else>
                <thead v-if="doc.cols">
                <tr>
                    <th></th>
                    <c-t-header v-for="c in cols" :key="c.id" :colspan="colspanForCol(c)"
                                :class="{multicol: colspanForCol(c) > 1, 'no-value': !c.showValue}"
                                :item="c" :editable="editable" :attrs="attrs" :show-hidden="showHidden"
                                :dashboard-model="dashboardModel"
                                @show-hidden="onShowHiddenChange" @updated="onRowColSettingsChange"/>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="r in rows" :key="r.id" :style="{background: rowColColours[r.id]}">
                    <c-t-header :item="r" :editable="editable" :attrs="attrs"
                                :dashboard-model="dashboardModel"
                                :show-hidden="showHidden" @show-hidden="onShowHiddenChange" @updated="onRowColSettingsChange"/>
                    <template v-for="c in cols">
                        <comp-table-cell v-if="c.showValue" :row="r" :col="c" :attrs="attrs" :key="c.id" :background="rowColColours[r.id] || rowColColours[c.id]" @click="showMentions(r,c)"
                                         :editable="editable"  @editnote="editNote"/>
                        <comp-table-bar v-if="c.showBar" :row="r" :col="c" :doc="doc" :key="c.id + '-bar'" :background="rowColColours[r.id] || rowColColours[c.id]" @click="showMentions(r,c)"/>
                        <comp-table-delta v-if="c.hasPrev" :row="r" :col="c" :key="c.id + '-delta'" :background="rowColColours[r.id] || rowColColours[c.id]" @click="showMentions(r,c)"/>
                    </template>
                </tr>
                </tbody>
            </template>
        </table>

        <edit-comp-table-note-dialog v-if="showEditNoteDialog" v-model="showEditNoteDialog"
                                     :row="noteRow" :col="noteCol" :attrs="attrs" @updated="saveChanges"/>
    </div>
</template>

<script>

import CompTableSettingsDialog from "./CompTableSettingsDialog"
import CompTableData, {includePreviousValues} from "./CompTableData"
import CompTableCell, {formatCellValue} from "./CompTableCell"
import CompTableBar from "./CompTableBar"
import CompTableDelta, {toCellDelta, formatCellDelta} from "./CompTableDelta"
import './CompTable.css'
import DateInterval from "../fantasticchart/DateInterval"
import CTHeader from "./CTHeader"
import EditCompTableNoteDialog from "./EditCompTableNoteDialog"
import {hasPublished, removePublished} from "@/dashboards/filter/BasicFilter";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {DEFAULT_COLOUR, GROUPBY_BY_ID} from "@/dashboards/widgets/comptable/CompTableUtils";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {
    responseTimeCalculationFootnoteWarning,
    showResponseTimeFootnote
} from "@/app/utils/Interactions";
import {getPalette} from "@/app/utils/Colours";
import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";

if (module.hot) window.beWidgetReloaded('CompTable')

export default {
    name: "CompTable",

    widgetType: {
        height: 4,
        width: 4,
        vueSettingsDialog: CompTableSettingsDialog,
        fontSize: 12,
        'hidden-title': false,
        rows: [
            { id: 1, groupBy: 'brand', limit: 99, slaResponseTime: 2, showOther: true, showUnknown: true, name: null, sortByFirstCol: false }
        ],
        cols: [
            { id: 1, type: 'mentionCount', groupBy: null, limit: 10, slaResponseTime: 2, showOther: true, showUnknown: true, name: null,
                showPercentage: true, showBar: true, showValue: true }
        ],
        swapRowsAndCols: false,
        prevDate: null,
        prevFilter: null,
        topicViewId: null,
        language: null,
        hiddenRowCols: null
    },

    components: { CompTableCell, CompTableBar, CompTableDelta, CTHeader, EditCompTableNoteDialog },

    props: {
        model: Object
    },

    data() {
        this.model.attributes.cols.forEach(c => {
            if (c.showValue === undefined) this.$set(c, 'showValue', true)
            if (c.showDelta === undefined) this.$set(c, 'showDelta', true)
        })
        return {
            doc: null,
            refreshTimeout: null,
            showHidden: false,
            showEditNoteDialog: false,
            noteRow: null,
            noteCol: null,
            rowColColours: {}
        }
    },

    computed: {
        attrs() { return this.model.attributes },

        filter() { return this.attrs._effectiveFilter },

        dashboardModel() { return this.model.getDashboardModel() },

        editable() { return !this.dashboardModel.get('_readOnly') },

        hasColWithValue() { return this.cols.find(c => c.showValue) },

        hasColWithBars() { return this.cols.find(c => c.showBar) },

        hasColWithPrev() { return this.cols.find(c => c.hasPrev) },

        rowColspan() {
            // this is used when rows and cols are swapped
            return Math.max((this.hasColWithValue?1:0) + (this.hasColWithBars?1:0) + (this.hasColWithPrev?1:0), 1)
        },

        rows() { return this.filterRowCols(this.doc.rows) },

        cols() { return this.filterRowCols(this.doc.cols) }
    },

    watch: {
        filter() { this.refresh() },
        'attrs.cols': { deep: true, handler() { this.refreshLater() }},
        'attrs.rows': { deep: true, handler() { this.refreshLater() }},
    },

    created() {
       this.updateRowColColours();
    },

    methods: {
        updateRowColColours() {
            if (this.attrs.rowColColours) {
                Object.keys(this.attrs.rowColColours).forEach(rowColId => {
                    this.$set(this.rowColColours, rowColId, this.getRowColBackgroundColour(rowColId));
                });
            }
        },

        getRowColBackgroundColour(id) {
            if (this.attrs.rowColColours && this.attrs.rowColColours[id]) {
                let rowColColourOptions = this.attrs.rowColColours[id];
                let colourPalette = rowColColourOptions['colour-palette'];
                return getPalette(colourPalette ? rowColColourOptions : DEFAULT_COLOUR, this.dashboardModel.attributes).at(0);
            }

            return null;
        },

        filterRowCols(a) {
            let hidden = this.attrs.hiddenRowCols
            return !hidden || this.showHidden ? a : a.filter(rc => hidden.indexOf(rc.id) < 0)
        },

        colspanForCol(c) {
            return Math.max((c.showValue?1:0) + (c.showBar?1:0) + (c.hasPrev?1:0), 1)
        },

        async refresh() {
            // somehow this.attrs._effectiveFilter (which is what this.filter uses) is undefined sometimes?
            if (this.filter === undefined) return
            // console.log("refresh", this.model.attributes)
            try {
                this.model.generalData.set({_loading: true, _completed: false})

                let currentDoc, prevDoc

                let d = new CompTableData({
                    filter: this.filter,
                    dashboardModel: this.dashboardModel,
                    sectionModel: this.model.getSectionModel(),
                    topicViewId: this.attrs.topicViewId,
                    language: this.attrs.language,
                    rows: this.attrs.rows,
                    cols: this.attrs.cols,
                    hiddenRowCols: this.showHidden ? null : this.attrs.hiddenRowCols
                })

                let merge = (current, prev) => {
                    if (prev && !prev.busy && current && !current.busy) includePreviousValues(current, prev)
                    //console.log("merge", current)
                    this.doc = current
                }

                // current data call
                await d.refresh(doc => merge(currentDoc = doc, prevDoc), null);

                // comparing to previous time period and/or filter
                let havePrevDate = this.attrs.prevDate && this.attrs.prevDate !== "current"
                if (havePrevDate || this.attrs.prevFilter) {
                    let pf = this.filter
                    if (this.attrs.prevFilter) {
                        if (hasPublished(this.attrs.prevFilter)) pf = removePublished(pf)
                        pf = appendFiltersReadably(pf, this.attrs.prevFilter)
                    }
                    if (havePrevDate) {
                        let prevDate = new DateInterval(this.filter).calcPreviousDate(this.attrs.prevDate)
                        pf = appendFiltersReadably(removePublished(pf), prevDate.filter)
                    }
                    let pd = new CompTableData({
                        filter: pf,
                        dashboardModel: this.dashboardModel,
                        sectionModel: this.model.getSectionModel(),
                        topicViewId: this.attrs.topicViewId,
                        language: this.attrs.language,
                        rows: this.attrs.rows,
                        cols: this.attrs.cols,
                        keepAllCols: true
                    })

                    // prev data call
                    await pd.refresh(doc => merge(currentDoc, prevDoc = doc), currentDoc.rows.map(r => r.id));
                }

                this.model.generalData.set({
                    _footnotes: []
                });

                for (const column of this.attrs.cols) {
                    if (showResponseTimeFootnote(column.type, this.filter)) {
                        this.model.generalData.set({
                            _footnotes: [responseTimeCalculationFootnoteWarning]
                        })
                        break;
                    }
                }

                this.model.generalData.set({_loading: false, _completed: true})
            } catch (e) {
                errorHelper(this.model, e.response || e);
            }
        },

        refreshLater() {
            clearTimeout(this.refreshTimeout)
            this.refreshTimeout = setTimeout(() => { this.refresh() }, 1)
        },

        onRowColSettingsChange() {
            this.updateRowColColours();

            this.calcMaxPercentages();
            // can't use a watch on attrs.hiddenRowCols for this as that gets triggered by the settings dialog
            this.saveChanges();
        },

        saveChanges() {
            this.model.save(null, {
                error: (model, xhr, options) => {
                    if (xhr.responseText) console.error(xhr.responseText, xhr)
                    else (console.error(xhr))
                    window.alert("Error saving changes .. please retry")
                }
            })

            notifyUser({
                message: encloseInDisplayQuotes(this.attrs.caption) + " settings updated.",
                icon: '<i class="icon-signal-1"></i>'
            })
        },

        onShowHiddenChange(v) {
            this.showHidden = v
            this.calcMaxPercentages()
        },

        calcMaxPercentages() {
            this.doc.calcMaxPercentages(this.showHidden ? null : this.attrs.hiddenRowCols)
        },

        showMentions(row, col) {
            let filter = "";
            if (row.filter || col.filter) {
                filter = appendFiltersReadably(row.filter, col.filter);
            }
            filter = appendFiltersReadably(filter, this.attrs._effectiveFilter);

            let title = row.name
            if (col.filter) {
                title = title ? `${title}: ${col.name}` : col.name;
            }
            showMentions(filter, title)
        },

        editNote(row, col) {
            this.noteRow = row
            this.noteCol = col
            this.showEditNoteDialog = true
        },

        toCSV() {
            if (!this.cols || !this.rows) return null
            let firstRow = this.attrs.rows[0]
            let gb = GROUPBY_BY_ID[firstRow.groupBy]
            let thead = [ gb ? quoteForCSV(gb.name) : '']
            let tr = []
            if (this.attrs.swapRowsAndCols) {
                let rowColSpan = ((this.hasColWithValue || this.hasColWithBars)?1:0) + (this.hasColWithPrev?1:0)
                this.rows.forEach(r => {
                    thead.push(quoteForCSV(r.name))
                    if (rowColSpan > 1) thead.push('')
                })
                tr.push(thead.join(","))
                this.cols.forEach(c => {
                    let td = [c.name ? quoteForCSV(c.name) : '']
                    this.rows.forEach(r => {
                        let v = r.colValues[c.id] || { }
                        if (c.showValue || c.showBar) {
                            let s = formatCellValue(r, c, v, this.attrs, true)
                            if (c.quoteInCSV) s = quoteForCSV(s)
                            else s = s.replace(/ /g, '')
                            td.push(s)
                        } else if (this.hasColWithValue || this.hasColWithBars) {
                            td.push('')
                        }
                        if (c.hasPrev) {
                            let delta = toCellDelta(r, c)
                            td.push(delta ? ((delta < 0 ? "-" : "+") + formatCellDelta(c, delta)) : '')
                        } else if (this.hasColWithPrev) {
                            td.push('')
                        }
                    })
                    tr.push(td.join(","))
                })
            } else {
                this.cols.forEach(c => {
                    thead.push(quoteForCSV(c.name))
                    if ((c.showValue || c.showBar) && c.hasPrev) thead.push(quoteForCSV(c.name + "-change"))
                })
                tr.push(thead.join(","))
                this.rows.forEach(r => {
                    let td = [quoteForCSV(r.name)]
                    this.cols.forEach(c => {
                        let v = r.colValues[c.id] || { }
                        if (c.showValue || c.showBar) {
                            let s = formatCellValue(r, c, v, this.attrs, true)
                            if (c.quoteInCSV) s = quoteForCSV(s)
                            else s = s.replace(/ /g, '')
                            td.push(s)
                        }
                        if (c.hasPrev) {
                            let delta = toCellDelta(r, c)
                            td.push(delta ? ((delta < 0 ? "-" : "+") + formatCellDelta(c, delta)) : '')
                        }
                    })
                    tr.push(td.join(","))
                })
            }
            return tr.join("\n")
        }
    }
}

function quoteForCSV(v) {
    if (!v) return v
    return '"' + v.replace(/"/g, '""') + '"'  // dup double quotes
}

</script>

<style>
</style>