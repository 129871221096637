import { render, staticRenderFns } from "./FantasticChartSettingsDialog.vue?vue&type=template&id=189bc9a7&scoped=true"
import script from "./FantasticChartSettingsDialog.vue?vue&type=script&lang=js"
export * from "./FantasticChartSettingsDialog.vue?vue&type=script&lang=js"
import style0 from "./FantasticChartSettingsDialog.vue?vue&type=style&index=0&id=189bc9a7&prod&scoped=true&lang=css"
import style1 from "./FantasticChartSettingsDialog.vue?vue&type=style&index=1&id=189bc9a7&prod&scoped=true&lang=sass"
import style2 from "./FantasticChartSettingsDialog.vue?vue&type=style&index=2&id=189bc9a7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189bc9a7",
  null
  
)

export default component.exports