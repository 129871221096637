import {isFunction} from "@/app/utils/Util";
import {escapeHtml, removeQuotes, splitAtSpaces} from "@/app/utils/StringUtils";

/**
 * Return a model binder converter function that renders a space separated list of values using labels from
 * the supplied items map or function. If items is a function then it is passed each code and expected to
 * return the corresponding name. If nameFormatter is supplied then it is invoked with each name and the
 * neg flag and must return escaped html.
 *
 * @param options.splitter a function that should be used to split apart the string of data items in to individual data points.
 *          This defaults to splitAtSpaces.
 * @param options.acceptNew defaults to false. If true, items outside of that allowed by "items" is accepted.
 * @param options.newCallback a function, which is passed new items when they are generated.
 * @param options.validate an optional function. Returns true if this is a valid tag, false otherwise. Only new items are validated.
 * @param options.nameFormatter a function that will take a value and format it
 * @param options.getName an optional function that is given an item, and should return a string representation of that item. Useful
 *          only if the item isn't already a string.
 * @param options.tooltip an optional function passed the item to be displayed, and returning tooltip text for the tag.
 * @param options.handlebarToolip an optional function passed the item to be displayed, and should return everything that the tooltip
 *          module's show method expects, except for the target (which will be auto filled in by createTagConverter).
 * @param options.valueIsArray indicates that the value is an array and not a string (no splitting needed)
 * @param options.readOnly the tags should not allow editing. In this case, for the tag converter, it ensures that closing × icon isn't displayed.
 *
 * @deprecated This is to support the old backbone pickers. We need to remove it.
 *
 * WAS PREVIOUSLY IN Beef.Picker MODULE DEFINED IN picker.js
 */
export function createTagConverter(options) {
    options = options || {};
    return function(direction, value) {
        if (!options.valueIsArray) value = value? value.toString() : null;
        var $e = $(this.boundEls[0]);
        var $input = $e.next();     // assumes that if there is an input tag it immediately follows us
        if (!$input.is("input")) $input = null;
        var tags = createTags(value, Object.assign({ input: $input }, options));
        var haveValue = value && value.length > 0;

        $e.html(tags.join(""));

        if (options.placeholder && $input) {
            // use real placeholder if an input is available
            $input.attr('placeholder', haveValue ? null : options.placeholder);
            $input.toggleClass('has-placeholder', !haveValue);
        }
        return value;
    };
}

/**
 * @deprecated This is to support the told backbone picker code. We need to remove it.
 */
function createTags(value, options) {
    var items = options.items;
    var nameFormatter = options.nameFormatter;
    var getName = options.getName || (d => d);
    var getClasses = options.getClasses || function() { return ''; };
    var tags = [];
    var haveValue = value && value.length > 0;

    if (haveValue) {
        var a = options.valueIsArray ? value : options.splitter ? options.splitter(value) : splitAtSpaces(value);
        var itemsFunc = isFunction(items);
        for (var i = 0; i < a.length; i++) {
            var code = a[i];
            var neg = !options.noNeg && code.charAt(0) == '-';
            var lookupCode = neg ? code.substring(1) : code;
            var item = itemsFunc ? items(lookupCode) : items[lookupCode];
            var name = getName(item);
            var newlyAdded = false;
            if (options.acceptNew && !name) {
                name =  removeQuotes(lookupCode, "'");
                if (!options.preserveCase) {
                    name = name.toLowerCase();
                }
                if (options.newCallback) options.newCallback(name);
                newlyAdded = true;
            }
            if (options.excludeSubBrands && !name && code == "x") {
                name = "[ no sub-brands ]";
            }
            if (name) {
                var valid = true;
                if (options.validate && newlyAdded) valid = options.validate(name);
                var escapedCode, cls, tooltip;
                if (code == "x" && options.excludeSubBrands) {
                    escapedCode = code;
                    cls = "tag all";
                } else if (code == "&") {
                    name = "[ all required ]";
                    escapedCode = code;
                    cls = "tag all";
                } else {
                    name = nameFormatter ? nameFormatter(name, neg, item) : $('<pre>').text((neg ? "-" : "") + name).html();
                    escapedCode = code.replace('"', '&quot;');
                    cls = isFunction(options.cls) ? options.cls(item) : (options.cls || 'tag');
                    tooltip = options.tooltip ? options.tooltip(lookupCode) : null;
                }

                tags.push("<span class='" + cls + (!valid ? " invalid" : "") + (neg ? " tag-neg" : "") +
                    "' data-value=\"" + escapedCode + "\"" +
                    (tooltip ? ' title="' + tooltip + '"' : "") + ">" +
                    "<span class='tag-text'>" + name + "</span>" +
                    (!options.readOnly ? "<button class='close' tabindex='-1' title='Remove this item'>&times;</button>" : '&nbsp;') +
                    "</span>");
            }
        }
    }

    if (options.plus) tags.push("<i class='plus icon-plus-sign'></i>");

    if (options.placeholder && !options.input && (!haveValue || isPlaceholderPersistent(options, value))) {
        // placeholder for pickers without an input box
        tags.unshift(toPlaceholderHTML(options.placeholder));
    }

    return tags;
}

/**
 * @deprecated For supporting the old picker code. Must remove.
 */
function isPlaceholderPersistent(options, value) {
    var p = options.placeholderPersistent;
    return isFunction(p) ? p(value) : p;
}

/**
 * @deprecated To support old picker code.
 */
export function toPlaceholderHTML(placeholder) {
    return "<span class='placeholder muted'>" + escapeHtml(placeholder) + "</span>";
}