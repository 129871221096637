var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-phrase=\""
    + alias1(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\">\n    <td colspan=\"1\" class=\"phrase\">\n        <div class=\"inner\">\n            <div class=\"q\">"
    + alias1(__default(require("../../helpers/renderPhrase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":48}}}))
    + "</div>\n        </div>\n    </td>\n</tr>\n";
},"useData":true});