/**
 * Information about the stats displayed in the Stats metric and elsewhere.
 */

import _ from 'underscore';

Beef.module("StatFields").addInitializer(function(startupOptions) {

    /**
     * Return the info for a field. Always returns something even for unrecognized fields.
     */
    this.get = function(name) {
        var f = map[name];
        if (f) return f;
        console.debug("No field info found for [" + name + "]");
        return { label: "(" + name + ")", description: "(descr " + name + ")" }
    };

    /**
     * Return all fields visible to the current user. The returned objects are copies. If attrs is supplied then
     * the current value for each field is extracted/calculated as 'value' in the returned objects. If fieldOptions is
     * supplied then these are merged into the object created for each field.
     */
    this.list = function(attrs, fieldOptions) {
        var a = [];
        for (var i = 0; i < fields.length; i++) {
            var f = fields[i];
            if (f.permission && !Beef.Permissions[f.permission]()) continue;
            f = Object.assign({}, fieldOptions, f);
            if (attrs) f.value = f.calc ? f.calc(attrs) : attrs[f.id];
            a.push(f);
        }
        return a;
    };

    var fields = [
        { id: 'count', label: "Mentions", description: "The total number of mentions, including replies and reshares, in the data set" },
        { id: 'replyCount', label: "Replies", description: "The total number of replies to all mentions in the data set" },
        { id: 'reshareCount', label: "Reshares", description: "The total number of reshares (e.g. retweets) of all mentions in the data set" },
        { id: 'conversationIdCount', label: "Conversations", description: "The total number of distinct conversations in the data set", v4: true },
        { id: 'authorIdCount', label: "Unique authors", description: "The total number of unique authors of mentions in the data set" },
        { id: 'sites', label: "Unique sites", description: "The total number of unique sites (sources) that the mentions in the data set are from" },
        { id: 'ots', label: "OTS", description: "Opportunities-to-see, the total number of individuals who would have had the opportunity to see the mentions" },
        { id: 'ave', label: "AVE", format: 'zar', description: "Advert value equivalent, the total earned media value of the potential online exposure of the mentions" },
        { id: 'engagement', label: "Engagement", description: "The total number of reshares (e.g. retweets) and replies generated by all mentions in the data set" },
        { id: 'totalSentiment', label: "Net sentiment", description: "Net sentiment for the mentions in the data set" },
        { id: 'totalSentimentP', label: "Net %", format: '%', description: "Overall sentiment for the mentions in the data set, as a percentage of total mentions" },
        { id: 'positiveCount', label: "Positive mentions", description: "Total number of positive mentions" },
        { id: 'neutralCount', label: "Neutral mentions", description: "Total number of neutral mentions" },
        { id: 'negativeCount', label: "Negative mentions", downIsGood: true, description: "Total number of negative mentions"},
        { id: 'positiveCountP', label: "Positive", format: '%', description: "Percentage of total mentions that are positive"},
        { id: 'neutralCountP', label: "Neutral", format: '%', description: "Percentage of total mentions that are neutral"},
        { id: 'negativeCountP', label: "Negative", format: '%', downIsGood: true, description: "Percentage of total mentions that are negative"},
        { id: 'positiveOts', label: "Positive OTS", description: "Total OTS of all positive mentions" },
        { id: 'negativeOts', label: "Negative OTS", downIsGood: true, description: "Total OTS of all negative mentions" },
        { id: 'positiveOtsP', label: "Positive OTS %", format: '%', description: "Percentage of total OTS from positive mentions",
            calc: function(a){ return percentage(a.positiveOts, a.positiveOts, a.neutralOts, a.negativeOts) }},
        { id: 'negativeOtsP', label: "Negative OTS %", format: '%', downIsGood: true, description: "Percentage of total OTS from negative mentions",
            calc: function(a){ return percentage(a.negativeOts, a.positiveOts, a.neutralOts, a.negativeOts) }},
        { id: 'sampleSize', label: "Sample size", description: "Size of the verified sample used to estimate sentiment" },
        { id: 'days', label: "Days", description: "Number of days between the start and end date of the filter" },
        { id: 'avgResponseTime', label: "Avg resp. time", downIsGood: true, format: 'duration', description: "Average response time" },
        { id: 'stddevResponseTime', label: "\u03C3 resp. time", downIsGood: true, format: 'duration', description: "Response time std deviation (\u03C3)" },
        { id: 'minResponseTime', label: "Min resp. time", downIsGood: true, format: 'duration', description: "Min response time" },
        { id: 'maxResponseTime', label: "Max resp. time", downIsGood: true, format: 'duration', description: "Max response time" },

        // interaction response times
        { id: 'averageInteractionResponseTime', label: "Avg interaction resp. time", downIsGood: true, format: 'duration', description: "Average interaction response time" },
        { id: 'stddevInteractionResponseTime', label: "\u03C3 interaction resp. time", downIsGood: true, format: 'duration', description: "Interaction response time std deviation (\u03C3)" },
        { id: 'minInteractionResponseTime', label: "Min interaction resp. time", downIsGood: true, format: 'duration', description: "Min interaction response time" },
        { id: 'maxInteractionResponseTime', label: "Max interaction resp. time", downIsGood: true, format: 'duration', description: "Max interaction response time" },
        { id: 'averageInteractionWhResponseTime', label: "Avg interaction wh. resp. time", downIsGood: true, format: 'duration',
            description: "Average interaction response time (working hours)" },
        { id: 'stddevInteractionWhResponseTime', label: "\u03C3 interaction wh. resp. time", downIsGood: true, format: 'duration',
            description: "Interaction response time std deviation (\u03C3) (working hours)" },
        { id: 'minInteractionWhResponseTime', label: "Min interaction wh. resp. time", downIsGood: true, format: 'duration',
            description: "Min interaction response time (working hours)" },
        { id: 'maxInteractionWhResponseTime', label: "Max interaction wh. resp. time", downIsGood: true, format: 'duration',
            description: "Max interaction response time (working hours)" }
    ];

    var map = _.reduce(fields, function(o, item) { o[item.id] = item; return o }, {});

    var percentage = function(a) {
        if (!Number.isFinite(a)) return 0;
        var tot = 0;
        for (var i = 1; i < arguments.length; i++) {
            var n = arguments[i];
            if (Number.isFinite(n)) tot += n;
        }
        return tot ? a * 100 / tot : 0;
    };

    this.convertV4FieldNamesToV3 = function(data) {
        if (data.mentionCount !== undefined) data.count = data.mentionCount;
        if (data.authorNameCount !== undefined) data.authorNames = data.authorNameCount;
        if (data.siteCount !== undefined) data.sites = data.siteCount;
        if (data.totalOTS !== undefined) data.ots = data.totalOTS;
        if (data.totalAVE !== undefined) data.ave = data.totalAVE;
        if (data.totalEngagement !== undefined) data.engagement = data.totalEngagement;
        if (data.totalReshareCount !== undefined) data.reshareCount = data.totalReshareCount;
        if (data.totalReplyCount !== undefined) data.replyCount = data.totalReplyCount;
        if (data.averageResponseTime !== undefined) data.avgResponseTime = data.averageResponseTime;
        if (data.totalPositive !== undefined) data.positiveCount = data.totalPositive;
        if (data.totalNeutral !== undefined) data.neutralCount = data.totalNeutral;
        if (data.totalNegative !== undefined) data.negativeCount = data.totalNegative;
        return data;
    };

    this.hackAVEFromOTS = function(data) {
        if (data.totalOTS !== undefined) data.totalAVE = data.totalOTS * 0.225 + 7.5;
    }
});