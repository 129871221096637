/**
 * Provides private settings for our chart widgets. This is a customizable view, provided by extending
 * the view and settings the display setting. See categoricalDisplay for an example.
 *
 * <p>
 * This has been passed the originalModel in the view options.
 */
import {filterSuggestsHourly} from "@/dashboards/filter/FilterParser";
import {getPalette} from "@/app/utils/Colours";
import {createBrandTagConverterFactory} from "@/components/pickers/brands/BrandPickerUtilities";

Beef.module("Widget.MetricSettings").addInitializer(function(startupOptions) {

    /**
     * Some standard display settings for categorical data.
     */
    this.categoricalDisplay = {
        graphType:  { rows: true, columns: true, pies: true },
        show: {
            other: true,
            unknown: true,
            labelTypes: true,
            //credibilityType: true,
            labels: true,
            legend: true,
            maxItems: true,
            colour: true,
            topicview: undefined,    // Whether to show the view + tree selector for topics.
            topictree: undefined,    // Whether to show only tree selctor for topics.
            language: undefined,
            parents: undefined,      // Whether to show parent items or not
            children: undefined      // Whether to show children items or not
            //currency: false

        }
    };

    this.Coarseness = {
        total:   'total',
        hourly:  'hourly',
        daily:   'daily',
        weekly:  'weekly',
        monthly: 'monthly',
        yearly:  'yearly'
    };

    this.LabelType = {
        count:       'count',
        percentage:  'percentage',
        ots:         'ots',
        ave:         'ave',
        engagement:  'engagement',
        credibility: 'credibility',
        uniqueAuthors: 'authorNames',
        uniqueSites: 'sites',
        brandIndex:  'brandIndex'
    };

    this.ChartType = {
        text:       'text',
        histogram:  'histogram',
        columns:    'columns',
        rows:       'rows',
        pies:       'pies',
        lines:      'lines'
    };

    this.SentimentGroupType = {
        automatic: 'automaticSentiment',
        verbose: 'verboseSentiment',
        succinct: 'succinctSentiment'
    };

    this.View = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/settings/MetricSettings.handlebars"),
        attributes: {
            class: 'metric-settings'
        },

        display: this.categoricalDisplay,

        editAttributes: function() {
            return ['brands', 'chart-type', 'hide-others', 'hide-unknown', 'hide-legend',
                    'hide-parents', 'hide-children',
                    'language', 'topicview',
                    'hide-labels', 'label-type', 'dates',
                    'coarseness', 'currency', 'max-items',
                    'colour-index', 'colour-palette', 'colour-palette-custom',
                    'profiles', 'sentiment-grouping', 'sampled-sentiment',
                    'sentiment-verification']
        },

        //---------------------------------------

        initialize: function() {
            this.model.set('display-options', this.display);
            this.model.on('change', function () {if (this.model.get('type') === "Sentiment") this.checkSentimentVerification();}, this);
        },

        //---------------------------------------

        bindings: function() {
            return {
                currency: { converter: Beef.CurrencyPicker.converter, elAttribute: "data-value" },
                brands: { converterFactory: createBrandTagConverterFactory("Brands"), elAttribute: "data-value" },
                profiles: { converterFactory: Beef.ProfilePicker.createConverterFactory("Profiles"), elAttribute: "data-value" },
                topicview: { converterFactory: Beef.TopicViewPicker.createConverterFactory("Select a Topic Tree"), elAttribute: "data-value" },
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
            }
        },

        regions: {
            colourSelector: '.colour-selector'
        },

        events: {
            "click .btn":          "isEnabled",
            "click .text":          "text",
            "click .histogram":     "histogram",
            "click .rows":          "rows",
            "click .columns":       "columns",
            "click .pies":          "pies",
            "click .lines":         "lines",
            "click .total":         "total",
            "click .hourly":        "hourly",
            "click .daily":         "daily",
            "click .weekly":        "weekly",
            "click .monthly":       "monthly",
            "click .yearly":        "yearly",
            "change .other":        "other",
            "change .unknown":      "unknown",
            "change .labels":       "labels",
            "change .legend":       "legend",
            "change .parents":      "parents",
            "change .children":     "children",
            "click .count":         "count",
            "click .percentage":    "percentage",
            "click .ave":           "ave",
            "click .ots":           "ots",
            "click .credibility":   "credibility",
            "click .colour-scheme": "showColourSelector",
            "click .colour-back":   "hideColourSelector",
            "click .automaticSentiment": "automaticSentiment",
            "click .verboseSentiment" : "verboseSentiment",
            "click .succinctSentiment" : "succinctSentiment",
            "click .sampled-sentiment" : "sampledSentiment",
            "click .sentiment-verification" : "sentimentVerification"
        },

        //---------------------------------------

        templateHelpers: function() {
            return {
                colour: getPalette(this.model.attributes,
                    this.options.originalModel.getDashboardModel().attributes)[0]
            }
        },

        //---------------------------------------

        onFirstRender: function() {
            this.$('.' + this.model.get('chart-type')).addClass('active');
            this.$('.' + this.model.get('label-type')).addClass('active');
            this.$('.other').attr('checked', !this.model.get('hide-others'));
            this.$('.unknown').attr('checked', !this.model.get('hide-unknown'));
            this.$('.labels').attr('checked', !this.model.get('hide-labels'));
            this.$('.legend').attr('checked', !this.model.get('hide-legend'));
            this.$('.parents').attr('checked', !this.model.get('hide-parents'));
            this.$('.children').attr('checked', !this.model.get('hide-children'));
            this.$('.' + this.model.get('coarseness')).addClass('active');
            this.$('.' + (this.model.get('sentiment-grouping') || Beef.Widget.MetricSettings.SentimentGroupType.succinct) ).addClass('active');

            Beef.CurrencyPicker.attach(this, ".currency", "currency", {onlyOne: true});
            Beef.BrandPicker.attach(this, ".brands", "brands", {rootsOnly: true});
            Beef.ProfilePicker.attach(this, ".profiles", "profiles");
            Beef.LanguagePicker.attach(this, ".language", "language");
            Beef.TopicViewPicker.attach(this, ".topic-view", "topicview", {onlyOne: true, onlyTrees: !!this.display.show.topictree});

            this.colourSelector.show(new Beef.ColourSettings.View({
                model: this.model,
                dashboardModel: this.options.originalModel.getDashboardModel()
            }));

            this.sampledSentimentToView();
            this.sentimentVerificationToView();
        },

        sampledSentimentToView: function() {
            this.$el.find(".sampled-sentiment button").toggleClass("active", false);
            var ss = this.model.get('sampled-sentiment');
            if (ss == null) ss = 'auto';
            this.$el.find(".sampled-sentiment button[data-id='" + ss + "']").toggleClass("active", true);

            // stop the user from selecting count and ten point scale when estimate sentiment is auto
            if (this.display.show.sampledSentiment) {
                this.disableButtons();
            }
        },

        sentimentVerificationToView: function() {
            this.$el.find(".sentiment-verification").toggleClass("active", false);
            var veri = this.model.get("sentiment-verification");
            if (!veri) veri = "yes";
            this.$el.find(".sentiment-verification button[data-id='" + veri + "']").toggleClass("active", true);
            this.disableSentimentVerification();
        },

        disableSentimentVerification: function () {
            var labelWidget = this.$el.find("#senti-ver-label-widget");
            labelWidget.hide();
            var widgetFilter = this.model.get('filter');

            this.$el.find(".sentiment-verification button[data-id='yes']").removeClass('disabled');
            this.$el.find(".sentiment-verification button[data-id='no']").removeClass('disabled');

            if (widgetFilter !== "" && widgetFilter !== undefined) {
                labelWidget.show();
                this.$el.find(".sentiment-verification button[data-id='yes']").addClass('disabled');
                this.$el.find(".sentiment-verification button[data-id='no']").addClass('disabled');
            }
        },

        checkSentimentVerification: function() {
            if (this.model.changed.hasOwnProperty('filter')) {
                this.disableSentimentVerification();
            }
        },

        // if btn is clicked and it is disabled stop the event from propagating
        isEnabled: function (ev) {
            var disabled = $(ev.target).hasClass('disabled');
            if (disabled) {
                ev.stopPropagation();
            }
        },
        //---------------------------------------

        text: function() {
            if (this.model.get('coarseness') !== Beef.Widget.MetricSettings.Coarseness.total) {
                this.total();
                this.$('.hourly').removeClass('active');
                this.$('.daily').removeClass('active');
                this.$('.weekly').removeClass('active');
                this.$('.monthly').removeClass('active');
                this.$('.yearly').removeClass('active');
                this.$('.total').addClass('active');
            }
            this.model.set("chart-type", Beef.Widget.MetricSettings.ChartType.text);
        },

        histogram: function() {
            if (this.model.get('coarseness') === Beef.Widget.MetricSettings.Coarseness.total) {
                this.$('.total').removeClass('active');

                if (filterSuggestsHourly(this.options.originalModel.getSectionModel().get('filter'))) {
                    this.hourly();
                    this.$('.hourly').addClass('active');
                }
                else {
                    this.daily();
                    this.$('.daily').addClass('active');
                }
            }
            this.model.set("chart-type", Beef.Widget.MetricSettings.ChartType.histogram);
        },

        columns: function() {
            this.model.set("chart-type", Beef.Widget.MetricSettings.ChartType.columns);
        },

        rows: function() {
            this.model.set("chart-type", Beef.Widget.MetricSettings.ChartType.rows);
        },

        pies: function() {
            this.model.set("chart-type", Beef.Widget.MetricSettings.ChartType.pies);
        },

        lines: function() {
            this.model.set("chart-type", Beef.Widget.MetricSettings.ChartType.lines);
        },

        other: function() {
            this.model.set('hide-others', !this.$('.other').is(':checked'));
        },

        parents: function() {
            this.model.set('hide-parents', !this.$('.parents').is(':checked'));
        },

        children: function() {
            this.model.set('hide-children', !this.$('.children').is(':checked'));
        },

        unknown: function() {
            this.model.set('hide-unknown', !this.$('.unknown').is(':checked'))
        },

        labels: function() {
            this.model.set('hide-labels', !this.$('.labels').is(':checked'))
        },

        legend: function() {
            this.model.set('hide-legend', !this.$('.legend').is(':checked'))
        },

        total: function() {
            this.model.set('coarseness', Beef.Widget.MetricSettings.Coarseness.total);
            this.$('.histogram').removeClass('active');
            this.$('.text').addClass('active');
            this.text();
        },

        hourly: function() {
            this.model.set('coarseness', Beef.Widget.MetricSettings.Coarseness.hourly);
            this.$('.histogram').addClass('active');
            this.$('.text').removeClass('active');
            this.histogram();
        },

        daily: function() {
            this.model.set('coarseness', Beef.Widget.MetricSettings.Coarseness.daily);
            this.$('.histogram').addClass('active');
            this.$('.text').removeClass('active');
            this.histogram();
        },

        weekly: function() {
            this.model.set('coarseness', Beef.Widget.MetricSettings.Coarseness.weekly);
            this.$('.histogram').addClass('active');
            this.$('.text').removeClass('active');
            this.histogram();
        },

        monthly: function() {
            this.model.set('coarseness', Beef.Widget.MetricSettings.Coarseness.monthly);
            this.$('.histogram').addClass('active');
            this.$('.text').removeClass('active');
            this.histogram();
        },

        yearly: function() {
            this.model.set('coarseness', Beef.Widget.MetricSettings.Coarseness.yearly);
            this.$('.histogram').addClass('active');
            this.$('.text').removeClass('active');
            this.histogram();
        },

        count: function() {
            this.model.set('label-type', Beef.Widget.MetricSettings.LabelType.count);
        },

        percentage: function() {
            this.model.set('label-type', Beef.Widget.MetricSettings.LabelType.percentage);
        },

        ave: function() {
            this.model.set('label-type', Beef.Widget.MetricSettings.LabelType.ave);
        },

        ots: function() {
            this.model.set('label-type', Beef.Widget.MetricSettings.LabelType.ots);
        },

        credibility: function() {
            this.model.set('label-type', Beef.Widget.MetricSettings.LabelType.credibility);
        },


        //---------------------------------------------------

        showColourSelector: function() {
            this.flipDivs('.main-metric-settings', '.colour-page');
        },

        hideColourSelector: function() {
            this.flipDivs('.colour-page', '.main-metric-settings');
        },

        automaticSentiment: function() {
            this.model.set("sentiment-grouping", Beef.Widget.MetricSettings.SentimentGroupType.automatic);
        },

        verboseSentiment: function() {
            this.model.set("sentiment-grouping", Beef.Widget.MetricSettings.SentimentGroupType.verbose);
        },

        succinctSentiment: function() {
            this.model.set("sentiment-grouping", Beef.Widget.MetricSettings.SentimentGroupType.succinct);
        },

        sampledSentiment: function(ev) {
            var id = $(ev.target).attr("data-id");
            if (id) this.model.set("sampled-sentiment", id);
            this.disableButtons();
        },

        sentimentVerification: function(ev) {
            var oldVal = this.model.get("sentiment-verification");
            var id = $(ev.target).attr("data-id");

            if (oldVal === id) return; // no logic if nothings is changing.

            this.model.set("sentiment-verification", id);
        },

        disableButtons: function () {
            var ss = this.model.get('sampled-sentiment');
            if (ss == null) ss = 'auto';
            if (ss === 'auto' || ss === 'true') {
                this.$('.btn.automaticSentiment').removeClass('active');
                this.$('.btn.verboseSentiment').removeClass('active');
                this.$('.btn.succinctSentiment').removeClass('active');
                this.$('.' + this.model.get('label-type')).removeClass('active');
                this.percentage();
                this.succinctSentiment();
                this.$('.' + this.model.get('sentiment-grouping')).addClass('active');
                this.$('.' + this.model.get('label-type')).addClass('active');

                this.$('.btn.count').addClass('disabled');
                this.$('.btn.percentage').addClass('disabled');
                this.$('.btn.automaticSentiment').addClass('disabled');
                this.$('.btn.verboseSentiment').addClass('disabled');
                this.$('.btn.succinctSentiment').addClass('disabled');
            } else {
                this.$('.btn.count').removeClass('disabled');
                this.$('.btn.percentage').removeClass('disabled');
                this.$('.btn.automaticSentiment').removeClass('disabled');
                this.$('.btn.verboseSentiment').removeClass('disabled');
                this.$('.btn.succinctSentiment').removeClass('disabled');
            }
        },

        flipDivs: function(fromSelector, toSelector) {
            var outAnimation = 'short-animated fadeOut';
            var inAnimation = 'short-animated fadeIn';

            var $from = this.$(fromSelector);
            var $to = this.$(toSelector);

            $from.toggleClass(outAnimation, false);
            $from.toggleClass(inAnimation, false);
            $to.toggleClass(outAnimation, false);
            $to.toggleClass(inAnimation, false);

            $from.toggleClass(outAnimation, true);
            setTimeout(function() {
                $from.hide();
                $from.toggleClass(outAnimation, false);
                $to.show();
                $to.toggleClass(inAnimation, true)
            }.bind(this), 410);
        }
    });

});
