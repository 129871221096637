<template>
    <div class="row-fluid" :class="index === 0 ? 'first-col': 'other-col'">
        <div class="comp-table-row span12 control-group control-row">
            <drop-down-input v-model="type" :options="colOptions" style="width: 200px; flex-grow: 0"
                             :label="index === 0 ? 'Column' : null"/>

            <div style="margin-left: 8px; min-width: 88px" class="show-checks">
                <div style="display: flex">
                    <label class="checkbox">
                        <input v-model="showValue" type="checkbox"> Value
                    </label>
                    <label class="checkbox" style="margin-left: 8px">
                        <input v-model="showBar" type="checkbox"> Bar
                    </label>
                </div>
                <div style="display: flex">
                    <label class="checkbox" v-if="!colType.noPercentageOption">
                        <input v-model="showPercentage" type="checkbox"> Percentage
                    </label>
                    <label class="checkbox" v-if="attrs.prevDate || attrs.prevFilter" style="margin-left: 8px">
                        <input v-model="showDelta" type="checkbox"> Change
                    </label>
                </div>
            </div>

            <div v-if="canGroupBy">
                <drop-down-input  v-model="groupBy" :options="groupByOptions" label="Compare" optional
                            style="width: 165px; flex-grow: 0"/>
            </div>
            <template v-if="col.groupBy">
                    <text-input v-if="showLimitInput" v-model="limit" numeric label="Max values" :rules="rules.maxItems"
                                style="width: 70px; flex-grow: 0"/>

                    <drop-down-input v-if="showSlaResponseTimeInput"  v-model="slaResponseTime" :options="slaResponseTimeOptions" label="SLA response time"
                                 style="width: 120px; flex-grow: 0"/>

                    <div style="margin-left: 10px; margin-top: 13px">
                        <label class="checkbox">
                            <input v-model="showUnknown" type="checkbox"> Show unknown
                        </label>
                        <label class="checkbox" v-if="!colType.noPercentageOption">
                            <input v-model="showOther" type="checkbox"> Show others
                        </label>
                    </div>
            </template>
            <text-input v-else v-model="name" :label="index === 0 ? 'Column name' : null"
                        style="width: 140px; flex-grow: 0"/>

            <colour-settings-input :value="col" :dashboard-model="dashboardModel" no-opacity single
                                   :label="index === 0 ? '\u00A0' : null" :default-value="defaultColour"/>

            <up-down-button v-if="canRemove" :index="index" :array="attrs.cols"
                style="margin-left: 4px" :style="{marginTop: (index === 0 ? 22 : 0) + 'px'}"/>

            <a v-if="canRemove" class="btn btn-link" @click="remove" title="Remove this column"
               :style="{marginTop: (index === 0 ? 25 : 2) + 'px'}">
                <i class="icon-trash-1" />
            </a>
        </div>
    </div>
</template>

<script>
import DropDownInput from "@/components/inputs/DropDownInput";
import TextInput from "@/components/inputs/TextInput";
import ColourSettingsInput from "@/dashboards/ColourSettingsInput";
import UpDownButton from "@/components/UpDownButton";
import {RULES} from "@/dashboards/widgets/WidgetSettingsDialog";
import {getAvailableGroupByOptions, COLS, COLS_BY_ID, DEFAULT_COLOUR, GROUPBY, SLA_RESPONSE_TIME_OPTIONS} from "@/dashboards/widgets/comptable/CompTableUtils";

export default {
    name: "CTColSettings",

    components: { DropDownInput, TextInput, ColourSettingsInput, UpDownButton },

    props: {
        col: Object,
        index: Number,
        attrs: Object,
        dashboardModel: Object
    },

    data() {
        if (this.col.showValue === undefined) this.$set(this.col, 'showValue', true)
        return {
            colOptions: COLS,
            rules: RULES,
            defaultColour: DEFAULT_COLOUR,
            slaResponseTimeOptions: SLA_RESPONSE_TIME_OPTIONS,

            type: this.col.type,
            showValue: this.col.showValue,
            showBar: this.col.showBar,
            showPercentage: this.col.showPercentage,
            showDelta: this.col.showDelta,
            groupBy: this.col.groupBy,
            limit: this.col.limit,
            slaResponseTime: this.col.slaResponseTime,
            showUnknown: this.col.showUnknown,
            showOther: this.col.showOther,
            name: this.col.name,
        }
    },

    created() {
        // set certain cols to not be visible based on feature flag
        if (!this.slaResponseTime) this.slaResponseTime = 2;
    },

    watch: {
        type() {
            this.$set(this.col, "type", this.type);
        },
        'col.type'() {
            this.type = this.col.type;
        },

        showValue() {
            this.$set(this.col, "showValue", this.showValue);
        },
        'col.showValue'() {
            this.showValue = this.col.showValue;
        },

        showBar() {
            this.$set(this.col, "showBar", this.showBar);
        },
        'col.showBar'() {
            this.showBar = this.col.showBar;
        },

        showPercentage() {
            this.$set(this.col, "showPercentage", this.showPercentage);
        },
        'col.showPercentage'() {
            this.showPercentage = this.col.showPercentage;
        },

        showDelta() {
            this.$set(this.col, "showDelta", this.showDelta);
        },
        'col.showDelta'() {
            this.showDelta = this.col.showDelta;
        },

        groupBy() {
            this.$set(this.col, "groupBy", this.groupBy);
        },
        'col.groupBy'() {
            this.groupBy = this.col.groupBy;
        },

        limit() {
            this.$set(this.col, "limit", this.limit);
        },
        'col.limit'() {
            this.limit = this.col.limit;
        },

        slaResponseTime() {
            this.$set(this.col, "slaResponseTime", this.slaResponseTime);
        },
        'col.slaResponseTime'() {
            this.slaResponseTime = this.col.slaResponseTime;
        },

        showUnknown() {
            this.$set(this.col, "showUnknown", this.showUnknown);
        },
        'col.showUnknown'() {
            this.showUnknown = this.col.showUnknown;
        },

        showOther() {
            this.$set(this.col, "showOther", this.showOther);
        },
        'col.showOther'() {
            this.showOther = this.col.showOther;
        },

        name() {
            this.$set(this.col, "name", this.name);
        },
        'col.name'() {
            this.name = this.col.name;
        }
    },

    computed: {
        canGroupBy() { return this.index === 0 && this.attrs.cols.length === 1 },

        canRemove() { return this.attrs.cols.length > 1 },

        groupByOptions() {
            let used = []
            this.attrs.rows.forEach(r => { if (r.groupBy) used.push(r.groupBy) })
            return getAvailableGroupByOptions(used)
        },

        colType() {
            return COLS_BY_ID[this.col.type] || { }
        },

        showSlaResponseTimeInput() {
            return this.col.groupBy === "interactionResponseTimeBreakdown" || this.col.groupBy === "interactionResponseTimeWhBreakdown" || this.col.groupBy === "firstResponseTimeBreakdown" || this.col.groupBy === "firstResponseTimeWhBreakdown";
        },

        showLimitInput() {
            for (const groupByOption of GROUPBY) {
                if (groupByOption.id === this.col.groupBy) {
                    return !groupByOption.noMax;
                }
            }

            return true;
        }
    },

    methods: {
        remove() {
            const result = Array.from(this.attrs.cols);
            result.splice(this.index, 1);
            this.$set(this.attrs, "cols", result);
        }
    }
}
</script>

<style scoped>

.comp-table-row {
    align-items: center;
}

.first-col .show-checks {
    margin-top: 14px;
}

.other-col .show-checks {
    margin-top: -7px;
}

</style>