<template>
    <div class="explore-setup">
        <header>
            <h1>Explore setup</h1>
            <staff-only-tag/>
        </header>

        <div v-if="setupWarnings && setupWarnings.length">
            This account has <strong><deq-number :number="uniqueCount"/> {{formatPlural(uniqueCount, 'warning')}}</strong>
            that could be an account setup problem, and  which could cause Rewind to not work well.
        </div>
        <div v-else-if="setupWarnings">
            <div class="explore-setup__all-good">
                This account has been setup to make best use of the Explore panel.
            </div>
        </div>

        <warning-cluster-card v-if="setupWarnings" :warnings="setupWarnings" :all-possible-warnings="allWarnings"/>
    </div>
</template>


<script>
import {mapActions, mapState} from "vuex";
import DeqNumber from "@/components/formatters/DeqNumber";
import {formatBrandName, formatPlural} from "@/app/utils/Format";
import StaffOnlyTag from "@/components/tags/StaffOnlyTag";
import WarningClusterCard from "@/components/cards/WarningClusterCard";
import {ALL_EXPLORE_WARNINGS, countUniqueWarnings} from "@/store/vuex/dataChecks";

export default {
    components: {DeqNumber, WarningClusterCard, StaffOnlyTag},

    data() {
        return {
            allWarnings: ALL_EXPLORE_WARNINGS
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState('explorePanel', ['setupWarnings']),

        uniqueCount() {
            return countUniqueWarnings(this.setupWarnings)
        },

        warningsTypes() {
            if (!this.setupWarnings?.length) return [];
            return Array.from(new Set(this.setupWarnings.map(warning => warning.id)));
        },

        visibleWarningDescriptions() {
            if (!this.setupWarnings) return [];
            const warnings = new Set(this.setupWarnings.map(w => w.id));
            return Object.keys(this.allWarnings)
                .filter(key => !warnings.has(key) && !this.allWarnings[key]?.hide);
        }
    },

    async mounted() {
        await this.checkWarnings();
    },

    methods: {
        formatBrandName,
        formatPlural,
        ...mapActions('explorePanel', ['checkWarnings']),

        getDescription(type) {
            const item = this.setupWarnings?.find(warning => warning.id === type);
            if (!item) return "";
            return item.description;
        },

        getComment(type, brand) {
            const item = this.setupWarnings?.find(warning => warning.id === type && warning.brand?.id === brand?.id);
            if (!item) return null;
            return item.comment;
        },

        getFix(type) {
            const item = this.setupWarnings?.find(warning => warning.id === type);
            if (!item?.fix) return null;
            return item.fix;
        },

        getBrandsForWarning(type) {
            if (!this.setupWarnings?.length) return [];
            return this.setupWarnings.filter(warning => warning.id === type && warning.brand).map(warning => warning.brand)
        },

        getBrandLink(brand) {
            return `/${this.account.code}/setup/brands/${brand?.id}`;
        },

        gotoBrand(brand) {
            Beef.router.navigate(this.getBrandLink(brand), {trigger: true});
        }
    }
}
</script>


<style scoped lang="sass">

header
    border-bottom: thin solid grey
    padding-bottom: 5px
    margin-bottom: 20px

    h1
        color: white
        margin-bottom: 0
        padding-bottom: 0

.explore-setup
    color: var(--be-colour-text-dark)
    strong
        color: white
        font-size: calc(1em + 1px)



.explore-setup__all-good
    text-align: center
    color: white







</style>