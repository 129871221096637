var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"control-group\">\n        <span class=\"spinner\"></span> <span>Searching for mentions .. please wait</span>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"control-group\">\n        Your phrase will bring in approximately <strong>"
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mentionsPerDay") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":9,"column":56},"end":{"line":9,"column":87}}}))
    + " mentions(s) per day</strong>,\n        and <strong>"
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mentionsTotal") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":50}}}))
    + " total mention(s)</strong>.\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"mentions") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mentions") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":67,"column":11}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"control-group\">\n            This search phrase did not return any results at this time. If you would like to edit your search\n            phrase to make it less specific, please return to the previous screen or click Ok to save.\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"control-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"high") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"medium") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"no") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ok") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"phrase-mentions\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"mentions") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":65,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                Such a high volume of conversation will negatively impact the performance of your account.\n                Please return to the previous screen to refine your search phrase by including more terms or\n                adding words or phrases which you would like to exclude from the search.\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                This is a high volume of mentions which will impact the relevancy of your overall conversation and\n                make it much harder to derive insights from the data. We suggest clicking Previous\n                and trying a more specific phrase.\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <div style=\"font-size: calc(1em + 2px); padding-block: 1rem;\">\n                    <div>\n                        <i class=\"symbol-warning\"></i> This phrase is expected to pull in far more data than the brand's volume limit in a month.\n                    </div>\n                    <div>\n                        <strong>Note:</strong>\n                        This phrase setup page cannot estimate any brand and phrase filters you have added will lower volumes.\n                        Be careful when adding this phrase.\n                    </div>\n                </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"control-group\">\n                Please review the sample of mentions below and select all that you don't feel are relevant. We'll use\n                the irrelevant mentions you select to help you fine tune your search phrase. When you've selected all\n                those that don't apply, click <strong>Next</strong> to proceed.\n            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"phrase-mention\">\n                    <img class=\"profile\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"authorPictureLink") : depth0), depth0))
    + "\" width=\"48\" height=\"48\"/>\n                    <input type=\"checkbox\" class=\"sel\">\n                    <span>From: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"authorName") : depth0), depth0))
    + "</span>\n                    <a class=\"author\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uri") : depth0), depth0))
    + "\" target=\"_blank\">@"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"authorHandle") : depth0), depth0))
    + "</a>\n                    <div class=\"text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"content") : depth0), depth0))
    + "</div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"message") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"message") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":69,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});