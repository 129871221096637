<template>
    <div class="platform-auth-settings">
        <header>
            <h1>Platform Authorisation</h1>
        </header>

        <p>Grant DataEQ access to profiles that you manage from various platforms.</p>

        <div v-show="showPlatformChooser" class="platform-auth-settings__platform-selector-container">
            <span>Select platform</span>
            <be-button
                @click="selectedPlatform = platforms.facebook"
                tooltip="Facebook & Instagram Business"
                :disabled="loading"
                :class="{'facebook' : selectedPlatform === platforms.facebook}">
                <i class="symbol-facebook-rect"></i> <i class="icon-instagram"></i>
            </be-button>

            <be-button
                @click="selectedPlatform = platforms.twitter"
                tooltip="X"
                :disabled="loading"
                :class="{'twitter' : selectedPlatform === platforms.twitter}">
                <i class="symbol-twitter"></i>
            </be-button>

            <be-button
                @click="selectedPlatform = platforms.linkedin"
                tooltip="LinkedIn"
                :disabled="loading"
                :class="{'linkedin' : selectedPlatform === platforms.linkedin}">
                <i class="symbol-linkedin-rect"></i>
            </be-button>

            <be-button
                v-if="features.googleManagedBusinessIntegration()"
                @click="selectedPlatform = platforms.googleBusiness"
                tooltip="Google"
                :disabled="loading"
                :class="{ 'googleBusiness': selectedPlatform === platforms.googleBusiness }">
                <i class="symbol-google-full"></i>
            </be-button>

            <be-button
                v-if="features.tikTokProfiles()"
                @click="selectedPlatform = platforms.tiktok"
                tooltip="Tiktok"
                :disabled="loading"
                :class="{ 'tiktok': selectedPlatform === platforms.tiktok }">
                <i class="symbol-tiktok"></i>
            </be-button>
        </div>

        <h4 class="platform-auth-settings__header">{{ heading }}</h4>
        <loading-message v-if="loading"
                         class="platform-auth-settings__loader"
                         :message="loadingMessage"/>

        <!-- Needs to be v-show, otherwise we can't emit events to this parent from child components like facebook-auth during loading -->
        <div v-show="!loading">
            <keep-alive>
                <div class="platform-auth-settings__no-platform-selected" v-if="!selectedPlatform">
                    <i class="symbol-online-profiles"></i>
                    <h2>Select a platform to get started</h2>
                </div>
                <facebook-auth
                    ref="facebookAuth"
                    v-if="selectedPlatform === platforms.facebook"
                    :expandAccounts="options ? !!options.expandAccounts : false"
                    :logged-in-through-facebook="loggedInThroughFacebook"
                    :facebook-pages="managedProfiles"
                    @loading-updated="setLoading"
                    @show-platform-chooser="setShowPlatformChooser"
                    @logged-in-through-facebook="setLoggedInThroughFacebook"
                    @refresh-facebook-pages="fetchManagedFacebookPages($event.forceRefresh, $event.showNotification)"
                    @unauth-profile="unauthoriseProfile">
                </facebook-auth>
                <twitter-auth
                    ref="twitterAuth"
                    v-if="selectedPlatform === platforms.twitter"
                    :expandAccounts="options ? !!options.expandAccounts : false"
                    :twitter-profiles="managedProfiles"
                    @loading-updated="setLoading"
                    @show-platform-chooser="setShowPlatformChooser"
                    @fetch-twitter-profiles="fetchTwitterProfiles"
                    @unauth-profile="unauthoriseProfile">
                </twitter-auth>
                <linkedin-auth
                    ref="linkedinAuth"
                    v-if="selectedPlatform === platforms.linkedin"
                    :expandAccounts="options ? !!options.expandAccounts : false"
                    :linkedin-profiles="managedProfiles"
                    @loading-updated="setLoading"
                    @show-platform-chooser="setShowPlatformChooser"
                    @fetch-linkedin-profiles="fetchLinkedinProfiles"
                    @unauth-profile="unauthoriseProfile">
                </linkedin-auth>
                <google-business-auth
                    ref="googleBusinessAuth"
                    v-if="selectedPlatform === platforms.googleBusiness"
                    :expandAccounts="options ? !!options.expandAccounts : false"
                    :google-business-profiles="managedProfiles"
                    @loading-updated="setLoading"
                    @show-platform-chooser="setShowPlatformChooser"
                    @fetch-google-business-profiles="fetchGoogleBusinessProfiles"
                    @unauth-profile="unauthoriseProfile">
                </google-business-auth>
                <tiktok-auth
                    ref="TiktokAuth"
                    v-if="selectedPlatform === platforms.tiktok"
                    :expandAccounts="options ? !!options.expandAccounts : false"
                    :tiktok-profiles="managedProfiles"
                    @loading-updated="setLoading"
                    @show-platform-chooser="setShowPlatformChooser"
                    @fetch-tiktok-profiles="fetchTiktokProfiles"
                    @unauth-profile="unauthoriseProfile">
                </tiktok-auth>
            </keep-alive>
        </div>
    </div>
</template>

<script>
import Beef from "@/app/Beef";
import { features } from "@/app/Features";
import { showWhenDialog } from "@/app/framework/dialogs/Dialog";
import { showDialogComponent as showDialog } from "@/app/framework/dialogs/DialogUtilities";
import GoogleBusinessAuth from "@/app/framework/dialogs/user-settings/views/platform-auth/google-business/GoogleBusinessAuth";
import TiktokAuth from "@/app/framework/dialogs/user-settings/views/platform-auth/tiktok/TikTokAuth";
import LinkedinAuth from "@/app/framework/dialogs/user-settings/views/platform-auth/linkedin/LinkedinAuth";
import { notifyUser, notifyUserOfError, notifyWithText } from "@/app/framework/notifications/Notifications";
import { showTip } from "@/app/help/tips/tips";
import LoadingMessage from "@/components/LoadingMessage";
import BeButton from "@/components/buttons/BeButton";
import { profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef, mash } from "@/store/Services";
import VuexStore from "@/store/vuex/VuexStore";
import { mapActions, mapState } from "vuex";
import { platforms } from "./PlatformAuthUtils";
import AddedRemovedPageDetails from "./facebook/AddedRemovedPageDetails";
import FacebookAuth from "./facebook/FacebookAuth";
import TwitterAuth from "./twitter/TwitterAuth";

export default {
    name: "PlatformAuthSettings",
    store: VuexStore,
    components: {LinkedinAuth, GoogleBusinessAuth, BeButton, TwitterAuth, FacebookAuth, LoadingMessage, TiktokAuth},

    props: {
        /**
         * Options for platform auth settings. the following fields are valid:
         *
         * @param {String} selectedPlatform: the platform that the settings should open on immediately, see {@link platforms} for possible values (required).
         * @param {Object} authorise: authorisation settings.
         * @param {Boolean} authorise.trigger: true if authorisation should be triggered immediately, false otherwise.
         * @param {Object} authorise.profile: the profile that should be authorised. Only required if authorise.trigger is true and selectedPlatform is {@link platforms.facebook}.
         * @param {String} authorise.profile.handle: the handle of the profile that should be authorised.
         * @param {String} authorise.profile.handleId: the handle ID of the profile that should be authorised.
         */
        options: {
            type: Object,
            default: function() {return {}}
        }
    },

    data: function () {
        return {
            managedProfiles: [],
            selectedPlatform: null,
            loading: false,
            loadingMessage: "",
            showPlatformChooser: true,
            features: features,
            profileType: profileTypes,
            platforms: platforms,

            // Facebook
            showTerms: false,
            loggedInThroughFacebook: false
        }
    },

    computed: {
        ...mapState(['user', 'account']),

        ...mapState('userPlatformAuth', [
            'facebookUserToken', 'linkedinLoginStatus', 'managedFacebookPages', 'managedTwitterProfiles', 'managedLinkedinProfiles'
        ]),

        ...mapState('userPlatformAuth/googleBusinessAuthModule', [
             'googleBusinessLoginStatus', 'managedGoogleBusinessProfiles'
        ]),

        ...mapState('userPlatformAuth/tiktokAuthModule', [
             'tiktokLoginStatus', 'managedTiktokProfiles'
        ]),

        heading() {
            switch (this.selectedPlatform) {
                case platforms.facebook:
                    return "Managed Facebook pages & Instagram business profiles";
                case platforms.twitter:
                    return "Managed X profiles";
                case platforms.linkedin:
                    return "Managed LinkedIn profiles";
                case platforms.googleBusiness:
                    return "Managed Google Business profiles";
                default:
                    return "";
            }
        }
    },

    created() {
        if (this.options.selectedPlatform) {
            this.selectedPlatform = this.options.selectedPlatform;

            if (this.options.authorise && this.options.authorise.trigger) {
                let authOptions = this.options.authorise;
                switch (this.selectedPlatform) {
                    case platforms.facebook:
                        this.$nextTick(() => {
                            this.$refs.facebookAuth.handleFacebookLogin();
                        });
                        break;
                    case platforms.twitter:
                        if (authOptions.profile?.handle && authOptions.profile?.handleId) {
                            this.$nextTick(() => {
                                this.$refs.twitterAuth.authoriseProfile(authOptions.profile.handle, authOptions.profile.handleId);
                            });
                        }
                        break;
                    case platforms.linkedin:
                        if (authOptions.profile?.handle && authOptions.profile?.handleId) {
                            this.$nextTick(() => {
                                this.$refs.linkedinAuth.authoriseProfile();
                            });
                        }
                        break;
                    case platforms.googleBusiness:
                        if (authOptions.profile?.handle && authOptions.profile?.handleId) {
                            this.$nextTick(() => {
                                this.$refs.googleBusinessAuth.authoriseProfile();
                            });
                        }
                        break;
                }
            }
        } else {
            const lastPlatformSelected = sessionStorage.getItem("beef:user:" + this.user.id + ":platform-auth-selection");
            if (lastPlatformSelected) this.selectedPlatform = lastPlatformSelected;
        }

        // don't show the tip if:
        // 1. The user has been redirected to this page from the dialog that the tip is about
        // 2. The user is currently on the page that the tip action redirects them to
        if (!this.redirectPlatform && window.location.href.indexOf("setup/profiles") === -1) {
            if (this.account?.code && (this.managedFacebookPages.length || this.managedTwitterProfiles.length)) {
                showTip("ADD_MULTIPLE_PROFILES")
            }
        }
    },

    methods: {
        ...mapActions('userPlatformAuth', [
            'initializeFbUserToken', 'initLinkedInUserToken', 'initializeManagedFacebookPages', 'initializeManagedTwitterProfiles', 'initializeManagedLinkedinProfiles'
        ]),

        ...mapActions('userPlatformAuth/googleBusinessAuthModule' , [
            'initGoogleBusinessUserToken', 'initializeManagedGoogleBusinessProfiles'
        ]),

        ...mapActions('userPlatformAuth/tiktokAuthModule' , [
            'initializeManagedTiktokProfiles'
        ]),

        async isFacebookAuthorised() {
            await this.initializeFbUserToken(true);
            return !!this.facebookUserToken;
        },

        async isLinkedInAuthorised() {
            await this.initLinkedInUserToken(true);
            return this.linkedinLoginStatus.loggedIn;
        },

        async isGoogleBusinessAuthorised() {
            await this.initGoogleBusinessUserToken(true);
            return this.googleBusinessLoginStatus.loggedIn;
        },

        async fetchManagedFacebookPages(forceRefresh, showNotification) {
            try {
                forceRefresh ??= false;
                showNotification ??= false;

                let oldPages = Object.assign([], this.managedProfiles);

                this.managedProfiles = [];

                await this.initializeManagedFacebookPages(forceRefresh);

                let facebookPages = JSON.parse(JSON.stringify(this.managedFacebookPages));

                let promises = [];

                for (let i = 0; i < facebookPages?.length; i++) {
                    let facebookPage = facebookPages[i];

                    if (facebookPage) promises.push(this.setManagedProfileAccounts(facebookPage));
                }

                await Promise.all(promises);

                this.managedProfiles = facebookPages;

                // will notify user of pages added/removed
                if (showNotification) {
                    let pagesAdded = [];
                    let pagesRemoved = [];

                    // compare pages
                    let oldHandleIds = new Set(oldPages.map(page => page.handleId));
                    let curHandleIds = new Set(this.managedProfiles.map(page => page.handleId));

                    oldHandleIds.forEach(oldHandleId => {
                        if (!curHandleIds.has(oldHandleId)) pagesRemoved.push(oldHandleId);
                    });

                    curHandleIds.forEach(curHandleId => {
                        if (!oldHandleIds.has(curHandleId)) pagesAdded.push(curHandleId);
                    });

                    if (pagesAdded.length || pagesRemoved.length) {
                        let message = pagesAdded.length ? (pagesAdded.length === 1 ? `<div>${pagesAdded.length} new page has been authorised</div>` : `<div>${pagesAdded.length} new pages have been authorised</div>`)  : ``
                        message += pagesRemoved.length ? (pagesRemoved.length === 1 ? `<div>${pagesRemoved.length} page has been unauthorised</div>` : `<div>${pagesRemoved.length} pages have been unauthorised</div>`) : ``

                        notifyUser({
                            message: message,
                            isEscapedHtml: true,
                            longDelay: true,
                            icon: '<i class="symbol-facebook-rect"></i>',
                            action: {
                                name: "View",
                                tooltip: "View which pages were authorised and/or unauthorised",
                                method: () => {
                                    showDialog(AddedRemovedPageDetails, {curPages: this.managedProfiles, oldPages: oldPages});
                                }
                            }
                        });
                    }
                }
            } finally {
                this.loading = false;
            }
        },

        async fetchTwitterProfiles(forceRefresh) {
        forceRefresh ??= false;
        try {
            this.loading = true;
                this.loadingMessage = "Fetching your Twitter Profiles...";

                this.managedProfiles = [];

                await this.initializeManagedTwitterProfiles(forceRefresh);

                let twitterPages = JSON.parse(JSON.stringify(this.managedTwitterProfiles));

                let promises = [];

                for (let i = 0; i < twitterPages?.length; i++) {
                    let twitterProfile = twitterPages[i];

                    if (twitterProfile) promises.push(this.setManagedProfileAccounts(twitterProfile));

                    this.managedProfiles.push(twitterProfile);
                }

                await Promise.all(promises);

                this.managedProfiles = twitterPages;
            } catch (e) {
                console.error("Error occurred while fetching user Twitter profiles", e)

                notifyUserOfError("An error occurred while loading your Twitter profiles. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        async fetchLinkedinProfiles(forceRefresh) {
            forceRefresh ??= false;
            try {
                this.loading = true;
                this.loadingMessage = "Fetching your LinkedIn profiles...";

                this.managedProfiles = [];

                await this.initializeManagedLinkedinProfiles(forceRefresh);

                let linkedinProfiles = JSON.parse(JSON.stringify(this.managedLinkedinProfiles));

                let promises = [];

                for (let i = 0; i < linkedinProfiles?.length; i++) {
                    let linkedinProfile = linkedinProfiles[i];

                    if (linkedinProfile) promises.push(this.setManagedProfileAccounts(linkedinProfile));

                    this.managedProfiles.push(linkedinProfile);
                }

                await Promise.all(promises);

                this.managedProfiles = linkedinProfiles;
            } catch (e) {
                console.error("Error occurred while fetching user Linkedin profiles", e)

                notifyUserOfError("An error occurred while loading your LinkedIn profiles. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        async fetchGoogleBusinessProfiles(forceRefresh) {
            forceRefresh ??= false;
            try {
                this.loading = true;
                this.loadingMessage = "Fetching your Google Business profiles...";

                this.managedProfiles = [];

                await this.initializeManagedGoogleBusinessProfiles(forceRefresh);

                let googleBusinessProfiles = JSON.parse(JSON.stringify(this.managedGoogleBusinessProfiles));

                let promises = [];

                for (let i = 0; i < googleBusinessProfiles?.length; i++) {
                    let googleProfile = googleBusinessProfiles[i];

                    if (googleProfile) promises.push(this.setManagedProfileAccounts(googleProfile));

                    this.managedProfiles.push(googleProfile);
                }

                await Promise.all(promises);

                this.managedProfiles = googleBusinessProfiles;
            } catch (e) {
                console.error("Error occurred while fetching user Google business profiles", e)

                notifyUserOfError("An error occurred while loading your Google business profiles. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        async fetchTiktokProfiles(forceRefresh) {
            forceRefresh ??= false;
            try {
                this.loading = true;
                this.loadingMessage = "Fetching your Tiktok profiles...";

                this.managedProfiles = [];

                await this.initializeManagedTiktokProfiles(forceRefresh);

                let tiktokProfiles = JSON.parse(JSON.stringify(this.managedTiktokProfiles));

                let promises = [];

                for (let i = 0; i < tiktokProfiles?.length; i++) {
                    let tiktokProfile = tiktokProfiles[i];

                    if (tiktokProfile) promises.push(this.setManagedProfileAccounts(tiktokProfile));

                    this.managedProfiles.push(tiktokProfile);
                }

                await Promise.all(promises);

                this.managedProfiles = tiktokProfiles;
            } catch (e) {
                console.error("Error occurred while fetching user Tiktok profiles", e)

                notifyUserOfError("An error occurred while loading your Tiktok profiles. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        /**
         * Checks if the profile is authorized on accounts that the user has access to.
         * Creates a list of accounts on the managed profile for each account the user has access to.
         */
        async setManagedProfileAccounts(managedProfile) {
            managedProfile.accounts = [];

            // for admin users, we can search for all accounts that the profile has been added to,
            // for non admin users we have to check each individual account that the user has access to
            if (this.user.admin) {
                let response = await mash.get(`/rest/online-profiles/search?handleId=${managedProfile.handleId}&type=${managedProfile.type}&excludeDeleted=true&phraseMatch=true`);
                let accountCodesSet = new Set();

                for (let i = 0; i < response.data?.length; i++) {
                    let accountOnlineProfile = response.data[i];

                    accountCodesSet.add(accountOnlineProfile.accountCode);

                    let account = {
                        accountCode: accountOnlineProfile.accountCode,
                        authorised: !!accountOnlineProfile?.authorized,
                        authorisedByCurUser: accountOnlineProfile?.authorizedById && accountOnlineProfile?.authorizedById === this.user.id,
                        onlineProfile: accountOnlineProfile,
                        saving: false,
                        accountProfileJustAuthorised: false,
                        accountProfileDmsJustToggled: false,
                        phraseMatching: !!accountOnlineProfile.brands
                    }

                    if (!account.authorised) {
                        managedProfile.accounts.push(account);
                        continue;
                    }

                    let expired = false;

                    // check if token is expired on Mash
                    if (accountOnlineProfile?.tokenExpire) expired = Beef.TokenExpirePopup.hasExpired(accountOnlineProfile?.tokenExpire);

                    if (!expired) {
                        managedProfile.accounts.push(account);
                    } else {
                        account.authorised = false;
                        managedProfile.accounts.push(account);
                    }
                }

                // accounts that the profile hasn't been added to yet
                let remainderAccounts = this.user.accounts.filter(acc => {
                    return !acc.inactive && !accountCodesSet.has(acc.code);
                });

                for (const remainderAccount of remainderAccounts) {
                    // LINKEDIN_USER profiles can only be added to accounts that have Engage.
                    if (managedProfile.type === profileTypes.linkedin_user && !remainderAccount.hasEngage) continue;

                    let account = {
                        accountCode: remainderAccount.code,
                        authorised: false,
                        saving: false,
                        accountProfileJustAuthorised: false,
                        accountProfileDmsJustToggled: false
                    }

                    managedProfile.accounts.push(account);
                }
            } else {
                for (let i = 0; i < this.user.accounts?.length; i++) {
                    let userAcc = this.user.accounts[i];

                    // LINKEDIN_USER profiles can only be added to accounts that have Engage.
                    if (managedProfile.type === profileTypes.linkedin_user && !userAcc.hasEngage) continue;

                    let accountOnlineProfile = {};

                    let account = {
                        accountCode: userAcc.code,
                        saving: false,
                        accountProfileJustAuthorised: false,
                        accountProfileDmsJustToggled: false,
                    }

                    try {
                        let response = await mash.get(`/rest/accounts/${userAcc.code}/online-profiles/handleId/${managedProfile.handleId}?phraseMatch=true`);
                        accountOnlineProfile = response.data;

                        account.authorised = !!accountOnlineProfile?.authorized;
                        account.authorisedByCurUser = accountOnlineProfile?.authorizedById && accountOnlineProfile?.authorizedById === this.user.id;
                        account.onlineProfile = accountOnlineProfile;
                        account.phraseMatching = !!accountOnlineProfile.brands
                    } catch (e) {
                        if (e.response?.status === 404) account.authorised = false;
                    }

                    if (!account.authorised) {
                        managedProfile.accounts.push(account);
                        continue;
                    }

                    let expired = false;

                    // check if token is expired on Mash
                    if (accountOnlineProfile?.tokenExpire) expired = Beef.TokenExpirePopup.hasExpired(accountOnlineProfile?.tokenExpire);

                    if (!expired) {
                        managedProfile.accounts.push(account);
                    } else {
                        account.authorised = false;
                        managedProfile.accounts.push(account);
                    }
                }
            }
        },

        async platformFacebookSelected() {
            try {
                this.loading = true;

                // check if user is Facebook authorised
                this.loadingMessage = "Checking if you are logged in through Facebook...";
                this.loggedInThroughFacebook = await this.isFacebookAuthorised();

                if (this.loggedInThroughFacebook) {
                    this.loadingMessage = "Fetching your managed Facebook pages...";
                    await this.fetchManagedFacebookPages(true);
                }
            } catch (e) {
                console.error("Error occurred while checking Facebook token validity/fetching managed Facebook pages", e)

                notifyUserOfError("An error occurred while loading your managed Facebook pages. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        async platformLinkedInSelected() {
            try {
                this.loading = true;

                // check if user is logged in through LinkedIn
                this.loadingMessage = "Checking if you are logged in through LinkedIn...";
                let loggedInThroughLinkedIn = await this.isLinkedInAuthorised();

                if (loggedInThroughLinkedIn) {
                    this.loadingMessage = "Fetching your managed LinkedIn company profiles...";
                    await this.fetchLinkedinProfiles(true);
                }
            } catch (e) {
                console.error("Error occurred while checking LinkedIn token validity/fetching managed LinkedIn profiles", e)

                notifyUserOfError("An error occurred while loading your managed LinkedIn profiles. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        async platformGoogleSelected() {
            try {
                this.loading = true;

                // check if user is logged in through Google
                this.loadingMessage = "Checking if you are logged in through Google...";
                let loggedInThroughGoogle = await this.isGoogleBusinessAuthorised();

                if (loggedInThroughGoogle) {
                    this.loadingMessage = "Fetching your managed Google business profiles...";
                    await this.fetchGoogleBusinessProfiles(true);
                }
            } catch (e) {
                console.error("Error occurred while checking Google Business token validity", e)

                notifyUserOfError("An error occurred while loading your managed Google business profiles. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        async platformTiktokSelected() {
            try {
                this.loadingMessage = "Fetching your managed Tiktok profiles...";
                await this.fetchTiktokProfiles(true);
            } catch (e) {
                console.error("Error occurred while checking Tiktok token validity", e)

                notifyUserOfError("An error occurred while loading your managed Tiktok profiles. Please close the dialog and try again or contact support.");
            } finally {
                this.loading = false;
            }
        },

        unauthoriseProfile(args) {
            let handleId = args.profile.handleId;
            let profileType = args.profile.type;
            let linkedProfile = args.linkedProfile;

            showWhenDialog(`Are you sure you want to unauthorise this profile?
            This profile will also be unauthorised from all accounts that you have added it to.`, "Unauthorise Profile?")
                .then(async () => {
                    try {
                        this.loading = true;
                        this.loadingMessage = "Unauthorising profile...";

                        if (linkedProfile) {
                            await beef.delete(`/api/online-profile/unauthorize-user-profile/profile/${handleId}?linkedPresenceHandleId=${linkedProfile.handleId}${this.account?.code ? `&accountCode=${this.account.code}` : ''}`);
                        } else {
                            await beef.delete(`/api/online-profile/unauthorize-user-profile/profile/${handleId}${this.account?.code ? `?accountCode=${this.account.code}` : ''}`);
                        }

                        let icon = '<i class="symbol-profiles"></i>';
                        switch (profileType) {
                            case this.profileType.facebook:
                                this.loadingMessage = "Fetching managed Facebook pages...";
                                await this.fetchManagedFacebookPages(true);
                                icon = '<i class="symbol-facebook-rect"></i>';
                                break;
                            case this.profileType.twitter:
                                await this.fetchTwitterProfiles(true);
                                icon = '<i class="symbol-twitter"></i>';
                                break;
                            case this.profileType.linkedin:
                                await this.fetchLinkedinProfiles(true);
                                icon = '<i class="symbol-linkedin"></i>';
                                break;
                        }

                        notifyWithText(`Your profile has been unauthorised.`,
                            null,
                            icon);
                    } catch (e) {
                        console.error(`An error occurred while unauthorising profile with handle ID ${handleId}`, e);

                        notifyUserOfError("An error occurred while unauthorising your profile. Please close the dialog and try again or contact support.");
                    } finally {
                        this.loading = false;
                    }
                });
        },

        setLoggedInThroughFacebook(value) {
            this.loggedInThroughFacebook = value;
        },

        setLoading(message, value) {
            this.loading = value;
            this.loadingMessage = message;
        },

        setShowPlatformChooser(value) {
            this.showPlatformChooser = value;
        }
    },

    watch: {
        selectedPlatform() {
            switch (this.selectedPlatform) {
                case platforms.facebook:
                    sessionStorage.setItem("beef:user:" + this.user.id + ":platform-auth-selection", platforms.facebook);
                    if (!this.options.authorise || !this.options.authorise.trigger) this.platformFacebookSelected(); // only trigger fetching of profiles if we are not immediately authorising
                    break;
                case platforms.twitter:
                    sessionStorage.setItem("beef:user:" + this.user.id + ":platform-auth-selection", platforms.twitter);
                    if (!this.options.authorise || !this.options.authorise.trigger) this.fetchTwitterProfiles(); // only trigger fetching of profiles if we are not immediately authorising
                    break;
                case platforms.linkedin:
                    sessionStorage.setItem("beef:user:" + this.user.id + ":platform-auth-selection", platforms.linkedin);
                    if (!this.options.authorise || !this.options.authorise.trigger) this.platformLinkedInSelected(); // only trigger fetching of profiles if we are not immediately authorising
                    break;
                case platforms.googleBusiness:
                    sessionStorage.setItem("beef:user:" + this.user.id + ":platform-auth-selection", platforms.googleBusiness);
                    if (!this.options.authorise || !this.options.authorise.trigger) this.platformGoogleSelected(); // only trigger fetching of profiles if we are not immediately authorising
                    break;
                case platforms.tiktok:
                    sessionStorage.setItem("beef:user:" + this.user.id + ":platform-auth-selection", platforms.tiktok);
                    if (!this.options.authorise || !this.options.authorise.trigger) this.platformTiktokSelected(); // only trigger fetching of profiles if we are not immediately authorising
                    break;
            }
        }
    }
}
</script>

<style scoped lang="sass">
.platform-auth-settings
    color: var(--be-colour-text-dark)

    &__platform-selector-container
        display: flex
        column-gap: 10px
        align-items: center
        padding: 5px 0

        span
            margin-right: 15px

        .facebook
            ::v-deep .btn
                background: linear-gradient(to right, #4267b2, #5963b3, #8357ad, #a6489d, #b53e92, #c13584) !important

        .twitter
            ::v-deep .btn
                background: var(--colour-twitter) !important

        .linkedin
            ::v-deep .btn
                background: var(--colour-linkedin) !important

        .googleBusiness
            ::v-deep .btn
                background: var(--colour-googlebusiness) !important
        .tiktok
            ::v-deep .btn
                background: var(--colour-tiktok-dark) !important

    &__no-platform-selected
        display: flex
        flex-direction: column
        align-items: center
        margin-top: 30px

        i
            font-size: 25px

        h2
            margin: 0

    &__platform-selector
        margin-top: 20px
        display: flex
        align-items: center
        gap: 15px
        width: 95%

    &__header
        border-bottom: 1px solid #666
        width: 95%

    &__loader
        margin: auto

    &__content
        height: clamp(240px, 25vh, 600px)
        padding-right: 10px
        overflow-y: auto

</style>