import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
import _ from 'underscore';

Beef.module("WidgetList").addInitializer(function(startupOptions) {

    var ListView = Backbone.Marionette.CollectionView.extend({
        tagName: "div",
        itemView: Beef.Widget.View,
        itemViewOptions: function() { return { cache: this.cache } },
        onAfterItemAdded: function(itemView){
            itemView.model.view = itemView;
            this.lastItemViewAdded = itemView;
        },

        attributes: function() {
            var dashboardModel = this.options && this.options.dashboardModel;
            var grid = "widget-grid-col" + (dashboardModel  && dashboardModel.get("max-width") || "0");
            let showFold = !Beef.Widget.isAsEmail() && dashboardModel && dashboardModel.get('showFoldLine') ? " show-fold-line" : ""
            var resizable = !Beef.Widget.isAsEmail() && !showFold && dashboardModel  && dashboardModel.get("resizable") ? "resizable-grid" : "";
            return { class: "widget-grid " + resizable + " " + grid + showFold }
        },

        initialize: function () {
            this.listenTo(this.options.dashboardModel, "change", this.onDashboardChange, this);
        },

        onDashboardChange: function() {
            this.$el.attr('class', this.attributes().class);
        }
    });

    this.View = Backbone.Marionette.Layout.extend({
        template: require("@/dashboards/widgets/WidgetList.handlebars"),

        regions: {
            list:   "> .widget-list"
        },

        initialize: function() {
            if (document.defaultView) {
                this.onResizeHandler = _.throttle(this.onResize.bind(this), 500);
                document.defaultView.addEventListener("resize", this.onResizeHandler);
            }

            this.listenTo(this.collection, "remove", this.onResize, this);
            this.listenTo(this.collection, "layout", this.onResize, this);

            if (this.options.dashboardModel) {
                this.listenTo(this.options.dashboardModel, "change:resizable", this.onResize, this);
            }
        },

        onRender: function() {
            this.list.show(new ListView({collection: this.collection, dashboardModel: this.options.dashboardModel}));
        },

        /**
         * Asks all child widgets to refresh their data and redraw if needed.
         */
        refresh: function() {
            if (this.list.currentView) {
                this.list.currentView.children.each(function(widgetView) {
                    widgetView.refresh();
                });
            }
        },

        onClose: function() {
            if (document.defaultView) {
                document.defaultView.removeEventListener("resize", this.onResizeHandler);
            }
        },

        onResize: function() {
            if (this.collection && this.collection.length) {
                this.collection.forEach(function(m) {
                    if (!m) return;

                    var view = m.view;
                    var container = m.view && m.view.container && m.view.container.currentView;
                    if (view && container) {
                        setTimeout(function() {
                            var width = view.$el.width();
                            var height = view.$el.height();
                            var containerWidth = view.container.currentView.$el.width();
                            var containerHeight = view.container.currentView.$el.height();

                            var widgetWidth = parseInt(getComputedStyle(view.$el[0]).getPropertyValue('--widget-width'));
                            var widgetHeight = parseInt(getComputedStyle(view.$el[0]).getPropertyValue('--widget-height'));

                            // The stretchable metrics are may now no longer be rendered correctly
                            if (width !== widgetWidth || height !== widgetHeight || containerWidth > width || containerHeight > height) {
                                container.render();
                            }
                        }.bind(this));
                    }
                })
            }
        },

        /**
         * Add a new widget and return its view.
         */
        addWidget: function(widgetDesc) {
            var id = this.collection.max(function(o) { return o.id } );
            id = id == null || id == -Infinity ? 1 : id.id + 1;

            // If you add or remove any of these items, you should
            // add or remove them in the UpdateWidgetViewImpl function in Widget.js as well
            var typeObject = Beef.WidgetRegistry.typeMap[widgetDesc.type];
            var data = Object.assign({
                id: id,
                type: widgetDesc.type,
                width: widgetDesc.width || (typeObject ? typeObject.width : 2),
                height: widgetDesc.height || (typeObject ? typeObject.height : 2),
                caption: widgetDesc.caption || (typeObject ? typeObject.name : "Widget " + id),
                filter: widgetDesc.filter
            }, widgetDesc);

            var dm = this.collection.owner.getDashboardModel();
            var cycleColoursDisabled = typeObject && typeObject.cycleColoursDisabled || widgetDesc.cycleColoursDisabled;
            if (!dm.get('colour-cycle-disabled') && !cycleColoursDisabled) {
                data['colour-index'] = (dm.get('colour-index') || 0) + this.collection.length;
            }

            if (typeof typeObject.onAdd === 'function') typeObject.onAdd(data, widgetDesc);

            var widget = new Beef.Dashboard.WidgetModel(data);
            this.collection.add(widget);

            // Notifications use the widget's caption. We need to give the
            // widget a bit of time to set the caption, and so this is in a timeout.
            setTimeout(() => {
                notifyUser({
                    message: widgetDesc.notification || `Added <strong>${widget.get('caption')}</strong> metric`,
                    isEscapedHtml: true,
                    undo: () => {
                        this.collection.remove(widget);
                        this.collection.owner.save();
                        notifyWithText("Metric removed", null,  "<i class='symbol-metric'></i>");
                    },
                    icon: "<i class='symbol-metric'></i>",
                    action: widgetDesc.notificationAction
                });
            }, 0);
            widget.save(null, {action: 'add'});
            return this.list.currentView.lastItemViewAdded;
        }
    });
});