import Vue from 'vue';

const socialMetrics = Vue.observable({
    title: "Social metrics",
    description: "See example mentions and measure basic social media stats.",
    expanded:
`See examples of mentions, measure engagement levels, and view measurements by social network.
 If you want to look at the basic social media metrics around your conversations, you can find them here.`,
    image: require("../assets/social.png"),
    metrics: [
        {
            id: "examplementions",
            title: "Example Mentions",
            description: "View and search example mentions using conversation filters",
            more: "When possible, we show one mention mention per theme underlying the selected mentions",
            image: require("../assets/social/mentions.png"),
            hero: true,
            widgetDesc: {
                type: "Conversation"
            },
            options: [
                {
                    key: "engagement",
                    title: "See most engaging",
                    tooltip: "Show mentions from your filter that were the most engaged with",
                    action() {
                        return {
                            id: "examplementions/engaging",
                            widgetDesc: {
                                type: "Conversation",
                                show: "top",
                                caption: "Most engaging mentions",
                                orderby: "engagement desc"
                            }
                        }
                    }
                },
                {
                    key: "recent",
                    title: "See most recent",
                    tooltip: "Show the mostly recently published mentions selected by your filter",
                    action() {
                        return {
                            id: "examplementions/recent",
                            widgetDesc: {
                                type: "Conversation",
                                show: "top",
                                caption: "Most recent mentions",
                                orderby: "published desc"
                            }
                        }
                    }
                }

            ]
        },
        {
            id: "engagement/lines",
            title: "Engagement",
            description: "The number of reshares and replies in the conversation",
            more: "Engagement is calculated by adding the total number of reshares and replies that a mentioned receives.",
            image: require("../assets/social/engagement.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                yAxis: "totalEngagement",
                width: 8,
                height: 4,
                geometry: "lines"
            },
            options: [
                {
                    key: "brand",
                    title: "Compare by brand",
                    tooltip: "Compares engagement by brand",
                    action: () => {
                        return {
                            id: "platform/net",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                yAxis: "totalEngagement",
                                compare: "brand",
                                width: 8,
                                height: 4,
                                geometry: "lines"
                            }
                        }
                    }

                }
            ]
        },
        {
            id: "reshares/columns",
            title: "Reshares",
            description: "The number of reshares that mentions in the filter received",
            image: require("../assets/social/reshares.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                yAxis: "totalReshareCount",
                width: 8,
                height: 4,
                geometry: "columns"
            },
            options: [
                {
                    key: "brand",
                    title: "Compare by brand",
                    tooltip: "Compares reshares by brand",
                    action: () => {
                        return {
                            id: "reshares/lines/brands",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                yAxis: "totalReshareCount",
                                compare: "brand",
                                width: 8,
                                height: 4,
                                geometry: "lines"
                            }
                        }
                    }

                }
            ]
        },
        {
            id: "platform/rows",
            title: "Social networks",
            description: "See the conversation volumes that you've received per social media platform",
            more: "This provides various stats, like volume, Net Sentiment, and engagement, grouped by the major social networks that we track",
            image: require("../assets/social/platforms.png"),
            keywords: ["platforms", "twitter", "facebook", "X", "instagram", "linkedin"],
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "socialNetwork",
                yAxis: "mentionPercent",
                width: 6,
                height: 4,
                geometry: "rows"
            },
            options: [
                {
                    key: "brand",
                    title: "Compare by brand",
                    tooltip: "Compares social media volumes by brand",
                    action: () => {
                        return {
                            id: "platform/net",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                yAxis: "mentionPercent",
                                compare: "brand",
                                width: 6,
                                height: 4,
                            }
                        }
                    }
                },
                {
                    key: "net",
                    title: "Show Net Sentiment",
                    tooltip: "Shows the overall Net Sentiment per social media platform",
                    action: () => {
                        return {
                            id: "platform/net",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                yAxis: "totalSentimentPercent",
                                width: 6,
                                height: 4,
                                filter: "visibility is public"
                            }
                        }
                    }
                },
                {
                    key: "engagement",
                    title: "Show engagement",
                    tooltip: "Shows the engagement per social media platform",
                    action: () => {
                        return {
                            id: "platform/engagement",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                yAxis: "totalEngagement",
                                width: 6,
                                height: 4,
                            }
                        }
                    }
                },
                {
                    key: "ots",
                    title: "Show Opportunities-To-See",
                    tooltip: "Shows the OTS per social media platform",
                    action: () => {
                        return {
                            id: "platform/ots",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "socialNetwork",
                                yAxis: "totalOTS",
                                width: 6,
                                height: 4,
                                noSi: false
                            }
                        }
                    }
                }
            ]
        },
        {
            id: "sites/columns",
            title: "Top sources",
            description: "Determine which web sites are generating the most conversation",
            image: require("../assets/social/sites.png"),
            keywords: ["site"],
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "site",
                yAxis: "mentionPercent",
                width: 6,
                height: 4
            },
            options: [
                {
                    key: "unique",
                    title: "Show count of unique sources",
                    action: () => {
                        return {
                            id: "sites/unique",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                yAxis: "siteCount",
                                width: 6,
                                height: 4,
                                notification: "Added metric showing the number of <strong>unique sources</strong>"
                            }
                        }
                    }
                }
            ]
        },
        {
            id: "ots/columns",
            title: "Opportunities-To-See",
            keywords: ["ots"],
            description: "Graph showing the number of people who may have seen the conversations you've filtered for",
            image: require("../assets/social/ots.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published" ,
                yAxis: "totalOTS",
                width: 6,
                height: 4,
                noSi: false,
                notification: "Added a metric showing <strong>Opportunities-To-See</strong>"
            }
        },
        {
            id: "profiles",
            title: "Online profile",
            description: "View page likes and follower counts for your social media profiles",
            additional: true,
            widgetDesc: {
                type: "OnlineProfile"
            }
        },
        {
            id: "ave/social/columns",
            title: "Social Advert Value Equivalent",
            description: "Graph of earned media value of your online conversations",
            keywords: ["ave"],
            additional: true,
            hide: true,
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                yAxis: "totalOnlineAVE",
                width: 6,
                height: 4,
                notification: "Added a legacy metric showing <strong>Online AVE</strong>"
            }
        },
        {
            id: "ave/trad/columns",
            title: "Traditional Advert Value Equivalent",
            description: "Graph of earned media value from traditional media sources",
            keywords: ["ave"],
            additional: true,
            hide: true,
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                yAxis: "totalAVE",
                width: 6,
                height: 4,
                notification: "Added a metric showing <strong>Traditional AVE</strong>"
            }
        },

    ]
});

export default socialMetrics;

