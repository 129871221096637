import {fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import {currentAccountCode} from "@/app/utils/Account";
import {grouseGet} from "@/data/Grouse";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

/**
 * Select one or more sites by typing the domain name. Auto-completes against recent sites in the account.
 * Fires a change event when the selection is changed. Also provides a model binder converter to display the
 * selected options in an element.
 */
Beef.module("SitePicker").addInitializer(function(startupOptions) {

    var fetchSites = function(view, cb) {
        var cache = view.cache = view.cache || {};
        if (cache.sites) {
            cb(cache.sites);
        } else {
            var code = view.model.getAncestorProperty('accountCode');
            fetchCachedBrands(code, function(brands) {
                var ids = brands.tree.map(function(b) { return b.id });

                if (!ids.length) return;

                var filter = "published inthelast week and brand isorchildof " + ids[0];

                let params = {
                    filter: filter,
                    groupBy: "site",
                    limit: 100
                }

                grouseGet("/v4/accounts/" + currentAccountCode() + "/mentions/count", params)
                    .then(res => {
                        cb(cache.sites = res.map(function (d) {
                            return d.site
                        }));
                    }).catch(error => {
                        console.warn("Unable to find sites for site picker", error)
                    });
            }, cache);
        }
    };

    var items = function(q, view, callback) {
        fetchSites(view, callback);
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "site-picker auto-complete-picker" },
        items: items,
        mustSelectFromItems: false
    });

    this.converter = createTagConverter({
        items: function(code) { return code },
        placeholder: "Type part of the site domain"
    });

    /**
     * Attach a tag picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View);
    };

});
