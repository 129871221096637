<template>
    <div class="glossary-tab deq-reset" tabindex="-1">
        <div class="glossary__search">
            <search-input placeholder="Search glossary terms" autofocus v-model="searchTerm" @keydown.esc.stop="escapeHandler()" ref="search"/>

            <div v-if="matchingEntries.length" class="glossary__search-matches">
                Matching glossary entries
                <ul>
                    <li v-for="entry of matchingEntries"
                        :key="entry.id">
                        <tooltip-with-glossary :glossary="entry.id">
                            <a :href="`#${entry.id}`">{{ entry.glossaryTerm }}</a>
                        </tooltip-with-glossary>
                    </li>
                </ul>
            </div>
            <div v-else-if="searchTerm" class="glossary__search-matches">
                <no-topics-message>
                    No glossary entries match your search
                </no-topics-message>
            </div>
        </div>

        <div class="all-tips-dialog__glossary dark-scrollbars dark-scrollbars--visible"
             tabindex="-1"
             :data-searching="!!searchTerm">
            <div v-for="entry of visibleEntries"
                 :key="entry.id"
                 :data-matches="match(entry)"
                 :id="entry.id"
                 :tabindex="match(entry) ? 0 : -1"
                 class="all-tips-dialog__glossary-entry">
                <h2>{{entry.glossaryTerm}}</h2>
                <div v-html="entry.description" class="glossary-entry__description"></div>
                <div v-if="entry.seeAlso" class="glossary-entry__also">
                    <h3>See also</h3>
                    <ul>
                        <li v-for="link of entry.seeAlso"
                            :key="link">
                            <tooltip-with-glossary :glossary="link">
                                <a :href="'#' + link" @click="entryClicked($event, link)">
                                    <glossary-entry-name :glossary-id="link"/>
                                </a>
                            </tooltip-with-glossary>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getAllGlossaryEntries, getGlossaryEntriesFor, getGlossaryEntry} from "@/app/help/tips/tip-utils";
import SearchInput from "@/components/inputs/SearchInput";
import TooltipWithGlossary from "@/components/tooltip/TooltipWithGlossary";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import Vue from "vue";
import GlossaryEntryName from "@/app/help/tips/GlossaryEntryName";

export default {
    components: {GlossaryEntryName, NoTopicsMessage, TooltipWithGlossary, SearchInput },

    props: {
        section: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            entries: [],
            searchTerm: ""
        }
    },

    computed: {
        cleanSearchTerm() {
            return this.searchTerm.toLowerCase().trim();
        },

        matchingEntries() {
            if (!this.searchTerm) return [];
            return this.entries.filter(e => this.match(e));
        },

        visibleEntries() {
            // if (!this.searchTerm) return this.entries;
            // return this.entries.filter(e => this.match(e));
            return this.entries
        }
    },

    async created() {
        if (this.section) {
            this.entries = await getGlossaryEntriesFor(this.section);
        } else {
            this.entries = await getAllGlossaryEntries();
        }
    },

    mounted() {
        document.addEventListener('keydown', this.escapeHandler, { capture: true});
    },

    activated() {
        document.addEventListener('keydown', this.escapeHandler, { capture: true});
    },

    deactivated() {
        document.removeEventListener('keydown', this.escapeHandler, true);
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.escapeHandler, true);
    },

    methods: {
        /**
         * @param {Tip} entry
         */
        match(entry) {
            if (!this.searchTerm) return false;
            const clean = s => s.toLowerCase().trim();

            return clean(entry.glossaryTerm).includes(this.cleanSearchTerm) ||
                clean(entry.description).includes(this.searchTerm);
        },

        escapeHandler(event) {
            if (event && event.key !== "Escape") return;

            if (this.searchTerm) {
                event?.stopPropagation();
                event?.preventDefault();
                this.searchTerm = "";

                if (document.activeElement?.tagName !== 'INPUT') {
                    document.activeElement.blur(); // if something has keyboard focus, blur it.
                }
                return;
            } else if (!event) {
                this.$refs.search?.blur();
            }
        },

        async entryClicked(event, id) {
            if (this.entries.find(d => d.id === id)) return;

            // We may not have loaded all entries (see the sections property),
            // and the user is asking to see one that we haven't loaded. Let's see if
            // we can load it into the DOM and scroll to it.
            event.preventDefault();
            const entry = await getGlossaryEntry(id);
            if (entry) {
                this.entries.push(entry);
                this.$nextTick(() => {
                    document.getElementById(id)?.scrollIntoView({behavior: 'smooth'});
                })
            }
        }
    }
}
</script>


<style scoped lang="sass">

.glossary-tab
    display: contents
    ul
        display: flex
        gap: 5px 2rem
        flex-wrap: wrap
    li
        margin: 0
        padding: 0

.glossary__search
    padding-bottom: 10px

.glossary__search-matches
    padding-top: 2ch
    min-height: 12ch

.all-tips-dialog__glossary
    overflow-y: auto
    box-sizing: border-box
    padding-block: 10px

    @media (prefers-reduced-motion: no-preference)
        scroll-behavior: smooth

.all-tips-dialog__glossary-entry
    transition-property: color, filter, margin-top
    transition-duration: 250ms
    margin: 0
    padding: 10px 20px
    min-height: 5ch
    border-block: thin solid transparent

    h2, h3
        margin: 0
        padding: 0
        line-height: 1.5em
        color: var(--heading-colour-text-dark)

    h3
        font-size: 1em
        margin-top: 5px

    div
        padding-left: 2ch

    ::v-deep p
        margin-top: 1ch

.all-tips-dialog__glossary-entry:nth-child(even)
    background: var(--body-background-colour-lighter)
    border-radius: 3px

.all-tips-dialog__glossary-entry + .all-tips-dialog__glossary-entry
    border-top: var(--border-separation)

[data-searching] .all-tips-dialog__glossary-entry[data-matches]
    background: var(--section-background-colour)
    border-block: var(--focus-border)

[data-searching] .all-tips-dialog__glossary-entry[data-matches]:not(:first-of-type)
    margin-top: 2ch


[data-searching] .all-tips-dialog__glossary-entry:not([data-matches])
    background: revert
    filter: grayscale(0.8)
    color: var(--be-colour-muted-text-dark)
    --heading-colour-text-dark: var(--be-colour-muted-text-dark)
    ::v-deep strong
        color: var(--be-colour-muted-text-dark)
    a:not(:hover)
        color: var(--be-colour-muted-text-dark)


.all-tips-dialog__glossary-entry:focus-visible
    border: var(--focus-border)


</style>