var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>Edit Crowd Quota Percentage</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"row-fluid\">\n        <div class=\"control-group\">\n            <label style=\"margin-bottom: 5px\">Percentage of crowd quota reserved for "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":93}}}) : helper)))
    + "</label>\n            <div class=\"controls\">\n                <input type=\"text\" name=\"crowdQuotaPercentage\" class=\"span3\"/>\n                <div class=\"help-block\">Enter 0 to not reserve any quota</div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"dialog-button-bar\">\n        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n</div>";
},"useData":true});