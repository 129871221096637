var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":21},"end":{"line":13,"column":27}}}) : helper)))
    + "\">\n            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":24}}}) : helper)))
    + "</td>\n            <td class=\"account-code\"><a href=\"/"
    + alias4(((helper = (helper = lookupProperty(helpers,"acCode") || (depth0 != null ? lookupProperty(depth0,"acCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acCode","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":57}}}) : helper)))
    + "/setup/brands/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":71},"end":{"line":15,"column":77}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"acCode") || (depth0 != null ? lookupProperty(depth0,"acCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acCode","hash":{},"data":data,"loc":{"start":{"line":15,"column":79},"end":{"line":15,"column":89}}}) : helper)))
    + "</a></td>\n            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":31}}}) : helper)))
    + "</td>\n            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"tier") || (depth0 != null ? lookupProperty(depth0,"tier") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tier","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":24}}}) : helper)))
    + "</td>\n            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"crowdSamplePercentage") || (depth0 != null ? lookupProperty(depth0,"crowdSamplePercentage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"crowdSamplePercentage","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":41}}}) : helper)))
    + "</td>\n            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"crowdTopicPercentage") || (depth0 != null ? lookupProperty(depth0,"crowdTopicPercentage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"crowdTopicPercentage","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":40}}}) : helper)))
    + "</td>\n        </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <tr>\n            <td colspan=\"6\">No brands found</td>\n        </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-condensed table-bordered table-hover\">\n    <thead>\n    <tr>\n        <td>Brand</td>\n        <td colspan=\"2\">Account</td>\n        <td>Tier</td>\n        <td title=\"Crowd sampling rate\">Sample%</td>\n        <td title=\"Percentage of emotive mentions sent for topics\">Topic%</td>\n    </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"brands") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"useData":true});