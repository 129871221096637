<template>
    <div class="be-mention-panel-toolbar">
        <english-filter class="english-filter" :filter="filter"/>
        <section class="be-mention-panel-toolbar__bar">
            <be-button @click="toggleFilter">Choose mentions</be-button>
            <span v-if="numMentions"> <deq-number :number="numMentions"/> {{ formatPlural(numMentions, 'mention')}}</span>
            <be-button link>Select all</be-button>
            <be-button link>Select none</be-button>
        </section>

        <div class="be-mention-panel-toolbar__editor-background short-animated fadeIn"
             v-if="filterOpen">
        </div>

        <div class="be-mention-panel-toolbar__editor short-animated fadeIn" v-if="filterOpen">
            <filter-editor v-model="tempFilter"/>
            <be-button @click="updateFilter">
                Close
            </be-button>
        </div>


    </div>
</template>


<script>

import VuexStore from "@/store/vuex/VuexStore";
import EnglishFilter from "@/components/formatters/EnglishFilter";
import {mapActions, mapGetters, mapState} from "vuex";
import BeButton from "@/components/buttons/BeButton";
import {count} from "@/data/Grouse";
import DeqNumber from "@/components/formatters/DeqNumber";
import FilterEditor from "@/components/inputs/FilterEditor";
import {formatPlural} from "@/app/utils/Format";

export default {
    components: {DeqNumber, FilterEditor, BeButton, EnglishFilter},
    store: VuexStore,

    data() {
        return {
            numMentions: null,
            filterOpen: false,
            tempFilter: null
        }
    },

    computed: {
        ...mapState({
            filter: state => state.mentionPanel.filter
        }),

        ...mapGetters('mentionPanel', ['brands']),
    },

    watch: {
        async filter() {
            await this.onFilterChanged();
        }
    },

    async mounted() {
        await this.onFilterChanged()
    },

    methods: {
        formatPlural,
        ...mapActions('mentionPanel', ['setFilter']),

        toggleFilter() {
            this.filterOpen = !this.filterOpen;
        },

        updateFilter() {
            this.filterOpen = false;
            if (this.tempFilter === this.filter) return;
            this.setFilter(this.tempFilter);
        },

        async onFilterChanged() {
            this.tempFilter = this.filter;
            if (this.filter) {
                try {
                    this.numMentions = null;
                    const result = await count(this.filter); // todo. Should be cancellable.
                    this.numMentions = result?.mentionCount ?? null;
                } catch (e) {
                    console.error(e);
                    this.numMentions = null;
                }
            }
        }
    }
}

</script>

<style scoped lang="sass">

.be-mention-panel-toolbar
    position: relative

.be-mention-panel-toolbar__bar
    display: flex

.be-mention-panel-toolbar__editor-background
    position: fixed
    top: 0
    left: 0
    z-index: 1001
    width: 100vw
    height: 100vh
    background: rgb(0 0 0 / 0.5)

.be-mention-panel-toolbar__editor
    width: var(--filter-width)
    position: absolute
    z-index: 1001
    padding: 20px
    box-sizing: border-box
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.8), -1px -1px 4px rgba(0, 0, 0, 0.4)
    border-radius: 3px
    background: var(--body-background-colour)



</style>