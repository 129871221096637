var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onlyOne") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":103}}})) != null ? stack1 : "")
    + "</p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Set gender to";
},"4":function(container,depth0,helpers,partials,data) {
    return "Include conversation from authors with these genders:";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noIntroduction") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"control-group\">\n    <div class=\"controls\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" name=\"FEMALE\">Female\n        </label>\n    </div>\n</div>\n<div class=\"control-group\">\n    <div class=\"controls\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" name=\"MALE\">Male\n        </label>\n    </div>\n</div>\n<div class=\"control-group\">\n    <div class=\"controls\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" name=\"OTHER\">Other\n        </label>\n    </div>\n</div>\n<div class=\"control-group\">\n    <div class=\"controls\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" name=\"UNKNOWN\">Unknown\n        </label>\n    </div>\n</div>\n";
},"useData":true});