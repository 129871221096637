import CompTable from "./widgets/comptable/CompTable.vue"
import TrendingWidget from "@/dashboards/widgets/trending/TrendingWidget";
import ConductWidget from "@/dashboards/widgets/conduct/ConductWidget";
import BlockSpace from "@/dashboards/widgets/spaces/BlockSpace";
import CommentaryPlusWidget from "@/dashboards/widgets/commentaryplus/CommentaryPlusWidget.vue";

/**
 * Enumerates available widgets by looking for sub-modules of the Beef.Widget module and extracting their type
 * objects. Registers widgets implemented as Vue.js components.
 */
Beef.module("WidgetRegistry").addInitializer(function(startupOptions) {
    this.typeMap = { }

    for (var moduleName in Beef.Widget.submodules) {
        var module = Beef.Widget[moduleName];
        var type = module.type;
        if (type) {
            this.typeMap[moduleName] = type;
            type.type = moduleName;
            if (!type.View) type.View = type.vueComponent ? Beef.VuejsWidget.View : module.View;
            type.SettingsView = module.SettingsView;
            type.description = type.description || "Empty description";
        }
    }

    // dont inline this into the vueTypes.forEach(..)! .. for some reason the Vue templates dont get compiled
    let vueTypes = [CompTable, TrendingWidget, ConductWidget, BlockSpace, CommentaryPlusWidget].map(c => {
        let t = c.widgetType
        t.vueComponent = c
        t.name = c.name
        return t
    })

    vueTypes.forEach(type => {
        this.typeMap[type.name] = type
        type.type = type.name
        type.View = Beef.VuejsWidget.View
        type.description = type.description || "Empty description"
    });
});

