/**
 * Describe how the mention has been verified or not.
 */
var helper = function(options) {
    var msg;
    // gravyMentionId might be set but someone has modified the mention after it was rated by the crowd so
    // these cases also check that the last modification was by a system user
    if (this.sentimentVerified) {
        msg = "Verified by " + getUserDescription(this.lastUpdatedBy, "DataEQ");
    } else if (this.relevancyVerified) {
        if (this.relevancy == "IRRELEVANT") {
            msg = "Deleted by " + getUserDescription(this.relevancyLastUpdatedBy, "DataEQ");
        } else {
            msg = "Sorted by " + getUserDescription(this.relevancyLastUpdatedBy, "DataEQ");
        }
    } else if (this.relevancy == "IRRELEVANT") {
        if (this.discardedByRule) {
            if (this.discardedByRule == -1) {
                msg = "Deleted by brand country &amp; language filters";
            } else {
                msg = "Deleted by rule " + toRuleLink(this._accountCode, this.discardedByRule);
            }
        } else {
            msg = "Deleted by " + getUserDescription(this.relevancyLastUpdatedBy, "DataEQ");
        }
    } else if (this.relevancy == "UNSORTED") {
        msg = "Unsorted"
    } else if (this.relevancy == "RELEVANT") {
        msg = "Sorted by " + getUserDescription(this.relevancyLastUpdatedBy, "DataEQ");
    } else {
        // we shouldn't get here
        msg = "Relevancy " + this.relevancy;
    }
    return new Handlebars.SafeString(msg);
}

var ruleNameCache = null;

var toRuleLink = function(accountCode, ruleId) {
    var name = ruleNameCache && ruleNameCache[ruleId];
    var link = "<a class='rule' href='/" + accountCode +"/setup/rules/" + ruleId + "' data-id='" + ruleId + "'>" +
        (name ? name : ruleId) + "</a>";
    if (!name && !ruleNameCache) {
        ruleNameCache = {};     // make sure only one call is active as a time
        Beef.Sync.mashGET("accounts/" + accountCode + "/rules", {includeDeleted: true}, function(data){
            ruleNameCache = {};
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                ruleNameCache[r.id] = r.name;
                $('a.rule[data-id="' + r.id + '"]').text(r.name);
            }
        });
    }
    return link;
};

/**
 * Call this when screens using renderMentionVerification load to make sure the rule cache is fresh.
 */
helper.clearRuleNameCache = function() {
    ruleNameCache = null;
};

var isSystemUser = function(user) {
    return !user || user.system;
};

var getUserDescription = function(user, def) {
    if (!user || user.system) return def;
    if (user.admin && !Beef.user.get('admin')) return "DataEQ staff";
    if (user.name) return user.name;
    var s = user.firstName;
    if (user.lastName) s = (s ? s + ' ' : '') + user.lastName;
    return s ? s : user.id;
};

export default helper
