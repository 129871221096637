<template>
    <slotted-tag v-if="user.ops"
                 no-close
                 tooltip="Only Ops users can see this content">
        <i class="symbol-info"></i> Ops only
    </slotted-tag>
</template>

<script>

import SlottedTag from "@/components/tags/SlottedTag";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

/** A convenient tag to show if something is visible to OPS users only. */
export default {
    store: VuexStore,
    components: {SlottedTag},
    computed: {
        ...mapState(['user'])
    }

}

</script>

<style scoped lang="sass">
    i.symbol-info
        color: white
</style>