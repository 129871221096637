/**
 * Select one or more currencies by typing a name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("CurrencyPicker").addInitializer(function(startupOptions) {

    /**
     * If you change this list, update the list on Beef server side.
     */
    this.items = {
        "ALL": "Albanian Lek",
        "DZD": "Algerian Dinar",
        "ARS": "Argentine Peso",
        "AWG": "Aruba Florin",
        "AUD": "Australian Dollar",
        "BSD": "Bahamian Dollar",
        "BHD": "Bahraini Dinar",
        "BDT": "Bangladesh Taka",
        "BBD": "Barbados Dollar",
        "BYR": "Belarus Ruble",
        "BZD": "Belize Dollar",
        "BMD": "Bermuda Dollar",
        "BTN": "Bhutan Ngultrum",
        "BOB": "Bolivian Boliviano",
        "BWP": "Botswana Pula",
        "BRL": "Brazilian Real",
        "GBP": "British Pound",
        "BND": "Brunei Dollar",
        "BGN": "Bulgarian Lev",
        "BIF": "Burundi Franc",
        "KHR": "Cambodia Riel",
        "CAD": "Canadian Dollar",
        "CVE": "Cape Verde Escudo",
        "XOF": "CFA Franc (BCEAO)",
        "XAF": "CFA Franc (BEAC)",
        "CLP": "Chilean Peso",
        "CNY": "Chinese Yuan",
        "COP": "Colombian Peso",
        "KMF": "Comoros Franc",
        "CRC": "Costa Rica Colon",
        "HRK": "Croatian Kuna",
        "CUP": "Cuban Peso",
        "CZK": "Czech Koruna",
        "DKK": "Danish Krone",
        "DJF": "Dijibouti Franc",
        "DOP": "Dominican Peso",
        "XCD": "East Caribbean Dollar",
        "EGP": "Egyptian Pound",
        "SVC": "El Salvador Colon",
        "ETB": "Ethiopian Birr",
        "EUR": "Euro",
        "FKP": "Falkland Islands Pound",
        "FJD": "Fiji Dollar",
        "GMD": "Gambian Dalasi",
        "GIP": "Gibraltar Pound",
        "XAU": "Gold Ounces",
        "GTQ": "Guatemala Quetzal",
        "GNF": "Guinea Franc",
        "GYD": "Guyana Dollar",
        "HTG": "Haiti Gourde",
        "HNL": "Honduras Lempira",
        "HKD": "Hong Kong Dollar",
        "HUF": "Hungarian Forint",
        "ISK": "Iceland Krona",
        "INR": "Indian Rupee",
        "IDR": "Indonesian Rupiah",
        "IRR": "Iran Rial",
        "IQD": "Iraqi Dinar",
        "ILS": "Israeli Shekel",
        "JMD": "Jamaican Dollar",
        "JPY": "Japanese Yen",
        "JOD": "Jordanian Dinar",
        "KZT": "Kazakhstan Tenge",
        "KES": "Kenyan Shilling",
        "KRW": "South Korean Won",
        "KWD": "Kuwaiti Dinar",
        "LAK": "Lao Kip",
        "LVL": "Latvian Lat",
        "LBP": "Lebanese Pound",
        "LSL": "Lesotho Loti",
        "LRD": "Liberian Dollar",
        "LYD": "Libyan Dinar",
        "LTL": "Lithuanian Lita",
        "MOP": "Macau Pataca",
        "MKD": "Macedonian Denar",
        "MWK": "Malawi Kwacha",
        "MYR": "Malaysian Ringgit",
        "MVR": "Maldives Rufiyaa",
        "MRO": "Mauritania Ougulya",
        "MUR": "Mauritius Rupee",
        "MXN": "Mexican Peso",
        "MDL": "Moldovan Leu",
        "MNT": "Mongolian Tugrik",
        "MAD": "Moroccan Dirham",
        "MMK": "Myanmar Kyat",
        "NAD": "Namibian Dollar",
        "NPR": "Nepalese Rupee",
        "ANG": "Neth Antilles Guilder",
        "NZD": "New Zealand Dollar",
        "NIO": "Nicaragua Cordoba",
        "NGN": "Nigerian Naira",
        "KPW": "North Korean Won",
        "NOK": "Norwegian Krone",
        "OMR": "Omani Rial",
        "XPF": "Pacific Franc",
        "PKR": "Pakistani Rupee",
        "PAB": "Panama Balboa",
        "PGK": "Papua New Guinea Kina",
        "PYG": "Paraguayan Guarani",
        "PEN": "Peruvian Nuevo Sol",
        "PHP": "Philippine Peso",
        "PLN": "Polish Zloty",
        "QAR": "Qatar Rial",
        "RON": "Romanian New Leu",
        "RUB": "Russian Rouble",
        "RWF": "Rwanda Franc",
        "WST": "Samoa Tala",
        "STD": "Sao Tome Dobra",
        "SAR": "Saudi Arabian Riyal",
        "SCR": "Seychelles Rupee",
        "SLL": "Sierra Leone Leone",
        "SGD": "Singapore Dollar",
        "SBD": "Solomon Islands Dollar",
        "SOS": "Somali Shilling",
        "ZAR": "South African Rand",
        "LKR": "Sri Lanka Rupee",
        "SHP": "St Helena Pound",
        "SDG": "Sudanese Pound",
        "SZL": "Swaziland Lilageni",
        "SEK": "Swedish Krona",
        "CHF": "Swiss Franc",
        "SYP": "Syrian Pound",
        "TWD": "Taiwan Dollar",
        "TZS": "Tanzanian Shilling",
        "THB": "Thai Baht",
        "TOP": "Tonga Pa'ang",
        "TTD": "Trinidad & Tobago Dollar",
        "TND": "Tunisian Dinar",
        "TRY": "Turkish Lira",
        "AED": "UAE Dirham",
        "UGX": "Ugandan Shilling",
        "UAH": "Ukraine Hryvnia",
        "USD": "United States Dollar",
        "UYU": "Uruguayan New Peso",
        "VUV": "Vanuatu Vatu",
        "VEF": "Venezuelan Bolivar Fuerte",
        "VND": "Vietnam [removed]",
        "YER": "Yemen Riyal",
        "ZMK": "Zambian Kwacha"
    };

    this.getCurrency = function(code) {
        return Beef.CurrencyPicker.items[code] || code;
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "currency-picker auto-complete-picker" },
        items: this.items
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({items: this.items, placeholder: placeholder});
    };

    this.converter = this.createConverter("Type the currency name or code here");

    /**
     * Attach a currency picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
