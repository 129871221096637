<template>
    <dialog-box modal class="report-author-dialog" title="Report author" @close="close()" width="550px">
        <transition :name="curTransitionType">
            <div key="1" v-if="!previewMentions">
                <p>Report this author as a potential problem-author. DataEQ Support will be notified once your report has been submitted.
                    If this author is confirmed as a problem-author, their future & historic mentions will be marked as irrelevant and tagged with the appropriate mention subtype segment.</p>
                <div class="report-author-dialog__author-details">

                    <online-profile class="report-author-dialog__author-profile" :profile="author"></online-profile>
                </div>

                <div class="report-author-dialog__context">
                    <div class="report-author-container">
                        <h5>What are you reporting this author for?</h5>
                        <drop-down-input class="drop-down" :options="authorTypeOptions" v-model="selectedAuthorType"></drop-down-input>
                        <tooltip-component>
                            <i class="symbol-info"></i>
                            <template #tooltip>
                                <span>{{ authorTypeTooltip }}</span>
                            </template>
                        </tooltip-component>
                    </div>

                    <h5>Filter</h5>
                    <p>Please provide a filter to example mentions of this author that are of concern.</p>

                    <div class="btn-group">
                        <be-button :active="useExistingFilter" @click="useExistingFilter = true">Use current mentions panel filter</be-button>
                        <be-button :active="!useExistingFilter" @click="useExistingFilter = false">Specify custom filter</be-button>
                    </div>
                    <div v-if="!useExistingFilter">
                        <div v-if="displayFilter" class="filter editable">
                            <english-filter :filter="displayFilter" @click="toggleFilterEditor()"></english-filter>
                            <i style="visibility: hidden" class="symbol-edit"></i>
                        </div>
                        <be-button v-else link @click="toggleFilterEditor()"><i class="symbol-edit"></i> Edit filter</be-button>
                    </div>
                    <english-filter v-else class="filter" :filter="displayFilter"></english-filter>


                    <h5>Comment</h5>
                    <p>Please provide any additional information that can assist us with the investigation.</p>
                    <textarea v-model="comment" class="comment-box"></textarea>

                    <p class="error-text">{{ errorText }}</p>
                </div>
            </div>
            <div key="2" v-else>
                <preview-mentions class="report-author-dialog__mention-preview" :filter="displayFilter"></preview-mentions>
            </div>
        </transition>


        <template #buttons>
            <div class="report-author-dialog__btn-bar" v-if="!previewMentions">
                <be-button style="margin-left: auto" link :disabled="saving" @click="close()">Cancel</be-button>
                <be-button :disabled="!displayFilter || saving" @click="toggleMentionPreview()">Preview filter mentions</be-button>
                <be-button primary :disabled="disableReport" @click="reportAuthor()">Report</be-button>
            </div>
            <div class="report-author-dialog__btn-bar" v-else>
                <be-button style="margin-left: auto" link @click="toggleMentionPreview()">Back</be-button>
            </div>
        </template>

        <dialog-box v-if="editFilter"
                    @close="toggleFilterEditor()"
                    overlay
                    width="850px"
                    title="Author mentions that are of concern">
            <div style="overflow-y: auto; overflow-x: hidden">
                <filter-editor v-model="tempCustomFilter"/>
            </div>
            <template #buttons>
                <section style="display: flex">
                    <be-button style="margin-left: auto" link @click="toggleFilterEditor()">Cancel</be-button>
                    <be-button primary @click="saveCustomFilter()">Update</be-button>
                </section>
            </template>
        </dialog-box>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import {Profile} from "@/app/utils/types";
import {profileTypes, socialNetworkToProfileType} from "@/setup/profiles/ProfileUtils";
import BeButton from "@/components/buttons/BeButton";
import EnglishFilter from "@/components/formatters/EnglishFilter";
import FilterEditor from "@/components/inputs/FilterEditor";
import PreviewMentions from "@/components/PreviewMentions";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import {spam} from "@/store/Services";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import DropDownInput from "@/components/inputs/DropDownInput";
import TooltipComponent from "@/components/tooltip/TooltipComponent";

export default {
    name: "ReportAuthorDialog",
    components: {
        TooltipComponent,
        DropDownInput, OnlineProfile, PreviewMentions, FilterEditor, EnglishFilter, BeButton, DialogBox},
    store: VuexStore,
    props: {
        authorHandleId: {
            type: String,
            required: true
        },
        authorHandle: {
            type: String,
            required: true
        },
        authorId: {
            type: String,
            required: true
        },
        authorName: {
            type: String,
            required: true
        },
        authorPictureLink: {
            type: String,
            required: true
        },
        socialNetwork: {
            type: Object,
            required: true
        },
        originalFilter: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            author: new Profile(),
            useExistingFilter: true,
            customFilter: "",
            tempCustomFilter: "",
            editFilter: false,
            comment: "",
            previewMentions: false,
            authorTypeOptions: [],
            selectedAuthorType: "SPAMMER",
            transitionType: {
                NEXT: "slide-next",
                PREV: "slide-previous"
            },
            curTransitionType: "slide-next",
            saving: false
        }
    },

    computed: {
        ...mapState(['account', 'user']),

        displayFilter() {
            if (this.useExistingFilter) return this.originalFilter;

            return this.customFilter;
        },

        disableReport() {
            return this.saving || !this.comment || (!this.useExistingFilter && !this.customFilter);
        },

        getReportedAsType() {
            return this.selectedAuthorType === "SPAMMER" ? "spammer" : "retweeter";
        },

        errorText() {
            if (!this.comment) return "Comment is required";
            if (!this.useExistingFilter && !this.customFilter) return "Please specify a filter";

            return "";
        },

        authorTypeTooltip() {
            return this.selectedAuthorType === "SPAMMER" ? "Author posts a substantial amount of spam mentions."
                : "Author constantly retweets a substantial amount of mentions."
        },

        isTwitterProfile() {
            return this.author.type === profileTypes.twitter;
        }
    },

    created() {
        this.author.name = this.authorName;
        this.author.handle = this.authorHandle;
        this.author.handleId = this.authorHandleId;
        this.author.pictureLink = this.authorPictureLink;
        this.author.type = socialNetworkToProfileType(this.socialNetwork.id);

        this.authorTypeOptions = [
            { id: "SPAMMER", name: "Spamming" },
            { id: "RETWEETER", name: "Retweeting", disabled: !this.isTwitterProfile } // only twitter profiles can be reported as retweeters
        ];
    },

    methods: {
        toggleFilterEditor() {
            if (this.saving) return;

            this.tempCustomFilter = this.customFilter;
            this.editFilter = !this.editFilter;
        },

        saveCustomFilter() {
            this.customFilter = this.tempCustomFilter;
            this.editFilter = false;
        },

        close() {
            this.$emit('close');
        },

        toggleMentionPreview() {
            this.curTransitionType = this.curTransitionType === this.transitionType.NEXT ? this.transitionType.PREV : this.transitionType.NEXT;
            this.previewMentions = !this.previewMentions;
        },

        async reportAuthor() {
            try {
                this.saving = true;

                if (!this.displayFilter) showErrorDialog("Please specify a filter", "Unable to report author");

                let payload = {
                    authorId: this.authorId,
                    handle: this.authorHandle,
                    handleId: this.authorHandleId,
                    name: this.authorName,
                    comment: this.comment,
                    filter: this.displayFilter,
                    socialNetwork: this.socialNetwork.id,
                    pictureLink: this.authorPictureLink,
                    accountCode: this.account.code,
                    reportedByName: `${this.user.firstName} ${this.user.lastName}`,
                    reportedByEmail: this.user.email,
                    type: this.selectedAuthorType,
                    doesNotExpire: true
                }

                await spam.post("/rest/authors", payload);

                notifyUser({
                    message: `This author has successfully been reported as a ${this.getReportedAsType}. You will be notified via email if your report has been rejected or accepted.`,
                    longDelay: true
                });
                this.close();
            } catch (e) {
                if (e.response.status === 409) {
                    notifyUserOfError(`This author has already been reported as a problem author.`);
                    this.close();
                }
            } finally {
                this.saving = false;
            }
        }
    }
}
</script>

<style scoped lang="sass">


.report-author-dialog

    p
        color: var(--be-colour-muted-text-light)

    h5
        margin: 0

    .error-text
        font-style: italic

    &__author-details
        margin: 20px 0

    &::v-deep .online-profile

        img
            margin-left: 0
            height: 50px
            width:  50px

    &__context

        h5
            margin-top: 15px

        .report-author-container
            display: flex
            column-gap: 20px
            align-items: center

            .drop-down
                width: 100px

            h5
                margin-top: 0



        .btn-group
            margin-bottom: 10px
            display: flex
            .be-button:not(:last-of-type) ::v-deep .btn
                border-top-right-radius: 0
                border-bottom-right-radius: 0
            .be-button + .be-button
                & ::v-deep .btn
                    border-top-left-radius: 0
                    border-bottom-left-radius: 0

        .filter
            color: var(--be-colour-text-dark)

            &.editable:hover
                color: #aee15d
                cursor: pointer

                i
                    visibility: visible !important

        .comment-box
            width: 95%
            height: 100px

    &__mention-preview
        height: 600px

    &__btn-bar
        display: flex
        column-gap: 5px

.slide-next-enter-active,
.slide-previous-enter-active
    transition: all .25s ease

.slide-next-leave-active,
.slide-previous-leave-active
    transition: none
    opacity: 0

.slide-next-enter
    transform: translateX(30px)
    opacity: 0

.slide-previous-enter
    transform: translateX(-30px)
    opacity: 0


</style>