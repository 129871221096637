Beef.module("EditBrandTreeJson").addInitializer(function(startupOptions) {

    // these are fields that cannot be edited via JSON so leave these out
    var SKIPPED_FIELDS = {};
    ['volume', 'created', 'rateSentiment', "crowdQuotaPercentage", "crowdQuota", "tier", "volumeLimit",
        "crowdSamplePercentage", "crowdTopicPercentage", "topicTrees", "segmentListIds", "activeSegmentListIds",
        "schema", "importedByAccounts", "supportProfileIds", "tagItems", "cleanName", "sentiment",
        "relevancyModel"]
        .forEach(function(d) { SKIPPED_FIELDS[d] = true });

    /**
     * Callback is invoked if anything is changed.
     */
    this.createView = function(accountCode, brands, callback) {
        var tree = Beef.Sync.cloneModel(brands, function(name) {
            return Beef.Sync.defaultAttrFilter(name) && !SKIPPED_FIELDS[name]
        });
        var text = JSON.stringify(tree, undefined, 2);
        var m = new Backbone.Model({state: EDIT, text: text});
        return new View({model: m, accountCode: accountCode, callback: callback});
    };

    // the dialog transitions between these states ('state' in its model)
    var EDIT = 0, CHECKING = 1, CONFIRM = 2, SAVING = 3;

    var spinner = "<span class='spinner'/>";

    var View = Beef.BoundItemView.extend({

        template: require("@/setup/brands/EditBrandTreeJson.handlebars"),

        attributes: { class: "edit-brand-tree-json dialog" },

        events: {
            "click .dialog-title .close":   "close",
            "click .cancel":                "close",
            "click .ok":                    "ok",
            "click .prev":                  "prev"
        },

        modelEvents: {
            "change": "modelChanged"
        },

        onRender: function() {
            var that = this;
            this.updateControls();
            setTimeout(function() { that.$('textarea')[0].focus(); });
        },

        modelChanged: function() {
            var c = this.model.changedAttributes();
            if (c.results) {
                this.$('.results').html(require('@/setup/brands/EditBrandTreeJsonResults.handlebars')({
                    results: this.model.get('results')
                }));
            }
            this.updateControls();
        },

        getState: function() {
            return this.model.get('state');
        },

        updateControls: function() {
            var state = this.getState();
            this.$('.ok').html(state == CONFIRM ? "Ok" : (state == CHECKING || state == SAVING) ? spinner : "Next");
            this.$('.ok').toggleClass("disabled", state == CHECKING || state == SAVING);
            this.$('.prev').toggleClass("disabled", state == EDIT || state == SAVING);
            this.$('.edit-area').attr('readonly', state != EDIT ? 'readonly' : null);
            this.$('.edit-area').toggle(state == EDIT || state == CHECKING);
            this.$('.results-area').toggle(state == CONFIRM || state == SAVING);
        },

        close: function() {
            if (!this.$el[0].parentElement) return; // already closed
            Beef.BoundItemView.prototype.close.call(this);
        },

        ok: function(ev) {
            ev.preventDefault();
            var that = this;
            var state = this.getState();
            var text = this.model.get('text');
            var cb;
            if (state == EDIT) {
                this.model.set('state', CHECKING);
                cb = function(data) {
                    if (that.getState() != CHECKING || text != that.model.get('text')) return; // stale call
                    if (data.error || data.changes.length == 0) {
                        that.model.set('state', EDIT);
                        window.alert(data.error ? data.error : 'No changes detected');
                    } else {
                        that.model.set({'results': data.changes, state: CONFIRM})
                    }
                };
            } else if (state == CONFIRM) {
                this.model.set('state', SAVING);
                cb = function(data) {
                    if (data.error || data.changes.length == 0) {
                        that.model.set('state', EDIT);
                        window.alert(data.error ? data.error : 'No changes detected');
                    } else {
                        that.close();
                        if (that.options.callback) that.options.callback();
                    }
                };
            } else {
                return
            }
            Beef.Sync.mashPUT('accounts/' + this.options.accountCode + "/brands?dry-run=" + (state != CONFIRM), text, cb)
                .fail(function(xhr, errorType, error){
                    window.alert("Error [" + errorType + "]: " + error);
                    that.model.set('state', EDIT);
                });
        },

        prev: function(ev) {
            ev.preventDefault();
            if (this.getState() == SAVING) return;
            this.model.set('state', EDIT);
        }

    });
});