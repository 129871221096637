/**
 * Same as {{#ifallowed "isMashAdmin"}}, except that it can override the
 * noAdmin setting {{#ifMashAdmin ignoreNoAdmin=true}}
 */
import {shouldTurnOffAdmin} from "@/app/Features";

export default function(options) {
    var admin = !!Beef.user.get("admin");
    var noAdmin = shouldTurnOffAdmin();

    if (options && options.hash && options.hash.ignoreNoAdmin === true) {
        noAdmin = false;
    }

    if (admin && !noAdmin) return options.fn(this);
    return options.inverse(this);
}