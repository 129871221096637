<template>
    <section v-if="brand && brandHasRisk">
        <overview-title>Risk for </overview-title>

        <overview-description>
            <h4>Trending risk factors</h4>
            <p>
                The following risk factors have been trending for {{formatBrandName(brand)}} {{ englishDate }}.
                You can also <a href="#" @click.prevent="createDashboard()"><i class='symbol-reports'></i>create a dashboard</a> to further explore this data.
            </p>
        </overview-description>


        <div class="explore-risk__info">
            <alert-component v-for="alert in brandAlerts" :key="alert.id" :notification="alert"/>
            <risk-chart v-if="brand" :brand="brand"/>
        </div>
        <risk-trends v-if="brand" :brand="brand"/>
        <overview-description v-if="brand">
            <h4>Conversations that produced the most risk</h4>
            <p v-if="riskDashboard">
                You can find out more about conversations relating to this brand's risk profile
                on the <a :href="riskLink" @click.prevent="gotoRiskDashboard">{{ riskDashboard.name }}</a> dashboard.
            </p>
        </overview-description>
        <engaging-mentions v-if="brand" sort-by="risk" :filter="filter" :date-filter="dateFilter"/>
    </section>
    <section v-else-if="brand">
        <overview-title>Risk for </overview-title>
        <no-topics-message>
            {{formatBrandName(brand)}} does not have&nbsp;<em>Risk and Reputation</em>
        </no-topics-message>
    </section>
</template>


<script>
import RiskTrends from "@/app/toplevel/explore/overview/RiskTrends";
import EngagingMentions from "@/app/toplevel/explore/overview/components/EngagingMentions";
import {mapActions, mapGetters, mapState} from "vuex";
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";
import AlertComponent from "@/components/notifications/AlertComponent";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {formatBrandName} from "@/app/utils/Format";
import RiskChart from "@/app/toplevel/explore/overview/components/RiskChart";
import {showTip} from "@/app/help/tips/tips";
import {gotoDashboard} from "@/app/toplevel/dashboards/DashboardUtilities";
import {createRiskPanelDashboard} from "@/app/toplevel/explore/overview/OverviewDashboards";
import {getTrendingRiskFilter} from "@/app/toplevel/explore/overview/ExploreUtilities";


export default {
    components: {
        OverviewDescription,
        RiskChart, NoTopicsMessage, AlertComponent, OverviewTitle, EngagingMentions, RiskTrends},

    data() {
        return {}
    },

    computed: {
        ...mapState(['account']),
        ...mapState('explorePanel', ['brand', 'brandHasRisk']),
        ...mapGetters('dashboards', ['riskDashboard']),
        ...mapGetters('explorePanel', ['dateFilter', 'englishDate']),
        ...mapGetters('digests', ['riskAlerts']),

        filter() {
            if (!this.brand) return null;
            return `(${this.dateFilter}) and brand isorchildof ${this.brand.id} and (relevancy isnt irrelevant and tag is 1 and visibility is public and reshareof is unknown)`;
        },

        riskLink() {
            if (!this.riskDashboard) return '#';
            return `/${this.account.code}/dashboards/${this.riskDashboard.id}`;
        },

        brandAlerts() {
            if (!this.brand || !this.riskAlerts) return [];
            const id = `${this.brand.id}:`;
            return this.riskAlerts.filter(alert => alert.externalId.startsWith(id));
        }
    },

    async mounted() {
        await this.refreshDashboards();
        await this.refreshDigests();
        await showTip("EXPLORE_DASHBOARD");
    },

    methods: {
        formatBrandName,

        ...mapActions('dashboards', ['refreshDashboards']),
        ...mapActions('digests', ['refreshDigests']),

        gotoRiskDashboard() {
            if (!this.riskDashboard) return;
            gotoDashboard(this.riskDashboard.id);
        },

        async createDashboard() {
            await createRiskPanelDashboard(
                await getTrendingRiskFilter(this.brand.id, this.dateFilter),
                `Exploring Risk for ${this.formatBrandName(this.brand)} ${this.englishDate}`,
                "From Explore"
            );
        },
    }
}
</script>


<style scoped lang="sass">

.explore-risk__dotted
    width: fit-content
    padding: 20px

.explore-risk__info
    display: grid
    grid-template-columns: max-content auto
    grid-template-rows: 130px
    max-width: 1050px
    margin-inline: auto
</style>