/**
 * Drill down by adding/removing countries to the filter.
 */
Beef.module("Widget.SourceSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Sources",
        description:    "Interactively filter by mention source",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.CountSelector.View.extend({

        filterAttribute: "link",

        groupby: "site",

        maxItems: 200,

        mapData: function(d) {
            return { id: d.site, name: d.site, count: d.mentionCount }
        }
    });
});