/**
 * Select author name fragments to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
Beef.module("IncExAuthorIdPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/author/IncExAuthorIdPicker.handlebars"),
        items: Beef.AuthorNamePicker.items,
        bindings: {
            inc: { converter: Beef.AuthorIdPicker.converter, elAttribute: "data-value" },
            ex: { converter: Beef.AuthorIdPicker.converter, elAttribute: "data-value" }
        },
        attachPickers: function() {
            Beef.AuthorIdPicker.attach(this, ".inc", "inc");
            Beef.AuthorIdPicker.attach(this, ".ex", "ex");
        }
    });

    this.converter = Beef.AuthorNamePicker.createConverter("Author IDs");

    /**
     * Attach a inc/ex term picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
