<template>
    <section>
        <header>
            <h4 class="conduct-filter__header--right">
                <span v-if="outcomeTag">{{ outcomeName }}</span>
            </h4>
            <h4>
                <span v-if="sublistName">{{ sublistName }}</span>
            </h4>
        </header>
        <section v-if="outcomes">
            <section v-for="s in outcomes" :key="s.id"
                     class="conduct-filter__outcome-grid"
                     :class="{'conduct-filter__outcome-grid--selected': isSelected(s.id)}">
                <section class="conduct-filter__outcome"
                         :class="{'conduct-filter__outcome--selected': isSelected(s.id)}">
                    <span v-if="!isSelected(s.id)"
                          class="conduct-filter__selectable"
                          @mouseenter="showTooltip($event, s)"
                          @click="selectOutcome(s.id)">
                        {{s.name}}
                    </span>
                    <span v-else class="conduct-filter__selected">
                        <old-tooltip v-if="!isNegated(s.id)" class="conduct-filter__negate" label="Click to exclude this outcome">
                            <span @click="negate(s.id)">–</span>
                        </old-tooltip>
                        <slotted-tag v-else class="conduct-filter__negate-tag" no-close @click="negate(s.id)" tooltip="Click to include this outcome">–</slotted-tag>
                        <slotted-tag @close="selectOutcome(s.id)" :negated="isNegated(s.id)" @mouseenter="showTooltip($event, s)">
                            <span v-if="isNegated(s.id)">-</span>
                            {{s.name}}
                        </slotted-tag>
                    </span>
                </section>

                <section v-for="id in getSorted(s.children)" :key="id"
                         class="conduct-filter__category"
                         :class="{'conduct-filter__category--selected': isSelected(id)}">
                    <span v-if="!isSelected(id)"
                          class="conduct-filter__selectable"
                          @mouseenter="showTooltip($event, idToTag.get(id))"
                          @click="selectCategory(id)">
                        {{idToTag.get(id).name}}
                    </span>
                    <span v-else class="conduct-filter__selected">
                        <slotted-tag @close="selectCategory(id)" :negated="isNegated(id)" @mouseenter="showTooltip($event, idToTag.get(id))">
                            <span v-if="isNegated(id)">-</span>{{idToTag.get(id).name}}
                        </slotted-tag>
                        <old-tooltip label="Click to exclude this sub-category" v-if="!isNegated(id)" class="conduct-filter__negate">
                            <span @click="negate(id)">–</span>
                        </old-tooltip>
                        <slotted-tag v-else class="conduct-filter__negate-tag" no-close @click="negate(id)" tooltip="Click to include this sub-category">–</slotted-tag>
                    </span>

                </section>
            </section>

            <section class="conduct-filter__buttons">
                <section>
                    <be-button v-if="!allSelected"
                               link
                               tooltip="Select all mentions that related to your Conduct"
                               @click="selectAll()">
                        <span v-if="allAccountOutcomes.length === 1">Select all outcomes</span>
                        <span v-else>Select all {{outcomeTag.subtitle}} outcomes</span>
                    </be-button>
                    <slotted-tag v-else
                                 @close="unselectAll()">
                        <animated-check animated/>
                        <span v-if="allAccountOutcomes.length === 1">All outcomes selected</span>
                        <span v-else>All {{outcomeTag.subtitle}} outcomes selected</span>
                    </slotted-tag>

                    <be-button v-if="!noneAboveSelected"
                               link
                               @click="selectNone()"
                               tooltip="Select mentions that our Crowd has confirmed are not related to your Conduct">
                        <span v-if="allAccountOutcomes.length === 1">Select non-Conduct</span>
                        <span v-else> Select non{{outcomeTag.subtitle ? `-${outcomeTag.subtitle}-` : ' '}}Conduct</span>
                    </be-button>
                    <slotted-tag v-else @close="unselectNone()">
                        <animated-check animated/>
                        <span v-if="allAccountOutcomes.length === 1">Non-Conduct selected</span>
                        <span v-else>Non-{{outcomeTag.subtitle}}-Conduct selected</span>
                    </slotted-tag>

                    <be-button link @click="unselectAll()">
                        <span v-if="allAccountOutcomes.length === 1">Unselect all</span>
                        <span v-else>Unselect all {{outcomeTag.subtitle}} outcomes</span>
                    </be-button>
                </section>

                <segment-choice v-if="allAccountOutcomes.length > 1"
                                class="conduct-filter__outcome-choice"
                                :value="outcomeTag"
                                @input="switchOutcome"
                                :selected-ids="selected"
                                :with-heading="false"
                                :segment-lists="allAccountOutcomes"/>

            </section>

        </section>
    </section>
</template>


<script>
import {getAllMarketConductSegmentLists, getConductListParent} from "@/app/utils/Segments";
import SlottedTag from "@/components/tags/SlottedTag";
import BeButton from "@/components/buttons/BeButton";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import OldTooltip from "@/components/tooltip/OldTooltip";
import SegmentChoice from "@/dashboards/filter/SegmentChoice";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import SegmentTooltip from "@/dashboards/filter/SegmentTooltip";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {OldTooltip, SegmentChoice, AnimatedCheck, BeButton, SlottedTag},
    store: VuexStore,

    props: {
        value: {
            type: Array,
            default() { return [] }
        }
    },


    data() {
        return {
            outcomeTag: null,

            allAccountOutcomes: [],         // All the different outcome lists that someone can select between
            outcomes: null,                 // The current individual outcomes that a user can select between
            subCategoryWasSelected: false,  // true iff the value had a sub-category parent list selected.

            selected: [],
            allOutcomesSelected: false,

            outcomeToSubOutcomeMap: {},

            animateSwitch: false

        }
    },

    computed: {
        ...mapGetters(['idToTag']),

        allSelected() {
            if (this.selected.length < this.outcomes.length) { // Can be greater than if we have multiple lists
                return false;
            }

            return this.outcomes.every(outcome => this.selected.find(id => id === outcome.id));
        },

        noneAboveSelected() {
            const none = this.outcomeTag.children.find(id => this.idToTag.get(id)?.flag === "NONE_OF_THE_ABOVE");
            if (!none) return false;

            return this.selected.some(id => Math.abs(id) === none);
        },

        outcomeName() {
            const name = this.outcomeTag?.name ?? "";
            return this.allAccountOutcomes.length > 1 ? (this.outcomeTag.subtitle ? `${this.outcomeTag.subtitle.trim()} ${name}` : name) : name;
        },

        sublistName() {
            return this.outcomeToSubOutcomeMap[this.outcomeTag.id] ? this.outcomeToSubOutcomeMap[this.outcomeTag.id].name : "";
        }
    },

    created() {
        this.loadData();
    },

    methods: {
        ...mapActions(['refreshTags']),

        async loadData() {
            try {
                await this.refreshTags();
                const segments = await getAllMarketConductSegmentLists();
                this.outcomeTag = null;
                this.allAccountOutcomes = [];
                this.selected = [];

                // Todo No segments?
                if (segments.length) {
                    await this.refreshTags();
                    for (const segment of segments) {
                        const conductListParent = await getConductListParent(segment);
                        const isOutcomes = !conductListParent;

                        if (isOutcomes) {
                            this.outcomes ??= segment.children.map(id => this.idToTag.get(id)).filter(s => s && s.flag !== "NONE_OF_THE_ABOVE" && !s.deleted && !s._loading);
                            this.outcomeTag ??= segment;
                            this.allAccountOutcomes.push(segment);
                        } else {
                            this.outcomeToSubOutcomeMap[conductListParent.id] = segment;
                        }
                    }

                    const allChildren = new Set();
                    this.allAccountOutcomes.forEach(list => {
                        list.children.forEach(id => allChildren.add(id));
                    })

                    // Set up the initial selection of segments
                    if (this.value?.length) {
                        for (const originalId of this.value) {
                            if (!isFinite(originalId)) continue; // Making sure we get numbers that make sense. No NaNs, Infs, etc

                            // See if this is a top level segment list, in which case we add all the children
                            for (const outcomeList of this.allAccountOutcomes) {
                                if (outcomeList.id === Math.abs(originalId)) {
                                    if (outcomeList.id === this.outcomeTag.id) this.allOutcomesSelected = true;
                                    outcomeList.children.forEach(id => {
                                        const tag = this.idToTag.get(id);
                                        if (tag && tag.flag !== "NONE_OF_THE_ABOVE" && !tag.deleted) {
                                            this.selected.push(id * Math.sign(originalId));
                                        }
                                    })
                                } else {
                                    const tag = this.idToTag.get(Math.abs(originalId));
                                    if (!allChildren.has(tag.id) && tag.children) {
                                        this.subCategoryWasSelected = true;
                                        for (const childId of tag.children) {
                                            const child = this.idToTag.get(childId);
                                            if (child && !child.deleted && child.flag !== "NONE_OF_THE_ABOVE") {
                                                this.selected.push(childId * Math.sign(originalId))
                                            }
                                        }
                                    } else {
                                        this.selected.push(originalId);
                                    }
                                }
                            }
                        }
                    }
                }
            } catch(e) {
                console.error(e);
            }
        },

        getSorted(ids) {
            if (!ids?.length) return [];
            return ids
                .map(id => this.idToTag.get(id))
                .sort((lhs, rhs) => (lhs.ordinal ?? lhs.id) - (rhs.ordinal ?? rhs.id))
                .map(t => t.id);
        },

        isSelected(id) {
            return this.selected.some(selected => Math.abs(selected) === id)
        },

        isNegated(id) {
            return this.selected.some(selected => -selected === id)
        },

        selectOutcome(id) {
            const actualId = this.isNegated(id) ? -id : id;

            if (this.selected.find(selected => selected === actualId)) {
                this.selected = this.selected.filter(selected => selected !== actualId);
            } else {
                this.selected.push(id);
                // Deselect all of its children
                const outcome = this.idToTag.get(id);
                if (outcome?.children?.length) {
                    for (const childId of outcome.children) {
                        this.selected = this.selected.filter(id => Math.abs(id) !== childId);
                    }
                }
            }

            this.emitUpdate();
            this.$nextTick(() => this.unselectNone());
        },

        selectCategory(id) {
            const actualId = this.isNegated(id) ? -id : id;
            const outcome = this.outcomes.find(o => o.children?.find(c => c === id));

            if (this.selected.find(selected => selected === actualId)) {
                this.selected = this.selected.filter(selected => selected !== actualId);
            } else {
                this.selected.push(id);

                if (outcome.children.length > 1 && outcome.children.every(childId => this.selected.find(sId => sId === childId))) {
                    this.$nextTick(() => this.selectOutcome(outcome.id));
                } else {
                    this.selected = this.selected.filter(id => Math.abs(id) !== outcome.id);
                }
            }

            this.emitUpdate();
            this.$nextTick(() => this.unselectNone());
        },

        unselectAll() {
            // Remove all outcomes and children related to the currently visible outcomeTag, including NONE_OF_THE_ABOVE
            const allOutcomes = Array.from(this.outcomeTag.children);
            const allChildren = this.outcomes.map(o => o.children).flat();
            const removalSet = new Set([...allOutcomes, ...allChildren]);

            this.selected = this.selected.filter(id => !removalSet.has(Math.abs(id)));
            this.emitUpdate();
        },

        selectAll() {
            // Add all outcomes and children related to the currently visible outcomeTag (and remove unwanted outcomes / children)
            const allOutcomes = this.outcomes.map(o => o.id);
            const allChildren = this.outcomes.map(o => o.children).flat();
            const removalSet = new Set([...allOutcomes, ...allChildren]);

            this.selected = [...allOutcomes, ...this.selected.filter(id => !removalSet.has(Math.abs(id)))];
            this.emitUpdate();
            this.$nextTick(() => this.unselectNone());
        },

        selectNone() {
            for (const id of this.outcomeTag.children) {
                const tag = this.idToTag.get(id);
                if (tag?.flag === 'NONE_OF_THE_ABOVE') {
                    this.selected.push(tag.id);
                    this.emitUpdate();
                    return;
                }
            }

            console.error("Unable to find NONE_OF_THE_ABOVE");
        },

        unselectNone() {
            const none = this.outcomeTag.children.find(id => this.idToTag.get(id)?.flag === "NONE_OF_THE_ABOVE");

            if (this.selected.some(id => none === Math.abs(id))) {
                this.selected = this.selected.filter(id => Math.abs(id) !== none);
                this.emitUpdate();
            }
        },

        negate(id) {
            const isNegated = this.isNegated(id);
            const actualId = isNegated ? -id : id;

            this.selected = this.selected.filter(current => current !== actualId);
            this.selected.push(-actualId);
            this.emitUpdate();

            // If all children of an outcome are now positive,
            // select the outcome.
            const outcome = this.outcomes.find(o => o.children?.find(c => c === id));
            if (outcome && outcome.children.length > 1 && outcome.children.every(childId => this.selected.some(sId => sId === childId))) {
                this.selectOutcome(outcome.id);
            }
            // Check for all negative children
            if (outcome && outcome.children.length > 1 && outcome.children.every(childId => this.selected.some(sId => -sId === childId))) {
                this.selectOutcome(outcome.id);
                this.negate(outcome.id);
            }
        },

        emitUpdate() {
            if (!this.selected.length) {
                this.$emit('input', []);
                return;
            }

            const input = new Set();
            const seen = new Set();

            for (const list of this.allAccountOutcomes) {
                let allPositiveChildrenSelected = true;
                let allNegativeChildrenSelected = true;
                let positiveNoneSelected = null;
                for (const id of list.children) {
                    const tag = this.idToTag.get(id);
                    if (!tag) continue;
                    if (tag.deleted) continue;
                    if (tag.flag === "NONE_OF_THE_ABOVE") {
                        positiveNoneSelected = positiveNoneSelected || this.selected.find(selected => selected === id) && tag;
                        continue;
                    }

                    allPositiveChildrenSelected = allPositiveChildrenSelected && this.selected.some(selected => selected === id);
                    allNegativeChildrenSelected = allNegativeChildrenSelected && this.selected.some(selected => selected === -id);
                }

                if (positiveNoneSelected) input.add(positiveNoneSelected.id);

                if (allPositiveChildrenSelected) {
                    input.add(list.id);
                    list.children.forEach(id => seen.add(id));
                    for (const outcomeId of list.children) {
                        const outcome = this.idToTag.get(outcomeId);
                        outcome?.children?.forEach(id => seen.add(id));
                    }
                }

                if (allNegativeChildrenSelected) {
                    input.add(-list.id);
                    list.children.forEach(id => seen.add(-id));
                    for (const outcomeId of list.children) {
                        const outcome = this.idToTag.get(outcomeId);
                        outcome?.children?.forEach(id => seen.add(-id));
                    }
                }
            }

            this.selected.forEach(selected => {
                if (!seen.has(selected)) {
                    input.add(selected);
                }
            })

            this.$emit('input', Array.from(input));
        },

        isActive(needle, haystack) {
            return haystack.find(obj => obj.id === needle.id && obj.active);
        },

        switchOutcome(outcome) {
            this.outcomes = outcome.children.map(id => this.idToTag.get(id)).filter(s => s && s.flag !== "NONE_OF_THE_ABOVE");
            this.outcomeTag = outcome;
            this.animateSwitch = true;
            this.allOutcomesSelected = this.outcomes.every(outcome => this.selected.find(id => outcome.id === id));
        },

        showTooltip(event, segment) {
            showTooltipComponent(event.target, SegmentTooltip, {tag: segment})
        }
    }

}
</script>

<style scoped lang="sass">


header,
.conduct-filter__outcome-grid
    box-sizing: border-box
    padding: 5px 0
    display: grid
    grid-column-gap: 40px
    grid-template-columns: 1.5fr 1fr
    color: var(--be-colour-text-dark)


.conduct-filter__outcome-grid
    &:not(:last-of-type)
        border-bottom: thin solid #444

    > *
        grid-column-start: 2
        padding: 2px 5px
        margin: auto 0

    &--selected
        //noinspection CssInvalidFunction
        background: radial-gradient(#444, rgba(0, 0, 0, 0) 60%)

header
    h4
        margin: 0
        padding: 0
        color: white
    .conduct-filter__header--right
        text-align: right

.conduct-filter__outcome
    box-sizing: border-box
    text-align: right

    grid-row: 1 / span 20
    grid-column-start: 1
    font-size: calc(1em + 1px)


.conduct-filter__outcome:not(.conduct-filter__outcome--selected):hover,
.conduct-filter__category:not(.conduct-filter__category--selected):hover
    background: var(--background-menu-hover)
    cursor: pointer
    color: white

.conduct-filter__selectable
    box-sizing: border-box
    display: block
    width: 100%
    padding-right: 18px
    padding-left: 5px
    border: 1px solid transparent

.conduct-filter__selected
    display: flex
    justify-content: space-between
    color: white


.conduct-filter__negate
    transition: opacity var(--transition-duration) 200ms
    cursor: pointer
    padding: 0 5px
    margin-right: 8px

    opacity: 0
    display: inline-block
    width: 10px

    &:hover
        transition: opacity 0ms
        opacity: 1
        color: var(--be-colour-text-dark__hover)

.conduct-filter__selected:hover
    .conduct-filter__negate
        opacity: 1

.conduct-filter__buttons
    display: flex
    margin-top: 5px

.conduct-filter__outcome-choice
    flex-grow: 1
    min-width: max-content


.conduct-filter__negate-tag
    cursor: pointer

.be-button
    display: inline-block





</style>