import _ from 'underscore';
import {isObject} from "@/app/utils/Util";

/**
 * Extends the default Backbone model class to be aware of the fact that it might be nested in another owning model
 * and contain nested models. Adds a Root model class with support for undo.
 */
Beef.module("Model", function() {

    var ensureUndoLabel = function(model, options, action) {
        options = options || {};
        if (!options.undoLabel) {
            options.undoLabel = model.getLabel() + " " +
                (action == 'destroy' ? 'deleted' : action == 'add' ? 'added' : 'updated') + ".";
        }
        return options;
    };

    var superSave = Backbone.Model.prototype.save;
    var superDestroy = Backbone.Model.prototype.destroy;
    var superSet = Backbone.Model.prototype.set;

    /**
     * Extend the default Backbone Model to handle the concept of models nested inside other models that are not
     * persisted to the server independently. Nested models have an owner attribute. Models inside a nested
     * collection are considered nested and convert destroy into a save. Other nested models delegate save
     * to their owner.
     *
     * A model containing nested models must define a nestedModels object that maps attributes to the prototype of
     * the model. This is used by set to convert attribute values indo models. Example:
     *
     * this.Model = Backbone.Model.extend({
     *     nestedModels: {
     *         sections: this.SectionList
     *     },...
     *
     * The 'sections' attribute (an array) is converted into a SectionList model (new SectionList(sections)) on first
     * set. Subsequent sets merge the array into the model. For this to work well the objects in the array should
     * have id attributes.
     */
    Object.assign(Backbone.Model.prototype, {

        save: function(attrs, options) {
            // delegate the save to our owner if we are a nested model or a model in a nested collection
            var owner = this.owner;
            if (!owner && this.collection) owner = this.collection.owner;
            if (owner) {
                var action = options ? options.action : null;
                return owner.save.call(owner, attrs, ensureUndoLabel(this, options, action || 'save'));
            }
            return superSave.call(this, attrs, options);
        },

        destroy: function(options) {
            if (this.collection) {
                var owner = this.collection.owner;
                if (owner) {
                    options = ensureUndoLabel(this, options, 'destroy');
                    this.collection.remove(this);
                    return owner.save(null, options);
                }
            }
            return superDestroy.call(this, options);
        },

        // Handles creation of nested models
        set: function(attrs, options) {
            // set must have this signature for more info see
            // http://jstarrdewar.com/blog/2012/07/20/the-correct-way-to-override-concrete-backbone-methods

            if (this.nestedModels) {
                if (attrs == null) return this;

                // Handle both `"key", value` and `{key: value}` -style arguments.
                if (!isObject(attrs)) {
                    var key = attrs;
                    (attrs = {})[key] = options;
                    options = arguments[2];
                }

                // For each attribute that is a nested model replace its value in attrs with with a model containing
                // the value. If the nested model already exists then merge in the new data (collections) or set the
                // new data (other models).
                var that = this;
                _.each(this.nestedModels, function (modelPrototype, attribute) {
                    var value = attrs[attribute];
                    if (value) {
                        var nestedModel = that.get(attribute);
                        if (nestedModel) {
                            if (nestedModel.add) {  // a collection
                                nestedModel.each(function(model){ delete model._keep });

                                // update existing models and add new models
                                _.each(value, function(o) {
                                    if (!o.id) throw new Error("Missing id attribute: " + JSON.stringify(o));
                                    var model = nestedModel.get(o.id);
                                    if (model) { // update
                                        model.set(o, options);
                                    } else {        // add
                                        options || (options = {});
                                        options.collection = nestedModel;
                                        nestedModel.add(model = new nestedModel.model(o, options));
                                    }
                                    model._keep = true;
                                });

                                // nuke models that should no longer be in the collection
                                var toNuke = [];
                                nestedModel.each(function(element){
                                    if (!element._keep) toNuke.push(element);
                                    else delete element._keep;
                                });
                                nestedModel.remove(toNuke);
                            } else {
                                nestedModel.set(value);
                            }
                        } else {
                            nestedModel = new modelPrototype(value);
                            nestedModel.owner = that;
                        }
                        attrs[attribute] = nestedModel;
                    }
                });
            }

            return superSet.call(this, attrs, options);
        },

        /**
         * Generate a label for this model (for undo etc.). This implementation uses this.entityName and the 'name'
         * or 'title' attribute of the model (if any).
         */
        getLabel: function() {
            var entityName = this.entityName || "[Model has no entityName]";
            if (typeof entityName == "function") entityName = entityName.call(this, arguments);
            var name = this.get('name');
            if (!name) name = this.get('title');
            if (name) return entityName + " '" + name + "'";
            return entityName;
        },

        /**
         * Get a list of attributes from this model.
         */
        getAttrs: function(attributeNames) {
            var data = {};
            for (var i = 0; i < attributeNames.length; i++) {
                var a = attributeNames[i];
                data[a] = this.attributes[a];
            }
            return data;
        },

        /** Create a copy of this model containing only the attributes in the attrs hash. */
        copy: function(attrs) {
            return new this.constructor(attrs);
        },

        /** Search for a property in this model or a parent model and so on recursively. */
        getAncestorProperty: function(prop) {
            if (this[prop] != null) return this[prop];
            return this.collection ? this.collection.getAncestorProperty(prop) : null;
        }
    });

    Object.assign(Backbone.Collection.prototype, {
        /** Search for a property in this model or a parent model and so on recursively. */
        getAncestorProperty: function(prop) {
            if (this[prop] != null) return this[prop];
            return this.owner ? this.owner.getAncestorProperty(prop) : null;
        }
    });

    /**
     * Model with support for undo. The whole state of the model is remembered on fetch and after each save and
     * used to implement undo support. The model should be given an 'entityName' field to use in undo records
     * (e.g. 'Dashboard').
     */
    this.Root = Backbone.Model.extend({

        fetch: function(options) {
            options = options || {};
            var success = options.success;
            options.success = function(model, resp) {
                if (success) success(model, resp);
                // remember the state of the model so we can use it to undo changes
                model.originalJSON = JSON.parse(JSON.stringify(model));
            };
            return Backbone.Model.prototype.fetch.call(this, options);
        },

        /** Save the model creating an undo record to restore its state. */
        save: function(attrs, options) {
            options = ensureUndoLabel(this, options, 'save');

            if (this.owner) return this.owner.save.call(this, attrs, options);

            var undoJSON = options.undoInProgress ? null : this.originalJSON;
            if (undoJSON) {
                delete undoJSON.version;        // don't keep old version number or we will get a 409 on undo
                delete undoJSON.lastUpdated;    // don't keep these fields as we don't need to undo them
                delete undoJSON.lastUpdatedBy;
            }

            var success = options.success;
            options.success = function(model, resp) {
                if (success) success(model, resp);
                model.originalJSON = JSON.parse(JSON.stringify(model));
            };

            return Backbone.Model.prototype.save.call(this, attrs, options);
        },

        /** Delete the model creating an undo record to undelete it. */
        destroy: function(options) {
            options = ensureUndoLabel(this, options, 'destroy');

            var model = this;
            var id = model.id;
            var url = model.url();
            var collection = model.collection;
            var index = collection ? collection.indexOf(model) : -1;
            return Backbone.Model.prototype.destroy.call(this, options);
        }
    });

});
