import {mapActions} from "vuex";

export const addRuleMixins = {
    data() {
        return {
            saving: false,
            saveComplete: false,
            saveError: false,
            saveMessage: "Saving your rule...",
            saveCompleteMessage: "Your rule has been added to the account.",
            saveErrorMessage: "There was an error saving your rule. Please refresh the page and try again or contact DataEQ support",
            ruleToAdd: {},
            addRuleStages: {
                editMentions: {
                    EDIT_ATTRIBUTES: 1,
                    EDIT_FILTER: 2,
                    EXAMPLE_MENTIONS: 3,
                    SAVE: 4
                },
                tagMentions: {
                    EDIT_TAGS_AND_FILTER: 1,
                    TAG_EXISTING_MENTIONS: 2,
                    EXAMPLE_MENTIONS: 3,
                    SAVE: 4
                },
                crowd: {
                    EDIT_FILTER: 1,
                    EXAMPLE_MENTIONS: 2,
                    SAVE: 3
                },
                engage: {
                    EDIT_ENGAGE_PROCESSING: 1,
                    EDIT_FILTER: 2,
                    EXAMPLE_MENTIONS: 3,
                    SAVE: 4
                },
                deleteMentions: {
                    EDIT_FILTER: 1,
                    EXAMPLE_MENTIONS: 2,
                    SAVE: 3
                },
                setRelevantMentions: {
                    EDIT_FILTER: 1,
                    EXAMPLE_MENTIONS: 2,
                    SAVE: 3
                },
                create_crowd_job: {
                    CHOOSE_JOB_TYPE: 1,
                    EDIT_FILTER: 2,
                    EXAMPLE_MENTIONS: 3,
                    SAVE: 4
                }
            }
        }
    },

    methods: {
        ...mapActions(['createRule']),

        async saveRule(payload) {
            try {
                this.saving = true;
                this.saveError = false;

                await this.createRule(payload);

                this.saveComplete = true;

                this.$emit('rule-created');
            } catch (e) {
                this.saveError = true;
                console.error("Error occurred while trying to create rule: ", e);
            } finally {
                this.saving = false;
            }
        }
    }
}