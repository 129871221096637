import axios from 'axios';

// an axios instance for each of our services .. the axios assignment here is just for code completion .. it gets
// overwritten with an axios instance for the specific service when this module is initialized
let beef = axios
let mash = axios
let grouse = axios
let caper = axios
let rosemary = axios
let snoek = axios
let spam = axios
let turducken = axios
let bones = axios
let fondue = axios

export { beef, mash, grouse, caper, rosemary, snoek, spam, turducken, bones, fondue }

export default function(startupOptions) {
    let headers = { Authorization: startupOptions.authorization }

    beef = axios.create({ headers })

    mash = axios.create({
        baseURL: startupOptions.mashApi.replace("/rest/", "/"),
        headers: headers
    })

    spam = axios.create({
        baseURL: startupOptions.spamApi.replace("/rest/", "/"),
        headers: headers
    })

    grouse = axios.create({
        baseURL: startupOptions.grouseUrl,
        headers: headers
    })

    caper = axios.create({
        baseURL: startupOptions.caperUrl,
        headers: headers
    })

    rosemary = axios.create({
        baseURL: startupOptions.rosemaryUrl,
        headers: headers
    })

    snoek = axios.create({
        baseURL: startupOptions.snoekApi,
        headers: headers
    })

    turducken = axios.create({
        baseURL: startupOptions.turduckenUrl,
        headers: headers
    })

    bones = axios.create({
        baseURL: startupOptions.bonesUrl,
        headers
    });

    fondue = axios.create({
        baseURL: startupOptions.fondueApi,
        headers: {
            Authorization: startupOptions.authorization
        }
    });
}
