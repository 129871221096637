Beef.module("EditImportedBrand").addInitializer(function(startupOptions) {

    var categoryPicker = Beef.EditBrand.categoryPicker;

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/setup/brands/EditImportedBrand.handlebars"),

        attributes: { class: "edit-imported-brand dialog" },

        bindings: {
            category: { converter: categoryPicker.converter, elAttribute: "data-value" }
        },

        onFirstRender: function() {
            categoryPicker.attach(this, ".category", "category");
        }
    });

});