import { isCxSolution } from "../../utils/Segments";
import _ from 'underscore';
import {getMetatagsFromTags} from "@/app/utils/Metatags";

Beef.module("RipcasePopup").addInitializer(function(startupOptions) {

    var View = Beef.BoundItemView.extend({
        tagName: "div",
        attributes: { class: "framework-dialog ripcase-popup" },
        template: require("@/app/framework/ripcase/RipcasePopup.handlebars"),

        events: {
            "click .ok": "ok",
            "click .cancel": "cancel",
            "click .close": "cancel"
        },

        initialize: function(options) {
            var risk = [],
                purchase = [],
                cancel = [],
                service = [],
                cx = [];

            var tags = this.model.get('tags') || [];
            tags.forEach(function(tag) {
                var rpcsTag = getMetatagsFromTags(tag);
                if (rpcsTag) {
                    switch (rpcsTag.code) {
                        case "RISK": risk.push(tag); break;
                        case "PURCHASE": purchase.push(tag); break;
                        case "CANCEL": cancel.push(tag); break;
                        case "SERVICE": service.push(tag); break;
                        default:
                            console.warn("Unrecognised rpcs tag", rpcsTag)
                    }
                } else if (isCxSolution(tag) && tag.flag !== "NONE_OF_THE_ABOVE") {
                    cx.push(tag);
                }
            });

            this.model.set({
                risk: risk,
                purchase: purchase,
                cancel: cancel,
                service: service,
                cx: cx
            })

        },

        ok: function() {
            this.close();
        },

        cancel: function() {
            this.close();
        },

        onClose: function() {
            this.model.trigger("close");
        },

        keyup: function(ev) {
            if (ev.keyCode === 13) {
                ev.stopPropagation();
                ev.preventDefault();
                this.ok();
            }
        }
    });

    var currentPopup = null;

    this.getCurrentPopup = function() {
        return currentPopup;
    };

    this.show = function(mentionModel, optionalTarget) {
        if (currentPopup) {
            currentPopup.close();
            currentPopup = null;
        }

        return new Promise(function(resolve, reject) {
            try {
                var model = mentionModel.clone();
                var view = new View({model: model});
                var popup = new Beef.Popup.View({
                    closeOnHide: true,
                    positions:["bottom-left", "bottom-right", "top-left", "top-right"],
                    offsets: { top: 5, left: 0 },
                    alwaysMove: true,
                });

                popup.setTarget(optionalTarget || $("html"));
                popup.show(view);
                popup.listenTo(popup, "keyup", _.throttle(view.keyup, 500, {trailing: false}), view);
                currentPopup = popup;
                model.on("close", function() { resolve() });
            } catch (e) {
                console.error(e);
                reject();
            }
        })
    };
});