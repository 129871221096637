/**
 * Manages an array of footnotes. Footnotes should be an array in the footnotes field
 * of the model.
 *
 * <p>
 * Footnotes can be managed by using various functions that this module provides.
 */
Beef.module("Footnotes").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({

        attributes: {class: "footnotes"},

        template: require("@/dashboards/widgets/Footnotes.handlebars"),

        tagName: 'span',

        templateHelpers() {
            return {
                noteClasses: Beef.Widget.isDisableAnimation() ? '' : 'animated fadeInLeft'
            }
        },

        modelEvents: {
            "change:noSpaceForComments": "render",
            "change:_footnotes": "render"
        },

        onRender: function() {
            let footnotes = this.model.get("_footnotes")?.length || this.model.get("noSpaceForComments");
            if (!footnotes || $(document.documentElement).hasClass("fullscreen")) {
                this.$el.hide();
                this.$el.parents('.widget').removeClass('widget-footnotes');
            } else {
                this.$el.show();
                this.$el.parents('.widget').addClass('widget-footnotes');
            }
        }
    });

    /**
     * Remove all footnotes from the given view. The view can be anything that has a model property.
     */
    this.clearFootnotes = function(view) {
        view.model.generalData.unset('_footnotes');
    };

    /**
     * Add a footnote to the existing list of footnotes.
     */
    this.addFootnote = function(view, note) {
        let footnotes = view.model.generalData.get('_footnotes');
        if (!Array.isArray(footnotes)) footnotes = [ note ];
        else {
            // check if footnote already exists
            let existingFootnote = footnotes.find(f => f === note);
            if (!existingFootnote) footnotes = footnotes.concat([note]);
        }
        view.model.generalData.set('_footnotes', footnotes);
    };

    /**
     * Sets the footnotes. This can be either a single string or an array of strings.
     */
    this.setFootnotes =  function(view, notes) {
        if (!Array.isArray(notes)) notes = [ notes ];
        view.model.generalData.set('_footnotes', notes);
    };

    /**
     * Returns the footnotes associated with the given view.
     */
    this.getFootnotes = function(view) {
        return view.model.generalData.get('_footnotes');
    }

    this.setSpaceForComments = function(view, value) {
        view.model.generalData.set('noSpaceForComments', !value);
    }

});