<template>
    <td :style="{textAlign: textAlign, paddingRight: col.showBar ? '0.1em' : null, background: background}"
            :class="{clickable: editable || !isNote}" @click="onClick">
        <template v-if="value">
            <span v-if="isNote" v-html="markdown" class="markdown-note"/>
            <span v-else :style="{whiteSpace: 'nowrap'}">{{formattedValue}}</span>
        </template>
    </td>
</template>

<script>
import Markdown from 'pagedown'
import {formatNumber, formatPercentage} from "@/app/utils/Format";

export default {
    name: "CompTableCell",

    props: {
        row: Object,
        col: Object,
        attrs: Object,
        editable: Boolean,
        background: {
            required: false,
            default: null,
            type: String
        }
    },

    computed: {
        value() { return this.row.colValues[this.col.id] || { } },

        formattedValue() { return formatCellValue(this.row, this.col, this.value, this.attrs) },

        markdown() { return MarkdownConverter.makeHtml(this.formattedValue) },

        isNote() { return this.col.type === 'note' },

        textAlign() { return this.isNote ? 'left' : 'right'}
    },

    methods: {
        onClick() {
            if (this.isNote && this.editable) this.$emit('editnote', this.row, this.col)
            else this.$emit('click')
        }
    }
}

const MarkdownConverter = new Markdown.getSanitizingConverter()

export function formatCellValue(row, col, value, attrs, forCsv) {
    forCsv ??= false;

    if (col.showPercentage && col.type !== 'note') {
        let p = value.percentage
        return typeof p === 'number' ? formatPercentage(p, 1) : ''
    }
    if (col.formatter) return col.formatter(value, row, col, attrs, forCsv)
    return formatNumber(value.number)
}

</script>

<style scoped>
</style>