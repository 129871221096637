/**
 * Supports some named options:
 * * text-flow (default false), if you want this to appear as a drop down in text.
 * * tooltip, if you want to show a tooltip on the input box itself.
 */
export default function(name, options) {
    var switches = Object.assign({}, { 'text-flow': false}, options.hash);
    var classes = [name, "tag-input"];
    if (switches.class) classes.push(switches.class);
    if (!switches['text-flow']) classes.push("uneditable-input");
    if (switches['text-flow']) classes.push("dynamic-picker__input");
    return new Handlebars.SafeString('<span class="' + classes.join(' ') + '" name="' + name + '" tabindex="0" ' + (switches.tooltip ? "tooltip=\"" + switches.tooltip + '"' : '' ) + '></span>')
}