<template>
    <be-button link @click="$emit('click', $event)" :disabled="disabled" :tooltip="tooltip">
        <slot><i class="symbol-explore"></i>Explore</slot>
    </be-button>
</template>

<script>

import BeButton from "@/components/buttons/BeButton";

export default {
    components: {BeButton},

    props: {
        disabled: {
            type: Boolean,
            default: () => false
        },

        tooltip: {
            type: String,
            default: () => "See more in the Explore panel"
        }
    }
};
</script>