import vid from '@/helpers/vid'
import {fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import {showTip} from "@/app/help/tips/tips";
import _ from 'underscore';
import {formatBrandName} from "@/app/utils/Format";
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";
import {createBrandTagConverterFactory} from "@/components/pickers/brands/BrandPickerUtilities";

/**
 * Select one or more brands by typing the name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 *
 * <p>
 * This picker supports the following options:
 *
 * <ul>
 *     <li> noExclusions: default false. Set to true if users should not be able to exclude child brands.</li>
 *     <li> noNegatives: default false. Will ensure that
 *     <li> rootsOnly: default false. Set to true if it should only allow root brands for selection.
 *     <li> onlyOne: default false. Set to true if only a single brand may be selected without its children
 * </ul>
 */
Beef.module("BrandPicker").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/dashboards/filter/pickers/brand/BrandPicker.handlebars"),

        attributes: { class: "brand-picker dialog" },

        initialize: function() {
            if (!this.model.get('tree')) {
                fetchCachedBrands(this.options.view, function(data) {
                    if (this.options.rootsOnly) {
                        this.model.set({
                            tree: _(data.tree).map(function(d) {
                                return { id: d.id, name: d.name, shortName: d.shortName }; // Leave out children.
                            })
                        });
                    } else {
                        this.model.set({tree: data.tree});
                    }
                }.bind(this));
            }
        },

        modelEvents: {
            "change:tree": "render"
        },

        events: {
            "mousedown .dialog-body": "mousedown",
            "change .dialog-body": "checkboxChanged",
            "click .expand": "expandClicked",
            "keyup .search": "search",
            "change .exclude-sub-brands": "excludeSubBrandsChanged"
        },

        mousedown: function(ev) {
            ev.preventDefault();    // this stops stuff on the picker from getting focus
        },

        expandClicked: function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            $(ev.target).closest(".brand").toggleClass("expanded");
        },

        checkboxChanged: function(ev) {
            var $t = $(ev.target), on;
            var id = $t.attr('id');
            if (!id) return;
            id = id.substring(id.indexOf("-") + 1);

            if (this.excludeSubBrands) {
                on = $t.is(":checked");
                var i = this.selection.indexOf(id);
                if (on) {
                    if (i < 0) this.selection.push(id);
                } else {
                    if (i >= 0) this.selection.splice(i, 1);
                }
            } else {
                // If we have exclusions, we don't want someone to unselect
                // the base brands.
                if (this.options.noExclusions || this.options.noNegatives) {
                    var brand = this.brandMap[id];
                    if (brand.parent) {
                        var prefix = "#" + this.cid + "-";
                        var $parent = $(prefix + brand.parent.id);
                        if ($parent.is(':checked')) {
                            if (!$t.is(':checked')) {
                                if (this.options.noNegatives) $parent.attr('checked', false);
                                else this.uncheckTree(id);
                                this.trigger('change', this.getSelection());
                                return;
                            }
                        }
                    }
                }

                if (this.options.onlyOne) {
                    on = $t.is(":checked");
                    this.$el.find("input:checked").prop('checked', false);
                    $t.prop('checked', on ? true : false);
                } else {
                    this.checkTree(id, $t.is(":checked"));
                }
            }

            this.trigger('change', this.getSelection());
        },

        /**
         * Finds the highest parent node that is checked, and unchecks it and
         * all of its children.
         */
        uncheckTree: function(brandId) {
            var prefix = "#" + this.cid + "-";
            var f = function(b) {
                if (!b.parent || !$(prefix + b.parent.id).is(':checked')) {
                    this.checkTree(b.id, null);
                    return;
                }
                f(b.parent);
            }.bind(this);
            f(this.brandMap[brandId]);
        },

        /**
         * Marks this node and all of its children as being checked / unchecked.
         */
        checkTree: function(brandId, on) {
            var prefix = "#" + this.cid + "-";
            $(prefix + brandId).closest(".brand").parent().closest(".brand").toggleClass("expanded", true);
            var excludeChildren = this.options.onlyOne || this.excludeSubBrands;
            var f = function(b) {
                $(prefix + b.id).attr('checked', on);
                if (!excludeChildren && b.children) for (var i = 0; i < b.children.length; i++) f(b.children[i]);
            };
            f(this.brandMap[brandId]);
        },

        onBeforeRender: function() {
            vid.cid = this.cid

            this.brandMap = {};
            this.brandCount = 0;
            var tree = this.model.get('tree');
            if (tree) {
                for (var i = 0; i < tree.length; i++) this.buildBrandMap(tree[i]);
            }
        },

        buildBrandMap: function(b) {
            this.brandMap[b.id] = b;
            this.brandCount++;
            if (b.children) {
                for (var i = 0; i < b.children.length; i++) this.buildBrandMap(b.children[i]);
            }
        },

        templateHelpers: function() {
            var brands = this.model.get('tree');
            var activeBrands = [], archivedBrands = [], i, b;
            if (brands) {
                for (i = 0; i < brands.length; i++) {
                    b = brands[i];
                    (b.archived ? archivedBrands : activeBrands).push(b);
                }
            }
            return {
                brands: brands,
                activeBrands: activeBrands,
                archivedBrands: archivedBrands
            }
        },

        onRender: function() {
            if (this.selection && this.model.get('tree')) {
                setTimeout(function(){
                    this.$el.find('.search').focus();
                    this.selectionToView();
                }.bind(this));
            }

            showTip("SECTION_BRAND_FILTERS");
        },

        selectionToView: function() {
            var i, code, neg;
            this.$el.find(".exclude-sub-brands").prop("checked", this.excludeSubBrands);
            if (this.excludeSubBrands) {
                this.$el.find('.dialog-body .cb').prop("checked", false);
                for (i = 0; i < this.selection.length; i++) {
                    code = this.selection[i];
                    neg = code.charAt(0) == "-";
                    if (!neg) this.checkTree(code, true);
                }
            } else {
                // tick all boxes for included brands, then untick the excluded brands
                for (i = 0; i < this.selection.length; i++) {
                    code = this.selection[i];
                    neg = code.charAt(0) == "-";
                    if (neg) code = code.substring(1);
                    this.checkTree(code, !neg);
                }
            }
        },

        /** Values can be an array or space separated string. */
        setSelection: function(values) {
            if (!Array.isArray(values)) values = values ? values.toString().split(" ") : [];
            this.excludeSubBrands = values[0] === 'x';
            if (this.excludeSubBrands) values = values.slice(1);
            this.selection = values;
        },

        /** Get selected values as an array. */
        getSelection: function() {
            var sel = [];
            if (this.excludeSubBrands) {    // just collect data-id attrs for all checked brand checkboxes
                var $cb = this.$el.find(".dialog-body input:checked");
                if ($cb.length > 0) {
                    sel.push("x");
                    $cb.each(function() { sel.push($(this).attr("data-id")) });
                }
            } else {
                var prefix = "#" + this.cid + "-";
                var rootsOnly = this.options.rootsOnly;
                // process the tree starting in 'include mode' looking for brand trees to include then
                // switch to 'exclude mode' when we need to exclude part of an included subtree and so on recursively
                var process = function(brand, inc) {
                    if ($(prefix + brand.id).is(":checked")) {
                        if (!inc) {
                            sel.push("" + brand.id);
                            inc = true;
                        }
                    } else if (inc) {
                        sel.push("-" + brand.id);
                        inc = false;
                    }
                    if (brand.children && !rootsOnly) {
                        for (var i = 0; i < brand.children.length; i++) process(brand.children[i], inc);
                    }
                };
                var tree = this.model.get('tree');
                for (var i = 0; i < tree.length; i++) process(tree[i], false);
            }
            return sel;
        },

        search: function(ev) {
            var val = $(ev.target).val();
            var searching = val.length >= 2;
            this.$el.toggleClass("searching", searching);
            if (!searching) return;
            var found = {};
            findBrandIds(this.model.get('tree'), val.toLowerCase(), found);
            var that = this;
            this.$el.find(".brand").toggleClass("found", false);
            _.each(found, function(value, brandId){
                that.$el.find('.brand[data-id="' + brandId + '"]').toggleClass("found", true);
            });
        },

        excludeSubBrandsChanged: function(ev) {
            this.excludeSubBrands = ev.target.checked;
            this.selectionToView();
            this.trigger('change', this.getSelection());
        }
    });

    var findBrandIds = function(brands, search, found) {
        if (!brands) return;
        for (var i = 0; i < brands.length; i++) {
            var b = brands[i];
            if (b.name.toLowerCase().indexOf(search) >= 0) {
                for (var o = b; o; o = o.parent) found[o.id] = true;
            }
            findBrandIds(b.children, search, found);
        }
    };

    /**
     * The brand picker with none of the dialog fluff. Useful for embedding in other views.
     */
    this.PlainView = this.View.extend({
        template: require("@/dashboards/filter/pickers/brand/BrandPickerBody.handlebars"),
        attributes: { class: "brand-picker" }
    });


    /**
     * Attach a brand picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
