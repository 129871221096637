Beef.module("ReplyToPicker").addInitializer(function(startupOptions) {

    this.View = Beef.ReshareOfReplyToPicker.View.extend({
        template: require("@/dashboards/filter/pickers/sharing/ReplyToPicker.handlebars"),

        mentionPredicate: {
            label: "Recently viewed mentions that have replies",
            fn: function(m) { return m.replyCount > 0 }
        }
    });

    this.converter = Beef.ReshareOfReplyToPicker.createConverter("Reply to", "reply");

    /**
     * Attach a reply to picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View);
    };

});
