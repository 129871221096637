import beefRenderSearchJobChartD3 from '../../chart/SearchJobChartD3'
import {formatNumber} from "@/app/utils/Format";
import moment from "moment";

Beef.module("HistoricalSearch.SampleMentionRegion").addInitializer(function() {

    this.View = Backbone.Marionette.ItemView.extend({
        attributes: { class: "row-fluid" },

        template: require("@/historical-search/job/mentions/SampleMentionRegion.handlebars"),

        templateHelpers: function() {
            var job = this.model.get("job");
            var start = this.model.get("start") || job.searchRangeStartDate;
            var end = this.model.get("end") || job.searchRangeEndDate;
            var paginationModel = this.model.get("paginationModel");
            var len = paginationModel.get("total") || 0;
            var filter = "Showing " + formatNumber(len) + " sampled";
            if (len === 1) {
                filter += " mention";
            } else {
                filter += " mentions";
            }
            filter += " published between ";
            filter += formatUTC(start) + " and " + formatUTC(end);
            return {
                filter: filter
            };
        },

        initialize: function() {
            var paginationModel = this.model.get("paginationModel");
            paginationModel.on("change:offset", this.renderMentions.bind(this));
        },

        updateDateRange: function(start, end) {
            this.model.set({ start: start, end: end });
            var paginationModel = this.model.get("paginationModel");
            if (paginationModel.get("offset") !== 0) {
                paginationModel.set("offset", 0);
            } else {
                this.renderMentions();
            }
        },

        updateJob: function(job) {
            this.model.set("job", job);
            var paginationModel = this.model.get("paginationModel");
            if (paginationModel.get("offset") !== 0) {
                paginationModel.set("offset", 0);
            } else {
                this.renderMentions();
            }
        },

        renderMentions: function() {
            var mentions = [];
            var start = this.model.get("start");
            var end = this.model.get("end");
            var job = this.model.get("job");
            var data = job && job.details && job.details.length > 0 ? job.details : null;
            if (data) {
                var range;
                if (start && end) {
                    range = findRange(data, start, end);
                } else {
                    range = [0, data.length];
                }
                mentions = getMentions(data, range[0], range[1]);
            }
            var paginationModel = this.model.get("paginationModel");
            var offset = paginationModel.get("offset");
            var limit = paginationModel.get("limit");
            paginationModel.set("total", mentions.length);
            this.model.set("mentions", mentions.slice(offset, offset + limit));
            this.render();
        }
    });

    function findRange(data, startDate, endDate) {
        var range = [0, 0];
        var len = data.length;
        startDate = moment(startDate).utc().startOf("day").toDate();
        endDate = moment(endDate).utc().startOf("day").toDate();
        var start = +beefRenderSearchJobChartD3.toDateStamp(startDate);
        var end = +beefRenderSearchJobChartD3.toDateStamp(endDate);
        if (end < data[0].bucketId || start > data[len-1].bucketId) {
            return range;
        }
        if (start > data[0].bucketId) {
            range[0] = daysBetween(start, data[0].bucketId);
        }
        if (end <= data[len-1].bucketId) {
            range[1] = len - daysBetween(end, data[len-1].bucketId);
        } else {
            range[1] = len;
        }
        return range;
    }

    function formatUTC(date) {
        return moment(date).utc().format("MMMM Do YYYY") + " UTC";
    }

    function daysBetween(a, b) {
        var diff = toMillis(a) - toMillis(b);
        return Math.abs(Math.round(diff/(1000 * 60 * 60 * 24)));
    }

    function toMillis(timestamp) {
        return beefRenderSearchJobChartD3.parse(''+timestamp).getTime();
    }

    function getMentions(data, start, end) {
        var mentions = [];
        for (var i = start; i < end; i++) {
            if (data[i].sample && data[i].sample.length > 0) {
                mentions = mentions.concat(data[i].sample);
            }
        }
        return mentions;
    }
});
