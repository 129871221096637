<template>
    <div>
        <slot/>
    </div>
</template>

<script>
    /**
     * Collects validation errors from child components providing a single isValid() method.
     */
    export default {
        name: "BeForm",

        data() {
            return {
                valid: true
            }
        },

        methods: {
            validate() {
                validateImpl(this.$children)
                this.valid = isValidImpl(this.$children)
                this.$emit('input', this.valid)
                return this.valid
            }
        }
    }

    function validateImpl(children) {
        if (children) children.forEach(c => {
            if (typeof c.validate === "function") c.validate()
            validateImpl(c.$children)
        })
    }

    function isValidImpl(children) {
        if (children) {
            for (let i = 0; i < children.length; i++) {
                let c = children[i]
                if (typeof c.isValid === "function" && !c.isValid()) return false
                if (!isValidImpl(c.$children)) return false
            }
        }
        return true
    }
</script>

<style scoped>

</style>