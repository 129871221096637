<template>
    <div class="nomenclature">
        <header v-if="list">
            <h4>{{title}}</h4>
            <p class="nomenclature__intro">
                {{intro}}
            </p>
        </header>
        <div class="nomenclature__table-container">
            <table v-if="list">
                <tr v-for="row in rows" :key="row.id">
                    <td class="nomenclature__name">{{ row.name }}</td>
                    <td>
                        <div v-html="getRowDescription(row)">

                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <section v-if="interactions.length" class="nomenclature__interactions">
            <p class="nomenclature__intro">
                Including the following interactions
            </p>
            <table>
                <tr v-for="child in interactions" :key="child.id">
                    <td class="nomenclature__name">{{child.name}}</td>
                    <td>{{removeBrand(child.clientDescription)}}</td>
                </tr>
            </table>
        </section>
    </div>
</template>

<script>
    import { substituteTagParamaters } from "@/app/utils/Tags";
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapGetters} from "vuex";
    import {Tag} from "@/app/utils/types";

    export default {
        name: "NomenclatureWidgetComponent",
        store: VuexStore,

        data() {
            return {
                list: null,
            }
        },

        computed: {
            ...mapGetters(['idToTag']),

            isSegmentList() {
                return this.list instanceof Tag;
            },

            title() {
                return this.isSegmentList ? this.list.name  : this.list.title;
            },

            rows() {
                return this.isSegmentList ? this.stages : this.list.terms;
            },

            stages() {
                if (!this.list?.children) return [];
                return this.list.children
                           .map(id => this.idToTag.get(id))
                           .filter(t => t && t.flag !== "NONE_OF_THE_ABOVE" && !t.isSecondary && !t._loading && !t.deleted);
            },
            interactions() {
                if (!this.list?.children) return [];
                return this.list.children
                           .map(id => this.idToTag.get(id))
                           .filter(t => t && t.flag !== "NONE_OF_THE_ABOVE" && t.isSecondary && !t._loading && !t.deleted);
            },
            intro() {
                if (!this.list) return '';

                if (this.isSegmentList) {
                    switch (this.list.segmentType?.id) {
                        case "CX_LIST":
                            return "consists of the following journey stages";
                        case "CHANNEL_LIST":
                            return "have the following touchpoints";
                        case "CONDUCT_LIST":
                        default:
                            return "has the following factors";

                    }
                } else {
                    return this.list.subTitle ?? "";
                }
            }
        },

        methods: {
            setList(list) {
                this.list = list;
            },

            getRowDescription(row) {
                return this.isSegmentList ? this.removeBrand(row.clientDescription) : row.description;
            },

            removeBrand(text) {
                if (!text) return '';
                return substituteTagParamaters(text)
            }
        }

    }

</script>


<style scoped lang="sass">

    .nomenclature
        display: flex
        flex-direction: column
        box-sizing: border-box
        padding: 20px
        height: var(--widget-inner-height)

    .nomenclature__intro
        color: #888
        margin: 5px 0

    .nomenclature__table-container
        height: 100%
        overflow-y: auto
        margin: 40px 10px

    header h4
        color: var(--be-colour-text)
        font-size: 1.7em
        margin: 0 0 0.2em

    h4 + .nomenclature__intro
        padding-top: 0
        margin-top: 0

    table
        box-sizing: border-box
        background: white
        min-width: 95%

        tr:nth-child(odd)
            background: #eee
        td
            padding: 5px 5px
        td:not(:first-of-type)
            padding-left: 10px

    .nomenclature__interactions
        margin-bottom: auto


    .nomenclature__name
        color: black
        font-weight: 600
        font-size: 1.05em


    .width1
        .nomenclature
            padding: 10px
            font-size: 12px
        header .nomenclature__intro
            display: none
        header
            margin-bottom: 15px
        table
            margin: 0 5px
        tr
            display: flex
            flex-direction: column
            padding: 5px
        td
            padding: 0
        .nomenclature__interactions
            display: none


    .width3 .nomenclature,
    .width4 .nomenclature,
    .width5 .nomenclature,
    .width6 .nomenclature,
    .width7 .nomenclature,
    .width8 .nomenclature
        font-size: 15px

    .height6, .height7, .height8
        .nomenclature__intro
            margin: 20px 0
        h4 + .nomenclature__intro
            margin-top: 10px

</style>