var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <table "
    + ((stack1 = __default(require("../../../helpers/ifcond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"_data") : depth0)) != null ? lookupProperty(stack1,"series") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),"==",1,{"name":"ifcond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":83}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = __default(require("../../../helpers/ifcond.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"_data") : depth0)) != null ? lookupProperty(stack1,"series") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),">",1,{"name":"ifcond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "        <tbody>\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"volume","_count","count",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":52}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"uniqueAuthors","_count","authorIdCount",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":67}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"conversations","_count","conversationIdCount",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":73}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"uniqueSites","_count","sites",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":22,"column":57}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"OTS","_count","ots",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":47}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"AVE","_count","ave","zar",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":53}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"engagement","_count","engagement",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":61}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"reshares","_count","reshareCount",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":61}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"replies","_count","replyCount",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":27,"column":58}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"positiveOts","_count","positiveOts",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":28,"column":63}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"negativeOts","_count","negativeOts",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":63}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"positiveOtsP","_count","positiveOtsP",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":30,"column":65}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"negativeOtsP","_count","negativeOtsP",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":65}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"totalSentiment","_count","totalSentiment",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":69}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"totalSentimentP","_count","totalSentimentP",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":71}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"positiveCount","_count","positiveCount",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":34,"column":67}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"neutralCount","_count","neutralCount",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":65}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"negativeCount","_count","negativeCount",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":36,"column":67}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"positiveCountP","_count","positiveCountP",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":37,"column":69}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"neutralCountP","_count","neutralCountP",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":38,"column":67}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"negativeCountP","_count","negativeCountP",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":39,"column":69}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"sampleSize","_count","sampleSize",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":40,"column":61}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"brandIndex","_count","brandIndex",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":61}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"avgResponseTime","_count","avgResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":42,"column":71}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"stddevResponseTime","_count","stddevResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":43,"column":77}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"minResponseTime","_count","minResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":44,"column":71}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"maxResponseTime","_count","maxResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":45,"column":71}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"averageInteractionResponseTime","_count","averageInteractionResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":46,"column":101}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"stddevInteractionResponseTime","_count","stddevInteractionResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":47,"column":99}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"minInteractionResponseTime","_count","minInteractionResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":48,"column":93}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"maxInteractionResponseTime","_count","maxInteractionResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":49,"column":93}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"averageInteractionWhResponseTime","_count","averageInteractionWhResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":50,"column":105}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"stddevInteractionWhResponseTime","_count","stddevInteractionWhResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":51,"column":103}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"minInteractionWhResponseTime","_count","minInteractionWhResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":52,"column":8},"end":{"line":52,"column":97}}}))
    + "\n        "
    + alias2(__default(require("../../../helpers/renderStatsRow.js")).call(alias1,"maxInteractionWhResponseTime","_count","maxInteractionWhResponseTime",{"name":"renderStatsRow","hash":{},"data":data,"loc":{"start":{"line":53,"column":8},"end":{"line":53,"column":97}}}))
    + "\n        </tbody>\n    </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "class='stats-oneseries'";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <thead>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_data") : depth0)) != null ? lookupProperty(stack1,"series") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":14,"column":21}}})) != null ? stack1 : "")
    + "            <th>&nbsp;</th>\n            </thead>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_showDeltas") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":64}}})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_showDeltas") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "class=\"delta-main\"";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dateComparison") : depth0),"==",true,{"name":"ifcond","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":12,"column":31}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <th class=\"delta-td delta-th\">Δ</th>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                        <th class=\"delta-td delta-th icon-chart-pie\"></th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_data") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":56,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});