<template>
    <div class="fb-copy-influencer-links">
        <p>
            Send one link to each influencer. Ensure that you do not send the same link to multiple influencers.
        </p>
        <div class="fb-copy-influencer-links__links-container dark-scrollbars dark-scrollbars--visible">
            <div class="fb-copy-influencer-links__link-container">
                <div class="fb-copy-influencer-links__link" v-for="(link, index) in newProfileData.influencerProfiles.links" :key="link">
                    <span>{{ index + 1 }}</span>
                    <input type="text" disabled :value="link"/>
                    <be-button class="btn-copy" @click="copyLink(index, link)" :disabled="copiedLinks.includes(index)"><i class="icon-clipboard"></i>{{ copiedLinks.includes(index) ? `Copied` : `Copy to clipboard` }}</be-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import BeButton from "@/components/buttons/BeButton";
import VuexStore from "@/store/vuex/VuexStore";
import {copyToClipboard} from "@/app/Clipboard";
import {notifyWithText} from "@/app/framework/notifications/Notifications";

export default {
    name: "FbCopyInfluencerLinks",
    components: {BeButton},
    store: VuexStore,

    data: function() {
        return {
            copiedLinks: []
        }
    },

    computed: {
        ...mapState('profiles', ['newProfileData'])
    },

    methods: {
        copyToClipboard,

        async copyLink(linkIndex, link) {
            await this.copyToClipboard(link);

            this.copiedLinks.push(linkIndex);

            notifyWithText(`Link ${linkIndex + 1} has been copied to clipboard.`,
                null,
                "<i class='icon-clipboard'></i>");
        }
    }
}
</script>

<style scoped lang="sass">

.fb-copy-influencer-links

    margin-top: 20px

    p
        color: var(--be-colour-text-dark)

    &__links-container
        margin-top: 30px
        height: 300px
        overflow-y: auto
        padding-right: 10px

    &__link-container
        display: flex
        flex-direction: column
        row-gap: 10px

    &__link
        color: var(--be-colour-text-dark)
        background: #333
        display: flex
        column-gap: 10px
        align-items: center
        border: 1px solid #1A1A17
        padding: 10px

        span
            width: 20px

        input
            width: 65%
            margin: 0 12px 0 0

        .btn-copy
            width: 25%

</style>