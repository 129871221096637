import {findMissingBrands} from "@/dashboards/filter/FilterParser";
import {notifyWithHtml, notifyWithText} from "@/app/framework/notifications/Notifications";
import {showTip} from "@/app/help/tips/tips";
import {escapeExpression} from "@/app/utils/StringUtils";

Beef.module("SectionSettings").addInitializer(function(startupOptions) {

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/dashboards/SectionSettings.handlebars"),

        attributes: { class: "section-settings dialog" },

        editAttributes: ['title', 'filter', 'animation-delay', 'refresh-interval'],

        regions: {
            filter: "div.filter"
        },

        events: Object.assign({}, Beef.SettingsDialog.View.prototype.events,{
            "click a.btn.advanced": "toggleAdvanced",
            "click a.btn.basic": "toggleBasic",
        }),

        onFirstRender: function() {
            this.model.accountCode = this.originalModel.getAncestorProperty('accountCode');
            this.originalSettings = Beef.Sync.cloneModel(this.originalModel);
            var filter = new Beef.Filter.View({model: this.model, cache: this.cache, noToggle: true});
            filter.on("editor-changed", function(ed) {this.$el.attr('data-editor', ed);}.bind(this));
            this.filter.show(filter);

            this.listenTo(this.originalModel, 'change',function() {
                this.modelWasUpdated = true;
            }.bind(this));

            var showBrandError = function() {
                setTimeout(function() {
                    this.$('.missing-brand-message')
                        .show()
                        .toggleClass('animated fadeIn', true);
                }.bind(this));
            }.bind(this);

            try {
                findMissingBrands(this.model.get('filter'))
                    .then(function (errors) {
                        if (errors.length) {
                            showBrandError();
                        }
                    })
                    .catch(e => console.error(e));
            } catch (e) {
                console.error(e);
            }

            setTimeout(function() {
                this.$('input[name=title]').select();
            }.bind(this));

            showTip("SECTION_DATE_RANGE_FILTERS");
        },

        onClose: function() {
            if (this.modelWasUpdated && this.okClicked) {
                notifyWithHtml(
                    `<strong>${escapeExpression(this.originalSettings.title)}</strong> settings updated.`,
                    () => {
                        this.originalModel.set(this.originalSettings);
                        this.originalModel.save();
                        notifyWithText("Section setting changes have been undone.");
                    }
                );
            }
        },
        toggleAdvanced: function() {
            if (this.filter && this.filter.currentView) {
                this.filter.currentView.changeEditor("advanced");
            }
        },
        toggleBasic: function() {
            if (this.filter && this.filter.currentView) {
                this.filter.currentView.changeEditor("basic");
            }
        }
    });

});