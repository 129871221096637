<template>
    <section>
        <header>
            <h4 v-if="selectedSegmentList">
                <span v-if="hasMultipleLists">{{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }}</span>
                <span v-else>{{ selectedSegmentList.name }}</span>
            </h4>
            <h4 v-else>Channels</h4> <!-- It's busy loading here -->
        </header>
        <section v-if="loading">
            <loading-message message="Fetching your channels" class="segment-list-filter__loading"/>
        </section>
        <section v-else class="segment-list-filter__tag-list">
            <section v-for="tag in tags"
                     :key="tag.id"
                     class="segment-list-filter__tag">
                <span v-if="!isSelected(tag.id)"
                      class="segment-list-filter__tag-selectable"
                      @mouseenter="showTooltip($event, tag)"
                      @click="select(tag.id)">
                    <rpcs-tag v-if="tag.flag" :code="tag.flag"></rpcs-tag>
                    {{ tag.name }}
                </span>
                <section v-else class="segment-list-filter__tag-selected">
                    <span v-if="!isNegated(tag.id)" class="segment-list-filter__tag-negate" @click="negate(tag.id)">–</span>
                    <slotted-tag v-else class="segment-list-filter__tag-negated" no-close @click="negate(tag.id)">–</slotted-tag>
                    <slotted-tag @close="select(tag.id)"
                                 @mouseenter="showTooltip($event, tag)"
                                 :negated="isNegated(tag.id)">
                        <span v-if="isNegated(tag.id)">-</span>
                        <rpcs-tag v-if="tag.flag" :code="tag.flag"></rpcs-tag>
                        {{ tag.name }}
                    </slotted-tag>
                </section>
            </section>
        </section>
        <section v-if="!loading" class="segment-list-filter__controls">
            <section class="segment-list-filter__buttons">
                <be-button v-if="!isAllSelected" link @click="selectAll()"
                           :tooltip="selectAllTooltip">
                    <span v-if="hasMultipleLists">Select all {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }}</span>
                    <span v-else>{{ selectAllLabel }}</span>
                </be-button>
                <slotted-tag v-else @close="unselectAll(false)">
                    <animated-check/>
                    <span v-if="hasMultipleLists">All {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }} selected</span>
                    <span v-else>{{ allSelectedLabel }}</span>
                </slotted-tag>

                <be-button v-if="!isNonAboveSelected" link @click="selectNonAbove()"
                           :tooltip="selectNoneTooltip">
                    <span v-if="hasMultipleLists">Select none {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }}</span>
                    <span v-else>{{selectNoneLabel}}</span>
                </be-button>
                <slotted-tag v-else @close="select(none.id)" :negated="isNegated(none.id)">
                    <animated-check/>
                    <span v-if="isNegated(none.id)">-</span>
                    <span v-if="hasMultipleLists">None {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }} selected</span>
                    <span v-else>{{ noneSelectedLabel }}</span>
                </slotted-tag>

                <be-button link @click="unselectAll()" :tooltip="unselectAllTooltip">Unselect all</be-button>
            </section>

            <segment-choice v-if="hasMultipleLists"
                            v-model="selectedSegmentList"
                            :segment-lists="allSegmentLists"
                            :selected-ids="selected"
            />
        </section>
    </section>
</template>


<script>
import {deprecatedTagsStore as tagStore} from "@/store/deprecated/Stores";
import {getAllSegmentListsWithType} from "@/app/utils/Segments";
import {substituteTagParamaters} from "@/app/utils/Tags";
import SlottedTag from "@/components/tags/SlottedTag";
import BeButton from "@/components/buttons/BeButton";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import SegmentChoice from "@/dashboards/filter/SegmentChoice";
import { showTooltipComponent } from "@/components/tooltip/TooltipUtilities";
import SegmentTooltip from "@/dashboards/filter/SegmentTooltip";
import LoadingMessage from "@/components/LoadingMessage";
import RpcsTag from "@/components/tags/RpcsTag";

export default {
    components: {RpcsTag, LoadingMessage, SegmentChoice, AnimatedCheck, BeButton, SlottedTag},
    props: {
        originalSegments: {
            type: Array,
            default() { return [] }
        },
        segmentListType: {              // The type of segment lists that we want to show
            type: String,
            required: true
        },
        selectAllLabel: {
            type: String,
            default: "Select all tags"
        },
        selectAllTooltip: {
            type: String,
            default: "Click to select all mentions related to this segment"
        },
        allSelectedLabel: {
            type: String,
            default: "All tags selected"
        },
        selectNoneLabel: {
            type: String,
            default: "Select non-segment mentions"
        },
        selectNoneTooltip: {
            type: String,
            default: "Click to select all mentions that are verified to not be related to this segment"
        },
        noneSelectedLabel: {
            type: String,
            default: "Non-segment mentions selected"
        },
        unselectAllTooltip: {
            type: String,
            default: "Click to unselect all of these tags"
        }
    },


    data() {
        return {
            loading: false,
            allSegmentLists: [],        // All segment lists of the given type

            selectedSegmentList: null,  // The current segment list whose child tags we are displaying
            tags: [],                   // All the tags that are currently on display
            none: null,                 // The none-of-the-above option

            selected: [],               // The tags that are selected
            unknown: []                 // Tags the user has chosen that are no longer usable (segments from archived brands, etc)
        }
    },

    computed: {
        isAllSelected() {
            return !!this.tags.length && this.tags.every(s => this.selected.some(id => id === s.id));
        },

        isNonAboveSelected() {
            return this.none && this.selected.some(selected => Math.abs(selected) === this.none.id);
        },

        hasMultipleLists() {
            return this.allSegmentLists?.length > 1;
        }
    },

    watch: {
        selectedSegmentList(segmentList) {
            if (segmentList?.children?.length) {
                const children = segmentList.children.map(id => tagStore.get(id));
                this.tags = children.filter(tag => tag.flag !== "NONE_OF_THE_ABOVE" && !tag.deleted && !tag._loading);
                this.none = children.find(tag => tag.flag === "NONE_OF_THE_ABOVE");

            } else {
                this.tags = [];
                this.none = null;
            }
        }
    },

    async mounted() {
        await this.loadData();
    },

    methods: {
        substituteTagParamaters,

        async loadData() {
            try {
                this.loading = true;
                await tagStore.refresh(true);

                this.allSegmentLists = await getAllSegmentListsWithType(this.segmentListType);
                if (this.allSegmentLists.length) {
                    this.selectedSegmentList = this.allSegmentLists[0];
                }

                if (this.originalSegments.length) {
                    const selected = [];
                    for (const originalId of this.originalSegments) {
                        if (!isFinite(originalId)) continue; // Skip numbers.
                        const tag = tagStore.get(Math.abs(originalId));
                        if (tag.segmentType?.id === this.segmentListType) { // This is a parent, segment list tag.
                            tag.children.forEach(cId => {
                                const child = tagStore.get(cId);
                                if (child.flag === "NONE_OF_THE_ABOVE" || child.deleted || child._loading) return;
                                selected.push(cId * Math.sign(originalId));
                            })
                        } else {
                            selected.push(originalId);
                        }
                    }
                    this.selected = selected;
                }

                // Now we need to check that we have display options for all the selected segments.
                // We may not if a segment was from an archived or deleted brand, for example.
                const allKnownTags = new Set();
                for (const list of this.allSegmentLists) {
                    list.children.forEach(id => allKnownTags.add(id));
                }

                const unknown = [];
                this.selected.forEach(selected => {
                    if (!allKnownTags.has(Math.abs(selected))) {
                        unknown.push(Math.abs(selected));
                    }
                })

                // We add the parents of unknown segments to our list of risks, which will
                // enable users to choose those lists.
                if (unknown.length) {
                    const parentIds = new Set(unknown.map(id => tagStore.get(id)._parent.id));
                    for (const id of parentIds) {
                        this.allSegmentLists.push(tagStore.get(id));
                    }
                }

            } catch(e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },

        select(id) {
            if (this.isSelected(id)) {
                this.selected = this.selected.filter(sId => Math.abs(sId) !== id);
            } else {
                this.selected.push(id);
            }

            if (this.none && this.isSelected(this.none.id)) {
                this.$nextTick(() => this.select(this.none.id));      // Unselect the none-of-the-above.
            }

            this.emitUpdates();
        },

        isSelected(id) {
            return this.selected.some(sId => Math.abs(sId) === id);
        },

        isNegated(id) {
            return this.selected.some(selected => -selected === id);
        },

        negate(id) {
            const isNegated = this.isNegated(id);
            const actualId = isNegated ? -id : id;

            this.selected = this.selected.filter(current => current !== actualId);
            this.selected.push(-actualId);
            this.emitUpdates();
        },

        selectAll() {
            const toRemove = new Set(this.tags.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.selected = [...this.selected, ...this.tags.map(f => f.id)];
            this.emitUpdates();
        },

        unselectAll(includeNone) {
            includeNone ??= true;
            const toRemove = includeNone
                ? new Set(this.selectedSegmentList.children)
                : new Set(this.tags.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.emitUpdates();
        },

        selectNonAbove() {
            if (this.none) this.selected.push(this.none.id);
            this.emitUpdates();
        },

        emitUpdates() {
            if (!this.selected.length) {
                this.$emit('input', []);
                return;
            }

            const input = [];
            const seen = new Set();
            for (const risk of this.allSegmentLists) {
                let allPositiveChildrenSelected = true;
                let allNegativeChildrenSelected = true;
                let positiveNoneSelected = null;
                for (const id of risk.children) {
                    const tag = tagStore.get(id);
                    if (tag.deleted) continue;
                    if (tag.flag === "NONE_OF_THE_ABOVE") {
                        positiveNoneSelected = positiveNoneSelected || this.selected.find(selected => selected === id) && tag;
                        continue;
                    }

                    allPositiveChildrenSelected = allPositiveChildrenSelected && this.selected.some(selected => selected === id);
                    allNegativeChildrenSelected = allNegativeChildrenSelected && this.selected.some(selected => selected === -id);
                }

                if (positiveNoneSelected) input.push(positiveNoneSelected.id);

                if (allPositiveChildrenSelected) {
                    input.push(risk.id);
                    risk.children.forEach(id => seen.add(id));
                }

                if (allNegativeChildrenSelected) {
                    input.push(-risk.id);
                    risk.children.forEach(id => seen.add(-id));
                }
            }

            this.selected.forEach(selected => {
                if (!seen.has(selected)) input.push(selected);
            })

            this.$emit('input', input);
        },

        showTooltip(event, segment) {
            showTooltipComponent(event.target, SegmentTooltip, {tag: segment})
        }
    }

}
</script>

<style scoped lang="sass">

.segment-list-filter__loading
    margin-top: 20px
    height: 150px

.segment-list-filter__tag-list
    display: grid
    grid-template-columns: 1fr 1fr
    color: var(--be-colour-text-dark)


.segment-list-filter__tag
    box-sizing: border-box
    display: flex
    padding: 5px
    width: 100%
    font-size: calc(1em + 1px)
    border-bottom: thin solid #444

.segment-list-filter__tag-selectable
    box-sizing: border-box
    width: 100%
    padding: 2px 10px 2px 57px
    border: thin solid transparent

    &:hover
        transition: background-color var(--transition-duration), color var(--transition-duration)
        background: var(--background-menu-hover)
        cursor: pointer
        color: white


.segment-list-filter__tag-selected
    padding: 2px 10px 3px 5px
    color: white

.segment-list-filter__tag-negate
    box-sizing: border-box
    margin-right: 32px
    padding-left: 6px
    cursor: pointer
    opacity: 0
    &:hover
        transition: opacity var(--transition-duration)
        color: var(--be-colour-text-dark__hover)
        opacity: 1

.segment-list-filter__tag:hover
    .segment-list-filter__tag-negate
        transition: opacity var(--transition-duration) 200ms
        opacity: 1

.segment-list-filter__tag-negated
    margin-right: 20.5px
    cursor: pointer


.segment-list-filter__controls
    box-sizing: border-box
    padding-top: 10px
    display: flex
    align-items: center


.segment-list-filter__buttons
    box-sizing: border-box
    display: flex
    flex-wrap: wrap
    align-items: center

    > .be-tag
        margin-right: 5px




</style>