import moment from "moment"
import {flattenFilterNodes, parseFilterString} from "@/dashboards/filter/FilterParser";
import {lookForPublished} from "@/dashboards/filter/BasicFilter";

/**
 * Given a filter this can derive intervals back in time based on the published range of the filter. It can also
 * convert those back into actual date ranges.
 */
export default class DateInterval {

    constructor(filter) {
        let root = flattenFilterNodes(parseFilterString(filter))
        let terms = root.type === 'AndNode' ? root.children : [root]
        this.published = lookForPublished(terms, [], null, root)
        this.interval = calcInterval(this.published)  // int (days), "day", "week", "month", "quarter", "year"

        //console.log("published " + this.published + " interval " + this.interval)
        let i = this.published ? this.published.indexOf('-') : -1
        if (i < 0) {
            this.start = moment().startOf('day')
            this.end = moment(this.start)
            this.start.add(-1, typeof this.interval === "string" ? this.interval : 'day')
        } else {
            let s = this.published.substring(0, i)
            this.start = moment(s, s.length <= 10 ? yyyyMMdd : "YYYY/MM/DD HH:mm")
            s = this.published.substring(i + 1)
            let noTime = s.length <= 10;
            this.end = moment(s, noTime ? yyyyMMdd : "YYYY/MM/DD HH:mm")
            if (noTime) this.end.add(1, 'day')  // make exclusive
        }
    }

    getPreviousDates() {
        let interval = this.interval
        if (typeof interval === "number") {
            if (interval <= 7) interval = "week"
            else if (interval <= 31) interval = "month"
            else if (interval <= 90) interval = "quarter"
            else interval = "year"
        }
        let a = [this.calcPreviousDate("current")], prev
        for (let p = INTERVALS.indexOf(interval); p < INTERVALS.length; p++) {
            let ip = INTERVALS[p]
            for (let i = 1; i <= 3; i++) {
                let o = this.calcPreviousDate(ip + "-" + i)
                if (!prev || prev.name !== o.name) a.push(prev = o)
                if (interval !== "quarter" && ip === "quarter") o.visible = false
            }
        }
        return a
    }

    /**
     * Convert a previous date id (e.g. month-1) to an object with the range, name etc.
     */
    calcPreviousDate(id) {
        if ("current" === id) {
            return {
                id: id,
                name: this.formatDateRange(this.start, this.end),
                filter: ""
            }
        }

        let i = id.indexOf('-')
        if (i < 0) throw "Invalid prev date id [" + id + "]"
        let n = parseInt(id.substring(i + 1))
        let interval = id.substring(0, i)
        let start = moment(this.start).add(-n, interval)

        // make sure we have a whole week, month, quarter, or year if needed
        let end
        if (typeof this.interval === "string") end = moment(start).add(1, this.interval)
        else end = moment(this.end).add(-n, interval)

        let f = "YYYY/MM/DD"
        return {
            id: id,
            name: this.formatDateRange(start, end),
            filter: "Published AFTER '" + start.format(f) + "' AND Published BEFORE '" + end.format(f) + "'"
        }
    }

    /**
     * Dates are moment's. End is exclusive.
     */
    formatDateRange(start, end) {
        let relativeTo = this.start
        let timeFmt = start.hour() || start.minute() ? " HH:mm" : ""
        let year = relativeTo.year()
        let startYearFmt = start.year() === year ? "" : "'YY"
        let endYearFmt = startYearFmt || (end.year() === year ? "" : "'YY")
        if (!timeFmt && start.date() === 1 && end.date() === 1 && moment(start).add(1, "months").isSame(end)) {
            return start.format("MMMM" + startYearFmt)
        }
        if (!end.hour() && !end.minute()) end = moment(end).add(-1, 'day')    // make inclusive

        if (start.month() === end.month() && start.year() === end.year() && !timeFmt) {
            return start.date() + " - " + end.format("D MMM" + endYearFmt)
        }

        let startDayFormat = start.date() === 1 && end.date() === 1 ? "" : "D "
        let endDayFormat = start.date() === 1 && end.date() === 1 ? "" : "D "

        return start.format(startDayFormat + "MMM" + startYearFmt + timeFmt) + " - " +
            end.format(endDayFormat + "MMM" + endYearFmt + timeFmt)
    }

    get doWeeksMakeSense() {
        return this.interval <= 7 || this.interval === "week"
    }

    get doMonthsMakeSense() {
        return this.interval <= 31 || this.interval === "week" || this.interval === "month"
    }
}

const INTERVALS = ["week", "month", "quarter", "year"]

const yyyyMMdd = "YYYY/MM/DD"

function calcInterval(published) {
    if (!published) return null

    switch (published) {
        case 'TWENTY_FOUR_HRS':
        case 'DAY':         return 1
        case 'WEEK':        return "week"
        case 'MONTH':       return "month"
        case 'QUARTER':     return "quarter"
        case 'YEAR':        return "year"
    }

    let i = published.indexOf('-')
    if (i < 0) return null

    let start = published.substring(0, i)
    let end = published.substring(i + 1)
    if (start.length > 10 || end.length > 10) return 1  // has time

    let endDate = moment(end, yyyyMMdd).add(1, 'days') // end is inclusive and we want exclusive
    let d = moment(start, yyyyMMdd)
    if (moment(d).add(7, 'days').isSame(endDate)) return "week"
    if (moment(d).add(1, 'months').isSame(endDate)) return "month"
    if (moment(d).add(3, 'months').isSame(endDate)) return "quarter"
    if (moment(d).add(1, 'years').isSame(endDate)) return "year"
    return moment(end, yyyyMMdd).diff(d, 'days')
}
