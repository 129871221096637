<template>
    <dialog-box class="profile-picker-dialog"
                @close="closeNoEscape($event)"
                title="Include statistics for these profiles"
                width="950px"
                stop-mousedown
                modal>
        <div class="dialog-top">
            <search-input class="profile-picker-dialog__search"
                          autofocus
                          ref="searchBar"
                          placeholder="Search for a profile"
                          @keydown.enter.stop.prevent="focusFirst()"
                          :value="searchTerm"
                          @input="searchTermHandler($event)"/>
            <div class="selected-counter"> {{ selected.length }} Profiles selected</div>

            <popup-menu
                :value="showOptions"
                ref="profileOptions"
                left
                stop-mousedown
                never-hide
                :arrow-pointer="{ show: true, left: false }"
            >
                <template #activator>
                    <be-button class="show-profile-options-btn">
                        Options <i class="caret" style="margin-left: 5px;"/>
                    </be-button>
                </template>
                    <div class="show-profile-options short-animated fadeIn" id="show-profile-options">
                        <ul>
                            <li class="show-profile-options-header">Filter Profiles</li>
                            <li v-for="show in showProfileTypeOptions" :key="show.label">
                                <label class="show-profile-options-label checkbox" v-if="!show.notVisible">
                                    <input type="checkbox" v-model="show.value"
                                           @input="changeProfileFilter($event, show.type)"/>
                                    {{ show.label }}
                                </label>
                            </li>
                        </ul>
                </div>
            </popup-menu>
        </div>

        <new-profile-picker
            class="profile-picker"
            style="height: auto"
            v-model="selected"
            :allow-negation="allowNegation"
            :profiles="profiles.profiles"
            :profile-options="showProfileTypeOptions"
            :search-term="searchTerm">

        </new-profile-picker>

        <template #buttons>
            <section class="profile-picker-dialog__buttons">
                <div class="profile-picker-dialog__left-buttons">
                    <be-button link @click="selectAll">Select all</be-button>
                    <be-button link @click="selectFacebook">Select Facebook</be-button>
                    <be-button link @click="selectInstagram">Select Instagram</be-button>
                    <be-button link @click="selectTwitter">Select X</be-button>
                    <be-button link @click="selectGoogleBusiness">Select Google Business</be-button>
                    <be-button link @click="clear">Clear</be-button>
                </div>

                <be-button link @click="close">Cancel</be-button>
                <be-button primary @click="save()">Select</be-button>
            </section>
        </template>

    </dialog-box>
</template>

<script>

import DialogBox from "@/components/DialogBox.vue";
import NewProfilePicker from "@/components/pickers/profiles/NewProfilePicker.vue";
import {mapActions, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import BeButton from "@/components/buttons/BeButton.vue";
import SearchInput from "@/components/inputs/SearchInput.vue";
import PopupMenu from "@/components/PopupMenu.vue";
import {profileTypes} from "@/setup/profiles/ProfileUtils";
import {features} from "@/app/Features";
import ProfilePicker from "@/dashboards/filter/pickers/profile/ProfilePicker";

export default {
    store: VuexStore,
    components: {PopupMenu, SearchInput, BeButton, NewProfilePicker, DialogBox},

    props: {
        previousSelected: Array,
        allowNegation: Boolean
    },

    data() {
        const showProfileTypeOptions = [
            ...[
                {
                    value: false,
                    label: "X",
                    type: profileTypes.twitter
                },
                {
                    value: false,
                    label: "Facebook",
                    type: profileTypes.facebook
                },
                {
                    value: false,
                    label: "Instagram Business",
                    type: profileTypes.instagram_business
                },
                {
                    value: false,
                    label: "Instagram User",
                    type: profileTypes.instagram_user
                },
                {
                    value: false,
                    label: "LinkedIn",
                    type: profileTypes.linkedin
                },
                {
                    value: false,
                    label: "Youtube",
                    type: profileTypes.youtube
                },
                {
                    value: false,
                    label: "Telegram",
                    type: profileTypes.telegram
                },
                {
                    value: false,
                    // value: features.tikTokProfiles(),
                    notVisible: !features.tikTokProfiles(),
                    label: "TikTok",
                    type: profileTypes.tikTok
                },
                {
                    value: false,
                    label: "Whatsapp",
                    type: profileTypes.whatsapp
                },
                {
                    value: false,
                    label: "Trustpilot",
                    type: profileTypes.trustpilot
                }
            ],
            ...[
                {
                    value: false,
                    label: "Hellopeter",
                    type: profileTypes.helloPeter
                }
            ],
            ...[
                {
                    value: false,
                    label: "Google Business",
                    type: profileTypes.googleBusiness
                }
            ],
            ...[
                {
                    value: false,
                    label: "Authorised profiles",
                    type: "AUTH"
                },
                {
                    value: false,
                    label: "Unauthorised profiles",
                    type: "UNAUTH"
                }
            ]
        ];
        return {
            selected: this.previousSelected ? this.previousSelected : [],
            searchTerm: '',
            showOptions: false,
            showProfileTypeOptions,
            searchTimeout: null,
        };
    },

    methods: {
        ...mapActions("profiles", ["refreshProfiles", "refreshProfileStats", "checkWarnings"]),

        selectAll() {
            this.selected = this.profiles.profiles.map(p => p.id);
        },

        selectFacebook() {
            this.selected = this.profiles.profiles
                .filter(profile => profile.type === "FACEBOOK_PAGE" || profile.type === "FACEBOOK_USER")
                .map(p => p.id);
        },

        selectTwitter() {
            this.selected = this.profiles.profiles
                .filter(profile => profile.type === "TWITTER_SCREEN_NAME")
                .map(p => p.id);
        },

        selectInstagram() {
            this.selected = this.profiles.profiles
                .filter(profile => profile.type === "INSTAGRAM_USER" || profile.type === "INSTAGRAM_BUSINESS")
                .map(p => p.id);
        },

        selectGoogleBusiness() {
            this.selected = this.profiles.profiles
                .filter(profile => profile.type === "GOOGLEBUSINESS")
                .map(p => p.id);
        },

        changeProfileFilter(event, optionType) {
            event.stopPropagation();
            event.preventDefault();
            this.showProfileTypeOptions = this.showProfileTypeOptions.map(option => {
                if (option.type === optionType) {
                    option.value = !option.value;
                }
                return option;
            })
        },

        save() {
            this.$emit('selected', this.selected);
            this.close();
        },

        clear() {
            this.selected = [];
        },

        focusFirst() {
            console.log("Something")
        },

        close(){
            this.$emit('close');
        },

        closeNoEscape(event) {
            if (event.key === 'Escape') return;
            this.$emit('close');
        },

        searchTermHandler(event) {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }
            this.searchTimeout = setTimeout(() => this.searchTerm = event, 200);
        },

        /**
         * @param {KeyboardEvent} event
         */
        keyHandler(event) {
            event.preventDefault();
            event.stopPropagation();

            if (event.key === 'Escape') {
                if (this.searchTerm?.length) {
                    this.searchTerm = '';
                } else {
                    this.$emit('close');
                }
            } else if (this.$refs.searchBar && event.key.length === 1) {
                this.$refs.searchBar.focus();
            }
        },
    },

    computed: {
        ...mapState(['profiles']),
    },

    async mounted() {
        document.addEventListener("keyup", this.keyHandler, {capture: true});
        await this.refreshProfiles(false);
    },

    beforeDestroy() {
        document.removeEventListener("keyup", this.keyHandler, true);
    },
}

</script>

<style scoped>

.profile-picker-dialog__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: var(--background-dialog-buttoncontainer);
}

.profile-picker-dialog__left-buttons {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 5px;
}

.profile-picker-dialog__search {
    position: relative;
    margin-bottom: 4px;
    width: 660px;
    box-sizing: border-box;
}

.profile-picker-dialog ::v-deep .dialog-top {
    display: flex;
    column-gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.selected-counter {
    margin-left: auto;
    align-items: center;
    color: var(--be-colour-text-dark__hover);
}

.show-profile-options {
    float: right;
    display: flex;
    flex-direction: column;
    border: var(--border);
}

.show-profile-options ul {
    list-style: none;
    margin: 0;
}

.show-profile-options li {
    line-height: 20px;
}

.show-profile-options li a {
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    display: block;
    padding: 3px 10px;
    clear: both;
    white-space: nowrap;
}

.show-profile-options li a:hover {
    color: #aee15d;
    text-decoration: none;
    background-color: #222222;
}

.show-profile-options-header {
    background: #222222;
    padding: 3px 10px;
    margin-bottom: 3px;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--clr-sidebar-header) !important;
    pointer-events: none;
}

.show-profile-options-label {
    padding: 3px 10px;
    margin-left: 20px;
}

.show-profile-options-btn {
    margin: auto;
}

.profile-picker-dialog ::v-deep .dialog-button-bar {
    margin: 0;
    padding: 10px;
    border-top: var(--border-separation);
    background: var(--background-dialog-buttoncontainer);
}

.profile-picker-dialog ::v-deep .dialog-body {
    padding: 0;
    margin: 0;
    background: var(--body-background-colour);
}

.profile-picker-dialog .profile-picker {
    padding-left: 20px;
    padding-right: 20px;
}


</style>