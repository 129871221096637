<template>
    <section>
        <dotted-card @click="$emit('click', $event)">
            <h4>
                Add a brand
                <br>
                <i class="icon-plus"></i>
            </h4>
            <p>
                There are currently no brands in this account. Click here to add one.
            </p>
        </dotted-card>
    </section>
</template>

<script>
import DottedCard from "@/components/cards/DottedCard";
export default {
    components: {DottedCard}
};
</script>