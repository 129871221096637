import { AttributeParser } from '../../mentionq/mentionq'

/**
 * Parses attribute strings using MentionQ.
 */
Beef.module("AttributeParser").addInitializer(function(startupOptions) {

    /**
     * Parse the attributes and return the parse tree. Returns null if the attributes are undefined, null or empty.
     * Throws an error message if the attributes fail to parse.
     */
    this.parse = function(attr) {
        return attr && attr.trim().length > 0 ? new AttributeParser().parse(attr) : null;
    };

});