
<template>
    <main class="deq-reset">
        <div class="head title">
            <h1>Sorting</h1>
        </div>

        <h4 class="relevancy-table-header">
            Relevancy Table
        </h4>

        <LoadingMessage v-if="relevancy.isLoading" :message="'Loading Sorter'" class="sorter-loader" />
        <ErrorMessage v-if="relevancy.hasError"/>


        <section v-if="!relevancy.isLoading && !relevancy.hasError" class="table-container">

            <div class="relevancy-row titles-container">
                <h5 class="brand-name">Brand Name</h5>
                <div class="data-container">
                    <h5>Accuracy</h5>
                    <h5>False Irrelevant Rate</h5>
                </div>
            </div>


            <div class="the-table dark-scrollbars">
                <template>
                    <div v-for="(relevance, index) in relevancy.data" :key="index" class="relevancy-row">
                        <div class="brand-name">{{ formatName(relevance) }}</div>
                        <div v-if="!relevance.error && relevance.model" class="data-container">
                            <div class="relevancy-data">
                                <deq-percentage :percent="relevance.accuracy" is-proportion :precision="3"/>
                            </div>
                            <div class="relevancy-data">
                                <deq-percentage :percent="relevance.falseIrrelevantRate" is-proportion :precision="3"/>
                            </div>
                        </div>
                        <div v-if="relevance.error" class="data-container">
                            <p class="relevance-model-error"><i class="symbol-warning"></i>{{relevance.error}}</p>
                        </div>
                        <div v-if="!relevance.model" class="data-container">
                            <p class="relevance-model-error"><i class="symbol-warning"></i>No model available, all mentions left unsorted</p>
                        </div>
                    </div>
                </template>
            </div>
        </section>


        <template  v-if="!relevancy.isLoading && !relevancy.hasError">
            <div class="sorter-blurb">
                The performance of the sorter is influenced by the quality of incoming mentions and the ratio of
                human sorted mentions. The following steps will help the sorter and improve the quality of data in your
                account:
                <ul>
                    <li>Make your search phrases more specific so they bring in higher quality mentions</li>
                    <li>Add <a @click="gotoAccountRules">rules</a> to delete mentions you know you don't need</li>
                    <li>Look for relevant mentions in the
                        <a @click="gotoMentions">trash</a>
                        and undelete them</li>
                </ul>
            </div>

            <div class="sorter-blurb">
                When the sorter restarts it automatically sorts all unsorted mentions in your account.
            </div>

        </template>



    </main>
</template>

<script lang="js">

import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import ErrorMessage from "@/components/ErrorMessage.vue";
import DeqPercentage from "@/components/formatters/DeqPercentage.vue";
import {gotoAccountRules, gotoMentions} from "@/app/toplevel/setup/SetupUtilities";
import {bones} from "@/store/Services";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import LoadingMessage from "@/components/LoadingMessage.vue";

export default {
    name: "SetupSorter",

    components: {LoadingMessage, DeqPercentage, ErrorMessage},

    data() {
        return {
            relevancy: {
                isLoading: true,
                hasError: false,
                data: []
            },
        }
    },

    store: VuexStore,

    computed: {
        ...mapState(['account']),
        ...mapState(['deletedBrands']),
    },

    methods: {
        gotoMentions,
        gotoAccountRules,

        formatName(brand) {
            return (brand?.brandShortName ?? brand?.brandName).trim();
        }
    },

    created() {
    },

    async mounted() {
        this.relevancy.isLoading = true;
        const accountCode = this.account.code;

        let res;
        try {

            res = await bones.get(`/rest/v1/accounts/${accountCode}/sorter`);

            if (res.status !== 200) {
                console.warn('Error while fetching: ', res.status, res.statusText);
                throw new Error(res.statusText);
            }

            this.relevancy.data = res.data;

            this.relevancy.hasError = !Object.keys(this.relevancy.data).length;

            // remove deleted brands
            this.relevancy.data = this.relevancy.data
                .filter(data => {
                    return !this.deletedBrands.find(delBrand => {
                        return  (delBrand.id === data.brandId);
                    })
                }).filter(data => {
                    return this.account.brands.find(brand => brand.id === data.brandId);
                })

            //  sort order own brands then competitor then archived
            this.relevancy.data = this.relevancy.data.sort((lhs, rhs) => {
                const lhsBrand = this.account.brands.find(brand => brand.id === lhs.brandId)
                const rhsBrand = this.account.brands.find(brand => brand.id === rhs.brandId)

                const getCat = (obj) => {
                    if (obj === "OWN") return -1;
                    if (obj === "COMPETITOR") return 0;
                    return 1;
                }
                return getCat(lhsBrand?.category) - getCat(rhsBrand?.category)
            })

            // move main brand to first in list
            if (this.account.defaultBrand?.id) {
                this.relevancy.data.unshift(this.relevancy.data.splice(this.relevancy.data.findIndex(x => {
                    return x.brandId === this.account.defaultBrand.id
                }), 1)[0])
            }

            this.relevancy.isLoading = false;

        } catch (error) {
            console.warn('An error occurred: ', error);
            notifyUserOfError(error)
            this.relevancy.hasError = true;
            this.relevancy.isLoading = false;
        }

    }

}
</script>

<style scoped lang="sass">

.sorter-loader
    --loading-message-delay: 100ms

.sorter-blurb
    max-width: unset
    width: unset
    margin-top: 20px
    margin-bottom: 20px
    color: #ccc

    em
        font-style: normal
        font-weight: 400
        color: #eee

    a
        &:hover
            cursor: pointer

.relevancy-table-header
    margin: 0
    padding: 5px 0 5px 5px

.table-container
    color: var(--heading-colour-text-dark)
    border: 1px solid var(--colour-background-black)
    margin-bottom: 10px

    .spinner-container
        margin: 10px
        text-align: center
        position: relative
        width: 100%

    .titles-container
        background-color: #222 !important
        padding-right: 11px

        .data-container

            h5
                width: 50%
                text-align: center
                margin-block-start: 0
                margin-block-end: 0
                margin-inline-start: 0
                margin-inline-end: 0

    .the-table
        max-height: 300px
        overflow-y: scroll
        scrollbar-width: var(--scrollbar-width)

    .relevancy-row
        display: flex
        flex-direction: row
        align-items: center
        height: 50px
        background-color: #333

        &:not(:last-of-type)
            border-bottom: 1px solid var(--colour-background-black)

        .relevance-model-error
            margin: 0

    .brand-name
        width: 40%
        height: 100%
        border-right: 1px solid var(--colour-background-black)
        text-align: center
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

    .data-container
        width: 60%
        height: 100%
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center

</style>