var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            Edit the user details"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":33},"end":{"line":4,"column":76}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " for <em>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</em>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":80}}})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Adding user <em>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</em>";
},"7":function(container,depth0,helpers,partials,data) {
    return "Add a user";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"main-user control-group-colour control-group-spacer\">\n                        This is the main user of this account. The main user cannot be deleted. If you wish to remove this\n                        user from the account, <a href=\"mailto:support@dataeq.com\">contact</a> the DataEQ team to\n                        change your account's main user.\n                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"mainUser") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":29,"column":31}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"delete btn pull-left\">Delete</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n<div class=\"dialog-body\">\n    <div class=\"row-fluid\">\n        <div class=\"span12\">\n            <div class=\"pages\" style=\"min-height: 184px\">\n                <div class=\"email\"></div>\n                <div class=\"details\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mainUser") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"dialog-button-bar\">\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"deleteContact",{"name":"ifallowed","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":30,"column":30}}})) != null ? stack1 : "")
    + "                <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n                <a class=\"prev btn\"><i class=\"icon-chevron-left\"></i> Previous</a>\n                <a class=\"next btn\"><i class=\"icon-chevron-right\"></i> Next</a>\n                <a class=\"ok btn btn-primary user-save\">Ok</a>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});