<template>
    <section class="be-sidebar-menu-item deq-reset"
             :class="classObject"
             @mouseenter="$emit('mouseenter', $event)"
             @click="click($event)">
        <old-tooltip v-if="tooltip" :label="tooltip">
            <slot>A menu item</slot>
        </old-tooltip>
        <slot v-else>A menu item</slot>
    </section>
</template>


<script>

import OldTooltip from "@/components/tooltip/OldTooltip";

export default  {
    components: {OldTooltip},
    props: {
        active: {type: Boolean, default: false},        // Whether this menu item is active / selected or not
        indented: {type: Boolean, default: false},      // Whether this menu item should be indented or not.
        disabled: {type: Boolean, default: false},      // Whether this menu item should be disabled or not
        tooltip: {type: String, default: null},
        disableHover: {type: Boolean, default: false}   // Turn off hover effects. Button is not disabled.
    },

    computed: {
        classObject() {
            return {
                'be-sidebar-menu-item--active': this.active,
                'be-sidebar-menu-item--indented': this.indented,
                'be-sidebar-menu-item--disabled': this.disabled,
                'be-sidebar-menu-item--hover-enabled': !this.disableHover
            }
        }
    },

    methods: {
        click(event) {
            if (!this.disabled) this.$emit('click', event);
        }
    }
}

</script>

<style scoped lang="sass">

.be-sidebar-menu-item
    box-sizing: border-box
    line-height: 20px
    min-height: var(--sidebar-item-min-height)
    padding: 10px 16px 10px 12px
    border-radius: 0
    text-align: left
    color: var(--sidebar-colour)
    font-weight: 400
    letter-spacing: 0
    position: relative
    border-right: 1px solid var(--colour-background-black)
    border-left: 1px solid var(--colour-background-black)
    word-wrap: break-word
    cursor: pointer
    touch-action: manipulation
    background: var(--sidebar-background)

    ::v-deep a
        color: var(--sidebar-colour)
        &:focus
            outline: none

.be-sidebar-menu-item.be-sidebar-menu-item--active
    background: var(--colour-active-gradient)
    --sidebar-colour: white
    color: var(--sidebar-colour)
    ::v-deep a
        color: var(--sidebar-colour)

.be-sidebar-menu-item.be-sidebar-menu-item--disabled
    filter: grayscale(80%)
    color: var(--be-colour-muted-text-dark)
    cursor: default

.be-sidebar-menu-item.be-sidebar-menu-item--active.be-sidebar-menu-item--disabled
    color: black

.be-sidebar-menu-item.be-sidebar-menu-item--indented
    padding-left: 40px

.be-sidebar-menu-item.be-sidebar-menu-item--hover-enabled:hover
    background: var(--background-menu-hover)
    --sidebar-colour: white
    color: var(--sidebar-colour)
    a
        color: var(--sidebar-colour)

</style>