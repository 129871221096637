var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit…";
},"3":function(container,depth0,helpers,partials,data) {
    return "Duplicate";
},"5":function(container,depth0,helpers,partials,data) {
    return "Copy to clipboard";
},"7":function(container,depth0,helpers,partials,data) {
    return "Refresh";
},"9":function(container,depth0,helpers,partials,data) {
    return "Move to end";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"divider\"></li>\n    <li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"View the mentions used in this metric","method":"viewMentions"},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":120}}})) != null ? stack1 : "")
    + "</li>\n    <li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"View the authors who created the mentions used by this metric","method":"viewAuthors"},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":142}}})) != null ? stack1 : "")
    + "</li>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editMentions",{"name":"ifallowed","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":18}}})) != null ? stack1 : "")
    + "    <li class=\"divider\"></li>\n    <li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"optional":"true","title":"Change the word cloud layout","method":"nextLayout"},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":126}}})) != null ? stack1 : "")
    + "</li>\n    <li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Save the chart as a PNG formatted image","method":"savePNG"},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":117}}})) != null ? stack1 : "")
    + "</li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"csvLink") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "View mentions";
},"14":function(container,depth0,helpers,partials,data) {
    return "View authors";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Tag the mentions used in this metric","method":"tagMentions"},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":124}}})) != null ? stack1 : "")
    + "</li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "Tag mentions...";
},"19":function(container,depth0,helpers,partials,data) {
    return "Shuffle layout";
},"21":function(container,depth0,helpers,partials,data) {
    return "Save as image";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li><a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"csvLink") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Export data to a CSV file\">Export as CSV</a></li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Export the raw data used in this metric","method":"exportCSV"},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":123}}})) != null ? stack1 : "")
    + "</li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "Export as CSV";
},"28":function(container,depth0,helpers,partials,data) {
    return "Replace…";
},"30":function(container,depth0,helpers,partials,data) {
    return "Delete…";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Edit settings","method":"edit"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":76}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Add a copy of this metric","method":"copy"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":92}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Copy this metric to be pasted elsewhere","method":"copyToClipboard"},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":125}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Reload the metric's data","method":"refresh"},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":92}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Move this metric to the end of the section","method":"toEnd"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":112}}})) != null ? stack1 : "")
    + "</li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSelector") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "<li class=\"divider\"></li>\n<li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Replace this metric with another","method":"replace"},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":23,"column":101}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Remove this metric from the section","method":"delete"},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":24,"column":102}}})) != null ? stack1 : "")
    + "</li>\n\n\n\n";
},"useData":true});