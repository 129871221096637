import VuexStore from "@/store/vuex/VuexStore";

/**
 * Returns the current account. This might return null if no account is currently set.
 * @returns {Account|null}
 */
export function account() {
    return VuexStore.state.account;
}

/**
 * Returns the account code. Throws an exception if no account is currently active.
 * @returns {string}
 */
export function currentAccountCode() {
    if (!VuexStore.state.account?.code) {
        throw new ReferenceError("Account has not been initialised: there is no account code");
    }
    return VuexStore.state.account.code;
}

/**
 * Indicates whether we are running in a dev environment. or not.
 * @returns {boolean}
 */
export function isDevEnvironment() {
    return !!VuexStore.state.account?.dev;
}