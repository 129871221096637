var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"user-edit\"><i class=\"edit icon-pencil\" id="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "></i></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"view-user\"><span class=\"user-name\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":76}}})) != null ? stack1 : "")
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + " "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + "</span></td>\n<td>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</td>\n<td title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"permissionDescr") : depth0), depth0))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"permissionLabel") : depth0), depth0))
    + "</td>\n<td>"
    + alias3(__default(require("../../helpers/renderPrivateMentions.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"privateMentions") : depth0),{"name":"renderPrivateMentions","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":45}}}))
    + "</td>\n<td>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"teams") : depth0), depth0))
    + "</td>\n<td>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editContact",(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":18}}})) != null ? stack1 : "")
    + "</td>\n\n";
},"useData":true});