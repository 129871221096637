import { WordCloudChart } from "@/app/utils/WordCloud";
import {grouseGet} from "@/data/Grouse";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {getPalette} from "@/app/utils/Colours";
import {formatNumber} from "@/app/utils/Format";
import moment from "moment";
import {errorHelper} from "@/dashboards/DashboardUtils";
import VuexStore from "@/store/vuex/VuexStore";
import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";

/**
 * Displays conversation themes as a word cloud.
 */
Beef.module("Widget.WordCloud").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Word Cloud",
        description:    "Visualise commonly used words",
        tooltip:        "Identify the key themes emerging in your conversation",
        group:          "themes",
        width: 6,
        height: 4
    };

    var newSeed = function() {
        return Math.floor(Math.random() * 10000)
    };

    var wordPicker = Beef.DynamicPicker.create({
        extractWord: { name: "Mentions", description: "Use words from the mentions selected by your filter" },
        authorBioWord: { name: "Author bios", description: "Use words from the authors' bios" }
    });

    this.View = Beef.BoundItemView.extend({
        modelEvents: {
            "change": "maybeRefresh",
            "change:seed": "render"
        },

        attributes: {
            class: 'word-cloud widget-height-inner'
        },

        initialize: function() {
            if (!this.model.get('word-choice')) {
                this.model.set({'word-choice': 'extractWord'}, {silent: true})
            }

            if (!this.model.get('seed')) {
                this.model.set({'seed': newSeed()}, {silent: true}); // Initialise the seed for our layout.
                setTimeout(function(){
                    this.model.save();
                }.bind(this), 2000)
            }

            // Provide some defaults:
            var defaults = {};
            if (!this.model.has('layout')) defaults.layout = 'archimedean';
            if (!this.model.has('scale')) defaults.scale = 'square';
            if (!this.model.has('min-font')) defaults['min-font'] = 9;
            if (!this.model.has('max-font')) defaults['max-font'] = 70;
            if (!this.model.has('group-threshold')) defaults['group-threshold'] = 5;
            if (!this.model.has('group')) defaults.group = false;
            if (!this.model.has('commentFontSize')) defaults.commentFontSize = 14;
            if (!this.model.has('commentWidth')) defaults.commentWidth = null;
            this.model.set(defaults, {silent: true});
            this.refresh();
        },

        maybeRefresh: function() {
            if (this.model.hasChanged("_effectiveFilter")
                || this.model.hasChanged('num-words')
                || this.model.hasChanged('hashtags')
                || this.model.hasChanged('atnames')
                || this.model.hasChanged('word-choice')
                || this.model.hasChanged('group')
                || this.model.hasChanged('remove-list')
                || this.model.hasChanged('group-threshold')) {
                this.refresh();
            } else if (this.model.hasChanged('width')
                || this.model.hasChanged('height')
                || this.model.hasChanged('scale')
                || this.model.hasChanged('layout')
                || this.model.hasChanged('min-font')
                || this.model.hasChanged('max-font')
                || this.model.hasChanged('colour-index')
                || this.model.hasChanged('colour-palette')) {
                this.$('svg').attr({width: 0, height: 0});
                this.render();
            }
        },

        refresh: function() {
            const errorHelperWrapper = e => errorHelper(this.model, e);
            try {
                if (!this.model.get('_effectiveFilter')) return;

                this.model.generalData.set("_loading", true);
                const code = VuexStore.state.account.code;
                let numWords = this.model.get('num-words');

                if (!numWords) {
                    numWords = 140;
                    this.model.set({'num-words': numWords}, {silent: true});
                }

                var params = {
                    filter: this.model.get('_effectiveFilter'),
                    limit: numWords
                };

                if (this.model.get('hashtags')) {
                    params.prefix = "#";
                    params.wordsLike = "#%";
                }

                if (this.model.get('atnames')) {
                    params.prefix = "@";
                    params.wordsLike = "@%";
                }

                const fromGrouse = this.model.getSectionModel()
                    ? this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view)
                    : grouseGet;

                if (this.model.get('group')) {
                    params.group = true;
                    params.groupThreshold = this.model.get('group-threshold');
                    fromGrouse('/v4/accounts/' + code + '/mentions/word-groups', params)
                        .then((data) => this.updateWords(data))
                        .catch(errorHelperWrapper);
                } else {
                    var choice = this.model.get('word-choice') || "extractWord";
                    params.groupBy = choice;
                    params.select = choice === "authorBioWord" ? "authorIdCount" : "mentionCount";
                    params.stopWords = true;
                    this.model.getSectionModel().view.getJsonFromGrouse('/v4/accounts/' + code + '/mentions/count', params)
                        .then( (data) => {
                            this.updateWords(data.map(function(row) {
                                return {word: row.extractWord || row.authorBioWord, count: row.mentionCount || row.authorIdCount}
                            }));
                        })
                        .catch(errorHelperWrapper);
                }
            } catch (e) {
                errorHelperWrapper(e);
            }
        },

        updateWords: function (words) {
            //console.log("updateWords", words)
            this.model.generalData.set('_loading', false);
            if (words.length === 0) {
                this.model.generalData.set('_message', "No mentions match your filter");
                this.$('.word').remove();
            } else {
                this.model.generalData.set('_message', null);
            }
            // force a change event even if the words are the same otherwise things like changing the default
            // colours at dashboard level don't work because the word cloud doesn't re-render itself
            this._data = words;
            this.render();
        },

        getCsv: function() {
            var csv = this.dataToCsv();
            if (csv == null) {
                alert("There is no data to download. Check your filters");
                return;
            }

            var caption = this.model.get("caption") || "csv";

            // The idea here is to create an anchor element for downloading
            // a file. The href encodes the data that we want to download.
            // We convert the json data into a string CSV, and create an appropriately
            // encoded url using some html5 features (although could be done manually).
            var link = document.createElement("a");
            link.style = "display: none";
            link.href = window.URL.createObjectURL(new Blob([csv], {type: "text/csv"}));
            link.download = caption + "-" + (moment().format("YYYY-MM-DD-HH[h]mm")) + ".csv";

            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        },

        dataToCsv: function() {
            var data = this._data;
            if (!data) return null;


            // Add the UTF-8 BOM to the header.
            // The UTF-8 BOM is so that Excel + Windows correctly interprets and opens
            // this file.
            var csv = "\ufeffword,count\n";

            data.forEach(function(d) {
                csv = csv + '"' + d.word.replace(/"/g, '""') + '",' + d.count + "\n";
            });

            return csv;
        },

        /*
         * Called from the mini-menu to change the layout of the word cloud.
         */
        nextLayout: function() {
            this.model.set('seed', newSeed());
            this.model.save();
        },


        render: function() {
            if (!this.model.get('_effectiveFilter')) return;

            this.$('svg').attr({width: 0, height: 0});

            var words = this.getWords();
            if (words && words.length && window.location.pathname.match(/\/........\/dashboards\//i)) {
                var width = this.$el.width(),
                    height = this.$el.height();

                // Setup the canvas.
                var cloud = this.cloud;

                if (!cloud) {
                    cloud = this.cloud = new WordCloudChart()
                }

                // Begin the layout.
                // There is an unfortunate timing bug: data may be loading asynchronously, and the user
                // might in this time change to another panel. In this case, the layout below will lock up the browser.
                // We need to not start the word cloud if we're not on the dashboard panel.
                if (window.location.pathname.match(/\/........\/dashboards\//i)) {
                    cloud
                        .element(this.$el[0])
                        .width(width)
                        .height(height)
                        .seed(this.model.get('seed'))
                        .data(words)
                        .layout(this.model.get('layout'))
                        .scale(this.model.get('scale'))
                        .minFont(this.model.get('min-font'))
                        .maxFont(this.model.get('max-font'))
                        .colours(getPalette(this.model.attributes, this.model.getDashboardModel().attributes))
                        .noAnimation(Beef.Widget.isDisableAnimation())
                        .onRender(() => this.endOfRender())
                        .render();

                    var wordToData = {};
                    words?.forEach(d => wordToData[d.word] = d);

                    var that = this;
                    cloud.dispatch()
                        .on('elementClick', function(d, node) {
                            that.wordClicked(d, node);
                        })
                        .on('tooltipShow', function(d, node) {
                            var data = wordToData[d.text];
                            if (!data)
                                return;
                            // If there is no group or group contains only one word, we use the normal tooltip.
                            var group = (data.group && data.group.length > 1 ? data.group : [])
                                .map((wordCount) => {
                                    return {
                                        word: encloseInDisplayQuotes(wordCount.word),
                                        count: formatNumber(wordCount.count)
                                    };
                                });
                            var isAuthor = that.model.get("word-choice") === "authorBioWord";

                            Beef.Tooltip.show({
                                template: require("@/dashboards/widgets/wordcloud/WordCloudTooltip.handlebars"),
                                positions: ['bottom-right', 'bottom-left'],
                                offsets: { right: -d.width / 2, top: d.size + 5 },
                                target: node,
                                model: new Backbone.Model({
                                    word: encloseInDisplayQuotes(data.word),
                                    count: formatNumber(data.count),
                                    group: group,
                                    isAuthor: isAuthor
                                })
                            })
                        })
                        .on('tooltipHide', function() { Beef.Tooltip.close(); });
                }
            } else {
                this.endOfRender()
            }
        },

        wordClicked: function(d, node) {
            this.selectedWord = d;
            Beef.MiniMenu.show({
                menu: [
                    {
                        text: "Remove word",
                        tooltip: "Permanently remove this word from the word cloud",
                        method: "removeWord"
                    },
                    Beef.MiniMenu.divider,
                    {
                        text: "View mentions",
                        tooltip: "See the mentions that use this word",
                        method: "seeMentions"
                    },
                    {
                        text: "View mentions in tab",
                        tooltip: "See the mentions using this word in a new tab",
                        method: "tabMentions"
                    },
                    Beef.MiniMenu.divider,
                    {
                        text: "View authors",
                        tooltip: "See the authors using this word",
                        method: "seeAuthors"
                    }
                ],
                object: this,
                target: this.$(node),
                positions: null,
                offsets: { right: -d.width / 2, top: d.size + 5 },
                dropdown: true
            });
        },

        getWords: function() {
            var removeList = new Set(this.model.get('remove-list') ?? []),
                data = this._data;

            if (data?.length && removeList.size) {
                data = data.filter(w => !removeList.has(w.word));
            }

            return data;
        },

        removeWord: function() {
            if (this.selectedWord) {
                var text = this.selectedWord.text,
                    removeList = this.model.get('remove-list') || [];

                this.model.set({
                    'remove-list': removeList.concat([text])
                });
                this.model.save();
            }
        },

        seeMentions: function() {
            this.openMentions(false);
        },

        tabMentions: function() {
            this.openMentions(true);
        },

        seeAuthors: function() {
            var filter = this.getFilterForSelectedWord();
            Beef.AuthorsSectionV4.navigateToAuthors(this.model.getAncestorProperty('accountCode'), filter);
        },

        openMentions: function(newTab) {
            var filter = this.getFilterForSelectedWord();

            if (newTab) {
                Beef.MentionList.navigateToMentions(this.model.getAncestorProperty('accountCode'), filter, null, newTab);
            } else {
                var selectedWord = this.selectedWord && this.selectedWord.text;
                if (!selectedWord) showMentions(filter);
                else showMentions(filter, [encloseInDisplayQuotes(selectedWord)])
            }
        },

        getFilterForSelectedWord: function() {
            var selectedWord = this.selectedWord && this.selectedWord.text,
                filterWords;
            if (!selectedWord)
                return;
            if (this.model.get('group')) {
                var wordGroup = this.getWords().find(function (wordGroup) {
                    return wordGroup.word === selectedWord;
                });
                if (!wordGroup || !wordGroup.group)
                    return;
                filterWords = wordGroup.group.map(d => d.word);
            } else {
                filterWords = [selectedWord];
            }

            var operand = "content";
            switch(this.model.get("word-choice") || "extractWord") {
                case "extractWord":
                    operand = "content"; break;
                case "authorBioWord":
                    operand = "authorBio"; break;
                default:
                    console.error("Do not know how to search " + this.model.get("word-choice") + " in word cloud");
            }

            var wordFilters = filterWords.map(function (word) {
                var text = word.replace("'", "\\'");
                text = '"' + text + '"';
                text = operand + " matches '" + text + "'";
                return text;
            });

            return "(" + this.model.get('_effectiveFilter') + ") and (" + wordFilters.join(" or ") + ")";
        },

        endOfRender: function() {
            if (!this.model.generalData.get('_loading')) {
                this.model.generalData.set('_completed', true);
            }
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/wordcloud/WordCloudSettings.handlebars"),

        editAttributes: function() {
            return ['min-font', 'max-font', 'num-words', 'scale', 'layout', 'remove-list', 'hashtags', 'atnames', 'group',
                'group-threshold', 'colour-palette', 'colour-palette-custom', 'colour-index', 'word-choice']
        },

        regions: {
            colourSettingsRegion: ".colour-settings-region"
        },

        events: {
            'click .archimedean':   'archimedean',
            'click .rectangular':   'rectangular',
            'click .log':           'log',
            'click .square':        'square',
            'click .linear':        'linear',
            'click .removed-word-list .close': 'removeTag',
            'change .hashtags': 'hashtags',
            'change .atnames': 'atnames',
            'change .group': 'group'
        },

        bindings: function() {
            return {
                'word-choice': { converter: wordPicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            this.colourSettingsRegion.show(new Beef.ColourSettings.View({
                model: this.model,
                dashboardModel: this.options.originalModel.getDashboardModel()
            }));

            wordPicker.attach(this, ".word-choice", "word-choice");

            this.$('.' + this.model.get('scale')).addClass('active');
            this.$('.' + this.model.get('layout')).addClass('active');
            this.$('.hashtags').attr('checked', !!this.model.get('hashtags'));
            this.$('.atnames').attr('checked', !!this.model.get('atnames'));
            this.$('.group').attr('checked', !!this.model.get('group'));
            this.drawTags();
        },

        archimedean: function() {
            this.model.set('layout', 'archimedean');
        },

        rectangular: function() {
            this.model.set('layout', 'rectangular');
        },

        log: function() {
            this.model.set('scale', 'log');
        },

        square: function() {
            this.model.set('scale', 'square');
        },

        linear: function() {
            this.model.set('scale', 'linear');
        },

        hashtags: function() {
            this.model.set('hashtags', this.$('.hashtags').is(':checked'))
        },

        atnames: function() {
            this.model.set('atnames', this.$('.atnames').is(':checked'))
        },

        group: function () {
            this.model.set('group', this.$('.group').is(':checked'))
        },

        drawTags: function() {
            var words = this.model.get('remove-list'),
                tags = [],
                $wordList = this.$('.removed-word-list');

            if (words && words.length) {
                for (var i = 0; i < words.length; i++) {
                    tags.push("<span class='tag' data-value='" + words[i] + "'>" + words[i] +
                        "<button class='close' tabindex='-1'>&times;</button></span>");
                }
                $wordList.html(tags.join(''));
            } else {
                $wordList.html("(none, click words on chart to remove)");
            }
        },

        removeTag: function(e) {
            var word = $(e.target).parent().attr('data-value'),
                words = this.model.get('remove-list');

            this.model.set('remove-list', words.filter(function(w) { return w !== word; }));
            this.drawTags();
        }
    });



});

