<template>
    <add-profile-dialog class="profile-csv-upload-dialog"
                        :profile-type="profileTypes.csv"
                        :enable-close-btn="!readingProfiles"
                        :enable-prev-btn="!readingProfiles"
                        :enable-next-btn="enableNext"
                        :next-btn-text="nextBtnText"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-prev-btn="!saving && curStage > 1"
                        :show-next-btn="!saving"
                        @close="$emit('close')"
                        @prev="previous"
                        @next="next">
        <template #workflow>
            <div key="1" v-if="!saving && curStage === addProfileStages.csv.public.INSTRUCTIONS" class="profile-csv-upload-dialog__instructions">
                <h4>Instructions</h4>

                <p>
                    Upload a CSV of profiles to add to account {{ account.name }}. <span style="color: white">Note that
                    <i class="symbol-linkedin-rect" style="color: var(--colour-linkedin)"></i> LinkedIn profiles are not validated on upload,
                    only on authorization. So please ensure that the input handle is correct.</span>
                    Please make sure that the follow (case-sensitive) column headings are present in your CSV:
                </p>
                <h2>handle</h2>
                <p>
                    The handle of the profile. If a URL is supplied in an upload, we attempt to extract the handle from it.
                </p>
                <h2>handleId</h2>
                <p>
                    The handle ID of the profile.
                </p>
                <h2>type</h2>
                <p>
                    A type indicating the social medium. Supported values include FACEBOOK_PAGE, TWITTER_SCREEN_NAME,
                    YOUTUBE_CHANNEL, LINKEDIN_COMPANY, TELEGRAM_CHANNEL.
                </p>
                <h2>category</h2>
                <p>
                    Optional for upload. A (possibly empty) media category for the profile. Supported values include
                    CONSUMER, ENTERPRISE, PRESS, and DIRECTORY.
                </p>
            </div>

            <div key="2" v-if="!saving && curStage === addProfileStages.csv.public.IMPORT_PROFILES">
                <input type="file" ref="profilesCsv" style="display: none" @change="readFile">

                <h4>
                    <a :class="readingProfiles ? 'disabled' : ''" @click="$refs.profilesCsv.click()"><i
                    class="symbol-csv-doc"></i>Upload CSV</a>
                </h4>

                <loading-message style="margin-top: 10vh" v-if="readingProfiles"
                                 :message="`Reading profiles from ${fileName}...`"/>
                <div v-else-if="!showFacebookLogin">
                    <h2 :style="importStatus.type === 'WARN' ? 'color: var(--be-colour-warning)' : ''">
                        {{ importStatus.message }}</h2>

                    <p v-if="uploadedProfiles.length">
                        Select the profiles you would like to add to the account
                    </p>
                    <div class="profile-csv-upload-dialog__table-container dark-scrollbars dark-scrollbars--visible"
                         v-if="uploadedProfiles.length">
                        <table class="table table-condensed table-hover">
                            <thead>
                            <tr>
                                <th style="width: 5%" class="cell-checkbox">
                                    <input type="checkbox" :disabled="saving" v-model="selectAll"/>
                                </th>
                                <th>Profile</th>
                                <th>Category</th>
                                <th>Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="profile in uploadedProfiles"
                                :key="profile.handleId">
                                <td class="cell-checkbox"><input type="checkbox" v-model="profile.added"
                                                                 :disabled="!profile.canAdd"></td>
                                <td>
                                    <online-profile v-if="profile.name" :profile="profile"/>
                                </td>
                                <td>
                                    <span v-if="profile.category">{{ profile.category }}</span>
                                    <span v-else class="no-category">No category</span>
                                </td>
                                <td>
                                    <div :style="profile.status.type === 'INFO' ? 'color: #77b815' : 'color: var(--be-colour-warning)'" class="profile-csv-upload-dialog__status-message">
                                        <i :class="profile.status.type === 'INFO' ? 'symbol-checkmark' : 'symbol-warning'"></i>
                                        <span>
                                            {{ profile.status.message }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <fb-login v-else ref="fbLogin">
                    <template #loginMessage>
                        <p>
                            File {{ fileName }} contains Facebook pages. In order to import Facebook pages via CSV, you must authorise DataEQ to access the Facebook API on your behalf.
                        </p>
                    </template>
                </fb-login>
            </div>

            <saving-item key="3" v-if="saving" :save-complete="saveComplete" :saving-message="saveProgressMessage" :save-error="saveError" :save-error-message="saveErrorMessage"
                            save-complete-message="The selected profiles have been added to the account">
                <template #additionalActions>
                    <h4 style="text-align: center; margin-top: 50px">Click <a @click="reset">here</a> to import another CSV file</h4>
                </template>
            </saving-item>
        </template>
    </add-profile-dialog>
</template>

<script>
import VuexStore from "@/store/vuex/VuexStore";
import {isSupportedCategory, profileTypes} from "@/setup/profiles/ProfileUtils";
import {beef} from "@/store/Services";
import {mapActions, mapState} from "vuex";
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";
import LoadingMessage from "@/components/LoadingMessage";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import FbLogin from "@/setup/profiles/add-profile/facebook/FbLogin";
import SavingItem from "@/setup/SavingItem";

export default {
    name: "ProfileCsvUploadDialog",
    store: VuexStore,
    components: {SavingItem, FbLogin, AddProfileDialog, OnlineProfile, LoadingMessage},
    mixins: [addProfileMixins],

    data: function () {
        return {
            curStage: 1,
            totalStages: 1,
            selectAll: false,
            fileName: "",
            readerResult: "",
            readingProfiles: false,
            importStatus: {
                message: "No file chosen",
                type: "INFO"
            },
            uploadedProfiles: [],
            profilesToAdd: [],
            saveProgressMessage: "",
            showFacebookLogin: false
        }
    },

    async created() {
        this.totalStages = Object.keys(this.addProfileStages.csv.public).length;

        await this.initializeFbUserToken();
    },

    computed: {
        ...mapState(['account']),
        ...mapState('userPlatformAuth', ['facebookUserToken']),

        enableNext() {
            if (this.curStage === this.addProfileStages.csv.public.IMPORT_PROFILES) {
                // no errors, imported profiles, and at least one profile marked to be added
                return this.importStatus.type !== "WARN" && !!this.uploadedProfiles?.length && !!this.uploadedProfiles.find(profile => profile.added);
            }
            return true;
        },

        nextBtnText() {
            // if we are on the final stage, show "Save" instead of "Next"
            return this.curStage === this.totalStages - 1 ? "Save" : "Next";
        }
    },

    watch: {
        selectAll(value) {
            this.uploadedProfiles.forEach(profile => {
                if (profile.canAdd) {
                    profile.added = value;
                }
            });
        }
    },

    methods: {
        ...mapActions('profiles', ['createProfile']),
        ...mapActions('userPlatformAuth', ['initializeFbUserToken']),
        isSupportedCategory,

        readFile(event) {
            this.uploadedProfiles = []; // reset profile list
            let file = event.target.files[0];
            let csvFile = /csv.*/;

            if (file) {
                this.fileName = file.name;

                if (file.name.match(csvFile)) {
                    let reader = new FileReader();

                    reader.onload = () => {
                        this.readerResult = reader.result;
                        this.processData();
                    }

                    reader.readAsText(file);
                } else {
                    this.setWarnMessage(`Unable to read file: ${file.name} is not a CSV file.`);
                }
            }
        },

        getProfileStatus(profile) {
            let profileStatus = {};

            if (profile.existing) { // profile already exists in acc
                profileStatus = {
                    message: "Profile already exists on this account",
                    type: "WARN"
                }
            } else if (profile.message) { // failed to lookup profile with the given handle
                profileStatus = {
                    message: profile.message,
                    type: "WARN"
                }
            } else { // profile is able to be added to the account
                profileStatus = {
                    message: "Profile found successfully",
                    type: "INFO"
                }
            }

            return profileStatus;
        },

        /**
         * extract the handle from the handle from the handle lookup given in the CSV
         *
         * @param handleLookup
         * @returns {String|string|*} a handle ID that will be used to look up the profile
         */
        prepLookup(handleLookup) {
            handleLookup = handleLookup.split('?')[0];
            let parts = handleLookup.split('/');
            parts = parts.filter(part => part !== "");
            let lookup = parts[parts.length - 1];
            if (lookup && lookup.charAt(0) === '@') lookup = lookup.substring(1);
            if (parts.includes("www.facebook.com")) lookup = handleLookup; // Facebook seems to perform more robustly if given the whole url.
            return lookup;
        },

        async processData() {
            this.showFacebookLogin = false;

            try {
                this.readingProfiles = true;
                let payloadContainsFb = false;

                let csvData = d3.csvParse(this.readerResult);
                let payload = [];

                if (csvData.length === 0) {
                    this.setInfoMessage(`There are no profiles present in ${this.fileName}`);
                } else {
                    let requiredColumns = ['handle', 'handleId', 'type'];
                    for (const column of requiredColumns) {
                        if (csvData.columns.indexOf(column) === -1) {
                            this.setWarnMessage(`Required column "${column}" is not present in ${this.fileName}`);
                            return;
                        }
                    }

                    // create payload
                    for (const csvEntry of csvData) {
                        let category = null;

                        if (csvEntry.type === profileTypes.facebook) {
                            if (!this.facebookUserToken) {
                                this.showFbLogin();
                                return;
                            }

                            payloadContainsFb = true;
                        }

                        if (csvEntry.category) {
                            if (this.isSupportedCategory(csvEntry.category)) category = csvEntry.category.toUpperCase();
                        }

                        payload.push({handle: this.prepLookup(csvEntry.handle), handleId: csvEntry.handleId, category: category, type: csvEntry.type, accountCode: csvEntry.accountCode, onlineProfileId: csvEntry.onlineProfileId});
                    }

                    let response = null;
                    if (payloadContainsFb) {
                        response = await beef.post(`/api/online-profile/bulk?code=${this.account.code}&facebookAccessToken=${this.facebookUserToken}`, payload);
                    } else {
                        response = await beef.post(`/api/online-profile/bulk?code=${this.account.code}`, payload);
                    }

                    this.setInfoMessage(`Profiles found in ${this.fileName}`);

                    // set status for each profile
                    for (const profile of response.data) {
                        let status = this.getProfileStatus(profile);

                        let uploadedProfile = {
                            added: false,
                            name: profile.name ? profile.name : profile.handle,
                            handle: profile.handle,
                            handleId: profile.handleId,
                            category: profile.category,
                            pictureLink: profile.pictureLink,
                            type: profile.type,
                            status: status
                        }

                        uploadedProfile.canAdd = uploadedProfile.status.type !== "WARN"

                        this.uploadedProfiles.push(uploadedProfile);
                    }

                    this.uploadedProfiles.sort((a, b) => {
                        return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                    });
                }
            } catch (e) {
                this.setWarnMessage(`An error occurred while reading data from ${this.fileName}. Please reload and try again or contact DataEQ support.`);
            } finally {
                this.readingProfiles = false;
            }
        },

        async saveProfilesToAccount() {
            try {
                this.saving = true;
                this.saveError = false;

                let profilesToSave = this.uploadedProfiles.filter(profile => profile.added);

                for (const [i, profile] of profilesToSave.entries()) {
                    this.saveProgressMessage = `Saving profile ${i + 1} of ${profilesToSave.length}`;

                    let payload = {
                        handle: profile.handle,
                        handleId: profile.handleId,
                        name: profile.name,
                        type: profile.type,
                        media: profile.category,
                        pictureLink: profile.pictureLink
                    }

                    await this.createProfile(payload);
                }

                this.saveComplete = true;
            } catch (e) {
                console.error("An error occurred while importing profiles from CSV: ", e);
                this.saveError = true;
            }
        },

        showFbLogin() {
            this.showFacebookLogin = true;
            this.$nextTick(() => {
               this.$refs.fbLogin.$on('fb-login-complete', () => {
                   this.processData();
               });
            });
        },

        setInfoMessage(message) {
            this.importStatus.message = message;
            this.importStatus.type = "INFO"
        },

        setWarnMessage(message) {
            this.importStatus.message = message;
            this.importStatus.type = "WARN"
        },

        next() {
            this.curStage++;
            if (this.curStage === this.totalStages)  this.saveProfilesToAccount();
        },

        previous() {
            this.curStage--;

            if (this.curStage === 0) {
                this.curStage = 1;
            }
        },

        reset() {
            this.curStage = this.addProfileStages.csv.public.IMPORT_PROFILES;
            this.saveComplete = false;
            this.saving = false;
            this.uploadedProfiles = [];
            this.selectAll = false;
            this.fileName = "";
            this.readerResult = "";
            this.readingProfiles = false;
            this.showFacebookLogin = false;
            this.importStatus = {
                message: "No file chosen",
                type: "INFO"
            }
            this.profilesToAdd = [];
            this.saveProgressMessage = "";
        }
    }
}
</script>

<style scoped lang="sass">

.profile-csv-upload-dialog
    p
        color: var(--be-colour-text-dark)

    h4
        margin-bottom: 40px

    a
        cursor: pointer

    &__instructions
        h2
            margin: 0

    &__table-container
        width: 100%
        height: 360px
        padding-right: 5px
        overflow-y: auto

        table
            border: none
            background: var(--body-background-colour)

        th
            border: 1px solid #555
            color: white
            text-align: center


        td
            border: 1px solid #272727
            background: #333333
            vertical-align: middle

            span

                &.no-category
                    color: var(--be-colour-text-dark)
                    font-size: 0.9em

        .cell-checkbox
            text-align: center
            vertical-align: middle

            input
                margin: 0
                transform: scale(1.2)

    &__status-message
        display: flex
        align-items: center

        i
            margin-right: 10px

    &__btn-bar
        display: flex
        margin: 10px -20px -20px
        justify-content: flex-end
        column-gap: 10px
        padding: 10px 20px
        border-top: 1px solid #272727
        background: #333333
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px

.facebook-login

    &__btn-bar
        margin-top: 50px
        display: flex
        justify-content: flex-end

        & > .fb-login-btn
            ::v-deep .btn
                background: var(--colour-facebook)

            &:hover
                filter: brightness(1.2)

.disabled
    color: #777777
    pointer-events: none

.slide-next-enter-active,
.slide-previous-enter-active
    transition: all .25s ease

.slide-next-leave-active,
.slide-previous-leave-active
    transition: none
    opacity: 0

.slide-next-enter
    transform: translateX(30px)
    opacity: 0

.slide-previous-enter
    transform: translateX(-30px)
    opacity: 0

</style>