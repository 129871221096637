Beef.module("AuthorListV4").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/authorsV4/AuthorListV4.handlebars"),

        attributes: { class: 'author-list-v4' },

        modelEvents: {
            'change': 'render'
        },

        events: {
            "click .author-table thead td": "columnClicked",
            "click .author-table tbody .mentions": "mentionsClicked",
            "click .author-table .avatar": "avatarClicked",
            "mouseenter .author-table .avatar": "showAuthorPopup",
            "mouseout .author-table .avatar": "hideAuthorPopup"
        },

        initialize: function(options) {
        },

        onRender: function() {
            var orderBy = this.model.get('orderBy');
            var i = orderBy.indexOf(' desc');
            var desc = i > 0;
            if (desc) orderBy = orderBy.substring(0, i);
            var $td = this.$('td[data-order-by="' + orderBy + '"]');
            $td.toggleClass("sorted");
            $td.append('<i class="icon-' + (desc ? "down" : "up") +'"></i>');
        },

        columnClicked: function(ev) {
            ev.preventDefault();
            var orderBy = $(ev.target).closest("td").attr("data-order-by");
            if (orderBy) {
                var current = this.model.get('orderBy');
                if (current.indexOf(orderBy) == 0) {
                    if (current.indexOf(' desc') < 0) orderBy += " desc";
                } else {
                    orderBy += " desc"
                }
                this.model.set('orderBy', orderBy);
            }
        },

        mentionsClicked: function(ev) {
            if (ev.which > 1 || ev.button == 4 || ev.metaKey) return;   // middle or right or ctrl click
            ev.preventDefault();
            var url = $(ev.target).closest("a").attr("href");
            if (url) Beef.router.navigate(url, {trigger: true});
        },

        avatarClicked: function(ev) {
            ev.preventDefault();
            this.showAuthorPopup(ev, true);
        },

        showAuthorPopup: function(ev, toggle) {
            var i = parseInt($(ev.target).closest("tr").attr("data-index"));
            var row = this.model.get('authors')[i];

            if (toggle && this._authorInfoPopup && this._authorInfoPopup._rowId === row.mostRecentMention.id) {
                this.hideAuthorPopup();
                return;
            }

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions:["right"],
                offsets: { top: 16 },
                alwaysMove: true
            });

            var model = new Backbone.Model(row.mostRecentMention);
            var view = new Beef.AuthorItemV4.View({model: model});
            var that = this;
            popup.setTarget($(ev.target));
            popup.show(view);
            popup._rowId = row.mostRecentMention.id;
            this._authorInfoPopup = popup;
            return popup;
        },

        hideAuthorPopup: function() {
            if (this._authorInfoPopup) {
                this._authorInfoPopup.hide();
                this._authorInfoPopup = null;
            }
        }
    });
});