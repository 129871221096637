var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"deleted\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "2";
},"5":function(container,depth0,helpers,partials,data) {
    return "1";
},"7":function(container,depth0,helpers,partials,data) {
    return " edit-phrase";
},"9":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-reply\"></span>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inactive") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":58},"end":{"line":7,"column":103}}})) != null ? stack1 : "")
    + ">";
},"12":function(container,depth0,helpers,partials,data) {
    return "checked=\"true\"";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"icon-filter\" title=\""
    + alias2(__default(require("../../helpers/formatConversationFeedFilter.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mentionFilter") : depth0),{"name":"formatConversationFeedFilter","hash":{},"data":data,"loc":{"start":{"line":10,"column":70},"end":{"line":10,"column":116}}}))
    + ": "
    + alias2(__default(require("../../helpers/formatConversationFilter.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mentionFilter") : depth0),{"name":"formatConversationFilter","hash":{},"data":data,"loc":{"start":{"line":10,"column":118},"end":{"line":10,"column":160}}}))
    + "\"></span>";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"icon-comment-empty\" title=\"This phrase only matches for certain social networks\"></span>\n                    <span>"
    + ((stack1 = __default(require("../../helpers/formatSocialNetworksIconList.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"socialNetworks") : depth0),{"name":"formatSocialNetworksIconList","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":75}}})) != null ? stack1 : "")
    + "</span>";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"icon-comment-1\" title=\"Feeds: "
    + container.escapeExpression(__default(require("../../helpers/formatFeedsList.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"feeds") : depth0),{"name":"formatFeedsList","hash":{},"data":data,"loc":{"start":{"line":13,"column":72},"end":{"line":13,"column":97}}}))
    + "\"></span>";
},"20":function(container,depth0,helpers,partials,data) {
    return "<span class=\"icon-clock approval-pending\" title=\"Phrase is inactive pending approval\"></span>";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"edit-phrase-pencil edit-phrase\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":51},"end":{"line":19,"column":89}}})) != null ? stack1 : "")
    + ">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":60}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":69}}})) != null ? stack1 : "")
    + "\n        </td>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "title=\"Undelete\"";
},"25":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-reply\"></i>";
},"27":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-pencil\"></i>";
},"29":function(container,depth0,helpers,partials,data) {
    return "        <td></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-phrase=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":62}}})) != null ? stack1 : "")
    + ">\n    <td colspan=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"_parent") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":58}}})) != null ? stack1 : "")
    + "\"\n            class=\"phrase"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"_parent") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":68}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"inner\">\n            <label>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":70}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":115}}})) != null ? stack1 : "")
    + "\n            </label>\n            <div class=\"q\">"
    + alias1(__default(require("../../helpers/renderPhrase.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"q") : depth0),{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":45}}}))
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"mentionFilter") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":176}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"socialNetworks") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":12,"column":89}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"feeds") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":113}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"approved") : depth0),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":140}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"_parent") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./BrandPhraseVolume.handlebars"),depth0,{"name":"./BrandPhraseVolume","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true});