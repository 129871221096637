<template>
    <div class="be-selector-menu">
        <slot/>
    </div>
</template>

<script>
export default {

}
</script>



<style scoped lang="sass">

.be-selector-menu
    background: #222   // Same colour as the menus: allows extra menus to fade in without a flash of odd background.

</style>
