<template>
    <div>
        <div class="profile-list dark-scrollbars dark-scrollbars--visible">
            <div class="row-fluid online-presence-list">
                <div class="online-presence-table">
                    <div v-if="!hasProfilesToShow">
                        <h2>No profiles to show</h2>
                    </div>
                    <div v-for="agg in aggregate" :key="agg.id" class="profile-rows">
                        <h3 class="brand-name">{{ agg.name }}</h3>

                        <div v-if="agg.supportProfiles.length > 0">
                            <div class="brand-profiles-label">Support Profiles</div>

                            <PpOnlineProfile v-for="profile in agg.supportProfiles"
                                             :key="profile.id"
                                             :profile="profile"
                                             :allow-negation="allowNegation"
                                             :isProfileSelected="isProfileSelected(profile)"
                                             :isProfileExcluded="isProfileExcluded(profile)"
                                             @selected="updateSelection(profile)"
                                             @negated="updateSelectionWithNegation(profile)"
                            ></PpOnlineProfile>
                        </div>

                        <div v-if="agg.otherProfiles.length > 0">
                            <div class="brand-profiles-label">Other Profiles</div>
                            <PpOnlineProfile v-for="profile in agg.otherProfiles"
                                             :key="profile.id"
                                             :profile="profile"
                                             :allow-negation="allowNegation"
                                             :isProfileSelected="isProfileSelected(profile)"
                                             :isProfileExcluded="isProfileExcluded(profile)"
                                             @selected="updateSelection(profile)"
                                             @negated="updateSelectionWithNegation(profile)"
                            ></PpOnlineProfile>
                        </div>

                    </div>

                    <div class="rows">
                        <h3 v-if="hasProfilesSet">Uncategorized</h3>
                        <PpOnlineProfile v-for="profile in remaining"
                                         :key="profile.id"
                                         :profile="profile"
                                         :allow-negation="allowNegation"
                                         :isProfileSelected="isProfileSelected(profile)"
                                         :isProfileExcluded="isProfileExcluded(profile)"
                                         @selected="updateSelection(profile)"
                                         @negated="updateSelectionWithNegation(profile)"
                        ></PpOnlineProfile>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VuexStore from "@/store/vuex/VuexStore";
import {getProfileIcon} from "@/app/utils/Profiles";
import {mapState} from "vuex";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile.vue";
import PpOnlineProfile from "@/components/pickers/profiles/PpOnlineProfile.vue";

export default {
    name: "NewProfilePicker",
    components: {PpOnlineProfile},
    store: VuexStore,

    props: {
        profiles: [],
        selected: Array,
        searchTerm: String,
        profileOptions: [],
        allowNegation: Boolean
    },

    model: {
        prop: 'selected',
        event: 'selected'
    },

    data() {
        return {}
    },

    methods: {
        getProfileIcon,

        updateSelection(profile) {
            let selectedClone = JSON.parse(JSON.stringify(this.selected));

            if (selectedClone.some(p => Math.abs(p) === profile.id)) {
                if (this.isProfileExcluded(profile)) {
                    selectedClone = selectedClone.filter(p => Math.abs(p) !== profile.id)
                    selectedClone.push(profile.id);
                } else {
                    selectedClone = selectedClone.filter(p => Math.abs(p) !== profile.id)
                }
            } else {
                selectedClone.push(profile.id);
            }

            this.$emit('selected', selectedClone);
        },

        updateSelectionWithNegation(profile) {
            let selectedClone = JSON.parse(JSON.stringify(this.selected));

            if (selectedClone.some(p => Math.abs(p) === profile.id)) {
                if (!this.isProfileExcluded(profile)) {
                    selectedClone = selectedClone.filter(p => Math.abs(p) !== profile.id)
                    selectedClone.push(profile.id * -1);
                } else {
                    selectedClone = selectedClone.filter(p => Math.abs(p) !== profile.id)
                }
            } else {
                selectedClone.push(profile.id * -1);
            }

            this.$emit('selected', selectedClone);
        },

        isProfileSelected(profile) {
            return this.selectedProfiles.some(p => Math.abs(p) === profile.id);
        },

        isProfileExcluded(profile) {
            return this.selected.filter(profile => profile < 0).some(p => Math.abs(p) === profile.id)
        },

        shouldBeVisible(profile) {
            if (this.searchTerm === '') return true;
            return profile.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                profile.handle.toLowerCase().includes(this.searchTerm.toLowerCase());
        },

    },

    watch: {
        profileOptions() {
            this.visibleProfiles = this.visibleProfiles
                .filter(profile => this.profileOptions.find(p => profile.type === p.type).value)
        }
    },

    computed: {
        ...mapState(['rootBrands']),

        aggregate() {
            return this.rootBrands.map((brand) => {
                return {
                    id: brand.id,
                    name: brand.name,
                    supportProfiles: brand.supportProfileIds ? brand.supportProfileIds
                        .map(id => this.visibleProfiles.find(profile => profile?.id === id))
                        .filter(p => p !== undefined) : [],

                    otherProfiles: brand.otherProfileIds ? brand.otherProfileIds
                        .map(id => this.visibleProfiles.find(profile => profile?.id === id))
                        .filter(p => p !== undefined) : [],
                }
            }).filter(agg => agg.supportProfiles.length > 0 || agg.otherProfiles.length > 0);
        },

        remaining() {
            const profilesWithBrand = this.aggregate
                .flatMap(a => [...a.supportProfiles, ...a.otherProfiles])
            return this.visibleProfiles
                .filter(profile => !profilesWithBrand
                    .some(p => p?.id === profile.id)
                );
        },

        hasProfilesSet() {
            return this.aggregate
                .reduce((acc, a) => a.supportProfiles.length > 0 || a.otherProfiles.length > 0 || acc, false)
        },

        hasProfilesToShow() {
            return this.aggregate.flatMap(agg => [...agg.supportProfiles, ...agg.otherProfiles]).length + this.remaining.length > 0;
        },

        visibleProfiles: {
            get() {
                return this.profiles
                    .filter(profile => (this.profileOptions.find(opt => opt.type === "AUTH").value) ? profile.authorized : true)
                    .filter(profile => (this.profileOptions.find(opt => opt.type === "UNAUTH").value) ? !profile.authorized : true)
                    .filter(profile => (this.profileOptions.reduce((acc, a) => a.value || acc, false) &&
                        !(this.profileOptions.find(opt => opt.type === "AUTH").value) && !(this.profileOptions.find(opt => opt.type === "UNAUTH").value)) ?
                        this.profileOptions.find(p => profile.type === p.type).value : true)
                    .filter(p => this.shouldBeVisible(p));
            },
            set(newValue) {

            }

        },

        selectedProfiles() {
            return this.selected;
        },

    },

}
</script>

<style scoped>
.brand-profiles-label {
    color: var(--heading-colour-light);
}

.online-presence-table {
    background-color: var(--body-background-colour);
}

</style>