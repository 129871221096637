import VuexStore from "@/store/vuex/VuexStore";
import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import {parseFilterString} from "@/dashboards/filter/FilterParser";

export const ruleTypes = {
    edit: "EDIT_MENTIONS",
    tag: "TAG_MENTIONS",
    delete: "DELETE_MENTIONS",
    set_relevant: "SET_RELEVANT",
    crowd: "CROWD_VERIFICATION",
    engage: "ENGAGE_PROCESSING",
    create_crowd_job: "CREATE_CROWD_JOB"
};

export const rulePrettyActions = {
    edit: "Edit mentions",
    tag: "Tag mentions",
    delete: "Send to trash",
    relevant: "Do not automatically delete",
    crowd: "Verify through the Crowd",
    engage: "Engage",
    create_crowd_job: "Create Crowd job"
}

export function getRuleIcon(type) {
    switch (type) {
        case ruleTypes.edit:
            return 'symbol-edit';
        case ruleTypes.tag:
            return 'symbol-tags';
        case ruleTypes.delete:
            return 'symbol-sorter';
        case ruleTypes.set_relevant:
            return 'symbol-checkmark';
        case ruleTypes.crowd:
        case ruleTypes.create_crowd_job:
            return 'symbol-crowd';
        case ruleTypes.engage:
            return 'icon-equalizer';
        default:
            return 'symbol-rules';
    }
}

/**
 * Extracts tags from rule attributes for rules with the "MACRO" action
 *
 * @param rule
 * @returns {*[]} list of tags
 */
export function getRuleTags(rule) {
    if (!rule) return [];

    let tags = [];
    const TAG_ID_REGEX = /^tag\s+=\s+([0-9]+)$/
    const idToTag = VuexStore.getters.idToTag;

    if (rule.action !== "MACRO") return [];

    let attributes = rule.attributes.split(",");
    attributes.forEach(attribute => {
        let matches = attribute.match(TAG_ID_REGEX);
        if (matches) {
            let tagId = parseInt(matches[1]);
            let tag = idToTag.get(tagId);
            if (tag) tags.push(tag);
        }
    });

    tags = tags.sort((a, b) => a < b ? 1 : -1)

    return tags;
}

/**
 * Returns new rule priority based on the rule's current index, and the new index that the rule wants to move to
 *
 * @param list list of rules
 * @param currentIndex current index of the rule relative to the given list
 * @param newIndex index that the rule should be moved to relative to the given list
 * @returns {null|*} the new priority if it can be resolved, null otherwise
 */
export function getNewRulePriority(list, currentIndex, newIndex) {
    if (!list || !list.length) return null;

    let newPriority = null;

    if (currentIndex === newIndex) return list.at(currentIndex)?.priority;

    if (currentIndex > newIndex) { // move up
        let p1 = newIndex - 1 >= 0 ? list.at(newIndex - 1).priority : 0;
        let p2 = list.at(newIndex).priority;

        newPriority = (p1 + p2) / 2;
    } else if (currentIndex < newIndex) { // move down
        let p1 = newIndex + 1 <= list.length - 1  ? list.at(newIndex + 1).priority : list.at(newIndex).priority + 1;
        let p2 = list.at(newIndex).priority;

        newPriority = (p1 + p2) / 2;
    }

    return newPriority;
}

export function getRuleType(prettyAction) {
    if (prettyAction.includes("Engage")) return ruleTypes.engage;

    switch (prettyAction) {
        case rulePrettyActions.edit:
            return ruleTypes.edit;
        case rulePrettyActions.tag:
            return ruleTypes.tag;
        case rulePrettyActions.crowd:
            return ruleTypes.crowd;
        case rulePrettyActions.delete:
            return ruleTypes.delete;
        case rulePrettyActions.relevant:
            return ruleTypes.set_relevant;
        default:
            return "unknown"
    }
}

export function getRulePrettyAction(rule, tags) {
    if (!rule) return "";

    let prettyAction = "";
    let messages = [];

    switch (rule.action) {
        case "ENGAGEMENT_CONSOLE":
            if (rule.data?.send) {
                if (rule.data.send === "YES") messages.push("Send to Engage");
                else if (rule.data.send === "NO") messages.push("Do not send to Engage");
                else messages.push("send=" + rule.data.send);
            }
            if (rule.data.teamId) messages.push(`For team ${rule.data.teamName ? rule.data.teamName : rule.data.teamId}`);
            if (rule.data.priority) messages.push(`Priority is ${rule.data.priority}`);
            prettyAction = "Engage: " + messages.join(", ");
            break;
        case "DISCARD":
            prettyAction = rulePrettyActions.delete;
            break;
        case "CROWD":
            prettyAction = rulePrettyActions.crowd;
            break;
        case "CREATE_CROWD_JOB":
            prettyAction = rulePrettyActions.create_crowd_job;
            break;
        case "MACRO":
            tags = tags ? tags : getRuleTags(rule);

            if (tags.length && (rule.attributes?.split(",")?.length === tags.length)) {
                prettyAction = rulePrettyActions.tag;
            } else {
                if (rule.attributes?.toLowerCase()?.trim() === 'relevancy = relevant') {
                    prettyAction = rulePrettyActions.relevant;
                } else {
                    prettyAction = rulePrettyActions.edit;
                }
            }
            break;
        case "RELEVANT":
            if (rule.attributes?.toLowerCase()?.trim() === 'relevancy = relevant') {
                prettyAction = rulePrettyActions.relevant;
            } else {
                prettyAction = rulePrettyActions.edit;
            }
            break;
    }

    return prettyAction;
}

const unsupportedFilterAttributes = [
    {
        invalidAttributes: ["seed", "proportion"],
        warningMessage: "Sampling is not supported in rule filters"
    },
    {
        invalidAttributes: ["reshareCount"],
        warningMessage: "Reshare count is not supported in rule filters"
    },
    {
        invalidAttributes: ["replyCount"],
        warningMessage: "Reply count is not supported in rule filters"
    },
    {
        invalidAttributes: ["responseTime"],
        warningMessage: "Response time is not supported in rule filters"
    },
    {
        invalidAttributes: ["replyOrderByAuthor"],
        warningMessage: "Reply order by author is not supported in rule filters"
    },
    {
        invalidAttributes: ["engagement"],
        warningMessage: "Engagement is not supported in rule filters"
    },
    {
        invalidAttributes: ["reshareOf"],
        validValues: ["unknown"],
        warningMessage: "Reshare of is only applicable for 'unknown' values in rule filters"
    },
    {
        invalidAttributes: ["replyTo"],
        validValues: ["unknown"],
        warningMessage: "Reply to is only applicable for 'unknown' values in rule filters"
    },
    {
        invalidAttributes: ["conversationId"],
        warningMessage: "Conversation is not supported in rule filters"
    },
    {
        invalidAttributes: ["interactionId", "interactionHasResponse", "interactionResponseTime", "interactionWhResponseTime", "interactionIdList"],
        warningMessage: "Interaction is not supported in rule filters"
    }
]

/**
 * Returns a list of warnings for a rule. Currently only checks if a rule has a valid filter.
 *
 * @param rule
 * @returns {any[]|null}
 */
export function getRuleWarnings(rule) {
    if (!rule) return null;

    let warnings = [];

    try {
        // get rule filter warnings
        warnings = getRuleFilterWarnings(rule);
    } catch (e) {
        console.warn(`Unable to get rule warnings for rule ${rule?.id}: `, e);
    }

    return warnings;
}

export function getRuleFilterWarnings(rule) {
    let warnings = [];
    if (!rule || !rule?.filter) return warnings;

    // check if rule filter parses
    try {
        parseFilterString(rule.filter);
    } catch (e) {
        // parse exception thrown
        warnings.push({warningMessage: "Filter is invalid", ruleId: rule.id, type: "FILTER_WARNING"});
        return warnings;
    }

    let attributes = {};
    try {
        attributes = convertFilterToAttrs(rule.filter);
    } catch (e) {
        warnings.push({warningMessage: "Filter is invalid", ruleId: rule.id, type: "FILTER_WARNING"});
        return warnings;
    }

    for (const key of Object.keys(attributes)) {
        let attribute = attributes[key];
        let validAttribute = false;

        // only check non null attributes of filter
        if (attribute) {
            unsupportedFilterAttributes.forEach(attr => {
                if (attr.invalidAttributes.includes(key)) {
                    // check if rule warning has edge case values (values that are allowed, e.g. reshareOf is 'unknown' is allowed but reshareOf is '<mention id>' is not)
                    if (attr.validValues) {
                        attr.validValues.forEach(validValue => {
                            if (validValue === attribute.toLowerCase() || `-${validValue}` === attribute.toLowerCase()) {
                                validAttribute = true;
                            }
                        });
                    }

                    if (!validAttribute) {
                        let existingWarning = warnings.find(w => w.type === "FILTER_WARNING" && w.warningMessage === attr.warningMessage);
                        if (!existingWarning) warnings.push({...attr, ruleId: rule.id, type: "FILTER_WARNING"});
                    }
                }
            });
        }
    }

    return warnings;
}

export const JobTypes = [
    { "id": "RATING", "name": "Rating" },
    { "id": "TOPIC_LIST", "name": "Classification" },
    { "id": "TOPIC_TREE", "name": "Topic assignment" },
    { "id": "SEGMENTATION", "name": "Segmentation" }
]

export const JobTypesById = { }

for (let t of JobTypes) JobTypesById[t.id] = t