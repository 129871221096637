import {CommandEl} from "@/dashboards/widgets/commentaryplus/customHtmlElements/CommandEl";

export class FontSizeEl extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback(){
        this.style.fontSize = this.dataset.size;
    }

}

customElements.define("font-size", FontSizeEl);
