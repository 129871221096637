var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit the historical search";
},"3":function(container,depth0,helpers,partials,data) {
    return "Add a search";
},"5":function(container,depth0,helpers,partials,data) {
    return "active";
},"7":function(container,depth0,helpers,partials,data) {
    return "<a class=\"btn delete pull-left\" title=\"Delete this search\">Delete</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"job") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + "</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"row-fluid\">\n        <div class=\"main-form\">\n            <div class=\"control-group\">\n                <div class=\"controls\">\n                    <input dir=\"auto\" type=\"text\" name=\"name\" class=\"span12\" placeholder=\"Enter a name for this search\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"root-brand-filter-region control-group-spacer\"></div>\n\n        <div class=\"control-group control-group-spacer hide-this-too\">\n            <label class=\"control-group-colour\">I am only interested in these tweets</label>\n            <div class=\"controls btn-group retweet-rule\">\n                <button class=\"btn "
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"retweetRule") : depth0),"===","TWEETS_AND_RETWEETS",{"name":"ifcond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":35},"end":{"line":22,"column":103}}})) != null ? stack1 : "")
    + "\"\n                        data-retweet-rule=\"TWEETS_AND_RETWEETS\"\n                        title=\"Include tweets and retweets\">All</button>\n                <button class=\"btn "
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"retweetRule") : depth0),"===","ONLY_TWEETS",{"name":"ifcond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":95}}})) != null ? stack1 : "")
    + "\"\n                        data-retweet-rule=\"ONLY_TWEETS\"\n                        title=\"Include tweets and exclude retweets\">Tweets</button>\n                <button class=\"btn "
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"retweetRule") : depth0),"===","ONLY_RETWEETS",{"name":"ifcond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":35},"end":{"line":28,"column":97}}})) != null ? stack1 : "")
    + "\"\n                        data-retweet-rule=\"ONLY_RETWEETS\"\n                        title=\"Include retweets and exclude tweets\">Retweets</button>\n            </div>\n        </div>\n\n    </div>\n\n    <div class=\"dialog-button-bar\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"job") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":37,"column":95}}})) != null ? stack1 : "")
    + "\n        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n</div>\n";
},"useData":true});