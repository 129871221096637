import Vue from "vue";

/**
 * Provides a way to dynamically pop up a dedicated dialog component
 * (i.e., one that ultimately uses <dialog-box />
 *
 * The DialogComponent needs to emit a 'close' or 'input' false event.
 */
export function showDialogComponent(DialogComponent, props) {
    const element = document.body.appendChild(document.createElement("DIV"));

    const ComponentClass = Vue.extend(DialogComponent);
    const instance = new ComponentClass({propsData: props || {}});

    let nuke = () => {
        instance.$destroy();
        instance.$el.remove();
    };
    instance.$on('close', nuke);
    instance.$on('input', v => {
        if (!v) nuke();
    });
    instance.$mount(element);
    instance._closeDialog = nuke;
    return instance;
}

/** how many popups have been opened, so that we can keep track of them. */
let count = 1;

export function getDialogId() {
    return count;
}

export function incrementDialogCount() {
    return count++;
}

export function registerDialog(id) {
    if (id === undefined) id = ++count;
    // let's try to figure out the scroll bar size, so that we can stop the
    // page from shifting when we disable scrolling.
    let documentWidth = document.documentElement.clientWidth;
    let windowWidth = window.innerWidth;
    let scrollBarWidth = windowWidth - documentWidth;
    document.documentElement.style.setProperty('--popup-scrollbar-width', scrollBarWidth + "px");

    const body = document.body;
    let dialogs = new Set((body.dataset.dialogs && body.dataset.dialogs.split(' ')) || []);
    dialogs.add("" + id);
    body.dataset.dialogs = Array.from(dialogs).join(' ');

    return count;
}

export function unregisterDialog(id) {
    const body = document.body;
    let dialogs = new Set((body.dataset.dialogs && body.dataset.dialogs.split(' ')) || []);
    dialogs.delete("" + id);
    if (dialogs.size) {
        body.dataset.dialogs = Array.from(dialogs).join(' ');
    } else {
        delete body.dataset.dialogs;
    }
}

/**
 * Returns true iff there are no popups / dialogs open
 * @return {boolean}
 */
export function areAnyPopupsVisible() {
    return !!document.querySelector("body > ul.popup") || !!document.querySelector(".dialog");
}