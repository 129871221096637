<template>
    <add-rule-dialog class="add-edit-rule"
                     @close="$emit('close')"
                     @next="next" @prev="prev"
                     :rule-type="ruleTypes.create_crowd_job"
                     :total-stages="totalStages"
                     :cur-stage="curStage"
                     :next-btn-text="nextBtnText"
                     :show-next-btn="showNextBtn"
                     :show-prev-btn="showPrevBtn"
                     :enable-next-btn="enableNextBtn">
        <template #workflow>
            <div key="1" v-if="curStage === addRuleStages.create_crowd_job.CHOOSE_JOB_TYPE">
                <text-input class="add-edit-rule__text-input" v-model="ruleName" @valid="setValidRuleName" required label="Rule name" ref="name" placeholder="Rule name or description"/>

                <p>Create an additional crowd job when a mention matching the filter enters the account or comes
                back from The Crowd. This is in addition to whatever jobs are created as a result of normal crowd
                sampling rates and standard crowd job creation rules.</p>

                <div>
                    <div class="row-fluid">
                        <div class="span4">
                            <div class="control-group">
                                <label>Job Type</label>
                                <div class="controls">
                                    <drop-down-input class="drop-down" :options="jobTypeOptions" v-model="jobType"></drop-down-input>
                                </div>
                            </div>
                        </div>
                        <div class="span8">
                            <div class="control-group" v-if="jobType === 'SEGMENTATION'">
                                <label>Segment List</label>
                                <div class="controls">
                                    <drop-down-input class="drop-down" :options="segmentListOptions" v-model="segmentListId"></drop-down-input>
                                </div>
                            </div>
                            <div class="control-group" v-if="jobType === 'TOPIC_TREE'">
                                <label>Topic Tree</label>
                                <div class="controls">
                                    <drop-down-input class="drop-down" :options="topicTreeOptions" v-model="topicTreeId"></drop-down-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-fluid" style="margin-top: 10px">
                        <div class="span4">
                            <div class="control-group">
                                <label>Crowd</label>
                                <div class="controls">
                                    <drop-down-input class="drop-down" :options="crowdOptions" v-model="crowdId" optional></drop-down-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div key="2" v-if="curStage === addRuleStages.create_crowd_job.EDIT_FILTER">
                <h4>Edit the filter</h4>
                <p>This rule will be applied to mentions that match this filter.</p>
                <filter-editor class="add-edit-rule__filter-editor" @input="onFilterChange" :value="ruleFilter" for-rule/>
            </div>

            <div key="3" v-if="curStage === addRuleStages.create_crowd_job.EXAMPLE_MENTIONS">
                <h4>Example mentions</h4>
                <p>This rule will be applied to all future mentions like this.</p>
                <div class="add-edit-rule__preview-mentions">
                    <preview-mentions :filter="ruleFilter"></preview-mentions>
                </div>
            </div>

            <div key="4" v-if="curStage === addRuleStages.create_crowd_job.SAVE">
                <saving-item :save-complete="saveComplete" :save-error="saveError" :saving-message="saveMessage" :save-complete-message="saveCompleteMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more <i class="symbol-edit"></i> Edit rules</h4>
                    </template>
                </saving-item>
            </div>
        </template>
    </add-rule-dialog>
</template>

<script>
import AddRuleDialog from "@/setup/rules/add-rule/AddRuleDialog";
import {ruleTypes, JobTypes} from "@/setup/rules/RuleUtils";
import {addRuleMixins} from "@/setup/rules/add-rule/AddRuleMixins";
import TextInput from "@/components/inputs/TextInput";
import FilterEditor from "@/components/inputs/FilterEditor";
import PreviewMentions from "@/components/PreviewMentions";
import SavingItem from "@/setup/SavingItem";
import VuexStore from "@/store/vuex/VuexStore";
import DropDownInput from "@/components/inputs/DropDownInput.vue"
import {getAllSegmentLists, getAllTopicTrees} from "@/app/utils/Segments"

export default {
    name: "AddCreateCrowdJobRule",
    components: {
        SavingItem, PreviewMentions, FilterEditor, TextInput, DropDownInput, AddRuleDialog},
    mixins: [addRuleMixins],
    store: VuexStore,

    data() {
        return {
            ruleTypes: ruleTypes,
            totalStages: 1,
            curStage: 1,
            ruleName: "",
            ruleNameValid: true,
            ruleFilter: "",

            jobType: "",
            segmentListId: "",
            topicTreeId: "",
            crowdId: "",
            crowdPriority: "NORMAL",

            jobTypeOptions: JobTypes,
            segmentListOptions: [],
            topicTreeOptions: [],
            crowdOptions: []
        }
    },

    mounted() {
        getAllSegmentLists().then(list => {
            this.segmentListOptions = list.filter(s => !s.global).map(s => {
                return {
                    id: s.id,
                    name: `${s.name} » ${s.subtitle } (${s.id})`,
                    description: s.description
                }
            })
        })
        getAllTopicTrees().then(list => {
            this.topicTreeOptions = list.map(t => {
                return {
                    id: t.id,
                    name: `${t.name} (${t.id})`,
                    description: t.description
                }
            })
        })
        getAllCrowds().then(list => this.crowdOptions = list)
        //getAllCrowds().then(list => this.crowdOptions = [{id:"",name:'(auto)'}].concat(list))
    },

    created() {
        this.totalStages = Object.keys(this.addRuleStages.create_crowd_job).length;
    },

    computed: {
        enableNextBtn() {
            switch (this.curStage) {
                case this.addRuleStages.create_crowd_job.CHOOSE_JOB_TYPE:
                    if (!this.ruleNameValid) return false
                    if (!this.jobType) return false
                    switch (this.jobType) {
                        case 'SEGMENTATION':    return !!this.segmentListId
                        case 'TOPIC_TREE':      return !!this.topicTreeId
                    }
                    return true
                case this.addRuleStages.create_crowd_job.EDIT_FILTER:
                    return !!this.ruleFilter;
                case this.addRuleStages.create_crowd_job.SAVE:
                    return false;
                default:
                    return true;
            }
        },

        showNextBtn() {
            return this.curStage !== this.addRuleStages.create_crowd_job.SAVE;
        },

        showPrevBtn() {
            return this.curStage > 1 && this.curStage !== this.addRuleStages.create_crowd_job.SAVE;
        },

        nextBtnText() {
            return this.curStage !== this.totalStages - 1  ? "Next" : "Save Rule";
        }
    },

    methods: {
        setValidRuleName(value) {
            this.ruleNameValid = value;
        },

        onAttributeChange(changeData) {
            this.attributes = changeData;
        },

        onFilterChange(changeData) {
            this.ruleFilter = changeData;
        },

        next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                let payload = {
                    action: "CREATE_CROWD_JOB",
                    active: true,
                    filter: this.ruleFilter,
                    name: this.ruleName,
                    data: {
                        jobType: this.jobType,
                        segmentListId: this.segmentListId ? this.segmentListId : null,
                        topicTreeId: this.topicTreeId ? this.topicTreeId : null,
                        crowdPriority: this.crowdPriority ? this.crowdPriority : null,
                        crowdId: this.crowdId ? this.crowdId : null,
                        doNotCreateDerivedJobs: true
                    }
                }

                this.saveRule(payload);
            }
        },

        prev() {
            this.curStage--;
        },

        reset() {
            this.curStage = 1;
            this.ruleName = "";
            this.ruleNameValid = true;
            this.attributes = "";
            this.ruleFilter = "";
        }
    }
}

async function getAllCrowds() {
    await VuexStore.dispatch('crowds/refreshCrowds')
    return VuexStore.state.crowds.crowds
}

</script>

<style scoped lang="sass">

.add-edit-rule

    p
        color: var(--be-colour-text-dark)

    &__header-container
        border-bottom: 1px dashed var(--be-colour-muted-text-dark)
        padding-bottom: 10px

    &__text-input
        height: 90px

        ::v-deep input
            width: 80%

    &__filter-editor
        margin-top: 20px

    &__preview-mentions
        display: flex
        height: 475px

</style>