<template>
    <section class="user-settings-notifications">
        <header>
            <h1>Alerts for {{account.name}}</h1>
        </header>
        <p>
            These <em>Alerts</em> are available to subscribe to. They send you emails when
            conversations around specific themes are spiking.
            Find out more on our
            <a :href="notificationLink" @click.prevent="gotoNotifications"><i class="icon-link-ext"></i>Email & Notifications Panel</a>.
        </p>
        <div class="user-settings-notifications__scroll-area dark-scrollbars dark-scrollbars--visible">
            <section class="user-settings-notifications__alerts">
                <alert-component v-for="alert in alerts"
                                 :key="alert.id"
                                 :notification="alert"/>
            </section>
        </div>
    </section>
</template>

<script>

import AlertComponent from "@/components/notifications/AlertComponent";
import {isSubscribed} from "@/setup/notifications/SetupNotificationUtilities";
import {notifyUserOfError, notifyWithHtml} from "@/app/framework/notifications/Notifications";
import { codeToId } from "@/app/utils/Metatags";
import {mapActions, mapGetters, mapState} from "vuex";
import {escapeExpression} from "@/app/utils/StringUtils";

export default {
    components: {AlertComponent},
    data() {
        return {
            disabled: false,

            wasSubscribed: []
        }
    },

    computed: {
        ...mapState(['user', 'account']),
        ...mapState('digests', ['digests']),
        ...mapGetters('digests', ['loading']),

        alerts() {
            const rpcs = new Set(["RISK", "PURCHASE", "CANCEL", "SERVICE"]);
            return this.digests
                .filter(d => d.type === 'SPIKE_ALERT' && d.externalId)
                .sort((lhs, rhs) => {
                    let split = lhs.externalId.split(':');
                    const lhsTag = split[split.length - 1].toUpperCase();

                    split = rhs.externalId.split(':');
                    const rhsTag = split[split.length - 1].toUpperCase();

                    if (rpcs.has(lhsTag) && rpcs.has(rhsTag)) {
                        return codeToId(lhsTag) - codeToId(rhsTag);
                    } else if (rpcs.has(lhsTag)) {
                        return -1;
                    } else if (rpcs.has(rhsTag)) {
                        return 1;
                    } else {
                        return 0;
                    }
                })
        },

        subscribed() {
            return this.digests.filter(d => this.wasSubscribed.includes(d.id));
        },

        notificationLink() {
            return `/${this.account.code}/setup/notifications`;
        }
    },

    watch: {
        digests() {
            this.wasSubscribed = [];
            this.digests.forEach(d => {
                if (isSubscribed(d) && (d.type !== 'SPIKE_ALERT' || !d.externalId)) this.wasSubscribed.push(d.id);
            })
        }
    },

    async created() {
        await this.refreshDigests();
    },

    methods: {
        isSubscribed,

        ...mapActions('digests', ['refreshDigests', 'updateDigest']),

        gotoNotifications() {
            this.$emit('goto-notifications');
        },

        async unsubscribe(notification) {
            try {
                this.disabled = true;

                if (isSubscribed(notification)) {
                    const email = this.user.email.toLowerCase();
                    const quoted = `<${email}>`;

                    notification.recipients = notification.recipients.filter(e => {
                        e = e.toLowerCase();
                        return e !== email && !e.includes(quoted);
                    });

                    await this.updateDigest(notification);
                }
                notifyWithHtml(
                    `You have been unsubscribed from <strong>${escapeExpression(notification.name)}</strong>`,
                    () => this.subscribe(notification),
                    '<i class="symbol-notification"></i>'
                )
            } catch(e) {
                console.error(e);
                notifyUserOfError("We were unable to unsubscribe you from the notification");
            } finally {
                this.disabled = false;
            }
        },

        async subscribe(notification) {
            try {
                this.disabled = true;
                if (!isSubscribed(notification)) {
                    const username = `${this.user.firstName} ${this.user.lastName}`.trim();
                    notification.recipients.push(`"${username}" <${this.user.email}>`);

                    await this.updateDigest(notification);
                }
                notifyWithHtml(
                    `You have been subscribed to <strong>${escapeExpression(notification.name)}</strong>`,
                    () => this.unsubscribe(notification),
                    '<i class="symbol-notification"></i>'
                )
            } catch(e) {
                console.error(e);
                notifyUserOfError("We were unable to subscribe you to the notification");
            } finally {
                this.disabled = false;
            }
        }
    }
}

</script>

<style scoped lang="sass">

.user-settings-notifications
    box-sizing: border-box
    display: flex
    flex-direction: column
    color: var(--be-colour-text-dark)
    max-height: 100%

.user-settings-notifications__scroll-area
    height: auto
    overflow-y: auto
    padding-right: 20px

.user-settings-notifications__alerts
    box-sizing: border-box
    display: grid
    grid-template-columns: 1fr 1fr


.user-settings-notifications__grid
    display: grid
    box-sizing: border-box
    grid-template-columns: 0.7fr auto 1fr
    background: #333
    border: thin solid #1a1a1a

    > span
        padding: 5px
        border-bottom: thin solid #1a1a1a
        &:not(:nth-child(3n))
            border-right: thin solid #1a1a1a

.user-settings-notifications__header
    background: #222

.user-settings-notifications__description
    color: var(--be-colour-muted-text-dark)
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-box-orient: vertical
    //noinspection CssUnknownProperty
    -webkit-line-clamp: 2

</style>