<template>
    <table class="table table-condensed table-bordered metric-list">
        <tr v-for="metric in metrics"
            tabindex="0"
            :class="{'hover-options': hoverOptions && hasOptions(metric)}"
            :key="metric.id || metric.title"
            @focus="scrollIntoView($event)"
            @keydown.enter.stop="$emit('add-metric', metric)"
            @keydown.up.stop="up($event)"
            @keydown.down.stop="down($event)">
            <td @click="$emit('add-metric', metric)">{{metric.title}}</td>
            <td class="metric-table__description" @click="$emit('add-metric', metric)">
                <span v-if="metric.description">{{metric.description}}</span>
                <span v-else style="color: red;">«Missing description»</span>
            </td>
            <td class="metric-table__options" :class="{'no-options': !hasOptions(metric)}"
                @mouseenter="hoverOptions = true"
                @mouseleave="hoverOptions = false">
                <ul>
                    <li v-if="metric.reference">
                        <a class="btn btn-link metric-table__reference static-beef-tooltip--left"
                           :title="metric.reference.tooltip"
                           @click="$emit('add-metric', metric.reference)">
                            Reference
                        </a>
                    </li>

                    <li v-for="o in metric.options" :key="o.key">
                        <a class="btn btn-link"
                           :title="o.tooltip"
                           @click="addMetric(metric, o)">
                            {{o.title}}
                        </a>
                    </li>
                </ul>
            </td>
        </tr>
    </table>
</template>

<script>
    import { applyOptionAction } from "./availableMetrics";
    import {showErrorDialog} from "@/app/framework/dialogs/Dialog";

    export default {
        name: "MetricTable",
        components: {},
        props: {
            metrics: Array
        },
        data() {
            return {
                hoverOptions: false
            }
        },
        methods: {
            async addMetric(metric, options) {
                metric = await applyOptionAction(metric, options);

                if (!metric) {
                    await showErrorDialog("Unable to show this metric. Please contact support@dataeq.com for assistance.");
                    return;
                }
                this.$emit('add-metric', metric);
            },
            hasOptions(metric) {
                return metric.reference || (metric.options && metric.options.length)
            },
            blur(ev) {
                ev.target.blur();
            },
            scrollIntoView(ev) {
                ev.target.scrollIntoView({behavior: 'smooth', block: "center"})
            },
            down(ev) {
                ev.stopPropagation();
                ev.preventDefault();
                const items = document.querySelectorAll(".metric-list tr");
                const index = this.findTrIndex(items, ev.target);
                if (index >= 0 && index + 1 < items.length) {
                    items[index + 1].focus();
                }
            },
            up(ev) {
                ev.stopPropagation();
                ev.preventDefault();
                const items = document.querySelectorAll(".metric-list tr");
                const index = this.findTrIndex(items, ev.target);
                if (index > 0) {
                    items[index - 1].focus();
                }
            },
            findTrIndex(items, target) {
                let index = -1;
                for (let i = 0; i < items.length; i++) {
                    const row = items[i];
                    if (Object.is(row, target)) {
                        index = i;
                        break;
                    }
                }

                return index;
            }
        }
    }
</script>

<style scoped lang="sass">
.metric-list td:not(.metric-table__options)
    cursor: pointer

.metric-list tr td:first-child
    width: 20%


.metric-list tr:not(.hover-options):hover, tr:focus
    color: var(--be-colour-text-dark__hover)
    background: #383838

table
    box-sizing: border-box
    width: calc(100% - 1px)     // Minus size of margin
    margin-left: 1px            // Needed to show the full outline on the tr

tr
    outline: none
    &:focus
        outline: thin solid var(--be-filter-tag-hover-colour)

.btn-link
    color: var(--be-colour-text-dark)

.metric-table__options
    width: 20%
    &.no-options
        cursor: pointer
    ul
        list-style: none
        margin: 0
        li
            margin: 0
            padding: 0

.metric-table__actions
    text-align: right
    width: 100px
    border-left: none


@media (max-width: 815px)
    .metric-table__options
        display: none


</style>