import { MentionQAst } from '@/mentionq/mentionq';
import _ from 'underscore';
import {removeQuotes, splitUnique} from "@/app/utils/StringUtils";

Beef.module("Attributes").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({
        template: require("@/setup/rules/Attributes.handlebars"),

        modelEvents: {
            "change": "buildAttributeString"
        },

        events: {
            "click .relevancy": "relevancyChange"
        },

        bindings: function() {
            var bindings = {
                media: { converter: Beef.MediaPicker.converter, elAttribute: "data-value" },
                credibility: { converter: Beef.CredibilityPicker.converter, elAttribute: "data-value" },
                location: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" },
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
                tags: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" },
                crowdregion: { converterFactory: Beef.CrowdRegionPicker.converterFactory, elAttribute: "data-value" }
            };

            return bindings;
        },

        templateHelpers: function() {
            return {
                showTitle: !this.options.hideTitle
            }
        },

        initialize: function(options) {
            this.model.set('ok', true);
            this.updateModelAttributes();
        },

        onFirstRender: function() {
            Beef.MediaPicker.attach(this, ".mediaPicker", "media", {onlyOne: true, noIntroduction: true});
            Beef.CredibilityPicker.attach(this, ".credibility", "credibility", {onlyOne: true, noIntroduction: true});
            Beef.LanguagePicker.attach(this, ".language", "language", {onlyOne: true});
            Beef.TagPicker.attach(this, ".tags", "tags");
            Beef.CrowdRegionPicker.attach(this, ".crowdregion", "crowdregion", {onlyOne: true});
            Beef.LocationPicker.attach(this, ".location", "location", {onlyOne: true, noGroups: true});
            this.$('.relevancy').attr('checked', this.model.get('relevancy') == "RELEVANT")
        },

        onClose: function() {
            if (this.popup) this.popup.close();
        },

        ok: function() {
            return this.model.get('ok');
        },

        updateModelAttributes: function() {
            if (this.ignoreAttrChange) return;
            var attributes = this.model.get('attributes');
            var attrs, root = null;
            try {
                root = Beef.AttributeParser.parse(attributes);
            } catch (e) {
                if (e.stack) console.error(e, e.stack);
                else console.error(e);
            }
            if (root) { attrs = convertExpToAttrs(root); }
            this.model.set(attrs);
        },

        buildAttributeString: function() {
            var attr = buildAttributes(this.model.attributes);
            if (attr !== this.model.get('attributes')) {
                this.ignoreAttrChange = true;
                this.model.set('attributes', attr);
                this.ignoreAttrChange = false;

                // closes popup menus like the media picker upon selection
                if (this.options?.closeMenusOnClick) {
                    window.setTimeout(() => {
                        if (this.popup) this.popup.close();
                    });
                }
            }
        },

        relevancyChange: function() {
            if (!this.model.get('relevancy')) { this.model.set('relevancy', "RELEVANT"); }
            else { this.model.unset('relevancy') }
            this.$('.relevancy').attr('checked', this.model.get('relevancy') == "RELEVANT")
        }
    });

    /**
     * Convert a attribute expression to attributes for our model.
     */
    var convertExpToAttrs = function(exp) {
        let attrs = {};
        for (let i = 0; i < exp.length; i ++) {
            let node = exp[i];
            if (node.operandImage.toString().toLowerCase() !== 'action') {
                attrs[node.operandImage.toString().toLowerCase()] = removeQuotes(node.literalImage.toString(), "'");
                if (node.operandType === MentionQAst.TAG) {
                    attrs['tags'] = (attrs['tags'] ? (attrs['tags'] + ' ') : ' ') + removeQuotes(node.literalImage.toString(), "'");
                }
                if (node.operandType === MentionQAst.COUNTRY) {
                    attrs.location = node.literalImage;
                }
            }

        }
        return attrs;
    };

    var buildAttributes = function(attr) {
        var list = [];
        if (attr.relevancy) list.push('relevancy = ' + attr.relevancy);
        if (attr.location) list.push('location = ' + attr.location);
        if (attr.media) list.push('media = ' + attr.media);
        if (attr.language) list.push('language = \'' + attr.language + '\'');
        if (attr.credibility) list.push('credibility = ' + attr.credibility);
        if (attr.crowdregion) list.push('crowdRegion = ' + attr.crowdregion);
        if (attr.tags) {
            _(splitUnique(attr.tags)).each(function(tag) {
                list.push('tag = ' + tag);
            })
        }

        return list.join(',');
    };
});