var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"general-message topics-loading animated fadeIn delay-1000\">\n            <h3>Fetching your topics from DataEQ...</h3>\n            <p>\n                <span class=\"spinner\"></span>\n            </p>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <h3>You have no topics</h3>\n        <p>\n            No topics have been discovered in this account yet.\n        </p>\n        <p>\n            <em>Topic Discovery</em> is a service offered by the <em>DataEQ Crowd</em>. If you would\n            like to enable the service, please contact us.\n        </p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <table class=\"topics-table table table-condensed table-bordered\">\n                <thead>\n                <tr>\n                    <th title=\"The name of the topic, and how it will appear in Analyse\">Topic</th>\n                    <th title=\"What this topic is about\">Description</th>\n                    <th class=\"topic-discovered-on\" title=\"The date that this topic was discovered\">Discovered on</th>\n                </tr>\n                </thead>\n                <tbody class=\"body\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":45,"column":25}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\n                        <td>\n                            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                        </td>\n                        <td>\n                            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\n                        </td>\n                        <td class=\"topic-discovered-on\" >\n                            "
    + alias2(__default(require("../../helpers/moment.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"created") : depth0),"MMMM Do YYYY",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":42,"column":28},"end":{"line":42,"column":61}}}))
    + "\n                        </td>\n                    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n"
    + ((stack1 = container.invokePartial(require("../../app/Header.handlebars"),depth0,{"name":"../../app/Header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noTopics") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "\n\n</div>\n\n";
},"usePartial":true,"useData":true});