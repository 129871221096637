var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <tfoot>\n            <tr>\n                <td colspan=\"6\">\n                    <a class=\"btn btn-link add-user\" title=\"Create new user\"><i class=\"icon-plus\"></i> Add a user</a>\n                </td>\n            </tr>\n            </tfoot>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"main\">\n    <table class=\"table table-condensed table-bordered table-hover\">\n        <thead>\n        <tr>\n            <td>Name</td>\n            <td>Email</td>\n            <td>Permissions</td>\n            <td>View Private Mentions</td>\n            <td>Teams</td>\n            <td class=\"edit\">Edit</td>\n        </tr>\n        </thead>\n        <tbody class=\"body\"></tbody>\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":23,"column":22}}})) != null ? stack1 : "")
    + "    </table>\n</div>\n\n<div class=\"overlay\" style=\"display: none\">\n    <span class=\"spinner\"></span>\n</div>";
},"useData":true});