/**
 * A popup for starting mention sampling for a search job.
 */
Beef.module("HistoricalSearch.SampleSetupPopup").addInitializer(function() {

    this.Model = Backbone.Model.extend({});

    this.View = Beef.HistoricalSearch.FilterPopup.View.extend({
        template: require("@/historical-search/popup/setup/sample/SampleSetupPopup.handlebars"),

        /**
         * Starts a mention sample. Results in a NOP if the button is disabled.
         */
        ok: function(ev) {
            if (!ev.target.classList.contains("disabled")) {
                $("#sample-data-button").addClass("disabled");
                var code = this.model.get("accountCode");
                var that = this;
                var onSuccess = function(updatedJob) {
                    if (updatedJob) {
                        that.model.set("job", updatedJob, { silent: true });
                        that.trigger("sampleStarted");
                    } else {
                        Beef.HistoricalSearch.Message.onUnknownError();
                    }
                };
                var onError =  function() {
                    Beef.HistoricalSearch.Message.onNetworkError();
                };
                var job = this.model.get("job");
                job.sampleStart = this.model.get("selectedStartDate");
                job.sampleEnd = this.model.get("selectedEndDate");
                Beef.SearchJobSync.sample(code, job, onSuccess, onError);
                this.close(true);
            }
        },

        onRender: function() {
            this.showFilterRegion();
        },

        getVolume: function(start, end) {
            return this.model.get("chart").sumOverRange([start, end], true, 80);
        }
    });
});
