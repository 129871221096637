import {
    tagAndTopicDescriptionGetterFactory, tagAndTopicExtender,
    tagAndTopicFilterGetterFactory,
    tagAndTopicPreProcess, tagAndTopicPreProcessExclNoneOfTheAbove,
    tagAndTopicRawDataGetterFactory,
    tagAndTopicRawDataSetterFactory,
    tagAndTopicSetterFactory
} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
import {getTopicsInFilter} from "@/dashboards/filter/FilterParser";
import {getDefaultTopicView} from "@/app/utils/Util";

export default {
    "topic": {
        tooltipPrepositionPercent: 'with topic',
        tooltipComparePreposition: 'with topic',
        tooltipMentionsWithVerb: 'with topic',
        isMultiple: true,
        hideUnknown: true,
        showCooccurrence: true,
        noLimit: true,
        isTag: true,
        tagNamespace: "topic,topic_tree,segment",
        grouseAlias: ["tag"],
        getter: function (d, compare) {
            var field = !compare ? "topic.name" : "topic2.name";
            return d[field];
        },
        setter: tagAndTopicSetterFactory("topic"),
        filterGetter: tagAndTopicFilterGetterFactory("topic"),
        rawDataGetter: tagAndTopicRawDataGetterFactory("topic"),
        rawDataSetter: tagAndTopicRawDataSetterFactory("topic"),
        preProcess: tagAndTopicPreProcessExclNoneOfTheAbove,
        descriptionGetter: tagAndTopicDescriptionGetterFactory("topic"),
        extendData: tagAndTopicExtender,
        getOthersFilter: function(ids) {
            return [...ids].map(id =>`topic is ${id}`).join(" or ");
        },
        filterData: function (data, model, extraFootnotes) {
            var topicview = model.get("topicview") || getDefaultTopicView(model.get("_effectiveFilter"));
            if (topicview) topicview = parseInt(topicview);

            var hideParents = !model.has("hideParents") || model.get("hideParents");
            var hideChildren = model.get("hideChildren");

            var topics = getTopicsInFilter(model.get("_effectiveFilter")).include;
            var showCooccurrence = !!model.get("showCooccurrence");

            return data.filter(function (d) {
                if (topicview && (d["topic.id"] === topicview || d["topic2.id"] === topicview)) {
                    return false;
                }

                var topic = d["_topic"];
                var topic2 = d["_topic2"];

                if (topic && topic.namespace !== "topic" && topic.namespace !== "segment") {
                    return false;
                }

                if (topic2 && topic2.namespace !== "topic" && topic2.namespace !== "segment") {
                    return false;
                }

                if (topic && (!topic.parent && topic.namespace !== "topic")) {
                    return false;
                }

                if (topic2 && (!topic2.parent && topic2.namespace !== "topic")) {
                    return false;
                }

                if (topic && topic.parent && topic.namespace !== "topic" && topic.parent.namespace !== "topic") {
                    return false;
                }

                if (topic2 && topic2.parent && topic2.namespace !== "topic" && topic2.parent.namespace !== "topic") {
                    return false;
                }

                if (hideParents && (d["topic.is_parent"] || d["topic2.is_parent"])) {
                    return false;
                }
                if (hideChildren && (d["topic.is_parent"] === false || d["topic2.is_parent"] === false)) {
                    return false;
                }

                if (showCooccurrence || !topics.length) return true;

                // Filter for only topics and their children from the filter.
                return (topic && topics.includes(topic.id)) ||
                    (topic2 && topics.includes(topic2.id)) ||
                    (topic && topic.parent && topics.includes(topic.parent.id)) ||
                    (topic2 && topic2.parent && topics.includes(topic2.parent.id));
            })
        }
    }
}
