<template>
    <dialog-box @close="$emit('input', null)" :width="dialogWidth" class="widget-settings" :class="className"
                modal no-transition
                :title="title">
        <be-form v-if="!isPreviewing" ref="form" v-model="valid" class="control-form settings-container dark-scrollbars dark-scrollbars--visible" style="min-height: 480px">
            <div class="row-fluid" v-if="!noTitle">
                <div class="span12 control-row">
                    <div style="position: relative">
                        <text-input v-model="attrs.caption" label="Title" placeholder="Title" required ref="caption"
                                    @valid="validate" style="width: 320px"/>
                        <div class="control-group" style="position: absolute; right: 0; top: 0">
                            <label class="checkbox">
                                <input v-model="attrs['hidden-title']" type="checkbox" :false-value="true" :true-value="false"> Show title
                            </label>
                        </div>
                    </div>

                    <div class="row-fluid" v-if="canHaveComments">
                        <div class="control-group">
                            <label>Comments</label>
                            <div class="controls">
                            <span class="tag-input uneditable-input span12 comments" tabindex="0"
                                  style="width: 230px; white-space: nowrap; text-overflow: ellipsis" @click="editComment">
                                <span style="color: #a0a0a0">
                                    <span v-if="attrs.comment">{{attrs.comment}}</span>
                                    <span v-else>Add comments ...</span>
                                </span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-fluid" style="margin: 10px 0">
                <div class="span12 control-row">
                    <template v-if="!noSize">
                        <drop-down-input v-model="attrs.height" :options="options.height" label="Height" style="width: 40px"/>
                        <drop-down-input v-model="attrs.width" :options="options.width" label="Width" style="width: 40px"/>
                    </template>
                    <slot name="title-inputs" v-bind:options="options"  v-bind:attrs="attrs" v-bind:rules="rules"/>
                </div>
            </div>


            <slot :attrs="attrs" :options="options" :rules="rules"/>

            <div class="row-fluid" v-if="canHaveFilter">
                <div class="span12 control-row">
                    <div class="control-group" @click="editFilter">
                        <label>Additional filter for metric</label>
                        <div class="controls">
                            <div class="tag-input uneditable-input filter span12">
                                <english-filter v-if="attrs.filter" :filter="attrs.filter" partial/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </be-form>
        <div v-else>
            <div class="preview-widget dark-scrollbars dark-scrollbars--visible">
                <div class="preview-widget-container widget-grid resizable-grid widget-grid-col12" tabindex="0" @keydown.esc.stop="preview" ref="metricPreview"/>
            </div>
        </div>

        <dialog-box v-if="showEditFilter" title="Edit Additional Filter" @close="showEditFilter = false" width="850px">
            <filter-editor v-model="filter" no-published no-trash :brand="brand"/>
            <template #buttons>
                <a class="cancel btn" @click="showEditFilter = false">Cancel</a>
                <a class="btn btn-primary" @click="editFilterOk">Ok</a>
            </template>
        </dialog-box>

        <div v-if="commentaryPlusInMetricFeatureFlag">
            <dialog-box modal v-if="showEditComment" title="Edit Comments" @close="showEditComment = false" width="850px">
                <section class="commentary-box">
                    <CommentaryPlusEditor
                        ref="commentaryPlusEditor"
                        :blocks="this.attrs.commentPlus"
                        :isReadOnly="false"
                        :showActionBtns="true"
                        :filter="this.model.attributes._effectiveFilter"
                        @onEditorChange="editCommentPlus"></CommentaryPlusEditor>
                </section>
                <div class="row-fluid">
                    <div class="control-row span12" style="height: 56px">
                        <drop-down-input v-model="attrs.commentFontSize" label="Font size" :options="options.fontSize" style="width: 60px"/>
                        <text-input v-model="attrs.commentWidth" label="Width" style="width: 80px" numeric :rules="rules.commentWidth"/>
                        <span class="text-units">blocks</span>
                    </div>
                </div>
                <template #buttons>
                    <a class="cancel btn" @click="showEditComment = false">Cancel</a>
                    <a class="btn btn-primary" @click="editCommentOk">Ok</a>
                </template>
            </dialog-box>
        </div>

        <div v-else>
            <dialog-box modal v-if="showEditComment" title="Edit Comments" @close="showEditComment = false" width="650px">
                <markdown-editor v-model="comment" class="comment-editor"/>
                <div class="row-fluid">
                    <div class="control-row span12" style="height: 56px">
                        <drop-down-input v-model="attrs.commentFontSize" label="Font size" :options="options.fontSize" style="width: 60px"/>
                        <text-input v-model="attrs.commentWidth" label="Width" style="width: 80px" numeric :rules="rules.commentWidth"/>
                        <span class="text-units">blocks</span>
                    </div>
                </div>
                <template #buttons>
                    <a class="cancel btn" @click="showEditComment = false">Cancel</a>
                    <a class="btn btn-primary" @click="editCommentOk">Ok</a>
                </template>
            </dialog-box>

        </div>

        <template #buttons>
            <div class="btn-container">
                <be-button v-if="!isPreviewing" link @click="cancel">Cancel</be-button>
                <be-button :link="isPreviewing" @click="preview" :class="{disabled: !valid}">{{ isPreviewing ? '« Back to edit' : 'Preview' }}</be-button>
                <be-button v-if="!isPreviewing" primary @click="ok" :class="{disabled: !valid}">Ok</be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
    import DialogBox from "../../components/DialogBox"
    import BeForm from "../../components/inputs/BeForm"
    import TextInput from "../../components/inputs/TextInput"
    import DropDownInput from "../../components/inputs/DropDownInput"
    import EnglishFilter from "../../components/formatters/EnglishFilter"
    import FilterEditor from "../../components/inputs/FilterEditor"

    import {cloneDeep} from 'lodash'
    import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
    import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
    import {escapeExpression} from "@/app/utils/StringUtils";
    import BeButton from "@/components/buttons/BeButton";
    import MarkdownEditor from "@/components/inputs/MarkdownEditor";
    import CommentaryPlusEditor from "@/dashboards/widgets/commentaryplus/CommentaryPlusEditor.vue";
    import {features} from "@/app/Features";

    /**
     * Provides a widget settings dialog with ok, preview and cancel as well as support for some standard controls
     * (caption, width, height, additional filter etc.). Shares data with its enclosing component via the dto
     * property which must be initialized with an empty attrs object.
     */
    export default {
        name: "WidgetSettingsDialog",

        components: {
            MarkdownEditor,
            CommentaryPlusEditor, BeButton, DialogBox, TextInput, BeForm, DropDownInput, EnglishFilter, FilterEditor },

        props: {
            model: Object,
            dto: Object,    // must be { attrs: { } }
            width: { type: String, default: "600px" },
            noTitle: Boolean,
            noSize: Boolean,
            noFocus: Boolean,
            className: String
        },

        data() {
            return {
                original: this.cloneModel(),
                valid: true,
                showEditFilter: false,
                filter: String,
                showEditComment: false,
                isPreviewing: false,
                comment: String,
                dialogWidth: this.width,
                commentaryPlusInMetricFeatureFlag: features.commentaryPlusInWidget()
            }
        },

        created() {
            this.options = OPTIONS
            this.rules = RULES
        },

        computed: {
            attrs() { return this.dto.attrs },

            brand() {
                let brand = this.model.getInteractiveFilterModel().get('brand');
                if (!brand) brand = convertFilterToAttrs(this.sectionFilter).brand
                return brand
            },

            title() {
                return this.isPreviewing ? "Metric Preview" : "Edit the metric's settings";
            },

            canHaveComments() {
                return this.model.get('type') !== "Text" && this.model.get('type') !== "Nomenclature";
            },

            canHaveFilter() {
                return this.model.get('type') !== "Nomenclature";
            }
          },

        watch: {
            model(v) {
                this.$set(this.dto, "attrs", this.cloneModel());
            }
        },

        mounted() {
            this.$set(this.dto, "attrs", this.cloneModel());
            if (!this.noFocus) this.$nextTick(() => {
                if (this.$refs.caption) this.$refs.caption.focus();
            })
        },

        methods: {
            cloneModel() {
                let attrs = {...this.model.attributes}
                Object.keys(attrs).forEach(k => {
                    if (k.charAt(0) == '_') {
                        delete attrs[k]
                    } else {
                        let v = attrs[k]
                        if (typeof v === 'object') attrs[k] = cloneDeep(v)
                    }
                })
                return attrs
            },

            validate() {
                this.$refs.form.validate()
                return this.valid
            },

            validateLater() {
                this.$nextTick(() => this.validate())
            },

            ok() {
                if (!this.validate()) return
                this.applyChanges(this.attrs)
                this.model.save(null, {
                    error: (model, xhr, options) => {
                        if (xhr.responseText) console.error(xhr.responseText, xhr)
                        else (console.error(xhr))
                        window.alert("Error saving changes .. please retry")
                    }
                })
                this.$emit('input', null)

                notifyUser({
                    message: `<strong>${escapeExpression(this.original.caption)}</strong> settings updated.`,
                    isEscapedHtml: true,
                    icon: '<i class="icon-signal-1"></i>',
                    undo: () => {
                        this.model.clear({silent: true})
                        this.model.set(this.original)
                        this.model.save();
                        notifyWithText("Metric setting changes have been undone.", null, '<i class="icon-signal-1"></i>')
                    }
                })
            },

            applyChanges(attrs) {
                this.model.set(attrs)
                // make sure all the props are reactive
                Object.entries(attrs).forEach(e => {
                    let key = e[0]
                    delete this.model.attributes[key]   // have to get rid of prop first so it is new for $set
                    this.$set(this.model.attributes, key, e[1])
                })
            },

            preview() {
                this.isPreviewing = !this.isPreviewing;
                if (!this.isPreviewing) {
                    this.dialogWidth = this.width;
                    return;
                }

                if (!this.validate()) return;

                let previewModel = $.extend(true, {}, this.model);
                previewModel.set(this.attrs);

                let previewView = new Beef.Widget.View({model: previewModel});
                previewView.updateFilter();

                this.$nextTick(() => {
                    previewView.render();
                    this.$refs.metricPreview.append(previewView.el);
                    this.$refs.metricPreview.focus();

                    let style = getComputedStyle(previewView.el)
                    let widgetWidth = style.getPropertyValue('--widget-width');

                    // set width of dialog to be the width of the widget (add 40px to account for dialog padding)
                    if (widgetWidth) this.dialogWidth = `calc(${widgetWidth} + 40px)`;
                });
            },

            editComment() {
                this.comment = this.attrs.comment
                this.showEditComment = true
            },

            editCommentOk() {
                this.showEditComment = false
                this.attrs.comment = this.comment
            },

            cancel() {
                this.$emit('input', null)
            },

            editFilter() {
                this.filter = this.attrs.filter
                this.showEditFilter = true
            },

            editFilterOk() {
                this.showEditFilter = false
                this.attrs.filter = this.filter
            },

            async editCommentPlus(output){
                try {
                    this.attrs.commentPlus = JSON.stringify(output);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }

    export const OPTIONS = {
        height: [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}],

        width: [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8},
            {id: 9}, {id: 10}, {id: 11}, {id: 12}, {id: 13}, {id: 14}, {id: 15}, {id: 16}],

        fontSize: [{id:10}, {id:12}, {id:14}, {id:16}, {id:18}]
    }

    export const RULES = {
        maxItems: [v => !v || v >= 1 && v <= 999 || "Invalid"],
        commentWidth: [v => !v || v >= 0.5 && v <= 20 || "Invalid"]
    }

</script>

<style>
    .row-fluid .control-row,
    .control-row {
        display: flex;
    }

    .control-row > div:not(:first-child) {
        margin-left: 15px;
    }

    .control-form > .row-fluid:not(:first-child) {
        margin-top: 10px;
    }

</style>

<style scoped>
.commentary-box {
    max-height: 500px;
    height: 50%;
    overflow: auto;
    background-color: white;
}
</style>

<style scoped lang="sass">

.settings-container
    max-height: 700px
    overflow-y: auto

.btn-container
    display: flex
    column-gap: 5px

    & >:first-child
        margin-left: auto

.preview-widget
    max-height: calc(90vh - 125px)
    overflow: auto

.preview-widget-container
    grid-template-columns: repeat(auto-fit, var(--widget-width))
    animation: fadeIn linear 250ms
    width: auto

    ::v-deep .widget-height-inner
        & >:first-child
            pointer-events: none

    ::v-deep .title
        pointer-events: none

    ::v-deep .markdown-display
        overflow: visible !important

@keyframes fadeIn
    0%
        opacity: 0
    100%
        opacity: 1

</style>