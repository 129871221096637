import {formatNumber, numTo4Chars} from "@/app/utils/Format";
import VuexStore from "@/store/vuex/VuexStore";
import {grouseGet} from "@/data/Grouse";
import {encloseInDisplayQuotes, restrictToLength, splitAtSpaces} from "@/app/utils/StringUtils";

/**
 * Drill down by adding/removing tags to the filter.
 */
Beef.module("Widget.TagSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Tags",
        description:    "Interactively filter by tags",
        width:          2,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.CountSelector.View.extend({

        filterAttribute: "tags",
        groupby: "tag",
        tagSelection: true,

        modelEvents:Object.assign({
            "change": "maybeRefresh"
        }, Beef.Widget.CountSelector.View.prototype.modelEvents),

        maybeRefresh: function() {
            var c = this.model.changed;
            if (c.hasOwnProperty('tags') || c.hasOwnProperty('excludingTags')
                    || c.hasOwnProperty('showAll') || c.hasOwnProperty('allLabel')) {
                this.clearAndRefresh();
            }
        },

        clearAndRefresh: function() {
            this.clearFilterAttribute();
            this.refresh();
        },

        getTooltipText: function(item) {
            var tooltip = "Click to select mentions tagged with " + encloseInDisplayQuotes(item.name);
            if (item.description) tooltip += ".\n\nDescription: " + restrictToLength(item.description, 100);
            return tooltip;
        },

        getCountTooltip: function(item) {
            var mentionText = item.count == 1 ? "mention" : "mentions";
            var verb = item.count == 1 ? "is" : "are";
            return "There " + verb + " " + formatNumber(item.count) + " " + mentionText +
                " tagged with " + encloseInDisplayQuotes(item.name);
        },

        tweakQuery: function(q) {
            this._lastFilterWithBrands = q.filter
            let tagIds = this.model.get(this.filterAttribute)
            if (tagIds) {
                var a = splitAtSpaces(tagIds)
                var f = '';
                for (var i = 0; i < a.length; i++) f += (i > 0 ? " or " : '') + "tag is " + a[i];
                q.filter += " and (" + f + ")";
            }
        },

        cropItemName: false,

        mapData: function(d) {
            return { id: d.tag.id, name: d.tag.name, count: d.mentionCount }
        },

        getChildren: function(tag) {
            return tag.children;    // this is used by SegmentSelector to exclude 'none of the above'
        },

        tagItems: async function(items) {
            await VuexStore.dispatch("refreshTags");
            const idToTag = VuexStore.getters.idToTag;
            let rows = []

            // put in all the 'include' tags .. their counts will be in items from the groupBy=tag query run
            // by CountSelector
            let tagIds = this.model.get(this.filterAttribute)
            if (tagIds && tagIds.length > 0) {
                let lookupCountForTag = async id => {
                    let tag = idToTag.get(id);
                    if (!tag || tag.deleted) return;
                    var children = this.getChildren(tag);
                    if (children && children.length) {
                        // todo might need to exclude 'none of the above' for segment lists
                        for (var i = 0; i < children.length; i++) await lookupCountForTag(children[i]);
                    } else {
                        var name = tag.name;
                        var description = tag.description;
                        let row = items.find(item => item.id === id)
                        if (row) {
                            row.description = description;
                            rows.push(row);
                        } else {
                            rows.push({id: id, name: name ? name : id, count: 0, description: description})
                        }
                    }
                };
                for (const id of tagIds.split(' ')) {
                    await lookupCountForTag(parseInt(id));
                }
            }

            let endpoint = "/v4/accounts/" + VuexStore.state.account.code + "/mentions/count"
            let filter = this._lastFilterWithBrands

            const fromGrouse = this.model.getSectionModel()
                ? this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view)
                : grouseGet;

            // run separate queries in parallel for all the "exclude" tags to get their counts
            tagIds = this.model.get('excludingTags')
            if (tagIds && tagIds.length > 0) {
                tagIds.split(' ').forEach(id => {
                    id = parseInt(id)
                    let tag = idToTag.get(id);
                    if (!tag || tag.deleted) return
                    let row = {id: "-" + id, name: "Excluding " + tag.name, count: null, description: tag.description}
                    rows.push(row)
                    let params = { filter: "(" + filter + ") AND Tag ISNT " + id }
                    fromGrouse(endpoint, params).then(res => {
                        row.count = res.mentionCount
                        this.$('tr.value[data-value="-' + id + '"] td.num')
                            .text(numTo4Chars(row.count))
                            .attr("title", this.getCountTooltip(row))
                    });
                })
            }

            if (this.model.get('showAll')) {
                let row = {id: ".", name: this.model.get('allLabel') || "All conversation", count: null }
                rows.push(row)
                let params = {
                    filter: filter
                };
                fromGrouse(endpoint, params).then(res => {
                    row.count = res.mentionCount
                    this.$('tr.value[data-value="."] td.num')
                        .text(numTo4Chars(row.count))
                        .attr("title", this.getCountTooltip(row))
                });
            }

            if (!rows.length) {
                rows.push({name: "(no tags - edit this metric and choose some tags)"})
            }
            this.text(rows);
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/selectors/TagSelectorSettings.handlebars"),

        editAttributes: function() {
            return ['tags', 'excludingTags', 'showAll', 'allLabel']
        },

        bindings: function() {
            return {
                tags: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" },
                excludingTags: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" }
            }},

        onFirstRender: function() {
            let chooser = d => {
                if (Beef.TagPicker.tagChooser(d) || Beef.TagPicker.topicChooser(d)) return true
                // we can't include all the RPCS flag "tags" here as we can't do excluding on them
                return d.item.namespace !== 'segment';
            }

            // we can only include leaf tags because the list of tags presented in the drill down is built using
            // a count query with groupBy=tag
            var options = {
                searchFilter: d => Beef.TagPicker.tagChooser(d) || d.item.namespace === 'segment'
                        || d.item.namespace === 'topic' && !d.item.children
                // showTopicTagDetail: false,
                // startDroppedDown: true,
                // importanceSorter: ImportanceSorter,
                // sortFun: Beef.TagPicker.sortFun
            };

            Beef.TagPicker.attach(this, ".tags", "tags", options);
            Beef.TagPicker.attach(this, ".excludingTags", "excludingTags", options);
        }
    });

});