/**
 * Utility methods to display and update mention ordering info.
 */
Beef.module("OrderBy").addInitializer(function(startupOptions) {

    var orderByMap = {
        id: "date received",
        published: "published date",
        updated: "last updated",
        ots: "OTS",
        ave: "AVE",
        reach: "total reach",
        engagement: "engagement",
        resharecount: "reshares",
        replycount: "replies",
        responsetime: "response time",
        interactionResponseTime: "interaction response time",
        interactionFollowupResponseTime: "follow-up response time",
        interactionWhResponseTime: "interaction response time (working hours)",
        interactionWhFollowupResponseTime: "follow-up response time (working hours)"
    };

    /**
     * Convert an 'orderby' value into English text. If nullIfDefault is true and the default ordering (id desc) is
     * being used then null is returned.
     */
    this.getOrderByAsEnglish = function(orderby, nullIfDefault) {
        var i = orderby.indexOf(' ');
        var desc = i > 0;
        if (desc) orderby = orderby.substring(0, i);
        if (orderby == "id" && desc) return nullIfDefault ? null : "date received";
        var en = orderByMap[orderby] || orderby;
        if (desc) return en;
        if (orderby == "id" || orderby == "published" || orderby == "updated") return en + " (oldest first)";
        return en + " (lowest first)"
    };

    /**
     * Displays a sort options menu for the view using the view model 'orderby' property and updates 'orderby' if
     * a new option is selected.
     * @param $target jQuery selection for element that menu should be displayed relative to
     */
    this.displayOrderByMenu = function(view, $target) {
        view.popup = Beef.MiniMenu.show({
            template: require("@/mentions/MentionsSortMenu.handlebars"),
            object: {
                sortBy: function($t) {
                    var orderby = $t.attr('data-orderby');
                    var current = view.model.get('orderby');
                    if (current.indexOf(orderby) == 0) {
                        if (current.indexOf(' desc') < 0) orderby = orderby + " desc";
                    } else {
                        orderby = orderby + " desc"
                    }
                    view.model.set('orderby', orderby);
                }
            },
            target: $target,
            onRender: function() {
                this.$el.toggleClass("mini-menu-selectable");
                var orderby = view.model.get('orderby');
                var i = orderby.indexOf(' desc');
                var desc = i > 0;
                if (desc) orderby = orderby.substring(0, i);
                this.$('a[data-orderby="' + orderby + '"]').toggleClass(desc ? "up" : "down");
            }
        });
    };
});
