<template>
    <section class="overview-tool">
        <overview-title/>

        <section v-if="!brand && isInitialised && !exploreLoading">
            <staff-only-tag/> Set some owned brands so that you can see data here.
        </section>

        <section v-if="error">
            We're having a problem speaking with our servers. Please try again in a few minutes.
        </section>

        <section v-if="brand && !error">
            <div class="overview-tool__tabs" ref="tabs">
                <dark-card class="overview-tool__enterprise overview-tool__tab">
                    <h1>Enterprise mentions</h1>
                    <div v-if="enterpriseCount !== null">
                        <deq-number :number="enterpriseCount"/> {{formatPlural(enterpriseCount, "mention")}}
                    </div>
                    <div v-else class="overview-tool__loading animated fadeIn delay-200"></div>

                    <template #popup
                              v-if="enterpriseCount !== null">

                        <div class="overview-tool__tab-description">
                            <div>
                                <strong>{{ formatBrandName(brand) }}</strong>
                                posted
                                <strong>
                                    <deq-number :number="enterpriseCount"/>
                                    {{ formatPlural(enterpriseCount, "time") }}
                                </strong>
                            </div>

                            <div class="overview-tool__links">
                                <explore-button @click="gotoExploreEnterprise()"/>
                                <see-mentions-button :filter="enterpriseFilter" title="Enterprise mentions (by engagement"/>
                            </div>
                        </div>
                    </template>
                </dark-card>

                <dark-card class="overview-tool__press overview-tool__tab">
                    <h1>Press mentions</h1>
                    <div v-if="pressCount !== null" class="short-animated fadeIn">
                        <deq-number :number="pressCount"/> {{formatPlural(pressCount, "mention")}}
                    </div>
                    <div v-else class="overview-tool__loading animated fadeIn delay-200"></div>

                    <template #popup
                              v-if="pressCount !== null">

                        <div class="overview-tool__tab-description">
                            <div>
                                <strong>Press sources</strong> posted
                                <strong>
                                    <deq-number :number="pressCount"/>
                                    {{ formatPlural(pressCount, "time") }}
                                </strong>
                                about {{formatBrandName(brand)}}
                            </div>

                            <div class="overview-tool__links">
                                <explore-button @click="gotoExplorePress()"/>
                                <see-mentions-button :filter="pressFilter" title="Press mentions (by engagement"/>
                            </div>
                        </div>
                    </template>
                </dark-card>

                <dark-card class="overview-tool__consumer overview-tool__tab">
                    <h1>Consumer mentions</h1>
                    <div v-if="consumerCount !== null" class="short-animated fadeIn">
                        <deq-number :number="consumerCount"/> {{formatPlural(consumerCount, "conversation")}}
                    </div>
                    <div v-else class="overview-tool__loading animated fadeIn delay-200"></div>

                    <template #popup
                              v-if="consumerCount !== null">

                        <div class="overview-tool__tab-description">
                            <div>
                                <strong>Consumers</strong> posted
                                <strong>
                                    <deq-number :number="consumerCount"/>
                                    {{ formatPlural(consumerCount, "time") }}
                                </strong>
                                about {{formatBrandName(brand)}}
                            </div>

                            <div class="overview-tool__links">
                                <explore-button @click="gotoExploreConsumer()"/>
                                <see-mentions-button :filter="consumerFilter" title="Consumer mentions (by engagement)"/>
                            </div>
                        </div>
                    </template>
                </dark-card>

                <dark-card v-if="accountHasRisk || !isInitialised"
                           class="overview-tool__risk overview-tool__tab"
                           :disabled="!brandHasRisk && isInitialised">
                    <h1>Risk trends</h1>
                    <div v-if="riskError">Unable to load risk</div>
                    <div v-else-if="riskLoading || !isInitialised" class="overview-tool__loading animated fadeIn delay-200"></div>
                    <div v-else-if="!brandHasRisk" class="overview-tool__blank animated fadeIn delay-200">
                        <em>{{ brand.shortName || brand.name }}</em> is not on <em>Risk & Reputation</em>
                    </div>
                    <div v-else-if="topRisk" class="overview-tool__top-topic short-animated fadeIn">
                        <div>
                            <net-sentiment v-if="topRisk.net !== null"
                                           no-number
                                           :net-sentiment="topRisk.net"/>
                            {{ topRisk.topic.name }}
                        </div>
                    </div>
                    <div v-else class="overview-tool__blank">
                        Nothing unusual
                    </div>

                    <template #popup v-if="topRisk !== null">
                        <div class="overview-tool__tab-description">
                            <div v-if="topRisk.net !== null && topRisk.maxDate !== null">
                                The risk factor
                                <strong class="overview-tool--nowrap">
                                    <net-sentiment no-number :net-sentiment="topRisk.net"/>
                                    {{ topRisk.topic.name }}
                                </strong>
                                spiked on
                                <strong><deq-date :date="topRisk.maxDate" format="YYYY-MM-DD"/></strong>,
                                occurring on <strong><deq-number :number="topRisk.max"/></strong> mentions
                            </div>

                            <div class="overview-tool__links">
                                <explore-button @click="gotoExploreRisk()"/>
                                <see-mentions-button :filter="topRisk.filter" :title="`Mentions for '${topRisk.topic.name}' (by engagement)`"/>
                            </div>
                        </div>
                    </template>
                </dark-card>
                <dark-card v-else
                           class="overview-tool__risk overview-tool__tab"
                           disabled>
                    <h1>Risk trends</h1>
                    <p>
                        <em>Risk factors</em> are not tracked
                    </p>
                </dark-card>

                <dark-card v-if="accountHasTopics || !isInitialised"
                           class="overview-tool__topics overview-tool__tab"
                           :disabled="!brandHasTopics && isInitialised">
                    <h1>Topic trends</h1>
                    <div v-if="topicError">Unable to load topics</div>
                    <div v-else-if="topicLoading || !isInitialised" class="overview-tool__loading animated fadeIn delay-200"></div>
                    <div v-else-if="!brandHasTopics" class="overview-tool__blank animated fadeIn delay-200">
                        <em>{{ brand.shortName || brand.name }}</em> does not use <em>DataEQ Topics</em>
                    </div>
                    <div v-else-if="topTopic" class="overview-tool__top-topic short-animated fadeIn">
                        <div>
                            <net-sentiment v-if="topTopic.net !== null"
                                           no-number
                                           :net-sentiment="topTopic.net"/>
                            {{ topTopic.topic.name }}
                        </div>
                    </div>
                    <div v-else class="overview-tool__blank">
                        Nothing unusual
                    </div>

                    <template #popup v-if="topTopic !== null">
                        <div class="overview-tool__tab-description">
                            <div v-if="Number.isFinite(topTopic.net) && topTopic.maxDate">
                                The topic
                                <strong class="overview-tool--nowrap">
                                    <net-sentiment no-number :net-sentiment="topTopic.net"/>
                                    <span>{{ topTopic.topic.name }}</span>
                                </strong>
                                spiked in volume on
                                <strong><deq-date :date="topTopic.maxDate" format="YYYY-MM-DD"/></strong>,
                                occurring on <strong><deq-number :number="topTopic.max"/> mentions</strong>
                            </div>

                            <div class="overview-tool__links">
                                <explore-button @click="gotoExploreTrendingTopics()"/>
                                <see-mentions-button :filter="topTopic.filter" :title="`Mentions for '${topTopic.topic.name}' (by engagement)`"/>
                            </div>
                        </div>
                    </template>
                </dark-card>
                <dark-card v-else
                           class="overview-tool__topics overview-tool__tab"
                           disabled>
                    <h1>Topic trends</h1>
                    <p>
                        <em>Topic analysis</em> is not supported
                    </p>
                </dark-card>

                <dark-card class="overview-tool__keywords overview-tool__tab">
                    <h1>Keyword trends</h1>
                    <div v-if="keywordLoading" class="overview-tool__loading animated fadeIn delay-200"></div>
                    <div v-else-if="topKeyword !== null" class="short-animated fadeIn">
                        <div>
                            <net-sentiment v-if="Number.isFinite(topKeyword.net)"
                                           class="animated fadeIn"
                                           :net-sentiment="topKeyword.net"
                                           no-number/>
                            &nbsp;<span v-html="withEmoji(topKeyword.word)"></span>
                        </div>
                    </div>
                    <div v-else class="overview-tool__blank">
                        Nothing unusual
                    </div>

                    <template #popup v-if="topKeyword !== null">
                        <div class="overview-tool__tab-description">
                            <div v-if="topKeyword.maxDate && Number.isFinite(topKeyword.net)">
                                The number of mentions using the word
                                <strong class="overview-tool--nowrap">
                                    <net-sentiment no-number :net-sentiment="topKeyword.net"/><span>{{ topKeyword.word }}</span>
                                </strong>
                                spiked in volume on
                                <strong><deq-date :date="topKeyword.maxDate" format="YYYY-MM-DD"/></strong>
                            </div>

                            <div class="overview-tool__links">
                                <explore-button @click="gotoExploreTrendingKeywords()"/>
                                <see-mentions-button :filter="topKeyword.filter" :title="`Mentions for '${topKeyword.word}' (by engagement)`"/>
                            </div>
                        </div>
                    </template>
                </dark-card>

                <dark-card class="overview-tool__overall overview-tool__tab" popup-direction="down">
                    <div v-if="overallCount !== null" class="short-animated fadeIn">
                        <div>
                            <deq-number :number="overallPublic"/>
                            Public {{formatPlural(overallPublic, 'mention')}}
                        </div>
                        <div v-if="brandHasDms">
                            <deq-number :number="overallDm"/>
                            {{ formatPlural(overallDm, 'Direct message') }}
                        </div>
                        <div v-if="overallOtherPrivate">
                            <deq-number :number="overallOtherPrivate"/> other private
                        </div>
                    </div>
                    <div v-else>
                        <div class="overview-tool__loading animated fadeIn delay-200" style="width: 3ch; display: inline-block"></div>Public mentions
                    </div>


                    <template #popup>
                        <div v-if="overallCount !== null">
                            <strong>
                                <deq-number :number="overallCount"/>
                                total {{ formatPlural(overallCount, "mention") }}
                            </strong>
                        </div>
                        <div v-if="overallCount">
                            <div><deq-percentage :percent="overallPublic / overallCount * 100" :precision="1"/> public</div>
                            <div v-if="brandHasDms"><deq-percentage :percent="overallDm / overallCount * 100" :precision="1"/> DMs</div>
                            <div v-if="overallOtherPrivate">
                                <deq-percentage :percent="overallOtherPrivate / overallCount * 100" :precision="1"/> other private
                            </div>
                        </div>

                    </template>
                </dark-card>

                <dark-card class="overview-tool__networks overview-tool_tab">
                    <volume-overview-chart/>


                </dark-card>

                <div v-if="isInitialised && !centreContainerLoading"
                     class="overview-tool__container short-animated fadeIn">
                    <dark-card v-if="!accountHasSentiment" disabled class="overview-tool__no-sentiment">
                        <h1>Public Sentiment</h1>
                        This account does not have ongoing verified sentiment
                    </dark-card>
                    <dark-card v-else-if="!brandHasSentiment" disabled class="overview-tool__no-sentiment">
                        <h1>Public Sentiment</h1>
                        This brand does not have any verified sentiment
                    </dark-card>

                    <div v-if="brandHasSentiment" class="overview-tool__sentiment-metrics">
                        <dark-card class="overview-tool__overall-sentiment" popup-direction="down">
                            <div class="overview-tool__public-sentiment">
                                <net-sentiment-calculator v-if="publicSentimentScoreFilter"
                                                          :filter="publicSentimentScoreFilter"
                                                          v-slot="{netSentiment, delta, previousNetSentiment}">
                                    <section class="overview-tool__public-sentiment-header">
                                        <div>
                                            <h4>Public Sentiment</h4>
                                            <span v-if="Math.abs(delta) > 0.01"><percentage-point-delta :delta="delta"/> from <deq-percentage :percent="previousNetSentiment" is-proportion :precision="1"/></span>
                                            <span v-else-if="Math.abs(delta) <= 0.01">Unchanged</span>
                                        </div>
                                        <h1>
                                            <deq-percentage :percent="netSentiment"
                                                            :class="netSentiment < 0 ? 'negative-sentiment' : netSentiment > 0 ? 'positive-sentiment' : 'neutral-sentiment'"
                                                            is-proportion
                                                            :precision="1"
                                            />
                                        </h1>
                                    </section>
                                </net-sentiment-calculator>


                                <div v-if="accountHasCx">
                                    <section>

                                        <net-sentiment-calculator v-if="operationalSentimentFilter"
                                                                  :filter="operationalSentimentFilter"
                                                                  v-slot="{netSentiment, delta}">
                                            <span class="overview-tool__sentiment-label">Operational</span>
                                            <net-sentiment :net-sentiment="netSentiment"/> <span v-if="delta !== null" class="overview-tool--muted"><percentage-point-delta :delta="delta"/></span>
                                        </net-sentiment-calculator>
                                    </section>
                                    <section v-if="accountHasCx">
                                        <net-sentiment-calculator v-if="reputationalSentimentFilter"
                                                                  :filter="reputationalSentimentFilter"
                                                                  v-slot="{netSentiment, delta}">
                                            <span class="overview-tool__sentiment-label">Reputational</span>
                                            <net-sentiment :net-sentiment="netSentiment"/> <span v-if="delta !== null" class="overview-tool--muted"><percentage-point-delta :delta="delta"/></span>
                                        </net-sentiment-calculator>
                                    </section>
                                </div>
                                <div v-else class="overview-tool__no-cx">
                                    <em>Customer Experience</em> factors are not tracked

                                </div>
                            </div>

                            <template #popup>
                                <div class="overview-tool__public-sentiment">
                                    <net-sentiment-calculator v-if="benchmarkSentimentScoreFilter"
                                                              :filter="benchmarkSentimentScoreFilter"
                                                              v-slot="{netSentiment, previousNetSentiment, delta}">
                                        <section class="overview-tool__public-sentiment-header">
                                            <div>
                                                <h4>Benchmark Sentiment</h4>
                                                <span v-if="Math.abs(delta) > 0.01"><percentage-point-delta :delta="delta"/> from <deq-percentage :percent="previousNetSentiment" is-proportion :precision="1"/></span>
                                                <span v-else-if="Math.abs(delta) <= 0.01">Unchanged</span>
                                            </div>
                                            <h1>
                                                <deq-percentage :percent="netSentiment"
                                                            :class="netSentiment < 0 ? 'negative-sentiment' : netSentiment > 0 ? 'positive-sentiment' : 'neutral-sentiment'"
                                                            is-proportion
                                                            :precision="1"/>
                                            </h1>
                                        </section>
                                    </net-sentiment-calculator>
                                    <div class="overview-tool__benchmark-sentiment-description">
                                        <glossary-entry glossary-id="glossary:BENCHMARK-SENTIMENT"/>
                                    </div>
                                </div>
                            </template>
                        </dark-card>

                        <dark-card v-if="brandHasDms" popup-direction="down" class="overview-tool__direct">
                            <net-sentiment-calculator v-if="directMentionSentimentScoreFilter"
                                                      :filter="directMentionSentimentScoreFilter"
                                                      style="text-align: center"
                                                      v-slot="{netSentiment}">
                                Direct messages<br>
                                <net-sentiment :net-sentiment="netSentiment"/>
                            </net-sentiment-calculator>

                            <template #popup>
                                Private sentiment is
                                <strong>
                                    <net-sentiment-calculator v-if="directMentionSentimentScoreFilter"
                                                              :filter="directMentionSentimentScoreFilter"
                                                              with-delta/>
                                </strong>

                            </template>
                        </dark-card>
                        <dark-card v-else-if="accountHasDms" disabled class="overview-tool__no-cx" style="text-align: center">
                            This brand is not collecting
                            <em>direct mentions</em>
                        </dark-card>
                        <dark-card v-else disabled class="overview-tool__no-cx">
                            Not collecting
                            <em>direct mentions</em>
                        </dark-card>


                    </div>
                    <pcs-charts class="brand-sentiment__charts" :brand="brand" ref="charts"/>
                </div>
                <div v-if="centreContainerLoading" class="overview-tool__container">
                    <loading-message>
                        <h2>
                            Creating an overview of {{ brand ? formatBrandName(brand) : 'of the brand' }}
                            <deq-percentage :percent="initialisePercent"/>
                            ...
                        </h2>
                    </loading-message>
                </div>

            </div>
        </section>


    </section>
</template>

<script>

import StaffOnlyTag from "@/components/tags/StaffOnlyTag";
import DeqNumber from "@/components/formatters/DeqNumber";
import {
    fetchSentimentForKeyword,
    gotoExploreConsumer,
    gotoExploreEnterprise,
    gotoExplorePress,
    gotoExploreRisk,
    gotoExploreTrendingKeywords,
    gotoExploreTrendingTopics,
    loadKeywordTrendsForBrand,
    loadTrendingRisk,
    loadTrendingTopics
} from "@/app/toplevel/explore/overview/ExploreUtilities";
import NetSentiment from "@/components/NetSentiment";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import SetupTooltip from "@/app/toplevel/setup/SetupTooltip";
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";
import {mapGetters, mapState} from "vuex";
import DarkCard from "@/components/cards/DarkCard";
import NetSentimentCalculator from "@/app/toplevel/explore/overview/components/NetSentimentCalculator";
import {
    getBenchmarkSentimentScore,
    getDirectMessageSentimentScore,
    getOperationalSentimentFilter,
    getPublicSentimentScore,
    getReputationalSentimentFilter
} from "@/app/utils/Sentiment";
import PercentagePointDelta from "@/components/formatters/PercentagePointDelta";
import DeqPercentage from "@/components/formatters/DeqPercentage";
import PcsCharts from "@/app/toplevel/explore/overview/components/PcsCharts";
import VolumeOverviewChart from "@/app/toplevel/explore/overview/components/VolumeOverviewChart";
import LoadingMessage from "@/components/LoadingMessage";
import {formatBrandName, formatPlural, toEmojiHtml} from "@/app/utils/Format";
import {earliestDate, latestDate} from "@/dashboards/filter/FilterParser";
import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import DeqDate from "@/components/formatters/DeqDate";
import ExploreButton from "@/components/buttons/ExploreButton";
import SeeMentionsButton from "@/components/buttons/SeeMentionsButton";
import GlossaryEntry from "@/app/help/tips/GlossaryEntry";

export default {
    components: {
        DeqPercentage,
        DeqNumber,
        DeqDate,
        GlossaryEntry,
        SeeMentionsButton,
        ExploreButton,
        LoadingMessage,
        VolumeOverviewChart,
        PcsCharts,
        PercentagePointDelta,
        NetSentimentCalculator,
        DarkCard,
        OverviewTitle,
        NetSentiment,
        StaffOnlyTag
    },

    data() {
        return {
            tabSizeObserver: null,

            loading: false,
            active: 'enterprise',

            topKeyword: null,
            topTopic: null,
            topRisk: null,

            pressError: false,
            topicError: false,
            riskError: false,

            topicLoading: true,
            riskLoading: true,
            keywordLoading: true,

            benchmarkSentimentScoreFilter: null,
            publicSentimentScoreFilter: null,
            directMentionSentimentScoreFilter: null,
            operationalSentimentFilter: null,
            reputationalSentimentFilter: null
        }
    },

    computed: {
        ...mapState('explorePanel', [
            'isInitialised',
            'initialisePercent',
            'error',
            'brand',
            'brandHasTopics', 'brandHasRisk', 'brandHasSentiment',
            'accountHasSentiment', 'accountHasRisk', 'accountHasTopics', 'accountHasCx',
            'accountHasDms', 'brandHasDms'
        ]),
        ...mapState('explorePanel', { exploreLoading: state => state.loading }),
        ...mapGetters('explorePanel', [
            'enterpriseCount', 'pressCount', 'consumerCount',
            'overallCount', 'overallPublic', 'overallDm', 'overallOtherPrivate',
            'consumerCount', 'statsLoading',
            'dateFilter', 'englishDate',
            'pressFilter', 'enterpriseFilter', 'consumerFilter'
        ]),
        priorityDashboardName() {
            return this.priorityDashboard?.name ?? "Priority Conversations";
        },
        hasErrors() {
            return this.pressError || this.topicError || this.riskError;
        },

        centreContainerLoading() {
            return this.brandHasSentiment && (this.statsLoading || this.exploreLoading);
        },
    },

    watch: {
        brand() {
            this.updateBrandFilters();
            this.loadStats();
            this.tabSizeObserver?.disconnect();
            this.$nextTick(() => this.tabSizeObserver?.observe(this.$refs.tabs))
        },
        accountHasCx() {
            this.updateBrandFilters();
        },
        dateFilter() {
            this.updateBrandFilters();
            this.loadStats();
        }
    },

    async created() {
        this.loadStats().catch(e => console.error(e));
        this.updateBrandFilters().catch(e => console.error(e));
    },

    async mounted() {
        // Start watching for size changes, to redraw the charts.
        this.tabSizeObserver = new ResizeObserver(() => this.$refs.charts?.redraw());
        if (this.$refs.tabs) this.tabSizeObserver.observe(this.$refs.tabs);
    },

    async beforeDestroy() {
        this.tabSizeObserver?.disconnect();
    },

    methods: {
        formatPlural,
        formatBrandName,
        gotoExploreEnterprise,
        gotoExplorePress,
        gotoExploreTrendingKeywords,
        gotoExploreRisk,
        gotoExploreTrendingTopics,
        gotoExploreConsumer,
        showMentions,
        showWordcloud,


        withEmoji(text) {
            return toEmojiHtml(text);
        },

        async updateBrandFilters() {
            this.publicSentimentScoreFilter = this.brand
                ? getPublicSentimentScore(this.brand.id, this.dateFilter)
                : null;

            this.benchmarkSentimentScoreFilter = this.brand
                ? getBenchmarkSentimentScore(this.brand.id, this.dateFilter)
                : null;

            this.directMentionSentimentScoreFilter = this.brand
                ? getDirectMessageSentimentScore(this.brand.id, this.dateFilter)
                : null;

            if (this.accountHasCx) {
                this.operationalSentimentFilter = this.brand
                    ? await getOperationalSentimentFilter(this.brand.id, this.dateFilter)
                    : null;
                this.reputationalSentimentFilter = this.brand
                    ? await getReputationalSentimentFilter(this.brand.id, this.dateFilter)
                    : null;
            }
        },

        async loadStats() {
            if (!this.brand) return;
            if (this.loading) return;

            this.loading = true;

            try {
                this.topicError = false;
                this.topicLoading = this.brandHasTopics;
                this.riskLoading = this.brandHasRisk;
                this.keywordLoading = true;

                try {
                    if (this.brandHasTopics) {
                        const topics = await loadTrendingTopics(this.brand.id, this.dateFilter);
                        if (topics.trendingTopics.length) {
                            this.topTopic = topics.trendingTopics.find(t => t.isSpike) ?? null;
                            if (!this.topTopic && topics.trendingTopics[0].delta >= 0.5) this.topTopic = topics.trendingTopics[0];
                            this.topicLoading = false;
                        } else this.topTopic = null;
                    }
                } catch (e) {
                    console.error("Error loading trending topics", e);
                    this.topicError = true;
                }

                try {
                    if (this.brandHasRisk) {
                        const risks = await loadTrendingRisk(this.brand.id, this.dateFilter);

                        if (risks.length) {
                            this.topRisk = risks.find(r => r.isSpike) ?? null;
                            if (!this.topRisk && risks[0].delta >= 0.5) this.topRisk = risks[0];
                            this.riskLoading = false;
                        } else this.topRisk = null;
                    }
                } catch (e) {
                    console.error("Error loading trending topics", e);
                    this.riskError = true;
                }

                const start = earliestDate(this.dateFilter);
                const end = latestDate(this.dateFilter);
                const keywords = await loadKeywordTrendsForBrand(this.brand.id, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
                if (keywords?.length) {
                    this.topKeyword = keywords.find(k => k.spike) ?? null;
                    if (this.topKeyword) {
                        fetchSentimentForKeyword(this.brand.id, this.topKeyword.word)
                            .then(sentiment => this.$set(this.topKeyword, "net", sentiment))
                            .catch(e => console.warn("Error reading keyword sentiment", e));
                    }
                    this.keywordLoading = false;
                }
            } catch(e) {
                console.error(e);
            }
            finally {
                this.loading = false;
                this.topicLoading = false;
                this.riskLoading = false;
                this.keywordLoading = false;
            }
        },
        showTooltip(event) {
            const target = event?.currentTarget;
            if (target) {
                const text = target.dataset.text;
                const heading = target.dataset.heading;
                if (!text) return;

                showTooltipComponent(
                    target,
                    SetupTooltip,
                    {
                        text: text,
                        heading: heading,
                        ops: false,
                        staff: false
                    },
                    {positions: ['bottom-left']}
                )
            }
        }
    }
}

</script>

<style scoped lang="sass">

h1, h2, h3, h4
    line-height: 1.05em

.overview-tool
    .overview-tool__body
        margin-top: 50px


.overview-tool__loading
    background: darkgrey
    margin: 5px 10px 0
    box-sizing: border-box
    border-radius: 10px
    height: 12px
    width: 45%
    animation: pulse-in-out 2s ease-in-out both infinite

.overview-tool__tabs
    --tab-size: clamp(240px, 15vw, 300px)
    display: grid
    grid-template-columns: var(--tab-size) 1fr var(--tab-size)
    grid-auto-rows: 110px
    grid-template-areas: 'enterprise container total'   'press container network'   'consumer container network'  'risk container network'   'topics container space' 'keywords container space'
    grid-row-gap: 10px

    .overview-tool__tab
        --be-colour-text-dark: white
        margin: 5px

        .overview-tool__tab-description
            display: flex
            flex-direction: column
            height: 100%
        h1
            color: var(--be-colour-muted-text-dark)
            margin: 0 0 0.2rem 0
            padding: 0

    .overview-tool__enterprise
        grid-area: enterprise
    .overview-tool__press
        grid-area: press
    .overview-tool__consumer
        grid-area: consumer
    .overview-tool__risk
        grid-area: risk
    .overview-tool__topics
        grid-area: topics
    .overview-tool__keywords
        grid-area: keywords
    .overview-tool__overall
        grid-area: total
    .overview-tool__networks
        grid-area: network
    .overview-tool__container
        grid-area: container

.overview-tool__overall
    text-align: center
    width: auto
    margin: 5px
    padding: 0
    display: grid
    justify-content: center
    align-content: center


@media (max-width: 1550px)
    .overview-tool__tabs
        --tab-size: clamp(240px, 18vw, 300px)
        grid-template-columns: var(--tab-size) 1fr
        grid-template-areas: 'total container' 'enterprise container'   'press container'   'consumer container'  'network container'  'network container' 'network container'  'keywords container'  'risk container'   'topics container'



.overview-tool__sentiment-metrics
    margin: 0 10px
    min-width: 800px
    display: grid
    grid-template-columns: 1fr 0.2fr
    grid-auto-rows: 110px


@media (max-width: 1300px)
    .overview-tool__sentiment-metrics
        min-width: unset
        grid-template-columns: minmax(400px, 600px) auto
        justify-items: center

@media (max-width: 1300px)
    .overview-tool__sentiment-metrics
        grid-template-columns: 1fr
        gap: 10px

.overview-tool__direct,
.overview-tool__overall-sentiment
    display: grid
    justify-content: center
    align-content: center
    ::v-deep .beef-tooltip
        height: 100px !important
        display: grid
        justify-content: center
        align-content: center


.overview-tool__overall-sentiment
    margin-right: 5px

    .overview-tool__public-sentiment
        margin-right: auto
        display: grid
        grid-template-columns: 1.4fr 1fr
        gap: 40px
        align-items: center



        .overview-tool__public-sentiment-header
            display: flex
            text-align: right
            justify-content: flex-end
            color: var(--be-colour-muted-text-dark)

            h1, h4
                text-align: right
                margin: 0
                padding: 0
                color: var(--be-colour-text-dark)
            h1
                font-size: 3rem
                padding-left: 20px
                box-sizing: border-box

    .overview-tool__benchmark-sentiment-description
        text-align: left
        color: var(--be-colour-muted-text-dark)


.overview-tool__no-cx
    color: var(--be-colour-muted-text-dark)
    font-style: italic
    em
        font-style: normal

.overview-tool__blank
    color: var(--be-colour-muted-text-dark)
    font-style: italic
    em
        font-style: normal

.overview-tool__sentiment-label
    display: inline-block
    min-width: 11ch
    text-align: end
    box-sizing: border-box
    padding-right: 1ch

.overview-tool--muted
    color: var(--be-colour-muted-text-dark)

.overview-tool__links
    margin-top: 10px
    display: flex
    flex-wrap: wrap

.overview-tool--nowrap
    white-space: nowrap
    margin-inline: 3px


.overview-tool__no-sentiment
    width: calc(100% - 10px)
    box-sizing: border-box
    margin-right: 10px
    display: grid
    justify-content: center
    align-content: center

    @keyframes pulse-in-out
        0%
            opacity: 0.5
        50%
            opacity: 1
        100%
            opacity: 0.5


.brand-sentiment__charts
    padding: 0
    margin: 10px


</style>
