/**
 * Extends Marionette's Layout (which extends ItemView) and adds data binding and validation. The view is only
 * rendered once, after that its modelBinder is responsible for updating it when the model changes. It calls
 * onFirstRender and fires a firstRender event after the first time it is rendered.
 *
 * Define a bindings literal to override or add bindings to the default bindings.
 *
 * The view options object (if any) is included in the data passed to its handlebars template as _options. This
 * is useful for conditional rendering of the template based on view options and not just the actual data. The view
 * is also passed as _view.
 *
 * You can provide contextual help by providing a field called contextualHelp. See Help.js for more details.
 * Help.js has a simple handlebars helper to add a help icon to your own handlebars template as well.
 */
import _ from 'underscore';
import {isFunction} from "@/app/utils/Util";

Beef.BoundItemView = Backbone.Marionette.Layout.extend({

    createBindings: function() {
        return Beef.ModelBinder.createDefaultBindings(this.el);
    },

    render: function() {
        if (this.modelBinder == null) {
            Backbone.Marionette.Layout.prototype.render.call(this, arguments);
            this.modelBinder = new Backbone.ModelBinder();
            var bindings = this.createBindings();
            if (this.bindings) {    // merge in any overrides to bindings
                var viewBindings = this.bindings;
                if (isFunction(viewBindings)) viewBindings = viewBindings(this);
                var that = this;
                _.each(viewBindings, function(value, key) {
                    var b = Object.assign(bindings[key] || {}, value);
                    if (b.selector) {
                        if (value.converterFactory) {
                            b.converter = value.converterFactory(that);
                            b.converterFactory = null;
                        }
                        bindings[key] = b;
                    }
                });
            }
            this.modelBinder.bind(this.model, this.el, bindings);
            Backbone.Validation.bind(this);
            this.triggerMethod("firstRender");
        }
        return this;
    },

    close: function() {
        if (this.modelBinder) {
            if (this.modelBinder._attributeBindings) {
                // For each binding with a "closable" attribute call its close method. Converters can set the
                // closable attribute and use this hook to remove popups from the DOM and so on.
                _.each(this.modelBinder._attributeBindings, function(value, key) {
                    for (var i = value.elementBindings.length - 1; i >= 0; i--) {
                        var eb = value.elementBindings[i];
                        if (eb.closable) eb.closable.close();
                    }
                });
            }
            this.modelBinder.unbind();
            delete this.modelBinder;
        }
        Backbone.Validation.unbind(this);
        Backbone.Marionette.Layout.prototype.close.call(this, arguments);
    },

    serializeData: function(){
        var data = Backbone.Marionette.ItemView.prototype.serializeData.call(this, arguments);
        data._options = this.options;
        return data;
    }
});
