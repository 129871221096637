<template>
    <sidebar-menu-item :active="active"
                       :indented="indented"
                       :disabled="disabled"
                       class="be-sidebar-nav-item"
                       :class="classes"
                       @mouseenter="$emit('mouseenter', $event)"
                       @click.stop.prevent="$emit('click', $event)">
        <a :href="href" >
            <slot/>
        </a>
    </sidebar-menu-item>
</template>

<script>
import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
export default {
    components: {SidebarMenuItem},
    props: {
        active: {type: Boolean, default: false},        // Whether this menu item is active / selected or not
        indented: {type: Boolean, default: false},      // Whether this menu item should be indented or not.
        disabled: {type: Boolean, default: false},       // Whether this menu item should be disabled or not
        href: {type: String, required: true}
    },

    computed: {
        classes() {
            const classes = [];
            if (this.disabled) classes.push("be-sidebar-nav-item--disabled");
            return classes;
        }
    }
}
</script>


<style scoped lang="sass">

.be-sidebar-nav-item
    &--disabled
        > a
            cursor: default
            pointer-events: none
            color: var(--be-colour-muted-text-dark)


</style>