
class Tree {
    constructor(mentions) {
        const idMap = new Map();
        mentions.forEach((mention, idx) => idMap.set(mention.id, idx));
        mentions.forEach(mention => {
            const replyToIdx = idMap.get(mention.replyToId);
            const reshareOfIdx = idMap.get(mention.reshareOfId);
            if (replyToIdx) {
                const replyTo = mentions[replyToIdx];
                replyTo.replyIds = [...(replyTo.replyIds ?? []), mention.id];
            }
            if (reshareOfIdx) {
                const reshareOf = mentions[reshareOfIdx];
                reshareOf.reshareIds = [...(reshareOf.reshareIds ?? []), mention.id];
            }
        });

        this.idMap = idMap;
        this.mentions = mentions;
    }

    getMention(mentionId) {
        const idx = this.idMap.get(mentionId);
        if (idx == null) return null;
        return this.mentions[idx];
    }

    expandChain(mentionId) {
        console.log(`Searching for mention id: ${mentionId}`);
        const mention = this.getMention(mentionId);
        if (!mention) return;

        mention.visible = true;
        console.log(`Mention id: ${mentionId} set to visible`);
        this.expandChain(mention.replyToId);
        this.expandChain(mention.reshareOfId);
    }

    expandReplies(mentionId, visible) {
        const mention = this.getMention(mentionId);
        if (!mention || !mention.replyIds) return;

        mention.replyIds.forEach(id => {
            const reply = this.getMention(id);
            if (reply) reply.visible = visible;
        });
    }

    expandReshares(mentionId, visible) {
        const mention = this.getMention(mentionId);
        if (!mention || !mention.reshareIds) return;

        mention.reshareIds.forEach(id => {
            const reply = this.getMention(id);
            if (reply) reply.visible = visible;
        });
    }
}

const createTree = function (mentions, selectedMentionId) {
    const tree = new Tree(mentions);
    tree.expandChain(selectedMentionId);
    tree.expandReplies(selectedMentionId, true);
    tree.expandReshares(selectedMentionId, true);
    return tree;
};

export {createTree, Tree };
