/**
 * Select one or more sources of conversation (Twitter, Facebook etc.). These become "link contains" filter
 * expressions. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
import {COMPOUND_LINKS, LINK_NAMES} from "@/app/utils/MediaLinks";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("LinkPicker").addInitializer(function(startupOptions) {

    this.items = LINK_NAMES;
    this.compoundItems = COMPOUND_LINKS;

    this.View = Beef.IncExPicker.View.extend({
        attributes: { class: "link-picker" },
        template: require("@/dashboards/filter/pickers/link/LinkPicker.handlebars"),
        items: this.items,
        bindings: {
            inc: { converter: Beef.SitePicker.converter, elAttribute: "data-value" },
            ex: { converter: Beef.SitePicker.converter, elAttribute: "data-value" }
        },
        initialFocus: function() {
            setTimeout(function() { Beef.FeedNotify.attachLinkPickerNotifications() });
        },
        attachPickers: function() {
            Beef.SitePicker.attach(this, ".inc", "inc");
            Beef.SitePicker.attach(this, ".ex", "ex");
        },
        onFirstRender: function() {
            Beef.IncExPicker.View.prototype.onFirstRender.call(this);
            if (this.options.noExclusions) {
                $('.ex', this.$el).closest('.control-group').remove();
                $('.inc', this.$el).closest('.control-group').find("label").text("These sites");
            }
        },
        isSpecial: function(code) {
            return this.items[code];
        }
    });

    var itemLookup = function(code) {
        var name = Beef.LinkPicker.items[code];
        return name ? name : code;
    };

    this.converter = createTagConverter({
        tooltip: function(code) { return itemLookup(code); },
        items: itemLookup,
        placeholder: "Sources"
    });

    /**
     * Attach a media picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
