<template>
    <drop-down-input v-model="val" :optional="optional" :multiselect="multiselect" :options="items"
                     search :label="label" :disabled="disabled" placeholder="Start typing timezone" :required="required"/>
</template>

<script>
import DropDownInput from "./DropDownInput"
import {TIMEZONES} from "@/app/utils/Timezone";

export default {
    name: "TimezoneInput",

    components: { DropDownInput },

    props: {
        label: String,
        value: [String, Array],
        optional: Boolean,
        multiselect: Boolean,
        required: Boolean,
        disabled: Boolean
    },

    data() {
        return {
            val: this.value
        }
    },

    watch: {
        value(v) {
            this.val = v
        },

        val(v) {
            this.$emit("input", v)
        }
    },

    computed: {
        items() {
            let timezoneList = {};

            Object.entries(TIMEZONES).forEach(timezone => {
                let id = timezone[0];
                let name = timezone[1]?.name;

                timezoneList[id] = {
                    id: id,
                    name: id,
                    subLabel: name
                }
            });

            return timezoneList;
        }
    }
}

</script>

<style scoped>

</style>