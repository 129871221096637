var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " per month";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label style=\"margin-bottom: 10px\">The following brands have been allocated specific portions of the quota:</label>\n                <table class=\"table table-condensed table-bordered table-hover\">\n                    <thead>\n                    <tr>\n                        <th>Brand</th>\n                        <th>Percentage Of Quota</th>\n                        <th>Mentions/Month</th>\n                    </tr>\n                    </thead>\n                    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"brandsWithCrowdQuota") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":32,"column":29}}})) != null ? stack1 : "")
    + "                    </tbody>\n                </table>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                            <td class=\""
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias3,"editCrowdQuotaPercentage",{"name":"ifallowed","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":102}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n                            <td class=\""
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias3,"editCrowdQuotaPercentage",{"name":"ifallowed","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":39},"end":{"line":29,"column":102}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"crowdQuotaPercentage") : depth0), depth0))
    + "</td>\n                            <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"crowdQuota") : depth0), depth0))
    + "</td>\n                        </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "editable";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <label>No brands have been assigned a specific percentage of the account crowd quota.</label>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"control-group\">\n            <label style=\"margin-bottom: 10px\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sharedQuota") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":52,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n            <table class=\"table table-condensed table-bordered table-hover\">\n                <thead>\n                <tr>\n                    <th>Brand</th>\n                </tr>\n                </thead>\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"otherBrands") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":65,"column":25}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    The "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"brandsWithCrowdQuota") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":24},"end":{"line":48,"column":69}}})) != null ? stack1 : "")
    + " quota of "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"sharedQuota") : depth0), depth0))
    + " mention(s) is\n                    shared between the following brands according to their relative volumes:\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " remaining";
},"13":function(container,depth0,helpers,partials,data) {
    return "                    Mentions for the following brands are not sent to The Crowd:\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                        <td class=\""
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"editCrowdQuotaPercentage",{"name":"ifallowed","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":35},"end":{"line":63,"column":98}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n                    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n    <div class=\"span3\">\n        <div class=\"control-group\" title=\"Up to this many mentions will be sent for human verification each month\">\n            <label>Crowd verified mention quota</label>\n            <div class=\"field\">\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"crowdVerifiedMentionsPerMonth") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"crowdVerifiedMentionsPerMonth") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":103}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"row-fluid\" style=\"margin-top: 20px\">\n    <div class=\"span8\">\n        <div class=\"control-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brandsWithCrowdQuota") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n<div class=\"row-fluid\">\n    <div class=\"span8\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"otherBrands") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    </div>\n</div>\n";
},"useData":true});