/**
 * A module for showing a list of jobs in the sidebar.
 */

import _ from 'underscore';
import {adjustContentHeight} from "@/app/Beef";

Beef.module("SearchJobList").addInitializer(function() {

    this.View = Backbone.Marionette.ItemView.extend({

        template: require("@/historical-search/job-list/SearchJobList.handlebars"),

        attributes: {
            class: "sidebar-list"
        },

        events: {
            "click a": "click"
        },

        modelEvents: {
            "change": "render"
        },

        onRender: function() {
            adjustContentHeight();
        },

        click: function(ev) {
            // stop the page from reloading
            ev.preventDefault();
            var href = $(ev.target).closest("a").attr("href");
            var id = parseInt(href.split("/").pop());
            if (id) {
                this.triggerJobClicked(id);
            }
        },

        triggerJobClicked: function(id) {
            var jobs = this.model.get("jobs");
            for (var i = 0; i < jobs.length; i++) {
                if (jobs[i].id == id) {
                    this.trigger("click", jobs[i]);  // TODO the click event is currently bound to the HistoricalSearchSection
                    break;
                }
            }
        },

        /**
         * Overrides the current jobs and updates the view.
         */
        update: function(jobs) {
            if (jobs) {
                this.updateStorage(jobs);
                this.model.set({ jobs: jobs }, { silent: true });
            }
            this.render();
        },

        /**
         * Overrides the ids stored in local storage with ids from the given job list. Duplicate ids are ignored.
         * @param {Object[]} jobs A list of jobs.
         */
        updateStorage: function(jobs) {
            try {
                var ids = [];
                for (var i = 0; i < jobs.length; ++i) {
                    if (jobs[i].id) {
                        ids.push(+jobs[i].id);
                    }
                }
                ids = _.unique(ids);
                localStorage.setItem(this.getStorageKey(), ids.join(','));
            } catch (e) {
                console.error(e.message);
            }
        },

        /**
         * Adds a job to the job list if it is not found.
         * @param job The job to add.
         */
        appendIfNotFound: function(job) {
            if (job) {
                var jobs = this.model.get("jobs") || [];
                var index = findIndexInJobs(job.id, jobs);
                if (index < 0) {
                    jobs.push(job);
                    this.update(jobs);
                }
            }
        },

        /**
         * Returns true if this module has jobs.
         */
        hasJobs: function() {
            return !!this.model.get("jobs");
        },

        /**
         * Returns the key that this module uses for local storage.
         */
        getStorageKey: function() {
            var code = this.model.get("accountCode");
            if (!code) {
                throw new Error("Cannot get namespace: account code must be set");
            }
            return  code + ":historical-search:job-list";
        },

        /**
         * Returns all the job ids stored in local storage.
         * @return {Number[]} A unique list of ids.
         */
        getStoredIds: function() {
            var ids;
            try {
                var stored = localStorage.getItem(this.getStorageKey());
                if (stored) {
                    ids = stored.split(',').map(function(id) {
                        return +id;
                    });
                    ids = _.unique(ids);
                }
            } catch (e) {
                console.error(e.message);
            }
            return ids ? ids : [];
        }
    });

    /**
     * Finds a search job in an array of jobs.
     * @return {Number} The index of the search job. Returns -1 if the id is not found.
     */
    function findIndexInJobs(id, jobs) {
        jobs = jobs|| [];
        var found = false;
        var index = -1;
        while (!found && (++index < jobs.length)) {
            found = (id === jobs[index].id);
        }
        return found ? index : -1;
    }
});
