import VuexStore from "@/store/vuex/VuexStore";
import Vue from "vue";

const geographic = {
    title: "Geography",
    description: "See where the authors of your conversation are from.",
    tooltip: "Geographic metrics lets you plot information about where your authors are from",
    expanded: `The authors of your conversations can be from all over the world. For those that are sharing their location,
we let you know where they are from`,
    image: require("../assets/geography.png"),
    metrics: [
        {
            id: "geography/worldmap",
            title: "World map",
            description: "This map illustrates which countries or cities your authors are from",
            hero: true,
            image: require("../assets/geography/world-map.png"),
            widgetDesc: {
                type: "WorldMap",
                country: "'world'",
                width: 8,
                height: 8
            },
            options: [
                {
                    key: "cities",
                    title: "See data by city",
                    tooltip: "Add a map of South Africa to your dashboard",
                    action(metric) {
                        return {
                            id: "geoegraphy/worldmap/cities",
                            widgetDesc: Object.assign(
                                {},
                                metric.widgetDesc,
                                {"group-by-city": true})
                        }
                    }
                },
                {
                    key: "ZA",
                    title: "Map of South Africa",
                    tooltip: "Add a map of South Africa to your dashboard",
                    action(metric) {
                        return {
                            id: "geoegraphy/worldmap/za",
                            widgetDesc: Object.assign(
                                {},
                                metric.widgetDesc,
                                {
                                    caption: "South Africa",
                                    "country": "'ZA'"
                                })
                        }
                    }
                },
                {
                    key: "SA",
                    title: "Map of Saudi Arabia",
                    tooltip: "Add a map of Saudi Arabia to your dashboard",
                    action(metric) {
                        return {
                            id: "geoegraphy/worldmap/sa",
                            widgetDesc: Object.assign({},
                                metric.widgetDesc,
                                {
                                    caption: "Saudi Arabia",
                                    "country": "'SA'"
                                })
                        }
                    }
                },
                {
                    key: "AE",
                    title: "Map of the UAE",
                    tooltip: "Add a map of the United Arab Emirates to your dashboard",
                    action(metric) {
                        return {
                            id: "geoegraphy/worldmap/ae",
                            widgetDesc: Object.assign({},
                                metric.widgetDesc,
                                {
                                    caption: "The UAE",
                                    "country": "'AE'"
                                })
                        }
                    }
                },
                {
                    key: "GB",
                    title: "Map of the UK",
                    tooltip: "Add a map of the United Kingdom to your dashboard",
                    action(metric) {
                        return {
                            id: "geoegraphy/worldmap/uk",
                            widgetDesc: Object.assign({},
                                metric.widgetDesc,
                                {
                                    caption: "The United Kingdom",
                                    "country": "'GB'"
                                })
                        }
                    }
                }


            ]
        },
        {
            id: "countries/columns",
            title: "Top countries",
            description: "This breaks down the countries that your authors are most frequently from",
            keywords: ['country'],
            image: require("../assets/geography/country.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "country",
                yAxis: "authorIdCount",
                width: 8,
                height: 4
            },
            options: [
                {
                    key: "volume",
                    title: "As mention volumes",
                    tooltip: "See mentions volumes per country",
                    action() {
                        return {
                            id: "countries/volumes",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "country",
                                yAxis: "mentionCount",
                                width: 8,
                                height: 4
                            },
                        }
                    }
                },
                {
                    key: "sentiment",
                    title: "As Net Sentiment",
                    tooltip: "See the overall, public Net Sentiment per country",
                    action() {
                        return {
                            id: "countries/net",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "country",
                                yAxis: "totalSentimentPercent",
                                width: 8,
                                height: 4,
                                filter: "visibility is public"
                            },
                        }
                    }
                }

            ]

        },
        {
            id: "regions/columns",
            title: "Top regions",
            description: "Shows the states and provinces that your authors are from",
            image: require("../assets/geography/region.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "region",
                yAxis: "authorIdCount",
                width: 8,
                height: 4
            },
            options: [
                {
                    key: "volume",
                    title: "As mention volumes",
                    tooltip: "See mentions volumes per region",
                    action() {
                        return {
                            id: "countries/volumes",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "region",
                                yAxis: "mentionCount",
                                width: 8,
                                height: 4
                            },
                        }
                    }
                },
                {
                    key: "sentiment",
                    title: "As Net Sentiment",
                    tooltip: "See the overall, public Net Sentiment per region",
                    action() {
                        return {
                            id: "countries/net",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "region",
                                yAxis: "totalSentimentPercent",
                                width: 8,
                                height: 4,
                                filter: "visibility is public"
                            },
                        }
                    }
                }

            ]

        },
        {
            id: "cities/columns",
            title: "Top cities",
            image: require("../assets/geography/city.png"),
            description: "See what cities your authors are from",
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "city",
                yAxis: "authorIdCount",
                width: 8,
                height: 4
            },
            options: [
                {
                    key: "volume",
                    title: "As mention volumes",
                    tooltip: "See mentions volumes per city",
                    action() {
                        return {
                            id: "countries/volumes",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "city",
                                yAxis: "mentionCount",
                                width: 8,
                                height: 4
                            },
                        }
                    }
                },
                {
                    key: "sentiment",
                    title: "As Net Sentiment",
                    tooltip: "See the overall, public Net Sentiment per city",
                    action() {
                        return {
                            id: "countries/net",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "city",
                                yAxis: "totalSentimentPercent",
                                width: 8,
                                height: 4,
                                filter: "visibility is public"
                            },
                        }
                    }
                }

            ]

        },
    ]
};

export default geographic;
let isInitialised = false;

export async function init() {
    const account = VuexStore.state.account;
    const getLocation = id => VuexStore.dispatch('locations/getLocation', id);

    if (isInitialised) return;
    isInitialised = true;
    if (account.country) {
        const countries = new Set(['ZA', 'AE', 'SA', 'GB']);
        if (!countries.has(account.country)) {
            const country = await getLocation(account.country);
            const name = country.label;
            const map = geographic.metrics.find(m => m.id === "geography/worldmap");
            if (map) {
                map.options.push(                {
                        key: "default",
                        title: `Map of ${name}`,
                        tooltip: `Add a map of ${name} to your dashboard`,
                        action(metric) {
                            return {
                                id: "geoegraphy/worldmap/default",
                                widgetDesc: Object.assign({},
                                    metric.widgetDesc,
                                    {
                                        caption: `${name}`,
                                        "country": `'${account.country}'`
                                    })
                            }
                        }
                    }
                )
            }
        }
    }
}