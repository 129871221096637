<template>
    <section ref="markdown">
    </section>
</template>

<script>
    export default {
        name: "MarkdownText",

        props: {
            text: String
        },

        data() {
            return {
                renderer: null
            }
        },

        mounted() {
            this.$nextTick(() => {
                if (!this.text) return;

                const result = Beef.Markdown.render(this.text, "published inthelast day");
                this.renderer = result;
                this.$refs.markdown.innerHTML = result.text;
                result
                    .then(updates => {
                        if (updates.size){
                            updates.forEach((html, selector) => {
                                const item = this.$refs.markdown.querySelector(selector);
                                item.innerHTML = html;
                                item.classList.remove('text-loading');
                            });
                        }
                    })
            })
        },

        beforeDestroy() {
            if (this.renderer) {
                this.renderer.destroy();
            }
        }
    }
</script>

<style scoped lang="sass">

</style>