import Vue from 'vue';
import { getAllRiskProductSegmentLists, getAllCxSegmentLists } from "@/app/utils/Segments";
import {rpcs as rpcsStats} from "../../../markdown/MarkdownExamples";
import VuexStore from "@/store/vuex/VuexStore";

export const rpcsType = {
    type: "FantasticChart",
    xAxis: "rpcs",
    yAxis: "mentionPercent",
    width: 8,
    height: 4,
    geometry: "columns",
    notification: "Added a <strong>Priority Conversations</strong> metric"
};

const priority = Vue.observable({
    title: "Priority conversations",
    expanded:
`We help you mitigate risk, improve retention & acquisition rates, and deliver superior customer experience by categorising your conversation with four priority conversation tags.
    
- {{Risk}} mentions that pose an immediate reputational risk
- {{Purchase}} mentions are from a prospective customer who wants to purchase your product or service
- {{Cancel}} mentions are from a customer looking to cancel their service or not buy from you again
- {{Service}} mentions are from a customer that require service or describe their experience

`,
    hide: true,
    image: require("../assets/priority.png"),
    metrics: [
        {
            id: "rpcs/columns",
            title: "Priority conversation volumes",
            image: require("../assets/priority/volumes.png"),
            hero: true,
            description: "See the volume of conversation you have categorised by their priority",
            more: "Priority mentions are those that relate to risk factors (tagged with Risk), customer acquisition and retention (tagged with Purchase and Cancel) and general service enquiries (tagged with Service)",
            keywords: ["rpcs", "risk", "service", "cancel", "purchase"],
            widgetDesc: rpcsType,
            options: [
                {
                    key: "time",
                    title: "See priority volumes over time",
                    tooltip: "Displays a line chart of mentions classified over time to one of our priority groups",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "rpcs/lines",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "published",
                                    yAxis: "mentionCount",
                                    compare: "rpcs",
                                    width: 10,
                                    height: 4,
                                    geometry: "lines"
                                }
                            })
                    }
                },
                {
                    key: "volume",
                    title: "See high vs low priority volumes",
                    tooltip: "See how many of your mentions can be prioritised over mentions that are low priority",
                    action: async metric => {
                        return Object.assign({},
                            metric,
                            {
                                id: "priority/columns",
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "priority",
                                    yAxis: "mentionPercent",
                                    width: 4,
                                    height: 4,
                                    notification: "Added a metric showing <strong>High vs low priority volumes</strong>"
                                }
                            })
                    }
                },
                {
                    key: "rpcs",
                    title: "As text",
                    tooltip: "A breakdown of your priority conversation volumes given as a table",
                    action() {
                        return {
                            id: "rpcs/text",
                            widgetDesc: {
                                type: "Text",
                                width: 2,
                                height: 4,
                                text: rpcsStats,
                                notification: "Added <strong>Priority Conversation Volumes</strong> as a text metric"
                            }
                        }
                    }
                }

            ]

        },
        {
            id: "response/flowmap",
            title: "Priority conversation flow map",
            description: "How well does the brand responds to publicly to online conversation",
            more: "The priority conversation flow maps illustrate a brand’s response rate to inbound priority conversation from customers. The analysis only looked at responses to the customer’s first post or “head post” in a conversation thread. It includes both direct (mentions brand handle) and indirect (does not mention brand handle) customer interactions.",
            image: require("../assets/priority/flow-map.png"),
            keywords: ["sankey", "chankey", "engage"],
            widgetDesc: {
                type: "ResponseSankey"
            }
        },
        {
            id: "response/table",
            title: "Priority conversation response times",
            description: "How quickly does the brand respond to public, online conversation",
            more: "This is the average time it takes a brand to respond to customers requesting a assistance. It looks at public Twitter posts engaging a brand's support profiles to measure how well the brand responds to priority conversations",
            image: require("../assets/priority/responses.png"),
            widgetDesc: {
                type: "ResponseTable"
            }
        },
        {
            id: "venn",
            title: "Venn diagram",
            keywords: ["euler"],
            description: "Compare sizes and overlap of sets of your priority mentions",
            image: require("../assets/priority/venn.png"),
            widgetDesc: {
                type: "Venn"
            }
        }
    ]
});

export default priority;


const engageSankeyReference = {
    tooltip: "Add a metric that describes the priority conversation flow",
    type: "Text",
    width: 4,
    caption: "Flow map reference",
    height: 6,
    text: `# {{brand}}: priority conversation flow

This chart shows the flow of tickets through the Engage platform in the last month.

Interactions with the brand are prioritised as follows:

- {{risk}}
- {{purchase}}
- {{cancel}}
- {{service}}

When a ticket is Engaged with by an agent, they decide whether it requires a response. Tickets that require no response are set as Closed. Tickets that require a response proceed through further milestones before reaching a final outcome.

If a ticket is set as Pending and no response is received from the customer, it may be automatically closed, depending on the team's configuration. Otherwise, agents must select an outcome corresponding to the ticket's conclusion.`,
    notification: "Added a <strong>Flow Diagram Reference Sheet</strong>"

};


export async function init() {
    const [cx, riskSegments] = await Promise.all([getAllCxSegmentLists(), getAllRiskProductSegmentLists()]);
    priority.hide = !(cx?.length || riskSegments?.length);
    const account = VuexStore.state.account;

    // Some accounts, like Barloworld, may have risk but no CX. 
    if (cx && cx.length || riskSegments && riskSegments.length) {
        priority.hide = false;

        // Change the copy because we now have Engage
        if (account.hasEngage) {
            const flowmap = priority.metrics.find(m => m.id === 'response/flowmap');
            if (flowmap) {
                flowmap.reference = engageSankeyReference;
                flowmap.description = "See the amount of conversation you categorised by priority and processed by your Engage team";
                flowmap.more = "Priority mentions are those that relate to risk factors, customer acquisition and retention, as well as general service enquiries";
                flowmap.widgetDesc.width = 6;
                flowmap.widgetDesc.height = 3;
            }
        }

        if (riskSegments && riskSegments.length) {
            priority.description = `See conversation and metrics around risk factors,
            customer acquisition and retention, and service queries.`;
        } else {
            priority.description = `See conversation and metrics around 
            customer acquisition and retention, and service queries.`;
        }

        priority.metrics.push({duplicate: "service"});
        priority.metrics.push({duplicate: "serviceVolumes", additional: true});
        priority.metrics.push({duplicate: "cancelVolumes", additional: true});
        priority.metrics.push({duplicate: "purchaseVolumes", additional: true});


    }

    if (riskSegments?.length) {
        if (riskSegments.length > 1) {
            priority.metrics.push({duplicate: "risk"});
        } else {
            priority.metrics.push({duplicate: `risk:${riskSegments[0].id}`});
        }

        priority.metrics.push({duplicate: "riskVolumes", additional: true});
    }


}