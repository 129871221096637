import _ from 'underscore';
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";

/**
 * Drill down by adding/removing brands to the filter.
 */
Beef.module("Widget.SubBrandSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Sub-Brands",
        description:    "Interactively filter by sub-brand",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.BrandSelector.View.extend({

        refresh: function() {
            this.fetchBrands(function(data) {
                let f = this.model.getSectionModel().get('filter');
                this._lastFilter = f;

                // try to get selected root brand from root brand drill down (if there is one)
                let selectedBrand = this.getCurrentValueArray();
                if (!selectedBrand?.length) {
                    // use filter if we can't find brand from root brand drill down
                    selectedBrand = getBrandsInFilter(f)?.include;
                }

                if (!selectedBrand?.length) {
                    this.text([ { name: "(please ensure that a root brand is selected in a root brand drill down, or your section filter includes a root brand)" } ]);
                    return;
                }

                this.roots = Beef.Widget.BrandSelector.findRootBrands(selectedBrand);

                if (this.roots.length === 0) {
                    this.text([ { name: "(please ensure that a root brand is selected in a root brand drill down, or your section filter includes a root brand)" } ]);
                } else if (this.roots.length > 1) {
                    this.text([ { name: "(please ensure that only a single root brand is selected in a root brand drill down, or your section filter only includes a single root brand)" } ]);
                } else {
                    let b = data.map[this.roots[0]];
                    let brands = b.children ? b.children.slice(0) : [];
                    if (!brands?.length) {
                        this.text([ { name: "(this brand has no sub-brands)" } ]);
                    } else {
                        brands.sort(function(a, b) { return a.name < b.name ? -1 : a.name > b.name ? +1 : 0; });
                        this.text(brands);
                    }
                }
            });
        },

        interactiveFilterModelChanged: function(ifm, value) {
            this.fetchBrands(function(data) {
                let a = value ? value.split(' ') : [];
                let roots = Beef.Widget.BrandSelector.findRootBrands(a);
                if (!_.isEqual(roots, this.roots)) {
                    let current = this.getCurrentValueArray();
                    if (this._value) {
                        current = current.filter(item => item !== this._value && item !== "/");
                        current = current.join(" / ");
                        this._value = null;
                        ifm.set(this.filterAttribute, current);
                    }
                    this.refresh();
                }

                // the fetchBrands callback in refresh will run synchronously because the brands will be cached so this is ok
                Beef.Widget.SelectorWidget.View.prototype.interactiveFilterModelChanged.call(this, ifm, value);
            });
        },

        getDefaultValue: function() {
            return this.roots && this.roots.length > 0 ? this.roots.join(" ") : null;
        },

        onValueClicked(value) {
            Beef.Widget.BrandSelector.View.prototype.onValueClicked.call(this, value);
            this._value = value;
        }
    });
});