var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"dialog-body dialog-body-notitle\">\n    ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"helpButton") || (depth0 != null ? lookupProperty(depth0,"helpButton") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"helpButton","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":34}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"helpButton")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    <div class=\"row-fluid filter\"></div>\n    <div class=\"dialog-button-bar\">\n        <a class=\"advanced btn btn-link pull-left\" title=\"Use the advanced filter editor to type out a DataEQ filter (experts only)\">Show the advanced filter editor</a>\n        <a class=\"basic btn btn-link pull-left\">Return to the basic filter editor</a>\n        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n</div>\n\n\n";
},"useData":true});