<template>
    <section class="be-author-handle">
        <old-tooltip :label="mention.authorProfileLink ? 'Open the author\'s profile in a new window' : null">
            <a :href="mention.authorProfileLink" target="_blank">
                <span class="be-author-handle__name">{{name}} </span>
                <span class="be-author-handle__handle">{{ prefix }}{{ handle }}</span>
            </a>
        </old-tooltip>
    </section>
</template>

<script>
import OldTooltip from "@/components/tooltip/OldTooltip";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters} from "vuex";
import {escapeExpression} from "@/app/utils/StringUtils";

export default {
    components: {OldTooltip},
    store: VuexStore,

    props: {
        mention: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            profile: null
        }
    },

    inject: ['isPrintMedia', 'isNovusBroadcastMedia'],

    watch: {
        'mention.link'() {
            this.loadProfile();
        }
    },

    created() {
        this.loadProfile();
    },

    computed: {
        ...mapGetters('profiles', ['handleIdToProfile']),

        prefix() {
            switch (this.mention.socialNetwork?.id) {
                case "TWITTER":
                case "INSTAGRAM":
                    return this.mention.authorHandle ? '@' : '';
                default:
                    return '';
            }
        },
        name() {
            switch (this.mention.socialNetwork?.id) {
                case 'INSTAGRAM':
                    if (this.mention.authorName) return this.mention.authorName;
                    if (this.mention.authorHandle) return 'Instagram User';
                    return 'Anonymous Instagram User';
                default:
                    if (this.isPrintMedia() || this.isNovusBroadcastMedia()) {
                        return this.mention.title;
                    }

                    return this.mention.authorName ?? this.mention.site;
            }
        },
        handle() {
            switch (this.mention.socialNetwork?.id ?? null) {
                case "TWITTER":
                case "INSTAGRAM":
                    return this.mention.authorHandle;
                case "FACEBOOK":
                    if (this.profile?.name) {
                        const pageName = escapeExpression(this.profile.name);
                        if (this.mention.visibility) {
                            return "DMing with " + pageName;
                        } else {
                            return"On page " + pageName;
                        }
                    } else {
                        return '';
                    }
                case null:
                    return this.mention.site;
                default:
                    if (this.isPrintMedia() || this.isNovusBroadcastMedia()) {
                        return this.mention.site;
                    }

                    return this.mention.authorHandle ?? this.mention.authorName;
            }
        }
    },

    methods: {
        ...mapActions('profiles', ['refreshProfiles']),

        async loadProfile() {
            const link = this.mention?.link;
            this.profile = null;
            if (this.mention.socialNetwork?.id === 'FACEBOOK') {
                const split = link.substr(link.indexOf('.com')).split('/');
                if (split.length >= 2) {
                    await this.refreshProfiles();
                    this.profile = this.handleIdToProfile(split[1]);
                }
            }
        }
    }

}
</script>


<style scoped lang="sass">

.be-author-handle__name
    color: #222

.be-author-handle__handle
    color: #666
    font-size: 0.9em

a:hover
    .be-author-handle__name,
    .be-author-handle__handle
        color: black

a:focus
    outline: none

</style>