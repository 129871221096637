/**
 * Displays a reply and reshare chain of mentions. Allows for replying to the mention as well.
 */
import {toGrouseLink} from "@/data/Grouse";
import {getGreenScheme} from "@/app/utils/Colours";
import {formatNumber} from "@/app/utils/Format";
import _ from 'underscore';
import moment from "moment";
import {once} from "@/app/utils/Functions";
import {account} from "@/app/utils/Account";
import {adjustContentHeight} from "@/app/Beef";
import {grouse} from "@/store/Services";
import {features} from "@/app/Features";
import MentionConversationView from "@/conversation/vue/MentionConversationView";
import Vue from "vue";

Beef.module("MentionConversation").addInitializer(function (_startupOptions) {

    //--------------------------------------------------------

    /**
     * Represents a row of information that we display
     * - mention A mention that is associated with this row.
     */
    const RowData = function (options) {
        this.mention = options.mention;
        this.message = options.message;
        this.reshares = [];
        this.replies = [];
        this.depth = 0;
        return this;
    };

    RowData.prototype.addReshare = function (mention) {
        this.reshares.push(mention);
        return this;
    };

    RowData.prototype.addReply = function (row) {
        const last = _(this.replies).last();
        row.previous = last ? last : null;

        if (last) last.next = row;

        this.replies.push(row);
        row.depth = this.depth + 1;
        return this;
    };

    RowData.prototype.getHeight = function () {
        if (!this.mention) return 0;
        return this.mention.graphView.$el.height();
    };

    RowData.prototype.getHeightWithChildren = function () {
        let last = _(this.replies).last(),
            extra = 0;

        if (this.replyOverflow) {
            extra = last.getPostHeight();

            while (this.replyOverflow && last.replies.length) {
                last = _(last.replies).last();
                extra += last.getPostHeight();
            }
        }

        // We need to remove the height of the parent (which related to the last mentions finalY position),
        // and then also add the height of the final mention (which is not considered for its final y position).
        const shift = last.mention.graphView.$el.height() - this.mention.graphView.$el.height();
        return last.mention.finalY - this.mention.finalY + extra + shift;
    };

    RowData.prototype.getPostHeight = function () {
        if (this.replyOverflow) return 66;
        return 0;
    };

    //--------------------------------------------------------

    /**
     * Create and display the reply view. The given mention is the one for whom related conversation
     * should be returned.
     */
    this.show = function (mentionItem, accountCode) {
        const $originalEl = mentionItem.view.$el;
        const o = $originalEl.offset();
        o.left = o.left - $(window).scrollLeft();
        o.top = o.top - $(window).scrollTop();

        const model = new Backbone.Model({
            accountCode: accountCode,
            selection: mentionItem,
            offset: o
        });

        // each mention item has a collection reference that will be changed when we add it to our collection so
        // preserve this info
        const originalCollection = mentionItem.collection;

        const collection = new Backbone.Collection();
        collection.url = toGrouseLink('/v4/accounts/' + accountCode + '/mentions', null, true);
        collection.add(mentionItem);

        if (features.newMentionConversationView()) {
            showMcBackboneViewWrapper(mentionItem, accountCode);
            return;
        }

        const v = new View({
            model: model, collection: collection, originalCollection: originalCollection,
            cache: mentionItem.view.cache
        });
        if (originalCollection) {
            v.on("close", function () {
                const models = originalCollection.models;
                for (let i = 0; i < models.length; i++) {
                    const m = models[i];
                    m.collection = originalCollection;
                    m.graphView = null;
                }
            });
        }

        const authorName = mentionItem.get('author')?.name || mentionItem.get('authorName') || 'Unknown Author';
        Beef.overlay.show(v, `Conversation with ${authorName}`);
        v.fetchGraph();
        return v;
    };

    function showMcBackboneViewWrapper (mentionItem, accountCode) {
        const authorName = mentionItem.get('author')?.name || mentionItem.get('authorName') || 'Unknown Author';
        const conversationId = mentionItem.get("conversationId");
        const id = mentionItem.id;
        const published = mentionItem.get("published");
        const authorId = mentionItem.get("authorHandleId") || mentionItem.get("authorHandle");
        const toId = mentionItem.get("toHandleId") || mentionItem.get("toHandle");
        const isDirectMessage = mentionItem.get("visibility")?.id === 'DIRECT_MESSAGE';

        const selectedMention = {
            authorName, conversationId, id, published, authorId, toId, isDirectMessage
        };

        const mentionConversationView = new McBackboneViewWrapper({
            component: MentionConversationView, props: {selectedMention, accountCode}
        });
        Beef.overlay.show(mentionConversationView, `Conversation with ${authorName}`);
    }

    const McBackboneViewWrapper = Backbone.Marionette.ItemView.extend({

        attributes: { class: "vue-mention-conversation"},

        initialize: function() {
        },

        getTemplate() {
            return () => '<div class="mention-replies" id="mention-conversation-container-id"></div>'
        },

        onRender() {
            const ComponentClass = Vue.extend(this.options.component);
            const propsData = Object.assign({}, this.options.props, { onClose: () => this.close()});

            this.vm = new ComponentClass({propsData});
            setTimeout(() => { this.vm.$mount("#mention-conversation-container-id") }, 1);
        },

        onClose() {
            this.vm.$destroy();
        }
    });

    const View = Backbone.Marionette.CompositeView.extend({
        template: require("@/conversation/MentionConversation.handlebars"),

        attributes: {class: 'mention-replies', tabindex: "0"},

        itemViewContainer: ".mentions",

        itemView: Beef.MentionItem.View,

        initialize: function (options) {
            this.collection.accountCode = this.accountCode = this.model.get('accountCode');
            this.originalCollection = options.originalCollection;
            // sort oldest first
            this.collection.comparator = function (a, b) {
                return a.attributes.published.localeCompare(b.attributes.published);
            };
            this.layoutOffsetX = 0;
            this.layoutOffsetY = 0;
            this.bars = [];
            this.reshareBars = [];

            this.boundHeightChangeEvent = this.heightChangedEvent.bind(this);
            $('#content').on('change:height', this.boundHeightChangeEvent);

        },

        events: {
            "click .mention-replies-close": "close",
            "click .mention-replies-graph": "graphClicked",
            "click .mention-replies-menu": "menuClicked"
        },

        modelEvents: {
            "change:selection": "updateAddressBar"
        },

        duration: 750,
        maxReplies: 20,
        maxReshares: 2,
        defaultHalfHeight: 85, // Defined in the CSS by negative margins on MentionItem

        appendHtml: function (compositeView, itemView) {
            // We want backbone to add the item items in reverse order, so that later things appear
            // beneath earlier things.
            compositeView.$('.mentions').prepend(itemView.el);
        },

        fetchGraph: function () {
            const ids = _.uniq(this.collection.pluck('id'));
            const filter = `ID in ('${ids.join(`','`)}')`;
            const qs = `${Beef.MentionList.getMentionQueryString(null, filter, null, 100, null, true)}&fetchGraph=true`;

            this.fetchGraphPromise = grouse.post("/v4/accounts/" + this.accountCode + "/mentions/list", qs);
        },

        /**
         * Add a collection of mentions to the view. This may not display all of them, and should
         * only be used once (calling it multiple times is likely not what you want to be doing).
         */
        addMentions: function (data) {
            data.sort(function (lhs, rhs) {
                if (lhs.replyToId === rhs.id || lhs.reshareOfId === rhs.id) return 1;
                if (rhs.replyToId === lhs.id || rhs.reshareOfId === lhs.id) return -1;
                return new moment(lhs.published).isBefore(new moment(rhs.published)) ? -1 : 1;
            });
            const dataRoot = data[0];
            const rootId = data[0].id;
            for (let curIndex = 1; curIndex < data.length; curIndex++) {
                const cur = data[curIndex];
                if (!cur.replyToId && !cur.reshareOfId) {
                    cur.replyToId = rootId;
                    if (!dataRoot.replies) dataRoot.replies = [cur.id];
                    else dataRoot.replies.push(cur.id);
                }
            }
            this.data = data;

            const idToData = this.idToData = {};
            const reshareCounts = this.reshareCounts = {},
                replyCounts = this.replyCounts = {};
            let reshareId = this.collection.models[0].get('reshareOfId'),
                replyId = this.collection.models[0].get('replyToId');
            let i, m;
            if (reshareId) reshareCounts[reshareId] = 1;
            if (replyId) replyCounts[replyId] = 1;

            const skipped = {};

            // Now we begin adding the mentions that we want to display
            for (i = 0; i < data.length; i++) {
                m = data[i];
                this.idToData[m.id] = m;

                const model = this.collection.get(m.id);
                if (model) {
                    model.set(m);
                    this.addMentionToRows(model);
                } else {
                    reshareId = m.reshareOfId;
                    replyId = m.replyToId;

                    if (reshareId) {
                        reshareCounts[reshareId] = reshareCounts[reshareId] + 1 || 1;
                        if (reshareCounts[reshareId] > this.maxReshares || skipped[reshareId]) {
                            skipped[m.id] = true;
                            if (this.idToRow[reshareId]) this.idToRow[reshareId].reshareOverflow = true;
                            continue;
                        }
                    }

                    if (replyId) {
                        replyCounts[replyId] = replyCounts[replyId] + 1 || 1;
                        if ((replyCounts[replyId] > this.maxReplies && !m.replies) || skipped[replyId]) {
                            skipped[m.id] = true;
                            if (this.idToRow[replyId]) this.idToRow[replyId].replyOverflow = true;
                            continue;
                        }
                    }

                    this.addIndividualMention(m);
                }
            }

            for (i = 0; i < data.length; i++) {
                m = data[i];
                // These need to be sorted for when we expand them later. We want to expand
                // them in date order
                if (m.replies) m.replies.sort(function (lhs, rhs) {
                    return new moment(idToData[lhs].published).isBefore(new moment(idToData[rhs].published)) ? -1 : 1;
                });
            }

            this.layout();
        },

        addMentionToRows: function (model) {
            if (!this.rows) this.rows = [];
            if (!this.idToRow) this.idToRow = {};
            if (!model.reshares) model.reshares = [];
            if (!model.replies) model.replies = [];
            if (!model.children) model.children = [];

            let curReshareOfId = model.get('reshareOfId');
            let row;
            let previousRow;
            while (curReshareOfId) {
                row = this.idToRow[curReshareOfId];
                if (!row) break;
                if (row.get && row.get('reshareOfId')) {
                    curReshareOfId = row.get('reshareOfId');
                } else {
                    break;
                }
                previousRow = row;
            }
            if (!row) {
                row = previousRow;
            }
            if (model.get('reshareOfId') && !row) {
                console.debug("Unable to find original mention for mention", model, "which is a reshare of", model.get('reshareOfId'));
            }
            if (row) {
                row.addReshare(model);
                model.parent = row.mention;
                row.mention.children.push(model);
                row.mention.reshares.push(model);

                this.idToRow[model.get('id')] = model;
                return row;
            }

            row = new RowData({mention: model});
            const pictures = row.mention.graphView.$el.find(".content > .mention-photo > img");
            const that = this;
            if (pictures && pictures.length) {
                pictures.on('load', function () {
                    if (that.rows && that.rows.length) {
                        that.layout();
                    }
                }).each(function () {
                    if (this.complete) $(this).load();
                });
            }
            this.rows.push(row);
            this.idToRow[model.get('id')] = row;

            let parent;
            if (model.get('replyToId')) {
                parent = this.idToRow[model.get('replyToId')];
                if (!parent) {
                    console.debug("Unable to find reply to mention for mention", model);
                } else {
                    model.parent = parent.mention;
                    parent.mention.children.push(model);
                    parent.mention.replies.push(model);
                    parent.addReply(row);
                }
            }
            return row;
        },

        addIndividualMention: function (mention) {
            let model;
            if (this.originalCollection) model = this.originalCollection.get(mention.id);
            if (model) model.set(mention);
            else model = new Beef.MentionItem.Model(mention);
            this.collection.add(model);
            return this.addMentionToRows(model);
        },

        isReplyOverflow: function (model) {
            return model.replies.length < (this.idToData[model.get('id')].replies || []).length;
        },

        getOverflowReplyCount: function (model) {
            return this.replyCounts[model.get('id')] - model.replies.length;
        },

        getOverflowReshareCount: function (model) {
            return this.reshareCounts[model.get('id')] - model.reshares.length;
        },

        /**
         * Expand the replies of the given model. This will add the new models to this.collection,
         * and animate their addition to the page.
         */
        expandReplies: function (row) {
            const model = row.mention;
            const original = _(this.data).find(function (d) {
                return d.id === model.get('id');
            });
            let found = 0;
            let m, newRow;

            for (let i = 0; i < original.replies.length && found < this.maxReplies; i++) {
                if (!_(model.replies).find(function (d) {
                    return original.replies[i] === d.get('id');
                })) {
                    found++;

                    m = this.idToData[original.replies[i]];
                    newRow = this.addIndividualMention(m);

                    if (m.reshares) {
                        for (let j = 0; j < m.reshares.length; j++) {
                            if (j >= this.maxReshares) {
                                newRow.reshareOverflow = true;
                                this.reshareOverflow.push(newRow);
                                break;
                            }
                            this.addIndividualMention(this.idToData[m.reshares[j]]);
                        }
                    }
                }
            }

            if (found) {
                if (this.getOverflowReplyCount(model) === 0) row.replyOverflow = false;
                this.layout();
            }
        },

        itemViewOptions: function (model, _index) {
            let usePublishFull = Beef.Conversation.isFacebook(model) || Beef.Conversation.isInstagram(model)
                || Beef.Conversation.isPrint(model) || Beef.Conversation.isYoutube(model) || Beef.Conversation.isLinkedIn(model)
                || Beef.Conversation.isVk(model) || Beef.Conversation.isTwitter(model);
            if (usePublishFull) {
                // publishExtract contains the original post for v4 accounts so use this if we don't have the reply
                const pf = model.get('publishFull');
                if (usePublishFull && !pf) usePublishFull = false;
                if (!model.get('replyToId')) {
                    const pe = model.get('publishExtract');
                    if (pe && pf && pe.length > pf.length) usePublishFull = false;
                }
            }
            return {
                usePublishFull: usePublishFull,
                youtubeHeadPost: Beef.Conversation.isYoutube(model),
                linkedinHeadPost: Beef.Conversation.isLinkedIn(model),
                facebookHeadPost: Beef.Conversation.isFacebookVideo(model),
                telegramHeadPost: Beef.Conversation.isTelegramVideo(model),
                includePhoto: true,
                includeExportButton: true,
                authorOnly: !!model.get('reshareOfId'),
                noSelect: true,
                noViewConversation: true,
                cache: this.cache
            };
        },

        onAfterItemAdded: function (view) {
            view.model.graphView = view;
        },

        onRender: function () {
            const e = this.$el;
            const $content = $('#content');
            e.css('minHeight', $content.height() + "px");
            this.updateAddressBar();

            this.totalWidth = $content.width();
            this.baseX = 0;
            this.baseY = 300;

            if (this.totalWidth <= 1024) {
                this.maxReshares = 1;
                this.baseX = -100;
            }

            if (!this._rendered) {
                this._rendered = true;

                setTimeout(function () {
                    e.toggleClass('mention-replies-fadein', true);

                    if (!Number.isFinite(this.originalScrollTop)) {
                        this.originalScrollTop = window.pageYOffset;
                        window.scrollTo(0, 0);
                    }

                    this.beginLayout();
                }.bind(this));
            }

        },

        updateAddressBar: function () {
            const sel = this.model.get('selection');
            if (sel) {
                if (!this.originalLocation) {
                    const s = window.location.toString();
                    this.originalLocation = s.substring(s.indexOf(window.location.pathname));
                }
                Beef.router.navigate("/" + this.model.get('accountCode') + "/mentions/" + sel.get('id') + "/conversation", {replace: true});
            }
        },

        onClose: function () {
            if (Number.isFinite(this.originalScrollTop)) {
                window.scrollTo(0, this.originalScrollTop);
                this.originalScrollTop = null;
            }

            _(this.collection.models).each(function (m) {
                m.hasBeenLaidOut = null;
                m.isInitial = null;
                m.x = m.y = null;
                m.node = null;
                m.fromX = m.fromY = m.finalX = m.finalY = m.previousFinalX = m.previousFinalY = null;
                m.replies = m.reshares = null;
                m.parent = m.children = null;
            });

            if (this.originalLocation) Beef.router.navigate(this.originalLocation, {replace: true});
            $('#content').off('change:height', this.boundHeightChangeEvent);
        },

        getFilter: function () {
            const firstMention = this.data[0];
            if (firstMention?.visibility?.id === 'DIRECT_MESSAGE')
                return this.getPrivateFilter(firstMention);
            return this.getPublicFilter();
        },

        getPublicFilter: function () {
            const conversationIds = _.uniq(_(this.data).pluck('conversationId'));
            const brandIds = _.uniq(conversationIds
                .map(id => id.substring(0, id.indexOf('-')))
                .filter(id => !!id));
            return `(${brandIds.map(id => `(brand ISORCHILDOF ${id})`).join(' OR ')}) AND ` +
                `(${conversationIds.map(id => `(conversationId IS '${id}')`).join(' OR ')})`;
        },

        getPrivateFilter: function (firstMention) {
            const id = firstMention.id;
            const brandId = id.substring(0, id.indexOf('-'));
            const handleIdquery = (!!firstMention.authorHandleId && !!firstMention.toHandleId)
                ? `((authorHandleId IS '${firstMention.authorHandleId}' AND 
                            toHandleId IS '${firstMention.toHandleId}') OR 
                         (authorHandleId IS '${firstMention.toHandleId}' AND 
                            toHandleId IS '${firstMention.authorHandleId}'))`
                : null;
            const handleQuery = (!!firstMention.authorHandle && !!firstMention.toHandle)
                ? `((authorHandle IS '${firstMention.authorHandle}' AND toHandle IS '${firstMention.toHandle}') OR 
                    (authorHandle IS '${firstMention.toHandle}' AND toHandle IS '${firstMention.authorHandle}'))`
                : null;
            const combinedQuery = !!handleIdquery && !!handleQuery
                ? `(${handleIdquery} OR ${handleQuery})`
                : handleIdquery ?? handleIdquery;
            return `brand ISORCHILDOF ${brandId} AND 
                    visibility IS DIRECT_MESSAGE AND 
                    ${combinedQuery}`;
        },

        /**
         * This is set to layout the initial mention, by first placing it in the same screen position
         * as it was initially in the mention panel, and then shifting it to the base x position at
         * which parent tweets are displayed.
         *
         * This will then layout the remaining mentions.
         */
        beginLayout: function () {
            const m = _(this.collection.models).first();
            m.isInitial = true;

            const $el = m.graphView.$el;
            const ro = $('#content').offset();
            const o = this.model.get('offset');
            const elementWidth = Math.min($el.width(), 374);

            m.x = m.px = (o.left - ro.left) + elementWidth / 2;
            m.y = m.py = (o.top - ro.top) + $el.height() / 2;

            const ox = this.layoutOffsetX,
                oy = this.layoutOffsetY,
                node = m.node = d3.select($el[0]),
                x = ox + m.x,
                y = oy + m.y;

            m.fromX = ox + this.baseX + elementWidth;
            m.fromY = oy + m.y;
            m.fromYFn = function (height) {
                return (o.top - ro.top) + (height / 2);
            };

            node
                .style('left', x + "px")
                .style('top', y + "px")
                .style('display', 'inherit')
                .transition()
                .duration(this.duration)
                .style('left', m.fromX + "px")
                .style('top', m.fromY + "px")
                .on("end", once(async function () {
                    try {
                        const axiosResponse = await this.fetchGraphPromise;
                        if (axiosResponse.status !== 200)
                            throw new Error("Grouse error");
                        const data = axiosResponse.data;
                        this.addMentions(data);
                        this.$('.replies-loading').css({'display': 'none', 'opacity': 0});
                        this.$('.mention-replies-menu').toggleClass('disabled', false);
                    } catch (err) {
                        console.error("Error fetching mention data: ", err);
                        alert("There has been an error talking to our servers. Please try refreshing the page.");
                    } finally {
                        this.fetchComplete = true;
                    }
                }.bind(this)));

            // We want to place a loading icon if this is taking too long.
            setTimeout(function () {
                if (!this.fetchComplete) {
                    this.$('.replies-loading').css({'display': 'block', 'opacity': 0});
                    this.$('.replies-loading').toggleClass('animated fadeIn', true);
                }
            }.bind(this), 2000);
        },

        layout: function () {
            this.layoutHeader();

            const models = this.collection.models,
                ox = this.layoutOffsetX;

            let i, m;

            function setDefaultCss(m) {
                if (!m.hasBeenLaidOut) {
                    m.graphView.$el.css({
                        display: 'inherit',
                        opacity: m.isInitial ? 1 : 0
                    });
                }
            }

            function setFromValues(m) {
                if (m.parent) {
                    m.fromX = m.parent.fromX;
                    m.fromY = m.parent.fromY;
                }
            }

            function setFromValuesWithFinal(m, parent) {
                m.fromX = parent.finalX;
                m.fromY = parent.finalY;
            }

            const baseX = this.baseX;
            const baseY = this.baseY;
            const rowBuffer = 10;
            const depthOffset = 20;

            // Now we use the above function to begin laying out the mentions from the
            // root mentions (those with no parents)
            const firstMention = this.model.get('selection');
            const focusMentionId = firstMention.get('id');
            const focusMention = $("div.mention-item[data-id=" + focusMentionId + "]");
            let focusMentionHeight = 0;
            focusMention.each(
                function () {
                    const e = $(this);
                    if (e.height() > focusMentionHeight)
                        focusMentionHeight = e.height();
                });
            const initialX = firstMention.fromX,
                initialY = firstMention.fromYFn(focusMentionHeight);
            this.maxY = 0;

            m = this.rows[0].mention;
            m.fromX = initialX;
            m.fromY = initialY;
            // positionMention(m, 0, new LayoutStateConstructor());

            let row;
            let currentY = baseY;
            let baseXOffset;
            const laidOut = {};

            function layoutRow(row) {
                laidOut[row] = true;
                m = row.mention;

                if (m) {
                    // For when multiple mentions have been added, we want to look back at its previous siblings to
                    // find one that has already been laid out, to take its starting position from that.
                    let previous = row.previous;
                    while (previous && !previous.mention.hasBeenLaidOut) {
                        previous = previous.previous;
                    }

                    setDefaultCss(m);

                    // Here, the mention follows another which has previously been laid out (i.e., this mention has been
                    // added late to the rendered tree / dom.
                    if ((previous && previous.mention.hasBeenLaidOut) || (!previous && m.parent && m.parent.hasBeenLaidOut)) {
                        setFromValuesWithFinal(m, previous ? previous.mention : m.parent);
                    } else setFromValues(m);

                    m.finalY = currentY;
                    // Standard mention item width is 374px. See MentionItem.css
                    m.finalX = ox + baseX + row.depth * depthOffset + Math.min(m.graphView.$el.width(), 374);
                }

                if (row.reshares.length) {
                    baseXOffset = m.finalX + depthOffset + m.graphView.$el.width();
                    for (let j = 0; j < row.reshares.length; j++) {
                        let child = row.reshares[j];
                        child.finalY = currentY;
                        child.finalX = baseXOffset;
                        baseXOffset += child.graphView.$el.width() + 10;
                        setDefaultCss(child);
                        setFromValues(child);
                    }
                }

                currentY += row.getHeight() + rowBuffer;

                if (row.replies) {
                    for (let j = 0; j < row.replies.length; j++) {
                        layoutRow(row.replies[j]);
                    }
                }

                const postHeight = row.getPostHeight();
                if (postHeight) currentY += postHeight + rowBuffer;
            }

            for (i = 0; i < this.rows.length; i++) {
                row = this.rows[i];
                if (!laidOut[row]) layoutRow(row);
            }


            for (i = 0; i < models.length; i++) {
                m = models[i];
                if (!m.node) m.node = d3.select(m.graphView.$el[0]);
                const node = m.node;

                if (m.fromX && m.fromY && !m.hasBeenLaidOut) {
                    node
                        .style('left', m.fromX + "px")
                        .style('top', m.fromY + "px");
                }

                if (m.finalX !== m.previousFinalX || m.finalY !== m.previousFinalY) {
                    node
                        .transition()
                        .duration(this.duration)
                        .style('opacity', 1)
                        .style('left', m.finalX + "px")
                        .style('top', m.finalY + "px");
                }

                m.hasBeenLaidOut = true;
                m.previousFinalX = m.finalX;
                m.previousFinalY = m.finalY;

                if (m.finalY > this.maxY) this.maxY = m.finalY;
            }

            this.layoutBars();
            this.layoutOverflow();

            setTimeout(function () {
                if (firstMention.finalY >= window.innerHeight) {
                    window.scrollTo({top: firstMention.finalY - window.innerHeight / 2, behavior: 'smooth'});
                }
                adjustContentHeight();
            }, 250);


            this.$el.css('height', (this.maxY + 150) + "px");
            adjustContentHeight();
        },

        layoutHeader: function () {
            const data = this.data,
                initialModel = this.model.get('selection');
            let reshares = 0,
                replies = 0;
            const authors = {};
            let earliestDate,
                latestDate,
                lastReshareDate;
            let date;

            _(data).each(function (m) {
                date = new moment(m.published);
                let authorId;
                const a = m.author;
                if (a) {
                    authorId = a.id;
                    if (!authorId) authorId = a.name + ":" + a.handle + ":" + a.handleId;   // v4
                } else {
                    authorId = m.authorName;
                }
                authors[authorId] = true;

                if (m.reshareOfId) {
                    reshares++;

                    if (!lastReshareDate || date.isAfter(lastReshareDate)) {
                        lastReshareDate = date;
                    }
                } else {
                    replies++;
                    if (!earliestDate || date.isBefore(earliestDate)) {
                        earliestDate = date;
                    }
                    if (!latestDate || date.isAfter(latestDate)) {
                        latestDate = date;
                    }
                }
            });

            const dateFormat = "YYYY-MM-DD, HH:mm";
            const author = data[0].author;
            this.$('.conversation-author').html(author ? author.name : data[0].authorName);
            this.$('.reshare-count').html(formatNumber(reshares));
            this.$('.mention-count').html(formatNumber(replies));
            this.$('.last-reshare-date').html(lastReshareDate ? lastReshareDate.format(dateFormat) : "No reshares");
            this.$('.start-date').html(earliestDate.format(dateFormat));
            this.$('.end-date').html(latestDate.format(dateFormat));
            this.$('.author-count').html(formatNumber(_(authors).size()));
            this.$('.total-engagement').html(formatNumber(initialModel.get('engagement')));
            this.$('.total-reach').html(formatNumber(initialModel.get('reach')));

            d3.select(this.$('.information')[0])
                .transition()
                .duration(this.duration)
                .style('opacity', 1);
        },

        layoutBars: function () {
            this.replyBars = [];
            this.reshareBars = [];
            let row;

            for (let i = 0; i < this.rows.length; i++) {
                row = this.rows[i];
                if (row.replies.length >= 2) this.replyBars.push(row);
                if (row.reshares.length) this.reshareBars.push(row.mention);
            }

            const barsDiv = d3.select(this.$('.bars')[0]);
            const bars = barsDiv.selectAll('.bar').data(this.replyBars, function (d) {
                return d;
            });
            const colours = getGreenScheme();
            const defaultHalfHeight = this.defaultHalfHeight;

            function determinePosition(xDistLabel, yDistLabel, item) {
                // Standard mention item width is 374px. See MentionItem.css
                item
                    .style('top', function (row) {
                        return (row.mention[yDistLabel] + row.mention.graphView.$el.height() - defaultHalfHeight) + "px";
                    })
                    .style('left', function (row) {
                        return (row.mention[xDistLabel] - Math.min(row.mention.graphView.$el.width(), 374) / 2) + "px";
                    });
            }

            bars.exit()
                .remove();

            bars.enter()
                .append('div')
                .classed('bar', true)
                .style('opacity', 0)
                .style('background-color', function (m, i) {
                    return colours[i % colours.length];
                })
                .call(_(determinePosition).partial('fromX', 'fromY'));

            bars.enter()
                .selectAll('.bar')
                .transition()
                .duration(this.duration)
                .call(_(determinePosition).partial('finalX', 'finalY'))
                .style('height', function (row) {
                    let extra = 5;
                    if (row.getPostHeight()) extra += row.getPostHeight() + 10;
                    return row.getHeightWithChildren() + extra + "px";
                }.bind(this))
                .style('opacity', 1);

            //------------------------------------

            const resharesDiv = d3.select(this.$('.reshares')[0]);
            const reshareBars = resharesDiv.selectAll('.reshare-bar').data(this.reshareBars);
            const reshareCounts = this.reshareCounts;

            function determineResharePosition(xDistLabel, yDistLabel, item) {
                item
                    .style('top', function (m) {
                        return (m[yDistLabel]) + "px";
                    })
                    .style('left', function (m) {
                        return (m[xDistLabel] + m.graphView.$el.width() / 2) + "px";
                    });
            }

            reshareBars.exit()
                .remove();

            reshareBars.enter()
                .append('div')
                .classed('reshare-bar', true)
                .call(_(determineResharePosition).partial('fromX', 'fromY'))
                .style('width', "0px")
                .style('opacity', 0)
                .append('div')
                .classed('reshare-label', true)
                .text('Reshares ▸');

            reshareBars.merge(reshareBars.enter().selectAll(".reshare-bar"))
                .transition()
                .duration(this.duration)
                .delay(100)
                .call(_(determineResharePosition).partial('finalX', 'finalY'))
                .style('width', function (m) {
                    if (!m.reshares.length) return "0px";

                    let width = _(m.reshares).last().finalX - m.finalX - 55;
                    if (reshareCounts[m.get('id')] > this.maxReshares) width += 96;
                    return width + "px";
                }.bind(this))
                .style('opacity', 0.9);

        },

        layoutOverflow: function () {
            if (!this.reshareOverflow && !this.replyOverflow) {
                this.reshareOverflow = [];
                this.replyOverflow = [];
                for (let i = 0; i < this.rows.length; i++) {
                    if (this.rows[i].reshareOverflow) this.reshareOverflow.push(this.rows[i]);
                    if (this.rows[i].replyOverflow) this.replyOverflow.push(this.rows[i]);
                }
            }

            const overflow = d3.select(this.$('.overflow')[0]);
            const reshareOverflows = overflow.selectAll('.reshare-overflow-item').data(this.reshareOverflow),
                replyOverflows = overflow.selectAll('.reply-overflow-item').data(this.replyOverflow);

            //----------------------------------------------

            reshareOverflows.exit()
                .remove();

            reshareOverflows.enter()
                .append('div')
                .classed('reshare-overflow-item', true)
                .style('top', function (row) {
                    return row.mention.fromY + "px";
                })
                .style('left', function (row) {
                    return row.mention.fromX + "px";
                })
                .style('opacity', 0)
                .attr('title', "Click here to see all the reshares of this mention")
                .on('click', function (row) {
                    const filter = "published inthelast year and reshareof is '" + row.mention.get('id') +
                        "' and brand isorchildof " + row.mention.get('brand').id;
                    Beef.MentionList.navigateToMentions(this.model.get('accountCode'), filter);
                }.bind(this))
                .append('p')
                .text(function (row) {
                    const count = this.getOverflowReshareCount(row.mention);
                    return formatNumber(count) + " more " + (count === 1 ? 'reshare' : 'reshares');
                }.bind(this));

            reshareOverflows
                .enter()
                .selectAll(".reshare-overflow-item")
                .transition()
                .duration(this.duration)
                .style('opacity', 1)
                .style('top', function (row) {
                    const last = _(row.mention.reshares).last();
                    return (last.finalY - last.graphView.$el.height() / 2) + "px";
                })
                .style('left', function (row) {
                    const last = _(row.mention.reshares).last();
                    return (last.finalX + last.graphView.$el.width() / 2 + 10) + "px";
                });

            //----------------------------------------------

            replyOverflows.enter()
                .append('div')
                .classed('reply-overflow-item', true)
                .style('top', function (row) {
                    const last = _(row.mention.replies).last();
                    return last.fromY + last.graphView.$el.height() / 2 + "px";
                })
                .style('left', function (row) {
                    const last = _(row.mention.replies).last();
                    return last.fromX - last.graphView.$el.width() / 2 + "px";
                })
                .style('opacity', 0)
                .attr('title', "Click here to see more replies to this mention")
                .on('click', function (row) {
                    this.expandReplies(row);
                }.bind(this))
                .append('p');

            replyOverflows
                .enter()
                .selectAll(".reply-overflow-item")
                .each(function (row) {
                    if (!row.replyOverflow) d3.select(this).style('display', 'none');
                })
                .transition()
                .duration(this.duration)
                .style('opacity', 1)
                .style('top', function (row) {
                    const m = row.mention;
                    let additional = 0;
                    let last = _(m.replies).last();
                    if (this.isReplyOverflow(last)) {
                        additional += 76;
                    }
                    while (last.replies && last.replies.length) {
                        last = _(last.replies).last();
                        if (this.isReplyOverflow(last)) {
                            additional += 76;
                        }
                    }
                    let position = last.finalY + (last.graphView.$el.height() / 2) + 10 + additional;
                    if (additional === 0 && !this.isReplyOverflow(_(m.replies).last()) && this.isReplyOverflow(m)) {
                        position += 76;
                    }
                    if (position > this.maxY) this.maxY = position;
                    return position + "px";
                }.bind(this))
                .style('left', function (row) {
                    const last = _(row.mention.replies).last();
                    return last.finalX - (last.graphView.$el.width() / 2) + "px";
                })
                .selectAll('p')
                .text(function (row) {
                    const count = this.getOverflowReplyCount(row.mention);
                    return formatNumber(count) + " more " + (count === 1 ? 'reply' : 'replies');
                }.bind(this))
                .on('end', once(function () {
                    // Resize to fit overflow contents
                    this.$el.css('height', (this.maxY + 150) + "px");
                    adjustContentHeight();
                }).bind(this));

        },

        //-----------------------------------------
        // Menu event handlers

        seeAuthors: function () {
            const filter = this.getFilter();
            Beef.AuthorsSectionV4.navigateToAuthors(this.model.get('accountCode'), filter);
        },

        seeMentions: function () {
            const filter = this.getFilter();
            Beef.MentionList.navigateToMentions(this.model.get('accountCode'), filter, "published");
        },

        highlightSelected: function () {
            let firstMention = this.model.get('selection');
            if (firstMention) {
                window.scrollTo({top: firstMention.finalY - window.innerHeight / 2, behavior: 'smooth'});
            }
        },

        emailConversation: function () {
            const $button = this.$('.mention-replies-menu');
            const ids = _(this.data).chain().filter(function (m) {
                return !m.reshareOfId;
            }).pluck('id').value();
            const model = this.collection.models[0];

            Beef.InteractDialog.show({
                target: $button,
                accountCode: this.model.get('accountCode'),
                email: true,
                subject: 'See this conversation from DataEQ | ' + account().code + " " + account().name +
                    ": " + (model.get('author') ? model.get('author').name : model.get('authorName')) +
                    ", dated " + new moment(model.get('published')).format("YYYY/MM/DD HH:mm"),
                footer: "This message was sent to you by " + Beef.user.get('firstName') + " " + Beef.user.get('lastName') +
                    ", who is using DataEQ, a web application for the analysis and tracking of online conversation. " +
                    "You can view the conversation <a href=\"https://v3.brandseye.com/" + this.model.get('accountCode') +
                    "/mentions/" + model.get('id') + "/conversation\">here</a>",
                mentionIds: ids,
                namespace: "conversation"
            });
        },

        //-----------------------------------------
        // Event handlers

        heightChangedEvent: function () {
            this.$el.css('minHeight', $('#content').height() + "px");
        },

        graphClicked: function () {
            Beef.MentionGraph.show(this.model.get('selection'), this.model.get('accountCode'));
        },

        menuClicked: function (ev) {
            if (!this.data) return;
            Beef.MiniMenu.show({
                template: require("@/conversation/MentionConversationMenu.handlebars"),
                object: this,
                target: $(ev.target).closest("a")
            });
        }
    });

});
