<template>
    <div>
        <selector-menu class="explore-panel-selector" v-if="showWhichMentions">
            <selector-menu-heading>
                Show what mentions?
            </selector-menu-heading>
            <selector-menu-item @click="onChange('all', visibility)"
                                :disabled="loading"
                                :active="show === 'all'">All mentions and conversations</selector-menu-item>
            <selector-menu-item v-if="accountHasCx"
                                @click="onChange('cx', 'public')"
                                :disabled="loading || !brandHasCx"
                                :active="show === 'cx'">
                <tooltip-with-glossary glossary="glossary:OPERATIONAL" :brand="brand">
                    <div>Operational</div>
                </tooltip-with-glossary>
            </selector-menu-item>

            <selector-menu-item v-if="accountHasCx"
                                @click="onChange('rep', 'public')"
                                :disabled="loading || !brandHasCx"
                                :active="show === 'rep'">
                <tooltip-with-glossary glossary="glossary:REPUTATIONAL" :brand="brand">
                    <div>Reputational</div>
                </tooltip-with-glossary>
            </selector-menu-item>

            <selector-menu-item v-if="accountHasRisk && showRisk"
                                @click="onChange('risk', visibility)"
                                :disabled="loading || !brandHasRisk"
                                :active="show === 'risk'">
                <tooltip-with-glossary glossary="glossary:RISK" :brand="brand">
                    <div>Risk</div>
                    <template #tooltip>
                        They can be either public mentions, or in their direct messages.
                    </template>
                </tooltip-with-glossary>
            </selector-menu-item>
            <selector-menu-item v-if="accountHasConduct"
                                @click="onChange('conduct', visibility)"
                                :disabled="loading || !brandHasConduct"
                                :active="show === 'conduct'">
                <tooltip-with-glossary glossary="glossary:CONDUCT" :brand="brand">
                    <div>Conduct</div>
                </tooltip-with-glossary>
            </selector-menu-item>
            <selector-menu-item @click="onChange('press', visibility)"
                                :disabled="loading"
                                :active="show === 'press'">
                <tooltip-with-glossary glossary="glossary:PRESS" :brand="brand">
                    <div>Press</div>
                </tooltip-with-glossary>
            </selector-menu-item>
        </selector-menu>

        <selector-menu class="explore-panel-selector" v-if="showCx && accountHasCx">
            <selector-menu-heading>
                Customer Experience
            </selector-menu-heading>
            <selector-menu-item @click="onChange('cx', visibility)"
                                :tooltip="substituteBrand('All mentions relating to  %brand%\'s Customer Experience')"
                                :disabled="loading || !brandHasCx"
                                :active="show === 'cx'">All CX</selector-menu-item>
            <selector-menu-item v-if="accountHasPurchase"
                                @click="onChange('purchase', visibility)"
                                :disabled="loading || !brandHasCx"
                                :active="show === 'purchase'">
                <tooltip-with-glossary glossary="glossary:PURCHASE" :brand="brand">
                    <div>Purchase</div>
                </tooltip-with-glossary>
            </selector-menu-item>
            <selector-menu-item v-if="accountHasCancel"
                                @click="onChange('cancel', visibility)"
                                :disabled="loading || !brandHasCx"
                                :active="show === 'cancel'">
                <tooltip-with-glossary glossary="glossary:CANCEL" :brand="brand">
                    <div>Cancel</div>
                </tooltip-with-glossary>
            </selector-menu-item>
            <selector-menu-item @click="onChange('service', visibility)"
                                :disabled="loading || !brandHasCx"
                                :active="show === 'service'">
                <tooltip-with-glossary glossary="glossary:SERVICE" :brand="brand">
                    <div>Service only</div>
                </tooltip-with-glossary>
            </selector-menu-item>
            <selector-menu-divider/>
            <selector-menu-item @click="onChange('rep', visibility)"
                                :tooltip="substituteBrand('Mentions that do not relate to %brand%\'s Customer Experience')"
                                :disabled="loading || !brandHasCx"
                                :active="show === 'rep'">Non CX</selector-menu-item>
        </selector-menu>

        <selector-menu class="explore-panel-selector">
            <selector-menu-heading>
                Visibility
            </selector-menu-heading>
            <selector-menu-item :disabled="nonPublicReputationDisabled || nonPublicOperationalDisabled"
                                tooltip="All public mentions and direct messages"
                                :active="visibility === 'all'"
                                @click="onChange(show, 'all')">
                Public mentions and DMs
            </selector-menu-item>
            <selector-menu-item :disabled="loading"
                                :active="visibility === 'public'"
                                tooltip="Only public mentions, no direct messages"
                                @click="onChange(show, 'public')">
                Public mentions
            </selector-menu-item>
            <selector-menu-item :disabled="nonPublicReputationDisabled || nonPublicOperationalDisabled"
                                :active="visibility === 'private'"
                                tooltip="Only direct messages, not public mentions"
                                @click="onChange(show, 'private')">
                Direct messages
            </selector-menu-item>
        </selector-menu>
    </div>
</template>

<script>
import SelectorMenuItem from "@/components/selector-menu/SelectorMenuItem";
import SelectorMenu from "@/components/selector-menu/SelectorMenu";
import SelectorMenuHeading from "@/components/selector-menu/SelectorMenuHeading";
import {mapActions, mapGetters, mapState} from "vuex";
import {appendSegmentRestrictions, getAllCxSegmentLists, getAllMarketConductSegmentLists} from "@/app/utils/Segments";
import SelectorMenuDivider from "@/components/selector-menu/SelectorMenuDivider";
import {substituteTagParamaters} from "@/app/utils/Tags";
import {getOperationalSentimentFilter} from "@/app/utils/Sentiment";
import TooltipWithGlossary from "@/components/tooltip/TooltipWithGlossary";
export default {
    components: {TooltipWithGlossary, SelectorMenuDivider, SelectorMenuHeading, SelectorMenu, SelectorMenuItem},

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        showRisk: {
            type: Boolean,
            default: true
        },
        showWhichMentions: {
            type: Boolean,
            default: true
        },

        showCx: {
            type: Boolean,
            default: false
        },

        disableNonPublicReputation: {
            type: Boolean,
            default: true
        },

        disableNonPublicOperational: {
            type: Boolean,
            default: true
        },

        initialShow: {
            type: String,
            default: "all"
        },

        initialVisibility: {
            type: String,
            default: "all"
        },

        brand: { type: Object }
    },

    data() {
        return {
            show: this.initialShow,
            visibility: this.initialVisibility,

            cxTags: [],
            conductTags: []
        }
    },

    computed: {
        ...mapState('explorePanel', [
            'accountHasCx', 'accountHasConduct', 'accountHasRisk',
            'brandHasCx', 'brandHasConduct', 'brandHasRisk',
            'accountHasCancel', 'accountHasPurchase'
        ]),
        ...mapGetters(['idToTag']),

        nonPublicReputationDisabled() {
            return this.loading || (this.show === 'rep' && this.disableNonPublicReputation);
        },

        nonPublicOperationalDisabled() {
            return this.loading || (this.show === 'cx' && this.disableNonPublicOperational);
        },
    },

    async mounted() {
        await this.refreshTags();
        this.cxTags = await getAllCxSegmentLists();
        this.conductTags = await getAllMarketConductSegmentLists();
        this.onChange(this.show, this.visibility);
    },

    methods: {
        ...mapActions(['refreshTags']),

        async onChange(show, visibility) {
            this.show = show;
            this.visibility = visibility;
            let visibilityFilter = null;
            let subFilter = null;

            switch (visibility) {
                case "public": visibilityFilter = "visibility is public"; break;
                case "private": visibilityFilter = "visibility is DIRECT_MESSAGE"; break;
                case "all":
                default: visibilityFilter = null; break;
            }

            switch (show) {
                case "all":         subFilter = null; break;
                // For CX below, we allow public, and rely on navigation tools turning off public as / when needed
                case "cx":          subFilter = await getOperationalSentimentFilter(null, null, true); break;
                case "risk":        subFilter = "tag is 1"; break;
                case "press":       subFilter = "media is PRESS"; break;
                case "purchase":    subFilter = appendSegmentRestrictions("tag is 2"); break;
                case "cancel":      subFilter = appendSegmentRestrictions("tag is 3"); break;
                case "service":     subFilter = appendSegmentRestrictions("tag is 4 and tag isnt 3 and tag isnt 2"); break;
                case "rep": {
                    // Reputational: is a non-cx reshare (100916, a global segment) or is a non-cx post.
                    const nonAbove = this.cxTags
                        .flatMap(t => t.children ?? [])
                        .map(id => this.idToTag.get(id))
                        .filter(t => t && t.flag === 'NONE_OF_THE_ABOVE');

                    if (!nonAbove.length) {
                        subFilter =  'tag is -5213212333'; // No CX. No data.
                    } else {
                        const nonAboveFilter = nonAbove.map(t => `segment is ${t.id}`).join( 'or ');
                        subFilter = `segment is 100916 or ${nonAboveFilter}`;
                    }
                    break;
                }
                case "conduct":
                    subFilter = this.conductTags.map(t => `segment is ${t.id}`).join(' or ');
                    break;
                default:
                    console.warn("Unrecognised show value: " + show);
                    subFilter = "tag is -3465654333244"; // Use an unknown tag
            }

            const elements = [];
            if (subFilter) elements.push(subFilter);
            if (visibilityFilter) elements.push(visibilityFilter);
            this.$emit('filter', elements.join(' and ') || null);
            this.$emit('visibility', visibility);
            this.$emit('show', show);

        },

        substituteBrand(text) {
            return substituteTagParamaters(text, this.brand ? [this.brand] : null);
        }
    }
}
</script>

<style scoped lang="sass">

.explore-panel-selector + .explore-panel-selector
    margin-top: 20px

</style>