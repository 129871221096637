import { render, staticRenderFns } from "./StaffInfoCard.vue?vue&type=template&id=1387dff0&scoped=true"
import script from "./StaffInfoCard.vue?vue&type=script&lang=js"
export * from "./StaffInfoCard.vue?vue&type=script&lang=js"
import style0 from "./StaffInfoCard.vue?vue&type=style&index=0&id=1387dff0&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1387dff0",
  null
  
)

export default component.exports