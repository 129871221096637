import {isEntirelyVisible} from "@/app/utils/Util";
import _ from 'underscore';
import {
    areAnyPopupsVisible,
    getDialogId,
    incrementDialogCount,
    registerDialog,
    unregisterDialog
} from "@/app/framework/dialogs/DialogUtilities";

/**
 * Display another view in a popup displayed near a target element.
 */
Beef.module("Popup").addInitializer(function(startupOptions) {

    /**
     * If created with { closeOnHide: true } then the popup (and hence any contained view) is closed when the popup
     * is hidden.
     */
    this.View = Backbone.Marionette.Layout.extend({
        tagName: "ul",

        attributes: function() {
            return {
                class: (this.options && this.options.plain ? "popup" : "popup popup-styled") +
                    (this.options && this.options.fixed ? " popup-fixed " : "") +
                    (this.options && this.options.extraCls ? " " + this.options.extraCls : "") +
                    (!this.options.noAnimation ? " short-animated fadeIn" : ''),
                'data-popup-id': getDialogId() // hasn't been incremented yet
            };
        },

        template: require("@/dashboards/filter/popup/Popup.handlebars"),

        templateHelpers: function() {
            return {
                fixed: this.options.fixed,
                modal: this.options.modal
            }
        },

        regions: {
            contents: ".contents"
        },

        initialize: function(options) {
            this.id = incrementDialogCount();
            this.offsets = Object.assign({}, {left:0, top:0, bottom:0, right:0}, this.options.offsets);
            this.scrollIntoView = options && options.scrollIntoView !== undefined ? options.scrollIntoView : true;
        },

        events: {
            "mousedown": "mousedown",
            "click .dialog-title .close": "hide",
            "click .dialog-close": "hide"
        },

        mousedown: function(ev) {
            if (this.options?.modal) {
                ev.stopPropagation();
                return;
            }
            if (ev.target.classList.contains('popup-fixed__background')) return; // Let non modal elements with a fixed background area close if the background is clicked.
            ev.stopPropagation();   // don't let this get to the document and cause us to be hidden
        },

        setTarget: function(target) {
            this.target = $(target);
        },

        onRender: function() {
            if (this.options.noMinWidth) this.$el.css('min-width', 'inherit');
        },

        show: function(view) {
            // you can't see popups in fullscreen mode but they sometimes re-appear when exiting fullscreen so
            // rather don't show them at all
            if (Beef.Dashboard.isPresentationMode()) return;
            if (!this.target) throw new Error("no target set");
            if (!this.rendered) {
                this.render();
                this.rendered = true;
                $('body').append(this.render().el);
            }
            if (view && view != this.contents.currentView) {
                this.contents.show(view);
                view.parentPopup = this;
            }
            if (!this.$el.is(":visible")) {
                if (!this.hideHandler) {
                    this.hideHandler = function(ev){
                        // If the user clicks on the scroll bar, then target will be the html page.
                        // We also don't want to close the dialog if the help button is clicked.
                        // We also don't want to close any popup-menu items we have just opened.
                        if (ev.target === $('html')[0] || ev.target === $('.brandseye-help i')[0] || ev.target.parentElement.classList.contains('mini-menu'))  return;


                        // Don't close old style popups when clicking on notifications.
                        if (ev.target.closest('be-notification-item')) return;


                        // Close the popup.
                        this.hide();
                    }.bind(this);
                    this.keyupHandler = this.keyup.bind(this);
                }
                if (!this.options?.modal) {
                    $(document).on('mousedown', this.hideHandler);
                }
                $(document).on('keyup', this.keyupHandler);
                this.target.on('keyup', this.keyupHandler);
            }
            this.$el.show();
            this.move();

            var viewClosed = function() {
                view.unbind("close", viewClosed);
                if (!this.hasClosed) {
                    this.close();
                }
            }.bind(this);

            view.on("close", viewClosed);

            if (this.options.fixed) registerDialog(this.id);
        },

        hide: function() {
            if (this.options.fixed) unregisterDialog(this.id);

            var closeOnHide = this.options && this.options.closeOnHide;

            this.$el.hide();
            $(document).off('mousedown', this.hideHandler);
            $(document).off('keyup', this.keyupHandler);
            this.target.off("keyup", this.keyupHandler);
            if (this.contents) this.contents.reset();
            this.trigger('hide', this);

            if (closeOnHide) this.close();
        },

        /** Get the view being displayed in this popup. */
        getCurrentView: function() {
            return this.contents ? this.contents.currentView : null;
        },

        keyup: function(ev) {
            if (ev.keyCode == 27 && this.$el.is(":visible")) {
                if (this.options.gobbleEsc || (this.target.is(ev.target) && this.$el.css('visibility') != 'hidden')) {
                    ev.stopPropagation();
                } else this.trigger("keyup", ev);
                this.hide();
            } else {
                this.trigger("keyup", ev);
            }
        },

        move: function(newTarget) {
            if (newTarget) this.target = newTarget;
            var e = this.$el;
            if (this.target.is(".big-popup") || this.options.fixed) {
                this.setPosition("center");
                // e.css({top: "", left: "", right: "", display: ""});
            } else {
                // don't move popup if it is currently positioned and visible and the target hasn't changed or moved
                var targetOffset = this.target.offset();
                if (!this.options.alwaysMove && this.posTarget && this.posTarget[0] == this.target[0]
                        && _.isEqual(targetOffset, this.posTargetOffset)) {
                    var pos = this.getPosition();
                    if (pos && isEntirelyVisible(e)) return;
                }

                this.$el.toggleClass("dropdown-menu", this.target.hasClass("popup-menu") || (_(this.options.dropdown).isBoolean() && this.options.dropdown));

                // try positions until we find one that keeps the popup on screen
                var classes = this.options.positions || ["bottom-right", "bottom-left", "top-right", "top-left"];
                for (var i = 0; i < classes.length; i++) {
                    this.setPosition(classes[i]);
                    if (isEntirelyVisible(e)) break;
                }

                // default position if we couldn't find place.
                if (i == classes.length) {
                    this.setPosition(classes[0]);
                    if (this.scrollIntoView) e[0].scrollIntoView({behavior: 'smooth', block: 'end'});
                }

                this.posTarget = this.target;
                this.posTargetOffset = targetOffset;
            }
        },

        setPosition: function(pc) {
            var pos = null;
            var o = this.target.offset();
            // SVG elements that have been animated will return 0 width and height using jquery, while the DOM
            // width and height elements are svg animation objects. Here we test for svg elements that have bounding
            // boxes, and use that.
            var targetNode = this.target[0];
            var bounding = targetNode && targetNode.getBBox ? targetNode.getBBox() : { height: this.target.outerHeight(), width: this.target.outerWidth() };
            if (o) {
                switch (pc) {
                    case "inside":
                        pos = {
                            top: o.top + this.offsets.top,
                            left: o.left + this.offsets.left,
                            right: 'auto',
                            bottom: 'auto'
                        };
                        break;
                    case "bottom-right":
                        pos = {
                            top: o.top + bounding.height + this.offsets.top,
                            left: o.left + this.offsets.left,
                            right: 'auto',
                            bottom: 'auto'
                        };
                        break;
                    case "bottom-left":
                        pos = {
                            top: o.top + bounding.height + this.offsets.top,
                            left: 'auto',
                            right: $(window).width() - o.left - bounding.width + this.offsets.right,
                            bottom: 'auto'
                        };
                        break;
                    case "top-right":
                        pos = {
                            top: 'auto',
                            left: o.left + this.offsets.left,
                            right: 'auto',
                            bottom: $(window).height() - o.top + 1 + this.offsets.bottom
                        };
                        break;
                    case "top-left":
                        pos = {
                            top: 'auto',
                            left: 'auto',
                            right: $(window).width() - o.left - bounding.width + this.offsets.right,
                            bottom: $(window).height() - o.top + 1 + this.offsets.bottom
                        };
                        break;
                    case "right":
                        pos = {
                            top: o.top + this.offsets.top,
                            left: o.left + bounding.width + 4 + this.offsets.left,
                            right: 'auto',
                            bottom: 'auto'
                        };
                        break;
                    case "left":
                        pos = {
                            top: o.top + this.offsets.top,
                            left: 'auto',
                            right: $(window).width() - o.left + 4 + this.offsets.right,
                            bottom: 'auto'
                        };
                        break;
                    case "center":
                        pos = {
                            top: Math.floor(($(window).height() - this.$el.height()) / 2) + $(window).scrollTop(),
                            left: Math.floor(($(window).width() - this.$el.width()) / 2),
                            right: 'auto',
                            bottom: 'auto'
                        };
                        break;
                }
            }

            if (this.options.fixed){
                pos.top = Math.floor(($(window).height() - this.$el.height()) / 2);
            }

            if (pos) {
                this.$el.css(pos);
                this.setPositionClass(pc);
            }
        },

        /**
         * Sets the position of the popup. Call this after #show() has been called, so that it knows the size
         * of the element that it is displaying.
         */
        getPosition: function() {
            var cls = this.$el.attr('class');
            for (var i = 0; i < posClasses.length; i++) {
                var c = posClasses[i];
                if (cls.indexOf(c) >= 0) return c;
            }
            return null;
        },

        setPositionClass: function(pc) {
            var e = this.$el;
            for (var i = 0; i < posClasses.length; i++) {
                var c = posClasses[i];
                e.toggleClass(c, c == pc);
            }
        },

        setVisible: function(on) {
            this.$el.css('visibility', on ? 'visible' : 'hidden');
        },

        sendToBack: function(on) {
            this.$el.css('z-index', on ? -1 : null);
        },

        onClose: function() {
            if (this.options.fixed) unregisterDialog(this.id);
            this.hasClosed = true;
        }
    });

    var posClasses = ["popup-center", "bottom-right", "bottom-left", "top-right", "top-left", "left", "right"];

    /**
     * Recursively hide and close view's popup and those of all of its regions.
     */
    this.closePopups = function (view) {
        if (view.popup) {
            view.popup.hide();
            view.popup.close();
            view.popup = null;
        }
        if (view.regionManager) {
            _.each(view.regionManager._regions, function (region) {
                if (region.currentView) Beef.Popup.closePopups(region.currentView);
            });
        }
    };

    /**
     * @deprecated
     * @return {boolean}
     */
    this.areAnyPopupsVisible = function() {
        return areAnyPopupsVisible();
    }

});
