import {
    getJourneySegmentList,
    getRiskSegmentList,
    hasMarketConductSegmentLists,
} from "@/app/utils/Segments";
import {showErrorDialog, showInfoDialog} from "@/app/framework/dialogs/Dialog";
import CreateCrowdJudgement from "./CreateCrowdJudgement";
import { beef } from "@/store/Services"
import { deprecatedTagsStore } from "@/store/deprecated/Stores"
import {depcreatedSnoekPostJson} from "@/data/Snoek";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {showBusyNotification} from "@/app/framework/notifications/Notifications";
import {features} from "@/app/Features";
import {convertTo, getAccountCurrency} from "@/app/utils/Currency";
import {recordRecentMention} from "@/app/RecentMentions";
import VuexStore from "@/store/vuex/VuexStore";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {formatDefaultCurrency, formatPlural} from "@/app/utils/Format";
import {currentAccountCode} from "@/app/utils/Account";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import moment from "moment";
import ReportAuthorDialog from "@/mentions/ReportAuthorDialog";

let handlerCalled = false;

/**
 * This is for handling the case of the user pressing refresh / ctrl-R while this dialog is open.
 * They usually do not want to land up at a mention panel with only this mention selected.
 */
function pagehideHandler(originalUrl, event) {
    if (handlerCalled || !originalUrl) return;
    if (event.persisted) return;
    try {
        window.location.href = originalUrl;
    } finally {
        handlerCalled = true; // Prevents multiple calls from happening if there is a logic error with the handler.
    }
}

/**
 * This view must be used with Beef.MentionItem.Model, not a generic Backbone.Model.
 */
Beef.module("EditMention").addInitializer(function(startupOptions) {

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/mentions/EditMention.handlebars"),

        attributes: function() {
            var classes = "edit-mention dialog edit-mention__rpcs";
            return {
                class: classes
            }
        },

        templateHelpers: function() {
            var brandPhrases = [];
            var phrases = this.model.attributes.phrases;
            var brands = this.model.attributes.brands;
            if (phrases && brands) {
                for (var i = 0; i < phrases.length; i++) {
                    var p = phrases[i];
                    var b = brandPhrases.find(function(b) { return b.id == p.brandId });
                    if (!b) {
                        b = brands.find(function(b) { return b.id == p.brandId });
                        // this happens when a phrase has been moved to a different root brand .. we need to make
                        // grouse exclude these from the mention
                        if (!b) continue;
                        brandPhrases.push(b = { id: b.id, fullName: b.fullName, phrases: []});
                    }
                    b.phrases.push(p);
                }
            }

            var segmentName = [];
            var journey = getJourneySegmentList(),
                risk = getRiskSegmentList(),
                hasConduct = hasMarketConductSegmentLists();

            if (journey) segmentName.push(journey.name);
            if (risk) segmentName.push(risk.name);
            if (hasConduct) segmentName.push("Market conduct")
            if (!segmentName.length) segmentName.push("Segments");

            let readableResponseTime = null;
            let responseTimeStillCalculating = false;

            if (this.model.get("interactionResponseTime")) {
                // if the interaction has a response time, show it.
                let duration = moment.duration(this.model.get("interactionResponseTime"), 'seconds');
                let days = duration.days() ? `${duration.days()} ${formatPlural(duration.days(), 'day')} ` : '';
                let hours = duration.hours() ? `${duration.hours()} ${formatPlural(duration.hours(), 'hour')} ` : '';
                let minutes = duration.minutes() ? `${duration.minutes()} ${formatPlural(duration.minutes(), 'minute')} ` : '';
                let seconds = duration.seconds() ? `${duration.seconds()} ${formatPlural(duration.seconds(), 'second')} ` : '';

                readableResponseTime = `${days}${hours}${minutes ? minutes : seconds}`;
            } else if (this.model.get("interactionHasResponse")) {
                // if the interaction doesn't have a response time, but does have a response, let the user know that the response time will be calculated at a later point.
                let pickedUp = moment(this.model.get("pickedUp"));
                let hoursDiff = moment().diff(pickedUp, 'hours');

                responseTimeStillCalculating = hoursDiff >= 0 && hoursDiff <= 24;
            }


            return {
                relevant: this.model.get('relevancy') !== 'IRRELEVANT',
                v4: this.model.get('id').indexOf('-') > 0,
                brandPhrases: brandPhrases,
                canEditSentiment: this.model.get('_canEditAttrs') && features.canEditSentient() && !this.model.isDeletedOnNetwork(),
                segmentLabel: segmentName.join(', '),
                readableResponseTime: readableResponseTime,
                responseTimeStillCalculating: responseTimeStillCalculating
            }
        },

        events: Object.assign({
            "click .brand .path": "togglePhrases",
            "click .sentiment": "editSentiment",
            "click .delete-link": "delete",
            "click .mark-relevant": "markRelevant",
            "click .show-graph": "showGraph",
            "click .menu": "showMenu",
            "click .mention-translation": "mentionItemTranslateClicked",
            "mousedown": "mousedown"
        }, Beef.SettingsDialog.View.prototype.events),

        regions: {
            mentionItem: ".mention-item-container",
        },

        initialize: function(options) {
            Beef.SettingsDialog.View.prototype.initialize.call(this);
            this.accountCode = this.originalModel.getAncestorProperty('accountCode');
            options.noSelect = true;
            options.noView = true;
        },

        onSaveError: function(model, xhr, options) {
            // This code is very similar to the one in SettingsDialog. SettingsDialog cannot
            // import the showErrorDialog function because of circular dependency issues with the
            // old backbone module system, and we should have some better error reporting for editing mentions.
            // noinspection DuplicatedCode
            if (xhr.responseText) console.error(xhr.responseText, xhr);
            else (console.error(xhr));
            let errorMessage = "We weren't able to save your mention updates. Please try again in a bit.";
            if (xhr.status === 422) {
                errorMessage = xhr.responseJSON?.error ?? errorMessage;
                if (errorMessage.toLowerCase().startsWith("invalid tag id")) {
                    errorMessage = "There was an error finding the tags in the account";
                }
            } else if (xhr.status === 403) {
                errorMessage = "You do not have enough permissions to update these mentions.";
            }
            showErrorDialog(errorMessage);
        },

        cloneAttrs: function(attrs) {
            if (attrs._brands) {
                var brands = JSON.parse(JSON.stringify(attrs._brands));
                attrs._brands = brands;
                // recreate the matchedPhrases array from the newly cloned phrase objects so changes made via brands
                // will be seen on save
                var phrases = [];
                for (var i = 0; i < brands.length; i++) phrases = phrases.concat(brands[i].phrases);
                attrs.matchedPhrases = phrases;
            }
            if (attrs.sentiments) attrs.sentiments = JSON.parse(JSON.stringify(attrs.sentiments));
            return attrs;
        },

        bindings: function() {
            return {
                _category: { converter: Beef.MediaPicker.converter, elAttribute: "data-value" },
                location: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" },
                _language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
                _tags: {
                    converterFactory: Beef.TagPicker.createConverterFactory(null, {
                        sort: true
                    }),
                    elAttribute: "data-value"
                },
                _topics: {
                    converterFactory: Beef.TagPicker.createConverterFactory("", {
                        noHighlightTopics: true,
                        placeholderPersistant: false,
                        acceptNew: false,
                        sort: true
                    }),
                    elAttribute: "data-value" },
                _segments: {
                    converterFactory: Beef.TagPicker.createConverterFactory("", {
                        noHighlightSegments: true,
                        placeholderPersistant: false,
                        acceptNew: false,
                        sort: true
                    }),
                    elAttribute: "data-value" },
                _gender: { converter: Beef.GenderPicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            recordRecentMention(this.model);

            this.boundHandler = e => pagehideHandler(this.originalLocation, e);
            this.boundPopstateHandler = e => this.close();
            window.addEventListener('pagehide', this.boundHandler, true);           // Handles refreshing.
            window.addEventListener('popstate', this.boundPopstateHandler, true);   // Handles navigating with back / forward button through history sessions.

            this.mentionItem.show(new Beef.MentionItem.View({model: this.model, ignoreEditEvents: true, noSelect: true}));

            if (this.model.get('_canEditAttrs')) {
                Beef.MediaPicker.attach(this, ".mediaPicker", "_category", {onlyOne: true});
                Beef.LocationPicker.attach(this, ".location", "location", {onlyOne: true, noGroups: true});
                Beef.LanguagePicker.attach(this, "._language", "_language", {onlyOne: true});
                Beef.GenderPicker.attach(this, ".genderPicker", "_gender", {onlyOne: true});
            }
            if (this.model.get('_canEditTags')) {
                Beef.TagPicker.attach(this, "._tags", "_tags", {
                    mustSelectFromItems: false,
                    encloseNewItemsInQuotes: true,
                    searchFilter: Beef.TagPicker.tagChooser
                });
            }

            Beef.TagPicker.attach(this, "._topics", "_topics", {
                mustSelectFromItems: true,
                encloseNewItemsInQuotes: true,
                searchFilter: Beef.TagPicker.topicChooser,
                showTopicTagDetail: false,
                acceptNew: false
            });
            Beef.TagPicker.attach(this, "._segments", "_segments", {
                mustSelectFromItems: true,
                encloseNewItemsInQuotes: true,
                searchFilter: function(d) { return d.item.namespace === 'segment' },
                showTopicTagDetail: false,
                acceptNew: false
            });

            if (!this.model.get('_canEditTags') && !this.model.get('_tags')) {
                this.$("._tags.tf-read-only").text("None");
            }
            if (!this.model.get('_canEditTopics') && !this.model.get('_segments')) {
                this.$("._segments.tf-read-only").text("None");
            }

            if (VuexStore.state.account.showAVE && this.model.get('ave')) {
                var currency = getAccountCurrency();
                var $ave = this.$('.ave-value');
                convertTo(this.model.get('ave'), currency, function(ave, rate) {
                    var text = "<span title='Value calculated in South African Rands and converted to " +
                        Beef.CurrencyPicker.items[getAccountCurrency()] + " using an exchange rate of " + rate +
                        ". Exchange rate supplied by Yahoo Finance.'>" +
                        formatDefaultCurrency(ave) + "</span>";
                    $ave.html(text);
                });
            }

            if (!this.originalLocation) {
                var s = window.location.toString();
                this.originalLocation = s.substring(s.indexOf(window.location.pathname));
            }
            var code = this.options.accountCode || currentAccountCode();
            Beef.router.navigate("/" + code + "/mentions/" + this.model.get('id'), {replace: true});

            setTimeout(function() {
                this.$('input').blur();
            }.bind(this));
        },

        onEscape: function(ev) {
            if (this.popup) this.closePopup();
            else this.close();
        },

        onClose: function() {
            if (this.boundHandler) window.removeEventListener('pagehide', this.boundHandler, true);
            if (this.boundPopstateHandler) window.removeEventListener('popstate', this.boundPopstateHandler, true);
            if (this.originalLocation) Beef.router.navigate(this.originalLocation, {replace: true});
            if (this.intervalHandler) {
                clearInterval(this.intervalHandler);
                this.intervalHandler = null;
            }
            Beef.Tooltip.close();
        },

        ok: function(ev, dontVerify) {
            var attrs = Beef.SettingsDialog.View.prototype.ok.call(this);
            if (attrs && attrs._updateSnoek && attrs._canUpdateSnoek) {
                var loc = attrs.location
                if (loc && loc.charAt(0) == "'") loc = loc.substring(1, loc.length - 1)
                var payload = {
                    network: attrs.socialNetwork ? attrs.socialNetwork.id : "WEBSITE",
                    site: attrs.site,
                    name: attrs.authorName,
                    handle: attrs.authorHandle,
                    handleId: attrs.authorHandleId,
                    location: loc
                    //gender: attrs._gender -- not supported by snoek (yet)
                };
                if (attrs._category !== 'ENTERPRISE') payload.category = attrs._category;
                depcreatedSnoekPostJson("/v4/accounts/" + currentAccountCode() + "/online-profiles/update",
                    JSON.stringify(payload));
            }
        },

        togglePhrases: function(ev) {
            var $dd = $(ev.target).closest("dd");
            var collapsed = !$dd.hasClass("collapsed");
            $dd.toggleClass("collapsed", collapsed);
            $dd.find(".path i").attr("class", collapsed ? "icon-down-dir" : "icon-up-dir");
        },

        closePopup: function() {
            if (this.popup) {
                this.popup.hide();
                this.popup = null;
            }
        },

        showInPopup: function(picker, target) {
            this.closePopup();
            this.popup = new Beef.Popup.View({ closeOnHide: true, gobbleEsc: true });
            this.popup.setTarget(target);
            this.popup.show(picker);
        },

        editSentiment: function(ev) {
            if (!features.canEditSentient()) return;
            if (!this.model.get('_canEditAttrs')) return;
            var spm = new Backbone.Model();
            var picker = new Beef.SentimentPicker.View({view: this, model: spm, onlyOne: true});
            picker.setSelection([this.model.get('sentiment')]);
            picker.model.set('onlyOne', true);
            picker.on("change", function(sel){
                var v = parseInt(sel[0]);
                if (v >= 1) --v;        // remove when picker is using new scale
                this.model.set('sentiment', v);
                this.model.set('sentimentVerified', true);
                this.model.set('relevancy', 'RELEVANT');
                this.model.set('relevancyVerified', true);
                this.renderSentiments();
                // close popup after event or modelbinder is closed before getting the change event -> NPE
                setTimeout(function(){ this.closePopup() }.bind(this));
            }.bind(this));
            this.showInPopup(picker, $(ev.target));
        },

        renderSentiments: function() {
            this.$('.sentiments').html(Handlebars.render(require("@/mentions/EditMentionSentiment.handlebars"), this.model.attributes));
        },

        seeOriginal: function() {
            if (!this.model.get("reshareOfId")) {
                showErrorDialog("This mention is not a reshare");
                return;
            }

            Beef.MentionList.navigateToMentions(this.accountCode, "published inthelast year and id is '" + this.model.get('reshareOfId') + "'");
            this.close();
        },

        seeReplies: function() {
            if (!this.model.get("replyCount")) {
                showErrorDialog("This mention has no replies");
                return;
            }

            var brand = this.model.get("brand");
            Beef.MentionList.navigateToMentions(this.accountCode, "published inthelast year and ReplyTo IS '" + this.model.get('id') + "' " +
                "and brand isorchildof " + brand.id +
            " and relevancy isnt irrelevant");
            this.close();
        },

        seeReplyingTo: function() {
            var replyToId = this.model.get("replyToId");
            if (!replyToId) {
                showErrorDialog("This mention is not in reply to anything")
                return;
            }

            Beef.MentionList.navigateToMentions(this.accountCode, "published inthelast year and id is '" + replyToId + "'");
            this.close();
        },

        viewInteraction: function() {
            let interactionId = this.model.get("interactionId");
            let published = this.model.get("published");
            let afterDate = moment(published).subtract(2, 'weeks').format("YYYY/MM/DD");
            let beforeDate = moment(published).add(2, 'weeks').format("YYYY/MM/DD");

            if (interactionId && (afterDate && beforeDate)) {
                let filter = `Published AFTER '${afterDate}' AND Published BEFORE '${beforeDate}' AND interactionId IS '${interactionId}'`;

                gotoMentionPanel(filter, "published", true);
            }
        },

        seeAuthorMentions: function() {
            let authorFilter;
            let authorName = this.model.get('authorName');
            const authorId = this.model.get('authorId');
            if (authorId && authorName) authorFilter = `authorId is '${authorName} ${authorId}'`;
            else if (authorId) authorFilter = `authorId is '${authorId}'`;
            else authorFilter = "authorName contains '" + this.model.get('authorName') + "'";

            if (authorFilter) {
                this.close();
                gotoMentionPanel(appendFiltersReadably("published inthelast year", authorFilter));
            } else {
                throw new Error("Unable to determine what author to display mentions for");
            }
        },

        seeLocationMentions: function() {
            if (this.model.get('location')) {
                this.close();
                gotoMentionPanel(`published inthelast year and relevancy isnt irrelevant and location is ${this.model.get('location')}`)
            }
        },

        delete: function(ev) {
            ev.preventDefault();
            if (!this.model.get('_canEditAttrs')) return;
            var undelete = this.model.get('relevancy') == 'IRRELEVANT';
            this.model.set({relevancy: undelete ? 'RELEVANT' : 'IRRELEVANT', relevancyVerified: true});
            var e = this.$('.mention-item');
            if (undelete) e.html(require('@/mentions/MentionItem.handlebars')(this.model.toJSON()));
            Beef.MentionItem.onRender(e, this.model, false);
            if (!undelete) this.ok(null, true);
        },

        markRelevant: function(ev) {
            ev.preventDefault();
            if (!this.model.get('_canEditAttrs')) return;
            this.model.set({relevancy: 'RELEVANT', relevancyVerified: true});
            var e = this.$('.mention-item');
            e.html(require('@/mentions/MentionItem.handlebars')(this.model.toJSON()));
            this.ok(null, true);
        },

        showMenu: function(ev) {
            var that = this;
            this.popup = Beef.MiniMenu.show({
                template: require("@/mentions/EditMentionMenu.handlebars"),
                positions: ['top-left'],
                object: this,
                model: this.model,
                target: $(ev.target).closest("a"),
                onRender: function() {
                    this.$('.menu-conversation').toggleClass('disabled', that.options.noViewConversation === true);
                    this.$('.open-crowd').toggleClass('disabled', !that.model.get('crowdJobs'));
                    this.$('[data-method="createCrowdJudgement"]').toggleClass('disabled', !that.model.get('crowdJobs'));
                    this.$('.menu-see-original').toggleClass('disabled', !that.model.get('reshareOfId'));
                    this.$('.menu-see-mentions').toggleClass('disabled', !that.model.get('authorName'));
                    this.$('.menu-see-replying-to').toggleClass('disabled', !that.model.get('replyToId'));
                    this.$('.menu-see-replies').toggleClass('disabled', !that.model.get('replyCount'));
                    this.$('.menu-view-interactions').toggleClass('disabled', !that.model.get('interactionId'));
                    this.$('.open-engage').toggleClass('disabled', !that.model.get('_engageTicketed'));
                    this.$('.menu-see-tickets').toggleClass('disabled', !that.model.get('_engageTicketed'));
                    if (that.model.get('_anonymise')) {
                        this.$el.toggleClass("mini-menu-selectable");
                        this.$('a[data-method="anonymise"]').toggleClass("selected");
                    }
                }
            });
        },

        showGraph: function(ev) {
            if (ev && ev.preventDefault) ev.preventDefault();
            if (this.options.noViewConversation) return;
            this.cancel();
            Beef.MentionConversation.show(this.originalModel, this.originalModel.collection.accountCode || currentAccountCode());
        },

        seeTickets: function(ev) {
            var ticketId = this.model.get("ticketId");
            var brand = this.model.get("brand");

            if (!ticketId) {
                showErrorDialog("This mention is not a part of an Engage ticket");
                return;
            }

            Beef.MentionList.navigateToMentions(this.accountCode, "published inthelast year and ticketId IS '" + ticketId + "' " +
                "and brand isorchildof " + brand.id);
            this.close();
        },

        openEngage: function(ev) {
            var $target = this.$('.menu');
            var loading = showBusyNotification(
                "Looking up Engage information " + name + "…",
                null,
                $target
            );

            fetch("/api/engage/accounts/" + this.accountCode + "/mentions/" + this.model.get("id") + "/ticketId")
                .then(function(response) { return response.json() })
                .then(function(ticket) {
                    if (!ticket) {
                        showInfoDialog("This mention hasn't been added to a ticket in Engage");
                        return;
                    }
                    window.open(ticket.ticketLink, true);
                })
                .catch(function(error) {
                    console.error(error);
                    showErrorDialog("We're having a problem linking you through to Engage. Please try again in a bit.", "Temporary problems")
                })
                .finally(function() {
                    loading.close();
                })
        },

        openGrouse: function(ev) {
            if (ev && ev.preventDefault) ev.preventDefault();
            window.open(startupOptions.grouseUrl + '/v4/accounts/' + this.accountCode + '/mentions/' + this.model.get('id'));
        },

        openChervil: function(ev) {
            if (ev && ev.preventDefault) ev.preventDefault();
            window.open('https://chervil.brandseye.com/?id=' + this.model.get('id'));
        },

        reportSpamAuthor: async function() {
            if (!this.model.get("authorHandleId")) {
                await showErrorDialog("This author does not have a handle ID. An author needs a handle ID in order to be reported as a spammer.");
            } else {
                showDialog(ReportAuthorDialog, {
                    authorHandleId: this.model.get("authorHandleId"),
                    authorHandle: this.model.get("authorHandle"),
                    authorId: this.model.get("authorId"),
                    authorName: this.model.get("authorName"),
                    authorPictureLink: this.model.get("authorPictureLink"),
                    socialNetwork: this.model.get("socialNetwork"),
                    originalFilter: this.originalModel.filter
                });

                this.close();
            }
        },

        openCrowd: async function(ev) {
            if (ev && ev.preventDefault) ev.preventDefault();
            let crowdJobs = this.model.get('crowdJobs')
            if (!crowdJobs) return alert("This mention does not have a crowd rating");

            let jobs = await beef.post("/api/crowd/jobs", { ids: crowdJobs })
                .then(res => res.data)
                .catch(e => window.alert("Error fetching jobs"))

            if (!jobs) return
            if (!jobs.length) return alert("This mention's crowd jobs no longer exist");

            var methods = {};
            var menu = jobs.map(job => {
                methods["openJob" + job.id] = () => {
                    window.open((job.id < 300_000_000 ? 'https://crowd2.brandseye.com/jobs/' : 'https://crowd3.dataeq.com/jobs/') + job.id)
                }
                let name = job.typeDisplayName
                if (job.subType) {
                    if (job.subType.indexOf("segment:") === 0) {
                        let tag = deprecatedTagsStore.get(parseInt(job.subType.substring(8)))
                        name = tag.name
                    } else if (job.subType.indexOf("topicTree:") === 0) {
                        let tag = deprecatedTagsStore.get(parseInt(job.subType.substring(10)))
                        name = tag.name
                    } else {
                        name += " (" + job.subType + ")"
                    }
                }
                return {
                    text: name + " job",
                    tooltip: "See the crowd job " + job.id + " in the Crowd",
                    method: "openJob" + job.id
                }
            });

            this.closePopup();
            this.popup = Beef.MiniMenu.show({
                positions: ['top-left'],
                target: $(".btn.menu"),
                menu: menu,
                object: methods
            });
        },

        createCrowdJudgement: function(ev) {
            if (!this.model.get('crowdJobs')) return
            showDialog(CreateCrowdJudgement, { mention: this.model.attributes })
        },

        mentionItemTranslateClicked: function(ev) {

        },

        translate: function() {
            Beef.Dialogs.translate(this.model);
        },

        anonymise: function() {
            this.model.set("_anonymise", !this.model.get("_anonymise"));
        },

        emailMention: function(ev) {
            if (ev && ev.preventDefault) ev.preventDefault();
            var $button = this.$('.menu');
            Beef.InteractDialog.show({
                target: $button,
                accountCode: this.accountCode,
                email: true,
                mentionIds: [this.model.get('id')]
            });
        },

        showWords: function(ev) {
            if (ev && ev.preventDefault) ev.preventDefault();
            Beef.MentionWords.show(this.model.get('id'));
        },

        getFilename: function() {
            return this.model.accountCode + "-" + this.model.id;
        },

        savePNG: function() {
            var container = this.$('.mention-item');
            Beef.MentionItem.saveImage($('.btn.menu.popup-menu.pull-left', this.$el), container, this.model.attributes);
        },

        mousedown: function(ev) {
            Beef.SettingsDialog.View.prototype.mousedown.call(this);
            if (this.mentionItem.currentView) {
                this.mentionItem.currentView.closePopups();
            }
        },


    });
});