/**
 * Generic Message
 */
Beef.module("GenericMsg").addInitializer(function(startupOptions) {

    this.show = function(msgObj) {
        var v = new View({model: new Backbone.Model({
            title: msgObj.title ? msgObj.title : "Warnings for this Section",
            generalMessages: msgObj.generalMessage ? msgObj.generalMessage : null,
            section : msgObj.section ? msgObj.section : null,
            widgets : msgObj.widgets ? msgObj.widgets : null,
            profileError : msgObj.profileError ? msgObj.profileError : null,
            authLink : msgObj.authLink ? msgObj.authLink : null
        })});
        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"]});
        popup.setTarget($('#content'));
        popup.show(v);
    };

    var View = Backbone.Marionette.ItemView.extend({
        template: require("@/app/GenericMsg.handlebars"),

        attributes: { class: "gen-msg dialog" },

        events: {
            "click .oink": "cancel"
        },

        onRender: function() {

        },

        cancel: function() {
            this.parentPopup.hide();
        },

        showSpinner: function() {
            this.$('.dialog-body .info').toggleClass("busy", true);
        }
    });
});
