<template>
    <div class="sidebar-list">
        <div v-if="!list" style="padding-top: 16px">
            <span class="spinner"></span>
        </div>
        <div v-else v-for="b in list" class="brand" :key="b.id" :class="{active: b.id === value}">
            <a class="btn tree depth1" @click="$emit('input', b.id)">{{b.name}}</a>
        </div>
    </div>
</template>

<script>
    import { deprecatedBrandsStore } from '../store/deprecated/Stores'

    export default {
        name: "BrandList",

        props: {
            value: Number,
            brandIds: Array     // only show these brands
        },

        computed: {
            list() {
                if (!deprecatedBrandsStore.list) return null
                if (this.brandIds) return deprecatedBrandsStore.list.filter(b => this.brandIds.indexOf(b.id) >= 0)
                else return deprecatedBrandsStore.list
            }
        },

        mounted() {
            deprecatedBrandsStore.refresh(true).then(() => this.ensureDefaultSelection())
        },

        methods: {
            ensureDefaultSelection() {
                if (this.value) return
                let bid = deprecatedBrandsStore.getDefaultBrandId()
                if (bid) this.$emit('input', bid)
            }
        }
    }
</script>

<style scoped>

</style>