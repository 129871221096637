<template>
    <dialog-box @close="$emit('close')" width="600px" overlay class="added-removed-page-details" title="Added/Removed Pages">
            <p>
                You have authorised/unauthorised the following <i class="symbol-facebook-rect"></i> Facebook pages.
                <span class="added-removed-page-details__bold-text">Unauthorised</span> pages that you previously added to accounts
                are now  <span class="added-removed-page-details__bold-text">unauthorised</span> on those accounts.
            </p>
            <div class="added-removed-page-details__table-container dark-scrollbars dark-scrollbars--visible">
                <table class="table table-condensed table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Facebook Page</th>
                        <th>Connected Instagram Account</th>
                        <th>State</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="page in [...addedPages, ...removedPages]"
                        :key="page.handleId">
                        <td>
                            <online-profile class="added-removed-page-details__online-profile" :profile="page"/>
                        </td>
                        <td>
                            <online-profile class="added-removed-page-details__online-profile" v-if="page.linkedProfile" :profile="page.linkedProfile"/>
                        </td>
                        <td style="vertical-align: middle; padding-left: 10px">
                            {{ page.added ? 'Authorised' : 'Unauthorised' }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
export default {
    name: "AddedRemovedPageDetails",
    components: {OnlineProfile, DialogBox},

    props: {
        curPages: {
            type: Array,
            required: true
        },
        oldPages: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            addedPages: [],
            removedPages: []
        }
    },

    created() {
        this.addedPages = this.getAddedPages();
        this.removedPages = this.getRemovedPages();
    },

    methods: {
        getAddedPages() {
            let addedHandleIds = [];
            let addedPages = [];

            let oldHandleIds = new Set(this.oldPages.map(page => page.handleId));
            let curHandleIds = new Set(this.curPages.map(page => page.handleId));

            curHandleIds.forEach(curHandleId => {
                if (!oldHandleIds.has(curHandleId)) addedHandleIds.push(curHandleId);
            });

            addedHandleIds.forEach(addedHandleId => {
               let addedPage = this.curPages.find(page => page.handleId === addedHandleId);
               if (addedPage) {
                   addedPage.added = true;
                   addedPages.push(addedPage);
               }
            });

            return addedPages;
        },

        getRemovedPages() {
            let removedHandleIds = [];
            let removedPages = [];

            let oldHandleIds = new Set(this.oldPages.map(page => page.handleId));
            let curHandleIds = new Set(this.curPages.map(page => page.handleId));

            oldHandleIds.forEach(oldHandleId => {
                if (!curHandleIds.has(oldHandleId)) removedHandleIds.push(oldHandleId);
            });

            removedHandleIds.forEach(removedHandleId => {
                let removedPage = this.oldPages.find(page => page.handleId === removedHandleId);
                if (removedPage) {
                    removedPage.added = false;
                    removedPages.push(removedPage);
                }
            });

            return removedPages;
        }
    }
}
</script>

<style scoped lang="sass">

.added-removed-page-details
    color: var(--be-colour-text-dark)

    &__bold-text
        font-weight: bold
        color: white

    &__table-container
        width: 100%
        height: clamp(300px, 30vh, 600px)
        margin-bottom: 30px
        padding-right: 5px
        overflow-y: auto

        table
            border-collapse: separate
            border-spacing: 0 5px
            border: none
            background: var(--body-background-colour)

        th
            padding-left: 10px
            color: white

        td
            border-bottom: 1px solid #272727
            background: #333333

            &:last-of-type
                border-right: 1px solid #272727

    &__online-profile
        height: 35px

</style>