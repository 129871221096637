var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <li><a id=\"tagMentions\" data-method=\"tagMentions\" title=\"Tag these mentions\">Tag mentions...</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li><a id=\"mentions\" data-method=\"viewMentions\" title=\"See the mentions for this topic\">Show mentions</a></li>\n<li><a id=\"tab-mentions\" data-method=\"viewMentionsInTab\" title=\"See the mentions for this topic in a new tab\">Show mentions in tab</a></li>\n<li><a id=\"wordcloud\" data-method=\"viewWordcloud\" title=\"See the mentions for this topic in a Word Cloud\">Show word cloud</a></li>\n"
    + ((stack1 = __default(require("../../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"editMentions",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":6,"column":14}}})) != null ? stack1 : "")
    + "<li class=\"divider\"></li>\n<li><a id=\"authors\" data-method=\"viewAuthors\" title=\"See the authors of the this topic's mentions\">Show authors</a></li>\n<li class=\"divider\"></li>\n<li><a data-method=\"toggleInitialSelection\" title=\"Select this item when the diagram is displayed\">Toggle Initial Selection</a></li>\n";
},"useData":true});