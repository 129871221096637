<template>
    <div v-once
         class="explore-panel-short-loading animated fadeIn delay-400">
        <spinner-component :size="16"/> Loading...
    </div>
</template>


<script>
import SpinnerComponent from "@/components/SpinnerComponent";
export default {
    components: {SpinnerComponent}
}
</script>

<style scoped lang="sass">
.explore-panel-short-loading
    display: flex
    text-align: center
    margin-top: 10px
    font-variant: small-caps
    place-items: center
    place-content: center
</style>