<template>
    <div v-if="tag" class="be-tag-component">
        <span>{{ name }}</span>
        <span v-if="namespace"
              class="be-tag-component__namespace">
            ({{ namespace }})
        </span>
    </div>
</template>


<script>/**
 * Displays tag names. Does not display it in an actual "tag" visual element. Use slotted-tag if you need
 * to do that.
 */
import {formatTagNamespace} from "@/app/utils/Format";

export default {
    props: {
        tag: {
            type: Object,
            required: true
        },
    },

    computed: {
        name() {
            return this.tag?.name ?? "NA"
        },

        namespace() {
            return formatTagNamespace(this.tag);
        }
    }
}
</script>


<style scoped lang="sass">

.be-tag-component
    display: inline-block

.be-tag-component__namespace
    color: var(--tag-picker-detail)

</style>