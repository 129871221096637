/**
 * Creates a be-inline-svg tag, to inline external svg into the dom.
 * Currently has a problem, since the grails redirects require CORS headers to be
 * turned on for our beef-merry3, beef-pippin3
 */
Beef.module("InlineSvg").addInitializer(function(startupOptions) {

    // We cache the data to avoid always have repeated
    // image flashing when showing an inline svg.
    var cache = new Map();

    function BeInlineSvg() {
        return Reflect.construct(HTMLElement, [], BeInlineSvg);
    }

    BeInlineSvg.prototype = Object.create(HTMLElement.prototype);

    Object.defineProperty(BeInlineSvg, 'observedAttributes', {
        get: function() { return ['src']; }
    });


    // Respond to attribute changes.
    BeInlineSvg.prototype.attributeChangedCallback = function(attr, oldValue, newValue) {
        if (attr === 'src' && newValue) {
            if (cache.has(newValue))
            this.innerHTML = cache.get(newValue);

            $.ajax({
                url: newValue,
                type: "GET",
                success: function(data, stats, jqXHR) {
                    this.innerHTML = jqXHR.responseText;
                }.bind(this),
                error: function(error) {
                    console.error("Error loading inline svg", error);
                }
            })
        }
    };

    if (typeof customElements !== "undefined") {
        customElements.define('be-inline-svg', BeInlineSvg);
    } else {
        console.error("customElements is not supported. Please upgrade your browser.")
    }


});