import * as d3 from "d3"
import {cloneDeep} from 'lodash'

/**
 * Low level d3 code to render the response tables.
 */
export default function beefRenderResponseTable(domNode, width, height, data, options) {
    options = options || {}

    data = cloneDeep(data)
    let tables = data.tables

    // calc the max value for each column across all tables
    let max = { }, tot = { }
    tables.forEach(t => {
        t.rows = t.rows.filter(row => row.value)
        t.rows.forEach(row => {
            row.cols.forEach(col => {
                max[col.id] = Math.max(max[col.id] || 0, col.value)
                tot[col.id] = (tot[col.id] || 0) + col.value
            })
        })
    })
    max.responseRate = 1    // don't want bars relative to each other for this one
    max.responseTime = Math.max(max.responseTime, 24 * 60 * 60)

    let noCaption = false
    if (tables[0].id === "both") { // mentions total is only from 'both' table if this is present
        tot.mentions = 0
        tables[0].rows.forEach(row => tot.mentions += row.cols[0].value || 0)
        if (tables.length === 1) noCaption = true
    }

    let sampleSize = 0
    for (let i = 0; i < tables.length; i++) {
        let t = tables[i]
        if (t.id === "both") {
            sampleSize = t.value
            break
        } else {
            sampleSize += t.value
        }
    }
    data.sampleSize = sampleSize

    // calc the bar width for each column based on its value compared to the max for that column
    tables.forEach(t => {
        if (sampleSize && t.showPercent && !isNaN(t.value)) t.percent = t.value * 100 / sampleSize
        t.rows.forEach(row => {
            row.cols.forEach(col => col.barWidth = max[col.id] ? col.value * 100 / max[col.id] : 0)
            // first col (distribution of mentions) is relative to the total not max for the col
            let col = row.cols[0]
            col.barWidth = tot[col.id] ? col.value * 100 / tot[col.id] : 0
        })
    })

    let barWidth = (width - 106) / 3 - 45
    if (barWidth < 25) barWidth = 25
    //console.log("width " + width + " bw " + barWidth)

    let sel = d3.select(domNode)
    let table = ensure(sel, "table", "data").attr("cell-spacing", 0).attr("cell-padding", 0)

    let bodies = table.selectAll("tbody.data").data(tables, d => d.id)
    bodies.exit().remove()
    bodies = bodies.enter().append("tbody").attr("class", "data").merge(bodies)
    bodies.each(function(table) {
        let tbody = d3.select(this)

        let spacer = tbody.select("tr.spacer")
        if (spacer.empty()) tbody.append("tr").attr("class", "spacer " + table.id).append("td")

        let header = tbody.select("tr.header")
        if (header.empty()) {
            let tr = tbody.append("tr").attr("class", "header")
            tr.append("th")
            table.cols.forEach((c,i) => tr.append("th").text(c).attr("class", "col" + i))
        }

        let col0 = header.select("th.col0").text((table.id === "both" ? "" : table.label + " ") + "Distribution" +
            (table.percent !== undefined ? " " + formatPercent(table.percent) : ""))
        if (options.onClick) col0.on("click", d => options.onClick(table))

        let trs = tbody.selectAll("tr.data").data(table.rows, d => d.id)
        trs.exit().remove()
        trs = trs.enter().append("tr").attr("class", d => "data " + d.id).merge(trs)
        trs.each(function(row, i) {
            let firstRow = i === 0
            let lastRow = i === table.rows.length - 1
            let tr = d3.select(this)
            ensure(tr, "td", "header").text(row.label)

            let tds = tr.selectAll("td.data").data(row.cols, d => d.id)
            tds.exit().remove()
            tds = tds.enter().append("td").attr("class", "data").merge(tds)
            tds.each(function(col, i) {
                let td = d3.select(this).classed("col" + i, true);
                let outer = ensure(td, "div", "outer")

                let dm = outer.select("div.day-marker")
                let dmPercent = i == 2 ? 24 * 3600 / max[col.id] : 0
                if (dmPercent > 0.95) dmPercent = 0
                if (firstRow && dmPercent) {
                    if (dm.empty()) dm = outer.append("div").attr("class", "day-marker")
                    dm.style("left", (36 + dmPercent * barWidth) + "px")
                    dm.style("height", (table.rows.length * 28 - 5) + "px")
                } else {
                    dm.remove()
                }

                let wrap = ensure(outer, "div", "wrap")

                let value = ensure(wrap, "div", "value")
                if (col.id === "responseRate") value.text(formatPercent(col.value * 100))
                else if (col.id === "mentions") value.text(formatPercent(col.value * 100 / (tot.mentions || 1)))
                else if (col.id === "responseTime") value.text(formatHours(col.value))
                else value.text(col.value)

                let bar = ensure(wrap, "div", "bar").style("width", barWidth + "px")
                ensure(bar, "div", "inner").style("width", col.barWidth + "%")

                let dmt = outer.select("div.day-marker-text")
                if (lastRow && dmPercent) {
                    if (dmt.empty()) dmt = outer.append("div").attr("class", "day-marker-text").text("1 day")
                    dmt.style("left", (36 + dmPercent * barWidth - 13) + "px")
                } else {
                    dmt.remove()
                }

                let bl = outer.selectAll("div.bar-label")
                if (lastRow && i === 1) {
                    if (outer.select("div.bar-label").empty()) {
                        outer.append("div").attr("class", "bar-label left").text("Responded to")
                        outer.append("div").attr("class", "bar-label right").text("Not responded to")
                    }
                } else {
                    bl.remove()
                }

                if (options.onClick) td.on("click", d => options.onClick(table, row, d))
            })
        })
    })

    return data
}

function formatPercent(p) {
    return Math.floor(p + 0.5) + "%"
}

function formatHours(secs) {
    if (!secs) return "-"
    let h = secs / (60 * 60)
    return (h < 10 ? Math.floor(h * 10 + 0.5) / 10 : Math.floor(h + 0.5)) + "h"
}

function ensure(parent, tag, cls) {
    let sel = parent.select(tag + (cls ? "." + cls : ""))
    if (sel.empty()) {
        sel = parent.append(tag)
        if (cls) sel.attr("class", cls)
    }
    return sel
}
