<template>
    <h4 class="be-selector-menu-heading">
        <slot/>
    </h4>
</template>

<script>
export default {

}
</script>


<style scoped lang="sass">

.be-selector-menu-heading
    box-sizing: border-box
    padding: 4px 12px
    background: var(--colour-background-black)
    border: 1px solid #111
    cursor: default
    font-size: 12px
    text-transform: uppercase
    color: var(--clr-sidebar-header)
    margin: 0
    line-height: 20px

</style>