<template>
    <div class="spike-summaries deq-reset"
         :class="classes">
        <section @mouseenter="stopTimer"
                 @mouseleave="startTimer">
            <section v-if="loading" class="spike-summaries__empty" key="loading">
                <NoTopicsMessage>
                    Examining conversation spikes...
                </NoTopicsMessage>
            </section>
            <section v-else-if="!spiking || !spiking.length" class="spike-summaries__empty" key="empty">
                <slot name="empty">
                    <NoTopicsMessage>
                        There are no spikes in this time period
                    </NoTopicsMessage>
                </slot>
            </section>
            <section v-else-if="currentSummary" :key="currentSummary.risk.topic.id">
                <h2 class="animated fadeIn" ref="heading">
                    <strong>{{currentSummary.risk.topic.name}}</strong>
                    spiked on <deq-date :date="currentSummary.risk.maxDate" format="D MMMM, YYYY"/>
                    with
                    <NetSentiment :net-sentiment="currentSummary.risk.net"/>
                </h2>
                <LoadingMessage v-if="currentSummary.loading" class="spike-summaries__summary">
                    Summarising the conversation spike...
                </LoadingMessage>
                <div v-else-if="!currentSummary.error"
                   ref="summary"
                   class="spike-summaries__summary animated fadeIn delay-400">
                    <IfDebug>
                        🐛<a :href="`https://turducken.dataeq.com/pages/summaries/${currentSummary.summary.id}`"
                            tooltip="Open on turducken"
                            target="_blank"> {{currentSummary.summary.id}}</a>
                    </IfDebug>

                    <p>
                        {{currentSummary.summary.summary}}
                    </p>

                    <SeeMentionsButton :filter="currentSummary.risk.filter"
                                       @click="manualStopTimer()"
                                       tooltip="Click to see mentions related to this spike"/>
                </div>
                <p v-else class="spike-summaries__summary">
                    Error
                </p>
            </section>

        </section>

        <div class="spike-summaries__progress" ref="progress" v-if="shouldAnimate">

        </div>

        <SelectorMenu v-if="shouldAnimate"
                      class="spike-summaries__tags animated fadeIn">

            <SelectorMenuItem v-for="(topic, index) in spiking"
                              :active="currentSummary && topic.topic.id === currentSummary.risk.topic.id"
                              @click="setCurrentSummaryIndex(index)"
                              :key="topic.topic.id">
                {{topic.topic.name}}
            </SelectorMenuItem>
            <SelectorMenuItem  v-if="paused" @click="manualStartTimer()" tooltip="Click to begin cycling through summaries of the spikes">
                <span><i class="symbol-play"></i></span>
            </SelectorMenuItem>
            <SelectorMenuItem v-if="!paused" @click="manualStopTimer()" tooltip="Click to pause cycling through the summaries">
                <span><i class="symbol-pause"></i></span>
            </SelectorMenuItem>
        </SelectorMenu>
    </div>
</template>


<script>
import {summariseFilter} from "@/app/utils/turducken";
import DeqDate from "@/components/formatters/DeqDate.vue";
import SelectorMenu from "@/components/selector-menu/SelectorMenu.vue";
import SelectorMenuItem from "@/components/selector-menu/SelectorMenuItem.vue";
import NetSentiment from "@/components/NetSentiment.vue";
import LoadingMessage from "@/components/LoadingMessage.vue";
import IfDebug from "@/components/IfDebug.vue";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage.vue";
import SeeMentionsButton from "@/components/buttons/SeeMentionsButton.vue";
import {isDevEnvironment} from "@/app/utils/Account";
import {isMashAdmin} from "@/app/Permissions";

const TIME = 10_000;

export default {

    components: {
        SeeMentionsButton,
        NoTopicsMessage,
        IfDebug,
        LoadingMessage,
        NetSentiment,
        SelectorMenuItem,
        SelectorMenu,
        DeqDate
    },

    props: {
        topics: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            summaries: [],
            currentSummaryIndex: null,
            animation: null,

            paused: false,
            manualPause: false,
            startMs: null,
            stopMs: null,
            timeRemaining: TIME
        }
    },

    computed: {
        classes() {
            if (this.loading) return ["spike-summaries__loading"];
            return [];
        },
        spiking() {
            if (!this.topics?.length) return [];
            return this.topics.filter(d => d.isSpike);
        },

        currentSummary() {
            if (this.currentSummaryIndex === null) return null;
            return this.summaries.at(this.currentSummaryIndex);
        },

        shouldAnimate() {
            return this.summaries.length > 1 && !this.loading && !this.currentSummary?.loading;
        }
    },

    beforeDestroy() {
        this.stopTimer();
    },

    deactivated() {
        this.stopTimer()
    },

    activated() {
        this.startTimer()
    },

    watch: {
        async spiking() {
            this.summaries = [];
            this.currentSummaryIndex = null;

            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            if (this.animation) {
                this.animation.finish();
            }

            if (this.spiking.length <= 0) return;


            this.currentSummaryIndex = 0;
            let animationsBegun = false;
            const summaries = this.summaries = [];
            for (const risk of this.spiking) {
                const item = {
                    risk: risk,
                    summary: null,
                    loading: true,
                    error: false
                };

                summaries.push(item);

                summariseFilter(risk.filter)
                    .then(results => {
                        item.summary = results;
                        item.loading = false;
                    })
                    .catch(e => {
                        if (e.isAxiosError && !e.response) {
                            if (isDevEnvironment() && isMashAdmin()) {
                                item.summary = {
                                    id: "dear-dev:turn-on-turducken",
                                    summary: "Turducken hasn't been started",
                                    algorithm: "DEFAULT_TEXT"
                                };
                            }
                        } else {
                            console.error(e);
                            item.error = true;
                        }
                    })
                    .finally(() => {
                        item.loading = false;
                    });


                if (!animationsBegun) {
                    this.startAnimation();
                    animationsBegun = true;
                }

            }
        },

        shouldAnimate(value) {
            if (value) {
                this.$nextTick(() => this.startAnimation());
            } else {
                this.animation?.finish();
                this.animation = null;
            }

        }
    },

    methods: {
        calculateRemainingTime() {
            if (!this.startMs || !this.stopMs) return TIME;
            const remainingTime = TIME - (this.stopMs - this.startMs);

            if (remainingTime < 0) return TIME;
            return Math.min(TIME, remainingTime);
        },

        mouseenter() {
            this.stopTimer();
        },

        mouseleave() {
            this.startTimer();
        },

        stopTimer() {
            this.stopMs = Date.now();
            this.paused = true;
            this.animation?.pause();
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.timeRemaining = this.calculateRemainingTime();
        },

        manualStopTimer() {
            this.manualPause = true;
            this.stopTimer();
        },

        startTimer() {
            // Here the user has previously clicked the pause button
            // and has not yet asked for animations to be unpaused.
            if (this.manualPause) return;

            if (this.timeRemaining) {
                // Adjust start time so that multiple stop / start
                // events don't cause the time remaining to be miscalculated.
                this.startMs = Date.now() - (TIME - this.timeRemaining);
            }

            this.paused = false;
            this.animation?.play();
            this.queueStep();
        },

        manualStartTimer() {
            this.manualPause = false;
            this.startTimer();
        },

        setCurrentSummaryIndex(index) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = null;
            this.animation.finish();

            this.startMs = Date.now();
            this.stopMs = null;
            this.timeRemaining = null;

            this.currentSummaryIndex = index;
            this.startAnimation();
        },

        startAnimation() {
            if (!this.shouldAnimate) return;

            this.startMs = Date.now();
            this.stopMs = null;
            this.timeRemaining = null;
            if (this.timeout) clearTimeout(this.timeout);

            if (this.manualPause) return;

            if (!this.animation) {
                this.animation = this.animation ?? this.$refs.progress.animate({ width: "100%" }, { duration: TIME});
            } else {
                this.animation.finish();
                this.animation.play();
            }

            this.queueStep();

        },

        queueStep() {
            if (this.paused) return;
            if (!this.shouldAnimate) return;

            this.timeout = setTimeout(async () => {
                try {
                    const animate = el => el.animate({opacity: [1, 0]}, { duration: 250 }).finished;
                    const animations = [];
                    if (this.$refs.summary) animations.push(animate(this.$refs.summary));
                    if (this.$refs.heading) animations.push(animate(this.$refs.heading));
                    if (animations.length) await Promise.all(animations);
                } catch (e) {
                    console.warn("Animation errors", e);
                }

                this.currentSummaryIndex++;
                if (this.currentSummaryIndex >= this.summaries.length) {
                    this.currentSummaryIndex = 0;
                }

                // If this is null, we've stopped the animation somewhere else.
                if (!this.animation) return;

                this.animation.finish();
                this.animation.play();

                this.startMs = Date.now();
                this.stopMs = null;
                this.timeRemaining = null;
                this.queueStep();
            }, this.timeRemaining ?? TIME);


            // Try to reduce jumping around and relaying out content
            // because of height changes.
            this.$el.style['min-height'] = `${this.$el.clientHeight}px`;

        },
    }

}
</script>


<style scoped lang="sass">

.spike-summaries
    display: flex
    flex-direction: column
    --min-height: 17ch

.spike-summaries__tags
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: fit-content
    margin-inline: auto
    margin-top: 0.5rem

.spike-summaries__progress
    height: 3px
    width: 0
    background: var(--background-menu-active)
    margin-top: auto

.spike-summaries__summary
    margin-block: auto
    min-height: var(--min-height)
    display: block
    padding: 0 1rem 1rem

.spike-summaries__empty
    min-height: var(--min-height)
    display: grid
    place-content: center


</style>