import {deprecatedFetchProfiles} from "@/data/DeprecatedBeefCache";
import _ from 'underscore';
import {getProfileIcon} from "@/app/utils/Profiles";
import {currentAccountCode} from "@/app/utils/Account";
import {getProfileDisplayName} from "@/setup/profiles/ProfileUtils";
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";
import {splitUnique} from "@/app/utils/StringUtils";

Beef.module("ProfilePicker").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.Layout.extend({
        template: require("@/dashboards/filter/pickers/profile/ProfilePicker.handlebars"),

        attributes: { class: "profile-picker dialog" },

        regions: {
            listRegion: '.profile-list'
        },

        events: {
            "mousedown": "mousedown",
            "click .profile-select-all": "selectAll",
            "click .profile-deselect-all": "deselectAll",
            "click .profile-select-twitter": "selectTwitter",
            "click .profile-select-facebook": "selectFacebook",
            "click .profile-select-instagram": "selectInstagram",
            "click .close": "close"
        },

        templateHelpers: function() {
            // flags for disabling 'select all' buttons for different profile types
            var disabled = this.options.disabled ? this.options.disabled : {};
            return {
                title: this.options.title,
                disabled: disabled
            }
        },
        //-----------------------------------

        mousedown: function(ev) {
            ev.preventDefault();    // this stops stuff on the picker from getting focus
        },

        selectAll: function(ev) {
            ev.preventDefault();
            if (this.model.get('onlyOne') || $(ev.target).hasClass('disabled')) return;

            _(this.list.collection.models).each(function (m) {
                m.setSelected(true);
            });
            this.handleCollectionChange();
        },

        deselectAll: function(ev) {
            ev.preventDefault();
            if (this.model.get('onlyOne')) return;

            _(this.list.collection.models).each(function (m) {
                m.setSelected(false);
            });
            this.handleCollectionChange();
        },

        selectTwitter: function(ev) {
            ev.preventDefault();
            if (this.model.get('onlyOne') || $(ev.target).hasClass('disabled')) return;

            _(this.list.collection.models).each(function (m) {
                switch (m.get('type')) {
                    case "TWITTER_SCREEN_NAME":
                        m.setSelected(true); break;
                    case "FACEBOOK_PAGE":
                    case "FACEBOOK_USER":
                    default:
                        m.setSelected(false);
                }
            });
            this.handleCollectionChange();
        },

        selectFacebook: function(ev) {
            ev.preventDefault();
            if (this.model.get('onlyOne') || $(ev.target).hasClass('disabled')) return;

            _(this.list.collection.models).each(function (m) {
                switch (m.get('type')) {
                    case "FACEBOOK_PAGE":
                    case "FACEBOOK_USER":
                        m.setSelected(true); break;
                    case "TWITTER_SCREEN_NAME":
                    default:
                        m.setSelected(false);
                }
            });
            this.handleCollectionChange();
        },

        selectInstagram: function(ev) {
            ev.preventDefault();
            if (this.model.get('onlyOne') || $(ev.target).hasClass('disabled')) return;

            _(this.list.collection.models).each(function (m) {
                switch (m.get('type')) {
                    case "INSTAGRAM_USER":
                    case "INSTAGRAM_BUSINESS":
                        m.setSelected(true); break;
                    default:
                        m.setSelected(false);
                }
            });
            this.handleCollectionChange();
        },

        handleCollectionChange: function() {
            var ids = _(this.list.collection.models).chain().filter(function(m) { return m.isSelected(); }).pluck('id').value();

            if (this.model.get('onlyOne')) {
                if (ids.length > 1) {
                    var currentId = this.previousId || 0;
                    ids = [ _(ids).find(function(d) { return d != currentId }) ];

                    _(this.list.collection.models).each(function (m) {
                        m.setSelected(m.id == ids[0]);
                    });
                }

                this.previousId = ids.length ? ids[0] : 0;
            }

            this.trigger('change', ids);
        },

        onRender: function() {
            var collection = new Beef.OnlineProfileList.Collection(null, {
                accountCode: currentAccountCode(),
                skipMeasurements: true
            });

            if (this.model.get('onlyOne')) {
                this.$('.actions .btn').toggleClass('disabled', true);
            }

            this.listenTo(collection, 'sync', this.updateSelected, this);
            this.listenTo(collection, 'reset', this.updateSelected, this);
            this.listenTo(collection, 'add', this.updateSelected, this);
            this.listenTo(collection, 'change:selected', this.handleCollectionChange, this);

            this.list =  new Beef.OnlineProfileList.View({collection: collection, display: 'short', select: true});
            this.listRegion.show(this.list);

            if (this.options.profiles) {
                collection.reset(this.options.profiles);
            }
            else {
                this.list.fetch();
            }
        },

        updateSelected: function() {
            if (!this.list) return;

            var list = this.list;
            var profileAttribute = this.model.get('profiles');

            if (profileAttribute) {
                var selected = _(splitUnique(profileAttribute)).map(function (val) { return parseInt(val); });

                setTimeout(function() {
                    _(list.collection.models).each(function (m) {
                        m.setSelected(_(selected).contains(m.id));
                    });
                })
            }
        },

        /** Values can be an array or space separated string. */
        setSelection: function(values) {
            this.model.set('profiles', values);
            this.updateSelected();
        },

        /** Get selected values as an array. */
        getSelection: function() {
            return _(this.list.collection.models).chain().filter(function(m) { return m.selected; }).pluck('id').value();
        }
    });

    this.createConverterFactory = function(placeholder) {
        return function(view) {
            var conv;
            return function(direction, value) {
                value = value? value.toString() : null;
                if (value && value.length > 0) {
                    var binding = this;
                    if (conv) {
                        return conv.call(binding, direction, value);
                    }
                    deprecatedFetchProfiles(view, function(profiles) {
                        conv = createTagConverter({
                            tooltip: function(code) {
                                var value = profiles.map[code];
                                var name = getProfileDisplayName(value.handle, value.handleId, value.name, value.type);
                                var network = Beef.Conversation.getNameOfNetwork(value, null);
                                return "The online profile, '" + value.name + "' (" + name + ")" + (network ? (", on " + network) : "");
                            },
                            items: profiles.map,
                            nameFormatter: function(value, isNeg) {
                                var prefix = (isNeg ? "-" : "") + getProfileIcon(value.type);
                                var name = getProfileDisplayName(value.handle, value.handleId, value.name, value.type);
                                return prefix + name;
                            },
                            placeholder: placeholder
                        });
                        return conv.call(binding, direction, value);
                    });
                } else {
                    if (placeholder) $(this.boundEls[0]).html(toPlaceholderHTML(placeholder));
                    else $(this.boundEls[0]).text("");
                }
                return value;
            }
        };
    };

    this.converterFactory = this.createConverterFactory(null);

    /**
     * Attach a profile picker to a view attribute identified by selector. Updates attribute in the view's model.
     *
     * Supported options
     * <ul>
     *     <li>onlyOne - allow only one profile to be chosen.
     *     <li>profiles - A list of profiles that should be shown instead of showing all available profiles.
     *     <li>title - The title to use, apart from the default.
     *     <li>disabled - An object with boolean properties 'all', 'facebookAll', 'instagramAll', 'twitterAll'.
     *                    If true, the 'select all' buttons for these profile types will be disabled. By default,
     *                    all buttons are enabled (i.e. set to false).
     */
    this.attach = function(view, selector, attribute, options) {
        options = Object.assign({
            disabled: {
                all: false,
                facebookAll: false,
                instagramAll: false,
                twitterAll: false
            },
            title: "Include statistics for these profiles"
        }, options || {});
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
