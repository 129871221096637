var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "/settings\">settings</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sidebar\">\n    <div class=\"inner-bg\"></div>\n    <div class=\"fixed-inner\">\n        <div>\n            <div class=\"brand-menu dark-scrollbars dark-scrollbars--visible\"></div>\n        </div>\n    </div>\n</div>\n\n<div class=\"sidebar-right\">\n"
    + ((stack1 = container.invokePartial(require("../app/Header.handlebars"),depth0,{"name":"../app/Header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"mention-options\"></div>\n\n    <div class=\"mentions-outer\">\n        <div class=\"no-mentions animated fadeIn\" style=\"display: none\">\n            No mentions match your filter\n        </div>\n\n        <div style=\"margin-left: 20px\" class=\"errors\" style=\"display: none\">\n            <div class=\"choose-brand text-warning\" style=\"display: none\">\n                Please choose a brand from the sidebar on the left.\n                You can also set a default brand in your account\n                "
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"withAccountInfo") : depth0), depth0),{"name":"withAccountInfo","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":98}}})) != null ? stack1 : "")
    + ".\n            </div>\n            <div class=\"fetch-error text-warning\" style=\"display: none\"></div>\n        </div>\n\n        <section class=\"mention-panel__loading\">\n        </section>\n\n        <div class=\"mentions-holder\">\n            <div class=\"mentions\"></div>\n            <div class=\"mentions-summary-region\" style=\"display: none\"></div>\n        </div>\n\n    </div>\n\n    <div class=\"pages-region\"></div>\n\n    <div class=\"overlay\" style=\"display: none\"></div>\n</div>";
},"usePartial":true,"useData":true});