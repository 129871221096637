/**
 * Provides a picker for selecting location identifiers (eg ZA, western cape, cape town).
 * Each of the locations will be quoted by single quotes, and space separated, as so:
 *
 * 'ZA' 'NA' 'africa' 'ZA, western cape, cape town' 'HK, , hong kong'
 *
 * If you always need location information for an identifier / code, #lookup
 * is a good way to do this. If you're working with a country or group, this information
 * is always cached client side, and methods such as #getCountry or #identifierInCache
 * will always return a result. These are great to use when you want to avoid callbacks.
 *
 * Calling #lookup will cache the result returned from our servers. If you need to access them
 * again, you can use #identifierInCache, which returns the cached location value if it exists.
 */

import _ from 'underscore';
import {encloseInDisplayQuotes, removeQuotes, removeSingleQuotes, splitQuotedString} from "@/app/utils/StringUtils";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("LocationPicker").addInitializer(function(startupOptions) {

    var countryIdToLocation = startupOptions.locations.countryIdToLocation;
    var groupIdToLocation = startupOptions.locations.groupIdToLocation;
    var theWorld = {
        id: "world",
        group: "world",
        groupDisplay: "The World",
        continent: true,
        shapeFilePresent: true,
        value: "The World"
    };

    /**
     * This takes a string of the form 'ZA' 'AF' 'africa' and returns
     * a list of countries. This includes flattening out country groups.
     */
    this.quotedCountryStringToUnquoted = function(string) {
        var locations = splitQuotedString(string);

        return _(locations)
            .chain()
            .map(function(location) {
                location = removeSingleQuotes(location.trim());
                if (countryIdToLocation[location]) return location;
            })
            .flatten()
            .value()
            .join(' ');
    };

    var cacheGet = function(id) {
        var json = sessionStorage.getItem("countries:" + id);
        return json ? JSON.parse(json) : null;
    };

    var cachePut = function(id, location) {
        sessionStorage.setItem("countries:" + id, JSON.stringify(location));
    };

    /**
     * Returns a promise for looking up the location of an identifier.
     * This location may be cached in session storage. This will
     * return a location object, as used by the Beef location endpoints.
     * @deprecated
     */
    this.lookup = function(identifier) {
        if (identifier === "world") return Promise.resolve(theWorld);

        var value = groupIdToLocation[identifier] || countryIdToLocation[identifier] || cacheGet(identifier);
        if (value) return Promise.resolve(value);

        return fetch("/api/location/display?location=" + encodeURIComponent(identifier))
            .then(function(response) { return response.json() })
            .then(function(location) {
                if (location) cachePut(identifier, location);
                return location;
            });
    };

    /**
     * This looks up locations that are only in the cache.
     */
    this.lookupCacheOnly = function(identifier) {
        if (identifier === "world") return theWorld;
        return groupIdToLocation[identifier] || countryIdToLocation[identifier] || cacheGet(identifier) || null;
    };

    this.searchLocations = function(text) {
        return fetch("/api/location/search?q=" + encodeURIComponent(text))
            .then(function(response) { return response.json() })
    };

    /**
     * Returns html for a well formatted location object.
     */
    this.formatLocation = function(location, html) {
        if (arguments.length !== 2) html = true;

        var split = [];
        if (location.city) split.push(location.cityDisplay || location.city);
        if (location.region) split.push(location.regionDisplay || location.region);
        if (location.country) split.push(location.countryDisplay || location.country);
        if (!split.length) {
            split = location.value.split(',');
        }
        var text = split[0];

        if (split.length > 1) {
            var details = location.alternate ? encloseInDisplayQuotes(location.alternate) + ' ' : "";

            details += "(" + _(split.slice(1, 3)).collect(function(d) { return d.trim(); }).join(', ') + ")";
            if (html) {
                details = '<span class="location-details">' + details + "</span>";
            }

            text = split[0] + " " + details;
        }

        if (html) {
            var tooltip = Beef.LocationPicker.getLocationTooltip(location);
            text = '<span class="location-name" title="' + tooltip + '">' + text + "</span>";
        }

        return text;
    };

    this.getLocationTooltip = function(location) {
        var result = "";

        function regionText() {
            var regionNoun = "region";
            var verb = "in";
            switch (location.id.split(',')[0].trim()) {
                case 'ZA': regionNoun = "province"; verb = "of"; break;
                case 'US': regionNoun = "state"; verb = "in the"; break;
                case 'UK':
                case 'GB': verb = "of the"; break;
            }
            return regionNoun + " " + verb + " " + location.countryDisplay;
        }

        if (location.id == 'UN') {
            result = "The location is unknown";
        }
        else if (location.city) {
            result += location.cityDisplay;
            result += ", a city from ";
            if (location.region) result += "the " + location.regionDisplay + " " + regionText();
            else result += location.countryDisplay;
        }
        else if (location.region) {
            result += location.regionDisplay + ", a " + regionText();
        }
        else if (!location.continent && !location.countryGroup) {
            result += location.countryDisplay + ", a country";
        }
        else if (location.continent) {
            result += location.groupDisplay + ", a continent";
        }
        else if (location.countryGroup) {
            result += location.groupDisplay + ", a geographic region";
        }

        if (location.alternate) {
            result += ', also known as ' + location.alternate;
        }

        return result;
    };

    /**
     * Looks to see if the given identifier is already in the cache, and returns
     * the associated location for it. Very likely you want to be using the #lookup
     * function instead. #lookup is the function that adds items to the cache.
     */
    this.identifierInCache = function(identifier) {
        if (identifier === "world") return theWorld;
        return groupIdToLocation[identifier] || countryIdToLocation[identifier] || cacheGet(identifier);
    };

    /**
     * This returns text for a location. This is not asynchronous, and does not
     * make a call to the server. This means it will almost certainly not return what you
     * want for cities. See #lookUp
     */
    this.getLocationName = function(identifier, html) {
        var location = Beef.LocationPicker.identifierInCache(identifier);
        if (location) return Beef.LocationPicker.formatLocation(location, html);
        return identifier;
    };

    /**
     * Returns location information for a specific country (using their country code).
     */
    this.getCountry = function(code) {
        return countryIdToLocation[code];
    };

    /**
     * Given a country code, this will return a display name for the country, or it will
     * return the code.
     */
    this.getCountryName = function(code) {
        if (code) {
            var location = Beef.LocationPicker.getCountry(code);
            return location ? location.value : code;
        }
        return Beef.LocationPicker.getCountry("UN").value;
    };

    /**
     * Returns the group info for the code found in the groupIdToLocation map.
     */
    this.getGroup = function (code) {
        return groupIdToLocation[code];
    };

    var cacheLocation = function(location) {
        var countries = Beef.sessionData().get('countries') || {};
        countries[location.id] = location;
        Beef.sessionData().set('countries', countries);
    };

    var embedAndUpdateLocation = function(item, negative) {
        var id = "location-" + item.toLowerCase().replace(/[\s,']+/g, '');

        Beef.LocationPicker.lookup(item)
            .then(function (location) {
                $('.' + id).html((negative ? '-' : '') + Beef.LocationPicker.formatLocation(location));
            })
            .catch(console.warn);

        return '<span class="' + id + '">' + item + '</span>';
    };

    var items = _.throttle(function(q, view, callback, options) {
        if (!q || q.length <= 1) {
            callback({});
            return;
        }

        var idToValue = {};
        var countryCache = Beef.sessionData().get('countries') || {};

        function recordResult(location) {
            if (!options.topoJosnOnly || location.shapeFilePresent) {
                idToValue[location.id] = location.value;
                countryCache[location.id] = location;
            }
        }

        if (options.withWorld) recordResult(theWorld);

        if (options.onlyCountries) {
            _(_(countryIdToLocation).values()).each(recordResult);
            callback(idToValue);
            return;
        }

        if (options.onlyCountriesAndGroups) {
            _(_(countryIdToLocation).values()).each(recordResult);
            _(_(groupIdToLocation).values()).each(recordResult);
            callback(idToValue);
            return;
        }

        Beef.LocationPicker.searchLocations(q)
            .then(function (results) {
                var cities    = results.cities || [],
                    regions   = results.regions || [],
                    countries = results.countries || [],
                    groups    = results.groups || [];


                if (!options.noCities) _(cities).each(recordResult);
                _(regions).each(recordResult);
                _(countries).each(recordResult);
                if (!options.noGroups) _(groups).each(recordResult);

                Beef.sessionData().set('countries', countryCache);
                callback(idToValue);
            })
            .catch(console.error);
    }, 600);

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "location-picker auto-complete-picker" },
        template: require("@/dashboards/filter/pickers/location/LocationPicker.handlebars"),
        items: items,
        templateHelpers: function() {
            var found = this.model.get('found');
            var countries = [],
                geographicRegions = [],
                regions = [],
                cities = [],
                hasWorld = null;

            if (found) {
                for (var i = 0; i < found.length; i++) {
                    if (found[i].code === "world") {
                        hasWorld = theWorld;
                    } else if (countryIdToLocation[found[i].code]) {
                        countries.push(found[i]);
                    }
                    else if (groupIdToLocation[found[i].code]) {
                        geographicRegions.push(found[i]);
                    }
                    else if (found[i].code.split(',').length === 2) {
                        regions.push(found[i]);
                    }
                    else {
                        cities.push(found[i]);
                    }
                }
            }

            return {
                hasWorld: hasWorld,
                hasCountries: countries.length > 0,
                countries: countries,
                hasGeographicRegions: geographicRegions.length > 0,
                geographicRegions: geographicRegions,
                hasRegions: regions.length > 0,
                regions: regions,
                hasCities: cities.length > 0,
                cities: cities
            }
        }
    });

    function init(d) {
        d.group = groupIdToLocation[d.code];
        d.country = countryIdToLocation[d.code];
        d.split = d.code.split(',');
        d.initialised = true;
        d.regionLength = d.split.length === 2;
        d.location = Beef.LocationPicker.identifierInCache(d.code);
    }

    function sortFun(lhs, rhs) {
        if (!lhs.initialised) init(lhs);
        if (!rhs.initialised) init(rhs);

        if (lhs.group && !rhs.group) return -1;
        if (!lhs.group && rhs.group) return 1;

        if (lhs.country && !rhs.country) return -1;
        if (!lhs.country && rhs.country) return 1;

        if (lhs.regionLength && !rhs.regionLength) return -1;
        if (!lhs.regionLength && rhs.regionLength) return 1;

        return lhs.name.localeCompare(rhs.name);
    }

    //------------------------

    function ImportanceSorter() {
    }

    ImportanceSorter.prototype.sort = function(query, data) {
        var groups = [],
            countries = [],
            regions = [],
            cities = [];

        for (var i = 0; i < data.length; i++) {
            init(data[i]);
            switch (data[i].code.split(',').length) {
                case 1:
                    if (data[i].code.length === 2) countries.push(data[i]);
                    else groups.push(data[i]);
                    break;
                case 2: regions.push(data[i]); break;
                default: cities.push(data[i]);
            }
        }

        var beginPattern = new RegExp("^" + query.toLowerCase(), "i");

        function sort(lhs, rhs) {
            if (lhs.location) {
                if (lhs.location.alternate && beginPattern.test(lhs.location.alternate)) return -1;
                if (lhs.location.city && beginPattern.test(lhs.location.city)) return -1;
                if (lhs.location.cityDisplay && beginPattern.test(lhs.location.cityDisplay)) return -1;
                if (lhs.location.region && beginPattern.test(lhs.location.region)) return -1;
                if (lhs.location.regionDisplay && beginPattern.test(lhs.location.regionDisplay)) return -1;
                if (lhs.location.country && beginPattern.test(lhs.location.country)) return -1;
                if (lhs.location.countryDisplay && beginPattern.test(lhs.location.countryDisplay)) return -1;
            }
            if (rhs.location) {
                if (rhs.location.alternate && beginPattern.test(rhs.location.alternate)) return 1;
                if (rhs.location.city && beginPattern.test(rhs.location.city)) return 1;
                if (rhs.location.cityDisplay && beginPattern.test(rhs.location.cityDisplay)) return 1;
                if (rhs.location.region && beginPattern.test(rhs.location.region)) return 1;
                if (rhs.location.regionDisplay && beginPattern.test(rhs.location.regionDisplay)) return 1;
                if (rhs.location.country && beginPattern.test(rhs.location.country)) return 1;
                if (rhs.location.countryDisplay && beginPattern.test(rhs.location.countryDisplay)) return 1;
            }
            return lhs.name.localeCompare(rhs.name);
        }

        groups.sort(sort);
        countries.sort(sort);
        regions.sort(sort);
        cities.sort(sort);

        var size = 4;
        var results = _(groups)
            .take(size)
            .concat(_(countries).take(size))
            .concat(_(regions).take(size))
            .concat(_(cities).take(size));
        results = results
            .concat(_(groups).drop(size))
            .concat(_(countries).drop(size))
            .concat(_(regions).drop(size))
            .concat(_(cities).drop(size));

        return results;
    };

    this.createConverter = function(placeholder, splitter) {
        return createTagConverter({
            items: function(code) { return removeQuotes(code, "'"); },
            placeholder: placeholder,
            splitter: splitter,
            nameFormatter: function(item, negative) {
                var location = Beef.LocationPicker.identifierInCache(item);

                if (location) {
                    return (negative ? '-' : '') +  Beef.LocationPicker.formatLocation(location);
                }

                return embedAndUpdateLocation(item, negative);
            }
        });
    };

    this.converter = this.createConverter("Start typing the location here");

    /**
     * Attach a location picker to a view attribute identified by selector. Updates attribute in the view's model.
     * Supported options include:
     * <ul>
     *     <li> onlyCountries - only allows the selection of countries: no groups, cities, or regions.
     *     <li> onlyCountriesAndGroups - allows the selection of countries and country groups.
     *     <li> noGroups - does not provide options for continents or groups.
     *     <li> noCities - does not provide options for cities.
     *     <li> topoJosnOnly - only returns locations which have shape files.
     *     <li> withWorld - allows the user to select 'The World', with id 'world'.
     * </ul>
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, Object.assign(
            {
                sortFun: sortFun,
                importanceSorter: ImportanceSorter,
                quoteCodes: "'"
            }, options || {}));
    };

});