import {toGrouseLink} from "@/data/Grouse";
import {features, isDebugModeEnabled} from "@/app/Features";


Beef.module("MentionList").addInitializer(function(_startupOptions) {

    const thisModule = this;

    this.getMentionQueryString = function(include, filter, offset, limit, orderby, withContent) {
        let s = "filter=" + encodeURIComponent(filter);
        if (offset) s += "&offset=" + offset;
        if (limit) s += "&limit=" + limit;
        if (isDebugModeEnabled()) s += "&debug=true";
        if (orderby) {
            orderby = orderby.replace("ots", "OTS")
                .replace("ave", "AVE")
                .replace("resharecount", "reshareCount")
                .replace("replycount", "replyCount")
                .replace("responsetime", "responseTime");
            s += "&orderBy=" + encodeURIComponent(orderby);
        }
        s += "&select=" + encodeURIComponent("+combinedHtml,phrases,brand,discardedByRule,region," +
            "crowdJobs,reshareOfId,replyToId,interactionId,interactionResponseTime,interactionHasResponse");
        if (withContent) s += ",contentHtml";
        return s;
    };

    this.Collection = Backbone.Collection.extend({
        model: Beef.MentionItem.Model,

        initialize: function(models, options) {
            this.accountCode = options.accountCode;
            this.paginationModel = options.paginationModel;
            this.parentModel = options.parentModel;
            this.statsModel = options.statsModel;
            this.url = this.urlRoot = toGrouseLink('/v4/accounts/' + options.accountCode + '/mentions', null, true);
        },

        fetch: function(filter, offset, limit, orderby, includeStats, includeTotal, options) {
            const qs = thisModule.getMentionQueryString(null, filter, offset, limit, orderby, includeStats);
            this.url = this.urlRoot + "?" + qs;
            options = options || {};
            options.urlRoot = this.urlRoot;
            Backbone.Collection.prototype.fetch.call(this, options);
        },

        parse: function(response) {
            // if (response.total !== undefined) {
            //     this.total = response.total;
            //     this.paginationModel.set('total', this.total);
            //     this.parentModel.set('total', this.total);
            // }
            // if (this.statsModel && response.stats !== undefined) this.statsModel.set(response.stats);
            const data = response;
            for (let i = 0; i < data.length; i++) data[i]._accountCode = this.accountCode;
            return data;
        }
    });

    this.View = Backbone.Marionette.CompositeView.extend({
        template: require("@/mentions/MentionList.handlebars"),

        itemView: Beef.MentionItem.View,

        attributes: { class: 'row-fluid' },

        events: {
            "click .selected-icon": "selectMention",
            "click .mention-item__selection-button": "selectMention"
        },

        itemViewOptions: function() {
            return {
                noSelect: this.options.noSelect,
                showSingleMention: this.options.showSingleMention,
                cache: this.cache
            }
        },

        onAfterItemAdded: function(view) {
            view.model.view = view;
            view.model.filter = this.model.get('filter');
        },

        selectMention: function(ev) {
            const $m = $(ev.target).closest(".mention-item");
            const id = $m.attr('data-id');

            const allSelected = this.collection.parentModel.get('allSelected');
            const selected = this.collection.parentModel.get('selected');
            const unselected = this.collection.parentModel.get('unselected');

            if($m.hasClass('selected')) {
                $m.toggleClass('selected', false);
                if (!allSelected) delete selected[id];
                else unselected[id] = id;
            } else {
                $m.toggleClass('selected', true);
                if (allSelected) delete unselected[id];
                else selected[id] = id;
            }
            this.collection.parentModel.trigger('change:selected');
            this.collection.parentModel.set({selected: selected, unselected: unselected})
        }
    });

    /**
     * Use this to navigate to the mentions panel using a particular filter. The filter will be url encoded, you
     * you do not need to worry about this.
     * @param newTab An optional argument. Set to true if this should be opened in a new tab.
     */
    this.navigateToMentions = function(code, filter, orderby, newTab) {
        const href = this.buildMentionsHref(code, filter, orderby);
        if (newTab) window.open(href);
        else Beef.router.navigate(href.substring(1), {trigger: true});
    };

    /**
     * Build an absolute href to navigate to the mentions panel using a particular filter.
     */
    this.buildMentionsHref = function(code, filter, orderby) {
        filter = encodeURIComponent(filter);
        if (orderby) orderby = encodeURIComponent(orderby);
        return "/" + code + "/mentions?filter=" + filter + (orderby ? "&orderby=" + orderby : "");
    };
});