import _ from 'underscore';
import {isString} from "@/app/utils/StringUtils";
import {isFunction, isObject} from "@/app/utils/Util";

/**
 * Overrides Backbone.sync to add our Authentication parameter to all requests to Mash and Grouse. Call toMashUrl
 * and toGrouseUrl to convert relative URL's for the Mash and Grouse API into actual URLs for Backbone models.
 */
Beef.module("Sync").addInitializer(function(startupOptions) {

    var thisModule = this;

    var authorization = startupOptions.authorization;
    var mashApi = startupOptions.mashApi;
    var fondueApi = startupOptions.fondueApi;
    var broccoliApi = startupOptions.broccoliApi;

    /** Convert a relative url for Mash into an actual URL. */
    this.toMashUrl = function(url, includeAuth) {
        var u = mashApi + (url.charAt(0) == '/' ? url.substring(1) : url);
        if (includeAuth) u = u + (u.indexOf('?') == -1 ? "?" : "&") + "Authorization=" + authorization;
        return u;
    };

    /** Convert a relative url for Broccoli into an actual URL. */
    this.toBroccoliUrl = function(url, includeAuth) {
        var u = broccoliApi + (url.charAt(0) == '/' ? url.substring(1) : url);
        if (includeAuth) u = u + (u.indexOf('?') == -1 ? "?" : "&") + "Authorization=" + authorization;
        return u;
    };

    this.toFondueUrl = function(url) {
        return fondueApi + (url.charAt(0) == '/' ? url.substring(1) : url);
    };

    // Cut and past from backbone.js: Get a value from a Backbone object as a property or as a function.
    var getValue = function(object, prop) {
        if (!(object && object[prop])) return null;
        return isFunction(object[prop]) ? object[prop]() : object[prop];
    };

    var methodMap = {
        'create': 'POST',
        'update': 'PUT',
        'delete': 'DELETE',
        'read':   'GET'
    };

    var urlError = function() {
        throw new Error('A "url" property or function must be specified');
    };

    Backbone.sync = function(method, model, options) {
        var type = methodMap[method];

        options || (options = {});

        var params = {type: type, dataType: 'json', headers: { Authorization: authorization }};

        if (options.url) params.url = options.url;
        else params.url = getValue(model, 'url') || urlError();

        if (method == 'create' || method == 'update') params.contentType = 'application/json';

        if (!options.data && model && (method == 'create' || method == 'update')) {
            params.data = JSON.stringify(Beef.Sync.cloneModel(model.toJSON()));
        }

        if (type !== 'GET') params.processData = false;

        // Bump up a syncBusy counter on the model and dec it when the call is done. This can be used to discover
        // in a change event handler that the change is due to a sync and not user input.
        if (model.syncBusy) model.syncBusy++;
        else model.syncBusy = 1;
        var success = options.success;
        var error = options.error;
        options.success = function(resp, status, xhr) {
            try {
                if (success) success(resp, status, xhr);
            } finally {
                model.syncBusy--;
            }
        };
        options.error = function(model, resp) {
            try {
                if (error) error(model, resp);
            } finally {
                model.syncBusy--;
            }
        };

        return $.ajax(Object.assign(params, options));
    };

    /**
     * Deep-clone attrs. You can supply an optional attrFilter function that accepts the name of each attribute
     * found and returns true if it should be included. The default filter excludes fields starting with underscore.
     */
    this.cloneModel = function(attrs, attrFilter) {
        if (!attrFilter) attrFilter = thisModule.defaultAttrFilter;
        return prepareDataImpl(attrs, attrFilter);
    };

    this.defaultAttrFilter = function(name) {
        return name.charAt(0) != '_';
    };

    var prepareDataImpl = function(attrs, attrFilter) {
        var i, ans;
        if (!attrs) return attrs;
        if (isFunction(attrs.toJSON)) attrs = attrs.toJSON();
        if (Array.isArray(attrs)) {
            ans = [];
            for (i = 0; i < attrs.length; i++) {
                ans.push(prepareDataImpl(attrs[i], attrFilter));
            }
            return ans;
        } else if (isObject(attrs)) {
            ans = {};
            for (i in attrs) {
                if (attrFilter(i)) ans[i] = prepareDataImpl(attrs[i], attrFilter);
            }
            return ans;
        } else {
            return attrs;
        }
    };

    /** PUT JSON data to mash. */
    this.mashPUT = function(endpoint, data, success) {
        return this.mashCall("PUT", endpoint, data, success);
    };

    /** POST JSON data to mash. */
    this.mashPOST = function(endpoint, data, success) {
        return this.mashCall("POST", endpoint, data, success);
    };

    this.mashCall = function(type, endpoint, data, success) {
        if (!isString(data)) data = JSON.stringify(data);
        return $.ajax({
            type: type,
            url: this.toMashUrl(endpoint),
            headers: { Authorization: authorization },
            contentType: "application/json",
            data: data,
            success: success
        });
    };

    /**
     * Get some JSON data from Mash.
     */
    this.mashGET = function(endpoint, data, callback, error) {
        if (mashApi.endsWith('/') && endpoint.startsWith('/')) endpoint = endpoint.slice(1);
        return $.ajax({
            url: mashApi + endpoint,
            data: data,
            success: callback,
            error: error,
            dataType: 'json',
            headers: { Authorization: authorization }
        });
    };

    /**
     * Get some JSON data from Beef.
     * @deprecated. Use the beef axios object
     */
    this.beefGET = function(endpoint, data, callback) {
        return $.ajax({
            dataType: "json",
            url: endpoint,
            data: data,
            success: callback,
            headers: { Authorization: authorization }
        });
    };

    /**
     * Get PUT some JSON data to Beef and expect a JSON reply.
     */
    this.beefPUT = function(endpoint, data, callback) {
        if (!isString(data)) data = JSON.stringify(data);
        return $.ajax({
            type: "PUT",
            url: endpoint,
            headers: { Authorization: authorization },
            contentType: "application/json",
            data: data,
            success: callback
        });
    };
    
    this.beefPOST = function (endpoint, data, success) {
        if (!isString(data)) data = JSON.stringify(data);
        return $.ajax({
            type: "POST",
            url: endpoint,
            headers: { Authorization: authorization },
            contentType: "application/json",
            data: data,
            success: success
        });
    };

    this.broccoliCall = function(type, endpoint, data, success, error) {
        if (!isString(data)) data = JSON.stringify(data);
        return $.ajax({
            type: type,
            url: Beef.Sync.toBroccoliUrl(endpoint),
            headers: { Authorization: authorization },
            contentType: "application/json",
            data: data,
            success: success,
            error: error
        });
    };

    /**
     * Get some JSON data from Broccoli.
     */
    this.broccoliGET = function(endpoint, data, success, error) {
        return $.ajax({
            url: Beef.Sync.toBroccoliUrl(endpoint),
            data: data,
            success: success,
            error: error,
            dataType: "json",
            headers: { Authorization: authorization }
        });
    };

    /**
     * POST JSON data to Broccoli.
     */
    this.broccoliPOST = function(endpoint, data, success, error) {
        return this.broccoliCall("POST", endpoint, data, success, error);
    };

    /** PUT JSON data to Broccoli. */
    this.broccoliPUT = function(endpoint, data, success, error) {
        return this.broccoliCall("PUT", endpoint, data, success, error);
    };

    /** DELETE a resource in Broccoli. */
    this.broccoliDELETE = function(endpoint, success, error) {
        return this.broccoliCall("DELETE", endpoint, null, success, error);
    };

});
