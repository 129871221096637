import beefRenderComparePredictedD3 from './ComparePredictedD3'
import {currentAccountCode} from "@/app/utils/Account";

Beef.module("Mentions.ComparePredicted").addInitializer(function() {

    this.showInPopup = function(accountCode, sel) {
        if (!sel.total) {
            window.alert("Please select some mentions to send");
            return;
        }

        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
        popup.setTarget($("body"));

        var m = new Backbone.Model({ code: accountCode, sel: sel});
        var view = new View({model: m});
        view.on("close", function(){ popup.hide(); });
        popup.show(view);
    };

    function toRows(confusionMatrix) {
        var toIndex = {
            "negative": 0, "neutral": 1, "positive": 2
        };
        var rows = [[], [], [], []];
        for (var i = 0; i < 4; ++i) {
            rows[i] = [0, 0, 0, 0];
        }
        confusionMatrix.forEach(function(cell) {
            var i = toIndex[cell["true_label"]];
            var j = toIndex[cell["predicted_label"]];
            var count = cell.count ? cell.count : 0;
            rows[i][j] = count;
            rows[i][3] += count;
            rows[3][j] += count;
            rows[3][3] += count;
        });

        return [
            {
                label: "negative", cells: rows[0]
            },
            {
                label: "neutral", cells: rows[1]
            },
            {
                label: "positive", cells: rows[2]
            },
            {
                label: "total", cells: rows[3]
            }
        ]
    }

    var View = Backbone.Marionette.Layout.extend({

        template: require("@/mentions/compare-predicted/ComparePredicted.handlebars"),

        attributes: { class: "compare-predicted dialog" },

        initialize: function() {
            this.model.set({
                labels: ["negative", "neutral", "positive", "total"]
            }, {silent: true});
            this.loadData();
        },

        loadData: function() {
            var that = this;
            this.model.set({
                _busy: this.model.set({"_busy": true}, {silent: true})
            }, { silent: true });
            var callback = function(data) {
                that.model.set({
                    test: data.test,
                    rows: toRows(data.test["confusion_matrix"]),
                    _busy: false
                });
                var svgs = d3.select(that.$el[0]).selectAll("svg")[0];
                beefRenderComparePredictedD3(data, svgs[2], svgs[3], svgs[1], svgs[0]);
            };
            Beef.Sync.beefGET("/api/" + currentAccountCode() + "/sentiment/compare-predicted", {
                filter: this.model.attributes.sel.filter.toString()
            }, callback);
        },

        events: {
            "click .dialog-title .close":   "close",
            "click .ok":                    "close"
        },

        modelEvents: {
            "change": "render"
        },

        close: function() {
            if (this.popup) {
                this.popup.close();
            }
            // already closed
            if (!this.$el[0].parentElement) {
                return;
            }
            Backbone.Marionette.Layout.prototype.close.call(this, arguments);
        }
    });
});
