var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <section class=\"markdown-editor--controls markdown-editor--controls__group\">\n            <button class=\"summary btn\" title=\"Insert a summary of the mentions selected by your filter\">Summary</button>\n        </section>\n        <section class=\"markdown-editor--controls markdown-editor--controls__group\">\n            <button class=\"static btn\" title=\"Insert text and logos\">Etc</button>\n            <button class=\"count btn\" title=\"Count mention data\">Count</button>\n            <button class=\"top btn\" title=\"Find the category with the most mentions, like the country or language with the most mentions\">Top</button>\n        </section>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<section>\n    <p>\n        Type in your text above or use <a href=\"https://www.markdownguide.org/\" target=\"_blank\">markdown</a>\n        to include formatting.\n\n        Replace all text with the\n        <a class=\"cheat\" href=\"#\"\n           title=\"This cookbook shows examples of various things that the comment box can do, like counting mentions and showing mentions\">\n            Comment Cookbook\n        </a>.\n        Or try one of <a class=\"statistics\" href=\"#\">statistics setups</a>.\n    </p>\n</section>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<textarea class=\"text dark-scrollbars dark-scrollbars--visible\"\n          placeholder=\"Enter your text here...\"\n          name=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"field") || (depth0 != null ? lookupProperty(depth0,"field") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"field","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":25}}}) : helper)))
    + "\">\n</textarea>\n<section class=\"markdown-editor--controls\">\n    <section class=\"markdown-editor--controls markdown-editor--controls__group\">\n        <button class=\"header btn\" data-level=\"1\" title=\"Insert a top level header (ctrl-shift-1)\">H1</button>\n        <button class=\"header btn\" data-level=\"2\" title=\"Insert a sub-heading (ctrl-shift-2)\">H2</button>\n        <button class=\"header btn\" data-level=\"3\" title=\"Insert a heading (ctrl-shift-3)\">H3</button>\n    </section>\n\n    <section class=\"markdown-editor--controls markdown-editor--controls__group\">\n        <button class=\"style btn\" data-style=\"_\" title=\"Insert italics (Ctrl+I)\"><strong><i class=\"icon-italic\"></i></strong></button>\n        <button class=\"style btn\" data-style=\"**\" title=\"Bolden your text (Ctrl+B)\"><i class=\"icon-bold\"></i></button>\n    </section>\n\n    <section class=\"markdown-editor--controls markdown-editor--controls__group\">\n        <button class=\"bullet btn\" data-bullet=\"-\" title=\"Insert a bulleted list (ctrl-shift-8)\"><i class=\"icon-list-bullet\"></i></button>\n        <button class=\"bullet btn\" data-bullet=\"1.\" title=\"Insert a numbered list (ctrl-shift-7)\"><i class=\"icon-list-numbered\"></i></button>\n    </section>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"simple") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "\n    <button class=\"undo btn\" title=\"Undo all changes\"><i class=\"icon-undo\"></i></button>\n</section>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"simple") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":0},"end":{"line":50,"column":11}}})) != null ? stack1 : "");
},"useData":true});