<template>
    <a v-if="isEntity" :href="url" @click.stop.prevent="fireEvent">{{text}}</a>
    <sup class="deq-reset summary__citation" v-else-if="isMention"><a :href="url" @click.stop.prevent="fireEvent">{{text}}</a></sup>
    <span v-else class="summary__text">{{text}}</span>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {currentAccountCode} from "@/app/utils/Account";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {showEditMentionDialog, showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";

export default {
    props: {
        entity: Object
    },

    computed: {
        ...mapGetters('mentionPanel', ['filter']),

        isEntity() {
            return this.entity.entity && !this.isMention;
        },

        isMention() {
            return !!this.entity.mention;
        },

        text() {
            return this.entity.text;
        },

        url() {
            if (this.isMention) {
                return `/${currentAccountCode()}/mentions/${this.entity.mention}`
            }

            return `/${currentAccountCode()}/mentions?filter=` + encodeURIComponent(appendFiltersReadably(this.filter, this.filterPortion));
        },

        filterPortion() {
            if (this.isMention) {
                return `id is '${this.entity.mention}'`;
            }

            return `content matches '"${this.text}"'`
        }
    },

    methods: {
        ...mapActions('mentionPanel', ['addFilterAddendum']),

        fireEvent() {
            if (this.isMention) {
                showEditMentionDialog(this.entity.mention);
                return;
            }
            this.addFilterAddendum({filter: this.filterPortion, label: `Content: '${this.text}'`});
        },
    }
}
</script>

<style scoped lang="sass">
.summary__citation
    padding-left: 0.3ch

.summary__citation + .summary__citation:before
    content: ', '
    padding-left: 0

.summary__text
    white-space: pre-wrap

</style>