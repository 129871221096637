import beefRenderTopicWheel from '../../dashboards/widgets/topics/TopicWheelD3'
import {deprecatedMashGet} from "@/app/Mash";
import _ from 'underscore';

Beef.module("SelectTopicTree").addInitializer(function(startupOptions) {

    /**
     * The onChange callback is passed a tree object { id, label, description } or null if none is selected.
     */
    this.createView = function(treeId, editable, onChange) {
        var model = new Backbone.Model({treeId: treeId, list: null, tree: null, editable: editable});
        var view = new View({model: model});
        model.on("change:treeId", function(){
            if (onChange) onChange(model.get('tree'));
        });
        if (editable) {
            deprecatedMashGet('topics/trees/list', null, function(data) { model.set("list", data) });
        }
        return view;
    };

    var View = Backbone.Marionette.ItemView.extend({
        template: require("@/setup/brands/SelectTopicTree.handlebars"),

        attributes: { class: "select-topic-tree" },

        modelEvents: {
            "change:list": "render",
            "change:treeId": "fetchTopicTreeWheel"
        },

        events: {
            "change .topic-tree-select": 'treeSelected'
        },

        templateHelpers: {
        },

        onRender: function() {
            this.fetchTopicTreeWheel();
        },

        treeSelected: function(ev) {
            var val = this.$(ev.target).val();
            var treeId = val ? parseInt(val) : null;
            var tree = null;
            if (treeId) tree = this.model.get("list").find(function(t) { return t.id === treeId });
            this.model.set('tree', tree);
            this.model.set('treeId', treeId);
        },

        fetchTopicTreeWheel: function() {
            var $tw = this.$('.topic-wheel');
            var treeId = this.model.get("treeId");
            if (treeId) {
                if (treeId === this._wheelTreeId) return;
                $tw.html('<span class="spinner"></span>');
                this._wheelTreeId = treeId;
                var that = this;
                deprecatedMashGet('topics/tree/' + treeId, null, function(tree) {
                    if (that._wheelTreeId !== treeId) return;   // tree changed since fetch was started
                    that._wheelTags = _.map(tree.categories, function(c){
                        return {tag: {id: c.id, name: c.name, description: c.description, children: c.children},
                            size: 1, actualSize: 1,
                            children: _.map(c.topics, function(o){
                                return {tag: {id: c.id + "_" + o.id, itemId: o.id, name: o.name, description: o.description},
                                    size: 1, actualSize: 1}
                            })
                        };
                    });
                    that.renderTopicTreeWheel();
                });
            } else {
                this._wheelTreeId = treeId;
                this._wheelTags = null;
                this.renderTopicTreeWheel();
            }
        },

        renderTopicTreeWheel: function() {
            var $tw = this.$('.topic-wheel');
            $tw.text('');
            if (this._wheelTags) {
                var options = { owner: this, hideLegend: true, v3: true };
                beefRenderTopicWheel($tw[0], 600, 600, this._wheelTags, options);
            }
        }
    });
});

