<template>
    <div class="twitter-select-profile-type">
        <h4>Select the type of <i class="symbol-twitter"/> X profile you would like to add</h4>

        <div class="twitter-select-profile-type__profile-type-container">
            <div class="btn twitter-select-profile-type__profile-btn" @click="selectProfileType(addProfileTypes.twitter.PUBLIC)">
                <i class="icon-users"></i>
                Unauthorised Profile
            </div>
            <p class="twitter-select-profile-type__profile-description">
                Add an unauthorised X profile to the account. This will allow you to track public data.
            </p>
        </div>

        <div class="twitter-select-profile-type__divider"></div>

        <div class="twitter-select-profile-type__profile-type-container">
            <div class="btn twitter-select-profile-type__profile-btn" @click="selectProfileType(addProfileTypes.twitter.MANAGED)">
                <i class="icon-lock-1"></i>
                Managed Profile
            </div>
            <p class="twitter-select-profile-type__profile-description">
                Add X profiles that you have authorised. Adding an authorised profile to an account will allow you to reply to mentions from the app.
                You can also choose to see direct messages sent to or from the profile in your DataEQ account.
            </p>
        </div>
    </div>
</template>

<script>
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";

export default {
    name: "TwitterSelectProfileType",
    mixins: [addProfileMixins],

    methods: {
        selectProfileType(profileType) {
            this.$emit('select-profile', profileType);
        }
    }
}
</script>

<style scoped lang="sass">

.twitter-select-profile-type

    p
        color: var(--be-colour-text-dark)

    h4
        margin-bottom: 40px

    &__profile-type-container
        display: flex
        align-items: center

    &__profile-btn
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        border-radius: 6px
        width: 150px
        height: 100px
        padding: 5px

        i
            font-size: 2em
            margin: 5px 0


    &__profile-description
        margin-left: 20px
        width: 75%

    &__divider
        margin: 15px 0
        width: 100%
        border-bottom: 1px solid #666

</style>