/**
 * Drill down by adding/removing countries to the filter.
 */
Beef.module("Widget.CountrySelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Countries",
        description:    "Interactively filter by country",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.CountSelector.View.extend({

        filterAttribute: "country",

        mapData: function(d) {
            if (d.country) return { id: d.country.id, name: d.country.name, count: d.mentionCount };
            return { id: "UN", name: "Unknown", count: d.mentionCount }
        }
    });
});