/**
 * View that displays an error page. Model { status, message, detail }.
 */
import {setTitle} from "@/app/Beef";

Beef.module("ErrorPage", function(ErrorPage, Beef) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require('@/app/ErrorPage.handlebars'),
        modelEvents: { "change": "render" },
        onShow: function() {
            var title = this.model.get('status');
            setTitle("Error" + (title ? " " + title : ""));
        }
    });

});
