/**
 * Drill down by adding/removing category (media) types to the filter.
 */

import {MEDIA_CATEGORIES} from "@/app/utils/MediaLinks";

Beef.module("Widget.CategorySelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Categories",
        description:    "Interactively filter by category",
        width:          1,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        filterAttribute: "media",

        getTooltipText: function(item) {
            var lower = item.name.toLowerCase();
            if (lower == 'unknown') return "Click to select mentions with unknown category";
            return "Click to select " + lower + " mentions";
        },

        refresh: function() {
            var items = Object.entries(MEDIA_CATEGORIES).map(function([key, value]) {
                return { id: key, name: value }
            });
            this.text(items);
        }
    });
});