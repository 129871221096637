<template>
    <add-profile-dialog ref="addProfileDialog"
                        :profile-type="profileTypes.twitter"
                        :enable-next-btn="enableNext"
                        :next-btn-text="nextBtnText"
                        :show-progress-bar="!!selectedProfileType"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-prev-btn="!saving && !!selectedProfileType"
                        :show-next-btn="!saving && !!selectedProfileType"
                        @close="$emit('close')"
                        @prev="previous"
                        @next="next">
        <template #workflow>
            <keep-alive>
                <twitter-select-profile-type v-if="!saving && !selectedProfileType" @select-profile="profileTypeSelected"></twitter-select-profile-type>

                <!--PUBLIC WORKFLOW-->
                <search-profile :profile-type="profileTypes.twitter" v-if="!saving && selectedProfileType === addProfileTypes.twitter.PUBLIC && curStage === addProfileStages.twitter.public.SEARCH_PROFILE">
                    <template #description>
                        <p>Adding an unauthorised X profile will allow you to track public posts on X.</p>
                    </template>
                </search-profile>

                <edit-public-profile v-if="!saving && selectedProfileType === addProfileTypes.twitter.PUBLIC && curStage === addProfileStages.twitter.public.EDIT_PROFILE"/>
                <!--PUBLIC WORKFLOW-->

                <!--MANAGED WORKFLOW-->
                <select-managed-profiles :profile-type="profileTypes.twitter" v-if="!saving && selectedProfileType === addProfileTypes.twitter.MANAGED && curStage === addProfileStages.twitter.managed.SELECT_PROFILES"/>
                <!--MANAGED WORKFLOW-->

                <saving-item v-if="saving" :saving-message="saveMessage" :save-complete="saveComplete" :save-complete-message="saveCompleteMessage" :save-error="saveError" :save-error-message="saveErrorMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more <i class="symbol-twitter"></i> X profiles</h4>
                    </template>
                </saving-item>
            </keep-alive>
        </template>
    </add-profile-dialog>
</template>

<script>
import SavingItem from "@/setup/SavingItem";
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import { addProfileMixins } from "@/setup/profiles/add-profile/AddProfileMixins";
import EditPublicProfile from "@/setup/profiles/add-profile/EditPublicProfile";
import SearchProfile from "@/setup/profiles/add-profile/SearchProfile";
import SelectManagedProfiles from "@/setup/profiles/add-profile/SelectManagedProfiles";
import TwitterSelectProfileType from "@/setup/profiles/add-profile/twitter/TwitterSelectProfileType";
import VuexStore from "@/store/vuex/VuexStore";


export default {
    name: "TwitterAddProfileDialog",
    store: VuexStore,
    components: {
        SavingItem,
        AddProfileDialog,
        SelectManagedProfiles,
        EditPublicProfile, SearchProfile, TwitterSelectProfileType},
    mixins: [addProfileMixins],

    data: function () {
        return {
            curStage: 1,
            totalStages: 1,
            selectedProfileType: null,
        }
    },

    computed: {
        enableNext() {
            switch (this.selectedProfileType) {
                case this.addProfileTypes.twitter.PUBLIC:
                    // If we are on the edit stage, we only allow saving if the profile has a category set
                    if (this.curStage === this.addProfileStages.twitter.public.EDIT_PROFILE) {
                        return !!this.newProfileData.publicProfile?.media
                    }
                    // If we are on the search stage, we only enable next if the profile is not currently in the account (check "existing" field)
                    if (this.curStage === this.addProfileStages.twitter.public.SEARCH_PROFILE) {
                        return !this.newProfileData.publicProfile?.existing && !!this.newProfileData.publicProfile;
                    }
                    return !!this.newProfileData.publicProfile;
                case this.addProfileTypes.twitter.MANAGED:
                    return !!this.newProfileData.managedProfiles.profiles?.length;
                default:
                    return false;
            }
        },

        nextBtnText() {
            // if we are on the final stage, show "Save" instead of "Next"
            return this.curStage === this.totalStages - 1 ? "Save" : "Next";
        }
    },

    methods: {
        next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                switch (this.selectedProfileType) {
                    case this.addProfileTypes.twitter.PUBLIC:
                        this.savePublicProfile();
                        break;
                    case this.addProfileTypes.twitter.MANAGED:
                        this.saveManagedProfiles(this.profileTypes.twitter);
                        break;
                }
            }
        },

        previous() {
            this.curStage--;

            if (this.curStage === 0) {
                this.curStage = 1;
                this.selectedProfileType = null;
            }
        },

        profileTypeSelected(profileType) {
            this.$refs.addProfileDialog.setTransitionToNext();
            this.selectedProfileType = profileType;

            switch(profileType) {
                case this.addProfileTypes.twitter.PUBLIC:
                    this.totalStages = Object.keys(this.addProfileStages.twitter.public).length;
                    break;
                case this.addProfileTypes.twitter.MANAGED:
                    this.totalStages = Object.keys(this.addProfileStages.twitter.managed).length;
                    break;
            }
        },

        reset() {
            this.curStage = 1;
            this.selectedProfileType = null;
            this.saveComplete = false;
            this.saving = false;
            this.clearNewProfiles();
        }
    }
}
</script>

<style scoped lang="sass">

</style>