<template>
    <sidebar-menu v-if="brands.length" class="sidebar-menu">

        <template v-if="selectionBrands">
            <sidebar-header>
                <h4>Brand selection</h4>
            </sidebar-header>

            <sidebar-menu-item v-for="brand in selectionBrands"
                               :key="brand.id"
                               class="sidebar__item"
                               :active="brand.id === activeBrandId"
                               :disabled="disabled"
                               @click="select(brand)">

                <span :data-indent="getIndent(brand)">
                    {{formatBrandName(brand)}}
                </span>

                <tooltip-component :positions="['right']" v-if="getChildren(brand)">
                    <button class="sidebar__caret" @click.stop.prevent="toggleExpand(brand)">
                        <collapsable-caret :collapsed="!isExpanded(brand)"/>
                    </button>

                    <template #tooltip v-if="!isExpanded(brand)">
                        Click to see this brand's children
                    </template>
                    <template #tooltip v-else>
                        Click to hide this brand's children
                    </template>
                </tooltip-component>

            </sidebar-menu-item>
        </template>

        <sidebar-header v-if="ownBrands.length"
                        tooltip="These are your own brands, the primary brands that you are tracking">
            <h4>Own brands</h4>
        </sidebar-header>

        <sidebar-menu-item v-for="brand in ownBrands"
                           :key="brand.id"
                           class="sidebar__item"
                           :active="brand.id === activeBrandId"
                           :disabled="disabled"
                           @click="select(brand)">

            <span :data-indent="getIndent(brand)">
                {{formatBrandName(brand)}}
            </span>

            <tooltip-component :positions="['right']" v-if="getChildren(brand)">
                <button class="sidebar__caret" @click.stop.prevent="toggleExpand(brand)">
                    <collapsable-caret :collapsed="!isExpanded(brand)"/>
                </button>

                <template #tooltip v-if="!isExpanded(brand)">
                    Click to see this brand's children
                </template>
                <template #tooltip v-else>
                    Click to hide this brand's children
                </template>
            </tooltip-component>
        </sidebar-menu-item>

        <sidebar-header v-if="competitorBrands.length"
                        tooltip="These brands are competitors of your own">
            <h4>Competitor brands</h4>
        </sidebar-header>
        <sidebar-menu-item v-for="brand in competitorBrands"
                           :key="brand.id"
                           class="sidebar__item"
                           :active="brand.id === activeBrandId"
                           :disabled="disabled"
                           @click="select(brand)">

            <span :data-indent="getIndent(brand)">
                {{formatBrandName(brand)}}
            </span>

            <tooltip-component :positions="['right']" v-if="getChildren(brand)">
                <button class="sidebar__caret" @click.stop.prevent="toggleExpand(brand)">
                    <collapsable-caret :collapsed="!isExpanded(brand)"/>
                </button>

                <template #tooltip v-if="!isExpanded(brand)">
                    Click to see this brand's children
                </template>
                <template #tooltip v-else>
                    Click to hide this brand's children
                </template>
            </tooltip-component>

        </sidebar-menu-item>

        <!--
        OTHER BRANDS

        This may be titled as just Brands, if there are no owned brands and competitors.
        If there are Owned brands, etc, this is collapsable, since it contains brands
        of lower interest than owned and competitor brands. If there are many other brands,
        like in the BE account, it begins collapsed.
        -->
        <sidebar-menu v-if="otherBrands.length"
                      :collapsable="otherBrandsIsCollapsable" :start-collapsed="otherBrandStartsCollapsed">
            <template #header="{collapsed}">
                <sidebar-header v-if="otherBrands.length" :class="{'be-sidebar-brands--collapsable': otherBrandsIsCollapsable}">
                    <h4>
                        <collapsable-caret v-if="otherBrandsIsCollapsable" :collapsed="collapsed"/>
                        {{ otherBrandTitle }}
                    </h4>
                </sidebar-header>
            </template>

            <template #default>
                <sidebar-menu-item v-for="brand in otherBrands"
                                   :key="brand.id"
                                   class="sidebar__item"
                                   :indented="otherBrandsIsCollapsable"
                                   :active="brand.id === activeBrandId"
                                   :disabled="disabled"
                                   @click="select(brand)">

                    <span :data-indent="getIndent(brand)">
                        {{formatBrandName(brand)}}
                    </span>

                    <tooltip-component :positions="['right']" v-if="getChildren(brand)" >
                        <button class="sidebar__caret" @click.stop.prevent="toggleExpand(brand)">
                            <collapsable-caret :collapsed="!isExpanded(brand)"/>
                        </button>

                        <template #tooltip v-if="!isExpanded(brand)">
                            Click to see this brand's children
                        </template>
                        <template #tooltip v-else>
                            Click to hide this brand's children
                        </template>
                    </tooltip-component>

                </sidebar-menu-item>
            </template>
        </sidebar-menu>


        <sidebar-menu v-if="archivedBrands.length" collapsable :start-collapsed="archivedShouldStartCollapsed">
            <template #header="{collapsed}">
                <sidebar-header class="be-sidebar-brands--collapsable">
                    <h4>
                        <collapsable-caret :collapsed="collapsed"/>
                        Archived brands
                    </h4>
                </sidebar-header>
            </template>

            <template #default>
                <sidebar-menu-item v-for="brand in archivedBrands"
                                   :key="brand.id"
                                   class="sidebar__item"
                                   :active="brand.id === activeBrandId"
                                   :disabled="disabled"
                                   indented
                                   @click="select(brand)">


                    <span :data-indent="getIndent(brand)">
                        {{formatBrandName(brand, {markArchiveDuplicates: false})}}
                    </span>

                    <tooltip-component :positions="['right']" v-if="getChildren(brand)">
                        <button class="sidebar__caret" @click.stop.prevent="toggleExpand(brand)">
                            <collapsable-caret :collapsed="!isExpanded(brand)"/>
                        </button>

                        <template #tooltip v-if="!isExpanded(brand)">
                            Click to see this brand's children
                        </template>
                        <template #tooltip v-else>
                            Click to hide this brand's children
                        </template>
                    </tooltip-component>
                </sidebar-menu-item>
            </template>
        </sidebar-menu>

        <sidebar-menu v-if="deletedBrands.length" collapsable>
            <template #header="{collapsed}">
                <sidebar-header class="be-sidebar-brands--collapsable">
                    <h4>
                        <collapsable-caret :collapsed="collapsed"/>
                        Deleted brands
                    </h4>
                </sidebar-header>
            </template>

            <template #default>
                <sidebar-menu-item v-for="brand in deletedBrands"
                                   :key="brand.id"
                                   class="sidebar__item"
                                   :active="brand.id === activeBrandId"
                                   :disabled="disabled"
                                   indented
                                   @click="select(brand)">
                    {{formatBrandName(brand, {markDeletedBrands: false})}}
                </sidebar-menu-item>
            </template>
        </sidebar-menu>


    </sidebar-menu>
</template>

<script>

import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import CollapsableCaret from "@/components/CollapsableCaret";
import {mapState} from "vuex";
import {formatBrandName} from "@/app/utils/Format";
import VuexStore from "@/store/vuex/VuexStore";
import {mapGetters} from "vuex/dist/vuex.esm.browser";
import TooltipComponent from "@/components/tooltip/TooltipComponent";


export default {
    name: "SidebarBrands",
    store: VuexStore,

    components: {TooltipComponent, CollapsableCaret, SidebarHeader, SidebarMenu, SidebarMenuItem},

    model: {
        prop: 'activeBrandId',
        event: 'selected-id'
    },

    props: {
        brands: {
            type: Array,
            default: () => []
        },
        activeBrandId: {
            type: Number,
            default: null
        },
        selectionIds: {             // These are an array of IDs of brands to place in a separate "Selected brands" area.
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },

        showChildren: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            expanded: []
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapGetters(['idToBrand']),

        selectionBrands() {
            if (!this.selectionIds?.length) return null;
            const selected = new Set(this.selectionIds);
            return this.getExpandedBrands(this.brands.filter(brand => selected.has(brand.id)));
        },

        availableBrands() {
            if (!this.selectionIds?.length) return this.brands;
            const selected = new Set(this.selectionIds);
            return this.brands.filter(brand => !selected.has(brand.id));
        },

        ownBrands() {
            return this.getExpandedBrands(this.availableBrands
                .filter(b => b.category === "OWN" && !b.archived && !b.deleted)
                .sort((lhs, rhs) => this.compare(lhs, rhs)))
        },
        competitorBrands() {
            return this.getExpandedBrands(this.availableBrands
                .filter(b => b.category === "COMPETITOR"  && !b.archived && !b.deleted)
                .sort((lhs, rhs) => this.compare(lhs, rhs)));
        },
        otherBrands() {
            return this.getExpandedBrands(this.availableBrands
                .filter(b => b.category !== "OWN" && b.category !== "COMPETITOR"  && !b.archived && !b.deleted)
                .sort((lhs, rhs) => this.compare(lhs, rhs)));
        },
        archivedBrands() {
            return this.getExpandedBrands(this.availableBrands
                .filter(b => !!b.archived & !b.deleted)
                .sort((lhs, rhs) => this.compare(lhs, rhs)));
        },
        deletedBrands() {
            return this.brands
                .filter(b => !!b.deleted)
                .sort((lhs, rhs) => this.compare(lhs, rhs));
        },

        otherBrandTitle() {
            if (this.ownBrands.length || this.competitorBrands.length) return "Other brands";
            return "Brands";
        },

        otherBrandsIsCollapsable() {
            return !!this.ownBrands.length || !!this.competitorBrands.length;
        },

        otherBrandStartsCollapsed() {
            return this.otherBrands.length > 10 && (!this.activeBrandId || this.otherBrands.every(b => b.id !== this.activeBrandId));
        },

        archivedShouldStartCollapsed() {
            return !this.activeBrandId || this.archivedBrands?.every(b => b.id !== this.activeBrandId);
        }
    },

    watch: {
        activeBrandId() {
            this.ensureActiveExpanded();
        },
    },

    created() {
        this.ensureActiveExpanded();
    },

    methods: {
        formatBrandName,

        ensureActiveExpanded() {
            let brand = this.idToBrand.get(this.activeBrandId);

            while(brand) {
                brand = brand.parent;
                if (brand?.id) {
                    if (!this.isExpanded(brand)) {
                        this.toggleExpand(brand);
                    } else break; // Break early if the parent is expanded
                }
            }
        },

        toggleExpand(brand) {
            if (this.isExpanded(brand)) {
                this.expanded = this.expanded.filter(id => id !== brand.id);
            } else {
                this.expanded.push(brand.id);
            }
        },

        isExpanded(brand) {
            return this.expanded.includes(brand.id);
        },

        getExpandedBrands(brandList) {
            if (!this.showChildren) return brandList;
            let result = [];
            for (const brand of brandList) {
                result.push(brand);
                if (this.isExpanded(brand)) {
                    result = [...result, ...this.getExpandedBrands(this.getChildren(brand) ?? [])];
                }
            }

            return result;
        },

        collapseAllBrands() {
            this.expanded = [];
        },

        getIndent(brand) {
            if (!brand.parent) return null;
            return 1 + this.getIndent(brand.parent) ?? 0;
        },
        
        select(brand) {
            this.$emit('select', brand);
            this.$emit('selected-id', brand?.id ?? null);
        },

        compare(lhs, rhs) {
            const defaultBrand = this.account.defaultBrand;
            if (defaultBrand?.id === lhs.id) return -1;
            if (defaultBrand?.id === rhs.id) return 1;
            const lhsName = formatBrandName(lhs).toLowerCase();
            const rhsName = formatBrandName(rhs).toLowerCase();
            return lhsName.localeCompare(rhsName);
        },

        getChildren(brand) {
            if (!this.showChildren || !brand.children?.length) return null;
            const children = brand.children?.filter(c => !c.deleted);
            return children.length ? children : null
        }
    }
}

</script>

<style scoped lang="sass">

.sidebar-menu
    background: var(--sidebar-background)

.sidebar__item
    display: flex

.sidebar__caret
    background: transparent
    border: none
    color: var(--sidebar-colour)
    width: 3ch
    padding: 0
    margin: 0 0 0 auto

    &:hover
        background: var(--sidebar-background)

[data-indent],
[data-indent="3"]
    padding-left: 9ch

[data-indent="1"]
    padding-left: 3ch

[data-indent="2"]
    padding-left: 6ch



</style>