<template>
    <add-rule-dialog class="add-relevancy-rule"
                     @close="$emit('close')"
                     @next="next" @prev="prev"
                     :rule-type="ruleTypes.set_relevant"
                     :total-stages="totalStages"
                     :cur-stage="curStage"
                     :next-btn-text="nextBtnText"
                     :show-next-btn="showNextBtn"
                     :show-prev-btn="showPrevBtn"
                     :enable-next-btn="enableNextBtn">
        <template #workflow>
            <div key="1" v-if="curStage === addRuleStages.setRelevantMentions.EDIT_FILTER">
                <text-input class="add-relevancy-rule__text-input" v-model="ruleName" @valid="setValidRuleName" required label="Rule name" ref="name" placeholder="Rule name or description"/>

                <h4>Edit the filter</h4>
                <p>
                    This rule will be mark all incoming mentions that match this filter as relevant. DataEQ will never send these mentions to the trash unless they are sorted by a human or deleted by another rule.
                </p>
                <filter-editor class="add-relevancy-rule__filter-editor" @input="onFilterChange" :value="ruleFilter" for-rule/>
            </div>

            <div key="2" v-if="curStage === addRuleStages.setRelevantMentions.EXAMPLE_MENTIONS">
                <h4>Example mentions</h4>
                <p>Future mentions like this will always be relevant.</p>
                <div class="add-relevancy-rule__preview-mentions">
                    <preview-mentions :filter="ruleFilter"></preview-mentions>
                </div>
            </div>

            <div key="3" v-if="curStage === addRuleStages.setRelevantMentions.SAVE">
                <saving-item :save-complete="saveComplete" :save-error="saveError" :saving-message="saveMessage" :save-complete-message="saveCompleteMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more <i class="symbol-checkmark"></i> Relevancy rules</h4>
                    </template>
                </saving-item>
            </div>
        </template>
    </add-rule-dialog>
</template>

<script>
import AddRuleDialog from "@/setup/rules/add-rule/AddRuleDialog";
import {ruleTypes} from "@/setup/rules/RuleUtils";
import {addRuleMixins} from "@/setup/rules/add-rule/AddRuleMixins";
import TextInput from "@/components/inputs/TextInput";
import FilterEditor from "@/components/inputs/FilterEditor";
import PreviewMentions from "@/components/PreviewMentions";
import SavingItem from "@/setup/SavingItem";
import VuexStore from "@/store/vuex/VuexStore";

export default {
    name: "AddRelevancyRule",
    components: {
        SavingItem, PreviewMentions, FilterEditor, TextInput, AddRuleDialog},
    mixins: [addRuleMixins],
    store: VuexStore,

    data() {
        return {
            ruleTypes: ruleTypes,
            totalStages: 1,
            curStage: 1,
            ruleName: "",
            ruleNameValid: true,
            ruleFilter: ""
        }
    },

    created() {
        this.totalStages = Object.keys(this.addRuleStages.setRelevantMentions).length;
    },

    computed: {
        enableNextBtn() {
            switch (this.curStage) {
                case this.addRuleStages.setRelevantMentions.EDIT_FILTER:
                    return !!this.ruleFilter && !!this.ruleNameValid;
                case this.addRuleStages.setRelevantMentions.SAVE:
                    return false;
                default:
                    return true;
            }
        },

        showNextBtn() {
            return this.curStage !== this.addRuleStages.setRelevantMentions.SAVE;
        },

        showPrevBtn() {
            return this.curStage > 1 && this.curStage !== this.addRuleStages.setRelevantMentions.SAVE;
        },

        nextBtnText() {
            return this.curStage !== this.totalStages - 1  ? "Next" : "Save Rule";
        }
    },

    methods: {
        setValidRuleName(value) {
            this.ruleNameValid = value;
        },


        onFilterChange(changeData) {
            this.ruleFilter = changeData;
        },

        next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                let payload = {
                    action: "MACRO",
                    active: true,
                    attributes: "relevancy = relevant",
                    filter: this.ruleFilter,
                    name: this.ruleName
                }

                this.saveRule(payload);
            }
        },

        prev() {
            this.curStage--;
        },

        reset() {
            this.curStage = 1;
            this.ruleName = "";
            this.ruleNameValid = true;
            this.ruleFilter = "";
        }
    }
}
</script>

<style scoped lang="sass">

.add-relevancy-rule

    p
        color: var(--be-colour-text-dark)

    &__header-container
        border-bottom: 1px dashed var(--be-colour-muted-text-dark)
        padding-bottom: 10px

    &__text-input
        height: 90px

        ::v-deep input
            width: 80%

    &__filter-editor
        margin-top: 20px

    &__preview-mentions
        display: flex
        height: 475px

</style>