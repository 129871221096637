import {ParagraphBlock} from "@/dashboards/widgets/commentaryplus/blockTools/ParagraphBlock";

export class SummaryBlock extends ParagraphBlock {

    constructor(data) {
        super(data);
    }

    render() {
        this._element = super.render();
        if (!this._data.text){
            const container = document.createElement('command-element');
            container.classList.add('deq-count');
            container.dataset.command = '{{summarise-mentions}}';
            container.dataset.filter = this.filter;
            container.innerText = container.dataset.command;

            this._element.appendChild(container);
        }
        return this._element;
    }

    static get toolbox() {
        return {
            icon: '<i class="icon-doc-text"></i>',
            title: "Summary"
        };
    }

}