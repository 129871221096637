import {mailSupport} from "@/app/help/Help";
import {account} from "@/app/utils/Account";

Beef.module("FeedNotify").addInitializer(function(startupOptions) {

    this.feature = Backbone.Model.extend({});
    this.features = {};
    this.localStoragePrefix = 'beef:feed-notify:ack:'
    this.featuresByFeedPickerRef = {};
    this.featuresByLinkPickerRef = {};

    /**
     * Adds a new feature notifier to this component
     * @param featureName
     * @param options
     * {
     *      acceptCallBack: a callback to invoke if a user would like more information,
     *      popupMessage: a description of the feature,
     *      acceptPhrase: the word the user will click to positively acknowledge the notification,
     *      declinePhrase: the text the user will click to negatively acknowledge the notification,
     * }
     */
    this.addFeature = function(featureName, options) {
        options = this._defaultOptions(options);
        options.ackKey = this.localStoragePrefix + featureName;
        this.features[featureName] = options;
        if(options.feedPickerRef) this.featuresByFeedPickerRef[options.feedPickerRef] = featureName;
        if(options.linkPickerRef) this.featuresByLinkPickerRef[options.linkPickerRef] = featureName;
        return featureName;
    };

    this._defaultOptions = function(options) {
        options = Object.assign({
            acceptCallback: function() {alert("Please specify a call back function for a positive user response.")},
            popupMessage: "Please specify some information about the feature.",
            acceptPhrase: "Positive",
            declinePhrase: "Negative"
        }, options || {});
        return options;
    };

    this.showFeaturePopup = function(featureName, targetControl, positions) {
        var options = this._getFeatureOptions(featureName);
        if(!positions) positions = ["bottom-left", "top-left", "top-right", "bottom-right"];
        if (!options || options.acknowledged) return;
        var popup = Beef.MiniMenu.show({
            target: targetControl,
            dropdown: false,
            positions: positions,
            offsets: {right: -1, top: +1},
            template: require("@/app/framework/feed-notify/FeedNotify.handlebars"),
            model: new Backbone.Model({
                popupMessage: options.popupMessage,
                acceptPhrase: options.acceptText,
                declinePhrase: options.declinePhrase})
        });
        var $popupEl = popup.$el;
        $popupEl.find('.feed-notify-ack').on('click', function () {
            Beef.FeedNotify.acknowledgeFeature(featureName);
        });
        if (options.acceptCallback) {
            $popupEl.find('.feed-notify-message-yes-option').on('click', function () {
                var options = Beef.FeedNotify._getFeatureOptions(featureName);
                if(!options) return;
                var cb = options.acceptCallback;
                if(cb) cb(featureName);
            });
        }
        $('html').on('click', function(event) {
            if(popup) popup.hide();
            Beef.FeedNotify._clearPopupDetail(options);
        });
        options.popup = popup;
    };

    this._clearPopupDetail = function(options) {
        options.popup = null;
        options.timerRef = null;
    };

    this._getFeatureOptions = function(featureName) {
        var options = this.features[featureName];
        if(!options) return null;
        options.acknowledged = sessionStorage.getItem(options.ackKey);
        return options;
    };

    this._getFeatureName = function(ref) {
        var featureName = this.featuresByFeedPickerRef[ref];
        if(featureName) return featureName;
        return this.featuresByLinkPickerRef[ref];
    };

    this.acknowledgeFeature = function(featureName) {
        var options = this._getFeatureOptions(featureName);
        if(!options) return;
        var stamp = new Date();
        sessionStorage.setItem(options.ackKey, stamp);
        if(options.popup) {
            if(options.timerRef) window.clearTimeout(options.timerRef);
            options.popup.hide();
        }
        options.popup = null;
        options.timerRef = null;
    };

    this.attachFeedPickerNotifications = function() {
        return function(data) {
            var jqEl = $('span[name=feeds]').find('span[data-value="' + data + '"]');
            if (jqEl.size() <= 0) return;
            var featureName = Beef.FeedNotify._getFeatureName(data);
            var display =
                (featureName == 'Broadcast Media' && !account().hasFeedBroadcast)
                || (featureName == 'Print Media' && !account().hasFeedPrint);
            if(display) {
                Beef.FeedNotify.showFeaturePopup(featureName, jqEl.get(0), ['top-right']);
            }
        };
    };

    this.attachLinkPickerNotifications = function() {
        var $elements = $('.link-picker').find('input[type=checkbox]');
        $elements.each(function() {
            var jqEl = $(this);
            var key = jqEl.attr('name');
            if(!key) return;
            var featureName = Beef.FeedNotify._getFeatureName(key);
            if(!featureName) return;
            jqEl.on('change', function(event) {
                setTimeout(function() {
                    var selector = 'span[title="' + featureName + '"]';
                    var $lastTag = $('span.link.tag-input').find(selector);
                    if($lastTag.size() <= 0) return;
                    var display =
                        (featureName == 'Broadcast Media' && !account().hasFeedBroadcast)
                        || (featureName == 'Print Media' && !account().hasFeedPrint);
                    if (display && $(event.target).prop('checked')) {
                        Beef.FeedNotify.showFeaturePopup(featureName, $lastTag.get(0), ['top-right']);
                    }
                });
            });
        });
    };


    this.addFeature('Broadcast Media', {
        popupMessage: "Your account is not subscribed to Broadcast Media. Find out more?",
        acceptText: "Contact support",
        declinePhrase: "No Thanks",
        acceptCallback: function() {
            // DataEQ doesn't have an active ticketing system - rather send a mail to support for the time being
            // Beef.SupportTicket.show({title: "Broadcast Media Monitoring Information", description: "Hi, \n\n I would like to find out more about Broadcast Media Monitoring."});
            mailSupport(null,
                "Broadcast Media Monitoring Information",
                "Hi, \n\n I would like to find out more about Broadcast Media Monitoring."
            );
        },
        feedPickerRef: 'EMEDIAMONITOR', //Brand Setup
        linkPickerRef: 'token_broadcast_media'
    }),

    this.addFeature('Print Media', {
        popupMessage: "Your account is not subscribed to Print Media. Find out more?",
        acceptText: "Contact support",
        declinePhrase: "No Thanks",
        acceptCallback: function() {
            // DataEQ doesn't have an active ticketing system - rather send a mail to support for the time being
            // Beef.SupportTicket.show({title: "Print Media Monitoring Information", description: "Hi, \n\n I would like to find out more about Print Media Monitoring."});
            mailSupport(null,
                "Print Media Monitoring Information",
                "Hi, \n\n I would like to find out more about Print Media Monitoring."
            );
        },
        linkPickerRef: 'token_print_media',
        feedPickerRef: 'PRESSREADER'
    });
});