import {earliestDate, findAllNodes, latestDate, parseFilterString} from "@/dashboards/filter/FilterParser";
import {MentionQLexer} from "@/mentionq/mentionq";
import VuexStore from "@/store/vuex/VuexStore";
import {
    capitalise, encloseInDisplayQuotes,
    escapeExpression,
    isUnknown,
    removeQuotes,
    splitAtSpaces,
    splitQuotedString
} from "@/app/utils/StringUtils";
import {SOCIAL_NETWORKS} from "@/app/utils/SocialNetworks";
import {
    get as getSegment,
    getChannelSegmentList, getMarketConductOutcomeListsOnBrands,
    getRiskSegmentList,
    getSegmentList
} from "@/app/utils/Segments";
import {codeToOrdinal, getMetatagsFromTags} from "@/app/utils/Metatags";
import moment from "moment";
import {formatBrandName, formatNumber, formatSeconds as originalFormatSeconds} from "@/app/utils/Format";
import {getProfileIcon} from "@/app/utils/Profiles";
import {convertExpToAttrs} from "@/dashboards/filter/BasicFilter";
import {LANGUAGES} from "@/app/utils/Language";
import {currentAccountCode} from "@/app/utils/Account";

/**
 * Transforms the given filter into English and invoke callback with the english text. The callback may be invoked
 * multiple times if brand, phrase or tag names have to be looked up via ajax calls as the data comes in. The
 * initial text will contain ids in this case. The optional cache object is used to cache and lookup brand, phrase
 * and tag data. If the filter is too complex to be converted to English it is returned as is.
 * <p>
 *     An optional options object can be supplied for passing additional information or options
 *     to term builders. Some properties that can be set are:
 *     - {boolean} interactsWith.noIcons, only show text instead of including an HTML icon prefix for profile types
 * </p>
 *
 * @param {FilterString} filter
 * @param {function(english: String)} callback
 * @param [cache]
 * @param [options]
 */
export function toEnglish(filter, callback, cache, options) {
    VuexStore.dispatch("refreshTags")
        .then(() => {
            try {
                toEnglishImpl(filter, cache, currentAccountCode(), callback, options);
            } catch (e) {
                console.warn("FilterToEnglish: Unable to convert filter [" + filter + "]: ", e);
                callback(filter ? filter.toString() : filter);
            }
        })
        .catch(e => {
            console.error(e);
            callback(filter ? filter.toString() : filter);
        });
}


/**
 * Converts a filter to an english string defining a date. Will always return a string.
 * @param filter
 * @returns {string}
 */
export function dateToEnglish(filter) {
    if (typeof filter === 'string' || filter instanceof String) filter = parseFilterString(filter);

    var dates = findAllNodes(filter, function(n) {
        return n.operandType === MentionQLexer.PUBLISHED;
    });

    if (!dates.length) return "in all time";
    if (dates.length === 1) {
        if (dates[0].operationType === MentionQLexer.ON && dates[0].literalImage.toLowerCase() === "today") {
            return "today";
        }
        if (dates[0].operationType === MentionQLexer.INTHELAST) {
            var period = dates[0].literalImage.toLowerCase();
            switch (period) {
                case "24hours": period = "24 hours"; break;
            }
            return "in the last " + period
        }
    }

    var earliest = earliestDate(filter);
    var latest = latestDate(filter);

    const isStartOfMonth = earliest.isSame(earliest.clone().startOf('month'));
    const isEndOfMonth = latest.isSame(earliest.clone().endOf("month").add(1, 'day').startOf('day'));
    if (isStartOfMonth && isEndOfMonth) {
        return "during " + earliest.format("MMMM");
    }

    return "between " + earliest.format("MMMM D, YYYY") + " and " + latest.subtract(1, 'day').format("MMMM D, YYYY");
}



// ---------------------------------------------------------------------------------------------------------------------

export function removeFromTerms(attrs, key) {
    const ans = attrs[key];
    delete attrs[key];
    return ans;
}

export function toEnglishFormatStrong(text, tooltip, noHtml) {
    if (!text) text = "";
    text = escapeExpression(text);
    if (noHtml) return encloseInDisplayQuotes(text);
    if (tooltip) tooltip = escapeExpression(tooltip);
    return "<strong" + (tooltip ? " tooltip='" + tooltip + "'" : '') +">" + text + "</strong>";
}

export function toEnglishCommas(list, sep) {
    if (!list || !list.length) return '';
    if (list.length === 1) return list[0];
    return list.slice(0, list.length - 1).join(", ") + sep + list[list.length - 1];
}

/**
 * For internal implementation of toEnglish.
 */
export function doToEnglishAuthorId(attrs, terms) {
    const ids = splitQuotedString(removeFromTerms(attrs, 'authorId'));
    if (!ids?.length) return;

    if (ids.length === 1 && ids[0].toLowerCase() === "unknown") {
        terms.push("by " + toEnglishFormatStrong("unknown authors"));
        return;
    }

    if (ids.length === 1 && ids[0].toLowerCase() === "-unknown") {
        terms.push("excluding " + toEnglishFormatStrong("unknown authors"));
        return;
    }

    const authors = [];
    const exclude = [];
    for (let id of ids) {
        let insertTo = authors;
        if (id.startsWith('-')) {
            id = id.substring(1);
            insertTo = exclude;
        }
        id = removeQuotes(id);
        if (id.includes(" ")) {
            const index = id.lastIndexOf(" ");
            let name = id.substring(0, index);
            const authorId = id.substring(index + 1);
            insertTo.push(toEnglishFormatStrong(name, `${name} has author ID ${authorId}`));
        } else {
            insertTo.push(toEnglishFormatStrong(id));
        }
    }

    if (authors?.length) {
        let intro = authors.length > 1 ? "by authors " : "by author ";
        terms.push(intro + toEnglishCommas(authors, " or "));
    }

    if (exclude?.length) {
        let intro = exclude.length > 1 ? "excluding authors " : "excluding author ";
        terms.push(intro + toEnglishCommas(exclude, " and "));
    }
}


export function doSocialNetwork(attrs, terms) {
    const ids = removeFromTerms(attrs, 'socialNetwork');
    if (!ids) return;

    doSyncAttr(ids, terms, "from", function(id) {
        const prettyName = toEnglishFormatStrong(SOCIAL_NETWORKS[id] || id);

        switch(id) {
            case "TWITTER":         return prettyName;
            case "FACEBOOK":        return '<strong><i class="symbol-facebook-rect"></i></strong>' + prettyName;
            case "YOUTUBE":         return '<strong><i class="symbol-youtube"></i></strong>' + prettyName;
            case "INSTAGRAM":       return '<strong><i class="symbol-instagram"></i></strong>' + prettyName;
            case "LINKEDIN":        return '<strong><i class="symbol-linkedin-rect"></i></strong>' + prettyName;
            case "VK":              return '<strong><i class="symbol-vk"></i></strong>&nbsp;' + prettyName;
            case "TUMBLR":          return '<strong><i class="symbol-tumblr"></i></strong>' + prettyName;
            case "TELEGRAM":        return '<strong><i class="symbol-telegram"></i></strong>' + prettyName;
            case "WHATSAPP":        return '<strong><i class="symbol-whatsapp"></i></strong>' + prettyName;
            case "HELLOPETER":      return '<strong><i class="symbol-hellopeter"></i></strong>' + prettyName;
            case "GOOGLEBUSINESS":  return '<strong><i class="symbol-google-full"></i></strong>' + prettyName;
            case "TIKTOK":          return '<strong><i class="symbol-tiktok"></i></strong>' + prettyName;
            case "TRUSTPILOT":      return '<strong><i class="symbol-trustpilot"></i></strong>&nbsp;' + prettyName;
            case "UNKNOWN":         return '<strong><i class="symbol-website"></i></strong>' + prettyName;
        }

        return prettyName;
    }, true);
}

export function doEngageTicket(attrs, terms) {
    const ids = removeFromTerms(attrs, 'ticketId');
    if (!ids) return;
    if (ids.toLowerCase() === "unknown") {
        terms.push("not ticketed in Engage");
        return;
    }
    if (ids.toLowerCase() === "-unknown") {
        terms.push("ticketed in Engage");
        return;
    }
    doSyncAttr(ids, terms, "with Engage ticket ID", id => id);
}

export function doInteger(v, terms, prefix, formatFn) {
    if (!v) return;
    let f = formatFn || (d => d);
    // v is less-than-nnn, greater-than-nnn or between-nnn-nnn
    let a = v.split('-');
    if (a[0] === "between") {
        v = "between " + f(a[1]) + " and " + f(a[2]);
    } else if (a[0] === "equals") {
        v = a[0] + " " + a[1];
    } else {
        v = a[0] + " " + a[1] + " " + f(a[2]);
    }
    terms.push(prefix + " " + v);
}

export function doIntegerRange(v, terms, prefix, formatFn) {
    if (!v) return;
    let f = formatFn || (d => d);
    // v is less-than-nnn, greater-than-nnn or between-nnn-nnn
    let a = v.split('-');
    if (a[0] === "between") v = "between " + f(a[1]) + " and " + f(a[2]);
    else v = a[0] + " " + a[1] + " " + f(a[2]);
    terms.push(prefix + " " + toEnglishFormatStrong(v));
}

export function doPhrase(attrs, terms, accountCode, cache, cb) {
    var ids = removeFromTerms(attrs, 'phrase');
    if (!ids) return;
    const idToString = id => `«phrase:${id}»`;
    if (!VuexStore.state.phrases) {
        VuexStore.dispatch("refreshPhrases").then(() => cb()).catch(e => console.error(e));
        doSyncAttr(ids, terms, "for the phrase", idToString);
        return;
    }
    const lookup = function(id) {
        const s = VuexStore.getters.idToPhrase.get(parseInt(id));
        return s?.query ?? idToString(id);
    };
    doSyncAttr(ids, terms, "for the phrase", lookup);
}

export function doBrand(attrs, terms) {
    const ids = removeFromTerms(attrs, 'brand');
    if (!ids) return;
    const idToBrand = VuexStore.getters.idToBrand;

    let a = ids.split(" ");
    let excludeSubBrands = a.length > 0 && a[0] === 'x';
    let pos = [], neg = [];
    for (let i = excludeSubBrands ? 1 : 0; i < a.length; i++) {
        let id = a[i], b;
        if (id.charAt(0) === '-') {
            id = id.substring(1);
            b = idToBrand.get(parseInt(id));
            neg.push(b ? toEnglishFormatStrong(b.shortName || b.name, b.shortName ? b.name : null) : id);
        } else {
            b = idToBrand.get(parseInt(id));
            let name = `«Unknown Brand:${id}»`;
            if (b) name = formatBrandName(b);
            pos.push(toEnglishFormatStrong(name));
        }
    }
    if (pos.length > 0) {
        let t = pos.length === 1 ? "for the brand " + pos[0] : "for brands " + toEnglishCommas(pos, " or ");
        if (excludeSubBrands) t += " (excluding sub-brands)";
        terms.push(t);
        if (neg.length > 0) terms.push("excluding " + toEnglishCommas(neg, " and "));
    } else {
        terms.push(neg.length === 1 ? "excluding the brand " + neg[0] : "excluding brands " + toEnglishCommas(neg, " and "));
    }
}

export function doAuthorLocation(attrs, terms) {
    const authorLocation = removeFromTerms(attrs, "authorLocation");
    if (authorLocation) {
        throw "Unhandled authorLocation";
    }
}

export function doLocation(attrs, terms, cb) {
    const ids = removeFromTerms(attrs, 'location');
    if (!ids) return;
    let pos = [], neg = [];
    splitAtSpaces(ids, "'").forEach(function(id) {
        let not = id.charAt(0) === '-';
        if (not) id = id.substring(1);
        id = removeQuotes(id);
        if (id === "UN") {
            (not ? neg : pos).push(toEnglishFormatStrong("an unknown location"));
        } else {
            var p = VuexStore.getters["locations/getCached"](id);
            if (p) {
                (not ? neg : pos).push(toEnglishFormatStrong(p.label));
            } else {
                (not ? neg : pos).push(id);
                VuexStore.dispatch("locations/getLocation", id)
                    .then(() => cb())
                    .catch(e => console.error(e));
            }
        }
    });

    if (pos.length > 0) {
        terms.push("from " + toEnglishCommas(pos, " or ") +
            (neg.length > 0 ? " and not from " + toEnglishCommas(neg, " and ") : ""));
    } else {
        terms.push("not from " + toEnglishCommas(neg, " and "));
    }
}

export function doCx(attrs, terms) {
    let journeys = VuexStore.getters.cxLists;
    let channels = getChannelSegmentList();
    let channelIds = null;
    let channelsNone = {};
    if (channels) {
        channelIds = removeFromTerms(attrs, "channels");
        channelsNone = channels.children.find(function(c) { return c.flag === "NONE_OF_THE_ABOVE"; });
    }
    let conjunction = ' or ';

    let getName = id => {
        let segment = getSegment(id);
        if (!segment) return toEnglishFormatStrong(id);
        let rpcs = getMetatagsFromTags(segment);
        if (!rpcs) return toEnglishFormatStrong(segment.name);
        return "<be-rpcs-icon code='" + rpcs.code + "'></be-rpcs-icon>&nbsp;" + toEnglishFormatStrong(segment.name);
    };

    let multipleCxLists = journeys?.length > 1;

    if (journeys) {
        for (const journey of journeys) {
            let journeyNone = journey && journey.children.find(function(c) { return c.flag === "NONE_OF_THE_ABOVE"; }) || {};

            let journeyIds;
            let interactionIds;

            journeyIds = removeFromTerms(attrs, "" + journey.id);
            interactionIds = removeFromTerms(attrs, "" + journey.id + ":interactions");

            if (!journeyIds && !interactionIds) continue;

            if (journeyIds) {
                conjunction = journeyIds[0] === '&' ? ' and ' : ' or ';
                journeyIds = splitAtSpaces(journeyIds).map(function(id) { return parseInt(id) });

                // if we have multiple cx segment lists in the account, we have to be more specific
                let journeyName = multipleCxLists && journey.subtitle ? `${journey.subtitle} ${journey.name}` : journey.name

                if (journeyIds.length === 1 && journeyIds[0] === journey.id) {
                    terms.push("relating to your " + toEnglishFormatStrong(journeyName));
                } else if (journeyIds.length === 1 && journeyIds[0] === journeyNone.id) {
                    terms.push("unrelated to your " + toEnglishFormatStrong(journeyName));
                } else {
                    let includeJourney = journeyIds
                        .filter(function(id) { return id > 0 })
                        .map(getName);

                    let excludeJourney = journeyIds
                        .filter(function(id) { return id < 0 })
                        .map(getName);

                    if (includeJourney.length) {
                        terms.push("with " + journeyName + " stage " + toEnglishCommas(includeJourney, conjunction));
                    }
                    if (excludeJourney.length) {
                        terms.push("excluding " + journeyName + " stage " + toEnglishCommas(excludeJourney, ' and '));
                    }
                }
            }

            if (interactionIds) {
                conjunction = interactionIds[0] === '&' ? ' and ' : ' or ';
                interactionIds = splitAtSpaces(interactionIds).map(function(id) { return parseInt(id) });

                let includeInteractions = interactionIds
                    .filter(id => id > 0 )
                    .map(getName);
                let excludeInteractions = interactionIds
                    .filter(id => id < 0)
                    .map(getName);

                if (includeInteractions?.length) terms.push("with interactions " + toEnglishCommas(includeInteractions, conjunction));
                if (excludeInteractions?.length) terms.push("excluding interactions " + toEnglishCommas(excludeInteractions, ' and '));
            }
        }
    }

    if (channelIds) {
        conjunction = channelIds[0] === '&' ? ' and ' : ' or ';
        channelIds = splitAtSpaces(channelIds).map(function(id) { return parseInt(id) });

        if (channelIds.length === 1 && channelIds[0] === channels.id) {
            terms.push("relating to your " + toEnglishFormatStrong(channels.name));
        } else if (channelIds.length === 1 && channelIds[0] === channelsNone.id) {
            terms.push("with " + toEnglishFormatStrong('no channels'))
        } else {
            let includeChannels = channelIds
                .filter(function(id) { return id > 0 })
                .map(getName);
            let excludeChannels = channelIds
                .filter(function(id) { return id < 0 })
                .map(getName);

            if (includeChannels?.length) terms.push("with channels " + toEnglishCommas(includeChannels, conjunction));
            if (excludeChannels?.length) terms.push("excluding channels " + toEnglishCommas(excludeChannels, ' and '));
        }
    }
};


export function doRisk(attrs, terms) {
    let risk = getRiskSegmentList();
    let riskNone = risk && risk.children.find(function(c) { return c.flag === "NONE_OF_THE_ABOVE"; }) || {};

    let riskIds = null;
    if (risk) riskIds = removeFromTerms(attrs, "risk");
    if (!riskIds) return;

    let getName = function(id) {
        let segment = getSegment(id);
        if (!segment) return toEnglishFormatStrong(id);
        let rpcs = getMetatagsFromTags(segment);
        if (!rpcs) return toEnglishFormatStrong(segment.name);
        return "<be-rpcs-icon code='" + rpcs.code + "'></be-rpcs-icon>&nbsp;" + toEnglishFormatStrong(segment.name);
    };

    let conjunction = ' or ';
    if (riskIds) {
        conjunction = riskIds[0] === '&' ? ' and ' : ' or ';
        riskIds = splitAtSpaces(riskIds).map(function(id) { return parseInt(id) });

        if (riskIds.length === 1 && riskIds[0] === risk.id) {
            terms.push("relating to your " + toEnglishFormatStrong(risk.name));
        } else if (riskIds.length === 1 && riskIds[0] === riskNone.id) {
            terms.push("unrelated to your " + toEnglishFormatStrong(risk.name));
        } else {
            let includeConduct = riskIds
                .filter(function(id) { return id > 0 })
                .map(getName);

            let excludeConduct = riskIds
                .filter(function(id) { return id < 0 })
                .map(getName);

            if (includeConduct.length) {
                terms.push("with " + risk.name + " tag " + toEnglishCommas(includeConduct, conjunction));
            }
            if (excludeConduct.length) {
                terms.push("excluding " + risk.name + " tag " + toEnglishCommas(excludeConduct, ' and '));
            }
        }
    }
}

/**
 * Generates a description for the 'interactsWith' filter in natural language. If the cache is undefined or does
 *     not have profiles defined, the function exits.
 */
export function doInteractsWith(idsString, terms, accountCode, cache, cb, options) {
    if (!idsString) return;
    if (!VuexStore.state.profiles.profiles) {
        VuexStore.dispatch("profiles/refreshProfiles").then(() => cb()).catch(e => console.error(e));
        return;
    }
    let { pos, neg } = toProfilePosNeg(idsString, cache, options)
    if (pos.length > 0) {
        terms.push(pos.length === 1 ? "that are directed at the profile for " + pos[0]
            : "that are directed at the profiles for " + toEnglishCommas(pos, " or "));

        if (neg.length > 0) terms.push("excluding " + toEnglishCommas(neg, " and "));
    } else {
        terms.push(neg.length === 1 ? "excluding those directed at the profile for " + neg[0]
            : "excluding those directed at profiles for " + toEnglishCommas(neg, " and "));

    }
}

export function doHasReplyFromProfile(idsString, terms, accountCode, cache, cb, options) {
    if (!idsString) return
    if (!VuexStore.state.profiles.profiles) {
        VuexStore.dispatch("profiles/refreshProfiles").then(() => cb()).catch(e => console.error(e));
        return;
    }

    let { pos, neg } = toProfilePosNeg(idsString, cache, options)
    if (pos.length > 0) {
        terms.push("that have a reply from " + toEnglishCommas(pos, " or "));
        if (neg.length > 0) terms.push(" and not " + toEnglishCommas(neg, " and "));
    } else {
        terms.push("that do not have a reply from " + toEnglishCommas(neg, " or "));
    }
};

function toProfilePosNeg(idsString, cache, options) {
    const handleTypeMap = {
        TWITTER_SCREEN_NAME: "Twitter",
        FACEBOOK_PAGE:       "Facebook page",
        FACEBOOK_USER:       "Facebook user",
        INSTAGRAM_USER:      "Instagram",
        LINKEDIN_COMPANY:    "LinkedIn"
    };

    let neg = [], pos = [],
        profiles = VuexStore.getters["profiles/idToProfile"],
        ids = idsString.split(" "),
        noIcons = options && options.interactsWith && options.interactsWith.noIcons;

    let getProfileName = function(id) {
        let displayName = "",
            p = profiles.get(parseInt(id));

        if (!p) {
            displayName = id;
        } else if (noIcons) {
            displayName = (p.handle && p.type) ? p.handle + " (" + handleTypeMap[p.type] + ")" : id;
        } else {
            let prefix = (p.type) ? getProfileIcon(p.type) : "";
            displayName = (p.name) ? (`<strong>${prefix}</strong>` + toEnglishFormatStrong(p.name)) : id;
        }
        return displayName;
    };

    for (let i = 0; i < ids.length; ++i) {
        let isNeg = (ids[i].charAt(0) === "-");
        if (isNeg) neg.push(getProfileName(ids[i].substr(1)));
        else pos.push(getProfileName(ids[i]));
    }
    return { pos: pos, neg: neg }
}



export function doSegments(attrs, terms) {
    let ids = removeFromTerms(attrs, 'segments');
    if (!ids) return;

    let and = ids[0] === '&';

    let a = splitAtSpaces(ids).map(function(i) { return parseInt(i)});

    let parentToChildren = {};
    let parents = new Set();
    let isExcluded = new Set();
    let unknown = new Set();
    a.forEach(function(id) {
        var parent = getSegmentList(Math.abs(id));
        if (parent) {
            parents.add(parent.id);
            parentToChildren[parent.id] = parentToChildren[parent.id] || [];
            if (parent.id !== Math.abs(id)) {
                parentToChildren[parent.id].push(id);
            } else if (id < 0) { // This is a parent that has been excluded
                isExcluded.add(parent.id);
            }
        } else {
            unknown.add(id);
        }
    });

    var first = true;
    Array.from(parents).forEach(function(parentId) {
        var children = parentToChildren[parentId];
        var parent = getSegment(parentId);

        var conjunction = first ? '' : (and ? 'and ' : 'or ');

        if (!children.length) {
            if (!isExcluded.has(parent.id)) {
                terms.push(conjunction + "concerning your " + toEnglishFormatStrong(parent.name.trim()));
            } else {
                terms.push(conjunction + "excluding your " + toEnglishFormatStrong(parent.name.trim()));
            }

        } else {
            if (children.length === 1) {
                var child = getSegment(children[0]);
                var rpcs = getMetatagsFromTags(child);
                terms.push(conjunction +
                    (children[0] > 0 ? "with " : "excluding ") +
                    parent.name.trim() + " tag " +
                    (rpcs ? "<be-rpcs-icon code='" + rpcs.code + "'></be-rpcs-icon>&nbsp;" : '') +
                    "<em>" + toEnglishFormatStrong(child.name.trim()) + "</em>");
            } else {
                var includingText = toEnglishCommas(children
                    .filter(function(cId) { return cId > 0 })
                    .map(function(cId) { return getSegment(cId) || cId})
                    .map(function(c) {
                        var rpcs = getMetatagsFromTags(c);
                        var name = c.id ? c.name.trim() : "" + c;
                        if (rpcs) name = "<be-rpcs-icon code='" + rpcs.code + "'></be-rpcs-icon>&nbsp;" + toEnglishFormatStrong(name);
                        return name;
                    }), and ? ' and ' : ' or ');
                var excludingText = toEnglishCommas(children
                    .filter(function(cId) { return cId < 0 })
                    .map(function(cId) { return getSegment(Math.abs(cId)) || cId})
                    .map(function(c) {
                        var rpcs = getMetatagsFromTags(c);
                        var name = c.id ? c.name.trim() : "" + c;
                        if (rpcs) name = "<be-rpcs-icon code='" + rpcs.code + "'></be-rpcs-icon>&nbsp;" + toEnglishFormatStrong(name);
                        return name;
                    }), and ? ' or ' : ' and ');

                if (includingText) {
                    terms.push(conjunction +
                        "with " +
                        parent.name.trim() + " tags " + includingText);
                }
                if (excludingText) {
                    if (includingText) terms.push("excluding " + excludingText);
                    else terms.push(conjunction + "excluding " + parent.name.trim() + " tags " + excludingText);
                }
            }
        }
        first = false;

    });


    if (unknown.size) {
        let includeUnknown = toEnglishCommas(Array.from(unknown)
                .filter(function(cId) { return cId > 0 })
                .map(cId => toEnglishFormatStrong(`«Unknown segment:${cId}»`)),
            and ? ' and ' : ' or ');

        let excludeUnknown = toEnglishCommas(Array.from(unknown)
                .filter(function(cId) { return cId < 0 })
                .map(cId => toEnglishFormatStrong(`«Unknown segment:${cId}»`)),
            ' and ');

        if (includeUnknown.length) {
            terms.push("including segments " + includeUnknown);
        }
        if (excludeUnknown.length) {
            terms.push("excluding segments " + excludeUnknown)
        }
    }
}

export function doPublished(attrs, terms) {
    const dateFormat = 'D MMMM YYYY';
    const dateFormatHourly = 'D MMMM YYYY HH:mm';

    const inTheLast = {
        HOUR:            'hour',
        TWENTY_FOUR_HRS: '24 hours',
        DAY:             'day',
        WEEK:            'week',
        FORTNIGHT:       'fortnight',
        MONTH:           'month',
        QUARTER:         'quarter',
        YEAR:            'year'
    };

    const p = removeFromTerms(attrs, 'published');
    if (!p) return;

    const s = inTheLast[p];
    if (s) return terms.push(`published ${toEnglishFormatStrong('in the last ' + s)}`);
    if (p === "TODAY") return terms.push("published today (" + moment().format(dateFormat) + ")");

    const a = p.split("-");
    if (a.length !== 2) throw "Unhandled published [" + p + "]";

    let before = moment(a[1], 'YYYY-MM-DD HH:mm');
    let after = moment(a[0], 'YYYY-MM-DD HH:mm');

    let noTime = after.hours() === 0 && after.minutes() === 0 && before.hours() === 0 && before.minutes() === 0;
    if (before.dayOfYear() === after.dayOfYear() && before.year() === after.year()) { // start+end on same day
        let on = "published on " + before.format(dateFormat);
        if (!noTime) on += " between " + after.format("HH:mm") + " and " + before.clone().add(1, 'minute').format("HH:mm");
        terms.push(toEnglishFormatStrong(on));
    } else {
        const format = noTime ? dateFormat : dateFormatHourly;
        terms.push(toEnglishFormatStrong("published between " + after.format(format) + " and " + before.format(format)));
    }
}

export function doMedia(attrs, terms) {
    const idString = removeFromTerms(attrs, 'media');
    if (!idString) return;

    const ids = splitAtSpaces(idString);
    const all = new Set(["CONSUMER", "PRESS", "ENTERPRISE", "UNKNOWN", "DIRECTORY"]);
    if (ids.length === all.size - 1) {
        const notPresent = new Set(all);
        ids.forEach(id => notPresent.delete(id));
        if (notPresent.size === 1) {
            const single = notPresent.values().next().value;
            switch (single.toUpperCase()) {
                case "UNKNOWN":
                    terms.push(`with a ${toEnglishFormatStrong("known category")}`); return;
                default:
                    terms.push(toEnglishFormatStrong(`excluding ${capitalise(single)}`)); return;
            }
        }
    }

    terms.push("where category is " + toEnglishCommas(ids.map(id => toEnglishFormatStrong(capitalise(id))), " or "));
}

export function doConduct(attrs, terms) {
    let conductIds = removeFromTerms(attrs,"conduct");
    if (!conductIds) return;
    const outcomes = getMarketConductOutcomeListsOnBrands();

    const getName = function(id) {
        const segment = getSegment(id);
        if (!segment) return toEnglishFormatStrong(id);
        return toEnglishFormatStrong(segment.name);
    };

    let conjunction = ' or ';
    if (conductIds) {
        conjunction = conductIds[0] === '&' ? ' and ' : ' or ';
        conductIds = splitAtSpaces(conductIds).map(id => parseInt(id));
        const isNone = conductIds.length === 1 && getSegment(conductIds[0]).flag === "NONE_OF_THE_ABOVE";
        const noneParent = isNone
            ? outcomes.find(outcome => outcome.children.some(child => conductIds[0] === child.id))
            : null;

        if (conductIds.length === 1 && outcomes.some(outcome => outcome.id === conductIds[0])) {
            const conduct = getSegment(conductIds[0])
            terms.push("relating to your " + toEnglishFormatStrong(conduct.name));
        } else if (isNone) {
            const parent = noneParent ?? { name: "Conduct"};
            terms.push("unrelated to your " + toEnglishFormatStrong(parent.name));
        } else {
            const includeConduct = conductIds
                .filter(function(id) { return id > 0 })
                .map(getName);

            const excludeConduct = conductIds
                .filter(function(id) { return id < 0 })
                .map(getName);

            if (includeConduct.length) {
                terms.push("with Conduct tags " + toEnglishCommas(includeConduct, conjunction));
            }
            if (excludeConduct.length) {
                terms.push("excluding Conduct tags " + toEnglishCommas(excludeConduct, ' and '));
            }
        }
    }
}

export function doRpcs(attrs, terms) {
    let ids = removeFromTerms(attrs, 'rpcs');
    if (!ids) return;

    // Lets see if we're handling any special tags.
    ids = splitAtSpaces(ids);
    let rpcs = [];
    let and = ids[0] === "&";
    ids = ids.map(function(id) { return parseInt(id) });


    let includeIds = ids.filter(function(id) { return id > 0 });
    let excludeIds = ids.filter(function(id) { return id < 0 }).map(Math.abs);

    let getName = function(id) {
        switch (id) {
            case 1: return "<be-rpcs-icon full code='RISK'></be-rpcs-icon>";
            case 2: return "<be-rpcs-icon full code='PURCHASE'></be-rpcs-icon>";
            case 3: return "<be-rpcs-icon full code='CANCEL'></be-rpcs-icon>";
            case 4: return "<be-rpcs-icon full code='SERVICE'></be-rpcs-icon>";
            default:
                return "«rpcs:" + id + "»"
        }
    };

    let includeTerms = includeIds.map(getName);
    let excludeTerms = excludeIds.map(getName);

    if (includeTerms.length) {
        if (and) terms.push("tagged with " + includeTerms.join(''));                     // tagged with RISK PURCHASE CANCEL
        else {
            terms.push("tagged with " + toEnglishCommas(includeTerms, ' or '))      // tagged with RISK, PURCHASE or CANCEL
        }
    }

    if (excludeTerms.length) {
        let prefix = includeTerms.length ? "excluding " : "not tagged with ";
        terms.push(prefix + toEnglishCommas(excludeTerms, ' and '));
    }
}



export function doSentiment(attrs, terms) {
    const ids = removeFromTerms(attrs, 'sentiment');
    if (!ids) return;
    let a = splitAtSpaces(ids);
    let pos, neg, neutral;
    for (let i = 0; i < a.length; i++) {
        let v = parseInt(a[i]);
        if (v < 0) neg = true;
        else if (v > 1) pos = true;
        else if (v === 1) neutral = true;
    }

    const list = [];
    if (neg) list.push("<i class='icon-circle negative-sentiment'></i>negative");
    if (neutral) list.push("<i class='icon-circle neutral-sentiment'></i>neutral");
    if (pos) list.push("<i class='icon-circle positive-sentiment'></i>positive");
    terms.push("with " + toEnglishCommas(list, " or ") + " sentiment");
}

export function doV4ContentMatches(a, terms, prefix) {
    if (!a) return;
    terms.push(prefix + " " + toEnglishCommas(a.map(val => toEnglishFormatStrong(val)), " or "));
}

export function doIdList(attrs, terms) {
    const ids = removeFromTerms(attrs, 'idList');
    if (!ids) return;
    terms.push(ids.annotation ? ids.annotation : "with ID in " + ids.list);
}

export function doTopics(attrs, terms) {
    const ids = removeFromTerms(attrs, 'topics');
    if (!ids) return;
    const idToTags = VuexStore.getters.idToTag;
    function lookup(id) {
        let s = idToTags?.get(parseInt(id));
        return s && s.name || `«Unknown topic:${id}»`;
    }
    doSyncAttr(ids, terms, "topic is", lookup);
}

export function doTags(attrs, terms) {
    let ids = removeFromTerms(attrs, 'tags');
    if (!ids) return;

    // Lets see if we're handling any special tags.
    let split = splitAtSpaces(ids);
    let termsToAdd = [];
    let filteredOut = [];
    let rpcs = [];

    split.forEach(function(s) {
        if (Math.abs(parseInt(s)) === 100) {
            termsToAdd.push(s.startsWith('-') ? "no Topics" : "Topics");
        } else if (Math.abs(parseInt(s)) === 200) {
            termsToAdd.push(s.startsWith('-') ? "not ticketed in Engage" : "ticketed in Engage");
        } else  if (Math.abs(parseInt(s)) === 1) {
            if (s.startsWith('-')) termsToAdd.push("without Risk");
            else rpcs.push("RISK");
        } else  if (Math.abs(parseInt(s)) === 2) {
            if (s.startsWith('-')) termsToAdd.push("without Purchase");
            else rpcs.push("PURCHASE");
        } else  if (Math.abs(parseInt(s)) === 3) {
            if (s.startsWith('-')) termsToAdd.push("without Cancel");
            else rpcs.push("CANCEL");
        } else  if (Math.abs(parseInt(s)) === 4) {
            if (s.startsWith('-')) termsToAdd.push("without Service");
            else rpcs.push("SERVICE");
        } else {
            filteredOut.push(s);
        }
    });

    if (rpcs.length) {
        // Let's ensure that the RPCS tags are rendered next to each other,
        // in RPCS priorioty.
        termsToAdd.push(rpcs
            .sort(function(lhs, rhs) {
                return codeToOrdinal(rhs) - codeToOrdinal(lhs);
            })
            .map(function(s) { return "<be-rpcs-icon full code='" + s + "'></be-rpcs-icon>"; })
            .join(''));
    }

    if (termsToAdd.length) termsToAdd.forEach(function(t, i) {
        terms.push(i === 0 ? "with " + t : t)
    });
    ids = filteredOut.join(' ');
    if (!ids || ids.length === 1 && ids[0] === '&') return;

    const idToTags = VuexStore.getters.idToTag;
    function lookup(id) {
        let s = idToTags?.get(parseInt(id));
        return s && s.name || `«Unknown tag:${id}»`;
    }

    doSyncAttr(ids, terms, "tag is", lookup);
}

export function doInteractionId(attrs, terms) {
    const idString = removeFromTerms(attrs, 'interactionId');
    const inString = removeFromTerms(attrs, 'interactionIdList');

    if (!idString && !inString?.list) return;

    if (isUnknown(idString)) {
        terms.push(toEnglishFormatStrong("not in an interaction"));
    } else {
        terms.push("in interaction " + toEnglishFormatStrong(idString));
    }

    if (inString?.list?.length) {
        terms.push("in interactions with " + toEnglishCommas(inString.list.split(',').map(id => toEnglishFormatStrong(removeQuotes(id))), ' and '))
    }
}

export function doInteractionHasResponse(value, terms) {
    if (!value) return;
    if (value === "true") return terms.push("for interactions that " + toEnglishFormatStrong("have a response"));
    if (value === "false") return terms.push("for interactions that " + toEnglishFormatStrong("do not have a response"));
    doSyncAttr(value, terms, "for interactions that", id => id);
}

export function doReshareOfOrReplyTo(ids, terms, reshares, resharesOf) {
    if (!ids) return;
    if (ids === "-unknown") return terms.push("that " + toEnglishFormatStrong("are " + reshares));
    if (ids === "unknown") return terms.push("that are " + toEnglishFormatStrong("not " + reshares));
    doSyncAttr(ids, terms, "that are " + resharesOf + " ", id => id);
}



// -----------------

export function doSyncAttr(ids, terms, prefix, lookupFn, noStrong) {
    const highlight = (val) => noStrong ? val : toEnglishFormatStrong(val);
    if (!ids) return;
    let a = splitAtSpaces(ids);
    let pos = [], neg = [];
    let and = a.length > 1 && a[0] === "&";
    for (let i = and ? 1 : 0; i < a.length; i++) {
        let id = a[i];
        let not = id.charAt(0) === '-';
        if (not) id = id.substring(1);
        (not ? neg : pos).push(highlight(lookupFn(id) || id));
    }
    if (pos.length > 0) {
        terms.push(prefix + " " + toEnglishCommas(pos, and ? " and " : " or ") +
            (neg.length > 0 ? " and not " + prefix + " " + toEnglishCommas(neg, " and ") : ""));
    } else {
        terms.push("not " + prefix + " " + toEnglishCommas(neg, " and "));
    }
}

export function toEnglishImpl(filter, cache, accountCode, callback, options) {
    if (!filter) return callback("Mentions (including deleted)");
    if (!options) options = { }

    var node = parseFilterString(filter);
    var attrs = convertExpToAttrs(node, {noPublished: true, partial: options.partial});
    if (attrs.errors) return callback(filter ? filter.toString() : filter);

    let index = 1;
    var cb = function() {
        // With out new data lookup and prefetching of brands, the callback may be called
        // immediately, causing its correctly filled data to be overwritten by the original toEnglishImpl
        // finally being completed after this callback. To fix this, we essentially mimic the delay of a promise.
        setTimeout(() => toEnglish(filter, callback, cache, options), 1);
    };

    const verifiedTrash =  {
        "ALL ISNT_IRRELEVANT": "Mentions",
        "ALL IRRELEVANT": "Deleted mentions",
        "ALL ALL": "All mentions (including deleted)",
        "VERIFIED ISNT_IRRELEVANT": "Human-verified mentions",
        "VERIFIED IRRELEVANT": "Deleted human-verified mentions",
        "VERIFIED ALL": "Human-verified mentions (including deleted)",
        "NOT_VERIFIED ISNT_IRRELEVANT": "Non-verified mentions",
        "NOT_VERIFIED IRRELEVANT": "Deleted non-verified mentions",
        "NOT_VERIFIED ALL": "Non-verified mentions (including deleted)"
    };

    let terms = [];
    const identityFn = id => id;
    const formatSeconds = v => originalFormatSeconds(parseInt(v));
    const languageLookup = (id) => isUnknown(id) ? "an unknown language" : LANGUAGES[id];
    const visibilityLookup = (id) => id.toLowerCase().replace('_', ' ');
    const linkLookup = (id) => {
        if(id && id.indexOf('token_') === 0) {
            return encloseInDisplayQuotes(id.substring('token_'.length).replace("_", " "));
        }
        return id;
    };


    doPublished(attrs, terms);
    doSocialNetwork(attrs, terms);
    doBrand(attrs, terms);
    doSentiment(attrs, terms);
    doRpcs(attrs, terms);
    doCx(attrs, terms, accountCode, cache, cb);
    doRisk(attrs, terms, accountCode, cache, cb);
    doConduct(attrs, terms, accountCode, cache, cb);
    doSegments(attrs, terms, accountCode, cache, cb);
    doInteractsWith(removeFromTerms(attrs, 'interactsWith'), terms, accountCode, cache, cb, options);
    doHasReplyFromProfile(removeFromTerms(attrs, 'hasReplyFromProfile'), terms, accountCode, cache, cb, options);
    doLocation(attrs, terms, cb);
    doSyncAttr(removeFromTerms(attrs, 'language'), terms, "in", languageLookup);
    doSyncAttr(removeFromTerms(attrs, 'link'), terms, "from", linkLookup);
    // todo site is not extracted by BasicFilter
    doMedia(attrs, terms);
    doSyncAttr(removeFromTerms(attrs, 'visibility'), terms, "where visibility is", visibilityLookup);
    // We ignore these
    removeFromTerms(attrs, 'gender');
    removeFromTerms(attrs, 'race');

    doV4ContentMatches(removeFromTerms(attrs, 'content'), terms, "matching the text");

    doV4ContentMatches(removeFromTerms(attrs, 'authorBio'), terms, "author bio matching");

    doIdList(attrs, terms);
    doSyncAttr(removeFromTerms(attrs, 'id'), terms, "with ID", identityFn);
    doSyncAttr(removeFromTerms(attrs, 'conversationId'), terms, "in conversation", identityFn);
    doInteractionId(attrs, terms);
    doReshareOfOrReplyTo(removeFromTerms(attrs, 'reshareOf'), terms, "reshares", "reshares of");
    doReshareOfOrReplyTo(removeFromTerms(attrs, 'replyTo'), terms, "replies", "replies to");
    doInteractionHasResponse(removeFromTerms(attrs, 'interactionHasResponse'), terms);
    doSyncAttr(removeFromTerms(attrs, 'replyTo'), terms, "that are replying to", identityFn);
    doSyncAttr(removeFromTerms(attrs, 'author'), terms, "author contains", identityFn);
    doSyncAttr(removeFromTerms(attrs, 'credibility'), terms, "credibility is", identityFn);
    doSyncAttr(removeFromTerms(attrs, 'unbiasedSample'), terms, "unbiasedSample is", identityFn);
    doSyncAttr(removeFromTerms(attrs, 'crowdVerified'), terms, "crowdVerified is", identityFn);
    doIntegerRange(removeFromTerms(attrs, 'ots'), terms, "with OTS");
    doIntegerRange(removeFromTerms(attrs, 'engagement'), terms, "with engagement");
    doIntegerRange(removeFromTerms(attrs, 'reach'), terms, "with reach");
    doIntegerRange(removeFromTerms(attrs, 'replyCount'), terms, "with reply count");
    doIntegerRange(removeFromTerms(attrs, 'reshareCount'), terms, "with reshare count");
    doIntegerRange(removeFromTerms(attrs, 'responseTime'), terms, "with response time", formatSeconds);
    doIntegerRange(removeFromTerms(attrs, 'interactionResponseTime'), terms, "with interaction response time", formatSeconds);
    doIntegerRange(removeFromTerms(attrs, 'interactionFirstResponseTime'), terms, "with first response time", formatSeconds);
    doIntegerRange(removeFromTerms(attrs, 'interactionFollowUpResponseTime'), terms, "with follow-up response time", formatSeconds);
    doIntegerRange(removeFromTerms(attrs, 'interactionWhResponseTime'), terms, "with interaction response time (working hours)", formatSeconds);
    doIntegerRange(removeFromTerms(attrs, 'interactionWhFirstResponseTime'), terms, "with first response time (working hours)", formatSeconds);
    doIntegerRange(removeFromTerms(attrs, 'interactionWhFollowUpResponseTime'), terms, "with follow-up response time (working hours)", formatSeconds);
    doInteger(removeFromTerms(attrs, 'replyOrderByAuthor'), terms, " with reply order by author ", identityFn);
    doTags(attrs, terms);
    doTopics(attrs, terms);
    doPhrase(attrs, terms, accountCode, cache, cb);
    doEngageTicket(attrs, terms);
    doAuthorLocation(attrs, terms);

    let verification = removeFromTerms(attrs, 'verification')
    let trash = removeFromTerms(attrs, 'trash')
    let prefix
    if (options.partial) {
        let pv, pt
        if (verification === "VERIFIED") pv = "human-verified"
        else if (verification === "NOT_VERIFIED") pv = "non-verified"
        else if (verification) throw "Unhandled verification [" + verification + "]"
        if (trash === "ISNT_IRRELEVANT") pt = "non-deleted"
        else if (trash === "IRRELEVANT") pt = "deleted"
        else if (trash) throw "Unhandled trash [" + trash + "]"
        if (pv && pt) prefix = pv + ", " + pt
        else if (pv) prefix = pv
        else if (pt) prefix = pt
        if (prefix) prefix = capitalise((prefix + " mentions"))
        else prefix = "Mentions"
    } else {
        var tv = verification + " " + trash;
        prefix = verifiedTrash[tv];
        if (!prefix) throw "Unhandled verification and trash combo [" + tv + "]";
    }

    removeFromTerms(attrs, 'seed');
    var proportion = removeFromTerms(attrs, 'proportion');
    if (proportion !== undefined) {
        var p = parseFloat(proportion);
        if (!Number.isFinite(p)) throw "Invalid proportion [" + proportion + "]";
        prefix = formatNumber(p, 1) + "% of " + prefix.substr(0, 1).toLowerCase() + prefix.substr(1);
    }

    doToEnglishAuthorId(attrs, terms);

    // this shouldn't happen .. if BasicFilter extracts the attrs there should be code here to deal with them
    if (Object.keys(attrs).length) throw "Unhandled attributes " + JSON.stringify(attrs);

    callback((prefix + " " + terms.join(", ")).toString());
};
