import _ from 'underscore';
import {isFunction} from "@/app/utils/Util";
import {encloseInQuotes, removeQuotes, splitAtSpaces} from "@/app/utils/StringUtils";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

/**
 * Base class for views designed to be displayed in a popup that allow a user to select from one or more options
 * displayed by a template. The selected options are available space separated via getSelection/setSelection.
 * Fires a change event when the selection is changed. If the template contains table rows then clicking them
 * will select the matching option in that row (if any).
 *
 * Look at AutoCompletePicker, if you would like your picker to autocomplete from a set of available options.
 *
 * A picker is made up of two main portions: a portion for displaying the selected tags on a display (such as
 * on the basic filter panel), and another for displaying them in the selection popup (such as the popup seen when
 * choosing a tag).
 *
 * For dealing with the rendering of tags, see the createTagConverter method below.
 *
 * For dealing with popup rendering, you should see AutoCompletePicker.
 *
 * Interesting methods in both case to provide and / or override include getName and nameFormatter.
 *
 * TagPicker is likely a great example picker to look at.
 */
Beef.module("Picker").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({
        modelEvents: {
            "change": "change"
        },

        change: function() {
            this.trigger('change', this.getSelection());
        },

        events: {
            "click tbody tr": "rowClick",
            "mousedown": "mousedown",
            "click": "click",
            "keyup": "keyup",
            "click .checkbox": "newSelectionClicked"
        },

        newSelectionClicked: function(ev) {
            if (this.options.onlyOne) {
                let v = $(ev.target).attr('name')
                this.$el.find('.checkbox input').each(function() {
                    let $cb = $(this);
                    $cb.attr('checked', $cb.attr('name') === v)
                })
                for (var a in this.items) {
                    this.model.attributes[a] = false;
                }
            }
        },

        rowClick: function(ev) {
            var $target = $(ev.target);
            if(this.options.onlyOne) {
                this.$el.find('input').attr('checked',false);
                $target.attr('checked', true);
                for (var a in this.items) {
                    this.model.attributes[a] = false;
                }
            }
            if (!$target.is("input")) {
                var v = $("input", $target.closest("tr")).attr('name');
                if (v) this.model.set(v, !this.model.get(v));
            }
        },

        mousedown: function(ev) {
            ev.preventDefault();    // this stops stuff on the picker from getting focus
        },

        click: function() {     // so 'subclasses' can easily pickup clicks
        },

        keyup: function() {     // so 'subclasses' can easily pickup keyups
        },

        /** Values can be an array or space separated string. */
        setSelection: function(values) {
            if (!Array.isArray(values)) values = values ? values.toString().split(" ") : [];
            var attrs = {};
            for (var i = 0; i < values.length; i++) {
                var v = values[i];
                if (v && v.length > 0) attrs[v] = true;
            }
            this.model.clear({silent: true});
            this.model.set(attrs);
        },

        /** Get selected values as an array. */
        getSelection: function() {
            var sel = [];
            for (var a in this.items) {
                if (this.model.attributes[a]) sel.push(a);
            }
            return sel;
        },

        onPopupHidden: function() {
            // a nested popup has closed so check if our window still has focus and close us if not
            if (!document.hasFocus() && this.parentPopup) this.parentPopup.hide();
        }
    });


    this.createTagConverter = createTagConverter;

    /**
     * Attach a picker to a view attribute identified by selector. Updates attribute in the view's model.
     * @param options A map of options whose items will be added to the picker when the picker is initialised.
     */
    this.attachPicker = function(view, selector, attribute, pickerClass, options) {
        var $f = $(selector, view.$el);
        var ctx = {
            view: view,
            selector: selector,
            attribute: attribute,
            pickerClass: pickerClass,
            splitter: options && options.splitter || splitAtSpaces,
            combiner: options && options.combiner || arrayToString,
            options: options || {}
        };
        $f.on("click", function(ev) { onFieldClick(ev, ctx) });
        $f.on("keydown", function(ev) { onFieldKeyDown(ev, ctx) });
        $f.on("mouseover", function(ev) { onFieldMouseOver(ev, ctx) });
    };


    var arrayToString = function(a) {
        return a.join(" ");
    };

    var onFieldClick = function(ev, ctx) {
        if ($(ev.target).is(".close")) {
            removeTag(ev, ctx);
        } else {
            activateField(ev, ctx);
        }
    };

    var onFieldMouseOver = function(ev, ctx) {
        if (ctx.options.mouseover) {
            var node = $(ev.target).closest('.tag');
            var tag = node.attr('data-value');
            if (tag) ctx.options.mouseover(tag, node);
        }
    };

    var onFieldKeyDown = function(ev, ctx) {
        if (ev.keyCode == 32) {
            ev.preventDefault();
            ev.stopPropagation();
            activateField(ev, ctx);
        }
    };

    var activateField = function(ev, ctx) {
        if ($(ev.target).closest(".tag-input").hasClass("readonly")) return

        var view = ctx.view;
        var picker = new ctx.pickerClass(Object.assign({view: view, model: new Backbone.Model()},
                                                  ctx.options ? ctx.options : {}));
        if (isFunction(ctx.options.binder)) {
            ctx.options.binder(picker, ctx, ev, showInPopup);
        } else {
            picker.on('change', function(sel) {
                view.model.set(ctx.attribute, sel.join(" "));
                if (view.popup) view.popup.move();
            });
            picker.setSelection(view.model.get(ctx.attribute));
            picker.model.set('onlyOne', ctx.options.onlyOne);
            var target = $(ev.target).closest(".tag-input");
            if (target.length == 0) {
                // when the placeholders are clicked somehow they don't have a parent?
                target = $(ev.currentTarget).closest(".tag-input");
                if (target.length == 0) console.log("still no target");
            }
            showInPopup(view, picker, target);
        }
    };

    var removeTag = function(ev, ctx) {
        Beef.Tooltip.close();
        var view = ctx.view;
        var attribute = ctx.attribute;

            if (ctx.options.forceTagRemove) {
                view.model.set(attribute, null);
            }
            else {
                var tag = $(ev.target).closest('span').attr('data-value');
                var value = view.model.get(attribute);
                if (value && value.toString().trim().length > 0) {
                    var a = ctx.options.valueIsArray ? value.slice() : ctx.splitter(value.toString().trim());
                    if (ctx.options.alwaysOne && a.length <= 1) return;

                    var i = a.indexOf(tag);
                    if (i >= 0) {
                        a.splice(i, 1);
                        view.model.set(attribute, ctx.options.valueIsArray ? a : ctx.combiner(a));
                    }
                }
            }

        ev.stopPropagation();
        if (view.popup && view.popup.$el.is(":visible")) view.popup.move();
    };

    var showInPopup = function(view, picker, target, restoreFocusTarget) {
        if (!restoreFocusTarget) restoreFocusTarget = target;

        if (view.popup) {
            view.popup.hide();
            view.popup = null;
        }

        function restoreState() {
            if (picker.restoreFocusOnClose) restoreFocusTarget.focus();
        }

        view.popup = new Beef.Popup.View({ closeOnHide: true });
        view.popup.setVisible(picker.shouldPopupBeVisible == undefined || picker.shouldPopupBeVisible());
        view.popup.setTarget(target);
        view.popup.show(picker);
        view.popup.on("close", restoreState);
    };

    /**
     * Attach a picker with text input to a view attribute identified by selector. Updates attribute in the view's
     * model. If quoteCodes is not false then newly added items are put in quotes using quoteCodes as the character.
     *
     * <p>
     * Supported options include:
     * <ul>
     *   <li>onlyOne: only one option may be selected at a time.
     * </ul>
     */
    this.attachInputPicker = function(view, selector, attribute, pickerClass, options) {
        var $f = $(selector, view.$el);
        var $input = $("input", $f);
        var ctx = {
            view: view,
            selector: selector,
            attribute: attribute,
            pickerClass: pickerClass,
            splitter: options && options.splitter || splitAtSpaces,
            combiner: options && options.combiner || arrayToString,
            options: options || {}
        };
        $f.on("click", function(ev) { onInputFieldClick(ev, ctx) });
        $f.on("mouseover", function(ev) { onFieldMouseOver(ev, ctx) });
        $input.on("focus", function(ev) { onInputFocus(ev, ctx) });
        //$input.on("blur", function(ev) { onInputFocus(ev, ctx) });
        $input.on("keydown", function(ev) { onKeyDown(ev, ctx) });
    };

    var onInputFieldClick = function(ev, ctx) {
        var $target = $(ev.target);
        if ($target.is(".close")) {
            removeTag(ev, ctx);
        } else if ($target.is("input")) {
            activatePicker(ev, ctx);
        } else {
            setTimeout(function(){ $("input", $target.closest(".tag-input")).focus() });
        }
    };

    var onInputFocus = function(ev, ctx) {
        var view = ctx.view;
        var on = ev.type == "focus";
        $(ctx.selector, view.$el).toggleClass("focus", on);
        if (on) {
            activatePicker(ev, ctx);
        } else {
            Beef.Popup.closePopups(view);
        }
    };

    /**
     * Does work in setting up the picker, including attaching listening events for when a picker's value
     * changes. This is the 'change' event. When this event is received, it sets the attributes value.
     */
    var activatePicker = function(ev, ctx) {
        var view = ctx.view;
        var $input = $("input", $(ev.target).closest(".tag-input"));

        // don't do anything if a picker is already active on our input element
        var cv;
        if (view.popup && view.popup.contents && (cv = view.popup.contents.currentView) && $input.is(cv.options.input)) {
            return;
        }

        var picker = new ctx.pickerClass({view: view, input: $input, model: new Backbone.Model(), options: ctx.options});
        picker.on('change', function(code) {
            if (!code || !code.length || code === "''") return;
            if (ctx.options.cleaningFunction) code = ctx.options.cleaningFunction(code);
            if (ctx.options.quoteCodes) code = encloseInQuotes(code, ctx.options.quoteCodes);

                var v = view.model.get(ctx.attribute);
                if (ctx.options.valueIsArray) {
                    if (ctx.options.onlyOne) {
                        v = [code];
                    } else {
                        v = v ? v.slice() : [];
                        if (v.indexOf(code) < 0) v.push(code);
                    }
                } else {
                    if (!v || ctx.options.onlyOne) v = code;
                    else if ((" " + v + " ").indexOf(" " + code + " ") < 0) v = v + " " + code;
                }
                view.model.set(ctx.attribute, v);
            if (view.popup) {
                view.popup.move();
                view.popup.setVisible(picker.shouldPopupBeVisible == undefined || picker.shouldPopupBeVisible());
            }
        });
        picker.on('render', function() {
            if (view.popup) {
                view.popup.move();
                view.popup.setVisible(picker.shouldPopupBeVisible == undefined || picker.shouldPopupBeVisible());
            }
        });
        showInPopup(view, picker, $input);
    };

    var onKeyDown = function(ev, ctx) {
        activatePicker(ev, ctx);

        if (ev.keyCode == 8 && $(ev.target).val().length == 0) {
            var view = ctx.view;
            var v = view.model.get(ctx.attribute);
            if (v && v.length > 0) {
                var a = ctx.splitter(v);
                view.model.set(ctx.attribute, a.length > 1 ? ctx.combiner(a.splice(0, a.length - 1)) : "");
                if (view.popup) view.popup.move();
            }
        }
    };

});

