import Vue from "vue";
import Vuex from "vuex";
import {mash} from "@/store/Services";

Vue.use(Vuex);

let refreshPromise = null;

export default {
    namespaced: true,

    state: () => ({
        staff: null
    }),

    mutations: {
        setStaff: (state, value) => state.staff = value
    },

    getters: {
        idToStaff: (state) => {
            if (!state.staff) return new Map();

            const map = new Map();
            for (const staff of state.staff) {
                map.set(staff.id, staff);
            }
            return map
        }
    },

    actions: {
        async refreshStaff({state, commit}, forceRefresh) {
            forceRefresh ??= false;

            if (refreshPromise) return refreshPromise;
            if (state.availableSegments && !forceRefresh) return;

            try {
                refreshPromise = mash.get("/rest/users/staff");
                const res = await refreshPromise;
                await commit('setStaff', res.data);
            } catch(e) {
                console.error(e);
            } finally {
                refreshPromise = null;
            }
        },
    }


};