import {turducken} from "@/store/Services";
import {currentAccountCode, isDevEnvironment} from "@/app/utils/Account";
import {isMashAdmin} from "@/app/Permissions";

export class Summary {
    /** @type String */
    id;

    /** @type String */
    summary;

    /** @type String */
    created;

    /** @type { filter: String } */
    request;

    /**
     * @param {object} object
     * @return {Summary}
     */
    static from(object) {
        return Object.assign(new Summary(), object);
    }
}

export class Citation {
    /** @type String */
    id;
    /** @type String */
    text;
    references;
    /** @type String */
    created;

    static from(object) {
        return Object.assign(new Citation(), object);
    }
}

let ongoingSummaryCalls = [];

/**
 * Request a summary from turducken
 *
 * @param {String} filter
 * @param abortController An optional axios abort controller.
 * @param {String} account Optional account code. Defaults to the current account.
 * @return {Promise<Summary>}
 */
export async function summariseFilter(filter,
                                      abortController = undefined,
                                      account = currentAccountCode()) {

    // --- ATTENTION ---
    // The code below is meant to stop the front end from hitting turducken with many
    // requests all at once (easily possible using http/2 and above).
    // It places all requests in a buffer of promises (called ongoingSummaryCalls),
    // and if that is above a limit in size, it delays making calls until the current
    // calls are complete.

    const CONCURRENT_REQUEST_LIMIT = 3;

    if (ongoingSummaryCalls.length >= CONCURRENT_REQUEST_LIMIT) {
        // Delay until at least one previous call has finished (with or without error),
        // and then try again.
        return Promise.any([...ongoingSummaryCalls])
            .catch(e => console.error((e)))
            .then(() => summariseFilter(filter, abortController, account));
    }

    const promise = summariseFilterImpl(filter, abortController, account);
    ongoingSummaryCalls.push(promise);
    promise.finally(() => {
        // Remove completed calls from the buffer.
        ongoingSummaryCalls = ongoingSummaryCalls.filter(i => i !== promise);
    });

    return promise;
}

window.summariseFilter = summariseFilter;

/**
 * @param filter
 * @param abortController
 * @param account
 * @return {Promise<Summary>}
 */
async function summariseFilterImpl(filter, abortController, account ) {
    const config = {};
    if (abortController) config.signal = abortController.signal;

    try {
        let response = await turducken.post("/rest/summaries", {
            filter: filter,
            account: account
        }, config);

        return Summary.from(response.data);
    } catch (e) {
        if (isDevEnvironment() && isMashAdmin()) {
            return Summary.from({
                id: "dear-dev:turn-on-turducken",
                summary: "Dear dev: please turn on the Turducken service. \n\nBest. \nAnalyse.",
                algorithm: "DEFAULT_TEXT"
            });
        } else {
            throw e;
        }
    }
}

/**
 *
 * @param text {string}
 * @param filter {string}
 * @param abortController
 * @param account {string}
 * @return {Promise<Citation>}
 */
export async function giveCitations(text, filter, abortController = undefined, account = currentAccountCode()) {
    const config = {};
    if (abortController) config.signal = abortController.signal;

    let response = await turducken.post("/rest/citations", {
        filter: filter,
        account: account,
        text
    }, config);

    return Citation.from(response.data);
}

export async function giveCitationsForSummary(summaryId, abortController = undefined) {
    const config = {};
    if (abortController) config.signal = abortController.signal;

    let response = await turducken.get(`/rest/summaries/${summaryId}/citations`, config);
    return Citation.from(response.data);
}

class Entity {
    /** @type {string} */
    label;

    /** @type {integer} */
    start;

    /** @type {integer} */
    end;

    /**
     * @param {object} object
     * @return {Entity}
     */
    static from(object) {
        return Object.assign(new Entity, object);
    }
}

/**
 *
 * @param {string} text
 * @param abortController An axios abort controller.
 * @return {Promise<[Entity]>}
 */
export async function findEntities(text,
                                   abortController = undefined) {
    const config = {};
    if (abortController) config.signal = abortController.signal;

    let response = await turducken.post("/rest/entities", {
        text: text,
        account: currentAccountCode()
    }, config);

    return response.data.entities.map(item => Entity.from(item));
}

class Title {
    /** @type String */
    id;

    /** @type String */
    title;

    /** @type String */
    created;

    /**
     * @param {object} object
     * @return {Title}
     */

    static from(object) {
        return Object.assign(new Title(), object);
    }
}

/**
 * Request a topic title from
 *
 * @param {String} filter
 * @param abortController An optional axios abort controller.
 * @param {String} account Optional account code. Defaults to the current account.
 * @param allowCached
 * @return {Promise<Title>}
 */
export async function getTitle(filter,
                                    abortController = undefined,
                                    account = currentAccountCode(),
                                    allowCached = true) {
    const config = {};
    if (abortController) config.signal = abortController.signal;

    let response = await turducken.post("/rest/titles", {
        filter: filter,
        account: account,
        allowCached: allowCached
    }, config);

    return Title.from(response.data);
}