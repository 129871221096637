<template>
    <div class="deq-reset" tabindex="-1" ref="picker">
        <div class="brand-picker__grid" :data-has-competitors="!!competitorBrands.length"
             :data-hide-competitor-children="!competitorsHaveChildren || !showChildren" tabindex="-1">

            <bp-brand-group v-if="ownBrands.length"
                            :brands="ownBrands"
                            :search-text="searchText"
                            class="brand-picker__group"
                            @selected="selectedHandler($event)"
                            @negate="negateHandler($event)"
                            :exclude-children="excludeChildren"
                            :allow-exclude-root="allowExcludeRoot"
                            :show-children="showChildren">
                Your brands
            </bp-brand-group>

            <bp-brand-group v-if="competitorBrands.length"
                            class="brand-picker__group brand-picker__competitors"
                            :brands="competitorBrands"
                            :search-text="searchText"
                            @selected="selectedHandler($event)"
                            @negate="negateHandler($event)"
                            :exclude-children="excludeChildren"
                            :allow-exclude-root="allowExcludeRoot"
                            :show-children="showChildren">
                Your competitors
            </bp-brand-group>

            <bp-brand-group v-if="otherBrands.length"
                            class="brand-picker__group"
                            :brands="otherBrands"
                            :search-text="searchText"
                            @selected="selectedHandler($event)"
                            @negate="negateHandler($event)"
                            :is-other="true"
                            :exclude-children="excludeChildren"
                            :allow-exclude-root="allowExcludeRoot"
                            :show-children="showChildren">
                {{ otherBrandsTitle }}
            </bp-brand-group>

            <bp-brand-group v-if="showArchivedBrands && archivedBrands.length"
                            class="brand-picker__group"
                            @selected="selectedHandler($event)"
                            @negate="negateHandler($event)"
                            :brands="archivedBrands"
                            :search-text="searchText"
                            :is-other="true"
                            :exclude-children="excludeChildren"
                            :allow-exclude-root="allowExcludeRoot"
                            :show-children="showChildren">
                Archived brands
            </bp-brand-group>

        </div>
    </div>
</template>

<script>

import VuexStore from "@/store/vuex/VuexStore";
import {formatBrandName} from "@/app/utils/Format";
import BpBrandGroup from "@/components/pickers/brands/BpBrandGroup";
import {
    brandPickerNegate,
    brandPickerSelectChild,
    brandPickerSelectRoot
} from "@/components/pickers/brands/BrandPickerUtilities";

export default {

    store: VuexStore,

    model: {
        prop: 'selected',
        event: 'selected'
    },

    components: {
        BpBrandGroup
    },

    props: {
        // The available brands to show the user
        brands: {
            type: Array,
            required: true
        },
        // The brands that are currently selected
        selected: {
            type: Array,
            default: () => []
        },
        showChildren: {
            type: Boolean,
            default: false
        },
        excludeChildren: {
            type: Boolean,
            default: false
        },
        showArchivedBrands: {
            type: Boolean,
            default: false
        },
        onlyOne: {
            type: Boolean,
            default: false
        },
        allowExcludeRoot: {
            type: Boolean,
            default: false
        },
        searchText: {
            type: String
        }
    },

    data() {
        return {
            availableBrands: [],
            searchTimeout: null
        }
    },

    computed: {
        otherBrandsTitle() {
            if (!this.ownBrands.length && !this.competitorBrands.length) return "Brands";
            return "Other brands";
        },

        undeletedBrands() {
            if (!this.availableBrands?.length) return [];
            return this.availableBrands.filter(b => !b.brand.deleted);
        },

        unarchivedBrands() {
            return this.undeletedBrands.filter(b => !b.brand.archived);
        },

        archivedBrands() {
            return this.undeletedBrands.filter(b => b.brand.archived);
        },

        ownBrands() {
            return this.unarchivedBrands.filter(b => b.brand.category === "OWN");
        },

        competitorBrands() {
            return this.unarchivedBrands.filter(b => b.brand.category === "COMPETITOR");
        },

        otherBrands() {
            return this.unarchivedBrands.filter(b => !b.brand.category);
        },

        competitorsHaveChildren() {
            return this.competitorBrands.some(b => b.children?.length);
        }

    },

    watch: {
        brands() {
            this.setBrands();
        },

        selected() {
            this.updateSelection();
        },

        searchText() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }

            if (this.searchText?.length >= 5 || this.searchText?.length === 0) {
                this.searchTextHandler();
            } else {
                this.searchTimeout = setTimeout(() => this.searchTextHandler(), 500);
            }
        },

        excludeChildren() {
            this.updateSelection();
        }
    },

    created() {
        this.setBrands();
    },

    beforeDestroy() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }
    },

    methods: {
        formatBrandName,

        searchTextHandler() {
            const text = this.searchText?.toLowerCase() ?? "";
            // Handle the quick terminate case.
            if (!text.length) {
                const clear = brand => {
                    brand.visible = true;
                    brand.children?.forEach(clear);
                };
                this.availableBrands.forEach(clear);
                return;
            }

            for (const brand of this.availableBrands) {
                const name = formatBrandName(brand.brand, {markArchiveDuplicates: false}).toLowerCase();
                brand.visible = name.includes(text);

                if (brand.children?.length) {
                    for (const child of brand.children) {
                        const name = formatBrandName(child.brand, {markArchiveDuplicates: false}).toLowerCase();
                        child.visible = name.includes(text);
                        if (child.visible) brand.expanded = true;

                        if (child.children) {
                            for (const grandChild of child.children) {
                                const name = formatBrandName(grandChild.brand, {markArchiveDuplicates: false}).toLowerCase();
                                grandChild.visible = name.includes(text);
                                if (grandChild.visible) {
                                    brand.expanded = true;
                                    child.expanded = true;
                                }
                            }
                        }
                    }
                }
            }
        },

        fireSelected() {
            const collect = b => {
                let results = [];
                if (b.selected) results.push(b);
                if (this.showChildren && b.children?.length) {
                    results = [...results, ...b.children.flatMap(collect)]
                }
                return results;
            };
            const selected = this.availableBrands.flatMap(collect);

            this.$emit('selected', selected);
        },

        updateSelection() {
            if (!this.availableBrands?.length) return;

            const selectedIds = new Set();
            const excludedIds = new Set();
            this.selected?.forEach(b => {
                if (b.selected) selectedIds.add(b.brand.id);
                if (b.excluded) excludedIds.add(b.brand.id);
            });

            for (const brand of this.availableBrands) {
                brand.selected = selectedIds.has(brand.brand.id) || excludedIds.has(brand.brand.id);
                brand.excluded = excludedIds.has(brand.brand.id);
                brand.excludeChildren = this.excludeChildren;
                if (brand.children?.length) {
                    for (const child of brand.children) {
                        child.selected = selectedIds.has(child.brand.id) || excludedIds.has(child.brand.id);
                        child.excluded = excludedIds.has(child.brand.id);

                        if (child.children) {
                            for (const grandChild of child.children) {
                                grandChild.selected = selectedIds.has(grandChild.brand.id) || excludedIds.has(grandChild.brand.id);
                                grandChild.excluded = excludedIds.has(grandChild.brand.id);
                                if (grandChild.selected) child.expanded = true;
                            }
                        }
                    }
                }
            }
        },

        setBrands() {
            const setup = (brand, depth) => {
                const result = {
                    brand,
                    selected: false,
                    excluded: false,
                    visible: true,
                    excludeChildren: this.excludeChildren,
                    indent: depth <= 0 ? null : depth
                };

                if (brand.children) {
                    result.children = brand.children
                        ?.filter(b => !b.deleted)
                        ?.map(c => {
                            const dto = setup(c, depth + 1);
                            dto.parent = result;
                            dto.expanded = false;
                            return dto;
                        })
                }

                return result;
            };

            this.availableBrands = this.brands?.map(b => setup(b, 0)) ?? [];
            this.updateSelection();
        },

        clearAllSelections() {
            this.availableBrands.forEach(b => {
                b.selected = false;
                if (this.showChildren && b.children) b.children.forEach(c => c.selected = false);
            })
        },

        selectRoot(brand, optionalValue) {
            if (this.onlyOne) this.clearAllSelections();
            brandPickerSelectRoot(brand, this.showChildren, optionalValue);
            this.fireSelected();
        },

        selectChild(brand) {
            if (!this.showChildren) return;
            if (this.onlyOne) this.clearAllSelections();

            brandPickerSelectChild(brand);
            this.fireSelected();
        },

        selectedHandler(brand) {
            if (!brand.parent) this.selectRoot(brand);
            else this.selectChild(brand);
        },

        negateHandler(brand) {
            brandPickerNegate(brand);
            this.fireSelected();
        },


        focusFirst() {
            if (this.$refs.picker) {
                const first = this.$refs.picker.querySelector("[tabindex='0']");
                first?.focus();
            }
        }
    }

}
</script>


<style scoped lang="sass">

.brand-picker__grid
    --grid-main: minmax(250px, 1fr) 20px minmax(100px, 250px)
    --final-grid: var(--grid-main)
    display: grid
    grid-template-columns: var(--final-grid)
    --min-height: 30px
    --be-animated-check-background: #333
    --span: 3
    --subgrid-columns: subgrid

[data-has-competitors]
    --final-grid: var(--grid-main) var(--grid-main)
    --span: 6

[data-has-competitors][data-hide-competitor-children]
    --final-grid: var(--grid-main) minmax(100px, 230px) 10px 10px
    --subgrid-columns: var(--grid-main)

.brand-picker__group:nth-of-type(even):last-of-type,
.brand-picker__competitors
    border-left: 5px solid var(--colour-background-black)


</style>