import {deprecatedFetchTags} from "@/data/DeprecatedBeefCache";
import {setTitle} from "@/app/Beef";
import _ from 'underscore';

Beef.module("TopicsSetup").addInitializer(function(startupOptions) {

    /**
     * Display topics in an account
     */
    this.View = Backbone.Marionette.Layout.extend({
        template: require("@/setup/topics/TopicsSetup.handlebars"),

        attributes: { class: "topics-setup" },

        initialize: function() {
            this.model.set({
                loading: true,
                title: 'Topics'
            });
            setTitle('Topics');

            deprecatedFetchTags(this.model.get('accountCode'), function(tags) {

                var topics = [];
                _(Object.values(tags)).each(function(value) {
                    if (value.namespace === 'topic') {
                        topics.push(value);
                    }
                }.bind(this));

                topics.sort(function(lhs, rhs) {
                    lhs = lhs.name.toLowerCase();
                    rhs = rhs.name.toLowerCase();

                    if (lhs < rhs) return -1;
                    if (rhs < lhs) return 1;

                    return 0;
                });

                this.model.set({
                    loading: false,
                    topics: topics,
                    noTopics: topics.length == 0
                });

                console.log(topics);
                console.log(topics.length);
                this.render();

            }.bind(this), this);
        },

        onRender: function() {
            this.$('.topics-table').toggleClass("animated fadeIn", true);
        }


    });



});

