import _ from 'underscore';
import {isFunction} from "@/app/utils/Util";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";

/**
 * Dialog with ok, preview, cancel and delete buttons. Makes a copy of the model on init containing only the attributes
 * being edited. Updates the original model on ok. Triggers onValidated each time validation is done. If you don't
 * need apply or delete then leave them out of the template. Specify attributes edited by the dialog:
 *
 * this.View = Beef.SettingsDialog.View.extend({
 *     template: require("@/dashboards/DashboardSettings.handlebars"),
 *     editAttributes: ['name', 'verifiedDataOnly']
 * });
 *
 * editAttributes may also be a function that returns an array. If editAttributes is undefined or returns null then
 * all attributes currently in the model are editable.
 *
 * You can add help by supplying a field called contextualHelp. See BoundItemView and Help.js for more details.
 * This should be dialog level help, and a help button will automatically be added to the dialog title bar for you.
 */
Beef.module("SettingsDialog").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({
        events: {
            "click .dialog-button-bar .delete": "delete",
            "click .dialog-title .close":   "cancel",
            "click .cancel":                "cancel",
            "click .ok":                    "ok",
            "click .preview":               "preview",
            "keyup":                        "keyup",
            "mousedown":                    "mousedown"
        },

        resolveEditAttributes: function() {
            var ans = isFunction(this.editAttributes) ? this.editAttributes() : this.editAttributes;
            if (!ans) ans = Object.keys(this.model.attributes);
            return ans;
        },

        initialize: function(options) {
            this.originalModel = this.model;
            this.originalAttrs = this.model.getAttrs(this.resolveEditAttributes());
            this.model = new this.model.constructor(this.cloneAttrs(this.originalAttrs));

            if (options && options.cache) this.cache = options.cache;

            // Ensure that we always have the appropriate account code set.
            var accountCode = this.originalModel.getAncestorProperty('accountCode');
            if (accountCode) {
                this.model.accountCode = accountCode;
            }

            // Ensure that we always have the appropriate ID set.
            var id = this.originalModel.get('id');
            if (id) {
                this.model.set('id', id);
            }

            this.model.bind('validated', function(isValid, model, errors) {
                this.updateButtons();
                this.triggerMethod('validated', isValid, model, errors);
            }.bind(this));

            this.model.validate();
        },

        close: function(doNotCancel) {
            if (!this.$el[0].parentElement) return; // already closed
            if (!doNotCancel) {
                if (this.previewDone) {
                    this.originalModel.set(this.originalAttrs);
                    this.previewDone = false;
                }
            }

            Beef.Popup.closePopups(this);

            Beef.BoundItemView.prototype.close.call(this);

            if (isFunction(this.options.onClose)) this.options.onClose(doNotCancel == true, this);
        },

        /** If any entries in attrs need to be deep cloned then do so. */
        cloneAttrs: function(attrs) {
            return attrs;
        },

        updateButtons: function() {
            var valid = this.isValid();
            $('.ok', this.$el).toggleClass('disabled', !valid);
            $('.preview', this.$el).toggleClass('disabled', !valid);
        },

        ok: function() {
            if (this.isValid()) {
                this.okClicked = true;
                var attrs = this.prepareForSave(this.model.getAttrs(this.resolveEditAttributes()));
                this.originalModel.set(attrs);
                this.originalModel.save(null, {
                    error: this.onSaveError.bind(this),
                    success: this.onSaveSuccess.bind(this)
                });
                this.close(true);
                this.trigger("ok", attrs);
                return attrs;
            } else {
                return false;
            }
        },

        prepareForSave: function(attrs) {
            // if the original value was undefined and the new value is undefined it is unchanged so don't set it.
            var ans = {};
            for (var property in attrs) {
                if (attrs.hasOwnProperty(property)) {
                    if (this.originalModel.get(property) === undefined && attrs[property] === undefined) {
                        continue;
                    }
                    ans[property] = attrs[property];
                }
            }
            return ans;
        },

        onSaveError: function(model, xhr, options) {
            if (xhr.responseText) console.error(xhr.responseText, xhr);
            else (console.error(xhr));
            let errorMessage = "Error saving changes .. please retry";
            if (xhr.status === 422) {
                errorMessage = xhr.responseJSON?.error ?? errorMessage;
                if (errorMessage.toLowerCase().startsWith("invalid tag id")) {
                    errorMessage = "There was an error finding the tags in the account";
                }
            } else if (xhr.status === 403) {
                errorMessage = "You do not have enough permissions to update these mentions.";
            }
            notifyUserOfError(errorMessage);
        },

        onSaveSuccess: function(model, xhr, options) {
        },

        preview: function() {
            this.previewDone = true;
            if (this.model.isValid()) {
                this.originalModel.set(this.model.getAttrs(this.resolveEditAttributes()));
            }
        },

        cancel: function() {
            this.close();
        },

        mousedown: function(ev) {
            Beef.Popup.closePopups(this);
        },

        keyup: function(ev) {
            if (ev.keyCode == 13) {
                var t = $(ev.target);
                // trigger a change before checking if we are valid so any changes made are checked
                if (t.is("textarea") || t.is("input")) t.change();
                Beef.Popup.closePopups(this);
                this.ok();
            } else if (ev.keyCode == 27) {
                this.onEscape(ev);
            }
        },

        /**
         * This is a convenience method for you to use in order to tie in with the edit
         * box closing.
         */
        onEscape: function(ev) { },

        delete: function() {
            var entityName = this.originalModel.entityName || this.entityName;
            if (window.confirm("Are you sure you want to delete" + (entityName ? " this " + entityName : "") + "?")) {
                this.onDelete();
                this.originalModel.destroy().then(() => this.onDeleteSuccess());
                this.close(true);
            }
        },

        onDelete: function() {
        },

        onDeleteSuccess: function() {
        },

        onRender: function() {
            this.okClicked = false;
            // defer this check as animations have to be started after we have been added to a region and rendered
            var that = this;
            setTimeout(function(){
                if (that.$el.parent().is(":animated")) {
                    // only set focus when animation is complete or the browser will make sure the focused field
                    // is visible and mess up the effect
                    that.$el.parent().promise().done(function(){ that.initFocus(); })
                } else {
                    that.initFocus();
                }
            });

            // this makes it possible to tab through all the controls in the dialog
            var $last = this.$("dialog-button-bar");
            $last.attr("tabindex", "0");
            $last.on("focus", function(){ this.initFocus() }.bind(this));
        },

        initFocus: function() {
            var $input = $(this.focusSelector ? this.focusSelector : 'input', this.$el);
            if ($input.length > 0) $input[0].focus();
        }
    });

});