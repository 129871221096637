var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"avatar\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stat-box highlight\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"followerCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":25,"column":37},"end":{"line":25,"column":84}}}))
    + "</span> followers\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"followerArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followerDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":27,"column":39},"end":{"line":27,"column":69}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followerPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":27,"column":71},"end":{"line":27,"column":107}}}))
    + ")\n                    <div class=\"follower-area area-graph\"></div>\n                </div>\n            </div>\n\n            <div class=\"stat-box\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"followingCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":33,"column":37},"end":{"line":33,"column":85}}}))
    + "</span> following\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"followingArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followingDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":35,"column":40},"end":{"line":35,"column":71}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followingPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":35,"column":73},"end":{"line":35,"column":110}}}))
    + ")\n                    <div class=\"following-area area-graph\"></div>\n                </div>\n            </div>\n\n            <div class=\"stat-box\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"statusCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":41,"column":37},"end":{"line":41,"column":82}}}))
    + "</span> statuses\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"statusArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"statusDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":43,"column":37},"end":{"line":43,"column":65}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"statusPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":43,"column":67},"end":{"line":43,"column":101}}}))
    + ")\n                    <div class=\"status-area area-graph\"></div>\n                </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stat-box highlight\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"likes") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":51,"column":37},"end":{"line":51,"column":76}}}))
    + "</span> likes\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"likesArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"likesDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":53,"column":63}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"likesPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":53,"column":65},"end":{"line":53,"column":98}}}))
    + ")\n                    <div class=\"likes-area area-graph\"></div>\n                </div>\n            </div>\n\n            <div class=\"stat-box\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"talkingAboutCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":59,"column":37},"end":{"line":59,"column":88}}}))
    + "</span> talking about\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"talkingAboutArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"talkingAboutDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":61,"column":43},"end":{"line":61,"column":77}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"talkingAboutPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":61,"column":79},"end":{"line":61,"column":119}}}))
    + ")\n                    <div class=\"talking-about-area area-graph\"></div>\n                </div>\n            </div>\n\n            <div class=\"stat-box\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"checkins") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":67,"column":37},"end":{"line":67,"column":79}}}))
    + "</span> checkins\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"checkinsArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"checkinsDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":69,"column":39},"end":{"line":69,"column":69}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"checkinsPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":69,"column":71},"end":{"line":69,"column":107}}}))
    + ")\n                    <div class=\"checkins-area area-graph\"></div>\n                </div>\n            </div>\n\n            <div class=\"stat-box\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"wereHereCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":75,"column":37},"end":{"line":75,"column":84}}}))
    + "</span> were here\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"wereHereArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"wereHereDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":77,"column":39},"end":{"line":77,"column":69}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"wereHerePercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":77,"column":71},"end":{"line":77,"column":107}}}))
    + ")\n                    <div class=\"talking-about-area area-graph\"></div>\n                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stat-box\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"subscribers") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":111,"column":37},"end":{"line":111,"column":82}}}))
    + "</span> subscribers\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"subscribersArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribersDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":113,"column":42},"end":{"line":113,"column":75}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribersPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":113,"column":77},"end":{"line":113,"column":116}}}))
    + ")\n                    <div class=\"following-area area-graph\"></div>\n                </div>\n            </div>\n\n            <div class=\"stat-box\">\n                <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"statusCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":119,"column":37},"end":{"line":119,"column":82}}}))
    + "</span> videos\n                <div class=\"under-stats\">\n                    "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"statusArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"statusDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":121,"column":37},"end":{"line":121,"column":65}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"statusPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":121,"column":67},"end":{"line":121,"column":101}}}))
    + ")\n                    <div class=\"status-area area-graph\"></div>\n                </div>\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "between "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"startShort") : depth0), depth0))
    + ", and "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dateShort") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"profile-trow\">\n                    <div class=\"profile-tcell val\" title=\"The advert value equivalent of "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions of "
    + alias2(__default(require("../../../../helpers/formatAccountCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"ave") : depth0),{"name":"formatAccountCurrency","hash":{},"data":data,"loc":{"start":{"line":148,"column":112},"end":{"line":148,"column":141}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"startShort") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":142},"end":{"line":148,"column":208}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias2(__default(require("../../../../helpers/formatAccountCurrency.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"ave") : depth0),{"name":"formatAccountCurrency","hash":{},"data":data,"loc":{"start":{"line":149,"column":24},"end":{"line":149,"column":53}}}))
    + "\n                    </div>\n                    <div class=\"profile-tcell divide\">|</div>\n                    <div class=\"profile-tcell\" title=\"The advert value equivalent of "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions\">AVE</div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"title\">\n    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Open "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s profile in a new tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"pictureLink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"display-name\">\n            <div class=\"name\">\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n            </div>\n            <div class=\"vanity "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"networkIcon") : depth0), depth0))
    + "\">\n                "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "\n            </div>\n        </div>\n        <!--<div class=\"details\">-->\n            <!--<div>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "</div>-->\n            <!--<div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"handleId") : depth0), depth0))
    + "</div>-->\n        <!--</div>-->\n    </a>\n</div>\n\n<div class=\"body\">\n    <div class=\"group1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isTwitter") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isFacebook") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":81,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isInstagram") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":8},"end":{"line":107,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isYouTube") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":8},"end":{"line":125,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"other-groups\">\n        <div class=\"profile-table\">\n            <div class=\"profile-trow\">\n                <div class=\"profile-tcell val\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " published "
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"count") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":131,"column":73},"end":{"line":131,"column":95}}}))
    + " mentions "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"startShort") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":105},"end":{"line":131,"column":171}}})) != null ? stack1 : "")
    + "\">\n                    <a class=\"mentions-count\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mentionsUrl") : depth0), depth0))
    + "\" target=\"_blank\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"count") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":132,"column":85},"end":{"line":132,"column":107}}}))
    + "</a>\n                </div>\n                <div class=\"profile-tcell divide\">|</div>\n                <div class=\"profile-tcell\" title=\"The number of mentions published by this profile\">\n                    <a class=\"mentions-count\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mentionsUrl") : depth0), depth0))
    + "\" target=\"_blank\">Mentions</a>\n                </div>\n            </div>\n            <div class=\"profile-trow\">\n                <div class=\"profile-tcell val\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions produced an opportunity-to-see of "
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"ots") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":140,"column":108},"end":{"line":140,"column":128}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"startShort") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":129},"end":{"line":140,"column":195}}})) != null ? stack1 : "")
    + "\">\n                    "
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"ots") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":141,"column":20},"end":{"line":141,"column":40}}}))
    + "\n                </div>\n                <div class=\"profile-tcell divide\">|</div>\n                <div class=\"profile-tcell\" title=\"The opportunity-to-see generated by "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions\">OTS</div>\n            </div>\n"
    + ((stack1 = __default(require("../../../../helpers/ifShowAVE.js")).call(alias3,{"name":"ifShowAVE","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":12},"end":{"line":154,"column":26}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div style=\"padding-top: 16px;\">Engagement:</div>\n\n        <div class=\"profile-table\">\n            <div class=\"profile-trow\">\n                <div class=\"profile-tcell val\" title=\"There were "
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"replyCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":161,"column":65},"end":{"line":161,"column":92}}}))
    + " replies to "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"startShort") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":124},"end":{"line":161,"column":190}}})) != null ? stack1 : "")
    + "\">\n                    "
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"replyCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":162,"column":20},"end":{"line":162,"column":47}}}))
    + "\n                </div>\n                <div class=\"profile-tcell divide\">|</div>\n                <div class=\"profile-tcell\" title=\"The number of replies to "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions\">Replies</div>\n            </div>\n            <div class=\"profile-trow\">\n                <div class=\"profile-tcell val\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions were reshared "
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"reshareCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":168,"column":88},"end":{"line":168,"column":117}}}))
    + " times "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"startShort") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":124},"end":{"line":168,"column":190}}})) != null ? stack1 : "")
    + "\">\n                    "
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"reshareCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":169,"column":20},"end":{"line":169,"column":49}}}))
    + "\n                </div>\n                <div class=\"profile-tcell divide\">|</div>\n                <div class=\"profile-tcell\" title=\"The number of times that "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s mentions were reshared\">Reshares</div>\n            </div>\n        </div>\n\n    </div>\n\n</div>\n\n";
},"useData":true});