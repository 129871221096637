<template>
    <dialog-box class="edit-working-hours"
                @close="close()"
                :title="title"
                width="650px"
                modal>

        <div class="edit-working-hours__container">
            <p>
                Set the working hours for the brand. You do not need to set working hours for days on which the brand is not working.
            </p>

            <div class="edit-working-hours__copy" v-if="canCopyFromOtherBrands">
                <drop-down-input label="Copy working hours from selected brand" placeholder="Select a brand..." :disabled="saving" v-model="brandToCopyFrom" :options="brandToCopyOptions"></drop-down-input>
            </div>

            <div class="edit-working-hours__working-hours-container">
                <div class="edit-working-hours__working-hours" v-for="day in days" :key="day.value">
                    <div :class="{'warning' : warning && warning.day === day.value}">{{ day.label }}</div>

                    <popup-menu ref="times" bottom :arrow-pointer="{show: true, left: true}" :ignore-activator-click="day.allDay">
                        <template #activator>
                            <tooltip-component>
                                <p class="edit-working-hours__time" :class="{'disabled': day.allDay || saving}" v-if="!day.editStartTime">{{ day.startTime ? timeValueToLabel(day.startTime) : 'Set start time' }} <i class="symbol-edit"></i></p>
                                <template #tooltip>
                                    {{ startTimeTooltip(day) }}
                                </template>
                            </tooltip-component>
                        </template>

                        <section class="edit-working-hours__time-select-list dark-scrollbars dark-scrollbars--visible" style="font-family: var(--number-font-family)">
                            <div v-for="time in startTimeList(day)" :key="time.value" @click="selectStartTime(day, time.value)">
                                {{ time.label }}
                            </div>
                        </section>
                    </popup-menu>

                    <popup-menu ref="times" bottom :arrow-pointer="{show: true, left: true}" :ignore-activator-click="day.allDay || !day.startTime">
                        <template #activator>
                            <tooltip-component>
                                <p class="edit-working-hours__time" :class="{'disabled': day.allDay || !day.startTime || saving}" v-if="!day.editEndTime">{{ day.endTime ? timeValueToLabel(day.endTime) : 'Set end time' }} <i class="symbol-edit"></i></p>
                                <p v-else>
                                    {{ timeValueToLabel(day.endTime) }}
                                </p>
                                <template #tooltip>
                                    {{ endTimeTooltip(day) }}
                                </template>
                            </tooltip-component>
                        </template>

                        <section class="edit-working-hours__time-select-list dark-scrollbars dark-scrollbars--visible" style="font-family: var(--number-font-family)">
                            <div v-for="time in endTimeList(day)" :key="time.value" @click="selectEndTime(day, time.value)">
                                {{ time.label }}
                            </div>
                        </section>
                    </popup-menu>

                    <label class="checkbox" :class="{'disabled': saving}">
                        <input type="checkbox" :disabled="saving" v-model="day.allDay"> 24 hours
                    </label>

                    <be-button link :disabled="saving" @click="resetDay(day)">Unset</be-button>
                </div>
            </div>

            <div class="edit-working-hours__public-holidays">
                <h4>Holiday calendar configuration</h4>
                <div class="toggle-container">
                    <p>Would you like to apply a holiday calendar to this brand?</p>
                    <div class="btn-selector">
                        <be-button :active="!useHolidayCalendar" @click="toggleUseHolidayCalendar()">No</be-button>
                        <be-button :active="useHolidayCalendar" @click="toggleUseHolidayCalendar()">Yes</be-button>
                    </div>
                </div>

                <div v-if="useHolidayCalendar" class="edit-working-hours__calendar-select-container">
                    <drop-down-input class="region-drop-down" label="Calendar region" placeholder="Select a region" :disabled="saving" v-model="selectedRegion" :options="regions"></drop-down-input>
                    <drop-down-input class="calendar-drop-down" label="Calendar" placeholder="Select a holiday calendar" :disabled="saving || !selectedRegion" v-model="holidayCalendarId" :options="regionHolidayCalendars"></drop-down-input>
                </div>
            </div>

            <div class="edit-working-hours__warning-block">
                <p v-if="warning">
                    <i class="symbol-warning"></i> {{ warning.message }}
                </p>
            </div>
        </div>


        <template #buttons>
            <div class="edit-working-hours__btns">
                <be-button link :disabled="saving" @click="close()">Cancel</be-button>
                <be-button primary :disabled="disableSaveBtn" @click="save()"><spinner-component v-if="saving" :size="11"></spinner-component>{{ saveBtnText }}</be-button>
            </div>
        </template>

    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import PopupMenu from "@/components/PopupMenu";
import TooltipComponent from "@/components/tooltip/TooltipComponent";
import DropDownInput from "@/components/inputs/DropDownInput";
import BeButton from "@/components/buttons/BeButton";
import {mash} from "@/store/Services";
import {mapActions, mapGetters, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {notifyUserOfError, notifyWithText} from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent";

export default {
    name: "EditWorkingHours",
    store: VuexStore,
    components: {SpinnerComponent, BeButton, DropDownInput, TooltipComponent, PopupMenu, DialogBox},

    props: {
        brand: {
            type: Object,
            required: true
        }
    },

    data: function() {
        return {
            selectedRegion: null,
            days: [
                {
                    label: "Monday",
                    value: "monday",
                    startTime: null,
                    endTime: null,
                    editStartTime: false,
                    editEndTime: false,
                    allDay: false
                },
                {
                    label: "Tuesday",
                    value: "tuesday",
                    startTime: null,
                    endTime: null,
                    editStartTime: false,
                    editEndTime: false,
                    allDay: false
                },
                {
                    label: "Wednesday",
                    value: "wednesday",
                    startTime: null,
                    endTime: null,
                    editStartTime: false,
                    editEndTime: false,
                    allDay: false
                },
                {
                    label: "Thursday",
                    value: "thursday",
                    startTime: null,
                    endTime: null,
                    editStartTime: false,
                    editEndTime: false,
                    allDay: false
                },
                {
                    label: "Friday",
                    value: "friday",
                    startTime: null,
                    endTime: null,
                    editStartTime: false,
                    editEndTime: false,
                    allDay: false
                },
                {
                    label: "Saturday",
                    value: "saturday",
                    startTime: null,
                    endTime: null,
                    editStartTime: false,
                    editEndTime: false,
                    allDay: false
                },
                {
                    label: "Sunday",
                    value: "sunday",
                    startTime: null,
                    endTime: null,
                    editStartTime: false,
                    editEndTime: false,
                    allDay: false
                }
            ],
            times: [
                {
                    label: "06:00",
                    value: "06:00:00"
                },
                {
                    label: "06:30",
                    value: "06:30:00"
                },
                {
                    label: "07:00",
                    value: "07:00:00"
                },
                {
                    label: "07:30",
                    value: "07:30:00"
                },
                {
                    label: "08:00",
                    value: "08:00:00"
                },
                {
                    label: "08:30",
                    value: "08:30:00"
                },
                {
                    label: "09:00",
                    value: "09:00:00"
                },
                {
                    label: "09:30",
                    value: "09:30:00"
                },
                {
                    label: "10:00",
                    value: "10:00:00"
                },
                {
                    label: "10:30",
                    value: "10:30:00"
                },
                {
                    label: "11:00",
                    value: "11:00:00"
                },
                {
                    label: "11:30",
                    value: "11:30:00"
                },
                {
                    label: "12:00",
                    value: "12:00:00"
                },
                {
                    label: "12:30",
                    value: "12:30:00"
                },
                {
                    label: "13:00",
                    value: "13:00:00"
                },
                {
                    label: "13:30",
                    value: "13:30:00"
                },
                {
                    label: "14:00",
                    value: "14:00:00"
                },
                {
                    label: "14:30",
                    value: "14:30:00"
                },
                {
                    label: "15:00",
                    value: "15:00:00"
                },
                {
                    label: "15:30",
                    value: "15:30:00"
                },
                {
                    label: "16:00",
                    value: "16:00:00"
                },
                {
                    label: "16:30",
                    value: "16:30:00"
                },
                {
                    label: "17:00",
                    value: "17:00:00"
                },
                {
                    label: "17:30",
                    value: "17:30:00"
                },
                {
                    label: "18:00",
                    value: "18:00:00"
                },
                {
                    label: "18:30",
                    value: "18:30:00"
                },
                {
                    label: "19:00",
                    value: "19:00:00"
                },
                {
                    label: "19:30",
                    value: "19:30:00"
                },
                {
                    label: "20:00",
                    value: "20:00:00"
                },
                {
                    label: "20:30",
                    value: "20:30:00"
                },
                {
                    label: "21:00",
                    value: "21:00:00"
                },
                {
                    label: "21:30",
                    value: "21:30:00"
                },
                {
                    label: "22:00",
                    value: "22:00:00"
                },
                {
                    label: "22:30",
                    value: "22:30:00"
                },
                {
                    label: "23:00",
                    value: "23:00:00"
                },
                {
                    label: "23:30",
                    value: "23:30:00"
                },
                {
                    label: "00:00",
                    value: "24:00:00"
                }
            ],
            brandToCopyOptions: [],
            holidayCalendarId: null,
            brandToCopyFrom: -1,
            useHolidayCalendar: false,
            saving: false
        }
    },

    watch: {
        selectedRegion(newValue, oldValue) {
            // ensure that we don't unselect the holidayCalendarId when the dialog is opened
            if (oldValue != null) {
                this.holidayCalendarId = null;
            }
        },

        brandToCopyFrom(brandId) {
            this.days.forEach(day => {
                this.resetDay(day);
            });

            if (brandId === -1) {
                this.setData(this.brand.id);
            } else {
                this.setData(brandId);
            }
        }
    },

    created() {
        this.brandToCopyOptions = [{id: -1, name: 'None'}, ...this.rootBrands.filter(b => b.id !== this.brand.id && this.brandIdToWorkingHours.get(b.id))];

        this.setData(this.brand.id);
    },

    computed: {
        ...mapState(['rootBrands']),

        ...mapState('workingHours', ['holidayCalendars']),

        ...mapGetters('workingHours', ['brandIdToWorkingHours', 'holidayCalendarsRegionMap']),

        title() {
            return `Edit ${this.brand.name}'s working hours`;
        },

        regions() {
            let regions = [];
            if (this.holidayCalendars) {
                for (const holidayCalendar of this.holidayCalendars) {
                    let calendarRegion = holidayCalendar.region;
                    if (calendarRegion) {
                        if (!regions.find(r => r.id === calendarRegion.id)) regions.push(calendarRegion);
                    }
                }
            }

            return regions;
        },

        regionHolidayCalendars() {
            let regionHolidayCalendars = [];
            if (this.selectedRegion) {
                for (const holidayCalendar of this.holidayCalendarsRegionMap.get(this.selectedRegion)) {
                    regionHolidayCalendars.push({
                        id: holidayCalendar.id,
                        name: holidayCalendar.title
                    });
                }
            }
            return regionHolidayCalendars;
        },

        // whether or not the user can copy working hours from other brands
        canCopyFromOtherBrands() {
            return this.brandToCopyOptions?.length > 1;
        },

        saveBtnText() {
            return this.saving ? 'Saving...' : 'Save';
        },

        disableSaveBtn() {
            return this.saving || !!this.warning;
        },

        warning() {
            for (const day of this.days) {
                if (day.startTime && !day.endTime) return {
                    day: day.value,
                    message: `Set an end time for ${day.label}`
                }

                if (day.startTime && day.endTime) {
                    let start = Number(day.startTime.replaceAll(":", ""));
                    let end = Number(day.endTime.replaceAll(":", ""));

                    if (start > end) return {
                        day: day.value,
                        message: `Set a start time that is before the end time for ${day.label}`
                    }
                }
            }

            if (this.useHolidayCalendar) {
                if (!this.selectedRegion || !this.holidayCalendarId) return {
                    message: "Select a holiday calendar for this brand"
                }
            }

            return null;
        }
    },

    methods: {
        ...mapActions('workingHours', ['refreshWorkingHours']),

        toggleUseHolidayCalendar() {
            this.useHolidayCalendar = !this.useHolidayCalendar;

            if (!this.useHolidayCalendar) {
                this.selectedRegion = null;
                this.holidayCalendarId = null;
            }
        },

        setData(brandId) {
            let brandWorkingHours = this.brandIdToWorkingHours.get(brandId);
            if (brandWorkingHours) {
                let workingHours = brandWorkingHours.weekWorkTimes;
                let holidayCalendar = brandWorkingHours.holidayCalendar;

                this.days.forEach(day => {
                    let curDayWorkingHours = workingHours[day.value];
                    if (curDayWorkingHours) {
                        if (curDayWorkingHours.startTime === "00:00:00" && curDayWorkingHours.endTime === "24:00:00") {
                            day.allDay = true;
                        } else {
                            day.startTime = curDayWorkingHours.startTime;
                            day.endTime = curDayWorkingHours.endTime;
                        }
                    }
                });

                if (holidayCalendar) {
                    this.useHolidayCalendar = true;
                    this.selectedRegion = holidayCalendar.regionId;
                    this.holidayCalendarId = holidayCalendar.id;
                }
            }
        },

        startTimeTooltip(day) {
            return day.allDay ? `Unable to edit start time when '24 hours' is checked` : "Click to edit the start time";
        },

        endTimeTooltip(day) {
            if (day.allDay) return `Unable to edit end time when '24 hours' is checked`;

            return !day.startTime ? "First select a starting time" : "Click to edit the end time"
        },

        startTimeList(day) {
            if (!day.endTime) return this.times.filter(t => t.value !== "24:00:00");

            return this.times.filter(t => Number(t.value.replaceAll(":", "")) < Number(day.endTime.replaceAll(":", "") || t.value !== "24:00:00"));
        },

        endTimeList(day) {
            if (!day.startTime) return this.times;

            return this.times.filter(t => Number(t.value.replaceAll(":", "")) > Number(day.startTime.replaceAll(":", "")) || t.value === "24:00:00");
        },

        timeValueToLabel(value) {
            return this.times.find(t => t.value === value)?.label;
        },

        selectStartTime(day, time) {
            day.startTime = time;
        },

        selectEndTime(day, time) {
            day.endTime = time;
        },

        resetDay(day) {
            day.startTime = null;
            day.endTime = null;
            day.allDay = false;
        },

        close() {
            this.$emit('close');
        },

        async save() {
            try {
                this.saving = true;

                let workingHours = {
                    weekWorkTimes: {}
                };

                this.days.forEach(day => {
                    if (day.allDay) {
                        workingHours.weekWorkTimes[day.value] = {
                            startTime: "00:00:00",
                            endTime: "24:00:00"
                        }
                    } else if (day.startTime && day.endTime) {
                        workingHours.weekWorkTimes[day.value] = {
                            startTime: day.startTime,
                            endTime: day.endTime
                        }
                    }
                });

                if (this.useHolidayCalendar) {
                    workingHours["holidayCalendar"] = {
                        id: this.holidayCalendarId
                    }
                }

                await mash.post(`/rest/work-calendars/brands/${this.brand.id}`, workingHours);
                await this.refreshWorkingHours(true);

                this.$emit('update-working-hours', this.brand.id);

                notifyWithText(`${this.brand.name}'s working hours have been updated.`);
            } catch (e) {
                console.warn(`Error when saving working hours for brand ${this.brand.id}: `, e);

                notifyUserOfError("An error occurred while trying to update the brand's working hours. Please try again or contact DataEQ support.");
            } finally {
                this.saving = false;

                this.close();
            }
        }
    }
}
</script>

<style scoped lang="sass">

.edit-working-hours

    --separator-border: 1px solid #9999993b
    --disabled-color: #c0c0c04a

    p
        color: var(--be-colour-text-dark)

    &::v-deep .dialog-body
        padding: 0

    &__container
        padding: 20px

    &__copy
        width: 350px
        margin-top: 30px

    &__working-hours-container
        margin-top: 30px

    &__working-hours
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(10px, 1fr))
        margin-bottom: 10px
        border-bottom: var(--separator-border)

        .warning
            color: var(--be-colour-warning-dark) !important
            cursor: default

        label
            color: var(--be-colour-text-dark)

    &__time
        cursor: pointer
        color: var(--be-colour-text-dark)

        &:hover
            color: var(--be-colour-text-dark__hover)

            .symbol-edit
                transition-property: color, opacity
                transition-duration: var(--transition-duration)
                opacity: 1

        &:not(:hover)
            .symbol-edit
                opacity: 0

        &.disabled
            .symbol-edit
                opacity: 0

    &__time-select-list
        background: #333
        width: 70px
        text-align: center
        max-height: 300px
        overflow-y: auto

        @media (max-height: 800px)
            max-height: 225px

        div
            padding: 2px
            margin-bottom: 2px

            &:hover
                cursor: pointer
                background-color: #222
                color: #aee15d

    &__public-holidays
        height: 100px
        margin-top: 30px
        margin-bottom: 70px

        .region-drop-down
            width: 300px

        .calendar-drop-down
            width: 500px

        .toggle-container

            p
                margin: 0

            display: flex
            column-gap: 10px
            margin-bottom: 20px
            align-items: center

        .btn-selector
            display: flex
            .be-button:not(:last-of-type) ::v-deep .btn
                border-top-right-radius: 0
                border-bottom-right-radius: 0
            .be-button + .be-button
                & ::v-deep .btn
                    border-top-left-radius: 0
                    border-bottom-left-radius: 0

    &__calendar-select-container
        display: flex
        column-gap: 50px
        width: 350px

    &__warning-block
        height: 20px

    &::v-deep .dialog-button-bar
        margin: 0
        padding: 10px
        border-top: var(--border-separation)
        background: var(--background-dialog-buttoncontainer)
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px


    &__btns
        display: flex
        justify-content: flex-end

.disabled
    color: var(--disabled-color) !important
    cursor: default

</style>