<template>
    <add-profile-dialog ref="addProfileDialog"
                        class="fb-add-profile-dialog"
                        :profile-type="profileTypes.facebook"
                        :enable-next-btn="enableNext"
                        :next-btn-text="nextBtnText"
                        :show-progress-bar="!!selectedPageType"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-prev-btn="!saving && !!selectedPageType"
                        :show-next-btn="!saving && !!selectedPageType"
                        @close="$emit('close')"
                        @prev="previous"
                        @next="next">
        <template #workflow>
            <keep-alive>
                <div v-if="checkingFbLoginStatus" class="fb-add-profile-dialog__login-status-check"><spinner-component :size="20"></spinner-component>
                    <h4>Checking if you are logged in through <i class="symbol-facebook-rect"></i> Facebook...</h4>
                </div>
                <fb-login v-if="!checkingFbLoginStatus && !loggedInThroughFacebook" @fb-login-complete="checkFbLoginStatus()"/>

                <fb-select-profile-type v-if="!saving && !selectedPageType && loggedInThroughFacebook && !checkingFbLoginStatus" @select-page="pageTypeSelected"/>

                <!--PUBLIC WORKFLOW-->
                <fb-page-search v-if="!saving && selectedPageType === addProfileTypes.facebook.PUBLIC && curStage === addProfileStages.facebook.public.SEARCH_PAGE"/>

                <edit-public-profile v-if="!saving && selectedPageType === addProfileTypes.facebook.PUBLIC && curStage === addProfileStages.facebook.public.EDIT_PAGE"/>
                <!--PUBLIC WORKFLOW-->

                <!--MANAGED WORKFLOW-->
                <select-managed-profiles :profile-type="profileTypes.facebook" v-if="!saving && selectedPageType === addProfileTypes.facebook.MANAGED && curStage === addProfileStages.facebook.managed.SELECT_PAGES"/>
                <!--MANAGED WORKFLOW-->

                <!--INFLUENCER WORKFLOW-->
                <fb-generate-influencer-links v-if="!saving && selectedPageType === addProfileTypes.facebook.INFLUENCER && curStage === addProfileStages.facebook.influencer.CREATE_LINKS"></fb-generate-influencer-links>
                <!--INFLUENCER WORKFLOW-->

                <saving-item v-if="saving" :saving-message="saveMessage" :save-complete="saveComplete" :save-complete-message="saveCompleteMessage" :save-error="saveError" :save-error-message="saveErrorMessage">
                    <template #additionalActions>
                        <h4 v-if="selectedPageType !== addProfileTypes.facebook.INFLUENCER" style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more <i class="symbol-facebook-rect"></i> Facebook pages</h4>
                        <fb-copy-influencer-links v-else></fb-copy-influencer-links>
                    </template>
                </saving-item>
            </keep-alive>
        </template>
    </add-profile-dialog>
</template>

<script>
import FbSelectProfileType from "./FbSelectProfileType";
import VuexStore from "@/store/vuex/VuexStore";
import EditPublicProfile from "@/setup/profiles/add-profile/EditPublicProfile";
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";
import SelectManagedProfiles from "@/setup/profiles/add-profile/SelectManagedProfiles";
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import FbGenerateInfluencerLinks from "@/setup/profiles/add-profile/facebook/FbGenerateInfluencerLinks";
import FbCopyInfluencerLinks from "@/setup/profiles/add-profile/facebook/FbCopyInfluencerLinks";
import {mapActions, mapState} from "vuex";
import FbLogin from "@/setup/profiles/add-profile/facebook/FbLogin";
import SpinnerComponent from "@/components/SpinnerComponent";
import FbPageSearch from "@/setup/profiles/add-profile/facebook/FbPageSearch";
import SavingItem from "@/setup/SavingItem";


export default {
    name: "FbAddProfileDialog",
    store: VuexStore,
    components: {
        SpinnerComponent,
        SavingItem,
        FbPageSearch,
        FbLogin,
        FbCopyInfluencerLinks,
        FbGenerateInfluencerLinks,
        AddProfileDialog,
        SelectManagedProfiles,
        EditPublicProfile,
        FbSelectProfileType},
    mixins: [addProfileMixins],

    data: function () {
        return {
            loggedInThroughFacebook: false,
            checkingFbLoginStatus: false,
            curStage: 1,
            totalStages: 1,
            selectedPageType: null
        }
    },

    async created() {
        // user must be logged in through FB before they are able to start adding FB pages
        await this.checkFbLoginStatus();
    },

    computed: {
        ...mapState('userPlatformAuth', ['facebookUserToken']),

        enableNext() {
            switch (this.selectedPageType) {
                case this.addProfileTypes.facebook.PUBLIC:
                    // If we are on the edit stage, we only allow saving if the profile has a category set
                    if (this.curStage === this.addProfileStages.facebook.public.EDIT_PAGE) {
                        return !!this.newProfileData.publicProfile?.media
                    }
                    // If we are on the search stage, we only enable next if the profile is not currently in the account (check "existing" field)
                    if (this.curStage === this.addProfileStages.facebook.public.SEARCH_PAGE) {
                        return !this.newProfileData.publicProfile?.existing && !!this.newProfileData.publicProfile;
                    }
                    return !!this.newProfileData.publicProfile;
                case this.addProfileTypes.facebook.MANAGED:
                    return !!this.newProfileData.managedProfiles.profiles?.length;
                case this.addProfileTypes.facebook.INFLUENCER:
                    return this.newProfileData.influencerProfiles.generateLinkCount > 0;
                default:
                    return false;
            }
        },

        nextBtnText() {
            // if we are on the final stage, show "Save" instead of "Next"
            switch (this.selectedPageType) {
                case this.addProfileTypes.facebook.PUBLIC:
                case this.addProfileTypes.facebook.MANAGED:
                    return this.curStage === this.totalStages - 1 ? "Save" : "Next";
                case this.addProfileTypes.facebook.INFLUENCER:
                    return "Generate Links";
                default:
                    return "Next";
            }
        }
    },

    methods: {
        ...mapActions('userPlatformAuth', ['initializeFbUserToken']),

        async checkFbLoginStatus() {
            try {
                this.checkingFbLoginStatus = true;

                await this.initializeFbUserToken();
                this.loggedInThroughFacebook = !!this.facebookUserToken;
            } finally {
                this.checkingFbLoginStatus = false;
            }
        },

        next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                switch (this.selectedPageType) {
                    case this.addProfileTypes.facebook.PUBLIC:
                        this.savePublicProfile();
                        break;
                    case this.addProfileTypes.facebook.MANAGED:
                        this.saveManagedProfiles(this.profileTypes.facebook);
                        break;
                    case this.addProfileTypes.facebook.INFLUENCER:
                        this.generateInfluencerLinks();
                        break;
                }
            }
        },

        previous() {
            this.curStage--;

            if (this.curStage === 0) {
                this.curStage = 1;
                this.selectedPageType = null;
            }
        },

        pageTypeSelected(pageType) {
            this.$refs.addProfileDialog.setTransitionToNext();
            this.selectedPageType = pageType;

            switch(pageType) {
                case this.addProfileTypes.facebook.PUBLIC:
                    this.totalStages = Object.keys(this.addProfileStages.facebook.public).length;
                    break;
                case this.addProfileTypes.facebook.MANAGED:
                    this.totalStages = Object.keys(this.addProfileStages.facebook.managed).length;
                    break;
                case this.addProfileTypes.facebook.INFLUENCER:
                    this.totalStages = Object.keys(this.addProfileStages.facebook.influencer).length;
                    break;
            }
        },

        reset() {
            this.curStage = 1;
            this.selectedPageType = null;
            this.saveComplete = false;
            this.saving = false;
            this.clearNewProfiles();
        }
    }
}
</script>

<style scoped lang="sass">

.fb-add-profile-dialog

    &__login-status-check
        display: flex
        align-items: center
        column-gap: 10px
        justify-content: center

</style>