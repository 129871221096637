<template>
    <section class="beef-tooltip dashboard-item-tooltip">
        <h4>{{ dashboard.name }}</h4>
        <p v-if="description">
            {{description}}
        </p>

        <section v-if="dashboard.type === 'NORMAL'"
                 class="dashboard-item-tooltip__grid">
            <span class="dashboard-item-tooltip__grid-name">Created</span><span v-html="created"></span>
            <span class="dashboard-item-tooltip__grid-name">Created by</span>
                <deq-user v-if="createdBy" :user="createdBy"/><span v-else>Unknown</span>

            <span class="dashboard-item-tooltip__grid-name">Last updated</span><span v-html="lastUpdated"></span>
            <span class="dashboard-item-tooltip__grid-name">Updated by</span>
                <deq-user v-if="lastUpdatedBy" :user="lastUpdatedBy"/><span v-else>Unknown</span>
        </section>


        <p v-if="accessMessage" class="dashboard-item-tooltip__security">
            <i v-if="accessIcon" :class="accessIcon"></i>
            {{accessMessage}}
        </p>
    </section>
</template>


<script>
import {getDashboardAccessMsg} from "@/dashboards/DashboardUtils";
import pluralise from "@/helpers/pluralise";
import humanizeDate from "@/helpers/humanizeDate";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import DeqUser from "@/components/formatters/DeqUser";

export default {
    store: VuexStore,
    components: {DeqUser},
    props: {
        dashboard: {
            type: Object,
            required: true
        },
    },

    computed: {
        ...mapState('dashboards', ['dashboards']),

        description() {
            if (!this.full && !this.dashboard.type) {
                // dashboard is a folder
                const count = this.dashboard.dashboards ? this.dashboard.dashboards.length : 0;
                return `A folder with ${count} ${pluralise(count, 'dashboard')}`;
            }
            return this.full?.description ?? this.dashboard?.description;
        },

        created() {
            const created = this.full?.created ?? this.dashboard?.created;
            if (!created) return null;
            return humanizeDate(created, true);
        },

        lastUpdated() {
            const lastUpdated = this.full?.lastUpdated ?? this.dashboard?.lastUpdated;
            if (!lastUpdated) return null;
            return humanizeDate(lastUpdated, true);
        },

        createdBy() {
            return this.full?.createdBy ?? this.dashboard?.createdBy;
        },

        lastUpdatedBy() {
            return this.full?.lastUpdatedBy ?? this.dashboard?.lastUpdatedBy;
        }
    },

    data() {
        return {
            full: null,
            accessIcon: null,
            accessMessage: null
        }
    },

    async mounted() {
        if (this.dashboard?.id) {
            const result = await getDashboardAccessMsg(this.dashboard);
            this.accessIcon = result.icon;
            this.accessMessage = result.msg;
            this.full = await this.getFullDashboard(this.dashboard?.id);
            await this.refreshDashboards();
        }
    },

    methods: {
        ...mapActions('dashboards', ['refreshDashboards', 'getFullDashboard'])
    }
}
</script>


<style scoped lang="sass">

.dashboard-item-tooltip
    padding: 10px 20px

.dashboard-item-tooltip__security
    box-sizing: border-box
    border-top: thin solid grey
    border-bottom-left-radius: 3px
    border-bottom-right-radius: 3px
    margin: 10px -20px 0 -20px
    padding: 10px 20px


.dashboard-item-tooltip__grid
    display: grid
    grid-template-columns: auto 1fr
    grid-column-gap: 10px


.dashboard-item-tooltip__grid-name
    box-sizing: border-box
    padding-left: 20px
    text-align: right
    color: var(--be-colour-muted-text-dark)
</style>