<template>
    <div class="dashboard-category dashboard-panel__list-item"
         :class="{'dashboard-category__active': highlight}"
         @click="$emit('toggle-open', category)"
         @mouseenter="showTooltip">
        <i :class="{'icon-right-dir': !category.expanded, 'icon-down-dir': category.expanded}"></i>
        <i v-if="category.pinned" class="symbol-pin" style="padding-right: 5px"></i>
        <span v-if="!editingName">{{category.name}}</span>
        <inline-text-input v-else
                           style="width: 300px"
                           ref="categoryNameInput"
                           v-model="categoryModel"
                           :disable-done="!showSaveButton"
                           signal-for-all
                           text-required
                           @cancel="resetName"
                           @ok="updateName"/>

        <section class="dashboard-panel__list-item__actions" v-if="!category.saving">
            <be-button v-if="canEditDashboards" link @click.stop="$emit('create', category.name)"
                       tooltip="Create a dashboard in this folder">
                <i class="icon-plus"></i>
            </be-button>
            <popup-menu @popup-menu-visible="handlePopupMenuClose"
                        ref="dashboardCategoryOptions"
                        ignore-children-click
                        fixed
                        :arrow-pointer="{show: true, left: true}"
                        :pos-offset="{top: 8, left: -6}">
                <template #activator>
                    <old-tooltip label="Edit this folder">
                        <i class="symbol-menu"></i>
                    </old-tooltip>
                </template>
                <div class="dashboard-category__action-menu short-animated fadeIn">
                    <ul v-if="canEditDashboards">
                        <li>
                            <a @click="toggleEditName">Edit folder name</a>
                        </li>
                        <li v-if="user.admin && !category.purchasedCategory">
                            <a @click="updateCategoryPinnedState">{{!category.pinned ? 'Pin folder…' : 'Unpin folder…'}}</a>
                        </li>
                        <li class="dashboard-category__divider"></li>
                        <li>
                            <a @click="archiveCategory">Archive folder…</a>
                        </li>
                        <li>
                            <a @click="deleteCategory">Delete folder…</a>
                        </li>
                        <li v-if="!purchasedCategory" class="dashboard-category__divider"></li>
                        <li v-if="!purchasedCategory">
                            <a @click="changeCategoryPermissions">Change folder permissions…</a>
                        </li>
                    </ul>
                    <ul>
                        <li class="dashboard-category__action-menu-header">
                            Sort By
                        </li>
                        <li v-for="sort in sortBy" :key="sort.name">
                            <a @click="toggleSort(sort)"
                               :style="sort.name === activeSortBy.name ? null : 'text-indent: 20px'">
                                <i v-if="sort.name === activeSortBy.name" :class="sort.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"></i>
                                {{ sort.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </popup-menu>
        </section>
    </div>
</template>
<script>

import BeButton from "@/components/buttons/BeButton";
import {showAskDialog, showWhenDialog} from "@/app/framework/dialogs/Dialog";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import OldTooltip from "@/components/tooltip/OldTooltip";
import PopupMenu from "@/components/PopupMenu";
import {features} from "@/app/Features";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

export default {
    store: VuexStore,
    components: {OldTooltip, InlineTextInput, PopupMenu, BeButton},

    props: {
        category: Object,
        canEditDashboards: Boolean,
        purchasedCategory: Boolean
    },

    data: function () {
        return {
            features,
            editingName: false,
            categoryModel: "",
            highlight: false,

            // sorting
            activeSortBy: {
                name: "name",
                label: "Name",
                order: "ASCENDING"
            },
            sortBy: [
                {
                    name: "name",
                    label: "Name",
                    order: "ASCENDING"
                },
                {
                    name: "created",
                    label: "Date created",
                    order: "ASCENDING"
                },
                {
                    name: "lastUpdated",
                    label: "Last updated",
                    order: "ASCENDING"
                }
            ]
        }
    },

    computed: {
        ...mapState(['user']),

        showSaveButton() {
            return this.categoryModel !== this.category.name;
        }
    },

    created() {
        this.categoryModel = this.category.name;
    },

    methods: {
        showTooltip(event) {
            this.$emit('show-tooltip', {dashboard: this.category, target: event.target});
        },

        closePopupMenu() {
            this.$refs.dashboardCategoryOptions?.close();
        },

        handlePopupMenuClose(value) {
            this.highlight = value;
        },

        async updateName() {
            if (this.categoryModel !== this.category.name) {
                if (await showAskDialog("Change Folder Name?", `Are you sure you want to change "${this.category.name}" to "${this.categoryModel}"?`, true)) {
                    this.editingName = false;
                    this.$emit('update-category-name', {category: this.category, newName: this.categoryModel});
                    this.categoryModel = this.category.name; // reset model in case name change fails
                } else {
                    this.resetName();
                }
            } else {
                this.resetName();
            }
        },

        toggleSort(sortBy) {
            sortBy.order = sortBy.name === this.activeSortBy.name ? (sortBy.order === "ASCENDING" ? "DESCENDING" : "ASCENDING") : "ASCENDING";
            this.activeSortBy = sortBy;

            this.$emit('sort-category', {category: this.category, sortBy: sortBy.name, order: sortBy.order});
        },

        toggleEditName() {
            this.closePopupMenu();

            this.editingName = true;

            this.$nextTick(() =>  this.$refs.categoryNameInput?.focus());
        },

        updateCategoryPinnedState() {
            this.closePopupMenu();

            showWhenDialog(`Are you sure you want to ${this.category.pinned ? `unpin` : `pin`} this folder?`, this.category.pinned ? `Unpin folder?` : `Pin folder?`)
                .then(() => {
                    this.$emit('update-category-pinned-state', {category: this.category, pinned: !this.category.pinned});
                });
        },

        resetName() {
            this.editingName = false;
            this.categoryModel = this.category.name;
        },

        deleteCategory() {
            this.closePopupMenu();

            showWhenDialog(`Are you sure you want to delete "${this.category.name}"? All dashboards in this folder will also be deleted.`, "Delete Folder?")
                .then(() => {
                    this.$emit('delete-category', this.category);
                });
        },

        archiveCategory() {
            this.closePopupMenu();

            showWhenDialog(`Are you sure you want to archive "${this.category.name}"? All dashboards in this folder will be moved to the Archived folder.`, "Archive Folder?")
                .then(() => {
                    this.$emit('archive-category', this.category);
                });
        },

        changeCategoryPermissions() {
            this.closePopupMenu();

            this.$emit('edit-category-permissions');
        }
    }
}

</script>


<style scoped lang="sass">
.dashboard-category
    &__action-menu
        margin: 3px 0

        ul
            list-style: none
            margin: 0

        li
            line-height: 20px

        li > a
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none
                background-color: #222222

    &__action-menu-header
        margin-top: 5px
        background: #222222
        padding: 3px 10px
        font-size: 12px
        text-transform: uppercase
        color: var(--clr-sidebar-header) !important
        pointer-events: none

    &__action-menu-close-button
        float: right
        padding: 2px 10px 5px 0

    &__divider
        margin: 5px 0
        overflow: hidden
        border-bottom: 1px solid #222222
        pointer-events: none
        padding: 0

    &__active
        background: var(--background-menu-hover)
        color: white

        & .symbol-menu, .icon-plus
            display: inline-block !important

        & .symbol-menu
            border-radius: 10px
            box-shadow: 0 0 2px #fff

</style>