<template>
    <div class="glossary-tip" ref="slot">
        <slot/>
        <div ref="tip" class="glossary-top__tip">
            <glossary-entry :glossary-id="glossary" :brand="brand"/>
            <slot name="tooltip">
            </slot>
        </div>
    </div>
</template>


<script>
import GlossaryEntry from "@/app/help/tips/GlossaryEntry";
import {showTooltipDomElement} from "@/components/tooltip/TooltipUtilities";

export default {
    components: {GlossaryEntry},
    props: {
        glossary: {
            type: String,
            required: true
        },
        brand: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            tooltipNode: null,
            target: null,

            isDestroyed: false
        }
    },

    beforeDestroy() {
        this.isDestroyed = true;
        if (this.target) {
            this.target.removeEventListener('mouseenter', this.mouseHandler, true);
        }
    },

    mounted() {
        this.tooltipNode = this.$refs.tip.parentNode.removeChild(this.$refs.tip);
        const target = this.target = this.$refs.slot.childNodes[0];

        if (!target) {
            console.error("Unable to find tooltip target");
            return;
        }

        target.addEventListener('mouseenter', this.mouseHandler, {capture: true, passive: true})
    },

    methods: {
        mouseHandler() {
            if (this.isDestroyed) return;
            showTooltipDomElement(this.target, this.tooltipNode)
        }
    }
}
</script>

<style scoped lang="sass">

.glossary-tip
    display: contents

</style>