import Vue from "vue";
import Vuex from "vuex";
import {beef} from "@/store/Services";

Vue.use(Vuex);


export default {
    namespaced: true,

    state: {
        countryIdToLocation: null,
        groupIdToLocation: null,
        cached: {}
    },

    mutations: {
        initialiseLocations(state, location) {
            state.countryIdToLocation = location.countryIdToLocation;
            state.groupIdToLocation = location.groupIdToLocation;
        },

        cacheValue(state, {identifier, location}) {
            state.cached[identifier] = location ?? null;
        },
    },

    getters: {
        getCached: (state) => (identifier) => {
            if (state.countryIdToLocation[identifier]) {
                return standardise(state.countryIdToLocation[identifier])
            }
            if (state.groupIdToLocation[identifier]) {
                return standardise(state.groupIdToLocation[identifier])
            }

            return state.cached[identifier] ?? null;
        }
    },

    actions: {
        async getLocation({state, commit}, identifier) {
            if (state.countryIdToLocation[identifier]) {
                return standardise(state.countryIdToLocation[identifier])
            }
            if (state.groupIdToLocation[identifier]) {
                return standardise(state.groupIdToLocation[identifier])
            }

            const response = await beef.get("/api/location/display?location=" + encodeURIComponent(identifier));
            if (!response.data) {
                const location = {id: identifier, label: `«unknown location:${identifier}»`};
                commit("cacheValue", {identifier, location});
                return location;
            }
            commit("cacheValue", {identifier, location:standardise(response.data)});
            return standardise(response.data);
        }
    }
};

/**
 * The data structures are old and does not use the same property names that we had previously standardised on.
 * @param location
 * @returns {{id, label: string}}
 */
function standardise(location) {
    return {
        id: location.id,
        label: location.value || location.countryDisplay || location.groupDisplay,
    }
}