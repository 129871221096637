<template>
    <dialog-box class="new-in-analyse-dialog deq-reset hide-in-fullscreen"
                @close="$emit('close')"
                title="New in Analyse"
                width="65vw"
                modal>
        <div class="new-in-analyse-dialog__grid">
            <header class="new-in-analyse-dialog__header">
                <h1 v-if="isSubmessage">
                    <a href="#" @click.stop.prevent="loadMessage(selected)">{{ heading }}</a> » {{ isSubmessage }}
                </h1>
                <h1 v-else>{{ heading }}</h1>
            </header>

            <sidebar-component class="new-in-analyse-dialog__sidebar dark-scrollbars dark-scrollbars--visible">
                <sidebar-header>
                    <h4>What's new</h4>
                </sidebar-header>
                <sidebar-menu class="new-in-analyse-dialog__sidebar-new dark-scrollbars dark-scrollbars--visible">
                    <sidebar-menu-item v-for="item in visibleMotds"
                                       :key="item.id"
                                       @click="selected = item.id"
                                       :active="item.id === selected">
                        {{item.title}}
                    </sidebar-menu-item>
                </sidebar-menu>


                <sidebar-menu position="bottom">
                    <sidebar-header>
                        <h4>Getting help</h4>
                    </sidebar-header>
                    <sidebar-menu-item tooltip="Download our Quickstart Guide for getting up and running quickly">
                        <a href="https://storage.googleapis.com/brandseye-public-files/dataeq-analyse-quickstart-guide-1.1.pdf"
                           target="_blank">
                            Read our quickstart guide
                        </a>
                    </sidebar-menu-item>
                    <sidebar-menu-item tooltip="Download our PDF manual for Analyse">
                        <a href="https://storage.googleapis.com/brandseye-public-files/dataeq-analyse-training-manual-1.4.pdf"
                           target="_blank">
                            See our manual
                        </a>
                    </sidebar-menu-item>
                    <sidebar-menu-item tooltip="Send an email to our support team">
                        <a href="mailto:support@dataeq.com"
                           target="_blank">
                        Mail our support team
                        </a>
                    </sidebar-menu-item>
                </sidebar-menu>


                <sidebar-menu position="bottom">
                    <sidebar-header>
                        <h4>Staying connected</h4>
                    </sidebar-header>
                    <sidebar-menu-item tooltip="Find us on X">
                        <a href="https://x.com/dataeq"
                           target="_blank">
                            <i class="symbol-twitter"></i> X
                        </a>
                    </sidebar-menu-item>
                    <sidebar-menu-item tooltip="Follow our Facebook page">
                        <a href="https://www.facebook.com/DataEQofficial/"
                           target="_blank">
                            <i class="symbol-facebook"></i> Facebook
                        </a>
                    </sidebar-menu-item>
                    <sidebar-menu-item tooltip="Read more about us on our Website">
                        <a href="https://www.dataeq.com"
                           target="_blank">
                            <i class="symbol-website"></i> Website
                        </a>
                    </sidebar-menu-item>
                    <sidebar-menu-item tooltip="We have a quarterly newsletter than you can subscribe to">
                      <i class="icon-email"></i>
                      <a href="https://mailchi.mp/brandseye.com/research-digest-june-2020"
                         target="_blank">
                          Subscribe to our newsletter
                      </a>
                    </sidebar-menu-item>
                </sidebar-menu>
            </sidebar-component>


            <div v-if="!error"
                 class="new-in-analyse-dialog__main dark-scrollbars dark-scrollbars--visible">
                <loading-message v-if="!selected || !content">
                    Loading messages...
                </loading-message>
                <!-- motd-dialog__message keeps styling in the gsp working -->
                <div v-else v-html="content" class="short-animated fadeIn motd-dialog__message">
                </div>

                <div v-if="content && links.length" class="new-in-analyse-dialog__links short-animated fadeIn">
                    <h2>Find out more</h2>
                    <div class="new-in-analyse-dialog__links-grid">
                        <div v-for="link in links" :key="link.id">
                            <a href="#" @click.prevent.stop="loadSubmessage(link)">{{link.title}} »</a>
                        </div>
                    </div>
                    <be-button link v-if="isSubmessage" @click="loadMessage(selected)">« Back</be-button>
                </div>


                <div v-if="content" class="new-in-analyse-dialog__date deq-text--muted short-animated fadeIn">
                    <span v-if="!isSubmessage">First published on <deq-date :date="selected" format="D MMMM, YYYY"/></span>
                </div>
            </div>

            <div v-else-if="error"
                 class="new-in-analyse-dialog__main dark-scrollbars dark-scrollbars--visible">
                <error-message>
                    <template #extra>
                        <p>Click to <a href="#" @click.stop.prevent=loadMessage(selected)>retry</a>.</p>
                    </template>
                </error-message>
            </div>
        </div>

        <template #buttons>
            <section class="new-in-analyse-dialog__buttons">
                <div class="new-in-analyse-dialog__navigation">
                    <be-button link @click="previous" :disabled="index <= 0" tooltip="Click to see the Analyse News post from just before this one">
                        See older
                    </be-button>
                    <be-button v-if="index < motd.length - 1"
                               class="short-animated fadeIn"
                               link @click="next" tooltip="Click to see the next Analyse News post">
                        See newer
                    </be-button>
                </div>

                <be-button primary
                           tooltip="Close this dialog"
                           keyword="esc"
                           @click="$emit('close')">Close</be-button>
            </section>
        </template>
    </dialog-box>
</template>

<script>

import DialogBox from "@/components/DialogBox";
import {beef} from "@/store/Services";
import {setPauseTips, showTip} from "@/app/help/tips/tips";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex/dist/vuex.esm.browser";
import {isString} from "@/app/utils/StringUtils";
import SidebarComponent from "@/components/sidebar/SidebarComponent";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
import BeButton from "@/components/buttons/BeButton";
import LoadingMessage from "@/components/LoadingMessage";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import DeqDate from "@/components/formatters/DeqDate";
import ErrorMessage from "@/components/ErrorMessage";

export default {
    components: {
        DeqDate,
        ErrorMessage,
        LoadingMessage,
        BeButton,
        SidebarMenuItem,
        SidebarComponent,
        SidebarHeader, SidebarMenu, DialogBox
    },

    store: VuexStore,

    data() {
        return {
            selected: null,
            isSubmessage: false,
            content: null,
            showOnClose: false,
            error: false
        }
    },

    computed: {
        ...mapState(['motd', 'user']),

        heading() {
            if (!this.selected) return "New in Analyse";
            const item = this.motd.find(d => d.id === this.selected || d === this.selected);
            return item?.title ?? "Analyse News";
        },

        visibleMotds() {
            if (!this.motd?.length) return [];
            return Array.from(this.motd)
                .filter(d => !isString(d))
                .reverse()
                .slice(0, 6)
        },

        index() {
            if (!this.selected) return null;
            return this.motd.findIndex(item => item === this.selected || item.id === this.selected);
        },

        current() {
            return this.index >= 0 ? this.motd[this.index] : null;
        },

        links() {
            if (this.isSubmessage) return this.current?.links?.filter(link => link.title !== this.isSubmessage) ?? [];
            return this.current?.links ?? [];
        }
    },

    watch: {
        selected() {
            this.loadMessage(this.selected);
        },

        visibleMotds() {
            for (const item of this.visibleMotds) {
                beef.get('/api/motd/' + item.id).catch(e => console.warn(`Unable to prefetch ${item.id}`));
            }
        }
    },

    created() {
        setPauseTips(true); // Don't show any tips while this dialog is open.
        if (this.visibleMotds?.length) {
            this.selected = this.visibleMotds[0].id;
        }
    },

    beforeDestroy() {
        this.maybeShowCloseMessage();
        setTimeout(() => {
            setPauseTips(false);
            showTip('ONBOARD_WELCOME_QUICKSTART');   // Show the welcome tip after 10 seconds.
        }, 20_000);
    },

    methods: {
        async loadSubmessage({id, title}) {
            await this.loadMessage(id);
            this.isSubmessage = title;
        },

        async loadMessage(id) {
            try {
                if (this.selected === id) this.isSubmessage = false;
                this.error = false;
                this.content = null;
                const response = await beef.get('/api/motd/' + id);
                this.content = response.data;
            } catch(error) {
                console.error(error);
                this.error = true;
            }

            // Prewarm caches for faster navigation
            if (this.links) {
                for (const link of this.links) {
                    beef.get('/api/motd/' + link.id).catch(e => console.warn(`Unable to prefetch ${link.id}`));
                }
            }
        },

        previous() {
            if (!this.selected) return;
            if (this.index > 0) {
                this.selected = this.motd[this.index - 1].id ?? this.motd[this.index - 1];
            }
        },

        next() {
            if (!this.selected) return;
            if (this.index < this.motd.length - 1) {
                this.selected = this.motd[this.index + 1].id ?? this.motd[this.index + 1];
            }
        },

        /**
         * Tell users how to find this message again.
         */
        maybeShowCloseMessage() {
            const key = `safe-cache:motd:${this.user.id}:has-seen-help`;
            if (!localStorage.getItem(key)) {
                notifyUser({
                    message: "Reopen our <strong>New in Analyse</strong> dialog from the " +
                        "<strong style='white-space: nowrap'><i class='symbol-help'></i>Help</strong> menu",
                    isEscapedHtml: true,
                    longDelay: true,
                    icon: "<i class='symbol-help'></i>"
                });
                localStorage.setItem(key, "1");
            }
        }
    }
}

</script>


<style scoped lang="sass">

.new-in-analyse-dialog
    --dialog-height: clamp(400px, 650px, calc(100vh - 150px))
    // Below strange --header-height value is to match height of sidebar-header
    --header-height: 52px
    --max-width-main: 650px

::v-deep .dialog-button-bar
    box-sizing: border-box
    margin: 0
    padding: 10px
    border-top: 1px solid #272727

::v-deep .modal-container
    width: clamp( 800px, 1050px, calc(100vw - 300px)) !important

::v-deep .dialog-body
    margin: 0
    padding: 0


.new-in-analyse-dialog__grid
    height: var(--dialog-height)
    max-height: var(--dialog-height)
    display: grid
    grid-template-columns: 250px 1fr
    grid-template-rows: var(--header-height) 1fr
    grid-template-areas: "side header" "side main"

.new-in-analyse-dialog__header
    grid-area: header
    background: linear-gradient(to right, var(--colour-background-black), var(--be-colour-net))

    h1
        margin-inline: auto
        margin-block: 0
        max-width: var(--max-width-main)
        line-height: var(--header-height)
        white-space: nowrap

        a:not(:hover)
            color: var(--heading-colour-text-dark)

.new-in-analyse-dialog__main
    grid-area: main
    box-sizing: border-box
    overflow-y: auto
    width: 100%
    padding: 20px 40px
    display: flex
    flex-direction: column
    justify-items: stretch

    h2
        margin: 0
        padding: 0

    ::v-deep h1, ::v-deep h2, ::v-deep h3, ::v-deep h4
        color: var(--heading-colour-text-dark)



.new-in-analyse-dialog__sidebar
    grid-area: side
    position: static
    height: auto
    overflow-y: auto
    width: 250px

.new-in-analyse-dialog__buttons
    display: flex
    flex-direction: row
    justify-content: flex-end
    box-sizing: border-box
    margin: 0

    .new-in-analyse-dialog__navigation
        display: flex
        margin-right: auto

.motd-dialog__message
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-auto-rows: auto
    grid-column-gap: 10px
    max-width: var(--max-width-main)
    margin-inline: auto
    margin-bottom: auto

    ::v-deep strong
        color: var(--strong-colour-dark)
        font-size: var(--strong-font-size)
        white-space: nowrap

    ::v-deep img
        max-width: 90%
        margin-inline: auto
        display: block
        box-shadow: -2px -2px 10px rgb(150 150 150 / 0.1), 5px 5px 8px rgb(0 0 0 / 0.4)
        margin-bottom: 20px

    ::v-deep picture
        margin-inline: auto
        margin-bottom: 20px
        padding-top: 10px
        display: block
        background-image: radial-gradient(rgb(200 200 200 / 0.5), rgb(0 0 0 / 0) 70%)

    ::v-deep picture.full-image,
    ::v-deep img.full-image
        width: calc(100% + 80px)
        margin-left: -40px
        grid-column: 1 / span 4

    ::v-deep .main-left
        align-self: center
        grid-column: 1 / span 3

    ::v-deep .main-left + *
        grid-column: 4

    ::v-deep .left
        align-self: center
        grid-column: 1 / span 2

    ::v-deep .left + *
        grid-column: 3 / -1

    ::v-deep .two-row
        grid-row: span 2

    ::v-deep > *, ::v-deep > .full-row
        grid-column: 1 / -1

    ::v-deep
        img.left,
        .left + img,
        img.main-left,
        .main-left + img,
        img.small-left,
        .small-left + img
            max-width: 100%

    ::v-deep .small-left
        align-self: center
        grid-column: 1

    ::v-deep .small-left + *
        grid-column: 2 / -1




.new-in-analyse-dialog__date
    width: 100%
    max-width: var(--max-width-main)
    margin-inline: auto
    text-align: right

.new-in-analyse-dialog__sidebar-new
    min-height: calc(var(--sidebar-item-min-height) * 3)
    overflow-y: auto

.new-in-analyse-dialog__links
    width: 100%
    display: flex
    flex-direction: column
    row-gap: 10px
    margin-inline: auto
    max-width: var(--max-width-main)

    &-grid
        display: grid
        grid-template-columns: repeat(2, 1fr)
        column-gap: 10px
        row-gap: 2px
        padding-left: 20px


</style>