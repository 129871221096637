import beefRenderMatrix from './MatrixD3';
import {adjustedWald, getDefaultTopicView} from "@/app/utils/Util";
import {
    appendBasicFilters,
    buildBasicFilter,
    convertFilterToAttrs,
    extractDays,
    isVerifiedOnly, filterToDateRange
} from "@/dashboards/filter/BasicFilter";
import {deprecatedFetchTags} from "@/data/DeprecatedBeefCache";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {getPalette} from "@/app/utils/Colours";
import _ from 'underscore';
import moment from "moment";
import {once} from "@/app/utils/Functions";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {account} from "@/app/utils/Account";
import {getRootBrands} from "@/app/utils/Brands";
import {encloseInDisplayQuotes, splitAtSpaces} from "@/app/utils/StringUtils";

/**
 * Matrix of little volume and sentiment over time charts.
 */
Beef.module("Widget.Matrix").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Comparison Matrix",
        description:    "Compare volume and sentiment over time for brands, topics etc.",
        group:          "other",
        width:          4,
        height:         6,

        onAdd: function(data) {
            if (account().hasTopics) {
                data.colField = 'topic';
                data.width = 4;
            }
        }
    };

    var defaults = {
        rowField: 'brand',
        colField: 'none',
        barField: 'netSentiment',
        vis: 'bubbles',
        timeBucket: 'auto',
        showMarkers: false,
        hideSmallRows: true,
        hideSmallCols: true,
        segments: '',
        topicView: '',
        language: '',
        tags: ''
    };

    this.View = Beef.BoundItemView.extend({

        attributes: {
            'class': 'matrix widget-font widget-height-inner'
        },

        modelEvents: {
            "change":   "maybeRefresh"
        },

        initialize: function() {
            var defs = {};
            _.each(defaults, function(value, key) {
                if (!this.model.has(key)) defs[key] = value;
            }, this);
            this.model.set(defs, {silent: true});

            this.accountCode = this.model.getAncestorProperty('accountCode');

            this.refresh();
        },

        labelLookup: function(field, value, data) {
            var label, descr, o;
            if (field == 'brand') {
                label = data.brandName;
            } if (field == 'topic') {
                label = data.topicName;
            } if (field == 'segment') {
                label = data.segmentName;
            } if (field == 'tag') {
                label = data.tagName;
            } else if (field == "gender") {
                label = data.genderLabel;
            }
            return { label: label || ("" + value), description: descr }
        },

        render: function(){
            var attr = this.model.attributes;
            if (!this._data) return;
            var data = this._data;

            if (!data.length) {
                setTimeout(function() {
                    this.model.generalData.set("_message", "No mentions match your filter");
                }.bind(this));
                return;
            }

            // Need to remove svg elements before we calculate size,
            // so that everything can shrink smaller properly. Chrome requires
            // a max width because of an svg rendering problem. Our expandable last column in the section widget grid
            // means we can't determine this via css.
            this.$el.text('');
            this.$el.css({'max-width': "none"});
            var w = this.$el.width();
            var h = this.$el.height();
            this.$el.css({'max-width': w + "px"});

            var that = this;
            var timeBucket = this.getTimeBucket();
            var options = {
                rowField: attr.rowField == 'none' ? null : attr.rowField,
                colField: attr.colField == 'none' ? null : attr.colField,
                barField: attr.barField == 'none' ? null : attr.barField,
                vis: attr.vis,
                sentiment: attr.sentiment,
                showMarkers: attr.showMarkers,
                hideSmallRows: attr.hideSmallRows,
                hideSmallCols: attr.hideSmallCols,
                palette: getPalette(this.model.attributes, this.model.getDashboardModel().attributes),
                labelLookup: this.labelLookup.bind(this),
                timeBucket: timeBucket,
                onChartClick: function(d) { that.onChartClick.call(that, this, d) },
                owner: that
            };

            if (this._dateRange) {
                options.start = this._dateRange.start;
                options.end = this._dateRange.end;
            }

            beefRenderMatrix(this.$el[0], w, h, data, options);
        },

        getTimeBucket: function() {
            var filter = this.model.get('_effectiveFilter');
            if (!filter) return null;
            if (this.model.get('vis') === 'bubbles') return 'none';
            var timeBucket = this.model.get('timeBucket');
            if (timeBucket == 'auto') {
                var days = extractDays(filter);
                if (days >= 90) timeBucket = 'month';
                else if (days >= 28) timeBucket = 'week';
                else timeBucket = 'day';
            }
            return timeBucket;
        },

        maybeRefresh: function() {
            var c = this.model.changed;
            var refresh = c['_effectiveFilter'];
            var newBF = c['barField'];
            if (!refresh && newBF) {
                var oldBF = this.model.previous('barField');
                refresh = oldBF != 'sentiment' && oldBF != 'netSentiment' || newBF != 'sentiment' && newBF != 'netSentiment';
            }
            if (refresh || c['rowField'] || c['colField'] || c['timeBucket'] || c['segments'] !== undefined ||
                    c['topicView'] !== undefined || c['language'] !== undefined || c['tags'] !== undefined ||
                    c['vis'] !== undefined) {
                this.refresh();
            } else {
                if (c['width'] || c['height']) {
                    this.$el.text('');
                    this.$el.css({'max-width': "none"});
                    setTimeout(function() { this.render() }.bind(this), 400);
                }
                else this.render();
            }
        },

        refresh: function() {
            var filter = this.model.get('_effectiveFilter');
            if (!filter) return;

            var that = this;

            this.model.generalData.set({'_loading': true, _completed: false});

            var attr = this.model.attributes;

            var groupby = [];
            if (attr.rowField != 'none') groupby.push(attr.rowField);
            if (attr.colField != 'none') groupby.push(attr.colField);

            var timeBucket = this.getTimeBucket();
            if (timeBucket != 'none') groupby.push('published[' + timeBucket.toUpperCase() + "]");

            var params = {
                filter: filter,
                select: 'mentionCount'
            };

            var usingSentimentVerified = false;
            switch (attr.barField) {
                case 'netSentiment':
                case 'sentiment':
                    params.select += ",totalPositive,totalNeutral,totalNegative";
                    if (attr.vis === 'bubbles') {
                        groupby.push('sentimentVerified');  // we want unverified volume but verified sentiment
                        usingSentimentVerified = true;
                    } else if (!isVerifiedOnly(params.filter)) {
                        params.filter = appendBasicFilters(params.filter, "process is verified");
                    }
                    break;
                case 'engagement':
                    params.select += ',totalEngagement';
                    break;
                case 'authorNames':
                    params.select += ',authorNameCount';
                    break;
            }

            var tagNamespace = [], andTerms = [], i, j, term;

            for (i = 0; i < groupby.length; i++) {
                var gb = groupby[i];
                if (gb === 'topic') {
                    groupby[i] = 'tag';
                    tagNamespace.push('topic');
                    var view = this.model.get('topicView') || getDefaultTopicView(params.filter);
                    if (view) andTerms.push("Topic is " + view);
                } else if (gb === 'segment') {
                    groupby[i] = 'tag';
                    tagNamespace.push('segment');
                    term = tagIdsToOrTerm(this.model.get('segments'), "Segment");
                    if (term) andTerms.push(term);
                } else if (gb === 'tag') {
                    tagNamespace.push('tag');
                    term = tagIdsToOrTerm(this.model.get('tags'), "Tag");
                    if (term) andTerms.push(term);
                }
            }

            if (tagNamespace.length > 0) params.tagNamespace = tagNamespace.join(",");
            if (andTerms.length > 0) params.filter = "(" + params.filter + ") AND " + andTerms.join(" AND ");

            params.groupBy = groupby.join(",");

            for (i = 0; i < groupby.length; i++) {
                if (groupby[i] === 'tag') {
                    params.select += ",tag[id,name,namespace,labels,descriptions,index,parent[id]]";
                    break;
                }
            }

            this.model.getSectionModel().view.getJsonFromGrouse('/v4/accounts/' + this.accountCode + '/mentions/count', params)
                .then(function (data) {
                    var rowField = attr.rowField != 'none' ? attr.rowField : null;
                    var colField = attr.colField != 'none' ? attr.colField : null;
                    var lang = attr.language || 'en';
                    if (usingSentimentVerified) data = mergeSentimentRows(data, rowField, colField);
                    var keep = [];
                    var segmentListIds;
                    if (attr.segments) {
                        segmentListIds = splitAtSpaces(attr.segments).map(function(id) { return parseInt(id) })
                    }
                    for (var i = 0; i < data.length; i++) {
                        var row = convertV4FieldsToV3(data[i], lang, segmentListIds);
                        if (row && (!rowField || row[rowField]) && (!colField || row[colField])) keep.push(row);
                    }
                    that._data = keep;
                    that._filter = params.filter;
                    that._dateRange = filterToDateRange(params.filter);
                    if (that._dateRange && that._dateRange.end === 'today') that._dateRange.end = new Date();
                    that.render();
                    that.model.generalData.set({'_loading': false, _completed: true});
            },once(e => errorHelper(this.model, e)));
        },

        onChartClick: function(node, d) {
            var ev = d3.event;

            var $target = $(ev.target);
            var $td = $target.closest(".mini-chart");

            var published = $target.attr("data-published");
            var rowValue = $td.attr("data-row");
            var colValue = $td.attr("data-col");

            var attr = convertFilterToAttrs(this._filter);
            var filter;
            if (attr.errors) {
                filter = this._filter;
            } else {
                var updateAttr = function(field, value) {
                    if (field == 'none') return;
                    if (field == "topic") field = "topics";
                    else if (field == "tag") field = "tags";
                    else if (field == "segment") field = "segments";
                    attr[field] = value;
                };
                updateAttr(this.model.get('rowField'), rowValue);
                updateAttr(this.model.get('colField'), colValue);

                if (published) {
                    var start = moment(published);
                    var end = start.clone();
                    var tb = this.getTimeBucket();
                    if (tb == "month") end.add(1, 'months').add(-1, 'days');
                    else if (tb == "week") end.add(6, 'days');
                    attr.published = start.format("YYYY/MM/DD") + "-" + end.format("YYYY/MM/DD");
                }

                filter = buildBasicFilter(attr);
            }

            //console.log("onChartClick " + filter);
            var rowField = this.model.get('rowField');
            var colField = this.model.get('colField');

            if (rowField === "none" && colField === "none") {
                showMentions(filter);
            } else {
                deprecatedFetchTags(this, function(tagMap) {
                    var brands = getRootBrands();
                    var rowName = null,
                        colName = null;
                    if (rowField === "brand") rowName = brands.find(function(b) { return b.id == rowValue });
                    if (colField === "brand") colName = brands.find(function(b) { return b.id == colValue });

                    if (rowField === "topic" || rowField === "tag" || rowField === "segment") rowName = tagMap[rowValue];
                    if (colField === "topic" || colField === "tag" || colField === "segment") colName = tagMap[colValue];

                    if (rowField === "gender") rowName = rowValue.toLowerCase();
                    if (colField === "gender") colName = colValue.toLowerCase();

                    var titles = [];
                    if (rowName && rowName.name) rowName = rowName.name;
                    if (colName && colName.name) colName = colName.name;

                    if (rowName) titles.push(rowName);
                    if (colName) titles.push(colName);

                    return showMentions(filter, titles.map(function(t) { return encloseInDisplayQuotes(t) }));
                })
            }
        },

        onClose: function() {
            if (this.__tip) {
                this.__tip.destroy();
                this.__tip = null;
            }
        }
    });

    function convertFieldToData(field) {
        return field == 'topic' || field == 'segment' ? 'tag' : field;
    }

    function mergeSentimentRows(data, rowField, colField) {
        var keep = [], i, row;
        // This is for groupBy sentimentVerified. We need to keep the mentionCount from each sentimentVerified:true
        // row as 'sampleSize' and use the mentionCount from sentimentVerified:null as 'mentionCount'. For a given
        // (rowField, colField) tuple there may be sentimentVerified null and/or true rows.
        rowField = convertFieldToData(rowField);
        colField = convertFieldToData(colField);

        var toKey = function(row) {
            var a = rowField  && row[rowField] ? row[rowField].id : null;
            var b = colField && row[colField] ? row[colField].id : null;
            return a + ":" + b;
        };

        // first find all the !sentimentVerified rows and put them into a map
        var map = { };
        for (i = 0; i < data.length; i++) {
            row = data[i];
            if (!row.sentimentVerified) {
                row.totalPositive = null;   // wipe out these field in case we don't have a matching verified row
                row.totalNeutral = null;
                row.totalNegative = null;
                map[toKey(row)] = row;
                keep.push(row);
            }
        }

        // now merge in matching verified rows
        for (i = 0; i < data.length; i++) {
            row = data[i];
            if (!row.sentimentVerified) continue;
            var prev = map[toKey(row)];
            if (prev) {
                // merge verified data into unverified row
                prev.sampleSize = row.mentionCount;
                prev.mentionCount += row.mentionCount;
                prev.totalPositive = row.totalPositive;
                prev.totalNeutral = row.totalNeutral;
                prev.totalNegative = row.totalNegative;
            } else {
                // no unverified row so use this one
                row.sampleSize = row.mentionCount;
                keep.push(row);
            }
        }
        return keep;
    }

    function convertV4FieldsToV3(row, lang, segmentListIds) {
        if (isNoneOfTheAbove(row.tag) || isNoneOfTheAbove(row.tag2)) return null;
        if (row.tag && row.tag2 && row.tag.namespace === row.tag2.namespace) return null;
        if (segmentListIds) {
            // we have to filter here as the section filter might pull in segments we don't want even though we
            // list the ones we do want in our filter
            if (row.tag && row.tag.namespace === 'segment'
                && (!row.tag.parent || segmentListIds.indexOf(row.tag.parent.id) < 0)) return null;
            if (row.tag2 && row.tag2.namespace === 'segment'
                && (!row.tag2.parent || segmentListIds.indexOf(row.tag2.parent.id) < 0)) return null;
        }
        if (row.brand) {
            row.brandName = row.brand.shortName || row.brand.fullName;
            row.brand = row.brand.id;
        }
        if (row.gender !== undefined) {
            row.genderLabel = row.gender ? row.gender.label : "Unknown";
            row.gender = row.gender ? row.gender.id : "UNKNOWN";
        }
        convertTag(row, row.tag, lang);
        convertTag(row, row.tag2, lang);
        row.count = row.mentionCount;
        if (row.authorNameCount !== undefined) row.authorNames = row.authorNameCount;
        if (row.totalEngagement !== undefined) row.engagement = row.totalEngagement;
        if (row.totalPositive !== undefined) row.positiveCount = row.totalPositive;
        if (row.totalNeutral !== undefined) row.neutralCount = row.totalNeutral;
        if (row.totalNegative !== undefined) row.negativeCount = row.totalNegative;
        if (row.authorNameCount !== undefined) row.authorNames = row.authorNameCount;
        var pub = row.published;
        if (pub) {
            if (pub.length == 7) row.published = pub + "-01";  // yyyy-MM
            else if (pub.length == 4) row.published = pub + "-01-01";  // yyyy
        }

        var aw, moe;
        if (row.sampleSize) {
            if (row.totalPositive) {
                aw = adjustedWald(row.sampleSize, row.totalPositive);
                moe = calcMarginOfError(aw.high, aw.low, row.totalPositive);
                row.posPercentageMOE = percentage(moe, row.sampleSize);
            }
            if (row.totalNegative) {
                aw = adjustedWald(row.sampleSize, row.totalNegative);
                moe = calcMarginOfError(aw.high, aw.low, row.totalNegative);
                row.negPercentageMOE = percentage(moe, row.sampleSize);
            }
        }

        return row;
    }

    var percentage = function(a, b) {
        if (!Number.isFinite(a) || !Number.isFinite(b)) return 0;
        return b ? Math.floor(a * 100 / b) + '%' : 'n/a';
    };

    function calcMarginOfError(high, low, count) {
        return Math.max(count - low, high - count);
    }

    function isNoneOfTheAbove(tag) {
        return tag && tag.namespace === 'segment' && tag.name.toLowerCase().indexOf('none of the above') >= 0;
    }

    function convertTag(b, tag, lang) {
        if (!tag) return;
        if (tag.namespace === 'topic') {
            b.topic = tag.id;
            b.topicName = tag.labels[lang] || tag.name;
            b.topicNamespace = tag.namespace;
            b.topicIsLeaf = tag.leaf;
            b.labels = tag.labels;
            b.descriptions = tag.descriptions;
        } else if (tag.namespace === 'segment') {
            b.segment = tag.id;
            b.segmentName = tag.labels[lang] || tag.name;
            if (tag.index) b.segmentIndex = tag.index;
        } else {
            b.tagName = tag.name;
            b.tag = tag.id;
        }
    }

    function tagIdsToOrTerm(tagIds, operand) {
        if (!tagIds) return null;
        var a = splitAtSpaces(tagIds);
        return "(" + a.map(function(id) { return operand + " IS " + id }).join(" OR ") + ")";
    }

    this.SettingsView = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/comparisonmatrix/ComparisonMatrixSettings.handlebars"),

        attributes: { 'class': 'matrix-settings' },

        editAttributes: function() {
            return Object.keys(defaults).concat('colour-index', 'colour-palette', 'colour-palette-custom');
        },

        regions: {
            colourSelector: '.colour-selector'
        },

        events: {
            "change .checkbox": "checkboxChanged",
            "click .btn-group .btn": "buttonGroupChanged",
            "click .colour-scheme": "showColourSelector",
            "click .colour-back":   "hideColourSelector"
        },

        bindings: function() {
            return {
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
                topicView: { converterFactory: Beef.TopicViewPicker.createConverterFactory("Select a Topic Tree"), elAttribute: "data-value" },
                segments: { converterFactory: Beef.TagPicker.createConverterFactory(null, {noHighlightSegments: true}),
                    elAttribute: "data-value" },
                tags: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            this.modelToView();

            this.colourSelector.show(new Beef.ColourSettings.View({
                model: this.model,
                dashboardModel: this.options.originalModel.getDashboardModel()
            }));

            Beef.LanguagePicker.attach(this, ".language", "language");
            Beef.TopicViewPicker.attach(this, ".topic-view", "topicView", {onlyOne: true, onlyTrees: false});
            Beef.TagPicker.attach(this, ".segments", "segments", {
                searchFilter: Beef.TagPicker.segmentListChooser,
                startDroppedDown: true,
                noHighlightSegments: true
            });
            Beef.TagPicker.attach(this, ".tags", "tags");
        },

        templateHelpers: function() {
            return {
                colour: getPalette(this.model.attributes,
                    this.options.originalModel.getDashboardModel().attributes)[0]
            }
        },

        modelToView: function() {
            this.$(".btn-group .btn").removeClass('active');

            var attr = this.model.attributes;
            this.$('.rowField button[data-value="' + attr.rowField +'"]').addClass('active');
            this.$('.colField button[data-value="' + attr.colField +'"]').addClass('active');
            this.$('.barField button[data-value="' + attr.barField +'"]').addClass('active');
            this.$('.vis button[data-value="' + attr.vis +'"]').addClass('active');
            this.$('.timeBucket button[data-value="' + attr.timeBucket +'"]').addClass('active');

            var usingSegments = attr.colField == 'segment' || attr.rowField == 'segment';
            var usingTopics = attr.colField == 'topic' || attr.rowField == 'topic';
            var usingTags = attr.colField == 'tag' || attr.rowField == 'tag';
            this.$('.segments-row').toggleClass('hide', !usingSegments);
            this.$('.topic-row').toggleClass('hide', !usingTopics);
            this.$('.language-row').toggleClass('hide', !usingSegments && !usingTopics);
            this.$('.tags-row').toggleClass('hide', !usingTags);

            this.$('.timeBucket-col').toggleClass('hide', attr.vis === 'bubbles');

            _.each(attr, function(value, key){
                if (value && key.indexOf("show") == 0) this.$('.' + key).attr('checked', value);
            }, this);
        },

        checkboxChanged: function(ev) {
            var $t = $(ev.target).closest("input");
            var key = $t.attr('class');
            if (key) {
                this.model.set(key, $t.is(":checked"));
                if (this.options.owner) this.options.owner.preview();
            }
            this.modelToView();
        },

        buttonGroupChanged: function(ev) {
            var $t = $(ev.target).closest(".btn");
            if (!$t.hasClass("disabled")) {
                var value = $t.attr('data-value');
                var key = $t.closest(".btn-group").attr('data-key');
                if (key && value) {
                    var old = this.model.get(key);
                    this.model.set(key, value);

                    if (key == 'rowField' || key == 'colField') {
                        // don't allow row and col to be the same unless they are both 'none'
                        var rf = this.model.get('rowField');
                        var cf = this.model.get('colField');
                        if (rf == cf) {
                            if (rf != 'none') {
                                if (key == 'rowField') this.model.set('colField', old);
                                else this.model.set('rowField', old);
                            }
                        }
                    }

                    if (this.options.owner) this.options.owner.preview();
                }
            }
            this.modelToView();
        },

        showColourSelector: function() {
            this.$('.matrix-settings-main').hide();
            this.$('.colour-page').show();
        },

        hideColourSelector: function() {
            this.$('.matrix-settings-main').show();
            this.$('.colour-page').hide();
        }
    });
});