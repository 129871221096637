<template>
    <section>
        <overview-description>
            <h4>Trending keywords {{ englishDate }}</h4>
            <p>
                These are words that people have been using a lot in your account, and
                how unusual their occurrences are.
            </p>
        </overview-description>

        <section v-if="error">
            <i class="symbol-warning"></i>
            {{error}}
        </section>

        <section class="keyword-trends__table">
            <div class="keyword-trends__trend-table">
                <trend-table v-if="words.length"
                             :topics="words"
                             :disabled="loading"
                             with-footer
                             with-max-date
                             with-average
                             :size-restriction="10"
                             show-new
                             wordtype="Keywords"
                             @seeMentions="seeMentions($event)"
                             @visible-changed="onVisibleChanged($event)"/>
                <section v-else-if="loading && !words.length">
                    <loading-message message="Reading your keywords..."/>
                </section>
                <no-topics-message v-else-if="brand && !loading && !words.length" class="keyword-trends__none">
                    <p>
                        No trending keywords
                    </p>
                </no-topics-message>
                <short-loading v-if="loading && words.length"/>
            </div>


            <navigation-controls @filter="additionalFilter = $event" :loading="loading"/>
        </section>
    </section>
</template>

<script>

import LoadingMessage from "@/components/LoadingMessage";
import {fetchSentimentForKeyword, loadKeywordTrendsForBrand,} from "@/app/toplevel/explore/overview/ExploreUtilities";
import TrendTable from "@/app/toplevel/explore/overview/TrendTable";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {mapGetters} from "vuex";
import {earliestDate, latestDate} from "@/dashboards/filter/FilterParser";
import NavigationControls from "@/app/toplevel/explore/overview/components/NavigationControls";
import ShortLoading from "@/app/toplevel/explore/overview/components/ShortLoading";

export default {
    components: {OverviewDescription, ShortLoading, NavigationControls, NoTopicsMessage, TrendTable, LoadingMessage },

    props: {
        brand: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            status: null,
            loading: false,
            words: [],
            additionalFilter: null,

            error: null
        }
    },

    computed: {
        ...mapGetters('explorePanel', ['dateFilter', 'englishDate'])
    },

    watch: {
        brand() {
            this.readTrends();
        },

        dateFilter() {
            this.readTrends();
        },

        additionalFilter() {
            this.readTrends();
        }
    },

    mounted() {
        this.readTrends();
    },

    methods: {
        seeMentions(word) {
            showMentions(word.filter,
                `Mentions for '${word.topic.name}'`,
                true)
        },

        onVisibleChanged(visible) {
            for (const word of visible) {
                if (Number.isFinite(word.net)) continue;
                fetchSentimentForKeyword(this.brand.id, word.topic.name).then(val => word.net = val).catch(e => console.warn("Unable to calculate sentiment", e));
            }
        },

        async readTrends() {
            this.error = null;
            // this.words = []; don't reset. Allows for better reloading.
            try {
                this.loading = true;
                const start = earliestDate(this.dateFilter);
                const end = latestDate(this.dateFilter);
                const words = await loadKeywordTrendsForBrand(this.brand.id, start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"), this.additionalFilter);

                this.words = words
                    .filter(w => w.periodCount > 5)
                    .splice(0, 1000)
                    .map(word => {
                        return {
                            delta: word.delta ?? null,
                            topic: {
                                id: word.word,
                                name: word.word
                            },
                            net: null,
                            weekAverage: word.periodAverage,
                            weekTotal: word.periodCount,
                            average: word.longAverage,
                            longStddev: word.longStddev,
                            periodStddev: word.periodStddev,
                            filter: word.filter,
                            max: word.max,
                            maxDate: word.maxDate,
                            isSpike: word.spike
                        }
                    })

                this.loading = false;
            } catch(e) {
                console.error(e);
                this.error = "There has been an error updating the trending keyword list. Please contact support if this continues.";
                this.words = [];
            } finally {
                this.loading = false;
            }
        }
    }
}

</script>


<style scoped lang="sass">

.keyword-trends__table
    display: flex
    flex-wrap: wrap
    flex-direction: row
    margin: 0 auto
    max-width: 90%

.keyword-trends__trend-table
    width: 800px
    margin-right: 20px


.symbol-warning
    color: orangered

</style>