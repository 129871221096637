var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"add-section section-cta-big thumbnail\">\n        <h1>Add a section</h1>\n        <p>A section uses a range of filters to look at a slice of your conversation. So you can isolate\n            conversation from Twitter only, for example, or conversation originating from a specific list\n            of authors. If you want to report on different slices of your conversation in the same dashboard,\n            use multiple sections.</p>\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"editDashboards") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "        <a class=\"duplicate-dashboard section-cta-big thumbnail\">\n            <h1>Duplicate This Dashboard</h1>\n            <p>You cannot edit this dashboard but you can make a copy of it and edit the copy.</p>\n        </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid dashboard-section-list\">\n</div>\n\n<div class=\"row-fluid hide-in-fullscreen\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});