import { beef } from '@/store/Services'
import { isCampaignDashboardLink, getCampaignFromLink, getAnchorSectionId } from "@/dashboards/DashboardUtils";
import moment from "moment";
import VuexStore from "@/store/vuex/VuexStore";
import {account} from "@/app/utils/Account";
import {getBrowserId} from "@/app/utils/Util";
import {getBrowser} from "know-your-platform";


/**
 * Logs account usage statistics
 * @param {Number,optional} userId
 * @returns {Promise<void>}
 */

export async function logUserSeen(userId) {
    userId ??= VuexStore.state.user?.id;
    if (alreadyLoggedToday("dataeq:logs:user-seen:" + userId)) return Promise.resolve();

    try {
        await beef.get("/api/logging/users/" + userId);

        if (navigator?.userAgent) {
            const browser = getBrowser(navigator.userAgent);
            await beef.put("/api/logging/browsers/", {
                browser,
                id: getBrowserId()
            });
        }
    } catch(e) {
        console.warn(e);
    }
}

/**
 * Logs that a particular page has been seen by the given user (for a particular day).
 * @param {string} page
 * @param {Number,optional} userId
 * @return {void}
 */
export function logPageUsed(page, userId) {
    if (!page) throw new Error("No page specified");
    userId ??= VuexStore.state.user?.id;

    new Promise((resolve, reject) => {
        logUserSeen(userId)
            .then(() => {
                const account = VuexStore.state.account;
                if (!account) {
                    // Some pages, like the account selection page, may not have an account set.
                    resolve();
                    return;
                }

                const key = `dataeq:logs:page-seen:${account.code}:${userId}:${page}`;
                if (alreadyLoggedToday(key)) {
                    resolve();
                    return;
                }

                return beef.get(`/api/logging/accounts/${account.code}/users/${userId}/pages/${encodeURIComponent(page)}`)
                    .then(resolve);
            })
            .catch(reject);
    }).catch(e => console.warn(e))
}

/**
 * Logs if a user is using a particular account.
 * @returns {Promise<AxiosResponse<any> | void>|Promise<void>}
 */
export function logAccountUsed() {
    const userId = Beef.user.get('id');
    const code = account().code;
    if (!code) return logUserSeen(userId);

    if (alreadyLoggedToday("dataeq:logs:account-seen:" + code + ":" + userId)) return Promise.resolve();

    return beef.get("/api/logging/accounts/" + code + "/users/" + userId)
        .catch(function(error) { console.warn("Error logging usage: ", error); })
}

/**
 * Logs if a user is using a paritcular dashboard.
 * @param dashboardId
 * @returns {Promise<AxiosResponse<any> | void>|Promise<void>|Promise<AxiosResponse<*>|void>|Promise<void>}
 */
export function logDashboardUsed(dashboardId) {
    try {
        const userId = Beef.user.get('id');
        const code = account().code;

        if (!dashboardId) return logAccountUsed();
        if (!code) return logUserSeen(userId);


        // record stats for campaign if required
        let campaign = "";
        if (isCampaignDashboardLink()) {
            campaign = `?campaign=${getCampaignFromLink()}&section=${getAnchorSectionId()}`

            if (alreadyLoggedToday("dataeq:logs:dashboard-seen:" + code + ":campaign:" + campaign + ":" + userId + ":" + dashboardId + ":" + getAnchorSectionId())) {
                return Promise.resolve();
            }
        }

        if (alreadyLoggedToday("dataeq:logs:dashboard-seen:" + code + ":" + userId + ":" + dashboardId) && !campaign) return Promise.resolve();

        return beef.get("/api/logging/accounts/" + code + "/users/" + userId + "/dashboards/" + dashboardId + campaign)
            .catch(function(error) { console.warn("Error logging usage: ", error); })
    } catch (e) {
        console.warn("Error logging dashboard usage", e);
    }
}

function alreadyLoggedToday(key) {
    let seen = {};
    let date = (new moment()).format("YYYY-MM-DD");

    try {
        if (localStorage.getItem(key)) {
            seen = JSON.parse(localStorage.getItem(key));

            if (seen && seen[date]) return true;
            if (seen) seen = {}; // Remove previous days data.
        }
    } catch(error) {
        console.warn("Error reading seen logs", error)
    }

    seen[date] = true;
    try {
        localStorage.setItem(key, JSON.stringify(seen));
    } catch(error) {
        console.warn("Error writing seen logs", error)
    }

    return false;
}