/**
 * Calendar view. Based on: http://www.eyecon.ro/bootstrap-datepicker
 *
 * Model:
 * date: the selected date
 * start: start of highlighted date range
 * end: end of highlighted date range
 */

import moment from "moment";

Beef.module("Calendar", function() {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/app/Calendar.handlebars"),

        attributes: { "class": "calendar" },

        modelEvents: {
            "change:mode": "modeChanged",
            "change:date": "dateChanged",
            "change:viewDate": "fill",
            "change:start": "fill",
            "change:end": "fill"
        },

        events: {
            "click": "click"
        },

        initialize: function(options) {
            options = options || {};
            this.weekStart = this.options.weekStart || 1;
            this.weekEnd = this.weekStart === 0 ? 6 : this.weekStart - 1;

            if (!this.model) this.model = new Backbone.Model();

            var date = this.model.get('date');
            if (!date) this.model.set('date', date = new Date());
            this.model.set('viewDate', new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0));
            if (options.minDate) {
                this.model.set('minDate', options.minDate);
            }
            if (options.maxDate) {
                this.model.set('maxDate', options.maxDate);
            }
        },

        onRender: function() {
            this.fillDow();
            this.fillMonths();
            this.fill();
        },

        dateChanged: function() {
            var date = this.model.get('date');
            this.model.set('viewDate', date ? date : new Date());
        },

        modeChanged: function() {
            this.$el.find("> table").hide();
            this.$el.find("> table.calendar-" + DPGlobal.modes[this.model.get('mode')].clsName).show();
        },

        fillDow: function(){
            var dowCnt = this.weekStart;
            var html = '<tr>';
            while (dowCnt < this.weekStart + 7) {
                html += '<th class="dow">'+DPGlobal.dates.daysMin[(dowCnt++)%7]+'</th>';
            }
            html += '</tr>';
            this.$el.find('.calendar-days thead').append(html);
        },

        fillMonths: function(){
            var html = '';
            var i = 0;
            while (i < 12) {
                html += '<span class="month">'+DPGlobal.dates.monthsShort[i++]+'</span>';
            }
            this.$el.find('.calendar-months td').append(html);
        },

        fill: function() {
            var md = this.model.get('date');
            if (!md) md = new Date();

            var d = new Date(this.model.get('viewDate')),
                year = d.getFullYear(),
                month = d.getMonth(),
                currentDate = md.valueOf(),
                start = this.model.get('start'),
                end = this.model.get('end');

            if (start) start = moment(start).startOf('day').valueOf();
            if (end) end = moment(end).startOf('day').valueOf();

            this.$el.find('.calendar-days th:eq(1)')
                .text(DPGlobal.dates.months[month]+' '+year);
            var prevMonth = new Date(year, month-1, 28,0,0,0,0),
                day = DPGlobal.getDaysInMonth(prevMonth.getFullYear(), prevMonth.getMonth());
            prevMonth.setDate(day);
            prevMonth.setDate(day - (prevMonth.getDay() - this.weekStart + 7)%7);
            var nextMonth = new Date(prevMonth);
            nextMonth.setDate(nextMonth.getDate() + 42);
            nextMonth = nextMonth.valueOf();
            var html = [];
            var clsName;
            while(prevMonth.valueOf() < nextMonth) {
                if (prevMonth.getDay() === this.weekStart) {
                    html.push('<tr>');
                }
                clsName = '';
                if (prevMonth.getMonth() < month) {
                    clsName += ' old';
                } else if (prevMonth.getMonth() > month) {
                    clsName += ' new';
                }
                if (prevMonth.valueOf() === currentDate) {
                    clsName += ' active';
                }
                if (start && end) {
                    var time = prevMonth.getTime();
                    if (time >= start && time <= end) clsName += " selected";
                }
                if (!this.isDateAllowed(prevMonth)) {
                    clsName += ' disabled';
                }

                html.push('<td class="day'+clsName+'">'+prevMonth.getDate() + '</td>');
                if (prevMonth.getDay() === this.weekEnd) {
                    html.push('</tr>');
                }
                prevMonth.setDate(prevMonth.getDate()+1);
            }
            this.$el.find('.calendar-days tbody').empty().append(html.join(''));
            var currentYear = md.getFullYear();

            var months = this.$el.find('.calendar-months')
                .find('th:eq(1)')
                .text(year)
                .end()
                .find('span').removeClass('active');
            if (currentYear === year) {
                months.eq(md.getMonth()).addClass('active');
            }

            html = '';
            year = parseInt(year/10, 10) * 10;
            var yearCont = this.$el.find('.calendar-years')
                .find('th:eq(1)')
                .text(year + '-' + (year + 9))
                .end()
                .find('td');
            year -= 1;
            for (var i = -1; i < 11; i++) {
                html += '<span class="year'+(i === -1 || i === 10 ? ' old' : '')+(currentYear === year ? ' active' : '')+'">'+year+'</span>';
                year += 1;
            }
            yearCont.html(html);
        },

        /**
         * Checks whether a date falls within the min and max date set on this view's model. If no min and max
         * are defined, the date is always valid.
         * @param {Date} date The date to check.
         * @return {boolean} True if the date is valid.
         */
        isDateAllowed: function(date) {
            var allowed = true;
            var min = this.model.get("minDate");
            var max = this.model.get("maxDate");
            if (min) {
                allowed = (allowed && (date.getTime() >= min.getTime()));
            }
            if (max) {
                allowed = (allowed && (date.getTime() <= max.getTime()));
            }
            return allowed;
        },

        click: function(e) {
            e.stopPropagation();
            e.preventDefault();
            var target = $(e.target).closest('span, td, th');
            if (target.length === 1) {
                var viewDate = new Date(this.model.get('viewDate').getTime());
                var changes = {};
                var month, year, mode;
                switch(target[0].nodeName.toLowerCase()) {
                    case 'th':
                        switch(target[0].className) {
                            case 'switch':
                                changes.mode = ((this.model.get('mode') || 0) + 1) % 3;
                                break;
                            case 'prev':
                            case 'next':
                                mode = DPGlobal.modes[this.model.get('mode') || 0];
                                viewDate['set'+mode.navFnc].call(
                                    viewDate,
                                    viewDate['get'+mode.navFnc].call(viewDate) +
                                        mode.navStep * (target[0].className === 'prev' ? -1 : 1)
                                );
                                changes.viewDate =  viewDate;
                                break;
                        }
                        break;
                    case 'span':
                        if (target.is('.month')) {
                            month = target.parent().find('span').index(target);
                            viewDate.setMonth(month);
                            changes.viewDate = viewDate;
                        } else {
                            year = parseInt(target.text(), 10)||0;
                            viewDate.setFullYear(year);
                            changes.viewDate = viewDate;
                        }
                        mode = this.model.get('mode') || 0;
                        if (mode !== 0) changes.date = viewDate;
                        changes.mode = (mode + 2) % 3;
                        break;
                    case 'td':
                        if (target.is('.day') && !target.is('.disabled')){
                            var day = parseInt(target.text(), 10)||1;
                            month = viewDate.getMonth();
                            if (target.is('.old')) {
                                month -= 1;
                            } else if (target.is('.new')) {
                                month += 1;
                            }
                            year = viewDate.getFullYear();
                            changes.date = new Date(year, month, day,0,0,0,0);
                            changes.viewDate = new Date(year, month, Math.min(28, day),0,0,0,0);
                        }
                        break;
                }
                this.model.set(changes);
            }
        }
    });

    var DPGlobal = {
        modes: [
            {
                clsName: 'days',
                navFnc: 'Month',
                navStep: 1
            },
            {
                clsName: 'months',
                navFnc: 'FullYear',
                navStep: 1
            },
            {
                clsName: 'years',
                navFnc: 'FullYear',
                navStep: 10
            }],
        dates:{
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        },
        isLeapYear: function (year) {
            return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
        },
        getDaysInMonth: function (year, month) {
            return [31, (DPGlobal.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
        }
    };
});
