import {cleanFilenameParts, getBrowserId, isFunction, isObject} from "@/app/utils/Util";
import WarningIndicator from "../components/WarningIndicator";
import {deprecatedUserObject} from '@/store/deprecated/Stores'
import {account} from "@/app/utils/Account";
import {grouseGet} from "@/data/Grouse";
import {deprecatedSnoekGetJson} from "@/data/Snoek";
import {logDashboardUsed} from "@/app/utils/UserAccessLog";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {buildBasicFilter} from "@/dashboards/filter/BasicFilter";
import {dashboardIsEditable, updateOldDashboard} from "@/dashboards/DashboardUtils";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import {editDashboards} from "@/app/Permissions";
import {schemeToPaletteOptions} from "@/app/utils/Colours";
import {notifyUser, showBusyNotification} from "@/app/framework/notifications/Notifications";
import VuexStore from "@/store/vuex/VuexStore";
import {showTip} from "@/app/help/tips/tips";
import {escapeExpression} from "@/app/utils/StringUtils";
import _ from 'underscore';
import moment from "moment";
import {setTitle} from "@/app/Beef";


Beef.module("Dashboard").addInitializer(function(startupOptions) {

    var thisModule = this;

    // This token is included in all requests by dashboards to get data from grouse to indicate to grouse that
    // the data should have headers set to be cached on the client. AJAX calls that might show the user mentions
    // or that modify data (other than dashboards) reset the token dashboards retrieve fresh data.

    this.getCacheToken = function() {
        return getBrowserId() + "-" + (localStorage.getItem("dashboardCacheToken") || "");
    };

    this.clearCache = function() {
        localStorage.setItem("dashboardCacheToken",
            Math.floor(new Date().getTime() + Math.random() * 1000000).toString(36));
    };

    var isCacheTokenUpdateRequired = function(settings) {
        var url = settings.url;
        if (url.indexOf("/reports") >= 0) return false;
        if (settings.type !== "GET") return true;
        if (url.indexOf("/mentions") >= 0 && url.indexOf("cacheToken=") < 0) return true;
        return false;
    };

    $(document).ajaxSend(function(event, jqxhr, settings) {
        if (isCacheTokenUpdateRequired(settings)) Beef.Dashboard.clearCache();
    });

    /**
     * Are we in presentation or fullscreen mode?
     */
    const isPresentationMode = function() {
        return BigScreen.element || $(document.documentElement).hasClass("fullscreen")
    }
    this.isPresentationMode = isPresentationMode

    this.getJsonFromSnoek = function(endpoint, data, callback) {
        data = data || {};
        data.cacheToken = Beef.Dashboard.getCacheToken();
        if (isPresentationMode() && !data.cacheValidFor) data.cacheValidFor = 15 * 60;  // cache for longer in fullscreen mode
        return deprecatedSnoekGetJson(endpoint, data, callback);
    };

    /**
     * Calls data from grouse. Manages browser caching for grouse. Returns a
     * promise.
     *
     * Importantly, this handles browser cache tokens for the API.
     */
    this.getJsonFromGrouse = function(endpoint, data) {
        data = data || {};
        data.cacheToken = Beef.Dashboard.getCacheToken();
        if (isPresentationMode() && !data.cacheValidFor) data.cacheValidFor = 15 * 60;  // cache for longer in fullscreen mode

        return grouseGet(endpoint, data);
    };

    this.WidgetModel = Backbone.Model.extend({

        constructor: function(attrs, options) {
            if (attrs) {
                schemeToPaletteOptions(attrs);
                if (attrs.deltaOpt !== undefined) {
                    attrs['show-deltas'] = attrs.deltaOpt;
                    delete attrs.deltaOpt;
                }
                if (attrs.deltaExact !== undefined) {
                    attrs['show-exact-deltas'] = attrs.deltaExact;
                    delete attrs.deltaExact;
                }
                Beef.Widget.Stats.fixLegacyAttributes(attrs);
            }
            Backbone.Model.apply(this, arguments);
        },

        entityName: "Graph",

        validation: {
            caption: { required:true },
            'max-items': {
                required:   false,
                range:      [1, 100],
                msg:        "Please choose between 1 and 100 items to view"
            },
            'max-comparisons': {
                required:   false,
                range:      [1, 10],
                msg:        "Please choose between 1 and 10 items to compare"
            },
            'max-font': {
                required: false,
                min: 1,
                msg: "The font cannot be smaller than 1 pixel"
            },
            'min-font': {
                required: false,
                min: 1,
                msg: "The font must be larger than 1 pixel"
            },
            'num-words': {
                required: false,
                min: 1,
                msg: "The value must be larger than 1"
            },
            'group-threshold': {
                required: false,
                min: 1,
                msg: "The value must be larger than 1"
            }
        },

        getSectionModel: function() {
            return this.collection ? this.collection.owner : null;
        },

        getDashboardModel: function() {
            return this.getSectionModel().getDashboardModel();
        },

        isEditable: function() {
            var sm = this.getSectionModel();
            return sm && sm.isEditable();
        },

        getInteractiveFilterModel: function() {
            return this.getSectionModel().get('_interactiveFilterModel');
        },

        getDataHighlightModel: function () {
            return null;
        },

        getFilter: function() {
            var widgetFilter = this.get('filter');
            return appendFiltersReadably(this.getSectionModel().getFilter(), widgetFilter);
        },

        getCompare: function() {
            return this.getSectionModel().get('compare');
        }
    });

    this.WidgetList = Backbone.Collection.extend({
        model: this.WidgetModel
    });

    this.SectionModel = Backbone.Model.extend({
        entityName: "Section",

        nestedModels: {
            widgets: this.WidgetList
        },

        validation: {
            title: { required:true },
            'animation-delay': { required: false, min: 5 },
            'refresh-interval': { required: false, min: 5 }
        },

        defaults: function() {
            var ifm = new Backbone.Model();
            var dhm = new Backbone.Model();
            return {
                filter: "RELEVANCY isnt IRRELEVANT AND Published INTHELAST WEEK",
                _interactiveFilterModel: ifm,
                _dataHighlightModel: dhm
            }
        },

        initialize: function(attrs, options) {
            var ifm = this.getInteractiveFilterModel();
            if (attrs.widgets) {
                // if we have a brand drill down then select a default brand to display
                let widgets = attrs.widgets;
                for (var i = 0; i < widgets.length; i++) {
                    if ("BrandSelector" == widgets[i].type) {
                        var brand = Beef.Widget.BrandSelector.getDefaultBrand(attrs.filter);
                        if (brand) ifm.set('brand', "" + brand.id);
                        break;
                    }
                }
            }
            ifm.on("change", this.interactiveFilterModelChanged, this);
        },

        getDashboardModel: function() {
            return this.collection ? this.collection.owner : null;
        },

        isEditable: function() {
            var dm = this.getDashboardModel();
            return dm && dm.isEditable();
        },

        getInteractiveFilterModel: function() {
            return this.get('_interactiveFilterModel');
        },

        getDataHighlightModel: function() {
            return null;
        },

        /**
         * Returns the filter that widgets in the section should use.
         * This includes any modifications from drilldowns / drill downs.
         */
        getFilter: function() {
            var a = this.getInteractiveFilterModel().attributes;
            var xf = _.isEmpty(a) ? null : buildBasicFilter(a);
            var f = this.get('filter') || "";
            var filter = appendFiltersReadably(f, xf);
            if (a._cxSelector) {
                filter = appendFiltersReadably(filter, a._cxSelector);
            }
            if (a._showWhatDrilldown) {
                filter = appendFiltersReadably(filter, a._showWhatDrilldown);
            }
            return filter;
        },

        interactiveFilterModelChanged: function(model, value) {
            this.trigger('change:filter', this, this.get('filter'));
        }
    });

    this.SectionList = Backbone.Collection.extend({
        model: this.SectionModel
    });

    this.Model = Beef.Model.Root.extend({
        entityName: "Dashboard",
        nestedModels: {
            sections: this.SectionList
        },
        defaults: {
            "type":  "NORMAL",

            /** The category a dashboard is placed in when shown on the sidebar. */
            category: "",

            /** Whether a dashboard is displayed in the sidebar. Set to private to avoid saving it back to server. */
            _display: true
        },
        validation: {
            name: { required:true },
            category: { required:false },
            ordinal: { range: [1,999], required: false }
        },
        isEditable: function() {
            return dashboardIsEditable(this.attributes);
        },

        save: function(attrs, options) {
            if (!options) options = { }

            let success = options.success
            options.success = function(model, resp) {
                if (VuexStore.getters["dashboards/idToDashboard"].has(resp.id)) {
                    VuexStore.commit("dashboards/setDashboard", {value: resp, dashboardId: resp.id});
                }

                if (success) success(model, resp)
            };

            return Beef.Model.Root.prototype.save.call(this, attrs, options);
        },

        parse: function(data, options) {
            return updateOldDashboard(data);
        },
    });

    this.View = Beef.BoundItemView.extend({
        attributes: function() {
            return { class: "row-fluid dashboard" }
        },

        template: require("@/dashboards/Dashboard.handlebars"),

        scrollTimeout: 600,

        templateHelpers: function() {
            var dashboard = this.model;
            return {
                editable: dashboard.isEditable(),
                editDashboards: editDashboards(),
                accountInactive: !!account().inactive
            }
        },

        regions: {
            sections: "> div.sections",
            warnings: "> .title .warn-area"
        },

        events: {
            "click > .title .editable": "edit",
            "click > .title .options": "displayMenu",
            "click .add-section": "addSection",
            "click .duplicate-dashboard": "copy",
            "click .unlock-dashboard": "unlock",
            "click .change-dashboard": "changeDashboard"
        },

        modelEvents: {
            "change:sections": "updateSections",
            "change": "maybeRefreshWidgets",
            "change:name": "nameChange",
            "change:font": "updateClass"
        },

        onRender: function() {
            if(!this.model.isEditable()) {
                this.$('.add-section').hide();
                this.$('.edit-dashboard').removeClass("editable");
            }
            this.updateSections();
            setTitle(this.model.get('name'));
            this.updateClass();
            showTip("CREATE_NOTIFICATION_FOR_DASHBOARD");
        },

        onFirstRender: function() {
            logDashboardUsed(this.model.get('id'));
            this.isWhite = !Beef.generalData().get('dashboards-is-white');

            // We want the widget framework to know when the whole dashboard is scrolling, so that we can
            // stop widgets from taking over the scroll events and disrupting the scroll experience.
            this.boundScrollTimeoutHandler = function() {
                if (!this.isClosed) {
                    var diff = moment().diff(this.lastScrollTime);
                    if (diff < this.scrollTimeout) {
                        setTimeout(this.boundScrollTimeoutHandler, this.scrollTimeout);
                        return;
                    }

                    this.scrollTimeoutSet = false;
                    this.model.set('_scrolling', false);
                }
            }.bind(this);

            this.boundScrollHandler = function() {
                this.lastScrollTime = moment();
                this.model.set("_scrolling", true);
                if (!this.scrollTimeoutSet) {
                    setTimeout(this.boundScrollTimeoutHandler, this.scrollTimeout);
                    this.scrollTimeoutSet = true;
                }
            }.bind(this);

            $(window).on('scroll', this.boundScrollHandler);
        },

        onClose: function() {
            this.isClosed = true;
        },

        nameChange: function() {
            setTitle(this.model.get('name'));
        },

        maybeRefreshWidgets: function() {
            this.updateClass()
            var c = this.model.changedAttributes();
            if (c['colour-palette'] || c['colour-index'] || c['colour-palette-custom']) {
                this.eachWidget(function(widget){
                    if (widget && widget.model.generalData.get('_completed')) widget.refresh();
                });
            }
        },

        displayMenu: function() {
            var that = this;
            var sectionImagesLink = '/v4/accounts/' + account().code + '/dashboards/' + this.model.id + "/section-images"
            var mm = new Backbone.Model({view: this, sectionImagesLink: sectionImagesLink});
            Beef.MiniMenu.show({
                model: mm,
                template: require("@/dashboards/DashboardMenu.handlebars"),
                object: this,
                target: $("> .title .options", this.$el),
                onRender: async function() {
                    if(!that.model.isEditable()) {
                        this.$('#edit').addClass("disabled");
                        this.$('#addSection').addClass("disabled");
                        this.$('#delete').addClass("disabled");
                        this.$('#archive').addClass("disabled");
                    }
                    if(!editDashboards()) {
                        this.$('#copy').addClass("disabled");
                    }
                    if (that.model.get("category") === "Archived") {
                        this.$('#archive').addClass("disabled");
                    }

                    await VuexStore.dispatch('digests/refreshDigests');
                    const digests = VuexStore.state.digests.digests;
                    const used = digests.find(d => d.reportId === that.model.get('id'));
                    if (used) {
                        this.$('#editNotification').toggleClass('disabled', false);
                    }
                }
            });
        },

        /**
         * Opens up a popup for editing a dashboard. This should only be called for editable dashboards.
         * @param ev The event that triggered the edit menu popup.
         * @param msg A brief message describing the source of the event.
         */
        edit: function(ev, msg) {
            msg = ev && ev.preventDefault ? 'From title click' : (msg || 'From menu');
            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["bottom-right", "center"],
                alwaysMove: true,
                offsets: { left: 40 }
            });
            popup.setTarget(this.$("> .title h1"));
            var view = new Beef.DashboardSettings.View({model: this.model, cache: this.cache});
            view.on("close", () => { popup.hide(); this.trigger('dashboard-settings-close', {model: this.model});});
            popup.show(view);
        },

        copy: function() {
            this.trigger("duplicate-dashboard", this.model);
        },

        /**
         * Ask all of the contained sections to refresh their data and possibly redraw.
         */
        refresh: function() {
            Beef.Dashboard.clearCache();
            if (this.sections.currentView) this.sections.currentView.refresh();
        },

        getMenuTrigger: function() {
            return $('> .title .buttons .btn', this.$el);
        },

        addSection: function(ev) {
            var cv = this.sections.currentView;
            if (cv && cv.addSection) {
                cv.addSection();
            }
        },

        async editNotification(ev) {
            await VuexStore.dispatch('digests/refreshDigests');
            const digests = VuexStore.state.digests.digests;
            const notification = digests.find(d => d.reportId === this.model.get('id'));

            if (notification) {
                Beef.router.navigate(`/${account().code}/setup/notifications/${notification.id}`, {trigger: true})
            }
        },

        async subscribeToNotification(ev) {
            const busy = showBusyNotification("Subscribing you to your notification");

            try {
                await VuexStore.dispatch('digests/refreshDigests');

                // First, let's see if there is already a digest using this dashboard as a report.
                let notification = VuexStore.state.digests.digests.find(d => d.type === "MENTION_DIGEST" && d.reportId === this.model.get('id'));

                if (!notification) {
                    // Here we create a new one to use
                    const filter = "published inthelast 24hours and relevancy isnt irrelevant and reshareof is unknown";
                    const dashboardName = this.model.get('name');
                    let title = dashboardName;

                    // Ensure that the notification name is unique.
                    let i = 1;
                    while (VuexStore.state.digests.digests.find(d => d.name === title)) {
                        title = `${dashboardName} v${i++}`;
                    }

                    let description = `This is a weekly notification for the dashboard ${dashboardName}.`;
                    notification = await VuexStore.dispatch('digests/createDigest', {
                        name: title,
                        description: description,
                        active: true,
                        type: "MENTION_DIGEST",
                        filter: filter,
                        days: ['MON'],
                        times: ['0800'],
                        reportId: this.model.get('id'),
                        maxExampleMentions: 0,
                        recipients: [deprecatedUserObject.email]
                    });
                } else {
                    // See if we need to subscribe the user to the notification, or if they already are.
                    if (!notification.recipients?.find(email => email === deprecatedUserObject.email || email.includes(`<${deprecatedUserObject.email}>`))) {
                        notification = Object.assign({}, notification);
                        notification.recipients = [deprecatedUserObject.email, ...notification.recipients];
                        notification.active = true;
                        await VuexStore.dispatch('digests/updateDigest', notification);
                    }
                }

                busy.close();

                const formatDay = day => {
                    switch(day) {
                        case 'MON': return "Monday";
                        case 'TUE': return "Tuesday";
                        case 'WED': return "Wednesday";
                        case 'THU': return "Thursday";
                        case 'FRI': return "Friday";
                        case 'SAT': return "Saturday";
                        case 'SUN': return "Sunday";
                    }
                };

                const formatTime = time => `${time.slice(0,2)}:${time.slice(2)}`;

                notifyUser({
                    message: `You have been subscribed to the notification <strong>${escapeExpression(notification.name)}</strong>. 
 It will be sent to you every ${formatDay(notification.days[0])} at ${formatTime(notification.times[0])}`,
                    isEscapedHtml: true,
                    icon: '<i class="symbol-notification"></i>',
                    longDelay: true,
                    action: {
                        name: "Edit",
                        method: () => {
                            Beef.router.navigate(`/${account().code}/setup/notifications/${notification.id}`, {trigger: true})
                        },
                        tooltip: "Edit the notification's settings"
                    }
                })
            } catch(e) {
                console.error(e);
                busy.close();
                // noinspection ES6MissingAwait
                showErrorDialog("We were unable to create a notification for you. Please try again in a moment, or contact our support team.");
            }
        },

        updateClass: function() {
            let cls = this.attributes().class + " db-width-" + this.model.get('max-width')
            cls += " dashboard-font-" + (this.model.get('font') || account().font)
            this.$el.attr('class', cls);
            this.$el.toggleClass('white-background', this.isWhite);
            this.onWarningsChanged(); // This can update classes as well
        },

        updateSections: function() {
            var list = this.model.get('sections');
            if (list == null) {    // still loading sections
                this.sections.show(new Beef.Empty.View());
            } else if (!this.sections.currentView || !this.sections.currentView.collection) {
                this.sections.show(new Beef.SectionList.View({collection: list}));
                $("> .title .btn", this.$el).toggleClass("disabled", false);
                var shouldUpgrade = Beef.Widget.FantasticChart.Upgrade.shouldUpgrade(this.model);
                if (shouldUpgrade) {
                    console.info("Dashboard '" + this.model.get("name") + "' needs to be upgraded. Doing so now.");
                    Beef.Widget.FantasticChart.Upgrade.dashboard(this.model, true)
                        .catch(console.error);
                }
                this.listenTo(list, "checkwarnings", this.onWarningsChanged, this)
            }
        },

        updateAllViews: function() {
            var list = this.sections.currentView.children;
            _.each(list, function(section, id) { section.updateAllViews(); });
        },

        onWarningsChanged() {
            const warnings = [];
            const sections = this.model.get('sections');
            if (sections && sections.models) {
                sections.models.forEach(section => {
                    const sectionWarnings = section.get("_warnings");
                    if (sectionWarnings && sectionWarnings.length) {
                        sectionWarnings.forEach(w => warnings.push(Object.assign({
                            sectionId: section.id,
                            title: section.get('title') || '«No title»'
                        }, w)));
                    }
                })
            }

            let vue = null;
            if (warnings.length) {
                if (this.warnings.currentView) {
                    vue = this.warnings.currentView.vm;
                } else {
                    const view = new Beef.VuejsView.View({component: WarningIndicator, props: {tooltip: "This dashboard has warnings"}});
                    this.warnings.show(view);
                    vue = view.vm;
                }
            }
            if (vue) vue.warnings = warnings; // Don't need to create more dom elements if we don't need them
            this.$el.toggleClass("has-warnings", !!warnings.length);
        },

        /** Callback is invoked with all the widget views from our sections and the corresponding section view. */
        eachWidget: function(callback) {
            this.sections.currentView.children.each(function(section) {
                section.eachWidget(callback);
            });
        },

        /** Get a filename for data saved from this Dashboard. */
        getFilename: function() {
            var parts = [];
            parts.push(this.model.getAncestorProperty('accountCode'));
            parts.push(this.model.get('name') || this.cid);
            return cleanFilenameParts(parts).join('-');
        },

        /**
         * Calls prepForExport and calls the server for the export
         */
        exportPPT: function() {
            // get a list of widget models for the section
            var widgets = [];
            var sections = this.sections.currentView.children;
            _.each(sections, function(section, id) {
                var list = section.widgets.currentView.collection.models;
                for (var i = 0; i < list.length; ++i) {
                    var exportDisabled = list[i].view.imageExportDisabled();
                    if (!exportDisabled) {
                        widgets.push(list[i]);
                    }
                }
            }.bind(this));

            if (widgets.length) {
                var location = window.location;

                // get a list of widget models for the section
                var popup = new Beef.Popup.View({
                    closeOnHide: true,
                    positions:["center"],
                    alwaysMove: true
                });

                this.powerpointModel = new Backbone.Model({cancelled: false, duration: widgets.length, position: 0, done: false});
                popup.setTarget($(this.getMenuTrigger()));
                var view = new Beef.Powerpoint.View({model: this.powerpointModel});
                view.on("close", function() { popup.hide(); });
                popup.show(view);

                this.updateAllViews();
                this.exportWidgets(location, widgets);
            }
        },

        /**
         * Check to see if all widgets have loaded, if not, check again in 100ms
         */
        exportWidgets: function(location, widgets) {
            if (location == window.location && !this.powerpointModel.get('cancelled')) {
                var done = true;
                var sections = this.sections.currentView.children;
                _.each(sections, function(section, id) {
                    var list = section.widgets.currentView.collection.models;
                    for (var i = 0; i < list.length; ++i) {
                        if (!list[i].generalData.get('_completed')) {
                            done = false;
                        }
                    }
                }.bind(this));

                if (done) {
                    var that = this;
                    $.ajax({
                        url: "/ppt/open",
                        type: "POST",
                        contentType: "application/json",
                        processData: false,
                        data: JSON.stringify(cloneDashboard(that.model)),
                        success: function(id) {
                            that.sendWidget(id, widgets, 0);
                        },
                        error: function() {
                            that.powerpointModel.set('done', true);
                        }
                    });
                } else {
                    setTimeout(function(){
                        this.exportWidgets(location, widgets);
                    }.bind(this), 1000);
                }
            }
        },

        unsetXml: function() {
            var sections = this.sections.currentView.children;
            _.each(sections, function(section, id) {
                var list = section.widgets.currentView.collection.models;
                for (var i = 0; i < list.length; ++i) {
                    if(list[i].has("_xml")) list[i].unset("_xml")
                }
            }.bind(this));
        },


        sendWidget: function(id, list, i) {
            if (location == window.location && !this.powerpointModel.get('cancelled')) {
                var w = list[i];
                var widget = this.prepForExport(w);
                var that = this;
                $.ajax({
                    url: "/ppt/" + id + "/section/" + w.collection.owner.id + "/widget/" + widget.get('id'),
                    type: "POST",
                    contentType: "application/json",
                    processData: false,
                    data: JSON.stringify(cloneWidget(widget)),
                    success: function() {
                        that.powerpointModel.set('position', that.powerpointModel.get('position') + 1);
                        if(i + 1 < list.length) {
                            that.sendWidget(id, list, i + 1);
                        } else {
                            $.ajax({
                                url: "/ppt/" + id + "/close",
                                type: "POST",
                                contentType: "application/json",
                                processData: false,
                                data: JSON.stringify({close: true}),
                                success: function(data) {
                                    if (location == window.location && !that.powerpointModel.get('cancelled')) {
                                        that.powerpointModel.set('done', true);
                                        window.location = data.link;
                                        that.unsetXml();
                                    }
                                },
                                error: function() {
                                    that.powerpointModel.set('done', true);
                                    that.unsetXml();
                                }
                            });
                        }
                    },
                    error: function() {
                        that.powerpointModel.set('done', true);
                        that.unsetXml();
                    }
                });
            }
        },

        /**
         * Prepares the section for PPT export by preparing each widget's xml, title, width, height and text
         */
        prepForExport: function(w) {
            // If the widget does not have a width or height set, then set it to its default according to its type
            // or alternatively 2, if its type has no default value
            var widget = new Backbone.Model(Beef.Sync.cloneModel(w));

            var type = widget.get('type');

            if (type) type = Beef.WidgetRegistry.typeMap[type];
            if (type) {
                if (!widget.has('width') && type.width) widget.set('width', type.width, {silent:true});
                else if (!widget.has('width')) widget.set('width', 2, {silent:true});
                if (!widget.has('height') && type.height) widget.set('height', type.height, {silent:true});
                else if (!widget.has('height')) widget.set('height', 2, {silent:true});
                if (!widget.has('caption')) widget.set('caption', type.name, {silent:true});
            }

            // The cid is required to find the widget view and container
            var cid = w.cid;
            var $widget = $('.widget[widget-id="' + cid + '"]', this.$el);
            var $view = $('.widget-container', $widget);

            if (type.name != 'Conversation' && type.group != 'selector') {
                // Find any text in the widget
                var $text = $('td', $view);
                if (!$text) $text = $('.text', $view);
                if ($text) {
                    var text = "";
                    $.each($text, function(i, v) {
                        text = text + '\n' + v.textContent;
                    });

                    if (!text.trim()) {
                        text = '\n' + $text.prevObject[0].textContent;
                    }

                    widget.set('_text', text, {silent:true});
                }

                // Convert widget to xml and save it on model
                var imageData = w.view.prepareImageData('png');
                widget.set('_xml', imageData.xml, {silent:true});
                widget.set('_params', imageData.options, {silent:true});
            }
            return widget;
        },

        delete: function() {
            this.trigger("delete", this.model);
        },

        archive: function() {
            this.trigger("archive", this.model);
        },

        unlock: function(ev) {
            ev.preventDefault();
            this.trigger("unlock");
            // this.model.set("_unlocked", true);
            // this.owner.close();
            // this.owner.show(new thisModule.View({model: this.model}))
        },

        changeDashboard() {
            this.trigger("show-sidebar");
        },

        saveImages: function() {
            var widgetModels = [];
            this.sections.currentView.children.forEach(function(section) {
                section.stopSectionAnimation();
                Array.prototype.push.apply(widgetModels, section.widgets.currentView.collection.models)
            });
            Beef.Dashboard.saveImages(widgetModels, this.getFilename() + ".zip", this.getMenuTrigger());
        },

        saveSectionImages: function() {
            window.open('/v4/accounts/' + account().code + '/dashboards/' + this.model.id + "/section-images.pdf",
                '_blank')
        }
    });

    /**
     * Deep-clone attrs leaving out fields starting with underscore and converting Backbone models to JSON.
     */
    var cloneDashboard = function(attrs) {
        return prepareDataImpl(attrs);
    };

    var prepareDataImpl = function(attrs) {
        var i, ans;
        if (!attrs) return attrs;
        if (isFunction(attrs.toJSON)) attrs = attrs.toJSON();
        if (Array.isArray(attrs)) {
            ans = [];
            for (i = 0; i < attrs.length; i++) {
                ans.push(prepareDataImpl(attrs[i]));
            }
            return ans;
        } else if (isObject(attrs)) {
            ans = {};
            for (i in attrs) {
                if (i.charAt(0) != '_') ans[i] = prepareDataImpl(attrs[i]);
            }
            return ans;
        } else {
            return attrs;
        }
    };

    /**
     * Deep-clone attrs leaving out fields starting with underscore and converting Backbone models to JSON.
     */
    var cloneWidget = function(attrs) {
        return widgetDataImpl(attrs);
    };

    var widgetDataImpl = function(attrs) {
        var i, ans;
        if (!attrs) return attrs;
        if (isFunction(attrs.toJSON)) attrs = attrs.toJSON();
        if (Array.isArray(attrs)) {
            ans = [];
            for (i = 0; i < attrs.length; i++) {
                ans.push(prepareDataImpl(attrs[i]));
            }
            return ans;
        } else if (isObject(attrs)) {
            ans = {};
            for (i in attrs) {
                if (i == 'width' || i == 'height' || i == 'type' ||
                    i == '_xml' || i == '_text' || i == 'caption' ||
                    i == '_params') {
                    ans[i] = prepareDataImpl(attrs[i]);
                }
            }
            return ans;
        } else {
            return attrs;
        }
    };

    //---------------------------

    /**
     * Generate images for all the widgetModels in a zip file. The models must have views.
     */
    this.saveImages = function(widgetModels, zipFilename, popupTarget) {
        if (widgetModels.length == 0) return;
        widgetModels.forEach(function(m) { if (!m.view.isRenderingComplete()) m.view.updateWidgetView(true) });

        var timeoutID, popup, index = 0, id, that = this;
        var save = function() {
            if (index >= widgetModels.length) {
                popup.hide();
                if (id) window.location = "/api/trotter/image-zip/" + id + "?filename=" + encodeURIComponent(zipFilename);
                return;
            }

            var widget = widgetModels[index].view;
            var suffix = (index + 1) + "/" + widgetModels.length;
            if (!widget.isRenderingComplete()) {
                timeoutID = setTimeout(save, 100);
                popup.setMessage('Loading metric ' + suffix);
                return;
            }

            var html = widget.getImageMarkup();
            if (!html) {
                ++index;
                return save();
            }

            popup.setMessage('Creating image ' + suffix);

            $.ajax({
                url: "/api/trotter/image-zip?filename=" + encodeURIComponent(widget.getFilename() + ".png") +
                (id ? "&id=" + id : ""),
                type: "POST",
                contentType: "text/plain",
                processData: false,
                data: html,
                success: function(data) {
                    id = data.id;
                    ++index;
                    save();
                },
                error: function() {
                    popup.hide();
                    window.alert("Error creating images");
                }
            });
        };

        popup = showBusyNotification(
            "Creating zip file with images ...",
            function() { clearTimeout(timeoutID);},
            popupTarget
        );

        timeoutID = setTimeout(save, 1);
    }

});