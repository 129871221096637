import {checkBrands, checkTags, checkDeprecatedResponseTimeFields} from "@/components/WarningIndicator";
import VuexStore from "@/store/vuex/VuexStore";
import {formatNumber, formatPlural} from "@/app/utils/Format";

let currentlyChecking = null;

/**
 * Staff do not always have landing pages properly setup and maintained.
 * This is meant to prompt people to fix the errors that occur in landing pages.
 * @param stopAtFirst
 * @returns {Promise<null|[]|*[]|undefined>}
 */
export async function findLandingPageErrors(stopAtFirst) {
    stopAtFirst ??= false;

    if (currentlyChecking) return currentlyChecking;

    async function impl() {
        try {
            await VuexStore.dispatch("dashboards/refreshDashboards");
            const landingDashboards = VuexStore.getters["dashboards/landingPages"];
            if (!landingDashboards?.length) return [];

            let errors = [];
            for (const d of landingDashboards) {
                if (stopAtFirst && errors.length) break;

                let errorCount = 0,
                    full;

                try {
                    full = await VuexStore.dispatch('dashboards/getFullDashboard', d.id);
                } catch (e) {
                    console.error(e);
                    continue;
                }

                for (const section of full.sections) {
                    const tagWarnings = await checkTags(section.filter);
                    const brandWarnings = await checkBrands(section.filter);
                    if (!!tagWarnings.length || brandWarnings.length) errorCount++;

                    for (const widget of section.widgets) {
                        const tagWarnings = await checkTags(widget.filter);
                        const brandWarnings = await checkBrands(widget.filter);
                        const deprecatedResponseTimeFieldWarnings = await checkDeprecatedResponseTimeFields(widget.show);
                        if (!!tagWarnings.length || brandWarnings.length || deprecatedResponseTimeFieldWarnings.length) errorCount++;
                    }
                }

                if (errorCount > 0) {
                    errors.push({
                        id: "LANDING_PAGE_ERROR",
                        description: "These landing pages have errors",
                        object: d,
                        comment: `Has ${formatNumber(errorCount)} ${formatPlural(errorCount, 'error')}`,
                        errorCount: errorCount,
                        icon: 'symbol-reports',
                        notification: `Landing page <strong>${d.name}</strong> has errors. Please have them fixed.`
                    })
                }
            }

            return errors;
        } catch(e) {
            console.error(e);
        } finally {
            currentlyChecking = null;
        }
    }

    return currentlyChecking = impl();
}


/**
 * Navigates to a given dashboard, or the current default dashboard.
 */
export function gotoDashboard(dashboardId) {
    var href = "/" + VuexStore.state.account.code + "/dashboards";
    if (dashboardId) href += "/" + dashboardId;
    Beef.router.navigate(href, {trigger: true});
}


function getLastSeenDashboardKey() {
    const account = VuexStore.state.account;
    const user = VuexStore.state.user;
    return `safe-cache:analyse:${user.id}:${account.code}:last-dashboard`;
}

/**
 * @returns {Number|null}
 */
export function getLastSeenDashboard() {
    const item = localStorage.getItem(getLastSeenDashboardKey());
    if (!item) return null;
    try {
        return Number.parseInt(item);
    } catch(ignore) {
        console.warn("Last seen dashboard ID is not a number");
        localStorage.removeItem(getLastSeenDashboardKey());
        return null;
    }
}

/**
 * @returns {void}
 */
export function setLastSeenDashboard(id) {
    try {
        if (!id) return;
        localStorage.setItem(getLastSeenDashboardKey(), "" + id);
    } catch(ignore) {
        console.warn("Unable to save last seen ID");
    }
}