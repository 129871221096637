/**
 * Displays all the icons in our icon font.
 */
Beef.module("Icons").addInitializer(function(startupOptions) {

    var View = Backbone.Marionette.Layout.extend({
        template: require("@/hack/IconList.handlebars"),
        attributes: { class: "row-fluid list-of-icons" },
        templateHelpers: function(){
            var a = [];
            for (var i = 0; i < codes.length; i++) {
                var s = codes[i];
                switch (s.length) {
                    case 1: s = "000" + s;  break;
                    case 2: s = "00" + s;  break;
                    case 3: s = "0" + s;  break;
                }
                a.push({code: s, content: "\\" + s});
            }
            return {
                icons: a
            }
        }
    });

    var routeCallback = function() {
        var model = new Backbone.Model();
        var view = new View({model: model});
        Beef.content.show(view);
    };

    Beef.route("icons", "icons", routeCallback);

    var codes = [
        "0021",
        "0022",
        "0023",
        "0024",
        "0025",
        "0026",
        "0027",
        "0028",
        "0029",
        "002a",
        "002b",
        "002c",
        "002d",
        "002e",
        "002f",
        "0030",
        "0031",
        "0032",
        "0033",
        "0034",
        "0035",
        "0036",
        "0037",
        "0038",
        "0039",
        "003a",
        "003b",
        "003c",
        "003d",
        "003e",
        "003f",
        "0040",
        "0041",
        "0042",
        "0043",
        "0044",
        "0045",
        "0046",
        "0047",
        "0048",
        "0049",
        "004a",
        "004b",
        "004c",
        "004d",
        "004e",
        "004f",
        "0050",
        "0051",
        "0052",
        "0053",
        "0054",
        "0055",
        "0056",
        "0057",
        "0058",
        "0059",
        "005a",
        "005b",
        "005c",
        "005d",
        "005e",
        "005f",
        "0060",
        "0061",
        "0062",
        "0063",
        "0064",
        "0065",
        "0066",
        "0067",
        "0068",
        "0069",
        "006a",
        "006b",
        "006c",
        "006d",
        "006e",
        "006f",
        "0070",
        "0071",
        "0072",
        "0073",
        "0074",
        "0075",
        "0076",
        "0077",
        "0078",
        "0079",
        "007a",
        "007b",
        "007c",
        "007d",
        "e000",
        "e001",
        "e002",
        "e003",
        "e004",
        "e005",
        "e006",
        "e007",
        "e008",
        "e009",
        "e00a",
        "e00b",
        "e00c",
        "e00d",
        "e00e",
        "e00f",
        "e010",
        "e011",
        "e012",
        "e013",
        "e014",
        "e015",
        "e016",
        "e017",
        "e018",
        "e019",
        "e01a",
        "e01b",
        "e01c",
        "e01d",
        "e01e",
        "e01f",
        "e020",
        "e021",
        "e022",
        "e023",
        "e024",
        "e025",
        "e026",
        "e027",
        "e028",
        "e029",
        "e02a",
        "e02b",
        "e02c",
        "e02d",
        "e02e",
        "e02f",
        "e030",
        "e031",
        "e032",
        "e033",
        "e034",
        "e035",
        "e036",
        "e037",
        "e038",
        "e039",
        "e03a",
        "e03b",
        "e03c",
        "e03d",
        "e03e",
        "e03f",
        "e040",
        "e041",
        "e042",
        "e043",
        "e044",
        "e045",
        "e046",
        "e047",
        "e048",
        "e049",
        "e04a",
        "e04b",
        "e04c",
        "e04d",
        "e04e",
        "e04f",
        "e050",
        "e051",
        "e052",
        "e053",
        "e054",
        "e055",
        "e056",
        "e057",
        "e058",
        "e059",
        "e05a",
        "e05b",
        "e05c",
        "e05d",
        "e05e",
        "e05f",
        "e060",
        "e061",
        "e062",
        "e063",
        "e064",
        "e065",
        "e066",
        "e067",
        "e068",
        "e069",
        "e06a",
        "e06b",
        "e06c",
        "e06d",
        "e06e",
        "e06f",
        "e070",
        "e071",
        "e072",
        "e073",
        "e074",
        "e075",
        "e076",
        "e077",
        "e078",
        "e079",
        "e07a",
        "e07b",
        "e07c",
        "e07d",
        "e07e",
        "e07f",
        "e080",
        "e081",
        "e082",
        "e083",
        "e084",
        "e085",
        "e086",
        "e087",
        "e088",
        "e089",
        "e08a",
        "e08b",
        "e08c",
        "e08d",
        "e08e",
        "e08f",
        "e090",
        "e091",
        "e092",
        "e093",
        "e094",
        "e095",
        "e096",
        "e097",
        "e098",
        "e099",
        "e09a",
        "e09b",
        "e09c",
        "e09d",
        "e09e",
        "e09f",
        "e0a0",
        "e0a1",
        "e0a2",
        "e0a3",
        "e0a4",
        "e0a5",
        "e0a6",
        "e0a7",
        "e0a8",
        "e0a9",
        "e0aa",
        "e0ab",
        "e0ac",
        "e0ad",
        "e0ae",
        "e0af",
        "e0b0",
        "e0b1",
        "e0b2",
        "e0b3",
        "e0b4",
        "e0b5",
        "e0b6",
        "e0b7",
        "e0b8",
        "e0b9",
        "e0ba",
        "e0bb",
        "e0bc",
        "e0bd",
        "e0be",
        "e0bf",
        "e0c0",
        "e0c1",
        "e0c2",
        "e0c3",
        "e0c4",
        "e0c5",
        "e0c6",
        "e0c7",
        "e0c8",
        "e0c9",
        "e0ca",
        "e0cb",
        "e0cc",
        "e0cd",
        "e0ce",
        "e0cf",
        "e0d0",
        "e0d1",
        "e0d2",
        "e0d3",
        "e0d4",
        "e0d5",
        "e0d6",
        "e0d7",
        "e0d8",
        "e0d9",
        "e0da",
        "e0db",
        "e0dc",
        "e0dd",
        "e0de",
        "e0df",
        "e0e0",
        "e0e1",
        "e0e2",
        "e0e3",
        "e0e4",
        "e0e5",
        "e0e6",
        "e0e7",
        "e0e8",
        "e0e9",
        "e0ea",
        "e0eb",
        "e0ec",
        "e0ed",
        "e0ee",
        "e0ef",
        "e0f0",
        "e0f1",
        "e0f2",
        "e0f3",
        "e0f4",
        "e0f5",
        "e0f6",
        "e0f7",
        "e0f8",
        "e0f9",
        "e0fa",
        "e0fb",
        "e0fc",
        "e0fd",
        "e0fe",
        "e0ff",
        "e100",
        "e101",
        "e102",
        "e103",
        "e104",
        "e105",
        "e106",
        "e107",
        "e108",
        "e109",
        "e10a",
        "e10b",
        "e10c",
        "e10d",
        "e10e",
        "e10f",
        "e110",
        "e111",
        "e112",
        "e113",
        "e114",
        "e115",
        "e116",
        "e117",
        "e118",
        "e119",
        "e11a",
        "e11b",
        "e11c",
        "e11d",
        "e11e",
        "e11f",
        "e120",
        "e121",
        "e122",
        "e123",
        "e124",
        "e125",
        "e126",
        "e127",
        "e128",
        "e129",
        "e12a",
        "e12b",
        "e12c",
        "e12d",
        "e12e",
        "e12f",
        "e130",
        "e131",
        "e132",
        "e133",
        "e134",
        "e135",
        "e136",
        "e137",
        "e138",
        "e139",
        "e13a",
        "e13b",
        "e13c",
        "e13d",
        "e13e",
        "e13f",
        "e140",
        "e141",
        "e142",
        "e143",
        "e144",
        "e145",
        "e146",
        "e147",
        "e148",
        "e149",
        "e14a",
        "e14b",
        "e14c",
        "e14d",
        "e14e",
        "e14f",
        "e150",
        "e151",
        "e152",
        "e153",
        "e154",
        "e155",
        "e156",
        "e157",
        "e158",
        "e159",
        "e15a",
        "e15b",
        "e15c",
        "e15d",
        "e15e",
        "e15f",
        "e160",
        "e161",
        "e162",
        "e163",
        "e164",
        "e165",
        "e166",
        "e167",
        "e168",
        "e169",
        "e16a",
        "e16b",
        "e16c",
        "e16d",
        "e16e",
        "e16f",
        "e170",
        "e171",
        "e172",
        "e173",
        "e174",
        "e175",
        "e176",
        "e177",
        "e178",
        "e179",
        "e17a",
        "e17b",
        "e17c",
        "e17d",
        "e17e",
        "e17f",
        "e180",
        "e181",
        "e182",
        "e183",
        "e184",
        "e185",
        "e186",
        "e187",
        "e188",
        "e189",
        "e18a",
        "e18b",
        "e18c",
        "e18d",
        "e18e",
        "e18f",
        "e190",
        "e191",
        "e192",
        "e193",
        "e194",
        "e195",
        "e196",
        "e197",
        "e198",
        "e199",
        "e19a",
        "e19b",
        "e19c",
        "e19d",
        "e19e",
        "e19f",
        "e1a0",
        "e1a1",
        "e1a2",
        "e1a3",
        "e1a4",
        "e1a5",
        "e1a6",
        "e1a7",
        "e1a8",
        "e1a9",
        "e1aa",
        "e1ab",
        "e1ac",
        "e1ad",
        "e1ae",
        "e1af",
        "e1b0",
        "e1b1",
        "e1b2",
        "e1b3",
        "e1b4",
        "e1b5",
        "e1b6",
        "e1b7",
        "e1b8",
        "e1b9",
        "e1ba",
        "e1bb",
        "e1bc",
        "e1bd",
        "e1be",
        "e1bf",
        "e1c0",
        "e1c1",
        "e1c2",
        "e1c3",
        "e1c4",
        "e1c5",
        "e1c6",
        "e1c7",
        "e1c8",
        "e1c9",
        "e1ca",
        "e1cb",
        "e1cc",
        "e1cd",
        "e1ce",
        "e1cf",
        "e1d0",
        "e1d1",
        "e1d2",
        "e1d3",
        "e1d4",
        "e1d5",
        "e1d6",
        "e1d7",
        "e1d8",
        "e1d9",
        "e1da",
        "e1db",
        "e1dc",
        "e1dd",
        "e1de",
        "e1df",
        "e1e0",
        "e1e1",
        "e1e2",
        "e1e3",
        "e1e4",
        "e1e5",
        "e1e6",
        "e1e7",
        "e1e8",
        "e1e9",
        "e1ea",
        "e1eb",
        "e1ec",
        "e1ed",
        "e1ee",
        "e1ef",
        "e1f0",
        "e1f1",
        "e1f2",
        "e1f3",
        "e1f4",
        "e1f5",
        "e1f6",
        "e1f7",
        "e1f8",
        "e1f9",
        "e1fa",
        "e1fb",
        "e1fc",
        "e1fd",
        "e1fe",
        "e1ff",
        "e200",
        "e201",
        "e202",
        "e203",
        "e204",
        "e205",
        "e206",
        "e207",
        "e208",
        "e209",
        "e20a",
        "e20b",
        "e20c",
        "e20d",
        "e20e",
        "e20f",
        "e210",
        "e211",
        "e212",
        "e213",
        "e214",
        "e215",
        "e216",
        "e217",
        "e218",
        "e219",
        "e21a",
        "e21b",
        "e21c",
        "e21d",
        "e21e",
        "e21f",
        "e220",
        "e221",
        "e222",
        "e223",
        "e224",
        "e225",
        "e226",
        "e227",
        "e228",
        "e229",
        "e22a",
        "e22b",
        "e22c",
        "e22d",
        "e22e",
        "e22f",
        "e230",
        "e231",
        "e232",
        "e233",
        "e234",
        "e235",
        "e236",
        "e237",
        "e238",
        "e239",
        "e23a",
        "e23b",
        "e23c",
        "e23d",
        "e23e",
        "e23f",
        "e240",
        "e241",
        "e242",
        "e243",
        "e244"
    ]
});
