var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <!--Example: 25 mentions for Thursday, Sept 1, 2019 -->\n    <!--Example: 25% of mentions are for Thursday, Sept 1, 2019 -->\n    <strong>\n        <span class=\"number\">"
    + alias3((lookupProperty(helpers,"formatTooltip")||(depth0 && lookupProperty(depth0,"formatTooltip"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),(depth0 != null ? lookupProperty(depth0,"yDecimal") : depth0),{"name":"formatTooltip","hash":{},"data":data,"loc":{"start":{"line":7,"column":29},"end":{"line":7,"column":60}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"moePrecent") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":61},"end":{"line":7,"column":118}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"xIsPercent") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    </strong>\n\n    "
    + alias3(((helper = (helper = lookupProperty(helpers,"percentDatePeriod") || (depth0 != null ? lookupProperty(depth0,"percentDatePeriod") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"percentDatePeriod","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":25}}}) : helper)))
    + " <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"xVal") || (depth0 != null ? lookupProperty(depth0,"xVal") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"xVal","hash":{},"data":data,"loc":{"start":{"line":15,"column":34},"end":{"line":15,"column":42}}}) : helper)))
    + "</strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"compare") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"summary") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "(&#177; "
    + container.escapeExpression((lookupProperty(helpers,"formatTooltip")||(depth0 && lookupProperty(depth0,"formatTooltip"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"moe") : depth0),0,{"name":"formatTooltip","hash":{},"data":data,"loc":{"start":{"line":7,"column":87},"end":{"line":7,"column":110}}}))
    + ")";
},"4":function(container,depth0,helpers,partials,data) {
    return "            of the mentions\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((lookupProperty(helpers,"mention")||(depth0 && lookupProperty(depth0,"mention"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),{"name":"mention","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":28}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>("
    + alias4(((helper = (helper = lookupProperty(helpers,"comparePreposition") || (depth0 != null ? lookupProperty(depth0,"comparePreposition") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comparePreposition","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":34}}}) : helper)))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"compare") || (depth0 != null ? lookupProperty(depth0,"compare") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"compare","hash":{},"data":data,"loc":{"start":{"line":17,"column":43},"end":{"line":17,"column":54}}}) : helper)))
    + "</strong>)</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>\n                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":31}}}) : helper)))
    + "\n            </p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"toolTip-summary\"> <strong>What are these mentions about?</strong> </p>\n        <p> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"summary") || (depth0 != null ? lookupProperty(depth0,"summary") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"summary","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":23}}}) : helper)))
    + " </P>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isInteractions") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":68,"column":11}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <!--Example: 25 interactions for Thursday, Sept 1, 2019 -->\n        <!--Example: 25% of interactions are for Thursday, Sept 1, 2019 -->\n        <strong>\n            <span class=\"number\">"
    + alias3((lookupProperty(helpers,"formatTooltip")||(depth0 && lookupProperty(depth0,"formatTooltip"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),(depth0 != null ? lookupProperty(depth0,"yDecimal") : depth0),{"name":"formatTooltip","hash":{},"data":data,"loc":{"start":{"line":34,"column":33},"end":{"line":34,"column":64}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"moePrecent") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":65},"end":{"line":34,"column":122}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"xIsPercent") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":39,"column":19}}})) != null ? stack1 : "")
    + "        </strong>\n\n        "
    + alias3(((helper = (helper = lookupProperty(helpers,"percentDatePeriod") || (depth0 != null ? lookupProperty(depth0,"percentDatePeriod") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"percentDatePeriod","hash":{},"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":42,"column":29}}}) : helper)))
    + " <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"xVal") || (depth0 != null ? lookupProperty(depth0,"xVal") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"xVal","hash":{},"data":data,"loc":{"start":{"line":42,"column":38},"end":{"line":42,"column":46}}}) : helper)))
    + "</strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"compare") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "                of the interactions\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"mention")||(depth0 && lookupProperty(depth0,"mention"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),{"name":"mention","hash":{},"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":38,"column":32}}}))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>("
    + alias4(((helper = (helper = lookupProperty(helpers,"comparePreposition") || (depth0 != null ? lookupProperty(depth0,"comparePreposition") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comparePreposition","hash":{},"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":44,"column":38}}}) : helper)))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"compare") || (depth0 != null ? lookupProperty(depth0,"compare") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"compare","hash":{},"data":data,"loc":{"start":{"line":44,"column":47},"end":{"line":44,"column":58}}}) : helper)))
    + "</strong>)</p>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>\n                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":48,"column":35}}}) : helper)))
    + "\n                </p>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <!--example: Mentions for Thursday, Sept 1, 2010, have an Engagement of 1020 -->\n        Mentions "
    + alias4(((helper = (helper = lookupProperty(helpers,"mentionsWith") || (depth0 != null ? lookupProperty(depth0,"mentionsWith") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mentionsWith","hash":{},"data":data,"loc":{"start":{"line":54,"column":17},"end":{"line":54,"column":33}}}) : helper)))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"xVal") || (depth0 != null ? lookupProperty(depth0,"xVal") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"xVal","hash":{},"data":data,"loc":{"start":{"line":54,"column":42},"end":{"line":54,"column":50}}}) : helper)))
    + "</strong>\n        have "
    + alias4(((helper = (helper = lookupProperty(helpers,"yArticle") || (depth0 != null ? lookupProperty(depth0,"yArticle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yArticle","hash":{},"data":data,"loc":{"start":{"line":55,"column":13},"end":{"line":55,"column":25}}}) : helper)))
    + "\n        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"yName") || (depth0 != null ? lookupProperty(depth0,"yName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yName","hash":{},"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":56,"column":25}}}) : helper)))
    + " of\n            "
    + alias4((lookupProperty(helpers,"formatTooltip")||(depth0 && lookupProperty(depth0,"formatTooltip"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),(depth0 != null ? lookupProperty(depth0,"yDecimal") : depth0),{"name":"formatTooltip","hash":{},"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":57,"column":43}}}))
    + "\n        </strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"compare") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":67,"column":15}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>\n        <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"sizeName") || (depth0 != null ? lookupProperty(depth0,"sizeName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"sizeName","hash":{},"data":data,"loc":{"start":{"line":73,"column":16},"end":{"line":73,"column":28}}}) : helper)))
    + "</strong>\n        is\n        <strong>\n            <span class=\"number\">\n                "
    + alias3((lookupProperty(helpers,"formatSize")||(depth0 && lookupProperty(depth0,"formatSize"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sizeVal") : depth0),{"name":"formatSize","hash":{},"data":data,"loc":{"start":{"line":77,"column":16},"end":{"line":77,"column":38}}}))
    + "\n            </span>\n        </strong>\n    </p>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <br>\n        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"yName") || (depth0 != null ? lookupProperty(depth0,"yName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yName","hash":{},"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":86,"column":25}}}) : helper)))
    + "</strong> is defined as: "
    + alias4(((helper = (helper = lookupProperty(helpers,"yDefinition") || (depth0 != null ? lookupProperty(depth0,"yDefinition") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yDefinition","hash":{},"data":data,"loc":{"start":{"line":86,"column":50},"end":{"line":86,"column":65}}}) : helper)))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--We have two major cases: y-value is a mention count or percent, or something else.-->\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMentions") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":69,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":0},"end":{"line":81,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"yDefinition") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":0},"end":{"line":88,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"example-mention\">\n\n</div>";
},"useData":true});