var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"row-fluid\">\n    <div class=\"col\" style=\"width: 100%\">\n        <div class=\"group top right\">\n            <table class=\"table table-condensed table-bordered\">\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"BrandSelector","Filter by brand",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":64}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"SubBrandSelector","Filter by sub-brand",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":71}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"CategorySelector","Filter by mention category (Press, Consumer etc.)",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":102}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"CountrySelector","Filter by country of mention",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":79}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"DateSelector","Filter by day, week, month or year",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":82}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"GenderSelector","Filter by gender",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":66}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"LanguageSelector","Filter by language",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":70}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"SegmentSelector","Filter by segmentation sets, like Customer Journey",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":101}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"SentimentSelector","Filter by sentiment",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":72}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"SourceSelector","Filter by mention source (X, Facebook etc.)",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":93}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"TagSelector","Filter by tag",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":60}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"TopicSelector","Filter by topic",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":64}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"TopicViewSelector","Filter by topic view",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":73}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"CxSelector","Filter by Customer Experience",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":75}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"VisibilitySelector","Filter by visibility",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":74}}}))
    + "\n                "
    + alias2(__default(require("../../helpers/widgetType.js")).call(alias1,"ShowWhatSelector","Filter by conversation types",{"name":"widgetType","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":80}}}))
    + "\n            </table>\n        </div>\n    </div>\n</div>\n";
},"useData":true});