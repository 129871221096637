import Vue from "vue";
import Vuex from "vuex";
import {beef, mash, snoek} from "@/store/Services";
import {profileTypes} from "@/setup/profiles/ProfileUtils";
import {Profile} from "@/app/utils/types";
import {checkAccountProfiles, consolidateWarnings} from "@/store/vuex/dataChecks";

Vue.use(Vuex);

let profilesRefreshPromise = null;
let profileStatsRefreshPromise = null;
let profilesWarningsPromise = null;

export default {
    namespaced: true,

    state: {
        profiles: null,
        profileStats: null,
        setupWarnings: null,

        newProfileData: { // keeps track of new profiles that are being added to an account
            publicProfile: null,
            managedProfiles: {
                profiles: [],
                dmsEnabled: false
            },
            influencerProfiles: {
                generateLinkCount: 1,
                links: []
            }
        }
    },

    getters: {
        /** Useful for getting a profile from a profile Id, or for testing if the profile exists */
        idToProfile: state => {
            if (!state.profiles) return new Map();

            const map = new Map();
            for (const profile of state.profiles) {
                map.set(profile.id, profile);
            }
            return map;
        },

        getNonDeletedProfiles: state => {
            return state.profiles ? state.profiles.filter(p => !p.deleted) : [];
        },

        getFacebookPages: state => {
            return state.profiles? state.profiles.filter(p => p.type === profileTypes.facebook) : []
        },

        getGoogleUnauthorizedBusinesses: state => {
            return state.profiles? state.profiles.filter(p => p.type === profileTypes.googleBusiness) : []
        },

        getFacebookPagesAndInstaProfiles: state => {
            return state.profiles? state.profiles.filter(p => p.type === profileTypes.facebook || p.type === profileTypes.instagram_business) : []
        },

        getLinkedinProfiles: state => {
            return state.profiles? state.profiles.filter(p => p.type === profileTypes.linkedin) : []
        },

        getTikTokProfiles: state => {
            return state.profiles? state.profiles.filter(p => p.type === profileTypes.tikTok) : []
        },

        getTwitterProfiles: state => {
            return state.profiles? state.profiles.filter(p => p.type === profileTypes.twitter) : []
        },

        /** Useful for getting a profile from a profile handle id, or for testing if the profile exists */
        handleIdToProfile: state => handleId => {
            if (!state.profiles) return null;

            const profile = state.profiles.find(profile => profile.handleId === handleId && !profile.deleted);
            if (profile) return profile;

            return null;
        },

        /** Useful for getting a profile's stats from a profile handle id, or for testing if the profile has stats */
        handleIdToProfileStats: state => handleId => {
            if (!state.profileStats) return null;

            const stats = state.profileStats?.find(stats => stats.handleId === handleId);
            if (stats) return stats;

            return null;
        }
    },

    mutations: {
        setProfiles: (state, value) => {
            if (value) {
                const parent = new Profile();
                value.forEach(o => o.__proto__ = parent);
            }
            state.profiles = value
        },
        setProfileStats: (state, value) => {
            let profileStats = [];
            Object.values(value).forEach(val => {
                profileStats.push(val);
            });

            state.profileStats = profileStats;
        },
        addProfile: (state, value) => {
            if (state.profiles === null) state.profiles = [];

            let existingProfile = state.profiles.find(p => p.id === value.id);
            if (existingProfile) {
                console.error(`Profile with ID {} already exists in profiles state`);
            } else {
                value.__proto__ = new Profile();
                state.profiles.push(value);
            }
        },

        setNewPublicProfile: (state, value) => {
            // ensures that all properties that are set are reactive
            if (value) {
                state.newProfileData.publicProfile = {...state.newProfileData.publicProfile, ...value}
            } else {
                state.newProfileData.publicProfile = null;
            }
        },
        setNewPublicProfileCategory: (state, value) => state.newProfileData.publicProfile.media = value,
        setNewPublicProfileDescription: (state, value) => state.newProfileData.publicProfile.description = value,
        setNewPublicProfileRelevant: (state, value) => state.newProfileData.publicProfile.relevant = value,
        setNewPublicProfileTagIds: (state, value) => state.newProfileData.publicProfile.tagIds = value,
        setNewPublicProfileBrands: (state, value) => state.newProfileData.publicProfile.brands = value,

        setNewManagedProfilesDmsEnabled: (state, value) => state.newProfileData.managedProfiles.dmsEnabled = value,
        setNewManagedProfiles: (state, value) => state.newProfileData.managedProfiles.profiles = value,

        setInfluencerLinkCount: (state, value) => state.newProfileData.influencerProfiles.generateLinkCount = value,
        setInfluencerLinks: (state, value) => state.newProfileData.influencerProfiles.links = value,

        clearNewProfiles: (state) => {
            state.newProfileData.publicProfile = null;
            state.newProfileData.managedProfiles = {
                profiles: [],
                dmsEnabled: false
            };
            state.newProfileData.influencerProfiles = {
                generateLinkCount: 1,
                links: []
            }
        },

        setWarnings: (state,value) => state.setupWarnings = value
    },

    actions: {
        async refreshProfiles({state, commit, rootState}, forceRefresh) {
            forceRefresh ??= false;

            if (profilesRefreshPromise) return profilesRefreshPromise;
            if (state.profiles && !forceRefresh) return;
            if (!rootState.account?.code) return;
            try {
                profilesRefreshPromise = mash.get("/rest/accounts/" + rootState.account.code + "/online-profiles?includeDeleted=true");
                const res = await profilesRefreshPromise;
                commit('setProfiles', res.data);
            } catch (e) {
                console.error("Error occurred while fetching account profiles: ", e);
                throw e;
            } finally {
                profilesRefreshPromise = null;
            }
        },

        async refreshProfileStats({state, commit, rootState}, forceRefresh) {
            forceRefresh ??= false;

            if (profileStatsRefreshPromise) return profileStatsRefreshPromise;
            if (state.profileStats && !forceRefresh) return;
            try {
                profileStatsRefreshPromise = await snoek.get(`v4/accounts/${rootState.account.code}/online-profiles/latest-measurements`);
                const res = await profileStatsRefreshPromise;
                commit('setProfileStats', res.data);
            } catch (e) {
                console.error("Error occurred while fetching stats for profiles: ", e);
                throw e;
            } finally {
                profileStatsRefreshPromise = null;
            }
        },

        async refreshProfile({state, commit, rootState}, profileId) {
            return mash
                .get("/rest/accounts/" + rootState.account.code + "/online-profiles/" + profileId)
                .then(res => {
                    commit('setProfiles', state.profiles?.map(p => p.id === profileId ? res.data : p));
                });
        },

        async updateProfile({state, commit, rootState}, profile) {
            return mash
                .put("/rest/accounts/" + rootState.account.code + "/online-profiles/" + profile.id, profile)
                .then(res => {
                    commit('setProfiles', state.profiles?.map(p => p.id === profile.id ? res.data : p));
                });
        },

        async createProfile({state, commit, rootState}, profile) {
            return mash
                .post("/rest/accounts/" + rootState.account.code + "/online-profiles", profile)
                .then(res => {
                    commit('addProfile', res.data);
                });
        },

        async deleteProfile({state, commit, rootState, dispatch}, {profileId, linkedProfileId}) {
            await mash.delete("/rest/accounts/" + rootState.account.code + "/online-profiles/" + profileId);

            let updatedProfile = await dispatch('getProfile', profileId);
            commit('setProfiles', state.profiles?.map(p => p.id === updatedProfile.id ? updatedProfile : p));

            if (linkedProfileId) {
                updatedProfile = await dispatch('getProfile', linkedProfileId);
                commit('setProfiles', state.profiles?.map(p => p.id === updatedProfile.id ? updatedProfile : p));
            }
        },

        async undeleteProfile({state, commit, rootState, dispatch}, {profileId, linkedProfileId}) {
            await mash.put(`/rest/accounts/${rootState.account.code}/online-profiles/${profileId}/undelete`);

            let updatedProfile = await dispatch('getProfile', profileId);
            commit('setProfiles', state.profiles?.map(p => p.id === updatedProfile.id ? updatedProfile : p));

            if (linkedProfileId) {
                updatedProfile = await dispatch('getProfile', linkedProfileId);
                commit('setProfiles', state.profiles?.map(p => p.id === updatedProfile.id ? updatedProfile : p));
            }
        },

        async getProfile({rootState}, profileId) {
            return mash
                .get("/rest/accounts/" + rootState.account.code + "/online-profiles/" + profileId)
                .then(res => res.data);
        },

        async unauthoriseProfile({rootState, dispatch, state, commit}, {profileId, linkedProfileId, reason}) {
            return mash
                .delete(`/rest/accounts/${rootState.account.code}/online-profiles/${profileId}/unauthorise-profile?${reason ? `unauthReason=${encodeURIComponent(reason)}` : ``}`)
                .then(async res => {
                    let updatedProfile = await dispatch('getProfile', profileId);
                    commit('setProfiles', state.profiles?.map(p => p.id === updatedProfile.id ? updatedProfile : p));

                    if (linkedProfileId) {
                        updatedProfile = await dispatch('getProfile', linkedProfileId);
                        commit('setProfiles', state.profiles?.map(p => p.id === updatedProfile.id ? updatedProfile : p));
                    }
                });
        },

        async checkWarnings({commit}) {
            if (profilesWarningsPromise) return profilesWarningsPromise;

            const impl = async () => {
                try {
                    const warnings = await checkAccountProfiles();
                    commit('setWarnings', consolidateWarnings(warnings));
                } catch (e) {
                    console.warn("Error occurred when checking profile warnings", e);
                } finally {
                    profilesWarningsPromise = null;
                }
            };

            return profilesWarningsPromise = impl();
        }
    }
};