<template>
    <add-rule-dialog class="add-tagging-rule"
                     @close="$emit('close')"
                     @next="next" @prev="prev"
                     :rule-type="ruleTypes.tag"
                     :total-stages="totalStages"
                     :cur-stage="curStage"
                     :next-btn-text="nextBtnText"
                     :show-next-btn="showNextBtn"
                     :show-prev-btn="showPrevBtn"
                     :enable-next-btn="enableNextBtn">
        <template #workflow>
            <div key="1" v-if="curStage === addRuleStages.tagMentions.EDIT_TAGS_AND_FILTER">
                <h4>Select tags</h4>
                <inline-tag-input class="tag-selector" v-model="tags"></inline-tag-input>

                <h4>Edit the filter</h4>
                <p>
                    Choose the mentions that will be tagged.
                </p>
                <filter-editor class="add-tagging-rule__filter-editor" @input="onFilterChange" :value="ruleFilter" for-rule/>
            </div>

            <div key="2" v-if="curStage === addRuleStages.tagMentions.TAG_EXISTING_MENTIONS" class="add-tagging-rule__tag-existing-mentions">
                <h4>Would you like to tag existing mentions?</h4>

                <div class="btn-selector">
                    <be-button :active="!tagExistingMentions" @click="tagExistingMentions = false">No</be-button>
                    <be-button :active="tagExistingMentions" @click="tagExistingMentions = true">Yes</be-button>
                </div>

                <div v-if="tagExistingMentions">
                    <p>Tag existing mentions within the period</p>
                    <published-input class="published-input" v-model="tagExistingDateRange" :read-only="false"/>

                    <p>Filter that will be applied</p>
                    <english-filter :filter="ruleFilter"></english-filter>

                    <p>{{ brandListTitle }}</p>
                    <div class="brand-list dark-scrollbars dark-scrollbars--visible">
                        <div class="brand" v-for="brand in brandList" :key="brand.id">
                            {{ brand.name }}
                        </div>
                    </div>
                </div>
            </div>

            <div key="3" v-if="curStage === addRuleStages.tagMentions.EXAMPLE_MENTIONS">
                <h4>Example mentions</h4>
                <p>Future mentions like this will be tagged</p>
                <div class="add-tagging-rule__preview-mentions">
                    <preview-mentions :filter="ruleFilter"></preview-mentions>
                </div>
            </div>

            <div key="4" v-if="curStage === addRuleStages.tagMentions.SAVE">
                <saving-item :save-complete="saveComplete" :save-error="saveError" :saving-message="saveMessage" :save-complete-message="saveCompleteMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more <i class="symbol-tag"></i> Tagging rules</h4>
                    </template>
                </saving-item>
            </div>
        </template>
    </add-rule-dialog>
</template>

<script>
import AddRuleDialog from "@/setup/rules/add-rule/AddRuleDialog";
import {ruleTypes} from "@/setup/rules/RuleUtils";
import {addRuleMixins} from "@/setup/rules/add-rule/AddRuleMixins";
import FilterEditor from "@/components/inputs/FilterEditor";
import PreviewMentions from "@/components/PreviewMentions";
import SavingItem from "@/setup/SavingItem";
import VuexStore from "@/store/vuex/VuexStore";
import InlineTagInput from "@/components/inputs/InlineTagInput";
import {mapActions, mapGetters, mapState} from "vuex";
import PublishedInput from "@/components/inputs/PublishedInput";
import BeButton from "@/components/buttons/BeButton";
import EnglishFilter from "@/components/formatters/EnglishFilter";
import {appendBasicFilters, buildBasicFilter, extractBrands} from "@/dashboards/filter/BasicFilter";
import {deprecatedBrandsStore} from "@/store/deprecated/Stores";
import {appendFiltersReadably, getBrandsInFilter, parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import {formatPlural} from "@/app/utils/Format";
import {MentionQLexer} from "@/mentionq/mentionq";
import {grouse} from "@/store/Services";
import {logBulkMentionUpdate} from "@/data/Chervil";

export default {
    name: "AddTaggingRule",
    components: {
        EnglishFilter,
        BeButton,
        PublishedInput,
        InlineTagInput,
        SavingItem, PreviewMentions, FilterEditor, AddRuleDialog},
    mixins: [addRuleMixins],
    store: VuexStore,

    data() {
        return {
            ruleTypes: ruleTypes,
            totalStages: 1,
            curStage: 1,
            ruleFilter: "",
            tagExistingMentions: false,
            tagExistingDateRange: "QUARTER",
            mentionUpdateCount: 0,
            tags: []
        }
    },

    created() {
        this.totalStages = Object.keys(this.addRuleStages.tagMentions).length;
    },

    computed: {
        ...mapState(['rootBrands', 'account']),
        ...mapGetters(['idToBrand']),

        enableNextBtn() {
            switch (this.curStage) {
                case this.addRuleStages.tagMentions.EDIT_TAGS_AND_FILTER:
                    return !!this.ruleFilter && !!this.tags.length;
                case this.addRuleStages.tagMentions.SAVE:
                    return false;
                default:
                    return true;
            }
        },

        showNextBtn() {
            return this.curStage !== this.addRuleStages.tagMentions.SAVE;
        },

        showPrevBtn() {
            return this.curStage > 1 && this.curStage !== this.addRuleStages.tagMentions.SAVE;
        },

        nextBtnText() {
            return this.curStage !== this.totalStages - 1  ? "Next" : "Save Rule";
        },

        tagExistingMentionsFilter() {
            let publishedFilter = buildBasicFilter({published: this.tagExistingDateRange});
            return appendFiltersReadably(publishedFilter, this.ruleFilter);
        },

        brandList() {
            let brandIds = getBrandsInFilter(this.tagExistingMentionsFilter).include

            if (!brandIds.length) return this.rootBrands.map(b => b);
            else return brandIds.map(id => this.idToBrand.get(id));
        },

        brandListTitle() {
            return `${formatPlural(this.brandList.length, "Brand")} that will be updated`;
        },
    },

    methods: {
        ...mapActions(['createTag']),

        onFilterChange(changeData) {
            this.ruleFilter = changeData;
        },

        async tagMentions(tagIds) {
            // grouse only allows one brand to be tagged at a time - split tag requests by brand
            let filterWithoutBrands = removeNodes(parseFilterString(this.tagExistingMentionsFilter), function(node) {
                return node.operandType === MentionQLexer.BRAND
            }).toString();

            for (const brand of this.brandList) {
                let payloadFilter = `${filterWithoutBrands} AND Brand ISORCHILDOF ${brand.id}`;

                let payload = {
                    addTags: tagIds,
                    filter: payloadFilter
                };

                let response = await grouse.put(`/v4/accounts/${this.account.code}/mentions`, payload);
                this.mentionUpdateCount = response.data.updateCount;

                logBulkMentionUpdate(this.account.code, payloadFilter, payload).catch(e => console.error(e));
            }
        },

        async next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                let tagIds = [];
                let creatingTagsError = true;
                let tagExistingMentionsError = true;

                try {
                    this.saving = true;
                    this.saveMessage = "Creating tags if required...";

                    for (const tag of this.tags) {
                        // at this point, if the tag doesn't have an ID, a tag with the same name should not exist
                        if (!tag.id) {
                            tag.namespace ??= "tag";
                            let newTag = await this.createTag(tag);
                            tagIds.push(newTag.id);
                        } else {
                            tagIds.push(tag.id);
                        }
                    }
                    creatingTagsError = false;

                    if (this.tagExistingMentions) {
                        this.saveMessage = "Tagging existing mentions...";
                        await this.tagMentions(tagIds);
                    }
                    tagExistingMentionsError = false;

                } catch (e) {
                    if (creatingTagsError) console.error("Error occurred while trying to create new tags: ", e);
                    else console.error("Error occurred while trying to tag existing mentions: ", e);
                    this.saveError = true;
                }

                if (!creatingTagsError && !tagExistingMentionsError) {
                    this.saveMessage = "Saving your rule...";

                    let payload = {
                        action: "MACRO",
                        active: true,
                        attributes: tagIds.map(tagId => `tag = ${tagId}`).join(","),
                        filter: this.ruleFilter,
                        name: "Tags: " + this.tags.map(t => t.name).join(",")
                    }

                    await this.saveRule(payload);

                    if (this.mentionUpdateCount > 0) {
                        this.saveCompleteMessage = `Your rule has been added to the account and ${this.mentionUpdateCount} existing mentions have been tagged.`;
                    }
                }
            }
        },

        prev() {
            this.curStage--;
        },

        reset() {
            this.curStage = 1;
            this.tags = [];
            this.tagExistingMentions = false;
            this.tagExistingDateRange = "QUARTER";
            this.mentionUpdateCount = 0;
            this.saveCompleteMessage = "Your rule has been added to the account.";
            this.ruleFilter = "";
        }
    }
}
</script>

<style scoped lang="sass">

.add-tagging-rule

    .tag-selector
        height: 40px

    .btn-selector
        display: flex
        .be-button:not(:last-of-type) ::v-deep .btn
            border-top-right-radius: 0
            border-bottom-right-radius: 0
        .be-button + .be-button
            & ::v-deep .btn
                border-top-left-radius: 0
                border-bottom-left-radius: 0

    label
        margin-top: 10px
        margin-bottom: 30px

    p
        color: var(--be-colour-text-dark)

    &__header-container
        border-bottom: 1px dashed var(--be-colour-muted-text-dark)
        padding-bottom: 10px

    &__text-input
        height: 90px

        ::v-deep input
            width: 80%

    &__filter-editor
        margin-top: 20px

    &__tag-existing-mentions

        p
            margin-top: 20px

        .published-input
            width: 300px

        .brand-list
            max-height: 250px
            width: 400px
            overflow-y: auto
            border: 1px solid #1a1a1a

        .brand
            background: #333
            padding: 5px

            &:not(:last-of-type)
                border-bottom: 1px solid #1a1a1a

    &__preview-mentions
        display: flex
        height: 475px

</style>