var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " deleted";
},"3":function(container,depth0,helpers,partials,data) {
    return " edit";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"icon-attention smiley-warn\" title=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"smileyError") : depth0)) != null ? lookupProperty(stack1,"error") : stack1), depth0))
    + "\"></i>";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"edit\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":21},"end":{"line":14,"column":59}}})) != null ? stack1 : "")
    + ">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":91}}})) != null ? stack1 : "")
    + "\n    </td>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "title=\"Undelete\"";
},"10":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-reply\"></i>";
},"12":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-pencil\"></i>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"authorize editable\" style=\"display: block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authorizationExpired") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"authorizedText") : depth0), depth0))
    + "\n        </a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"icon-attention-1 warn\"></i>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showWarning") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":79}}})) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-attention-1 warn\"></i>";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"authorizationExpired") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":30,"column":13},"end":{"line":30,"column":156}}})) != null ? stack1 : "")
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"authorizedText") : depth0), depth0))
    + "</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showWarning") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":86},"end":{"line":30,"column":149}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div><span style=\"font-variant: small-caps; color: #ccc\">chatbot</span> "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"chatbot") : depth0), depth0))
    + "</div>";
},"25":function(container,depth0,helpers,partials,data) {
    return "deleted";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/renderMedia.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"media") : depth0),{"name":"renderMedia","hash":{},"data":data,"loc":{"start":{"line":36,"column":17},"end":{"line":36,"column":38}}}));
},"29":function(container,depth0,helpers,partials,data) {
    return "Yes";
},"31":function(container,depth0,helpers,partials,data) {
    return "No";
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div title=\"Likes\">"
    + container.escapeExpression(__default(require("../../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"likes") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":42,"column":36},"end":{"line":42,"column":58}}}))
    + " <i class=\"icon-thumbs-up\"></i></div>";
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div title=\"Follower count\">"
    + container.escapeExpression(__default(require("../../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"followerCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":43,"column":53},"end":{"line":43,"column":83}}}))
    + " <i class=\"icon-user-3\"></i></div>";
},"37":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div title=\"Subscriber count\">"
    + container.escapeExpression(__default(require("../../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"subscribers") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":44,"column":53},"end":{"line":44,"column":81}}}))
    + " <i class=\"icon-user-3\"></i></div>";
},"39":function(container,depth0,helpers,partials,data) {
    return "<div class=\"brand\"><i class=\"icon-dot\"></i>"
    + container.escapeExpression(__default(require("../../helpers/renderBrandPath.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"renderBrandPath","hash":{},"data":data,"loc":{"start":{"line":47,"column":63},"end":{"line":47,"column":84}}}))
    + "</div>";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"extra-brands\">\n            <a class=\"toggle\"><i class=\"plus icon-down-dir\"></i><i class=\"minus icon-up-dir\"></i>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"extraBrands") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " brands</a>\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"extraBrands") : depth0),{"name":"each","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":51,"column":112}}})) != null ? stack1 : "")
    + "\n        </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"tag-name tag editable\">\n            <span class=\"edit\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"handle-and-avatar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":58}}})) != null ? stack1 : "")
    + "\">\n    <div style=\"display: inline-flex\">\n        <img class=\"avatar"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":80}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n        <a href=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\">\n            <div class=\"handle\">"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "</div>\n            <div title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayHandle") : depth0), depth0))
    + "\" class=\"handle-id\">&nbsp;&nbsp;"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayHandle") : depth0), depth0))
    + "</div>\n        </a>\n    </div>\n    <div style=\"display: inline\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"smileyError") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":106}}})) != null ? stack1 : "")
    + "\n    </div>\n</td>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":17,"column":14}}})) != null ? stack1 : "")
    + "\n<td class=\"authorize-field\" title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"authorizedTitle") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canAuthorize") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chatbot") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":32,"column":115}}})) != null ? stack1 : "")
    + "\n</td>\n\n<td class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":11},"end":{"line":35,"column":40}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":40},"end":{"line":35,"column":94}}})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"media") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":36,"column":45}}})) != null ? stack1 : "")
    + "\n</td>\n<td class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":11},"end":{"line":38,"column":40}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":40},"end":{"line":38,"column":94}}})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"relevant") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":39,"column":40}}})) != null ? stack1 : "")
    + "\n</td>\n<td class=\"stats\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"likes") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":42,"column":102}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"followerCount") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":4},"end":{"line":43,"column":124}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribers") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":44,"column":122}}})) != null ? stack1 : "")
    + "\n</td>\n<td class=\"brands "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":18},"end":{"line":46,"column":47}}})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"brands") : depth0),{"name":"each","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":47,"column":99}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"extraBrands") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":53,"column":11}}})) != null ? stack1 : "")
    + "</td>\n<!--Tags column-->\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"_tags") : depth0),{"name":"each","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":4},"end":{"line":61,"column":13}}})) != null ? stack1 : "")
    + "</td>\n";
},"useData":true});