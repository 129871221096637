import {
    filterGetterFactory,
    formatDuration,
    formatDurationTooltip,
    formatNumber,
    formatPercentage,
    formatPercentageTooltip,
    simpleGetterFactory,
    simpleSetterFactory
} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";

import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import VuexStore from "@/store/vuex/VuexStore";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {formatBrandName} from "@/app/utils/Format";
import _ from 'underscore';

async function getResponseRateData(model, query, groupBy, ignore, ignoreY, getData) {
    await VuexStore.dispatch("refreshBrands");
    const idToBrand = VuexStore.getters.idToBrand;
    let filterBrands = [...new Set(getBrandsInFilter(query.filter).include)];
    const promises = filterBrands.map(brandId => {
        let filter = `(${query.filter}) and brand isorchildof ${brandId}`;
        let brand = idToBrand.get(brandId);
        while (brand?.parent) brand = brand.parent;

        // We want to filter to those support profiles that this brand uses.
        if (brand?.supportProfileIds?.length) {
            filter += " and (" +
                brand.supportProfileIds.map(profileId => "MentionedProfile IS " + profileId + " OR MentionedProfile ISNT " + profileId).join(" OR ")
                + ")";
        } else {
            notifyUserOfError(formatBrandName(brand) + " has no support handles, check brand setup")
        }

        const q = {...query, filter};
        return getData(q, [...groupBy], [...ignore], ignoreY)
    });
    const results = await Promise.all(promises);
    return results.flat();
}

export default {
    "averageResponseTime": {
        name: "Average response time",
        chartName: "Average response time",
        formatY: formatDuration,
        formatLabel: formatDuration,
        tooltipArticle: "an",
        formatTooltipY: formatDurationTooltip,
        defaultSortOptions: {
            label: "Average response time",
            field: "averageResponseTime",
            order: "descending"
        },
        filterGetter: filterGetterFactory("averageResponseTime"),
        getter: simpleGetterFactory("averageResponseTime"),
        setter: simpleSetterFactory("averageResponseTime"),
        excludeMoE: true
    },
    "maxResponseTime": {
        name: "Maximum response time",
        chartName: "Maximum response time",
        formatY: formatDuration,
        formatLabel: formatDuration,
        defaultSortOptions: {
            label: "Max response time",
            field: "maxResponseTime",
            order: "descending"
        },
        tooltipArticle: "an",
        formatTooltipY: formatDurationTooltip,
        filterGetter: filterGetterFactory("maxResponseTime"),
        getter: simpleGetterFactory("maxResponseTime"),
        setter: simpleSetterFactory("maxResponseTime"),
        excludeMoE: true
    },
    "minResponseTime": {
        name: "Minimum response time",
        chartName: "Minimum response time",
        formatY: formatDuration,
        formatLabel: formatDuration,
        defaultSortOptions: {
            label: "Min response time",
            field: "minResponseTime",
            order: "descending"
        },
        tooltipArticle: "an",
        formatTooltipY: formatDurationTooltip,
        filterGetter: filterGetterFactory("minResponseTime"),
        getter: simpleGetterFactory("minResponseTime"),
        setter: simpleSetterFactory("minResponseTime"),
        excludeMoE: true
    },
    "averageFirstReplyTime": {
        name: "Average time to first reply",
        chartName: "Average time to first reply",
        yLabel: {long: "Average time to first reply", short: "Average 1st reply"},
        defaultSortOptions: {
            label: "Average reply time",
            field: "averageFirstReplyTime",
            order: "descending"
        },
        formatY: formatDuration,
        formatLabel: formatDuration,
        tooltipArticle: "an",
        formatTooltipY: formatDurationTooltip,
        filterGetter: filterGetterFactory("averageFirstReplyTime"),
        getter: simpleGetterFactory("averageFirstReplyTime"),
        setter: simpleSetterFactory("averageFirstReplyTime"),
        onlySupportHandles: true,
        getData: getResponseRateData,
        excludeMoE: true
    },
    "maxFirstReplyTime": {
        name: "Maximum time to first reply",
        chartName: "Maximum time to first reply",
        formatY: formatDuration,
        formatLabel: formatDuration,
        defaultSortOptions: {
            label: "Max reply time",
            field: "maxFirstReplyTime",
            order: "descending"
        },
        tooltipArticle: "an",
        formatTooltipY: formatDurationTooltip,
        filterGetter: filterGetterFactory("maxFirstReplyTime"),
        getter: simpleGetterFactory("maxFirstReplyTime"),
        setter: simpleSetterFactory("maxFirstReplyTime"),
        excludeMoE: true
    },
    "minFirstReplyTime": {
        name: "Minimum time to first reply",
        chartName: "Minimum time to first reply",
        formatY: formatDuration,
        formatLabel: formatDuration,
        defaultSortOptions: {
            label: "Min reply time",
            field: "minFirstReplyTime",
            order: "descending"
        },
        tooltipArticle: "an",
        formatTooltipY: formatDurationTooltip,
        filterGetter: filterGetterFactory("minFirstReplyTime"),
        getter: simpleGetterFactory("minFirstReplyTime"),
        setter: simpleSetterFactory("minFirstReplyTime"),
        onlySupportHandles: true,
        getData: getResponseRateData,
        excludeMoE: true
    },
    "averageHasReply": {
        tooltipArticle: "a",
        name: "Response rate",
        chartName: "Response rate",
        isPercent: true,
        yLabel: {long: "Percentage of mentions replied to", short: "% of Mentions"},
        defaultSortOptions: {
            label: "Response rate %",
            field: "averageHasReply",
            order: "descending"
        },
        formatY: _.partial(formatPercentage, _, 0),
        formatLabel: _.partial(formatPercentage, _, 1),
        formatTooltipY: formatPercentageTooltip,
        getter: simpleGetterFactory("averageHasReply"),
        setter: simpleSetterFactory("averageHasReply"),
        onlySupportHandles: true,
        getData: getResponseRateData,
        excludeMoE: true
    },
    "totalHasReply": {
        tooltipArticle: "a",
        name: "Total mentions replied to",
        chartName: "Total mentions replied to",
        yLabel: {long: "Total mentions replied to", short: "# replied to"},
        defaultSortOptions: {
            label: "Total mentions replied to",
            field: "totalHasReply",
            order: "descending"
        },
        formatY: formatNumber,
        formatLabel: formatNumber,
        getter: simpleGetterFactory("totalHasReply"),
        setter: simpleSetterFactory("totalHasReply"),
        onlySupportHandles: true,
        getData: getResponseRateData,
        excludeMoE: true
    }

}