import moment from "moment";
import VuexStore from "@/store/vuex/VuexStore";

const timedWorked = new Map();

export function timedCacheKeyExists(key) {
    const cacheJson = localStorage.getItem(getTimedCacheKey(key));
    if (!cacheJson) return null;
    try {
        const cache = JSON.parse(cacheJson);
        const expire = moment(cache.expire);
        if (!expire || expire.isBefore(moment.now())) {
            localStorage.removeItem(key);
        }
        return cache.data;
    } catch(e) {
        console.warn("Unable to read cached data: ", e);
        localStorage.removeItem(key);
    }
}

/**
 * This provides a very useful way of storing data for a limited amount
 * of time in local storage. Note that the local storage cache
 * will only be cleared of stale data on access.
 *
 * NOTE: This can be called multiple times for long running compute / network calls and
 * will ensure that the extra calls are ignored and everyone sees the same data at the
 * end. This only works in the /current/ tab, but the data is shared across tabs.
 *
 * Caching automatically handles caching data between different accounts and users.
 *
 * @param {string} key
 * @param {function} asyncProducerFunction
 * @param {string,optional} expireTime A string giving the date and time of expiry, in the format "YYYY-MM-DD HH:mm"
 */
export async function getTimedLocalstorageCache(key, asyncProducerFunction, expireTime) {
    const hasStore = timedCacheKeyExists(key);
    if (hasStore) return hasStore;

    key = getTimedCacheKey(key);

    // Calculate cached data if we do not.
    let alreadyWorkingOn = timedWorked.get(key);
    if (alreadyWorkingOn) {
        // On this code path we can rely on the path
        // already executing the work to cache the data.
        return await alreadyWorkingOn;
    } else {
        let dataPromise = asyncProducerFunction();
        timedWorked.set(key, dataPromise);
        try {
            const data = await dataPromise;
            try {
                // Local storage errors shouldn't kill data being returned.
                localStorage.setItem(key, JSON.stringify({
                    expire: expireTime ?? moment().add(30, 'minutes').format("YYYY-MM-DD HH:mm"),
                    data: data
                }));
            } catch(e) {
                console.warn("Unable to cache data: ", key, e);
                if (e.name === "QuotaExceededError") localStorage.clear();
                else await clearTimedCacheOfStaleValues();
            }
            return data;
        } finally {
            timedWorked.delete(key);
        }
    }
}

/**
 * This goes through the timed cache to remove old, stale values.
 * @param {boolean} [force = false] Whether to delete all values, even if stale
 */
export async function clearTimedCacheOfStaleValues(force) {
    force ??= false;
    try {
        const keys = Object.keys(localStorage).filter(k => k.startsWith("timed-cache"));

        const impl = () => {
            if (!keys?.length) return;
            const key = keys.pop();

            if (force) {
                localStorage.removeItem(key);
            } else {
                try {
                    const cacheJson = localStorage.getItem(key);

                    if (cacheJson) {
                        const cache = JSON.parse(cacheJson);
                        if (!cache.expire || moment(cache.expire).isBefore(moment.now())) {
                            localStorage.removeItem(key);
                        }
                    }
                } catch (e) {
                    console.warn(`Unable to remove stale timed cache item [${key}]`, e);
                }
            }

            requestAnimationFrame(impl);
        };

        // We use requestAnimationFrame to ensure that this never reduces analyse's performance
        requestAnimationFrame(impl);
    } catch (e) {
        console.warn("Unable to clear timed cache of stale data", e);
    }
}


function getTimedCacheKey(key) {
    const account = VuexStore.state.account;
    const user = VuexStore.state.user;
    return `timed-cache:${account.code}:${user.id}:${key}`;
}
