<template>
    <section class="warning-indicator">
        <popup-menu ref="popup">
            <template #activator>
                <old-tooltip :label="tooltip">
                    <i class="symbol-warning"></i>
                </old-tooltip>
            </template>

            <section v-if="sections.length" class="warning-indicator__list">
                <ul v-if="sections.length">
                    <li v-for="sid in sections" :key="sid" @click="navigateToSection(sid)" class="warning-indicator__metric-link">
                        Section <strong>{{getSection(sid).title}}</strong> has
                        <strong class="number">{{getWarningsForSection(sid).length}}</strong>
                        {{warningText(getWarningsForSection(sid).length)}}
                    </li>
                </ul>
            </section>
            <section v-else class="warning-indicator__list">
                <ul v-if="warningsWithNoWidget.length" class="warning-indicator__toplevel">
                    <li v-for="w in warningsWithNoWidget"
                        :key="w.id + w.linkId"
                        :class="{'warning-indicator__metric-link': w.linkId}"
                        @click="clicked(w)"
                        v-html="w.message">
                    </li>
                </ul>
                <ul v-if="widgets.length">
                    <li class="warning-indicator__metric-link"
                        v-for="wid in widgets"
                        :key="wid"
                        @click="navigateToWidget(wid)">
                        Metric <strong>{{getWidget(wid).caption}}</strong> has {{getWarningsForWidget(wid).length}} {{warningText(getWarningsForWidget(wid).length)}}:
                        <ul>
                            <li v-for="w in getWarningsForWidget(wid)" :key="w.id" v-html="w.message">
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
        </popup-menu>
    </section>
</template>

<script>
import PopupMenu from "./PopupMenu";
import {getBrandsInFilter, getProfilesInFilter, getSegmentsInFilter, getTagsInFilter, getTopicsInFilter} from "@/dashboards/filter/FilterParser";
import VuexStore from "@/store/vuex/VuexStore";
import OldTooltip from "@/components/tooltip/OldTooltip";

const deprecatedResponseTimeYAxisFields = {
    averageFirstReplyTime: { name: "Average reply time" },
    averageHasReply: { name: "Response rate" },
    averageResponseTime: { name: "Average response time" },
    maxResponseTime: { name: "Maximum response time" },
    minResponseTime: { name: "Minimum response time" }
}

export async function checkDeprecatedResponseTimeFields(yFields) {
    if (yFields) {
        for (const yField of yFields) {
            const yAxis = yField.yAxis;
            if (Object.keys(deprecatedResponseTimeYAxisFields).includes(yAxis)) {
                return [
                    {
                        id: "DEPRECATED_FIELD_WARNING",
                        message: `This metric is using an old response time field that is no longer supported: <strong>${deprecatedResponseTimeYAxisFields[yAxis].name}</strong>`
                    }
                ]
            }
        }
    }

    return [];
}

export async function checkTags(filter, optionalCopyFunction) {
        await VuexStore.dispatch('refreshTags');
        const idToTag = VuexStore.getters.idToTag;
        const getCopy = (id, defaultValue) => optionalCopyFunction ? optionalCopyFunction(id) || defaultValue : defaultValue;
        if (filter) {
            const tags = getTagsInFilter(filter);
            const topics = getTopicsInFilter(filter);
            const segments = getSegmentsInFilter(filter);

            const notExists = async (array) => array && array.length && !(await Promise.all(array.map(id => idToTag.has(id)))).every(b => b)

            const missingTags = await notExists(tags.include) || await notExists(tags.exclude);
            const missingTopics = await notExists(topics.include) || await notExists(topics.exclude);
            const missingSegments = await notExists(segments.include) || await notExists(segments.exclude);
            const warnings = [];

            if (missingTags) {
                warnings.push({
                    id: 'MISSING_TAGS',
                    message: getCopy('MISSING_TAGS', "This metric's subfilter uses <strong>tags</strong> that have been deleted from the account")
                })
            }
            if (missingTopics) {
                warnings.push({
                    id: 'MISSING_TOPICS',
                    message: getCopy('MISSING_TOPICS', "This metric's subfilter uses <strong>topics</strong> that have been removed from the account")
                })
            }
            if (missingSegments) {
                warnings.push({
                    id: 'MISSING_SEGMENTS',
                    message: getCopy('MISSING_SEGMENTS', "This metric's subfilter uses <strong>CX or Conduct tags</strong> that have been removed from the account")
                })
            }
            return warnings;
        }

        return [];
    }

    export async function checkBrands(filter, optionalCopyFunction) {
        await VuexStore.dispatch("refreshBrands");
        const idToBrand = VuexStore.getters.idToBrand;
        const getCopy = (id, defaultValue) => optionalCopyFunction ? optionalCopyFunction(id) || defaultValue : defaultValue;
        const warnings = [];
        if (filter) {
            const brands = getBrandsInFilter(filter);
            const brandIds = [...brands.include, ...brands.exclude];

            for (const id of brandIds) {
                const exists = idToBrand.has(id);
                if (!exists) {
                    warnings.push({
                        id: 'MISSING_BRAND',
                        message: getCopy(
                            'MISSING_BRAND',
                            "This metric's subfilter uses a <strong>deleted brand</strong>"
                        )
                    });
                    break;
                }
            }
        }

        return warnings;
    }

    export async function checkProfiles(filter, optionalCopyFunction) {
        if (!filter) return [];

        await VuexStore.dispatch('profiles/refreshProfiles');
        const idToProfile = VuexStore.getters["profiles/idToProfile"];

        const getCopy = (id, defaultValue) => optionalCopyFunction ? optionalCopyFunction(id) || defaultValue : defaultValue;
        const warnings = [];

        if (filter) {
            const profiles = getProfilesInFilter(filter);
            const profileIds = [...profiles.include, ...profiles.exclude];

            for (const id of profileIds) {
                let profile = idToProfile.get(id);
                if (!profile || profile?.deleted) {
                    warnings.push({
                        id: 'MISSING_PROFILE',
                        message: getCopy(
                            'MISSING_PROFILE',
                            "This metric's subfilter uses a <strong>deleted profile</strong>"
                        )
                    })
                }
            }
        }

        return warnings;
    }


    export default {
        name: "WarningIndicator",
        components: {OldTooltip, PopupMenu},
        props: {
            warnings: {
                type: Array,
                default() { return [] }
            },
            tooltip: {
                type: String
            }
        },
        data() {
            return {
                open: true
            }
        },
        computed: {
            sections() {
                return [...new Set(this.warnings.map(w => w.sectionId).filter(id => !!id))];
            },
            widgets() {
                return [...new Set(this.warnings.map(w => w.widgetCid).filter( id => !!id))];
            },
            warningsWithNoWidget() {
                return this.warnings.filter(w => !w.widgetCid && !w.sectionId);
            }
        },
        methods: {
            showWarnings() {
                this.$refs.popup.showMenu = true;
            },
            warningText(count) {
                if (count === 1) return "warning";
                return "warnings";
            },
            getWidget(cid) {
                return this.warnings.find(w => w.widgetCid === cid);
            },
            getSection(sid) {
                return this.warnings.find(w => w.sectionId === sid);
            },
            getWarningsForWidget(cid) {
                return this.warnings.filter(w => w.widgetCid === cid);
            },
            getWarningsForSection(id) {
                return this.warnings.filter(w => w.sectionId === id);
            },

            clicked(w) {
                this.$emit('warning-clicked', w);
            },

            navigateToWidget(wid) {
                const widget = document.querySelector(`[widget-id='${wid}']`);
                if (widget) {
                    widget.scrollIntoView({behavior: "smooth", block: "center"});
                }
            },
            navigateToSection(sid) {
                const section = document.querySelector(`.section[data-id='${sid}']`);
                if (section) {
                    section.scrollIntoView({behavior: "smooth", block: "start"});
                }
            }
        }

    }
</script>

<style lang="sass">
    // Needs to not be scoped css so that it can match the embedded html in the warnings.
    .warning-indicator__list
        color: var(--be-colour-text-dark)
        strong
            font-weight: bold
            font-size: calc(1em + 1px)
            color: white
</style>

<style scoped lang="sass">
    .warning-indicator__list
        position: relative
        width: 400px
        cursor: default

    ul
        padding: 0
        margin: 0

    ul ul
        margin-top: 5px
        margin-bottom: 2px
        border-left: 3px solid #222

    ul + ul
        border-top: 3px solid #222
        & > li
            padding-left: 30px

    li
        list-style: none
        margin: 0
        padding: 5px 10px
        &:first-of-type
            border-top-left-radius: 6px
            border-top-right-radius: 6px
        &:last-of-type
            border-bottom-left-radius: 6px
            border-bottom-right-radius: 6px
        &:not(:first-of-type)
            border-top: thin solid black
        &:nth-child(odd)
            background: #333
        &:nth-child(even)
            background: #444

    li.warning-indicator__metric-link
        cursor: pointer
        &:hover
            background: var(--background-menu-hover)
            color: white


    .symbol-warning
        position: relative
        cursor: pointer
        font-size: 1.5rem
        color: red
        animation: warning-pulse-colour 1.5s linear alternate-reverse infinite

    .symbol-warning:hover
        animation: none



    @keyframes warning-pulse-colour
        0%
            color: orange
        100%
            color: red

</style>