<template>
    <dialog-box
        modal
        :title="'Edit Command'"
        @close="close">
        <div style="display: flex">

            <drop-down-input :label="dropdownLabel"
                             placeholder="Select a metric..."
                             v-model="commandData"
                             :options="countOptions"></drop-down-input>
        </div>

        <div class="row-fluid">
            <div class="span12 control-row">
                <div class="control-group" @click="editFilter">
                    <label>Additional filter for metric</label>
                    <div class="controls">
                        <div class="tag-input uneditable-input filter span12">
                            <english-filter v-if="subFilter" :filter="subFilter" partial/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #buttons>
            <a class="cancel btn" @click="close">Cancel</a>
            <a class="btn btn" @click="deleteCommand">Delete</a>
            <a class="btn btn-primary" @click="save">Save</a>
        </template>

        <dialog-box v-if="showEditFilter" title="Edit Additional Filter" @close="showEditFilter = false" width="850px">
            <filter-editor v-model="subFilter" no-published no-trash />
            <template #buttons>
                <a class="cancel btn" @click="showEditFilter = false">Cancel</a>
                <a class="btn btn-primary" @click="editFilterOk">Ok</a>
            </template>
        </dialog-box>

    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox.vue";
import {countBtnOptions} from "@/dashboards/widgets/commentaryplus/inlineTools/CountInlineTool";
import {notifyUserOfError, notifyWithText} from "@/app/framework/notifications/Notifications";
import EnglishFilter from "@/components/formatters/EnglishFilter.vue";
import FilterEditor from "@/components/inputs/FilterEditor.vue";
import DropDownInput from "@/components/inputs/DropDownInput.vue";
import {topBtnOptions} from "@/dashboards/widgets/commentaryplus/inlineTools/TopInlineTool";

export default {
    name: "EditCountDialog",
    components: {DropDownInput, FilterEditor, EnglishFilter, DialogBox},

    props: {
        command: String,
        subFilterProp: String,
        commandType: String
    },

    data: function (){
        return {
            commandData: this.command,
            subFilter: this.subFilterProp,
            showEditFilter: false,
        }
    },

    methods: {

        close(){
            this.$emit('close');
        },

        save(){
            if (!this.isValidCommand(this.commandData)) {
                notifyUserOfError("Invalid command, please try again");
                return;
            }

            this.$emit('command-updated', {command: this.commandData, subFilter: this.subFilter});
            notifyWithText("Command changed successfully");
            this.close();
        },

        deleteCommand(){
            this.$emit('command-deleted');
            notifyWithText("Command deleted");
            this.close();
        },

        isValidCommand(text) {
            text = text?.trim() ?? '';
            return text.startsWith("{{") && text.endsWith("}}");
        },

        editFilter() {
            this.showEditFilter = true;
        },

        editFilterOk() {
            this.showEditFilter = false;
        },

    },

    computed: {
        countOptions(){
            switch (this.commandType) {
                case 'Count':
                    return countBtnOptions.map((option) => {
                        return {
                            id: option.command,
                            name: option.text
                        }
                    });
                case 'Top':
                    return topBtnOptions.map((option) => {
                        return {
                            id: option.command,
                            name: option.text
                        }
                    });
                default:
                    return {}
            }
        },

        dropdownLabel(){
            switch (this.commandType) {
                case 'Count':
                    return "What do you want to count?";
                case'Top':
                    return "What category with the most mentions do you want?";
                default:
                    return "";
            }
        }
    }
}


</script>


<style scoped lang="sass">

</style>