var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"std-palette palette\" data-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":58},"end":{"line":6,"column":64}}}) : helper)))
    + "\">\n                    <div class=\"inner\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"colours") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":10,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"swatch\" data-index=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":9,"column":60},"end":{"line":9,"column":70}}}) : helper)))
    + "\"><div class=\"swatch-inner\" style=\"background-color: "
    + alias1(container.lambda(depth0, depth0))
    + "\"></div></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"btn use-dashboard-colours\"\n                   title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"_useDefaultTitle") || (depth0 != null ? lookupProperty(depth0,"_useDefaultTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_useDefaultTitle","hash":{},"data":data,"loc":{"start":{"line":19,"column":26},"end":{"line":19,"column":46}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"_useDefaultText") || (depth0 != null ? lookupProperty(depth0,"_useDefaultText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_useDefaultText","hash":{},"data":data,"loc":{"start":{"line":19,"column":48},"end":{"line":19,"column":67}}}) : helper)))
    + "</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                <label class=\"checkbox\" title=\"Cycle through the colours in the palette\">\n                    <input class=\"colour-cycle\" type=\"checkbox\" checked=\"checked\">\n                    Use a different colour for each metric\n                </label>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"custom\" data-index=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":32,"column":61},"end":{"line":32,"column":71}}}) : helper)))
    + "\"><input class=\"colour-definition\" type=\"text\"></div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n    <div class=\"control-group span7\">\n        <label>Use one of the standard colour palettes</label>\n        <div class=\"controls palettes\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"_palettes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div style=\"margin-top: 2px\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"dashboardModel") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\"control-group span5\">\n        <label>Define your own palette</label>\n        <div class=\"controls custom-palette\">\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"_custom") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":133}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div style=\"margin-top: 5px\">\n            <a class=\"btn reset-custom\" title=\"Restore default custom colour palette\">Reset</a>\n            <a class=\"btn edit-custom-as-hex\" title=\"View/edit custom palette as a list of hex colour values\">Edit Hex</a>\n        </div>\n    </div>\n</div>\n";
},"useData":true});