<template>
    <div v-if="netSentiment !== null"
         class="be-net-sentiment-calculator">
        <slot :netSentiment="netSentiment"
              :previousNetSentiment="previousNetSentiment"
              :delta="delta"
              :loading="loading">
            <net-sentiment v-if="!noVerified" :net-sentiment="netSentiment" class="short-animated fadeIn"/>
            <span v-else class="deq-callout--muted">No verification</span>
            <span v-if="withDelta && delta !== null" class="be-sentiment-calculator__delta">
                &nbsp;
                <percentage-point-delta :delta="delta" class="short-animated fadeIn"/>
            </span>
        </slot>
    </div>
</template>

<script>
import {count} from "@/data/Grouse";
import NetSentiment from "@/components/NetSentiment";
import {earliestDate, latestDate} from "@/dashboards/filter/FilterParser";
import moment from "moment";
import {buildBasicFilter, convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import PercentagePointDelta from "@/components/formatters/PercentagePointDelta";

// The cache is used to allow calculators for the exact same filter to be used at the same time
// in multiple places.
const cache = new Map();

// Don't want to cache data forever. Clear the cache every minute.
setInterval(() => cache.clear(), 60000);

export default {
    components: {PercentagePointDelta, NetSentiment},
    props: {
        filter: String,
        withDelta: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            netSentiment: null,
            previousNetSentiment: null,
            noVerified: null
        }
    },

    computed: {
        delta() {
            if (this.netSentiment === null || this.previousNetSentiment === null) return null;
            return this.netSentiment - this.previousNetSentiment;
        },

        key() {
            if (!this.filter) return null;
            return this.filter.toLowerCase().trim();
        }
    },

    watch: {
        filter() {
            this.loadData();
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async loadData() {
            if (!this.key) return;

            try {
                let promise = cache.get(this.key);
                if (promise) {
                    try {
                        this.loading = true;
                        this.netSentiment = null;
                        this.previousNetSentiment = null;
                        this.noVerified = null;
                        const result = await promise;
                        if (result) {
                            this.netSentiment = result.netSentiment;
                            this.previousNetSentiment = result.previousNetSentiment;
                            this.noVerified = result.noVerified ?? false;
                        }
                    } catch (e) {
                        if (e.readyState === 0) return; // This was a network request that never started
                        cache.delete(this.key);
                        throw e;
                    } finally {
                        this.loading = false;
                    }
                } else {
                    promise = this.loadDataImpl();
                    cache.set(this.key, promise);
                    await promise;
                }
            } catch(e) {
                if (e.readyState !== 0) {
                    console.error("Unable to calculate net sentiment", e);
                }
            }
        },

        async loadDataImpl() {
            if (this.loading || !this.filter) return;
            try {
                this.loading = true;
                this.netSentiment = null;
                this.previousNetSentiment = null;
                this.noVerified = null;

                const sentiment = await count(this.filter, null, ["totalVerifiedSentiment", "sentimentVerifiedCount"]);
                this.noVerified = sentiment?.sentimentVerifiedCount === 0;
                this.netSentiment = sentiment?.sentimentVerifiedCount
                    ? sentiment.totalVerifiedSentiment / sentiment.sentimentVerifiedCount
                    : 0;

                const earliest = earliestDate(this.filter);
                const latest = latestDate(this.filter);

                if (earliest && latest) {
                    const duration = moment.duration(latest.diff(earliest));
                    earliest.subtract(duration);
                    latest.subtract(duration);

                    const attrs = convertFilterToAttrs(this.filter);
                    if (attrs) {
                        attrs.published = `${earliest.format('YYYY/MM/DD')}-${latest.format("YYYY/MM/DD")}`;
                        const previousFilter = buildBasicFilter(attrs);

                        const previous = await count(previousFilter, null, ["totalVerifiedSentiment", "sentimentVerifiedCount"]);
                        this.previousNetSentiment = previous?.sentimentVerifiedCount
                            ? previous.totalVerifiedSentiment / previous.sentimentVerifiedCount
                            : 0;
                    }
                }

                return {
                    netSentiment: this.netSentiment !== null ? parseFloat(this.netSentiment) : null,
                    previousNetSentiment: this.previousNetSentiment !== null ? parseFloat(this.previousNetSentiment) : null,
                    noVerified: this.noVerified
                }
            }
            finally {
                this.loading = false;
            }
        }
    }
}
</script>


<style scoped lang="sass">

.be-net-sentiment-calculator
    display: inline-block

    strong
        color: white
        font-size: calc(1em + 0.5px)

    .be-sentiment-calculator__delta
        color: var(--be-colour-muted-text-dark)


</style>