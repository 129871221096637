<template>

    <main class="deq-reset">
        <modal-popup  :overlay="true" class="modal-popup vue-dialog" :left="'0'" :top="'20%'" :bottom="'50%'"
                      :right="'0'" :modal="true" :no-dim="false" :width="'400px'" :no-transition="false" @close="emitClose">
            <div class="modal-content">
                <div class="title">
                    <h5>Import Mentions</h5>
                    <a @click="emitClose" class="close-btn"><i class="icon-cancel-7"></i></a>
                </div>

                <div class="body">
                    <div class="deq-file-input">
                        <label>Choose CSV file to upload:</label>
                        <div class="csv-control">
                            <input ref="fileInput" @change="readFile($event)" id="importMentionFile" type="file" name="file" class="" style="margin-bottom:10px;">
                        </div>
                        <div class="info-message">
                        </div>
                    </div>

                    <div v-if="infoMessage && !upload.start">
                        <p>Mention field headers found: <span v-for="(header, index) in infoMessage.fieldHeaders"
                                                              :key="index">{{ header }} <span
                            v-if="index !== infoMessage.fieldHeaders.length - 1">,</span></span></p>
                        <p>Tag headers found: <span v-for="(tag, index) in infoMessage.tagHeaders"
                                                    :key="index">{{ tag }} <span
                            v-if="index !== infoMessage.tagHeaders.length - 1">,</span></span></p>
                        <p>Total number of rows found: {{infoMessage.totalRows}}</p>
                    </div>

                    <div v-if="upload.start" class="progress-div">
                        <template v-if="!upload.error">
                            <label>Uploading ...</label>
                            <div class="progressMention">
                                <div class="progressMentionBar" :style="{'width': upload.progress + '%'}"></div>
                            </div>
                        </template>

                        <div v-if="upload.error" class="upload-error">
                            <i class="symbol-warning"></i>
                            <p v-if="upload.error">An error occurred while uploading the CSV file</p>
                        </div>

                        <div v-if="upload.success" class="upload-success">
                            <i class="symbol-success"></i>
                            <p>Successfully uploaded the CSV file</p>
                        </div>

                    </div>
                </div>

                <div class="footer">
                    <span class="error-message">{{upload.fileError}}</span>
                    <div class="footer-btns">
                        <be-button @click="emitClose">Cancel</be-button>
                        <be-button @click="onImportClick" :primary="true">Import</be-button>
                    </div>
                </div>
            </div>
        </modal-popup>
    </main>
</template>

<script>
import ModalPopup from "@/components/ModalPopup.vue";
import {mentionFields} from "@/setup/importmentions/mentionFieldsData";
import {mapState} from "vuex";
import {fondue} from "@/store/Services";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {features} from "@/app/Features";
import BeButton from "@/components/buttons/BeButton.vue";

export default {
    name: "ImportMentionsDialog",
    components: {BeButton, ModalPopup},
    data() {
        return {
            mentionFields: mentionFields,
            data: undefined,
            infoMessage: null,
            upload: {
                start: false,
                progress: 0,
                error: false,
                success: false,
                fileError: null
            }
        }
    },

    computed: {
        ...mapState(['account']),
    },


    mounted() {
        document.addEventListener("click",  this.onListen, {capture: true});
    },

    destroyed() {
        document.removeEventListener("click",  this.onListen, {capture: true});
    },

    methods: {

        onListen(event) {
            if(event.target.classList.contains('modal-wrapper')) {
                this.emitClose()
            }
        },

        emitClose() {
            this.$emit('close')
        },

        readFile(ev) {
            this.upload = {
                start: false,
                progress: 0,
                error: false,
                success: false,
                fileError: null
            };
            const file = this.$refs.fileInput.files[0];
            const csvFile = /csv.*/;
            this.data = undefined;
            this.upload.fileError = null;
            this.infoMessage = null;

            if (file) {
                if (file.name.match(csvFile)) {
                    const reader = new FileReader();
                    reader.onload = (e) => this.processData(reader.result);
                    reader.readAsText(file);
                } else {
                    this.upload.fileError = "File must be of type csv.";
                }
            }
        },

        processData(dataStr) {
            const data = d3.csvParse(dataStr);

            if (data.length === 0) {
                // this.showError("Unable to parse file. Is it valid csv?");
                this.upload.fileError = "Unable to parse file. Is it valid csv?";
                return;
            }

            const fieldHeaders = [];
            const tagHeaders = [];

            data.columns.forEach(header => {
                if (this.mentionFields.find(mentionObj => mentionObj.name === header.trim())) {
                    fieldHeaders.push(header);
                } else {
                    tagHeaders.push(header);
                }

            });

            const required = ['uri', 'published', 'content'];

            for (let i = 0; i < required.length; i++) {
                if (!fieldHeaders.includes(required[i])) {
                    this.upload.fileError = 'File must have a "' + required[i] + '" column.';
                    return;
                }
            }

            if (features.importMentionsObjectBugFix()) {
                data.forEach(d => {

                    const tags = this.handleDataMapping(d.tags, "name");
                    if (tags) d.tags = tags;

                    const mentionedAuthors = this.handleDataMapping(d.mentionedAuthors, "name");
                    if (mentionedAuthors) d.mentionedAuthors = mentionedAuthors;

                    const mediaLinks = this.handleDataMapping(d.mediaLinks, "url");
                    if (mediaLinks) d.mediaLinks = mediaLinks;

                    const generator = this.handleDataMapping(d.generator, "name");
                    if (generator){
                        d.generator = generator.at(0);
                    }
                })

                // TODO: remove after testing in Prod
                console.log(data)
            }

            // unknown fields used as tags
            data.forEach(d => {
                tagHeaders.forEach(function (tagHeader) {
                    const tag = d[tagHeader];
                    if (tag) {
                        d.tags = d.tags || [];
                        d.tags.push({name: tag});
                    }
                    delete d[tagHeader];
                });

                // Jackson Date deserialization is supposedly tolerant, but really wants a "T" between time and date.
                d.published = d.published.replace(' ', 'T');
                // If there is a time, add a UTC timezone if there is none.
                if (d.published.length > 10 && d.published.indexOf('+') === -1 && /[0-9]$/.test(d.published))
                    d.published += 'Z';
            });

            this.data = data;
            this.infoMessage = {fieldHeaders: fieldHeaders, tagHeaders: tagHeaders, totalRows: data.length};
            this.upload.fileError = null;
        },

        handleDataMapping(data, key) {
            let mappedData;
            if (data) {
                try {
                    // object entered by admin
                    mappedData = JSON.parse(data);
                } catch (e) {
                    if (e.toString().startsWith('SyntaxError')) {
                        // normal client input -> tag1, tag2, tag3
                        mappedData =  this.splitAndMap(data, key);
                    }
                }
            }

            return mappedData;
        },

        splitAndMap(str, key) {
            let splitGenerator = str.split(',');
            return splitGenerator.map(gen => {
                return {[key]: gen.trim()}
            })
        },

        onImportClick() {
            if (!this.data) {
                this.upload.fileError = "File must be of type csv.";
                return;
            }

            this.upload.start = true;

            const onUploadProgress = (progressEvent) => {
                this.upload.progress = ( progressEvent.loaded / progressEvent.total ) * 100;
            };

            const jsonData = JSON.stringify(this.data);
            const headers = {"Content-Type": "application/json"};

            fondue.post("accounts/" + this.account.code + "/mentions/create",
                jsonData,
                { headers, onUploadProgress: progressEvent => onUploadProgress(progressEvent) })
                .then(res => {
                    if (res.status === 200) {
                        this.upload.success = true
                        setTimeout(() => {
                            this.emitClose();
                        }, 1000)
                    }
                })
                .catch(error => {
                    notifyUserOfError(error)
                    console.warn(error)
                    this.upload.error = true
                })
        }
    }
}
</script>

<style scoped lang="sass">

.progressMention
    height: 20px
    margin-bottom: 20px
    overflow: hidden
    background-color: #f7f7f7
    border-radius: 3px
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)

    .progressMentionBar
        float: left
        width: 0
        height: 100%
        font-size: 12px
        color: #000000
        text-align: center
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
        background-color: #0e90d2
        transition: width 0.6s ease



.modal-content
    height: 100%
    position: relative

.title
    background-color: var(--dialog-title-background-colour)
    border-top-left-radius: 2px
    border-top-right-radius: 2px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 0 10px 0 10px
    height: 31px

    .close-btn
        display: block
        cursor: pointer
        float: right
        font-weight: bold
        text-shadow: unset
        opacity: 0.5
        color: unset

        &:hover
            color: white
            opacity: 1

        i
            display: block

.body
    padding: 0 10px 0 10px
    margin-top: 30px



    .progress-div
        display: block
        margin-top: 20px

        .upload-error, .upload-success
            display: flex
            flex-direction: row
            align-items: center
            gap: 5px

            p
                margin-bottom: -4px

        .upload-success
            p
                color: var(--background-menu-active)

        .bar
            width: 0
            background-color: blue

.footer
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 0 10px 0 10px
    margin-bottom: 20px
    position: absolute
    bottom: 0

    .error-message
        color: var(--ac-colour-suspended)

    .footer-btns
        display: flex
        flex-direction: row
        gap: 5px

        .cancel-btn, .save-btn
            height: 25px
            width: 60px
            text-align: center
            padding: 2px
            border-radius: 3px
            cursor: pointer

        .save-btn
            background-color: var(--mention-item-selector-colour)

            &:hover
                background-color: var(--background-menu-active)
                color: #fff
                transition: all .1s linear

        .cancel-btn
            &:hover
                color: var(--background-menu-active)
                transition: color .1s linear

</style>