import moment from "moment";

/**
 * Returns true iff the given string is of the form DAY-n, WEEK-n, MONTH-n, where
 * n is a positive integer.
 */
export function isDateSpec(spec) {
    let split = spec.split('-');
    if (split.length !== 2) return false;

    let first,
        second;

    try {
        first = split[0].toLowerCase();
        second = parseInt(split[1]);
    }
    catch (e) {
        return false;
    }

    return (first === 'month' || first === 'week' || first === 'day') && second > 0;
}

//--------------

/**
 * Converts date specs to moment.js objects. These date specs are in the form
 * MONTH-2, WEEK-1, and so on, as produced by the PastDatePicker.
 */
export function specToDate(spec, date) {
    if (!Array.isArray(spec)) spec = spec.split('-');

    let granularity = spec[0].toLowerCase();
    let period      = parseInt(spec[1]);

    if (!date) date = moment();

    switch (granularity) {
        case 'day':     date.subtract(period, 'days'); break;
        case 'week':    date.subtract(period, 'weeks'); break;
        case 'month':   date.subtract(period, 'months'); break;
        default:        throw "Unrecognised date specified [" + granularity + "]";
    }

    return date;
}


/**
 * Utility method to convert a published value (MONTH, WEEK, 2016/06/01-2016/06/30) to a start and end date
 * (both inclusive). This is used by some charts to fill in missing date buckets.
 * @param {string} value
 * @returns {{start: Date, end: Date}}
 */
export function toDateRange(value) {
    let start, end;
    let fn = DATE_RANGE[value];
    if (fn) {
        let range = fn();
        start = range.start;
        end = range.end;
    } else if (value) {
        let a = value.split("-");
        start = parseDate(a[0]);
        end = parseDate(a[1]);
    }
    return { start: start, end: end }
}


export const DATE_RANGE = {
    TODAY: function() { return {label: 'Today', start: Date.today(), end: 'today'}},
    '24HOURS': function() { return {label: 'In the last 24 hours', start: Date.today().add({ hours: -24}), end: 'today'}},
    'TWENTY_FOUR_HRS': function() { return {label: 'In the last 24 hours', start: Date.today().add({ hours: -24}), end: 'today'}},
    DAY: function() { return {label: 'In the last day', start: Date.today().add({ days: -1 }), end: 'today'}},
    WEEK: function() { return {label: 'In the last week', start: Date.today().add({ days: -7 }), end: 'today'} },
    FORTNIGHT: function() { return {label: 'In the last fortnight', start: Date.today().add({ days: -13 }), end: 'today'} },
    MONTH: function() { return {label: 'In the last month', start: Date.today().add({ months: -1}), end: 'today'} },
    QUARTER: function() { return {label: 'In the last quarter', start: Date.today().add({ months: -3 }), end: 'today'} },
    YEAR: function() { return {label: 'In the last year', start: Date.today().add({ years: -1 }), end: 'today'} },
    EPOCH: function() { return {label: 'In the last epoch', start: Date.today().add({ years: -100 }), end: 'today'} }
};


/**
 * @param {String} text
 * @returns {Date}
 */
export function parseDate(text) {
    const mom = moment(text, isHour(text) ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD');
    return mom.toDate();
}

/**
 * @param {String} text
 * @returns {boolean}
 */
export function isHour(text) {
    return text.length > 10;
}