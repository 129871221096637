var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Set sentiment to";
},"3":function(container,depth0,helpers,partials,data) {
    return "Include conversation with this sentiment";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label>"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onlyOne") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":7},"end":{"line":1,"column":93}}})) != null ? stack1 : "")
    + "</label>\n\n<table class=\"table-condensed\">\n    <tbody>\n    <tr>\n        <td><input type=\"checkbox\" name=\"-1\">\n            <i class=\"icon-circle negative-sentiment\"></i>\n            Negative\n        </td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"1\">\n            <i class=\"icon-circle neutral-sentiment\"></i>\n            Neutral\n        </td>\n    </tr>\n    <tr class=\"pos\">\n        <td><input type=\"checkbox\" name=\"2\">\n            <i class=\"icon-circle positive-sentiment\"></i>\n            Positive\n        </td>\n    </tr>\n    </tbody>\n</table>\n";
},"useData":true});