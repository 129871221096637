import Vue from "vue";
import {getAllChannelSegmentLists, getAllCxSegmentLists} from "@/app/utils/Segments";
import {substituteTagParamaters} from "@/app/utils/Tags";
import priority from "@/app/framework/dialogs/metric-picker/metrics/priorityMetrics";
import VuexStore from "@/store/vuex/VuexStore";
import {appendSegmentRestrictions} from "@/app/utils/Segments";
import {escapeHtml} from "@/app/utils/StringUtils";


const cx = Vue.observable({
    title: "Customer experience",
    description: "Identify areas of friction and opportunity in your customer journey.",
    expanded:
`_Customer experience_ categorises mentions from your customers. 
This may include mentions from authors who we have identified as being on a customer journey with you, 
or are interacting in a way that lets us know they are a customer, or potential customer.`,
    image: require("../assets/cx.png"),
    hide: true,
    metrics: []
});

export default cx;

function createNomenclature(segment, tooltip) {
    return {
        type: "Nomenclature",
        caption: escapeHtml`${segment.name} reference sheet`,
        width: 6,
        height: 4,
        selectedList: segment.id,
        tooltip: tooltip
    }
}

export async function init() {
    const [cxLists, channels, ignore] = await Promise.all([
        getAllCxSegmentLists(),
        getAllChannelSegmentLists(),
        VuexStore.dispatch('refreshBrands')
    ]);
    const brands = VuexStore.state.rootBrands;

    // We want to be able to look up which segments are active
    const activeSegments = new Set();
    brands.forEach(b => b.activeSegmentListIds?.forEach(id => activeSegments.add(id)));

    if (cxLists.length) {
        for (const segment of cxLists) {
            const title = cxLists.length === 1 ? segment.name : `${segment.subtitle} ${segment.name}`
            cx.metrics.push({
                id: `cx:${segment.id}`,
                title: title,
                description: substituteTagParamaters(segment.clientDescription),
                more: "This allows you to understand customer feedback in relation to the customer lifecycle and monitor sentiment by journey stage.",
                image: require("../assets/cx/cx.png"),
                keywords: ["cx", "priority", "rpcs"],
                additional: !activeSegments.has(segment.id),
                widgetDesc: {
                    type: "FantasticChart",
                    xAxis: `${segment.id}`,
                    yAxis: "mentionPercent",
                    width: 8,
                    height: 4,
                    geometry: "columns",
                    notification: escapeHtml`Added a <strong>${title}</strong> metric`
                },
                reference: createNomenclature(segment,
                    "Add a metric that describes the journey stages")
            })
        }
        cx.metrics[0].hero = true;
        cx.metrics[0].additional = false;
    }

    if (channels.length) {
        let channel = null;
        for (const segment of channels) {
            const title = channels.length === 1 ? segment.name : `${segment.subtitle} ${segment.name}`
            cx.metrics.push({
                id: `channels:${segment.id}`,
                title: title,
                description: substituteTagParamaters(segment.clientDescription),
                more: "Channel mapping allows you to assess customer interactions with touch points, such as call centres and mobile apps.",
                keywords: ["cx", "priority", "rpcs"],
                image: require("../assets/cx/channels.png"),
                additional: !activeSegments.has(segment.id),
                widgetDesc: {
                    type: "FantasticChart",
                    xAxis: `${segment.id}`,
                    yAxis: "mentionPercent",
                    width: 8,
                    height: 4,
                    geometry: "columns",
                    notification: escapeHtml`Added a <strong>${title}</strong> metric`
                },
                reference: createNomenclature(segment,
                    "Add a metric that describes the channel touchpoints for this metric")
            });
            channel = channel || cx.metrics[cx.metrics.length - 1];
        }
    }

    if (cxLists.length) {
        cx.metrics.push({
            id: `service`,
            title: "Service factors",
            description: "See mention volumes broken down by your service moments",
            image: require("../assets/cx/service.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "service",
                yAxis: "mentionPercent",
                width: 8,
                height: 4,
                geometry: "columns",
                notification: "Added a metric showing all <strong>Service Factors</strong>"
            }
        });

        cx.metrics.push({
            id: `serviceVolumes`,
            title: "Service volumes",
            description: "See how your volumes of service-related conversations have changed",
            image: require("../assets/cx/service-volumes.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                width: 8,
                height: 4,
                filter: appendSegmentRestrictions(`tag is 4 and (${cxLists.map(c => `segment is ${c.id}`).join(' or ')})`),
                notification: "Added a metric showing all <strong>Service volumes</strong>",
                caption: "Service volumes",
                "manual-caption": true,
                xGridLines: false,
                show: [
                    {
                        geometry: "lines",
                        yAxis: "mentionPercent",
                        "colour-palette": "rpcs",
                        "colour-index": 3,
                    }
                ]
            }
        });

        cx.metrics.push({
            id: `cancelVolumes`,
            title: "Cancellation volumes",
            description: "See how your volumes of cancellation-related conversations have changed",
            image: require("../assets/cx/cancel-volumes.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                width: 8,
                height: 4,
                filter: appendSegmentRestrictions(`tag is 3 and (${cxLists.map(c => `segment is ${c.id}`).join(' or ')})`),
                notification: "Added a metric showing all <strong>Cancellation volumes</strong>",
                caption: "Cancellation volumes",
                "manual-caption": true,
                xGridLines: false,
                show: [
                    {
                        geometry: "lines",
                        yAxis: "mentionPercent",
                        "colour-palette": "rpcs",
                        "colour-index": 2,
                    }
                ]
            }
        });

        cx.metrics.push({
            id: `purchaseVolumes`,
            title: "Purchase volumes",
            description: "See how your volumes of purchase-related conversations have changed",
            image: require("../assets/cx/purchase-volumes.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                width: 8,
                height: 4,
                filter: appendSegmentRestrictions(`tag is 2 and (${cxLists.map(c => `segment is ${c.id}`).join(' or ')})`),
                notification: "Added a metric showing all <strong>Purchase volumes</strong>",
                caption: "Purchase volumes",
                "manual-caption": true,
                xGridLines: false,
                show: [
                    {
                        geometry: "lines",
                        yAxis: "mentionPercent",
                        "colour-palette": "rpcs",
                        "colour-index": 1,
                    }
                ]
            }
        });
    }


    if (cx.metrics.length) {
        cx.hide = false;
    }
}

