const LANGUAGE_KEY = 'dataeq:dialogs:translation';

/**
 * Translates the text into the requested language. Returns a promise.
 *
 * The object returned looks like:
 *
 * {
 *    sourceLanguage: { name: "English", code: "en" }​,
 *    sourceText: <original text>,​
 *    targetLanguage: Object { name: "Afrikaans", code: "af" }​,
 *    targetText: <translated text>
 * }
 *
 * @param text {string} Text to translate
 * @param language {string} 2-char language code.
 * @returns {Promise<{targetText: string, sourceText: string, targetLanguage: {name: string, code: string}, sourceLanguage: {name: string, code: string}}>}
 */
export function translate(text, language) {
    if (!text || !text.trim().length) {
        return Promise.reject("No text provided");
    }
    if (!language || !language.trim().length) {
        return Promise.reject("No language provided");
    }

    return fetch("/api/languages/translate", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({text: text.trim(), code: language.trim()})
    }).then(function (response) {
        if (!response.ok) throw "HTTP error handling translation";
        return response.json()
    }).then(function (value) {
        if (value.error) throw new Error(value.error);
        if (value.targetText) {
            // This appears to be a problem with either sauerkraut or google translate,
            // where some elements are replaced with html elements. This is very
            // noticeable for apostrophes in afrikaans.
            // ideally this should be fixed in sauerkraut.
            value.targetText = value.targetText.replaceAll("&#39;", "'");
        }
        return value;
    })
}


export const LANGUAGES = {
    'ab': 'Abkhazian',
    'aa': 'Afar',
    'af': 'Afrikaans',
    'sq': 'Albanian',
    'am': 'Amharic',
    'ar': 'Arabic',
    'an': 'Aragonese',
    'hy': 'Armenian',
    'as': 'Assamese',
    'ay': 'Aymara',
    'az': 'Azerbaijani',
    'ba': 'Bashkir',
    'eu': 'Basque',
    'bn': 'Bengali (Bangla)',
    'dz': 'Bhutani',
    'bh': 'Bihari',
    'bi': 'Bislama',
    'br': 'Breton',
    'bg': 'Bulgarian',
    'my': 'Burmese',
    'be': 'Byelorussian (Belarusian)',
    'km': 'Cambodian',
    'ca': 'Catalan',
    'zh': 'Chinese',
    'co': 'Corsican',
    'hr': 'Croatian',
    'cs': 'Czech',
    'da': 'Danish',
    'nl': 'Dutch',
    'en': 'English',
    'eo': 'Esperanto',
    'et': 'Estonian',
    'fo': 'Faeroese',
    'fa': 'Farsi',
    'fj': 'Fiji',
    'fi': 'Finnish',
    'fr': 'French',
    'fy': 'Frisian',
    'gl': 'Galician',
    'gd': 'Gaelic (Scottish)',
    'gv': 'Gaelic (Manx)',
    'ka': 'Georgian',
    'de': 'German',
    'el': 'Greek',
    'kl': 'Greenlandic',
    'gn': 'Guarani',
    'gu': 'Gujarati',
    'ht': 'Haitian Creole',
    'ha': 'Hausa',
    'he': 'Hebrew (he)',
    'iw': 'Hebrew (iw)',
    'hi': 'Hindi',
    'hu': 'Hungarian',
    'is': 'Icelandic',
    'io': 'Ido',
    'ig': 'Igbo',
    'id': 'Indonesian (id)',
    'in': 'Indonesian (in)',
    'ia': 'Interlingua (ia)',
    'ie': 'Interlingue (ie)',
    'iu': 'Inuktitut',
    'ik': 'Inupiak',
    'ga': 'Irish',
    'it': 'Italian',
    'ja': 'Japanese',
    'jv': 'Javanese',
    'kn': 'Kannada',
    'ks': 'Kashmiri',
    'kk': 'Kazakh',
    'rw': 'Kinyarwanda (Ruanda)',
    'ky': 'Kirghiz',
    'rn': 'Kirundi (Rundi)',
    'ko': 'Korean',
    'ku': 'Kurdish',
    'lb': 'Luxembourgish',
    'lo': 'Laothian',
    'la': 'Latin',
    'lv': 'Latvian (Lettish)',
    'li': 'Limburgish (Limburger)',
    'ln': 'Lingala',
    'lt': 'Lithuanian',
    'mk': 'Macedonian',
    'mg': 'Malagasy',
    'ms': 'Malay',
    'ml': 'Malayalam',
    'mt': 'Maltese',
    'mi': 'Maori',
    'mr': 'Marathi',
    'mo': 'Moldavian',
    'mn': 'Mongolian',
    'na': 'Nauru',
    'nd': 'isiNdebele',
    'ne': 'Nepali',
    'no': 'Norwegian',
    'oc': 'Occitan',
    'or': 'Oriya',
    'om': 'Oromo (Afan, Galla)',
    'ps': 'Pashto (Pushto)',
    'pl': 'Polish',
    'pt': 'Portuguese',
    'pa': 'Punjabi',
    'qu': 'Quechua',
    'rm': 'Rhaeto-Romance',
    'ro': 'Romanian',
    'ru': 'Russian',
    'sm': 'Samoan',
    'sg': 'Sangro',
    'sa': 'Sanskrit',
    'sr': 'Serbian',
    'sh': 'Serbo-Croatian',
    'st': 'Sesotho',
    'tn': 'Setswana',
    'sn': 'Shona',
    'ii': 'Sichuan Yi',
    'sd': 'Sindhi',
    'si': 'Sinhalese',
    'ss': 'Siswati',
    'sk': 'Slovak',
    'sl': 'Slovenian',
    'so': 'Somali',
    'es': 'Spanish',
    'su': 'Sundanese',
    'sw': 'Swahili (Kiswahili)',
    'sv': 'Swedish',
    'tl': 'Tagalog (Filipino)',
    'tg': 'Tajik',
    'ta': 'Tamil',
    'tt': 'Tatar',
    'te': 'Telugu',
    'th': 'Thai',
    'bo': 'Tibetan',
    'ti': 'Tigrinya',
    'to': 'Tonga',
    'ts': 'Tsonga',
    'tr': 'Turkish',
    'tk': 'Turkmen',
    'tw': 'Twi',
    'ug': 'Uighur',
    'uk': 'Ukrainian',
    'ur': 'Urdu',
    'uz': 'Uzbek',
    've': 'Venda',
    'vi': 'Vietnamese',
    'vo': 'Volap\u00fck',
    'wa': 'Wallon',
    'cy': 'Welsh',
    'wo': 'Wolof',
    'xh': 'Xhosa',
    'yi': 'Yiddish (yi)',
    'ji': 'Yiddish (ji)',
    'yo': 'Yoruba',
    'zu': 'Zulu',
    'unknown': 'Unknown'
};

/**
 * Returns a readable name for the given language code, or returns the language code if no name is available.
 * @param code {string}
 * @returns {string}
 */
export function getLanguageName(code) {
    switch(code) {
        case 'zh-CN': return "Chinese (Simplified)";
        case 'zh-TW': return "Chinese (Traditional)";
        default:
            return LANGUAGES[code] || code;
    }
}


/**
 * Returns the default language to use as a translation target.
 * Attempts to use the last language used, or one specified by the browser (i.e., the user).
 * Defaults to English, otherwise.
 *
 * @returns {string}
 */
export function getDefaultTargetLanguage() {
    // We want to figure out what language we should translate to.
    // If the user has previously translated, we use that. If the user hasn't,
    // we try to use the language's they've set the browser to report to us.
    // Otherwise we use the language of the browser's UI.
    // If all else fails, default to English.
    let lastLanguage = localStorage.getItem(LANGUAGE_KEY);

    try {
        if (!lastLanguage) {
            if (navigator.languages && navigator.languages.length) {
                // stores regionalised language names, like en-US
                lastLanguage = navigator.languages[0].split('-')[0].toLowerCase();
            } else if (navigator.language) {
                lastLanguage = navigator.language.split('-')[0].toLowerCase();
            }
        }
    } catch (e) {
        console.warn("Error while determining default browser language " + e);
    }

    // Ensure that something is always set.
    return lastLanguage || 'en';
}

/**
 * Saves a new language to use as the default target language in the future.
 */
export function saveDefaultTargetLanguage(code) {
    try {
        if (!code || !code.length) {
            localStorage.removeItem(LANGUAGE_KEY);
            return;
        }

        localStorage.setItem(LANGUAGE_KEY, code);
    } catch (e) {
        console.warn("Unable to save default target language ", e);
    }
}