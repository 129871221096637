/* Provides a way to show a loading message while loading other components. */
<template>
    <div class="section section-form activity-list">
        <div v-if="title" class="header">
            <h2 class="title">
                {{ title }} <spinner-component v-if="loading" :size="16"/>
            </h2>
        </div>


        <div class="main">

            <div class="filters">
                <div class="control-group">
                    <label>Type</label>
                    <div class="activity-list__types btn-group">
                        <span @click="selectType(null)" :class="`btn${selectedType === null ? ' active' : ''}`">All</span>
                        <span
                            v-bind:key="type.id"
                            v-for="type in types"
                            @click="selectType(type.id)"
                            :class="`btn${ selectedType === type.id ? ' active' : ''}`"
                        >
                            <old-tooltip :label="type.description">{{type.label}}</old-tooltip>
                        </span>
                    </div>
                </div>
                <div v-if="!dateRangeOverride" class="control-group">
                    <label>Date range</label><br/>
                    <published-input v-model="dateRange" :options="dateRangeOptions" />
                </div>
                <div class="control-group">
                    <label>Search within Details</label><br/>
                    <input v-model="searchString" type="text" />
                </div>
                <div v-if="!title" class="control-group">
                    &nbsp;<br/>
                    <spinner-component v-if="loading" :size="16"/>
                </div>
            </div>
             <div v-if="errorMessage" class="error-message">
                {{ errorMessage }}
            </div>
            <table v-if="total > 0" :class="`table table-bordered table-condensed table-hover${loading ? ' loading' : ''}`">
                <thead>
                    <tr>
                        <td>User</td>
                        <td colspan="2">Date</td>
                        <td colspan="2">Type</td>
                        <td>Details</td>
                    </tr>
                </thead>
                <tbody>
                    <activity-item v-for="(item, index) in logItems" :item="item" v-bind:key="index" :searchString="searchString" />
                </tbody>
            </table>
            <div v-else class="muted">
                No logs were found for the given filters
            </div>

            <div v-if="pages > 1" class="pagination">
                <div class="beef-pager">
                    <span :class="`prev${hasPrev ? '': ' disabled'}`" @click="hasPrev ? prev() : null ">prev</span>
                    <span v-if="hasLess" @click="less()" class="btn">...</span>
                    <span
                        v-for="i in numButtons"
                        v-bind:key="i"
                        :class="`btn${page % maxButtons === i % maxButtons ? ' active' : ''}`"
                        @click="goToPage(i)"
                    >
                        {{ list * maxButtons + i }}
                    </span>
                    <span v-if="hasMore" @click="more()" class="btn">...</span>
                    <span :class="`next${hasNext ? '' : ' disabled'}`" @click="hasNext ? next() : null ">next</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import moment from "moment";

import {mash} from "@/store/Services";
import PublishedInput from '../inputs/PublishedInput';
import ActivityItem from "./ActivityItem";
import axios, {CancelToken} from 'axios';
import {debounce} from 'underscore';
import {isFunction} from "@/app/utils/Util";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {toDateRange} from "@/app/utils/Dates";
import SpinnerComponent from "@/components/SpinnerComponent";

export default {
        name: "ActivityView",
        store: VuexStore,

        components: {OldTooltip, SpinnerComponent, ActivityItem, PublishedInput},

        props: {
            title: {
                type: String,
                default: 'Recent activity'
            },
            dateRangeOverride: String
        },

        data: function() {
            return {
                maxButtons: 10,
                offset: 0,
                limit: 20,
                total: 0,

                dataTypes: [],
                dateRange: this.dateRangeOverride || "MONTH",
                searchString: '',
                selectedType: null,
                errorMessage: null,
                logItems: [],
                cancel: null,
                dateRangeOptions: {
                    labelPrefix: 'Logged ',
                    promptText: {
                        overall: 'Include logs from the last',
                        multiple: 'logs'
                    }
                }
            }
        },

        computed: {
            ...mapState(['user', 'account']),

            types: function() {
                const publicTypes = [
                    { id: "DASHBOARD",    label: "Dashboard edits" },
                    { id: "ACCOUNT_ACCESSED", label: "Account accessed"},
                    { id: "DATA_DOWNLOAD", label: "Data downloaded" },
                    { id: "ALERT_DIGEST", label: "Alert emails" },
                    { id: "TAG", label: "Tag edits"},
                    { id: "PROFILE", label: "Profiles"}
                ];

                if (!this.user.admin) {
                    // Look for the actual types if they have been returned, so that we can
                    // use up to date labels, descriptions, etc, from mash.
                    if (!this.dataTypes?.length) return publicTypes;
                    const results = Array.from(publicTypes);
                    for (const item of publicTypes) {
                        const found = this.dataTypes.find(d => d.id === item.id);
                        if (found) Object.assign(item, found);
                    }
                    return results;
                }
                return this.dataTypes?.length ? this.dataTypes : publicTypes;
            },
            toExclusive: function() {
                return (
                    this.range.end === 'today'
                    ? moment().add(1, 'minute')
                    : moment(this.range.end).format('HHmm') !== '0000' // infer that the user specified a time
                    ? moment(this.range.end).add(1, 'minute')
                    : moment(this.range.end).add(1, 'day')
                )
            },
            numButtons: function() {
                const lastButton = this.maxButtons * (this.list + 1);
                return lastButton > this.pages ? this.pages - lastButton + this.maxButtons : this.maxButtons
            },
            range:      function() { return toDateRange(this.dateRange) },
            from:       function() { return this.dateRange === '24HOURS' ? moment().subtract(24, 'hours') : moment(this.range.start); },
            loading:    function() { return isFunction(this.cancel) },
            page:       function() { return Math.floor(this.offset / this.limit) + 1 },
            list:       function() { return Math.floor((this.page - 1) / this.maxButtons) },
            pages:      function() { return Math.floor((this.total - 1) / this.limit) + 1 },
            hasPrev:    function() { return this.offset > 0 },
            hasLess:    function() { return this.list > 0 },
            hasNext:    function() { return this.offset + this.limit < this.total },
            hasMore:    function() { return this.list < Math.floor(this.pages / this.maxButtons) }
        },

        created() {
            mash.get("/rest/data-model/activity-types")
                .then(response => {
                    this.dataTypes = response.data;
                    this.dataTypes.push({ id: "NONE", label: "Other"});
                })
                .catch(e => console.warn(e));
        },

        mounted() {
            this.fetchData();
        },

        async activated() {
            await this.fetchData();
        },

        methods: {
            async fetchData() {
                this.errorMessage = null;

                let query = [
                    `/rest/accounts/${this.account.code}/activity`,
                    `?limit=${ this.limit }`,
                    `&offset=${ this.offset }`,
                    `&from=${this.from.toISOString()}`,
                    `&to=${this.toExclusive.toISOString()}`,
                    this.searchString ? `&searchString=${this.searchString}` : '',
                    this.selectedType !== null ? `&type=${this.selectedType}` : ''
                ].join('');

                if (isFunction(this.cancel)) this.cancel('Cancelling previous request');
                try {
                    const response = await mash.get(query, { cancelToken: new CancelToken(c => this.cancel = c) });
                    this.logItems = response.data.data;
                    this.total = response.data.total;
                    this.cancel = null;
                } catch (error) {
                    if (!axios.isCancel(error)){
                        this.cancel = null;
                        this.errorMessage = error.message;
                    }
                }
            },
            prev()              { this.offset -= this.limit },
            next()              { this.offset += this.limit },
            less()              { this.offset = this.list * this.maxButtons * this.limit - 1 },
            more()              { this.offset = (this.list + 1) * this.maxButtons * this.limit },
            goToPage(i)         { this.offset = (this.list * this.maxButtons + i - 1) * this.limit },
            selectType(typeId)  { this.selectedType = typeId }
        },

        watch: {
            selectedType:       function(){ this.offset = 0; this.fetchData(); },
            searchString:       debounce(function(){ this.offset = 0; this.fetchData(); }, 450), //, {maxWait: 1000}
            offset:             function(){ this.fetchData() },
            dateRange:          function(){ this.offset = 0; this.fetchData(); },
            dateRangeOverride:  function(){ this.dateRange = this.dateRangeOverride || 'MONTH' }
        }
    }
</script>

<style scoped lang="sass">
.error-message
    color: #ff938f
    margin-bottom: 20px
table
    transition: opacity 0.3s
    &.loading
        pointer-events: none
        transition: opacity 0.1s
        opacity: 0.3
.css-spinner
    margin-left: 8px
.filters
    margin-bottom: 20px
    display: flex
    .control-group
        padding-right: 20px
        .btn-group
            margin-top: 3px
            display: block
            .btn
                user-select: none
    label
        display: inline-block
.beef-pager
    .btn, .next, .prev
        user-select: none
    .next, .prev
        color: #aee15d
        &.disabled
            color: #999


.filters .btn-group.activity-list__types
    display: flex
    flex-wrap: wrap
</style>