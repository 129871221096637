var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit the";
},"3":function(container,depth0,helpers,partials,data) {
    return "Add a";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"control-group\">\n                    This phrase has been deleted. You can make changes here and click Ok to un-delete it.\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"read-only-inc-terms\">\n                        <label>Include all of these terms (case insensitive)</label>\n                        <div class=\"controls\">\n                        <span class=\"tag-input uneditable-input span12 readonly include\">\n                            "
    + alias2(__default(require("../../helpers/renderPhrase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"_include") : depth0),{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":53}}}))
    + "\n                        </span>\n                        </div>\n                        <label>This phrase is more than "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"_maxEditAge") : depth0), depth0))
    + " old so the include terms are read-only. If you\n                            need to make changes then add a new phrase and de-activate this one\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOldPhrases",{"name":"ifallowed","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":36,"column":42}}})) != null ? stack1 : "")
    + "                        </label>\n                    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                                <a class=\"link-bright unlock\"\n                                    title=\"You are an ops user so it is assumed that you understand the risks involved in editing phrases\"\n                                    >unlock</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"edit-inc-terms\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":48},"end":{"line":42,"column":93}}})) != null ? stack1 : "")
    + ">\n                        <label>Include all of these terms (case insensitive). Press enter after each phrase</label>\n                        <div class=\"controls\">\n                            <span class=\"_include tag-input uneditable-input span12\">\n                                <span name=\"_include\"></span>\n                                <input dir=\"auto\" class=\"tag popup-menu\" type=\"text\"/>\n                            </span>\n                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":54,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "                            <label>You are editing an existing phrase that may have already collected data. Don't\n                            make edits that change the meaning of the phrase, rather create a new phrase and\n                                de-activate or delete this one.</label>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"approval\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"approved") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":73,"column":24},"end":{"line":79,"column":31}}})) != null ? stack1 : "")
    + "                        </span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"approvedBy") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":28},"end":{"line":76,"column":36}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"icon-check\"></span> Approved "
    + alias1(__default(require("../../helpers/humanizeDate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"approved") : depth0),{"name":"humanizeDate","hash":{},"data":data,"loc":{"start":{"line":75,"column":74},"end":{"line":75,"column":99}}}))
    + " by "
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"approvedBy") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"approvedBy") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                            <span class=\"icon-clock\"></span> Phrase is inactive pending approval\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                            Collect mentions for this phrase\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                            Start collecting mentions for this phrase when I'm done\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"control-group control-group-spacer\">\n                    <label>Only read these platforms (leave blank for all)</label>\n                    <div class=\"controls\">\n                        <span class=\"socialNetworks tag-input uneditable-input span12\">\n                            <span name=\"socialNetworks\"></span>\n                            <input class=\"tag popup-menu\" type=\"text\"/>\n                        </span>\n                    </div>\n                </div>\n                <div class=\"control-group control-group-spacer\" style=\"margin-top: 0\">\n                    <label>Only read these feeds (leave blank for all)</label>\n                    <div class=\"controls\">\n                        <span class=\"feeds tag-input uneditable-input span12\">\n                            <span name=\"feeds\"></span>\n                            <input class=\"tag popup-menu\" type=\"text\"/>\n                        </span>\n                    </div>\n                </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"phrase-filter-region\"></div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"pull-left\">\n                "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":16},"end":{"line":126,"column":105}}})) != null ? stack1 : "")
    + "\n                <a class=\"move btn\" title=\"Move this phrase to a different brand\">Move</a>\n            </span>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "<a class=\"delete btn\" title=\"Delete this phrase\">Delete</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":33}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":63}}})) != null ? stack1 : "")
    + " search phrase</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"pages dark-scrollbars dark-scrollbars--visible\">\n\n        <div class=\"main-form row-fluid page-edit\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"control-group\">\n                Find mentions that:\n            </div>\n\n            <div class=\"control-group control-group-spacer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditIncTerms") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"control-group control-group-spacer\">\n                <label>Exclude all of these terms (case insensitive). Press enter after each phrase</label>\n                <div class=\"controls\">\n                    <span class=\"_exclude tag-input uneditable-input span12\">\n                        <span name=\"_exclude\"></span>\n                        <input dir=\"auto\" class=\"tag popup-menu\" type=\"text\"/>\n                    </span>\n                </div>\n            </div>\n\n            <div class=\"control-group control-group-spacer\">\n                <div class=\"controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":20},"end":{"line":81,"column":27}}})) != null ? stack1 : "")
    + "                    <label class=\"checkbox\">\n                        <input type=\"checkbox\" name=\"active\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":84,"column":24},"end":{"line":88,"column":31}}})) != null ? stack1 : "")
    + "                    </label>\n                </div>\n            </div>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"phraseFilters",{"name":"ifallowed","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":12},"end":{"line":111,"column":26}}})) != null ? stack1 : "")
    + "\n        </div>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"phraseFilters",{"name":"ifallowed","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":8},"end":{"line":116,"column":22}}})) != null ? stack1 : "")
    + "        <div class=\"main-form page-goodness\"></div>\n\n        <div class=\"main-form page-tune\"></div>\n\n    </div>\n\n    <div class=\"dialog-button-bar\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":8},"end":{"line":129,"column":15}}})) != null ? stack1 : "")
    + "        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"prev btn\"><i class=\"icon-chevron-left\"></i> Previous</a>\n        <a class=\"next btn\"><i class=\"icon-chevron-right\"></i> Next</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n</div>\n";
},"useData":true});