import * as brandseye from 'brandseyejs'
import {cloud as d3Cloud} from "@/js/d3.layout.cloud";
import _ from 'underscore';

/**
 * Raw wordcloud rendering support. Used by our metrics. Copied from the old brandseyejs.
 */

    // the actual chart code is a cut and paste from brandseyejs

export function WordCloudChart() {
    this.attributes = {
        data: [],
        width: 250,
        height: 250,
        duration: 250,
        padding: {left: 0, right: 0, bottom: 0, top: 0},
        dispatch: d3.dispatch('elementClick', 'elementMiddleClick', 'elementRightClick', 'tooltipShow', 'tooltipHide'),
        seed: Math.floor(Math.random() * 10000),
        x: function (d) {
            return d.word
        },
        y: function (d) {
            return d.count
        },
        font: 'Cairo',
        layout: 'archimedean',
        minFont: 9,
        maxFont: 70
    };
    return this;
}

WordCloudChart.prototype.element = function (_) {
    if (!arguments.length) return this.attributes.element;
    this.attributes.element = _;
    return this;
};

WordCloudChart.prototype.width = function (_) {
    if (!arguments.length) return this.attributes.width;
    this.attributes.width = _;
    return this;
};

WordCloudChart.prototype.height = function (_) {
    if (!arguments.length) return this.attributes.height;
    this.attributes.height = _;
    return this;
};

WordCloudChart.prototype.colours = function (_) {
    if (!arguments.length) return this.attributes.colours;
    this.attributes.colours = (_ && _.length) ? _ : brandseye.colours.allColours;
    return this;
};

WordCloudChart.prototype.dispatch = function () {
    return this.attributes.dispatch;
};

WordCloudChart.prototype.setupContainer = function () {
    this.attributes.parent.classed('bm', true)
};
WordCloudChart.prototype.arrangeLabels = function () {
};

WordCloudChart.prototype.data = function (data) {
    if (!arguments.length) return this.attributes.data;
    this.attributes.data = data || [];
    return this;
};

WordCloudChart.prototype.x = function (x) {
    if (!arguments.length) return this.attributes.x;
    this.attributes.x = x;
    return this;
};

WordCloudChart.prototype.y = function (y) {
    if (!arguments.length) return this.attributes.y;
    this.attributes.y = y;
    return this;
};

/**
 * Function to be invoked when rendering is complete.
 */
WordCloudChart.prototype.onRender = function (fn) {
    if (!arguments.length) return this.attributes.onRender;
    this.attributes.onRender = fn;
    return this;
};

WordCloudChart.prototype.noAnimation = function (on) {
    if (!arguments.length) return this.attributes.noAnimation;
    this.attributes.noAnimation = on;
    return this;
};

WordCloudChart.prototype.render = function () {
    if (!this.attributes.nvChart) this.attributes.nvChart = d3Cloud();

    var parent = this.attributes.parent = d3.select(this.element());
    this.setupContainer();

    if (parent.selectAll('svg').empty()) {
        parent.append('svg').append('g');
    }

    var x      = this.attributes.x,
        y      = this.attributes.y,
        width  = this.width(),
        height = this.height();

    var svg = this.attributes.svg = parent.select('svg');

    svg.attr("width", width).attr("height", height)
       .select("g")
       .attr("transform", "translate(" + [Math.floor(width / 2), Math.floor(height / 2)] + ")");

    var cloud = this.attributes.nvChart;

    var fontSize;
    switch (this.attributes.scale) {
        case 'linear':
            fontSize = d3.scaleLinear();
            break;
        case 'square':
            fontSize = d3.scaleSqrt();
            break;
        case 'log':
        default:
            fontSize = d3.scaleLog();
    }

    var words = this.data();
    var min = Infinity,
        max = -Infinity;
    _(words).each(function (d) {
        if (y(d) < min) min = y(d);
        if (y(d) > max) max = y(d);
    });

    fontSize.domain([min, max]).range([this.attributes.minFont, this.attributes.maxFont]);

    var font = this.attributes.font;

    cloud
        .random(createRandom(this.seed()))
        .spiral(this.attributes.layout)
        .size([width, height])
        .timeInterval(10)
        .text(x)
        .fontSize(function (d) {
            return fontSize(y(d));
        })
        .font(font)
        .rotate(function () {
            return 0;
        })
        .padding(true)
        .on("end", this.layoutComplete.bind(this))
        .words(words)
        .start();

};

// This is called after the words have been laid out by the layout
// algorithm. It places the words in the svg container
// and lays them out.
WordCloudChart.prototype.layoutComplete = function (words) {
    var wordToCount = {};
    var data = this.data();
    if (!data.length) return;

    var x       = this.attributes.x,
        y       = this.attributes.y,
        colours = this.colours();

    _(data).each(function (d) {
        wordToCount[x(d)] = y(d);
    });

    var dispatch = this.dispatch();

    var text = this.attributes.svg.select('g').selectAll(".word").data(words);
    text.exit().remove();
    text = text.enter()
        .append("text")
        .classed('word', true)
        .attr("text-anchor", "middle")
        .attr("direction", d => isRtlWord(d.text) ? "rtl" : null)
        .on('click', function (d) {
            dispatch.call("elementClick", this, d, this)
        })
        .on('mouseover', function (d) {
            dispatch.call("tooltipShow", this, d, this)
        })
        .on('mouseout', function (d) {
            dispatch.call("tooltipHide", this, d)
        })
        .merge(text)

    text.text(function (d) { return d.text })
        .style('font-family', this.font())

    let o = this.attributes.noAnimation ? text : text.transition().duration(1000)
    o.style("font-size", function (d) {return d.size + "px" })
        .style("fill", function (d, i) { return colours[i % colours.length] })
        .attr("transform", function (d) { return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")" });

    if (this.attributes.onRender) this.attributes.onRender()
};

// https://stackoverflow.com/a/14824756/159434
const RTL_CHARS_REGEX = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]+/

function isRtlWord(d) {
    return RTL_CHARS_REGEX.test(d)
}

WordCloudChart.prototype.layout = function (_) {
    if (!arguments.length) return this.attributes.layout;
    this.attributes.layout = _;
    return this;
};

// This determines how the different fonts should be scaled to one another.
// There are three choices (specified as a string):
// - *linear*: word sizes are in a direct linear relationship based on their count.
// - *square*: word sizes are related by the square root of their counts.
// - *log*: word sizes are related logarithmically by their counts.
WordCloudChart.prototype.scale = function (_) {
    if (!arguments.length) return this.attributes.scale;
    this.attributes.scale = _;
    return this;
};

WordCloudChart.prototype.minFont = function (_) {
    if (!arguments.length) return this.attributes.minFont;
    this.attributes.minFont = _;
    return this;
};

WordCloudChart.prototype.maxFont = function (_) {
    if (!arguments.length) return this.attributes.maxFont;
    this.attributes.maxFont = _;
    return this;
};

// Sets the font to be used for sizing information. This defaults
// to sans-serif. Please ensure to use the same font as you would when
// rendering the cloud, otherwise you will notice rendering problems, such
// as overlapping words, large spaces between words, and so on.
WordCloudChart.prototype.font = function (_) {
    if (!arguments.length) return this.attributes.font;
    this.attributes.font = _;
    return this;
};

// This seed value is used to determine the sequence of random values that are used when
// laying out the words. If you would like to use the same layout, use the same seed as previously used.
// Each new instance of *WordCloudChart* will have a seed initialised to a random value by default.
WordCloudChart.prototype.seed = function (_) {
    if (!arguments.length) return this.attributes.seed;
    this.attributes.seed = _;
    return this;
};

// This stops the animation of the word cloud.
WordCloudChart.prototype.stop = function () {
    if (this.attributes.nvChart) this.attributes.nvChart.stop();
};

// Returns a pseudo-random number generator function starting with the specified seed.
// Modified from: http://jacksondunstan.com/articles/393
var createRandom = function (seed) {
    return function () {
        return (seed = (seed * 9301 + 49297) % 233280) / 233280.0;
    }
};