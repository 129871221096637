var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"shortName") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"fullName") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "<i class='symbol-warning' title='Sentiment has not been verified by a human and may be inaccurate'></i>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<dt>This mention has the following sentiment to</dt>\n<table cellpadding=\"0\" cellspacing=\"0\" border=\"0\">\n    <tr data-brand=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n        <td class=\"brand\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"fullName") : stack1), depth0))
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"shortName") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":53},"end":{"line":4,"column":128}}})) != null ? stack1 : "")
    + "</td>\n        <td class=\"sentiment-warning-col\">"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"sentimentVerified") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":185}}})) != null ? stack1 : "")
    + "</td>\n        <td class=\"sentiment-col\">"
    + alias2(__default(require("../helpers/renderSentiment.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"sentiment") : depth0),depth0,{"name":"renderSentiment","hash":{},"data":data,"loc":{"start":{"line":6,"column":34},"end":{"line":6,"column":68}}}))
    + "</td>\n    </tr>\n</table>\n";
},"useData":true});