import { render, staticRenderFns } from "./AnalyseLink.vue?vue&type=template&id=81a5951a&scoped=true"
import script from "./AnalyseLink.vue?vue&type=script&lang=js"
export * from "./AnalyseLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a5951a",
  null
  
)

export default component.exports