/**
 * Drill down by adding/removing gender to the filter.
 */

import {GENDER_ITEMS} from "@/app/utils/Gender";

Beef.module("Widget.GenderSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Gender",
        description:    "Interactively filter by gender",
        width:          1,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        filterAttribute: "gender",

        getTooltipText: function(item) {
            var lower = item.name.toLowerCase();
            if (lower == 'unknown') return "Click to select mentions from authors who we do not know the gender of";
            if (lower == 'other') return "Click to select mentions from authors who do not identify themselves as women or men";

            var label = lower;
            switch (label) {
                case 'female': label = "women"; break;
                case 'male': label = "men"; break;
            }

            return "Click to select mentions from " + label;
        },

        refresh: function() {
            var items = Object.entries(GENDER_ITEMS).map(function([key, value]) {
                return { id: key, name: value }
            });
            this.text(items);
        }
    });
});