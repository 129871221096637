<template>
    <section :class="classes" class="sidebar-menu">
        <section @click="toggleCollapsed()" :class="{'sidebar-menu--collapsable': collapsable}">
            <slot name="header" v-bind:collapsed="isCollapsed"/>
        </section>


        <slot v-bind:collapsed="isCollapsed" v-if="!isCollapsed || !collapsable">
            <sidebar-menu-item>Example one</sidebar-menu-item>
            <sidebar-menu-item>Example two</sidebar-menu-item>
        </slot>
    </section>
</template>


<script>

import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";

export default {
    components: {SidebarMenuItem},

    props: {
        collapsable: {
            type: Boolean,
            default: false
        },
        startCollapsed: {                    // Whether this starts as collapsed or not.
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: "inline",
            validator: val => ["inline", "bottom"].indexOf(val) > -1
        }
    },

    data() {
        return {
            isCollapsed: this.startCollapsed
        }
    },

    computed: {
        classes() {
            const classes = [];
            if (this.position === "bottom") classes.push("sidebar-menu--bottom");
            return classes;
        }
    },

    methods: {
        toggleCollapsed() {
            if (!this.collapsable) return;
            this.isCollapsed = !this.isCollapsed;
        }
    },

    watch: {
        // handles cases where "startCollapsed" property is changed in parent component after this component has been rendered
        startCollapsed(v) {
            this.isCollapsed = v;
        }
    }
}

</script>


<style scoped lang="sass">

@use "sidebar"

.sidebar-menu--collapsable
    cursor: pointer

.sidebar-menu--bottom
    margin-top: auto


</style>