var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"control-group\">\n                <div class=\"controls btn-group\" data-toggle=\"buttons-radio\">\n                    <label>Display the metric as</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"graphType") : stack1)) != null ? lookupProperty(stack1,"text") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":11,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"graphType") : stack1)) != null ? lookupProperty(stack1,"histogram") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"graphType") : stack1)) != null ? lookupProperty(stack1,"rows") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"graphType") : stack1)) != null ? lookupProperty(stack1,"columns") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"graphType") : stack1)) != null ? lookupProperty(stack1,"pies") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"graphType") : stack1)) != null ? lookupProperty(stack1,"lines") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":36,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"text btn\" title=\"Display the data as text only\">\n                            <div class=\"thumb\"><i class=\"icon-fontsize\"></i></div>Text\n                        </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"histogram btn\" title=\"Display the data as a histogram\">\n                            <div class=\"thumb\"><i class=\"icon-signal-1\"></i></div>Histogram\n                        </button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"rows btn\" title=\"Display the data as a horizontal bar chart\">\n                            <div class=\"thumb\"><i class=\"icon-align-left\"></i></div>Bars\n                        </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"columns btn\" title=\"Display the data as a vertical bar chart\">\n                            <div class=\"thumb\"><i class=\"icon-signal-1\"></i></div>Columns\n                        </button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"pies btn\" title=\"Display the data as a pie chart\">\n                            <div class=\"thumb\"><i class=\"icon-chart-pie\"></i></div>Pie Chart\n                        </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"lines btn\" title=\"Display the data as a line chart\">\n                            <div class=\"thumb\"><i class=\"icon-chart-line\"></i></div>Line Chart\n                        </button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"labelTypes") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":55,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"sampledSentiment") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":73,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"maxItems") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":16},"end":{"line":84,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"control-group\">\n                        <label>Report on the data by</label>\n                        <div class=\"controls btn-group button-array\" data-toggle=\"buttons-radio\">\n                            <button class=\"btn count\"       title=\"Show raw totals for each category\">Count</button>\n                            <button class=\"btn percentage\"  title=\"Show the percentage each category makes up of the total\">%</button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"noAveOts") : stack1),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":28},"end":{"line":52,"column":39}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                <button class=\"btn ave\"     title=\"Show the Advert-Value-Equivalent for each category\">AVE</button>\n                                <button class=\"btn ots\"     title=\"Show the Opportunities-To-See for each category\">OTS</button>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"control-group\">\n                        <label>Estimate sentiment by</label>\n                        <div class=\"btn-group button-array sampled-sentiment\" data-toggle=\"buttons-radio\">\n                            <button class=\"btn\" data-id=\"auto\"\n                                    title=\"Use random human sampling if enough crowd verified mentions are available to provide accurate results\">\n                                Auto\n                            </button>\n                            <button class=\"btn\" data-id=\"true\" title=\"Estimate sentiment using randomly selected mentions verified by The Crowd\">\n                                Random Crowd Sample\n                            </button>\n                            <button class=\"btn\" data-id=\"false\" title=\"Aggregate sentiment from all mentions\">\n                                Aggregating All Mentions\n                            </button>\n                        </div>\n                    </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"control-group\">\n                        <label title=\"The number of items to show in the metric\">\n                            Show the following number of items\n                            <div class=\"controls\">\n                                <input class=\"maxItems\" type=\"text\" name=\"max-items\">\n                            </div>\n                        </label>\n                    </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row-fluid\">\n                <div class=\"control-group\">\n                    <label>Show only these profiles</label>\n                    <div class=\"controls\">\n                        <span class=\"profiles tag-input uneditable-input big-popup\" tabindex=\"0\" style=\"width: 90%\">\n                            <span name=\"profiles\"></span>\n                        </span>\n                    </div>\n                </div>\n            </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"control-group\">\n                <label>Group the data by</label>\n                <div class=\"controls\">\n                    <div class=\"btn-group button-array\" data-toggle=\"buttons-radio\">\n                        <button class=\"hourly btn\"  title=\"Show the data broken down hour-by-hour\">Hour</button>\n                        <button class=\"daily btn\"   title=\"Show the data broken down day-by-day\">Day</button>\n                        <button class=\"weekly btn\"  title=\"Show the data broken down week-by-week\">Week</button>\n                        <button class=\"monthly btn\" title=\"Show the data broken down month-by-month\">Month</button>\n                        <button class=\"yearly btn\"  title=\"Show the data broken down year-by-year\">Year</button>\n                        <button class=\"total btn\"   title=\"Show a grand total. This will only show a textual total value, not a graph\">Overall</button>\n                    </div>\n                </div>\n            </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row-fluid\">\n                <div class=\"control-group\">\n                    <label>Display AVE in the following currency</label>\n                    <div class=\"controls\">\n                        <span class=\"currency tag-input uneditable-input\" tabindex=\"0\">\n                            <span name=\"currency\"></span>\n                            <input class=\"tag popup-menu\" type=\"text\"/>\n                        </span>\n                    </div>\n                </div>\n            </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row-fluid\">\n                <div class=\"control-group\">\n                    <label>Include these root brands</label>\n                    <div class=\"controls\">\n                        <span class=\"brands tag-input uneditable-input\" tabindex=\"0\">\n                            <span name=\"brands\"></span>\n                            <input class=\"tag popup-menu\" type=\"text\"/>\n                        </span>\n                    </div>\n                </div>\n            </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label>Other Options</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"labels") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":12},"end":{"line":155,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"legend") : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":12},"end":{"line":161,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"parents") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":12},"end":{"line":167,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"children") : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":12},"end":{"line":173,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"other") : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":12},"end":{"line":179,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"unknown") : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":12},"end":{"line":185,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"colour") : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":12},"end":{"line":189,"column":19}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    return "                <label class=\"checkbox\" title=\"Show/hide the labels on the metric\">\n                    <input class=\"labels\" type=\"checkbox\" checked>\n                    Show data labels\n                </label>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "                <label class=\"checkbox\" title=\"Show/hide information about the comparison data sets\">\n                    <input class=\"legend\" type=\"checkbox\" checked>\n                    Show legend\n                </label>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "                <label class=\"checkbox\" title=\"Show/hide the parent topics\">\n                    <input class=\"parents\" type=\"checkbox\" checked>\n                    Show parent topics\n                </label>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "                <label class=\"checkbox\" title=\"Show/hide the child topics\">\n                    <input class=\"children\" type=\"checkbox\" checked>\n                    Show child topics\n                </label>\n";
},"39":function(container,depth0,helpers,partials,data) {
    return "                <label class=\"checkbox\" title=\"Show/hide all of the results that are grouped under 'other'\">\n                    <input class=\"other\" type=\"checkbox\" checked>\n                    Show Other values\n                </label>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "                <label class=\"checkbox\" title=\"Show/hide all of the results with unknown values\">\n                    <input class=\"unknown\" type=\"checkbox\" checked>\n                    Show Unknown values\n                </label>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"current-colour\" style=\"background-color: "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"colour") : depth0), depth0))
    + "\" title=\"The current colour of the main data series\"></div>\n                <a class=\"btn btn-link colour-scheme\">Choose colour palette</a>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"sentimentVerification") : stack1),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":4},"end":{"line":208,"column":11}}})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"span6\">\n        <div class=\"control-group\">\n            <label>Use only verified data for sentiment</label>\n            <div class=\"btn-group button-array sentiment-verification\" data-toggle=\"buttons-radio\">\n                <button class=\"btn\" data-id=\"no\" title=\"Use any data\">No</button>\n                <button class=\"btn\" data-id=\"yes\" title=\"Use verified data\">Yes</button>\n            </div>\n            <label id=\"senti-ver-label-widget\">Metric filter use overrides this option.</label>\n        </div>\n    </div>\n";
},"48":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"span6\">\n            <div class=\"control-group\">\n                <label>Display Language</label>\n                    <div class=\"controls\">\n                    <span class=\"language tag-input uneditable-input\" tabindex=\"0\">\n                        <span name=\"language\"></span>\n                        <input class=\"tag popup-menu\" type=\"text\"/>\n                    </span>\n                </div>\n            </div>\n        </div>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"span6\">\n            <div class=\"control-group\">\n                <label>Topic View</label>\n                <div class=\"controls\">\n                    <span class=\"topic-view tag-input uneditable-input span12\" name=\"topicview\" tabindex=\"0\"></span>\n                </div>\n            </div>\n        </div>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"topictree") : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":237,"column":8},"end":{"line":246,"column":15}}})) != null ? stack1 : "");
},"53":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"span6\">\n                <div class=\"control-group\">\n                    <label>Topic Tree</label>\n                    <div class=\"controls\">\n                        <span class=\"topic-view tag-input uneditable-input span12\" name=\"topicview\" tabindex=\"0\"></span>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid main-metric-settings\" style=\"margin-top: 10px\">\n    <div class=\"span7\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"graphType") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":86,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"profiles") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":8},"end":{"line":99,"column":15}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"coarseness") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":8},"end":{"line":116,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"currency") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":8},"end":{"line":130,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"brands") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":8},"end":{"line":144,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"span5\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":8},"end":{"line":190,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n</div>\n\n<div class=\"row-fluid main-metric-settings\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":0},"end":{"line":209,"column":7}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"row-fluid main-metric-settings\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"language") : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":213,"column":4},"end":{"line":225,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display-options") : depth0)) != null ? lookupProperty(stack1,"show") : stack1)) != null ? lookupProperty(stack1,"topicview") : stack1),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":226,"column":4},"end":{"line":235,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/hasTrees.js")).call(alias1,{"name":"hasTrees","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":236,"column":4},"end":{"line":247,"column":17}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"colour-page\">\n    <div class=\"colour-selector\"></div>\n    <a class=\"btn colour-back pull-right\" title=\"Return to the other settings for this metric\">Back</a>\n</div>\n\n\n\n\n\n\n\n\n\n\n";
},"useData":true});