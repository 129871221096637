<template>
    <section>
        <overview-title>Press mentions for </overview-title>
        <loading-message v-if="storeLoading" message="Examining your mentions..."/>
        <overview-description v-else-if="pressCount > 0">
            <h4>The most engaging, top-level, Press Mentions posted {{ englishDate }}</h4>
            <div>
                <p>
                    There have been
                    <strong>
                        <deq-number :number="pressCount"/> {{ formatPlural(pressCount, 'press mention') }} posted {{ englishDate }}
                    </strong>
                        concerning {{formatBrandName(brand)}}.
                    You can see all of them in the <a @click.prevent="gotoPressMentions()" :href="pressMentionsLink"><i class="symbol-mentions"></i>Mentions panel</a>,
                    or you can <a @click.prevent="createDashboard" href="#"><i class='symbol-reports'></i>create a dashboard</a> to examine these mentions in depth.

                    <net-sentiment-calculator v-if="filter"
                                              :filter="filter"
                                              v-slot="{netSentiment, delta}">
                        The overall Net Sentiment of the top-level Press mentions is
                        <strong style="white-space: nowrap">
                            <net-sentiment :net-sentiment="netSentiment"/> <percentage-point-delta v-if="delta !== null" :delta="delta"/>.
                        </strong>
                    </net-sentiment-calculator>
                </p>
                <p v-if="filter">
                    <most-frequent-topic :filter="filter"/>
                </p>
            </div>
        </overview-description>
        <no-topics-message v-else-if="pressCount === 0">
            There have been no top-level Press Mentions concerning {{formatBrandName(brand)}}
        </no-topics-message>
        <engaging-mentions v-if="!storeLoading && brand && pressCount > 0" :filter="filter" :date-filter="dateFilter"/>
    </section>
</template>


<script>
import EngagingMentions from "@/app/toplevel/explore/overview/components/EngagingMentions";
import {mapGetters, mapState} from "vuex";
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";
import DeqNumber from "@/components/formatters/DeqNumber";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import moment from "moment";
import NetSentiment from "@/components/NetSentiment";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";
import NetSentimentCalculator from "@/app/toplevel/explore/overview/components/NetSentimentCalculator";
import PercentagePointDelta from "@/components/formatters/PercentagePointDelta";
import MostFrequentTopic from "@/app/toplevel/explore/overview/components/MostFrequentTopic";
import LoadingMessage from "@/components/LoadingMessage";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {createSimpleDashboardForFilter} from "@/app/toplevel/dashboards/BeefModuleDashboardUtilities";
import {formatBrandName, formatPlural} from "@/app/utils/Format";

export default {
    components: {
        DeqNumber,
        OverviewDescription,
        NoTopicsMessage,
        LoadingMessage,
        MostFrequentTopic,
        PercentagePointDelta,
        NetSentimentCalculator, NetSentiment, OverviewTitle, EngagingMentions},

    computed: {
        ...mapState(['account']),
        ...mapState('explorePanel', ['brand']),
        ...mapState('explorePanel', {
            storeLoading: state => state.loading
        }),
        ...mapGetters('explorePanel', ['pressCount', 'brandName', 'dateFilter', 'englishDate']),
        ...mapGetters('explorePanel', {
            filter: 'pressFilter'
        }),
        pressMentionsLink() {
            if (!this.filter) return "#";
            return `/${this.account.code}/mentions?filter=${encodeURIComponent(this.filter)}&orderby=engagement desc`;
        },
    },

    methods: {
        formatBrandName,
        formatPlural,

        gotoPressMentions() {
            if (!this.filter) return;
            gotoMentionPanel(this.filter, "engagement desc");
        },

        createDashboard() {
            if (!this.filter) return;
            createSimpleDashboardForFilter(
                this.filter,
                `Exploring press mentions for ${this.brandName} - ${moment().format("YYYY-MM-DD")}`,
                "Press mentions"
            );
        }
    }
}
</script>


<style scoped lang="sass">

</style>