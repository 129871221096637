import { render, staticRenderFns } from "./TrendingWidgetSettings.vue?vue&type=template&id=7949af44&scoped=true"
import script from "./TrendingWidgetSettings.vue?vue&type=script&lang=js"
export * from "./TrendingWidgetSettings.vue?vue&type=script&lang=js"
import style0 from "./TrendingWidgetSettings.vue?vue&type=style&index=0&id=7949af44&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7949af44",
  null
  
)

export default component.exports