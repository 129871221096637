Beef.module("Client").addInitializer(function(startupOptions) {

    this.ClientEditView = Beef.SettingsDialog.View.extend({
        template: require("@/settings/profile/ClientEdit.handlebars"),

        attributes: { class: "dialog client-edit-view" },

        editAttributes: ['clientName', 'addressLine1', 'addressLine2', 'addressCity', 'addressProvince', 'addressPostalCode',
            'vatNumber', 'contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone'],

        initialize: function(options) {
            this.billingModel = options.billingModel;
            Beef.SettingsDialog.View.prototype.initialize.call(this);
        },

        ok: function() {
            if (this.model.isValid()) {
                Beef.SettingsDialog.View.prototype.ok.call(this);
                this.billingModel.set(this.model.attributes);
            }
        }
    });

    this.AccountClient = Backbone.Model.extend({
        initialize: function(options) {
            this.accountCode = options.accountCode;
            this.url = Beef.Sync.toMashUrl('accounts/' + this.accountCode + "/billing");
        },

        validation: {
            clientName: { required: true },
            addressLine1: { required: true },
            addressLine2: { required: true },
            addressCity: { required: true },
            addressProvince: { required: true },
            addressPostalCode: { required: true },
            vatNumber: { required: true },
            contactFirstName: { required: true },
            contactLastName: { required: true },
            contactEmail: { required: true, pattern: "email" },
            contactPhone: { required: true }
        }
    });

});