/**
 * Maps currency codes to their respective currency symbols.
 */
import VuexStore from "@/store/vuex/VuexStore";

/**
 * If you change this list, update the list on Beef server side.
 */
export const CURRENCIES = {
    "ALL": "Albanian Lek",
    "DZD": "Algerian Dinar",
    "ARS": "Argentine Peso",
    "AWG": "Aruba Florin",
    "AUD": "Australian Dollar",
    "BSD": "Bahamian Dollar",
    "BHD": "Bahraini Dinar",
    "BDT": "Bangladesh Taka",
    "BBD": "Barbados Dollar",
    "BYR": "Belarus Ruble",
    "BZD": "Belize Dollar",
    "BMD": "Bermuda Dollar",
    "BTN": "Bhutan Ngultrum",
    "BOB": "Bolivian Boliviano",
    "BWP": "Botswana Pula",
    "BRL": "Brazilian Real",
    "GBP": "British Pound",
    "BND": "Brunei Dollar",
    "BGN": "Bulgarian Lev",
    "BIF": "Burundi Franc",
    "KHR": "Cambodia Riel",
    "CAD": "Canadian Dollar",
    "CVE": "Cape Verde Escudo",
    "XOF": "CFA Franc (BCEAO)",
    "XAF": "CFA Franc (BEAC)",
    "CLP": "Chilean Peso",
    "CNY": "Chinese Yuan",
    "COP": "Colombian Peso",
    "KMF": "Comoros Franc",
    "CRC": "Costa Rica Colon",
    "HRK": "Croatian Kuna",
    "CUP": "Cuban Peso",
    "CZK": "Czech Koruna",
    "DKK": "Danish Krone",
    "DJF": "Dijibouti Franc",
    "DOP": "Dominican Peso",
    "XCD": "East Caribbean Dollar",
    "EGP": "Egyptian Pound",
    "SVC": "El Salvador Colon",
    "ETB": "Ethiopian Birr",
    "EUR": "Euro",
    "FKP": "Falkland Islands Pound",
    "FJD": "Fiji Dollar",
    "GMD": "Gambian Dalasi",
    "GIP": "Gibraltar Pound",
    "XAU": "Gold Ounces",
    "GTQ": "Guatemala Quetzal",
    "GNF": "Guinea Franc",
    "GYD": "Guyana Dollar",
    "HTG": "Haiti Gourde",
    "HNL": "Honduras Lempira",
    "HKD": "Hong Kong Dollar",
    "HUF": "Hungarian Forint",
    "ISK": "Iceland Krona",
    "INR": "Indian Rupee",
    "IDR": "Indonesian Rupiah",
    "IRR": "Iran Rial",
    "IQD": "Iraqi Dinar",
    "ILS": "Israeli Shekel",
    "JMD": "Jamaican Dollar",
    "JPY": "Japanese Yen",
    "JOD": "Jordanian Dinar",
    "KZT": "Kazakhstan Tenge",
    "KES": "Kenyan Shilling",
    "KRW": "South Korean Won",
    "KWD": "Kuwaiti Dinar",
    "LAK": "Lao Kip",
    "LVL": "Latvian Lat",
    "LBP": "Lebanese Pound",
    "LSL": "Lesotho Loti",
    "LRD": "Liberian Dollar",
    "LYD": "Libyan Dinar",
    "LTL": "Lithuanian Lita",
    "MOP": "Macau Pataca",
    "MKD": "Macedonian Denar",
    "MWK": "Malawi Kwacha",
    "MYR": "Malaysian Ringgit",
    "MVR": "Maldives Rufiyaa",
    "MRO": "Mauritania Ougulya",
    "MUR": "Mauritius Rupee",
    "MXN": "Mexican Peso",
    "MDL": "Moldovan Leu",
    "MNT": "Mongolian Tugrik",
    "MAD": "Moroccan Dirham",
    "MMK": "Myanmar Kyat",
    "NAD": "Namibian Dollar",
    "NPR": "Nepalese Rupee",
    "ANG": "Neth Antilles Guilder",
    "NZD": "New Zealand Dollar",
    "NIO": "Nicaragua Cordoba",
    "NGN": "Nigerian Naira",
    "KPW": "North Korean Won",
    "NOK": "Norwegian Krone",
    "OMR": "Omani Rial",
    "XPF": "Pacific Franc",
    "PKR": "Pakistani Rupee",
    "PAB": "Panama Balboa",
    "PGK": "Papua New Guinea Kina",
    "PYG": "Paraguayan Guarani",
    "PEN": "Peruvian Nuevo Sol",
    "PHP": "Philippine Peso",
    "PLN": "Polish Zloty",
    "QAR": "Qatar Rial",
    "RON": "Romanian New Leu",
    "RUB": "Russian Rouble",
    "RWF": "Rwanda Franc",
    "WST": "Samoa Tala",
    "STD": "Sao Tome Dobra",
    "SAR": "Saudi Arabian Riyal",
    "SCR": "Seychelles Rupee",
    "SLL": "Sierra Leone Leone",
    "SGD": "Singapore Dollar",
    "SBD": "Solomon Islands Dollar",
    "SOS": "Somali Shilling",
    "ZAR": "South African Rand",
    "LKR": "Sri Lanka Rupee",
    "SHP": "St Helena Pound",
    "SDG": "Sudanese Pound",
    "SZL": "Swaziland Lilageni",
    "SEK": "Swedish Krona",
    "CHF": "Swiss Franc",
    "SYP": "Syrian Pound",
    "TWD": "Taiwan Dollar",
    "TZS": "Tanzanian Shilling",
    "THB": "Thai Baht",
    "TOP": "Tonga Pa'ang",
    "TTD": "Trinidad & Tobago Dollar",
    "TND": "Tunisian Dinar",
    "TRY": "Turkish Lira",
    "AED": "UAE Dirham",
    "UGX": "Ugandan Shilling",
    "UAH": "Ukraine Hryvnia",
    "USD": "United States Dollar",
    "UYU": "Uruguayan New Peso",
    "VUV": "Vanuatu Vatu",
    "VEF": "Venezuelan Bolivar Fuerte",
    "VND": "Vietnam [removed]",
    "YER": "Yemen Riyal",
    "ZMK": "Zambian Kwacha"
};

export const symbolMap = {
    'ZAR': 'R',
    'USD': '$',
    'GBP': '£',
    'EUR': '€',
    'KES': 'KSh',
    'BRL': 'R$',
    'INR': '₹',
    'CNY': '¥',
    'JPY': '¥',
    'SGD': '$',
    'MYR': 'RM',
    'KRW': '₩',
    'ARS': '$',
    'CLP': '$',
    'MXN': '$',
    'VEF': 'Bs'
};


const currencyCache = {};

/**
 * The currency that our AVE is by default calculated in.
 */
export const defaultCurrency = 'ZAR';

let getRateFromZarQueue;

function processGetRateFromZarQueue() {
    if (!getRateFromZarQueue) return;
    for (var i = 0; i < getRateFromZarQueue.length; i++) {
        var entry = getRateFromZarQueue[i];
        try {
            entry.callback(currencyCache[entry.to] || 0)
        } catch (e) {
            console.error(e);
        }
    }
    getRateFromZarQueue = null;
}

/**
 * Returns the rate for converting Rands into the given currency. This rate
 * is given as the argument to the callback. Multiple calls to this method are queued and a single AJAX call is
 * made after event processing is complete. If the data has been cached callback is invoked immediately.
 */
export function getRateFromZar(to, callback) {
    if (to === 'ZAR') return callback(1.0);
    if (currencyCache[to]) return callback(currencyCache[to]);

    var entry = {to: to, callback: callback};
    if (!getRateFromZarQueue) {
        getRateFromZarQueue = [entry];
        setTimeout(function () {
            $.ajax({
                url: '/currency/' + to,
                dataType: "json",
                success: function (data) {
                    currencyCache[to] = data.exchangeRate;
                    processGetRateFromZarQueue();
                }
            })
                .error(console.error);
        });
    } else {
        getRateFromZarQueue.push(entry);
    }
}

/**
 * Converts the given value to the given currency (from ZAR). Passes it to the callback.
 * If we are unable to determine an exchange rate, this supplies the value null. The rate is also
 * passed in as the second argument to the function.
 */
export function convertTo(value, to, callback) {
    getRateFromZar(to, function(rate) {
        if (rate) callback(value * rate, rate);
        else callback(null, rate);
    });
}

/**
 * This returns the currency that the account is using. If the account data has not yet loaded,
 * it will ensure that 'ZAR' is returned.
 *
 * @returns {string}
 */
export function getAccountCurrency() {
    return VuexStore.state.account.currency || 'ZAR';
}