<template>
    <div>
        <slot :loading="loading" :text="citationText" :citations="citations">
            <div v-if="loading">
                <inline-loading-message>
                    Finding citations...
                </inline-loading-message>
            </div>
            <div v-else-if="text">
                <div>
                    {{text}}
                </div>
            </div>
        </slot>
    </div>

</template>

<script>
import {giveCitationsForSummary, Summary} from "@/app/utils/turducken";
import InlineLoadingMessage from "@/components/InlineLoadingMessage.vue";
import {features} from "@/app/Features";

export default {
    components: {InlineLoadingMessage},
    props: {
        summary: Summary,
        text: String
    },

    data() {
        return {
            loading: false,
            citationText: null,
            citations: null
        }
    },

    watch: {
        async summary() {
            await this.loadCitations();
        },
        async text() {
            await this.loadCitations();
        }
    },

    mounted() {
        this.loadCitations();
    },

    methods: {
        async loadCitations() {
            if (!this.text) return;
            this.citationText = this.text;
            this.citations = null;

            if (!features.showCitations()) {
                // pretend we're done.
                this.citations = [];
                this.$emit("text", this.text);
                this.$emit("citations", []);
                return;
            }

            // todo handle loading
            // todo handle load interruptions

            try {
                this.$emit("loading", true);
                this.loading = true;
                const result = await giveCitationsForSummary(this.summary.id);
                this.citationText = result.text;
                this.citations = result.references;

                this.$emit("text", this.citationText);
                this.$emit("citations", this.citations);
            } finally {
                this.loading = false;
                this.$emit("loading", false);
            }
        }
    }


}
</script>