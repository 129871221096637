import Vue from 'vue';

const authors = Vue.observable({
    title: "Authors",
    description: "Learn about the people talking about you online.",
    expanded:
`Find out about the authors talking about you, where they come from, and how they describe themselves.

We only provide this information where the authors themselves have publicly done so.
`,
    image: require("../assets/authors.png"),
    metrics: [
        {
            id: "authors/columns",
            title: "Top authors",
            description: "See which authors are creating the most mentions",
            image: require("../assets/author/volume.png"),
            keywords: ["contributor"],
            hero: true,
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "fullAuthor",
                yAxis: "mentionPercent",
                width: 6,
                height: 4,
                filter: "media isnt enterprise"
            },
            options: [
                {
                    key: "conversations",
                    title: "Show authors by conversations",
                    tooltip: "Instead of counting mentions, count unique conversations that an author is a part of",
                    action: () => {
                        return {
                            id: "authors/conversations",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "fullAuthor",
                                yAxis: "conversationIdCount",
                                width: 6,
                                height: 4,
                                filter: "media isnt enterprise"
                            }
                        }
                    }
                },
                {
                    key: "unique",
                    title: "Show the number of authors",
                    tooltip: "See how many authors are posting online per day",
                    action: () => {
                        return {
                            id: "authors/unique",
                            widgetDesc: {
                                type: "FantasticChart",
                                xAxis: "published",
                                yAxis: "authorIdCount",
                                width: 6,
                                height: 4,
                                filter: "media isnt enterprise",
                                notification: "Added a metric showing the number of <strong>unique authors</strong>"
                            }
                        }
                    }
                }
            ]
        },
        {
            id: "author/engagement",
            title: "Engaging authors",
            description: "See your authors ranked by their engagement",
            image: require("../assets/author/engagement.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "fullAuthor",
                yAxis: "totalEngagement",
                width: 4,
                height: 4,
                geometry: "rows",
                filter: "media isnt enterprise"
            },
        },
        {
            id: "category/columns",
            title: "Category",
            description: "Distinguish between consumer and other conversation",
            image: require("../assets/author/category.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "category",
                yAxis: "mentionPercent",
                width: 6,
                height: 4
            }
        },
        {
            id: "language/rows",
            title: "Languages",
            description: "Review the breakdown of languages in your conversations",
            image: require("../assets/author/language.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "language",
                yAxis: "mentionPercent",
                geometry: "rows"
            }
        },
        {
            id: "gender/pie",
            title: "Gender",
            description: "Review the breakdown of genders in your conversations",
            image: require("../assets/author/gender.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "gender",
                yAxis: "mentionPercent",
                geometry: "pie",
                width: 4,
                height: 4,
            }
        },
        {
            duplicate: "countries/columns",
            image: require("../assets/author/country.png"),
        },
        {
            duplicate: "themes/authorbiocloud",
        }
    ]
    }
);

export default authors;