import {addBusyPhrases, editOldPhrases} from "@/app/Permissions";
import _ from 'underscore';
import moment from "moment";
import {notifyUserOfError, notifyWithText} from "@/app/framework/notifications/Notifications";
import {isPhraseANo, normalisePhrase, parsePhraseString, phraseNeedsQuotes} from "@/app/utils/Phrases";
import {escapeHtml} from "@/app/utils/StringUtils";
import {formatNumber} from "@/app/utils/Format";
import {getBrand, getRootBrandFor} from "@/app/utils/Brands";


Beef.module("EditPhrase").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
    });

    var MAX_EDIT_MINS = 60;  // include terms for phrases older than this can only be edited by ops users

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/setup/brands/EditPhrase.handlebars"),

        attributes: { class: "edit-phrase dialog" },

        regions: {
            phraseFilterRegion: '.phrase-filter-region',
            pageGoodness: ".page-goodness",
            pageTune: ".page-tune"
        },

        bindings: {
            _include: { converter: Beef.PhraseTermPicker.createConverter("Enter words or phrases to include here"), elAttribute: "data-value" },
            _exclude: { converter: Beef.PhraseTermPicker.createConverter("Enter words or phrases to exclude here"), elAttribute: "data-value" },
            socialNetworks: { converter: Beef.SocialNetworkPicker.converter, elAttribute: "data-value" },
            feeds: { converter: Beef.FeedPicker.converter, elAttribute: "data-value" }
        },

        createBindings: function() {
            // don't do bindings for our whole element or the subform for root brand filtering gets picked up
            // as well and the checkboxes don't work as they have bindings to both models
            return Beef.ModelBinder.createDefaultBindings(this.$el.find('.main-form'));
        },

        events:Object.assign({
            "click .dialog-button-bar .next": "next",
            "click .dialog-button-bar .prev": "prev",
            "click .dialog-button-bar .move": "move",
            "click .unlock": "unlock"
        }, Beef.SettingsDialog.View.prototype.events),

        modelEvents: {
            "change:_include": "rebuildQuery",
            "change:_exclude": "rebuildQuery",
        },

        initialize: function() {
            this.accountCode = this.options.accountCode;
            this.brand = this.options.brand;
            for (this.rootBrand = this.brand; this.rootBrand._parent; this.rootBrand = this.rootBrand._parent);

            this.page = "edit";

            var inc = [], ex = [], qinc = [], qex = [];
            var q = this.model.get('query');
            if (q) {
                var a = parsePhraseString(q);
                for (var i = 0; i < a.length; i++) {
                    var t = a[i];
                    if (t) {
                        var needsQuotes = phraseNeedsQuotes(t);
                        if (t.charAt(0) == '-') {
                            t = t.substring(1);
                            ex.push(t);
                            qex.push(needsQuotes ? '"' + t + '"' : t);
                        } else {
                            inc.push(t);
                            qinc.push(needsQuotes ? '"' + t + '"' : t);
                        }
                    }
                }
            }
            this.model.set({_include: qinc.join(" "), _exclude: qex.join(" ")});
            this.lastGoodInc = inc;
            this.lastGoodEx = ex;
            this.model.set("_goodQuery", inc.length > 0);   // assuming existing queries are good

            var readOnly = this.model.id && moment().diff(moment(this.model.get('created')), 'minutes') >= MAX_EDIT_MINS;
            this.model.set('_maxEditAge', MAX_EDIT_MINS + " minutes");
            this.model.set('_readOnly', readOnly);
            this.model.set('_canEditIncTerms', !readOnly || editOldPhrases());

            this.rebuildQuery();

            Beef.SettingsDialog.View.prototype.initialize.call(this, arguments);
        },

        onFirstRender: function() {
            Beef.PhraseTermPicker.attach(this, "._include", "_include");
            Beef.PhraseTermPicker.attach(this, "._exclude", "_exclude");
            Beef.SocialNetworkPicker.attach(this, ".socialNetworks", "socialNetworks");
            Beef.FeedPicker.attach(this, ".feeds", "feeds");

            var gm = new Backbone.Model();
            this.pageGoodness.show(new Beef.PhraseGoodness.View({
                model: gm,
                accountCode: this.accountCode,
                rootBrand: this.rootBrand
            }));
            gm.on("change", function(){
                var level = gm.get('level');
                var goodQuery = level && level != "high";
                if (goodQuery) {
                    // remember current "good" term lists so check does not have to be re-done every time
                    this.lastGoodInc = this.model.get("_includeTerms");
                    this.lastGoodEx = this.model.get("_excludeTerms");
                }
                this.model.set("_goodQuery", goodQuery);
                this.model.set("_goodnessCheckRequired", false);
                var mentions = gm.get('mentions');
                this.model.set("_noMentions", mentions != null && mentions.length == 0);
                this.model.set("_level", level);
                this.model.set("_mentionsTotal", gm.get("mentionsTotal"));

                if (gm.attributes.query) {
                    if (this.isLevelNo()) {
                        notifyUserOfError(escapeHtml`The phrase <strong>${gm.attributes.query}</strong> cannot be added to this account. 
It is estimated to bring in <strong>${formatNumber(gm.get('mentionsTotal'))}</strong> mentions in a month, exceeding this brand's volume limit. 
Please add restrictions to this brand and/or phrase.`, true);
                    } else if (level === "NO") {
                        notifyUserOfError(escapeHtml`Carefully consider the volumes and account setup for the phrase <strong>${gm.attributes.query}</strong> It is expected
to exceed this brand's volume limit.`, true);
                    }
                }

            }.bind(this));

            this.pageTune.show(new Beef.PhraseTune.View({
                model: new Backbone.Model(),
                accountCode: this.accountCode,
                rootBrand: this.rootBrand
            }));
        },

        onRender: function() {
            Beef.SettingsDialog.View.prototype.onRender.apply(this, arguments);
            Beef.FeedPicker.attach(this, ".feeds", "feeds");
            var v = Beef.RootBrandFilter.createView(this.model.get('mentionFilter'));
            var that = this;
            v.model.on("change", function(){ that.model.set('mentionFilter', v.getFilter()); });
            this.phraseFilterRegion.show(v);
        },

        rebuildQuery: function() {
            var _include = this.model.get("_include");
            var inc = _include ? parsePhraseString(_include) : [];

            var _exclude = this.model.get("_exclude");
            var ex = _exclude ? parsePhraseString(_exclude) : [];

            // the include and exclude lists must contain at least all of the terms present at the last goodness
            // check or a new check is required
            var goodnessCheckRequired = this.lastGoodInc.length == 0 || !containsAll(inc, this.lastGoodInc)
                || !containsAll(ex, this.lastGoodEx) || this.model.get("deleted");

            var terms = inc.slice(0);
            for (var i = 0; i < ex.length; i++) terms.push("-" + ex[i]);
            var q = normalisePhrase(terms);

            this.model.set({
                query: q,
                _queryValid: inc.length > 0,
                _goodnessCheckRequired: goodnessCheckRequired,
                _includeTerms: inc,
                _excludeTerms: ex
            });
        },

        isValid: function() {
            return this.model.get("_queryValid");
        },

        isGoodnessCheckRequired: function() {
            return this.model.get("_goodnessCheckRequired");
        },

        isGoodQuery: function() {
            return this.model.get("_goodQuery");
        },

        isLevelNo: function() {
            if (!this.model.get("_level")) return true;

            const brand = getRootBrandFor(this.model.get('brandId'));
            return isPhraseANo(brand, this.model.attributes, {
                level: this.model.get("_level"),
                mentionsTotal: this.model.get("_mentionsTotal"),
            });
        },

        isNoMentions: function() {
            return this.model.get("_noMentions");
        },

        isOkAllowed: function() {
            return this.isOKForHistoricalSearch() || ((this.page != "goodness" || this.isNoMentions())
                && this.isValid()
                && !this.isLevelNo()
                && !this.isGoodnessCheckRequired() && (this.isGoodQuery() || addBusyPhrases()));
        },

        /**
         * Checks whether the phrase is OK for the historical search setup.
         */
        isOKForHistoricalSearch: function() {
            return (
                   this.model.get("_fromHistoricalSearch")
                && (this.page === "goodness")
                && !this.isGoodnessCheckRequired()
                && this.isValid()
            );
        },

        isNextAllowed: function() {
            if (this.page === "edit") return this.isValid();
            if (this.page === "goodness") return !this.isLevelNo() && (this.isGoodQuery() || addBusyPhrases()) && !this.isNoMentions();
            if (this.page === "tune") return false;
            return false;
        },

        isPrevAllowed: function() {
            if (this.page == "edit") return false;
            return this.page == "goodness" || this.page == "tune";
        },

        updateButtons: function() {
            this.$el.find('.ok').toggleClass('disabled', !this.isOkAllowed());
            this.$el.find('.next').toggleClass('disabled', !this.isNextAllowed());
            this.$el.find('.prev').toggleClass('disabled', !this.isPrevAllowed());

            this.$el.find('.page-edit').toggle(this.page == "edit");
            this.$el.find('.page-goodness').toggle(this.page == "goodness");
            this.$el.find('.page-tune').toggle(this.page == "tune");
        },

        ok: function() {
            if (!this.isOkAllowed()) return;
            if (this.page == "tune") this.addSelectedExclusions();
            this.model.set('deleted', false);
            Beef.SettingsDialog.View.prototype.ok.call(this, arguments);
        },

        onSaveSuccess() {
            notifyWithText("The phrase has been updated", null, "<i class='symbol-setup'></i>");
        },

        delete: function() {
            if (window.confirm("Are you sure you want to delete this phrase?")) {
                this.originalModel.destroy();
                this.close(false);
            }
        },

        prev: function() {
            if (!this.isPrevAllowed()) return;
            if (this.page == "goodness") {
                this.page = "edit";
                $(".phrase-filter-region").show();
            } else if (this.page == "tune") {
                this.page = "goodness";
            }
            this.updateButtons();
        },

        next: function() {
            if (!this.isNextAllowed()) return;
            if (this.page == "edit") {
                if (this.isValid()) {
                    this.page = "goodness";
                    this.checkPhrase();
                    $(".phrase-filter-region").hide();
                }
            } else if (this.page == "goodness") {
                this.page = "tune";
                var all = this.pageGoodness.currentView.getExclusionSuggestions();
                var words = [];
                for (var i = 0; i < all.length; i++) {
                    var w = all[i];
                    if (this.lastGoodEx.indexOf(w) < 0) words.push(w);
                }

                this.pageTune.currentView.model.set({
                    words: words,
                    haveIrrelevantMentions: this.pageGoodness.currentView.haveIrrelevantMentions
                });
            }
            this.updateButtons();
        },

        checkPhrase: function() {
            this.pageGoodness.currentView.setQuery(this.model.get('query'));
        },

        addSelectedExclusions: function() {
            var a = this.pageTune.currentView.getSelectedTerms();
            if (a) {
                var _exclude = this.model.get("_exclude");
                // put in all the 1 word terms first so we can avoid adding 2 word terms that include them
                var map = {};
                for (var i = 0; i < a.length; i++) {
                    var term = a[i];
                    if (term.indexOf(' ') < 0) {
                        _exclude += " " + term;
                        map[term] = true;
                        a[i] = null;
                    }
                }
                for (i = 0; i < a.length; i++) {
                    term = a[i];
                    if (term) {
                        var words = term.split(" ");
                        for (var j = 0; j < words.length; j++) {
                            if (map[words[i]]) {
                                term = null;
                                break;
                            }
                        }
                        if (term) _exclude += ' "' + term + '"';
                    }
                }
                this.model.set("_exclude", _exclude);
            }
        },

        move: function() {
            var view = new Beef.MovePhrase.View({model: this.originalModel, accountCode: this.accountCode,
                cache: this.cache, rootBrands: this.options.rootBrands});
            this.close();

            var popup = new Beef.Popup.View({ closeOnHide:true, positions:["center"], alwaysMove:true });
            popup.setTarget(this.parentPopup.target);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        unlock: function(ev) {
            ev.preventDefault();
            this.$('.edit-inc-terms').show();
            this.$('.read-only-inc-terms').hide();
            setTimeout(function(){
                this.$('._include input').focus();
            }.bind(this));
        }
    });

    /**
     * Does list contain all the strings in other? Not case sensitive.
     */
    var containsAll = function(list, other) {
        list = list.slice(0);
        for (var i = 0; i < list.length; i++) list[i] = list[i].toLowerCase();
        for (i = 0; i < other.length; i++) {
            if (list.indexOf(other[i].toLowerCase()) < 0) return false;
        }
        return true;
    }

});