import {getTitle, setTitle} from "@/app/Beef";
import {isFunction} from "@/app/utils/Util";

/**
 * Displays a stack of views with the one on top visible and available in currentView (like a layout).
 */
Beef.module("ViewStack", function() {

    this.create = function() {
        return new View({collection: new Backbone.Collection()});
    };

    var View = Backbone.Marionette.CollectionView.extend({

        attributes: { class: 'view-stack' },

        itemView: Backbone.View,

        /**
         * Display a view on top of the stack. If the view has an onStackReveal method,
         * this will be called when it is the current view on the stack. OnStackHide occurs
         * when something is moved on top of that item. The title is an optional title that the new window
         * should be given.
         */
        show: function(view, title) {
            this.collection.add(new Backbone.Model({
                view: view,
                title: title,
                previousTitle: getTitle()
            }));

            if (this.currentView && this.currentView.onStackHide) {
                this.currentView.onStackHide();
            }
            this.currentView = view;
            if (title) setTitle(title);

            view.on("close", function(){
                for (var i = 0; i < this.collection.size(); i++) {
                    var m = this.collection.at(i);
                    if (m.get('view') == view) {
                        this.collection.remove(m);
                        setTitle(m.get('previousTitle'));
                        break;
                    }
                }

                var n = this.collection.size() - 1;
                this.currentView = n < 0 ? null : this.collection.at(n).get('view');
                if (n >= 0 && this.collection.at(n).get('title')) setTitle(this.collection.at(n).get('title'));

                if (this.currentView && this.currentView.onStackReveal) {
                    this.currentView.onStackReveal();
                }
            }.bind(this));
        },

        /**
         * Close all views.
         */
        reset: function() {
            this.collection.reset();
        },

        buildItemView: function(item){
            return item.get('view');
        },

        /**
         * What is the height of our tallest child view?
         */
        height: function() {
            var ans = 50;
            for (var i = 0; i < this.collection.length; i++) {
                var view = this.collection.at(i).get('view');
                var h;
                if (isFunction(view.height)) h = view.height();
                else h = view.$el ? view.$el.height() : 0;
                if (h > ans) ans = h;
            }
            return ans;
        }
    });
});