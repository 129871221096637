/**
 * Dialog to show how V4 mention extract has been tokenized.
 */
import {grouseGet} from "@/data/Grouse";
import {currentAccountCode} from "@/app/utils/Account";

Beef.module("MentionWords").addInitializer(function(startupOptions) {

    this.show = function(mentionId) {
        var model = new Backbone.Model({id: mentionId});
        var view = new View({model: model});

        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
        popup.setTarget($('body'));
        popup.show(view);

        var endpoint = "/v4/accounts/" + currentAccountCode() + "/mentions/" + mentionId;
        var data = { select: "extractIndex,authorBioIndex" };
        grouseGet(endpoint, data)
            .then(function(data) { model.set(data)})
            .catch(e => console.error(e));
    };

    var View = Beef.BoundItemView.extend({

        template: require("@/mentions/MentionWords.handlebars"),

        className: "dialog mention-words",

        events: {
            "click .cancel":    "close",
            "click .ok":        "close"
        },

        modelEvents: {
            "change": "onModelChange"
        },

        onModelChange: function() {
            this.$('.spinner').hide();

            var html = renderIndex(this.model.get('extractIndex'));
            if (html) {
                var $words = this.$('.extract-words');
                $words.find('.words').html(html);
                $words.show();
            }

            html = renderIndex(this.model.get('authorBioIndex'));
            if (html) {
                $words = this.$('.author-bio-words');
                $words.find('.words').html(html);
                $words.show();
            }
        }
    });

    var renderIndex = function(index) {
        if (!index) return null;
        var tags = [];
        var a = index.split(' ');
        // order the terms by position, repeating terms that appear more than once
        for (var i = 0; i < a.length; i++) {
            var term = a[i];    // 'i':6,24
            var p = term.indexOf(':');
            var html = "<span class='tag'>" + term.substring(1, p - 1) + "</span>";
            var list = term.substring(p + 1).split(',');
            for (var j = 0; j < list.length; j++) tags.push({html: html, pos: parseInt(list[j])});
        }
        return tags
            .sort((lhs, rhs) => lhs.pos - rhs.pos)
            .map(d => d.html)
            .join(' ');
    }
});