/**
 * Select an integer representation for the number of replies
 */
Beef.module("ReshareCountPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IntegerPicker.View.extend({
        attribute: 'Reshare Count'
    });

    this.converter = Beef.IntegerPicker.createConverter("Number of Reshares", "Reshare count");

    this.attach = function(view, selector, attribute, options) {
        Beef.IntegerPicker.attachPicker(view, selector, attribute, this.View, options);
    };

});
