<template>
    <div class="edit-public-profile" v-if="newProfileData.publicProfile">
        <div class="edit-public-profile__header">
            <online-profile class="edit-public-profile__profile" :profile="newProfileData.publicProfile"></online-profile>
            <div class="edit-public-profile__profile-type">
                <i :class="getProfileIcon(newProfileData.publicProfile.type)"
                   :style="{color: getProfileColor(newProfileData.publicProfile.type)}"></i>{{
                    prettyProfileType(newProfileData.publicProfile.type)
                }}
            </div>
        </div>

        <div class="edit-public-profile__edit-container" @click.stop>
            <div class="edit-public-profile__edit-left">
                <h4>Handle</h4>
                <p style="word-wrap: break-word">
                    {{ newProfileData.publicProfile.handle }}
                </p>

                <h4>Category</h4>
                <popup-menu fixed
                            :arrow-pointer="{show: true, left: true} ">
                    <template #activator>
                        <p class="edit-public-profile__editable-text" :class="{'disabled': saving}">
                            {{ profileCategory ? profileCategory : 'Select a category' }}<i class="symbol-edit"
                                                                                            style="visibility: hidden"></i>
                            <training-marker v-if="!profileCategory" class="marker"></training-marker>
                        </p>
                    </template>
                    <div
                        class="edit-public-profile__category-list">
                        Set category to
                        <ul>
                            <li v-for="category in profileCategories" :key="category.value">
                                <label class="checkbox" @click.prevent="updateCategory(category)">
                                    <input type="checkbox"
                                           :checked="newProfileData.publicProfile.media === category.value">
                                    {{ category.description }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </popup-menu>

                <h4>Description</h4>
                <textarea style="grid-row: 4; grid-column: 1/3; width: 90%;  height: 80px; resize: none"
                          :disabled="saving"
                          class="dark-scrollbars dark-scrollbars--visible" v-model="profileDescription"/>


                <label class="checkbox" style="grid-column: 1/3"
                       :class="{'disabled': saving}"
                       title="Tick to skip sorting and mark mentions as relevant">
                    <input type="checkbox" v-model="profileRelevance"> Always keep mentions from this profile
                    even
                    if they appear irrelevant</label>

            </div>
            <div class="edit-public-profile__edit-right">
                <h4>Tags</h4>
                <div v-if="profileTags.length"
                     class="edit-public-profile__tags-container dark-scrollbars dark-scrollbars--visible">
                    <slotted-tag style="margin-right: 10px; margin-bottom: 5px"
                                 v-for="profileTag in profileTags"
                                 :disabled="saving"
                                 @close="removeTag(profileTag)"
                                 class="edit-public-profile__added-tag"
                                 :key="profileTag.id">
                        {{ profileTag.name }}
                    </slotted-tag>
                </div>

                <p v-if="!editTags" @click="toggleEditTags" class="edit-public-profile__editable-text"
                   :class="{'disabled': saving}">Add a tag<i
                    class="symbol-edit"></i></p>
                <popup-menu v-else fixed :value="!!addTagName && !!filteredTagSuggestions.length && !saving"
                            ignore-activator-click :arrow-pointer="{show: true, left: true} ">
                    <template #activator>
                        <inline-text-input
                            ref="profileTagInput"
                            placeholder="Enter tag name..."
                            ignore-blur
                            signal-for-all
                            :tooltip="newTagInvalid"
                            :disable-done="!!newTagInvalid"
                            v-model="addTagName"
                            :disabled="saving"
                            @cancel="resetTag"
                            @ok="addNewTag">
                        </inline-text-input>
                    </template>
                    <div v-if="!!addTagName && !!filteredTagSuggestions.length"
                         class="edit-public-profile__tag-suggestions dark-scrollbars dark-scrollbars--visible">
                        <ul>
                            <li v-for="suggestedTag in filteredTagSuggestions" :key="suggestedTag.id">
                                <a @click="addSuggestedTag(suggestedTag)">{{ suggestedTag.name }}</a>
                            </li>
                        </ul>
                    </div>
                </popup-menu>

                <h4>Brands with phrases matching profile handle</h4>
                <div v-if="!profileBrands.length">
                    Click <a :class="saving ? 'disabled' : ''" style="cursor: pointer" @click="openBrandPicker">here</a> to select brands to phrase match this profile to
                </div>
                <div v-else>
                    <div class="edit-public-profile__brands-list dark-scrollbars dark-scrollbars--visible">
                        <ul>
                            <li v-for="brand in profileBrands" :key="brand.name">
                                {{ brand.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getProfileColor, getProfileIcon, prettyProfileType, profileTypes, profileCategories} from "@/setup/profiles/ProfileUtils";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import PopupMenu from "@/components/PopupMenu";
import SlottedTag from "@/components/tags/SlottedTag";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapMutations, mapState} from "vuex";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import TrainingMarker from "@/components/inputs/TrainingMarker";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";


export default {
    name: "EditPublicProfile",
    store: VuexStore,
    components: {TrainingMarker, SlottedTag, PopupMenu, InlineTextInput, OnlineProfile},

    data: function () {
        return {
            saving: false,
            editTags: false,
            addTagName: "",
            profileTypes: profileTypes,
            profileCategories: profileCategories,
            profileBrands: [],
            profileTagIds: []
        }
    },

    deactivated() {
        this.profileBrands = [];
        this.profileTagIds = [];
        this.setNewPublicProfileTagIds(null);
        this.setNewPublicProfileBrands(null);
    },

    computed: {
        ...mapState(['tags', 'account']),
        ...mapState('profiles', ['newProfileData']),

        profileDescription: {
            get() {
                return this.newProfileData.publicProfile.description;
            },
            set(value) {
                this.setNewPublicProfileDescription(value);
            }
        },

        profileRelevance: {
            get() {
                return !!this.newProfileData.publicProfile.relevant;
            },
            set(value) {
                this.setNewPublicProfileRelevant(value);
            }
        },

        filteredTagSuggestions() {
            let text = this.addTagName.toLowerCase();

            return this.tags?.filter(tagSuggestion => tagSuggestion.name?.toLowerCase().includes(text) && !this.profileTagIds?.includes(tagSuggestion.id) && tagSuggestion.namespace === 'tag');
        },

        getProfileHandle() {
            return this.newProfileData.publicProfile.type === profileTypes.instagram_user || this.newProfileData.publicProfile.type === profileTypes.twitter ? `@${this.newProfileData.publicProfile.handle}` : this.newProfileData.publicProfile.handle;
        },

        profileTags() {
            if (this.profileTagIds.length) {
                if (this.tags.length) return this.tags.filter(tag => !!this.profileTagIds.find(profileTagId => profileTagId === tag.id));
            }

            return [];
        },

        newTagInvalid() {
            if (this.addTagName.length > 50) return "The tag " + encloseInDisplayQuotes(this.addTagName) + " is too long";
            // We don't actually disallow the following, but it's almost certainly bad style
            if (/^[!"#$%&'()*+,\-./:;<=>?@ [\\\]^_`{|}~]+$/.test(this.addTagName)) return "The tag " + encloseInDisplayQuotes(this.addTagName) + " contains only punctuation";
            if (/^\s*$/.test(this.addTagName)) return "Tag name cannot be empty";

            return null
        },

        profileCategory() {
            let cat = this.profileCategories.find(category => this.newProfileData.publicProfile.media === category.value);

            if (cat) return cat.label;

            return '';
        }
    },

    methods: {
        ...mapActions(['createTag']),
        ...mapMutations('profiles', ['setNewPublicProfile', 'setNewPublicProfileCategory', 'setNewPublicProfileDescription', 'setNewPublicProfileRelevant', 'setNewPublicProfileTagIds', 'setNewPublicProfileBrands']),

        getProfileColor,
        getProfileIcon,
        prettyProfileType,

        removeTag(tag) {
            this.profileTagIds = this.profileTagIds.filter(profileTagId => profileTagId !== tag.id);
        },

        toggleEditTags() {
            this.editTags = true;

            this.$nextTick(() => this.$refs.profileTagInput?.focus());
        },

        addSuggestedTag(tag) {
            // don't add the tag if the profile already has it
            if (!this.profileTagIds.includes(tag.id)) {
                this.profileTagIds.push(tag.id);

                this.setNewPublicProfileTagIds(JSON.parse(JSON.stringify(this.profileTagIds)));
            }


            this.resetTag();
        },

        // create new tag and add it to profile
        async addNewTag() {
            let tag = {
                name: this.addTagName
            }

            // check if tag with same name exists
            let existingTag = this.tags.find(curTag => curTag.name === tag.name);

            if (existingTag) {
                // ensure that list does not contain tag already
                if (!this.profileTagIds.includes(existingTag.id)) {
                    this.profileTagIds.push(existingTag.id);
                    this.setNewPublicProfileTagIds(JSON.parse(JSON.stringify(this.profileTagIds)));
                }
            } else {
                try {
                    this.saving = true;
                    let newTag = await this.createTag(tag);

                    if (newTag) {
                        this.profileTagIds.push(newTag.id);
                        this.setNewPublicProfileTagIds(JSON.parse(JSON.stringify(this.profileTagIds)));
                    }
                } finally {
                    this.saving = false;
                }
            }

            this.resetTag();
        },


        openBrandPicker() {
            let brandPicker = showDialog(BrandPickerDialog, {onlyOne: false, mustHaveOne: true});

            brandPicker.$on('selected', selectedBrands => {
                this.profileBrands = [];

                selectedBrands.forEach(brand => {
                    this.profileBrands.push(brand);

                    this.setNewPublicProfileBrands(this.profileBrands);
                });
            });
        },

        resetTag() {
            this.addTagName = "";
            this.editTags = false;
        },

        updateCategory(category) {
            this.setNewPublicProfileCategory(category.value);
        },
    }
}
</script>

<style scoped lang="sass">

.edit-public-profile

    p
        margin: 0

    &__header
        display: flex
        margin-top: 10px
        align-items: center
        border: 1px solid #272727
        border-radius: 4px
        background: #333
        padding: 10px
        justify-content: space-between

    &__profile
        font-size: 14px
        max-width: 60%

        ::v-deep img
            width: 60px
            height: 60px
            margin: 0

    &__profile-type
        font-size: 20px
        padding: 20px 10px
        min-width: fit-content

    &__edit-container
        display: flex
        margin-top: 10px

    &__edit-left
        display: grid
        align-items: center
        grid-template-columns: 100px 180px
        grid-auto-rows: auto
        column-gap: 20px
        row-gap: 20px
        width: 45%
        padding: 10px
        border-right: 1px solid #888

    &__edit-right
        display: flex
        flex-direction: column
        row-gap: 15px
        width: 55%
        padding-left: 10px
        padding-top: 10px

    &__editable-text
        word-break: break-all
        align-self: flex-start
        margin-bottom: 5px !important
        cursor: pointer
        font-style: italic
        transition: color var(--transition-duration)
        color: var(--be-colour-muted-text-dark)

        &:hover
            color: var(--be-colour-text-dark__hover)

            i
                visibility: visible !important

    &__category-list
        margin: 3px 0
        padding: 5px 5px 5px 10px

        ul
            list-style: none
            margin: 10px 0 0 0

        li
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none

    &__tags-container
        border: 1px solid #888
        padding: 5px
        border-radius: 4px
        max-height: 80px
        overflow-y: auto

    &__tag-suggestions
        margin: 3px 0
        min-width: 125px
        max-height: 200px
        overflow-y: auto

        ul
            list-style: none
            margin: 0

        li > a
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none
                background-color: #222222

    &__added-tag
        animation: new-tag-transition 1s linear
        animation-iteration-count: 1

    &__brands-list
        border: 1px solid #888
        border-radius: 4px
        max-height: 150px
        overflow-y: auto

        .expandable-brand
            cursor: pointer

            &:hover
                color: var(--be-colour-text-dark__hover)

        ul
            list-style: none
            margin: 0

        li
            padding: 0 5px
            line-height: 25px

            &:nth-child(odd)
                background: #666

            &:nth-child(even)
                background: #3c3c3c

            &:last-of-type
                margin-bottom: 0

.disabled
    color: #666 !important
    pointer-events: none !important

@keyframes new-tag-transition
    0%
        background: #333

    50%
        background: #aee15d

    100%
        background: #333

</style>