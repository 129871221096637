<template>
    <add-profile-dialog class="hellopeter-add-profile-dialog"
                        :profile-type="profileTypes.helloPeter"
                        :enable-next-btn="enableNext"
                        next-btn-text="Save"
                        :show-prev-btn="false"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-next-btn="!saving"
                        @close="$emit('close')"
                        @next="next">
        <template #workflow>
            <div key="1" v-if="!saving">
                <h4>Configure your Hellopeter profile</h4>
                <text-input
                    class="hellopeter-add-profile-dialog__text-input"
                    type="text"
                    placeholder="Profile Name"
                    label="Hellopeter Profile Name"
                    :required="true"
                    :numeric="false"
                    @valid="setNameValid"
                    v-model="profileName"
                    :rules="[ nameValidRule ]"
                    title="Company name as displayed on Hellopeter.com e.g. Your Company"
                />

                <text-input
                    class="hellopeter-add-profile-dialog__text-input"
                    type="text"
                    placeholder="Profile ID"
                    label="Hellopeter Profile ID"
                    :required="true"
                    :numeric="false"
                    v-model="profileHandleId"
                    @valid="setprofileHandleIdValid"
                    :rules="[ profileHandleIdValidRule ]"
                    title="The company url on Hellopeter.com determines the profile id, e.g. https://www.hellopeter.com/your-company-profile-id"
                />

                <div style="height: 100px">
                    <text-input
                        type="text"
                        placeholder="API Key"
                        label="Hellopeter API Key"
                        :required="false"
                        :numeric="false"
                        v-model="profileApiKey"
                    />
                    <p
                        v-if="!canSendReplies">
                        An API key is required in order for replies to be sent using DataEQ Engage.
                    </p>
                </div>

                <div>
                    <label>Add phrase to brand</label>
                    <div v-if="!profileBrands.length">
                        Click <a :class="saving ? 'disabled' : ''" style="cursor: pointer"
                                 @click="openBrandPicker">here</a> to select brands to phrase match this profile to
                    </div>
                    <div v-else>
                        <div
                            class="hellopeter-add-profile-dialog__brands-list dark-scrollbars dark-scrollbars--visible">
                            <ul>
                                <li v-for="brand in profileBrands" :key="brand.name">
                                    {{ brand.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <saving-item key="2" v-if="saving" :saving-message="savingMessage" :save-complete="saveComplete" :save-error="saveError" :save-error-message="saveErrorMessage">
                <template #additionalActions>
                    <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add another <i class="symbol-hellopeter"></i> Hellopeter Profile</h4>
                </template>
            </saving-item>
        </template>
    </add-profile-dialog>
</template>

<script>
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import {mash} from "@/store/Services";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import TextInput from "@/components/inputs/TextInput";
import SavingItem from "@/setup/SavingItem";
import {phraseNeedsQuotes} from "@/app/utils/Phrases";

const HELLOPETER_API_APP_ID = 47;

export default {
    name: "HelloPeterAddProfileDialog",
    components: {SavingItem, TextInput, AddProfileDialog},
    mixins: [addProfileMixins],
    store: VuexStore,
    data: function () {
        return {
            profileName: "",
            profileHandleId: "",
            profileApiKey: "",
            savingMessage: "",
            profileBrands: [],
            nameValid: true,
            profileHandleIdValid: false,
            tempProfiles: [],
            curStage: 1,
            totalStages: 1,
        }
    },

    created() {
        this.totalStages = Object.keys(this.addProfileStages.helloPeter.managed).length;
        this.refreshBrands();
    },

    computed: {
        ...mapState('profiles', ['profiles']),

        canSendReplies() {
            return this.profileApiKey !== "";
        },

        enableNext() {
            return this.nameValid && this.profileHandleIdValid;
        }
    },
    methods: {
        ...mapActions('profiles', ['createProfile', 'updateProfile', 'refreshProfiles']),
        ...mapActions(['refreshBrands']),

        setNameValid(e) {
            this.nameValid = e;
        },

        setprofileHandleIdValid(e) {
            this.profileHandleIdValid = e;
        },

        async next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                // first create the profile, then update the profile with auth
                try {
                    this.saving = true;
                    this.saveComplete = false;

                    await this.savePhrases();

                    this.savingMessage = "Creating profile...";
                    // create profile
                    let profile = {
                        type: this.profileTypes.helloPeter,
                        name: this.profileName,
                        handle: this.profileName,
                        handleId: this.profileHandleId,
                    }

                    this.savingMessage = "Adding profile...";
                    await this.createProfile(profile);

                    let addedProfile = this.profiles.find(p => p.name === profile.name && p.type === this.profileTypes.helloPeter);

                    if (addedProfile) {
                        // update profile with auth
                        if (this.profileApiKey) {
                            this.savingMessage = "Authorising profile...";
                            profile.id = addedProfile.id;
                            profile.token = this.profileHandleId;
                            profile.tokenSecret = this.profileApiKey;
                            profile.apiAppId = HELLOPETER_API_APP_ID;

                            await this.updateProfile(profile);
                        }

                        await this.refreshProfiles(true);
                        this.saveComplete = true;
                    } else {
                        this.saveError = true;
                        console.error("Could not find added profile");
                    }
                } catch (e) {
                    console.error("Error saving hellopeter profile: ", e);
                    this.saveError = true;
                }
            }
        },

        async savePhrases() {
            if (this.profileBrands.length) {
                let phrases = [];
                this.savingMessage = "Saving phrases...";

                // prepare phrases
                this.profileBrands.forEach(brand => {
                    // only allow adding phrases to brands that aren't imported
                    if (!brand.importedFromAccount) {
                        let quoteHandle = phraseNeedsQuotes(this.profileName);
                        let handle = this.profileName;

                        let handlePhrase = {
                            query: quoteHandle === true ? `"${handle}"` : handle,
                            active: true,
                            brandId: brand.id,
                            deleted: false,
                            socialNetworks: "HELLOPETER"
                        };
                        phrases.push(handlePhrase);

                        let quoteHandleId = phraseNeedsQuotes(this.profileHandleId);
                        let handleIdPhrase = {
                            query: quoteHandleId === true ? `"${this.profileHandleId}"` : this.profileHandleId,
                            active: true,
                            brandId: brand.id,
                            deleted: false,
                            socialNetworks: "HELLOPETER"
                        };
                        phrases.push(handleIdPhrase);
                    }
                });

                // save phrases
                await mash.post(`/rest/accounts/${this.account.code}/phrases`, phrases);
                this.refreshBrands(true);
            }
        },

        nameValidRule(value) {
            let existingProfile = this.profiles.find(profile => profile.type === this.profileTypes.helloPeter && (profile.handle === value || profile.name === value));

            this.nameValid = !existingProfile;

            return existingProfile ? "A Hellopeter profile with that name already exists on this account" : true;
        },

        profileHandleIdValidRule(value) {
            let existingProfile = this.profiles.find(profile => profile.type === this.profileTypes.helloPeter && profile.handleId === value);

            this.profileHandleIdValid = !existingProfile;

            return existingProfile ? "A Hellopeter profile with that ID already exists on this account" : true;
        },

        openBrandPicker() {
            let brandPicker = showDialog(BrandPickerDialog, {onlyOne: false, mustHaveOne: true});

            brandPicker.$on('selected', selectedBrands => {
                selectedBrands.forEach(brand => {
                    this.profileBrands.push(brand);
                });
            });
        },

        reset() {
            this.saving = false;
            this.saveComplete = false;
            this.profileName = "";
            this.profileHandleId = "";
            this.profileApiKey = "";
            this.profileBrands = [];
            this.curStage = 1;
        }
    }
}
</script>

<style scoped lang="sass">

.hellopeter-add-profile-dialog

    &__config
        display: flex
        flex-direction: column
        row-gap: 5px

    &__text-input
        height: 80px

    &__brands-list
        border: 1px solid #888
        border-radius: 4px
        max-height: 80px
        overflow-y: auto

        ul
            list-style: none
            margin: 0

        li
            padding: 0 5px
            line-height: 25px

            &:nth-child(odd)
                background: #666

            &:nth-child(even)
                background: #3c3c3c

            &:last-of-type
                margin-bottom: 0

    ::v-deep input
        width: 80%
</style>
