import Vue from "vue";
import {getAllRiskProductSegmentLists,appendSegmentRestrictions} from "@/app/utils/Segments";
import {substituteTagParamaters} from "@/app/utils/Tags";
import VuexStore from "@/store/vuex/VuexStore";
import {escapeHtml} from "@/app/utils/StringUtils";


const performance = Vue.observable({
    title: "Risk factors",
    description: "Measure and report on risk factors for your brand.",
    expanded:
`_Risk factors_ label issues that might pose a risk to your brand. 
This includes conversations from customers, non-customers, and press sources.`,
    image: require("../assets/risk.png"),
    hide: true,
    metrics: []
});

export default performance;

let initPromise = null;

export async function init() {
    // Allow this to be called multiple times.
    if (initPromise) return initPromise;
    initPromise = Promise.all([
        getAllRiskProductSegmentLists(),
        VuexStore.dispatch('refreshBrands')
    ]);
    const [riskSegmentLists, ignore] = await initPromise;
    const brands = VuexStore.state.rootBrands;

    // We want to be able to look up which segments are active,
    // and also know if this account is on Risk and Reputation (a 100% sampling package).
    const activeSegments = new Set();
    let hasRisk = false;
    brands.forEach(b => {
        b.activeSegmentListIds?.forEach(id => activeSegments.add(id));
        if (b.crowdSamplePercentage !== 100 || hasRisk) return;
        b.segmentLists?.forEach(s => {
            if (activeSegments.has(s.id)) {
                hasRisk = true;
            }
        })
    });

    if (riskSegmentLists.length) {
        for (const segment of riskSegmentLists) {
            const title = `${segment.subtitle} ${segment.name.toLowerCase().trim()}`;
            performance.metrics.push({
                id: `risk:${segment.id}`,
                title: title,
                description: substituteTagParamaters(segment.clientDescription),
                more: "This chart shows how much each risk factor contributed to your risk-related conversation",
                keywords: ["risk", "priority", "rpcs"],
                image: require("../assets/risk/risk-volumes-pie.png"),
                additional: !activeSegments.has(segment.id),
                widgetDesc: {
                    type: "FantasticChart",
                    xAxis: `${segment.id}`,
                    yAxis: "mentionPercent",
                    'hidden-title': true,
                    width: 6,
                    height: 4,
                    geometry: "pie",
                    isDonut: true,
                    labelPlacement: "outside",
                    notification: escapeHtml`Added a <strong>${title}</strong> metric`
                },
                reference: {
                    type: "Nomenclature",
                    caption: escapeHtml`${title} reference sheet`,
                    width: 6,
                    height: 4,
                    selectedList: segment.id
                },
                options: [
                    {
                        key: "volume",
                        title: "As bar chart",
                        tooltip: "See volumes as a bar chart",
                        action: async metric => {
                            return Object.assign({},
                                metric,
                                {
                                    widgetDesc: {
                                        type: "FantasticChart",
                                        xAxis: `${segment.id}`,
                                        yAxis: "mentionPercent",
                                        width: 6,
                                        height: 4,
                                        geometry: "rows",
                                        notification: escapeHtml`Added a <strong>${title}</strong> metric`
                                    }
                                })
                        }
                    }
                ]
            });
        }

        performance.hide = false;
        performance.metrics[0].hero = true;
        performance.metrics[0].additional = false;


        if (riskSegmentLists.length > 1) {
            performance.metrics.push({
                id: `risk`,
                title: "All risk factors",
                description: "View all risk-related conversation broken down by contributing factors",
                image: require("../assets/risk/risk.png"),
                widgetDesc: {
                    type: "FantasticChart",
                    xAxis: "risk",
                    yAxis: "mentionPercent",
                    width: 8,
                    height: 4,
                    geometry: "columns",
                    notification: "Added a metric showing all <strong>Risk Factors</strong>"
                }
            });
        }

        performance.metrics.push({
            id: `riskVolumes`,
            title: "Risk volumes",
            description: "See how your volumes of risk-related conversations have changed",
            more: "This metric can highlight spikes in risk-related conversation",
            image: require("../assets/risk/risk-volumes.png"),
            widgetDesc: {
                type: "FantasticChart",
                xAxis: "published",
                width: 8,
                height: 4,
                filter: appendSegmentRestrictions(`tag is 1 and (${riskSegmentLists.map(c => `segment is ${c.id}`).join(' or ')})`),
                notification: "Added a metric showing all <strong>Risk volumes</strong>",
                caption: "Risk volumes",
                "manual-caption": true,
                xGridLines: false,
                show: [
                    {
                        geometry: "lines",
                        yAxis: "mentionPercent",
                        "colour-palette": "rpcs",
                        "colour-index": 0,

                    }
                ]
            }
        });

        if (hasRisk) {
            performance.metrics.push({
                id: "risk/trending",
                title: "Trending risk factors",
                description: "See how your risk factors have changed",
                more: "Highlights risk factors that have increased significantly in volume, or spiked, compared to the previous time period",
                image: require("../assets/risk/trending.png"),
                keywords: ['trend', 'trends', 'risk trends'],
                widgetDesc: {
                    type: "TrendingWidget",
                    width: 8,
                    height: 4,
                    caption: "Trending risk factors",
                    dataType: 'RISK',
                    withTotal: true,
                    withSentiment: true,
                    withAverage: true,
                    withMaximum: true,
                    withMaxDate: true
                }
            })
        }
    }

}

