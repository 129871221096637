import Vue from "vue";
import Vuex from "vuex";
import {appendFiltersReadably, getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {getDefaultBrand} from "@/app/toplevel/explore/overview/ExploreUtilities";

Vue.use(Vuex);

export default {
    namespaced: true,

    state: {
        rawFilter: "relevancy isnt irrelevant and published inthelast week",
        mentionCount: null,
        previousFilters: [],

        addendums: [] // Extra filters to apply to the raw filter. Helps to keep track of specific filter changes.
    },

    getters: {
        brands(state) {
            if (!state.filter) return [];
            return getBrandsInFilter(state.filter).include;
        },
        filter: state => {
            let filter = state.rawFilter;
            state.addendums?.forEach(addendum => filter = appendFiltersReadably(filter, addendum.filter));
            return filter;
        }
    },

    mutations: {
        setFilter: (state, value) => {
            state.mentionCount = null;
            state.addendums = [];
            state.rawFilter = value
        },
        setPreviousFilters: (state, value) => state.previousFilters = value,
        setMentionCount: (state, value) => state.mentionCount = value,
        setFilterAddendums: (state, value) => state.addendums = Array.from(value),
        pushFilterAddendum: (state, {label, filter}) => state.addendums.push({label, filter})
    },

    actions: {
        async initialise({dispatch}) {
            let filter = "relevancy isnt irrelevant and published inthelast week";
            const brand = await getDefaultBrand();
            if (brand) {
                filter = `relevancy isnt irrelevant and published inthelast week and brand isorchildof ${brand.id}`;
                // filter = `relevancy isnt irrelevant and published before '2021/05/31' and published after '2021/05/01' and brand isorchildof ${brand.id}`;
            }

            dispatch("setFilter", filter);
        },

        async addFilterAddendum({commit, state, getters}, {filter, label}) {
            const oldFilter = getters.filter;
            if (state.addendums.find(d => d.label === label)) return;

            await commit('pushFilterAddendum', {filter, label});
            if (getters.filter !== oldFilter) {
                commit('setMentionCount', null);
                return true;
            }
            return false;
        },

        async removeFilterAddendum({commit, state, getters}, addendumLabel) {
            const addendums = state.addendums.filter(d => d.label !== addendumLabel);
            if (addendums.length !== state.addendums.length) {
                await commit('setMentionCount', null);
            }
            await commit('setFilterAddendums', addendums);
        },

        setFilter({dispatch, commit, state, getters}, filter) {
            dispatch('pushFilter', state.filter);
            commit('setFilter', filter);
        },

        undoLastFilter({commit, state}) {
            if (!state.previousFilters?.length) return;
            const previous = Array.from(state.previousFilters);
            const last = previous.pop();
            commit('setFilter', last);
            commit('setPreviousFilters', previous);
        },

        pushFilter({state, commit}, filter) {
            if (!filter) return;
            const MAX_SIZE = 20;

            const filters = Array.from(state.previousFilters);
            if (filters.at(-1) !== filter) {
                filters.push(filter);
                if (filters.length > MAX_SIZE) filters.shift();
                commit('setPreviousFilters', filters);
            }
        }
    }


};