/**
 * Select languages to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
import {LANGUAGES} from "@/app/utils/Language";

Beef.module("IncExLanguagePicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/incexlanguage/IncExLanguagePicker.handlebars"),
        items: LANGUAGES,
        bindings: {
            inc: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
            ex: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" }
        },
        attachPickers: function() {
            Beef.LanguagePicker.attach(this, ".inc", "inc");
            Beef.LanguagePicker.attach(this, ".ex", "ex");
        }
    });

    this.converter = Beef.LanguagePicker.createConverter("Languages");

    /**
     * Attach a language picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View);
    };

});
