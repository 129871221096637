<template>
    <div :class="{'be-button': true, 'has-options': !!options}"
             :tabindex="tabSelectable ? 0 : -1"
             ref="button"
             @keydown.enter.stop="click($event)">
        <old-tooltip :label="tooltip" :keyword="keyword">
            <span class="btn" :class="{'btn-primary': primary, 'btn-link': link, 'btn-danger': danger, disabled, active}" @click="click">
                <span class="be-button__button">
                    <slot>
                        <em style="color: red;">«No label»</em>
                    </slot>
                </span>
                <popup-menu v-if="options && options.length"
                            v-model="isOpen"
                            :left="true"
                            :bottom="true"
                            @click="showOptions = !showOptions">
                    <template #activator>
                        <span class="be-button__options">
                            <span class="be-button__border"></span>
                            <span class="caret"></span>
                        </span>
                    </template>

                    <div class="mini-menu">
                        <a v-for="option in visibleOptions"
                           :key="option.key"
                           @click="select(option)"
                           @mouseover="showTooltip($event, option.tooltip)">
                            {{option.title || option.key}}
                        </a>
                    </div>
                </popup-menu>
            </span>
        </old-tooltip>
    </div>
</template>

<script>
import PopupMenu from "../PopupMenu";
import {closeCurrentTooltip, showTooltipText} from "../tooltip/TooltipUtilities";
import OldTooltip from "@/components/tooltip/OldTooltip";


export default {
        name: "BeButton",
        components: {OldTooltip, PopupMenu},
        props: {
            value: Boolean, // linked to whether the button options are open or not
            tooltip: String,
            keyword: String,
            primary: {
                type: Boolean,
                default: false
            },
            danger: {
                type: Boolean,
                default: false
            },
            link: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            active: {
                type: Boolean,
                default: false
            },
            options: {
                type: Array
            },
            tabSelectable: {
                type: Boolean,
                default: true
            }
        },

        data: function() {
            return {
                isOpen: false
            }
        },

        computed: {
            visibleOptions() {
                return this.options;
            }
        },

        watch: {
            isOpen(val) {
                this.$emit('input', val);
            },
            value(val) {
                this.isOpen = val;
            }
        },

        methods: {
            focus() {
                this.$refs.button.focus();
            },
            click(ev) {
                if (!this.disabled) this.$emit('click', ev);
            },

            select(option) {
                closeCurrentTooltip();
                this.$emit('option', option);
            },
            showTooltip(ev, tooltip) {
                if (!tooltip) return;
                showTooltipText(ev.target, tooltip);
            }
        },

        beforeDestroy() {
            this.isOpen = false;
        }
    }
</script>

<style>
    :root {
        --be-button-link-colour: var(--be-colour-text-dark);
    }
</style>

<style scoped lang="scss">


.be-button {
    --disabled-background: #747474;
    --disabled-color: #bbbbbb;

    position: relative;
    width: max-content;
    box-sizing: border-box;
    touch-action: manipulation;

    &:focus-visible {
        outline: var(--focus-border);
        outline-offset: -2px;
    }

    .btn {
        box-sizing: border-box;
        width: 100%;
    }

    &.has-options .btn {
        padding-right: 0;
        padding-left: 0;
    }
}

.dialog-button-bar .be-button {
    min-width: 60px;
}

.be-button__button {
    margin-top: 3px;
    box-sizing: border-box;
}

.has-options .be-button__button {
    padding-left: 9px;
    padding-right: 5px;
}

.be-button__border {
    border-left: thin solid var(--be-colour-mid-grey);
    height: 20px;
    display: inline-block;
    padding-left: 5px;
    margin-bottom: -5px;
    box-sizing: border-box;
}

.be-button__options {
    padding-right: 6px;
    outline: none;
    box-sizing: border-box;
}

.btn:hover {
    --background-colour: #747474;
    .be-button__button:not(:hover) {
    }

    .be-button__options:not(:hover) {
    }
}


.be-button .options-popup.popup {
    display: block;

    &.bottom-left {
        top: 110%;
        left: -90%;
    }
}

.be-button .btn-link {
    color: var(--be-button-link-colour);
}

.be-button .btn-link.disabled {
    color: var(--be-button-link-colour);
    cursor: not-allowed;
    background: transparent;
}

.be-button .btn-link:hover {
    color: var(--be-colour-text-dark__hover);
}


.be-button .btn-primary.btn-link {
    background: none;
    color: #77b815;
    animation: none;

    &:hover {
        color: var(--be-colour-text-dark__hover);
    }
}

.btn-danger.disabled {
    color: var(--disabled-color);
    background: var(--disabled-background);
}

.btn-primary.disabled {
    color: var(--disabled-color);
}


</style>