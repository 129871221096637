import beefRenderResponseSankey from './ResponseSankeyD3';
import "./ResponseSankey.css"

import {beef} from '@/store/Services'
import CSV from "../../../app/CSV";
import BrandResponseHelper from './BrandResponseHelper'
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {extractLink, extractSocialNetwork, filterToDateRange} from "@/dashboards/filter/BasicFilter";
import moment from "moment";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {account, currentAccountCode} from "@/app/utils/Account";
import {features} from "@/app/Features";
import {getAllCxSegmentLists} from "@/app/utils/Segments";
import {grouseGet} from "@/data/Grouse";
import {splitAtSpaces} from "@/app/utils/StringUtils";


/**
 * Sankey chart showing how well the brand responds to its customers online.
 */
Beef.module("Widget.ResponseSankey").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Priority Conversation Flow Map",
        description:    "How well does the brand responds to publicly to online conversation",
        group:          "other",
        width:          6,
        height:         4
    };

    this.View = Beef.BoundItemView.extend({

        attributes: {
            'class': 'response-sankey widget-font widget-height-inner'
        },

        modelEvents: {
            "change":   "maybeRefresh"
        },

        initialize: function() {
            this.refresh();
        },

        render: function(){
            //console.log("render " + this._data)
            // Need to clear text before getting width and height, otherwise cannot shrink this widget.
            this.$el.text('')
            this.renderChart()
        },

        renderChart: function() {
            let options = {
                onClick: this.onChartClick,
                //showTooltip: this.showTooltip
            }
            let w = this.$el.width()
            let h = this.$el.height()
            if (this._data) {
                this._d3data = beefRenderResponseSankey(this.$el[0], w, h, this._data, options)
            } else {
                this.$el.text('')
                this._d3data = null
            }
        },

        maybeRefresh: function() {
            var c = this.model.changed;
            var refresh = c['_effectiveFilter'];
            if (refresh || c['supportProfiles'] !== undefined || c['ignoreEngage'] !== undefined) {
                this.refresh();
            } else {
                if (c['width'] || c['height']) {
                    this.$el.text('');
                    this.$el.css({'max-width': "none"});
                    setTimeout(function() { this.render() }.bind(this), 400);
                }
                else this.render();
            }
        },

        async refresh() {
            try {
                let filter = this.model.get('_effectiveFilter')
                if (!filter) return

                let helper = new BrandResponseHelper(this)
                await helper.analyzeFilter()

                if (account().hasEngage && helper.ownBrands && !this.model.get('ignoreEngage')) {
                    this.refreshEngage(filter, helper)
                } else {
                    this.refreshAnalyze(filter, helper)
                }
            } catch(error) {
                errorHelper(this.model, error);
            }
        },

        /**
         * Get much more accurate ticket based data from Engage instead of looking at mentions.
         */
        refreshEngage: async function(filter, helper) {
            try {
                let range = filterToDateRange(filter)
                if (!range) return helper.setMessage("Filter must contain a date range")
                let start = formatDate(range.start)
                let end = formatDate(range.end)

                this._data = null
                this.render()

                let sites = []
                let priorities = []

                let link = extractLink(filter)
                if (link) splitAtSpaces(link).forEach(s => {
                    if (!s || s.charAt(0) === '-') return
                    if (s === "twitter.com") sites.push("Twitter")
                    else if (s === "facebook.com") sites.push("Facebook")
                    //else if (s === "instagram.com") sites.push("Instagram")
                })

                let socialNetwork = extractSocialNetwork(filter)
                if (socialNetwork) splitAtSpaces(socialNetwork).forEach(s => {
                    if (!s || s.charAt(0) === '-') return
                    if (s === "TWITTER" && sites.indexOf("Twitter") < 0) sites.push("Twitter")
                    else if (s === "FACEBOOK" && sites.indexOf("Facebook") < 0) sites.push("Facebook")
                    //else if (s === "INSTAGRAM") sites.push("Instagram")
                })

                await beef.get("/api/engage/accounts/" + currentAccountCode() + "/ticket-flow", { params: { start, end } })
                          .then(res => {
                              let nodes = res.data.nodes
                              let links = res.data.sites.filter(s => !sites.length || sites.indexOf(s.site) > -1)
                                             .reduce((links, siteMap) => {
                                                 let pLinks = Object.values(siteMap.priorities).filter(p => {
                                                     return !priorities.length || priorities.indexOf(p.id) > -1
                                                 }).map(priorityMap =>  priorityMap.links)
                                                 pLinks.forEach(pLinkList => links = [...links, ...pLinkList])
                                                 return links
                                             }, [])

                              let linkMap = links.reduce((linkMap, link) => {
                                  let key = link.source + " " + link.target
                                  if (linkMap[key]) linkMap[key].value = linkMap[key].value + link.value
                                  else linkMap[key] = {source: link.source, target: link.target, value: link.value}
                                  return linkMap
                              }, {});

                              let sortedLinks = Object.values(linkMap).sort( (l1, l2) => l1.index - l2.index)
                              this._data = {nodes: nodes, links: sortedLinks, fromEngage: true}
                              this.renderChart()
                          })

                this.model.generalData.set({
                    '_loading': false,
                    _completed: true,
                    _footnotes: ["Ticket counts from DataEQ Engage" + (sites.length ? " for " + niceJoin(sites) : "")]
                })
            } catch (error) {
                errorHelper(this.model, error);
            }
        },

        /**
         * Get response information from looking at mentions.
         */
        refreshAnalyze: async function(filter, helper) {
            let cx = await getAllCxSegmentLists();
            if (!cx?.length) return;
            let supportProfileIds = helper.supportProfileIds;

            let supportHandles = "(" + supportProfileIds.map(id => "MentionedProfile IS " + id).join(" OR ") + ")";
            let noSupportHandles = supportProfileIds.map(id => "MentionedProfile ISNT " + id).join(" AND ");
            let supportHandlesIn = "(" + supportProfileIds.join(",") + ")";
            let respondedTo = "HasReplyFromProfile IN " + supportHandlesIn;
            let notRespondedTo = "HasReplyFromProfile NOTIN " + supportHandlesIn;
            let rpcsTags = "(Tag IS 1 OR Tag IS 2 OR Tag IS 3 OR Tag IS 4)";
            let noRpcsTags = "Tag ISNT 1 AND Tag ISNT 2 AND Tag ISNT 3 AND Tag ISNT 4";
            let cxTags = `(${cx.map(cx => `Tag IS ${cx.id} OR Tag IS ${cx.children[cx.children.length - 1]}`).join(" OR ")})`;

            let topLevelFilter = filter + " AND Visibility IS PUBLIC AND ReshareOf IS unknown AND ReplyTo IS unknown";
            let cxFilter = topLevelFilter + " AND " + cxTags;
            let directFilter = cxFilter + " AND " + supportHandles;
            let indirectFilter = cxFilter + " AND " + noSupportHandles;
            let needsResponseFilter = topLevelFilter + " AND " + rpcsTags;
            let noResponseNeededFilter = cxFilter + " AND " + noRpcsTags;
            let riskFilter = topLevelFilter + " AND Tag IS 1";
            let purchaseFilter = topLevelFilter + " AND Tag IS 2";
            let cancelFilter = topLevelFilter + " AND Tag IS 3";
            let serviceFilter = topLevelFilter + " AND Tag IS 4";
            let respondedToFilter = cxFilter + " AND " + respondedTo;
            let notRespondedToPriFilter = topLevelFilter + " AND " + notRespondedTo + " AND " + rpcsTags;
            let notRespondedToNonPriFilter = cxFilter + " AND " + notRespondedTo + " AND " + noRpcsTags;

            let nodes = [
                { id: "direct", label: "Direct", filter: directFilter, comment: "Public top level posts with CX segments mentioning brand support handles" },
                { id: "indirect", label: "Indirect", filter: indirectFilter, comment: "Public top level posts with CX segments not mentioning brand support handles" },
                { id: "needsResponse", label: "Priority conversation", filter: needsResponseFilter, comment: "Public top level posts with RPCS tags", calcValueFromInputs: true },
                { id: "noResponseNeeded", label: "No response needed", filter: noResponseNeededFilter, comment: "Public top level posts with CX segments and no RPCS tags" },
                { id: "risk", label: "Risk", filter: riskFilter, comment: "Public top level posts with CX segments and risk tag" },
                { id: "purchase", label: "Purchase", filter: purchaseFilter, comment: "Public top level posts with CX segments and purchase tag" },
                { id: "cancel", label: "Cancel", filter: cancelFilter, comment: "Public top level posts with CX segments and cancel tag" },
                { id: "service", label: "Service", filter: serviceFilter, comment: "Public top level posts with CX segments and service tag" },
                { id: "nonPriority", label: "Non-priority", filter: noResponseNeededFilter, noPercent: true, comment: "Public top level posts with CX segments and no RPCS tags" },
                { id: "respondedTo", label: "Responded to", filter: respondedToFilter, comment: "Public top level posts with CX segments and a response from one of the brands support handles" },
                { id: "notRespondedTo", label: "Not responded to", filter: notRespondedToPriFilter, comment: "Public top level posts with RPCS tags without a response from one of the brands support handles" },
                { id: "notRespondedToNonPri", label: "Not responded to", filter: notRespondedToNonPriFilter, noPercent: true, comment: "Public top level posts with CX segments and no RPCS tags without a response from one of the brands support handles"  }
            ]

            let links = [
                { source: "direct", target: "needsResponse", filter: topLevelFilter + " AND " + supportHandles + " AND " + rpcsTags, comment: "Public top level posts mentioning brand support handles, with RPCS tags" },
                { source: "direct", target: "noResponseNeeded", filter: directFilter + " AND " + noRpcsTags, comment: "Public top level posts with CX segments, mentioning brand support handles, without RPCS tags" },
                { source: "indirect", target: "needsResponse", filter: topLevelFilter + " AND " + noSupportHandles + " AND " + rpcsTags, comment: "Public top level posts not mentioning brand support handles, with RPCS tags" },
                { source: "indirect", target: "noResponseNeeded", filter: indirectFilter + " AND " + noRpcsTags, comment: "Public top level posts with CX segments, not mentioning brand support handles, without any RPCS tags" },
                { source: "needsResponse", target: "risk", filter: riskFilter, comment: "Public top level posts with risk tag" },
                { source: "needsResponse", target: "purchase", filter: purchaseFilter, comment: "Public top level posts with purchase tag" },
                { source: "needsResponse", target: "cancel", filter: cancelFilter, comment: "Public top level posts with cancel tag" },
                { source: "needsResponse", target: "service", filter: serviceFilter, comment: "Public top level posts with service tag" },
                { source: "noResponseNeeded", target: "nonPriority", filter: noResponseNeededFilter, comment: "Public top level posts with CX segments and no RPCS tags" },
                { source: "risk", target: "respondedTo", filter: topLevelFilter + " AND " + respondedTo + " AND Tag IS 1", comment: "Public top level posts, with a response from one of the brands support handles, with risk tag" },
                { source: "risk", target: "notRespondedTo", filter: topLevelFilter + " AND " + notRespondedTo + " AND Tag IS 1", comment: "Public top level posts, without a response from one of the brands support handles, with risk tag" },
                { source: "purchase", target: "respondedTo", filter: topLevelFilter + " AND " + respondedTo + " AND Tag IS 2", comment: "Public top level posts, with a response from one of the brands support handles, with purchase tag" },
                { source: "purchase", target: "notRespondedTo", filter: topLevelFilter + " AND " + notRespondedTo + " AND Tag IS 2", comment: "Public top level posts, without a response from one of the brands support handles, with purchase tag" },
                { source: "cancel", target: "respondedTo", filter: topLevelFilter + " AND " + respondedTo + " AND Tag IS 3", comment: "Public top level posts, with a response from one of the brands support handles, with cancel tag" },
                { source: "cancel", target: "notRespondedTo", filter: topLevelFilter + " AND " + notRespondedTo + " AND Tag IS 3", comment: "Public top level posts, without a response from one of the brands support handles, with cancel tag" },
                { source: "service", target: "respondedTo", filter: topLevelFilter + " AND " + respondedTo + " AND Tag IS 4", comment: "Public top level posts, with a response from one of the brands support handles, with service tag" },
                { source: "service", target: "notRespondedTo", filter: topLevelFilter + " AND " + notRespondedTo + " AND Tag IS 4", comment: "Public top level posts, without a response from one of the brands support handles, with service tag" },
                { source: "nonPriority", target: "respondedTo", filter: respondedToFilter + " AND " + noRpcsTags, comment: "Public top level posts with CX segments, with a response from one of the brands support handles, without any RPCS tags" },
                { source: "nonPriority", target: "notRespondedToNonPri", filter: notRespondedToNonPriFilter, comment: "Public top level posts with CX segments, without a response from one of the brands support handles, without any RPCS tags" },
            ]

            this._data = null;
            this.render();
            this._data = { nodes: nodes, links: links };

            let countEndpoint = '/v4/accounts/' + currentAccountCode() + '/mentions/count';
            let first = true;

            const fromGrouse = this.model.getSectionModel()
                ? this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view)
                : grouseGet;

            let calls = links.filter(d => d.filter).map(d => {
                let params = { filter: d.filter };

                return fromGrouse(countEndpoint, params).then(res => {
                    d.value = res.mentionCount;
                    if (first) {
                        this.model.generalData.set({'_loading': false });
                        first = false;
                    }
                    this.renderChart();
                });
            });
            await Promise.all(calls);

            let sampleSize = 0;
            for (let i = 0; i < 4; i++) sampleSize += links[i].value;

            this.model.generalData.set({
                '_loading': false,
                _completed: true,
                _footnotes: ["Calculated from " + sampleSize + " public top level mentions with customer experience segments"]
            });
        },

        onChartClick: function(d) {
            let title = (d.label || (d.source.label + " & " + d.target.label)) + " Mentions"
            showMentions(d.filter, title)
        },

        showTooltip: function(d) {
            let model = new Backbone.Model(d)
            let tooltipSettings = {
                template: require("./ResponseSankeyTooltip.handlebars"),
                target: d3.event.target,
                positions: ['top-right', 'top-left', 'bottom-left', 'bottom-right'],
                model: model,
                autoclose: true
            }
            Beef.Tooltip.show(tooltipSettings)
        },

        getCsv: function() {
            if (!this._d3data) return
            let csv = new CSV()
            csv.add("link_or_node,mentions,layer,percent,comment,filter").eol()
            this._d3data.nodes.forEach(node => {
                csv.quote(node.label).add().add(node.layer).add(dec1(node.percent)).quote(node.comment).quote(node.filter).eol()
            })
            this._d3data.links.forEach(link => {
                csv.quote(link.source.label + " - " + link.target.label).add(link.value).add().add().quote(link.comment)
                    .quote(link.filter).eol()
            })
            csv.download(this.model.get("caption") || "csv")
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/response/ResponseSankeySettings.handlebars"),

        editAttributes: function() {
            return ['supportProfiles', 'ignoreEngage']
        },

        events: {
        },

        onFirstRender: function() {
            Beef.ProfilePicker.attach(this, ".supportProfiles", "supportProfiles");
        },

        bindings: function() {
            return {
                supportProfiles: { converterFactory: Beef.ProfilePicker.createConverterFactory("Profiles"), elAttribute: "data-value" },
            }
        }
    });
});

function dec1(v) {
    if (v === null || v === undefined || isNaN(v)) return ""
    return Math.floor(v * 10 + 0.5) / 10
}

function niceJoin(a) {
    let ans = ""
    for (let i = 0; i < a.length; i++) {
        if (ans.length > 0) ans += i == a.length -1 ? " and " : ", "
        ans += a[i]
    }
    return ans
}

function formatDate(d) {
    let m = !d || d === "today" ? moment() : moment(d)
    return m.format("YYYY-MM-DD")
}

