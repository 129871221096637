<template>
    <div class="select-managed-profiles">
        <h4>Select your managed <i :class="getProfileIcon(profileType)"></i> {{ prettyProfileType(profileType) }}s</h4>

        <loading-message v-if="loading" :message="loadingMessage"/>
        <div v-else>
            <div v-if="managedProfiles.length">
                <p>
                    Select the managed {{ prettyProfileType(profileType) }}s you would like to add to <strong class="select-managed-profiles__account-name">{{account.name}}</strong>.
                    The list only contains profiles that have not been authorised on this account yet. You can authorise more profiles in the <a @click="openPlatformAuthorisation">Platform Authorisation</a> settings.
                </p>

                <search-input
                    class="select-managed-profiles__search-bar"
                    autofocus
                    placeholder="Search for a page"
                    v-if="managedProfiles.length > 5"
                    v-model="searchTerm">
                </search-input>

                <div class="select-managed-profiles__table-container dark-scrollbars dark-scrollbars--visible">
                    <table class="table table-condensed table-hover">
                        <thead>
                        <tr>
                            <th class="cell-checkbox">
                                <input type="checkbox" v-model="selectAll"/>
                            </th>
                            <th>{{ prettyProfileType(profileType) }}</th>
                            <th v-if="profileType === profileTypes.facebook">Connected Instagram Account</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="profile in filteredProfiles"
                            :key="profile.handleId">
                            <td class="cell-checkbox"><input type="checkbox" v-model="profile.added" @change="selectProfile()"></td>
                            <td>
                                <online-profile class="select-managed-profiles__online-profile" :profile="profile"/>
                            </td>
                            <td v-if="profileType === profileTypes.facebook">
                                <online-profile class="select-managed-profiles__online-profile" v-if="profile.linkedProfile" :profile="profile.linkedProfile"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <dark-card v-if="hasDms">
                    <label class="checkbox"
                           title="Tick to make direct messages sent to this profile visible inside DataEQ">
                        <input type="checkbox" v-model="dmsEnabled">
                        Direct messages visible in DataEQ
                        <span class="help-block">
                        If enabled, DMs will be visible to all users of your DataEQ account. DMs sent to the Crowd
                        have numbers and email addresses anonymised.
                    </span>
                    </label>
                </dark-card>
            </div>
            <p v-else>
                You have no more managed {{ prettyProfileType(profileType) }}s to add to this account. To add more managed {{ prettyProfileType(profileType) }}s profiles to this account, you must first authorise them using the <a @click="openPlatformAuthorisation">Platform Authorisation</a> settings.
            </p>
        </div>

    </div>
</template>

<script>
import { showDialogComponent as showDialog } from "@/app/framework/dialogs/DialogUtilities";
import UserSettings from "@/app/framework/dialogs/user-settings/UserSettings";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import { platforms } from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import { notifyUserOfError } from "@/app/framework/notifications/Notifications";
import LoadingMessage from "@/components/LoadingMessage";
import DarkCard from "@/components/cards/DarkCard";
import SearchInput from "@/components/inputs/SearchInput";
import { getProfileIcon, prettyProfileType, profileTypes } from "@/setup/profiles/ProfileUtils";
import { mash } from "@/store/Services";
import VuexStore from "@/store/vuex/VuexStore";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "SelectManagedProfiles",
    components: {OnlineProfile, DarkCard, LoadingMessage, SearchInput},
    store: VuexStore,
    props: {
        profileType: {
            required: true,
            type: String
        }
    },

    data: function () {
        return {
            loading: false,
            profileTypes: profileTypes,
            platforms: platforms,
            managedProfiles: [],
            selectAll: false,
            searchTerm: ""
        }
    },

    async activated() {
        await this.init();
    },

    watch: {
        selectAll(value) {
            this.filteredProfiles.forEach(profile => {
                profile.added = value;
            });

            let selectedProfiles = JSON.parse(JSON.stringify(this.filteredProfiles.filter(profile => profile.added)));

            this.setNewManagedProfiles(selectedProfiles);
        },

        async managedFacebookPages() {
            if (this.profileType === this.profileTypes.facebook) {
                await this.init();
            }
        },

        async managedTwitterProfiles() {
            if (this.profileType === this.profileTypes.twitter) {
                await this.init();
            }
        },

        async managedLinkedinProfiles() {
            if (this.profileType === this.profileTypes.linkedin) {
                await this.init();
            }
        },

        async managedGoogleBusinessProfiles() {
            if (this.profileType === this.profileTypes.googleBusiness) {
                await this.init();
            }
        }
    },

    computed: {
        ...mapState(['account']),

        ...mapState('profiles', [
            'newProfileData'
        ]),
        ...mapState('userPlatformAuth', [
            'facebookUserToken', 'managedFacebookPages', 'managedTwitterProfiles', 'managedLinkedinProfiles',
        ]),

        ...mapState('userPlatformAuth/googleBusinessAuthModule', [
            'managedGoogleBusinessProfiles'
        ]),

        ...mapState('userPlatformAuth/tiktokAuthModule', [
            'managedTiktokProfiles'
        ]),

        hasDms(){
            switch(this.profileType){
                case this.profileTypes.linkedin:
                case this.profileTypes.googleBusiness:
                case this.profileTypes.tiktok:
                    return false;
                default:
                    return true;
            }
        },

        dmsEnabled: {
            get() {
                return this.newProfileData.managedProfiles.dmsEnabled
            },
            set(value) {
                this.setNewManagedProfilesDmsEnabled(value);
            }
        },

        filteredProfiles() {
            let search = this.searchTerm.toLowerCase();

            return this.managedProfiles.filter(profile => profile.name.toLowerCase().includes(search) ||
                profile.handle.toLowerCase().includes(search) || profile.handleId.toLowerCase().includes(search) ||
                profile.linkedProfile?.name.toLowerCase().includes(search) ||
                profile.linkedProfile?.handle.toLowerCase().includes(search) || profile.linkedProfile?.handleId.toLowerCase().includes(search));
        },

        loadingMessage() {
            switch (this.profileType) {
                case this.profileTypes.facebook:
                    return "Fetching your managed Facebook pages";
                case this.profileTypes.twitter:
                    return "Fetching your managed X profiles";
                case this.profileTypes.linkedin:
                    return "Fetching your managed LinkedIn Company profiles";
                case this.profileTypes.googleBusiness:
                    return "Fetching your managed Google business profiles";
                case this.profileTypes.tiktok:
                    return "Fetching your managed Tiktok profiles";
                default:
                    return "Fetching your managed profiles"
            }
        }
    },

    methods: {
        ...mapActions('userPlatformAuth', [
            'initializeFbUserToken', 'initializeManagedFacebookPages', 'initializeManagedTwitterProfiles', 'initializeManagedLinkedinProfiles'
        ]),

        ...mapActions('userPlatformAuth/googleBusinessAuthModule', [
            'initializeManagedGoogleBusinessProfiles'
        ]),

        ...mapActions('userPlatformAuth/tiktokAuthModule', [
            'initializeManagedTiktokProfiles'
        ]),
        
        ...mapMutations('profiles', ['setNewManagedProfiles', 'setNewManagedProfilesDmsEnabled']),

        getProfileIcon,
        prettyProfileType,

        openPlatformAuthorisation() {
            let selectedPlatform = null;

            switch (this.profileType) {
                case this.profileTypes.facebook:
                    selectedPlatform = this.platforms.facebook;
                    break;
                case this.profileTypes.twitter:
                    selectedPlatform = this.platforms.twitter;
                    break;
                case this.profileTypes.linkedin:
                    selectedPlatform = this.platforms.linkedin;
                    break;
                case this.profileTypes.googleBusiness:
                    selectedPlatform = this.platforms.googleBusiness;
                    break;
                case this.profileTypes.tiktok:
                    selectedPlatform = this.platforms.tiktok;
                    break;
            }

            let platformAuthOptions = {
                selectedPlatform: selectedPlatform
            }

            showDialog(UserSettings, {openView: "platform_auth", platformAuthOptions: platformAuthOptions});
        },

        selectProfile() {
            let selectedProfiles = JSON.parse(JSON.stringify(this.filteredProfiles.filter(profile => profile.added)));

            this.setNewManagedProfiles(selectedProfiles);
        },

        async init() {
            try {
                this.loading = true;

                this.selectAll = false;

                this.setNewManagedProfiles([]);
                this.setNewManagedProfilesDmsEnabled(false);

                this.managedProfiles = JSON.parse(JSON.stringify(await this.fetchManagedProfiles()));
            } catch (e) {
                console.error(`Unable to fetch user's managed ${prettyProfileType(this.profileType)}s: `, e);
                notifyUserOfError(`We were unable to fetch your managed ${prettyProfileType(this.profileType)}s. Please reload the page and try again or contact DataEQ support.`);
            } finally {
                this.loading = false;
            }
        },

        async fetchManagedProfiles() {
            // check which pages have already been added to Mash
            let response = await mash.get(`/rest/accounts/${this.account.code}/online-profiles`);

            let addedProfiles = response.data;

            switch (this.profileType) {
                case this.profileTypes.facebook:
                    await this.initializeFbUserToken();
                    await this.initializeManagedFacebookPages();

                    return this.managedFacebookPages.filter(profile => !addedProfiles.find(addedAuthProfile => addedAuthProfile.handleId === profile.handleId && addedAuthProfile.authorized));
                case this.profileTypes.twitter:
                    await this.initializeManagedTwitterProfiles();

                    return this.managedTwitterProfiles.filter(page => !addedProfiles.find(addedPage => page.handleId === addedPage.handleId && addedPage.authorized));
                case this.profileTypes.linkedin:
                    await this.initializeManagedLinkedinProfiles();

                    return this.managedLinkedinProfiles.filter(page => !addedProfiles.find(addedPage => page.handleId === addedPage.handleId && addedPage.authorized));
                case this.profileTypes.googleBusiness:
                    await this.initializeManagedGoogleBusinessProfiles();

                    return this.managedGoogleBusinessProfiles.filter(business => !addedProfiles.find(addedBusiness => business.handleId === addedBusiness.handleId && addedBusiness.authorized));
                case this.profileTypes.tiktok:
                    await this.initializeManagedTiktokProfiles();

                    return this.managedTiktokProfiles.filter(business => !addedProfiles.find(addedBusiness => business.handleId === addedBusiness.handleId && addedBusiness.authorized));
                default:
                    return [];
            }
        }
    }
}
</script>

<style scoped lang="sass">

.select-managed-profiles

    p
        color: var(--be-colour-text-dark)

    &__loader
        margin: auto

    &__search-bar
        margin-bottom: 10px
        width: 90%

    &__account-name
        font-weight: bold
        color: white

    &__table-container
        width: 100%
        height: clamp(250px, 25vh, 550px)
        margin-bottom: 20px
        padding-right: 5px
        overflow-y: auto

        table
            border-collapse: separate
            border-spacing: 0 5px
            border: none
            background: var(--body-background-colour)

        th
            border: 1px solid #555
            padding-left: 14px
            color: white

            &:first-of-type
                padding: 0

        td
            border: 1px solid #272727
            background: #333333

        .cell-checkbox
            text-align: center
            vertical-align: middle

            input
                margin: 0
                transform: scale(1.2)

    &__online-profile
        height: 35px

    a
        cursor: pointer

</style>