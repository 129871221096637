var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../helpers/capitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":19}}}))
    + " this as\n<span class=\"profile tag-input uneditable-input\">\n    <span name=\"profile\"></span>\n</span>\n<a class=\"btn like-button disabled\" href=\"#\">"
    + alias2(__default(require("../helpers/capitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":64}}}))
    + "</a>";
},"useData":true});