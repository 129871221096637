var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "        <div class=\"row-fluid\">\n            <div class=\"span6\">\n                <div class=\"control-group\">\n                    <label>Send to a specific crowd</label>\n                    <div class=\"controls\">\n                        <span class=\"crowdregion tag-input uneditable-input span12\" tabindex=\"0\">\n                            <span name=\"crowdregion\"></span>\n                            <input class=\"tag popup-menu\" type=\"text\" placeholder=\"Type the crowd name\" style=\"width:150px\"/>\n                        </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row-fluid\">\n            <div class=\"span6\">\n                <div class=\"control-group\">\n                    <label>Create specific job(s)</label>\n                    <div class=\"controls\">\n                        <span class=\"jobtypes tag-input uneditable-input span12\" name=\"jobtypes\" tabindex=\"0\"></span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"span4\">\n                <div class=\"control-group\">\n                    <label>With segment</label>\n                    "
    + alias2(__default(require("../helpers/dynamic-picker.js")).call(alias1,"segment",{"name":"dynamic-picker","hash":{},"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":48}}}))
    + "\n                </div>\n                <div class=\"control-group\">\n                    <label>With tree</label>\n                    "
    + alias2(__default(require("../helpers/dynamic-picker.js")).call(alias1,"topicTree",{"name":"dynamic-picker","hash":{},"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":55,"column":50}}}))
    + "\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row-fluid\">\n            <div class=\"span12\">\n                <label class=\"checkbox\" style=\"margin-left: 6px\">\n                    <input name=\"doNotCreateDerivedJobs\" type=\"checkbox\">\n                    Do not create derived jobs (e.g. topics from emotive data)\n                </label>\n            </div>\n        </div>\n\n        <div class=\"row-fluid\">\n            <div class=\"span12\">\n                <label class=\"checkbox\" style=\"margin-left: 6px\">\n                    <input name=\"reprocess\" type=\"checkbox\">\n                    Reprocess data i.e. do not use previous work done by the crowd\n                </label>\n            </div>\n        </div>\n\n        <div class=\"row-fluid\">\n            <div class=\"span12\">\n                <div class=\"control-group\" style=\"margin-bottom: 10px\">\n                    <label>Only send these mention IDs (selection and filter ignored, one per line)</label>\n                    <div class=\"controls\">\n                        <textarea name=\"mentionIds\" placeholder=\"181042-1429037\" style=\"width:100%; box-sizing: border-box; height:100%;\"></textarea>\n                    </div>\n                    <label>IDs for brands not in this account will be ignored</label>\n                </div>\n            </div>\n        </div>\n\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"row-fluid\">\n            <div class=\"span12\">\n                <div class=\"control-group\">\n                    <label class=\"control-group-colour\">Crowd priority</label>\n                    <div class=\"controls btn-group crowd-priority\">\n                        <button class=\"btn\" data-id=\"NORMAL\">Normal</button>\n                        <button class=\"btn\" data-id=\"RETROSEND\">Retrosend</button>\n                        <button class=\"btn\" data-id=\"LOW\">Low</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row-fluid\" style=\"margin-top: 10px\">\n        <div class=\"span12\">\n            <div class=\"control-group\">\n                <div class=\"controls\">\n                    <label class=\"checkbox\"><input type=\"checkbox\" name=\"allBrands\">\n                        Send mentions for "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"brands_") : depth0), depth0))
    + "\n                    </label>\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row-fluid essentials-warning\" style=\"margin-top: 10px\">\n        <div class=\"span12\">\n            <div class=\"alert\"><i class=\"icon icon-warning\"></i> "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"essentialsWarning") : depth0), depth0))
    + "</div>\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>Send mentions to the DataEQ Crowd</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"row-fluid\">\n        <div class=\"span12\">\n            <p>Do you want to send "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"sel") : depth0)) != null ? lookupProperty(stack1,"total") : stack1), depth0))
    + " mention(s) to The DataEQ Crowd?</p>\n        </div>\n    </div>\n    <div class=\"row-fluid\">\n        <div class=\"span12\">\n            <div class=\"control-group\">\n                <label>What is the reason for this retrosend?</label>\n                <div class=\"controls\">\n                    <textarea class=\"reason\" placeholder=\"Reason for retrosend\" style=\"width:100%; box-sizing: border-box; height:100%;\"></textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"assignMentionToCrowd",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":90,"column":18}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"changeCrowdPriority",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":4},"end":{"line":105,"column":18}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brands_") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":4},"end":{"line":119,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"essentialsWarning") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":4},"end":{"line":127,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"row-fluid\" style=\"margin-top: 10px\">\n        <div class=\"span12\">\n            <ul class=\"progress-msg\"></ul>\n        </div>\n    </div>\n\n    <div class=\"dialog-button-bar\">\n        <a class=\"cancel btn\">Cancel</a>\n        <a class=\"ok btn btn-primary\">Send</a>\n    </div>\n</div>\n\n\n";
},"useData":true});