/**
 * A module for showing a chart legend.
 */
Beef.module("SearchJobChartLegend").addInitializer(function() {

    this.View = Backbone.Marionette.ItemView.extend({

        attributes: {
            class: "chart-legend"
        },

        template: require("@/historical-search/chart/SearchJobChartLegend.handlebars"),

        /**
         * Updates the current view's model properties, and updates the view.
         */
        update: function(chart, data) {
            this.model.set({ chart: chart, data: data }, { silent: true });
            this.render();
        },

        /**
         * Clears the chart polling.
         */
        onClose: function() {
            // TODO would be better to put this in the SearchJobChart and HistoricalSearchOverview modules
            var chart = this.model.get("chart");
            if (chart) {
                chart.clearPoll();
            }
        }
    });
});
