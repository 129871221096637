<template>
    <div class="fb-select-profile-type">
        <h4>Select the type of <i class="symbol-facebook-rect"/> Facebook page you would like to add</h4>

        <div class="fb-select-profile-type__profile-type-container">
            <div class="btn fb-select-profile-type__profile-btn" @click="selectPageType(addProfileTypes.facebook.PUBLIC)">
                <i class="icon-users"></i>
                Public Page
            </div>
            <p class="fb-select-profile-type__profile-description">
                You will receive public posts by the page and comments that are anonymised.
            </p>
        </div>

        <div class="fb-select-profile-type__divider"></div>

        <div class="fb-select-profile-type__profile-type-container">
            <div class="btn fb-select-profile-type__profile-btn" @click="selectPageType(addProfileTypes.facebook.MANAGED)">
                <i class="icon-lock-1"></i>
                Managed Page
            </div>
            <p class="fb-select-profile-type__profile-description">
                You will receive public posts by the page, comments, and direct messages dependent on Facebook privacy settings.
                You can also add <span><i class="symbol-instagram"></i>Instagram Business profiles</span> that are connected to your managed Facebook pages in order to collect public data from them.
            </p>
        </div>

        <div class="fb-select-profile-type__divider"></div>

        <div class="fb-select-profile-type__profile-type-container">
            <div class="btn fb-select-profile-type__profile-btn" @click="selectPageType(addProfileTypes.facebook.INFLUENCER)">
                <i class="icon-link"></i>
                Influencer Page
            </div>
            <p class="fb-select-profile-type__profile-description">
                Generate Facebook login links which can be used to authorise influencer profiles.
            </p>
        </div>
    </div>
</template>
<script>
import { addProfileMixins } from "@/setup/profiles/add-profile/AddProfileMixins";

export default {
    name: "FbSelectProfileType",
    mixins: [addProfileMixins],

    methods: {
        selectPageType(pageType) {
            this.$emit('select-page', pageType);
        }
    }
}
</script>

<style scoped lang="sass">

.fb-select-profile-type

    h4
        margin-bottom: 40px

    p
        color: var(--be-colour-text-dark)

    &__profile-type-container
        display: flex
        align-items: center

    &__profile-btn
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        border-radius: 6px
        width: 150px
        height: 100px
        padding: 5px

        i
            font-size: 2em
            margin: 5px 0


    &__profile-description
        margin-left: 20px
        width: 75%

        span
            color: white
            font-weight: bold

    &__divider
        margin: 15px 0
        width: 100%
        border-bottom: 1px solid #666

</style>