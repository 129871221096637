import {editDashboards} from "@/app/Permissions";
import { isCampaignDashboardLink } from "@/dashboards/DashboardUtils";

Beef.module("SectionList").addInitializer(function(startupOptions) {

    const asEmail = startupOptions.asEmail  // dashboard is being rendered for images for email by trotter
    if (asEmail) {
        // trotter uses this to advance between sections .. returns true if there are no more slides
        window.beNextSlide = function() {
            return window.beSectionList && window.beSectionList.nextSection()
        }
        window.beIsSlideReady = function () {   // true if current section has finished rendering
            return window.beSectionList && window.beSectionList.getFsSectionView().isWidgetsCompleted()
        }
    }

    this.View = Backbone.Marionette.CompositeView.extend({
        template: require("@/dashboards/SectionList.handlebars"),

        itemViewContainer: ".dashboard-section-list",

        itemView: Beef.Section.View,

        initialize: function() {
            this.collection.view = this;
            // if we are only showing one dashboard via public share link we start in presentation mode
            this.fsSectionIndex = startupOptions.onlyShowDashboardId ? 0 : null;
        },

        templateHelpers: function() {
            var dashboard = this.collection.owner;
            return {
                editable: dashboard.isEditable(),
                editDashboards: editDashboards()
            }
        },

        onRender: function() {
            // make this section globally available for the global slideshow controls used by trotter to image
            // each section when rendering a dashboard for email
            if (asEmail) {
                window.beSectionList = this
                this.$(".section:first-child").toggleClass("active", true)
            }

            // Here we delay setting the scroll position until things have had a chance to lay themselves out.
            // If we don't do this, the scroll bar might not yet have set itself to the correct min / max size
            // to scroll to the needed position.
            setTimeout(function() {
                if (!isCampaignDashboardLink()) {
                    var scrollPosition = Beef.sessionData().get(this.getScrollSessionKey());
                    if (scrollPosition) $(document).scrollTop(scrollPosition);
                }

                if (!this.boundRecordScroll) this.boundRecordScroll = this.recordScroll.bind(this);
                $(document).scroll(this.boundRecordScroll);
            }.bind(this));
        },

        itemViewOptions: function() { return { cache: this.cache } },

        events: {
            "click .add-section": "addSection",
            "keydown": "onKeyDown"
        },

        appendHtml: function(compositeView, itemView, index){
            if (compositeView.isBuffering) {
                compositeView.elBuffer.appendChild(itemView.el);
                compositeView._bufferedChildren.push(itemView);
            }
            else {
                let $container = this.getItemViewContainer(compositeView)
                if (!$container.length || typeof index !== 'number') {
                    $container.append(itemView.el)
                } else {
                    let $pos = $container.find("> div:nth-child(" + index +")")
                    $pos.after(itemView.el)
                }
            }
        },

        recordScroll: function() {
            Beef.sessionData().set(this.getScrollSessionKey(), $(document).scrollTop());
        },

        getScrollSessionKey: function() {
            if (this.collection && this.collection.owner) {
                return 'dashboard-' + this.collection.owner.get('id') + '-scroll-top';
            }
            return 'dashboard-scroll-top';
        },

        onClose: function() {
            if (this.boundRecordScroll) $(document).off("scroll", this.boundRecordScroll);
        },

        addSection: function(ev) {
            if (ev) ev.stopPropagation();
            var id = this.collection.max(function(o) { return o.id } );
            id = id < 0 ? 1 : id.id + 1;
            var section = new Beef.Dashboard.SectionModel({
                id: id,
                title: "Section " + id,
                widgets: [Beef.Widget.BrandSelector.createDefaultWidget(1)]
            });
            this.lastItemViewAdded = null;
            this.collection.add(section);
            var iv = this.lastItemViewAdded;
            if (iv) {
                // get rid of the new section if the user doesn't click ok in the dialog
                iv.edit(function(ok) {
                    if (!ok) this.collection.remove(section);
                }.bind(this));
            }
        },

        /**
         * Ask all child sections to refresh themselves, pulling fresh data from grouse and redrawing as necessary.
         */
        refresh: function() {
            this.children.each(function(sectionView) {
                sectionView.refresh();
            });
        },

        /**
         * Ask child sections to relayout their widgets as-if the browser size has changed.
         */
        layout: function() {
            this.children.each(sectionView => sectionView.layout())
        },

        onAfterItemAdded: function(itemView) {
            this.lastItemViewAdded = itemView;
        },

        getFsSectionView: function() {
            return this.fsSectionIndex !== null ? this.collection.models[this.fsSectionIndex].view : null;
        },

        viewFullscreen: function(sectionIndex, presentation, options) {
            options = options || {}
            if (!presentation && !BigScreen.enabled) return window.alert("Fullscreen mode is not supported in your browser");
            var that = this;
            let enter = function() {
                let $doc = $(document.documentElement);
                $doc.toggleClass("fullscreen", true);
                $doc.toggleClass("email", !!options.email);
                if (options.bodyClass) $doc.toggleClass(options.bodyClass, true);
                that.fsSectionIndex = sectionIndex;
                that.getFsSectionView().onEnterFullscreen();
            }
            let exit = function() {
                let $doc = $(document.documentElement);
                $doc.toggleClass("fullscreen", false);
                $doc.toggleClass("email", false);
                if (options.bodyClass) $doc.toggleClass(options.bodyClass, false);
                that.fsSectionIndex = null;
                if (presentation) that.layout()
            }
            if (presentation) {
                enter()
                $(document.documentElement).on("keydown", ev => {
                    if (ev.keyCode === 27) {
                        exit()
                        $(document.documentElement).off("keydown")
                    }
                })
            } else {
                BigScreen.request(document.documentElement, enter, exit,
                    function(element, reason) {    // error
                        window.alert("Unable to enter fullscreen mode: " + reason)
                    })
            }
        },

        moveToSection: function(delta) {
            if (!this.collection.length || this.fsSectionIndex === null) return;
            var currentSection = this.getFsSectionView();
            var i = this.fsSectionIndex + delta;
            if (i < 0) i = this.collection.length - 1;
            else if (i >= this.collection.length) i = 0;
            this.fsSectionIndex = i;
            var nextSection = this.getFsSectionView();
            if (currentSection._animationState) {
                currentSection.stopSectionAnimation();
                nextSection.startSectionAnimation(true);
            }
            nextSection.$el[0].scrollIntoView({behavior: asEmail ? "auto" : "smooth", block: "start", inline: "nearest"});
            nextSection.ensureWidgetViews(); // this is for asEmail slide images as the widget scroll listener will not have created the view
            nextSection.refreshIfStale();
        },

        nextSection: function(ev) {
            // log: function(msg) {
            //     let sm = this.model.getSectionModel()
            //     console.log(sm.get('title') + " " + this.model.id + ": " + msg + " _completed " +
            //         this.model.generalData.get('_completed'))
            // },

            let old = this.fsSectionIndex
            this.moveToSection(+1)
            //console.log("nextSection [" + this.getFsSectionView().model.get('title') + "]")
            if (asEmail) {
                this.$(".section").toggleClass("active", false)
                this.getFsSectionView().$el.toggleClass("active", true)
            }
            return this.fsSectionIndex <= old    // true if we have completed the last section and wrapped around
        },

        prevSection: function(ev) {
            this.moveToSection(-1);
        },

        onKeyDown: function(ev) {
            if (!Beef.Dashboard.isPresentationMode()) return;
            var delta;
            if (ev.keyCode == 37 /*left*/ ) delta = -1;
            else if (ev.keyCode == 39 /*right*/) delta = +1;
            if (delta) {
                var that = this;    // have to delay the move or it doesn't work .. don't know why
                setTimeout(function() { that.moveToSection(delta, true) }, 1);
            }
        }
    });

});