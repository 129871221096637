<template>
    <div class="deq-reset brand-picker__root" :data-root-selected="brand.selected" tabindex="-1">
        <tooltip-component>
            <span class="brand-picker__brand"
                  :class="{
                        'brand--active': brand.selected,
                        'deq-disabled': !brand.visible,
                        'brand--matches': !!searchText && !!brand.visible,
                        'brand--excluded': brand.excluded,
                    }"
                  @keydown.enter.stop="selected(brand)"
                  @click="selected(brand)"
                  :tabindex="brand.visible ? 0 : -1">
                <animated-check v-if="brand.selected && !brand.excluded" animated/>
                <span v-if="allowExcludeRoot && brand.selected && brand.excluded" class="brand__excluded"></span>

                <span class="brand-picker__brand-name">
                    {{ formatBrandName(brand.brand, {markArchiveDuplicates: false}) }}
                </span>

                <tooltip-component v-if="allowExcludeRoot && !brand.excluded">
                    <span class="brand-picker__negate" @click.stop="negate(brand)"></span>
                    <template #tooltip>
                        Click to exclude this brand
                    </template>
                </tooltip-component>
            </span>
            <template #tooltip>
                <span v-if="!brand.selected && !brand.excluded">
                    Click to select this brand
                </span>
                <span v-else-if="brand.excluded">
                    This brand has been excluded. Click to undo.
                </span>
                <span v-else>
                    This brand is selected. Click to deselect it.
                </span>
            </template>
        </tooltip-component>
        <div v-if="showChildren && getChildren(brand).length">
            <div class="brand-picker__notch"></div>
            <div class="brand-picker__border"></div>
        </div>
        <div v-if="showChildren && getChildren(brand).length"
             class="brand-picker__children dark-scrollbars dark-scrollbars--visible">


            <div v-for="c in getChildren(brand)"
                 class="brand-picker__brand brand--child"
                 :class="{
                    'brand--active': c.selected,
                    'deq-disabled': !c.visible,
                    'brand--excluded': c.excluded,
                    'child--excluded': excludeChildren,
                    'brand--matches': !!searchText && !!c.visible
                 }"
                 :data-indent="c.indent"
                 :key="c.brand.id"
                 :tabindex="c.visible ? 0 : -1"
                 @keydown.enter.stop="selected(c)"
                 @click="selected(c)">

                <tooltip-component>
                    <animated-check v-if="c.selected && !c.excluded" animated/>
                    <span v-if="c.selected && c.excluded" class="brand__excluded"></span>

                    <span class="brand-picker__brand-name">{{ formatBrandName(c.brand) }}</span>
                    <template #tooltip>
                        <span v-if="!c.selected && !c.excluded">
                            Click to select this sub-brand.
                        </span>
                        <span v-else-if="c.excluded">
                            This sub-brand has been excluded. Click to undo.
                        </span>
                        <span v-else>
                            This sub-brand has been selected. Click to deselect.
                        </span>
                    </template>
                </tooltip-component>

                <tooltip-component v-if="!c.excluded">
                    <span class="brand-picker__negate" @click.stop="negate(c)"></span>
                    <template #tooltip>
                        Click to exclude this sub-brand
                    </template>
                </tooltip-component>

                <tooltip-component>
                    <button v-if="c.children" class="child__expand-button" @click.stop.prevent="expand(c)" tabindex="-1">
                        <collapsable-caret :collapsed="!c.expanded"/>
                    </button>
                    <template #tooltip>
                        <span v-if="!c.expanded">
                            Click to see children
                        </span>
                        <span v-else>
                            Click to hide children
                        </span>
                    </template>
                </tooltip-component>

            </div>

        </div>
    </div>
</template>

<script>

import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import { formatBrandName } from "@/app/utils/Format";
import CollapsableCaret from "@/components/CollapsableCaret";
import TooltipComponent from "@/components/tooltip/TooltipComponent";

export default {
    components: {TooltipComponent, CollapsableCaret, AnimatedCheck},
    props: {
        brand: {
            type: Object,
            required: true
        },
        showChildren: {
            type: Boolean,
            default: false
        },
        excludeChildren: {
            type: Boolean,
            default: false
        },
        allowExcludeRoot: {
            type: Boolean,
            default: false
        },
        searchText: { type: String }
    },

    methods: {
        formatBrandName,

        selected(brand) {
            this.$emit("selected", brand);
        },

        negate(brand) {
            this.$emit("negate", brand);
        },

        expand(brand) {
            brand.expanded = !brand.expanded;
        },

        getChildren(brand) {
            if (!brand.children?.length) return [];

            const collect = d => {
                if (!d.expanded) return d;
                return [d, ...d.children.flatMap(collect)];
            };

            return brand.children.flatMap(collect);
        }
    }
}


</script>


<style scoped lang="sass">

.brand-picker__root
    --third-height: calc(var(--min-height) / 3)
    --border: thin solid black
    --border-separation-colour: var(--background-menu-hover)
    --left-padding: 24px
    grid-column: span 3
    display: grid
    grid-template-columns: minmax(100px, 250px) 20px auto // For chrome
    //grid-template-columns: subgrid // subgrid seems to be broken in chromium >= 117. See if this works in the future
    grid-auto-rows: minmax(var(--min-height), auto)
    margin-bottom: auto

.brand-picker__brand
    display: flex
    align-items: center
    gap: 3px
    cursor: pointer
    width: 100%
    margin-block: auto
    min-height: var(--min-height)
    padding: 0 5px 0 var(--left-padding)
    border: 1px solid transparent

    &.child--excluded:not(:hover):not(.brand--active)
        font-style: italic
        color: var(--colour-disabled)


    &:not(.deq-disabled):hover
        background: var(--background-menu-hover)
        color: var(--colour-menu-hover)
        font-style: revert


    &.deq-disabled
        cursor: default


.brand-picker__brand:focus-visible
    border: var(--focus-border)

.brand-picker__brand:focus
    outline: none

:where(.brand--active)
    background: var(--colour-active-gradient)
    color: var(--colour-menu-active)

.brand--matches
    text-decoration: underline dotted
    .brand-picker__brand-name
        color: var(--strong-colour-dark)
        text-decoration-color: currentColor


:is(.brand--active + .brand--active),
:where([data-root-selected] + [data-root-selected]) > .brand--active
    border-top: thin solid var(--border-separation-colour)

.brand-picker__notch
    display: inline-block
    height: 50%
    width: 10px
    border-top: var(--border)

.brand-picker__border
    display: inline-block
    border-left: var(--border)
    border-top: var(--border)
    border-bottom: var(--border)
    margin-block: var(--third-height)
    height: calc(100% - var(--third-height) * 2)
    width: calc(100% - 10px)

.brand__excluded
    display: inline-block
    height: 16px
    aspect-ratio: 1
    background: var(--be-animated-check-background)
    border-radius: 50%
    color: var(--be-colour-negated)

.brand__excluded::after
    content: '–'
    position: relative
    color: indianred
    display: flex
    place-content: center
    line-height: 100%
    left: 0.8px
    text-align: center

:where(.brand--excluded)
    background: var(--be-gradient-negated)
    color: var(--colour-menu-active)
    --border-separation-colour: hsl(var(--be-colour-negated-hs) 60% / 0.4)

.brand--excluded
    border-left-color: var(--be-background-negated)

.brand--excluded:not(.deq-disabled):hover
    background: var(--be-gradient-negated-hover)
    border-left-color: var(--background-menu-hover)

.brand--active
    padding-left: 5px
    --indent-offset: -19px /* For making space for the check button. */

.brand--child:first-of-type
    margin-top: calc(var(--third-height) * 2)

.brand--child:last-of-type
    margin-bottom: calc(var(--third-height) * 2)

.brand--child[data-indent]
    padding-left: calc(3ch * var(--indent, 1) + var(--indent-offset, 0px))

.brand--child[data-indent="1"]
    --indent: 1

.brand--child[data-indent="2"]
    --indent: 2

.brand--child[data-indent="3"]
    --indent: 3

.brand-picker__negate
    margin-left: auto
    opacity: 0
    text-align: center
    border-radius: 3px
    aspect-ratio: 1
    height: 20px

    &::after
        content: '–'
        position: relative
        display: flex
        place-content: center

.brand-picker__brand:hover .brand-picker__negate
    transition: opacity 250ms 500ms
    opacity: 1

.brand-picker__brand .brand-picker__negate:hover
    transition: unset
    background: var(--sidebar-background)
    opacity: 1

.brand--active .brand-picker__negate
    transition: unset
    opacity: 1

.brand-picker__brand.deq-disabled .brand-picker__negate
    opacity: 0

.child__message
    padding-left: var(--left-padding)

.child__expand-button
    margin: 0
    padding: 0
    background: transparent
    border: none
    color: var(--be-button-link-colour)

    aspect-ratio: 1
    text-align: center
    border-radius: 3px

    &:hover
        background: var(--sidebar-background)
        color: white

.brand--active .child__expand-button
    color: white

.brand-picker__brand:hover .child__expand-button
    color: white

.brand--excluded .child__expand-button
    margin-left: auto
    color: var(--be-colour-text)
    &:hover
        color: white


button:focus-visible
    outline: var(--focus-border)


</style>