var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                        Select an option\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>Send a query to DataEQ support</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"header-and-message\">\n        <div class=\"row-fluid replying-as\">\n            <span class=\"interact-label\">From</span>\n            <div class=\"text-box\">\n                <span class=\"text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userEmail") : depth0), depth0))
    + "</span>\n            </div>\n        </div>\n\n        <div class=\"row-fluid subject-box\">\n            <span class=\"interact-label\">Query</span>\n            <div class=\"text-box dark query\">\n                <span class=\"text\">\n"
    + ((stack1 = __default(require("../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"category") : depth0),"&&",((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"label") : stack1),{"name":"ifcond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":24,"column":31}}})) != null ? stack1 : "")
    + "                </span>\n                <span class=\"caret pull-right\"></span>\n            </div>\n        </div>\n\n        <div class=\"row-fluid subject-box\">\n            <div class=\"subject\">\n                <div class=\"control-group\">\n                    <div class=\"controls\">\n                        <span class=\"interact-label\">Subject</span>\n                        <span class=\"subject-text\">\n                            <input dir=\"auto\" type=\"text\"\n                                   name=\"title\"\n                                   id=\"ticket-title\"\n                                   class=\"ticket-title message-subject\"\n                                   placeholder=\"Start typing a subject here\"\n                                   maxlength=\"50\">\n                        </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row-fluid\">\n            <div class=\"control-group\">\n                <div class=\"controls\">\n                    <textarea name=\"description\"\n                              id=\"ticket-description\"\n                              class=\"message ticket-description\"\n                              placeholder=\"Describe your issue or query here. If something went wrong, help us troubleshoot by explaining what you were trying to do, and what happened instead.\"\n                              maxlength=\"800\">\n                    </textarea>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row-fluid email-disclaimer\">\n        A copy of this ticket will be sent to <em>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userEmail") : depth0), depth0))
    + "</em>.\n    </div>\n\n    <div class=\"row-fluid\">\n        <div class=\"dialog-button-bar\">\n            <div class=\"pull-right\">\n                <a class=\"btn cancel\" title=\"Discard this message (esc)\">Cancel</a>\n                <a id=\"send-ticket\" class=\"btn btn-primary send disabled\" title=\"Send a support request\">\n                    <span class=\"send-label\">Send</span>\n                    <span class=\"sending-label\"><span class=\"spinner-dark\"></span>Sending ...</span>\n                </a>\n            </div>\n        </div>\n    </div>\n\n</div>\n";
},"useData":true});