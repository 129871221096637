import moment from "moment";

const compareMentionsByPublishedDate = (a, b) => {
    const aMoment = new moment(a.published);
    const bMoment = new moment(b.published);
    if (aMoment.isBefore(bMoment)) return -1;
    if (bMoment.isBefore(aMoment)) return 1;
    return 0;
}

const compareMentionsByReplyTo = (a, b) => {
    if (a.replyToId === b.id || a.reshareOfId === b.id) return 1;
    if (b.replyToId === a.id || b.reshareOfId === a.id) return -1;
    return compareMentionsByPublishedDate(a, b);
}

export { compareMentionsByPublishedDate, compareMentionsByReplyTo };