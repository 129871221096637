import {deprecatedFetchTags} from "@/data/DeprecatedBeefCache";
import _ from 'underscore';

Beef.module("Widget.DevDiscoveredTopics.Helpers").addInitializer(function(startupOptions) {

    this.ChartView = Beef.ChartView.extend({
        x: function(d) {
            var language = this.model.get("language") || 'en';
            var labels = d.labels || (d.tag && d.tag.labels);
            if (labels && labels[language]) return labels[language];
            if (d.topicName) return d.topicName;
            return d.topic;
        },

        tooltipObject: 'topics',
        percentageDataAxisLabel:    {long: "Percentage of Topics", short: "% of Topics"},
        tooltipVerb: 'have the topic',
        tooltipAveOtsVerb: 'with topic',

        tooltip: function(event){
            deprecatedFetchTags(this, function(items) {
                var topicId = parseInt(event.point.topic),
                    tag = items[topicId],
                    description = null,
                    model = Beef.Tooltip.chartViewDefaultDataHandler.call(this, this.chart, event);
                var overflowSize = 3,
                    language = this.model.get('language') || 'en';

                if (tag) {
                    if (event.point.descriptions && event.point.descriptions[language]) {
                        description = event.point.descriptions[language];
                    }
                    if (!description) {
                        description = tag && tag.description || null;
                    }
                    if (language === 'en' && description && !description.match("\\.\\?!\"\'\s*$")) {
                        description += '.';
                    }
                    model.set('description', description);
                    model.set('created', tag.created);
                    if (tag._parent) {
                        model.set('parent', tag._parent.name);
                    }

                    if (tag && tag.children && tag.children.length) {
                        var children = _(tag.children)
                            .chain()
                            .filter(function (id) {
                                return !!items[id]
                            })
                            .take(overflowSize)
                            .map(function (id) {
                                return items[id].name
                            })
                            .value();

                        model.set('children', children);

                        if (tag.children.length > overflowSize) {
                            model.set('childrenOverflow', true);
                        }
                    }
                }

                // The chart may be closed by this point. Ensure we don't try to show the tooltip.
                if (!this.isClosed) {
                    // Beef.Tooltip.show({
                    //     template: require("@/dashboards/widgets/topics/AllTopicsTooltip.handlebars"),
                    //     positions: ['top-right', 'top-left', 'bottom-left', 'bottom-right'],
                    //     target: event.e.target,
                    //     autoclose: true,
                    //     model: model
                    // })
                }
            }.bind(this));
        },


        preTransform: function(data) {
            if (!this.model.has('hide-parents')) {
                this.model.set('hide-parents', true);
            }

            var hideParents =  this.model.get("hide-parents");
            var hideChildren = !!this.model.get("hide-children");

            if (hideParents && hideChildren) return [];

            var result = data.map(function(series) { return Object.assign({}, series) });

            if (hideParents || hideChildren) {
                for (var i = 0; i < result.length; i++) {
                    var series = result[i];
                    if (!series) continue;
                    series.values = series.values?.filter(function(d) {
                        return (hideParents && !!d.topicIsLeaf) || (hideChildren && !d.topicIsLeaf);
                    }) ?? [];
                }
            }

            return result;
        }
    });
});

