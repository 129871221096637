
export class FontColorEl extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback(){
        this.style.color = this.dataset.color;
    }

}

customElements.define("font-color", FontColorEl);
