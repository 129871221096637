import {isMashAdmin} from "@/app/Permissions";
import {setTitle} from "@/app/Beef";
import CrowdHistory from "@/setup/crowd/CrowdHistory";
import MachineSetup from "@/setup/crowd/MachineSetup.vue";

Beef.module("CrowdSetup").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.Layout.extend({
        template: require("@/setup/crowd/CrowdSetup.handlebars"),

        attributes: { class: "crowd-setup" },

        regions: {
            crowdSettingsRegion: ".crowd-settings-region",
            machineSentimentRegion: ".machine-sentiment-region",
            crowdHistoryRegion: ".crowd-history-region"
        },

        initialize: function() {
            this.accountCode = this.model.get('accountCode');
            setTitle('Crowd Setup')
        },

        templateHelpers: {
            title: "Crowd Verification"
        },

        onRender: function() {
            if (!this.crowdSettings) this.crowdSettings = Beef.CrowdSettings.createView(this.model.get('accountCode'));
            this.crowdSettingsRegion.show(this.crowdSettings);

            if (isMashAdmin()) {
                if (!this.crowdHistory) this.crowdHistory = new Beef.VuejsView.View({component: CrowdHistory});
                this.crowdHistoryRegion.show(this.crowdHistory);

                if (!this.machineSentiment) this.machineSentiment = new Beef.VuejsView.View({component: MachineSetup});
                this.machineSentimentRegion.show(this.machineSentiment);
            }
        }

    });

});

