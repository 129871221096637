/**
 * This is a useful base class when you need to delay rendering until various aspects
 * of a container have rendered so that the amount of remaining space can be determined.
 *
 * <p>
 * Your rendering should take place in a function called 'renderImpl'. This will be called when the model changes.
 *
 * <p>
 * You may also override setup(), which will be called before the first render.
 */

import _ from 'underscore';

Beef.ChartViewHelper = Backbone.Marionette.View.extend({

    modelEvents: {
        "change": "modelChanged"
    },

    initialize: function() {
        this.listenTo(this.model.generalData, "change:_data", this.render, this);
    },

    modelChanged: function() {
        var changes = this.model.changedAttributes();

        // don't render if only width and height have changed - this change is handled by ChartItemView
        // don't render if an underscore field has changed unless it is _data as those don't change the graph
        var shouldRender = _.find(changes, function(value, key) {
            // the changes map includes lots of "undefined" values so check for that
            return value !== undefined && key != "width" && key != "height" &&
                   (key.charAt(0) != '_' || key == "_data") && key != 'hide-labels';
        });

        if (shouldRender !== undefined) {
            // give footnotes etc. a chance to draw so our size will be correct
            setTimeout(function(){
                this.render();
            }.bind(this));
        }
        else {
            // Showing and hiding of labels are handled somewhat seperately to everything else to keep this
            // function not feeling delayed. This means that it could possibly occur that we should not be
            // rendering anything, but want to just update the labels.
            var keys = _(changes).keys();
            if (_(keys).contains('hide-labels')) {
                if (this.chart && this.model.get('hide-labels')) {
                    this.chart.labels().hide();
                }
                else {
                   this.chart.labels().show();
                }
            }
        }
    },

    render: function() {
        // Need to ensure that our charts don't have a fixed width and height
        // so that we can properly read the component size. If the svg is larger
        // than the component should be, we will never be able to shrink the component.
        this.$('svg').css("width", "100%");
        this.$('svg').css("height", "100%");
        var w = this.$el.width();
        var h = this.$el.height();

        if (w == 0 || h == 0) {
            // this must be _.delay and not _.defer or renderImpl doesn't get the height of the draw area
            setTimeout(function() { this.renderImpl() }.bind(this), 1);
        } else {
            if (!this.firstRender) {
                if (this.setup) this.setup();
                this.firstRender = true;
            }
            this.renderImpl();
        }
    },

    renderImpl: null,
    setup: null
});