/**
 * Dialog to generates a list of phrasse from the cross join of two lists of words.
 */
import {mergePhraseLists} from "@/app/utils/Phrases";

Beef.module("PhraseGen").addInitializer(function(startupOptions) {

    /**
     * Show the generate dialog. An array of generated phrases is passed to callback.
     */
    this.show = function(callback) {
        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
        popup.setTarget($('body'));
        popup.show(new DialogView({model: createModel(), callback: callback}));
    };

    this.createView = function() {
        return new View({model: createModel()});
    };

    var createModel = function() {
        var json = localStorage.getItem('PhraseGen.model');
        var attrs = json ? JSON.parse(json) : {};
        return new Backbone.Model(attrs);
    };

    var View = Beef.BoundItemView.extend({

        template: require("@/dashboards/filter/PhraseGen.handlebars"),

        className: "phrase-gen",

        bindings: function() {
            return { }
        },

        events: {
            "click .cancel":    "close",
            "click .ok":        "ok"
        },

        modelEvents: {
            "change": "onModelChange"
        },

        onFirstRender: function() {
            var that = this;
            setTimeout(function(){ that.$("textarea").first().focus() });
        },

        onModelChange: function() {
        },

        ok: function() {
            var phrases = this.generate();
            this.close();
            if (phrases.length > 0) {
                this.saveModel();
                this.options.callback(phrases);
            }
        },

        saveModel: function() {
            localStorage.setItem('PhraseGen.model', JSON.stringify(this.model.attributes));
        },

        generate: function() {
            var col1 = toWords(this.model.get('col1'));
            var col2 = toWords(this.model.get('col2'));
            var joinWords = this.model.get('joinWords');

            return mergePhraseLists(col1, col2, joinWords);
        }
    });

    var toWords = function(s) {
        var words = [];
        if (!s) return words;
        var a = s.split("\n");
        for (var i = 0; i < a.length; i++) {
            var word = a[i]; //Beef.PhraseTermPicker.cleanPhraseTerm(a[i], true);
            if (word && word.trim().length > 0 && words.indexOf(word) < 0) words.push(word);
        }
        return words;
    };

    var DialogView = View.extend({
        template: require("@/dashboards/filter/PhraseGenDialog.handlebars"),
        className: "dialog phrase-gen"
    });
});