<template>
    <div class="beef-tooltip">
        <h1>{{network}}</h1>
        <p>
            Collected <strong><deq-number :number="total"/> {{formatPlural(total, 'mention')}}</strong>
            <span v-if="privateCount > 0">
                and <strong><deq-number :number="privateCount"/> private {{formatPlural(privateCount, 'mention')}}</strong>
            </span>
        </p>
    </div>
</template>

<script>
import DeqNumber from "@/components/formatters/DeqNumber";
import {formatPlural} from "@/app/utils/Format";

export default {
    components: {DeqNumber},
    props: {
        network: String,
        total: Number,
        privateCount: Number
    },
    computed: {
        privatePercent() {
            return this.privateCount / this.total;
        }
    },
    methods: {
        formatPlural
    }
}
</script>