<template>
    <div class="rules">
        <div class="row-fluid head title">
            <h1>Rules</h1>
        </div>
        <loading-message v-if="loading" message="Fetching account's rules..."/>
        <div v-else class="rules__container">
            <div class="rules__options-container">
                <p>Rules can be used to edit, delete, or control the Crowd & Engage processing of incoming mentions.</p>

                <div class="rules__options">
                    <search-input class="search-input" :disabled="reorderRulesEnabled" v-model="searchTerm" placeholder="Search for a rule..."></search-input>

                    <div class="button-group">
                        <div v-if="isOps">
                            <be-button v-if="!reorderRulesEnabled" link tooltip="Click here to enable reordering rule priorities via drag and drop" @click="toggleReorderRules">Reorder rules</be-button>
                            <slotted-tag v-else @close="toggleReorderRules">Reorder mode enabled - drag rules to reorder them</slotted-tag>
                        </div>

                        <popup-menu ref="addRule"
                                    fixed
                                    left
                                    :ignore-activator-click="reorderRulesEnabled"
                                    :arrow-pointer="{show: true, left: false}">
                            <template #activator>
                                <be-button primary v-if="!reorderRulesEnabled && !groupByAction" :disabled="reorderRulesEnabled">
                                    <i class="icon-plus-circle"></i> Create rule
                                </be-button>
                            </template>
                            <div class="rules__add-rule-popup-menu short-animated fadeIn">
                                <div class="add-rule-btn" @click="addRule(ruleTypes.tag)">
                                    <div class="add-logo">
                                        <i class="symbol-tags"></i>
                                    </div>
                                    Tag mentions
                                </div>
                                <div class="add-rule-btn" @click="addRule(ruleTypes.edit)">
                                    <div class="add-logo">
                                        <i class="symbol-edit"></i>
                                    </div>
                                    Edit mentions
                                </div>
                                <div class="add-rule-btn" @click="addRule(ruleTypes.delete)">
                                    <div class="add-logo">
                                        <i class="symbol-sorter"></i>
                                    </div>
                                    Delete mentions
                                </div>
                                <div class="add-rule-btn" @click="addRule(ruleTypes.set_relevant)">
                                    <div class="add-logo">
                                        <i class="symbol-checkmark"></i>
                                    </div>
                                    Set relevant mentions
                                </div>
                                <div class="add-rule-btn" @click="addRule(ruleTypes.crowd)">
                                    <div class="add-logo">
                                        <i class="symbol-crowd"></i>
                                    </div>
                                    Crowd verification
                                </div>
                                <div class="add-rule-btn" @click="addRule(ruleTypes.engage)" v-if="canAddEngageRules">
                                    <div class="add-logo">
                                        <i class="icon-equalizer"></i>
                                    </div>
                                    Engage processing
                                </div>
                            </div>
                        </popup-menu>

                        <popup-menu ref="ruleOptions"
                                    fixed
                                    left
                                    ignore-doc-click
                                    :ignore-activator-click="reorderRulesEnabled"
                                    :arrow-pointer="{show: true, left: false}">
                            <template #activator>
                                <be-button @click="showOptions = true" :disabled="reorderRulesEnabled">
                                    Options <i class="caret" style="margin-left: 5px;"/>
                                </be-button>
                            </template>
                            <div v-if="showOptions">
                                <div class="rules__options-popup-menu short-animated fadeIn">
                                    <ul>
                                        <li class="rules__popup-menu-header">Show Rules</li>
                                        <li v-for="show in showRuleTypeOptions" :key="show.label">
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="show.value">
                                                {{ show.label }}
                                            </label>
                                        </li>
                                        <li v-if="!groupByAction">
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="showInactive">
                                                Inactive rules
                                            </label>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li class="rules__popup-menu-header">Show Columns</li>
                                        <li v-for="showColumn in showColumnOptions" :key="showColumn.label">
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="showColumn.visible">
                                                {{ showColumn.label }}
                                            </label>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li class="rules__popup-menu-header">Other</li>
                                        <li>
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="groupByAction">
                                                Group by action
                                            </label>
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="combineTaggingRules">
                                                Combine tagging rules
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </popup-menu>
                    </div>
                </div>
            </div>

            <div>
                <div v-if="groupByAction">
                    <div v-if="searchTerm && !groupedRulesFoundInSearchResults">
                        <no-topics-message class="rules__no-rules-found-message">No rules found in search results</no-topics-message>
                    </div>
                    <div v-else>
                        <div v-for="group in groups" :key="group.name" class="rules__rule-group-container">
                            <div v-if="showGroup(group)">
                                <div class="rules__rule-group-header">
                                    <div v-if="groupHasRules(group)">
                                        <h4 @click="group.visible = !group.visible" class="can-click"><i :class="group.visible ? 'icon-down-dir' : 'icon-right-dir'"></i> {{ group.name }}</h4>
                                        <div v-if="group.visible">
                                            <p v-html="group.description"/>
                                            <div class="rules__rule-group-controls">
                                                <be-button v-if="canCreateRule(group.type)" primary @click="addRule(group.type)">
                                                    <i class="symbol-add"></i> Create new rule
                                                </be-button>
                                                <label>
                                                    <input type="checkbox" v-model="group.hideInactive">
                                                    <span>Hide inactive rules</span>
                                                </label>

                                                <popup-menu ref="ruleWarningsList" v-if="getTotalRulesWithWarnings(group)" fixed :arrow-pointer="{show: true, left: true}">
                                                    <template #activator>
                                                        <label class="rule-warning-text">
                                                            <i class="symbol-warning"></i> {{ getTotalRulesWithWarnings(group) }} {{ formatPlural(getTotalRulesWithWarnings(group), 'rule has warnings', 'rules have warnings') }}
                                                        </label>
                                                    </template>
                                                    <div class="rules__rule-warning-list dark-scrollbars dark-scrollbars--visible short-animated fadeIn">
                                                        <div v-for="ruleItem in groupedRules[group.name]" :key="ruleItem.topLevelRule.id">
                                                            <div class="warning-item" v-if="ruleItem.warnings.length" @click="scrollToRule(ruleItem.topLevelRule)">
                                                                Rule <strong>{{ ruleItem.topLevelRule.name }}</strong> has {{ ruleItem.warnings.length }} {{ formatPlural(ruleItem.warnings.length, 'warning') }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </popup-menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <h4>{{ group.name }}</h4>
                                        <p v-html="group.description"/>
                                    </div>
                                </div>
                                <div v-if="group.visible">
                                    <div v-if="groupHasRules(group)">
                                        <div class="rules__row-header" :style="gridColumnStyle">
                                    <span v-for="column in visibleColumns"
                                          :key="column.label"
                                          :style="reorderRulesEnabled ? 'pointer-events: none': ''"
                                          @click="sortRules(column, false, null, group)">
                                        {{ column.label }} <i v-show="group.sortedOnColumn === column.label"
                                                              :class="group.sortOrder === 'ascending' ? 'icon-up' : 'icon-down'"></i>
                                    </span>
                                        </div>
                                        <div class="rules__rule-grouped-list dark-scrollbars dark-scrollbars--visible">
                                            <setup-rules-row v-for="ruleItem in filteredGroupedRules[group.name]"
                                                             class="rules__rule-item"
                                                             :ref="'rule-row-' + ruleItem.topLevelRule.id"
                                                             :key="ruleItem.topLevelRule.id"
                                                             :rules="ruleItem.rules"
                                                             :warnings="ruleItem.warnings"
                                                             :rules-types-to-show="showRuleTypeOptions"
                                                             :show-inactive="!group.hideInactive"
                                                             :filter-visible="filterVisible"
                                                             :priority-visible="priorityColumn.visible"
                                                             :grid-column-style="gridColumnStyle">
                                            </setup-rules-row>
                                        </div>
                                    </div>
                                    <dotted-card v-else-if="canCreateRule(group.type)" class="rules__dotted-card" @click="addRule(group.type)">
                                        <h1>Create new rule</h1>
                                        <p>
                                            {{ group.createDescription }}
                                        </p>
                                    </dotted-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="searchTerm && !ungroupedRulesFoundInSearchResults">
                        <no-topics-message class="rules__no-rules-found-message">No rules found in search results</no-topics-message>
                    </div>
                    <div v-else>
                        <popup-menu ref="ruleWarningsList" v-if="getTotalRulesWithWarnings() && !reorderRulesEnabled" fixed :arrow-pointer="{show: true, left: true}">
                            <template #activator>
                                <label class="rule-warning-text">
                                    <i class="symbol-warning"></i> {{ getTotalRulesWithWarnings() }} {{ formatPlural(getTotalRulesWithWarnings(), 'rule has warnings', 'rules have warnings') }}
                                </label>
                            </template>
                            <div class="rules__rule-warning-list dark-scrollbars dark-scrollbars--visible short-animated fadeIn">
                                <div v-for="ruleItem in ruleItems" :key="ruleItem.topLevelRule.id">
                                    <div class="warning-item" v-if="ruleItem.warnings.length" @click="scrollToRule(ruleItem.topLevelRule)">
                                        Rule <strong>{{ ruleItem.topLevelRule.name }}</strong> has {{ ruleItem.warnings.length }} {{ formatPlural(ruleItem.warnings.length, 'warning') }}
                                    </div>
                                </div>
                            </div>
                        </popup-menu>

                        <div class="rules__row-header" :style="gridColumnStyle">
                            <span v-for="column in visibleColumns"
                                  :key="column.label"
                                  :style="reorderRulesEnabled ? 'pointer-events: none': ''"
                                  @click="sortRules(column)">
                                {{ column.label }} <i v-show="ungroupedSortBy.column.label === column.label"
                                                      :class="ungroupedSortBy.order === 'ascending' ? 'icon-up' : 'icon-down'"></i>
                            </span>
                        </div>
                        <div class="rules__rule-list dark-scrollbars dark-scrollbars--visible" :class="{'disabled': saving}">
                            <div v-for="ruleItem in filteredRules" :key="ruleItem.topLevelRule.id" :ref="'rule-row-' + ruleItem.topLevelRule.id">
                                <div class="rules__drop-zone"
                                     :class="{'rules__drop-zone-expand' : draggingOverRuleId === ruleItem.topLevelRule.id && draggingIndex > draggedOverIndex}"
                                     @dragover.prevent
                                     @dragenter.prevent="dragEnter"
                                     @dragleave.prevent="dragLeave"
                                     @drop="onDrop">
                                    Move here
                                </div>

                                <setup-rules-row v-show="draggingRuleId !== ruleItem.topLevelRule.id"
                                                 :class="{'rules__dropped-rule': droppedRuleId === ruleItem.topLevelRule.id, 'rules__rule-item': draggingRuleId === null}"
                                                 :drag-enabled="reorderRulesEnabled"
                                                 :ref="'rule-row-' + ruleItem.topLevelRule.id"
                                                 :rules="ruleItem.rules"
                                                 :warnings="ruleItem.warnings"
                                                 :rules-types-to-show="showRuleTypeOptions"
                                                 :show-inactive="showInactive"
                                                 :filter-visible="filterVisible"
                                                 :priority-visible="priorityColumn.visible"
                                                 :grid-column-style="gridColumnStyle"
                                                 @dragged-over="setDraggedOver"
                                                 @drag-rule-start="onDragStart"
                                                 @drag-rule-end="onDragEnd">
                                </setup-rules-row>

                                <div class="rules__drop-zone"
                                     :class="{'rules__drop-zone-expand' : draggingOverRuleId === ruleItem.topLevelRule.id && draggingIndex < draggedOverIndex}"
                                     @dragover.prevent
                                     @dragenter.prevent="dragEnter"
                                     @dragleave.prevent="dragLeave"
                                     @drop="onDrop">
                                    Move here
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import BeButton from "@/components/buttons/BeButton";
import SearchInput from "@/components/inputs/SearchInput";
import SetupRulesRow from "@/setup/rules/SetupRulesRow";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import SlottedTag from "@/components/tags/SlottedTag";
import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import LoadingMessage from "@/components/LoadingMessage";
import PopupMenu from "@/components/PopupMenu";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import AddEditRule from "@/setup/rules/add-rule/AddEditRule";
import AddFilterOutRule from "@/setup/rules/add-rule/AddFilterOutRule";
import AddCrowdRule from "@/setup/rules/add-rule/AddCrowdRule";
import AddRelevancyRule from "@/setup/rules/add-rule/AddRelevancyRule";
import AddEngageRule from "@/setup/rules/add-rule/AddEngageRule";
import AddTaggingRule from "@/setup/rules/add-rule/AddTaggingRule";
import AddCreateCrowdJobRule from "@/setup/rules/add-rule/AddCreateCrowdJobRule";
import {getRuleWarnings, getNewRulePriority, getRulePrettyAction, getRuleTags, rulePrettyActions, ruleTypes} from "@/setup/rules/RuleUtils";
import {isAccountAdmin, isOps} from "@/app/Permissions";
import DottedCard from "@/components/cards/DottedCard";
import {formatPlural} from "@/app/utils/Format";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";

export default {
    name: "SetupRulesPanel",
    components: {
        NoTopicsMessage,
        DottedCard, PopupMenu, LoadingMessage, SlottedTag, SetupRulesRow, BeButton, SearchInput},
    store: VuexStore,

    data() {
        return {
            columns: [
                {
                    label: "Rule",
                    sortField: "name",
                    visible: true
                },
                {
                    label: "Tags",
                    sortField: "tags",
                    visible: true
                },
                {
                    label: "Action",
                    sortField: "action",
                    visible: true
                },
                {
                    label: "Filter",
                    sortField: "filter",
                    visible: false,
                    canToggleVisibility: true
                },
                {
                    label: "Last 24 hours volume",
                    sortField: "last24h",
                    visible: true
                },
                {
                    label: "Last 7 days volume",
                    sortField: "lastWeek",
                    visible: true
                },
                {
                    label: "Priority",
                    sortField: "priority",
                    visible: false,
                    canToggleVisibility: true
                }
            ],
            groups: [
                {
                    name: rulePrettyActions.tag,
                    type: ruleTypes.tag,
                    description: "Rules with the <em>Tag mentions</em> action will automatically tag mentions matching a configured filter. Multiple filters can be applied to these rules.",
                    createDescription: "Create a rule to automatically tag mentions.",
                    icon: "symbol-tags",
                    visible: true,
                    sortedOnColumn: "Priority",
                    sortOrder: "ascending",
                    hideInactive: false
                },
                {
                    name: rulePrettyActions.edit,
                    type: ruleTypes.edit,
                    description: "Rules with the <em>Edit mentions</em> action will automatically change the attributes of mentions (such as category and location) matching a configured filter.",
                    createDescription: "Create a rule to automatically edit mention attributes.",
                    icon: "symbol-edit",
                    visible: true,
                    sortedOnColumn: "Priority",
                    sortOrder: "ascending",
                    hideInactive: false
                },
                {
                    name: rulePrettyActions.relevant,
                    type: ruleTypes.set_relevant,
                    description: "Rules with the <em>Do not automatically delete</em> action will automatically mark mentions matching a configured filter as relevant.",
                    createDescription: "Create a rule to automatically set mentions as relevant.",
                    icon: "symbol-checkmark",
                    visible: true,
                    sortedOnColumn: "Priority",
                    sortOrder: "ascending",
                    hideInactive: false
                },
                {
                    name: rulePrettyActions.delete,
                    type: ruleTypes.delete,
                    description: "Rules with the <em>Send to trash</em> action will automatically delete mentions matching a configured filter.",
                    createDescription: "Create a rule to automatically delete mentions.",
                    icon: "symbol-sorter",
                    visible: true,
                    sortedOnColumn: "Priority",
                    sortOrder: "ascending",
                    hideInactive: false
                },
                {
                    name: rulePrettyActions.engage,
                    type: ruleTypes.engage,
                    description: "Rules with the <em>Engage</em> action control Engage processing, such as whether or not mentions matching a configured filter should be sent to Engage.",
                    createDescription: "Create a rule to control Engage processing.",
                    icon: "icon-equalizer",
                    visible: true,
                    sortedOnColumn: "Priority",
                    sortOrder: "ascending",
                    hideInactive: false
                },
                {
                    name: rulePrettyActions.crowd,
                    type: ruleTypes.crowd,
                    description: "Rules with the <em>Verify through the Crowd</em> action control which mentions get sent to the crowd for verification.",
                    createDescription: "Create a rule to control which mentions get sent to the crowd.",
                    icon: "symbol-crowd",
                    visible: true,
                    sortedOnColumn: "Priority",
                    sortOrder: "ascending",
                    hideInactive: false
                },
                {
                    name: rulePrettyActions.create_crowd_job,
                    type: ruleTypes.create_crowd_job,
                    description: "Create extra crowd jobs (beyond normal verification flow) when other crowd jobs are completed.",
                    createDescription: "Create a rule to create an additional crowd job.",
                    icon: "symbol-crowd",
                    visible: true,
                    sortedOnColumn: "Priority",
                    sortOrder: "ascending",
                    hideInactive: false
                }
            ],
            ungroupedSortBy: {
                column: {
                    label: "Priority",
                    sortField: "priority",
                    visible: true
                },
                order: "ascending"
            },
            ruleTypes: ruleTypes,
            rulePrettyActions: rulePrettyActions,
            showRuleTypeOptions: [
                {
                    value: true,
                    label: rulePrettyActions.edit
                },
                {
                    value: true,
                    label: rulePrettyActions.tag
                },
                {
                    value: true,
                    label: rulePrettyActions.delete
                },
                {
                    value: true,
                    label: rulePrettyActions.crowd
                },
                {
                    value: true,
                    label: rulePrettyActions.relevant
                },
                {
                    value: true,
                    label: rulePrettyActions.engage
                },
                {
                    value: true,
                    label: rulePrettyActions.create_crowd_job
                }
            ],
            ruleWarnings: {},
            showInactive: true,
            draggingRuleId: null,
            draggingOverRuleId: null,
            droppedRuleId: null,
            reorderRulesEnabled: false,
            loading: false,
            searchTerm: "",
            showOptions: false,
            saving: false,
            groupByAction: true,
            tempGroupByAction: true,
            combineTaggingRules: true,
            tempCombineTaggingRules: true,
            tempShowPriorityColumn: false,
            tempShowFilterColumn: false
        }
    },

    watch: {
        ruleItems() {
            this.ensureCorrectOrder();
        }
    },

    activated() {
        this.reorderRulesEnabled = false;
    },

    async mounted() {
        try {
            this.loading = true;

            await this.refreshTags();
            await this.refreshRules();

            // set rule warnings
            this.rules.forEach(rule => {
                let warnings = getRuleWarnings(rule);
                if (warnings.length) this.ruleWarnings[rule.id] = warnings;
            });

            await this.refreshEngageTeams();
        } finally {
            this.loading = false;
        }
    },

    computed: {
        ...mapState(['rules', 'engageTeams']),

        isOps() {
            return isOps();
        },

        ruleItems() {
            let rules = [];
            let rulesById = {};

            if (this.reorderRulesEnabled || !this.combineTaggingRules) {
                this.rules?.forEach(rule => {
                    rules.push({
                        topLevelRule: rule,
                        rules: [rule],
                        searchTerm: rule.name,
                        warnings: getRuleWarnings(rule)
                    })
                })
            } else {
                this.rules?.forEach(rule => {
                    let tags = getRuleTags(rule);
                    let isTaggingRule = tags.length && (rule.attributes?.split(",")?.length === tags.length);

                    if (isTaggingRule) {
                        let id = tags.join(",");
                        let ruleById = rulesById[id];

                        if (ruleById) {
                            ruleById.rules.push(rule);

                            // ensure warnings are unique
                            ruleById.warnings = [...new Set([...ruleById.warnings, ...getRuleWarnings(rule)])];

                            ruleById.searchTerm += ` ${rule.name}`;
                        } else {
                            rulesById[id] = { topLevelRule: rule, rules: [rule], searchTerm: rule.name , warnings: getRuleWarnings(rule)}
                            rules.push(rulesById[id])
                        }
                    } else {
                        rulesById[rule.id] = {
                            topLevelRule: rule,
                            searchTerm: rule.name,
                            rules: [rule],
                            warnings: getRuleWarnings(rule)
                        }
                        rules.push(rulesById[rule.id])
                    }
                });
            }

            return rules;
        },

        groupedRules() {
            let groupedRules = {};
            this.ruleItems.forEach(r => {
                let prettyAction = getRulePrettyAction(r.topLevelRule);

                if (prettyAction.includes("Engage")) prettyAction = "Engage"

                if (groupedRules[prettyAction]) {
                    groupedRules[prettyAction].push(r)
                } else {
                    groupedRules[prettyAction] = [r];
                }
            });

            return groupedRules;

        },

        filteredRules() {
            let st = this.searchTerm.toLowerCase();

            return this.ruleItems?.filter(rule => (rule.searchTerm.toLowerCase().includes(st)));
        },

        ungroupedRulesFoundInSearchResults() {
            return !!this.filteredRules?.length;
        },

        filteredGroupedRules() {
            let st = this.searchTerm.toLowerCase();

            let groupedRules = {};

            Object.keys(this.groupedRules).forEach(key => {
                let list = this.groupedRules[key];

                groupedRules[key] = list.filter(rule => (rule.searchTerm.toLowerCase().includes(st)));
            });

            return groupedRules;
        },

        groupedRulesFoundInSearchResults() {
            for (const key of Object.keys(this.filteredGroupedRules)) {
                let list = this.filteredGroupedRules[key];

                if (list.length) return true;
            }

            return false;
        },

        showColumnOptions() {
            return this.columns.filter(column => column.canToggleVisibility);
        },

        visibleColumns() {
            return this.columns.filter(column => column.visible);
        },

        filterVisible() {
            return this.columns.find(column => column.sortField === 'filter')?.visible;
        },

        gridColumnStyle() {
            return {
                "grid-template-columns": this.filterVisible ? "var(--grid-columns)" : "var(--grid-columns-no-filter)"
            }
        },

        draggingIndex() {
            return this.filteredRules.findIndex(r => r.topLevelRule.id === this.draggingRuleId);
        },

        draggedOverIndex() {
            return this.filteredRules.findIndex(r => r.topLevelRule.id === this.draggingOverRuleId);
        },

        priorityColumn() {
            return this.getColumnByLabel("Priority");
        },

        filterColumn() {
            return this.getColumnByLabel("Filter");
        },

        canAddEngageRules() {
            return isAccountAdmin() && !!this.engageTeams?.length;
        }
    },

    methods: {
        ...mapActions(['refreshRules', 'updateRule', 'refreshTags', 'refreshEngageTeams']),

        formatPlural,

        ensureCorrectOrder() {
            this.groups.forEach(group => {
                let column = this.getColumnByLabel(group.sortedOnColumn);
                if (column && this.filteredGroupedRules[group.name]?.length) {
                    this.sortRules(column, true, null, group);
                }
            });

            this.sortRules(this.ungroupedSortBy.column, true);
        },

        sort(a, b, order) {
            a ??= "";
            b ??= "";

            if (typeof a === 'string') a = a.toLowerCase();
            if (typeof b === 'string') b = b.toLowerCase();

            if (order === "ascending") {
                return a > b ? 1 : -1
            } else {
                return a < b ? 1 : -1;
            }
        },

        sortRules(column, keepOrder, orderOverride, group) {
            if (this.reorderRulesEnabled) return;

            keepOrder ??= false;
            let order = "";

            if (group) {
                // set group's sort by data
                group.sortedOnColumn = column.label;
                group.sortOrder = orderOverride ? orderOverride : group.sortOrder;

                keepOrder ? order = group.sortOrder : (order = group.sortOrder === "ascending" ? "descending" : "ascending");
                group.sortOrder = order
            } else {
                this.ungroupedSortBy.order = orderOverride ? orderOverride : this.ungroupedSortBy.order;

                keepOrder ? order = this.ungroupedSortBy.order : (order = this.ungroupedSortBy.order === "ascending" ? "descending" : "ascending");
                this.ungroupedSortBy = {
                    column: column,
                    order: order
                };
            }

            let list = group ? this.filteredGroupedRules[group.name] : this.filteredRules;

            list.sort((a, b) => {
                let aValue;
                let bValue;

                if (column.label === "Tags") {
                    aValue = getRuleTags(a.topLevelRule).length;
                    bValue = getRuleTags(b.topLevelRule).length;
                } else if (column.label === "Action") {
                    aValue = getRulePrettyAction(a.topLevelRule);
                    bValue = getRulePrettyAction(b.topLevelRule);
                } else {
                    aValue = a.topLevelRule[column.sortField];
                    bValue = b.topLevelRule[column.sortField];
                }

                return this.sort(aValue, bValue, order);
            });
        },

        scrollToRule(rule) {
            if (rule.id) this.$refs['rule-row-' + rule.id][0]?.expand(true);
        },

        addRule(type) {
            let dialog = null;
            switch (type) {
                case ruleTypes.tag:
                    dialog = showDialog(AddTaggingRule, null);
                    break;
                case ruleTypes.edit:
                    dialog = showDialog(AddEditRule, null);
                    break;
                case ruleTypes.delete:
                    dialog = showDialog(AddFilterOutRule, null);
                    break;
                case ruleTypes.crowd:
                    dialog = showDialog(AddCrowdRule, null);
                    break;
                case ruleTypes.set_relevant:
                    dialog = showDialog(AddRelevancyRule, null);
                    break;
                case ruleTypes.engage:
                    dialog = showDialog(AddEngageRule, null);
                    break;
                case ruleTypes.create_crowd_job:
                    dialog = showDialog(AddCreateCrowdJobRule, null);
                    break;
            }
        },

        canCreateRule(ruleType) {
            if (ruleType === ruleTypes.engage) return this.canAddEngageRules;
            if (ruleType === ruleTypes.create_crowd_job) return isOps()
            return true;
        },

        getColumnByLabel(label) {
            return this.columns.find(c => c.label === label);
        },

        getGroupByType(type) {
            return this.groups.find(g => g.type === type);
        },

        getTotalRulesWithWarnings(group) {
            let warningCounter = 0;

            let list = group ? this.groupedRules[group.name] : this.ruleItems;

            list.forEach(ruleItem => {
                warningCounter += ruleItem.warnings.length ? 1 : 0;
            });

            return warningCounter;
        },

        showGroup(group) {
            let hiddenThroughOptions = !this.showRuleTypeOptions.find(o => o.label === group.name)?.value;
            if (hiddenThroughOptions) return false;

            // don't show group headings if they don't contain any rules matching the search term
            if (this.searchTerm) {
                return !!this.filteredGroupedRules[group.name]?.length;
            }

            return !hiddenThroughOptions && (this.canCreateRule(group.type) || this.groupHasRules(group));
        },

        groupHasRules(group) {
            return !!this.filteredGroupedRules[group.name]?.length;
        },

        setDraggedOver(args) {
            this.draggingOverRuleId = args.ruleId;
        },

        dragEnter(event) {
            event.target.classList.add("rules__drag-target--highlight");
        },

        dragLeave(event) {
            event.target.classList.remove("rules__drag-target--highlight");
        },

        async onDrop() {
            try {
                this.saving = true;

                let list = this.filteredRules.map(r => r.topLevelRule);
                let rule = this.filteredRules.find(r => r.topLevelRule.id === this.draggingRuleId).topLevelRule

                let newPriority = getNewRulePriority(list, this.draggingIndex, this.draggedOverIndex);

                if (newPriority === null || rule.priority === newPriority) return;

                let originalRule = JSON.parse(JSON.stringify(rule));
                if (rule) {
                    let payload = JSON.parse(JSON.stringify(rule));
                    payload.priority = newPriority;

                    await this.updateRule(payload);

                    this.droppedRuleId = rule.id;

                    notifyUser({
                        message: `Rule "${rule.name}" priority has been changed.`,
                        isEscapedHtml: true,
                        icon: "<i class='symbol-rules'></i>",
                        longDelay: true,
                        undo: async () => {
                            try {
                                this.saving = true;

                                await this.updateRule(originalRule);

                                this.droppedRuleId = rule.id;

                                notifyWithText(`Rule "${rule.name}" priority change has been undone.`, null, "<i class='symbol-rules'></i>");

                                this.$nextTick(() => {
                                    let el = this.$refs['rule-row-' + rule.id][0]?.$el;
                                    if (el) el.scrollIntoView();
                                });
                            } catch (e) {
                                console.error(`An error occurred while undoing the priority change of rule ${rule.id}: `, e);
                                showErrorDialog(`We were unable to undo "${rule.name}" priority change.`);
                            } finally {
                                this.saving = false;
                            }
                        }
                    });
                }

            } catch (e) {
                console.error(`Error occurred when trying to change priority of rule ${this.draggingRuleId}: `, e);
                showErrorDialog(`An error occurred while trying to update the rule's priority.`);
            } finally {
                this.saving = false;
                window.setTimeout(() => this.droppedRuleId = null, 500);
            }
        },


        onDragStart(args) {
            this.draggingRuleId = args.ruleId;
        },

        onDragEnd() {
            this.draggingRuleId = null;
            this.draggingOverRuleId = null;
        },

        toggleReorderRules() {
            if (!this.reorderRulesEnabled) this.sortRules(this.priorityColumn, true, "ascending");

            this.reorderRulesEnabled = !this.reorderRulesEnabled;

            if (this.reorderRulesEnabled) {
                this.tempGroupByAction = this.groupByAction;
                this.tempCombineTaggingRules = this.combineTaggingRules;
                this.tempShowPriorityColumn = this.priorityColumn.visible;
                this.tempShowFilterColumn = this.filterColumn.visible;

                this.groupByAction = false;
                this.priorityColumn.visible = true;
                this.filterColumn.visible = false;
            } else {
                this.groupByAction = this.tempGroupByAction;
                this.combineTaggingRules = this.tempCombineTaggingRules;
                this.priorityColumn.visible = this.tempShowPriorityColumn;
                this.filterColumn.visible = this.tempShowFilterColumn;
            }

            this.searchTerm = "";
            this.showRuleTypeOptions.forEach(rt => rt.value = true);
            this.showInactive = true;
        }
    }
}
</script>

<style scoped lang="sass">

.rules
    --border: 1px solid #1a1a1a
    --border-dragging: 1px dashed #1a1a1a
    --grid-columns: minmax(10px, 2fr) minmax(10px, 2fr) minmax(10px, 2fr) minmax(10px, 3fr) repeat(auto-fit, minmax(10px, 1fr))
    --grid-columns-no-filter: minmax(10px, 2fr) minmax(10px, 2fr) minmax(10px, 1fr) repeat(auto-fit, minmax(10px, 1fr))
    --rule-background-color: #333
    padding-bottom: 20px

    &__rule-item
        &:not(.expanded):hover
            background: var(--background-menu-hover)
            cursor: pointer

    p
        color: var(--be-colour-text-dark)

    &__container
        max-width: 1300px

        @media screen and (max-width: 1280px)
            --grid-columns: repeat(auto-fit, minmax(10px, 1fr))
            --grid-columns-no-filter: minmax(10px, 2fr) minmax(10px, 2fr) repeat(auto-fit, minmax(10px, 1fr))

    &__options-container
        margin: 10px 0
        padding-bottom: 15px

    &__no-rules-found-message
        margin-top: 100px

    &__options
        margin-top: 30px
        display: flex

        .search-input
            width: 40%

        .button-group
            display: flex
            column-gap: 10px
            margin-left: auto

    &__add-rule-popup-menu
        width: 190px

        .add-rule-btn
            display: flex
            align-items: center
            padding: 5px

            &:not(:last-of-type)
                border-bottom: 1px solid #666

            &:hover
                background: var(--background-menu-hover)
                cursor: pointer

                &:first-of-type
                    border-top-left-radius: 4px
                    border-top-right-radius: 4px

                &:last-of-type
                    border-bottom-left-radius: 4px
                    border-bottom-right-radius: 4px

            .add-logo
                display: flex
                align-items: center
                justify-content: center
                height: 32px
                width: 40px
                border-radius: 3px

    &__rule-warning-list
        max-height: 400px
        overflow-y: auto

        & div > .warning-item
                    padding: 5px
                    color: var(--be-colour-text-dark)
                    line-height: 20px
                    border-bottom: 1px solid #1a1a1a

                    strong
                        font-weight: bold
                        font-size: calc(1em + 1px)
                        color: white

                    &:hover
                        cursor: pointer
                        background: var(--background-menu-hover)


    &__options-popup-menu
        display: flex
        flex-direction: column
        border: var(--border)

        @media screen and (max-height: 770px)
            flex-direction: row

        ul
            list-style: none
            margin: 0

            @media screen and (max-height: 770px)
                border-right: 1px solid #1a1a1a

                &:last-of-type
                    border: none

        li
            line-height: 20px

        li > a
            font-weight: 400
            color: #ffffff
            cursor: pointer
            display: block
            padding: 3px 10px
            clear: both
            white-space: nowrap

            &:hover
                color: #aee15d
                text-decoration: none
                background-color: #222222

        label
            padding: 3px 10px
            margin-left: 20px

    &__popup-menu-header
        background: #222222
        padding: 3px 10px
        margin-bottom: 3px
        font-size: 12px
        text-transform: uppercase
        color: var(--clr-sidebar-header) !important
        pointer-events: none

    &__row-header
        display: grid
        cursor: pointer
        overflow-x: auto
        grid-template-columns: var(--grid-columns)
        position: sticky
        top: 40px
        z-index: 10
        border: none
        color: var(--be-colour-text-dark)
        text-align: center

        span
            background: #222
            box-sizing: border-box
            border-top: var(--border)
            border-right: var(--border)
            padding: 5px 10px

            &:first-child
                border-top-left-radius: 3px
                border-left: var(--border)

            &:last-child
                border-top-right-radius: 3px

    &__rule-group-header

        h4
            display: inline-block

    .can-click:hover
        cursor: pointer
        color: var(--be-colour-text-dark__hover)


    &__rule-grouped-list
        //height: calc(100vh - 280px)
        max-height: 500px
        position: relative
        overflow-y: auto
        overflow-x: hidden

    &__dotted-card
        width: 50%

    &__rule-list
        height: calc(100vh - 280px)
        position: relative
        overflow-y: auto
        overflow-x: hidden

        &.disabled
            transition-delay: 100ms
            transition-duration: 250ms
            filter: grayscale(80%) blur(3px)
            cursor: default
            pointer-events: none

    &__rule-group-container
        margin-bottom: 20px

    &__rule-group-controls
        display: flex
        margin-bottom: 10px

        label
            color: var(--be-colour-muted-text-dark)
            display: flex
            align-items: flex-start
            &:not(:first-child)
                padding-left: 10px
            & > *
                padding-left: 5px

    .rule-warning-text
        color: red

    &__drop-zone
        height: 0
        visibility: hidden
        //transition: height .2s
        display: flex
        align-items: center
        justify-content: center

    &__drop-zone-expand
        color: var(--be-colour-text-dark__hover)
        border: 1px dashed
        border-radius: 4px
        height: 30px
        margin: 10px 0
        visibility: visible

    &__dropped-rule
        animation: highlight-rule 1s

    &__drag-target--highlight
        background: var(--background-menu-hover)
        color: var(--colour-menu-hover) !important

    @keyframes highlight-rule
        0%
            background: var(--be-colour-text-dark__hover)
        100%
            background: var(--rule-background-color)

</style>