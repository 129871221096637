var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Change";
},"3":function(container,depth0,helpers,partials,data) {
    return "View";
},"5":function(container,depth0,helpers,partials,data) {
    return "<a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>";
},"7":function(container,depth0,helpers,partials,data) {
    return "Ok";
},"9":function(container,depth0,helpers,partials,data) {
    return "Close";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":49}}})) != null ? stack1 : "")
    + " Topic Tree</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"select-topic-tree-region\"></div>\n\n    <div class=\"dialog-button-bar\" style=\"margin-top: 10px\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":93}}})) != null ? stack1 : "")
    + "\n        <a class=\"ok btn btn-primary\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":76}}})) != null ? stack1 : "")
    + "</a>\n    </div>\n</div>\n\n\n";
},"useData":true});