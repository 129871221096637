<template>
    <div class="facebook-auth">
        <transition name="slide-content">
            <div v-if="!showTerms">
                <div v-if="loggedInThroughFacebook">
                    <div v-if="facebookPages.length">
                        <p class="facebook-auth__info-text-block">
                            These are the Facebook pages & Instagram business profiles that you have granted DataEQ access to. Click on a profile's row to view the accounts you can add it to.
                        </p>

                        <search-input
                            v-if="facebookPages.length > 15"
                            class="facebook-auth__search-bar"
                            autofocus
                            placeholder="Search for a page"
                            v-model="searchTerm">
                        </search-input>
                        <div class="facebook-auth__pages dark-scrollbars dark-scrollbars--visible"
                            :style="facebookPages.length > 15 ? 'height: clamp(230px, 22vh, 600px)' : 'height: clamp(265px, 26vh, 600px)'">

                            <div class="facebook-auth__headers">
                                <span>Facebook Page</span>
                                <span>Connected Instagram Account</span>
                                <span>Unauthorise</span>
                            </div>
                            <online-profile-row v-for="page in filteredPages"
                                                :profile="page"
                                                :linked-profile="page.linkedProfile"
                                                :key="page.handleId"
                                                :expand-row="showAccounts"
                                                @collapse-row="showAccounts = false"
                                                @unauth-profile="triggerUnauthProfile"/>
                            <div style="margin-top: 10px" v-if="!filteredPages.length">
                                No pages match your search
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        Facebook pages that you are an admin of will appear here.
                    </div>
                </div>
                <div v-else>
                    <span v-if="facebookUserTokenInvalid">
                        You have logged in through Facebook before, however the login has been invalidated. This could be because you have removed the DataEQ app directly from Facebook, or you have changed your Facebook password.
                        Please login again so that pages you have authorised can continue to collect data.
                    </span>
                    <span v-else :class="{'failed-login': facebookLoginFailed}">{{ facebookLoginStatus }}</span>
                </div>

                <div class="facebook-auth__btn-bar">
                    <be-button
                        v-if="!loggedInThroughFacebook"
                        class="btn-facebook"
                        @click="toggleTerms">
                        Login with Facebook
                    </be-button>
                    <be-button class="btn-facebook" v-else-if="!reauthorised" @click="handleFacebookLogin(true)">
                        Refresh authorisation
                    </be-button>
                    <slotted-tag v-else no-close>
                        <animated-check animated/> Login refreshed
                    </slotted-tag>
                    <span class="login-status" v-if="facebookLoggedInAs">Logged in through <i class="symbol-facebook-rect"></i>Facebook as {{ facebookLoggedInAs.name }}</span>
                </div>
            </div>
        </transition>

        <transition name="slide-terms">
            <div v-if="showTerms">
                <div class="facebook-auth__terms dark-scrollbars dark-scrollbars--visible">
                    <div>
                        <p>Please review the permissions below. The permissions are used to access the Facebook API on
                            your behalf.</p>
                        <p>Without authorisation we cannot collect Facebook data for you.</p>
                    </div>
                    <div>
                        <h4>Permissions requested for managed Facebook pages</h4>
                        <ul>
                            <li>
                                <h5>Public Profile</h5>
                                <p>Allows DataEQ see your Facebook name, profile picture and Facebook ID</p>
                            </li>
                            <li>
                                <h5>Manage Page Ads</h5>
                                <p>Allows DataEQ to collect comments and engagement statistics from your ads</p>
                            </li>
                            <li>
                                <h5>Manage Page Metadata</h5>
                                <p>Allows DataEQ to collect real-time data and view metadata such as profile pictures</p>
                            </li>
                            <li>
                                <h5>Read Page Engagement</h5>
                                <p>Allows DataEQ to read engagement statistics such as follower and like counts</p>
                            </li>
                            <li>
                                <h5>Read Page User Content</h5>
                                <p>Allows DataEQ to collect posts created by users on your page/s</p>
                            </li>
                            <li>
                                <h5>Manage Page Posts</h5>
                                <p>Allows you to create, edit, and delete your page posts via DataEQ</p>
                            </li>
                            <li>
                                <h5>Manage Page Engagement</h5>
                                <p>Allows you to create, edit, and delete comments posted on your page/s via DataEQ</p>
                            </li>
                            <li>
                                <h5>Page Messaging</h5>
                                <p>Allows you to read and reply to Facebook direct messages via DataEQ</p>
                            </li>
                            <li>
                                <h5>Read Page Mailboxes</h5>
                                <p>Allows you to read and reply to Facebook direct messages via DataEQ</p>
                            </li>
                            <li>
                                <h5>Ads Management</h5>
                                <p>Allows you to manage ads for ad accounts that you have access to</p>
                            </li>
                            <li>
                                <h5>Read Insights</h5>
                                <p>Allows DataEQ to read engagement statistics such as impressions</p>
                            </li>
                            <li>
                                <h5>Business Management</h5>
                                <p>Required for DataEQ to view the pages you are an admin of</p>
                            </li>
                        </ul>

                        <h4>Permissions requested for managed Instagram Business profiles</h4>
                        <ul>
                            <li>
                                <h5>Instagram Basic</h5>
                                <p>Allows DataEQ to view Instagram Business profiles connected to
                                    your Facebook page/s</p>
                            </li>
                            <li>
                                <h5>Manage Instagram Comments</h5>
                                <p>Allows DataEQ to collect comments on your profile posts and allows you to reply to comments on those posts via DataEQ</p>
                            </li>
                            <li>
                                <h5>Manage Instagram Insights</h5>
                                <p>Allows DataEQ to read engagement statistics such as follower counts and like counts</p>
                            </li>
                            <li>
                                <h5>Manage Instagram Messages</h5>
                                <p>Allows you to read and reply to Instagram direct messages via DataEQ</p>
                            </li>
                            <li>
                                <h5>Publish Instagram Content</h5>
                                <p>Allows DataEQ to create organic feed photo and video posts</p>
                            </li>
                        </ul>
                    </div>
                    <div>To authorise us, please click the "Continue" button below.</div>
                </div>
                <div class="facebook-auth__terms-button-bar">
                    <be-button link style="margin-left: auto" @click="toggleTerms">Back</be-button>
                    <be-button @click="handleFacebookLogin(false)">Continue</be-button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import BeButton from "@/components/buttons/BeButton";
import SearchInput from "@/components/inputs/SearchInput";
import {notifyWithText, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import OnlineProfileRow from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfileRow";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import {handleFacebookPageAuthLogin} from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import SlottedTag from "@/components/tags/SlottedTag";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import {features} from "@/app/Features";

export default {
    name: "FacebookAuth",
    store: VuexStore,
    components: {AnimatedCheck, SlottedTag, OnlineProfileRow, BeButton, SearchInput},

    props: {
        loggedInThroughFacebook: {
            type: Boolean,
            required: true,
            default: false
        },
        facebookPages: {
            type: Array,
            required: false
        },
        expandAccounts: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            showTerms: false,
            facebookLoginFailed: false,
            features: features,
            facebookLoginStatus: "Login with Facebook to see your managed pages.",
            searchTerm: "",
            showAccounts: false,
            reauthorised: false
        }
    },

    created() {
        this.showAccounts = this.expandAccounts;
    },

    computed: {
        ...mapState(['user', 'account']),

        ...mapState('userPlatformAuth', [
            'facebookUserToken', 'facebookLoggedInAs', 'facebookUserTokenInvalid'
        ]),

        filteredPages() {
            let search = this.searchTerm.toLowerCase();

            let profiles = this.facebookPages.filter(page => page.name.toLowerCase().includes(search) ||
                page.handle.toLowerCase().includes(search) || page.handleId.toLowerCase().includes(search) ||
                page.linkedProfile?.name.toLowerCase().includes(search) ||
                page.linkedProfile?.handle.toLowerCase().includes(search) || page.linkedProfile?.handleId.toLowerCase().includes(search));

            return profiles?.sort((a, b) => {
                return a.name > b.name ? 1 : -1
            });
        },

        facebookLoginButtonText() {
            return this.loggedInThroughFacebook ? "Authorise more pages" : "Login with Facebook";
        }
    },

    methods: {
        ...mapActions('userPlatformAuth', [
            'initializeFbUserToken', 'initializeManagedFacebookPages'
        ]),

        toggleTerms() {
            this.showTerms = !this.showTerms;

            // only show platform chooser if terms are not shown
            this.$emit('show-platform-chooser', !this.showTerms);
        },

        async handleFacebookLogin(reauthorising) {
            this.setLoading("Communicating with Facebook...", true);

            try {
                reauthorising ??= false;

                handleFacebookPageAuthLogin(async callbackResponse => {
                    if (callbackResponse["auth"] === "success") {

                        this.setLoggedInThroughFacebook(true);

                        notifyWithText("Facebook authorisation successful.",
                            null,
                            "<i class='icon-facebook'></i>");

                        this.setLoading("Facebook login successful! Fetching your managed pages...", true);

                        // update managed fb pages
                        this.fetchFacebookPages();

                        if (reauthorising) this.reauthorised = true;

                        this.showAccounts = !reauthorising;
                    } else {
                        this.facebookLoginStatus = "Login with Facebook failed. Please reload the page and try again or contact DataEQ support.";
                        this.facebookLoginFailed = true;

                        this.setLoading("", false);
                    }
                });
            } catch (e) {
                console.error("Error occurred while trying to log user in with Facebook ", e);
                this.facebookLoginStatus = "Login with Facebook failed. Please try again.";
                this.facebookLoginFailed = true;

                this.setLoading("", false);

                notifyUserOfError("An error occurred while trying to log you in with Facebook. Please close the dialog and try again or contact DataEQ support.");
            } finally {
                if (!reauthorising) this.toggleTerms();
            }
        },

        triggerUnauthProfile(args) {
            this.$emit('unauth-profile', args);
        },

        setLoading(message, value) {
            this.$emit('loading-updated', message, value);
        },

        setLoggedInThroughFacebook(value) {
            this.$emit('logged-in-through-facebook', value)
        },

        fetchFacebookPages() {
            this.$emit('refresh-facebook-pages', {forceRefresh: true, showNotification: true});
        }
    }
}
</script>

<style scoped lang="sass">

.facebook-auth

    .online-profile
        height: 35px

    table
        border-collapse: separate
        border-spacing: 0 5px
        border: none
        background: var(--body-background-colour)

        td
            border: 1px solid #272727
            background: #333333

            &:last-child
                text-align: center
                vertical-align: middle

    &__search-bar
        margin-bottom: 10px
        width: 90%

    &__info-text-block
        margin-right: 20px

    &__headers
        display: grid
        cursor: pointer
        overflow-x: auto
        grid-template-columns: 250px 250px repeat(auto-fit, minmax(100px, 1fr))
        position: sticky
        top: 0
        z-index: 10
        border: none
        color: var(--be-colour-text-dark)
        text-align: center

        span
            background: #222
            box-sizing: border-box
            border-top: 1px solid #1a1a1a
            border-right: 1px solid #1a1a1a
            padding: 5px 10px

            &:first-child
                border-top-left-radius: 3px
                border-left: 1px solid #1a1a1a

            &:last-child
                border-top-right-radius: 3px

    &__pages
        padding-right: 10px
        overflow-y: scroll

    .failed-login
        color: #df8505

    &__terms
        height: clamp(305px, 27vh, 600px)
        padding-right: 20px
        overflow-y: auto

        ul
            list-style: circle

        li
            p
                font-size: 0.9em
                color: #999

    &__terms-button-bar
        display: flex
        padding-right: 20px
        margin-top: 15px
        column-gap: 10px

    &__btn-bar
        display: flex
        align-items: center
        margin-top: 10px
        column-gap: 10px

        .login-status
            color: var(--be-colour-muted-text-dark)
            font-style: italic
            font-size: 0.9em
            padding-right: 10px
            margin-left: auto
            //max-width: 295px

        .btn-facebook
            ::v-deep .btn
                background: var(--colour-facebook)

            &:hover
                ::v-deep .btn
                    filter: brightness(1.2)


.slide-content-enter-active,
.slide-terms-enter-active
    transition: all .5s ease

.slide-content-leave-active,
.slide-terms-leave-active
    transition: none
    opacity: 0

.slide-content-enter
    transform: translateX(-5px)
    opacity: 0

.slide-terms-enter
    transform: translateX(5px)
    opacity: 0

</style>