import {notifyWithText, showBusyNotification} from "@/app/framework/notifications/Notifications";
import moment from "moment";
import {getAllCxSegmentLists, getAllRiskProductSegmentLists} from "@/app/utils/Segments";
import {getMetricFromId} from "@/app/framework/dialogs/metric-picker/availableMetrics";
import VuexStore from "@/store/vuex/VuexStore";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";

/**
 * Create a new dashboard around a particular filter. For instance,
 * this is useful to allow the user to create a dashboard from the mention panel from the
 * filter that they are looking at.
 *
 * @param filter {String}
 * @param dashboardTitle {String, optional}
 * @param sectionTitle {String, optional}
 */
export async function createSimpleDashboardForFilter(filter, dashboardTitle, sectionTitle) {
    dashboardTitle ??= "Temporary dashboard " + moment().format("YYYY-MM-DD");
    sectionTitle ??= "Name me";

    const [conduct, cx] = await Promise.all([getAllRiskProductSegmentLists(), getAllCxSegmentLists()]);
    const hasRpcs = conduct.length || cx.length;

    const rpcsArea = hasRpcs
        ? [Object.assign({}, getMetricFromId("rpcs/columns").widgetDesc, {width: 8, height: 2, id: 100})]
        : [
            Object.assign({}, getMetricFromId("sites/columns").widgetDesc, {
                width: 4,
                height: 2,
                geometry: "rows",
                'max-items': 4,
                id: 101
            }),
            Object.assign({}, getMetricFromId("authors/columns").widgetDesc, {
                width: 4,
                height: 2,
                geometry: "rows",
                'max-items': 4,
                id: 102
            })
        ];

    const dashboard = {
        name: dashboardTitle,
        category: "From mention panel",
        sections: [
            {
                id: 1,
                title: sectionTitle,
                filter: filter,
                widgets: [
                    {
                        "id": 2,
                        "type": "FantasticChart",
                        "width": 8,
                        "height": 2,
                        "caption": "Percentage Overall Net Sentiment",
                        "xAxis": "published",
                        "yAxis": "totalSentimentPercent",
                        "geometry": "lines",
                        "hidden-title": true,
                        "xGridLines": false,
                        "comment": "> ## {{brand's}} Overall Net Sentiment is {{total net%}}"
                    },
                    Object.assign({}, getMetricFromId("filter").widgetDesc, {width: 4, height: 2, id: 3}),
                    Object.assign({}, getMetricFromId("volume/overview").widgetDesc, {width: 8, height: 2, id: 4}),
                    Object.assign({caption: "Word cloud"}, getMetricFromId("themes/wordcloud").widgetDesc, {
                        width: 4,
                        height: 4,
                        id: 5
                    }),
                    ...rpcsArea,
                ]
            }
        ]
    };

    await createDashboard(dashboard);
}

/**
 * Creates an ad-hoc dashboard and then navigates to it.
 *
 * @param dashboard {Object}
 * @returns {Promise<void>}
 */
export async function createDashboard(dashboard) {
    if (!dashboard.name) throw new Error("No title for dashboard");

    const USER_NAME = VuexStore.getters.userName;
    const user = VuexStore.state.user;

    // Ensure that we have some decent defaults.
    dashboard = {
        category: USER_NAME,
        'max-width': '12',
        resizable: true,
        gridVersion: 2,
        ...dashboard
    };

    if (!dashboard.privacy && user.admin) {
        dashboard.privacy = "MASH_ADMIN"; // Hide staff dashboards by default.
    }

    // Ensure that all sections and widgets have IDs. Those are easy to forget, and can cause
    // problems.
    let count = 10001;
    if (dashboard.sections?.length) {
        for (const section of dashboard.sections) {
            if (!section.id) section.id = count++;

            if (section.widgets?.length) {
                for (const widget of section.widgets) {
                    if (!widget.id) widget.id = count++;
                }
            }
        }
    }


    let busyMessage = showBusyNotification("Creating your dashboard");
    try {
        const account = VuexStore.state.account;
        const url = Beef.Sync.toMashUrl("accounts/" + account.code + "/reports/", true);
        const response = await fetch(url, {method: "POST", body: JSON.stringify(dashboard)});
        if (response.status !== 200) {
            console.error(`Received status ${response.status} from mash`);
            // noinspection ES6MissingAwait
            showErrorDialog("We've had a problem creating the dashboard. Please try again.");
            return;
        }
        const object = await response.json();
        Beef.router.navigate(`${account.code}/dashboards/${object.id}`, {trigger: true});

        setTimeout(() => {
            if (busyMessage) {
                busyMessage.close();
                notifyWithText("Remember to give this dashboard a good name", null, "<i class='symbol-reports'></i>", true);
            }
        }, 5000);
    } catch(e) {
        console.error("Unable to create dashboard", e);
        // Close immediately on error, otherwise closed in the timeout above.
        busyMessage.close();
        busyMessage = null;
    }
}