/**
 * Base class for widgets that interactively change the section filter.
 */
Beef.module("Widget.SelectorWidget").addInitializer(function(startupOptions) {

    this.View = Beef.FilteredTextView.extend({

        template: require("@/dashboards/widgets/selectors/SelectorWidget.handlebars"),

        attributes: {
            class: 'scroll-auto widget-height-inner dark-scrollbars dark-scrollbars--visible'
        },

        events: {
            "click .value": "tileClicked"
        },

        modelEvents:Object.assign({}, Beef.ConstrainedWidgetView.prototype.modelEvents),

        getCsvEntries: null,

        imageExportDisabled: true,

        filterAttribute: null,

        multiselect: true,

        initialize: function(){
            this.listenTo(this.model.getInteractiveFilterModel(), "change:" + this.filterAttribute,
                this.interactiveFilterModelChanged, this);
            Beef.FilteredTextView.prototype.initialize.apply(this, arguments);
        },

        onClose: function() {
            // only clear the attr if we are being deleted to avoid generating unnecessary calls to fetch data
            // when we are closed e.g. when switching between dashboards
            if (this._deleting) this.clearFilterAttribute();
        },

        clearFilterAttribute: function() {
            var ifm = this.model.getInteractiveFilterModel();
            var current = ifm.get(this.filterAttribute);
            if (current) ifm.set(this.filterAttribute, null);
        },

        getTooltipText: function(item) {
            return "Click to see mentions from " + item.name;
        },

        onRender: function() {
            setTimeout(this.renderImpl.bind(this));
        },

        renderRow: function(select, item) {
            if (!item) return;
            if (item.id) select.attr('data-value', item.id).attr('class', 'value');
            else select.attr('class', 'value value-noid');
            select.append('td');
            select.select('td')
                .text(item.clientName || item.shortName || item.name)
                .attr('title', this.getTooltipText);
        },

        endOfRender: function() {
            // do not attempt to scroll the selector into view here (scroll = false) because it will override the default
            // scroll position (see SectionList.js)
            this.highlightSelectedRows(this.model.getInteractiveFilterModel().get(this.filterAttribute), false);

            Beef.FilteredTextView.prototype.endOfRender.apply(this, arguments);
        },

        interactiveFilterModelChanged: function(ifm, value) {
            this.highlightSelectedRows(value);
        },

        highlightSelectedRows: function(value, scroll) {
            scroll ??= true;
            var rows = this.$el.find('table.selector-widget-tiles .value');
            if (value) {
                var a = value.split(' ');
                var first = null;
                rows.each(function() {
                    var r = $(this);
                    var selected = a.indexOf(r.attr('data-value')) >= 0;
                    r.toggleClass("selected", selected);
                    if (selected && !first) first = r;
                });
                // if e.scrollIntoViewIfNeeded is not available (Firefox) only do the scrolling if we are animating
                // as it causes annoying jumping during interactive use
                if (scroll && first && (this._animating || first[0].scrollIntoViewIfNeeded)) {
                    var e = first[0];
                    if (e.scrollIntoViewIfNeeded) e.scrollIntoViewIfNeeded();
                    else e.scrollIntoView({scrollMode: "if-needed"});
                }
            } else {
                rows.toggleClass("selected", false);
                // select 'all conversation' if it is present
                this.$('tr.value[data-value="."]').toggleClass("selected", true)
            }
        },

        /** Get the current value(s) of our filter attribute in an array or empty array if none. */
        getCurrentValueArray: function() {
            var ifm = this.model.getInteractiveFilterModel();
            var current = ifm.get(this.filterAttribute);
            return current ? current.split(' ') : [];
        },

        tileClicked: function(ev) {
            ev.preventDefault();

            var a = this.getCurrentValueArray();

            var $t = $(ev.target);
            var value = $t.attr('data-value');
            if (!value) value = $t.closest("tr").attr('data-value');

            var allOurIds = { };
            $t.closest("table").find("tr").each(function() {
                var id = $(this).attr("data-value");
                if (id) allOurIds[id] = true;
            });

            // Remove all of our ids from a and keep the 'foreign' ids so we can put them back again later. These
            // belong to other drill downs for the same filter attribute (e.g. 2 segment drill downs in the same
            // section).
            var foreign = [], ours = [];
            for (var i = 0; i < a.length; i++) {
                var id = a[i];
                if (allOurIds[id]) ours.push(id);
                else foreign.push(id);
            }
            if (foreign.length) {
                if (foreign[0] == '/') foreign.splice(0, 1);
                if (foreign.length && foreign[foreign.length - 1] === '/') foreign.splice(foreign.length - 1, 1);
            }

            i = ours.indexOf(value);

            this.onValueClicked(value);

            if (this.multiselect && (ev.shiftKey || ev.ctrlKey)) {
                if (i >= 0) ours.splice(i, 1);
                else ours.push(value);
            } else if (i >= 0 && ours.length == 1) {
                ours = [];
            } else {
                ours = [value];
            }

            // combine with the foreign ids (if any) with '/' between the 2 lists
            if (foreign.length > 0) {
                if (ours.length > 0) {
                    foreign.push('/');
                    a = foreign.concat(ours);
                } else {
                    a = foreign;
                }
            } else {
                a = ours;
            }

            this.model.getInteractiveFilterModel().set(
                this.filterAttribute, a.length == 0 ? this.getDefaultValue() : a.join(" "));
        },

        /**
         * Called when the user clicks on a value.
         */
        onValueClicked: function(value) {
        },

        /**
         * Advance to the next animation frame if possible. Return true if this loops us back to the beginning of
         * our sequence (or we have no data) or false otherwise.
         */
        nextAnimationFrame: function() {
            var data = this.model.generalData.get('textvalue');
            var a = this.getCurrentValueArray();
            var currentValue = a.length > 0 ? a[0] : null;
            var nextValue, looped = false;
            if (currentValue) {
                for (var i = 0; i < data.length && (!data[i].id  || data[i].id != currentValue); i++);
                if (i < data.length) {
                    for (; ++i < data.length && !data[i].id; );
                    if (i >= data.length) looped = true;
                    else nextValue = data[i].id;
                }
            }
            if (!nextValue) {
                for (i = 0; i < data.length && !data[i].id; i++);
                nextValue = i < data.length ? data[i].id : null;
            }

            if (nextValue) {
                this._animating = true;
                try {
                    this.model.getInteractiveFilterModel().set(this.filterAttribute, "" + nextValue);
                } finally {
                    this._animating = false;
                }
            }

            return looped || !nextValue;
        },

        getDefaultValue: function() {
            return null;
        },

        widthChanged: function() {

        }
    });

});