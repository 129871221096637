import FastClick from 'fastclick'
import {isMashAdmin} from "@/app/Permissions";
import VuexStore from "@/store/vuex/VuexStore";
import {showNewInAnalyseDialog, showNewInAnalyseDialogIfNotSeen} from "@/app/help/Help";
import {account} from "@/app/utils/Account";

Handlebars.render = function(template, data) {
    // the templates are pre-compiled by webpack so we just have to invoke the function
    if (typeof template === "function") return template(data)
    if (!template) return ""
    throw "Missing template: " + template
};
Backbone.Marionette.Renderer.render = Handlebars.render;

// this eliminates the delays on clicks on mobile devices
window.addEventListener('load', function() {
    FastClick.attach(document.body);
}, false);

var Beef = new Backbone.Marionette.Application();
window.Beef = Beef
export default Beef


let currentTitle = "";

/** Provides a way to consistently set a window title across analyse.
 * @param {String} title - The new title for the tab.
 * @return {void}
 */
export function setTitle(title) {
    const account = VuexStore.state.account;
    currentTitle = title;
    const toJoin = [account?.name, title, "Analyse"];
    let workingTitle = toJoin.filter(s => !!s).join(' | ');
    if (account.dev) workingTitle = "DEV: " + workingTitle;
    window.document.title = workingTitle;
}

/**
 * @return {string} Returns the current title.
 */
export function getTitle() {
    return currentTitle;
}

/**
 * Some of our older code updates the content size. We want to reset that for
 * some newer elements.
 */
export function resetContentHeight() {
    const content = document.getElementById("content");
    content.style["min-height"] = "calc(100vh - 50px)";
}

/**
 * This must be called from screens using the sidebar when the sidebar height may have changed or when the overlay
 * height may have changed. I haven't been able to sort this out with css as the inner part of the sidebar is
 * absolutely positioned and hence doesn't contribute to the height of the sidebar. Ditto for the overlay.
 *
 * It fires a change event on the #content item, and a change:height event as well, when the height changes.
 *
 * @deprecated All newer code that relies on this should be using modern CSS to not need this.
 */
export function adjustContentHeight() {
    var $cr = $('#content-region');
    var minHeight = findMaxSidebarHeight(1000, $cr.find('.sidebar .fixed-inner'));
    minHeight = findMaxSidebarHeight(minHeight, $cr.find('.sidebar .inner'));
    var h = Beef.overlay.height();
    if (h > minHeight) minHeight = h;
    var $content = $('#content');
    $content.css("min-height", minHeight + "px");
    $content.trigger('change');
    $content.trigger('change:height');
}

function findMaxSidebarHeight(max, $sel) {
    for (var j = 0; j < $sel.length; j++) {
        var h = 50;
        var $c = $sel.children();
        for (var i = 0; i < $c.length; i++) h += $($c[i]).height();
        if (h > max) max = h;
    }
    return max;
}




document.addEventListener("keyup",  function (ev) {
    if (ev.key === 'Escape' && !Beef.Popup.areAnyPopupsVisible()) this.close();
});


// uncomment this and load a dashboard with asEmail=true in the URL to check that widgets are correctly indicating
// completion
/*
let foo = 50
let to = setInterval(() => {
    let ready
    if (window.beIsSlideReady) console.log("beIsSlideReady " + (ready = window.beIsSlideReady()))
    if (--foo <= 0 || ready) clearInterval(to)
}, 100)
 */

Beef.addInitializer(function(startupOptions) {
    let bc = new URLSearchParams(document.location.search).get("bodyClass")
    if (bc) $(document.documentElement).toggleClass(bc, true)

    if (startupOptions.onlyShowDashboardId) {
        $('#navbar-vue').remove()
        let $doc = $(document.documentElement);
        $doc.toggleClass("fullscreen", true)
        $doc.toggleClass("single-dashboard", true)
        $doc.toggleClass("email", !!startupOptions.asEmail)
    }

    Beef.startupOptions = startupOptions;

    /** If the view is using a fixed sidebar then ensure that it is correctly positioned on scroll or resize. */
    this.autoAdjustFixedSidebar = function(view) {
        var $inner = view.$el.find(".sidebar .fixed-inner");
        if ($inner.length > 0) {
            var ctx = { inner: $inner, lastScrollTop: -1, top: 0 };
            var f = function() { positionFixedSidebar(ctx) };
            $(window).on("scroll", f);
            $(window).on("resize", f);
            view.on("close", function() {
                $(window).off("scroll", f);
                $(window).off("resize", f);
            });
            f();
        }
    };

    // detect views using sidebar-fixed and add listeners to position it properly
    var ContentRegion = Backbone.Marionette.Region.extend({
        el: "#content-region",
        show: function(view) {
            Beef.overlay.reset();
            Backbone.Marionette.Region.prototype.show.apply(this, arguments);
            Beef.autoAdjustFixedSidebar(view);
        }
    });

    var positionFixedSidebar = function(ctx) {
        var $w = $(window);
        var wh = $w.height();

        var st = $w.scrollTop();
        if (st < 0) st = 0;         // this happens on touch "bounce" above top of page when scrolling up

        var scrollHeight = $(document.body)[0].offsetHeight + 20;
        var maxSt = scrollHeight - wh;
        if (st > maxSt) st = maxSt; // this happens on touch "bounce" past bottom of page when scrolling down

        var delta = ctx.lastScrollTop < 0 ? 0 : st - ctx.lastScrollTop;
        ctx.lastScrollTop = st;

        var ch = ctx.inner.children().height() + 61; // top menu + body margin
        var scrollablePart = ch - wh;
        if (scrollablePart < 0) scrollablePart = 0;

        ctx.top -= delta;
        if (ctx.top < -scrollablePart) ctx.top = -scrollablePart;
        else if (ctx.top > 0) ctx.top = 0;

        ctx.inner.css({top: (ctx.top + 41) + "px"});
    };

    Beef.addRegions({
        content: ContentRegion,
        overlayRegion: "#overlay-region"
    });

    this.overlayRegion.show(this.overlay = Beef.ViewStack.create());

    document.addEventListener("keyup",event => {
        if (event.key === 'Escape' && !Beef.Popup.areAnyPopupsVisible() && this.overlay.currentView) {
            this.overlay.currentView.close();
        }
    });

    // log uncaught errors to Google Analytics
    var oldError = window.onerror;
    window.onerror = function beefErrorHandler(errorMsg, url, lineNumber) {
        try {
            if (oldError) return oldError(errorMsg, url, lineNumber);
            return false;
        }
        catch (e) {
            console.error("The following error occurred in the error handler: ", e);
            console.error("The original (now masked) error message that caused the handler to be called was: ", errorMsg);
            return false;
        }
    };
    
    var UserModel = Backbone.Model.extend({
        /**
         * @return {Array} An array of authorized profiles with tokens that have not expired.
         */
        getNonExpiredAuthorizedProfiles: function() {
            var authorizedProfiles = this.get("authorizedProfiles") || [];
            var profiles = [];
            for (var i = 0; i < authorizedProfiles.length; ++i) {
                if (!Beef.TokenExpirePopup.hasExpired(authorizedProfiles[i]["tokenExpire"])) {
                    profiles.push(authorizedProfiles[i]);
                }
            }
            return profiles;
        }
    });

    this.user = new UserModel({                          // The logged in user.
        id:         startupOptions.user.id,
        email:      startupOptions.user.email,
        firstName:  startupOptions.user.firstName,
        lastName:   startupOptions.user.lastName,
        language:   startupOptions.user.language,
        admin:      startupOptions.user.admin,                // Remember that all queries to our services will
        ops:        startupOptions.user.ops,
        authorizedProfiles: startupOptions.user.authorizedProfiles
    });

    let createStore =  (storage, variables) => {
        var _generalData = variables['_generalData'];

        if (startupOptions.account.code && storage.getItem(startupOptions.account.code)) {
            storage.getItem(startupOptions.account.code) ;
            try {
                var json = JSON.parse(storage.getItem(startupOptions.account.code));
                _generalData.set(json);
            }
            catch (e) {
                console.error("Error reading local storage. Emptying it out: " + e);
                storage.setItem(startupOptions.account.code, null);
            }
        }
        _generalData.on('change', function() {
            storage.setItem(variables['_generalDataCode'], JSON.stringify(Beef.Sync.cloneModel(_generalData.toJSON())));
        });

        return code => {
            if (!code) code = VuexStore.state.account.code;
            if (!code) throw new Error("Must supply an account code");
            if (code === _generalData.get('code')) return _generalData;

            variables['_generalDataCode'] = code;
            var data = storage.getItem(code);
            if (data) {
                _generalData.clear({silent: true});
                try {
                    var json = JSON.parse(data);
                    _generalData.set(json);
                }
                catch(e) {
                    console.error("Error reading local storage. Emptying it out: " + e);
                    storage.setItem(code, null);
                    _generalData.clear();
                }
            }
            else {
                _generalData.clear();
            }

            return _generalData;
        };
    };

    // A useful place to store general data. We save this on the browser.
    var localStorageVariables = {
        _generalData: new Backbone.Model({code: startupOptions.account?.code ?? null}),
        _generalDataCode: startupOptions.account?.code ?? null
    };

    this.generalData = createStore(localStorage, localStorageVariables);

    // A useful place to store session level general data. This will be cleared when the tab
    // closes. It uses sessionStorage instead of localStorage.
    var sessionStorageVariables = {
        _generalData: new Backbone.Model({code: startupOptions.account?.code ?? null}),
        _generalDataCode: startupOptions.account?.code ?? null
    };

    this.sessionData = createStore(sessionStorage, sessionStorageVariables);

    // This allows us to later determine if we have the correct data.

    // Maps logical resource names to the Grails resource plugin generated URLs. These mappings are done in
    // index.gsp. Don't reference things like /static/img/logo.png in .handlebars files, it won't work in production.
    this.resources = startupOptions.resources;

    /** Is s a possible account code? */
    this.isAccountCode = function(s) {
        return s && s.length === 8 && /[0-9A-Z]+/.test(s);
    };

    this.router = new Backbone.Marionette.AppRouter();

    this.route = function(route, name, callback) {
        // don't have access to or that are invalid
        if (!account()?.code && route.indexOf(":code") === 0) return;
        return Beef.router.route(route, name, callback);
    };

    this.view404 = new Beef.ErrorPage.View({model: new Backbone.Model()});
    this.view404.model.set({status: 404, message: "Page not found"});
    this.offlinePage = new Beef.OfflinePage.View({model: new Backbone.Model()});
    this.suspendedPage = new Beef.SuspendedPage.View({model: new Backbone.Model()});

    this.router.route("*path", "default", function(path) {
        if (path && path.length && path[path.length - 1] === '/') {
            Beef.router.navigate(path.substring(0, path.length - 1), {trigger: true});
            return;
        }
        console.warn("Bad path [" + path + ']');
        Beef.content.show(Beef.view404);
    });

    // update selected account to match the url when it changes + track page it to Google Analytics
    this.router.bind("all", function(route) {
        var fragment = Backbone.history.fragment;
        var i = fragment.indexOf('/');
        var ac = i >= 0 ? fragment.substring(0, i) : fragment;
        var isAccountCode = Beef.isAccountCode(ac);
        if (isAccountCode && !account().name) {
            setTimeout(function() {Beef.content.show(Beef.view404);});
            return;
        }

        Beef.Tooltip.close();
        window.scrollTo(0, 0);

        // try to avoid showing multiple popups
        var didShowPopup = false;
        var showPopup = isAccountCode && startupOptions.account && startupOptions.account.showNotifications;
        if (showPopup) {
            if (!didShowPopup) didShowPopup = Beef.TokenExpirePopup.showIfNotAlreadySeen();
        }

        // don't show motd if user is on account selection screen
        // if (!didShowPopup && isAccountCode) Beef.Dialogs.showMotdIfNotAlreadySeen();
        if (!didShowPopup && isAccountCode) showNewInAnalyseDialogIfNotSeen();
    });

    // Here we are going to change the default route setting
    // functionality so that route callbacks are wrapped to test
    // whether an account is currently offline / suspended.
    this.router.unwrappedRoute = this.router.route;
    this.router.route = function(route, name, callback) {
        this.router.unwrappedRoute(route, name, function() {
            // See the event handler for the account code changing to handle
            // the account code changing dynamically (since in this test, the account
            // code will be stale if that has happened).
            if (Backbone.history.fragment.indexOf(account()?.code) >= 0) {
                if (account()?.offline) {
                    setTimeout(function() {
                        Beef.content.show(Beef.offlinePage);
                    });
                    return;
                }
                else if (account().onlyAdminLogin && !isMashAdmin()) {
                    setTimeout(function() {
                        Beef.content.show(Beef.suspendedPage);
                    });
                    return;
                }
            }

            callback.apply(callback, arguments);

        }.bind(this));
    }.bind(this);




    this.on("start", function(){
        // patch Backbone so we can track page views with Google Analytics
        var navigate = Backbone.history.navigate;
        var prev = "";
        Backbone.history.navigate = function(fragment, options) {
            navigate.call(this, fragment, options);

            var url = Backbone.history.getFragment();
            var i = url.indexOf('?');
            if (i >= 0) url = url.substring(0, i);

            // don't track a page view if the user is going "up" e.g. closing the view mention dialog
            if (prev.indexOf(url) >= 0) return;
            prev = url;
        };

        Backbone.history.start({pushState: true});
    });


});
