var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"profile-tooltip\">\n    <strong>\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + " <i class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"arrow") : depth0), depth0))
    + "\"></i>\n        <span class=\"number\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"score") : depth0), depth0))
    + "</span>\n        (<span class=\"number\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"percent") : depth0), depth0))
    + "</span>)\n    </strong>\n    for <strong>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</strong>\n    <div>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"date") : depth0), depth0))
    + "</div>\n    <div style=\"text-align: center\">Total "
    + alias2(__default(require("../../../../helpers/lowerCase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"lowerCase","hash":{},"data":data,"loc":{"start":{"line":9,"column":42},"end":{"line":9,"column":60}}}))
    + ": <span class=\"number\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"total") : depth0), depth0))
    + "</span></div>\n</div>\n";
},"useData":true});