import { MentionQLexer } from "@/mentionq/mentionq";
import renderMentionVerification from '../helpers/renderMentionVerification';
import { getDeprecatedDefaultBrand } from "@/app/utils/Util";
import LoadingMessage from "../components/LoadingMessage";
import {grousePut, toGrouseLink} from "@/data/Grouse";
import {appendFiltersReadably, getBrandsInFilter, parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import {
    extractBrands,
    isNotVerifiedOnly,
    quoteIfV4Id
} from "@/dashboards/filter/BasicFilter";
import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
import {features} from "@/app/Features";
import {showErrorDialog, showWhenDialog} from "@/app/framework/dialogs/Dialog";
import {showGlossaryTip, showTip} from "@/app/help/tips/tips";
import MentionPanel from "@/app/toplevel/mentions/MentionPanel";
import {createSimpleDashboardForFilter} from "@/app/toplevel/dashboards/BeefModuleDashboardUtilities";
import VuexStore from "@/store/vuex/VuexStore";
import {escapeExpression} from "@/app/utils/StringUtils";
import _ from 'underscore';
import {setTitle} from "@/app/Beef";
import {logPageUsed} from "@/app/utils/UserAccessLog";
import MentionStats from "@/app/toplevel/mentions/components/MentionStats";
import {getDefaultBrand} from "@/app/toplevel/explore/overview/ExploreUtilities";
import SidebarBrands from "@/components/sidebar/SidebarBrands";
import {currentAccountCode} from "@/app/utils/Account";
import {getBrand} from "@/app/utils/Brands";
import {areAnyPopupsVisible} from "@/app/framework/dialogs/DialogUtilities";
import moment from "moment";
import {toEnglish} from "@/dashboards/filter/FilterToEnglish";


Beef.module("MentionPanel").addInitializer(function(startupOptions) {

    var thisModule = this;

    var nutmegUrl = startupOptions.nutmegUrl;

    let mentionStatsView = null;

    var View = Backbone.Marionette.Layout.extend({
        template: require("@/mentions/MentionPanel.handlebars"),

        attributes: { class: "row-fluid mentions-widget" },

        regions: {
            mentions: ".mentions",
            pagesRegion: ".pages-region",
            mentionOptions: ".mention-options",
            overlay: ".overlay",
            brandMenu: ".sidebar .brand-menu",
            mentionsSummaryRegion: ".mentions-summary-region",
            loadingMessage: ".mention-panel__loading"
        },

        events: {
            "click .edit-filter": "openFilterPanel",
            "click .mention-options .select-all": "selectAll",
            "click .mention-options .select-none": "selectNone",
            "click .edit-selected": "editSelected",
            "click .trash-selected": "trashSelected",
            "click .restore-selected": "restoreSelected",
            "click .send-to-crowd": "sendToCrowd",
            "click .sidebar .clear-filter": "clearFilter",
            "click .actions .mentions-menu": "displayMenu",
            "click .actions .mentions-sort-menu": "displaySortMenu",
            "click .actions .mentions-clear": "clearFilter",
            "click .actions .whip-crowd": "toggleWhipCrowd"
        },

        modelEvents: {
            "change:filter": "filterChanged",
            "change:undoFilter": "undoFilterChanged",
            "change:orderby": "orderbyChanged",
            "change:allSelected": "selectionChange",
            "change:selected": "selectionChange",
            "change:unselected": "selectionChange"
        },

        initialize: function() {
            logPageUsed("mentions-panel");
            this.model.set('title', 'Mentions');
            setTitle('Mentions');
            if (!this.model.get('orderby')) this.model.set('orderby', 'id desc');
            this.model.set('allSelected', false);
            this.model.set('selected', {});
            this.model.set('unselected', {});
            this.model.accountCode = this.accountCode = this.model.get('accountCode');

            this.cache = { }

            this.paginationModel = new Backbone.Model({offset: 0, limit: 30, page: 1});
            this.paginationModel.on('change:offset', this.onOffsetChange.bind(this));

            this.statsModel = new Backbone.Model();

            var filter = this.model.get('filter');
            if (!filter) {
                filter = Beef.generalData(this.accountCode).get('last-mention-filter')
                    || 'Relevancy ISNT IRRELEVANT AND Published INTHELAST WEEK';
                if(filter === Object(filter)) {
                    filter = 'Relevancy ISNT IRRELEVANT AND Published INTHELAST WEEK';
                }
                this.model.set('filter', filter);
            } else {
                this.updateBrandSubset();
            }

            this.model.set('total', VuexStore.state.mentionPanel.mentionCount);
            VuexStore.dispatch("mentionPanel/setFilter", this.model.get('filter')).catch(e => console.error(e));
            this.storeFilterWatcher = VuexStore.watch((state, getter) => getter['mentionPanel/filter'],
                (newValue) => {
                    this.model.set('filter', newValue);
                }
            );

            this.storeTotalWatcher = VuexStore.watch(state => state.mentionPanel.mentionCount,
                (newValue) => {
                    this.model.set('total', newValue);
                    this.paginationModel.set('total', newValue);
                }
            );

            if (this.model.get('totalSelected') == null) this.model.set('totalSelected', 0);

            renderMentionVerification.clearRuleNameCache();

            this.list = new Beef.MentionList.Collection(null, {accountCode: this.model.get('accountCode'),
                paginationModel: this.paginationModel, parentModel: this.model,
                cache: this.cache});
            this.list.on("reset", this.onMentionsFetched.bind(this));
            this.list.on("relevancyUpdated", this.onMentionRelevancyUpdated.bind(this));

            showTip("glossary:MENTION")
                .catch(e => console.warn(e))
                .finally(() => showGlossaryTip())
                .catch(e => console.warn(e));

            this.keyboardEventListenerBound = this.keyboardEventListener.bind(this);
            document.addEventListener("keyup", this.keyboardEventListenerBound, { capture: true, passive: true});
        },

        keyboardEventListener(event) {
            if (areAnyPopupsVisible()) return;
            if (event.target?.tagName === 'INPUT') return;

            switch (event.key) {
                case 'Backspace':
                    this.clearFilter(); break;
                case 'r':
                    this.refresh(); break;
                case 'f':
                    this.openFilterPanel(); break;
            }
        },

        updateBrandSubset: function() {
            if (this._ignoreBrandSubsets) {
                this._ignoreBrandSubsets = false;
                return;
            }

            var filter = this.model.get('filter');
            this.model.set('allBrandFilter', filter);

            let url = "/" + this.accountCode + "/mentions" + "?filter=" + encodeURIComponent(filter);
            let orderby = this.model.get('orderby');
            if (orderby !== "id desc") url += "&orderby=" + encodeURIComponent(orderby);
            Beef.router.navigate(url, {replace: true});

            if (this.sidebar) {
                const currentlySelected = this.sidebar.vm.activeBrandId;
                let selectedBrands = getBrandsInFilter(this.model.get('filter')).include;

                if (!selectedBrands.includes(currentlySelected)) this.sidebar.vm.activeBrandId = selectedBrands.at(0);
            }

            let subset;
            try {
                subset = toRootBrandsIds(extractBrands(filter).include);
            } catch(e) {
                // This is fine. It's not actually an error.
                console.debug("Mention panel unable to find selected brands in the filter: ", e);
                subset = [];
            }

            if (subset.length <= 1) this.model.unset('brandSubset');
            if (subset.length >= 1 || !this.model.get('brandSubset')) this.model.set('brandSubset', subset);
            if (subset.length > 1) {
                if (this.sidebar) this.selectBrand(this.sidebar.vm.activeBrandId);
                else this.selectBrand(subset[0]);
            }

            if (this.sidebar) {
                if (subset.length > 1) this.sidebar.vm.selectionIds = Array.from(subset);
                else this.sidebar.vm.selectionIds = null;
            }
        },

        onRender: function() {
            // var btv = new Beef.MentionsBrandMenu.View({model: this.model});
            // this.brandMenu.show(btv);
            // btv.on("click", function(brandId){ this.brandClicked(brandId) }.bind(this));
            let selected = null;
            try {
                selected = getBrandsInFilter(this.model.get('filter')).include.at(0) ?? null;
            } catch (e) {
                console.error(e);
            }

            const subset = this.model.get("brandSubset") ?? [];
            const selectionIds = subset?.length > 1 ? Array.from(subset) : null;

            const view = this.sidebar = new Beef.VuejsView.View({
                component: SidebarBrands,
                props: { brands: VuexStore.state.rootBrands, activeBrandId: selected, showChildren: true, selectionIds }
            });

            this.brandMenu.show(view);
            view.vm.$on('selected-id', id => this.brandClicked(id));

            this.updateSidebarMenu();

            this.mentionOptions.show(new Options({model: this.model}));

            // this.mentionsSummaryRegion.show(new Beef.MentionsSummary.View({model: this.statsModel, fixed: true}));
            mentionStatsView = new Beef.VuejsView.View({component: MentionStats});
            this.mentionsSummaryRegion.show(mentionStatsView);

            this.mentions.show(new Beef.MentionList.View({
                collection: this.list,
                accountCode: this.accountCode,
                model: this.model,
                showSingleMention: true}));

            this.pagesRegion.show(new Beef.Pager.View({model: this.paginationModel, fixedTo: this.mentions.currentView}));

            this.updateEnglishFilter();
        },

        orderbyChanged: function() {
            this.filterChanged();
        },

        filterChanged: function() {
            // calling updateBrandSubset may trigger additional filterChanged events so ignore those
            if (this._inFilterChanged) return;
            this._inFilterChanged = true;

            // noinspection JSIgnoredPromiseFromCall
            showTip("MENTION_PANEL_DASHBOARD", "UNDO_MENTIONS");

            try {
                this.updateBrandSubset();
                this.updateEnglishFilter();
                this.clearSelection();
                if (this.paginationModel.get('offset') !== 0) this.paginationModel.set({offset: 0});
                else this.fetchMentions();
                if (this.model.get('filter') !== VuexStore.getters['mentionPanel/filter']) {
                    VuexStore.dispatch("mentionPanel/setFilter", this.model.get('filter'))
                        .catch(e => console.error(e));
                }
            } finally {
                this._inFilterChanged = false;
            }
        },

        undoFilterChanged: function() {
            var previous = this.model.get("undoFilter");
            if (previous && previous !== this.model.get("filter")) {
                notifyWithText(
                    "Mention filter updated.",
                    function() { this.undo(previous) }.bind(this),
                    '<i class="icon-comment"></i>'
                )
            }
        },

        refresh: function() {
            this.fetchMentions();
            mentionStatsView.vm.refresh();
        },

        updateEnglishFilter() {
            toEnglish(this.model.get('filter'), enFilter => {
                let oen = Beef.OrderBy.getOrderByAsEnglish(this.model.get('orderby'), true);
                if (oen) enFilter += ", sorted by " + oen;
                this.model.set('englishFilter', enFilter);
            }, this.cache);
        },

        undo: function(previous, activeId) {
            previous = previous || this.model.get("undoFilter");
            if (previous && previous !== this.model.get("filter")) {
                notifyWithText("Filter changes undone.", null, '<i class="icon-comment"></i>');
                VuexStore.dispatch("mentionPanel/setFilter", previous);
                if (activeId && this.sidebar) this.sidebar.vm.activeBrandId = activeId;
                this.model.set({
                    "undoFilter": null
                });
            }
        },

        undoMenuClicked: function() {
            this.undo();
        },

        fetchMentions: function() {
            var offset = this.paginationModel.get('offset');
            this._disabled = true;
            this.$('.no-mentions').toggle(false);
            this.$('.choose-brand').toggle(false);
            this.$('.fetch-error').toggle(false);
            this.$(".mentions").toggleClass("mentions-loading", true);
            this.$(".mention-options").toggleClass("deq-disabled", true);

            if (!this.list?.length) {
                this.loadingMessage.show(new Beef.VuejsView.View({component: LoadingMessage, props: {message: "Fetching your mentions…"}}))
            }

            var filter = this.model.get('filter');
            Beef.generalData(this.accountCode).set('last-mention-filter', filter);

            if (offset === 0) this.abortStatsXhrs();

            var ans = this.list.fetch(filter, offset,
                        this.paginationModel.get('limit'), this.model.get('orderby'),
                        null, false,
                        {reset: true, error: this.onFetchMentionsError.bind(this)});

            return ans;
        },

        abortStatsXhrs: function() {
        },

        onFetchMentionsError: function(resp, status, xhr) {
            this.list.reset();

            this.$('.no-mentions').toggle(false);
            this.$('.mentions-summary-region').toggle(false);
            this.$(".mentions").toggleClass("mentions-loading", false);
            this.$(".mention-options").toggleClass("deq-disabled", false);
            var $fetchError = this.$('.fetch-error');
            $fetchError.toggle(false);
            this.$('.choose-brand').toggle(false);
            this.$('.errors').toggle(true);
            this._disabled = false;

            var code = status.status;
            if (code === 422 && status.responseText && status.responseText.indexOf("[BRAND-ERROR]") > 0) {
                // See if we can figure out a brand to use.
                try {
                    var brand = getDeprecatedDefaultBrand(true);
                    if (!brand || !brand.id) {
                        this.$('.choose-brand').toggle(true);
                        return;
                    }

                    this.selectBrand(brand.id);
                } catch(error) {
                    console.warn(error);
                    this.$('.choose-brand').toggle(true);
                }
                return;
            }
            let msg;
            if (code === 400 || code === 422) {
                if (status.responseText.indexOf("[BRAND-ERROR-MULTIPLE]") >= 0) {
                    msg = "Please select a brand from the menu on the left"
                } else if (status.responseJSON?.error?.startsWith('Invalid mention id')) {
                    msg = "Your filter uses a badly formatted mention ID"
                } else if (status.responseJSON?.error?.startsWith("Cannot use offset with multiple brands")) {
                    msg = "Please select only a single brand to view";
                } else if (status.responseJSON?.error?.startsWith("Invalid brand ID:")) {
                    msg = "Your filter contains a brand that's not in your account";
                } else {
                    msg = "Unable to fetch mentions, there may be an issue with your filter";
                    // Let's check if the filter is bad.
                    try {
                        if (this.model.get('filter')) {
                            parseFilterString(this.model.get('filter'));
                        }
                    } catch(e) {
                        console.warn("The mention panel filter is malformed: ", e);
                        msg = "Your filter has a mistake in it";
                        if (VuexStore.state.user.admin) msg += ": " + e;
                    }
                }
            } else if (code ) {
                msg = "Something has gone wrong, please try again later (" + code + ")"
            } else {
                msg = "Unable to reach DataEQ, please check your internet connection and try again"
            }
            $fetchError.html('<i class="symbol-warning"></i>' + escapeExpression(msg));
            $fetchError.toggle(true);
            this.paginationModel.set('total', 0);
            this.model.set('total', null)
        },

        onMentionsFetched: function() {
            let noMentions = this.list.length === 0 && this.paginationModel.get('offset') === 0;
            this._disabled = false;
            this.$('.errors').toggle(false);
            this.$('.no-mentions').toggle(noMentions);
            this.$('.mentions-summary-region').toggle(!noMentions);
            this.$(".mentions").toggleClass("mentions-loading", false);
            this.$(".mention-options").toggleClass("deq-disabled", false);
            this.loadingMessage?.reset();

            this.updateSidebarMenu();

            showTip(
                "MENTIONS_NAVIGATE", "SORT_MENTIONS",
                "FILTER_MENTIONS", "SELECT_BRAND_MENTIONS",
                "DOWNLOAD_MENTION_AS_IMAGE",
                "TRANSLATE_MENTION",
                "MENTIONS_FILTER_SHORTCUT",
                "MENTIONS_REFRESH",
                "MENTIONS_CLEAR_FILTER"
            ).catch(e => console.warn(e));
            showGlossaryTip().catch(e => console.warn(e));
        },

        onClose: function() {
            if (this.storeFilterWatcher) this.storeFilterWatcher();
            document.removeEventListener("keyup", this.keyboardEventListenerBound, true);
        },

        displayMenu: function(ev) {
            var that = this;

            var mm;
            var filter = this.model.get('filter');

            var ids;
            if (this.model.get('allSelected')) {
                ids = _(this.model.get('unselected')).keys();
                if (ids.length > 0) filter += " and id notin (";
            } else {
                ids = _(this.model.get('selected')).keys();
                if (ids.length > 0) filter = "id in (";
            }
            if (ids.length > 0) {
                for (var i = 0; i < ids.length; i++) {
                    if (i > 0) filter += ",";
                    filter += '"' + ids[i] + '"';
                }
                filter += ")";
            }

            var csvLink = toGrouseLink("/v4/accounts/" + this.model.get('accountCode') + "/mentions.csv", {
                filter: filter,
                select: thisModule.MENTIONS_CSV_SELECT,
                limit: 100000
            });
            mm = new Backbone.Model({csvLink: csvLink});

            Beef.MiniMenu.show({
                template: require("@/mentions/MentionsMenu.handlebars"),
                object: this,
                model: mm,
                target: $(ev.target).closest("a"),
                onRender: function() {
                    var disable = !that.model.get('allSelected') && !_(that.model.get('selected')).size() > 0;
                    this.$('.menu-send-to-crowd').toggleClass('disabled', disable);
                    this.$('.menu-rephrase-match').toggleClass('disabled', disable);

                    this.$('[data-method="undoMenuClicked"]').toggleClass('disabled', !that.model.get("undoFilter"));

                    var nonSelected = !that.model.get('allSelected') && !_(that.model.get('selected')).size() > 0;
                    var $email = this.$('.email-mentions');
                    $email.toggleClass('disabled', nonSelected);
                    $email.attr('title', nonSelected ? 'Please select mentions to email to someone': 'Send the selected mentions to someone via email');

                    this.$el.toggleClass("mini-menu-selectable");
                    if (that.model.get('whipCrowd')) this.$('a.whip-crowd').toggleClass("selected");
                }
            });
        },

        displaySortMenu: function(ev) {
            Beef.OrderBy.displayOrderByMenu(this, $(ev.target).closest('a'));
        },

        // This is for the side bar. It removes all the items that the sidebar could effect from the filter.
        getResetFilter: function() {
            var filter = removeNodes(parseFilterString(this.model.get('filter')), function(node) {
                return node.operandType === MentionQLexer.BRAND
            });
            if (filter) return filter.toString();
            return "";
        },

        brandClicked: function(brandId) {
            const subsets = this.model.get("brandSubset") ?? [];

            // if we only have 1 brand in subsets, then don't worry about excluding sub brands
            // if parent brand is in filter
            if (subsets?.length > 1) {
                const parents = [brandId];
                let brand = VuexStore.getters.idToBrand.get(brandId);
                while (brand) {
                    brand = brand.parent
                    if (brand?.id) parents.push(brand.id);
                }

                if (parents.some(id => subsets.includes(id))) {
                    // ensure that child brand doesn't get added to brand subsets if parent brand is already part of them
                    this._ignoreBrandSubsets = true;
                }
            }

            this.selectBrand(brandId);
        },

        selectBrand: function(brandId) {
            this.clearSelection();
            var filter = this.getResetFilter();
            this.model.set({filter: appendFiltersReadably(filter, `brand isorchildof ${brandId}`)});
            if (this.sidebar) this.sidebar.vm.activeBrandId = brandId;
            this.updateSidebarMenu();
        },

        clearSelection: function() {
            this.model.set('allSelected', false);
            this.model.set('selected', {});
            this.model.set('unselected', {});
        },

        updateSidebarMenu: function() {
            this.model.set("notAllowed", "");
        },

        openFilterPanel(event) {
            event?.preventDefault();
            if (this._disabled) return;

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["bottom-right"],
                alwaysMove: true
            });

            popup.setTarget(this.$el[0].querySelector('.edit-filter'));
            var view = new FilterPanel({model: this.model, list: this.list, cache: this.cache});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        onOffsetChange: function() {
            this.model.set("notAllowed", "");
            // weird stuff happens with true here or trying to scroll to just the first mention
            $(".mention-options")[0].scrollIntoView(false);
            this.fetchMentions();
        },

        selectAll: function(ev) {
            selectAllMentions(this.model, true);
            this.selectionChange();
        },

        selectNone: function(ev) {
            selectAllMentions(this.model, false);
            this.selectionChange();
        },

        editSelected: function(ev) {
            ev.preventDefault();
            var totalSelected = this.model.get('totalSelected');
            if (!totalSelected) return;

            var view = new Beef.EditSelectedMentions.View({
                model: new Backbone.Model({totalSelected: totalSelected}),
                list: this.list,
                filter: this.model.get('filter'),
                accountCode: this.model.get('accountCode'),
                parent: this});

            var popup = new Beef.Popup.View({ closeOnHide:true, alwaysMove:true });
            popup.setTarget($(ev.target));
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        trashSelected: function(ev) {
            ev.preventDefault();
            var totalSelected = this.model.get('totalSelected');
            if (!totalSelected || this.model.get("notAllowed") === "trash") return;

            if (this.model.get('allSelected') && !this.filterVerificationAllowsTrashing(this.model.get('filter'))) return;

            showWhenDialog("Delete mentions?", "Are you sure you want to delete " + this.model.get('totalSelected') + " mentions?")
                .then(function() {
                    this.trashMentions(true);
                }.bind(this));
        },

        restoreSelected: function(ev) {
            ev.preventDefault();
            var totalSelected = this.model.get('totalSelected');
            if (!totalSelected || this.model.get("notAllowed") == "restore") return;
            showWhenDialog("Restore mentions?", "Are you sure you want to restore " + this.model.get('totalSelected') + " mentions?")
                .then(function() {
                    this.trashMentions(false);
                }.bind(this));
        },

        filterVerificationAllowsTrashing: function(filter) {
            if (!isNotVerifiedOnly(filter)) {
                showErrorDialog(
                    "The filter you are using to select these mentions may also be selecting verified mentions. Please update this to 'Not Verified' and try again.",
                    "Cannot delete verified mentions"
                );
                return false;
            }

            return true;
        },

        trashMentions: function(trash) {
            var filter = this.model.get('filter');
            var ids;
            if (this.model.get('allSelected')) {
                if (!this.filterVerificationAllowsTrashing(filter)) return;
                ids = _(this.model.get('unselected')).keys();
                if (ids.length > 0) filter = "(" + filter + ") and id notin (" + toCommaList(ids) + ")";
            } else {
                ids = _(this.model.get('selected')).keys();
                if (ids.length == 0) return;
                filter = "id in (" + toCommaList(ids) + ")";
            }

            var payload = { filter: filter, relevancy: trash ? 'IRRELEVANT' : 'RELEVANT' };

            this.$(".mentions-summary").toggleClass("mentions-loading", true);
            this.$(".mentions").toggleClass("mentions-loading", true);

            grousePut("/v4/accounts/" + currentAccountCode() + "/mentions", payload)
                .then(() => {
                    this.clearSelection();
                    this.fetchMentions();
                })
                .catch(() => {
                    window.alert("Error updating mentions");
                    this.clearSelection();
                    this.fetchMentions();
                });
        },

        async toNotification() {
            window.notificationData = { filter: this.model.get('filter') }
            Beef.router.navigate(`${this.model.get('accountCode')}/setup/notifications`, {trigger: true});
        },

        async toDashboard() {
            await createSimpleDashboardForFilter(this.model.get('filter'));
        },

        clearFilter: async function(ev) {
            let filter = 'published inthelast week and relevancy isnt irrelevant';
            const defaultBrand = await getDefaultBrand();
            const oldActive = this.sidebar?.vm?.activeBrandId;
            var undoFilter = this.model.get("filter") !== filter ? this.model.get("filter") : null;
            const oldFilter = this.model.get('filter');

            if (defaultBrand?.id) {
                filter = appendFiltersReadably(filter, `brand isorchildof ${defaultBrand.id}`);
                if (this.sidebar) this.sidebar.vm.activeBrandId = defaultBrand.id;
            }

            if (this.sidebar) {
                this.sidebar.vm.collapseAllBrands();
            }

            VuexStore.commit("mentionPanel/setFilterAddendums", []);
            this.model.unset("allBrandFilter");
            await VuexStore.dispatch("mentionPanel/setFilter", filter);
            if (filter !== oldFilter) {
                notifyUser({
                    message: "Mention filter cleared.",
                    icon: '<i class="symbol-mentions"></i>',
                    undo: !undoFilter ? null : () => this.undo(undoFilter, oldActive)
                })
            }
        },

        seeAuthors: function() {
            Beef.AuthorsSectionV4.navigateToAuthors(this.model.get('accountCode'), this.model.get('filter'));
        },

        buildSelection: function(noneIsAll) {
            var ids = [];
            var filter = "";
            var total = this.model.get("totalSelected");

            if (this.model.get('allSelected')) {
                filter = this.model.get("filter");
                if (_(this.model.get('unselected')).keys().length != 0)
                    filter = filter + encodeURIComponent(' and id notin (' +
                        _(this.model.get('unselected')).keys().join(',') + ')');
            } else if (_(this.model.get('selected')).keys().length != 0) {
                ids = _(this.model.get('selected')).keys().join(',');
            } else if (noneIsAll) {
                total = this.model.get("total");
                filter = this.model.get("filter");
            }

            return { ids: ids, filter: filter, total: total }
        },

        sendToCrowd: function() {
            Beef.SendToCrowd.showInPopup(this.model.get("accountCode"), this.buildSelection(true), this);
        },

        toggleWhipCrowd: function() {
            this.model.set('whipCrowd', !this.model.get('whipCrowd'));
        },

        copyToOtherAccounts: function() {
            var url = nutmegUrl + "/copy-mentions?fromAccount=" + currentAccountCode() +
                "&filter=" + encodeURIComponent(this.model.get('filter'));
            var win = window.open(url, '_blank');
            win.focus();
        },

        comparePredicted: function() {
            Beef.Mentions.ComparePredicted.showInPopup(this.model.get("accountCode"), this.buildSelection(true));
        },

        selectionChange: function() {
            // this stuff should really be done by Options itself
            var totalSelected = 0;
            if (this.model.get('allSelected')) {
                totalSelected = this.model.get('total') - _(this.model.get('unselected')).keys().length;
            } else {
                totalSelected = _(this.model.get('selected')).keys().length;
            }
            this.model.set('totalSelected', totalSelected);
            if (!totalSelected) {
                this.$('.edit-selected').toggleClass('disabled', true);
                this.$('.restore-selected').toggleClass('disabled', true);
                this.$('.trash-selected').toggleClass('disabled', true);
                this.$('.mentions').removeClass("mentions-selected");
            } else {
                this.$('.edit-selected').toggleClass('disabled', false);
                this.$('.restore-selected').toggleClass('disabled',  this.model.get("notAllowed") == "restore");
                this.$('.trash-selected').toggleClass('disabled', this.model.get("notAllowed") == "trash");
                this.$('.mentions').addClass("mentions-selected");
            }
        },

        emailMentions: function() {
            var $button = this.$('.mentions-menu');
            var selection = this.buildSelection();
            var ids = null;

            if (selection.ids.length) {
                ids = selection.ids.split(',');
            }

            Beef.InteractDialog.show({
                target: $button,
                accountCode: this.model.get('accountCode'),
                email: true,
                mentionIds: ids,
                filter: selection.filter,
                filterTotal: selection.total
            });
        },

        onMentionRelevancyUpdated: function(model) {
            alert("This is currently not implemented. Please contact David or Connie if you need it");
            if (this.model.get('whipCrowd') && model.get('crowdVerify')
                    && "IRRELEVANT" == model.get('relevancy')) {
                $.ajax({
                    type: "PUT",
                    url: "/api/crowds/mentions",
                    contentType: "application/json",
                    data: JSON.stringify({
                        id: model.get('id'),
                        relevancy: model.get('relevancy'),
                        crowdJobs: model.get('crowdJobs')
                    })
                });
            }
        }
    });

    this.MENTIONS_CSV_SELECT = "id,uri,link,title,extract,published,brand," +
        "visibility.label,authorId,authorName,authorHandle,authorHandleId,authorPictureLink,authorProfileLink," +
        "authorBio,authorLocation,authorTimezone,replyToUri,replyToId,reshareOfUri,reshareOfId,toId,toName," +
        "toHandle,toHandleId,postExtract,relevancy,relevancyVerified,sentiment,sentimentVerified," +
        "crowdVerified,pickedUp,city.name,region.name,country.name,language.name,category.label,gender.label," +
        "race.label,engagement,OTS,ticketId,tags,interactionId";

    var selectAllMentions = function(model, selectAll) {
        model.set('selected', {});
        model.set('unselected', {});
        model.set('allSelected', selectAll);
        $('.mention-item').toggleClass('selected', selectAll);
    };

    var Options = Backbone.Marionette.ItemView.extend({
        template: require("@/mentions/Options.handlebars"),
        modelEvents: { "change": "render" }
    });

    var FilterPanel = Beef.SettingsDialog.View.extend({
        template: require("@/mentions/Filters.handlebars"),
        attributes: { class: "dialog filter-dialog" },
        editAttributes: ['filter', 'allBrandFilter'],
        regions: {
            filters: ".filter"
        },
        events: Object.assign({}, Beef.SettingsDialog.View.prototype.events,{
            "click a.btn.advanced": "toggleAdvanced",
            "click a.btn.basic": "toggleBasic",
        }),
        initialize: function(options) {
            this.list = options.list;
            this.cache = options.cache
            Beef.SettingsDialog.View.prototype.initialize.call(this);
        },
        onFirstRender: function() {
            this.model.accountCode = this.originalModel.get('accountCode');
            var filter = new Beef.Filter.View({model: this.model, cache: this.cache, showTrash: true, noToggle: true});
            filter.on("editor-changed", function(ed) {
                this.$el.attr('data-editor', ed);
            }.bind(this));
            this.filters.show(filter);

        },
        ok: function() {
            // whether or not we should trigger the filter change manually.
            let triggerFilterChange = false;

            selectAllMentions(this.model, false);
            var oldFilter = this.originalModel.get('allBrandFilter') || this.originalModel.get("filter");

            const newSelectedBrands = getBrandsInFilter(this.model.get('filter')).include;
            const oldSelectedBrands = getBrandsInFilter(this.originalModel.get('allBrandFilter')).include;

            if (newSelectedBrands && oldSelectedBrands) {
                /**
                 * Only trigger filter change manually if we:
                 * 1. previously had more than 1 brand in allBrandFilter
                 * 2. currently only have 1 brand in filter
                 * 3. the first brand (brand selected within updateBrandSubset {@link updateBrandSubset}) is the same as the first brand in the new filter
                 */
                if (oldSelectedBrands.length > 1 && newSelectedBrands.length === 1 && (newSelectedBrands?.at(0) === oldSelectedBrands?.at(0))) {
                    triggerFilterChange= true;
                }
            }

            var attrs = this.model.getAttrs(this.resolveEditAttributes());
            this.originalModel.set(attrs);
            var newFilter = this.originalModel.get("filter");
            if (triggerFilterChange) {
                this.originalModel.trigger("change:filter");
            }
            if (newFilter !== oldFilter) this.originalModel.set("undoFilter", oldFilter);
            this.close(true);
        },
        toggleAdvanced: function() {
            if (this.filters && this.filters.currentView) {
                this.filters.currentView.changeEditor("advanced");
            }
        },
        toggleBasic: function() {
            if (this.filters && this.filters.currentView) {
                this.filters.currentView.changeEditor("basic");
            }
        }
    });

    var routeCallback = function(code) {
        const params = new URLSearchParams(window.location.search)

        let filter = params.get('filter');
        let orderby = params.get("orderby");

        if (!Beef.isAccountCode(code)) {
            Beef.content.show(Beef.view404);
            return
        }

        if (features.newMentionsPanel()) {
            const view = new Beef.VuejsView.View({component: MentionPanel, props: {initialFilter: filter}});
            Beef.content.show(view)
        } else {
            var model = new Backbone.Model({
                accountCode: code,
                filter: filter
            });
            if (orderby) model.set('orderby', orderby);
            var view = new View({model: model});
            Beef.content.show(view);
            view.fetchMentions();
        }
    };

    var routeCallbackForBrands = function(code, brand) {
        if (!Beef.isAccountCode(code)) {
            Beef.content.show(Beef.view404);
            return
        }
        var model = new Backbone.Model({
            accountCode: code,
            filter: "published inthelast week and Relevancy isnt irrelevant and brand isorchildof " + brand
        });
        var view = new View({model: model});
        Beef.content.show(view);
        view.fetchMentions();
    };

    var routeCallbackForId = function(code, mentionId) {
        if (!Beef.isAccountCode(code)) {
            Beef.content.show(Beef.view404);
            return
        }

        const before = moment()
            .add(1, "day")
            .format("YYYY/MM/DD");
        const after = moment()
            .subtract("5", "years")
            .format("YYYY/MM/DD");

        let model = new Backbone.Model({
            accountCode: code,
            filter: `Published AFTER '${after}' AND Published BEFORE '${before}' AND ID IS ${quoteIfV4Id(mentionId)}`
        });
        let view = new View({model: model});
        Beef.content.show(view);
        view.fetchMentions();
    };

    var routeCallbackForConversation = function(code, mentionId) {
        if (!Beef.isAccountCode(code)) {
            Beef.content.show(Beef.view404);
            return
        }
        var model = new Backbone.Model({
            accountCode: code,
            filter: "id is " + quoteIfV4Id(mentionId) + " and published inthelast year"
        });
        var view = new View({model: model});
        Beef.content.show(view);
        view.fetchMentions();
        view.list.on('reset', function() {
            Beef.MentionConversation.show(view.list.models[0], code);
        });
    };

    Beef.route(":code/mentions", "mentions", routeCallback);
    Beef.route(":code/mentions/:mentionId", "mentions", routeCallbackForId);
    Beef.route(":code/mentions/:mentionId/conversation", "mentions", routeCallbackForConversation);

    // NOTE: as of Backbone 1.1.1, routes specified as strings should no longer include query parameters. All parameters are passed to handlers as the last argument
    // See http://backbonejs.org/#changelog
    // TODO: fix all routes
    // Beef.route(":code/mentions?filter=:filter", "mentions", routeCallback);
    // Beef.route(":code/mentions?filter=:filter&orderby=:orderby", "mentions", routeCallback);

    Beef.route(":code/mentions/brand/:brandId", "mentions", routeCallbackForBrands);

    var toCommaList = function(ids) {
        var a = [];
        for (var i = 0; i < ids.length; i++) a.push("'" + ids[i] + "'");
        return a.join(",");
    };

    var toRootBrandsIds = function(brandIds) {
        var ans = [];
        for (var i = 0; i < brandIds.length; i++) {
            var b = getBrand(brandIds[i]);
            if (b) {
                while (b.parent) b = b.parent;
                if (ans.indexOf(b.id) < 0) ans.push(b.id);
            }
        }
        return ans;
    }
});
