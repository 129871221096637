export const definitions = [
    {
        id: "custom-scs",
        name: "Social Customer Service",
        title: "Social Customer Service terms",
        subTitle: "report on the below key data fields",
        terms: [
            {
                name: "Social Customer Service (SCS)",
                description: "Social customer service refers to the practice of using social media platforms and other digital channels to provide customer support, assistance, " +
                    "and engagement. It involves responding to customer inquiries, addressing their concerns, and providing solutions or answers to their questions in a timely and effective manner."
            },
            {
                name: "Interaction",
                description: `An "interaction" refers to a single work item that a brand needs to address. It consists of a mention (post) or set of mentions (posts) from a consumer, followed by a reply or set of replies from a brand (within a conversation).
                    <br>
                    <br>
                    To keep track of different interactions, a new interaction is formed in the following scenarios:
                    <ul>
                        <li>If the consumer posts again, after the brand reply, this starts a new interaction.</li>
                        <li>If there is a gap of more than 14 days between posts from the consumer or brand.</li>
                        <li>If the author switches between private and public communication.</li>
                    </ul>`
            },
            {
                name: "Interaction ID",
                description: `An Interaction ID is a unique identifier assigned to an interaction.`
            },
            {
                name: "Unique interactions",
                description: "The total number of unique interactions captured in a specific data set."
            },
            {
                name: "Interactions replied to",
                description: "The total number of unique interactions responded to, by the brand, within a specific data set."
            },
            {
                name: "Interaction response rate",
                description: "The percentage of all interactions responded to by the brand, within a specified data set."
            },
            {
                name: "Response time",
                description: `Response time refers to the amount of time it takes for a brand to respond to a customer's inquiry or message on a serviceable social media platform, such as Facebook, Twitter, Hellopeter or Instagram.`
            },
            {
                name: "Response time (working hours)",
                description: `Response Time (during working hours) refers to the amount of time it takes for a brand to respond to a customer's inquiry or message on a serviceable social media platforms, while taking into account their scheduled work hours.
                            <br>
                            <br>
                            To measure the response time during working hours, a timer is started at the beginning of the interaction, 
                            paused outside of work hours, and resumed during the next scheduled work hours until the end of the interaction.`
            },
            {
                name: "First response time",
                description: "The duration of time between the first customer mention (the start of an interaction) and the first brand response. Represented as an average of the selected data set."
            },
            {
                name: "Follow-up response time",
                description: "The duration of time between the first and last brand mention (post) in an interaction. Represented as an average of the selected data set."
            }
        ]
    }
]

export function getDefinitionsList(id) {
    for (const definition of definitions) {
        if (definition.id === id) return definition;
    }

    return null;
}