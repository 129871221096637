var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"control-group control-group-spacer control-group-colour\">\n            <label class=\"checkbox\" title=\"No date selection\">\n                <input class=\"no-date\" type=\"checkbox\" checked>\n                No date selection\n            </label>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div>\n            <a class=\"btn btn-link time-button\">Time <span class=\"caret\"></span></a>\n            <div class=\"time-display row-fluid\" style=\"display: none\">\n                <div class=\"span7\">\n                    <p>\n                        <label for=\"from-time\">From</label>\n                        <input id=\"from-time\" class=\"time-input\"\n                               type=\"text\"\n                               placeholder=\"from\"\n                               title=\"Type in a time to begin from\">\n                        on <span class=\"from-date\"></span>\n                    </p>\n                    <p>\n                        <label for=\"to-time\">to</label>\n                        <input id=\"to-time\"\n                               class=\"time-input\"\n                               type=\"text\"\n                               placeholder=\"time\"\n                               title=\"Type in a time to end at (inclusive)\">\n                        on <span class=\"to-date\"></span>.\n                    </p>\n                </div><div class=\"span5 error-message\">\n                </div>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-body dialog-body-notitle\">\n\n    <div class=\"control-group\">\n        <label>\n             "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"overall") : stack1), depth0))
    + "\n        </label>\n        <div class=\"controls btn-group\">\n            <a class=\"btn\" data-value=\"TODAY\" title=\"Select "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"multiple") : stack1), depth0))
    + " only from today\">Today</a>\n            <a class=\"btn\" data-value=\"24HOURS\" title=\"Select "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"multiple") : stack1), depth0))
    + " only from the last 24 hours\">24 Hours</a>\n            <a class=\"btn\" data-value=\"WEEK\" title=\"Select "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"multiple") : stack1), depth0))
    + " from the last week\">Week</a>\n            <a class=\"btn\" data-value=\"MONTH\" title=\"Select "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"multiple") : stack1), depth0))
    + " from the last month\">Month</a>\n            <a class=\"btn\" data-value=\"QUARTER\" title=\"Select "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"multiple") : stack1), depth0))
    + " from the last three months\">Quarter</a>\n            <a class=\"btn\" data-value=\"YEAR\" title=\"Select "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"multiple") : stack1), depth0))
    + " from the last year\">Year</a>\n            <a class=\"btn\" data-value=\"CUSTOM\" title=\"Select "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"promptText") : depth0)) != null ? lookupProperty(stack1,"multiple") : stack1), depth0))
    + " from a custom date range\">\n                Custom\n            </a>\n        </div>\n        <div class=\"help-block\">\n            <span class=\"range\"></span>\n            <span class=\"days-container\">(<span class=\"days\"></span>)</span>\n        </div>\n    </div>\n\n    <div class=\"cals\">\n        <div class=\"cal-wrapper\">\n            <h6>Start Date</h6>\n            <div class=\"start-calendar\"></div>\n        </div>\n\n        <div class=\"cal-wrapper\">\n            <h6>End Date</h6>\n            <div class=\"end-calendar\"></div>\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"notRequired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":43,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"timeDisabled") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":71,"column":15}}})) != null ? stack1 : "")
    + "</div>\n\n\n";
},"useData":true});