/**
 * Select tags to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
Beef.module("IncExTagPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/incextag/IncExTagPicker.handlebars"),
        items: Beef.TagPicker.items,
        bindings: {
            inc: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" },
            ex: { converterFactory: Beef.TagPicker.converterFactory, elAttribute: "data-value" }
        },
        attachPickers: function() {
            Beef.TagPicker.attach(this, ".inc", "inc");
            Beef.TagPicker.attach(this, ".ex", "ex");
        }
    });

    this.converterFactory = Beef.TagPicker.createConverterFactory("Custom tags", {showWarning: true});

    /**
     * Attach the picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        var options = {
            mouseover: function(item, node) { Beef.TagPicker.tooltip(view, item, node); }
        };

        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
