/**
 * A picker for dashboard categories. The picker restricts input to only one category per dashboard.
 */
import {deprecatedMashGet} from "@/app/Mash";
import _ from 'underscore';
import {isString} from "@/app/utils/StringUtils";
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";

Beef.module("DashboardCategoryPicker").addInitializer(function(startupOptions) {

    /**
     * Creates an object of dashboard categories from an array of dashboards.
     * @param dashboards {Object[]} An array of dashboard objects.
     * @returns {Object} An object whose keys are identical to its values. The values are set to dashboard categories.
     */
    var extractCategories = function(dashboards) {

        var items = {};
        var categories = _.chain(dashboards)
            .pluck("category")
            .unique()
            .filter(function(category) {
                return (!_.isUndefined(category) && category.trim().length > 0)
            })
            .value();

        for (var i = 0; i < categories.length; ++i) {
            items[categories[i]] = categories[i];
        }
        return items;
    };

    var fetchDashboards = function(viewOrCode, callback, cache) {
        if (!cache) cache = viewOrCode.cache = viewOrCode.cache || {};
        if (cache.categories) {
            callback(cache.categories);
        } else {
            var code = isString(viewOrCode) ? viewOrCode : viewOrCode.model.getAncestorProperty("accountCode");
            deprecatedMashGet("accounts/" + code + "/reports", null, function(data){
                data = extractCategories(data);
                callback(cache.categories = data);
            });
        }
    };

    this.items = function(q, view, callback) {
        fetchDashboards(view, callback);
    };

    this.View = Beef.AutoCompletePicker.View.extend({

        attributes: { class: "auto-complete-picker" },

        items: this.items,

        /**
         * @param s {string} An input string.
         * @returns {string} A trimmed string with all redundant whitespace removed.
         */
        cleanupInput: function(s) {
            if (isString(s)) s = s.replace(/\s+/g, ' ').trim();
            return s;
        },

        /** If item is a string then see if it contains s, otherwise assume it is an array and test the last element.
         * @param s {string} A string to search for.
         * @param item {(string|string[])} The string or array to search within.
         * @returns {boolean} True if contained, otherwise false.
         */
        contains: function(item, s) {
            if (typeof s === "string") s = s.toLowerCase().trim();
            if (item.toLowerCase) return item.toLowerCase().indexOf(s) >= 0;
            var last = item[item.length - 1];
            return last !== undefined && last.toLowerCase && last.toLowerCase().indexOf(s) >= 0
        }
    });

    // since the value is always atomic, the splitter returns an array containing the value
    var splitter = function(value) {
        return [value];
    };

    this.createConverterFactory = function(placeholder) {
        return function(view) {
            var converter;
            return function(direction, value) {
                if (Number.isFinite(value)) value = "" + value;
                if (value && value.length > 0) {
                    var binding = this;
                    if (converter) return converter.call(binding, direction, value);
                    fetchDashboards(view, function() {
                        converter = createTagConverter({
                            items: _.identity,
                            placeholder: placeholder,
                            splitter: splitter,
                            noNeg: true,
                            acceptNew: true
                        });
                        converter.call(binding, direction, value);
                    });
                } else {
                    if (placeholder) $(this.boundEls[0]).html(toPlaceholderHTML(placeholder));
                    else $(this.boundEls[0]).text("");
                }
                return value;
            }
        }
    };

    this.converterFactory = this.createConverterFactory(null);

    /**
     * Attach a tag picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        options = Object.assign({
            mustSelectFromItems: false,
            forceTagRemove: true,
            splitter: splitter,
            onlyOne: true
        }, options || {});
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };
});
