<template>
    <widget-settings-dialog width="650px" :model="model" :dto="dto" @input="$emit('input', $event)" no-focus>
        <template #title-inputs="{options}">
            <drop-down-input v-model="attrs.fontSize" label="Font size" :options="options.fontSize" style="width: 60px"/>
        </template>
        <div style="margin-top: 10px">
            <markdown-editor v-model="attrs.text" class="comment-editor" ref="editor"/>
        </div>
    </widget-settings-dialog>
</template>

<script>
    import WidgetSettingsDialog from "../WidgetSettingsDialog"
    import DropDownInput from "../../../components/inputs/DropDownInput"
    import MarkdownEditor from "../../../components/inputs/MarkdownEditor"

    export default {
        name: "CommentSettingsDialog",

        components: { WidgetSettingsDialog, DropDownInput, MarkdownEditor },

        props: {
            model: Object
        },

        data() {
            return {
                dto: {
                    attrs: { }  // this gets set by widget-settings-dialog
                }
            }
        },

        computed: {
            attrs() { return this.dto.attrs }
        },

        mounted() {
            this.$nextTick(() => this.$refs.editor.focus())
        }
    }
</script>

<style scoped>
    .comment-editor ::v-deep textarea {
        min-height: 300px;
    }
</style>