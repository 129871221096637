/**
 *This just counts mentions, with no grouping. It also ignores
 *any additional changes made to the filter (such as filtering on topics,
 *or adding process is verified, and so on. This is useful if a "population size"
 *needs to be calculated from a crowd sample.
 */
import {grouseGet} from "@/data/Grouse";
import {buildBasicFilter, convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import {FANTASTIC_FIELDS} from "@/dashboards/widgets/fantasticchart/fields/Fields";
import {currentAccountCode} from "@/app/utils/Account";

export function countPopulation(model, query) {
    const fields = FANTASTIC_FIELDS;
    const xAxis = model.get("xAxis") || "published";
    const xField = fields[xAxis] || {};
    const compare = model.get("compare") || "published";
    const compareField = compare && fields[compare] || {};
    const show = model.get('show');

    const anyYExcludes = show.some(s => {
        const field = fields[s.yAxis] || {};
        return !!field.excludeMoE;
    });

    if (!xField.calculateMoe && !compareField.calculateMoe || anyYExcludes) {
        return Promise.resolve({mentionCount: 0});
    }

    const fromGrouse = model.getSectionModel()
        ? model.getSectionModel().view.getJsonFromGrouse.bind(model.getSectionModel().view)
        : grouseGet;

    // We don't want to build the population from only verified data. If the user
    // has selected only verified data, we need to remove it.
    let filter = model.get("_effectiveFilter");
    try {
        const attrs = convertFilterToAttrs(query.filter);
        attrs.verification = null;
        filter = buildBasicFilter(attrs);
    } catch (e) {
        console.warn("Unable to remove verified status from filter");
    }

    return fromGrouse('/v4/accounts/' + currentAccountCode() + '/mentions/count', {
        filter: filter
    });
}

export function createGroupExcluding(groupBy, ignore, item) {
    return groupBy.filter(function (g) {
        return g !== item && !ignore.includes(g);
    });
}

export function createSelectExcluding(query, ignore, item) {
    if (!query.select) return [];

    return query.select.split(',').filter(function (g) {
        if (g.endsWith(".*")) g = g.substring(0, g.length - 3);
        return g !== item && !ignore.includes(g);
    });
}