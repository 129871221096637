import {setTitle} from "@/app/Beef";

/**
 * Displays information about the account being offline.
 */
Beef.module("OfflinePage", function(startupOptions) {

    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/app/OfflinePage.handlebars"),
        attributes: {class: "row-fluid"},

        modelEvents: { "change": "render" },
        onShow: function() {
            setTitle("Offline")
        }
    });

});
