<template>
    <div v-if="debug" class="debug-feature">
        <slot/>
    </div>
</template>


<script>
import {isDebugModeEnabled} from "@/app/Features";

export default {
    data() {
        return {
            debug: isDebugModeEnabled()
        }
    },
}
</script>

<style scoped>
.debug-feature {
    display: contents;
}
</style>