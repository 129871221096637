<template>
    <div v-if="showRule"
         class="rule-row" :class="{'expanded': expanded}" :style="dragContainerStyle"
         tabindex="-1" @click="expand()" @keydown.esc.stop="expand(false)"
         :draggable="dragEnabled" @dragstart="startDrag($event)" @dragend="stopDrag" @dragover="onDragOver($event)" >
        <div v-if="!expanded" class="rule-row__rule-container" :style="gridColumnStyle">
            <div class="rule-row__data">
                <span class="rule-info">
                    <span>{{ rule.name }}</span>
                    <popup-menu v-if="warnings.length" fixed :arrow-pointer="{show: true, left: true}" :pos-offset="{top: 5, left: 0}">
                        <template #activator>
                            <slotted-tag no-close :tooltip="`This rule has ${warnings.length} ${formatPlural(warnings.length, 'warning')}.`" style="cursor: pointer">
                                <i class="symbol-warning"></i> Has warnings
                            </slotted-tag>
                        </template>
                        <div class="rule-row__warning-list dark-scrollbars dark-scrollbars--visible short-animated fadeIn">
                            <div class="warning-item" v-for="warning in warnings" :key="warning.warningMessage">
                                {{ warning.warningMessage }}
                            </div>
                        </div>
                    </popup-menu>
                    <slotted-tag v-if="isTaggingRule && rules.length > 1" no-close tooltip="This row represents multiple rules with different filters. Expand the row to see them.">
                        {{ rules.length }} combined
                    </slotted-tag>
                    <slotted-tag v-if="!isActive"
                                 class="rule-row__inactive"
                                 close-tooltip="Click to activate this rule"
                                 :disabled="saving || dragEnabled"
                                 @click.stop
                                 @close="setActiveState()">Inactive</slotted-tag>
                </span>

                <div v-if="user.debugMode" class="rule-row__debug-mode-text" style="margin-left: auto">[id: {{ rule.id }}]</div>
            </div>
            <div class="rule-row__data">
                <div v-if="tags.length" class="rule-row__tags">
                    <slotted-tag v-for="tag in tags" :key="tag.name" no-close>
                        {{tag.name}}
                    </slotted-tag>
                </div>
                <div v-else-if="crowdJobDescription">{{crowdJobDescription}}</div>
            </div>
            <span class="rule-row__data justify">
                {{ prettyAction }}
            </span>
            <span v-if="filterVisible" class="rule-row__data">
                    <ul :class="{'unstyled-list': !isTaggingRule || rules.length === 1}">
                        <li v-for="rule in rules" :key="rule.id"  :class="{'rule-row__inactive-text': !rule.active && isTaggingRule}">
                            <english-filter :filter="rule.filter"/>
                        </li>
                    </ul>
            </span>
            <span class="rule-row__data justify" :class="{'muted': last24hVolume === 'None'}">
                {{ last24hVolume }}
            </span>
            <span class="rule-row__data justify" :class="{'muted': lastWeekVolume === 'None'}">
                {{ lastWeekVolume }}
            </span>
            <span v-if="priorityVisible" class="rule-row__data justify">
                {{ rulePriorities }}
            </span>
        </div>

        <div v-else class="rule-row__expanded-container">
            <div class="rule-row__expanded-header">
                <span class="info">
                    {{ rule.name }}
                </span>
                <popup-menu v-if="warnings.length" fixed :arrow-pointer="{show: true, left: true}" :pos-offset="{top: 5, left: 0}">
                    <template #activator>
                        <slotted-tag no-close :tooltip="`This rule has ${warnings.length} ${formatPlural(warnings.length, 'warning')}.`" style="cursor: pointer">
                            <i class="symbol-warning"></i> Has warnings
                        </slotted-tag>
                    </template>
                    <div class="rule-row__warning-list dark-scrollbars dark-scrollbars--visible short-animated fadeIn">
                        <div class="warning-item" v-for="warning in warnings" :key="warning.warningMessage">
                            {{ warning.warningMessage }}
                        </div>
                    </div>
                </popup-menu>
                <slotted-tag v-if="isTaggingRule && rules.length > 1" no-close tooltip="This row represents multiple rules with different filters.">
                    {{ rules.length }} combined
                </slotted-tag>
                <span class="info" :tooltip="ruleTypeTooltip">
                    <i :class="icon"/> {{ prettyAction }}
                </span>
                <span class="info" :tooltip="activeStateTooltip">
                    {{ isActive ? 'Active rule' : 'Inactive rule' }}
                </span>

                <div v-if="saving" class="rule-row__saving-message">
                    <spinner-component :size="16"/>
                    <span style="padding-left: 5px">Saving rule…</span>
                </div>

                <div>
                    <span v-if="user.debugMode" class="rule-row__debug-mode-text">[id: {{ rule.id }}]</span>
                    <be-button link tooltip="Click to close (esc)" @click.stop="expand()"><i class="symbol-close"></i></be-button>
                </div>
            </div>

            <div class="rule-row__expanded-edit-container" @click.stop>
                <div class="rule-row__expanded-edit-block">
                    <div class="rule-name-edit">
                        <h4>Rule name</h4>

                        <inline-text-input class="rule-name-input" v-if="editName" text-required v-model="tempRuleName" @ok="updateRuleName" @cancel="toggleEditName"/>
                        <div v-else :class="{'rule-row__editable-text':canEdit, 'disabled': saving}" @click="toggleEditName">
                            <span>{{ rule.name }}</span>
                            <i style="visibility: hidden" class="symbol-edit"></i>
                        </div>

                    </div>

                    <div v-if="showAttributeEditor" class="column-with-gaps">
                        <h4>Edit attributes</h4>
                        <p>Mentions matching this rules filter will have their attributes changed to the following</p>
                        <attribute-editor :key="rule.attributes" close-menus-on-click :disabled="saving" @input="updateRuleAttributes" :value="rule.attributes"></attribute-editor>
                    </div>

                    <div v-if="showTagEditor" class="column-with-gaps">
                        <h4>Select tags</h4>
                        <p>Mentions matching this rules filter will have the following tags applied to them</p>
                        <inline-tag-input v-model="tags" :disabled="saving" @selected-changed="updateRuleTags"/>
                    </div>

                    <div v-if="showEngageEditor" class="column-with-gaps">
                        <h4>Send to Engage</h4>
                        <p>
                            Specify whether or not these mentions should be sent to Engage.
                        </p>
                        <div class="btn-selector">
                            <be-button :disabled="saving" :active="!rule.data.send" @click="updateRuleSendToEngage(null)">Default</be-button>
                            <be-button :disabled="saving" :active="rule.data.send === 'YES'" @click="updateRuleSendToEngage('YES')">Send</be-button>
                            <be-button :disabled="saving" :active="rule.data.send === 'NO'" @click="updateRuleSendToEngage('NO')">Do Not Send</be-button>
                        </div>

                        <div v-if="rule.data.send !== 'NO' && engageTeams.length" class="column-with-gaps">
                            <h4>Assign to a specific Engage Team</h4>
                            <p>
                                Specify which team these mentions should be assigned to.
                            </p>
                            <drop-down-input :disabled="saving" style="width: 200px" :value="rule.data.teamId" :options="engageTeams" @input=updateRuleEngageTeam></drop-down-input>
                        </div>

                        <div v-if="rule.data.send !== 'NO'" class="column-with-gaps">
                            <h4>Set Engage priority</h4>
                            <p>
                                Specify the priority in which these mentions should be processed on Engage.
                            </p>
                            <input :disabled="saving" v-model="tempRuleEngagePriority" @blur="updateRuleEngagePriority" type="number"/>
                        </div>
                    </div>

                    <div v-if="crowdJobDescription" class="column-with-gaps">
                        <p>{{crowdJobDescription}}</p>
                    </div>
                </div>
                <div class="rule-row__expanded-edit-block column-with-gaps">
                    <h4>{{ formatPlural(rules.length, 'Filter') }}</h4>
                    <p>Mentions matching {{ formatPlural(rules.length, 'this filter', 'these filters') }} will have the rule applied to them</p>

                    <div v-if="isTaggingRule">
                        <div v-for="rule in rules" :key="rule.id" class="rule-row__tagging-rule-filter-container" :class="{'rule-row__inactive-text': !rule.active && isTaggingRule}">
                            <popup-menu ref="editTaggingRuleFilter"
                                        fixed
                                        right
                                        :pos-offset="{left: -5, top: 0}"
                                        :arrow-pointer="{show: true, left: true}">
                                <template #activator>
                                    <options-button></options-button>
                                </template>
                                <div class="rule-row__edit-rule-btns short-animated fadeIn">
                                    <div @click="toggleEditFilter(rule)">Edit filter</div>
                                    <div @click="showExampleMentionsDialog(rule.filter)">See example mentions</div>
                                    <div @click="setActiveState(rule.id)">{{ rule.active ? 'Deactivate filter' : 'Activate filter' }}</div>
                                    <div @click="showTagExistingMentionsDialog(rule.filter)">Tag existing mentions</div>
                                    <div @click="deleteRule(rule.id)">Delete filter</div>
                                </div>
                            </popup-menu>
                            <div class="rule-row__editable-text" :class="{'disabled': saving, 'rule-row__warning-text': ruleHasFilterWarning(rule.id)}" @click="toggleEditFilter(rule)">
                                <english-filter :filter="rule.filter"></english-filter>
                                <i style="visibility: hidden" class="symbol-edit"></i>
                                <span v-if="user.debugMode && rules.length > 1" class="rule-row__debug-mode-text" style="float: right">[id: {{ rule.id }}]</span>
                            </div>
                        </div>
                    </div>
                    <div v-else :class="{'rule-row__editable-text':canEdit, 'disabled': saving, 'rule-row__warning-text': ruleHasFilterWarning()}" @click="toggleEditFilter(rule)">
                        <english-filter :filter="rule.filter"/>
                        <i style="visibility: hidden" class="symbol-edit"></i>
                    </div>

                    <be-button v-if="isTaggingRule" :disabled="saving" link @click="toggleAddFilter(rule.id)"><i class="symbol-add"></i> Add another filter</be-button>

                </div>
            </div>

            <div class="rule-row__expanded-footer">
                <be-button v-if="rule.active && rules.length < 2" @click.stop="showExampleMentionsDialog()" primary tooltip="Preview mentions that this rule will be applied to"><i class="symbol-mentions"></i>See example mentions</be-button>

                <div class="rule-row__btns-bar" style="margin-left: auto" v-if="canEdit">
                    <be-button v-if="!rule.active" @click.stop="setActiveState()">Make active</be-button>
                    <be-button v-else @click.stop="setActiveState()" :disabled="saving">Deactivate</be-button>

                    <be-button @click.stop="deleteRule()" :disabled="saving" danger>Delete</be-button>
                </div>
            </div>
        </div>

        <dialog-box v-if="editingFilter || addingFilter"
                    @close="closeFilterDialog"
                    overlay
                    width="850px"
                    title="What mentions should this rule apply to?">
            <div style="overflow-y: auto; overflow-x: hidden">
                <filter-editor v-model="tempRuleFilter" for-rule/>
            </div>
            <template #buttons>
                <section class="rule-row__btns-bar">
                    <be-button style="margin-left: auto" link @click="closeFilterDialog">Cancel</be-button>
                    <be-button primary @click="updateRuleFilter">{{ addingFilter ? 'Add' : 'Update' }}</be-button>
                </section>
            </template>
        </dialog-box>
    </div>
</template>

<script>
import {formatPlural} from "@/app/utils/Format";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import SlottedTag from "@/components/tags/SlottedTag";
import EnglishFilter from "@/components/formatters/EnglishFilter";
import FilterEditor from "@/components/inputs/FilterEditor";
import AttributeEditor from "@/setup/rules/add-rule/AttributeEditor";
import InlineTagInput from "@/components/inputs/InlineTagInput";
import BeButton from "@/components/buttons/BeButton";
import DropDownInput from "@/components/inputs/DropDownInput";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import PreviewMentionsDialog from "@/components/PreviewMentionsDialog";
import TagExistingMentionsDialog from "@/setup/tagrules/TagExistingMentionsDialog";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import {
    notifyUser,
    notifyUserOfError,
    notifyWithHtml,
    showBusyNotification
} from "@/app/framework/notifications/Notifications";
import {showAskDialog} from "@/app/framework/dialogs/Dialog";
import DialogBox from "@/components/DialogBox";
import {getRulePrettyAction, getRuleTags, JobTypesById, rulePrettyActions} from "@/setup/rules/RuleUtils";
import PopupMenu from "@/components/PopupMenu";
import OptionsButton from "@/components/OptionsButton";
import SpinnerComponent from "@/components/SpinnerComponent";
import VuexStore from "@/store/vuex/VuexStore"
import {isOps} from "@/app/Permissions"

export default {
    name: "SetupRulesRow",
    components: {
        SpinnerComponent,
        OptionsButton,
        PopupMenu,
        DialogBox,
        InlineTextInput,
        DropDownInput, BeButton, InlineTagInput, FilterEditor, EnglishFilter, SlottedTag, AttributeEditor},
    props: {
        rules: {
            required: true
        },
        warnings: {
            type: Array
        },
        dragEnabled: {
            type: Boolean,
            default: false
        },
        rulesTypesToShow: {
            type: Array
        },
        showInactive: {
            type: Boolean
        },
        filterVisible: {
            type: Boolean
        },
        priorityVisible: {
            type: Boolean,
            default: false
        },
        gridColumnStyle: {
            type: Object
        }
    },

    data() {
        return {
            prettyAction: "",
            tags: [],
            tempRuleName: "",
            tempRuleFilter: "",
            tempRuleEngagePriority: 0,
            editingRuleId: null,
            editingFilter: false,
            addingFilter: false,
            editName: false,
            expanded: false,
            saving: false,
            showEditFilterMenu: false,
            crowdJobDescription: null
        }
    },

    created() {
        this.tags = getRuleTags(this.rule);
        this.prettyAction = getRulePrettyAction(this.rule, this.tags);

        this.tempRuleEngagePriority = this.rule.data?.priority;
    },

    mounted() {
        this.buildCrowdJobDescription()
    },

    watch: {
        dragEnabled(v) {
            if (v) this.expanded = false;
        }
    },

    computed: {
        ...mapGetters(['idToTag']),
        ...mapState(['user', 'engageTeams']),

        rule() {
            return this.rules?.[0] ? this.rules?.[0] : {};
        },

        rulesById() {
            let rulesById = {};

            this.rules?.forEach(rule => {
                rulesById[rule.id] = rule;
            });

            return rulesById;
        },

        canEdit() {
            return this.rule.action !== 'CREATE_CROWD_JOB' || isOps()
        },

        last24hVolume() {
            return this.rule.stats?.last24h ? `Applied to ${this.rule.stats?.last24h} ${formatPlural(this.rule.stats?.last24h, 'mention')}` : "None"
        },

        lastWeekVolume() {
            return this.rule.stats?.lastWeek ? `Applied to ${this.rule.stats?.lastWeek} ${formatPlural(this.rule.stats?.lastWeek, 'mention')}` : "None"
        },

        showRule() {
            let ruleTypeShow = this.rulesTypesToShow ? this.rulesTypesToShow.find(r => this.prettyAction.toLowerCase().includes(r.label.toLowerCase()) && r.value) : true;
            let inactiveShow = this.showInactive ? true : this.rule.active;

            return ruleTypeShow && inactiveShow;
        },

        showAttributeEditor() {
            return this.prettyAction === rulePrettyActions.edit;
        },

        showTagEditor() {
            return this.prettyAction === rulePrettyActions.tag;
        },

        showEngageEditor() {
            return this.prettyAction.includes(rulePrettyActions.engage);
        },

        isTaggingRule() {
            return this.prettyAction === rulePrettyActions.tag;
        },

        editingRule() {
            return this.rules.find(r => r.id === this.editingRuleId);
        },

        isActive() {
            return !!this.rules.find(r => r.active);
        },

        rulePriorities() {
            return this.rules.length > 1 ? `[${this.rules.map(r => r.priority).join(", ")}]` : this.rule.priority;
        },

        dragContainerStyle() {
            let style = {
                "border": "var(--border-dragging)",
                "border-radius": "5px",
                "margin": "5px 0"
            }

            return this.dragEnabled ? style : {};
        },

        ruleTypeTooltip() {
            if (this.prettyAction.includes(rulePrettyActions.engage)) return "This rule controls engage processing of incoming mentions.";
            else if (this.prettyAction === rulePrettyActions.delete) return "This rule deletes incoming mentions based.";
            else if (this.prettyAction === rulePrettyActions.crowd) return "This rule controls crowd processing of incoming mentions.";
            else if (this.prettyAction === rulePrettyActions.tag) return "This rule tags incoming mentions.";
            else if (this.prettyAction === rulePrettyActions.relevant) return "This rule sets incoming mentions to be relevant.";
            else if (this.prettyAction === rulePrettyActions.edit) return "This rule edits incoming mentions.";
            else return "";
        },

        activeStateTooltip() {
            return this.rule.active ? "This rule is active and will affect incoming mentions." : "This rule is inactive and will have no affect on incoming mentions."
        },

        icon() {
            if (this.prettyAction.includes(rulePrettyActions.engage)) return "icon-equalizer";
            else if (this.prettyAction === rulePrettyActions.delete) return "symbol-sorter";
            else if (this.prettyAction === rulePrettyActions.crowd) return "symbol-crowd";
            else if (this.prettyAction === rulePrettyActions.tag) return "symbol-tags";
            else if (this.prettyAction === rulePrettyActions.relevant) return "symbol-checkmark";
            else if (this.prettyAction === rulePrettyActions.edit) return "symbol-edit";
            else return "";
        }
    },

    methods: {
        ...mapActions({
            storeUpdateRule: 'updateRule',
            storeDeleteRule: 'deleteRule',
            storeCreateRule: 'createRule',
            storeCreateTag: 'createTag'
        }),
        ...mapMutations({
            storeAddRule: 'addRule'
        }),

        formatPlural,

        expand(value) {
            if (this.dragEnabled) return;

            value ??= null;

            this.expanded = value === null ? !this.expanded : value;
            if (!this.expanded) {
                this.editName = false;
                this.editingFilter = false;
            } else {
                this.$nextTick(() => {
                    this.$el.scrollIntoView({behavior: 'smooth', block: 'end'});
                });
            }
        },

        showExampleMentionsDialog(filter) {
            if (!filter) {
                filter = "";
                this.rules.forEach((rule, i) => {
                    filter += `(${rule.filter})`;
                    if (i < this.rules.length - 1) filter += ' OR '
                });
            }

            showDialog(PreviewMentionsDialog, {value: filter, title: `${this.rule.name}: Example mentions`});
        },

        showTagExistingMentionsDialog(filter) {
            showDialog(TagExistingMentionsDialog, {tags: this.tags, filter: filter});
        },

        ruleHasFilterWarning(ruleId) {
            if (!this.warnings.length) return false;

            for (const warning of this.warnings) {
                if (warning.type === "FILTER_WARNING") {
                    if (ruleId) {
                        if (warning.ruleId === ruleId) return true;
                    } else {
                        return true;
                    }
                }
            }

            return false;
        },

        async setActiveState(ruleId) {
            this.closeFilterDialog();

            let rulesToUpdate = [];

            // if ruleId is given, then only update that specific rule
            if (ruleId) {
                rulesToUpdate = [this.rulesById[ruleId]];
            } else {
                rulesToUpdate = this.rules;
            }

            let payload = {};
            try {
                this.saving = true;

                let activeState = rulesToUpdate.length > 1 ? !this.rule.active : !rulesToUpdate.at(0).active;
                for (const rule of rulesToUpdate) {
                    payload = JSON.parse(JSON.stringify(rule));
                    payload.active = activeState;

                    await this.storeUpdateRule(payload);
                }

                notifyWithHtml(
                    `Rule "${this.rule.name}" has been ${payload.active ? 'activated' : 'deactivated'}`,
                    null,
                    "<i class='symbol-rules'></i>");
            } catch (e) {
                console.error("Error setting rule active state: ", e);
                notifyUserOfError(`An error occurred while trying to ${payload.active ? 'activate' : 'deactivate'} this rule. Please refresh the page and try again or contact support.`);
            } finally {
                this.saving = false;
            }
        },

        toggleEditFilter(rule) {
            if (!this.canEdit) return
            this.editingFilter = !this.editingFilter;
            if (this.editingFilter) {
                this.tempRuleFilter = rule.filter;
                this.editingRuleId = rule.id;
            }
        },

        closeFilterDialog() {
            this.addingFilter = false;
            this.editingFilter = false;
        },

        toggleAddFilter(ruleId) {
            this.addingFilter = !this.addingFilter;
            if (this.addingFilter) {
                this.tempRuleFilter = "";
                this.editingRuleId = ruleId;
            }
        },

        toggleEditName() {
            if (!this.canEdit) return
            this.editName = !this.editName;
            if (this.editName) this.tempRuleName = this.rule.name;
        },

        async updateRule(message, payload, oldRule) {
            oldRule ??= JSON.parse(JSON.stringify(this.rule));
            try {
                this.saving = true;

                await this.storeUpdateRule(payload);

                message ??= 'Profile has been updated';

                notifyWithHtml(
                    message,
                    async () => {
                        try {
                            this.saving = true;

                            await this.storeUpdateRule(oldRule);

                            if (this.rule.action === "ENGAGEMENT_CONSOLE") {
                                this.prettyAction = getRulePrettyAction(this.rule);
                                this.tempRuleEngagePriority = this.rule.data?.priority;
                            } else if (this.rule.action === "MACRO") {
                                this.tags = getRuleTags(this.rule);
                            }

                            notifyWithHtml(
                                "Rule changes have been undone",
                                null,
                                "<i class='symbol-rules'></i>")
                        } catch (e) {
                            console.error("Error occurred while trying to undo rule changes: ", e);
                            notifyUserOfError(`We were unable to undo rule changes`);
                        } finally {
                            this.saving = false;
                        }
                    },
                    "<i class='symbol-rules'></i>")
            } catch (e) {
                console.error("Error occurred while trying to update rule: ", e);
                notifyUserOfError("We were unable to save this rule's changes. Please refresh the page and try again or contact support.");
            } finally {
                this.saving = false;
            }
        },

        async updateRuleSendToEngage(value) {
            if (!value && !this.rule.data?.send) return; // already "default"
            if (this.rule.data?.send === value) return;

            let payload = JSON.parse(JSON.stringify(this.rule));
            if (!value) {
                delete payload.data.send;
            } else {
                payload.data.send = value;
            }

            await this.updateRule(`Rule has been updated`, payload);

            this.prettyAction = getRulePrettyAction(this.rule);
        },

        async updateRuleEngageTeam(value) {
            if (value === this.rule.data.teamId) return;

            let payload = JSON.parse(JSON.stringify(this.rule));
            payload.data.teamId = Number(value);
            payload.data.teamName = this.engageTeams.find(t => t.id === value).name;

            await this.updateRule(`Rule's engage team has been updated`, payload);
        },

        async updateRuleEngagePriority() {
            if (this.tempRuleEngagePriority === this.rule.data.priority) return;

            let payload = JSON.parse(JSON.stringify(this.rule));
            payload.data.priority = this.tempRuleEngagePriority;

            await this.updateRule(`Rule's engage priority has been updated`, payload);

            this.tempRuleEngagePriority = this.rule.data?.priority;
        },

        async updateRuleAttributes(value) {
            if (this.rule.attributes === value) return;

            let payload = JSON.parse(JSON.stringify(this.rule));
            payload.attributes = value;

            await this.updateRule(`Rule's attributes have been updated`, payload);

            this.tags = getRuleTags(this.rule);
        },

        async updateRuleFilter() {
            if (this.addingFilter) {
                this.toggleAddFilter();

                await this.addFilter();
            } else {
                this.toggleEditFilter();

                let ruleToUpdate = this.rulesById[this.editingRuleId];

                // has filter changed?
                if (ruleToUpdate === this.tempRuleFilter) return;

                let payload = JSON.parse(JSON.stringify(ruleToUpdate));
                payload.filter = this.tempRuleFilter;

                await this.updateRule(`Rule's filter has been updated`, payload, ruleToUpdate);
            }
        },

        // create a new tagging rule with the same tags as the top level rule
        async addFilter() {
            try {
                this.saving = true;

                let payload = {
                    action: this.rule.action,
                    active: true,
                    attributes: this.rule.attributes,
                    filter: this.tempRuleFilter,
                    name: this.rule.name
                }

                await this.storeCreateRule(payload);
            } catch (e) {
                console.error("Error occurred while trying to add a new filter: ", e);
                notifyUserOfError("An error occurred adding your new filter. Please refresh the page and try again or contact support.");
            } finally {
                this.saving = false;
            }
        },

        async updateRuleName() {
            this.toggleEditName();

            // has name changed?
            if (this.rule.name === this.tempRuleName) return;

            let payload = JSON.parse(JSON.stringify(this.rule));
            payload.name = this.tempRuleName;

            await this.updateRule(`Rule's name has been updated`, payload);
        },

        async updateRuleTags() {
            let tagIds = [];
            let creatingTagsError = true;

            try {
                this.saving = true;

                for (const tag of this.tags) {
                    // at this point, if the tag doesn't have an ID, a tag with the same name should not exist
                    if (!tag.id) {
                        tag.namespace ??= "tag";
                        let newTag = await this.storeCreateTag(tag);
                        tagIds.push(newTag.id);
                    } else {
                        tagIds.push(tag.id);
                    }
                }
                creatingTagsError = false;
            } catch (e) {
                console.error("Error occurred while trying to create new tags: ", e);
                notifyUserOfError("An error occurred while creating the required new tags. Please refresh the page and try again or contact support.");
                this.saving = false;
            }

            if (!creatingTagsError) {
                try {
                    this.saving = true;

                    let oldRules = this.rules.map(r => JSON.parse(JSON.stringify(r)));

                    for (const ruleToUpdate of this.rules) {
                        let payload = JSON.parse(JSON.stringify(ruleToUpdate));
                        payload.attributes = tagIds.map(tagId => `tag = ${tagId}`).join(",");

                        await this.storeUpdateRule(payload);
                    }

                    notifyWithHtml(
                        "Rule tags have been updated",
                        async () => {
                            try {
                                this.saving = true;

                                for (const oldRule of oldRules) {
                                    await this.storeUpdateRule(oldRule);
                                }

                                this.tags = getRuleTags(this.rule);

                                notifyWithHtml(
                                    "Rule changes have been undone",
                                    null,
                                    "<i class='symbol-rules'></i>")
                            } catch (e) {
                                console.error("Error occurred while trying to undo rule changes: ", e);
                                notifyUserOfError(`We were unable to undo rule changes`);
                            } finally {
                                this.saving = false;
                            }
                        },
                        "<i class='symbol-rules'></i>")
                } catch (e) {
                    console.error("Error occurred while trying to update rule: ", e);
                    notifyUserOfError("We were unable to save this rule's changes. Please refresh the page and try again or contact support.");
                } finally {
                    this.saving = false;
                }
            }
        },

        async deleteRule(ruleId) {
            try {
                const proceed = await showAskDialog(
                     "Delete rule?",
                    `Are you sure that you want to delete this rule?`,
                    true
                );

                if (!proceed) return;

                this.closeFilterDialog();

                this.saving = true;

                let rulesToDelete = [];
                let oldRules = [];

                // if ruleId is given, then only delete that specific rule
                if (ruleId) {
                    rulesToDelete = [ruleId];
                    oldRules = [JSON.parse(JSON.stringify(this.rulesById[ruleId]))];
                } else {
                    rulesToDelete = this.rules.map(r => r.id);
                    oldRules = this.rules.map(r => JSON.parse(JSON.stringify(r)));
                }

                for (const id of rulesToDelete) {
                    await this.storeDeleteRule(id);
                }

                notifyUser({
                    message: `Rule "${oldRules.at(0)?.name}" has been deleted`,
                    isEscapedHtml: true,
                    icon: "<i class='symbol-rules'></i>",
                    longDelay: true,
                    undo: async () => {
                        let busy = showBusyNotification(`Undeleting rule "${oldRules.at(0)?.name}"...`, null, null, true);
                        try {
                            for (const oldRule of oldRules) {
                                oldRule.deleted = false;
                                await this.storeUpdateRule(oldRule);
                                this.storeAddRule({value: oldRule, sort: true});
                            }

                            notifyWithHtml(
                                `Rule "${oldRules.at(0)?.name}" has been undeleted`,
                                null,
                                "<i class='symbol-rules'></i>")
                        } catch (e) {
                            console.error("Unable to undelete rule: ", e);
                            notifyUserOfError(`An error occurred while trying to undelete this rule. If you would like to undelete this rule, please contact support.`);
                        } finally {
                            busy.close();
                        }
                    }
                })
            } catch (e) {
                console.error("Error deleting rule: ", e);
                notifyUserOfError(`An error occurred while trying to delete this rule. Please refresh the page and try again or contact support.`);
            } finally {
                this.saving = false;
            }
        },

        startDrag(event) {
            // need to fire drag start event after a small timeout otherwise chrome
            // will fire the drag end event prematurely when manipulating DOM
            window.setTimeout(() => {
                this.$emit('drag-rule-start', {event: event, ruleId: this.rule.id});
            }, 10)
        },

        onDragOver(event) {
            this.$emit('dragged-over', {event: event, ruleId: this.rule.id});
        },

        stopDrag() {
            this.$emit('drag-rule-end');
        },

        buildCrowdJobDescription() {
            if (this.rule.action !== "CREATE_CROWD_JOB") return
            let job = this.rule.data
            if (!job) return "No rule data?"
            let s = (JobTypesById[job.jobType]?.name || job.jobType)
            let tagId
            if (job.jobType === 'SEGMENTATION') tagId = job.segmentListId
            else if (job.jobType === 'TOPIC_TREE') tagId = job.topicTreeId
            if (tagId) {
                const idToTag = VuexStore.getters.idToTag
                let tag = idToTag.get(tagId)
                if (tag) s += ", " + tag.name + " "
                s += "(" + tagId + ")"
            }
            //if (job.crowdPriority) s += ", " + job.crowdPriority + " priority"
            if (job.crowdId) {
                let crowd
                let crowds = VuexStore.state.crowds.crowds
                if (crowds) crowd = crowds.find(c => c.id === job.crowdId)
                else VuexStore.dispatch('crowds/refreshCrowds').then(() => this.buildCrowdJobDescription())
                s += ", " + (crowd?.name || job.crowdId) + " crowd"
            }
            this.crowdJobDescription = s
        }
    }
}

</script>

<style scoped lang="sass">

div + .rule-row
    border-top: none

.rule-row
    --container-border: 1px solid rgb(136 136 136 / 0.5)
    --divider-border: 1px solid #888
    --hover-edit-color: #aee15d
    background: var(--rule-background-color)
    transition-property: background, margin
    transition-duration: 100ms
    border: var(--border)

    &.expanded
        display: flex
        transition-property: margin
        /* Make sure background doesnt transition here, or the flicker is awful. */
        background: #444
        margin-top: 20px
        margin-bottom: 20px
        border-top: var(--border)
        border-left: 0
        border-right: 0
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4)

    &:focus
        outline: none

    &__inactive
        line-height: 1.3rem
        color: var(--be-colour-muted-text-dark)

    &__rule-container
        display: grid
        box-sizing: border-box
        transition-property: background, margin
        transition-duration: 100ms
        grid-template-columns: var(--grid-columns)

    &__saving-message
        color: var(--be-colour-muted-text-dark)
        font-size: 1rem
        margin-left: 30px

        & ::v-deep .css-spinner
            top: 3px
            margin: 0 5px

    &__tags
        display: flex
        column-gap: 5px
        flex-wrap: wrap
        row-gap: 8px

    &__warning-list
        max-height: 400px
        overflow-y: auto

        .warning-item
            padding: 5px
            line-height: 20px

            & + .warning-item
                    border-top: 1px solid #1a1a1a

    &__data
        padding: 10px
        border-right: var(--border)

        & > .rule-info
            span + span
                margin-left: 8px

            .be-tag + .be-tag
                margin-left: 8px

        &.justify
            display: inline-flex
            align-items: center
            justify-content: center

        &.muted
            color: var(--be-colour-muted-text-dark)

    &__expanded-container
        border-left: var(--border)
        border-right: var(--border)
        width: 100%

        p
            margin: 0
            color: var(--be-colour-text-dark)

        h4
            margin: 0

    &__expanded-header
        display: flex
        align-items: center
        border-bottom: 1px solid #272727
        background: #333
        padding: 10px
        cursor: pointer
        column-gap: 10px

        > *:last-child
            display: flex
            align-items: center
            margin-left: auto

        .info
            font-size: 16px
            padding: 20px 10px

            &:not(:first-of-type)
                margin-left: 40px

    &__expanded-edit-container
        display: flex
        padding: 10px 0

    &__expanded-edit-block
        width: 55%
        padding: 20px

        .rule-name-edit
            display: grid
            grid-template-columns: 100px 1fr
            column-gap: 60px
            margin-bottom: 20px

        .rule-name-input
            width: 400px

        &:first-of-type
            width: 45%
            border-right: var(--container-border)

    &__editable-text
        color: var(--be-colour-text-dark)

        &:hover
            color: var(--hover-edit-color)
            cursor: pointer

            i
                visibility: visible !important

    &__inactive-text
        color: var(--be-colour-muted-text-dark)
        text-decoration: line-through

    &__warning-text
        color: var(--be-colour-warning)

    &__tagging-rule-filter-container
        display: flex
        column-gap: 5px
        margin-bottom: 5px


    &__edit-rule-btns > div
        padding: 5px

        &:not(:last-of-type)
            border-bottom: 1px solid #666

        &:hover
            background: var(--background-menu-hover)
            cursor: pointer

            &:first-of-type
                border-top-left-radius: 4px
                border-top-right-radius: 4px

            &:last-of-type
                border-bottom-left-radius: 4px
                border-bottom-right-radius: 4px

    .column-with-gaps
        display: flex
        row-gap: 12px
        flex-direction: column

    .rule-name-labels
        display: flex
        row-gap: 5px
        flex-direction: column
        min-width: 80px

    .btn-selector
        display: flex
        .be-button:not(:last-of-type) ::v-deep .btn
            border-top-right-radius: 0
            border-bottom-right-radius: 0
        .be-button + .be-button
            & ::v-deep .btn
                border-top-left-radius: 0
                border-bottom-left-radius: 0

    &__expanded-footer
        display: flex
        background: #333333
        border-top: 1px solid #272727
        padding: 10px
        align-items: center
        column-gap: 10px

    &__btns-bar
        column-gap: 10px
        display: flex

    &__debug-mode-text
        padding-top: 5px
        color: var(--be-colour-muted-text-dark)
        font-size: 0.9em

.unstyled-list
    list-style: none
    margin: 0

.disabled
    color: #666 !important
    pointer-events: none !important

</style>