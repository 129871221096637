var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"noun") : depth0),"===","search",{"name":"ifcond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":13,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"noun") : depth0),"===","phrase",{"name":"ifcond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":27}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                    If you delete this search, you will lose your search results and phrases. Are you sure you want\n                    to delete this search?\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    Are you sure you want to delete this phrase?\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"noun") : depth0),"===","search",{"name":"ifcond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"noun") : depth0),"===","phrase",{"name":"ifcond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":25,"column":27}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "                    If you edit the phrases or filters of this search, your search results will be reset. Are you\n                    sure you want to edit this search?\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    Are you sure you want to edit this phrase?\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>"
    + alias2(__default(require("../../helpers/capitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":27}}}))
    + " "
    + alias2(__default(require("../../helpers/capitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"noun") : depth0),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":47}}}))
    + " Confirmation</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"header-and-message\">\n        <p>\n"
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),"===","delete",{"name":"ifcond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),"===","edit",{"name":"ifcond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":26,"column":23}}})) != null ? stack1 : "")
    + "        </p>\n    </div>\n    <div class=\"row-fluid\">\n        <div class=\"dialog-button-bar\">\n            <div class=\"control-group\">\n                <div class=\"controls pull-right\">\n                    <a class=\"btn cancel\" href=\"#\" title=\"Cancel "
    + alias2(__default(require("../../helpers/lowerCase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"noun") : depth0),{"name":"lowerCase","hash":{},"data":data,"loc":{"start":{"line":33,"column":65},"end":{"line":33,"column":83}}}))
    + " "
    + alias2(__default(require("../../helpers/lowerCase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),{"name":"lowerCase","hash":{},"data":data,"loc":{"start":{"line":33,"column":84},"end":{"line":33,"column":102}}}))
    + " (esc)\">Cancel</a>\n                    <a class=\"btn continue\" href=\"#\" title=\"Click to "
    + alias2(__default(require("../../helpers/lowerCase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),{"name":"lowerCase","hash":{},"data":data,"loc":{"start":{"line":34,"column":69},"end":{"line":34,"column":87}}}))
    + " this "
    + alias2(__default(require("../../helpers/lowerCase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"noun") : depth0),{"name":"lowerCase","hash":{},"data":data,"loc":{"start":{"line":34,"column":93},"end":{"line":34,"column":111}}}))
    + "\">"
    + alias2(__default(require("../../helpers/capitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"verb") : depth0),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":34,"column":113},"end":{"line":34,"column":132}}}))
    + "</a>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});