/**
 * Displays randomly selected mentions from the account.
 */
import {toGrouseLink} from "@/data/Grouse";
import {getLdaTopicsFromCaper} from "@/data/Caper";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {once} from "@/app/utils/Functions";

Beef.module("Widget.Conversation").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Example Mentions",
        description:    "Mentions matching the filter",
        group:          "other",
        width:          4,
        height:         6,

        onAdd: function(data) {
            data.show = data.show || 'ldaTopics';
        }
    };

    Beef.MentionItem.start(); // We have a dependency on this module

    var ListView = Backbone.Marionette.CompositeView.extend({

        tagName: "div",

        itemView: Beef.MentionItem.View,

        itemViewOptions: function() {
            return {
                noSelect: true,
                timeline: true,
                notime: true,
                orderby: this.owner.model.get('show') == 'ldaTopics' ? null : this.owner.model.get('orderby')
            }
        },

        onAfterItemAdded: function(view) {
            view.model.view = view; // this is so "show conversation" and other things on mention view/edit dlg work
        },

        renderCollection: function(){
            this.$el.text('');
            Marionette.CompositeView.prototype.renderCollection.apply(this, arguments);
        },

        appendHtml: function(cv, iv){
            this.getItemViewContainer(cv).append(iv.el)
        }
    });

    this.View = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/examplementions/ExampleMentions.handlebars"),

        svgExportDisabled: true,

        attributes: {
            'class': 'conversation widget-height-inner'
        },

        regions: {
            list: ".mention-list-region"
        },

        modelEvents: {
            "change":   "maybeRefresh"
        },

        initialize: function() {
            var defaults = {};
            if (!this.model.has('num-mentions')) defaults['num-mentions'] = 7;
            if (!this.model.has('orderby')) defaults['orderby'] = 'engagement desc';
            if (!this.model.has('seed')) defaults['seed'] = 1;
            this.model.set(defaults, {silent: true});

            this.accountCode = this.model.getAncestorProperty('accountCode');
            this.collection = new Backbone.Collection();
            this.collection.accountCode = this.accountCode;
            this.collection.url = toGrouseLink('/v4/accounts/' + this.accountCode + '/mentions', null, true);
            this.refresh();
        },

        onFirstRender: function() {
            var list = new ListView({collection: this.collection});
            list.owner = this;
            this.list.show(list)
        },

        maybeRefresh: function() {
            var c = this.model.changed;
            if (c['_effectiveFilter'] || c['show'] || c['num-mentions'] || c['orderby'] || c['seed']) this.refresh();
        },

        viewMentions() {
            const filter = this.model.get('_effectiveFilter');
            if (!filter) return;
            gotoMentionPanel(filter);
        },

        /**
         * We have a lot of dashboards using the old V3 codes.
         * This translates between that and the new V4 codes.
         */
        getOrderBy: function() {
            var order = this.model.get('orderby');
            switch (order) {
                case "ots":
                    return "totalOTS";
                case "ots desc":
                    return "totalOTS desc";
                case "ave":
                    return "totalAVE";
                case "ave desc":
                    return "totalAVE desc";
                case "resharecount":
                    return "totalReshareCount";
                case "resharecount desc":
                    return "totalReshareCount desc";
                case "replycount":
                    return "totalReplyCount";
                case "replycount desc":
                    return "totalReplyCount desc";
                case "responsetime":
                    return "responseTime";
                case "responsetime desc":
                    return "responseTime desc";
                default:
                    return order;
            }
        },

        refresh: function() {
            try {
                var filter = this.model.get('_effectiveFilter');
                if (!filter) return;

                let brandsInFilter = getBrandsInFilter(filter).include;
                const isLda = this.model.get('show') === 'ldaTopics';

                // We can only sort data from the API if one brand is selected.
                // Multiple brands will stop data selection.
                if (brandsInFilter?.length > 1 && !isLda) {
                    this.model.generalData.set({
                        "_loading": false,
                        "_completed": true
                    });
                    this.model.generalData.set({
                        '_message': "Please select only one brand",
                    });

                    return;
                }


                this.model.generalData.set({'_loading': true, _completed: false});


                var params = {
                    filter: filter,
                    limit: this.model.get('num-mentions') || 7,
                    select: "+combinedHtml,phrases,brand,discardedByRule,region,crowdJobs"
                };

                if (isLda) {
                    params.seed = this.model.get('seed') || 1;
                    params.includeExamples = true;
                } else {
                    params.orderBy = this.getOrderBy();
                }

                var that = this;

                var processMentions = function (mentions) {
                    var a = [];
                    for (var i = 0; i < mentions.length; i++) {
                        var mod = new Beef.MentionItem.Model(mentions[i]);
                        mod.accountCode = that.accountCode;
                        a.push(mod);
                    }
                    that.collection.reset(a);
                    that.model.generalData.set({
                        '_loading': false,
                        _completed: true
                    });

                    if (!mentions.length) that.model.generalData.set("_message", "No mentions match your filter");
                };

                if (isLda) {
                    getLdaTopicsFromCaper(params)
                        .then(function (data) {
                            var mentions = [];
                            for (var i = 0; i < data.topics.length; i++) mentions.push(data.topics[i].exampleMention);
                            processMentions(mentions);
                        })
                        .catch(once(e => errorHelper(this.model, e)));
                } else {
                    this.model.getSectionModel().view.getJsonFromGrouse('/v4/accounts/' + this.accountCode + '/mentions', params)
                        .then(processMentions)
                        .catch(once(e => errorHelper(this.model, e)));
                }
            } catch (e) {
                errorHelper(this.model, e);
            }
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/examplementions/ExampleMentionsSettings.handlebars"),

        editAttributes: function() { return ['num-mentions', 'orderby', 'show', 'seed'] },

        events: {
            "click .orderby": "displayOrderByMenu",
            "click .btn-group .btn": "buttonGroupChanged",
            'click .inc-seed': 'incSeed'
        },

        modelEvents: {
            "change": "modelToView",
            "change:orderby": "maybePreview",
            "change:num-mentions": "maybePreview"
        },

        onFirstRender: function() {
            this.modelToView();
        },

        modelToView: function() {
            var attr = this.model.attributes;
            var show = attr.show || 'top';
            this.$(".btn-group .btn").removeClass('active');
            this.$('.show button[data-value="' + show +'"]').addClass('active');
            this.$('.orderby-col').toggleClass('hide', show !== 'top');
            this.$('.orderby .orderby-label').text(Beef.OrderBy.getOrderByAsEnglish(this.model.get('orderby')));
            this.$('.inc-seed').toggleClass('hide', show !== 'ldaTopics');
        },

        displayOrderByMenu: function(ev) {
            Beef.OrderBy.displayOrderByMenu(this, $(ev.target).closest('a'));
        },

        buttonGroupChanged: function(ev) {
            var $t = $(ev.target).closest(".btn");
            if (!$t.hasClass("disabled")) {
                var value = $t.attr('data-value');
                var key = $t.closest(".btn-group").attr('data-key');
                if (key && value) this.model.set(key, value);
            }
            this.modelToView();
            // TODO: indicate that themes have been found when "Find different themes" is clicked, and that user should click "preview" in order to see them
            //if (this.options.owner) this.options.owner.preview();
        },

        incSeed: function() {
            this.model.set('seed', (this.model.get('seed') || 1) + 1);
            this.maybePreview();
        },

        maybePreview: function() {
           // if (this.options.owner) this.options.owner.preview();
        }
    });
});