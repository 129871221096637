import {mash} from "@/store/Services";
import Vue from "vue";
import Vuex from "vuex";

let workingHoursPromise = null;
let holidayCalendarsPromise = null;

Vue.use(Vuex);

export default {
    namespaced: true,

    state: {
        workingHours: [],
        holidayCalendars: []
    },

    mutations: {
        setWorkingHours: (state, value) => {
            state.workingHours = value;
        },
        setHolidayCalendars: (state, value) => {
            state.holidayCalendars = value;
        }
    },

    getters: {
        /** Useful for getting a working hours from a brand Id, or for testing if the brand has working hours */
        brandIdToWorkingHours: state => {
            const map = new Map();

            let workingHours = [...state.workingHours];

            while (workingHours.length) {
                const brandWorkingHours = workingHours.pop();
                map.set(brandWorkingHours.brandId, brandWorkingHours);
            }

            return map;
        },
        holidayCalendarsRegionMap: state => {
            const map = new Map();

            for (const holidayCalendar of state.holidayCalendars) {
                let curRegion = holidayCalendar.region?.id;
                if (curRegion) {
                    if (map.get(curRegion)) map.get(curRegion).push(holidayCalendar);
                    else map.set(curRegion, [holidayCalendar])
                }
            }

            return map;
        },
    },

    actions: {
        async refreshWorkingHours({state, rootState, commit}, forceRefresh = false) {
            if (state.workingHours?.length && !forceRefresh) return;
            if (!rootState.account?.code) return;

            if (workingHoursPromise) return workingHoursPromise;

            try {
                workingHoursPromise = mash.get("/rest/work-calendars/accounts/" + rootState.account.code);
                const res = await workingHoursPromise;
                const workingHours = res.data;

                await commit('setWorkingHours', workingHours);
            } catch (e) {
                console.warn(`Error occurred when trying to fetch account ${rootState.account.code} working hours: `, e);
            } finally {
                workingHoursPromise = null;
            }
        },
        async refreshHolidayCalendars({state, rootState, commit}, forceRefresh = false) {
            if (state.holidayCalendars?.length && !forceRefresh) return;
            if (!rootState.account?.code) return;

            if (holidayCalendarsPromise) return holidayCalendarsPromise;

            try {
                holidayCalendarsPromise = mash.get(`rest/work-calendars/holiday-calendars`);
                const res = await holidayCalendarsPromise;
                const holidayCalendars = res.data;

                await commit('setHolidayCalendars', holidayCalendars);
            } catch (e) {
                console.warn(`Error occurred when trying to fetch account ${rootState.account.code} holiday calendars: `, e);
            } finally {
                holidayCalendarsPromise = null;
            }
        }
    }
}