var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = __default(require("../../helpers/ifequal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),"UNSORTED",{"name":"ifequal","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":13,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/ifequal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),"VOL_UP",{"name":"ifequal","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":21,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/ifequal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),"VOL_DOWN",{"name":"ifequal","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":28,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../helpers/ifequal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),"TRASH_HIGH",{"name":"ifequal","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":0},"end":{"line":46,"column":12}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"control-group\">\n    DataEQ has found mentions which have been assumed relevant to this conversation but\n    need your input. Please <a href=\""
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"mentionsLink") : depths[1]), depth0))
    + "\">review these mentions</a> to double-check that they\n    are relevant or send those which are not required to Trash. DataEQ will use this information to better\n    understand future conversation which comes into your account.\n</div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"control-group\">\n    The volume of mentions for this "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"q") : depths[1]),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":74}}})) != null ? stack1 : "")
    + " has increased significantly since\n    yesterday. This may be because you have added new phrases or perhaps there is something interesting\n    happening online. Please check your search phrases to make sure they are picking up the right conversation.\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "phrase";
},"7":function(container,depth0,helpers,partials,data) {
    return "brand";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"control-group\">\n    The volume of mentions for this "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"q") : depths[1]),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":25,"column":36},"end":{"line":25,"column":74}}})) != null ? stack1 : "")
    + " has dropped significantly since\n    yesterday. Please check your search phrases to make sure they are picking up the right conversation.\n</div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"control-group\">\n    This "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"q") : depths[1]),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":32,"column":9},"end":{"line":32,"column":47}}})) != null ? stack1 : "")
    + " is sending a lot of mentions to Trash.\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"discardPercentage") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"children") : depths[1]),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":44,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"discardPercentage") : depths[1]), depth0))
    + " % of mentions are being deleted by account rules.\n        Others are being deleted automatically considered irrelevant.\n        Please review your account rules and search phrases.\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "        Some mentions are being automatically considered irrelevant.\n        Please review your search phrases to ensure they're picking up the right conversation.\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "        Remember that you may need to look in your sub-brands to isolate the source of the Trash.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-body dialog-body-tooltip\">\n    <div class=\"row-fluid\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"volume") : depth0)) != null ? lookupProperty(stack1,"messages") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":48,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n\n";
},"useData":true,"useDepths":true});