var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                            <td>"
    + container.escapeExpression(__default(require("../../helpers/capitalize.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":51}}}))
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr>\n                            <td class=\"dark\">"
    + container.escapeExpression(__default(require("../../helpers/capitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":31,"column":45},"end":{"line":31,"column":70}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"cells") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":34,"column":37}}})) != null ? stack1 : "")
    + "                        </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                                <td>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"positive_precision") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":52,"column":58},"end":{"line":52,"column":97}}}));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"positive_recall") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":55,"column":55},"end":{"line":55,"column":91}}}));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"positive_f1_score") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":58,"column":57},"end":{"line":58,"column":95}}}));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"negative_precision") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":75,"column":58},"end":{"line":75,"column":97}}}));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"negative_recall") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":78,"column":55},"end":{"line":78,"column":91}}}));
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"negative_f1_score") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":81,"column":57},"end":{"line":81,"column":95}}}));
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"neutral_precision") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":98,"column":58},"end":{"line":98,"column":96}}}));
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"neutral_recall") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":101,"column":55},"end":{"line":101,"column":90}}}));
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../helpers/formatScore.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"test") : depth0)) != null ? lookupProperty(stack1,"neutral_f1_score") : stack1),{"name":"formatScore","hash":{},"data":data,"loc":{"start":{"line":104,"column":57},"end":{"line":104,"column":94}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>Crowd vs Machine Sentiment</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"row-fluid\">\n        <div class=\"span12\">\n            <div class=\"right\">\n                <table class=\"confusion-matrix table table-condensed table-bordered table-hover\">\n                    <tr>\n                        <td></td>\n                        <td></td>\n                        <td colspan=\"4\">Confusion Matrix</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td></td>\n                        <td colspan=\"4\">Machine</td>\n                    </tr>\n                    <tr>\n                        <td rowspan=\"5\"><span>Crowd</span></td>\n                        <td></td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"labels") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":27,"column":33}}})) != null ? stack1 : "")
    + "                    </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"rows") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":36,"column":29}}})) != null ? stack1 : "")
    + "                </table>\n            </div>\n            <div class=\"chart-wrapper\">\n                <svg></svg>\n            </div>\n\n            <div class=\"right\">\n                <table class=\"test-metrics table table-condensed table-bordered table-hover\">\n                    <tr>\n                        <td colspan=\"2\">Positive</td>\n                    </tr>\n                    <tr>\n                        <td>Metric</td><td>Score</td>\n                    </tr>\n                    <tr>\n                        <td>Precision</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":46},"end":{"line":52,"column":104}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                    <tr>\n                        <td>Recall</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":43},"end":{"line":55,"column":98}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                    <tr>\n                        <td>F1 Score</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":45},"end":{"line":58,"column":102}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                </table>\n            </div>\n            <div class=\"chart-wrapper\">\n                <svg></svg>\n            </div>\n\n            <div class=\"right\">\n                <table class=\"test-metrics table table-condensed table-bordered table-hover\">\n                    <tr>\n                        <td colspan=\"2\">Negative</td>\n                    </tr>\n                    <tr>\n                        <td>Metric</td><td>Score</td>\n                    </tr>\n                    <tr>\n                        <td>Precision</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":46},"end":{"line":75,"column":104}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                    <tr>\n                        <td>Recall</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":43},"end":{"line":78,"column":98}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                    <tr>\n                        <td>F1 Score</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":45},"end":{"line":81,"column":102}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                </table>\n            </div>\n            <div class=\"chart-wrapper\">\n                <svg></svg>\n            </div>\n\n            <div class=\"right\">\n                <table class=\"test-metrics table table-condensed table-bordered table-hover\">\n                    <tr>\n                        <td colspan=\"2\">Neutral</td>\n                    </tr>\n                    <tr>\n                        <td>Metric</td><td>Score</td>\n                    </tr>\n                    <tr>\n                        <td>Precision</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":46},"end":{"line":98,"column":103}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                    <tr>\n                        <td>Recall</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":43},"end":{"line":101,"column":97}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                    <tr>\n                        <td>F1 Score</td><td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"test") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":45},"end":{"line":104,"column":101}}})) != null ? stack1 : "")
    + "</td>\n                    </tr>\n                </table>\n            </div>\n            <div class=\"chart-wrapper\">\n                <svg></svg>\n            </div>\n\n        </div>\n    </div>\n\n    <div class=\"dialog-button-bar\">\n        <a class=\"ok btn btn-primary\">Close</a>\n    </div>\n</div>\n";
},"useData":true});