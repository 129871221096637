
export class Delimiter {


    constructor({api}) {
        this.api = api;
    }

    static get toolbox(){
        return {
            title: 'Delimiter',
            icon: '<i class="icon-dot-3" ></i>',
        }
    }

    render(){
        const delimiter = document.createElement("div");
        delimiter.style.borderBottom = "thin dashed var(--be-colour-mid-grey)";
        return delimiter;
    }

    static get isReadOnlySupported() {
        return !0;
    }

    save(t){
        return {}
    }


}