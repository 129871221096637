import {cloneDeep} from 'lodash';
import * as d3s from "d3-sankey";
import * as d3lib from "d3";
const d3 = Object.assign(d3lib, d3s);
import _ from 'underscore';

/**
 * Low level d3 code to render the response sankey. Returns a copy of the data with Sankey stuff filled in.
 */
export default function beefRenderResponseSankey(domNode, width, height, data, options) {
    options = options || {}

    data = cloneDeep(data)
    data.links = data.links.filter(d => d.value)

    // eliminate unreferenced nodes
    let nodesSeen = new Set()
    data.links.forEach(d => {
        nodesSeen.add(d.source)
        nodesSeen.add(d.target)
    })
    data.nodes = data.nodes.filter(d => nodesSeen.has(d.id))
    let fromEngage = data.fromEngage

    let margin = { top: 20, right: 20, bottom: 20, left: 20 }

    const sankey = d3.sankey()
        .size([width - (margin.left + margin.right), height - (margin.top + margin.bottom)])
        .nodeId(d => d.id)
        .nodeWidth(12)
        .nodePadding(16)
        .linkSort(null)
        .nodeSort(null)
        .nodeAlign(d3.sankeyCenter)
    let graph = data.nodes.length ? sankey(data) : data

    // figure out the position for the percentage labels (inline = left or right) .. if the first or last horizontal
    // layer has inline percentages then we need to increase the left and/or right margins and recalculate the layout
    let extraLeftMargin = 0, extraRightMargin = 0
    let maxLayer = d3.max(data.nodes, d => d.layer)
    Object.entries(_.groupBy(data.nodes, "layer")).forEach(e => {
        let [layer, list] = e
        let tot = list.reduce((a,v) => a + (!v.noPercent || v.showValue ? valueOf(v) : 0), 0)
        if (tot) {
            let pp = 'below'
            list.forEach(n => {
                if (!n.noPercent || n.showValue) {
                    if (!n.noPercent) n.percent = valueOf(n) * 100 / tot
                    if (n.y1 - n.y0 < 32 && (!fromEngage || layer > 0)) pp = 'inline'
                }
            })
            list.forEach(n => n.percentPos = n.percent || n.showValue ? pp : undefined)
            if (pp === 'inline') {
                if (layer == 0) extraLeftMargin = 30
                else if (layer == maxLayer) extraRightMargin = 30
            }
        }
    })
    //console.log("data", data)

    if (extraLeftMargin || extraRightMargin) {  // recompute layout
        margin.left += extraLeftMargin
        margin.right += extraRightMargin
        sankey.size([width - (margin.left + margin.right), height - (margin.top + margin.bottom)])
        graph.links.forEach(d => {
            d.source = d.source.id
            d.target = d.target.id
        })
        graph = sankey(data)
    }

    let svg = ensure(d3.select(domNode), "svg").attr("width", width).attr("height", height)
        .classed("from-engage", fromEngage)

    let g = ensure(svg, "g", "main").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    // centre incoming links on nodes where the node is bigger and there is a gap on the bottom left
    graph.nodes.forEach(node => {
        if (!node.targetLinks || !node.targetLinks.length) return
        let tot = 0
        node.targetLinks.forEach(link => tot += link.value)
        if (tot >= node.value) return
        let pixelsPerValue = (node.y1 - node.y0) / node.value
        let pixels = (node.value - tot) * pixelsPerValue / 2
        node.targetLinks.forEach(link => link.y1 += pixels)
    })

    let links = ensure(g, "g", "links").selectAll(".link").data(graph.links)
    links.exit().remove()
    links = links.enter().append("path").merge(links)
    links.attr("class", d => "link " + d.source.id + " " + d.target.id)
        .attr("d", d3.sankeyLinkHorizontal())
        .attr("stroke-width", d => Math.max(1.0, d.width))
    if (options.onClick) links.on("click", options.onClick)

    if (options.showTooltip) {
        links.on("mouseover", options.showTooltip)
    } else {
        links.each(function(link) {
            ensure(d3.select(this), "title")
                .text(link.source.label + " → " + link.target.label + " " + link.value +
                    (fromEngage ? " tickets" : " mentions"))
        })
    }

    let nodes = ensure(g, "g", "nodes").selectAll(".node").data(graph.nodes)
    nodes.exit().remove()
    nodes = nodes.enter().append("g").merge(nodes)
    nodes.each(function(node) {
        let sel = d3.select(this)
        sel.attr("class", "node" + " " + node.id)
            .attr("transform", "translate(" + node.x0 + "," + node.y0 + ")")

        let h = node.y1 - node.y0

        let rect = ensure(sel, "rect").attr("width", node.x1 - node.x0).attr("height", h)
        if (options.onClick) rect.on("click", options.onClick)
        if (options.showTooltip) rect.on("mouseover", options.showTooltip)

        let percentPos = node.percentPos
        let right = node.x1 < width / 2

        ensure(sel, "text", "name")
            .attr("x", right ? 5 + sankey.nodeWidth() : -6)
            .attr("y", h / 2 + (percentPos === 'below' ? -7 : 0))
            .attr("dy", ".35em")
            .attr("text-anchor", right ? "start" : "end")
            .text(node.label)

        if (percentPos) {
            let s = node.showValue ? '' + node.value : ''
            if (!node.noPercent) s = (s ? s + ' (' : '') + formatPercent(node.percent) + (s ? ')' : '')
            let p = ensure(sel, "text", "percent").text(s)
            if (percentPos === 'below') {
                p.attr("x", right ? 5 + sankey.nodeWidth() : -6)
                    .attr("y", h / 2 + 7)
                    .attr("dy", ".35em")
                    .attr("text-anchor", right ? "start" : "end")
            } else {
                p.attr("x", !right ? 5 + sankey.nodeWidth() : -6)
                    .attr("y", h / 2)
                    .attr("dy", ".35em")
                    .attr("text-anchor", !right ? "start" : "end")
            }
        } else {
            sel.select("text.percent").remove()
        }
    })

    return data
}

function formatPercent(p) {
    return (p < 10 ? Math.floor(p * 10 + 0.5) / 10 : Math.floor(p + 0.5)) + "%"
}

function ensure(parent, tag, cls) {
    let sel = parent.select(tag + (cls ? "." + cls : ""))
    if (sel.empty()) {
        sel = parent.append(tag)
        if (cls) sel.attr("class", cls)
    }
    return sel
}

function valueOf(node) {
    if (node.calcValueFromInputs) {
        let tot = 0
        node.targetLinks.forEach(link => tot += link.value)
        return tot
    }
    return node.value
}
