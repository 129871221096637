<template>
    <div class="deq-reset brand-picker-group" :data-is-other="isOther" tabindex="-1">
        <header>
            <h2 class="deq-uppercase-header">
                <span>
                    <slot>Default title — FIX ME</slot>
                    <span v-if="!searchText && numSelected > 0" class="brand-picker-group__count">
                        <deq-number :number="numSelected"/>
                        selected
                    </span>

                    <span v-if="searchText" class="brand-picker-group__count">
                        <span v-if="numMatch">
                            <deq-number :number="numMatch"/>
                            <plural-word word="brand" :count="numMatch"/> found
                        </span>
                        <span v-else>
                            No matches
                        </span>
                    </span>
                </span>


                <be-button v-if="possibleToShowChildren"
                           link
                           :tooltip="`Click to ${showChildToggle ? 'hide' : 'show'} these brands' children`"
                           @click="showChildToggle = !showChildToggle">
                    {{ showChildToggle ? 'Hide' : 'Show' }} children
                </be-button>
            </h2>

        </header>
        <div :data-no-children="!childrenAreVisible"
             class="brand-picker-group__overflow dark-scrollbars dark-scrollbars--visible" tabindex="-1">
            <bp-root-brand v-for="brand of brands"
                           :key="brand.brand.id"
                           :brand="brand"
                           :show-children="childrenAreVisible"
                           :exclude-children="excludeChildren"
                           :allow-exclude-root="allowExcludeRoot"
                           :search-text="searchText"
                           @negate="negate($event)"
                           @selected="selected($event)"/>
        </div>
    </div>
</template>

<script>

import BpRootBrand from "@/components/pickers/brands/BpRootBrand";
import BeButton from "@/components/buttons/BeButton";
import DeqNumber from "@/components/formatters/DeqNumber";
import PluralWord from "@/components/formatters/PluralWord";

export default {
    components: {PluralWord, DeqNumber, BeButton, BpRootBrand},

    props: {
        brands: Array,
        showChildren: Boolean,
        excludeChildren: Boolean,
        isOther: { type: Boolean, default: false},
        allowExcludeRoot: { type: Boolean, default: false},
        searchText: { type: String },
    },

    data() {
        let expandByDefault = !this.isOther;

        if (!expandByDefault) {
            let allChildren = Array.from(this.brands ?? []);
            while (allChildren.length) {
                const brand = allChildren.pop();
                if (brand.brand.parent && brand.selected) {
                    expandByDefault = true;
                    break;
                }
                if (brand.children?.length) allChildren = allChildren.concat(brand.children);
            }
        }
        return {
            showChildToggle: expandByDefault
        }
    },

    computed: {
        childrenAreVisible() {
            return this.showChildren && this.showChildToggle && !this.noChildrenPresent
        },

        possibleToShowChildren() {
            return this.showChildren && this.isOther && this.brands?.some(b => b?.children?.length);
        },

        noChildrenPresent() {
            return this.brands?.every(b => !b?.children?.length) ?? true;
        },

        numSelected() {
            let count = 0;
            const counter = b => {
                if (b.selected) count++;
                b.children?.forEach(c => counter(c));
            };

            this.brands.forEach(b => counter(b));
            return count;
        },

        numMatch() {
            let count = 0;
            const counter = b => {
                if (b.visible) count++;
                b.children?.forEach(c => counter(c));
            };

            this.brands.forEach(b => counter(b));
            return count;
        }
    },

    methods: {
        selected(brand) {
            this.$emit("selected", brand);
        },

        negate(brand) {
            this.$emit("negate", brand);
        }
    }
}

</script>


<style scoped lang="sass">

.brand-picker-group
    grid-column: span 3
    display: grid
    grid-template-columns: 2fr 20px 1fr
    //grid-template-columns: subgrid // subgrid seems to be broken in chromium >= 117. See if this works in the future
    align-content: start

.brand-picker-group:nth-of-type(odd):last-of-type
    grid-column: 1 / -1

header
    position: sticky
    top: 0
    grid-column: 1 / -1
    margin: 0
    h2
        display: flex
        justify-content: space-between
        margin: 0
        padding: 0
        padding-inline: 10px 0
        font-size: 12px
        line-height: var(--min-height)

        > span
            display: inline-block
            width: 100%

        .be-button ::v-deep .btn
            font-size: var(--default-text-size-two-thirds)
            white-space: nowrap
            width: fit-content

.brand-picker-group__count
    padding-left: clamp(0px, 50%, 40px)
    width: auto
    font-size: var(--default-text-size-two-thirds)
    line-height: 1em
    color: var(--background-menu-active)

.brand-picker-group__overflow
    grid-column: span var(--span, 3)
    display: grid
    grid-template-columns: var(--grid-main)
    //grid-template-columns: var(--subgrid-columns) // subgrid seems to be broken in chromium >= 117. See if this works in the future
    max-height: 400px
    overflow-y: auto


.brand-picker-group__overflow[data-no-children]
    grid-template-columns: minmax(150px, 1fr)

[data-is-other] .brand-picker-group__overflow
    max-height: 200px


[data-is-other] .brand-picker-group__overflow[data-no-children]
    grid-template-columns: repeat(auto-fit, minmax(150px, 49%))
    gap: 5px
    align-content: stretch
    > *
        grid-column: unset


</style>