import ObjectStore from './ObjectStore'
import { mash } from '../Services'

/**
 * Fetches and caches brands for the selected account.
 * @deprecated
 */
export default class BrandStore extends ObjectStore {

    constructor() {
        super()
        this.refreshCall = null
    }

    add(o) {
        o = super.add(o)
        if (o.children) {
            for (let i = 0; i < o.children.length; i++) {
                o.children[i] = this.add(o.children[i]);
                o.children[i].parent = o;
            }
        }
        return o
    }

    /**
     * Refresh our tags. Returns promise. This does not start a new fetch if one is already in progress.
     */
    refresh(soft) {
        if (this.refreshCall) return this.refreshCall
        if (this.list && soft) return Promise.resolve(this.list)
        if (!this.accountCode) return Promise.resolve([])
        return this.refreshCall = mash.get("/rest/accounts/" + this.accountCode + "/brands")
            .then(res => {
                this.list = []
                res.data.forEach(brand => this.list.push(this.add(brand)))
                return this.list
            }).finally(() => this.refreshCall = null)
    }

    /**
     * Lookup a tag by id. If we don't have it already a placeholder tag is returned and it is fetched in the
     * background.
     */
    get(id) {
        let brand = this.byId[id]
        if (!brand) {
            brand = { id: id, name: "" + id, shortName: "" + id, children: null, category: null }
            this.byId[brand.id] = brand
            this.refresh()
        }
        return brand
    }

    /**
     * Returns true iff the brand exists in the account.
     */
    async exists(id) {
        await this.refresh(true);
        return !!this.byId[id];
    }

    /**
     * Returns true iff the brand exists in the account. Refresh needs to have been called before this can be used.
     */
    existsSynchronous(id) {
        return !!this.byId[id];
    }

    getDefaultBrandId() {
        var ans = this.list.find(b => b.category === 'OWN')
        if (!ans) ans = this.list[0]
        return ans ? ans.id : null
    }

    /**
     * Returns an array of all brands in the account.
     * @returns {Promise<Array>}
     */
    async getAllBrands() {
        await this.refresh(true);
        return this.list || [];
    }

    /**
     * Returns an array of the root brands in an account.
     * @returns {Promise<Array>}
     */
    async getRootBrands() {
        return (await this.getAllBrands()).filter(b => !b.parent);
    }
}