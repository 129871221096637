var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"mini-menu__loading\">\n    "
    + alias1(__default(require("../helpers/spinner.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"spinner","hash":{"size":14},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":23}}}))
    + " <span>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</span>\n</div>\n";
},"useData":true});