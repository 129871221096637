<template>
    <span class="be-number" :data-value="formatNumber(number, precision)">{{isFinite(number) ? formatNumber(number, precision) : 'NA' }}</span>
</template>

<script>

    import {formatNumber} from "@/app/utils/Format";

    /**
     * Formats a number for display. This also uses an appropriate font
     */
    export default {
        props: {
            number: {
                type: Number,
                required: true
            },
            precision: {
                type: Number
            }
        },

        methods: {
            formatNumber
        }
    }
</script>

<style scoped lang="sass">
.be-number
    font-family: var(--number-font-family)
    white-space: nowrap
</style>