import Paragraph from "@editorjs/paragraph";
import {
    sanitizer,
    openInlineToolbar, shouldDeleteNestedNode,
    shouldOpenInlineTool, toHeader
} from "@/dashboards/widgets/commentaryplus/blockTools/Utils";

export class ParagraphBlock extends Paragraph {

    constructor(data) {
        super(data);
    }

    render() {
        this._element = super.render();
        if (!this.readOnly) {
            this._element.addEventListener('keydown', this.onKeyDown);
        }
        this._element.style.color = 'black';
        return this._element;
    }

    onKeyUp(t) {
        super.onKeyUp(t);
        if (t.code === 'Space') {
            let words = this._element.innerHTML.split(' ');
            switch (words[0].trim()) {
                case '#&nbsp;':
                case '#':
                    toHeader(this._element, words, 1);
                    break;
                case '##&nbsp;':
                case '##':
                    toHeader(this._element, words, 2);
                    break;
                case '###&nbsp;':
                case '###':
                    toHeader(this._element, words, 3);
                    break;
                case '####&nbsp;':
                case '####':
                    toHeader(this._element, words, 4);
                    break;
            }
        }
        if (t.code === 'KeyC') {
            openInlineToolbar();
        }
    }

    static get sanitize() {
        return sanitizer;
    }

}