<template>
    <section class="metric-description" :class="{expanded: optionsExpanded}"
             tabindex="0"
             @keydown.enter.stop="addMetric(metric)">
        <section v-if="metric.image"
                 class="metric-description__image">
            <img :src="metric.image" @click="addMetric(metric)">
        </section>


        <section class="metric_description__content" @click="addMetric(metric)">
            <h1>{{metric.title}}</h1>
            <p v-if="metric.description">
                {{metric.description}}
            </p>
            <p v-else style="color: red">
                «Missing description»
            </p>
            <p v-if="metric.more && !showMore" class="more-less">
                <a class="btn btn-link"  @click.stop="showMore = true">Show more</a>
            </p>
            <p v-if="showMore && metric.more">
                {{metric.more}}
            </p>
            <p v-if="showMore && metric.more" class="more-less">
                <a class="btn btn-link" @click.stop="showMore = false">Show less</a>
            </p>
        </section>
        <section class="metric-description__actions"
                 v-if="metric.reference || metric.options"
                 :style="actionsStyle">
            <a v-if="metric.reference"
               class="btn btn-link metric-description__reference"
               :tooltip="metric.reference.tooltip"
               @click="addMetric(metric.reference)">
                Reference
            </a>
            <a v-for="o in metric.options" :key="o.key"
               class="btn btn-link"
               @mouseover="showTooltip($event, o.tooltip)"
               @click="addMetric(metric, o)">
                {{o.title}}
            </a>
        </section>
    </section>
</template>


<script>

    import {applyOptionAction} from "./availableMetrics";
    import {showTooltipText} from "@/components/tooltip/TooltipUtilities";
    import {showErrorDialog} from "@/app/framework/dialogs/Dialog";

    export default {
        name: "MetricDescription",

        props: {
            metric: Object
        },

        data() {
            return {
                showMore: false,
                optionsExpanded: false
            }
        },

        computed: {
            actionsStyle() {
                if (this.optionsExpanded) return { opacity: 1.0 };
                return {};
            }
        },

        methods: {
            async addMetric(metric, options) {
                metric = await applyOptionAction(metric, options);

                if (!metric) {
                    showErrorDialog("Unable to show this metric. Please contact support@dataeq.com for assistance.");
                    return;
                }
                this.$emit('add', metric);
            },
            showTooltip(ev, text) {
                if (!text) return;
                showTooltipText(ev.target, text)
            }
        }
    };

</script>

<style scoped lang="sass">
    .metric-description
        display: flex
        align-items: center
        --metric-description-height: 100px
        box-sizing: border-box
        width: 100%

        &:focus-visible
            outline: var(--focus-border)

        h1
            margin: 0 0 10px 0
            color: #ddd
            font-size: 1.8rem
            line-height: 1.8rem


        .metric-description:first-of-type
            margin-top: 20px


        .metric-description:not(:first-of-type)
            margin-top: 80px

        .expanded-class__grid .metric-description
            margin-left: 40px


        .metric-description__image
            max-width: 45%
            cursor: pointer
            img
                border-radius: 3px
                height: auto



        .metric_description__content
            cursor: pointer
            align-self: start
            display: flex
            flex-direction: column
            margin: 0 20px
            padding: 10px
            box-sizing: border-box
            width: 100%


        .metric-description__actions
            display: flex
            flex-direction: column
            border-left: thin solid var(--light-border-colour)
            flex-basis: 50%
            align-self: stretch
            justify-content: center
            a
                text-align: left

        p
            color: var(--be-colour-text-dark)
            font-family: var(--widget-font)
            margin: 0

        p + p:not(.more-less)
            margin-top: 5px

        p:last-of-type
            margin-bottom: 10px

        .more-less
            margin: 0 !important
            .btn-link
                margin: 0
                padding: 0

        .btn-link
            &:not(:hover)
                color: var(--be-colour-muted-text-dark)

    @media (max-height: 800px), (max-width: 1280px)
        .metric-description
            h1
                font-size: 1.5rem
                line-height: 1.8rem

</style>
