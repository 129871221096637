import twemoji from 'twemoji';
import VuexStore from "@/store/vuex/VuexStore";
import moment from "moment";
import 'moment-timezone';
import accounting from "accounting";
import {capitalise, isString} from "@/app/utils/StringUtils";
import {getAccountCurrency, symbolMap} from "@/app/utils/Currency";
import {account} from "@/app/utils/Account";

/**
 * Uses twemoji to translate text in to embeddable html that contains emoji images, or to modify
 * a dom element to insert emoji elements. Note that it does not sanitise existing html!!
 * Also, if you want to render text, consider using the render-emoji handle bar helper.
 */
export function toEmojiHtml(textOrDom) {
    if (!textOrDom) return '';
    function callback(icon, options) {
        return '/img/twemoji/' + options.size + '/' + icon + '.png';
    }

    return twemoji.parse(textOrDom, callback);
}

export function humanizeDate(dateTime, useAccountTimezone) {
    const date = moment(dateTime);
    const days = moment().diff(date, 'days');
    if (days < 1) return new Handlebars.SafeString(date.fromNow());
    let f;

    if (days < 7) f =  'Do MMM HH:mm';
    else if (days < 300) f = 'Do MMM';
    else f = 'D MMM YYYY';

    if (useAccountTimezone) {
        var timezone = VuexStore.state.account.timezone;
        var tz = date.tz(timezone);
        if (!tz) {
            tz = date.tz(timezone = 'UTC');
        }
        return tz.format(f);
    } else {
        return date.format(f);
    }
}

/**
 * Returns a brand's client facing name, if possible. Otherwise, its full name.
 * @param {Brand|Object} brand
 * @param {{markArchiveDuplicates: boolean=, markDeletedBrands: boolean=}, optional} options
 * @returns {String}
 */
export function formatBrandName(brand, options) {
    const {markArchiveDuplicates, markDeletedBrands} = {
        markArchiveDuplicates: true,
        markDeletedBrands: true,
        ...options
    };

    let name = (brand?.shortName ?? brand?.name ?? brand?.fullName ?? '«unknown brand»').trim();
    if (brand?.deleted && markDeletedBrands) name = `${name} (Deleted)`;
    const rootBrands = VuexStore.state.rootBrands;

    // We want to do some dedupping of names, where possible.
    if (rootBrands?.length > 1 && brand?.id) {
        brand = VuexStore.getters.idToBrand.get(brand.id) ?? brand;
        if (rootBrands.some(b => b.id !== brand.id && (b.shortName ?? b.name)?.trim() === name)) {
            if (brand.archived && markArchiveDuplicates) return `${name} (Archived)`;
            if (brand.importedFromAccount) {
                name = brand.name?.trim() === name ? `${name} (Imported)` : brand.name;
            }
        }
    }
    return name;
}

/**
 * Formats a user name for display. Hides it if it is an admin user.
 */
export function formatUser(user) {
    const currentUser = VuexStore.state.user;
    const isAdmin = currentUser.admin;
    if (!isAdmin && user.system) return "DataEQ Software";
    if (!isAdmin && user.admin) return "DataEQ Staff";
    return user.name || `${user.firstName} ${user.lastName}`.trim() || user.email;
}

/**
 * Pluralises a word based on a count value.
 *
 * @param count {Number}
 * @param singularWord {String}
 * @param optionalPluralForm {String, optional}
 */
export function formatPlural(count, singularWord, optionalPluralForm) {
    const plural = optionalPluralForm || singularWord + "s";
    return Math.abs(count - 1) < 0.01 ? singularWord : plural;
}

/**
 * Formats a date. Ensures that it is displayed in the account's timezone.
 * If you would like more formatting (so date numbers appear in the correct font,
 * for instance), use the Date component.
 * @param date
 * @param optionalFormat
 */
export function formatDate(date, optionalFormat) {
    optionalFormat = optionalFormat || "YYYY-MM-DD HH:mm";
    const m = moment(date);
    let tz = m.tz(VuexStore.state.account.timezone);
    if (!tz) tz = m.tz('UTC');
    return tz.format(optionalFormat);
}

/**
 * Returns the number as a string, with thousandths separated by spaces.
 * @param {Number} number
 * @param {Number,optional} [accuracy = 0]
 * @param {String,optional} [optSeperator = ' ']
 * @returns {String}
 */
export function formatNumber(number, accuracy, optSeperator) {
    optSeperator = optSeperator === undefined ? ' ' : optSeperator;
    if (accuracy === undefined) accuracy = 0;
    return accounting.formatNumber(number, accuracy, optSeperator);
}

/**
 * Takes a number and returns a string representation of it as a percentage.
 * Note that a percentage sign is added (or a symbol of your choice)
 *
 * Examples:
 * formatPercentage(99) -> 99%
 * formatPercentage(99, 1) -> 99.1%
 * formatPercentage(0.1, 1) -> 0.1%
 * formatPercentage(0.01, 1) -> 0.1%   // Don't want to show 0 if there is some substantial data there.
 * formatPercentage(0.001, 1) -> 0.0%   // Very small data compared to requested accuracy.
 * formatPercentage(0.001, 2) -> 0.01%   // Very small data compared to requested accuracy.
 *
 * @param number {Number}
 * @param accuracy {Number, optional}
 * @param symbol {String, optional}
 *
 */
export function formatPercentage(number, accuracy, symbol) {
    symbol ??= '%';
    accuracy ??= 0;
    if (number === Infinity) {
        return '∞';
    } else {
        var percent = formatNumber(number, accuracy);

        // We generally don't want to show the value 0 if there is a value.
        // Here we test to see if the number isn't super small, then 0 rounded values
        // will be bumped up.
        if (parseFloat(percent) === 0 && number !== 0) {
            var guard = 1 / Math.pow(10, accuracy + 1);
            if (Math.abs(number) >= guard) {
                percent = 1 / Math.pow(10, accuracy) * (Math.sign(number));
            }
        }
        return percent + symbol;
    }
}

/**
 * Formats the namespace of a tag object for display.
 * @param {Tag|Object|null} tag
 * @return {string|null}
 */
export function formatTagNamespace(tag) {
    if (tag && !tag.id) return "new";
    if (!tag?.namespace || tag.namespace === 'tag') return null;
    return tag?.namespace.trim()
}

/**
 * Formats the name of tag object for display.
 * @param {Tag|Object|null} tag
 * @return {string|null}
 */
export function formatTagName(tag) {
    const trimmed = tag.name.trim();

    if (tag.namespace === 'segment_list' && tag.subtitle) {
        const segmentLists = VuexStore.getters.segmentLists;
        if (!segmentLists?.length) return trimmed;
        const cleanName = trimmed.toLowerCase();
        const other = segmentLists.find(t => t.id !== tag.id && t.name.toLowerCase().trim() === cleanName);
        return other ? `${tag.subtitle.trim()} ${trimmed}` : trimmed;
    }

    return trimmed;
}


/**
 * Format a duration in seconds into a human readable but still accurate format.
 */
export function formatSeconds(seconds, fullUnit, hoursThreshold) {
    hoursThreshold ??= 3;
    var n = seconds / (60 * 60 * 24), units;
    if (n > 3) {
        units = "d"
    } else {
        if ((n = seconds / (60 * 60)) > hoursThreshold) {
            units = "h"
        } else {
            if ((n = seconds / 60) > 3) {
                units = "m";
            } else {
                n = seconds;
                units = "s";
            }
        }
    }

    if (fullUnit) {
        var plural = n > 1;
        switch (units) {
            case "d": units = plural ? "days" : "day"; break;
            case "h": units = plural ? "hours" : "hour"; break;
            case "m": units = plural ? "minutes" : "minute"; break;
            case "s": units = plural ? "seconds" : "second"; break;
        }
        units = " " + units;
    }

    return formatNumber(n, n < 10 && ((n * 10 % 10) >= 1) ? 1 : 0) + units;
}


/**
 * Rounds a number using SI units.
 */
export function toSi(number, options) {
    number = accounting.toFixed(number, 0);
    options = Object.assign({decimal: 1}, options || {});
    var n;
    if (Math.abs(n = (number / 1000000)) >= 1) return accounting.toFixed(n, options.decimal) + "m";
    if (Math.abs(n = (number / 1000)) >= 1) return accounting.toFixed(n, options.decimal) + 'k';
    return number;
}


/**
 * Rounds a number to me at most 4 characters wide by using k, m etc. suffixes.
 */
export function numTo4Chars(number) {
    number = accounting.toFixed(number, 0);
    if (number < 10000) return number;
    if (number < 1000000) return accounting.toFixed(number / 1000, 0) + "k";
    if (number < 10000000) return accounting.toFixed(number / 1000000, 1) + "m";
    return accounting.toFixed(number / 1000000) + "m";
}

export function accountDate(dateTime, format) {
    if (!dateTime) return new Handlebars.SafeString("");
    if (!isString(format)) format = "MMMM Do YYYY HH:mm:ss Z";
    const m = moment(dateTime);
    const timezone = account().timezone;
    let tz = m.tz(timezone);
    if (!tz) tz = m.tz('UTC');
    return tz.format(format);
}


export function stripEndingParantheses(text) {
    if (!text) return "";

    text = text.trim();
    if (!text.length) return "";

    var index = text.indexOf('(');
    if (index < 0) return text;
    return text.substring(0, index).trim();
}

/**
 * Formats a number as a unit of currency. Returns a string beginning with the unit,
 * and spaces added to separate out thousandths of a unit.
 *
 * Options include:
 * - cents: defaults to true; when true, shows decimal cents. Otherwise rounds to nearest whole currency unit.
 * - si: defaults to false: when true, uses si units with one decimal place.
 * - decimal: defaults to true: when true, si units of currency show a decimal.
 * - showUnit: defaults to true: when true, shows the currency unit.
 */
export function formatMoney(amount, unit, options) {
    options = Object.assign({
        cents: false,
        si: false,
        decimal: true,
        showUnit: true
    }, options || {});

    unit = options.showUnit ? (symbolMap[unit] || unit) : '';

    if (options.si) return unit + toSi(amount, {decimal: options.decimal});

    if (!options.cents) amount = accounting.toFixed(amount, 0);
    return accounting.formatMoney(amount, unit + ' ', options.cents ? 2 : 0, ' ');
}

/**
 * Formats the given amount using the accounts default currency.
 */
export function formatDefaultCurrency(amount, options) {
    return formatMoney(amount, getAccountCurrency(), options);
}


/**
 * Formats a number as a unit of rand. Returns a string beginning with the unit,
 * and spaces added to separate out thousandths of a unit.
 */
export function formatRand(amount, options) {
    return Beef?.Format?.formatMoney(amount, 'R', options);
}

/**
 * Format's the machine learning fields on a brand
 * @param label
 * @return {string}
 */
export function formatPolicy(label) {
    if (!label) return "";
    if (label === 'MACHINE') return "AI";
    return capitalise(label);
}