<template>
    <div class="be-calendar">
        <div class="be-calendar__title">
            <slot name="title"/>
        </div>
        <header class="be-calendar-header">
            <span @click="backPage()"
                  @keypress.enter="backPage()"
                  tabindex="0"
                  class="be-calendar__chevron">«</span>
            <span class="be-calendar__period"
                  tabindex="0"
                  v-if="state === 'dates'"
                  @keypress.enter="state = 'months'"
                  @click="state = 'months'">{{ month }} {{ year }}</span>
            <span class="be-calendar__period"
                  tabindex="0"
                  v-if="state === 'months'"
                  @keypress.enter="state = 'dates'"
                  @click="state = 'dates'">{{ year }}</span>
            <span @click="forwardPage()"
                  @keypress.enter="forwardPage()"
                  tabindex="0"
                  class="be-calendar__chevron">»</span>
        </header>
        <div class="be-calendar-grid" v-if="state === 'dates'">
            <span class="be-calendar__day">Mo</span>
            <span class="be-calendar__day">Tu</span>
            <span class="be-calendar__day">We</span>
            <span class="be-calendar__day">Th</span>
            <span class="be-calendar__day">Fr</span>
            <span class="be-calendar__day">Sa</span>
            <span class="be-calendar__day">Su</span>

            <span v-for="date in dateList"
                  :key="date.format('YYYY-MM-DD')"
                  class="be-calendar__date"
                  tabindex="0"
                  @click="setDate(date)"
      @keypress.enter="setDate(date)"
                  :class="{
                      'be-calendar__date--selected': date.isSame(mom, 'day'),
                      'be-calendar__date--muted': date.isBefore(pageStart) || date.isAfter(pageEnd),
                      'be-calendar__date--highlighted': isHighlighted(date)
                  }">
                {{date.date()}}
            </span>
        </div>
        <div class="be-calendar-month-grid" v-if="state === 'months'">
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(0)"
                  @click="setMonth(0)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(0), 'month')}">
                Jan
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(1)"
                  @click="setMonth(1)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(1), 'month')}">
                Feb
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(2)"
                  @click="setMonth(2)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(2), 'month')}">
                Mar
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(3)"
                  @click="setMonth(3)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(3), 'month')}">
                Apr
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(4)"
                  @click="setMonth(4)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(4), 'month')}">
                May
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(5)"
                  @click="setMonth(5)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(5), 'month')}">
                Jun
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(6)"
                  @click="setMonth(6)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(6), 'month')}">
                Jul
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(7)"
                  @click="setMonth(7)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(7), 'month')}">
                Aug
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(8)"
                  @click="setMonth(8)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(8), 'month')}">
                Sep
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(9)"
                  @click="setMonth(9)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(9), 'month')}">
                Oct
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(10)"
                  @click="setMonth(10)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(10), 'month')}">
                Nov
            </span>
            <span class="be-calendar__month"
                  tabindex="0"
                  @keypress.enter="setMonth(11)"
                  @click="setMonth(11)"
                  :class="{'be-calendar__month--selected': mom.isSame(currentPageMoment.clone().month(11), 'month')}">
                Dec
            </span>
        </div>
    </div>
</template>

<script>
import moment from "moment";

const FORMATS = ['YYYY-MM-DD', 'YYYY/MM/DD'];

export default {
    props: {
        value: [String,Object],
        rangeEnd: {   // For highlighting a range of dates. This is the other date to highlight to.
            type: [String,Object],
            default: null
        }
    },

    data() {
        return {
            currentPageDate: this.value ?? moment().format("YYYY-MM-DD"), // The date that we are currently viewing.
            state: "dates"
        }
    },

    watch: {
        value() {
            if (this.value) {
                this.currentPageDate = this.value;
            }
        }
    },

    computed: {
        mom() {
            return moment(this.value, FORMATS);
        },
        currentPageMoment() {
            return moment(this.currentPageDate, FORMATS);
        },
        rangeEndMoment() {
            return moment(this.rangeEnd, FORMATS);
        },
        month() {           // The month that we are looking at
            return moment(this.currentPageDate, FORMATS).format("MMMM");
        },
        year() {            // The year that we are looking at
            return moment(this.currentPageDate, FORMATS).format("YYYY");
        },
        pageStart() {
            return moment(this.currentPageDate, FORMATS).startOf('month');
        },
        pageEnd() {
            return moment(this.currentPageDate, FORMATS).endOf('month');
        },
        dateList() {
            const results = [];
            // We are creating a grid of dates. We want to show a few days before and after a month.
            const start = moment(this.currentPageDate, FORMATS).startOf('month').startOf('isoWeek');
            for (let i = 0; i < 7 * 6; i++) {
                results.push(start.clone());
                start.add(1, 'day');
            }

            return results;
        }
    },

    created() {
        if (!this.value) {
            this.setDate(moment());
        }
    },

    methods: {
        setDate(date) {
            this.$emit('input', date.format("YYYY-MM-DD"));
        },

        setMonth(month) {
            this.state = 'dates';
            this.setDate(moment(this.value, FORMATS).month(month).year(this.currentPageMoment.year()));
        },

        backPage() {
            const size = this.state === 'dates' ? 'month' : 'year';
            this.currentPageDate = moment(this.currentPageDate, FORMATS).subtract(1, size).format("YYYY-MM-DD");
        },

        forwardPage() {
            const size = this.state === 'dates' ? 'month' : 'year';
            this.currentPageDate = moment(this.currentPageDate, FORMATS).add(1, size).format("YYYY-MM-DD");
        },

        isHighlighted(date) {
            if (!this.rangeEnd) return false;

            let first = this.mom.isBefore(this.rangeEndMoment) ? this.mom : this.rangeEndMoment;
            let last = this.mom.isBefore(this.rangeEndMoment) ? this.rangeEndMoment : this.mom;
            return date.isSame(first, 'day') ||
                date.isSame(last, 'day') ||
                date.isBetween(first, last, null, "[]");
        }
    }
}
</script>


<style scoped lang="sass">
.be-calendar
    width: 255px
    box-sizing: border-box
    border: thin solid var(--colour-background-black)
    border-radius: 3px

    .be-calendar__title
        text-align: center
        color: var(--be-colour-muted-text-dark)
        background: var(--colour-background-black)
        font-size: calc(1em - 2px)
        line-height: 2em
        cursor: default

    .be-calendar-header
        display: flex
        flex-direction: row
        justify-content: space-between
        margin-top: 2px
        height: 25px
        color: white

    .be-calendar-grid
        --height: 30px
        cursor: default
        display: grid
        grid-template-columns: repeat(7, 1fr)
        grid-auto-rows: var(--height)
        text-align: center
        justify-content: center
        align-items: center

    .be-calendar-month-grid
        --height: 35px
        gap: 20px
        display: grid
        padding: 5px 10px
        box-sizing: border-box
        cursor: default
        grid-template-columns: repeat(3, 1fr)
        grid-auto-rows: var(--height)
        text-align: center
        justify-content: center
        align-items: center

    .be-calendar__chevron
        font-size: calc(1em + 5px)
        margin: 0 5px
        cursor: pointer
        width: 25px
        text-align: center
        &:hover
            background: var(--background-menu-hover)
            color: white
        &:focus-visible
            outline: var(--focus-border)
            outline-offset: -5px

    .be-calendar__period
        cursor: pointer
        min-width: 125px
        text-align: center
        &:hover
            background: var(--background-menu-hover)
            color: white

        &:focus-visible
            outline: var(--focus-border)
            outline-offset: -2px

    .be-calendar__day
        color: var(--be-colour-text-dark)

    .be-calendar__date, .be-calendar__month
        line-height: var(--height)
        color: white

        &--highlighted
            background: var(--colour-background-black)

        &--selected
            color: var(--background-menu-active)
            background: var(--colour-background-black)

        &--muted
            color: var(--be-colour-muted-text-dark)



        &:focus-visible
            outline: var(--focus-border)
            outline-offset: -2px

        &:hover
            color: white
            background: var(--background-menu-hover)
            cursor: pointer

</style>