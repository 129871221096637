import beefRenderSearchJobChartD3 from '../chart/SearchJobChartD3'
import {defaultCustom} from "@/app/utils/Colours";
import _ from 'underscore';
import moment from "moment";
import {setTitle} from "@/app/Beef";
import {numTo4Chars} from "@/app/utils/Format";
import {once} from "@/app/utils/Functions";

/**
 * A module for showing the DataEQ Explore comparison section. The view includes a line chart for the
 * series being compared and a list of all search jobs of an account.
 */
Beef.module("HistoricalSearchOverview").addInitializer(function() {

    var palette = defaultCustom;

    this.View = Backbone.Marionette.Layout.extend({

        attributes: { class: "historical-search-overview" },

        events: {
            "click td.name": "onNameClick",
            "click .legend-entry .toggle-line": "toggleLine",
            "click .legend-entry .remove-line": "removeLineClick",
            "click .export-as-csv": "exportAsCSV",
            "click .create-support-ticket": "createSupportTicket"
        },

        regions: {
            legendRegion: "#legend-region",
            tableRegion: "#table-region",
            titleRegion: ".title-region"
        },

        template: require("@/historical-search/overview/HistoricalSearchOverview.handlebars"),

        templateHelpers: function() {
            return {
                showChart: true,
                chartId: "chart-overview"
            };
        },

        initialize: function() {
            setTitle("Historical");
            Beef.SearchJobSync.list(this.model.get("accountCode"),
                this.onListSuccess.bind(this),
                this.onListError.bind(this)
            );
        },

        onRender: function() {
            if (typeof this.model.get("data") === "undefined") {
                beefRenderSearchJobChartD3.setLoading(true);
            }
            var legendModel = new Backbone.Model({});
            var legendView = new Beef.SearchJobChartLegend.View({ model: legendModel });
            this.legendRegion.show(legendView);

            var tableModel = new Backbone.Model({ jobs: this.model.get("jobs") });
            var tableView = new Beef.HistoricalSearchOverviewList.View({ model: tableModel });
            this.tableRegion.show(tableView);

            var titleView = new Beef.SearchJobChartTitle.View({ model: new Backbone.Model({}) });
            this.titleRegion.show(titleView);
        },

        onListSuccess: function(jobs) {
            jobs = _.sortBy(jobs, function(job) {
                    return -job["id"];
                });
            this.model.set("jobs", jobs);
            this.render();
            var jobListView = this.model.get("jobListView");
            var toAdd = jobListView.model.get("jobs");
            if (toAdd && (toAdd.length > 0)) {
                this.fetchThenAddMultiple(_.pluck(toAdd, "id"));
            } else if (!this.model.get("data") && (jobs.length > 0)) {
                this.fetchThenAdd(jobs[0].id);
            } else {
                this.model.set("data", []);
                this.renderChart();
            }
        },

        onListError: function(xhr, status, error) {
            console.error(status + " " + error);
            Beef.HistoricalSearch.Message.onNetworkError();
        },

        /**
         * Handles an event where a row in the job list is clicked. If the job is shown in the chart, it is
         * removed. Otherwise, it is added to the chart.
         */
        onNameClick: function(ev) {
            var id = $(ev.target).data("job");
            var key = beefRenderSearchJobChartD3.toKey(id);
            var index = beefRenderSearchJobChartD3.findSeries(key, this.model.get("data"));
            if (index >= 0) {
                this.removeSeries(key);
            } else {
                this.fetchThenAdd(id);
            }
        },

        /**
         * Toggles the visibility of the SVG line of a series in the chart.
         */
        toggleLine: function(ev) {
            var el = ev.target.parentElement;
            var key = el.dataset.key;
            var legendEntry = key ? document.getElementById("legend-entry-" + key) : null;
            if (legendEntry) {
                var data = this.model.get("data");
                var line = document.getElementById("line-" + key);
                legendEntry.classList.toggle("hide");
                line.classList.toggle("hide");

                var mustHide = line.classList.contains("hide");
                d3.select(".tooltip-layer").selectAll(".line-" + key)
                    .style("opacity", mustHide ? 0 : 1);

                // keep track of state in case a render is triggered for a region
                var index = beefRenderSearchJobChartD3.findSeries(key, data);
                if (index >= 0) {
                    data[index].hide = mustHide;
                } else {
                    console.error("Cannot hide data series: series not found");
                }
            } else {
                console.error("The chart legend entry does not have a key.");
            }
        },

        removeLineClick: function(ev) {
            this.removeSeries(ev.target.parentElement.parentElement.dataset.key);
        },

        /**
         * Removes a series from the chart.
         * @param key The unique key of a series.
         */
        removeSeries: function(key) {
            var data = this.model.get("data");
            var index = beefRenderSearchJobChartD3.findSeries(key, data);
            if (index >= 0) {
                var chart = this.model.get("chart");
                data.splice(index, 1);
                setColours(data);
                chart.render(data);
                this.tableRegion.currentView.update(data);
                this.legendRegion.currentView.update(chart, data);
                var jobs = this.findJobsInData(data);
                this.model.get("jobListView").update(jobs);
            } else {
                console.error("The chart legend entry does not have a key.");
            }
        },

        /**
         * Finds all jobs in the current model's job list that are in the given data set and returns them.
         * @param data A chart data set.
         * @return {Object[]} A list of found jobs, or an empty list if none are found.
         */
        findJobsInData: function(data) {
            var keys = _.pluck(data, "key");
            var found = [];
            var jobs = this.model.get("jobs") || [];
            for (var i = 0; i < jobs.length; ++i) {
                if (keys.indexOf(beefRenderSearchJobChartD3.toKey(jobs[i].id)) >= 0) {
                    found.push(jobs[i]);
                }
            }
            return found;
        },

        /**
         * Fetches a job for an account and adds it to the chart.
         * @param id The id of the job to fetch.
         */
        fetchThenAdd: function(id) {
            var that = this;
            var data = this.model.get("data");
            var code =this.model.get("accountCode");
            var success = function(job) {
                if (job) {
                    that.addSeries(beefRenderSearchJobChartD3.formatData([job], code)[0]);
                    that.renderChart();
                } else {
                    console.error("No job received from broccoli");
                    Beef.HistoricalSearch.Message.onUnknownError();
                }
            };
            var error = function(xhr, status, error) {
                console.error(status + " " + error);
                Beef.HistoricalSearch.Message.onNetworkError();
            };
            Beef.SearchJobSync.get(code, id, success, error);
        },

        /**
         * Silently adds a series to the current data set.
         * @param series The series to add.
         */
        addSeries: function(series) {
            var data = this.model.get("data") || []; // this might be the first series being added
            data.push(series);
            this.model.set("data", data, { silent: true });
        },

        /**
         * Adds multiple search jobs to the chart and updates the view.
         * @param ids a list of search jobs to add.
         */
        fetchThenAddMultiple: function(ids) {
            var that = this;
            var code = this.model.get("accountCode");
            var seriesMap = {};
            var updateAfter = _.after(ids.length, function() {
                for (var id in seriesMap) {
                    if (seriesMap.hasOwnProperty(id)) {
                        that.addSeries(seriesMap[id]);
                    }
                }
                that.renderChart();
            });

            // use once to avoid multiple popups for similar errors
            var onNetworkErrorOnce = once(function() { Beef.HistoricalSearch.Message.onNetworkError(); });
            var onUnknownErrorOnce = once(function() { Beef.HistoricalSearch.Message.onUnknownError(); });

            var success = function(job) {
                if (job) {
                    seriesMap[beefRenderSearchJobChartD3.toKey(job.id)] = beefRenderSearchJobChartD3.formatData([job], code)[0];
                    updateAfter();
                } else {
                    console.error("No job received from broccoli");
                    onUnknownErrorOnce();
                }
            };
            var error = function(xhr, status, error) {
                console.error(status + " " + error);
                onNetworkErrorOnce();
            };

            for (var i = 0; i < ids.length; ++i) {
                Beef.SearchJobSync.get(code, ids[i], success, error);
            }
        },

        renderChart: function() {
            beefRenderSearchJobChartD3.setLoading(false);
            var data = this.model.get("data");
            setColours(data); // TODO set colours in chart
            var chart = this.model.get("chart");
            var svg = document.getElementById("chart-overview");
            if (chart) {
                chart.render(data);
            } else {
                if (svg) {
                    var rect = svg.getBoundingClientRect();
                    chart = beefRenderSearchJobChartD3.initialize({
                        container: this.el,
                        svg: svg,
                        width: rect.width,
                        height: rect.height,
                        data: data,
                        y: {
                            tickFormat: function(y) {
                                return ((y < 0.01) || (y > 10)) ? numTo4Chars(y) : y.toFixed(1);
                            },
                            label: "Count"
                        },
                        GET: Beef.Sync.broccoliGET,
                        pollOn: true,
                        accountCode: this.model.get("accountCode"),
                        tooltip: {
                            textOn: false,
                            beefTooltipOn: true
                        }
                    });
                    this.model.set("chart", chart, { silent: true });
                }
            }
            if (this.legendRegion) {
                this.legendRegion.currentView.update(chart, data);
            }
            if (this.tableRegion) {
                this.tableRegion.currentView.update(data);
            }
            var jobs = this.findJobsInData(data);
            this.model.get("jobListView").update(jobs);
        },

        /**
         * Export the chart's data set as a CSV.
         */
        exportAsCSV: function() {
            var chart = this.model.get("chart");
            var data = this.model.get("data");
            if (chart && data && (data.length > 0)) {
                chart.exportAsCSV(data, "search " +  moment().format("YYYY-DD-MM HH:mm:ss") + ".csv");
            }
        },

        /**
         * Opens a support ticket form.
         */
        createSupportTicket: function() {
            Beef.SupportTicket.show();
        }
    });

    function setColours(data) {
        if (data) {
            for (var i = 0; i < data.length; ++i) {
                data[i].colour = palette[i % palette.length];
            }
        }
    }
});
