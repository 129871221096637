/**
 * Drill down by adding/removing languages to the filter.
 */
import {formatNumber} from "@/app/utils/Format";

Beef.module("Widget.LanguageSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Languages",
        description:    "Interactively filter by language",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.CountSelector.View.extend({

        filterAttribute: "language",

        getTooltipText: function(item) {
            var lower = item.name.toLowerCase();
            if (lower == 'unknown') return "Click to select mentions whose language we do not recognise";

            return "Click to select mentions in " + item.name;
        },

        getCountTooltip: function(item) {
            var mentionText = item.count == 1 ? "mention" : "mentions";
            var verb = item.count == 1 ? "is" : "are";
            var label = item.name;
            if (label.toLowerCase() == 'unknown') label = "an unknown language";
            return "There " + verb + " " + formatNumber(item.count) + " " + mentionText + " in " + label;
        },

        mapData: function(d) {
            if (d.language) return { id: d.language.id, name: d.language.name, count: d.mentionCount };
            return { id: "UNKNOWN", name: "Unknown", count: d.mentionCount }
        }
    });
});