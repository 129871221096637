var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"row-fluid head title edit-brand\" title=\"Click to edit\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " class=\"row-fluid head title\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"read-only\" style=\"padding-left: 24px\">\n        Brand is "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"archived") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":17},"end":{"line":4,"column":59}}})) != null ? stack1 : "")
    + "read-only"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":266}}})) != null ? stack1 : "")
    + "\n    </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "archived and ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":252}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", imported from <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "/setup/brands/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "</a>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"category\">"
    + container.escapeExpression(__default(require("../../helpers/brandCategory.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"category") : stack1),"span12",{"name":"brandCategory","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":72}}}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"shortName") : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    return "class=\"root-brand-options-container\"";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":48},"end":{"line":24,"column":120}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    return "edit-root-brand-description";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr class=\"brand-description-row\" data-language-code=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" title=\"Edit the description\">\n                        <td>"
    + alias2(__default(require("../../helpers/renderLanguage.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"renderLanguage","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":55}}}))
    + "</td>\n                        <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</td>\n                        <td>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1])) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":34,"column":45}}})) != null ? stack1 : "")
    + "\n                        </td>\n                    </tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":49},"end":{"line":34,"column":38}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                            <span title=\"Remove this description\" class=\"remove-brand-description\" data-language-code=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n                                <i class=\"icon-trash-1\"></i>\n                            </span>\n                        ";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":38},"end":{"line":44,"column":30}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    return "\n                    <tr data-language-code=\"\" title=\"Add a new brand description\">\n                        <td><i class=\"icon-plus\"></i></td>\n                        <td>Add a description</td>\n                        <td></td>\n                    </tr>\n                ";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"archived\">Archived on "
    + container.escapeExpression(__default(require("../../helpers/moment.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"archived") : stack1),"MMMM Do YYYY",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":51,"column":47},"end":{"line":51,"column":87}}}))
    + ", not collecting new mentions\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canArchive") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":52,"column":108}}})) != null ? stack1 : "")
    + "\n            </span>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"unarchive\" data-brand=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">unarchive</a>";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":56,"column":18},"end":{"line":56,"column":117}}})) != null ? stack1 : "")
    + ">\n                <span class=\"description\">"
    + alias2(__default(require("../../helpers/formatConversationFeedFilter.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"mentionFilter") : stack1),{"name":"formatConversationFeedFilter","hash":{},"data":data,"loc":{"start":{"line":57,"column":42},"end":{"line":57,"column":94}}}))
    + ": </span>\n                "
    + alias2(__default(require("../../helpers/formatConversationFilter.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"mentionFilter") : stack1),{"name":"formatConversationFilter","hash":{},"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":64}}}))
    + ".\n            </span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "class=\"filter edit-brand\" title=\"Click to edit\"";
},"35":function(container,depth0,helpers,partials,data) {
    return " class=\"filter\"";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":62,"column":18},"end":{"line":62,"column":115}}})) != null ? stack1 : "")
    + ">\n                <span class=\"description\">Social networks: </span>\n                Only search "
    + container.escapeExpression(__default(require("../../helpers/formatSocialNetworksList.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"socialNetworks") : stack1),{"name":"formatSocialNetworksList","hash":{},"data":data,"loc":{"start":{"line":64,"column":28},"end":{"line":64,"column":77}}}))
    + ".\n            </span>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-brand\" title=\"Click to edit\"";
},"40":function(container,depth0,helpers,partials,data) {
    return " class=\"feeds\"";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":68,"column":18},"end":{"line":68,"column":115}}})) != null ? stack1 : "")
    + ">\n                <span class=\"description\">Feeds: </span>\n                Only search "
    + container.escapeExpression(__default(require("../../helpers/formatFeedsList.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"feeds") : stack1),{"name":"formatFeedsList","hash":{},"data":data,"loc":{"start":{"line":70,"column":28},"end":{"line":70,"column":59}}}))
    + ".\n            </span>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "edit";
},"46":function(container,depth0,helpers,partials,data) {
    return "view";
},"48":function(container,depth0,helpers,partials,data) {
    return "(none)";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":78,"column":39}}})) != null ? stack1 : "")
    + "\n                <span title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"unless","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":46},"end":{"line":79,"column":91}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</span>\n";
},"51":function(container,depth0,helpers,partials,data) {
    return ", ";
},"53":function(container,depth0,helpers,partials,data) {
    return "class=\"inactive\"";
},"55":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-segments editable\" title=\"Click to edit\"";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span title=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ifMashAdmin","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(66, data, 0),"data":data,"loc":{"start":{"line":88,"column":20},"end":{"line":112,"column":36}}})) != null ? stack1 : "")
    + "                </span>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":24},"end":{"line":89,"column":48}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":24},"end":{"line":98,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inactive") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":24},"end":{"line":108,"column":31}}})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    return " | ";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span><i title=\"Active "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " segments\" class=\"icon-check-1\"></i> "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " (active) » </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"each","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":28},"end":{"line":97,"column":37}}})) != null ? stack1 : "");
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":32},"end":{"line":93,"column":55}}})) != null ? stack1 : "")
    + "\n                                <span title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\">\n                                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "\n                                </span>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span><i title=\"Inactive "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " segments\" class=\"icon-cancel-circle\"></i> "
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " (inactive) » </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inactive") : depth0),{"name":"each","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":28},"end":{"line":107,"column":37}}})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":24},"end":{"line":110,"column":107}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inactive") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":24},"end":{"line":111,"column":117}}})) != null ? stack1 : "")
    + "\n";
},"67":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <span><i class=\"icon-check-1\"></i> "
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"key")), depth0))
    + " (active)</span> ";
},"69":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <span> <i class=\"icon-cancel-circle\"></i> "
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"key")), depth0))
    + " (inactive)</span> ";
},"71":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-profiles editable edit-brand\" title=\"Click to edit\"";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(74, data, 0),"inverse":container.program(76, data, 0),"data":data,"loc":{"start":{"line":122,"column":35},"end":{"line":122,"column":167}}})) != null ? stack1 : "")
    + " ";
},"74":function(container,depth0,helpers,partials,data) {
    return "class=\"feeds edit-working-hours editable\" title=\"Click to edit\" ";
},"76":function(container,depth0,helpers,partials,data) {
    return " class=\"feeds\" ";
},"78":function(container,depth0,helpers,partials,data) {
    return "\n            class=\"feeds edit-colour editable\" title=\"Click to edit the colour that the brand appears as in metrics and reports\"\n";
},"80":function(container,depth0,helpers,partials,data) {
    return "            class=\"feeds\" title=\"The colour the brand appears as in metrics and reports\"\n        ";
},"82":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedByAccounts") : stack1),{"name":"if","hash":{},"fn":container.program(83, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":35},"end":{"line":153,"column":11}}})) != null ? stack1 : "");
},"83":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"row-fluid\">\n            <div class=\"span-12\">\n                Brand is imported by\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedByAccounts") : stack1),{"name":"each","hash":{},"fn":container.program(84, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":16},"end":{"line":150,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    ";
},"84":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"index")),{"name":"if","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":20},"end":{"line":148,"column":43}}})) != null ? stack1 : "")
    + "\n                    <span><a href=\"/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "/setup/brands/"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1])) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "</a> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n";
},"86":function(container,depth0,helpers,partials,data) {
    return "<span class=\"pull-right\">Add a sub-brand</span>";
},"88":function(container,depth0,helpers,partials,data) {
    return "                <td class=\"edit-brand\">Edit</td>\n";
},"90":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./BrandTree.handlebars"),depth0,{"name":"./BrandTree","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"92":function(container,depth0,helpers,partials,data) {
    return "4";
},"94":function(container,depth0,helpers,partials,data) {
    return "3";
},"96":function(container,depth0,helpers,partials,data) {
    return "checked=\"true\"";
},"98":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/iffeature.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"editBrandJson",{"name":"iffeature","hash":{},"fn":container.program(99, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":30},"end":{"line":194,"column":22}}})) != null ? stack1 : "");
},"99":function(container,depth0,helpers,partials,data) {
    return "\n            <a class=\"btn edit-json\">Edit JSON</a>\n        ";
},"101":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":12},"end":{"line":202,"column":19}}})) != null ? stack1 : "");
},"102":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span style=\"margin-right: 16px\">Brand is imported from <a href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "/setup/brands/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"importedFromAccount") : stack1), depth0))
    + "</a></span>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"importBrand",{"name":"ifallowed","hash":{},"fn":container.program(103, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":16},"end":{"line":201,"column":30}}})) != null ? stack1 : "");
},"103":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn remove-imported-brand\" title=\"Remove imported brand from this account\">Remove from this account</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEditRoot") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":137}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"category") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <h1>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"shortName") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":79}}})) != null ? stack1 : "")
    + "</h1>\n</div>\n\n<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBottomMargin") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":5},"end":{"line":13,"column":72}}})) != null ? stack1 : "")
    + ">\n    <div class=\"root-brand-description root-brand-description-float-right\">\n        <table class=\"table\">\n            <thead>\n                <tr title=\"Brand descriptions which are visible to the Crowd\">\n                    <th></th>\n                    <th>Descriptions for the Crowd</th>\n                    <th></th>\n                </tr>\n            </thead>\n\n            <tbody class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":127}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = __default(require("../../helpers/eachMap.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"description") : stack1),{"name":"eachMap","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":37,"column":28}}})) != null ? stack1 : "")
    + "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":44,"column":37}}})) != null ? stack1 : "")
    + "\n            </tbody>\n        </table>\n    </div>\n\n    <div class=\"root-brand-options\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"archived") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"mentionFilter") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":8},"end":{"line":60,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"socialNetworks") : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":66,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"feeds") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":72,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"feeds edit-topic-tree editable\" title=\"Click to "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTopicTree") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.program(46, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":74,"column":68},"end":{"line":74,"column":116}}})) != null ? stack1 : "")
    + "\">\n            <span class=\"description\">Topic trees: </span>\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"topicTrees") : stack1),{"name":"unless","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":57}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"topicTrees") : stack1),{"name":"each","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":80,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEditTopicTree") : stack1),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":83,"column":13},"end":{"line":83,"column":131}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Segments: </span>\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"segmentLists") : stack1),{"name":"unless","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":85,"column":59}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"segmentMap") : depth0),{"name":"each","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":114,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(71, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":117,"column":13},"end":{"line":117,"column":133}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Support profiles: </span>\n            <span class=\"support-profiles\"></span>\n        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(73, data, 0, blockParams, depths),"inverse":container.program(76, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":122,"column":13},"end":{"line":122,"column":198}}})) != null ? stack1 : "")
    + " >\n            <span class=\"description\">Working hours: </span>\n            <span class=\"working-hours\"></span>\n        </div>\n\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(71, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":127,"column":13},"end":{"line":127,"column":133}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Other profiles: </span>\n            <span class=\"other-profiles\"></span>\n        </div>\n\n        <div style=\"position: relative\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(78, data, 0, blockParams, depths),"inverse":container.program(80, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":132,"column":40},"end":{"line":136,"column":15}}})) != null ? stack1 : "")
    + ">\n            <span class=\"description\">Colour: </span>\n            <input type=\"text\" class=\"brand-colour\" value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"colour") : stack1), depth0))
    + "\">\n            <span class=\"colour-name\"></span>\n        </div>\n    </div>\n\n    "
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isAccountAdmin",{"name":"ifallowed","hash":{},"fn":container.program(82, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":4},"end":{"line":153,"column":25}}})) != null ? stack1 : "")
    + "\n</div>\n\n<div class=\"main\">\n    <table class=\"table table-condensed table-bordered table-hover\">\n        <thead>\n        <tr>\n            <td class=\"brand\">Brand\n                <a class=\"expand-all\" title=\"Expand/collapse tree\">All<i class=\"plus icon-down-open\"></i><i class=\"minus icon-up-open\"></i></a>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(86, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":16},"end":{"line":162,"column":92}}})) != null ? stack1 : "")
    + "\n            </td>\n            <td class=\"phrases\">Phrases</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(88, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":12},"end":{"line":167,"column":19}}})) != null ? stack1 : "")
    + "            <td class=\"trash\" title=\"% of mentions sent to trash\">Trash</td>\n        </tr>\n        </thead>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"brand") : depth0),{"name":"with","hash":{},"fn":container.program(90, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":8},"end":{"line":173,"column":17}}})) != null ? stack1 : "")
    + "        <tfoot>\n        <tr>\n            <td colspan=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(92, data, 0, blockParams, depths),"inverse":container.program(94, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":176,"column":25},"end":{"line":176,"column":64}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"form-inline\">\n                    <label class=\"checkbox\">\n                        <input type=\"checkbox\" class=\"showInactive\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_showInactive") : depth0),{"name":"if","hash":{},"fn":container.program(96, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":68},"end":{"line":179,"column":110}}})) != null ? stack1 : "")
    + "> Show inactive search phrases\n                    </label>\n                    <label class=\"checkbox\">\n                        <input type=\"checkbox\" class=\"showDeleted\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_showDeleted") : depth0),{"name":"if","hash":{},"fn":container.program(96, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":67},"end":{"line":182,"column":108}}})) != null ? stack1 : "")
    + "> Show brands and phrases in Trash\n                    </label>\n                </div>\n            </td>\n        </tr>\n        </tfoot>\n    </table>\n\n\n    <div class=\"extras\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"_canEdit") : stack1),{"name":"if","hash":{},"fn":container.program(98, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":8},"end":{"line":194,"column":29}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(101, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":8},"end":{"line":203,"column":22}}})) != null ? stack1 : "")
    + "    </div>\n\n</div>\n\n<div class=\"overlay\" style=\"display: none\">\n    <span class=\"spinner\"></span>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});