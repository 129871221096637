<template>
    <section class="trending-topics" :class="classes">
        <section>
            <overview-description>
                <h4>Trending topics</h4>
                <p>
                    These are the topics we've seen {{ englishDate }} that have unusual volumes.
                    You can <a href="#" @click.prevent.stop="createDashboard()"><i class='symbol-reports'></i>create a dashboard</a> to explore this data further.
                </p>

            </overview-description>

            <div v-if="spikingToday.length" class="trending-topics__spiking-today animated fadeIn">
                <i class="symbol-info"></i>
                <div>
                    <p>These topics are spiking today:</p>
                    <div class="trending-topics__spiking-today-tags">
                        <slotted-tag v-for="spike in spikingToday" :key="spike.topic.id"
                                     @mouseenter="showBarTooltip($event, spike.topic)"
                                     @click="seeMentions(spike, 'published on today')"
                                     no-close>
                            <net-sentiment :net-sentiment="spike.net" no-number/>
                            {{spike.topic.name}}
                        </slotted-tag>
                    </div>
                </div>
            </div>

            <div class="trending-topics__current">
                <div class="trending-topics__table">
                    <trend-table v-if="topics.length"
                                 :topics="topics"
                                 :disabled="loading"
                                 class="short-animated fadeIn"
                                 with-footer
                                 with-average
                                 with-max-date
                                 :size-restriction="10"
                                 @seeMentions="seeMentions($event)"
                                 @seeWordcloud="seeWordcloud($event)"
                                 @seeAuthors="seeAuthors($event)"/>
                    <short-loading v-if="loading && topics.length"/>
                    <no-topics-message v-if="!loading && !topics.length"/>
                    <most-frequent-topic v-if="topicFilter"
                                         :filter="topicFilter"
                                         class="trending-topics-frequent animated fadeIn delay-1000"/>
                    <loading-message v-if="loading && !topics.length" message="Reading your trending topics..."/>

                </div>

                <navigation-controls @filter="additionalFilter = $event" :loading="loading" :brand="brand"/>
            </div>
        </section>

        <section v-if="topics.length"
                 ref="charts"
                 class="trending-topics__charts">
            <dark-card class="trending-topics__typical">
                <h4>Typical averages</h4>
                <p>
                    These are the daily average volumes for your topics over the last last few months. These are the volumes
                    that you might expect to see on a typical day.
                </p>
                <div class="trending-topics__chart"
                     ref="typicalChart">
                </div>


                <div class="trending-topics__trend-description">
                    <span class="trending-topics__trend-block"></span> topics that spiked {{englishDate}}
                </div>
            </dark-card>

            <dark-card class="trending-topics__actual">
                <h4>Actual values</h4>
                <p>
                    These are the actual volumes of each topic {{englishDate}}.
                    Topics are only applied to a sample of data, so these numbers don't represent the "real" value, just the relative size of each topic.
                </p>

                <div class="trending-topics__chart"
                     ref="actualChart">
                </div>

                <div class="trending-topics__trend-description">
                    <span class="trending-topics__trend-block"></span> topics that spiked {{englishDate}}
                </div>
            </dark-card>
        </section>


    </section>
</template>

<script>

import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import * as b3js from "brandseyejs";
import {getTrendingTopicFilter, loadTrendingTopics} from "@/app/toplevel/explore/overview/ExploreUtilities";
import TrendTable from "@/app/toplevel/explore/overview/TrendTable";
import LoadingMessage from "@/components/LoadingMessage";
import MostFrequentTopic from "@/app/toplevel/explore/overview/components/MostFrequentTopic";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";
import NavigationControls from "@/app/toplevel/explore/overview/components/NavigationControls";
import ShortLoading from "@/app/toplevel/explore/overview/components/ShortLoading";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {mapGetters} from "vuex";
import {formatBrandName, formatNumber} from "@/app/utils/Format";
import DarkCard from "@/components/cards/DarkCard";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import MostFrequentTopicTooltip from "@/app/toplevel/explore/overview/components/MostFrequentTopicTooltip";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {createTopicPanelDashboard} from "@/app/toplevel/explore/overview/OverviewDashboards";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import moment from "moment";
import SlottedTag from "@/components/tags/SlottedTag";
import NetSentiment from "@/components/NetSentiment";

export default {
    components: {
        OverviewDescription,
        NetSentiment,
        SlottedTag,
        DarkCard,
        NoTopicsMessage,
        ShortLoading,
        NavigationControls,
        MostFrequentTopic, LoadingMessage, TrendTable},
    props: {
        brand: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            topics: [],
            typicalTopics: [],
            actualTopics: [],
            typicalChart: null,
            actualChart: null,
            topicFilter: null,
            additionalFilter: null,
            spikingSet: new Set(),

            sizeObserver: null
        }
    },

    computed: {
        ...mapGetters('explorePanel', ['dateFilter', 'englishDate']),

        classes() {
            const classes = [];
            if (this.loading) classes.push("trending-topics--loading");
            return classes;
        },

        spikingToday() {
            if (!this.topics) return [];
            return this.topics.filter(t => t.isSpike && t.maxDate && moment().startOf('day').isSameOrBefore(t.maxDate));
        }
    },

    watch: {
        typicalTopics() {
            this.$nextTick(() => this.renderTypical());
        },
        actualTopics() {
            this.$nextTick(() => this.renderActual());
        },
        brand() {
            this.loadTopics();
        },
        additionalFilter() {
            this.loadTopics();
        },
        dateFilter() {
            this.loadTopics();
        }
    },

    async mounted() {
        await this.loadTopics();

        // Start watching for size changes, to redraw the charts.
        this.sizeObserver = new ResizeObserver(() => this.redrawCharts() );
        if (this.$refs.charts) this.sizeObserver.observe(this.$refs.charts);
    },

    async beforeDestroy() {
        this.sizeObserver?.disconnect();
        this.sizeObserver = null;
    },

    methods: {
        seeMentions(topic, extraFilter) {
            let filter = appendFiltersReadably(this.topicFilter, `topic is ${topic.topic.id}`);
            if (extraFilter) filter = appendFiltersReadably(filter, extraFilter);
            showMentions(filter, `Mentions for '${topic.topic.name} (by engagement)'`, true);
        },

        seeWordcloud(topic) {
            let filter = appendFiltersReadably(this.topicFilter, `topic is ${topic.topic.id}`);
            showWordcloud(
                filter,
                `Mentions for '${topic.topic.name}'`
            );
        },

        seeAuthors(topic) {
            let filter = appendFiltersReadably(this.topicFilter, `topic is ${topic.topic.id}`);
            gotoAuthorsPanel(filter);
        },

        async createDashboard() {
            const filter = await getTrendingTopicFilter(this.brand.id, this.dateFilter); // Leave out additional filter.
            await createTopicPanelDashboard(filter, `Exploring topics for ${formatBrandName(this.brand)} ${this.englishDate}`, "From Explore");
        },

        async loadTopics() {
            if (!this.brand) return;
            if (this.loading) {
                console.warn("Already loading topics");
                return;
            }

            try {
                this.loading = true;
                // Don't clear topics to provide better loading experience.
                // this.topics = [];
                // this.typicalTopics = [];
                // this.actualTopics = [];
                this.topicFilter = null;

                const results = await loadTrendingTopics(this.brand.id, this.dateFilter, this.additionalFilter, 20);

                this.spikingSet.clear();
                results?.trendingTopics?.forEach(t => {
                    if (t.isSpike) this.spikingSet.add(t.topic.id);
                });

                this.topicFilter = await getTrendingTopicFilter(this.brand.id, this.dateFilter, this.additionalFilter);
                this.topics = results?.trendingTopics ?? [];
                this.typicalTopics = results?.typicalTopics ?? [];
                this.actualTopics = results?.periodTopics ?? [];
            } finally {
                this.loading = false;
            }
        },

        takeWithSpikes(max, topics) {
            if (!topics.length) return [];
            let count = 0;
            const maxNoneSpikesToTake = Math.max(max - this.spikingSet.size, 0);
            return topics
                .filter(t => {
                    count++;
                    if (this.spikingSet.has(t.topic?.id ?? t.tag?.id)) return true;
                    return count < maxNoneSpikesToTake;
                });
        },

        showBarTooltip(event, tag) {
            tag ??= event.point.tag ?? event.point.topic;
            showTooltipComponent(event.currentTarget ?? event.e.currentTarget,
                MostFrequentTopicTooltip,
                {
                    tag: tag,
                    mentionCount: event?.point?.mentionCount,
                    filter: appendFiltersReadably(this.topicFilter, `tag is ${tag.id}`),
                    dailyAverage: event?.point?.average
                })
        },

        onBarClicked(event) {
            const tag = event.point.tag ?? event.point.topic;

            showMentions(
                appendFiltersReadably(this.topicFilter, `topic is ${tag.id}`),
                `Mentions for '${tag.name}' (by engagement)`,
                true
            )
        },

        renderTypical() {
            const dom = this.$refs.typicalChart;
            const topics = this.takeWithSpikes(20, Array.from(this.typicalTopics).sort((lhs, rhs) => rhs.average - lhs.average));

            if (dom && topics.length) {
                dom.classList.remove('trending-topics__chart--visible');
                this.typicalChart ??= new b3js.chart();

                this.typicalChart
                    .reset()
                    .geometry(b3js.barChart())
                    .scaleY(b3js.scaleDiscrete())
                    .data(topics.splice(0, 20))
                    .element(dom)
                    .x(d => d.average)
                    .y(d => d.topic.name)
                    .formatLabel(val => formatNumber(val, 1))
                    .width(dom.clientWidth)
                    .height(dom.clientHeight)
                    .showXAxis(false)
                    .showLegend(false)
                    .individualColours((d) => {
                        if (this.spikingSet.has(d?.topic?.id)) return "darkgreen";
                        return null;
                    })
                    .render();
                this.typicalChart.dispatch().on("tooltipShow", this.showBarTooltip);
                dom.classList.add('trending-topics__chart--visible');
            }
        },

        renderActual() {
            const dom = this.$refs.actualChart;
            const topics = this.takeWithSpikes(20, Array.from(this.actualTopics).sort((lhs, rhs) => rhs.average - lhs.average));

            if (dom && topics.length) {
                dom.classList.remove('trending-topics__chart--visible');
                this.actualChart ??= new b3js.chart();

                this.actualChart
                    .reset()
                    .geometry(b3js.barChart())
                    .scaleY(b3js.scaleDiscrete())
                    .data(topics.splice(0, 20))
                    .element(dom)
                    .x(d => d.average)
                    .y(d => d.tag.name)
                    .formatLabel(val => formatNumber(val, 1))
                    .width(dom.clientWidth)
                    .height(dom.clientHeight)
                    .showXAxis(false)
                    .showLegend(false)
                    .individualColours((d) => {
                        if (this.spikingSet.has(d?.tag?.id)) return "darkgreen";
                        return null;
                    })
                    .render();
                this.actualChart.dispatch().on("tooltipShow", this.showBarTooltip);
                this.actualChart.dispatch().on("elementClick", this.onBarClicked);
                dom.classList.add('trending-topics__chart--visible');
            }
        },

        /**
         * This clears the chart element, waits a few seconds, and th en redraws.
         * This is useful if you want to resize the chart.
         */
        async redrawCharts() {
            if (this.$refs.actualChart) this.$refs.actualChart.innerHTML = '';
            if (this.$refs.typicalChart) this.$refs.typicalChart.innerHTML = '';



            setTimeout(() => {
                    this.renderTypical();
                    this.renderActual();
                },
                250);
        },
    }
}

</script>

<style scoped lang="sass">

.trending-topics
    color: var(--be-colour-text-dark)

.trending-topics__loading
    flex: 1

.trending-topics__current
    width: max-content
    min-height: 500px
    margin-left: auto
    margin-right: auto

    display: flex
    flex-direction: row



.trending-topics__table
    width: 800px
    margin-right: 20px


.trending-topics__charts
    margin: 20px 20px
    --chart-margin: 10px
    --chart-width: calc(50% - 2 * var(--chart-margin))
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    > *
        width: var(--chart-width)
        box-sizing: border-box
        flex-basis: var(--chart-width)
        margin: var(--chart-margin)

    h4
        color: white

    p
        box-sizing: border-box
        margin: 10px 0      // Margins are outside of height specification
        --line-height: 1.5  // lineheight is best used unitless
        --number-of-lines: 3
        // Ensure that paragraphs have a nice, consistent space. Sub-grid would have
        // been great here, but lol, chrome. THANKS.
        line-height: var(--line-height)
        min-height: calc(var(--number-of-lines) * 1em * var(--line-height))


.trending-topics__chart
    width: 100%
    height: 500px
    box-sizing: border-box
    transition: filter 250ms
    opacity: 0

    &--visible
        animation: fadeIn 200ms both
        animation-delay: 100ms


.trending-topics-frequent
    margin-top: 10px
    display: block
    text-align: center

.trending-topics__trend-description
    display: flex
    flex-direction: row
    margin: 0
    padding: 0
    align-items: center
    color: var(--be-colour-muted-text-dark)
    font-size: 0.9em

.trending-topics__trend-block
    display: inline-block
    width: 1.1em
    height: 1.1em
    background: darkgreen
    padding: 0
    margin-right: 5px


.trending-topics--loading
    .trending-topics__chart
        pointer-events: none
        filter: grayscale(50%) blur(3px)



.trending-topics__typical
    &::v-deep svg g.group rect.bar
        cursor: default !important


.trending-topics__spiking-today
    width: max-content
    max-width: 50vw
    margin-inline: auto
    margin-block: 40px
    display: flex
    align-content: center

    .symbol-info
        font-size: 2.5rem
        line-height: 2.7rem
    p
        padding: 0
        margin: 0

    .be-tag
        margin-right: 5px
        margin-bottom: 5px
        cursor: pointer
        color: white

    .trending-topics__spiking-today-tags
        display: flex
        flex-wrap: wrap



</style>