<template>
    <div class="dashboard-panel">
        <section v-if="!hidden"
                 class="dashboard-panel__sidebar-frame"
                 @click="maybeCloseSidebar()">
        </section>
        <nav class="dashboard-panel__sidebar"
             @mouseleave="mouseLeaveEvent"
             :class="{'dashboard-panel__sidebar--hidden': hidden}">
            <sidebar-header class="dashboard-panel__title">
                <h4><old-tooltip label="These dashboards give an overview of your account">Overview</old-tooltip></h4>
                <old-tooltip label="Hide this side bar" keyword="esc" style="margin-left: auto; width: auto">
                    <a class="btn-three-bar"
                       tabindex="0"
                       @click="maybeCloseSidebar()">
                        <span class="three-bar"></span>
                        <span class="three-bar"></span>
                        <span class="three-bar"></span>
                    </a>
                </old-tooltip>
            </sidebar-header>
            <section class="dashboard-panel__dashboard-list">
                <div v-for="landing in landingDashboards"
                     :key="landing.id"
                     @click="selectDashboard(landing.id)"
                     class="dashboard-panel__list-item dashboard-panel__list-item--landing"
                     @mouseenter="showTooltip(landing, $event)"
                     :class="{'dashboard-panel__list-item--active': landing.id === selectedDashboardId, 'dashboard-panel__list-item--private': dashboardIsPrivate(landing)}">
                    {{landing.name}}
                    <section class="dashboard-panel__list-item__actions">
                        <span v-if="dashboardIsPrivate(landing)"><i class="symbol-lock"></i></span>
                    </section>
                </div>
            </section>

            <div v-if="hasPurchasedDashboards">
                <sidebar-header class="dashboard-panel__title" style="cursor: pointer" @click="purchasedExpanded = !purchasedExpanded">
                    <i style="padding-left: 10px" :class="{'icon-right-dir': !purchasedExpanded, 'icon-down-dir': purchasedExpanded}"></i>
                    <h4><old-tooltip label="These dashboards have been purchased from DataEQ">DataEQ Reporting</old-tooltip></h4>
                    <section class="dashboard-panel__actions">
                        <be-button v-if="user.admin"
                                   class="dashboard-panel__add-purchased-button"
                                   link
                                   @click="createDashboard(null, true)"
                                   tooltip="Create a new purchased dashboard">
                            <i class="icon-plus"></i>
                        </be-button>
                    </section>
                </sidebar-header>
                <section class="dashboard-panel__dashboard-list" v-if="purchasedExpanded">
                    <div class="dashboard-panel__purchased dark-scrollbars dark-scrollbars--visible">
                        <div v-for="category in purchasedDashboardCategories"
                             :key="category.name">
                            <dashboard-category :category="category" :can-edit-dashboards="user.admin"
                                                purchased-category
                                                @create="createDashboard(category.name, true)"
                                                @toggle-open="category.expanded = !category.expanded"
                                                @show-tooltip="showTooltip($event.dashboard, $event)"
                                                @update-category-name="updateCategoryName($event.category, $event.newName)"
                                                @archive-category="archiveCategory"
                                                @sort-category="sortCategory($event.category, $event.sortBy, $event.order)"
                                                @delete-category="deleteCategory"
                                                @edit-category-permissions="editCategoryPermissions(category)"
                                                :class="{'dashboard-panel__disabled': category.saving}">
                            </dashboard-category>
                            <section v-if="category.expanded">
                                <div v-for="d in category.dashboards"
                                     :key="d.id"
                                     :data-id="d.id"
                                     @click="selectDashboard(d.id)"
                                     @mouseenter="showTooltip(d, $event)"
                                     class="dashboard-panel__list-item dashboard-panel__list-item--indented"
                                     :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                                                'dashboard-panel__list-item--private': dashboardIsPrivate(d),
                                                'dashboard-panel__disabled': category.saving, 'dashboard-panel__highlight-list-item': d.id === highlightDashboardId}">
                                    <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                                    <dashboard-actions :dashboard="d"
                                                       @show-menu="showMenu($event.dashboard, $event)"
                                                       @show-tooltip="showTooltip($event.dashboard, $event)"/>
                                </div>
                            </section>
                        </div>
                        <div v-for="d in noCategoryPurchasedDashboards"
                             :key="d.id"
                             :data-id="d.id"
                             @click="selectDashboard(d.id)"
                             @mouseenter="showTooltip(d, $event)"
                             class="dashboard-panel__list-item"
                             :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                                        'dashboard-panel__list-item--private': dashboardIsPrivate(d),
                                        'dashboard-panel__highlight-list-item': d.id === highlightDashboardId}">
                            <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                            <dashboard-actions :dashboard="d"
                                               @show-menu="showMenu($event.dashboard, $event)"
                                               @show-tooltip="showTooltip($event.dashboard, $event)"/>
                        </div>
                    </div>
                </section>
            </div>

            <sidebar-header class="dashboard-panel__title dashboard-panel__custom">
                <section class="dashboard-panel__custom-header">
                    <h4>Custom dashboards</h4>
                    <section v-if="hasDashboards" class="dashboard-panel__actions">
                        <div v-if="selectMode" class="dashboard-panel__multi-select-actions">
                            {{ selectedDashboards.length }} selected
                            <be-button v-if="canEditDashboards" @click="deleteSelectedDashboards" link tooltip="Delete selected dashboards">
                                <i class="icon-trash"></i>
                            </be-button>
                        </div>
                        <search-input v-else v-model="searchTerm" class="dashboard-panel__search"/>


                        <be-button v-if="canEditDashboards" link @click="createDashboard()" tooltip="Create a new dashboard" keyword="a">
                            <i class="icon-plus"></i>
                        </be-button>
                        <popup-menu ref="customDashboardOptions"
                                    fixed
                                    :arrow-pointer="{show: true, left: true}"
                                    :pos-offset="{top:5, left:-8}">
                            <template #activator>
                                <be-button link tooltip="Options">
                                    <i class="symbol-menu"></i>
                                </be-button>
                            </template>
                            <div class="dashboard-panel__action-menu short-animated fadeIn">
                                <ul>
                                    <li class="dashboard-panel__action-menu-header">Show</li>
                                    <li>
                                        <label class="dashboard-panel__action-menu-label checkbox" @click.prevent="showArchived = !showArchived">
                                            <input type="checkbox" v-model="showArchived">Archived dashboards</label>
                                    </li>
                                    <li>
                                        <label class="dashboard-panel__action-menu-label checkbox" @click.prevent="showPinned = !showPinned">
                                            <input type="checkbox" v-model="showPinned">Pinned dashboards</label>
                                    </li>
                                    <li>
                                        <label class="dashboard-panel__action-menu-label checkbox" @click.prevent="showRecent = !showRecent">
                                            <input type="checkbox" v-model="showRecent">Recently used dashboards</label>
                                    </li>
                                    <li>
                                        <label class="dashboard-panel__action-menu-label checkbox" @click.prevent="showOwnedDashboards = !showOwnedDashboards">
                                            <input type="checkbox" v-model="showOwnedDashboards">Only your own dashboards</label>
                                    </li>
                                    <li class="dashboard-panel__action-menu-header">
                                        Sort By
                                    </li>
                                    <li v-for="sort in sortBy" :key="sort.name">
                                        <a @click="sortAllCustomDashboards(sort)"
                                           :style="activeSortBy.name === sort.name ? null : 'text-indent: 20px'">
                                            <i v-if="activeSortBy.name === sort.name" :class="sort.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"></i>
                                            {{ sort.label }}
                                        </a>
                                    </li>
                                    <li class="dashboard-panel__action-menu-header">
                                        Actions
                                    </li>
                                    <li>
                                        <a @click="toggleSelectMode">
                                            <i class="icon-checkbox"></i>{{ selectMode ? 'Disable' : 'Enable' }} select mode
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </popup-menu>
                    </section>
                </section>
                <!--section>
                    <be-button link>Sort by creation</be-button>
                </section-->
            </sidebar-header>

            <section v-if="hasDashboards" class="dashboard-panel__dashboard-list dashboard-panel__dashboard-list--fill dark-scrollbars dark-scrollbars--visible">
                <div v-if="showRecent && !searchTerm">
                    <div class="dashboard-panel__list-item"
                         @click="recentExpanded = !recentExpanded"
                         ref="recent"
                         :class="{'dashboard-panel__disabled' : disableRecent}">
                        <i :class="{'icon-right-dir': !recentExpanded, 'icon-down-dir': recentExpanded}"></i>
                        <i class="icon-back-in-time" style="padding-right: 5px"></i> Recently used
                    </div>
                    <section v-if="recentExpanded">
                        <div v-for="d in recentDashboards"
                             :key="d.id + '-recent'"
                             :data-id="d.id + '-recent'"
                             @click="selectDashboard(d.id)"
                             @mouseenter="showTooltip(d, $event)"
                             class="dashboard-panel__list-item dashboard-panel__list-item--indented"
                             :class="{'dashboard-panel__disabled' : disableRecent,
                             'dashboard-panel__list-item--private': dashboardIsPrivate(d)}">
                             <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">
                                 <i :class="{'symbol-pin': !d.category && d.pinned && !d.purchased}"></i>
                                 {{d.name}}
                                 <span v-if="d.category" class="dashboard-panel__list-item-divider">
                                     <i :class="{'icon-folder': d.category !== 'Archived' && (!d.pinned || d.purchased),
                                                 'symbol-archive' : d.category === 'Archived',
                                                 'symbol-pin': d.pinned && d.category !== 'Archived'}"/>
                                     {{d.category}}
                                 </span>
                                  <span v-if="d.purchased" class="dashboard-panel__list-item-divider">
                                      Purchased
                                 </span>
                            </a>
                            <dashboard-actions :dashboard="d"
                                               @show-menu="showMenu($event.dashboard, $event)"
                                               @show-tooltip="showTooltip($event.dashboard, $event)"/>
                        </div>
                        <div v-if="!recentDashboards.length && !searchTerm"
                             class="dashboard-panel__list-item dashboard-panel__list-item--indented">
                            <em>Dashboards that you have recently used will appear here</em>
                        </div>
                    </section>
                </div>

                <div v-if="showPinned && sortingByCategory">
                    <div v-for="pinnedCategory in pinnedCategories"
                         :key="pinnedCategory.name">
                        <dashboard-category :category="pinnedCategory" :can-edit-dashboards="user.admin"
                                            @create="createDashboard($event, false, true)"
                                            @toggle-open="pinnedCategory.expanded = !pinnedCategory.expanded"
                                            @show-tooltip="showTooltip($event.dashboard, $event)"
                                            @update-category-name="updateCategoryName($event.category, $event.newName)"
                                            @sort-category="sortCategory($event.category, $event.sortBy, $event.order)"
                                            @delete-category="deleteCategory"
                                            @archive-category="archiveCategory"
                                            @edit-category-permissions="editCategoryPermissions(pinnedCategory)"
                                            @update-category-pinned-state="updateCategoryPinnedState($event.category, $event.pinned)"
                                            :class="{'dashboard-panel__disabled': pinnedCategory.saving}">
                        </dashboard-category>
                        <section v-if="pinnedCategory.expanded">
                            <div v-for="d in pinnedCategory.dashboards"
                                 :key="d.id"
                                 :data-id="d.id"
                                 @click="selectDashboard(d.id)"
                                 @mouseenter="showTooltip(d, $event)"
                                 class="dashboard-panel__list-item dashboard-panel__list-item--indented"
                                 :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                             'dashboard-panel__list-item--private': dashboardIsPrivate(d),
                             'dashboard-panel__disabled': pinnedCategory.saving, 'dashboard-panel__highlight-list-item': d.id === highlightDashboardId}">
                                <input v-if="selectMode" class="dashboard-panel__select-checkbox" type="checkbox" :checked="isInSelectedDashboards(d.id)">
                                <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                                <dashboard-actions :dashboard="d"
                                                   @show-menu="showMenu($event.dashboard, $event)"
                                                   @show-tooltip="showTooltip($event.dashboard, $event)"/>
                            </div>
                        </section>
                    </div>
                    <div v-for="d in noCategoryPinnedDashboards"
                         :key="d.id"
                         :data-id="d.id"
                         @click="selectDashboard(d.id)"
                         @mouseenter="showTooltip(d, $event)"
                         class="dashboard-panel__list-item"
                         :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                         'dashboard-panel__list-item--private': dashboardIsPrivate(d),
                         'dashboard-panel__highlight-list-item': d.id === highlightDashboardId}">
                        <i class="symbol-pin" style="padding-right: 5px"></i>
                        <input v-if="selectMode" class="dashboard-panel__select-checkbox" type="checkbox" :checked="isInSelectedDashboards(d.id)">
                        <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                        <dashboard-actions :dashboard="d"
                                           @show-menu="showMenu($event.dashboard, $event)"
                                           @show-tooltip="showTooltip($event.dashboard, $event)"/>
                    </div>
                </div>
                <div v-if="showArchived">
                    <div class="dashboard-panel__list-item"
                         v-if="archivedDashboards.length || !searchTerm"
                         @click="archiveExpanded = !archiveExpanded"
                         ref="archive"
                         :class="{'dashboard-panel__disabled' : disableArchived}">
                        <i :class="{'icon-right-dir': !archiveExpanded, 'icon-down-dir': archiveExpanded}"></i>
                        <i class="symbol-archive" style="padding-right: 5px"></i> Archived
                    </div>
                    <section v-if="archiveExpanded">
                        <div v-for="d in archivedDashboards"
                             :key="d.id"
                             :data-id="d.id"
                             @click="selectDashboard(d.id)"
                             @mouseenter="showTooltip(d, $event)"
                             class="dashboard-panel__list-item dashboard-panel__list-item--indented"
                             :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                             'dashboard-panel__disabled' : disableArchived,
                             'dashboard-panel__list-item--private': dashboardIsPrivate(d)}">
                            <input v-if="selectMode" class="dashboard-panel__select-checkbox" type="checkbox" :checked="isInSelectedDashboards(d.id)">
                             <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                             <span v-if="d.purchased" class="dashboard-panel__list-item-divider">
                                 Purchased
                             </span>
                            <dashboard-actions :dashboard="d"
                                               @show-menu="showMenu($event.dashboard, $event)"
                                               @show-tooltip="showTooltip($event.dashboard, $event)"/>
                        </div>
                        <div v-if="!archivedDashboards.length && !searchTerm"
                             class="dashboard-panel__list-item dashboard-panel__list-item--indented">
                            <em>There are no archived dashboards in this account</em>
                        </div>
                    </section>
                </div>
                <div v-if="sortingByCategory">
                    <div v-for="category in categories"
                         :key="category.name">
                        <dashboard-category :category="category" :can-edit-dashboards="canEditDashboards"
                                            @create="createDashboard($event)"
                                            @toggle-open="category.expanded = !category.expanded"
                                            @show-tooltip="showTooltip($event.dashboard, $event)"
                                            @update-category-name="updateCategoryName($event.category, $event.newName)"
                                            @sort-category="sortCategory($event.category, $event.sortBy, $event.order)"
                                            @delete-category="deleteCategory"
                                            @archive-category="archiveCategory"
                                            @edit-category-permissions="editCategoryPermissions(category)"
                                            @update-category-pinned-state="updateCategoryPinnedState($event.category, $event.pinned)"
                                            :class="{'dashboard-panel__disabled': category.saving}">
                        </dashboard-category>
                        <section v-if="category.expanded">
                            <div v-for="d in category.dashboards"
                                 :key="d.id"
                                 :data-id="d.id"
                                 @click="selectDashboard(d.id)"
                                 @mouseenter="showTooltip(d, $event)"
                                 class="dashboard-panel__list-item dashboard-panel__list-item--indented"
                                 :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                             'dashboard-panel__list-item--private': dashboardIsPrivate(d),
                             'dashboard-panel__disabled': category.saving, 'dashboard-panel__highlight-list-item': d.id === highlightDashboardId}">
                                <input v-if="selectMode" class="dashboard-panel__select-checkbox" type="checkbox" :checked="isInSelectedDashboards(d.id)">
                                <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                                <dashboard-actions :dashboard="d"
                                                   @show-menu="showMenu($event.dashboard, $event)"
                                                   @show-tooltip="showTooltip($event.dashboard, $event)"/>
                            </div>
                        </section>
                    </div>
                    <div v-for="d in noCategoryDashboards"
                         :key="d.id"
                         :data-id="d.id"
                         @click="selectDashboard(d.id)"
                         @mouseenter="showTooltip(d, $event)"
                         class="dashboard-panel__list-item"
                         :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                         'dashboard-panel__list-item--private': dashboardIsPrivate(d),
                         'dashboard-panel__highlight-list-item': d.id === highlightDashboardId}">
                        <input v-if="selectMode" class="dashboard-panel__select-checkbox" type="checkbox" :checked="isInSelectedDashboards(d.id)">
                        <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                        <dashboard-actions :dashboard="d"
                                           @show-menu="showMenu($event.dashboard, $event)"
                                           @show-tooltip="showTooltip($event.dashboard, $event)"/>
                    </div>
                    <section v-if="!noCategoryDashboards.length && !archivedDashboards.length && !pinnedCategories.length && !noCategoryPinnedDashboards.length && !categories.length && searchTerm"
                             class="dashboard-panel__message">
                        No matching dashboards could be found
                    </section>
                </div>
                <div v-else>
                    <div v-for="d in sortedDashboards"
                         :key="d.id"
                         :data-id="d.id"
                         @click="selectDashboard(d.id)"
                         @mouseenter="showTooltip(d, $event)"
                         class="dashboard-panel__list-item"
                         :class="{'dashboard-panel__list-item--active': d.id === selectedDashboardId,
                         'dashboard-panel__list-item--private': dashboardIsPrivate(d),
                         'dashboard-panel__highlight-list-item': d.id === highlightDashboardId}">
                        <span>
                            <i v-if="!d.category && d.pinned" class="symbol-pin" style="padding-right: 5px"></i>
                             <input v-if="selectMode" class="dashboard-panel__select-checkbox" type="checkbox" :checked="isInSelectedDashboards(d.id)">
                             <a :href="`/${account.code}/dashboards/${d.id}`" @click.stop.prevent="selectDashboard(d.id)">{{d.name}}</a>
                            <span v-if="d.category" class="dashboard-panel__list-item-divider">
                                <i :class="d.pinned ? 'symbol-pin' : 'icon-folder'"></i>
                                {{d.category}}
                             </span>
                        </span>
                        <dashboard-actions :dashboard="d"
                                           @show-menu="showMenu($event.dashboard, $event)"
                                           @show-tooltip="showTooltip($event.dashboard, $event)"/>
                    </div>
                    <section v-if="!noCategoryDashboards.length && !archivedDashboards.length && !pinnedCategories.length && !noCategoryPinnedDashboards.length && !categories.length && searchTerm"
                             class="dashboard-panel__message">
                        No matching dashboards could be found
                    </section>
                </div>
            </section>
            <dotted-card v-if="canEditDashboards && !hasDashboards && !loadingDashboards"
                         class="dashboard-panel__new-dashboard-message"
                         @click="createDashboard()">
                <h4>
                    Add dashboard
                    <br>
                    <i class="icon-plus" title="Create a new dashboard"></i>
                </h4>
                <p>
                    Custom dashboards are where you can create reports and run analytics
                    on your mentions and conversations.
                </p>
                <p>
                    Click here to add your first dashboard.
                </p>
            </dotted-card>
            <loading-message v-if="loadingDashboards" :message="'Fetching your dashboards'"/>
        </nav>


        <loading-message v-if="loadingCurrentDashboard"
                         message="Fetching your dashboards…"/>
        <section ref="view"></section>

        <dashboard-contents :dashboard="currentDashboard"
                            @section-selected="sectionSelected($event)"
                            @section-moved="sectionMoved($event)"
        />

    </div>
</template>

<script>
import moment from "moment";
import Vue from 'vue';
import {features} from "@/app/Features";
import LoadingMessage from "@/components/LoadingMessage";
import SearchInput from "@/components/inputs/SearchInput";
import {
    notifyUser,
    notifyWithHtml,
    notifyWithText,
    showBusyNotification
} from "@/app/framework/notifications/Notifications";
import BeButton from "@/components/buttons/BeButton";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {showAskDialog, showErrorDialog, showInfoDialog} from "@/app/framework/dialogs/Dialog";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import DashboardTooltip from "@/app/toplevel/dashboards/DashboardTooltip";
import EditDashboardSecurityDialog from "@/app/toplevel/dashboards/dialogs/EditDashboardSecurityDialog";
import DashboardContents from "@/app/toplevel/dashboards/DashboardContents";
import {
    dashboardIsEditable,
    dashboardIsPrivate,
    getAnchorSectionId,
    getCampaignFromLink,
    isCampaignDashboardLink,
    updateOldDashboard
} from "@/dashboards/DashboardUtils";
import DottedCard from "@/components/cards/DottedCard";
import {showTip} from "@/app/help/tips/tips";
import {editDashboards} from "@/app/Permissions";
import DashboardCategory from "@/app/toplevel/dashboards/DashboardCategory";
import './dashboard-panel.sass';
import DashboardActions from "@/app/toplevel/dashboards/DashboardActions";
import {setLastSeenDashboard} from "@/app/toplevel/dashboards/DashboardUtilities";
import PopupMenu from "@/components/PopupMenu";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";
import {escapeExpression, escapeHtml} from "@/app/utils/StringUtils";
import {areAnyPopupsVisible, showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {logPageUsed} from "@/app/utils/UserAccessLog";
import {addSidebarHoverFlag, removeSidebarHoverFlag} from "@/components/sidebar/SidebarUtilities";
import {formatPlural} from "@/app/utils/Format";

export default {
        components: {
            OldTooltip,
            PopupMenu,
            DashboardActions,
            DashboardCategory,
            DottedCard, DashboardContents, SidebarHeader, BeButton, LoadingMessage, SearchInput
        },
        store: VuexStore,

        props: {
            id: {
                type: [Number, String]
            }
        },

        data() {
            return {
                features,
                isDestroyed: false,
                loadingDashboards: true,
                loadingCurrentDashboard: false,
                creatingDashboard: false,
                hidden: true,
                hideOnMouseOut: false,
                selectedDashboardId: null,
                view: null,
                showArchived: false,
                showOwnedDashboards: false,
                archiveExpanded: true,
                searchTerm: null,
                searchTimeout: null,
                canEditDashboards: editDashboards(),
                currentDashboard: null,
                currentOpenMenu: null,
                disableArchived: false,
                showRecent: true,
                recentExpanded: false,
                disableRecent: false,
                recentDashboardIds: [],
                highlightDashboardId: null,
                purchasedExpanded: true,
                handleCampaignSectionNav: true,
                showPinned: true,
                pinnedExpanded: false,

                // Custom dashboards sorting
                sortingByCategory: true, // if we are sorting by category, we want to show collapsable folders
                activeSortBy: {
                    name: "category",
                    label: "Folder (default)",
                    order: "ASCENDING"
                },
                sortBy: [
                    {
                        name: "category",
                        label: "Folder (default)",
                        order: "ASCENDING"
                    },
                    {
                        name: "name",
                        label: "Name",
                        order: "ASCENDING"
                    },
                    {
                        name: "created",
                        label: "Date created",
                        order: "ASCENDING"
                    },
                    {
                        name: "lastUpdated",
                        label: "Last updated",
                        order: "ASCENDING"
                    }
                ],
                selectMode: false,
                selectedDashboards: []
            }
        },

        watch: {
            selectedDashboardId(id, oldId) {
                if (id) setLastSeenDashboard(id);
                // Relock a dashboard once it is no longer used.
                if (id && oldId && oldId !== id) {
                    if (this.idToDashboard?.has(oldId)) {
                        delete this.idToDashboard.get(oldId)._unlocked;
                    }
                }
                this.loadDashboard(id);
            },
            async hidden(val) {
                // If the user opens the sidebar, don't clear the search while they're looking at it.
                if (this.searchTimeout) clearTimeout(this.searchTimeout);

                if (val && this.searchTerm) {
                    if (this.searchTimeout) {
                        clearTimeout(this.searchTimeout);
                    }
                    // When the sidebar is hidden, clear the search after a few seconds.
                    this.searchTimeout = setTimeout(() => {
                        if (this.hidden) {
                            this.searchTerm = null;
                            this.searchTimeout = null;
                        }
                    }, 30000)
                }

                // Ensure that the selected dashboard is in view.
                if (!val && this.selectedDashboardId) {
                    this.scrollSelectedDashboardIntoView();
                }

                if (!val) {     // things to do when the sidebar is opened.
                    await showTip("CREATE_DASHBOARD_SHORTCUT", "DASHBOARD_SHOW_ARCHIVE", "DASHBOARD_SHOW_DASHBOARDS");
                    addSidebarHoverFlag("400px");
                } else {        // Things to do when the sidebar is closed.
                    this.$refs.customDashboardOptions?.close();
                    if (this.currentOpenMenu) {
                        this.currentOpenMenu.close();
                        this.currentOpenMenu = null;
                    }
                    removeSidebarHoverFlag();
                }
            },
        },

        computed: {
            ...mapState(['user', 'account']),

            ...mapState('dashboards', [
                'dashboards', 'tok', 'asEmail'
            ]),

            ...mapGetters('dashboards', ['landingPages', 'idToDashboard']),

            hasDashboards() {
                return !!this.dashboards?.find(d => d.type !== "LANDING_PAGE");
            },
            hasPurchasedDashboards() {
                return !!this.dashboards?.find(d => d.type !== "LANDING_PAGE" && d.category !== "Archived" && d.purchased);
            },
            landingDashboards() {
                let landingPages = this.landingPages;

                return landingPages?.sort((lhs, rhs) => {
                    return (lhs.ordinal ?? 0) - (rhs.ordinal ?? 0);
                })
            },
            purchasedDashboards() {
                return this.dashboards?.filter(d => d.type === "NORMAL" && d.category !== "Archived" && d.purchased);
            },
            purchasedDashboardCategories() {
                const cats = new Set();
                const dashboards = {};
                const catExpanded = {};
                this.purchasedDashboards.forEach(d => {
                    if (d.category) {
                        cats.add(d.category);
                        dashboards[d.category] = dashboards[d.category] ?? [];
                        dashboards[d.category].push(d);
                        if (d.id && d.id === this.selectedDashboardId) {
                            catExpanded[d.category] = true;
                        }
                    }
                })

                return [...cats]
                    .sort((lhs, rhs) => lhs.localeCompare(rhs))
                    .map(name => {
                        return Vue.observable({
                            name,
                            saving: false,
                            purchasedCategory: true,
                            expanded: !!catExpanded[name],
                            dashboards: dashboards[name]
                        })
                    })
            },
            noCategoryPurchasedDashboards() {
                return this.dashboards?.filter(d => d.type === "NORMAL" && !d.category && d.purchased);
            },
            sortedDashboards() {
                let dashboards = [];

                // when sorted by category, a different list is used
                if (this.activeSortBy.name !== "category") {
                    dashboards = this.dashboards?.filter(d => d.type === "NORMAL" && d.category !== "Archived" && (!this.showPinned ? !d.pinned : true)
                        && this.isVisible(d) && this.showDashboardConditional(d));

                    this.sort(dashboards, this.activeSortBy.name, this.activeSortBy.order);
                }

                return dashboards;
            },
            recentDashboards() {
                let recentDashboards = [];

                this.recentDashboardIds.forEach(cachedRecentDashboardId => {
                   let recentDashboard = this.dashboards?.find(dashboard => dashboard.id === cachedRecentDashboardId);

                   if (recentDashboard) recentDashboards.push(recentDashboard);
                });

                return recentDashboards;
            },
            regularDashboards() {
                return this.dashboards?.filter(d => d.type === "NORMAL" && d.category !== "Archived" && !d.pinned && this.isVisible(d) && this.showDashboardConditional(d))
            },
            noCategoryDashboards() {
                return this.dashboards?.filter(d => d.type === "NORMAL" && !d.category && !d.pinned && this.isVisible(d) && this.showDashboardConditional(d));
            },
            archivedDashboards() {
                if (!this.showArchived) return [];
                return this.dashboards?.filter(d => d.type === "NORMAL" && d.category === 'Archived' && this.isVisible(d));
            },
            pinnedDashboards() {
                if (!this.showPinned) return [];
                return this.dashboards?.filter(d => d.type === "NORMAL" && d.category !== "Archived" && d.pinned && this.isVisible(d) && this.showDashboardConditional(d));
            },
            pinnedCategories() {
                const cats = new Set();
                const dashboards = {};
                const catExpanded = {};
                for (const d of this.pinnedDashboards) {
                    if (d.category) {
                        cats.add(d.category);
                        dashboards[d.category] = dashboards[d.category] ?? [];
                        dashboards[d.category].push(d);
                        if (d.id && d.id === this.selectedDashboardId) {
                            catExpanded[d.category] = true;
                        }
                    }
                }

                return [...cats]
                    .sort((lhs, rhs) => lhs.localeCompare(rhs))
                    .map(name => {
                        return Vue.observable({
                            name,
                            saving: false,
                            pinned: true,
                            expanded: !!catExpanded[name] || !!this.searchTerm,
                            dashboards: dashboards[name]
                        })
                    });
            },
            noCategoryPinnedDashboards() {
                return this.pinnedDashboards?.filter(d => !d.category);
            },
            categories() {
                const cats = new Set();
                const dashboards = {};
                const catExpanded = {};

                for (const d of this.regularDashboards) {
                    if (d.category) {
                        cats.add(d.category);
                        dashboards[d.category] = dashboards[d.category] ?? [];
                        dashboards[d.category].push(d);
                        if (d.id && d.id === this.selectedDashboardId) {
                            catExpanded[d.category] = true;
                        }
                    }
                }

                return [...cats]
                    .sort((lhs, rhs) => lhs.localeCompare(rhs))
                    .map(name => {
                        return Vue.observable({
                            name,
                            saving: false,
                            expanded: !!catExpanded[name] || !!this.searchTerm,
                            dashboards: dashboards[name]
                        })
                    })

            }
        },

        async created() {
            try {
                // We want things to be quickly accessible, so use cache if present.
                // We force a total reload later.
                const shouldForceRefresh = !!this.dashboards?.length;
                await this.refreshDashboards(false);

                if (this.dashboards?.length) {
                    for (const d of this.dashboards) {
                        d._unlocked = false; // Ensure that everything is locked in case they were previously unlocked.
                    }
                }

                this.loadingDashboards = false;

                if (shouldForceRefresh && !this.isDestroyed) {
                    await this.refreshDashboards(true);
                }

                if (!this.dashboards?.length) this.hidden = false;

            } catch (e) {
                console.error(e);
            } finally {
                this.loadingDashboards = false;
            }
        },

        async mounted() {
            logPageUsed("dashboards");

            // We want to be able to hide the sidebar when the user clicks escape.
            document.addEventListener('keyup', this.keyPressHandler, {capture: true, passive: true});

            // load recent dashboards
            let cachedRecentDashboards = localStorage.getItem(`safe-cache:user:${this.user.id}:account:${this.account.code}:recent-dashboards`);
            this.recentDashboardIds = cachedRecentDashboards ? JSON.parse(cachedRecentDashboards) : [];

            if (this.id && isFinite(this.id)) { // Ensure this is an int-like item
                this.selectedDashboardId = parseInt(this.id); // Sometimes we are passed a string.
            } else {
                await this.refreshDashboards(false);
                if (this.landingDashboards?.length) {
                    this.selectedDashboardId = this.landingDashboards[0].id;
                } else if (this.dashboards?.length) {
                    this.selectedDashboardId = this.dashboards[0].id;
                }
            }

            await showTip("DASHBOARD_WELCOME"); // Show this first
            await showTip("DASHBOARD_SEE_RECENT", "DASHBOARD_NAVIGATE");
            await showTip("OPS", "READING_THIS", "SARAH_CONNOR", "REAL_EYES");
        },

        beforeDestroy() {
            this.isDestroyed = true;
            window.removeEventListener('keyup', this.keyPressHandler, true);
            if (this.view) {
                this.view.close();
                this.view = null;
            }
        },

        methods: {
            ...mapActions('dashboards', ['refreshDashboards', 'getFullDashboard',
                 'updateDashboard', 'updateDashboardCategory',
                'deleteDashboardCategory', 'restoreDashboardCategory', 'updateDashboardCategoryPinnedState', 'multiDeleteDashboards', 'restoreDeletedDashboards']),

            ...mapActions('dashboards', {createDashboardVuex: 'createDashboard', deleteDashboardVuex: 'deleteDashboard'}),

            dashboardIsEditable,
            dashboardIsPrivate,
            isVisible(d) {
                if (!this.searchTerm) return true;

                const term = this.searchTerm.toLowerCase();
                const name = d.name?.toLowerCase() ?? '';
                return name.includes(term);
            },

            showTooltip(dashboard, event) {
                showTooltipComponent(event.target, DashboardTooltip, {dashboard: dashboard}, {positions: ['right', 'top-left']})
            },

            mouseLeaveEvent() {
                if (this.hideOnMouseOut) {
                    this.maybeCloseSidebar();
                    this.hideOnMouseOut = false;
                }
            },

            scrollSelectedDashboardIntoView() {
                this.$nextTick(() => {
                    const item = document.querySelector(`.dashboard-panel__list-item[data-id='${this.selectedDashboardId}']`);
                    item?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                })
            },

            getCategory(name, pinned) {
                pinned ??= false;
                let list = pinned ? this.pinnedCategories : this.categories;
                let category = null;

                for (let i = 0; i < list?.length; i++) {
                    let cat = list[i];
                    if (cat.name === name) {
                        category = cat;
                        break;
                    }
                }

                return category;
            },

            updateSelectedDashboardsList(dashboardId) {
                let dashboardIndex = this.selectedDashboards.indexOf(dashboardId);

                if (dashboardIndex > -1) {
                    this.selectedDashboards.splice(dashboardIndex, 1);
                } else {
                    this.selectedDashboards.push(dashboardId);
                }
            },

            isInSelectedDashboards(dashboardId) {
                return this.selectedDashboards.indexOf(dashboardId) > -1;
            },

            toggleSelectMode() {
                this.selectedDashboards = [];
                this.selectMode = !this.selectMode;
            },

            async deleteSelectedDashboards() {
                if (this.selectedDashboards.length === 0) {
                    await showInfoDialog("You must select at least one dashboard to delete.", "No dashboards selected");
                    return;
                }

                const proceed = await showAskDialog(`Delete ${this.selectedDashboards.length} ${formatPlural(this.selectedDashboards.length, "dashboard")}?`,
                    `Are you sure you want to delete ${formatPlural(this.selectedDashboards.length,
                        "the selected dashboard", `the selected ${this.selectedDashboards.length} dashboards`)}?`, true);
                if (!proceed) return;

                let busy = showBusyNotification(`Deleting ${this.selectedDashboards.length} ${formatPlural(this.selectedDashboards.length, "dashboard")}...`, null, null, true);
                try {
                    let oldSelectedDashboardId = this.selectedDashboardId;
                    let selectedDashboardsIdsCopy = this.selectedDashboards; // used for "undo" option

                    // remove from recent dashboard ID's if required
                    this.selectedDashboards.forEach(id => {
                        let index = this.recentDashboardIds.indexOf(id);
                        if (index > -1) {
                            this.recentDashboardIds.splice(index, 1);
                        }
                    });
                    localStorage.setItem(`safe-cache:user:${this.user.id}:account:${this.account.code}:recent-dashboards`, JSON.stringify(this.recentDashboardIds));

                    await this.multiDeleteDashboards(this.selectedDashboards);

                    if (this.selectedDashboards?.find(id => id === this.selectedDashboardId)) {
                        this.selectedDashboardId = null;
                    }

                    notifyUser({
                        message: `${this.selectedDashboards.length} ${formatPlural(this.selectedDashboards.length, "dashboard has", "dashboards have")} <strong> been deleted</strong>.`,
                        isEscapedHtml: true,
                        icon: "<i class='symbol-reports'></i>",
                        longDelay: true,
                        undo: async () => {
                            try {
                                busy = showBusyNotification(`Restoring ${selectedDashboardsIdsCopy.length} ${formatPlural(selectedDashboardsIdsCopy.length, "dashboard")}...`, null, null, true);

                                await this.restoreDeletedDashboards(selectedDashboardsIdsCopy);

                                this.selectedDashboardId = oldSelectedDashboardId;

                                await this.refreshDashboards(true);

                                notifyWithText(`${selectedDashboardsIdsCopy.length} ${formatPlural(selectedDashboardsIdsCopy.length, "dashboard has", "dashboards have")}
                                 been undeleted.`, null, "<i class='symbol-reports'></i>");
                            } catch (e) {
                                console.error("An error occurred while restoring deleted dashboards: ", e);
                                showErrorDialog("We were unable to undelete your dashboards.");
                            } finally {
                                busy.close();
                            }
                        }
                    });
                } catch (e) {
                    console.error("An error occurred while deleting selected dashboards: ", e);
                    let errorMessage = "There was an error deleting your selected dashboards";
                    if (e.response?.status === 403) {
                        errorMessage += ": there are dashboards in this selection that you are not able to edit."
                    }
                    showErrorDialog(errorMessage);
                } finally {
                    this.selectedDashboards = [];
                    this.selectMode = false;
                    busy.close();
                }
            },

            editCategoryPermissions(category) {
                const dialog = showDialog(EditDashboardSecurityDialog, { category: { name: category.name, dashboards: category.dashboards }});

                dialog.$on('disable-category', categoryName => {
                    let cat = this.getCategory(categoryName, !!category.pinned);
                    if (category) {
                        this.disableRecent = true;
                        cat.saving = true;
                    }
                });

                dialog.$on('enable-category', async categoryName => {
                    let cat = this.getCategory(categoryName, !!category.pinned);

                    // reload the current dashboard if necessary
                    let reloadCurDashboard = !!this.dashboards?.find(d => d.id === this.selectedDashboardId && d.category === categoryName);
                    if (reloadCurDashboard) await this.loadDashboard(this.selectedDashboardId, false);

                    if (cat) {
                        this.disableRecent = false;
                        cat.saving = false;
                    }
                });
            },

            sort(array, field, order) {
                if (order === "ASCENDING") {
                    field === "created" || field === "lastUpdated" ? array.sort((a, b) => a[field].replace(/^ +/gm, '').toLowerCase() < b[field].replace(/^ +/gm, '').toLowerCase() ? 1 : -1)
                        : array.sort((a, b) => a[field].replace(/^ +/gm, '').toLowerCase() > b[field].replace(/^ +/gm, '').toLowerCase() ? 1 : -1)
                } else {
                    field === "created" || field === "lastUpdated" ? array.sort((a, b) => a[field].replace(/^ +/gm, '').toLowerCase() > b[field].replace(/^ +/gm, '').toLowerCase() ? 1 : -1)
                        : array.sort((a, b) => a[field].replace(/^ +/gm, '').toLowerCase() < b[field].replace(/^ +/gm, '').toLowerCase() ? 1 : -1)
                }
            },

            showDashboardConditional(dashboard) {
                let ownerDashboardConditional = this.showOwnedDashboards ? dashboard.createdBy?.id === this.user.id : true;
                let dashboardPurchased = dashboard.purchased;

                return ownerDashboardConditional && !dashboardPurchased;
            },

            sortAllCustomDashboards(sortBy) {
                this.sortingByCategory = sortBy.name === "category";

                sortBy.order = this.activeSortBy.name === sortBy.name ? (sortBy.order === "ASCENDING" ? "DESCENDING" : "ASCENDING") : "ASCENDING";

                this.activeSortBy = sortBy;

                // when not sorting by category field, the sort is handled by the "sortedDashboards" computed variable
                if (sortBy.name === "category") {
                    this.sort(this.categories, "name", this.activeSortBy.order);
                }
            },

            sortCategory(category, sortBy, order) {
                if (category) {
                    this.sort(category.dashboards, sortBy, order);
                }
            },

            async updateCategoryName(category, newName) {
                let busy = showBusyNotification("Updating folder name...", null, null, true);
                try {
                    this.disableRecent = true;
                    category.saving = true;

                    let oldFolderDashboards = Object.assign([], category.dashboards);
                    let reloadCurDashboard = !!category.dashboards.find(d => d.id === this.selectedDashboardId);

                    let dashboardIds = [];
                    for (let i = 0 ; i < oldFolderDashboards.length; i++) {
                        dashboardIds.push(oldFolderDashboards[i].id);
                    }

                    await this.updateDashboardCategory({oldCategory: category.name, newCategory: newName, dashboardIds: dashboardIds});

                    if (reloadCurDashboard) await this.loadDashboard(this.selectedDashboardId, false);

                    notifyUser({
                        message: `Folder ${escapeHtml(category.name)} <strong>has been changed to </strong> ${escapeExpression(newName)}.`,
                        isEscapedHtml: true,
                        icon: "<i class='symbol-reports'></i>",
                        longDelay: true,
                        undo: async () => {
                            // we need to find the updated category with the new name in order to set the .saving variable on the correct object
                            let updatedCategory = null;
                            try {
                                updatedCategory = this.getCategory(newName, !!category.pinned);

                                busy = showBusyNotification("Undoing folder name change...", null, null, true);

                                if (updatedCategory) {
                                    this.disableRecent = true;
                                    updatedCategory.saving = true;
                                }

                                await this.updateDashboardCategory({oldCategory: newName, newCategory: category.name, dashboardIds: dashboardIds});

                                if (reloadCurDashboard) await this.loadDashboard(this.selectedDashboardId, false);

                                notifyWithText(`Your folder name update has been undone.`, null, "<i class='symbol-reports'></i>");
                            } catch (e) {
                                console.error("An error occurred while undoing the name change of this folder: ", e);
                                showErrorDialog("We were unable to undo your folder name change.");
                            } finally {
                                if (updatedCategory) {
                                    this.disableRecent = false;
                                    updatedCategory.saving = false;
                                }
                                busy.close();
                            }
                        }
                    });
                } catch (e) {
                    console.error("An error occurred while changing the name change of this folder: ", e);
                    let errorMessage = "There was an error changing your folder's name";
                    if (e.response?.status === 403) {
                        errorMessage += ": there are dashboards in this folder that you are not able to edit."
                    }
                    showErrorDialog(errorMessage);
                } finally {
                    this.disableRecent = false;
                    category.saving = false;
                    busy.close();
                }
            },

            async updateCategoryPinnedState(category, pinned) {
                let busy = showBusyNotification(pinned ? "Pinning folder..." : "Unpinning folder...", null, null, true);
                try {
                    this.disableRecent = true;
                    category.saving = true;

                    let oldFolderDashboards = Object.assign([], category.dashboards);
                    let reloadCurDashboard = !!category.dashboards.find(d => d.id === this.selectedDashboardId);

                    let dashboardIds = [];
                    for (let i = 0 ; i < oldFolderDashboards.length; i++) {
                        dashboardIds.push(oldFolderDashboards[i].id);
                    }

                    await this.updateDashboardCategoryPinnedState({category: category.name, pinned: pinned, dashboardIds: dashboardIds});

                    if (reloadCurDashboard) await this.loadDashboard(this.selectedDashboardId, false);


                    notifyUser({
                        message: `Folder ${escapeExpression(category.name)} has been <strong>${pinned ? `pinned` : `unpinned` }</strong>`,
                        isEscapedHtml: true,
                        icon: "<i class='symbol-pin'></i>",
                        longDelay: true,
                        undo: async () => {
                            let updatedCategory = null;
                            try {
                                updatedCategory = this.getCategory(category, pinned);

                                busy = showBusyNotification(pinned ? "Unpinning folder..." : "Re-pinning folder...", null, null, true);

                                if (updatedCategory) {
                                    this.disableRecent = true;
                                    updatedCategory.saving = true;
                                }

                                await this.updateDashboardCategoryPinnedState({category: category.name, pinned: !pinned, dashboardIds: dashboardIds});

                                if (reloadCurDashboard) await this.loadDashboard(this.selectedDashboardId, false);

                                notifyWithText(pinned ? "Your folder has been unpinned" : "Your folder has been re-pinned", null, "<i class='symbol-pin'></i>");
                            } catch (e) {
                                console.error("An error occurred while undoing the pinned state of this folder: ", e);
                                showErrorDialog(pinned ? "We were unable to unpin your folder" : "We were unable to re-pin your folder");
                            } finally {
                                if (updatedCategory) {
                                    this.disableRecent = false;
                                    updatedCategory.saving = false;
                                }
                                busy.close();
                            }
                        }
                    });
                } catch (e) {
                    console.error("An error occurred while changing the pinned state of this folder: ", e);
                    let errorMessage = pinned ? "There was an error pinning your folder" : "There was an error unpinning your folder"
                    if (e.response?.status === 403) {
                        errorMessage += ": there are dashboards in this folder that you are not able to edit."
                    }
                    showErrorDialog(errorMessage);
                } finally {
                    this.disableRecent = false;
                    category.saving = false;
                    busy.close();
                }
            },

            async deleteCategory(category) {
                let busy = showBusyNotification("Deleting folder...", null, null, true);
                try {
                    this.disableRecent = true;
                    category.saving = true;

                    let oldSelectedDashboardId = JSON.parse(JSON.stringify(this.selectedDashboardId));
                    let oldFolderDashboards = Object.assign([], category.dashboards);

                    // remove from recent dashboard ID's if required
                    category.dashboards.forEach(d => {
                        let index = this.recentDashboardIds.indexOf(d.id);
                        if (index > -1) {
                            this.recentDashboardIds.splice(index, 1);
                        }
                    });

                    let dashboardIds = [];
                    for (let i = 0 ; i < oldFolderDashboards.length; i++) {
                        dashboardIds.push(oldFolderDashboards[i].id);
                    }

                    localStorage.setItem(`safe-cache:user:${this.user.id}:account:${this.account.code}:recent-dashboards`, JSON.stringify(this.recentDashboardIds));

                    await this.deleteDashboardCategory({category: category.name, dashboardIds: dashboardIds});

                    if (category.dashboards?.find(d => d.id === this.selectedDashboardId && d.category === category.name)) {
                        this.selectedDashboardId = null;
                    }

                    notifyUser({
                        message: `Folder ${escapeExpression(category.name)} <strong>has been deleted</strong>.`,
                        isEscapedHtml: true,
                        icon: "<i class='symbol-reports'></i>",
                        longDelay: true,
                        undo: async () => {
                            try {
                                busy = showBusyNotification("Restoring folder...", null, null, true);

                                await this.restoreDashboardCategory({category: category.name, dashboardIds: dashboardIds});

                                this.selectedDashboardId = oldSelectedDashboardId;

                                await this.refreshDashboards(true);

                                notifyWithText(`Your folder has been undeleted.`, null, "<i class='symbol-reports'></i>");
                            } catch (e) {
                                console.error("An error occurred while restoring this folder: ", e);
                                showErrorDialog("We were unable to undelete your folder");
                            } finally {
                                busy.close();
                            }
                        }
                    });
                } catch (e) {
                    console.error("An error occurred while deleting this folder: ", e);
                    let errorMessage = "There was an error deleting your folder";
                    if (e.response?.status === 403) {
                        errorMessage += ": there are dashboards in this folder that you are not able to edit."
                    }
                    showErrorDialog(errorMessage);
                } finally {
                    this.disableRecent = false;
                    category.saving = false;
                    busy.close();
                }
            },

            async archiveCategory(category) {
                let busy = showBusyNotification("Archiving folder...", null, null, true);
                try {
                    this.disableRecent = true;
                    category.saving = true;

                    let oldFolderDashboards = Object.assign([], category.dashboards);
                    let reloadCurDashboard = !!category.dashboards.find(d => d.id === this.selectedDashboardId && d.category === category.name);

                    let dashboardIds = [];

                    for (let i = 0 ; i < oldFolderDashboards.length; i++) {
                        dashboardIds.push(oldFolderDashboards[i].id);
                    }

                    await this.updateDashboardCategory({oldCategory: category.name, newCategory: "Archived", dashboardIds: dashboardIds});

                    if (reloadCurDashboard) await this.loadDashboard(this.selectedDashboardId, false);

                    notifyUser({
                        message: `Folder ${escapeExpression(category.name)} has been <strong>archived</strong>.`,
                        isEscapedHtml: true,
                        icon: "<i class='symbol-reports'></i>",
                        longDelay: true,
                        undo: async () => {
                            try {
                                this.disableRecent = true;
                                this.disableArchived = true;

                                busy = showBusyNotification("Undoing folder archive...", null, null, true);

                                await this.updateDashboardCategory({oldCategory: "Archived", newCategory: category.name, dashboardIds: dashboardIds});

                                if (reloadCurDashboard) await this.loadDashboard(this.selectedDashboardId, false);

                                notifyWithText(`Your folder archive has been undone.`, null, "<i class='symbol-reports'></i>");
                            } catch (e) {
                                console.error("An error occurred while undoing the folder archive: ", e);
                                showErrorDialog("We were unable to undo your folder archive.");
                            } finally {
                                this.disableRecent = false;
                                this.disableArchived = false;
                                busy.close();
                            }
                        }
                    });
                } catch (e) {
                    console.error("An error occurred while archiving this folder: ", e);
                    let errorMessage = "There was an error archiving your folder";
                    if (e.response?.status === 403) {
                        errorMessage += ": there are dashboards in this folder that you are not able to edit."
                    }
                    showErrorDialog(errorMessage);
                } finally {
                    this.disableRecent = false;
                    category.saving = false;
                    busy.close();
                }
            },

            showMenu(dashboard, event) {
                if (this.selectMode) return;
                if (!dashboardIsEditable(dashboard)) return ;
                const menu = [
                    {
                        text: "Show dashboard",
                        tooltip: "Show this dashboard",
                        method: "showDashboard",
                        disabled: dashboard.id === this.selectedDashboardId,
                    },
                    {
                        text: "Edit dashboard…",
                        tooltip: "Shows the dashboard, and opens its settings",
                        method: "editDashboard",
                    },
                    {
                        text: "Pin dashboard…",
                        tooltip: "Pins the dashboard to a top-level custom dashboard folder",
                        method: "pinDashboard",
                        disabled: dashboard.category === 'Archived',
                        hide: !this.user.admin || (dashboard.pinned || dashboard.purchased)
                    },
                    {
                        text: "Unpin dashboard…",
                        tooltip: "Removes the dashboard from the Pinned folder",
                        method: "unpinDashboard",
                        hide: !this.user.admin || (!dashboard.pinned || dashboard.purchased)
                    },
                    Beef.MiniMenu.divider,
                    {
                        text: "Archive dashboard…",
                        tooltip: "Places the dashboard in the Archived folder. Out of sight, out of mind",
                        method: "archiveDashboard",
                        disabled: dashboard.category === 'Archived'
                    },
                    {
                        text: "Delete dashboard…",
                        tooltip: "Deletes the dashboard",
                        method: "deleteDashboard"
                    }
                ];

                if (this.user.admin) {
                    const privacy = dashboard.privacy ?? "PUBLIC";
                    menu.push(Beef.MiniMenu.divider);
                    switch (privacy) {
                        case "PUBLIC":
                            menu.push({
                                text: "Make private",
                                tooltip: "Makes the dashboard visible to DataEQ staff only",
                                method: "makePrivate"
                            });
                            break;
                        case "MASH_ADMIN":
                            menu.push({
                                text: "Make visible",
                                tooltip: "Makes the dashboard visible to the account's clients",
                                method: "makePublic"
                            });
                            break;
                    }
                }

                if (this.currentOpenMenu) {
                    this.currentOpenMenu.close();
                    this.currentOpenMenu = null;
                }

                this.highlightDashboardId = dashboard.id;

                this.currentOpenMenu = Beef.MiniMenu.show({
                    object: {
                        showDashboard:    () => this.selectedDashboardId = dashboard.id,
                        editDashboard:    () => this.editDashboard(dashboard),
                        pinDashboard:     () => this.pinDashboard(dashboard),
                        unpinDashboard:   () => this.unpinDashboard(dashboard),
                        archiveDashboard: () => this.archiveDashboard(dashboard),
                        deleteDashboard:  () => this.deleteDashboard(dashboard),
                        makePublic:       () => this.updatePrivacy(dashboard, 'PUBLIC'),
                        makePrivate:      () => this.updatePrivacy(dashboard, 'MASH_ADMIN')
                    },
                    target: event.target,
                    onClose: () => {
                      this.highlightDashboardId = null;
                    },
                    positions: ['bottom-right', 'top-right'],
                    offsets: { left: -5, top: 10},
                    dropdown: true,
                    menu: menu
                });
            },

            editDashboard(dashboard) {
                if (this.selectedDashboardId === dashboard.id) {
                    this.hidden = true;
                    this.view?.edit(null, "From sidebar");
                } else {
                    this.$once('dashboard-loaded',
                        () => {
                            this.hidden = true;
                            this.view?.edit(null, "From sidebar");
                        });
                    this.selectedDashboardId = dashboard.id;
                }
            },

            async updatePrivacy(dashboard, value) {
                const fullDashboard = await this.getFullDashboard(dashboard.id);
                const oldValue = fullDashboard.privacy;
                if (this.idToDashboard.has(dashboard.id)) {
                    this.idToDashboard.get(dashboard.id).privacy = fullDashboard.privacy = value;
                }
                try {
                    this.view?.model.set('privacy', value);
                    await this.updateDashboard(fullDashboard);
                } catch (e) {
                    if (this.idToDashboard.has(dashboard.id)) {
                        this.idToDashboard.get(dashboard.id).privacy = fullDashboard.privacy = oldValue;
                    }
                    this.view?.model.set('privacy', oldValue);
                    await showErrorDialog("There was an error changing this dashboard's visibility");
                }
            },

            updateRecentDashboards(dashboard){
                // check if dashboard is already in list or if dashboard is landing page
                if (this.recentDashboardIds.find(recentDashboardId => recentDashboardId === dashboard.id)) {
                    return;
                }

                if (dashboard.type !== "LANDING_PAGE") {
                    // only keep the 5 most recent dashboards
                    if (this.recentDashboardIds.length === 5) {
                        // remove first dashboard ID since it has the lowest priority
                        this.recentDashboardIds.splice(0, 1);
                    }

                    this.recentDashboardIds.push(dashboard.id);

                    // save recent dashboards in local storage
                    localStorage.setItem(`safe-cache:user:${this.user.id}:account:${this.account.code}:recent-dashboards`, JSON.stringify(this.recentDashboardIds));
                }
            },

            selectDashboard(id) {
                if (this.selectMode) this.updateSelectedDashboardsList(id);
                else this.selectedDashboardId = id;
            },

            maybeCloseSidebar() {
                if (this.selectedDashboardId) {
                    this.hidden = true;
                }
            },

            backgroundFetchDashboard(id) {
                let dashboard = this.idToDashboard.get(id);
                if (!dashboard) {
                    dashboard = {
                        id: id,
                        name: "" + id,
                        description: null,
                        category: null,
                        type: null,
                        privacy: null,
                        ordinal: null,
                        _loading: true
                    }
                    this.refreshDashboards(false).then(() => {
                        if (this.idToDashboard.has(id)) {
                            Object.assign(dashboard, {_loading: false}, this.idToDashboard.get(id));
                            this.idToDashboard.set(id, dashboard);
                        } else {
                            Object.assign(dashboard, {deleted: true, _loading: false}, dashboard);
                        }
                    })
                }
                return dashboard;
            },

            async loadDashboard(id, hidePanel) {
                hidePanel ??= true;

                this.updateUrl();

                if (this.view) {
                    this.view.close();
                    this.view = null;
                }

                if (this.$refs.view) {
                    this.$refs.view.innerHTML = '';
                }

                if (!id) {
                    // There is no dashboard to show, but if we are
                    // creating a dashboard, there is about to be one. And we are currently showing
                    // a message to the user.
                    this.hidden = this.creatingDashboard;
                    return;
                }

                // If the user selected a dashboard, then hide the sidebar when they move out of it.
                // Otherwise we don't need to do anything.
                if (hidePanel) {
                    this.hideOnMouseOut = !this.hidden;
                }

                const dashboardShallow = this.backgroundFetchDashboard(id);
                this.currentDashboard = dashboardShallow;
                let dashboard = null;
                try {
                    this.loadingCurrentDashboard = true;
                    dashboard = await this.getFullDashboard(id);
                    // todo: there's no clear indication that you've opened a deleted dashboard.
                } catch (e) {
                    if (e.response?.status === 404) {
                        // TODO: this is the old backbone behaviour. It should really show a message that the dashboard is missing instead.
                        console.warn(`Requested dashboard with id ${this.selectedDashboardId} does not exist. Opening default dashboard.`);
                        await this.refreshDashboards(false); // Ensure that the dashboards have all been loaded (and this.dashboards set)
                        if (this.dashboards?.length) {
                            this.selectedDashboardId = this.dashboards[0].id;
                        }
                    } else {
                        console.error(e);
                        await showErrorDialog("There has been an unexpected error when trying to find your dashboard. Please try again in a few minutes.");
                    }
                    return;
                } finally {
                    this.loadingCurrentDashboard = false;
                }

                dashboard = updateOldDashboard(dashboard);  // Ensure that we run model updates, if needed.

                if (dashboardShallow._unlocked) {
                    dashboard._unlocked = true;
                    dashboardShallow._readOnly = false;
                    dashboard._readOnly = false;
                }
                if (dashboardShallow._readOnly !== undefined) dashboard._readOnly = dashboardShallow._readOnly;


                const model = new Beef.Dashboard.Model(dashboard);
                model.urlRoot = Beef.Sync.toMashUrl("accounts/" + this.account.code + "/reports/")
                model.accountCode = this.account.code;

                if (dashboard.category === "Archived") {
                    this.showArchived = true;
                    this.archiveExpanded = true;
                }

                // Between beginning to load a dashboard and now, the user may have navigated away from the
                // dashboard panel, so we no longer have a dom to play with.
                if (!this.$refs.view) return;
                const view = this.view = new Beef.Dashboard.View({model: model});
                model.view = view;
                view.render();
                this.$refs.view.append(view.el);

                if (isCampaignDashboardLink()) {
                    let sectionId = getAnchorSectionId();

                    this.scrollToSection(sectionId);
                }

                view.on("dashboard-settings-close", value => {
                    let updatedDashboard = value?.model?.attributes;
                    if (updatedDashboard) {
                        VuexStore.commit('dashboards/updateDashboardSettings', {dashboardId: id, value: updatedDashboard});
                    }

                    this.refreshDashboards(true);
                });
                view.on("show-sidebar", () => this.hidden = false);
                view.on("duplicate-dashboard", () => this.duplicateDashboard(dashboard));
                view.on("delete", () => this.deleteDashboard(dashboard));
                view.on("archive", () => this.archiveDashboard(dashboard));
                view.on("unlock", () => {
                    this.selectedDashboardId = null;
                    dashboardShallow._unlocked = true;
                    dashboard._readOn
                    this.$nextTick(() => {
                        this.hidden = true;
                        this.selectedDashboardId = dashboard.id;
                        setTimeout(() => notifyWithText("The dashboard can now be edited", null, "<i class='symbol-reports'></i>"), 1000)                        ;
                    });
                });

                // update recent dashboard list
                this.updateRecentDashboards(dashboard);

                this.$emit('dashboard-loaded');
            },

            updateUrl() {
                if (this.selectedDashboardId) {
                    setLastSeenDashboard(this.selectedDashboardId);
                    let link = `/${this.account.code}/dashboards${(this.selectedDashboardId ? "/" + this.selectedDashboardId : "")}`
                    // preserve dashboard public link token if there is one
                    if (this.tok) link += "?tok=" + this.tok

                    // don't handle query params if we're navigating between dashboards using the panel
                    if (this.handleCampaignSectionNav) {
                        if (isCampaignDashboardLink()) {
                            link += `?campaign=${getCampaignFromLink()}#section-content-${getAnchorSectionId()}`;
                        }
                        // query parameter & hash anchor will be removed when another dashboard is loaded via the panel
                        this.handleCampaignSectionNav = false;
                    }

                    // easier to test the asEmail stuff if the browser URL isn't updated wiping the asEmail token
                    if (!this.asEmail) Beef.router.navigate(link)
                }
            },

            scrollToSection(sectionId) {
                const section = document.querySelector(`.section[data-id='${sectionId}']`);
                if (section) {
                    section.scrollIntoView();
                } else {
                    console.warn(`Unable to scroll to given section ${sectionId}`)
                }
            },

            async createDashboard(optionalCategory, purchased, pinned) {
                pinned ??= false;

                if (!this.canEditDashboards) return;
                if (this.creatingDashboard) return;

                this.creatingDashboard = true;
                this.selectedDashboardId = null;

                let busyMessage = "Creating your dashboard";
                if (optionalCategory) busyMessage += ` in the <strong>${escapeExpression(optionalCategory)}</strong> folder`;
                const busy = showBusyNotification(busyMessage, null, null, true);
                try {
                    // We want to find a decent name for the new dashboard.
                    let newDashboardName = "New Dashboard " + moment().format("YYYY-MM-DD");
                    if (this.user.admin) {
                        newDashboardName = this.user.firstName + " " + moment().format("YYYY-MM-DD");
                    }

                    let max = 0;
                    let unnumberedDashboards = 0;
                    this.dashboards?.forEach(function(d) {
                        let name = d.name;
                        if (name.startsWith(newDashboardName)) {
                            let parts = name.substring(newDashboardName.length).trim().split(" ");
                            if (parts.length === 1 && parts[0].match(/^v\d+$/)) { // matches things like v2, v3, etc.
                                // Here we have, hopefully, a number.
                                max = Math.max(max, parseInt(parts[0].substring(1)));
                            } else {
                                unnumberedDashboards++;
                            }
                        }
                    });

                    max = Math.max(max, unnumberedDashboards) + 1;
                    if (max > 1) newDashboardName += " v" + max;

                    let dashboard = {
                        name: newDashboardName,
                        'max-width': '12',
                        resizable: true,
                        gridVersion: 2,
                        styleVersion: 2,
                        sections: [
                            {
                                id: 1,
                                title: "Section 1",
                                widgets: [Beef.Widget.BrandSelector.createDefaultWidget(1)]
                            }
                        ]
                    };

                    if (this.user.admin) {
                        dashboard.category = "DataEQ";
                        dashboard.privacy = "MASH_ADMIN";
                    }

                    if (optionalCategory) {
                        dashboard.category = optionalCategory
                    }

                    dashboard.pinned = pinned;

                    if (purchased) {
                        dashboard.purchased = purchased
                    }

                    await this.createDashboardVuex(dashboard);
                    this.creatingDashboard = false;

                    this.$once('dashboard-loaded', () => busy.close());
                    this.selectedDashboardId = dashboard.id;

                    showTip("DASHBOARD_NAMING", "DASHBOARD_SETTINGS", "CUSTOMISE_DASHBOARD_FILTERS");
                } catch(e) {
                    console.error(e);
                    busy.close();
                    showErrorDialog("Something went wrong when creating your dashboard. Please try again.");
                }
                finally {
                    this.creatingDashboard = false;
                }
            },

            async duplicateDashboard(partialDashboard) {
                try {
                    const dashboard = await this.getFullDashboard(partialDashboard.id);
                    delete dashboard.id;

                    dashboard.name = dashboard.name ? (dashboard.name + " duplicate") : "duplicate";
                    dashboard.type = 'NORMAL';
                    delete dashboard._readOnly; // This is a copy of a now normal dashboard. It should now be editable.
                    dashboard.purchased = false; // Dashboard should be editable by clients if duplicated
                    dashboard.pinned = false; // Dashboard should be editable by clients if duplicated

                    if (this.user.admin && !dashboard.category) {
                        dashboard.category = "DataEQ";
                        dashboard.privacy = "MASH_ADMIN";
                    }

                    await this.createDashboardVuex(dashboard);
                    this.selectedDashboardId = dashboard.id;
                } catch (e) {
                    console.error(e);
                    showErrorDialog("We've had a problem duplicating this dashboard. Please try again in a few minutes.");
                }
            },

            async unpinDashboard(dashboard) {
                const proceed = await showAskDialog("Unpin this dashboard?",
                    "Would you like to unpin this dashboard?",
                    true
                );

                if (!proceed) return;

                dashboard = this.idToDashboard.get(dashboard.id);
                let fullDashboard = await this.getFullDashboard(dashboard.id);

                this.view?.model?.set('pinned', false);
                fullDashboard.pinned = false;
                try {
                    this.showPinned = true;
                    this.pinnedExpanded = true;

                    if (dashboard.id === this.selectedDashboardId) {
                        this.$nextTick(() => {
                            const item = document.querySelector(`.dashboard-panel__list-item[data-id='${this.selectedDashboardId}']`);
                            item?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                        });
                    }

                    await this.updateDashboard(fullDashboard);

                } catch (e) {
                    console.error(e);
                    this.view?.model?.set('pinned', true);
                    showErrorDialog("We were unable to unpin this dashboard");
                    return
                }

                notifyUser({
                    message: "Dashboard has been unpinned",
                    icon: "<i class='symbol-pin'></i>",
                    undo: async () => {
                        fullDashboard = await this.getFullDashboard(dashboard.id);
                        fullDashboard.pinned = true;

                        this.view?.model?.set('pinned', true);
                        try {
                            await this.updateDashboard(fullDashboard);
                            notifyWithHtml("The dashboard has been pinned");
                        } catch (e) {
                            console.error(e);
                            showErrorDialog("Unable to pin this dashboard");
                        }
                    }
                })
            },

            async pinDashboard(dashboard) {
                const proceed = await showAskDialog("Pin this dashboard?",
                    "Would you like to pin this dashboard?",
                    true
                );

                if (!proceed) return;

                dashboard = this.idToDashboard.get(dashboard.id);
                let fullDashboard = await this.getFullDashboard(dashboard.id);

                this.view?.model?.set('pinned', true);
                fullDashboard.pinned = true;
                try {
                    this.showPinned = true;
                    this.pinnedExpanded = true;

                    if (dashboard.id === this.selectedDashboardId) {
                        this.$nextTick(() => {
                            const item = document.querySelector(`.dashboard-panel__list-item[data-id='${this.selectedDashboardId}']`);
                            item?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                        });
                    }

                    await this.updateDashboard(fullDashboard);

                } catch (e) {
                    console.error(e);
                    this.view?.model?.set('pinned', false);
                    showErrorDialog("We were unable to pin this dashboard");
                    return
                }

                notifyUser({
                    message: "Dashboard has been pinned",
                    icon: "<i class='symbol-pin'></i>",
                    undo: async () => {
                        fullDashboard = await this.getFullDashboard(dashboard.id);
                        fullDashboard.pinned = false;

                        this.view?.model?.set('pinned', false);
                        try {
                            await this.updateDashboard(fullDashboard);
                            notifyWithHtml("The dashboard has been unpinned");
                        } catch (e) {
                            console.error(e);
                            showErrorDialog("Unable to unpin this dashboard");
                        }
                    }
                })
            },

            async archiveDashboard(dashboard) {
                const proceed = await showAskDialog("Archive this dashboard?",
                    new Handlebars.SafeString("Would you like to move the dashboard " +
                        "<em>" + dashboard.name + "</em> to the <em>Archive</em> folder? The dashboard will not be deleted."),
                    true
                );

                if (!proceed) return;

                dashboard = this.idToDashboard.get(dashboard.id);
                let fullDashboard = await this.getFullDashboard(dashboard.id);

                const oldCategory = fullDashboard.category;
                this.view?.model?.set('category', "Archived");
                fullDashboard.category = "Archived";
                try {
                    this.showArchived = true;
                    this.archiveExpanded = true;

                    if (dashboard.id === this.selectedDashboardId) {
                        this.$nextTick(() => {
                            const item = document.querySelector(`.dashboard-panel__list-item[data-id='${this.selectedDashboardId}']`);
                            item?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                        });
                    }

                    await this.updateDashboard(fullDashboard);

                } catch (e) {
                    console.error(e);
                    this.view?.model?.set('category', oldCategory);
                    showErrorDialog("We were unable to archive this dashboard");
                    return
                }

                notifyUser({
                    message: "Dashboard was archived",
                    icon: "<i class='symbol-reports'></i>",
                    undo: async () => {
                        fullDashboard = await this.getFullDashboard(dashboard.id);
                        fullDashboard.category = oldCategory;

                        this.view?.model?.set('category', oldCategory);
                        try {
                            await this.updateDashboard(fullDashboard);
                            notifyWithHtml("The dashboard has been unarchived and moved back to <strong>" + escapeExpression(oldCategory) + '</strong>.');
                        } catch (e) {
                            console.error(e);
                            showErrorDialog("Unable to unarchive this dashboard");
                        }
                    }
                })
            },

            async deleteDashboard(dashboard) {
                const name = dashboard.name;

                const proceed = await showAskDialog("Delete dashboard?", "Are you sure you want to delete dashboard '" + name + "'?", true);
                if (!proceed) return;

                try {
                    let oldDashboard = Object.assign({}, dashboard);
                    if (oldDashboard.id === this.selectedDashboardId) {
                        this.selectedDashboardId = null;
                    }

                    await this.deleteDashboardVuex(dashboard.id);

                    // remove from recent dashboard ID's if required
                    let index = this.recentDashboardIds.indexOf(dashboard.id);
                    if (index > -1) {
                        this.recentDashboardIds.splice(index, 1);
                        localStorage.setItem(`safe-cache:user:${this.user.id}:account:${this.account.code}:recent-dashboards`, JSON.stringify(this.recentDashboardIds));
                    }

                    notifyUser({
                        message: "Dashboard " + escapeExpression(name) + " <strong>has been deleted</strong>.",
                        isEscapedHtml: true,
                        icon: "<i class='symbol-reports'></i>",
                        longDelay: true,
                        undo: async () => {
                            // Refetch, to make sure we don't have update conflicts (409s)
                            oldDashboard = await this.getFullDashboard(oldDashboard.id);
                            oldDashboard.deleted = false;
                            try {
                                await this.updateDashboard(oldDashboard);
                                notifyWithText("Your dashboard has been undeleted.", null, "<i class='symbol-reports'></i>");
                                this.selectedDashboardId = dashboard.id;
                                await this.refreshDashboards(true);
                            } catch (e) {
                                console.error(e);
                                showErrorDialog("We were unable to undelete your dashboard");
                            }

                        }
                    })
                } catch (e) {
                    console.error(e);
                }
            },

            showArchiveClicked() {
                this.showArchived = !this.showArchived;
                this.archiveExpanded = true;

                if (this.showArchived) {
                    this.$nextTick(() => {
                        this.$refs.archive?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                    })
                }
            },

            keyPressHandler(event) {
                if (areAnyPopupsVisible()) return;
                if (event.target?.tagName === 'INPUT') return;

                if (event.key === "Escape") this.maybeCloseSidebar();

                if (event.key === 'd') {
                    if (this.hidden) this.hidden = false;
                    else this.maybeCloseSidebar();
                }

                if (!this.hidden) {
                    // We only want keypresses to come into play in specific cases.
                    // For instance, if the user is in the dashboard search box, press 'a'
                    // should do nothing.

                    // Press 'a' creates a new dashboard.
                    if (event.key === 'a' && this.canEditDashboards) {
                        this.createDashboard();
                    }

                    // Press 'r'
                    if (event.key === 'r') {
                        this.showArchiveClicked();
                    }
                } else {
                    switch (event.key) {
                        case 'r':
                            if (this.view) {
                                this.view.refresh();
                            }
                            break;
                    }
                }
            },

            sectionSelected(val) {
                if (val?.id) {
                    const section = document.querySelector(`.section[data-id='${val.id}']`);
                    if (section) {
                        // Can't use scrollIntoView, since we need to take the top menu bar into consideration.
                        window.scrollTo({
                            top: section.offsetTop,
                            behavior: "smooth"
                        })
                    }
                }
            },

            sectionMoved({section, position}) {
                if (!this.view?.sections?.currentView?.collection?.models) return;
                const sectionModels = this.view.sections.currentView.collection.models;
                const toMove = sectionModels.find(m => m.attributes.id === section.id);
                const moveTo = sectionModels.at(position);

                // We are going to rebuilt the list of sections and leave out
                // the item that we are being asked to move, and add it back later.
                // There is one special case: when it's being moved to the very end.
                const reordered = [];
                for (let i = 0; i < sectionModels.length; i++) {
                    if (sectionModels[i].cid === toMove.cid) continue;
                    if (sectionModels[i].cid === moveTo?.cid) reordered.push(toMove);
                    reordered.push(sectionModels[i]);
                }

                if (!moveTo) reordered.push(toMove);        // Our special-case, mentioned above.

                for (let i = 0; i < sectionModels.length; i++) {
                    sectionModels[i] = reordered[i];
                }

                if (moveTo) {
                    toMove.view.el.remove();
                    moveTo.view.el.insertAdjacentElement("beforebegin", toMove.view.el);
                } else {
                    // Our special case, again mentioned above.
                    const parent = toMove.view.el.parentNode;
                    toMove.view.el.remove();
                    parent.appendChild(toMove.view.el);
                }


                this.sectionSelected(section);

                this.view.sections.currentView.collection.owner.save();
                this.view.sections.currentView.collection.trigger("reorder");
            }

        }
    }
</script>


<style scoped lang="sass">

.dashboard-panel
    margin-right: 15px       // This space is used by the contents navigation bar.
    --dashboard-sidebar-shadow-colour: rgba(0, 0, 0, 0.7)

.fullscreen .dashboard-panel
    margin-right: 0         // We don't show the contents navigation bar in full screen or presentation mode.

.dashboard-panel__sidebar-frame
    position: fixed
    z-index: 1000
    height: calc(100vh - 40px)
    width: 100vw
    background: rgba(0, 0, 0, 0.5)
    animation: fadeIn 250ms both

.dashboard-panel__sidebar
    --sidebar-size: 400px

    transition: left 150ms
    contain: strict

    display: flex
    flex-direction: column
    width: var(--sidebar-size)
    left: 0
    top: 40px
    height: calc(100vh - 40px)
    position: fixed
    z-index: 1001
    box-shadow: 5px 5px 5px var(--dashboard-sidebar-shadow-colour)

    background: var(--sidebar-background)

    &--hidden
        left: calc(-1 * var(--sidebar-size) - 10px)

.dashboard-panel__title
    display: flex
    align-items: center
    background: var(--colour-background-black)
    .btn-three-bar
        margin-left: auto
        margin-right: 20px
        box-sizing: border-box
        span
            background: var(--sidebar-grey)
        &:hover
            span
                background: var(--be-colour-text-dark__hover)
    h4
        font-size: 12px
        text-transform: uppercase
        color: var(--clr-sidebar-header)
        margin: 0 0 0 12px
        line-height: 52px

.dashboard-panel__dashboard-list
    display: flex
    flex-direction: column
    overscroll-behavior-y: contain
    &--fill
        flex-grow: 1
        overflow-y: auto


.search-input
    height: 25px
    margin-left: 10px

.be-button ::v-deep .btn-link
    margin: 0
    padding: 0


.dashboard-panel__message
    color: var(--be-colour-muted-text-dark)
    text-align: center
    padding-top: 20px

.dashboard-panel__purchased
    max-height: 20vh
    overflow-y: auto

.dashboard-panel__custom
    display: flex
    flex-direction: column
    .dashboard-panel__custom-header
        display: flex
        align-items: center
        width: 100%

.dashboard-panel__actions
    flex-grow: 1
    display: flex
    justify-content: flex-end
    margin-right: 10px

    i[class^= 'symbol-'], i[class^= 'icon-']
        font-size: 20px
        margin-left: -5px

    i[class^= 'symbol-']
        font-size: 16px

    .dashboard-panel__search
        margin-right: 10px
        ::v-deep input.search
            background: var(--colour-background-black)
        ::v-deep label
            line-height: 15px


.dashboard-panel__new-dashboard-message
    height: auto

.dashboard-panel__disabled
    color: #999999
    pointer-events: none

.dashboard-panel__action-menu
    margin: 3px 0
    width: 100%

    ul
        list-style: none
        margin: 0

    li
        line-height: 20px

    li > a
        font-weight: 400
        color: #ffffff
        cursor: pointer
        display: block
        padding: 3px 10px
        clear: both
        white-space: nowrap

        &:hover
            color: #aee15d
            text-decoration: none
            background-color: #222222

.dashboard-panel__select-checkbox
    margin-right: 10px

.dashboard-panel__multi-select-actions
    width: 150px
    display: flex
    column-gap: 20px
    font-style: italic
    color: #ccc

.dashboard-panel__action-menu-header
    background: #222222
    padding: 3px 10px
    font-size: 12px
    text-transform: uppercase
    color: var(--clr-sidebar-header) !important
    pointer-events: none

.dashboard-panel__action-menu-divider
    margin: 5px 0
    overflow: hidden
    border-bottom: 1px solid #222222
    pointer-events: none
    padding: 0

.dashboard-panel__action-menu-label
    padding: 3px 10px
    margin-left: 20px

.dashboard-panel__action-menu-close-button
    float: right
    padding: 2px 10px 5px 0

.dashboard-panel__highlight-list-item
    background: var(--background-menu-hover)
    color: white

    & ::v-deep .symbol-menu, ::v-deep .symbol-lock
        display: inline-block !important

    & ::v-deep .symbol-menu
        border-radius: 10px
        box-shadow: 0 0 2px #fff

.dashboard-panel__list-item-divider
    display: inline-block
    margin-left: 5px
    padding-left: 5px
    border-left: 1px solid #888

.dashboard-panel__add-purchased-button
    opacity: 0

    &:hover
        opacity: 1
        transition: opacity var(--transition-duration)

</style>