/**
 * A module for making requests to the historical search service.
 */

import moment from "moment";

Beef.module("SearchJobSync").addInitializer(function() {

    function toISOStringNoMillis(m) {
        var date = moment(m).utc();
        if (0 < date.year() && date.year() <= 9999) {
            return date.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        } else {
            return date.format("YYYYYY-MM-DD[T]HH:mm:ss[Z]");
        }
    }
    this.toISOStringNoMillis = toISOStringNoMillis;

    /**
     * Returns the endpoint used to make requests to the historical search endpoint.
     */
    var getSyncUrl = function(code, id) {
        return code + "/archive-searches/" + (id ? id + '/' : '');
    };
    this.getSyncUrl = getSyncUrl;

    /**
     * Creates a historical search from an attribute map. The returned object is a deep copy.
     * @param {Object} attrs Properties of a search job. Accepted properties are:
     * <ul>
     * <li>jobId
     * <li>name
     * <li>searchFilter
     * <li>searchRangeEndDate
     * <li>searchRangeStartDate
     * <li>searchPhrases
     * </ul>
     * @return {{Object}} A search job. Dates are formatted to ISO Strings without milliseconds. Returned properties,
     *     if available, are:
     *     {{
     *         id: Number,
     *         name: String,
     *         searchFilter: String,
     *         searchRangeEndDate: String,
     *         searchRangeStartDate: String,
     *         searchPhrases: String[]
     *     }}
     */
    var createDTO = function(attrs) {
        attrs = attrs || {};
        var job = {};
        if (attrs.jobId) {
            job.id = attrs.jobId;
        } else if (attrs.id) {
            job.id = attrs.id;
        }
        if (attrs.name) job.name = attrs.name;
        if (attrs.searchRangeEndDate) job.searchRangeEndDate = toISOStringNoMillis(moment(attrs.searchRangeEndDate));
        if (attrs.searchRangeStartDate) job.searchRangeStartDate = toISOStringNoMillis(moment(attrs.searchRangeStartDate));
        if (attrs.searchPhrases) {
            job.searchPhrases = [];
            for (var i = 0; i < attrs.searchPhrases.length; ++i) {
                job.searchPhrases.push(attrs.searchPhrases[i]);
            }
        }
        if (attrs.customQuery) {
            job.customQuery = attrs.customQuery;
            job.retweetRule = null;
            job.searchFilter = null;
        }
        else {
            attrs.customQuery = null;
            if (attrs.retweetRule) job.retweetRule = attrs.retweetRule;
            if (attrs.searchFilter || (attrs.searchFilter === "")) job.searchFilter = attrs.searchFilter;
        }
        return job;
    };
    this.createDTO = createDTO;

    /**
     * Returns a search job for an account.
     * @param code The code of the account the job belongs to.
     * @param id The id of the search job to retrieve.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.get = function(code, id, success, error) {
        if (!code || !id) {
            throw new Error("Must specify an account code and id.");
        }
        return Beef.Sync.broccoliGET(getSyncUrl(code, id), {}, success, error);
    };

    /**
     * Returns a list of all search jobs for an account.
     * @param code The code of the account whose jobs should be returned.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.list = function(code, success, error) {
        if (!code) {
            throw new Error("Must specify an account code.");
        }
        return Beef.Sync.broccoliGET(getSyncUrl(code), {}, success, error);
    };

    /**
     * Updates a job for an account.
     * @param code The code of the account the job belongs to.
     * @param job The search job to update with all its updated fields.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    var update = function(code, job, success, error) {
        var url = toJobUrl(code, job);
        var dto = createDTO(job);
        return Beef.Sync.broccoliPUT(url, dto, success, error);
    };
    this.update = update;

    /**
     * Updates a job for an account.
     * @param code The code of the account the job belongs to.
     * @param job The search job to update with all its updated fields.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    var updateWithSplit = function(code, job, success, error) {
        var url = toJobUrl(code, job);
        if (!url.endsWith("/")) {
            url += "/";
        }
        url = url + "split";
        var dto = createDTO(job);
        return Beef.Sync.broccoliPOST(url, dto, success, error);
    };

    /**
     * Removes search data from a search job.
     * @param code The code of the account the job belongs to.
     * @param job The search job to reset.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    var resetData = function(code, job, success, error) {
        var url = toJobUrl(code, job) + "details/";
        return Beef.Sync.broccoliDELETE(url, success, error);
    };
    this.resetData = resetData;

    /**
     * Removes search data from a search job and thereafter tries to update the job.
     * @param code The code of the account the job belongs to.
     * @param job The search job to reset and update.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.clearAndUpdate = function(code, job, success, error) {
        var onReset = function() {
            update(code, job, success, error);
        };
        var onResetError = function() {
            console.error("Cannot update this job: unable to clear data");
        };
        resetData(code, job, onReset, onResetError);
    };

    /**
     * Removes search data from a search job and thereafter tries to update the job.
     *
     * If the job query is too long, then multiple jobs are created.
     *
     * @param code The code of the account the job belongs to.
     * @param job The search job to reset and update.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.clearAndUpdateWithSplit = function(code, job, success, error) {
        var onReset = function() {
            updateWithSplit(code, job, success, error);
        };
        var onResetError = function() {
            console.error("Cannot update this job: unable to clear data");
        };
        resetData(code, job, onReset, onResetError);
    };

    /**
     * Deletes a search job from an account.
     * @param code The code of the account the job belongs to.
     * @param job The search job to delete.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.delete = function(code, job, success, error) {
        var url = toJobUrl(code, job);
        return Beef.Sync.broccoliDELETE(url, success, error);
    };

    /**
     * Starts a volume search for a search job.
     * @param code The code of the account the job belongs to.
     * @param job The search job to start.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.start = function(code, job, success, error) {
        var url = toJobUrl(code, job);
        var dto = { searchCommenceDate: toISOStringNoMillis(moment()) };
        return Beef.Sync.broccoliPUT(url, dto, success, error);
    };

    /**
     * Starts mention retrieval for a search job.
     * @param code The code of the account the job belongs to.
     * @param job The search job mentions must be retrieved for.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.retrieve = function(code, job, success, error) {
        checkPhrases(job);
        var url = toJobUrl(code, job);
        var dto = { dataRetrieveCommenceDate: toISOStringNoMillis(moment()) };
        return Beef.Sync.broccoliPUT(url, dto, success, error);
    };

    /**
     * Starts mention sampling for a search job.
     * @param code The code of the account the job belongs to.
     * @param job The search job mentions must be sampled for.
     * @param success An ajax event on success.
     * @param error An ajax event on error.
     * @return {{jqXHR}}
     */
    this.sample = function(code, job, success, error) {
        checkPhrases(job);
        checkSample(job);
        var url = toJobUrl(code, job);
        var dto = {
            sampleStart: toISOStringNoMillis(job.sampleStart),
            sampleEnd: toISOStringNoMillis(job.sampleEnd)
        };
        return Beef.Sync.broccoliPUT(url, dto, success, error);
    };

    function checkPhrases(job) {
        let noCustomQuery = !job.customQuery || typeof job.customQuery !== 'string' || job.customQuery?.trim()?.length < 1;
        let noSearchPhrases = !job.searchPhrases || (job.searchPhrases.length < 1)

        if (!job || (noSearchPhrases && noCustomQuery)) {
            throw new Error("Must specify at least one search phrase.");
        }
    }

    function checkSample(job) {
        if (!job || !job.sampleStart || !job.sampleEnd) {
            throw new Error("Must specify a sample start and end date.");
        }
    }

    function toJobUrl(code, job) {
        if (!job || !job.id || !code) {
            throw new Error("Must specify an account code and job with id.");
        }
        return getSyncUrl(code, job.id);
    }
});
