/**
 * Drill down by adding/removing CX related tags
 */
import {getOperationalSentimentFilter, getReputationalSentimentFilter} from "@/app/utils/Sentiment";
import {appendSegmentRestrictions} from "@/app/utils/Segments";
import {showTooltipText} from "@/components/tooltip/TooltipUtilities";

Beef.module("Widget.ShowWhatSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Conversation types",
        width:          2,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        template: require("@/dashboards/widgets/selectors/ShowWhatSelector.handlebars"),

        events: Object.assign({}, Beef.Widget.SelectorWidget.View.prototype.events, {
            'mouseenter tr[data-tooltip]': 'showTooltip'
        }),

        filterAttribute: "_showWhatDrilldownSelection",

        refresh() {
        },

        renderImpl() {
            this.model.generalData.set('_completed', true);
            this.highlightSelectedRows(this.model.getInteractiveFilterModel().get(this.filterAttribute), false);
        },

        showTooltip(event) {
            showTooltipText(event.currentTarget, event.currentTarget.dataset.tooltip, {
                positions: ["right", "left"]
            })
        },

        // async highlightSelectedddRows(value, scroll) {
        //     const rows = this.$el.find('table.selector-widget-tiles .value');
        //     rows.toggleClass("selected", false);
        //
        //     if (!value) return;
        //     const row = this.$el.find(`table.selector-widget-tiles .value[data-value='${value}']`);
        //     if (row) row.toggleClass("selected", true);
        // },

        async tileClicked(event) {
            const value = event.currentTarget.dataset.value;
            const current = this.model.getInteractiveFilterModel().get(this.filterAttribute);

            this.model.getInteractiveFilterModel().set(this.filterAttribute, value !== current ? value : null);

            if (value === current) {
                this.model.getInteractiveFilterModel().set('_showWhatDrilldown', null);
                return;
            }

            let filter = null;
            switch (value) {
                case '.': break;
                case 'operational': filter = await getOperationalSentimentFilter(); break;
                case 'reputational':   filter = await getReputationalSentimentFilter(); break;
                case 'risk':  filter = appendSegmentRestrictions("tag is 4"); break;
                case 'press': filter = "media is press"; break;
                default:
                    throw new Error("Unhandled selector value: " + value);
            }

            this.model.getInteractiveFilterModel().set('_showWhatDrilldown', filter);
        }
    });
});