import {deprecatedBrandsStore, deprecatedTagsStore} from '../../../store/deprecated/Stores'
import {extractBrands} from "@/dashboards/filter/BasicFilter";

/**
 * Functionality common to the response metrics.
 */
export default class BrandResponseHelper {

    constructor(view) {
        this.view = view
        this.model = view.model
    }

    /**
     * Figure out what our cx segment list is and the support profiles.
     */
    async analyzeFilter() {
        var filter = this.model.get('_effectiveFilter')

        this.model.generalData.set({'_loading': true, _completed: false, _footnotes: ["Loading..."]})

        let brandIds = extractBrands(filter).include
        if (!brandIds.length) {
            return this.setMessage("Please select at least one brand")
        }

        await Promise.all([deprecatedBrandsStore.refresh(true), deprecatedTagsStore.refresh(true)])

        let cx
        let supportProfileIds = this.model.attributes.supportProfiles ? this.model.attributes.supportProfiles.split(' ') : []
        let usingModelProfiles = !!supportProfileIds.length
        this.ownBrands = true
        for (let i = 0; i < brandIds.length; i++) {
            let brand = deprecatedBrandsStore.get(brandIds[i])
            if (!brand) return this.setMessage("Brand " + brandIds[i] + " not found?")
            let rootBrand = brand;
            while (rootBrand.parent) rootBrand = rootBrand.parent;
            if (rootBrand.category !== 'OWN') this.ownBrands = false

            let bcx = await deprecatedTagsStore.getCXSegmentList(brand)
            if (!bcx) return this.setMessage((brand.shortName || brand.name) + " does not have customer experience enabled")
            if (!cx) cx = bcx
            else if (cx.id !== bcx.id) {
                return this.setMessage("All brands must have the same CX segments (" + cx.id + " " + cx.name +
                    " vs " + bcx.id + " " + bcx.name + ")")
            }

            if (!usingModelProfiles) {
                if (!rootBrand.supportProfileIds || !rootBrand.supportProfileIds.length) {
                    return this.setMessage((rootBrand.shortName || rootBrand.name) + " has no support online profiles")
                }
                supportProfileIds = supportProfileIds.concat(rootBrand.supportProfileIds)
            }
        }

        this.supportProfileIds = supportProfileIds
        this.cx = cx
    }

    setMessage(msg) {
        this.model.generalData.set({'_loading': false, _completed: true})
        this.model.generalData.set({_message: msg, _footnotes: [] })
    }
}