import {MentionQLexer} from '@/mentionq/mentionq';
import {grouseGet, toGrouseLink} from "@/data/Grouse";
import {parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import {buildBasicFilter, convertFilterToAttrs, extractBrands} from "@/dashboards/filter/BasicFilter";
import _ from 'underscore';
import {setTitle} from "@/app/Beef";
import {logPageUsed} from "@/app/utils/UserAccessLog";
import {getBrand} from "@/app/utils/Brands";
import {toEnglish} from "@/dashboards/filter/FilterToEnglish";
import {removeQuotes} from "@/app/utils/StringUtils";

Beef.module("AuthorsSectionV4").addInitializer(function(startupOptions) {

    var View = Backbone.Marionette.Layout.extend({
        template: require("@/authorsV4/AuthorsSectionV4.handlebars"),

        attributes: { class: "row-fluid authors-section-v4" },

        regions: {
            brandMenu: ".sidebar .brand-menu",
            authorListRegion: ".author-list-region",
            pagesRegion: ".pages-region",
            authorOptionsRegion: ".author-options"
        },

        events: {
            "click .edit-filter": "editFilter",
            "click .actions .clear-filter": "clearFilter",
            "click .actions .refresh": "refresh"
        },

        modelEvents: {
            "change:filter": "filterChanged"
        },

        initialize: function() {
            logPageUsed("authors-panel");

            this.model.set('title', 'Authors');
            setTitle('Authors');
            this.model.accountCode = this.accountCode = this.model.get('accountCode');

            this.paginationModel = new Backbone.Model({offset: 0, limit: 30, page: 1});
            this.paginationModel.on('change:offset', this.onOffsetChange.bind(this));

            this.authorsModel = new Backbone.Model({
                busy: true, orderBy: this.model.get('orderBy') || 'mentionCount desc'
            });
            this.authorsModel.on('change:orderBy', this.orderByChanged.bind(this));

            this.statsModel = new Backbone.Model();

            var filter = this.model.get('filter');
            if (!filter) {
                filter = Beef.generalData(this.accountCode).get('last-mention-filter');
                if (filter) {
                    var attrs = convertFilterToAttrs(filter);
                    if (!attrs.errors && attrs.author) {
                        attrs.author = null;
                        filter = buildBasicFilter(attrs);
                    }
                } else {
                    filter = 'Relevancy ISNT IRRELEVANT AND Published INTHELAST WEEK';
                }
                this.model.set('filter', filter);
            } else {
                this.updateBrandSubset();
            }
        },

        updateBrandSubset: function() {
            var filter = this.model.get('filter');
            var subset = toRootBrandsIds(extractBrands(filter).include);
            if (subset.length > 1 || !this.model.get('brandSubset')) this.model.set('brandSubset', subset);
            if (subset.length > 1) this.selectBrand(subset[0]);
        },

        onRender: function() {
            var btv = new Beef.MentionsBrandMenu.View({model: this.model});
            this.brandMenu.show(btv);
            btv.on("click", function(brandId){ this.brandClicked(brandId) }.bind(this));

            this.updateSidebarMenu();

            this.authorOptionsRegion.show(new Options({model: this.model}));

            this.authorListRegion.show(new Beef.AuthorListV4.View({model: this.authorsModel}));

            this.pagesRegion.show(new Beef.Pager.View({model: this.paginationModel,
                fixedTo: this.authorListRegion.currentView, fixedToWidthAdust: 0}));
        },

        orderByChanged: function() {
            this.filterChanged();
        },

        filterChanged: function() {
            // calling updateBrandSubset may trigger additional filterChanged events so ignore those
            if (this._inFilterChanged) return;
            this._inFilterChanged = true;
            try {
                this.updateBrandSubset();
                this.clearSelection();
                if (this.paginationModel.get('offset') !== 0) this.paginationModel.set({offset: 0});
                else this.fetchAuthors();
            } finally {
                this._inFilterChanged = false;
            }
        },

        refresh: function() {
            this.fetchAuthors();
        },

        fetchAuthors: function() {
            let offset = this.paginationModel.get('offset');

            if (offset === 0) this.abortStatsXhrs();

            if (offset === 0 && !this.authorsModel.get('authors')) {
                this.authorsModel.set({busy: true});
                this.abortStatsXhrs();
            } else {
                // moving to a new page so use the dimming effect instead of a spinner
                this.$(".mentions").toggleClass("mentions-loading", true);
            }

            this.$('.choose-brand').toggle(false);
            this.$('.fetch-error').toggle(false);

            let filter = this.model.get('filter');
            Beef.generalData(this.accountCode).set('last-mention-filter', filter);

            let select = 'mentionCount,totalEngagement,maxFollowerCount,maxFollowingCount,maxStatusCount,totalOTS,' +
                'totalPositive,totalNegative,totalSentiment';

            let params = {
                filter: filter,
                groupBy: 'socialNetwork,authorId',
                select: select + ',mostRecentMention',
                orderBy: this.authorsModel.get('orderBy'),
                offset: offset,
                limit: this.paginationModel.get('limit')
            };
            let endpoint = "/v4/accounts/" + this.accountCode + "/mentions/count";

            let ans = grouseGet(endpoint, params)
                .then((data) => this.onAuthorsFetched(data))
                .catch((error) => this.onFetchAuthorsError(error));

            this.model.set('csvLink', toGrouseLink(endpoint + ".csv", Object.assign({}, params, {
                select: 'socialNetwork.id,' + select + ',mostRecentMention[id,authorName,authorHandle,authorHandleId,' +
                    'authorPictureLink,authorProfileLink,authorBio,authorLocation,authorTimezone,country,region[id,name],city[id,name]],' +
                    'gender.id',
                limit: 100000
            })));

            if (offset === 0) {
                this.statsXhr = grouseGet(endpoint, {
                    filter: filter,
                    groupBy: 'socialNetwork',
                    select: "authorIdCount," + select
                });
                this.statsXhr.then((data) => this.onStatsReceived(data));
            }

            return ans;
        },

        onAuthorsFetched: function(authors) {
            var filter = this.model.get('filter');

            var ml = "/" + this.accountCode + "/mentions?filter=";
            for (var i = 0; i < authors.length; i++) {
                var a = authors[i];
                var name = a.mostRecentMention.authorName;
                a.mentionsLink = ml + encodeURIComponent(filter + " and authorid is " +
                        (a.authorId ? "'" + (name ? name + " " : '') + a.authorId + "'" : "UNKNOWN"));
            }

            this.authorsModel.set({busy: false, authors: authors, errorMsg: false, chooseBrand: false, filter: filter});

            this.$('.errors').toggle(false);
            this.$(".mentions").toggleClass("mentions-loading", false);

            toEnglish(filter, function(enFilter){
                enFilter = "Authors of " + enFilter.charAt(0).toLowerCase() + enFilter.substr(1);
                var oen = getOrderByAsEnglish(this.authorsModel.get('orderBy'));
                if (oen) enFilter += ", sorted by " + oen;
                this.model.set('englishFilter', enFilter);
            }.bind(this), this.cache);

            var url = "/" + this.accountCode + "/authors" + "?filter=" + encodeURIComponent(filter);
            var orderBy = this.authorsModel.get('orderBy');
            if (orderBy != "mentionCount desc") url += "&orderBy=" + encodeURIComponent(orderBy);
            Beef.router.navigate(url, {replace: true});

            this.updateSidebarMenu();
        },

        onStatsReceived: function(data) {
            this.statsXhr = null;
            var tot = {
                authorIdCount: 0,
                mentionCount: 0,
                totalEngagement: 0,
                totalOTS: 0,
                totalPositive: 0,
                totalNegative: 0
            };
            var keys = Object.keys(tot);
            for (var i = 0; i < data.length; i++) {
                var row = data[i];
                for (var j = 0; j < keys.length; j++) {
                    var key = keys[j];
                    var v = row[key];
                    if (v) tot[key] += v;
                }
            }
            tot.networks = data;
            this.statsModel.set(tot);
            this.paginationModel.set('total', tot.authorIdCount);
        },

        abortStatsXhrs: function() {
            if (this.statsXhr) {
                this.statsXhr.cancel();
            }
        },

        onFetchAuthorsError: function(xhr) {
            var p = { busy: false, authors: [], errorMsg: false, chooseBrand: false};
            var code = xhr.status;
            if (code == 422 && xhr.responseText && xhr.responseText.indexOf("[BRAND-ERROR]") > 0) {
                p.chooseBrand = true;
            } else {
                var msg;
                if (code == 400 || code == 422) msg = "Unable to fetch authors, there may be an issue with your filter";
                else if (code) msg = "Something has gone wrong, please try again later (" + code + ")";
                else msg = "Unable to reach DataEQ, please check your internet connection and try again";
                p.errorMsg = msg;
            }
            this.authorsModel.set(p);
        },

        onClose: function() {
            this.abortStatsXhrs();
        },

        // This is for the side bar. It removes all the items that the sidebar could effect from the filter.
        getResetFilter: function() {
            var filter = removeNodes(parseFilterString(this.model.get('filter')), function(node) {
                return node.operandType === MentionQLexer.BRAND
            });

            if (filter) return filter.toString();
            return "";
        },

        brandClicked: function(brandId) {
            this.selectBrand(brandId);
        },

        selectBrand: function(brandId) {
            this.clearSelection();
            var filter = this.getResetFilter();
            this.model.set({filter: filter + " and brand isorchildof " + brandId});
            this.updateSidebarMenu();
        },

        clearSelection: function() {
            this.model.set('allSelected', false);
            this.model.set('selected', {});
            this.model.set('unselected', {});
        },

        updateSidebarMenu: function() {
            this.model.set("notAllowed", "");
        },

        editFilter: function(ev) {
            ev.preventDefault();
            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["bottom-right"],
                alwaysMove: true
            });
            popup.setTarget($(ev.target));
            var view = new FilterPanel({model: this.model, list: this.list});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        onOffsetChange: function() {
            this.model.set("notAllowed", "");
            // weird stuff happens with true here or trying to scroll to just the first mention
            $(".author-options")[0].scrollIntoView(false);
            this.fetchAuthors();
        },

        clearFilter: function(ev) {
            var attr = convertFilterToAttrs(this.model.get('filter'));
            var filter = 'published inthelast week and relevancy isnt irrelevant';
            var rootBrand, a, i, b;
            if (!attr.errors && this.cache.brands) {
                if (attr.brand) {
                    a = attr.brand.split(' ');
                    for (i = 0; i < a.length; i++) {
                        var brandId = parseInt(a[i]);
                        if (brandId <= 0) continue;
                        b = this.cache.brands.map[brandId];
                        if (!b) continue;
                        while (b.parent) b = b.parent;
                        rootBrand = b;
                        break;
                    }
                }
                if (!rootBrand && attr.id) {
                    a = attr.id.split(' ');
                    for (i = 0; i < a.length; i++) {
                        var id = a[i];
                        var pos = id.indexOf('-');
                        if (pos > 0) {
                            b = this.cache.brands.map[parseInt(id.substr(0, pos))];
                            if (!b) continue;
                            while (b.parent) b = b.parent;
                            rootBrand = b;
                            break;
                        }
                    }
                }
            }
            if (!rootBrand && this.cache.brands && this.cache.brands.tree.length > 0) {
                rootBrand = this.cache.brands.tree[0];
            }
            if (rootBrand) filter += " and brand isorchildof " + rootBrand.id;
            this.model.set('filter', filter);
        }
    });

    var Options = Backbone.Marionette.ItemView.extend({
        template: require("@/authorsV4/AuthorOptionsV4.handlebars"),
        modelEvents: { "change": "render" }
    });

    var FilterPanel = Beef.SettingsDialog.View.extend({
        template: require("@/mentions/Filters.handlebars"),
        attributes: { class: "dialog filter-dialog" },
        editAttributes: ['filter'],
        regions: {
            filters: ".filter"
        },
        events: Object.assign({}, Beef.SettingsDialog.View.prototype.events,{
            "click a.btn.advanced": "toggleAdvanced",
            "click a.btn.basic": "toggleBasic",
        }),
        initialize: function(options) {
            this.list = options.list;
            Beef.SettingsDialog.View.prototype.initialize.call(this);
        },
        onFirstRender: function() {
            this.model.accountCode = this.originalModel.get('accountCode');
            let filter = new Beef.Filter.View({model: this.model, cache: this.cache, showTrash: true, noToggle: true});
            filter.on("editor-changed", function(ed) {
                this.$el.attr('data-editor', ed);
            }.bind(this));
            this.filters.show(filter);
        },
        ok: function() {
            var attrs = this.model.getAttrs(this.resolveEditAttributes());
            this.originalModel.set(attrs);
            this.close(true);
        },
        toggleAdvanced: function() {
            if (this.filters && this.filters.currentView) {
                this.filters.currentView.changeEditor("advanced");
            }
        },
        toggleBasic: function() {
            if (this.filters && this.filters.currentView) {
                this.filters.currentView.changeEditor("basic");
            }
        }
    });

    var getOrderByAsEnglish = function(orderBy) {
        var i = orderBy.indexOf(' ');
        var desc = i > 0;
        if (desc) orderBy = orderBy.substring(0, i);
        var en = orderByMap[orderBy] || orderBy;
        return desc ? en : en + " (lowest first)";
    };

    var orderByMap = {
        mentionCount:       "number of mentions",
        totalEngagement:    "engagement",
        maxFollowerCount:   "follower count",
        maxFollowingCount:  "following count",
        totalOTS:           "OTS",
        totalPositive:      "positive mentions",
        totalNegative:      "negative mentions",
        totalSentiment:     "net sentiment"
    };

    /**
     * Causes a transition to the author section, using the given filter.
     * @param code The account code this should be done for
     * @param filter The filter that we want to see.
     * @param newTab Optional boolean. If true, opens the author section in a new tab
     */
    this.navigateToAuthors = function(code, filter, newTab) {
        if (_(newTab).isUndefined()) newTab = false;
        filter = encodeURIComponent(filter);
        if (newTab) window.open("/" + code + "/authors?filter=" + filter);
        else Beef.router.navigate(code + "/" + 'authors?filter=' +  filter, {trigger: true});
    };

    var routeCallback = function(code, filter, orderBy) {
        if (!Beef.isAccountCode(code)) {
            Beef.content.show(Beef.view404);
            return
        }
        var model = new Backbone.Model({
            accountCode: code,
            filter: filter ? removeQuotes(decodeURIComponent(filter)) : filter
        });
        if (orderBy) model.set('orderBy', decodeURIComponent(orderBy));
        var view = new View({model: model});
        Beef.content.show(view);
        view.fetchAuthors();
    };

    Beef.route(":code/authors", "authors", routeCallback);
    Beef.route(":code/authors?filter=:filter", "authors", routeCallback);
    Beef.route(":code/authors?filter=:filter&orderBy=:orderBy", "authors", routeCallback);

    var toRootBrandsIds = function(brandIds) {
        var ans = [];
        // This field is not available on the current account object in vuex.
        for (var i = 0; i < brandIds.length; i++) {
            var b = getBrand(brandIds[i]);
            if (b) {
                while (b.parent) b = b.parent;
                if (ans.indexOf(b.id) < 0) ans.push(b.id);
            }
        }
        return ans;
    }
});
