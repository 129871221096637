<template>
    <section class="metric-description"
             :class="{expanded: optionsExpanded}"
             tabindex="0"
             @keydown.enter.stop="addMetric(metric)">
        <section class="metric-description__image" v-if="metric.image">
            <img :src="metric.image"
                 @click="addMetric(metric)">
        </section>

        <section class="metric_description__content" @click="addMetric(metric)">
            <h1>{{metric.title}}</h1>
            <p v-if="metric.description">
                {{metric.description}}
            </p>
            <p v-else style="color: red">
                «Missing description»
            </p>
            <p v-if="metric.more && !showMore" class="more-less">
                <a class="btn btn-link"  @click.stop="showMore = true">Show more</a>
            </p>
            <p v-if="showMore && metric.more">
                {{metric.more}}
            </p>
            <p v-if="showMore && metric.more" class="more-less">
                <a class="btn btn-link" @click.stop="showMore = false">Show less</a>
            </p>
            <section class="metric-description__actions" :style="actionsStyle">
                <be-button
                        ref="addButton"
                        tooltip="Add this metric to your dashboard"
                        :options="metric.options"
                        @click="addMetric(metric)"
                        @option="addMetric(metric, $event)"
                        :tab-selectable="false"
                        v-model="optionsExpanded">
                    <i class="symbol-add"></i>Add
                </be-button>
                <old-tooltip :label="metric.reference.tooltip" v-if="metric.reference" class="metric-description__reference">
                    <a class="btn btn-link"
                       @click="addMetric(metric.reference)">
                        Reference
                    </a>
                </old-tooltip>
            </section>
        </section>
    </section>
</template>


<script>

import BeButton from "../../../../components/buttons/BeButton";
import {applyOptionAction} from "./availableMetrics";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
        name: "MetricDescription",

        components: {OldTooltip, BeButton },

        props: {
            metric: Object
        },

        data() {
            return {
                showMore: false,
                optionsExpanded: false
            }
        },

        computed: {
            actionsStyle() {
                if (this.optionsExpanded) return { opacity: 1.0 };
                return {};
            }
        },

        methods: {
            async addMetric(metric, options) {
                metric = await applyOptionAction(metric, options);

                if (!metric) {
                    showErrorDialog("Unable to show this metric. Please contact support@dataeq.com for assistance.");
                    return;
                }
                this.$emit('add', metric);
            }
        }
    };

</script>

<style scoped lang="sass">
    .metric-description
        display: flex
        flex-direction: column
        padding: 10px
        &:focus-visible
            outline: var(--focus-border)

    .metric-description__image
        cursor: pointer
        max-height: 150px
        width: 100%
        text-align: center
        img
            max-height: 150px
            border-radius: 3px

    .metric_description__content
        cursor: pointer

    .more-less
        margin: 0 !important
        .btn-link
            margin: 0
            padding: 0

    .metric-description__actions
        transition: opacity var(--transition-duration)
        display: flex
        opacity: 0
        margin-top: 10px
        .metric-description__reference
            margin-left: auto
            width: max-content

    .btn-link
        &:not(:hover)
            color: var(--be-colour-muted-text-dark)

    h1
        font-size: 1.4em
        line-height: 1.5rem
        color: #ddd

    p
        font-size: 0.8rem
        line-height: 1.2rem
        color: var(--be-colour-text-dark)
        font-family: var(--widget-font)
    .more-less
        .btn-link
            font-size: 0.8rem
            line-height: 1.2rem

    .metric-description.expanded,
    .metric-description:hover
        //noinspection CssInvalidFunction
        background: radial-gradient(var(--light-highlight), rgba(0, 0, 0, 0) 60%)
        .metric-description__actions
            -moz-transition-duration: 400ms
            opacity: 1

</style>
