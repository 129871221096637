/**
 * Utilities for dealing with RPCS metatags
 */
import VuexStore from "@/store/vuex/VuexStore";


export function codeToColour(code, domElement) {
    if (!code) return null;
    domElement = domElement || document.documentElement;
    return getComputedStyle(domElement)
        .getPropertyValue('--be-colour-tag-' + code.toLowerCase())
        .trim();
}

/**
 * @param {string} name
 * @return {number}
 */
export function codeToOrdinal(name) {
    switch (name) {
        case "SERVICE": return 1;
        case "CANCEL": return 2;
        case "PURCHASE": return 3;
        case "RISK": return 4;
        default: {
            console.warn("Unrecognised risk name: ", name);
            return 0;
        }
    }
}

export function codeToId(name) {
    switch (name) {
        case "SERVICE": return 4;
        case "CANCEL": return 3;
        case "PURCHASE": return 2;
        case "RISK": return 1;
        default: {
            console.warn("Unrecognised risk name: ", name);
            return 0;
        }
    }
}

export function isRpcsFlag(flag) {
    switch(flag) {
        case "RISK":
        case "PURCHASE":
        case "CANCEL":
        case "SERVICE":
            return true;
        default:
            return false;
    }
}

/**
 * Given an array of tag objects, this returns the unique metatags
 * associated with them in order of importance (highest to lowest).
 */
export function getMetatagsFromTags(tagOrTagList) {
    if (tagOrTagList && !Array.isArray(tagOrTagList)) {
        if (tagOrTagList.namespace !== 'segment' && tagOrTagList.namespace !== 'BrandsEye') return null;
        var results = getMetatagsFromTags([tagOrTagList]);
        return (results && results.length && results[0]) || null;
    }

    if (!tagOrTagList || !tagOrTagList.length) return [];

    var names = new Set();

    tagOrTagList
        .filter(function(d) { return d.namespace === 'segment' || d.namespace === 'BrandsEye'; })
        .forEach(function(d) {
            if (d.id === 1) names.add("RISK");
            if (d.id === 2) names.add("PURCHASE");
            if (d.id === 3) names.add("CANCEL");
            if (d.id === 4) names.add("SERVICE");

            switch (d.flag) {
                case "SERVICE":
                case "CUSTOMER_SERVICE": names.add("SERVICE"); break;
                case "PURCHASE":
                case "INTENT_TO_PURCHASE": names.add("PURCHASE"); names.add("SERVICE"); break;
                case "CANCEL":
                case "INTENT_TO_CANCEL": names.add("CANCEL"); names.add("SERVICE"); break;
                case "RISK":
                case "HIGH_RISK": names.add("RISK"); break;
            }
        });

    return Array.from(names)
        .map(function(t) { return new Metatag(t) })
        .sort(function(lhs, rhs) {
            return rhs.ordinal - lhs.ordinal;
        })
}

/**
 * Returns the metatags available in the account. These are determined by the segment lists
 * attached to the account.
 */
export function getMetatagsInAccount() {
    const segmentLists = (VuexStore.state.account.segmentLists || [])
        .filter(list => !!list.segmentType);

    if (!segmentLists.length) return [];
    return getMetatagsFromTags(segmentLists.flatMap(function(s) { return s.children }));
}

export function codeToIconUrl(name) {
    switch (name) {
        case "SERVICE": return "/static/img/rpcs-icons/service-full.svg";
        case "CANCEL": return "/static/img/rpcs-icons/cancel-full.svg";
        case "PURCHASE": return "/static/img/rpcs-icons/purchase-full.svg";
        case "RISK": return "/static/img/rpcs-icons/risk-full.svg";
        default: {
            console.warn("Unrecognised risk name: ", name);
            return 0;
        }
    }
}


export function codeToSmallIconUrl(name) {
    switch (name) {
        case "SERVICE": return "/static/img/rpcs-icons/service.svg";
        case "CANCEL": return "/static/img/rpcs-icons/cancel.svg";
        case "PURCHASE": return "/static/img/rpcs-icons/purchase.svg";
        case "RISK": return "/static/img/rpcs-icons/risk.svg";
        default: {
            console.warn("Unrecognised risk name: ", name);
            return 0;
        }
    }
}

export function codeToOpportunity(name) {
    switch (name) {
        case "SERVICE": return "Assist a customer looking for service";
        case "CANCEL": return "Retain a customer looking to cancel";
        case "PURCHASE": return "Acquire a customer looking to purchase";
        case "RISK": return "Mitigate risk";
        default: {
            console.warn("Unrecognised risk name: ", name);
            return '«unknown»';
        }
    }
}

export function codeToLabel(name) {
    switch (name) {
        case "SERVICE": return "Service";
        case "CANCEL": return "Cancel";
        case "PURCHASE": return "Purchase";
        case "RISK": return "Risk";
        default: {
            console.warn("Unrecognised risk name: ", name);
            return name;
        }
    }
}

export function idToCode(id) {
    switch (Math.abs(parseInt(id))) {
        case 1: return "RISK";
        case 2: return "PURCHASE";
        case 3: return "CANCEL";
        case 4: return "SERVICE";
        default:
            return null;
    }
}

/**
 * Determines if a given tag is a customer service tag or not. Does not include channel tags.
 */
export function isService(tag) {
    if (typeof tag === 'string' || tag instanceof String) {
        switch (tag) {
            case 'CUSTOMER_SERVICE':
            case 'INTENT_TO_PURCHASE':
            case 'INTENT_TO_CANCEL':
            case 'SERVICE':
            case 'PURCHASE':
            case 'CANCEL':
                return true;
            default:
                return false;
        }
    }

    if (tag && tag.flag) {
        return isService(tag.flag) || (tag._parent && isService(tag._parent));
    }
    return null;
}

/**
 * Determines if a given tag is a risk tag or not.
 */
export function isRisk(tag) {
    if (typeof tag === 'string' || tag instanceof String) {
        return tag === 'RISK' || tag === 'HIGH_RISK';
    }

    if (tag && tag.flag) return isRisk(tag.flag);
    return null;
}



export function Metatag(code) {
    this.code = code;
    this.id = codeToId(code);
    this.label = codeToLabel(code);
    this.ordinal = codeToOrdinal(code);
    this.iconUrl = codeToIconUrl(code);
    this.smallIconUrl = codeToSmallIconUrl(code);
    this.opportunity = codeToOpportunity(code);

    Object.defineProperty(this, 'colour', {
        get: function() {return this.getColourViaElement(document.documentElement);}
    });

    return this;
}

Metatag.prototype.getColourViaElement = function(domElement) {
    return codeToColour(this.code, domElement);
};







// ------------ Web component support --------------------

/** <be-rpcs-icon rpcs='RISK'></be-rpcs-icon> Shows just the tag icon */
function BeRpcsIcon() {
    return Reflect.construct(HTMLElement, [], BeRpcsIcon);
}

BeRpcsIcon.prototype = Object.create(HTMLElement.prototype);

Object.defineProperty(BeRpcsIcon, 'observedAttributes', {
    get: function() { return ['code', 'full']; }
});

Object.defineProperties(BeRpcsIcon.prototype, {
    code: {
        get: function() {
            var code = this.getAttribute("code");
            return code ? code.trim().toUpperCase() : code;
        }
    },
    full: {
        get: function() {
            return this.hasAttribute("full") && this.getAttribute("full").toLowerCase() !== "false";
        }
    }
});

BeRpcsIcon.prototype.render = function() {
    if (this.isConnected) {
        var classes = "be-rpcs be-rpcs--" + this.code.toLowerCase();

        if (this.full) {
            classes += " be-rpcs--full";
        }

        this.innerHTML = "<span class='" + classes + "'><span class='be-rpcs__label'></span></span>";
    }
};

// Respond to attribute changes.
BeRpcsIcon.prototype.attributeChangedCallback = function(attr, oldValue, newValue) {
    this.render();
};

BeRpcsIcon.prototype.connectedCallback = function() {
    this.render();
};


// ----------------------------------------------------------------------------

if (typeof customElements !== "undefined") {
    customElements.define('be-rpcs-icon', BeRpcsIcon);
}


