import moment from "moment";

export default function beefRenderComparePredictedD3(rawData, negativeSvg, neutralSvg, positiveSvg, netSvg, options) {
    options = options || {};
    var yTickFormat = options.yTickFormat || function(d) { return d; };
    var margin = { top: 30, right: 90, bottom: 35, left: 60 };
    var showPercentages = true; //options.showPercentages;
    if (showPercentages) margin.left -= 15;

    // A random string is appended to elements that use an id.
    var randomString = generateRandomString();

    rawData.mentions.forEach(function(d) {
        d.published = moment(new Date(d.published)).startOf("day").format("YYYY-MM-DD");
    });

    var dates = generateDates(rawData);
    var seriesData = toSeriesData(dates, rawData.mentions);

    d3.select(negativeSvg).attr("class", "negative");
    d3.select(neutralSvg).attr("class", "neutral");
    d3.select(positiveSvg).attr("class", "positive");
    d3.select(netSvg).attr("class", "net");

    var yAxisLabel = showPercentages ? "%" : "Count";
    drawLineChart(negativeSvg, "negative", "Negative Sentiment", yAxisLabel, [seriesData[0], seriesData[4]]);
    drawLineChart(neutralSvg, "neutral", "Neutral Sentiment", yAxisLabel, [seriesData[1], seriesData[5]]);
    drawLineChart(positiveSvg, "positive", "Positive Sentiment", yAxisLabel, [seriesData[2], seriesData[6]]);
    drawLineChart(netSvg, "net", "Net Sentiment", yAxisLabel, [seriesData[3], seriesData[7]]);

    function drawLineChart(svgNode, sentiment, title, label, seriesData) {

        var yDomain = [
            Math.min(
                d3.min(seriesData[0].values, function(d) { return d.value; }),
                d3.min(seriesData[1].values, function(d) { return d.value; })),
            Math.max(
                d3.max(seriesData[0].values, function(d) { return d.value; }),
                d3.max(seriesData[1].values, function(d) { return d.value; }))
        ];

        // always include zero
        if (yDomain[0] > 0) yDomain[0] = 0;
        if (yDomain[1] < 0) yDomain[1] = 0;

        var box = svgNode.getBoundingClientRect();
        var svg = d3.select(svgNode);
        var svgWidth = box.width;
        var svgHeight = box.height;
        var width = svgWidth - margin.left - margin.right;
        var height = svgHeight - margin.top - margin.bottom;
        var xScale = d3.scaleOrdinal()
            .rangeRoundBands([0, width], .0)
            .domain(dates);
        var yScale = d3.scaleLinear()
            .range([height, 0])
            .domain(yDomain);
        svg.append("text")
            .text(label)
            .attr("class", "data-label")
            .attr("transform", "translate(19, " + (margin.top + height / 2) + ") rotate(-90)");
        svg.append("text")
            .attr("class", "title")
            .text(title)
            .attr("transform", "translate(" + [margin.left + width / 2, margin.top / 2 + 6] + ")");

        var chartGroup = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        chartGroup.append("rect")
            .attr("class", "background")
            .attr("width", width)
            .attr("height", height);

        var line = d3.line()
            .x(function(d) { return xScale(d.published) + xScale.bandwidth() / 2; })
            .y(function(d) { return yScale(d.value); })
            .interpolate("linear");
        chartGroup.selectAll(".series")
            .data(seriesData)
            .enter().append("g")
            .attr("class", function (d, i) {
                return "series " + (i == 0 ? "verified" : "predicted");
            })
            .append("path")
            .attr("class", "line")
            .attr("d", function(d) { return line(d.values); })
            .style("stroke-dasharray", function(d, i) {
                return i > 0 ? "3, 3" : "";
            });

        var yAxis = d3.svg.axis()
            .orient("left")
            .scale(yScale)
            .ticks(5)
            .tickFormat(yTickFormat)
            .outerTickSize(0)
            .tickSizeInner(-width);
        chartGroup.append("g")
            .attr("class", "axis y-axis")
            .call(yAxis);

        var xAxis = d3.svg.axis()
            .orient("bottom")
            .scale(xScale)
            .tickSize(0)
            .tickSizeInner(-height)
            .tickFormat(function(d) { return d.slice(5); });
        var xAxisSvg = chartGroup.append("g")
            .attr("class", "axis x-axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);
        xAxisSvg.selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "0px")
            .attr("dy", "10px")
            .attr("transform", "rotate(-45)");

        var legendTransform = [width + margin.left + 10, margin.top + height / 2 - 20];
        var legend = svg.append("g")
            .attr("class", "legend")
            .attr("transform", "translate(" + legendTransform + ")");

        ["Crowd", "Machine"].forEach(function(d, i) {
            var y = i * 20;
            legend.append("rect")
                .attr("class", "marker " + sentiment + " " + d.toLowerCase())
                .attr("width", 10)
                .attr("height", 10)
                .attr("y", y)
                .attr("rx", d == "Crowd" ? 2 : 0)
                .attr("ry", d == "Crowd" ? 2 : 0);
            legend.append("text")
                .text(d)
                .attr("dx", 20)
                .attr("dy", 10 + y)
                .attr("fill", "black");
        });
    }

    function toSeriesData(dates, mentions) {
        var datesToIndex = {};
        dates.forEach(function(date, i) {
            datesToIndex[date] = i;
        });
        var labelToIndex = {negative: 0, neutral: 1, positive: 2};
        var series = [];
        var names = [
            "Verified Negative", "Verified Neutral", "Verified Positive", "Verified Net",
            "Machine Negative", "Machine Neutral", "Machine Positive", "Machine Net"
        ];
        names.forEach(function(name) {
            series.push({
                id: name.toLowerCase().replace(" ", "-") + "-" + randomString,
                name: name,
                values: dates.map(function(date) {
                    return { value: 0, published: date };
                })
            })
        });
        mentions.forEach(function(m) {
            var i = datesToIndex[m.published];
            var verifiedIndex = labelToIndex[m.true_label];
            series[verifiedIndex].values[i].value += 1;
            var predictedIndex = labelToIndex[m.predicted_label] + names.length / 2;
            series[predictedIndex].values[i].value += 1;
        });

        var crowdNeg = series[0].values;
        var crowdNeut = series[1].values;
        var crowdPos = series[2].values;
        var crowdNet = series[3].values;
        var predNeg = series[names.length / 2].values;
        var predNeut = series[1 + names.length / 2].values;
        var predPos = series[2 + names.length / 2].values;
        var predNet = series[3 + names.length / 2].values;
        for (var i = 0; i < dates.length; ++i) {
            crowdNet[i].value = crowdPos[i].value - crowdNeg[i].value;
            predNet[i].value = predPos[i].value - predNeg[i].value;

            if (!showPercentages) continue;

            var tot = crowdNeg[i].value + crowdNeut[i].value + crowdPos[i].value;
            crowdNeg[i].value = -p(crowdNeg[i].value, tot);
            crowdNeut[i].value = p(crowdNeut[i].value, tot);
            crowdPos[i].value = p(crowdPos[i].value, tot);
            crowdNet[i].value = p(crowdNet[i].value, tot);

            tot = predNeg[i].value + predNeut[i].value + predPos[i].value;
            predNeg[i].value = -p(predNeg[i].value, tot);
            predNeut[i].value = p(predNeut[i].value, tot);
            predPos[i].value = p(predPos[i].value, tot);
            predNet[i].value = p(predNet[i].value, tot);
        }
        return series;
    }

    function p(num, denom) {
        return denom == 0 ? 0 : num * 100 / denom;
    }

    function generateDates(rawData) {
        var extent = d3.extent(rawData.mentions, function(d) {
            return d.published;
        });
        var format = d3.utcFormat("%Y-%m-%d");
        var start = format.parse(extent[0]);
        var end = format.parse(extent[1]);
        end.setDate(end.getDate() + 1);
        var dates = d3.time.scale.utc().domain([start, end]).ticks(d3.time.days, 1);
        return dates.map(function(d) {
            return format(d);
        });
    }

    function generateRandomString() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
}
