import { render, staticRenderFns } from "./ExploreTopics.vue?vue&type=template&id=843dcdea&scoped=true"
import script from "./ExploreTopics.vue?vue&type=script&lang=js"
export * from "./ExploreTopics.vue?vue&type=script&lang=js"
import style0 from "./ExploreTopics.vue?vue&type=style&index=0&id=843dcdea&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "843dcdea",
  null
  
)

export default component.exports