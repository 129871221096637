/**
 * Edit mention filter string. This chooses the basic filter editor if possible, otherwise the advanced editor is
 * used.
 */

Beef.module("Filter").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.Layout.extend({
        attributes: { class: "row-fluid" },

        template: require("@/dashboards/filter/Filter.handlebars"),

        regions: {
            editorRegion: "> div"
        },

        modelEvents: {
            "filter:change": "updateEditor",
            "change": "onChange"
        },

        initialize: function(options) {
            this.cache = options.cache
            this.model.set('filter', this.model.get('allBrandFilter') || this.model.get('filter'));
        },

        onRender: function() {
            this.updateEditor();
        },

        updateEditor: function(ed) {
            var that = this;
            var accountCode = this.model.getAncestorProperty('accountCode');
            var filter = this.model.get('filter');
            var editor = this.editorRegion.currentView;
            var warning = null, error = null;
            var editorType = null;
            if (!editor && ed != "advanced") {
                editor = new Beef.BasicFilter.View({
                    model: new Beef.BasicFilter.Model({previewBrand: this.model.get('previewBrand')}),
                    cache: this.cache,
                    noPublished: this.options.noPublished,
                    noTrash: this.options.noTrash,
                    noOts: this.options.noOts,
                    noEngagement: this.options.noEngagement,
                    noReach: this.options.noReach,
                    noReplyCount: this.options.noReplyCount,
                    noReplyOrderByAuthor: this.options.noReplyOrderByAuthor,
                    noReshareCount: this.options.noReshareCount,
                    noSample: this.options.noSample,
                    noReshareOf: this.options.noReshareOf,
                    noConversation: this.options.noConversation,
                    noInteraction: this.options.noInteraction,
                    noReplyTo: this.options.noReplyTo,
                    noRace: this.options.noRace,
                    brand: this.options.brand,
                    previewBrandWarning: this.options.previewBrandWarning,
                    noToggle: this.options.noToggle
                });
                editor.on("navigated", function() {
                    // the user has clicked a link and navigated elsewhere in the app so close the dialog we are in
                    that.$el.closest(".dialog").find(".btn.cancel").click();
                });
                editor.model.accountCode = accountCode;
                editor.model.set('filter', filter);
                var err = editor.getError();
                if (err) {
                    if (err == "complex") {
                        warning = "This filter is too complex to edit using the basic editor. If you are not sure how " +
                            "to edit it please contact DataEQ support.";
                    } else {
                        error = err;
                    }
                }
                editorType = "basic";
            }
            if (!editor || warning || error) {
                // give the model the filter now or the validation throws it out if it is invalid
                editor = new Beef.AdvancedFilter.View({model: new Beef.AdvancedFilter.Model({
                    filter: filter, warning: warning, msg: !(error || warning) }), cache:this.cache, noToggle: this.options.noToggle});
                editor.model.accountCode = accountCode;
                // wait until the view has been rendered before setting filter or validation errors are not
                // reflected in the view
                setTimeout(function(){
                    editor.model.set({filter: filter});
                });
                editorType = "advanced";
            }
            if (this.editorRegion.currentView != editor) {
                editor.model.on('change:filter', function(){
                    that.model.set('filter', editor.model.get('filter'));
                });
                editor.model.bind('validated', function(isValid, model, errors) {
                    that.model.trigger('validated', isValid, that.model, errors);
                });
                editor.on("changeEditor", function(ed) {
                    this.changeEditor(ed);
                }.bind(this));
                this.editorRegion.show(editor);
                this.trigger("editor-changed", editorType);
            }
        },

        changeEditor: function(ed) {
            this.editorRegion.reset();
            this.updateEditor(ed);
        },

        onChange: function() {
            // trying to listen to previewBrand:change in modelEvents doesn't seem to work?
            if (this.model.changed.previewBrand !== undefined) {
                var editor = this.editorRegion.currentView;
                if (editor) editor.model.set("previewBrand", this.model.get('previewBrand'));
            }
        }
    });

});