<template>
    <div class="search-profile">
        <h4>Search for the <i :class="getProfileIcon(profileType)"/> {{ prettyProfileType(profileType) }} you would like to add</h4>

        <slot name="description"></slot>

        <div class="search-profile__container">
            <div class="search-profile__details">
                Enter search details
                <input style="width: 85%" type="text" :placeholder="searchPlaceholder ? searchPlaceholder : 'Profile name'" v-model="searchHandle" @keydown.enter="search()"/>
                <be-button @click="search" :disabled="searchHandle === '' || searching"><i class="icon-search"/>Search for {{ prettyProfileType(profileType) }}</be-button>
            </div>
            <div class="search-profile__divider"></div>
            <div class="search-profile__results">
                <loading-message style="margin-top: 0" v-if="searching" message="Searching..."/>
                <div v-else>
                    Search Results
                    <online-profile class="search-profile__profile" v-if="newProfileData.publicProfile" :profile="newProfileData.publicProfile"/>
                    <p class="search-profile__search-error" v-if="searchError">
                        {{ searchError }}
                    </p>
                </div>
            </div>
        </div>

        <div v-if="newProfileData.publicProfile && newProfileData.publicProfile.existing" class="search-profile__error-message">
            <i class="symbol-warning"></i>
            <p>
                {{ newProfileData.publicProfile.existing.deleted ? `This profile has already been added to account ${account.name} but has been deleted. To undelete the profile, edit the profile and click "Undelete".` :
                `This profile has already been added to account ${account.name}. You are only able to add profiles that have not been added to the account yet.`}}
            </p>
        </div>
    </div>
</template>

<script>
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import {beef} from "@/store/Services";
import {extractHandleId, getProfileIcon, prettyProfileType, profileTypes} from "@/setup/profiles/ProfileUtils";
import BeButton from "@/components/buttons/BeButton";
import LoadingMessage from "@/components/LoadingMessage";
import VuexStore from "@/store/vuex/VuexStore";
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
    name: "SearchProfile",
    store: VuexStore,
    components: {LoadingMessage, BeButton, OnlineProfile},
    props: {
        profileType: {
            required: true,
            type: String
        },
        extractHandleId: {
            default: true,
            type: Boolean
        },
        searchPlaceholder: String
    },

    data: function() {
        return {
            searching: false,
            searchHandle: "",
            searchError: "",
            profileTypes: profileTypes
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapState('profiles', ['newProfileData']),
        ...mapGetters('profiles', ['handleIdToProfile'])
    },

    methods: {
        ...mapMutations('profiles', ['setNewPublicProfile']),
        getProfileIcon,
        prettyProfileType,

        async search() {
            try {
                this.searching = true;
                this.profileExists = false;
                this.setNewPublicProfile(null);

                let lookup = this.extractHandleId ? extractHandleId(this.searchHandle) : this.searchHandle;

                let response = await beef.get(`/api/online-profile/validate?code=${this.account.code}&type=${this.profileType}&handle=${lookup}`);

                // if response data contains "message" field, the profile was not found.
                if (response.data.message) {
                    this.setNewPublicProfile(null);
                    this.searchError = response.data.message;
                } else {
                    this.searchError = "";

                    let profile = response.data;

                    profile.media = null;
                    profile.tagIds = [];
                    profile.relevant = false;
                    profile.description = response.data.description ??= "";
                    profile.type = this.profileType;

                    this.setNewPublicProfile(profile);
                }
            } catch(e) {
                console.error(`An error occurred while searching for profile ${this.searchHandle}: `, e);

                this.setNewPublicProfile(null);
                this.searchError = "An error occurred while searching";
            } finally {
                this.searching = false;
            }
        },
    }
}
</script>

<style scoped lang="sass">

.search-profile

    h4
        margin-bottom: 20px

    p
        color: var(--be-colour-text-dark)

    &__container
        display: flex
        background: #444
        border: 1px solid #272727
        padding: 10px
        margin-top: 20px
        margin-bottom: 30px
        border-radius: 4px

    &__search-error
        color: var(--be-colour-warning) !important
        word-break: break-word

    &__error-message
        color: var(--be-colour-warning)
        display: flex
        align-items: center

        i
            margin-right: 10px

        p
            margin: 0

    &__details
        display: flex
        flex-direction: column
        row-gap: 10px
        width: 50%

    &__divider
        margin: -10px 0
        border-right: 1px dashed #272727

    &__results
        display: flex
        flex-direction: column
        row-gap: 10px
        width: 50%
        padding-left: 10px

    &__profile
        font-size: 14px
        margin-top: 5px

        ::v-deep img
            width: 50px
            height: 50px


</style>