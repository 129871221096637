<template>
    <add-profile-dialog ref="addProfileDialog"
                        :profile-type="profileTypes.trustpilot"
                        :enable-next-btn="enableNext"
                        :next-btn-text="nextBtnText"
                        :show-progress-bar="!!selectedProfileType"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-prev-btn="!saving && curStage > 1"
                        :show-next-btn="!saving"
                        @close="$emit('close')"
                        @prev="previous"
                        @next="next">
        <template #workflow>
            <keep-alive>
                <search-profile search-placeholder="Trustpilot site URL" :extract-handle-id="false" :profile-type="profileTypes.trustpilot" v-if="!saving && curStage === addProfileStages.trustpilot.public.SEARCH_PROFILE">
                    <template #description>
                        <p>Adding a trustpilot site will allow you to collect trustpilot reviews.</p>
                    </template>
                </search-profile>

                <edit-public-profile v-if="!saving && curStage === addProfileStages.trustpilot.public.EDIT_PROFILE"/>

                <saving-item v-if="saving" :saving-message="saveMessage" :save-complete="saveComplete" :save-complete-message="saveCompleteMessage" :save-error="saveError" :save-error-message="saveErrorMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more Trustpilot sites</h4>
                    </template>
                </saving-item>
            </keep-alive>
        </template>
    </add-profile-dialog>
</template>

<script>
import VuexStore from "@/store/vuex/VuexStore";
import SearchProfile from "@/setup/profiles/add-profile/SearchProfile";
import EditPublicProfile from "@/setup/profiles/add-profile/EditPublicProfile";
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import SavingItem from "@/setup/SavingItem";


export default {
    name: "TrustPilotAddProfileDialog",
    store: VuexStore,
    components: {
        SavingItem,
        AddProfileDialog,
        EditPublicProfile, SearchProfile},
    mixins: [addProfileMixins],

    data: function () {
        return {
            curStage: 1,
            totalStages: 1,
            selectedProfileType: null,
        }
    },

    created() {
        this.totalStages = Object.keys(this.addProfileStages.trustpilot.public).length;
    },

    computed: {
        enableNext() {
            // If we are on the edit stage, we only allow saving if the profile has a category set
            if (this.curStage === this.addProfileStages.trustpilot.public.EDIT_PROFILE) {
                return !!this.newProfileData.publicProfile?.media
            }
            // If we are on the search stage, we only enable next if the profile is not currently in the account (check "existing" field)
            if (this.curStage === this.addProfileStages.trustpilot.public.SEARCH_PROFILE) {
                return !this.newProfileData.publicProfile?.existing && !!this.newProfileData.publicProfile;
            }

            return !!this.newProfileData.publicProfile;
        },

        nextBtnText() {
            // if we are on the final stage, show "Save" instead of "Next"
            return this.curStage === this.totalStages - 1 ? "Save" : "Next";
        }
    },

    methods: {
        next() {
            this.curStage++;

            if (this.curStage === this.totalStages) this.savePublicProfile();
        },

        previous() {
            this.curStage--;

            if (this.curStage === 0) {
                this.curStage = 1;
            }
        },


        reset() {
            this.curStage = 1;
            this.saveComplete = false;
            this.saving = false;
            this.clearNewProfiles();
        }
    }
}
</script>

<style scoped lang="sass">

</style>