<template>
    <div class="be-calendar-range">
        <div>
            <slot name="in-last-message">
                <p>Include mentions published in the last</p>
            </slot>
            <div class="be-calendar-range__selectors">
                <be-button class="be-calendar-range__button"
                           @click="$emit('input', 'TODAY')"
                           tooltip="Select mentions from today"
                           :active="value.toLowerCase() === 'today'">Today</be-button>

                <be-button v-if="allowHours"
                           class="be-calendar-range__button"
                           @click="$emit('input', '24HOURS')"
                           tooltip="Select mentions from the last 24 hours"
                           :active="value.toLowerCase() === '24hours' || value.toLowerCase() === 'twenty_four_hrs'">24 Hours</be-button>

                <be-button class="be-calendar-range__button"
                           @click="$emit('input', 'WEEK')"
                           tooltip="Select mentions from the last week"
                           :active="value.toLowerCase() === 'week'">Week</be-button>

                <be-button class="be-calendar-range__button"
                           @click="$emit('input', 'MONTH')"
                           tooltip="Select mentions from the last month"
                           :active="value.toLowerCase() === 'month'">Month</be-button>

                <be-button class="be-calendar-range__button"
                           @click="$emit('input', 'QUARTER')"
                           tooltip="Select mentions from the last quarter"
                           :active="value.toLowerCase() === 'quarter'">Quarter</be-button>

                <be-button class="be-calendar-range__button"
                           @click="$emit('input', 'YEAR')"
                           tooltip="Select mentions from the last year"
                           :active="value.toLowerCase() === 'year'">Year</be-button>

                <be-button class="be-calendar-range__button"
                           @click="updateRange()"
                           tooltip="Select mentions from a custom date range"
                           :active="isRange">Custom</be-button>
            </div>

        </div>

        <p v-if="value.toLowerCase() === 'today'">
            Today, {{moment().format("D MMMM, YYYY")}}
        </p>
        <p v-else-if="value.toLowerCase() === '24hours' || value.toLowerCase() === 'twenty_four_hrs'">
            Exactly 24 hours ago until now (24 hours)
        </p>
        <p v-else-if="duration">
            {{startDateTimeText}} to {{endDateTimeText}}
            (<deq-number :number="duration"/>
            {{ formatPlural(duration, 'day') }})
        </p>


        <div class="be-calendar-range__calendars">
            <date-picker class="be-calendar-range__picker" :value="start" :range-end="end" @input="updateRange($event)">
                <template #title>
                    Start date
                </template>
            </date-picker>
            <date-picker class="be-calendar-range__picker" :value="end" :range-end="start" @input="updateEnd($event)">
                <template #title>
                    End date
                </template>
            </date-picker>
        </div>


        <div v-if="allowTime" class="deq-reset">
            <be-button link @click="timeOpen = !timeOpen">Time <collapsable-caret :collapsed="!timeOpen"/></be-button>
            <div v-if="timeOpen">
                <div class="be-calendar-range__times">
                    <label>
                        From
                        <tooltip-component>
                            <input type="text"
                                   placeholder="from"
                                   :value="startHoursText"
                                   @input="fromKeyup($event, 'start')"
                                   @invalid="showInvalid($event)">
                            <template #tooltip>
                                Type in the starting hour for your filter. Use a 24 hour format.
                            </template>
                        </tooltip-component>
                    </label>

                    <span>
                        on <span class="deq-callout">{{ startDateText }}</span>
                    </span>

                    <label for="to-time">
                        to
                        <tooltip-component>
                            <input type="text"
                                   placeholder="time"
                                   :value="endHoursText"
                                   @input="fromKeyup($event, 'end')"
                                   @invalid="showInvalid($event)">
                            <template #tooltip>
                                Type in the ending time for your filter (not inclusive). Use a 24 hour format.
                            </template>
                        </tooltip-component>
                    </label>

                    <span>
                        on <span class="deq-callout">{{ endDateText }}</span>
                    </span>
                </div><div class="span5 error-message">
            </div>
            </div>
        </div>

    </div>
</template>


<script>

import DatePicker from "@/components/pickers/dates/DatePicker";
import moment from "moment";
import BeButton from "@/components/buttons/BeButton";
import {formatPlural} from "@/app/utils/Format";
import DeqNumber from "@/components/formatters/DeqNumber";
import CollapsableCaret from "@/components/CollapsableCaret.vue";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";

const FORMATS = ['YYYY-MM-DD', 'YYYY-MM-DD HH:mm', 'YYYY/MM/DD', 'YYYY/MM/DD HH:mm'];
const HOUR_VARIANTS = [ "HH:mm", "hh:mm a" ];

export default {
    components: {TooltipComponent, CollapsableCaret, DeqNumber, BeButton, DatePicker},

    props: {
        value: String,
        allowHours: {
            type: Boolean,
            default: false
        },
        allowTime: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            start: null,
            end: null,

            timeOpen: false,
            messageOpen: false,
        }
    },

    computed: {
        isRange() {
            const index = this.value?.indexOf('-');
            const NO_HOURS_INDEX = 10;
            const WITH_HOURS_INDEX = 16;
            return index === NO_HOURS_INDEX || index === WITH_HOURS_INDEX;
        },

        duration() {
            if (this.start && this.end) {
                return moment.duration(this.end.diff(this.start)).asDays();
            }
            return null;
        },

        timeFormat() {
            let format = "D MMMM, YYYY";
            if (this.allowTime) format += ", HH:mm";
            return format;
        },

        startDateTimeText() {
            if (!this.start) return "";
            return this.start.format(this.timeFormat)
        },

        startDateText() {
            if (!this.start) return "";
            return this.start.format("D MMMM, YYYY");
        },

        startHoursText() {
            if (!this.start) return "";
            return this.start.format("HH:mm");
        },

        endDateTimeText() {
            if (!this.end) return "";
            return this.end.format(this.timeFormat);
        },

        endDateText() {
            if (!this.end) return "";
            return this.end.format("D MMMM, YYYY");
        },

        endHoursText() {
            if (!this.end) return "";
            return this.end.format("HH:mm");
        },
    },

    watch: {
        value() {
            this.setupValues();
        }
    },

    created() {
        if (!this.value) this.$emit('input', 'WEEK');
        this.setupValues();
    },

    methods: {
        moment,
        formatPlural,

        setupValues() {
            this.start = null;
            this.end = null;

            if (this.isRange) {
                const split = this.value.split('-');
                this.start = moment(split[0], FORMATS);
                this.end = moment(split[1], FORMATS);

                const hasHours = split[0].includes(' ') || split[1].includes(' ');

                if (!this.allowTime || !hasHours) {
                    this.start = this.start.clone().startOf('day');
                    this.end = this.end.clone().endOf('day');
                }

                return;
            }

            switch (this.value.toLowerCase()) {
                case 'today':
                    this.end = moment().endOf('day');
                    this.start = moment().startOf('day');
                    return;
                case '24hours':
                case 'twenty_four_hrs':
                    this.end = moment();
                    this.start = this.end.clone().subtract(24, 'hours');
                    return;
                case 'week':
                    this.end = moment().endOf('day');
                    this.start = moment().subtract(1, 'week').startOf('day');
                    return;
                case 'month':
                    this.end = moment().endOf('day');
                    this.start = moment().subtract(1, 'month').startOf('day');
                    return;
                case 'quarter':
                    this.end = moment().endOf('day');
                    this.start = moment().subtract(3, 'months').startOf('day');
                    return;
                case 'year':
                    this.end = moment().endOf('day');
                    this.start = moment().subtract(1, 'year').startOf('day');
                    return;
                case 'custom':
                    this.updateRange(this.start, this.end);
                    return;
                default:
                    console.error(`Unable to handle date value: [${this.value}]`);
                    return;
            }


        },

        fromKeyup(event, date) {
            const value = event.target?.value;

            const time = moment(value, HOUR_VARIANTS, true);
            if (!time.isValid()) {
                event.target.setCustomValidity(
                    "Fill in the <strong>From</strong> and <strong>To</strong> time fields " +
                    "using the 24 hour time format. " +
                    "Some examples: <strong>01:10</strong> and <strong>13:10</strong>");
                event.target.reportValidity();
            } else {
                event.target.setCustomValidity("");
                this.cancelInvalid();

                const newStart = this.start.clone();
                const newEnd = this.end.clone();

                if (date === "start") {
                    newStart.set('hours', time.hours());
                    newStart.set('minutes', time.minutes());
                } else if (date === "end") {
                    newEnd.set('hours', time.hours());
                    newEnd.set('minutes', time.minutes());
                } else {
                    throw new Error("Unrecognised date position: " + date);
                }

                this.updateRange(newStart, newEnd);
            }
        },

        showInvalid(event) {
            event.preventDefault();
            if (this.messageOpen) return;
            if (this.errorTimeout) return;

            this.errorTimeout = setTimeout(() => {
                this.errorTimeout = null;
                const message = event.target.validationMessage;
                if (!message) {
                    this.messageOpen = false;
                    return;
                }

                notifyUserOfError(event.target.validationMessage,
                    true,
                    true,
                    () => {
                        // Don't alert of the error too quickly after it was dismissed.
                        setTimeout(() => this.messageOpen = false, 5_000);
                    });
            }, 3_000);

            this.messageOpen = true;
        },

        cancelInvalid() {
            if (this.errorTimeout) {
                try {
                    clearTimeout(this.errorTimeout);
                } catch (e) {
                    console.warn("Unable to clear error timeout:", e);
                }
                this.errorTimeout = null;
                this.messageOpen = false;
            }
        },

        updateEnd(end) {
            if (this.allowTime) end += " 23:59";
            this.updateRange(null, end);
        },

        updateRange(start, end) {
            let s = moment(start ?? this.start, FORMATS);
            let e = moment(end ?? this.end, FORMATS);

            // Shift the date that wasn't set (indicated by the null argument)
            if (end && e.isBefore(s)) s = e.clone();
            if (start && e.isBefore(s)) e = s.clone();

            let format = "YYYY/MM/DD";
            if (this.allowTime && (s.hours() !== 0 || s.minutes() !== 0 || e.hours() !== 23 || e.minutes() !== 59)) {
                format = 'YYYY/MM/DD HH:mm';
            }

            this.$emit('input', `${s.format(format)}-${e.format(format)}`);
        },
    }
};

</script>

<style scoped lang="sass">

p
    margin: 0.5rem 0

.be-calendar-range
    color: var(--be-colour-text-dark)

.be-calendar-range__calendars
    display: flex
    box-sizing: border-box

.be-calendar-range__picker + .be-calendar-range__picker
    margin-left: 10px


.be-calendar-range__selectors
    display: flex

    .be-calendar-range__button
        &:not(:last-of-type)
            &::v-deep .btn
                border-top-right-radius: 0
                border-bottom-right-radius: 0

    .be-calendar-range__button + .be-calendar-range__button
        &::v-deep .btn
            border-top-left-radius: 0
            border-bottom-left-radius: 0


.be-calendar-range__times
    padding-inline: 1rem

    display: grid
    grid-template-columns: auto 8ch 1fr
    column-gap: 1rem

    input
        width: unset
        text-align: center

    label
        grid-column: span 2
        text-align: right

        display: grid
        grid-template-columns: subgrid


</style>