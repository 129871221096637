/**
 * Common functionality for pickers that wrap two other pickers, one for items to include and one for items to
 * exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */

import {splitAtSpaces} from "@/app/utils/StringUtils";

Beef.module("IncExPicker").addInitializer(function(startupOptions) {

    this.View = Beef.Picker.View.extend({
        attributes: { class: "inc-ex-picker" },

        initialize: function() {
            var view = this.options.view;
            this.model.accountCode = view.model.getAncestorProperty('accountCode');
            this.cache = view.cache = view.cache || {};
            // share cache with owning view so items are cached across invocations
        },

        onFirstRender: function() {
            if (this.attachPickers) this.attachPickers();
            this.initialFocus();
        },

        initialFocus: function() {
            setTimeout(function(){ $($("input", this.$el).first()).focus() }.bind(this));
        },

        onClose: function(ev) {
            if (this.popup) this.popup.close();
        },

        mousedown: function(ev) {
            if ($(ev.target).closest(".tag-input").length > 0) {
                Beef.Picker.ignoreNextBlur = true; // stop our popup from closing when our inputs get focus
            } else {
                ev.preventDefault();    // this stops stuff on the picker from getting focus
                if (this.popup) this.popup.hide();  // hide language picker - it won't see the event to hide itself
            }
        },

        setSelection: function(values) {
            if (!Array.isArray(values)) values = values ? splitAtSpaces(values) : [];
            var attrs = {};
            var inc = [], ex = [], and = false;
            for (var i = 0; i < values.length; i++) {
                var v = values[i];
                if (v && v.length > 0) {
                    if (v == "&") and = true;
                    else if (this.isSpecial(v)) attrs[v] = true;
                    else if (v.charAt(0) == '-') ex.push(v.substring(1));
                    else inc.push(v);
                }
            }
            attrs["inc"] = inc.join(" ");
            attrs["ex"] = ex.join(" ");
            attrs["and"] = and;
            this.model.clear({silent: true});
            this.model.set(attrs);
        },

        isSpecial: function(code) {
            return false;
        },

        getSelection: function() {
            var sel = Beef.Picker.View.prototype.getSelection.call(this);
            if (this.model.get('and')) sel.push("&");
            var s = this.model.get("inc");
            var i, codes, code;
            if (s && (s = s.trim()).length > 0) {
                codes = splitAtSpaces(s);
                for (i = 0; i < codes.length; i++) {
                    code = codes[i];
                    if (sel.indexOf(code) < 0) sel.push(code);
                }
            }
            s = this.model.get("ex");
            if (s && (s = s.trim()).length > 0) {
                codes = splitAtSpaces(s);
                for (i = 0; i < codes.length; i++) {
                    code = "-" + codes[i];
                    if (sel.indexOf(code) < 0) sel.push(code);
                }
            }
            return sel;
        },

        keyup: function(ev) {
            // don't let escape go up and close whatever dialog we might be in .. just close our popup instead
            if (ev.keyCode == 27 && this.parentPopup) {
                ev.stopPropagation();
                this.restoreFocusOnClose = true;
                this.parentPopup.hide();
            }
        }
    });
});
