/**
 * Opens a mailto form for contacting DataEQ support. The parameters are optional and will
 * revert to a default value if not provided.
 */
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import NewInAnalyseDialog from "@/app/framework/dialogs/motd/NewInAnalyseDialog";
import VuexStore from "@/store/vuex/VuexStore";
import {account} from "@/app/utils/Account";

export function mailSupport(target, subject, body) {
    subject = subject || "Direct app support query for account " + account().code;
    subject = encodeURIComponent(subject);
    body = body ? encodeURIComponent(body) : "";

    window.open('mailto:support@dataeq.com?subject=' + subject + '&body=' + body);
}



let haveShownNew = false;

/**
 * Shows the message of the day (motd) dialog box.
 */
export function showNewInAnalyseDialog() {
    showDialogComponent(NewInAnalyseDialog);
}

/**
 * Shows the message of the day (motd) dialog box if the user has not seen
 * the most recent motd.
 */
export function showNewInAnalyseDialogIfNotSeen() {
    if (haveShownNew) return;
    haveShownNew = true;

    const user = VuexStore.state.user;
    const lastMotd = VuexStore.state.motd.at(-1);
    const key = `safe-cache:motd:${user.id}:` + lastMotd?.id ?? lastMotd;
    if (localStorage.getItem(key)) return;
    localStorage.setItem(key, "1");
    showNewInAnalyseDialog();
}