/**
 * View to edit an array of V4 'content matches' or 'authorbio matches' expressions.
 */

import _ from 'underscore';

Beef.module("TextSearch").addInitializer(function(startupOptions) {

    this.createView = function(filterModel, attribute) {

        var phrases = filterModel.get(attribute);
        if (!phrases || phrases.length == 0) phrases = [''];

        var models = [];
        for (var i = 0; i < phrases.length; i++) models.push({phrase: phrases[i]});
        var collection = new Backbone.Collection(models);
        collection.on("change", function(){
            var a = [];
            for (var i = 0; i < collection.size(); i++) {
                var phrase = collection.at(i).get('phrase');
                if (phrase) a.push(phrase);
            }
            if (a.length > 0) filterModel.set(attribute, a);
            else filterModel.unset(attribute);
        });

        return new CompositeView({collection: collection, escapeApostrophe: true});
    };

    this.createPhraseGenView = function(collection) {
        return new CompositeView({collection: collection, forPhraseGen: true, escapeApostrophe: false});
    };

    var ItemView = Beef.BoundItemView.extend({

        template: require("@/dashboards/filter/TextSearchItemView.handlebars"),

        className: "phrase-item row-fluid",

        bindings: function() {
            return {
                phrase: { converter: Beef.PhraseTermPicker.createConverter(""), elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            Beef.PhraseTermPicker.attach(this, ".phrase", "phrase", {forContentMatch: !this.options.forPhraseGen, escapeApostrophe: !!this.options.escapeApostrophe});
        }
    });

    var CompositeView = Backbone.Marionette.CompositeView.extend({

        template: require("@/dashboards/filter/TextSearch.handlebars"),

        className: "text-search",

        itemViewContainer: ".items",

        itemView: ItemView,

        itemViewOptions: function() {
            return  { forPhraseGen: this.options.forPhraseGen, escapeApostrophe: this.options.escapeApostrophe }
        },

        events: {
            "click .add-phrase": "addPhrase",
            "click .generate": "generate"
        },

        templateHelpers: function() {
            return {
                forPhraseGen: this.options.forPhraseGen
            }
        },

        addPhrase: function(ev) {
            ev.preventDefault();
            this.collection.add({phrase: ''});
            var that = this;
            setTimeout(function() {
                that.$(".items .phrase-item:last-child input").focus();
            });
        },

        generate: function(ev) {
            ev.preventDefault();
            var that = this;
            Beef.PhraseGen.show(function(phrases) {
                phrases = phrases?.filter(function(p) {
                    return !that.collection.find(function(m) { return p == m.get('phrase') })
                }) ?? [];
                if (phrases.length == 0) return;
                for (var i = 0; i < that.collection.length; ) {
                    var m = that.collection.at(i);
                    if (!m.get('phrase')) that.collection.remove(m);
                    else ++i;
                }
                for (i = 0; i < phrases.length; i++) that.collection.add({phrase: phrases[i]});
                that.collection.trigger("change");
            });
        }
    });

});