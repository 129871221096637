/**
 * A module for showing error and confirmation messages for historical search events.
 */
Beef.module("HistoricalSearch.Message").addInitializer(function() {

    this.onPhraseError = function() {
        showError("Your phrase and filter will result in a query with too many characters. " +
        "Try to remove one of the existing phrases before you add a new phrase, or " +
        "create a new search.");
    };

    this.onFilterError = function() {
        showError("Your filter will result in a query with too many characters. " +
        "Try to shorten the filter.");
    };

    this.onStartError = function() {
        showError("An error occurred while trying to start your search. " +
        "Please try again and make sure you have at least one phrase in your search setup.");
    };

    this.onNetworkError = function() {
        showError("An error occurred while trying to communicate with DataEQ, please try again.");
    };

    this.onUnknownError = function() {
        showError("An unknown error occurred, please refresh the page and try again.");
    };

    function showError(message) {
        var PopupView = Backbone.Marionette.ItemView.extend({
            template: require("@/historical-search/popup/HistoricalSearchPopup.handlebars"),
            attributes: { class: "historical-search dialog" },
            modelEvents: { "change": "render" }
        });
        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
        popup.setTarget($("#content"));
        popup.show(new PopupView({ model: new Backbone.Model({
            title: "Search Error",
            message: message,
            appendSupport: true
        })}));
    }
    this.showError = showError;

    function showConfirm(callback, verb, noun) {
        var PopupView = Backbone.Marionette.ItemView.extend({
            template: require("@/historical-search/popup/HistoricalSearchConfirmation.handlebars"),
            attributes: { class: "historical-search dialog" },
            modelEvents: { "change": "render" }
        });
        var view = new PopupView({ model: new Backbone.Model({ verb: verb, noun: noun }) });
        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
        popup.setTarget($("#content"));
        popup.show(view);
        $(view.el).find(".btn.continue").click(function(ev) {
            ev.preventDefault(); // avoid a page refresh
            popup.close();
            callback();
        });
        $(view.el).find(".btn.cancel").click(function(ev) {
            ev.preventDefault(); // avoid a page refresh
            popup.close();
        });
    }
    this.showConfirm = showConfirm;
});
