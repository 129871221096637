/**
 * Select email addresses. This will store previously used email addresses in local storage,
 * and will also provide easy access to users.
 */
import {deprecatedFetchUsers} from "@/data/DeprecatedBeefCache";
import _ from 'underscore';
import {account} from "@/app/utils/Account";
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";
import {splitQuotedString} from "@/app/utils/StringUtils";

Beef.module("EmailPicker").addInitializer(function(startupOptions) {


    var fetchUsers = function(viewOrCode, cb, cache) {
        if (!cache) cache = viewOrCode.cache = viewOrCode.cache || {};

        var general = Beef.generalData(account().code);
        var current = general.get('locally-used-emails') || [];

        function onComplete(data) {
            var results = {};
            _(data).each(function(value, key) {
                results[key] = value;
            });
            _(current).each(function (d) {
                results["'" + d + "'"] = d;
            });
            cb(results);
        }

        if (cache.users) {
            onComplete(cache.users);
        } else {
            deprecatedFetchUsers(viewOrCode, cb, cache, function(data){
                onComplete(cb(cache.users = data));
            });
        }
    };

    var addNew = function(item) {
        var general = Beef.generalData(account().code);
        var current = general.get('locally-used-emails') || [];

        if (!_(current).contains(item) && Beef.EmailPicker.validate(item)) {
            current.push(item);
            general.set('locally-used-emails', current);
        }
    };

    /**
     * Returns true iff the given email address is valid. If the email contains angle brackets only the part between
     * the angle brackets is checked.
     */
    this.validate = function(email) {
        var emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;
        var i = email.indexOf('<');
        if (i >= 0) {
            var j = email.indexOf('>', i + 1);
            if (j > i) email = email.substring(i + 1, j);
        }
        return email.match(emailRegex) != null;
    };

    this.items = function(q, view, callback) {
        fetchUsers(view, callback);
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "email-picker auto-complete-picker" },
        items: this.items
    });

    this.createConverterFactory = function(placeholder, options) {
        return function(view) {
            var conv;
            return function(direction, value) {
                if (value && value.length > 0) {
                    var binding = this;
                    if (conv) return conv.call(binding, direction, value);
                    fetchUsers(view, function(items) {
                        conv = createTagConverter(Object.assign({
                            items: items,
                            placeholder: placeholder,
                            acceptNew: true,
                            validate: Beef.EmailPicker.validate,
                            newCallback: function(value) { addNew(value) }
                        }, options));
                        conv.call(binding, direction, value);
                    });
                } else {
                    if (placeholder) $(this.boundEls[0]).html(toPlaceholderHTML(placeholder));
                    else $(this.boundEls[0]).text("");
                }
                return value;
            }
        }
    };

    this.converterFactory = this.createConverterFactory(null);

    /**
     * Attach ane email picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        options = Object.assign({
            mustSelectFromItems: false,
            encloseNewItemsInQuotes: !options.valueIsArray,
            splitter: function(value) { return splitQuotedString(value); }
        }, options || {});
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});