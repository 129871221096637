import ObjectStore from './ObjectStore'
import { mash } from '../Services'

/**
 * Fetches and caches online profiles for the selected account.
 * @deprecated
 */
export default class ProfileStore extends ObjectStore {

    constructor() {
        super();
        this.refreshCall = null
    }

    /**
     * Refresh our profiles. Returns promise. This does not start a new fetch if one is already in progress.
     */
    refresh(soft) {
        if (this.refreshCall) return this.refreshCall;
        if (this.list && soft) return Promise.resolve(this.list);
        return this.refreshCall = mash.get("/rest/accounts/" + this.accountCode + "/online-profiles")
            .then(res => {
                this.list = [];
                res.data.forEach(o => this.list.push(this.add(o)));
                return this.list;
            }).finally(() => this.refreshCall = null);
    }

    async getAllProfiles() {
        await this.refresh(false);
        return this.list;
    }

    /**
     * Lookup a profile by id. If we don't have it already a placeholder profile is returned and it is fetched in the
     * background.
     */
    get(id) {
        let profile = this.byId[id];
        if (!profile) {
            profile = {
                _loading: true,
                id: id,
                handle: "" + id,
                handleId: "" + id,
                name: "" + id,
                authorId: "" + id,
                type: null,
                pictureLink: null,
                authorized: null,
            };
            this.byId[profile.id] = profile;
            this.refresh();
        }
        return profile
    }

    getByHandleId(handleId) {
        if (!this.list) return null;
        return this.list.find(profile => profile.handleId === handleId);
    }
}