<template>
    <dialog-box @close="$emit('close')" modal no-dim width="600px"
                title="Share Dashboard Section" stop-mousedown>
        <div class="share-section-dialog__container" style="padding: 10px">
            <p>Copy the link below to share section <span class="share-section-dialog__bold-text">{{sectionName}}</span> of dashboard
                <span class="share-section-dialog__bold-text">{{dashboardName}}</span>. This is not a public link.</p>

            <textarea class="share-section-dialog__text-input dark-scrollbars dark-scrollbars--visible" readonly v-model="sectionLink"></textarea>
            <be-button class="share-section-dialog__copy-link-button" @click.prevent="copyToClipboard" link>Copy to clipboard</be-button>
        </div>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import {notifyWithText} from "@/app/framework/notifications/Notifications";
import BeButton from "@/components/buttons/BeButton";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

export default {
    name: "ShareSectionDialog",
    store: VuexStore,
    components: {BeButton, DialogBox},

    props: {
        sectionId: {
            required: true,
            type: Number
        },
        sectionName: {
            required: true,
            type: String
        },
        dashboardId: {
            required: true,
            type: Number
        },
        dashboardName: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            campaign: "powerpoint" // TODO: cater for multiple campaigns
        }
    },

    computed: {
        ...mapState(['account']),

        sectionLink() {
            return `${window.location.origin}/${this.account.code}/dashboards/${this.dashboardId}?campaign=${this.campaign}#section-content-${this.sectionId}`;
        }
    },

    methods: {
        copyToClipboard() {
            let copyText = document.querySelector(".share-section-dialog__text-input");
            copyText.select();
            document.execCommand("copy");
            notifyWithText("Section link copied to clipboard", null, "<i class='icon-clipboard'></i>");
        }
    }
}
</script>

<style scoped lang="sass">

.share-section-dialog

    &__container
        padding: 5px
        margin-bottom: 20px

        p
            color: var(--be-colour-text-dark)

    &__bold-text
        font-weight: bold
        font-style: italic
        color: white

    &__text-input
        width: 100%
        min-height: 30px
        margin-bottom: 10px
        padding: 1px
        background: #262727

    &__copy-link-button

        ::v-deep .btn
            padding: 0


</style>