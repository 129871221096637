<template>
    <section v-once class="be-sidebar-spacer">

    </section>
</template>

<script>
export default {

}
</script>


<style scoped lang="sass">
.be-sidebar-spacer
    margin: 0
    padding: 5px
    background: var(--colour-background-black)
</style>