/**
 * A popup for starting mention retrieval for a search job.
 */

import _ from 'underscore';
import moment from "moment";
import {parsePhraseString} from "@/app/utils/Phrases";

Beef.module("HistoricalSearch.RetrievalSetupPopup").addInitializer(function() {

    var PAGE = { BRAND_SELECT: "brand-select", PREVIEW: "preview", SYNC_AND_DATE_SELECT: "sync-and-date-select",
        CONFIRM: "confirm"
    };
    var steps = [ PAGE.BRAND_SELECT, PAGE.PREVIEW,  PAGE.SYNC_AND_DATE_SELECT, PAGE.CONFIRM ];

    this.Model = Backbone.Model.extend({});

    this.View = Beef.HistoricalSearch.FilterPopup.View.extend({
        template: require("@/historical-search/popup/setup/retrieval/RetrievalSetupPopup.handlebars"),

        events: Object.assign({}, Beef.HistoricalSearch.FilterPopup.View.prototype.events, {
            "click .dialog-button-bar .next": "next",
            "click .dialog-button-bar .previous": "previous",
            "click .root-brand": "showBrandDropdown"
        }),

        templateHelpers: function() {
            var step = this.model.get("step");
            var loading = this.model.get("loading");
            var cannotContinue = loading || !this.model.get("brand") || this.model.get("errorMessage");
            if (steps[step] === PAGE.SYNC_AND_DATE_SELECT) {
                cannotContinue = cannotContinue || (this.model.get("volume") < 1)
            }
            return {
                filter: this.getPublishedFilter(),
                volumeLabel: this.createVolumeLabel(this.model.get("volume")),
                brandTitle: "Open the brand setup in a new tab",
                brandUrl: this.getBrandUrl(),
                PAGE: PAGE,
                page: steps[step],
                previousDisabled: (step === 0) || loading,
                nextDisabled: (step === (steps.length - 1)) || cannotContinue,
                okDisabled: (step < (steps.length - 1)) || cannotContinue
            };
        },

        formatPublishedDate: function(date) {
            return moment(date).utc().format("MMMM Do YYYY") + " UTC";
        },

        getPublishedFilter: function() {
            return "published between " +
                this.formatPublishedDate(this.model.get("selectedStartDate")) +
                " and " +
                this.formatPublishedDate(this.model.get("selectedEndDate"));
        },

        getBrandUrl: function() {
            var brand = this.model.get("brand");
            if (!brand) {
                return "";
            }
            return "/" + this.model.get("accountCode") + "/setup/brands/" + brand.id;
        },

        onInitialize: function() {
            this.loadBrands();
            this.runStep(0);
        },

        /**
         * Fetches the brands of the current account
         */
        loadBrands: function() {
            var that = this;
            this.model.set("loading", true);
            var success = function(brands) {
                that.model.set("loading", false);
                that.model.set("brands", brands);
                // update current brand
                var brand = that.model.get("brand");
                if (brand) {
                    brand = _.find(brands, function(b) {
                        return b.id == brand.id;
                    });
                    that.model.set("brand", brand);
                }
            };
            var error = function() {
                console.error(status + " " + error);
                Beef.Popup.closePopups(that);
                Beef.BoundItemView.prototype.close.call(that);
                Beef.HistoricalSearch.Message.onNetworkError();
            };
            Beef.Sync.mashGET("/accounts/" + this.model.get("accountCode") + "/brands", null, success, error);
        },

        /**
         * Starts mention retrieval. A new search job is created if the published filter of the search
         * job differs from the mention retrieval date range. Results in a NOP if the button is disabled.
         */
        ok: function(ev) {
            if (!ev.target.classList.contains("disabled")) {
                $("#retrieve-data-button").addClass("disabled");
                var code = this.model.get("accountCode");
                var job = this.model.get("job");
                var start = this.model.get("selectedStartDate");
                var end = this.model.get("selectedEndDate");
                var that = this;
                var onRetrieveSuccess = function(updatedJob) {
                    if (updatedJob) {
                        if (that.model.get("createdJob")) {
                            that.model.set("createdJob", updatedJob, { silent: true });
                        } else {
                            that.model.set("job", updatedJob, { silent: true });
                        }
                        that.trigger("retrievalStarted");
                    } else {
                        Beef.HistoricalSearch.Message.onUnknownError();
                    }
                };
                var onCreateSuccess = function(createdJob) {
                    if (createdJob) {
                        that.model.set("createdJob", createdJob, { silent: true });
                        Beef.SearchJobSync.retrieve(code, createdJob, onRetrieveSuccess, onError);
                    } else {
                        Beef.HistoricalSearch.Message.onUnknownError();
                    }
                };
                var onError =  function() {
                    Beef.HistoricalSearch.Message.onNetworkError();
                };
                var dto = Beef.SearchJobSync.createDTO(job);
                // create a new job is the date range is different. must use published date since the filter does not
                // support granularity up to seconds. the new job will have volume data populated from its parent search.
                if (Beef.EditSearchJob.dateToPublished(dto.searchRangeStartDate, dto.searchRangeEndDate) !== Beef.EditSearchJob.dateToPublished(start, end)) {
                    var url = Beef.SearchJobSync.getSyncUrl(code);
                    dto.searchRangeStartDate = Beef.SearchJobSync.toISOStringNoMillis(start);
                    dto.searchRangeEndDate = Beef.SearchJobSync.toISOStringNoMillis(end);
                    dto.name = this.createDTOName(job.name, dto.searchRangeStartDate, dto.searchRangeEndDate);
                    dto.copyFromId = job.id;
                    Beef.Sync.broccoliPOST(url, dto, onCreateSuccess, onError);
                } else {
                    Beef.SearchJobSync.retrieve(code, this.model.get("job"), onRetrieveSuccess, onError);
                }
                this.close(true);
            }
        },

        /**
         * Creates a search job name from the name of another search. A start and end date is appended to the name. If
         * there already is a date range appended to the given name, the date range will be replaced if it has the
         * same date format.
         */
        createDTOName: function(name, startDate, endDate) {
            name = name || "";
            var regex = /\(\d{4}-\d{2}-\d{2}\sto\s\d{4}-\d{2}-\d{2}\)$/;
            var cleaned = name.trim().replace(regex, "").trim();
            var start = moment(startDate).utc().format("YYYY-MM-DD");
            var end = moment(endDate).utc().format("YYYY-MM-DD");
            var dateSuffix = "(" + start + " to " + end + ")";
            return cleaned + " " + dateSuffix;
        },

        /**
         * Renders and executes the next step in the sequence. Results in a NOP if the button is disabled.
         */
        next: function(ev) {
            if (!ev.target.classList.contains("disabled")) {
                var step = Math.min(steps.length - 1, 1  + this.model.get("step"));
                this.model.set("step", step);
                this.runStep(step);
            }
        },

        /**
         * Renders and executes the previous step in the sequence. Results in a NOP if the button is disabled.
         */
        previous: function(ev) {
            if (!ev.target.classList.contains("disabled")) {
                this.model.set({ errorMessage: null }, { silent: true });
                var step = Math.max(0, this.model.get("step") - 1);
                this.model.set("step", step);
                this.runStep(step);
            }
        },

        /**
         * Initialises and prepares a step in the retrieval setup. Results in a NOP if the specified step is invalid.
         * @param {Number} stepIndex The step to run.
         */
        runStep: function(stepIndex) {
            var brand = this.model.get("brand");
            switch(steps[stepIndex]) {
                case PAGE.BRAND_SELECT:
                    break;
                case PAGE.PREVIEW:
                    var phrases = extractBrandPhrases([brand]).map(function(p) { return p.q });
                    var preview = buildMergePreview(phrases, this.model.get("job").searchPhrases);
                    this.model.set("preview", preview);
                    break;
                case PAGE.SYNC_AND_DATE_SELECT:
                    this.addPhrasesToBrand(this.model.get("accountCode"), brand.id, this.model.get("preview").toAdd);
                    break;
                default:
                    break;
            }
        },

        onRender: function() {
            if (steps[this.model.get("step")] === PAGE.SYNC_AND_DATE_SELECT) {
                this.showFilterRegion();
            }
        },

        /**
         * Updates a brand in mash with one or more phrases. Results in a NOP if there are no search phrases to add.
         * @param code The code of the account phrases will be added to.
         * @param brandId The id of the brand to which the phrases should be added.
         * @param {String[]} searchPhrases An array of search phrases to add.
         */
        addPhrasesToBrand: function(code, brandId, searchPhrases) {
            if (searchPhrases && searchPhrases.length > 0) {
                var that = this;
                this.model.set("loading", true);
                var phrases = searchPhrases.map(function(query) {
                    return { active: true, deleted: false, query: query };
                });
                var dto = { brandId: brandId, phrases: phrases };
                Beef.Sync.mashPOST("/accounts/" + code + "/phrases/batch", dto, function() {
                    that.loadBrands.bind(that)();
                }).error(function(){
                    that.model.set("loading", false);
                    that.model.set("errorMessage", "There was a problem communicating with DataEQ.");
                });
            }
        },

        /**
         * Closes all popups that were created from the current popup.
         */
        closePopups: function() {
            this.closeBrandDropdown();
            this.closeCalendarAndRender();
        },

        /**
         * Closes the dropdown for selecting a brand.
         */
        closeBrandDropdown: function() {
            if (this.brandDropdown) {
                this.brandDropdown.close();
            }
        },

        /**
         * Opens a dropdown for selecting a brand. Results in a NOP if brands are still being loaded.
         */
        showBrandDropdown: function(ev) {
            ev.stopPropagation();
            this.closeBrandDropdown();
            if (this.model.get("loading")) {
                return;
            }
            var that = this;
            var job = this.model.get("job");
            var target = $(this.el).find(".controls .root-brand")[0];
            var model = new Backbone.Model({ brands: this.model.get("brands") });
            //noinspection JSUnusedGlobalSymbols
            this.brandDropdown = Beef.MiniMenu.show({
                template: require("@/historical-search/popup/setup/retrieval/BrandDropdown.handlebars"),
                dropdown: true,
                offsets: { right: -1, top: 10 },
                model: model,
                object: {
                    setBrand: function($t) {
                        var id = +$t.data("id");
                        var brand = _.find(that.model.get("brands"), function(b) {
                            return b.id === id;
                        });
                        if (brand) {
                            that.model.set({
                                brand: brand,
                                hasFilterWarning: (brand.mentionFilter && !Beef.SearchJobFilter.compareFilterAttributes(job.searchFilter, brand.mentionFilter))
                            });
                            that.brandDropdown.close();
                        } else {
                            console.error("Cannot set brand: no brand with id " + id + " found");
                        }
                    }
                },
                target: target,
                onRender: function() {
                    this.$el.toggleClass("mini-menu-selectable");
                    this.$el.addClass("brand-dropdown");
                }
            });
        }
    });

    /**
     * Extracts phrases for each brand and its children, and collates the phrases into a list.
     * @param {Array} brands A list of brands.
     * @return {Array} A list of phrase strings.
     */
    function extractBrandPhrases(brands) {
        var phrases = [];
        brands = brands || [];
        brands.forEach(function(brand) {
            if (brand) {
                (brand.phrases || []).forEach(function(p) {
                    phrases.push(p);
                });
                extractBrandPhrases(brand.children).forEach(function(p) {
                    phrases.push(p);
                });
            }
        });
        return phrases;
    }

    /**
     * Returns a preview of the result of adding an array of phrases to another array of phrases.
     * The returned preview is a map containing the phrases from the "to add" array split into two
     * lists. One containing phrases unique to the array and another containing all the duplicates.
     * Comparisons between phrases only consider positive terms and ignore negative terms.
     * @return {{ toAdd: Array, ignored: Array }}
     */
    function buildMergePreview(base, phrasesToAdd) {
        function reducePhrase(phrase) {
            var positive;
            var parsed = parsePhraseString(phrase);
            if (parsed && (parsed.length > 0)) {
                positive = parsed?.filter(function(term) {
                    return !term.startsWith('-');
                }) ?? [];
            }
            return (positive && (positive.length > 0)) ? positive.sort().join(' ') : "";
        }
        var toAdd = (phrasesToAdd || []).slice(0);
        var toAddReduced = toAdd.map(function(phrase) {
            return reducePhrase(phrase);
        });
        var phrasesReduced = base.map(function(phrase) {
            return reducePhrase(phrase);
        });
        var i = -1;
        var ignored = [];
        while ((toAddReduced.length > 0) && (++i < phrasesReduced.length)) {
            var index = toAddReduced.indexOf(phrasesReduced[i]);
            if (index >= 0) {
                toAddReduced.splice(index, 1);
                ignored.push(toAdd.splice(index, 1)[0]);
            }
        }
        return { toAdd: toAdd, ignored: ignored };
    }
    this.buildMergePreview = buildMergePreview;
});
