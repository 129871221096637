import Vue from "vue";
import Vuex from "vuex";
import {mash} from "@/store/Services";

Vue.use(Vuex);

let refreshPromise = null;

export default {
    namespaced: true,

    state: () => ({
        /**
         * These are the segments available to be added to brands in the account,
         * and not those that are already on the account.
         */
        availableSegments: null
    }),

    mutations: {
        setAvailableSegments: (state, value) => {
            state.availableSegments = value
        }
    },

    getters: {

    },

    actions: {
        async refreshAvailableSegments({state, commit}, forceRefresh) {
            forceRefresh ??= false;

            if (refreshPromise) return refreshPromise;
            if (state.availableSegments && !forceRefresh) return;

            try {
                refreshPromise = mash.get("/rest/topics/trees/list?type=SEGMENT_LIST");
                const res = await refreshPromise;
                await commit('setAvailableSegments', res.data);
            } catch(e) {
                console.error(e);
            } finally {
                refreshPromise = null;
            }
        },

        async updateSegmentsForBrand({rootState}, {brandId, dto}) {
            return mash.put("/rest/accounts/" + rootState.account.code + "/brands/" + brandId, dto);
        }
    }
};

export const BrandSegmentMutations = {

    setBrandSegments: (state, {brandId, segmentListIds, segmentLists, activeSegmentListIds}) => {
        let brand = state.rootBrands.find(b => b.id === brandId)
        if (brand) {
            brand.segmentListIds = segmentListIds;
            brand.segmentLists = segmentLists;
            brand.activeSegmentListIds = activeSegmentListIds;
        }
    }

};