import Vue from 'vue'
import {shouldTurnOffAdmin} from "@/app/Features";
import TagStore from './TagStore'
import RuleStore from './RuleStore'
import BrandStore from './BrandStore'
import ProfileStore from "./ProfileStore";
import TeamStore from "./TeamStore";
import VuexStore from "@/store/vuex/VuexStore";


/** @deprecated */
let deprecatedUserObject = { }; // The current user
/** @deprecated */
let deprecatedTagsStore = new TagStore();
/** @deprecated */
let rules = new RuleStore();
/** @deprecated */
let deprecatedBrandsStore = new BrandStore();
/** @deprecated */
let profiles = new ProfileStore();
/** @deprecated */
let teams = new TeamStore();

export { deprecatedTagsStore, rules, deprecatedBrandsStore, deprecatedUserObject, profiles, teams }

export default function(startupOptions) {
    let account = Object.assign({}, {
        showTraditionalAVE: startupOptions.account.hasFeedBroadcast || startupOptions.account.hasFeedPrint
    }, startupOptions.account)
    if (startupOptions.dev) account.dev = true;

    Object.assign(deprecatedUserObject, startupOptions.user)
    // Whether the user was originally an admin or not when we got their settings.
    deprecatedUserObject.originalAdmin = startupOptions.user.admin
    deprecatedUserObject.admin = shouldTurnOffAdmin(deprecatedUserObject.id) ? false : deprecatedUserObject.admin

    VuexStore.commit('setUser', {...deprecatedUserObject});
    VuexStore.commit('setAccount', account);
    VuexStore.commit('locations/initialiseLocations', startupOptions.locations);
    VuexStore.commit("setMotd", startupOptions.motd);

    // Ensure that we have the full account data from mash.
    VuexStore.dispatch("refreshAccount", true).catch(e => console.error("Unable to read account data", e));

    deprecatedTagsStore.init(startupOptions);
    rules.init(startupOptions);
    deprecatedBrandsStore.init(startupOptions);
    profiles.init(startupOptions);
    teams.init(startupOptions);

    // make all the stores reactive
    const vm = new Vue({ data() { return { tags: deprecatedTagsStore, rules, brands: deprecatedBrandsStore, user: deprecatedUserObject, profiles, teams } }});
    deprecatedTagsStore = vm.tags;
    rules = vm.rules;
    deprecatedBrandsStore = vm.brands;
    deprecatedUserObject = vm.user;
    profiles = vm.profiles;
    teams = vm.teams;
}