var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Save all images";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li><a id=\"edit\" data-method=\"edit\" title=\"Edit the dashboard's settings\">Edit…</a></li>\n<li><a id=\"copy\" data-method=\"copy\" title=\"Create a new dashboard that is a copy of this one\">Duplicate</a></li>\n<li><a id=\"refresh\" data-method=\"refresh\" title=\"Refresh all the sections and metrics in this dashboard\">Refresh</a></li>\n<li class=\"divider\"></li>\n<li><a id=\"addSection\" data-method=\"addSection\">Add a section…</a></li>\n<li><a id=\"subscribeToNotification\" data-method=\"subscribeToNotification\"\n       title=\"Subscribe yourself to an email sending out this dashboard\">Subscribe to notification</a></li>\n<li><a id=\"editNotification\" class=\"disabled\" data-method=\"editNotification\"\n       title=\"Edit the email that sends out this dashboard\">Edit notification</a></li>\n<li class=\"divider\"></li>\n<li><a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sectionImagesLink") : depth0), depth0))
    + ".pdf\" target=\"_blank\" title=\"Save each section as a page in a pdf\">Download as PDF</a></li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Save all charts on the dashboard as PNG images in a zip file","method":"saveImages"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":12,"column":139}}})) != null ? stack1 : "")
    + "</li>\n<li><a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sectionImagesLink") : depth0), depth0))
    + ".zip\" target=\"_blank\" title=\"Save each section as image in a zip\">Section images (zip)</a></li>\n<li class=\"divider\"></li>\n<li><a id=\"archive\" data-method=\"archive\" title=\"Archive this dashboard\">Archive…</a></li>\n<li><a id=\"delete\" data-method=\"delete\" title=\"Remove this dashboard\">Delete…</a></li>\n";
},"useData":true});