var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/ifcond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"phrases") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"==",1,{"name":"ifcond","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    <table class=\"table table-condensed table-bordered table-hover\">\n        <tbody class=\"phrases level0 branch-collapsed\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"phrases") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <p>A phrase will be added to your search:</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>A total of "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"phrases") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " phrases will be added to your search:</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n                <td colspan=\"1\" class=\"phrase\">\n                    <div class=\"inner\">\n                        <div class=\"q\">"
    + container.escapeExpression(__default(require("../../../../helpers/renderPhrase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":13,"column":39},"end":{"line":13,"column":60}}}))
    + "</div>\n                    </div>\n                </td>\n            </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <p>No phrases will be added.</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"phrases") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":22,"column":7}}})) != null ? stack1 : "");
},"useData":true});