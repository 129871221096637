<template>
    <div>
        <label v-for="cb in options" :key="cb.id" class="checkbox" :title="cb.description">
            <input type="checkbox" @change="onChange($event, cb)" :checked="isChecked(cb.id) && !cb.disabled" :disabled="cb.disabled">
            <slot :item="cb">{{cb.label || cb.name}}</slot>
        </label>
    </div>
</template>

<script>

    /**
     * List of checkboxes, value is array of the ids of the selected options.
     */
    export default {
        name: "CheckboxList",

        props: {
            value: Array,
            options: Array  // [{ id: .., label: .., exclusive: .. }, ...]
        },

        computed: {
            exclusiveIds() {
                let set = new Set()
                this.options.forEach(o => {
                    if (o.exclusive) set.add(o.id)
                })
                return set.size ? set : null
            }
        },

        methods: {
            onChange(ev, cb) {
                let id = cb.id
                if (ev.target.checked) {
                    if (!this.isChecked(id)) {
                        let a
                        if (cb.exclusive) a = []
                        else if (this.exclusiveIds) a = this.value.filter(v => !this.exclusiveIds.has(v))
                        else a = this.value.slice()
                        a.push(id)
                        this.$emit("input", a)
                        this.$emit("check", cb)
                    }
                } else if (this.value) {
                    let i = this.value.indexOf(id)
                    if (i >= 0) {
                        let a = this.value.slice()
                        a.splice(i, 1)
                        this.$emit("input", a)
                        this.$emit('uncheck', cb)
                    }
                }
            },

            isChecked(id) {
                return this.value && this.value.indexOf(id) >= 0
            }
        }
    }
</script>

<style scoped>

</style>