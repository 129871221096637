<template>
    <section>
        <overview-title>Keyword trends for </overview-title>
        <keyword-trends v-if="brand" :brand="brand"/>
    </section>
</template>


<script>
import KeywordTrends from "@/app/toplevel/explore/overview/KeywordTrends";
import {mapState} from "vuex";
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";

export default {
    components: {OverviewTitle, KeywordTrends},
    computed: {
        ...mapState('explorePanel', ['brand'])
    }
}
</script>


<style scoped lang="sass">

</style>