import moment from "moment"

/**
 * Assorted date formats etc as used by Fantastic chart
 */
export default {

    dateFormats: {
        auto: {
            name: "Auto",
            fn : function(d, i, model) {
                var m = moment(d);
                let coarseness
                if (typeof model === "string") {
                    coarseness = model
                } else {
                    let fc = model.view?.container?.currentView
                    coarseness = fc?.getEffectiveCourseness()
                }

                switch (coarseness) {
                    case 'hourly':
                        if (m.date() === 1 && m.hour() === 0 && m.minute() === 0 || i === 0) return m.format("MMM D, HH:mm");
                        if (m.day() === 1 && m.hour() === 0) return m.format("ddd DD, HH:mm");
                        if (m.hour() === 0) return m.format("ddd D, HH:mm");
                        return m.format("HH:mm");
                    case 'daily':
                        if (m.day() === 1) return m.format("MMM DD");
                        return m.format("DD");
                    case 'weekly':
                        return m.format("MMM DD")
                    case 'monthly':
                        if (m.month() === 0) return m.format("MMMM ’YY");
                        return m.format("MMMM");
                    case 'yearly':
                        return m.format("YYYY");
                }
                console.warn("No coarseness set for ", d, "Maybe not a date?");
                return "" + d;
            }
        },

        DD: { name: "29", fn: function(d) { return moment(d).format("DD") } },
        MMM_DD: { name: "Feb 29", fn: function(d) { return moment(d).format("MMM DD") } },
        ddd_DD: { name: "Sat 29", fn: function(d) { return moment(d).format("ddd DD") } },
        MMM: { name: "Feb", fn: function(d) { return moment(d).format("MMM") } },
        MMM_YY: { name: "Feb YY", fn: function(d) { return moment(d).format("MMM YY") } },
        hidden:  { name: "Hidden", fn: function(d) { return '\u00A0' }}
    },

    dateGroup: {
        hourly: { id: "hourly", name: "Hour" },
        daily: { id: "daily", name: "Day" },
        weekly: { id: "weekly", name: "Week" },
        monthly: { id: "monthly", name: "Month" },
        yearly: { id: "yearly", name: "Year" },
        auto: { id: "auto", name: "Auto" }
    },

    labelAngles: {
        auto: { name: "Auto", value: null },
        flat: { name: "Flat", value: 0 }
    },

    dateTicks: {
        auto: { name: "Auto" },
        weekly: {
            name: "Weekly",
            fn: function(scale) { return scale.domain().filter(date => moment(date).day() === 1) }
        },
        monthly: {
            name: "Monthly",
            fn: function(scale) {
                if (this.model.get('coarseness') === "weekly") {
                    let pm = -1
                    return scale.domain().filter(date => {
                        let m = moment(date).month()
                        let keep = m !== pm
                        pm = m
                        return keep
                    })
                } else {
                    return scale.domain().filter(date => moment(date).date() === 1)
                }
            }
        },
        yearly: { name: "Yearly" }
    }
}