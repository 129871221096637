var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <th class=\"num\">Volume Limit</th>\n                <th class=\"num\" title=\"What percentage of mentions should go to the crowd?\">Sampling %</th>\n                <th class=\"num\" title=\"Assign topics to this percentage of emotive mentions\">Topic %</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\""
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias3,"editBrandCrowdSettings",{"name":"ifallowed","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":105}}})) != null ? stack1 : "")
    + "\">\n                    <td tooltip=\"Asked in the Crowd as, "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"shortName") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":32,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"_tier") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"_tier") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</td>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_hasConduct") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":42,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(alias3,{"name":"ifMashAdmin","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":67,"column":36}}})) != null ? stack1 : "")
    + "                </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "editable";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"shortName") : depth0), depth0))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                            Risk and Reputation\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_hasCx") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":28},"end":{"line":41,"column":35}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "                                Customer Experience\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <td class=\"num\">\n"
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tempVolumeLimit") : depth0),">=",0,{"name":"ifcond","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":52,"column":35}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td class=\"num\">\n"
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tempCrowdSamplePercentage") : depth0),">=",0,{"name":"ifcond","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":61,"column":35}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td class=\"num\">\n                        "
    + container.escapeExpression(__default(require("../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"crowdTopicPercentage") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":64,"column":65}}}))
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"neutralTopics") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":24},"end":{"line":65,"column":117}}})) != null ? stack1 : "")
    + "\n                    </td>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span title=\""
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tempVolumeLimit") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":47,"column":41},"end":{"line":47,"column":73}}}))
    + " until end of month then reverts to "
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"volumeLimit") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":47,"column":109},"end":{"line":47,"column":137}}}))
    + "\">\n                                <span class=\"temp-crowd\">"
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tempVolumeLimit") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":48,"column":57},"end":{"line":48,"column":89}}}))
    + "</span> ("
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"volumeLimit") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":48,"column":98},"end":{"line":48,"column":126}}}))
    + ")\n                            </span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(__default(require("../../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"volumeLimit") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":51,"column":28},"end":{"line":51,"column":56}}}))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span title=\""
    + alias2(__default(require("../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tempCrowdSamplePercentage") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":56,"column":41},"end":{"line":56,"column":87}}}))
    + " until end of month then reverts to "
    + alias2(__default(require("../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"crowdSamplePercentage") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":56,"column":123},"end":{"line":56,"column":165}}}))
    + "\">\n                                <span class=\"temp-crowd\">"
    + alias2(__default(require("../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"tempCrowdSamplePercentage") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":57,"column":57},"end":{"line":57,"column":103}}}))
    + "</span> ("
    + alias2(__default(require("../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"crowdSamplePercentage") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":57,"column":112},"end":{"line":57,"column":154}}}))
    + ")\n                            </span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(__default(require("../../helpers/formatPercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"crowdSamplePercentage") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":60,"column":28},"end":{"line":60,"column":70}}}))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "<div title=\"Neutral mentions sent for topics\">(inc neutral)</div>";
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n    <div class=\"span3\">\n        <div class=\"control-group\">\n            <label>Total volume limit</label>\n            <div class=\"field\">"
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mentionsPerMonth") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":80,"column":31},"end":{"line":80,"column":64}}}))
    + " per month</div>\n        </div>\n    </div>\n    <div class=\"span3\">\n        <div class=\"control-group\">\n            <label>Estimated mentions sent to crowd</label>\n            <div class=\"field\">"
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"crowdVerifiedMentionsPerMonth") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":86,"column":31},"end":{"line":86,"column":77}}}))
    + " per month</div>\n        </div>\n    </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\" style=\"margin-top: 20px\">\n    <div class=\"span9\">\n        This account is using the pay-per-brand model. Each brand has its own volume limit and a crowd sampling\n        percentage. If topics have been purchased then topic assignment is done on a a percentage of the\n        verified emotive mentions.\n    </div>\n</div>\n\n<div class=\"row-fluid\" style=\"margin-top: 20px\">\n    <div class=\"span9\">\n        <table>\n            <thead>\n            <tr>\n                <th>Brand</th>\n                <th>Product Tier</th>\n                <th title=\"Whether this brand shows Customer Experience or Risk and Reputation\">Solutions</th>\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(alias1,{"name":"ifMashAdmin","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":21,"column":32}}})) != null ? stack1 : "")
    + "            </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"brands") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":69,"column":21}}})) != null ? stack1 : "")
    + "            </tbody>\n        </table>\n    </div>\n</div>\n\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(alias1,{"name":"ifMashAdmin","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":0},"end":{"line":90,"column":16}}})) != null ? stack1 : "");
},"useData":true});