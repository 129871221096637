<template>
    <div>
        <section v-if="brand && brandHasTopics">
            <overview-title>Topic trends for </overview-title>
            <topic-trends v-if="brand" :brand="brand"/>
        </section>
        <section  v-else-if="brand">
            <overview-title>Topic trends for </overview-title>
            <no-topics-message>
                {{formatBrandName(brand)}} does not use topics
            </no-topics-message>
        </section>
    </div>
</template>


<script>
import TopicTrends from "@/app/toplevel/explore/overview/TopicTrends";
import {mapState} from "vuex";
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {formatBrandName} from "@/app/utils/Format";
import {showTip} from "@/app/help/tips/tips";


export default {
    components: {NoTopicsMessage, OverviewTitle, TopicTrends},

    computed: {
        ...mapState('explorePanel', [
            'brand', 'brandHasTopics'
        ])
    },

    async mounted() {
        await showTip("EXPLORE_DASHBOARD");
    },

    methods: {
        formatBrandName
    }
}
</script>


<style scoped lang="sass">

.explore-topics__dotted
    width: fit-content
    padding: 20px

</style>