/**
 * Utilities for dealing with segments and segment lists, including
 * performing unions, intersections and so on, while considering children.
 */


import { getRootBrands } from "./Brands";
import VuexStore from "@/store/vuex/VuexStore";

import {
    union as setUnion,
    intersection as setIntersection,
    difference as setDifference
} from "./Sets";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {isRisk, isService} from "@/app/utils/Metatags";

const parentCache = new Map();
const segmentCache = new Map();

/**
 * If you are filtering on segments in some way, such as trying to find CX data,
 * these additional filters ensure that the data is clean, and has mostly not been contaminated
 * by bad data copies, retrosends, account setup, and various other things that can cause bad account
 * problems.
 *
 * This should probably not be added to reputation.
 *
 * @param filter {String}
 * @returns {String}
 */
export function appendSegmentRestrictions(filter) {
    return appendFiltersReadably(filter, "reshareOf is unknown and process is verified and (media is consumer or media is press or media is directory or media is unknown)");
}

function collectBrandSegments(field) {
    const segmentIds = new Set();
    getRootBrands().forEach(function(b) {
        const ids = b[field];
        if (ids) ids.forEach(function(id) { segmentIds.add(id) })
    });
    const a = [];
    segmentIds.forEach(function(id) {
        const s = getSegmentList(id);
        if (s) a.push(s);   // we might have ids for deleted segment lists so leave those out
    });
    return a;
}

/**
 * @deprecated
 * @return {*[]}
 */
export function getSegmentListsOnBrands() {
    return collectBrandSegments('segmentListIds');
}

/**
 * @deprecated
 * @returns {*[]}
 */
function getActiveSegmentListsOnBrands() {
    return collectBrandSegments('activeSegmentListIds');
}

/**
 * @deprecated
 * @param tagId
 * @return {unknown|null}
 */
export function get(tagId) {
    if (!tagId) return null;
    tagId = Math.abs(parseInt(tagId)); // In case this is a string id, and also excluded tags.

    if (segmentCache.has(tagId)) return segmentCache.get(tagId);

    // Index is used to keep the segment order known.
    var index = 1;
    var segmentList = VuexStore.state.account.segmentLists?.find(function(s) {
        s.index = index++;
        segmentCache.set(s.id, s);
        return s.id === tagId;
    });

    if (segmentList) return segmentList;
    return VuexStore.state.account.segmentLists
               ?.flatMap(function(s) { return s.children })
               ?.find(function(c) {
                   c.index = index++;
                   c.interaction = !!c.isSecondary;
                   segmentCache.set(c.id, c);

                   return c.id === tagId;
               });

}

/**
 * For a given tag, returns the segment parent that it is a part of.
 */
export function getSegmentList(tagOrId) {
    if (!tagOrId) return null;
    if (!VuexStore.state.account.segmentLists) return null;
    if (tagOrId.namespace === 'segment_list') return tagOrId;
    if (tagOrId.id && tagOrId.namespace !== 'segment') return null;

    var id = tagOrId.id || parseInt(tagOrId);
    if (parentCache.has(id)) return parentCache.get(id);

    var parent = VuexStore.state.account.segmentLists.find(function(s) {
        if (s.id === id) return true;
        if (s.children) return s.children.find(function(c) {
            parentCache.set(c.id, s);
            return c.id === id;
        });
    });

    parentCache.set(id, parent);
    return parent;
}

export async function getActiveSegmentLists(type) {
    await VuexStore.dispatch('refreshTags');
    const idToTags = VuexStore.getters.idToTag;

    await VuexStore.dispatch('refreshBrands');
    const brands = VuexStore.state.rootBrands;
    if (!brands || !brands.length) return [];

    const unique = new Set(brands.filter(b => !b.deleted).flatMap(b => b.activeSegmentListIds || []));
    return [...unique]
        .map(id => idToTags.get(id))
        .filter(t => type ? t.segmentType && t.segmentType.id === type : true)
}

export async function getAllSegmentListsWithType(type) {
    if (!type) throw new Error("No type supplied");
    await VuexStore.dispatch('refreshBrands');
    await VuexStore.dispatch('refreshTags');
    const idToTags = VuexStore.getters.idToTag;
    const brands = VuexStore.state.rootBrands;
    if (!brands || !brands.length) return [];
    const unique = new Set(brands.filter(b => !b.deleted).flatMap(b => b.segmentListIds || []));
    return [...unique]
        .map(id => {
            const tag = idToTags.get(id);
            if (!tag) throw new Error(`Segment with id ${id} listed in a brand's 'segmentListIds' is not returned via the /tag endpoint`);
            return tag;
        })
        .filter(t => t.segmentType && t.segmentType.id === type)
}

/**
 * Determines if a tag is part of the CX solution.
 */
export function isCxSolution(tag) {
    const parent = getSegmentList(tag);
    if (!parent || !parent.segmentType) return false;
    const id = parent.segmentType.id;
    return id === "CX_LIST" || id === "CHANNEL_LIST";
}

/**
 * Determines if a tag is part of the Risk solution.
 */
export function isRiskSolution(tag) {
    const parent = getSegmentList(tag);
    return parent && parent.segmentType && parent.segmentType.id === "CONDUCT_LIST";
}

/**
 * Determines if a tag is part of the TCF / Market conduct solution introduced in Jan 2021.
 */
export function isTcfSolution(tag) {
    const parent = getSegmentList(tag);
    return parent && parent.segmentType && parent.segmentType.id === "TCF_LIST";
}

/**
 * Returns all the risk related segments in the account.
 */
export function getAllRiskSegments() {
    const segmentLists = getSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) return [];

    const children = segmentLists.flatMap(function(s) { return s.children });
    return children.filter(function(c) { return isRisk(c) })
}


export function getAllServiceSegments() {
    var segmentLists = getSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) return [];

    var children = segmentLists.flatMap(function(s) { return s.children });
    return children.filter(function(c) { return isService(c) })
}


//------------------------------------------------------

/**
 * Returns the journey / loyalty / etc segment list for
 * the customer experience solution.
 *
 * @deprecated
 */
export function getJourneySegmentList(returnAll, includeInactive) {
    returnAll ??= false;
    includeInactive ??= false;

    const segmentLists = includeInactive ? getSegmentListsOnBrands() : getActiveSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) return null;

    const journey = segmentLists.filter(function(list) {
        return list.segmentType && list.segmentType.id === "CX_LIST";
    });

    if (!journey.length) return null;

    if (returnAll) {
        journey.forEach(j => {
            j.children.forEach(function(c) {
                    c.interaction = !!c.isSecondary;
                });
        })

        return journey;
    } else {
        journey[0].children.forEach(function(c) {
            c.interaction = !!c.isSecondary;
        });

        return journey[0];
    }
}

/**
 * @deprecated
 * @returns {null|*}
 */
export function getChannelSegmentList() {
    const segmentLists = getActiveSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    const channel = segmentLists.filter(function(list) {
        return list.segmentType && list.segmentType.id === "CHANNEL_LIST";
    });

    if (!channel.length) return null;

    return channel[0];
}

/**
 * @deprecated
 * @returns {null|*}
 */
export function getRiskSegmentList() {
    const segmentLists = getActiveSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    const conduct = segmentLists.filter(function(list) {
        return list.segmentType && list.segmentType.id === "CONDUCT_LIST";
    });

    if (!conduct.length) return null;

    conduct[0].children.forEach(function(c) {
        c.interaction = !!c.isSecondary;
    });

    return conduct[0];
}

/**
 * Returns true iff the account has an active TCF_LIST segment (for our market conduct solution)
 * @returns {null|boolean}
 */
export function hasMarketConductSegmentLists() {
        const segmentLists = getActiveSegmentListsOnBrands();
        if (!segmentLists || !segmentLists.length) {
            return null;
        }

        const conduct = segmentLists.filter(function(list) {
            return list.segmentType && list.segmentType.id === "TCF_LIST";
        });

        return conduct.length > 0;
}

/**
 * Searches through market conduct segments for the given conduct segment list's parent.
 * E.g. TCF sub-categories will return TCF outcomes
 * @param childConductList - conduct list to check
 * @returns {tag} parent conduct list if the childConductList is a sub list, null otherwise
 */
export async function getConductListParent(childConductList) {
    let conductSegmentLists = await getAllMarketConductSegmentLists();
    const idToTags = VuexStore.getters.idToTag;

    // loop over conduct segment lists i.e. TCF outcomes, TCF sub-categories etc
    for (const conductList of conductSegmentLists) {
        if (conductList.id === childConductList.id) continue;

        // combine all ids of conduct segment (i.e. TCF outcome 1) children into a single list
        let conductSegmentChildren = [];
        conductList.children?.forEach(conductSegmentId => {
            let conductSegment = idToTags.get(conductSegmentId);
            if (conductSegment) {
                let children = conductSegment?.children ? conductSegment?.children : [];
                conductSegmentChildren = [...conductSegmentChildren, ...children];
            }
        });

        // If the childConductList has ANY children that are in a conduct segment's children, the conduct segment's parent is also the childConductList's parent. E.g
        // childConductList is TCF sub categories
        // TCF sub categories has children segments
        // One of those child segments (let's say TCF sub category 1A) are part of a conduct segment's (let's say TCF outcomes 1) children
        // TCF outcomes 1's parent is TCF outcomes
        // TCF sub categories is therefore a child of TCF outcomes
        for (const candidateChildConductSegment of childConductList.children) {
            if (conductSegmentChildren.includes(candidateChildConductSegment)) {
                return conductList;
            }
        }
    }

    return null;
}

/**
 * @deprecated use #getAllCxSegmentLists when possible.
 */
export function getCxSegmentListsOnBrands() {
    const segmentLists = getSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    return segmentLists.filter(list => list.segmentType && list.segmentType.id === "CX_LIST");
}

/**
 * @deprecated use #getAllChannelSegmentLists when possible.
 */
export function getChannelSegmentListsOnBrands() {
    const segmentLists = getSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    return segmentLists.filter(list => list.segmentType && list.segmentType.id === "CHANNEL_LIST");
}

/**
 * Returns true iff the account has channel list segments.
 * @returns {null|boolean}
 */
export function hasChannelSegmentLists() {
    const segmentLists = getActiveSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    const channels = segmentLists.filter(function(list) {
        return list.segmentType && list.segmentType.id === "CHANNEL_LIST";
    });

    return channels.length > 0;
}

/**
 * Returns true iff the account has cx list segments.
 * @returns {null|boolean}
 */
export function hasCxSegmentLists() {
    const segmentLists = getActiveSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    const channels = segmentLists.filter(function(list) {
        return list.segmentType && list.segmentType.id === "CX_LIST";
    });

    return channels.length > 0;
}

/**
 * @deprecated use #getAllRiskProductSegmentLists when possible.
 */
export function getRiskSegmentListsOnBrands() {
    const segmentLists = getSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    return segmentLists.filter(list => list.segmentType && list.segmentType.id === "CONDUCT_LIST");
}

/**
 * @deprecated use #getAllMarketConductSegmentLists when possible.
 */
export function getMarketConductSegmentListsOnBrands() {
    const segmentLists = getSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    return segmentLists.filter(list => list.segmentType && list.segmentType.id === "TCF_LIST");
}

/**
 * @deprecated use #getAllMarketConductSegmentLists when possible.
 */
export function getMarketConductOutcomeListsOnBrands() {
    const segmentLists = getSegmentListsOnBrands();
    if (!segmentLists || !segmentLists.length) {
        return null;
    }

    return segmentLists
        .filter(list => list.segmentType && list.segmentType.id === "TCF_LIST")
       // .filter(list => list.children?.some(c => c.type === "SegmentParent"));
}

/**
 * Returns all active and inactive segment lists on this accounts various brands.
 * @returns {Promise<Array>}
 */
export async function getAllRiskProductSegmentLists() {
    return getAllSegmentListsWithType("CONDUCT_LIST");
}

/**
 * Returns all active and inactive market conduct segment lists from this account on all its brands.
 * @returns {Promise<*[]|*[]>}
 */
export async function getAllMarketConductSegmentLists() {
    return getAllSegmentListsWithType("TCF_LIST");
}

export async function getAllCxSegmentLists() {
    return getAllSegmentListsWithType("CX_LIST");
}

export async function getAllChannelSegmentLists() {
    return getAllSegmentListsWithType("CHANNEL_LIST");
}

export async function getAllSegmentLists() {
    await VuexStore.dispatch('refreshTags');
    return VuexStore.state.tags.filter(t => t.namespace === "segment_list");
}

export async function getAllTopicTrees() {
    await VuexStore.dispatch('refreshTags');
    return VuexStore.state.tags.filter(t => t.type === "Tree");
}

/**
 * Performs an intersection between two sets of segments. These can be either inidividual
 * segment IDs are objects, as well as Arrays of IDs / segment objects.
 * It always returns an Array.
 */
export function union(lhs, rhs) {
    return setUnion(lhs, rhs, get)
        .filter(function(c) { return c.flag !== "NONE_OF_THE_ABOVE" });
}

/**
 * Calculates the intersection between two sets of segments, subtracting the rhs set from the lhs set.
 * Always returns an array. The input can be either individual segment IDs or objects, or arrays of ids / objects.
 * Takes segment children into consideration.
 */
export function difference(lhs, rhs) {
    return setDifference(lhs, rhs, get)
        .filter(function(c) { return c.flag !== "NONE_OF_THE_ABOVE" });
}

export function intersection(lhs, rhs) {
    return setIntersection(lhs, rhs, get);
}

/**
 * These are the IDs for the global Crowd segment list.
 * @type {{duplicates: number}}
 */
export const CROWD_SEGMENTS = {
    DUPLICATES: 161692,
    SEGMENT_FILTER: 140169,
    NEUTRAL_FILTER: 160387,
    TOPIC_FILTER: 169000,
    SEGMENT_SKIPPED: 169210
};