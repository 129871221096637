<template>
    <section class="topic-reference">
        <div ref="wheel" class="topic-reference_wheel topic-wheel">

        </div>
    </section>
</template>

<script>
    import beefRenderTopicWheel from '../../../dashboards/widgets/topics/TopicWheelD3';
    import { mash as mashService } from "../../../store/Services";

    /**
     * Returns reference data for showing in the topic reference tree.
     * It leaves out archived and deleted topics.
     */
    export async function getTopicReferenceData(treeId) {
        const treeRequest = await mashService.get(`/rest/topics/tree/${treeId}`);
        const tree = treeRequest.data;
        return tree.categories
            .filter(c => !c.archived && !c.deleted && c.flag !== "NONE_OF_THE_ABOVE")
            .map(c => {
                return {
                    tag: {id: c.id, name: c.name, labels: c.labels, description: c.description,
                        descriptions: c.descriptions, children: c.children},
                    size: 1, actualSize: 1,
                    children: c.topics
                        .filter(o => !o.archived && !o.deleted)
                        .map(o => {
                            return {
                                tag: {
                                    id: c.id + "_" + o.id,
                                    itemId: o.id,
                                    name: o.name,
                                    labels: o.labels,
                                    description: o.description,
                                    descriptions: o.descriptions
                                },
                                size: 1, actualSize: 1
                            }
                        })
                };
            });
    }

    export default {
        name: "TopicReference",

        props: {
            tags: Array,
            language: String
        },

        watch: {
            tags() { this.drawWheel() },
            language() { this.drawWheel() }
        },

        methods: {
            drawWheel() {
                const wheel = this.$refs.wheel;
                const tags = this.tags;

                wheel.innerHTML = '';
                if (!this.$refs || !this.$refs.wheel) return;

                const width = wheel.clientWidth,
                      height = wheel.clientHeight;

                if (tags && tags.length) {
                    const options = { owner: this, hideLegend: true, v3: true, language: this.language };
                    beefRenderTopicWheel(wheel, width, height, tags, options);
                }
            }
        }
    }
</script>


<style scoped lang="sass">
    .topic-reference
        height: var(--widget-inner-height)

    .topic-reference_wheel
        width: 100%
        height: 100%
        box-sizing: border-box
</style>