import {count} from "@/data/Grouse";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {buildBasicFilter} from "@/dashboards/filter/BasicFilter";
import {
    calculateCxStats,
    getExploreConsumerFilter,
    getExploreEnterpriseFilter,
    getExplorePressFilter
} from "@/app/toplevel/explore/overview/ExploreUtilities";
import {checkBrandsForExplore} from "@/store/vuex/dataChecks";
import {dateToEnglish} from "@/dashboards/filter/FilterToEnglish";


export const INITIAL_EXPLORE_DATE = 'MONTH';

/**
 * A general store for shared information across the Explore panel.
 */
export default {
    namespaced: true,
    state: () => ({
        accountHasSentiment: false,
        accountHasRisk: false,
        accountHasCx: false,
        accountHasConduct: false,
        accountHasTopics: false,
        accountHasCancel: false,
        accountHasPurchase: false,
        accountHasDms: false,
        brand: null,
        brandHasTopics: false,
        brandHasRisk: false,
        brandHasConduct: false,
        brandHasCx: false,
        brandHasSentiment: false,
        brandHasDms: false,
        date: INITIAL_EXPLORE_DATE,
        stats: null,
        socialNetworks: null,
        cxStats: null,
        loading: true,
        isInitialised: false,
        initialisePercent: 0,
        error: null,                        // Whether there has been an error initialising and fetching data.

        setupWarnings: null                 // A list of setup related warnings for ensuring that Explore will function correctly
    }),

    mutations: {
        setBrand(state, updates) {
            state.brand = updates.newBrand;
            state.brandHasSentiment = updates.brandHasSentiment;
            state.brandHasTopics = updates.brandHasTopics;
            state.brandHasRisk = updates.brandHasRisk;
            state.brandHasConduct = updates.brandHasConduct;
            state.brandHasCx = updates.brandHasCx;
            state.brandHasDms = updates.brandHasDms;
        },
        setDate: (state, value) => state.date = value,
        setError: (state, error) => state.error = error,
        updateStats: (state, stats) => state.stats = stats,
        setAccountHasTopics: (state, value) => state.accountHasTopics = value,
        setAccountHasSentiment: (state, value) => state.accountHasSentiment = value,
        setAccountHasRisk: (state, value) => state.accountHasRisk = value,
        setAccountHasCx: (state, value) => state.accountHasCx = value,
        setAccountHasConduct: (state, value) => state.accountHasConduct = value,
        setAccountHasCancel: (state, value) => state.accountHasCancel = value,
        setAccountHasPurchase: (state, value) => state.accountHasPurchase = value,
        setAccountHasDms: (state, value) => state.accountHasDms = value,
        setLoading: (state, value) => state.loading = !!value,
        setCxStats: (state, value) => state.cxStats = value,
        isInitialised: (state, value) => state.isInitialised = !!value,
        setInitialisedPercent: (state, value) => state.initialisePercent = Math.max(Math.min(100, value), 0),
        setSocialNetworks: (state, value) => state.socialNetworks = value,
        setSetupWarnings: (state, value) => state.setupWarnings = value
    },

    getters: {
        brandName: state => (state.brand?.shortName || state.brand?.name) ?? null,
        dateFilter: state => state.date ? buildBasicFilter({published: state.date}) : null,
        englishDate: (state, getters) => state.date ? dateToEnglish(getters.dateFilter) : null,
        pressCount: state => state.stats?.pressCount ?? null,
        enterpriseCount: state => state.stats?.enterpriseCount ?? null,
        consumerCount: state => state.stats?.consumerCount ?? null,
        overallCount: state => state.stats?.overallCount ?? null,
        overallPublic: state => state.stats?.overallPublic ?? null,
        overallDm: state => state.stats?.overallDm ?? null,
        overallOtherPrivate: state => {
            if (state.stats?.overallCount === null) return null;
            if (state.stats?.overallCount === 0) return 0;

            return state.stats.overallCount - state.stats.overallPublic - state.stats.overallDm;
        },
        totalVerified: state => state.stats?.totalVerified ?? null,
        topicLoading: state => !!state.stats?.topicLoading,
        statsLoading: state => !!state.stats?.statsLoading,
        topTopic: state => state.stats?.topTopic ?? null,


        twitterStats: state => {
            let twitter = state.socialNetworks?.filter(s => s.socialNetwork?.id === 'TWITTER') ?? [];
            return calculateNetworkStats(twitter);
        },
        instagramStats: state => {
            let instagram = state.socialNetworks?.filter(s => s.socialNetwork?.id === 'INSTAGRAM');
            return calculateNetworkStats(instagram);
        },
        facebookStats: state => {
            let fb = state.socialNetworks?.filter(s => s.socialNetwork?.id === 'FACEBOOK');
            return calculateNetworkStats(fb);
        },
        otherNetworkStats: state => {
            const ignore = new Set(['TWITTER', 'INSTAGRAM', 'FACEBOOK']);
            let other = state.socialNetworks?.filter(s => !ignore.has(s.socialNetwork?.id));
            return calculateNetworkStats(other);
        },

        // -------------------------------------
        // Filters -----------------------------

        enterpriseFilter: (state, getters) => {
            return getExploreEnterpriseFilter(state.brand.id, getters.dateFilter);
        },

        pressFilter: (state, getters) => {
            return getExplorePressFilter(state.brand.id, getters.dateFilter);
        },

        consumerFilter: (state, getters) => {
            return getExploreConsumerFilter(state.brand.id, getters.dateFilter);
        }
    },

    actions: {
        async initialise({commit, dispatch, rootState, rootGetters}) {
            try {
                const account = rootState.account;
                if (!account) {
                    console.warn("Account not yet set");
                    return;
                }

                // Let's see if we have some values cached.
                // This lets us load bits of explore quicker and smoother.
                const CACHE_KEY = `dataeq:${account.code}:explore:cache`;
                try {
                    const cacheJson = localStorage.getItem(CACHE_KEY);
                    if (cacheJson) {
                        const cache = JSON.parse(cacheJson);
                        commit('setAccountHasSentiment', !!cache.hasSentiment);
                        commit('setAccountHasTopics', !!cache.hasTopics);
                        commit('setAccountHasRisk', !!cache.hasRisk);
                        commit('setAccountHasCx', !!cache.hasCx);
                        commit('setAccountHasConduct', !!cache.hasConduct);
                        commit('setAccountHasPurchase', !!cache.hasPurchase);
                        commit('setAccountHasCancel', !!cache.hasCancel);
                        commit('setAccountHasDms', !!cache.hasDms);
                        commit('isInitialised', true);
                    }
                } catch(e) {
                    console.warn(e);
                    localStorage.removeItem(CACHE_KEY);
                }


                commit('setLoading', true);
                await Promise.all([
                    dispatch('refreshBrands', null, {root: true}),
                    dispatch('refreshTags', null, {root: true}),
                    dispatch('profiles/refreshProfiles', null, {root: true})
                ]);
                const hasTopics = rootState.rootBrands.some(b => !!b.crowdTopicPercentage);
                const hasSentiment = rootState.rootBrands.some(b => !!b.crowdSamplePercentage);

                let hasRisk = false; // A client only has risk if they have a brand on 100% sampling with a conduct_list / risk list.
                let hasCx = false;
                let hasConduct = false;
                rootState
                    .rootBrands
                    .forEach(b => {
                        hasRisk ||= b.crowdSamplePercentage === 100 && b.activeSegmentListIds?.some(id => rootGetters.idToTag.get(id)?.segmentType?.id === "CONDUCT_LIST");
                        hasCx ||= b.activeSegmentListIds?.some(id => rootGetters.idToTag.get(id)?.segmentType?.id === "CX_LIST");
                        hasConduct ||= b.activeSegmentListIds?.some(id => rootGetters.idToTag.get(id)?.segmentType?.id === "TCF_LIST");
                    });

                commit('setAccountHasSentiment', hasSentiment);
                commit('setAccountHasTopics', hasTopics);
                commit('setAccountHasRisk', hasRisk);
                commit('setAccountHasCx', hasCx);
                commit('setAccountHasConduct', hasConduct);

                const purchaseTag = rootGetters.idToTag.get(2);
                const cancelTag = rootGetters.idToTag.get(3);
                const hasPurchase = !!purchaseTag?.children?.length;
                const hasCancel = !!cancelTag?.children?.length;
                commit('setAccountHasPurchase', hasPurchase);
                commit('setAccountHasCancel', hasCancel);

                const hasDms = !!rootState.profiles.profiles?.find(p => p.directMessagesEnabled);
                commit('setAccountHasDms', hasDms);

                commit('isInitialised', true);

                try {
                    localStorage.setItem(CACHE_KEY, JSON.stringify({
                        hasSentiment: !!hasSentiment,
                        hasTopics: !!hasTopics,
                        hasCx: !!hasCx,
                        hasRisk: !!hasRisk,
                        hasConduct: !!hasConduct,
                        hasPurchase: !!hasPurchase,
                        hasCancel: !!hasCancel,
                        hasDms: !!hasDms
                    }));
                } catch (e) {
                    console.warn(e);
                    localStorage.removeItem(CACHE_KEY);
                }
            } finally {
                commit('setLoading', false);
            }
        },

        async setBrand({dispatch, commit, rootGetters}, newBrand) {
            await dispatch('profiles/refreshProfiles', null, {root: true});
            const idToTag = rootGetters.idToTag;
            const idToProfiles = rootGetters["profiles/idToProfile"];
            const update = {
                newBrand,
                brandHasSentiment: !!newBrand.crowdSamplePercentage,
                brandHasTopics: !!newBrand.crowdTopicPercentage,
                brandHasRisk: !!newBrand?.activeSegmentListIds?.some(s => idToTag.get(s)?.segmentType?.id === "CONDUCT_LIST"),
                brandHasConduct: !!newBrand?.activeSegmentListIds?.find(s => idToTag.get(s)?.segmentType?.id === "TCF_LIST"),
                brandHasCx: !!newBrand?.activeSegmentListIds?.find(s => idToTag.get(s)?.segmentType?.id === "CX_LIST"),
                brandHasDms: !![...newBrand?.supportProfileIds ?? [], ...newBrand?.otherProfileIds ?? []]
                    .some(id => id && idToProfiles.get(id)?.directMessagesEnabled)
            };
            await commit('setBrand', update);
            await dispatch('loadStats');
        },

        async setDate({dispatch, commit}, newDate) {
            await commit('setDate', newDate);
            await dispatch('loadStats');
        },

        async loadStats({commit, state, getters}) {
            if (!state.brand) return;
            if (!state.date) return;

            const initial = {
                statsLoading: true,
                pressCount: null,
                enterpriseCount: null,
                consumerCount: null,
                overallCount: null,
                overallPublic: null,
                overallDm: null,
                totalVerified: null
            };
            await commit('setError', null);
            await commit('updateStats', {...initial});
            await commit('setSocialNetworks', null);
            await commit('setCxStats', null);
            await commit('setInitialisedPercent', 0);
            const brand = state.brand;

            const dateFilter = getters.dateFilter;

            const filter = `brand isorchildof ${brand.id} and
            relevancy isnt irrelevant and
            (${dateFilter}) and
            visibility is public and
            reshareof is unknown and
            replyto is unknown`;

            let haveError = false;
            const finalStats = {
                topicLoading: !!state.brandHasTopics,
                ...initial
            };

            try {
                try {
                    const categoryStats = await count(filter, ["category"]);
                    finalStats.pressCount = categoryStats.find(r => r.category?.id === "PRESS")?.mentionCount ?? 0;
                    finalStats.enterpriseCount = categoryStats.find(r => r.category?.id === "ENTERPRISE")?.mentionCount ?? 0;
                    finalStats.consumerCount = categoryStats.find(r => r.category?.id === "CONSUMER")?.mentionCount ?? 0;
                    await commit('updateStats', {...finalStats}); // Temporarily update while we make the next call.
                    commit('setInitialisedPercent', 15);

                    const overallRelevant = await count(`brand isorchildof ${brand.id} and relevancy isnt irrelevant and (${dateFilter})`,
                        ["socialNetwork", "visibility"],
                        ['mentionCount', 'sentimentVerifiedCount']
                    );
                    finalStats.overallCount = overallRelevant.map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0);
                    finalStats.overallPublic = overallRelevant.filter(d => !d.visibility).map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0);
                    finalStats.overallDm = overallRelevant.filter(d => d?.visibility?.id === "DIRECT_MESSAGE").map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0);
                    finalStats.totalVerified = overallRelevant.map(d => d.sentimentVerifiedCount).reduce((lhs, rhs) => lhs + rhs, 0);
                    await commit('updateStats', {...finalStats});
                    await commit('setSocialNetworks', overallRelevant);

                    commit('setInitialisedPercent', 30);
                    await commit('updateStats', {...finalStats});

                    const cxResults = await calculateCxStats(brand.id, dateFilter, (count, total) => {
                        commit('setInitialisedPercent', (30 + Math.floor(count / total * 100 / 2)));
                    });

                    let cxStats = {
                        total: 0,
                        private: 0,
                        public: 0,
                        reshares: 0,
                        purchaseCount: 0,
                        cancelCount: 0
                    };

                    if (cxResults) cxStats = {...cxStats, ...cxResults};
                    await commit('setCxStats', cxStats);
                } catch (error) {
                    if (error.readyState !== 0) {
                        console.error("Error loading overview stats", error);
                        commit('setError', error);
                    }

                    commit('isInitialised', false);
                }

                finalStats.statsLoading = false;
                commit('updateStats', finalStats);
                commit('setInitialisedPercent', 100);
            } catch(e) {
                console.error(e);
                haveError = true;
                commit('updateStats', {...initial});
                notifyUserOfError("We're having a problem loading your data. Please try refreshing this page.")
            }
        },

        async checkWarnings({commit, rootState}) {
            if (!rootState.user.admin) return; // Don't need to do this work for clients.

            try {
                const warnings = await checkBrandsForExplore();
                await commit('setSetupWarnings', warnings);
            } catch(e) {
                console.error("Unable to check warnings for Explore panel", e);
                await commit('setSetupWarnings', [{
                    id: "UNABLE_TO_CHECK",
                    description: "Unable to check explore panel health",
                    fix: "Try again, or contact support"
                }]);
            }
        }
    }
}


/**
 * Calculates social network data from data returned via grouse.
 */
function calculateNetworkStats(data) {
    if (!data) return null;
    const total = data.map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0) ?? 0;
    const _public = data.filter(d => !d.visibility).map(d => d.mentionCount).reduce((lhs, rhs) => lhs + rhs, 0) ?? 0;
    return {
        total,
        public: _public,
        private: total - _public
    }
}

