<template>
    <section class="engaging-mention-item deq-reset">
        <div class="engaging-mention-item__summary">
            <with-summary :filter="summaryFilter"/>
        </div>
        <mention-item class="engaging-mention-item__mention"
                      :mention="mention"
                      :options="{ noSelect: true, noView: true}"/>


        <section v-if="showLoadingSign" class="engaging-mention-item__loading short-animated fadeIn">
        </section>

        <section v-else-if="showRiskDetails"
                 class="engaging-mention-item__segment-details short-animated fadeIn">
            <h4>
                Risk factors
            </h4>
            <span v-for="r in riskDetails" :key="r.id">
                <rpcs-tag code="RISK"/>
                {{r.name}}
            </span>
            <be-button link @click="showRiskDetails = false">« Back</be-button>
        </section>

        <section v-else-if="showPurchaseDetails"
                 class="engaging-mention-item__segment-details short-animated fadeIn">
            <h4>
                Purchase factors
            </h4>
            <span v-for="p in purchaseDetails" :key="p.id">
                <rpcs-tag code="PURCHASE"/>
                {{p.name}}
            </span>
            <be-button link @click="showPurchaseDetails = false">« Back</be-button>
        </section>

        <section v-else-if="showCancelDetails"
                 class="engaging-mention-item__segment-details short-animated fadeIn">
            <h4>
                Cancel factors
            </h4>
            <span v-for="c in cancelDetails" :key="c.id">
                <rpcs-tag code="CANCEL"/>
                {{c.name}}
            </span>
            <be-button link @click="showCancelDetails = false">« Back</be-button>
        </section>

        <section v-else-if="showServiceDetails"
                 class="engaging-mention-item__segment-details short-animated fadeIn">
            <h4>
                Service factors
            </h4>
            <span v-for="s in serviceDetails" :key="s.id">
                <rpcs-tag :code="s.flag"/>
                {{s.name}}
            </span>
            <be-button link @click="showServiceDetails = false">« Back</be-button>
        </section>

        <section v-else class="engaging-mention-item__stats">
            <section class="engaging-mention-item__menu">
                <popup-menu>
                    <template #activator>
                        <options-button/>
                    </template>

                    <section class="mini-menu">
                        <a @click="seeMentions()">See mentions</a>
                        <a @click="seeWordcloud()">See wordcloud</a>
                        <a @click="seeAuthors()">See authors</a>
                    </section>
                </popup-menu>
            </section>


            <old-tooltip v-if="sentiment !== null"
                     :label="`The Net Sentiment of all the mentions in this conversation is ${formatPercentage(sentiment * 100, 1)}` "
                     class="engaging-mention-item__stat-text-item engaging-mention-item__stat-text-item--net">
                <span>Net Sentiment</span>
                <net-sentiment :net-sentiment="sentiment"/>
            </old-tooltip>
            <span v-else class="deq-callout--muted">No verified data</span>

            <old-tooltip v-if="graphEngagement !== null"
                     label="Graph engagement includes engagement not only of this mention, but the engagement of all the mentions in this conversation"
                     class="engaging-mention-item__stat-text-item engaging-mention-item__stat-text-item--engagement">
                <span>Graph engagement</span>
                <deq-number :number="graphEngagement"/>
            </old-tooltip>

            <section class="engaging-mention-item__rpcs">
                <old-tooltip v-if="risk"
                         :label="riskTooltip">
                    <span @click="showRiskDetails = true"
                          class="engaging-mention-item__stat-text-item engaging-mention-item__stat-item--with-gap">
                        <rpcs-tag code="RISK" full/>
                        <deq-number class="engaging-mention-item__number" v-if="showNumbers" :number="risk"/>
                    </span>
                </old-tooltip>
                <old-tooltip v-if="purchase"
                         :label="purchaseTooltip">
                    <span @click="showPurchaseDetails = true"
                          class="engaging-mention-item__stat-text-item engaging-mention-item__stat-item--with-gap">
                        <rpcs-tag code="PURCHASE" full/>
                        <deq-number class="engaging-mention-item__number" v-if="showNumbers" :number="purchase"/>
                    </span>
                </old-tooltip>
                <old-tooltip v-if="cancel"
                         :label="cancelTooltip">
                    <span @click="showCancelDetails = true"
                          class="engaging-mention-item__stat-text-item engaging-mention-item__stat-item--with-gap">
                        <rpcs-tag code="CANCEL" full/>
                        <deq-number class="engaging-mention-item__number" v-if="showNumbers" :number="cancel"/>
                    </span>
                </old-tooltip>
                <old-tooltip v-if="service"
                         :label="serviceTooltip">
                    <span @click="showServiceDetails = true"
                          class="engaging-mention-item__stat-text-item engaging-mention-item__stat-item--with-gap">
                        <rpcs-tag code="SERVICE" full/>
                        <deq-number class="engaging-mention-item__number" v-if="showNumbers" :number="service"/>
                    </span>
                </old-tooltip>
            </section>


            <span class="engaging-mention-item__topics">
                <slotted-tag v-for="topic in topics"
                             :key="topic.id"
                             no-close
                             @mouseenter="showTopicTooltip($event, topic)">
                    {{topic.name}}
                </slotted-tag>
            </span>
        </section>
    </section>
</template>


<script>

import MentionItem from "@/components/MentionItem";
import DeqNumber from "@/components/formatters/DeqNumber";
import PopupMenu from "@/components/PopupMenu";
import SlottedTag from "@/components/tags/SlottedTag";
import NetSentiment from "@/components/NetSentiment";
import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import OptionsButton from "@/components/OptionsButton";
import BeButton from "@/components/buttons/BeButton";
import {count} from "@/data/Grouse";
import RpcsTag from "@/components/tags/RpcsTag";
import {mapActions, mapGetters, mapState} from "vuex";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import MostFrequentTopicTooltip from "@/app/toplevel/explore/overview/components/MostFrequentTopicTooltip";
import {formatNumber, formatPercentage, formatPlural} from "@/app/utils/Format";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import {features, isDebugModeEnabled} from "@/app/Features";
import WithSummary from "@/app/toplevel/explore/overview/components/summaries/WithSummary.vue";

/**
 * Shows a mention with some extra engagement related stats on the side,
 * including RPCS tags.
 */
export default {
    components: {
        WithSummary,
        OldTooltip,
        RpcsTag, BeButton, OptionsButton, NetSentiment, SlottedTag, PopupMenu, DeqNumber, MentionItem},
    props: {
        mention: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            showLoadingSign: false,
            debug: isDebugModeEnabled(),

            risk: null,
            cancel: null,
            purchase: null,
            service: null,
            showNumbers: false,

            sentiment: null,
            topics: [],
            graphEngagement: null,

            promiseToCancel: null,

            showRiskDetails: false,
            riskDetails: [],

            showServiceDetails: false,
            serviceDetails: [],

            showPurchaseDetails: false,
            purchaseDetails: [],

            showCancelDetails: false,
            cancelDetails: [],
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapGetters(['idToBrand']),

        filter() {
            return `brand isorchildof ${this.mention.brand.id} and conversationId is '${this.mention.conversationId}' and relevancy isnt irrelevant`
        },

        summaryFilter() {
            return `visibility is public and
                relevancy isnt irrelevant and
                reshareof is unknown and
                brand isorchildof ${this.mention.brand.id} and
                conversationId is '${this.mention.conversationId}'`
        },

        riskTooltip() {
            return `${formatNumber(this.risk)} ${formatPlural(this.risk, 'mention')} were tagged with Risk in this conversation.
            Click to see the Risk factors that occurred`;
        },

        purchaseTooltip() {
            return `${formatNumber(this.purchase)} ${formatPlural(this.purchase, 'mention')} were tagged with Purchase in this conversation.
            Click to see the Purchase factors that occurred`;
        },

        cancelTooltip() {
            return `${formatNumber(this.cancel)} ${formatPlural(this.cancel, 'mention')} were tagged with Cancel in this conversation.
            Click to see the Cancel factors that occurred`;
        },

        serviceTooltip() {
            return `${formatNumber(this.service)} ${formatPlural(this.service, 'mention')} were tagged with Service in this conversation.
            Click to see the Service factors that occurred`;
        }
    },

    mounted() {
        this.loadStats();
    },

    beforeDestroy() {
        try {
            if (this.promiseToCancel) {
                if (this.promiseToCancel.cancel) {
                    this.promiseToCancel.cancel();
                }
                this.promiseToCancel = null;
            }
        } catch (e) {
            console.warn("Unable to cancel network comms", e);
        }
    },

    methods: {
        ...mapActions(['refreshBrands']),
        formatPercentage,

        showTopicTooltip(event, topic) {
            showTooltipComponent(event.target, MostFrequentTopicTooltip,
                {
                    tag: topic
                }
            );
        },

        seeMentions() {
            showMentions(
                `brand isorchildof ${this.mention.brand.id} and conversationId is '${this.mention.conversationId}' and relevancy isnt irrelevant`,
                `Engaging conversations for ${this.mention.brand.shortName || this.mention.brand.name}`,
                true
            );
        },

        seeWordcloud() {
            showWordcloud(
                `brand isorchildof ${this.mention.brand.id} and conversationId is '${this.mention.conversationId}' and relevancy isnt irrelevant`,
                `Engaging conversations for ${this.mention.brand.shortName || this.mention.brand.name}`,
                true
            );
        },

        seeAuthors() {
            gotoAuthorsPanel(`brand isorchildof ${this.mention.brand.id} and conversationId is '${this.mention.conversationId}' and relevancy isnt irrelevant`);
        },

        async loadStats() {
            if (this.loading) {
                console.warn("Engagement stats already loading");
                return;
            }

            if (!this.mention) return;

            try {
                this.loading = true;
                this.showLoadingSign = true;
                await this.refreshBrands();

                const brand = this.mention?.brand?.id ? this.idToBrand.get(this.mention.brand.id) : null;
                if (!brand) return;

                const filter = this.filter;

                // Find the tags in the conversation
                this.promiseToCancel = count(filter, ["tag"], null, null);
                const tags = await this.promiseToCancel;
                this.promiseToCancel = null;
                this.service = 0;
                this.cancel = 0;
                this.purchase = 0;
                this.risk = 0;
                this.riskDetails = [];
                this.serviceDetails = [];
                this.cancelDetails = [];
                this.purchaseDetails = [];
                this.topics = [];
                this.graphEngagement = null;
                this.sentiment = null;

                let sentimentFragment = "visibility is public and process is verified";
                const sentimentFilter = `brand isorchildof ${this.mention.brand.id} and conversationId is '${this.mention.conversationId}' and (${sentimentFragment}) and relevancy isnt irrelevant`;
                this.promiseToCancel = count(sentimentFilter, null, ["mentionCount", "totalSentiment"]);
                const sentiment = await this.promiseToCancel;
                this.promiseToCancel = null;
                this.sentiment = sentiment.mentionCount ? sentiment.totalSentiment / sentiment.mentionCount : null;
                if (this.sentiment !== null) {
                    this.showLoadingSign = false;
                }

                const graphEngagementFilter = `visibility is public and
                relevancy isnt irrelevant and
                brand isorchildof ${this.mention.brand.id} and
                conversationId is '${this.mention.conversationId}'`;

                // Graph engagement counts should not count the head post itself.
                // So we subtract 1 from the calculation
                this.promiseToCancel = count(graphEngagementFilter);
                const graphEngagementCount = await this.promiseToCancel;
                this.promiseToCancel = null;
                this.graphEngagement = Math.max(graphEngagementCount.mentionCount - 1, 0);

                // Clients who are not getting everything verified do not get all the stats, since
                // they may be misleading in this situation (suggesting that a certain amount of a
                // conversation is risky, for instance, when it's even more).
                this.showNumbers = brand.crowdSamplePercentage === 100;
                this.showLoadingSign = false;

                const riskDetails = [];
                const purchaseDetails = [];
                const cancelDetails = [];
                const serviceDetails = [];

                for (const row of tags) {
                    const tag = row.tag;
                    switch (tag.flag) {
                        case "SERVICE":
                            this.service += row.mentionCount;
                            serviceDetails.push(tag);
                            break;
                        case "CANCEL":
                            this.cancel += row.mentionCount;
                            cancelDetails.push(tag);
                            break;
                        case "PURCHASE":
                            this.purchase += row.mentionCount;
                            purchaseDetails.push(tag);
                            break;
                        case "RISK":
                            this.risk += row.mentionCount;
                            riskDetails.push(tag);
                            break;
                    }
                }


                const topics = [];
                for (const row of tags) {
                    const tag = row.tag;
                    if (tag.namespace !== "topic" || !tag.leaf) continue;

                    topics.push(tag);
                    if (topics.length >= 3) break;
                }
                this.topics = topics;
                this.riskDetails = riskDetails;
                this.purchaseDetails = purchaseDetails;
                this.cancelDetails = cancelDetails;
                this.serviceDetails = serviceDetails;

            } catch(e) {
                if (e.readyState !== 0) {
                    throw e;
                }
            } finally {
                this.loading = false;
                this.showLoadingSign = false;
            }
        }
    }
}

</script>

<style scoped lang="sass">

.engaging-mention-item
    --engaging-mention-item-stats-width: 250px
    --max-topic-width: calc(var(--engaging-mention-item-stats-width) - 50px)

    display: inline-grid
    grid-template-columns: var(--mention-item-width) var(--mention-item-width) var(--engaging-mention-item-stats-width)
    grid-template-rows: auto
    grid-template-areas: "summary mention stats"
    grid-column-gap: 15px

    max-width: calc(2 * var(--mention-item-width) + var(--engaging-mention-item-stats-width))

    .engaging-mention-item__mention
        place-self: center

    & ::v-deep .mention-item
        margin: 0
        padding: 0


    .be-tag
        color: var(--be-colour-text-dark)

    .engaging-mention-item__menu
        margin-left: auto

    .engaging-mention-item__loading
        margin: auto auto
        color: var(--be-colour-muted-text-dark)
        width: 150px
        text-align: center
        .css-spinner
            margin: 5px auto

.engaging-mention-item__stats
    grid-area: stats
    display: flex
    flex-direction: column
    margin-bottom: auto
    max-width: var(--engaging-mention-item-stats-width)

    > span
        animation: 750ms engaging-mention-item-fade-in both

.engaging-mention-item__stat-text-item
    display: flex
    flex-direction: row
    > span:first-child, > .rpcs-tag:first-child
        flex-basis: 130px
    > span:first-child
        color: var(--be-colour-text-dark)

    &--net
        > span:first-child
            flex-basis: 125px

.engaging-mention-item__rpcs
    &:not(:first-child)
        margin-top: 10px

    .engaging-mention-item__stat-text-item
        transition: background var(--transition-duration)
        &:hover
            background: var(--background-menu-hover)

    .rpcs-tag
        box-sizing: border-box
        padding-left: 15px

    > *
        margin: 1px 0
        animation: 750ms engaging-mention-item-fade-in both
        cursor: pointer

.engaging-mention-item__topics
    display: flex
    flex-direction: column
    align-items: flex-start
    &:not(:first-child)
        margin-top: 10px
    .be-tag
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: var(--max-topic-width)
        margin-top: 2px


.engaging-mention-item__segment-details
    display: flex
    flex-direction: column
    margin-top: 10px
    color: var(--be-colour-text-dark)

    h4
        color: white

    .be-button
        margin-left: auto


.engaging-mention-item__summary
    grid-area: summary
    padding-block: calc(var(--mention-item__value-bar-height) + 1em)
    text-align: right


.engaging-mention-item + .engaging-mention-item
    margin-top: 40px

.engaging-mention-item + .engaging-mention-item:nth-child(even)
    grid-template-areas: "mention summary stats"
    .engaging-mention-item__summary
        text-align: left
        padding-right: 2em

@keyframes engaging-mention-item-fade-in
    0%
        opacity: 0
    100%
        opacity: 1

@media (max-width: 1280px)
    .engaging-mention-item
        --_areas: "mention stats" "summary summary"
        grid-template-columns: var(--mention-item-width) var(--engaging-mention-item-stats-width)
        grid-template-rows: auto auto
        grid-template-areas: var(--_areas)

    .engaging-mention-item + .engaging-mention-item:nth-child(even)
        grid-template-areas: var(--_areas)

    .engaging-mention-item__summary
        text-align: left
        padding-block: 1rem



</style>