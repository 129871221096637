<template>
    <section class="beef-tooltip">
        <h1>{{klass.title}}</h1>
        <p>
            {{klass.description}}
        </p>
        <p v-if="klass.tooltip">
            {{klass.tooltip}}
        </p>
        <p>
            <b>Example metrics:</b>
        </p>
        <ul>
            <li v-for="m in examples" :key="m.id">
                {{m.title}}
            </li>
        </ul>
    </section>
</template>

<script>
    export default {
        name: "MetricClassTooltip",

        props: {
            klass: Object
        },

        computed: {
            examples() {
                if (!this.klass || !this.klass.metrics) return [];
                return this.klass
                    .metrics
                    .filter(m => !m.hide && !m.additional)
                    .slice(0, 3);
            }
        }
    }
</script>


<style scoped lang="sass">

.beef-tooltip
    max-width: 250px

img
    max-width: calc(250px + 20px)
    height: auto
    margin-left: -10px
    margin-top: -3px
    margin-bottom: 10px
    border-top-left-radius: 3px
    border-top-right-radius: 3px

</style>