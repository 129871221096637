var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showVolume") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSentiment") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"cell vol\" title=\"Volume\">\n            <span class=\"circle\">\n                <span class=\"inner\" style=\"width:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeRadius") : depth0), depth0))
    + "%; height:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeRadius") : depth0), depth0))
    + "%;\n                        top:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeOffset") : depth0), depth0))
    + "%; left:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeOffset") : depth0), depth0))
    + "%\"></span>\n                <span class=\"num "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeNumCls") : depth0), depth0))
    + "\"\n                      style=\"font-size:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeFontSize") : depth0), depth0))
    + "px; bottom:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"volumeBottom") : depth0), depth0))
    + "px\">"
    + alias2(__default(require("../../../helpers/numToStat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"volume") : depth0),{"name":"numToStat","hash":{},"data":data,"loc":{"start":{"line":10,"column":88},"end":{"line":10,"column":108}}}))
    + "</span>\n            </span>\n        </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"sentiment\">\n            <span class=\"cell neg\" title=\"Negative sentiment %\">\n                <span class=\"num\">"
    + alias2(__default(require("../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"negPercentage") : depth0),0,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":70}}}))
    + "</span>\n                <span class=\"bar\">\n                    <span class=\"inner\" style=\"width: "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"negBarSize") : depth0), depth0))
    + "%\"></span>\n                </span>\n                <span class=\"margin-of-error\" title=\"95% confidence, sample size "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"sampleSize") : depth0), depth0))
    + "\">&plusmn; "
    + alias2(__default(require("../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"negPercentageMOE") : depth0),0,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":22,"column":106},"end":{"line":22,"column":145}}}))
    + "</span>\n            </span>\n            <span class=\"cell pos\" title=\"Positive sentiment %\">\n                <span class=\"num\">"
    + alias2(__default(require("../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"posPercentage") : depth0),0,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":70}}}))
    + "</span>\n                <span class=\"bar\">\n                    <span class=\"inner\" style=\"width: "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"posBarSize") : depth0), depth0))
    + "%\"></span>\n                </span>\n                <span class=\"margin-of-error\" title=\"95% confidence, sample size "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"sampleSize") : depth0), depth0))
    + "\">&plusmn; "
    + alias2(__default(require("../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"posPercentageMOE") : depth0),0,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":29,"column":106},"end":{"line":29,"column":145}}}))
    + "</span>\n            </span>\n        </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"spinner-light\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"value\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":36,"column":7}}})) != null ? stack1 : "")
    + "</td>";
},"useData":true});