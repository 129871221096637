<template>
    <section class="be-sidebar" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <slot>
            <sidebar-header>
                <h4> Sidebar Header</h4>
            </sidebar-header>
            <sidebar-menu>

            </sidebar-menu>

        </slot>

    </section>
</template>

<script>

import SidebarHeader from "@/components/sidebar/SidebarHeader";
import SidebarMenu from "@/components/sidebar/SidebarMenu";
import {removeSidebarHoverFlag} from "@/components/sidebar/SidebarUtilities";

export default {
    components: {SidebarHeader, SidebarMenu},

    beforeDestroy() {
        removeSidebarHoverFlag();
    },

    methods: {
        mouseEnter() {
            document.documentElement.classList.add("sidebar-hover");
        },

        mouseLeave() {
            document.documentElement.classList.remove("sidebar-hover");
        }
    }
}

</script>


<style scoped lang="sass">
.be-sidebar
    transition: left 250ms
    display: flex
    flex-direction: column
    width: var(--sidebar-size)
    left: 0
    top: 40px
    height: calc(100vh - 40px)
    position: fixed
    z-index: 1001

    background: var(--sidebar-background)

    &--hidden
        left: calc(-1 * var(--sidebar-size))

</style>