var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Model details";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <tr>\n                    <td colspan=\"3\">No likely tags are required for this rule to match</td>\n                </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"autoTagsRequired") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":40,"column":29}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tagId") : depth0), depth0))
    + "\">\n                        <td>\n                            <span class=\"tag\">\n                                <span class=\"tag-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"_tag") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n                                <button class=\"close\" tabindex=\"-1\" title=\"Remove this item\">×</button>\n                            </span>\n                        </td>\n                        <td class=\"probability\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"probability") : depth0), depth0))
    + "</td>\n                        <td>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":37,"column":42}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"https://kale.brandseye.com/models/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tagId") : depth0), depth0))
    + "\" target=\"_blank\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tagId") : depth0), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"auto-tag-rule-settings\">\n    <div class=\"row-fluid\">\n        <div class=\"span12\">\n            <h1>Machine Learning Tags</h1>\n            <p style=\"margin-bottom: 20px\">\n                DataEQ uses machine learning to find tags that are likely to apply to incoming mentions. Each tag\n                is given a probability ranging from 0 (not on the mention) to 1 (very likely). The tags listed on this\n                page must have at least the specified probability of being on the mention for the rule to match.\n            </p>\n\n            <table class=\"table table-condensed table-bordered table-hover\">\n                <thead>\n                <tr>\n                    <th>Tag</th>\n                    <th>Required probability</th>\n                    <th>"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":79}}})) != null ? stack1 : "")
    + "</th>\n                </tr>\n                </thead>\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"autoTagsRequired") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":41,"column":27}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n\n            <div>\n                <a class=\"btn add-tag\">Add Tag</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});