import { adjustedWald } from "@/app/utils/Util";
import {getBrandsInFilter, getTagsInFilter, getTopicsInFilter} from "@/dashboards/filter/FilterParser";
import {deprecatedFetchTags, fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import {
    extractBrands,
    isCrowdVerifiedOnly,
    isUnbiasedSampleOnly,
    isVerifiedOnly
} from "@/dashboards/filter/BasicFilter";
import {formatNumber} from "@/app/utils/Format";
import _ from 'underscore';
import moment from "moment";
import {once} from "@/app/utils/Functions";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {features} from "@/app/Features";

Beef.module("Widget.Stats").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Statistics",
        description:    "See mention volumes, unique authors, sentiment, displayed in a table",
        tooltip:        "This lets you see summary values for your conversations, such as the opportunity-to-see, " +
                        "number of mentions, unique authors involved in the conversation, and so on. This is displayed in a table, rather than as a chart",
        group:          "benchmarking",
        width:          2,
        height:         4,

        onAdd: function(data) {
            data.exact = true;
            data.volume = true;
            data.uniqueAuthors = true;
            data.OTS = false;
            data.engagement = false;
            data.reshares = false;
            data.replies = false;
            data.positiveCountP = true;
            data.neutralCountP = true;
            data.negativeCountP = true;
        },
    };

    let defaultOnStats = ['volume', 'uniqueAuthors', 'OTS', 'engagement', 'reshares', 'replies'];

    let responseTimeStats = ['avgResponseTime', 'minResponseTime', 'maxResponseTime', 'stddevResponseTime'];

    let interactionResponseTimeStats = [
        "averageInteractionResponseTime",
        "minInteractionResponseTime",
        "maxInteractionResponseTime",
        "stddevInteractionResponseTime",
        "averageInteractionWhResponseTime",
        "minInteractionWhResponseTime",
        "maxInteractionWhResponseTime",
        "stddevInteractionWhResponseTime",
    ];

    let otherStats = ['AVE', 'totalSentimentP', 'totalSentiment', 'positiveOts', 'negativeOts', 'positiveCount', 'neutralCount', 'negativeCount', 'uniqueSites', 'brandIndex',
        'positiveOtsP', 'negativeOtsP', 'sampleSize', 'positiveCountP', 'neutralCountP', 'negativeCountP', 'conversations'];

    let allAttributes = defaultOnStats.concat(responseTimeStats).concat(otherStats).concat(interactionResponseTimeStats)
        .concat('exact', 'show-deltas', 'show-exact-deltas', 'sampled-sentiment', 'sentiment-verification', 'comparison');

    let legacyAttrs = [ "positiveReachP", "negativeReachP", "positiveReach", "negativeReach" ];

    this.fixLegacyAttributes = function(attrs) {
        for (var i = 0; i < legacyAttrs.length; i++) {
            var key = legacyAttrs[i];
            var value = attrs[key];
            if (value) {
                attrs[key.replace("Reach", "Ots")] = value;
                delete attrs[key];
            }
        }
    };

    this.View = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/stats/Stats.handlebars"),

        attributes: { 'class': 'stats widget-font widget-height-inner' },

        svgExportDisabled: true,

        modelEvents: {
            "change":   "maybeRefresh",
            "change:width": "widthChanged"
        },

        widthChanged: function() {
            this.$el.removeClass("short-animated fadeIn");
            this.$el.css({opacity: 0});

            setTimeout(function() {
                this.$el.addClass("short-animated fadeIn");
            }.bind(this), 350)
        },

        initialize: function() {
            var defaults = {};

            if (!this.model.has('show-deltas')) defaults['show-deltas'] = true;

            for (var i = 0; i < defaultOnStats.length; i++) {
                if (!this.model.has(defaultOnStats[i])) defaults[defaultOnStats[i]] = true;
            }
            if (!this.model.get('comparison')) {
                defaults['comparison'] = 'none';
            }

            this.model.set(defaults, {silent: true});

            this.accountCode = this.model.getAncestorProperty('accountCode');
            this.refresh();
        },

        viewMentions: function() {
            var filter = this.model.get("_effectiveFilter");
            Beef.MentionList.navigateToMentions(this.accountCode, filter, null)
        },

        viewAuthors: function() {
            var filter = this.model.get("_effectiveFilter");
            Beef.AuthorsSectionV4.navigateToAuthors(this.accountCode, filter);
        },

        render: function() {
            // Ensure that we can determine a max-width to constrain this element
            // appropriately. max-width cannot easily be set in css at the moment since
            // there is no easy way to determine it for the last, expandable column of our section grid.
            var $table = this.$('table');
            this.$el.css({'max-width': "none"});
            $table.css({display: "none"});
            var w = this.$el.width();
            this.$el.css({'max-width': w + "px"});
            $table.css({display: "block"});

            this.calcDerivedStats();
            // bypass the BoundItemView render - we don't need the model binding and so on and just want to repaint
            // our template whenever new data comes in
            Backbone.Marionette.Layout.prototype.render.call(this, arguments);
        },

        onFirstRender: function() {
        },

        renderImpl: function() {
        },

        maybeRefresh: function() {
            var c = this.model.changed;
            var fieldsChanged = allAttributes.some(function(attr) { return c[attr] });

            if (fieldsChanged || c['_effectiveFilter'] || c['sampled-sentiment'] || c['sentiment-verification'] || c['comparison'] ||
                !_.isEmpty(_.omit(this.calcEndpoints(), this.endpoints ? Object.keys(this.endpoints) : []))) {
                this.refresh();
            } else {
                this.render();
            }
        },

        calcEndpoints: function() {
            return { count: true };   // we get everything from the count endpoint now
        },

        refresh: function() {
            var filter = this.model.get("_effectiveFilter");
            if (!filter) return;

            var that = this;
            var modifyCheck = this.filterModifyCheck(this.model);
            this.getSeries(filter, this.model.get('comparison'))
                .then(function(series) {
                    this.model.set("_data", series);

                    var that = this;
                    this.endpoints = this.calcEndpoints();
                    this.model.generalData.set("_completed", false);
                    this.outstandingCalls = 0;

                    _.each(series.series, function(s){

                        s._count = null;
                        if (this.endpoints.count) {
                            ++this.outstandingCalls;

                            let includeResponseTime = false;
                            let includeInteractionResponseTime = false;

                            for (const responseTimeStat of responseTimeStats) {
                                if (this.model.get(responseTimeStat)) {
                                    includeResponseTime = true;
                                    break;
                                }
                            }

                            for (const interactionResponseTimeStat of interactionResponseTimeStats) {
                                if (this.model.get(interactionResponseTimeStat)) {
                                    includeInteractionResponseTime = true;
                                    break;
                                }
                            }

                            if (getBrandsInFilter(s.filter).include.length > 1) {
                                includeResponseTime = false;
                                includeInteractionResponseTime = false;
                            }

                            let select = "mentionCount,authorId,site,totalAVE,totalOTS,totalEngagement,totalReshareCount," +
                                "totalReplyCount,conversationIdCount";

                            if (includeInteractionResponseTime) {
                                interactionResponseTimeStats.forEach(selectVariable => {
                                    if (this.model.get(selectVariable)) {
                                        select += `,${selectVariable}`;
                                    }
                                });
                            }

                            // cater for legacy stats tables using old response times
                            if (includeResponseTime) {
                                select += ",averageResponseTime,minResponseTime,maxResponseTime,stddevResponseTime";
                            }

                            var promise = this.model.getSectionModel()
                                .view
                                .getJsonFromGrouse('/v4/accounts/' + this.accountCode + '/mentions/count',
                                    {filter: s.filter, select: select});

                            if (!includeResponseTime && !includeInteractionResponseTime) {
                                // Make a separate call to get sentiment values using verified data
                                promise = Promise
                                    .all([
                                            promise,
                                            this.model.getSectionModel()
                                                .view
                                                .getJsonFromGrouse('/v4/accounts/' + this.accountCode + '/mentions/count',
                                                    {
                                                        filter: "(" + s.filter + ") and sentimentVerified is true",
                                                        select: "totalPositive,totalNeutral,totalNegative,totalSentiment"
                                                    })
                                        ]
                                    )
                                    .then(function(data) {
                                        var verified = data[0];
                                        var unverified = data[1];
                                        return Object.assign({}, verified, unverified);
                                    })
                            }


                            promise.then(function (data) {
                                Beef.StatFields.hackAVEFromOTS(data);
                                Beef.StatFields.convertV4FieldNamesToV3(data);
                                if (!s._count) s._count = data;
                                else Object.assign(s._count, data);
                                that.onCallCompleted();
                                that.render();
                            }, once(e => errorHelper(this.model, e)));

                            if (includeResponseTime || includeInteractionResponseTime) {
                                // have to make separate call for the sentiment as we cannot use groupBy=sentimentVerified
                                // without messing up averageResponseTime etc.
                                ++this.outstandingCalls;
                                var check = this.filterModifyCheck(this.model);
                                var callFilter = s.filter;
                                if(check.modify) callFilter = s.filter + " AND (" + check.filter + ")";

                                this.model.getSectionModel().view.getJsonFromGrouse('/v4/accounts/' + this.accountCode + '/mentions/count',
                                    { filter: callFilter, select: "totalPositive,totalNeutral,totalNegative,totalSentiment" })
                                    .then(function (data) {
                                        Beef.StatFields.convertV4FieldNamesToV3(data);
                                        if (!s._count) s._count = data;
                                        else Object.assign(s._count, data);
                                        that.onCallCompleted();
                                        that.render()
                                    }, once(e => errorHelper(this.model, e)));
                            }
                        }

                    }, this);

                    this.render();
                }.bind(this))
                .catch(function(error) {
                    errorHelper(that.model, error);
                })
        },

        getSeries: function(filter, comparison, oldSeries) {
            var defaultSeries = Promise.resolve({series: [{filter: filter}]});
            var that = this;

            try {
                switch (comparison) {
                    case 'brands':
                        var brands = getBrandsInFilter(filter).include;
                        if (!brands.length) {
                            return defaultSeries;
                        } else {
                            return new Promise(function(resolve) {
                                fetchCachedBrands(that, function(data) {
                                    resolve(data.map);
                                });
                            }).then(function(brandMap) {
                                return {
                                    series: brands.map(function (b) {
                                        return {
                                            label: brandMap[b].name,
                                            filter: "(" + filter + ") and brand isorchildof " + b
                                        }
                                    })
                                }
                            })
                        }

                    case 'tags':
                        var tags = getTagsInFilter(filter).include;
                        if (!tags.length) {
                            return defaultSeries;
                        } else {
                            return new Promise(function(resolve) {
                                deprecatedFetchTags(that, function(data) {
                                    resolve(data);
                                });
                            }).then(function(tagMap) {
                                return {
                                    series: tags.map(function (t) {
                                        return {
                                            label: tagMap[t].name,
                                            filter: "(" + filter + ") and tag is " + t
                                        }
                                    })
                                }
                            })
                        }

                    case 'topics':
                        var topics = getTopicsInFilter(filter).include;
                        if (!topics.length) {
                            return defaultSeries;
                        } else {
                            return new Promise(function(resolve) {
                                deprecatedFetchTags(that, function(data) {
                                    resolve(data);
                                });
                            }).then(function(tagMap) {
                                return {
                                    series: topics.map(function (t) {
                                        return {
                                            label: tagMap[t].name,
                                            filter: "(" + filter + ") and topic is " + t
                                        }
                                    })
                                }
                            })

                        }

                    case 'none':
                    default:
                        return defaultSeries;
                }
            } catch (e) {
                return Promise.reject(e);
            }


        },

        filterModifyCheck: function(testModel) {
            var obj = {"modify": false, "filter": "Process IS VERIFIED"};
            var filter = testModel.get("_effectiveFilter");
            if (isVerifiedOnly(filter)) return obj;


            if (testModel.get('sentiment-verification') === undefined || testModel.get('sentiment-verification') === "yes") {
                var keys = Object.keys(testModel.attributes);
                if (keys.some(function(k) { return testModel.get(k) && (k.startsWith("negative") || k.startsWith("positive")
                    || k.startsWith("neutral") || k.startsWith("totalSentiment"))})) {

                    if (testModel.get('filter') === undefined || testModel.get('filter') === "") obj.modify = true;
                }
            }
            return obj
        },

        onCallCompleted: function() {
            if (--this.outstandingCalls <= 0) this.model.generalData.set("_completed", true)
        },

        calcDerivedStats: function(s) {
            var series = this.model.get('_data');
            if (!series) return;

            var exact = this.model.get('exact');
            var showDeltas = this.model.get('show-deltas');

            var footnote = undefined;

            series = series.series;
            _.each(series, function(s) {
                var c = s._count, t;
                if (c) {
                    var sentimentTotal = c.positiveCount + c.neutralCount + c.negativeCount;
                    if (c.unknownCount) sentimentTotal += c.unknownCount;

                    c.positiveCountP = percentage(c.positiveCount, sentimentTotal);
                    c.neutralCountP = percentage(c.neutralCount, sentimentTotal);
                    c.negativeCountP = percentage(c.negativeCount, sentimentTotal);
                    c.totalSentimentP = percentage(c.totalSentiment, c.positiveCount + c.neutralCount + c.negativeCount); // Can't use mentionCount, since that includes unverified data.
                    t = c.positiveOts + c.neutralOts + c.negativeOts;
                    c.positiveOtsP = percentage(c.positiveOts, t);
                    c.negativeOtsP = percentage(c.negativeOts, t);
                    c.sampleSize = 0;

                    var sectionCheck = verifiedFilters(this.model.getSectionModel().get('filter'));
                    var filterCheck = verifiedFilters(this.model.get('filter'));
                    var seriesCheck = verifiedFilters(this.model.get('_effectiveFilter'));
                    var modifyCheck = this.filterModifyCheck(this.model);

                    if (sectionCheck || filterCheck || seriesCheck || modifyCheck.modify) {
                        if (modifyCheck.modify) footnote = "Using verified data for sentiment values";
                        c.sampleSize = sentimentTotal;

                        // Moe == margin of error, P == percentage, Z == one sided range when sample value is 0
                        if (c.positiveCount) {
                            var adjWaldPos = adjustedWald(sentimentTotal, c.positiveCount, c.mentionCount);
                            c.positiveCountHi = adjWaldPos.high;
                            c.positiveCountLow = adjWaldPos.low;

                            c.positiveCountMoe = this.calcMarginOfError(c.positiveCountHi, c.positiveCountLow, c.positiveCount);
                            c.positiveCountPMoeP = percentage(c.positiveCountMoe, sentimentTotal);
                            c.positiveCountPLow = percentage(c.positiveCountLow, sentimentTotal);
                            c.positiveCountPHi = percentage(c.positiveCountHi, sentimentTotal);
                        } else {
                            c.positiveCountMoeZ = c.positiveCountHi;
                            c.positiveCountPMoeZ = percentage(c.positiveCountMoeZ, sentimentTotal)
                        }

                        if (c.negativeCount) {
                            var adjWaldNeg = adjustedWald(sentimentTotal, c.negativeCount, c.mentionCount);
                            c.negativeCountHi = adjWaldNeg.high;
                            c.negativeCountLow = adjWaldNeg.low;

                            c.negativeCountMoe = this.calcMarginOfError(c.negativeCountHi, c.negativeCountLow, c.negativeCount);
                            c.negativeCountPMoeP = percentage(c.negativeCountMoe, sentimentTotal);
                            c.negativeCountPLow = percentage(c.negativeCountLow, sentimentTotal);
                            c.negativeCountPHi = percentage(c.negativeCountHi, sentimentTotal);
                        } else {
                            c.negativeCountMoeZ = c.negativeCountHi;
                            c.negativeCountPMoeZ = percentage(c.negativeCountMoeZ, sentimentTotal)
                        }
                    }
                }
                s._exact = exact;
            }, this);

            for (var i = 1; i < series.length; i++) {
                var current = series[i];
                var prev = series[i - 1];
                series[i]._showDeltas = showDeltas;
                this.calcDeltas(prev._count, current._count);
            }

            if(series[0].dateComparison != true && series.length > 1){
                series[0]._showDeltas = showDeltas;
                this.zeroDeltas(series[0]._count);
                var countTotals = this.keyTotals(series, '_count');
                this.percentageShare(series, '_count', countTotals);
            }

            Beef.Footnotes.clearFootnotes(this);
            if (footnote) Beef.Footnotes.addFootnote(this, footnote);
        },

        calcMarginOfError: function (high, low, count) {
            var moe = (high - low) / 2;
            var highDiff = high - count;
            var lowDiff = count - low;
            moe = Math.max(lowDiff, highDiff);
            return moe;
        },

        keyTotals: function(series, attr) {
            if(series[0][attr]){
                var totals = {};
                _.each(Object.keys(series[0][attr]), function(key) {
                    var keyTotal = 0;
                    for(var x = 0; x < series.length; x++) {
                        if(series[x][attr]) keyTotal += series[x][attr][key];
                    }
                    totals[key] = keyTotal;
                });
                return totals;
            }
        },

        percentageShare: function(series, prop, totals) {
            if(!totals) return;
            _.each(series, function(s){
                if(s[prop]){
                    _.each(Object.keys(s[prop]), function(key) {
                        if (key == "_deltas") return;
                        if (!s[prop]._deltas) return; // in case it hasn't been defined yet.
                        if (key === "totalSentiment") {
                            // Net sentiment percentage should not be calculated globally.
                            // It is not what people are expecting to see.
                            s[prop]._deltas[key].percentShare = percentage(s[prop]["totalSentiment"],
                                s[prop]["totalPositive"] + s[prop]["totalNegative"] + s[prop]["totalNeutral"]);
                        } else {
                            // Most other percentages are calculated globally
                            s[prop]._deltas[key].percentShare = percentage(s[prop][key],totals[key]);
                        }

                    })
                }
            });
        },

        zeroDeltas: function(zObj) {
            if(!zObj) return;
            var deltas = zObj._deltas = {};
            _.each(Object.keys(zObj), function(key) {
                if (key == "_deltas") return;
                deltas[key] = {
                    diff: 0,
                    absDiff: 0,
                    percentage: 0
                };
            });
        },

        calcDeltas: function(prevObj, currentObj) {
            if (!prevObj || !currentObj) return;
            var deltas = currentObj._deltas = {};
            _.each(Object.keys(currentObj), function(key) {
                if (key == "_deltas") return;
                var curr = currentObj[key];
                var prev = prevObj[key];
                var diff = (Number.isFinite(curr) ? curr : 0) - (Number.isFinite(prev) ? prev : 0);
                deltas[key] = {
                    diff: diff,
                    absDiff: Math.abs(diff),
                    percentage: prev == 0 && diff != 0 ? Infinity : Math.abs(percentage(diff, prev))
                };
            });
        },

        v4IdToModel: function(id) {
            switch(id) {
                case "mentionCount": return "volume";
                case "authorIdCount": return "uniqueAuthors";
                case "conversationIdCount": return "conversations";
                case "sites": return "uniqueSites";
                case "ots": return "OTS";
                case "ave": return "AVE";
                case "reshareCount": return "reshares";
                case "replyCount": return "replies";
                default: return id;
            }
        },

        dataToCsv: function() {
            var data = this.model.get("_data");
            if (!data) return null;
            if (!data.series) return null;

            // Add the UTF-8 BOM to the header.
            // The UTF-8 BOM is so that Excel + Windows correctly interprets and opens
            // this file.
            var keys = Object.keys(data.series[0]._count)
                .filter(function(key) {
                    return this.model.get(this.v4IdToModel(key));
                }.bind(this));

            var csv = "\ufefflabel," + keys.map(function(key) { return Beef.StatFields.get(key).label })
                                           .join(',') + "\n";

            data.series.forEach(function(series) {
                csv = csv + '"' + (series.label || "Stats").replace(/"/g, '""') + '"';
                keys.forEach(function(key) {
                    csv += ',"' + formatNumber(series._count[key], 1, '') + '"';
                });
                csv += "\n";
            });

            return csv;
        },

        getCsv: function() {
            var csv = this.dataToCsv();
            if (csv == null) {
                alert("There is no data to download. Check your filters");
                return;
            }

            var caption = this.model.get("caption") || "csv";

            // The idea here is to create an anchor element for downloading
            // a file. The href encodes the data that we want to download.
            // We convert the json data into a string CSV, and create an appropriately
            // encoded url using some html5 features (although could be done manually).
            var link = document.createElement("a");
            link.style = "display: none";
            link.href = window.URL.createObjectURL(new Blob([csv], {type: "text/csv"}));
            link.download = caption + "-" + (moment().format("YYYY-MM-DD-HH[h]mm")) + ".csv";

            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        },
    });

    var percentage = function(a, b) {
        if (!Number.isFinite(a) || !Number.isFinite(b)) return 0;
        return b ? a * 100 / b : 0;
    };

    var comparisonPicker = Beef.DynamicPicker.create({
        "none": {name: "Nothing", description: "Do not compare anything"},
        "brands": {name: "Brands", description: "Compare your stats by the brands in your filter"},
        "tags": {name: "Tags", description: "Compare your stats by the tags in your filter"},
        "topics": {name: "Topics", description: "Compare your brands by the topics in your filter"}
    });

    this.SettingsView = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/stats/StatsSettings.handlebars"),

        attributes: { 'class': 'stats-settings' },

        editAttributes: function() { return allAttributes },

        events: {
            "change .checkbox": "checkboxChanged",
            "click .nav-tabs a": "tabClicked",
            "click .sentiment-verification button" : "sentimentVerificationToModel"
        },

        modelEvents: {
            "change": "sentimentVerificationToView"
        },

        bindings: function() {
            return {
                'comparison': { converter: comparisonPicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            comparisonPicker.attach(this, ".comparison", "comparison");

            _.each(this.model.attributes, function(value, key){
                if (value) this.$('.' + key).attr('checked', value);
            }, this);
            this.sentimentVerificationToView();
        },

        checkboxChanged: function(ev) {
            var $t = $(ev.target).closest("input");
            var key = $t.attr('class');
            if (key) {
                this.model.set(key, $t.is(":checked"));
            }
        },

        tabClicked: function(ev) {
            ev.preventDefault();
            var $t = $(ev.target).closest("a");
            $t.closest(".nav-tabs").find("li").toggleClass('active', false);
            $t.closest("li").toggleClass('active', true);
            var tab = $t.attr('data-tab');
            this.$('.stats-tabs > div').hide();
            this.$('.' + tab).show();
        },

        showHideCheckboxes: function() {
            // uses legacy reference in StatsSettings.handlebars
            var veri = this.model.get("sentiment-verification");
            this.$('tbody.unless-sampled-sentiment').toggle(veri == "no");
        },

        sentimentVerificationToView: function() {
            this.$el.find(".sentiment-verification button").toggleClass("active", false);
            var veri = this.model.get("sentiment-verification");
            if (!veri) veri = "yes";
            this.$el.find(".sentiment-verification button[data-id='" + veri + "']").toggleClass("active", true);
            this.disableSentimentVerification();
            this.showHideCheckboxes();
        },

        disableSentimentVerification: function () {
            var labelWidget = this.$el.find("#senti-ver-label-widget");
            labelWidget.hide();
            var widgetFilter = this.model.get('filter');

            this.$el.find(".sentiment-verification button[data-id='yes']").removeClass('disabled');
            this.$el.find(".sentiment-verification button[data-id='no']").removeClass('disabled');

            if (widgetFilter !== "" && widgetFilter !== undefined) {
                labelWidget.show();
                this.$el.find(".sentiment-verification button[data-id='yes']").addClass('disabled');
                this.$el.find(".sentiment-verification button[data-id='no']").addClass('disabled');
            }
        },

        sentimentVerificationToModel: function(ev) {
            var oldVal = this.model.get("sentiment-verification");
            var id = $(ev.target).attr("data-id");

            if (oldVal === id) return; // no logic if nothings is changing.

            var widgetFilter = this.model.get('filter');
            if (widgetFilter !== "" && widgetFilter !== undefined) return; // ignore event since this option should be disabled.

            if (id) this.model.set("sentiment-verification", id);
        }
    });

    var verifiedFilters = function(filter) {
        if (!filter) return false;

        if (isVerifiedOnly(filter)
            || isCrowdVerifiedOnly(filter)
            || isUnbiasedSampleOnly(filter)) {
            return true;
        }
        else return false;
    };
});