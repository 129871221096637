import { teams } from "@/store/deprecated/Stores";
import VuexStore from "@/store/vuex/VuexStore";

Beef.module("AccountUsers").addInitializer(function(startupOptions) {

    var maxUsers = startupOptions.account.maxUsers;

    /**
     * Main layout for users, containing list and detail regions
     */
    this.View = Backbone.Marionette.Layout.extend({

        template: require("@/settings/users/AccountUsers.handlebars"),

        regions: {
            list: ".user-list"
        },

        events: {
            "click a.add-user": "newUser",
            "click a.delete": "deleteUser"
        },

        initialize: function() {
            this.model.set('title', 'Users');
            this.getList();
        },

        renderList: function() {
            this.list.show(this.listView);
        },

        getList: async function() {
            await teams.refresh(true)
            this.listUsers = new List({accountCode: this.model.get('accountCode')});
            setTimeout(function() {
                if (this.list && !this.list.currentView && !this.$('.rules-error').is(':visible')) {
                    var $loading = this.$('.users-loading');
                    $loading.show();
                    $loading.toggleClass('animated fadeIn', true);
                }
            }.bind(this), 1000);
            this.listUsers.fetch({
                success: function() {
                    if (this.list) {
                        var $loading = this.$('.users-loading');
                        $loading.hide();
                        this.listView = new ListView({collection: this.listUsers});
                        this.list.show(this.listView);
                        this.$('.user-list').toggleClass('short-animated fadeIn', true);
                    }
                }.bind(this),
                    error: function(e) {
                    console.error(e);
                    if (this.list) {
                        var $loading = this.$('.users-loading'),
                            $error   = this.$('.users-error');
                        $loading.hide();
                        $error.show();
                        $error.toggleClass('animated fadeIn', true);
                    }
                }
            });
        },

        newUser: function(ev) {
            ev.preventDefault();

            var numUsers = 0;
            for (var i = 0; i < this.listUsers.length; i++) {
                var email = this.listUsers.at(i).get('email');
                if (email && email.toLowerCase().indexOf("@dataeq.com") < 0) numUsers++;
            }
            if (numUsers >= maxUsers) {
                Beef.LimitReached.show(ev, maxUsers, "user", "users");
                return;
            }

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["center"],
                alwaysMove: true
            });
            popup.setTarget($(ev.target));

            var user = new User({accountCode: this.model.get('accountCode')});
            var view = new Beef.EditUser.View({model: user, detail: this.detail, listUsers: this.listUsers});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);

            var that = this;
            view.on("ok", function() { {
                if (!user.attributes.permissions) user.attributes.permissions = 'FULL';
                that.listUsers.add(user)
            } });
        },

        deleteUser: function(ev) {
            ev.preventDefault();
            var user = new User({id: $(ev.target).attr('id'), accountCode: this.model.get('accountCode')});
            this.listUsers.remove(user);
            user.destroy().then(() => VuexStore.dispatch("accountUsers/refreshAccountUsers", false));
        }
    });

    var PERMISSIONS = {
        FULL:       { label: "Full",        descr: "User can edit everything except for users"},
        READ:       { label: "Read",        descr: "User can see everything but cannot make changes"},
        REPORTING:  { label: "Reporting",   descr: "User can see everything but can only edit dashboards"},
        ADMIN:      { label: "Admin",       descr: "User can edit everything including users"}
    };
    this.PERMISSIONS = PERMISSIONS;

    /**
     *  View for each item within the Collection of Users
     */
    var ItemView = Backbone.Marionette.ItemView.extend({
        tagName: "tr",

        template: require("@/settings/users/UserItemView.handlebars"),

        events: {
            "click .user-edit": "editUser"
        },

        modelEvents: {
            "change": "render"
        },

        templateHelpers: function() {
            return {
                permissionLabel: function() { return PERMISSIONS[this.permissions].label },
                permissionDescr: function() { return PERMISSIONS[this.permissions].descr },
                teams: function() { return teams.getUserTeams(this.id).map(t => t.name).join(", ") }
            }
        },

        editUser: function(ev) {
            ev.preventDefault();

            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions: ["center"],
                alwaysMove: true
            });
            popup.setTarget($(ev.target));

            var view = new Beef.EditUser.View({model: this.model});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        }
    });

    /**
     * View for Collection, appends each view item within the collection to the body of the table.
     */
    var ListView = Backbone.Marionette.CompositeView.extend({
        itemView: ItemView,
        template: require("@/settings/users/UserListView.handlebars"),
        attributes: { class: "section" },
        appendHtml: function(collectionView, itemView, index){
            collectionView.$(".body").append(itemView.el);
        },
        initialize: function(options) {
            this.collection = options.collection;
        }
    });

    /**
     * View for a single user
     */
    var UserView = Beef.BoundItemView.extend({
        template: require("@/settings/users/UserView.handlebars"),
        attributes: { class: "dialog" }
    });

    /**
     * User Model
     */
    var User = Backbone.Model.extend({
        defaults: {
            accessStatus: true
        },
        validation: {
            email: {
                required: true,
                pattern: "email"
            },
            firstName: { required: true },
            lastName: { required: true }
        },
        initialize: function(options) {
            this.accountCode = options.accountCode;
        },
        save: function(attributes, options) {
            this.url = Beef.Sync.toMashUrl('accounts/' + this.accountCode + '/users/' + (this.id || ''));
            return Backbone.Model.prototype.save.call(this, attributes, options);
        }
    });

    /**
     * User Collection
     */
    var List = Backbone.Collection.extend({
        model: User,
        initialize: function(options){
            this.accountCode = options.accountCode;
            this.url = Beef.Sync.toMashUrl('accounts/' + this.accountCode + '/users');
        }
    });
});
