/**
 * Provides a way to switch between widget types based on the selected 'chart-type' in the model.
 * To implement this, provide a defaultChartType, and a map (typeViews) between chart types and
 * functions that return a new instance of that appropriate chart view.
 */

import {isString} from "@/app/utils/StringUtils";
import {isFunction} from "@/app/utils/Util";

Beef.module("Widget.CompositeWidget").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/composite/CompositeWidget.handlebars"),

        attributes: {
            class: 'composite-widget'
        },

        regions: {
            'child': '.child'
        },

        modelEvents: {
            'change:chart-type': 'chartTypeChanged'
        },

        //----------------------------------------------

        /*
         * The default chart type
         */
        defaultChartType: null,

        defaultCoarseness: Beef.Widget.MetricSettings.Coarseness.total,

        getCsvEntries: function(filename) { return this.child.currentView.getCsvEntries(filename); },

        refresh: function() { this.child.currentView.refresh(); },

        /*
         * A map between chart types and a function (or name of a function) that will return an
         * instance of the appropriate view.
         */
        typeViews: {},

        //----------------------------------------------

        onFirstRender: function() {
            if (!this.model.get('chart-type')) this.model.set({'chart-type': this.defaultChartType}, {silent: true});
            if (!this.model.get('coarseness')) {
                var coarseness = this.defaultCoarseness;
                if (isFunction(this.defaultCoarseness)) coarseness = this.defaultCoarseness();
                this.model.set({'coarseness': coarseness}, {silent: true});
            }
            this.chartTypeChanged();
        },

        //----------------------------------------------

        chartTypeChanged: function() {
            var chartType = this.model.get('chart-type');
            if (chartType === this._chartType) return;  // hack to work around other buggy code changing model in
            this._chartType = chartType;                // render and so on

            var fun = this.typeViews[chartType];

            if (!fun) throw new Error('No initialisation function for chart-type [' + chartType + ']');

            this.child.reset();

            var view = null;
            if (isFunction(fun)) view = fun.call();
            if (isString(fun)) view = this[fun]();

            if (!view) throw new Error('Unable to execute view initialisation for [' + chartType + ']');
            this.model.generalData.set('_message', null);
            this.child.show(view);

            this.imageExportDisabled = view.imageExportDisabled;
            this.svgExportDisabled = view.svgExportDisabled ? view.svgExportDisabled : false;
        }

        //----------------------------------------------
    });
});