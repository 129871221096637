export default function (v1, op, v2, options) {
    switch (op) {
        case '==':  return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===': return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '<':   return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':  return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':   return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':  return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '||':  return (v1 || v2) ? options.fn(this) : options.inverse(this);
        case '&&':  return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '!=':  return (v1 != v2) ? options.fn(this) : options.inverse(this);
        default:    return options.inverse(this);
    }
}
