<template>
    <div class="mention-themes deq-reset">
        <inline-loading-message class="mention-themes__loading" v-if="loading || loadingThemeTitles">
            Discovering themes...
        </inline-loading-message>
        <p v-else>
            These are some of the themes that appear in these mentions.
        </p>

        <div v-if="themesWithTitles.length" class="dark-scrollbars dark-scrollbars--visible" style="overflow-y: auto;">
            <popup-menu v-if="debug && !loadingThemeTitles">
                <template #activator>
                    <be-button link>🐛 debug menu</be-button>
                </template>
                <div class="mini-menu mention-themes__debug-menu">
                    <div class="menu-item">
                        <a @click="init(false)">Regenerate themes</a>
                    </div>
                    <div class="header">Theme titles</div>
                    <div class="menu-item" v-for="theme in themesWithTitles" :key="theme.id">
                        <div>
                            {{ theme.title }}
                        </div>
                        <a :href="`${turduckenTitlesUrl}/${theme.titleId}`"
                           tooltip="Open on turducken"
                           target="_blank"><i class="icon-link-ext"></i>Open on turducken</a>
                        <a @click="fetchThemeTitle(false, theme)">
                            Regenerate
                        </a>
                    </div>
                </div>
            </popup-menu>

            <selector-menu style="background: #222">
                <SelectorMenuItem :active="allMentionsActive"
                                  @click="allMentionsClicked"
                                  tooltip="See mentions across all themes">
                    All mentions and themes
                </SelectorMenuItem>
                <selector-menu-item v-for="theme in themesWithTitles"
                                    class="short-animated fadeIn"
                                    :key="theme.id"
                                    :active="theme.isSelected"
                                    tooltip="Click to see mentions related to this theme"
                                    @click="themeClicked(theme)">
                    {{ theme.title }}
                </selector-menu-item>
            </selector-menu>
        </div>
        <no-topics-message class="short-animated fadeIn" v-else-if="!loading && !loadingThemeTitles">No themes available</no-topics-message>
    </div>
</template>

<script>
import {getTitle} from "@/app/utils/turducken";
import InlineLoadingMessage from "@/components/InlineLoadingMessage.vue";
import {isDebugModeEnabled} from "@/app/Features";
import {isOps} from "@/app/Permissions";
import {currentAccountCode, isDevEnvironment} from "@/app/utils/Account";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage.vue";
import SelectorMenu from "@/components/selector-menu/SelectorMenu.vue";
import SelectorMenuItem from "@/components/selector-menu/SelectorMenuItem.vue";
import {caper} from "@/store/Services";
import BeButton from "@/components/buttons/BeButton.vue";
import PopupMenu from "@/components/PopupMenu.vue";

export default {
    name: "MentionThemes",
    components: {PopupMenu, BeButton, SelectorMenuItem, SelectorMenu, NoTopicsMessage, InlineLoadingMessage},

    props: {
        filter: {
            type: String,
            required: true
        },
        singleThemeSelect: Boolean
    },

    data: function() {
        return {
            loading: false,
            themes: [],
            debug: isDebugModeEnabled(),
            isOps: isOps(),
            themeMergePromise: null,
            abortController: null
        }
    },

    watch: {
        filter() {
            this.init(); // todo this init function needs to be named better.
        },
        loadingThemeTitles(value) {
            if (value === false) {
                this.abortController = null;
            }
        },
        loading(value) {
            this.$emit("loading", value);
        }
    },

    created() {
        this.init();
    },

    destroyed() {
        if (this.abortController) {
            this.abortController.abort();
        }
    },

    computed: {
        allMentionsActive() {
            return !this.themes.find(theme => theme.isSelected);
        },

        turduckenTitlesUrl() {
            return isDevEnvironment() ? "http://localhost:8223/pages/titles" : "https://turducken.dataeq.com/pages/titles";
        },

        loadingThemeTitles() {
            return !!this.themes.find(theme => theme.loadingTitle);
        },

        themesWithTitles() {
            return this.themes.filter(theme => theme.title);
        }
    },

    methods: {
        async init(fromCache) {
            try {
                if (this.abortController) {
                    try {
                        this.abortController.abort();
                    } catch(e) {
                        console.warn(e);
                    }
                }
                this.abortController = new AbortController();
                this.loading = true;
                fromCache ??= true;

                if (!fromCache) {
                    this.themes = [];
                }

                await this.fetchThemes(fromCache);

                if (this.themes.length) {
                    this.themes.forEach(theme => {
                        this.fetchThemeTitle(true, theme);
                    });
                }
            } catch (e) {
                if (e.message === "canceled") return;
                console.warn("Error occurred while fetching mention themes: ", e);

                this.themes = [];
            } finally {
                this.loading = false;
            }
        },

        async fetchThemes(fromCache) {
            fromCache ??= true;

            let payload = {
                filter: this.filter,
                clusterLimit: 8,
                mentionLimit: 1000,
                allowCached: fromCache
            };

            let config = {
                signal: this.abortController.signal
            };

            let response = await caper.post(`/v4/accounts/${currentAccountCode()}/mentions/cluster`, payload, config);

            response.data?.clusters?.forEach((theme, index) => {
                if (theme.mentionIds) {
                    this.themes.push({
                        id: index,
                        filter: `ID IN (${theme.mentionIds.map(id => `'${id}'`).join(",")})`,
                        mentionIds: theme.mentionIds,
                        title: null,
                        titleId: null,
                        loadingTitle: false,
                        isSelected: false
                    })
                }
            });
        },

        async fetchThemeTitle(fromCache = true, theme) {
            try {
                theme.loadingTitle = true;

                let themeFilter = theme.filter;

                let titleResponse = await getTitle(themeFilter, this.abortController, currentAccountCode(), fromCache);

                theme.title = titleResponse.title;
                theme.titleId = titleResponse.id;

                // merge theme if it has the same title as an existing theme
                if (this.themeMergePromise) {
                    await this.themeMergePromise;
                    await this.mergeThemes(theme);
                } else {
                    await this.mergeThemes(theme);
                }

                this.themeMergePromise = null;
            } catch (e) {
                console.warn("error fetching theme title: ", e);
            } finally {
                theme.loadingTitle = false;
            }
        },

        async mergeThemes(themeToCheck) {
            this.themeMergePromise = new Promise(resolve => {
                let mergedTheme = false;

                for (const theme of this.themes) {
                    if (theme.title && themeToCheck.id !== theme.id) {
                        if (theme.title.toLowerCase() === themeToCheck.title.toLowerCase()) {
                            theme.mentionIds = [...theme.mentionIds, ...themeToCheck.mentionIds];
                            mergedTheme = true;
                            break;
                        }
                    }
                }

                if (mergedTheme) {
                    this.themes = this.themes.filter(t => t.id !== themeToCheck.id);
                }

                resolve(true);
            });

            return this.themeMergePromise;
        },

        allMentionsClicked() {
            if (this.allMentionsActive) return;

            const selected = this.themes.find(theme => theme.isSelected);
            this.themes?.forEach(theme => theme.isSelected = false);
            if (selected) {
                this.$emit('unselect-theme', selected);
            }
        },

        themeClicked(theme) {
            if (theme.loadingTitle || !theme.title) return;

            theme.isSelected = !theme.isSelected;

            if (theme.isSelected) {
                // unselect other themes if singleThemeSelect property is true
                if (this.singleThemeSelect) {
                    this.themes.forEach(curTheme => {
                        if (curTheme.id !== theme.id) {
                            curTheme.isSelected = false;
                        }
                    });
                }

                this.$emit('select-theme', theme);
            } else {
                this.$emit('unselect-theme', theme);
            }
        }
    }
}
</script>

<style scoped lang="sass">

.mention-themes
    display: flex
    flex-direction: column
    overflow-y: auto

    &__loading
        display: block
        margin-bottom: 20px

    &__debug-menu

        .menu-item
            padding: 2px 5px
            display: flex
            column-gap: 10px

            a:hover
                cursor: pointer

        .header
            background: #222
            text-transform: uppercase
            text-align: center
            color: var(--be-colour-mid-grey)


</style>