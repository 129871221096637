import VuexStore from "@/store/vuex/VuexStore";
import {escapeExpression, escapeHtml} from "@/app/utils/StringUtils";


/**
 * Invoke the block with an object containing author information for a mention (the current context).
 */
export default function(options) {
    var link = this.authorProfileLink,
        avatar = this.authorPictureLink,
        name = this.authorName,
        screenName = this.authorHandle,
        mentionLink = this.link,
        visibility = this.visibility;
    var followOnTwitter, i, id, n, failAvatar;
    failAvatar = Beef.resources["twitter_avatar.png"];

    var site = this.site;
    var uri = this.uri;

    var broadcastMedia = uri && (uri.toLowerCase().indexOf('.emediamonitor.net') != -1
        || uri.toLowerCase().indexOf('video-cache.brandseye.com') != -1
        || uri.toLowerCase().indexOf('media-player.brandseye.com') != -1);
    var novusBroadcastMedia = broadcastMedia && uri.toLowerCase().indexOf('media-player.brandseye.com') != -1;
    var printMedia = uri && (uri.toLowerCase().indexOf('pressdisplay-redirect.brandseye.com') != -1
        || uri.toLowerCase().startsWith('http://media-cache.brandseye.com'));
    var tweet = isTweet(this);
    var facebook = !tweet && site && site.endsWith("www.facebook.com");
    var tumblr = this.socialNetwork?.id === 'TUMBLR' || (!tweet && site && site.endsWith("tumblr.com"));
    var googleplus = !tweet && site == "plus.google.com";
    var instagram = !tweet && site.endsWith("instagram.com");
    var youtube = !tweet && site.endsWith("youtube.com");
    var linkedin = !tweet && site.endsWith("linkedin.com");
    var tiktok = !tweet && site.endsWith("tiktok.com");
    var vk = !tweet && site.endsWith("vk.com");
    const hellopeter = this.socialNetwork?.id === 'HELLOPETER' || (!tweet && site.endsWith('hellopeter.com'));
    const googleBusiness = this.socialNetwork?.id === 'GOOGLEBUSINESS' || (!tweet && site.endsWith('google.com'));
    const trustpilot = site === 'www.trustpilot.com';

    var moreover = uri && (uri.indexOf("moreover") > -1);
    if (moreover) link = uri;

    if (tweet && screenName) {
        followOnTwitter = "https://twitter.com/intent/user?screen_name=" + screenName;
        screenName = "@" + screenName;
    }
    if (facebook) {
        failAvatar = Beef.resources["facebook_avatar.png"];

        if (!name && !screenName) name = "Anonymised Facebook User";

        var split = mentionLink.substr(mentionLink.indexOf('.com')).split('/');
        let pageName = null;

        if (split.length >= 2) {
            const profile = VuexStore.getters["profiles/handleIdToProfile"](split[1]);
            if (profile && profile.name) pageName = escapeExpression(profile.name);
        }

        if (pageName) {
            if (visibility) {
                screenName = new Handlebars.SafeString("DMing with <strong>" + (this.toName || pageName) + "</strong>");
            } else {
                screenName = new Handlebars.SafeString("On page <strong>" + pageName + "</strong>");
            }
        }

    }

    // Both of these cases are review sites that mostly have the same logic.
    if (hellopeter || trustpilot) {
        if(hellopeter){
            screenName = "Hellopeter"; 
        }
        if(trustpilot){
            screenName = "Trustpilot"
        }
        if (this.toHandle) screenName = escapeHtml`Reviewing ${this.toHandle}`;
        if (this.toHandle && this.replyToUri) screenName = escapeHtml`Responding to ${this.toHandle}`;
    }

    let address = null; 
    if (googleBusiness) { 
        screenName = "Google Business";
        const profile = VuexStore.getters["profiles/handleIdToProfile"](this.toHandleId);  
        address = profile?.description;
        if (this.toHandle){
            if (this.uri.startsWith("https://www.google.com/search?")){
                screenName = escapeHtml`Asking ${this.toName}  ${address}`;
                link = this.link;
            } else {
                screenName = escapeHtml`Reviewing ${this.toName}  ${address}`;
            }
        }
        
        if (this.toHandle && this.replyToUri) screenName = escapeHtml`Responding to ${this.toName}`;
        let replyProfile = VuexStore.getters["profiles/handleIdToProfile"](this.authorHandleId);  
        if (this.authorHandleId == replyProfile?.handleId) {
            avatar = replyProfile?.pictureLink?replyProfile.pictureLink:"/img/business_icon.svg";
            failAvatar = "/img/business_icon.svg";
        }
    }

    if (googleplus) failAvatar = Beef.resources["googleplus_avatar.png"];
    if (instagram) failAvatar = Beef.resources["instagram_avatar.jpg"];
    if (youtube) failAvatar = Beef.resources["googleplus_avatar.png"];


    if (!avatar) {
        if (tumblr && this.site) {
            avatar = "http://api.tumblr.com/v2/blog/" + this.site + "/avatar/128"
        } else if (broadcastMedia) {
            avatar = Beef.resources["avatar_broadcast_media.png"];
        } else if (printMedia) {
            var ml = this.mediaLinks;
            avatar = (ml && ml.length && ml.length >= 1) ? ml[0].url : Beef.resources["press_avatar.png"];
        } else {
            switch (this.media) {
                case "PRESS":       avatar = Beef.resources["press_avatar.png"];        break;
                case "ENTERPRISE":  avatar = Beef.resources["enterprise_avatar.png"];   break;
                case "DIRECTORY":   avatar = Beef.resources["directory_avatar.png"];    break;
                default:            avatar = Beef.resources["facebook_avatar.png"];
            }
        }
    }

    if (printMedia || novusBroadcastMedia) {
        name = this.title;
        screenName = this.site;
    } else {
        if (!name) {
            name = this.authorName;
            if (!name) {
                if (!screenName) {
                    name = this.title;
                    screenName = site;
                } else {
                    name = site;
                }
            }
        }
        if (!screenName) {
            if (name == site) screenName = "\u00A0";
            else screenName = site;
        }
    }

    // Handle instagram authors
    if (name === 'www.instagram.com') {
        name = screenName;
        screenName = 'www.instagram.com';
    }

    if (broadcastMedia || printMedia) link = this.uri;

    if (this._isDeletedOnNetwork) link = null;

    return options.fn({
        id: null,
        link: link,
        avatar: avatar,
        failAvatar: failAvatar,
        name: name,
        screenName: screenName,
        followOnTwitter: followOnTwitter,
        address: address,
        facebook: facebook,
        googleplus: googleplus,
        instagram: instagram,
        youtube: youtube,
        linkedin: linkedin,
        tiktok: tiktok,
        hellopeter,
        googleBusiness,
        tumblr,
        telegram: this.socialNetwork && this.socialNetwork.id === "TELEGRAM",
        trustpilot: this.socialNetwork?.id === "TRUSTPILOT",
        vk: vk,
        website: !this.socialNetwork && !hellopeter && !googleBusiness,
        description: this.authorBio,
        location: this.authorLocation,
        timezone: this.authorTimezone,
        hasBio: this.authorBio || this.authorLocation || this.authorTimezone,
        twitterUserId: tweet ? this.authorHandleId : null,
        _options: this._options
    });
}

var isTweet = function(mention) { return mention.site && mention.site.endsWith("twitter.com") };

