/**
 * Displays a graph of mentions. The attributes of the selected mention can be edited.
 */
import {toGrouseLink, grouseGet} from "@/data/Grouse";
import {adjustContentHeight} from "@/app/Beef";

Beef.module("MentionGraph").addInitializer(function(startupOptions) {

    /**
     * Create and display our view starting at the mention. Returns the view.
     */
    this.show = function(mentionItem, accountCode) {
        var ro = $('#content').offset();
        var $mel = mentionItem.view.$el;
        var o = $mel.offset();
        // mentions are centered around their x and y coords
        mentionItem.x = mentionItem.px = (o.left - ro.left) + $mel.width() / 2;
        mentionItem.y = mentionItem.py = (o.top - ro.top) + $mel.height() / 2;

        var model = new Backbone.Model({
            accountCode: accountCode,
            selection: mentionItem
        });

        // each mention item has a collection reference that will be changed when we add it to our collection so
        // preserve this info
        var originalCollection = mentionItem.collection;

        var collection = new Backbone.Collection();
        collection.url = toGrouseLink('/v4/accounts/' + accountCode + '/mentions', null, true);
        collection.add(mentionItem);

        var v = new View({model: model, collection: collection, originalCollection: originalCollection,
            cache: mentionItem.view.cache});
        if (originalCollection) {
            v.on("close", function(){
                var models = originalCollection.models;
                for (var i = 0; i < models.length; i++) {
                    var m = models[i];
                    m.collection = originalCollection;
                    m.graphView = null;
                }
            });
        }

        Beef.overlay.show(v);
        v.fetchGraph();
        return v;
    };

    var View = Backbone.Marionette.CompositeView.extend({
        template: require("@/mentions/MentionGraph.handlebars"),

        attributes: { class: 'mention-graph', tabindex: "0" },

        itemViewContainer: ".mentions",

        itemView: Beef.MentionItem.View,

        initialize: function(options) {
            this.collection.accountCode = this.accountCode = this.model.get('accountCode');
            this.originalCollection = options.originalCollection;
            // sort oldest first
            this.collection.comparator = function(a, b) {
                return a.attributes.published.localeCompare(b.attributes.published);
            };
            this.layoutOffsetX = 0;
            this.layoutOffsetY = 0;
            this.rootMentions = this.collection.toArray();
        },

        events: {
            "click .mention-graph-close": "close",
            "click .mention-graph-go": "layout"
        },

        modelEvents: {
            "change:selection": "updateAddressBar"
        },

        fetchGraph: function() {
            var ids = this.collection.pluck("id");
            if (ids.length == 0) return;

            var filter = "ID in (";
            for (var i = 0; i < ids.length; i++) {
                if (i > 0) filter += ",'" + ids[i] + "'";
                else filter += "'" + ids[i] + "'";
            }
            filter += ")";

            var qs = Beef.MentionList.getMentionQueryString(null, filter, null, 100) + "&fetchGraph=true";

            grouseGet("/v4/accounts/" + this.accountCode + "/mentions?" + qs)
                .then(this.addMentions.bind(this),
                    function() { alert("There has been an error talking to our servers. Please try refreshing the page.") })
                .catch(e => console.error(e));
        },

        addMentions: function(data) {
            var root = this.collection.first();
            for (var i = 0; i < data.length; i++) {
                var m = data[i];
                var model = this.collection.get(m.id);
                if (model) {
                    model.set(m);
                } else {
                    if (this.originalCollection) model = this.originalCollection.get(m.id);
                    if (model) model.set(m);
                    else model = new Beef.MentionItem.Model(m);
                    this.collection.add(model);
                }
            }

            for (i = 0; i < this.collection.length; i++) {
                var attrs = this.collection.at(i).attributes;
                if (attrs.reshareOfId) {
                    model = this.collection.get(attrs.reshareOfId);
                    if (model) {
                        if (!model.attributes.reshares) model.attributes.reshares = [];
                        model.attributes.reshares.push(attrs.id);
                    }
                }
                if (attrs.replyToId) {
                    model = this.collection.get(attrs.replyToId);
                    if (model) {
                        if (!model.attributes.replies) model.attributes.replies = [];
                        model.attributes.replies.push(attrs.id);
                    }
                }
            }

            // place new models in concentric circle's around our root model
            var models = this.collection.models;
            var cx = root.x;
            var cy = root.y;
            arrangeInRings(models, function(m, ringNo, i, n){
                if (m.x) return;
                var radians = i * (Math.PI * 2 / n);
                var r = ringNo * 200;
                m.x = cx + r * Math.cos(radians);
                m.y = cy + r * Math.sin(radians);
            });

            this.layout();
        },

        itemViewOptions: function(model, index) {
            return {
                ball: true,
                small: !model.attributes.engagement,
                noSelect: true,
                noViewConversation: true,
                cache: this.cache
            }
        },

        onAfterItemAdded: function(view) {
            view.model.graphView = view;
        },

        onRender: function() {
            if (!Number.isFinite(this.originalScrollTop)) {
                this.originalScrollTop = window.pageYOffset;
                window.scrollTo(0, 0);
            }

            var e = this.$el;
            var $content = $('#content');
            e.css('minHeight', $content.height() + "px");
            this.updateAddressBar();

            if (!this._rendered) {
                this._rendered = true;

                setTimeout(function(){
                    pulse(this.rootMentions[0].graphView.$el, true);
                    e.toggleClass('mention-graph-fadein', true);
                    this.initToolbar();
                    this.layout();
                }.bind(this));
            }
        },

        initToolbar: function() {
            var tb = this.$(".mention-graph-toolbar");
            tb.css({top: "59px", left: tb.offset().left + "px", margin: "0", position: "fixed"});
        },

        updateAddressBar: function() {
            var sel = this.model.get('selection');
            if (sel) {
                if (!this.originalLocation) {
                    var s = window.location.toString();
                    this.originalLocation = s.substring(s.indexOf(window.location.pathname));
                }
                Beef.router.navigate("/" + this.model.get('accountCode') + "/mentions/" + sel.get('id'), {replace: true});
            }
        },

        onClose: function() {
            if (Number.isFinite(this.originalScrollTop)) {
                window.scrollTo(0, this.originalScrollTop);
                this.originalScrollTop = null;
            }

            if (this.force) {
                this.force.stop();
                this.force = null;
            }
            if (this.originalLocation) Beef.router.navigate(this.originalLocation, {replace: true});
        },

        layout: function() {
            if (!this.force) {
                this.forceLink = d3.forceLink().distance(function(link){ return link.length * 200 });

                // use the height of the window and not our element as we will be very long if opened on a long page
                var height = $(window).height() - 41;
                this.force = d3.forceSimulation() //.charge(-2500);
                    .force('charge', d3.forceManyBody().strength(-2500))
                    .force('center', d3.forceCenter(this.$el.width() / 2, height / 2))
                    .force('link', this.forceLink)
                    .on("tick", this.onTick.bind(this));
            }

            var models = this.collection.models;
            var links = [], i;
            for (i = 0; i < models.length; i++) {
                this.createLinks(models[i], links);
            }

            // create lines in reverse order so older mention lines are on top of newer lines because older mentions
            // are on the outside of the circles we build and hence have longer lines
            var html = [];
            for (i = links.length - 1; i >= 0; i--) {
                // only create visible lines for links with a type
                var link = links[i];
                if (link.type) {
                    var title = link.type == "reshare" ? "Reshare" : "Reply";
                    html.push("<div class='line line-" + link.type + "' title='" + title + "'></div>");
                }
            }
            var $lines = this.$(".lines");
            $lines.html(html.join(""));
            $lines = $lines.children();
            var c;
            for (i = links.length - 1, c = 0; i >= 0; i--) {
                if (links[i].type) links[i].el = $lines[c++];
            }

            this.force.nodes(models);
            this.forceLink.links(links);
        },

        createLinks: function(from, links) {
            var reshares = from.attributes.reshares;
            var replies = from.attributes.replies;
            if (!reshares && !replies) return;

            // Create single list of all reshared and reply models sorted oldest first.
            // It is possible that referenced mentions are not in the graph yet if the model has been previously
            // displayed on a graph and hence has reshares and replies filled in before fetch graph call returns.
            var a = [], i, m;
            if (reshares) {
                for (i = 0; i < reshares.length ; i++) {
                    m = this.collection.get(reshares[i]);
                    if (m) a.push(m);
                }
            }
            if (replies) {
                for (i = 0; i < replies.length ; i++) {
                    m = this.collection.get(replies[i]);
                    if (m) {
                        m.reply = true;
                        a.push(m);
                    }
                }
            }
            a.sort(this.collection.comparator);

            // arrange in concentric rings with more mentions in outer rings
            arrangeInRings(a, function(m, ringNo){
                links.push({source: from, target: m, type: m.reply ? "reply" : "reshare", length: ringNo * 0.9 + 0.1});
            });
        },

        onTick: function(ev) {
            this.positionModels(true);
            this.positionLinks();
            pulse(this.rootMentions[0].graphView.$el, this.force.alpha() >= 0.01);
        },

        positionModels: function(adjustGraphSize) {
            var m, models = this.collection.models;

            if (adjustGraphSize) {

                // see how big the layout is
                var minX = 5000, maxX = 0, minY = 5000, maxY = 0;
                for (var i = 0; i < models.length; i++) {
                    m = models[i];
                    if (m.x < minX) minX = m.x;
                    if (m.x > maxX) maxX = m.x;
                    if (m.y < minY) minY = m.y;
                    if (m.y > maxY) maxY = m.y;
                }
                var layoutWidth = Math.floor(maxX - minX + 140 + 40);   // 140 is size of small ball
                var layoutHeight = Math.floor(maxY - minY + 140 + 40);  // 20 = 10 margin either side

                // don't resize us until layout has settled down as it starts off huge
                var gw = this.$el.width();
                var gh = this.$el.height();
                if (this.force.alpha() < 0.7 && (gw != layoutWidth || gh != layoutHeight)) {
                    this.$el.css({width: (gw = layoutWidth) + "px", height: (gh = layoutHeight) + "px"});
                    var pw = this.$el.parent().width();
                    adjustContentHeight();
                }

                // adjust layout coordinates so most left = 0, topmost = 0
                this.layoutOffsetX = -minX + 70 + 20;    // 70 is half small ball
                this.layoutOffsetY = -minY + 70 + 20;    // 20 is margin

                // centre layout in our area if it is small
                gw = this.$el[0].offsetWidth;   // have to use this instead of width() as that uses css set width
                var s = gw - layoutWidth;
                if (s > 0) this.layoutOffsetX += s/2;
                s = gh - layoutHeight;
                if (s > 0) this.layoutOffsetY += s/2;
            }

            var ox = this.layoutOffsetX;
            var oy = this.layoutOffsetY;
            for (i = 0; i < models.length; i++) {
                m = models[i];
                if (!m.graphView) continue;
                var $el = m.graphView.$el;
                if ($el) $el.css({ left: (ox + m.x) + "px", top: (oy + m.y) + "px", display: "block" });
            }
        },

        positionLinks: function() {
            var links = this.forceLink.links();
            var ox = this.layoutOffsetX;
            var oy = this.layoutOffsetY;
            for (var i = 0; i < links.length; i++) {
                var link = links[i];
                if (link.el) {
                    link.el.style.cssText = connect(ox + link.source.x, oy + link.source.y,
                        ox + link.target.x, oy + link.target.y, 15);
                }
            }
        }
    });

    /**
     * Split a into concentric rings with more items in the outer rings. The callback is passed the item, its
     * ring number (1 based), its index in the ring (zero based) and the number of items in the ring.
     */
    var arrangeInRings = function(a, callback) {
        var ringNo = 0, ringStart = 0, ringSize = 0, ringDistance = 0.1;
        for (var i = 0; i < a.length ; i++) {
            if (i >= ringStart + ringSize) {
                ++ringNo;
                ringSize = Math.floor(Math.PI * (ringNo + ringNo) / 0.89);
                ringStart = i;
                ringDistance += 0.9;
            }
            callback(a[i], ringNo, i - ringStart, ringSize);
        }
    };

    /**
     * Return cssText for a div that will connect the points.
     * http://stackoverflow.com/questions/8672369/how-to-draw-a-line-between-two-divs
     */
    var connect = function(x1, y1, x2, y2, thickness) {
        var length = Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));
        var cx = ((x1 + x2) / 2) - (length / 2);
        var cy = ((y1 + y2) / 2) - (thickness / 2);
        var angle = Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI);
        return "height:" + thickness + "px; left:" + cx + "px; top:" + cy + "px; width:" +
            length + "px; -moz-transform:rotate(" + angle + "deg); -webkit-transform:rotate(" + angle +
            "deg); -o-transform:rotate(" + angle + "deg); -ms-transform:rotate(" + angle + "deg); transform:rotate(" +
            angle + "deg);";
    };

    var pulse = function($el, on) {
        if ($el) $el.toggleClass("animated animate-forever pulse", on);
    };

});