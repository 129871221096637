<template>
    <div class="google-select-profile-type">
        <h4>Select the type of <i class="symbol-google-full"/> Google business profile you would like to add</h4>

        <div class="google-select-profile-type__profile-type-container">
            <div class="btn google-select-profile-type__profile-btn" @click="selectProfileType(addProfileTypes.googleBusiness.PUBLIC)">
                <i class="icon-users"></i>
                Unauthorised Profile
            </div>
            <p class="google-select-profile-type__profile-description">
                Add an unauthorised Google business profile to the account. This will allow you to track public reviews.
            </p>
        </div>

        <div class="google-select-profile-type__divider"></div>

        <div class="google-select-profile-type__profile-type-container">
            <div class="btn google-select-profile-type__profile-btn" @click="selectProfileType(addProfileTypes.googleBusiness.MANAGED)">
                <i class="icon-lock-1"></i>
                Managed Profile
            </div>
            <p class="google-select-profile-type__profile-description">
                Add Google business profiles that you have authorised. Adding an authorised profile to an account will allow you to reply to reviews and questions from the app.
            </p>
        </div>
    </div>
</template>

<script>
import { addProfileMixins } from "@/setup/profiles/add-profile/AddProfileMixins";

export default {
    name: "GoogleSelectProfileType",
    mixins: [addProfileMixins],

    methods: {
        selectProfileType(profileType) {
            this.$emit('select-profile', profileType);
        }
    }
}
</script>

<style scoped lang="sass">

.google-select-profile-type

    p
        color: var(--be-colour-text-dark)

    h4
        margin-bottom: 40px

    &__profile-type-container
        display: flex
        align-items: center

    &__profile-btn
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        border-radius: 6px
        width: 150px
        height: 100px
        padding: 5px

        i
            font-size: 2em
            margin: 5px 0


    &__profile-description
        margin-left: 20px
        width: 75%

    &__divider
        margin: 15px 0
        width: 100%
        border-bottom: 1px solid #666

</style>