<template>
    <span ref="top">
        <span @click.stop="onActivatorClick" ref="target"><slot name="activator"/></span>
        <div v-if="showMenu" ref="popup" class="drop-down" :class="[{styled: !unstyled, fixed: fixed}, arrowPointerClass] ">
            <slot name="default"/>
        </div>
    </span>
</template>

<script>
    /**
     * Menu (can be any markup) triggered by user clicking an activator component (typically button or similar).
     * Can also be triggered via value property. Defaults to auto positioning to fit on screen if left/right and/or
     * top/bottom are not set. Favours bottom and right.
     */
    export default {
        name: "PopupMenu",

        props: {
            value: Boolean,
            unstyled: Boolean,
            left: Boolean,
            right: Boolean,
            top: Boolean,
            bottom: Boolean,
            neverHide: Boolean, // useful if you want to dictate when the menu closes in your component
            ignoreActivatorClick: Boolean,
            ignoreDocClick: Boolean, // Ignoring this click will stop the popup from automatically closing when interacted with
            stopMousedown: Boolean,
            fixed: Boolean,
            arrowPointer: { // Whether or not to show an arrow pointing to the activator
                type: Object,
                required: false,
                default() {
                    return {
                        show: false,
                        left: false
                    }
                }
            },
            posOffset: {
                type: Object,
                required: false,
                default() {
                    return {
                        top: 0,
                        left: 0
                    }
                }
            },
            useActivatorWidth: Boolean
        },

        data() {
            return {
                showMenu: this.value,
                menuTop: false
            }
        },

        watch: {
            value(v) {
                this.showMenu = v
            },

            showMenu(v) {
                if (v) {
                    this.$emit('popup-menu-visible', true);
                    this.addListeners()
                    this.$nextTick(() => this.detach())
                } else {
                    this.$emit('popup-menu-visible', false);
                    this.removeListeners()
                }
            }
        },

        computed: {
            arrowPointerClass() {
                if (!this.arrowPointer.show) return '';

                if (this.menuTop) {
                    return this.arrowPointer.left ? 'arrow-bottom-left' : 'arrow-bottom-right';
                } else {
                    return this.arrowPointer.left ? 'arrow-top-left' : 'arrow-top-right';
                }
            }
        },

        mounted() {
            if (this.showMenu) {
                this.addListeners()
                this.$nextTick(() => this.detach())
            }
        },

        beforeDestroy() {
            this.removeListeners()
            if (this.$refs.popup && this.$refs.popup.parentNode) {
                this.$refs.popup.parentNode.removeChild(this.$refs.popup)
            }
        },

        methods: {
            close() {
                this.showMenu = false;
            },

            onActivatorClick() {
                if (!this.ignoreActivatorClick) this.$emit("input", this.showMenu = !this.showMenu)
            },

            addListeners() {
                document.addEventListener("mousedown", this.onDocMouseDown, {capture: true})
                document.addEventListener("click", this.onDocClick, {capture: true})
                document.addEventListener("keydown", this.onDocKeyDown, {capture: true})
            },

            removeListeners() {
                document.removeEventListener("mousedown", this.onDocMouseDown, true)
                document.removeEventListener("click", this.onDocClick, true)
                document.removeEventListener("keydown", this.onDocKeyDown, true)
            },

            detach() {
                document.querySelector('body').appendChild(this.$refs.popup)
                this.reposition()
            },

            reposition() {
                let popup = this.$refs.popup
                let pr = popup.getBoundingClientRect()

                let target = this.$refs.target
                let tr = target.getBoundingClientRect()

                let topValue = 0;
                let leftValue = 0;

                if (this.useActivatorWidth) popup.style.width = tr.width + "px"

                let left = this.left && !this.right
                if (!left && !this.right) left = tr.left + pr.width > window.innerWidth

                if (left) leftValue = (tr.right - pr.width)
                else leftValue = tr.left

                let scrollTop = this.fixed ? 0 : (window.pageYOffset || document.documentElement.scrollTop)

                let top = this.top && !this.bottom
                if (!top && !this.bottom) top = tr.top + tr.height + pr.height > window.innerHeight

                if (top) topValue = (scrollTop + tr.top - pr.height)
                else topValue = (scrollTop + tr.top + tr.height)

                if (this.posOffset) {
                    topValue += this.posOffset.top;
                    leftValue += this.posOffset.left;
                }

                this.menuTop = top;

                popup.style.top = topValue + "px";
                popup.style.left = leftValue + "px";
            },

            onDocMouseDown(ev) {
                if (this.stopMousedown && this.$refs.popup.contains(ev.target)){
                    ev.preventDefault();
                    ev.stopPropagation();
                }

                if (this.neverHide) return;

                // hide menu if it's not clicked
                if (!this.$refs.popup.contains(ev.target)) {
                    this.$emit("input", this.showMenu = false);
                    return;
                }

                // don't hide the menu if the event is from one of our children .. otherwise clicks on the drop down
                // do not work
                if (!this.ignoreDocClick && this.showMenu && (!this.$refs.popup || !this.$refs.popup.contains(ev.target))) {
                    this.$emit("input", this.showMenu = false)
                }
            },

            onDocClick(ev) {
                if (this.neverHide) return;

                // hide menu if it's not clicked
                if (!this.$refs.popup.contains(ev.target)) {
                    this.$emit("input", this.showMenu = false);
                    return;
                }

                // hide the menu when one of our children is clicked
                if (!this.ignoreDocClick && this.showMenu && this.$refs.popup && this.$refs.popup.contains(ev.target)) {
                    this.$emit("input", this.showMenu = false)
                }

            },

            onDocKeyDown(ev) {
                if (this.neverHide) return;

                if (!this.ignoreDocClick && this.showMenu && ev.keyCode === 27) {
                    this.$emit("input", this.showMenu = false)
                }
            }
        }
    }
</script>

<style scoped>
    .arrow-top-left:before {
        position: absolute;
        top: -7px;
        left: 9px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #333;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    .arrow-top-left:after {
        position: absolute;
        top: -6px;
        left: 10px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #333;
        border-left: 6px solid transparent;
        content: '';
    }

    .arrow-bottom-left:before {
        position: absolute;
        bottom: -7px;
        left: 9px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-top: 7px solid #333;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    .arrow-bottom-left:after {
        position: absolute;
        bottom: -6px;
        left: 10px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-top: 6px solid #333;
        border-left: 6px solid transparent;
        content: '';
    }

    .arrow-top-right:before {
        position: absolute;
        top: -7px;
        right: 9px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #333;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    .arrow-top-right:after {
        position: absolute;
        top: -6px;
        right: 10px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #333;
        border-left: 6px solid transparent;
        content: '';
    }

    .arrow-bottom-right:before {
        position: absolute;
        bottom: -7px;
        right: 9px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-top: 7px solid #333;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    .arrow-bottom-right:after {
        position: absolute;
        bottom: -6px;
        right: 10px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-top: 6px solid #333;
        border-left: 6px solid transparent;
        content: '';
    }

    .drop-down {
        position: absolute;
        z-index: 1031;
    }

    .drop-down.fixed {
        position: fixed;
    }

    .styled {
        background-color: #333;
        border: 1px solid #222;
        border-radius: 6px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }

    .divider {
        display: block;
        margin: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #222222;
        cursor: default;

    }
</style>