<template>
    <span class="be-date" v-if="!humanise">{{formatDate(date, format)}}</span>
    <span class="be-date" v-else>{{ humanizeDate(date) }}</span>
</template>

<script>

    import {formatDate, humanizeDate} from "@/app/utils/Format";

    /**
     * Formats a date to a specified format, using the account's timezone.
     */
    export default {
        props: {
            date: {
                type: String,
                required: true
            },

            format: {
                type: String,
                default() { return "YYYY-MM-DD HH:mm" }
            },

            humanise: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            humanizeDate,
            formatDate
        }
    }
</script>

<style scoped lang="sass">
    .be-date
        font-family: var(--number-font-family)
</style>