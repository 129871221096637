<template>
    <div class="account-menu short-animated fadeIn">
        <ul>
            <li><a title="See your user options" @click="openUserSettings">User options…</a></li>
            <li class="account-menu__divider"></li>
        </ul>

        <ul v-if="showSearch">
            <li>
                <search-input
                    class="account-menu__accounts-search-bar"
                    autofocus
                    placeholder="Type account code or name to search…"
                    v-model="accountSearchTerm"/>
            </li>
            <li class="account-menu__divider"></li>
        </ul>

        <div class="account-menu__loader" v-if="loadingAccounts"><spinner-component :size="15" />Fetching your accounts...</div>
        <div v-else class="account-menu__accounts-list dark-scrollbars dark-scrollbars--visible" tabindex="-1">
            <ul>
                <li v-for="account in filteredAccounts"
                    :key="account.code"
                    class="account-menu__account"
                    tabindex="0"
                    @keydown.enter.stop="navigateToAccount(account.code)">
                    <a :href="getAddress(account.code)" tabindex="-1">
                        <span class="account-menu__account-name">{{ account.name }}</span>
                        <span class="account-menu__account-code" :class="account.status">{{ account.code }}</span>
                    </a>
                </li>
            </ul>
        </div>

        <ul v-if="user.admin">
            <li class="account-menu__divider"></li>
            <li><a :href="accountMashLink" target="_blank" :class="{'disabled' : !accountMashLink}">Open {{ account.code }} in Mash…</a></li>
        </ul>

        <ul>
            <li class="account-menu__divider"></li>
            <li><a @click="logout" title="Log out of DataEQ">Log out…</a></li>
        </ul>
    </div>
</template>

<script>
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import SearchInput from "@/components/inputs/SearchInput";
import {showWhenDialog} from "@/app/framework/dialogs/Dialog";
import UserSettings from "@/app/framework/dialogs/user-settings/UserSettings";
import SpinnerComponent from "@/components/SpinnerComponent";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";

export default {
    name: "AccountMenu",
    components: {SpinnerComponent, SearchInput},
    store: VuexStore,

    props: {
        accounts: {
            type: Array,
            required: true
        },
        loadingAccounts: {
            type: Boolean,
            default: false
        }
    },

    data: function() {
        return {
            accountSearchTerm: "",
        }
    },

    computed: {
        ...mapState(['user', 'account']),

        accountMashLink() {
            return this.account?.code ? `https://mash.dataeq.com/account/show/${this.account.code}` : null
        },

        filteredAccounts() {
            let searchTerm = this.accountSearchTerm.toLowerCase();

            return this.filterAccounts(searchTerm);
        },

        showSearch() {
            return this.accounts?.length > 5;
        }
    },

    methods: {
        openUserSettings() {
            showDialog(UserSettings, null);
        },

        filterAccounts(searchTerm) {
            // reset tab nav
            this.curAccountHoverIndex = 0;

            return this.accounts.filter(account => account.name.toLowerCase().includes(searchTerm) || account.code.toLowerCase().includes(searchTerm));
        },

        logout() {
            showWhenDialog("Would you like to logout?", "Logout?")
                .then(() => {
                    try {
                        sessionStorage.clear();
                        for (const key of Object.keys(localStorage)) {
                            if (key.startsWith("safe-cache:")) continue;
                            try {
                                localStorage.removeItem(key);
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                    window.location = "/api/logout";
                });
        },

        navigateToAccount(accountCode) {
            if (accountCode) {
                window.location = this.getAddress(accountCode);
            }
        },

        getAddress(accountCode) {
            return `/${accountCode}/explore`;
        }
    }
}
</script>

<style scoped lang="sass">

.account-menu
    margin: 1px 0 0
    padding-top: 5px
    padding-bottom: 5px
    z-index: 1031
    width: 370px

    &__loader
        display: flex
        align-items: center
        flex-direction: column
        width: 100%

    li
        line-height: 20px

    li > a
        color: var(--nav-colour-base)
        display: block
        padding: 3px 10px

    li > a:hover
        color: #aee15d
        text-decoration: none
        background-color: #222222
        cursor: pointer

    li:focus
        outline: none
        border: 1px solid #aee15d
        border-radius: 6px
        color: #aee15d
        text-decoration: none
        background-color: #222222
        cursor: pointer

    ul
        list-style: none
        margin: 0

    &__account-selected
        color: #aee15d
        text-decoration: none
        background-color: #222222

    &__divider
        margin-bottom: 5px
        margin-top: 5px
        overflow: hidden
        background-color: #333333
        border-bottom: 1px solid #222222
        pointer-events: none
        padding: 0 !important

    &__accounts-search-bar
        width: 340px
        margin: 3px 10px

    &__accounts-list
        max-height: 65vh
        overflow-y: scroll

        li
            scroll-snap-align: start

    &__account-name
        width: 250px
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        display: inline-block

    &__account-code
        float: right
        font-family: monospace
        padding-right: 15px

    .ARCHIVED
        color: var(--ac-colour-archived)

    .SUSPENDED
        color: var(--ac-colour-suspended)

    .disabled
        color: #777

    .disabled:hover
        color: #333333
        cursor: default
</style>