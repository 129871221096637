import { getDeprecatedDefaultBrand } from "@/app/utils/Util";
import {buildBasicFilter} from "@/dashboards/filter/BasicFilter";
import _ from 'underscore';
import {currentAccountCode} from "@/app/utils/Account";
import {grouseGet} from "@/data/Grouse";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";
import {encloseInQuotes, removeSingleQuotes} from "@/app/utils/StringUtils";

/**
 * Select one or more V4 author IDs by typing the name. Auto-completes against recent authors in the account.
 * Fires a change event when the selection is changed. Also provides a model binder converter to display the
 * selected options in an element.
 */
Beef.module("AuthorIdPicker").addInitializer(function(startupOptions) {

    var thisModule = this;

    this.toNiceAuthorId = function(id, handle, name) {
        var prefix;
        if (handle) prefix = name ? handle + "(" + name + ")" : handle;
        else prefix = name;
        return prefix + ' ' + id;
    };

    var fetchAuthors = _.throttle(function(filter, limit, cb) {
        grouseGet("/v4/accounts/" + currentAccountCode() + "/mentions/count", {
            filter: filter,
            select: 'mostRecentMention[id,socialNetwork,authorHandleId,authorPictureLink,authorProfileLink,authorLocation]',
            groupBy: "authorId,authorName,authorHandle",
            limit: limit})
            .then(function (data) {
                var idToValue = {};
                for (var i = 0; i < data.length; i++) {
                    var a = data[i];
                    if (a.authorName || a.authorHandle) {
                        var id = thisModule.toNiceAuthorId(a.authorId, a.authorHandle, a.authorName);
                        idToValue[id] = a;
                        a.mostRecentMention.authorId = a.authorId;
                        a.mostRecentMention.authorName = a.authorName;
                        a.mostRecentMention.authorHandle = a.authorHandle;
                    }
                }
                cb(data, idToValue);
            });
    }, 1000);

    var fetchItems = function(q, view, cb) {
        if (!q || q.length < 2) {
            cb([]);
            return;
        }

        var brand = view.options.filterAttrs.brand;
        if (!brand) {
            var defaultBrand = getDeprecatedDefaultBrand(true);
            if (defaultBrand) brand = "" + defaultBrand.id;
        }

        if (!brand) {
            cb([]);
            return;
        }

        var attrs = Object.assign({}, view.options.filterAttrs);
        attrs.brand = brand;
        attrs.authorName = null;
        attrs.author = null;
        attrs.authorId = null;
        if (!attrs.published) attrs.published = "MONTH";
        var baseFilter = buildBasicFilter(attrs);
        var c = view._cached;
        if (c && q.indexOf(c.q) === 0 && c.baseFilter === baseFilter) {
            cb(c.idToValue);
        } else {
            var query = encloseInQuotes(q, '"');
            var filter = baseFilter + " and (AuthorName contains " + query + " or AuthorHandle contains " + query + ")";
            var limit = 400;
            fetchAuthors(filter, limit, function(data, idToValue){
                if (data.length < limit) view._cached = { q: q, baseFilter: baseFilter, idToValue: idToValue };
                cb(idToValue);
            });
        }
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "author-id-picker auto-complete-picker" },

        template: require("@/dashboards/filter/pickers/author/AuthorIdPicker.handlebars"),

        items: fetchItems,

        mustSelectFromItems: false,

        getName: function(item) {return item.authorName || item.authorHandle },

        nameFormatter: function(item) { return item.authorName || item.authorHandle },

        renderSelected: function() {
            var code = this.model.get('selected');
            this.$("tr").toggleClass("active", false);
            this.$("tr[data-value='" + code + "']").toggleClass("active", true);
        }
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            tooltip: function(code) { return "The author, " + code; },
            items: function(code) { return removeSingleQuotes(code) },
            placeholder: placeholder
        });
    };

    this.converter = this.createConverter("Start typing the author's name here");

    /**
     * Attach the picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, {quoteCodes: "'"});
    };

});
