<template>
    <div>
        <transition name="slide-content">
            <div v-if="!showAddProfile">
                <div v-if="twitterProfiles.length">
                    <p>
                        These are the X profiles that you have granted DataEQ access to. Click on a profile's row to view the accounts you can add it to.
                    </p>

                    <search-input
                        v-if="twitterProfiles.length > 5"
                        class="twitter-auth__search-bar"
                        autofocus
                        placeholder="Search for a profile"
                        v-model="searchTerm">
                    </search-input>
                    <div class="twitter-auth__profiles dark-scrollbars dark-scrollbars--visible"
                         :style="twitterProfiles.length > 5 ? 'height: clamp(230px, 22vh, 600px)' : 'height: clamp(265px, 26vh, 600px)'">

                        <div class="twitter-auth__headers">
                            <span>Profile</span>
                            <span>Reauthorise</span>
                            <span>Unauthorise</span>
                        </div>
                        <online-profile-row v-for="profile in filteredProfiles"
                                            :profile="profile"
                                            :linked-profile="profile.linkedProfile"
                                            :key="profile.handleId"
                                            :expand-row="showAccounts"
                                            :reauthorised-profiles="reauthorisedProfiles"
                                            :profile-just-reauthorised="profileJustReauthorised"
                                            @collapse-row="showAccounts = false"
                                            @refresh-profile-auth="authoriseProfile"
                                            @unauth-profile="triggerUnauthProfile"/>
                        <div style="margin-top: 10px" v-if="!filteredProfiles.length">
                            No profiles match your search
                        </div>
                    </div>
                </div>
                <div v-else>
                    You can authorise a X profile by clicking the "Authorise X Profile" button below.
                </div>

                <be-button class="twitter-auth__add-profile-button" @click="toggleAddProfile">
                   Authorise X Profile
                </be-button>
            </div>
        </transition>

        <transition name="slide-add-profile">
            <div v-if="showAddProfile" class="twitter-auth__add-profile-container">
                Once you authorise DataEQ to access an X account,
                you will be able to post and reply to mentions from within DataEQ. DataEQ will never post to one of your accounts without your permission.
                <div class="twitter-auth__add-profile">
                    <div class="twitter-auth__enter-profile-details">
                        <label>Enter X Handle<input type="text" v-model="searchProfile"/></label>
                        <be-button @click="searchForProfile"><i class="icon-search"/>Search for Profile</be-button>
                    </div>
                    <div class="twitter-auth__add-profile-divider"></div>
                    <div class="twitter-auth__found-profile">
                        <loading-message class="twitter-auth__add-profile-loader" v-if="searching" message="Searching..."/>
                        <div v-else>
                            Search Results
                            <online-profile v-if="foundProfile" :profile="foundProfile"/>
                            <div v-if="searchError">
                                {{ searchError }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-html="authMessage.message"
                     :style="{color: authMessage.type === 'WARN' ? 'var(--be-colour-warning)'
                     : authMessage.type === 'ERROR' ? 'var(--be-colour-negative)' : ''}"></div>

                <div class="twitter-auth__add-profile-button-bar">
                    <be-button link style="margin-left: auto" @click="toggleAddProfile">Back</be-button>
                    <be-button :disabled="disableAddButton" @click="authoriseProfile(foundProfile.handle, foundProfile.handleId)">Authorise Profile</be-button>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import {beef} from "@/store/Services";
import SearchInput from "@/components/inputs/SearchInput";
import {notifyWithText, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import BeButton from "@/components/buttons/BeButton";
import LoadingMessage from "@/components/LoadingMessage";
import OnlineProfileRow from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfileRow";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import {openWindowCenter} from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import {extractHandleId, profileTypes} from "@/setup/profiles/ProfileUtils";

export default {
    name: "TwitterAuth",
    store: VuexStore,
    components: {OnlineProfileRow, LoadingMessage, BeButton, OnlineProfile, SearchInput},

    props: {
        twitterProfiles: {
            type: Array,
            required: true
        },
        expandAccounts: {
            type: Boolean,
            default: false
        }
    },

    data: function() {
        return {
            searchProfile: "",
            searching: false,
            foundProfile: null,
            searchError: "",
            showAddProfile: false,
            authMessage: {
                message: "",
                type: "INFO"
            },
            profileTypes: profileTypes,
            searchTerm: "",
            showAccounts: false,
            reauthorisedProfiles: new Set(),
            profileJustReauthorised: ""
        }
    },

    created() {
        this.showAccounts = this.expandAccounts;
    },

    computed: {
        ...mapState(['user', 'account']),

        filteredProfiles() {
            let search = this.searchTerm.toLowerCase();

            let profiles = this.twitterProfiles.filter(profile => profile.name.toLowerCase().includes(search) ||
                profile.handle.toLowerCase().includes(search) ||  profile.handleId.toLowerCase().includes(search));

            return profiles.sort((a, b) => {
                return a.name > b.name ? 1 : -1
            });
        },

        disableAddButton() {
            return !this.canAuthoriseProfile();
        }
    },

    methods: {
        ...mapActions('userPlatformAuth', [
            'initializeManagedTwitterProfiles'
        ]),

        async searchForProfile() {
            try {
                this.searchError = "";

                let lookup = extractHandleId(this.searchProfile)

                this.searching = true;

                let response =  await beef.get(`/api/online-profile/validate?code=${this.account.code}&type=${profileTypes.twitter}&handle=${encodeURIComponent(lookup)}`);

                // If no profile is found and status is 200, response returns JSON with a message attribute
                if (response.data.message) {
                    this.foundProfile = null;
                    this.searchError = `No data found for profile \"${lookup}\"`;
                } else {
                    this.foundProfile = response.data;
                    this.foundProfile.type = profileTypes.twitter;
                }
            } catch (e) {
                this.foundProfile = null;
                this.searchError = "An error occurred while trying to fetch profile " + this.searchProfile;
            } finally {
                this.searching = false;
            }
        },

        toggleAddProfile() {
            this.showAddProfile = !this.showAddProfile;

            // only show platform chooser if add profile is not shown
            this.$emit('show-platform-chooser', !this.showAddProfile);
        },

        triggerUnauthProfile(args) {
          this.$emit('unauth-profile', args);
        },

        triggerFetchProfiles() {
            this.$emit('fetch-twitter-profiles', true);
        },

        setLoading(message, value) {
            this.$emit('loading-updated', message, value);
        },

        async authoriseProfile(handle, handleId, reauthorising) {
            try {
                reauthorising ??= false;

                this.setLoading("Communicating with X...", true);

                let payload = {
                    handle: handle,
                    handleId: handleId,
                    type: profileTypes.twitter
                }

                let response = await beef.post(`/api/online-profile/authorize-user-profile${this.account?.code ? `?accountCode=${this.account.code}` : ''}`, payload);

                // handles success/failure of callback
                let authFunctionName = `userPlatformAuth${this.user.id}`;

                window[authFunctionName] = (paramsMap) => {
                    if (paramsMap["auth"] === "success") {
                        this.triggerFetchProfiles(true);

                        notifyWithText("X profile successfully authorised.",
                            null,
                            "<i class='symbol-twitter'></i>");

                        this.showAccounts = !reauthorising;
                    } else {
                        this.authMessage = {
                            message: `An error occurred while trying to authorise profile ${handle}. Please reload the page and try again or contact DataEQ support.`,
                            type: "ERROR"
                        }
                    }
                }

                openWindowCenter(response.data.url, "", 600, 1000);
            } catch (error) {
                console.error("Error occurred while trying to authorise X profile " + handle + ": ", error);

                this.authMessage = {
                    message: `An error occurred while trying to authorise profile ${handle}. Please reload the page and try again or contact DataEQ support.`,
                    type: "ERROR"
                }

                this.setLoading("", false);

                notifyUserOfError("An error occurred while trying to authorise your X Profile. Please close the dialog and try again or contact support.");
            } finally {
                if (reauthorising) {
                    this.showAddProfile = false;
                    this.$emit('show-platform-chooser', true);
                    this.reauthorisedProfiles.add(handleId);
                    this.profileJustReauthorised = handleId;
                } else {
                    this.toggleAddProfile();
                }
            }
        },

        canAuthoriseProfile() {
            // currently searching for profile
            if (this.searching) {
                return false;
            }

            // profile could not be found by search
            if (!this.foundProfile) {
                this.authMessage = {
                    message: "No profile to authorise.",
                    type: "INFO"
                }

                return false;
            }

            let duplicateChecker = this.twitterProfiles.find(profile => profile.handleId === this.foundProfile?.handleId)

            // profile has already been authorised by this user
            if (duplicateChecker) {
                this.authMessage = {
                    message: `<i class='symbol-twitter'>${this.foundProfile.handle}</i> has already been authorised by you. You can repeat the process to refresh it's authorisation state.`,
                    type: "INFO"
                }
                return true;
            }

            this.authMessage = {
                message: `<i class='symbol-twitter'>${this.foundProfile.handle}</i> is a valid profile to authorise.`,
                type: "INFO"
            }
            return true;
        }
    }


}
</script>

<style scoped lang="sass">


.twitter-auth

    &__search-bar
        margin-bottom: 10px
        width: 90%

    &__profiles
        padding-right: 10px
        overflow-y: scroll

    &__headers
        display: grid
        cursor: pointer
        overflow-x: auto
        grid-template-columns: 350px repeat(auto-fit, minmax(100px, 1fr))
        position: sticky
        z-index: 10
        border: none
        color: var(--be-colour-text-dark)
        text-align: center

        span
            background: #222
            box-sizing: border-box
            border-top: 1px solid #1a1a1a
            border-right: 1px solid #1a1a1a
            padding: 5px 10px

            &:first-child
                border-top-left-radius: 3px
                border-left: 1px solid #1a1a1a

            &:last-child
                border-top-right-radius: 3px

    &__add-profile-container
        padding-right: 20px

    &__add-profile
        display: flex
        background: #444
        height: 90px
        border: 1px solid #272727
        padding: 10px
        margin-top: 20px
        margin-bottom: 30px
        border-radius: 6px

    &__enter-profile-details
        width: 50%

    &__found-profile
        width: 50%
        padding-left: 10px

    &__add-profile-divider
        margin: -10px 0
        border-right: 1px dashed #272727

    &__add-profile-loader
        margin-top: 0

    &__add-profile-button
        margin-top: 15px

        ::v-deep .btn
            background: var(--colour-twitter)

        &:hover
            ::v-deep .btn
                filter: brightness(1.2)

    &__add-profile-button-bar
        display: flex
        margin-top: 50px
        column-gap: 10px

.slide-content-enter-active,
.slide-add-profile-enter-active
    transition: all .5s ease

.slide-content-leave-active,
.slide-add-profile-leave-active
    transition: none
    opacity: 0

.slide-content-enter
    transform: translateX(-5px)
    opacity: 0

.slide-add-profile-enter
    transform: translateX(5px)
    opacity: 0

</style>