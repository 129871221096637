import * as Handlebars from 'handlebars';

/**
 * Provides a way to interpolate strings into an html fragment while escaping any contained html
 * Example of use:
 *      escapeHtml`The name is <strong>${variableToEscape}</strong>`
 * @return {string}
 */
export function escapeHtml(strings, ...rest) {
    let final = "";
    let i = 0;
    while (true) {
        if (i >= strings.length && (!rest || i >= rest.length)) break;

        if (i < strings.length) final += strings[i];
        if (i < rest?.length) final += escapeExpression(rest[i]);

        i++;
    }

    return final;
}

/**
 * Escapes any html characters in the given string.
 * @param {string} string
 * @return {string}
 */
export function escapeExpression(string) {
    return Handlebars.Utils.escapeExpression(string);
}

/**
 * Tests if the given object is a string or not.
 * @param {string} str
 * @return {boolean}
 */
export function isString(str) {
    return (str instanceof String) || (typeof str === 'string');
}

/**
 * If value starts with quoteChar or hyphen followed by a quoteChar then assume it is space separated quoted
 * strings with optional hyphen prefixes and split into quoted strings retaining hyphen prefixes
 * (i.e. each string has quotes and escaping intact). Otherwise just split at spaces. Default quoteChar is single
 * quote.
 *
 * If you want to split a string of items that are quoted, you likely want to use splitQuoatedString instead.
 * @param {String} text
 * @param {String} [quoteChar="'"]
 * @returns {String[]}
 */
export function splitAtSpaces(text, quoteChar= "'") {
    let n = text.length;
    let quoted = n > 0 && (text.charAt(0) === quoteChar || n > 1 && text.charAt(0) === '-' && text.charAt(1) === quoteChar );
    if (!quoted) return text.split(" ");
    let a = [], inside = false, i, p;
    for (i = p = 0; i < n; ++i) {
        let c = text.charAt(i);
        if (inside) {
            if (c === quoteChar) {
                a.push(text.substring(p, i + 1));
                inside = false;
            } else if (c === '\\') {
                ++i;    // skip over the next character so escaped quotes etc. don't break split
            }
        } else if (c === quoteChar) {
            inside = true;
            p = i;
        } else if (c === "-") {
            inside = true;
            p = i;
            if (i < n - 1 && text.charAt(i + 1) === quoteChar) ++i;
        }
    }
    if (inside) a.push(text.substring(p));
    return a;
}

/**
 * Given a string, this returns the same string with the first character capitalised.
 * @param {string} value
 * @return {string}
 */
export function capitalise(value) {
    if (value === "") return "";
    if (value.length === 1) return value.toUpperCase();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

/**
 * Split the text at spaces and dedup the resulting array. Returns null if s is undefined or empty.
 * @param {String|null} text
 * @returns {String[]|null}
 */
export function splitUnique(text) {
    let s = text.trim();
    if (s.length === 0) return null;
    let a = splitAtSpaces(s);
    switch (a.length) {
        case 0: return null;
        case 1: return a;
    }
    let hash = {};
    let out = [];
    for (let i = 0; i < a.length; i++) hash[a[i]] = 0;
    for (let i in hash) out.push(i);
    return out;
}


/**
 * This assumes that a string is made up of either unquoted, single word items, or quoted substrings.
 * This will split that string in to an array made up of the single word items and quoted substrings.
 *
 * @param {string} string
 * @param {string|string[]} [quoteChar=']
 * @return {string[]}
 */
export function splitQuotedString(string, quoteChar = "'") {
    if (!string?.length) return [];
    if (!Array.isArray(quoteChar)) quoteChar = [quoteChar];

    let n = string.length;
    let currentQuote;
    let a = [], insideQuote = false, insideWord = false, i, p;
    for (i = p = 0; i < n; ++i) {
        let c = string.charAt(i);
        if (insideQuote) {
            // See if we're at the end of a quote. We don't want apostrophe usage to end quotes.
            if (c === currentQuote && (i + 1 >= n || string.charAt(i + 1) === ' ')) {
                a.push(string.substring(p, i + 1));
                insideQuote = false;
            } else if (c === '\\') {
                ++i;    // skip over the next character so escaped quotes etc. don't break split
            }
        }
        else if (!insideWord && quoteChar.includes(c)) {
            insideQuote = true;
            currentQuote = c;
            p = i;
        }
        else if (insideWord) {
            if (c === ' ') {
                a.push(string.substring(p, i));
                p = i + 1;
                insideWord = false;
            }
        }
        else if (c !== ' ') {
            insideWord = true;
            p = i;
        }
    }

    if (insideWord) a.push(string.substring(p));
    if (insideQuote) a.push(string.substring(p));
    return a;
}


/**
 * This is similar to removeSingleQuotes except that it will remove either the single or the double quotes.
 * If quoteChar is not specified and the first character of the string is a ' or " then this is used.
 * It will also unescape things that have been escaped inside of the string. Strings that are not quoted are
 * returned as-is.
 *
 * @param {string} string
 * @param {string?} quoteChar Leave blank to strip out any quote character at the beginning of the string.
 * @return {string}
 */
export function removeQuotes(string, quoteChar) {
    let n = string.length - 1;
    if (n <= 0) return string;

    if (!quoteChar) {
        quoteChar = string.charAt(0);
        if (quoteChar !== '"' && quoteChar !== "'") return string;
    } else if (string.charAt(0) !== quoteChar) {
        return string;
    }
    if (string.charAt(n) !== quoteChar) return string;

    let i = string.indexOf("\\", 1);
    if (i < 0) return string.substring(1, n);
    let o = string.substring(1, i);
    for (; i < n; ) {
        let c = string.charAt(i++);
        if (c === '\\') c = string.charAt(i++);
        o += c;
    }
    return o;
}

export function removeSingleQuotes(string) {
    return removeQuotes(string, "'");
}

/**
 * @param {*} unknown
 * @returns {boolean}
 */
export function isUnknown(unknown) {
    if (!unknown) return false;
    if (!isString(unknown)) return false;
    unknown = unknown.toLowerCase();

    return (unknown === 'unknown' || unknown === 'un');
}

/**
 * Returns the string quoted in quotes suitable for display purposes. These use particular unicode characters:
 * ‘ and ’, and are not meant for delimiting strings. This function will NOT escape content in the string.
 * If you want to use string delimiters instead, see encloseInSingleQuotes()
 *
 * @param {string} str
 * @return {string}
 */
export function encloseInDisplayQuotes(str) {
    return "‘" + str + "’";
}

/**
 * Return the string in single quotes, escaping single quotes with \ and backslash with \.
 * @deprecated
 */
export function encloseInSingleQuotes(string) {
    return encloseInQuotes(string, "'");
}

/**
 * Return the string in quoteChar, escaping quoteChar quotes with \ and backslash with \.
 */
export function encloseInQuotes(string, quoteChar) {
    var o;
    var i = string.indexOf(quoteChar);
    var j = string.indexOf("\\");
    if (i >= 0 || j >= 0) {
        i = i < 0 ? j : j < 0 || i < j ? i : j;
        o = string.substring(0, i);
        for (; i < string.length; i++) {
            var c = string.charAt(i);
            if (c === quoteChar || c === "\\") o += "\\";
            o += c;
        }
    }
    return quoteChar + (o ? o : string) + quoteChar;
}


/**
 * Restricts the length of a string to the given size. This should cut text
 * at word boundaries, and provide ellipses.
 *
 * @param {String} string
 * @param {Number} length
 *
 * @return {string}
 */
export function restrictToLength(string, length) {
    if (string.length <= length) return string.toString();

    let result = [];
    let word = [];
    for (let i = 0; i < length - 1; i++) {
        let character = string.charAt(i);
        if (character === ' ' || character === '\n') {
            if (result.length === 0) result = result.concat(word);
            else {
                result.push(' ');
                result = result.concat(word);
            }
            word = [];
        } else {
            word.push(character)
        }
    }

    if (string.length !== 0 && result.length === 0) result = string; // Can happen if the string is a single word longer than length.
    else result.push('…');

    if (result.length > length) {
        return result.substring(0, length - 1) + '…';
    }

    return result.join('');
}
