/*************************************************************************************************
 *
 * This file provides functions for showing ephemeral notifications to users. Also provides undo
 * functionality.
 *
 *************************************************************************************************/


import VuexStore from "@/store/vuex/VuexStore";

/**
 * Provides a text message to the user. The text should be escaped
 * HTML (for instance, use escapeExpression).
 * Can have an optional function
 * to undo an action, and an optional html fragment to show an icon.
 */
export function notifyWithHtml(html, optionalUndo, optionalIcon, useLongDelay) {
    return notifyUser({
        message: html,
        isEscapedHtml: true,
        undo: optionalUndo,
        icon: optionalIcon,
        longDelay: !!useLongDelay
    })
}

/**
 * Provides an easy way to show a notification to a user.
 * Shows only plain text (no html), and allows for an optional
 * undo function.
 *
 * Returns an object that has hide and close functions (this message will close automatically).
 */
export function notifyWithText(text, optionalUndo, optionalIcon, useLongDelay) {
    return notifyUser({
        message: text,
        undo: optionalUndo,
        icon: optionalIcon,
        longDelay: !!useLongDelay
    });
}

let undoInfo = null;

/**
 * Runs the last undo operation, if any exist.
 */
export function undo() {
    if (!VuexStore.state.user?.admin) return;
    console.info("Experimental undo functionality");

    try {
        if (undoInfo?.undo) {
            undoInfo.undo();
        }

        if (undoInfo?.notification) {
            undoInfo.notification.remove();
        }
    } catch(e) {
        console.warn("Unable to undo", e);
    } finally {
        undoInfo = null;
    }
}

/**
 * A general tool for notifying users. If provided with just text, it acts
 * similarly to #notifyWithText. Can take a map of parameters to control
 * the notification.
 *
 * @param textOrOptions.message             The message to show the user
 * @param textOrOptions.more                Optional text to show in the "more" section
 * @param textOrOptions.isEscapedHtml       Applies to both message and more.
 * @param textOrOptions.noDismiss
 * @param textOrOptions.noDismissTimer
 * @param textOrOptions.longDelay
 * @param textOrOptions.icon
 * @param textOrOptions.undo
 * @param textOrOptions.action
 */
export function notifyUser(textOrOptions) {
    if (typeof textOrOptions === "string") {
        textOrOptions = { message: textOrOptions }
    }

    let area = document.getElementById('notification-area');
    if (!area) throw new Error("There is no notification area available");
    if (!textOrOptions.message) throw new Error("No message provided for notification");

    let item = document.createElement("be-notification-item");
    item.text = textOrOptions.message || "«no message»";
    item.more = textOrOptions.more ?? null;
    item.isEscapedHtml = !!textOrOptions.isEscapedHtml;
    item.noDismiss = !!textOrOptions.noDismiss;
    item.noDismissTimer = !!textOrOptions.noDismissTimer;
    item.delay = !textOrOptions.longDelay
        ? 5000
        : Number.isFinite(textOrOptions.longDelay)
            ? textOrOptions.longDelay
            : 15000;
    if (textOrOptions.icon) item.icon = textOrOptions.icon;

    if (textOrOptions.undo) {
        item.undo = true;
        undoInfo = {
            undo: textOrOptions.undo,
            notification: item
        };

        item.addEventListener("undo", function() {
            textOrOptions.undo();
            if (Object.is(undoInfo, item)) {
                undoInfo = null;
            }
        });
    }

    if (textOrOptions.action && textOrOptions.action.method) {
        if (textOrOptions.action.name) item.action = textOrOptions.action.name;
        if (textOrOptions.action.tooltip) item.actionTooltip = textOrOptions.action.tooltip;

        item.addEventListener("action", function() {
            textOrOptions.action.method();
        });
    }

    if (textOrOptions.onClose) {
        item.addEventListener("close", function() {
            textOrOptions.onClose();
        })
    }

    area.appendChild(item);

    return {
        hide: function() {
            item.removeItem();
        },
        close: function() {
            item.removeItem();
        },
        setMessage: function(message) {
            item.text = message || "";
        }
    }
}

/**
 * If you need an error message, this creates a popup notification
 * @param message
 * @param isEscapedHtml     Does the message include safely handled / escaped html already?
 * @param dismissAllowed    Can the user dismiss this error message.
 * @param onDismiss         A callback for when this error message is dismissed.
 */
export function notifyUserOfError(message,
                                  isEscapedHtml = false,
                                  dismissAllowed = true,
                                  onDismiss = null) {
    const notification = notifyUser({
        message: message,
        isEscapedHtml: isEscapedHtml,
        noDismiss: true,
        icon: '<i class="symbol-warning"></i>',
        action: !dismissAllowed ? null : {
            name: "Dismiss",
            method: () => notification.close()
        },
        onClose: onDismiss
    });
    return notification;
}


export function showBusyNotification(message, optionalOnClose, optionalTarget, isEscapedHtml) {
    if (typeof customElements === "undefined") {
        // Support for old browsers
        // noinspection JSUnresolvedVariable
        return Beef.MiniMenu.showBusyMessage({target: optionalTarget, message: message, onClose: optionalOnClose});
    }

    return notifyUser({
        icon: '<div class="css-spinner"><div></div><div></div><div></div><div></div></div>',
        message: message,
        noDismiss: true,
        onClose: optionalOnClose,
        isEscapedHtml: !!isEscapedHtml
    });
}


export function symbolClass(symbolClass) {
    if (!symbolClass) throw new Error("No symbol class provided");
    return `<i class="${symbolClass}"></i>`;
}




function NotificationItem() {
    return Reflect.construct(HTMLElement, [], NotificationItem);
}

NotificationItem.prototype = Object.create(HTMLElement.prototype);

Object.defineProperty(NotificationItem, 'observedAttributes', {
    get: function() { return ["text"]; }
});

Object.defineProperties(NotificationItem.prototype, {
    text: {
        get: function() {return this.getAttribute("text");},
        set: function(text) {this.setAttribute("text", text);}
    },
    more: {
        get: function() {
            const text = this.getAttribute("more");
            if (text === "null") return null;
            return text;
        },
        set: function(text) {this.setAttribute("more", "" + text);}
    },
    showMore: {
        get: function() {
            const text = this.getAttribute("show-more");
            return text === "true";

        },
        set: function(bool) {this.setAttribute("show-more", "" + bool);}
    },
    icon: {
        get: function() {return this.getAttribute("icon");},
        set: function(icon) {this.setAttribute("icon", icon);}
    },
    undo: {
        get: function() { return this.getAttribute("undo"); },
        set: function(undo) { this.setAttribute("undo", !!undo) }
    },
    isEscapedHtml: {
        get: function() { return this.getAttribute("is-escaped-html") === 'true'; },
        set: function(isEscaped) { this.setAttribute("is-escaped-html", !!isEscaped) }
    },
    noDismiss: {
        get: function() { return this.getAttribute("no-dismiss") === "true"; },
        set: function(noDismiss) { this.setAttribute("no-dismiss", !!noDismiss) }
    },
    noDismissTimer: {
        get: function() { return this.getAttribute("no-dismiss-timer") === "true"; },
        set: function(noDismissTimer) { this.setAttribute("no-dismiss-timer", !!noDismissTimer) }
    },
    action: {
        get: function() { return this.getAttribute("action"); },
        set: function(action) { this.setAttribute("action", action); }
    },
    delay: {
        get: function() { return this.getAttribute("delay"); },
        set: function(delay) { this.setAttribute("delay", delay); }
    },
    actionTooltip: {
        get: function() { return this.getAttribute("action-tooltip"); },
        set: function(action) { this.setAttribute("action-tooltip", action); }
    },
});

NotificationItem.prototype.removeItem = function() {
    if (this.isConnected) {
        if (!this.alreadyClosed) {
            this.dispatchEvent(new Event("close"));
            this.alreadyClosed = true;
        }

        this.classList.add('notification-item-removing');
        setTimeout(() => this.remove(), 5000);
    }
};

// Respond to attribute changes.
NotificationItem.prototype.attributeChangedCallback = function(attr, oldValue, newValue) {
    try {
        if (this.isConnected) {
            if (attr === "text") {
                var messages = this.querySelector(".notification-item__message");
                if (messages) {
                    messages.innerText = newValue;
                }
            }
        }
    } catch (e) {
        console.warn("Error updating notification message", oldValue, newValue);
    }

};

NotificationItem.prototype.connectedCallback = function() {
    if (this.isConnected) {
        try {
            let text = Handlebars.render(require("@/app/framework/notifications/NotificationItem.handlebars"), {
                message: this.isEscapedHtml ? new Handlebars.SafeString(this.text) : this.text,
                undo: this.undo,
                noDismiss: this.noDismiss,
                icon: this.icon,
                action: this.action,
                actionTooltip: this.actionTooltip,
                more: !this.more ? null : (this.isEscapedHtml ? new Handlebars.SafeString(this.more) : this.more),
            });

            this.innerHTML = text;

            var dismissBtn = this.querySelector('.notification-item__dismiss');
            if (dismissBtn) {
                dismissBtn.addEventListener("click", function(ev) {
                    this.removeItem();
                }.bind(this))
            }

            var actionBtn = this.querySelector('.notification-item__action');
            if (actionBtn) {
                actionBtn.addEventListener("click", function(ev) {
                    this.dispatchEvent(new Event("action"));
                    this.removeItem();
                }.bind(this))
            }

            var undoBtn = this.querySelector('.notification-item__undo');
            if (undoBtn) {
                undoBtn.addEventListener("click", function(ev) {
                    this.remove();  // Just remove. Don't do whole animation. We're sliding in another message.
                    setTimeout(() => this.dispatchEvent(new Event("undo")));
                }.bind(this))
            }

            this.addEventListener('mouseenter', function(ev) {
                this.mouseEntered = true;
            }.bind(this));

            this.addEventListener('mouseleave', function(ev) {
                this.mouseEntered = false;
                if (this.removeOnMouseLeave) this.removeItem();
            }.bind(this));

            const button = this.querySelector(".show-more-button");

            if (button) {

                const findOutMore = () => {
                    button.innerText = "more »";
                    button.setAttribute('tooltip', "Find out more");
                };

                findOutMore(); // Ensure that the DOM is in sync with the code.

                button.addEventListener("click", event => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.showMore = !this.showMore;

                    if (this.showMore) {
                        button.innerText = "« less";
                        button.setAttribute('tooltip', "Show less");
                    } else {
                        findOutMore();
                    }
                });
            }

        } finally {
            // Whatever happens, we want to make sure that we don't accumulate broken
            // notifications. This timeout must happen in a finally clause in case there
            // are exceptions.
            if (!this.noDismiss && !this.noDismissTimer) {
                setTimeout(function() {
                    if (!this.mouseEntered) {
                        this.removeItem();
                    } else {
                        this.removeOnMouseLeave = true;
                    }
                }.bind(this), parseInt(this.delay) || 4000);
            }
        }

    }
};

NotificationItem.prototype.disconnectedCallback = function() {
    if (!this.alreadyClosed) {
        this.dispatchEvent(new Event("close"));
        this.alreadyClosed = true;
    }
};


// ----------------------------------------------------------------------------

if (typeof customElements !== "undefined") {
    customElements.define('be-notification-item', NotificationItem);
} else if (typeof process?.env?.JEST_WORKER_ID === "undefined") { // not running in test environment.
    console.warn("customElements is not supported. Please upgrade your browser.")
}
