/**
 * Select conversations to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
Beef.module("IncExConversationPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/sharing/IncExConversationPicker.handlebars"),
        items: Beef.MentionPicker.items,
        bindings: {
            inc: { converter: Beef.MentionPicker.converter, elAttribute: "data-value" },
            ex: { converter: Beef.MentionPicker.converter, elAttribute: "data-value" }
        },
        attachPickers: function() {
            Beef.MentionPicker.attach(this, ".inc", "inc", {predicate: this.mentionPredicate});
            Beef.MentionPicker.attach(this, ".ex", "ex", {predicate: this.mentionPredicate});
        },
        mentionPredicate: {
            label: "Recently viewed conversations",
            fn: function(m) { return m.id == m.conversationId }
        }
    });

    this.converter = Beef.MentionPicker.createConverter("Conversations");

    /**
     * Attach a inc/ex term picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
