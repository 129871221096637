import beefRenderTopicWheel from './TopicWheelD3'
import beefRenderChord from './ChordD3'
import {grouseGet} from "@/data/Grouse";
import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {isVerifiedOnly} from "@/dashboards/filter/BasicFilter";
import {getDefaultTopicView} from "@/app/utils/Util";
import {showTagMentionDialog} from "@/app/framework/dialogs/Dialog";
import {once} from "@/app/utils/Functions";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {currentAccountCode} from "@/app/utils/Account";
import {getTopicsInFilter} from "@/dashboards/filter/FilterParser";
import VuexStore from "@/store/vuex/VuexStore";
import {encloseInDisplayQuotes, splitAtSpaces} from "@/app/utils/StringUtils";

/**
 * Topics arranged in a wheel.
 */
Beef.module("Widget.TopicWheel").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Topic Wheel",
        description:    "Explore topics and sub-topics",
        tooltip:        "See how topics and sub-topics are related to one another, as well as the relative volume of mentions assigned to each topic",
        group:          "other",
        width:          6,
        height:         6
    };

    var defaults = {
        drillDownMode: false,
        hideLegend: true,
        onlyTopicParentsInFilter: false,
        topicView: undefined,
        language: undefined,
        mode: 'wheel',           // or 'chord',
        maxRibbons: 50,
        sentiment: 'all',
        segments: undefined,
        initialSelection: null
    };

    this.View = Beef.BoundItemView.extend({

        attributes: {
            'class': 'widget-height-inner' /* scroll-auto */
        },

        modelEvents: {
            "change": "maybeRefresh",
            "change:drillDownMode": "drillDownModeChanged",
            "change:sentiment": "sentimentChanged",
            "change:language": "refresh"
        },

        initialize: function() {
            this.listenTo(this.model.getInteractiveFilterModel(), "change:topics", this.interactiveFilterModelChanged, this);
            this.listenTo(this.model.getSectionModel(), "change:filter", this.refreshIfFilterChanged, this);

            var defs = {};
            Object.entries(defaults).forEach(([key, value]) => {
                if (!this.model.has(key)) defs[key] = value;
            });

            this.model.set(defs, {silent: true});

            this.accountCode = this.model.getAncestorProperty('accountCode');

            if(this.model.get('topicView') && Beef.TopicViewPicker.items()[this.model.get('topicView')] == undefined) {
                this.model.unset('topicView');
            }

            this.refresh();
        },

        isDrillDownMode: function() {
            return this.model.get('drillDownMode');
        },

        destroyTooltip: function() {
            if (this.__tip) {
                this.__tip.destroy();
                this.__tip = null;
            }
        },

        onClose: function() {
            this.destroyTooltip();
            this.clearFilterAttribute();
        },

        drillDownModeChanged: function() {
            this.clearFilterAttribute(true);
        },

        clearFilterAttribute: function(force) {
            if (this.isDrillDownMode() || force) {
                var ifm = this.model.getInteractiveFilterModel();
                var fields = { };
                if (ifm.get("topics")) fields.topics = null;
                if ('chord' == this.model.get('mode') && 'all' != this.model.get('sentiment') && ifm.get('sentiment')) {
                    fields.sentiment = null;
                }
                if (!fields && Object.keys(fields).length) ifm.set(fields);
                //if (current) ifm.set("tags", null);
            }
        },

        interactiveFilterModelChanged: function(ifm, value) {
            if (this.isDrillDownMode() && this.d3diagram) {
                this.d3diagram.setSelection(value ? {id: parseInt(value)} : null);
            }
        },

        render: function(){
            if (!this._data) return;
            let tags = this._data;

            // Need to clear text before getting width and height, otherwise cannot shrink this widget.
            this.$el.text('');

            let w = this.$el.width();
            let h = this.$el.height();

            let segmentListIds;
            if (this.model.get('segments')) {
                segmentListIds = splitAtSpaces(this.model.get('segments')).map(function(id) { return parseInt(id) })
            }

            if (this.model.get("mode") === "chord" && this.model.get("onlyTopicParentsInFilter")) {
                let topics = VuexStore.getters.idToTag;

                let topicsInFilter = getTopicsInFilter(this.model.get("filter"))?.include;
                let topicParentsInFilter = [];

                for (const topicId of topicsInFilter) {
                    let topic = topics.get(topicId);

                    // topic is a parent topic
                    if (topic?.children) topicParentsInFilter.push(topic.id);
                }

                if (topicParentsInFilter?.length) {
                    let tagPredicate = (tag, topicIds) => {
                        return topicIds.includes(tag.tag?.parent?.id) && topicIds.includes(tag.tag2?.parent?.id);
                    };

                    tags = tags.filter(tag => tagPredicate(tag, topicParentsInFilter))
                }
            }

            let that = this;
            let options = {
                owner: that,
                hideLegend: this.model.get('hideLegend'),
                language: this.model.get('language'),
                maxRibbons: this.model.get('maxRibbons'),
                sentiment: this.model.get('sentiment'),
                segmentListIds: segmentListIds,
                onSelectionChanged: function(tag) { that.onSelectedTopicChanged.call(that, tag) },
                noAnimation: Beef.Widget.isDisableAnimation()
            };

            this.destroyTooltip();

            let chord = this.model.get('mode') === 'chord';
            this.$el.toggleClass("chord", chord);
            this.$el.toggleClass("topic-wheel", !chord);

            if (chord) {
                options.onRightClick = function(d, item1, item2) { that.onChordRightClick.call(that, this, item1, item2) };
                this.d3diagram = beefRenderChord(this.$el[0], w, h, tags, options);
            } else {
                options.v4 = true;
                options.onSegmentRightClick = function(d) { that.onSegmentRightClick.call(that, this, d) };
                this.d3diagram = beefRenderTopicWheel(this.$el[0], w, h, tags, options);
            }

            let is = this.model.get('initialSelection');
            if (is && this.d3diagram) this.d3diagram.setSelection({id: is}, true);

            this.model.generalData.set({'_completed': true});
        },

        maybeRefresh: function() {
            let c = this.model.changed;
            let attributesChanged = c.hasOwnProperty('comment') || c.hasOwnProperty('commentFontSize') || c.hasOwnProperty('commentWidth') || c.hasOwnProperty('onlyTopicParentsInFilter');
            let refresh = this.getEffectiveFilter() !== this._filter || attributesChanged
            if (!refresh) Object.entries(defaults).forEach(([key, value]) => { if (c[key] !== undefined) refresh = true });
            if (refresh) {
                this.refresh();
            } else {
                if (c['height']) {
                    // height is animated, so we can only figure out the new height after the animation.
                    // But we clear the contents so that we can see the animation if the area is shrinking.
                    this.$el.text('');
                    setTimeout(this.render.bind(this), 500);
                }
                else if (c['width']) this.render();
            }
        },

        refreshIfFilterChanged: function(data) {
            var f = this.getEffectiveFilter();
            if (f != this._filter) this.refresh();
        },

        getEffectiveFilter: function() {
            var filter, a, i;
            if (this.isDrillDownMode()) {
                filter = this.model.getSectionModel().get('filter');
                var sub = this.model.get('filter');
                if (sub) filter = "(" + filter + ") AND (" + sub + ")";
                var brands = this.model.getInteractiveFilterModel().get('brand');
                if (brands) {
                    a = brands.split(' ');
                    if (a.length == 1) {
                        filter += " and brand isorchildof " + a[0];
                    } else {
                        filter += " and (";
                        for (i = 0; i < a.length; i++) {
                            if (i > 0) filter += " or ";
                            filter += "brand isorchildof " + a[i];
                        }
                        filter += ")";
                    }
                }
            } else {
                filter = this.model.get('_effectiveFilter') || null;
            }
            if (filter && !isVerifiedOnly(filter)) {
                filter = "(" + filter + ") AND Process IS VERIFIED";
            }

            var view = this.model.get('topicView') || getDefaultTopicView(this.model.get('_effectiveFilter'));
            if (view) filter = "(" + filter + ") AND Tag is " + view;

            var segments = this.model.get('segments');
            if (segments) {
                a = splitAtSpaces(segments);
                filter = "(" + filter + ") AND (";
                for (i = 0; i < a.length; i++) {
                    if (i > 0) filter += " OR ";
                    filter += "Tag is " + a[i];
                }
                filter += ")";
            }

            return filter;
        },

        refresh: function() {
            if (!this.model.get('_effectiveFilter')) return;

            var filter = this.getEffectiveFilter();
            this.model.generalData.set({'_loading': true, _completed: false});
            this._filter = filter;
            var that = this, params;

            params = {
                filter: filter,
                select: 'tag[id,name,description,namespace,labels,descriptions,leaf,parent],mentionCount',
                groupBy: 'tag',
                tagNamespace: 'topic,topic_tree,segment,segment_list'
            };
            if (this.model.get('mode') === 'chord') {
                params.select += ",totalSentiment";
                params.groupBy = 'tag,tag';
                params.limit = 200;
            }

            const fromGrouse = this.model.getSectionModel()
                ? this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view)
                : grouseGet;

            fromGrouse('/v4/accounts/' + currentAccountCode() + '/mentions/count', params)
                .then((data) => {
                    this._data = data;
                    this._animationList = null;
                    this.render();
                    this.model.generalData.set({'_loading': false});
                })
                .catch(once(e => errorHelper(this.model, e)));
        },

        onSelectedTopicChanged: function(tag) {
            if (this.isDrillDownMode()) {
                var fields = {'tags': tag ? ("" + tag.id) : ""};
                if ('chord' == this.model.get('mode')) fields.sentiment = this.getSelectedSentiments();
                this.model.getInteractiveFilterModel().set(fields);
            }
        },

        getSelectedSentiments: function() {
            var s = this.model.get('sentiment');
            if (s == 'neg') return "-1";
            else if (s == 'pos') return "2";
            else return null;
        },

        sentimentChanged: function() {
            if (this.isDrillDownMode() && 'chord' == this.model.get('mode')) {
                this.onSelectedTopicChanged(null);
                this.d3diagram.setSelection(null);
            }
        },

        onSegmentRightClick: function(node, d) {
            var tag = d.data.tag;
            if (tag.id < 0) tag = d.parent.data.tag;
            if (!tag.id) return;
            this.selectedTag = tag;
            this.selectedTag2 = null;
            this.showRightClickMenu(node);
        },

        onChordRightClick: function(node, item1, item2) {
            this.selectedTag = item1;
            this.selectedTag2 = item2;
            this.showRightClickMenu(node);
        },

        showRightClickMenu: function(node) {
            var $svg = this.$(node).closest("svg");
            var coords = d3.mouse($svg[0]);

            Beef.MiniMenu.show({
                template: require("@/dashboards/widgets/topics/TopicWheelMenu.handlebars"),
                object: this,
                target: $svg,
                positions: ['inside'],
                offsets: { left: coords[0], top: coords[1] },
                dropdown: true
            });
        },

        toggleInitialSelection: function() {
            if (!this.selectedTag) return;
            var is = this.model.get('initialSelection');
            if (is) {
                if (is === this.selectedTag.id) is = null;
                else is = this.selectedTag.id;
            } else {
                is = this.selectedTag.id;
            }
            this.model.set('initialSelection', is);
            this.model.save();
        },

        viewMentions: function() {
            this.navigateToMentions(false);
        },

        viewMentionsInTab: function() {
            this.navigateToMentions(true);
        },

        viewAuthors: function() {
            this.navigateToAuthors();
        },

        navigateToMentions: function(newTab) {
            if (!this.selectedTag) return;
            var filter = "(" + this._filter + ") and " + tagFilterOperand(this.selectedTag) + " is " + this.selectedTag.id;
            if (this.selectedTag2) filter += " and " + tagFilterOperand(this.selectedTag2) + " is " + this.selectedTag2.id;

            if (newTab) {
                Beef.MentionList.navigateToMentions(this.model.getAncestorProperty('accountCode'), filter, null, newTab);
            } else {
                var title = [];
                if (this.selectedTag) title.push(this.selectedTag.name);
                if (this.selectedTag2) title.push(this.selectedTag2.name);
                showMentions(filter, title.map(function(t) { return encloseInDisplayQuotes(t) }));
            }
        },

        viewWordcloud: function() {
            if (!this.selectedTag) return;
            var filter = "(" + this._filter + ") and " + tagFilterOperand(this.selectedTag) + " is " + this.selectedTag.id;
            if (this.selectedTag2) filter += " and " + tagFilterOperand(this.selectedTag2) + " is " + this.selectedTag2.id;

            var title = [];
            if (this.selectedTag) title.push(this.selectedTag.name);
            if (this.selectedTag2) title.push(this.selectedTag2.name);
            showWordcloud(filter, title.map(function(t) { return encloseInDisplayQuotes(t) }));
        },

        tagMentions() {
            if (!this.selectedTag) return;
            var filter = "(" + this._filter + ") and " + tagFilterOperand(this.selectedTag) + " is " + this.selectedTag.id;
            if (this.selectedTag2) filter += " and " + tagFilterOperand(this.selectedTag2) + " is " + this.selectedTag2.id;

            var title = [];
            if (this.selectedTag) title.push(this.selectedTag.name);
            if (this.selectedTag2) title.push(this.selectedTag2.name);
            showTagMentionDialog(filter, "Tagging " + title.join(', '));
        },

        navigateToAuthors: function() {
            if (!this.selectedTag) return;
            var filter = "(" + this._filter + ") and " + tagFilterOperand(this.selectedTag) + " is " + this.selectedTag.id;
            if (this.selectedTag2) filter += " and " + tagFilterOperand(this.selectedTag2) + " is " + this.selectedTag2.id;
            Beef.AuthorsSectionV4.navigateToAuthors(this.model.getAncestorProperty('accountCode'), filter);
        },

        isAnimationSupported: function() {
            return this.isDrillDownMode();
        },

        getSelectedTagId: function() {
            var ifm = this.model.getInteractiveFilterModel();
            var current = ifm.get('tags');
            if (!current) return null;
            var id = parseInt(current);
            return isNaN(id) ? null : id;
        },

        /**
         * Advance to the next animation frame if possible. Return true if this loops us back to the beginning of
         * our sequence (or we have no data) or false otherwise.
         */
        nextAnimationFrame: function() {
            if (!this._data) return true;

            var list = this._animationList;
            if (!list) {
                list = this._animationList = [];
                var f = function(a) {
                    for (var i = 0; i < a.length; i++) {
                        var n = a[i];
                        list.push(n.tag.id);
                        if (n.children) f(n.children);
                    }
                };
                f(this._data);
                list.push(0); // include 'all topics' in the sequence at the end to show the whole wheel each cycle
            }

            var currentValue = this.getSelectedTagId();
            var pos = list.indexOf(currentValue) + 1;
            var looped = pos >= list.length;
            if (looped) pos = 0;
            var nextValue = pos < list.length ? list[pos] : null;
            if (nextValue != null) this.model.getInteractiveFilterModel().set('tags', nextValue == 0 ? null : ("" + nextValue));
            return looped || !nextValue;
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/topics/TopicWheelSettings.handlebars"),

        editAttributes: function() {
            return ['drillDownMode', 'hideLegend', 'topicView', 'language', 'mode', 'maxRibbons', 'sentiment',
                'segments', 'onlyTopicParentsInFilter']
        },

        events: {
            "click .btn-group .btn": "buttonGroupChanged"
        },

        onFirstRender: function() {
            Beef.TopicViewPicker.attach(this, ".topicView", "topicView", {onlyOne: true});
            Beef.LanguagePicker.attach(this, ".language", "language", { onlyOne: true });
            Beef.TagPicker.attach(this, ".segments", "segments", {
                searchFilter: Beef.TagPicker.segmentListChooser,
                startDroppedDown: true,
                noHighlightSegments: true
            });
            this.modelToView();

            window.setTimeout(() => {
                this.toggleChordSettings();
            }, 0);
        },

        toggleChordSettings: function() {
            if (this.model.get("mode") === "chord") {
                $(".co-occurrences-section").show();
            } else {
                $(".co-occurrences-section").hide();
            }
        },


        bindings: function() {
            return {
                topicView: { converter: Beef.TopicViewPicker.converter, elAttribute: "data-value" },
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
                segments: { converterFactory: Beef.TagPicker.createConverterFactory(null, {noHighlightSegments: true}),
                    elAttribute: "data-value" }
            }
        },

        modelToView: function() {
            this.$(".btn-group .btn").removeClass('active');

            var attr = this.model.attributes;
            this.$('.mode button[data-value="' + (attr.mode || 'wheel') +'"]').addClass('active');
            this.$('.max-ribbons button[data-value="' + (attr.maxRibbons || '0') +'"]').addClass('active');
            this.$('.sentiment button[data-value="' + (attr.sentiment || 'all') +'"]').addClass('active');
        },

        buttonGroupChanged: function(ev) {
            var $t = $(ev.target).closest(".btn");
            var value = $t.attr('data-value');
            var key = $t.closest(".btn-group").attr('data-key');
            if (key && value) this.model.set(key, value);

            this.toggleChordSettings();

            this.modelToView();
        }
    });

    function tagFilterOperand(tag) {
        var ns = tag.namespace;
        return ns === 'segment' || ns === 'segment_list' ? 'segment' : 'topic';
    }
});