export const SOCIAL_NETWORKS = {
    TWITTER: '𝕏 (Twitter)',
    FACEBOOK: 'Facebook',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'LinkedIn',
    TIKTOK: 'TikTok',
    TUMBLR: 'Tumblr',
    VK: 'VK',
    YOUTUBE: 'YouTube',
    TELEGRAM: 'Telegram',
    UNKNOWN: 'Websites, blogs, etc',
    WHATSAPP: 'WhatsApp',
    HELLOPETER: 'Hellopeter',
    GOOGLEBUSINESS: 'Google Business',
    TRUSTPILOT: "Trustpilot"
};