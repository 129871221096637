import { deprecatedTagsStore, deprecatedBrandsStore } from "../../../../store/deprecated/Stores"
import {account} from "@/app/utils/Account";
import {splitAtSpaces} from "@/app/utils/StringUtils";

/**
 * Select segments to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
Beef.module("IncExSegmentPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/incexsegment/IncExSegmentPicker.handlebars"),
        items: Beef.TagPicker.items,
        bindings: {
            inc: { converterFactory: Beef.TagPicker.createConverterFactory(null, {noHighlightSegments: true, showWarning: true}), elAttribute: "data-value" },
            ex: { converterFactory: Beef.TagPicker.createConverterFactory(null, {noHighlightSegments: true, showWarning: true}), elAttribute: "data-value" }
        },
        attachPickers: function() {
            var options = {
                searchFilter: Beef.TagPicker.segmentChooser,
                showTopicTagDetail: false,
                startDroppedDown: true,
                importanceSorter: ImportanceSorter,
                sortFun: Beef.TagPicker.sortFun
            };

            Beef.TagPicker.attach(this, ".inc", "inc", options);
            Beef.TagPicker.attach(this, ".ex", "ex", options);
        },

        click: async function(ev) {
            if (ev.target.className.indexOf("exclude-all") < 0) return
            ev.preventDefault()
            await Promise.all([deprecatedBrandsStore.refresh(true), deprecatedTagsStore.refresh(true)])
            let segmentListIds = new Set()
            deprecatedBrandsStore.list.forEach(b => {
                if (b.segmentListIds) b.segmentListIds.forEach(id => segmentListIds.add(id))
            })
            let ex = splitAtSpaces(this.model.get("ex"))
            Array.from(segmentListIds).forEach(id => {
                let segmentList = deprecatedTagsStore.get(id)
                if (!segmentList.children || !segmentList.children.length) return
                let sid = "" + id
                if (ex.indexOf(sid) < 0) ex.push(sid)
                sid = "" + segmentList.children[segmentList.children.length - 1]
                if (ex.indexOf(sid) < 0) ex.push(sid)
            })
            this.model.set("ex", ex.join(" "))
        }
    });

    var placeholder = "Segments";
    var lists = account().segmentLists;
    if (lists && lists.length) {
        placeholder = "";
        for (var i = 0; i < lists.length; i++) {
            if (i > 0) placeholder += ", ";
            placeholder += lists[i].name.trim();
        }
        placeholder += " (Segments)";
    }
    this.converterFactory = Beef.TagPicker.createConverterFactory(placeholder,
        {noHighlightSegments: true, placeholderPersistant: false, showWarning: true});

    /**
     * Attach the picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        var options = {
            mouseover: function(item, node) { Beef.TagPicker.tooltip(view, item, node); }
        };

        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

    function ImportanceSorter() { }

    ImportanceSorter.prototype.sort = function(query, data) {
        data.sort(Beef.TagPicker.sortFun);
        return data;
    };


});
