<template>
    <drop-down-input v-model="val" :optional="optional" :multiselect="multiselect" :options="items"
                     search :label="label" :disabled="disabled" placeholder="Start typing the currency name or code" :required="required"/>
</template>

<script>
import DropDownInput from "./DropDownInput"
import {CURRENCIES} from "@/app/utils/Currency";

export default {
    name: "CurrencyInput",

    components: { DropDownInput },

    props: {
        label: String,
        value: [String, Array],
        optional: Boolean,
        multiselect: Boolean,
        required: Boolean,
        disabled: Boolean
    },

    data() {
        return {
            val: this.value
        }
    },

    watch: {
        value(v) {
            this.val = v
        },

        val(v) {
            this.$emit("input", v)
        }
    },

    computed: {
        items() {
            let currencyList = {};

            Object.entries(CURRENCIES).forEach(currency => {
                let id = currency[0];
                let name = currency[1];

                currencyList[id] = {
                    id: id,
                    name: name
                }
            });

            return currencyList;
        }
    }
}

</script>

<style scoped>

</style>