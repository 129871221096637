var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"control-group control-group-spacer main-form\">\n                        <label>Only read these feeds (leave blank for all)</label>\n                        <div class=\"controls\">\n                            <span class=\"feeds tag-input uneditable-input span12\">\n                                <span name=\"feeds\"></span>\n                                <input class=\"tag popup-menu\" type=\"text\"/>\n                            </span>\n                        </div>\n                    </div>\n                    <div class=\"phrase-filter-region\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"dialog-title\">\n    <h5>Generate search phrases</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"pages\">\n        <div class=\"page-gen\">\n            <div class=\"phrase-gen-region\"></div>\n\n            <div class=\"row-fluid\">\n                <div class=\"span12\">\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"phraseFilters",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":25,"column":34}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n\n        <div class=\"page-list\" style=\"display: none\">\n            <div class=\"phrase-list-region\"></div>\n        </div>\n\n        <div class=\"page-confirm\" style=\"display: none\">\n            <div class=\"row-fluid\">\n                <div class=\"span12 inner\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"dialog-button-bar\">\n        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"prev btn\"><i class=\"icon-chevron-left\"></i> Previous</a>\n        <a class=\"next btn\"><i class=\"icon-chevron-right\"></i> Next</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n</div>\n";
},"useData":true});