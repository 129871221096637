import {CustomInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/CustomInlineTool";

export class CountInlineTool extends CustomInlineTool {

    getBtnOptions() {
        return countBtnOptions;
    }

    getBtnName() {
        return "Count";
    }

    toolTipText() {
        return "Add a count metric";
    }

}

export const countBtnOptions = [
    {
        text: "Mentions",
        command: "{{total mentions}}"
    },
    {
        text: "Verified mentions",
        command: "{{total mentions 'process is verified'}}"
    },
    {
        text: "Public sentiment",
        command: "{{total public-net%}}"
    },
    {
        text: "Benchmark sentiment",
        command: "{{total benchmark-net%}}"
    },
    {
        text: "Net sentiment",
        command: "{{total net}}"
    },
    {
        text: "Net sentiment%",
        command: "{{total net%}}"
    },
    {
        text: "Negative sentiment",
        command: "{{total negative}}"
    },
    {
        text: "Neutral sentiment",
        command: "{{total neutral}}"
    },
    {
        text: "Positive sentiment",
        command: "{{total positive}}"
    },
    {
        text: "Unique authors",
        command: "{{total authors}}"
    },
    {
        text: "Unique sources",
        command: "{{total sites}}"
    },
    {
        text: "Engagement",
        command: "{{total engagement}}"
    },
    {
        text: "OTS",
        command: "{{total ots}}"
    },
    {
        text: "CX %",
        command: "{{cx%}}"
    },
    {
        text: "CX Reshare%",
        command: "{{cx-reshare%}}"
    },
    {
        text: "Purchase%",
        command: "{{cx-purchase%}}"
    },
    {
        text: "Cancel%",
        command: "{{cx-cancel%}}"
    }

];