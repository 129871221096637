/**
 * Displays available widget types in a palette.
 *
 *
 */
import {showRaceFilter} from "@/app/Permissions";

Beef.module("WidgetPalette").addInitializer(function(startupOptions) {

    /**
     * Create a view showing all available widget types and the currently selected type (from model 'type' attribute).
     * Changes 'type' attribute if the user changes the selection.
     */
    this.createView = function(options) {
        options = Object.assign({}, options, {model: new Backbone.Model()});
        return new View(options);
    };

    var View = Backbone.Marionette.ItemView.extend({

        attributes: function(){
            return { class: "widget-palette section widget-palette-" + this.options.group }
        },

        template: require("@/dashboards/widgetpalette/WidgetPalette.handlebars"),

        serializeData: function(){
            return {
                "title": this.options.title || "Select A Metric",
                "group": this.options.group || "other",
                "help":  this.options.group === 'other'
            }
        },

        events: {
            "click tr": "click"
        },

        initialize: function() {
        },

        onClose: function() {
        },


        click: function(ev) {
            ev.preventDefault();
            var t = $(ev.target);
            var dt = t.attr('data-type');
            if (!dt) {
                t = t.closest("tr");
                dt = t.attr('data-type');
            }
            var type = Beef.WidgetRegistry.typeMap[dt];

            var xAxis = t.attr('data-xaxis');
            var compare = t.attr('data-compare');

            // Show race upsell message instead of adding a widget.
            var isRace = type.type === "Race" || xAxis === "race" || compare === "race";
            if (type && isRace && !showRaceFilter()){
                Beef.RaceUpsell.show(t);
            } else if (type) {
                this.trigger('selected', {
                    type: type.type,
                    xAxis: xAxis,
                    yAxis: t.attr('data-yaxis'),
                    compare: compare,
                    width: t.attr('data-width'),
                    height: t.attr('data-height'),
                    simple: t.attr('data-simple') === "true",
                    geometry: t.attr('data-geometry')
                })
            }
        }
    });
});