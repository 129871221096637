var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"edit edit-rule-icon\">\n            <span><i class=\"edit icon-pencil\" href=\"edit\" id="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "></i></span>\n        </td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <td></td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "        <td class=\"move\">\n            <span class=\"move-up-btn\"><i class=\"icon-up\"></i></span>\n            <span class=\"move-down-btn\"><i class=\"icon-down\"></i></span>\n        </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n    <input type=\"checkbox\" checked=\"true\" class=\"rule-active\">\n    <span class=\"rule-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = container.invokePartial(require("../../dashboards/filter/V4Issues.handlebars"),depth0,{"name":"../../dashboards/filter/V4Issues","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"actionEnglish") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias3,"editRules",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":15,"column":14}}})) != null ? stack1 : "")
    + "<td class=\"num-column\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"last24h") : depth0), depth0))
    + "</td>\n<td class=\"num-column\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastWeek") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias3,"editRules",{"name":"ifallowed","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":0},"end":{"line":27,"column":14}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});