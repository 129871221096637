<template>
    <div class="feature-flag-settings">
        <header>
            <h1>Feature Flags</h1>
        </header>

        <p>You can optionally enable or disable the following features. These features are still in development, but
            please
            feel free to test them. They will only be enabled for you on this specific browser.</p>

        <div class="feature-flag-settings__features-container dark-scrollbars dark-scrollbars--visible">
            <div class="feature-flag-settings__feature">
                Experience Analyse as a non-staff member (resets when you close this browser tab)
                <input type="checkbox" :checked="nonAdminMode" @change="toggleNonAdminMode($event)">
            </div>
            <div v-if="!nonAdminMode">
                <div class="feature-flag-settings__feature" v-for="featureFlag in featureFlags" :key="featureFlag.name">
                    {{ featureFlag.description }}
                    <input type="checkbox" :name="featureFlag.name" :checked="featureFlag.value" @change="toggleFeatureFlag(featureFlag.name, $event)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "FeatureFlagSettings",

    props: {
        featureFlags: Array,
        nonAdminMode: Boolean
    },

    methods: {
        toggleFeatureFlag(featureFlagName, event) {
            this.$emit('feature-flag-updated', featureFlagName, event.target.checked);
        },

        toggleNonAdminMode(event) {
            this.$emit('non-admin-mode-updated', event.target.checked);
        }
    }

}
</script>

<style scoped lang="sass">

.feature-flag-settings
    display: flex
    flex-direction: column
    max-height: 99%
    color: var(--be-colour-text-dark)

    &__features-container
        background: #333333
        padding-right: 20px
        height: auto
        overflow-y: auto

    &__feature
        display: flex
        align-items: center
        justify-content: space-between
        padding: 10px
        border-top: 1px solid #1a1a1a
        border-left: 1px solid #1a1a1a
        border-right: 1px solid #1a1a1a

        &:last-of-type
            border-bottom: 1px solid #1a1a1a

        input[type=checkbox]
            transform: scale(1.3)

</style>