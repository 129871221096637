/**
 * Renders a string containing '>>' as a separator e.g. a brand fullName.
 */
export default function(path, options) {
    var a = [], i;
    if (path) {
        path = path.split(">");
        for (i = 0; i < path.length; i++) a.push($('<pre>').text(path[i]).html());
    }
    return new Handlebars.SafeString(a.join(" <span class='delim'> &raquo; </span> "));
}