export function openInlineToolbar() {
    const selection = window.getSelection();
    const range = document.createRange();

    const currentNode = selection.focusNode;
    const currentOffset = selection.focusOffset;

    if (currentOffset > 0) {
        range.setStart(currentNode, currentOffset - 2);
        range.setEnd(currentNode, currentOffset);
        if (range.cloneRange().cloneContents().textContent === "/c"){
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
}

export const sanitizer = {
    'command-element': {
        class: 'deq-count',
        'data-command': true,
        'data-commandtype': true,
        'data-subfilter': true,
        'data-filter': false,
        'data-readOnly': false
    },
    'font-size': true,
    'font-color': true,
    'be-rpcs-icon': true,
    b: true,
    i: true,
    font: true,
    a: true,
    img: true,
    span: true
};

export function shouldOpenInlineTool(key){

}

export function shouldDeleteNestedNode(key){
    if (key.code === "Backspace") {
        let selection = document.getSelection();

        if( selection && selection.anchorOffset === 1 ) {
            selection.anchorNode.previousSibling.parentNode.removeChild(selection.anchorNode.previousSibling)
        }
    }
}

export function toHeader(el, words, level){
    let event = new CustomEvent("shouldConvertToHeader",
        {
            bubbles: true,
            detail: {
                data: words.slice(1).join(' '),
                level: level
            }
        });
    el.dispatchEvent(event);
}
