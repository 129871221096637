/**
 * Select locations to include and exclude. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 */
Beef.module("IncExLocationPicker").addInitializer(function(startupOptions) {

    this.View = Beef.IncExPicker.View.extend({
        template: require("@/dashboards/filter/pickers/incexlocation/IncExLocationPicker.handlebars"),
        items: Beef.LocationPicker.items,
        bindings: {
            inc: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" },
            ex: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" }
        },
        attachPickers: function() {
            Beef.LocationPicker.attach(this, ".inc", "inc");
            Beef.LocationPicker.attach(this, ".ex", "ex");
        }
    });

    this.converter = Beef.LocationPicker.createConverter("Locations");

    /**
     * Attach a location picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View);
    };

});
