<template>
    <section class="be-author-avatar" :class="classes">
        <img :src="defaultUrl" class="be-author-avatar__default">
        <img :src="url"
             class="be-author-avatar__actual short-animated fadeIn"
             @load="onLoad">
    </section>
</template>


<script>

export default {
    props: {
        mention: {
            type: Object,
            required: true
        }
    },

    inject: ['isBroadcastMedia', 'isPrintMedia'],

    data() {
        return {
            loaded: false
        }
    },

    computed: {
        classes() {
            if (this.loaded) {
                return ["be-author-avatar--loaded"]
            } else {
                return [];
            }
        },

        url() {
            if (this.mention.authorPictureLink) return this.mention.authorPictureLink;

            if (this.mention.socialNetwork?.id === 'TUMBLR') {
                return `https://api.tumblr.com/v2/blog/${this.mention.site}/avatar/128`;
            }

            if (this.isBroadcastMedia()) return Beef.resources["avatar_broadcast_media.png"];
            if (this.isPrintMedia()) {
                let ml = this.mediaLinks;
                return (ml && ml.length && ml.length >= 1) ? ml[0].url : Beef.resources["press_avatar.png"];
            }

            switch (this.mention.category?.id) {
                case "PRESS":       return Beef.resources["press_avatar.png"];
                case "ENTERPRISE":  return Beef.resources["enterprise_avatar.png"];
                case "DIRECTORY":   return Beef.resources["directory_avatar.png"];
                case "CONSUMER":    return Beef.resources["consumer_avatar.png"];
                default:
                    console.warn(`Unrecognised category [${this.mention.category?.id}]`);
                    return Beef.resources["facebook_avatar.png"];
            }
        },

        defaultUrl() {
            switch (this.mention.category?.id) {
                case "PRESS":       return Beef.resources["press_avatar.png"];
                case "ENTERPRISE":  return Beef.resources["enterprise_avatar.png"];
                case "DIRECTORY":   return Beef.resources["directory_avatar.png"];
                case "CONSUMER":    return Beef.resources["consumer_avatar.png"];
                default:
                    return Beef.resources["facebook_avatar.png"];
            }
        }
    },

    methods: {
        onLoad(event) {
            this.loaded = true;
        }
    }
}

</script>



<style scoped lang="sass">

img
    height: 48px
    width: 48px
    border-radius: 3px
    background-color: #eee

.be-author-avatar__actual
    display: none

.be-author-avatar--loaded
    .be-author-avatar__actual
        display: block
    .be-author-avatar__default
        display: none



</style>