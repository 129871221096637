import accounting from 'accounting'
import _ from 'underscore';
import {toSi} from "@/app/utils/Format";

Beef.MapUtils = {

    reportOnMap: {
        count: {key: 'count', label: 'Volume', select: 'mentionCount'},
        authorNames: {key: 'authorNames', label: 'Unique Authors', select: 'authorNameCount'},
        ave: {key: 'ave', label: 'AVE', select: 'totalAVE'},
        ots: {key: 'ots', label: 'OTS', select: 'totalOTS'},
        sites: {key: 'sites', label: 'Unique Sites', select: 'siteCount'},
        reshareCount: {key: 'reshareCount', label: 'Reshares', select: 'totalReshareCount'},
        replyCount: {key: 'replyCount', label: 'Replies', select: 'totalReplyCount'},
        engagement: {key: 'engagement', label: 'Engagement', select: 'totalEngagement'},
        positiveCount: {key: 'positiveCount', label: 'Positive Volume', sentiment: 'totalPositive'},
        neutralCount: {key: 'neutralCount', label: 'Neutral Volume', sentiment: 'totalNeutral'},
        negativeCount: {key: 'negativeCount', label: 'Negative Volume', sentiment: 'totalNegative'},
        volumeSentiment: {key: 'volumeSentiment', label: 'Volume by Sentiment',
            sentiment: 'mentionCount,totalPositive,totalNegative,totalNeutral',
            slices: [{key: 'neutralCount', neutral:true}, {key: 'positiveCount'}, {key: 'negativeCount'}]}
    },

    getCountData: function (data, countMap) {
        var fakeCountData = {
            getCode: function () {
                return data.properties.iso_a2;
            },
            getName: function () {
                return data.properties.name;
            },
            getValue: function () {
                return 0;
            },
            getDisplayValue: function () {
                return 0;
            }

        };

        var countData = undefined;
        var code = data.properties.iso_a2;
        if (code) {
            countData = countMap[data.properties.iso_a2];
            if (countData === undefined) {
                // if we have not got any count data from grouse we make a fake
                // count data from the topo json data.
                countData = fakeCountData;
            }
            return countData;
        } else {
            console.error('We don\'t have a country code', data.properties);
        }
    },

    /**
     * Helper method to get the mention data from grouse.
     */
    getMentionData: function (accountCode, params, reportOn, sectionView) {
        return sectionView.getJsonFromGrouse('/v4/accounts/' + accountCode + '/mentions/count', params)
            .then(function(countries) {
                return countries.map(function(c) {
                    var m = {};

                    if (params.groupBy === "country") {
                        m.country = c.country ? c.country.id : "UN";
                        m.countryName = c.country ? c.country.name : "Unknown";
                    }
                    if (params.groupBy === "city") {
                        if (!c.city) {
                            m.city = "UNKNOWN";
                            m.cityName = "Unknown";
                            m.region = "UNKNOWN";
                            m.regionCode = "UNKNOWN";
                            m.regionName = "Unknown";
                            m.country = "UN";
                            m.countryName = "Unknown";
                        } else {
                            m.city = c.city.id;
                            m.cityName = c.city.name;
                            m.region = c.city.region ? c.city.region.name.toLowerCase() : "UNKNOWN";
                            m.regionCode = c.city.region ? c.city.region.id : "UNKNOWN";
                            m.regionName = c.city.region ? c.city.region.name : "Unknown";
                            m.country = c.city.country ? c.city.country.id : "UN";
                            m.countryName = c.city.country ? c.city.country.name : "Unknown";
                            m.cityLatitude = c.city.latitude;
                            m.cityLongitude = c.city.longitude;
                        }

                    }

                    if (c.hasOwnProperty("mentionCount")) m.count = c.mentionCount;
                    if (c.hasOwnProperty("authorNameCount")) m.authorNames = c.authorNameCount;
                    if (c.hasOwnProperty("siteCount")) m.sites = c.siteCount;
                    if (c.hasOwnProperty("totalOTS")) m.ots = c.totalOTS;
                    if (c.hasOwnProperty("totalAVE")) m.ave = c.totalAVE;
                    if (c.hasOwnProperty("totalEngagement")) m.engagement = c.totalEngagement;
                    if (c.hasOwnProperty("totalReshareCount")) m.reshareCount = c.totalReshareCount;
                    if (c.hasOwnProperty("totalReplyCount")) m.replyCount = c.totalReplyCount;
                    if (c.hasOwnProperty("totalPositive")) m.positiveCount = c.totalPositive;
                    if (c.hasOwnProperty("totalNeutral")) m.neutralCount = c.totalNeutral;
                    if (c.hasOwnProperty("totalNegative")) m.negativeCount = c.totalNegative;
                    if (c.hasOwnProperty("totalPositiveOTS")) m.positiveOts = c.totalPositiveOTS;
                    if (c.hasOwnProperty("totalNeutralOTS")) m.neutralOts = c.totalNeutralOTS;
                    if (c.hasOwnProperty("totalNegativeOTS")) m.negativeOts = c.totalNegativeOTS;

                    return m;
                })
            });

    },

    processData: function (data, params, projection, reportOn, showNeutral, minBubbleSize, maxBubbleSize) {
        var that = this;
        var totalCount = 0;
        var unknownCount = 0;
        var maxCount = 0;
        var dataMap = {};
        var dataCleaned = [];

        var slices = reportOn.slices;
        if (!showNeutral && slices) {
            slices = _.reject(slices, function (s) {
                return s.neutral;
            });
        }

        if (params.groupBy === 'city') {
            data = data.splice(0, 200);
        }

        _.each(data, function (d) {
            d.negativeOts = d.negativeOts || 0;
            d.positiveOts = d.positiveOts || 0;
            d.negativeCount = d.negativeCount || 0;
            d.positiveCount = d.positiveCount || 0;
            d.neutralOts = d.ots - (d.negativeOts + d.positiveOts);
            d.neutralCount = d.count - (d.negativeCount + d.positiveCount);

            if (params.groupBy === 'country') {
                d.getCode = function () {
                    return d.country;
                };

                d.getName = function () {
                    return d.countryName;
                };

            } else {
                d.getCode = function () {
                    return d.city === 'UNKNOWN' ? d.city : d.regionCode +'-'+ d.city;
                };

                d.getName = function () {
                    return d.cityName;
                };
            }

            d.getValue = function () {
                if (slices) {
                    var sum = 0;
                    slices.forEach(function (s) {
                        sum += d[s.key];
                    });
                    return sum;
                } else {
                    return d[reportOn.key] ? d[reportOn.key] : 0;
                }
            };

            d.getDisplayValue = function () {
                var val = d.getValue();
                return that.formatNumber(val);
            };

            totalCount += d.getValue();

            d.pieData = [];
            if (slices) {
                slices.forEach(function (s) {
                    d.pieData.push({key: s.key, label: Beef.MapUtils.reportOnMap[s.key].label,
                        value: d[Beef.MapUtils.reportOnMap[s.key].key]});
                })
            } else {
                d.pieData.push({key: reportOn.key, label: reportOn.label, value: d[reportOn.key]});
            }

            var code = d.getCode();
            if (code && code !== 'UNKNOWN' && code !== 'UN') {
                if (params.groupBy === 'country') {
                    dataMap[code] = d;
                } else {
                    dataCleaned.push(d);
                }
                if (maxCount < d.getValue()) { maxCount = d.getValue(); }
            } else {
                unknownCount += d.getValue();
            }
        });

        var dataWrapper = {
            totalCount: totalCount,
            unknownCount: unknownCount,
            maxCount: maxCount
        };

        if (params.groupBy === 'country') {
            dataWrapper.data = dataMap;
        } else {
            var bubbleScale = d3.scaleLinear()
                .domain([0, dataWrapper.maxCount])
                .range([minBubbleSize, maxBubbleSize]);

            let calcRadius = function(d) {
                var val = accounting.toFixed(d.getValue(), 2);
                if (Math.abs(val) > 0) {
                    val = val < dataWrapper.maxCount ? val : dataWrapper.maxCount;

                    return Math.sqrt(bubbleScale(val) / Math.PI);
                } else {
                    return 0;
                }
            }

            dataCleaned.forEach(function (d) {
                var p = projection([d.cityLongitude, d.cityLatitude]);
                d.x = p[0];
                d.y = p[1];
                d.radius = calcRadius(d);

                d.pieData.forEach(function (p) {
                    p.radius = d.radius;
                });
            });

            var mergedData = this.mergeOverlaps(dataCleaned);
            dataWrapper.data = mergedData.data;
            dataWrapper.mergeMap = mergedData.mergeMap;
        }

        return dataWrapper;
    },

    mergeOverlaps: function (data) {

        var ans;
        var merged = [];
        var mergeMap = {};

        var distanceBetween = function (x2, x1, y2, y1) {
            var ans;
            ans = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
            return ans;
        };

        var findNewCenter = function (x2, x1, y2, y1) {
            var x = (x2 + x1) / 2;
            var y = (y2 + y1) / 2;

            return [x, y];
        };

        var mergedAlready = function (a) {
            return _.find(merged, function (b) {
                return a.getCode() === b.getCode();
            });
        };

        var mergeData = function (a, b) {
            // keep track of who is merged into who
            var parent = mergeMap[a.getCode()];
            if (parent === undefined) {
                mergeMap[a.getCode()] = {parent: a, children: []};
                parent = mergeMap[a.getCode()];
            }

            // if b is a parent add b's children to a
            if (mergeMap[b.getCode()] !== undefined) {
                parent.children.push(parent.children.concat(mergeMap[b.getCode()].children));
                mergeMap[b.getCode()] = undefined;
            }

            parent.children.push(b);

            a.pieData.forEach(function (p) {
                p.value += b[p.key];
            });


            return a;
        };

        //remove any mentions which don't have a radius
        data = _.reject(data, function (d) {
            return d.radius === 0;
        });

        data.forEach(function (a) {
            if (!mergedAlready(a)) {
                data.forEach(function (b) {
                    if (a.getCode() !== b.getCode() && !mergedAlready(b)) {
                        var totalRadius = a.radius + b.radius;
                        var distance = distanceBetween(b.x, a.x, b.y, a.y);
                        if (totalRadius > distance) {
                            mergeData(a, b);
                            merged.push(b);
                        }
                    }
                });
            }
        });

        ans = _.reject(data, function (a) {
            return mergedAlready(a);
        });

        return {data: ans, mergeMap: mergeMap};
    },

    syncPieLayer: function (data, layer, setHoverValue, projection, colourScale, reportOn, noAnimation) {
        var pieColourMap = {
            neutralCount: '#969696',
            positiveCount: '#5473bd',
            negativeCount: '#a52a2a',
            neutralOts: '#969696',
            positiveOts: '#5473bd',
            negativeOts: '#a52a2a'
        };

        var pie = d3.pie()
            .value(function (d) { return d.value });

        var pies = layer.selectAll('.pie')
            .data(data, function (d) { return d.getCode() })
            .enter()
            .append('g')
            .attr('class', 'pie')
            .attr("transform", function (d) {
                var p = projection([d.cityLongitude, d.cityLatitude]);
                return "translate(" + p[0] + "," + p[1] + ")";
            })
            .on('mouseover', function (d) { setHoverValue(d) })
            .on('mouseout', function () { setHoverValue(undefined) });

        var arcs = pies.selectAll(".arc")
            .data(function (d) { return pie(d.pieData)}, function (d, i) { return i });

        arcs = arcs.enter()
            .append("path")
            .attr("class", "arc")
            .attr("opacity", 0)
            .attr("fill", '#f00')
            .attr('d', d3.arc().outerRadius(0).innerRadius(0))
        if (!noAnimation) arcs = arcs.transition().duration(500)
        arcs.attr("opacity", function () {return reportOn.slices ? 0.6 : 0.4 })
            .attr('d', function (arc) { return d3.arc().outerRadius(arc.data.radius).innerRadius(0)(arc) })
            .attr("fill", function (arc) {return reportOn.slices ? pieColourMap[arc.data.key] : colourScale[0] });
    },

    /**
     * This method updates the filter to only match countries found in the countryCodes.
     * This method expects a space separated String 'ZA NA' and the filter for the section.
     * The filter is updated with (country is 'ZA' and country is 'NA')
     */
    generateCountryFilter: function (countryCodes, filter) {
        var countryCodeFilter = '';
        var cc = countryCodes.split(' ');
        for (var i = 0; i < cc.length; i++) {
            if (countryCodeFilter.length > 0) {
                countryCodeFilter += ' or ';
            }
            countryCodeFilter += 'country is \'' + cc[i] + '\'';
        }
        return filter + ' and ('+countryCodeFilter+')';
    },

    /**
     * We want to change the country boarders when the user zooms in or out.
     */
    updateStrokeWidth: function (g, scale) {

        function getStrokeWidth () {
            if (scale <= 1) {
                return 1;
            } else if (scale < 2) {
                return .75;
            } else if (scale < 3) {
                return .5;
            } else if (scale < 7) {
                return .25;
            } else {
                return .1;
            }
        }

        g.selectAll('path')
            .style('stroke-width', getStrokeWidth());
    },

    /**
     * Helper method to format numbers, handles decimals nicely.
     */
    formatNumber: function (val) {
        var ans;
        if (Math.abs(val) < 1) {
            ans = accounting.toFixed(val, 2);
        } else {
            ans = toSi(val);
        }
        return ans;
    }
};