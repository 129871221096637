import {ParagraphBlock} from "@/dashboards/widgets/commentaryplus/blockTools/ParagraphBlock";

export class BlockQuote extends ParagraphBlock {

    render() {
        const quoted = document.createElement("blockquote");
        const unquoted  = super.render();
        quoted.appendChild(unquoted);
        this._element = quoted;
        this._element.classList.add("commentary-plus-display-blockquote");
        this._element.style.backgroundColor = "rgb(245, 245, 245)";
        return this._element;
    }

    static get toolbox() {
        return {
            icon: '<i class="icon-quote-left-alt" ></i>',
            title: "Block Quote"
        };
    }

}