<template>
    <div class="deq-reset">
        <span v-if="text" v-html="text">
        </span>
        <span v-if="error">
            <i class="symbol-warning"></i> ERROR LOADING GLOSSARY TERM
        </span>
    </div>
</template>


<script>
import {getGlossaryEntry} from "@/app/help/tips/tip-utils";
import {substituteTagParamaters} from "@/app/utils/Tags";
import substituteBrand from "@/helpers/substituteBrand";

export default {
    props: {
        glossaryId: {
            type: String,
            required: true
        },
        brand: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            entry: null,
            error: false
        }
    },

    computed: {
        text() {
            if (!this.entry) return null;
            if (!this.brand) return substituteBrand(this.entry.text);
            return substituteTagParamaters(this.entry.rawText, [this.brand])
        }
    },

    watch: {
        glossaryId() {
            this.loadEntry();
        }
    },

    async created() {
        await this.loadEntry();
    },

    methods: {
        async loadEntry() {
            try {
                this.entry = await getGlossaryEntry(this.glossaryId);
                this.error = false;
            } catch(e) {
                console.error(e);
                this.error = true;
            }
        }
    }
}
</script>


<style scoped lang="sass">

</style>