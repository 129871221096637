<template>
    <span class="deq-plural-word">
        {{ plural }}
    </span>
</template>

<script>
import {formatPlural} from "@/app/utils/Format";
import {escapeExpression} from "@/app/utils/StringUtils";

export default {
    props: {
        word: {type: String, required: true},
        count: {type: Number, required: true}
    },

    computed: {
        plural() {
            return escapeExpression(formatPlural(this.count ?? 1, this.word ?? ""));
        }
    }
}
</script>

<style scoped lang="sass">

.deq-plural-word
    display: contents

</style>