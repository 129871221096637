import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {splitAtSpaces} from "@/app/utils/StringUtils";

Beef.module("Widget.FantasticChart.Upgrade").addInitializer(function(startupOptions) {

    var thisModule = this;

    var upgradable = new Set([
        "Volumes",
        "UniqueAuthors", "UniqueSites",
        "Ave", "Ots",
        "SentimentTimeSeries",
        "Country", "Language", "Domains", "Gender", "Media",
        "Authors", "Sentiment", "Engagement",
        "DevDiscoveredTopics",
        "BrandMatrix",
        "Stats",
        "NetSentimentLineChart"
    ]);

    this.canUpgrade = function(name) {
        return upgradable.has(name);
    };

    this.shouldUpgrade = function(dashboard) {
        var sections = dashboard.get("sections");
        if (!sections || !sections.models || !sections.models.length) return false;

        return sections.models.some(function(section) {
            var compare = section.get("compare");
            if (compare && Object.keys(compare).length) return true;

            var widgets = section.get("widgets");

            if (!widgets || !widgets.models || !widgets.models.length) return false;

            return widgets.models.some(function(widget) {
                var type = widget.get("type");
                if (type === "Stats" || type === "BrandMatrix") {
                    var comparison = widget.get('comparison');
                    return comparison === "section"
                }
                return upgradable.has(type);
            })
        })
    };

    var checkComparisons = function(compare) {
        if (compare.published) {
            return "Unable to upgrade metrics that compare publication date. Upgrade cancelled. No changes have been made.";
        }

        if (Object.keys(compare).length > 1) {
            return "Unable to upgrade metrics performing so many comparisons at once. No changes have been made.";
        }

        return null;
    };

    //------------------------------------------------------------

    this.fixSectionComparisonFilter = function(sectionModel, updates) {
        var compare = sectionModel.get("compare") || {};
        var sectionFilter = sectionModel.get("filter");
        updates = updates || {};

        if (compare && compare.brand && !updates.compare) {
            var brands = splitAtSpaces(compare.brand);
            sectionFilter = appendFiltersReadably(
                sectionFilter,
                brands
                    .map(function (b) {
                        return "brand isorchildof " + b
                    })
                    .join(" or ")
            );
            if (!sectionFilter) {
                throw "Unable to upgrade the sub-filters on this metric. Upgrade cancelled. No changes have been made.";
            }
            updates.compare = "brand";
        }

        if (compare && compare.tags && !updates.compare) {
            var tags = splitAtSpaces(compare.tags);
            sectionFilter = appendFiltersReadably(
                sectionFilter,
                tags
                    .map(function (b) {
                        return "tag is " + b
                    })
                    .join(" or ")
            );
            if (!sectionFilter) {
                throw "Unable to upgrade the sub-filters on this metric. Upgrade cancelled. No changes have been made.";
            }
            updates.compare = "tag";
        }

        if (compare && compare.topics && !updates.compare) {
            var topics = splitAtSpaces(compare.topics);
            sectionFilter = appendFiltersReadably(
                sectionFilter,
                topics
                    .map(function (b) {
                        return "topic is " + b
                    })
                    .join(" or ")
            );
            if (!sectionFilter) {
                throw "Unable to upgrade the sub-filters on this metric. Upgrade cancelled. No changes have been made.";
            }
            updates.compare = "topic";
        }

        if (sectionFilter !== sectionModel.get('filter')) {
            sectionModel.set('filter', sectionFilter);
        }

        return sectionFilter;
    };

    //------------------------------------------------------------

    this.widget = function(model, noSave, force) {
        var currentType = model.get('type');

        if (!thisModule.canUpgrade(currentType)) return Promise.resolve();

        var compare = model.getCompare() || {};

        var cannotCompare = checkComparisons(compare);
        if (cannotCompare) {
            if (!force) return Promise.reject(cannotCompare);
            compare = {};
        }

        var chartType = model.get("chart-type");
        var labelType = model.get("label-type");
        var yAxis = "mentionPercent";
        switch (labelType) {
            case "percentage":
                yAxis = "mentionPercent"; break;
            case "count":
                yAxis = "mentionCount"; break;
            case "ave":
                yAxis = "totalOnlineAVE"; break;
            case "ots":
                yAxis = "totalOTS"; break;
        }

        function getAuthorUpdates(options) {
            return Object.assign({
                "type": "Stats", "show-deltas": true, "exact": true,
                "volume": false, "uniqueAuthors": false, "OTS": false, "engagement": false, "reshares": false, "replies": false
            }, options);
        }

        function getChart(xAxis, yAxis, compare) {
            return {
                type: "FantasticChart",
                xAxis: xAxis,
                yAxis: yAxis,
                compare: compare
            }
        }

        var understoodCoarseness = new Set([
            "hourly",
            "daily",
            "weekly",
            "monthly",
            "yearly",
            "auto"
        ]);

        var updates = null, attrs = model.attributes;
        switch (currentType) {
            case "Volumes":
                updates = chartType !== "text"
                    ? getChart("published", "mentionCount")
                    : getAuthorUpdates({volume: true});

                break;
            case "Ave":
                updates = chartType !== "text"
                    ? getChart("published", "totalOnlineAVE")
                    : getAuthorUpdates({AVE: true});
                break;
            case "Ots":
                updates = chartType !== "text"
                    ? getChart("published", "totalOTS")
                    : getAuthorUpdates({OTS: true});
                break;
            case "UniqueAuthors":
                updates = chartType !== "text"
                    ? getChart("published", "authorIdCount")
                    : getAuthorUpdates({uniqueAuthors: true});
                break;
            case "UniqueSites":
                updates = chartType !== "text"
                    ? getChart("published", "siteCount")
                    : getAuthorUpdates({uniqueSites: true});
                break;
            case "SentimentTimeSeries":
                updates = getChart("published", "mentionPercent", "sentiment");
                break;
            case "Country":
                updates = getChart("country", yAxis);
                break;
            case "Language":
                updates = getChart("language", yAxis);
                break;
            case "Gender":
                updates = getChart("gender", yAxis);
                break;
            case "Media":
                updates = getChart("category", yAxis);
                break;
            case "Domains":
                updates = getChart("site", yAxis);
                break;
            case "Authors":
                updates = chartType !== "text"
                    ? getChart("fullAuthor", yAxis)
                    : getAuthorUpdates({uniqueAuthors: true});
                break;
            case "Sentiment":
                updates = getChart("sentiment", yAxis);
                break;
            case "Engagement":
                updates = chartType !== "text"
                    ? getChart("published", "totalEngagement")
                    : getAuthorUpdates({engagement: true});
                break;
            case "Topics":
                updates = {
                    type: "Conversation"
                }
                break;
            case "DevDiscoveredTopics":
                updates = getChart("topic", yAxis);
                break;
            case "BrandMatrix":
                var brandMatrixComparison = model.get('comparison');
                if (!brandMatrixComparison || brandMatrixComparison === 'section') {
                    brandMatrixComparison = 'none';
                    if (compare.tags) brandMatrixComparison = 'tags';
                    if (compare.topics) brandMatrixComparison = 'topics';
                }

                updates = {
                    comparison: brandMatrixComparison
                };
                break;
            case "Stats":
                var statsComparison = model.get('comparison');
                if (!statsComparison || statsComparison === "section") {
                    statsComparison = 'none';
                    if (compare.brand) statsComparison = 'brands';
                    if (compare.tags) statsComparison = 'tags';
                    if (compare.topics) statsComparison = 'topics';
                }

                updates = {
                    comparison: statsComparison
                };
                break;
            case "NetSentimentLineChart":
                updates = {
                    type: "FantasticChart",
                    caption: attrs.caption,
                    'manual-caption': true,
                    xAxis: "published",
                    coarseness: attrs.coarseness,
                    hideLegend: true,
                    show: [
                        {
                            "opacity": 50,
                            "yAxis": "posAndNegPercent",
                            "geometry": "columns",
                            "barOutline": 0,
                            "barPadding": 20,
                            "labelPlacement": "inside",
                            "hideLabels": true
                        },
                        {
                            "yAxis": "totalSentimentPercent",
                            "geometry": "lines",
                            "barOutline": 0,
                            "barPadding": 20,
                            "labelPlacement": "inside"
                        }
                    ]
                }
                if (!understoodCoarseness.has(updates.coarseness)) updates.coarseness = "daily"
                break
        }

        if (!updates) {
            return Promise.reject("Sorry, we're unable to upgrade this metric at this time");
        }

        // Ensure that we preserve width and height, or use the
        // old metric default if that was what it was using.
        updates.width = model.get("width") || 2;
        updates.height = model.get("height") || 2;

        // Ensure that the widget doesn't reset some settings when updating the widget type.
        updates['_preserveSettingsOnUpdate'] = true;

        if (model.get('hide-others')) updates['hide-other'] = true;

        // Ensure that we only use coarseness values that we understand.
        if (updates.coarseness && !understoodCoarseness.has(updates.coarseness)) {
            delete updates.coarseness;
        }

        try {
            thisModule.fixSectionComparisonFilter(model.getSectionModel(), updates)
        } catch (e) {
            return Promise.reject(e);
        }

        updates["in-simple"] = labelType !== "ave";
        switch(chartType) {
            case "columns":
                updates.geometry = "columns";
                break;
            case "rows":
                updates.geometry = "rows";
                break;
        }

        return new Promise(function(resolve, reject) {
            var errorMessage = "There was an error upgrading this metric <p class='info'>Please reload this dashboard.</p>";
            try {
                model.set(updates);
                if (model.generalData) {
                    var completed = false;
                    model.generalData.bind("change:_completed", function() {
                        try {
                            if (!completed && model.generalData.get("_completed")) {
                                completed = true;
                                resolve();
                            }
                        } catch (e) {
                            model.generalData.set("_message", errorMessage);
                            reject(e);
                        }
                        setTimeout(function() {
                            if (!completed) {
                                model.generalData.set("_message", errorMessage);
                                reject(new Error("Timed out updating chart"));
                            }
                        }, 20000)
                    });
                } else {
                    resolve();
                }
            } catch (e) {
                model.generalData.set("_message", errorMessage);
                reject(e);
            }
        }).then(function() {
            if (!noSave) model.getSectionModel().save();
        })
    };


    //------------------------------------------------------------


    this.section = function(model, noSave, force) {
        var compare = model.get("compare") || {};

        var cannotCompare = checkComparisons(compare);
        if (cannotCompare) {
            if (!force) return Promise.reject(cannotCompare);
            compare = {};
        }

        // Fix the comparison filter, if it exists
        try {
            thisModule.fixSectionComparisonFilter(model);
        } catch (e) {
            return new Promise.reject(e);
        }

        // Upgrade all widgets
        var widgets = model.get('widgets').models;

        var upgrades = widgets.map(function(widget) {
            Beef.Widget.FantasticChart.Upgrade.widget(widget, true, !!force)
        });


        return Promise.all(upgrades)
            .then(function() {
                model.set({
                    "fantastic-upgrade": true,
                    compare: null
                });
                if (!noSave) model.getDashboardModel().save();
            })
    };

    this.dashboard = function(model, force) {
        var sections = model.get('sections').models;

        var upgrades = sections.map(function(section) {
            return Beef.Widget.FantasticChart.Upgrade.section(section, true, !!force);
        });

        return Promise.all(upgrades)
            .then(function() {
                model.set({"fantastic-upgrade": true});
                model.save();
            })
    };

});