/**
 * A module for logging support tickets. It creates a popup window where users enter all the necessary fields.
 */

Beef.module("SupportTicket").addInitializer(function(startupOptions) {


    var SupportTicket = Backbone.Model.extend({});
    var accountCode = startupOptions && startupOptions.account && startupOptions.account.code ? startupOptions.account.code : null;
    var titleKey = "beef:support-ticket:" + (accountCode ? accountCode : "landing-page") + ":title";
    var descriptionKey = "beef:support-ticket:" + (accountCode ? accountCode : "landing-page") + ":description";

    var View = Beef.BoundItemView.extend({
        template: require("@/app/popup/support-ticket/SupportTicket.handlebars"),

        attributes: { class: "interact-dialog dialog support-ticket" },

        events: {
            "click .send": "send",
            "click .cancel": "cancel",
            "keyup .ticket-title": "userTypedTitle",
            "keyup .ticket-description": "userTypedDescription",
            "click .query": "showQueryOptions",
            "click": "closeDropdown"
        },

        templateHelpers: function() {
            return {
                userEmail: Beef.user.get("email")
            };
        },

        /**
         * Attempts to log a new support ticket. Waits until Beef gives a good response, or warns when an error occurred.
         */
        send: function() {

            if ($("#send-ticket").hasClass("disabled") || this.currentlySending) return;

            this.currentlySending = true;

            var title = document.getElementById("ticket-title").value,
                description = document.getElementById("ticket-description").value,
                promise = null,
                send = this.$(".send"),
                sendLabel = this.$(".send-label"),
                sendingLabel = this.$(".sending-label");

            send.prop("title", "Sending your message");
            send.toggleClass("send-active", true);
            sendLabel.css({ display: "none" });
            sendingLabel.css({ display: "inherit" });

            var onComplete = function() {
                $.when(promise)
                    .then(function() {
                        this.cancel();
                        this.removeFromLocal();
                    }, this)
                    .fail(function() {
                        alert("There was an error sending the message. Please reload and try again.");
                    }.bind(this))
                    .always(function() {
                        this.currentlySending = false;
                        send.toggleClass("send-active", false);
                        sendLabel.css({ display: "inherit" });
                        sendingLabel.css({ display: "none" });
                    }.bind(this));
            }.bind(this);

            var category = this.model.get("category") && this.model.get("category").label ? this.model.get("category").type : null;
            promise = this.postTicket(title, description, Beef.user.get("email"), category);
            onComplete();
        },

        /**
         * Closes the popup window.
         */
        cancel: function() {
            Beef.Popup.closePopups(this);
            Beef.BoundItemView.prototype.close.call(this);
        },

        /**
         * Saves support ticket fields to local storage. Can be used to recover fields if the ticket is accidentally
         *     closed while typing.
         */
        saveToLocal: function() {
            // undefined values are saved as the string "undefined", so rather default to empty strings
            var title = this.model.get("title") || "",
                description = this.model.get("description") || "";
            localStorage.setItem(titleKey, title);
            localStorage.setItem(descriptionKey, description);
        },

        /**
         * Removes support ticket fields from local storage. Can be used to clear storage once the ticket has been sent.
         */
        removeFromLocal: function() {
            localStorage.removeItem(titleKey);
            localStorage.removeItem(descriptionKey);
        },

        /**
         * Validates the new title and updates local storage with the new text.
         */
        userTypedTitle: function(ev) {
            this.model.set("title", ev.target.value, { silent: true });
            this.toggleSend(this.isValid());
            this.saveToLocal();
        },

        /**
         * Validates the new description and updates local storage with the new text.
         */
        userTypedDescription: function(ev) {
            this.model.set("description", ev.target.value, { silent: true });
            this.toggleSend(this.isValid());
            this.saveToLocal();
        },

        /**
         * Determines whether the current ticket is valid for sending.
         */
        isValid: function() {
            var description = this.model.get("description") || " ",
                title = this.model.get("title") || " ";
            return (description.trim().length > 0) && (title.trim().length > 0);
        },

        /**
         * Enables or disables the send button.
         */
        toggleSend: function(enabled) {
            if (enabled) $("#send-ticket").removeClass("disabled");
            else $("#send-ticket").addClass("disabled");
        },

        /**
         * POSTs a support ticket to Beef.
         * @return A jQuery promise for the ticket POST request.
         */
        postTicket: function(title, description, email, category) {
            var deferred = new $.Deferred();
            if (title && accountCode) {
                title = title.trim() + " (" + accountCode + ")";
            }
            $.ajax({
                url: "/api/ticket/create",
                type: "POST",
                contentType: "application/json",
                headers: { Authorization: startupOptions.authorization },
                data: JSON.stringify({
                    accountCode: accountCode,
                    subject: title,
                    body: description,
                    email: email,
                    category: category,
                    url: window.location.href
                }),
                success: function() {
                    deferred.resolve();
                },
                error: function() {
                    deferred.reject();
                }
            });
            return deferred.promise();
        },

        closeDropdown: function() {
            if (this.popup) {
                this.popup.close()
            }
        },

        showQueryOptions: function(ev) {
            ev.stopPropagation();
            var that = this;
            this.closeDropdown();
            var target  = $(this.el).find(".query .caret")[0];
            //noinspection JSUnusedGlobalSymbols
            this.popup = Beef.MiniMenu.show({
                template: require("@/app/popup/support-ticket/SupportTicketMenu.handlebars"),
                dropdown: true,
                offsets: { right: -1, top: 10 },
                object: {
                    setCategory: function($t) {
                        var label = $t.text();
                        that.model.set("category", { type: $t.data("category"), label: label });
                        that.popup.close();
                        var el = $(that.el).find(".query .text");
                        el.text(label);
                        el.addClass("selected");
                    }
                },
                target: target,
                onRender: function() {
                    this.$el.toggleClass("mini-menu-selectable");
                }
            });
        }
    });

    this.show = function(ticketOptions) {
        var model = new SupportTicket();
        if(ticketOptions && ticketOptions.title && ticketOptions.description) {
            model.set("title", ticketOptions.title);
            model.set("description", ticketOptions.description);
        }
        else {
            var title = localStorage.getItem(titleKey),
                description = localStorage.getItem(descriptionKey);
            if (title) model.set("title", title);
            if (description) model.set("description", description);
        }
        var view = new View({ model: model }),
            popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"]});
        popup.setTarget($("#content"));
        popup.show(view);
        view.toggleSend(view.isValid());
    };
});
