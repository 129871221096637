<template>

    <main class="deq-reset">

        <div class="head title">
            <h1>Topics</h1>
        </div>

        <template v-if="!isLoading && !loadingError">

            <div v-if="topicList.length" class="topics-table dark-scrollbars dark-scrollbars--visible">
                <div class="header topic">Topic</div>
                <div class="header description">Description</div>
                <div class="header discovered">Discovered on</div>

                <div class="topic-container" v-for="(topic) in topicList" :key="topic.id">
                    <div class="topic">{{topic.name}}</div>
                    <div class="description">{{topic.description}}</div>
                    <div class="discovered">
                        {{formatTopicDate(topic.created)}}
                    </div>
                </div>
            </div>

            <div class="no-topics" v-if="!topicList.length">
                <h3>You have no topics</h3>
                <p>
                    No topics have been discovered in this account yet.
                </p>
                <p>
                    <em>Topic Discovery</em> is a service offered by the <em>DataEQ Crowd</em>. If you would
                    like to enable the service, please contact us.
                </p>
            </div>

            <br>
            <br>

        </template>

        <LoadingMessage v-if="isLoading" :message="'Fetching your topics from DataEQ...'" class="topics-loader" />

        <ErrorMessage v-if="loadingError" />

    </main>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LoadingMessage from "@/components/LoadingMessage.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import moment from "moment";

export default {
    name: "SetupTopics",
    components: {ErrorMessage, LoadingMessage},
    data() {
        return {
            isLoading: false,
            loadingError: false,
        }
    },

    computed: {
        ...mapGetters(['topicList'])
    },

    async mounted() {
       try {
           this.isLoading = true;
           this.loadingError = false
           await this.refreshTags(true);
           this.isLoading = false;
       } catch (e) {
           this.isLoading = false;
           this.loadingError = true
       }
    },

    methods: {
        ...mapActions(['refreshTags']),

        formatTopicDate(createdAt) {
            return moment(createdAt).format("MMMM Do YYYY")
        }
    }


}
</script>




<style scoped lang="sass">

.topics-loader
    --loading-message-delay: 100ms

.topics-table
    display: grid
    grid-template-columns: repeat(5, 1fr)
    border: 1px solid black // outside border
    border-bottom: none
    height: 80vh
    overflow-y: scroll

    .header
        background-color: #222
        position: sticky
        top: 0

    .topic
        grid-column: span 1
        border-right: 1px solid black
    .description
        grid-column: span 3
        border-right: 1px solid black
    .discovered
        grid-column: span 1

    .topic, .description, .discovered
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        text-align: center
        padding: 5px
        border-bottom: 1px solid black


    .topic-container
        display: contents

        .description
            text-align: left
            justify-content: flex-start

        .topic, .description, .discovered
            background-color: #333

.no-topics
    color: #eeeeee
</style>