import {currentAccountCode, isDevEnvironment} from "@/app/utils/Account";
import {bones} from "@/store/Services";
import {isMashAdmin} from "@/app/Permissions";

export class Goodness {
    /** @type String */
    level;

    /** @type String */
    query;

    /** @type {'TWITTER' | 'SAMPLE_STREAM'} */
    source;

    /** @type String */
    message;

    /** @type Number */
    mentionsPerDay;

    /** @type Number */
    mentionsTotal;

    /** @type [] */
    mentions = [];

    /**
     * @param {object} object
     * @return {Goodness}
     */
    static from(object) {
        return Object.assign(new Goodness(), object);
    }
}


/**
 * todo [x] get bones url into beef
 * todo [ ] lol what are comments?
 * todo [ ] Remove from mash
 * @param {number} brandId
 * @param {string | [string]} query
 *
 * @return {Promise<Goodness[]>}
 * @property {string} level
 */
export async function getGoodness(brandId, query) {
    if (!Number.isFinite(brandId)) throw new Error("bad argument to getGoodness: brandId should be a number");
    query = Array.isArray(query) ? query : [query];

    try {
        const url = `/rest/v1/accounts/${currentAccountCode()}/brands/${brandId}/phrases/goodness`;
        const response = await bones.post(url, query);
        return response.data.map(Goodness.from);
    } catch (error) {
        if (isDevEnvironment() && isMashAdmin() && error.isAxiosError && !error.response) {
            throw new Error("Dear dev: please start the bones backend service");
        } else {
            throw error;
        }
    }
}
