/**
 * Select an engage team.
 */
import {deprecatedFetchEngageTeams} from "@/data/DeprecatedBeefCache";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("EngageTeamPicker").addInitializer(function(startupOptions) {

    this.items = function(q, view, callback) {
        deprecatedFetchEngageTeams(view, callback);
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "engage-team-picker auto-complete-picker" },
        items: this.items
    });

    this.createConverterFactory = function(placeholder) {
        return function(view) {
            var conv;
            return function(direction, value) {
                if (value && value.length > 0) {
                    var binding = this;
                    if (conv) return conv.call(binding, direction, value);
                    deprecatedFetchEngageTeams(view, function(items) {
                        conv = createTagConverter({ items: items, placeholder: placeholder });
                        conv.call(binding, direction, value);
                    });
                } else {
                    $(this.boundEls[0]).text("");
                }
                return value;
            }
        }
    };

    this.converterFactory = this.createConverterFactory("Type the team name");

    /**
     * Attach an engage team picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
