<template>
    <div :class="{'disabled': disabled}" ref="holder"></div>
</template>

<script>
export default {
    name: "AttributeEditor",

    props: {
        value: String,
        disabled: Boolean,
        closeMenusOnClick: Boolean
    },

    data() {
        return {
            view: null,
            model: null,
            tempValue: ""
        }
    },

    created() {
        this.tempValue = this.value;
        this.model = new Backbone.Model({active: true, attributes: this.value});
        this.model.on("change", this.modelChanged);

    },

    mounted() {
        this.view = new Beef.Attributes.View({
            model: this.model,
            cache: cache,
            hideTitle: true,
            closeMenusOnClick: this.closeMenusOnClick
        });
        this.view.render();
        this.$refs.holder.append(this.view.el);
    },

    beforeDestroy() {
        this.view.close()
    },

    methods: {
        modelChanged() {
            if (this.value !== this.model.get("attributes")) {
                this.$emit('input', this.model.get("attributes"));
            }
        }
    }
}

const cache = { }
</script>

<style scoped lang="sass">

.disabled
    transition-delay: 100ms
    transition-duration: 250ms
    filter: grayscale(80%) blur(3px)
    cursor: default
    pointer-events: none

</style>