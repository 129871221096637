var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row-fluid\">\n"
    + ((stack1 = container.invokePartial(require("../../app/Header.handlebars"),depth0,{"name":"../../app/Header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"search-setup-block\">\n"
    + ((stack1 = container.invokePartial(require("../chart/SearchJobChart.handlebars"),depth0,{"name":"../chart/SearchJobChart","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"search-setup-block\">\n        <div id=\"table-region\"></div>\n    </div>\n\n</div>\n";
},"usePartial":true,"useData":true});