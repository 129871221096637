import VuexStore from "@/store/vuex/VuexStore";

/**
 * Navigate to the author panel using the given filter.
 * @param {FilterString,optional} filter
 * @param {Boolean,optional} [newTab = false]
 * @return {void}
 */
export function gotoAuthorsPanel(filter, newTab) {
    const code = VuexStore.state.account.code;
    newTab ??= false;
    let uri = "/" + code + "/authors";
    if (filter) uri += "?filter=" + encodeURIComponent(filter);
    if (newTab) window.open(uri);
    else Beef.router.navigate(uri, {trigger: true});
}