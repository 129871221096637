/**
 * A container object for instantiating the DataEQ Explore chart. The object also provides
 * access to utility functions.
 */
import {defaultCustom} from "@/app/utils/Colours";
import {formatNumber} from "@/app/utils/Format";
import _ from 'underscore';
import moment from "moment";

var beefRenderSearchJobChartD3 = {};

export default beefRenderSearchJobChartD3

beefRenderSearchJobChartD3.format = d3.utcFormat("%Y%m%d%H%M"); // e.g. "201606150000"
beefRenderSearchJobChartD3.parse = d3.utcParse("%Y%m%d%H%M"); // e.g. "201606150000"
beefRenderSearchJobChartD3.formatShort = d3.utcFormat("%Y%m%d"); // e.g. "20160615"

/**
 * Converts a historical search id into a chart key.
 */
beefRenderSearchJobChartD3.toKey = function(id) {
    return ''+id;
};

/**
 * Converts a historical search chart key into a job id.
 */
beefRenderSearchJobChartD3.toId = function(key) {
    return +key;
};

/**
 * Converts a chart key into an SVG path id.
 */
beefRenderSearchJobChartD3.toLineId = function(key) {
    return "line-" + key;
};

/**
 * Finds a series in a data set by its unique key.
 * @return {Number} The index of the series in the chart, or -1 if not found.
 */
beefRenderSearchJobChartD3.findSeries = function(key, data) {
    var found = false;
    var index = -1;
    if (key && data) {
        while (!found && (++index < data.length)) {
            found = (key === data[index].key);
        }
    }
    return found ? index : -1;
};

/**
 * Toggle the visibility of the loading screen.
 */
beefRenderSearchJobChartD3.setLoading = function(on, id) {
    if (id) {
        $("#" + id + " .widget .spinner-overlay").css("display", on ? "block" : "none");
    } else {
        $(".search-job-chart .chart .widget .spinner-overlay").css("display", on ? "block" : "none");
    }
};

/**
 * Converts an array of historical search DTOs into an array of chart series.
 */
beefRenderSearchJobChartD3.formatData = function(jobs, code) {
    var parseDate = beefRenderSearchJobChartD3.parse;
    var data = [];
    for (var i = 0; i < jobs.length; ++i) {
        var key = beefRenderSearchJobChartD3.toKey(jobs[i].id);
        var length = jobs[i].details.length;
        var series = {
            key: key,
            values: [],
            name: jobs[i].name,
            hide: false,
            url: code + "/archive-searches/" + jobs[i].id + '/',
            searchComplete: jobs[i].searchComplete,
            started: !!jobs[i].searchCommenceDate,
            startDate: new Date(jobs[i].searchRangeStartDate),
            endDate: new Date(jobs[i].searchRangeEndDate),
            colour: defaultCustom[i % defaultCustom.length]
        };
        for (var j = 0; j < length; ++j) {
            var point = jobs[i].details[j];
            series.values.push({
                y: +point.count,
                x: parseDate(''+point.bucketId),
                sampleSize: point.sample ? point.sample.length : 0,
                key: key,                                           // to identify the data series a point belongs to
                bucketId: ''+point.bucketId,
                series: beefRenderSearchJobChartD3.toLineId(key),   // identify the SVG path a point belongs to
                uniqueKey: key + "-" + point.bucketId,              // uniquely identify this point when binding data
                group: "tooltip-" + point.bucketId,                 // identify the tooltip group a point belongs
                groupLeft: "tooltip-left-" + j,                     // identify the tooltip group a point belongs to when shifted left
                groupRight: "tooltip-right-" + (length - j - 1)     // identify the tooltip group a point belongs to when shifted right
            });
        }
        data.push(series);
    }
    return data;
};

/**
 * Converts a date into a date string. The date string is formatted for consistency with the bucket id format
 * of broccoli's JSON payload.
 * @param {Date} date The date to format.
 * @return {string} A "%Y%m%d" formatted date string padded with 4 zeros, for example 201606150000.
 */
beefRenderSearchJobChartD3.toDateStamp = function(date) {
    var start = new Date(date);
    start.setUTCHours(0, 0, 0, 0);
    var end = new Date(new Date(start).setDate(start.getDate() + 1));
    end.setUTCHours(0, 0, 0, 0);
    var toFormat = (date - start) < ((end - start) / 2) ? start : end;
    return beefRenderSearchJobChartD3.formatShort(toFormat) + "0000";
};

beefRenderSearchJobChartD3.initialize = function(options) {
    options.x = options.x || {};
    options.y = options.y || {};
    options.brush = options.brush || { bounded: true };
    var container = d3.select(options.container);
    var pollerId;
    var pollInterval = options.pollInterval || 1000;
    var containerSvg = options.svg;
    var format = beefRenderSearchJobChartD3.format;
    var parse = beefRenderSearchJobChartD3.parse;
    var toDateStamp = beefRenderSearchJobChartD3.toDateStamp;

    var margin = { top: 30, right: 40, bottom: 60, left: 70 };
    var width = options.width - margin.left - margin.right;
    var height = options.height - margin.top - margin.bottom;

    // keep track of the data boundaries to restrict panning and zooming
    var xMin;
    var xMax;
    var yMin;
    var yMax;

    var circleSize = 5;    // default circle size for tooltips
    var opacityHidden = 0; // opacity of tooltip circles when not hovering
    var currentData = [];  // keep track of current data for polling
    var busyPolling = false;

    var GROUP_LEFT = "groupLeft";
    var GROUP_RIGHT = "groupRight";
    var GROUP_NONE = "group";
    var currentGroup = "group";
    var brushing = false;

    // keep track of the scales, axes, and data boundaries for zooming and panning
    var xScale = d3.scaleUtc()
        .range([0, width])
        .domain([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]);
    var yScale = d3.scaleLinear()
        .range([height, 0])
        .domain([0, 10]);
    var yAxis = d3.axisLeft(yScale)
        .tickSizeInner(-width)
        .tickFormat(options.y ? options.y.tickFormat : null);
    var xAxis = d3.axisBottom(xScale);

    var zoom = d3.zoom()
        // .x(xScale)
        // .y(yScale)
        .scaleExtent([1, Infinity])
        .on("zoom", zoomed);

    // var brush = d3.svg.brush()
    //     .x(xScale)
    //     .on("brushstart", brushstart)
    //     .on("brushend", brushend);

    // if (!options.brush.bounded) brush.y(yScale);

    var valueline = d3.line()
        .x(function(d) {
            return xScale(d.x);
        })
        .y(function(d) {
            return yScale(d.y);
        });
        // .interpolate("linear");

    var prepareSVG = function() {
        var chart = d3.select(containerSvg).select(".chart-elements")
            .attr("width", options.width)
            .attr("height", options.height);
        var svg = chart.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        svg.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", options.width - margin.left - margin.right)
            .attr("height", options.height - margin.top - margin.bottom)
            .attr("class", "chart-background");
        svg.append("clipPath").attr("id", "zoom-clip")
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", options.width - margin.left - margin.right)
            .attr("height", options.height - margin.top - margin.bottom);
        if (options.y && options.y.label) {
            chart.append("g").attr("class", "chart-labels").append("text")
                .text(options.y.label)
                .attr("class", "data-label")
                .attr("transform", "translate(20," + (margin.top + (height / 2)) + ") rotate(-90)");
        }
        return svg;
    };

    // the order in which groups are added should be maintained to ensure elements do not render over the tooltip hover area
    var svg = prepareSVG();
    svg.append("g")
        .attr("class","x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);
    svg.append("g")
        .attr("class","y axis")
        .call(yAxis);
    var zoomSVG = svg.append("g")
        .attr("class", "zoom-layer")
        .attr("clip-path", "url(#zoom-clip)")
        .append("g")
        .attr("class", "zoom-transform");
    var lineSVG = zoomSVG.append("g")
        .attr("class", "line-layer");
    var tooltipSVG = zoomSVG.append("g")
        .attr("class", "tooltip-layer");
    var tooltipHoverSVG = zoomSVG.append("g")
        .attr("class", "tooltip-hover-layer");

    var brushSVG = svg.append("g")
        .attr("class", "brush")
        .attr("display", "none")
        .on("mousemove", onBrushMousemove)
        .on("mouseout", onBrushMouseout)
        // .call(brush);
    brushSVG.selectAll("rect")
        .attr("height", height);

    // don't show the sample bar when there are multiple series
    var sampleSVG;
    if (options.showSampleBar) {
        var sampleLayer = svg.append("g")
            .attr("class","sample-layer")
            .attr("transform", "translate(0," + (options.height - margin.top - margin.bottom + 30) + ")");

        sampleLayer.append("clipPath").attr("id", "sample-clip")
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", options.width - margin.left - margin.right)
            .attr("height", 30);

        sampleSVG = sampleLayer.append("g")
            .attr("class","sample-bar-layer")
            .attr("clip-path", "url(#sample-clip)");

        sampleSVG.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", width)
            .attr("height", 5)
            .style("fill", "#d5d5d5")
            .style("stroke", "#d5d5d5");

        sampleSVG.append("text")
            .text("Sampled Dates")
            .attr("class", "data-label")
            .attr("transform", "translate(" + width/2 + "," + 25 + ")")
            .style("text-anchor", "middle");

    }
    /**
     * Checks whether a data set has at least one point.
     * @return {Boolean} True if there is at least one point in the data set.
     */
    function canRender(data) {
        var hasData = false;
        if (data && data.length > 0) {
            var i = -1;
            while (!hasData && (++i < data.length)) {
                hasData = data[i].values && (data[i].values.length > 0);
            }
        }
        return !!hasData
    }

    /**
     * Renders a data set as a multi-line chart.
     */
    function render(data) {
        closeTooltip();
        resetZoom();
        var chartElements = container.select(".chart .chart-elements");
        var overlay = container.select(".chart .widget .no-data-overlay");
        if (canRender(data)) {
            overlay.style("display", "none");
            chartElements.style("visibility", "visible");
        } else {
            chartElements.style("visibility", "hidden");
            overlay.style("display", "block");
        }

        currentData = data;
        var stats = getStatsForData(data);

        xMin = (options.x && options.x.min) ? Math.min(options.x.min, stats.x.min) : stats.x.min;
        xMax = (options.x && options.x.max) ? Math.max(options.x.max, stats.x.max) : stats.x.max;
        yMin = Math.min(0, stats.y.min);
        yMax = Math.max(stats.y.max, 5);

        // set a default domain to avoid errors
        var today = new Date();
        var lastWeek = new Date(today).setDate(today.getDate() - 7);
        var xDomain = [isNaN(xMin) ? lastWeek : xMin, isNaN(xMax) ? today : xMax];
        var yDomain = [isNaN(yMin) ? 0 : yMin, isNaN(yMax) ? 10 : yMax];

        if (currentData && (currentData.length > 1) && (currentGroup !== GROUP_NONE)) {
            var maxLength = getMaxLength(currentData);
            if (currentGroup === GROUP_LEFT) {
                var start = new Date(xDomain[0]);
                xDomain[1] = new Date(new Date(start).setDate(start.getDate() + maxLength));
            } else if (currentGroup === GROUP_RIGHT) {
                var end = new Date(xDomain[1]);
                xDomain[0] = new Date(new Date(end).setDate(end.getDate() - maxLength));
            }
        }

        // the order in which function calls are made should be maintained
        updateAxesAndScale(xDomain, yDomain);
        updateLines(data);
        addTooltipLayer(data);
        addTooltipHoverLayer();
        setStyle(data);
        shiftLines(data, currentGroup);
        onViewChange();
    }

    function updateAxesAndScale(xDomain, yDomain) {
        if (xDomain) {
            xScale.domain(xDomain);
            xAxis.scale(xScale);
            // zoom.x(xScale);
            // brush.x(xScale);
            svg.selectAll(".x.axis")
                .transition()
                .duration(0)
                .call(xAxis);
        }
        if (yDomain) {
            yScale.domain(yDomain);
            yAxis.scale(yScale);
            // zoom.y(yScale);
            // if (!options.brush.bounded) brush.y(yScale);
            svg.selectAll(".y.axis")
                .transition()
                .duration(0)
                .call(yAxis);
        }
    }

    /**
     * Updates the line paths with new data points, if any.
     * @param data
     */
    function updateLines(data) {
        // generate line paths and specify a key function so that d3 removes the correct elements on exit
        var values = getValues(data);
        var lines = lineSVG.selectAll(".line")
            .data(values, function(d) {
                return d.key;
            });

        lines.attr("d", valueline)
            .transition()
            .duration(0);

        lines.enter()
            .append("path")
            .attr("class",function(d) {
                return "line " + beefRenderSearchJobChartD3.toLineId(d.key);
            })
            .attr("id", function(d) {
                return beefRenderSearchJobChartD3.toLineId(d.key);
            })
            .attr("d", valueline);

        lines.exit().remove();
    }

    /**
     * Translates all series so that they either start from the minimum x-value, end at the maximum x-value,
     * or are positioned at their true coordinates.
     */
    function shiftLines(data, group) {
        if (data) {
            data.forEach(function(series) {
                if (series && series.values && (series.values.length > 0)) {
                    var t = [0, 0];
                    var line = beefRenderSearchJobChartD3.toLineId(series.key);
                    if (group === GROUP_LEFT) {
                        t[0] = -xScale(series.values[0].x);
                    } else if (group === GROUP_RIGHT) {
                        t[0] = xScale(xScale.domain()[1]) - xScale(series.values[series.values.length - 1].x);
                    }
                    zoomSVG.selectAll("." + line)
                        .transition()
                        .duration(0)
                        .attr("transform", "translate(" + t +")");
                }
            });
        }
    }

    /**
     * Handles a zoom event.
     */
    function zoomed() {
        console.log("zoomed event")
        // Beef tooltips are scrolled into view when shown. During zoom, this causes the chart to lose focus
        // when the tooltip target is no longer within the clip path of the chart.
        closeTooltip();
        // var t = d3.event.translate;
        var tx = d3.zoomTransform(this).x;
        var ty = d3.zoomTransform(this).y;
        var tk = d3.zoomTransform(this).k;
        // set the circle size relative to the zoom scale so that the pixel dimensions are kept consistent
        var bar = tooltipHoverSVG.select("#bar-" + getDateStampFromMouse(d3.mouse(this)[0]));
        if (bar[0] && bar[0][0]) {
            tooltipSVG.selectAll("circle.tip." + bar.datum()[currentGroup])
                .attr("r", (circleSize / tk));
                // .attr("r", (circleSize / zoom.scale()));
        }
        // keep the chart within the data boundaries.
        // have to use adjusted boundaries when the series are shifted
        var maxLength = getMaxLength(currentData);
        var xMinShifted = xMin;
        var xMaxShifted = xMax;
        if (currentGroup === GROUP_LEFT) {
            var minDate = new Date(xMin);
            xMaxShifted = minDate.setDate(minDate.getDate() + maxLength);
        } else if (currentGroup === GROUP_RIGHT) {
            var maxDate = new Date(xMax);
            xMinShifted = maxDate.setDate(maxDate.getDate() - maxLength);
        }
        if (xScale.domain()[0] < xMinShifted) {
            tx = tx - xScale(xMinShifted) + xScale.range()[0];
        }
        if (xScale.domain()[1] > xMaxShifted) {
            tx = tx - xScale(xMaxShifted) + xScale.range()[1];
        }

        if (yScale.domain()[0] < yMin) {
            ty = ty - yScale(yMin) + yScale.range()[0];
        }
        if (yScale.domain()[1] > yMax) {
            ty = ty - yScale(yMax) + yScale.range()[1]
        }

        // zoom.translate(t); todo

        zoomSVG.attr("transform", "translate(" + tx + "," + ty + ")scale(" + tk + ")");
        svg.select(".x.axis").call(xAxis);
        svg.select(".y.axis").call(yAxis);
        onViewChange();
    }

    /**
     * Resets all the zoom attributes.
     */
    function resetZoom() {
        // possible solution: svg.call(zoom.transform, d3.zoomIdentity);
        // zoom.translate([0, 0]); todo all this
        // zoom.scale(1);
        // zoom.scaleExtent([1, Infinity]);
        // zoomSVG.attr("transform", "translate(0,0)scale(1)");
    }

    function brushstart() {
        brushing = true;
    }

    function brushend() {
        /* brush is not defined?
        brushing = false;
        var xDomain;
        var yDomain = yScale.domain();
        if (!options.brush.bounded) {
            xDomain = [brush.extent()[0][0], brush.extent()[1][0]];
            yDomain = [brush.extent()[0][1], brush.extent()[1][1]];
        } else {
            xDomain = brush.extent();
        }

        var rect = brushSVG.select("rect.extent");
        if (!brush.empty() && (rect.attr("width") > 20) && (rect.attr("height") > 20)) {
            updateAxesAndScale(xDomain, yDomain);
            updateLines(currentData);
            addTooltipLayer(currentData);
            addTooltipHoverLayer();
            shiftLines(currentData, currentGroup);
            resetZoom();
            var s = (yMax - yMin) / (yDomain[1] - yDomain[0]);
            zoom.scaleExtent([(1 / s), Infinity]);
        }
        brushSVG.call(brush.clear());
        onViewChange();
         */
    }

    /**
     * Updates the chart element colours. This ensures all chart elements belonging to a series have consistent colours.
     * <p>
     * The colour assigned to a series and its related elements might change outside of this context. The colours
     * thus have to be reset manually instead of inside the d3 enter/exit pattern.
     * </p>
     */
    function setStyle(data) {
        if (data) {
            for (var i = 0; i < data.length; ++i) {
                var line = beefRenderSearchJobChartD3.toLineId(data[i].key);
                var colour =  data[i].colour || "black";
                svg.select("#" + line)
                    .attr("stroke", colour)
                    .classed("hide", data[i].hide);
                tooltipSVG.selectAll("." + line + " circle")
                    .style("fill", colour);
            }
        }
    }

    /**
     * Returns true if a coordinate is not within the current x or y domain.
     */
    function isOutOfView(x, y, milliOffset) {
        var ms = new Date(x).getTime();
        if (currentGroup === GROUP_LEFT) {
            ms += milliOffset.left;
        } else if (currentGroup === GROUP_RIGHT) {
            ms += milliOffset.right;
        }
        return (
               y < yScale.domain()[0]
            || y > yScale.domain()[1]
            || ms < new Date(xScale.domain()[0]).getTime()
            || ms > new Date(xScale.domain()[1]).getTime()
        );
    }

    /**
     * Returns a map of display properties for each series. Each series is keyed by the unique key associated with the
     * series. Given a point, the map allows quick access to the point's series properties. Properties that are returned
     * for a series include:
     * <ul>
     * <li> {Boolean} hide True if the series must be hidden.
     * <li> {Number} milliOffset.left The amount in milliseconds by which a point's x-value must be incremented if the series is shifted left
     * <li> {Number} milliOffset.right The amount in milliseconds by which a point's x-value must be incremented if the series is shifted right
     * </ul>
     */
    function getRenderProperties(data, xMin, xMax) {
        var props = {};
        if (data) {
            data.forEach(function(series) {
                if (series) {
                    props[series.key] = {};
                    props[series.key].hide = series.hide;
                    if (series.values && series.values.length > 0) {
                        props[series.key].milliOffset = { left: 0, right: 0};
                        props[series.key].milliOffset.left = new Date(xMin).getTime() - new Date(series.values[0].x).getTime();
                        props[series.key].milliOffset.right = new Date(xMax).getTime() - new Date(series.values[series.values.length - 1].x).getTime();
                    }
                }
            })
        }
        return props;
    }

    function getDatesForBars() {
        return d3.scaleUtc()
            .domain(xScale.domain())
            .ticks(d3.timeDay.every(1));
            // .ticks(d3.time.days.utc, 1);
    }

    function updateSampleBarWithJob(job, code) {
        if (currentData && currentData.length === 1) {
            var data = beefRenderSearchJobChartD3.formatData([job], code);
            asyncRender(data);
        } else {
            console.error("Cannot update sample bar for multiple series");
        }
    }

    /**
     * Adds sampled dates to the sample bar. Results in a NOP if the sample
     * bar is disabled or there is not only 1 series in the current data set.
     */
    function renderSampleBar() {
        if (!options.showSampleBar || !currentData || currentData.length !== 1) {
            return;
        }
        var values = currentData[0].values;
        var dates = d3.scaleUtc()
            .domain(xScale.domain())
            .nice()
            .ticks(d3.timeDay.every(1));

            // .ticks(d3.time.days.utc, 1);

        // add padding dates to prevent gaps at the edges of the sample bar
        // dates.unshift(d3.time.day.offset(dates[0], -1));
        // dates.push(d3.time.day.offset(dates[dates.length-1], 1));

        var points = dates.map(function(d) {
            var stamp = format(d);
            return {
                x: d,
                key: stamp,
                group: "sample-" + stamp
            };
        });

        var pointIdx = 0, dataIdx = 0;
        if (values.length > 0) {
            var days = Math.abs(moment(values[0].x).diff(points[0].x, "days"));
            if (values[0].x < points[0].x) {
                dataIdx += days;
            } else if (values[0].x > points[0].x) {
                pointIdx += days;
            }
        }
        while (pointIdx < points.length && dataIdx < values.length) {
            points[pointIdx].sampled = values[dataIdx].sampleSize > 0;
            points[pointIdx].key += values[dataIdx].sampleSize > 0 ? "-blue" : "-grey";
            pointIdx++;
            dataIdx++;
        }

        var bars = sampleSVG.selectAll(".sample-bar")
            .data(points, function(d) {
                return d.key;
            })
            .attr("class", "sample-bar");

        bars.exit()
            .transition()
            .duration(0)
            .attr("y", 0)
            .attr("height", 5)
            .remove();

        bars.enter()
            .append("rect")
            .attr("class", "sample-bar")
            .attr("id", function(d) {
                return "sample-bar-" + d.key;
            })
            .attr("y", 0)
            .attr("height", 5)
            .style("fill", function(d) {
                return d.sampled ? "rgb(88, 182, 255)" : "#d5d5d5";
            })
            .style("stroke", function(d) {
                return d.sampled ? "rgb(88, 182, 255)" : "#d5d5d5";
            });

        var barWidth =  (points.length < 2) ? 0 : xScale(dates[1]) - xScale(dates[0]);
        bars.enter()
            .selectAll(".sample-bar")
            .transition()
            .duration(0)
            .attr("x", function(d) {
                return xScale(d.x);
            })
            .attr("width", barWidth)
            .attr("y", 0)
            .attr("height", 5);
    }

    /**
     * Adds the hover response areas to the chart. These areas toggle the visibility of tooltips on hover events.
     */
    function addTooltipHoverLayer() {
        var dates = getDatesForBars();
        var length = dates.length;
        var points = dates.map(function(d, i) {
                var stamp = format(d);
                return {
                    x: d,
                    key: stamp,
                    group: "tooltip-" + stamp,
                    groupLeft: "tooltip-left-" + i,
                    groupRight: "tooltip-right-" + (length - i - 1)
                };
            });

        var bars = tooltipHoverSVG.selectAll(".bar")
            .data(points, function(d) { return d.key; })
            .attr("class","bar");

        bars.exit()
            .transition()
            .duration(0)
            .attr("y", 0)
            .attr("height", height)
            .remove();

        bars.enter()
            .append("rect")
            .attr("class", "bar")
            .attr("id", function(d) { return "bar-" + d.key; })
            .attr("y", 0)
            .attr("height", height)
            .style("opacity", 0);

        var barWidth =  (points.length > 0) ? (width / points.length) : 0;
        var xOffset = barWidth / 2;  // translate the bars to be centered on the data point x value
        bars.enter()
            .selectAll(".bar")
            .transition()
            .duration(0)
            .attr("x", function(d) { return xScale(d.x) - xOffset; })
            .attr("width", barWidth)
            .attr("y", 0)
            .attr("height", height);

        bars.on("mouseover", onBarMouseover)
            .on("mouseout", onBarMouseout);
    }

    /**
     * Handles a mouseout event for tooltip bars.
     * @param d A d3 datum.
     */
    function onBarMouseout(d) {
        closeTooltip();
        tooltipSVG.selectAll(".tip." + d[currentGroup])
            .style("opacity", opacityHidden);
    }

    /**
     * Handles a mousemove event for the brush.
     */
    function onBrushMousemove() {
        tooltipSVG.selectAll(".tip").style("opacity", opacityHidden);
        var bar = tooltipHoverSVG.select("#bar-" + getDateStampFromMouse(d3.mouse(this)[0]));
        if (bar[0] && bar[0][0]) {
            onBarMouseover(bar.datum());
        }
    }

    /**
     * Converts a pixel x-coordinate into a date string using the current x-scale. The date strings are
     * formatted for consistency with the bucket id format of broccoli's JSON payload.
     * @param x A pixel x-coordinate.
     * @return {string} A "%Y%m%d" formatted date string padded with 4 zeros, for example 201606150000.
     */
    function getDateStampFromMouse(x) {
        var date = xScale.invert(x);
        return toDateStamp(date);
    }

    /**
     * Handles a mouseout event for the brush.
     */
    function onBrushMouseout() {
        closeTooltip();
        tooltipSVG.selectAll(".tip").style("opacity", opacityHidden);
    }

    /**
     * Handles a mouseover event for tooltip bars.
     * @param d A d3 datum.
     */
    function onBarMouseover(d) {
        console.log("on bar mouseover");
        tooltipSVG.selectAll(".tip." + d[currentGroup])
            .style("opacity", 1);
        // set the circle size relative to the zoom scale so that the pixel dimensions are kept consistent
        console.log("zoom amount", d3.zoomTransform(this).k);
        var circles = tooltipSVG.selectAll("circle.tip." + d[currentGroup])
            .attr("r", (circleSize / d3.zoomTransform(this).k));
        if (!brushing && options.tooltip.beefTooltipOn) {
            var series = [];
            var max = -1;
            var target = this;
            // calculate the offset that should be applied after the series were shifted
            var renderProps = getRenderProperties(currentData, xMin, xMax);

            // filter out hidden elements and accumulate the rest
            circles.each(function() {
                var datum = d3.select(this.parentNode).datum();
                var props = renderProps[datum.key] || {};
                if (datum && !props.hide && !isOutOfView(datum.x, datum.y, props.milliOffset)) {
                    if (max < datum.y) {
                        max = datum.y;
                        target = this;
                    }
                    series.push({ x: datum.x, y: datum.y, colour: this.style.fill });
                }
            });
            if (series.length > 0) {
                var verbose = (currentGroup !== GROUP_NONE);
                series.sort(function(a, b) { return d3.descending(a.y, b.y); });
                Beef.Tooltip.show({
                    template: require("@/historical-search/chart/SearchJobChartTooltip.handlebars"),
                    target: target,
                    autoclose: true,
                    model: new Backbone.Model({
                        date: verbose ? null : d.x,
                        series: series,
                        verbose: verbose
                    })
                });
            }
        }
    }

    /**
     * Adds elements to each series in the chart to highlight data points on a mouseover event.
     */
    function addTooltipLayer(data) {
        var nodeGroup = tooltipSVG.selectAll(".node-group")
            .data(data, function(d) {
                return d.key;
            });

        nodeGroup.enter()
            .append("g")
            .attr("class", function(d) {
                return "node-group " + beefRenderSearchJobChartD3.toLineId(d.key);
            });

        var tooltipEnter = nodeGroup.selectAll("g.node")
            .data(function(d) {
                return d.values;
            }, function(d) {
                return d.uniqueKey;
            })
            .enter()
            .append("g")
            .attr("class", "node")
            .attr("transform", function(d) {
                return "translate(" + xScale(d.x) + "," + yScale(d.y) + ")";
            });

        tooltipEnter.append("circle")
            .attr("r", circleSize)
            .attr("class", function(d) {
                return "tip " + d.group + " " + d.groupLeft + " " + d.groupRight;
            })
            .style("opacity", opacityHidden);

        if (options.tooltip.textOn) {
            tooltipEnter
                .append("text")
                .style("text-anchor", "middle")
                .style("opacity", opacityHidden)
                .attr("dy", -10)
                .attr("class", function(d) {
                    return "tip " + d.group + " " + d.groupLeft + " " + d.groupRight;
                })
                .text(function(d) {
                    return d.y;
                });
        }

        // need to do a transition in case the scale changed
        nodeGroup.selectAll("g.node").transition()
            .duration(0)
            .attr("transform", function(d) {
                return "translate(" + xScale(d.x) + "," + yScale(d.y) + ")";
            });

        nodeGroup.exit().remove();
    }

    /**
     * Extracts the series values from a data set.
     */
    function getValues(data) {
        var values = [];
        if (data) {
            for (var i = 0; i < data.length; ++i) {
                values[i] = data[i].values;
                values[i].key = data[i].key; // TODO expose the key differently
            }
        }
        return values;
    }

    /**
     * Returns the maximum number of points of a series in a data set.
     */
    function getMaxLength(data) {
        var lengths;
        if (data) {
            lengths = data.map(function(series) {
                return (series && series.values) ? series.values.length : 0;
            });
        }
        return lengths ? d3.max(lengths) : 0;
    }

    /**
     * Closes any open chart tooltips if they are enabled.
     */
    function closeTooltip() {
        if (options.tooltip.beefTooltipOn) {
            Beef.Tooltip.close();
        }
    }

    /**
     * Calculates the max and min for both the x and y axes on a data set. Assumes that the x values are sorted in
     * ascending order.
     */
    function getStatsForData(data) {
        var x = {};
        var y = {};
        if (data) {
            y.max = d3.max(data, function(series) { return d3.max(series.values, function(d) { return d.y; }); });
            y.min = d3.min(data, function(series) { return d3.min(series.values, function(d) { return d.y; }); });
            data.forEach(function (series) {
                if (series.values && (series.values.length > 0)) {
                    x.min = d3.min([x.min,  series.values[0].x]);
                    x.max = d3.max([x.max, series.values[series.values.length - 1].x]);
                }
            });
        }
        return { x: x, y: y };
    }

    /**
     * Stops the chart from polling.
     */
    function clearPoll() {
        if(pollerId) clearTimeout(pollerId);
        pollerId = null;
    }

    /**
     * Resumes chart polling.
     */
    function startPoll() {
        clearPoll();
        pollerId = setTimeout(poll, pollInterval);
    }

    /**
     * Returns the indices of all the series in the data set that can be polled for new data.
     */
    function getPollIndices(data) {
        var indices = [];
        if (data) {
            for (var i = 0; i < data.length; ++i) {
                if (data[i].started && data[i].values && !data[i].searchComplete) {
                    indices.push(i);
                }
            }
        }
        return indices;
    }

    /**
     * Fetches data for all series in the data set whose searches are in progress. If new data is found, the chart
     * is updated.
     */
    function poll() {
        if (busyPolling) {
            return;
        }
        var data = currentData;
        var indices = getPollIndices(data);
        var spinner = container.select(".spinner-light");
        if (indices.length < 1) {
            busyPolling = false;
            spinner.style("visibility", "hidden");
        } else {
            busyPolling = true;
            spinner.style("visibility", "visible");
            var polled = {};
            var updateAfter = _.after(indices.length, function() {
                busyPolling = false;
                var changed = false;
                for (var prop in polled) {
                    if (polled.hasOwnProperty(prop)) {
                        var updated = polled[prop];
                        var index = beefRenderSearchJobChartD3.findSeries(updated.key, data);
                        if (index >= 0) {
                            changed = changed || (data[index].values.length !== updated.values.length);
                            if (changed) {
                                data[index].values = updated.values;
                            }
                        } else {
                            console.error("Cannot update chart: series " + updated.key + " not found");
                        }
                    }
                }
                if (changed) {
                    asyncRender(data);
                }
                startPoll();
            }.bind(this));

            var success = function(job) {
                if (job) {
                    var series = beefRenderSearchJobChartD3.formatData([job], options.accountCode)[0];
                    polled[series.key] = series;
                }
                updateAfter();
            };

            var error = function(xhr, status, error) {
                console.error(status + " " + error);
                updateAfter();
            };

            for (var i = 0; i < indices.length; ++i) {
                options.GET(data[indices[i]].url, {}, success, error);
            }
        }
    }

    /**
     * Creates a CSV string from a data set.
     * <p>
     * The first column contains the dates over which the data set spans. All other columns correspond to a series
     * in the data set. Missing x values (dates) are filled in so that there are no gaps. Missing y values are
     * filled in with empty strings.
     * </p>
     */
    function toCSV(data) {
        var lines = [];
        if (data) {
            var rowMap = {};
            var stats = getStatsForData(data);
            var dates = d3.scaleUtc()
                          .domain([stats.x.min, stats.x.max])
                          .ticks(d3.utcDay.every(1));
            var headers = ["Date"];
            var dateKeys = [];
            var i, j, key;

            // set the date column and empty strings for all other columns
            for (i = 0; i < dates.length; ++i) {
                key = format(dates[i]);
                dateKeys.push({ key: key, date: dates[i] });
                rowMap[key] = [];
                for (j = 0; j < data.length; ++j) {
                    rowMap[key].push({ y: "" });
                }
            }

            // override the empty stings in the rows where a series has values
            for (i = 0; i < data.length; ++i) {
                headers.push(data[i].name ? data[i].name : ("Search " + data[i].key));
                for (j = 0; j < data[i].values.length; ++j) {
                    var point = data[i].values[j];
                    key = format(point.x);
                    rowMap[key][i] = point;
                }
            }

            // prepare the CSV 2D array for d3's row formatter
            lines.push(headers);
            for (i = 0; i < dateKeys.length; ++i) {
                var k = dateKeys[i];
                var row = rowMap[k.key];
                var line = [k.date];
                for (j = 0; j < row.length; ++j) {
                    line.push(row[j].y);
                }
                lines.push(line);
            }
        }
        return d3.csvFormatRows(lines);
    }

    /**
     * Converts a data set into a CSV file and tries to download the file to the client.
     * @return {Boolean} True if the export was successful.
     */
    function exportAsCSV(data, filename) {
        var success = false;
        if (data) {
            var hideAndAdd = function(el) {
                el.style.display= "none";
                el.style.visibility = "hidden";
                document.body.appendChild(el);
            };

            var a = document.createElement("a");
            var csv = toCSV(data);
            var encoded = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);

            try {
                if ("download" in a) {
                    a.href = encoded;
                    a.setAttribute("download", filename);
                    hideAndAdd(a);
                    setTimeout(function() {
                        a.click();
                        document.body.removeChild(a);
                    }, 100);
                } else {
                    var frame = document.createElement("iframe");
                    frame.src = encoded;
                    hideAndAdd(frame);
                    setTimeout(function() {
                        document.body.removeChild(frame);
                    }, 400);
                }
                success = true;
            } catch(e) {
                console.error("Error exporting CSV: " + e.message);
            }
        }
        return success;
    }

    function onViewChange() {
        updateVolumeLabel();
        asyncAfterViewChange();
        renderSampleBar(currentData);
    }

    /**
     * Updates the volume label. Results in a NOP if the label is disabled.
     */
    function updateVolumeLabel() {
        if (options.showVolume) {
            var domain = xScale.domain();
            var start = parse(toDateStamp(new Date(domain[0])));
            var end = parse(toDateStamp(new Date(domain[1])));
            var sum = sumOverRange([start, end], false);
            sum = formatNumber(sum);
            document.getElementById("volume-label").innerHTML = sum;
        }
    }

    /**
     * Sums the y-values of each point in the current chart data set. The summation does not account for series shifting.
     * @param {Date[]} range The date range that should be summed over. Both boundaries are inclusive.
     * @param {Boolean} countHidden If true, points that are outside the y axis boundaries are summed.
     * @param {number} limit If greater than 0, each point cannot contribute more than the limit to the sum.
     * @return {number} The sum of all points, or 0 if the current chart data is falsey.
     */
    function sumOverRange(range, countHidden, limit) {
        if (!currentData) {
            return 0;
        }

        var sum = 0;
        var days = moment(range[1]).diff(range[0], "days") + 1;

        function sumSeries(series) {
            var values = series.values;
            var seriesSum = 0;
            var d0 = parse(values[0].bucketId);
            var diff = moment(range[0]).diff(d0, "days");
            var start = Math.max(0, diff);
            var end = Math.min(values.length, days + diff);
            for (var i = start; i < end; ++ i) {
                if (countHidden || !isOutOfView(values[i].x, values[i].y, { left: 0, right: 0})) {
                    if (limit > 0) {
                        seriesSum += Math.min(limit, values[i].y);
                    } else {
                        seriesSum += values[i].y;
                    }
                }
            }
            return seriesSum;
        }

        currentData.forEach(function(series) {
            if (series.values && (series.values.length > 0)) {
                sum += sumSeries(series);
            }
        });
        return sum;
    }

    /**
     * Returns a copy of the x domain of the chart.
     * @return {Date[]}
     */
    function getXDomain() {
        var domain = xScale.domain();
        return [new Date(domain[0]), new Date(domain[1])];
    }

    function asyncRender(data, callback) {
        setTimeout(function() {
            render(data);
            if (callback) {
                try {
                    callback();
                } catch(e) {
                    console.error(e.message);
                }
            }
        }, 0);
    }

    function asyncAfterViewChange() {
        if (options.afterAxesUpdate) {
            setTimeout(function() {
                try {
                    options.afterAxesUpdate(getXDomain());
                } catch(e) {
                    console.error(e.message);
                }
            }, 0);
        }
    }

    /**
     * Initialises the button events and statuses, and sets the corresponding chart behaviour.
     */
    function initTitleButtons() {
        var menu = d3.select(".chart-menu");
        var brushAndZoom = menu.selectAll(".brush-and-zoom");
        var panAndZoom = menu.selectAll(".pan-and-zoom");
        var shiftLeft = menu.selectAll(".shift-left");
        var shiftRight = menu.selectAll(".shift-right");

        svg.on("dblclick", function() {
            // set the circle size relative to the zoom scale so that the pixel dimensions are kept consistent
            var callback = null;
            var bar = tooltipHoverSVG.select("#bar-" + getDateStampFromMouse(d3.mouse(this)[0]));
            if (bar[0] && bar[0][0]) {
                var circleClass = "circle.tip." + bar.datum()[currentGroup];
                callback = function() {
                    tooltipSVG.selectAll(circleClass)
                        .attr("r", (circleSize / zoom.scale()));
                }
            }
            asyncRender(currentData, callback);
        });

        function updateZoomBehaviour(behaviour) {
            if (behaviour === "PAN_AND_ZOOM") {
                // hide brush and enable zoom listeners
                brushSVG.attr("display", "none");
                svg.attr("cursor", "pointer");
                svg.call(zoom);
                zoom.scaleExtent([1, Infinity]);
            } else {
                // display brush and disable zoom listeners
                brushSVG.attr("display", null);
                svg.attr("cursor", null);
                svg.on(".zoom", null);
                svg.call(zoom)
                    .on("mousedown.zoom", null)
                    .on("touchstart.zoom", null)
                    .on("touchmove.zoom", null)
                    .on("touchend.zoom", null);
            }
            var options = getOptions();
            options.zoom = behaviour;
            setOptions(options);
        }

        function toggle(options) {
            options = options || {};
            if (typeof options.brushAndZoom === "boolean") brushAndZoom.classed("active", options.brushAndZoom);
            if (typeof options.panAndZoom === "boolean") panAndZoom.classed("active", options.panAndZoom);
            if (typeof options.shiftLeft === "boolean") shiftLeft.classed("active", options.shiftLeft);
            if (typeof options.shiftRight === "boolean") shiftRight.classed("active", options.shiftRight);
        }

        function toggleAndRender(options) {
            toggle(options);
            asyncRender(currentData);
        }

        // set default button states
        var toggleOptions = { shiftLeft: false, shiftRight: false, panAndZoom: false, brushAndZoom: false };
        var behaviour = getOptions().zoom;
        if (behaviour === "PAN_AND_ZOOM") {
            toggleOptions.panAndZoom = true;
            updateZoomBehaviour(behaviour);
        } else if (behaviour === "BRUSH_AND_ZOOM"){
            toggleOptions.brushAndZoom = true;
            updateZoomBehaviour(behaviour);
        } else {
            toggleOptions.panAndZoom = true;
            updateZoomBehaviour("PAN_AND_ZOOM");
        }
        toggle(toggleOptions);

        brushAndZoom.on("click", function() {
            currentGroup = GROUP_NONE;
            updateZoomBehaviour("BRUSH_AND_ZOOM");
            toggleAndRender({ shiftLeft: false, shiftRight: false, panAndZoom: false, brushAndZoom: true });
        });
        panAndZoom.on("click", function() {
            currentGroup = GROUP_NONE;
            updateZoomBehaviour("PAN_AND_ZOOM");
            toggleAndRender({ shiftLeft: false, shiftRight: false, panAndZoom: true, brushAndZoom: false });
        });
        shiftLeft.on("click", function() {
            currentGroup = GROUP_LEFT;
            toggleAndRender({ shiftLeft: true, shiftRight: false });
        });
        shiftRight.on("click", function() {
            currentGroup = GROUP_RIGHT;
            toggleAndRender({ shiftLeft: false, shiftRight: true });
        });
        menu.selectAll(".refresh").on("click", function() {
            currentGroup = GROUP_NONE;
            toggleAndRender({ shiftLeft: false, shiftRight: false });
        });
        menu.selectAll(".generate-data").on("click", function() {
            asyncRender(fillWithRandom(currentData));
        });
    }

    /**
     * Returns the localStorage namespace for the chart options.
     */
    function getStorageKey() {
        return "analyse:explore:trend-chart:options";
    }

    /**
     * Returns chart options stored in localStorage.
     * @return {Object} An object, or an empty object if no options are set.
     */
    function getOptions() {
        var stored = null;
        try {
            stored = localStorage.getItem(getStorageKey());
            if (stored) stored = JSON.parse(stored);
        } catch (e) {
            console.error(e.message);
        }
        return stored ? stored : {};
    }

    /**
     * Saves chart options to localStorage.
     */
    function setOptions(options) {
        try {
            localStorage.setItem(getStorageKey(), JSON.stringify(options));
        } catch(e) {
            console.error(e.message);
        }
    }

    /**
     * Populates a chart with random data. This must only be used for testing the chart.
     */
    function fillWithRandom(data) {
        if (data) {
            data.forEach(function(series) {
                if (series) {
                    var last = 20 + Math.floor(Math.random() * 20);
                    var points = d3.scaleUtc()
                        .domain([series.startDate, series.endDate])
                        // .ticks(d3.time.days.utc, 1)
                        .map(function (date) {
                            last = last + Math.floor((Math.random() * 6) - 3);
                            if (last < 0) last = Math.floor((Math.random() * 3));
                            return {
                                bucketId: format(date),
                                count: last
                            };
                        });
                    var job = { id: beefRenderSearchJobChartD3.toId(series.key), details: points };
                    series.values = beefRenderSearchJobChartD3.formatData([job])[0].values;
                }
            });
        }
        return data;
    }

    asyncRender(options.data);
    initTitleButtons();

    if (options.pollOn) {
        if (options.GET && options.accountCode) {
            startPoll();
        } else {
            console.error("Chart polling is disabled: must supply a GET function and 'accountCode' property");
        }
    }

    return {
        render: asyncRender,
        updateSampleBarWithJob: updateSampleBarWithJob,
        exportAsCSV: exportAsCSV,
        clearPoll: clearPoll,
        sumOverRange: sumOverRange,
        getXDomain: getXDomain,
        initTitleButtons: initTitleButtons
    }
};
