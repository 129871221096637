Beef.module("Widgets.TextOnlineProfileList").addInitializer(function(startupOptions) {

    this.Collection = Backbone.Collection.extend({
        model: Beef.Widgets.TextOnlineProfileItem.Model
    });

    this.View = Backbone.Marionette.CompositeView.extend({
        template: require("@/dashboards/widgets/onlineprofile/text/TextOnlineProfileList.handlebars"),

        itemView: Beef.Widgets.TextOnlineProfileItem.View,

        itemViewOptions: function() {
            return {
                code: this.code,
                filter: this.filter,
                collection: this.collection
            }
        },

        attributes: { class: 'text-online-profile-list' },

        itemViewContainer: ".profile-rows",

        initialize: function(options) {
            this.code = options.code;
            this.filter = options.filter;
            if (!this.code) {
                throw new Error("No account code supplied");
            }
            if (!this.filter) {
                throw new Error("No filter was supplied");
            }
        },

        onRender: function() { }
    });
});