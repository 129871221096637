Beef.module("EditBrandTopicTree").addInitializer(function(startupOptions) {

    this.View = Beef.SettingsDialog.View.extend({

        template: require("@/setup/brands/EditBrandTopicTree.handlebars"),

        attributes: { class: "edit-brand-topic-tree dialog" },

        regions: {
            selectTopicTree: '.select-topic-tree-region'
        },

        templateHelpers: {
        },

        onFirstRender: function() {
            var that = this;
            this.selectTopicTree.show(new Beef.SelectTopicTree.createView(this.model.get('topicTreeId'),
                    this.model.get('_canEdit'), function(tree) {
                that.model.set("topicTreeId", tree ? tree.id : 0);
                that.model.set("_topicTree", tree);
            }));
        },

        ok: function() {
            if (this.model.get('_canEdit')) Beef.SettingsDialog.View.prototype.ok.call(this);
            else this.cancel();
        }
    });
});

