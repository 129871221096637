import { beef } from "@/store/Services";
import './tips.sass';
import {displayTipNotification} from "@/app/help/tips/tip-utils";
import moment from "moment";
import VuexStore from "@/store/vuex/VuexStore";

/**
 * A function for showing a tip for the user to be aware of.
 * @param tipId
 */

let shownCache = new Set();

// System to stop tips being shown too frequently.
let processingTip = false;

// The time that the last tip was shown to the user at.
export function getLastTipDateKey() {
    return `analyse:users:${VuexStore.state.user.id}:tips:last-tip-date`
}

export function clearTipsCache() {
    shownCache.clear();
}

/**
 * Stop or start tips from being shown during this session.
 * @param {boolean} val
 */
export function setPauseTips(val) {
    processingTip = !!val;
}

/**
 * @returns {boolean}
 */
export function getPauseTips() {
    return processingTip;
}

/**
 * Decides whether a glossary tip should be shown or not.
 * @return {Promise<void>}
 */
export async function showGlossaryTip() {
    const {default: tips} = await import('./tip-text');
    if (tips) {
        const glossary = Object.values(tips).filter(t => t.isGlossary).map(t => t.id);
        return showTip.apply(null, glossary);
    }
}


/**
 * Shows a single tip. Can be passed multiple tip IDs, in which case
 * a tip is chosen from the list of IDs at random.
 * @param {String} tipId
 * @param {String} rest
 * @return {Promise<void>}
 */
export async function showTip(tipId, ...rest) {
    // even if tips in user settings are turned on, only show them if the feature flag is enabled

    try {
        // We've recently shown a tip. Cancel this one.
        if (processingTip) return;
        // Ensure we only show one tip at a time.
        processingTip = true;

        // Ensure that our user data is fresh.
        await VuexStore.dispatch('refreshUser');
        const user = VuexStore.state.user;

        // Only show tips if they are enabled in user settings
        const enabled = user.settings?.viewTips ?? true;
        if (!enabled) {
            processingTip = false;
            return;
        }

        // Ensure that we don't show tips too frequently.
        const LAST_TIP_DATE = getLastTipDateKey();
        try {
            const lastDateString = localStorage.getItem(LAST_TIP_DATE);
            if (lastDateString) {
                const lastDate = moment(Number.parseInt(lastDateString));
                if (lastDate.isAfter(moment().subtract(60, "minutes"))) {
                    processingTip = false;
                    return;
                }
            }
        } catch (e) {
            console.warn(e);
            localStorage.removeItem(LAST_TIP_DATE);
            processingTip = false;
            return;
        }

        if (!shownCache.size) {
            const seen = await beef.get(`/api/users/${user.id}/tips`);
            seen.data.forEach(id => shownCache.add(id));
        }

        const ids = [tipId, ...rest];

        // Choose a random tip ID if there is more than one given.
        if (ids.length > 1) {
            const unseenIds = ids.filter(id => !shownCache.has(id));
            if (!unseenIds.length) {
                // All the requested tips have been seen before.
                processingTip = false;
                return;
            }
            // Let's randomly choose one of the unseen tips.
            tipId = unseenIds[Math.floor(Math.random() * unseenIds.length)];
        }

        // check the cache, if it's not there, check the api
        if (shownCache.has(tipId)) {
            processingTip = false;
            return;
        }

        // We don't need to package the tip text if the user is not using tips,
        // so we import the module dynamically.
        const {default: tips} = await import('./tip-text');

        const unseenOnboardingTips = Object
            .values(tips)
            .filter(t => t.onboardingSequence && !shownCache.has(t.id))
            .sort((lhs, rhs) => lhs.onboardingSequence - rhs.onboardingSequence);

        if (unseenOnboardingTips.length) {
            // The user has not seen any of these onboarding tips, so now we will show one
            tipId = unseenOnboardingTips[0].id;
        }

        const tip = tips[tipId];
        if (!tip) {
            console.warn(`Unable to find tip for tip ID [${tipId}]`);
            processingTip = false;
            return;
        }

        if (tip.predicate && !(await tip.predicate())) {
            processingTip = false;
            return;
        }

        if (tip.chance) {
            const chance = Math.random();
            if (chance > tip.chance) {
                processingTip = false;
                return;
            }
        }

        let response = await beef.post(`/api/users/${user.id}/tips/${tipId}`);

        if (response.status === 200 || response.status === 201) {
            let seenTip = response.data.hasSeenTip;

            //add tip to cache regardless of result
            shownCache.add(tipId);

            if (!seenTip) {
                //user has not seen tip -> show it
                if (tip) {
                    displayTipNotification(tip);
                    setTimeout(() => processingTip = false, 1000 * 60) // Don't show tips too frequently.
                    try {
                        localStorage.setItem(LAST_TIP_DATE, "" + moment());
                    } catch (e) {
                        console.warn(e);
                        localStorage.removeItem(LAST_TIP_DATE)
                    }
                } else {
                    processingTip = false;
                }
            } else {
                //user has seen tip -> stop processing
                processingTip = false;
            }
        } else {
            console.error(`Server error occurred when checking whether or not user has seen tip id [${tipId}]`);
            processingTip = false;
        }
    } catch(e) {
        console.error(`Error occurred when trying to show tip id [${tipId}]`, e);
        processingTip = false;
    }
}


/**
 * Reset all of the tips recorded as seen by the user.
 */
export async function resetTips() {
    await beef.delete(`/api/users/${VuexStore.state.user.id}/tips`);
    shownCache.clear();
    localStorage.removeItem(getLastTipDateKey());
    sessionStorage.removeItem(getLastTipDateKey());
}