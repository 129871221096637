/**
 * Drill down by adding/removing topics to the filter.
 */
import {formatPercentage} from "@/app/utils/Format";
import {getDefaultTopicView} from "@/app/utils/Util";
import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";

Beef.module("Widget.TopicSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Topics",
        description:    "Interactively filter by topic",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.CountSelector.View.extend({

        groupby: "tag",
        filterAttribute: "topics",
        showPercentage: true,

        getTooltipText: function(item) {
            var tooltip = "Click to select mentions with the topic " + encloseInDisplayQuotes(item.name);
            if (item.description) tooltip += ".\n\n" + item.description;
            return tooltip;
        },

        getCountTooltip: function(item) {
            return formatPercentage(item.percentage, 2) + " of the mentions have the topic " + item.name;
        },

        tweakQuery: function(q) {
            var tree = getDefaultTopicView(q.filter);
            if (tree) q.filter = "(" + q.filter + ") and topic is " + tree;
            q.tagNamespace = "topic,topic_tree";
        },

        calculatePercent: function(items, q) {
            var tree = getDefaultTopicView(q.filter);
            var tot = null;

            if (tree) {
                tree = parseInt(tree);
                for (var i = 0; i < items.length; i++) {
                    if (items[i].id === tree) break;
                }

                if (i < items.length) {
                    var treeCount = items.splice(i, 1)[0];
                    tot = treeCount.count
                }
            }

            if (tot === null) {
                tot = 0;
                for (i = 0; i < items.length; i++) tot += items[i].count;
            }

            if (tot > 0) for (i = 0; i < items.length; i++) items[i].percentage = 100 * items[i].count / tot;
        },

        mapData: function(d) {
            return {
                id: d.tag.id, name: d.tag.name, count: d.mentionCount,
                percentage: d.percentage,
                description: d.tag.descriptions.en || d.tag.description
            }
        }
    });
});