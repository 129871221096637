import AdminPanel from "@/app/toplevel/admin/AdminPanel";
import VuexStore from "@/store/vuex/VuexStore";

let view = null;

/**
 * View etc. for the admin panel.
 */
Beef.module("Admin").addInitializer(function(_startupOptions) {
    function routeCallback() {
        const user = VuexStore.state.user;
        if (!user.admin) {
            Beef.content.show(Beef.view404);
            return
        }

        if (!view || view.vm.destroyed) {
            view = new Beef.VuejsView.View({component: AdminPanel});
            Beef.content.show(view);
        }
    }

    Beef.route("admin", "admin", routeCallback);
});
