import _ from 'underscore';

Beef.module("EngageRuleSettings").addInitializer(function(startupOptions) {

    this.createView = function(ruleModel, cache) {
        var model = new Backbone.Model(ruleModel.get('data') || {});
        // the EngageTeamPicker picker likes strings so convert the teamId (if any) to a string
        var teamId = model.get('teamId');
        if (teamId) model.set('teamId', "" + teamId);
        return new View({ruleModel :ruleModel, model: model, cache: cache})
    };

    var View = Beef.BoundItemView.extend({
        template: require("@/setup/rules/EngageRuleSettings.handlebars"),

        modelEvents: {
            "change:send": "sendToView",
            "change": "updateRuleModel"
        },

        events: {
            "click .send": "sendToModel"
        },

        bindings: function() {
            return {
                teamId: { converterFactory: Beef.EngageTeamPicker.converterFactory, elAttribute: "data-value" }
            };
        },

        initialize: function(options) {
            this.ruleModel = options.ruleModel;
        },

        onFirstRender: function() {
            Beef.EngageTeamPicker.attach(this, ".teamId", "teamId", {onlyOne: true});
            this.sendToView();
        },

        sendToModel: function(ev) {
            var id = $(ev.target).attr("data-id");
            if (id) {
                if (id == 'default') this.model.unset('send');
                else this.model.set("send", id);
            }
        },

        sendToView: function() {
            this.$el.find(".send button").toggleClass("active", false);
            var send = this.model.get('send');
            if (send === undefined || send == null) send = 'default';
            this.$el.find(".send button[data-id='" + send + "']").toggleClass("active", true);
        },

        onClose: function() {
            if (this.popup) this.popup.close();
        },

        updateRuleModel: function() {
            var data = Object.assign({}, this.model.attributes);
            if (!data.send) delete data.send;
            if (data.priority) data.priority = parseFloat(data.priority);
            else delete data.priority;
            if (data.teamId) {
                data.teamId = parseInt(data.teamId);
                data.teamName = this.cache.engageTeams ? this.cache.engageTeams[data.teamId] : null;
            } else {
                delete data.teamId;
                delete data.teamName;
            }
            if (_.isEmpty(data)) this.ruleModel.unset('data');
            else this.ruleModel.set('data', data);
        }
    });
});