<template>
    <div class="business-search">
        <h4>Search for the <i class="symbol-google-full"></i> Google Business Profile you would like to add</h4>

        <p>
            Adding an unauthorised Google Business Profile will allow you to track reviews, questions and answers for that business
        </p>

        <div class="business-search__search-container">
            <inline-text-input
                ref="searchInput"
                v-model="searchQuery"
                :disabled="searching"
                class="business-search__search-input"
                hide-controls
                placeholder="Search query e.g. the business name and address"
                signal-for-all
                text-required
            ></inline-text-input>

            <be-button :disabled="searching || searchQuery === ''" primary @click="search">
                <i v-if="!searching" class="symbol-search"></i>
                <spinner-component v-else :size="14"></spinner-component>
                {{ searching ? "Searching..." : "Search" }}
            </be-button>
        </div>

        <transition name="fade">
            <div
                v-if="foundGoogleBusinesses && foundGoogleBusinesses.length"
                class="business-search__business-list-container"
            >
                <p>Search results for "{{ displaySearchQuery }}"</p>

                <div
                    ref="GBusinessList"
                    class="business-search__business-list dark-scrollbars dark-scrollbars--visible"
                >
                    <div
                        v-for="business in foundGoogleBusinesses"
                        :key="business.handleId"
                        :class="{ selected: selectedBusiness.handleId === business.handleId, disabled: searching, inactive: businessExistsOnAccount(business) }"
                        class="business-search__business"
                        :tooltip="businessExistsOnAccount(business)? 'This business is already on your account':null "
                        @click="selectBusiness(business)"
                    >
                        <online-profile :profile="business"></online-profile>
                        <div class="link" tooltip="Open in Google Maps" @click.stop="openInGoogleMaps(business)">
                            <be-button  class="link-button" link
                            ><i class="icon-forward-2"></i>
                            </be-button>
                            <div class="link-text">Open in Google Maps</div>
                        </div>
                    </div>
                </div>

                <div class="business-search__business-list-footer">
                    <p v-if="selectedBusiness.handleId || selectedAlreadyExistingBusiness">{{ selectedBusinessMessage }}</p>
                    <p v-else>
                        Select the business profile you would like to add. Only businesses profiles that are not already on the account can be
                        added.
                    </p>
                </div>
            </div>
            <div
                v-else-if="!searching && !foundGoogleBusinesses.length"
                :class="{ 'error-msg': message.type === 'error' }"
                class="business-search__no-businesses-message"
            >
                <i v-if="message.type === 'error'" class="symbol-warning"></i> {{ message.message }}
            </div>
        </transition>
    </div>
</template>

<script>
import InlineTextInput from "@/components/inputs/InlineTextInput";
import BeButton from "@/components/buttons/BeButton";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import {profileTypes} from "@/setup/profiles/ProfileUtils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SpinnerComponent from "@/components/SpinnerComponent";
import {beef} from "@/store/Services";

const MESSAGE_TYPE = {
    INFO: "info",
    ERROR: "error"
};
const SEARCH_ERROR = ""
export default {
    name: "GoogleBusinessSearch",
    components: {SpinnerComponent, OnlineProfile, BeButton, InlineTextInput},

    data() {
        return {
            foundGoogleBusinesses: [],

            directPageSearchResult: null,
            searchQuery: "",
            displaySearchQuery: "",
            message: {
                type: MESSAGE_TYPE.INFO,
                message: `Enter a search query to find the business you would like to add and then click "Search".`
            },
            searching: false,
            enableNext: false,
            enablePrevious: false,
            selectedBusiness: {},
            selectedBusinessMessage: "",
            selectedAlreadyExistingBusiness: false,
            curScrollPos: 0
        };
    },

    created() {
        this.refreshProfiles();
    },

    mounted() {
        // event handler for pressing 'enter' key while search bar is in focus
        this.$refs.searchInput.$on("ok", () => {
            if (this.searchQuery && !this.searching) {
                this.search();
            }
        });
    },

    computed: {
        ...mapGetters("profiles", ["getGoogleUnauthorizedBusinesses"]),
        ...mapState(['account'])
    },
    methods: {
        ...mapActions("profiles", ["refreshProfiles"]),
        ...mapMutations("profiles", ["setNewPublicProfile"]),

        businessExistsOnAccount(business) {
            let existingBusiness = this.getGoogleUnauthorizedBusinesses?.find(p => p.handleId === business.handleId);
            return !!(existingBusiness && !existingBusiness.deleted);
        },

        async search() {
            try {
                this.searching = true;
                this.selectedBusiness = {};
                this.setNewPublicProfile(null);

                this.displaySearchQuery = this.searchQuery;

                // search Google businesses
                // set picture links & additional data
                this.foundGoogleBusinesses = [];
                
                let response = await beef.get(`/api/online-profile/google-business/search`, {
                    params: {
                    query: this.searchQuery,
                    accountCode: this.account.code
                    }
                });

                if (!response || !response.data || !response.data.length) {
                    this.setInfoMessage(`No businesses could be found for search query "${this.displaySearchQuery}"`);
                    return;
                }
                response.data.forEach(business => {
                    business.type = profileTypes.googleBusiness;
                    business.description = business.description?.replaceAll(", ", "\n");
                });
                this.foundGoogleBusinesses.push(...response.data);
            } catch (e) {
                console.error(
                    `An error occurred when searching for Google places businesses with search query ${this.searchQuery}: `,
                    e
                );

                this.foundGoogleBusinesses = [];
                this.setErrorMessage(
                    "An error occurred while searching for Google businesses. Please refresh the page and try again or contact support."
                );
            } finally {
                this.searching = false;
            }
        },

        setInfoMessage(message) {
            this.message = {
                type: MESSAGE_TYPE.INFO,
                message: message
            };
        },

        setErrorMessage(message) {
            this.message = {
                type: MESSAGE_TYPE.ERROR,
                message: message
            };
        },

        selectBusiness(business) {
            if (this.businessExistsOnAccount(business)) { 
                this.selectedBusinessMessage = `${business.handle} (${business.description}) already exists on the account`;
                this.selectedAlreadyExistingBusiness =true;
                return;
            }
            this.selectedAlreadyExistingBusiness = false;
            if (this.selectedBusiness?.handleId === business.handleId) {
                this.selectedBusiness = {};
                this.setNewPublicProfile(null);
                return;
            }

            this.selectedBusiness = business;

            let profile = {
                handle: business.handleId,
                handleId: business.handleId,
                name: business.name,
                pictureLink: business.pictureLink,
                type: profileTypes.googleBusiness,
                media: null,
                tagIds: [],
                relevant: false,
                description: business.description
            };

            this.selectedBusinessMessage = `${this.selectedBusiness.handle} (${business.description}) has been selected`;

            this.setNewPublicProfile(profile);
        },

        openInGoogleMaps(business) {
            window.open(business.link, "_blank");
        }
    }
};
</script>

<style scoped lang="sass">

.business-search

    p
        color: var(--be-colour-text-dark)

    .online-profile
        width: 70%
        text-align: left

    .link
        width: 30%
        display: flex
        align-items: center
        .link-text
            width: 100%
            text-align: left

    &__search-container
        display: flex
        column-gap: 30px
        padding-top: 20px
        border-top: 1px dashed var(--be-colour-muted-text-dark)

    &__search-input
        width: 60%

    &__business-list-container
        margin-top: 15px

    &__business-list
        height: 285px
        overflow-y: auto

    &__no-businesses-message
        transition-delay: 100ms
        transition-duration: 250ms
        height: 300px
        display: flex
        place-items: center
        justify-content: center
        font-style: italic
        color: var(--be-colour-muted-text-dark)

        &.error-msg
            color: var(--be-colour-warning)

    &__business
        display: flex
        align-items: center
        background: #333
        padding: 5px
        border-top: 1px solid #1a1a1a
        border-left: 1px solid #1a1a1a
        border-right: 1px solid #1a1a1a
        
        &.selected
            background: var(--background-menu-active) !important

        &:hover
            background: var(--background-menu-hover)
            cursor: pointer

        &:last-of-type
            border-bottom: 1px solid #1a1a1a

        &.disabled
            transition-delay: 100ms
            transition-duration: 250ms
            filter: grayscale(80%) blur(3px)
            cursor: default
            pointer-events: none

        &.inactive
            background: #1a1a1a
            color: #999
            ::v-deep *
                color: #999

    &__business-on-acc-msg
        border-left: 1px solid var(--be-colour-text-dark)
        margin-left: 20px
        padding-left: 20px
        color: var(--be-colour-text-dark)
        font-style: italic
        font-size: 0.9em
        width: 20%

    &__business-list-footer
        display: flex
        align-items: center
        margin-top: 15px

        .btn-container
            margin-left: auto
            display: flex

.fade-enter-active, .fade-leave-active
    transition: opacity .5s

.fade-enter, .fade-leave-to
    opacity: 0
</style>
