<template>
    <div class="deq-reset">
        <slot :text="text" :isTranslating="isTranslating"></slot>
        <footer v-if="hasCompleteSummary">
            <PopupMenu left>
                <template #activator>
                    <div class="with-summary-card__actions">
                        <InlineLoadingMessage v-if="currentState">
                                {{ currentState }}
                        </InlineLoadingMessage>
                        <span class="with-summary-card__more deq-callout">more »</span>
                    </div>
                </template>
                <section class="mini-menu">
                    <TooltipComponent>
                        <a :aria-disabled="disabled" @click="translate">Translate...</a>
                        <template #tooltip>
                            Translate this summary
                        </template>
                    </TooltipComponent>

                    <a :aria-disabled="disabled" v-if="!isTranslated" @click="translateIntoDefaultLanguage">Translate into {{defaultLanguageName}}</a>
                    <TooltipComponent v-if="isTranslated">
                        <a  :aria-disabled="disabled" @click="undoTranslation">Undo translation</a>
                        <template #tooltip>
                            See the original summary text
                        </template>
                    </TooltipComponent>

                    <a class="divider"></a>
                    <ul class="with-summary-card__openai">
                        <li>Summary powered by <a :href="poweredByUri" target="_blank">{{poweredByName}}</a></li>
                        <IfDebug>
                            <li>Summarised on <DeqDate :date="summary.created"/></li>
                        </IfDebug>
                    </ul>
                </section>
            </PopupMenu>
        </footer>
    </div>
</template>


<script>

import PopupMenu from "@/components/PopupMenu.vue";
import {getDefaultTargetLanguage, getLanguageName, translate} from "@/app/utils/Language";
import TooltipComponent from "@/components/tooltip/TooltipComponent.vue";
import {showTranslationDialog} from "@/app/framework/dialogs/Dialog";
import DeqDate from "@/components/formatters/DeqDate.vue";
import IfDebug from "@/components/IfDebug.vue";
import InlineLoadingMessage from "@/components/InlineLoadingMessage.vue";

export default {
    components: {InlineLoadingMessage, IfDebug, DeqDate, TooltipComponent, PopupMenu},

    props: {
        summary: Object,
        state: { type: String, required: false }
    },

    data() {
        return {
            text: this.summary?.summary ?? "nothing",
            defaultLanguageName:  getLanguageName(getDefaultTargetLanguage()),

            isTranslated: false,
            isTranslating: false
        }
    },

    computed: {
        disabled() {
            return !!(this.isTranslating || this.state);
        },

        currentState() {
            if (this.isTranslating) return "translating...";
            if (this.state) return this.state;
            return null;
        },

        poweredByName() {
            return this.summary?.poweredBy?.name ?? '3rd party APIs';
        },

        poweredByUri() {
            return this.summary?.poweredBy?.uri ?? '#';
        },

        hasCompleteSummary() {
            return this.summary && this.summary.algorithm !== 'DEFAULT_TEXT';
        }
    },

    watch: {
        'summary.summary'() {
            this.text = this.summary?.summary ?? "";
        }
    },

    methods: {
        async translate() {
            if (this.disabled) return;

            try {
                this.isTranslating = true;

                // let result = await translate(this.summary.summary, "af");
                let result = await showTranslationDialog(this.summary.summary);
                if (result) {
                    this.text = result.targetText;
                    this.isTranslated = true;
                    this.defaultLanguageName = getLanguageName(getDefaultTargetLanguage());
                }
            } finally {
                this.isTranslating = false;
            }

        },
        async translateIntoDefaultLanguage() {
            if (this.disabled) return;

            try {
                this.isTranslating = true;
                this.isTranslated = true;


                let result = await translate(this.summary.summary, getDefaultTargetLanguage());
                this.text = result.targetText;
            } finally {
                this.isTranslating = false;
            }

        },
        undoTranslation() {
            if (this.disabled) return;

            this.isTranslated = false;
            this.text = this.summary?.summary ?? "nothing";
        }
    }

}
</script>


<style scoped lang="sass">

.with-summary-card__actions
    display: flex

    & > * + *
        padding-left: 1rem

    & > *:first-child
       margin-left: auto

.with-summary-card__more
    cursor: pointer

.with-summary-card__openai
    margin: 0
    padding: var(--mini-menu-padding)
    cursor: default
    color: var(--be-colour-muted-text-dark)
    list-style: none

    a:not(:hover)
        transition: color var(--transition-duration)
        color: var(--be-colour-text-dark)

    li
        margin: 0



</style>