var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"icon-align-left shift-left\" title=\"Align left\"></i>\n        <i class=\"icon-align-right shift-right\" title=\"Align right\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"icon-attention-1 warn\"></i> An error occurred while processing this search. Reset this search by\n        editing its phrases or filters. If the problem persists,\n        <a class=\"create-support-ticket\" title=\"Request support by creating a new ticket\">contact support</a>.\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"spinner-light\"></span> Search Volume\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chart-menu pull-right\">\n    <i class=\"icon-zoom-in brush-and-zoom\" title=\"Zoom by scrolling or selecting an area\"></i>\n    <i class=\"icon-move pan-and-zoom\" title=\" Zoom by scrolling and pan by dragging\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"shiftDisabled") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    <i class=\"icon-arrows-cw refresh\" title=\"Refresh\"></i>\n    <i class=\"icon-download-5 export-as-csv\" title=\"Download CSV\"></i>\n</div>\n<h5>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chartError") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "</h5>\n";
},"useData":true});