<template>
    <section class="be-animated-check"
             :class="{'be-animated-check__not-animated': !animated}"
             ref="container"

             :style="{'--be-animated-check-scale': scale, width: width + 'px', height: height + 'px', left: left + 'px', top: top + 'px'}">
        <svg class="be-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="be-checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="be-checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
    </section>

</template>




<script>
    /**
     * Creates a simpmle animated checkmark. Great for confirming actions, etc.
     */
    export default {
        props: {
            sizePx: Number,
            animated: {
                type: Boolean
            }
        },

        data() {
            const defaultSize = 52;
            let pixels = defaultSize;
            let scale = 1;

            if (this.sizePx) {
                pixels = parseInt(this.sizePx);
                scale = pixels / defaultSize;
            }

            return {
                defaultSize: defaultSize,
                scale: scale,
                height: pixels,
                width: pixels,
                left: -pixels / 2,
                top: -3
            }
        },

        mounted() {
            const container = this.$refs.container;

            // If the user has not set any size, let's try to make this fit
            // with the font-size.
            if (container && !this.sizePx) {
                const styles = getComputedStyle(container);
                const fontSize = styles['font-size'];
                if (fontSize.endsWith('px')) {
                    const fontSizePx = parseInt(fontSize);
                    // We add 2px to make this look a bit nicer with text (which can be larger than its font-size, which defines the size of a lower-case x
                    const enlargedPx = fontSizePx + 2;

                    this.scale = enlargedPx / this.defaultSize;
                    this.width = enlargedPx;
                    this.height = enlargedPx;
                    this.left = -fontSizePx / 2;
                }
            }
        }
    }
</script>




<style scoped lang="sass">
    .be-animated-check
        display: inline-block
        position: relative
        transform: scale(var(--be-animated-check-scale, 0.4))

    svg
        position: relative
        top: -0.5em /* A hack. should be half its height, and added to the svg in the template. */

    .be-checkmark
        width: 52px
        height: 52px
        border-radius: 50%
        display: block
        stroke-width: 4
        stroke: var(--be-animated-check-color, var(--background-menu-active))
        stroke-miterlimit: 10
        box-sizing: border-box
        margin: auto
        box-shadow: inset 0 0 0 #7ac142
        animation: fill 400ms ease-in-out forwards, scale 250ms ease-in-out 1000ms both, empty 500ms ease-in-out 1250ms forwards

    .be-checkmark__circle
        stroke-dasharray: 166
        stroke-dashoffset: 166
        stroke-width: 1
        stroke-miterlimit: 10
        stroke: #333
        fill: none
        animation: stroke 500ms cubic-bezier(0.65, 0, 0.45, 1) forwards

    .be-checkmark__check
        transform-origin: 50% 50%
        stroke-dasharray: 48
        stroke-dashoffset: 48
        animation: stroke 250ms cubic-bezier(0.65, 0, 0.45, 1) 800ms forwards

    .be-animated-check__not-animated
        .be-checkmark__check, .be-checkmark__circle, .be-checkmark
            animation-duration: 0s
            animation-delay: 0s


    @keyframes stroke
        100%
            stroke-dashoffset: 0

    @keyframes scale
        0%, 100%
            transform: none
        50%
            transform: scale3d(1.1, 1.1, 1)

    @keyframes fill
        100%
            box-shadow: inset 0 0 0 30px #222

    @keyframes empty
        100%
            box-shadow: inset 0 0 0 30px var(--be-animated-check-background, transparent)
</style>