import _ from 'underscore';

/**
 * Low level d3 code to render the chord diagram. This is separate to facilitate quick development and testing using
 * a static HTML file.
 */
export default function beefRenderChord(domNode, width, height, rows, options) {
    options = Object.assign({language: 'en'}, options);

    rows = processTagTagRows(rows, options);
    // rows = processExtactWordExtractWordRows(rows, options);
    // console.log("rows", rows);

    rows = filterRowsBySentiment(rows, options.sentiment);
    if (options.maxRibbons) rows = removeSmallRows(rows, options.maxRibbons);

    // find the unique set of items being compared from all of our rows .. there will be one chord group for each
    var items = [];
    var itemsById = { };
    for (var i = 0; i < rows.length; i++) {
        var row = rows[i];
        if (!itemsById[row.item1.id]) items.push(itemsById[row.item1.id] = row.item1);
        if (!itemsById[row.item2.id]) items.push(itemsById[row.item2.id] = row.item2);
    }

    // sort items so items with the same category label are grouped together, then by label with items with no category last
    items.sort(function(a, b) {
        if (a.category && b.category) {
            var diff = a.category.label.localeCompare(b.category.label);
            if (diff) return diff;
        } else if (a.category) {
            return -1;
        } else {
            return +1;
        }
        return a.label.localeCompare(b.label);
    });

    var haveCategories = false;
    for (i = 0; i < items.length; i++) {
        items[i].index = i;
        if (items[i].category) haveCategories = true;
    }

    //console.log("items", items);

    var matrix = [], rowMatrix = [];

    var setMatrix = function(matrix, i, j, value) {
        var mr = matrix[i];
        if (!mr) matrix[i] = mr = [];
        mr[j] = value;
    };

    for (i = 0; i < rows.length; i++) {
        row = rows[i];
        var item1 = itemsById[row.item1.id], item2 = itemsById[row.item2.id];
        setMatrix(matrix, item1.index, item2.index, row.mentionCount);
        setMatrix(matrix, item2.index, item1.index, row.mentionCount);
        setMatrix(rowMatrix, item1.index, item2.index, row);
        setMatrix(rowMatrix, item2.index, item1.index, row);
    }
    for (i = 0; i < items.length; i++) {
        var a = matrix[i];
        if (a === undefined) matrix[i] = a = [];
        for (var j = 0; j < items.length; j++) if (a[j] === undefined) a[j] = 0;
    }

    var rowForChord = function(c) { return rowMatrix[c.source.index][c.target.index] };

    var ribbonColor = buildRibbonColorFn(rows);

    var sel = d3.select(domNode)
        .style("position", 'relative');

    var svg = sel.append("svg")
        .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
        .attr("width", width)
        .attr("height", height)
        .attr("class", 'lang-' + options.language);

    var tip = sel.append("div")
        .attr("class", "svg-tip topic-wheel-tip lang-" + options.language);

    var maxLabelWidth = 70;

    var radius = Math.min(width, height) * 0.5 - 8;

    // radius for category label donut
    var categoryOuterRadius = radius;
    var categoryInnerRadius = categoryOuterRadius - 20;

    // radius for chord diagram
    var farOuterRadius = (haveCategories ? categoryInnerRadius : radius) - 7;
    var outerRadius = farOuterRadius - maxLabelWidth - 7;
    var innerRadius = outerRadius - 20;

    var padAngle = 0.05;

    var chord = d3.chord()
        .padAngle(padAngle)
        .sortSubgroups(d3.descending);

    var arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    var bgArc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(farOuterRadius)
        .startAngle(function(d) { return d.startAngle - padAngle / 2})
        .endAngle(function(d) { return d.endAngle + padAngle / 2});

    var ribbon = d3.ribbon()
        .radius(innerRadius);

    var highlightedItem = null;

    var highlightItem = function(index) {
        svg.selectAll(".item").classed("hl", false);
        if (index !== null && (highlightedItem = items[index])) {
            var id = highlightedItem.id;
            svg.selectAll(".item[data-id='" + id + "']").classed("hl", true);
            svg.selectAll(".ribbon[data-to-id='" + id + "']").classed("hl", true);
            var a = matrix[index];
            for (var j = 0; j < a.length; j++) {
                if (a[j]) svg.select(".group[data-id='" + items[j].id + "']").classed("hl", true);
            }
        } else {
            highlightedItem = null;
        }
        svg.classed("hl-active", !!id);
    };

    var groupMouseEnter = function(d, i) {
        highlightItem(i);
        showTip.call(this, d, i);
    };

    var selectedItem = null;

    var setSelectedItem = function(item, highlight) {
        if (item) {
            if (selectedItem && selectedItem.id === item.id) return false;
            if (item.index === undefined) {
                for (var i = 0; i < items.length; i++) {
                    if (items[i].id === item.id) {
                        item = items[i];
                        break;
                    }
                }
            }
        } else {
            if (!selectedItem) return false;
        }
        selectedItem = item;
        svg.selectAll(".group").classed("sel", false);
        if (item) svg.selectAll(".group[data-id='" + item.id + "']").classed("sel", true);
        if (options.onSelectionChanged) options.onSelectionChanged(item);
        if (highlight && item) highlightItem(item.index);
        return true;
    };

    var groupClick = function(d, i) {
        d3.event.stopPropagation();
        if (!setSelectedItem(items[d.index])) {
            setSelectedItem(null);
            highlightItem(null);
        }
    };

    var ribbonMouseOver = function(d, i) {
        var sel = d3.select(this);
        if (!sel.classed("hl")) return;
        showTip.call(this, d, i);
    };

    var ribbonMouseOut = function(d, i) {
        hideTip();
    };

    var chords = chord(matrix);
    // console.log("chords", chords);

    var color = createColorScale(chords.groups.length);

    svg.on("click", function() { if (setSelectedItem(null)) highlightItem(null) });

    var top = svg.append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
        .on("mouseleave", function() {
            highlightItem(selectedItem ? selectedItem.index : null);
            hideTip();
        })
        .datum(chords);

    top.append("circle")    // this makes sure we only get mouseleave from top when mouse actually leaves area
        .attr("r", farOuterRadius)
        .attr("fill", "#fff");

    if (chords.length == 0) {
        top.append("text")
            .attr("class", "no-topics")
            .text("No co-occurrences found")
            .style("text-anchor", "middle")
    }

    var group = top.append("g")
        .attr("class", "groups")
        .selectAll("g")
        .data(function(chords) { return chords.groups; })
        .enter()
        .append("g")
        .attr("class", "group item")
        .attr("data-id", function(d) { return items[d.index].id })
        .on("mouseenter", groupMouseEnter)
        .on("mouseleave", hideTip)
        .on("contextmenu", onRightClick)
        .on("click", groupClick);

    group.append("path")
        .attr("fill", "#fff")
        .attr("d", bgArc);

    group.append("path")
        .style("fill", function(d) { return color(d.index); })
        .style("stroke", function(d) { return d3.rgb(color(d.index)).darker(); })
        .attr("d", arc);

    group.append("g")
        .each(function(d) { d.angle = (d.startAngle + d.endAngle) / 2 })
        .attr("transform", function(d) { return "rotate(" + (d.angle * 180 / Math.PI - 90) + ") translate(" + outerRadius + ",0)"; })
        .append("g")
        .each(wrapGroupLabel);

    top.append("g")
        .attr("class", "ribbons")
        .selectAll("path")
        .data(function(chords) { return chords; })
        .enter().append("path")
        .attr("class", "ribbon item")
        .attr("data-id", function(d) { return items[d.source.index].id })
        .attr("data-to-id", function(d) { return items[d.target.index].id })
        .attr("d", ribbon)
        .style("fill", ribbonColor)
        .style("stroke", function(d, i) { return d3.rgb(ribbonColor(d, i)).darker(); })
        .on("mouseover", ribbonMouseOver)
        .on("mouseout", ribbonMouseOut)
        .on("contextmenu", onRightClick);

    if (haveCategories) {

        // build the 'category labels' donut around the outside .. the chord groups are in the same order as items and
        // sorted so those with the same category are together

        var categories = [], current;
        for (i = 0; i < chords.groups.length; i++) {
            var cg = chords.groups[i];
            var c = items[cg.index].category;
            if (!current || current.category !== c) categories.push(current = { category: c, startAngle: cg.startAngle });
            current.endAngle = cg.endAngle;
        }
        // console.log("categories", categories);

        var categoryArc = d3.arc()
            .innerRadius(categoryInnerRadius)
            .outerRadius(categoryOuterRadius);

        var categoryTextArc = d3.arc()
            .innerRadius(categoryInnerRadius)
            .outerRadius(categoryInnerRadius)
            .startAngle(function(d) { return d.flip ? d.endAngle : d.startAngle })
            .endAngle(function(d) { return d.flip ? d.startAngle : d.endAngle });

        var instanceId = "" + ++beefRenderChordId;

        var categoryIdFn = function(d) { return "brc" + instanceId + "_" + d.category.id };

        var category = top.append("g")
            .attr("class", "categories")
            .selectAll("g")
            .data(categories)
            .enter()
            .append("g")
            .attr("data-id", function(d) { return d.category.id })
            .on("contextmenu", onRightClick)
            .on("mouseenter", showTip)
            .on("mouseleave", hideTip);

        category.append("path")
            .style("fill", "#f0f0f0")
            .attr("d", categoryArc);

        category.append("path")
            .attr("id", categoryIdFn)
            .each(function(d) { // figure out if text needs to be flipped so label isn't upside down
                d.angle = (d.startAngle + d.endAngle) / 2;
                d.flip = d.angle > Math.PI * 0.5 && d.angle < Math.PI * 1.5;
            })
            .attr("d", categoryTextArc);

        category.append("text")
            .attr("dy", function(d) { return d.flip ? "1.15em" : "-0.5em" })
            .append("textPath")
            .attr("class", "category")
            .attr("xlink:href", function(d) { return "#" + categoryIdFn(d) })
            .attr("startOffset", "25%")
            .text(function(d) {return d.category.label })
            .each(wrapCategoryLabel);

        /** This is an API for callers to use to interact with the diagram */
        return { setSelection: setSelectedItem }
    }

    function wrapGroupLabel(d) {
        var g = d3.select(this);
        var maxHeight = (d.endAngle - d.startAngle) * outerRadius;
        var label = items[d.index].label;
        var textAnchor = d.angle > Math.PI ? "end" : null;

        var words = label.split(/\s+/).reverse(),
            word,
            line = [],
            lineCount = 1,
            text = g.append("text").attr("x", 8).style("text-anchor", textAnchor),
            lineHeight, maxLines;
        while (word = words.pop()) {
            line.push(word);
            text.text(line.join(" "));
            if (!lineHeight) {
                lineHeight = text.node().getBBox().height;
                maxLines = maxHeight / lineHeight;
            }
            if (text.node().getComputedTextLength() > maxLabelWidth) {
                if (line.length == 1) { // only one word so have to shrink that character by character until it fits
                    while (true) {
                        word = word.slice(0, -1);
                        text.text(word + "\u2026");    // ... character
                        if (text.node().getComputedTextLength() <= maxLabelWidth || word.length == 0) break;
                    }
                    word = null;
                    line = [];
                } else {
                    line.pop();
                    text.text(line.join(" "));
                    line = [word];
                }
                if (lineCount >= maxLines) {    // add ... to previous line and make sure it still fits
                    word = text.text();
                    while (true) {
                        text.text(word + "\u2026");    // ... character
                        if (text.node().getComputedTextLength() <= maxLabelWidth || word.length == 0) break;
                        word = word.slice(0, -1);
                    }
                    break;
                }
                text = g.append("text").text(word).attr("x", 8).attr("y", lineCount + "em")
                    .style("text-anchor", textAnchor);
                ++lineCount;
            }
        }

        // center vertically
        var dx = d.angle > Math.PI ? -16 : 0;
        var dy =  (- lineCount * lineHeight) / 2 + lineHeight * 0.80;
        var tf = "translate(" + dx + "," + dy + ")";
        g.attr("transform", (d.angle > Math.PI ? "rotate(180) " : "") + tf);
    }

    function wrapCategoryLabel(d) {
        var self = d3.select(this);
        var maxWidth = (d.endAngle - d.startAngle) * categoryInnerRadius;
        var text = self.text();
        while (self.node().getComputedTextLength() > maxWidth) {
            text = text.slice(0, -1);
            if (text.length == 0) {
                self.text(null);
                break;
            }
            self.text(text + "\u2026");    // ... character
        }
    }

    function createColorScale(dataLen) {
        var colors = [];
        var cyclePoint = 14;
        if (dataLen % cyclePoint < 3) cyclePoint -= 3;
        var numColors = Math.min(dataLen, cyclePoint);
        var startOffset = 200; // largest block is blue
        var stepSize = 360 / numColors;
        var baseSaturation = 0.55;
        var baseLightness = 0.66;
        for (var i = 0; i < numColors ; i++){
            colors.push(d3.hsl(startOffset + i * stepSize, baseSaturation, baseLightness));
        }
        return d3.scaleOrdinal().range(colors);
    }

    function processTagTagRows(rows, options) {
        var lang = options.language || 'en';

        // get all the tags we care about into a map by id and make all rows reference the same tag objects
        var tagsById = { }, row, i, keep = [];
        var tagsKept = { }, tags = [];  // these are the tags referenced by a row we are keeping .. there will be one chord group for each

        var ensureTag = function(tag, keeping) {
            var existing = tagsById[tag.id];
            if (existing) {
                tag = existing;
            } else {
                tagsById[tag.id] = tag;
                tag.label =  tag.labels ? tag.labels[lang] || tag.name : tag.name;
                tag.description =  tag.descriptions ? tag.descriptions[lang] : null;
            }
            if (keeping && !tagsKept[tag.id]) {
                tagsKept[tag.id] = tag;
                tags.push(tag);
            }
            return tag;
        };

        var segmentListIds = options.segmentListIds;
        for (i = 0; i < rows.length; i++) {
            row = rows[i];
            if (!isTagIncluded(row.tag, segmentListIds) || !isTagIncluded(row.tag2, segmentListIds)) continue;
            var keeping = row.tag.leaf && row.tag2.leaf;
            row.item1 = ensureTag(row.tag, keeping);
            row.item2 = ensureTag(row.tag2, keeping);
            if (keeping) {
                keep.push({item1: row.item1, item2: row.item2,
                    mentionCount: row.mentionCount,
                    totalSentiment: row.totalSentiment,
                    sentiment: row.totalSentiment * 100 / row.mentionCount});
            }
        }

        // add a category field to each tag
        _.each(tags, function(t) {
            if (t.parent) { // the filter references a single a topic tree or view so we have parent tags
                t.category = ensureTag(t.parent, false);
            } else if (t.parents) {
                var pids = t.parents;
                for (var i = 0; i < pids.length; i++) if (t.category = tagsById[pids[i]]) break;
            }
        });

        return keep;
    }

    function removeSmallRows(rows, maxRibbons) {
        maxRibbons = parseInt(maxRibbons);
        if (maxRibbons <= 0 || rows.length <= maxRibbons) return rows;
        var i, data = [];
        for (i = 0; i < rows.length; i++) data.push(rows[i].mentionCount);
        data.sort(function(a, b) { return a - b });
        var min = data[rows.length - (maxRibbons + 1)];
        //console.log("data", data);
        //console.log("min " + min);
        if (!min || min <= 0) return rows;
        var keep = [];
        for (i = 0; i < rows.length; i++) if (rows[i].mentionCount > min) keep.push(rows[i]);
        //console.log("kept " + keep.length + " rows");
        return keep;
    }

    function filterRowsBySentiment(rows, sentiment) {
        var keep = [];
        if ('neg' === sentiment) {
            for (i = 0; i < rows.length; i++) if (rows[i].sentiment <= 0) keep.push(rows[i]);
        } else if ('pos' === sentiment) {
            for (i = 0; i < rows.length; i++) if (rows[i].sentiment >= 0) keep.push(rows[i]);
        } else {
            return rows;
        }
        return keep;
    }

    function processExtactWordExtractWordRows(rows, options) {
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            row.item1 = { id: row.extractWord, label: row.extractWord };
            row.item2 = { id: row.extractWord2, label: row.extractWord2 };
        }
        return rows;
    }

    function showTip(d, i) {
        //console.log("showTip", d);
        if (tip.datum() === d) return;
        tip.datum(d);

        var angle, html, radius;
        if (d.index !== undefined || d.category) {  // chord group or category
            var item = d.category || items[d.index];
            html = "<div class='name'>" + escapeHtml(item.label) + "</div>" +
                "<div class='description'>" + escapeHtml(item.description) + "</div>" +
                "<div class='help-inline'>(right-click for menu)</div>";
            angle = d.angle;
            radius = farOuterRadius;
        } else {        // chord (ribbon)
            var row = rowForChord(d);
            var source = d.source, target;
            if (source.index === highlightedItem.index) {
                target = d.target;
            } else {
                target = source;
                source = d.target;
            }
            angle = (source.startAngle + source.endAngle) / 2;
            radius = outerRadius;
            source = items[source.index];
            target = items[target.index];
            html =
                "<div>" + escapeHtml(source.label) + "</div>" +
                "<div>&amp; " + escapeHtml(target.label) + "</div>" +
                "<table style='background: none'><tbody>" +
                "<tr><td>" + row.mentionCount + "</td><td>Mention" + (row.mentionCount == 1 ? "" : "s") + "</td></yd></tr>";
            if (row.sentiment !== undefined) {
                html += "<tr><td class='" + (row.sentiment < 0 ? 'neg' : row.sentiment > 0 ? 'pos' : '') + "'>" +
                    Math.floor(row.sentiment) + "%</td><td>Net sentiment</td></yd></tr>";
            }
            html += "</tbody></table>";
        }
        tip.html(html);

        var w = parseInt(tip.style('width'));
        var h = parseInt(tip.style('height'));
        var x = Math.floor(Math.sin(angle) * radius + width / 2);
        var y = Math.floor(Math.cos(angle) * radius + height / 2);
        var dir = angleToCompassPoint(angle);
        var left, bottom;
        switch (dir) {
            case 'n':   left = x - w/2; bottom = y;         break;
            case 'ne':  left = x;       bottom = y;         break;
            case 'e':   left = x;       bottom = y - h/2;   break;
            case 'se':  left = x;       bottom = y;         break;
            case 's':   left = x - w/2; bottom = y - h;     break;
            case 'sw':  left = x - w;   bottom = y - h;     break;
            case 'w':   left = x - w;   bottom = y - h/2;   break;
            case 'nw':  left = x - w;   bottom = y;         break;
        }
        //console.log("x " + x + " y " + y + " " + dir + " w " + w + " h " + h + " left " + left + " bottom " + bottom);
        tip.style("left", left + "px");
        tip.style("bottom", bottom + "px");
        tip.style("display", "block");
        tip.classed("svg-tip-visible", true)
    }

    function hideTip() {
        tip.classed("svg-tip-visible", false).datum(null);
    }

    function escapeHtml(string) {
        return String(string).replace(/[&<>"'\/]/g, function (s) {
            var entityMap = { "&": "&amp;", "<": "&lt;", ">": "&gt;", '"': '&quot;', "'": '&#39;', "/": '&#x2F;'};
            return entityMap[s];
        });
    }

    function angleToCompassPoint(angle) {
        var deg = 180 * angle / Math.PI;
        if (deg <= 22.5) return 'n';
        else if (deg < 67.5) return 'ne';
        else if (deg <= 112.5) return 'e';
        else if (deg < 157.5) return 'se';
        else if (deg <= 202.5) return 's';
        else if (deg < 247.5) return 'sw';
        else if (deg <= 292.5) return 'w';
        else if (deg < 337.5) return 'nw';
        else return 'n';
    }

    function buildRibbonColorFn(rows) {
        // Build a sentiment color scale that highlights values that are far away from the mean on either side
        // (neg/pos) so that outliers stand out but the whole chart isn't dark red because its all negative.
        // Sentiment needs to be 2 standard deviations away from the mean on either side to get the darkest colour
        // value.
        var neg = [], pos = [], neutral = 0;
        for (var i = 0; i < rows.length; i++) {
            var s = rows[i].sentiment;
            if (s !== undefined) {
                if (s < 0) neg.push(s);
                else if (s > 0) pos.push(s);
                ++neutral;
            }
        }
        if (neg.length == 0 && pos.length == 0 && neutral == 0) {   // no sentiment info found
            return function(d) { return color(d.target.index) };
        }

        // make the mean for neg and pos appear in the middle of each side of the colour scale so if everything is very
        // negative we don't end up with a very dark chart etc.
        var domain = [], range = [], u, d;
        if (neg.length > 0) {
            d = d3.deviation(neg);
            if (d === undefined) {
                domain.push(d3.extent(neg)[0]);
                range.push("#f31d21");
            } else {
                u = d3.mean(neg);
                domain.push(u - d);
                range.push("#f31d21");
                domain.push(Math.min(u + d, 0));
                range.push("#ffcccd");
            }
        }
        domain.push(0);
        range.push("#f0f0f0");
        if (pos.length > 0) {
            d = d3.deviation(pos);
            if (d === undefined) {
                domain.push(d3.extent(pos)[1]);
                range.push("#20b7c4");
            } else {
                u = d3.mean(pos);
                domain.push(Math.max(u - d, 0));
                range.push("#9fe2e6");
                domain.push(u + d);
                range.push("#20b7c4");
            }
        }

        // console.log("domain " + domain);
        // console.log("range " + range);

        var sc = d3.scaleLinear().domain(domain).range(range);
        return function(d) { return sc(rowForChord(d).sentiment) }
    }

    function onRightClick(d) {
        d3.event.preventDefault();
        if (options.onRightClick) {
            var item1, item2;
            if (d.category) {
                item1 = d.category;
            } else if (d.index) {
                item1 = items[d.index];
            } else {
                item1 = items[d.source.index];
                item2 = items[d.target.index];
            }
            options.onRightClick.call(this, d, item1, item2);
        }
    }

    function isTagIncluded(tag, segmentListIds) {
        if (tag?.namespace === 'topic') {
            // do not include "no topics" topics
            return tag.name.toLowerCase().indexOf("no topics") === -1;
        }
        if (tag?.namespace !== 'segment') return false;
        // this is a very hacky way to exclude these but all we can do for now
        if (tag.name.toLowerCase().indexOf("none of the above") >= 0) return false;
        if (tag.parent && tag.parent.namespace === 'topic') return true;  // segments can be added to topic view trees
        return tag.parent && segmentListIds && segmentListIds.indexOf(tag.parent.id) >= 0;
    }
}

// this is used to generate unique ids for some elements
var beefRenderChordId = 0;

