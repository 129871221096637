var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <table class=\"table table-condensed table-bordered\">\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"found") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "    </tbody>\n    </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" class=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"mostRecentMention") : stack1)) != null ? lookupProperty(stack1,"socialNetwork") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"mostRecentMention") : stack1),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../helpers/withAuthorV4.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"withAuthorV4","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":19,"column":29}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"avatar\"><img class=\"avatar\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\"></td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n                <td class=\"handle\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"authorProfileLink") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "")
    + "                </td>\n                <td class=\"location\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"authorLocation") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":84}}})) != null ? stack1 : "")
    + "</td>\n                <td class=\"author-id\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"authorId") : depth0), depth0))
    + "</td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"authorProfileLink") : depth0), depth0))
    + "\" target=\"_blank\"><span class=\"prefix\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + "</span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"prefix\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + "</span>"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"screenName") : depth0), depth0))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"authorLocation") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"help-inline\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"help") : depth0), depth0))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"lots\" title=\"There are many more results — you can refine your search\">...</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"found") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":27,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lots") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "");
},"useData":true});