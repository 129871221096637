import Beef from "@/app/Beef";
import {isString} from "@/app/utils/StringUtils";

/**
 * Get some JSON data from Mash.
 * @deprecated Use Services.js
 */
export function deprecatedMashGet(endpoint, data, callback) {
    const mashApi = Beef?.startupOptions?.mashApi;
    const authorization = Beef?.startupOptions?.authorization;
    if (!mashApi || !authorization) {
        throw new Error("Analyse has not been correctly initialised, and this function is deprecated.")
    }

    if (endpoint.charAt(0) === '/') endpoint = endpoint.substr(1, -1);
    return $.ajax({
        dataType: "json",
        url: mashApi + endpoint,
        data: data,
        success: callback,
        headers: { Authorization: authorization }
    });
}

/**
 * PUT some JSON data to Mash and return a jqXHR instance i.e. a  promise for the response.
 * @deprecated Use Services.js
 */
export function deprecatedMashPut(endpoint, data) {
    const mashApi = Beef?.startupOptions?.mashApi;
    const authorization = Beef?.startupOptions?.authorization;
    if (!mashApi || !authorization) {
        throw new Error("Analyse has not been correctly initialised, and this function is deprecated.")
    }

    if (!isString(data)) data = JSON.stringify(data);
    return $.ajax({
        type: 'PUT',
        url: mashApi + endpoint,
        headers: { Authorization: authorization },
        contentType: "application/json",
        data: data
    });
}

