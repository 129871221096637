<template>
    <widget-settings-dialog
        :model="model"
        :dto="dto"
        @input="$emit('input', $event)"
        no-focus
        width="850px">
        <section class="commentary-box">
            <CommentaryPlusEditor
                ref="commentaryPlusEditor"
                :blocks="this.attrs.text"
                :isReadOnly="false"
                :showActionBtns="true"
                :filter="filter"
                @onEditorChange="save"></CommentaryPlusEditor>
        </section>
    </widget-settings-dialog>
</template>

<script>

import WidgetSettingsDialog from "@/dashboards/widgets/WidgetSettingsDialog.vue";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import {ParagraphBlock} from "@/dashboards/widgets/commentaryplus/blockTools/ParagraphBlock";
import RawTool from '@editorjs/raw';
import CommentaryPlusEditor from "@/dashboards/widgets/commentaryplus/CommentaryPlusEditor.vue";

export default {
    name: "CommentaryPlusSettingsDialog",
    components: {CommentaryPlusEditor, WidgetSettingsDialog},
    props: {
        model: Object
    },

    async mounted() {
        this.currentOutputData = JSON.parse(this.attrs.text);
    },

    data() {
        return {
            dto: {
                attrs: {}  // this gets set by widget-settings-dialog
            },
            currentOutputData: null
        };
    },

    methods: {
        async save(output) {
            try {
                this.dto.attrs.text = JSON.stringify(output);
                this.currentOutputData = output;
            } catch (e) {
                console.error(e);
            }
        },

    },

    computed: {
        attrs() {
            return this.dto.attrs;
        },

        filter() {
            return this.model.attributes._effectiveFilter;
        }
    },
}
</script>

<style scoped>

.commentary-box {
    max-height: 500px;
    height: 50%;
    overflow: auto;
    background-color: white;
}

</style>
