var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"chart-container\" class=\"search-job-chart\">\n\n    <div class=\"title-region\"></div>\n\n    <svg class=\"chart\" id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"chartId") || (depth0 != null ? lookupProperty(depth0,"chartId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"chartId","hash":{},"data":data,"loc":{"start":{"line":6,"column":27},"end":{"line":6,"column":38}}}) : helper)))
    + "\">\n        <foreignObject class=\"widget\" width=\"100%\" height=\"100%\">\n            <div class=\"spinner-overlay overlay\">\n                <div class=\"loading-message\" title=\"Currently fetching your data from our servers\">\n                    <div class=\"spinner48\"></div>\n                    <h3>Loading...</h3>\n                </div>\n            </div>\n        </foreignObject>\n        <foreignObject class=\"widget\" width=\"100%\" height=\"100%\">\n            <div class=\"overlay no-data-overlay\">\n                <div class=\"loading-message\">\n                    <h3>No Data</h3>\n                </div>\n            </div>\n        </foreignObject>\n        <g class=\"chart-elements\"></g>\n    </svg>\n    <div id=\"legend-region\"></div>\n    <div class=\"progress-bar\"></div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showChart") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":27,"column":7}}})) != null ? stack1 : "");
},"useData":true});