import axios from 'axios'
import {showErrorDialog, showInfoDialog} from "@/app/framework/dialogs/Dialog";
import _ from 'underscore';
import {setTitle} from "@/app/Beef";
import {currentAccountCode} from "@/app/utils/Account";

Beef.module("FeedsSetup").addInitializer(function(startupOptions) {

    /**
     * Display/edit feeds.
     */
    this.View = Backbone.Marionette.Layout.extend({
        template: require("@/setup/feeds/FeedsSetup.handlebars"),

        attributes: { class: "feeds-setup" },
        regions: {
            "listRegion": ".feed-list"
        },

        events: {
            "click button.add": "newFeed",
            "click a.delete": "deleteFeed",
            "click p a": "linkClicked"
        },

        initialize: function() {
            this.model.set('title', 'Custom Data Sources');
            setTitle('Custom Data Sources');

            this.list = new List({accountCode: this.model.get('accountCode')});

            setTimeout(function() {
                if (this.listRegion && !this.listRegion.currentView && !this.$('.feed-error').is(':visible')) {
                    var $loading = this.$('.feed-loading');
                    $loading.show();
                    $loading.toggleClass('animated fadeIn', true);
                }
            }.bind(this), 1000);

            this.list.fetch({
                success: function() {
                    if (this.listRegion) {
                        var $loading = this.$('.feed-loading');
                        $loading.hide();
                        this.listView = new ListView({collection: this.list});
                        this.listRegion.show(this.listView);
                        this.$('.feed-list').toggleClass('short-animated fadeIn', true);
                    }
                }.bind(this),
                error: function() {
                    if (this.listRegion) {
                        var $loading = this.$('.feed-loading'),
                            $error   = this.$('.feed-error');
                        $loading.hide();
                        $error.show();
                        $error.toggleClass('animated fadeIn', true);
                    }
                }
            });
        },

        onRender: function() { },

        newFeed: function(ev) {
            ev.preventDefault();
            var popup = new Beef.Popup.View({
                closeOnHide: true,
                alwaysMove: true
            });
            popup.setTarget($(ev.target));
            var view = new EditView({model: new Feed({accountCode: this.model.get('accountCode')}), list: this.list});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        deleteFeed: function(ev) {
            ev.preventDefault();
            var feed = new Feed({id: $(ev.target).attr('id'), accountCode: this.model.get('accountCode')});
            this.list.remove(feed);
            feed.destroy();
        },

        linkClicked: function(ev) {
            ev.preventDefault();
            var href = $(ev.target).attr('href');
            if (href && href.charAt(0) == '/') {
                $(".sidebar a[href='" + href + "']").click();
            }
        }
    });

    var EditView = Beef.SettingsDialog.View.extend({
        template: require("@/setup/feeds/ItemEdit.handlebars"),

        attributes: { class: "dialog feed-dialog" },

        editAttributes: ['name', 'link', 'includeContentHashInURI', 'includeFeedNameInContent',
            'sendMentionsToAllAccounts', 'location'],

        modelEvents: {
            "change:link": "onLinkChange"
        },

        initialize: function(options) {
            this.model.accountCode = this.model.get('accountCode');
            this.model.set('accountCode', this.model.get('accountCode'));
            this.list = options.list;
            Beef.SettingsDialog.View.prototype.initialize.call(this);
        },

        bindings: function() {
            return {
                location: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            Beef.LocationPicker.attach(this, ".location", "location", {onlyOne: true, noGroups: true});
            if (this.originalModel.isNew()) { this.$el.find('.delete').hide(); }
        },

        ok: function() {
            if (this.isValid()) {
                var attrs = this.model.getAttrs(this.resolveEditAttributes());
                this.originalModel.set(attrs);
                this.originalModel.save(this.list);
                this.close(true);
            }
        },

        onLinkChange: function() {
            var prevHost, host;
            try {
                prevHost = new URL(this.model.previous('link')).hostname;
            } catch (ignore) {
            }
            try {
                host = new URL(this.model.get('link')).hostname;
            } catch (ignore) {
                return
            }
            var name = this.model.get('name');
            if (!name || name == prevHost) this.model.set('name', host);
        }
    });

    /**
     * Item view
     */
    var ItemView = Backbone.Marionette.ItemView.extend({
        tagName: "tr",

        template: require("@/setup/feeds/ItemView.handlebars"),

        events: {
            "click .edit-feed-icon": "editFeed",
            "click .repoll": "repollFeed"
        },

        modelEvents: {
            "change": "render"
        },

        editFeed: function(ev) {
            ev.preventDefault();
            var popup = new Beef.Popup.View({
                closeOnHide: true,
                positions:["bottom-left", "center"],
                alwaysMove: true
            });
            popup.setTarget($(ev.target));
            var view = new EditView({model: this.model});
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        repollFeed: function(ev) {
            ev.preventDefault();
            axios.post('/api/feeds/repoll?accountCode=' + currentAccountCode() + "&id=" + this.model.id)
                .then(() => {
                    // noinspection JSIgnoredPromiseFromCall
                    showInfoDialog("Feed will be re-polled shortly")
                })
                .catch(e => {
                    console.error(e)
                    showErrorDialog("Re-poll failed: " + e)
                })
        }
    });

    /**
     * List View
     */
    var ListView = Backbone.Marionette.CompositeView.extend({
        itemView: ItemView,

        template: require("@/setup/feeds/ListView.handlebars"),

        appendHtml: function(collectionView, itemView, index) {
            collectionView.$(".body").append(itemView.el);
        }
    });

    /**
     * Feed model
     */
    var Feed = Backbone.Model.extend({
        defaults: {
            active: true
        },

        validation: {
            name: { required: true },
            link: { required: true }
        },

        initialize: function(options) {
            this.accountCode = this.getAncestorProperty('accountCode');
            if (this.accountCode == null) this.accountCode = options.accountCode;
            this.url = Beef.Sync.toMashUrl('accounts/' + this.accountCode + '/feeds/' + this.id);
        },

        save: function(collection) {
            if (this.isNew()) {
                this.url = Beef.Sync.toMashUrl('accounts/' + this.accountCode + '/feeds');
                Backbone.Model.prototype.save.call(this, {}, {success:function(model, response) {
                    collection.add(this);
                    this.url = Beef.Sync.toMashUrl('accounts/' + this.accountCode + '/feeds/' + this.id);
                }.bind(this)});
            } else {
                Backbone.Model.prototype.save.call(this);
            }
        },

        parse: function(response) {
            if (!_(response.macro).isUndefined()) {
                response.attributes = response.macro.attributes;
            }
            return response
        }
    });

    /**
     * Feed collection
     */
    var List = Backbone.Collection.extend({
        model: Feed,

        initialize: function(options) {
            this.accountCode = options.accountCode;
            this.url = Beef.Sync.toMashUrl('accounts/' + this.accountCode + '/feeds?includeStatus=true');
        }
    });


});

