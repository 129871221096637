import * as b3js from 'brandseyejs';
import {appendSegmentRestrictions, getSegmentList} from "@/app/utils/Segments";
import {
    calculateBarPercentages,
    calculateMoE,
    formatCurrency,
    formatDate,
    formatEnglishDate,
    formatNumber as beefFormatNumber,
    formatPercentage,
    formatSentimentShort,
    getCompareFilterGetter,
    getCompareGetter,
    getDefaultCompareSortField,
    getDefaultXSortField,
    getDescriptionGetter,
    getEffectiveFilter,
    getTopicView,
    initialiseRow,
    isOther,
    sortAndReduceDataToMaxItems,
    tagAndTopicDescriptionGetterFactory,
    tagAndTopicExtender,
    tagAndTopicFilterGetterFactory,
    tagAndTopicSetterFactory
} from "./FantasticUtilities";

import {pick} from "underscore";

import {countPopulation, createGroupExcluding, createSelectExcluding} from "./FantasticDataHelpers";
import AxisOptions from "./AxisOptions";
import FantasticChartSettingsOptions from "./FantasticChartSettingsOptions";

import FantasticChartSettingsDialog from "./FantasticChartSettingsDialog";
import FCPointSettingsDialog from "./FCPointSettingsDialog";

import {codeToColour} from "@/app/utils/Metatags";
import {grouseGet} from "@/data/Grouse";
import {notifyUser, notifyWithHtml, notifyWithText} from "@/app/framework/notifications/Notifications";
import {
    appendFiltersReadably,
    getBrandsInFilter,
    getSegmentsInFilter,
    getTagsInFilter,
    parseFilterString,
    removeNodes
} from "@/dashboards/filter/FilterParser";
import {buildBasicFilter, convertFilterToAttrs, extractDays} from "@/dashboards/filter/BasicFilter";
import {deprecatedFetchTags} from "@/data/DeprecatedBeefCache";
import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {getPalette} from "@/app/utils/Colours";
import VuexStore from "@/store/vuex/VuexStore";
import {capitalise, encloseInDisplayQuotes, escapeExpression, escapeHtml, isUnknown} from "@/app/utils/StringUtils";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import {editMentions, isMashAdmin} from "@/app/Permissions";
import {FANTASTIC_FIELDS} from "@/dashboards/widgets/fantasticchart/fields/Fields";
import {showTagMentionDialog} from "@/app/framework/dialogs/Dialog";
import {features} from "@/app/Features";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {account, currentAccountCode, isDevEnvironment} from "@/app/utils/Account";
import {getBrand} from "@/app/utils/Brands";
import moment from "moment";
import {createEqualityStatement, getLexerTokenForFantasticField} from "@/dashboards/filter/Generator";
import {responseTimeCalculationFootnoteWarning, showResponseTimeFootnote} from "@/app/utils/Interactions";
import {summariseFilter} from "@/app/utils/turducken";

/**
 * A general chart to replace many of our other charts with.
 */
Beef.module("Widget.FantasticChart").addInitializer(function (startupOptions) {

    this.cache = {};

    const fcOptions = new FantasticChartSettingsOptions();

    const initializeShow = function (show) {
        show.forEach(s => {
            if (s.barOutline === undefined) s.barOutline = 0;
            if (s.barPadding === undefined) s.barPadding = 20;
            if (s.labelPlacement === undefined) s.labelPlacement = s.useOutsideLabels ? 'outside' : 'inside';
            delete s.useOutsideLabels; // replaced with labelPlacement
        });
    };

    this.type = {
        name: "General Chart",
        settingsTitle: "Edit the metric's settings",
        description: "Choose what data you would like to plot",
        tooltip: "Choose the data you would like to plot, and how you would like to plot it.",
        group: "other",
        width: 8,
        height: 4,
        vueSettingsDialog: FantasticChartSettingsDialog,

        onAdd: function (data, widgetDesc) {
            data.xAxis = widgetDesc.xAxis || "published";
            data.yAxis = widgetDesc.yAxis || "mentionPercent";
            data.compare = widgetDesc.compare;
            data.geometry = widgetDesc.geometry;
            data["hideFuture"] = true;
            data['onlySupportHandles'] = true;

            // For segments, we want to try to show all the segment items if possible. Some have more than 8 items
            // (which is the default max items to show).
            if (data.xAxis.includes("segment")) data['maxItems'] = 10;
            if (data.compare && data.compare.includes("segment")) data['maxItems'] = 10;
        },

        onReplace: function (model, widgetDesc, oldType) {
            const show = widgetDesc.show;
            if (show?.length) {
                // initialise some defaults
                initializeShow(show);
            }
            model.set({
                type: widgetDesc.type,
                xAxis: widgetDesc.xAxis,
                yAxis: widgetDesc.yAxis,
                compare: widgetDesc.compare,
                geometry: widgetDesc.geometry,
                width: model.get('width') || oldType.width || 2,     // fall back to default widget sizes.
                height: model.get('height') || oldType.height || 2,
                show: widgetDesc.show,
            });
        }
    };

    function replaceKey(attrs, old, nw) {
        let v = attrs[old];
        if (v !== undefined) {
            attrs[nw] = v;
            delete attrs[old];
        }
    }

    this.View = Beef.BoundItemView.extend({

        attributes: {
            'class': 'widget-height-inner fantastic-chart' /* scroll-auto */
        },

        modelEvents: {
            "change": "maybeRefresh",
            "change:language": "refresh",
            "change:xAxis": "updateCaption",
            "change:compare": "updateCaption",
            "change:caption": "captionChanged",
            "change:comment": "render",
            "change:commentFontSize": "render",
            "change:commentWidth": "render",
            "click:bar": "dataSelected",
            "middle-click:bar": "dataSelectedTab",
            "right-click:bar": "showRightClickMenu"
        },

        chart: null,

        coarsenessMap: {
            "hourly": "hour",
            "daily": "day",
            "weekly": "week",
            "monthly": "month",
            "yearly": "year"
        },

        initialize: function () {
            this.listenTo(this.model, "change:_effectiveFilter", this.refreshIfFilterChanged, this);
            this.accountCode = this.model.getAncestorProperty('accountCode');

            this.chart = new b3js.chart();

            if (!this.model.get("geometry")) {
                this.model.set("geometry", "columns");
            }

            let attrs = this.model.attributes;
            replaceKey(attrs, 'hide-labels', 'hideLabels');
            replaceKey(attrs, 'use-outside-labels', 'useOutsideLabels');
            replaceKey(attrs, 'is-donut', 'isDonut');
            replaceKey(attrs, 'line-width', 'lineWidth');
            replaceKey(attrs, 'line-curve', 'lineCurve');
            replaceKey(attrs, 'only-support-handles', 'onlySupportHandles');
            replaceKey(attrs, 'hide-future', 'hideFuture');
            replaceKey(attrs, 'hide-legend', 'hideLegend');
            replaceKey(attrs, 'hide-other', 'hideOther');
            replaceKey(attrs, 'hide-unknown', 'hideUnknown');
            replaceKey(attrs, 'no-si', 'noSi');
            replaceKey(attrs, 'show-cooccurrence', 'showCooccurrence');
            replaceKey(attrs, 'hide-parents', 'hideParents');
            replaceKey(attrs, 'hide-children', 'hideChildren');
            replaceKey(attrs, 'font-size', 'fontSize');
            replaceKey(attrs, 'hide-missing', 'hideMissing');
            replaceKey(attrs, 'max-comparisons', 'maxComparisons');
            replaceKey(attrs, 'max-items', 'maxItems');

            if (!attrs.xAxis) attrs.xAxis = "published";
            if (!attrs.yAxis) attrs.yAxis = "mentionCount";
            if (!attrs.geometry) attrs.geometry = "columns";
            if (!attrs.coarseness) attrs.coarseness = "daily";
            if (attrs['hidden-title'] === undefined) attrs['hidden-title'] = false;
            if (attrs.hideLegend === undefined) attrs.hideLegend = false;
            if (attrs.hideOther === undefined) attrs.hideOther = false;
            if (attrs.hideUnknown === undefined) attrs.hideUnknown = false;
            if (attrs.lineWidth === undefined) attrs.lineWidth = 2;
            if (!attrs.lineCurve) attrs.lineCurve = 'smooth';
            if (attrs.hideLabels === undefined) attrs.hideLabels = false;
            if (attrs.noSi === undefined) attrs.noSi = true;
            if (attrs.hideFuture === undefined) attrs.hideFuture = false;
            if (attrs.showCooccurrence === undefined) attrs.showCooccurrence = false;
            if (attrs.hideParents === undefined) attrs.hideParents = true;
            if (attrs.hideChildren === undefined) attrs.hideChildren = false;
            attrs.numberFormat = attrs.noSi ? 'space' : 'si';
            if (attrs.fontSize === undefined) attrs.fontSize = 12;
            if (attrs.commentFontSize === undefined) attrs.commentFontSize = 14;
            if (attrs.commentWidth === undefined) attrs.commentWidth = null;
            else if (attrs.commentWidth > 20) attrs.commentWidth = attrs.commentWidth / 104; // pixels to grid blocks
            if (attrs.dateFormat === undefined) attrs.dateFormat = 'auto';
            if (attrs.dateTicks === undefined) attrs.dateTicks = 'auto';
            if (attrs.labelAngle === undefined) attrs.labelAngle = 'auto';
            if (attrs.xGridLines === undefined) attrs.xGridLines = true;
            if (attrs.yGridLines === undefined) attrs.yGridLines = true;
            if (attrs.useOutsideLabels === undefined) attrs.useOutsideLabels = false;
            if (attrs.isDonut === undefined) attrs.isDonut = false;
            if (attrs.axisLabel === undefined) attrs.axisLabel = '';
            if (attrs.showAxisLabel === undefined) attrs.showAxisLabel = true;
            if (attrs.axisMaxValue === undefined) attrs.axisMaxValue = null;
            if (attrs.axisMinValue === undefined) attrs.axisMinValue = null;
            if (attrs.hideAxisMin === undefined) attrs.hideAxisMin = false;
            if (attrs.axis2Label === undefined) attrs.axis2Label = '';
            if (attrs.showAxis2Label === undefined) attrs.showAxis2Label = true;
            if (attrs.axis2MaxValue === undefined) attrs.axis2MaxValue = null;
            if (attrs.axis2MinValue === undefined) attrs.axis2MinValue = null;
            if (attrs.hideAxis2Min === undefined) attrs.hideAxis2Min = false;
            if (!attrs.maxComparisons) attrs.maxComparisons = 8;
            if (!attrs.maxItems) attrs.maxItems = 8;
            if (attrs.dataSets === undefined) attrs.dataSets = null;
            if (attrs.stacked === undefined) attrs.stacked = false;
            if (attrs.xSortByField === undefined) attrs.xSortByField = 'default';
            if (attrs.compareBucketSortByField === undefined) attrs.compareBucketSortByField = 'default';
            if (attrs.durationFormat === undefined) attrs.durationFormat = 'default';
            if (attrs.durationHoursThreshold === undefined) attrs.durationHoursThreshold = 3;

            if (!attrs.show) {
                let o = {opacity: 100};
                let move = ['yAxis', 'geometry', 'barOutline', 'barPadding', 'hideLabels', 'useOutsideLabels',
                    'isDonut', 'lineWidth', 'lineCurve', 'onlySupportHandles', 'labelPlacement',
                    'colour-palette', 'colour-palette-custom', 'colour-index'];
                move.forEach(k => {
                    o[k] = attrs[k];
                    delete attrs[k];
                });
                attrs.show = [o];
            }

            if (attrs.xSortOrder === undefined) {
                let yAxis = attrs?.show[0]?.yAxis;
                let xAxis = attrs.xAxis;

                let defaultSortField = getDefaultXSortField(xAxis, yAxis);
                attrs.xSortOrder = defaultSortField?.defaultSortOptions?.order ?? "descending";
            }

            if (attrs.compareBucketSortOrder === undefined) {
                let yAxis = attrs?.show[0]?.yAxis;
                let compare = attrs.compare;

                let defaultSortField = getDefaultCompareSortField(yAxis, compare);
                attrs.compareBucketSortOrder = defaultSortField?.defaultSortOptions?.order ?? "descending";
            }

            // Setting defaults for the geometry we show.
            initializeShow(attrs.show);
            this.updateCaption();
            this.refresh();
        },


        onClose: function () {

        },

        render: function (delay) {
            Beef.Footnotes.clearFootnotes(this);
            var c = this.model.changed;
            // Only a rerender is needed in this section. No new data needs to be fetched.
            if (delay || c['height']) {
                // Need to delay for the height change to have the widget's correct height.
                this.$el.css({"opacity": 0});

                setTimeout(function () {
                    this.renderImpl();
                    this.$el.css({"opacity": 1});
                }.bind(this), 300);
            } else return this.renderImpl();
        },

        renderImpl: async function () {
            if (!this._data) return;

            this._times = this._times || 0;
            this._times++;
            this.log("renderImpl x" + this._times);

            // Need to remove hard width and height values so that this can be resized.
            this.$('svg').css("width", "100%");
            this.$('svg').css("height", "100%");

            if (!this.$('.fantastic-container').length) {
                // This may be removed by other methods.
                this.$el.html('<div class="fantastic-container"><div class="chart-holder"></div></div>');
            }

            var footnotes = [];
            if (this._data_footnotes) this._data_footnotes.forEach(function (f) {
                footnotes.push(f);
            });

            try {
                let show = this.model.get('show');
                let first = show[0];

                let data = this.maxItemsData();
                let xAxis = this.model.get("xAxis");
                let yAxis = first.yAxis;
                let compare = this.model.get("compare");
                let size = this.model.get("size");


                let fields = FANTASTIC_FIELDS;
                let yField = fields[yAxis] || {};
                let yAxisGetter = yField.getter || function (d) {
                    return d[yAxis];
                };
                let yAxisGetter2 = yField.getter2;
                let compareField = fields[compare] || {};
                let compareGetter = getCompareGetter(compare);

                let xField = (fields[xAxis] && fields[xAxis]) || {};
                let xAxisGetter = xField.getter || function (d) {
                    return d[xAxis];
                };
                let formatX = xField.formatX || function (d) {
                    return d;
                };

                if (!data.length && data.extra?.itemsWereHidden) { // Only show a message if we have hidden something
                    this.model.generalData.set('_message', "All data has been hidden" +
                        "<p class='info'>" +
                        "You can reveal hidden data in this metric's settings dialog" +
                        "</p>");
                    return;
                }

                if ((xAxis === "race" || yAxis === "race" || compare === "race")) {
                    this.model.generalData.set('_message', "We no longer support ethnicity filtering." +
                        "<p class='info'>" +
                        "For more information, please email <a href='mailto:support@dataeq.com'>support@dataeq.com</a> " +
                        "or contact your client service representative." +
                        "</p>");
                    this.clearChart();
                    return;
                }

                if (xAxis === "totalOnlineAVE" || yAxis === "totalOnlineAVE" || compare === "totalOnlineAVE" ||
                    xAxis === "totalAVE" || yAxis === "totalAVE" || compare === "totalAVE") {
                    if (!VuexStore.state.account.showAVE) {
                        this.model.generalData.set('_message', "Your account does not support Advert Value Equivalent" +
                            "<p class='info'>" +
                            "For more information, please email <a href='mailto:support@dataeq.com'>support@dataeq.com</a> " +
                            "or contact your client service representative." +
                            "</p>");
                        this.clearChart();
                        return;
                    }
                }

                var hideAll = (!this.model.has('hideParents') || this.model.get('hideParents')) && this.model.get("hideChildren");
                if ((xAxis === "topic" || compare === "topic") && hideAll) {
                    this.model.generalData.set('_message', "Both parent and child topics have been removed" +
                        "<p class='info'>" +
                        "There is no data to display because of this. Please choose to display either parents or topics in " +
                        "the metric settings." +
                        "</p>");
                    this.clearChart();
                    return;
                }

                if (!data || !data.length) {
                    let msg;
                    if ((xAxis === "topic" || compare === "topic") && !getTopicView(this.model)) {
                        msg = "Multiple or no topic trees present, please select a topic view in settings";
                    } else {
                        msg = "No mentions match your filter";
                    }
                    this.model.generalData.set('_message', msg);
                    this.clearChart();
                    return;
                } else {
                    this.model.generalData.unset('_message');
                }

                // We don't want to compare things with themselves,
                // except for values that support that, such as tags and topics.
                if (compare === xAxis && compare !== "topic" && compare !== "tag") compare = null;

                let comparingBrands = compare === "brand" || xAxis === "brand";
                if (comparingBrands) {
                    var brands = getBrandsInFilter(this._filter).include;
                    if (brands.length <= 1) footnotes.push("Select more brands to compare them");
                }

                for (const curShow of show) {
                    if (showResponseTimeFootnote(curShow.yAxis, this._filter)) {
                        footnotes.push(responseTimeCalculationFootnoteWarning);
                        break;
                    }
                }

                // Give two values, x and y, this will return x if the geometry
                // does not call for rotating the x and y data sets. Otherwise,
                // such as for row geometry, it returns y.
                let firstGeometry = first.geometry;
                let rotate = function (x, y) {
                    if (firstGeometry === "rows") return y;
                    return x;
                };

                var othersCouldBeHidden = !compareField.otherNotSupported
                    && (!rotate(xField.hideOthers, yField.hideOthers) || (compare && !compareField.hideOthers));
                var unknownCouldBeHidden = !rotate(xField.hideUnknown, yField.hideUnknown) || (compare && !compareField.hideUnknown);
                if (othersCouldBeHidden && this.model.get("hideOther")) footnotes.push(encloseInDisplayQuotes("Other") + " values have been hidden");
                if (unknownCouldBeHidden && this.model.get("hideUnknown")) footnotes.push(encloseInDisplayQuotes("Unknown") + " values have been hidden");

                if (first.geometry === 'pie' && data.some(d => yAxisGetter(d) === 0)) {
                    footnotes.push("Zero-sized segments have been excluded"); // exclusion happens in brandseyejs
                }

                if (footnotes.length) Beef.Footnotes.setFootnotes(this, footnotes);

                // Get after setting footnotes.
                let $chartHolder = this.$('.chart-holder');
                var width = $chartHolder.width();
                var height = $chartHolder.height();

                let y2Field, y2AxisGetter, y2AxisGetter2;

                let geometryField = Beef.Widget.FantasticChartGeometry.geometry[firstGeometry];
                var sizeField = (size && geometryField.size && fields[size]) || {};
                var sizeGetter = sizeField.getter || function (d) {
                    return d[size];
                };

                var xGeometries = show.map((s, i) => {
                    let n, g, scaleType, j, o;
                    switch (s.geometry) {
                        case "lines":
                            g = b3js.line();
                            if ((n = parseInt(s.lineWidth)) >= 0) g.strokeWidth(n);
                            switch (s.lineCurve) {
                                case 'smooth':
                                    g.curve('curveCatmullRom');
                                    break;
                                case 'jagged':
                                    g.curve('curveLinear');
                                    break;
                            }
                            // use padding from bars if we are on top of any so lines match the bars exactly
                            if ((n = parseFloat(show[0].barPadding)) >= 0) g.padding(n / 100);
                            if (i > 0) { // see if we need to use the 2nd y-axis
                                scaleType = fcOptions.yAxis[s.yAxis].scaleType;
                                if (scaleType !== fcOptions.yAxis[show[0].yAxis].scaleType) {
                                    g.useY2Axis(true);
                                    y2Field = fields[s.yAxis] || {};
                                    y2AxisGetter = y2Field.getter || (d => d[s.yAxis]);
                                    y2AxisGetter2 = y2Field.getter2;
                                    g.formatY((one, two) => y2Field.formatY(one, two, this.model));
                                }
                            }
                            g.y2(yAxisGetter2);
                            break;
                        case "rows":
                            g = b3js.barChart();
                            if ((n = parseInt(s.barOutline)) >= 0) g.strokeWidth(n);
                            if ((n = parseFloat(s.barPadding)) >= 0) g.padding(n / 100);
                            g.x2(yAxisGetter2);
                            g.stacked(this.model.get('stacked'));
                            break;
                        case "points":
                            g = b3js.points();
                            break;
                        case "pie":
                            g = b3js.pie();
                            g.labelPlacement(s.labelPlacement);
                            g.isDonut(s.isDonut);
                            break;
                        default:
                            g = xField.isDate ? b3js.histogram() : b3js.columnChart();
                            if ((n = parseInt(s.barOutline)) >= 0) g.strokeWidth(n);
                            if ((n = parseFloat(s.barPadding)) >= 0) g.padding(n / 100);
                            g.y2(yAxisGetter2);
                            g.stacked(this.model.get('stacked'));
                    }

                    if (s['colour-palette'] !== 'custom' && !compare) {
                        let yf = fields[s.yAxis] || {};
                        if (yf.gradientFn && !comparingBrands) g.gradientFn(yf.gradientFn);
                        else if (yf.colourFromY) g.individualColours(yf.colourFromY);
                    }

                    g.colourScale(getPalette(s, this.model.getSectionModel() && this.model.getDashboardModel().attributes));
                    if (s.opacity !== null && s.opacity !== undefined) g.opacity(s.opacity / 100);

                    if (g.useY2Axis()) {
                        g.axisMinValue(this.model.get('axis2MinValue'));
                        g.axisMaxValue(this.model.get('axis2MaxValue'));
                    } else {
                        g.axisMinValue(this.model.get('axisMinValue'));
                        g.axisMaxValue(this.model.get('axisMaxValue'));
                    }

                    let yf = fields[s.yAxis] || {};
                    if (s.geometry !== "rows") {
                        g.setupY(yf.getter || function (d) {
                            return d[yAxis];
                        });
                        if (!compare) g.colour(d => yf.name || s.yAxis);
                    }

                    if (!compare && i === 0) {
                        if (yf.colourFromY) g.individualColours(yField.colourFromY);
                        if (sizeField.colourFromY) g.individualColours(sizeField.colourFromY);
                        if (xField.colourFromX) g.individualColours((one, two) => xField.colourFromX(one, two, this.model));
                    }

                    return g;
                });

                if (xGeometries.length > 1) {
                    let setYMax = geoms => {
                        if (!geoms.length) return;
                        let max = 0;     // make sure all geometries are using the same scale for y axis
                        geoms.forEach(g => data.forEach(d => {
                            max = Math.max(max, g.y()(d));
                            if (g.y2()) max = Math.max(max, g.y2()(d));
                        }));
                        if (max) geoms.forEach(g => g.axisMaxValue(max));
                    };
                    let setYMin = geoms => {
                        if (!geoms.length) return;
                        let min = 0;     // make sure all geometries are using the same scale for y axis
                        geoms.forEach(g => data.forEach(d => {
                            min = Math.min(min, g.y()(d));
                            if (g.y2()) min = Math.min(min, g.y2()(d));
                        }));
                        if (min) geoms.forEach(g => g.axisMinValue(min));
                    };

                    if (!this.model.get('axisMinValue')) setYMin(xGeometries.filter(g => !g.useY2Axis()));
                    if (!this.model.get('axisMaxValue')) setYMax(xGeometries.filter(g => !g.useY2Axis()));
                    if (!this.model.get('axis2MinValue')) setYMin(xGeometries.filter(g => g.useY2Axis()));
                    if (!this.model.get('axis2MaxValue')) setYMax(xGeometries.filter(g => g.useY2Axis()));
                }

                // Find a non-null examplar data point to determine what kind of x-axis scale we want.
                let xScaleExamples = data.map(xAxisGetter).filter(d => d !== null && d !== undefined).slice(0, 5);
                let yScaleExamples = data.map(yAxisGetter).filter(d => d !== null && d !== undefined).slice(0, 5);

                let scaleX = xField.scaleX ? xField.scaleX() : b3js.chooseScale(xScaleExamples);
                let scaleY = yField.scaleY ? yField.scaleY() : b3js.chooseScale(yScaleExamples);

                let scaleY2;
                if (y2AxisGetter && y2Field) {
                    let examples = data.map(y2AxisGetter).filter(d => d !== null && d !== undefined).slice(0, 5);
                    scaleY2 = y2Field.scaleY ? y2Field.scaleY() : b3js.chooseScale(examples);
                }

                var importance = (one, two) => (rotate(xField.importance, yField.importance) || (function () {
                    return false;
                }))(one, two, this.model);

                let yGridLines = this.model.get('yGridLines');

                let geometrySettings = this.model.attributes.show;
                let colourFn = (d, geometryIndex) => {
                    let s = geometrySettings[geometryIndex ?? 0]?.pointSettings?.[d.id];
                    if (s?.['colour-index'] !== undefined) {
                        return getPalette(s, geometrySettings[geometryIndex ?? 0])[0];
                    }
                    return d._extraColourFn ? d._extraColourFn(d, geometryIndex ?? 0) : null;
                };

                data.forEach(d => d._colourFn = colourFn);

                let chart = this.chart
                    .reset()
                    .data(data)
                    .element($chartHolder[0])
                    .width(width)
                    .height(height)
                    .x(rotate(xAxisGetter, yAxisGetter))
                    .y(rotate(yAxisGetter, xAxisGetter))
                    .scaleX(rotate(scaleX, scaleY))
                    .scaleY(rotate(scaleY, scaleX))
                    .formatX((one, two) => (rotate(formatX, yField.formatY || this.chart.formatY()))(one, two, this.model))
                    .formatY((one, two) => (rotate(yField.formatY || this.chart.formatY(), formatX))(one, two, this.model))
                    .importanceX(importance)
                    .colourScale(null)
                    .showLegend(!this.model.get("hideLegend"))
                    .showLabels(!first.hideLabels)
                    .xGridLines(this.model.get('xGridLines') && firstGeometry !== "columns")
                    .yGridLines(yGridLines && firstGeometry !== "rows")
                    .axisBox(firstGeometry !== "columns" && firstGeometry !== "rows")
                    .xLabelAngle(AxisOptions.labelAngles[this.model.get('labelAngle') || 'auto'].value)
                    .noAnimation(Beef.Widget.isDisableAnimation());

                if (xAxis === 'dataSet') chart.xLabelNotrim(true);

                xGeometries.forEach(g => {
                    chart.geometry(g);
                    if (g.useY2Axis()) g.scaleY(scaleY2);
                });

                if (this.model.get('showAxisLabel')) {
                    let label = this.model.get('axisLabel');
                    if (!label) {
                        let a = show.filter((s, i) => !xGeometries[i].useY2Axis());
                        label = a.map(s => {
                            let o = (fields[s.yAxis] || {}).yLabel;
                            return !o ? "No y axis label allocated" : o[a.length > 1 ? 'short' : 'long'] || o;
                        }).join(" / ");
                    }
                    chart.xAxisLabel(rotate(null, label)).yAxisLabel(rotate(label, null));
                }

                if (this.model.get('hideAxisMin')) {
                    chart.hideXAxisMin(rotate(null, true)).hideYAxisMin(rotate(true, null));
                }

                if (y2Field) {
                    if (this.model.get('showAxis2Label')) {
                        let label = this.model.get('axis2Label');
                        if (!label) {
                            let a = show.filter((s, i) => xGeometries[i].useY2Axis());
                            label = a.map(s => {
                                let o = (fields[s.yAxis] || {}).yLabel;
                                return o[a.length > 1 ? 'short' : 'long'] || o;
                            }).join(" / ");
                        }
                        chart.y2AxisLabel(label);
                    }
                    chart.hideY2AxisMin(this.model.get('hideAxis2Min'));
                }

                let fontSize = this.model.get('fontSize');
                if (fontSize) chart.fontSize(fontSize);

                // For now, we set this explicitly on pie geom rather than saving on the model.
                // Saving on the model might cause unexpected behaviour in the future
                // if the ability to turn axes off is added to other charts.
                if (firstGeometry === 'pie') {
                    chart.showXAxis(false);
                    chart.showYAxis(false);
                }

                let dateTicks = AxisOptions.dateTicks[this.model.get('dateTicks') || "auto"];
                if (dateTicks.fn && firstGeometry !== 'pie' && firstGeometry !== 'rows') {
                    if (xField.isDate) chart.xTickValuesFn(scale => dateTicks.fn.call(this, scale));
                    if (yField.isDate) chart.yTickValuesFn(scale => dateTicks.fn.call(this, scale));
                }

                var formatLabel = yField.formatLabel || yField.formatY || chart.formatY();
                if (formatLabel) chart.formatLabel((one, two) => formatLabel(one, two, this.model));

                if (!compare && show.length <= 1) {
                    if (yField.colourFromY) chart.individualColours(yField.colourFromY);
                    if (sizeField.colourFromY) chart.individualColours(sizeField.colourFromY);
                    if (xField.colourFromX) chart.individualColours((one, two) => xField.colourFromX(one, two, this.model));
                }

                if (compareField.colourFromX) chart.individualColours((one, two) => compareField.colourFromX(one, two, this.model));
                if (compareField.legendColours) {
                    let lc = compareField.legendColours;
                    if (typeof compareField.legendColours === "function") lc = compareField.legendColours(this.model);
                    chart.legendColours(d => lc[d]);
                }

                if (compare) {
                    var compareFormat = function (d) {
                        return d;
                    };
                    if (fields[compare] && fields[compare].formatX) compareFormat = fields[compare].formatX;

                    var comparator = function (d) {
                        return compareFormat(compareGetter(d));
                    };

                    if (this.model.get("facet")) chart.facetX(comparator);
                    else chart.colour(comparator);
                }

                if (size) chart.size(sizeGetter);

                this.setupEvents();
                try {
                    chart.render();
                } catch (e) {
                    // This sometimes happens on Firefox when rendering dashboards for image download. Supposedly
                    // it is caused by calling getBBox() when the svg has display none but forcing our SVGs to display
                    // none failed to reproduce the problem.
                    // https://stackoverflow.com/questions/45184101/error-ns-error-failure-in-firefox-while-use-getbbox
                    if ("NS_ERROR_FAILURE" === e.name) {
                        console.warn("got NS_ERROR_FAILURE, retrying", e);
                        setTimeout(() => chart.render(), 1);
                    } else {
                        throw e;
                    }
                }

            } catch (e) {
                errorHelper(this.model, e);
                this.clearChart();
            }
        },

        log: function (msg) {
            // this is to help debug "image snapshot taking before rendering complete" bugs
            // let sm = this.model.getSectionModel()
            // console.log(sm.get('title') + " " + this.model.id + ": " + msg + " _completed " +
            //     this.model.generalData.get('_completed'))
        },

        clearChart: function () {
            this._data = [];
            this._data_footnotes = null;
            this._reduced = null;
            this.$el.text('');
        },

        maxItemsData: function () {
            // Cache this data, since it can be expensive to calculate.
            // Functions such as #clearChart will clear this cached data,
            // as does functions that get data, etc.
            return this._reduced ?? [];

        },

        async reduceData(extraFootnotes) {
            this._reduced = await sortAndReduceDataToMaxItems(this.model, this._data, this._data.extra ?? {}, extraFootnotes);
        },

        async maybeRefresh() {
            var effectiveFilter = getEffectiveFilter(this.model);
            var c = this.model.changed;

            let ps = this.model.previousAttributes().show;
            let show = this.model.get('show');

            let check = ['yAxis', 'onlySupportHandles'];
            let yAxisChanged = !ps || show.length !== ps.length ||
                JSON.stringify(show.map(d => pick(d, check))) !== JSON.stringify(ps.map(d => pick(d, check)));
            if (yAxisChanged) this.updateCaption();

            var filterRefresh = effectiveFilter !== this._filter;
            var attributeRefresh = c.hasOwnProperty('xAxis')
                || c.hasOwnProperty('line') || c.hasOwnProperty('size')
                || c.hasOwnProperty('compare') || c.hasOwnProperty('coarseness')
                || c.hasOwnProperty("language") || c.hasOwnProperty('show-none-above')
                || c.hasOwnProperty('onlySupportHandles') || c.hasOwnProperty('dataSets')
                || yAxisChanged;
            if (filterRefresh || attributeRefresh) {
                // New data needs to be fetched for these changes.
                this.refresh(!!c["height"]);
            } else {
                const SHOULD_RERENDER = c.hasOwnProperty('maxComparisons') || c.hasOwnProperty("maxItems") ||
                    c.hasOwnProperty("facet") ||
                    c.hasOwnProperty("hideOther") || c.hasOwnProperty("hideUnknown") ||
                    c.hasOwnProperty("hideLegend") || c.hasOwnProperty("hideFuture") ||
                    c.hasOwnProperty("hideParents") || c.hasOwnProperty("hideChildren") ||
                    c.hasOwnProperty('noSi') || c.hasOwnProperty("showCooccurrence") ||
                    c.hasOwnProperty('hideMissing') || c.hasOwnProperty('fontSize') ||
                    c.hasOwnProperty('dateFormat') || c.hasOwnProperty('labelAngle') ||
                    c.hasOwnProperty('dateTicks') || c.hasOwnProperty('xGridLines') ||
                    c.hasOwnProperty('yGridLines') ||
                    c.hasOwnProperty('showAxisLabel') || c.hasOwnProperty('axisLabel') ||
                    c.hasOwnProperty('hideAxisMin') || c.hasOwnProperty('axisMaxValue') ||
                    c.hasOwnProperty('axisMinValue') ||
                    c.hasOwnProperty('showAxis2Label') || c.hasOwnProperty('axis2Label') ||
                    c.hasOwnProperty('hideAxis2Min') || c.hasOwnProperty('axis2MaxValue') ||
                    c.hasOwnProperty('axis2MinValue') || c.hasOwnProperty('stacked') ||
                    c.hasOwnProperty('hidden') || c.hasOwnProperty("xSortByField") || c.hasOwnProperty("xSortOrder") ||
                    c.hasOwnProperty("compareBucketSortByField") || c.hasOwnProperty("compareBucketSortOrder") ||
                    c.hasOwnProperty("durationFormat") || c.hasOwnProperty("durationHoursThreshold") ||
                    JSON.stringify(show) !== JSON.stringify(ps);
                // Only a rerender is needed in this section. No new data needs to be fetched.
                if (c['width'] || c['height']) {
                    await this.reduceData();
                    this.render(!!c['height']);
                } else if (SHOULD_RERENDER) {
                    await this.reduceData();
                    this.render();
                } else if (c.hasOwnProperty('hideLabels')) {
                    this.chart.immediatelyRenderLabels(!c['hideLabels']);
                }
            }
        },

        refreshIfFilterChanged: function (data) {
            if (getEffectiveFilter(data) !== this._filter) {
                this.refresh();
            }
        },

        annotateData: function (footnotes) {
            if (!this._data || !this._data.length) return;
            this._data.extra ??= {};

            if (!footnotes) footnotes = [];
            this._data_footnotes = footnotes;

            // We want to calculate mentionPercent across all data that
            // we have totals for. We need to do this for everything that we're
            // grouping by.

            let fields = FANTASTIC_FIELDS;
            let xAxis = this.model.get("xAxis");
            let xAxisField = fields[xAxis] || {};
            let compare = this.model.get("compare");
            let compareField = fields[compare] || {};
            let group = null;
            let isCompare = null;
            if (compare) {
                group = compare;
                isCompare = true;
            }

            if (group && compare && (compare === "sentiment" || compare === "topic")) {
                group = xAxis;
                isCompare = false;
            }
            var groupField = group && fields[group] || {};
            var groupFieldGetter = group && (isCompare ? getCompareGetter(group) : groupField.getter);
            var groupFieldFilterGetter = group && (isCompare ? getCompareFilterGetter(group) : groupField.filterGetter);

            var groupValues = new Set();
            var groupId = {};

            if (group) {
                // Find the values that we need to group over.
                for (var i = 0; i < this._data.length; i++) {
                    groupValues.add(groupFieldGetter(this._data[i]));
                    groupId[groupFieldGetter(this._data[i])] = groupFieldFilterGetter(this._data[i]);
                }
            }

            const data = this._data;
            const uniqueFootnotes = [];

            if (!group) calculateBarPercentages(null, group, groupFieldGetter, this.model, data, uniqueFootnotes);
            else groupValues.forEach(g => calculateBarPercentages(g, group, groupFieldGetter, this.model, data, uniqueFootnotes));

            new Set(uniqueFootnotes).forEach(f => footnotes.push(f));

            if (xAxisField.calculateMoe) calculateMoE(xAxis, data, this.model, footnotes);
            if (!xAxisField.calculateMoe && compareField.calculateMoe) calculateMoE(compare, data, this.model, footnotes);
        },

        toCSV: function () {
            if (!this._data) return null;

            var fields = FANTASTIC_FIELDS;
            var xAxis = this.model.get("xAxis");
            var xField = fields[xAxis] || {};
            var xAxisGetter = xField.getter || function (d) {
                return d[xAxis];
            };

            let show = this.model.get('show');
            let first = show[0];
            var yAxis = first.yAxis;
            var yField = fields[yAxis] || {};
            var yAxisGetter = yField.getter || function (d) {
                return d[yAxis];
            };
            var formatLabel = yField.formatLabel || function (val) {
                return "" + val;
            };
            var compare = this.model.get("compare");
            var compareField = fields[compare] || {};
            var compareGetter = compareField.getter || function (d) {
                return d[compare];
            };


            // We want to pivot sentiment data so that the analysts can more easily
            // use the data without pivoting in excel.
            var data = this._data;
            if (xAxis === "sentiment") {
                data = {};
                this._data.forEach(function (d) {
                    var key = compare && compareGetter(d) || "empty";
                    var row = data[key] = data[key] || {};
                    if (compare) row[compareField.name || compare] = compareGetter(d);
                    row[formatSentimentShort(d.sentiment)] = formatLabel(yAxisGetter(d));
                });

                data = Object.values(data);
            }

            if (compare === "sentiment") {
                data = {};
                this._data.forEach(function (d) {
                    var key = xAxisGetter(d) || "empty";
                    var row = data[key] = data[key] || {};
                    row[xField.name || xAxis] = xAxisGetter(d);
                    row[formatSentimentShort(d.sentiment)] = yAxisGetter(d);
                });

                data = Object.values(data);
            }


            var keys = new Set();
            var exampleSeen = false;
            var csvLabels = {};
            data.forEach(function (d) {
                Object.keys(d).forEach(function (k) {
                    if (k[0] === '_') return;                // Don't export keys starting with _
                    if (k !== "exampleMention") { // Want this to be the last column always.
                        if (xField.csvAlias && k.startsWith(xAxis + ".")) {
                            csvLabels[k] = k.replace(xAxis + ".", xField.csvAlias + " ");
                        }

                        if (compareField.csvAlias && k.startsWith(compare + ".")) {
                            csvLabels[k] = k.replace(compare + ".", compareField.csvAlias + " ");
                        }

                        if (compareField.csvAlias && k.startsWith(compare + "2.")) {
                            csvLabels[k] = k.replace(compare + "2.", compareField.csvAlias + " ");
                        }
                        keys.add(k);
                    } else exampleSeen = true;
                });
            });

            if (exampleSeen) keys.add("exampleMention");

            // Add the UTF-8 BOM to the header.
            // The UTF-8 BOM is so that Excel + Windows correctly interprets and opens
            // this file.
            var csv = '\ufeff' + Array.from(keys).map(function (k) {
                return csvLabels[k] || k;
            }).join(",") + "\n";

            data.forEach(function (d) {
                var line = [];
                keys.forEach(function (key) {
                    var val = null;
                    if (key.toLowerCase().endsWith("percent")) val = "" + (Math.floor(d[key] * 10000) / 100) + "%";
                    else val = (d[key] === null || d[key] === undefined) ? "NA" : "" + d[key];

                    val = val.replace(/"/g, '""');        // Escape double quotes.
                    line.push('"' + val + '"');
                });

                csv = csv + line.join(",") + "\n";
            });

            return csv;
        },

        /**
         * If courseness is auto then return what we think it should be.
         */
        getEffectiveCourseness(footnotes = []) {
            let coarseness = this.model.get("coarseness");
            let filter = this._filter || getEffectiveFilter(this.model);
            if ("auto" === coarseness) {
                if (filter) {
                    let days = extractDays(filter);
                    if (days <= 0) days = 999999; // extractDays returns 0 if there is no date.
                    if (days >= 90) return "monthly";
                    if (days >= 28) return "weekly";
                }
                return "daily";
            }
            // Ensure that we don't allow people to group by hour if the filter
            // date range is too long. This can cause massive performance problems
            // in the browser, including locking up a tab (in firefox) or the whole browser (in chrome)
            // for extended periods of time.
            // See https://app.clickup.com/t/862k7tddz for how bad this can get.
            if ("hourly" === coarseness) {
                if (filter) {
                    let days = extractDays(filter);
                    if (days <= 0) days = 999999; // extractDays returns 0 if there is no date.
                    const LOWER_BOUND = 28;
                    if (days >= LOWER_BOUND) {
                        footnotes?.push("The time frame is too large to group data by hour");
                    }
                    if (days >= 365) return "monthly";
                    if (days >= 90) return "weekly";
                    if (days >= LOWER_BOUND) return "daily";
                }
                return coarseness;
            }

            return coarseness || "daily";
        },

        async refresh(delay) {
            if (this.isRefreshing) return;
            this.isRefreshing = true;

            try {
                let filter = getEffectiveFilter(this.model);
                if (!filter) return;

                const fields = FANTASTIC_FIELDS;
                let footnotes = [];

                this._filter = filter;

                const getGrouse = function (field) {
                    const fields = FANTASTIC_FIELDS;
                    return (fields[field] && fields[field].grouseAlias) || [field];
                };

                let show = this.model.get('show');
                let first = show[0];
                const yAxis = first.yAxis;

                const select = new Set();
                // todo each show with own filter needs a separate query
                show.forEach(s => getGrouse(s.yAxis).forEach(d => select.add(d)));
                if (this.model.get("line")) getGrouse(this.model.get("line")).forEach(d => select.add(d));
                if (this.model.get("size")) getGrouse(this.model.get("size")).forEach(d => select.add(d));

                let xAxis = this.model.get("xAxis");
                const originalXAxis = xAxis;
                const xField = fields[xAxis] || {};
                const yField = fields[yAxis] || {};
                if (xAxis === "published") {
                    const coarseness = this.coarsenessMap[this.getEffectiveCourseness(footnotes)];
                    xAxis = "published[" + coarseness.toUpperCase() + "]";
                } else if (!xField.noSelect) {
                    // Select all required fields, except publication dates and brand.
                    getGrouse(xAxis).forEach(d => select.add(d));
                }

                const groupBy = Array.from(getGrouse(xAxis));
                const compare = this.model.get("compare");
                const compareField = (compare && fields[compare]) || {noLimit: true};
                if (compare) getGrouse(compare).forEach(function (d) {
                    if (d === "tag" || !groupBy.includes(d)) groupBy.push(d);
                });


                // We can only have one tag namespace. If multiple are chosen, we fall back to
                // not using a namespace at all, to make sure that we include everything. Fields
                // need to know how to filter out the data that they want, probably using the
                // preProcess field option.
                const tagNamespaceOptions = new Set();
                if (xField.tagNamespace) tagNamespaceOptions.add(xField.tagNamespace);
                if (compareField.tagNamespace) tagNamespaceOptions.add(compareField.tagNamespace);

                let tagNamespace = null;
                if (tagNamespaceOptions.size === 1) tagNamespace = Array.from(tagNamespaceOptions)[0];
                if (xAxis === "tag" || compare === "tag") tagNamespace = null;

                this.model.generalData.set({'_loading': true, _completed: false});
                this.log("refresh");
                //console.trace("refresh")

                // For showing example mentions efficiently on the db. Just get the ID.
                // If multiple brands are in the filter, we need to be grouping by brand for this
                // to work.
                const filterBrands = getBrandsInFilter(filter).include;
                if (groupBy.includes("brand") || filterBrands.length === 1) {
                    select.add("mostRecentMention[id]");
                }

                // for "Response rate" etc. include the brand support handles in the filter so only replies from those
                // handles are counted
                // if (yField.onlySupportHandles && first.onlySupportHandles) {
                //     await brandStore.refresh(true)
                //     let pids = []
                //     filterBrands.forEach(id => {
                //         let brand = brandStore.get(id)
                //         while (brand.parent) brand = brand.parent;
                //         if (brand.supportProfileIds && brand.supportProfileIds.length) {
                //             pids = pids.concat(brand.supportProfileIds)
                //         } else {
                //             footnotes.push((brand.shortName || brand.name) + " has no support handles, check brand setup")
                //         }
                //     })
                //     if (pids.length) {
                //         filter += " AND (" +
                //             pids.map(id => "MentionedProfile IS " + id + " OR MentionedProfile ISNT " + id).join(" OR ") + ")"
                //     }
                // }

                const query = {
                    filter: filter,
                    select: Array.from(select).join(','),
                    groupBy: groupBy.join(',')
                };

                if ((!xField.isDate && !xField.noLimit) || !compareField.noLimit) query.limit = 3000;

                if (tagNamespace) {
                    if (tagNamespace instanceof Array) tagNamespace = tagNamespace.join(",");
                    query.tagNamespace = tagNamespace;
                }

                try {
                    let data = await this.getData(query, groupBy);
                    this._data = [];
                    this._data.extra = data.extra || {};
                    this._reduced = null;

                    if (xField.preProcess) data = xField.preProcess(data, this.model);
                    if (yField.preProcess) data = yField.preProcess(data, this.model);
                    if (compareField.preProcess) data = compareField.preProcess(data, this.model);

                    data.forEach(d => {
                        const result = {};
                        Object.keys(d).forEach(key => {
                            if (fields[key] && fields[key].extendData) {
                                const extension = fields[key].extendData(d[key], key, this.model);
                                Object.keys(extension).forEach(k => {
                                    result[k] = extension[k];
                                });
                            } else {
                                result[key] = d[key];
                            }
                        });

                        this._data.push(result);
                    });

                    // Here we want to make sure that we fill in missing comparison fields,
                    // which grouse returns to us as null values with a mentioncount of 0.
                    if (compare) {
                        const allCompare = [];
                        const compareIds = new Set();
                        const allX = [];
                        const xIds = new Set();
                        const present = new Set();

                        let getId = function (d) {
                            if (d !== null && d !== undefined) {
                                if (d.id) return d.id;
                                else if (d.label) return d.label;
                                return "" + d;
                            }

                            return null;
                        };

                        data.forEach(function (d) {

                            const c = d[compareField.isTag ? "tag" : compare];
                            const cId = getId(c);

                            const x = d[xField.isTag ? "tag" : originalXAxis];
                            const xId = getId(x);

                            if (cId) {
                                if (!compareIds.has(cId)) allCompare.push(c);
                                compareIds.add(cId);
                            }

                            if (xId) {
                                if (!xIds.has(xId)) allX.push(x);
                                xIds.add(xId);
                            }

                            if (cId && xId) {
                                present.add(xId + ":" + cId);
                            }
                        });

                        allCompare.forEach(c => {
                            const cId = getId(c);

                            allX.forEach(x => {
                                const xId = getId(x);
                                if (present.has(xId + ":" + cId)) return;

                                const add = {};
                                add[compare] = c;
                                add[originalXAxis] = x;
                                initialiseRow(this.model, add);
                                if (xField.extendData) {
                                    Object.assign(add, xField.extendData(add[originalXAxis], originalXAxis, this.model));
                                }
                                if (compareField.extendData) {
                                    Object.assign(add, compareField.extendData(add[compare], compare, this.model));
                                }

                                this._data.push(add);
                            });
                        });
                    }

                    this.annotateData(footnotes);
                    await this.reduceData(footnotes); // Calculate and cache this

                    this.model.generalData.set({'_loading': false});
                    this.log("refresh waiting for render");
                    await this.render(!!delay);
                    this.model.generalData.set({'_loading': false, '_completed': true});
                    this.log("refresh waiting for render done");
                } catch (error) {
                    errorHelper(this.model, error);
                    this.clearChart();
                }
            } finally {
                this.isRefreshing = false;
            }
        },

        /**
         * Pulls the data that the chart needs from the API.
         */
        async getData(query, groupBy, ignore, ignoreY, dataSetCounter) {
            ignore = ignore || [];
            ignoreY ??= [];
            const that = this;

            const fields = FANTASTIC_FIELDS;
            const xAxis = this.model.get("xAxis");
            const xField = fields[xAxis] || {};
            let show = this.model.get('show');
            let first = show[0]; // Should really iterate
            const yAxis = first.yAxis;
            const yField = fields[yAxis] || {};
            const compare = this.model.get("compare") || "published";
            const compareField = compare && fields[compare] || {};

            if (yField.getData && !ignoreY.includes(yAxis)) {
                ignoreY.push(yAxis);
                return yField.getData(this.model, query, groupBy, ignore, ignoreY, this.getData.bind(this));
            }

            // See if our particular x axis related fields have their own ways to get data.
            if (xField.getData && !ignore.includes(xAxis)) {
                ignore.push(xAxis);
                return xField.getData(this.model, query, groupBy, ignore, ignoreY, this.getData.bind(this), this.model.get("dataSets")?.length);
            }

            // if we are grouping by data sets, there are some cases where we have to use compareField.getData once per data set (such as comparing RPCS or operational vs reputation)
            if (xAxis === "dataSet" && (compare === "rpcs" || compare === "functionalSentiment")) { // TODO: possibly remove the compare checker
                if (compareField.getData && dataSetCounter > -1) {
                    return compareField.getData(this.model, query, groupBy, ignore, ignoreY, this.getData.bind(this));
                }
            } else {
                if (compareField.getData && !ignore.includes(compare)) {
                    ignore.push(compare);
                    return compareField.getData(this.model, query, groupBy, ignore, ignoreY, this.getData.bind(this));
                }
            }


            const get = () => {
                const q = Object.assign({}, query);
                if (q.select) {
                    q.select = q.select
                        .split(",")
                        .map(function (s) {
                            return s === "tag" ? "tag[id,name,namespace,labels,descriptions,parent,index,leaf,flag]" : s;
                        })
                        .join(',');
                }

                const fromGrouse = this.model.getSectionModel()
                    ? this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view)
                    : grouseGet;

                return Promise.all([
                    fromGrouse('/v4/accounts/' + currentAccountCode() + '/mentions/count', q),
                    countPopulation(this.model, query)
                ]).then(([one, two]) => {
                    one.extra = {populationSize: two.mentionCount};
                    return one;
                });
            };

            // We want to decide if we need multiple queries to get our data. For almost everything
            // we do not.
            if (!groupBy) return get();

            // Now we are going to recursively remove every item that we need to group by,
            // and call this method again and again until we have our results.
            if (groupBy.includes("brand")) {
                var filterBrands = getBrandsInFilter(this._filter).include;

                if (filterBrands && filterBrands.length > 1) {
                    var brands = filterBrands.map(function (b) {
                        return getBrand(b);
                    });
                    var hasSubChildren = brands.some(function (brand) {
                        return brand && !!brand.parent;
                    });

                    if (hasSubChildren) {
                        var brandAttrs = convertFilterToAttrs(query.filter);
                        brandAttrs.brand = null;

                        var brandFilter = buildBasicFilter(brandAttrs);

                        return Promise
                            .all(
                                brands.map(function (b) {
                                    var f = "(" + brandFilter + ") and brand isorchildof " + b.id;
                                    var g = createGroupExcluding(groupBy, ignore, "brand");
                                    var q = Object.assign({}, query, {
                                        filter: f,
                                        groupBy: g.join(',')
                                    });

                                    if (!g.length) delete q.groupBy;

                                    return that.getData(q, g, ignoreY);
                                }))
                            .then(function (queryData) {
                                // We need to update the brand info to have the sub-brand info that we
                                // are grouping by.
                                // There's also a chance that the count endpoint did not return an array, so we
                                // need to handle that.
                                queryData.forEach(function (s, i) {
                                    if (!(s instanceof Array)) s = [s];
                                    s.forEach(function (row) {
                                        row.brand = brands[i];

                                        // add index to brand to ensure that brand field sorting works correctly
                                        // by default, when grouping by brand, brands should be sorted by the order that they appear in the filter
                                        row.brand.index = i;

                                    });
                                });

                                return queryData.flat();
                            });
                    }
                }
            }

            if (groupBy.includes("tag") && !ignore.includes("tag")) {
                var filterTags = getTagsInFilter(this._filter).include;
                if (filterTags && filterTags.length) {

                    // We want to separate out when we are querying for views.
                    // Views are not stored directly in the account db, so we will not get a total
                    // if we group by tag. We need to count the total separate without grouping by tag.
                    var views = {};
                    account().topicViews?.forEach(function (v) {
                        views[v.id] = v;
                    });

                    var filterViews = filterTags.filter(function (t) {
                        return views[t];
                    });
                    if (filterViews.length >= 1) {
                        var tagAttrs = convertFilterToAttrs(query.filter);
                        var notViews = filterTags.filter(function (t) {
                            return !views[t];
                        });

                        tagAttrs.tags = notViews.join(' ');
                        var tagFilter = buildBasicFilter(tagAttrs);

                        var viewCounts = filterViews.map(function (view) {
                            var f = "(" + tagFilter + ") and tag is " + view;
                            var g = createGroupExcluding(groupBy, ignore, "tag");
                            var q = Object.assign({}, query, {
                                filter: f,
                                select: createSelectExcluding(query, ignore, "tag").join(','),
                                groupBy: g.join(',')
                            });

                            if (!g.length) delete q.groupBy;
                            return that.getData(q, g, ignoreY)
                                .then(function (result) {
                                    if (!Array.isArray(result)) result = [result];
                                    result.forEach(function (row) {
                                        row["tag"] = views[view];
                                    });

                                    return result;
                                });
                        });

                        var tagSelect = createSelectExcluding(query, ignore, "tag").concat(["tag.*"]);
                        var tagQuery = Object.assign({}, query, {select: tagSelect.join(',')});

                        var totalCounts = this.getData(tagQuery, groupBy, ignore.concat(["tag"]))
                            .then(function (results) {
                                return results;
                            });

                        var parentCounts = new Promise(function (resolve, reject) {
                            try {
                                const code = currentAccountCode();
                                deprecatedFetchTags(code, resolve, {});
                            } catch (e) {
                                reject(e);
                            }
                        }).then(function (tags) {
                            return filterViews.map(function (view) {
                                var children = tags[view].children;
                                return children.map(function (child) {
                                    var f = "(" + tagFilter + ") and tag is " + child;
                                    var g = createGroupExcluding(groupBy, ignore, "tag");
                                    var q = Object.assign({}, query, {
                                        filter: f,
                                        select: createSelectExcluding(query, ignore, "tag").join(','),
                                        groupBy: g.join(',')
                                    });

                                    if (!g.length) delete q.groupBy;
                                    return that.getData(q, g, ignoreY)
                                        .then(function (result) {
                                            if (!Array.isArray(result)) result = [result];
                                            result.forEach(function (row) {
                                                row["tag"] = tags[child];
                                            });
                                            return result;
                                        });
                                });
                            }).flat();
                        }).then(function (calls) {
                            return Promise.all(calls);
                        });

                        // return Promise.all(viewCounts).then(_.flatten)
                        return Promise.all(viewCounts.concat(parentCounts, [totalCounts])).then(items => items.flat());
                    }
                }
            }

            return get();
        },


        showRightClickMenu: function (data) {
            // The D3 mouse subsystem applies SVG transforms to the mouse
            // coordinate. This is definitely not what we want in this case.
            // So here we use plain old javascript to figure out mouse coordinates
            // relative to our target element.
            var rect = data.e.target.getBoundingClientRect();
            var x = data.e.clientX - rect.left; //x position within the element.
            var y = data.e.clientY - rect.top;  //y position within the element.

            // Because of our transforms, the y coord could be flipped,
            // so we transpose it with the height of our target.

            var menu = [
                {
                    text: "Show mentions",
                    tooltip: "See these mentions in a popup dialog",
                    method: "showMentions"
                },

                {
                    text: "Show mentions in tab",
                    tooltip: "See these mentions in the Mentions Panel, opened in a new tab",
                    method: "showMentionsTab"
                },
                {
                    text: "Show word cloud",
                    tooltip: "Visualise these mentions as a Word Cloud",
                    method: "showWordCloud"
                },
                {
                    text: "Show authors",
                    tooltip: "See the authors involved in these mentions",
                    method: "showAuthors"
                }
            ];

            if (editMentions()) {
                menu.push(Beef.MiniMenu.divider);
                menu.push({
                    text: "Tag mentions...",
                    tooltip: "Tag these mentions",
                    method: "tagMentions"
                });
            }

            if (data.point) {
                let geoIndex = data.geometry?.index() ?? 0;
                let geo = this.model.attributes.show[geoIndex].geometry;
                if (geo !== 'lines') {
                    let item;
                    switch (geo) {
                        case "rows":
                        case "columns":
                            item = "bar";
                            break;
                        case "lines":
                            item = "line";
                            break;
                        case "pie":
                            item = "slice";
                            break;
                        default:
                            item = "point";
                    }
                    data.pointLabel = item;
                    menu.push(Beef.MiniMenu.divider);
                    menu.push({
                        text: "Edit " + item + " settings",
                        tooltip: "Edit colour and other settings for this " + item,
                        method: "editPointSettings"
                    });
                }
            }

            var fields = FANTASTIC_FIELDS;
            var xAxis = this.model.get("xAxis") || "published";
            var xField = fields[xAxis] || {};
            var xGetter = xField.getter || function (d) {
                return "" + d[xAxis];
            };
            var xFormatLabel = xField.formatLabel || function (d) {
                return "" + d;
            };
            var xFilterGetter = xField.filterGetter || function (d) {
                return d[xAxis];
            };
            var isOtherp = isOther(xFilterGetter(data.point));
            var isUnknownp = isUnknown(xFilterGetter(data.point));

            if (!xField.noRemoveData && !isOtherp && !isUnknownp) {
                menu.push(Beef.MiniMenu.divider);
                menu.push({
                    text: "Exclude from filter",
                    tooltip: "Remove " + encloseInDisplayQuotes(xFormatLabel(xGetter(data.point))) + " using this metric's sub-filter",
                    method: "filterOut"
                });
                menu.push({
                    text: "Hide data",
                    tooltip: "Hide " + encloseInDisplayQuotes(xFormatLabel(xGetter(data.point))) + " this data item from display. Does not affect percentage calculations.",
                    method: "hideData"
                });
            }

            var xFieldMenuOptions = xField.menuOptions ? xField.menuOptions() : null;
            if (xFieldMenuOptions && !isOtherp && !isUnknownp) {
                menu.push(Beef.MiniMenu.divider);
                xField.menuOptions(xFilterGetter(data.point)).forEach(function (option) {
                    menu.push(
                        Object.assign({}, option, {method: "xFieldMenu"})
                    );
                });
            }

            if (isOtherp || isUnknownp) {
                menu.push(Beef.MiniMenu.divider);
                if (isOtherp) {
                    menu.push({
                        text: "Hide others",
                        tooltip: "Hide the 'other' category from this chart",
                        method: "hideOthers"
                    });
                }
                if (isUnknownp) {
                    menu.push({
                        text: "Hide unknown",
                        tooltip: "Hide the 'unknown' category from this chart",
                        method: "hideUnknown"
                    });
                }
            }


            var that = this;

            Beef.MiniMenu.show({
                object: {
                    previewMentions: this.previewMentions.bind(this, data),
                    showMentions: this.dataSelected.bind(this, data, false),
                    showMentionsTab: this.dataSelected.bind(this, data, true),
                    showWordCloud: this.showWordCloud.bind(this, data),
                    showAuthors: this.authorSelected.bind(this, data, false),
                    tagMentions: () => this.tagMentions(data),

                    hideOthers: function () {
                        that.model.set("hideOther", true);
                        that.model.save();
                        notifyUser({
                            message: "'Other' values have been hidden from " + encloseInDisplayQuotes(that.model.get('caption')),
                            icon: "<i class='icon-signal-1'></i>",
                            undo: function () {
                                that.model.set("hideOther", false);
                                that.model.save();
                                notifyWithText("'Other' values are displayed again.", null, "<i class='icon-signal-1'></i>");
                            }
                        });
                    },

                    hideUnknown: function () {
                        that.model.set("hideUnknown", true);
                        that.model.save();
                        notifyUser({
                            message: "'Unknown' values have been hidden from " + encloseInDisplayQuotes(that.model.get('caption')),
                            icon: "<i class='icon-signal-1'></i>",
                            undo: function () {
                                that.model.set("hideUnknown", false);
                                that.model.save();
                                notifyWithText("'Unknown' values are displayed again.", null, "<i class='icon-signal-1'></i>");
                            }
                        });
                    },

                    hideData() {
                        const id = data.point.id;
                        if (!id === undefined) throw new Error("No id provided when hiding data");

                        const previousHidden = Array.from(that.model.get("hidden") ?? []);
                        let hidden = Array.from(that.model.get("hidden") ?? []);
                        let name = that.model.get('show')[0].geometry !== "rows" ? data.point._x : data.point._y;
                        if (that.model.get('compare')) name = `${name} (${data.point._colour})`;
                        hidden.push({id: id, name: name});

                        that.model.set("hidden", hidden);
                        that.model.save();

                        notifyWithHtml(
                            escapeHtml`Item <strong>${name}</strong> has been hidden`,
                            () => {
                                that.model.set("hidden", previousHidden);
                                notifyWithHtml(escapeHtml`Item <strong>${name}</strong> is no longer hidden`);
                            },
                            "<i class='icon-signal-1'></i>"
                        );
                    },

                    filterOut: function () {
                        var oldFilter = that.model.get('filter');
                        var xFilterField = xField.filterField || xAxis;
                        that.model.set("filter",
                            appendFiltersReadably(that.model.get("filter"),
                                createEqualityStatement(xFilterField,
                                    xFilterGetter(data.point),
                                    xGetter(data.point),
                                    {negate: true})));
                        that.model.save();

                        notifyUser({
                            message: `<strong>${escapeExpression(xGetter(data.point))}</strong>` +
                                " has been removed from " +
                                `<strong>${that.model.get('caption')}</strong>` + ".",
                            icon: "<i class='icon-signal-1'></i>",
                            isEscapedHtml: true,
                            undo: function () {
                                that.model.set('filter', oldFilter);
                                that.model.save();
                                notifyUser(encloseInDisplayQuotes(xFormatLabel(xGetter(data.point))) + " has been added back.", null, "<i class='icon-signal-1'></i>");
                            }
                        });
                    },

                    xFieldMenu: function () {
                        if (xFieldMenuOptions && xFieldMenuOptions.length) {
                            xFieldMenuOptions[0].method(xFilterGetter(data.point));
                        }
                    },

                    editPointSettings: function () {
                        that.editPointSettings(data);
                    }
                },
                target: data.e.target,
                positions: ['inside', 'left', 'right'],
                offsets: {left: x, top: y},
                dropdown: true,
                menu: menu
            });
        },

        /**
         * Edit colour etc. for a specific point (bar, line etc.) on the chart. These go in the pointSettings map
         * keyed by the id of the point.
         */
        editPointSettings: function (data) {
            showDialogComponent(FCPointSettingsDialog, {
                model: this.model,
                point: data.point,
                pointLabel: data.pointLabel,
                geometrySettings: this.model.attributes.show[data.geometry?.index() || 0]
            }).$on("ok", () => this.render());
        },

        setupEvents: function () {

            this.chart.dispatch().on('elementClick', function (data) {
                var event = data.e || d3.event;
                if (event.button === 2 || event.type === "contextmenu") { // right click
                    this.model.trigger('right-click:bar', data);
                } else if (event.button === 0) {           // left click
                    if (event.metaKey) {
                        this.model.trigger('middle-click:bar', data);
                    } else if (event.ctrlKey) {
                        // Safari does contextmenu on control click and fires this event
                        // Chrome only does contextmenu
                        if (!isSafari()) this.model.trigger('right-click:bar', data);
                    } else {
                        this.model.trigger('click:bar', data);
                    }
                } else if (event.button === 1) {    // middle click
                    this.model.trigger('middle-click:bar', data);
                }
            }.bind(this));

            let fetchSummaryTimeout;
            this.chart.dispatch().on('tooltipShow', function (event, data) {
                let show = this.model.get('show');
                let first = show[(event.geometry ? event.geometry.index() : 0)];
                var geometry = first.geometry;

                function rotate(x, y) {
                    if (geometry === "rows") {
                        return y;
                    }

                    return x;
                }

                var xAxis = this.model.get("xAxis");
                var yAxis = first.yAxis;
                var compare = this.model.get("compare");
                var size = this.model.get("size");

                var fields = FANTASTIC_FIELDS;
                var xField = fields[xAxis] || {};
                var yField = fields[yAxis] || {};
                var formatX = xField.formatTooltipX || xField.formatX || function (d) {
                    return d;
                };
                var formatTooltipY = yField.formatTooltipY || beefFormatNumber;
                var compareField = fields[compare] || {};
                var compareGetter = getCompareGetter(compare);
                var compareName = compareField.name || compare;
                var formatCompare = (fields[compare] && (fields[compare].formatTooltipX || fields[compare].formatX)) || function (d) {
                    return d;
                };
                var sizeField = fields[size] || {};
                var sizeGetter = getCompareGetter(size);
                var sizeName = sizeField.name || size;
                var formatSize = (fields[size] && fields[size].formatTooltip) || beefFormatNumber;
                var descriptionGetter = getDescriptionGetter(xAxis);

                var datePeriod = xField.tooltipPreposition || "for";
                var dateFormat = "dddd, MMMM D, YYYY";
                var coarseness = this.getEffectiveCourseness();

                if (!xField.tooltipPreposition) {
                    switch (coarseness) {
                        case "hourly":
                            datePeriod = "for the hour starting at";
                            dateFormat = "HH:mm, on dddd, MMMM D, YYYY";
                            break;
                        case "daily":
                            break;
                        case "weekly":
                            datePeriod = "for the week beginning";
                            break;
                        case "monthly":
                            datePeriod = "for";
                            dateFormat = "MMMM, YYYY";
                            break;
                        case "yearly":
                            datePeriod = "for";
                            dateFormat = "YYYY";
                            break;
                    }
                }

                var percentDatePeriod = xField.tooltipPrepositionPercent || "are " + datePeriod;

                var xVal = xField.tooltipGetter ? xField.tooltipGetter(event.point) : rotate(event.point._x, event.point._y);
                var yVal = yField.tooltipGetter ? yField.tooltipGetter(event.point) : rotate(event.point._y, event.point._x);
                var sizeVal = event.point._size;

                if (xField.isDate) {
                    xVal = formatDate(dateFormat, xVal);
                } else {
                    xVal = formatX(xVal);
                }

                if (yField.isDate) {
                    yVal = formatDate(dateFormat, yVal);
                }


                let summary = null;

                var model = new Backbone.Model({
                    isMentions: yAxis === "mentionCount" || yAxis === "mentionPercent",
                    isInteractions: yAxis === "interactionCount" || yAxis === "interactionPercent",
                    x: "published",
                    y: yAxis,
                    size: size,
                    sizeName: sizeField.name || size,
                    datePeriod: datePeriod,
                    dateFormat: dateFormat,
                    percentDatePeriod: percentDatePeriod,
                    yName: (yField.name || yAxis).toLowerCase(),
                    yArticle: yField.tooltipArticle || "a",
                    xVal: xVal,
                    yVal: yVal,
                    sizeVal: sizeVal,
                    moe: yField.isPercent ? event.point.moePercent : event.point.moe,
                    moePrecent: yField.isPercent ? event.point.moePercent >= 0 : false, // Do not show MoE for absolute amounts
                    yDecimal: yField.tooltipDecimal || 0,
                    description: descriptionGetter(event.point),
                    compare: compare ? formatCompare(compareGetter(event.point)) : null,
                    compareName: compareName,
                    comparePreposition: compareField.tooltipComparePreposition || ("for " + compareName),
                    mentionsWith: xField.tooltipMentionsWithVerb || datePeriod,
                    xIsPercent: !!yField.isPercent,
                    yDefinition: yField.description,
                    summary: summary
                });

                fetchSummaryTimeout = setTimeout(async () => {
                    model.set({summary: "Summarising your mentions..."});
                    try {
                        let summary = await summariseFilter(this.getSelectedFilter(event));
                        model.set({summary: summary.summary});
                        Beef.Tooltip.move();
                    } catch (e) {
                        model.set({summary: "No summary available"});
                        if (e.response?.status === 403 || e.response?.status === 401) {
                            console.error("It looks like you are not logged in. Please refresh the page and log in.");
                        } else {
                            console.error(e);
                        }
                        if (e.isAxiosError && !e.response) {
                            if (isDevEnvironment() && isMashAdmin()) {
                                let summary = {
                                    id: "dear-dev:turn-on-turducken",
                                    summary: "Dear dev: please turn on the Turducken service. \n\nBest. \nAnalyse.",
                                    algorithm: "DEFAULT_TEXT"
                                };
                                model.set({summary: summary.summary});
                            }
                        }
                    }
                }, 2000);

                var template = yField.tooltipTemplate || require("@/dashboards/widgets/fantasticchart/FantasticTip.handlebars");

                var tooltipSettings = {
                    template: template,
                    target: event.e.target,
                    positions: ['top-right', 'top-left', 'bottom-left', 'bottom-right'],
                    model: model,
                    autoclose: true,
                    templateHelpers: {
                        tooltipPercent: function (val) {
                            return formatPercentage(val, 1);
                        },
                        tooltipNumber: function (number, decimal) {
                            return new Handlebars.SafeString(beefFormatNumber(number, decimal));
                        },
                        tooltipCurrency: function (val) {
                            return formatCurrency(val, event.point, false);
                        },
                        formatTooltip: function (val) {
                            return new Handlebars.SafeString(formatTooltipY(val));
                        },
                        formatSize: function (val) {
                            return new Handlebars.SafeString(formatSize(val));
                        },
                        mention: function (val) {
                            return val === 1 ? "mention" : "mentions";
                        }
                    },
                    extraCls: "fantastic-chart--tooltip"
                };

                Beef.Tooltip.show(tooltipSettings);
            }.bind(this));

            this.chart.dispatch().on('tooltipHide', function (event) {
                clearTimeout(fetchSummaryTimeout);
            }.bind(this));
        },

        dataSelectedTab: function (data) {
            this.dataSelected(data, true);
        },

        dataSelected: function (data, newTab) {
            if (!newTab) {
                this.previewMentions(data);
                return;
            }

            var filter = this.getSelectedFilter(data);
            Beef.MentionList.navigateToMentions(this.accountCode, filter, null, !!newTab);
        },

        tagMentions(data) {
            const filter = this.getSelectedFilter(data);
            const title = "Tag mentions for " + encloseInDisplayQuotes(this.getTitlePortions(data).join(' '));

            showTagMentionDialog(filter, title);
        },

        previewMentions: function (data) {
            const filter = this.getSelectedFilter(data);
            const titles = this.getTitlePortions(data);

            showMentions(filter, titles.map(t => encloseInDisplayQuotes(t.toString())));
        },

        showWordCloud: function (data) {
            var filter = this.getSelectedFilter(data);
            var titles = this.getTitlePortions(data);
            showWordcloud(filter, titles.map(function (t) {
                return encloseInDisplayQuotes(t);
            }));
        },

        getTitlePortions: function (data) {
            var fields = FANTASTIC_FIELDS;
            var xAxis = this.model.get("xAxis") || "published";
            var xField = fields[xAxis] || {};
            var xGetter = xField.getter;
            var xFormatter = xField.formatX || function (d) {
                return "" + d;
            };
            if (xField.isDate) xFormatter = d => formatEnglishDate(d, this.getEffectiveCourseness());
            var selectedX = xGetter(data.point);


            var compare = this.model.get("compare");
            var compareField = fields[compare] || {};
            var compareGetter = getCompareGetter(compare);
            var compareFormatter = compareField.formatX || function (d) {
                return "" + d;
            };
            var selectedCompare = compare && compareGetter(data.point);

            var titles = [];
            if (selectedX !== null && selectedX !== undefined) titles.push(xFormatter(selectedX));
            if (selectedCompare) titles.push(compareFormatter(selectedCompare));

            return titles;
        },

        authorSelectedTab: function (data) {
            this.authorSelected(data, true);
        },

        authorSelected: function (data, newTab) {
            var filter = this.getSelectedFilter(data);
            Beef.AuthorsSectionV4.navigateToAuthors(this.accountCode, filter, !!newTab);
        },

        viewMentions: function () {
            var filter = getEffectiveFilter(this.model);
            Beef.MentionList.navigateToMentions(this.accountCode, filter, null);
        },

        viewAuthors: function () {
            var filter = getEffectiveFilter(this.model);
            Beef.AuthorsSectionV4.navigateToAuthors(this.accountCode, filter);
        },

        getSelectedFilter: function (data) {
            var fields = FANTASTIC_FIELDS;
            var xAxis = this.model.get("xAxis");
            let show = this.model.get('show');
            let first = show[(data.geometry ? data.geometry.index() : 0)];

            var yAxis = first.yAxis;
            var xField = fields[xAxis] || {};
            var yField = fields[yAxis] || {};
            var xFilterGetter = xField.filterGetter || function (d) {
                return d[xAxis];
            };
            var xGetter = xField.getter;
            var tree = parseFilterString(this._filter);
            var xLess = tree; // For when we need to remove things from the filter.

            var xFilterField = xField.filterField || xAxis;

            if (xField.isDate || !xField.isMultiple) {
                xLess = removeNodes(tree, function (node) {
                    return node.operandType === getLexerTokenForFantasticField(xFilterField);
                });
            }

            var compare = this.model.get("compare");
            var compareField = fields[compare] || {};
            var compareFilterField = compareField.filterField || compare;

            if (compare && !compareField.isMultiple) {
                xLess = removeNodes(xLess, function (node) {
                    return node.operandType === getLexerTokenForFantasticField(compareFilterField);
                });
            }

            var isOtherp = isOther(xFilterGetter(data.point));
            var xFilter = "";

            if (xAxis !== "dataSet" && !xField.isConstructed) {  // data point doesn't matter if we have a whole different data set
                if (!isOtherp) {
                    // The simple case. We need to make a filter that selects one x value, such as,
                    // "country is 'ZA'".
                    xFilter = createEqualityStatement(xFilterField, xFilterGetter(data.point),
                        xGetter(data.point), {
                            coarseness: this.getEffectiveCourseness(),
                            "filter": this._filter
                        });
                } else {
                    // We need to take all the data points that are not 'other' and make a conjunction
                    // of their negations. For instance: 'country isnt 'ZA' and country isnt 'UN'
                    var points = [];
                    this.maxItemsData().forEach(function (d) {
                        if (!isOther(xFilterGetter(d))) points.push(xFilterGetter(d));
                    });

                    var that = this;
                    var filters = points.map(function (d) {
                        return createEqualityStatement(xFilterField, d,
                            xGetter(data.point), {
                                coarseness: that.getEffectiveCourseness(),
                                "filter": that._filter,
                                negate: true
                            });
                    });
                    xFilter = filters.join(" and ");
                }
            }

            // Handle unknown authors from a particular website, and so on.
            if (xField.extraFilter) {
                var extra = xField.extraFilter(data.point);
                if (extra) xFilter = appendFiltersReadably("" + xFilter, "" + extra);
            }
            if (yField.extraFilterWhenY) {
                extra = yField.extraFilterWhenY(data.point);
                if (extra) xFilter = appendFiltersReadably("" + xFilter, "" + extra);
            }

            var filter = appendFiltersReadably("" + xFilter, "" + xLess);

            if (compare) {
                var compareId = getCompareFilterGetter(compare);
                var compareName = getCompareGetter(compare);

                var compareIds = new Set();
                var negate = false;
                if (!isOther(compareName(data.point))) {
                    compareIds.add(compareId(data.point));
                } else {
                    negate = true;
                    this.maxItemsData().forEach(function (d) {
                        if (!isOther(compareName(d))) compareIds.add(compareId(d));
                    });
                }

                var compareFilters = Array.from(compareIds).map(function (d) {
                    return createEqualityStatement(compareFilterField, d, compareName(data.point), {negate: negate});
                });

                var compareFilter = compareFilters.join(" AND ");

                if (compareField.extraFilter) {
                    extra = compareField.extraFilter(data.point);
                    if (extra) compareFilter = appendFiltersReadably("" + compareFilter, "" + extra);
                }
                filter = appendFiltersReadably("" + filter, "" + compareFilter);
            }

            return filter;
        },

        determineCaption: function () {
            let fields = FANTASTIC_FIELDS;
            let xAxis = this.model.get("xAxis");
            let compare = this.model.get("compare");
            let xField = fields[xAxis] || {};
            let compareField = compare && fields[compare] || {};

            let names = this.model.get('show').map(first => {
                var yAxis = first.yAxis;
                var yField = fields[yAxis] || {};

                if (!yField.chartName) {
                    console.warn("Unable to determine figure name for y-axis", yAxis);
                    return yAxis;
                }
                if (compare === xAxis) compare = null;

                if (!compare) {
                    if (xAxis === "sentiment" && yAxis === "mentionCount") return "Sentiment volume";
                    if (xAxis === "sentiment" && yAxis === "mentionPercent") return "Sentiment volume";
                    if (xAxis === "brand" && yAxis === "mentionCount") return "Brand volume";
                    if (xAxis === "brand" && yAxis === "mentionPercent") return "Brand volume";
                    if (xAxis === "brand" && yAxis === "totalEngagement") return "Brand engagement";
                    if (xAxis === "tag" && yAxis === "mentionCount") return "Tag volume";
                    if (xAxis === "tag" && yAxis === "mentionPercent") return "Tag volume";
                    if (xAxis === "tag" && yAxis === "totalEngagement") return "Tag engagement";
                }

                let name = "";
                if (compare) name = capitalise((compareField.name || compare)) + " comparison of ";
                let yName = (yField.chartName || yAxis);
                name += (name.length ? yName.toLowerCase() : yName);
                return name;
            });

            let name = names[0];
            for (let i = 1; i < names.length; i++) {
                name += i < names.length - 1 ? ", " : " and ";
                name += names[i];
            }
            if (xAxis !== "published") name += " by " + (xField.name || xAxis).toLowerCase();
            return name;
        },

        updateCaption: function () {
            var manual = this.model.get("manual-caption");
            if (manual) return;
            this.model.set("caption", this.determineCaption());
        },

        captionChanged: function () {
            var caption = this.model.get("caption");
            if (!caption || caption === "General Chart") { // Caption was unset. Let's use a default.
                this.updateCaption();
                return;
            }

            var name = this.determineCaption();

            if (caption === name) {
                this.model.unset("manual-caption");
                return;
            }

            this.model.set("manual-caption", true);
        }
    });

    //---------------------------------------------------

    if (account().segmentLists) {

        let applyNewPercentageCalculations = (model) => {
            if (!model) return false;

            if (features.chartSegmentListPercentageChanges()) {
                let newPercentageCalcCutoff = "2022-08-08";

                let dashboardCreated = moment(model.getDashboardModel()?.get("created"));
                let cutoffDate = moment(newPercentageCalcCutoff);

                // only apply new logic if this dashboard was created after the cutoff date
                return dashboardCreated.isAfter(cutoffDate);
            }

            return false;
        };

        // Here we are account the global segments and others that are available in the
        // account.
        account().segmentLists.forEach(function (s) {
            const fields = FANTASTIC_FIELDS;
            const noneOfTheAboveId = s.children?.filter(c => c.flag === "NONE_OF_THE_ABOVE")?.id;

            const sortOrder = {};
            s.children.forEach(function (c, i) {
                sortOrder[c.id] = i;
            });

            fields[s.id] = {
                name: s.name,
                isMultiple: true,
                noMaxItems: true,
                hideUnknown: true,
                hideOthers: true,
                showCooccurrence: true,
                hideMissing: true,
                isTag: true,
                calculateMoe: true,
                noLimit: true,
                csvAlias: s.name,
                grouseAlias: ["tag"],
                tagNamespace: "segment",
                colourFromX: function (d, defaultColour) {
                    const element = d['_' + s.id];
                    const defaultSegmentColour = s.segmentType?.id !== "TCF_LIST" ? "#c0c0c0" : "#757788";
                    if (element) {
                        return codeToColour(element.flag) || defaultSegmentColour;
                    }
                    return defaultColour;
                },
                formatTooltipX: function (tagName) {
                    const item = s.children.find(c => c.name === tagName);
                    if (!item || !item.flag || item.flag === "NONE_OF_THE_ABOVE") return tagName;
                    return new Handlebars.SafeString("<be-rpcs-icon code='" + item.flag + "'></be-rpcs-icon> " + tagName);
                },
                appendForFetch: function (model) {
                    // if CX, market conduct, or risk segment, append restriction filter
                    let filter = "";
                    if (s.segmentType?.id === "CX_LIST" || s.segmentType?.id === "CONDUCT_LIST" || s.segmentType?.id === "TCF_LIST" || s.segmentType?.id === "CHANNEL_LIST") {
                        filter = appendSegmentRestrictions(filter);
                    }

                    const showCooccurrence = !!model.get("showCooccurrence");
                    if (showCooccurrence) {
                        filter = appendFiltersReadably(filter, "segment is " + s.id);
                        return filter;
                    }

                    const globalSegments = getSegmentsInFilter(model.get("_effectiveFilter"))
                        .include
                        .filter(function (sId) {
                            return getSegmentList(sId).id === s.id;
                        });

                    const localSegments = model.get('filter')
                        ? getSegmentsInFilter(model.get('filter'))
                            .include
                            .filter(function (sId) {
                                return getSegmentList(sId).id === s.id;
                            })
                        : [];


                    if (localSegments.length) {
                        let segmentFilter = "(" + localSegments.map(function (s) {
                            return "segment is " + s;
                        }).join(' or ') + ")";
                        filter = appendFiltersReadably(filter, segmentFilter);
                        return filter;
                    }

                    if (globalSegments.length) {
                        let segmentFilter = "(" + globalSegments.map(function (s) {
                            return "segment is " + s;
                        }).join(' or ') + ")";
                        filter = appendFiltersReadably(filter, segmentFilter);
                        return filter;
                    }

                    filter = appendFiltersReadably(filter, "segment is " + s.id);
                    return filter;
                },
                getter: function (d, compare) {
                    const field = !compare ? s.id + ".name" : s.id + "2.name";
                    return d[field];
                },
                setter: tagAndTopicSetterFactory("" + s.id),
                filterField: "segment",
                filterGetter: tagAndTopicFilterGetterFactory("" + s.id),
                extendData: tagAndTopicExtender,
                filterData: function (data, model, ignore) {
                    const compare = model.get("compare");
                    const prefix = compare !== ("" + s.id) ? s.id : (s.id + "2");
                    const children = new Set();
                    s.children.forEach(c => children.add(c.id));

                    return data.filter(d => {
                        const id = d[prefix + ".id"];
                        return s.id === id || children.has(id);
                    });
                },
                postProcess: function (data, model) {
                    const showCooccurrence = !!model.get("showCooccurrence");
                    const hideMissing = !!model.get("hideMissing");
                    const xAxis = model.get("xAxis");

                    let compare = model.get("compare");
                    const isCompare = compare === "" + s.id;
                    const prefix = !isCompare ? "" + s.id : s.id + "2";

                    // If we're not showing coOccurrence, we should filter out any segments not in the filter.

                    if (!hideMissing) {
                        // Here we want to make sure that we are not missing any of the segments.
                        // If we are, we add it as a zero entry. The complication
                        // is when we are comparing with other data.
                        let show = model.get('show');
                        let first = show[0];
                        const yAxis = first.yAxis;
                        const hiddenIds = model.get("hidden")?.map(d => d.id) ?? [];

                        if (isCompare) {
                            compare = xAxis;
                        }

                        const fields = FANTASTIC_FIELDS;
                        const yField = fields[yAxis] || {};
                        const compareField = fields[compare] || {};
                        let compareGetter = null;
                        let compareFilterGetter = null;
                        if (compare) {
                            compareGetter = !isCompare ? getCompareGetter(compare) : compareField.getter || function (d) {
                                return d[compare];
                            };
                            compareFilterGetter = !isCompare ? getCompareFilterGetter(compare) : compareField.filterGetter || function (d) {
                                return d[compare];
                            };
                        }

                        const presentWithComparison = new Set();
                        const comparisons = new Set();
                        const idToName = {};

                        const segments = getSegmentsInFilter(getEffectiveFilter(model));
                        const includedSegments = new Set(segments.include);
                        const excludedSegments = new Set(segments.exclude);
                        const children = new Set();
                        s.children.forEach(function (c) {
                            children.add(c.id);
                        });

                        let canAdd = function (id) {
                            if (excludedSegments.has(id)) return false;
                            const isHidden = hiddenIds.some(hidden => hidden === id
                                || hidden?.toString().startsWith(`${id}:`)
                                || hidden?.toString().endsWith(`:${id}`)
                                || hidden?.toString().indexOf(`:${id}:`) >= 0);
                            if (isHidden) return false;
                            return !showCooccurrence
                                || !includedSegments.size && !excludedSegments.size
                                || includedSegments.has(id)
                                || includedSegments.has(s.id) && children.has(id);
                        };

                        let id;
                        for (let i = 0; i < data.length; i++) {
                            if (compare) {
                                // noinspection JSObjectNullOrUndefined,JSValidateTypes,JSValidateTypes
                                id = compareFilterGetter(data[i]);
                                comparisons.add(id);
                                // noinspection JSObjectNullOrUndefined,JSValidateTypes,JSValidateTypes
                                idToName[id] = compareGetter(data[i]);
                            }
                            presentWithComparison.add("" + (data[i][prefix + '.id'] + ":" + (compare ? id : '')));
                        }

                        const segmentsPresent = new Set();
                        data.forEach(function (d) {
                            segmentsPresent.add("" + d[prefix + '.id']);
                        });

                        let c;

                        for (let i = 0; i < s.children.length; i++) {
                            c = s.children[i];
                            if (c.id === noneOfTheAboveId) {
                                continue;
                            }
                            if (!compare) comparisons.add("");
                            comparisons.forEach(function (comp) {
                                if (!presentWithComparison.has(c.id + ":" + comp) && canAdd(c.id)) {
                                    const result = {};
                                    result[prefix + '.id'] = c.id;
                                    result[prefix + '.name'] = c.name;
                                    result[prefix + '.description'] = c.description;
                                    result[prefix + '.namespace'] = "segment";
                                    result['_' + s.id] = c;

                                    yField.setter(result, 0);
                                    if (compare) compareField.setter(result, comp, idToName[comp]);
                                    data.push(result);
                                }
                            });
                        }
                    }

                    // apply new methodology for segment list percentage calculations
                    if (applyNewPercentageCalculations(model)) {
                        let show = model.get('show');
                        let first = show[0];
                        const yAxis = first.yAxis;
                        let isMentions = yAxis === "mentionCount" || yAxis === "mentionPercent";

                        let segmentField = `_${prefix}`;
                        let countField = isMentions ? "mentionCount" : "interactionCount";
                        let percentField = isMentions ? "mentionPercent" : "interactionPercent";

                        // we need to handle total calculations differently when comparing
                        if (compare) {
                            let compareTotals = {};

                            // determine totals to use for percentage calculations
                            data.forEach(dataPoint => {
                                let compareValue = dataPoint[compare];
                                let segmentId = dataPoint[segmentField]?.id;

                                // if the data point's segment ID is the same as the segment_list (s.id), then use that mention count for percentage calculations
                                if (segmentId === s.id && compareTotals[compareValue] == null) {
                                    compareTotals[compareValue] = dataPoint[countField];
                                }
                            });

                            // calculate segment percentages based on segment_list total determined above
                            data.forEach(dataPoint => {
                                let compareValue = dataPoint[compare];
                                let segmentId = dataPoint[segmentField]?.id;

                                if (segmentId !== s.id && compareTotals[compareValue]) {
                                    dataPoint[percentField] = dataPoint[countField] / compareTotals[compareValue];
                                }
                            });
                        } else {
                            let total = 0;

                            // determine total to use for percentage calculations
                            for (const dataPoint of data) {
                                let segmentId = dataPoint[segmentField]?.id;

                                // if the data point's segment ID is the same as the segment_list (s.id), then use that mention count for percentage calculations
                                if (segmentId === s.id && total === 0) {
                                    total = dataPoint[countField];
                                    break;
                                }
                            }

                            // calculate segment percentages based on segment_list total determined above
                            data.forEach(dataPoint => {
                                let segmentId = dataPoint[segmentField]?.id;

                                if (segmentId !== s.id && total) {
                                    dataPoint[percentField] = dataPoint[countField] / total;
                                }
                            });
                        }

                        // remove segment_list from data
                        data = data.filter(d => d[segmentField]?.id !== s.id);
                    }

                    return data;
                },
                descriptionGetter: tagAndTopicDescriptionGetterFactory("" + s.id),
                defaultSortOptions: {
                    label: s.name,
                    field: s.id + ".id",
                    order: "ascending"
                },
                sorter: function (lhs, rhs, order) {
                    order ??= "ascending";

                    // when used as compare field, "2" is appended to segment ID field
                    let lhsP = sortOrder[lhs[s.id + ".id"]] ?? sortOrder[lhs[s.id + "2.id"]];
                    let rhsP = sortOrder[rhs[s.id + ".id"]] ?? sortOrder[rhs[s.id + "2.id"]];

                    if (order === "ascending") {
                        if (lhsP < rhsP) return -1;
                        if (rhsP < lhsP) return 1;
                    } else {
                        if (lhsP > rhsP) return -1;
                        if (rhsP > lhsP) return 1;
                    }

                    return 0;
                },
                async getData(model, query, groupBy, ignore, ignoreY, getData) {
                    // Most segments, like those for channels, CX and Risk, can be called from the API
                    // using our regular grouping code. Unfortunately, TCF / SegmentParents behaves like topic views,
                    // and we cannot group by them (since they are not on the mentions themselves). We need to
                    // query for them individually.

                    if (applyNewPercentageCalculations(model)) {
                        query.tagNamespace = "segment,segment_list";

                        return getData(query, groupBy, ignore, ignoreY);
                    } else {
                        const anySegmentsAreParents = s.children.some(c => c.type === 'SegmentParent'); // TODO: do we still need to do this? Comp table uses groupBy for TCF_OUTCOMES
                        if (!anySegmentsAreParents) {
                            return getData(query, groupBy, ignore, ignoreY);
                        } else {
                            const parents = s.children.filter(c => c.flag !== "NONE_OF_THE_ABOVE").map(async c => {
                                let filter = `(${query.filter}) and segment is ${c.id}`;
                                let g = createGroupExcluding(groupBy, ignore, "tag");
                                let q = Object.assign({}, query, {
                                    filter: filter,
                                    select: createSelectExcluding(query, ignore, "tag").join(','),
                                    groupBy: g.join(',')
                                });

                                if (!g.length) delete q.groupBy;
                                let data = await getData(q, g, ignore, ignoreY);
                                if (!Array.isArray(data)) data = [data];
                                data.forEach(row => row["tag"] = c);

                                return data;
                            });

                            const data = await Promise.all(parents);
                            return data.flat();
                        }
                    }
                }

            };
        });
    }
});

// https://www.denisbouquet.com/javascript-targeting-safari-only/
function isSafari() {
    let ua = navigator.userAgent;
    return ua.indexOf('Safari') >= 0 && ua.indexOf('Chrome') < 0;
}