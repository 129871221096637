<template>
    <div class="be-main-menu hide-in-fullscreen">
        <div class="be-main-menu__logo-container">
            <img src="/img/dataeq-venn.svg" alt="DataEQ Logo" class="be-main-menu__logo-image">
            <a v-if="user.accounts && user.accounts.length > 1" href="/accounts" @click.prevent="navigate('accounts')" class="be-main-menu__logo-text" tooltip="Click to see your accounts">
                Analyse
            </a>
            <span v-else class="be-main-menu__logo-text">
                Analyse
            </span>
        </div>

        <div id="ajax-container">
            <div id="ajax-busy" class="css-spinner"><div></div><div></div><div></div><div></div></div>
            <div id="the-great-eye"></div>
        </div>

        <nav v-if="account.code">
            <ul>
                <li v-for="navItem in navItems" :key="navItem.href" :class="{'active' : navItem.active}">
                    <a :href="navItem.href" v-if="navItem.visible" @click.prevent="navigate(navItem.href)"  class="static-beef-tooltip--trigger static-beef-tooltip--below">
                        <header>
                            <i :class="navItem.icon"></i>
                            <span class="be-main-menu__name">{{ navItem.name }}</span>
                        </header>
                        <div class="be-main-menu__underline"></div>
                        <section class="static-beef-tooltip">
                            <h4><i :class="navItem.icon"></i> {{ navItem.tooltipHeading }}</h4>
                            <p>{{ navItem.tooltip }}</p>
                            <p v-if="navItem.key">
                                Press the <keyboard-key>g</keyboard-key> key then <keyboard-key>{{navItem.key}}</keyboard-key> to go here.
                            </p>
                        </section>
                    </a>
                </li>
            </ul>
        </nav>

        <div class="be-main-menu__options">
            <a :href="`/${account.code}/setup/health`" @click.prevent="navigate(`/${account.code}/setup/health`)" class="account-name be-main-menu__option">{{ account.name }}</a>

            <a class="be-main-menu__option">
                <popup-menu fixed :arrow-pointer="{show: true, left: false}" :pos-offset="{top: 5, left: 15}">
                    <template #activator>
                        <span>
                            <i class="symbol-help"></i> Help
                        </span>
                    </template>
                    <help/>
                </popup-menu>
            </a>

            <a class="be-main-menu__option">
                <popup-menu fixed :arrow-pointer="{show: true, left: false}" :pos-offset="{top:5, left:3}">
                    <template #activator>
                        <span @click="loadAccounts" class="accounts-menu-activator">
                            <span class="caret"></span>
                        </span>
                    </template>

                    <account-menu :loading-accounts="loadingAccounts" :accounts="accounts"/>
                </popup-menu>
            </a>
        </div>
    </div>
</template>

<script>
import AccountMenu from "@/app/main-menu/AccountMenu";
import Help from "@/app/help/HelpMenu.vue";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import PopupMenu from "@/components/PopupMenu";
import {features} from "@/app/Features";
import KeyboardKey from "@/components/formatters/KeyboardKey";

export default {
    name: "MainMenu",
    store: VuexStore,
    components: {KeyboardKey, PopupMenu, Help, AccountMenu},
    data() {
        return {
            features,
            accounts: [],
            loadingAccounts: false,
            navItems: []
        }
    },

    created() {
        this.navItems = [
            {
                name: "Explore",
                href: `/${this.account.code}/explore`,
                icon: "symbol-explore",
                active: false,
                visible: true,
                tooltipHeading: "Explore",
                tooltip: "See an overview of what's happening in your account. Explore the conversations, topics and trends affecting you now.",
                key: 'e'
            },
            {
                name: "Reports",
                href: `/${this.account.code}/dashboards`,
                icon: "symbol-reports",
                active: false,
                visible: true,
                tooltipHeading: "Dashboards & Reporting",
                tooltip: "Create dashboards to visualise how people are talking about you and your competitors. See risk factors and opportunities to service your customers.",
                key: 'r'
            },
            {
                name: "Mentions",
                href: `/${this.account.code}/mentions`,
                icon: "symbol-mentions",
                active: false,
                visible: true,
                tooltipHeading: "Mentions & Conversations",
                tooltip: "Read individuals mentions (tweets, Facebook and Instagram posts) as well as whole conversations.",
                key: 'm'
            },
            {
                name: "Authors",
                href: `/${this.account.code}/authors`,
                icon: "symbol-authors",
                active: false,
                visible: true,
                tooltipHeading: "Authors",
                tooltip: "See who is who in your community.",
                key: 'a'
            },
            {
                name: "Rewind",
                href: `/${this.account.code}/rewind`,
                icon: "icon-back-in-time",
                active: false,
                visible: features.historicalSearch(),
                tooltipHeading: "Rewind",
                tooltip: "Rewind yourself some data. Explore historical Twitter data trends."
            },
            {
                name: "Setup",
                href: `/${this.account.code}/setup`,
                icon: "symbol-setup",
                active: false,
                visible: true,
                tooltipHeading: "Account Setup",
                tooltip: "See your account settings, such as the brands you're tracking, your phrases and rules.",
                key: 's'
            }
        ];

        // handle setting of initial active menu item
        this.setActiveNavItem(window.location.pathname);

        // handle setting active nav items during user routing
        Beef.router.bind("all", () => {
            this.setActiveNavItem(Backbone.history.fragment)
        });
    },

    computed: {
      ...mapState(['user', 'account'])
    },

    methods: {
        async loadAccounts() {
            try {
                // load data from user accounts first
                let accounts = this.user.accounts;

                if (accounts) {
                    this.accounts = accounts.filter(ac => !ac.inactive);
                } else {
                    // no accounts on user object yet
                    this.loadingAccounts = true;
                }

                // load data from API
                await VuexStore.dispatch('refreshUser', true);

                this.accounts = (this.user.accounts || []).filter(ac => !ac.inactive);
            } catch (e) {
                console.warn("Error reading accounts list", e);
            } finally {
                this.loadingAccounts = false;
            }
        },

        setActiveNavItem(curPage) {
            if (curPage && curPage.charAt(0) !== '/') curPage = `/${curPage}`;

            this.navItems.forEach(navItem => {
                navItem.active = curPage.includes(navItem.href);
            });
        },

        navigate(href) {
            if (href) Beef.router.navigate(href, {trigger: true});
        }
    }
}
</script>

<style scoped lang="sass">

//Ensures that account options doesn't shift when dialog is opened
body[data-dialogs]
    .be-main-menu
        padding-right: var(--popup-scrollbar-width, 0)

.be-main-menu
    position: fixed
    top: 0
    right: 0
    left: 0
    min-height: 40px
    background: var(--navbar-background)
    border: none
    box-shadow: 0 1px 5px rgba(0,0,0,0.2)
    z-index: 1030
    display: flex

    a
        color: var(--nav-colour-base)

    &__logo-container
        padding: 5px 8px 0 16px

    &__logo-image
        width: 30px
        height: 30px

    &__logo-text
        cursor: default
        padding: 3px 0 3px 7px
        margin-left: 8px
        font-size: 13px
        color: #eeeeee
        border-left: 1px solid #5b5d63
        --tooltip-y-offset: -65px



    header
        padding: 0 14px 5px 0

    &__underline
        transition-property: background, width, margin
        transition-duration: var(--transition-duration)
        transition-timing-function: cubic-bezier(0.33, 1.2, 0.52, 1)
        height: 3px
        width: 0
        background: #939393
        margin: 2px auto 0
        outline: none !important

    ul
        list-style: none
        display: inline-flex
        margin: 9px 0 0

    li
        cursor: pointer
        padding: 0 5px
        color: #939393
        &:hover
            --transition-duration: 300ms
            .be-main-menu__underline
                width: 70%
        &.active
            color: #fff
            .be-main-menu__underline
                width: 100%
                height: 5px
                background: #fff
                margin-top: 0

        i
            font-size: 1.2em

    &__options
        margin-left: auto
        margin-right: 7px
        font-size: 13px
        display: inline-flex
        align-items: center

        .accounts-menu-activator
            padding: 0 10px
            .caret
                vertical-align: middle
            &:hover
                .caret
                    border-top-color: #ceed9e

        a
            cursor: pointer
            border-right: 1px solid #353535
            padding: 0 10px
            color: #fff

            &:hover
                color: #ceed9e

            &:last-child
                border: none
                padding: 0

        .account-name
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap

    &__loader
        transition: opacity 0.5s ease-out
        opacity: 0
        margin-top: 12px


a.be-main-menu__logo-text
    cursor: pointer

    &:hover
        color: #ceed9e

@media screen and (prefers-reduced-motion: reduce)
    li:hover
        --transition-duration: 1ms

@media (max-width: 1000px)
    .be-main-menu
        a header
            padding-right: 0

        &__name
            display: none

        li:hover
            .be-main-menu__underline
                width: 100%

</style>