<template>
    <section class="enhanced-comment">
        <CommentaryPlusEditor
            :key="editorJsKey"
            :blocks="blocksData"
            :filter="filter"
            :isReadOnly="true"
            @completed="completed"
        />
    </section>
</template>


<script>
import CommentaryPlusEditor from "@/dashboards/widgets/commentaryplus/CommentaryPlusEditor.vue";
export default {
    name: "CommentaryPlusInMetric",
    components: {CommentaryPlusEditor},

    data() {
        return {
            editorJsKey: Math.floor(Math.random() * 10000),
            blocksData: this.blocks
        };
    },

    props: {
        model: Object,
        blocks: String
    },

    watch: {
        attributes() {
            this.editorJsKey = Math.floor(Math.random() * 10000);
        },
        blocksData() {
        }
    },

    methods: {
        completed(){
            this.model.generalData.set('_completed', true);
        }
    },

    computed: {
        attributes() {
            return this.model.attributes;
        },

        text() {
            return this.blocksData;
        },

        filter() {
            return this.attributes._effectiveFilter;
        },
    },
}

</script>

<style scoped>

.enhanced-comment{
    max-height: inherit;
    height: 100%;
    overflow: auto;
    background-color: rgb(245, 245, 245);
}

::v-deep .ce-block__content {
    margin: 0;
    max-width: inherit;
}

</style>