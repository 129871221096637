var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit the ";
},"3":function(container,depth0,helpers,partials,data) {
    return "Add a ";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"pull-left\">\n                <a class=\"delete btn\" title=\"Delete this phrase\">Delete</a>\n            </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editing") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":53}}})) != null ? stack1 : "")
    + "search phrase</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"pages\">\n        <div class=\"main-form row-fluid page-edit\">\n\n            <div class=\"control-group\">Find mentions that:</div>\n\n            <div class=\"control-group control-group-spacer\">\n                <div class=\"edit-inc-terms\">\n                    <label>Include all of these terms (case insensitive). Press enter after each phrase</label>\n                    <div class=\"controls\">\n                        <span class=\"_include tag-input uneditable-input span12\">\n                            <span name=\"_include\"></span>\n                            <input class=\"tag popup-menu\" type=\"text\"/>\n                        </span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"control-group control-group-spacer\">\n                <label>Exclude all of these terms (case insensitive). Press enter after each phrase</label>\n                <div class=\"controls\">\n                    <span class=\"_exclude tag-input uneditable-input span12\">\n                        <span name=\"_exclude\"></span>\n                        <input class=\"tag popup-menu\" type=\"text\"/>\n                    </span>\n                </div>\n            </div>\n\n        </div>\n\n        <div class=\"main-form page-goodness\"></div>\n\n        <div class=\"main-form page-tune\"></div>\n\n    </div>\n\n    <div class=\"dialog-button-bar\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editing") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + "        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"prev btn\"><i class=\"icon-chevron-left\"></i> Previous</a>\n        <a class=\"next btn\"><i class=\"icon-chevron-right\"></i> Next</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n</div>\n";
},"useData":true});