<template>
    <span class="be-number">{{ value }}</span>
</template>

<script>
    import {formatPercentage} from "@/app/utils/Format";

    /**
     * Formats a percentage for display. This also uses an appropriate font
     */
    export default {
        props: {
            percent: {
                type: Number,
                required: true
            },
            isProportion: {
                type: Boolean
            },
            isPercentagePoints: {
                type: Boolean
            },
            precision: Number
        },

        computed: {
            value() {
                const val = this.isProportion ? this.percent * 100 : this.percent;
                if (val !== undefined && val !== null) {
                    return formatPercentage(val, this.precision, !this.isPercentagePoints ? null : 'pp');
                }
                return "NA"
            }
        },
    }
</script>

<style scoped lang="sass">
.be-number
    font-family: var(--number-font-family)

strong .be-number
    color: white
    font-size: calc(1em + 0.5px)


</style>