import {extractBrands} from "@/dashboards/filter/BasicFilter";

Beef.module("MentionPreview").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({
        template: require("@/setup/rules/MentionPreview.handlebars"),

        regions: {
            mentions: ".mentions",
            pages: ".mention-pages"
        },

        initialize: function(options) {
            this.accountCode = options.accountCode;
            this.model.set({allSelected: false, selected: {}, unselected: {}});
            this.paginationModel = new Backbone.Model({offset: 0, limit: 30, page: 1});
            this.paginationModel.on('change:page', this.onPageChange.bind(this));
            this.list = new Beef.MentionList.Collection(null, {accountCode: this.accountCode,
                paginationModel: this.paginationModel, parentModel: this.model});
        },

        onRender: function() {
            this.mentions.show(new Beef.MentionList.View({collection: this.list,
                accountCode: this.accountCode, model: this.model, noSelect: true }));

            // this.paginationView = Beef.Pagination.createView({model: this.paginationModel});
            // this.pages.show(this.paginationView);

            this.fetchMentions();
        },

        getFilter: function() {
            var f = this.model.get('filter');
            var extra = "published inthelast month";
            var brandIds = extractBrands(f).include;
            if (brandIds.length == 0) {
                var pb = this.model.get('previewBrand');
                if (pb) extra += " and brand isorchildof " + pb;
            }
            return (f ? "(" + f + ") and " : "") + extra;
        },

        fetchMentions: function() {
            this.$(".spinner").show();
            var offset = this.paginationModel.get('offset');
            var that = this;
            this.list.fetch(
                this.getFilter(),
                offset,
                this.paginationModel.get('limit'),
                null,   // orderBy
                null,   // includeStats
                offset == 0, // includeTotal
                {
                    success: function() { that.onFetchSuccess() },
                    error: function(collection, res) { that.onFetchFailure(res) }
                }
            );
        },

        onFetchSuccess: function() {
            var msg, found = this.list.length, limit = this.paginationModel.get('limit');
            if (found == 0) {
                msg = "The rule filter doesn't match any mentions published in the last week. Click OK to " +
                    "save the rule or return to the previous screen to edit your criteria."
            } else {
                msg = "The rule filter matches " + (found == limit ? "at least " : "") + found +
                    " mention" + (found == 1 ? "" : "s") + " published in the last week. If you are happy to apply " +
                    "this rule to all future mentions like this, click OK to save the rule or return to the previous screen to " +
                    "edit your criteria."
            }
            this.$(".spinner").hide();
            this.$('.mention-pages').show();
            var $msg = this.$(".msg");
            $msg.toggleClass("text-warning", false);
            $msg.text(msg);
        },

        onFetchFailure: function(res) {
            var msg;
            if (res.status == 422 && res.responseText) {
                if (res.responseText.indexOf("[BRAND-ERROR]") >= 0) {
                    msg = "Please return to the previous page and select a preview brand on the top right to preview " +
                        "mentions or click OK to save the rule without previewing mentions"
                } else if (res.responseText.indexOf("[BRAND-ERROR-MULTIPLE]") >= 0) {
                    msg = "Unable to preview mentions as the rule filter contains more than one brand. The rule will " +
                        "still work. Click OK to save the rule"
                }
            }
            if (!msg) msg = "Error(" + res.status + ") " + (res.statusText ? ": " + res.statusText : "");
            this.$(".spinner").hide();
            this.$('.mention-pages').hide();
            var $msg = this.$(".msg");
            $msg.toggleClass("text-warning", true);
            $msg.html("<i class='icon-warning'></i> " + msg);
        },

        onPageChange: function() {
            this.$('.spinner').show();
            this.fetchMentions();
        }
    });
});