<template>
    <div class="deq-ok-cancel-close-button">
        <slot name="close-button" v-if="!isChanged">
            <BeButton
                      primary
                      tooltip="Close this dialog" keyword="ESC"
                      :disabled="disabled"
                      @click="$emit('close')">
                Close
            </BeButton>
        </slot>

        <div v-else
             style="display:contents;">
            <slot name="cancel-button">
                <BeButton link
                          class="animated fadeIn"
                          tooltip="Cancel these changes" keyword="ESC"
                          :disabled="disabled"
                          @click="$emit('cancel')">Cancel</BeButton>
            </slot>

            <slot name="ok-button">
                <BeButton primary
                          tooltip="Accept these changes"
                          :disabled="disabled"
                          @click="$emit('accept')">Ok
                </BeButton>
            </slot>
        </div>
    </div>
</template>


<script>

import BeButton from "@/components/buttons/BeButton.vue";

export default {
    components: {BeButton},

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        isChanged: {
            type: Boolean,
            default: false
        }
    }
}

</script>



<style scoped lang="sass">

.deq-ok-cancel-close-button
    display: flex

</style>