<template>
    <div class="account-profile">
        <div class="row-fluid head title">
            <h1>Account Profile</h1>
        </div>

        <div class="account-profile__settings-header">
            <h4>{{ canEditAccountProfile ? 'Account Settings' : 'Account Information' }}</h4>
            <be-button v-if="canEditAccountProfile" link @click="editAccountSettings()"><i class="symbol-edit"></i>Edit</be-button>
        </div>

        <div class="account-profile__read-only-info-container">
            <div class="info-column">
                <span>Account Name</span>
                <span>{{ account.name }}</span>

                <span>Account Code</span>
                <span>{{ account.code }}</span>

                <span>Date Created</span>
                <deq-date :date="account.dateCreated || ''" format="MMMM Do YYYY, HH:mm:ss"></deq-date>

                <span>Timezone</span>
                <span>{{ account.timezone }}</span>

                <span>Region</span>
                <span>{{ account.region ? account.region.name : '' }}</span>

                <span>Location</span>
                <span :class="{'unset-value': !location}">{{ location || 'Not set' }}</span>

                <span>Currency</span>
                <deq-currency :currency="account.currency"></deq-currency>

                <span>Font</span>
                <span>{{ font }}</span>

                <span>Default brand</span>
                <span :class="{'unset-value': !defaultBrand}">{{ defaultBrand || 'Not set' }}</span>
            </div>
            <div class="info-column">
                <span>Volume Limit/Month</span>
                <deq-number :number="account.mentionsPerMonth"></deq-number>

                <span>Root brands</span>
                <span :class="{'over-limit': rootBrandsOverLimit}">{{ account.usage ? account.usage.rootBrands : '' }} {{ rootBrandsOverLimit ? `(Over limit: max allowed = ${account.maxRootBrands})` : '' }}</span>

                <span>Search Phrases</span>
                <span :class="{'over-limit': searchPhrasesOverLimit}">{{ account.usage ? account.usage.phrases : '' }} {{ searchPhrasesOverLimit ? `(Over limit: max allowed = ${account.maxPhrases})` : '' }}</span>

                <span>Online Profiles</span>
                <span :class="{'over-limit': onlineProfilesOverLimit}">{{ account.usage ? account.usage.onlineProfiles : '' }} {{ onlineProfilesOverLimit ? `(Over limit: max allowed = ${account.maxOnlineProfiles})` : '' }}</span>

                <span>Users</span>
                <span :class="{'over-limit': usersOverLimit}">{{ account.usage ? account.usage.users : '' }} {{ usersOverLimit ? `(Over limit: max allowed = ${account.maxUsers})` : '' }}</span>
            </div>
            <div class="info-column">
                <span>Status</span>
                <span>{{ account.status }}</span>

                <span>Engage</span>
                <span>{{ account.hasEngage ? 'Enabled' : 'Not enabled' }}</span>
            </div>
        </div>

        <div>
            <activity-view></activity-view>
        </div>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import DeqDate from "@/components/formatters/DeqDate";
import DeqNumber from "@/components/formatters/DeqNumber";
import DeqCurrency from "@/components/formatters/DeqCurrency";
import BeButton from "@/components/buttons/BeButton";
import ActivityView from "@/components/activity-view/ActivityView";
import EditAccountSettingsDialog from "@/settings/account-profile/EditAccountSettingsDialog";
import {editAccountProfile} from "@/app/Permissions";

export default {
    name: "SetupAccountProfile",
    components: {ActivityView, BeButton, DeqCurrency, DeqNumber, DeqDate},

    data() {
        return {
            location: ""
        }
    },

    created() {
        this.setLocation();
    },

    watch: {
        'account.country'() {
            this.setLocation();
        }
    },

    computed: {
        ...mapState(['account']),
        ...mapGetters(['idToBrand']),

        canEditAccountProfile() {
            return editAccountProfile();
        },

        font() {
            return Beef.FontPicker.getFont(this.account.font);
        },

        defaultBrand() {
            let defaultBrand = this.account.defaultBrand;

            if (!defaultBrand) return null;
            else {
                let brand = this.idToBrand.get(defaultBrand.id);
                return brand?.shortName || brand?.name || defaultBrand.name;
            }
        },

        rootBrandsOverLimit() {
            return this.account?.usage?.rootBrands > this.account.maxRootBrands;
        },

        searchPhrasesOverLimit() {
            return this.account?.usage?.phrases > this.account.maxPhrases;
        },

        onlineProfilesOverLimit() {
            return this.account?.usage?.onlineProfiles > this.account.maxOnlineProfiles;
        },

        usersOverLimit() {
            return this.account?.usage?.users > this.account.maxUsers;
        }
    },

    methods: {
        editAccountSettings() {
            showDialogComponent(EditAccountSettingsDialog);
        },

         setLocation() {
             if (this.account.country) {
                 Beef.LocationPicker.lookup(this.account.country)
                     .then(location => {
                         let loc =  Beef.LocationPicker.formatLocation(location, false);
                         if (loc) this.location = loc;
                     })
                     .catch(error => {
                         console.warn("Unable to look up location: ", error);
                     });
             } else {
                 this.location = "";
             }
         }
    }
}
</script>

<style scoped lang="sass">

.activity-list
    // reset inner view's padding
    margin: -20px -20px 0

.account-profile

    &__settings-header
        display: flex
        align-items: center
        margin-bottom: 20px

    &__read-only-info-container
        display: flex
        align-items: baseline
        column-gap: 30px
        margin-bottom: 50px

        .info-column
            display: grid
            grid-template-columns: auto auto
            row-gap: 10px
            column-gap: 20px

            span:nth-child(odd)
                color: var(--be-colour-muted-text-light)

            .unset-value
                color: var(--be-colour-muted-text-light)
                font-style: italic

            .over-limit
                color: var(--ac-colour-suspended)

</style>