<template>
    <drop-down-input v-model="val" :optional="optional" :multiselect="multiselect" :options="items"
                     search :label="label" :disabled="disabled" placeholder="Start typing the font name" :required="required"/>
</template>

<script>
import DropDownInput from "./DropDownInput"
import {FONTS} from "@/app/utils/Font";

export default {
    name: "FontInput",

    components: { DropDownInput },

    props: {
        label: String,
        value: [String, Array],
        optional: Boolean,
        multiselect: Boolean,
        required: Boolean,
        disabled: Boolean
    },

    data() {
        return {
            val: this.value
        }
    },

    watch: {
        value(v) {
            this.val = v
        },

        val(v) {
            this.$emit("input", v)
        }
    },

    computed: {
        items() {
            let fontList = {};

            Object.entries(FONTS).forEach(font => {
                let id = font[0];
                let name = font[1];

                fontList[id] = {
                    id: id,
                    name: name
                }
            });

            return fontList;
        }
    }
}

</script>

<style scoped>

</style>