/**
 * Select one or more crowd job types. Fires a change event when the selection is changed. Also provides a model binder
 * converter to display the selected options in an element.
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("CrowdJobTypePicker").addInitializer(function(startupOptions) {

    this.items = {
        RATING: "Rating",
        TOPIC_LIST: "Classification",
        TOPIC_TREE: "Topic assignment",
        SEGMENTATION: "Segmentation"
    };

    this.View = Beef.Picker.View.extend({
        attributes: { class: "crowd-job-type-picker" },
        template: require("@/dashboards/filter/pickers/crowdjobtype/CrowdJobTypePicker.handlebars"),
        items: this.items
    });

    this.converter = createTagConverter({
        tooltip: function(code) {
            return Beef.CrowdJobTypePicker.items[code];
        },
        items: this.items,
        placeholder: "Crowd job types"
    });

    /**
     * Attach a crowd job type picker to a view attribute identified by selector. Updates attribute in the view's
     * model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
