<template>
    <dialog-box v-if="show" @close="close" modal no-dim width="575px"
                :title="category ? 'Folder Access Control' : 'Dashboard Access Control'" stop-mousedown>
        <loading-message v-if="loading" message="Fetching teams data..." style="margin-top: 20px"></loading-message>
        <div v-else>
            <div style="min-height: 200px">
            <span v-if="category">
                Edit the read and write access control of dashboards within <i class="icon-folder">{{ category.name }}</i>
            </span>
                <span v-else>
                Edit the read and write access control of dashboard {{ model.attributes.name }}
            </span>
                <div class="row-fluid" style="margin-top: 10px;">
                    <div class="span12" >
                        <div class="control-group" style="display: flex">
                            <div class="dashboard-security__team-list-container">
                                <h4>Teams that can view</h4>
                                <checkbox-list v-model="readTeamIds" :options="viewTeams"
                                               class="dashboard-security__team-list dark-scrollbars dark-scrollbars--visible" @check="onReadCheck" @uncheck="onReadUncheck"/>

                                <dark-card class="dashboard-security__message-container">{{ readTeamsMessage }}</dark-card>
                            </div>
                            <div class="dashboard-security__team-list-container">
                                <h4>Teams that can view and edit</h4>
                                <checkbox-list v-model="writeTeamIds" :options="editTeams"
                                               class="dashboard-security__team-list dark-scrollbars dark-scrollbars--visible" @check="onWriteCheck"/>

                                <dark-card class="dashboard-security__message-container">{{ writeTeamsMessage }}</dark-card>
                            </div>
                        </div>
                        <div v-if="noTeams" style="margin-top: 10px">
                            No teams have been created for this account. Account admins can group users into teams
                            to control access to dashboards in <a @click.prevent="gotoTeams" :href="teamSettingsLink">settings</a>.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-fluid" v-if="canShareDashboardLinks && !category" style="margin-top: 20px">
                <div class="control-group span12">
                    <label>Link that anyone can use to view this dashboard without having to login</label>
                    <div class="controls">
                    <span class="tag-input uneditable-input span12 public-link" @click="showPublicLink">
                        <span v-if="busy" class='spinner' style='vertical-align: -3px'></span>
                        <textarea v-else-if="publicLink" readonly rows='2' :value="publicLink" ref="textarea"/>
                        <span v-else style="color: #bbb">
                            <span v-if="hasPublicLink">(click to show link)</span>
                            <span v-else>(no public viewing allowed, click to create public link)</span>
                        </span>
                    </span>
                    </div>
                    <div style="display: flex; align-items: center">
                        <div>
                            <a class="btn" :class="{disabled: !hasPublicLink}"
                               title="Cancel any previously shared links" @click.prevent="revokePublicLink">Revoke</a>
                        </div>
                        <div v-if="noIpWhitelist" style="margin-left: 8px; margin-top: 2px">
                            Please note that no IP addresses have been whitelisted, please contact DataEQ support.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #buttons>
            <a class="btn cancel" @click="close">Cancel</a>
            <a class="btn btn-primary" @click="ok">Ok</a>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import CheckboxList from "@/components/inputs/CheckboxList"
import {teams} from "@/store/deprecated/Stores"
import {mash} from "@/store/Services";
import {notifyWithText, notifyUserOfError, showBusyNotification} from "@/app/framework/notifications/Notifications";
import {showErrorDialog, showInfoDialog, showWhenDialog} from "@/app/framework/dialogs/Dialog";
import {isMashAdmin, canShareDashboardLinks} from "@/app/Permissions";
import DarkCard from "@/components/cards/DarkCard";
import Vue from "vue";
import LoadingMessage from "@/components/LoadingMessage";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: "EditDashboardSecurityDialog",

    components: {LoadingMessage, DarkCard, DialogBox, CheckboxList },
    store: VuexStore,

    props: {
        // only set if editing the security permissions of a single dashboard
        value: Boolean,
        model: Object,
        originalModel: Object,

        // only set if editing the security permissions of a folder
        category: Object
    },

    data() {
        return {
            loading: false,
            showDialog: true,
            allTeams: [],
            viewTeams: [],
            editTeams: [],
            readTeamIds: [],
            writeTeamIds: [],
            hasPublicLink: false,
            otherOwnerTeam: null,
            version: 0,
            busy: false,
            publicLink: null
        }
    },

    async created() {
        try {
            this.loading = true;

            await teams.refresh(false);

            this.allTeams = JSON.parse(JSON.stringify(teams.list));

            if (!this.category) {
                this.handleSingleDashboardLoad();
            } else {
                this.handleCategoryLoad();
            }
        } catch (e) {
            console.error("There was an error fetching teams for this account: ", e);
            notifyUserOfError("There was an error fetching the teams for this account. Please close the dialog and try again or contact support.");
        } finally {
            this.loading = false;
        }
    },

    computed: {
        ...mapState(['account', 'user']),
        ...mapGetters('accountUsers', ['idToUser']),

        noTeams() { return teams.list && teams.list.length === 0 },

        show() {
            return (!this.category && this.value) || (this.category && this.showDialog)
        },

        isMashAdmin() { return isMashAdmin() },

        canShareDashboardLinks() { return canShareDashboardLinks() },

        isAccountUser() { return !!this.idToUser.get(this.user.id) },

        teamSettingsLink() { return "/" + this.account.code + "/setup/teams" },

        noIpWhitelist() { return !this.account.dashboardLinkIpWhitelist || !this.account.dashboardLinkIpWhitelist.length },

        writeTeamsMessage() {
            let allWriteDisabled = this.editTeams.filter(wt => wt.disabled)?.length === this.editTeams.length;

            if (allWriteDisabled) {
                return `An exclusive team has been selected for view-only access.
                        ${this.category ? `Dashboards currently in this folder will automatically only be editable by this team.` :
                    `The dashboard will only be editable by this team.`}`;
            }

            if (!this.writeTeamIds.length) {
                return `No teams selected.
                        ${this.category ? `Dashboards currently in this folder will be editable by all users that can see them.` :
                    `The dashboard will be editable by all users that can access it.`}`;
            }

            return `${this.category ? `Dashboards currently in this folder` : `The dashboard`} will only be editable by the selected teams.`;
        },

        readTeamsMessage() {
            let allWriteDisabled = this.editTeams.filter(wt => wt.disabled)?.length === this.editTeams.length;

            // An exclusive read team has only been selected if all write teams are disabled
            if (allWriteDisabled) {
                return `An exclusive team has been selected for view-only access.
                        ${this.category ? `Dashboards currently in this folder` : `The dashboard`}
                        will only be viewable by this team.`
            }

            if (!this.readTeamIds.length) {
                return `No teams selected.
                        ${this.category ? `Dashboards currently in this folder` : `The dashboard`}
                        will be visible for all users.`
            }

            return `${this.category ? `Dashboards currently in this folder` : `The dashboard`} will only be viewable for the selected teams.`;
        }
    },

    watch: {
        version(v) {
            this.model.set('version', v)
            this.originalModel.set('version', v)
        },

        hasPublicLink(v) {
            this.model.set('hasPublicLink', v)
            this.originalModel.set('hasPublicLink', v)
        }
    },

    async mounted() {
        await this.refreshAccountUsers(true)
    },

    methods: {
        ...mapActions('dashboards', ['updateDashboardCategoryPermissions']),
        ...mapActions('accountUsers', ['refreshAccountUsers']),

        close() {
            if (!this.category) {
                this.$emit('input', false);
            } else {
                this.$emit('close');
            }
        },

        toggleWriteTeams(value) {
            this.editTeams.forEach(editTeam => {
                editTeam.disabled = value;
            });
        },

        handleCategoryLoad() {
            if (this.isMashAdmin) {
                this.allTeams.unshift(TEAM_STAFF);
            } else if (this.isAccountUser) {
                this.allTeams.unshift(TEAM_SELF);
            }

            this.viewTeams = JSON.parse(JSON.stringify(this.allTeams));
            this.editTeams = JSON.parse(JSON.stringify(this.allTeams));

            this.editTeams.forEach(editTeam => {
                Vue.set(editTeam, 'disabled', false);
            });
        },

        handleSingleDashboardLoad() {
            let attrs = this.model.attributes
            let privacy = attrs.privacy

            let ownerId = attrs.ownerId ?? attrs.createdBy.id;

            // if the dashboard has privacy OWNER or OWNER_EDIT and someone else is the owner then this is the team
            let otherOwnerTeam = null
            if ((privacy === 'OWNER' || privacy === 'OWNER_EDIT') && ownerId !== this.user.id) {
                otherOwnerTeam = {
                    id: "owner",
                    exclusive: true,
                    name: "Only " + this.idToUser.get(ownerId).name
                }
            }

            let read = attrs.readTeamIds ? [...attrs.readTeamIds] : []
            let write = attrs.writeTeamIds ? [...attrs.writeTeamIds] : []
            if (privacy === 'MASH_ADMIN') {
                read.push(TEAM_STAFF.id)
            } else if (privacy === 'MASH_ADMIN_EDIT') {
                write.push(TEAM_STAFF.id)
            } else if (privacy === 'OWNER') {
                if (otherOwnerTeam) read.push(otherOwnerTeam.id)
                else read.push(TEAM_SELF.id)
            } else if (privacy === 'OWNER_EDIT') {
                if (otherOwnerTeam) write.push(otherOwnerTeam.id)
                else write.push(TEAM_SELF.id)
            }

            let a = []
            if (this.isMashAdmin) a.push(TEAM_STAFF);
            else if (this.isAccountUser) a.push(TEAM_SELF);
            if (this.otherOwnerTeam) a.push(this.otherOwnerTeam)

            this.viewTeams = JSON.parse(JSON.stringify(a.concat(this.allTeams)));
            this.editTeams = JSON.parse(JSON.stringify(a.concat(this.allTeams)));

            this.editTeams.forEach(editTeam => {
                Vue.set(editTeam, 'disabled', privacy === "MASH_ADMIN" || privacy === "OWNER")
            });

            this.readTeamIds = read;
            this.writeTeamIds = write;
            this.hasPublicLink = attrs.hasPublicLink;
            this.otherOwnerTeam = otherOwnerTeam;
            this.version = attrs.version;
        },

        onWriteCheck(cb) {
            let staff = this.readTeamIds.indexOf("staff") >= 0
            let owner = this.readTeamIds.indexOf("owner") >= 0
            let self = this.readTeamIds.indexOf("self") >= 0
            if (cb.id === "self" && (owner || staff)) this.readTeamIds = [cb.id]
            else if (cb.id === "owner" && (self || staff)) this.readTeamIds = [cb.id]
            else if (cb.id === "staff" && (self || owner)) this.readTeamIds = [cb.id]
        },

        onReadCheck(cb) {
            if (cb.id === "self"  || cb.id === "owner" || cb.id === "staff") {
                this.writeTeamIds = [];

                this.toggleWriteTeams(true);
            } else {
                this.toggleWriteTeams(false);
            }
        },

        onReadUncheck(cb) {
            if (cb.id === "self" || cb.id === "owner" || cb.id === "staff") {
                this.toggleWriteTeams(false);
            }
        },

        ok() {
            if (!this.category) {
                this.saveSingleDashboard();
            } else {
                this.saveCategoryDashboards();
            }
        },

        saveSingleDashboard() {
            let m = this.model
            let ownerId = m.attributes.ownerId
            let privacy = "PUBLIC"
            if (this.readTeamIds.indexOf("staff") >= 0) {
                privacy = "MASH_ADMIN"
            } else if (this.writeTeamIds.indexOf("staff") >= 0) {
                privacy = "MASH_ADMIN_EDIT"
            } else if (this.readTeamIds.indexOf("self") >= 0) {
                privacy = "OWNER"
                ownerId = this.user.id
            } else if (this.readTeamIds.indexOf("owner") >= 0) {
                privacy = "OWNER"
            } else if (this.writeTeamIds.indexOf("self") >= 0) {
                privacy = "OWNER_EDIT"
                ownerId = this.user.id
            } else if (this.writeTeamIds.indexOf("owner") >= 0) {
                privacy = "OWNER_EDIT"
            }

            m.set('readTeamIds', numbersOnly(this.readTeamIds))
            m.set('writeTeamIds', numbersOnly(this.writeTeamIds))
            m.set('privacy', privacy)
            m.set('ownerId', ownerId)

            this.$emit('input', false)
        },

        saveCategoryDashboards() {
            showWhenDialog(`Are you sure you want to change the access permissions of all dashboards within ${this.category.name}?`,
                "Change folder permissions?")
                .then(async () => {
                    this.showDialog = false;
                    let busy = showBusyNotification("Changing folder permissions...", null, null, true);
                    try {
                        this.$emit('disable-category', this.category.name);

                        let privacy = "PUBLIC"
                        if (this.readTeamIds.indexOf("staff") >= 0) {
                            privacy = "MASH_ADMIN"
                            this.readTeamIds = [];
                            this.writeTeamIds = [];
                        } else if (this.writeTeamIds.indexOf("staff") >= 0) {
                            privacy = "MASH_ADMIN_EDIT"
                        } else if (this.readTeamIds.indexOf("self") >= 0) {
                            privacy = "OWNER"
                        } else if (this.readTeamIds.indexOf("owner") >= 0) {
                            privacy = "OWNER"
                        } else if (this.writeTeamIds.indexOf("self") >= 0) {
                            privacy = "OWNER_EDIT"
                        } else if (this.writeTeamIds.indexOf("owner") >= 0) {
                            privacy = "OWNER_EDIT"
                        }

                        let dashboardIds = this.category.dashboards.map(d => d.id);
                        this.readTeamIds = numbersOnly(this.readTeamIds);
                        this.writeTeamIds = numbersOnly(this.writeTeamIds);

                        await this.updateDashboardCategoryPermissions({
                            category: this.category.name,
                            privacy: privacy,
                            readTeamIds: this.readTeamIds,
                            writeTeamIds: this.writeTeamIds,
                            dashboardIds: dashboardIds
                        });

                        notifyWithText(`Folder ${this.category.name}'s permissions have been updated.`, null, "<i class='symbol-reports'></i>");
                    } catch (e) {
                        console.error("There was an error updating the permissions of dashboards within this folder: ", e);
                        let errorMessage = "There was an error updating the permissions within your folder";
                        if (e.response?.status === 403) {
                            errorMessage += ": there are dashboards in this folder that you are not able to edit."
                        }
                        showErrorDialog(errorMessage);
                    } finally {
                        this.$emit('enable-category', this.category.name);
                        this.$emit('close');
                        busy.close();
                    }
                });
        },

        gotoTeams() {
            this.$emit('input', false)
            Beef.router.navigate(this.teamSettingsLink, {trigger: true})
        },

        showPublicLink() {
            if (this.busy) return
            if (!this.publicLink) {
                this.busy = true
                mash.get('/rest/accounts/' + this.account.code + '/reports/' + this.model.get('id') + '/share-link').then(res => {
                    this.publicLink = res.data.link
                    this.hasPublicLink = true
                    this.version = res.data.version
                    setTimeout(() => this.copyLinkToClipboard(), 1)
                }).catch(e => {
                    showErrorDialog("Error fetching link: " + e, "Error")
                }).finally(() => this.busy = false)
            } else {
                this.copyLinkToClipboard()
            }

        },

        copyLinkToClipboard() {
            this.$refs.textarea.select()
            document.execCommand('copy')
            let noIps = !this.account.dashboardLinkIpWhitelist || !this.account.dashboardLinkIpWhitelist.length
            showInfoDialog("Be careful who you share the link with. Anyone following the link from a " +
                "whitelisted IP address will be able view this dashboard. You can revoke the link at any time by " +
                "clicking the 'Revoke' button." +
                (noIps ? "\n\nPlease note that no IP addresses have been whitelisted. " +
                    "Please contact DataEQ support to whitelist your organizations IP addresses." : ""),
                "Link copied to clipboard")
        },

        revokePublicLink() {
            if (this.busy || !this.hasPublicLink) return;
            this.busy = true;
            mash.delete('/rest/accounts/' + this.account.code + '/reports/' + this.model.get('id') + '/share-link').then(res => {
                this.hasPublicLink = false;
                this.version = res.data.version;
            }).catch(e => {
                showErrorDialog("Error revoking link: " + e, "Error");
            }).finally(() => this.busy = false)
        }
    }
}

function numbersOnly(a) { return a.filter(v => typeof v === "number") }

const TEAM_STAFF = { id: "staff", exclusive: true, name: "Only DataEQ staff (Exclusive)" }
const TEAM_SELF = { id: "self", exclusive: true, name: "Only yourself (Exclusive)" }
</script>

<style scoped lang="sass">

.dashboard-security

    &__team-list-container
        padding: 5px 10px
        border: 1px solid #272727
        background: #333333
        width: 50%
        margin-right: 8px

    &__team-list
        max-height: 200px
        min-height: 50px
        overflow: auto
        margin: 10px 0 4px 8px

    &__message-container
        min-height: 125px
        margin-top: 20px

</style>

<style scoped>
.team-list {
    max-height: 300px;
    overflow: auto;
    margin: 4px 0 4px 8px;
}

.public-link textarea {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    cursor: text;
}

</style>