<template>
    <section>
        <overview-title>Consumer mentions for </overview-title>
        <loading-message v-if="storeLoading" message="Examining your mentions..."/>
        <overview-description v-else-if="consumerCount > 0">
            <h4>
                The most engaging, top-level, Consumer conversations started {{ englishDate }}
            </h4>
            <div>
                <p>
                    <span v-if="consumerCount !== null">
                        Consumers began
                        <strong><deq-number :number="consumerCount"/> {{formatPlural(consumerCount, 'conversation')}} {{ englishDate }}</strong>.
                        You can see all of them in the <a @click.prevent="gotoConsumerMentions()" :href="mentionsLink"><i class="symbol-mentions"></i>Mentions panel</a>,
                        or <a @click.prevent="createDashboard" href="#"><i class='symbol-reports'></i>create a dashboard</a>
                        to explore them further.
                    </span>

                    <net-sentiment-calculator v-if="filter"
                                              :filter="filter"
                                              v-slot="{netSentiment, delta}">

                        The overall Net Sentiment of the top-level consumer mentions is
                        <strong style="white-space: nowrap">
                            <net-sentiment :net-sentiment="netSentiment"/>
                            &nbsp;<percentage-point-delta v-if="delta !== null" :delta="delta"/>.
                        </strong>
                    </net-sentiment-calculator>
                </p>
                <p v-if="filter">
                    <most-frequent-topic :filter="filter"/>
                </p>
            </div>
        </overview-description>
        <no-topics-message v-else-if="consumerCount === 0" class="keyword-trends__none">
            <p>
                No top-level Consumer mentions
            </p>
        </no-topics-message>

        <engaging-mentions v-if="!storeLoading && brand && consumerCount > 0" :filter="filter" :date-filter="dateFilter"/>
    </section>
</template>

<script>
import EngagingMentions from "@/app/toplevel/explore/overview/components/EngagingMentions";
import {mapGetters, mapState} from 'vuex';
import OverviewTitle from "@/app/toplevel/explore/overview/components/OverviewTitle";
import DeqNumber from "@/components/formatters/DeqNumber";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import LoadingMessage from "@/components/LoadingMessage";
import moment from "moment";
import NetSentiment from "@/components/NetSentiment";
import NetSentimentCalculator from "@/app/toplevel/explore/overview/components/NetSentimentCalculator";
import PercentagePointDelta from "@/components/formatters/PercentagePointDelta";
import MostFrequentTopic from "@/app/toplevel/explore/overview/components/MostFrequentTopic";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {createSimpleDashboardForFilter} from "@/app/toplevel/dashboards/BeefModuleDashboardUtilities";
import {formatPlural} from "@/app/utils/Format";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";

export default {
    components: {
        DeqNumber,
        OverviewDescription,
        NoTopicsMessage,
        MostFrequentTopic,
        PercentagePointDelta,
        NetSentimentCalculator,
        NetSentiment, LoadingMessage, OverviewTitle, EngagingMentions},

    computed: {
        ...mapState(['account']),
        ...mapGetters('explorePanel', ['brandName', 'consumerCount', 'dateFilter', 'englishDate']),
        ...mapState('explorePanel', {
            brand: state => state.brand,
            storeLoading: state => state.loading
        }),
        ...mapGetters('explorePanel', {
            filter: 'consumerFilter'
        }),

        mentionsLink() {
            if (!this.filter) return "#";
            return `/${this.account.code}/mentions?filter=${encodeURIComponent(this.filter)}&orderby=engagement desc`;
        },
    },

    methods: {
        formatPlural,


        gotoConsumerMentions() {
            if (!this.filter) return;
            gotoMentionPanel(this.filter, "engagement desc");
        },

        createDashboard() {
            if (this.filter) {
                createSimpleDashboardForFilter(
                    this.filter,
                    `Exploring consumer conversation for ${this.brandName} - ${moment().format("YYYY-MM-DD")}`,
                    "Consumer conversations"
                );
            }
        }
    }
}
</script>



<style scoped lang="sass">

</style>