<template>
    <div class="row-fluid head title" :class="{'overview-title--disabled': disabled}">
        <h1 v-if="!brand">Exploring</h1>
        <h1 v-else>
            <span v-if="disabled">
                 <spinner-component :size="16"/> Loading
            </span>
            <slot v-else>Explore </slot>
            <span class="overview-tool__selector" @click="selectBrand">
                <old-tooltip :label="!disabled ? 'Choose the brand that you want to explore' : 'Loading...'"
                         style="width: auto; height: auto;">
                    {{ formatBrandName(brand) }}<i class="symbol-edit"></i>
                </old-tooltip>
            </span>
            &nbsp;
            <span class="overview-tool__selector"
                  @click="selectDate()">
                <old-tooltip :label="!disabled ? 'Choose the date range you would like to examine' : 'Loading...'"
                         style="width: auto; height: auto;">
                    {{ englishDate }}<i class="symbol-edit"></i>
                </old-tooltip>
            </span>
        </h1>
    </div>
</template>

<script>
import OldTooltip from "@/components/tooltip/OldTooltip";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import DatePickerDialog from "@/app/framework/dialogs/date-picker/DatePickerDialog";
import {mapActions, mapGetters, mapState} from "vuex";
import {formatBrandName} from "@/app/utils/Format";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import SpinnerComponent from "@/components/SpinnerComponent";

export default {
    components: {OldTooltip, SpinnerComponent},

    computed: {
        ...mapState('explorePanel', ['isInitialised', 'loading', 'date', 'brand']),
        ...mapGetters('explorePanel', ['statsLoading', 'englishDate']),
        ...mapGetters(['idToBrand']),

        disabled() {
            return !this.isInitialised || this.loading || this.statsLoading;
        }
    },

    methods: {
        formatBrandName,

        ...mapActions('explorePanel', [
            'setBrand', 'setDate'
        ]),

        selectDate() {
            if (this.disabled) return;

            const dialog = showDialog(DatePickerDialog, {date: this.date});

            dialog.$on('selected', (date) => {
                this.setDate(date);
            });
        },

        selectBrand() {
            if (this.disabled) return;

            const brands = [];
            if (this.brand) brands.push(this.brand.id);
            const dialog = showDialog(BrandPickerDialog, {selectedIds: brands, onlyOne: true, mustHaveOne: true});

            dialog.$on('selected', selected => {
                if (selected.length) {
                    this.setBrand(this.idToBrand.get(selected[0].id));
                } else {
                    this.setBrand(null);
                }
            })
        },
    }
}
</script>


<style scoped lang="sass">

.overview-tool__selector
    cursor: pointer
    transition: color var(--transition-duration)

    &:hover
        color: var(--be-colour-text-dark__hover)

[class='symbol-edit']
    transition: opacity var(--transition-duration)


.overview-title--disabled
    .overview-tool__selector
        cursor: not-allowed
        &:hover
            color: inherit

    [class='symbol-edit']
        opacity: 0.4

</style>