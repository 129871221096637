var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"generalMessages") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":12,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                <li style=\"padding-bottom: 8px\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"warn-heading\">Section</div>\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"section") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":21,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n        <br>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"warn-heading\">Metrics</div>\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"widgets") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":31,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"caption") : depth0), depth0))
    + " - "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span style=\"color: #ff9602;\">Handle:</span> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"profileError") : depth0)) != null ? lookupProperty(stack1,"handle") : stack1), depth0))
    + " <br>\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"profileError") : depth0)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + " <br>\n        <span style=\"color: #ff9602;\">Probable reason:</span> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"profileError") : depth0)) != null ? lookupProperty(stack1,"reason") : stack1), depth0))
    + " <br>\n        <span style=\"color: #ff9602;\">Error encountered:</span> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"profileError") : depth0)) != null ? lookupProperty(stack1,"timestamp") : stack1), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"authLink") : depth0)) != null ? lookupProperty(stack1,"msg") : stack1), depth0))
    + "</div>\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"authLink") : depth0)) != null ? lookupProperty(stack1,"msg2") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":44,"column":89}}})) != null ? stack1 : "")
    + "\n        <div style=\"padding-top: 16px\"><a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"authLink") : depth0)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "\"  target=\"_blank\">LINK</a></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div style=\"margin-top: 8px;\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"authLink") : depth0)) != null ? lookupProperty(stack1,"msg2") : stack1), depth0))
    + "</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5 style=\"color: #ff9602;\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body not-too-big\">\n\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"generalMessages") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"ifcond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"section") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"ifcond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"widgets") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"ifcond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":33,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"profileError") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authLink") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"dialog-button-bar\">\n        <a class=\"oink btn\">Close</a>\n    </div>\n</div>";
},"useData":true});