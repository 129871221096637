<template>
    <div style="display: contents">
        <slot v-bind:entry="entry"/>
    </div>
</template>

<script>

import {getGlossaryEntry} from "@/app/help/tips/tip-utils";

export default {
    props: {
        glossaryId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            entry: null,
        }
    },

    watch: {
        glossaryId() {
            this.loadEntry();
        },
    },

    async created() {
        await this.loadEntry();
    },

    methods: {
        async loadEntry() {
            if (this.glossaryId) {
                this.entry = await getGlossaryEntry(this.glossaryId);
            }
        }
    }
}
</script>