import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)
Vue.config.productionTip = false
Vue.config.ignoredElements = ['be-rpcs-icon']

import './css/bootstrap.css'
import './css/bootstrap-tweaks.css'
import './css/spectrum.css'
import './css/twemoji.css'
import './css/animate.css'
import "./font/roto-kufi-arabic.css"
import "./font/animation.css"
import "./font/icons.css"

import "./app/variables.css"
import "./app/common.css"
import "./app/common-light.css"
import "./app/transitions.css"
import "./app/symbols.css"

// this must be loaded before anything else as it attaches itself to Backbone
import 'backbone.marionette'
import 'backbone.modelbinder'
import 'backbone.validation'
import 'backbone.deep-model'

import 'spectrum-colorpicker'

import md5 from 'md5'
window.hex_md5 = md5

import 'datejs'
import Beef from './app/Beef'
import "./app/utils/Metatags.js"
import "./app/utils/Metatags.css"
import "./app/Validation.js"
import "./app/Model.js"
import "./app/View.js"
import "./app/BoundItemView.js"
import "./app/Sync.js"
import "./data/Snoek.js"
import "./data/Grouse.js"
import "./data/Chervil.js"
import "./data/Caper.js"
import "./app/Mash.js"
import "./app/Ajax.js"
import "./app/SettingsDialog.js"
import "./app/VuejsView"
import "./dashboards/widgets/VuejsWidget.js"

import d3tp from 'd3-tip'
d3.tip = d3tp

import "./styling/_index.sass"
import "./app/Permissions.js"

import "./app/help/HelpMenu.handlebars"
import "./app/help/Help.js"
import "./app/help/Help.css"

import "./app/framework/components/InlineSvg.js"

import "./app/framework/pickers/Picker.js"

import "./app/framework/pickers/AutoCompletePicker.js"
import "./app/framework/pickers/AutoCompletePicker.css"
import "./app/framework/pickers/AutoCompletePicker.handlebars"

import "./app/framework/pickers/dynamicpicker/DynamicPickerItem.handlebars"
import "./app/framework/pickers/dynamicpicker/DynamicPicker.handlebars"
import "./app/framework/pickers/dynamicpicker/DynamicPicker.js"
import "./app/framework/pickers/dynamicpicker/DynamicPicker.css"

import "./app/framework/dialogs/Dialog.js"
import "./app/framework/dialogs/Dailog.css"
import "./app/framework/dialogs/Dialog.handlebars"
import "./app/framework/dialogs/TranslationDialog.js"
import "./app/framework/dialogs/TranslationDialog.css"
import "./app/framework/dialogs/TranslationDialog.handlebars"
import "./app/framework/dialogs/WordCloudDialogTooltip.handlebars"
import "./app/framework/notifications/Notifications.js"
import "./app/framework/notifications/Notifications.css"
import "./app/framework/notifications/NotificationItem.css"
import "./app/framework/notifications/NotificationItem.handlebars"

import "./app/framework/ripcase/RipcasePopup.js"
import "./app/framework/ripcase/RipcasePopup.css"
import "./app/framework/ripcase/RipcasePopupTag.handlebars"
import "./app/framework/ripcase/RipcasePopup.handlebars"

import "./app/framework/markdown/Markdown.js"
import "./app/framework/markdown/Markdown.css"
import "./app/framework/markdown/MarkdownEditor.js"
import "./app/framework/markdown/MarkdownEditor.css"
import "./app/framework/markdown/MarkdownEditor.handlebars"

import "./app/framework/spinner/Spinner.js"
import "./app/framework/spinner/Spinner.css"

import "./app/ViewStack.js"
import "./app/ViewStack.css"

import "./app/IntegerPicker.js"
import "./app/IntegerPicker.css"
import "./app/IntegerPicker.handlebars"

import "./app/Empty.js"
import "./app/Empty.handlebars"

import "./app/ErrorPage.js"
import "./app/ErrorPage.handlebars"
import "./app/ErrorPage.css"

import "./app/OfflinePage.js"
import "./app/OfflinePage.handlebars"

import "./app/SuspendedPage.js"
import "./app/SuspendedPage.handlebars"

import "./app/ModelBinder.js"
import "./app/LastUpdated.handlebars"

import "./app/Calendar.js"
import "./app/Calendar.css"
import "./app/Calendar.handlebars"

import "./app/framework/menu/MiniMenu.js"
import "./app/framework/menu/MiniMenu.css"
import "./app/framework/menu/MiniMenu.handlebars"
import "./app/framework/menu/MiniIconMenu.handlebars"

import "./app/Loading.handlebars"

import "./app/framework/tooltips/Tooltip.js"
import "./app/framework/tooltips/Tooltip.css"
import "./app/framework/tooltips/Tooltip.handlebars"

import "./app/popup/token-expire/TokenExpirePopup.js"
import "./app/popup/token-expire/TokenExpirePopup.css"
import "./app/popup/token-expire/TokenExpirePopup.handlebars"

import "./app/popup/support-ticket/SupportTicket.js"
import "./app/popup/support-ticket/SupportTicket.css"
import "./app/popup/support-ticket/SupportTicket.handlebars"
import "./app/popup/support-ticket/SupportTicketMenu.handlebars"

import "./app/framework/feed-notify/FeedNotify.js"
import "./app/framework/feed-notify/FeedNotify.css"
import "./app/framework/feed-notify/FeedNotify.handlebars"

import "./app/Sidebar.css"

import "./app/GenericMsg.js"
import "./app/GenericMsg.css"
import "./app/GenericMsg.handlebars"

import "./notifications/RaceUpsell.js"
import "./notifications/RaceUpsell.handlebars"

import "./app/toplevel/accounts/AccountSelectionPage.js"

import "./app/Pagination.js"
import "./app/Pagination.css"
import "./app/Pagination.handlebars"

import "./app/Pager.js"
import "./app/Pager.css"
import "./app/Pager.handlebars"

import "./app/Header.handlebars"
import "./app/Header.css"

import "./app/TimezonePicker.js"
import "./app/TimezonePicker.css"

import "./data/DeprecatedBeefCache.js"

import "./dashboards/Dashboard.js"
import "./dashboards/Dashboard.css"
import "./dashboards/Dashboard.handlebars"
import "./dashboards/DashboardMenu.handlebars"

import "./dashboards/DashboardNav.js"
import "./dashboards/DashboardNav.css"
import "./dashboards/DashboardNav.handlebars"

import "./dashboards/DashboardSettings.js"
import "./dashboards/DashboardSettings.css"
import "./dashboards/DashboardSettings.handlebars"

import "./dashboards/EditDashboardJson.js"
import "./dashboards/EditDashboardJson.css"
import "./dashboards/EditDashboardJson.handlebars"

import "./dashboards/DashboardCategoryPicker.js"

import "./app/toplevel/dashboards/DashboardRouting.js"

import "./dashboards/Section.js"
import "./dashboards/Section.css"
import "./dashboards/Section.handlebars"
import "./dashboards/SectionMenu.handlebars"

import "./dashboards/SectionList.js"
import "./dashboards/SectionList.css"
import "./dashboards/SectionList.handlebars"

import "./dashboards/SectionSettings.js"
import "./dashboards/SectionSettings.css"
import "./dashboards/SectionSettings.handlebars"

import "./dashboards/widgets/Footnotes.js"
import "./dashboards/widgets/Footnotes.css"
import "./dashboards/widgets/Footnotes.handlebars"

import "./dashboards/widgets/ConstrainedWidgetView.js"

import "./dashboards/WidgetTypeChange.js"
import "./dashboards/WidgetTypeChange.css"
import "./dashboards/WidgetTypeChange.handlebars"

import "./dashboards/widgets/WidgetMenu.handlebars"

import "./dashboards/widgets/Widget.js"
import "./dashboards/widgets/Widget.css"
import "./dashboards/widgets/Widget.handlebars"
import "./dashboards/widgets/UnknownWidget.handlebars"

import "./dashboards/ColourSettings.js"
import "./dashboards/ColourSettings.css"
import "./dashboards/ColourSettings.handlebars"
import "./dashboards/ColourSettingsEditHex.handlebars"

import "./dashboards/widgets/WidgetSettings.js"
import "./dashboards/widgets/WidgetSettings.css"
import "./dashboards/widgets/WidgetSettings.handlebars"

import "./dashboards/widgets/WidgetList.js"
import "./dashboards/widgets/WidgetList.css"
import "./dashboards/widgets/WidgetList.handlebars"

import "./dashboards/widgetpalette/WidgetPalette.js"
import "./dashboards/widgetpalette/WidgetPalette.css"
import "./dashboards/widgetpalette/WidgetPalette.handlebars"
import "./dashboards/widgetpalette/WidgetPaletteDrillDowns.handlebars"

import "./dashboards/widgets/settings/MetricSettings.js"
import "./dashboards/widgets/settings/MetricSettings.css"
import "./dashboards/widgets/settings/MetricSettings.handlebars"

import "./dashboards/charts/FromTooltip.handlebars"
import "./dashboards/charts/ChartViewHelper.js"
import "./dashboards/charts/ChartView.js"
import "./dashboards/charts/ChartItemView.js"
import "./dashboards/charts/ChartItemView.css"
import "./dashboards/charts/ChartItemView.handlebars"
import "./dashboards/charts/FilteredTextView.js"
import "./dashboards/charts/FilteredTextView.css"

import "./dashboards/filter/popup/Popup.js"
import "./dashboards/filter/popup/Popup.css"
import "./dashboards/filter/popup/Popup.handlebars"

import "./dashboards/filter/pickers/media/MediaPicker.js"
import "./dashboards/filter/pickers/media/MediaPicker.css"
import "./dashboards/filter/pickers/media/MediaPicker.handlebars"

import "./dashboards/filter/pickers/visibility/VisibilityPicker.js"
import "./dashboards/filter/pickers/visibility/VisibilityPicker.css"
import "./dashboards/filter/pickers/visibility/VisibilityPicker.handlebars"

import "./dashboards/filter/pickers/crowdjobtype/CrowdJobTypePicker.js"
import "./dashboards/filter/pickers/crowdjobtype/CrowdJobTypePicker.css"
import "./dashboards/filter/pickers/crowdjobtype/CrowdJobTypePicker.handlebars"

import "./dashboards/filter/pickers/gender/GenderPicker.js"
import "./dashboards/filter/pickers/gender/GenderPicker.css"
import "./dashboards/filter/pickers/gender/GenderPicker.handlebars"

import "./dashboards/filter/pickers/published/PublishedPicker.js"
import "./dashboards/filter/pickers/published/PublishedPicker.css"
import "./dashboards/filter/pickers/published/PublishedPicker.handlebars"

import "./dashboards/filter/pickers/credibility/CredibilityPicker.js"
import "./dashboards/filter/pickers/credibility/CredibilityPicker.css"
import "./dashboards/filter/pickers/credibility/CredibilityPicker.handlebars"

import "./dashboards/filter/pickers/engagement/EngagementPicker.js"
import "./dashboards/filter/pickers/reach/ReachPicker.js"
import "./dashboards/filter/pickers/responsetime/InteractionResponseTimePicker.js"
import "./dashboards/filter/pickers/responsetime/InteractionFirstResponseTimePicker.js"
import "./dashboards/filter/pickers/responsetime/InteractionFollowUpResponseTimePicker.js"
import "./dashboards/filter/pickers/responsetime/InteractionResponseTimeWhPicker.js"
import "./dashboards/filter/pickers/responsetime/InteractionFirstResponseTimeWhPicker.js"
import "./dashboards/filter/pickers/responsetime/InteractionFollowUpResponseTimeWhPicker.js"
import "./dashboards/filter/pickers/ots/OtsPicker.js"

import "./dashboards/filter/pickers/sharing/MentionIdTooltip.js"
import "./dashboards/filter/pickers/sharing/MentionIdTooltip.css"
import "./dashboards/filter/pickers/sharing/MentionPicker.js"
import "./dashboards/filter/pickers/sharing/MentionPicker.css"
import "./dashboards/filter/pickers/sharing/MentionPicker.handlebars"
import "./dashboards/filter/pickers/sharing/ReplyCountPicker.js"
import "./dashboards/filter/pickers/sharing/ReshareCountPicker.js"
import "./dashboards/filter/pickers/sharing/ReshareOfReplyToPicker.js"
import "./dashboards/filter/pickers/sharing/ReshareOfReplyToPicker.css"
import "./dashboards/filter/pickers/sharing/ReshareOfPicker.js"
import "./dashboards/filter/pickers/sharing/ReshareOfPicker.handlebars"
import "./dashboards/filter/pickers/sharing/ReplyToPicker.js"
import "./dashboards/filter/pickers/sharing/ReplyToPicker.handlebars"

import "./dashboards/filter/pickers/sentiment/SentimentPicker.js"
import "./dashboards/filter/pickers/sentiment/SentimentPicker.css"
import "./dashboards/filter/pickers/sentiment/SentimentPicker.handlebars"

import "./dashboards/filter/pickers/rpcspicker/RpcsPicker.js"
import "./dashboards/filter/pickers/rpcspicker/RpcsPicker.css"
import "./dashboards/filter/pickers/rpcspicker/RpcsPicker.handlebars"

import "./dashboards/filter/pickers/socialnetwork/SocialNetworkPicker.js"
import "./dashboards/filter/pickers/socialnetwork/SocialNetworkPicker.css"
import "./dashboards/filter/pickers/socialnetwork/SocialNetworkPicker.handlebars"

import "./dashboards/filter/pickers/pastdate/PastDatePicker.js"
import "./dashboards/filter/pickers/pastdate/PastDatePicker.css"
import "./dashboards/filter/pickers/pastdate/PastDatePicker.handlebars"

import "./dashboards/filter/pickers/language/LanguagePicker.js"
import "./dashboards/filter/pickers/feed/FeedPicker.js"
import "./dashboards/filter/pickers/tag/TagPicker.js"
import "./dashboards/filter/pickers/tag/TagPicker.css"
import "./dashboards/filter/pickers/tag/TagPickerTooltip.handlebars"
import "./dashboards/filter/pickers/tag/TagPickerCxTooltip.handlebars"
import "./dashboards/filter/pickers/tag/TagPickerRiskTooltip.handlebars"
import "./dashboards/filter/pickers/tag/TagPickerRpcsTooltip.handlebars"
import "./dashboards/filter/pickers/tag/TagPickerNoneAboveTooltip.handlebars"
import "./dashboards/filter/pickers/site/SitePicker.js"

import "./dashboards/filter/pickers/incex/IncExPicker.js"
import "./dashboards/filter/pickers/incex/IncExPicker.css"

import "./dashboards/filter/pickers/sharing/IncExConversationPicker.js"
import "./dashboards/filter/pickers/sharing/IncExConversationPicker.handlebars"

import "./dashboards/filter/pickers/interaction/IncExInteractionPicker.js"
import "./dashboards/filter/pickers/interaction/IncExInteractionPicker.handlebars"
import "./dashboards/filter/pickers/interaction/InteractionHasResponsePicker.js"
import "./dashboards/filter/pickers/interaction/InteractionHasResponsePicker.handlebars"

import "./dashboards/filter/pickers/author/AuthorNamePicker.js"
import "./dashboards/filter/pickers/author/IncExAuthorNamePicker.js"
import "./dashboards/filter/pickers/author/IncExAuthorNamePicker.handlebars"

import "./dashboards/filter/pickers/author/AuthorIdPicker.js"
import "./dashboards/filter/pickers/author/AuthorIdPicker.css"
import "./dashboards/filter/pickers/author/AuthorIdPicker.handlebars"
import "./dashboards/filter/pickers/author/IncExAuthorIdPicker.js"
import "./dashboards/filter/pickers/author/IncExAuthorIdPicker.handlebars"

import "./dashboards/filter/pickers/contains/ContainsPicker.js"
import "./dashboards/filter/pickers/contains/ContainsPicker.css"

import "./dashboards/filter/pickers/location/LocationPicker.js"
import "./dashboards/filter/pickers/location/LocationPicker.css"
import "./dashboards/filter/pickers/location/LocationPicker.handlebars"

import "./dashboards/filter/pickers/incexlanguage/IncExLanguagePicker.js"
import "./dashboards/filter/pickers/incexlanguage/IncExLanguagePicker.handlebars"

import "./dashboards/filter/pickers/incexlocation/IncExLocationPicker.js"
import "./dashboards/filter/pickers/incexlocation/IncExLocationPicker.handlebars"

import "./dashboards/filter/pickers/incextag/IncExTagPicker.js"
import "./dashboards/filter/pickers/incextag/IncExTagPicker.handlebars"

import "./dashboards/filter/pickers/incextopic/IncExTopicPicker.js"
import "./dashboards/filter/pickers/incextopic/IncExTopicPicker.handlebars"

import "./dashboards/filter/pickers/incexsegment/IncExSegmentPicker.js"
import "./dashboards/filter/pickers/incexsegment/IncExSegmentPicker.handlebars"

import "./dashboards/filter/pickers/link/LinkPicker.js"
import "./dashboards/filter/pickers/link/LinkPicker.css"
import "./dashboards/filter/pickers/link/LinkPicker.handlebars"

import "./dashboards/filter/pickers/brand/BrandPicker.js"
import "./dashboards/filter/pickers/brand/BrandPicker.css"
import "./dashboards/filter/pickers/brand/BrandPicker.handlebars"
import "./dashboards/filter/pickers/brand/BrandPickerBody.handlebars"
import "./dashboards/filter/pickers/brand/BrandPickerTree.handlebars"

import "./dashboards/filter/pickers/topicview/TopicViewPicker.js"
import "./dashboards/filter/pickers/topicview/TopicViewPicker.css"
import "./dashboards/filter/pickers/topicview/TopicViewPicker.handlebars"

import "./dashboards/filter/FilterParser.js"
import "./dashboards/filter/AttributeParser.js"
import "./dashboards/filter/BeefBasicFilter.js"
import "./dashboards/filter/BasicFilter.css"
import "./dashboards/filter/BasicFilter.handlebars"
import "./dashboards/filter/V4Issues.handlebars"
import "./dashboards/filter/AdvancedFilter.js"
import "./dashboards/filter/AdvancedFilter.css"
import "./dashboards/filter/AdvancedFilter.handlebars"
import "./dashboards/filter/Filter.js"
import "./dashboards/filter/Filter.css"
import "./dashboards/filter/Filter.handlebars"
import "./dashboards/filter/Generator.js"

import "./dashboards/filter/TextSearch.js"
import "./dashboards/filter/TextSearch.css"
import "./dashboards/filter/TextSearch.handlebars"
import "./dashboards/filter/TextSearchItemView.handlebars"

import "./dashboards/filter/PhraseGen.js"
import "./dashboards/filter/PhraseGen.css"
import "./dashboards/filter/PhraseGen.handlebars"
import "./dashboards/filter/PhraseGenDialog.handlebars"

import "./mentions/OrderBy.js"

import "./mentions/MentionPanel.js"
import "./mentions/MentionPanel.css"
import "./mentions/MentionPanel.handlebars"

import "./mentions/MentionWords.js"
import "./mentions/MentionWords.css"
import "./mentions/MentionWords.handlebars"

import "./mentions/MentionsBrandMenu.js"
import "./mentions/MentionsBrandMenu.css"
import "./mentions/MentionsBrandMenu.handlebars"
import "./mentions/MentionsBrandTree.handlebars"

import "./mentions/MentionItem.js"
import "./mentions/MentionItem.css"
import "./mentions/MentionItemMedia.handlebars"
import "./mentions/MentionItem.handlebars"
import "./mentions/MentionItemRipcase.handlebars"
import "./mentions/MentionItemSentimentTip.handlebars"
import "./mentions/MentionItemBall.handlebars"
import "./mentions/MentionItemAuthorOnly.handlebars"
import "./mentions/InteractionSuccess.handlebars"

import "./mentions/EditMention.js"
import "./mentions/EditMention.css"
import "./mentions/EditMention.handlebars"
import "./mentions/EditMentionMenu.handlebars"
import "./mentions/EditMentionSentiment.handlebars"

import "./mentions/SendToCrowd.js"
import "./mentions/SendToCrowd.css"
import "./mentions/SendToCrowd.handlebars"

import "./mentions/compare-predicted/ComparePredictedD3.js"
import "./mentions/compare-predicted/ComparePredicted.js"
import "./mentions/compare-predicted/ComparePredicted.css"
import "./mentions/compare-predicted/ComparePredicted.handlebars"

import "./mentions/MentionGraph.js"
import "./mentions/MentionGraph.css"
import "./mentions/MentionGraph.handlebars"

import "./mentions/EditSelectedMentions.js"
import "./mentions/EditSelectedMentions.css"
import "./mentions/EditSelectedMentions.handlebars"

import "./mentions/Mentions.css"
import "./mentions/MentionList.js"
import "./conversation/Conversation.js"

import "./conversation/EmailPicker.js"
import "./conversation/InteractDialog.handlebars"
import "./conversation/InteractDialog.js"
import "./conversation/InteractDialog.css"

import "./conversation/SimpleProfileActionPopup.handlebars"
import "./conversation/SimpleProfileActionPopup.css"
import "./conversation/SimpleProfileActionPopup.js"

import "./conversation/MentionConversation.handlebars"
import "./conversation/MentionConversationMenu.handlebars"
import "./conversation/MentionConversation.css"
import "./conversation/MentionConversation.js"

import "./authorsV4/AuthorsSectionV4.js"
import "./authorsV4/AuthorsSectionV4.css"
import "./authorsV4/AuthorsSectionV4.handlebars"

import "./authorsV4/AuthorOptionsV4.handlebars"

import "./authorsV4/AuthorListV4.js"
import "./authorsV4/AuthorListV4.css"
import "./authorsV4/AuthorListV4.handlebars"

import "./authorsV4/AuthorItemV4.js"
import "./authorsV4/AuthorItemV4.css"
import "./authorsV4/AuthorItemV4.handlebars"

import "./dashboards/widgets/composite/CompositeWidget.js"
import "./dashboards/widgets/composite/CompositeWidget.css"
import "./dashboards/widgets/composite/CompositeWidget.handlebars"

import "./dashboards/filter/pickers/currency/CurrencyPicker.js"
import "./dashboards/filter/pickers/font/FontPicker.js"
import "./dashboards/widgets/topics/Helpers.js"
import "./dashboards/widgets/comment/Comment.js"
import "./dashboards/widgets/comment/Comment.css"
import "./dashboards/widgets/comment/Comment.handlebars"
import "./dashboards/widgets/Text-Value.handlebars"
import "./dashboards/widgets/filterdisplay/FilterDisplay.js"
import "./dashboards/widgets/filterdisplay/FilterDisplay.css"
import "./dashboards/widgets/filterdisplay/FilterDisplay.handlebars"
import "./dashboards/widgets/nomenclature/NomenclatureWidget.js"
import "./dashboards/widgets/examplementions/ExampleMentions.js"
import "./dashboards/widgets/examplementions/ExampleMentions.css"
import "./dashboards/widgets/examplementions/ExampleMentions.handlebars"
import "./dashboards/widgets/examplementions/ExampleMentionsSettings.handlebars"

import "./dashboards/widgets/topics/TopicWheel.js"
import "./dashboards/widgets/topics/TopicWheelD3.js"
import "./dashboards/widgets/topics/TopicWheel.css"
import "./dashboards/widgets/topics/TopicWheelMenu.handlebars"
import "./dashboards/widgets/topics/TopicWheelSettings.handlebars"
import "./dashboards/widgets/topics/ChordD3.js"
import "./dashboards/widgets/topics/Chord.css"
import "./dashboards/widgets/topics/TopicReferenceWidget.js"

import "./dashboards/widgets/response/ResponseSankey.js"
import "./dashboards/widgets/response/ResponseTable.js"
import "./dashboards/widgets/venn/Venn"

import "./dashboards/widgets/fantasticchart/FantasticGeometry.js"
import "./dashboards/widgets/fantasticchart/FantasticChart.js"
import "./dashboards/widgets/fantasticchart/Upgrade.js"
import "./dashboards/widgets/fantasticchart/FantasticChart.css"
import "./dashboards/widgets/fantasticchart/FantasticTip.handlebars"
import "./dashboards/widgets/fantasticchart/FantasticCountTip.handlebars"
import "./dashboards/widgets/fantasticchart/FantasticCurrencyTip.handlebars"
import "./dashboards/widgets/fantasticchart/FantasticGeometrySettings.js"

import "./dashboards/StatFields.js"

import "./dashboards/widgets/stats/Stats.js"
import "./dashboards/widgets/stats/Stats.css"
import "./dashboards/widgets/stats/Stats.handlebars"
import "./dashboards/widgets/stats/StatsRow.handlebars"
import "./dashboards/widgets/stats/StatsSettings.handlebars"

import "./dashboards/widgets/stats/BrandMatrix.js"
import "./dashboards/widgets/stats/BrandMatrix.css"
import "./dashboards/widgets/stats/BrandMatrix.handlebars"
import "./dashboards/widgets/stats/BrandMatrixCell.handlebars"
import "./dashboards/widgets/stats/BrandMatrixFooter.handlebars"
import "./dashboards/widgets/stats/BrandMatrixSettings.handlebars"

import "./dashboards/widgets/comparisonmatrix/ComparisonMatrix.js"
import "./dashboards/widgets/comparisonmatrix/ComparisonMatrix.css"
import "./dashboards/widgets/comparisonmatrix/ComparisonMatrixSettings.handlebars"

import "./dashboards/widgets/wordcloud/WordCloud.js"
import "./dashboards/widgets/wordcloud/WordCloud.css"
import "./dashboards/widgets/wordcloud/WordCloudSettings.handlebars"
import "./dashboards/widgets/wordcloud/WordCloudTooltip.handlebars"

import "./dashboards/widgets/maps/MapUtils.js"
import "./dashboards/widgets/maps/ReportOnMenu.handlebars"

import "./dashboards/widgets/maps/WorldMap.css"
import "./dashboards/widgets/maps/WorldMap.js"
import "./dashboards/widgets/maps/WorldMap.handlebars"
import "./dashboards/widgets/maps/WorldMapSettings.handlebars"

import "./dashboards/widgets/selectors/SelectorWidget.css"
import "./dashboards/widgets/selectors/SelectorWidget.js"
import "./dashboards/widgets/selectors/SelectorWidget.handlebars"
import "./dashboards/widgets/selectors/BrandSelector.js"
import "./dashboards/widgets/selectors/BrandSelector.css"
import "./dashboards/widgets/selectors/SubBrandSelector.js"
import "./dashboards/widgets/selectors/CategorySelector.js"
import "./dashboards/widgets/selectors/GenderSelector.js"
import "./dashboards/widgets/selectors/CountSelector.js"
import "./dashboards/widgets/selectors/CountrySelector.js"
import "./dashboards/widgets/selectors/LanguageSelector.js"
import "./dashboards/widgets/selectors/SourceSelector.js"
import "./dashboards/widgets/selectors/SentimentSelector.js"
import "./dashboards/widgets/selectors/SentimentSelector.css"
import "./dashboards/widgets/selectors/SentimentSelector.handlebars"
import "./dashboards/widgets/selectors/DateSelector.js"
import "./dashboards/widgets/selectors/DateSelectorSettings.handlebars"
import "./dashboards/widgets/selectors/CredibilitySelector.js"
import "./dashboards/widgets/selectors/TagSelector.js"
import "./dashboards/widgets/selectors/TagSelectorSettings.handlebars"
import "./dashboards/widgets/selectors/TopicSelector.js"
import "./dashboards/widgets/selectors/TopicViewSelector.js"
import "./dashboards/widgets/selectors/SegmentSelector.js"
import "./dashboards/widgets/selectors/SegmentSelectorSettings.handlebars"
import "./dashboards/widgets/selectors/VisibilitySelector.js"
import "./dashboards/widgets/selectors/VisibilitySelector.handlebars"
import "./dashboards/widgets/selectors/CxSelector.js"
import "./dashboards/widgets/selectors/ShowWhatSelector.js"


import "./dashboards/filter/pickers/profile/ProfilePicker.js"
import "./dashboards/filter/pickers/profile/ProfilePicker.css"
import "./dashboards/filter/pickers/profile/ProfilePicker.handlebars"
import "./dashboards/widgets/onlineprofile/visual/VisualOnlineProfile.js"
import "./dashboards/widgets/onlineprofile/visual/VisualOnlineProfile.css"
import "./dashboards/widgets/onlineprofile/visual/VisualOnlineProfileTooltip.handlebars"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfileItem.js"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfileItem.css"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfileItem.handlebars"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfileItemWidth1.handlebars"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfileItemMultiple.handlebars"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfileList.js"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfileList.handlebars"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfile.js"
import "./dashboards/widgets/onlineprofile/text/TextOnlineProfile.handlebars"
import "./dashboards/widgets/onlineprofile/OnlineProfile.js"

import "./dashboards/filter/pickers/incexprofile/IncExProfilePicker.js"
import "./dashboards/filter/pickers/incexprofile/IncExProfilePicker.handlebars"

import "./dashboards/WidgetRegistry.js"

import "./settings/settings.css"
import "./settings/profile/AccountProfile.css"
import "./settings/profile/ClientEdit.handlebars"
import "./settings/profile/Client.js"
import "./settings/profile/AccountManagement.css"

import "./settings/profile/CrowdHistory.css"
import "./settings/profile/CrowdHistory.js"
import "./settings/profile/CrowdHistory.handlebars"

import "./settings/users/AccountUsers.js"
import "./settings/users/EditUser.js"
import "./settings/users/AccountUsers.css"
import "./settings/users/AccountUsers.handlebars"
import "./settings/users/UserItemView.handlebars"
import "./settings/users/UserListView.handlebars"
import "./settings/users/UserView.handlebars"
import "./settings/users/UserEdit.handlebars"
import "./settings/users/UserEmailEdit.handlebars"
import "./settings/users/UserDetailsEdit.handlebars"

import "./i18n/Locales.css"
import "./i18n/Locales.handlebars"

import "./setup/brands/RootBrandSetup.js"
import "./setup/brands/RootBrandSetup.css"
import "./setup/brands/RootBrandSetup.handlebars"
import "./setup/brands/NoRootBrands.handlebars"
import "./setup/brands/BrandTree.handlebars"
import "./setup/brands/Phrase.handlebars"
import "./setup/brands/AddingBrand.handlebars"
import "./setup/brands/AddingPhrase.handlebars"
import "./setup/brands/BrandPhraseVolume.handlebars"

import "./setup/brands/EditBrandTreeJson.js"
import "./setup/brands/EditBrandTreeJson.css"
import "./setup/brands/EditBrandTreeJson.handlebars"
import "./setup/brands/EditBrandTreeJsonResults.handlebars"

import "./setup/brands/RootBrandFilter.js"
import "./setup/brands/RootBrandFilter.css"
import "./setup/brands/RootBrandFilter.handlebars"

import "./setup/brands/EditBrand.js"
import "./setup/brands/EditBrand.css"
import "./setup/brands/EditBrand.handlebars"
import "./setup/brands/EditImportedBrand.js"
import "./setup/brands/EditImportedBrand.css"
import "./setup/brands/EditImportedBrand.handlebars"

import "./setup/brands/EditBrandDescription.js"
import "./setup/brands/EditBrandDescription.handlebars"

import "./setup/brands/SelectTopicTree.js"
import "./setup/brands/SelectTopicTree.handlebars"

import "./setup/brands/EditBrandTopicTree.js"
import "./setup/brands/EditBrandTopicTree.css"
import "./setup/brands/EditBrandTopicTree.handlebars"

import "./setup/brands/EditBrandSegments.js"
import "./setup/brands/EditBrandSegments.css"
import "./setup/brands/EditBrandSegments.handlebars"

import "./setup/brands/LimitReached.js"
import "./setup/brands/LimitReached.css"
import "./setup/brands/LimitReached.handlebars"

import "./setup/brands/LimitBrandDepth.js"
import "./setup/brands/LimitBrandDepth.css"
import "./setup/brands/LimitBrandDepth.handlebars"

import "./setup/brands/PhraseTermPicker.js"

import "./setup/brands/PhraseGoodness.js"
import "./setup/brands/PhraseGoodness.css"
import "./setup/brands/PhraseGoodness.handlebars"

import "./setup/brands/PhraseTune.js"
import "./setup/brands/PhraseTune.css"
import "./setup/brands/PhraseTune.handlebars"

import "./setup/brands/EditPhrase.js"
import "./setup/brands/EditPhrase.css"
import "./setup/brands/EditPhrase.handlebars"

import "./setup/brands/GeneratePhrases.js"
import "./setup/brands/GeneratePhrases.css"
import "./setup/brands/GeneratePhrases.handlebars"
import "./setup/brands/GeneratePhrasesTable.handlebars"

import "./setup/brands/MovePhrase.js"
import "./setup/brands/MovePhrase.css"
import "./setup/brands/MovePhrase.handlebars"

import "./setup/brands/MoveBrand.js"
import "./setup/brands/MoveBrand.css"
import "./setup/brands/MoveBrand.handlebars"

import "./setup/brands/ImportBrand.js"
import "./setup/brands/ImportBrand.css"
import "./setup/brands/ImportBrand.handlebars"
import "./setup/brands/ImportBrandList.handlebars"

import "./setup/brands/VolumeWarningHelp.js"
import "./setup/brands/VolumeWarningHelp.css"
import "./setup/brands/VolumeWarningHelp.handlebars"

import "./setup/rules/CrowdRegionPicker.js"

import "./setup/rules/Attributes.js"
import "./setup/rules/AutoTagsRequired.js"
import "./setup/rules/AutoTagsRequired.handlebars"
import "./setup/rules/AutoTagsRequired.css"
import "./setup/rules/EditAutoTag.handlebars"
import "./setup/rules/MentionPreview.js"
import "./setup/rules/MentionPreview.css"
import "./setup/rules/Attributes.css"
import "./setup/rules/ListView.handlebars"
import "./setup/rules/ItemView.handlebars"
import "./setup/rules/Attributes.handlebars"
import "./setup/rules/ActionEdit.handlebars"
import "./setup/rules/FilterEdit.handlebars"
import "./setup/rules/MentionPreview.handlebars"

import "./setup/rules/EngageTeamPicker.js"
import "./setup/rules/EngageRuleSettings.js"
import "./setup/rules/EngageRuleSettings.css"
import "./setup/rules/EngageRuleSettings.handlebars"

import "./setup/topics/TopicsSetup.js"
import "./setup/topics/TopicsSetup.css"
import "./setup/topics/TopicsSetup.handlebars"

import "./setup/feeds/FeedsSetup.js"
import "./setup/feeds/FeedsSetup.css"
import "./setup/feeds/FeedsSetup.handlebars"
import "./setup/feeds/ListView.handlebars"
import "./setup/feeds/ItemView.handlebars"
import "./setup/feeds/ItemEdit.handlebars"

import "./setup/crowd/CrowdSetup.js"
import "./setup/crowd/CrowdSetup.css"
import "./setup/crowd/CrowdSetup.handlebars"

import "./setup/crowd/CrowdSettings.js"
import "./setup/crowd/CrowdSettings.css"
import "./setup/crowd/CrowdSettings.handlebars"
import "./setup/crowd/CrowdSettingsLegacy.handlebars"
import "./setup/crowd/CrowdSettingsPayPerBrand.handlebars"

import "./setup/crowd/EditBrandCrowdQuota.js"
import "./setup/crowd/EditBrandCrowdQuota.css"
import "./setup/crowd/EditBrandCrowdQuota.handlebars"

import "./setup/crowd/EditBrandTier.js"
import "./setup/crowd/EditBrandTier.css"
import "./setup/crowd/EditBrandTier.handlebars"

import "./setup/online/OnlineProfileItem.js"
import "./setup/online/OnlineProfileItem.css"
import "./setup/online/OnlineProfileItem.handlebars"
import "./setup/online/OnlineProfileItemShort.handlebars"
import "./setup/online/OnlineProfileList.js"
import "./setup/online/OnlineProfileList.css"
import "./setup/online/OnlineProfileList.handlebars"
import "./setup/online/OnlineProfileListShort.handlebars"

import "./app/toplevel/setup/SetupRouter.js"
import "./app/toplevel/admin/AdminRouter.js"

import "./historical-search/chart/SearchJobChartTooltip.handlebars"
import "./historical-search/chart/SearchJobChartD3.js"
import "./historical-search/chart/SearchJobChart.js"
import "./historical-search/chart/SearchJobChartLegend.js"
import "./historical-search/chart/SearchJobChartLegend.handlebars"
import "./historical-search/chart/SearchJobChartTitle.js"
import "./historical-search/chart/SearchJobChartTitle.handlebars"
import "./historical-search/chart/SearchJobChart.css"
import "./historical-search/chart/SearchJobChart.handlebars"

import "./historical-search/job/mentions/SampleMentionRegion.handlebars"
import "./historical-search/job/mentions/SampleMentionRegion.js"

import "./historical-search/job/SearchJobSync.js"
import "./historical-search/job/SearchJobButtonRegion.handlebars"
import "./historical-search/job/SearchJob.js"
import "./historical-search/job/SearchJob.css"
import "./historical-search/job/EditSearchJob.css"
import "./historical-search/job/SearchJob.handlebars"
import "./historical-search/job/SearchJobPhrase.handlebars"
import "./historical-search/job/EditSearchJobPhrase.handlebars"

import "./historical-search/job/SearchJobFilter.js"
import "./historical-search/job/SearchJobFilter.handlebars"
import "./historical-search/job/EditSearchJob.js"
import "./historical-search/job/EditSearchJob.handlebars"

import "./historical-search/job-list/SearchJobList.js"
import "./historical-search/job-list/SearchJobList.handlebars"

import "./historical-search/overview/HistoricalSearchOverviewList.js"
import "./historical-search/overview/HistoricalSearchOverviewList.handlebars"
import "./historical-search/overview/HistoricalSearchOverview.js"
import "./historical-search/overview/HistoricalSearchOverview.css"
import "./historical-search/overview/HistoricalSearchOverview.handlebars"

import "./historical-search/popup/HistoricalSearchConfirmation.handlebars"
import "./historical-search/popup/HistoricalSearchPopup.handlebars"
import "./historical-search/popup/HistoricalSearchMessage.js"

import "./historical-search/popup/setup/SetupPopup.css"
import "./historical-search/popup/setup/FilterPopup.js"

import "./historical-search/popup/setup/retrieval/BrandDropdown.handlebars"
import "./historical-search/popup/setup/retrieval/RetrievalSetupPopupFilter.js"
import "./historical-search/popup/setup/retrieval/RetrievalSetupPopupFilter.handlebars"
import "./historical-search/popup/setup/retrieval/RetrievalSetupPopup.js"
import "./historical-search/popup/setup/retrieval/RetrievalSetupPopup.handlebars"

import "./historical-search/popup/setup/sample/SampleSetupPopup.js"
import "./historical-search/popup/setup/sample/SampleSetupPopup.handlebars"

import "./historical-search/popup/setup/brand-phrases/BrandPhrasesPopup.handlebars"
import "./historical-search/popup/setup/brand-phrases/BrandPhrasesPopup.js"
import "./historical-search/popup/setup/brand-phrases/PhrasePreview.handlebars"
import "./historical-search/popup/setup/brand-phrases/PhrasePreview.js"

import "./historical-search/HistoricalSearchSection.js"
import "./historical-search/HistoricalSearchSection.css"
import "./historical-search/HistoricalSearchSection.handlebars"

import "./app/toplevel/explore/ExplorePanelRouting.js"

import "./app/help/HelpRouting.js"

import "./hack/IconList.js"
import "./hack/IconList.css"
import "./hack/IconList.handlebars"

import "./dashboards/widgets/commentaryplus/CommentaryPlus.css"
import "./dashboards/widgets/commentaryplus/inlineTools/CommentaryInlineTool.css"
import "./dashboards/widgets/commentaryplus/blockTools/CommentaryBlockTools.css"

import Services from './store/Services'
import Stores, { deprecatedUserObject, deprecatedBrandsStore } from './store/deprecated/Stores'

import axios from 'axios'
import {init as initMetrics } from "./app/framework/dialogs/metric-picker/availableMetrics";
import {prefetchCache} from "@/data/DeprecatedBeefCache";
import {notifyUser} from "@/app/framework/notifications/Notifications";
import {initFeatures} from "@/app/Features";
import { isCampaignDashboardLink, getCampaignFromLink, getAnchorSectionId } from "@/dashboards/DashboardUtils";
import VuexStore from "@/store/vuex/VuexStore";
import MainMenu from "@/app/main-menu/MainMenu.vue";
import {initialiseVersionCheck} from "@/app/VersionChecker";
import {checkSpikingConsumer, checkSpikingRisk} from "@/app/toplevel/explore/overview/ExploreUtilities";
import {clearTimedCacheOfStaleValues} from "@/data/Cache";

// Ensure that moment timezone data is loaded.
// noinspection ES6UnusedImports
import * as tz from 'moment-timezone';
import {initialiseKeyboardShortcuts} from "@/app/help/Shortcuts";
import {notifyOfWarningsThatMustBeHandled} from "@/store/vuex/dataChecks";
import {redirectToDataEq} from "@/app/utils/Util";
import {isDevEnvironment} from "@/app/utils/Account";
import "@/dashboards/widgets/commentaryplus/customHtmlElements/CommandEl";
import "@/dashboards/widgets/commentaryplus/customHtmlElements/FontSizeEl";
import "@/dashboards/widgets/commentaryplus/customHtmlElements/FontColorEl";

// check if "logged_in_through_facebook" cookie exists
function loggedInThroughFb() {
    try {
        if (isDevEnvironment()) return true;

        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let [k,v] = el.split('=');
            cookie[k.trim()] = v;
        });

        return !!cookie["logged_in_through_facebook"];
    } catch (e) {
        console.error("Error occurred when trying to check if user is logged in through Facebook", e);
        return true;
    }
}

document.fonts.ready.then(function() {
    let href = window.location.href;
    let pathname = window.location.pathname;
    const pathItems = pathname.split('/');
    const code = pathItems.length >= 2 ? pathItems[1] : null;

    let cfg = { params: { code, href }}

    axios.get("/api/startup-options", cfg).then(async res => {
        try {
            document.getElementById('loading-logo').classList.add("short-animated", "fadeOut")
            document.getElementById('loading-animation-container').classList.add("short-animated", "fadeOut", "delay-400")
        } catch (e) {
            console.warn(e)
        }

        const startupOptions = res.data;

        Services(startupOptions);
        Stores(startupOptions);
        await VuexStore.commit('dashboards/setTok', startupOptions.tok);
        await VuexStore.commit('dashboards/setAsEmail', startupOptions.asEmail);

        initFeatures();
        redirectToDataEq(startupOptions);
        initMetrics().catch(e => console.error(e));


        let requireFacebookLogin = false;
        let loggedIntoFacebook = false;

        /**
         * If user is staff, has a Facebook account, and they within the "dailyFbLoginUsers" list,
         * We perform a check to see if the user has logged in through Facebook. This will happen every day.
         */

        // check if the user is staff and if they have a Facebook account
        if ((deprecatedUserObject && deprecatedUserObject.admin) && startupOptions.user.hasFacebookAccount) {
            requireFacebookLogin = true;

            loggedIntoFacebook = loggedInThroughFb();

            if (!loggedIntoFacebook) {
                let redirectTo = encodeURIComponent(window.location.pathname);

                if (isCampaignDashboardLink()) {
                    redirectTo = `${window.location.pathname}?campaign=${getCampaignFromLink()}%23section-content-${getAnchorSectionId()}`;
                }

                // redirect user to facebook login if they are not logged in
                window.location = "/api/fb-login-redirect?redirectTo=" + redirectTo;
            }
        }

        if (!requireFacebookLogin || loggedIntoFacebook) {
            if (window.location.pathname === "/") {
                if (startupOptions.defaultAccount) {
                    window.location = "/" + startupOptions.defaultAccount;
                    return;
                }
                history.replaceState(null, null, "/accounts")
            }

            await VuexStore.dispatch('refreshBrands', true);
            Beef.start(startupOptions);


            initialiseKeyboardShortcuts().catch(e => console.error(e));
            prefetchCache();
            initialiseVersionCheck();

            if (!startupOptions.onlyShowDashboardId) {
                const element = document.getElementById('navbar-vue');
                const ComponentClass = Vue.extend(MainMenu);
                const instance = new ComponentClass({propsData: {}});

                instance.$mount(element);
            }

            deprecatedBrandsStore.refresh(false);
            await VuexStore.dispatch('refreshUser', true); // Ensure we get a full set of user data from mash.

            // Check to see if this account is receiving new mentions.
            if (startupOptions.account && startupOptions.account.code && !startupOptions.account.findNewMentions) {
                try {
                    const seen = !!sessionStorage.getItem(`dataeq:${startupOptions.account.code}:data-warning-seen`);

                    if (!seen) {
                        const notification = notifyUser({
                            message: "This account is <strong>not collecting mentions</strong>",
                            isEscapedHtml: true,
                            noDismiss: true,
                            icon: '<i class="symbol-warning"></i>',
                            action: {
                                method: () => {
                                    notification.close();
                                    sessionStorage.setItem(`dataeq:${startupOptions.account.code}:data-warning-seen`, '1')
                                },
                                name: "Dismiss"
                            }
                        })
                    }
                } catch (e) {
                    console.warn(e)
                }
            }

            setTimeout(async () => {
                await checkSpikingRisk();
                await checkSpikingConsumer();
                await notifyOfWarningsThatMustBeHandled();
            }, 10000 );
            setTimeout(() => clearTimedCacheOfStaleValues(), 20000);

        }
    }).catch(e => {
        let res = e.response;
        if (res && res.status === 401) {
            if (isCampaignDashboardLink()) {
                window.location = `/api/login${ window.location.pathname !== '/' ? `?redirectTo=${window.location.pathname}?campaign=${getCampaignFromLink()}%23section-content-${getAnchorSectionId()}`  : ''}`
            } else {
                window.location = `/api/login${ window.location.pathname !== '/' ? '?redirectTo=' + window.location.pathname : ''}`
            }
        } else {
            // If the error is because local storage / session storage is full,
            // make sure we recover.
            if (e.name === "QuotaExceededError") {
                localStorage.clear();
                sessionStorage.clear();
            }

            console.error("Error loading analyse:", e)
            let model = { message: e.message, status: '?' }
            if (res) {
                model.status = res.status
                if (res.data && res.data.error) model.message = res.data.error
            }
            $('#content-region').html(require('@/app/ErrorPage.handlebars')(model))
        }
    }).finally(() => {
        setTimeout(() => {
            document.getElementById('loading-apparatus').remove()
        }, 1000)
    })
})
