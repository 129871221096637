import { getDeprecatedDefaultBrand as utilDefaultBrand } from "../../../app/utils/Util";
import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import {fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import {currentAccountCode} from "@/app/utils/Account";
import {getBrand, getRootBrands} from "@/app/utils/Brands";

/**
 * Drill down by adding/removing brands to the filter.
 */
Beef.module("Widget.BrandSelector").addInitializer(function(startupOptions) {

    var thisModule = this;

    this.type = {
        name:           "Brands",
        description:    "Interactively filter by brand",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.createDefaultWidget = function(id) {
        var ans = {type: "BrandSelector", caption: "Brands", 'colour-index': 0, height: 4, width: 2};
        ans.id = id;
        return ans;
    };

    /**
     * Note that SubBrandSelector.js extends this View so be careful.
     */
    this.View = Beef.Widget.SelectorWidget.View.extend({

        filterAttribute: "brand",

        modelEvents: {
            "change:showArchived": "refresh"
        },

        initialize: function(){
            this.listenTo(this.model.getSectionModel(), "change:filter", this.refreshIfFilterChanged, this);
            Beef.Widget.SelectorWidget.View.prototype.initialize.apply(this, arguments);
        },

        refreshIfFilterChanged: function(data) {
            var f = this.model.getSectionModel().get('filter');
            if (f != this._lastFilter) this.refresh();
        },

        refresh: function() {
            this.fetchBrands(function(data) {
                // if the filter references brands then keep only the matching root brands
                var f = this.model.getSectionModel().get('filter'), i;
                this._lastFilter = f;
                var brands = thisModule.extractRootBrandsFromFilter(f, data.tree, data.map);

                if (!this.model.get('showArchived')) {
                    brands = brands.filter(b => !b.archived);
                }

                // insert a separator brand before the first archived brand (if any)
                for (i = 0; i < brands.length; i++) {
                    if (brands[i].archived) {
                        brands.splice(i, 0, {name: "Archived"});
                        break;
                    }
                }

                this.text(brands);

                // make sure our currently selected brand(s) are still valid
                var ifm = this.model.getInteractiveFilterModel();
                var current = ifm.get('brand');
                if (current) {
                    var sel = current.split(' ');
                    var keep = [];
                    for (i = 0; i < sel.length; i++) {
                        var id = sel[i];
                        for (var j = 0; j < brands.length; j++) {
                            if (brands[j].id == id) {
                                keep.push(id);
                                break;
                            }
                        }
                    }
                    if (keep.length == 0) this.clearFilterAttribute();
                    else if (keep.length < sel.length) ifm.set('brand', keep.join(" "));
                }
            });
        },

        fetchBrands: function(callback) {
            fetchCachedBrands(this, callback.bind(this));
        },

        interactiveFilterModelChanged: function(ifm, value) {
            // the value(s) might be sub-brands so find all the corresponding root brands
            this.fetchBrands(function(data) {
                var roots = thisModule.findRootBrands(value ? value.split(' ') : []);
                Beef.Widget.SelectorWidget.View.prototype.interactiveFilterModelChanged.call(this, ifm, roots.join(" "));
            });
        },

        onValueClicked: function (value) {
            Beef.generalData(currentAccountCode()).set('defaultBrandId', value);
        },

        getTooltipText: function(item) {
            if (item.id) return "Click to see mentions from " + (item.shortName || item.name);
            return "Brands below this point have been archived"
        }
    });

    /**
     * Choose a default brand for this drill down seeing the supplied filter using previously stored brand tree
     * information. This returns null if the account has no brands.
     */
    this.getDefaultBrand = function(filter) {
        var brands = thisModule.extractRootBrandsFromFilter(
            filter, getRootBrands());
        if (brands.length > 0) {
            var id = Beef.generalData(currentAccountCode()).get('defaultBrandId');
            if (!id) {
                var b = utilDefaultBrand(true);
                if (b) id = b.id;
            }
            if (id) for (var i = 0; i < brands.length; i++) if (brands[i].id == id) return brands[i];
            return brands[0];
        }
        return null;
    };

    /**
     * If the filter references brands then return all the root brands involved otherwise return all root brands.
     */
    this.extractRootBrandsFromFilter = function(filter, brandTree) {
        var brands;
        var fb = convertFilterToAttrs(filter).brand;
        if (fb) {
            brands = [];
            var roots = thisModule.findRootBrands(fb.split(' '));
            for (var i = 0; i < roots.length; i++) {
                var b = getBrand(roots[i]);
                if (b) brands.push(b);
            }
        }
        if (!brands || brands.length == 0) brands = brandTree.slice(0);
        brands.sort(function(a, b) {
            var diff = (a.archived ? 1 : 0) - (b.archived ? 1 : 0);
            if (diff) return diff;
            return a.name < b.name ? -1 : a.name > b.name ? +1 : 0;
        });
        return brands;
    };

    /**
     * Convert an array of brand ids into the corresponding root brand ids (strings).
     */
    this.findRootBrands = function(values) {
        var roots = [];
        for (var i = 0; i < values.length; i++) {
            var b = getBrand(values[i]);
            if (b) {
                while (b.parent != null) b = b.parent;
                var id = "" + b.id;
                if (roots.indexOf(id) < 0) roots.push(id);
            }
            roots.sort();
        }
        return roots;
    }

    this.SettingsView = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/selectors/BrandSelectorSettings.handlebars"),

        editAttributes: function() {
            return ['showArchived']
        },


        onFirstRender: function() {

        }
    })
});