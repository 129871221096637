<template>
    <div class="deq-reset">
        <h4>Crowd Usage <staff-only-tag/></h4>
        <loading-message v-if="loading">
            Fetching Crowd statics...
        </loading-message>
        <div v-else-if="data && data.length === 0" class="crowd-history__no-mentions">
            <p class="deq-callout">
                This account has no Crowd history.
            </p>
            <p>
                Remember that we sell mention volumes to clients, and <em>not</em> Crowd usage.
            </p>
        </div>
        <div v-else>
            <p>
                The following usage table is only shown to staff. We do <em>not</em> sell Crowd volumes to clients, only mention volumes.
            </p>
            <p>
                <be-button link @click="showAll = true" v-if="!showAll">Show everything</be-button>
                <slotted-tag v-else @close="showAll = false">Showing everything</slotted-tag>
            </p>
            <table :class="{'crowd-history--show-all': showAll}">
                <thead>
                <tr>
                    <th>What?</th>
                    <th>How?</th>
                    <th class="crowd-history--show-on-everything">By who?</th>
                    <th v-for="month in data" :key="month.date" >
                        <deq-date :date="month.date" format="MMM YYYY"/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <!-- Live -->
                <tr class="crowd-history__everything">
                    <td rowspan="11" class="deq-callout">
                        Ratings
                    </td>
                    <td>
                        Live & Retrosends
                    </td>
                    <td class="crowd-history--show-on-everything deq-align-right">
                        By the whole Crowd
                    </td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getTotal(month, 'ratings')"/>
                    </td>
                </tr>

                <tr>
                    <td rowspan="5">Live sampling</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.byHuman.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.duplicates.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.screened.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.skipped.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getLiveTotal(month, 'ratings')"/>
                    </td>
                </tr>
                <!-- Retrosends -->
                <tr>
                    <td rowspan="5">Retrosends</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.byHuman.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.duplicates.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.screened.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.skipped.ratings"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getRetrosendTotal(month, 'ratings')"/>
                    </td>
                </tr>

                <!-- Topics -->
                <!-- Live -->
                <tr class="crowd-history__everything">
                    <td rowspan="11" class="deq-callout">
                        Topics
                    </td>
                    <td>
                        Live & Retrosends
                    </td>
                    <td class="crowd-history--show-on-everything deq-align-right">
                        By the whole Crowd
                    </td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getTotal(month, 'topics')"/>
                    </td>
                </tr>

                <tr>
                    <td rowspan="5">Live sampling</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.byHuman.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.duplicates.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.screened.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.skipped.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getLiveTotal(month, 'topics')"/>
                    </td>
                </tr>
                <!-- Retrosends -->
                <tr>
                    <td rowspan="5">Retrosends</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.byHuman.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.duplicates.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.screened.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.skipped.topics"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getRetrosendTotal(month, 'topics')"/>
                    </td>
                </tr>

                <!-- Segments -->
                <!-- Live -->
                <tr class="crowd-history__everything">
                    <td rowspan="11" class="deq-callout">
                        Segments
                    </td>
                    <td>
                        Live & Retrosends
                    </td>
                    <td class="crowd-history--show-on-everything deq-align-right">
                        By the whole Crowd
                    </td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getTotal(month, 'segments')"/>
                    </td>
                </tr>

                <tr>
                    <td rowspan="5">Live sampling</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.byHuman.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.duplicates.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.screened.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.skipped.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getLiveTotal(month, 'segments')"/>
                    </td>
                </tr>
                <!-- Retrosends -->
                <tr>
                    <td rowspan="5">Retrosends</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.byHuman.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.duplicates.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.screened.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.skipped.segments"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getRetrosendTotal(month, 'segments')"/>
                    </td>
                </tr>

                <!-- Classification -->
                <!-- Live -->
                <tr class="crowd-history__everything">
                    <td rowspan="11" class="deq-callout">
                        Classifications
                    </td>
                    <td>
                        Live & Retrosends
                    </td>
                    <td class="crowd-history--show-on-everything deq-align-right">
                        By the whole Crowd
                    </td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getTotal(month, 'classifications')"/>
                    </td>
                </tr>

                <tr>
                    <td rowspan="5">Live sampling</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.byHuman.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.duplicates.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.screened.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.skipped.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getLiveTotal(month, 'classifications')"/>
                    </td>
                </tr>
                <!-- Retrosends -->
                <tr>
                    <td rowspan="5">Retrosends</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.byHuman.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.duplicates.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.screened.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.skipped.classifications"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getRetrosendTotal(month, 'classifications')"/>
                    </td>
                </tr>


                <!-- Other -->
                <!-- Live -->
                <tr class="crowd-history__everything">
                    <td rowspan="11" class="deq-callout">
                        Other types
                    </td>
                    <td>
                        Live & Retrosends
                    </td>
                    <td class="crowd-history--show-on-everything deq-align-right">
                        By the whole Crowd
                    </td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getTotal(month, 'other')"/>
                    </td>
                </tr>

                <tr>
                    <td rowspan="5">Live sampling</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.byHuman.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.duplicates.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.screened.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.live.skipped.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getLiveTotal(month, 'other')"/>
                    </td>
                </tr>
                <!-- Retrosends -->
                <tr>
                    <td rowspan="5">Retrosends</td>
                    <td class="deq-align-right">By a Crowd member</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.byHuman.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By duplicate checker</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.duplicates.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By an AI screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.screened.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right">By a skip screener</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="month.retrosends.skipped.other"/>
                    </td>
                </tr>
                <tr>
                    <td class="deq-align-right deq-callout">Total</td>
                    <td v-for="month in data" :key="month.date">
                        <deq-number :number="getRetrosendTotal(month, 'other')"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";
import {isMashAdmin} from "@/app/Permissions";
import LoadingMessage from "@/components/LoadingMessage";
import {beef} from "@/store/Services";
import StaffOnlyTag from "@/components/tags/StaffOnlyTag";
import DeqDate from "@/components/formatters/DeqDate";
import DeqNumber from "@/components/formatters/DeqNumber";
import BeButton from "@/components/buttons/BeButton";
import SlottedTag from "@/components/tags/SlottedTag";

export default {
    components: {SlottedTag, BeButton, DeqNumber, DeqDate, StaffOnlyTag, LoadingMessage},
    store: VuexStore,

    data() {
        return {
            loading: false,
            data: null,

            showAll: false
        }
    },

    computed: {
        ...mapState(['user', 'account']),
    },

    async mounted() {
        if (!isMashAdmin()) return;

        this.loading = true;

        try {
            const request = await beef.get("/api/crowd?code=" + this.account.code);
            this.data = request.data;
        } catch(e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        getTotal(item, field) {
            return this.getRetrosendTotal(item, field) + this.getLiveTotal(item, field);
        },

        getRetrosendTotal(item, field) {
            return item.retrosends.byHuman[field] +
                item.retrosends.duplicates[field] +
                item.retrosends.screened[field] +
                item.retrosends.skipped[field]
        },

        getLiveTotal(item, field) {
            return item.live.byHuman[field] +
                item.live.duplicates[field] +
                item.live.screened[field] +
                item.live.skipped[field];
        }

    }
}
</script>


<style scoped lang="sass">

.crowd-history--show-on-everything
    display: none

table:not(.crowd-history--show-all)
    tr:not(.crowd-history__everything)
        td
            display: none

table.crowd-history--show-all
    td
        display: revert
    .crowd-history--show-on-everything
        display: revert

</style>