import { MentionQLexer } from '../mentionq/mentionq'
import {findAllNodes, parseFilterString} from "@/dashboards/filter/FilterParser";
import VuexStore from "@/store/vuex/VuexStore";
import {addSidebarHoverFlag, removeSidebarHoverFlag} from "@/components/sidebar/SidebarUtilities";
import {adjustContentHeight} from "@/app/Beef";

Beef.module("MentionsBrandMenu").addInitializer(function(startupOptions) {

    /**
     * Brand tree displayed in the sidebar. Triggers a click event with the brand id as the parameter when a
     * brand is clicked.
     */
    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/mentions/MentionsBrandMenu.handlebars"),

        attributes: { class: "sidebar-list" },

        modelEvents: {
            "change:brandSubset": "render",
            "change:filter": "renderSelectedBrand",
        },

        events: {
            "click a": "click",
            "click a .expand": "expandClick",
            "mouseenter": "mouseenter",
            "mouseleave": "mouseleave"
        },

        onClose() {
            removeSidebarHoverFlag();
        },

        templateHelpers: function() {
            var brands = VuexStore.state.rootBrands;
            var subset = this.model.get('brandSubset');
            var activeBrands = [], subsetBrands = [], otherBrands = [], archivedBrands = [], i, b;
            if (brands) {
                if (subset && subset.length > 1) {
                    for (i = 0; i < brands.length; i++) {
                        b = brands[i];
                        if (subset.indexOf(b.id) >= 0) subsetBrands.push(b);
                        else (b.archived ? archivedBrands : otherBrands).push(b);
                    }
                } else {
                    for (i = 0; i < brands.length; i++) {
                        b = brands[i];
                        (b.archived ? archivedBrands : activeBrands).push(b);
                    }
                }
            }
            return {
                brands: brands,
                activeBrands: activeBrands,
                subsetBrands: subsetBrands,
                otherBrands: otherBrands,
                archivedBrands: archivedBrands
            }
        },

        mouseenter(ev) {
            addSidebarHoverFlag();
        },

        mouseleave(ev) {
            removeSidebarHoverFlag();
        },

        click: function(ev) {
            ev.preventDefault();
            var id = parseInt($(ev.target).closest("div").attr("data-id"));
            if (id) this.trigger("click", id);
        },

        expandClick: function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            var $div = $(ev.target).closest("div");
            $div.toggleClass("expanded");
            if ($div.hasClass("expanded")) adjustContentHeight();
        },

        onRender: function() {
            this.renderSelectedBrand();
            setTimeout( function(){ adjustContentHeight() } );
        },

        renderSelectedBrand: function() {
            var parsedFilter = null;
            try {
                parsedFilter = parseFilterString(this.model.get('filter'));
            } catch(e) {
                console.error("Brand menu unable to parse filter: ", e)
                return;
            }
            var brands = findAllNodes(parsedFilter, function(node) {
                return node.operandType === MentionQLexer.BRAND;
            });

            var brandId = brands.length == 1 ? brands[0].literal : null;

            this.$el.find(".brand").toggleClass("active", false);
            if (brandId) {
                var $div = this.$el.find(".brand[data-id='" + brandId + "']");
                $div.toggleClass("active", true);
                while (true) {
                    $div = $div.parent().closest(".brand");
                    if ($div.length == 0) break;
                    $div.toggleClass("expanded", true);
                }
            }
        }
    });

});
