<template>
    <section class="be-short-mention">
        <author-avatar :mention="mention" class="be-short-mention__avatar"/>
        <author-handle :mention="mention" class="be-short-mention__author"/>
        <old-tooltip :label="publishedOn">
            <deq-date v-if="mention" :date="mention.published"  humanise class="be-short-mention__date"/>
        </old-tooltip>


        <section v-html="mention.combinedHtml"
                 class="be-short-mention__text"
                 ref="content"
                 tabindex="-1"
                 @click="viewMention">
        </section>

        <section class="be-short-mention__metadata animated fadeIn delay-1000">
            <section v-if="mention.sentimentVerified"
                     @mouseenter="showSentimentTooltip"
                     style="margin-right: -3px">
                <i v-if="mention.sentiment > 0" class="positive-sentiment icon-circle"></i>
                <i v-else-if="mention.sentiment < 0" class="negative-sentiment icon-circle"></i>
                <i v-else class="neutral-sentiment icon-circle"></i>
            </section>
            <author-network :mention="mention"/>
        </section>

        <section class="be-short-mention__footer animated fadeIn delay-1000"
                 v-if="rpcs.length">
            <slotted-tag v-for="tag in rpcs"
                         :key="tag.id"
                         class="be-short-mention__rpcs-tag"
                         no-close>
                <rpcs-tag :code="tag.flag"/>
                {{tag.name}}
            </slotted-tag>
        </section>
    </section>
</template>

<script>
import AuthorHandle from "@/components/mentions/AuthorHandle";
import AuthorAvatar from "@/components/mentions/AuthorAvatar";
import DeqDate from "@/components/formatters/DeqDate";
import AuthorNetwork from "@/components/mentions/AuthorNetwork";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import SentimentTooltip from "@/components/mentions/SentimentTooltip";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {profiles} from "@/store/deprecated/Stores";
import {toGrouseLink} from "@/data/Grouse";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import {formatDate, toEmojiHtml} from "@/app/utils/Format";
import SlottedTag from "@/components/tags/SlottedTag";
import RpcsTag from "@/components/tags/RpcsTag";
import {codeToId, isRpcsFlag} from "@/app/utils/Metatags";

export default {
    store: VuexStore,
    components: {OldTooltip, RpcsTag, SlottedTag, AuthorNetwork, DeqDate, AuthorAvatar, AuthorHandle},
    props: {
        mention: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapState(['account']),

        publishedOn() {
            if (!this.mention) return "";
            return `Published on ${formatDate(this.mention.published, 'dddd, MMMM Do, YYYY, HH:mm')}`
        },

        rpcs() {
            if (!this.mention?.tags?.length) return [];
            return this.mention.tags
                .filter(t => t.flag && isRpcsFlag(t.flag))
                .sort((lhs, rhs) => codeToId(lhs.flag) - codeToId(rhs.flag));
        }
    },

    watch: {
        'mention.combinedHtml'() {
            this.$nextTick(() => this.addTargetToLinks());
        }
    },

    mounted() {
        this.addTargetToLinks();
        this.refreshBrands();
        this.refreshTags();
        profiles.refresh(true);
    },

    provide: {
        isBroadcastMedia() {
            return this.mention.uri?.toLowerCase()?.indexOf('.emediamonitor.net') !== -1
                || this.mention.uri?.toLowerCase()?.indexOf('video-cache.brandseye.com') !== -1
                || this.mention.uri?.toLowerCase()?.indexOf('media-player.brandseye.com') !== -1;
        },
        isNovusBroadcastMedia() {
            return this.isBroadcastMedia && this.mention.uri?.toLowerCase()?.indexOf('media-player.brandseye.com') !== -1;
        },
        isPrintMedia() {
            return this.mention.uri?.toLowerCase()?.indexOf('pressdisplay-redirect.brandseye.com') !== -1
                || this.mention.uri?.toLowerCase().startsWith('http://media-cache.brandseye.com');
        },
    },

    methods: {
        ...mapActions(['refreshBrands', 'refreshTags']),

        showSentimentTooltip(ev) {
            showTooltipComponent(ev.target, SentimentTooltip, {mention: this.mention});
        },

        addTargetToLinks() {
            const content = this.$refs.content;
            if (this.mention.combinedHtml && content) {
                const anchors = content.querySelectorAll('a');
                anchors.forEach(a => {
                    // Don't want the edit dialog to open.
                    a.addEventListener('click', (ev) => ev.stopPropagation());
                    if (!a.getAttribute('target')) {
                        a.setAttribute('target', '_blank');
                        a.setAttribute('tooltip', 'Open this link in a new tab');
                    }
                });

                toEmojiHtml(content);
            }
        },

        async viewMention(event) {
            let popup = new Beef.Popup.View({
                closeOnHide: true,
                positions:["center"],
                alwaysMove: true
            });

            await this.refreshTags();
            await this.refreshBrands();
            await profiles.refresh(true);
            const model = new Beef.MentionItem.Model({...this.mention});
            model.accountCode = this.account.code;
            model.url = toGrouseLink('/v4/accounts/' + this.account.code + '/mentions/' + this.mention.id, null, true);

            setTimeout(() => {
                let view = new Beef.EditMention.View({
                    model: model,
                    cache: {},
                    accountCode: this.account.code,
                    noViewConversation: true
                });
                view.on("close", () => popup.hide());
                popup.setTarget($(event.target));
                popup.show(view);
            }, 0);

        }
    }
}
</script>



<style lang="sass">
// Not scoped, otherwise won't apply to classes used inside of v-html.

.be-short-mention
    .be-post-extract, .be-to
        display: none

    a
        color: var(--be-colour-text)
        &:hover
            color: blue

</style>


<style scoped lang="sass">

.be-short-mention
    background: white
    padding: 10px 10px
    color: var(--be-colour-text)
    font-family: var(--widget-font)

    display: grid
    grid-template-columns: 50px 1fr auto auto
    grid-column-gap: 5px
    grid-template-areas: "avatar author metadata date" "avatar text text text" "avater footer footer footer"

.be-short-mention + .be-short-mention
    padding-top: 15px

.be-short-mention__avatar
    grid-area: avatar
    box-sizing: border-box
    padding-top: 2px

.be-short-mention__author
    grid-area: author

.be-short-mention__text
    grid-area: text
    cursor: pointer

.be-short-mention__footer
    grid-area: footer

.be-short-mention__metadata
    grid-area: metadata
    display: flex
    color: grey
    font-size: 0.8rem

.be-short-mention__date
    grid-area: date
    font-size: 0.7rem
    text-align: right

.be-short-mention__rpcs-tag
    color: white
    margin-right: 5px
    margin-bottom: 1px

</style>