<template>
    <div class="user-details-settings">
        <header>
            <h1>Personal Details</h1>
        </header>

        <p>Manage your personal details.</p>

        <staff-info-card class="user-details-settings__info-card" show-when-original-admin>
            <section class="user-details-settings__info-card-content">
                <p>
                    You are currently logged in as an admin user.
                </p>
                <be-button v-if="!nonAdminMode" @click="$emit('non-admin-mode-updated', true)" style="flex-basis: 120px">See as a client</be-button>
                <slotted-tag v-else @close="$emit('non-admin-mode-updated', false)" style="flex-basis: 140px">Viewing as client</slotted-tag>
            </section>
        </staff-info-card>

        <table class="user-details-settings__table">
            <tr>
                <th>Label</th>
                <th>Value</th>
            </tr>
            <tr>
                <td>First Name</td>
                <td><input type="text" name="FIRST_NAME" :value="firstName" @input="updateUserDetails($event)" required :disabled="disabled"/></td>
            </tr>
            <tr>
                <td>Last Name</td>
                <td><input type="text" name="LAST_NAME" :value="lastName" @input="updateUserDetails($event)" required :disabled="disabled"/></td>
            </tr>
            <tr>
                <td>Email</td>
                <td>{{ user.email }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import StaffInfoCard from "@/components/cards/StaffInfoCard";
import BeButton from "@/components/buttons/BeButton";
import SlottedTag from "@/components/tags/SlottedTag";
import {mapState} from "vuex";

export default {
    name: "UserDetailsSettings",
    components: {SlottedTag, BeButton, StaffInfoCard},

    props: {
        firstName: String,
        lastName: String,
        disabled: {
            type: Boolean,
            default: false
        },
        nonAdminMode: Boolean
    },

    computed: {
        ...mapState(['user'])
    },

    methods: {
        updateUserDetails(event) {
            if (this.disabled) return;
            this.$emit('update-user-details', event.target.name, event.target.value?.trim());
        }
    }
}
</script>

<style scoped lang="sass">
.user-details-settings
    color: var(--be-colour-text-dark)

    &__info-card
        margin: 0
    &__info-card-content
        display: flex
        align-items: center
        p
            margin: 0

    &__table
        border: 1px solid #1a1a1a
        width: 95%
        margin-top: 25px

        td
            border-bottom: 1px solid #1a1a1a
            height: 40px

        tr:nth-child(1)
            display: none // Don't display column headers

        td:nth-child(1)
            padding-left: 10px
            width: 50%

        input[type=text]
            margin-top: 4px
</style>