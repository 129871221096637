<template>
    <div class="be-selector-menu-item" :class="classes" @click.capture="clicked">
        <old-tooltip v-if="tooltip" :label="tooltip">
            <slot/>
        </old-tooltip>
        <slot v-else/>
    </div>
</template>


<script>
import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
    components: {OldTooltip},
    props: {
        active: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String
        }
    },

    computed: {
        classes() {
            const classes = [];
            if (this.active) classes.push('be-selector-menu-item--active');
            if (this.disabled) classes.push('be-selector-menu-item--disabled');
            return classes;
        }
    },

    methods: {
        clicked(event) {
            if (!this.disabled) this.$emit('click', event);
        }
    }
}
</script>


<style scoped lang="sass">

.be-selector-menu-item
    box-sizing: border-box
    padding: 4px 12px
    background-color: #222
    border: 1px solid #111
    color: white
    line-height: 20px
    cursor: pointer

    &:not(:last-of-type)
        border-bottom-width: 0

    &--active
        background: var(--colour-active-gradient)

    &--disabled
        filter: grayscale(50%)
        cursor: default

    &--disabled:not(.be-selector-menu-item--active)
        background: #2a2a2a
        border-color: #2a2a2a
        color: var(--be-colour-muted-text-dark)
        cursor: not-allowed

    &:hover:not(.be-selector-menu-item--disabled)
        background: var(--background-menu-hover)

    ::v-deep a
        color: white


</style>