export default function(inputArray, options){
    var reversedArray = inputArray.slice().reverse();
    if (options.data) {
        var data = Handlebars.createFrame(options.data);
    }
    var html = reversedArray.map(function(item, index, arr){
        if (data && index === arr.length - 1) {
            data.last = true;
        }
        return options.fn(item, {data: data});
    }).join('');
    return new Handlebars.SafeString(html);
}
