/**
 * Drill down by adding/removing segments to the filter.
 */

import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";

Beef.module("Widget.SegmentSelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Segments",
        description:    "Interactively filter by segment",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.TagSelector.View.extend({

        filterAttribute: "segments",

        modelEvents:Object.assign({
            "change:segments": "clearAndRefresh",
            "change:hideNoneOfTheAbove": "clearAndRefresh"
        }, Beef.Widget.CountSelector.View.prototype.modelEvents),

        getTooltipText: function(item) {
            var tooltip = "Click to select mentions with the segment " + encloseInDisplayQuotes(item.name);
            if (item.description) tooltip += ".\n\n" + item.description;
            return tooltip;
        },

        getChildren: function(tag) {
            // Don't supply children if this is not a segment list. Otherwise you can never show,
            // for example, the outcome categories of a TCF heirarchy. 
            if (tag.namespace !== "segment_list") return [];

            var a = tag.children;
            if (a && a.length > 0 && this.model.attributes.hideNoneOfTheAbove) a = a.slice(0, a.length - 1);
            if (a) return a.filter(t => !t.deleted);
            return a;
        },

        tagItems: function(items) {
            var tagIds = this.model.get('segments');
            if (!tagIds || tagIds.length === 0) {
                this.text([{ name: "(no segments - edit this metric and choose some segments)" }]);
            } else {
                Beef.Widget.TagSelector.View.prototype.tagItems.apply(this, arguments);
            }
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/selectors/SegmentSelectorSettings.handlebars"),

        editAttributes: function() {
            return ['segments', 'hideNoneOfTheAbove']
        },

        modelEvents: {
            "change:segments": "onSegmentsChange"
        },

        bindings: function() {
            return {
                segments: { converterFactory: Beef.TagPicker.createConverterFactory(null, {noHighlightSegments: true}),
                    elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            Beef.TagPicker.attach(this, ".segments", "segments", {
                searchFilter: Beef.TagPicker.segmentListChooser,
                startDroppedDown: true,
                noHighlightSegments: true
            });
        },

        onSegmentsChange: function() {
            var tags = this.model.get('segments');
            if (tags && tags.indexOf(' ') < 0) { // only one tag so change caption to match
                var tag = this.cache.tags[tags];
                if (tag) this.model.set('caption', tag.name);
            }
        }
    });

});