var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"edit-feed-icon pull-right\"><i class=\"edit icon-pencil\" href=\"edit\" id="
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "></i></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-users pull-right\" title=\"Send mentions from feed to all accounts\"></i>";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"warn\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"icon-attention-1 warn\"></i>\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"readIOExceptionCount") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":84}}})) != null ? stack1 : "")
    + "\n        "
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastReadError") : stack1), depth0))
    + ", "
    + alias2(__default(require("../../helpers/humanizeDate.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastRead") : stack1),{"name":"humanizeDate","hash":{},"data":data,"loc":{"start":{"line":15,"column":34},"end":{"line":15,"column":66}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"readIOExceptionCount") : stack1), depth0))
    + ") ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastReadMentions") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            Read "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastReadNewMentions") : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastReadMentions") : stack1), depth0))
    + " new\n            mention"
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastReadMentions") : stack1),"!=",1,{"name":"ifcond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":19},"end":{"line":19,"column":73}}})) != null ? stack1 : "")
    + ", "
    + alias2(__default(require("../../helpers/humanizeDate.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastRead") : stack1),{"name":"humanizeDate","hash":{},"data":data,"loc":{"start":{"line":19,"column":75},"end":{"line":19,"column":107}}}))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "s";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"num"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"numMaybeMissedDataEvents") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":18},"end":{"line":25,"column":69}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"numMaybeMissedDataEvents") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td style=\"padding: 0; padding-top: 2px\">\n        <a class=\"btn btn-link repoll\" title=\"Repoll feed\"><i class=\"icon-ccw\"></i></a>\n    </td>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return " warn";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"icon-attention-1 warn\"></i> "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"numMaybeMissedDataEvents") : stack1), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"name\">\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editFeeds",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":18}}})) != null ? stack1 : "")
    + "    "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n</td>\n<td>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sendMentionsToAllAccounts") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":129}}})) != null ? stack1 : "")
    + "\n    "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\n</td>\n<td "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastReadError") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":51}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"lastReadError") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "</td>\n<td class=\"num\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? lookupProperty(stack1,"numNewMentions") : stack1), depth0))
    + "</td>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":33,"column":14}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});