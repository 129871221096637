<template>
    <section class="be-alert-component">
        <h5>
            <rpcs-tag v-if="tag && tag !== 'SENTIMENT'" :code="tag"/>
            <span v-if="tag === 'SENTIMENT'" class="be-alert-component__negative">
                <i class="icon-circle"></i>
            </span>
            {{ notification.name }}
        </h5>
        <p class="be-alert-component__description">
            {{notification.description}}
        </p>
        <p class="be-alert-component__count">
            <span v-if="count !== null" class="animated fadeIn">
                <strong>
                    Sent <deq-number :number="count"/> {{ formatPlural(count, 'time') }}
                </strong>
                in the last month
            </span>
        </p>
        <div class="be-alert-component__buttons">
            <slotted-tag v-if="isSubscribed"
                         tooltip="You are subscribed"
                         close-tooltip="Click to unsubscribe"
                         :disabled="!enabled"
                         @close="unsubscribe">
                <animated-check/>
                Subscribed
            </slotted-tag>
            <be-button v-else
                       :disabled="!enabled"
                       @click="subscribe"
                       link>
                Sign up for an email alert
            </be-button>
        </div>
    </section>
</template>


<script>

import SlottedTag from "@/components/tags/SlottedTag";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import {notificationMixin} from "@/setup/notifications/NotificationMixins";
import BeButton from "@/components/buttons/BeButton";
import RpcsTag from "@/components/tags/RpcsTag";
import {mapActions} from "vuex";
import moment from "moment";
import DeqNumber from "@/components/formatters/DeqNumber";
import {formatPlural} from "@/app/utils/Format";

export default {
    components: {DeqNumber, RpcsTag, BeButton, AnimatedCheck, SlottedTag},
    mixins: [notificationMixin],

    props: {
        notification: {                    // The alert type notification to show.
            type: Object,
            required: true
        }
    },

    data() {
        return {
            count: null         // Times fired in the last while
        }
    },

    watch: {
        'notification.id'() {
            this.updateCounts();
        }
    },

    async mounted() {
        await this.updateCounts();
    },

    methods: {
        ...mapActions('digests', ['getLogsForAlerts']),

        formatPlural,

        async updateCounts() {
            try {
                this.count = null;
                if (!this.notification?.id) return;

                const alerts = await this.getLogsForAlerts(this.notification);
                const aMonthAgo = moment().startOf('day').subtract(1, 'month');
                this.count = alerts.filter(l => aMonthAgo.isBefore(l.date)).length;
            } catch (e) {
                console.error(e);
                this.count = null;
            }
        }
    }
}

</script>


<style scoped lang="sass">

.be-alert-component
    display: inline-block
    box-sizing: border-box
    padding: 10px
    margin: 5px
    border: thin solid #111
    border-radius: 3px
    background: #222
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4), -2px -2px 2px rgba(100, 100, 100, 0.3)
    color: var(--be-colour-text-dark)

    h5
        font-size: 1em
        color: white
        margin: 0
        padding: 0

    p
        padding: 0
        margin: 0

    .be-alert-component__description
        color: var(--be-colour-muted-text-dark)

    .be-alert-component__count
        color: var(--be-colour-muted-text-dark)
        min-height: 1.1em
        line-height: 1.1em
        strong
            color: var(--be-colour-text-dark)

    .be-alert-component__negative
        color: var(--be-colour-negative)
        margin-left: -7px

    .be-alert-component__buttons
        padding-top: 5px
        min-height: 30px
        box-sizing: border-box




</style>