<template>
    <dialog-box modal @close="$emit('input', null)" width="780px" class="mentions" :title="title">
        <div style="height: 80vh; display: flex;">
            <preview-mentions :filter="value"/>
        </div>
        <template #buttons>
            <a class="btn btn-primary" @click="$emit('input', null)">Close</a>
        </template>
    </dialog-box>
</template>

<script>
    import DialogBox from './DialogBox'
    import PreviewMentions from './PreviewMentions'

    export default {
        name: "PreviewMentionsDialog",

        components: { PreviewMentions, DialogBox },

        props: {
            value: String,
            title: String
        }
    }
</script>

<style scoped>
    .mentions ::v-deep .dialog-body {
        padding: 1px;
    }

    .mentions ::v-deep .dialog-button-bar {
        margin: 7px 5px 5px;
    }
</style>