<template>
    <dialog-box class="brand-picker-dialog"
                title="Select a brand"
                width="500px"
                stop-mousedown
                @close="closeNoEscape($event)"
                modal>


        <loading-message v-if="loading"
                         class="brand-picker-dialog__loading"
                         message="Fetching your brands"/>
        <section v-else-if="!brands.length" class="brand-picker-dialog__no-brands">
            No brands have been added to this account yet. Please contact our support team.
        </section>
        <div v-else>
            <search-input class="brand-picker-dialog__search"
                          autofocus
                          ref="searchBar"
                          placeholder="Search for a brand"
                          @keydown.enter.stop.prevent="focusFirst()"
                          v-model="searchTerm"/>

            <brand-picker v-model="brandPickerSelection"
                          ref="picker"
                          :brands="rootBrands"
                          :show-archived-brands="showArchivedBrands"
                          :only-one="onlyOne"
                          :show-children="showChildren"
                          :exclude-children="currentExcludeChildren"
                          :search-text="searchTerm"/>
        </div>

        <template #buttons>
            <section class="brand-picker-dialog__buttons">
                <div class="brand-picker-dialog__left-buttons">
                    <be-button v-if="!onlyOne"
                               :tooltip="`Click to select all root brands`"
                               link
                               @click="selectAllRootBrands">Select all brands</be-button>

                    <div v-if="archivedBrands.length">
                        <be-button v-if="!showArchivedBrands"
                                   :tooltip="`Click to reveal ${archivedBrands.length} archived ${formatPlural(archivedBrands.length, 'brand')}`"
                                   link
                                   @click="showArchivedBrands = true">Show archived brands</be-button>
                        <slotted-tag v-else
                                     @close="showArchivedBrands = false"
                                     close-tooltip="Click to hide archived brands">
                            <animated-check/> Archived brands shown
                        </slotted-tag>
                    </div>

                    <be-button v-if="!currentExcludeChildren && showChildren"
                               link
                               tooltip="Click to only select data directly related to your root brands, not their sub-brands"
                               @click="currentExcludeChildren = true">Including sub-brands</be-button>
                    <slotted-tag v-else-if="showChildren"
                                 close-tooltip="Click to include all data from your brands"
                                 @close="currentExcludeChildren = false">
                        Data from sub-brands not included
                    </slotted-tag>

                    <be-button v-if="brandPickerSelection.length && rootBrands.length"
                               link
                               tooltip="Click to clear your selection"
                               @click="clearSelection()">
                        Clear selection
                    </be-button>
                </div>

                <be-button tooltip="Cancel adding a metric"
                           link
                           keyword="esc"
                           @click="$emit('close')">Cancel</be-button>
                <be-button tooltip="Select these brands"
                           :disabled="selectDisabled"
                           ref="selectButton"
                           primary
                           @click="emitSelected">Select</be-button>
            </section>
        </template>

    </dialog-box>
</template>


<script>
    import DialogBox from "@/components/DialogBox";
    import SearchInput from "@/components/inputs/SearchInput";
    import BeButton from "@/components/buttons/BeButton";
    import LoadingMessage from "@/components/LoadingMessage";
    import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
    import SlottedTag from "@/components/tags/SlottedTag";
    import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapActions, mapState} from "vuex";
    import {formatBrandName, formatPlural} from "@/app/utils/Format";
    import BrandPicker from "@/components/pickers/brands/BrandPicker";
    import {mapGetters} from "vuex/dist/vuex.esm.browser";

    export default {
        store: VuexStore,
        name: "BrandPickerDialog",
        components: {
            BrandPicker,
            AnimatedCheck, SlottedTag, LoadingMessage, DialogBox, SearchInput, BeButton},

        props: {
            selectedIds: Array,
            excludedIds: Array,
            onlyOne: {
                type: Boolean,
                default() { return false }
            },
            mustHaveOne: {
                type: Boolean,
                default() { return false }
            },
            showChildren: {
                type: Boolean,
                default: false
            },
            excludeChildren: {
                type: Boolean,
                default: false
            },
            useAdvancedFormat: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                brands: [],
                loading: true,
                searchTerm: '',
                showArchivedBrands: false,
                currentExcludeChildren: this.excludeChildren,
                brandPickerSelection: [],
            }
        },

        computed: {
            ...mapState(['rootBrands', 'account']),
            ...mapGetters(['idToBrand']),

            ownedBrands() {
                return this.brands
                    .filter(b => b.brand.category === 'OWN' && !b.brand.archived)
                    .sort((lhs, rhs) => this.compare(lhs, rhs));
            },
            competitors() {
                return this.brands
                    .filter(b => b.brand.category === 'COMPETITOR' && !b.brand.archived)
                    .sort((lhs, rhs) => this.compare(lhs, rhs));
            },
            otherBrands() {
                return this.brands
                    .filter(b => b.brand.category !== 'OWN' && b.brand.category !== 'COMPETITOR' && !b.brand.archived)
                    .sort((lhs, rhs) => this.compare(lhs, rhs));
            },
            archivedBrands() {
                return this.brands
                    .filter(b => b.brand.archived)
                    .sort((lhs, rhs) => this.compare(lhs, rhs));
            },
            selectDisabled() {
                const numSelected = this.brandPickerSelection?.length ?? 0;
                return this.mustHaveOne && numSelected === 0;
            },
            otherBrandTitle() {
                return this.ownedBrands?.length ? "Other brands" : "Brands";
            }
        },

        watch: {
            searchTerm() {
                this.brands.forEach(b => b.visible = this.isVisible(b.brand));
            },

            selectedIds() {
                this.updateSelection();
            },

            excludedIds() {
                this.updateSelection();
            },

            brandPickerSelection() {
                // If we have selected archived brands somehow, we do need to make sure that
                // they are visible.
                if (this.brandPickerSelection.some(s => s.brand.archived)) this.showArchivedBrands = true;
            },

            currentExcludeChildren() {
                this.updateSelection();
            }

        },

        async created() {
            this.updateSelection();
            await this.loadBrands();
        },

        mounted() {
            document.addEventListener("keyup",  this.keyHandler, {capture: true});
        },

        beforeDestroy() {
            document.removeEventListener("keyup",  this.keyHandler, true);
        },

        methods: {
            formatPlural,

            ...mapActions(['refreshBrands']),

            closeNoEscape(event) {
                if (event.key === 'Escape') return;
                this.$emit('close');
            },

            focusFirst() {
                this.$refs.picker?.focusFirst();
            },

            selectAllRootBrands() {
                // Don't selected archived brands if they're not visible.
                const {makeBrand} = this;
                const brands = this.showArchivedBrands
                    ? this.rootBrands
                    : this.rootBrands.filter(b => !b.archived);
                this.brandPickerSelection = brands.map(b => makeBrand(b, false));
            },

            clearSelection() {
                this.brandPickerSelection = [];
            },

            makeBrand(brand, excluded) {
                return {
                    brand,
                    selected: true,
                    excludeChildren: this.currentExcludeChildren,
                    excluded
                }
            },

            updateSelection() {
                const { makeBrand } = this;
                const included = this.selectedIds?.map(id => this.idToBrand.get(id))?.filter(d => !!d).map(brand => makeBrand(brand, false)) ?? [];
                const excluded = this.excludedIds?.map(id => this.idToBrand.get(id))?.filter(d => !!d).map(brand => makeBrand(brand, true)) ?? [];
                this.brandPickerSelection = [...included, ...excluded];
            },

            /**
             * @param {KeyboardEvent} event
             */
            keyHandler(event) {
                if (event.target?.tagName === 'INPUT') return;
                if (event.target.classList.contains("be-button")) return;
                if (event.target.classList.contains("brand-picker-dialog__root-brand")) return;

                if (event.key === 'Enter') return;
                // if (event.key === 'Escape') return;

                event.preventDefault();
                event.stopPropagation();
                if (event.key === 'Escape') {
                    if (this.searchTerm?.length) {
                        this.searchTerm = '';
                    } else {
                        this.$emit('close');
                    }
                }
                else if (this.$refs.searchBar && event.key.length === 1) {
                    this.$refs.searchBar.focus();
                    this.searchTerm += event.key;
                }
            },

            compare(lhs, rhs) {
                const defaultBrand = this.account.defaultBrand;
                if (defaultBrand?.id === lhs.brand.id) return -1;
                if (defaultBrand?.id === rhs.brand.id) return 1;
                const lhsName = formatBrandName(lhs.brand).toLowerCase();
                const rhsName = formatBrandName(rhs.brand).toLowerCase();
                return lhsName.localeCompare(rhsName);
            },

            selectBrand(brand, keyboardUsed) {
                const currentlySelected = brand.selected;

                if (this.onlyOne && !currentlySelected) {
                    this.brands.forEach(b => b.selected = false);
                }
                brand.selected = !currentlySelected;

                if (keyboardUsed && this.onlyOne && this.brands.some(b => b.selected)) {
                    this.$refs?.selectButton?.focus();
                }
            },

            isVisible(brand) {
                if (!this.searchTerm) return true;
                const term = this.searchTerm?.toLowerCase() ?? '';
                const name = (brand.name + ' ' + (brand.shortName ?? '')).toLowerCase();
                return name.includes(term)

            },
            async loadBrands() {
                try {
                    const selected = new Set(this.selectedIds ?? []);
                    await this.refreshBrands();
                    this.brands = (this.rootBrands)
                        .map(brand => {
                            return {
                                brand: brand,
                                selected: selected.has(brand.id),
                                visible: true,
                                expanded: false,
                                children: brand?.children?.map(c => ({
                                    brand: c,
                                    visible: true,
                                    selected: selected.has(c.id)
                                })) ?? []
                            }
                        });

                    // Ensure that we can see archived brands that are selected.
                    const archiveSelected = this.brands.some(b => b.selected && b.brand.archived);
                    if (archiveSelected) this.showArchivedBrands = true;
                } catch (e) {
                    console.error(e);
                    showErrorDialog("There has been an error fetching your brands. Please try again in a moment.");
                } finally {
                    this.loading = false;
                }
            },

            emitSelected() {
                if (this.selectDisabled) return;

                if (this.useAdvancedFormat) {
                    this.$emit('selected', this.brandPickerSelection);
                } else {
                    this.$emit('selected', this.brandPickerSelection.filter(b => !b.excluded).map(b => b.brand));
                }

                this.$emit('close');
            }
        }
    }
</script>


<style scoped lang="sass">


::v-deep .modal-container
    //width: clamp( 500px, 100%, calc(100vw - 300px)) !important
    width: fit-content !important
    max-width: calc(100vw - 300px)
    min-width: 500px

.brand-picker-dialog::v-deep .dialog-body
    margin: 0
    padding: 0
    background: var(--body-background-colour)

.brand-picker-dialog::v-deep .dialog-button-bar
    margin: 0
    padding: 10px
    border-top: var(--border-separation)
    background: var(--background-dialog-buttoncontainer)

.brand-picker-dialog__buttons
    display: flex
    flex-direction: row
    justify-content: flex-end

    .brand-picker-dialog__left-buttons
        margin-right: auto
        display: flex
        align-items: center
        gap: 5px

.brand-picker-dialog__loading
    margin: 0
    padding-top: 40px
    padding-bottom: 40px

.brand-picker-dialog__brands
    background: #222
    max-height: 60vh
    overflow-y: auto

.brand-picker-dialog__search
    margin: 10px 10px 4px
    box-sizing: border-box

.brand-picker-dialog__no-brands
    padding: 10px


</style>