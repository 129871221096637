<template>
    <div ref="holder">
    </div>
</template>

<script>
    import {toGrouseLink} from "@/data/Grouse";
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapState} from "vuex";

    export default {
        name: "MentionList",
        store: VuexStore,

        props: {
            mentions: Array
        },

        computed: {
            ...mapState(['account'])
        },

        mounted() {
            this.collection = new Backbone.Collection();
            this.collection.accountCode = this.account.code;
            this.collection.url = toGrouseLink('/v4/accounts/' + this.account.code + '/mentions', null, true);
            this.view = new ListView({collection: this.collection});
            this.renderList();
        },

        beforeDestroy() {
            this.view.close()
        },

        watch: {
            mentions() {
                this.renderList()
            }
        },

        methods: {
            renderList() {
                let models = this.mentions.map(m => {
                    let mod = new Beef.MentionItem.Model(m);
                    mod.accountCode = this.account.code;
                    return mod;
                })
                this.collection.reset(models);
                this.view.render();
                this.$refs.holder.innerText = '';
                this.$refs.holder.append(this.view.el);
            }
        }
    }

    Beef.MentionItem.start(); // We have a dependency on this module

    const ListView = Backbone.Marionette.CompositeView.extend({

        tagName: "div",

        itemView: Beef.MentionItem.View,

        itemViewOptions: function() {
            return {
                noSelect: true,
                timeline: true,
                notime: true
            }
        },

        onAfterItemAdded: function(view) {
            view.model.view = view; // this is so "show conversation" and other things on mention view/edit dlg work
        }
    });

</script>

<style scoped>
</style>