/**
 * Provides utilities for interacting with mentions, such as replying, posting, and so on.
 */

import _ from 'underscore';
import {isString} from "@/app/utils/StringUtils";
import {account} from "@/app/utils/Account";

Beef.module("Conversation").addInitializer(function(startupOptions) {

    /**
     * Returns the profiles authorized for this user.
     * @returns {*}
     */
    this.getAuthorizedProfiles = function() {
        return Beef.user.getNonExpiredAuthorizedProfiles();
    };

    /**
     * Given a mention or profile, this returns the authorized profiles specific to the
     * network of that mention or profile, or returns null if there is no appropriate network.
     */
    this.getAuthorizedNetworkProfiles = function(mentionOrProfile) {
        if (Beef.Conversation.isTwitter(mentionOrProfile)) {
            return Beef.Conversation.getAuthorizedTwitterProfiles();
        }
        else if (Beef.Conversation.isFacebook(mentionOrProfile)) {
            return Beef.Conversation.getAuthorizedFacebookProfiles();
        }
        else return null;
    };

    /**
     * Returns the authorized profiles for twitter.
     */
    this.getAuthorizedTwitterProfiles = function() {
        return _(this.getAuthorizedProfiles()).filter(function(p) {
            return this.isTwitter(p);
        }.bind(this));
    };

    /**
     * Returns the authorized profiles for facebook.
     */
    this.getAuthorizedFacebookProfiles = function() {
        return _(this.getAuthorizedProfiles()).filter(function(p) {
            return this.isFacebook(p);
        }.bind(this));
    };

    /**
     * This returns the name of the given social network, for profiles or mentions.
     * unknownValue is an optional argument to give a value that should be returned for an
     * unknown network.
     */
    this.getNameOfNetwork = function(model, unknownValue) {
        if (_(unknownValue).isUndefined()) unknownValue = "the Social Network";

        if (this.isTwitter(model)) return "Twitter";
        if (this.isFacebook(model)) return "Facebook";
        if (this.isInstagram(model)) return "Instagram";
        if (this.isYoutube(model)) return "YouTube";
        if (this.isLinkedIn(model)) return "LinkedIn";

        return unknownValue;
    };

    /**
     * Reply to a mention.
     * @param mentionModel The mention being replied to.
     * @param text The text of the reply message
     * @param profile The profile to use when replying.
     */
    this.replyTo = function(mentionModel, text, profile) {
        var messageDeferred = new $.Deferred();

        var data = {
            inReplyTo: this.getId(mentionModel),
            profileId: profile.id,
            message: text,
            code: account().code
        };

        $.ajax({
            url: "/api/message/replyto",
            type: "POST",
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify(data),
            success: function() {
                messageDeferred.resolve();
            },
            error: function() {
                messageDeferred.reject();
            }
        });

        return messageDeferred.promise();
    };

    /**
     * Posts a new message using the given profile.
     * @param text The message to send
     * @param profile The profile sending the message.
     */
    this.post = function(options) {
        var messageDeferred = new $.Deferred();

        var profile = options.profile;
        var text = options.text;

        if (!profile) throw new Error("No profile has been provided");

        var data = {
            profileId: profile.id,
            code: account().code,
            message: text,
            link: options.link
        };

        $.ajax({
            url: "/api/message/send",
            type: "POST",
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify(data),
            success: function() {
                messageDeferred.resolve();
            },
            error: function() {
                messageDeferred.reject();
            }
        });

        return messageDeferred.promise();
    };

    /**
     * Favourites or likes the given mention (based on which network this is on).
     * This is a synonym for the like function.
     */
    this.favourite = function(mention, profile) {
        var favouriteDeferred = new $.Deferred();

        var data = {
            messageId: this.getId(mention),
            profileId: profile.id,
            code: account().code
        };

        $.ajax({
            url: "/api/message/favourite",
            type: "POST",
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify(data),
            success: function() {
                favouriteDeferred.resolve();
            },
            error: function() {
                favouriteDeferred.reject();
            }
        });

        return favouriteDeferred.promise();
    };

    /**
     * A synonym for favourite.
     */
    this.like = this.favourite;

    /**
     * Causes the given profile to begin following the given author
     * The returned promise might pass the following strings on a failure:
     * - SAME: the author of the mention and the profile are the same.
     * - GENERAL: There was an unspecified error.
     */
    this.follow = function(mention, profile) {
        var followDeferred = new $.Deferred();

        // Ensure that we're not attempting to follow ourself.
        var author = mention.get('author');
        if (author) {
            var authorHandleId = null;
            if (this.isTwitter(mention)) {
                authorHandleId = author.twitterUserId;
            }
            else if (this.isFacebook(mention)) {
                authorHandleId = author.facebookId;
            }

            if (authorHandleId == profile.handleId) {
                followDeferred.reject("SAME");
                return followDeferred.promise();
            }
        }

        var data = {
            messageId: this.getId(mention),
            profileId: profile.id,
            code: account().code
        };

        $.ajax({
            url: "/api/message/follow",
            type: "POST",
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify(data),
            success: function() {
                followDeferred.resolve();
            },
            error: function() {
                followDeferred.reject("GENERAL");
            }
        });

        return followDeferred.promise();
    };

    /**
     * Synonym for follow.
     */
    this.friend = this.follow;

    this.reshare = function(mention, profile) {
        var retweetDeferred = new $.Deferred();

        var data = {
            messageId: this.getId(mention),
            profileId: profile.id,
            code: account().code
        };

        $.ajax({
            url: "/api/message/reshare",
            type: "POST",
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify(data),
            success: function() {
                retweetDeferred.resolve();
            },
            error: function() {
                retweetDeferred.reject();
            }
        });

        return retweetDeferred.promise();
    };

    /**
     * Synonym for reshare.
     */
    this.retweet = this.reshare;


    /**
     * Returns the message ID for a given mention.
     */
    this.getId = function(mentionModel) {
        var uri = mentionModel.get('uri');
        var site = mentionModel.get('site');

        if (this.isTwitter(mentionModel)) {
            return _(uri.split('/')).last();
        }
        else if (this.isFacebook(mentionModel)) {
            if (this.isFacebookPost(mentionModel)) {
                let url = mentionModel.get('uri');
                let ids = url.match(/\d+/g);
                return ids[0] + '_' + ids[1];
            }
            if (this.isFacebookComment(mentionModel)) {
                let url = mentionModel.get('uri');
                let ids = url.match(/\d+/g);
                return ids.join('_');
            }
        }

        throw new Error("Unsupported site " + site);
    };

    /**
     * Determins an author name appropriate for the network.
     */
    this.getAuthor = function(mentionModel) {
        var site = mentionModel.get('site');
        var authorName = mentionModel.get('authorName');

        if (!authorName) return null;

        switch(site) {
            case 'twitter.com':
            case 'http://twitter.com':
            case 'https://twitter.com':
                return '@' + _(authorName.split(' ')).first();
            default:
                throw new Error("Unsupported site " + site);
        }
    };

    /**
     * Returns a list of @names in the mention or text.
     */
    this.getAtNames = function(modelOrText) {
        var text = modelOrText;
        if (!isString(text)) text = text.get('title');

        return text.match(/(^|\s)@[a-z][a-z0-9_]*/gi);
    };

    /**
     * Returns true if this mention is a part of a conversation. It is either a reply or reshare,
     * or something is engaging with it.
     */
    this.isInConversation = function(mentionModel) {
        return (mentionModel.get('replyToUri') || mentionModel.get('reshareOfUri') || mentionModel.get('engagement') > 0) == true
    };

    /**
     * Returns true if this mention is from twitter, or if the profile is for twitter.
     */
    this.isTwitter = function(mentionModelOrProfile) {
        // See if this is a profile
        if (mentionModelOrProfile.type || (mentionModelOrProfile.get && mentionModelOrProfile.get('type'))) {
            var type = mentionModelOrProfile.type || mentionModelOrProfile.get('type');
            if (type == 'TWITTER_SCREEN_NAME') {
                return true;
            }
            return false;
        }

        var site = mentionModelOrProfile.get('site');

        switch(site) {
            case 'twitter.com':
            case 'http://twitter.com':
            case 'https://twitter.com':
                return true;
            default:
                return false;
        }
    };

    /**
     * Returns true if this profile or mention model is related to facebook.
     */
    this.isFacebook = function(mentionModelOrProfile) {
        // See if this is a profile
        if (mentionModelOrProfile.type || (mentionModelOrProfile.get && mentionModelOrProfile.get('type'))) {
            var type = mentionModelOrProfile.type || mentionModelOrProfile.get('type');
            if (type == 'FACEBOOK_PAGE' || type == 'FACEBOOK_USER') {
                return true;
            }
            return false;
        }

        var site = mentionModelOrProfile.get('site');

        switch(site) {
            case 'facebook.com':
            case 'http://facebook.com':
            case 'https://facebook.com':
            case 'www.facebook.com':
            case 'http://www.facebook.com':
            case 'https://www.facebook.com':
                return true;
            default:
                return false;
        }
    };

    /**
     * Returns true iff this profile or mention model is related to instagram
     */
    this.isInstagram = function(mentionModelOrProfile) {
        // See if this is a profile
        if (mentionModelOrProfile.type || (mentionModelOrProfile.get && mentionModelOrProfile.get('type'))) {
            var type = mentionModelOrProfile.type || mentionModelOrProfile.get('type');
            if (type == 'INSTAGRAM_USER') return true;
            return false;
        }

        var site = mentionModelOrProfile.get('site');

        switch(site) {
            case 'instagram.com':
            case 'http://instagram.com':
            case 'https://instagram.com':
            case 'www.instagram.com':
            case 'http://www.instagram.com':
            case 'https://www.instagram.com':
                return true;
            default:
                return false;
        }
    };
    
    this.isYoutube = function(mentionModelOrProfile) {
        // See if this is a profile
        if (mentionModelOrProfile.type || (mentionModelOrProfile.get && mentionModelOrProfile.get('type'))) {
            var type = mentionModelOrProfile.type || mentionModelOrProfile.get('type');
            if (type == 'YOUTUBE_CHANNEL') return true;
            return false;
        }

        var site = mentionModelOrProfile.get('site');

        switch(site) {
            case 'www.youtube.com':
            case 'youtube.com':
                return true;
            default:
                return false;
        }
    };

    this.isVk = function(mentionModelOrProfile) {
        // See if this is a profile. NB we don't support VK profiles.
        if (mentionModelOrProfile.type || (mentionModelOrProfile.get && mentionModelOrProfile.get('type'))) {
            var type = mentionModelOrProfile.type || mentionModelOrProfile.get('type');
            return false;
        }

        var site = mentionModelOrProfile.get('site');
        switch(site) {
            case 'vk.com':
                return true;
            default:
                return false;
        }
    };

    this.isLinkedIn = function(mentionModelOrProfile) {
        // See if this is a profile
        if (mentionModelOrProfile.type || (mentionModelOrProfile.get && mentionModelOrProfile.get('type'))) {
            var type = mentionModelOrProfile.type || mentionModelOrProfile.get('type');
            if (type == 'LINKEDIN_COMPANY') return true;
            return false;
        }

        var site = mentionModelOrProfile.get('site');
        switch(site) {
            case 'www.linkedin.com':
            case 'linkedin.com':
                return true;
            default:
                return false;
        }
    };

    /**
     * Returns true iff this mention model is related to instagram
     */
    this.isPrint = function(mention) {
        var uri = mention.get('uri');
        return uri && (uri.toLowerCase().indexOf('pressdisplay-redirect.brandseye.com') != -1
                    || uri.toLowerCase().indexOf('http://media-cache.brandseye.com') == 0);
    };

    /**
     * Returns true iff this is a comment to a facebook post.
     */
    this.isFacebookComment = function(mentionModel) {
        return this.isFacebook(mentionModel) && mentionModel.get('link').indexOf('comment_id') >= 0;
    };

    /**
     * Returns true if this is a post on facebook, and not a comment.
     */
    this.isFacebookPost = function(mentionModel) {
        return this.isFacebook(mentionModel) && mentionModel.get('link').indexOf('comment_id') < 0;
    };

    /**
     * Returns true if this is a post on instagram, and not a comment.
     */
    this.isInstagramPost = function(mentionModel) {
        return this.isInstagram(mentionModel) && mentionModel.get('link').indexOf('/#') < 0;
    };

    /**
     * Returns true if this is a video on facebook, and not a comment.
     */
    this.isFacebookVideo = function(mentionModel) {
        var mediaLinks = mentionModel.get('mediaLinks')
        var isFbPost = this.isFacebook(mentionModel)
        if(!isFbPost) return false;
        var isMediaLinkedVideo = false;
        if(mediaLinks && mediaLinks.length) {
            for (var i = 0; i < mediaLinks.length; i++) {
                var mediaLink = mediaLinks[i];
                if (isFbPost && ((mediaLink.mimeType.toUpperCase().indexOf('VIDEO/MP4') != -1) ||
                    (mediaLink.mimeType.toUpperCase().indexOf('TEXT/HTML') != -1 && mediaLink.url.toUpperCase().indexOf('/VIDEO') != -1))) {
                    isMediaLinkedVideo = true;
                    break;
                }
            }
        }
        return isFbPost && isMediaLinkedVideo;
    };

    /**
     * Returns true if this is a video on instagram, and not a comment.
     */
    this.isInstagramVideo = function(mentionModel) {
        var mediaLinks = mentionModel.get('mediaLinks')
        var isInstagramPost = this.isInstagram(mentionModel)
        if(!isInstagramPost) return false;
        var isMediaLinkedVideo = false;
        if(mediaLinks && mediaLinks.length) {
            for (var i = 0; i < mediaLinks.length; i++) {
                var mediaLink = mediaLinks[i];
                if (isInstagramPost && ((mediaLink.mimeType.toUpperCase().indexOf('VIDEO/MPEG') != -1))) {
                    isMediaLinkedVideo = true;
                    break;
                }
            }
        }
        return isInstagramPost && isMediaLinkedVideo;
    };

    /**
     * Returns true if this is a video on Telegram.
     */
    this.isTelegramVideo = function(mentionModel) {
        var modelFeed = mentionModel.get('feed');
        if (!modelFeed || modelFeed.toUpperCase() !== "TELEGRAM")
            return false;
        var mediaLinks = mentionModel.get('mediaLinks');
        if (mediaLinks && mediaLinks.length) {
            for (var i = 0; i < mediaLinks.length; i++) {
                if (mediaLinks[i].mimeType && mediaLinks[i].mimeType.toUpperCase().indexOf('VIDEO/MP4') !== -1) {
                    return true;
                }
            }
        }
        return false;
    };

    //---------------------------------------------------

    this.postEmail = function(options) {

        var toList = options.toList,
            ccList = options.ccList,
            subject = options.subject,
            message = options.message,
            mentions = options.mentions,
            footer = options.footer,
            filter = options.filter;

        if (!toList) throw new Error("No to list has been provided");
        if (!filter && !mentions) throw new Error("Cannot provide both a filter and a list of mentions");

        var deferred = new $.Deferred();

        var firstName = Beef.user.get('firstName'),
            lastName = Beef.user.get('lastName');

        var name = "";
        if (firstName) name += firstName;
        if (firstName && lastName) name += ' ';
        if (lastName) name += lastName;

        if (!subject) subject = "Message from " + name;

        $.ajax({
            url: "/api/message/email/send",
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify({
                to: toList,
                cc: ccList,
                from: Beef.user.get('email'),
                fromName: name,
                code: account().code,
                subject: subject,
                message: message,
                mentions: mentions,
                footer: footer,
                filter: filter
            }),
            success: function() {
                deferred.resolve();
            },
            error: function(error) {
                console.error("error!", error);
                deferred.reject();
            }
        });

        return deferred.promise();
    };


});