<template>
    <div class="inline-tag-input deq-reset" :class="{'inline-tag-input--disabled': disabled}">
        <div v-if="!isOpen || disabled">
            <div v-if="!currentSelected.length" @click="isOpen = true" tabindex="0" @focus="isOpen = true">
                <slot name="no-tag-message">
                    <span class="inline-tag-input__non-message">Please select tags <i class="symbol-edit"></i></span>
                </slot>
            </div>
            <div v-else  @focus="isOpen = true">
                <slotted-tag v-for="tag in currentSelected"
                             :key="tag.id || tag.name"
                             :disabled="disabled"
                             class="inline-tag-input__tag"
                             @mouseenter="showTagTooltip($event, tag)"
                             @close="removeTag(tag)">
                    <be-tag :tag="tag"/>
                </slotted-tag>
                <old-tooltip label="Add another tag" style="display: inline">
                    <i class="symbol-add" @click="isOpen = true" tabindex="0" @focus="isOpen = true"></i>
                </old-tooltip>
            </div>
        </div>
        <div v-else>
            <inline-text-input v-model.trim="currentTagName"
                               ref="searchInput"
                               signal-for-all
                               ignore-blur
                               placeholder="Type to search for tags"
                               :disable-done="!currentTagName.length"
                               @keydown="inputKeydown($event)"
                               @focus="isOpen = true"
                               @cancel="inputCancelled()"
                               @ok="inputOk()"/>
        </div>
        <popup-menu :value="showSuggestedTags">
            <div class="inline-tag-input__suggested-tag-menu">
                <div v-if="!visibleTags.length && !currentTagName" class="inline-tag-input__footer deq-callout--muted">
                    Type to search
                </div>
                <h4 class="deq-uppercase-header" v-if="recent.length">Recently used</h4>
                <div v-for="tag in recent"
                     :key="'recent:' + tag.id"
                     class="inline-tag-input__suggested-tag"
                     tabindex="0"
                     @keyup.enter="inputOk(tag)"
                     @click="inputOk(tag)"
                     @keydown="itemKeydown($event)"
                     ref="items">
                    <be-tag :tag="tag"/>
                </div>

                <h4 class="deq-uppercase-header" v-if="recent.length && currentTagName">Search results</h4>
                <div v-for="tag in visibleTags"
                     :key="'search:' + tag.id"
                     class="inline-tag-input__suggested-tag"
                     tabindex="0"
                     @keyup.enter="inputOk(tag)"
                     @click="inputOk(tag)"
                     @keydown="itemKeydown($event)"
                     ref="items">
                    <be-tag :tag="tag"/>
                </div>
                <div v-if="remainingTagCount" class="inline-tag-input__footer deq-callout--muted">
                    And <num :number="remainingTagCount"/>
                    {{formatPlural(remainingTagCount, 'other')}}
                </div>
                <div v-if="currentTagName && !visibleTags.length"
                     class="inline-tag-input__footer deq-callout--muted">
                    No tags match your search
                </div>

            </div>
        </popup-menu>
    </div>
</template>

<script>
import VuexStore from "@/store/vuex/VuexStore";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import SlottedTag from "@/components/tags/SlottedTag";
import {mapActions, mapGetters, mapState} from "vuex";
import BeTag from "@/components/formatters/BeTag";
import PopupMenu from "@/components/PopupMenu";
import Num from "@/components/formatters/DeqNumber";
import {formatPlural} from "@/app/utils/Format";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import MostFrequentTopicTooltip from "@/app/toplevel/explore/overview/components/MostFrequentTopicTooltip";
import {Tag} from "@/app/utils/types";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {splitAtSpaces} from "@/app/utils/StringUtils";

export default {
    components: {OldTooltip, Num, PopupMenu, BeTag, SlottedTag, InlineTextInput},
    store: VuexStore,

    model: {
        prop: 'selected',
        event: 'selected-changed'
    },

    props: {
        selected: {
            type: Array,
            default: () => []
        },
        predicate: {
            type: Function,
            default: tag => tag.namespace === "tag"
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isOpen: false,
            currentTagName: "",
            currentSelected: Array.from(this.selected ?? []),
            recent: []
        }
    },

    computed: {
        ...mapState(['tags', 'user', 'account']),
        ...mapGetters(['idToTag']),

        availableTags() {
            if (!this.tags?.length) return [];
            return this.tags.filter(this.predicate);
        },

        tagsMatchingSearch() {
            if (!this.currentTagName?.trim()?.length) return [];
            const lower = this.currentTagName.trim().toLowerCase();
            return this.availableTags.filter(t => t.name.toLowerCase().includes(lower));
        },

        visibleTags() {
            return this.tagsMatchingSearch.slice(0, 5);
        },

        remainingTagCount() {
            return this.tagsMatchingSearch.length - this.visibleTags.length;
        },

        showSuggestedTags() {
            return !!(this.isOpen && (this.visibleTags.length || this.recent.length))
        },

        recentlySeenKey() {
            return `safe-cache:user:${this.user.id}:account:${this.account.code}:recent-tags`;
        }
    },

    watch: {
        selected() {
            this.currentSelected = Array.from(this.selected);
            this.saveRecentlyUsedTags();
        },

        isOpen() {
            if (this.isOpen) {
                setTimeout(() => this.$refs.searchInput?.focus());
            }
        },
    },

    async created() {
        await this.refreshTags();
        this.recent = this.getRecentlyUsedTags().map(id => this.idToTag.get(id)).filter(t => !!t);
    },

    methods: {
        formatPlural,

        ...mapActions(['refreshTags', 'doesTagExist']),

        async inputOk(suggestedTag) {
            let tag = null;
            if (suggestedTag)  {
                // tag has been selected from visible tags list
                tag = suggestedTag;
            } else if (this.currentTagName?.length) {
                tag = await this.doesTagExist(this.currentTagName, "tag");
                if (!tag) {
                    tag = new Tag(null, this.currentTagName, "tag");
                }
            }

            if (!this.currentSelected.some(t => (tag.id && t.id === tag.id) || (t.name === tag.name && t.namespace === tag.namespace))) {
                this.currentSelected.push(tag);
                this.$emit('selected-changed', this.currentSelected);
                setTimeout(() => this.saveRecentlyUsedTags());
            }

            this.isOpen = false;
            this.currentTagName = "";
        },

        inputCancelled() {
            this.isOpen = false;
            this.currentTagName = "";
        },

        removeTag(tag) {
            this.currentSelected = this.currentSelected.filter(t => t !== tag); // Checking reference equality
            this.$emit('selected-changed', this.currentSelected);
        },

        getRecentlyUsedTags() {
            try {
                const recentString = localStorage.getItem(this.recentlySeenKey);
                if (!recentString) return [];
                if (recentString) {
                    return splitAtSpaces(recentString)
                        .map(s => parseInt(s))
                        .filter(s => Number.isFinite(s));
                }
            } catch(e) {
                console.warn(e);
                localStorage.removeItem(this.recentlySeenKey);
                return [];
            }
        },

        saveRecentlyUsedTags() {
            try {
                if (this.currentSelected?.length) {
                    let tags = this.currentSelected.filter(t => t.id).map(t => t.id);
                    if (!tags.length) return;

                    const recent = new Set();
                    const recentString = localStorage.getItem(this.recentlySeenKey);
                    let recentArray = [];
                    if (recentString) {
                        recentArray = splitAtSpaces(recentString)
                            .map(s => parseInt(s))
                            .filter(s => Number.isFinite(s));
                        recentArray.forEach(n => recent.add(n));
                    }

                    tags.forEach(t => {
                        if (!recent.has(t)) recentArray.push(t);
                    });

                    const RECENT_SIZE = 5;
                    if (recentArray.length > RECENT_SIZE) {
                        recentArray = recentArray.slice(0, RECENT_SIZE);
                    }

                    localStorage.setItem(this.recentlySeenKey, recentArray.join(" "));
                }
            } catch (e) {
                console.error(e);
                localStorage.removeItem(this.recentlySeenKey);
            }
        },

        inputKeydown(event) {
            switch(event.key) {
                case 'ArrowDown': {
                    if (this.$refs.items?.length) {
                        this.$refs.items[0].focus();
                    }
                    break;
                }
                case 'ArrowUp': {
                    if (this.$refs.items?.length) {
                        this.$refs.items[this.$refs.items.length - 1].focus();
                    }
                    break;
                }
            }
        },

        itemKeydown(event) {
            const items = this.$refs.items;
            if (!items?.length) return;

            switch(event.key) {
                case 'ArrowDown': {
                    const index = items.findIndex(d => Object.is(d, event.target));
                    if (index >= 0 && index < items.length - 1) {
                        items[index + 1].focus();
                    }
                    break;
                }
                case 'ArrowUp': {
                    const index = items.findIndex(d => Object.is(d, event.target));
                    if (index > 0) {
                        items[index - 1].focus();
                    }
                    break;
                }
            }
        },

        showTagTooltip(event, tag) {
            showTooltipComponent(event.target, MostFrequentTopicTooltip, {tag});
        }
    }

}
</script>


<style scoped lang="sass">

.inline-tag-input
    .symbol-add:hover
        cursor: pointer
        color: var(--be-colour-text-dark__hover)

    &__non-message
        font-style: italic
        color: var(--be-colour-muted-text-dark)
        text-align: center

        &:hover
            cursor: pointer

    &__suggested-tag
        display: block
        color: #fff
        padding: 3px 10px
        cursor: pointer

        &:focus,
        &:hover
            outline: none
            background: var(--background-menu-hover)

            &:first-of-type
                border-top-left-radius: 6px
                border-top-right-radius: 6px

            &:last-of-type
                border-bottom-left-radius: 6px
                border-bottom-right-radius: 6px

    &__suggested-tag-menu
        h4
            padding: 5px
            margin: 0 0 5px

    &--disabled
        .inline-tag-input__non-message,
        i.symbol-add
            cursor: not-allowed !important

        i.symbol-add
            pointer-events: none
            opacity: 0

.inline-tag-input__footer
    padding: 5px

.inline-tag-input__tag
    margin-right: 5px
    margin-top: 5px



</style>