import _ from 'underscore';
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import VuexStore from "@/store/vuex/VuexStore";
import {getBrand, getRootBrands} from "@/app/utils/Brands";
import {account, isDevEnvironment} from "@/app/utils/Account";
import {getUser} from "@/app/utils/User";
import {features} from "@/app/Features";
import Services from "@/store/Services";

/**
 * Given the currently loaded account, this will try to find a good default brand
 * to use on the mention panel, and in filters.
 *
 * This will try to be the default brand set for the account, if available.
 *
 * If not set, and the brand only has a single, unarchived, root brand, this is returned.
 *
 * Returns either the full brand info, or null.
 *
 * It will not return a deleted brand. If a default brand is set, and it has been deleted
 * at some point, this will return null
 *
 * @param alwaysGiveABrand Set to true if, after trying to find a reasonable brand to use and failing,
 * the first non-deleted brand should be returned. May still return null if there are no undeleted brands.
 *
 * @deprecated
 */
export function getDeprecatedDefaultBrand(alwaysGiveABrand) {
    alwaysGiveABrand = !!alwaysGiveABrand;

    const brands = getRootBrands();
    const defaultBrand = account().defaultBrand;

    if (!brands || !brands.length) return null;

    if (defaultBrand && defaultBrand.id) {
        const found = getBrand(defaultBrand.id);
        if (found) return found;
    }

    const unarchived = brands ? brands.filter(function(b) { return !b.archived }) : [];
    if (unarchived.length === 1) return unarchived[0];

    if (alwaysGiveABrand) {
        let sorted = _(brands).sortBy(function(b) { return b.name.toLowerCase() });
        return sorted[0];
    }

    return null;
}


/**
 * Given the total number of tested events and the number of passed, this function returns the Adjusted Wald
 * confidence intervals as an object. Eg confidence for positive mentions by supplying sample size and the number of
 * positive mentions in the sample.
 */
export function adjustedWald(sampleSize, passed, populationSize) {
    const n = sampleSize;
    const np = passed;
    const zcrit = np === 0.0 ? 1.644853476 :1.959962787; // for alpha 0.05

    const na = n + (zcrit*zcrit);
    const pa = (np + (zcrit*zcrit)/2)/na;
    let val = (pa*(1-pa))/na;
    if (populationSize) val = val * (1 - sampleSize / populationSize); // Finite population correction
    const sqroot = Math.sqrt(val);

    const p_high = pa + (zcrit * sqroot);
    const p_low = pa - (zcrit * sqroot);

    const high = sampleSize * p_high;
    let low = sampleSize * p_low;

    if (np === 0.0) low = 0.0;
    if (low < 0.0) low = 0.0;

    return {
        total: sampleSize,
        passed: passed,
        high: Math.ceil(high),
        low: Math.floor(low),
        moe: Math.ceil(sampleSize * (zcrit * sqroot))
    };
}

/**
 * Encodes a string as a filename, translating forward slashes to hyphens and preserving white space.
 * All other illegal items are encoded as per encodeURI
 */
export function encodeFilename (filename) {
    return encodeURIComponent(filename.replace(/\//g, '-')).replace(/%20/g, ' ')
}

export function cleanFilenameParts(parts) {
    let re = /[\\/:"*?<>|]+/g;
    for (let i = 0; i < parts.length; i++) parts[i].replace(re, '_');
    return parts;
}

// from http://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling
export function isEntirelyVisible(e) {
    var $window = $(window);
    var $e = $(e);

    var docViewTop = $window.scrollTop();
    var docViewLeft = $window.scrollLeft();
    var docViewBottom = docViewTop + $window.height();
    var docViewRight = docViewLeft + $window.width();

    var offset = $e.offset();
    var elemTop = offset.top;
    var elemLeft = offset.left;
    var elemBottom = elemTop + $e.height();
    var elemRight = elemLeft + $e.width();

    var yOk = elemTop >= docViewTop && elemBottom <= docViewBottom;
    var xOk = elemLeft >= docViewLeft && elemRight <= docViewRight;

//        console.log("doc x " + docViewLeft  + " y " + docViewTop + " x2 " + docViewRight + " y2 " + docViewBottom +
//            " ele x " + elemLeft + " y " + elemTop + " x2 " + elemRight + " y2 " + elemBottom +
//            " X " + xOk + " Y " + yOk);

    return yOk && xOk;
}

/**
 * Is e at least partially visible? This only checks the y-axis.
 */
export function isPartiallyVisible(e) {
    let op = !!e[0].offsetParent    // https://stackoverflow.com/a/21696585/159434
    //console.log("isPartiallyVisible has e.offsetParent " + op)
    if (!op) return false

    var $window = $(window);
    var $e = $(e);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();

    var offset = $e.offset();
    var elemTop = offset.top;
    var elemBottom = elemTop + $e.height();

//        console.log("doc y " + docViewTop + " y2 " + docViewBottom +
//            " ele y " + elemTop + " y2 " + elemBottom);

    return elemTop >= docViewTop && elemTop <= docViewBottom
        || elemBottom >= docViewTop && elemBottom <= docViewBottom;
}

/**
 * Shifts the elements from begin to end-1 further up the array (i.e., to a higher index).
 * each element in [begin, end) will now have their index incremented by 1. The element at end is
 * removed from the array, and the element at begin is now an empty element.
 */
export function shiftElementsUp (array, begin, end) {
    if (end <= begin) throw new Error("End [" + end + "] must be greater than begin [" + begin + "]");

    for (var i = end; i >= begin; i--) {
        array[i] = i - 1 >= 0 ? array[i - 1] : null;
    }
}

/**
 * Shifts the elements from begin to end-1 downthe array (i.e., to a lowerindex).
 * each element in (begin, end] will now have their index decremented by 1. The element at begin is
 * removed from the array, and the element at end is now an empty element.
 */
export function shiftElementsDown(array, begin, end) {
    if (end <= begin) throw new Error("End [" + end + "] must be greater than begin [" + begin + "]");

    for (var i = begin; i <= end; i++) {
        array[i] = i + 1 < array.length ? array[i + 1] : null;
    }
}

/**
 * Returns true if the supplied item is a function
 * @param {*} fun - the object to test
 * @return {boolean}
 */
export function isFunction(fun) {
    return typeof fun === "function";
}

/**
 * Returns true if the supplied item is not-null, and is an object.
 * @param {*} object - The object to test
 * @returns {boolean}
 */
export function isObject(object) {
    const type = typeof object;
    return object !== null && (type === 'object' || type === 'function');
}


/**
 * generate a unique string to identify this browser
 * @returns {string}
 */
export function getBrowserId() {
    let browserId = localStorage.getItem("browserId");
    if (!browserId) {
        let s = "";
        let d = new Date().getTime();   // include time to guard against poor Math.random impls
        for (let i = 0; i < 8; i++) s += ((d + Math.random() * 36) % 36 | 0).toString(36);
        localStorage.setItem("browserId", browserId = s);
    }

    return browserId;
}



/**
 * Use the brands in the filter to look for a topic tree id. Returns null if there is more than one option or
 * no options.
 */
export function getDefaultTopicViewId(filter) {
    if (!filter) return null;
    let brands = getBrandsInFilter(filter).include;
    if (!brands.length) return null;
    let treeId;
    for (let i = 0; i < brands.length; i++) {
        let b = VuexStore.getters.idToBrand.get(brands[i]);
        if (b.topicTreeId) {
            if (treeId && b.topicTreeId !== treeId) return null;
            treeId = b.topicTreeId;
        }
    }
    return treeId;
}

/**
 * Use the brands in the filter to look for a topic tree id. Returns a random topic tree if there is more than
 * one option or no options.
 * todo replace with calls to getDefaultViewId and proper error handling for no tree case
 */
export function getDefaultTopicView(filter) {
    let trees = VuexStore.state.account.topicTrees || [];
    if (trees.length !== 0) {
        return getDefaultTopicViewId(filter) || _.sortBy(trees, "id")[0].id
    }
}

/**
 * Returns true iff the user is running on macos. Note that
 * this isn't a test if the user is using safari or not, just whether
 * they are on a mac or not.
 *
 * @returns {boolean}
 */
export function isMac() {
    return navigator?.platform?.includes("Mac") ?? false;
}


/**
 * This function redirects users from the old brandseye.com domain to the new dataeq.com domain,
 * specifically just for analyse.brandseye.com (does not do this for v3, so that we can
 * continue to log into internal resources).
 *
 */
export function redirectToDataEq(startupOptions) {
    try {
        if (!features.dataEqNameChange()) return;

        // This if statement is all a hack, which will thankfully be removed when
        // this all goes live.
        if (!isDevEnvironment()) {
            startupOptions.grouseUrl = "https://api.dataeq.com";
            startupOptions.caperUrl = "https://api.dataeq.com";
            startupOptions.mashApi = "https://mash.dataeq.com/rest/";
            startupOptions.spamApi = "https://spam.dataeq.com/rest/";
            Services(startupOptions);
        }

        const hostname = location.hostname;
        if (["analyse.brandseye.com", "analyze.brandseye.com"].includes(hostname)) {
            const url = "https://analyse.dataeq.com" + location.pathname + location.search;
            console.info(`Using old ${hostname} domain. Fallback javascript redirect.`);
            window.location = url;
        }
    } catch (e) {
        console.error(e);
    }
}

