<template>
    <add-profile-dialog
        ref="addProfileDialog"
        :cur-stage="curStage"
        :enable-next-btn="enableNext"
        :next-btn-text="nextBtnText"
        :profile-type="profileTypes.googleBusiness"
        :show-next-btn="!saving && !!selectedProfileType"
        :show-prev-btn="!saving && (features.googleManagedBusinessIntegration()?!!selectedProfileType:curStage === addProfileStages.googleBusiness.public.EDIT_PAGE)"
        :show-progress-bar="!!selectedProfileType"
        :total-stages="totalStages"
        @close="$emit('close')"
        @next="next"
        @prev="previous"
    >
        <template #workflow>
            <google-select-profile-type v-if="!saving && !selectedProfileType" @select-profile="profileTypeSelected"></google-select-profile-type>

            <keep-alive>
            <!--PUBLIC WORKFLOW-->
                <google-business-search
                    v-if="!saving &&
                        selectedProfileType === addProfileTypes.googleBusiness.PUBLIC &&
                        curStage === addProfileStages.googleBusiness.public.SEARCH_PAGE"
                />
                <edit-public-profile v-if="!saving && curStage === addProfileStages.googleBusiness.public.EDIT_PAGE" />
            <!--PUBLIC WORKFLOW-->
            <!--MANAGED WORKFLOW-->
            <select-managed-profiles :profile-type="profileTypes.googleBusiness" v-if="!saving && selectedProfileType === addProfileTypes.googleBusiness.MANAGED && curStage === addProfileStages.googleBusiness.managed.SELECT_PROFILES"/>
            <!--MANAGED WORKFLOW-->
            <saving-item
                v-if="saving"
                :save-complete="saveComplete"
                :save-complete-message="saveCompleteMessage"
                :save-error="saveError"
                :save-error-message="saveErrorMessage"
                :saving-message="saveMessage"
            >
                <template #additionalActions>
                    <h4 style="text-align: center; margin-top: 50px">
                        Click <a style="cursor: pointer" @click="reset">here</a> to add more
                        <i class="symbol-google-full"></i> Google business profiles
                    </h4>
                </template>
            </saving-item>
        </keep-alive>
        </template>
    </add-profile-dialog>
</template>

<script>
import { features } from "@/app/Features";
import SavingItem from "@/setup/SavingItem";
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import { addProfileMixins } from "@/setup/profiles/add-profile/AddProfileMixins";
import EditPublicProfile from "@/setup/profiles/add-profile/EditPublicProfile.vue";
import SelectManagedProfiles from "@/setup/profiles/add-profile/SelectManagedProfiles";
import GoogleBusinessSearch from "@/setup/profiles/add-profile/googlebusiness/GoogleBusinessSearch.vue";
import GoogleSelectProfileType from "@/setup/profiles/add-profile/googlebusiness/GoogleSelectProfileType";
import VuexStore from "@/store/vuex/VuexStore";
import { mapMutations } from "vuex";


export default {
    name: "GoogleAddBusinessDialog",
    mixins: [addProfileMixins],
    data: function() {
        return {
            features: features,
            curStage: 1,
            selectedProfileType: null,
        };
    },

    components: {
        GoogleBusinessSearch,
        EditPublicProfile,
        SavingItem,
        AddProfileDialog,
        GoogleSelectProfileType,
        SelectManagedProfiles
    },
    store: VuexStore,

    created() {
        if(!features.googleManagedBusinessIntegration()){
            this.selectedProfileType = this.addProfileTypes.googleBusiness.PUBLIC;
            this.totalStages = Object.keys(this.addProfileStages.googleBusiness.public).length;
        }
    },

    computed: {

        enableNext() {
            switch (this.selectedProfileType) {
                case this.addProfileTypes.googleBusiness.PUBLIC:
                    // If we are on the edit stage, we only allow saving if the profile has a category set
                    if (this.curStage === this.addProfileStages.googleBusiness.public.EDIT_PAGE) {
                        return !!this.newProfileData.publicProfile?.media;
                    }
                    // If we are on the search stage, we only enable next if the profile is not currently in the account (check "existing" field)
                    if (this.curStage === this.addProfileStages.googleBusiness.public.SEARCH_PAGE) {
                        return !this.newProfileData.publicProfile?.existing && !!this.newProfileData.publicProfile;
                    }
                    return !!this.newProfileData.publicProfile;
                case this.addProfileTypes.googleBusiness.MANAGED:
                    return !!this.newProfileData.managedProfiles.profiles?.length;
                default:
                    return false;
            }
        },

        nextBtnText() {
            // if we are on the final stage, show "Save" instead of "Next"
            return this.curStage === this.totalStages - 1 ? "Save" : "Next";
        }
    },
    methods: {
        ...mapMutations("profiles", ["setNewPublicProfile"]),

        async next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                switch (this.selectedProfileType) {
                    case this.addProfileTypes.googleBusiness.PUBLIC:
                        this.savePublicProfile();
                        break;
                    case this.addProfileTypes.googleBusiness.MANAGED:
                        this.saveManagedProfiles(this.profileTypes.googleBusiness);
                        break;
                }
            }
        },

        previous() {
            this.curStage--;
            if (this.curStage === 0) {
                this.curStage = 1;
                this.selectedProfileType = null;
            }
        },

        profileTypeSelected(profileType) {
            this.$refs.addProfileDialog.setTransitionToNext();
            this.selectedProfileType = profileType;

            switch(profileType) {
                case this.addProfileTypes.googleBusiness.PUBLIC:
                    this.totalStages = Object.keys(this.addProfileStages.googleBusiness.public).length;
                    break;
                case this.addProfileTypes.googleBusiness.MANAGED:
                    this.totalStages = Object.keys(this.addProfileStages.googleBusiness.managed).length;
                    break;
            }
        },

        reset() {
            this.curStage = 1;
            this.selectedProfileType = null;
            this.saveComplete = false;
            this.saving = false;
            this.clearNewProfiles();
            if(!features.googleManagedBusinessIntegration()){
                this.selectedProfileType = this.addProfileTypes.googleBusiness.PUBLIC;
                this.totalStages = Object.keys(this.addProfileStages.googleBusiness.public).length;
        }
        }
    }
};
</script>

<style lang="sass" scoped>

.hellopeter-add-profile-dialog

    &__config
        display: flex
        flex-direction: column
        row-gap: 5px

    &__text-input
        height: 80px

    &__brands-list
        border: 1px solid #888
        border-radius: 4px
        max-height: 80px
        overflow-y: auto

        ul
            list-style: none
            margin: 0

        li
            padding: 0 5px
            line-height: 25px

            &:nth-child(odd)
                background: #666

            &:nth-child(even)
                background: #3c3c3c

            &:last-of-type
                margin-bottom: 0

    ::v-deep input
        width: 80%
</style>
