<template>
    <div class="search-input" tabindex=0 @focus="focus" @keydown.esc="blur()">
        <label :for="componentId" class="search-icon"><i class="symbol-search"></i></label>
        <input
            @input="updateSearchText"
            @keydown="$emit('keydown', $event)"
            :id="componentId"
            :value="value"
            ref="input"
            type="text"
            class="search"
            :placeholder="placeholder"
            autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
            :disabled="disabled"
        >
    </div>
</template>

<script>
import { uniqueId } from 'underscore'

export default {
    data() {
        return {
            componentId: uniqueId('search-input')
        }
    },
    props: {
        value: String,
        autofocus: Boolean,
        placeholder: {
            type: String,
            default: "Search"
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        updateSearchText(event){
            setTimeout(() => {
                this.$emit('input', event.target.value)
            });
        },
        focus(){
            this.$refs.input?.focus()
        },
        blur(){
            this.$refs.input?.blur()
        }
    },
    mounted(){
        // nextTick doesn't consistently work here. So using a timeout.
        if (this.autofocus) {
            setTimeout(() => this.focus(), 100)
        }
    }
}
</script>

<style scoped lang="sass">

.search-input
    position: relative

input.search
    margin: 0
    padding-left: 25px
    width: 100%
    height: 100%
    box-sizing: border-box
    &::placeholder
        color: var(--sidebar-grey)

.search-icon
    position: absolute
    color: var(--sidebar-grey)

.search-icon
    left: 3px
    top: 0
    bottom: 0
    margin: auto 0
    height: fit-content

</style>