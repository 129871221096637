/**
 * Enter one or phrase search terms. Fires a change event when the selection is changed. Also provides a model binder
 * converter to display the selected options in an element.
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";
import {parsePhraseString, phraseCombiner} from "@/app/utils/Phrases";

Beef.module("PhraseTermPicker").addInitializer(function(startupOptions) {

    var thisModule = this;

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "phrase-term-picker auto-complete-picker" },

        // Temporarily disabled due to bug:
        items: {},
        /*
        items: function (q, view, callback) {
            var collection = view.model.collection;
            if (!collection) {
                callback([]);
                return;
            }
            var currentPhrases = _.flatten(_.compact(collection.map(function (model) {
                return Beef.PhraseUtil.parse(model.get('phrase'));
            })));
            $.get("/api/suggest-phrases", {"currentPhrases": JSON.stringify(currentPhrases)}, callback, "json")
                .fail(function () {
                    callback([]);
                });
        },
        */

        mustSelectFromItems: false,

        cleanupInput: function(s) {
            return s.toLowerCase().trim();
        }
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            items: function(code) { return code },
            placeholder: placeholder,
            splitter: parsePhraseString,
            combiner: phraseCombiner
        });
    };

    /**
     * Attach the picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        options = options || {};
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View,
            {
                quoteCodes: '"',
                splitter: parsePhraseString,
                combiner: phraseCombiner,
                startDroppedDown: true,
                cleaningFunction: function(value) {
                    return thisModule.cleanPhraseTerm(value, options.forContentMatch, options.escapeApostrophe);
                }
            });
    };

    this.cleanPhraseTerm = function(value, forContentMatch, escapeApostrophe) {
        escapeApostrophe ??= false;

        if (!value) return null;
        var notAllowed;
        if (escapeApostrophe) {
            notAllowed = forContentMatch ? /["]+/g : /["*]+/g;
        } else {
            notAllowed = forContentMatch ? /['"]+/g : /['"*]+/g;
        }
        value = value.replace(notAllowed, '').trim();
        if (value.indexOf('+') == 0) value = value.substring(1);
        return value;
    }
});
