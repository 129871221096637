/**
 * Displays a spinner animated using only CSS, providing smoother animations
 * than our old gif spinner.
 */
Beef.module("Ui.Spinner").addInitializer(function(startupOptions) {

    this.renderSpinner = function(size) {
        var style = [];
        size = size || 48;

        style.push("--css-spinner-size: " + size + "px");

        if (size <= 20) {
            style.push("--css-wedge-size: 3px");
        }


        return '<div class="css-spinner" style="' + style.join('; ') +'"><div></div><div></div><div></div><div></div></div>';
    }


});