/**
 * Choose a dashboard font.
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("FontPicker").addInitializer(function(startupOptions) {

    /**
     * If you change this list, update the list on Beef server side.
     */
    this.items = {
        "roto-kufi": "Roto Kufi Arabic",
        "open-sans": "Open Sans"
    };

    this.getFont = function(code) {
        return Beef.FontPicker.items[code] || code;
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "font-picker auto-complete-picker" },
        items: this.items,
        startDroppedDown() { return true }
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({items: this.items, placeholder: placeholder});
    };

    this.converter = this.createConverter("Font name");

    /**
     * Attach a currency picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
