/**
 * A popup template for setup popups containing date filters. Instantiating it
 * will not produce a valid stand-alone view.
 */
import {formatNumber} from "@/app/utils/Format";
import moment from "moment";
import {publishedToLabel} from "@/components/pickers/dates/DateRangePickerUtilities";


Beef.module("HistoricalSearch.FilterPopup").addInitializer(function() {

    this.View = Backbone.Marionette.Layout.extend({

        attributes: {
            class: "dialog setup-popup"
        },

        regions: {
            filterRegion: "#filter-region"
        },

        modelEvents: {
            "change": "render"
        },

        events: {
            "click .ok": "ok",
            "click .cancel": "cancel",
            "click .dialog-title .close": "cancel",
            "click .reset": "resetFilter",
            "click .published": "clickPublished",
            "click": "closePopups"
        },

        initialize: function() {
            var job = this.model.get("job");
            var chartDates = this.model.get("chart").getXDomain();
            var start = (chartDates && chartDates[0] && chartDates[1]) ? chartDates[0] : job.searchRangeStartDate;
            var end = (chartDates && chartDates[0] && chartDates[1]) ? chartDates[1] : job.searchRangeEndDate;

            // the published picker has dates in the user's time zone, but is being used as if it is in UTC.
            // the dates have to be offset so that they get the correct UTC date, since they are currently
            // in the user's time zone.
            var offset = moment().zone();
            var calendarOptions = {
                minDate: moment(job.searchRangeStartDate).utc().startOf("day").add(offset, "minutes").toDate(),
                maxDate: moment(job.searchRangeEndDate).utc().endOf("day").add(offset, "minutes").toDate()
            };
            this.model.set("calendarOptions", calendarOptions);

            // the start and end dates might have been set to an arbitrary time of the day, e.g. if the chart was
            // brushed or zoomed. broccoli retrieves data for a full day, so the dates must be rounded when shown
            // in the published picker.
            var clamped = Beef.SearchJobFilter.roundAndClampDates(start, end);
            this.model.set({ step: 0, selectedStartDate: clamped.start, selectedEndDate: clamped.end });
            this.onInitialize();
        },

        // Override this
        onInitialize: function() {},

        /**
         * Closes the setup popup.
         */
        cancel: function() {
            Beef.Popup.closePopups(this);
            Beef.BoundItemView.prototype.close.call(this);
        },

        // Override this
        ok: function(ev) {},

        /**
         * Renders the filter region in the retrieval setup.
         */
        showFilterRegion: function() {
            var job = this.model.get("job");
            if (job && !this.filterRegion.currentView) {
                var that = this;
                var start = this.model.get("selectedStartDate");
                var end = this.model.get("selectedEndDate");
                var published = Beef.EditSearchJob.dateToPublished(start, end);
                var volume = this.getVolume(start, end);
                this.model.set({ volume: volume }, { silent: true });
                this.setVolumeLabel(volume);

                var view = Beef.HistoricalSearch.RetrievalSetupPopupFilter.createView(published, this.model.get("calendarOptions"));
                view.model.on("change:published", function() {
                    var range = clampByJob(view.getDateRange(), that.model.get("job"));
                    var volume = that.model.get("chart").sumOverRange([range.start, range.end], true);
                    that.setVolumeLabel(volume);
                    that.model.set({
                        volume: volume,
                        selectedStartDate: range.start,
                        selectedEndDate: range.end
                    }, { silent: true });
                    // the picker cannot be re-rendered since it inherits from Beef.BoundItemView.
                    // the label must be manually updated
                    var published = Beef.EditSearchJob.dateToPublished(range.start, range.end);
                    var label = publishedToLabel(published);
                    var picker = view.$el.find(".published");
                    picker.data("value", label);
                    picker.html(label);
                    view.popup.contents.currentView.model.set("selection", published);
                    view.popup.contents.currentView.updateView();
                    that.filterRegion.currentView.model.set("published", published);
                });
                this.filterRegion.show(view);
            }
        },

        getVolume: function(start, end) {
            return this.model.get("chart").sumOverRange([start, end], true);
        },

        /**
         * Returns a human readable label for a volume count.
         */
        createVolumeLabel: function(volume) {
            var count = volume ? formatNumber(volume) : 0;
            return " approximately " + count + " " + (volume == 1 ? "mention" : "mentions");
        },

        /**
         * Updates the volume label in the setup.
         */
        setVolumeLabel: function(volume) {
            var label = this.createVolumeLabel(volume);
            $(this.el).find(".volume-label").html(label);
        },

        clickPublished: function(ev) {
            // prevent the date picker's popup from closing when trying to open.
            // the published picker will handle the event.
            ev.stopPropagation();
        },

        /**
         * Closes all popups that were created from the current popup.
         */
        closePopups: function() {
            this.closeCalendarAndRender();
        },

        /**
         * Closes the calendar popup and updates the retrieval setup view.
         */
        closeCalendarAndRender: function() {
            if (this.filterRegion && this.filterRegion.currentView && this.filterRegion.currentView.popup) {
                this.filterRegion.currentView.popup.close(); // close the date picker's popup
            }
            this.render(); // any model changes that were made silently should be updated
        },

        /**
         * Resets the date filter to the published filter on the search job.
         */
        resetFilter: function(ev) {
            ev.stopPropagation();
            var job = this.model.get("job");
            var clamped = Beef.SearchJobFilter.roundAndClampDates(job.searchRangeStartDate, job.searchRangeEndDate);
            this.model.set({ selectedStartDate: clamped.start, selectedEndDate: clamped.end }, { silent: true });
            this.closeCalendarAndRender();
        }
    });

    /**
     * Returns a clamped date range. The start and end dates are clamped such that they always fall within the job's
     * start and end dates.
     * @return {{start: Date, end: Date}}
     */
    function clampByJob(range, job) {
        var start = moment(range.start).utc();
        var end = moment(range.end).utc();
        var startMin = moment(job.searchRangeStartDate).utc().startOf("day");
        var endMin = moment(job.searchRangeStartDate).utc().endOf("day");
        var startMax = moment(job.searchRangeEndDate).utc().startOf("day");
        var endMax = moment(job.searchRangeEndDate).utc().endOf("day");
        if (end.isAfter(endMax)) {
            end = endMax;
        } else if (end.isBefore(endMin)) {
            end = endMin;
        }
        if (start.isBefore(startMin)) {
            start = startMin;
        } else if (start.isAfter(startMax)) {
            start = startMax;
        }
        return { start: start.toDate(), end: end.toDate() };
    }
});
