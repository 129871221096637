import _ from 'underscore';
import moment from "moment";


/**
 * Low level d3 code to render the matrix stripes. This is separate to facilitate quick development and testing
 * using a static HTML file.
 */
export default function beefRenderMatrix(domNode, width, height, data, options) {

    options = options || {};

    var owner = options.owner || {};
    var timeBucket = options.timeBucket || 'day';
    var barField = options.barField || 'sentiment';     // sentiment, count (volume), engagement, authorNames
    var showBubbles = options.vis === 'bubbles';
    var hideSmallRows = options.hideSmallRows || false;
    var hideSmallCols = options.hideSmallCols || false;

    var showNetSentiment = barField == 'netSentiment';
    var showSentiment = showNetSentiment || barField == 'sentiment';
    if (showSentiment) barField = 'count';

    var undefZero = function(n) { return n === undefined  ? 0 : n };
    var percentage = function(n, d) { return d ? undefZero(n) * 100 / d : 0 };
    var parsePublished = d3.timeParse("%Y-%m-%d");

    var pubDates = { };
    data.forEach(function(d) {
        pubDates[d.published] = true;
        d.date = parsePublished(d.published);
        if (d.positiveCount === undefined) d.positiveCount = 0;
        if (d.negativeCount === undefined) d.negativeCount = 0;
        d.netSentimentCount = d.positiveCount - d.negativeCount;
        d.pos = percentage(d.positiveCount, d.count);
        d.neg = percentage(d.negativeCount, d.count);
    });

    // add missing published dates (i.e. buckets where there was no data in the db) .. otherwise the chart behaves
    // badly when animating over sparse data sets .. sometimes 3 bars, sometimes 4 and so on
    if (options.start && options.end) fillInMissingPublishedDates(options.start, options.end, timeBucket, pubDates);

    var xDomain = Object.keys(pubDates).sort();

    var plural = function(label, v) { return label + (v == 1 ? "" : "s") };

    var barFields = {
        count:          { label: "Mention" },
        engagement:     { label: "Engagement" },
        authorNames:    { label: "Author" }
    };

    // group the data into cells with one cell for each possible row and column value in the data set

    var rowField = options.rowField;
    var colField = options.colField;
    var labelLookup = options.labelLookup || function(field, value, data) { return { label: "" + value } };

    var extractFieldValues = function(field, rowOrCol) {
        var ans = [], i;
        if (field) {
            _.each(_.groupBy(data, field), function(buckets, value) {
                var b = buckets[0];
                var o = labelLookup(field, value, b);
                var rc = { value: value, label: o.label, description: o.description, sortIndex: b[field + "Index"]};
                ans.push(rc);
                for (i = 0; i < buckets.length; i++) buckets[i][rowOrCol] = rc;
            });
            if (ans.length > 0) ans = _.sortBy(ans, ans[0].sortIndex ? 'sortIndex' : 'label');
            for (i = 0; i < ans.length; i++) ans[i].index = i;
        } else {
            var rc = {index: 0};
            ans.push(rc);
            for (i = 0; i < data.length; i++) data[i][rowOrCol] = rc;
        }
        return ans;
    };

    var rows = extractFieldValues(rowField, "row");
    var cols = extractFieldValues(colField, "col");

    // create a cell object for each possible row and column combination containing all the correct data buckets
    var cellMap = new Map();
    for (var i = 0; i < rows.length; i++) {
        for (var j = 0; j < cols.length; j++) {
            cellMap[rows[i].index + "," + cols[j].index] = { row: rows[i], col: cols[j], buckets: []}
        }
    }
    _.each(data, function(bucket) {
        var cell = cellMap[bucket.row.index + "," + bucket.col.index];
        bucket.cell = cell;
        cell.buckets.push(bucket);
    });

    if (hideSmallRows || hideSmallCols) {
        calcRowAndColSums(rows, cols, cellMap); // sum the total of the bars for each row and col
        if (hideSmallRows) rows = hideSmallRowsOrCols(rows);
        if (hideSmallCols) cols = hideSmallRowsOrCols(cols);
    }

    var cells = [];
    for (i = 0; i < rows.length; i++) {
        for (j = 0; j < cols.length; j++) {
            var cell = cellMap[rows[i].index + "," + cols[j].index];
            if (cell) {
                cell.buckets = _.sortBy(cell.buckets, "published");
                cells.push(cell);
            }
        }
    }

    if (cells.length == 0) {
        d3.select(domNode).append("div").attr("class", "no-data").text("No data found");
        return;
    }

    // some of the rows and cols might have been removed so assign new indexes
    for (i = 0; i < rows.length; i++) rows[i].index = i;
    for (i = 0; i < cols.length; i++) cols[i].index = i;

    // figure out how big our main chart can be and the size of each mini-chart

    var margin = {top: 16 + (colField ? 52 : 0), right: 16, bottom: 70, left: 16 + (rowField ? 92 : 0)};

    var mcHeight = (height - margin.top - margin.bottom) / rows.length;
    if (mcHeight < 80) mcHeight = 80;

    var mcWidth = (width - margin.left - margin.right) / cols.length;

    if (showBubbles) {
        if (mcWidth < 80) mcWidth = 80;
        if (mcHeight < mcWidth) mcWidth = mcHeight;
        else mcHeight = mcWidth;
    } else {
        if (mcWidth < 120) mcWidth = 120;
        // don't let the bars inside be more than 3x as wide as they are tall (xDomain.length is the number of bars)
        if (mcWidth / xDomain.length > mcHeight / 3) {
            mcWidth = mcHeight / 3 * xDomain.length;
        }
        // if there are column labels then make sure we always have space
        if (colField && mcWidth < 120) mcWidth = 120;

        if (mcHeight > mcWidth) mcHeight = Math.max(mcWidth, 80);
    }

    mcHeight = Math.floor(mcHeight);
    mcWidth = Math.floor(mcWidth);

    var desiredMcSpacing = Math.floor(mcHeight / 20); //ratio of height
    var allowedSpacing = Math.min( Math.max( desiredMcSpacing, 3), 10); // between 3 and 10
    var mcColSpacing = !showBubbles && rows.length > 1 ? allowedSpacing : 0;
    var mcRowSpacing = !showBubbles && cols.length > 1 ? allowedSpacing : 0;

    var mcInnerHeight = mcHeight - mcColSpacing;
    var mcInnerWidth = mcWidth - mcRowSpacing;

    // mcInnerWidth must divide evenly by the number of columns or there is extra padding on the left and right
    // http://stackoverflow.com/questions/37134085/unneeded-white-space-before-the-1st-bar-in-d3-stack-chart
    mcInnerWidth = Math.floor(mcInnerWidth / xDomain.length) * xDomain.length;
    mcWidth = mcInnerWidth + mcRowSpacing;

    var chartHeight = mcHeight * rows.length;
    var chartWidth = mcWidth * cols.length;

    var sparklineWidth = Math.max(mcHeight / 150, 1.5);

    // the stripes share the same scales so they can be compared to each other

    var x = d3.scaleBand().rangeRound([0, mcInnerWidth]).padding(.05).domain(xDomain);

    // this is for the backing bars on the charts .. they need to have no space between them for the tooltips to
    // work nicely
    var xbg = d3.scaleBand().rangeRound([0, mcInnerWidth]).domain(xDomain);

    var barScale = d3.scaleLinear()
            .range([mcInnerHeight, 0])
            .domain([0, d3.max(data, function(d) { return d[barField] })]);

    if (showSentiment) {
        // each cell has its own sentiment scales, one for pos and one for neg
        _.each(cells, function(cell) {
            var domain = [0, d3.max(cell.buckets, function(d) { return Math.max(d.negativeCount, d.positiveCount) })];
            cell.posScale = d3.scaleLinear().domain(domain).range([mcInnerHeight / 2, 0]);
            cell.negScale = d3.scaleLinear().domain(domain).range([mcInnerHeight / 2, mcInnerHeight]);
        });
    }

    // these are the colours used for the bar charts

    var palette = options.palette || ['#58B6FF'];
    var barColor = palette[0];

    // this chart is rendered onto a single svg element

    var svg = d3.select(domNode).append("svg")
        .attr("width", Math.max(width, chartWidth + margin.left + margin.right))
        .attr("height", Math.max(height, chartHeight + margin.top + margin.bottom));

    if (mcHeight <= 100) svg.attr("class", "small");

    var chart = svg.append("g")
        .attr("class", "stripes")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // create the mini-charts

    var miniCharts = chart.selectAll(".mini-chart").data(cells).enter()
        .append("g").attr("class", "mini-chart")
        .attr("data-row", function(cell) { return cell.row.value })
        .attr("data-col", function(cell) { return cell.col.value })
        .attr("transform", function(cell) {
            return "translate(" + cell.col.index * mcWidth + "," + cell.row.index * mcHeight + ")"
        });

    // put in the chart background and left and top baselines
    miniCharts.append("rect")
        .attr("class", 'bg').attr("x", 0).attr("width", mcInnerWidth).attr("y", 0).attr("height", mcInnerHeight);

    if (showBubbles) {
        if (showSentiment) barColor = "#969696";

        var circleScale = d3.scalePow().exponent(0.5)
            .domain(barScale.domain())
            .range([0, mcInnerHeight / 2 - 4]);

        var cx = Math.floor(mcInnerHeight / 2);

        var d3nf = d3.format(".2s");
        var nf = function(v) { return v < 1000 ? "" + v : d3nf(v) };

        var bubbles = miniCharts.append("g")
            .attr("class", "bubble")
            .attr("transform", "translate(" + cx + "," + cx + ")");

        bubbles.each(function(cell) {
            var b = cell.buckets[0];
            if (!b) return;
            var v = b[barField];
            var r = circleScale(v);
            var sel = d3.select(this);
            var arc = d3.arc().innerRadius(r >= 30 ? 20 : 0).outerRadius(r);
            var pie = d3.pie().sortValues(null).value(function(d) {return d.v });
            var va = [];
            if (showSentiment) {
                if (b.positiveCount) va.push({ v: b.positiveCount, cls: 'pos' });
                if (b.neutralCount) va.push({ v: b.neutralCount, cls: "neutral" });
                if (b.negativeCount) va.push({ v: b.negativeCount, cls: 'neg' });
                if (va.length == 0) va.push({ v: 0, cls: "neutral" });
            } else {
                va.push({v: v, cls: 'vol'});
            }
            var pieData = pie(va);
            var slices = sel.selectAll(".slice").data(pieData).enter()
                .append("path")
                .attr("class", function(d) { return "slice " + d.data.cls } )
                .attr("d", arc);
            if (!showSentiment) slices.attr("fill", barColor).attr("stroke", barColor);
        });

        var bubbleLabels = bubbles.append("text")
            .attr("class", "bubble-label")
            .text(function(cell) {
                var b = cell.buckets[0];
                return b ? nf(b[barField]) : null;
            })
            .attr("y", 4)
            .attr("text-anchor", "middle");

        bubbleLabels.each(function(cell) {
            var b = cell.buckets[0];
            if (!b) return;
            var r = circleScale(b[barField]);
            var sel = d3.select(this).style("fill", "#666");
            if (r < 30) sel.attr("y", - (r + 6));
        });

        bubbleLabels.append("title")
            .text(function(cell) {
                var b = cell.buckets[0];
                return b ? b[barField] + " " + plural(barFields[barField].label, v) : null;
            });

        plotTooltipBars();

    } else {
        plotTooltipBars();
        if (showSentiment) plotSentiment();
        else plotBars();

        // put in the mid point gridline
        var zeroPoint = mcInnerHeight / 2;
        miniCharts.append('line').attr('class', 'gridline')
            .attr("stroke-dasharray", "1,1")
            .attr('x1', 0).attr('x2', mcInnerWidth).attr('y1', zeroPoint).attr('y2', zeroPoint);
    }

    plotRowLabels();
    if (colField) plotColumnLabels();
    if (cols.length <= 1) plotDateLabels();

    // put in the bar and sentiment legends

    var legendY = margin.top + mcHeight * rows.length + 28 + 0;
    var legendHeight = 16;

    var lgBarWidth = Math.min(x.bandwidth(), legendHeight);
    var v = barScale.domain()[1];
    var barLabel = showSentiment ? "" : d3.format(".2s")(v) + " " + plural(barFields[barField].label, v);
    var barLegendWidth = lgBarWidth + barLabel.length * 7;
    var legendWidth;
    if (showBubbles) {
        legendWidth = barLegendWidth = showSentiment ? 218 : 80;
    } else {
        legendWidth = barLegendWidth + (cols.length > 1 ? mcWidth : 0);
    }

    var legendX;
    if (showBubbles) {
        // center under the chart area if the legend fits, otherwise under the full SVG width
        if (legendWidth <= chartWidth) legendX = margin.left + (chartWidth - legendWidth) / 2;
        else legendX = (chartWidth + margin.left + margin.right - legendWidth) / 2;
    } else {
        // if we have only one column center legend under the chart if possible, otherwise use full SVG width
        legendX = (mcWidth - legendWidth) / 2;
        if (legendX < 0 || cols.length > 1) legendX = (chartWidth + margin.left + margin.right - legendWidth) / 2;
        else legendX += margin.left;
    }
    if (legendX < 8) legendX = 8;

    var legend = svg.append("g")
        .attr("class", "legend")
        .attr("transform", "translate(" + legendX + "," + legendY + ")");
    var nextLegendX = 0;

    if (showBubbles) {
        plotBubbleLegend();
    } else {
        if (!showSentiment) plotBarLegend();
        if (cols.length > 1) plotTimeLegend();
    }

    // all done .. functions follow

    function percent(num, denom) {
        return denom <= 0 ? 'n/a' : (Math.floor(num * 100 / denom + 0.5) + "%");
    }

    function plotTooltipBars() {
        // put in the wider tooltip background bars
        var f;
        if ('week' == timeBucket) f = "Week of %a %d %b %Y";
        else if ('month' == timeBucket) f = "%b %Y";
        else f = "%a %d %b %Y";
        var tipDateFormat = d3.timeFormat(f);

        function hideTip(d) {
            if (owner.__tip) {
                owner.__tip.destroy();
                owner.__tip = null;
            }
        }

        var showTip = function(d) {
            hideTip();
            var tip = d3.tip().attr('class', 'matrix-tip').offset([showBubbles ? 10 : 4, 0]).direction('s')
                .html(function(d) {
                    if (d.buckets) d = d.buckets[0];
                    var s = showBubbles ? "" : "<div class='date'>" + tipDateFormat(d.date) + "</div>";
                    if (!showSentiment) {
                        var v = d[barField];
                        s += "<div class='main-value'>" + v + " " + plural(barFields[barField].label, v) + "</div>";
                    } else if (showBubbles) {
                        var nc = d.netSentimentCount < 0 ? 'neg' : d.netSentimentCount > 0 ? 'pos' : '';
                        s +=
                            "<table class='sentiment'><tbody>" +
                            "<tr>" +
                                "<td class='pos'>" + percent(d.positiveCount, d.sampleSize) + "</td><td>Positive</td>" +
                                (d.posPercentageMOE ? "<td>&plusmn; " + d.posPercentageMOE + "</td>" : "") +
                            "</tr>" +
                            "<tr>" +
                                "<td class='neg'>" + percent(d.negativeCount, d.sampleSize) + "</td><td>Negative</td>" +
                                (d.negPercentageMOE ? "<td>&plusmn; " + d.negPercentageMOE + "</td>" : "") +
                            "</tr>" +
                            "<tr><td class='" + nc + "'>" + percent(d.netSentimentCount, d.sampleSize) + "</td><td>Net Sentiment</td></tr>" +
                            "</tbody></table>";
                    } else {
                        s +=
                            "<table class='sentiment'><tbody>" +
                            "<tr class='pos'><td>" + d.positiveCount + "</td><td>Positive</td></tr>" +
                            "<tr class='neg'><td>" + d.negativeCount + "</td><td>Negative</td></tr>" +
                            "<tr><td>" + d.netSentimentCount + "</td><td>Net Sentiment</td></tr>" +
                            "</tbody></table>";
                    }
                    if (barField != 'count' || showSentiment) {
                        s += "<div class='notes'>";
                        if (d.sampleSize) s += "<div>" + d.sampleSize + " Sample size</div>";
                        s += "<div>" + d.count + " Mention" + (d.count == 1 ? "" : "s") + "</div>";
                        s += "</div>";
                    }
                    return s;
                });
            owner.__tip = tip;
            tip(d3.select(this));
            tip.show.apply(this, arguments);
        };

        if (showBubbles) {
            miniCharts.selectAll(".bubble")
                .on('mouseenter', showTip)
                .on('mouseout', hideTip)
                .on("click", options.onChartClick)
        } else {
            miniCharts.selectAll(".tt-bar")
                .data(function(cell) { return cell.buckets })
                .enter().append("rect")
                .attr("class", "tt-bar")
                .attr("data-published", function(d) { return d.published })
                .attr("x", function (d) { return xbg(d.published) })
                .attr("width", xbg.bandwidth())
                .attr("y", 0)
                .attr("height", mcInnerHeight)
                .on('mouseenter', showTip)
                .on('mouseout', hideTip)
                .on("click", options.onChartClick)
        }
    }

    function plotBars() {
        // put in the volume (or engagement etc.) bars
        miniCharts.selectAll(".bar")
            .data(function(cell) { return cell.buckets })
            .enter().append("rect")
            .attr("class", "bar")
            .attr("data-published", function(d) { return d.published })
            .attr("x", function(d) { return x(d.published); })
            .attr("width", x.bandwidth())
            .attr("y", function(d) { return barScale(d[barField]); })
            .attr("height", function(d) { return barScale.range()[0] - barScale(d[barField]); })
            .attr("fill", barColor);
    }

    function plotSentiment() {
        var y1 = mcHeight / 2;
        var y2 = mcHeight;

        var negGradientId = "matrix-stripes-neg-gradient_" + Math.floor(y1) + "_" + Math.floor(y2);
        var ng = svg.append("linearGradient")
            .attr("id", negGradientId)
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", 0).attr("y1", y1)
            .attr("x2", 0).attr("y2", y2);
        ng.append("stop").attr("class", "neg").attr("offset", "0").attr("stop-opacity", "0.15");
        ng.append("stop").attr("class", "neg").attr("offset", "100%").attr("stop-opacity", "0.5");

        y1 = 0;
        y2 = mcHeight / 2;

        var posGradientId = "matrix-stripes-pos-gradient_" + Math.floor(y1) + "_" + Math.floor(y2);
        var pg = svg.append("linearGradient")
            .attr("id", posGradientId)
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", 0).attr("y1", y1)
            .attr("x2", 0).attr("y2", y2);
        pg.append("stop").attr("class", "pos").attr("offset", "0").attr("stop-opacity", "0.5");
        pg.append("stop").attr("class", "pos").attr("offset", "100%").attr("stop-opacity", "0.15");

        var netGradientId;
        if (showNetSentiment) {
            y1 = 0;
            y2 = mcHeight;
            netGradientId = "matrix-stripes-net-gradient_" + Math.floor(y1) + "_" + Math.floor(y2);
            var netg = svg.append("linearGradient")
                .attr("id", netGradientId)
                .attr("gradientUnits", "userSpaceOnUse")
                .attr("x1", 0).attr("y1", y1)
                .attr("x2", 0).attr("y2", y2);
            netg.append("stop").attr("class", "pos").attr("offset", "0"); //.attr("stop-opacity", "0.5");
            netg.append("stop").attr("class", "pos").attr("offset", "45%"); //.attr("stop-opacity", "0.5");
            netg.append("stop").attr("class", "neg").attr("offset", "50%"); //.attr("stop-opacity", "0.15");
            netg.append("stop").attr("class", "neg").attr("offset", "100%"); //.attr("stop-opacity", "0.15");
        }

        miniCharts.selectAll(".pos-bar")
            .data(function(cell) { return cell.buckets })
            .enter().append("rect")
            .attr("class", "pos-bar")
            .attr("x", function(d) { return x(d.published); })
            .attr("width", x.bandwidth())
            .attr("y", function(d) { return d.cell.posScale(d.positiveCount) })
            .attr("height", function(d) { return d.cell.posScale.range()[0] - d.cell.posScale(d.positiveCount) })
            .style('fill', "url(#"  + posGradientId + ")");

        miniCharts.selectAll(".neg-bar")
            .data(function(cell) { return cell.buckets })
            .enter().append("rect")
            .attr("class", "neg-bar")
            .attr("x", function(d) { return x(d.published); })
            .attr("width", x.bandwidth())
            .attr("y", function(d) { return d.cell.negScale.range()[0] })
            .attr("height", function(d) { return d.cell.negScale(d.negativeCount) - d.cell.negScale.range()[0] })
            .style('fill', "url(#"  + negGradientId + ")");

        var line = d3.line()
            .curve(d3.curveBasis)
            .x(function(d) { return x(d.published) + x.bandwidth() / 2 });

        if (showNetSentiment) {
            miniCharts.append('path')
                .attr('class', 'sparkline net')
                .style('stroke-width', sparklineWidth)
                .style('stroke', "url(#"  + netGradientId + ")")
                .attr('d', function(cell) {
                    line.y(function(d) {
                        var ns = d.netSentimentCount;
                        return ns >= 0 ? cell.posScale(ns) : cell.negScale(-ns);
                    });
                    return line(cell.buckets)
                });
        } else {
            miniCharts.append('path')
                .attr('class', 'sparkline pos')
                .style('stroke-width', sparklineWidth)
                .attr('d', function(cell) {
                    line.y(function(d) { return cell.posScale(d.positiveCount) });
                    return line(cell.buckets)
                });

            miniCharts.append('path')
                .attr('class', 'sparkline neg')
                .style('stroke-width', sparklineWidth)
                .attr('d', function(cell) {
                    line.y(function(d) { return cell.negScale(d.negativeCount) });
                    return line(cell.buckets)
                });
        }
    }

    function wrapLabel(d, maxWidth, position, textAnchor) {
        var g = d3.select(this).append("g");
        var words = (d.label || "").split(/\s+/).reverse(),
            word,
            line = [],
            lineCount = 1,
            spacing = 0.8,
            lineHeight,
            text = g.append("text").attr("text-anchor", textAnchor).text(d.label);
        while (word = words.pop()) {
            line.push(word);
            text.text(line.join(" "));
            if (!lineHeight) lineHeight = text.node().getBBox().height;
            if (text.node().getComputedTextLength() > maxWidth) {
                line.pop();
                text.text(line.join(" "));
                line = [word];
                text = g.append("text").text(word).attr("y", (lineCount * lineHeight * spacing) + "px").attr("text-anchor", textAnchor);
                ++lineCount;
            }
        }

        var dy, height = lineCount * lineHeight * spacing;
        if (position == "left") {
            dy =  (- lineCount * lineHeight) / 2 + lineHeight * 0.55;
        } else if (position == "top") {
            dy =  -height + lineHeight * 0.6 - 2;
        }
        g.attr("transform", "translate(0," + dy + ")");
    }

    function plotRowLabels() {
        svg.selectAll(".row-label")
            .data(rows)
            .enter()
            .append("g")
            .attr("class", "row-label")
            .attr("transform", function(row, i) {
                return "translate(" + (margin.left - 8) + "," + (margin.top + (i + 0.5) * mcHeight) + ")"
            })
            .each(function(d) { wrapLabel.call(this, d, margin.left - 12, "left", "end") });
    }

    function plotColumnLabels() {
        svg.selectAll(".col-label")
            .data(cols)
            .enter()
            .append("g")
            .attr("class", "col-label")
            .attr("transform", function(col, i) {
                return "translate(" + (margin.left + (i + 0.5) * mcWidth) + "," + margin.top + ")"
            })
            .each(function(d) { wrapLabel.call(this, d, mcWidth - 8, "top", "middle") });
    }

    function plotDateLabels() {
        // put in the date labels along the bottom of each chart
        var axis = chart.selectAll(".axis").data(cols).enter().append("g").attr("class", "axis")
            .attr("transform", function(col, i) {
                return "translate(" + i * mcWidth + "," + mcHeight * rows.length + ")"
            });

        var dateFormat = d3.timeFormat('month' == timeBucket ? "%b %Y" : "%d-%b");
        var a = x.domain();

        var date = parsePublished(a[0]);
        if (date) {
            axis.append("text").attr("x", 4).attr("y", 0).attr("dy", "1em")
                .text(dateFormat(date));
        }
        date = parsePublished(a[a.length - 1]);
        if (date) {
            axis.append("text").attr("x", mcWidth - 4).attr("y", 0).attr("dy", "1em").attr("text-anchor", "end")
                .text(dateFormat(date));
        }
    }

    function plotLegendVerticalAxis(sel, x, height, label) {
        var tickWidth = 4;
        var y0 = 0;
        var y1 = height - 1;
        var points = [[x + tickWidth, y0], [x, y0], [x, y1], [x + tickWidth, y1]];
        sel.append("path").attr("class", "axis").attr("d", d3.line()(points));
        if (label) sel.append("text").attr("x", x + tickWidth + 4).attr("y", (height + 10) / 2 - 1).text(label);
    }

    function plotBarLegend() {
        var g = legend.append("g");
        if (nextLegendX > 0) g.attr("transform", "translate(" + nextLegendX + ",0)");

        g.append("rect").attr("class", "bar")
            .attr("x", 0).attr("y", 0)
            .attr("width", lgBarWidth)
            .attr("height", legendHeight)
            .attr("fill", barColor);

        plotLegendVerticalAxis(g, lgBarWidth + 4, legendHeight, barLabel);

        nextLegendX += barLegendWidth + 20;
    }

    function plotTimeLegend() {
        var g = legend.append("g");
        if (nextLegendX > 0) g.attr("transform", "translate(" + nextLegendX + ",0)");

        var tickWidth = 4;
        var points = [[0, 0], [0, tickWidth], [mcWidth - 1, tickWidth], [mcWidth - 1, 0]];
        g.append("path").attr("class", "axis").attr("d", d3.line()(points));

        var parseDate = d3.timeParse("%Y-%m-%d");
        var dateFormat = d3.timeFormat('month' == timeBucket ? "%b %Y" : "%d-%b");

        var a = x.domain();
        var y = tickWidth + 13;

        g.append("text").attr("x", 0).attr("y", y)
            .text(dateFormat(parseDate(a[0])));

        g.append("text").attr("x", mcWidth).attr("y", y).attr("text-anchor", "end")
            .text(dateFormat(parseDate(a[a.length - 1])));

        nextLegendX += mcWidth + 20;
    }

    function plotBubbleLegend() {
        var g = legend.append("g");

        var circ = g.append("circle")
            .attr("class", "slice " + (showSentiment ? "neutral" : "vol"))
            .attr("cx", 8).attr("cy", 8).attr("r", 8);
        if (!showSentiment) circ.attr("fill", barColor).attr("stroke", barColor);

        g.append("text").attr("x", 20).attr("y", 12)
            .text(showSentiment ? "Neutral" : plural(barFields[barField].label, 2));

        if (showSentiment) {
            g.append("circle").attr("class", "slice neg").attr("cx", 80).attr("cy", 8).attr("r", 8);
            g.append("text").attr("x", 92).attr("y", 12).text("Negative");
            g.append("circle").attr("class", "slice pos").attr("cx", 160).attr("cy", 8).attr("r", 8);
            g.append("text").attr("x", 172).attr("y", 12).text("Positive");
        }
    }

    function fillInMissingPublishedDates(start, end, timeBucket, pubDates) {
        start = moment(start);
        end = moment(end);
        var inc;
        if (timeBucket == 'day') {
            inc = {days: 1};
        } else if (timeBucket == 'week') {
            inc = {days: 7};
            start.day(1);
        } else if (timeBucket == 'month') {
            inc = {months: 1};
            start.date(1);
        } else if (timeBucket == 'none') {
            return; // nothing to do
        } else {
            console.warn("Unhandled timeBucket [" + timeBucket + "]");
            return;
        }
        //console.log("oink " + start.format('YYYY-MM-DD') + " to " + end.format('YYYY-MM-DD'));
        for (; start.valueOf() <= end.valueOf(); start.add(inc)) {
            var d = start.format("YYYY-MM-DD");
            //console.log("d " + d);
            pubDates[d] = true;
        }
    }

    function calcRowAndColSums(rows, cols, cellMap) {
        for (var i = 0; i < rows.length; i++) {
            var row = rows[i];
            row.total = 0;
            for (var j = 0; j < cols.length; j++) {
                var col = cols[j];
                if (col.total === undefined) col.total = 0;
                var cell = cellMap[row.index + "," + col.index];
                if (!cell) continue;
                for (var k = 0; k < cell.buckets.length; k++) {
                    var v = cell.buckets[k][barField];
                    if (v) {
                        row.total += v;
                        col.total += v;
                    }
                }
            }
        }
    }

    function hideSmallRowsOrCols(a) {
        var ans = [];
        var max = 0, o, i;
        for (i = 0; i < a.length; i++) {
            o = a[i];
            if (o.total > max) max = o.total;
        }
        if (!max) return a;
        for (i = 0; i < a.length; i++) {
            o = a[i];
            if (o.total / max >= 0.1) ans.push(o);
        }
        return ans;
    }
}
