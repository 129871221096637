/**
 * Invoke the block with an object containing information about the author.
 */
export default function(options) {
    var s = socialNetworks[this.socialNetwork ? this.socialNetwork.id : ""];
    if (!s) s = socialNetworks.unknown;
    var failAvatar = Beef.resources[s.failAvatar];
    return options.fn(Object.assign({
        avatar: this.authorPictureLink ? this.authorPictureLink : failAvatar,
        failAvatar: failAvatar,
        prefix: s.prefix,
        name: this.authorName ? this.authorName : '(unknown)',
        screenName: this.authorHandle || (this.socialNetwork ? this.socialNetwork.label : null),
        authorId: this.authorId
    }, this));
}

const socialNetworks = {
    FACEBOOK:       { prefix: '<i class="icon-facebook-3"></i>', failAvatar: "facebook_avatar.png" },
    GOOGLE_PLUS:    { prefix: '<i class="icon-gplus"></i>', failAvatar: "googleplus_avatar.png" },
    TWITTER:        { prefix: "@", failAvatar: "twitter_avatar.png" },
    INSTAGRAM:      { prefix: '<i class="icon-instagram"></i>', failAvatar: "instagram_avatar.jpg" },
    unknown:        { prefix: "", failAvatar: "twitter_avatar.png" }
};

