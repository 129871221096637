import {capitalise} from "@/app/utils/StringUtils";

Beef.module("LimitReached").addInitializer(function(startupOptions) {

    this.show = function(ev, max, name, names) {
        var p = new Beef.Popup.View({ closeOnHide: true, positions: ["center"], alwaysMove: true });
        p.setTarget($(ev.target));
        var a = name.split(' ');
        for (var i = 0; i < a.length; i++) a[i] = capitalise(a[i]);
        var v = new Beef.LimitReached.View(
            {model: new Backbone.Model({ max: max, names: names, name: name, title: a.join(" ")})});
        v.on("close", function() { p.hide(); });
        p.show(v);
    };

    this.View = Backbone.Marionette.ItemView.extend({

        template: require("@/setup/brands/LimitReached.handlebars"),

        attributes: { class: "limit-reached dialog" },

        events: {
            "click .ok": "close",
            "click .close": "close"
        },

        modelEvents: {
            "change": "render"
        },

        close: function() {
            if (!this.$el[0].parentElement) return; // already closed
            Backbone.Marionette.ItemView.prototype.close.call(this);
        }
    });
});