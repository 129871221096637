var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit…";
},"3":function(container,depth0,helpers,partials,data) {
    return "Duplicate";
},"5":function(container,depth0,helpers,partials,data) {
    return "Copy to clipboard";
},"7":function(container,depth0,helpers,partials,data) {
    return "Paste (Ctrl-V)";
},"9":function(container,depth0,helpers,partials,data) {
    return "Move up";
},"11":function(container,depth0,helpers,partials,data) {
    return "Move down";
},"13":function(container,depth0,helpers,partials,data) {
    return "Add a metric…";
},"15":function(container,depth0,helpers,partials,data) {
    return "Add a drill down…";
},"17":function(container,depth0,helpers,partials,data) {
    return "View mentions";
},"19":function(container,depth0,helpers,partials,data) {
    return "View authors";
},"21":function(container,depth0,helpers,partials,data) {
    return "View fullscreen";
},"23":function(container,depth0,helpers,partials,data) {
    return "View presentation";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Display section in email mode","method":"viewAsEmail"},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":17,"column":110}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Display section in PDF mode","method":"viewAsPDF"},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":104}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Check if widgets have completed rendering","method":"isWidgetsCompleted"},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":139}}})) != null ? stack1 : "")
    + "</li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "🐛 View as email";
},"28":function(container,depth0,helpers,partials,data) {
    return "🐛 View as PDF";
},"30":function(container,depth0,helpers,partials,data) {
    return "🐛 Call isWidgetsCompleted";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Share this section","method":"createSectionLink"},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":98}}})) != null ? stack1 : "")
    + "</li>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "Share";
},"35":function(container,depth0,helpers,partials,data) {
    return "Save all images";
},"37":function(container,depth0,helpers,partials,data) {
    return "Delete…";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"method":"edit"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":54}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Duplicate this section","method":"copy"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":89}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Copy this section to be pasted elsewhere","method":"copyToClipboard"},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":126}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"method":"pastFromClipboard"},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":76}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"method":"moveUp"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":58}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"method":"moveDown"},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":62}}})) != null ? stack1 : "")
    + "</li>\n<li class=\"divider\"></li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"method":"addGraph"},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":66}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"method":"addDrillDown"},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":74}}})) != null ? stack1 : "")
    + "</li>\n<li class=\"divider\"></li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"View mentions matching the section filter","method":"viewMentions"},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":120}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"See the authors related to this section in the Authors Panel","method":"viewAuthors"},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":12,"column":137}}})) != null ? stack1 : "")
    + "</li>\n<li class=\"divider\"></li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Display section in fullscreen mode","method":"viewFullscreen"},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":14,"column":117}}})) != null ? stack1 : "")
    + "</li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Display section in presentation mode","method":"viewPresentation"},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":123}}})) != null ? stack1 : "")
    + "</li>\n"
    + ((stack1 = __default(require("../helpers/ifdebug.js")).call(alias1,{"name":"ifdebug","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":20,"column":12}}})) != null ? stack1 : "")
    + "<li class=\"divider\"></li>\n"
    + ((stack1 = __default(require("../helpers/ifMashAdmin.js")).call(alias1,{"name":"ifMashAdmin","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":24,"column":16}}})) != null ? stack1 : "")
    + "<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"title":"Save all charts in this section as PNG images in a zip file","method":"saveImages"},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":138}}})) != null ? stack1 : "")
    + "</li>\n<li><a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"csvLink") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Export the mentions used in this section to a CSV file\">Export as CSV</a></li>\n<li class=\"divider\"></li>\n<li>"
    + ((stack1 = __default(require("../helpers/menuitem.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"view") : depth0),{"name":"menuitem","hash":{"method":"delete"},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":28,"column":58}}})) != null ? stack1 : "")
    + "</li>";
},"useData":true});