<template>
    <dialog-box @close="$emit('input', null)" width="400px" :title="'Edit ' + col.name + ' for ' + row.name">
        <be-form ref="form" v-model="valid" class="control-form">
            <div class="row-fluid">
                <div class="span12 control-row">
                    <markdown-editor v-model="text" ref="text" simple style="width: 100%"/>
                </div>
            </div>
        </be-form>

        <template #buttons>
            <a class="cancel btn" @click="$emit('input', null)">Cancel</a>
            <a class="btn btn-primary" @click="ok" :class="{disabled: !valid}">Ok</a>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import BeForm from "@/components/inputs/BeForm";
import MarkdownEditor from "@/components/inputs/MarkdownEditor";

export default {
    name: "EditCompTableNoteDialog",

    components: { DialogBox, BeForm, MarkdownEditor },

    props: {
        row: Object,
        col: Object,
        attrs: Object
    },

    data() {
        let noteId = this.row.id + ":" + this.col.id
        let notes = this.attrs.notes || { }
        let note = notes[noteId] || { }
        return {
            noteId: noteId,
            text: note.text,
            valid: true
        }
    },

    mounted() {
        this.$nextTick(() => { if (this.$refs.text) this.$refs.text.focus() })
    },

    methods: {
        validate() {
            this.$refs.form.validate()
            return this.valid
        },

        ok() {
            if (!this.validate()) return
            let notes = this.attrs.notes
            if (!notes) notes = this.$set(this.attrs, 'notes', {})
            let note = notes[this.noteId]
            if (!note) note = this.$set(notes, this.noteId, { text: '' })
            note.text = this.text
            this.$emit('updated')
            this.$emit('input', null)
        }
    }
}
</script>

<style scoped>

</style>