import Vue from "vue";
import Vuex from "vuex";
import {mash, beef} from "@/store/Services";
import axios from 'axios'
import {profileTypes} from "@/setup/profiles/ProfileUtils";
import GoogleBusinessAuthModule from "./AuthModules/GoogleBusinessAuthModule";
import {features} from "@/app/Features";
import TiktokAuthModule from "./AuthModules/TiktokAuthModule";

Vue.use(Vuex);

let userPromise = null;
let managedFbPagesPromise = null;
let managedTwitterProfilesPromise = null;
let managedLinkedinProfilesPromise = null;
let tokenPromises = null;

let foundValidToken = false;
let foundValidLinkedInToken = false;

let initializedFbToken = false;
let initializedLinkedInToken = false;

let initializedFbPages = false;
let initializedTwitterPages = false;
let initializedLinkedinProfiles = false;

export default {
    namespaced: true,

    modules: {
        googleBusinessAuthModule: GoogleBusinessAuthModule,
        tiktokAuthModule: TiktokAuthModule
    },

    state: {
        facebookUserToken: null,
        facebookLoggedInAs: null,
        facebookUserTokenInvalid: false,

        linkedinLoginStatus: {
            loggedIn: false, // is the user currently logged in through LinkedIn?
            loginInvalidated: false // has the user's LinkedIn login been invalidated?
        },
        linkedInLoggedInAs: null,

        managedFacebookPages: [],
        managedTwitterProfiles: [],
        managedLinkedinProfiles: []
    },

    mutations: {
        setFacebookUserToken: (state, value) => state.facebookUserToken = value,
        setFacebookUserTokenInvalid: (state, value) => state.facebookUserTokenInvalid = value,
        setFacebookLoggedInAs: (state, value) => state.facebookLoggedInAs = value,

        setLinkedinLoginStatus: (state, value) => state.linkedinLoginStatus = value,
        setLinkedInLoggedInAs: (state, value) => state.linkedInLoggedInAs = value,

        setManagedFbPages: (state, value) => state.managedFacebookPages = value,
        setManagedTwitterProfiles: (state, value) => state.managedTwitterProfiles = value,
        setManagedLinkedinProfiles: (state, value) => state.managedLinkedinProfiles = value,

        setInitializedFbToken: (value) => initializedFbToken = value,
        setInitializedLinkedinProfiles: (value) => initializedLinkedInToken = value
    },

    actions: {
        async initLinkedInUserToken({commit, dispatch}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedLinkedInToken) return;

            await dispatch('checkLinkedInTokenValidity');

            // set token to null if no valid tokens are found
            if (foundValidLinkedInToken) {
                initializedLinkedInToken = true;
                foundValidLinkedInToken = false;
            }
        },
        async checkLinkedInTokenValidity({commit}) {
            try {
                let response = await  axios.get(`/api/online-profile/linkedin/check-token`);

                foundValidLinkedInToken = true;

                commit('setLinkedinLoginStatus', {loggedIn: true, loginInvalidated: false});

                let name = `${response.data?.firstName} ${response.data?.lastName}`;
                commit('setLinkedInLoggedInAs', {name: name, id: response.data?.id, expire: response.data?.tokenExpire});
            } catch (e) {
                console.debug("LinkedIn token invalid");
                if (e.response.status === 401) {
                    commit('setLinkedinLoginStatus', {loggedIn: false, loginInvalidated: true});
                } else {
                    commit('setLinkedinLoginStatus', {loggedIn: false, loginInvalidated: false});
                }
            }
        },

        async initializeFbUserToken({commit, dispatch}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedFbToken) return;
            let response = null;
            if (userPromise) response = await userPromise;
            else {
                // We want multiple calls to fetch user data to
                userPromise = mash.get("/rest/users/me");
                response = await userPromise;
                userPromise = null;
            }
            const user = response.data;
            // search for valid facebook token
            if (tokenPromises) {
                response = await Promise.all(tokenPromises);
            }
            else {
                tokenPromises = [];
                for (let i = 0; i < user.facebookAuthorised?.length; i++) {
                    let curTokenObject = user.facebookAuthorised[i];

                    // only check tokens that belong to the "DataEQ Engage" Facebook app
                    if (curTokenObject.apiApp?.id === 4) tokenPromises.push(dispatch('checkFbTokenValidity', curTokenObject.token));
                }
                response = await Promise.all(tokenPromises);
                tokenPromises = null;
            }

            // set token to null if no valid tokens are found
            if (foundValidToken) {
                initializedFbToken = true;
                foundValidToken = false;
            } else {
                commit('setFacebookUserToken', null);
                commit('setFacebookLoggedInAs', null);
            }
        },
        async checkFbTokenValidity({commit}, token) {
            try {
                let response = await axios.get(`https://graph.facebook.com/me?access_token=${token}`);

                foundValidToken = true;

                commit('setFacebookUserTokenInvalid', false);
                commit('setFacebookUserToken', token);
                commit('setFacebookLoggedInAs', {name: response.data?.name, id: response.data?.id});
            } catch (e) {
                commit('setFacebookUserTokenInvalid', true);
		        console.debug('Facebook token is invalid.');
            }
        },
        async initializeManagedFacebookPages({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedFbPages) return;
            let response = null;
            if (managedFbPagesPromise) response = await managedFbPagesPromise;
            else {
                managedFbPagesPromise = beef.get(`/api/online-profile/facebook/list?token=${state.facebookUserToken}`);
                response = await managedFbPagesPromise;
                managedFbPagesPromise = null;
            }
            if (response.status === 200) {
                initializedFbPages = true;
                commit('setManagedFbPages', response.data);
            }
        },
        async initializeManagedTwitterProfiles({commit, state}, forceRefresh) {
            forceRefresh ??= false;
            if (!forceRefresh && initializedTwitterPages) return;
            let response = null;
            if (managedTwitterProfilesPromise) response = await managedTwitterProfilesPromise;
            else {
                managedTwitterProfilesPromise = beef.get("/api/online-profile/twitter/list");
                response = await managedTwitterProfilesPromise;
                managedTwitterProfilesPromise = null;
            }
            if (response.status === 200) {
                initializedTwitterPages = true;
                response.data?.forEach(twitterProfile => {
                    twitterProfile.type = profileTypes.twitter;
                    twitterProfile.name = twitterProfile.handle;
                })
                commit('setManagedTwitterProfiles', response.data);
            }
        },
        async initializeManagedLinkedinProfiles({commit, state, rootState}, forceRefresh) {
            let includeLinkedInPersonalProfile = false;

            // We only want to return their LinkedIn personal profile if they are part of an Engage account.
            // Data collection for these profiles is limited to posts published via the Engage Publishing tool.
            includeLinkedInPersonalProfile = rootState.user.admin;
            if (!rootState.user.admin && rootState.user.accounts) {
                includeLinkedInPersonalProfile = !!rootState.user.accounts.find(account => account.hasEngage);
            }

            forceRefresh ??= false;
            if (!forceRefresh && initializedLinkedinProfiles) return;
            let response = null;
            if (managedLinkedinProfilesPromise) response = await managedLinkedinProfilesPromise;
            else {
                managedLinkedinProfilesPromise = beef.get(`/api/online-profile/linkedin/list?includePersonalProfile=${includeLinkedInPersonalProfile}`);
                response = await managedLinkedinProfilesPromise;
                managedLinkedinProfilesPromise = null;
            }
            if (response.status === 200) {
                initializedLinkedinProfiles = true;
                response.data?.forEach(linkedinProfile => {
                    linkedinProfile.type = linkedinProfile.type || profileTypes.linkedin;
                })
                commit('setManagedLinkedinProfiles', response.data);
            }
        }
    }
};
