<template>
    <section v-if="showWhenOriginalAdmin ? user.originalAdmin : user.admin"
             class="staff-info-card"
             tabindex="-1">
        <section class="staff-info-card__flex">
            <staff-only-tag :show-when-original-admin=showWhenOriginalAdmin class="staff-info-card__tag"/>
            <section>
                <slot name="default"/>
            </section>
        </section>
    </section>
</template>

<script>
import StaffOnlyTag from "@/components/tags/StaffOnlyTag";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

export default {
    store: VuexStore,
    components: {StaffOnlyTag},

    props: {
      showWhenOriginalAdmin: Boolean
    },

    computed: {
        ...mapState(['user'])
    }
}
</script>


<style scoped lang="sass">

.staff-info-card
    box-sizing: border-box
    width: calc(100% - 20px)
    height: calc(100% - 40px)
    display: flex
    margin: 20px auto
    flex-direction: column
    border: 1px solid #999
    border-radius: 3px
    align-items: center
    justify-content: center
    outline: none
    background: linear-gradient(to right, lightblue, #aeffff)
    box-shadow: 3px 3px 3px black

    .staff-info-card__flex
        display: flex
        flex-direction: row
        align-items: center
        width: 100%
        padding: 10px
        box-sizing: border-box

    .staff-info-card__tag
        flex: 0 0 auto

    p
        transition: color var(--transition-duration)
        color: var(--be-colour-text)
        padding: 0 30px

    h1, h4
        transition: color var(--transition-duration)
        color: var(--be-colour-muted-text-dark)
        text-align: center

    & ::v-deep
        --be-button-link-colour: black

        .be-tag
            color: white
</style>