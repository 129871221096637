<template>
    <section class="be-sidebar-header"
             @click="$emit('click', $event)">
        <old-tooltip v-if="tooltip" :label="tooltip" right>
            <slot><h4>Header</h4></slot>
        </old-tooltip>
        <slot v-else>Header</slot>
    </section>
</template>

<script>

import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
    components: {OldTooltip},
    props: {
        tooltip: {
            type: String,
            default: null
        }
    }
}

</script>

<style scoped lang="sass">

@import "sidebar"

.be-sidebar-header
    display: flex
    align-items: center
    background: var(--colour-background-black)
    cursor: default

    &::v-deep h4
        font-size: 12px
        text-transform: uppercase
        color: var(--clr-sidebar-header)
        margin: 0 0 0 12px
        line-height: 52px

</style>