<template>
    <div class="control-group" @click="showPicker = true" :class="{single}">
        <label v-if="label">{{ label }}</label>
        <div class="controls">
            <div class="tag-input uneditable-input colours">
                <div v-for="(c,i) in colours" :key="i" :style="{backgroundColor: c}"/>
            </div>
        </div>
        <dialog-box v-if="showPicker" title="Colour Settings" @close="closeDialog()" width="500px">
            <colour-settings-picker v-model="settings" :dashboard-model="dashboardModel" :no-opacity="noOpacity"
                :use-default-text="useDefaultText" :use-default-title="useDefaultTitle" />

            <template #buttons>
                <be-button class="close-btn" primary @click="saveAndCloseDialog()">
                    Save
                </be-button>
            </template>
        </dialog-box>
    </div>
</template>

<script>
import DialogBox from "../components/DialogBox"
import ColourSettingsPicker from "./ColourSettingsPicker"
import {getPalette} from "@/app/utils/Colours";
import BeButton from "@/components/buttons/BeButton.vue";

/**
 * Display selected colour(s) with click to edit using a picker. Note that this input directly updates its value
 * property's fields.
 */
export default {
    name: "ColourSettingsInput",

    components: {BeButton, DialogBox, ColourSettingsPicker },

    props: {
        value: Object,  // {'colour-palette':.., 'colour-index':.., 'colour-palette-custom':.., opacity: ...}
        label: { type: String, default: "Colours" },
        dashboardModel: Object,
        noOpacity: Boolean,
        single: Boolean,
        noDefault: Boolean,
        defaultValue: { type: Object, default() { return {} } },
        useDefaultText: String,
        useDefaultTitle: String
    },

    data() {
        return {
            settings: copyColourFields(this.value),
            showPicker: false
        }
    },

    computed: {
        colours() {
            let cp = this.settings['colour-palette']
            if (!cp && this.noDefault) return []
            let a = getPalette(cp ? this.settings : this.defaultValue, this.dashboardModel.attributes)
            let c = this.single ? 1 : 6
            if (a.length > c) a = a.slice(0, c)
            return a
        }
    },

    watch: {
        value(v) { this.settings = copyColourFields(v) },

        settings(v) {
            Object.assign(this.value, v)
        }
    },

    methods: {
        showDialog() {
            this.showPicker = true;
        },

        closeDialog() {
            this.$emit('closed');
            this.showPicker = false;
        },

        saveAndCloseDialog() {
            this.$emit('saved-colour-change');

            this.showPicker = false;
        }
    }
}

function copyColourFields(o) {
    if (!o) o = { }
    return {
        'colour-palette': o['colour-palette'],
        'colour-index': o['colour-index'],
        'colour-palette-custom': o['colour-palette-custom'],
        opacity: o.opacity
    }
}

</script>

<style scoped>

.colours {
    display: flex;
    flex-wrap: wrap;
    width: 30px;
    padding: 4px;
}

.single .colours {
    width: 20px;
}

.colours > div {
    width: 10px;
    height: 10px;
}

.single .colours > div {
    width: 20px;
    height: 20px;
}

.close-btn {
    margin-left: auto
}

.colours:hover {
    border-color: var(--be-filter-tag-hover-colour);
}

</style>