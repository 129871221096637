<template>
    <section class="trending-widget widget-height-inner">
        <trend-table :topics="topics"
                     :wordtype="wordType"
                     :with-sentiment="attributes.withSentiment"
                     :with-expected="attributes.withExpected"
                     :with-z-score="attributes.withZScore"
                     :with-average="attributes.withAverage"
                     :with-maximum="attributes.withMaximum"
                     :with-max-date="attributes.withMaxDate"
                     :with-total="attributes.withTotal"
                     :with-description="attributes.withDescription"
                     :show-spiking="attributes.showSpiking"
                     :show-unusual="attributes.showUnusual"
                     :show-slightly="attributes.showSlightly"
                     :show-expected="attributes.showExpected"
                     :show-low="attributes.showLow"
                     :show-new="attributes.showNew"
                     @seeMentions="seeMentions($event)"
                     @seeAuthors="seeAuthors($event)"
                     @seeKeywords="seeKeywords($event)"/>
    </section>
</template>

<script>

import TrendingWidgetSettings from "@/dashboards/widgets/trending/TrendingWidgetSettings";
import {
    getCsvForTrendingTopics, loadRawSegmentTrends,
    loadRawTagTrends,
    loadRawTopicTrends
} from "@/app/toplevel/explore/overview/ExploreUtilities";
import './TrendingWidget.css'
import {showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {getAllChannelSegmentLists, getAllRiskProductSegmentLists, getAllCxSegmentLists} from "@/app/utils/Segments";
import TrendTable from "@/app/toplevel/explore/overview/TrendTable";
import {getTopicTreeIds} from "@/app/utils/Topics";
import {appendFiltersReadably, earliestDate, getBrandsInFilter, latestDate, replaceDate} from "@/dashboards/filter/FilterParser";
import {appendSegmentRestrictions} from "@/app/utils/Segments";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import {errorHelper} from "@/dashboards/DashboardUtils";

if (module.hot) window.beWidgetReloaded('TrendingWidget');

export default {
    name: "TrendingWidget",
    components: {TrendTable},
    widgetType: {
        height: 4,
        width: 6,
        vueSettingsDialog: TrendingWidgetSettings,
        withSentiment: true,
        withExpected: false,
        withZScore: false,
        withAverage: false,
        withMaximum: false,
        withMaxDate: false,
        withTotal: false,
        withDescription: false,
        dataType: "TOPICS",
        'hidden-title': false,
        maxItems: 10,
        showSpiking: true,
        showUnusual: true,
        showSlightly: true,
        showExpected: true,
        showNew: false,
        showLow: true
    },

    props: {
        model: Object
    },

    data() {
        console.log("Data in trending widget")
        return {
            loading: false,
            topics: []
        }
    },

    computed: {
        attributes() { return this.model.attributes },

        filter() { return this.attributes._effectiveFilter },

        brands() {
            const filter = this.filter;
            if (!filter) return [];

            return getBrandsInFilter(filter).include;
        },

        brandFilter() {
            return this.brands.map(id => `brand isorchildof ${id}`).join(" or ");
        },

        type() {
            return this.attributes.dataType ?? 'TOPICS';
        },

        wordType() {
            switch (this.type) {
                case 'RISK':
                    return "Risk factors";
                case 'CX':
                    return "CX Touchpoint"
                case 'CHANNELS':
                    return "Channel"
                case 'TAGS':
                    return "Tags";
                default:
                    return "Topic";
            }
        }
    },

    watch: {
        brands() {
            this.loadData();
        },

        type() {
            this.loadData();
        }
    },

    mounted() {
        this.loadData();

    },

    methods: {
        async refresh() {
            await this.loadData();
        },

        async loadData() {
            if (this.loading) return;
            if (!this.brands?.length) return;

            try {
                this.model.generalData.set({_loading: true, _completed: false, _footnotes: []});
                this.loading = true;
                this.topics = [];

                let longTermFilter = `(${this.brandFilter}) and published inthelast quarter and relevancy isnt irrelevant and process is verified`;
                const earliest = earliestDate(this.filter);
                const latest = latestDate(this.filter);
                const duration = latest.diff(earliest, 'days');
                let longDuration = 4 * 31;

                const longTermEnd = earliest.clone().subtract(1, 'day');
                const fourMonths = longTermEnd.clone().subtract(4, 'months');
                longTermFilter = replaceDate(longTermFilter, `published after '${fourMonths.format("YYYY/MM/DD")}' and published before '${longTermEnd.format("YYYY/MM/DD")}'`);
                longDuration = longTermEnd.diff(fourMonths, 'days');


                // Some trending data (like segments) need their filters updated a bit to get the data
                // we want. Here we do that.
                let periodFilter = this.filter;
                let optionalFilter; // used to append segment restrictions for RISK and CX trending widgets so that they are inline with the Risk and CX trending tables shown on Explore
                let isSegment = false;
                let tagList = null;
                let segmentTag = null;

                switch (this.type) {
                    case 'CX':
                        isSegment = true;
                        tagList = await getAllCxSegmentLists();
                        segmentTag = 4;
                        optionalFilter = appendSegmentRestrictions(optionalFilter);
                        break;
                    case 'CHANNELS':
                        isSegment = true;
                        tagList = await getAllChannelSegmentLists();
                        break;
                    case 'RISK':
                        isSegment = true;
                        tagList = await getAllRiskProductSegmentLists();
                        segmentTag = 1;
                        optionalFilter = appendSegmentRestrictions(optionalFilter);
                        break;
                    case 'TOPICS':
                        tagList = await getTopicTreeIds();
                        break;
                }



                if (isSegment) {
                    // Update the filters to add risk query portions.
                    const fragment = tagList?.length ? `and (${tagList?.map(c => `segment is ${c.id}`).join(' or ')})` : '';
                    const tagFragment = segmentTag ? ` and tag is ${segmentTag} ` : '';
                    longTermFilter = `(${longTermFilter}) ${tagFragment} ${fragment} ${optionalFilter ? `and ${optionalFilter}` : ``}`;
                    periodFilter = `(${periodFilter}) ${tagFragment} ${fragment} ${optionalFilter ? `and ${optionalFilter}` : ``}`;
                }

                // Now we fetch the actual data.
                const maxItems = this.attributes.maxItems > 0 ? this.attributes.maxItems : 10;
                switch (this.type) {
                    case 'TOPICS': {
                        const results = await loadRawTopicTrends(periodFilter, longTermFilter, duration, longDuration, maxItems, tagList);
                        this.topics = results.trendingTopics;
                        break;
                    }
                    case 'RISK':
                        this.topics = await loadRawSegmentTrends(periodFilter, longTermFilter, duration, longDuration, 'CONDUCT_LIST');
                        break;
                    case 'CX':
                        this.topics = await loadRawSegmentTrends(periodFilter, longTermFilter, duration, longDuration, 'CX_LIST');
                        break;
                    case 'CHANNELS':
                        this.topics = await loadRawSegmentTrends(periodFilter, longTermFilter, duration, longDuration, 'CHANNEL_LIST');
                        break;
                    case 'TAGS':
                        this.topics = await loadRawTagTrends(periodFilter, longTermFilter, duration, longDuration, maxItems, 'tag');
                        break;
                    default:
                        // noinspection ExceptionCaughtLocallyJS
                        throw new Error("Unable to load data for type " + this.type);
                }

                this.model.generalData.set({_loading: false, _completed: true});
                if (this.topics.length === 0) {
                    this.model.generalData.set({_message: "There are no trends matching your filter in this time period"});
                }
            } catch(e) {
                errorHelper(this.model, e.response || e);
            } finally {
                this.loading = false;
            }
        },

        toCSV() {
            return getCsvForTrendingTopics(this.topics);
        },

        getFilter() {
            let filter = this.filter;
            if (this.type === "CX" || this.type === "RISK") {
                filter = appendSegmentRestrictions(filter);
            }

            return filter;
        },

        seeMentions(topic) {
            let filter = this.getFilter();
            filter = appendFiltersReadably(filter, `segment is ${topic.topic.id}`);

            showMentions(
                filter,
                `Mentions for '${topic.topic.name}'`
            );
        },

        seeAuthors(topic) {
            let filter = this.getFilter();
            filter = appendFiltersReadably(filter, `segment is ${topic.topic.id}`);
            gotoAuthorsPanel(filter);
        },

        seeKeywords(topic) {
            let filter = this.getFilter();
            filter = appendFiltersReadably(filter, `segment is ${topic.topic.id}`);
            showWordcloud(
                `(${filter}) and segment is ${topic.topic.id}`,
                `Mentions for '${topic.topic.name}'`
            )
        },

        viewMentions() {
            gotoMentionPanel(this.getFilter());
        },

        viewAuthors() {
            gotoAuthorsPanel(this.getFilter());
        }
    }

}

</script>


