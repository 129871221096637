/**
 * Select one or more author names by typing the name. Auto-completes against recent authors in the account.
 * Fires a change event when the selection is changed. Also provides a model binder converter to display the
 * selected options in an element.
 */

import {grouseGet} from "@/data/Grouse";
import {buildBasicFilter} from "@/dashboards/filter/BasicFilter";
import {fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import _ from 'underscore';
import {currentAccountCode} from "@/app/utils/Account";
import {encloseInQuotes, removeQuotes} from "@/app/utils/StringUtils";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("AuthorNamePicker").addInitializer(function(startupOptions) {

    var fetchAuthorNamesV4 = _.throttle(function(filter, limit, cb) {
        grouseGet("/v4/accounts/" + currentAccountCode() + "/mentions/count",
                {filter: filter, groupBy: "authorName", limit: limit})
            .then(function (data) {
                var names = [];
                for (var i = 0; i < data.length; i++) names.push(data[i].authorName);
                cb(names.sort());
            })
            .catch(console.log)
    }, 1000);

    var fetchAuthorNames = function(q, view, cb) {
        if (!q || q.length < 3) {
            cb([]);
            return;
        }

        var c = view._cached, limit;

        let attrs = Object.assign({}, view.options.filterAttrs);
        attrs.authorName = null;
        attrs.author = null;

        // Build up a query to pull authors with.
        var promise = new Promise(function (resolve) {
            if (attrs.brand) {
                resolve(attrs);
                return;
            }

            fetchCachedBrands(view, function (brands) {
                var ids = brands.tree.map(function (b) {
                    return b.id
                });
                if (!ids.length) {
                    resolve(null);
                    return;
                }

                attrs.brand = "" + ids[0];
                resolve(attrs);
            })
        });

        promise
            .then(function(attrs) {
                if (!attrs) return [];

                if (!attrs.published) attrs.published = "MONTH";
                var baseFilter = buildBasicFilter(attrs);
                if (c && q.indexOf(c.q) === 0 && c.baseFilter === baseFilter) {
                    return c.names;
                } else {
                    var filter = baseFilter + " and AuthorName contains " + encloseInQuotes(q,'"');
                    limit = 400;
                    fetchAuthorNamesV4(filter, limit, function(names){
                        if (names.length < limit) view._cached = { q: q, baseFilter: baseFilter, names: names };
                        return names;
                    });
                }
            })
            .then(cb)
            .catch(console.warn);
    };

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "author-name-picker auto-complete-picker" },
        items: fetchAuthorNames,
        mustSelectFromItems: false
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            tooltip: function(code) { return "The author, " + code; },
            items: function(code) { return removeQuotes(code, "'"); },
            placeholder: placeholder
        });
    };

    this.converter = this.createConverter("Start typing the author's name here");

    /**
     * Attach the picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, {quoteCodes: "'"});
    };

});
