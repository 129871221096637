<template>
    <div class="fb-page-search">
        <h4>Search for the <i class="symbol-facebook-rect"></i> Facebook Page you would like to add</h4>

        <p>Adding an unauthorised Facebook page will allow you to track public data for that page, such as posts and comments.</p>

        <div class="fb-page-search__search-container">
            <inline-text-input ref="searchInput" class="fb-page-search__search-input" signal-for-all v-model="searchQuery" :disabled="searching" placeholder="Facebook page name" hide-controls text-required></inline-text-input>

            <be-button primary :disabled="searching || searchQuery === ''" @click="search">
                <i v-if="!searching" class="symbol-search"></i> <spinner-component :size="14" v-else></spinner-component>
                {{ searching ? 'Searching...' : 'Search' }}
            </be-button>
        </div>

        <transition name="fade">
            <div v-if="foundFbPages.pages && foundFbPages.pages.length" class="fb-page-search__page-list-container">
                <p>Search results for "{{ displaySearchQuery }}"</p>

                <div class="fb-page-search__page-list dark-scrollbars dark-scrollbars--visible" @scroll=onScroll ref="fbPageList">
                    <div v-for="page in foundFbPages.pages" :key="page.id" class="fb-page-search__page" :class="{'selected': selectedPage.id === page.id, 'disabled': searching}" @click="selectPage(page)">
                        <online-profile :profile="page"></online-profile>
                        <be-button link @click.stop="openInFacebook(page)"><i class="icon-forward-2"></i> Open in Facebook</be-button>
                        <div class="fb-page-search__page-on-acc-msg" v-if="pageExistsOnAccount(page)">
                            Page already on account
                        </div>
                    </div>
                </div>

                <div class="fb-page-search__page-list-footer">
                    <p v-if="selectedPage.id"> {{ selectedPageMessage }} </p>
                    <p v-else>Select the page you would like to add. Only pages that are not already on the account can be added.</p>
                </div>
            </div>
            <div class="fb-page-search__no-pages-message" :class="{'error-msg': message.type === 'error'}" v-else-if="!searching && !foundFbPages.pages.length">
                <i v-if="message.type === 'error'" class="symbol-warning"></i> {{ message.message }}
            </div>
        </transition>
    </div>
</template>

<script>
import InlineTextInput from "@/components/inputs/InlineTextInput";
import BeButton from "@/components/buttons/BeButton";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import {extractHandleId, profileTypes} from "@/setup/profiles/ProfileUtils";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import axios from "axios";
import Vue from "vue";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent";

const MESSAGE_TYPE = {
    INFO: "info",
    ERROR: "error"
}

export default {
    name: "FbPageSearch",
    components: {SpinnerComponent, OnlineProfile, BeButton, InlineTextInput},

    data() {
        return {
            foundFbPages: {
                pages: [],
                fetchMoreUrl: null
            },
            directPageSearchResult: null,
            searchQuery: "",
            displaySearchQuery: "",
            message: {
                type: MESSAGE_TYPE.INFO,
                message: `Enter the name of the Facebook page you would like to add and then click "Search"`
            },
            searching: false,
            enableNext: false,
            enablePrevious: false,
            selectedPage: {},
            selectedPageMessage: "",
            curScrollPos: 0
        }
    },

    created() {
        this.refreshProfiles();
    },

    mounted() {
        // event handler for pressing 'enter' key while search bar is in focus
        this.$refs.searchInput.$on('ok', () => {
            if (this.searchQuery && !this.searching) {
                this.search();
            }
        });
    },

    activated() {
        if (this.curScrollPos > 0) {
            this.$refs.fbPageList.scrollTop = this.curScrollPos;
        }
    },

    computed: {
        ...mapGetters('profiles', ['getFacebookPages']),
        ...mapState('userPlatformAuth', ['facebookUserToken'])
    },

    methods: {
        ...mapActions('profiles', ['refreshProfiles']),
        ...mapMutations('profiles', ['setNewPublicProfile']),

        pageExistsOnAccount(page) {
            let existingPage = this.getFacebookPages?.find(p => p.handleId === page.id);
            return !!(existingPage && !existingPage.deleted);
        },

        async onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            this.curScrollPos = scrollTop;

            // fetch next set of pages when user has scrolled to the bottom of page list (if there are more pages to fetch)
            if (scrollTop + clientHeight >= scrollHeight) {
                if (this.foundFbPages.fetchMoreUrl) {
                    try {
                        this.searching = true;

                        let response = await axios.get(this.foundFbPages.fetchMoreUrl);

                        // set picture links & additional data
                        for (let i = 0; i < response.data.data.length; i++) {
                            let page = response.data.data[i];
                            page.handleId = page.id;
                            page.type = profileTypes.facebook;

                            // no need to await requests for picture links
                            this.setPagePicture(page);
                        }

                        /**
                         * if we were able to fetch a page directly using https://graph.facebook.com/$searchQuery, ensure that we remove possible duplicate that could be
                         * found in results of the next paging url (obtained in the results from https://graph.facebook.com/pages/search?q=$searchQeury)
                         */
                        if (this.directPageSearchResult) {
                            response.data.data = response.data.data.filter(p => p.id !== this.directPageSearchResult.id);
                        }

                        this.foundFbPages.pages.push(...response.data.data);
                        this.foundFbPages.fetchMoreUrl = response.data.paging?.next ? response.data.paging.next : null
                    } catch (e) {
                        console.error(`Error occurred while trying to fetch more Facebook pages for search query ${this.searchQuery}: `, e);

                        this.foundFbPages.fetchMoreUrl = null;
                        notifyUserOfError(`An error occurred while trying to fetch more Facebook pages. Please refresh the page and try again or contact support.`);
                    } finally {
                        this.searching = false;
                    }

                }
            }
        },

        async search() {
            try {
                this.searching = true;
                this.selectedPage = {};
                this.setNewPublicProfile(null);

                let lookup = extractHandleId(this.searchQuery)
                this.displaySearchQuery = lookup;

                // search FB pages
                let response = await axios.get(`https://graph.facebook.com/pages/search?q=${lookup}&access_token=${this.facebookUserToken}&fields=id,name,link`);

                // set picture links & additional data
                for (let i = 0; i < response.data.data.length; i++) {
                    let page = response.data.data[i];
                    page.handleId = page.id;
                    page.type = profileTypes.facebook;

                    // no need to await requests for picture links
                    this.setPagePicture(page);
                }

                this.foundFbPages.pages = [];

                // handle direct page search
                await this.directPageSearch();

                /**
                 * if we were able to fetch a page directly using https://graph.facebook.com/$searchQuery, add that page to the beginning of the list. Also ensure that we remove possible duplicate that could be
                 * found in results of https://graph.facebook.com/pages/search?q=$searchQeury
                */
                if (this.directPageSearchResult) {
                    this.foundFbPages.pages.push(this.directPageSearchResult);
                    response.data.data = response.data.data.filter(p => p.id !== this.directPageSearchResult.id);
                }

                this.foundFbPages.pages.push(...response.data.data)
                this.foundFbPages.fetchMoreUrl = response.data.paging?.next ? response.data.paging.next : null

                // reset scroll position for new search
                if (this.$refs.fbPageList) this.$refs.fbPageList.scrollTop = 0;

                if (!this.foundFbPages.pages.length) {
                    this.setInfoMessage(`No pages could be found for search query "${this.displaySearchQuery}"`);
                }
            } catch(e) {
                console.error(`Error occurred when searching for Facebook pages with search query ${this.searchQuery}: `, e);

                this.foundFbPages = [];
                this.setErrorMessage("An error occurred while searching for Facebook pages. Please refresh the page and try again or contact support.")
            } finally {
                this.searching = false;
            }
        },

        /**
         * Tries to fetch a page directly using https://graph.facebook.com/$searchQuery. Only user tokens with appropriate permissions will be able to fetch a page using this endpoint.
         * This is done to ensure that users with those permissions on their tokens can still search for pages using handle ID's, since using the Page Search API (https://graph.facebook.com/pages/search?q=$searchQeury)
         * is best used with page names, not handle ID's.
         */
        async directPageSearch() {
            try {
                // search FB pages
                let response = await axios.get(`https://graph.facebook.com/${this.searchQuery}?access_token=${this.facebookUserToken}&fields=id,name,picture,link`);

                this.directPageSearchResult = {
                    id: response.data.id,
                    handleId: response.data.id,
                    name: response.data.name,
                    pictureLink: response.data.picture ? response.data.picture.data.url : null,
                    link: response.data.link
                }
            } catch(e) {
                this.directPageSearchResult = null;
            }
        },

        async setPagePicture(page) {
            try {
                Vue.set(page, 'pictureLink', "");

                let response = await axios.get(`https://graph.facebook.com/${page.id}/picture?redirect=0`);

                page.pictureLink = response.data.data.url;
            } catch(e) {
                console.error(`Error occurred when fetching picture link for Facebook pages with handle ID ${page.id}: `, e);
            }
        },

        setInfoMessage(message) {
            this.message = {
                type: MESSAGE_TYPE.INFO,
                message: message
            }
        },

        setErrorMessage(message) {
            this.message = {
                type: MESSAGE_TYPE.ERROR,
                message: message
            }
        },

        selectPage(page) {
            if (this.pageExistsOnAccount(page)) return;
            if (this.selectedPage?.id === page.id) {
                this.selectedPage = {};
                this.setNewPublicProfile(null);
                return;
            }

            this.selectedPage = page;

            let profile = {
                handle: page.id,
                handleId: page.id,
                name: page.name,
                pictureLink: page.pictureLink,
                type: profileTypes.facebook,
                media: null,
                tagIds: [],
                relevant: false,
                description: ""
            }

            this.selectedPageMessage = `${this.selectedPage.name} (${this.selectedPage.id }) has been selected`;

            this.setNewPublicProfile(profile);
        },

        openInFacebook(page) {
            window.open(page.link, '_blank');
        }
    }
}
</script>

<style scoped lang="sass">

.fb-page-search

    p
        color: var(--be-colour-text-dark)

    .online-profile
        width: 40%

    &__search-container
        display: flex
        column-gap: 30px
        padding-top: 20px
        border-top: 1px dashed var(--be-colour-muted-text-dark)

    &__search-input
        width: 60%

    &__page-list-container
        margin-top: 15px

    &__page-list
        height: 285px
        overflow-y: auto

    &__no-pages-message
        transition-delay: 100ms
        transition-duration: 250ms
        height: 300px
        display: flex
        place-items: center
        justify-content: center
        font-style: italic
        color: var(--be-colour-muted-text-dark)

        &.error-msg
            color: var(--be-colour-warning)

    &__page
        display: flex
        align-items: center
        background: #333
        padding: 5px
        border-top: 1px solid #1a1a1a
        border-left: 1px solid #1a1a1a
        border-right: 1px solid #1a1a1a

        &.selected
            background: var(--background-menu-active) !important

        &:hover
            background: var(--background-menu-hover)
            cursor: pointer

        &:last-of-type
            border-bottom: 1px solid #1a1a1a

        &.disabled
            transition-delay: 100ms
            transition-duration: 250ms
            filter: grayscale(80%) blur(3px)
            cursor: default
            pointer-events: none

    &__page-on-acc-msg
        border-left: 1px solid var(--be-colour-text-dark)
        margin-left: 20px
        padding-left: 20px
        color: var(--be-colour-text-dark)
        font-style: italic
        font-size: 0.9em

    &__page-list-footer
        display: flex
        align-items: center
        margin-top: 15px

        .btn-container
            margin-left: auto
            display: flex

.fade-enter-active, .fade-leave-active
    transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0

</style>