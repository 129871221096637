<template>
    <section>
        <header>
            <h4 v-if="selectedSegmentList">
                <span v-if="hasMultipleLists">{{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }}</span>
                <span v-else>{{ selectedSegmentList.name }}</span>
            </h4>
            <h4 v-else>Customer Experience</h4> <!-- It's busy loading here -->
        </header>

        <section class="cx-segment-filter__columns">
            <section v-if="journey.length" class="cx-segment-filter__column">
                <h5>Journey stages</h5>
                <section v-for="stage in journey"
                         :key="stage.id"
                         :class="{'cx-segment-filter__tag--selected': isSelected(stage.id), 'cx-segment-filter__tag--has-rpcs': !!stage.flag}"
                         class="cx-segment-filter__tag">
                    <span class="cx-segment-filter__tag-selectable"
                          v-if="!isSelected(stage.id)"
                          @mouseenter="showTooltip($event, stage)"
                          @click="select(stage.id)">
                        <rpcs-tag v-if="stage.flag" :code="stage.flag"></rpcs-tag>
                        <span class="cx-segment-filter__tag-name">
                            {{stage.name}}
                        </span>
                    </span>
                    <section v-else class="cx-segment-filter__tag-selected">
                        <span v-if="!isNegated(stage.id)" class="cx-segment-filter__tag-negate" @click="negate(stage.id)">–</span>
                        <slotted-tag v-else class="cx-segment-filter__tag-negated" no-close @click="negate(stage.id)">–</slotted-tag>
                        <slotted-tag @close="select(stage.id)"
                                     @mouseenter="showTooltip($event, stage)"
                                     :negated="isNegated(stage.id)">
                            <span v-if="isNegated(stage.id)">-</span>
                            <rpcs-tag v-if="stage.flag" :code="stage.flag"></rpcs-tag>
                            {{ stage.name }}
                        </slotted-tag>
                    </section>
                </section>
            </section>
            <section class="cx-segment-filter__column">
                <h5>Interactions</h5>
                <section v-if="!interactions.length"
                         class="cx-segment-filter__none">
                    No customer interactions
                </section>
                <section v-for="interaction in interactions"
                         :key="interaction.id"
                         :class="{'cx-segment-filter__tag--selected': isSelected(interaction.id), 'cx-segment-filter__tag--has-rpcs': !!interaction.flag}"
                         class="cx-segment-filter__tag">
                    <span class="cx-segment-filter__tag-selectable"
                          v-if="!isSelected(interaction.id)"
                          @mouseenter="showTooltip($event, interaction)"
                          @click="select(interaction.id)">
                        <rpcs-tag v-if="interaction.flag" :code="interaction.flag"></rpcs-tag>
                        {{interaction.name}}
                    </span>
                    <section v-else class="cx-segment-filter__tag-selected">
                        <span v-if="!isNegated(interaction.id)" class="cx-segment-filter__tag-negate" @click="negate(interaction.id)">–</span>
                        <slotted-tag v-else class="cx-segment-filter__tag-negated" no-close @click="negate(interaction.id)">–</slotted-tag>
                        <slotted-tag @close="select(interaction.id)"
                                     @mouseenter="showTooltip($event, interaction)"
                                     :negated="isNegated(interaction.id)">
                            <span v-if="isNegated(interaction.id)">-</span>
                            <rpcs-tag v-if="interaction.flag" :code="interaction.flag"></rpcs-tag>
                            {{ interaction.name }}
                        </slotted-tag>
                    </section>
                </section>
            </section>
        </section>
        <section class="cx-segment-filter__controls">
            <section class="cx-segment-filter__buttons">
                <be-button v-if="!isAllSelected" link @click="selectAll()">
                    <span v-if="hasMultipleLists && selectedSegmentList">Select all {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }}</span>
                    <span v-else>Select all</span>
                </be-button>
                <slotted-tag v-else @close="unselectAll(false)">
                    <animated-check/>
                    <span v-if="hasMultipleLists && selectedSegmentList">All {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }} selected</span>
                    <span v-else>All selected</span>
                </slotted-tag>


                <be-button v-if="!isJourneySelected" link @click="selectJourney()">
                    <span>Select journey</span>
                </be-button>
                <slotted-tag v-else @close="unselectJourney()">
                    <span v-if="hasMultipleLists && selectedSegmentList">{{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }} journey selected</span>
                    <span v-else>Journey selected</span>
                </slotted-tag>

                <div v-if="interactions.length">
                    <be-button v-if="!isInteractionsSelected" link @click="selectInteractions()">
                        <span>Select interactions</span>
                    </be-button>
                    <slotted-tag v-else @close="unselectInteractions()">
                        <span v-if="hasMultipleLists && selectedSegmentList">{{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }} interactions selected</span>
                        <span v-else>Interactions selected</span>
                    </slotted-tag>
                </div>


                <be-button v-if="!isNonAboveSelected" link @click="selectNonAbove()">
                    <span v-if="hasMultipleLists && selectedSegmentList">Select none {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }}</span>
                    <span v-else>Select none</span>
                </be-button>
                <slotted-tag v-else @close="select(none.id)" :negated="isNegated(none.id)">
                    <animated-check/>
                    <span v-if="isNegated(none.id)">-</span>
                    <span v-if="hasMultipleLists && selectedSegmentList">None {{ selectedSegmentList.subtitle }} {{ selectedSegmentList.name }} selected</span>
                    <span v-else>None selected</span>
                </slotted-tag>

                <be-button link @click="unselectAll()">Unselect all</be-button>
            </section>
            <segment-choice v-if="hasMultipleLists && selectedSegmentList"
                            v-model="selectedSegmentList"
                            :segment-lists="allSegmentLists"
                            :active-segments="activeCxLists"
                            :selected-ids="selected"
            />
        </section>
    </section>
</template>


<script>

import {substituteTagParamaters} from "@/app/utils/Tags";
import {deprecatedTagsStore as tagStore} from "@/store/deprecated/Stores";
import {getActiveSegmentLists, getAllCxSegmentLists} from "@/app/utils/Segments";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import SegmentTooltip from "@/dashboards/filter/SegmentTooltip";
import SlottedTag from "@/components/tags/SlottedTag";
import BeButton from "@/components/buttons/BeButton";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import SegmentChoice from "@/dashboards/filter/SegmentChoice";
import RpcsTag from "@/components/tags/RpcsTag";

export default {
    components: {RpcsTag, SegmentChoice, AnimatedCheck, BeButton, SlottedTag},

    props: {
        originalJourney: {
            type: Array,
            default() { return []; }
        },
        originalInteractions: {
            type: Array,
            default() { return []; }
        }
    },

    data() {
        return {
            loading: false,
            allSegmentLists: [],        // All segment lists of the given type

            activeCxLists: [],       // All active CX segment lists
            selectedSegmentList: null,  // The current segment list whose child tags we are displaying
            journey: [],                // All tags in the customer journey
            interactions: [],           // All the tags that representing user interactions
            none: null,                 // The none-of-the-above option

            selected: [],               // The tags that are selected
            unknown: []                 // Tags the user has chosen that are no longer usable (segments from archived brands, etc)
        }
    },

    computed: {
        allTags() {
            return [...this.journey, ...this.interactions];
        },

        isAllSelected() {
            return !!this.allTags.length && this.allTags.every(s => this.selected.some(id => id === s.id));
        },

        isJourneySelected() {
            return !!this.journey.length && this.journey.every(s => this.selected.some(id => id === s.id));
        },

        isInteractionsSelected() {
            return !!this.interactions.length && this.interactions.every(s => this.selected.some(id => id === s.id));
        },

        isNonAboveSelected() {
            return this.none && this.selected.some(selected => Math.abs(selected) === this.none.id);
        },

        hasMultipleLists() {
            return this.allSegmentLists?.length > 1;
        }
    },

    watch: {
        selectedSegmentList(segmentList) {
            if (segmentList?.children?.length) {
                const children = segmentList.children.map(id => tagStore.get(id));
                this.journey = children.filter(tag => tag.flag !== "NONE_OF_THE_ABOVE" && !tag.deleted && !tag._loading && !tag.isSecondary);
                this.interactions = children.filter(tag => tag.flag !== "NONE_OF_THE_ABOVE" && !tag.deleted && !tag._loading && tag.isSecondary);
                this.none = children.find(tag => tag.flag === "NONE_OF_THE_ABOVE");
            } else {
                this.journey = [];
                this.interactions = [];
                this.none = null;
            }
        }
    },

    created() {
        tagStore.refresh(true); // Can happen async here.
    },

    async mounted() {
        await this.loadData();
    },

    methods: {
        substituteTagParamaters,

        showTooltip(event, segment) {
            showTooltipComponent(event.target, SegmentTooltip, {tag: segment})
        },

        async loadData() {
            try {
                this.loading = true;
                this.selected = [];
                await tagStore.refresh(true);

                this.allSegmentLists = await getAllCxSegmentLists();

                // if there are multiple cx lists, ensure that we select one that is active by default
                this.activeCxLists = await getActiveSegmentLists("CX_LIST");
                if (this.activeCxLists.length) {
                    this.selectedSegmentList = this.activeCxLists[0];
                } else {
                    this.selectedSegmentList = this.allSegmentLists[0];
                }

                const originalSegments = [...this.originalJourney,...this.originalInteractions];
                if (originalSegments.length) {
                    const selected = [];
                    for (const originalId of originalSegments) {
                        if (!isFinite(originalId)) continue; // Skip non-numbers.
                        const tag = tagStore.get(Math.abs(originalId));
                        if (tag.children?.length) {             // This is a parent, segment list tag.
                            tag.children.forEach(cId => {
                                const child = tagStore.get(cId);
                                if (child.flag === "NONE_OF_THE_ABOVE" || child.deleted || child._loading) return;
                                selected.push(cId * Math.sign(originalId));
                            })
                        } else {
                            selected.push(originalId);
                        }
                    }
                    this.selected = selected;
                }

                // Now we need to check that we have display options for all the selected segments.
                // We may not if a segment was from an archived or deleted brand, for example.
                const allKnownTags = new Set();
                for (const list of this.allSegmentLists) {
                    list.children.forEach(id => allKnownTags.add(id));
                }

                const unknown = [];
                this.selected.forEach(selected => {
                    if (!allKnownTags.has(Math.abs(selected))) {
                        unknown.push(Math.abs(selected));
                    }
                })

                // We add the parents of unknown segments to our list of risks, which will
                // enable users to choose those lists.
                if (unknown.length) {
                    const parentIds = new Set(unknown.map(id => tagStore.get(id)._parent.id));
                    for (const id of parentIds) {
                        this.allSegmentLists.push(tagStore.get(id));
                    }
                }

            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },

        isSelected(id) {
            return this.selected.some(sId => Math.abs(sId) === id);
        },

        isNegated(id) {
            return this.selected.some(selected => -selected === id);
        },

        negate(id) {
            const isNegated = this.isNegated(id);
            const actualId = isNegated ? -id : id;

            this.selected = this.selected.filter(current => current !== actualId);
            this.selected.push(-actualId);
            this.emitUpdates();
        },

        select(id) {
            if (this.isSelected(id)) {
                this.selected = this.selected.filter(sId => Math.abs(sId) !== id);
            } else {
                this.selected.push(id);
            }

            if (this.none && this.isSelected(this.none.id)) {
                this.$nextTick(() => this.select(this.none.id));      // Unselect the none-of-the-above.
            }

            this.emitUpdates();
        },

        selectAll() {
            const toRemove = new Set(this.allTags.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.selected = [...this.selected, ...this.allTags.map(f => f.id)];
            this.emitUpdates();
        },

        selectJourney() {
            const toRemove = new Set(this.journey.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.selected = [...this.selected, ...this.journey.map(f => f.id)];
            this.emitUpdates();
        },

        selectInteractions() {
            const toRemove = new Set(this.interactions.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.selected = [...this.selected, ...this.interactions.map(f => f.id)];
            this.emitUpdates();
        },

        unselectAll(includeNone) {
            includeNone ??= true;
            const toRemove = includeNone
                ? new Set(this.selectedSegmentList.children)
                : new Set(this.allTags.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.emitUpdates();
        },

        unselectJourney() {
            const toRemove = new Set(this.journey.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.emitUpdates();
        },

        unselectInteractions() {
            const toRemove = new Set(this.interactions.map(f => f.id));
            this.selected = this.selected.filter(selected => !toRemove.has(Math.abs(selected)));
            this.emitUpdates();
        },

        selectNonAbove() {
            if (this.none) this.selected.push(this.none.id);
            this.emitUpdates();
        },

        emitUpdates() {
            const journeyId = "" + this.selectedSegmentList.id;
            const interactionId = `${journeyId}:interactions`;

            if (!this.selected.length) {
                this.$emit(journeyId, []);
                this.$emit(interactionId, []);
                return;
            }

            const tags = [];
            const seen = new Set();
            for (const segmentList of this.allSegmentLists) {
                let allPositiveChildrenSelected = true;
                let allNegativeChildrenSelected = true;
                let positiveNoneSelected = null;
                for (const id of segmentList.children) {
                    const tag = tagStore.get(id);
                    if (tag.deleted) continue;
                    if (tag.flag === "NONE_OF_THE_ABOVE") {
                        positiveNoneSelected = positiveNoneSelected || this.selected.find(selected => selected === id) && tag;
                        continue;
                    }

                    allPositiveChildrenSelected = allPositiveChildrenSelected && this.selected.some(selected => selected === id);
                    allNegativeChildrenSelected = allNegativeChildrenSelected && this.selected.some(selected => selected === -id);
                }

                if (positiveNoneSelected) tags.push(positiveNoneSelected.id);

                if (allPositiveChildrenSelected) {
                    tags.push(segmentList.id);
                    segmentList.children.forEach(id => seen.add(id));
                }

                if (allNegativeChildrenSelected) {
                    tags.push(-segmentList.id);
                    segmentList.children.forEach(id => seen.add(-id));
                }
            }

            this.selected.forEach(selected => {
                if (!seen.has(selected)) tags.push(selected);
            });

            const journey = [];
            const interactions = [];

            for (const id of tags) {
                const tag = tagStore.get(Math.abs(id));
                if (tag.isSecondary) interactions.push(id);
                else journey.push(id);
            }

            let segmentChildrenMap = {};
            for (const segment of this.allSegmentLists) {
                segmentChildrenMap[segment.id] = segment.children;
            }

            let journeyTagMap = {};
            let interactionTagMap = {};
            for (const segment of this.allSegmentLists) {
                for (const tagId of journey) {
                    if (segmentChildrenMap[segment.id]?.includes(Math.abs(tagId)) || tagId === segment.id) {
                        if (journeyTagMap[segment.id]) journeyTagMap[segment.id].push(tagId)
                        else journeyTagMap[segment.id] = [tagId]
                    }
                }

                for (const tagId of interactions) {
                    if (segmentChildrenMap[segment.id]?.includes(Math.abs(tagId)) || tagId === segment.id) {
                        if (interactionTagMap[segment.id]) interactionTagMap[segment.id].push(tagId)
                        else interactionTagMap[segment.id] = [tagId]
                    }
                }
            }

            for (const segment of this.allSegmentLists) {
                if (journeyTagMap[segment.id]) this.$emit("" + segment.id, journeyTagMap[segment.id]);
                else this.$emit("" + segment.id, []);

                if (interactionTagMap[segment.id]) this.$emit(`${segment.id}:interactions`, interactionTagMap[segment.id]);
                else this.$emit(`${segment.id}:interactions`, []);
            }
        }
    }
}

</script>


<style scoped lang="sass">

.cx-segment-filter__columns
    display: flex
    flex-direction: row

    .cx-segment-filter__column
        display: flex
        flex-direction: column
        flex: 1 1

        h5
            //font-size: calc(1em + 1px)
            box-sizing: border-box
            padding: 5px 5px 0 90px
            margin: 0
            text-transform: uppercase

.cx-segment-filter__tag
    display: flex
    box-sizing: border-box
    padding: 5px
    width: 100%
    font-size: calc(1em + 1px)
    border-bottom: thin solid #444
    color: var(--be-colour-text-dark)

    &--selected
        background: radial-gradient(#444, rgba(0, 0, 0, 0) 60%)

.cx-segment-filter__tag-name
    box-sizing: border-box
    padding-left: 26px

.cx-segment-filter__tag-selectable
    box-sizing: border-box
    width: 100%
    padding: 2px 10px 2px 57px
    border: thin solid transparent

    &:hover
        transition: background-color var(--transition-duration), color var(--transition-duration)
        background: var(--background-menu-hover)
        cursor: pointer
        color: white

.cx-segment-filter__tag-selected
    padding: 2px 10px 3px 5px
    color: white


.cx-segment-filter__tag-negate
    box-sizing: border-box
    margin-right: 57px
    padding-left: 6px
    cursor: pointer
    opacity: 0
    &:hover
        transition: opacity var(--transition-duration)
        color: var(--be-colour-text-dark__hover)
        opacity: 1

.cx-segment-filter__tag:hover
    .cx-segment-filter__tag-negate
        transition: opacity var(--transition-duration) 200ms
        opacity: 1

.cx-segment-filter__tag-negated
    margin-right: 42.5px
    cursor: pointer

.cx-segment-filter__tag
    &--has-rpcs
        .cx-segment-filter__tag-name
            padding-left: 0

        .cx-segment-filter__tag-negate
            margin-right: 32px

        .cx-segment-filter__tag-negated
            margin-right: 20px

.cx-segment-filter__controls
    box-sizing: border-box
    padding-top: 10px
    display: grid
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)
    align-items: center


.cx-segment-filter__buttons
    box-sizing: border-box
    display: flex
    flex-wrap: wrap
    align-items: center
    padding-top: 10px

    & ::v-deep .btn
        text-align: start

    > .be-tag
        margin-right: 5px

.cx-segment-filter__none
    color: var(--be-colour-muted-text-dark)
    box-sizing: border-box
    padding-left: 90px

</style>