import { SOCIAL_NETWORKS } from "@/app/utils/SocialNetworks";

const formatSocialNetworksIconList = function(socialNetworksStr) {
    return socialNetworksStr
        .split(" ")
        .map(
            socialNetworkId =>
                `<i class="${symbol(socialNetworkId)}" ` +
                `title="${SOCIAL_NETWORKS[socialNetworkId] ?? socialNetworkId}"></i>`
        )
        .join(" ");
};

const symbol = socialNetworkId => {
    switch (socialNetworkId) {
        case "TWITTER":
            return "symbol-twitter";
        case "FACEBOOK":
            return "symbol-facebook-rect";
        case "INSTAGRAM":
            return "symbol-instagram";
        case "LINKEDIN":
            return "symbol-linkedin";
        case "TIKTOK":
            return "symbol-tiktok";
        case "TUMBLR":
            return "symbol-tumblr";
        case "VK":
            return "symbol-vk";
        case "YOUTUBE":
            return "synbol-youtube";
        case "TELEGRAM":
            return "symbol-telegram";
        case "WHATSAPP":
            return "symbol-whatsapp";
        case "HELLOPETER":
            return "symbol-hellopeter";
        case "GOOGLEBUSINESS":
            return "symbol-google-full";
        default:
            return "symbol-website";
    }
};

export default formatSocialNetworksIconList;
