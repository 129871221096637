export default function(code, options) {
    var location = Beef.LocationPicker.identifierInCache(code);

    if (location) {
        return new Handlebars.SafeString(Beef.LocationPicker.formatLocation(location));
    }

    var span = embedAndUpdateLocation(code);
    return new Handlebars.SafeString(span);
}

const embedAndUpdateLocation = function(item, negative) {
    var id = "location-" + item.toLowerCase().replace(/[\s,']+/g, '');

    Beef.LocationPicker.lookup(item)
        .then(function (location) {
            $('.' + id).html((negative ? '-' : '') + Beef.LocationPicker.formatLocation(location));
        })
        .catch(console.warn);

    return '<span class="' + id + '">' + item + '</span>';
};
