<template>
    <section class="be-author-network">
        <old-tooltip v-if="symbol" :label="tooltip">
            <i :class="symbol"></i>
        </old-tooltip>
    </section>
</template>

<script>

import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
    components: { OldTooltip },
    props: {
        mention: {
            type: Object,
            required: true
        }
    },

    computed: {
        symbol() {
            switch (this.mention.socialNetwork?.id) {
                case 'TWITTER': return 'symbol-twitter';
                case 'FACEBOOK': return 'symbol-facebook-rect';
                case 'INSTAGRAM': return 'symbol-instagram';
                case 'LINKEDIN': return 'symbol-linkedin';
                case 'TIKTOK': return 'symbol-tiktok';
                case 'TUMBLR': return 'symbol-tumblr';
                case 'VK': return 'symbol-vk';
                case 'YOUTUBE': return 'synbol-youtube';
                case 'TELEGRAM': return 'symbol-telegram';
                case 'WHATSAPP': return 'symbol-whatsapp';
                case 'HELLOPETER': return 'symbol-hellopeter';
                case 'GOOGLE': return 'symbol-google-full';
                default: return 'symbol-website';
            }
        },

        tooltip() {
            if (!this.mention?.socialNetwork?.id) return "This is from a website or blog";
            return `This mention is from ${this.mention.socialNetwork.label}`;
        }
    }
}

</script>


<style scoped lang="sass">

.be-author-network
    display: inline-block

</style>