import AccountList from './AccountList';
import {setTitle} from "@/app/Beef";

Beef.module("AccountList").addInitializer(function(_startupOptions){

    // This needs to be defined before the accounts route just below it.
    Beef.route(":code", "explore", function(code) {
        Beef.router.navigate("/" + code + "/explore", {trigger: true});
    });

    Beef.route("accounts", "accounts", function() {
        let view = new Beef.VuejsView.View({component: AccountList});
        Beef.content.show(view);
        setTitle("Account Selection");
    });


});
