var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"head title row-fluid edit-search-job\" title=\"Edit the name and filters of this search\">\n    <div class=\"buttons pull-right\">\n        <a id=\"edit-job-button\"\n           class=\"btn options\"\n           title=\"Edit the name and filters of this search\">\n            Edit\n        </a>\n    </div>\n    <h1>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n</div>\n\n<div class=\"search-setup-block\">\n    <div class=\"chart-region\"></div>\n</div>\n\n\n<div class=\"search-setup-block\">\n    <h4>Sample</h4>\n    <div class=\"mentions-outer\">\n        <div class=\"mentions-holder\">\n            <div class=\"mentions mention-region\"></div>\n        </div>\n    </div>\n    <div class=\"pages-region\"></div>\n</div>\n\n<div class=\"search-setup-block search-job-options edit-search-job\" title=\"Edit the name and filters of this search\">\n    <h4>Filter</h4>\n    <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"filter") : depth0), depth0))
    + "</p>\n</div>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"customQuery") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":64,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"button-region\"></div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"search-setup-block search-job-options edit-search-job\" title=\"Edit the name and filters of this search\">\n            <h4>Custom Gnip Rule</h4>\n            <p>\n                "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"customQuery") : stack1), depth0))
    + "\n            </p>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"search-setup-block\">\n            <h4>Phrases</h4>\n            <table class=\"table table-condensed table-bordered table-hover\">\n                <tbody class=\"phrases level0 job"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + " branch-collapsed\" data-job=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"job") : depth0)) != null ? lookupProperty(stack1,"searchPhrases") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":20},"end":{"line":54,"column":29}}})) != null ? stack1 : "")
    + "                    <tr>\n                        <td colspan=\"3\">\n                            <span class=\"add-phrase\" title=\"Click to add a new search phrase\"><i class=\"icon-plus\"></i>Add phrase</span>\n                            <span class=\"add-phrases-from-brand\" title=\"Click to add phrases from a brand\"><i class=\"icon-plus\"></i>Add brand phrases</span>\n                        </td>\n                    </tr>\n                </tbody>\n            </table>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr data-phrase=\""
    + alias1(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\">\n                        <td colspan=\"1\" class=\"phrase editable\" title=\"Click to edit this phrase\">\n                            <div class=\"inner\">\n                                <div class=\"q\">"
    + alias1(__default(require("../../helpers/renderPhrase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":50,"column":47},"end":{"line":50,"column":68}}}))
    + "</div>\n                            </div>\n                        </td>\n                    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"job") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":66,"column":7}}})) != null ? stack1 : "");
},"useData":true});