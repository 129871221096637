<template>
    <section class="beef-tooltip fantastic-chart--tooltip">
        <strong>{{formatDate(data.published, "dddd, MMMM D, YYYY")}}</strong>
        received
        <strong><deq-percentage is-proportion :percent="data.percent" :precision="2"/></strong>
        of the mentions
    </section>
</template>

<script>
import DeqPercentage from "@/components/formatters/DeqPercentage";
import {formatDate} from "@/app/utils/Format";

export default {
    components: {DeqPercentage},
    props: {
        data: Object
    },

    methods: {
        formatDate
    }
}
</script>

<style scoped lang="sass">

</style>