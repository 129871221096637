import Markdown from 'pagedown'
import {count, count as grouseCount, getMentions} from "@/data/Grouse";
import {calculateOnlineAve} from "@/dashboards/widgets/fantasticchart/FantasticUtilities";
import {appendFiltersReadably, getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {
    formatBrandName,
    formatNumber,
    formatPercentage,
    formatRand, formatSeconds,
    toEmojiHtml
} from "@/app/utils/Format";
import {calculateCxStats} from "@/app/toplevel/explore/overview/ExploreUtilities";
import {getTimedLocalstorageCache} from "@/data/Cache";
import {dateToEnglish, toEnglish} from "@/dashboards/filter/FilterToEnglish";
import {getPublicSentimentScore} from "@/app/utils/Sentiment";
import VuexStore from "@/store/vuex/VuexStore";
import {getBrand} from "@/app/utils/Brands";
import {createEqualityStatement} from "@/dashboards/filter/Generator";
import {capitalise, encloseInDisplayQuotes, removeQuotes, splitQuotedString} from "@/app/utils/StringUtils";
import {summariseFilter} from "@/app/utils/turducken";
import {
    calculateCes5PointScore,
    calculateCsatScore, calculateFcrScore, calculateFcrScoreFrom,
    calculateNpsScore,
    getNpsDetractorsFilter,
    getNpsPassivesFilter,
    getNpsPromotersFilter, getTotalCes5PointMentions, getTotalCsatMentions, getTotalFcrMentions, getTotalNpsMentions
} from "@/app/utils/Surveys";

/**
 * Tools for rendering markdown. This includes being able to query information
 * from an account, as well as showing mentions, and so on.
 */
Beef.module("Markdown").addInitializer(function(startupOptions) {

    var converter = new Markdown.getSanitizingConverter();

    /**
     * Given text, this returns an object with the text to display, as well
     * as various methods to set up event handling and embedding of interactive elements.
     * @param text
     * @param filter
     * @param accountCode
     * @param options Optional map of settings.
     * @returns {{destroy: destroy, hasUpdates: boolean, text: string, then: (function(*=): Promise<T | never>), events: events}}
     */
    this.render = function(text, filter, accountCode, options) {
        options = Object.assign({
            caption: "Commentary",
            columns: true
        }, options);

        var caption = options.caption;
        text = converter.makeHtml(text || "") || "";

        // Use a random string for class names so that we won't have conflicts between subsequent
        // calls of render and the ordering of their data fetches and DOM updates.
        const COMMAND_CLASS_PREFIX = `command${Math.floor(Math.random() * (1 - 10000) + 1)}-`;
        var iterator = Beef.Markdown.getCommands(filter, text, COMMAND_CLASS_PREFIX);
        var commands = Array.from(iterator);

        text = toEmojiHtml(iterator.text());
        text = "<div class='markdown-display" + (!options.columns ? " markdown-display--no-columns" : "") +"'>" + text + "</div>";
        // text = "<div class=" + (!options.columns ? " markdown-display--no-columns" : "") +"'markdown-display'>" + text + "</div>";

        var promise = null;
        if (filter && commands.length) {
            promise = Command.execute(commands, filter, caption)
                .then(function(results) {
                    var map = new Map();
                    results.forEach(function(r, i) {
                        map.set("." + COMMAND_CLASS_PREFIX + (i + 1), r);
                    });

                    return map;
                })
        }

        return {
            text: text,
            hasUpdates: !!promise,
            events: function(element, eventMap) {
                eventMap = eventMap || {};

                commands.forEach(function(c, i) {
                    var link;
                    var currentFilter = filter;
                    if (c.act === "button") {
                        link = element.querySelector("." + COMMAND_CLASS_PREFIX + (i + 1) + " a");
                        if (link) {
                            link.addEventListener("click", function(ev) {
                                ev.preventDefault();
                                ev.stopPropagation();
                                if (eventMap[c.subfilter]) eventMap[c.subfilter](ev);
                            })
                        }
                    }
                    if (c.act === "total" || c.act === 'top') {
                        link = element.querySelector("." + COMMAND_CLASS_PREFIX + (i + 1) + " .markdown__link");
                        if (link && accountCode) {
                            link.addEventListener("click", function(ev) {
                                ev.preventDefault();
                                ev.stopPropagation();
                                if (c.subfilter) {
                                    currentFilter = appendFiltersReadably(currentFilter, c.subfilter);
                                }
                                if (ev.target.dataset.filter) {
                                    currentFilter = appendFiltersReadably(currentFilter, ev.target.dataset.filter);
                                }
                                showMentions(currentFilter);
                            })
                        }
                    }
                    if (c.act === "mention") {
                        link = element.querySelector("." + COMMAND_CLASS_PREFIX + (i + 1) + " div");
                        var model = new Beef.MentionItem.Model(c.mention);
                        model.accountCode = accountCode;
                        var v = new Beef.MentionItem.View({model: model, timeline: true, noSelect: true, noView: true, el: link});

                        // Use array of classes as args for add since whitespace is not allowed with classList.add
                        var cssClasses = v.attributes().class.match(/\S+/g) || []; // non-whitespace strings as array
                        DOMTokenList.prototype.add.apply(link.classList, cssClasses);

                        v.render();
                        c.view = v;
                    }
                })
            },
            then: function(then) {
                return (promise || Promise.resolve(new Map())).then(then);
            },
            destroy: function() {
                commands.forEach(function(c) {
                    try {
                        c.destroy()
                    } catch (e) {
                        console.error(e);
                    }
                });
            }
        }
    };

    function errorHandler(error) {
        const account = VuexStore.state.account;
        console.error("There was as problem creating comment text", error);
        let text = error;
        if (error.responseText) {
            try {
                text = JSON.parse(error.responseText).error || text;
            } catch (e) {
                // ignore
            }
        }
        let errorText = "error";
        if (error.status === 422 && error.responseText.includes("BRAND-ERROR-MULTIPLE")) {
            errorText = "select a single brand"
        } else if (error.status === 422 && error.responseText.includes("BRAND-ERROR")) {
            errorText = "select a brand"
        } else if (error.status === 0) {
            text = "Unable to fetch data. Your filter may be too long.";
            errorText = "error.";
            if (account.dev) {
                text += " Please start grouse or your local proxy";
            }
        }

        return "<span class='error' title='" + text + "'>«" + errorText + "»</span>";
    }

    function Command(filter, act, field, subfilter, index, args) {
        this.act = act.toLowerCase().trim();
        this.field = field && field.toLowerCase().trim() || field;
        this.subfilter = getFilter(act, field, filter, subfilter);
        this.index = index;
        var command = this;

        if (args && args.var) {
            this.var = args.var;
        }

        var executeTotal = function(filter, data, memory) {
            var field = totals[this.field];
            if (!field) throw "Unrecognised field " + encloseInDisplayQuotes(this.field);

            if (this.subfilter) filter = "(" + filter + ") and (" + this.subfilter + ")";

            return Promise
                .resolve(data)
                .then(function(data) {
                    return data || grouseCount(filter, null, field.select);
                })
                .then(function(result) {
                    if (this.var) {
                        memory[this.var] = field.get(result);
                    }
                    return "<span class='number markdown__link' title='Click to see the related mentions'>" + field.format(result) + "</span>";
                }.bind(this))
                .catch(errorHandler)
        }.bind(this);

        const executeSummary = function (filter, data) {
            if (this.field) filter = "(" + filter + ") and (" + this.field + ")";

            if (data) return Promise.resolve(data);
            return summariseFilter(filter)
                .then(summary => summary.summary)
                .catch(errorHandler);
        }.bind(this);

        const countFcr = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            return await getTotalFcrMentions(filter);
        };

        const countFcrScore = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            const score = await calculateFcrScore(filter);

            return Math.round(score * 100) + '%';
        };

        const countCes5 = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            return await getTotalCes5PointMentions(filter);
        };

        const countCes5Score = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            const score = await calculateCes5PointScore(filter);

            return Math.round(score * 100) + '%';
        };

        const countCsat = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            return await getTotalCsatMentions(filter);
        };

        const countCsatScore = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            const score = await calculateCsatScore(filter);
            return Math.round(score * 100) + "%";
        };

        const countNpsScore = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            const score = await calculateNpsScore(filter);

            return Math.round(score * 100) + "%";
        };

        const countNps = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            return await getTotalNpsMentions(filter);
        };

        const countNpsDetractors = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            filter = appendFiltersReadably(filter, getNpsDetractorsFilter());

            const { mentionCount } = await grouseCount(filter);
            return mentionCount;
        };

        const countNpsPassives = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            filter = appendFiltersReadably(filter, getNpsPassivesFilter());

            const { mentionCount } = await grouseCount(filter);
            return mentionCount;
        };

        const countNpsPromoters = async filter => {
            if (this.field) filter = appendFiltersReadably(filter, this.field);
            filter = appendFiltersReadably(filter, getNpsPromotersFilter());

            const { mentionCount } = await grouseCount(filter);
            return mentionCount;
        };


        const calcCxStats = async filter => getTimedLocalstorageCache("markdown:executeCx:stats" + filter, async () => {
            return await calculateCxStats(null, null, null, filter);
        });
        const calcCxVerifiedTotal = async filter => getTimedLocalstorageCache("markdown:executeCx:total" + filter, async () => {
            return await count(filter, null, ["sentimentVerified"]);
        }) ;

        const executeCxPercent = async filter => {
            const stats = await calcCxStats(filter);
            const total = await calcCxVerifiedTotal(filter);

            return formatPercentage(total.sentimentVerifiedCount ? stats.total / total.sentimentVerifiedCount * 100 : 0, 1);
        };

        const executeCxResharePercent = async filter => {
            const stats = await calcCxStats(filter);
            const total = await calcCxVerifiedTotal(filter);

            return formatPercentage(total.sentimentVerifiedCount ? stats.reshares / total.sentimentVerifiedCount * 100 : 0, 1);
        };

        const executeCxPublicPercent = async filter => {
            const stats = await calcCxStats(filter);
            return formatPercentage(stats.total ? stats.public / stats.total * 100 : 0, 1);
        };

        const executeCxPrivatePercent = async filter => {
            const stats = await calcCxStats(filter);
            return formatPercentage(stats.total ? stats.private / stats.total * 100 : 0, 1);
        };

        const executeCxPurchasePercent = async filter => {
            const stats = await calcCxStats(filter);
            return formatPercentage(stats.total ? stats.purchaseCount / stats.total * 100 : 0, 1);
        };

        const executeCxCancelPercent = async filter => {
            const stats = await calcCxStats(filter);
            return formatPercentage(stats.total ? stats.cancelCount / stats.total * 100 : 0, 1);
        };




        var executeTop = function(filter, data) {
            var field = getGroupFields(this.field);

            if (!field && this.field) throw "Unrecognised field " + encloseInDisplayQuotes(this.field);
            if (this.subfilter) filter = "(" + filter + ") and (" + this.subfilter + ")";

            return Promise
                .resolve(data)
                .then(function(data) {
                    return data || grouseCount(filter, [field], ["mentionCount"]);
                })
                .then(function(result) {
                    var top = "No mentions";
                    var sub;
                    if (result && result.length) {
                        top = result[0];
                        if (top[field] !== undefined && top[field] !== null) {
                            var id = top[field].id || top[field];
                            top = top[field].name || top[field].fullName || top[field].label || top[field].id || top[field];
                            if (top.en) top = top.en;
                            top = format(field, top);
                            if (id) sub = createEqualityStatement(field, id);
                        } else {
                            top = "Unknown";
                        }
                    }

                    return "<span class='markdown__link' title='Click to see the related mentions'" +
                        (sub ? ('data-filter="' + sub + '"') : '') + ">" +
                        top +
                        "</span>";
                })
                .catch(errorHandler)
        }.bind(this);


        var executeMention = function(filter) {
            if (this.subfilter) filter = "(" + filter + ") and (" + subfilter + ")";
            var field = getOrderByField(this.field);

            return getMentions(filter, [field], 1)
                .then(function(result) {
                    if (!result.length) return "<span class='message'>«no mention»</span>";
                    command.mention = result[0];

                    // var model = new Beef.MentionItem.Model(result[0]);
                    // var v = new Beef.MentionItem.View({model: model, timeline: true, noSelect: true, noView: true});
                    // var cls = v.attributes().class;
                    // var body = Marionette.Renderer.render(v.getTemplate(), v.serializeData());
                    return "<div><small>«loading»</small></div>";
                })
                .catch(errorHandler)
        }.bind(this);

        var executeBrand = function(filter, possessive) {
            if (this.subfilter) filter = "(" + filter + ") and (" + subfilter + ")";

            const filterBrands = getBrandsInFilter(filter);
            if (!filterBrands.include.length) return Promise.resolve("<span title='Your filter selected no brand'>«no brand»</span>");

            // Handle multiple brands. Don't need to know their names here.
            if (filterBrands.include.length > 1) return Promise.resolve("the selected brands" + (possessive ? "'" : ''));
            // Now we only have one brand.
            const brand = getBrand(filterBrands.include[0]);
            if (!brand) return Promise.resolve("<span title='Unable to find this brand in your account'>«the selected brand»</span>");

            let name = formatBrandName(brand);
            if (possessive) name += "'s";
            return Promise.resolve(name);
        }.bind(this);

        var executeImage = function(url, alt) {
            var size = this.field;
            return Promise.resolve("<img class='inline-image' src='" + url + "' title='" + alt + "' " + (size ? " style='height: " + size + "px'" : '') +">")
        }.bind(this);


        var executeButton = function(text, event) {
            return Promise.resolve("<a href='#'>" + text + "</a>");
        };

        var executeTitle = function(caption) {
            return Promise.resolve(caption);
        };


        var executeEval = function(memory) {
            return Promise.reject("Not yet implemented")
                .catch(errorHandler);
        };

        var executeError = function() {
            return errorHandler(args && args.error || "Error");
        };

        this.execute = function(filter, data, caption, memory) {
            try {
                switch (this.act) {
                    case "total": return executeTotal(filter, data, memory);
                    case "brand": return executeBrand(filter, false);
                    case "brand's": return executeBrand(filter, true);
                    case "top": return executeTop(filter, data);
                    case "mention": return executeMention(filter);
                    case "button": return executeButton(this.field);
                    case "twitter": return executeImage("/static/img/networks/twitter.png", "Twitter");
                    case "x": return executeImage("/static/img/networks/x.svg", "Twitter");
                    case "tiktok": return executeImage("/static/img/networks/tiktok.png", "TikTok");
                    case "trustpilot": return executeImage("/static/img/networks/trustpilot.svg", "Trustpilot");
                    case "linkedin": return executeImage("/static/img/networks/linkedin.png", "LinkedIn");
                    case "facebook": return executeImage("/static/img/networks/facebook.png", "Facebook");
                    case "instagram": return executeImage("/static/img/networks/instagram.png", "Instagram");
                    case "youtube": return executeImage("/static/img/networks/youtube.png", "YouTube");
                    case "hellopeter": return executeImage("/static/img/networks/hellopeter.png", "HelloPeter");
                    case "googlebusiness": return executeImage("/static/img/networks/google.png", "GoogleBusiness");
                    case "googlebusiness-g": return executeImage("/static/img/networks/google-g.png", "GoogleBusiness");
                    case "website": return Promise.resolve("<i class='symbol-website' title='Website'></i>");
                    case "negative-icon": return Promise.resolve("<i class='icon-circle negative-sentiment'></i>");
                    case "neutral-icon": return Promise.resolve("<i class='icon-circle neutral-sentiment'></i>");
                    case "positive-icon": return Promise.resolve("<i class='icon-circle positive-sentiment'></i>");
                    case "risk": return Promise.resolve("<be-rpcs-icon full code='RISK'></be-rpcs-icon>");
                    case "purchase": return Promise.resolve("<be-rpcs-icon full code='PURCHASE'></be-rpcs-icon>");
                    case "cancel": return Promise.resolve("<be-rpcs-icon full code='CANCEL'></be-rpcs-icon>");
                    case "service": return Promise.resolve("<be-rpcs-icon full code='SERVICE'></be-rpcs-icon>");
                    case "risk-small": return Promise.resolve("<be-rpcs-icon code='RISK'></be-rpcs-icon>");
                    case "purchase-small": return Promise.resolve("<be-rpcs-icon code='PURCHASE'></be-rpcs-icon>");
                    case "cancel-small": return Promise.resolve("<be-rpcs-icon code='CANCEL'></be-rpcs-icon>");
                    case "service-small": return Promise.resolve("<be-rpcs-icon code='SERVICE'></be-rpcs-icon>");
                    case "title": return executeTitle(caption);
                    case "rawfilter": return Promise.resolve(filter);
                    case "englishfilter": return new Promise((resolve, reject) => {
                        try {
                            toEnglish(filter, english => resolve(english));
                        } catch(e) {
                            reject(e);
                        }
                    });
                    case "summarise-mentions": return executeSummary(filter, data, memory);
                    case "timeframe": return Promise.resolve(capitalise(dateToEnglish(filter)));
                    case "cx%": return executeCxPercent(filter, data, memory);
                    case "cx-reshare%": return executeCxResharePercent(filter);
                    case "cx-public%": return executeCxPublicPercent(filter);
                    case "cx-private%": return executeCxPrivatePercent(filter);
                    case "cx-purchase%": return executeCxPurchasePercent(filter);
                    case "cx-cancel%": return executeCxCancelPercent(filter);
                    case "eval": return executeEval(memory);
                    case "error": return executeError();

                    case "nps-total": return countNps(filter);
                    case "nps-score": return countNpsScore(filter);
                    case "nps-detractors": return countNpsDetractors(filter);
                    case "nps-passives": return countNpsPassives(filter);
                    case "nps-promoters": return countNpsPromoters(filter);

                    case "csat-total": return countCsat(filter);
                    case "csat-score": return countCsatScore(filter);

                    case "ces5-total": return countCes5(filter);
                    case "ces5-score": return countCes5Score(filter);

                    case "fcr-total": return countFcr(filter);
                    case "fcr-score": return countFcrScore(filter);
                    default:
                        return Promise.resolve("<span class='error' title='Unrecognised command: " + this.act + "'>«error»</span>");
                }
            } catch (e) {
                return Promise.resolve(errorHandler(e))
            }
        };

        this.key = function() {
            var key = this.act;
            if (this.subfilter) {
                var sub = this.subfilter.toLowerCase().trim().replace(/\s/g, '');
                return key + ":" + sub;
            }
            return key;
        };

        this.destroy = function() {
            if (this.view) {
                this.view.close();
                this.view = null;
            }
        };

        return this;
    }


    Command.execute = function(commands, filter, caption) {
        var memory = {};
        if (!commands.length) return Promise.resolve([]);
        if (commands.length === 1) {
            return commands[0]
                .execute(filter, null, caption, memory)
                .then(function(r) {
                    return [r] });
        }

        var totalCommands = {};

        commands.forEach(function(c) {
            switch (c.act) {
                case "total":
                    var data = totalCommands[c.key()] = totalCommands[c.key()] || [];
                    data.push(c);
                    break;
            }
        });

        var completed = new Set();
        var fetches = [];

        var keys = Object.keys(totalCommands); // Need to keep consistent key / value order for later.
        keys
            .map(function (k) {return totalCommands[k];})
            .forEach(function (commands) {
                var select = new Set();
                var subfilter = commands[0].subfilter; // They should all be identical
                commands.forEach(function (c) {
                    var field = totals[c.field];
                    if (field) {
                        field.select.forEach(function(d) { select.add(d) });
                        completed.add(c.key());
                    }
                });

                var f = subfilter ? "(" + filter + ") and (" + subfilter + ")" : filter;
                fetches.push(grouseCount(f, null, Array.from(select)));
            });

        return Promise.all(fetches)
            .then(function(results) {
                return Promise.all(commands.map(function(c) {
                    if (!completed.has(c.key())) {
                        return c.execute(filter, null, caption, memory);
                    }

                    // Find index of results:
                    var index = keys.indexOf(c.key());
                    if (index < 0) return c.execute(filter, null, caption, memory);

                    return c.execute(filter, results[index], caption, memory);
                }))
            }).catch(function(e) {
                let error = errorHandler(e);
                return Promise.all(commands.map(function(c) {
                    return error;
                }));
            })
    };

    this.Command = Command;

    this.getCommands = function(filter, text, prefix) {
        var count = 1;

        var result = {
            next: function() {
                try {
                    var openingIndex = text.indexOf('{{');
                    if (openingIndex < 0) return { done: true};

                    var closingIndex = text.indexOf('}}');
                    if (closingIndex < 0) return { done: true };

                    var commandStr = text.substring(openingIndex, closingIndex + 2);
                    text = text.replace(commandStr, "<span class='text-loading " + prefix + count + "'><small>«loading»</small></span>");

                    return { done: false, value: parseCommand(filter, commandStr, count++)}
                } catch (e) {
                    console.error(e);
                    return { done: true }
                }

            },

            text: function() { return text }
        };

        // Defining the iterable in this naff way because we're still using
        // es5 syntax in our minifier, and there is much woah and gnashing of teeth.
        result[Symbol.iterator] = function() { return this };

        return result;
    };

    var parseCommand = function(filter, commandString, index) {
        if (!commandString) return null;

        try {
            commandString = commandString
                .replace("{{", "")
                .replace("}}", "");

            let tokens = splitQuotedString(commandString, ["'", '"']);
            let field = tokens.length >= 2 ? removeQuotes(tokens[1]) : null;

            let subFilter = null;
            if (tokens.length >= 3) subFilter = removeQuotes(tokens[2]);

            // The < character is given to us escaped as &lt;
            if (field?.includes('&lt;')) field = field.replace(/&lt;/g, '<');
            if (subFilter?.includes('&lt;')) subFilter = subFilter.replace(/&lt;/g, '<');

            let args = {};
            if (tokens.length >= 4) {
                tokens
                    .slice(3)
                    .forEach(s => {
                        let split = s.split('=');
                        if (split.length !== 2) throw new Error("Bad argument: " + s);
                        args[split[0]] = split[1];
                    });
            }

            return new Command(filter, tokens[0], field, subFilter, index, args);
        } catch (e) {
            console.error("Problem parsing command: ", commandString);
            return new Command(filter,"error", null, null, index, {error: "Unable to understand your command"});
        }
    };

    var getOrderByField = function(field) {
        switch(field) {
            case "ots": return "OTS";
        }

        return field;
    };

    var getGroupFields = function(field) {
        switch (field) {
            case "authors":
            case "uniqueauthors":
            case "author":              return "authorId";
            case "site":
            case "uniquesites":
            case "sites":               return "site";
            case "country":
            case "countries":           return "country";
            case "city":
            case "cities":              return "city";
            case "language":
            case "languages":           return "language";
            case "brand":
            case "brands":              return "brand";
            case "socialnetwork":       return "socialNetwork";
            case "tag":
            case "tags":                return "tag";
            case "sentiment":           return "sentiment";

        }

        return null;
    };

    var format = function(field, value) {
        switch (field) {
            case "sentiment":
                if (value <= -1) return "Negative";
                if (value === 0) return "Neutral";
                if (value >= 1) return "Positive";
                return value;
            default:
                return value;
        }
    };

    var getFilter = function(act, field, filter, subfilter) {
        if (act === "total") {
            var totalField = totals[field];
            if (totalField && totalField.filter) {
                let fieldFilter = totalField.filter;
                if (fieldFilter instanceof Function) {
                    fieldFilter = fieldFilter(filter, subfilter);
                }
                subfilter = appendFiltersReadably(subfilter, fieldFilter);
            }
        }
        return subfilter;
    };

    function getBenchmarkFilter(filter, subfilter) {
        if (subfilter) {
            filter = appendFiltersReadably(filter, subfilter);
        }

        let fieldFilter = getPublicSentimentScore();
        const brands = getBrandsInFilter(filter).include;
        if (brands.length) {
            fieldFilter = appendFiltersReadably(brands.map(id => `brand is ${id}`).join(' or '), fieldFilter);
        }

        return fieldFilter;
    }


    var totals = {
        "online-ave": {
            name: "Online AVE",
            select: ["totalOTS"],
            get: d => calculateOnlineAve(d.totalOTS),
            format: d => formatRand(calculateOnlineAve(d.totalOTS))
        },
        "engagement": {
            name: "Engagement",
            select: ["totalEngagement"],
            get: function(d) { return d.totalEngagement },
            format: function(d) { return formatNumber(d.totalEngagement) }
        },
        "mentions": {
            name: "Mentions",
            select: ["mentionCount"],
            get: function(d) { return d.mentionCount },
            format: function(d) { return formatNumber(d.mentionCount) }
        },
        "ots": {
            name: "OTS",
            select: ["totalOTS"],
            get: function(d) { return d.totalOTS },
            format: function(d) { return formatNumber(d.totalOTS) }
        },
        "conversations": {
            name: "Conversations",
            select: ["conversationIdCount"],
            get: function(d) { return d.conversationIdCount },
            format: function(d) { return formatNumber(d.conversationIdCount) }
        },
        "authors": {
            name: "Authors",
            select: ["authorIdCount"],
            get: function(d) { return d.authorIdCount },
            format: function(d) { return formatNumber(d.authorIdCount) }
        },
        "sites": {
            name: "Sites",
            select: ["siteCount"],
            get: function(d) { return d.siteCount },
            format: function(d) { return formatNumber(d.siteCount) }
        },
        "reshares": {
            name: "Reshares",
            select: ["totalReshareCount"],
            get: function(d) { return d.totalReshareCount },
            format: function(d) { return formatNumber(d.totalReshareCount) }
        },
        "replies": {
            name: "Replies",
            select: ["totalReplyCount"],
            get: function(d) { return d.totalReplyCount },
            format: function(d) { return formatNumber(d.totalReplyCount) }
        },
        "positive": {
            name: "Positive sentiment",
            select: ["totalPositive"],
            filter: "process is verified",
            get: function(d) { return d.totalPositive },
            format: function(d) { return formatNumber(d.totalPositive) }
        },
        "positive%": {
            name: "Positive sentiment%",
            select: ["totalPositive", "mentionCount"],
            filter: "process is verified",
            get: function(d) { return d.totalPositive / d.mentionCount },
            format: function(d) { return formatPercentage(d.totalPositive / d.mentionCount * 100, 1) }
        },
        "negative": {
            name: "Negative sentiment",
            select: ["totalNegative"],
            filter: "process is verified",
            get: function(d) { return d.totalNegative },
            format: function(d) { return formatNumber(d.totalNegative) }
        },
        "negative%": {
            name: "Negative sentiment%",
            select: ["totalNegative", "mentionCount"],
            filter: "process is verified",
            get: function(d) { return d.totalNegative / d.mentionCount },
            format: function(d) { return formatPercentage(d.totalNegative / d.mentionCount * 100, 1) }
        },
        "neutral": {
            name: "Neutral sentiment",
            select: ["totalNeutral"],
            filter: "process is verified",
            format: function(d) { return formatNumber(d.totalNeutral) }
        },
        "neutral%": {
            name: "Neutral sentiment%",
            select: ["totalNeutral", "mentionCount"],
            filter: "process is verified",
            get: function(d) { return d.totalNeutral },
            format: function(d) { return formatPercentage(d.totalNeutral / d.mentionCount * 100, 1) }
        },
        "net": {
            name: "Net sentiment",
            select: ["totalSentiment"],
            filter: "process is verified",
            get: function(d) { return d.totalSentiment },
            format: function(d) { return formatNumber(d.totalSentiment) }
        },
        "net%": {
            name: "Net sentiment%",
            select: ["totalSentiment", "mentionCount"],
            filter: "process is verified",
            get: function(d) { return d.totalSentiment / d.mentionCount },
            format: function(d) { return formatPercentage(d.totalSentiment / d.mentionCount * 100, 1) }
        },
        "public-net": {
            name: "Public sentiment",
            select: ["totalVerifiedSentiment"],
            filter: getPublicSentimentScore(),
            get: function(d) { return d.totalVerifiedSentiment },
            format: function(d) { return formatNumber(d.totalVerifiedSentiment) }
        },
        "public-net%": {
            name: "Public sentiment%",
            select: ["totalVerifiedSentiment", "sentimentVerifiedCount"],
            filter: getPublicSentimentScore(),
            get: function(d) { return d.totalVerifiedSentiment / d.sentimentVerifiedCount },
            format: function(d) { return formatPercentage(d.totalVerifiedSentiment / d.sentimentVerifiedCount * 100, 1) }
        },
        "benchmark-net": {
            name: "Benchmark sentiment",
            select: ["totalVerifiedSentiment"],
            filter: getBenchmarkFilter,
            get: function(d) { return d.totalVerifiedSentiment },
            format: function(d) { return formatNumber(d.totalVerifiedSentiment) }
        },
        "benchmark-net%": {
            name: "Benchmark sentiment%",
            select: ["totalVerifiedSentiment", "sentimentVerifiedCount"],
            filter: getBenchmarkFilter,
            get: function(d) { return d.totalVerifiedSentiment / d.sentimentVerifiedCount },
            format: function(d) { return formatPercentage(d.totalVerifiedSentiment / d.sentimentVerifiedCount * 100, 1) }
        },
        "unique-interactions": {
            name: "Unique interactions",
            select: ["interactionCount"],
            get: function(d) { return d.interactionCount },
            format: function(d) { return formatNumber(d.interactionCount) }
        },
        "interaction-response-rate": {
            name: "Interaction response rate",
            select: ["averageInteractionHasResponse"],
            get: function(d) { return d.averageInteractionHasResponse },
            format: function(d) { return formatPercentage(d.averageInteractionHasResponse * 100) }
        },
        "interaction-response-time": {
            name: "Average interaction response time",
            select: ["averageInteractionResponseTime"],
            get: function(d) { return d.averageInteractionResponseTime },
            format: function(d) { return formatSeconds(d.averageInteractionResponseTime) }
        },
        "interaction-wh-response-time": {
            name: "Average interaction response time (working hours)",
            select: ["averageInteractionWhResponseTime"],
            get: function(d) { return d.averageInteractionWhResponseTime },
            format: function(d) { return formatSeconds(d.averageInteractionWhResponseTime) }
        },
        "interaction-first-response-time": {
            name: "Average first response time",
            select: ["averageInteractionFirstResponseTime"],
            get: function(d) { return d.averageInteractionFirstResponseTime },
            format: function(d) { return formatSeconds(d.averageInteractionFirstResponseTime) }
        },
        "interaction-wh-first-response-time": {
            name: "Average first response time (working hours)",
            select: ["averageInteractionWhFirstResponseTime"],
            get: function(d) { return d.averageInteractionWhFirstResponseTime },
            format: function(d) { return formatSeconds(d.averageInteractionWhFirstResponseTime) }
        },
        "interaction-follow-up-response-time": {
            name: "Average follow-up response time",
            select: ["averageInteractionFollowupResponseTime"],
            get: function(d) { return d.averageInteractionFollowupResponseTime },
            format: function(d) { return formatSeconds(d.averageInteractionFollowupResponseTime) }
        },
        "interaction-wh-follow-up-response-time": {
            name: "Average follow-up response time (working hours)",
            select: ["averageInteractionWhFollowupResponseTime"],
            get: function(d) { return d.averageInteractionWhFollowupResponseTime },
            format: function(d) { return formatSeconds(d.averageInteractionWhFollowupResponseTime) }
        },
        "interaction-replied-to": {
            name: "Interactions replied to",
            select: ["interactionHasResponseCount"],
            get: function(d) { return d.interactionHasResponseCount },
            format: function(d) { return d.interactionHasResponseCount }
        }
    }


});