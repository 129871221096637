var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit Tag";
},"3":function(container,depth0,helpers,partials,data) {
    return "Add Tag";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tagId") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":51}}})) != null ? stack1 : "")
    + "</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"row-fluid\">\n        <div class=\"control-group\">\n            <label>Tag</label>\n            <div class=\"controls\">\n                <span class=\"tagId span12 tag-input uneditable-input\" tabindex=\"0\">\n                    <span name=\"tagId\"></span>\n                    <input class=\"tag popup-menu\" type=\"text\"/>\n                </span>\n            </div>\n        </div>\n        <div class=\"control-group\">\n            <label>Required probability</label>\n            <div class=\"controls\">\n                <input type=\"text\" name=\"probability\" class=\"probability span4\"/>\n            </div>\n        </div>\n        <p style=\"margin-top: 20px\">\n            Only tags that have been set to 'Auto Assign' can be used\n        </p>\n    </div>\n\n    <div class=\"dialog-button-bar\">\n        <a class=\"delete btn pull-left\" title=\"Delete Tag\">Delete</a>\n        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n\n</div>";
},"useData":true});