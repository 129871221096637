import {deprecatedFetchTags} from "@/data/DeprecatedBeefCache";
import {editOnlineProfiles} from "@/app/Permissions";
import {hasExpiredOrExpiresSoon} from "@/app/popup/token-expire/TokenUtilities";
import _ from 'underscore';
import {isFunction} from "@/app/utils/Util";
import {getProfileIcon} from "@/app/utils/Profiles";
import {profileTypes} from "@/setup/profiles/ProfileUtils";

/**
 * Displays an individual online presence. The View must be used with an instance of the Model in this file and not a
 * generic Backbone.Model.
 */
Beef.module("OnlineProfileItem").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
        initialize: function(attributes, options) {
            if (options && options.urlRoot) this.urlRoot = options.urlRoot;
        },

        getLink: function() {
            switch (this.attributes.type) {
                case "TWITTER_SCREEN_NAME":
                    return "https://twitter.com/" + this.attributes.handle;
                case "FACEBOOK_PAGE":
                case "FACEBOOK_USER":
                    return "https://www.facebook.com/" + this.attributes.handle;
                case "INSTAGRAM_USER":
                    return "https://instagram.com/" + this.attributes.handle;
                case "YOUTUBE_CHANNEL":
                    return "https://www.youtube.com/channel/" + this.attributes.handleId;
                case "LINKEDIN_COMPANY":
                case "TELEGRAM_CHANNEL":
                    return this.attributes.link;
            }
            return "";
        },

        isSelected: function() {
            return this.get('_selected');
        },

        setSelected: function(selected) {
            this.set('_selected', selected);
        },

        /**
         * Sets the tags on this model from the input map.
         * @param tagsById A map of tags by id.
         */
        updateTags: function(tagsById) {
            var tags = [];
            _.each(this.get("tagIds"), function(tagId) {
                var tag = tagsById[tagId];
                if (tag) {
                    tags.push(tag);
                }
            });
            this.set("_tags", tags);
        }
    });

    this.supportsDirectMessages = function(type) {
        return ("TWITTER_SCREEN_NAME" == type) || ("FACEBOOK_PAGE" == type);
    };

    this.isAuthorizableType = function(type) {
        return type != 'WHATSAPP' && type != 'FACEBOOK_USER' && type != 'YOUTUBE_CHANNEL' && type != 'TELEGRAM_CHANNEL' && type != 'HELLOPETER' && type != 'GOOGLEBUSINESS';
    };

    this.getDisplayName = function(handle, handleId, name, type) {
        if (arguments.length === 1) {
            handleId = handle.handleId;
            name = handle.name;
            handle = handle.handle;
        }
        name = name || handle;
        return handleId === handle ? name : handle;
    };

    this.getAvatar = function (type, picLink, displayName, handleId) {
        if (picLink) return picLink

        else if(type.indexOf("FACEBOOK") === 0) picLink = "https://graph.facebook.com/" + handleId + "/picture";
        else if(type.indexOf("WHATSAPP") === 0) picLink = "/img/whatsapp.svg";
        return picLink;
    };

    this.getFailAvatar = function (type) {
        switch (type) {
            case profileTypes.facebook:
                return '/img/consumer_avatar.png';
            case profileTypes.instagram_business:
            case profileTypes.instagram_user:
                return '/img/instagram-blank.jpg';
            case profileTypes.twitter:
                return '/img/twitter_avatar.png';
            default:
                return '/img/consumer_avatar.png';
        }
    };

    this.View = Backbone.Marionette.ItemView.extend({
        getTemplate: function() {
            switch (this.options.display) {
                case 'short':
                    return require("@/setup/online/OnlineProfileItemShort.handlebars");
                case 'full':
                default:
                    return require("@/setup/online/OnlineProfileItem.handlebars");
            }
        },

        // This function needs to have the #make function overridden, as below.
        tagName: function() {
            switch (this.options.display) {
                case 'short':
                    return 'div';
                case 'full':
                default:
                    return 'tr';
            }
        },

        attributes: function () {
            return {
                class: "online-presence-item",
                "data-type": this.model.get("type"),  // used by OnlineProfileList.js to toggle visibility
                "data-authorized": !!this.model.get("authorized")  // used by OnlineProfileList.js to toggle visibility
            };
        },

        events: {
            "click .selectable-area": "toggleSelect",
            "click .extra-brands .toggle": "toggleExtraBrands",
            "click i.smiley-warn": "profileErrorInfo"
        },

        modelEvents: {
            "change": "render",
            "change:_selected": "updateSelection",
            "change:tagIds": "updateModelTags"
        },

        initialize: function(options) {
            this.eventBus = options.eventBus;
        },

        make: function(tagName, attributes, content) {
            if (isFunction(tagName)) tagName = tagName.call(this);
            return Backbone.View.prototype.make.call(this, tagName, attributes, content);
        },

        templateHelpers: function() {
            let attr = this.model.attributes;
            let type = this.model.get('type');
            let prefix = getProfileIcon(type);
            let displayName = Beef.OnlineProfileItem.getDisplayName(this.model.get('handle'), this.model.get('handleId'),
                this.model.get('name'), type);
            let displayHandle = this.model.get('handleId');

            if (this.model.get('type') == profileTypes.googleBusiness) {
                displayHandle = this.model.get('description');
            } 

            let avatar = Beef.OnlineProfileItem.getAvatar(type, this.model.get('pictureLink'), displayName, this.model.get('handleId'));

            let brands = this.model.get('brands');
            let extraBrands;
            if (brands && brands.length > 4) {
                extraBrands = brands.slice(3);
                brands = brands.slice(0, 3);
            }

            let authorizedText, authorizedTitle, canAuthorize, authorizationExpired, showWarning = false;
            if (!Beef.OnlineProfileItem.isAuthorizableType(type)) {
                canAuthorize = false;
                authorizedText = "n/a";
                authorizedTitle = "Authorisation is not supported for this type of profile";
            } else {
                canAuthorize = editOnlineProfiles();
                if (attr.authorized) {
                    if (hasExpiredOrExpiresSoon(attr.tokenExpire)) {
                        authorizedText = "Expired";
                        authorizedTitle = "Authorisation has expired";
                        authorizationExpired = true;
                    } else {
                        authorizedText = "Yes";
                        if (type === 'INSTAGRAM_USER') {
                            authorizedTitle = "DataEQ is collecting mentions and data from this profile";
                        } else {
                            authorizedTitle = "DataEQ may post using this profile";
                            if (attr.directMessagesEnabled) {
                                authorizedText += " + DMs";
                                authorizedTitle += " and direct messages are visible in DataEQ";
                            }
                        }
                    }
                } else {
                    authorizedText = "No";
                    if (type.indexOf("LINKEDIN_COMPANY") === 0) {
                        authorizedTitle = "LinkedIn profiles must be authorised to collect mentions, click to authorise this profile";
                        showWarning = true;
                    } else {
                        authorizedTitle = "Click to authorise this profile";
                    }
                }
            }

            return {
                _options: this.options,
                link: this.model.getLink(),
                avatar: avatar,
                failAvatar: Beef.OnlineProfileItem.getFailAvatar(type),
                prefix: prefix,
                displayName: displayName,
                brands: brands,
                extraBrands: extraBrands,
                canAuthorize: canAuthorize,
                authorizedText: authorizedText,
                authorizedTitle: authorizedTitle,
                authorizationExpired: authorizationExpired,
                showWarning: showWarning,
                displayHandle: displayHandle,
            }
        },

        toggleSelect: function() {
            var selected = !this.model.isSelected();
            this.model.setSelected(selected);
            this.model.trigger('change:selected', selected);
        },

        updateSelection: function() {
            var selected = this.model.isSelected();
            this.$el.toggleClass('selected', selected);
        },

        /**
         * Updates the tags on this view's model.
         */
        updateModelTags: function() {
            var that = this;
            deprecatedFetchTags(this.options.accountCode, function(tagsById) {
                that.model.updateTags(tagsById);
            }, this.cache);
        },

        toggleExtraBrands: function(ev) {
            this.$('.extra-brands').toggleClass('expanded');
        },

        profileErrorInfo: function () {
            var err = this.model.attributes.smileyError;
            Beef.GenericMsg.show({title: err.error, profileError:err})
        },

        instagramAuthLink: function (ev) {
            ev.preventDefault();
            var showTitle = "Authorise Instagram Profile";
            var msg = "Reading an Instagram profile requires explicit permission from said profile. Please follow the link below to authorise the desired profile.";
            var accountCode = this.options.accountCode;
            var msg2 = "The Online Profiles page may need to be refreshed after authorisation is complete.";

            $.ajax({
                url: "/api/online-profile/createInstagramState",
                data: {
                    code: accountCode
                },
                success: function(data) {
                    var link = "https://analyse.dataeq.com/api/online-profile/instagramexternal?state=" + data.state;
                    Beef.GenericMsg.show({title: showTitle, authLink: {msg: msg, link: link, msg2: msg2}});
                }.bind(this)
            });
        }

    });
});