import _ from 'underscore';

/**
 * Get some JSON data from Snoek. The callback is optional, and this method will
 * return a promise for the data.
 * @deprecated Use Services.js
 */
export function deprecatedSnoekGetJson(endpoint, data, callback) {
    const authorization = Beef?.startupOptions?.authorization;
    // noinspection JSUnresolvedVariable
    const snoekApi = Beef?.startupOptions?.snoekApi;
    const endpointPrefix = Beef?.startupOptions?.account?.storage === 'v4' ? 'v4/' : 'v4/';

    if (!authorization || !snoekApi) {
        throw new Error("Bad initialisation of deprecated function");
    }


    if (!callback) callback = _.identity;
    return $.ajax({
        dataType: "json",
        url: snoekApi + endpointPrefix + endpoint,
        data: data,
        success: callback,
        headers: { Authorization: authorization }
    });
}

/**
 * Sends a post request to Snoek. Returns the xhr object. The callback is optional, and this method
 * will return a promise for the data.
 *
 * @deprecated Use services.js
 */
export function depcreatedSnoekPostJson(endpoint, data, callback) {
    const authorization = Beef?.startupOptions?.authorization;
    // noinspection JSUnresolvedVariable
    const snoekApi = Beef?.startupOptions?.snoekApi;
    const endpointPrefix = Beef?.startupOptions?.account?.storage === 'v4' ? 'v4/' : 'v4/';

    if (!authorization || !snoekApi) {
        throw new Error("Bad initialisation of deprecated function");
    }

    if (!callback) callback = _.identity;
    if (endpoint.charAt(0) !== "/") endpoint = endpointPrefix + endpoint;
    else endpoint = endpoint.substr(1);
    return $.ajax({
        url: snoekApi + endpoint,
        type: "POST",
        contentType: "application/json",
        dataType: 'json',
        data: data,
        success: callback,
        headers: { Authorization: authorization }
    });
}

/**
 * This is useful when you need to fetch all of the data from a paged endpoint. It returns
 * a jquery promise that will be passed the concatenation of all of the data portions
 * of a paged Snoek endpoint.
 * @deprecated Uses old jquery promises
 */
export function aggregatePage(endpoint, params) {
    let aggregatePromise = new $.Deferred();
    params = Object.assign({
        limit: 10000,
        offset: 0
    }, params || {});

    let aggregate = [];

    function determineEndpoint() {
        let args = _(params).chain()
            .map(function(value, key) { return key + '=' + encodeURIComponent(value); })
            .join('&')
            .value();

        return endpoint + (endpoint.indexOf('?') < 0 ? "?" : "&") + args;
    }


    function resultsReturned(data) {
        aggregate = aggregate.concat(data.data);

        if (data.data && data.data.length === data.limit) {
            params.offset += params.limit;
            var xhr = deprecatedSnoekGetJson(determineEndpoint(), null, resultsReturned);
            xhr.fail(function() {
                aggregatePromise.reject();
            })
        }
        else {
            aggregatePromise.resolve(aggregate);
        }
    }

    let xhr = deprecatedSnoekGetJson(determineEndpoint(), null, resultsReturned);
    xhr.fail(function() { aggregatePromise.reject(); });
    return aggregatePromise.promise();
}


/**
 * Create a link to a Snoek endpoint.
 */
export function toSnoekLink(endpoint, data) {
    let link = "/v4/" + endpoint;
    if (data) {
        let first = true
        _.each(data, function(value, key) {
            if (first) {
                link += "?";
                first = false;
            } else {
                link += "&";
            }
            if (value) link += key + "=" + encodeURIComponent(value);
        });
    }
    return link;
}
