/**
 * Drill down by adding/removing sentiment to the filter.
 */
Beef.module("Widget.CredibilitySelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Credibility",
        description:    "Interactively filter by credibility",
        width:          2,
        height:         4,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        filterAttribute: "credibility",

        refresh: function() {
            this.text(items);
        },

        renderRow: function(select, item) {
            if (!item) return;

            var tooltipText = "Select mentions with a credibility of " + item.id;

            select.attr('data-value', item.id);
            select.append('td').attr('title', tooltipText).append('div').text(item.id);
            select.append('td').attr('title', tooltipText).append('div').text(item.name);
        }
    });

    var items = [
        { id: '0', name: 'Low' },
        { id: '1', name: '' },
        { id: '2', name: '' },
        { id: '3', name: '' },
        { id: '4', name: '' },
        { id: '5', name: 'Respected' },
        { id: '6', name: '' },
        { id: '7', name: '' },
        { id: '8', name: '' },
        { id: '9', name: 'Authoritative' },
        { id: 'UNKNOWN', name: '' }
    ]

});