import {difference as setDifference, intersection as setIntersection, union as setUnion} from "@/app/utils/Sets";
import VuexStore from "@/store/vuex/VuexStore";

/**
 * Utilities for dealing with Tags
 */

export function substituteTagParamaters(text, brands) {
    if (!text || !text.length) return text;

    let brandName = "the brand";
    if (brands && brands.length === 1) {
        brandName = brands[0].shortName || brands[0].fullName;
    }

    const mustCapitalise = text.startsWith('%brand%');
    text = text.replace(new RegExp('%brand%', 'g'), brandName);

    if (mustCapitalise) {
        text = text[0].toUpperCase() + text.slice(1);
    }
    return text;
}
