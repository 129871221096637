<template>
    <slotted-tag v-if="showWhenOriginalAdmin ? user.originalAdmin : user.admin"
                 class="staff-only-tag"
                 no-close
                 tooltip="Only DataEQ staff can see this content. Cients cannot.">
        <i :class="symbol"></i> BE Staff Only
    </slotted-tag>
</template>

<script>

import SlottedTag from "@/components/tags/SlottedTag";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

/** A convenient tag to show if something is visible to BE staff only. */
export default {
    store: VuexStore,
    components: {SlottedTag},
    props: {
        showWhenOriginalAdmin: Boolean,
        symbol: {
            type: String,
            default: 'symbol-info'
        }
    },
    computed: {
        ...mapState(['user'])
    }
}

</script>

<style scoped lang="sass">
.staff-only-tag
    font-size: var(--default-text-size)
    display: inline-block
    color: var(--be-colour-text-dark)

i.symbol-info
    color: white
</style>