import {CommandEl} from "@/dashboards/widgets/commentaryplus/customHtmlElements/CommandEl";
import {CustomInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/CustomInlineTool";

export class EtcInlineTool extends CustomInlineTool {
    getBtnOptions() {
        return etcBtnOptions;
    }

    getBtnName() {
        return "Etc";
    }

}

export const etcBtnOptions = [
    {
        text: "Brand name",
        command: "{{brand}}"
    },
    {
        text: "Brand name's",
        command: "{{brand's}}"
    },
    {
        text: "Metric title",
        command: "{{title}}"
    },

];