import Header from "@editorjs/header";
import {
    openInlineToolbar,
    sanitizer,
    toHeader
} from "@/dashboards/widgets/commentaryplus/blockTools/Utils";

export class Header1 extends Header {

    constructor({ data: e, config: t, api: s, readOnly: r }) {
        e.level ??= 1;
        super({ data: e, config: t, api: s, readOnly: r });
        if (!this.readOnly) {
            this.onKeyUp = this.onKeyUp.bind(this);
        }
        this.level = t.level;
    }

    render() {
        this._element = super.render();
        if (!this.readOnly) {
            this._element.addEventListener('keyup', this.onKeyUp);
        }
        return this._element;
    }

    static get toolbox(){
        return {
            icon: '<span>H1</span>',
            title: "Header 1"
        }
    }

    onKeyUp(t) {
        if (t.code === 'Space'){
            const words = this._element.innerHTML.split(' ');
            switch (words[0].trim()) {
                case '#&nbsp;':
                case '#':
                    toHeader(this._element, words, 1);
                    break;
                case '##&nbsp;':
                case '##':
                    toHeader(this._element, words, 2);
                    break;
                case '###&nbsp;':
                case '###':
                    toHeader(this._element, words, 3);
                    break;
                case '####&nbsp;':
                case '####':
                    toHeader(this._element, words, 4);
                    break;
            }
        }
        if (t.code === 'KeyC') {
            openInlineToolbar();
        }
    }

    static get sanitize() {
        return sanitizer;
    }

}

export class Header2 extends Header1 {

    constructor({ data: e, config: t, api: s, readOnly: r }) {
        e.level ??= 2;
        super({ data: e, config: t, api: s, readOnly: r });
    }
    static get toolbox() {
        return {
            icon: '<span>H2</span>',
            title: "Header 2"
        };
    }

    onPaste(e) {
        super.onPaste(e);
    }

}

export class Header3 extends Header1 {

    constructor({ data: e, config: t, api: s, readOnly: r }) {
        e.level ??= 3;
        super({ data: e, config: t, api: s, readOnly: r });
    }
    static get toolbox() {
        return {
            icon: '<span>H3</span>',
            title: "Header 3"
        };
    }

    onPaste(e) {
        super.onPaste(e);
    }

}

export class Header4 extends Header1 {

    constructor({ data: e, config: t, api: s, readOnly: r }) {
        e.level ??= 4;
        super({ data: e, config: t, api: s, readOnly: r });
    }
    static get toolbox() {
        return {
            icon: '<span>H4</span>',
            title: "Header 4"
        };
    }

    onPaste(e) {
        super.onPaste(e);
    }

}