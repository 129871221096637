/**
 * Provides a consistant way of popping up simple actions that require a profile to be chosen.
 */
Beef.module("SimpleProfileActionPopup").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({
        template: require("@/conversation/SimpleProfileActionPopup.handlebars"),
        attributes: { class: "profile-action-popup dialog" },

        templateHelpers: function() {
            return {
                verb: this.getVerb()
            }
        },

        bindings: function() {
            return {
                profile: { converterFactory: Beef.ProfilePicker.createConverterFactory("(choose a profile)"), elAttribute: "data-value" }
            }
        },

        events: {
            "click .like-button": "likeClicked"
        },

        modelEvents: {
            "change:profile": "profileChanged"
        },

        //----------------------------------

        onFirstRender: function() {

            var lastProfile = null;
            var mention = this.model.get('mention');

            if (Beef.Conversation.isTwitter(mention)) lastProfile = Beef.generalData(this.model.accountCode).get('last-twitter-profile');
            else if (Beef.Conversation.isFacebook(mention)) lastProfile = Beef.generalData(this.model.accountCode).get('last-facebook-profile');

            if (lastProfile) this.model.set('profile', lastProfile);

            Beef.ProfilePicker.attach(this, ".profile", "profile",
                {
                    onlyOne: true,
                    profiles: this.model.get('profiles'),
                    title: "Choose a profile to " + this.getVerb() + " this as"
                }
            );
        },

        //----------------------------------

        likeClicked: function(ev) {
            ev.preventDefault();
            var profile = this.model.get('profile');
            if (profile) this.model.trigger('actioned', parseInt(profile));
        },

        profileChanged: function() {
            var profile = this.model.get('profile');
            var mention = this.model.get('mention');

            if (profile) {
                if (Beef.Conversation.isTwitter(mention)) {
                    Beef.generalData(this.model.accountCode).set('last-twitter-profile', profile);
                }

                if (Beef.Conversation.isFacebook(mention)) {
                    Beef.generalData(this.model.accountCode).set('last-facebook-profile', profile);
                }

                this.$('.like-button').toggleClass('disabled', false);
            }
            else {
                this.$('.like-button').toggleClass('disabled', true);
            }
        },

        //----------------------------------

        onClose: function() {
            Beef.Popup.closePopups(this);
        },

        //----------------------------------

        getVerb: function() {
            return this.model.get('verb');
        }
    });

});
