var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <li class=\"divider\"></li>\n    <li><a class=\"menu-send-to-crowd\" data-method=\"sendToCrowd\" title=\"Send the selected mentions to the Crowd\">Send to Crowd</a></li>\n    <li><a class=\"whip-crowd\" data-method=\"toggleWhipCrowd\" title=\"Send relevancy updates to the Crowd to change their behaviour\">Relevancy to Crowd</a></li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <li><a data-method=\"copyToOtherAccounts\" title=\"Send these mentions to other accounts\">Copy to other account(s)</a></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <li class=\"divider\"></li>\n    <li><a data-method=\"comparePredicted\" title=\"Compare machine sentiment against verified sentiment\">Crowd vs machine sentiment</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li><a data-method=\"clearFilter\" title=\"Reset the filter to the default value\">Clear filter</a></li>\n<li><a data-method=\"refresh\" title=\"Refresh the mentions list and statistics\">Refresh</a></li>\n<li><a data-method=\"undoMenuClicked\" title=\"Undo the last filter changes\">Undo changes</a></li>\n\n<li class=\"divider\"></li>\n\n<li><a data-method=\"toDashboard\" title=\"Create a dashboard based on this filter\">Create dashboard</a></li>\n<li><a data-method=\"toNotification\" title=\"Create a notification using this filter\">Create notification</a></li>\n\n<li class=\"divider\"></li>\n\n<li><a data-method=\"seeAuthors\" title=\"See how these authors are connected to one another in the Authors Panel\">View authors</a></li>\n\n<li class=\"divider\"></li>\n\n<li><a class=\"email-mentions\" data-method=\"emailMentions\">Email mentions</a></li>\n<li><a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"csvLink") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Export all the mentions in a CSV file for Excel\">Download CSV</a></li>\n\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"isMashAdmin",{"name":"ifallowed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":23,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"isOps",{"name":"ifallowed","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":26,"column":14}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"isOps",{"name":"ifallowed","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":31,"column":14}}})) != null ? stack1 : "");
},"useData":true});