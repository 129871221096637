/**
 * Displays past few months of sent to crowd etc.
 */
Beef.module("CrowdHistory").addInitializer(function(startupOptions) {

    var View = Backbone.Marionette.ItemView.extend({
        template: require("@/settings/profile/CrowdHistory.handlebars"),
        attributes: { class: 'crowd-history' },
        modelEvents: { "change": "render" }
    });
    this.View = View;

    /**
     * Create view showing history for the account.
     */
    this.createView = function(accountCode) {
        var m = new Backbone.Model({loading: true});
        var v = new View({model: m});
        $.getJSON("/api/crowd?code=" + accountCode, null, function(data) {
            m.set({loading: false, monthly: data.reverse()});
            v.render();
        });
        return  v;
    }

});