import beefRenderVenn from './VennD3';
import "./Venn.css"
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {getBrandsInFilter} from "@/dashboards/filter/FilterParser";
import {currentAccountCode} from "@/app/utils/Account";
import VennSettings from "@/dashboards/widgets/venn/VennSettings.vue";
import {grouseGet} from "@/data/Grouse";
import {errorHelper} from "@/dashboards/DashboardUtils";

/**
 * Venn/Euler diagram.
 */
Beef.module("Widget.Venn").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Priority Conversation Venn Diagram",
        description:    "Compare sizes and overlap of RPCS mentions",
        vueSettingsDialog: VennSettings,
        group:          "other",
        width:          4,
        height:         4
    };

    this.View = Beef.BoundItemView.extend({

        attributes: {
            'class': 'venn widget-font widget-height-inner'
        },

        modelEvents: {
            "change":   "maybeRefresh"
        },

        initialize: function() {
            if (this.model.attributes.orientation === undefined) this.model.attributes.orientation = 270
            this.refresh();
        },

        render: function(){
            // Need to clear text before getting width and height, otherwise cannot shrink this widget.
            this.$el.text('')
            this.renderChart()
        },

        renderChart: function() {
            let options = {
                onClick: this.onChartClick,
                orientation: this.model.get('orientation') || 90,
                noAnimation: Beef.Widget.isDisableAnimation()
            }
            let w = this.$el.width()
            let h = this.$el.height()
            if (this._data) {
                this._d3data = beefRenderVenn(this.$el[0], w, h, this._data, options)
            } else {
                this.$el.text('')
                this._d3data = null
            }
        },

        maybeRefresh: function() {
            var c = this.model.changed;
            var refresh = c['_effectiveFilter'] || c['selectedDataType'] !== undefined;
            if (refresh) {
                this.refresh();
            } else {
                if (c['width'] || c['height']) {
                    this.$el.text('');
                    this.$el.css({'max-width': "none"});
                    setTimeout(function() { this.render() }.bind(this), 400);
                }
                else this.render();
            }
        },

        refresh: async function() {
            let filter = this.model.get('_effectiveFilter')
            if (!filter) return

            let brandIds = getBrandsInFilter(filter).include;
            if (!brandIds.length) {
                this.model.generalData.set({'_loading': false, _completed: true})
                this.model.generalData.set({_message: "Please select at least one brand", _footnotes: [] })
                return
            }

            this.model.generalData.set({'_loading': true, _completed: false, _footnotes: ["Loading..."]})

            this._data = null
            this.render()
            let sets = []
            let data = this._data = { sets: sets, sampleSize: 0, complete: false }

            let countEndpoint = '/v4/accounts/' + currentAccountCode() + '/mentions/count'
            let useInteractionCount = this.model.get("selectedDataType") === "interactions";

            let callDone = (flags, row, index, filter) => {
                let count = useInteractionCount ? row.interactionCount : row.mentionCount;
                if (count) {
                    sets.push({sets: flags, size: count, index: index, filter: filter})
                    if (flags[0] == "Risk" && flags[1] == "Service") {
                        data.sampleSize -= count
                    } else if (flags.length == 1 && (flags[0] === "Service" || flags[0] === "Risk")) {
                        data.sampleSize += count
                    }
                    if (data.sampleSize > 0) sets.forEach(s => s.percentage = s.size * 100 / data.sampleSize)
                    sets.sort((a,b) => b.index - a.index)
                    this.renderChart()
                }
            }

            const fromGrouse = this.model.getSectionModel()
                ? this.model.getSectionModel().view.getJsonFromGrouse.bind(this.model.getSectionModel().view)
                : grouseGet;

            let index = 0
            let calls = RPCS.map((flag,i) => {
                let idx = index++
                let sf = filter + " AND Tag IS " + (i + 1)
                let params = useInteractionCount ? { filter: sf, select: "interactionCount" } : { filter: sf };
                return fromGrouse(countEndpoint, params)
                    .then(res => callDone([flag], res, idx, sf))
                    .catch(error => errorHelper(this.model, error));
            })

            RPCS.forEach((flag1, i1) => {
                RPCS.forEach((flag2, i2) => {
                    if (i2 <= i1) return
                    let idx = index++

                    let paramsFilter = filter + " AND Tag IS " + (i1 + 1) + " AND Tag IS " + (i2 + 1);
                    let params = useInteractionCount ? { filter: paramsFilter, select: "interactionCount" } : { filter: paramsFilter };

                    calls.push(fromGrouse(countEndpoint, params)
                        .then(res => callDone([flag1, flag2], res, idx))
                        .catch(error => errorHelper(this.model, error)));
                })
            })

            await Promise.all(calls)

            data.complete = true
            this.renderChart()

            this.model.generalData.set({
                '_loading': false,
                _completed: true,
                _footnotes: [`Calculated from ${data.sampleSize} ${useInteractionCount ? 'interactions' : 'mentions'} with RPCS tags`]
            })
        },

        onChartClick: function(d) {
            let title = (d.sets.join(" & ")) + " Mentions"
            showMentions(d.filter, title)
        }
    });
});

const RPCS = ["Risk", "Purchase", "Cancel", "Service"]