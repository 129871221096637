<template>
  <section class="mini-metric-class"
           @click.stop="$emit('expand', metricClass)"
           ref="target"
           @mouseover="showTooltip"
           @keydown="$emit('keydown', $event)"
           tabindex="0">
      <section class="mini-metric-class__image">
          <img :src="metric.image" class="metric-class__image" v-if="metric.image">
      </section>
      <header>
          <h1 :class="{error: !metric.title}">{{ metric.title || `«unrecognised class ${metricClass}»` }}</h1>
      </header>
  </section>
</template>

<script>

import { availableMetrics } from './availableMetrics';
import { showTooltipComponent } from "@/components/tooltip/TooltipUtilities";
import MetricClassTooltip from './MetricClassTooltip';

export default {
  name: "MiniMetricClass",

  components: { },

  props: {
    metricClass: String
  },

  computed: {
    metric() {
      return availableMetrics[this.metricClass];
    }
  },

  methods: {
    showTooltip() {
      showTooltipComponent(this.$refs.target, MetricClassTooltip,
          {klass: this.metric},
          { positions: ['right', 'left']})
    }
  }
}
</script>

<style scoped lang="sass">

.mini-metric-class
    display: grid
    grid-template-rows: 100%
    grid-template-columns: 100%
    cursor: pointer

    &:focus
        outline: 2px solid var(--be-filter-tag-hover-colour)



.mini-metric-class > *
    grid-row: 1
    grid-column: 1

.mini-metric-class__image
    height: 40px
    overflow: hidden
    border-radius: 3px

h1
    font-size: 1.2rem
    line-height: 1.2em
    margin: auto 0
    padding: 10px
    color: var(--be-colour-text)
    font-weight: bold
    box-sizing: border-box
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

// hidpi displays, like macbooks
@media (max-height: 900px), (max-width: 1440px)
    h1
        font-size: 1.05rem
        line-height: 1.1em


</style>