import beefRenderSearchJobChartD3 from './SearchJobChartD3'
import moment from "moment";
import {numTo4Chars} from "@/app/utils/Format";


/**
 * A module for showing a single search job as a chart.
 */
Beef.module("SearchJobChart").addInitializer(function() {

    this.View = Backbone.Marionette.Layout.extend({

        template: require("@/historical-search/chart/SearchJobChart.handlebars"),

        regions: {
            legendRegion: "#legend-region",
            titleRegion: ".title-region"
        },

        events: {
            "click .export-as-csv": "exportAsCSV",
            "click .create-support-ticket": "createSupportTicket"
        },

        templateHelpers: function() {
            var job = this.model.get("job");
            return {
                showChart: !!job,
                chartId: toChartId(job)
            };
        },

        initialize: function() {
            var job = this.model.get("job");
            var that = this;
            // have to defer since the svg and its container might not be in the DOM yet
            setTimeout(function() {
                var svg = document.getElementById(toChartId(job));
                if (job && svg) {
                    var data = that.model.get("data");
                    if (!data) {
                        that.loadData();
                    }
                }
            });
        },

        /**
         * Fetches a search job and adds its phrase volume to the chart.
         */
        loadData: function() {
            var job = this.model.get("job");
            beefRenderSearchJobChartD3.setLoading(true, toChartId(job));
            var that = this;
            if (job) {
                var code = this.model.get("accountCode");
                var success = function(updatedJob) {
                    if (updatedJob) {
                        var data = beefRenderSearchJobChartD3.formatData([updatedJob], code);
                        that.model.set({ data: data, job: updatedJob }, { silent: true });
                        that.renderChart();
                    } else {
                        Beef.HistoricalSearch.Message.onNetworkError();
                    }
                };
                Beef.SearchJobSync.get(code, job.id, success, this.error);
            } else {
                console.error("Cannot load data: no job set");
            }
        },

        onRender: function() {
            var legendView = new Beef.SearchJobChartLegend.View({ model: new Backbone.Model({
                toggleDisabled: true, // must keep this set to true for this module
                removeDisabled: true, // must keep this set to true for this module
                showVolume: true
            })});
            this.legendRegion.show(legendView);
            var titleView = new Beef.SearchJobChartTitle.View({ model: new Backbone.Model({
                job: this.model.get("job"),
                shiftDisabled: true // must keep this set to true for this module
            })});
            this.titleRegion.show(titleView);
        },

        renderChart: function() {
            var job = this.model.get("job");
            var svg = document.getElementById(toChartId(job));
            var data = this.model.get("data");
            if (svg && data) {
                var rect = svg.getBoundingClientRect();
                var options = Object.assign(getAxesOptions(job), {
                    container: this.el,
                    svg: svg,
                    width: rect.width,
                    height: rect.height,
                    data: data,
                    GET: Beef.Sync.broccoliGET,
                    pollOn: true,
                    accountCode: this.model.get("accountCode"),
                    showVolume: true,
                    showSampleBar: true,
                    afterAxesUpdate: this.model.get("afterAxesUpdate"),
                    tooltip: {
                        textOn: false,
                        beefTooltipOn: true
                    }
                });
                beefRenderSearchJobChartD3.setLoading(false, toChartId(job));
                var chart = beefRenderSearchJobChartD3.initialize(options);
                this.model.set("chart", chart, { silent: true });
                if (this.legendRegion && this.legendRegion.currentView) {
                    this.legendRegion.currentView.update(chart, data);
                }
                this.refreshTitle(this.model.get("job"), this.model.get("chart"));
            }
        },

        /**
         * Updates the chart title to reflect the given search job's state.
         */
        refreshTitle: function(job, chart) {
            if (this.titleRegion && this.titleRegion.currentView) {
                this.titleRegion.currentView.update(job, chart);
            } else {
                console.error("Cannot update chart title: no title region set");
            }
        },

        /**
         * Refreshes the sample bar to show new data.
         */
        refreshSampleBar: function(job) {
            var chart = this.model.get("chart");
            if (chart && chart.updateSampleBarWithJob) {
                chart.updateSampleBarWithJob(job, this.model.get("accountCode"));
            }
        },

        error: function(xhr, status, error) {
            console.error(status + " " + error);
            Beef.HistoricalSearch.Message.onNetworkError();
        },

        /**
         * Export the chart's data set as a CSV.
         */
        exportAsCSV: function() {
            var chart = this.model.get("chart");
            var data = this.model.get("data");
            if (chart && data && (data.length > 0)) {
                chart.exportAsCSV(data, "search " +  moment().format("YYYY-DD-MM HH:mm:ss") + ".csv");
            }
        },

        /**
         * Opens a support ticket form.
         */
        createSupportTicket: function() {
            Beef.SupportTicket.show();
        }
    });

    function toChartId(job) {
        return job ? ("chart-" + job.id) : "";
    }

    function getAxesOptions(job) {
        var start = job.searchRangeStartDate;
        var end = job.searchRangeEndDate;
        return  {
            y: {
                min: 0,
                max: 0,
                tickFormat: function(y) {
                    return ((y < 0.01) || (y > 10)) ? numTo4Chars(y) : y.toFixed(1);
                },
                label: "Count"
            },
            x: {
                // buckets returned from broccoli have their dates set to the start of the day.
                // the date filter on the job must be rounded to the start of the day
                min: moment(start).utc().startOf("day").toDate(),
                max: moment(end).utc().startOf("day").toDate()
            }
        };
    }
});
