<template>
    <div style="display: flex; flex-direction: column; height: 30px; box-sizing: border-box"
            :style="{paddingTop: (up && down ? 0 : 8) + 'px'}">
        <a v-if="up" class="btn btn-link" @click="move(-1)" title="Move up"><i class="icon-up" style="margin-top: -3px"/></a>
        <a v-if="down" class="btn btn-link" @click="move(+1)" title="Move down"><i class="icon-down" style="margin-top: -2px"/></a>
    </div>
</template>

<script>
/**
 * Up and down arrows for re-ordering stuff and so on. Modifies its input array when items are moved.
 */
export default {
    name: "UpDownButton",

    props: {
        index: Number,
        array: Array
    },

    computed: {
        up() { return this.index > 0 },
        down() { return this.index < this.array.length - 1 }
    },

    methods: {
        move(delta) {
            let a = this.array
            let t = a[this.index + delta]
            a[this.index + delta] = a[this.index]
            a[this.index] =  t
            a.splice(0, 0)   // dummy call so vue knows array has changed
        }
    }
}
</script>

<style scoped>
.btn {
    padding: 0;
    margin: 0;
    border: none;
    height: 15px;
    min-height: auto;
}

i {
    height: 15px;
    display: block;
}
</style>