/**
 * Edit mention filter string.
 */
import {filterValidator} from "@/dashboards/filter/FilterParser";
import {listV4Issues} from "@/dashboards/filter/BasicFilter";
import {showBrandPickerDialog} from "@/app/framework/dialogs/Dialog";

Beef.module("AdvancedFilter").addInitializer(function(startupOptions) {

    this.Model = Backbone.Model.extend({
        validation: {
            filter: filterValidator
        }
    });

    /**
     * Advanced filter. This can handle any filter string.
     */
    this.View = Beef.BoundItemView.extend({

        attributes: { class: "advanced-filter row-fluid" },

        template: require("@/dashboards/filter/AdvancedFilter.handlebars"),

        initialize: function() {
            this.model.bind('validated', function(isValid, model, errors) {
                var valid = this.isValid();
                $('.basic', this.$el).toggleClass('disabled', !valid);
            });
            this.model.set('v4Issues', listV4Issues(this.model.get('filter')));
        },

        events: {
            "click .basic": "changeToBasic",
            "keyup textarea": "keyup",
            "click .insert-brand-id": "insertBrandId",
            "click .insert-profile-id": "insertProfileId"
        },

        modelEvents: {
            "change:filter": "onFilterChange",
            "change:v4Issues": "v4IssuesToView"
        },

        onFirstRender: function() {
            this.v4IssuesToView();
        },

        changeToBasic: function() {
            if (this.isValid()) this.trigger("changeEditor", "basic");
        },

        keyup: function(ev) {
            // prevent enter on textarea from closing the dialog
            if (ev.keyCode == 13) ev.stopPropagation();
        },

        insertBrandId: function(ev) {
            showBrandPickerDialog({
                showChildren: true,
                onlyOne: true,
                onSelected: (items) => {
                    if (items?.length) {
                        let $t = this.$el[0].querySelector('textarea');
                        $t.value = $t.value.slice(0, $t.selectionStart ?? 0) + items[0].id + $t.value.slice($t.selectionEnd ?? 0, $t.value.length);
                    }
                }
            })
        },

        insertProfileId: function(ev) {
            let picker = new Beef.ProfilePicker.View({view: this, model: new Backbone.Model()});
            picker.on('change', (sel) => {
                let $t = this.$el[0].querySelector('textarea');
                $t.value = $t.value.slice(0, $t.selectionStart ?? 0) + sel + $t.value.slice($t.selectionEnd ?? 0, $t.value.length);
            });
            picker.setSelection("");
            this.showInPopup(picker, $(ev.target));
        },

        showInPopup: function(view, target) {
            this.popup = new Beef.Popup.View();
            this.popup.setTarget(target);
            this.popup.show(view);
        },

        onFilterChange: function() {
            this.model.set('v4Issues', listV4Issues(this.model.get('filter')));
        },

        v4IssuesToView: function() {
            this.$('.v4Issues').html(Handlebars.render(require("@/dashboards/filter/V4Issues.handlebars"), this.model.attributes));
        }
    });

});