import {
    getMentionsById,
    getPrivateConversationFilter,
    getPublicConversationFilter,
    getTreeMentions
} from "@/conversation/utils/GrouseConversationProxy";
import {createTree} from "@/conversation/utils/ConversationTree";
import {compareMentionsByReplyTo} from "@/conversation/utils/MentionConversationUtils";

class MentionConversationLoader {
    constructor(accountCode, selectedMention) {
        this.selectedMention = selectedMention;
        this.accountCode = accountCode;
        this.loading = false;
        this.error = null;

        const {conversationId, authorId, toId} = this.selectedMention;

        this.filter = this.selectedMention.isDirectMessage
            ? getPrivateConversationFilter(conversationId, authorId, toId)
            : getPublicConversationFilter(conversationId);

        this.treeMentions = [];
        this.tree = null;
        this.fetchedMentions = [];
    }

    async fetchData() {
        if (this.loading) return;
        this.loading = true;
        this.error = null;
        try {
            this.treeMentions = await getTreeMentions(this.accountCode, this.selectedMention) ?? [];
            this.tree = createTree(this.treeMentions, this.selectedMention?.id);
        } catch (err) {
            this.error = err;
        } finally {
            this.loading = false;
        }
        await this.fetchMentions();
    }

    async fetchMentions() {
        if (this.loading) return;
        this.loading = true;
        this.error = null;
        try {
            const newMentionIds = this.tree?.mentions?.filter(m => m.visible).map(m => m.id) ?? [];
            if (newMentionIds.length === 0) return;

            const mentions = await getMentionsById(this.accountCode, newMentionIds) ?? [];
            mentions.forEach(m => {
                const treeMention = this.tree.getMention(m.id);
                if (treeMention) treeMention.fetched = true;
            });
            this.fetchedMentions = [...this.fetchedMentions, ...mentions];
        } catch (err) {
            this.error = err;
        } finally {
            this.loading = false;
        }
    }

    visibleMentions() {
        return this.fetchedMentions?.filter(m => this.tree?.getMention(m.id)?.visible).sort(compareMentionsByReplyTo);
    }

    seeAuthors() {
        Beef.AuthorsSectionV4.navigateToAuthors(this.accountCode, this.filter);
    }

    seeMentions() {
        Beef.MentionList.navigateToMentions(this.accountCode, this.filter, "published");
    }
}

export {MentionConversationLoader};