import Vue from 'vue';
import {getPublicOperationalSentimentVolumeType, getPublicReputationalSentimentVolumeType} from './sentimentMetrics';
import VuexStore from "@/store/vuex/VuexStore";

export const volumeType = {
    type: "FantasticChart",
    xAxis: "published",
    yAxis: "mentionCount",
    width: 8,
    height: 4,
    geometry: "columns"
};

export const netSentimentType = {
    type: "FantasticChart",
    xAxis: "published",
    width: 8,
    height: 4,
    show: [
        {
            opacity: 50,
            yAxis: "posAndNegPercent",
            geometry: "columns"
        },
        {
            yAxis: "totalSentimentPercent",
            geometry: "lines"
        }
    ]
};

export const statsType = {
    type: "Stats",
    caption: "Summary table",
    notification: "Added a <strong>Summary Table</strong>"
};

const benchmarking = Vue.observable({
    title: "Overview",
    description: "Get an overall sense of your performance with some essential metrics.",
    expanded:
`These metrics will allow you to quickly get an overall picture of your online conversation using metrics such as 
Net Sentiment, volume and comparisons between your brands.`,
    image: require("../assets/overview.png"),
    metrics: [
        {
            id: "volume/overview",
            title: "Mention volume",
            description: "A graph showing the number of mentions received over time",
            image: require("../assets/benchmarking/volume-overview.png"),
            hero: true,
            widgetDesc: Object.assign({}, volumeType, {
                width: 10,
                "comment": "> ### Mentions received\n> {{total mentions}} mentions\n",
                "hidden-title": true
            }),
            options: [
                {
                    key: "engagement",
                    title: "With engagement",
                    tooltip: "Show your volume alongside engagement",
                    action: async function (metric) {
                        return Object.assign({},
                            metric, {
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "published",
                                    width: 10,
                                    height: 4,
                                    comment: "> ### Mentions received\n" +
                                        "> {{total mentions}} mentions\n" +
                                        "> ### Total engagement\n" +
                                        "> {{total engagement}} engagements\n",
                                    "hidden-title": true,
                                    show: [
                                        {
                                            opacity: 80,
                                            yAxis: "mentionPercent",
                                            geometry: "columns"
                                        },
                                        {
                                            yAxis: "totalEngagement",
                                            geometry: "lines"
                                        }
                                    ]
                                }
                            })
                    }
                },
                {
                    key: "authors",
                    title: "With total authors",
                    tooltip: "Show your volume alongside a count of authors posting these mentions",
                    action: async function (metric) {
                        return Object.assign({},
                            metric, {
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "published",
                                    width: 10,
                                    height: 4,
                                    comment: "> ### Mentions received\n" +
                                        "> {{total mentions}} mentions\n" +
                                        "> ### Unique authors\n" +
                                        "> {{total authors}} authors\n",
                                    "hidden-title": true,
                                    show: [
                                        {
                                            opacity: 80,
                                            yAxis: "mentionPercent",
                                            geometry: "columns"
                                        },
                                        {
                                            yAxis: "authorIdCount",
                                            geometry: "lines"
                                        }
                                    ]
                                }
                            })
                    }
                },
                {
                    key: "volume",
                    title: "Compare by brand",
                    tooltip: "Compare your volumes by brand",
                    action: async function (metric) {
                        return Object.assign({},
                            metric, {
                                widgetDesc: {
                                    type: "FantasticChart",
                                    xAxis: "brand",
                                    yAxis: "mentionPercent",
                                    width: 8,
                                    height: 4
                                }
                            })
                    }
                },
                {
                    key: "text",
                    title: "As text",
                    tooltip: "See a total count of all your received mentions",
                    action() {
                        return {
                            id: "volume/text",
                            widgetDesc: {
                                type: "Text",
                                text: `> # Mentions received\n> {{total mentions}} mentions`,
                                "hidden-title": true,
                                notification: "Added <strong>Mention Volume</strong> as a text metric"
                            }
                        }
                    }
                }
            ]


        },
        {
            id: "volume/columns",
            title: "Mention volume",
            description: "Shows mentions received over time without a commentary area",
            image: require("../assets/benchmarking/volume-columns.png"),
            additional:true,
            duplicate: true, // Duplicate of volume/overview, more or less. We don't want this appearing in search results
            widgetDesc: Object.assign({}, volumeType, {
                width: 8
            })

        },
        {
            id: "stats/default",
            title: "Summary table",
            description: `This table provides a summary of some key metrics such as overall mentions, unique authors, 
            engagement, reshares, replies, and sentiment. `,
            image: require("../assets/benchmarking/statistics.png"),
            keywords: ['stats'],
            widgetDesc: statsType
        },
        {
            id: "benchmarking/comptable",
            title: "Comparison table",
            description: "Compare volume and other stats for brands, topics etc. with change compare to previous period",
            image: require("../assets/benchmarking/comparison-table.png"),
            widgetDesc: {
                type: "CompTable",
                caption: "Comparison Table"
            }
        },
        {
            id: "sentiment/raw/net",
            title: "Net Sentiment",
            description: "See how the sentiment of online conversation combines towards your brand",
            more: "Net Sentiment is  a customer satisfaction metric that is a critical component in the quest for an authentic and complete voice-of-customer measurement. It is calculated by subtracting all negative mentions from positive mentions. This particular metric defaults to showing sentiment for both public and private mentions (suchs as direct messages), unlike some of our other sentiment metrics.",
            image: require("../assets/benchmarking/net-sentiment.png"),
            widgetDesc: netSentimentType,
            options: [
                {
                    key: "time",
                    title: "View over time",
                    tooltip: "View how your sentiment changes over time",
                    action: async function(metric) {
                        return Object.assign({},
                            metric, {
                                widgetDesc: {
                                    caption: "Net Sentiment over time",
                                    "manual-caption": true,
                                    type: "FantasticChart",
                                    xAxis: "published",
                                    yAxis: "mentionPercent",
                                    compare: "sentiment",
                                    width: 8,
                                    height: 4
                                }
                            })
                    }
                },
                {
                    key: "overall",
                    title: "As Public Net Sentiment",
                    tooltip: "Add a Net Sentiment chart showing overall public sentiment",
                    action: async function(metric) {
                        const desc = Object.assign({},
                            netSentimentType,
                            {filter: "visibility is public", caption: "Overall Net Sentiment for public mentions", 'manual-caption': true}
                        );
                        return Object.assign({}, metric, {widgetDesc: desc});
                    }
                },
                {
                    key: "operational",
                    title: "As operational sentiment",
                    tooltip: "Add a Net Sentiment chart showing operational sentiment",
                    action: async function(metric) {
                        const operational = await getPublicOperationalSentimentVolumeType();
                        const desc = Object.assign({},
                            netSentimentType,
                            {filter: operational.filter, caption: "Operational Net Sentiment", 'manual-caption': true}
                        );
                        return Object.assign({}, metric, {widgetDesc: desc});
                    }
                },
                {
                    key: "reputational",
                    title: "As reputational sentiment",
                    tooltip: "Add a Net Sentiment chart showing reputational sentiment",
                    action: async function(metric) {
                        const reputational = await getPublicReputationalSentimentVolumeType();
                        const desc = Object.assign({},
                            netSentimentType,
                            {filter: reputational.filter, caption: "Reputational Net Sentiment", 'manual-caption': true}
                        );
                        return Object.assign({}, metric, {widgetDesc: desc});
                    }
                }
            ]
        }
    ]
});

export default benchmarking;

export async function init() {
    if (VuexStore.state.user.admin) {
        benchmarking.metrics.find(m => m.id === "benchmarking/comptable").hide = false
    }
}