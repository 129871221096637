<template>
    <div class="account-list">
        <header class="head">
            <h1 style="display:inline-block">
                Accounts
            </h1>
            <search-input class="account-list__search"
                          autofocus
                          v-if="user.admin"
                          v-model="searchText"
                          placeholder="Type an account code or name to search"
            />
            <transition name="fade">
                <span class="account-list__loading" v-if="loading">
                    <spinner-component :size="20"/> Loading
                </span>
            </transition>
            <div style="flex-grow:1"></div>
            <div v-if="user.admin" class="control-group account-list__controls">
                <transition name="fade">
                    <div class="header-metric" v-if="!loading">
                        Active:
                        <span class="number">{{activeAccounts}}</span>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="header-metric suspended" v-if="!loading">
                        Suspended:
                        <span class="number">{{suspendedAccounts}}</span>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="header-metric inactive" v-if="showInactive && !loading">
                        Inactive:
                        <span class="number">{{inactiveAccounts}}</span>
                    </div>
                </transition>
                <label>
                    <input type="checkbox" v-model="showInactive" tabindex="-1"> Show inactive
                </label>
                <label>
                    <input type="checkbox" v-model="showMine" tabindex="-1"> Show my accounts
                </label>
                <a href="#"
                   ref="csvLink"
                   class="btn btn-link"
                   :class="{disabled: loading}"
                   tabindex="-1"
                   @click="saveCsv()">Export as CSV</a>
            </div>
        </header>

        <loading-message v-if="loading && !accounts.length" message="Fetching your accounts"/>
        <table v-else-if="visibleAccounts.length"
               class="table table-condensed table-bordered table-hover">
            <thead v-if="user.admin">
                <tr>
                    <th @click="setSort('code')" class="sortable" tooltip="Click to sort by account code">
                        Code
                        <span v-if="sortType === 'code'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th @click="setSort('name')" class="sortable" tooltip="Click to sort by the account's name">
                        Name
                        <span v-if="sortType === 'name'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable" @click="setSort('region')" tooltip="Click to sort by region">
                        Region
                        <span v-if="sortType === 'region'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable" @click="setSort('vertical')" tooltip="Click to sort by vertical">
                        Vertical
                        <span v-if="sortType === 'vertical'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable" @click="setSort('type')" tooltip="Click to sort by account type">
                        Type
                        <span v-if="sortType === 'type'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable" @click="setSort('clientCode')" tooltip="Click to sort by client code">
                        Client
                        <span v-if="sortType === 'clientCode'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable" @click="setSort('status')" tooltip="Click to sort by status">
                        Status
                        <span v-if="sortType === 'status'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable static-beef-tooltip--left" @click="setSort('manager')"
                        tooltip="Click to sort by account manager">
                        Manager
                        <span v-if="sortType === 'manager'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable static-beef-tooltip--left" @click="setSort('engage')"
                        tooltip="Click to sort by whether accounts are using Engage or not">
                        Engage
                        <span v-if="sortType === 'engage'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable static-beef-tooltip--left" @click="setSort('reporting')"
                        tooltip="Click to sort by whether accounts have reporting or not">
                        Reporting
                        <span v-if="sortType === 'reporting'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>
                    <th class="no-small-screen sortable static-beef-tooltip--left" @click="setSort('facebook')"
                        tooltip="Click to sort by whether accounts have been authorised to receive Facebook data">
                        <i class="symbol-facebook-rect"></i>
                        <span v-if="sortType === 'facebook'" :class="{caret: true, 'caret-toggle': reverse}"></span>
                    </th>

                </tr>
            </thead>
            <tbody>
            <tr is="AccountListRow"
                tabindex="0"
                v-for="account in visibleAccounts"
                :account="account"
                :user="user"
                :key="account.code">
            </tr>
            </tbody>
        </table>
        <section v-else-if="accounts.length" class="account-list__message">
            No accounts match your search terms
        </section>
        <section v-else class="account-list__message">
            You do not have access to any accounts
        </section>

        <section v-if="error">
            <button class="btn" tooltip="Reload account information" @click="reload">
                Reload
            </button>
        </section>
    </div>
</template>

<script>
import AccountListRow from './AccountListRow';
import SearchInput from '../../../components/inputs/SearchInput';
import LoadingMessage from "@/components/LoadingMessage";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapState} from "vuex";
import SpinnerComponent from "@/components/SpinnerComponent";

function getCleanString(inputString) {
        if (typeof inputString !== 'string') {
            console.warn('Input needs to be a string', inputString)
            return inputString
        }
        return inputString.trim().toLowerCase()
    }

    function sortType(lhs, rhs) {
        const lhsType = lhs.accountType || "";
        const rhsType = rhs.accountType || "";

        if (lhsType !== rhsType) {
            if (lhsType === "PAID") return -1;
            if (rhsType === "PAID") return 1;

            if (lhsType === "PITCH") return -1;
            if (rhsType === "PITCH") return 1;

            if (lhsType === "BENCHMARKING") return -1;
            if (rhsType === "BENCHMARKING") return 1;

            return lhsType.localeCompare(rhsType);
        }

        return sortAccountCode(lhs, rhs);
    }

    function sortVertical(lhs, rhs) {
        return (lhs.vertical || "").localeCompare(rhs.vertical || "");
    }


    function sortRegion(lhs, rhs) {
        return (lhs.region || "").localeCompare(rhs.region || "");
    }

    function sortName(lhs, rhs) {
        return (lhs.name || "").localeCompare(rhs.name || "");
    }

    function sortManager(lhs, rhs) {
        const lhsManager = lhs.clientService ? `${lhs.clientService.firstName}${lhs.clientService.lastName}` : "00";
        const rhsManager = rhs.clientService ? `${rhs.clientService.firstName}${rhs.clientService.lastName}` : "00";
        return (lhsManager).localeCompare(rhsManager);
    }

    function sortAccountCode(lhs, rhs) {
        return (lhs.code || "").localeCompare(rhs.code || "");
    }

    function sortClientCode(lhs, rhs) {
        return (lhs.clientCode || "").localeCompare(rhs.clientCode || "");
    }

    function sortStatus(lhs, rhs) {
        if (lhs.status !== rhs.status) {
            let lhsStatus = lhs.status || "";
            let rhsStatus = rhs.status || "";

            if (lhsStatus === "SUSPENDED") lhsStatus = "-1";
            if (rhsStatus === "SUSPENDED") rhsStatus = "-1";

            return lhsStatus.localeCompare(rhsStatus);
        }

        return 0;
    }

    export default {
        name: "AccountList",
        store: VuexStore,
        components: {SpinnerComponent, LoadingMessage, AccountListRow, SearchInput },

        data: function() {
            return {
                accounts: [],
                showInactive: false,
                showMine: false,
                loading: false,
                sortType: "type",
                sortFun: sortType,
                reverse: false,
                error: false,
                searchText: ''
            }
        },

        computed: {
            ...mapState(['user']),
            ...mapGetters(['userAccounts']),

            cleanSearchText() {
                return getCleanString(this.searchText);
            },
            visibleAccounts() {
                let data = this
                    .accounts
                    .filter(ac => {
                        if (this.showMine && ac.clientService?.id !== this.user.id) return false;
                        const cleanString = getCleanString(ac.code + ' ' + ac.name)
                        const searchMatches = (
                               !this.cleanSearchText.length
                            || cleanString.indexOf(this.cleanSearchText) !== -1
                        )
                        return searchMatches && ( this.showInactive || !ac.inactive  )
                    });

                if (!this.user.admin) return data;
                data = data.sort(this.sortFun);
                if (this.reverse) data = data.reverse();
                return data;
            },
            activeAccounts() {
                return this.accounts.filter( ac => !ac.inactive ).length;
            },
            suspendedAccounts() {
                return this.accounts.filter( ac => (this.showInactive || !ac.inactive) && ac.status === 'SUSPENDED').length;
            },
            inactiveAccounts() {
                return this.accounts.filter( ac => ac.inactive ).length;
            }
        },

        methods: {
            ...mapActions(['refreshUser']),

            setSort(type) {
                this.reverse = this.sortType === type && !this.reverse;
                this.sortType = type;
                switch(type) {
                    case "vertical":
                        this.sortFun = sortVertical;
                        break;
                    case "type":
                        this.sortFun = sortType;
                        break;
                    case "region":
                        this.sortFun = sortRegion;
                        break;
                    case "name":
                        this.sortFun = sortName;
                        break;
                    case "code":
                        this.sortFun = sortAccountCode;
                        break;
                    case "clientCode":
                        this.sortFun = sortClientCode;
                        break;
                    case "status":
                        this.sortFun = sortStatus;
                        break;
                    case "manager":
                        this.sortFun = sortManager;
                        break;
                    case "facebook":
                        this.sortFun = (lhs, rhs) => {
                            if (lhs.hasFacebookDataAuthorisation && rhs.hasFacebookDataAuthorisation) return sortType(lhs, rhs);
                            if (!lhs.hasFacebookDataAuthorisation && !rhs.hasFacebookDataAuthorisation) return sortType(lhs, rhs);
                            if (lhs.hasFacebookDataAuthorisation) return -1;
                            if (rhs.hasFacebookDataAuthorisation) return 1;
                        };
                        break;
                    case "engage":
                        this.sortFun = (lhs, rhs) => {
                            if (lhs.hasEngage && rhs.hasEngage) return sortType(lhs, rhs);
                            if (!lhs.hasEngage && !rhs.hasEngage) return sortType(lhs, rhs);
                            if (lhs.hasEngage) return -1;
                            if (rhs.hasEngage) return 1;
                        };
                        break;
                    case "reporting":
                        this.sortFun = (lhs, rhs) => {
                            if (lhs.hasReporting && rhs.hasReporting) return sortType(lhs, rhs);
                            if (!lhs.hasReporting && !rhs.hasReporting) return sortType(lhs, rhs);
                            if (lhs.hasReporting) return -1;
                            if (rhs.hasReporting) return 1;
                        };
                        break;
                }
            },
            reload() {
                window.location.reload();
            },

            saveCsv() {
                if (this.loading) return;

                const link = this.$refs.csvLink;
                const accounts = this.accounts || [];

                const quote = q => '"' + q + '"';

                // Add the UTF-8 BOM to the header.
                // The UTF-8 BOM is so that Excel + Windows correctly interprets and opens
                // this file.
                let csv = '\ufeff"code", "name", "client", "clientName", "created", "active", "type", "status", "region", "vertical", "manager", "collectingMentions", "hasReporting", "hasEngage", "hasFacebookDataAuthorisation", "hasAVE\n';
                for (const account of accounts) {
                    const data = [
                        account.code,
                        account.name,
                        account.clientCode,
                        account.clientName,
                        account.dateCreated,
                        !account.inactive,
                        account.accountType,
                        account.status,
                        account.region,
                        account.vertical,
                        account.clientService ? account.clientService.email : 'NA',
                        !!account.findNewMentions,
                        !!account.hasReporting,
                        !!account.hasEngage,
                        !!account.hasFacebookDataAuthorisation,
                        !!account.showAVE
                    ];
                    csv += data.map(quote).join(',') + "\n"
                }

                link.href = window.URL.createObjectURL(new Blob([csv], {type: "text/csv"}));
                link.download = "accounts.csv";
            }
        },

        async created() {
            try {
                const KEY = "dataeq:accounts-list:" + this.user.id;

                this.loading = true;
                await this.refreshUser(true);
                this.loading = false;

                this.accounts = Array.from(this.userAccounts);
                try {
                    sessionStorage.setItem(KEY, JSON.stringify(this.userAccounts));
                } catch (e) {
                    console.warn("Error saving account list", e);
                }
            } catch (error) {
                this.loading = false;
                this.error = true;
                console.error(error);
                await showErrorDialog("We're having a problem talking to our servers. Please check your internet connection and try again.");
            }
        },

        async mounted() {
            try {
                if (this.user.id) {
                    const KEY = "dataeq:accounts-list:" + this.user.id;
                    try {
                        let accounts = sessionStorage.getItem(KEY);
                        if (accounts) {
                            this.accounts = JSON.parse(accounts);
                        }
                    } catch(error) {
                        console.warn("Unable to load account list from session storage");
                    }
                }
            } catch (error) {
                this.error = true;
                console.error(error);
                await showErrorDialog("We're having a problem talking to our servers. Please check your internet connection and try again.");
            }
        }
    }
</script>

<style scoped lang="sass">

.account-list__search
    width: 35vw

.head
    display: flex
    align-items: baseline
    padding-bottom: 15px
    > *
        margin-right: 20px
    > *:last-child
        margin-right: 0
h1
    margin: 0
    line-height: 42px
    display: inline-block
    color: var(--heading-colour-text-dark)

input
    vertical-align: initial

.header-metric
    display: inline-block
    margin-right: 20px
    &.suspended
        color: var(--ac-colour-suspended)
    &.inactive
        color: var(--ac-colour-inactive)
.account-list
    margin-top: 20px

    &__controls
        color: var(--be-colour-muted-text-dark)
        > label
            display: inline-block
        > *
            margin-right: 40px
        > *:last-child
            margin-right: 0

    &__loading
        color: var(--be-colour-mid-grey)
        &.fade-leave-active // remove from document flow
            transition-duration: 0s
            display: none
        & .css-spinner
            vertical-align: bottom
.btn-link:not(:hover)
    color: var(--be-colour-muted-text-dark)

.account-list__message
    text-align: center
    font-style: italic
    margin-top: 5vh

@media (max-width: 1300px)
    .account-list__search
        width: 100%
    .head
        flex-direction: column


@media (max-width: 800px)
    .no-small-screen
        display: none

.sortable
    cursor: pointer

.number
    font-family: var(--number-font-family)

.caret
    vertical-align: super

table
    thead
        position: sticky
        top: 40px

.fade-enter-active,
.fade-leave-active
    transition: opacity var(--transition-duration)

.fade-enter,
.fade-leave-to
    opacity: 0
</style>