import {mash} from "@/store/Services";

export const FeedMutations = {
    setFeeds: (state, value) => {
        state.feeds = value;
    },

    editFeed: (state, crudFeed) => {
        state.feeds = state.feeds.map(feed => {
            if (feed.id === crudFeed.id) {
                feed.name = crudFeed.name;
                feed.location = crudFeed.location;
                feed.link = crudFeed.link;
                feed.includeContentHashInURI = crudFeed.includeContentHashInURI;
                feed.includeFeedNameInContent = crudFeed.includeFeedNameInContent;
                feed.sendMentionsToAllAccounts = crudFeed.sendMentionsToAllAccounts;
            }
            return feed;
        })
        sortFeeds(state);
    },

    deleteFeed: (state, crudFeed) => {
        state.feeds = state.feeds.filter(feed => feed.id !== crudFeed.id);
    },

    createFeed: (state, crudFeed) => {
        state.feeds.unshift(crudFeed);
        sortFeeds(state);
    },
}

export const FeedActions = {
    async refreshFeeds({state, commit}) {
        try {
            const res = await mash.get(`/rest/accounts/${state.account.code}/feeds?includeStatus=true`);

            await commit('setFeeds', res.data);
        } catch (e) {
            console.warn('An error occurred while fetching feeds: ', e);
            throw e;
        }
    },

    async editFeed({state, commit}, feed) {
        try {
            await mash.put(`/rest/accounts/${state.account.code}/feeds/${feed.id}`, feed);
            commit('editFeed', feed)
        } catch (error){
            console.warn(`An error occurred while saving an edit to feed name: ${feed.name}, error: `, error);
            throw error;
        }
    },

    async createFeed({state, commit}, feed) {
        try {
            const res = await mash.post(`/rest/accounts/${state.account.code}/feeds`, feed);
            feed.id = res.data.id
            commit('createFeed', feed)
        } catch (error) {
            console.warn(`An error occurred while saving new feed: ${feed.name}, error: `, error);
            throw error;
        }
    },

    async deleteFeed({state, commit}, feed) {
        try {
            await mash.delete(`/rest/accounts/${state.account.code}/feeds/${feed.id}`);
            commit('deleteFeed', feed)
        } catch (error) {
            console.warn(`An error occurred while deleting feed: ${feed.name}, error: `, error);
            throw error;
        }
    }
}

const sortFeeds = (state) => {
    state.feeds.sort((lhs, rhs) => {
        return lhs.name.localeCompare(rhs.name);
    });
}