var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "deleted";
},"3":function(container,depth0,helpers,partials,data) {
    return "toggle-branch";
},"5":function(container,depth0,helpers,partials,data) {
    return "leaf";
},"7":function(container,depth0,helpers,partials,data) {
    return "<i class=\"minus icon-down-dir\"></i><i class=\"plus icon-right-dir\"></i>";
},"9":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-dot\"></i>";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(__default(require("../../helpers/nameFormat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shortName") : depth0),40,{"name":"nameFormat","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":43}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(__default(require("../../helpers/nameFormat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),40,{"name":"nameFormat","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":38}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"sub-phrase-matching\">[partial phrase matching]</span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"general-debug-message\">("
    + ((stack1 = __default(require("../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_level") : depth0),"==",0,{"name":"ifcond","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":91}}})) != null ? stack1 : "")
    + " id: "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + ")</span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "🐛";
},"20":function(container,depth0,helpers,partials,data) {
    return "&nbsp;<span class=\"icon-filter\" title=\"This brand and/or its child brands and phrases have mention filters configured.\"></span>";
},"22":function(container,depth0,helpers,partials,data) {
    return "&nbsp;<span class=\"icon-comment-empty\" title=\"This brand and/or its child brands and phrases have feed or social network filters configured.\"></span>";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_subBrandsAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":106}}})) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "<span class=\"add-brand\"><span class=\"icon-plus\"></span></span>";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"edit-brand\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":31},"end":{"line":30,"column":69}}})) != null ? stack1 : "")
    + ">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":60}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"unless","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":69}}})) != null ? stack1 : "")
    + "\n        </td>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "title=\"Undelete\"";
},"30":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-reply\"></i>";
},"32":function(container,depth0,helpers,partials,data) {
    return "<i class=\"icon-pencil\"></i>";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./Phrase.handlebars"),depth0,{"name":"./Phrase","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":57,"column":11}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr class=\"add-phrase\">\n            <td colspan=\"1\" >\n                <a class=\"add-phrase\"><i class=\"icon-plus\"></i> Add a search phrase</a>\n"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"generatePhrases",{"name":"ifallowed","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":51,"column":30}}})) != null ? stack1 : "")
    + "            </td>\n            <td></td>\n            <td></td>\n            <td></td>\n        </tr>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"generate-phrases\"><i class=\"icon-plus\"></i> Generate search phrases</a>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./BrandTree.handlebars"),depth0,{"name":"./BrandTree","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tbody class=\"brand level"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_level") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_classes") : depth0), depth0))
    + " phrases-collapsed "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":96}}})) != null ? stack1 : "")
    + "\"\n       data-brand=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n<tr>\n    <td class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_subBrandsAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":60}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"_subBrandsAllowed") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":60},"end":{"line":4,"column":104}}})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_subBrandsAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":110}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"_subBrandsAllowed") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":72}}})) != null ? stack1 : "")
    + "\n        <span class=\"name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"shortName") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"subPhraseMatching") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/ifdebug.js")).call(alias3,{"name":"ifdebug","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":18,"column":24}}})) != null ? stack1 : "")
    + "        </span>\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_treeDoesMentionFilter") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":172}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_treeDoesFeedFilter") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":191}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td class=\"toggle-phrases\" title=\"There are "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"phrases") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + " search phrase(s) associated with this brand or sub-brand. Click to show the phrases\">\n        <span class=\"phrase-length\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"phrases") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</span> <i class=\"minus icon-up-open\"></i><i class=\"plus icon-down-open\"></i>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_canEdit") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./BrandPhraseVolume.handlebars"),depth0,{"name":"./BrandPhraseVolume","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</tr>\n</tbody>\n\n<tbody class=\"phrases level"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_level") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_classes") : depth0), depth0))
    + " phrases"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + " branch-collapsed\" data-brand=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"phrases") : depth0),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":0},"end":{"line":42,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"unless","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":0},"end":{"line":58,"column":11}}})) != null ? stack1 : "")
    + "</tbody>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"children") : depth0),{"name":"each","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":0},"end":{"line":63,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});