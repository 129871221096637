<template>
    <section v-if="isAdmin" class="deq-reset">

        <h4>Machine Learning Setup <staff-only-tag/></h4>

        <p>
            Staff can update where and how the brands in this account use machine learning and AI models.
        </p>

        <loading-message v-if="loading">
            Fetching account information...
        </loading-message>


        <div class="machine-grid">
            <header class="machine-grid__row">
                <div>Brand</div>
                <div class="row__percent">Human Crowd %</div>
                <div>Sentiment Policy</div>
                <div>Sentiment</div>
                <div>Segments</div>
                <div>Topics</div>
            </header>

            <div v-for="brand in rootBrands"
                 :key="brand.id"
                 @click="onBrandClicked(brand)"
                 class="machine-grid__row">
                <div>{{formatBrandName(brand)}}</div>
                <div class="row__percent"><DeqPercentage :percent="brand.humanCrowdPercentage" v-if="Number.isFinite(brand.humanCrowdPercentage)"/></div>
                <div>{{ formatPolicy(brand.sentimentPolicy) }}</div>
                <div>{{ formatPolicy(brand.sentiment) }}</div>
                <div>{{ formatPolicy(brand.segments) }}</div>
                <div>{{ formatPolicy(brand.topics) }}</div>
            </div>

        </div>

    </section>
</template>


<script>

import LoadingMessage from "@/components/LoadingMessage.vue";
import StaffOnlyTag from "@/components/tags/StaffOnlyTag.vue";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapState} from "vuex";
import {formatBrandName, formatPolicy} from "@/app/utils/Format";
import DeqPercentage from "@/components/formatters/DeqPercentage.vue";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import MachineSetupDialog from "@/setup/crowd/MachineSetupDialog.vue";

export default {
    store: VuexStore,
    components: {DeqPercentage, StaffOnlyTag, LoadingMessage},

    data() {
        return {
            loading: false
        }
    },


    computed: {
        ...mapState(['user', 'account', 'rootBrands']),

        isAdmin() {
            return this.user.admin
        }
    },

    async created() {
        await this.refreshBrands();


        // Ensure that we always have all brands loaded.
        this.refreshBrands(true)
            .catch(e => console.error(e));
    },

    methods: {
        formatBrandName,
        formatPolicy,

        ...mapActions(['refreshBrands']),




        onBrandClicked(brand) {
            showDialogComponent(MachineSetupDialog, { brand })
        }
    }


}

</script>


<style scoped lang="sass">

.machine-grid
    margin-right: auto

    display: grid
    grid-template-columns: minmax(20ch, max-content)  fit-content(20ch) repeat(5, max-content)
    grid-auto-rows: auto

    header
        background: var(--table-header-background-colour)
        color: var(--table-header-colour)
        border-top: var(--table-border)
        cursor: default


.machine-grid__row
    background-color: var(--table-background-colour)
    display: grid
    grid-column: 1 / -1
    grid-template-columns: subgrid
    grid-template-rows: subgrid
    line-height: var(--table-line-height)

    border-bottom: var(--table-border)

    & > div
        padding: var(--table-cell-padding)
        text-align: center

        border-left: var(--table-border)
        &:last-child
            border-right: var(--table-border)



    & > div:first-child
        text-align: left

    .row__percent
        text-align: right


    &:not(header):hover
        cursor: pointer
        background-color: var(--table-hover-background)
        color: var(--table-hover-colour)


</style>