var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Edit the";
},"3":function(container,depth0,helpers,partials,data) {
    return "Add a";
},"5":function(container,depth0,helpers,partials,data) {
    return "brand";
},"7":function(container,depth0,helpers,partials,data) {
    return "sub-brand";
},"9":function(container,depth0,helpers,partials,data) {
    return "I'd like to monitor the online sentiment towards";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "I am interested in conversation about "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"_parent") : depth0), depth0))
    + "'s";
},"13":function(container,depth0,helpers,partials,data) {
    return "More specifically, I want to listen to conversation about";
},"15":function(container,depth0,helpers,partials,data) {
    return "Name";
},"17":function(container,depth0,helpers,partials,data) {
    return "which I believe impacts its reputation";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "as one of "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_grandParent") : depth0), depth0))
    + "'s "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_parent") : depth0), depth0))
    + " and how it impacts the sentiment towards "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_grandParent") : depth0), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    return "Consider including your brands, competitor brands or your full company name";
},"23":function(container,depth0,helpers,partials,data) {
    return "Consider including conversation categories like employees, branches, products or sponsorships";
},"25":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"span5\">\n                        <div class=\"control-group control-group-spacer\">\n                            <label>Category</label>\n                            "
    + container.escapeExpression(__default(require("../../helpers/dynamic-picker.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"category",{"name":"dynamic-picker","hash":{"class":"span12"},"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":72}}}))
    + "\n                        </div>\n                    </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                    <div class=\"control-group control-group-spacer\">\n                        <label>Online profiles used for customer support</label>\n                        <div class=\"controls\">\n                            <span class=\"supportProfileIds tag-input uneditable-input span12\">\n                                <span name=\"supportProfileIds\"></span>\n                                <input class=\"tag popup-menu\" type=\"text\"/>\n                            </span>\n                        </div>\n                    </div>\n\n                    <div class=\"control-group control-group-spacer\">\n                        <label>Other online profiles not used for customer support</label>\n                        <div class=\"controls\">\n                            <span class=\"otherProfileIds tag-input uneditable-input span12\">\n                                <span name=\"otherProfileIds\"></span>\n                                <input class=\"tag popup-menu\" type=\"text\"/>\n                            </span>\n                        </div>\n                    </div>\n\n"
    + ((stack1 = __default(require("../../helpers/ifMashAdmin.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ifMashAdmin","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":20},"end":{"line":95,"column":36}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"control-group control-group-spacer\">\n                            <label>Only read these platforms (leave blank for all)</label>\n                            <div class=\"controls\">\n                                <span class=\"socialNetworks tag-input uneditable-input span12\">\n                                    <span name=\"socialNetworks\"></span>\n                                    <input class=\"tag popup-menu\" type=\"text\"/>\n                                </span>\n                            </div>\n                        </div>\n                        <div class=\"control-group control-group-spacer\">\n                            <label>Only read these feeds (leave blank for all)</label>\n                            <div class=\"controls\">\n                                <span class=\"feeds tag-input uneditable-input span12\">\n                                    <span name=\"feeds\"></span>\n                                    <input class=\"tag popup-menu\" type=\"text\"/>\n                                </span>\n                            </div>\n                        </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"isOps",{"name":"ifallowed","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":16},"end":{"line":115,"column":30}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"control-group-spacer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_parentSubPhraseMatching") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":101,"column":20},"end":{"line":107,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"control-group-colour\">\n                        Use this option to further classify mentions (e.g. to identify branches by name) without having to\n                        include the name of the brand in each search phrase. Phrases only match mentions that have already\n                        matched phrases for other brands. When enabled this option applies to this brand and all brands\n                        below it in the tree.\n                    </div>\n                </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "                        <label>Partial phrase matching has been enabled by a parent brand</label>\n";
},"34":function(container,depth0,helpers,partials,data) {
    return "                        <label class=\"checkbox\">\n                            <input type=\"checkbox\" name=\"subPhraseMatching\"> Partial phrase matching\n                        </label>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"root-brand-filter-region control-group-spacer\"></div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"pull-left\">\n                <a class=\"delete btn\" title=\"Delete this brand\">Delete</a>\n                <a class=\"move btn\"\n                        title=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_parent") : depth0),{"name":"unless","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":130,"column":31},"end":{"line":130,"column":132}}})) != null ? stack1 : "")
    + "\"\n                   "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_parent") : depth0),{"name":"unless","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":19},"end":{"line":131,"column":57}}})) != null ? stack1 : "")
    + ">\n                    Move</a>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"_parent") : depth0),{"name":"unless","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":16},"end":{"line":135,"column":27}}})) != null ? stack1 : "")
    + "            </span>\n";
},"39":function(container,depth0,helpers,partials,data) {
    return "You cannot move root brands";
},"41":function(container,depth0,helpers,partials,data) {
    return "Move this brand to a different brand";
},"43":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"45":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"archive btn\" title=\"Archive this brand (stop collecting mentions)\">Archive</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":33}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":63}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level0") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":51}}})) != null ? stack1 : "")
    + "\n    </h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n\n    <div class=\"row-fluid\">\n        <div class=\"span12\">\n            <div class=\" main-form\">\n                <div class=\"control-group\">\n                    <label>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level0") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":93}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level1") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":96}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level2") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":102}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"levelOther") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":53}}})) != null ? stack1 : "")
    + "\n                    </label>\n                    <div class=\"controls\">\n                        <input dir=\"auto\" type=\"text\" name=\"name\" class=\"span12\">\n                    </div>\n                    <div class=\"control-group-colour\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level1") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":24,"column":83}}})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level2") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":143}}})) != null ? stack1 : "")
    + "\n                    </div>\n                </div>\n\n                <div class=\"control-group-spacer\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level0") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":116}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level1") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":134}}})) != null ? stack1 : "")
    + "\n                </div>\n\n                <div class=\"row-fluid\">\n                    <div class=\"span7\">\n                        <div class=\"control-group control-group-spacer\"\n                             title=\"This name will be used in charts and other places where we want to show a client facing name for the brand\">\n                            <label>Client facing brand name</label>\n                            <div class=\"controls\">\n                                <input dir=\"auto\" type=\"text\" name=\"shortName\" class=\"span12\" placeholder=\"Client facing brand name\">\n                            </div>\n                        </div>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level0") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":51,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level0") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":96,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"level0") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":16},"end":{"line":116,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"level0") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":12},"end":{"line":121,"column":19}}})) != null ? stack1 : "")
    + "            </div>\n    </div>\n\n    <div class=\"dialog-button-bar\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":8},"end":{"line":137,"column":15}}})) != null ? stack1 : "")
    + "        <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n        <a class=\"ok btn btn-primary\">Ok</a>\n    </div>\n</div>\n\n\n";
},"useData":true});