<template>
    <add-rule-dialog class="add-engage-rule"
                     @close="$emit('close')"
                     @next="next" @prev="prev"
                     :rule-type="ruleTypes.engage"
                     :total-stages="totalStages"
                     :cur-stage="curStage"
                     :next-btn-text="nextBtnText"
                     :show-next-btn="showNextBtn"
                     :show-prev-btn="showPrevBtn"
                     :enable-next-btn="enableNextBtn">
        <template #workflow>
            <div key="1" v-if="curStage === addRuleStages.engage.EDIT_ENGAGE_PROCESSING">
                <text-input class="add-engage-rule__text-input" v-model="ruleName" @valid="setValidRuleName" required label="Rule name" ref="name" placeholder="Rule name or description"/>

                <div class="add-engage-rule__engage-settings">
                    <div>
                        <h4>Send to Engage</h4>
                        <p>
                            Specify whether or not these mentions should be sent to Engage.
                        </p>
                        <div class="btn-selector">
                            <be-button :active="sendToEngage === DEFAULT" @click="sendToEngage = DEFAULT">Default</be-button>
                            <be-button :active="sendToEngage === SEND" @click="sendToEngage = SEND">Send</be-button>
                            <be-button :active="sendToEngage === DO_NOT_SEND" @click="sendToEngage = DO_NOT_SEND">Do Not Send</be-button>
                        </div>
                    </div>

                    <div v-if="sendToEngage !== DO_NOT_SEND && engageTeams.length">
                        <h4>Assign to a specific Engage Team</h4>
                        <p>
                            Specify which team these mentions should be assigned to.
                        </p>
                        <drop-down-input class="team-drop-down" v-model="selectedEngageTeamId" :options="engageTeams"></drop-down-input>
                    </div>

                    <div v-if="sendToEngage !== DO_NOT_SEND">
                        <h4>Set Engage priority</h4>
                        <p>
                            Specify the priority in which these mentions should be processed on Engage.
                        </p>
                        <input v-model="priority" type="number"/>
                    </div>
                </div>
            </div>

            <div key="2" v-if="curStage === addRuleStages.engage.EDIT_FILTER">
                <h4>Edit the filter</h4>
                <p>
                    {{filterMessage}}
                </p>
                <filter-editor class="add-engage-rule__filter-editor" @input="onFilterChange" :value="ruleFilter" for-rule/>
            </div>

            <div key="2" v-if="curStage === addRuleStages.engage.EXAMPLE_MENTIONS">
                <h4>Example mentions</h4>
                <p>{{exampleMentionsMessage}}</p>
                <div class="add-engage-rule__preview-mentions">
                    <preview-mentions :filter="ruleFilter"></preview-mentions>
                </div>
            </div>

            <div key="3" v-if="curStage === addRuleStages.engage.SAVE">
                <saving-item :save-complete="saveComplete" :save-error="saveError" :saving-message="saveMessage" :save-complete-message="saveCompleteMessage">
                    <template #additionalActions>
                        <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add more <i class="symbol-checkmark"></i> Relevancy rules</h4>
                    </template>
                </saving-item>
            </div>
        </template>
    </add-rule-dialog>
</template>

<script>
import AddRuleDialog from "@/setup/rules/add-rule/AddRuleDialog";
import {ruleTypes} from "@/setup/rules/RuleUtils";
import {addRuleMixins} from "@/setup/rules/add-rule/AddRuleMixins";
import TextInput from "@/components/inputs/TextInput";
import FilterEditor from "@/components/inputs/FilterEditor";
import PreviewMentions from "@/components/PreviewMentions";
import SavingItem from "@/setup/SavingItem";
import VuexStore from "@/store/vuex/VuexStore";
import BeButton from "@/components/buttons/BeButton";
import DropDownInput from "@/components/inputs/DropDownInput";
import {mapActions, mapState} from "vuex";

export default {
    name: "AddEngageRule",
    components: {
        DropDownInput,
        BeButton,
        SavingItem, PreviewMentions, FilterEditor, TextInput, AddRuleDialog},
    mixins: [addRuleMixins],
    store: VuexStore,

    data() {
        return {
            ruleTypes: ruleTypes,
            totalStages: 1,
            curStage: 1,
            ruleName: "",
            ruleNameValid: true,
            ruleFilter: "",
            sendToEngage: "",
            DEFAULT: "DEFAULT",
            SEND: "SEND",
            DO_NOT_SEND: "DO_NOT_SEND",
            selectedEngageTeamId: 0,
            priority: 5000,
        }
    },

    async created() {
        this.totalStages = Object.keys(this.addRuleStages.engage).length;
        this.sendToEngage = this.DEFAULT;

        await this.refreshEngageTeams();

        this.selectedEngageTeamId = this.engageTeams.length ? this.engageTeams[0].id : null;
    },

    computed: {
        ...mapState(['engageTeams']),

        enableNextBtn() {
            switch (this.curStage) {
                case this.addRuleStages.engage.EDIT_ENGAGE_PROCESSING:
                    if (this.sendToEngage === this.DO_NOT_SEND) {
                        return !!this.ruleNameValid;
                    }
                    return !!this.ruleNameValid && !!this.selectedEngageTeamId && !!this.priority;
                case this.addRuleStages.engage.EDIT_FILTER:
                    return !!this.ruleFilter;
                case this.addRuleStages.engage.SAVE:
                    return false;
                default:
                    return true;
            }
        },

        showNextBtn() {
            return this.curStage !== this.addRuleStages.engage.SAVE;
        },

        showPrevBtn() {
            return this.curStage > 1 && this.curStage !== this.addRuleStages.engage.SAVE;
        },

        nextBtnText() {
            return this.curStage !== this.totalStages - 1  ? "Next" : "Save Rule";
        },

        filterMessage() {
            switch (this.sendToEngage) {
                case this.SEND:
                    return `Choose the mentions that will be sent to Engage and assigned to team ${this.engageTeamName}`;
                case this.DO_NOT_SEND:
                    return `Choose the mentions that will not be sent to Engage`;
                default:
                    return '';
            }
        },

        exampleMentionsMessage() {
            switch (this.sendToEngage) {
                case this.SEND:
                    return `Future mentions like this will be sent to Engage and assigned to team ${this.engageTeamName}`;
                case this.DO_NOT_SEND:
                    return `Future mentions like this will not be sent to Engage`;
                default:
                    return '';
            }
        },

        engageTeamName() {
            return this.engageTeams.find(t => t.id === this.selectedEngageTeamId)?.name
        }
    },

    methods: {
        ...mapActions(['refreshEngageTeams']),

        setValidRuleName(value) {
            this.ruleNameValid = value;
        },


        onFilterChange(changeData) {
            this.ruleFilter = changeData;
        },

        next() {
            this.curStage++;

            if (this.curStage === this.totalStages) {
                let payload = {
                    action: "ENGAGEMENT_CONSOLE",
                    active: true,
                    attributes: "",
                    data: {},
                    filter: this.ruleFilter,
                    name: this.ruleName
                }

                // TODO: should DO_NOT_SEND still require teamId, priority, and name?
                if (this.sendToEngage !== this.DO_NOT_SEND) {
                    payload.data = {
                        priority: this.priority,
                        teamId: Number(this.selectedEngageTeamId),
                        teamName: this.engageTeamName
                    }
                }

                if (this.sendToEngage === this.SEND || this.sendToEngage === this.DO_NOT_SEND) {
                    payload.data.send = this.sendToEngage === this.SEND ? "YES" : "NO";
                }

                this.saveRule(payload);
            }
        },

        prev() {
            this.curStage--;
        },

        reset() {
            this.curStage = 1;
            this.ruleName = "";
            this.ruleNameValid = true;
            this.sendToEngage = this.DEFAULT;
            this.ruleFilter = "";
        }
    }
}
</script>

<style scoped lang="sass">

.add-engage-rule

    p
        color: var(--be-colour-text-dark)

    &__header-container
        border-bottom: 1px dashed var(--be-colour-muted-text-dark)
        padding-bottom: 10px

    &__text-input
        height: 90px

        ::v-deep input
            width: 80%

    &__engage-settings
        display: flex
        flex-direction: column
        row-gap: 20px

        p
            margin-bottom: 10px

        .team-drop-down
            width: 200px


        .btn-selector
            display: flex
            .be-button:not(:last-of-type) ::v-deep .btn
                border-top-right-radius: 0
                border-bottom-right-radius: 0
            .be-button + .be-button
                & ::v-deep .btn
                    border-top-left-radius: 0
                    border-bottom-left-radius: 0


    &__filter-editor
        margin-top: 20px
        height: 500px

    &__preview-mentions
        display: flex
        height: 475px

</style>