/**
 * Select one or more RPCS related tags (Risk, Purchase, Cancel, Service).
 */
import {createTagConverter} from "@/app/framework/pickers/picker-utils";
import {splitAtSpaces} from "@/app/utils/StringUtils";

Beef.module("RpcsPicker").addInitializer(function(startupOptions) {

    var items = {
        "&": "&", // Need this for the "All Required" tag.
        "1": "Risk",
        "2": "Purchase",
        "3": "Cancel",
        "4": "Service"
    };
    this.items = items;

    this.View =  Beef.Picker.View.extend({
        attributes: { class: "rpcs-picker" },
        template: require("@/dashboards/filter/pickers/rpcspicker/RpcsPicker.handlebars"),
        items: items,

        setSelection: function(values) {
            values = values ? splitAtSpaces(values) : [];

            var attrs = {
                all: !values.length || values.length === 1 || values[0] === "&"
            };

            values.forEach(function(v) {
                if (v === "&") return;

                var id = parseInt(v);
                attrs[Math.abs(id)] = true;
                if (id < 0) attrs["ex-" + Math.abs(id)] = true;
            });

            this.model.set(attrs);
        },
        getSelection: function() {
            var results = [];
            if (this.model.get("1")) results.push(!this.model.get("ex-1") ? 1 : -1);
            if (this.model.get("2")) results.push(!this.model.get("ex-2") ? 2 : -2);
            if (this.model.get("3")) results.push(!this.model.get("ex-3") ? 3 : -3);
            if (this.model.get("4")) results.push(!this.model.get("ex-4") ? 4 : -4);

            this.$el[0].dataset.segments = results.map(Math.abs).join(" ");

            // We don't want to just return the "&"
            if (results.length >= 2 && this.model.get("all")) results.unshift("&");
            return results;
        }
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            items: items,
            placeholder: placeholder,
            placeholderPersistent: false,
            cls: function(d) { return "tag rpcs" },
            nameFormatter: function(name, neg) {
                if (neg) return "-" + name;

                switch (name.toLowerCase()) {
                    case 'risk':        return "<be-rpcs-icon code='RISK' full></be-rpcs-icon>";
                    case 'purchase':    return "<be-rpcs-icon code='PURCHASE' full></be-rpcs-icon>";
                    case 'cancel':      return "<be-rpcs-icon code='CANCEL' full></be-rpcs-icon>";
                    case 'service':     return "<be-rpcs-icon code='SERVICE' full></be-rpcs-icon>";
                }

                return name;
            }
        });
    };

    this.converter = this.createConverter("Priority conversations");

    /**
     * Attach an rpcs picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        var options = {
            mouseover: function(item, node) { Beef.TagPicker.tooltip(view, item, node); }
        };
        Beef.Picker.attachPicker(view, selector, attribute, this.View, options);
    };

});
