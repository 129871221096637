<template>
    <div class="deq-reset">
        <dotted-card v-if="unrecognisedWarnings.length && user.admin" >
            <h1><i class="symbol-warning"></i> The following are unrecognised warning types:</h1>
            <ul>
                <li v-for="warning of unrecognisedWarnings" :key="warning">
                    {{warning}}
                </li>
            </ul>
            <p>Please contact dev</p>
        </dotted-card>

        <details v-for="type in warningsTypes" :key="type"
                 class="warning-cluster-card__warning-section short-animated fadeIn"
                 :data-priority="getWarning(type).priority < 15"
                 :open="getWarning(type).priority < 15">
            <summary>
                <i class="symbol-warning" :class="{'symbol-warning--muted': getWarning(type).priority >= 15}"></i> {{getDescription(type)}}
                <span  v-if="user.debugMode" class="deq-callout--muted"><i class="symbol-"></i>🐛 id: {{type}} | priority: {{getWarning(type).priority}}</span>
            </summary>

            <div class="warning-cluster-card__body">
                <div v-if="getObjectsForWarning(type).length" class="warning-cluster-card__list">
                    <div v-for="object in getObjectsForWarning(type)" :key="object.id">
                        <analyse-link :object="object"/>
                        <div v-if="getComment(type, object)" class="warning-cluster-card__comment">
                            {{getComment(type, object)}}
                        </div>
                    </div>
                </div>

                <p v-if="user.admin && allPossibleWarnings[type].clientVisible">
                    <strong><i class="symbol-info"></i> Visible to clients</strong>
                </p>

                <p v-if="getFix(type)">
                    <strong><i class="symbol-info"></i> How to fix:</strong> {{getFix(type)}}
                </p>
            </div>
        </details>


        <div v-if="user.admin && allPossibleWarnings && !account.inactive" class="warning-cluster-card__all-warnings"
             :class="{'warning-cluster-card__all-warnings--loaded': !loading}">
            <h2>
                Setup checklist
            </h2>

            <p>
                <inline-loading-message v-if="loading">Checking...</inline-loading-message>
            </p>


            <div v-for="warning in visibleWarningDescriptions" :key="warning">
                <animated-check v-if="!loading"/>
                {{ allPossibleWarnings[warning].description }}
            </div>
        </div>
    </div>

</template>


<script>

import {formatBrandName} from "@/app/utils/Format";
import {mapState} from "vuex";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import AnalyseLink from "@/components/AnalyseLink";
import {ALL_HEALTH_WARNINGS} from "@/store/vuex/dataChecks";
import InlineLoadingMessage from "@/components/InlineLoadingMessage";
import DottedCard from "@/components/cards/DottedCard";

export default {
    components: {DottedCard, InlineLoadingMessage, AnalyseLink, AnimatedCheck},
    props: {
        warnings: {
            type: Array,
            required: true
        },

        allPossibleWarnings: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState(['account', 'user']),

        warningsTypes() {
            if (!this.warnings?.length) return [];
            return Array.from(
                new Set(this.warnings
                    .filter(warning => this.allPossibleWarnings[warning.id] && (this.user.admin || this.allPossibleWarnings[warning.id]?.clientVisible))
                    .map(warning => warning.id))
            );
        },

        unrecognisedWarnings() {
            if (!this.warnings?.length) return [];
            return [... new Set(this.warnings.filter(warning => !this.allPossibleWarnings[warning.id]).map(w => w.id))];
        },

        visibleWarningDescriptions() {
            if (!this.warnings || !this.allPossibleWarnings) return [];

            const warnings = new Set(this.warnings.map(w => w.id));
            return Object.keys(this.allPossibleWarnings)
                .filter(key => !warnings.has(key) && !this.allPossibleWarnings[key]?.hide);
        }
    },

    methods: {
        formatBrandName,

        getWarning(type) {
            return ALL_HEALTH_WARNINGS[type];
        },

        getDescription(type) {
            const item = this.warnings?.find(warning => warning.id === type);
            if (!item) return "";
            return item.description;
        },

        getComment(type, object) {
            const item = this.warnings?.find(warning => warning.id === type && (warning.brand?.id === object?.id || warning.dashboard?.id === object?.id || warning.profile?.id === object?.id));
            if (!item) return null;
            return item.comment;
        },

        getFix(type) {
            const item = this.warnings?.find(warning => warning.id === type);
            if (!item?.fix) return null;
            return item.fix;
        },

        getObjectsForWarning(type) {
            if (!this.warnings?.length) return [];
            return this.warnings
                .filter(warning => warning.id === type && warning.object)
                .map(warning => warning.object)
        },

        gotoObject(object) {
            Beef.router.navigate(object.analyseLink, {trigger: true});
        },
    }
}
</script>

<style scoped lang="sass">

.warning-cluster-card__warning-section
    .be-tooltip
        display: inline


.warning-cluster-card__warning-section:first-of-type
    margin-top: 20px


.warning-cluster-card__warning-section[open]
    margin-top: 20px
    padding-top: 10px


.warning-cluster-card__warning-section[data-priority] + .warning-cluster-card__warning-section:not([data-priority])
    margin-top: 20px


.warning-cluster-card__body
    box-sizing: border-box
    padding-left: 50px

.warning-cluster-card__all-warnings
    margin-top: 50px
    color: var(--be-colour-muted-text-dark)
    margin-left: 16px
    transition: color 200ms

    h5
        color: var(--be-colour-text-dark)
        font-style: italic

    &--loaded
        color: var(--be-colour-text-dark)
        margin-left: 0

.warning-cluster-card__list
    display: grid
    grid-template-columns: repeat(3, minmax(100px, 300px))
    margin-block: 10px
    column-gap: 10px
    row-gap: 10px

.warning-cluster-card__link
    display: flex

.warning-cluster-card__comment
    color: var(--be-colour-muted-text-dark)
    font-style: italic


</style>