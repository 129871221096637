import { printStackTrace } from '@/js/stacktrace'
import VuexStore from "@/store/vuex/VuexStore";
import {getMostRecentCommitHash} from "@/app/VersionChecker";
import {isString} from "@/app/utils/StringUtils";

/**
 * Log an error. This will never throw an error itself.
 */
export function logError(data) {
    const account = VuexStore.state.account;
    const user = VuexStore.state.user;
    if (account.dev) return;

    const currentVersion = COMMITHASH;

    try {
        if (isString(data)) data = { message: data };
        data = Object.assign({
            message: "error",
            type: "error",
            user: user.email,
            userId: user.id,
            account: account.code,
            location: window.location.toString(),
            userAgent: navigator.userAgent,
            commitHash: currentVersion,
            isMostCurrentCodebase: currentVersion === getMostRecentCommitHash()
        }, data);

        try {
            const uri = new URL(window.location);
            if (uri.searchParams.get("filter")) {
                data.filter = uri.searchParams.get("filter");
            }
        } catch(e) {
            console.warn("Error during logging", e);
        }

        if (data.exception) {
            data.stacktrace = (data.exception instanceof Error ? printStackTrace({e: data.exception}) : printStackTrace()).join('\n');
            delete data.exception;
        }
        $.ajax({
            url: '/api/logging/error',
            type: "POST",
            ignoreErrors: true,
            data: data
        })
    } catch (e) {
        console.error(e);
    }
}
