/** @typedef BrandPickerBrand {excluded, selected, children, parent} */

import {fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import {createTagConverter, toPlaceholderHTML} from "@/app/framework/pickers/picker-utils";
import {formatBrandName} from "@/app/utils/Format";

/**
 * See tests in brand-picker.spec.js on the behaviour of this function
 * @param {BrandPickerBrand} brand
 */
export function brandPickerNegate(brand) {
    brand.excluded = true;
    brand.selected = true;
    if (brand.children) {
        for (const child of brand.children) {
            child.selected = child.excluded = false;
        }
    }
    if (brand.parent && !brand.parent.selected) {
        const isChildOrGrandchildSelected = (c) => {
            if (c.brand.id !== brand.brand.id && c.selected) return true;
            if (c.children?.length) return c.children.some(isChildOrGrandchildSelected);
            return false;
        };

        const noSibling = !brand.parent.children.some(isChildOrGrandchildSelected);
        if (noSibling) {
            brand.parent.selected = true;
            brand.parent.excluded = false;
        }
    }
}

/**
 * See tests in brand-picker.spec.js on the behaviour of this function
 * @param {BrandPickerBrand} brand
 */
export function brandPickerSelectChild(brand) {
    if (brand.excluded) {
        brand.excluded = false;
        brand.selected = false;
    } else {
        brand.selected = !brand.selected;
        brand.parent.selected = false;
        // if selecting sub-sub brand
        if (brand.parent?.parent) {
            brand.parent.parent.selected = false;
        }

        // This needs to come last. We want to unselect
        // things that should no longer be selected.
        if (brand.parent) {
            let root = brand.parent;
            while (root.parent) root = root.parent;
            let selectedCount = 0;
            let excludedCount = 0;
            const counter = b => {
                if (b.selected && !b.excluded) selectedCount++;
                if (b.selected && b.excluded) excludedCount++;
                if (b.children?.length) b.children.forEach(counter);
            };
            root.children.forEach(counter);
            if (excludedCount && !selectedCount) {
                brandPickerSelectRoot(root, true, false);
            }
        }
    }
}

/**
 * See tests in brand-picker.spec.js on the behaviour of this function
 * @param {BrandPickerBrand} brand
 * @param {boolean} allowChildren
 * @param {boolean?} optionalValue
 */
export function brandPickerSelectRoot(brand, allowChildren, optionalValue) {
    const value = optionalValue ?? !brand.selected;
    if (allowChildren) {
        brand.children?.forEach(c => {
            if (!c.excluded) {
                c.selected = false;
                c.excluded = false;
            }
            if (!value && c.excluded) {
                c.excluded = c.selected = false;
                c.children?.forEach( gc => gc.selected = gc.excluded = false );
            }
            c.children?.forEach(gc => {
                if (!gc.excluded) {
                    gc.selected = false;
                    gc.excluded = false;
                }
            });
        });
    }
    brand.selected = value;
    if (!value) brand.excluded = false;
}


/**
 * This is to support the old picker code. Should be removed.
 * @deprecated
 */
export function createBrandTagConverterFactory(placeholder) {
    return function(view) {
        var conv;
        return function(direction, value) {
            value = value? value.toString() : null;
            if (value && value.length > 0) {
                var binding = this;
                if (conv) return conv.call(binding, direction, value);
                fetchCachedBrands(view, function(brands) {
                    conv = createTagConverter({
                        items: brands.map,
                        nameFormatter: brandFormatter,
                        placeholder: placeholder,
                        excludeSubBrands: true
                    });
                    conv.call(binding, direction, value);
                });
            } else {
                if (placeholder) $(this.boundEls[0]).html(toPlaceholderHTML(placeholder));
                else $(this.boundEls[0]).text("");
            }
            return value;
        }
    }
}

function brandFormatter(brand, neg) {
    const name = formatBrandName(brand);
    let a = [];
    for (; brand; brand = brand.parent) a.push($('<pre>').text(formatBrandName(brand)).html());
    a.reverse();
    return (neg ? "-" : "") + a.join(" <span class='delim'> &raquo; </span> ");
}