/**
 * Displays a popup on the screen. Only one popup can be shown at a time: this will automatically
 * hide any other popup that's in view.
 *
 * <p>
 * It is possible to supply templateHelpers by passing it as an option to the view.
 *
 * <p>
 * See thw show method.
 */
import {getMention} from "@/data/Grouse";
import {formatDefaultCurrency, formatNumber} from "@/app/utils/Format";
import {isString} from "@/app/utils/StringUtils";
import {account} from "@/app/utils/Account";

Beef.module("Tooltip").addInitializer(function(startupOptions) {

    let mentionCache = {};
    let lastMoreExpansion = Date.now() - 10000;
    let lastClosed = Date.now() - 10000;

    var TooltipView = Backbone.Marionette.ItemView.extend({
        tagName: "div",
        attributes: { class: "beef-tooltip" },

        mentionId: null,
        mentionPromise: null,
        firstRender: true,

        initialize: function(options) {
            if (options.templateHelpers) this.templateHelpers = options.templateHelpers;
            if (options.mention) this.mentionId = options.mention;
        },

        modelEvents: {
            "change": "render"
        },

        onRender: function() {
            try {
                var $div = this.$(".example-mention");
                if ($div.length) {
                    if (this.mentionId) {
                        var mention = mentionCache[this.mentionId];

                        if (mention) {
                            this.drawMention(mention);
                        } else if (!this.mentionPromise) {
                            $div.html("<div class='mention-loading-message animated fadeIn delay-1000'>" +
                                "<div class='spinner48'></div>" +
                                "<span>Loading example mention….</span>" +
                                "</div>"
                            );

                            this.mentionPromise = getMention(account().code, this.mentionId);
                            this.mentionPromise
                                .then(function(mention) {
                                    mentionCache[mention.id] = mention;
                                    return mention;
                                })
                                .then(function(mention) { this.drawMention(mention, true)}.bind(this))
                                .catch(console.warn);
                        }
                    } else $div.css({display: "none"})
                }

                if (this.firstRender) {
                    if (Date.now() - lastMoreExpansion < 1000) {
                        this.expandMore();
                    } else {
                        setTimeout(function() { this.expandMore(true)}.bind(this), 2500);
                    }
                }

            } finally {
                this.firstRender = false;
            }

        },

        expandMore: function(fadeIn) {
            this.expanded = true;
            lastMoreExpansion = Date.now();
            this.$el.addClass("beef-tooltip--expanded");
            this.$el.toggleClass("beef-tooltip--fade-in", !!fadeIn);
        },

        onClose: function() {
            if (this.mentionPromise) {
                this.mentionPromise.cancel();
                this.mentionPromise = null;
            }
            if (this.expanded) {
                lastMoreExpansion = Date.now();
            }

            lastClosed = Date.now();
        },

        drawMention: function(mention, fade) {
            var $div = this.$(".example-mention");
            if (!$div.length) return;

            var model = new Beef.MentionItem.Model(mention);
            var v = new Beef.MentionItem.View({model: model, timeline: true, noSelect: true, noView: true});
            var cls = v.attributes().class;
            if (fade) cls += " short-animated fadeIn";
            var body = Marionette.Renderer.render(v.getTemplate(), v.serializeData());
            var html = "<div class='" + cls + "'>" + body + "</div>";

            $div.html(html);
        }
    });

    /**
     * Useful for destroying tooltips.
     */
    var currentTooltip = null;
    /**
     * This private counter is used to ensure that after a tooltip delay occurs, it will only show itself
     * if it is the most recent tooltip to be opened.
     */
    var counter = 0;

    //------------------------------

    /**
     * Request that the current tooltip close and hide itself.
     */
    this.close = function() {
        if (currentTooltip) {
            currentTooltip.close();
            currentTooltip = null;
        }
    };

    /**
     * Is a tooltip currently displayed?
     */
    this.isShowing = function() {
        return currentTooltip != null;
    };

    /**
     * If we are displaying a tooltip then return its view.
     */
    this.getCurrentView = function() {
        return currentTooltip ? currentTooltip.getCurrentView() : null;
    };

    this.getCurrentTooltip = function() {
        return currentTooltip;
    };

    //------------------------------

    /**
     * Show a tooltip. The following options should be given:
     * {
     *      positions: an array of strings specifying where the tooltip should attempt to fit itself.
     *      offsets:   how the tooltip should offset itself from its chosen location.
     *      view:      optional view to display as the tooltip
     *      model:     a model to supply data to the template that is being displayed.
     *      delay:     a delay before the tooltip shows itself. Defaults to 400ms.
     *      template:  the template that should be displayed
     *      target:    the item that the tooltip is displaying over.
     *      autoclose: optional. If true, the tooltip will automatically close itself. Default false (for compatibility with older code)
     *      mention:   optional. An example mention to display. There should be a dom element in your template with the .example-mention class
     *      onTooltipCreated:   optional. A callback passed the tooltip. Useful for closing the tooltip.
     * }
     */
    this.show = function({onTooltipCreated, ...options}) {
        options = Object.assign({
            positions: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            offsets: { right: -1, top: +1 },
            delay: 1000,
            plain: true,
            mention: null,
            template: !options?.isEscapedHtml ? require("@/app/framework/tooltips/Tooltip.handlebars") : require("@/app/framework/tooltips/EscapedTooltip.handlebars")
        }, options || {});

        // See if we're trying to open the same tooltip on the current target. If we are, ignore it.
        // This avoids flashing of tooltips when people move the cursor over sub-elements of a target element.
        if (currentTooltip && !currentTooltip.isClosed && currentTooltip.target && currentTooltip.target[0] === options.target) {
            return;
        }

        this.close();
        var current = ++counter;

        if (!options.target) throw new Error("No target provided for tooltip");

        var mouseOut = false;
        var execute = function() {
            // The element has been removed

            var node = options.target instanceof $ ? options.target[0] : options.target;
            if (!document.body || !document.body.contains || !document.body.contains(node)) return;

            if (current === counter && !mouseOut) {
                this.close();
                var tooltip = new Beef.Popup.View({
                    closeOnHide: true,
                    positions: options.positions,
                    offsets: options.offsets,
                    dropdown: options.dropdown,
                    plain: options.plain,
                    extraCls: options.extraCls,
                    scrollIntoView: false
                });

                currentTooltip = tooltip;
                if (onTooltipCreated) onTooltipCreated(tooltip);

                var model = options.model || new Backbone.Model({text: options.text || "No text provided", keyword: options.keyword ?? null});
                var view = options.view || new TooltipView({model: model, template: options.template, templateHelpers: options.templateHelpers, mention: options.mention});
                currentTooltip.setTarget(options.target);
                currentTooltip.show(view);
                currentTooltip.$el.toggleClass("disposable-popup", true); // so version checker can reload app
                if (options.node) {
                    setTimeout(() => {
                        const tooltip = view.$('.be-tooltip--container')[0];
                        tooltip.replaceChildren();
                        tooltip.appendChild(options.node);
                    })
                }

                if (options.view) {
                    view.bind('close', () => lastClosed = Date.now());
                }

                if (options.autoclose) {
                    $(options.target).one("mouseleave", function() {
                        tooltip.close();
                    }.bind(this));
                }
            }
        }.bind(this);

        $(options.target).one("mouseout", function() {
            // don't open the tooltip if the mouse has left.
            mouseOut = true;
        });

        // Have tooltips open quickly after the last one closed.
        if (options.delay && Date.now() - lastClosed > 1000) setTimeout(execute, options.delay);
        else execute();
    };

    this.move = function (){
        if (currentTooltip) currentTooltip.move()
    }
    //----------------------

    this.chartViewDefaultDataHandler = function(chart, event) {
        var type = this.model.get('label-type'),
            x = this.x(event.point);
        return new Backbone.Model({
            type: {
                type: type,
                ave: type === 'ave',
                ots: type === 'ots',
                count: type === 'count',
                percentage: type === 'percentage',
                aveOrOts: type === 'ave' || type === 'ots'
            },
            x: x,
            label: x,
            count: formatNumber(event.point.count),
            percentage: event.point.percentage,
            ave: formatDefaultCurrency(event.point.ave),
            ots: formatNumber(event.point.ots),
            subject: this.tooltipSubject,
            verb: this.tooltipVerb,
            aveOtsVerb: this.tooltipAveOtsVerb,
            dataType: this.tooltipDataType,
            object: this.tooltipObject,
            others: x && isString(x) && x.toLowerCase() === 'others', // Only want to show English values.
            unknown: x && isString(x) && x.toLowerCase() === 'unknown'
        })
    }
});