var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <!-- Starts disabled, and we enable download when the metric has completed rendering. -->\n                <a class=\"download-button disabled hide-in-fullscreen\" title=\"Download this metric as an image\"> <i class=\"symbol-download\"></i></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"title\" onclick=\"void(0)\">\n    <div class=\"inner\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSelector") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "        <a class=\"menu-trigger popup-menu hide-in-fullscreen\" title=\"Click for metric menu\"><span class=\"caret\"></span></a>\n        <h5 name=\"caption\" class=\"editable edit-graph\" title=\"Click to edit metric\"></h5>\n    </div>\n</div>\n\n<div class=\"widget-data-container\">\n    <div class=\"widget-container\">\n\n    </div>\n    <div class=\"widget-markdown\">\n\n    </div>\n    <div class=\"widget-commentary\">\n\n    </div>\n</div>\n\n\n<div class=\"widget-footnotes widget-font hide-in-fullscreen\"></div>\n\n<div class=\"message-area\">\n    <span class=\"widget-message\"></span>\n</div>\n\n<div class=\"main-overlay overlay\"></div>\n\n<div class=\"spinner-overlay overlay\">\n    <div class=\"loading-message\" title=\"Currently fetching your data from our servers\">\n        "
    + container.escapeExpression(__default(require("../../helpers/spinner.js")).call(alias1,{"name":"spinner","hash":{},"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":19}}}))
    + "\n        <h3>Loading...</h3>\n    </div>\n</div>\n\n<section class=\"widget__warning-area\">\n\n</section>";
},"useData":true});