<template>
    <div class="mention-conversation-container">
        <conversation-toolbar @close="onClose()" @options-menu-clicked="(event) => showBeefMiniMenu(event)"/>
        <conversation-info :selected-mention="selectedMention" :accountCode="accountCode"/>
        <div class="mention-conversation-container-inset">
            <div v-if="loading">
                <div class="replies-loading" title="Loading the other mentions involved in this conversation">
                    <span class="spinner"></span>Loading…
                </div>
            </div>
            <template v-else>
                <div v-if="error" v-html="error"/>
                <MentionItem v-for="mention of mentions" :key="mention.id" :mention="mention" />
            </template>
        </div>
    </div>
</template>

<style scoped>
.mention-conversation-container {
    display: flex;
    position: fixed;
    top: 40px;
    flex-flow: column;
    width: 100vw;
    height: calc(100vh - 40px);
    overflow-x: clip;
    overflow-y: clip;
}

.mention-conversation-container-inset {
    flex: 1 1 auto;
    background: var(--body-background-colour);
    overflow-x: clip;
    overflow-y: auto;
    padding: 20px 100px;
}
</style>

<script>
import "../MentionConversation.css";
import ConversationInfo from "@/conversation/vue/ConversationInfo";
import ConversationToolbar from "@/conversation/vue/ConversationToolbar";
import {MentionConversationLoader} from "@/conversation/utils/MentionConversationLoader";
import {account} from "@/app/utils/Account";
import moment from "moment";
import MentionItem from "@/components/MentionItem.vue";

export default {
    name: "MentionConversationView",
    components: {MentionItem, ConversationToolbar, ConversationInfo},

    props: {
        selectedMention: {
            type: Object,
            required: true
        },
        accountCode: {
            type: String,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        }
    },
    data: function () {
        return {
            mentionLoader: null,
        }
    },
    computed: {
        loading: function () {
            return this.mentionLoader?.loading ?? false;
        },
        error: function () {
            return this.mentionLoader?.error ?? false;
        },
        mentions: function () {
            return this.mentionLoader?.visibleMentions() ?? [];
        }
    },
    methods: {
        fetchData: async function () {
            this.mentionLoader = new MentionConversationLoader(this.accountCode, this.selectedMention);
            await this.mentionLoader.fetchData();
            window.mentionLoader = this.mentionLoader;
        },
        showBeefMiniMenu(ev) {
            Beef.MiniMenu.show({
                template: require("@/conversation/MentionConversationMenu.handlebars"),
                object: this,
                target: $(ev.target).closest("a")
            });
        },
        seeAuthors() {
            this.mentionLoader?.seeAuthors();
            this.onClose();
        },
        seeMentions() {
            this.mentionLoader?.seeMentions();
            this.onClose();
        },
        highlightSelected() {
            document.getElementById(`conversation-mention-id-${this.selectedMention.id}`).scrollIntoView({
                behavior: "smooth"
            })
        },
        emailConversation() {
            const btn = document.getElementsByClassName('mention-replies-menu').item(0);
            const {authorName, id, published} = this.selectedMention;
            const ids = this.mentions.map(m => m.id);

            Beef.InteractDialog.show({
                target: btn,
                accountCode: this.accountCode,
                email: true,
                subject: 'See this conversation from DataEQ | ' + this.accountCode + ` ${account().name} ` +
                    ": " + authorName +
                    ", dated " + new moment(published).format("YYYY/MM/DD HH:mm"),
                footer: "This message was sent to you by " + Beef.user.get('firstName') + " " + Beef.user.get('lastName') +
                    ", who is using DataEQ, a web application for the analysis and tracking of online conversation. " +
                    "You can view the conversation <a href=\"https://v3.brandseye.com/" + this.accountCode +
                    "/mentions/" + id + "/conversation\">here</a>",
                mentionIds: ids,
                namespace: "conversation"
            });
        }

    },
    mounted() {
        this.fetchData();
    }
}
</script>
