var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr>\n        <td>"
    + alias2(__default(require("../../helpers/renderPhrase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"query") : depth0),{"name":"renderPhrase","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":34}}}))
    + "</td>\n        <td>"
    + alias2(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"mentionsTotal") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":42}}}))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"control-group\">\n    <div class=\"help-block\">Please review the volume estimates carefully. Note that the estimated volumes do not\n    take into account exclusions, phrase or root brand filters and will over estimate phrases with multiple\n    positive terms. Use Rewind if you need more accurate estimates.</div>\n</div>\n\n<table class=\"table table-condensed table-bordered table-hover\" style=\"margin-top: 10px; margin-bottom: 10px\">\n    <thead>\n    <tr>\n        <th>Phrase</th>\n        <th>Estimated Monthly Volume</th>\n    </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"phrases") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "    <tr>\n        <td>Total</td>\n        <td>"
    + container.escapeExpression(__default(require("../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":34}}}))
    + "</td>\n    </tr>\n    </tbody>\n</table>\n\n<div class=\"control-group\">\n    <div class=\"controls\" style=\"padding-left: 10px\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" class=\"confirm\">I have reviewed the volume estimates and want to add the phrases\n        </label>\n    </div>\n</div>\n\n";
},"useData":true});