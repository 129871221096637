import { translate, getDefaultTargetLanguage, saveDefaultTargetLanguage } from "../../utils/Language";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import {toEmojiHtml} from "@/app/utils/Format";
import _ from 'underscore';

/**
 * Provides a popup dialog for mention text.
 */
Beef.module("Dialogs").addInitializer(function(startupOptions) {

    var languagePicker = Beef.DynamicPicker.create({
        af: {name: "Afrikaans" },
        ar: {name: "Arabic" },
        'zh-CN': {name: "Chinese (Simplified)"},
        'zh-TW': {name: "Chinese (Traditional)"},
        en: {name: "English" },
        fr: {name: "French" },
        ru: {name: "Russian" },
        st: {name: "Sesotho"},
        es: {name: "Spanish"},
        sw: {name: 'Swahili (Kiswahili)'},
        xh: {name: "Xhosa"},
        zu: {name: "Zulu"}
    }, {placeholder: "Language", textFlow: true});


    var View = Beef.BoundItemView.extend({
        tagName: "div",
        attributes: { class: "framework-dialog translation-dialog" },
        template: require("@/app/framework/dialogs/TranslationDialog.handlebars"),

        events: {
            "click .ok": "ok",
            "click .cancel": "cancel",
            "click .close": "cancel",
            "mousedown": "mousedown"
        },

        modelEvents: {
            "change:language": "languageChanged",
            "change:loading": "loadingChanged"
        },

        templateHelpers: function() {
            return {
                original: this.model.get('mention').get('extract'),
                language: this.model.get('mention').get('language')
            }
        },

        bindings: function() {
            return {
                language: {selector: '.language', converter: languagePicker.converter, elAttribute: "data-value"}
            }
        },

        initialize: function() {
        },

        translate: function() {
            var mention = this.model.get('mention');
            var original = mention.get('extract');
            var language = this.model.get('language');

            var $suspected = this.$('.translation-dialog__suspected-language');

            this.model.set('loading', true);
            translate(original, language)
                .then(function(result) {
                    this.model.set('translation', result.targetText);
                    if (!mention.get('language') && result.sourceLanguage.name) {
                        $suspected.text("Original language might be " + result.sourceLanguage.name);
                    }
                }.bind(this))
                .catch(function(error) {
                    console.error("Error translating text", error);
                    showErrorDialog("We're having a problem communicating with Google Translate. Try again in a bit.", "Google Translate Problems");
                })
                .finally(function() {
                    this.model.set('loading', false);
                }.bind(this));
        },

        loadingChanged: function() {
            var loading = this.model.get('loading'),
                translation = this.model.get('translation');
            var $translated = this.$('.translation-dialog__translation');

            if (!loading) {
                $translated.html(toEmojiHtml(translation));
                $translated[0].dataset.lang = this.model.get('language');
            } else {
                $translated.html(Beef.Ui.Spinner.renderSpinner())
            }
        },

        languageChanged: function() {
            var language = this.model.get('language');
            if (!language || !language.length) return;

            saveDefaultTargetLanguage(language);

            this.translate();
        },

        onFirstRender: function() {
            languagePicker.attach(this, ".language", "language");
            this.translate();
        },

        ok: function() {
            this.handled = true;
            this.model.trigger("ok");
            this.close();
        },

        cancel: function() {
            this.close();
        },

        onClose: function() {
            if (!this.handled) {
                this.model.trigger(this.model.get("cancellable") ? "cancel" : "ok");
            }
        },

        keyup: function(ev) {
            if (ev.keyCode === 13) {
                ev.stopPropagation();
                ev.preventDefault();
                this.ok();
            }
        },

        mousedown: function() {
            Beef.Popup.closePopups(this);
        }
    });


    this.translate = function(mention, targetLanguage) {
        return new Promise(function(resolve, reject) {
            try {
                var model = new Backbone.Model({
                    mention: mention,
                    language: targetLanguage || getDefaultTargetLanguage()
                });

                model.on("ok", function() { resolve({
                    targetLanguage: model.get('language'),
                    targetText: model.get('translation')
                }) });
                model.on("cancel", function() { resolve(false) });

                var popup = new Beef.Popup.View({ closeOnHide: true, positions:["center"], plain: true, fixed: true });
                popup.setTarget($("html"));
                var view = new View({model: model, title: "Translate"});
                popup.show(view);
                popup.listenTo(popup, "keyup", _.throttle(view.keyup, 500, {trailing: false}), view);
            } catch(error) {
                console.warn(error);
                reject(error);
            }
        });
    }

});