<template>
    <div class="tips-and-help-settings">
        <header>
            <h1>Tips & Help</h1>
        </header>
        <p>Adjust your tips & help settings.</p>

        <table class="tips-and-help-settings__table">
            <tr>
                <th>Label</th>
                <th>Value</th>
            </tr>
            <tr>
                <td>See tips as you use Analyse</td>
                <td><input type="checkbox" :checked="viewTips" @change="updateViewTips($event)"/></td>
            </tr>
            <tr>
                <td>Clear tip history (you will be shown all tips again)</td>
                <td>
                    <be-button class="reset-tips-button" :disabled="!resetTipsEnabled" @click="resetTips">{{ resetTipsStatus }}
                        <spinner-component :size="15" v-show="resettingTips"></spinner-component>
                    </be-button>
                </td>
            </tr>
        </table>

        <show-in-dev>
            <be-button link @click="enableTesting" v-if="!testingEnabled">Enable tip testing functions</be-button>
            <slotted-tag v-else>Debug testing enabled</slotted-tag>
        </show-in-dev>

        <staff-info-card>
            <p>
                <be-button link
                           @click="downloadTipsCsv"
                           tooltip="Download all the tips that are currently show to users">Download tips CSV</be-button>
            </p>
        </staff-info-card>
    </div>
</template>

<script>

import {clearTipsCache, resetTips} from "@/app/help/tips/tips";
import BeButton from "@/components/buttons/BeButton";
import SpinnerComponent from "@/components/SpinnerComponent";
import ShowInDev from "@/components/cards/DevInfoCard";
import {notifyWithText} from "@/app/framework/notifications/Notifications";
import SlottedTag from "@/components/tags/SlottedTag";
import {enableTipTesting} from "@/app/help/tips/tip-utils";
import StaffInfoCard from "@/components/cards/StaffInfoCard";
import tips from "@/app/help/tips/tip-text";
import CSV from "@/app/CSV";

export default {
    name: "TipsSettings",
    components: {SpinnerComponent, StaffInfoCard, SlottedTag, ShowInDev, BeButton},

    props: {
        viewTips: Boolean
    },

    data: function () {
        return {
            resetTipsStatus: "Reset Tips",
            resettingTips: false,
            resetTipsEnabled: true,
            testingEnabled: false
        }
    },

    methods: {
        updateViewTips(event) {
            this.$emit('view-tips-updated', event.target.checked);
        },

        async resetTips() {
            if (this.resetTipsEnabled) {
                try {
                    this.resettingTips = true;
                    // clear tips in cache
                    clearTipsCache();

                    // reset tips in api
                    await resetTips();

                    this.resetTipsStatus = "Tips Successfully Reset";
                } catch (e) {
                    console.error("Error while trying to reset tips", e);

                    this.resetTipsStatus = "Error Resetting Tips"
                } finally {
                    this.resetTipsEnabled = false;
                    this.resettingTips = false;
                }
            }
        },

        async enableTesting() {
            this.testingEnabled = true;
            await enableTipTesting();
            notifyWithText("Debug testing functions enabled. See javascript console.");
        },

        downloadTipsCsv() {
            const tipObjects = Object.values(tips);
            const csv = new CSV();

            csv.addLine([
                "ID",
                "OnboardingSequence",
                "ChanceToShow",
                "HasAction",
                "Page",
                "Theme",
                "Text",
                "Description"
            ]);

            for (const tip of tipObjects) {
                if (tip.noExport) continue;
                csv.addLine([
                    tip.id,
                    tip.onboardingSequence ?? '',
                    tip.chance ?? '',
                    !!tip.action,
                    tip.page ?? '',
                    tip.theme?.name ?? '',
                    tip.text,
                    tip.description
                ]);
            }
            csv.download("all-tips");
        }
    }
}
</script>

<style scoped lang="sass">

    .tips-and-help-settings
        color: var(--be-colour-text-dark)
        padding-right: 20px

        &__table
            border: 1px solid #1a1a1a
            width: 100%
            margin-top: 25px

            td
                border-bottom: 1px solid #1a1a1a
                height: 40px

            tr:nth-child(1)
                display: none // Don't display column headers

            td:nth-child(1)
                padding-left: 10px
                width: 60%

            td:nth-child(2)
                padding-left: 50px

            input[type=text]
                margin-top: 4px

            input[type=checkbox]
                margin-bottom: 5px
                transform: scale(1.3)

            .reset-tips-button
                width: 160px

</style>