import ObjectStore from './ObjectStore'
import { mash } from '../Services'
import {currentAccountCode} from "@/app/utils/Account";

/**
 * Fetches and caches teams for the selected account.
 * @deprecated
 */
export default class TeamStore extends ObjectStore {

    constructor() {
        super()
        this.refreshCall = null
        this.userTeams = null
    }

    /**
     * Refresh our teams. Returns promise. This does not start a new fetch if one is already in progress.
     */
    refresh(soft) {
        if (this.refreshCall) return this.refreshCall
        if (this.list && soft) return Promise.resolve(this.list)
        return this.refreshCall = mash.get("/rest/accounts/" + this.accountCode + "/teams")
            .then(res => {
                this.list = []
                res.data.forEach(o => this.list.push(this.add(o)))
                this.userTeams = null
                return this.list
            }).finally(() => this.refreshCall = null)
    }

    /**
     * Lookup a team by id. If we don't have it already a placeholder team is returned and it is fetched in the
     * background.
     */
    get(id) {
        let team = this.byId[id]
        if (!team) {
            team = { id: id, name: "" + id, description: null, userIds: null }
            this.byId[team.id] = team
            this.refresh()
        }
        return team
    }

    update(team, payload) {
        return mash.put("/rest/accounts/" + currentAccountCode() + "/teams/" + team.id, payload)
            .then(res =>  {
                Object.assign(team, res.data)
                this.userTeams = null
            })
    }

    delete(teamId) {
        return mash.delete("/rest/accounts/" + currentAccountCode() + "/teams/" + teamId)
            .then(res => {
                delete this.byId[teamId]
                let i = this.list.findIndex(r => r.id === teamId)
                if (i >= 0) this.list.splice(i, 1)
                this.userTeams = null
            })
    }

    create(payload) {
        return mash.post("/rest/accounts/" + currentAccountCode() + "/teams", payload)
            .then(res => {
                let team = res.data;
                if (this.add(team) === team) this.list.push(team)
                this.userTeams = null
                return team
            })
    }

    /**
     * Returns array of teams for the user, empty array if none.
     */
    getUserTeams(userId) {
        if (!this.userTeams) {
            this.userTeams = { }
            this.list.forEach(team => {
                team.userIds.forEach(userId => {
                    let a = this.userTeams[userId]
                    if (!a) this.userTeams[userId] = [team]
                    else a.push(team)
                })
            })
        }
        return this.userTeams[userId] || []
    }
}