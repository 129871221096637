var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":31}}}) : helper)))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>\n                You can adjust the sample date range to any dates that fall within\n                the dates of your search. Each day is divided into 4 segments,\n                each 6 hours long. The first 20 mentions of each segment is\n                returned. In total, up to 80 mentions will be returned for each\n                day. If you have already sampled a date range the sample will run\n                again and return the same results.\n            </p>\n            <p>\n                The current filter will return a sample of <span class=\"volume-label\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"volumeLabel") || (depth0 != null ? lookupProperty(depth0,"volumeLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"volumeLabel","hash":{},"data":data,"loc":{"start":{"line":21,"column":86},"end":{"line":21,"column":101}}}) : helper)))
    + "</span>.\n                Click <i>Sample</i> to start sampling.\n            </p>\n            <div id=\"filter-region\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>Select a sample date range</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"page row-fluid\">\n        <div class=\"main-form\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"dialog-button-bar\">\n        <a class=\"ok btn btn-primary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"okDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":37},"end":{"line":29,"column":70}}})) != null ? stack1 : "")
    + "\">Sample</a>\n    </div>\n</div>\n";
},"useData":true});