import {readonly} from "@vue/runtime-dom";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";

export class ImageBlock {

    constructor({api, data, readOnly}) {
        this.api = api;
        this.wrapper = document.createElement("div");
        this.wrapper.style.color = 'black';

        this.src = undefined;
        this.imageEl = document.createElement("div");
        this.img = document.createElement("img");
        this.img.alt = "Image";
        this.imageEl.appendChild(this.img);

        this.caption = document.createElement("input");
        this.caption.type = "text";
        this.caption.placeholder = "Caption";
        this.caption.setAttribute("class", "image-block-caption");

        let label = "Browse";
        let message = "No image selected";
        if (data.src) {
            this.img.src = this.src = data.src;
            this.caption.value = data.caption;
            this.imageEl.style.width = data.width;
            label = "Edit"
            message = this.fileName = data.fileName;
        } else {
            this.imageEl.style.opacity = '0';
            this.caption.style.opacity = '0';
        }

        const labelElement = document.createElement("label");
        labelElement.setAttribute("class", "image-block-upload-label");
        labelElement.setAttribute("for", "upload-image");

        const uploadElement = document.createElement("span");
        uploadElement.setAttribute("class",'btn upload-image-btn');
        uploadElement.textContent = label;

        const spanElement = document.createElement("span");
        spanElement.textContent = message;

        labelElement.appendChild(uploadElement);
        labelElement.appendChild(spanElement);

        this.addImage = document.createElement("div");
        this.addImage.innerHTML = `<input class="image-block-upload-el" type="file" name="image" accept="image/png, image/jpeg" id="upload-image" />`;
        this.addImage.addEventListener('change', () => {
            const file = this.addImage.querySelector('input').files[0];
            if (file.size > 2097152){
                notifyUserOfError("File can not be larger than 2mb");
                return;
            }

            this.fileName = file.name;
            spanElement.textContent = file.name;
            const reader = new FileReader();
            let base64String;
            reader.onload = () => {
                base64String = btoa(reader.result);
                this.img.src  = this.src = `data:image/png;base64,${base64String}`;
                this.imageEl.style.opacity = '1';
                this.caption.style.opacity = '1';
                label = 'Edit';
            };
            reader.readAsBinaryString(file);
        })

        this.wrapper.appendChild(this.imageEl);
        if (!readOnly) {
            this.wrapper.appendChild(this.caption);
            this.wrapper.appendChild(labelElement);
            this.wrapper.appendChild(this.addImage);

            this.imageEl.style.resize = 'inline';
            this.imageEl.style.overflow = 'auto';
        } else {
            const captionReadOnly = document.createElement("div");
            captionReadOnly.textContent = data.caption;
            this.wrapper.appendChild(captionReadOnly);
        }
    }

    static get toolbox(){
        return {
            title: 'Image',
            icon: '<i class="icon-picture-1"></i>',
        }
    }

    render(){
        return this.wrapper;
    }

    static get isReadOnlySupported() {
        return !0;
    }

    save(t){
        return {
            fileName: this.fileName,
            src: this.src,
            caption: this.caption.value,
            width: this.imageEl.style.width
        }
    }


}