import {toEmojiHtml} from "@/app/utils/Format";
import _ from 'underscore';
import {isObject} from "@/app/utils/Util";

/**
 * A responsive view useful for reporting on text values that you will obtain from
 * chicken, using a filter.
 *
 * <p>
 * Implement this by overriding the refresh method and setting the text to be displayed
 * using the text method.
 *
 * <p>
 * As with ChartItemView, you can show and hide the loader using showLoader() and hideLoader()
 */
Beef.FilteredTextView = Beef.ConstrainedWidgetView.extend({
    template: require("@/dashboards/widgets/Text-Value.handlebars"),

    modelEvents:Object.assign({
        "change:_effectiveFilter":   "refresh"
    }, Beef.ConstrainedWidgetView.prototype.modelEvents),

    initialize: function() {
        this.listenTo(this.model.generalData, "change:textvalue", this.onRender, this);
        this.model.generalData.set({'textvalue': ''}, {silent: true});
        this.refresh();
    },

    /**
     * A getter / setter for the text to be displayed. The set text can be an array of text values,
     * in which case they are all displayed in their own cells.
     */
    text: function() {
        if (arguments.length === 0) return this.model.generalData.get('textvalue');

        var v = arguments[0];
        this.model.generalData.set('textvalue', v);
        // force a change for an array as backbone doesn't consider it to change in the 'deep' sense
        if (Array.isArray(v)) this.model.generalData.trigger('change:textvalue');

        return this;
    },

    renderImpl: function() {
        var data = this.model.generalData.get('textvalue');
        if (!Array.isArray(data)) data = [ data ];

        // Handles determining delays for our transitions.
        var delay = function(d, i) { return i * (300 / data.length); };

        // Handles animating a selection.
        var animate = function(selection) {
            selection.style('opacity', 0).transition().delay(delay).duration(750).style('opacity', 1);
        };

        // Select and set data
        var selection = d3.select(this.$el[0]).select('.text-value').selectAll('.value').data(data);

        // Remove unwanted items.
        selection.exit().remove();

        // Add new items.
        selection.enter().append('tr').attr('class', 'value');

        // Fiddling with existing items.
        selection.selectAll('.key').remove();
        selection.selectAll('.text').remove();
        var that = this;
        selection.each(function(d) {
            var select = d3.select(this);
            select.text('');
            that.renderRow(select, d);
        });

        toEmojiHtml(selection.node());

        if (!Beef.Widget.isDisableAnimation()) selection.call(animate);

        this.endOfRender();
    },

    /**
     * Render a row in the table.
     * @param select d3 selection for the tr
     * @param d data item to render
     */
    renderRow: function(select, d) {
        select.append('td');
        select = select.select('td');

        if (isObject(d)) {
            select.append('h3').attr('class', 'key').text(d.key);
            select.append('div').attr('class', 'text').text(d.value);
        } else {
            select.text(d);
        }
    },

    refresh: function() {
    },

    //-----------------------

    showLoader: function() {
        this.model.generalData.set('_loading', true);
    },

    hideLoader: function() {
        this.model.generalData.set('_loading', false);
    },

    //-----------------------

    endOfRender: function() {
        this.model.generalData.set('_completed', true);
    }

});
