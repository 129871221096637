import {notifyUser, notifyWithHtml, notifyWithText} from "@/app/framework/notifications/Notifications";
import {showAskDialog, showErrorDialog, showInfoDialog} from "@/app/framework/dialogs/Dialog";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import {getMentions} from "@/data/Grouse";
import {editDigests} from "@/app/Permissions";
import {mapActions, mapState} from "vuex";
import {escapeExpression, escapeHtml} from "@/app/utils/StringUtils";

export const notificationMixin = {
    data() {
        return {
            expanded: !!this.notification.expanded,
            updating: false,
            isNew: !!this.notification._new,
            warnings: [],
            nameEditMode: false,
            tempName: '',
            tempDescription: '',
            descriptionEditMode: false,
            justSubscribed: false,
            numRecipientsInAccount: 0,
            canEditNotifications: editDigests()
        }
    },

    created() {
        // If this dashboard started with a report, we don't want to
        // disable the report UI if the user clears the report info.
        // _dashboard is the variable that handles that.
        if (this.notification?.reportId) {
            this.notification._dashboard = true;
        }
    },

    mounted() {
        this.calculateNumRecipientsInAccount();
    },

    watch: {
        'notification.recipients'() {
            this.calculateNumRecipientsInAccount();
        },
        'notification.expanded'() {
            this.expanded = !!this.notification.expanded;
        }
    },

    computed: {
        ...mapState(['user', 'account']),

        isDefault() {
            return !!this.notification.externalId;
        },

        enabledAndEditable() {
            return this.enabled && this.canEditNotifications
        },

        enabled() {
            return !this.updating && !this.notification.deleted
        },

        isSubscribed() {
            return this.indexOfLoggedInUser >= 0
        },

        indexOfLoggedInUser() {
            const recipients = this.notification.recipients;
            if (!recipients) return -1;
            const userEmail = this.user.email;
            for (let i = 0; i < recipients.length; i++) {
                let e = recipients[i].toLowerCase();
                let j = e.indexOf('<');
                if (j >= 0) e = e.substring(j + 1);
                j = e.indexOf('>');
                if (j >= 0) e = e.substring(0, j);
                if (userEmail === e) return i;
            }
            return -1;
        },

        tag() {
            if (!this.notification.externalId) return null;
            const split = this.notification.externalId.split(':');
            return split[split.length - 1].toUpperCase();
        },
    },

    methods: {
        ...mapActions('digests', {
            storeCreateDigest: 'createDigest',
            storeDeleteDigest: 'deleteDigest',
            storeUpdateDigest: 'updateDigest'
        }),
        ...mapActions('accountUsers', {
            getUserByEmail: 'getUserByEmail',
            refreshAccountUsers: 'refreshAccountUsers'
        }),

        async viewDashboard() {
            if (this.notification.reportId) {
                if (this.isNew) {
                    const proceed = await showAskDialog(
                        "Cancel creating dashboard",
                        "Viewing this dashboard will cancel the creation of this notification. Do you want to go to the dashboard panel?",
                        true);
                    if (!proceed) return;
                }
                Beef.router.navigate(`/${this.account.code}/dashboards/${this.notification.reportId}`, {trigger: true});
            }
        },

        async previewMentions() {
            if (!this.expanded) return;
            if (!this.user.admin) return;
            if (!this.notification.id) {
                showInfoDialog("This notification hasn't been created yet.");
                return;
            }

            gotoMentionPanel(`(${this.notification.filter}) and published inthelast month`);
        },

        async previewInCorn() {
            if (!this.expanded) return;
            if (!this.user.admin) return;
            if (!this.notification.id) {
                showInfoDialog("This notification hasn't been created yet.");
                return;
            }

            const mentions = await getMentions(`(${this.notification.filter}) and published inthelast month`, null, 10);
            if (!mentions.length) {
                showErrorDialog("There are no mentions matching this notifications filter in the account");
                return
            }

            const ids = mentions.map(m => m.id).join(',');
            const cornAddress = this.account.dev ? 'http://localhost:8023' : 'https://corn.brandseye.com';
            let url = this.notification.type === "SPIKE_ALERT"
                ? `${cornAddress}/preview/spikes/email?accountCode=${this.account.code}&digestId=${this.notification.id}&bucket=day`
                : `${cornAddress}/preview/show?accountCode=${this.account.code}&digestId=${this.notification.id}&mentionIds=${ids}`;

            window.open(url, "_blank");
        },

        expand(val) {
            val = val ?? !this.expanded;
            if (this.isNew && !val) {
                this.cancelCreation();
                return;
            }
            if (this.notification.deleted && val) return;
            this.expanded = val;
            if (!this.notification.id) {
                this.notification._expanded = val;
            } else {
                this.$store.dispatch("digests/updateNotificationFields", {id: this.notification.id, '_expanded': val});
            }

            this.$emit('expanded', this.expanded);

            if (!this.expanded) {
                this.cancelEditDescription();
                this.cancelEditName();

            }
        },

        editDescription() {
            if (!this.enabledAndEditable) return;
            this.tempDescription = this.notification.description || '';
            this.descriptionEditMode = true;
            this.$nextTick(() => this.$refs.descriptionEditInput.focus());
        },

        cancelEditDescription() {
            this.tempDescription = '';
            this.descriptionEditMode = false;
        },

        async updateDescription() {
            await this.updateField(
                "description",
                this.tempDescription,
                "description",
                this.tempDescription ? "Description updated" : "Description removed",
                this.cancelEditDescription()
            )
        },

        cancelCreation() {
            this.$emit('creation-cancelled')
        },

        async saveNew() {
            if (this.notification.id) {
                await showErrorDialog("This notification has already been saved");
                return;
            }

            this.updating = true;
            try {
                this.isNew = this.notification._new = false;
                const created = await this.storeCreateDigest(this.notification);
                this.$emit('created', created);
                notifyWithHtml(
                    `Notification ${escapeExpression(this.notification.name)} has been created`,
                    null,
                    '<i class="symbol-notification"></i>'
                )
            } catch(e) {
                console.error(e);
                await showErrorDialog("We were unable to save this notification");
            } finally {
                this.updating = false;
                await this.checkWarnings();
                this.$emit('update-warnings');
            }
        },

        isUser(address) {
            return address.includes(this.user.email);
        },

        async subscribe() {
            if (!this.updating && this.isSubscribed) {
                notifyWithText(
                    "You are subscribed to the notification",
                    () => this.unsubscribe(),
                    '<i class="symbol-notification"></i>'
                )
                return;
            }

            const recipients = Array.from(this.notification.recipients ?? []);
            recipients.push(`${this.user.firstName} ${this.user.lastName} <${this.user.email}>`)
            await this.updateField(
                "recipients",
                recipients,
                "recipients",
                "You have been subscribed to the notification",
                () => this.justSubscribed = true
            );

            if (this.warnings.length && this.notification.active) {
                notifyUser({
                    icon: '<i class="symbol-warning"></i>',
                    message: "The notification you have subscribed to has warnings. Please contact support.",
                    longDelay: true
                })
            }

            if (!this.notification.active) {
                notifyUser({
                    icon: '<i class="symbol-warning"></i>',
                    message: "You have subscribed to an inactive notification that won't send mail.",
                    longDelay: true,
                    action: {
                        name: "Activate",
                        tooltip: "Active notifications will send out emails",
                        method: () => this.activateNotification()
                    }
                })
            }
        },

        async unsubscribe() {
            const index = this.indexOfLoggedInUser;
            if (index < 0) return;

            const value = Array.from(this.notification.recipients);
            value.splice(index, 1);

            await this.updateField(
                "recipients",
                value,
                "recipients",
                "You have been unsubscribed from this notification",
                null,
                () => this.subscribe(),
                "We were unable to unsubscribe you"
            );
        },

        async setAnonymise(anonymise) {
            if (this.updating) return;

            if (this.notification.id && !anonymise) {
                const turnOff = await showAskDialog(
                    "Show mention details?",
                    "Turning off anonymisation will allow this email's recipients to see author information when the notifications are sent. Are you sure you want to proceed?",
                    true,
                        'symbol-notification'
                    )
                if (!turnOff) return;
            }

            await this.updateField(
                "anonymise",
                !!anonymise,
                "anonymise",
                anonymise
                    ? `Notification <strong>${escapeExpression(this.notification.name)}</strong> will have their mentions anonymised when sent`
                    : `Notification <strong>${escapeExpression(this.notification.name)}</strong> will no longer anonymise their mentions`,
                null,
                () => this.setAnonymise(!anonymise),
                "We were unable to deactivate this notification"
            )
        },

        async deactivateNotification(noQuestion) {
            if (this.updating || this.isNew) return;

            if (!noQuestion) {
                const proceed = await showAskDialog(
                    "Deactivate",
                    `Would you like to stop this notification from sending emails to all its recipients? You can easily undo this later.`,
                    true
                );

                if (!proceed) return;
            }

            await this.updateField(
                "active",
                false,
                "active",
                `Notification <strong>${escapeExpression(this.notification.name)}</strong> is will no longer send emails`,
                null,
                () => this.activateNotification(),
                "We were unable to deactivate this notification"
            )
        },

        async activateNotification() {
            if (this.updating || this.isNew) return;
            await this.updateField(
                "active",
                true,
                "active",
                `Notification <strong>${escapeExpression(this.notification.name)}</strong> is now active and will begin to send emails`,
                null,
                () => this.deactivateNotification(true),
                "We were unable to activate this notification"
            );
        },

        async deleteNotification() {
            if (this.updating || this.isNew) return;

            const proceed = await showAskDialog(
                "Delete notification",
                `Are you sure that you want to delete this notification? Doing this cannot be undone at a later date, and will stop all recipients from receiving the notification.`,
                true
            );

            if (!proceed) return;
            this.updating = true;
            try {
                await this.storeDeleteDigest(this.notification.id);
                this.notification.deleted = true;
                notifyWithHtml(
                    `The notification <strong>${escapeExpression(this.notification.name)}</strong> has been deleted`,
                    () => {
                        this.storeUpdateDigest(this.notification);
                        this.notification.deleted = false;
                    },
                    '<i class="symbol-notification"></i>'
                )
            } catch(e) {
                console.error(e);
                await showErrorDialog("We were unable to delete this notification");
            } finally {
                this.updating = false;
            }

            this.expand(false);
        },

        async setMinimumAlertSize(value) {
            const oldValue = this.notification.minVolume ?? 1;
            await this.updateField("minVolume",
                value,
                "minimum volume",
                escapeHtml`The <strong>minimum volume</strong> is now <strong>${value}</strong>`,
                null,
                () => this.setMinimumAlertSize(oldValue),
                "Unable to set the alert minimum volume"
            )
        },

        async setBucket(value) {
            const oldValue = this.notification.bucket ?? "AUTO";
            let message = "Now bucketing <strong>automatically</strong>";
            if (value === "DAY") message = "Now bucketing <strong>daily</strong>";
            if (value === "HOUR") message = "Now bucketing <strong>hourly</strong>";

            await this.updateField("bucket",
                value,
                "bucket",
                message,
                null,
                () => this.setBucket(oldValue),
                "Unable to set the notification sensitivity"
            )
        },

        async removeRecipient(recipient) {
            await this.updateField("recipients",
                this.notification.recipients?.filter(r => r !== recipient) ?? [],
                "recipients",
                `<strong>${escapeExpression(recipient)}</strong> has been unsubscribed from the notifications`,
                null,
                () => this.addRecipient(recipient),
                "Unable to unsubscribe this recipient"
                )
        },

        async addRecipient(recipient) {
            if (!this.notification.recipients) {
                await this.$store.dispatch("digests/updateNotificationFields", {id: this.notification.id, recipients: []});
            }

            // Don't insert duplicate recipients.
            if (this.notification.recipients.includes(recipient)) {
                notifyWithHtml(
                    `<strong>${escapeExpression(recipient)}</strong> is already subscribed to the notification`,
                    () => this.removeRecipient(recipient),
                    '<i class="symbol-notification"></i>'
                );
                return;
            }

            const recipients = Array.from(this.notification.recipients || []);
            recipients.push(recipient);
            await this.updateField(
                "recipients",
                recipients,
                "recipients",
                `<strong>${escapeExpression(recipient)}</strong> has been subscribed to the notification`,
                null,
                () => this.removeRecipient(recipient),
                "Unable to subscribe the recipient"
            )
        },

        async updateFilter(filter, notificationMessage) {
            await this.updateField(
                "filter",
                filter ?? "published inthelast 24hours",
                "filter",
                notificationMessage
            )
        },

        /**
         * Updates arbitrary fields. Returns true if an update occurred, false otherwise.
         * @returns {Promise<boolean>}
         */
        async updateField(field, value, label,
                          optionalEscapedNotification,
                          optionalUiCleanup,
                          optionalUndo,
                          optionalErrorMessage) {
            if (this.updating) return false;

            const oldValue = this.notification[field];
            if (optionalUiCleanup) await optionalUiCleanup();
            if (oldValue === value) return false;

            let forUpdate = null;
            if (this.notification.id) {
                // Here it's a notification from our store.
                const notificationUpdate = { id: this.notification.id };
                notificationUpdate[field] = value;
                forUpdate = await this.$store.dispatch("digests/updateNotificationFields", notificationUpdate);
            }

            if (!forUpdate) {
                // Here it's a not yet created notification object,
                // or are working with one that has been deleted.
                forUpdate = this.notification;
                this.$set(this.notification, field, value);
            }


            if (this.isNew) {
                await this.checkWarnings();
                this.$emit('update-warnings');
                return true;
            }

            this.updating = true;
            try {
                await this.storeUpdateDigest({id: this.notification.id, [field]: forUpdate[field]});
                notifyWithHtml(
                    optionalEscapedNotification ?? "The notification's " + escapeExpression(label) + " has been updated",
                    optionalUndo ?? (() => { this.updateField(field, oldValue, label); }),
                    "<i class='symbol-notification'></i>"
                );
                return true
            } catch (e) {
                console.error(e);
                this.notification[field] = oldValue;
                if (e.response?.status === 422) {
                    await showErrorDialog(e.response.data.error);
                } else {
                    await showErrorDialog(optionalErrorMessage ?? "We were unable to update the notification's " + escapeExpression(label));
                }

                return false;
            } finally {
                this.updating = false;
                if (this.checkWarnings) await this.checkWarnings();
                this.$emit('update-warnings');
            }
        },

        editName() {
            if (!this.expanded) {
                this.expanded = true;
                return;
            }

            if (!this.enabledAndEditable || this.isDefault) return;

            this.tempName = this.notification.name || '';
            this.nameEditMode = true;
            this.$nextTick(() => this.$refs.nameEditInput.focus());
        },

        cancelEditName() {
            this.tempName = '';
            this.nameEditMode = false;
        },

        async updateName() {
            await this.updateField("name", this.tempName,
                "name",
                `The notification's name is now <strong>${escapeExpression(this.notification.name)}</strong>`,
                () => this.cancelEditName()
                );
        },

        async calculateNumRecipientsInAccount() {
            if (!this.notification?.recipients) this.numRecipientsInAccount = 0;
            else if (this.user.admin) this.numRecipientsInAccount = this.notification.recipients?.length ?? 0;
            else {
                this.numRecipientsInAccount = 0;
                await this.refreshAccountUsers();
                for (const r of this.notification.recipients) {
                    const inAccount = await this.getUserByEmail(r);
                    if (inAccount) {
                        this.numRecipientsInAccount++;
                    }
                }
            }
        },



    }
};