import {createBrandTagConverterFactory} from "@/components/pickers/brands/BrandPickerUtilities";

Beef.module("HistoricalSearch.BrandPhrasesPopup").addInitializer(function() {

    this.Model = Backbone.Model.extend({});

    this.View = Beef.BoundItemView.extend({
        template: require("@/historical-search/popup/setup/brand-phrases/BrandPhrasesPopup.handlebars"),

        attributes: {
            class: "dialog add-brand-phrases-setup setup-popup"
        },

        regions: {
            phraseRegion: ".phrase-region"
        },

        events: {
            "click .dialog-title .close": "close"
        },

        modelEvents: {
            "change:brand": "updatePhrasePreview"
        },

        templateHelpers: function() {
            return {};
        },

        bindings: function() {
            return {
                brand: {
                    converterFactory: createBrandTagConverterFactory("Brands"),
                    elAttribute: "data-value"
                }
            }
        },

        onFirstRender: function() {
            Beef.BrandPicker.attach(this, ".brand", "brand");
            var model = new Backbone.Model({
                accountCode: this.model.get("accountCode"),
                job: this.model.get("job")
            });
            var view = new Beef.HistoricalSearch.BrandPhrasesPopup.PhrasePreview.View({ model: model });
            this.phraseRegion.show(view);
        },

        close: function() {
            Beef.Popup.closePopups(this);
            Beef.BoundItemView.prototype.close.call(this);
        },

        /**
         * Updates the phrase preview region to show the phrases that will be added from the currently
         * selected brands.
         */
        updatePhrasePreview: function() {
            if (this.phraseRegion.currentView) {
                var brandStr = this.model.get("brand");
                var brands = brandStr ? brandStr.split(' ') : [];
                this.phraseRegion.currentView.updatePreview(brands);
            }
        },

        /**
         * Returns the phrases shown in the phrase region.
         */
        getPhrases: function () {
            return this.phraseRegion.currentView ? this.phraseRegion.currentView.model.get("phrases") : [];
        }
    });
});
