<template>
    <section>
        <SpikeSummaries :topics="risks"
                        :loading="loading"
                        class="trending-risk__summaries"/>

        <div class="trending-risk__table-area">
            <div class="trending-risk__table">
                <trend-table :topics="risks"
                             :disabled="loading"
                             show-new
                             class="trending-risk__table"
                             with-footer
                             wordtype="Risk factor"
                             with-average
                             with-max-date
                             @seeMentions="seeMentions($event)"
                             @seeWordcloud="seeWordcloud($event)"
                             @seeAuthors="seeAuthors($event)"/>
                <most-frequent-topic v-if="riskFilter" :filter="riskFilter" class="trending-risk-most-frequent animated fadeIn"/>
                <loading-message v-if="loading && !risks.length" class="trending-risk__table">
                    <h2>Reading your trending <rpcs-tag code="RISK" full/> tags...</h2>
                </loading-message>
                <short-loading v-if="loading && risks.length"/>
                <no-topics-message v-if="!loading && !risks.length">
                    No trending risk
                </no-topics-message>
            </div>


            <navigation-controls :loading="loading" @filter="additionalFilter = $event" :show-risk="false"/>
        </div>
    </section>
</template>

<script>

import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {getTrendingRiskFilter, loadTrendingRisk} from "@/app/toplevel/explore/overview/ExploreUtilities";
import TrendTable from "@/app/toplevel/explore/overview/TrendTable";
import LoadingMessage from "@/components/LoadingMessage";
import NavigationControls from "@/app/toplevel/explore/overview/components/NavigationControls";
import MostFrequentTopic from "@/app/toplevel/explore/overview/components/MostFrequentTopic";
import OverviewDescription from "@/app/toplevel/explore/overview/components/OverviewDescription";
import {mapGetters} from "vuex";
import {formatBrandName} from "@/app/utils/Format";
import RpcsTag from "@/components/tags/RpcsTag";
import ShortLoading from "@/app/toplevel/explore/overview/components/ShortLoading";
import NoTopicsMessage from "@/app/toplevel/explore/overview/components/NoTopicsMessage";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import SpikeSummaries from "@/app/toplevel/explore/overview/SpikeSummaries.vue";

export default {
    components: {
        SpikeSummaries,
        NoTopicsMessage,
        ShortLoading, RpcsTag, MostFrequentTopic, NavigationControls, TrendTable, LoadingMessage},
    props: {
        brand: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: false,

            risks: [],
            riskFilter: null,
            additionalFilter: null
        }
    },

    computed: {
        ...mapGetters('explorePanel', ['dateFilter', 'englishDate']),
    },

    watch: {
        brand() {
            this.loadRisks();
        },
        additionalFilter() {
            this.loadRisks();
        },
        dateFilter() {
            this.loadRisks();
        },
    },

    mounted() {
        this.loadRisks();
    },

    methods: {
        formatBrandName,

        seeMentions(risk) {
            let filter = appendFiltersReadably(this.riskFilter, `segment is ${risk.topic.id}`);
            showMentions(filter, risk.topic.name + " (by engagement)", true);
        },

        seeWordcloud(risk) {
            let filter = appendFiltersReadably(this.riskFilter, `segment is ${risk.topic.id}`);
            showWordcloud(
                filter,
                `Mentions for '${risk.topic.name}'`
            );
        },

        seeAuthors(risk) {
            let filter = appendFiltersReadably(this.riskFilter, `segment is ${risk.topic.id}`);
            gotoAuthorsPanel(filter);
        },

        async loadRisks() {
            if (!this.brand) return;
            if (this.loading) {
                console.warn("Already loading risks");
                return;
            }

            try {
                this.loading = true;
                this.riskFilter = null;
                // this.risks = []; Do not clear, so that we have a better loading experience.
                this.risks = await loadTrendingRisk(this.brand.id, this.dateFilter, this.additionalFilter);

                // Don't need a loading signal from here onwards.
                this.loading = false;
                this.riskFilter = await getTrendingRiskFilter(this.brand.id, this.dateFilter, this.additionalFilter);
            } finally {
                this.loading = false;
            }

        },


    }
}

</script>

<style scoped lang="sass">

.trending-risk__table
    width: var(--max-page-with)
    margin-right: 20px

.trending-risk__table-area
    width: max-content
    margin: 0 auto
    display: flex
    min-height: 400px

.trending-risk-most-frequent
    display: block
    text-align: center
    color: var(--be-colour-text-dark)
    margin-top: 10px


.trending-risk__summaries
    max-width: var(--max-page-with)
    margin-inline: auto
    margin-block: 1rem

</style>