import Vue from "vue"

let reloadListeners
if (module.hot) {   // running in dev with hot-reloading
    reloadListeners = []
    window.beWidgetReloaded = function(name) { reloadListeners.forEach(cb => cb(name)) }
}

Beef.module("VuejsWidget").addInitializer(function(startupOptions) {

    /**
     * Wrapper for widgets implemented as Vue.js components. See WidgetRegistry for more info.
     */
    this.View = Backbone.Marionette.ItemView.extend({

        cache: {},

        getTemplate() {
            return () => '<div></div>'
        },

        initialize(options) {
            // Check if we should enable a get CSV option.
            if (options?.type?.vueComponent?.methods?.getCsv) {
                this.getCsv = () => this.vm.getCsv();
            }
        },

        onRender() {
            // make sure all default attributes have values cloning arrays and objects
            let type = this.options.type
            let attrs = this.model.attributes
            Object.keys(type).forEach(key => {
                if (!IGNORED_WIDGET_TYPE_KEYS.has(key) && attrs[key] === undefined) {
                    let v = type[key]
                    if (typeof v === "object") v = JSON.parse(JSON.stringify(v))
                    Vue.set(attrs, key, v)
                }
            })

            // make sure _effectiveFilter is reactive
            let filter = attrs._effectiveFilter
            delete attrs._effectiveFilter
            Vue.set(attrs, '_effectiveFilter', filter)

            const ComponentClass = Vue.extend(type.vueComponent)
            let props = { model: this.model }
            this.vm = new ComponentClass({propsData: props})
            setTimeout(() => {
                // keep our parentElement as this.$el becomes detached as soon as vue takes over our space and
                // we need to be able to wipe the dom and re-attach on reload
                if (!this._parentElement) this._parentElement = this.$el[0].parentElement
                this.vm.$mount(this.$el[0])
            }, 1)

            // this horrible hack is to automatically recreate the component when it is hot reloaded .. it is
            // a manually mounted root component so doesn't get reloaded automatically
            if (reloadListeners && !this._reloadListener) {
                this._reloadListener = name => {
                    if (this.vm && name === type.name) {
                        setTimeout(() => {
                            console.info("Reloading " + name)
                            this.vm.$destroy()
                            this.vm = null
                            this._parentElement.innerText = ''
                            let div = document.createElement("div")
                            this._parentElement.appendChild(div)
                            this.$el = $(div)
                            this.render()
                        }, 1)
                    }
                }
                reloadListeners.push(this._reloadListener)
            }


            // #toCSV's existence is used to test whether to turn on / off a menu option for CSV
            // export. We need to dynamically create this method.
            if (this.vm?.toCSV) {
                this.toCSV = () => {
                    if (!this.vm?.toCSV) return null;
                    return this.vm.toCSV();
                }
            }

            if (this.vm?.viewMentions) {
                this.viewMentions = () => this.vm?.viewMentions();
            }

            if (this.vm?.viewAuthors) {
                this.viewAuthors = () => this.vm?.viewAuthors();
            }
        },

        onClose() {
            this.vm.$destroy()
            this.vm = null
            if (this._reloadListener) {
                let i = reloadListeners.indexOf(this._reloadListener)
                if (i >= 0) reloadListeners.splice(i, 1)
            }
        },

        async refresh() {
            if (this.vm) this.vm.refresh()
        }
    })
});

const IGNORED_WIDGET_TYPE_KEYS = new Set(['vueComponent', 'vueSettingsDialog', 'View', 'description'])
