<template>
    <tr class="activity-list-item">
        <td class="none-breaking-text activity-list-item__user">
            <deq-user :user="item.user" />
        </td>
        <td class="none-breaking-text date">
            <deq-date :date="item.date" format="D MMM YYYY HH:mm" />
        </td>
        <td class="none-breaking-text auxiliary-column">
            <span class="muted">{{ago}}</span>
        </td>
        <td class="none-breaking-text">
            <span v-if="item.logType && item.logType.label">
                {{ item.logType.label}}
            </span>
            <old-tooltip v-if="link" :label="tooltip">
                <a :href="link" @click.prevent.stop="navigate">
                    <i class='icon icon-forward-2'></i>
                </a>
            </old-tooltip>
        </td>
        <td class="none-breaking-text auxiliary-column">
            <span class="muted" v-if="item.repeatCount"> x {{ item.repeatCount }}</span>
        </td>
        <td width="100%">
            <div class="activity-list-item__description">
                <highlight-text :string="item.description" :query="searchString" />
                <be-button v-if="logAction && user.admin" link @click="logAction.action()">{{ logAction.description }}</be-button>
            </div>
            <div v-if="user.admin" class="muted">
                <highlight-text :string="item.comment" :query="searchString"/>
            </div>
        </td>
    </tr>
</template>

<script>
import moment from 'moment';
import {mapState} from "vuex";
import OldTooltip from "@/components/tooltip/OldTooltip";
import DeqDate from "@/components/formatters/DeqDate";
import DeqUser from "@/components/formatters/DeqUser";
import HighlightText from "@/components/formatters/HighlightText";
import {gotoMentionPanel} from "@/app/toplevel/mentions/MentionUtilities";
import BeButton from "@/components/buttons/BeButton";

export default {
    name: "ActivityItem",

    components: {
        BeButton,
        HighlightText,
        DeqUser,
        OldTooltip,
        DeqDate
    },

    props: {
        item: Object,
        searchString: String
    },

    data() {
        return {}
    },

    computed: {
        ...mapState(['account', 'user']),

        ago() {
            return moment(this.item.date).fromNow()
        },
        link() {
            const item = this.item;
            switch(item.logType?.id) {
                case 'DASHBOARD':
                    if (item.metadata?.id) {
                        return `/${this.account.code}/dashboards/${item.metadata.id}`
                    }
                    break;
                case 'ALERT_DIGEST':
                    if (item.metadata?.notificationId) {
                        return `/${this.account.code}/setup/notifications/${item.metadata.notificationId}`
                    }
                    break;
            }
            return null;
        },
        tooltip() {
            switch(this.item.logType.id) {
                case 'DASHBOARD':
                    return "Open this dashboard";
                case 'ALERT_DIGEST':
                    return "See this notification";
            }
            return null;
        },

        logAction() {
            if (this.item) {
                if (this.item.logType?.id === "DATA_DOWNLOAD" && this.item.metadata?.filter) {
                    return {
                        description: "View mentions",
                        action: () => gotoMentionPanel(this.item.metadata.filter, null, true)
                    }
                }
            }

            return null;
        }
    },

    methods: {
        navigate() {
            Beef?.router?.navigate(this.link, {trigger: true});
        }
    }

}
</script>

<style scoped lang="sass">
.activity-list-item
    cursor: default

    &__description
        display: flex
        justify-content: space-between

.auxiliary-column
    border-left: none
    text-align: right
    padding-right: 8px
.icon
    opacity: 0
    transition: opacity 0.03s
td
    word-break: break-word
    .date
        text-align: right
tr:hover
    .icon
        transition: opacity 0.1s
        transition-delay: 0.15s
        opacity: 0.5
        &:hover
            opacity: 1

.activity-list tbody tr:hover
    color: white

.activity-list-item__user
    min-width: 150px

::v-deep .highlight
    border-radius: 3px
    background: fade-out(#AEE15D, 0.77)
    color: #fff
    padding-right: 3px
    padding-left: 3px
    margin-left: -3px
    margin-right: -3px
</style>
