import _ from 'underscore';

/**
 * Displays a list of menu options and invokes methods on an object when they are selected.
 */
Beef.module("MiniMenu").addInitializer(function(startupOptions) {

    /**
     * A useful constant for adding a divider to a menu.
     */
    this.divider = { text: "ZZAASSDDCCCDDMSA" };

    /**
     * Show a mini menu in a popup. Options must be as follows:
     *
     * {
     *   template: .. handlebars template for the menu with "a" tags marked up with data-method="method to invoke",
     *   object: .. object to invoke methods on ...
     *   target: .. target element to display popup relative to
     *   preInvoke: .. optional function to invoke before invoking a menu option
     *   positions: .. a list of positions to attempt laying out the menu. Defaults to ['bottom-left']
     *   offsets: .. How to offset the menu. Defaults to { right: -1, top: +1 }
     *   dropdown: .. Set to true or false if this should be styled as a dropdown. Will examine style
     *   model: .. Provide any extra information that you would like to use in the template.
     *   onClose: .. A function to call when the mini menu closes.
     *   onRender: .. A function to call when rendering the menu.
     *   menu: .. An optional map listing default menu options and actions.
     * }
     *
     * The preInvoke function is given the menu option being invoked and must return true or the option will not be
     * invoked.
     *
     * Opening a basic menu is fairly easy:
     *
     * Beef.MiniMenu.show({
     *        target: $helpButton, // Where to show the menu
     *        object: [ Beef.Help, Beef.Motd ], // What objects to look for methods on
     *        menu: [
     *            {
     *                text: "Help",
     *                disabled: false,      // Can set a menu option as disabled or not
     *                tooltip: "Visit the DataEQ Documentation wiki",
     *                method: "openHelp"    // Use this method on one of the objects listed above
     *            },
     *            {
     *                text: contextualTitle,
     *                disabled: !entry,
     *                tooltip: entry && entry.tooltip  // Tooltips are completely optional
     *            },
     *            Beef.MiniMenu.divider,
     *            {
     *                text: "Contact support",
     *                tooltip: "Send an email query to support@dataeq.com",
     *                href: "mailto:support@dataeq.com",     // Instead of calling a method on click, you can just provide a href
     *                target: "_blank"                       // An optional target for the link.
     *            },
     *            {
     *                text: "See the message of the day",
     *                tooltip: "See the current message of the day, as well as all the previous ones",
     *                method: "show"
     *            }
     *        ]
     *    });
     */
    this.show = function(options) {
        Beef.Tooltip.close();  // Hide any tooltips that have popped up.

        options = Object.assign({
            positions: ['bottom-left'],
            offsets: { right: -1, top: +1 },
            model: new Backbone.Model(options.menu ? {menu: options.menu} : {}),
            template: require("@/app/framework/menu/MiniMenu.handlebars"),
            dropdown: !!options.menu
        }, options || {});

        var popup = new Beef.Popup.View({ closeOnHide: true, positions: options.positions, offsets: options.offsets, dropdown: options.dropdown });
        var view = new this.View({model: options.model, template: options.template, object: options.object, popup: popup, preInvoke: options.preInvoke});
        popup.setTarget(options.target);
        view.on("render", options.onRender);
        view.on("close", options.onClose);
        popup.show(view);
        return popup;
    };

    /**
     * Show a spinner and 'Loading ...' or some other message in a popup and return the popup. This is useful if
     * an action from a menu item might take a while. Options include most of those from show and:
     * {
     *   message: Message to display (default is 'Loading ...')
     *   target: .. target element to display popup relative to
     * }
     */
    this.showBusyMessage = function(options) {
        if (!options) options = {};
        options.model = new Backbone.Model({message: options.message || 'Loading ...'});
        if (!options.template) options.template = 'app/Loading';
        var popup = this.show(options);
        popup.model = options.model;
        popup.setMessage = function(message) {
            options.model.set('message', message);
        };
        return popup;
    };

    this.showIcons = function(menu, target) {
        Beef.Tooltip.close();  // Hide any tooltips that have popped up.

        return new Promise(function(resolve, reject) {
            try {
                var options = {
                    positions: ['left', 'right'],
                    autoclose: true,
                    dropdown: true,
                    closeOnHide: true,
                    offsets: { top: -5 },
                };

                var popup = new Beef.Popup.View({
                    closeOnHide: options.closeOnHide, positions: options.positions,
                    offsets: options.offsets, dropdown: options.dropdown,
                    extraCls: "mini-menu-icons__popup"
                });

                var view = new this.View({
                    model: new Backbone.Model(menu ? {menu: menu} : {}),
                    template: require("@/app/framework/menu/MiniIconMenu.handlebars"),
                    object: {
                        'item-clicked': function(target, ev) {
                            var index = parseInt(target.attr('data-index'));
                            var item = menu[index];
                            if (item.method) {
                                item.method(target, ev);
                            } else if (item.href) {
                                window.open(item.href, item.hrefTarget);
                            } else {
                                console.error("No method or href for menu item ", item);
                            }
                        }
                    },
                    popup: popup,
                    class: 'mini-menu-icons'
                });

                popup.setTarget(target);
                popup.show(view);
                resolve(popup);
            } catch (e) {
                console.error("Unable to show icon menu", e);
                reject(e);
            }
        }.bind(this));
    };

    /**
     * Create with {template: .., object: .., popup: ..} where object is the object to invoke methods on.
     */
    this.View = Backbone.Marionette.ItemView.extend({
        tagName: "div",
        attributes: function() {
            var classes = "mini-menu";
            if (this.options && this.options.class) {
                classes = this.options.class
            }
            return {
                class: classes
            }
        },
        modelEvents: {
            "change": "render"
        },
        events: {
            "click a": "click"
        },
        click: function(ev) {
            var $target = $(ev.currentTarget);
            var method = $target.attr('data-method');
            if ($target.hasClass("disabled")) return;
            if (method) {

                var test = function(object) {
                    if (object[method]) {
                        object[method]($target, ev);
                        return true;
                    }
                    return false;
                };

                if (this.options.popup) this.options.popup.hide();
                if (this.options.preInvoke && !this.options.preInvoke(method)) return;
                var objects = this.options.object;
                if (!Array.isArray(objects)) { objects = [objects]; }
                if (!_(objects).any(test)) {window.alert(method + " not implemented");}

            }
        }
    });
});