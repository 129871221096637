var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"selected-icon selectable-area\" title=\"Click to select/un-select the profile\" >\n        <i class=\"selected-icon-off icon-circle-empty\"></i>\n        <i class=\"selected-icon-on icon-ok-circled-1\"></i>\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " deleted";
},"5":function(container,depth0,helpers,partials,data) {
    return " with-selector";
},"7":function(container,depth0,helpers,partials,data) {
    return " edit";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"select") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "<span class=\"handle-and-avatar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deleted") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":60}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"select") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":60},"end":{"line":7,"column":104}}})) != null ? stack1 : "")
    + "\">\n    <img class=\"avatar"
    + ((stack1 = __default(require("../../helpers/ifallowed.js")).call(alias1,"editOnlineProfiles",{"name":"ifallowed","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":22},"end":{"line":8,"column":76}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\">\n    <a class=\"selectable-area\">\n        <div class=\"handle\">"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "</div>\n        <div title=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayHandle") : depth0), depth0))
    + "\" class=\"handle-id\">&nbsp;&nbsp;"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayHandle") : depth0), depth0))
    + "</div>\n    </a>\n</span>";
},"useData":true});