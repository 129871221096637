<template>
    <div ref="holder"></div>
</template>

<script>
    export default {
        name: "FilterEditor",

        props: {
            value: String,
            forRule: Boolean,
            noPublished: Boolean,
            noReach: Boolean,
            noReshareCount: Boolean,
            noReplyCount: Boolean,
            noReplyOrderByAuthor: Boolean,
            noEngagement: Boolean,
            noSample: Boolean,
            noReshareOf: Boolean,
            noReplyTo: Boolean,
            noConversation: Boolean,
            noInteraction: Boolean,
            noTrash: Boolean,
            previewBrandWarning: Boolean,
            brand: String
        },

        mounted() {
            this.model = new Backbone.Model({filter: this.value})
            this.model.on("change", this.modelChanged)
            this.view = new Beef.Filter.View({
                model: this.model,
                cache: cache,
                brand: this.brand,
                noPublished: this.noPublished || this.forRule,
                noReach: this.noReach || this.forRule,
                noReshareCount: this.noReshareCount || this.forRule,
                noReplyCount: this.noReplyCount || this.forRule,
                noReplyOrderByAuthor: this.noReplyOrderByAuthor || this.forRule,
                noEngagement: this.noEngagement || this.forRule,
                noSample: this.noSample || this.forRule,
                noReshareOf: this.noReshareOf || this.forRule,
                noReplyTo: this.noReplyTo || this.forRule,
                noConversation: this.noConversation || this.forRule,
                noInteraction: this.noInteraction || this.forRule,
                noTrash: this.noTrash,
                previewBrandWarning: this.previewBrandWarning || this.forRule
            })
            this.view.render()
            this.$refs.holder.append(this.view.el)
        },

        beforeDestroy() {
            this.view.close()
        },

        watch: {
            value(v) {
                if (v !== this.model.attributes.filter) this.model.set('filter', v)
            }
        },

        methods: {
            modelChanged() {
                this.$emit('input', this.model.attributes.filter)
            }
        }
    }

    const cache = { }
</script>

<style scoped>

</style>