<template>
    <th :title="item.title" :style="{background: background}" :class="{hidden, editable, left}" :colspan="colspan" @click="showMenu = editable">
        {{item.name}}
        <popup-menu v-model="showMenu" :arrow-pointer="{show: true, left: true}" ignore-doc-click :never-hide="neverHide">
            <div class="menu-items">
                <div class="item" @click="hide" v-if="!hidden">Hide</div>
                <div class="item" @click="show" v-if="hidden">Show</div>
                <div class="item" @click="$emit('show-hidden', true)" v-if="!showHidden"
                     :class="{disabled: !hasHiddenRowCols}">Reveal all hidden</div>
                <div class="item" @click="$emit('show-hidden', false)" v-if="showHidden && hasHiddenRowCols">Hide all hidden</div>
                <div class="item" @click="showColourPicker()">
                    <colour-settings-input ref="colourPicker" :value="itemColour" :dashboard-model="dashboardModel" no-opacity single
                                            :label="null" :default-value="defaultColour"
                                            @saved-colour-change="saveColour()" @closed="neverHide = false"/>
                    <div>{{ colspan ? 'Select column colour' : 'Select row colour' }}</div>
                </div>
                <div class="item" :class="{disabled: !hasColour}" @click="clearColour()">
                    Clear colour
                </div>
            </div>
        </popup-menu>
    </th>
</template>

<script>
import PopupMenu from "@/components/PopupMenu";
import ColourSettingsInput from "@/dashboards/ColourSettingsInput.vue";
import {DEFAULT_COLOUR} from "@/dashboards/widgets/comptable/CompTableUtils";

export default {
    name: "CTHeader",

    components: {ColourSettingsInput, PopupMenu },

    props: {
        item: Object,
        attrs: Object,
        dashboardModel: Object,
        editable: Boolean,
        showHidden: Boolean,
        colspan: Number,
        background: {
            required: false,
            default: null,
            type: String
        }
    },

    data() {
        return {
            showMenu: false,
            neverHide: false,
            itemColour: {},
            hasColour: false,
            defaultColour: DEFAULT_COLOUR
        }
    },

    mounted() {
        this.itemColour = this.attrs.rowColColours && this.attrs.rowColColours[this.item.id] ? this.attrs.rowColColours[this.item.id] : {};
        if (Object.keys(this.itemColour)?.length) this.hasColour = true;
    },

    computed: {
        hidden() { return this.attrs.hiddenRowCols && this.attrs.hiddenRowCols.indexOf(this.item.id) >= 0 },

        hasHiddenRowCols() { return this.attrs.hiddenRowCols && this.attrs.hiddenRowCols.length },

        left() { return this.item.type === 'note' }
    },

    methods: {
        hide() {
            if (this.hidden) return
            if (!this.attrs.hiddenRowCols) {
                this.$set(this.attrs, "hiddenRowCols", [])
            }

            this.$set(this.attrs, "hiddenRowCols", [...this.attrs.hiddenRowCols, this.item.id]);
            this.$emit("updated")

            this.showMenu = false;
        },

        show() {
            if (!this.hidden) return;
            let i = this.attrs.hiddenRowCols.indexOf(this.item.id);
            if (i >= 0) {
                const result = Array.from(this.attrs.hiddenRowCols);
                result.splice(i, 1);
                this.$set(this.attrs, "hiddenRowCols", result);
            }
            this.$emit("updated")

            this.showMenu = false;
        },

        saveColour() {
            this.neverHide = false;
            this.hasColour = true;

            if (!this.attrs.rowColColours) {
                this.$set(this.attrs, "rowColColours", {});
            }

            this.$set(this.attrs.rowColColours, this.item.id, this.itemColour);
            this.$emit("updated");

            this.showMenu = false;
        },

        clearColour() {
            this.itemColour = {};
            this.hasColour = false;

            if (!this.attrs.rowColColours) {
                this.$set(this.attrs, "rowColColours", {});
            }

            this.$set(this.attrs.rowColColours, this.item.id, null);
            this.$emit("updated");

            this.showMenu = false;
        },

        showColourPicker() {
            this.neverHide = true;
            this.$refs.colourPicker?.showDialog();
        }
    }
}
</script>

<style scoped lang="sass">

.item
    cursor: pointer
    color: #ffffff
    padding: 3px 20px 3px 10px
    display: flex
    align-items: center
    column-gap: 5px

    ::v-deep .tag-input
        margin-bottom: 0 !important

div + .item
    border-top: 1px solid rgb(136 136 136 / 0.5)

</style>