import {fetchCachedBrands} from "@/data/DeprecatedBeefCache";
import {features} from "@/app/Features";
import _ from 'underscore';

Beef.module("MoveBrand").addInitializer(function(startupOptions) {

    var maxRootBrands = startupOptions.account.maxRootBrands;

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/setup/brands/MoveBrand.handlebars"),

        attributes: { class: "move-brand dialog" },

        regions: {
            picker: ".picker"
        },

        initialize: function() {
            this.model.accountCode = this.options.accountCode;
            Beef.SettingsDialog.View.prototype.initialize.call(this, arguments);
        },

        onFirstRender: function() {
            var tree = buildPickerTree(this.options.rootBrands, this.model.get('id'));
            var p = new Beef.BrandPicker.PlainView({view: this, model: new Backbone.Model({tree: tree}), onlyOne: true});
            var _parentId = this.model.get('_parentId');
            if (_parentId) p.setSelection(_parentId.toString());
            this.picker.show(p);
        },

        ok: function() {
            var sel = this.picker.currentView.getSelection();
            var _parentId = this.model.get('_parentId');
            var parentId = null;

            if (sel.length == 0) {
                if (_parentId) parentId = 0;
            } else if (sel[0] != _parentId) {
                parentId = sel[0];
            }

            var undeletedCount = this.options.rootBrands ? _(this.options.rootBrands).filter(function(b) { return !b.deleted; }).length : 0;
            if (parentId != null) {
                if (parentId == 0 && undeletedCount >= maxRootBrands) {
                    Beef.LimitReached.show({target: this.$el}, maxRootBrands, "root brand", "root brands");
                    this.cancel();
                    return
                }

                fetchCachedBrands(this, function(brands) {
                    var depth = brandDepth(this.model.get('id'), brands) + brandHeight(parentId, brands);
                    if (depth >= 4 && !features.ignoreBrandDepth()) {
                        Beef.LimitBrandDepth.show({target: this.$el});
                        this.cancel();
                        return
                    }

                    this.model.set('parentId', parentId);
                    //this.cache.brands = null; // invalidate cache because tree is changing
                    Beef.SettingsDialog.View.prototype.ok.call(this, arguments);
                }.bind(this));
            } else {
                this.cancel();
            }
        }
    });

    var buildPickerTree = function(rootBrands, excludeBrandId) {
        var tree = [];
        for (var i = 0; i < rootBrands.length; i++) {
            var b = rootBrands[i];
            if (b.id != excludeBrandId) tree.push(createPickerBrand(b, excludeBrandId, null, 1));
        }
        return tree;
    };

    var createPickerBrand = function(brand, excludeBrandId, parent, level) {
        var ans = {id: brand.id, name: brand.name, parent: parent};
        if (level == 1 && brand.children) {
            ans.children = [];
            for (var i = 0; i < brand.children.length; i++) {
                var b = brand.children[i];
                if (b.id != excludeBrandId) ans.children.push(createPickerBrand(b, excludeBrandId, ans, level + 1));
            }
        }
        return ans;
    };

    /** Returns the depth of this particular brand node. */
    var brandDepth = function(id, brands) {
        var brand = brands.map[id];
        if (brand.children) {
            return 1 + _(brand.children).chain()
                    .map(function(b) { return brandDepth(b.id, brands); })
                    .max()
                    .value();
        }

        return 1;
    };

    /** Returns how many jumps there are until you reach the root brand. */
    var brandHeight = function(id, brands) {
        var height = 0;
        while (id) {
            id = brands.map[id].parent;
            if (id) id = id.id;
            height++;
        }

        return height;
    };

});