import _ from 'underscore';

/**
 * Adds validation to all models + provides callbacks to update views with errors.
 */
Beef.module("Validation", function() {

    Backbone.Validation.configure({
        forceUpdate: true
    });

    // add validation to all models
    Object.assign(Backbone.Model.prototype, Backbone.Validation.mixin);

    var super_validate = Backbone.Model.prototype._validate;

    Object.assign(Backbone.Model.prototype, {
        _validate: function(attrs, options) {
            // remember which attributes have actually been validated so the view can avoid clearing errors
            // for field that haven't changed
            this._lastValidatedAttrs = attrs;
            return super_validate.call(this, attrs, Object.assign({}, options, { validate: true}));
        }
    });

    // Cut and paste from backbone-validation.js
    // Returns an object with true property for all attributes on the model that has defined validation rules.
    var getValidatedAttrs = function(model) {
        return _.reduce(Object.keys(model.validation || {}), function(memo, key) {
            memo[key] = true;
            return memo;
        }, {});
    };

    // render error messages using Twitter Bootstrap styles
    // adapted from https://gist.github.com/2909552
    Object.assign(Backbone.Validation.callbacks, {

        valid: function(view, attr, selector) {
            if (view.model) {
                // Backbone validates all model attributes at once even when only one has changed using the valid
                // data already in the model for the others. So don't clear any existing error status here if
                // attr has not actually been validated.
                if (view.model._lastValidatedAttrs && !view.model._lastValidatedAttrs[attr]) return;

                // don't clear validation errors if the attr has no rules as this will clear errors from attributes
                // with the same name in nested views
                var attrs = getValidatedAttrs(view.model);
                if (!attrs[attr]) return;
            }

            var sel = '[' + selector + '="' + attr + '"]';
            // only update the first control found to avoid messing with controls in nested views - this does
            // assume that nested views will be 'lower down' in the document order
            var control = view.$(sel).first();
            var group = control.parents(".control-group");
            group.removeClass("error");
            if (control.data("error-style") == "inline") {
                group.find(".help-inline.error-message").remove()
            } else {
                group.find(".help-block.error-message").remove()
            }
        },

        invalid: function(view, attr, error, selector) {
            var sel = '[' + selector + '="' + attr + '"]';
            // only update the first control found to avoid messing with controls in nested views - this does
            // assume that nested views will be 'lower down' in the document order
            var control = view.$(sel).first();
            var group = control.parents(".control-group");
            group.addClass("error");
            if (control.data("error-style") == "inline") {
                if (group.find(".help-inline").length == 0) {
                    group.find(".controls").append("<span class=\"help-inline error-message\"></span>");
                }
                group.find(".help-inline").text(error);
            } else {
                if (group.find(".help-block").length == 0) {
                    group.find(".controls").append("<span class=\"help-block error-message\"></span>");
                }
                group.find(".help-block").text(error);
            }
        }

    });

});

