/**
 * Dialog to generates a list of phrases to add to a brand.
 */
import {currentAccountCode} from "@/app/utils/Account";
import {getGoodness} from "@/data/goodness";
import {isPhraseANo} from "@/app/utils/Phrases";
import {notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {getRootBrandFor} from "@/app/utils/Brands";
import {escapeHtml} from "@/app/utils/StringUtils";
import {formatNumber} from "@/app/utils/Format";

Beef.module("GeneratePhrases").addInitializer(function(startupOptions) {

    /**
     * Show the generate dialog. An array of generated phrases is passed to callback.
     */
    this.show = function(brandId, callback) {
        var model = new Backbone.Model({page: "gen"});
        var view = new View({model: model, brandId: brandId, callback: callback});

        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
        popup.setTarget($('body'));
        popup.show(view);
    };

    var View = Beef.BoundItemView.extend({

        template: require("@/setup/brands/GeneratePhrases.handlebars"),

        className: "dialog generate-phrases",

        regions: {
            phraseGenRegion: '.phrase-gen-region',
            phraseFilterRegion: '.phrase-filter-region',
            phraseListRegion: '.phrase-list-region'
        },

        bindings: {
            feeds: { converter: Beef.FeedPicker.converter, elAttribute: "data-value" }
        },

        createBindings: function() {
            // don't do bindings for our whole element or the subform for root brand filtering gets picked up
            // as well and the checkboxes don't work as they have bindings to both models
            return Beef.ModelBinder.createDefaultBindings(this.$el.find('.main-form'));
        },

        events: {
            "click .cancel":    "close",
            "click .ok":        "ok",
            "click .next":      "next",
            "click .prev":      "prev",
            "change .confirm":  "confirmClick"
        },

        modelEvents: {
            "change": "updateView"
        },

        onFirstRender: function() {
            var that = this;
            Beef.FeedPicker.attach(this, ".feeds", "feeds");

            this.phraseGenRegion.show(this.phraseGen = Beef.PhraseGen.createView());

            var v = Beef.RootBrandFilter.createView(this.model.get('mentionFilter'));
            v.model.on("change", function(){ that.model.set('mentionFilter', v.getFilter()); });
            this.phraseFilterRegion.show(v);

            this.collection = new Backbone.Collection();
            this.phraseListRegion.show(Beef.TextSearch.createPhraseGenView(this.collection));

            this.updateView();
        },

        updateView: function() {
            var saving = !!this.model.get('saving');
            var page = this.model.get('page');
            this.$('.pages > div').hide();
            this.$('.page-' + page).show();
            this.$('.next').toggleClass("disabled", page == "confirm" || saving);
            this.$('.prev').toggleClass("disabled", page == "gen" || saving);
            var $ok = this.$('.ok');
            $ok.toggleClass("disabled", page != "confirm" || !this.model.get('confirm') || saving || this.model.get("no"));
            $ok.html(saving ? "<span class='spinner'></span>" : "Ok");
        },

        next: function() {
            if (this.model.get('saving')) return;
            var page = this.model.get('page');
            if (page == "gen") {
                var phrases = this.phraseGen.generate();
                if (phrases.length == 0) return;
                if (!this._phrases || JSON.stringify(this._phrases) != JSON.stringify(phrases)) {
                    this._phrases = phrases;
                    var models = [];
                    for (var i = 0; i < phrases.length; i++) models.push({phrase: phrases[i]});
                    this.collection.reset(models);
                }
                this.model.set('page', "list");
            } else if (page == "list") {
                if (this.estimateVolume()) {
                    this.phraseGenRegion.currentView.saveModel();
                    this.model.set('page', "confirm");
                    this.model.set('confirm', false);
                }
            }
        },

        prev: function() {
            if (this.model.get('saving')) return;
            var page = this.model.get('page');
            if (page == "list") this.model.set('page', "gen");
            else if (page == "confirm") this.model.set('page', "list");
        },

        estimateVolume: function() {
            var phrases = [];
            for (var i = 0; i < this.collection.size(); i++) {
                var q = this.collection.at(i).get('phrase');
                if (q.length > 0 && phrases.indexOf(q) < 0) phrases.push(q);
            }
            if (phrases.length == 0) return false;
            var $inner = this.$('.page-confirm .inner');
            $inner.html("<div><span class='spinner' style='vertical-align: -3px;'></span> <span>Estimating volume ...</span></div>");
            var that = this;
            getGoodness(this.options.brandId, phrases)
                .then(data => {
                    this.model.set("no", false);
                    let tot = 0;
                    let warned = false;
                    for (let i = 0; i < data.length; i++) {
                        console.log(data[i]);
                        if (!warned && isPhraseANo(getRootBrandFor(this.options.brandId), {mentionFilter: this.model.get("mentionFilter")}, data[i])) {
                            warned = true;
                            this.model.set("no", true);
                            notifyUserOfError(escapeHtml`The phrase <strong>${data[i].query}</strong> cannot be added to this account. 
It is estimated to bring in <strong>${formatNumber(data[i].mentionsTotal)}</strong> mentions in a month, exceeding this brand's volume limit. 
Please add restrictions to this brand and/or phrase.`, true);
                        } else if (!warned && data[i].level === "NO") {
                            notifyUserOfError(`Carefully consider the volumes and account setup for these phrases. Some of them are expected
to exceed this brand's volume limit.`);
                        }
                        tot += data[i].mentionsTotal;
                    }
                    $inner.html(Handlebars.render(require("@/setup/brands/GeneratePhrasesTable.handlebars"), {total: tot, phrases: data}));
                    this._volData = data;
                })
                .catch(e => {
                    console.error(e);
                    $inner.html("<div class='text-warning'></div>");
                    $inner.find("div").text("Unable to reach DataEQ, please check your internet connection and try again");
                });
            return true;
        },

        confirmClick: function(ev) {
            this.model.set('confirm', $(ev.target).is(":checked"));
        },

        ok: function() {
            if (!this.model.get('confirm') || !this._volData || this.model.get('saving') || this.model.get("no")) return;
            var phrases = [];
            var feeds = this.model.get('feeds');
            var mentionFilter = this.model.get('mentionFilter');
            for (var i = 0; i < this._volData.length; i++) {
                var p = { query: this._volData[i].query, brandId: this.options.brandId, active: true };
                if (feeds) p.feeds = feeds;
                if (mentionFilter) p.mentionFilter = mentionFilter;
                phrases.push(p);
            }

            this.model.set('saving', true);
            var that = this;
            $.ajax({
                url: Beef.Sync.toMashUrl("accounts/" + currentAccountCode() + "/phrases", true),
                type: "POST",
                data: JSON.stringify(phrases),
                contentType: "application/json; charset=utf-8"
            }).done(function(data) {
                that.close();
                that.options.callback(data);
            }).fail(function(xhr) {
                window.alert(toFailMsg(xhr));
            }).always(function() {
                that.model.set('saving', false);
            });
        }
    });

    var toFailMsg = function(xhr) {
        if (xhr.status) {
            return "Something has gone wrong, please try again later (" + xhr.status + ")";
        } else {
            return "Unable to reach DataEQ, please check your internet connection and try again";
        }
    }
});