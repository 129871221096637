/**
 * Handles all permissions related queries.
 */
import {shouldTurnOffAdmin} from "@/app/Features";
import {features} from "@/app/Features";
import VuexStore from "@/store/vuex/VuexStore";

/**
 * Returns true iff the current logged in user is an admin. It also
 * obeys the feature flag for turning off admin features.
 */
export function isMashAdmin() {
    const noAdmin = shouldTurnOffAdmin();
    return !noAdmin && VuexStore.state.user.admin;
}


/**
 * Ops have access to special abilities and powers.
 */
export function isOps() {
    const noAdmin = shouldTurnOffAdmin();
    return !noAdmin && VuexStore.state.user.ops;
}

/** What access level does the logged in user have? READ, FULL or ADMIN? */
function getAccess() {
    let access = VuexStore.state.account.access;
    return access ? access : "READ";
}

export function isAccountAdmin() {
    return getAccess() === "ADMIN";
}

function isReadOnly() {
    let access = getAccess();
    // invert test so any new access levels default to READ until changed here
    return access !== "FULL" && access !== "ADMIN" && access !== "REPORTING";
}


function isReporting() {
    return getAccess() === "REPORTING";
}

export function isArchivedAccount() {
    return VuexStore.state.account.status === "ARCHIVED";
}

export function isAccountInactive() {
    return !!VuexStore.state.account.inactive;
}

export function editDashboards() { return !isReadOnly() && !isAccountInactive();  }

export function canShareDashboardLinks() {
    // in priority order
    let PERMS = ["ADMIN", "FULL", "READ", "REPORTING"]
    let user = VuexStore.state.user;
    let account = VuexStore.state.account;

    if (!account || !account.shareDashboardLink) return false;
    let userAccount = user.accounts.find(acc => acc.code === account.code);
    if (!userAccount) return false;
    let i = PERMS.indexOf(userAccount.access);
    let j = PERMS.indexOf(account.shareDashboardLink);

    return i >= 0 && j >= 0 && i <= j
}

export function editContact(email) { return (isAccountAdmin() || VuexStore.state.user.email === email)  && !isAccountInactive(); }

export function deleteContact() { return isAccountAdmin() && !isAccountInactive(); }

export function editAccountProfile() { return isAccountAdmin() && !isAccountInactive(); }

export function editBrandsAndPhrases() { return !isReadOnly() && !isArchivedAccount() && !isReporting() && !isAccountInactive()}

export function addBusyPhrases() { return features.addBusyPhrases() }

/**
 * Returns true if the user is able to edit mentions in the account.
 * @return {boolean}
 */
export function editMentions() { return !isReadOnly() && !isAccountInactive()}

export function editImportedMentions() { return !isReadOnly() && isMashAdmin() && !isAccountInactive()}

export function editRules() { return !isReadOnly() && !isReporting() && !isAccountInactive()}

export function editFeeds() { return !isReadOnly() && !isReporting() && !isAccountInactive()}

export function editTags() { return !isReadOnly() && !isReporting() && !isAccountInactive()}

export function editOnlineProfiles() { return !isReadOnly() && !isReporting() && !isAccountInactive()}

export function editDigests() { return !isReadOnly() && !isReporting() && !isAccountInactive()}

export function editOldPhrases() { return false; }

export function assignMentionToCrowd() { return isOps() && !isAccountInactive(); }

export function changeCrowdPriority() { return isOps() && !isAccountInactive(); }

export function sendToEngagementConsole() {  return isOps(); }

export function showRaceFilter() { return false; }

export function editCrowdQuotaPercentage() { return isAccountAdmin() && !isAccountInactive() }

export function editBrandCrowdSettings() { return isOps()  && !isAccountInactive()}


export function importBrand() {return isMashAdmin()  && !isAccountInactive();}

export function phraseFilters() { return isMashAdmin() }

export function generatePhrases() { return isMashAdmin() }

export function addBrandWithPayPerBrandCheck() {
    const account = VuexStore.state.account;
    // Normal edit brands check with Mash admin check if account is pay-per-brand.
    // only Mash admins should add root brands on pay-per-brand accounts.
    if (editBrandsAndPhrases()) {
        if (account.payPerBrand) { return isMashAdmin() && !isAccountInactive(); }
        else { return true; }
    }
    else { return false; }
}
