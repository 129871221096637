<template>
    <div class="mention-stats__links">
        <!-- needs to be a single line to properly handle whitespace between inline blocks. Fixed in view 3.1-->
        <template v-for="(item, index) in items"><MentionStatsSummaryLinkItem :entity="item" :key="index"/></template>
    </div>
</template>

<script>

import {findEntities} from "@/app/utils/turducken";
import {mapActions} from "vuex";
import MentionStatsSummaryLinkItem from "@/app/toplevel/mentions/components/MentionStatsSummaryLinkItem.vue";

export default {
    components: {MentionStatsSummaryLinkItem},
    props: {
        summary: String,
        citations: null
    },

    data() {
        return {
            items: this.summary ? [{text: this.summary}] : []
        }
    },

    async mounted() {
        await this.loadLinks();
    },

    watch: {
        async summary() {
            await this.loadLinks();
        },

        async citations() {
            await this.loadLinks();
        }

    },

    methods: {
        async loadLinks() {
            if (!this.summary) return;
            try {
                this.$emit("loading", true);

                const text = this.summary;

                this.items = [];
                if (this.summary) this.items.push({text});

                const entities = await findEntities(text);
                if (!entities.length) return;

                // This algorithm for generating links below requires
                // the entities to be sorted in text order. So we want to ensure
                // we always receive them in that order.
                entities.sort((lhs, rhs) => lhs.start - rhs.start);

                const CITATION_PATTERN = /\[(\d+,?\s*)+]/gm;
                const parseCitation = text => {
                    if (!text) return;
                    const matches = text.matchAll(CITATION_PATTERN);
                    let start = 0;

                    for (const match of matches) {
                        const clean = match[0].replaceAll(/[\[\]]/gm, "")
                            .split(',')
                            .map(s => s.trim())
                            .map(s => Number.parseInt(s));

                        const index = text.indexOf(match[0], start);
                        const matched = text.slice(start, index);

                        if (matched.length) {
                            results.push({text: matched});
                        }

                        clean.forEach(id => results.push({
                            entity: true,
                            text: id,
                            mention: this.citations?.at(id - 1)?.mention ?? '#'
                        }));

                        start = index + match[0].length;
                    }

                    if (start < text.length - 1) {
                        results.push({text: text.slice(start)})
                    }
                };

                const results = [];
                let start = 0;
                let end = 0;

                for (const entity of entities) {
                    // All of this can take a while. So we would like
                    // the ui to update as there are things to update.
                    await this.$nextTick();

                    end = entity.start;
                    const preMatchText = text.slice(start, end);
                    if (preMatchText) {
                        parseCitation(preMatchText);
                    }

                    results.push({entity: true, text: text.slice(entity.start, entity.end)});
                    start = entity.end;
                }

                if (start < text.length) {
                    parseCitation(text.slice(start));
                }

                this.items = results;

            } catch (e) {
                console.error(e);
                this.items = [{text: this.summary}];
            } finally {
                this.$emit("loading", false);
            }

        }
    }
}

</script>

<style scoped>

.mention-stats__links a {
    animation: 1s link-colours ease-in-out;
}

@keyframes link-colours {
    0% {
        color: inherit;
    }
}

</style>