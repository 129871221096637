var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_deltas") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":57}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_loading") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "        </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_deltas") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":50,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "delta-main";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"animated fadeIn\">\n                    "
    + container.escapeExpression(__default(require("../../../helpers/spinner.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"spinner","hash":{"size":16},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":39}}}))
    + "\n                </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fmt") : depth0),"==","zar",{"name":"ifcond","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":31,"column":27}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(__default(require("../../../helpers/zarToStat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),(depth0 != null ? lookupProperty(depth0,"exact") : depth0),{"name":"zarToStat","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":45}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"fmt") : depth0),"==","%",{"name":"ifcond","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":20,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"marginOfErrorP") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(__default(require("../../../helpers/formatPercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),1,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":52}}}))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fmt") : depth0),"==","duration",{"name":"ifcond","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":19,"column":35}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(__default(require("../../../helpers/formatDuration.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),(depth0 != null ? lookupProperty(depth0,"exact") : depth0),{"name":"formatDuration","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":58}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(__default(require("../../../helpers/numToStat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),(depth0 != null ? lookupProperty(depth0,"exact") : depth0),{"name":"numToStat","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":53}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"margin-of-error\" title=\"Between "
    + alias2(__default(require("../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"valueLow") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":22,"column":69},"end":{"line":22,"column":98}}}))
    + " and "
    + alias2(__default(require("../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"valueHi") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":22,"column":103},"end":{"line":22,"column":131}}}))
    + ", (95% confidence)\">&plusmn; "
    + alias2(__default(require("../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"marginOfErrorP") : depth0),1,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":22,"column":160},"end":{"line":22,"column":197}}}))
    + "</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"marginOfErrorZ") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":26,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"marginOfError") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":29,"column":31}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"margin-of-error\" title=\"In this range, 95% confidence\">&plusmn; 0-"
    + container.escapeExpression(__default(require("../../../helpers/numToStat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"marginOfErrorZ") : depth0),(depth0 != null ? lookupProperty(depth0,"exact") : depth0),{"name":"numToStat","hash":{},"data":data,"loc":{"start":{"line":25,"column":107},"end":{"line":25,"column":141}}}))
    + "</span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"margin-of-error\" title=\"Between "
    + alias2(__default(require("../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"valueLow") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":28,"column":73},"end":{"line":28,"column":98}}}))
    + " and "
    + alias2(__default(require("../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"valueHi") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":28,"column":103},"end":{"line":28,"column":127}}}))
    + ", 95% confidence\">&plusmn; "
    + alias2(__default(require("../../../helpers/numToStat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"marginOfError") : depth0),(depth0 != null ? lookupProperty(depth0,"exact") : depth0),{"name":"numToStat","hash":{},"data":data,"loc":{"start":{"line":28,"column":154},"end":{"line":28,"column":187}}}))
    + "</span>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"deltas") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(39, data, 0),"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":49,"column":19}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_dateComparison") : depth0),"==",true,{"name":"ifcond","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":46,"column":27}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"deltas") : depth0),{"name":"with","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":43,"column":29}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <td class=\"value delta-td "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dir") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "\">\n                            <div class=\"inner\">\n                                "
    + ((stack1 = __default(require("../../../helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"diff") : depth0),"<",0,{"name":"ifcond","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":32},"end":{"line":40,"column":93}}})) != null ? stack1 : "")
    + "<span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"exactDeltas") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":40,"column":99},"end":{"line":40,"column":192}}})) != null ? stack1 : "")
    + "</span>\n                            </div>\n                        </td>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "<span class=\"minus\">-</span>";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../../helpers/numToStat.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"absDiff") : depth0),true,{"name":"numToStat","hash":{},"data":data,"loc":{"start":{"line":40,"column":118},"end":{"line":40,"column":144}}}));
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../../helpers/formatPercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"percentage") : depth0),0,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":40,"column":152},"end":{"line":40,"column":185}}}));
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = __default(require("../../../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fmt") : depth0),"==","%",{"name":"ifcond","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":45,"column":177}}})) != null ? stack1 : "")
    + "\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "<td class=\"value delta-td\">N/A</td>";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"value delta-td\">"
    + container.escapeExpression(__default(require("../../../helpers/formatPercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"deltas") : depth0)) != null ? lookupProperty(stack1,"percentShare") : stack1),1,{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":45,"column":114},"end":{"line":45,"column":161}}}))
    + "</td>";
},"39":function(container,depth0,helpers,partials,data) {
    return "                <td class=\"value delta-td\"></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"cols") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":51,"column":13}}})) != null ? stack1 : "")
    + "    <td class=\"name\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</td>\n</tr>\n";
},"useData":true});