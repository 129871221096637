Beef.module("ImportBrand").addInitializer(function(startupOptions) {

    /**
     * Callback is invoked with the id of the brand if a brand was imported successfully.
     */
    this.createView = function(accountCode, callback) {
        var m = new Backbone.Model();
        return new View({model: m, accountCode: accountCode, callback: callback});
    };

    var SPINNER = "<span class='spinner'/>";

    var View = Beef.BoundItemView.extend({

        template: require("@/setup/brands/ImportBrand.handlebars"),

        attributes: { class: "import-brand dialog" },

        events: {
            "click .dialog-title .close":   "close",
            "click .cancel":                "close",
            "click .ok":                    "ok",
            "keyup .q":                     "onQueryKeyup",
            "keydown .q":                   "onQueryKeyDown",
            "click .brand-list tr":         "onBrandClicked",
            "click .dialog-body":           "giveQueryFocus"
        },

        modelEvents: {
            // "change:q": "queryChanged"
        },

        onRender: function() {
            var that = this;
            setTimeout(function() { that.giveQueryFocus(); });
        },

        giveQueryFocus: function() {
            this.$("input.q")[0].focus();
        },

        onQueryKeyup: function(ev) {
            this.update();
            var keyCode = ev.keyCode;
            if (keyCode == 13) this.ok();
            else if (keyCode == 38 || keyCode == 40) ev.preventDefault(); // up and down
        },

        onQueryKeyDown: function(ev) {
            if (this._brandsMatched && this._brandsMatched.length > 0) {
                var keyCode = ev.keyCode;
                if (keyCode == 38 || keyCode == 40) { // up and down
                    ev.preventDefault();
                    var i = this.indexOfSel() + (keyCode == 38 ? -1 : +1);
                    if (i >= 0 && i < this._brandsMatched.length) this._sel = this._brandsMatched[i];
                    this.renderSelection();
                }
            }
        },

        indexOfSel: function() {
            if (this._sel && this._brandsMatched) {
                for (var i = 0; i < this._brandsMatched.length; i++) {
                    if (this._brandsMatched[i].id == this._sel.id) return i;
                }
            }
            return -1;
        },

        update: function() {
            var q = this.$("input.q").val().trim().toLowerCase();
            var $list = this.$('.brand-list');
            if (q.length > 0) {
                if (this._brands && q.indexOf(this._q) == 0) {
                    var matched = [];
                    for (var i = 0; i < this._brands.length; i++) {
                        var b = this._brands[i];
                        if (b.name.toLowerCase().indexOf(q) >= 0) {
                            matched.push(b);
                            b.acCode = b.accountCode; // for some reason accountCode is always our account in the template
                        }
                    }
                    if (!this._brandsMatched || this._brandsMatched.length != matched.length) {
                        this._brandsMatched = matched;
                        $list.html(require('@/setup/brands/ImportBrandList.handlebars')({brands: matched}));
                        if (matched.length == 1) this._sel = matched[0];
                        else if (this.indexOfSel() < 0) this._sel = null;
                    }
                } else if (!this._brandsXhr) {
                    var that = this;
                    $list.html(SPINNER);
                    q = q.substring(0, 1);
                    this._brandsXhr = Beef.Sync.mashGET('accounts/' + this.options.accountCode + '/brands/for-import', {q: q})
                        .done(function(data) {
                            that._q = q;
                            that._brands = data;
                            that._brandsMatched = null;
                            that._sel = null;
                            that._brandsXhr = null;
                            that.update();
                        }).fail(function(xhr) {
                            $list.text('Error (' + xhr.status +"): " + xhr.responseText);
                            that._brands = null;
                            that._sel = null;
                            that._brandsXhr = null;
                        });
                }
            } else {
                this._brands = null;
                this._sel = null;
                $list.text('');
            }
            this.renderSelection();
        },

        onBrandClicked: function(ev) {
            var brandId = this.$(ev.target).closest("tr").attr("data-id");
            if (brandId) {
                this._sel = {id: brandId};
                var i = this.indexOfSel();
                this._sel = i < 0 ? null : this._brandsMatched[i];
                this.renderSelection();
            }
            this.giveQueryFocus();
        },

        renderSelection: function() {
            var $body = this.$('.brand-list .table tbody');
            $body.find('tr').toggleClass("selected", false);
            if (this._sel) $body.find('tr[data-id=' + this._sel.id + "]").toggleClass("selected", true);
            this.$(".ok").toggleClass("disabled", !this._sel);
        },

        ok: function() {
            if (!this._sel) return;

            var $ok = this.$('.ok');
            $ok.html(SPINNER);
            $ok.toggleClass("disabled", true);

            var that = this;
            var importId = this._sel.id;
            Beef.Sync.mashPOST('accounts/' + this.options.accountCode + "/brands", {importId: importId})
                .done(function() {
                    that.close();
                    that.options.callback(importId);
                })
                .fail(function(xhr){
                    window.alert('Error (' + xhr.status +"): " + xhr.responseText);
                    $ok.text("Import Brand");
                    $ok.toggleClass("disabled", false);
                });
        }
    });
});