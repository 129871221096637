/**
 * Select a time period to compare to on graphs. Listen for "change:selection" on its model to pickup changes.
 * Edits a space separated list of multiple time periods when attached.
 * Provides a model binder converter to display the selected options in an element.
 */
import moment from "moment";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";
import {splitAtSpaces} from "@/app/utils/StringUtils";

Beef.module("PastDatePicker").addInitializer(function(startupOptions) {

    var validUnits = ["DAY", "WEEK", "MONTH"];

    var TimePeriod = {
        toString: function() {
            return this.date ? moment(this.date).format("YYYY/MM/DD") : this.units + "-" + this.count;
        },
        toNiceString: function() {
            if (this.date) {
                return moment(this.date).format("D MMM YYYY");
            } else {
                if (this.count == 1) {
                    switch (this.units) {
                        case "DAY": return "A day earlier";
                        case "WEEK": return "A week earlier";
                        case "MONTH": return "A month earlier";
                    }
                }
                if (this.units == "MONTH") {
                    if (this.count == 3) return "A quarter earlier";
                    if (this.count == 12) return "A year earlier";
                    if (this.count == 24) return "2 years earlier";
                    if (this.count == 36) return "3 years earlier";
                }
                switch (this.units) {
                    case "DAY": return this.count + " days earlier";
                    case "WEEK": return this.count + " weeks earlier";
                    case "MONTH": return this.count + " months earlier";
                }
                return this.toString(); // we shouldn't get here but at least don't break
            }
        }
    };

    /**
     * Parse a time in the past into a units value and a count. Returns null if it is invalid.
     * Works with DAY-n WEEK-n MONTH-n yyyy/MM/dd. Returns { units: .., count: .., date: ... }. If date is not null
     * then the others are null and visa versa.
     */
    this.parseTimePeriod = function(s) {
        if (!s || s.length == 0) return null;
        var i;
        var ans;
        if ((i = s.indexOf("-")) > 0) {
            var units = s.substring(0, i);
            var count = s.substring(i + 1);
            ans = Object.assign({units: units, count: parseInt(count)}, TimePeriod);
            if (isNaN(ans.count) || ans.count <= 0 || validUnits.indexOf(ans.units) < 0) return null;
        } else {
            var m = moment(s, "YYYY/MM/DD");
            if (!m.isValid()) return null;
            ans = Object.assign({ date:m.toDate() }, TimePeriod);
        }
        return ans;
    };

    this.View = Beef.Picker.View.extend({
        attributes: { class: "past-date-picker dialog" },

        template: require("@/dashboards/filter/pickers/pastdate/PastDatePicker.handlebars"),

        regions: {
            calendarRegion: ".calendar-region"
        },

        initialize: function() {
            this.cal = new Beef.Calendar.View();
            var that = this;
            this.cal.model.on('change:date', function() { that.calDateChanged() });
        },

        items: {},

        modelEvents: {
            "change:selection": "updateView"
        },

        events: {
            "mousedown": "mousedown",
            "click .btn": "btnClick",
            "change .ago input": "agoValueChanged",
            "keyup": "keyup"
        },

        onFirstRender: function() {
            this.calendarRegion.show(this.cal);
            setTimeout(function(){ $(this.$("input").first()).focus() }.bind(this));
        },

        updateView: function() {
            $(".btn", this.$el).toggleClass("active", false);
            var sel = Beef.PastDatePicker.parseTimePeriod(this.model.get('selection'));
            if (sel) {
                if (sel.date) {
                    this.cal.model.set('date', sel.date);
                } else {
                    var btn = $(".btn[value='" + sel +"']");
                    if (btn.length == 0) {
                        $(".ago input", this.$el).val(sel.count);
                        btn = $(".btn[value='" + sel.units +"']");
                    }
                    btn.toggleClass("active", true);
                    this.cal.model.set('date', null);
                }
            } else {
                this.cal.model.set('date', null);
            }
        },

        setSelection: function(v) {
            this.model.set('selection', v);
        },

        getAgoValue: function() {
            var $input = $('.ago input', this.$el);
            var v = parseInt($input.val());
            var bad = isNaN(v) || v <= 0;
            $input.closest(".control-group").toggleClass("error", bad);
            return bad ? 0 : v;
        },

        btnClick: function(ev) {
            ev.preventDefault(); // stop form from being submitted for ago buttons
            var v = $(ev.target).attr('value');
            if (v.indexOf("-") < 0) {
                var ago = this.getAgoValue();
                if (ago) this.setSelection(v + "-" + ago);
            } else {
                this.setSelection(v);
            }
        },

        agoValueChanged: function() {
            var v = this.getAgoValue();
            if (!v) return;
            var tp = Beef.PastDatePicker.parseTimePeriod(this.model.get('selection')) || Object.assign({}, TimePeriod);
            if (!tp.units) tp.units = "WEEK";
            if (tp.date) tp.date = null;
            tp.count = v;
            this.setSelection(tp.toString());
        },

        calDateChanged: function() {
            var date = this.cal.model.get('date');
            if (date) {
                var tp = Object.assign({ date: date }, TimePeriod);
                this.setSelection(tp.toString());
            }
        },

        keyup: function(ev) {
            // don't let escape go up and close whatever dialog we might be in .. just close our popup instead
            if (ev.keyCode == 27 && this.parentPopup) {
                ev.stopPropagation();
                this.restoreFocusOnClose = true;
                this.parentPopup.hide();
            }
        }
    });

    this.converter = createTagConverter({
        items: function(code) {
            var tp = Beef.PastDatePicker.parseTimePeriod(code);
            return tp ? tp.toNiceString() : code;
        },
        plus: true,
        placeholder: "Comparison Date Range"
    });

    /**
     * Attach a picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View, { binder: binder });
    };

    /**
     * Custom binding between the attribute we are editing (list of time periods) and our picker. This makes sure
     * that the picker is editing the tag clicked or adding a new one otherwise.
     */
    var binder = function(picker, ctx, ev, showInPopup) {
        var $t = $(ev.target);
        var $tagInput = $t.closest(".tag-input");

        var tag = $t.hasClass("tag") ? $t.attr('data-value') : null;

        if (!tag) {
            $t = $tagInput.find('.plus');
        } else {
            // highlight tag we are editing a bit
            $t.animate({ opacity:0.5 }, 100, null, function() { $t.css('opacity', ""); });
        }

        // remember the index of the item in the list we are editing so we can update it on change
        var view = ctx.view;
        var dates = view.model.get(ctx.attribute);
        var a = dates && dates.length > 0 ? splitAtSpaces(dates) : null;
        if (a) {
            if (tag) ctx.editingIndex = a.indexOf(tag);
            else ctx.editingIndex = a.length;
        } else {
            ctx.editingIndex = 0;
        }

        picker.setSelection(a && ctx.editingIndex < a.length ? a[ctx.editingIndex] : null);

        picker.model.on('change:selection', function() {
            var sel = picker.model.get('selection');

            var dates = view.model.get(ctx.attribute);
            if (dates && dates.length > 0) {
                var a = splitAtSpaces(dates);
                if (ctx.editingIndex < a.length) a[ctx.editingIndex] = sel;
                else a.push(sel);
                sel = a.join(" ");
            }
            view.model.set(ctx.attribute, sel); // this will update the list of tags in our span

            if (view.popup) {
                var children = $tagInput.children();
                view.popup.move(ctx.editingIndex < children.length ? $(children[ctx.editingIndex]) : null);
            }
        });

        showInPopup(view, picker, $t, $tagInput);
    }

});
