/**
 * Render user access to private mentions.
 */
import {VISIBILITY_ITEMS} from "@/app/utils/Visibility";

export default function(pm, options) {
    if (!pm || !pm.length) return new Handlebars.SafeString("None");
    if (pm.length == 1 && pm[0] === '*') return new Handlebars.SafeString("All");
    var tags = [];
    for (var i = 0; i < pm.length; i++) {
        var code = pm[i]
        tags.push(VISIBILITY_ITEMS[code] || code);
    }
    return new Handlebars.SafeString(tags.join(", "));
}