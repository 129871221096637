import {FontSizeEl} from "@/dashboards/widgets/commentaryplus/customHtmlElements/FontSizeEl";
import {showTooltipText} from "@/components/tooltip/TooltipUtilities";

export class FontSize {

    get state() {
        return this._state;
    }

    set state(state) {
        this._state = state;
        this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }

    constructor({api}) {
        this._state = false;
        this.api = api;

        this.button = document.createElement("button");

        this.button.innerHTML = '<i class="icon-text-height-1"></i>';
        this.button.classList.add(this.api.styles.inlineToolButton);
        this.button.classList.add('commentary-inline-btn');

        this.button.addEventListener('mouseover', () => {
            showTooltipText(this.button, "Change the font size", {});
        });


        this.isActionsOpened = false;

        window.addEventListener('mouseup', (e) => {
            if (this.isActionsOpened && e.target.closest('button') !== this.button) {
                this.isActionsOpened = false;
                this.hideActions();
                this.button.classList.remove('commentary-inline-btn-active');
            }
        });
    }

    static get isInline() {
        return true;
    }

    render() {
        return this.button;
    }

    surround(range) {
        this.range = range;
        this.isActionsOpened = !this.isActionsOpened;
    }

    renderActions() {
        this.sizeOptions = document.createElement("div");
        this.sizeOptions.classList.add("commentary-inline-font-size-action");

        this.textSizes().forEach((size) => {
            const option = document.createElement("button");
            option.classList.add('commentary-inline-action-btns');
            option.textContent = size.label;
            option.addEventListener('click', () => {
                const selectedText = this.range.extractContents();
                const wrapper = document.createElement('font-size');
                wrapper.dataset.size = size.value;
                wrapper.appendChild(selectedText);

                this.range.insertNode(wrapper);

                let selection = document.getSelection();
                let nodes = wrapper.querySelectorAll('font-size');
                nodes.forEach(node => {
                    if (selection.containsNode(node, true)) {
                        let span = document.createElement('span');
                        span.innerHTML = node.innerHTML;
                        node.replaceWith(span);
                    }
                });

                this.api.selection.expandToTag(wrapper);
            });
            this.sizeOptions.appendChild(option);
        });
        return this.sizeOptions;
    }

    checkState(selection) {
        this.setActiveTextSize(selection);
        if (this.isActionsOpened) {
            this.showActions();
        } else {
            this.hideActions();
        }
    }

    showActions() {
        this.sizeOptions.hidden = false;
        this.button.classList.add('commentary-inline-btn-active');
        this.sizeOptions.style.left = this.getActionsOffset() + 'px';
    }

    hideActions() {
        this.sizeOptions.onchange = null;
        this.sizeOptions.hidden = true;
        this.button.classList.remove('commentary-inline-btn-active');
    }

    textSizes() {
        return [
            {label: '10', value: '8px'},
            {label: '13', value: '10px'},
            {label: '16', value: '12px'},
            {label: '18', value: '14px'},
            {label: '24', value: '18px'},
            {label: '32', value: '22px'},
            {label: '48', value: '26px'}
        ];
    }

    setActiveTextSize(selection) {
        const text = selection.anchorNode;
        if (!text) {
            return;
        }
        // const anchorElement = text instanceof Element ? text : text.parentElement;
        const anchorElement = text instanceof Element ? text : text.nextSibling ? text.nextSibling : text.parentElement;

        if (anchorElement instanceof FontSizeEl) {
            const size = anchorElement.dataset.size;
            this.button.innerHTML = `<i class="icon-text-height-1"></i> ${this.textSizes().filter(s => s.value === size)[0].label}`;
            this.anchor = anchorElement;
        }
        // normal size
        // this.button.innerHTML = `<i class="icon-text-height-1"></i> 18`;
        // else we actually just don't know. should figure this out

    }

    getActionsOffset() {
        const buttonRect = this.button.getBoundingClientRect();
        const optionsRect = this.sizeOptions.getBoundingClientRect();

        return buttonRect.left - optionsRect.left;

    }

}