/**
 * Drill down by adding/removing brands to the filter.
 */

import _ from 'underscore';

Beef.module("Widget.TopicViewSelector").addInitializer(function(startupOptions) {

    var thisModule = this;

    this.type = {
        name:           "Topic Views",
        description:    "Interactively filter by topic views",
        width:          2,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        filterAttribute: "tags",

        refresh: function() {
            var views = Beef.TopicViewPicker.items();
            if(views && Object.keys(views).length > 0) {
                var items = _.map(Beef.TopicViewPicker.items(), function(value, key) {
                    return { id: key, name: value['name'] }
                });
                this.text(items);
            } else {
                this.text([{ name: "(no views have been setup for this account)" }]);
            }
        }
    });
});