var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"englishFilter") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":42}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            Fetching authors …\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n    <div class=\"span12 english-filter\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"englishFilter") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div class=\"row-fluid\">\n    <div class=\"span12 actions\">\n        <span class=\"pull-left\">\n            <a class=\"btn edit-filter\" title=\"Set a filter to choose what mentions you want to work with\">Filter mentions</a>\n        </span>\n\n        <span class=\"pull-right\">\n            <a class=\"btn btn-link clear-filter\" title=\"Reset the filter\">Clear filter</a>\n            <a class=\"btn refresh\">Refresh</a>\n            <a class=\"btn download-csv\" title=\"Download data in CSV format\" target=\"_blank\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"csvLink") : depth0), depth0))
    + "\"><i class=\"icon-download\"></i> CSV</a>\n        </span>\n    </div>\n</div>\n";
},"useData":true});