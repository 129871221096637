<template>
    <section class="be-sidebar-action"
             :class="classObject"
             @mouseenter="$emit('mouseenter', $event)">
        <section tabindex="0" @click="click($event)">
            <slot>
                Action text
            </slot>
        </section>

    </section>
</template>


<script>

export default  {
    props: {
        active: {type: Boolean, default: false},        // Whether this menu item is active / selected or not
        indented: {type: Boolean, default: false},      // Whether this menu item should be indented or not.
        disabled: {type: Boolean, default: false}       // Whether this menu item should be disabled or not
    },

    computed: {
        classObject() {
            return {
                'be-sidebar-action--active': this.active,
                'be-sidebar-action--indented': this.indented,
                'be-sidebar-action--disabled': this.disabled
            }
        }
    },

    methods: {
        click(event) {
            if (!this.disabled) this.$emit('click', event);
        }
    }
}

</script>

<style scoped lang="sass">

.be-sidebar-action
    box-sizing: border-box
    line-height: 20px
    padding: 10px 16px 11px 12px
    border-radius: 0
    text-align: left
    color: #ccc
    font-weight: 400
    letter-spacing: 0
    position: relative
    background: var(--colour-background-black)
    border-right: 1px solid var(--colour-background-black)
    border-left: 1px solid var(--colour-background-black)
    word-wrap: break-word
    cursor: pointer
    touch-action: manipulation

    &--active
        color: var(--background-menu-active)

    &--disabled
        filter: grayscale(80%)
        color: var(--be-colour-muted-text-dark)
        cursor: default

    &--active.be-sidebar-action--disabled
        color: black

    &--indented
        padding-left: 40px

    &:hover
        color: var(--be-colour-text-dark__hover)

</style>