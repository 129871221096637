<template>
    <div class="online-profile-row">
        <div class="online-profile-row__profile-container" @click="toggleShowAccounts"
             :style="gridColumns">
            <online-profile class="online-profile-row__profile" :profile="profile"/>
            <div class="online-profile-row__profile" v-if="profile.type === profileTypes.facebook">
                <online-profile v-if="linkedProfile" :profile="linkedProfile"/>
            </div>
            <div class="online-profile-row__action-button" v-if="canReauthoriseProfile">
                <be-button v-if="!profileReauthorised" @click.stop="refreshProfileAuth">Reauthorise</be-button>
                <slotted-tag v-else no-close>
                    <animated-check :animated="profileJustReauthorised === profile.handleId"/> Reauthorised
                </slotted-tag>
            </div>
            <div class="online-profile-row__action-button" v-if="canUnauthoriseProfile">
                <be-button @click.stop="unauthManagedProfile">Unauthorise</be-button>
            </div>
        </div>

        <keep-alive>
            <transition name="slide-show-account">
                <div v-if="showAccounts || expandRow" class="online-profile-row__accounts-container">
                    <div v-if="connectedAccounts.length">
                        <div>
                            <i :class="getProfileIcon(profile.type)">{{ profile.name }}</i>
                            <span v-if="linkedProfile"> and <i :class="getProfileIcon(linkedProfile.type)">{{ linkedProfile.name }}</i></span>
                            can be added to/removed from the following accounts. <span v-if="profile.type === profileTypes.linkedin_user">LinkedIn personal profiles can only be added to accounts that have Engage.</span>
                        </div>

                        <div v-if="connectedAccounts.length > 5" style="display: flex; align-items: center; gap: 30px">
                            <search-input
                                style="margin: 8px 0 10px 0; width: 65%"
                                placeholder="Type account code or name to search…"
                                v-model="accountSearchTerm"/>

                            <label class="checkbox" :tooltip="`Only show accounts that ${profile.name} ${linkedProfile ? ` and ${linkedProfile.handle} have` : `has`} been added to`">
                                <input type="checkbox" v-model="onlyAddedAccounts">
                                Only added
                            </label>
                        </div>

                        <div class="online-profile-row__accounts dark-scrollbars dark-scrollbars--visible">
                            <div v-for="account in filteredAccounts" :key="account.accountCode + profile.handleId" class="online-profile-row__account">
                                <a :href="`/${account.accountCode}/setup/online`" :tooltip="`Go to ${getAccountName(account.accountCode)}'s online profiles`" class="online-profile-row__account-name">
                                    {{ getAccountName(account.accountCode) }}
                                </a>
                                <div v-if="account.authorised" style="display: flex">
                                    <slotted-tag  style="height: 23px; margin-right: 10px"
                                                  :close-tooltip="`Click to remove ${profile.name} ${linkedProfile ? ` and ${linkedProfile.handle}` : ``} from ${getAccountName(account.accountCode)}`"
                                                  @close="removeProfileFromAccount(account)">
                                        <animated-check :animated="account.accountProfileJustAuthorised"/> Added
                                    </slotted-tag>

                                    <div v-if="hasDMs">
                                        <slotted-tag  v-if="!!account.onlineProfile.directMessagesEnabled"
                                                      style="height: 23px; margin-right: 15px"
                                                      :close-tooltip="`Click to disable DM's for ${profile.name} ${linkedProfile ? ` and ${linkedProfile.handle}` : ``} on ${getAccountName(account.accountCode)}`"
                                                      @close="toggleDms(account)">
                                            <animated-check :animated="account.accountProfileDmsJustToggled"/> DMs enabled
                                        </slotted-tag>
                                        <be-button v-else
                                                   :disabled="account.saving"
                                                   link
                                                   style="margin-right: 45px"
                                                   :tooltip="`Click to enable DM's for ${profile.name} ${linkedProfile ? ` and ${linkedProfile.handle}` : ``} on ${getAccountName(account.accountCode)}`"
                                                   @click="toggleDms(account)">
                                            Enable DMs
                                        </be-button>
                                    </div>


                                    <div v-if="!account.phraseMatching" @click="noPhraseMatchingDialog(account.accountCode, profile.name)" class="online-profile-row__phrase-match-warning">
                                        <i  class="icon-warning"></i>No phrase matching
                                    </div>
                                </div>

                                <be-button v-else
                                           :disabled="account.saving"
                                           :tooltip="`${!account.saving ? `Click to add ${profile.name} ${linkedProfile ? ` and ${linkedProfile.handle}` : ``} to ${getAccountName(account.accountCode)}`: ``}`"
                                           primary
                                           @click="addProfileToAccount(account)">
                                    <spinner-component v-if="account.saving" :size="11"></spinner-component>
                                        {{account.btnText ? account.btnText : "Add profile to account"}}
                                </be-button>

                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </keep-alive>
    </div>
</template>

<script>
import { isOps } from "@/app/Permissions";
import { showInfoDialog, showWhenDialog } from "@/app/framework/dialogs/Dialog";
import OnlineProfile from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfile";
import { notifyUserOfError, notifyWithText } from "@/app/framework/notifications/Notifications";
import SpinnerComponent from "@/components/SpinnerComponent";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import BeButton from "@/components/buttons/BeButton";
import SearchInput from "@/components/inputs/SearchInput";
import SlottedTag from "@/components/tags/SlottedTag";
import { getProfileAppId, getProfileIcon, profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef, mash } from "@/store/Services";
import Vue from 'vue';
import { mapState } from "vuex";

export default {
    name: "OnlineProfileRow",
    components: {SpinnerComponent, BeButton, AnimatedCheck, SlottedTag, OnlineProfile, SearchInput},

    props: {
        profile: {
            required: true,
            type: Object
        },
        linkedProfile: Object,
        reauthorisedProfiles: Set,
        profileJustReauthorised: String,
        expandRow: Boolean
    },

    data: function() {
        return {
            showAccounts: false,
            accountsMap: {},
            accountSearchTerm: "",
            onlyAddedAccounts: false,
            profileTypes: profileTypes
        }
    },

    computed: {
        ...mapState(['user']),

        ...mapState('userPlatformAuth', [
            'facebookUserToken'
        ]),

        isOps() {
            return isOps();
        },

        hasDMs(){
            switch(this.profile.type){
                case profileTypes.googleBusiness:
                case profileTypes.linkedin:
                case profileTypes.linkedin_user:
                case profileTypes.tiktok:
                    return false;
                default:
                    return true;
            }
        },

        canReauthoriseProfile() {
            switch(this.profile.type){
                case profileTypes.googleBusiness:
                case profileTypes.linkedin:
                case profileTypes.linkedin_user:
                case profileTypes.facebook:
                case profileTypes.tiktok:
                    return false;
                default:
                    return true;
            }
        },

        canUnauthoriseProfile() {
            switch(this.profile.type){
                case profileTypes.googleBusiness:
                case profileTypes.linkedin:
                case profileTypes.linkedin_user:
                // This is possible, it just requires some work. 
                // will do this if it comes up as a requirement, for now, clients can remove profiles one
                // by one from each account ( there shouldn't be that many accounts) 
                case profileTypes.tiktok:
                    return false;
                default:
                    return true;
            }
        },

        profileReauthorised() {
            return this.reauthorisedProfiles ? this.reauthorisedProfiles.has(this.profile.handleId) : false;
        },

        gridColumns() {
            switch (this.profile.type) {
                case profileTypes.facebook:
                    return {'grid-template-columns': 'var(--grid-columns-facebook)'};
                case profileTypes.linkedin:
                case profileTypes.linkedin_user:
                    return {'grid-template-columns': 'var(--grid-columns-linkedin)'};
                default:
                    return {'grid-template-columns': 'var(--grid-columns)'};
            }
        },

        connectedAccounts() {
            return this.profile.accounts ?? [];
        },

        filteredAccounts() {
          let searchTerm = this.accountSearchTerm.toLowerCase();

          return this.connectedAccounts.filter(acc => (acc.accountCode.toLowerCase().includes(searchTerm) ||
              this.getAccountName(acc.accountCode).toLowerCase().includes(searchTerm)) && (this.onlyAddedAccounts ? acc.authorised : true)).sort((a, b) => this.getAccountName(a.accountCode) > this.getAccountName(b.accountCode) ? 1 : -1);
        }
    },

    created() {
      if (this.expandRow) {
          this.showAccounts = true;
      }
    },

    methods: {
        getProfileIcon,

        getStatus(profile) {
            let status = {name: "icon-check-1", color: "#85b03d", description: "Authorised on all connected accounts"}

            if (profile.accounts?.find(account => account.authorised === false)) {
                status.name = "icon-warning";
                status.color = "orange";
                status.description = "Page is unauthorised on one or more connected accounts";
            }

            return status;
        },

        toggleShowAccounts() {
            this.$emit('collapse-row');

            this.showAccounts = !this.showAccounts;
        },

        getAccountName(code) {
            let accounts = this.user.accounts;
            let accountName = "";

            if (this.accountsMap[code]) {
                return this.accountsMap[code];
            }

            for (let i = 0; i < accounts?.length; i++) {
                let account = accounts[i];
                if (account.code === code) {
                    this.accountsMap[code] = account.name;
                    accountName = account.name;
                    break;
                }
            }

            return accountName
        },

        unauthManagedProfile() {
            this.$emit('unauth-profile', {profile: this.profile, linkedProfile: this.linkedProfile})
        },

        refreshProfileAuth() {
            this.$emit('refresh-profile-auth', this.profile.handle, this.profile.handleId, true);
        },

        noPhraseMatchingDialog(accountCode, profile) {
            showInfoDialog(`No brands have phrases that match profile "${profile}" in account ${accountCode} | ${this.getAccountName(accountCode)}. Data from this profile will not be collected for the account.
            \nPlease contact DataEQ support if you need assistance with setting up phrase matching.`, `No phrase matching for ${profile}`);
        },

        async addProfileToAccount(account) {
            let message = `Are you sure you want to add "${this.profile.name}" to account ${account.accountCode} | ${this.getAccountName(account.accountCode)}?`
            if (this.linkedProfile) message += ` The linked profile "${this.linkedProfile.handle}" will also be added.`

            showWhenDialog(message, "Add profile to account?")
                .then(async () => {
                    try {
                        account.saving = true;
                        account.btnText = "Adding profile to account...";

                        let payloadProfile = JSON.parse(JSON.stringify(this.profile));
                        delete payloadProfile.accounts;

                        let payload = {
                            pageLookups: [payloadProfile],
                            accountCode: account.accountCode,
                            appId: getProfileAppId(this.profile.type)
                        }

                        let icon = "<i class='icon-user'></i>";

                        switch (this.profile.type) {
                            case profileTypes.twitter:
                                icon = "<i class='symbol-twitter'></i>";
                                payload.appId = this.profile.apiAppId;
                                break;
                            case profileTypes.linkedin:
                            case profileTypes.linkedin_user:
                                icon = "<i class='symbol-linkedin'></i>";
                                payload.appId = this.profile.apiAppId;
                                break;
                            case profileTypes.googleBusiness:
                                icon = "<i class='symbol-google-full'></i>";
                                payload.appId = this.profile.apiAppId;
                                break;
                            case profileTypes.facebook:
                                icon = "<i class='symbol-facebook-rect'></i>";
                                break;
                            case profileTypes.trustpilot:
                                icon = "<i class='symbol-trustpilot'></i>";
                                break;
                        }
                        await beef.post("/api/online-profile/add-managed-profiles", payload);

                        // if the managed profile is not on the account yet, we need to fetch it after the profile is added to the account.
                        let response = await mash.get(`/rest/accounts/${account.accountCode}/online-profiles/handleId/${this.profile.handleId}`);

                        if (!account.onlineProfile) {
                            Vue.set(account, "onlineProfile", response.data);
                        }

                        account.authorised = true;
                        account.authorisedByCurUser = true;
                        account.accountProfileJustAuthorised = true;
                        account.accountProfileDmsJustToggled = !!account.onlineProfile.directMessagesEnabled;

                        notifyWithText(`${this.profile.name} has been authorised on account ${account.accountCode}.`,
                            null,
                            icon);
                    } catch (error) {
                        console.error(`An error occurred while trying to authorise user profile ${this.profile.handleId} on account ${account.accountCode}`, error);

                        notifyUserOfError(`An error occurred while authorising your profile on account ${account.accountCode}. Please try again or contact support.`);
                    } finally {
                        account.saving = false;

                        window.setTimeout(() => {
                            account.accountProfileJustAuthorised = false
                            account.accountProfileDmsJustToggled = false;
                        }, 1000);

                        account.btnText = "Add profile to account";
                    }
                });
        },

        async toggleDms(account) {
            let value = !account.onlineProfile.directMessagesEnabled;

            let message = "";
            if (value) {
                message = `If enabled, direct messages for profile ${account.onlineProfile.name} will be visible to all users of this DataEQ account. Direct messages sent to the Crowd have numbers and email addresses anonymised.`
            } else {
                message = `If disabled, direct messages for profile ${account.onlineProfile.name} will no longer be shown to users of this DataEQ account`
            }

            showWhenDialog(message, "Enable Direct Messages?")
                .then(async () => {
                    try {
                        account.saving = true;

                        account.accountProfileDmsJustToggled = value;
                        account.onlineProfile.directMessagesEnabled = value;

                        await mash.put(`/rest/accounts/${account.accountCode}/online-profiles/${account.onlineProfile.id}`, account.onlineProfile);
                    } catch (error) {
                        console.error(`An error occurred while trying to authorise user profile ${this.profile.handleId} on account ${account.accountCode}`, error);

                        account.accountProfileDmsJustToggled = !value;

                        notifyUserOfError(`An error occurred while authorising your profile on account ${account.accountCode}. Please try again or contact support.`);
                    } finally {
                        account.saving = false;

                        window.setTimeout(() => {
                            account.accountProfileDmsJustToggled = false;
                        }, 1000);
                    }
                });
        },

        async removeProfileFromAccount(account) {
            let message = `Are you sure you want to remove "${this.profile.name}" from account ${account.accountCode} | ${this.getAccountName(account.accountCode)}?
            ${account.phraseMatching ? `Data will stop being collected for this profile if removed.`: ``}`
            if (this.linkedProfile) message += ` This also applies to the linked profile "${this.linkedProfile.handle}".`
            showWhenDialog(message, "Remove profile from account?")
                .then(async () => {
                    try {
                        account.saving = true;
                        account.authorised = false;
                        account.btnText = "Removing profile...";

                        let icon = "<i class='icon-user'></i>"

                        switch (this.profile.type) {
                            case profileTypes.twitter:
                                icon = "<i class='symbol-twitter'></i>";
                                break;
                            case profileTypes.facebook:
                                icon = "<i class='symbol-facebook'></i>";
                                break;
                            case profileTypes.linkedin:
                            case profileTypes.linkedin_user:
                                icon = "<i class='symbol-linkedin'></i>";
                                break;
                            case profileTypes.googleBusiness:
                                icon = "<i class='symbol-google-full'></i>";
                                break;
                            case profileTypes.trustpilot:
                                icon = "<i class='symbol-trustpilot'></i>";
                                break;
                            case profileTypes.tiktok:
                                icon = "<i class='symbol-tiktok'></i>"
                                break;
                        }

                        await mash.delete(`/rest/accounts/${account.accountCode}/online-profiles/${account.onlineProfile.id}`);

                        if (account.onlineProfile?.linkedPresenceId) {
                            await mash.delete(`/rest/accounts/${account.accountCode}/online-profiles/${account.onlineProfile.linkedPresenceId}`)
                        }

                        account.accountProfileJustAuthorised = false;

                        notifyWithText(`${this.profile.name} has been removed from account ${account.accountCode}.`,
                            null,
                            icon);
                    } catch (error) {
                        console.error(`An error occurred while trying to remove user profile ${this.profile.handleId} from account ${account.accountCode}`, error);

                        account.authorised = true;

                        notifyUserOfError(`An error occurred while removing your profile on account ${account.accountCode}. Please try again or contact support.`);
                    } finally {
                        account.saving = false;

                        account.btnText = "Add profile to account";
                    }
                });
        }
    }
}
</script>

<style scoped lang="sass">

.online-profile-row
    cursor: pointer
    --border: 1px solid #272727
    --grid-columns: 350px repeat(auto-fit, minmax(100px, 1fr))
    --grid-columns-linkedin: repeat(auto-fit, minmax(350px, 1fr))
    --grid-columns-facebook: 250px 250px repeat(auto-fit, minmax(100px, 1fr))

    &__profile-container
        display: grid
        box-sizing: border-box
        transition-property: background, margin
        transition-duration: 100ms
        background: #333
        border: var(--border)
        height: 50px

        &:hover
            background: var(--background-menu-hover)
            color: white

    & + .online-profile-row
        .online-profile-row__profile-container
            border-top: none

    &__profile
        display: flex
        border-right: 1px solid #272727

        &::v-deep .online-profile
            flex: 1


    &__status
        display: flex
        align-items: center
        height: 100%
        padding-left: 18px
        box-sizing: border-box
        width: 10%

    &__action-button
        display: flex
        align-items: center
        justify-content: center
        border-right: var(--border)

        &:last-of-type
            border-right: none

    &__accounts-container
        cursor: default
        margin: 10px 0
        border: 1px solid #272727
        background: #444
        max-width: 590px
        overflow: hidden
        padding: 10px 0 10px 10px
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4)
        border-radius: 3px

    &__accounts
        margin-top: 5px
        max-height: 200px
        overflow-y: auto

    &__account
        display: flex
        border-bottom: 1px solid #797979
        margin-top: 10px
        height: 27px
        width: 98%

    &__account-name
        width: 35%
        margin-right: 10px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap

    &__phrase-match-warning
        cursor: pointer
        font-size: 13px
        color: var(--be-colour-warning)

.slide-show-account-enter-active,
.slide-show-account-leave-active
    transition: all .1s ease

.slide-show-account-enter
    transform: translateY(-10px)
    opacity: 0

.slide-show-account-leave-to
    transform: translateY(-10px)
    opacity: 0

</style>