/**
 * Represents a tip.
 */
import {chances, pages, themes} from "./tip-utils";
import {gotoNotifications} from "@/setup/notifications/SetupNotificationUtilities";
import {gotoOnlineProfilesSetup} from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import VuexStore from "@/store/vuex/VuexStore";
import {escapeExpression} from "@/app/utils/StringUtils";
import {substituteTagParamaters} from "@/app/utils/Tags";

/**
 * These are the tips that we currently have.
 */
const tips = {};
export default tips;

const MAX_GLOSSARY_CHANCE = 0.5;
const DEFAULT_GLOSSARY_CHANCE = 0.02;

class Tip {
    id;
    #privateText;       // The tip text to show the user. Can contain html fragments. Ensure that all text is html safe.
    #chance;            // An optional percentage chance of showing the tip. Element of [0,1].
    predicate;          // An optional (possibly async) function that returns true if the tip should run or not. Good for extra testing.
    #description;       // Text shown to the user in the "View All Tips" dialog. Should provide more context on the tip. If a description is not provided, the tip text will be shown.
    theme;
    action;             // Optional action to be shown on the tip notification. format: {name: '', tooltip: '', method: () => {}}
    onboardingSequence; // Optional integer. Set if this tip should be shown before all other tips, as part of onboarding.
    noExport;           // Optional boolean. Whether this tip should be exportable via CSV download of all the tips, or not.
    isGlossary;         // Indicates whether this is a description for the glossary.
    keyboard;           // The keyboard shortcut a tooltip is related to
    glossaryTerm;       // If this is a glossary entry, this is the term being defined.
    seeAlso;            // An array of related terms to see.
    /** @type {?string[]} */
    sections;           // An array of sections this relates to.

    /**
     * @return {string}
     */
    get text() {
        return substituteTagParamaters(this.rawText);
    }

    get description() {
        return substituteTagParamaters(this.#description)
    }

    get rawText() {
        if (this.#privateText instanceof Function) {
            return this.#privateText();
        }
        return this.#privateText;
    }

    /**
     * @return {Number|null}
     */
    get chance() {
        if (this.isGlossary) return Math.min(this.#chance ?? 1, MAX_GLOSSARY_CHANCE);
        if (this.onboardingSequence) return null;               // Onboarding items always fire.
        return this.#chance;
    }

    constructor(id, text, description,
                theme, page,
                chance, predicate,
                action,
                onboardingSequence,
                noExport,
                isGlossary,
                keyboard, glossaryTerm, seeAlso, sections) {
        if (!id) throw new Error("An ID is required");
        if (tips[id]) throw new Error(`A tip with the ID [${id}] already exists`);
        if (!text) throw new Error("Tip text cannot be empty");
        if (chance && (chance < 0 || chance > 1)) throw new Error(`Tip chance must be within [0,1]. Was ${chance}`);
        chance ??= chances.STANDARD_CHANCE;

        this.id = id;
        this.#privateText = text;
        this.#description = description ?? this.text;
        this.theme = theme;
        this.page = page;
        this.#chance = chance ?? null;
        this.predicate = predicate ?? null;
        this.action = action ?? null;
        this.onboardingSequence = onboardingSequence ?? null;
        this.noExport = !!noExport;
        this.isGlossary = !!isGlossary;
        this.keyboard = keyboard ? keyboard.toLowerCase() : null;
        this.glossaryTerm = glossaryTerm ?? null;
        this.seeAlso = seeAlso ?? null;
        this.sections = sections ?? null;

        tips[id] = this;
    }
}

/**
 * @param {string} id
 * @param {string | Function<string>} text
 * @param {string | null,optional} description
 * @param {Object | null,optional} theme
 * @param {string | null,optional} page
 * @param {Number | null,optional} chance - The probability that this should occur. Defaults to {@link chances.STANDARD_CHANCE}
 * @param {Function<boolean> | null,optional} predicate
 * @param {Function|null, optional} action
 * @param {Number|null,optional} onboardingSequence
 * @param {boolean|null} [noExport = false]
 * @param {boolean} [isGlossary = false]
 * @param {string|null} [keyboard = null] - The keybaord shortcut, if any, that this tip is referring to
 * @param {?string} glossaryTerm
 * @param {?string[]} seeAlso
 * @param {?string[]} sections - List of IDs for sections a glossary term might be related to.
 * @return {Tip}
 */
function registerTip({id, text, description,
                         theme, page,
                         chance, predicate,
                         action,
                         onboardingSequence,
                         noExport,
                         isGlossary,
                         keyboard, glossaryTerm, seeAlso, sections}) {
    return new Tip(id, text, description, theme, page, chance, predicate, action, onboardingSequence, noExport, isGlossary, keyboard, glossaryTerm, seeAlso, sections);
}


let onboardingSequence = 1;

// ------------ Onboarding ------------------------------------
// These are all given sequence numbers to ensure the order in which they are shown.

new Tip(
    "ONBOARD_WELCOME_QUICKSTART",
    `<strong>Welcome to Analyse</strong>! If you need help getting started, 
                have a look at our <strong>Quickstart Guide</strong> in the <strong><i class="symbol-help"></i>Help</strong> menu.`,
    null,
    null,
    null,
    null,
    null,
    {
        name: "Open",
        tooltip: "Open the Quickstart Guide",
        method: () => window.open("https://storage.googleapis.com/brandseye-public-files/dataeq-analyse-quickstart-guide-1.1.pdf", "_blank")
    },
    onboardingSequence++
);

new Tip(
    "ONBOARDING_TIP_INTRODUCTION",
    `We will sometimes show you tips on how to use Analyse. You can turn tips on and off via 
            the <strong><i class="symbol-help"></i>Tips & Help</strong> dialog`,
    null,
    null,
    null,
    null,
    null,
    null,
    onboardingSequence++
);

registerTip({
    id: "onboarding:SHIFT-?",
    text: "<strong>Press <span class='be-tooltip__keyword-styling'>shift</span>+<span class='be-tooltip__keyword-styling'>?</span></strong> at any time to open the <strong><i class='symbol-help'></i>Tips & Help</strong> dialog",
    onboardingSequence: onboardingSequence++
});

/** -------------- Reports Panel ------------------  */


/** -------------- Reports Panel ------------------  */

// --------------- Metrics -----------------------

registerTip({
    id: "ENTER_METRIC",
    text: "<strong>Press <span class='be-tooltip__keyword-styling'>enter</span></strong> now to add a <strong>blank volume metric</strong>.",
    description: "Add a <strong>blank volume metric</strong>.",
    theme: themes.addingMetrics,
    page: pages.reports,
    keyboard: "enter"
});

new Tip(
    "SEARCH_METRIC",
    "<strong>Start typing to search</strong> for a metric that you want to use.",
    "When opening the Add Metric Dialog, you can <strong>start typing</strong> to <strong>search</strong> for the metric you'd like to use.",
    themes.addingMetrics,
    pages.reports
);

new Tip(
    "EDIT_METRIC_SHORTCUT",
    "Click on the metric's title to <strong>access the metric editor.</strong>",
    "You can <strong>edit a metric</strong> by clicking on it's title.",
    themes.editingMetrics,
    pages.reports
);

registerTip({
    id: "METRIC_PREVIEW",
    text: "You can press the <strong>Preview</strong> button to safely see any changes that you've made to this metric",
    description: "When editing metric settings, you can press the <strong>Preview</strong> button to safely see any changes that you've made",
    theme: themes.editingMetrics,
    page: pages.reports
});


new Tip(
    "EDIT_METRIC_COMPARE_BRANDS",
    "To compare data (e.g. brands), click the box labeled <strong>What do you want to compare?</strong>",
    "Some metrics allow you to compare data (e.g. brands) in their filters. To do this, edit a metric and click the box labeled <strong>What do you want to compare?</strong> The data will be compared according to the item that has been selected.",
    themes.editingMetrics,
    pages.reports
);

new Tip(
    "EDIT_METRIC_CHANGE_GEOMETRY",
    "Click the box labeled <strong>Geometry</strong> to change the type of visualisation shown.",
    "Data on metrics can be <strong>displayed in a variety of ways</strong>, such as: line charts, columns, rows, etc. This can be adjusted by opening the metric editor and clicking the box labeled <strong>Geometry</strong>.",
    themes.editingMetrics,
    pages.reports
);


// --------------- Dashboards -----------------------

registerTip({
    id: "DASHBOARD_WELCOME",
    text: "The <strong><i class='symbol-reports'></i>Reports Panel</strong> lets you, and others, create custom reports to answer the questions that you want to ask about your social media data.",
    chance: chances.ALWAYS,
    theme: themes.customisingDashboards,
    page: pages.reports,
});

registerTip({
    id: "DASHBOARD_NAVIGATE",
    text: "You can always <strong>press <span class='be-tooltip__keyword-styling'>g</span> followed by <span class='be-tooltip__keyword-styling'>R</span></strong> to return to the <strong><i class='symbol-reports'></i>Reports Panel</strong>",
});

new Tip(
    "CREATE_DASHBOARD_SHORTCUT",
    "<strong>Press <span class='be-tooltip__keyword-styling'>a</span></strong> to create a new dashboard.",
    "While viewing the list of dashboards, <strong>create a new dashboard</strong>.",
    themes.customisingDashboards,
    pages.reports,
    null,
    null, null, null, null, null, 'a'
);

new Tip(
    "DASHBOARD_SHOW_ARCHIVE",
    "<strong>Press <span class='be-tooltip__keyword-styling'>r</span></strong> to show your archived dashboards.",
    "While viewing the list of available dashboards, show or hide the archived dashboards.",
    themes.customisingDashboards,
    pages.reports,
    chances.LOW,            // Not super important to show
    async () => { // only show when there are archived dashboards.
        await VuexStore.dispatch('dashboards/refreshDashboards');
        return VuexStore.state.dashboards.dashboards.some(d => d.category === 'Archived');
    },
    null, null, null, false,
    "r"
);

registerTip({
    id: "DASHBOARD_SHOW_DASHBOARDS",
    text: "<strong>Press <span class='be-tooltip__keyword-styling'>d</span></strong> to open and close the list of dashboards.",
    description: "Show or hide the list of dashboards.",
    theme: themes.customisingDashboards,
    page: pages.reports,
    keyboard: "D"
});

registerTip({
    id: "DASHBOARD_REFRESH_DASHBOARDS",
    text: "<strong>Press <span class='be-tooltip__keyword-styling'>r</span></strong> to refresh the data shown on a dashboard.",
    description: "Refresh the data shown in your report.",
    theme: themes.customisingDashboards,
    page: pages.reports,
    keyboard: "R"
});

new Tip(
    "DASHBOARD_SETTINGS",
    "You can click on the dashboard's name to <strong>edit the dashboard's settings</strong>.",
    "When viewing your dashboard, you can click on it's name in order to <strong>adjust the settings</strong>.",
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "DASHBOARD_NAMING",
    "<strong>Naming your dashboard</strong> makes them easier to find again later.",
    "Once your dashboard has been created, consider <strong>naming it</strong> to make it easier to find later from the dashboard list.",
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "DASHBOARD_DESCRIPTIONS",
    "<strong>Giving a description to the dashboard</strong> is a great way to remember what your older dashboards were for.",
    "Once your dashboard has been created, consider <strong>giving it a description</strong> to ensure that you are able to easily remember what it was used for in the future.",
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "CUSTOMISE_DASHBOARD_FILTERS",
    "You can click on the section's name to <strong>open the dashboard filters</strong>.",
    "While viewing your dashboard, you can click on the section's name to <strong>open the dashboard filters</strong>. These filters will be applied to all metrics within the section.",
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "SECTION_DATE_RANGE_FILTERS",
    "Click the <strong>Published...</strong> box under the general tab to <strong>edit the date range for your section.</strong>",
    "On the section filter, Click the <strong>Published...</strong> box under the general tab to edit the date range for your section. By creating a date filter, you are choosing the date range to include in your section.",
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "SECTION_BRAND_FILTERS",
    "Click the checkbox <input type='checkbox' style='margin: 0' disabled> to <strong>select or deselect a brand.</strong>",
    "After opening the brand filters on the section filter, you can <strong>select or deselect a brand to be filtered on</strong> by clicking the checkboxes <input type='checkbox' style='margin: 0' disabled>.",
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "DOWNLOAD_GRAPH_AS_IMAGE",
    "Click <i class='symbol-download'></i> on the top right of the metric to download the graph/metric as an image.",
    "You can <strong>download metrics</strong> on your dashboard by clicking the <i class='symbol-download'></i> icon located on the top right of the metric.",
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "CREATE_NOTIFICATION_FOR_DASHBOARD",
    "From the <strong>Dashboard Options</strong> menu you can create a notification for this dashboard",
    `For any dashboard, you can create notifications to email the dashboard to you, either at specific times,
     or when mentions match a specific filter. You can find this in the <strong>Dashboard Options</strong> menu.`,
    themes.customisingDashboards,
    pages.reports
);

new Tip(
    "DASHBOARD_SEE_RECENT",
    "Quickly find dashboard that you have recently used in the  <strong>Recently Used</strong> folder",
    `You can find dashboard that you have recently used in the  <strong>Recently Used</strong> folder.`,
    themes.customisingDashboards,
    pages.reports
);

/** -------------- Mentions Panel ------------------  */

registerTip({
    id: "MENTIONS_NAVIGATE",
    text: "<strong>Pressing <span class='be-tooltip__keyword-styling'>g</span> followed by <span class='be-tooltip__keyword-styling'>M</span></strong> will bring you back to the <strong><i class='symbol-mentions'></i>Mentions Panel</strong>",
});

// --------------- Filtering -------------------

new Tip(
    "FILTER_MENTIONS",
    "Click the <a class='btn mentions-menu options popup-menu' style='pointer-events: none'>Filter mentions</a> button to change the set of mentions that is displayed. ",
    "The <strong>set of mentions displayed can be adjusted</strong> by clicking on the <a class='btn mentions-menu options popup-menu' style='pointer-events: none'>Filter mentions</a> button.",
    themes.filteringMentions,
    pages.mentions,
    chances.ALWAYS
);

registerTip({
    id: "MENTIONS_FILTER_SHORTCUT",
    text: "<strong>Press <span class='be-tooltip__keyword-styling'>f</span></strong> to open the <strong>filter panel</strong>.",
    description: "Open the filter panel.",
    theme: themes.filteringMentions,
    page: pages.mentions,
    keyboard: "f"
});

registerTip({
    id: "MENTIONS_REFRESH",
    text: "<strong>Press <span class='be-tooltip__keyword-styling'>r</span></strong> to <strong>refresh</strong> the mentions on display.",
    description: "<strong>Refresh</strong> the mentions on display.",
    theme: themes.filteringMentions,
    page: pages.mentions,
    keyboard: "r"
});

registerTip({
    id: "MENTIONS_CLEAR_FILTER",
    text: "<strong>Press <span class='be-tooltip__keyword-styling'>backspace</span></strong> to <strong>clear the current filter</strong>.",
    description: "<strong>Clear</strong> the current filter and reset it to default.",
    theme: themes.filteringMentions,
    page: pages.mentions,
    keyboard: "backspace"
});

new Tip(
    "UNDO_MENTIONS",
    "When you filter for different mentions, you can <strong>Undo</strong> the change in the notification popup to go back to your old mentions",
    "When you change the filter that you are using, you can click <strong>Undo</strong> on the popup notification to change back to your previous filter",
    themes.filteringMentions,
    pages.mentions
);


new Tip(
    "SORT_MENTIONS",
    "Click the <a class='btn mentions-menu options popup-menu' style='pointer-events: none'>Sort <span class=\"caret\"></span></a>  button to change the order in which mentions are displayed.",
    "Mentions shown on the page can be <strong>sorted</strong> by various fields by clicking on the <a class='btn mentions-menu options popup-menu' style='pointer-events: none'>Sort <span class=\"caret\"></span></a> button.",
    themes.filteringMentions,
    pages.mentions
);


new Tip(
    "SELECT_BRAND_MENTIONS",
    "Use the brands list (left-side of the screen) to <strong>quickly switch between brands</strong>.",
    "You can <strong>navigate between mentions for different brands and sub-brands</strong> using the brands list on the left-side of the Mentions page.",
    themes.filteringMentions,
    pages.mentions
);

// ----------- Exploring Mentions ----------------------

new Tip(
    "MENTION_PANEL_DASHBOARD",
    "You can create a dashboard from these mentions in the <strong>Options</strong> menu.",
    "You are able to create dashboards from the mentions currently in view by selecting <strong>Create dashboard</strong> from the <a class='btn mentions-menu options popup-menu' style='pointer-events: none'>Options <span class=\"caret\"></span></a> menu.",
    themes.exploringMentions,
    pages.mentions
);


new Tip(
    "DOWNLOAD_MENTION_AS_IMAGE",
    "To <strong>download a mention as an image</strong>, hover over the mention's bottom right corner and click the <i class='symbol-download'></i> button",
    "You can <strong>download mentions</strong> by clicking the <i class='symbol-download'></i> icon located on the bottom right of the mention. Hover over the mention to see the button.",
    themes.exploringMentions,
    pages.mentions
);

new Tip(
    "TRANSLATE_MENTION",
    "Need to <strong>translate a mention</strong> into a language you read? Hover over the mention's bottom right corner and click the <i class='symbol-translate'></i> button",
    null,
    themes.exploringMentions,
    pages.mentions
);



// ----------- Explore Panel ----------------------

new Tip(
    "EXPLORE_INTRO",
    "The <strong><i class='symbol-explore'></i>Explore panel</strong> gives an overview of what's happening in your account.",
    null,
    themes.exploringIntroduction,
    pages.explore,
    chances.ALWAYS
);

registerTip({
    id: "EXPLORE_NAVIGATE",
    text: "You can <strong>press <span class='be-tooltip__keyword-styling'>g</span> followed by <span class='be-tooltip__keyword-styling'>E</span></strong> to return to the <strong><i class='symbol-explore'></i>Explore Panel</strong>",
});

new Tip(
    "EXPLORE_DATE",
    "You can choose a <strong>brand and date range</strong> to explore in the page title. Look for the <i class='symbol-edit'></i>edit symbol.",
    null,
    themes.exploringIntroduction,
    pages.explore
);

new Tip(
    "EXPLORE_DASHBOARD",
    "Want to further explore this data? Click <strong><i class='symbol-reports'></i>create a dashboard</strong> to move over into customisable dashboards.",
    "Many sections of Explore allow you to <strong><i class='symbol-reports'></i>create a dashboard</strong> to customise how Explore is querying the data.",
    themes.exploringIntroduction,
    pages.explore
);



// Remove maybe since conversation view is buggy?
// new Tip(
//     "SEE_CONVERSATION_VIEW",
//     "Clicking the <i class='icon-chat'></i> icon on a mention will take you to the <strong>conversation view.</strong>",
//     "The <i class='icon-chat'></i> icon that appears on a mention indicates that it is <strong style='font-weight: bold'>part of a conversation</strong>. The number to the right of the icon indicates the engagement this particular mention received (replies or reshares). This conversation can be viewed in detail by clicking on the <i class='icon-chat'></i> icon.",
//     themes.exploringMentions,
//     pages.mentions);

/** -------------- Online Profiles ------------------  */

new Tip(
    "ADD_MULTIPLE_PROFILES",
    `You can add multiple managed Facebook Pages or Twitter Profiles to an account at the same time in the <strong><i class='symbol-online-profiles'></i> Profiles</strong> section of the Account Setup panel.`,
    `You can add multiple managed Facebook Pages or Twitter Profiles to an account at the same time in the <strong><i class='symbol-online-profiles'></i> Profiles</strong> section of the Account Setup panel 
by clicking the <strong>Add Profile</strong> button, selecting <strong><i class='symbol-facebook-rect'></i> Facebook Page</strong> or <strong><i class='symbol-twitter'></i> X Profile</strong> from the menu, 
and then selecting <strong><i class="icon-lock"></i> Managed Page/Profile</strong>.`,
    themes.onlineProfiles,
    pages.setup,
    null,
    null,
    {
        name: "See Profiles",
        tooltip: `Go to current account's Profiles`,
        method: () => gotoOnlineProfilesSetup()
    }
);

// ------------ todo: to be sorted and logged  ---------------------------

new Tip(
    "NOTIFICATIONS",
    `You can go to the <strong><i class='symbol-notification'></i> Notifications and Emails</strong> settings to subscribe to 
            near realtime email alerts of conversations as they happen.`,
    null, // Can be same as above.
    themes.notifications,
    pages.setup,
    chances.ALWAYS,
    null,
    {
        name: "See panel",                                  // todo. Needs a good short name
        tooltip: "Open the Notifications & Email panel",
        method: () => gotoNotifications()
    }
);

new Tip(
    "NOTIFICATIONS_ALERTS",
    `<strong>Alerts</strong> will let you know if conversations around a particular priority, such as 
            <be-rpcs-icon code="CANCEL" full></be-rpcs-icon> or <be-rpcs-icon full code="RISK"></be-rpcs-icon>,
            are increasing.`,
    null,
    themes.notifications,
    pages.setup,
    chances.MEDIUM,
    null // todo: alerts are enabled in the account
);

new Tip(
    "NOTIFICATIONS_WEEKLY",
    `<strong>Weekly summaries</strong> are emails that go out at a fixed time to summarise conversations concerning your brand.`,
    null,
    themes.notifications,
    pages.setup,
    chances.MEDIUM
);

new Tip(
    "NOTIFICATIONS_DASHBOARDS",
    `<strong>Email notifications</strong> can have <strong><i class="symbol-reports"></i> dashboards</strong> attached.`,
    null,
    themes.notifications,
    pages.setup,
    chances.MEDIUM
);


// ------------ Glossary ---------------------------
/**
 *
 * @param {string} id
 * @param {string} term
 * @param {string} shortText
 * @param {string} [longText?]
 * @param {string[]} [seeAlso?]
 * @param {string[]} [sections?]
 * @param {number} [chance=]
 * @return {void}
 */
function defineGlossary(id, term, shortText, {longText, seeAlso, sections , chance = DEFAULT_GLOSSARY_CHANCE} = {}) {
    registerTip({
        id,
        text: shortText,
        description: longText,
        isGlossary: true,
        glossaryTerm: term,
        seeAlso,
        sections
    })
}

defineGlossary(
    "glossary:SENTIMENT",
    "Sentiment",
    `<strong>Sentiment</strong> is the emotion that a mention expresses towards a brand. 
It can be <i class='icon-circle positive-sentiment'></i>positive, <i class='icon-circle negative-sentiment'></i>negative, or 
<i class='icon-circle neutral-sentiment'></i>neutral.`, {
        seeAlso: ["glossary:NET-SENTIMENT"],
        sections: ["EXPLORE"],
        chance: 1
    }
);


defineGlossary(
    "glossary:NET-SENTIMENT",
    "Net sentiment",
    `<strong>Net sentiment</strong> is an aggregated customer satisfaction metric. A positive value means that mentions were more positive than negative towards the brand.`,
    {
        longText: `<strong>Net sentiment</strong> is an aggregated customer satisfaction metric. We calculate it by subtracting the total number of positive mentions from the total number of negative mentions. 
        A positive value means that there were more positive than negative mentions. Net sentiment is usually represented as a percentage of all mentions. 
        Net sentiment is often described using a circle and colour. <i class='icon-circle negative-sentiment'></i> is negative sentiment,
        <i class="icon-circle positive-sentiment"></i> is positive, while <i class="icon-circle neutral-sentiment"></i> is neutral.`,
        seeAlso: ["glossary:SENTIMENT", "glossary:BENCHMARK-SENTIMENT", "glossary:PUBLIC-SENTIMENT", "glossary:PRIVATE-SENTIMENT",
            "glossary:OPERATIONAL-SENTIMENT", "glossary:REPUTATIONAL-SENTIMENT"],
        sections: ["EXPLORE"],
        chance: 1
    }
);

defineGlossary(
    "glossary:OPERATIONAL-SENTIMENT",
    "Operational sentiment",
    "<strong>Operational Sentiment</strong> is the net sentiment of consumers that are interacting publicly with the brand.",
    {
        longText: `<strong>Operational Sentiment</strong> is the net sentiment of consumers that are interacting publicly with the brand.
        <p>This only includes public data, and also includes requests for customer service and feedback given to the brand.`,
        seeAlso: ["glossary:SENTIMENT", "glossary:NET-SENTIMENT", "glossary:REPUTATIONAL-SENTIMENT"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:REPUTATIONAL-SENTIMENT",
    "Reputational sentiment",
    "<strong>Reputational Sentiment</strong> measures the net sentiment of consumer and press mentions that's not related to the brand's customer service.",
    {
        longText: `<strong>Reputational Sentiment</strong> measures the net sentiment of consumer and press mentions that's not related to the brand's customer service.
        <p>It only measures public conversations and includes sponsorship, campaigns and other reputational content. You can think of this as what the reputation of the brand is amongst non-customers.`,
        seeAlso: ["glossary:SENTIMENT", "glossary:NET-SENTIMENT", "glossary:OPERATIONAL-SENTIMENT"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:MENTION",
    "Mention",
    `<strong>Mentions</strong> are social media posts, such as tweets from Twitter, comments on Facebook, and video from YouTube.`,
    {
        seeAlso: ["glossary:CONVERSATION", "glossary:AUTHOR", "glossary:SOURCE", "glossary:ENGAGEMENT"],
        sections: ["EXPLORE"],
        chance: 1
    }
);

defineGlossary(
    "glossary:BENCHMARK-SENTIMENT",
    "Benchmark sentiment",
    "<strong>Benchmark Sentiment</strong> looks at all of your public mentions, excluding your campaigns.",
    {
        longText: "<strong>Benchmark Sentiment</strong> lets your compare the sentiment of public conversations, excluding your campaigns, which can be a useful way to compare yourself against your competitors.",
        seeAlso: ["glossary:NET-SENTIMENT"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:PRIVATE-SENTIMENT",
    "Private sentiment",
    "<strong>Private Sentiment</strong> shows the net sentiment of <strong>Direct Messages</strong>. This can be very different from Public Sentiment.",
    {
        seeAlso: ["glossary:NET-SENTIMENT"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:PUBLIC-SENTIMENT",
    "Public sentiment",
    "<strong>Public Sentiment</strong> shows the net sentiment of <strong>public posts</strong>.",
    {
        seeAlso: ["glossary:NET-SENTIMENT"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:TAG",
    "Custom tag",
    `<strong>Custom Tags</strong> are a way for you to label specific mentions so that you can easily find them in either 
        the <strong><i class='symbol-reports'></i>Reports Panel</strong> or the <strong><i class="symbol-mentions"></i>Mentions Panel</strong>.`
);

defineGlossary(
    "glossary:ENTERPRISE",
    "Enterprise",
    "<strong>Enterprise mentions</strong> are mentions posted by the brand Analyse is tracking.",
    {
        longText: `The word <strong>Enterprise</strong> relates to the brand whose data we're looking at. If a mention was posted by 
the brand itself, we say it's an <strong>Enterprise mention</strong>.`,
        seeAlso: ["glossary:PRESS", "glossary:CONSUMER"]
    }
);

defineGlossary(
    "glossary:CONVERSATION",
    "Conversation",
    `A <strong>conversation</strong> is a chain of mentions that are all related to each other. This includes one <em>head post</em>, as well as subsequent replies or reshares.`,
    {
        longText: `A <strong>conversation</strong> is a chain of mentions that are all related to each other. This includes one <em>head post</em>, as well as subsequent replies or reshares.
A Twitter thread or a Facebook post with all of its replies are examples of a conversation.`,
        seeAlso: ["glossary:MENTION"]
    }
);

defineGlossary(
    "glossary:PRESS",
    "Press",
    "<strong>Press mentions</strong> are mentions posted by news sources.",
    {
        longText: `The word <strong>Press</strong> relates to news sources. If a mention was posted by 
a news outlet, we say that it's an <strong>Press mention</strong>.`,
        seeAlso: ["glossary:ENTERPRISE", "glossary:CONSUMER"]
    }
);

defineGlossary(
    "glossary:CONSUMER",
    "Consumer",
    "<strong>Consumer mentions</strong> are mentions posted by sources that are not Press or Enterprise sources.",
    {
        longText: `<strong>Consumer mentions</strong> are mentions posted by sources that are not Press or Enterprise sources.`,
        seeAlso: ["glossary:ENTERPRISE", "glossary:PRESS"]
    }
);

defineGlossary(
    "glossary:AUTHOR",
    "Author",
    `An <strong>author</strong> is the person or entity responsible for posting a mention.`,
    {
        longText: `An <strong>author</strong> is the person or entity responsible for posting a mention. Some sources, such as websites and some news sites, might not have author information.`
    }
);

defineGlossary(
    "glossary:INTERACTION",
    "Interaction",
    `An <strong>interaction</strong> refers to a single work item that a brand needs to address. It consists of a mention (post) 
or set of mentions (posts) from a consumer, followed by a reply or set of replies from a brand (within a conversation).`,
    {
        longText: `An <strong>interaction</strong> refers to a single work item that a brand needs to address. It consists of a mention (post) or set of mentions (posts) from a consumer, followed by a reply or set of replies from a brand (within a conversation).
        <p>To keep track of different interactions, a new interaction is formed in the following scenarios:
        <ul>
            <li>If the consumer posts again, after the brand reply, this starts a new interaction.</li>
            <li>If there is a gap of more than 14 days between posts from the consumer or brand.</li>
            <li>If the author switches between private and public communication.</li>
        </ul>`
    }
);

defineGlossary(
    "glossary:CONDUCT",
    "Conduct",
    `<strong>Conduct</strong> relates to a brand's <strong>Market Conduct</strong>. Conduct mentions can be either public posts or direct messages.`,
    {
        longText: `<strong>Conduct</strong> relates to a brand's <strong>Market Conduct</strong>. Conduct mentions can be either public posts or direct messages.
        <p>Analyse tracks Market Conduct using a regulatory framework, such as the <em>Treating Customers Fairly</em> framework for financial institutions.`,
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:CX",
    "Customer experience",
    `DataEQ's <strong>Customer Experience</strong> solution is insights-based, and helps to identify areas of friction and opportunity a brand's customer journey.`,
    {
        longText: `DataEQ's <strong>Customer Experience</strong> solution is insights-based, and helps to identify areas of friction and opportunity a brand's customer journey.
The solution finds actionable opportunities for improvement and marks them with 
the <be-rpcs-icon code="PURCHASE" full></be-rpcs-icon>, <be-rpcs-icon code="CANCEL" full></be-rpcs-icon> and <be-rpcs-icon code="SERVICE" full></be-rpcs-icon> tags.`,
        seeAlso: ['glossary:PRIORITY', "glossary:OPERATIONAL", "glossary:REPUTATIONAL", "glossary:PURCHASE", "glossary:CANCEL", "glossary:SERVICE"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:OPERATIONAL",
    "Operational",
    `<strong>Operational</strong> data is public data that relates to a brand's Customer Experience. It is how their customers discuss them online.`,
    {
        seeAlso: ["glossary:CX"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:REPUTATIONAL",
    "Reputational",
    `<strong>Reputational</strong> data is public data that does not relate to a brand's Customer Experience. It is how the public sees and discusses them online`,
    {
        seeAlso: ["glossary:CX"],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:TOPIC",
    "Topic",
    `<strong>Topics</strong> identify the themes that are driving social media conversation. They allow you to perform root-cause analysis on the sentiment towards a brand.`,
    {
        longText: `<strong>Topic analysis</strong> enables a granular understanding of the specific issues driving sentiment. 
<p>We identify the industry-specific topics that are contained in individual social media posts. 
These are shown as tags on individual mentions, giving you the capability to perform root-cause analysis on the sentiment shown towards brands.`
    }
);

defineGlossary(
    "glossary:PRIORITY",
    "Priority conversation",
    `We categorise mentions using four <strong>priority conversation</strong> tags. We help you mitigate risk, improve retention & acquisition rates, and deliver superior customer experience.`,
    {
        longText: `We categorise mentions using four <strong>priority conversation</strong> tags. We help you mitigate risk, improve retention & acquisition rates, and deliver superior customer experience.
                    <p>The four priority conversation tags are <be-rpcs-icon code="RISK" full></be-rpcs-icon>, <be-rpcs-icon code="PURCHASE" full></be-rpcs-icon>, <be-rpcs-icon code="CANCEL" full></be-rpcs-icon>, <be-rpcs-icon code="SERVICE" full></be-rpcs-icon>`,
        seeAlso: ['glossary:CX', "glossary:RISK", "glossary:PURCHASE", "glossary:CANCEL", "glossary:SERVICE"]
    }
);

defineGlossary(
    "glossary:RISK",
    "Risk",
    `Mentions tagged with <be-rpcs-icon code="RISK" full></be-rpcs-icon> pose an immediate reputational risk.`,
    {
        seeAlso: ["glossary:PRIORITY"]
    }
);

defineGlossary(
    "glossary:PURCHASE",
    "Purchase",
    `Mentions tagged with <be-rpcs-icon code="PURCHASE" full></be-rpcs-icon> are from prospective customers who want to purchase or sign up to %brand%'s products or services.`,
    {
        seeAlso: ["glossary:PRIORITY"]
    }
);

defineGlossary(
    "glossary:CANCEL",
    "Cancel",
    `Mentions tagged with <be-rpcs-icon code="CANCEL" full></be-rpcs-icon> are from customers looking to cancel their service or not buy from %brand% again.`,
    {
        seeAlso: ["glossary:PRIORITY"]
    }
);

defineGlossary(
    "glossary:SERVICE",
    "Service",
    `Mentions tagged with <be-rpcs-icon code="Service" full></be-rpcs-icon> are from customers either requesting service from %brand%, or describing their experience.`,
    {
        seeAlso: ["glossary:PRIORITY"]
    }
);

defineGlossary(
    "glossary:ENGAGEMENT",
    "Engagement",
    `<strong>Engagement</strong> counts how many times a mention has been replied to or reshared.`,
    {
        longText: `<strong>Engagement</strong> counts how many times a mention has been replied to or reshared. 
<p>When counting replies, it only counts the number of direct replies to the mention`,
        seeAlso: ['glossary:GRAPH-ENGAGEMENT']
    }
);

defineGlossary(
    "glossary:GRAPH-ENGAGEMENT",
    "Graph engagement",
    `<strong>Graph Engagement</strong> counts how many times a mention has been replied to or reshared, and how many times these replies and reshares have in-turn been replied to or reshared.`,
    {
        longText: `<strong>Graph Engagement</strong> counts how many times a mention has been replied to or reshared, as well as how many times these replies and reshares have in-turn been replied to or reshared.
<p>You can think of this as a measure of how <em>indirectly amplified</em> a mention has been, by users of a social network seeing the ongoing conversation the mention has caused.`,
        seeAlso: ['glossary:ENGAGEMENT'],
        sections: ["EXPLORE"]
    }
);

defineGlossary(
    "glossary:SOURCE",
    "Source",
    `A mention's <strong>source</strong> is the domain it comes from.`,
    {
        longText: `A mention's <strong>source</strong> is the domain it comes from. For example, a tweet's source is twitter.com, and an article's source might be iol.co.za.`
    }
);


// ------------ Jokes ---------------------------

new Tip(
    "OPS",
    "Remember OPS user: with great power comes great <strong>R̶E̷S̵P̶O̵N̵S̷I̴B̵I̷L̶I̴T̶Y̶</strong>",
    null,
    null,
    null,
    0.05,
    () => VuexStore.state.user.ops,
    null,
    null,
    true
);

new Tip(
    "READING_THIS",
    ()  => {
        const name = VuexStore.getters.userPreferredName;
        return `Are you reading this, ${name}?`;
    },
    null,
    null,
    null,
    0.001,
    () => VuexStore.state.user.admin,
    null,
    null,
    true
);

new Tip(
    "SARAH_CONNOR",
    "🤖 Sarah Connor?",
    null,
    null,
    null,
    0.001,
    () => VuexStore.state.user.admin,
    null,
    null,
    true
);

new Tip(
    "REAL_EYES",
    "Real Eyes, Realize, Analyse 👀",
    null,
    null,
    null,
    0.001,
    () => VuexStore.state.user.admin,
    null,
    null,
    true
);

new Tip(
    "KRAKEN",
    "RELEASE THE KRAKEN 🐙",
    null,
    null,
    null,
    0.001,
    () => VuexStore.state.user.admin,
    null,
    null,
    true
);

new Tip(
    "RICK",
    () => {
        const name = escapeExpression(VuexStore.getters.userPreferredName);
        return `<strong>${name}</strong>, this <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' target='_blank'>video</a> explains it all`
    },
    null,
    null,
    null,
    0.001,
    () => VuexStore.state.user.admin,
    null,
    null,
    true
);



