<template>
    <tooltip-component>
        <span>{{ formattedCurrency }}</span>
        <template #tooltip>
            {{ tooltip }}
        </template>
    </tooltip-component>

</template>

<script>
import TooltipComponent from "@/components/tooltip/TooltipComponent";
export default {
    name: "DeqCurrency",
    components: {TooltipComponent},
    props: {
        currency: {
            type: String,
            required: true
        }
    },

    computed: {
        formattedCurrency() {
            let currencyCode = this.currency.toUpperCase();
            return Beef.CurrencyPicker.getCurrency(currencyCode);
        },

        tooltip() {
            return `The ${this.formattedCurrency} has currency code ${this.currency.toUpperCase()}`;
        }
    }
}
</script>

<style scoped>

</style>