<template>
    <widget-settings-dialog :model="model"
                            :dto="dto"
                            @input="$emit('input', $event)" width="500px"
                            class-name="widget-settings-dialog">
        <label>
            <input type="checkbox" v-model="attrs.withDescription"> Show description 
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withSentiment"> Show sentiment
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withTotal"> Show total mentions
        </label>
        <label>
            <input type="checkbox" v-model="attrs.withAuthors"> Show unique authors
        </label>
    </widget-settings-dialog>
</template>


<script>

import WidgetSettingsDialog from "@/dashboards/widgets/WidgetSettingsDialog";

export default {
    components: {WidgetSettingsDialog},

    props: {
        model: Object
    },

    data() {
        return {
            dto: {
                attrs: { }  // this gets set by widget-settings-dialog
            },
        }
    },

    computed: {
        attrs() { return this.dto.attrs },
    },
}

</script>


<style scoped lang="sass">

label
    display: flex
    align-items: flex-start

    input[type='checkbox']
        margin-right: 5px


::v-deep .control-form
    min-height: 200px !important



</style>