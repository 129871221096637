import {notifyWithText} from "@/app/framework/notifications/Notifications";
import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";

/**
 * Display a selection of widget types and change the type field in our model if the user chooses one.
 * Can also be used to just select a type by specifying 'select: true' in the options. Triggers a 'selected'
 * event with the type as an argument in that case.
 */
Beef.module("WidgetTypeChange").addInitializer(function(startupOptions) {

    this.View = Backbone.Marionette.Layout.extend({
        tagName: "div",

        attributes: { class: "widget-type-change dialog" },

        template: require("@/dashboards/WidgetTypeChange.handlebars"),

        regions: {
            palette:  ".dialog-body"
        },

        events: {
            "click": "click"
        },

        onRender: function() {
            if (!this.palette.currentView) {
                var that = this;
                var view = Beef.WidgetPalette.createView({title: this.options.title, group: this.options.group});
                view.on("new-picker", () => that.trigger("new-picker"));
                view.on("selected", function(widgetDesc) {
                    if (widgetDesc && (widgetDesc === "Authors" || widgetDesc.xAxis === "fullAuthor") && that.vorpal) widgetDesc = {type:"UberAuthors"};
                    if (that.options.select) {
                        that.options.popup.hide();
                        that.trigger("selected", widgetDesc);
                    } else {
                        var data = Beef.Sync.cloneModel(that.model);

                        var typeObject = Beef.WidgetRegistry.typeMap[widgetDesc.type];
                        var oldType = that.model.get('type');
                        var oldTypeObject = Beef.WidgetRegistry.typeMap[oldType];
                        var caption = that.model.get('caption');
                        if (oldType && caption) that.model.set('caption', caption.replace(oldType, widgetDesc.type));
                        if (typeObject && typeObject.onReplace) typeObject.onReplace(that.model, widgetDesc, oldTypeObject);
                        else that.model.set('type', widgetDesc.type);
                        that.options.popup.hide();
                        that.model.save();
                        notifyWithText(
                            "" + encloseInDisplayQuotes(caption) + " has been replaced.",
                            function() {
                                that.model.clear({silent: true});
                                that.model.set(data);
                                that.model.save();
                                notifyWithText("Your old metric has been returned.", null, "<i class='symbol-metric'></i>")
                            },
                            "<i class='symbol-metric'></i>"
                        )
                    }
                });
                this.palette.show(view);
            }
        },

        click: function(ev) {
            this.vorpal = true;
        }
    });

});