import {parsePhraseString} from "@/app/utils/Phrases";

/**
 * Render a phrase as tags.
 */
export default function(phrase, options) {
    var a = parsePhraseString(phrase);
    var tags = [];
    for (var i = 0; i < a.length; i++) {
        var code = a[i];
        var neg = code.charAt(0) == '-';
        var name = $('<pre>').text(code).html();
        tags.push("<span class='tag" + (neg ? " tag-neg" : "") + "'>" + name + "</span>");
    }
    return new Handlebars.SafeString(tags.join(""));
}