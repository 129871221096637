import { cheat, topLevelStats, sentiment, socialStats, rpcs } from "./MarkdownExamples";
import {showWhenDialog} from "@/app/framework/dialogs/Dialog";
import VuexStore from "@/store/vuex/VuexStore";

/**
 * Provides editing tools for editing Markdown.
 */
Beef.module("Markdown.Editor").addInitializer(function(startupOptions) {

    this.View = Beef.BoundItemView.extend({

        template: require("@/app/framework/markdown/MarkdownEditor.handlebars"),

        attributes: { class: "markdown-editor" },

        events: {
            "keyup .text": "keyUp",
            "click .undo": "undo",
            "click .cheat": "cheat",
            "click .statistics": "statistics",
            "click .btn.header": "headerClicked",
            "click .btn.style": "styleClicked",
            "click .btn.bullet": "bulletClicked",
            "click .btn.count": "countClicked",
            "click .btn.top": "topClicked",
            "click .btn.static": "staticClicked",
            "click .btn.summary": "summaryClicked",
            "mousedown": "closeMenus"
        },

        templateHelpers: function() {
            return {
                field: this.field,
                simple: this.simple,
                showExamples: this.showExamples
            }
        },

        initialize: function(options) {
            this.field = this.options.field || "text";
            this.simple = this.options.simple
            this.showExamples = !!this.options.examples;
            this.previousText = this.model.get(this.field);

            this.keyDownHandler = this.keyDown.bind(this);
            document.defaultView.addEventListener("keydown", this.keyDownHandler);
        },

        onClose: function() {
            document.defaultView.removeEventListener("keydown", this.keyDownHandler);
            this.closeMenus();
        },

        closeMenus: function() {
            if (this.menu) {
                this.menu.close();
                this.menu = null;
            }
        },

        onFirstRender: function() {
            this.textEl = this.$('textarea')[0];
        },

        keyDown: function(ev) {
            if (!this.$el.is(':visible')) return;

            if (ev.keyCode === 66 && ev.ctrlKey) {
                ev.preventDefault();
                ev.stopPropagation();
                this.addStyle("**");
                return;
            }

            if (ev.keyCode === 73 && ev.ctrlKey) {
                ev.preventDefault();
                ev.stopPropagation();
                this.addStyle("_");
                return;
            }

            if ((ev.keyCode === 49 || ev.keyCode === 50 || ev.keyCode === 51) && ev.ctrlKey && ev.shiftKey) {
                ev.preventDefault();
                ev.stopPropagation();
                this.addHeader(String.fromCharCode(ev.keyCode));
                return;
            }

            if (ev.keyCode === 55 && ev.ctrlKey && ev.shiftKey) {
                ev.preventDefault();
                ev.stopPropagation();
                this.addBullet("1.");
                return;
            }

            if (ev.keyCode === 56 && ev.ctrlKey && ev.shiftKey) {
                ev.preventDefault();
                ev.stopPropagation();
                this.addBullet("-");
                return;
            }


        },

        keyUp: function(ev) {
            // Allow the return key to enter a newline and not close dialogs
            if (ev.keyCode === 13) {
                ev.stopPropagation();
            }

            if (ev.keyCode === 27 && this.options.originalModel) {
                if (this.$('.text').val() === this.options.originalModel.get(this.field)) {
                    return;
                }

                if (this.options.owner) {
                    ev.preventDefault();
                    ev.stopPropagation();

                    showWhenDialog("Cancel changes?", "You've made changes to this commentary box. Are you sure you want to cancel them?")
                        .then(function() {
                            this.options.owner.close();
                        }.bind(this));
                    return;
                }
            }

            if (ev.keyCode === 27 && this.options.closeHandler) {
                ev.preventDefault();
                ev.stopPropagation();

                this.options.closeHandler();
                return;
            }
        },

        cheat: function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.model.set(this.field, cheat);
        },

        undo: function() {
            showWhenDialog("Undo changes?", "Would you like to revert all of your changes?")
                .then(function() {
                    this.model.set(this.field, this.previousText);
                }.bind(this));
        },

        headerClicked: function(ev) {
            this.addHeader(ev.currentTarget.dataset.level);
        },

        addHeader: function(level) {
            var text = this.textEl.value || "";

            var header;
            switch (level) {
                case "1": header = "# "; break;
                case "2": header = "## "; break;
                case "3": header = "### "; break;
                default:
                    header = "#### "; break;
            }

            var startIndex = this.textEl.selectionStart,
                endIndex = this.textEl.selectionEnd;

            var newlineIndex, begin, end;
            if (startIndex === endIndex) {
                newlineIndex = text.substring(0, startIndex).lastIndexOf("\n") + 1;

                begin = text.substring(0, newlineIndex);
                end = text.substring(newlineIndex);

                text = begin + header + end;
                startIndex = startIndex + header.length;
                endIndex = endIndex + header.length;
            } else {
                begin = text.substring(0, startIndex);
                var middle = text.substring(startIndex, endIndex);
                end = text.substring(endIndex);

                middle = "\n\n " + header + middle + "\n\n";
                text = begin + middle + end;

                startIndex = startIndex + header.length + 3;
                endIndex = endIndex + header.length + 3;
            }

            this.model.set(this.field, text);
            this.textEl.focus();
            this.textEl.selectionStart = startIndex;
            this.textEl.selectionEnd = endIndex;
        },

        bulletClicked: function(ev) {
            this.addBullet(ev.currentTarget.dataset.bullet);
        },

        addBullet: function(bullet) {
            var text = this.textEl.value || "";

            var startIndex = this.textEl.selectionStart,
                endIndex = this.textEl.selectionEnd;

            var begin = text.substring(0, startIndex);
            var middle = text.substring(startIndex, endIndex);
            var end = text.substring(endIndex);

            var insertNlAtStart = startIndex > 0 ? begin.charAt(startIndex - 1) !== "\n" : false;
            if (!middle.length) {
                text = begin + (insertNlAtStart ? "\n" : "") + bullet + " " + end;
                startIndex = startIndex + bullet.length + 1 + (insertNlAtStart ? 1 : 0);
                endIndex = endIndex + bullet.length + 1 + (insertNlAtStart ? 1 : 0);
            } else {
                var lines = middle
                    .split("\n")
                    .map(function(l) { return l.trim() })
                    .filter(function(l) {
                        return !!l.length;
                    })
                    .map(function(l) {
                        return bullet + " " + l;
                    });

                var startAndMiddle = begin + (insertNlAtStart ? "\n" : "") + lines.join("\n");
                text = startAndMiddle + end;
                endIndex = startAndMiddle.length + (insertNlAtStart ? 1 : 0);
            }

            this.model.set(this.field, text);
            this.textEl.focus();
            this.textEl.selectionStart = startIndex;
            this.textEl.selectionEnd = endIndex;

        },

        styleClicked: function(ev) {
            this.addStyle(ev.currentTarget.dataset.style);
        },

        addStyle: function(style) {
            var text = this.textEl.value || "";

            var startIndex = this.textEl.selectionStart,
                endIndex = this.textEl.selectionEnd;

            var begin = text.substring(0, startIndex);
            var middle = text.substring(startIndex, endIndex);
            var end = text.substring(endIndex);

            text = begin + style + middle + style + end;

            this.model.set(this.field, text);
            this.textEl.focus();
            this.textEl.selectionStart = startIndex + style.length;
            this.textEl.selectionEnd = endIndex + style.length;
        },

        countClicked: function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.closeMenus();

            var menu = [
                {
                    text: "Mentions",
                    method: "mentions"
                },
                {
                    text: "Verified mentions",
                    method: "verifiedMentions"
                },
                Beef.MiniMenu.divider,
                {
                    text: "Public sentiment",
                    method: "publicSentiment"
                },
                {
                    text: "Benchmark sentiment",
                    method: "benchmarkSentiment"
                },
                {
                    text: "Net sentiment",
                    method: "netSentiment"
                },
                {
                    text: "Net sentiment%",
                    method: "netSentimentPercent"
                },
                Beef.MiniMenu.divider,
                {
                    text: "Negative sentiment",
                    method: "negSentiment"
                },
                {
                    text: "Neutral sentiment",
                    method: "neutSentiment"
                },
                {
                    text: "Positive sentiment",
                    method: "posSentiment"
                },
                Beef.MiniMenu.divider,
                {
                    text: "Unique authors",
                    method: "authors"
                },
                {
                    text: "Unique sources",
                    method: "sources"
                },
                Beef.MiniMenu.divider,
                {
                    text: "Engagement",
                    method: "engagement"
                },
                {
                    text: "OTS",
                    method: "ots"
                },
                Beef.MiniMenu.divider,
                {
                    text: "CX %",
                    method: "cxPercent"
                },
                {
                    text: "CX Reshare%",
                    method: "cxResharePercent"
                },
                {
                    text: "Purchase%",
                    method: "cxPurchasePercent"
                },
                {
                    text: "Cancel%",
                    method: "cxCancelPercent"
                }
            ];

            if (VuexStore.state.account.showAVE) {
                menu.push(Beef.MiniMenu.divider);
                menu.push({
                    text: "Online AVE",
                    method: "onlineAve"
                })
            }


            this.menu = Beef.MiniMenu.show({
                object: {
                    mentions: function() { this.insert("{{total mentions}}") }.bind(this),
                    verifiedMentions: function() { this.insert("{{total mentions 'process is verified'}}") }.bind(this),
                    publicSentiment: function() { this.insert("{{total public-net%}}") }.bind(this),
                    benchmarkSentiment: function() { this.insert("{{total benchmark-net%}}") }.bind(this),
                    netSentiment: function() { this.insert("{{total net}}") }.bind(this),
                    netSentimentPercent: function() { this.insert("{{total net%}}") }.bind(this),
                    negSentiment: function() { this.insert("{{total negative}}") }.bind(this),
                    neutSentiment: function() { this.insert("{{total neutral}}") }.bind(this),
                    posSentiment: function() { this.insert("{{total positive}}") }.bind(this),
                    authors: function() { this.insert("{{total authors}}") }.bind(this),
                    sources: function() { this.insert("{{total sites}}") }.bind(this),
                    engagement: function() { this.insert("{{total engagement}}") }.bind(this),
                    ots: function() { this.insert("{{total ots}}") }.bind(this),
                    onlineAve: () => this.insert("{{total online-ave}}"),
                    cxPercent: () => this.insert("{{cx%}}"),
                    cxResharePercent: () => this.insert("{{cx-reshare%}}"),
                    cxPurchasePercent: () => this.insert("{{cx-purchase%}}"),
                    cxCancelPercent: () => this.insert("{{cx-cancel%}}"),
                },
                target: ev.target,
                dropdown: true,
                menu: menu
            })
        },

        topClicked: function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.closeMenus();

            var menu = [
                {
                    text: "Country",
                    method: "country"
                },
                {
                    text: "Language",
                    method: "language"
                },
                {
                    text: "Social network",
                    method: "socialNetwork"
                },
                {
                    text: "Source",
                    method: "source"
                },
                {
                    text: "Sentiment",
                    method: "sentiment"
                }
            ];

            this.menu = Beef.MiniMenu.show({
                object: {
                    country: function() { this.insert("{{top country \"country isnt 'UN'\"}}") }.bind(this),
                    language: function() { this.insert("{{top language 'language isnt unknown'}}") }.bind(this),
                    socialNetwork: function() { this.insert("{{top socialNetwork}}") }.bind(this),
                    source: function() { this.insert("{{top site}}") }.bind(this),
                    sentiment: function() { this.insert("{{top sentiment 'process is verified'}}") }.bind(this)
                },
                target: ev.target,
                dropdown: true,
                menu: menu
            })
        },

        summaryClicked() {
            this.insert("{{summarise-mentions}}");
        },

        staticClicked: function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.closeMenus();

            var menu = [
                {
                    text: "Brand name",
                    method: "brand"
                },
                {
                    text: "Brand name's",
                    method: "brandPossessive"
                },
                Beef.MiniMenu.divider,
                {
                    text: "Metric title",
                    method: "title"
                },
                Beef.MiniMenu.divider,
                {
                    text: "X logo",
                    method: "x"
                },
                {
                    text: "Twitter logo",
                    method: "twitter"
                },
                {
                    text: "TikTok logo",
                    method: "tiktok"
                },
                {
                    text: "Trustpilot logo",
                    method: "trustpilot"
                },
                {
                    text: "Facebook logo",
                    method: "facebook"
                },
                {
                    text: "Instagram logo",
                    method: "instagram"
                },
                {
                    text: "YouTube logo",
                    method: "youtube"
                },
                {
                    text: "LinkedIn logo",
                    method: "linkedin"
                },
                Beef.MiniMenu.divider,
                {
                    text: "Negative sentiment",
                    method: "negSentiment"
                },
                {
                    text: "Neutral sentiment",
                    method: "neutSentiment"
                },
                {
                    text: "Positive sentiment",
                    method: "posSentiment"
                },
                Beef.MiniMenu.divider,
                {
                    text: "Risk",
                    method: "risk"
                },
                {
                    text: "Purchase",
                    method: "purchase"
                },
                {
                    text: "Cancel",
                    method: "cancel"
                },
                {
                    text: "Service",
                    method: "service"
                },
            ];

            this.menu = Beef.MiniMenu.show({
                object: {
                    brand: function() { this.insert("{{brand}}") }.bind(this),
                    brandPossessive: function() { this.insert("{{brand's}}") }.bind(this),
                    title: function() { this.insert("{{title}}") }.bind(this),
                    twitter: function() { this.insert("{{twitter}}") }.bind(this),
                    x: function() { this.insert("{{x}}") }.bind(this),
                    tiktok: function() { this.insert("{{tiktok}}") }.bind(this),
                    trustpilot: function() { this.insert("{{trustpilot}}") }.bind(this),
                    facebook: function() { this.insert("{{facebook}}") }.bind(this),
                    instagram: function() { this.insert("{{instagram}}") }.bind(this),
                    youtube: function() { this.insert("{{youtube}}") }.bind(this),
                    linkedin: function() { this.insert("{{linkedin}}") }.bind(this),
                    negSentiment: function() { this.insert("{{negative-icon}}") }.bind(this),
                    neutSentiment: function() { this.insert("{{neutral-icon}}") }.bind(this),
                    posSentiment: function() { this.insert("{{positive-icon}}") }.bind(this),
                    risk: function() { this.insert("{{risk}}") }.bind(this),
                    purchase: function() { this.insert("{{purchase}}") }.bind(this),
                    cancel: function() { this.insert("{{cancel}}") }.bind(this),
                    service: function() { this.insert("{{service}}") }.bind(this)
                },
                target: ev.target,
                dropdown: true,
                menu: menu
            })
        },

        insert: function(toAdd) {
            var text = this.textEl.value || "";

            var startIndex = this.textEl.selectionStart,
                endIndex = this.textEl.selectionEnd;

            var begin = text.substring(0, startIndex);
            var end = text.substring(endIndex);

            text = begin + toAdd + end;

            this.model.set(this.field, text);
            this.textEl.focus();
            this.textEl.selectionEnd = (begin).length;
            this.textEl.selectionEnd = (begin + toAdd).length;
        },

        statistics: function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.closeMenus();

            var menu = [
                {
                    text: "Top-line stats",
                    tooltip: "Show overall volume and net sentiment as a 1×1 metric",
                    method: "topLine"
                },
                {
                    text: "Priority",
                    tooltip: "Show risk, purchase, cancel and service numbers in a 2×1 metric",
                    method: "priority"
                },
                {
                    text: "Sentiment",
                    tooltip: "Show sentiment volumes as 1×1 metric",
                    method: "sentiment"
                },
                {
                    text: "Social stats",
                    tooltip: "Show overall volumes from Twitter, Facebook, and Instagram as a 1×1 metric",
                    method: "social"
                }
            ];

            this.menu = Beef.MiniMenu.show({
                object: {
                    topLine: function() {
                        var updates = {width: 1, height: 1, 'hidden-title': true};
                        updates[this.field] = topLevelStats;
                        updates['hidden-title'] = true;
                        this.model.set(updates);
                    }.bind(this),

                    sentiment: function() {
                        var updates = {width: 1, height: 1, 'hidden-title': true};
                        updates[this.field] = sentiment;
                        updates['hidden-title'] = true;
                        this.model.set(updates);
                    }.bind(this),

                    social: function() {
                        var updates = {width: 1, height: 1, 'hidden-title': true};
                        updates[this.field] = socialStats;
                        this.model.set(updates);
                    }.bind(this),

                    priority: function() {
                        var updates = {width: 1, height: 2, 'hidden-title': true};
                        updates[this.field] = rpcs;
                        this.model.set(updates);
                    }.bind(this)
                },
                target: ev.target,
                dropdown: true,
                menu: menu
            })
        }


    });
});