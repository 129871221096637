import {showTooltipText} from "@/components/tooltip/TooltipUtilities";

/**
 * Drill down by adding/removing sentiment to the filter.
 */
Beef.module("Widget.VisibilitySelector").addInitializer(function(startupOptions) {

    this.type = {
        name:           "Visibility",
        description:    "Select whether to see public or direct mentions",
        width:          2,
        height:         2,
        group:          "selector",
        noSubFilter:    true,
        class:          'widget-dark'
    };

    this.View = Beef.Widget.SelectorWidget.View.extend({

        template: require("@/dashboards/widgets/selectors/VisibilitySelector.handlebars"),

        filterAttribute: "visibility",

        events: Object.assign({}, Beef.Widget.SelectorWidget.View.prototype.events, {
            'mouseenter tr[data-tooltip]': 'showTooltip'
        }),

        refresh() {
        },

        renderImpl() {
            this.model.generalData.set('_completed', true);
            const ifm = this.model.getInteractiveFilterModel();
            this.highlightSelectedRows(ifm.get(this.filterAttribute), false);
            this.listenTo(ifm, "change:_showWhatDrilldownSelection", () => {
                const cx = ifm.get('_showWhatDrilldownSelection');
                if (cx === "operational" || cx === "reputational") {
                    setTimeout(() => ifm.set(this.filterAttribute, "PUBLIC"));
                    this.$("[data-value!='PUBLIC']").toggleClass('disabled', true);
                } else {
                    this.$("[data-value!='PUBLIC']").toggleClass('disabled', false);
                }
            })
        },

        showTooltip(event) {
            showTooltipText(event.currentTarget, event.currentTarget.dataset.tooltip, {
                positions: ["right", "left"]
            })
        },
    });
});