<template>
    <div ref="holder" class="be-mention-item"></div>
</template>

<script>
import {toGrouseLink} from "@/data/Grouse";
import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex/dist/vuex.esm.browser";

export default {
    name: "MentionItem",
    store: VuexStore,

    props: {
        mention: { type: Object, required: true },
        options: { type: Object, default: () => ({}) },
        noSelect: { type: Boolean, default: true}
    },

    computed: {
        ...mapState(['account']),

        finalOptions() {
            return {noSelect: this.noSelect, ...this.options, };
        }
    },

    mounted() {
        // TODO: Enable rerender when model or options is updated
        const model = new Beef.MentionItem.Model({...this.mention});
        // Provide an url so that model saves / syncs know what to update.
        model.url = toGrouseLink('/v4/accounts/' + this.account.code + '/mentions/' + this.mention.id, null, true);
        this.view = new Beef.MentionItem.View({ model: model, ...this.finalOptions });
        model.view = this.view;
        this.view.render();
        this.$refs.holder.append(this.view.el);
    },

    beforeDestroy() {
        this.view?.close();
    }
}

</script>

<style lang="sass">

.be-mention-item
    display: inline-block
    .mention-item
        float: none

</style>