import { MentionQAst } from '@/mentionq/mentionq'
import {flattenFilterNodes, parseFilterString} from "@/dashboards/filter/FilterParser";
import {buildBasicFilter, convertExpToAttrs, filterToDateRange as basicToDateRange} from "@/dashboards/filter/BasicFilter";
import _ from 'underscore';
import moment from "moment";
import {publishedTagConverter} from "@/components/pickers/dates/DateRangePickerUtilities";


/**
 * A module for showing a search job filter. The module also contains utility functions for working with filters.
 */
Beef.module("SearchJobFilter").addInitializer(function(startupOptions) {

    this.createView = function(filter, published, customQuery) {
        var view = new View({ model: new Backbone.Model({
            published: published,
            customQuery: customQuery
        })} );
        view.setFilter(filter, true);
        return view;
    };

    var View = Beef.BoundItemView.extend({
        events: {
            'keyup .custom-gnip-rule': 'updateRule' ,
            'click #toggle-custom-gnip-rule': 'toggleCustomGnipRule'
        },

        attributes: { class: "root-brand-filter row-fluid" },

        template: require("@/historical-search/job/SearchJobFilter.handlebars"),

        templateHelpers: function() {
            return {
                showFeeds: false,
                hasCustomQuery: (typeof this.model.get("customQuery") === 'string')
            };
        },

        bindings: function() {
            return {
                location: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" },
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
                published: { converter: publishedTagConverter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            var calendarOptions = { maxDate: moment().subtract(1, "day").endOf("day").toDate() };
            Beef.LocationPicker.attach(this, ".location", "location", { onlyCountriesAndGroups: true });
            Beef.LanguagePicker.attach(this, ".language", "language");
            Beef.PublishedPicker.attach(this, ".published", "published", {notRequired: false, timeDisabled: true, calendarOptions: calendarOptions });
            const cQ = this.model.get("customQuery") && this.model.get("customQuery").trim().length > 0 ? this.model.get("customQuery").trim() : null;
            const that = this;
            if((typeof cQ) === 'string' && cQ.length > 0) {
                window.setTimeout(function() {
                    that.showCustomQuery();
                    $("textarea.custom-gnip-rule").val(cQ);
                }, 1);
            }
            else {
                window.setTimeout(function() {
                    that.hideCustomQuery();
                    $("textarea.custom-gnip-rule").val(cQ);
                }, 1);
            }
        },

        onClose: function() {
            if (this.popup) {
                this.popup.close();
            }
        },

        toggleCustomGnipRule: function(e) {
            e.preventDefault();
            var customGnipRuleDisplay = $('.custom-gnip-rule-row').css("display");
            if(customGnipRuleDisplay === "none") {
                const cQ = this.model.get("originalCustomQuery");
                this.model.set("customQuery", cQ);
                $("textarea.custom-gnip-rule").val(cQ);
                this.showCustomQuery();
            }
            else {
                this.model.set("originalCustomQuery", this.model.get("customQuery"));
                this.model.set("customQuery", null);
                this.hideCustomQuery();
            }
        },

        showCustomQuery: function() {           
            var normalFiltersRowDisplay = $(".normal-filters-row").css("display");
            $("#toggle-custom-gnip-rule").text("Use Phrases and Filters")
            $('.custom-gnip-rule-row').css("display", normalFiltersRowDisplay);
            $('.normal-filters-row').css("display", "none");
            $('.hide-this-too').css("display", "none");
        },

        hideCustomQuery: function() {
            var customGnipRuleDisplay = $('.custom-gnip-rule-row').css("display");
            $("#toggle-custom-gnip-rule").text("Use Custom GNIP Rule")
            $('.custom-gnip-rule-row').css("display", "none");
            $('.normal-filters-row').css("display", customGnipRuleDisplay);
            $('.hide-this-too').css("display", customGnipRuleDisplay);
        },

        updateRule: function(e) {
            var newValue = e.target.value && e.target.value.trim().length > 0 ? e.target.value.trim() : null;
            this.model.set("customQuery", newValue);
        },

        getCustomQuery: function() {
            return this.model.get("customQuery");
        },

        /**
         * Sets the various filters on this model from a mention filter.
         * @param {String} filter A flattened mention filter.
         * @param {Boolean} silent Set to true if setting the filter should not trigger an event.
         */
        setFilter: function(filter, silent) {
            var root = null;
            try {
                root = parseFilterString(filter);
            } catch (e) {
                if (e.stack) {
                    console.log(e, e.stack);
                } else {
                    console.log(e);
                }
            }
            this.model.set(Beef.SearchJobFilter.convertExpToAttrs(root), { silent: silent });
        },

        /**
         * Builds a basic mention filter from the search filters on this model. The different filters are joined with
         * an AND operator.
         * @return {String} A flattened mention filter.
         */
        getFilter: function() {
            var attrs = this.model.attributes;
            var filters = [];
            var countryFilter = attrs.location ? buildBasicFilter({ location: attrs.location }) : null;
            var languageFilter = attrs.language ? buildBasicFilter({ language: attrs.language }) : null;
            if (countryFilter) {
                filters.push(countryFilter);
            }
            if (languageFilter) {
                filters.push(languageFilter);
            }
            return (filters.length > 0) ? filters.join(" AND ") : "";
        },

        /**
         * Converts the published filter on this view's model into a clamped date range.
         * @return {{start: Date, end: Date }}
         */
        getDateRange: function() {
            return Beef.SearchJobFilter.toDateRange(this.model.get("published"));
        }
    });

    /**
     * Converts a published filter into a clamped and rounded date range.
     * @return {{start: Date, end: Date }}
     */
    this.toDateRange = function(published) {
        var publishedFilter = buildBasicFilter({ published: published });
        var range = basicToDateRange(publishedFilter);

        // the published picker has dates in the user's time zone, but is being used as if it is in UTC.
        // the dates have to be offset so that they get the correct UTC date, since they are currently
        // in the user's time zone.
        var offset = -moment().zone();
        var start = moment(range.start).add(offset, "minutes").toDate();
        var end = ((range.end === "today") ? Date.today() : range.end);
        end = moment(end).add(offset, "minutes").toDate();

        return Beef.SearchJobFilter.roundAndClampDates(start, end);
    };

    /**
     * Rounds and clamps two dates. A copy of the 'end' date  is rounded to the end of the UTC day in which the date falls.
     * The returned 'end' date is set to either the end of yesterday or the rounded date, whichever is the earliest.
     * The returned 'start' parameter is either the start of the UTC day in which the 'start' date falls, or the start
     * of rounded and clamped 'end' date, whichever is the earliest.
     *
     * @return {{start: Date, end: Date}}
     */
    this.roundAndClampDates = function(start, end) {
        var yesterday = moment().utc().subtract(1, "day").endOf("day");
        var rounded = Beef.SearchJobFilter.roundDates(start, end);
        var clampedStart = moment(rounded.start).utc();
        var clampedEnd = moment(rounded.end).utc();
        if (clampedEnd.isAfter(yesterday)) {
            clampedEnd = yesterday;
        }
        if (clampedStart.isAfter(clampedEnd)) {
            clampedStart = moment(clampedEnd).startOf("day");
        }
        return { start: clampedStart.toDate(), end: clampedEnd.toDate() };
    };

    /**
     * Returns a copy of rounded dates. The start is rounded to the start of a UTC day, while the end date is rounded to
     * the end of a UTC day.
     *
     * @return {{start: Date, end: Date}}
     */
    this.roundDates = function(start, end) {
        return {
            start: moment(start).utc().startOf("day").toDate(),
            end: moment(end).utc().endOf("day").toDate()
        };
    };

    /**
     * Returns the attributes of a brand or search filter within a map.
     */
    function extractAttributes(filter) {
        var root = parseFilterString(filter);
        var attrs = {};
        if (root) {
            root = flattenFilterNodes(root);
            attrs = Beef.SearchJobFilter.convertExpToAttrs(root);
        }
        return attrs;
    }

    /**
     * Checks whether the location and language attributes of two filters are identical.
     */
    this.compareFilterAttributes = function(filter1, filter2) {
        function sort(attr) {
            var terms = attr ? attr.split(' ') : [];
            var sorted = _.sortBy(terms, function(term) {
                return term;
            });
            return sorted.join(' ');
        }
        var attrs1 = extractAttributes(filter1);
        var attrs2 = extractAttributes(filter2);
        return (sort(attrs1.location) === sort(attrs2.location)) && (sort(attrs1.language) === sort(attrs2.language));
    };

    this.convertExpToAttrs = function(root) {
        var attrs = {};
        if (root) {
            root = flattenFilterNodes(root);
            visit(root, attrs);
            if (attrs.moreover) attrs.webhose = true;
            Object.assign(attrs, convertExpToAttrs(root, { noPublished: true }));
        }
        if (!attrs.location) {
            attrs.twitter = true;
            attrs.webhose = true;
        }
        return attrs;
    };

    var visit = function(node, attrs) {
        if (node.operandType == MentionQAst.FEED && node.operationType == MentionQAst.ISNT) {
            attrs[node.literal] = true;
            node.children = [];
        } else if (node.children) {
            var keep = [];
            for (var i = 0; i < node.children.length; i++) {
                var c = node.children[i];
                visit(c, attrs);
                if (!c.children) keep.push(c);
                else if (c.children.length == 1) keep.push(c.children[0]);
                else if (c.children.length > 0) keep.push(c);
            }
            if (keep.length < node.children.length) node.children = keep;
        }
    }
});
