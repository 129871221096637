<template>
    <section>
        <trend-table-section v-if="spiking.length && showSpiking"
                             :topics="spiking"
                             :all-data="topics"
                             :with-sentiment="withSentiment"
                             :with-footer="withFooter"
                             :with-maximum="withMaximum"
                             :with-max-date="withMaxDate"
                             :with-expected="withExpected"
                             :with-z-score="withZScore"
                             :with-average="withAverage"
                             :with-total="withTotal"
                             :with-description="withDescription"
                             :disabled="disabled"
                             @seeMentions="$emit('seeMentions', $event)"
                             @seeAuthors="$emit('seeAuthors', $event)"
                             @seeKeywords="$emit('seeKeywords', $event)"
                             @seeWordcloud="$emit('seeWordcloud', $event)"
                             @seeGraph="$emit('seeGraph', $event)"
                             :word-type="wordtype"
                             :show-restriction="sizeRestriction && sizeRestriction < allSpiking.length"
                             title="Spiking in this time period"
                             tooltip="These have had a spike of volume on one or more day"
                             @showMore="setShowMore('spiking')">
            <template #restriction>
                <old-tooltip label="Click to see more" v-if="showMore !== 'spiking'">See more...</old-tooltip>
                <old-tooltip v-else label="Click to see the other categories">See others...</old-tooltip>
            </template>
        </trend-table-section>

        <trend-table-section v-if="unusual.length && showUnusual"
                             :topics="unusual"
                             :all-data="topics"
                             :with-footer="withFooter"
                             :with-sentiment="withSentiment"
                             :with-maximum="withMaximum"
                             :with-max-date="withMaxDate"
                             :with-expected="withExpected"
                             :with-z-score="withZScore"
                             :with-average="withAverage"
                             :with-total="withTotal"
                             :with-description="withDescription"
                             :disabled="disabled"
                             @seeMentions="$emit('seeMentions', $event)"
                             @seeAuthors="$emit('seeAuthors', $event)"
                             @seeKeywords="$emit('seeKeywords', $event)"
                             @seeWordcloud="$emit('seeWordcloud', $event)"
                             @seeGraph="$emit('seeGraph', $event)"
                             :word-type="wordtype"
                             :show-restriction="sizeRestriction && sizeRestriction < allUnusual.length"
                             title="Unusually high volumes"
                             @showMore="setShowMore('unusual')"
                             :tooltip="`These volumes substantially above their long-term average volume${withZScore ? '. z-score is above 1.0.' : ''}`">
            <template #restriction>
                <old-tooltip label="Click to see more" v-if="showMore !== 'unusual'">See more...</old-tooltip>
                <old-tooltip v-else label="Click to see the other categories">See others...</old-tooltip>
            </template>
        </trend-table-section>

        <trend-table-section v-if="slightly.length && showSlightly"
                             :topics="slightly"
                             :all-data="topics"
                             :with-footer="withFooter"
                             :with-sentiment="withSentiment"
                             :with-maximum="withMaximum"
                             :with-max-date="withMaxDate"
                             :with-expected="withExpected"
                             :with-z-score="withZScore"
                             :with-average="withAverage"
                             :with-total="withTotal"
                             :with-description="withDescription"
                             :disabled="disabled"
                             @seeMentions="$emit('seeMentions', $event)"
                             @seeAuthors="$emit('seeAuthors', $event)"
                             @seeKeywords="$emit('seeKeywords', $event)"
                             @seeWordcloud="$emit('seeWordcloud', $event)"
                             @seeGraph="$emit('seeGraph', $event)"
                             :word-type="wordtype"
                             :show-restriction="sizeRestriction && sizeRestriction < allSlightly.length"
                             title="Slightly higher volumes"
                             @showMore="setShowMore('slightly')"
                             :tooltip="`These volumes are somewhat above their long-term average volume${withZScore ? '. z-score is between 0.5 and 1.0.' : ''}`">
            <template #restriction>
                <old-tooltip label="Click to see more" v-if="showMore !== 'slightly'">See more...</old-tooltip>
                <old-tooltip v-else label="Click to see the other categories">See others...</old-tooltip>
            </template>
        </trend-table-section>

        <trend-table-section v-if="normal.length && showExpected"
                             :topics="normal"
                             :all-data="topics"
                             :with-footer="withFooter"
                             :with-sentiment="withSentiment"
                             :with-maximum="withMaximum"
                             :with-max-date="withMaxDate"
                             :with-expected="withExpected"
                             :with-z-score="withZScore"
                             :with-average="withAverage"
                             :with-total="withTotal"
                             :with-description="withDescription"
                             :disabled="disabled"
                             @seeMentions="$emit('seeMentions', $event)"
                             @seeAuthors="$emit('seeAuthors', $event)"
                             @seeKeywords="$emit('seeKeywords', $event)"
                             @seeWordcloud="$emit('seeWordcloud', $event)"
                             @seeGraph="$emit('seeGraph', $event)"
                             :word-type="wordtype"
                             :show-restriction="sizeRestriction && sizeRestriction < allNormal.length"
                             title="Expected volumes"
                             @showMore="setShowMore('normal')"
                             :tooltip="`These volumes are close to their long-term average volume${withZScore ? '. z-score is between 0.5 and -0.5.' :''}`">
            <template #restriction>
                <old-tooltip label="Click to see more" v-if="showMore !== 'normal'">See more...</old-tooltip>
                <old-tooltip v-else label="Click to see the other categories">See others...</old-tooltip>
            </template>
        </trend-table-section>

        <trend-table-section v-if="low.length && showLow"
                             :topics="low"
                             :all-data="topics"
                             :with-footer="withFooter"
                             :with-sentiment="withSentiment"
                             :with-maximum="withMaximum"
                             :with-max-date="withMaxDate"
                             :with-expected="withExpected"
                             :with-z-score="withZScore"
                             :with-average="withAverage"
                             :with-total="withTotal"
                             :with-description="withDescription"
                             :disabled="disabled"
                             @seeMentions="$emit('seeMentions', $event)"
                             @seeAuthors="$emit('seeAuthors', $event)"
                             @seeKeywords="$emit('seeKeywords', $event)"
                             @seeWordcloud="$emit('seeWordcloud', $event)"
                             @seeGraph="$emit('seeGraph', $event)"
                             :word-type="wordtype"
                             :show-restriction="sizeRestriction && sizeRestriction < allLow.length"
                             title="Below average volumes"
                             @showMore="setShowMore('low')"
                             :tooltip="`These volumes are below their long-term average volume${withZScore ? '. z-score is below -0.5.' :''}`">
            <template #restriction>
                <old-tooltip label="Click to see more" v-if="showMore !== 'low'">See more...</old-tooltip>
                <old-tooltip v-else label="Click to see the other categories">See others...</old-tooltip>
            </template>
        </trend-table-section>
        <trend-table-section v-if="newTags.length && showNew"
                             :topics="newTags"
                             :all-data="topics"
                             :with-footer="withFooter"
                             :with-sentiment="withSentiment"
                             :with-maximum="withMaximum"
                             :with-max-date="withMaxDate"
                             :with-expected="withExpected"
                             :with-z-score="withZScore"
                             :with-average="withAverage"
                             :with-total="withTotal"
                             :with-description="withDescription"
                             :disabled="disabled"
                             @seeMentions="$emit('seeMentions', $event)"
                             @seeAuthors="$emit('seeAuthors', $event)"
                             @seeKeywords="$emit('seeKeywords', $event)"
                             @seeWordcloud="$emit('seeWordcloud', $event)"
                             @seeGraph="$emit('seeGraph', $event)"
                             :word-type="wordtype"
                             :show-restriction="sizeRestriction && sizeRestriction < allNewTags.length"
                             :title="`New ${wordtype}`"
                             @showMore="setShowMore('new')"
                             tooltip="These are new in the given time period, and we do not have enough data to know how we expect them to behave">
            <template #restriction>
                <old-tooltip label="Click to see more" v-if="showMore !== 'new'">See more...</old-tooltip>
                <old-tooltip v-else label="Click to see the other categories">See others...</old-tooltip>
            </template>
        </trend-table-section>
    </section>
</template>


<script>

import TrendTableSection from "@/app/toplevel/explore/overview/TrendTableSection";
import OldTooltip from "@/components/tooltip/OldTooltip";
import moment from "moment";

export default {
    components: {OldTooltip, TrendTableSection},

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        topics: {
            type: Array,
            required: true
        },
        showSpiking: {
            type: Boolean,
            default() { return true }
        },
        showUnusual: {
            type: Boolean,
            default() { return true }
        },
        showSlightly: {
            type: Boolean,
            default() { return true }
        },
        showExpected: {
            type: Boolean,
            default() { return true }
        },
        showLow: {
            type: Boolean,
            default() { return true }
        },
        showNew: {
            type: Boolean,
            default() { return false }
        },
        wordtype: {
            type: String,
            default() { return "Topic" }
        },
        withFooter: {
            type: Boolean,
            default() { return false }
        },
        withSentiment: {
            type: Boolean,
            default() { return true }
        },
        withMaximum: {
            type: Boolean,
            default() { return false }
        },
        withMaxDate: {
            type: Boolean,
            default() { return false }
        },
        withExpected: {
            type: Boolean,
            default() { return false }
        },
        withZScore: {
            type: Boolean,
            default() { return false }
        },
        withAverage: {
            type: Boolean,
            default() { return false }
        },
        withTotal: {
            type: Boolean,
            default() { return false }
        },
        withDescription: {
            type: Boolean,
            default() { return false }
        },
        sizeRestriction: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            showMore: null
        }
    },

    computed: {
        allSpiking() {
            return this.topics
                .filter(t => t.isSpike)
                .sort((lhs, rhs) => {
                    if (!lhs.maxDate && !rhs.maxDate) return 0;
                    if (!lhs.maxDate && rhs.maxDate) return 1;
                    if (lhs.maxDate && !rhs.maxDate) return -1;
                    return moment(rhs.maxDate).diff(lhs.maxDate);
                });
        },

        spiking() {
            if (this.showMore && this.showMore !== "spiking") return [];
            if (!this.sizeRestriction || this.showMore === "spiking") return this.allSpiking;
            return this.allSpiking.slice(0, this.sizeRestriction);
        },

        allUnusual() {
            return this.topics.filter(t => t.delta >= 1 && !t.isSpike && !t.isNew);
        },

        unusual() {
            if (this.showMore && this.showMore !== "unusual") return [];
            if (!this.sizeRestriction || this.showMore === "unusual") return this.allUnusual;
            return this.allUnusual.slice(0, this.sizeRestriction);
        },

        allSlightly() {
            return this.topics.filter(t => t.delta < 1 && t.delta >= 0.5 && !t.isSpike && !t.isNew);
        }                                                                                           ,

        slightly() {
            if (this.showMore && this.showMore !== "slightly") return [];
            if (!this.sizeRestriction || this.showMore === "slightly") return this.allSlightly;
            return this.allSlightly.slice(0, this.sizeRestriction);
        },

        allNormal() {
            return this.topics.filter(t => t.delta < 0.5 && t.delta >= -0.5 && !t.isSpike && !t.isNew);
        },

        normal() {
            if (this.showMore && this.showMore !== "normal") return [];
            if (!this.sizeRestriction || this.showMore === "normal") return this.allNormal;
            return this.allNormal.slice(0, this.sizeRestriction);
        },

        allLow() {
            return this.topics.filter(t => t.delta <= -0.5 && !t.isNew || t.delta === null && !t.isSpike && !t.isNew);
        },

        low() {
            if (this.showMore && this.showMore !== "low") return [];
            if (!this.sizeRestriction || this.showMore === "low") return this.allLow;
            return this.allLow.slice(0, this.sizeRestriction);
        },

        allNewTags() {
            return this.topics.filter(t => t.isNew);
        },

        newTags() {
            if (this.showMore && this.showMore !== "new") return [];
            if (!this.sizeRestriction) return this.allNewTags;
            return this.allNewTags.slice(0, this.sizeRestriction);
        },

        visible() {
            let visible = [];
            if (this.showSpiking && this.spiking.length) visible = [...visible, ...this.spiking];
            if (this.showUnusual && this.unusual.length) visible = [...visible, ...this.unusual];
            if (this.showSlightly && this.slightly.length) visible = [...visible, ...this.slightly];
            if (this.showExpected && this.normal.length) visible = [...visible, ...this.normal];
            if (this.showLow && this.low.length) visible = [...visible, ...this.low];
            if (this.showNew && this.newTags.length) visible = [...visible, ...this.newTags];

            return visible;
        }
    },

    watch: {
        visible() {
            this.$emit('visible-changed', this.visible);
        }
    },

    created() {
        this.$emit('visible-changed', this.visible);
    },

    methods: {
        setShowMore(value) {
            if (this.showMore === value) {
                this.showMore = null;
                return;
            }
            this.showMore = value
        }
    }
}
</script>



<style scoped lang="sass">

</style>