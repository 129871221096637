<template>
    <section class="conduct-widget widget-height-inner">
        <section v-if="lists.length">
            <trend-table-section v-for="s in lists"
                                 word-type="Sub-category"
                                 :key="s.tag.id"
                                 :topics="getSorted(s.children)"
                                 :title="s.tag.name"
                                 :tooltip="s.tag.clientDescription"
                                 :with-description="attributes.withDescription"
                                 :with-author-count="attributes.withAuthors"
                                 :with-sentiment="attributes.withSentiment"
                                 :with-total="attributes.withTotal"
                                 @seeTitleMentions="seeTitleMentions(s.tag)"
                                 @seeTitleAuthors="seeTitleAuthors(s.tag)"
                                 @seeTitleWordcloud="seeTitleWordcloud(s.tag)"
                                 @seeMentions="seeMentions($event)"
                                 @seeWordcloud="seeWordcloud($event)"
                                 @seeAuthors="seeAuthors($event)"/>
        </section>

    </section>
</template>

<script>

import './ConductWidget.css'
import {getAllMarketConductSegmentLists} from "@/app/utils/Segments";
import TrendTableSection from "@/app/toplevel/explore/overview/TrendTableSection";
import {deprecatedTagsStore as tagStore} from "@/store/deprecated/Stores";
import {count} from "@/data/Grouse";
import ConductWidgetSettings from "@/dashboards/widgets/conduct/ConductWidgetSettings";
import {showMentions, showWordcloud} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {appendSegmentRestrictions} from "@/app/utils/Segments";
import {gotoAuthorsPanel} from "@/authorsV4/AuthorUtilities";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {encloseInDisplayQuotes} from "@/app/utils/StringUtils";

if (module.hot) window.beWidgetReloaded('ConductWidget')

export default {
    name: "ConductWidget",
    components: {TrendTableSection},
    widgetType: {
        vueSettingsDialog: ConductWidgetSettings,
        'hidden-title': false,
        height: 5,
        width: 6,
        withSentiment: true,
        withTotal: false,
        withAuthors: false,
        withDescription: false,
        segmentId: null
    },

    props: {
        model: Object
    },

    data() {
        return {
            loading: false,
            segment: null,
            lists: []
        }
    },

    computed: {
        attributes() {
            return this.model.attributes
        },

        filter() {
            return this.attributes._effectiveFilter
        },
    },

    watch: {
        filter() {
            this.refresh();
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        async refresh() {
            await this.loadData();
        },

        async loadData() {
            if (this.loading) return;

            try {
                this.loading = true;
                this.model.generalData.set({_loading: true, _completed: false, _footnotes: []});

                const conductLists = await getAllMarketConductSegmentLists();
                this.segment = conductLists.find(s => s.id === this.attributes.segmentId);

                if (!this.segment) {
                    this.model.generalData.set({_loading: false, _completed: true});
                    this.model.generalData.set({_message: `<p>Unable to find the requested Market Conduct list in your account</p>
                                                            <p class='info'>Please contact <a href='mailto:support@dataeq.com'>our support team</a></p>`});
                    return;
                }

                this.lists = [];

                let filter = `(${this.filter}) and segment is ${this.segment.id}`;
                filter = appendSegmentRestrictions(filter);

                const data = await count(filter, ["tag"],
                    ["tag[id]", "mentionCount", "sentimentVerifiedCount", "totalVerifiedSentiment", "authorIdCount"],
                    null, {tagNamespace: "segment"})

                const maps = new Map();
                data.forEach(d => maps.set(d.tag.id, d))

                for (const childId of this.segment.children) {
                    if (await tagStore.exists(childId)) {
                        const segment = tagStore.get(childId);
                        if (segment.flag === "NONE_OF_THE_ABOVE") continue;

                        this.lists.push({
                            tag: segment,
                            children: segment.children?.length
                                ? segment.children.map(c => {
                                    const countData = maps.get(c);
                                    return {
                                        topic: tagStore.get(c),
                                        net: countData ? countData.totalVerifiedSentiment / countData.sentimentVerifiedCount : null,
                                        weekTotal: countData?.mentionCount ?? 0,
                                        periodAuthorIdCount: countData?.authorIdCount ?? 0,
                                        filter: `${filter} and segment is ${c}`
                                    };
                                })
                                : []
                        });
                    }
                }

                this.model.generalData.set({_loading: false, _completed: true});
            } catch (e) {
                errorHelper(this.model, e.response || e);
            } finally {
                this.loading = false;
            }
        },

        seeMentions(topic) {
            showMentions(
                topic.filter,
                `Mentions for ${topic.topic.name ? encloseInDisplayQuotes(topic.topic.name) : 'undefined'}`
            );
        },

        seeWordcloud(topic) {
            showWordcloud(
                topic.filter,
                `Mentions for ${topic.topic.name ? encloseInDisplayQuotes(topic.topic.name) : 'undefined'}`
            );
        },

        seeAuthors(topic) {
          gotoAuthorsPanel(topic.filter);
        },

        seeTitleMentions(tag) {
            showMentions(
                `(${this.filter}) and segment is ${tag.id}`,
                `Mentions for ${tag.topic.name ? encloseInDisplayQuotes(tag.topic.name) : 'undefined'}`
            );
        },

        seeTitleWordcloud(tag) {
            showWordcloud(
                `(${this.filter}) and segment is ${tag.id}`,
                `Mentions for ${tag.topic.name ? encloseInDisplayQuotes(tag.topic.name) : 'undefined'}`
            );
        },

        seeTitleAuthors(tag) {
          gotoAuthorsPanel(`(${this.filter}) and segment is ${tag.id}`)
        },

        toCSV() {
            const rows = [['tagId', 'tagName', 'netSentiment', 'totalMentions', 'uniqueAuthors']];
            const add = t => rows.push([t.topic.id, t.topic.name, t.net ?? 'NA', t.weekTotal ?? 0, t.periodAuthorIdCount ?? 0]);


            if (this.lists?.length) {
                for (const t of this.lists) {
                    for (const c of t.children) add(c);
                }
            }

            // Add the UTF-8 BOM to the header.
            // The UTF-8 BOM is so that Excel + Windows correctly interprets and opens
            // this file.
            return '\ufeff' + rows
                .map(row => row.map(d => `"${("" + d).replace(/"/g, '""')}"`).join(','))
                .join("\n");
        },

        getSorted(items) {
            return Array.from(items).sort((lhs, rhs) => (lhs.topic.ordinal ?? lhs.topic.id) - (rhs.topic.ordinal ?? rhs.topic.id))
        },
    }
}

</script>


