<template>
    <dialog-box @close="$emit('close')" width="560px" class="judgement-day" title="Report Crowd Error"
                modal no-dim right="20px" bottom="20px" @mousedown.stop>
        <div v-if="isDuplicate">
            <i class="symbol-info"></i>
            This mention is a duplicate.
        </div>
        <div v-if="isFiltered">
            <i class="symbol-info"></i>
            This mention is was automatically handled in the Crowd by an AI filter.
        </div>
        <div v-if="isSkipped">
            <i class="symbol-info"></i>
            This mention is was automatically handled in the Crowd by an AI filter.
        </div>
        <div class="row-fluid">
            <div class="span12">
                <div style="display: flex">
                    <div class="control-group" style="margin-right: 16px; width: 220px">
                        <label>What is the error in connection with?</label>
                        <div class="controls" style="margin-left: 8px">
                            <label class="checkbox"><input type="checkbox" v-model="relevancy">Relevancy</label>
                            <label class="checkbox"><input type="checkbox" v-model="sentiment">Sentiment</label>
                            <label class="checkbox"><input type="checkbox" v-model="classification">Classification</label>
                            <checkbox-list :options="segmentListOptions" v-model="segmentListIds"/>
                            <checkbox-list :options="topicTrees" v-model="topicTreeIds"/>
                        </div>
                    </div>
                    <div class="control-group" style="flex-grow: 1; display: flex; flex-direction: column; margin-bottom: 12px">
                        <label>What is the problem?</label>
                        <div class="controls" style="margin-top: 6px; margin-right: 16px; flex-grow: 1">
                            <textarea ref="reason" v-model="reason" style="width: 100%; height: 100%; margin: 0; min-height: 60px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="errors && errors.length" class="row-fluid" style="margin-top: 10px">
            <div class="span12" style="margin-bottom: 0; min-height: 0">
                <ul>
                <li v-for="(e,i) in errors" :key="'e' + i" class="text-warning">Error: {{e}}</li>
                <li v-for="(m,i) in messages" :key="'m' + i">{{m}}</li>
                </ul>
            </div>
        </div>
        <template #buttons>
            <a class="btn cancel" @click="$emit('close')">Cancel</a>
            <a class="btn btn-primary" @click="ok" :class="{disabled: disabled}">
                <span v-if="saving" class="spinner"/>
                <span v-else>Ok</span>
            </a>
        </template>
    </dialog-box>
</template>

<script>
    import DialogBox from "../components/DialogBox"
    import CheckboxList from "../components/inputs/CheckboxList"
    import { beef } from "@/store/Services"
    import {currentAccountCode} from "@/app/utils/Account";
    import {CROWD_SEGMENTS} from "@/app/utils/Segments";
    import VuexStore from "@/store/vuex/VuexStore";
    import {mapActions, mapGetters} from "vuex";
    import {formatTagName} from "@/app/utils/Format";
    import {notifyWithText} from "@/app/framework/notifications/Notifications";

    export default {
        name: "CreateCrowdJudgement",
        components: { DialogBox, CheckboxList },
        store: VuexStore,

        props: {
            value: Boolean,
            mention: Object
        },

        data() {
            return {
                relevancy: false,
                sentiment: false,
                classification: false,
                segmentListIds: [],
                topicTreeIds: [],
                reason: "",
                saving: false,
                errors: null,
                messages: null
            }
        },

        computed: {
            ...mapGetters(['idToBrand', 'idToTag']),

            segmentLists() {
                if (!this.mention?.brand) return [];
                const brand = this.idToBrand.get(this.mention.brand.id);
                return brand.segmentListIds.map(id => this.idToTag.get(id));
            },

            isDuplicate() {
                return this.mention?.tags?.find(t => t.id === CROWD_SEGMENTS.DUPLICATES);
            },
            isFiltered() {
                return this.mention?.tags?.find(t => [CROWD_SEGMENTS.NEUTRAL_FILTER, CROWD_SEGMENTS.SEGMENT_FILTER, CROWD_SEGMENTS.TOPIC_FILTER].includes(t.id));
            },
            isSkipped() {
                return this.mention?.tags?.find(t => [CROWD_SEGMENTS.SEGMENT_SKIPPED].includes(t.id));
            },
            disabled() {
                return !this.inputsValid || this.saving;
            },

            segmentListOptions() {
                if (!this.segmentLists?.length) return null;
                return this.segmentLists.map(s => ({
                    id: s.id,
                    label: formatTagName(s)
                }));
            },

            topicTrees() {
                let tt = this.idToBrand.get(this.mention.brand.id).topicTrees;
                return tt ? tt.map(t => Object.assign({}, t, {label: "Topics: " + t.label})) : null
            },

            inputsValid() {
                return (this.relevancy || this.sentiment || this.classification || this.segmentListIds.length
                    || this.topicTreeIds.length) && this.reason
            }
        },

        async mounted() {
            this.$nextTick(() => this.$refs.reason.focus());
            await Promise.all([this.refreshTags(), this.refreshBrands()]);
        },

        methods: {
            ...mapActions(['refreshTags', 'refreshBrands']),

            ok() {
                if (!this.inputsValid || this.saving) return;
                let dto = {...this.$data}
                delete dto.saving
                delete dto.errors
                delete dto.messages
                dto.accountCode = currentAccountCode()
                dto.mentionId = this.mention.id
                dto.crowdJobs = this.mention.crowdJobs
                this.saving = true
                this.errors = this.messages = null
                beef.post("/api/crowds/judgements", dto)
                    .then(res =>  {
                        this.messages = res.data.messages
                        if (res.data.errors) this.errors = res.data.errors
                        else {
                            notifyWithText("The problem has been reported to the Crowd team", null, '<i class="symbol-mentions"></i>');
                            this.$emit('close');
                        }
                    })
                    .catch(e => this.errors = ["" + e])
                    .finally(() => this.saving = false)
            }
        }
    }
</script>

<style scoped>

</style>