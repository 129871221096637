<template>
    <dialog-box @close="$emit('input', null)" width="400px" class="fc-point-settings"
                :title="'Edit ' + pointLabel + ' settings'">
        <div class="row-fluid">
            <div class="span12 control-row">
                <colour-settings-input :value="settings" :dashboard-model="dashboardModel" single no-opacity
                    no-default label="Colour" use-default-text="Use default colour" use-default-title="Use default colour"/>
            </div>
        </div>

        <template #buttons>
            <a class="btn btn-info pull-left" @click="restoreDefaults">Restore Defaults</a>
            <a class="cancel btn" @click="cancel">Cancel</a>
            <a class="btn btn-primary" @click="ok">Ok</a>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from "../../../components/DialogBox"
import ColourSettingsInput from "../../ColourSettingsInput"
import {cloneDeep} from 'lodash'

export default {
    name: "FCPointSettingsDialog",

    components: { DialogBox, ColourSettingsInput },

    props: {
        model: Object,
        point: Object,
        pointLabel: String,
        geometrySettings: Object
    },

    data() {
        let ps = this.geometrySettings.pointSettings
        let settings = ps ? cloneDeep(ps[this.point.id] || { }) : { }
        ColourKeys.forEach(k => { if (settings[k] === undefined) settings[k] = null })
        return { settings }
    },

    computed: {
        dashboardModel() {
            // this is used to get the custom colour palette and we want the one from the geometry settings not
            // the dashboard or whole chart
            return new Backbone.Model(this.geometrySettings)
        }
    },

    methods: {
        restoreDefaults() {
            let ps = this.geometrySettings.pointSettings
            if (ps) delete ps[this.point.id]
            this.saveModelAndClose()
        },

        ok() {
            // copy any changed custom palette back to the geometry colour settings
            let cp = this.settings['colour-palette-custom']
            if (cp) this.$set(this.geometrySettings, 'colour-palette-custom', cp);
            delete this.settings['colour-palette-custom']
            delete this.settings['opacity']

            let ps = this.geometrySettings.pointSettings
            if (!ps) ps = {};

            ps[this.point.id] = cloneDeep(this.settings);
            this.$set(this.geometrySettings, 'pointSettings', ps);
            this.saveModelAndClose()
        },

        saveModelAndClose() {
            this.model.save(null, {
                error: (model, xhr, options) => {
                    if (xhr.responseText) console.error(xhr.responseText, xhr)
                    else (console.error(xhr))
                    window.alert("Error saving changes .. please retry")
                }
            })
            this.$emit('ok')
            this.$emit('input', null)
        },

        cancel() {
            this.$emit('input', null)
        }
    }
}

const ColourKeys = ['colour-index', 'colour-palette', 'colour-palette-custom']

</script>

<style scoped>

</style>