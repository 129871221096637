import VuexStore from "@/store/vuex/VuexStore";

/**
 * Navigate to the setup panel
 * @return {void}
 */
export function gotoSetupPanel() {
    const code = VuexStore.state.account.code;
    let uri = "/" + code + "/setup";
    Beef.router.navigate(uri, {trigger: true});
}

/**
 * Navigate to the account health panel
 * @return {void}
 */
export function gotoAccountHealth() {
    const code = VuexStore.state.account.code;
    let uri = "/" + code + "/setup/health";
    Beef.router.navigate(uri, {trigger: true});
}

/**
 * Navigate to the account sorting panel
 * @return {void}
 */
export function gotoAccountSorting() {
    const code = VuexStore.state.account.code;
    let uri = "/" + code + "/setup/sorting";
    Beef.router.navigate(uri, {trigger: true});
}

/**
 * Navigate to the account sorting panel
 * @return {void}
 */
export function gotoAccountRules() {
    const code = VuexStore.state.account.code;
    let uri = `/${code}/setup/rules`;
    Beef.router.navigate(uri, {trigger: true});
}

/**
 * Navigate to the account sorting panel
 * @return {void}
 */
export function gotoMentions() {
    const code = VuexStore.state.account.code;
    let uri = `/${code}/mentions?filter=Published INTHELAST WEEK and relevancy is irrelevant`;
    Beef.router.navigate(uri, {trigger: true});
}