<template>
    <div>
        <div class="row-fluid">
            <div class="span12 control-group control-row">
                <div style="position: relative">
                    <a v-if="showRemove" class="btn btn-link" @click="$emit('remove')" title="Remove this series"
                            style="position: absolute; right: -13px; top: -2px">
                        <i class="icon-trash-1" />
                    </a>
                    <drop-down-input v-model="attrs.yAxis" label="What data would you like to see?"
                                     :options="yAxisOptions" style="width: 220px" required @valid="onValid"/>
                </div>
                <drop-down-input v-model="attrs.geometry" label="Geometry" :options="geometryOptions"
                        style="width: 125px"/>

                <colour-settings-input :value="attrs" :dashboard-model="dashboardModel"/>

                <template v-if="attrs.geometry === 'columns' || attrs.geometry === 'rows'">
                    <text-input v-model="attrs.barOutline" type="text" label="Outline" :rules="options.rules.barOutline"
                                style="width: 40px" numeric @valid="onValid"/>
                    <text-input v-model="attrs.barPadding" type="text" label="Pad %" :rules="options.rules.barOutline"
                                style="width: 40px" numeric @valid="onValid"/>
                </template>

                <template v-if="attrs.geometry === 'pie'">
                    <drop-down-input label="Label placement" v-model="attrs.labelPlacement" :options="options.labelPlacement" style="width: 100px"/>

                    <div class="control-group" style="width: 100px; margin-top: 13px;" >
                        <label class="checkbox" title="Use donut shape">
                            <input v-model="attrs['isDonut']" type="checkbox">
                            Donut
                        </label>
                        <label class="checkbox" title="Show/hide the labels on the metric">
                            <input v-model="attrs['hideLabels']" type="checkbox" :false-value="true" :true-value="false">
                            Show values
                        </label>
                    </div>
                </template>

                <div v-if="attrs.geometry === 'columns' || attrs.geometry === 'rows'" style="margin-top: 13px">
                    <label class="checkbox" title="Show/hide the labels on the metric">
                        <input v-model="attrs['hideLabels']" type="checkbox" :false-value="true" :true-value="false">
                        Data labels
                    </label>
                    <div v-if="attrs.geometry === 'rows'" class="control-group">
                        <label class="checkbox">
                            <input v-model="attrs.xGridLines" type="checkbox"> Vertical grid
                        </label>
                    </div>
                </div>
                <template v-if="attrs.geometry === 'lines'">
                    <text-input v-model="attrs.lineWidth" type="text" label="Width" :rules="options.rules.lineWidth"
                            style="width: 40px" numeric @valid="onValid"/>
                    <drop-down-input v-model="attrs.lineCurve" label="Style" :options="options.curve"
                            style="width: 80px"/>
                </template>
            </div>
        </div>
        <div class="row-fluid" v-if="yAxis.onlySupportHandles" style="margin-top: 0">
            <div class="span12 control-group" style="margin-left: 8px">
                <label class="checkbox">
                    <input type="checkbox" v-model="attrs['onlySupportHandles']">
                    Only count responses from brand support handles
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import TextInput from "../../../components/inputs/TextInput"
    import DropDownInput from "../../../components/inputs/DropDownInput"
    import ColourSettingsInput from "../../ColourSettingsInput"

    import {getPalette} from "@/app/utils/Colours";
    import {FANTASTIC_FIELDS} from "@/dashboards/widgets/fantasticchart/fields/Fields";

    /**
     * Edits settings for a geometry
     */
    export default {
        name: "FCYAxisSettings",

        components: { TextInput, DropDownInput, ColourSettingsInput },

        props: {
            show: Array,        // all the geometries
            index: Number,      // our index in show
            options: Object,    // FantasticChartSettingsOptions
            dashboardModel: Object
        },

        computed: {
            attrs() { return this.show[this.index] },
            fields() { return FANTASTIC_FIELDS },
            yAxis() { return this.fields[this.attrs.yAxis] },
            geometry() { return Beef.Widget.FantasticChartGeometry.geometry[this.attrs.geometry] },

            colours() {
                let a = getPalette(this.attrs, this.dashboardModel.attributes)
                if (a.length > 6) a = a.slice(0, 6)
                return a
            },

            geometryOptions() {
                if (this.yAxis.getter2) return this.options.geometryDoubleValue
                if (this.show.length > 1) return this.index === 0 ? this.options.geometryFirst : this.options.geometryOthers
                if (this.yAxis.mayHaveNegativeValues) return this.options.geometryNegativeValues
                return this.options.geometry
            },

            yAxisOptions() {
                if (this.index == 0) return this.options.yAxis
                let yAxis0 = this.options.yAxis[this.show[0].yAxis]
                let st0 = yAxis0.scaleType
                let st1 = this.options.yAxis[this.show[1].yAxis].scaleType
                let ans
                if (this.index === 1 || st0 === st1 || !st1) {
                    ans = this.options.yAxis
                } else {
                    // have two different scaleTypes already (i.e. already using 2nd y-axis) so more series must
                    // have one of those 2 scaleTypes
                    ans = Object.assign({}, this.options.yAxisByScaleType[st0], this.options.yAxisByScaleType[st1])
                }

                // need to remove options with doubleValue set until line chart can support that
                ans = {...ans}
                Object.entries(ans).forEach(row => { if (row[1].doubleValue) delete ans[row[0]] })
                return ans
            },

            showRemove() { return this.index >= 1 }
        },

        watch: {
            'attrs.yAxis'(v) {
                if ((this.yAxis.getter2 || this.yAxis.mayHaveNegativeValues) && !this.geometryOptions[this.attrs.geometry]) this.attrs.geometry = "columns"
                this.$emit("yAxisChange", v)
            }
        },

        methods: {
            onValid(v) {
                this.$emit('valid', v)
            }
        }
    }
</script>

<style scoped>
    .control-row {
        display: flex;
    }

    .control-row > div:not(:first-child) {
        margin-left: 15px;
    }

    .row-fluid [class*="span"] {
        min-height: 20px;
    }
</style>