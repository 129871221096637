/**
 * Select one or more languages by typing the code or name. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element.
 *
 * <p>
 * If you update this list, you should update the corresponding list in the API.
 */
import {LANGUAGES} from "@/app/utils/Language";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("LanguagePicker").addInitializer(function(startupOptions) {

    this.items = LANGUAGES;

    this.View = Beef.AutoCompletePicker.View.extend({
        attributes: { class: "language-picker auto-complete-picker" },
        items: this.items
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            tooltip: function(code) {
                if (code == 'unknown') return "Unknown language";
                return "The language, " + (LANGUAGES[code] || code);
            },
            items: this.items,
            placeholder: placeholder
        });
    };

    this.converter = this.createConverter("Type the language name");

    /**
     * Attach a language picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };

});
