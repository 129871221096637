<template>
    <dialog-box class="date-picker-dialog"
                :title="title"
                width="500px"
                @close="closeNoEscape($event)"
                stop-mousedown
                modal>
        <date-range-picker v-model="changeDate" :allow-hours="allowHours" :allow-time="allowTime"/>

        <template #buttons>
            <div class="date-picker-dialog__buttons">
                <be-button link @click="$emit('close')">Cancel</be-button>
                <be-button primary @click="okClicked()">Select</be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import DateRangePicker from "@/components/pickers/dates/DateRangePicker";
import BeButton from "@/components/buttons/BeButton";

export default {
    components: {
        BeButton,
        DateRangePicker,
        DialogBox
    },

    props: {
        date: {
            type: String
        },

        title: {
            type: String,
            default: "Select a date range"
        },

        allowHours: {
            type: Boolean,
            default: false
        },

        allowTime: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            changeDate: this.date
        }
    },

    mounted() {
        document.addEventListener("keyup",  this.keyHandler, {capture: true});
    },

    beforeDestroy() {
        document.removeEventListener("keyup",  this.keyHandler, true);
    },


    methods: {
        closeNoEscape(event) {
            if (event.key === 'Escape') return;
            this.$emit('close');
        },

        okClicked() {
            this.$emit('selected', this.changeDate);
            this.$emit('close');
        },


        /**
         * @param {KeyboardEvent} event
         */
        keyHandler(event) {
            event.preventDefault();
            event.stopPropagation();

            if (event.key === 'Escape') {
                this.$emit('close');
            }
        },
    }
}

</script>

<style scoped lang="sass">

.date-picker-dialog__buttons
    display: flex
    margin: 10px -20px -20px
    justify-content: flex-end
    padding: 10px 20px
    border-top: 1px solid #272727
    background: #333333
    border-bottom-left-radius: 6px
    border-bottom-right-radius: 6px

</style>