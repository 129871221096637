<template>
    <old-tooltip style="display: inline" :label="tooltip">
        <a :href="object.analyseLink" @click.stop.prevent="gotoObject()">
            <i v-if="object.analyseIcon" :class="object.analyseIcon"></i>
            <slot v-bind:name="object.toString()">
                {{ object }}
            </slot>
        </a>
    </old-tooltip>
</template>

<script>


import {DataEQType} from "@/app/utils/types";
import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
    components: {OldTooltip},
    props: {
        object: {
            type: DataEQType,
            required: true
        },
        tooltip: {
            type: String,
            default: "Click to open"
        }
    },

    methods: {
        gotoObject() {
            this.$emit('navigate');
            Beef.router.navigate(this.object.analyseLink, {trigger: true});
        }
    }
}
</script>


<style scoped lang="sass">

</style>