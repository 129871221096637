import './FantasticGeometrySettings.css'

Beef.module("Widget.FantasticGeometrySettings").addInitializer(function(startupOptions) {

    /**
     * Edit geometry related settings for a fantastic chart.
     */
    this.View = Beef.BoundItemView.extend({

        template: require("@/dashboards/widgets/fantasticchart/FantasticGeometrySettings.handlebars"),

        attributes: {
            'class': 'fantastic-geometry-settings'
        },

        bindings: function () {
            return {
                'line-curve': { selector: '.line-curve', converter: curvePicker.converter, elAttribute: "data-value" }
            }
        },

        modelEvents: {
            "change:geometry": "modelToView"
        },

        events: {
            "change [data-id='labels']": 'showLabelsChanged',
            "change [data-id='si']": 'showSiChanged',
        },

        templateHelpers: function () {
            return {}
        },

        initialize: function() {
            let m = this.model
            if (!m.get('line-curve')) m.attributes['line-curve'] = 'smooth'
        },

        onFirstRender: function () {
            this.modelToView();

            curvePicker.attach(this, ".line-curve", "line-curve");
        },

        modelToView: function() {
            let m = this.model

            this.$('.part').toggle(false)
            this.$('.part.' + m.get('geometry')).toggle(true)

            this.$('[data-id="labels"]').prop("checked", !m.get("hide-labels"));
            this.$('[data-id="si"]').prop("checked", !m.get("no-si"));
        },

        showLabelsChanged: function(ev) {
            this.model.set("hide-labels", !this.$(ev.target).is(":checked"))
        },

        showSiChanged: function(ev) {
            this.model.set("no-si", !this.$(ev.target).is(":checked"))
        }
    })

    const curvePicker = Beef.DynamicPicker.create({
        smooth: {name: "Smooth" },
        jagged: {name: "Jagged" },
    }, { placeholder: "Curve" });

});