import _ from 'underscore';
import {concatenateFilters, flattenFilterNodes, parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import {
    get as getSegment,
    getChannelSegmentList,
    getJourneySegmentList,
    getMarketConductOutcomeListsOnBrands,
    getRiskSegmentList, getSegmentList, intersection as segmentIntersection
} from "@/app/utils/Segments";
import {intersection, union, getBrand as getBrand} from "@/app/utils/Brands";
import moment from "moment";
import {MentionQAst} from "@/mentionq/mentionq";
import {isMashAdmin} from "@/app/Permissions";
import VuexStore from "@/store/vuex/VuexStore";
import {encloseInSingleQuotes, isString, splitAtSpaces, splitUnique} from "@/app/utils/StringUtils";
import {isFunction, isObject} from "@/app/utils/Util";
import {COMPOUND_LINKS, LINK_NAMES, MEDIA_CATEGORIES} from "@/app/utils/MediaLinks";
import {VISIBILITY_ITEMS} from "@/app/utils/Visibility";
import {GENDER_ITEMS} from "@/app/utils/Gender";
import {toDateRange} from "@/app/utils/Dates";
import {FilterError} from "@/app/utils/types";

/**
 * @typedef FilterString {String}
 */



/**
 * Parse the filter and convert it to attributes. Any parsing errors are ignored.
 */
export function convertFilterToAttrs(filter, options) {
    let root;
    try {
        root = parseFilterString(filter)
    } catch (e) {
        return {errors: e};
    }
    return convertExpToAttrs(root, options);
}

/**
 * Given a map of attributes, this creates a filter for them.
 *
 * ```javascript
 * filter = buildBasicFilters({country: 'SA'}
 * ```
 * @param {object} attrs
 * @return {string} The filter
 */
export function buildBasicFilter(attrs) {
    let terms = [];
    let ors, i, a, s, list;

    let trash = attrs.trash;
    switch (trash) {
        case "ISNT_IRRELEVANT": terms.push("Relevancy ISNT IRRELEVANT");    break;
        case "IRRELEVANT":      terms.push("Relevancy IS IRRELEVANT");      break;
    }

    let verification = attrs.verification;
    switch (verification) {
        case "VERIFIED":        terms.push("Process IS VERIFIED");      break;
        case "NOT_VERIFIED":    terms.push("Process ISNT VERIFIED");    break;
    }

    let published = attrs.published;
    if (published && published.length > 0) {
        switch (published) {
            case 'DAY':
            case 'WEEK':
            case 'FORTNIGHT':
            case 'MONTH':
            case 'QUARTER':
            case 'YEAR':
            case 'EPOCH':
                terms.push("Published INTHELAST " + published);
                break;
            case 'TWENTY_FOUR_HRS':
            case '24HOURS':
                terms.push("Published INTHELAST 24hours");
                break;
            case 'TODAY':
                terms.push("Published on " + published);
                break;
            default:
                list = [published];
                ors = [];
                for (i = 0; i < list.length; i++) {
                    a = list[i].split("-");
                    let end = parseDate(a[1]);
                    if (!isHour(a[1])) end.add({ days: 1 });   // BEFORE operator is exclusive
                    else end.add({ minutes: 1 });
                    ors.push("Published AFTER '" + a[0] + "' AND Published BEFORE '" + end.toString(isHour(a[1]) ? "yyyy/MM/dd HH:mm" : "yyyy/MM/dd") + "'");
                }
                terms.push(ors.length === 1 ? ors[0] : ors.join(" OR "));
        }
    }


    a = attrs.brand ? splitAtSpaces(attrs.brand) : null;
    if (a && a.length) {
        if (a[0] === 'x') {
            if (a.length > 1) {
                buildIncExTerms(a.slice(1).join(" "), 'Brand', 'IS', 'ISNT', terms);
            }
        } else if (a.length === 3 && a[1] === '/' && parseInt(a[0]) > 0 && parseInt(a[2]) > 0) {
            let parent = getBrand(a[0]);
            let child = getBrand(a[2]);
            if (child && parent?.children?.some(c => c.id === child?.id)) {
                terms.push(`brand isorchildof ${child.id}`);
            } else {
                buildIncExTerms(attrs.brand, 'Brand', 'ISORCHILDOF', 'ISNTNORCHILDOF', terms);
            }
        } else {
            buildIncExTerms(attrs.brand, 'Brand', 'ISORCHILDOF', 'ISNTNORCHILDOF', terms);
        }
    }

    buildIncExTerms(attrs.phrase, 'Phrase', 'IS', 'ISNT', terms);

    buildIncExTerms(attrs.ticketId, 'ticketId', 'IS', 'ISNT', terms, quoteAllButUnknown);

    buildIncExTerms(attrs.socialNetwork, 'socialNetwork', 'IS', 'ISNT', terms);

    if (attrs.idList && !attrs.idList.disabled) {
        let idList = attrs.idList;
        terms.push("ID IN " + (idList.annotation ? "@" + encloseInSingleQuotes(idList.annotation) : "") +
            "(" + idList.list + ")");
    }

    buildIncExTerms(attrs.id, 'ID', 'IS', 'ISNT', terms, quoteIfV4Id);
    buildIncExTerms(attrs.conversationId, 'ConversationID', 'IS', 'ISNT', terms, quoteIfV4Id);


    if (attrs.interactionIdList) {
        let idList = attrs.interactionIdList;
        terms.push("interactionID IN " + (idList.annotation ? "@" + encloseInSingleQuotes(idList.annotation) : "") +
            "(" + idList.list + ")");
    }
    buildIncExTerms(attrs.interactionId, 'InteractionID', 'IS', 'ISNT', terms, quoteAllButUnknown);

    if (attrs.authorId && !attrs._authorDisabled) {
        buildIncExTerms(attrs.authorId, 'AuthorId', 'IS', 'ISNT', terms, quoteAuthorId);
    }

    // v4 needs quotes around the IDs except for unknown which must not be quoted
    buildIncExTerms(attrs.reshareOf, 'ReshareOf', 'IS', 'ISNT', terms,
        "unknown" !== attrs.reshareOf && "-unknown" !== attrs.reshareOf ? quote : null);
    buildIncExTerms(attrs.replyTo, 'ReplyTo', 'IS', 'ISNT', terms,
        "unknown" !== attrs.replyTo && "-unknown" !== attrs.replyTo ? quote : null);
    buildIncExTerms(attrs.interactionHasResponse, 'InteractionHasResponse', 'IS', 'ISNT', terms,
        "true" !== attrs.interactionHasResponse && "false" !== attrs.interactionHasResponse ? quote : null);

    buildIncExTerms(upper(attrs.country), 'Country', 'IS', 'ISNT', terms, quote);
    buildIncExTerms(attrs.location, 'Location', 'IS', 'ISNT', terms);
    buildIncExTerms(lower(attrs.language), 'Language', 'IS', 'ISNT', terms, quoteAllButUnknown);
    buildIncExTerms(attrs.authorLocation, 'authorLocation', 'CONTAINS', 'DOESNTCONTAIN', terms, quote);


    let findLinksForToken = function(viewToken) {
        let stringAppend = "";

        if (viewToken.charAt(0) === "-") {
            viewToken = viewToken.substring(1);
            stringAppend = "-"
        }

        let linkStrings = COMPOUND_LINKS[viewToken];

        return linkStrings ? linkStrings.map(ls => `${stringAppend}${ls}`) : [`${stringAppend}${viewToken}`]
    };

    a = attrs.link ? splitUnique(lower(attrs.link)) : attrs.link;
    if (a) {
        if (a.indexOf('other') >= 0) {  // if "other sites" is checked we need to invert everything
            // generate DOESNTCONTAIN terms for all not-selected options
            for (i in LINK_NAMES) {
                if (i === 'other') continue;
                let links = findLinksForToken(i);
                if(a.indexOf(i) < 0) {
                    for(let k = 0; k < links.length; k++) {
                        if (a.indexOf(links[k]) < 0) {
                            terms.push("Link DOESNTCONTAIN " + encloseInSingleQuotes(links[k]));
                        }
                    }
                }
            }
            // generate DOESNTCONTAIN terms for negated options
            for (i = 0; i < a.length; i++) {
                s = a[i];
                if(s.charAt(0) !== '-') continue;
                let links = findLinksForToken(s.substring(1)); // stops us indexing tokens like -hellopeter.com
                for(let l = 0; l < links.length; l++) {
                    if (a.indexOf(links[l]) < 0) {
                        terms.push("Link DOESNTCONTAIN " + encloseInSingleQuotes(links[l]));
                    }
                }
            }
        } else {
            ors = [];
            for (i = 0; i < a.length; i++) {
                let links = findLinksForToken(a[i]);
                for(let m = 0; m < links.length; m++) {
                    if (links[m].charAt(0) === '-') {
                        terms.push("Link DOESNTCONTAIN " + encloseInSingleQuotes(links[m].substring(1)));
                    } else {
                        ors.push("Link CONTAINS " + encloseInSingleQuotes(links[m]));
                    }
                }
            }
            if (ors.length > 0) terms.push(joinOrs(ors));
        }
    }

    a = attrs.media ? splitUnique(upper(attrs.media)) : attrs.media;
    if (a && a.length !== MEDIA_CATEGORIES.length) {
        ors = [];
        for (i = 0; i < a.length; i++) ors.push("Media IS " + a[i]);
        terms.push(joinOrs(ors));
    }

    a = attrs.visibility ? splitUnique(upper(attrs.visibility)) : attrs.visibility;
    if (a && a.length !== Object.keys(VISIBILITY_ITEMS).length) {
        ors = [];
        for (i = 0; i < a.length; i++) ors.push("Visibility IS " + a[i]);
        terms.push(joinOrs(ors));
    }

    a = attrs.gender ? splitUnique(upper(attrs.gender)) : attrs.gender;
    if (a && a.length) {
        ors = [];
        for (i = 0; i < a.length; i++) ors.push("Gender IS " + a[i]);
        terms.push(joinOrs(ors));
    }

    a = attrs.race ? splitUnique(upper(attrs.race)) : attrs.race;
    if (a && a.length) {
        ors = [];
        for (i = 0; i < a.length; i++) ors.push("Race IS " + a[i]);
        terms.push(joinOrs(ors));
    }

    if (Array.isArray(attrs.content)) buildV4ContentMatchTerms(attrs.content, 'Content', terms);
    else buildIncExTerms(attrs.content, 'Content', 'MATCHES', 'DOESNTMATCH', terms);

    if (attrs.authorBio) buildV4ContentMatchTerms(attrs.authorBio, 'AuthorBio', terms);

    buildIncExTerms(attrs.author, 'Author', 'CONTAINS', 'DOESNTCONTAIN', terms);

    a = attrs.credibility ? splitUnique(attrs.credibility) : attrs.credibility;
    if (a) terms.push(joinOrs(numbersToOrs("Credibility", a, 0, 9)));

    buildIntegerRangeTerms(attrs.engagement, 'Engagement', terms);
    buildIntegerRangeTerms(attrs.replyCount, 'ReplyCount', terms);
    buildIntegerRangeTerms(attrs.reshareCount, 'ReshareCount', terms);
    buildIntegerRangeTerms(attrs.responseTime, 'ResponseTime', terms);
    buildIntegerRangeTerms(attrs.interactionResponseTime, 'InteractionResponseTime', terms);
    buildIntegerRangeTerms(attrs.interactionFirstResponseTime, 'InteractionFirstResponseTime', terms);
    buildIntegerRangeTerms(attrs.interactionFollowUpResponseTime, 'InteractionFollowUpResponseTime', terms);
    buildIntegerRangeTerms(attrs.interactionWhResponseTime, 'InteractionWhResponseTime', terms);
    buildIntegerRangeTerms(attrs.interactionWhFirstResponseTime, 'InteractionWhFirstResponseTime', terms);
    buildIntegerRangeTerms(attrs.interactionWhFollowUpResponseTime, 'InteractionWhFollowUpResponseTime', terms);
    buildIntegerRangeTerms(attrs.ots, 'OTS', terms);

    a = attrs.sentiment ? splitUnique(attrs.sentiment) : attrs.sentiment;
    if (a) {
        ors = [];
        if (a.indexOf("-1") >= 0) ors.push("Sentiment <= -1");
        if (a.indexOf("1") >= 0) ors.push("Sentiment = 1");
        if (a.indexOf("2") >= 0) ors.push("Sentiment >= 2");
        terms.push(joinOrs(ors));
    }

    buildIncExTerms(attrs.tags, 'Tag', 'IS', 'ISNT', terms);
    buildIncExTerms(attrs.topics, 'Topic', 'IS', 'ISNT', terms);
    buildIncExTerms(attrs.segments, 'Segment', 'IS', 'ISNT', terms);
    buildIncExTerms(attrs.rpcs, 'Tag', 'IS', 'ISNT', terms);

    let journeys = getJourneySegmentList(true, true);
    if (journeys?.length) {
        journeys.forEach(journey => {
            buildIncExTerms(attrs[journey.id], 'Segment', 'IS', 'ISNT', terms);
            buildIncExTerms(attrs[journey.id + ":interactions"], 'Segment', 'IS', 'ISNT', terms);
        });
    }

    let channels = getChannelSegmentList();
    if (channels) {
        buildIncExTerms(attrs['channels'], 'Segment', 'IS', 'ISNT', terms);
    }

    const risk = getRiskSegmentList();
    if (risk) {
        buildIncExTerms(attrs['risk'], 'Segment', 'IS', 'ISNT', terms);
    }

    const conduct = getMarketConductOutcomeListsOnBrands();
    if (conduct) {
        buildIncExTerms(attrs["conduct"], 'Segment', 'IS', 'ISNT', terms);
    }

    let p = parseFloat(attrs.proportion);
    if (!isNaN(p)) {
        let seed = parseInt(attrs.seed);
        terms.push("Sample(" + (p / 100.0) + (isNaN(seed) ? "" : "," + seed) + ")");
    }

    let unbiasedSample = attrs.unbiasedSample;
    if (unbiasedSample) {
        terms.push("UnbiasedSample IS "+ unbiasedSample);
    }

    let replyOrderByAuthor = attrs.replyOrderByAuthor;
    if (replyOrderByAuthor) {
        terms.push("ReplyOrderByAuthor = 1");
    }

    buildIncExTerms(attrs.interactsWith, "MentionedProfile", "IS", "ISNT", terms);
    buildInNotInList(attrs.hasReplyFromProfile, "HasReplyFromProfile", terms)

    let crowdVerified = attrs.crowdVerified;
    if(crowdVerified)terms.push("CrowdVerified IS " + crowdVerified);

    let directMessage = attrs.directMessage;
    if (directMessage) terms.push("DirectMessage IS " + directMessage);

    return terms.join(" AND ");
}



function buildIncExTerms(value, operand, incOp, exOp, terms, valueFunc) {
    let a, i, j;
    if (value && isString(value)) {
        if ("." === value) return
        a = splitAtSpaces(value);
        // process each sublist between '/' separators separately
        // segment: "93740 93741 / 93984 95246"
        // => (Segment IS 93740 OR Segment IS 93741) AND (Segment IS 93984 OR Segment IS 95246)
        for (i = 0; ; ) {
            j = a.indexOf('/', i);
            let sublist = j < 0 ? i === 0 ? a : a.slice(i) : a.slice(i, j);
            buildIncExTerms(sublist, operand, incOp, exOp, terms, valueFunc);
            if (j < 0) break;
            i = j + 1;
        }
        return
    } else {
        a = value;
    }
    if (a && a.length) {
        if (a.length === 1 && a[0] === ".") return
        a = _.uniq(a);
        let and = false;
        let ors = [];
        for (i = and ? 1 : 0; i < a.length; i++) {
            let s = a[i];
            if (s === "&") {
                and = true;
            } else if (s.charAt(0) !== '-') {
                if (valueFunc) s = valueFunc(s);
                ors.push(operand + " " + incOp + " " + s);
            }
        }
        if (ors.length > 0) {
            if (and) terms.push(ors.join(" AND "));
            else terms.push(joinOrs(ors));
        }
        for (i = 0; i < a.length; i++) {
            let s = a[i];
            if (s.charAt(0) === '-') {
                s = s.substring(1);
                if (valueFunc) s = valueFunc(s);
                terms.push(operand  + " " + exOp + " " + s);
            }
        }
    }
}

/**
 * Join two filters with 'AND'. Makes an attempt to produce a clean filter that can be parsed into attributes,
 * converted to english and so on.
 *
 * This can append filters handle by the basic filter, and will attempt to do so where possible. Otherwise
 * it will create a filter that only the advanced filter editor can handle.
 *
 * @param filter1 {FilterString}
 * @param filter2 {FilterString}
 * @return {FilterString}
 */
export function appendBasicFilters(filter1, filter2) {
    if (filter1) filter1 = filter1?.trim();
    if (filter2) filter2 = filter2?.trim();
    if (!filter1) return filter2;
    if (!filter2) return filter1;


    // try to merge sets of attributes to get a cleaner combined filter if possible
    let a = convertFilterToAttrs(filter1, {noPublished: true});
    let b = convertFilterToAttrs(filter2, {noPublished: true});

    const fallback = concatenateFilters(filter1, filter2);
    if (a.errors || b.errors) return fallback;

    if (b.published && a.published && b.published !== a.published) {
        var pub = mergePublishedAttr(a.published, b.published);
        if (!pub) return fallback;
        a.published = pub;
    } else if (!a.published) a.published = b.published; // Ensure we have one of the dates.
    delete b.published;

    if (b.trash && b.trash !== "ALL" && b.trash !== a.trash) {
        if (a.trash && a.trash !== "ALL") return fallback;
        a.trash = b.trash;
    }
    delete b.trash;

    if (b.verification && b.verification !== "ALL" && b.verification !== a.verification) {
        if (a.verification && a.verification !=="ALL") return fallback;
        a.verification = b.verification;
    }
    delete b.verification;

    if (a.content && b.content && a.content.length === 1 && b.content.length === 1) {
        a.content = [a.content[0] + " " + b.content[0]];
        delete b.content;
    }

    if (a.brand && b.brand) {
        let aBrands = splitAtSpaces(a.brand);
        let bBrands = splitAtSpaces(b.brand);

        [...aBrands, ...bBrands].forEach(id => {
            if (!getBrand(id)) throw new FilterError(`Unknown brand with ID [${id}]`);
        });

        if (aBrands.length && aBrands[0] === 'x') { // excluding sub-brands (implies a.brand won't have exclusions)
            if (b.brand.indexOf('-') >= 0) return fallback;     // can't handle exclusions
            if (bBrands.length && bBrands[0] === 'x') bBrands = bBrands.slice(1);
            a.brand = _.intersection(aBrands, bBrands).join(" ");
            if (a.brand && a.brand.charAt(0) !== 'x') a.brand = "x " + a.brand;
            delete b.brand;
        } else {
            const aPos = aBrands.filter(function(b) { return b.charAt(0) !== '-'}),
                  aNeg = aBrands.filter(function(b) { return b.charAt(0) === '-'});

            const bPos = bBrands.filter(function(b) { return b.charAt(0) !== '-'}),
                  bNeg = bBrands.filter(function(b) { return b.charAt(0) === '-'});

            let pos = intersection(aPos, bPos).map(function(b) { return b.id }),
                neg = union(aNeg, bNeg).map(function(b) { return b.id });

            if (pos.length === 0) {
                if (aPos.length && !bPos.length) pos = aPos;
                else if (bPos.length && !aPos.length) pos = bPos;
                else return fallback;
            }

            a.brand = [...pos, ...neg.map(id => -id)].join(" ");
            delete b.brand;
        }
    }

    if (a.segments && b.segments) {
        var aSegmentsIds = splitAtSpaces(a.segments);
        var bSegmentsIds = splitAtSpaces(b.segments);

        var aAnd = aSegmentsIds[0] === '&';
        var bAnd = bSegmentsIds[0] === '&';

        // Too complicated to represent in our string language
        if (aAnd !== bAnd) return fallback;

        if (aAnd) aSegmentsIds.shift();
        if (bAnd) bSegmentsIds.shift();

        const getSegmentAndCheckForExistance = id => {
            let segment = getSegment(id);
            if (!segment) throw new FilterError(`Unknown segment with id [${id}]`);
            return segment
        };

        var aSegments = aSegmentsIds.map(getSegmentAndCheckForExistance);
        var bSegments = bSegmentsIds.map(getSegmentAndCheckForExistance);

        var segmentListIds = new Set();
        try {
            aSegments.forEach(function (s) { segmentListIds.add(getSegmentList(s).id) });
            bSegments.forEach(function (s) { segmentListIds.add(getSegmentList(s).id) });
        } catch (e) {
            return fallback;    // cannot find the segment list
        }

        // Intersecting things from two segment lists is too complicated to represent with our
        // string encoded representation.
        if (segmentListIds.size > 1) return fallback;

        var segmentIntersectionsWorked = true;
        var results = new Set();
        Array.from(segmentListIds)
            .forEach(function(segmentListId) {
                var as = aSegments.filter(function(s) { return getSegmentList(s).id === segmentListId });
                var bs = bSegments.filter(function(s) { return getSegmentList(s).id === segmentListId });

                if (as.length && bs.length) {
                    var intersection = segmentIntersection(as, bs);
                    if (!intersection.length) segmentIntersectionsWorked = false;
                    intersection.forEach(function(s) { results.add(s.id) });
                } else if (as.length) {
                    as.forEach(function(s) { results.add(s.id) });
                } else if (bs.length) {
                    bs.forEach(function(s) { results.add(s.id) });
                }
            });

        if (!segmentIntersectionsWorked) return fallback; // Intersections don't exist. Let grouse sort this out with the original filter.

        a.segments = Array.from(results).join(' ');
        if (aAnd) a.segments = "& " + a.segments;
        delete b.segments;
    }

    const handleTags = fieldName => {
        if (a[fieldName] && b[fieldName]) {
            const aTagIds = splitAtSpaces(a[fieldName]);
            const bTagIds = splitAtSpaces(b[fieldName]);

            const aAnd = aTagIds[0] === '&';
            const aAndable = aTagIds[0] === '&' || aTagIds.length === 1;
            const bAnd = bTagIds[0] === '&';
            const bAndable = bTagIds[0] === '&' || bTagIds.length === 1;

            // Too complicated to represent in our string language
            if (aAnd !== bAnd && aAndable !== bAndable)  return fallback;

            if (aAnd) aTagIds.shift();
            if (bAnd) bTagIds.shift();

            // Get positives
            let pos = !aAnd
                ? _.intersection(aTagIds.filter(id => !id.startsWith("-")), bTagIds.filter(id => !id.startsWith("-")))
                : _.union(aTagIds.filter(id => !id.startsWith("-")), bTagIds.filter(id => !id.startsWith("-")));

            if (!pos.length) return fallback;

            // Get negatives
            let neg = !aAnd
                ? _.union(aTagIds.filter(id => id.startsWith("-")), bTagIds.filter(id => id.startsWith("-")))
                : _.intersection(aTagIds.filter(id => id.startsWith("-")), bTagIds.filter(id => id.startsWith("-")));

            a[fieldName] = Array.from(new Set([...pos, ...neg])).join(" ");
            if (aAnd) a[fieldName] = "& " + a[fieldName];
            delete b[fieldName];
        }
    };

    let tags = handleTags("tags");
    if (tags) return tags;

    tags = handleTags("topics");
    if (tags) return tags;

    var useFallback = false;
    _.each(b, function (bv, key) {
        if (!bv) return;
        var av = a[key];
        if (av) {
            if (Array.isArray(av)) useFallback = true;
            else {
                var c = _.intersection(splitAtSpaces(av), splitAtSpaces(bv));
                if (c.length === 0) useFallback = true;
                else a[key] = c.join(" ");
            }
        } else {
            a[key] = bv;
        }
    });
    if (useFallback) return fallback;

    var f = buildBasicFilter(a);
    return f;
}

function buildIntegerRangeTerms(value, operand, terms) {
    if (value) {
        if (value.indexOf('less-than') === 0) {
            terms.push(operand + ' < ' + value.substring(10));
        }
        else if (value.indexOf('greater-than') === 0) {
            terms.push(operand + ' > ' + value.substring(13));
        }
        else if (value.indexOf('between') === 0) {
            let first = value.indexOf('-') + 1;
            let last = value.lastIndexOf('-') + 1;
            terms.push(operand + ' > ' + value.substring(first, last - 1));
            terms.push(operand + ' < ' + value.substring(last));
        }
    }
}

function buildInNotInList(value, operand, terms) {
    if (!value) return
    let pos = [], neg = []
    splitAtSpaces(value).forEach(v => {
        if (v.charAt(0) === '-') {
            if (v.length > 1) neg.push(v.substring(1))
        } else {
            pos.push(v)
        }
    })
    if (pos.length) terms.push(operand + " IN (" + pos.join(",") + ")")
    if (neg.length) terms.push(operand + " NOTIN (" + neg.join(",") + ")")
}

function buildV4ContentMatchTerms(value, operand, terms) {
    if (!value) return;
    let ors = [];
    for (let i = 0; i < value.length; i++) {
        // escape apostrophe on MATCH phrases
        ors.push(operand + " MATCHES '" + value[i].replace(/'/g, "\\'") + "'");
    }
    if (ors.length > 0) terms.push(joinOrs(ors));
}

/** If a consists of a single string return it otherwise join the strings with OR and put the expression in () */
function joinOrs(a) {
    if (a.length === 1) return a[0];
    return "(" + a.join(" OR ") + ")";
}

/**
 * Convert an array of numbers (as strings including UNKNOWN) between min and max into an array of expressions.
 * This will attempt to make the list short by using <= and >=.
 */
function numbersToOrs(operand, a, min, max) {
    let ors = [];
    a.sort();
    let i = _.indexOf(a, "UNKNOWN", true);
    let haveUnknown = i >= 0;
    if (haveUnknown) a.splice(i, 1);
    if (a.length > 0) {
        for (i = 0; i < a.length; i++) a[i] = parseInt(a[i]);
        let start = min;
        let include = true;
        for (i = min; i <= max; i++) {
            if (a.indexOf(i) >= 0) {
                if (!include) {
                    start = i;
                    include = true;
                }
            } else if (include) {
                endRange(operand, ors, min, max, i, start);
                include = false;
            }
        }
        if (include && (ors.length > 0 || start > min)) endRange(operand, ors, min, max, i, start);
    }
    if (haveUnknown) ors.push(operand + " IS UNKNOWN");
    return ors;
}

function endRange(operand, ors, min, max, i, start) {
    let n = i - start;
    let end = i - 1;
    if (n > 1) {
        if (start === min) {
            ors.push(operand + " <= " + end);
        } else if (end === max) {
            ors.push(operand + " >= " + start);
        } else if (n > 2) {
            ors.push(operand + " >= " + start + " AND " + operand + " <= " + end);
        } else {
            ors.push(operand + " = " + start);
            ors.push(operand + " = " + end);
        }
    } else if (n  === 1) {
        ors.push(operand + " = " + end);
    }
}

function isHour(literal) {
    return literal.length > (literal[0] == "'" ? 12 : 10);
}

function parseDate(literal) {
    if (literal[0] == "'") literal = extractDate(literal);
    return new moment(literal, literal.length > 10 ? "YYYY/MM/DD HH:mm" : "YYYY/MM/DD").toDate();
}

function extractDate(literal) {
    return literal.substring(1, literal.length - 1);
}

function quoteAllButUnknown(s) {
    return s.toLowerCase() == 'unknown'? s : encloseInSingleQuotes(s);
}

export function quoteIfV4Id(s) {
    return s.indexOf('-') < 0 ? s : encloseInSingleQuotes(s);
}

function quoteAuthorId(s) {
    if (s.toLowerCase() === 'unknown') return s;
    if (s.charAt(0) === "'" && s.charAt(s.length - 1) === "'") return s; // Already quoted.
    return encloseInSingleQuotes(s);
}

function quote(s) {
    return encloseInSingleQuotes(s);
}

function upper(s) { return s ? s.toUpperCase() : s }
function lower(s) { return s ? s.toLowerCase() : s }

var mergePublishedAttr = function(a, b) {
    var ap = toDateRange(a);
    var bp = toDateRange(b);

    var end, now;
    if (ap.end == "today") {    // INTTHELAST xxx
        if (bp.end == "today") return bp.start > ap.start ? b : a; // both INTHELAST xxx so use smaller range
        now = moment().startOf('day').toDate();
        end = bp.end < now ? bp.end : now;
    } else {
        if (bp.end == "today") {    // INTTHELAST xxx
            now = moment().startOf('day').toDate();
            end = ap.end < now ? ap.end : now;
        } else {        // both are date ranges
            end = bp.end < ap.end ? bp.end : ap.end;
        }
    }

    var start = bp.start > ap.start ? bp.start : ap.start;
    if (end < start) return null;

    return formatDateAttr(start) + "-" + formatDateAttr(end);
};



var formatDateAttr = function(d) {
    var m = moment(d);
    var f = "YYYY/MM/DD";
    if (m.minutes() || m.hours()) f += " HH:mm";
    return m.format(f);
};










// maps token IDs to their names e.g. tokenMap[104] == 'YEAR'
var tokenMap = function(){
    var map = {};
    for (var i in MentionQAst) map[MentionQAst[i]] = i;
    return map;
}();



/**
 * Convert a filter expression to attributes for our model. If the return value contains 'errors' then the
 * filter is too complicated and errors is an array of error messages.
 *
 * <p>
 * Possible options include
 * - noPublished, which allows published to be empty.
 */
export function convertExpToAttrs(root, options) {
    var attrs = {};
    options = options || {};

    if (!root) return attrs;

    // Create a list of AND terms out of the tree with each node under that as flat as possible.
    // Each entry in the list is an ExpNode or an OrNode. We search the list for terms that we can represent
    // on our panel and if there is anything left afterwords or any unexpected nodes are found then the filter is
    // too complicated.
    root = flattenFilterNodes(root);
    var terms;
    if (root.type == 'AndNode') {
        terms = root.children;
    } else {
        terms = [];
        terms.push(root);
    }

    var errors = [];

    attrs.trash = lookForRelevancy(terms, errors, root, options);
    attrs.verification = lookForVerification(terms, errors, root, options);
    attrs.published = lookForPublished(terms, errors, options, root);

    // first look for Brand IS .. this is picking brands excluding sub-brands
    if (hasExpNode(terms, MentionQAst.BRAND, MentionQAst.IS)) {
        attrs.brand = "x " + lookForIncExList(terms, errors, MentionQAst.BRAND, null, MentionQAst.IS, null, null, root);
    } else {
        // look for normal ISORCHILDOF and ISNTNORCHILDOF terms that include sub-brands
        attrs.brand = lookForIncExList(terms, errors, MentionQAst.BRAND, null, MentionQAst.ISORCHILDOF, MentionQAst.ISNTNORCHILDOF, null, root);
    }

    attrs.phrase = lookForIncExList(terms, errors, MentionQAst.PHRASE, null, MentionQAst.IS, MentionQAst.ISNT, null, root);

    attrs.idList = lookForIncExList(terms, errors, MentionQAst.MENTION_ID, null, MentionQAst.IN, -2, function(lit, node){
        return {list: node.literalImage, annotation: node.annotation}
    }, root);
    if (attrs.idList) attrs.idList = attrs.idList[0];

    attrs.id = lookForIncExList(terms, errors, MentionQAst.MENTION_ID, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    attrs.location = lookForIncExList(terms, errors, MentionQAst.COUNTRY, null, MentionQAst.IS, MentionQAst.ISNT, quote, root);
    attrs.language = lookForIncExList(terms, errors, MentionQAst.LANGUAGE, null, MentionQAst.IS, MentionQAst.ISNT, lower, root);
    attrs.link = lookForLink(terms, errors, root);
    attrs.media = lookForMedia(terms, errors, root);
    attrs.gender = lookForGender(terms, errors, root);
    attrs.credibility = lookForNumeric(MentionQAst.CREDIBILITY, [0,1,2,3,4,5,6,7,8,9,"UNKNOWN"], terms, errors);
    attrs.sentiment = lookForSentiment(terms, errors);
    attrs.authorId = lookForAuthor(terms, errors, root);
    attrs.author = lookForIncExList(terms, errors, MentionQAst.AUTHOR, null, MentionQAst.CONTAINS, MentionQAst.DOESNTCONTAIN, quote, root);
    if (!attrs.author) attrs.author = lookForIncExList(terms, errors, MentionQAst.AUTHOR_NAME, null, MentionQAst.CONTAINS, MentionQAst.DOESNTCONTAIN, quote, root);
    attrs.authorLocation = lookForIncExList(terms, errors, MentionQAst.AUTHOR_LOCATION, null, MentionQAst.CONTAINS, MentionQAst.DOESNTCONTAIN, quote, root);

    attrs.ticketId = lookForIncExList(terms, errors, MentionQAst.TICKET_ID, null, MentionQAst.IS, MentionQAst.ISNT, null, root);

    attrs.interactionId = lookForIncExList(terms, errors, MentionQAst.INTERACTION_ID, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    attrs.interactionIdList = lookForIncExList(terms, errors, MentionQAst.INTERACTION_ID, null, MentionQAst.IN, -2, function(lit, node){
        return {list: node.literalImage, annotation: node.annotation}
    }, root);
    if (attrs.interactionIdList) {
        attrs.interactionIdList = attrs.interactionIdList[0];
        errors.push("InteractionID IN unsupported in basic filter");
    }

    if (attrs.responseTime) {
        errors.push("ResponseTime is a legacy field");
    }

    // content is handled differently for V3 and V4
    // v3: space separated string containing quoted search terms
    // v4: array of strings, each of which is a search phrase which may contain multiple terms, exclusions etc.
    attrs.content = lookForIncExList(terms, errors, MentionQAst.CONTENT, null, MentionQAst.MATCHES, -1,
        null, root, true);

    attrs.authorBio = lookForIncExList(terms, errors, MentionQAst.AUTHOR_BIO, null, MentionQAst.MATCHES, -1, null, root, true);
    attrs.conversationId = lookForIncExList(terms, errors, MentionQAst.CONVERSATION_ID, null, MentionQAst.IS, MentionQAst.ISNT, null, root);

    // Handle RPCS tags
    attrs.socialNetwork = lookForIncExList(terms, errors, MentionQAst.SOCIAL_NETWORK, null, MentionQAst.IS, MentionQAst.ISNT, function(lit, node) {
        return node.literalImage;
    }, root);

    // Must appear before attrs.tags.
    attrs.rpcs = lookForIncExList(terms, errors, MentionQAst.TAG, "tag", MentionQAst.IS, MentionQAst.ISNT, null,
        root, false, true, function(node) { return node.literal < 100; });


    attrs.tags = lookForIncExList(terms, errors, MentionQAst.TAG, "tag", MentionQAst.IS, MentionQAst.ISNT, null,
        root, false, true);
    attrs.topics = lookForIncExList(terms, errors, MentionQAst.TAG, "topic", MentionQAst.IS, MentionQAst.ISNT, null,
        root, false, true);

    const journeys = getJourneySegmentList(true, true);
    const channels = getChannelSegmentList();
    const risk = getRiskSegmentList();
    const conduct = getMarketConductOutcomeListsOnBrands();

    if (journeys?.length) {
        let getJourney = function(journey, node, isInteraction) {
            let segment = getSegment(node.literal);
            if (!segment) return false;
            let segmentList = getSegmentList(node.literal);
            if (!segmentList) return false;
            return segmentList && segmentList.id === journey.id && segment && !!segment.interaction === !!isInteraction;
        };

        journeys.forEach(journey => {
            attrs[journey.id] = lookForIncExList(terms, errors, MentionQAst.TAG, "segment", MentionQAst.IS, MentionQAst.ISNT, null,
                root, false, true, function (node, isInteraction) { return getJourney(journey, node, isInteraction) });
            attrs[journey.id + ":interactions"] = lookForIncExList(terms, errors, MentionQAst.TAG, "segment", MentionQAst.IS, MentionQAst.ISNT, null,
                root, false, true, function(node) { return getJourney(journey, node, true) });
        });
    }

    if (risk) {
        attrs['risk'] = lookForIncExList(terms, errors, MentionQAst.TAG, "segment", MentionQAst.IS, MentionQAst.ISNT, null,
            root, false, true, node => {
                let segmentList = getSegmentList(node.literal);
                return segmentList?.segmentType?.id === 'CONDUCT_LIST';
            });
    }

    if (conduct) {
        attrs['conduct'] = lookForIncExList(terms, errors, MentionQAst.TAG, "segment", MentionQAst.IS, MentionQAst.ISNT, null,
            root, false, true, node => {
                let segmentList = getSegmentList(node.literal);
                return segmentList?.segmentType?.id === "TCF_LIST";
            });
    }

    // We don't want to remove things we think are channels if there is no journey segment.
    if (journeys?.length && channels) {
        attrs['channels'] = lookForIncExList(terms, errors, MentionQAst.TAG, "segment", MentionQAst.IS, MentionQAst.ISNT, null,
            root, false, true, node => {
                let segmentList = getSegmentList(node.literal);
                return segmentList?.segmentType?.id === 'CHANNEL_LIST';
            });
    }

    attrs.segments = lookForIncExList(terms, errors, MentionQAst.TAG, "segment", MentionQAst.IS, MentionQAst.ISNT, null,
        root, false, true);

    attrs.engagement = lookForIntegerRange(MentionQAst.ENGAGEMENT, terms, errors, root);
    attrs.replyCount = lookForIntegerRange(MentionQAst.REPLY_COUNT, terms, errors, root);
    attrs.reshareCount = lookForIntegerRange(MentionQAst.RESHARE_COUNT, terms, errors, root);
    attrs.interactionResponseTime = lookForIntegerRange(MentionQAst.INTERACTION_RESPONSE_TIME, terms, errors, root);
    attrs.interactionFirstResponseTime = lookForIntegerRange(MentionQAst.INTERACTION_FIRST_RESPONSE_TIME, terms, errors, root);
    attrs.interactionFollowUpResponseTime = lookForIntegerRange(MentionQAst.INTERACTION_FOLLOWUP_RESPONSE_TIME, terms, errors, root);
    attrs.interactionWhResponseTime = lookForIntegerRange(MentionQAst.INTERACTION_WH_RESPONSE_TIME, terms, errors, root);
    attrs.interactionWhFirstResponseTime = lookForIntegerRange(MentionQAst.INTERACTION_WH_FIRST_RESPONSE_TIME, terms, errors, root);
    attrs.interactionWhFollowUpResponseTime = lookForIntegerRange(MentionQAst.INTERACTION_WH_FOLLOWUP_RESPONSE_TIME, terms, errors, root);
    attrs.replyOrderByAuthor = lookForInteger(MentionQAst.REPLY_ORDER_BY_AUTHOR, terms, errors, root);
    attrs.ots = lookForIntegerRange(MentionQAst.OTS, terms, errors, root);
    attrs.reshareOf = lookForIncExList(terms, errors, MentionQAst.RESHARE_OF, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    attrs.replyTo = lookForIncExList(terms, errors, MentionQAst.REPLY_TO, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    attrs.interactionHasResponse = lookForIncExList(terms, errors, MentionQAst.INTERACTION_HAS_RESPONSE, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    attrs.unbiasedSample = lookForIncExList(terms, errors, MentionQAst.UNBIASED_SAMPLE, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    attrs.interactsWith = lookForIncExList(terms, errors, MentionQAst.MENTIONED_PROFILE, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    attrs.hasReplyFromProfile = lookForHasReplyFromProfile(terms, errors, root)
    attrs.crowdVerified = lookForIncExList(terms, errors, MentionQAst.CROWD_VERIFIED, null, MentionQAst.IS, MentionQAst.ISNT, null, root);

    attrs.visibility = lookForVisibility(terms, errors, root);

    // convert 'DirectMessage IS/ISNT true/false' into visibility
    var dm = lookForIncExList(terms, errors, MentionQAst.DIRECT_MESSAGE, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    if ("-true" == dm) dm = "false";
    else if ("-false" == dm) dm = "true";
    if ("true" == dm) {
        if (!attrs.visibility) attrs.visibility = "DIRECT_MESSAGE"
        else if (attrs.visibility.indexOf("DIRECT_MESSAGE") < 0) attrs.visibility += " DIRECT_MESSAGE"
    } else if ("false" == dm) {
        // technically 'ISNT a DM' should include EMAIL, INTERNAL and PUBLIC but rather just use PUBLIC
        // this isn't quite right but probably what was intended when the filter was constructed
        if (!attrs.visibility) attrs.visibility = "PUBLIC"
        else if (attrs.visibility.indexOf("PUBLIC") < 0) attrs.visibility += " PUBLIC"
    }

    lookForSample(terms, errors, attrs, root);

    for (var i = 0; i < terms.length; i++) {
        if (terms[i] && !terms[i].handled) {
            errors.push("Unhandled term " + terms[i]);
        }
    }

    // basic filter only supports replyOrderByAuthor = 1 cause this maps to the First Reply checkbox
    if (attrs.replyOrderByAuthor != null && attrs.replyOrderByAuthor !== 'equals-1') {
        errors.push("Unhandled term replyOrderByAuthor " + attrs.replyOrderByAuthor);
    }

    if (errors.length > 0) {
        if (VuexStore.state.account?.dev) {
            console.debug(`dev build: convertExpToAttrs for [${root}]`, errors);
        }
        attrs.errors = errors;
    }

    return attrs;
}

/**
 * Find all the brandIds involved in the filter and return a { include: [], exclude: [] } object with
 * the lists of brands to include and exclude respectively. The lists are empty if the expression is too
 * complicated to analyze or has errors.
 * @deprecated use getBrands
 */
export function extractBrands(filter) {
    var ans = { include: [], exclude: [], isBrandExcluded: isBrandExcluded };
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return ans;
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    var errors = [];
    var s = lookForIncExList(terms, errors, MentionQAst.BRAND, null, MentionQAst.ISORCHILDOF, MentionQAst.ISNTNORCHILDOF,
        null, root);
    if (errors.length == 0 && s && s.length > 0) {
        var a = s.split(" ");
        for (var i = 0; i < a.length; i++) {
            var b = a[i];
            var neg = b.charAt(0) == '-';
            if (neg) b = b.substring(1);
            (neg ? ans.exclude : ans.include).push(parseInt(b));
        }
    }
    return ans;
};

/**
 * Find all the tag IDs involved in the filter and return a { include: [], exclude: [] } object with
 * the lists of tags to include and exclude respectively. The lists are empty if the expression is too
 * complicated to analyze or has errors.
 *
 * This extracts tags related to both the TAG and TOPIC operands.
 * @deprecated use getTags
 */
function extractTags(filter) {
    var ans = { include: [], exclude: [] };
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return ans;
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    var errors = [];
    var s = lookForIncExList(terms, errors, MentionQAst.TAG, null, MentionQAst.IS, MentionQAst.ISNT, null, root);
    if (errors.length == 0 && s && s.length > 0) {
        var a = s.split(" ");
        for (var i = 0; i < a.length; i++) {
            var b = a[i];
            var neg = b.charAt(0) == '-';
            if (neg) b = b.substring(1);
            (neg ? ans.exclude : ans.include).push(parseInt(b));
        }
    }
    return ans;
};

/**
 * Approximately how many days of data might the filter return? Returns 0 if this cannot be determined.
 * @param {string} filter
 * @return {number}
 */
export function extractDays(filter) {
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return "";
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    var published = lookForPublished(terms, [], null, root);
    if (!published) return 0;
    switch (published) {
        case 'TODAY':
        case 'DAY':             return 1;
        case 'TWENTY_FOUR_HRS': return 2;
        case 'WEEK':            return 7;
        case 'MONTH':           return 30;
        case 'QUARTER':         return 90;
        case 'YEAR':            return 365;
    }
    var i = published.indexOf('-');
    if (i < 0) return 0;
    try {
        var start = parseDate(published.substring(0, i));
        var end = parseDate(published.substring(i + 1));
        var days = (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000);
        if (i <= 10) ++days;    // doesn't include time and end date is exclusive so add 1 day
        return days;
    } catch (e) {
        return 0;
    }
}

/**
 * If possible return the date range of the filter { start: .., end: .. } both dates inclusive.
 */
export function filterToDateRange(filter) {
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return "";
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    var published = lookForPublished(terms, [], null, root);
    return published ? toDateRange(published) : null;
}

/**
 * Does the filter have any terms using published date?
 */
export function hasPublished(filter) {
    let root = flattenFilterNodes(parseFilterString(filter))
    let terms = root.type === 'AndNode' ? root.children : [root]
    return lookForPublished(terms, [], null, root)
}

/**
 * If the filter includes published terms then remove them.
 */
export function removePublished(filter) {
    let root = parseFilterString(filter)
    root = removeNodes(root, node => node.operandType === MentionQAst.PUBLISHED)
    return root.toString()
}

/**
 * Dig out any link contains info.
 */
export function extractLink(filter) {
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return "";
    }

    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    return lookForLink(terms, [], root)
}

export function extractSocialNetwork(filter) {
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return "";
    }

    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    return lookForIncExList(terms, [], MentionQAst.SOCIAL_NETWORK, null, MentionQAst.IS, MentionQAst.ISNT, function(lit, node) {
        return node.literalImage;
    }, root)
}

var HANDLE_ID_REGEX = /([^0-9]|^)[0-9]{8,}([^0-9]|$)/;

/**
 * If the filter is likely to have problems in a V4 account then return an array of issues otherwise return an
 * empty array.
 */
export function listV4Issues(filter) {
    var ans = [], a, i, j, s, p, root, attrs;
    if (!filter || !isMashAdmin()) return ans;

    try {
        root = parseFilterString(filter);
    } catch (e) {
        return ans;
    }

    var hasExtract, hasTitle, badAuthorName;
    walkFilterTree(root, function(exp) {
        switch (exp.operandType) {
            case MentionQAst.EXTRACT:       hasExtract = true;  break;
            case MentionQAst.TITLE:         hasTitle = true;    break;
            case MentionQAst.AUTHOR_NAME:
                if (!badAuthorName) {
                    var s = exp.literal;
                    if (HANDLE_ID_REGEX.test(s) || (p = s.indexOf('(')) >= 0 && s.indexOf(')') > p) badAuthorName = true;
                }
                break;
        }
    });

    if (hasExtract) ans.push("Filtering on extract is not supported");
    if (hasTitle) ans.push("Filtering on title is not supported");
    if (badAuthorName) ans.push("Filtering for author handles or IDs in the author name is not supported");

    return ans;
}

var walkFilterTree = function(root, fn) {
    fn(root);
    if (root.lhs) walkFilterTree(root.lhs, fn);
    if (root.rhs) walkFilterTree(root.rhs, fn);
};

/**
 * Does the filter return only verified mentions?
 */
export function isVerifiedOnly(filter) {
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return false;
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    return "VERIFIED" == lookForVerification(terms, [], root);
};

/**
 * Does the filter return only un-verified mentions?
 */
export function isNotVerifiedOnly(filter) {
    try {
        var root = parseFilterString(filter)
    } catch (e) {
        return false;
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    return "NOT_VERIFIED" == lookForVerification(terms, [], root);
};

/**
 * Does the filter return only CrowdVerified mentions? CrowdVerified IS true
 */
export function isCrowdVerifiedOnly(filter) {
    try {
        var root = parseFilterString(filter);
    } catch (e) {
        return "";
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    var crowdVerifiedValue = lookForIncExList(terms, [], MentionQAst.CROWD_VERIFIED, null, MentionQAst.IS, MentionQAst.ISNT, null, root);

    return crowdVerifiedValue === "true";
};

/**
 * Does the filter return only unbiasedSample mentions? UnbiasedSample IS true
 */
export function isUnbiasedSampleOnly(filter) {
    try {
        var root = parseFilterString(filter);
    } catch (e) {
        return "";
    }
    root = flattenFilterNodes(root);
    var terms = root.type == 'AndNode' ? root.children : [root];
    var unbiasedSampleValue = lookForIncExList(terms, [], MentionQAst.UNBIASED_SAMPLE, null, MentionQAst.IS, MentionQAst.ISNT, null, root);

    return unbiasedSampleValue === "true";
};

var isBrandExcluded = function(brandId) {
    return this.include.length > 0 && this.include.indexOf(brandId) < 0
        || this.exclude.length > 0 && this.exclude.indexOf(brandId) >= 0
};

var removeCompoundLinks = function(neg, list) {
    for(var compoundToken in COMPOUND_LINKS) {
        var compoundKey = COMPOUND_LINKS[compoundToken];
        var resolved = false;
        var resolveIndexes = [];
        for(var keyIndex = 0; keyIndex < compoundKey.length; keyIndex++) {
            var ansInd = list.indexOf(neg ? "-" + compoundKey[keyIndex] : compoundKey[keyIndex]);
            if(ansInd >= 0) {
                //we should never have links duped in compound links
                resolveIndexes.push(ansInd);
                resolved = true;
            }
            else {
                resolved = false;
                break;
            }
        }
        if(resolved && resolveIndexes.length == compoundKey.length) {
            resolveIndexes.sort();
            for(var listInd = resolveIndexes.length -1; listInd > 0 ; listInd--) {
                list.splice(resolveIndexes[listInd], 1);
            }
            list.splice(resolveIndexes[0], 1, neg ? "-" + compoundToken : compoundToken);
        }
    }
};

var hasExpNode = function(list, operandType, operator) {
    for (var i = 0; i < list.length; i++) {
        var node = list[i];
        if (!node) continue;
        if (node.children) {
            if (hasExpNode(node.children, operandType, operator)) return true;
        } else if (node.operandType === operandType && node.operationType === operator) {
            return true;
        }
    }
    return false;
};

/**
 * Search list for nodes with the correct operandType and set their entries in the list to null. If includeOrs
 * is true then OrNode's containing only exp nodes with the operandType are also removed and rolled into a
 * single OrNode.
 */
var removeExpNodes = function(list, operandType, operandImage, operation, includeOrs, root, nodePredicate) {
    var ans = [];
    var orNode = null;
    operandImage = operandImage ? operandImage.toLowerCase() : null;

    function operandMatches(node) {
        var typeMatches = node && node.operandType == operandType;
        var imageMatches = !operandImage || (node.operandImage && node.operandImage.toLowerCase() === operandImage);
        var predicateMatchs = !nodePredicate || nodePredicate(node);

        return typeMatches && imageMatches && predicateMatchs;
    }

    var countORsWithOnlyOperandChildren = function (list) {
        var count = 0;
        for (var i = 0; i < list.length; ++i) {
            node = list[i];
            if (node && node.type === 'OrNode') {
                var j = node.children.length - 1;
                while ((j >= 0) && operandMatches(node.children[j])) j--;
                if (j < 0) ++count;
            }
        }
        return count;
    };

    // ORs with all their children operands in common cannot be reduced to a single OR node if their
    // parent node is an AND node.
    // if the parent is an AND node, having OR nodes containing common children operands is a violation of the basic filter.
    // thus, only one such OR must be in the term list otherwise the filter is considered advanced

    var canAddORs = includeOrs ? ((root && root.type === 'OrNode') || (countORsWithOnlyOperandChildren(list) < 2))  : false;

    for (var i = 0; i < list.length; i++) {
        var node = list[i];
        if (node) {
            if (operandMatches(node) && (operation == null || node.operationType == operation)) {
                ans.push(node);
                list[i] = null;
            } else if (includeOrs && node.type == 'OrNode' && canAddORs) {
                var j = node.children.length - 1;
                for (; j >= 0 && operandMatches(node.children[j]); j--);
                if (j < 0) {
                    // append children to previously found or node if possible
                    if (orNode) orNode.children.push.apply(orNode.children, node.children);
                    else ans.push(orNode = node);
                    list[i] = null;
                }
            }
        }
    }
    return ans;
};

var lookForRelevancy = function(terms, errors, root, options) {
    var found = removeExpNodes(terms, MentionQAst.RELEVANCY, null, null, null, root);
    var ans = null;
    for (var i = 0; i < found.length; i++) {
        var exp = found[i];
        if (exp.operationType == MentionQAst.IS) {
            if (exp.literal == MentionQAst.IRRELEVANT) {
                if (ans == null || ans === "IRRELEVANT") ans = "IRRELEVANT";
                else errors.push("Unhandled term " + exp);
            } else {
                errors.push("Unhandled term " + exp);
            }
        } else if (exp.operationType == MentionQAst.ISNT) {
            if (exp.literal == MentionQAst.IRRELEVANT) {
                if (ans == null || ans === "ISNT_IRRELEVANT") ans = "ISNT_IRRELEVANT";
                else errors.push("Unhandled term " + exp);
            } else {
                errors.push("Unhandled term " + exp);
            }
        } else {
            errors.push("Unhandled term " + exp);
        }
    }
    return ans ? ans : (options && options.partial ? null : "ALL");
};

var lookForVerification = function(terms, errors, root, options) {
    var found = removeExpNodes(terms, MentionQAst.PROCESS, null, null, null, root);
    var ans = null;
    for (var i = 0; i < found.length; i++) {
        var exp = found[i];
        if (exp.operationType == MentionQAst.IS) {
            if (exp.literal == MentionQAst.VERIFIED) {
                if (ans == null || ans === "VERIFIED") ans = "VERIFIED";
                else errors.push("Unhandled term " + exp);
            } else {
                errors.push("Unhandled term " + exp);
            }
        } else if (exp.operationType == MentionQAst.ISNT) {
            if (exp.literal == MentionQAst.VERIFIED) {
                if (ans == null || ans === "NOT_VERIFIED") ans = "NOT_VERIFIED";
                else errors.push("Unhandled term " + exp);
            } else {
                errors.push("Unhandled term " + exp);
            }
        } else {
            errors.push("Unhandled term " + exp);
        }
    }
    return ans ? ans : (options && options.partial ? null : "ALL");
};

var lookForSample = function(terms, errors, attrs, root) {
    var found = removeExpNodes(terms, MentionQAst.SAMPLE, null, null, null, root);
    if (found.length == 0) return;
    if (found.length > 1) {
        errors.push("Only one sample expression is allowed");
    } else {
        var sa = found[0].literal;
        attrs.proportion = sa.proportion * 100;
        attrs.seed = sa.seed ? sa.seed : "";
    }
};

// Evaluate the expression for each entry in vals and keep those that are true
var lookForNumeric = function(operandType, vals, terms, errors) {
    var ans = [];
    for (var i = 0; i < vals.length; i++) {
        var value = vals[i];
        var res = true;
        for (var j = 0; j < terms.length; j++) {
            var t = terms[j];
            if (t) {
                var b = evalNumericExp(operandType, t, value, errors, true);
                if (b != null) res = res && b;
            }
        }
        if (res) ans.push(value);
    }
    return ans.length > 0 && ans.length != vals.length ? ans.join(" ") : null;
};

var evalNumericExp = function(operandType, exp, value, errors) {
    var i, res, b, c;
    if (exp.operandType == operandType) {
        exp.handled = true;
        switch (exp.operationType) {
            case MentionQAst.LESSTHAN:          return value < exp.literal;
            case MentionQAst.LESSTHANEQUALS:    return value <= exp.literal;
            case MentionQAst.GREATERTHANEQUALS: return value >= exp.literal;
            case MentionQAst.GREATERTHAN:       return value > exp.literal;
            case MentionQAst.IS:
            case MentionQAst.EQUALS:
                return exp.literal == null ? value == "UNKNOWN" : value == exp.literal;
            default:
                exp.handled = false;
        }
    } else if (exp.type == 'AndNode') {
        // if any child returns non-null then all children must return non-null i.e. involve our operandType
        res = true;
        for (i = c = 0; i < exp.children.length; i++) {
            b = evalNumericExp(operandType, exp.children[i], value, errors);
            if (b != null) {
                ++c;
                res = res && b;
            }
        }
        if (c == exp.children.length) {
            exp.handled = true;
            return res;
        }
    } else if (exp.type == 'OrNode') {
        // if any child returns non-null then all children must return non-null i.e. involve operandType
        res = false;
        for (i = c = 0; i < exp.children.length; i++) {
            b = evalNumericExp(operandType, exp.children[i], value, errors);
            if (b != null) {
                ++c;
                res = res || b;
            }
        }
        if (c == exp.children.length) {
            exp.handled = true;
            return res;
        }
    }
    return null;
};

var lookForInteger = function(operandType, terms, errors, root) {
    var found = removeExpNodes(terms, operandType, null, null, true, root);
    if (found.length > 2) {
        errors.push('Too many integer values');
        return;
    }
    else if (found.length == 1) {
        switch (found[0].operationType) {
            case MentionQAst.EQUALS:
                return "equals-" + found[0].literalImage;
            case MentionQAst.LESSTHAN:
                return "less-than-" + found[0].literalImage;
            case MentionQAst.GREATERTHAN:
                return "greater-than-" + found[0].literalImage;
            default:
                errors.push('Unsupported integer operator [' + found[0].operationType + ']');
                return;
        }
    }
    else if (found.length == 2) {
        var lessThan = _(found).find(function(item) { return item.operationType == MentionQAst.LESSTHAN;});
        var greaterThan = _(found).find(function(item) { return item.operationType == MentionQAst.GREATERTHAN; });

        if (!lessThan || !greaterThan) {
            errors.push('Unsupported operator combination for integral values');
            return;
        }

        return "between-" + greaterThan.literal + "-" + lessThan.literal;

    }
};

var lookForIntegerRange = function(operandType, terms, errors, root) {
    var found = removeExpNodes(terms, operandType, null, null, true, root);

    if (found.length > 2) {
        errors.push('Too many integer values');
    }
    else if (found.length == 2) {
        var lessThan = _(found).find(function(item) { return item.operationType == MentionQAst.LESSTHAN;});
        var greaterThan = _(found).find(function(item) { return item.operationType == MentionQAst.GREATERTHAN; });

        if (!lessThan || !greaterThan) {
            errors.push('Unsupported operator combination for integral values');
            return;
        }

        return "between-" + greaterThan.literal + "-" + lessThan.literal;

    }
    else if (found.length == 1) {
        switch (found[0].operationType) {
            case MentionQAst.LESSTHAN:
                return "less-than-" + found[0].literalImage;
            case MentionQAst.GREATERTHAN:
                return "greater-than-" + found[0].literalImage;
            default:
                errors.push('Unsupported integer operator [' + found[0].operationType + ']');
                return;
        }
    }
};

var lookForAuthor = function(terms, errors, root) {
    // todo make this handle IN and NOTIN as well
    return lookForIncExList(terms, errors, MentionQAst.AUTHOR_ID, null, MentionQAst.IS, MentionQAst.ISNT, quoteAuthorId, root);
};

var lookForIsIsntEnum = function(operand, items, terms, errors, root) {
    var found = removeExpNodes(terms, operand, null, null, true, root);
    var is = [];
    var isnt = [];
    for (var i = 0; i < found.length; i++) {
        var node = found[i];
        if (node.type == 'OrNode') {
            for (var j = 0; j < node.children.length; j++) {
                var exp = node.children[j];
                if (exp.operationType == MentionQAst.IS) {
                    if (isnt.length > 0) {
                        errors.push("Cannot mix IS and ISNT: " + exp);
                        return
                    }
                    is.push(exp.literalImage.toUpperCase());
                } else {
                    errors.push("Unhandled term " + exp);
                }
            }
        } else if (node.operationType == MentionQAst.ISNT) {
            if (is.length > 0) {
                errors.push("Cannot mix IS and ISNT: " + exp);
                return
            }
            isnt.push(node.literalImage.toUpperCase());
        } else if (node.operationType == MentionQAst.IS) {
            if (isnt.length > 0) {
                errors.push("Cannot mix IS and ISNT: " + exp);
                return
            }
            // this will match, for example, "Media IS ENTERPRISE AND Media IS PRESS" which isn't quite right but that filter
            // won't match any mentions anyway and likely the user mixed up AND's and OR's which will now be fixed
            is.push(node.literalImage.toUpperCase());
        } else {
            errors.push("Unhandled term " + node);
        }
    }
    if (isnt.length > 0) {
        // make sure all options that are not in 'isnt' are in 'ans'
        for (var m in items) {
            if (isnt.indexOf(m) < 0 && is.indexOf(m) < 0) is.push(m);
        }
    }
    return is.length > 0 ? is.join(" ") : null;
};

var lookForMedia = function(terms, errors, root) {
    return lookForIsIsntEnum(MentionQAst.MEDIA, MEDIA_CATEGORIES, terms, errors, root);
};

var lookForVisibility = function(terms, errors, root) {
    // These items have an extra field for "all" that is not supported in the filter language.
    const items = {...VISIBILITY_ITEMS};
    delete items['*'];
    return lookForIsIsntEnum(MentionQAst.VISIBILITY, items, terms, errors, root);
};

var lookForHasReplyFromProfile = function(terms, errors, root) {
    let found = removeExpNodes(terms, MentionQAst.HAS_REPLY_FROM_PROFILE, null, null, true, root)
    if (!found.length) return null
    return found.map(d => {
        let prefix = d.operationType == MentionQAst.IN ? "" : "-"
        return d.literalImage.split(/\s*,\s*/).map(s => prefix + s).join(" ")
    }).join(" ")
};

var lookForGender = function(terms, errors, root) {
    return lookForIsIsntEnum(MentionQAst.GENDER, GENDER_ITEMS, terms, errors, root);
};

var lookForLink = function(terms, errors, root) {
    var foundPos = removeExpNodes(terms, MentionQAst.LINK, null, MentionQAst.CONTAINS, true, root);
    var foundNeg = removeExpNodes(terms, MentionQAst.LINK, null, MentionQAst.DOESNTCONTAIN, true, root);
    var ans = [], site, spec;
    var inc = null;
    for (var i = 0; i < foundPos.length; i++) {
        var node = foundPos[i];
        if (node.type == 'OrNode') {
            for (var j = 0; j < node.children.length; j++) {
                var exp = node.children[j];
                if (exp.operationType == MentionQAst.CONTAINS) {
                    ans.push(exp.literal.toLowerCase());
                } else {
                    errors.push("Unhandled term " + exp);
                }
            }
        } else if (node.operationType == MentionQAst.CONTAINS && (node.type == 'OrNode' || foundPos.length == 1)) {
            site = node.literal.toLowerCase();
            spec = isSpecialSite(site);
            ans.push(spec ? spec : site);
        } else {
            errors.push("Unhandled term " + node);
        }
    }
    for (i = 0; i < foundNeg.length; i++) {
        node = foundNeg[i];
        if (node.operationType == MentionQAst.DOESNTCONTAIN) {
            site = node.literal.toLowerCase();
            spec = isSpecialSite(site);
            if (spec) {
                if (!inc) inc = _.clone(LINK_NAMES);
                delete inc[spec];
            }
            else ans.push("-" + site);
        } else {
            errors.push("Unhandled term " + node);
        }
    }
    removeCompoundLinks(false, ans);
    removeCompoundLinks(true, ans);
    if (inc) {
        var a = [];
        for (site in inc) if (site != "other") a.push(site);
        a.push("other");
        a.push.apply(a, ans);
        ans = a;
    }

    // Drop conflicting tokens
    // Eg having both token_print_media and -token_print_media cancel each other.
    var newAns = [];
    _.each(ans, function (element) {
        var inv;
        if(element.charAt(0) != '-') {inv = '-' + element;}
        else {inv = element.substring(1);}

        if(ans.indexOf(inv) < 0) newAns.push(element);
    });

    ans = newAns;

    return ans.length > 0 ? ans.join(" ") : null;
};

var isSpecialSite = function(site) {
    if (site.indexOf("other") < 0) {
        for (var i in LINK_NAMES) {
            if (site === i) return i;
        }
    }
    return null;
};

var lookForIncExList = function(terms, errors, operandType, operandImage, is, isnt, literalTransform, root,
                                keepArray, multipleAndTermsOk, nodePredicate) {
    var foundPos = removeExpNodes(terms, operandType, operandImage, is, true, root, nodePredicate);
    var foundNeg = isnt ? removeExpNodes(terms, operandType, operandImage, isnt, true, root, nodePredicate) : [];
    var ans = [], lit, i, node, exp;
    if (multipleAndTermsOk && foundPos.length > 1) {
        // if all the positive terms are IS e.g. Topic IS 69882 AND Topic IS 70331 then prepend the AND flag '&'
        // indicating that the "All topics must be present on each mention" checkbox should be ticked etc.
        for (i = 0; i < foundPos.length && foundPos[i].operationType == is; i++);
        if (i == foundPos.length) {
            ans.push('&');
            for (i = 0; i < foundPos.length; i++) {
                exp = foundPos[i];
                lit = exp.literal? exp.literal : exp.literalImage;
                if (isFunction(literalTransform)) lit = literalTransform(lit, exp);
                ans.push(lit);
            }
            foundPos = [];
        }
    }
    for (i = 0; i < foundPos.length; i++) {
        node = foundPos[i];
        if (node.type == 'OrNode') {
            for (var j = 0; j < node.children.length; j++) {
                exp = node.children[j];
                if (exp.operationType == is) {
                    lit = exp.literal? exp.literal : exp.literalImage;
                    if (isFunction(literalTransform)) lit = literalTransform(lit, exp);
                    ans.push(lit);
                } else {
                    errors.push("Unhandled term " + exp);
                }
            }
        } else if (node.operationType == is && (node.type == 'OrNode' || foundPos.length == 1)) {
            lit = node.literal? node.literal : node.literalImage;
            if (isFunction(literalTransform)) lit = literalTransform(lit, node);
            ans.push(lit);
        } else {
            errors.push("Unhandled term " + node);
        }
    }
    for (i = 0; i < foundNeg.length; i++) {
        node = foundNeg[i];
        if (node.operationType == isnt) {
            lit = node.literal? node.literal : node.literalImage;
            if (isFunction(literalTransform)) lit = literalTransform(lit, node);
            ans.push("-" + lit);
        } else {
            errors.push("Unhandled term " + node);
        }
    }
    if (ans.length == 0) return null;
    return isObject(ans[0]) || keepArray ? ans : ans.join(" ");
};

export function lookForPublished(terms, errors, options, root) {
    options = options || {};
    var found = removeExpNodes(terms, MentionQAst.PUBLISHED, null, null, null, root);
    var exp, exp2;
    switch (found.length) {
        case 0:
            if (!options.noPublished) errors.push("No published date");
            break;
        case 1:
            try {
                exp = found[0];
                if (exp.literalImage?.toLowerCase() === 'hour') {
                    errors.push("Unhandled published term: " + exp);
                    break;
                }
                if (exp.literalImage?.toLowerCase() === 'epoch') {
                    errors.push("Unhandled published term: " + exp);
                    break;
                }
                if (exp.operationType == MentionQAst.INTHELAST) {
                    return tokenMap[exp.literal];
                } else if (exp.operationType == MentionQAst.AFTER) {
                    return extractDate(exp.literal) + "-" +
                        parseDate(exp.literal).add({ years: 1 , days: -1}).toString('yyyy/MM/dd');
                } else if (exp.operationType == MentionQAst.BEFORE) {
                    var d = parseDate(exp.literal).add({ years: -1 });
                    // make sure start date is before today
                    while (d.getTime() > new Date().getTime()) d = d.add({ years: -1 });
                    return d.toString('yyyy/MM/dd') + "-" + extractDate(exp.literal);
                } else if (exp.operationType == MentionQAst.ON && exp.literal == MentionQAst.TODAY) {
                    return tokenMap[exp.literal];
                } else {
                    errors.push("Unhandled Published term: " + exp);
                }
            }
            catch (e) {
                errors.push("Unhandled Published term: " + exp);
            }
            break;
        case 2:
            try {
                exp = found[0];
                exp2 = found[1];
                if (exp2.operationType == MentionQAst.AFTER && exp.operationType == MentionQAst.BEFORE) {
                    let t = exp;
                    exp = exp2;
                    exp2 = t;
                }
                if (exp.operationType == MentionQAst.AFTER && exp2.operationType == MentionQAst.BEFORE) {
                    var end = parseDate(exp2.literal);
                    var hasHours = isHour(exp2.literal);
                    if (!hasHours)  end.add({ days: -1 });
                    else end.add({ minutes: -1 });
                    return extractDate(exp.literal) + "-" + end.toString(hasHours ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd');
                }
            }
            catch (e) {
                console.debug(e)
            }
        default:
            errors.push("Unhandled Published terms: " + found);
    }
    return null;
};

var lookForSentiment = function(terms, errors) {
    var sentiAttrs = lookForNumeric(MentionQAst.SENTIMENT, [-5,-4,-3,-2,-1,1,2,3,4,5,"UNKNOWN"], terms, errors);
    var foundSentiment = removeExpNodes(terms, MentionQAst.SENTIMENT, null, null, true);

    if (foundSentiment && foundSentiment.length && (!sentiAttrs || !sentiAttrs.length)) {
        errors.push("Unable to merge these sentiment values");
        return null;
    }
    // Don't want to add Sentiment is UNKNOWN to the basic filter, so chuck error for it.
    if (sentiAttrs) {
        if (sentiAttrs.indexOf("UNKNOWN") !== -1) {
            errors.push("Unhandled Sentiment UNKNOWN");
        } else {
            // shrink to 3 point scale
            var a = splitAtSpaces(sentiAttrs);
            var haveNeg, haveNeutral, havePos;
            for (var i = 0; i < a.length; i++) {
                var v = parseInt(a[i]);
                if (v < 0) haveNeg = true;
                else if (v > 1) havePos = true;
                else haveNeutral = true;
            }
            sentiAttrs = ((haveNeg ? "-1 " : "") + (haveNeutral ? "1 " : "") + (havePos ? "2" : "")).trim();
        }
    }
    return sentiAttrs
};


/** Returns null if s is empty or undefined */
var trim = function(s) {
    if (!s) return null;
    s = s.trim();
    return s.length == 0 ? null : s;
};


