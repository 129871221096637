<template>
    <dark-card class="explore-risk-chart">
        <section>
            <header>
                <h4>Risk</h4>
                <p v-if="total !== null" class="short-animated fadeIn">
                    <deq-number :number="total"/>
                    {{ formatPlural(total, 'mention') }}
                </p>
            </header>
        </section>
        <div class="explore-risk-chart__chart" ref="chart">
        </div>
        <section>
            <footer>
                <section v-if="firedlogs === null"
                    class="explore-risk-chart__loading short-animated fadeIn delay-2000">
                    Checking when alerts were last sent
                </section>
                <section v-else-if="!firedlogs.length"
                         key="none"
                         class="short-animated fadeIn">
                    No alerts were sent in this time period.
                </section>
                <section v-else class="short-animated fadeIn" key="counts">
                    <old-tooltip
                        label="These are the most recent times that Risk volumes were high enough that people were notified">
                        <h4>
                            Recent alerts
                        </h4>

                        <div class="explore-risk-chart__table">
                            <template v-for="log in recentlyFired">
                                <span :key="log.date">
                                    <be-button link @click="showMentions(log.date)"
                                               :class="{'explore-risk-chart__warning': isYesterday(log.date)}"
                                               tooltip="Click to see mentions">
                                        <span v-if="isToday(log.date)">
                                            Today<i class="symbol-warning"></i>
                                        </span>
                                        <span v-else-if="isYesterday(log.date)">
                                            Yesterday<i class="symbol-warning"></i>
                                        </span>
                                        <span v-else>{{ formatDate(log.date, "MMMM D") }}</span>
                                    </be-button>
                                </span>
                            </template>
                            <span v-if="firedlogs.length > recentlyFired.length"
                                  class="explore-risk-chart__table--span">
                                And <deq-number :number="firedlogs.length - recentlyFired.length"/> more times
                            </span>
                        </div>
                    </old-tooltip>
                </section>
            </footer>
        </section>
    </dark-card>
</template>


<script>
import DarkCard from "@/components/cards/DarkCard";
import {mapActions, mapGetters} from "vuex";
import {count as grouseCount} from "@/data/Grouse";
import * as b3js from "brandseyejs";
import {formatDate, formatPercentage, formatPlural} from "@/app/utils/Format";
import {codeToColour} from "@/app/utils/Metatags";
import DeqNumber from "@/components/formatters/DeqNumber";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import PcsChartTooltip from "@/app/toplevel/explore/overview/components/PcsChartTooltip";
import moment from "moment";
import {appendFiltersReadably, earliestDate, latestDate, replaceDate} from "@/dashboards/filter/FilterParser";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import OldTooltip from "@/components/tooltip/OldTooltip";
import BeButton from "@/components/buttons/BeButton";


// Used in the explore panel to show risk for a brand, and when risk was spiking.
export default {
    components: {DeqNumber, OldTooltip, BeButton, DarkCard},
    props: {
        brand: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            beChart: null,

            counts: null,
            total: null,
            firedlogs: null
        }
    },

    computed: {
        ...mapGetters('explorePanel', ['dateFilter']),
        ...mapGetters('digests', ['riskAlerts']),

        filter() {
            const rawFilter = `(${this.dateFilter}) and brand isorchildof ${this.brand.id} and relevancy isnt irrelevant`;
            return appendFiltersReadably(rawFilter, "tag is 1");
        },

        brandRiskAlert() {
            if (!this.riskAlerts?.length) return null;
            const alerts =  this.riskAlerts.filter(a => a.externalId.startsWith(`${this.brand.id}:`));
            return alerts.length ? alerts[0] : null;
        },

        recentlyFired() {
            if (!this.firedlogs?.length) return [];
            return this.firedlogs.slice(0, 2);

        }
    },

    watch: {
        brand()             { this.loadData() },
        dateFilter()        { this.loadData(); this.findFiredLogs(); },
        counts()            { this.render() },
        firedlogs()         { this.render() },
        brandRiskAlert()    { this.findFiredLogs()}
    },

    async mounted() {
        await this.loadData();
        await this.findFiredLogs();

        this.sizeObserver = new ResizeObserver(() => this.redraw());
        if (this.$el) this.sizeObserver.observe(this.$el);
    },

    async beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.sizeObserver?.disconnect();
    },

    activated() {
        if (this.$el) this.sizeObserver?.observe(this.$el);
    },

    async deactivated() {
        this.sizeObserver?.disconnect();

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = null;
    },

    methods: {
        formatPlural,
        formatDate,

        ...mapActions('digests', ['refreshDigests', 'getLogsForAlerts']),

        isToday(date) {
            return new moment(date).isSameOrAfter(new moment().startOf('day'));
        },

        isYesterday(date) {
            return new moment(date).isSameOrAfter(new moment().subtract(1, 'day').startOf('day'));
        },

        async loadData() {
            const filter = this.filter;
            this.counts = await grouseCount(filter, ["published"]);
            this.total = this.counts.reduce((total, current) => total + current.mentionCount, 0);
            this.counts.forEach(d => d.percent = this.total ? d.mentionCount / this.total : 0);

            await this.refreshDigests();
        },

        async findFiredLogs() {
            this.firedlogs = null;
            if (!this.brandRiskAlert) return;
            let logs = await this.getLogsForAlerts(this.brandRiskAlert);
            const earliest = earliestDate(this.dateFilter);
            const latest = latestDate(this.dateFilter);
            this.firedlogs = logs
                .filter(l => new Date(l.date) >= earliest && new Date(l.date) < latest)
                .sort((lhs, rhs) => new Date(lhs.date) < new Date(rhs.date));
        },

        showMentions(date) {
            showMentions(
                replaceDate(this.filter, `published on '${formatDate(date,'YYYY-MM-DD')}'`),
                `Spiking risk on ${formatDate(date, 'YYYY-MM-DD')} (by engagement)`,
                true
            )
        },

        redraw() {
            if (this.timeout) return;

            this.timeout = setTimeout(() => {
                try {
                    this.render();
                } finally {
                    this.timeout = null;
                }
            }, 100);
        },

        render() {
            if (!this.counts?.length) return;

            const chart = this.$refs.chart;
            if (!chart) return; // Might have moved on from the page.

            this.beChart ??= new b3js.chart();

            if (chart) {
                chart.innerHTML = "";

                this.beChart
                    .reset()
                    .geometry(b3js.line())
                    .scaleX(b3js.scaleTime())
                    .data(this.counts)
                    .element(chart)
                    .x(d => d.published)
                    .y(d => d.percent)
                    .formatY(val => formatPercentage(val * 100))
                    .formatX((val, i) => {
                        let format = this.counts.length > 7 ? "D" : "ddd D";
                        if (i === 0) format = "ddd D";
                        if (moment(val).isSameOrAfter(moment().startOf('day'))) return "Today";
                        return formatDate(val, format);
                    })
                    .xLabelAngle(0)
                    .width(chart.clientWidth)
                    .height(chart.clientHeight + 20)
                    // .showXAxis(false)
                    .showLegend(false)
                    .individualColours(() => codeToColour("RISK"))
                    .render();

                // if (this.firedlogs) {
                //     const pointSet = new Set(this.firedlogs.map(f => formatDate(f.date, "YYYY-MM-DD")));
                //     const points = this.counts.filter(d => pointSet.has(d.published));
                //     const svg = this.$refs.chart.querySelector('svg svg');
                //     if (svg) {
                //         points.forEach(p => {
                //             const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
                //             circle.setAttribute('cx', '50');
                //             circle.setAttribute('cy', '50');
                //             circle.setAttribute('r', '10');
                //             circle.setAttribute('fill', 'red');
                //             svg.appendChild(circle);
                //         })
                //     }
                // }

                this.beChart.dispatch().on("tooltipShow", event => {
                    showTooltipComponent(event.e.currentTarget,
                        PcsChartTooltip,
                        {
                            data: event.point
                        })
                });

                this.beChart.dispatch().on("elementClick", event => {
                    const after = moment(event.point.published);
                    const before = moment(event.point.published).clone().add({'days': 1})
                    // We use replace date to try to get a filter that the basic filter will parse in the mention panel.
                    const filter = replaceDate(this.filter,
                        `published after '${after.format("YYYY/MM/DD")}' and published before '${before.format("YYYY/MM/DD")}'`);
                    showMentions(
                        filter,
                        `Risk related mentions from ${event.point.published} (by engagement)`,
                        true
                    )
                });
            }
        }
    }


}
</script>


<style scoped lang="sass">

.explore-risk-chart
    display: flex
    min-width: 600px
    max-width: 800px
    height: auto
    overflow: hidden

    header
        width: 100px

    footer
        width: 120px

    h4
        margin: 0
        padding: 0
        color: white

    p
        color: var(--be-colour-muted-text-dark)

    .explore-risk-chart__loading
        font-style: italic
        color: var(--be-colour-muted-text-dark)

    .explore-risk-chart__table
        font-size: 0.9em
        color: var(--be-colour-muted-text-dark)
        display: grid
        grid-template-columns: 1fr
        .explore-risk-chart__month
            text-align: right

        .explore-risk-chart__table--span
            margin: 0
            font-style: italic


    .explore-risk-chart__chart
        width: 100%
        height: 100%

    .explore-risk-chart__warning
        --be-button-link-colour: orange
        --be-colour-text-dark__hover: darkorange

    .symbol-warning
        color: orange

</style>