<template>
    <section class="dashboard-contents hide-in-fullscreen"
             :class="{'dashboard-contents--draggable': draggingEnabled}">
        <section v-if="visible"
                 class="dashboard-contents__frame"
                 @click="expand()">
        </section>

        <nav class="dashboard-contents__button" tabindex="-1" @click="expand()"
             title="Click to open section navigation">
            <h6>contents</h6>
        </nav>

        <sidebar-component class="dashboard-contents__sidebar" :class="{'dashboard-contents__sidebar--hidden': !visible}">
            <sidebar-header v-if="dashboard">
                <h4>Contents</h4>
            </sidebar-header>
            <sidebar-header v-else>
                <h4>Dashboard loading...</h4>
            </sidebar-header>

            <template v-for="(section, i) in sections">
                <dotted-card v-if="dragging && dragging !== section && draggingIndex !== i - 1"
                             @dragover.native.prevent
                             @dragenter.native.prevent="dragEnter"
                             @dragleave.native.prevent="dragLeave"
                             class="dashboard-contents__drag-target deq-callout--muted"
                             :key="'drag' + section.id"
                             @drop.native.stop.prevent="onDrop($event, i)">
                    <span v-if="i === 0">move to start</span>
                    <span v-else>move here</span>
                </dotted-card>

                <sidebar-menu-item
                    :active="section.id === visibleId"
                    :key="section.id"
                    class="dashboard-contents__item"
                    :class="{'dashboard-contents__item--dragging': dragging === section}"
                    @click="open(section)"
                    :draggable="draggingEnabled"
                    :disabled="disabled"
                    @dragstart.native="onDragStart($event, section)"
                    @dragend.native.prevent="onDragEnd">
                    {{ section.title }}
                </sidebar-menu-item>
            </template>

            <dotted-card v-if="dragging && dragging !== sections.at(-1) && draggingIndex !== sections.length - 1"
                         @dragover.native.prevent
                         @dragenter.native.prevent="dragEnter"
                         @dragleave.native.prevent="dragLeave"
                         class="dashboard-contents__drag-target deq-callout--muted"
                         key="drag-last"
                         @drop.native.stop.prevent="onDrop($event, sections.length)">
                move to end
            </dotted-card>
        </sidebar-component>
    </section>
</template>


<script>
import SidebarComponent from "@/components/sidebar/SidebarComponent";
import SidebarHeader from "@/components/sidebar/SidebarHeader";
import SidebarMenuItem from "@/components/sidebar/SidebarMenuItem";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions} from "vuex";
import DottedCard from "@/components/cards/DottedCard";
import {dashboardIsEditable} from "@/dashboards/DashboardUtils";

export default {
    store: VuexStore,
    components: {DottedCard, SidebarMenuItem, SidebarHeader, SidebarComponent},

    props: {
        dashboard: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            visible: false,
            full: null,
            visibleId: null,
            dragging: null,
            disabled: false
        }
    },

    computed: {
        sections() {
            return this.full?.sections ?? [];
        },

        draggingEnabled() {
            return !this.disabled
        },

        draggingIndex() {
            if (!this.dragging) return -1;
            return this.sections.indexOf(this.dragging);
        }
    },

    watch: {
        async 'dashboard.id'() {
            if (this.dashboard?.id) {
                await this.loadDashboard();
            }
        }
    },

    async mounted() {
        // We want to be able to hide the sidebar when the user clicks escape.
        window.addEventListener('keyup', this.keyPressHandler);
        if (this.dashboard?.id) {
            await this.loadDashboard();
        }
    },

    beforeDestroy() {
        window.removeEventListener('keyup', this.keyPressHandler);
    },

    methods: {
        ...mapActions('dashboards', {getFullDashboardFromStore: 'getFullDashboard'}),

        async loadDashboard() {
            try {
                this.full = await this.getFullDashboardFromStore(this.dashboard.id);
                this.markVisible();
                this.disabled = false;
            } catch (e) {
                if (e.response?.status === 404) return;
                console.error(e);
            }
        },

        async expand() {
            if (!this.full) return;
            this.visible = !this.visible;
            if (this.visible) {
                await this.loadDashboard();
            }
        },

        open(section) {
            this.visible = false;
            this.visibleId = section?.id;
            this.$emit('section-selected', section);
        },
        markVisible() {
            const sections = document.querySelectorAll('.section[data-id]');
            if (sections?.length) {
                const height = window.innerHeight || document.documentElement.clientHeight;
                const right = window.innerWidth || document.documentElement.clientWidth;

                let visibleSection = null
                for (const section of sections) {
                    const rect = section.getBoundingClientRect();
                    const isVisible = rect.top >= 0 && rect.top <= height;
                    if (isVisible) {
                        visibleSection = section.dataset.id;
                        break;
                    }
                }

                this.visibleId = visibleSection ? parseInt(visibleSection) : null;
            }
        },

        keyPressHandler(ev) {
            if (ev.keyCode === 27) {
                this.visible = false;
            }
        },

        onDragOver(event) {
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.dropEffect = "move";

        },

        onDragStart(event, section) {
            // Not all users can edit dashboards. This will
            // cancel the drag and drop completely.
            if (!this.draggingEnabled || !dashboardIsEditable(this.dashboard)) {
                event.preventDefault();
                return;
            }

            this.dragging = section;
            event.dataTransfer.dropEffect = "move";
            event.dataTransfer.setData("text/plain", section.title ?? "«Untitled section»");
        },

        onDragEnd() {
            this.dragging = null;
        },

        dragEnter(event) {
            event.target.classList.add("dashboard-contents__drag-target--highlight");
        },

        dragLeave(event) {
            event.target.classList.remove("dashboard-contents__drag-target--highlight");
        },

        onDrop(event, position) {
            try {
                if (!dashboardIsEditable(this.dashboard)) {
                    return;
                }

                this.disabled = true;
                this.$emit('section-moved', {section: this.dragging, position});

                const replacement = this.full.sections.filter(s => s.id !== this.dragging.id);
                replacement.splice(position - 1, 0, this.dragging);
                this.full.sections = replacement;

                const id = this.dragging.id;
                this.$nextTick(() => this.visibleId = id);

                setTimeout(() => this.loadDashboard(), 1_000)
            } finally {
                this.dragging = null;
            }
        }
    }
}
</script>


<style scoped lang="sass">

.dashboard-contents__button
    background-color: var(--colour-background-black)
    position: fixed
    top: 40px
    right: 0
    width: var(--dashboard-nav-width)
    height: 100%
    cursor: pointer

    h6
        text-transform: uppercase
        transform: rotate(90deg)
        transform-origin: left top 0
        float: left
        margin-left: 25px


.dashboard-contents__sidebar
    left: unset
    right: 0
    transition: right 250ms
    box-shadow: -5px -5px 5px var(--dashboard-sidebar-shadow-colour)

    &--hidden
        right: calc(-1 * var(--sidebar-size) - 20px)


.dashboard-contents__frame
    position: fixed
    inset: 0
    z-index: 999
    height: 100vh
    width: 100vw
    background: rgba(0, 0, 0, 0.5)
    animation: fadeIn 250ms both

.dashboard-contents__drag-target
    display: none
    > *
        pointer-events: none // Don't want any spans etc in a card to effect dragging events.

.dashboard-contents__item
    isolation: isolate

.dashboard-contents--draggable
    .dashboard-contents__item::before
        content: '... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...'
        height: 100%
        max-height: 100%
        width: 20px
        background: rgb(0 0 0 / 0.5)
        z-index: 20
        position: absolute
        top: 0
        left: -20px
        opacity: 0
        cursor: move
        overflow: hidden
        font-family: sans-serif
        font-size: 18px
        line-height: 5px
        padding-inline: 2px
        box-sizing: border-box

    .dashboard-contents__item:hover::before,
    .dashboard-contents__item--dragging::before
        opacity: 1
        transition: opacity 200ms 1s

    .dashboard-contents__item--dragging::before
        transition-duration: 0ms

    .dashboard-contents__drag-target
        display: block
        padding: 0
        text-align: center
        color: var(--be-colour-muted-text-dark)
        overflow: hidden
        animation: expand both 250ms


.dashboard-contents__drag-target--highlight
    background: var(--background-menu-hover)
    color: var(--colour-menu-hover) !important



@keyframes expand
    0%
        margin-block: 0
        max-height: 0
    100%
        margin-block: 2px
        max-height: 20px



</style>

