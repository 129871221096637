import { MentionQLexer } from '@/mentionq/mentionq'
import {earliestDate, latestDate, parseFilterString} from "@/dashboards/filter/FilterParser";
import moment from "moment";

/**
 * Given a field as described in the filter language, this gives the appropriate
 * lexer token.
 * @param {string} field
 * @returns {string}
 */
export function getLexerTokenForFantasticField(field) {
    field = (field || "").toUpperCase();
    const keys = Object.keys(MentionQLexer);

    if (keys.find(function(k) { return k === field })) {
        return MentionQLexer[field];
    }

    switch (field) {
        case "PICKEDUP": return MentionQLexer.PICKED_UP;
        case "SOCIALNETWORK": return MentionQLexer.SOCIAL_NETWORK;
        case "CATEGORY": return MentionQLexer.MEDIA;
        case "AUTHORNAME": return MentionQLexer.AUTHOR_NAME;
        case "AUTHORHANDLE": return MentionQLexer.AUTHOR_HANDLE;
        case "FULLAUTHOR": return MentionQLexer.AUTHOR;
        case "EXTRACTWORD": return MentionQLexer.CONTENT;
        case "AUTHORBIOWORD": return MentionQLexer.AUTHOR_BIO;
        case "TICKETID": return MentionQLexer.TICKET_ID;
        case "INTERACTIONID": return MentionQLexer.INTERACTION_ID;
    }

    throw new Error("Unable to find lexer token for " + field);
}

/**
 * Creates statements for a given field where so that it equals
 * the given value. Such as "sentiment = 1";
 * @return {string}
 */
export function createEqualityStatement(field, value, name = null, options = {}) {
    var operand = null,
        originalValue = value;
    field = field.toLowerCase();
    switch (field) {
        case "published":
        case "pickedup":
        case "updated":
            var coarseness = options.coarseness || "daily";
            var additionUnit = "day";
            switch(coarseness) {
                case "hourly":   additionUnit = "hour";  break;
                case "daily":   additionUnit = "day";  break;
                case "weekly":  additionUnit = "week"; break;
                case "monthly": additionUnit = "month"; break;
                case "yearly":  additionUnit = "year"; break;
                default:
                    console.error("Unable to determine date range for coarseness:" + coarseness);
            }

            // Our given time frame may extend beyond our selected data.
            // We want to make sure that we clamp our data.
            var useHours = coarseness === 'hourly';
            var tree = parseFilterString(options.filter);
            var start = earliestDate(tree, useHours);
            var end = latestDate(tree, useHours);

            var from = moment(value,  !useHours ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm");
            var to = from.clone().add(1, additionUnit);

            if (from.isBefore(start)) from = start;
            if (to.isAfter(end)) to = end;

            var format = !useHours ? "YYYY/MM/DD" : "YYYY/MM/DD HH:mm";

            return field + " after '" + from.format(format) + "' and " +
                field + " before '" + to.format(format) + "'";
        case "publishedtime":
            var publishedTimeNext = parseInt(value) + 1;
            var publishedTimeStart = value + ":00";
            var publishedTimeEnd = publishedTimeNext + ":00";

            switch (value) {
                case "MON": publishedTimeStart = value; publishedTimeEnd = "TUE"; break;
                case "TUE": publishedTimeStart = value; publishedTimeEnd = "WED"; break;
                case "WED": publishedTimeStart = value; publishedTimeEnd = "THU"; break;
                case "THU": publishedTimeStart = value; publishedTimeEnd = "FRI"; break;
                case "FRI": publishedTimeStart = value; publishedTimeEnd = "SAT"; break;
                case "SAT": publishedTimeStart = value; publishedTimeEnd = "SUN"; break;
                case "SUN": publishedTimeStart = value; publishedTimeEnd = null; break;
            }

            var after = !options.negate ? "AFTER" : "BEFORE";
            var before = !options.negate ? "BEFORE" : "AFTER";
            if (publishedTimeEnd) {
                return "(PublishedTime " + after + " '" + publishedTimeStart + "' and PublishedTime " + before + " '" + publishedTimeEnd + "')";
            } else {
                return "PublishedTime " + after + " '" + publishedTimeStart + "'";
            }
        case "fullauthor":
            field = "authorId";
            break;
        case "brand":
            operand = "isorchildof";
            break;
        case "sentiment":
            if (value === 0) {
                value = 1;
                operand = "=";
            } else if (value >= 1) {
                value = 1;
                operand = '>';
            } else {
                value = 0;
                operand = '<';
            }
            break;
        case "site":
            field = "link";
            operand = "contains";
            value = "'" + value + "'";
            break;
        case "language":
            field = field.toUpperCase();
            break;
        case "region":
            field = "location";
            break;
        case "city":
            field = "location";
            break;
        case "category":
            field = "media";
            operand = "is";
            break;
        case "extractword":
            field = "content";
            operand = "matches";
            value = "'" + value + "'";
            break;
        case "authorbioword":
            field = "authorbio";
            operand = "matches";
            value = "'" + value + "'";
            break;
        // Various fields that shouldn't be quoted, but are strings or are treated as such.
        case "gender":
        case "race":
        case "relevancy":
        case "socialnetwork":
        case "tag":       // Not a string, but treated as one.
        case "topic":     // Not a string, but treated as one.
        case "segment":   // Not a string, but treated as one.
            operand = "is";
            break;
        case "handles":
        case "hashtags":
            field = "content";
            operand = "matches";
            value = "'" + value + "'";
            break;
        case "parenttopic":
            field = "topic";
            break;
        case "functionalsentiment":
        case "dataset":
            return "";
    }

    if (!operand) {
        if (typeof value === 'number') operand = '=';
        if (typeof value === 'string') {
            operand = 'is';
            value = "'" + value + "'";
        }
        if (value === undefined || value === null) {
            operand = 'is';
            value = "UNKNOWN";
        }
    }

    if (options.negate) {
        switch (operand) {
            case "is": operand = "isnt"; break;
            case "isorchildof": operand = "isntnorchildof"; break;
            case "contains": operand = "doesntcontain"; break;
            case "=": operand = "!="; break;
            case "matches": value = "'-" + originalValue + "'";
        }
    }

    if (!operand) throw new Error("Unable to create statement for " + field + " with value " + value);
    return "" + field + " " + operand + " " + value;
};



/**
 * Provides tools for creating filters.
 */
Beef.module("Filter.Generator").addInitializer(function(startupOptions) {


});