import { render, staticRenderFns } from "./TrustPilotAddProfileDialog.vue?vue&type=template&id=4b973616&scoped=true"
import script from "./TrustPilotAddProfileDialog.vue?vue&type=script&lang=js"
export * from "./TrustPilotAddProfileDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b973616",
  null
  
)

export default component.exports