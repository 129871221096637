import { FilterParser, MentionQAst, MentionQLexer } from '@/mentionq/mentionq';
import moment from "moment";
import {appendBasicFilters, buildBasicFilter, convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import {deprecatedBrandsStore as brandStore} from "@/store/deprecated/Stores";
import VuexStore from "@/store/vuex/VuexStore";
import _ from 'underscore';
import {isString} from "@/app/utils/StringUtils";
import 'datejs'; // This changes the date prototype. Needs to be imported here to better support our tests.

/**
 * Parse the filter and return the parse tree. Returns null if the filter is undefined, null or empty.
 * Throws an error message if the filter fails to parse.
 */
export function parseFilterString(filter) {
    return filter && isString(filter) && filter.trim().length > 0 ? new FilterParser().parse(filter) : null;
}

export function findAllNodes(node, visitor) {
    let results = [];

    if (!node) return results;

    const recurse = function(node) {
        if (visitor(node)) results.push(node);

        if (node.lhs) recurse(node.lhs);
        if (node.rhs) recurse(node.rhs);
    };

    recurse(node);
    return results;
}

/**
 * Given a filter, and a new date fragment, this attempts to remove
 *
 * @param filter The filter that should have its date replaced
 * @param newDateFilter The new date fragment.
 */
export function replaceDate(filter, newDateFilter) {
    const filterAttrs = convertFilterToAttrs(filter);
    const dateAttrs = convertFilterToAttrs(newDateFilter);

    if (filterAttrs.errors || dateAttrs.errors) {
        return `(${filter}) and (${newDateFilter})`;
    }

    filterAttrs.published = dateAttrs.published;

    return buildBasicFilter(filterAttrs);
}

/**
 * Returns the brands included and excluded in a filter.
 * @return {{include: any[], exclude: any[]}}
 */
export function getBrandsInFilter(filter) {
    let node = parseFilterString(filter);

    let brands = findAllNodes(node, function(n) { return n.operandType === MentionQLexer.BRAND; }) || [];
    let include = brands.filter(function(n) { return n.operationType === MentionQLexer.ISORCHILDOF || n.operationType === MentionQLexer.IS });
    let exclude = brands.filter(function(n) { return n.operationType === MentionQLexer.ISNTNORCHILDOF || n.operationType === MentionQLexer.ISNT });

    return {
        include: include.map(function(n) { return n.literal; }),
        exclude: exclude.map(function(n) { return n.literal; })
    }
}

/**
 * Returns the profiles that are included or excluded in a filter.
 * @return {{include: any[], exclude: any[]}}
 */
export function getProfilesInFilter(filter) {
    let node = parseFilterString(filter);
    let include = [];
    let exclude = [];
    let profiles = {include: [], exclude: []};

    // we have to handle these MENTIONED_PROFILE and HAS_REPLY_FROM_PROFILE operands separately since HAS_REPLY_FROM_PROFILE nodes belong to 1 object, where the profile IDs come from
    // the literalImage field (comma seperated array), while the MENTIONED_PROFILE nodes belong to a separate object per profile where the profile ID comes from the "literal" field
    let mentionedProfiles = findAllNodes(node, n =>  n.operandType === MentionQLexer.MENTIONED_PROFILE) || [];
    let hasReplyFromProfiles = findAllNodes(node, n =>  n.operandType === MentionQLexer.HAS_REPLY_FROM_PROFILE) || [];

    // handle MentionedProfile filter
    include = mentionedProfiles.filter(n => n.operationType === MentionQLexer.IS);
    exclude = mentionedProfiles.filter(n => n.operationType === MentionQLexer.ISNT);

    profiles.include = include.map(n => n.literal);
    profiles.exclude = exclude.map(n => n.literal)

    // handle HasReplyFromProfile filter
    include = hasReplyFromProfiles.filter(n => n.operationType === MentionQLexer.IN);
    exclude = hasReplyFromProfiles.filter(n => n.operationType === MentionQLexer.NOTIN);

    include.forEach(profile => {
        let includedProfiles = profile.literalImage.split(",");
        includedProfiles = includedProfiles.map(id => Number(id));
        profiles.include = [...profiles.include, ...includedProfiles];
    });
    exclude.forEach(profile => {
        let excludedProfiles = profile.literalImage.split(",");
        excludedProfiles = excludedProfiles.map(id => Number(id));
        profiles.exclude = [...profiles.exclude, ...excludedProfiles];
    });

    return profiles;
}

/**
 * Returns the tags included and excluded in a filter.
 */
export function getTagsInFilter(filter) {
    let node = parseFilterString(filter);

    let tags = findAllNodes(node, function(n) {
        return n.operandType === MentionQLexer.TAG && n.operandImage.toLowerCase() === "tag";
    }) || [];
    let include = tags.filter(function(n) { return n.operationType === MentionQLexer.IS });
    let exclude = tags.filter(function(n) { return n.operationType === MentionQLexer.ISNT });

    return {
        include: include.map(function(n) { return n.literal; }),
        exclude: exclude.map(function(n) { return n.literal; })
    }
}

export function getTopicsInFilter(filter) {
    let node = parseFilterString(filter);

    let topics = findAllNodes(node, function(n) {
        return n.operandType === MentionQLexer.TAG  && n.operandImage.toLowerCase() === "topic";
    }) || [];
    let include = topics.filter(function(n) { return n.operationType === MentionQLexer.IS });
    let exclude = topics.filter(function(n) { return n.operationType === MentionQLexer.ISNT });

    return {
        include: include.map(function(n) { return n.literal; }),
        exclude: exclude.map(function(n) { return n.literal; })
    }
}

export function getSegmentsInFilter(filter) {
    let node = parseFilterString(filter);

    let segments = findAllNodes(node, function(n) {
        return n.operandType === MentionQLexer.TAG  && n.operandImage.toLowerCase() === "segment";
    }) || [];
    let include = segments.filter(function(n) { return n.operationType === MentionQLexer.IS });
    let exclude = segments.filter(function(n) { return n.operationType === MentionQLexer.ISNT });

    return {
        include: include.map(function(n) { return n.literal; }),
        exclude: exclude.map(function(n) { return n.literal; })
    }
}

/**
 * Appends two filters. Attempts to do this in a way that will return
 * a filter that makes good English (i.e., it is _readable_), but will
 * fall back to concatenating filters if this is not possible.
 *
 * This will always return a filter, where appendBasicFilter may not.
 * @param {FilterString} filter1
 * @param {FilterString} filter2
 * @param {boolean} [throwExceptions = false] With false, no exceptions for basic filters are thrown, and this falls back to concatenating filters.
 * @returns {FilterString}
 */
export function appendFiltersReadably(filter1, filter2, throwExceptions = false) {
    let result;
    try {
        result = appendBasicFilters(filter1, filter2);
    } catch (e) {
        if (throwExceptions) throw e;
        if (VuexStore.state.account?.dev) {
            console.debug(`Dev warning: Unable to use basic filter appending for [${filter1}] and [${filter2}]. Using advanced appending. You can ignore this.`);
        }
        result = null;
    }

    if (result) return result;
    return concatenateFilters(filter1, filter2) || "";
}

/**
 * Combines two filters using AND
 * @param {FilterString | null} filter1
 * @param {FilterString | null} filter2
 * @returns {FilterString | null}
 */
export function concatenateFilters(filter1, filter2) {
    if (!filter1) return filter2 || null;
    if (!filter2) return filter1 || null;

    return "(" + filter1 + ") and (" + filter2 + ")";
}


/**
 * Backbone Validation framework validator for filters. Throws out filters that don't parse.
 */
export function filterValidator(value) {
    try {
        parseFilterString(value);
    } catch (e) {
        return e.toString();
    }
}


/**
 * Returns true if the filter has a published date field using either 24hours or today.
 * Whether it recommends hours does depend on the current coarseness as well.
 *
 * <p>
 * Not passing the second argument (the coarseness) means that coarseness will not be taken in to
 * account, and published times that have hour settings will be ignored when considering
 * whether hourly coarseness is suggested.
 */
export function filterSuggestsHourly(filter, currentCoarseness, ignoreDuration) {
    if (isString(filter)) filter = parseFilterString(filter);
    let isHourBreakdown = false;
    let hoursAdded = false;

    if (!ignoreDuration) {
        let duration = filterDuration(filter);
        if (duration && Math.abs(duration.asDays()) <= 1) {
            isHourBreakdown = true;
        }
    }

    eachNode(filter, function(node) {
        if (node.operandType === MentionQAst.PUBLISHED &&
            (node.literal === MentionQAst.TWENTY_FOUR_HRS || node.literal === MentionQAst.TODAY)) {
            isHourBreakdown = true;
        }
        else if (node.operandType === MentionQAst.PUBLISHED) {
            let time = moment(node.literal, ['YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm'], true)
            hoursAdded = time.isValid();
        }
    });

    return isHourBreakdown || (currentCoarseness === 'hourly' && hoursAdded);
}

/**
 * Returns the earliest date that occurs in the filter. Can take a filter
 * or a string. This returns moment.js moment, or null.
 * Only looks at PUBLISHED nodes.
 *
 * If preserveHour is true, then this will ensure that the hour value is kept.
 * Otherwise, the start of the day will be returned.
 *
 * @param filter
 * @param [preserveHour]
 * @returns {moment.Moment|null}
 */
export function earliestDate(filter, preserveHour) {
    if (isString(filter)) filter = parseFilterString(filter);

    let date = null;
    let d = null;

    let recurse = function(node) {
        if(!node) { return; }

        if (node.operandType === MentionQLexer.PUBLISHED) {

            if (node.operationType === MentionQLexer.AFTER) {
                d = moment(node.literalImage, 'YYYY/MM/DD HH:mm');
                if (!date || d.diff(date) > 0) {
                    date = d;
                    return;
                }
            }

            if (node.operationType === MentionQLexer.INTHELAST || node.operationType === MentionQLexer.ON) {

                switch(node.literal) {
                    case MentionQLexer.HOUR: d = moment().subtract(1, 'hours'); break;
                    case MentionQLexer.TWENTY_FOUR_HRS: d = moment().subtract(24, 'hours'); break;
                    case MentionQLexer.TODAY: d = moment().startOf('day'); break;
                    case MentionQLexer.DAY: d = moment().subtract(1, 'days').startOf('day'); break;
                    case MentionQLexer.WEEK: d = moment().subtract(1, 'weeks').startOf('day'); break;
                    case MentionQLexer.FORTNIGHT: d = moment().subtract(2, 'weeks').startOf('day'); break;
                    case MentionQLexer.MONTH: d = moment().subtract(1, 'months').startOf('day'); break;
                    case MentionQLexer.QUARTER: d = moment().subtract(3, 'months').startOf('day'); break;
                    case MentionQLexer.YEAR: d = moment().subtract(1, 'years').startOf('day'); break;
                    default:
                        throw "Unrecognised date range [" + node.literalImage + "]";
                }

                if (!date || d.diff(date) > 0) {
                    date = d;
                    return
                }
            }
        }

        if (node.lhs) recurse(node.lhs);
        if (node.rhs) recurse(node.rhs);
    };

    recurse(filter);
    if (date) return preserveHour ? date.startOf('hour') : date.startOf('day');
    return null;
}


/**
 * Returns the latest date that occurs in the filter. Can take a filter
 * or a string. This returns moment.js moment. This will never be null.
 * Only looks at PUBLISHED nodes.
 *
 * If preserveHour is true, then this will ensure that the hour:minute value is kept.
 * Otherwise, the start of the day will be returned.
 */
export function latestDate(filter, preserveTime) {
    if (isString(filter)) filter = parseFilterString(filter);

    let date = moment();

    if (!filterSuggestsHourly(filter, null, true)) {
        date = date.add(1, 'days').startOf('day');
    }

    let d = null;

    let recurse = function(node) {
        if(!node) { return; }

        if (node.operandType === MentionQLexer.PUBLISHED) {

            if (node.operationType === MentionQLexer.BEFORE) {
                d = moment(node.literalImage, 'YYYY/MM/DD HH:mm');
                if (!date || d.diff(date) < 0) {
                    date = d;
                    return;
                }
            }

            if (node.operationType === MentionQLexer.BEFORETHELAST) {

                switch(node.literal) {
                    case MentionQLexer.HOUR: d = moment().subtract(1, 'hours'); break;
                    case MentionQLexer.TWENTY_FOUR_HRS: d = moment().subtract(24, 'hours'); break;
                    case MentionQLexer.TODAY: d = moment().startOf('day'); break;
                    case MentionQLexer.DAY: d = moment().subtract(1, 'days').startOf('day'); break;
                    case MentionQLexer.WEEK: d = moment().subtract(1, 'weeks'); break;
                    case MentionQLexer.FORTNIGHT: d = moment().subtract(2, 'weeks'); break;
                    case MentionQLexer.MONTH: d = moment().subtract(1, 'months'); break;
                    case MentionQLexer.QUARTER: d = moment().subtract(3, 'months'); break;
                    case MentionQLexer.YEAR: d = moment().subtract(1, 'years'); break;
                    default:
                        throw "Unrecognised date range [" + node.literalImage + "]";
                }

                if (!date || d.diff(date) < 0) {
                    date = d;
                    return
                }
            }
        }

        if (node.lhs) recurse(node.lhs);
        if (node.rhs) recurse(node.rhs);
    };

    recurse(filter);
    if (date) return preserveTime ? date : date.startOf('day');
}

/**
 * This returns a moment.duration() object specifying the duration of a filter.
 */
export function filterDuration(filter, preserveTime) {
    let earliest = earliestDate(filter, preserveTime);
    let latest = latestDate(filter, preserveTime);

    return moment.duration(latest.diff(earliest));
}


/**
 * Returns a promise to calculate whether this filter contains any errors or not.
 */
export function findFilterErrors(accountCode, filter) {
    let errorPromise = new $.Deferred();
    let count = 1;
    let brandsPresent = false;
    let errors = [];

    if (findNode(filter, function(node) { return node.operandType === MentionQLexer.BRAND } )) {
        count++;
        brandsPresent = true;
    }

    let complete = _.after(count, function() {
        errorPromise.resolve(errors);
    }.bind(this));

    if (brandsPresent) {
        brandStore
            .refresh(true)
            .then(() => {
                eachNode(filter, function(node) {
                    if (node.operandType === MentionQLexer.BRAND) {
                        if (!brandStore.existsSynchronous(node.literal)) {
                            errors.push({
                                type: "MISSING_BRAND",
                                id: node.literal
                            });
                        }
                    }
                });
                complete();
        });
    }

    complete();
    return errorPromise.promise();
}


/**
 * Make the tree as flat as possible by creating children lists on each AndNode and OrNode and getting rid of
 * the lhs and rhs nodes. Nodes that already have children are left alone as flatten may have already been done
 * and children lists modified.
 */
export function flattenFilterNodes(node) {
    if (node.children !== undefined) return node;
    if (node.type === 'AndNode' || node.type === 'OrNode') {
        let children = [];
        let c = flattenFilterNodes(node.lhs);
        if (c.type === node.type) children.push.apply(children, c.children);
        else children.push(c);
        c = flattenFilterNodes(node.rhs);
        if (c.type === node.type) children.push.apply(children, c.children);
        else children.push(c);
        node.children = children;
    }
    return node;
}


/**
 * Removes any node that passes the given test.
 */
export function removeNodes(node, test) {

    if (!node) return null;
    if (test(node)) return null;     // Ensures that there is always a parent node.

    // Add parent information to nodes
    function addParent(node) {
        if (node.lhs) {
            node.lhs.parent = node;
            addParent(node.lhs);
        }

        if (node.rhs) {
            node.rhs.parent = node;
            addParent(node.rhs);
        }
    }

    addParent(node);

    // Deletes nodes if they pass the test.
    function testAndDelete(node) {
        if (test(node)) {
            if (node.parent.lhs === node) node.parent.lhs = null;
            if (node.parent.rhs === node) node.parent.rhs = null;
        }
        else {
            if (node.lhs) {
                testAndDelete(node.lhs);
            }
            if (node.rhs) {
                testAndDelete(node.rhs);
            }
        }
    }

    // This will leave blank spots that have to be removed
    testAndDelete(node);

    // This reorders the tree to remove the empty spaces caused by the deletion.
    // It does this depth first.
    function compactTree(node) {
        if (node.lhs) {
            node.lhs = compactTree(node.lhs);
        }

        if (node.rhs) {
            node.rhs = compactTree(node.rhs);
        }

        if (node.lhs === null && node.rhs === null) {
            return null;
        }

        if (node.lhs === null) {
            return node.rhs;
        }

        if (node.rhs === null) {
            return node.lhs;
        }

        return node;
    }

    return compactTree(node);
}

/**
 * Removes any brands from this filter that are missing from the given account. This is done
 * asynchronously, and it returns a promise that you can examine for when this is done. The promise
 * is passed the new filter object.
 */
export async function removeMissingBrands(filter) {
    if (isString(filter)) filter = parseFilterString(filter);


    await brandStore.refresh(true);

    filter = removeNodes(filter, function(node) {
        return node.operandType === MentionQLexer.BRAND && !brandStore.existsSynchronous(node.literal);
    });

    return filter;
}

/**
 * Returns a list of codes for brands in this filter that are not in the account. Uses promises.
 */
export async function findMissingBrands(filter) {
    await VuexStore.dispatch("refreshBrands");
    const exists = id => VuexStore.getters.idToBrand.has(id);
    const brandDto = getBrandsInFilter(filter);
    return [...brandDto.include, ...brandDto.exclude].filter(id => !exists(id));
}

/**
 * Does an in order traversal of the tree, passing each node to the visitor function.
 */
function eachNode(node, visitor) {
    if (!node) return;

    visitor(node);

    if (node.lhs) eachNode(node.lhs, visitor);
    if (node.rhs) eachNode(node.rhs, visitor);
}

function findNode(node, visitor) {
    if (visitor(node)) return node;

    if (node.lhs) {
        let result = findNode(node.lhs, visitor);
        if (result) return result;
    }
    if (node.rhs) return findNode(node.rhs, visitor);

    return null;
}

