import {LANGUAGES} from "@/app/utils/Language";

export default function(code, options) {
    if (code) {
        code = code.toLowerCase();
        var name = LANGUAGES[code];
        if (!name) name = code;
        return new Handlebars.SafeString(name);
    }
    return new Handlebars.SafeString("Unknown");
}