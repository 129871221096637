var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row-fluid\">\n        <label>Include conversation with "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lowerCaseAttribute") || (depth0 != null ? lookupProperty(depth0,"lowerCaseAttribute") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lowerCaseAttribute","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":63}}}) : helper)))
    + "</label>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noIntroduction") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"row-fluid\">\n    <div class=\"option-front\"><label><input type=\"radio\" name=\"operation\" value=\"greater-than\" class=\"radio-greater-than\"> greater than</label></div><input type=\"text\" class=\"integer-value greater-than-value\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"defaultGreaterThan") || (depth0 != null ? lookupProperty(depth0,"defaultGreaterThan") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"defaultGreaterThan","hash":{},"data":data,"loc":{"start":{"line":8,"column":216},"end":{"line":8,"column":238}}}) : helper)))
    + "\">\n</div>\n\n<div class=\"row-fluid\">\n    <div class=\"option-front\"><label><input type=\"radio\" name=\"operation\" value=\"less-than\" class=\"radio-less-than\"> less than</label></div><input type=\"text\" class=\"integer-value less-than-value\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"defaultLessThan") || (depth0 != null ? lookupProperty(depth0,"defaultLessThan") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"defaultLessThan","hash":{},"data":data,"loc":{"start":{"line":12,"column":204},"end":{"line":12,"column":223}}}) : helper)))
    + "\">\n</div>\n\n<div class=\"row-fluid\">\n    <div class=\"option-front\"><label><input type=\"radio\" name=\"operation\" value=\"between\" class=\"radio-between\"> between </label></div><input type=\"text\" class=\"integer-value between-begin-value\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"defaultMin") || (depth0 != null ? lookupProperty(depth0,"defaultMin") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"defaultMin","hash":{},"data":data,"loc":{"start":{"line":16,"column":203},"end":{"line":16,"column":217}}}) : helper)))
    + "\"> and <input type=\"text\" class=\"integer-value between-end-value\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"defaultMax") || (depth0 != null ? lookupProperty(depth0,"defaultMax") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"defaultMax","hash":{},"data":data,"loc":{"start":{"line":16,"column":290},"end":{"line":16,"column":304}}}) : helper)))
    + "\">\n</div>\n\n<div class=\"control-group error\">\n    <span class=\"error-message help-block\"></span>\n</div>\n\n";
},"useData":true});