import { feature } from 'topojson-client'
import { beef } from '@/store/Services'
import {toGrouseLink} from "@/data/Grouse";
import {appendBasicFilters, isVerifiedOnly} from "@/dashboards/filter/BasicFilter";
import {getPalette} from "@/app/utils/Colours";
import {formatNumber, toSi} from "@/app/utils/Format";
import _ from 'underscore';
import VuexStore from "@/store/vuex/VuexStore";
import {errorHelper} from "@/dashboards/DashboardUtils";
import {account} from "@/app/utils/Account";

Beef.module("Widget.WorldMap").addInitializer(function (startupOptions) {

    this.type = {
        name: "World Map",
        description: "View where your conversation is coming from on a map",
        group: "benchmarking",
        width: 6,
        height: 4,
        mashAdminOnly: true,
        cycleColoursDisabled: true
    };

    this.View = Beef.BoundItemView.extend({
        attributes: { class: "world-map widget-height-inner" },
        template: require("@/dashboards/widgets/maps/WorldMap.handlebars"),

        events: {
            "click .hud.top.left .show-world-view": "showWorldView"
        },

        modelEvents: {
            "change": "maybeRefresh"
        },

        firstRender: true, // end of render sets this to false
        blockScrolling: true,

        initialize: function() {
            var defaults = {};
            if (!this.model.has('report-on')) { defaults['report-on'] = Beef.MapUtils.reportOnMap.volumeSentiment; }
            if (!this.model.has('show-neutral')) { defaults['show-neutral'] = true; }
            if (!this.model.has('min-bubble-size')) { defaults['min-bubble-size'] = 20; }
            if (!this.model.has('max-bubble-size')) { defaults['max-bubble-size'] = 500; }
            if (!this.model.get('country')) { defaults['country'] = account().country || "'world'"; }
            this.model.set(defaults);
        },

        /**
         * Take the user to the mentions panel and show them the mentions which match the current filter.
         */
        viewMentions: function() {
            var params = this.getParams();

            Beef.MentionList.navigateToMentions(this.model.getAncestorProperty('accountCode'),
                params.filter, null, true);
        },

        getCsvLink: function() {
            var code = this.model.getAncestorProperty('accountCode');
            var params = this.getParams(true);
            params.filename = code + '-world-map.csv';
            return toGrouseLink("/v4/accounts/" + code + "/mentions/count.csv", params);
        },

        /**
         * If the user clicks on refresh in the widget menu re-render.
         */
        refresh: function () {
            this.firstRender = true;
            this.onRender();
        },

        /**
         * Whenever the model changes, we check what has changed and then decide what needs to happen.
         */
        maybeRefresh: function () {
            var that = this;
            if (this.model.hasChanged('country')) {
                // if the country has changed then we need to fetch data and re-render from scratch
                // if there is no countries selected then we need to render the world map
                this.model.generalData.set('_country', undefined);
                this.model.generalData.set('_countryCodes', undefined);
                this.firstRender = true;
                this.onRender();
            } else {
                // if the filter changes or a user clicks on a drill down
                if (this.model.hasChanged('_effectiveFilter') || this.model.hasChanged('report-on') ||
                    this.model.hasChanged('colour-palette') || this.model.hasChanged('colour-palette-custom') ||
                    this.model.hasChanged('colour-index') || this.model.hasChanged('group-by-city') ||
                    this.model.hasChanged('show-neutral') || this.model.hasChanged('min-bubble-size') ||
                    this.model.hasChanged('max-bubble-size')) {
                    // this delays the loading spinner so that we can see the sweet transitions
                    this.model.generalData.set("_delay", 3000);
                    this.onRender();
                }

                // If the width or height changes we need to wait for the widget container to resize before we re-render ourselves.
                if (this.model.hasChanged('width') || this.model.hasChanged('height')) {
                    that.$("svg").attr({width: 0, height: 0});
                    setTimeout(function () {
                        that.$el.fadeIn();
                        that.firstRender = true;
                        that.onRender();
                    }, 400);
                }
            }
        },

        /**
         * This is called if the user clicks the world link which will take them back to the world view or
         * a location if they have one selected.
         */
        showWorldView: function (e) {
            e.preventDefault();

            var datalayer = d3.select($(this.el)[0]).select('.data-layer');
            datalayer.selectAll('.pie').remove();

            this.firstRender = true;
            this.model.generalData.set('_country', undefined);
            this.model.generalData.set('_countryCodes', undefined);
            this.onRender();
        },

        /**
         * Managers the breadcrumb on the top left of the display.
         */
        breadcrumb: function () {
            var selectedCountry = this.model.generalData.get('_country');
            var $hudTopLeftDiv = $(this.$el[0]).find('.hud.top.left');

            var text;
            var country = Beef.LocationPicker.getCountry(this.getCountryCodes());
            var group = Beef.LocationPicker.getGroup(this.getCountryCodes());

            if (selectedCountry) {
                // if the user has selected a country we display the 'take me back link' and the name of the country.
                $hudTopLeftDiv.find('.breadcrumb-label').css('display', 'none');
                $hudTopLeftDiv.find('.world-map-breadcrumb').css('display', '');

                $hudTopLeftDiv.find('.country').addClass('active');
                $hudTopLeftDiv.find('.city').removeClass('active');
                $hudTopLeftDiv.css('visibility', 'visible');
                this.setOpacity('.hud.top.left', 1);
                $hudTopLeftDiv.find('.country').text(selectedCountry.getCountry());
            } else if (this.getCountryCodes() && this.getCountryCodes().split(' ').length === 1) {
                // if the user has set the location via the settings dialog to one country or region we display the name.
                $hudTopLeftDiv.find('.breadcrumb-label').css('display', '');
                $hudTopLeftDiv.find('.world-map-breadcrumb').css('display', 'none');
                this.setOpacity('.hud.top.left', 1);
                $hudTopLeftDiv.css('visibility', 'visible');

                if (country) {
                    text = country.countryDisplay;
                }
                if (group) {
                    text = group.groupDisplay;
                }

                $hudTopLeftDiv.find('.breadcrumb-label').text(text);
            } else {
                // if we are in the world view with nothing selected we hide the breadcrumb.
                $hudTopLeftDiv.css('visibility', 'hidden');
            }
        },

        /**
         * Helper method which animates the setting of the opacity.
         */
        setOpacity: function (selector, opacity) {
            var container = d3.select(this.$el[0]);
            let sel = container.select(selector);
            if (Beef.Widget.isDisableAnimation()) sel.style("opacity", opacity)
            else sel.transition().duration(500).style("opacity", opacity);
        },

        /**
         * Shows or hides the info bubble on the bottom left of the display.
         */
        setTotals: function (data) {
            var $unknownContainer = $(this.$el[0]).find('.hud.bottom.right .unknownContainer');
            var $unknown = $(this.$el[0]).find('.hud.bottom.right .unknown');
            var $total = $(this.$el[0]).find('.hud.bottom.right .total');

            $unknownContainer.css('display', '');
            $total.css('display', '');
            if (data === undefined) {
                this.setOpacity('.hud.bottom.right', 0);
            } else {
                this.setOpacity('.hud.bottom.right', 1);
                $unknown.text(toSi(data.unknownCount));
                $total.text(toSi(data.totalCount));
            }
        },

        /**
         * Shows or hides the legend on the bottom left of the display. This is only displayed if we are viewing
         * country level data.
         */
        setLegend: function (data, label) {
            var $legendContainer = $(this.$el[0]).find('.hud.bottom.left');
            var $legendLabel = $(this.$el[0]).find('.hud.bottom.left .legend-label');
            var $legendDiv = $(this.$el[0]).find('.hud.bottom.left .legend');
            if (data === undefined) {
                this.setOpacity('.hud.bottom.left', 0);
                $legendContainer.css('display', 'none');
            } else {
                $legendLabel.css('display', 'none');
                $legendContainer.css('display', '');
                $legendDiv.css('display', '');
                $legendDiv.find('.legend-0').css('background-color', data.legend0);
                $legendDiv.find('.legend-1').css('background-color', data.legend1);
                $legendDiv.find('.legend-2').css('background-color', data.legend2);
                $legendDiv.find('.legend-3').css('background-color', data.legend3);
                $legendDiv.find('.legend-4').css('background-color', data.legend4);
                $legendDiv.find('.legend-5').css('background-color', data.legend5);
                $legendDiv.find('.max-count').text(toSi(data.maxCount)  + ' ' + label );
                this.setOpacity('.hud.bottom.left', 1);
            }
        },

        /**
         * Shows or hides the label in the bottom left of the display this is only visible if the user is viewing
         * city level data.
         */
        setLegendLabel: function (data) {
            var $legendContainer = $(this.$el[0]).find('.hud.bottom.left');
            var $legendLabel = $(this.$el[0]).find('.hud.bottom.left .legend-label');
            var $legendDiv = $(this.$el[0]).find('.hud.bottom.left .legend');
            if (data === undefined) {
                this.setOpacity('.hud.bottom.left', 0);
                $legendContainer.css('display', 'none');
            } else {
                $legendContainer.css('display', '');
                $legendLabel.css('display', '');
                $legendDiv.css('display', 'none');
                $legendLabel.text(data);
                this.setOpacity('.hud.bottom.left', 1);
            }
        },

        /**
         * Method returns a space separated list of country codes.
         */
        getCountryCodes: function () {
            var country = this.model.generalData.get('_country');
            var countryCodes;
            if (country) {
                countryCodes = country.getCode();
            } else {
                countryCodes = this.model.generalData.get('_countryCodes');
            }
            return countryCodes;
        },

        /**
         * This is called when the user clicks on a country which then zooms in on the selected country.
         */
        showCountry: function (country) {
            // if we are looking at a single country then we have nowhere to go
            if (this.isCountry()) {
                return;
            }

            country.getCountry = function () {
                var c = Beef.LocationPicker.getCountry(country.getCode());
                if (c) {
                    return c.countryDisplay;
                } else {
                    return country.getName();
                }
            };

            this.model.generalData.set('_country', country);
            this.setLegend(undefined); // hide the legend
            this.firstRender = true;
            this.onRender();
        },

        /**
         * This is the entry point.
         */
        onRender: function() {
            if (!this.model.get('_effectiveFilter')) {
                this.model.generalData.set('_loading', true);
                return;
            }

            // check if the user has selected a location via the settings dialog.
            // If they've selected the world, then we leave the country codes blank,
            // even if they've selected other countries: they want to see EVERYTHING.
            var countryCodes = this.model.get('country');
            if (countryCodes && countryCodes.includes("'world'")) countryCodes = null;
            if (countryCodes && countryCodes.length > 0) {
                this.model.generalData.set('_countryCodes', countryCodes.replace(/'/g, '')); // remove the single quotes from the codes
            }
            this.loadData();
        },

        render: function() {
            var first = this.firstRender;
            Beef.BoundItemView.prototype.render.call(this);
            if (!first) {
                this.firstRender = true;
                this.onRender();
            }
        },

        /**
         * Get the TopoJson and mention data from grouse. The dataLoaded method only gets called when both calls have been
         * completed. We use JQuery promises to get this right.
         */
        loadData: function () {
            this.model.generalData.set('_loading', true);
            var accountCode = this.model.getAncestorProperty('accountCode');
            var countryCodes = this.getCountryCodes();
            var reportOn = this.model.get('report-on');

            var topoJsonUrl = countryCodes ? '/api/topojson/' + countryCodes.replace(/\s+/g, ',') : '/api/topojson/world';

            var params = this.getParams();
            Promise
                .all([
                    Beef.MapUtils.getMentionData(accountCode, params, reportOn, this.model.getSectionModel().view),
                    beef.get(topoJsonUrl)
                ])
                .then(function (data) {
                    this.dataLoaded(data[0], data[1].data);
                }.bind(this))
                .catch(error => errorHelper(this.model, error))
        },

        /**
         * This method sorts our what are the params needed for grouse.
         */
        getParams: function(forCsvLink) {
            var filter = this.model.get('_effectiveFilter');
            var countryCodes = this.getCountryCodes();
            var reportOn = this.model.get('report-on');

            var params = { };

            if (reportOn.sentiment) {
                params.select = reportOn.sentiment;
                if (!isVerifiedOnly(filter)) {
                    filter = appendBasicFilters(filter, "process is verified");
                }
            } else {
                params.select = reportOn.select;
            }

            if (params.select === undefined) params.select = Beef.MapUtils.reportOnMap.volumeSentiment.sentiment;

            if (!countryCodes) {
                params.filter = filter;
                params.groupBy = 'country';
            } else {
                params.filter = Beef.MapUtils.generateCountryFilter(countryCodes, filter);
            }

            // we want to group by city if the show city bubbles option has been selected in the settings or if the
            // user has clicked on a country or if the user has selected one location which is a country in the settings
            // dialog
            if (this.model.get('group-by-city') || this.isCountry()) {
                params.groupBy = 'city';
                if (forCsvLink) params.select += ",city[name,region[name],country[name]]";
                else params.select += ",city.*"
            } else {
                params.groupBy = 'country';
            }

            return params;
        },

        /**
         * Returns true if there is one country code and it is a country.
         */
        isCountry: function () {
            var countryCodes = this.getCountryCodes();
            if (countryCodes) {
                var nCC = countryCodes.split(' ');
                return nCC.length === 1 && Beef.LocationPicker.getCountry(nCC[0]);
            } else {
                return false;
            }
        },

        /**
         * We stuff our data into a model and call render.
         */
        dataLoaded: function (mentionData, topoJsonData) {
            this.model.generalData.set('_mentionData', mentionData);
            this.model.generalData.set('_topoJsonData', topoJsonData);

            this.renderImpl();
        },

        renderImpl: function () {
            var topoJsonData = this.model.generalData.get('_topoJsonData');
            var country = this.model.generalData.get('_country');
            var that = this;
            var minColourHex = '#f0f0f0';
            var dragInProgress = false;
            var groupByCity = this.model.get('group-by-city');
            var isCountry = this.isCountry();
            var countryFeatures = undefined;
            var width = this.$el.width(), height = this.$el.height();
            var colours = getPalette(this.model.attributes, {'colour-palette': 'blues9', 'colour-index': 0});
            var reportOn = this.model.get('report-on');
            let noAnimation = Beef.Widget.isDisableAnimation()

            // this is here because we changed displayText to label
            if (!reportOn.label) {
                reportOn.label = reportOn.displayText;
            }

            var colour = d3.scaleLinear()
                .domain([0, 100])
                .range([minColourHex, colours[0]]);

            var projection = d3.geoMercator();

            var path = d3.geoPath()
                .projection(projection);

            _.each(topoJsonData, function (data) {
                if (!countryFeatures) {
                    countryFeatures = feature(data, data.objects.countries);
                } else {
                    countryFeatures.features = countryFeatures.features.concat(feature(data, data.objects.countries).features);
                }
            });

            // check if we need to rotate the projection depending on the country we are looking at.
            var rotate = [0, 0, 0];
            if (this.getCountryCodes()) {
                if (this.getCountryCodes().indexOf('RU') !== -1 ||
                    this.getCountryCodes().indexOf('oceania') !== -1 ||
                    this.getCountryCodes().indexOf('europe') !== -1) {
                    rotate = [-100, 0, 0];
                } else if (this.getCountryCodes().indexOf('US') !== -1 ||
                    this.getCountryCodes().indexOf('north-america') !== -1 ||
                    this.getCountryCodes().indexOf('northern-america') !== -1) {
                    rotate = [100, 0, 0];
                } else if (this.getCountryCodes().indexOf('AU') !== -1 ||
                    this.getCountryCodes().indexOf('australia-and-new-zealand-un') !== -1) {
                    rotate = [-800, 0, 0];
                } else if (country) {
                    rotate = [country.yaw, country.pitch, country.roll];
                }
            }

            // calculate the bounds to set the projection so that everything fits on the screen
            projection.scale(1).rotate(rotate).translate([0, 0]);
            var b = path.bounds(countryFeatures),
                s = .95 / Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height),
                t = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2];
            projection.scale(s).translate(t);

            var mentionData = Beef.MapUtils.processData(this.model.generalData.get('_mentionData'),
                this.getParams(),
                projection,
                reportOn,
                this.model.get('show-neutral'),
                this.model.get('min-bubble-size'),
                this.model.get('max-bubble-size'));

            var countMap = mentionData.data;

            var logScale = d3.scaleLog()
                .domain([0.01, mentionData.maxCount])
                .range([0, 100]);

            var mapView = d3.select($(this.$el[0]).find('.map-view')[0]);

            var svg = mapView.select('svg');

            var mapHolder = svg.select('.map-holder');
            var background = mapHolder.select('.background');
            var mapLayer = mapHolder.select('.map-layer');
            var dataLayer = mapHolder.select('.data-layer');

            function setFill(d) {
                if (!groupByCity) {
                    var count = Beef.MapUtils.getCountData(d, countMap).getValue();
                    if (count === 0) {
                        return minColourHex;
                    } else {
                        return colour(logScale(count));
                    }
                } else {
                    return minColourHex;
                }
            }

            /**
             * Display the info bubble in the top right hand corner of the display when the user hovers over something of interest.
             */
            var showHover = function (data) {
                var $hoverDiv = $(that.$el[0]).find('.hud.top.right');
                var $hoverValue = $hoverDiv.find('.hover-value');

                if (data) {
                    $hoverValue.html(data);
                    that.setOpacity('.hud.top.right', 1);
                } else {
                    that.setOpacity('.hud.top.right', 0);
                }
            };

            this.breadcrumb();
            this.setTotals(mentionData);

            if (groupByCity || isCountry) {
                this.setLegendLabel(reportOn.label);
            } else {
                this.setLegend({legend0: colour(1), legend1: colour(20), legend2: colour(40), legend3: colour(60),
                    legend4: colour(80), legend5: colour(100), maxCount: formatNumber(mentionData.maxCount)},
                reportOn.label);
            }

            var zoom = d3.zoom();

            if (this.firstRender) {
                svg.attr("width", width)
                    .attr("height", height);

                // add the polygons to the svg
                mapLayer.selectAll('path').remove();
                var land = mapLayer.selectAll('path')
                    .data(countryFeatures.features);

                land.enter()
                    .append("path")
                    .attr("d", path)
                    .style("fill", minColourHex)
                    .style("cursor", "pointer");

                var drag = d3.drag();
                // keep track if we are 'really' dragging.
                drag.on("drag", function () {
                    if (d3.event.dx < -1 || d3.event.dx > 1
                        || (d3.event.dy < -1 || d3.event.dy > 1)) {
                        dragInProgress = true;
                    }
                });
                mapHolder.call(drag);

                var throttledUpdateStroke =  _.throttle(Beef.MapUtils.updateStrokeWidth, 250, { leading:false });

                // reset the zoom
                mapHolder.attr("transform", "translate(0,0)scale(1)");

                // enable zoom on the map
                zoom.scaleExtent([1, 20])
                    .on("zoom", function () {
                        throttledUpdateStroke(mapLayer, d3.event.transform.k);
                        mapHolder.attr("transform", d3.event.transform);
                    });
                svg.call(zoom);
            }

            /**
             * Decide what to show when a user hovers over something of interest.
             */
            var setHoverValue = function (data) {
                if (data) {
                    var html = data.getName();
                    if (data.pieData) {

                        var append = function (label, value) {
                            return '<br> ' + label + ': ' + Beef.MapUtils.formatNumber(value || 0);
                        };

                        data.pieData.forEach(function (d) {
                            html += append(d.label, d.value);
                        });
                    }
                    showHover(html);
                } else {
                    showHover(undefined)
                }
            };

            // set the fill for each country
            mapLayer.selectAll('path')
                .on('click', function (d) {
                    // When we drag we don't want to trigger a click
                    if (dragInProgress) {
                        dragInProgress = false;
                    } else {
                        setHoverValue(undefined);
                        that.showCountry(Beef.MapUtils.getCountData(d, countMap));
                    }
                })
                .on('mouseover', function (d) {
                    var data = Beef.MapUtils.getCountData(d, countMap);
                    if (groupByCity || isCountry) {
                        showHover(data.getName());
                    } else {
                        setHoverValue(data);
                    }

                })
                .on('mouseout', function (d) {
                    showHover(undefined);
                })
                .transition()
                .duration(500)
                .style("fill", function (d) {
                    return setFill(d);
                });

            if (groupByCity || isCountry) {
                var pies = dataLayer.selectAll('*');
                if (pies.empty() || noAnimation) {
                    Beef.MapUtils.syncPieLayer(mentionData.data, dataLayer, setHoverValue, projection, colours,
                        reportOn, noAnimation);
                } else {
                    pies.transition().duration(1000)
                        .attr("opacity", 0)
                        .on("end", function() {
                            Beef.MapUtils.syncPieLayer(mentionData.data, dataLayer, setHoverValue, projection, colours,
                                reportOn, noAnimation)
                        })
                        .remove();
                }
            } else {
                dataLayer.selectAll('*')
                    .transition().duration(1000)
                    .attr("opacity", 0)
                    .remove();
            }

            this.endOfRender();
        },

        endOfRender: function () {
            this.firstRender = false;
            this.model.generalData.set('_loading', false);
            this.model.generalData.set('_completed', true);
            this.model.generalData.set('_message', null);

            if (!this.$el.is(':visible')) {
                this.$el.fadeIn();
            }
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/maps/WorldMapSettings.handlebars"),

        regions: {
            colourSettingsRegion: '.colour-settings-region'
        },

        events: {
            'click .reporton': 'displayReportOnMenu',
            'change .group-by-city': 'groupByCityChanged',
            'change .show-neutral': 'showNeutralChanged',
            'change .min-bubble-size': 'minBubbleSizeChanged',
            'change .max-bubble-size': 'maxBubbleSizeChanged'
        },

        displayReportOnMenu: function (ev) {
            var that = this;
            this.popup = Beef.MiniMenu.show({
                template: require("@/dashboards/widgets/maps/ReportOnMenu.handlebars"),
                object: {
                    reportOn: function($t) {
                        that.reportOnChanged($t.attr('data-report-on'));
                    }
                },
                onRender: function() {
                    this.$("[data-report-on='ave']").toggleClass('disabled', ! VuexStore.state.account.showAVE);
                },
                target: $(ev.target).closest('a')
            });
        },

        reportOnChanged: function (reportOn) {
            this.model.set('report-on', Beef.MapUtils.reportOnMap[reportOn]);
            this.updateView();
        },

        editAttributes: function() { return ['country', 'location', 'report-on', 'colour-palette',
            'colour-palette-custom', 'colour-index', 'group-by-city', 'show-neutral',
            'min-bubble-size', 'max-bubble-size'] },

        bindings: function() {
            return {
                country: { converter: Beef.LocationPicker.converter, elAttribute: "data-value" }
            }
        },

        onFirstRender: function() {
            Beef.LocationPicker.attach(this, ".location", "country", {onlyCountriesAndGroups: true, topoJosnOnly: true, withWorld: true});

            this.updateView();

            this.colourSettingsRegion.show(new Beef.ColourSettings.View({model: this.model,
                dashboardModel: true,
                useDefaultText: 'Use defaults',
                useDefaultTitle: 'Reset to the default colours'}));
        },

        updateView: function () {
            var reportOn = this.model.get('report-on');
            var ans = 'Count';
            if (reportOn) {
                ans = Beef.MapUtils.reportOnMap[reportOn.key].label;
            }
            this.$('.reporton .reporton-label').text(ans);
            this.$('.group-by-city').attr('checked', this.model.attributes['group-by-city']);
            this.$('.show-neutral').attr('checked', this.model.attributes['show-neutral']);

            this.$('.min-bubble-size').val(this.model.attributes['min-bubble-size']);
            this.$('.max-bubble-size').val(this.model.attributes['max-bubble-size']);
        },

        groupByCityChanged: function() {
            this.model.set('group-by-city', this.$('.group-by-city').is(':checked'));
        },

        showNeutralChanged: function() {
            this.model.set('show-neutral', this.$('.show-neutral').is(':checked'));
        },

        minBubbleSizeChanged: function() {
            this.model.set('min-bubble-size', this.$('.min-bubble-size').val());
        },

        maxBubbleSizeChanged: function() {
            this.model.set('max-bubble-size', this.$('.max-bubble-size').val());
        }
    });
});