<template>
    <section class="pcs-charts"
             :class="{'pcs-charts--no-states': noStates}"
             @mouseenter="mouseenter"
             @mouseleave="mouseleave">

        <dark-card v-if="!loading">
            <section class="pcs-charts__chart animated fadeIn delay-200">
                <section ref="chart" class="pcs-charts__chart-item"></section>
            </section>

            <section class="pcs-charts__info">
                <section v-if="currentState === 'risk'"
                         key="risk"
                         class="pcs-charts__text">
                    <div class="animated fadeIn delay-1000">
                        <h4>Risk volumes</h4>
                        <p>
                            <deq-number :number="riskTotal"/> {{formatPlural(riskTotal, 'mention')}}
                        </p>
                        <p>
                            <be-button link
                                       class="pcs-charts__more-button"
                                       :tooltip="`Click to go the Risk panel and find out more about ${brandName}'s Risk factors` "
                                       @click="gotoExploreRisk()">
                                More »
                            </be-button>
                        </p>
                    </div>
                    <most-frequent-topic class="pcs-charts__text-frequent animated fadeIn delay-1500" :filter="riskFilter"/>
                </section>
                <section v-if="currentState === 'purchase'"
                         key="purchase"
                         class="pcs-charts__text">
                    <div class="animated fadeIn delay-1000">
                        <h4>Purchase volumes</h4>
                        <p>
                            <deq-number :number="purchaseTotal"/> {{formatPlural(purchaseTotal, 'mention')}}
                        </p>
                        <be-button link
                                   class="pcs-charts__more-button"
                                   :tooltip="`Click to go the CX panel and find out more about ${brandName}'s Customer Experience` "
                                   @click="gotoExploreCx()">
                            More »
                        </be-button>
                    </div>
                    <most-frequent-topic class="pcs-charts__text-frequent animated fadeIn delay-1500" :filter="purchaseFilter"/>
                </section>
                <section v-else-if="currentState === 'cancel'"
                         key="cancel"
                         class="pcs-charts__text">
                    <div class="animated fadeIn delay-1000">
                        <h4>Cancel volumes</h4>
                        <p>
                            <deq-number :number="cancelTotal"/>
                            {{ formatPlural(cancelTotal, 'mention') }}
                        </p>
                        <be-button link
                                   class="pcs-charts__more-button"
                                   :tooltip="`Click to go the CX panel and find out more about ${brandName}'s Customer Experience` "
                                   @click="gotoExploreCx()">
                            More »
                        </be-button>
                    </div>
                    <most-frequent-topic class="pcs-charts__text-frequent animated fadeIn delay-1500" :filter="cancelFilter"/>
                </section>
                <section v-else-if="currentState === 'service'"
                         key="service"
                         class="pcs-charts__text">
                    <div class="animated fadeIn delay-1000">
                        <h4>Service volumes</h4>
                        <p>
                            <deq-number :number="serviceTotal"/>
                            {{ formatPlural(serviceTotal, 'mention') }}
                        </p>
                        <be-button link
                                   class="pcs-charts__more-button"
                                   :tooltip="`Click to go the CX panel and find out more about ${brandName}'s Customer Experience` "
                                   @click="gotoExploreCx()">
                            More »
                        </be-button>
                    </div>
                    <most-frequent-topic class="pcs-charts__text-frequent animated fadeIn delay-1500" :filter="serviceFilter"/>
                </section>
            </section>
        </dark-card>


        <section class="pcs-charts__nav">
            <span v-for="s of states"
                  :key="s"
                  class="pcs-charts__nav-bubble"
                  :class="{'pcs-charts__nav-bubble--selected': currentState === s,
                            'RISK': s.startsWith('risk'),
                            'PURCHASE': s.startsWith('purchase'),
                            'CANCEL': s.startsWith('cancel'),
                            'SERVICE': s.startsWith('service')
                  }"
                  @click="gotoState(s)">
            </span>
        </section>



    </section>
</template>


<script>

import {getMetricFromId, init as initMetrics} from "@/app/framework/dialogs/metric-picker/availableMetrics";
import * as b3js from "brandseyejs";
import DeqNumber from "@/components/formatters/DeqNumber";
import {count as grouseCount} from "@/data/Grouse";
import {showTooltipComponent} from "@/components/tooltip/TooltipUtilities";
import PcsChartTooltip from "@/app/toplevel/explore/overview/components/PcsChartTooltip";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {replaceDate} from "@/dashboards/filter/FilterParser";
import moment from "moment";
import {codeToColour} from "@/app/utils/Metatags";
import MostFrequentTopic from "@/app/toplevel/explore/overview/components/MostFrequentTopic";
import DarkCard from "@/components/cards/DarkCard";
import {mapActions, mapGetters, mapState} from "vuex";
import {formatDate, formatPercentage, formatPlural} from "@/app/utils/Format";
import {getTimedLocalstorageCache} from "@/data/Cache";
import BeButton from "@/components/buttons/BeButton";
import {gotoExploreCx, gotoExploreRisk} from "@/app/toplevel/explore/overview/ExploreUtilities";

export default {
    components: {DeqNumber, BeButton, DarkCard, MostFrequentTopic},
    props: {
        brand: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            beChart: null,
            loading: false,
            noStates: false,

            riskFilter: null,
            riskData: null,
            riskTotal: 0,

            purchaseFilter: null,
            purchaseData: null,
            purchaseTotal: 0,

            cancelFilter: null,
            cancelData: null,
            cancelTotal: 0,

            serviceFilter: null,
            serviceData: null,
            serviceTotal: 0,

            state: 0,
            states: [],
            timer: null,
            redrawTimer: null,
            lastDrawState: null
        }
    },

    computed: {
        ...mapState('explorePanel', ['date']),
        ...mapGetters('explorePanel', ['dateFilter', 'brandName']),
        ...mapGetters(['idToTag']),

        currentState() {
            if (this.states.length) {
                return this.states[this.state];
            }
            return null
        },

        isCurrentStateList() {
            return this.currentState?.endsWith('Topics');
        },

        drawState() {
            switch(this.currentState) {
                case 'purchase':
                case 'purchaseTopics':
                    return 'purchase';
                case 'cancel':
                case 'cancelTopics':
                    return 'cancel';
                case 'service':
                case 'serviceTopics':
                    return 'service';
                default:
                    return this.currentState;
            }
        }
    },

    watch: {
        brand() {
            this.loadData();
        }
    },

    async mounted() {
        await this.loadData();
        await this.render();
        await this.startTimer();
    },

    async activated() {
        await this.render();
        await this.startTimer();
    },

    async deactivated() {
        await this.stopTimer();
    },

    beforeDestroy() {
        this.stopTimer();
    },

    methods: {
        formatPlural,
        gotoExploreRisk,
        gotoExploreCx,

        ...mapActions(['refreshTags']),

        mouseenter() {
            this.stopTimer();
        },

        mouseleave() {
            this.startTimer();
        },

        async loadData() {
            this.loading = true;
            const states = [];
            this.purchaseData = null;
            this.cancelData = null;
            this.serviceData = null;
            const brand = this.brand;

            try {
                if (!this.brand) return;
                await this.refreshTags();

                await initMetrics();
                const rawFilter = `(${this.dateFilter}) and brand isorchildof ${brand.id} and relevancy isnt irrelevant`;
                const risk = getMetricFromId('riskVolumes');
                const purchase = getMetricFromId('purchaseVolumes');
                const cancel = getMetricFromId('cancelVolumes');
                const service = getMetricFromId('serviceVolumes');

                const hasRisk = service && brand?.activeSegmentListIds?.some(id => this.idToTag.get(id)?.children?.some(cId => this.idToTag.get(cId)?.flag === 'RISK'));
                const hasPurchase = purchase && brand?.activeSegmentListIds?.some(id => this.idToTag.get(id)?.children?.some(cId => this.idToTag.get(cId)?.flag === 'PURCHASE'));
                const hasCancel = cancel && brand?.activeSegmentListIds?.some(id => this.idToTag.get(id)?.children?.some(cId => this.idToTag.get(cId)?.flag === 'CANCEL'));
                const hasService = service && brand?.activeSegmentListIds?.some(id => this.idToTag.get(id)?.children?.some(cId => this.idToTag.get(cId)?.flag === 'SERVICE'));


                if (hasRisk) {
                    states.push("risk");
                    const riskSubfilter = risk.widgetDesc.filter;
                    this.riskFilter = `${rawFilter} and (${riskSubfilter})`;
                    this.riskData = await getTimedLocalstorageCache(`pcs-chart:${this.riskFilter}`, async () => await grouseCount(this.riskFilter, ["published"]));
                    this.riskTotal = this.riskData.reduce((total, current) => total + current.mentionCount, 0);
                    this.riskData.forEach(d => d.percent = this.riskTotal ? d.mentionCount / this.riskTotal : 0);
                }

                if (hasPurchase) {
                    states.push("purchase");
                    const purchaseSubFilter = purchase.widgetDesc.filter;
                    this.purchaseFilter = `${rawFilter} and (${purchaseSubFilter})`;
                    this.purchaseData = await getTimedLocalstorageCache(`pcs-chart:${this.purchaseFilter}`, async () => await grouseCount(this.purchaseFilter, ["published"]));
                    this.purchaseTotal = this.purchaseData.reduce((total, current) => total + current.mentionCount, 0);
                    this.purchaseData.forEach(d => d.percent = this.purchaseTotal ? d.mentionCount / this.purchaseTotal : 0);
                }

                if (hasCancel) {
                    states.push("cancel");
                    const subFilter = cancel.widgetDesc.filter;
                    this.cancelFilter = `${rawFilter} and (${subFilter})`;
                    this.cancelData = await getTimedLocalstorageCache(`pcs-chart:${this.cancelFilter}`, async () => await grouseCount(this.cancelFilter, ["published"]));
                    this.cancelTotal = this.cancelData.reduce((total, current) => total + current.mentionCount, 0);
                    this.cancelData.forEach(d => d.percent = this.cancelTotal ? d.mentionCount / this.cancelTotal : 0);
                }

                if (hasService) {
                    states.push("service");
                    const subFilter = service.widgetDesc.filter;
                    this.serviceFilter = `${rawFilter} and (${subFilter})`;
                    this.serviceData = await getTimedLocalstorageCache(`pcs-chart:${this.serviceFilter}`, async () => await grouseCount(this.serviceFilter, ["published"]));
                    this.serviceTotal = this.serviceData.reduce((total, current) => total + current.mentionCount, 0);
                    this.serviceData.forEach(d => d.percent = this.serviceTotal ? d.mentionCount / this.serviceTotal : 0);
                }
            } finally {
                this.states = states;
                this.noStates = !states.length;
                this.loading = false;
            }
        },

        /**
         * This clears the chart element, waits a few seconds, and th en redraws.
         * This is useful if you want to resize the chart.
         */
        async redraw() {
            if (!this.beChart) return; // We have drawn nothing yet.

            // We don't want timers to redraw this while we're handling movement.
            // This also stops any existing redraw timers from firing if we are called multiple times.
            this.stopTimer();

            if (this.$refs.chart) {
                this.$refs.chart.innerHTML = '';
            }

            setTimeout(() => {
                    this.render(true);
                    this.startTimer();
                },
                100);
        },

        async render(force) {
            const chart = this.$refs.chart;

            this.beChart ??= new b3js.chart();

            const shouldDraw = this.lastDrawState !== this.drawState || force;
            if (chart && shouldDraw) {
                chart.style.opacity = 0;
                if (force) await this.completeRender(force);
                else setTimeout(() => this.completeRender(), 1500);
            }
        },

        async completeRender(force) {
            const chart = this.$refs.chart;
            if (!chart) return; // Might have moved on from the page.

            // See if we have already drawn the chart that should be drawn.
            if (this.lastDrawState === this.drawState && !force) return;
            if (this.lastDrawState !== this.drawState) this.lastDrawState = this.drawState;

            const tooltipEvent = (event) => {
                showTooltipComponent(event.e.currentTarget,
                    PcsChartTooltip,
                    {
                        data: event.point
                    })
            };

            const createClickEvent = (filter, title) => {
                return (event) => {
                    const after = moment(event.point.published);
                    const before = moment(event.point.published).clone().add({'days': 1})
                    // We use replace date to try to get a filter that the basic filter will parse in the mention panel.
                    filter = replaceDate(filter,
                        `published after '${after.format("YYYY/MM/DD")}' and published before '${before.format("YYYY/MM/DD")}'`);
                    showMentions(
                        filter,
                        `${title} from ${event.point.published} (by engagement)`,
                        true
                    )
                }
            };

            function createXFormatter(array) {
                return (val, i) => {
                    const numberOfDays = array.length;
                    const firstOrLast = i === 0 || i === array.length - 1;

                    let format = numberOfDays > 7 ? "D" : "ddd D";
                    if (firstOrLast) format = "MMM D";
                    else if (array.length > 10) {
                        format = moment(val).isoWeekday() !== 1 ? " " : "ddd D";
                    }


                    if (moment(val).isSameOrAfter(moment().startOf('day'))) return "Today";
                    return formatDate(val, format);
                }
            }

            if (chart && this.drawState === 'risk') {
                this.beChart
                    .reset()
                    .geometry(b3js.line())
                    .scaleX(b3js.scaleTime())
                    .data(this.riskData)
                    .element(chart)
                    .x(d => d.published)
                    .y(d => d.percent)
                    .formatY(val => formatPercentage(val * 100))
                    .formatX(createXFormatter(this.riskData))
                    .width(chart.clientWidth)
                    .height(chart.clientHeight)
                    .showLegend(false)
                    .individualColours(() => codeToColour("RISK"))
                    .render();
                this.beChart.dispatch().on("tooltipShow", tooltipEvent);
                this.beChart.dispatch().on("elementClick", createClickEvent(this.riskFilter, "Risk mentions"));
            }



            if (chart && this.drawState === 'purchase') {
                this.beChart
                    .reset()
                    .geometry(b3js.line())
                    .scaleX(b3js.scaleTime())
                    .data(this.purchaseData)
                    .element(chart)
                    .x(d => d.published)
                    .y(d => d.percent)
                    .formatY(val => formatPercentage(val * 100))
                    .formatX(createXFormatter(this.purchaseData))
                    .width(chart.clientWidth)
                    .height(chart.clientHeight)
                    .showLegend(false)
                    .individualColours(() => codeToColour("PURCHASE"))
                    .render();
                this.beChart.dispatch().on("tooltipShow", tooltipEvent);
                this.beChart.dispatch().on("elementClick", createClickEvent(this.purchaseFilter, "Purchase mentions"));
            }

            if (chart && this.drawState === 'cancel') {
                this.beChart
                    .reset()
                    .geometry(b3js.line())
                    .scaleX(b3js.scaleTime())
                    .data(this.cancelData)
                    .element(chart)
                    .x(d => d.published)
                    .y(d => d.percent)
                    .formatY(val => formatPercentage(val * 100))
                    .formatX(createXFormatter(this.cancelData))
                    .width(chart.clientWidth)
                    .height(chart.clientHeight)
                    .individualColours(() => codeToColour("CANCEL"))
                    .render();
                this.beChart.dispatch().on("tooltipShow", tooltipEvent);
                this.beChart.dispatch().on("elementClick", createClickEvent(this.cancelFilter, "Cancel mentions"));
            }

            if (chart && this.drawState === 'service') {
                this.beChart
                    .reset()
                    .geometry(b3js.line())
                    .scaleX(b3js.scaleTime())
                    .data(this.serviceData)
                    .element(chart)
                    .x(d => d.published)
                    .y(d => d.percent)
                    .formatY(val => formatPercentage(val * 100))
                    .formatX(createXFormatter(this.serviceData))
                    .width(chart.clientWidth)
                    .height(chart.clientHeight)
                    .individualColours(() => codeToColour("SERVICE"))
                    .render();
                this.beChart.dispatch().on("tooltipShow", tooltipEvent);
                this.beChart.dispatch().on("elementClick", createClickEvent(this.serviceFilter, "Service mentions"));
            }

            chart.style.opacity = 1;
        },

        startTimer() {
            this.stopTimer();

            this.timer = setInterval(() => {
                this.state += 1;
                if (this.state >= this.states.length) this.state = 0;
                this.$nextTick(() => this.render())
            }, 10000)
        },

        /**
         * Stops all timers and intervals.
         */
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            if (this.redrawTimer) {
                clearTimeout(this.redrawTimer);
                this.redrawTimer = null;
            }
        },

        gotoState(state) {
            const index = this.states.indexOf(state);
            if (index >= 0) {
                this.state = index;
                this.$nextTick(() => this.render());
                this.startTimer();
            }
        }
    }

}

</script>

<style scoped lang="sass">

.pcs-charts
    display: grid
    box-sizing: border-box
    transition-property: width, height, opacity
    transition-duration: 1500ms
    padding: 5px 10px

    &--no-states
        overflow: hidden
        width: 0
        height: 0


.pcs-charts__chart
    width: 100%
    height: 236px
    //background: #222
    box-sizing: border-box
    //border-top-left-radius: 3px
    //border-top-right-radius: 3px

.pcs-charts__chart-item
    width: 100%
    height: 100%
    transition: opacity 500ms

.pcs-charts__info
    display: flex
    flex-direction: row
    width: 100%
    min-height: 80px
    box-sizing: border-box
    padding-left: 40px

    h4
        margin-bottom: 0
        color: white

.pcs-charts__text
    box-sizing: border-box
    color: var(--be-colour-text-dark)
    display: flex
    width: 100%
    h4
        margin: 0

    .pcs-charts__text-frequent
        margin-left: auto
        margin-right: 20px
        max-width: 70%
        text-align: end
        &::v-deep .be-tag
            margin-left: 5px
            margin-right: 0


.pcs-charts__nav
    padding-top: 10px
    display: flex
    justify-content: center

.pcs-charts:hover
    .pcs-charts__nav
        .pcs-charts__nav-bubble
            transition: opacity var(--transition-duration)
            opacity: 1

.pcs-charts__nav-bubble
    transition-property: background, opacity
    transition-duration: var(--transition-duration)

    background: #5a5a5a
    border-radius: 50%
    width: 10px
    height: 10px
    display: inline-block
    cursor: pointer
    opacity: 0.4
    margin: 3px

    &--selected
        background: darkgrey
        opacity: 1 !important

    &:hover
        background: var(--background-menu-hover)

.pcs-charts__more-button
    opacity: 0
    transition: opacity var(--transition-duration)
    --be-button-link-colour: var(--be-colour-muted-text-dark)

    &::v-deep .btn
        margin: 0
        padding: 0

.pcs-charts:hover
    .pcs-charts__more-button
        opacity: 1

    .pcs-charts__nav-bubble:not(.pcs-charts__nav-bubble--selected)
        &.RISK
            background: var(--be-colour-tag-risk) !important
        &.PURCHASE
            background: var(--be-colour-tag-purchase) !important
        &.CANCEL
            background: var(--be-colour-tag-cancel) !important
        &.SERVICE
            background: var(--be-colour-tag-service) !important

.pcs-charts
    ::v-deep svg .y-axis text, ::v-deep svg .x-axis text
        opacity: 0


    ::v-deep svg .domain-selector:nth-child(odd) rect
        transition: opacity var(--transition-duration)

    &:hover
        ::v-deep svg .y-axis text, ::v-deep svg .x-axis text
            opacity: 1

        ::v-deep svg .domain-selector:nth-child(odd) rect
            opacity: 0.1 !important
            transition-delay: 500ms
            --transition-duration: 1000ms

            &:hover
                opacity: 0.2 !important
                transition-delay: 0s
                --transition-duration: 100ms



</style>