import VuexStore from "@/store/vuex/VuexStore";
import {getAllCxSegmentLists} from "@/app/utils/Segments";
import { getTopicTreeIds } from "@/app/utils/Topics";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";

const competitors = {
    title: "Competitors",
    description: "Gain insight into the public opinion of your competitors.",
    hide: true,
    expanded: `Competitor metrics allow you to identify areas where your competitors are both excelling and failing at, as well as measure overall public opinion and engagement. All data is measured using public conversation and excludes your sub-brands.`,
    image: require("../assets/competitors.png"),
    metrics: [
        {
            id: "competitors/overall",
            title: "Overall comparison",
            description: "Provides an overall comparison of your brand vs your competitors",
            more: "Comparisons include conversation volume, engagement, and benchmark sentiment.",
            hero: true,
            image: require("../assets/competitors/overall.png"),
            widgetDesc: async function () {
                return Object.assign({},
                    {
                        type: "CompTable",
                        caption: "Comparison Table: Overall",
                        width: 5,
                        height: 3,
                        filter: await getCompetitorsFilter(),
                        cols: [
                            {
                                id: 1,
                                type: "mentionCount",
                                limit: 10,
                                showOther: true,
                                showUnknown: true,
                                showPercentage: true,
                                showBar: true,
                                showValue: true,
                                showDelta: true
                            },
                            {
                                id: 2,
                                type: "totalEngagement",
                                limit: 10,
                                showOther: true,
                                showUnknown: true,
                                showPercentage: false,
                                showValue: true,
                                showBar: true,
                                showDelta: true,
                            },
                            {
                                id: 3,
                                type: "negative",
                                limit: 10,
                                showOther: true,
                                showUnknown: true,
                                showPercentage: false,
                                showValue: true,
                                showBar: true,
                                showDelta: true
                            },
                            {
                                id: 4,
                                type: "neutral",
                                limit: 10,
                                showOther: true,
                                showUnknown: true,
                                showPercentage: false,
                                showValue: true,
                                showBar: true,
                                showDelta: true
                            },
                            {
                                id: 5,
                                type: "positive",
                                limit: 10,
                                showOther: true,
                                showUnknown: true,
                                showPercentage: false,
                                showValue: true,
                                showBar: true,
                                showDelta: true
                            }
                        ],
                    });
            }
        },
        {
            id: "competitors/volume",
            title: "Volume",
            description: "Shows the volume of public mentions received by your brand and your competitors",
            keywords: ['competitors'],
            image: require("../assets/competitors/volume.png"),
            widgetDesc: async function () {
                return Object.assign({}, {
                    type: "FantasticChart",
                    width: 6,
                    height: 4,
                    caption: "Competitor Brand Volume",
                    xAxis: "brand",
                    geometry: "columns",
                    yAxis: "mentionCount",
                    filter: await getCompetitorsFilter()
                });
            },
            options: [
                {
                    key: "volume/pie",
                    title: "As donut",
                    tooltip: "Shows the volume of public mentions received by your brand and your competitors",
                    action: async function () {
                        return {
                            id: "competitors/volume/pie",
                            widgetDesc: {
                                type: "FantasticChart",
                                width: 6,
                                height: 4,
                                xAxis: "brand",
                                yAxis: "mentionCount",
                                caption: "Brand volume by brand",
                                geometry: "pie",
                                labelPlacement: "outside",
                                isDonut: true,
                                filter: await getCompetitorsFilter()
                            },
                        };
                    }
                },
                {
                    key: "volume/line",
                    title: "As line chart",
                    tooltip: "Shows the volume of public mentions received by your brand and your competitors",
                    action: async function () {
                        return {
                            id: "competitors/volume/line",
                            widgetDesc: {
                                type: "FantasticChart",
                                width: 6,
                                height: 4,
                                xAxis: "published",
                                compare: "brand",
                                yAxis: "mentionCount",
                                caption: "Brand volume by brand",
                                geometry: "lines",
                                filter: await getCompetitorsFilter()
                            },
                        };
                    }
                },
                {
                    key: "volume/social",
                    title: "As social network comparison",
                    tooltip: "Compare social media mention volume of your brand against your competitors",
                    action: async function () {
                        return {
                            id: "competitors/volumes/social",
                            widgetDesc: {
                                type: "FantasticChart",
                                width: 6,
                                height: 4,
                                xAxis: "brand",
                                compare: "socialNetwork",
                                yAxis: "mentionCount",
                                caption: "Social network comparison of volume by brand",
                                geometry: "rows",
                                filter: await getCompetitorsFilter()
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "competitors/sentiment",
            title: "Benchmark sentiment",
            description: "See the Net Sentiment of public conversation for your brand (excluding sub-brands), and your competitors",
            keywords: ['competitors'],
            image: require("../assets/competitors/sentiment.png"),
            widgetDesc: async function () {
                return Object.assign({}, {
                    type: "FantasticChart",
                    xAxis: "brand",
                    yAxis: "totalSentimentPercent",
                    width: 6,
                    height: 4,
                    filter: await getCompetitorsFilter()
                });
            },
            options: [
                {
                    key: "sentiment/line",
                    title: "As line chart",
                    tooltip: "See the Net Sentiment of public conversation for your brand (excluding sub-brands), and your competitors",
                    action: async function () {
                        return {
                            id: "competitors/sentiment/line",
                            widgetDesc: {
                                type: "FantasticChart",
                                width: 6,
                                height: 4,
                                xAxis: "published",
                                compare: "brand",
                                yAxis: "totalSentimentPercent",
                                caption: "Brand volume by brand",
                                geometry: "lines",
                                filter: await getCompetitorsFilter()
                            },
                        };
                    }
                }

            ]
        },
        {
            id: "competitors/opVsRep",
            title: "Operational vs reputational",
            image: require("../assets/competitors/op-vs-rep.png"),
            keywords: ['competitors'],
            description: "Compare the operational and reputational benchmark sentiment performance of your brand against your competitors",
            widgetDesc: async function () {
                return Object.assign({}, {
                    type: "FantasticChart",
                    xAxis: "functionalSentiment",
                    yAxis: "totalSentimentPercent",
                    compare: "brand",
                    width: 6,
                    height: 4,
                    filter: await getCompetitorsFilter()
                });
            },
            options: [
                {
                    key: "volume",
                    title: "As volume",
                    tooltip: "See operational and reputational mention volumes of your brand and your competitors",
                    action: async function () {
                        return {
                            id: "competitors/opVsRep/volumes",
                            widgetDesc: {
                                type: "FantasticChart",
                                yAxis: "mentionCount",
                                xAxis: "functionalSentiment",
                                compare: "brand",
                                width: 6,
                                height: 4,
                                filter: await getCompetitorsFilter()
                            },
                        };
                    }
                }
            ]
        },
        {
            id: "competitors/topics",
            title: "Topics",
            description: "See the benchmark sentiment per topic for your brand and your competitors",
            keywords: ['competitors'],
            hide: true,
            image: require("../assets/competitors/topics.png"),
            widgetDesc: async function () {
                return Object.assign({}, {
                    type: "CompTable",
                    caption: "Comparison Table: Topics sentiment per brand",
                    name: "CompTable",
                    width: 12,
                    height: 3,
                    rows: [
                        {
                            id: 1,
                            groupBy: "brand",
                            limit: 99,
                            showOther: true,
                            showUnknown: true,
                            name: "",
                            sortByFirstCol: false
                        }
                    ],
                    cols: [
                        {
                            id: 1,
                            type: "sentiment",
                            groupBy: "topic",
                            limit: 10,
                            showOther: false,
                            showUnknown: false,
                            showPercentage: true,
                            showBar: true,
                            showValue: true,
                            showDelta: true
                        }
                    ],
                    hiddenRowCols: [
                        "1-93744",
                        "1-97504",
                        "1-122045"
                    ],
                    swapRowsAndCols: true,
                    filter: await getCompetitorsFilter()
                });
            },
            options: [
                {
                    key: "topics/volume",
                    title: "As volume",
                    tooltip: "See the mention volume per topic for your brand and your competitors",
                    action: async function () {
                        return {
                            id: "competitors/topics/sentiment",
                            widgetDesc: {
                                type: "CompTable",
                                caption: "Comparison Table: Topics volume per brand",
                                name: "CompTable",
                                width: 12,
                                height: 3,
                                rows: [
                                    {
                                        id: 1,
                                        groupBy: "brand",
                                        limit: 99,
                                        showOther: true,
                                        showUnknown: true,
                                        name: "",
                                        sortByFirstCol: false
                                    }
                                ],
                                cols: [
                                    {
                                        id: 1,
                                        type: "mentionCount",
                                        groupBy: "topic",
                                        limit: 10,
                                        showOther: false,
                                        showUnknown: false,
                                        showPercentage: true,
                                        showBar: true,
                                        showValue: true,
                                        showDelta: true
                                    }
                                ],
                                hiddenRowCols: [
                                    "1-93744",
                                    "1-97504",
                                    "1-122045"
                                ],
                                swapRowsAndCols: true,
                                filter: await getCompetitorsFilter()
                            },
                        };
                    }
                }

            ]
        },
    ]
};

export default competitors;

let initPromise = null;

async function getCompetitorsFilter() {
    const rootBrands = VuexStore.state.rootBrands;

    let brands = rootBrands.filter(brand => {
        return (brand.category === "COMPETITOR" || brand.category === "OWN") && !brand.archived;
    });

    // build filters
    let filter = "Visibility IS PUBLIC";
    let brandFilter = "";
    for (let i = 0; i < brands.length; i++) {
        let brand = brands[i];

        if (i === brands.length - 1) {
            brandFilter += `Brand IS ${brand.id}`;
        } else {
            brandFilter += `Brand IS ${brand.id} OR `;
        }
    }

    filter = appendFiltersReadably(filter, brandFilter);

    return filter;
}

export async function init() {
    if (initPromise) return initPromise;
    return initPromise = initImpl();
}

export async function initImpl() {
    const [cxList, ignore] = await Promise.all([
        getAllCxSegmentLists(),
        VuexStore.dispatch('refreshBrands')
    ]);
    const brands = VuexStore.state.rootBrands;

    // only show competitor metrics if competitor brands + own brands > 1
    let ownCount = 0;
    let competitorCount = 0;
    let showMetrics = false;

    for (let i = 0; i < brands?.length; i++) {
        let brand = brands[i];

        if (brand.category === "COMPETITOR" && !brand.archived) competitorCount++;
        if (brand.category === "OWN" && !brand.archived) ownCount++;

        if (ownCount + competitorCount > 1) {
            showMetrics = true;
            break;
        }
    }

    if (showMetrics) {
        // check if topics metrics should be shown
        const topics = await getTopicTreeIds();
        if (topics.length) {
            competitors.metrics.find(m => m.id === "competitors/topics").hide = false;
        }

        // We want to be able to look up which segments are active
        const activeSegments = new Set();
        brands.forEach(b => b.activeSegmentListIds?.forEach(id => activeSegments.add(id)));

        // set up CX metrics
        if (cxList.length) {
            for (const segment of cxList) {
                const title = cxList.length === 1 ? segment.name : `${segment.subtitle} ${segment.name}`
                competitors.metrics.push({
                    id: `competitors/cx/${segment.id}`,
                    title: title,
                    description: "Compare consumers' interactions and experiences with the brand against those of your competitors",
                    image: require("../assets/competitors/cx.png"),
                    keywords: ['competitors', 'cx'],
                    additional: !activeSegments.has(segment.id),
                    widgetDesc: async function () {
                        return Object.assign({}, {
                            type: "FantasticChart",
                            xAxis: `${segment.id}`,
                            yAxis: "totalSentimentPercent",
                            compare: "brand",
                            width: 12,
                            height: 4,
                            filter: await getCompetitorsFilter()
                        });
                    },
                    options: [
                        {
                            key: `cx/volume/${segment.id}`,
                            title: "As volume chart",
                            action: async function () {
                                return {
                                    id: `competitors/cx/volume/${segment.id}`,
                                    widgetDesc: {
                                        type: "FantasticChart",
                                        xAxis: `${segment.id}`,
                                        yAxis: "mentionCount",
                                        compare: "brand",
                                        width: 12,
                                        height: 4,
                                        filter: await getCompetitorsFilter()
                                    },
                                };
                            }
                        },
                        {
                            key: `cx/volume/comparison/${segment.id}`,
                            title: "As volume comparison table",
                            action: async function () {
                                return {
                                    id: `competitors/cx/volume/comparison/${segment.id}`,
                                    widgetDesc: {
                                        type: "CompTable",
                                        caption: "Comparison Table: CX volume per brand",
                                        name: "CompTable",
                                        width: 12,
                                        height: 3,
                                        rows: [
                                            {
                                                id: 1,
                                                groupBy: `segment-${segment.id}`,
                                                limit: 99,
                                                showOther: true,
                                                showUnknown: true,
                                                name: "",
                                                sortByFirstCol: false
                                            }
                                        ],
                                        cols: [
                                            {
                                                id: 1,
                                                type: "mentionCount",
                                                groupBy: "brand",
                                                limit: 10,
                                                showOther: true,
                                                showUnknown: true,
                                                showPercentage: true,
                                                showBar: true,
                                                showValue: true,
                                                showDelta: true
                                            }
                                        ],
                                        swapRowsAndCols: true,
                                        filter: await getCompetitorsFilter()
                                    },
                                };
                            }
                        },
                        {
                            key: `cx/sentiment/comparison/${segment.id}`,
                            title: "As benchmark sentiment comparison table",
                            action: async function () {
                                return {
                                    id: `competitors/cx/sentiment/comparison/${segment.id}`,
                                    widgetDesc: {
                                        type: "CompTable",
                                        caption: "Comparison Table: CX Sentiment per brands",
                                        name: "CompTable",
                                        width: 12,
                                        height: 3,
                                        rows: [
                                            {
                                                id: 1,
                                                groupBy: `segment-${segment.id}`,
                                                limit: 99,
                                                showOther: true,
                                                showUnknown: true,
                                                name: "",
                                                sortByFirstCol: false
                                            }
                                        ],
                                        cols: [
                                            {
                                                id: 1,
                                                type: "sentiment",
                                                groupBy: "brand",
                                                limit: 10,
                                                showOther: true,
                                                showUnknown: true,
                                                showPercentage: true,
                                                showBar: true,
                                                showValue: true,
                                                showDelta: true
                                            }
                                        ],
                                        swapRowsAndCols: true,
                                        filter: await getCompetitorsFilter()
                                    },
                                };
                            }
                        }
                    ]
                });
            }
        }

        competitors.hide = false;
    }
}