<template>
    <div class="backbone-style-sample-picker deq-reset" :class="classes">
        <label>Sample rate</label>
        <div class="bssp-tag-holder">

            <SlottedTag v-if="internalData !== null" @close="removeRate" close-tooltip="Click to remove the sampling rate">{{internalData}}%</SlottedTag>

            <div v-else class="bssp-content-placeholder">
                <div class="bssp-content"
                     ref="content"
                     contenteditable="true"
                     @keyup.capture="stopEnter"
                     @keydown="keyDown">

                </div>
                <div class="bssp-placeholder"
                     v-if="showPlaceholder">
                    Type sample percentage
                </div>
            </div>

        </div>

    </div>
</template>


<script>
import Vue from "vue";
import SlottedTag from "@/components/tags/SlottedTag.vue";
import {notifyUserOfError, notifyWithText} from "@/app/framework/notifications/Notifications";

export default Vue.extend({
    components: {SlottedTag},

    props: {
        value: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            internalData: this.value,
            showPlaceholder: true,
            error: null
        }
    },

    computed: {
        classes() {
            const classes = [];
            if (this.error) classes.push('bssp-error');
            return classes;
        }
    },

    watch: {
        internalData() {
            this.$emit('update', this.internalData);
        }
    },

    methods: {
        checkPlaceholder() {
            const content = this.$refs.content?.innerText?.trim() ?? "";
            this.showPlaceholder = !content.length;
        },

        stopEnter(event) {
            try {
                if (event.key === "Enter") {
                    event.preventDefault();
                    event.stopPropagation();
                }

                if (event.key === "Escape") {
                    // todo escape

                    event.preventDefault();
                    event.stopPropagation();
                }
            } finally {
                this.checkPlaceholder();
            }
        },

        /**
         * @param {KeyboardEvent} event
         * @param event
         */
        async keyDown(event) {
            if (event.key?.length === 1) {
                this.showPlaceholder = false;
                await this.$nextTick();
            }

            if (event.key === "Enter") {
                //region Handle input validation
                event.stopPropagation();
                event.preventDefault();

                const contentEl = this.$refs.content;
                let current = contentEl?.innerText?.trim() ?? "";

                const number = Number.parseInt(current);
                if (!Number.isFinite(number) || number < 0 || number > 100) {
                    const error = "Sample rate should be a number between 0 and 100";
                    notifyUserOfError(error);
                    this.error = error;
                    return;
                }

                if (number === 100) {
                    notifyWithText("100% sampling is the same as having sampling disabled. We are now disabling sampling.", null, '<i class="symbol-warning"></i>');
                    contentEl.innerText = "";
                    this.checkPlaceholder();
                    contentEl.blur();
                    return;
                }

                this.internalData = number;
                contentEl.innerText = "";
                return
                //endregion

            }

            // Clear any errors.
            this.error = null;
        },

        removeRate() {
            this.internalData = null;
            this.checkPlaceholder();
        }
    }
})
</script>


<style scoped lang="sass">

.backbone-style-sample-picker
    box-sizing: border-box
    display: flex
    gap: 0.5rem

    align-items: center

    min-height: 33px

    .bssp-tag-holder
        flex-basis: 20ch


.bssp-content-placeholder
    display: grid
    grid-template-rows: 1fr
    grid-template-columns: 1fr

    padding: 1.5px 5px
    height: 100%
    align-items: center

    border: 1px solid #aaaaaa
    border-radius: 3px
    background-color: #414141
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025)

    &:focus-within
        border: var(--focus-border)
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(174, 225, 93, 0.6)

.bssp-placeholder
    pointer-events: none
    grid-row: 1
    grid-column: 1
    color: var(--colour-placeholder)
    height: 100%


.bssp-content
    grid-row: 1
    grid-column: 1
    height: 100%

    &:focus
        outline: none



.bssp-error
    .bssp-content-placeholder
        border-color: red
        box-shadow: 0 0 5px red

</style>