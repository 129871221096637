<template>
    <div>
        <div v-if="googleBusinessLoginStatus.loggedIn">
            <div v-if="googleBusinessProfiles.length">
                <p>
                    These are the Google business profiles that you are an admin of. Click on a profile's row to view the accounts you can add it to.
                </p>

                <search-input
                    v-if="googleBusinessProfiles.length > 5"
                    class="googleBusiness-auth__search-bar"
                    autofocus
                    placeholder="Search for a profile"
                    v-model="searchTerm">
                </search-input>
                <div class="googleBusiness-auth__profiles dark-scrollbars dark-scrollbars--visible"
                     :style="googleBusinessProfiles.length > 5 ? 'height: clamp(230px, 22vh, 600px)' : 'height: clamp(250px, 26vh, 600px)'">

                    <online-profile-row v-for="profile in filteredProfiles"
                                        :profile="profile"
                                        :linked-profile="profile.linkedProfile"
                                        :key="profile.handleId"
                                        :expand-row="showAccounts"
                                        :reauthorised-profiles="reauthorisedProfiles"
                                        :profile-just-reauthorised="profileJustReauthorised"
                                        @collapse-row="showAccounts = false"/>
                    <div style="margin-top: 10px" v-if="!filteredProfiles.length">
                        No profiles match your search
                    </div>
                </div>
            </div>
            <div v-else>
                Google business profiles that you are an admin of will be shown here. You are currently not an admin of any Google business profiles.
            </div>
        </div>
        <div v-else>
            <p v-if="googleBusinessLoginStatus.loginInvalidated">
                You have logged in through Google before, however the login has been invalidated.
                Please login again so that Google business profiles you have previously authorised can continue to collect data.
            </p>
            <p v-else :class="{'failed-login': googleBusinessLoginFailed}">
                {{ loginMessage }}
            </p>
        </div>

        <div class="googleBusiness-auth__btn-bar">
            <be-button class="btn-google-business" @click="loginThroughGoogleBusiness">
                {{ loginBtnText }}
            </be-button>
            <div class="login-status" v-if="googleBusinessLoggedInAs">
                <div>Logged in through <i class="symbol-google-full"></i>Google as {{ googleBusinessLoggedInAs.name }}</div>
            </div>

        </div>
    </div>
</template>

<script>
import OnlineProfileRow from "@/app/framework/dialogs/user-settings/views/platform-auth/OnlineProfileRow";
import { openWindowCenter } from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import { notifyUserOfError, notifyWithText } from "@/app/framework/notifications/Notifications";
import { formatDate } from "@/app/utils/Format";
import BeButton from "@/components/buttons/BeButton";
import SearchInput from "@/components/inputs/SearchInput";
import { profileTypes } from "@/setup/profiles/ProfileUtils";
import { beef } from "@/store/Services";
import VuexStore from "@/store/vuex/VuexStore";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "GoogleBusinessAuth",
    store: VuexStore,
    components: {OnlineProfileRow, BeButton, SearchInput},

    props: {
        googleBusinessProfiles: {
            type: Array,
            required: true
        },
        expandAccounts: {
            type: Boolean,
            default: false
        }
    },

    data: function() {
        return {
            profileTypes: profileTypes,
            googleBusinessLoginFailed: false,
            loginMessage: "Login with Google to see your managed business profiles. Once logged in, you can authorise your managed business profiles on DataEQ accounts that you have access to.",
            searchTerm: "",
            showAccounts: false,
            reauthorisedProfiles: new Set(),
            profileJustReauthorised: ""
        }
    },

    computed: {
        ...mapState(['user', 'account']),

        ...mapState('userPlatformAuth/googleBusinessAuthModule', ['googleBusinessLoggedInAs', 'googleBusinessLoginStatus']),

        loginBtnText() {
            return this.googleBusinessLoginStatus.loggedIn ? "Refresh Google login" : "Login with Google";
        },

        filteredProfiles() {
            let search = this.searchTerm.toLowerCase();

            let profiles = this.googleBusinessProfiles.filter(profile => profile.name.toLowerCase().includes(search) ||
                profile.handle.toLowerCase().includes(search) ||  profile.handleId.toLowerCase().includes(search));

            return profiles.sort((a, b) => {
                return a.name > b.name ? 1 : -1
            });
        }
    },

    created() {
        this.showAccounts = this.expandAccounts;
    },

    methods: {
        ...mapActions('userPlatformAuth/googleBusinessAuthModule', [
            'initializeManagedGoogleBusinessProfiles'
        ]),

        ...mapActions('profiles', ['refreshProfiles']),

        ...mapMutations('userPlatformAuth/googleBusinessAuthModule', ['setGoogleBusinessLoginStatus']),

        formatDate,

        async loginThroughGoogleBusiness() {
            try {
                this.setLoading("Communicating with Google...", true);

                let response = await beef.get(`/api/online-profile/google-business/login?accountCode=${this.account.code}`);

                // handle success of callback
                let addedFunctionName = `userPlatformAuth${this.user.id}`;

                window[addedFunctionName] = async (paramsMap) => {
                    if (paramsMap["auth"] === "success") {

                        if (window.location.href.indexOf("setup/profiles") !== -1) {
                            this.refreshProfiles(true);
                        }

                        this.setGoogleBusinessLoginStatus({loggedIn: true, loginInvalidated: false});

                        notifyWithText("Google login successful.",
                            null,
                            "<i class='symbol-googleBusiness-rect'></i>");

                        this.setLoading("Google login successful! Fetching your managed business profiles...", true);

                        this.triggerFetchProfiles();

                        this.showAccounts = true;
                    } else {
                        this.loginMessage = "Login with Google failed. Please reload the page and try again or contact DataEQ support.";
                        this.googleBusinessLoginFailed = true;

                        this.setLoading("", false);
                    }
                }

                openWindowCenter(response.data.url, "", 700, 900);
            } catch (e) {
                console.error("Error occurred while trying to log user in with Google", e);
                this.loginMessage = "Login with Google failed. Please reload the page and try again or contact DataEQ support.";
                this.googleBusinessLoginFailed = true;

                this.setLoading("", false);

                notifyUserOfError("An error occurred while trying to log you in with Google. Please close the dialog and try again or contact DataEQ support.");
            }
        },

        triggerFetchProfiles() {
            this.$emit('fetch-google-business-profiles', true);
        },

        setLoading(message, value) {
            this.$emit('loading-updated', message, value);
        },
    }


}
</script>

<style scoped lang="sass">


.googleBusiness-auth

    &__search-bar
        margin-bottom: 10px
        width: 90%

    &__profiles
        padding-right: 10px
        overflow-y: scroll

    .failed-login
        color: #df8505

    &__headers
        display: grid
        cursor: pointer
        overflow-x: auto
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr))
        position: sticky
        z-index: 10
        border: none
        color: var(--be-colour-text-dark)
        text-align: center

        span
            background: #222
            box-sizing: border-box
            border-top: 1px solid #1a1a1a
            border-right: 1px solid #1a1a1a
            padding: 5px 10px

            &:first-child
                border-top-left-radius: 3px
                border-left: 1px solid #1a1a1a

            &:last-child
                border-top-right-radius: 3px

    &__add-profile-container
        padding-right: 20px

    &__add-profile
        display: flex
        background: #444
        height: 90px
        border: 1px solid #272727
        padding: 10px
        margin-top: 20px
        margin-bottom: 30px
        border-radius: 6px

    &__enter-profile-details
        width: 50%

    &__found-profile
        width: 50%
        padding-left: 10px

    &__add-profile-divider
        margin: -10px 0
        border-right: 1px dashed #272727

    &__add-profile-loader
        margin-top: 0

    &__btn-bar
        display: flex
        align-items: center
        margin-top: 10px
        column-gap: 10px

        .login-status
            color: var(--be-colour-muted-text-dark)
            font-style: italic
            font-size: 0.9em
            padding-right: 10px
            margin-left: auto
            max-width: 295px

        .btn-google-business
            ::v-deep .btn
                background: var(--colour-googlebusiness)

            &:hover
                ::v-deep .btn
                    filter: brightness(1.2)

    &__login-btn
        margin-top: 15px

        ::v-deep .btn
            background: var(--colour-googlebusiness)

        &:hover
            ::v-deep .btn
                filter: brightness(1.2)

    &__add-profile-button-bar
        display: flex
        margin-top: 50px
        column-gap: 10px

.slide-content-enter-active,
.slide-add-profile-enter-active
    transition: all .5s ease

.slide-content-leave-active,
.slide-add-profile-leave-active
    transition: none
    opacity: 0

.slide-content-enter
    transform: translateX(-5px)
    opacity: 0

.slide-add-profile-enter
    transform: translateX(5px)
    opacity: 0

</style>