<template>
    <div :class="isProfileSelected ? 'online-presence-item selected' : 'online-presence-item'" >
        <a class="selected-icon selectable-area" title="Click to select/un-select the profile"
           @click="emitSelected">
            <span v-if="isProfileSelected">
                <animated-check v-if="!isProfileExcluded" class="animated-check" animated :size-px="25"/>
<!--                <i v-if="!isProfileExcluded" class="selected-icon-on icon-ok-circled-1"></i>-->
                <i v-else class="selected-icon-negate icon-minus-circled"></i>
            </span>
            <i v-else class="selected-icon-off icon-circle-empty"></i>
        </a>
        <span class="handle-and-avatar with-selector">
            <img class="avatar edit"
                 :src="profile.pictureLink"
                 @error="handleImageError"
                 @click="emitSelected"
                 alt="">
            <a class="selectable-area" @click="emitSelected">
                <div class="handle">
                    <span v-html="getProfileIcon(profile.type)"></span>
                    {{ profile.name }}
                </div>
                <div :title="profile.id" class="handle-id"> {{ profile.id }} </div>
            </a>
            <span v-if="allowNegation" class="profile-picker-negate" @click="emitNegated">
                <i class="icon-minus-squared"></i>
            </span>
        </span>
    </div>
</template>

<script>
import {getProfileIcon} from "@/app/utils/Profiles";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck.vue";

export default {
    name: "PpOnlineProfile",
    components: {AnimatedCheck},
    props: {
        isProfileSelected: Boolean,
        isProfileExcluded: Boolean,
        profile: {},
        allowNegation: Boolean
    },
    methods: {
        getProfileIcon,

        handleImageError(event){
            event.target.src = '/img/consumer_avatar.png';
        },

        emitSelected(){
            this.$emit('selected');
        },

        emitNegated(){
            this.$emit('negated');
        }
    }
}
</script>

<style scoped >
.online-presence-item {
    background-color: #1a1a1a;
}

.online-presence-table {
    background-color: var(--body-background-colour);
}

.handle-and-avatar {
    display: flex;
}

.handle {
    width: 110px;
}

.profile-picker-negate{
    display: inline-block;
    margin: auto;
    text-align: center;
    border-radius: 3px;
    aspect-ratio: 1;
    height: 20px;
    cursor: pointer;
    color: #888;
    opacity: 0;
}

.online-presence-item:hover .profile-picker-negate {
    transition: opacity 250ms 500ms;
    opacity: 1;
}

.selected-icon-negate {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    line-height: 26px;
}

.online-presence-item .selected-icon-negate {
    color: var(--be-colour-negated);
}

.animated-check {
    left: -6px !important;
}

.--be-animated-check-scale {
    left: 0;
    top: 0;
}
</style>