/**
 * Select one or more mentions by entering their ids. Fires a change event when the selection is changed.
 * Also provides a model binder converter to display the selected options in an element with tooltip showing
 * the mention (todo).
 */
import {getMention} from "@/data/Grouse";
import {getRecentMentions} from "@/app/RecentMentions";
import _ from 'underscore';
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("MentionPicker").addInitializer(function(startupOptions) {

    this.View = Beef.AutoCompletePicker.View.extend({

        attributes: { class: "mention-picker auto-complete-picker" },

        template: require("@/dashboards/filter/pickers/sharing/MentionPicker.handlebars"),

        items: function(q, view, callback) {
            var ac = view.model.getAncestorProperty('accountCode');
            var list = getRecentMentions();
            var predicate = this.options.options.predicate;
            if (predicate) list = list?.filter(predicate.fn) ?? [];
            list = _.take(list, 4);
            var firstRecent = predicate && predicate.label || "Recently Viewed Mentions";
            if (list.length > 0) list[0]._firstRecent = firstRecent;
            var cb = function(){
                for (var i = 0; i < list.length; i++) list[i]._index = i;
                callback(_.indexBy(list, 'id'));
            };
            if (q) {
                getMention(ac, q)
                    .then(function(mention) {
                        list.splice(0, 0, mention);
                        for (var i = 1; i < list.length; ) {
                            if (list[i].id === mention.id) list.splice(i, 1);
                            else ++i;
                        }
                        if (list.length > 1) list[1]._firstRecent = firstRecent;
                    })
                    .finally(cb);
            } else {
                cb();
            }
        },

        mustSelectFromItems: false,

        startDroppedDown: function () {
            return true;
        },

        events: {
            "mousedown .item": "mousedown"
        },

        mousedown: function(ev) {
            var predicate = this.options.options.predicate;
            var code = $(ev.target).closest('.item').attr('data-value');
            if (code) {
                ev.preventDefault();
                this.select(code);
            }
        },

        sortFun: function(a, b) {
            return a.name._index - b.name._index;
        },

        cleanupInput: function(s) {
            // don't allow URIs for now

            if (this.options?.options?.allowUnknown) {
                // allow 'unknown' (useful for finding mentions that have interaction IDs etc.)
                if (s.toLowerCase() === "unknown") return s;
            }

            return s.replace(/[^0-9-]/g, "");
        },

        renderSelected: function() {
            var code = this.model.get('selected');
            var $items = $(".item", this.$el);
            $items.toggleClass("active", false);
            if (code) {
                $items.each(function(){
                    var $this = $(this);
                    if ($this.attr('data-value') === code) {
                        $this.toggleClass("active", true);
                        return false;
                    }
                });
            }
        },

        contains: function(item, s) {
            return true;    // we always display all items in the popup
        }
    });

    this.createConverter = function(placeholder) {
        return createTagConverter({
            items: function(code) { return code; },
            placeholder: placeholder,
            cls: "tag mention-id"
        });
    };

    this.converter = this.createConverter("Mention IDs");

    /**
     * Attach a mention id picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute, options) {
        Beef.Picker.attachInputPicker(view, selector, attribute, this.View, options);
    };
});
