var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"avatar\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0))
    + "\" onerror=\"this.src='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"failAvatar") : depth0), depth0))
    + "'\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"stat-box highlight small\">\n        <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"followerCount") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":76}}}))
    + "</span> followers\n        <div class=\"under-stats\">\n            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"followerArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followerDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":18,"column":31},"end":{"line":18,"column":61}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"followerPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":18,"column":63},"end":{"line":18,"column":99}}}))
    + ")\n        </div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"stat-box highlight small\">\n        <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"likes") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":35,"column":29},"end":{"line":35,"column":68}}}))
    + "</span> likes\n        <div class=\"under-stats\">\n            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"likesArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"likesDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":37,"column":55}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"likesPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":37,"column":57},"end":{"line":37,"column":90}}}))
    + ")\n        </div>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"stat-box highlight small\">\n        <span class=\"number\">"
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"measurements") : depth0)) != null ? lookupProperty(stack1,"end") : stack1)) != null ? lookupProperty(stack1,"subscribers") : stack1),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":44,"column":29},"end":{"line":44,"column":74}}}))
    + "</span> likes\n        <div class=\"under-stats\">\n            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"subscribersArrow") : depth0), depth0)) != null ? stack1 : "")
    + alias2(__default(require("../../../../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribersDelta") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":46,"column":34},"end":{"line":46,"column":67}}}))
    + " ("
    + alias2(__default(require("../../../../helpers/formatPercentage.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribersPercent") : depth0),{"name":"formatPercentage","hash":{},"data":data,"loc":{"start":{"line":46,"column":69},"end":{"line":46,"column":108}}}))
    + ")\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"title small\">\n    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\" title=\"Open "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'s profile in a new tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"pictureLink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"display-name\">\n            <div class=\"vanity\">\n                "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0)) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "\n            </div>\n        </div>\n    </a>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isTwitter") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isInstagram") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isFacebook") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":0},"end":{"line":40,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isYouTube") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":0},"end":{"line":49,"column":7}}})) != null ? stack1 : "")
    + "\n\n";
},"useData":true});