<template>
    <div class="modal-mask short-animated fadeIn" :class="{overlay: (overlay || modal) && !noDim}"
            @click="modal || $emit('close')"
            @keydown.esc.stop="$emit('close')">
        <div class="modal-wrapper" @mousedown="onMouseDown">
            <div class="modal-container" :style="style" tabindex="-1" ref="container">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModalPopup",

        props: {
            width: { type: String, default: "300px" },
            noTransition: Boolean,
            overlay: Boolean,
            modal: Boolean,
            noDim: Boolean,
            left: String,
            top: String,
            bottom: String,
            right: String
        },

        computed: {
            style() {
                return {
                    width: this.width,
                    left: this.left,
                    top: this.top,
                    bottom: this.bottom,
                    right: this.right,
                    transition: this.noTransition ? '' : 'all var(--transition-duration) ease',
                    position: (this.left || this.top || this.bottom || this.right) ? 'absolute' : null
                }
            }
        },

        mounted() {
            this.$nextTick(() => this.$refs.container.focus())
        },

        methods: {
            onMouseDown(event) {
                if (!this.$refs.container) return;
                if (this.$refs.container.contains(event.target)) return;

                event.preventDefault();
                event.stopPropagation();
            }
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: table;
    }

    .overlay {
        background-color: rgba(0, 0, 0, .6);
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        margin: 0 auto;
        background-color: #333;
        border-radius: 2px;
        box-shadow: 0 5px 30px rgba(0,0,0,.75);
    }

    .modal-container.left {
        margin-left: 20px;
    }

    .modal-container:focus {
        outline: none;
    }
</style>