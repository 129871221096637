import TopicReference, { getTopicReferenceData } from './TopicReference';
import {errorHelper} from "@/dashboards/DashboardUtils";

Beef.module("Widget.TopicReference").addInitializer(function(startupOptions) {

    this.type = {
        name:           "TopicReference",
        height: 8,
        width: 8
    };

    this.View = Beef.BoundItemView.extend({

        cache: {},

        attributes: {
            'class': 'filter-display widget-height-inner'
        },

        modelEvents: {
            "change": "maybe",
            "change:language": "refresh"
        },

        getTemplate: function() {
            return () => "<div class=\"topicreference-vue\"></div>";
        },

        regions: {
            list: ".topicreference-vue"
        },

        svgExportDisabled: false,

        // Maybe redraws and maybe refreshes the data
        maybe() {
            if (this.list && this.list.currentView && this.list.currentView.vm) {
                const c = this.model.changed;
                if (c.hasOwnProperty('width') || c.hasOwnProperty('height')) {
                    this.list.currentView.vm.drawWheel();
                } else if (c.hasOwnProperty('treeId')) {

                }
            }
        },

        async onFirstRender() {
            try {
                const comp = new Beef.VuejsView.View({
                    component: TopicReference,
                    props: { language: this.model.attributes.language }
                });
                this.list.show(comp);
                this.refresh();
            } catch (e) {
                errorHelper(this.model, e);
            }
        },

        async refresh() {
            try {
                const vm = this.list && this.list.currentView && this.list.currentView.vm;
                if (!vm) return;

                const treeId = this.model.get("treeId");

                if (treeId) {
                    this.model.generalData.set({'_loading': true, _completed: false});
                    vm.tags = await getTopicReferenceData(treeId);
                    vm.language = this.model.attributes.language
                    this.model.generalData.set({'_loading': false, _completed: true});
                }
            } catch (e) {
                errorHelper(this.model, e);
            }
        }
    });

    this.SettingsView = Beef.BoundItemView.extend({
        template: require("@/dashboards/widgets/topics/TopicReferenceSettings.handlebars"),

        bindings: function() {
            return {
                treeId: {
                    converterFactory: Beef.TopicViewPicker.createConverterFactory("Select a Topic Tree"),
                    elAttribute: "data-value"
                },
                language: { converter: Beef.LanguagePicker.converter, elAttribute: "data-value" },
            }
        },

        onFirstRender() {
            Beef.TopicViewPicker.attach(this, ".topic-view", "treeId", {onlyOne: true, onlyTrees: true});
            Beef.LanguagePicker.attach(this, ".language", "language", { onlyOne: true });
        },

        editAttributes: function() {
            return ['treeId', 'language']
        }
    });
});