import {canShareDashboardLinks} from "@/app/Permissions";
import { getDashboardAccessMsg } from "./DashboardUtils"
import {showTip} from "@/app/help/tips/tips";
import EditDashboardSecurityDialog from "@/app/toplevel/dashboards/dialogs/EditDashboardSecurityDialog";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import {isString} from "@/app/utils/StringUtils";
import {account} from "@/app/utils/Account";

Beef.module("DashboardSettings").addInitializer(function(startupOptions) {

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/dashboards/DashboardSettings.handlebars"),

        attributes: { class: "dashboard-settings dialog" },

        regions: {
            colourSettingsRegion: ".colour-settings-region"
        },

        editAttributes: ['name', 'max-width', 'type', 'colour-palette', 'colour-palette-custom', 'colour-index',
            'colour-cycle-disabled', 'category', 'description', 'lastUpdatedBy', 'lastUpdated', 'privacy', 'resizable', 'ordinal',
            'created', 'createdBy', 'hasPublicLink', 'version' , 'showFoldLine', 'purchased', 'readTeamIds', 'writeTeamIds', 'ownerId',
            'font'],

        events: Object.assign({
            "click .max-width .btn": "changeMaxWidth",
            "click .type .btn": "changeType",
            "input .landing-order input": "changeLandingPageOrder",
            "change .landing-order input": "changeLandingPageOrder",
            "input input.description": "changeDescription",
            "change input.description": "changeDescription",
            "click .edit-json": "editJson",
            "click .access-control": "editAccessControl"
        }, Beef.SettingsDialog.View.prototype.events),

        modelEvents: {
            "change:showFoldLine": "showFoldLineChanged",
            "change:resizable": "resizableChanged",
            "change:hasPublicLink": "updateAccessControlView",
            "change:readTeamIds": "updateAccessControlView",
            "change:writeTeamIds": "updateAccessControlView",
            "change:privacy": "updateAccessControlView",
            "change:ownerId": "updateAccessControlView"
        },

        templateHelpers: function() {
            return {
                canShareDashboardLinks: canShareDashboardLinks(),
                noIpWhitelist: !account().dashboardLinkIpWhitelist || !account().dashboardLinkIpWhitelist.length
            }
        },

        bindings: function() {
            var bindings = {
                category: {
                    converterFactory: Beef.DashboardCategoryPicker.createConverterFactory("Dashboard folder"),
                    elAttribute: "data-value"
                },
                font: { converter: Beef.FontPicker.converter, elAttribute: "data-value" }
            };
            return Object.assign(bindings, Beef.SettingsDialog.View.prototype.bindings || {});
        },

        initialize: function(options) {
            Beef.SettingsDialog.View.prototype.initialize.call(this);
            if (!this.model.get('font')) this.model.set('font', account().font)
        },

        onFirstRender: function() {
            var w = this.model.get('max-width') || "16";
            $('.max-width .btn[data-value="' + w + '"]', this.$el).toggleClass("active", true);

            var type = this.model.get('type') || "NORMAL";
            $('.type .btn[data-value="' + type + '"]', this.$el).toggleClass("active", true);

            var description = this.model.get('description') || null;
            $('input.description', this.$el).val(description).trigger('change'); // the change trigger ensures the model and input match in cases where the db returned a falsy value (e.g. empty string) instead of null

            var landingPageOrder = parseInt(this.model.get('ordinal')) || null;
            $('.landing-order input', this.$el).val(landingPageOrder).trigger('change'); // ^ ^ ^
            if (type === "LANDING_PAGE"){
                $('.landing-order', this.$el).show();
            }

            this.colourSettingsRegion.show(new Beef.ColourSettings.View({model: this.model}));

            Beef.DashboardCategoryPicker.attach(this, ".category", "category");
            Beef.FontPicker.attach(this, ".font", "font", {onlyOne: true});

            this.updateView()

            setTimeout(function() {
                this.$('input[name=name]').select();
            }.bind(this));

            showTip("DASHBOARD_DESCRIPTIONS");
        },

        updateView: function() {
            this.updateAccessControlView()
        },

        showFoldLineChanged: function() {
            if (this.model.get('showFoldLine')) this.model.set("resizable", false)
        },

        resizableChanged: function() {
            if (this.model.get('resizable')) this.model.set("showFoldLine", false)
        },

        changeMaxWidth: function(ev) {
            var $t = $(ev.target);
            var maxWidth = $t.attr('data-value');
            this.setMaxWidth(maxWidth);
        },

        setMaxWidth: function(maxWidth) {
            maxWidth = "" + maxWidth;

            var $t = this.$('.max-width .btn[data-value="' + maxWidth + '"]');
            $('.max-width .btn', this.$el).toggleClass('active', false);
            $t.toggleClass("active", true);
            this.model.set('max-width', maxWidth);

            this.preview();

            var sectionModels = this.originalModel.get('sections').models;
            for(var i = 0; i < sectionModels.length; i++) {
                var widgetModels = sectionModels[i].get('widgets').models;
                for(var j = 0; j < widgetModels.length; j++) {
                    widgetModels[j].trigger('change:width', widgetModels[j]);
                }
            }
        },

        changeType: function(ev) {
            var $t = $(ev.target);
            $('.type .btn', this.$el).toggleClass('active', false);
            $t.toggleClass("active", true);
            this.model.set('type', $t.attr('data-value'));

            // Show and hide landing page order field accordingly
            // Set landing page order back to null if changing away from LANDING_PAGE type
            if ($t.attr('data-value') === 'LANDING_PAGE'){
                $('.landing-order', this.$el).show();
            } else {
                $('.landing-order', this.$el).hide();
                $('.landing-order input', this.$el).val(null).trigger('change');
            }
        },

        changeDescription: function(ev) {
            var s = ev.target.value;
            if (isString(s)) s = s.replace(/\s+/g, ' ').trim() || null;
            this.model.set('description', s);
        },

        changeLandingPageOrder: function(ev) {
            // coerce int
            var intValue = parseInt(ev.target.value) || null;
            $(ev.target).val(intValue);
            this.model.set('ordinal', intValue);

            var $validationEl = $(ev.target).parents('.control-group');
            this.model.isValid('ordinal') ?  $validationEl.removeClass('error') : $validationEl.addClass('error');
        },

        editJson: function(ev) {
            ev.preventDefault();

            var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
            popup.setTarget($(ev.target));

            var that = this;
            var endpoint = "accounts/" + this.originalModel.accountCode + "/reports/" + this.originalModel.id;
            var view = Beef.EditDashboardJson.createView(endpoint);
            view.on("close", function(){ popup.hide(); });
            popup.show(view);
        },

        async updateAccessControlView() {
            let o = await getDashboardAccessMsg(this.model.attributes)
            let $ac = this.$(".access-control")
            $ac.text('').append("<i class='" + o.icon + "'></i><span></span>")
            $ac.find("span").text(o.msg)
        },

        editAccessControl: function() {
            this._accessControlDialog = showDialog(EditDashboardSecurityDialog, {
                model: this.model,
                originalModel: this.originalModel,
                value: true
            })
        },

        onClose() {
            if (this._accessControlDialog) this._accessControlDialog._closeDialog()
        }
    });
});
