import * as b3js from "brandseyejs";
import {
    filterGetterFactory,
    formatNumber,
    formatPercentage,
    formatPercentageTooltip,
    formatSentiment,
    getCompareFilterGetter,
    getCompareGetter,
    getEffectiveFilter,
    initialiseRow,
    simpleGetterFactory,
    simpleSetterFactory,
    tagAndTopicDescriptionGetterFactory
} from "../FantasticUtilities";
import {createGroupExcluding, createSelectExcluding} from "../FantasticDataHelpers";
import {
    getDirectMessageSentimentScore,
    getOperationalSentimentFilter, getPublicSentimentScore,
    getReputationalSentimentFilter
} from "@/app/utils/Sentiment";
import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import {FANTASTIC_FIELDS} from "@/dashboards/widgets/fantasticchart/fields/Fields";
import {capitalise} from "@/app/utils/StringUtils";


/**
 * These are fields related to grouping by sentiment.
 */
export default {
    sentiment: {
        tooltipPreposition: 'have',
        tooltipPrepositionPercent: 'have',
        tooltipComparePreposition: 'with',
        tooltipMentionsWithVerb: 'with',
        formatTooltipX: function (sentiment) {
            switch (sentiment) {
                case -1:
                    return new Handlebars.SafeString("<span class='negative-sentiment'><i class='icon-circle'></i>negative sentiment</span>");
                case 0:
                    return new Handlebars.SafeString("<span class='neutral-sentiment'><i class='icon-circle'></i>neutral sentiment</span>");
                case 1:
                    return new Handlebars.SafeString("<span class='positive-sentiment'><i class='icon-circle'></i>positive sentiment</span>");
            }
            return sentiment;
        },
        noLimit: true,
        noMaxItems: true,
        hideOthers: true,
        hideUnknown: true,
        calculateMoe: true,
        setter: function (d, id, value) {
            d.sentiment = parseInt(value)
        },
        getter: simpleGetterFactory("sentiment"),
        filterGetter: filterGetterFactory("sentiment"),
        formatX: formatSentiment,
        formatY: formatSentiment,
        formatLabel: formatSentiment,
        scaleX: b3js.scaleDiscrete,
        colourFromX: function (d, defaultColour) {
            switch (d.sentiment) {
                case -1:
                    return b3js.colours.eighteen.sentiment.negative;
                case 0:
                    return b3js.colours.eighteen.sentiment.neutral;
                case 1:
                    return b3js.colours.eighteen.sentiment.positive;
                default:
                    console.warn("Unrecognised sentiment value", d.sentiment);
                    return defaultColour || b3js.colours.eighteen.sentiment.neutral;
            }
        },
        legendColours: {
            "negative sentiment": b3js.colours.eighteen.sentiment.negative,
            "neutral sentiment": b3js.colours.eighteen.sentiment.neutral,
            "positive sentiment": b3js.colours.eighteen.sentiment.positive
        },
        isSentiment: true,
        defaultSortOptions: {
            label: "Sentiment",
            field: "sentiment",
            order: "ascending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "ascending";

            const lhsX = lhs.sentiment;
            const rhsX = rhs.sentiment;

            return order === "ascending" ?  lhsX - rhsX : rhsX - lhsX;
        },
        // The goal of this function is to calculate margin of errors
        // for sentiment values.
        postProcess: function (data, model) {
            if (!data || !data.length) return data;

            for (const row of data) {
                // We cannot have unknown sentiment. Receiving unknown sentiment means
                // that some part of a query brought back no data, and we can safely set the
                // sentiment value to something else. In this case we use neutral.
                // This stops strange values from appearing in chart legends.
                if (row["sentiment"] === null) row["sentiment"] = 0;
            }

            const fields = FANTASTIC_FIELDS;
            const xAxis = model.get("xAxis") || "published";
            const yAxis = model.get("yAxis") || "mentionCount";
            const yField = fields[yAxis] || {};
            const compare = model.get("compare");


            // Figure out if we're grouping by anything, and calculate
            // MoE just for that group.
            let group = null;
            let isCompare = null;
            if (xAxis !== "sentiment") {
                group = xAxis;
                isCompare = false;
            }
            if (!group && compare && compare !== "sentiment") {
                group = compare;
                isCompare = true
            }
            const groupField = group && fields[group] || {};
            const groupFieldGetter = group && (isCompare ? getCompareGetter(group) : groupField.getter);
            let groupFieldFilterGetter = group && (isCompare ? getCompareFilterGetter(group) : groupField.filterGetter);

            // Some items may have a null filterGetter, in particular our data sets. In this case we want
            // to see if we can get some raw data to extend things with.
            if (groupFieldFilterGetter) {
                const oldGetter = groupFieldFilterGetter;
                groupFieldFilterGetter = (d) => {
                    let result = oldGetter(d);
                    if (result === null && groupField?.rawDataGetter) {
                        result =  groupField.rawDataGetter(d);
                    }

                    return result;
                }
            }


            let groupValues = new Set();
            let groupId = {};

            if (group) {
                // Find the values that we need to group over.
                for (let i = 0; i < data.length; i++) {
                    groupValues.add(groupFieldGetter(data[i]));
                    groupId[groupFieldGetter(data[i])] = groupFieldFilterGetter(data[i]);
                }
            }

            const filter = getEffectiveFilter(model);
            const attrs = convertFilterToAttrs(filter);
            const hidden = model.get('hidden') ?? [];
            let addSentiment = [-1, 0, 1];

            if (hidden.length) {
                const hiddenIds = hidden.map(d => d.id);
                addSentiment = addSentiment.filter(id => {
                    return !hiddenIds.some(hidden => hidden === id
                        || hidden?.startsWith(`${id}:`)
                        || hidden?.endsWith(`:${id}`)
                        || hidden?.indexOf(`:${id}:`) >= 0)
                });
            }


            if (attrs && attrs.sentiment) {
                const filterSentiment = attrs.sentiment.split(' ').map(s => parseInt(s));
                addSentiment = filterSentiment.map(s => {
                    switch (s) {
                        case -1: return -1;
                        case 1: return 0;
                        case 2: return 1;
                    }
                })
            }


            function calculate(g) {
                const present = new Set();

                // We want to add in the missing
                for (let i = 0; i < data.length; i++) {
                    let d = data[i];
                    if (group && g !== groupField.getter(d)) continue;

                    present.add(d.sentiment);
                }

                // We want to add in missing sentiment values
                addSentiment.forEach(function (val) {
                    if (present.has(val)) return;
                    let value = {
                        sentiment: val
                    };

                    if (yAxis === "mentionCount" || yAxis === "mentionPercent") {
                        value.mentionCount = 0;
                        value.mentionPercent = 0;
                    } else if (yAxis === "interactionCount" || yAxis === "interactionPercent") {
                        value.interactionCount = 0;
                        value.interactionPercent = 0;
                    } else {
                        yField.setter(value, 0);
                    }

                    if (g) {
                        value[group] = groupId[g];
                        initialiseRow(model, value);
                        if(value[group] && groupField.extendData) Object.assign(value, groupField.extendData(value[group], group, model))
                    }
                    data.push(value);
                });
            }

            if (!group) calculate();
            groupValues.forEach(calculate);
            return data;
        }
    },

    totalSentiment: {
        isSentiment: true,
        mayHaveNegativeValues: true,
        tooltipArticle: "a",
        name: "Net sentiment",
        chartName: "Net Sentiment",
        yLabel: "Net Sentiment",
        formatY: formatNumber,
        grouseAlias: ["sentimentVerifiedCount", "totalVerifiedSentiment", "totalVerifiedPositive", "totalVerifiedNegative"],
        setter: simpleSetterFactory("totalVerifiedSentiment"),
        getter: simpleGetterFactory("totalVerifiedSentiment"),
        defaultSortOptions: {
            label: "Net sentiment",
            field: "totalVerifiedSentiment",
            order: "descending"
        },
        colourFromY: function (d) {
            if (d.totalVerifiedSentiment < 0) return b3js.colours.eighteen.sentiment.negative;
            if (d.totalVerifiedSentiment > 0) return b3js.colours.eighteen.sentiment.positive;
            return b3js.colours.eighteen.sentiment.neutral;
        },
        extraFilterWhenY: function (d) {
            if (d.totalVerifiedSentiment < 0) return "sentiment = -1 and process is verified";
            if (d.totalVerifiedSentiment > 0) return "sentiment = 2 and process is verified";
            return "sentiment = 1 and process is verified";
        },
    },

    totalSentimentPercent: {
        isSentiment: true,
        mayHaveNegativeValues: true,
        tooltipArticle: "a",
        name: "% Net sentiment",
        chartName: "Percentage Net Sentiment",
        yLabel: {long: "% Net Sentiment", short: "% Net Sentiment"},
        formatY: (d) => formatPercentage(d, 0),
        formatLabel: (d) => formatPercentage(d, 1),
        formatTooltipY: formatPercentageTooltip,
        grouseAlias: ["sentimentVerifiedCount", "totalVerifiedSentiment", "totalVerifiedPositive", "totalVerifiedNegative"],
        isPercent: true,
        setter: simpleSetterFactory("totalSentimentPercent"),
        getter: simpleGetterFactory("totalSentimentPercent"),
        defaultSortOptions: {
            label: "Percentage net sentiment",
            field: "totalSentimentPercent",
            order: "descending"
        },
        sorter: function (lhs, rhs) {
            const lhsP = lhs["totalSentimentPercent"];
            const rhsP = rhs["totalSentimentPercent"];

            if (lhsP < rhsP) return -1;
            if (rhsP < lhsP) return 1;

            const lhsT = lhs["totalVerifiedSentiment"];
            const rhsT = rhs["totalVerifiedSentiment"];

            if (lhsT < rhsT) return -1;
            if (rhsT < lhsT) return 1;
            return 0;
        },
        gradientFn: renderSentimentGradient,
        colourFromY: function (d) {
            if (d.totalSentimentPercent < 0) return b3js.colours.eighteen.sentiment.negative;
            if (d.totalSentimentPercent > 0) return b3js.colours.eighteen.sentiment.positive;
            return b3js.colours.eighteen.sentiment.neutral;
        },
        extraFilterWhenY: ignore => "process is verified"
    },

    posAndNegPercent: {
        isSentiment: true,
        mayHaveNegativeValues: true,
        tooltipArticle: "a",
        name: "% Sentiment",
        chartName: "Percentage Sentiment",
        yLabel: {long: "% Sentiment", short: "% Sentiment"},
        formatY: (d) => formatPercentage(d, 0),
        formatLabel: (value, d) => {
            // This is a bit of a hack. We want to show net % here, not the actual
            // y value being passed through.
            const val = d.totalSentimentPercent ?? value;
            return formatPercentage(val, 1, d);
        },
        formatTooltipY: formatPercentageTooltip,
        grouseAlias: ["sentimentVerifiedCount", "totalVerifiedSentiment", "totalVerifiedPositive", "totalVerifiedNegative"],
        isPercent: true,
        setter: simpleSetterFactory("totalNegativePercent"),
        getter: d => {
            const val = d.totalNegativePercent
            return val === null || val === undefined || val === "NA" ? "UNKNOWN" : -val
        },
        getter2: simpleGetterFactory("totalPositivePercent"),
        setter2: simpleSetterFactory("totalPositivePercent"),
        defaultSortOptions: {
            label: "Percentage negative",
            field: "totalNegativePercent",
            order: "descending"
        },
        sorter: function (lhs, rhs) {
            let diff = lhs["totalSentimentPercent"] - rhs["totalSentimentPercent"]
            if (!diff) diff = lhs["totalVerifiedSentiment"] - rhs["totalVerifiedSentiment"]
            return diff ? diff < 0 ? -1 : +1 : 0;
        },
        gradientFn: renderSentimentGradient,
        colourFromY: function (d) {
            if (d.totalSentimentPercent < 0) return b3js.colours.eighteen.sentiment.negative;
            if (d.totalSentimentPercent > 0) return b3js.colours.eighteen.sentiment.positive;
            return b3js.colours.eighteen.sentiment.neutral;
        },
        extraFilterWhenY: ignore => "process is verified",
        tooltipGetter: d => d.totalSentimentPercent
    },

    positivePercent: {
        isSentiment: true,
        tooltipArticle: "a",
        name: "% Positive",
        chartName: "Percentage Positive",
        yLabel: {long: "% Positive", short: "% Positive"},
        formatY: (d) => formatPercentage(d, 0),
        formatLabel: (d) => formatPercentage(d, 1),
        formatTooltipY: formatPercentageTooltip,
        grouseAlias: ["sentimentVerifiedCount", "totalVerifiedSentiment", "totalVerifiedPositive", "totalVerifiedNegative"],
        isPercent: true,
        setter: simpleSetterFactory("totalPositivePercent"),
        getter: simpleGetterFactory("totalPositivePercent"),
        defaultSortOptions: {
            label: "Percentage positive",
            field: "totalPositivePercent",
            order: "descending"
        },
        sorter: function (lhs, rhs) {
            let diff = lhs["totalPositivePercent"] - rhs["totalPositivePercent"]
            if (!diff) diff = lhs["totalVerifiedPositive"] - rhs["totalVerifiedPositive"]
            return diff ? diff < 0 ? -1 : +1 : 0;
        },
        colourFromY: () => b3js.colours.eighteen.sentiment.positive,
        extraFilterWhenY: () => "sentiment = 2 and process is verified"
    },

    negativePercent: {
        isSentiment: true,
        tooltipArticle: "a",
        name: "% Negative",
        chartName: "Percentage Negative",
        yLabel: {long: "% Negative", short: "% Negative"},
        formatY: (d) => formatPercentage(d, 0),
        formatLabel: (d) => formatPercentage(d, 1),
        formatTooltipY: formatPercentageTooltip,
        grouseAlias: ["sentimentVerifiedCount", "totalVerifiedSentiment", "totalVerifiedPositive", "totalVerifiedNegative"],
        isPercent: true,
        setter: simpleSetterFactory("totalNegativePercent"),
        getter: simpleGetterFactory("totalNegativePercent"),
        defaultSortOptions: {
            label: "Percentage negative",
            field: "totalNegativePercent",
            order: "descending"
        },
        sorter: function (lhs, rhs) {
            let diff = lhs["totalNegativePercent"] - rhs["totalNegativePercent"]
            if (!diff) diff = lhs["totalVerifiedNegative"] - rhs["totalVerifiedNegative"]
            return diff ? diff < 0 ? -1 : +1 : 0;
        },
        colourFromY: () => b3js.colours.eighteen.sentiment.negative,
        extraFilterWhenY: () => "sentiment = -1 and process is verified"
    },

    sentimentSamplePercent: {
        isSentiment: true,
        tooltipArticle: "a",
        name: "% Verified",
        chartName: "Sentiment Sample Percentage",
        yLabel: {long: "% Verified", short: "% Verified"},
        formatY: (d) => formatPercentage(d, 0),
        formatLabel: (d) => formatPercentage(d, 1),
        formatTooltipY: formatPercentageTooltip,
        grouseAlias: ["mentionCount", "sentimentVerifiedCount"],
        isPercent: true,
        setter: simpleSetterFactory("sentimentVerifiedPercent"),
        getter: simpleGetterFactory("sentimentVerifiedPercent"),
        defaultSortOptions: {
            label: "Sentiment verified %",
            field: "sentimentVerifiedPercent",
            order: "descending"
        },
        sorter: function (lhs, rhs) {
            let diff = lhs["sentimentVerifiedPercent"] - rhs["sentimentVerifiedPercent"]
            if (!diff) diff = lhs["sentimentVerifiedCount"] - rhs["sentimentVerifiedCount"]
            return diff ? diff < 0 ? -1 : +1 : 0;
        },
        extraFilterWhenY: ignore => "process is verified"
    },

    sentimentSampleSize: {
        isSentiment: true,
        tooltipArticle: "a",
        name: "# Verified",
        chartName: "Sentiment Sample Size",
        yLabel: {long: "# Verified", short: "# Verified"},
        grouseAlias: ["mentionCount", "sentimentVerifiedCount"],
        isPercent: true,
        setter: simpleSetterFactory("sentimentVerifiedCount"),
        getter: simpleGetterFactory("sentimentVerifiedCount"),
        defaultSortOptions: {
            label: "Sentiment verified count",
            field: "sentimentVerifiedCount",
            order: "descending"
        },
        sorter: function (lhs, rhs) {
            let diff = lhs["sentimentVerifiedCount"] - rhs["sentimentVerifiedCount"]
            return diff ? diff < 0 ? -1 : +1 : 0;
        },
        extraFilterWhenY: ignore => "process is verified"
    },

    functionalSentiment: {
        name: "Operational vs reputational",
        isMultiple: true,
        isConstructed: true,
        noLimit: true,
        noMaxItems: true,
        hideOthers: true,
        hideUnknown: true,
        setter: function (d, id, value) {
            d['functionalSentiment.id'] = id;
            d['functionalSentiment.name'] = value;
        },
        getter: simpleGetterFactory("functionalSentiment.id"),
        rawDataGetter(d) {return d._functionalSentiment;},
        rawDataSetter(d, data) {return d._functionalSentiment = data;},
        scaleX: b3js.scaleDiscrete,
        tooltipPreposition: 'have',
        tooltipPrepositionPercent: 'have',
        tooltipComparePreposition: 'related to',
        tooltipMentionsWithVerb: 'related to',
        descriptionGetter: tagAndTopicDescriptionGetterFactory("functionalSentiment"),
        formatTooltipX(d) {
            switch(d) {
                case 'reputational': return "reputational issues";
                case 'operational': return "operational issues";
                case 'public': return "public mentions";
                case 'direct': return "direct messages";
                default:
                    return d;
            }
        },
        filterGetter() { return null },
        extraFilter(d) {
            if (d._functionalSentiment && d._functionalSentiment.filter) return d._functionalSentiment.filter;
            throw new Error("Unable to find filter for high/low priority metric")
        },
        extendData(d) {
            return {
                "functionalSentiment.id": d.id,
                "functionalSentiment.name": d.label,
                "functionalSentiment.description": d.description,
                "_functionalSentiment": d
            };
        },
        formatX(d) {
            switch(d) {
                case 'direct': return "Direct Messages";
                default:
                    return capitalise(d);
            }
        },
        defaultSortOptions: {
            label: "Operational vs reputational",
            field: "_functionalSentiment.ordinal",
            order: "descending"
        },
        sorter: function (lhs, rhs, order) {
            order ??= "descending";

            const lhsScore = lhs._functionalSentiment && lhs._functionalSentiment.ordinal || 0;
            const rhsScore = rhs._functionalSentiment && rhs._functionalSentiment.ordinal || 0;

            return order === "descending" ? rhsScore - lhsScore : lhsScore - rhsScore;
        },
        async getData(model, query, groupBy, ignore, ignoreY, getData) {
            const g = createGroupExcluding(groupBy, ignore, "functionalSentiment");
            const s = createSelectExcluding(query, ignore, "functionalSentiment").join(',');

            const [publicFilter, directMessageFilter, operationalFilter, reputationalFilter] =
                await Promise.all([
                        getPublicSentimentScore(),
                        getDirectMessageSentimentScore(),
                        getOperationalSentimentFilter(),
                        getReputationalSentimentFilter()
                    ]
                );

            const [publicSentiment, directSentiment, operational, reputational] = await Promise
                .all([
                    ...([
                        {id: 'public', filter: publicFilter, ordinal: 4, label: "Public", description: "Public sentiment of your data set"},
                        {id: 'direct', filter: directMessageFilter, ordinal: 3, label: "Direct Messages", description: "Net sentiment from direct messages"},
                        {id: 'operational', filter: operationalFilter, ordinal: 2, label: "Operational", description: "Net sentiment related to Customer Experience"},
                        {id: 'reputational', filter: reputationalFilter, ordinal: 1, label: "Reputational", description: "Net sentiment unrelated to Customer Experience"}
                        ]
                        .map(filterInfo => {
                            const q = Object.assign({}, query, {
                                filter: filterInfo.filter ? `(${query.filter}) and (${filterInfo.filter} )` : query.filter,
                                groupBy: g.join(','),
                                select: s
                            });

                            if (!g.length) delete q.groupBy;

                            return getData(q, g, ignore, ignoreY)
                                .then(d => Array.isArray(d) ? d : [d])
                                .then(d => d.map(r => Object.assign({functionalSentiment: filterInfo}, r)))
                                .then(d => d.length ? d : [initialiseRow(model, {functionalSentiment: filterInfo})])
                        }))
                ]);

            return [...publicSentiment, ...directSentiment, ...operational, ...reputational].flat();
        }
    }
}

let lastGradientId = 0;

/**
 * Renders a sentiment gradient to match the scale and returns its id. Neg is red and pos is blue more or less.
 */
function renderSentimentGradient(sel, scale, geometry) {
    let type = geometry.name()
    let id = "sg-" + ++lastGradientId
    let neg = b3js.colours.eighteen.sentiment.negative
    let pos = b3js.colours.eighteen.sentiment.positive
    let range = scale.range()
    let height = type === "BAR_CHART" ? range[1] : range[0]
    let domain = scale.domain()
    let min = +domain[0]
    let max = +domain[1]

    let g = sel.append("linearGradient").attr("id", id).attr("gradientUnits", "userSpaceOnUse")
    if (type === "BAR_CHART") g.attr("x1", 0).attr("x2", height).attr("y1", 0).attr("y2", 0)
    else if (type === "HISTOGRAM" || type === "COLUMN_CHART") g.attr("x1", 0).attr("x2", 0).attr("y1", 0).attr("y2", height)
    else g.attr("x1", 0).attr("x2", 0).attr("y1", height).attr("y2", 0)

    let zeroPoint = scale(0) / height * 100
    if (type !== "BAR_CHART") zeroPoint = 100 - zeroPoint
    let opacity = type === "LINE" ? 1.0 : 0.8
    let zeroGap = type === "LINE" ? 0.5 : 0
    if (min < 0) {
        g.append("stop").attr("offset", "0").attr("stop-color", neg).attr("stop-opacity", "1.0")
        g.append("stop").attr("offset", (zeroPoint < 100 ? zeroPoint - zeroGap : 100) + "%")
            .attr("stop-color", neg).attr("stop-opacity", opacity)
    }
    if (max >= 0) {
        g.append("stop").attr("offset", (zeroPoint > 0 ? zeroPoint + zeroGap : 0) + "%")
            .attr("stop-color", pos).attr("stop-opacity", opacity)
        g.append("stop").attr("offset", "100%").attr("stop-color", pos).attr("stop-opacity", "1.0")
    }

    // separate gradient for the legend so it shows pos and neg equally
    g = sel.append("linearGradient").attr("id", id + "-legend").attr("x1", 1).attr("x2", 0).attr("y1", 1).attr("y2", 0)
    g.append("stop").attr("offset", "0").attr("stop-color", neg).attr("stop-opacity", "1.0")
    g.append("stop").attr("offset", "49%").attr("stop-color", neg).attr("stop-opacity", "1.0")
    g.append("stop").attr("offset", "51%").attr("stop-color", pos).attr("stop-opacity", "1.0")
    g.append("stop").attr("offset", "100%").attr("stop-color", pos).attr("stop-opacity", "1.0")

    return id
}
