<script>
import {isMashAdmin} from "@/app/Permissions";
import BeButton from "@/components/buttons/BeButton.vue";
import {mapActions, mapState} from "vuex";
import LocationInput from "@/components/inputs/LocationInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DialogBox from "@/components/DialogBox.vue";
import {notifyUser, notifyUserOfError} from "@/app/framework/notifications/Notifications";
import {showWhenDialog} from "@/app/framework/dialogs/Dialog";

export default {
    name: "EditFeed",
    components: {DialogBox, TextInput, LocationInput, BeButton},

    props: ['feed'],

    data() {
        return {
            location: '',

            formGroup: {
                name: this.feed?.name ? this.feed?.name : '',
                link: this.feed?.link ? this.feed?.link : '',
                location: this.feed?.location ? [this.feed?.location] : [],
                includeContentHashInURI: this.feed?.includeContentHashInURI ? this.feed?.includeContentHashInURI : false,
                includeFeedNameInContent: this.feed?.includeFeedNameInContent ? this.feed?.includeFeedNameInContent : false,
                sendMentionsToAllAccounts: this.feed?.sendMentionsToAllAccounts ? this.feed?.sendMentionsToAllAccounts : false,
            },

            valid: {
                name: true,
                link: true
            },

            isValid: false,
            saving: false,

            rules: {
                linkRule: [url => {
                    const prefix = url.slice(0, url.indexOf(':'));
                    if (prefix !== 'http' && prefix !== 'https' || !URL.canParse(url)) {
                        return 'Invalid URL'
                    }
                }]
            },

        }
    },

    computed: {
        ...mapState(['account']),

        disableSaveBtb() {
            return !this.valid.name || !this.valid.link || this.saving;
        },

        isNewFeed() {
            return !Object.keys(this.feed).length;
        }
    },

    methods: {
        ...mapActions(['editFeed', 'createFeed', 'deleteFeed']),
        isMashAdmin,

        emitClose() {
            this.$emit('close');
        },

        setNameValid(v) {
            this.valid.name = v;
        },

        setLinkValid(v) {
            this.valid.link = v;
        },

        async onOkClick() {
            this.saving = true;

            try {
                if (!this.isNewFeed) {
                    const payload = {...this.formGroup, id: this.feed.id, location: this.formGroup.location[0]?.id};
                    await this.editFeed(payload);
                    this.emitClose();

                    notifyUser(`Feed "${this.formGroup.name}" updated.`);
                } else {
                    const payload = {...this.formGroup, location: this.formGroup.location[0]?.id, deleted: false};
                    await this.createFeed(payload);
                    this.emitClose();

                    notifyUser(`Feed "${this.formGroup.name}" created.`);
                }
            } catch (e) {
                console.warn('An error occurred while fetching feeds: ', e);
                notifyUserOfError(`We were unable to ${!this.isNewFeed ? "update" : "create"} this feed, please try again or contact DataEQ support.`);
            } finally {
                this.saving = false;
            }

        },

        async onDeleteClick() {
            this.saving = true;

            showWhenDialog(`Are you sure you want to delete this feed?.`, "Delete feed?")
                .then(async () => {
                    try {
                        await this.deleteFeed(this.feed);
                        this.emitClose();
                        notifyUser(`Feed "${this.formGroup.name}" deleted.`);
                    } catch (e) {
                        console.warn('An error occurred while deleting feed: ', e);
                        notifyUserOfError("We were unable to delete this feed, please try again or contact DataEQ support.");
                    } finally {
                        this.saving = true;
                    }
                });
        }
    },

}
</script>

<template>
    <dialog-box modal title="Edit the custom data source" class="edit-feed-dialog" width="500px" @close="emitClose()">
        <div>
            <div class="edit-feed-dialog__input-fields-container">
                <text-input label="Link to RSS Feed" required width="100%" name="link" v-model="formGroup.link" placeholder="URL for RSS feed" @valid="setLinkValid" :rules="rules.linkRule"/>

                <text-input required label="Name" dir="auto" width="100%" type="text" name="name" placeholder="Feed name" v-model="formGroup.name" @valid="setNameValid"/>

                <div>
                    <label>Location</label>
                    <location-input label="Location" only-one class="input" v-model="formGroup.location" :disabled="saving"/>
                </div>
            </div>

            <div class="edit-feed-dialog__checkbox-controls-container">
                <div>
                    <label class="checkbox">
                        <input type="checkbox" v-model="formGroup.includeFeedNameInContent"> Include the name of the feed in each mention
                    </label>
                </div>

                <template v-if="isMashAdmin">
                    <div>
                        <label class="checkbox"
                               title="Tick this box if the feed does not return unique URLs for items">
                            <input type="checkbox" v-model="formGroup.includeContentHashInURI"> Include hash of mention content in the URI
                        </label>
                    </div>

                    <div class="controls">
                        <label class="checkbox">
                            <input type="checkbox" v-model="formGroup.sendMentionsToAllAccounts"> Send mentions from this feed to all accounts
                        </label>
                    </div>
                </template>
            </div>
        </div>

        <template #buttons>
            <div class="edit-feed-dialog__footer">
                <be-button v-if="!isNewFeed" danger @click="onDeleteClick()" :disabled="saving">Delete</be-button>
                <div class="edit-feed-dialog__footer-btns">
                    <be-button @click="emitClose">Cancel</be-button>
                    <be-button class="cancel-btn" :primary="true" :disabled="disableSaveBtb" @click="onOkClick">Ok</be-button>
                </div>
            </div>
        </template>
    </dialog-box>
</template>

<style scoped lang="sass">

.edit-feed-dialog

    &__input-fields-container
        display: flex
        flex-direction: column
        row-gap: 10px

    &__checkbox-controls-container
        display: flex
        flex-direction: column
        row-gap: 5px
        margin-top: 20px

    &__footer
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

    &__footer-btns
        display: flex
        flex-direction: row
        gap: 5px
        margin-left: auto

</style>