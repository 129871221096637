<template>
    <section v-if="isInDevEnvironment" class="card-show-in-dev">
        <slot>
            Put your content here
        </slot>
    </section>
</template>

<script>/**
 * This component allows you to easily have html elements shown only in the development
 * environment, and not in production.
 */

import VuexStore from "@/store/vuex/VuexStore";
import {mapState} from "vuex";

export default {
    store: VuexStore,
    props: {
        staffOnly: {                // only show to staff accounts.
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapState(['account', 'user']),

        isInDevEnvironment() {
            if (this.staffOnly && !this.user.admin) return false;
            return this.account.dev;
        }
    }
}

</script>

<style scoped lang="sass">
.card-show-in-dev
    color: var(--be-colour-text)
    background: linear-gradient(to right, lightblue, #aeffff)
    outline: none
    box-shadow: 3px 3px 3px black
    border: 1px solid #999
    border-radius: 3px
    width: calc(100% - 20px)
    height: calc(100% - 40px)
    box-sizing: border-box
    margin: 20px auto 20px auto
    padding: 10px

    & ::v-deep
        --be-button-link-colour: black

        .be-tag
            color: white

</style>