<template>
    <add-profile-dialog class="whatsapp-add-profile-dialog"
                        :profile-type="profileTypes.whatsapp"
                        :enable-next-btn="enableNext"
                        next-btn-text="Save"
                        :show-prev-btn="false"
                        :cur-stage="curStage"
                        :total-stages="totalStages"
                        :show-next-btn="!saving"
                        @close="$emit('close')"
                        @next="next">
        <template #workflow>
            <div key="1" v-if="!saving" class="whatsapp-add-profile-dialog__config">
                <h4>Configure your <i class="symbol-whatsapp"></i> WhatsApp Profile</h4>
                <text-input
                    class="whatsapp-add-profile-dialog__text-input"
                    type="text"
                    placeholder="Profile Name"
                    label="WhatsApp Profile Name"
                    :required="true"
                    :numeric="false"
                    @valid="setNameValid"
                    v-model="profileName"
                    :rules="[ nameValidRule ]"
                />
                <text-input
                    class="whatsapp-add-profile-dialog__text-input"
                    type="text"
                    placeholder="Telephone Number"
                    label="WhatsApp Telephone Number"
                    :required="true"
                    :numeric="false"
                    v-model="profileNumber"
                    @valid="setNumberValid"
                    :rules="[ numberValidRule ]"
                />
                <text-input
                    class="whatsapp-add-profile-dialog__text-input"
                    type="text"
                    placeholder="Integration ID"
                    label="WhatsApp Integration ID"
                    :required="true"
                    :numeric="false"
                    v-model="profileIntegrationId"
                    @valid="setIntegrationIdValid"
                    :rules="[ integrationValidRule ]"
                />
                <div style="height: 100px">
                    <text-input
                        type="text"
                        placeholder="API Key"
                        label="WhatsApp API Key"
                        :required="false"
                        :numeric="false"
                        v-model="profileApiKey"
                    />
                    <p
                        v-if="!canSendReplies">
                        An API key is required in order for replies to be sent using DataEQ Engage.
                    </p>
                </div>


                <div>
                    <label>Add phrase to brand</label>
                    <div v-if="!profileBrands.length">
                        Click <a :class="saving ? 'disabled' : ''" style="cursor: pointer" @click="openBrandPicker">here</a> to select brands to phrase match this profile to
                    </div>
                    <div v-else>
                        <div class="whatsapp-add-profile-dialog__brands-list dark-scrollbars dark-scrollbars--visible">
                            <ul>
                                <li v-for="brand in profileBrands" :key="brand.name">
                                    {{ brand.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <saving-item key="2" v-if="saving" :saving-message="savingMessage" :save-complete="saveComplete" :save-error="saveError" :save-error-message="saveErrorMessage">
                <template #additionalActions>
                    <h4 style="text-align: center; margin-top: 50px">Click <a style="cursor: pointer" @click="reset">here</a> to add another <i class="symbol-whatsapp"></i> WhatsApp number</h4>
                </template>
            </saving-item>
        </template>

    </add-profile-dialog>
</template>

<script>
import AddProfileDialog from "@/setup/profiles/add-profile/AddProfileDialog";
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";
import TextInput from "@/components/inputs/TextInput";
import BrandPickerDialog from "@/app/framework/dialogs/brand-picker/BrandPickerDialog";
import {mapActions, mapState} from "vuex";
import VuexStore from "@/store/vuex/VuexStore";
import {mash} from "@/store/Services";
import {showDialogComponent as showDialog} from "@/app/framework/dialogs/DialogUtilities";
import SavingItem from "@/setup/SavingItem";
import {phraseNeedsQuotes} from "@/app/utils/Phrases";

const CLICKATELL_APP_ID = 46;

export default {
    name: "WhatsAppAddProfileDialog",
    components: {SavingItem, TextInput, AddProfileDialog},
    store: VuexStore,
    mixins: [addProfileMixins],

    data: function() {
        return {
            profileName: "",
            profileNumber: "",
            profileIntegrationId: "",
            profileApiKey: "",
            savingMessage: "",
            profileBrands: [],
            nameValid: true,
            numberValid: false,
            integrationIdValid: false,
            tempProfiles: [],
            curStage: 1,
            totalStages: 1,
        }
    },


    created() {
        this.totalStages = Object.keys(this.addProfileStages.whatsapp.managed).length;
        this.refreshBrands();
    },

    computed: {
        ...mapState('profiles', ['profiles']),

        canSendReplies() {
            return this.profileApiKey !== "";
        },

        enableNext() {
            return this.nameValid && this.numberValid && this.integrationIdValid;
        }
    },

    methods: {
        ...mapActions('profiles', ['createProfile', 'updateProfile', 'refreshProfiles']),
        ...mapActions(['refreshBrands']),

        setNameValid(e) {
            this.nameValid = e;
        },

        setNumberValid(e) {
            this.numberValid = e;
        },

        setIntegrationIdValid(e) {
            this.integrationIdValid = e;
        },

        async next() {
            this.curStage++;

            if (this.curStage === this.totalStages)  {
                // first create the profile, then update the profile with auth
                try {
                    this.saving = true;

                    await this.savePhrases();

                    this.savingMessage = "Creating profile...";
                    // create profile
                    let profile = {
                        type: this.profileTypes.whatsapp,
                        name: this.profileName,
                        handle: this.profileName,
                        handleId: this.profileNumber,
                    }

                    this.savingMessage = "Authorising profile...";
                    await this.createProfile(profile);

                    let addedProfile = this.profiles.find(p => p.name === profile.name && p.type === this.profileTypes.whatsapp);

                    if (addedProfile) {
                        // update profile with auth
                        profile.id = addedProfile.id;
                        profile.token = this.profileIntegrationId;
                        profile.tokenSecret = this.profileApiKey;
                        profile.apiAppId = CLICKATELL_APP_ID;

                        await this.updateProfile(profile);

                        await this.refreshProfiles(true);
                        this.saveComplete = true;
                    } else {
                        this.saveError = true;
                    }
                } catch (e) {
                    console.error("Error saving whatsapp profile: ", e);
                    this.saveError = true;
                }
            }
        },

        async savePhrases() {
            if (this.profileBrands.length) {
                let phrases = [];
                this.savingMessage = "Saving phrases...";

                // prepare phrases
                this.profileBrands.forEach(brand => {
                    // only allow adding phrases to brands that aren't imported
                    if (!brand.importedFromAccount) {
                        let quoteHandle = phraseNeedsQuotes(this.profileName);
                        let handle = this.profileName;

                        let handlePhrase = {
                            query: quoteHandle === true ? `"${handle}"` : handle,
                            active: true, brandId: brand.id, deleted: false
                        };
                        phrases.push(handlePhrase);

                        let quoteHandleId = phraseNeedsQuotes(this.profileNumber);
                        let handleIdPhrase = {
                            query: quoteHandleId === true ? `"${this.profileNumber}"` : this.profileNumber,
                            active: true, brandId: brand.id, deleted: false
                        };
                        phrases.push(handleIdPhrase);
                    }
                });

                // save phrases
                await mash.post(`/rest/accounts/${this.account.code}/phrases`, phrases);
                this.refreshBrands(true);
            }
        },

        nameValidRule(value) {
            let existingProfile = this.profiles.find(profile => profile.type === this.profileTypes.whatsapp && (profile.handle === value || profile.name === value));

            this.nameValid = !existingProfile;

            return existingProfile ? "A WhatsApp profile with that name already exists on this account" : true;
        },

        numberValidRule(value) {
            let existingProfile = this.profiles.find(profile => profile.type === this.profileTypes.whatsapp && profile.handleId === value);

            this.numberValid = !existingProfile;

            return existingProfile ? "A WhatsApp profile with that number already exists on this account" : true;
        },

        integrationValidRule(value) {
            let existingProfile = this.profiles.find(profile => profile.type === this.profileTypes.whatsapp && profile.token === value);

            this.integrationIdValid = !existingProfile;

            return existingProfile ? "A WhatsApp profile with that number already exists on this account" : true;
        },

        openBrandPicker() {
            let brandPicker = showDialog(BrandPickerDialog, {onlyOne: false, mustHaveOne: true});

            brandPicker.$on('selected', selectedBrands => {
                selectedBrands.forEach(brand => {
                    this.profileBrands.push(brand);
                });
            });
        },

        reset() {
            this.saving = false;
            this.saveComplete = false;
            this.profileName = "";
            this.profileNumber = "";
            this.profileIntegrationId = "";
            this.profileApiKey = "";
            this.profileBrands = [];
            this.curStage = 1;
        }
    }
}
</script>

<style scoped lang="sass">

.whatsapp-add-profile-dialog

    &__config
        display: flex
        flex-direction: column
        row-gap: 5px

    &__text-input
        height: 80px

    &__brands-list
        border: 1px solid #888
        border-radius: 4px
        max-height: 80px
        overflow-y: auto

        ul
            list-style: none
            margin: 0

        li
            padding: 0 5px
            line-height: 25px

            &:nth-child(odd)
                background: #666

            &:nth-child(even)
                background: #3c3c3c

            &:last-of-type
                margin-bottom: 0

    ::v-deep input
        width: 80%


</style>