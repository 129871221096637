import {mash} from "@/store/Services";
import {Phrase} from "@/app/utils/types";


let phrasePromise = null;
const PHRASE_PARENT = new Phrase();

export const PhraseMutations = {
    setPhrases: (state, value) => {
        if (value?.length) value.forEach(t => t.__proto__ = PHRASE_PARENT);
        state.phrases = value
    },
};

export const PhraseGetters = {
    idToPhrase: state => {
        const map = new Map();
        if (!state.phrases) return map;

        for (const phrase of state.phrases) {
            map.set(phrase.id, phrase);
        }
        return map
    },
};


export const PhraseActions = {
    async refreshPhrases({state, commit}, forceRefresh) {
        forceRefresh ??= false;

        if (!state.account?.code) return;
        if (state.phrases && !forceRefresh) return;
        if (phrasePromise) return phrasePromise;

        phrasePromise = mash.get("/rest/accounts/" + state.account.code + "/phrases");
        try {
            const res = await phrasePromise;
            const phrases = res.data;

            await commit('setPhrases', phrases);
        } finally {
            phrasePromise = null
        }
    }
};