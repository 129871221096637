/**
 * A module for showing the title of a chart.
 */
Beef.module("SearchJobChartTitle").addInitializer(function() {
    this.View = Backbone.Marionette.ItemView.extend({
        attributes: { class: "title" },

        template: require("@/historical-search/chart/SearchJobChartTitle.handlebars"),

        templateHelpers: function() {
            return {
                chartError: Beef.SearchJob.hasErrors(this.model.get("job"))
            }
        },

        update: function(job, chart) {
            var attrs = {};
            if (job) {
                attrs.job = job;
            }
            if (chart) {
                attrs.chart = chart;
            }
            if (Object.keys(attrs).length > 0) {
                this.model.set(attrs, { silent: true });
                this.render();
            }
        },

        onRender: function() {
            var chart = this.model.get("chart");
            if (chart) {
                chart.initTitleButtons();
            }
        }
    });
});
