<template>
    <section class="be-inline-text-input" @click.stop tabindex="-1" ref="container">
        <input type="text" v-model="tempValue"
               :placeholder="placeholder"
               @keydown.enter.stop="okWithEnter"
               @keydown.esc.stop="cancel"
               @keydown="$emit('keydown', $event)"
               @mousedown.stop="ignoreClick"
               @focus="$emit('focus', $event)"
               :disabled="disabled"
               :required="textRequired"
               ref="input"
        >
        <be-button v-if="!hideControls" link @click="cancel" :disabled="disabled">Cancel</be-button>
        <be-button v-if="!hideControls" primary @click="ok()" :disabled="disabled || disableDone || (textRequired && (value === '' || tempValue === ''))">Done</be-button>
    </section>
</template>

<script>
    import BeButton from "../buttons/BeButton";

    export default {
        components: {BeButton},
        props: {
            value: {
                type: String,
                required: true
            },
            placeholder: String,
            disabled: Boolean,
            disableDone: Boolean,    // Whether to disable just the done button
            signalForAll: Boolean,   // Whether input should be emitted for every edit. You usually don't want this.
            hideControls: Boolean,   // Whether the cancel and done buttons should be hidden by default.
            ignoreBlur: Boolean,     // Whether blur's should be ignored for signaling an OK value.
            textRequired: Boolean,   // Whether text should be required for the input,
            autofocus: Boolean       // Whether this should get focus automagically
        },

        data() {
            return {
                tempValue: this.value,
                containerClicked: false
            }
        },

        mounted() {
            const container = this.$refs.container;

            if (container && !this.ignoreBlur) {
                container.addEventListener('blur', this.containerBlurHandler , true);
                container.addEventListener('mousedown', this.containerMouseDownHandler);
            }

            if (this.autofocus) {
                setTimeout(() => this.focus());
            }
        },

        beforeDestroy() {
            const container = this.$refs.container;

            if (container) {
                container.removeEventListener('blur', this.containerBlurHandler);
                container.removeEventListener('mousedown', this.containerMouseDownHandler);
            }
        },

        watch: {
            value(val) {
                this.tempValue = val;
            },

            tempValue(val) {
                if (this.signalForAll) this.$emit('input', val);
            }
        },

        methods: {
            ok(withEnter) {
                this.containerClicked = true;
                this.$emit('input', this.tempValue);
                this.$emit('ok', !!withEnter);
                this.$emit('close');
            },

            cancel() {
                this.containerClicked = true;
                this.$emit('cancel');
                this.$emit('close');
            },

            okWithEnter() {
                if (this.disableDone) return;
                this.ok(true);
            },

            focus() {
                this.$refs.input.focus();
            },

            ignoreClick() {
                this.containerClicked = false;
            },

            containerMouseDownHandler() {
                this.containerClicked = true;
            },

            containerBlurHandler() {
                if (this.containerClicked) {
                    this.containerClicked = false;
                    return;
                }

                // The user has moved away from this control. Take it as the action being completed (unless the done button is disabled).
                if (!this.disabled && !this.disableDone && !(this.textRequired && (this.value === '' || this.tempValue === ''))) {
                    this.ok();
                } else {
                    this.cancel();
                }
            }
        }
    }
</script>

<style scoped lang="sass">
    .be-inline-text-input
        width: 100%
        display: flex
        align-items: center
        input
            box-sizing: border-box
            flex: 1
            height: 24px
            margin: 0
        .be-button
            width: 60px
        & > *
            margin-left: 5px
        &:first-child
            margin-left: 0
</style>