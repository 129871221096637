<template>
    <dialog-box modal @close="$emit('close')" width="700px" class="add-profile-dialog">

        <template #title>
            <h5>
                <i :class="getProfileIcon(profileType)"></i> {{ profileType === 'CSV' ? `Upload profile CSV` : `Add a ${prettyProfileType(profileType)}` }}
            </h5>
        </template>

        <progress-bar v-if="showProgressBar" :total-stages="totalStages" :cur-stage="curStage"/>

        <div class="add-profile-dialog__workflow-container">
            <transition :name="curTransitionType">
                <slot name="workflow">

                </slot>
            </transition>
        </div>

        <template #buttons>
            <div class="add-profile-dialog__btn-bar">
                <span v-if="profileType === profileTypes.facebook && facebookLoggedInAs">Logged in through <i class="symbol-facebook-rect"></i>Facebook as {{ facebookLoggedInAs.name }}</span>
                <be-button style="margin-left: auto" link @click="$emit('close')" :disabled="!enableCloseBtn">Close</be-button>
                <be-button class="short-animated fadeIn" @click="previous" v-if="showPrevBtn" :disabled="!enablePrevBtn">Previous</be-button>
                <be-button class="short-animated fadeIn" primary @click="next" v-if="showNextBtn" :disabled="!enableNextBtn">{{ nextBtnText }}</be-button>
            </div>
        </template>
    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import BeButton from "@/components/buttons/BeButton";
import VuexStore from "@/store/vuex/VuexStore";
import {addProfileMixins} from "@/setup/profiles/add-profile/AddProfileMixins";
import {getProfileIcon, prettyProfileType} from "@/setup/profiles/ProfileUtils";
import ProgressBar from "@/components/ProgressBar";

export default {
    name: "AddProfileDialog",
    store: VuexStore,
    components: {ProgressBar, BeButton,  DialogBox},
    mixins: [addProfileMixins],

    props: {
        totalStages: {
            type: Number,
            required: true
        },
        curStage: {
            type: Number,
            required: true
        },
        showProgressBar: {
            type: Boolean,
            default: true
        },
        enableNextBtn: {
            type: Boolean,
            default: true
        },
        enablePrevBtn: {
            type: Boolean,
            default: true
        },
        nextBtnText: {
            type: String,
            default: "Next"
        },
        showNextBtn: {
            type: Boolean,
            default: true
        },
        showPrevBtn: {
            type: Boolean,
            default: true
        },
        enableCloseBtn: {
            type: Boolean,
            default: true
        },
        profileType: {
            type: String,
            required: true
        }
    },

    data: function () {
        return {
            transitionType: {
                NEXT: "slide-next",
                PREV: "slide-previous"
            },

            curTransitionType: "slide-next"
        }
    },

    methods: {
        getProfileIcon,
        prettyProfileType,

        next() {
            this.curTransitionType = this.transitionType.NEXT;

            this.$emit('next');
        },

        previous() {
            this.curTransitionType = this.transitionType.PREV;

            this.$emit('prev');
        },

        setTransitionToNext() {
            this.curTransitionType = this.transitionType.NEXT;
        },

        setTransitionToPrev() {
            this.curTransitionType = this.transitionType.PREV;
        }
    }
}
</script>

<style scoped lang="sass">

.add-profile-dialog

    &__workflow-container
        height: 500px
        margin-top: 20px

    &__btn-bar
        display: flex
        margin: 10px -20px -20px
        column-gap: 10px
        padding: 10px 20px
        border-top: 1px solid #272727
        background: #333333
        border-bottom-left-radius: 6px
        border-bottom-right-radius: 6px

        span
            color: var(--be-colour-muted-text-dark)
            font-style: italic
            font-size: 0.9em

.slide-next-enter-active,
.slide-previous-enter-active
    transition: all .25s ease

.slide-next-leave-active,
.slide-previous-leave-active
    transition: none
    opacity: 0

.slide-next-enter
    transform: translateX(30px)
    opacity: 0

.slide-previous-enter
    transform: translateX(-30px)
    opacity: 0


</style>