<template>
    <section class="dashboard-panel__list-item__actions">
        <span v-if="dashboardIsPrivate(dashboard)"><i class="symbol-lock" @mouseenter="showTooltip"></i></span>
        <old-tooltip v-if="dashboardIsEditable(dashboard)" label="Edit the dashboard's settings">
            <i class="symbol-menu" @click.stop="showMenu"></i>
        </old-tooltip>
    </section>
</template>


<script>

import OldTooltip from "@/components/tooltip/OldTooltip";
import {dashboardIsEditable, dashboardIsPrivate} from "@/dashboards/DashboardUtils";

export default {
    components: {OldTooltip},

    props: {
        dashboard: Object
    },

    methods: {
        dashboardIsPrivate,
        dashboardIsEditable,

        showMenu(event) {
            this.$emit('show-menu', {dashboard: this.dashboard, target: event.target});
        },

        showTooltip(event) {
            this.$emit('show-tooltip', {dashboard: this.dashboard, target: event.target});
        }
    }
}

</script>


<style scoped lang="sass">

</style>