import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
import {convertFilterToAttrs} from "@/dashboards/filter/BasicFilter";
import {escapeExpression} from "@/app/utils/StringUtils";
import _ from 'underscore';
import {isFunction} from "@/app/utils/Util";
import {showDialogComponent} from "@/app/framework/dialogs/DialogUtilities";
import EditWidgetCommentsDialog from "@/dashboards/widgets/EditWidgetCommentsDialog";
import {features} from "@/app/Features";
import EditWidgetCommentsPlusDialog from "@/dashboards/EditWidgetCommentsPlusDialog.vue";

Beef.module("WidgetSettings").addInitializer(function(startupOptions) {

    let isPreviewing = false;

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/dashboards/widgets/WidgetSettings.handlebars"),

        attributes: { class: "widget-settings dialog" },

        editAttributes: function() {
            var type = this.model.get('type');
            var extra = [];
            if (type) type = Beef.WidgetRegistry.typeMap[type];
            if (type && type.SettingsView) {
                extra = type.SettingsView.prototype.editAttributes;
                if (_(extra).isUndefined()) extra = [];
                if (isFunction(extra)) extra = extra();
            }
            let editAttrs = ['caption', 'type', 'filter', 'width', 'height', 'hidden-title'].concat(extra);
            editAttrs = ['comment', 'commentWidth', 'commentFontSize', 'commentPlus'].concat(editAttrs);


            return editAttrs;
        },

        regions: {
            filter: ".filter",
            privateSettings: ".private-settings"
        },

        events: Object.assign({
            "click .sub-filter": "toggleSubFilter",
            "click .preview .btn": "preview",
            "click .width .btn": "changeWidth",
            "click .height .btn": "changeHeight",
            "click .edit-widget-comments": "editWidgetComments",
            "click .hidden-title-setting": "showTitleToggle"
        }, Beef.SettingsDialog.View.prototype.events),

        modelEvents: {
            "change:width": "modelWidthChanged",
            "change:height": "modelHeightChanged",
        },

        templateHelpers: function() {
            let type = Beef.WidgetRegistry.typeMap[this.originalModel.get('type')];
            let name = '';
            if (type) name = type.name.toLowerCase();
            return {
                title: type.settingsTitle || ("Edit the " + name + " metric settings"),
                name: name,
                type: type,
                canHaveComments: type.type !== "BrandSelector" && type.type !== "WorldMap",
                isPreviewing: false
            }
        },

        onClose: function() {
            if (this.modelWasUpdated && this.okClicked) {
                notifyUser({
                    message: `<strong>${escapeExpression(this.originalSettings.caption)}</strong> settings updated.`,
                    isEscapedHtml: true,
                    icon: '<i class="icon-signal-1"></i>',
                    undo: function() {
                        this.originalModel.clear({silent: true});
                        this.originalModel.set({'_preserveSettingsOnUpdate': true}, {silent: true}); // Need this to stop coarseness being updated.
                        this.originalModel.set(this.originalSettings);

                        this.originalModel.save();
                        notifyWithText("Metric setting changes have been undone.", null, '<i class="icon-signal-1"></i>');
                    }.bind(this)
                });
            }
        },

        onFirstRender: function() {
            var type = this.model.get('type');
            this.originalSettings = Beef.Sync.cloneModel(this.originalModel);

            if (type) type = Beef.WidgetRegistry.typeMap[type];
            if (type && type.SettingsView) {
                var settings = new type.SettingsView({model: this.model, cache: this.cache,
                    originalModel: this.originalModel, owner: this});
                this.privateSettings.show(settings);

                this.listenTo(this.originalModel, 'change',function() {
                    this.modelWasUpdated = true;
                }.bind(this));
            }

            let showTitleCheckbox = $(".hidden-title-checkbox", this.$el);
            if (this.model.get('hidden-title') === undefined) {
                this.model.set('hidden-title', false);
                showTitleCheckbox?.prop('checked', true);
            } else {
                showTitleCheckbox?.prop('checked', !this.model.get('hidden-title'));
            }

            if (this.model.get('filter')) {
                this.createAndShowSubFilter();
                $(".filter-wrapper", this.$el).show();
                $(".sub-filter", this.$el).toggleClass("dropup");
            }

            var w = this.model.get('width') || "2";
            $('.width .btn[data-value="' + w + '"]', this.$el).toggleClass("active", true);

            var h = this.model.get('height') || "2";
            $('.height .btn[data-value="' + h + '"]', this.$el).toggleClass("active", true);

            setTimeout(function() {
                let comment = this.model.get("comment");
                if (comment) $(".comment-text").text(comment);
                else $(".comment-text").text("Add comments...");

                this.$('input[name=caption]').select();
            }.bind(this));
        },

        toggleSubFilter: function(ev) {
            if (ev) ev.preventDefault();
            if (!this.filter.currentView) {
                this.createAndShowSubFilter();
                // wait for the view to be rendered so it has size and will slide down properly
                setTimeout(function(){ this.toggleSubFilter() }.bind(this));
            } else {
                var $sf = $(".sub-filter", this.$el);
                $(".filter-wrapper", this.$el).slideToggle('fast', function(){ $sf[0].scrollIntoView(false) });
                $sf.toggleClass("dropup");
            }
        },

        showTitleToggle() {
            let showTitleCheckbox = $(".hidden-title-checkbox", this.$el);

            if (showTitleCheckbox?.is(":checked")) {
                this.model.set('hidden-title', false);
            } else {
                this.model.set('hidden-title', true);
            }
        },

        editWidgetComments() {
            if (features.commentaryPlusInWidget()){
                let commentSettings = showDialogComponent(EditWidgetCommentsPlusDialog, {
                    model: this.model,
                    filter: this.originalModel.get('_effectiveFilter'),
                    blocks: this.model.attributes.commentPlus
                });
                commentSettings.$on('comment-saved', args => {
                    this.model.set('commentPlus', args.comment);
                    this.model.set('commentWidth', args.commentWidth);
                    this.model.set('commentFontSize', args.commentFontSize);

                });
            } else {
                let commentSettings = showDialogComponent(EditWidgetCommentsDialog, {model: this.model});
                commentSettings.$on('comment-saved', args => {
                    this.model.set('comment', args.comment);
                    this.model.set('commentWidth', args.commentWidth);
                    this.model.set('commentFontSize', args.commentFontSize);

                    if (args.comment) $(".comment-text").text(args.comment)
                    else $(".comment-text").text("Add comments...")
                });
            }
        },

        preview() {
            isPreviewing = !isPreviewing;

            let previewParentElement = $(".widget-settings-preview-widget");
            let widgetSettingsElement = $(".widget-settings");

            if (isPreviewing) {
                $("#settings").hide();
                $("#preview").show();

                 let previewContainerDiv = "<div id='preview-container' class='widget-settings-preview-widget-container widget-grid resizable-grid widget-grid-col12'></div>"

                 if (previewParentElement) {
                     previewParentElement.append(previewContainerDiv);

                     let previewContainerElement = $("#preview-container")

                     // setup widget
                     let previewModel = $.extend(true, {}, this.originalModel);
                     previewModel.set(this.model.attributes);

                     let previewView = new Beef.Widget.View({model: previewModel});
                     previewView.updateFilter();

                     previewView.render();
                     previewContainerElement.append(previewView.el);


                     let style = getComputedStyle(previewView.el);
                     let widgetWidth = style.getPropertyValue('--widget-width');

                     // set width of dialog to be the width of the widget (add 40px to account for dialog padding)
                     if (widgetWidth) {
                         widgetSettingsElement.width(`calc(${widgetWidth} + 40px)`);

                         // recenter popup
                         this.parentPopup?.setPosition("center");
                     }

                     previewParentElement.click(false);
                 }
            } else {
                $("#settings").show();
                $("#preview").hide();

                $("#preview-container")?.remove();

                // set width of dialog back to original
                widgetSettingsElement.width("600px");

                // recenter popup
                this.parentPopup?.setPosition("center");
            }
        },

        createAndShowSubFilter: function() {
            // extract brands from section filter and drill down model if there are any
            var brand = this.originalModel.getInteractiveFilterModel().get('brand');
            if (!brand) {
                var sm = this.originalModel.getSectionModel();
                brand = convertFilterToAttrs(sm.get('filter')).brand;
            }

            this.filter.show(new Beef.Filter.View({model: this.model, cache: this.cache, noPublished: true,
                noTrash: true, brand: brand}));
        },

        changeWidth: function(ev) {
            var $t = $(ev.target);
            this.model.set('width', $t.attr('data-value'));
        },

        changeHeight: function(ev) {
            var $t = $(ev.target);
            this.model.set('height', $t.attr('data-value'));
        },

        modelWidthChanged: function() {
            $('.width .btn', this.$el).toggleClass('active', false);
            $('.width .btn[data-value=\'' + this.model.get("width") + "']", this.$el).toggleClass("active", true);
        },

        modelHeightChanged: function() {
            $('.height .btn', this.$el).toggleClass('active', false);
            $('.height .btn[data-value=\'' + this.model.get("height") + "']", this.$el).toggleClass("active", true);
        }
    });

});