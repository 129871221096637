<template>
    <div class="fb-login">
        <h4>Facebook Login</h4>

        <loading-message style="margin: 0" v-if="loading" :message="loadingMessage"/>
        <div v-else>
            <slot name="loginMessage">
                 <p v-if="facebookUserTokenInvalid">
                    You have logged in through Facebook before, however the login has been invalidated. This could be because you have removed the DataEQ app directly from Facebook, or you have changed your Facebook password.
                    Please login again.
                </p>
                <p v-else>You must authorise DataEQ to access the Facebook API on your behalf before you can add a Facebook page.</p>
            </slot>
            <be-button class="fb-login__login-btn" @click="handleFacebookLogin"><i class="symbol-facebook-rect"></i>Login through Facebook</be-button>

            <p class="fb-login__login-failed" v-if="facebookLoginFailed">An error occurred while trying to log you in with Facebook, please try again.</p>
        </div>
    </div>
</template>

<script>
import LoadingMessage from "@/components/LoadingMessage";
import {notifyUserOfError, notifyWithText} from "@/app/framework/notifications/Notifications";
import {handleFacebookPageAuthLogin} from "@/app/framework/dialogs/user-settings/views/platform-auth/PlatformAuthUtils";
import {mapActions, mapState} from "vuex";
import BeButton from "@/components/buttons/BeButton";

export default {
    name: "FbLogin",
    components: {BeButton, LoadingMessage},
    data: function() {
        return {
            loading: false,
            loadingMessage: "",
            facebookLoginFailed: false
        }
    },

    computed: {
        ...mapState(['user', 'account']),

        ...mapState('userPlatformAuth', [
            'facebookUserTokenInvalid'
        ])
    },

    methods: {
        ...mapActions('userPlatformAuth', [
            'initializeFbUserToken', 'initializeManagedFacebookPages'
        ]),

        async handleFacebookLogin() {
            try {
                this.loading = true;
                this.loadingMessage = "Communicating with Facebook...";

                this.facebookLoginFailed = false;

                handleFacebookPageAuthLogin(callbackResponse => {
                    if (callbackResponse["auth"] === "success") {

                        notifyWithText("Facebook login successful",
                            null,
                            "<i class='symbol-facebook-rect'></i>");

                        this.loadingMessage = "Facebook login successful!";

                        this.loading = false;

                        this.$emit('fb-login-complete');
                    } else {
                        this.facebookLoginFailed = true;
                        this.loading = false;

                        this.$emit('fb-login-failed');
                    }
                });
            } catch (e) {
                console.error("Error occurred while trying to log user in with Facebook ", e);
                this.facebookLoginFailed = true;
                this.loading = false;

                this.$emit('fb-login-failed');
                notifyUserOfError("An error occurred while trying to log you in with Facebook. Please refresh the page and try again or contact DataEQ support.");
            }
        },
    }
}
</script>

<style scoped lang="sass">

.fb-login

    p
        color: var(--be-colour-text-dark)

    &__login-btn
        margin-top: 20px

        ::v-deep .btn
            background: var(--colour-facebook)

        &:hover
            filter: brightness(1.2)

    &__login-failed
        color: var(--be-colour-warning)
        margin-top: 20px

</style>