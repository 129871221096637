<template>
    <section class="segment-choice">
        <h5 v-if="withHeading">Showing</h5>
        <span v-for="segment in segmentLists" :key="segment.id" class="risk-filter__choice-row">
            <be-button link v-if="current.id !== segment.id"
                       @click="$emit('input', segment)">
                {{ segment.subtitle }} {{ segment.name }} <span v-if="!segmentIsActive(segment.id)" class="muted-text "><i class="symbol-info"></i> Inactive list</span>
            </be-button>
            <slotted-tag v-else no-close>
                <animated-check animated/>
                <span>
                     {{ segment.subtitle }} {{ segment.name }} <span v-if="!segmentIsActive(segment.id)" class="muted-text "><i class="symbol-info"></i> Inactive list</span>
                </span>
            </slotted-tag>
            <span class="risk-filter__count" :class="{'risk-filter__count--muted': !getNumberSelected(segment.id)}">
                <deq-number :number="getNumberSelected(segment.id)"/> selected
            </span>
        </span>
    </section>
</template>


<script>
import {deprecatedTagsStore as tagStore} from "@/store/deprecated/Stores";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import SlottedTag from "@/components/tags/SlottedTag";
import BeButton from "@/components/buttons/BeButton";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    components: {DeqNumber, BeButton, SlottedTag, AnimatedCheck},
    props: {
        value: Object,
        segmentLists: Array,
        activeSegments: Array,
        selectedIds: Array,
        withHeading: {
            type: Boolean,
            default() { return true }
        }
    },

    data() {
        return {
            current: this.value,
            selected: this.selectedIds
        }
    },

    watch: {
        value(val) {
            this.current = val;
        }
    },

    async mounted() {
        await tagStore.refresh(true);
    },

    methods: {

        /**
         * Returns true if the segment is active, false otherwise
         * @param id
         */
        segmentIsActive(id) {
            if (!this.activeSegments?.length) return true;

            return this.activeSegments.map(s => s.id).includes(id);
        },

        /**
         * Returns the number of children selected for the given risk list
         * @param id
         */
        getNumberSelected(id) {
            const tag = tagStore.get(id);
            if (!tag) return 0;
            const children = new Set(tag.children);
            tag.children?.forEach(id => {
                const child = tagStore.get(id);
                if (child?.children?.length) {
                    child.children.forEach(cId => children.add(cId));
                }
            })
            const selected = new Set(this.selectedIds.filter(selected => children.has(Math.abs(selected))));
            return selected.size;
        }
    }
}
</script>


<style scoped lang="sass">
.segment-choice
    h5
        margin-left: 24px
        text-transform: uppercase
    .be-button
        margin-left: 12px
        display: inline-block

        & ::v-deep .btn
            text-align: start

    .risk-filter__choice-row
        display: flex
        width: 100%
        align-items: center

        .muted-text
            color: var(--be-colour-muted-text-dark)

    .risk-filter__count
        margin-left: auto
        color: white
        padding-left: 15px
        min-width: 75px

        &--muted
            color: var(--be-colour-muted-text-dark)
</style>