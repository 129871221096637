var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            Edit "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"totalSelected") : depth0), depth0))
    + " Mention"
    + ((stack1 = __default(require("../helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalSelected") : depth0),"1",{"name":"ifequal","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":94}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " ";
},"4":function(container,depth0,helpers,partials,data) {
    return "s";
},"6":function(container,depth0,helpers,partials,data) {
    return "            Edit Mention\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"span6\">\n                    <div class=\"control-group\">\n                        <label>Location</label>\n                        <div class=\"controls\">\n                        <span class=\"location tag-input uneditable-input span12\" tabindex=\"0\">\n                            <span name=\"location\"></span>\n                            <input class=\"tag popup-menu\" type=\"text\"/>\n                        </span>\n                        </div>\n                    </div>\n                    <div class=\"control-group\">\n                        <label>Language</label>\n                        <div class=\"controls\">\n                            <span class=\"language tag-input uneditable-input span12\" tabindex=\"0\">\n                                <span name=\"language\"></span>\n                                <input class=\"tag popup-menu\" type=\"text\"/>\n                            </span>\n                        </div>\n                    </div>\n                    <div class=\"control-group\">\n                        <label>Gender</label>\n                        <div class=\"controls\">\n                                <span class=\"genderPicker tag-input uneditable-input span12\" tabindex=\"0\">\n                                    <span name=\"gender\"></span>\n                                </span>\n                        </div>\n                    </div>\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"showRaceFilter",{"name":"ifallowed","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":51,"column":34}}})) != null ? stack1 : "")
    + "                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"control-group\">\n                            <label>Ethnicity</label>\n                            <div class=\"controls\">\n                            <span class=\"racePicker tag-input uneditable-input span12\" tabindex=\"0\">\n                                <span name=\"race\"></span>\n                            </span>\n                            </div>\n                        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"control-group\">\n                        <label>Category</label>\n                        <div class=\"controls\">\n                            <span class=\"mediaPicker tag-input uneditable-input span12\" tabindex=\"0\">\n                                <span name=\"media\"></span>\n                            </span>\n                        </div>\n                    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":30},"end":{"line":74,"column":23}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "\n                    <div class=\"control-group\">\n                        <label>Credibility</label>\n                        <div class=\"controls\">\n                            <span class=\"credibility tag-input uneditable-input span12\" tabindex=\"0\">\n                                <span name=\"credibility\"></span>\n                            </span>\n                        </div>\n                    </div>\n                ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"control-group\">\n                        <label>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"v4") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":31},"end":{"line":77,"column":52}}})) != null ? stack1 : "")
    + "Tags</label>\n                        <div class=\"controls\">\n                            <span class=\"_tags tag-input uneditable-input span12\" tabindex=\"0\">\n                                <span name=\"_tags\"></span>\n                                <input class=\"tag popup-menu\" type=\"text\"/>\n                            </span>\n                        </div>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"v4") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":20},"end":{"line":95,"column":27}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "Add ";
},"19":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"control-group\">\n                            <label>Remove Tags</label>\n                            <div class=\"controls\">\n                            <span class=\"_removeTags tag-input uneditable-input span12\" tabindex=\"0\">\n                                <span name=\"_removeTags\"></span>\n                                <input class=\"tag popup-menu\" type=\"text\"/>\n                            </span>\n                            </div>\n                        </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row-fluid\" style=\"margin-top: 10px\">\n                <div class=\"span12\">\n                    <div class=\"control-group\">\n                        <div class=\"controls\">\n                            <label class=\"checkbox\"><input type=\"checkbox\" name=\"allBrands\">\n                                Apply to mentions for "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"brands_") : depth0), depth0))
    + "\n                            </label>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalSelected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n<div class=\"dialog-body\">\n    <div class=\"row-fluid\">\n        <fieldset>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"span6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditAttrs") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":64,"column":23}}})) != null ? stack1 : "")
    + "                "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"v4") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":74,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_canEditTags") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":16},"end":{"line":96,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </fieldset>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"brands_") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":8},"end":{"line":111,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"dialog-button-bar\">\n            <span class=\"progress-msg\" style=\"float: left\"></span>\n            <a class=\"cancel btn\" title=\"Discard changes (Esc)\">Cancel</a>\n            <a class=\"ok btn btn-primary\">Ok</a>\n        </div>\n    </div>\n</div>";
},"useData":true});