var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <a class=\"btn btn-default generate\" title=\"Generate phrases by combining words\"><i class=\"icon-plus\"></i> Generate</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <li>Use * to to match all words starting with a prefix (e.g. bank*)</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"items dark-scrollbars dark-scrollbars--visible\">\n    </div>\n\n    <div style=\"margin-top: 10px\">\n        <div class=\"actions\">\n            <a class=\"btn btn-default add-phrase\"><i class=\"icon-plus\"></i> Add Phrase</a>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"forPhraseGen") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n        <span class=\"help-inline\" style=\"vertical-align: top; margin-top: -3px\">\n            <ul>\n                <li>Press enter after typing a word or group of words to add them to the phrase</li>\n                <li>Use - to exclude words (e.g -no spam)</li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"forPhraseGen") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":18,"column":27}}})) != null ? stack1 : "")
    + "            </ul>\n        </span>\n    </div>\n</div>\n";
},"useData":true});