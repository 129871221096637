<template>
    <modal-popup :overlay="overlay" class="modal-popup vue-dialog" :left="left" :top="top" :bottom="bottom" :right="right"
                 :modal="modal" :no-dim="noDim" :width="width" :no-transition="noTransition"
                 @close="$emit('close')"
                 v-if="!inPresentation">
        <div class="dialog" @click.stop @keydown.esc.stop.prevent="$emit('close', $event)" @mousedown="onMousedown"
             ref="dialog"
             tabindex="-1">
            <div v-if="title || $slots['title']" class="dialog-title">
                <slot name="title"><h5>{{title}}</h5></slot>

                <a class="btn close" @click="$emit('close', $event)">
                    <old-tooltip label="Close" keyword="esc" style="display: inline">
                        <i class="symbol-close"></i>
                    </old-tooltip>
                </a>

            </div>
            <div class="dialog-body">
                <slot/>
                <div class="dialog-button-bar">
                    <slot name="buttons">
                        <a class="btn btn-primary" @click="$emit('close')" tooltip="Close (esc)">Close</a>
                    </slot>
                </div>
            </div>
        </div>
    </modal-popup>
</template>

<script>
import ModalPopup from './ModalPopup'
import {closeCurrentTooltip} from "./tooltip/TooltipUtilities";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {registerDialog, unregisterDialog} from "@/app/framework/dialogs/DialogUtilities";

export default {
        name: "DialogBox",

        components: {OldTooltip, ModalPopup },

        props: {
            width: String,
            modal: Boolean,         // clicking away doesn't close
            overlay: Boolean,       // shade the app
            stopMousedown: Boolean, // stop propagation on mousedown events to prevent other controls from closing
            noTransition: Boolean, // true if the dialog should not animate when size changes, false otherwise
            noDim: Boolean,
            title: String,
            left: String,
            top: String,
            bottom: String,
            right: String
        },

        computed: {
            inPresentation() {
                return false;
                // return Beef.Dashboard.isPresentationMode();
            }
        },

        methods: {
            focus() {
                this.$refs.dialog.focus();
            },

            onMousedown(ev) {
                if (this.stopMousedown) ev.stopPropagation()
                this.$emit('mousedown', ev)
            }
        },

        mounted: function() {
            if (this.modal || this.overlay) {
                // Register our modal dialogs can stop screen movement.
                this.modalId = registerDialog();
            }

            this.$nextTick(() => this.focus());
            closeCurrentTooltip();
        },

        destroyed: function() {
            if (this.modalId !== undefined) {
                // Unregister our modal dialog (and possibly allow screen movement again)
                unregisterDialog(this.modalId);
            }
        }
    }

</script>

<style scoped>
    .modal-popup ::v-deep .modal-container,
    .dialog {
        border-radius: 6px;
        outline: none;
    }

    .close {
        color: #fff !important;
    }
</style>