import moment from 'moment'
import {account} from "@/app/utils/Account";

/*
 * This returns a humanized date. For example, "1 minute ago", and so on.
 */
export default function(time, useAccountTimezone, options) {
    options = Object.assign({
        plainText: false
    }, options && options.hash || {});

    var date = new moment(time);
    var days = new moment().diff(date, 'days');
    if (days < 1) return new Handlebars.SafeString(date.fromNow());
    var f = days < 300 ? 'Do MMM HH:mm' : 'D MMM YYYY HH:mm';
    if (useAccountTimezone) {
        var timezone = account().timezone;
        var tz = date.tz(timezone);
        if (!tz) {
            tz = date.tz(timezone = 'UTC');
        }
        if (options.plainText) return new Handlebars.SafeString(tz.format(f));
        return new Handlebars.SafeString("<span title='Timezone " + timezone + "'>" + tz.format(f) + "</span>");
    } else {
        return new Handlebars.SafeString(date.format(f));
    }
}