var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(__default(require("../helpers/render-emoji.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"englishFilter") : depth0),{"name":"render-emoji","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":42}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            Fetching mentions …\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span>\n                    "
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"total") : depth0),"!=",undefined,{"name":"ifcond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":143}}})) != null ? stack1 : "")
    + "\n                </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"number\">"
    + alias2(__default(require("../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":30,"column":73},"end":{"line":30,"column":95}}}))
    + "</span> "
    + alias2(__default(require("../helpers/pluralise.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),"mention",{"name":"pluralise","hash":{},"data":data,"loc":{"start":{"line":30,"column":103},"end":{"line":30,"column":132}}}));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span>"
    + container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalSelected") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":33,"column":22},"end":{"line":33,"column":52}}}))
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),"!=",undefined,{"name":"ifcond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":52},"end":{"line":33,"column":121}}})) != null ? stack1 : "")
    + " selected</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " of "
    + container.escapeExpression(__default(require("../helpers/formatNumber.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":33,"column":88},"end":{"line":33,"column":110}}}));
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"edit-selected btn disabled\" title=\"Edit the selected mentions\"><i class=\"icon-pencil\"></i></a>\n            <a class=\"restore-selected btn disabled\" title=\"Un-delete the selected mentions (mark as relevant)\"><i class=\"icon-ccw\"></i></a>\n            <a class=\"trash-selected btn disabled\" title=\"Delete the selected mentions\"><i class=\"icon-trash-1\"></i></a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "            <a class=\"text-warning whip-crowd\" title=\"Deleting or undeleting individual crowd verified mentions will cost the crowd money and hopefully they will change their behaviour\">\n                <i class=\"symbol-warning\"></i>Crowd training enabled\n            </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row-fluid\">\n    <div class=\"span12 english-filter\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"englishFilter") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div class=\"row-fluid\">\n    <div class=\"span12 actions\">\n        <span class=\"pull-left\">\n            <a class=\"btn edit-filter static-beef-tooltip--trigger\">\n                <div class=\"static-beef-tooltip\">\n                    Choose which mentions to work with\n                    <span class=\"static-beef-tooltip__keyboard\">\n                        F\n                    </span>\n                </div>\n                <span>\n                    Filter mentions\n                </span>\n            </a>\n        </span>\n\n        <span class=\"selection\">\n"
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalSelected") : depth0),"==",0,{"name":"ifcond","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":34,"column":23}}})) != null ? stack1 : "")
    + "            <a class=\"select-all btn "
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalSelected") : depth0),"==",(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"ifcond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":37},"end":{"line":35,"column":92}}})) != null ? stack1 : "")
    + "\" title=\"Select all the mentions\">All</a>\n            <a class=\"select-none btn "
    + ((stack1 = __default(require("../helpers/ifcond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"totalSelected") : depth0),"==",0,{"name":"ifcond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":38},"end":{"line":36,"column":89}}})) != null ? stack1 : "")
    + "\" title=\"Unselect all the mentions\">None</a>\n        </span>\n\n"
    + ((stack1 = __default(require("../helpers/ifallowed.js")).call(alias1,"editMentions",{"name":"ifallowed","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":43,"column":22}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"whipCrowd") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "")
    + "\n        <span class=\"pull-right\">\n            <a class=\"btn btn-link mentions-clear static-beef-tooltip--trigger\">\n                <div class=\"static-beef-tooltip\">\n                    Reset the filter\n                    <span class=\"static-beef-tooltip__keyboard\">\n                        BACKSPACE\n                    </span>\n                </div>\n                <span>Clear filter</span>\n            </a>\n            <a class=\"btn mentions-sort-menu options popup-menu\" title=\"Change how the mentions are sorted\">Sort <span class=\"caret\"></span></a>\n            <a class=\"btn mentions-menu options popup-menu\" title=\"Extra options for how to view, share, and act on, mentions\">Options <span class=\"caret\"></span></a>\n        </span>\n    </div>\n</div>\n";
},"useData":true});