/**
 * Copies text to the clipboard. Attempts to use
 * the clipboard API if it is available, otherwise it falls back to relying on
 * deprecated DOM APIs (note that these APIs have already begun to break
 * in some browsers).
 *
 * Fallback implemented from https://stackoverflow.com/a/46118025/159434
 * @param text
 * @return {Promise<void>}
 */
export async function copyToClipboard(text) {
    try {
        if (navigator?.clipboard?.writeText) {
            return navigator.clipboard.writeText(text);
        }
    } catch (e) {
        console.warn("Ignorable error when using clipboard API:", e);
        console.warn("Clipboard write operation falling back to deprecated API");
    }

    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

