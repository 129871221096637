Beef.module("MovePhrase").addInitializer(function(startupOptions) {

    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/setup/brands/MovePhrase.handlebars"),

        attributes: { class: "move-phrase dialog" },

        regions: {
            picker: ".picker"
        },

        initialize: function() {
            this.model.accountCode = this.options.accountCode;
            Beef.SettingsDialog.View.prototype.initialize.call(this, arguments);
        },

        onFirstRender: function() {
            var tree = buildPickerTree(this.options.rootBrands);
            var p = new Beef.BrandPicker.PlainView({view: this, model: new Backbone.Model({tree: tree}), onlyOne: true});
            p.setSelection(this.model.get('_parent').id.toString());
            this.picker.show(p);
        },

        ok: function() {
            var sel = this.picker.currentView.getSelection();
            if (sel.length == 0 || sel[0] == this.model.get('_parent').id) {
                this.cancel();
            } else {
                this.model.set('brandId', sel[0]);
                Beef.SettingsDialog.View.prototype.ok.call(this, arguments);
            }
        }
    });

    var buildPickerTree = function(rootBrands) {
        var tree = [];
        for (var i = 0; i < rootBrands.length; i++) {
            tree.push(createPickerBrand(rootBrands[i], null));
        }
        return tree;
    };

    var createPickerBrand = function(brand, parent) {
        var ans = {id: brand.id, name: brand.name, parent: parent};
        if (brand.children) {
            ans.children = [];
            for (var i = 0; i < brand.children.length; i++) {
                ans.children.push(createPickerBrand(brand.children[i], ans));
            }
        }
        return ans;
    };

});