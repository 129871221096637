import {teams} from "@/store/deprecated/Stores"
import {deprecatedMashGet} from "@/app/Mash";
import {isAccountAdmin, isMashAdmin} from "@/app/Permissions";
import VuexStore from "@/store/vuex/VuexStore";
import _ from 'underscore';

Beef.module("EditUser").addInitializer(function(startupOptions) {

    /**
     * View for a user's edit page
     */
    this.View = Beef.SettingsDialog.View.extend({
        template: require("@/settings/users/UserEdit.handlebars"),

        attributes: { class: "user-dialog" },

        editAttributes: ['id', 'firstName', 'lastName', 'email', 'permissions', 'privateMentions'],

        regions: {
            "email": ".email",
            "details": ".details"
        },

        events:Object.assign({
            "click .dialog-button-bar .next": "next",
            "click .dialog-button-bar .prev": "prev"
        }, Beef.SettingsDialog.View.prototype.events),

        initialize: function(options) {
            this.detail = options.detail;
            this.listUsers = options.listUsers;
            Beef.SettingsDialog.View.prototype.initialize.call(this);

            this.model.set("teamIds", this.model.id ? teams.getUserTeams(this.model.id).map(t => t.id) : [])

            this.model.on('validated', function() {
                this.updatePage();
            }.bind(this));
        },

        onFirstRender: function() {
            this.email.show(new EmailView({model: this.model}));
            this.details.show(new DetailsView({model: this.model}));
            Backbone.Validation.bind(this); // Ensure that model validation occurs on the whole dialog, not just the last created view
            if (this.originalModel.isNew()) this.page = "email";
            else this.page = "details";
            this.busy = false;
            if (this.originalModel.isNew()) {
                this.$el.find('.delete').hide();
            } else {
                this.$el.find('.prev').hide();
                this.$el.find('.next').hide();
            }
            this.updatePage();
        },

        next: function() {
            if (this.page == "email" && this.isNextAllowed()) {
                this.model.set('email', this.model.get('email').trim());
                this.busy = true;
                this.updatePage();
                deprecatedMashGet("users/search?email=" + this.model.get('email'), null, function(data){
                    this.model.set(data);
                    this.busy = false;
                    this.page = "details"
                    this.updatePage();
                }.bind(this));
            }
            this.updatePage();
        },

        prev: function() {
            if (this.page == "details" && this.originalModel.isNew()) { this.page = "email" }
            this.updatePage();
        },

        isOkAllowed: function() {
            return this.page == "details"  && !this.busy;
        },

        isNextAllowed: function() {
            return this.page == "email" && !this.$('.email .error').length && !this.busy;
        },

        isPrevAllowed: function() {
            return (this.page == "details" && this.originalModel.isNew());
        },

        updatePage: function() {
            this.$el.find('.ok').toggleClass('disabled', !this.isOkAllowed());
            this.$el.find('.next').toggleClass('disabled', !this.isNextAllowed());
            this.$el.find('.prev').toggleClass('disabled', !this.isPrevAllowed());

            this.$el.find('.next').html(this.busy ? "<span class='spinner'/>" : "Next")

            this.$el.find('.email').toggle(this.page == "email");
            this.$el.find('.details').toggle(this.page == "details");

            if (!this.canEditName()) {
                this.$el.find("input[name='firstName']").prop('readonly', true)
                this.$el.find("input[name='lastName']").prop('readonly', true)
            }

            this.$el.find(".perms").toggle(this.canEditPerms())
        },

        canEditPerms: function() {
            return isAccountAdmin() && Beef.user.id != this.model.id || isMashAdmin();
        },

        canEditName: function() {
            return Beef.user.id == this.model.id || !this.model.id || isMashAdmin();
        },

        prepareForSave: function(attrs) {
            var ans = Beef.SettingsDialog.View.prototype.prepareForSave.call(this, attrs);
            if (!this.canEditPerms()) delete ans.permissions;
            return ans;
        },

        onSaveSuccess: async function(model, xhr, options) {
            VuexStore.dispatch("accountUsers/refreshAccountUsers", false);
            let teamIds = new Set(this.model.get('teamIds'))
            let userId = model.id
            for (const team of teams.getUserTeams(userId)) {    // existing teams
                if (!teamIds.delete(team.id)) {
                    await teams.update(team, {removeUserIds: [userId]})
                }
            }
            for (const id of teamIds) {
                await teams.update(teams.get(id), {addUserIds: [userId]})
            }
            this.originalModel.trigger("change")
        },

        onDeleteSuccess: function() {
            VuexStore.dispatch("accountUsers/refreshAccountUsers", false);
        }
    });

    var EmailView = Beef.BoundItemView.extend({
        template: require("@/settings/users/UserEmailEdit.handlebars"),
        modelEvents: {
            "change:email": "emailChanged"
        },
        emailChanged: function() {
            if (this.model.get('email')) {
                var email = this.model.get('email').trim();
                this.$('input').val(email);
                this.model.set('email', email);
            }
        }
    });

    var DetailsView = Beef.BoundItemView.extend({

        template: require("@/settings/users/UserDetailsEdit.handlebars"),

        events: {
            "click .permissions button": "changePermissions",
            "click .privateMentions button": "changePrivateMentions",
            "change .teams input": "changeTeams"
        },

        templateHelpers: function() {
            return {
                PERMISSIONS: Beef.AccountUsers.PERMISSIONS,
                teams: function() {
                    return teams.list.map(t => {
                        t = {...t}
                        t.selected = t.userIds.indexOf(this.id) >= 0
                        return t
                    })
                }
            }
        },

        onRender: function() {
            var b = this.model.get('permissions');
            this.$('.permissions .btn[data-value="' + b + '"]').toggleClass("active", true);
            this.privateMentionsToView()
        },

        changePermissions: function(ev) {
            var b = this.$(ev.target).attr('data-value');
            this.$('.permissions .btn').toggleClass("active", false);
            this.$('.permissions .btn[data-value="' + b + '"]').toggleClass("active", true);
            this.model.set('permissions', b);
        },

        privateMentionsToView: function() {
            let pm = this.model.get('privateMentions')
            this.$('.privateMentions .btn').toggleClass("active", false)
            if (pm && pm.length) {
                if (pm.length === 1 && pm[0] === '*') {
                    this.$('.privateMentions .btn[data-value="ALL"]').toggleClass("active", true)
                } else {
                    pm.forEach(v => this.$('.privateMentions .btn[data-value="' + v + '"]').toggleClass("active", true))
                }
            } else {
                this.$('.privateMentions .btn[data-value="NONE"]').toggleClass("active", true)
            }
        },

        changePrivateMentions: function(ev) {
            let v = this.$(ev.target).attr('data-value')
            let pm
            if (v === "ALL") pm = ["*"]
            else if (v === "NONE") pm = []
            else {
                pm = this.model.get('privateMentions')
                let i = pm.indexOf(v)
                if (i >= 0) {
                    pm.splice(i, 1)
                } else {
                    if (!pm || pm.length === 1 && pm[0] === "*") pm = []
                    pm.push(v)
                }
            }
            this.model.set('privateMentions', pm)
            this.privateMentionsToView()
        },

        changeTeams: function(ev) {
            let id = parseInt(ev.target.value)
            let teamIds = this.model.get('teamIds')
            let i = teamIds.indexOf(id)
            if (ev.target.checked) {
                if (i < 0) teamIds.push(id)
            } else {
                if (i >= 0) teamIds.splice(i, 1)
            }
        }
    });

});