/**
 * Shows simple tooltip text.
 * @param {Object} targetDomNode
 * @param {String} text
 * @param {Object.optional} tooltipOptions
 * @return {Object} - The tooltip object, if you wish to manipulate it further.
 */
export function showTooltipText(targetDomNode, text, tooltipOptions) {
    return Beef.Tooltip.show(Object.assign({
        target: targetDomNode,
        text: text || "No text",
        autoclose: true
    }, Object.assign({}, tooltipOptions)));
}

/**
 * Shows simple html in a tooltip. Please ensure that the text embedded in your html
 * has been escaped before calling this function.
 */
export function showTooltipHtml(targetDomOrNode, escapedHtml, tooltipOptions) {
    tooltipOptions ??= {};
    return showTooltipText(targetDomOrNode, escapedHtml, {isEscapedHtml: true, ...tooltipOptions});
}

/**
 * Show a tooltip containing a particular vue component over the given target.
 * @param targetDomNode
 * @param tooltipClass
 * @param props Optional map of properties
 * @param tooltipOptions Optional map to help setup the tooltip
 * @returns {*}
 */
export function showTooltipComponent(targetDomNode, tooltipClass, props, tooltipOptions) {
    const view = new Beef.VuejsView.View({component: tooltipClass, props: props});


    return Beef.Tooltip.show(Object.assign({
        target: targetDomNode,
        view: view,
        autoclose: true
    }, Object.assign({}, tooltipOptions)));
}

export function repositionCurrentTooltip(){
    if (Beef && Beef.Tooltip){
        Beef.Tooltip.move();
    }
}

export function showTooltipDomElement(targetDomNode, domNode, tooltipOptions) {
    return Beef.Tooltip.show(Object.assign({
        target: targetDomNode,
        node: domNode,
        autoclose: true
    }, Object.assign({}, tooltipOptions)));
}

export function closeCurrentTooltip() {
    if (Beef && Beef.Tooltip) {
        Beef.Tooltip.close();
    }
}