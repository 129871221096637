<template>
    <widget-settings-dialog class="venn-settings" :model="model" :dto="dto" width="620px" @input="$emit('input', $event)">
        <template #title-inputs>
            <div class="orientation-input">
                <text-input type="number" label="Orientation" width="60px" v-model="orientation"></text-input>
                <span>degrees</span>
            </div>
        </template>
        <div class="venn-settings__container">
            <drop-down-input class="data-type-input" v-model="selectedDataType" :options="dataTypes" label="What data would you like to see?"></drop-down-input>
        </div>

    </widget-settings-dialog>
</template>

<script>
import WidgetSettingsDialog from "@/dashboards/widgets/WidgetSettingsDialog";
import DropDownInput from "@/components/inputs/DropDownInput";
import TextInput from "@/components/inputs/TextInput";

export default {
    name: "VennSettings.vue",
    components: {TextInput, DropDownInput, WidgetSettingsDialog},

    props: {
        model: Object
    },

    data() {
        return {
            dto: {
                attrs: { }  // this gets set by widget-settings-dialog
            },
            orientation: 270,

            selectedDataType: "mentions",
            dataTypes: {
                mentions: {
                    name: "Mentions"
                },
                interactions: {
                    name: "Interactions"
                }
            }
        }
    },

    async created() {
        if (!this.selectedDataType) this.selectedDataType = "mentions";
    },

    watch: {
        orientation(v) {
            this.dto.attrs.orientation = v;
        },
        'dto.attrs.orientation'(v) {
            this.orientation = v;
        },


        selectedDataType(v) {
            this.dto.attrs.selectedDataType = v;
        },
        'dto.attrs.selectedDataType'(v) {
            this.selectedDataType = v;
        }
    }
}
</script>

<style scoped lang="sass">

.venn-settings

    ::v-deep .control-form
        min-height: 200px !important

    .orientation-input
        display: flex
        column-gap: 2px
        align-items: center

    span
        color: #bbb
        margin-top: 10px

    &__container
        margin-top: 15px

        display: flex
        column-gap: 40px

        label
            color: #bbb
            margin-top: 10px

        .data-type-input
            width: 200px

    &__list-dropdown
        width: 300px
        margin-top: 20px

</style>