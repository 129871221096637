/*
 * Formats a root brand feeds string into english.
 */
export default function(feeds) {
    var ans = "";
    if (feeds) {
        var a = feeds.split(' ');
        for (var i = 0; i < a.length; i++) {
            if (i > 0) {
                if (i == a.length - 1) ans += " and ";
                else ans += ", ";
            }
            var code = a[i];
            var name = Beef.FeedPicker.items[code];
            ans += name ? name : code
        }
    }
    return ans;
}