import {mash} from "@/store/Services";
import {Tag} from "@/app/utils/types";

export const TAG_PARENT = new Tag();

export const TagMutations = {

    setTag: (state, tag) => {
        if (!tag.id) throw new Error("Tag has no ID");
        let t = state.tags.find(t => t.id === tag.id);
        if (!t) throw new Error(`Tag ${t.id}:${t.name} is not a known tag`);
        let temp = Object.assign(t, tag);
        temp.__proto__ = TAG_PARENT;
        return temp;
    },

    addTag: (state, tag) => {
        if (!tag.id) throw new Error("Tag has no ID");
        state.tags.push(tag);
        tag.__proto__ = TAG_PARENT;
        return tag;
    },

    removeTag: (state, tag) => {
        if (!tag.id) throw new Error("Tag has no ID");
        const i = state.tags.findIndex(t => t.id === tag.id);
        if (i >= 0) state.tags.splice(i, 1);
    }

};

export const TagGetters = {
    segmentLists: state => {
        return state.tags.filter(t => t.namespace === 'segment_list');
    },
    activeSegmentLists: state => {
        let activeIds = new Set();
        state.rootBrands.forEach(b => {
            b.activeSegmentListIds?.forEach(id => activeIds.add(id));
        });
        return state.tags.filter(t => t.namespace === 'segment_list' && activeIds.has(t.id));
    },

    topicList: state => {
        return state.tags
            .filter(t => t.namespace === 'topic')
            .sort((lhs, rhs) => {
                lhs = lhs.name.toLowerCase();
                rhs = rhs.name.toLowerCase();

                if (lhs < rhs) return -1;
                if (rhs < lhs) return 1;

                return 0;
            });
    },
};

export const TagActions = {

    async updateTag({state, commit}, tag) {
        const response = await mash.put("/rest/accounts/" + state.account.code + "/tags/" + tag.id, tag);
        return commit('setTag', response.data);
    },

    async deleteTag({state, commit}, tag) {
        await mash.delete("/rest/accounts/" + state.account.code + "/tags/" + tag.id);
        return commit('removeTag', tag);
    },

    async createTag({state, commit}, tag) {
        const response = await mash.post("/rest/accounts/" + state.account.code + "/tags", tag);
        const newTag = response.data;
        commit('addTag', newTag);
        return newTag;
    },

    /**
     *
     * @param state
     * @param dispatch
     * @param {String} tagName
     * @param {String, optional} tagNamespace - If we only want to search for matching names in a particular namespace.
     * @return {Promise<Object>} - The tag, iff it exists.
     */
    async doesTagExist({state, dispatch}, tagName, tagNamespace) {
        await dispatch('refreshTags');
        const tags = state.tags;
        if (!tags?.length) return null;

        for (const tag of tags) {
            if (tag.name === tagName && (!tagNamespace || tagNamespace === tag.namespace)) return tag;
        }

        return null;
    }

};