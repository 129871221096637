var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    (for "
    + alias4(((helper = (helper = lookupProperty(helpers,"compareName") || (depth0 != null ? lookupProperty(depth0,"compareName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"compareName","hash":{},"data":data,"loc":{"start":{"line":7,"column":9},"end":{"line":7,"column":24}}}) : helper)))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"compare") || (depth0 != null ? lookupProperty(depth0,"compare") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"compare","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":44}}}) : helper)))
    + "</strong>)\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>\n            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":27}}}) : helper)))
    + "\n        </p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>\n        <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"sizeName") || (depth0 != null ? lookupProperty(depth0,"sizeName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"sizeName","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":28}}}) : helper)))
    + "</strong>\n        is\n        <strong>\n            <span class=\"number\">\n                "
    + alias3((lookupProperty(helpers,"formatSize")||(depth0 && lookupProperty(depth0,"formatSize"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sizeVal") : depth0),{"name":"formatSize","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":38}}}))
    + "\n            </span>\n        </strong>\n    </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--Example: Mentions from 2018-01-01 have an AVE of R25 -->\nMentions "
    + alias4(((helper = (helper = lookupProperty(helpers,"datePeriod") || (depth0 != null ? lookupProperty(depth0,"datePeriod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"datePeriod","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":23}}}) : helper)))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"xVal") || (depth0 != null ? lookupProperty(depth0,"xVal") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"xVal","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":40}}}) : helper)))
    + "</strong> have\n"
    + alias4(((helper = (helper = lookupProperty(helpers,"yArticle") || (depth0 != null ? lookupProperty(depth0,"yArticle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yArticle","hash":{},"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":12}}}) : helper)))
    + " <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"yName") || (depth0 != null ? lookupProperty(depth0,"yName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"yName","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":30}}}) : helper)))
    + " of\n    "
    + alias4((lookupProperty(helpers,"tooltipCurrency")||(depth0 && lookupProperty(depth0,"tooltipCurrency"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"yVal") : depth0),{"name":"tooltipCurrency","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":28}}}))
    + "\n</strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"compare") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "");
},"useData":true});