<template>
    <div class="overview-stats">
        <div v-if="overallCount !== null" class="overview-stats__overall">

            <header>
                <h4>Mentions collected</h4>
                <deq-number :number="overallCount"/> {{formatPlural(overallCount, 'mention')}}
            </header>

            <volume-overview-chart-bar v-for="stat in sortedStats"
                                       :key="stat.name"
                                       :name="stat.name"
                                       @private-clicked="showPrivate(stat.name, stat.filter)"
                                       @public-clicked="showPublic(stat.name, stat.filter)"
                                       :colour="stat.colour"
                                       :overall="overallCount"
                                       :total="stat.total"
                                       :public-count="stat.public"
                                       :private-count="stat.private">
                <template #total>
                    <i :class="stat.icon"></i>
                    <deq-number :number="stat.total"/>
                </template>
            </volume-overview-chart-bar>

            <div class="overview-stats__none animated fadeIn delay-1000">
                <div v-if="twitterStats && !twitterStats.total">
                    No <i class="symbol-twitter"></i>Twitter posts
                </div>
                <div v-if="facebookStats && !facebookStats.total">
                    No <i class="symbol-facebook-rect"></i>Facebook posts
                </div>
                <div v-if="instagramStats && !instagramStats.total">
                    No <i class="symbol-instagram"></i>Instagram posts
                </div>
                <div v-if="otherNetworkStats && !otherNetworkStats.total">
                    No <i class="symbol-website"></i>Other posts
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import VolumeOverviewChartBar from "@/app/toplevel/explore/overview/components/VolumeOverviewChartBar";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import {formatPlural} from "@/app/utils/Format";
import {appendFiltersReadably} from "@/dashboards/filter/FilterParser";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
    components: {DeqNumber, VolumeOverviewChartBar},
    computed: {
        ...mapState('explorePanel', ['brand']),
        ...mapGetters('explorePanel', [
            'overallCount',
            'twitterStats',
            'facebookStats',
            'instagramStats',
            'otherNetworkStats',
            'dateFilter'
        ]),

        sortedStats() {
            const sorted = [];
            if (this.twitterStats?.total) sorted.push({...this.twitterStats, name: "Twitter", filter: "socialnetwork is TWITTER", icon: 'symbol-twitter', colour: 'var(--colour-twitter)'});
            if (this.facebookStats?.total) sorted.push({...this.facebookStats, name: "Facebook", filter: "socialnetwork is FACEBOOK", icon: 'symbol-facebook-rect', colour: 'var(--colour-facebook)'});
            if (this.instagramStats?.total) sorted.push({...this.instagramStats, name: "Instagram", filter: "socialnetwork is INSTAGRAM", icon: 'symbol-instagram', colour: 'var(--colour-instagram)'});
            sorted.sort((lhs, rhs) => rhs.total - lhs.total);

            // 'Other' is always last.
            if (this.otherNetworkStats?.total) sorted.push({...this.otherNetworkStats, name: "other sources", filter: "socialnetwork isnt TWITTER and socialnetwork isnt FACEBOOK and socialnetwork isnt INSTAGRAM", icon: 'symbol-website'});
            return sorted;
        },

        filter() {
            return appendFiltersReadably(`brand isorchildof ${this.brand.id} and relevancy isnt irrelevant`, this.dateFilter);
        }
    },

    methods: {
        formatPlural,

        showPrivate(name, socialNetwork) {
            let filter = appendFiltersReadably(this.filter, "visibility is direct_message");
            filter = appendFiltersReadably(filter, socialNetwork);
            showMentions(
                filter,
                `Private mentions from ${name}`
            )
        },

        showPublic(name, socialNetwork) {
            let filter = appendFiltersReadably(this.filter, "visibility is public");
            filter = appendFiltersReadably(filter, socialNetwork);
            showMentions(
                filter,
                `Public mentions from ${name}`
            )
        }
    }
}
</script>


<style scoped lang="sass">

.overview-stats
    position: relative
    height: 100%

    header
        height: 50px
        grid-column: 1 / span 2
        padding: 5px 0 20px
        box-sizing: border-box
        width: 100%
        h4
            margin: 0
            padding: 0


.overview-stats__overall
    position: absolute
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: auto
    grid-column-gap: 5px
    inset: 0
    box-sizing: border-box
    border: thin solid grey
    background: rgba(100, 100, 100, 0.5)
    padding: 5px


.overview-stats__twitter
    position: relative
    box-sizing: border-box
    border: thin solid var(--colour-twitter)
    background: var(--colour-twitter)
    color: white
    height: 2em

.overview-stats__private
    position: absolute
    right: 0
    width: 50%
    height: 100%
    background: grey
    box-sizing: border-box


.overview-stats__none
    margin-top: auto
    grid-column: 1 / span 2
    cursor: default
    color: var(--be-colour-muted-text-dark)
    font-style: italic
    font-size: 0.9em


</style>