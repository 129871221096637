<template>
    <div style="position: relative">
        <div ref="holder"></div>
        <div v-if="!noOpacity" style="width: 70px; position: absolute; right: 116px; top: 132px" class="opacity">
            <text-input v-model="opacity" type="text" label="Opacity %" :rules="opacityRules" numeric @valid="onValid"/>
        </div>
    </div>
</template>

<script>
    import TextInput from "../components/inputs/TextInput"

    /**
     * Edit colour settings e.g. for a metric.
     */
    export default {
        name: "ColourSettingsPicker",

        components: { TextInput },

        props: {
            value: Object,   // { colour-palette: ..., colour-palette-custom: ... }
            dashboardModel: Object,
            noOpacity: Boolean,
            useDefaultText: String,
            useDefaultTitle: String
        },

        data() {
            return {
                opacity: this.value ? this.value.opacity : null
            }
        },

        created() {
            this.opacityRules = [v => !v || v >= 0 && v <= 100 || "Invalid"]
        },

        mounted() {
            this.opacity = this.value ? this.value.opacity : null
            this.model = new Backbone.Model(this.value)
            this.model.on("change", this.modelChanged)
            this.view = new Beef.ColourSettings.View({
                model: this.model,
                dashboardModel: this.dashboardModel,
                useDefaultText: this.useDefaultText,
                useDefaultTitle: this.useDefaultTitle
            })
            this.view.render()
            this.$refs.holder.append(this.view.el)
        },

        beforeDestroy() {
            this.view.close()
        },

        watch: {
            value(v) {
                if (v) {
                    this.model.set(v)
                    this.opacity = v.opacity
                }
            },

            opacity(v) {
                this.modelChanged()
            }
        },

        methods: {
            modelChanged() {
                let attrs = {...this.model.attributes}
                attrs.opacity = this.opacity
                this.$emit('input', attrs)
            },

            onValid(v) {
                this.$emit('valid', v)
            }
        }
    }
</script>

<style scoped>
    .opacity ::v-deep .span12 {
        width: 60px;
    }

</style>