import List from "@editorjs/list";
import {
    sanitizer, openInlineToolbar, shouldDeleteNestedNode,
    shouldOpenInlineTool
} from "@/dashboards/widgets/commentaryplus/blockTools/Utils";

export class ListBlock extends List {

    constructor(data) {
        super(data);
        this._data.style = 'unordered';
    }

    static get toolbox() {
        return {
            icon: '<i class="icon-list-bullet"></i>',
            title: "Bulleted List"
        };
    }

    render() {
        this._elements.wrapper = super.render();
        if (!this.readOnly) {
            this._elements.wrapper.addEventListener('keyup', this.onKeyUp);
        }
        this._elements.wrapper.style.color = 'black';
        return this._elements.wrapper;
    }

    onKeyUp(t) {
        if (t.code === 'KeyC') {
            openInlineToolbar();
        }
    }

    static get sanitize() {
        return sanitizer;
    }

}

export class OrderedListBlock extends ListBlock {
    constructor(data) {
        super(data);
        this._data.style = 'ordered';
    }

    static get toolbox() {
        return {
            icon: '<i class="icon-list-numbered"></i>',
            title: "Numbered List"
        };
    }
}