var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog-title\">\n    <h5>Authorise Online Profiles</h5>\n    <a class=\"btn close\"><i class=\"icon-cancel-7\"></i></a>\n</div>\n\n<div class=\"dialog-body\">\n    <div class=\"info\">\n        Your account has online profiles that need to be reauthorised. You can <br>\n        reauthorise online profiles on the <a href=\"/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"code","hash":{},"data":data,"loc":{"start":{"line":9,"column":53},"end":{"line":9,"column":61}}}) : helper)))
    + "/setup/online\">online profiles page</a> of your account.\n    </div>\n    <div class=\"dialog-button-bar\">\n        <a class=\"close-token-expire btn\">Close</a>\n    </div>\n</div>\n";
},"useData":true});