<template>
    <dialog-box
        overlay
        class="segment-picker-dialog"
        width="60vw"
        @close="close()"
        v-if="showDialog"
        :title="'Choose Brand Segments'">

        <div class="segment-picker-dialog__container">
            <loading-message v-if="loading"
                             class="segment-picker-dialog__loader"
                             message="Fetching your segments"/>

            <div v-if="!loading">

                <p>Segments are a means of tagging your brands mentions in order to provide more detailed analysis. Only
                    segments checked with "In Account" and "Ask Crowd" will be used to tag future mentions.</p>

                <div class="segment-picker-dialog__segment-filters">
                    <search-input
                        class="search-bar"
                        autofocus
                        placeholder="Search for a segment"
                        v-if="!loading"
                        v-model="searchTerm"/>
                    <div class="segment-list-select-container">
                        <i class="icon-info-circle" :tooltip="segmentListTip"></i>
                        <drop-down-input :options="showOptions" v-model="show" style="width: 200px; padding-right: 20px"/>
                    </div>
                </div>

                <div class="segment-picker-dialog__table-container dark-scrollbars dark-scrollbars--visible" v-if="!loading">
                    <table class="table table-condensed table-bordered table-hover">
                        <thead>
                        <tr>
                            <th @click="sort('inAccount', 'inAccount')"
                                tooltip="Checked 'In Account' segments will appear on previous mentions they were tagged on">
                                In Account<i v-show="sortBy.column.inAccount"
                                             :class="sortBy.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"></i>
                            </th>
                            <th @click="sort('segmentName', 'subtitle')">Segment Name <i v-show="sortBy.column.segmentName"
                                                                                         :class="sortBy.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"></i>
                            </th>
                            <th @click="sort('segmentType', 'trimmedLabel')">Segment Type<i v-show="sortBy.column.segmentType"
                                                                                            :class="sortBy.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"></i>
                            </th>
                            <th @click="sort('askCrowd', 'askCrowd')"
                                tooltip="Checked 'Ask Crowd' segments will be tagged on future mentions by the crowd"> Ask
                                Crowd<i
                                    v-show="sortBy.column.askCrowd"
                                    :class="sortBy.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"></i>
                            </th>
                            <th @click="sort('description', 'visibleDescription')">Description<i
                                v-show="sortBy.column.description"
                                :class="sortBy.order === 'ASCENDING' ? 'icon-up' : 'icon-down'"></i>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="segment in filteredSegments" :key="segment.id">
                            <td class="center-content">
                                <input :data-id="segment.id" type="checkbox" @change="toggleInAccount(segment)"
                                       v-model="segment.inAccount">
                            </td>
                            <td class="segment-name">
                                {{ segment.subtitle }}
                            </td>
                            <td class="center-content">
                                {{ segment.trimmedLabel }}
                            </td>
                            <td class="center-content">
                                <input :data-id="segment.id" type="checkbox" :disabled="!segment.inAccount" @change="toggleAskCrowd(segment)"
                                       v-model="segment.askCrowd">
                            </td>
                            <td class="description-td">
                                {{ segment.visibleDescription }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <template v-slot:buttons>
            <be-button link class="segment-picker-dialog__bottom-button" @click="close">Cancel</be-button>
            <be-button :primary="true" class="segment-picker-dialog__bottom-button" :disabled="saving" @click="save">Save
                <spinner-component :size="11" class="segment-picker-dialog__save-spinner" v-show="saving"></spinner-component>
            </be-button>
        </template>

    </dialog-box>
</template>

<script>
import DialogBox from "@/components/DialogBox";
import LoadingMessage from "@/components/LoadingMessage";
import SearchInput from "@/components/inputs/SearchInput";
import {substituteTagParamaters} from "@/app/utils/Tags.js";
import SpinnerComponent from "@/components/SpinnerComponent";
import BeButton from "@/components/buttons/BeButton";
import {notifyUser, notifyWithText} from "@/app/framework/notifications/Notifications";
import {showWhenDialog} from "@/app/framework/dialogs/Dialog";
import Vue from "vue";
import DropDownInput from "@/components/inputs/DropDownInput";
import VuexStore from "@/store/vuex/VuexStore";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
    name: "SegmentPickerDialog",
    store: VuexStore,

    components: {SpinnerComponent, DropDownInput, BeButton, DialogBox, LoadingMessage, SearchInput},

    props: {
        activeBrandId: Number
    },

    data() {
        return {
            showDialog: true,
            loading: true,
            saving: false,
            allSegments: [],
            brandSegments: [],
            brandActiveSegmentListIds: [],
            brand: null,
            searchTerm: '',
            sortBy: {
                column: {
                    inAccount: true,
                    segmentName: false,
                    segmentType: false,
                    askCrowd: false,
                    description: false
                },
                order: "ASCENDING"
            },
            show: "all",
            segmentListTip: "Showing all segments",
            showOptions: [
               {
                    name: "All Segments",
                    id: "all"
                },
                {
                    name: "Active Segments",
                    id: "active"
                },
                {
                    name: "Inactive Segments",
                    id: "inactive"
                },
                {
                    name: "Segments In Account",
                    id: "inAccount"
                },
                {
                    name: "TCF Outcome Segments",
                    id: "tcfOutcomes"
                }
            ],

            // using Set for segmentListIds and activeSegmentListIds on saveObject and oldObject to make it
            // easier and more efficient for us to update them when the user toggles 'In Account' and/or 'Ask Crowd'

            // payload for updating
            saveObject: {
                id: Number,
                name: String,
                segmentListIds: Set,
                segmentLists: [],
                activeSegmentListIds: Set
            },

            // payload for undoing
            oldObject: {
                id: Number,
                name: String,
                segmentListIds: Set,
                segmentLists: [],
                activeSegmentListIds: Set
            }
        }
    },

    computed: {
        ...mapGetters(['idToBrand']),
        ...mapState('segments', ['availableSegments']),

        filteredSegments() {
            return this.allSegments.filter(segment => this.searchQuery(segment) && this.showQuery(segment));
        }
    },

    async created() {
        try {
            this.brand = this.idToBrand.get(this.activeBrandId);
            await this.refreshAvailableSegments();
            this.allSegments =  JSON.parse(JSON.stringify(Array.from(this.availableSegments)));
            this.brandSegments = this.brand.segmentLists ? this.brand.segmentLists : [];
            this.brandActiveSegmentListIds = this.brand.activeSegmentListIds ? this.brand.activeSegmentListIds : [];

            this.saveObject.id = this.brand.id;
            this.saveObject.name = this.brand.name;
            this.saveObject.segmentLists = []; // this array will be built by the save function
            this.saveObject.segmentListIds = this.brand.segmentListIds ? new Set(this.brand.segmentListIds) : new Set();
            this.saveObject.activeSegmentListIds = this.brand.activeSegmentListIds ? new Set(this.brand.activeSegmentListIds) : new Set();

            this.oldObject.id = this.brand.id;
            this.oldObject.name = this.brand.name;
            this.oldObject.segmentLists = this.brand.segmentLists ? this.brand.segmentLists : [];
            this.oldObject.segmentListIds = this.brand.segmentListIds ? new Set(this.brand.segmentListIds) : new Set();
            this.oldObject.activeSegmentListIds = this.brand.activeSegmentListIds ? new Set(this.brand.activeSegmentListIds) : new Set();

            this.initSegmentList();
        } catch (e) {
            console.error("Error occurred during creation of segment picker dialog", e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('segments', ['refreshAvailableSegments', 'updateSegmentsForBrand']),
        ...mapMutations(['setBrandSegments']),

        initSegmentList() {
            for (let i = 0; i < this.allSegments.length; i++) {
                let curSegment = this.allSegments[i];

                let segmentInAccount = this.brandSegments.some(inAccountSegment => inAccountSegment.id === curSegment.id);
                let askCrowd = this.brandActiveSegmentListIds.some(activeSegment => activeSegment === curSegment.id);

                // these new properties need to be reactive
                Vue.set(curSegment, 'inAccount', !!segmentInAccount);
                Vue.set(curSegment, 'askCrowd', !!askCrowd);

                curSegment.visibleDescription = this.getDescription(curSegment).trim();
                curSegment.trimmedLabel = curSegment.label.trim(); // important for sorting to work
            }

            // sort list by in account descending by default
            this.sort("inAccount", "inAccount");
        },

        getDescription(segment) {
            if (segment.clientDescription) {
                return substituteTagParamaters(segment.clientDescription, [this.brand]).trim();
            } else {
                return substituteTagParamaters(segment.description, [this.brand]);
            }
        },

        sort(column, field) {
            // swap sort order
            let newSortOrder = this.sortBy.order === "ASCENDING" ? "DESCENDING" : "ASCENDING";

            this.sortBy.order = newSortOrder;

            if (newSortOrder === "ASCENDING") {
                this.allSegments.sort((a, b) => a[field] > b[field] ? 1 : -1);
            } else {
                this.allSegments.sort((a, b) => a[field] < b[field] ? 1 : -1);
            }

            // disable sort icon for other columns
            this.sortBy.column[column] = true;
            for (const key in this.sortBy.column) {
                if (key !== column) {
                    this.sortBy.column[key] = false;
                }
            }
        },

        searchQuery(segment) {
            let st = this.searchTerm.toLowerCase();

            return (segment.trimmedLabel.toLowerCase().includes(st)
                || segment.subtitle.toLowerCase().includes(st)
                || segment.visibleDescription.toLowerCase().includes(st)) && !segment.global
        },

        showQuery(segment) {
            switch (this.show) {
                case "active":
                    this.segmentListTip = "Showing segments that are in account and crowd-asked";
                    return segment.inAccount && segment.askCrowd;
                case "inactive":
                    this.segmentListTip = "Showing segments that are in account and not crowd-asked";
                    return segment.inAccount && !segment.askCrowd;
                case "inAccount":
                    this.segmentListTip = "Showing segments that are in account";
                    return segment.inAccount;
                case "tcfOutcomes":
                    this.segmentListTip = "Showing TCF Outcomes segments";
                    return segment.trimmedLabel === "TCF Outcomes";
                default:
                    this.segmentListTip = "Showing all segments";
                    return true;
            }
        },

        toggleInAccount(segment) {
            // non TCF Outcomes segments are active by default when segment is added to account
            if (segment.inAccount && segment.label !== "TCF Outcomes") {
                segment.askCrowd = true;
            }

            // segment is active
            if (segment.inAccount && segment.askCrowd) {
                this.saveObject.activeSegmentListIds.add(segment.id);
                this.saveObject.segmentListIds.add(segment.id);
            }

            // segment is inactive
            if (segment.inAccount && !segment.askCrowd) {
                this.saveObject.activeSegmentListIds.delete(segment.id);
                this.saveObject.segmentListIds.add(segment.id);
            }

            // segment no longer in account => should not be part of save object
            if (!segment.inAccount) {
                segment.askCrowd = false;

                this.saveObject.activeSegmentListIds.delete(segment.id);
                this.saveObject.segmentListIds.delete(segment.id);
            }
        },

        toggleAskCrowd(segment) {
            // segment is active
            if (segment.inAccount && segment.askCrowd) {
                this.saveObject.activeSegmentListIds.add(segment.id);
                this.saveObject.segmentListIds.add(segment.id);
            }

            // segment is inactive
            if (segment.inAccount && !segment.askCrowd) {
                this.saveObject.activeSegmentListIds.delete(segment.id);
                this.saveObject.segmentListIds.add(segment.id);
            }

            // segment no longer in account => should not be part of save object
            if (!segment.inAccount) {
                segment.askCrowd = false;

                this.saveObject.activeSegmentListIds.delete(segment.id);
                this.saveObject.segmentListIds.delete(segment.id);
            }
        },

        async save() {
            let changes = this.changes();
            let error = false;

            try {
                this.saving = true;

                // only perform save if there are changes
                if (changes) {
                    for (let i = 0; i < this.allSegments.length; i++) {
                        let curSegment = this.allSegments[i];

                        let payloadSegment = {
                            active: curSegment.inAccount && curSegment.askCrowd,
                            children: curSegment.children,
                            clientDescription: curSegment.clientDescription,
                            description: curSegment.description,
                            global: curSegment.global,
                            id: curSegment.id,
                            label: curSegment.label,
                            segmentType: curSegment.segmentType,
                            selected: curSegment.inAccount,
                            subtitle: curSegment.subtitle
                        }

                        if (payloadSegment.selected) {
                            this.saveObject.segmentLists.push(payloadSegment);
                        }
                    }

                    // convert sets to array in order for payload in save to be valid
                    this.saveObject.segmentListIds = Array.from(this.saveObject.segmentListIds);
                    this.saveObject.activeSegmentListIds = Array.from(this.saveObject.activeSegmentListIds);

                    await this.updateSegmentsForBrand({brandId: this.brand.id, dto: this.saveObject});

                    //update brand in brand store
                    this.setBrandSegments({brandId: this.brand.id, segmentListIds: this.saveObject.segmentListIds,
                        segmentLists: this.saveObject.segmentLists, activeSegmentListIds: this.saveObject.activeSegmentListIds});

                    // update backbone model in order to display correct segments on brand setup page
                    this.$emit('update-brand-segments', this.saveObject);
                }
            } catch (e) {
                console.error("Error occurred while trying to update brand segments", e);
                error = true;
            } finally {
                this.saving = false;

                if (changes && !error) {
                    // We cannot destroye the dialog here because we need access to it in order to emit the event that updates the backbone model
                    // (this.$emit('update-brand-segments', this.oldObject)) on 'undo'
                    this.showDialog = false;

                    // undo button show
                    notifyUser({
                        message: this.brand.name + "'s segments have been updated.",
                        undo: async function () {
                            try {
                                // convert sets to array in order for payload in save to be valid
                                this.oldObject.segmentListIds = Array.from(this.oldObject.segmentListIds);
                                this.oldObject.activeSegmentListIds = Array.from(this.oldObject.activeSegmentListIds);

                                await this.updateSegmentsForBrand({brandId: this.brand.id, dto: this.oldObject});

                                //update brand in brand store
                                this.setBrandSegments({brandId: this.brand.id, segmentListIds: this.oldObject.segmentListIds,
                                    segmentLists: this.oldObject.segmentLists, activeSegmentListIds: this.oldObject.activeSegmentListIds});

                                // update backbone model in order to display correct segments on brand setup page
                                this.$emit('update-brand-segments', this.oldObject);

                                notifyWithText(`${this.brand.name}'s segment changes have been undone.`);
                            } catch (e) {
                                console.error("Error occurred while trying to undo brand segments changes", e);
                                notifyWithText(`Unable to undo ${this.brand.name}'s segment changes due to an error.`);
                            }
                        }.bind(this),
                        onClose: function () {
                            // destroy the dialog soon after the notify popup closes
                            setTimeout(() => this.$emit('close'), 2000);
                        }.bind(this)
                    });
                } else if (changes && error) {
                    notifyWithText(`Unable to save ${this.brand.name}'s segment changes due to an error.`);
                    this.$emit('close');
                } else {
                    // No changes were made -> nothing to undo, therefore we can destroy the dialog immediately
                    this.$emit('close');
                }
            }
        },

        changes() {
            if (this.saveObject.segmentListIds.size !== this.oldObject.segmentListIds.size) {
                return true;
            }

            if (this.saveObject.activeSegmentListIds.size !== this.oldObject.activeSegmentListIds.size) {
                return true;
            }

            for (let segment of this.saveObject.segmentListIds) {
                if (!this.oldObject.segmentListIds.has(segment)) {
                    return true;
                }
            }

            for (let segment of this.saveObject.activeSegmentListIds) {
                if (!this.oldObject.activeSegmentListIds.has(segment)) {
                    return true;
                }
            }
        },

        close() {
            if (this.changes()) {
                showWhenDialog(`You've made changes to ${this.brand.name}'s segments, are you sure you want to cancel them?`, "Cancel changes?")
                    .then(function () {
                        this.$emit('close');
                    }.bind(this));
            } else {
                this.$emit('close');
            }
        }
    }
}
</script>

<style scoped lang="sass">

.segment-picker-dialog

    &__container
        height: 55vh
        min-height: 400px

    &__loader
        margin-top: 2vh
        animation: none

    &__segment-filters
        margin: 15px 0
        display: flex
        align-items: center

        .search-bar
            width: 70%

        .segment-list-select-container
            margin-left: auto
            display: flex
            align-items: center

            i
                padding-right: 10px

    &__bottom-button
        display: inline-block
        padding: 20px 0 0 10px
        width: 80px

        .save-spinner
            padding-left: 5px

    &__table-container
        width: 100%
        height: 45vh
        min-height: 330px
        overflow-y: auto

        th
            text-align: center
            background: #666
            white-space: nowrap

        th:hover
            cursor: pointer

        td input[type="checkbox"]
            transform: scale(1.2)
            margin-bottom: 5px

        .center-content
            text-align: center
            vertical-align: middle

        .segment-name
            vertical-align: middle

        .description-td
            width: 38%

@media screen and (max-width: 1580px)
    .segment-picker-dialog
        ::v-deep .modal-container
            width: 75vw !important

        &__segment-filters

            .search-bar
                width: 65%

</style>