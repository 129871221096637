<template>
    <div v-once class="be-selector-menu-divider">
    </div>
</template>


<script>
export default {

}
</script>

<style scoped lang="sass">

.be-selector-menu-divider
    box-sizing: border-box
    padding: 2px
    background: var(--colour-background-black)
    border: 1px solid #111
    cursor: default
    margin: 0

</style>