import {grouse} from "@/store/Services";

async function getTreeMentions(accountCode, selectedMention) {
    if (selectedMention.isDirectMessage) {
        const filter = `ID IN ('${selectedMention.id}')`;
        const select = `id, replyToId, reshareOfId, visibility, authorName, toName, authorHandle, toHandle, authorHandleId, toHandleId, socialNetwork, published`;
        return await listMentions(accountCode, filter, select, null, 'published', null, true);
    } else {
        const filter = getPublicConversationFilter(selectedMention.conversationId);
        const select = `id, replyToId, reshareOfId`;
        return await listMentions(accountCode, filter, select, null, null, null, false);
    }
}

async function getMentionsById(accountCode, mentionIds) {
    const filter = `ID IN ('${mentionIds.join(`', '`)}')`;
    const select = `+combinedHtml,phrases,brand,discardedByRule,region,crowdJobs,reshareOfId,replyToId,interactionId`;
    return await listMentions(accountCode, filter, select, null, null, null, false);
}

async function getPrivateConversationStats(accountCode, conversationId, authorId, toId) {
    const filter = getPrivateConversationFilter(conversationId, authorId, toId);
    return await getConversationStats(accountCode, filter);
}

async function getPublicConversationStats(accountCode, conversationId) {
    const filter = getPublicConversationFilter(conversationId);
    return await getConversationStats(accountCode, filter);
}

async function getConversationStats(accountCode, filter, isDirectMessage) {
    const select = `mention,authorId,totalEngagement,reshareCount`;

    const promises = [];
    promises.push(countMentions(accountCode, filter, select));
    promises.push(listMentions(accountCode, filter, `published`, 1, `published desc`));
    promises.push(listMentions(accountCode, filter, `published`, 1, `published`));
    if (!isDirectMessage)
        promises.push(listMentions(accountCode, `${filter} AND reshareOf ISNT UNKNOWN`,
            `published`, 1, `published desc`));

    const [result, lastPublished, firstPublished, lastReshare] = await Promise.all(promises);

    // listMentions returns a list, hence this bit of jiggery-pokery to get the first item if it's defined
    // countMentions returns most of what we need, so just whack the additional stuff onto the same object as the result
    result.lastPublished = (lastPublished || [])[0]?.published;
    result.firstPublished = (firstPublished || [])[0]?.published;
    result.lastReshare = (lastReshare || [])[0]?.published;
    return result;
}

function getPrivateConversationFilter(conversationId, authorId, toId) {
    const brandId = getBrandId(conversationId);
    return `brand ISORCHILDOF ${brandId} AND visibility IS DIRECT_MESSAGE AND ` +
        `((authorHandleId IS '${authorId}' AND toHandleId IS '${toId}') OR ` +
        `(authorHandleId IS '${toId}' AND toHandleId IS '${authorId}') OR ` +
        `(authorHandle IS '${authorId}' AND toHandle IS '${toId}') OR ` +
        `(authorHandle IS '${toId}' AND toHandle IS '${authorId}'))`;
}

function getPublicConversationFilter(conversationId) {
    const brandId = getBrandId(conversationId);
    return `brand ISORCHILDOF ${brandId} AND conversationId in ('${conversationId}')`;
}

function getBrandId(conversationId) {
    return conversationId.substring(0, conversationId.indexOf('-'));
}

async function listMentions(accountCode, filter, select, limit, orderBy, offset, fetchGraph) {
    limit = limit ?? 50000;
    if (fetchGraph) limit = Math.min(limit, 100);
    let queryString = `filter=${encodeURIComponent(filter)}&select=${encodeURIComponent(select)}`;
    if (limit) queryString += `&limit=${encodeURIComponent(limit)}`;
    if (offset) queryString += `&offset=${encodeURIComponent(offset)}`;
    if (orderBy) queryString += `&orderBy=${encodeURIComponent(orderBy)}`;
    if (fetchGraph) queryString += `&fetchGraph=true`;
    queryString += `&includeDeleted=true`;
    const response = await grouse.post(
        `/v4/accounts/${accountCode}/mentions/list`, queryString);
    if (response.status === 200)
        return response.data;
    else
        throw new Error(`Could not list mentions for filter ${filter}`);
}

async function countMentions(accountCode, filter, select) {
    const queryString = `filter=${encodeURIComponent(filter)}&select=${encodeURIComponent(select)}`;
    const response = await grouse.post(`/v4/accounts/${accountCode}/mentions/count`, queryString);
    if (response.status === 200)
        return response.data;
    else
        throw new Error(`Could not count mentions for filter ${filter}`);
}

export {
    getMentionsById, getPrivateConversationStats, getPublicConversationStats,
    getPrivateConversationFilter, getPublicConversationFilter, getTreeMentions
};