Beef.module("Pager").addInitializer(function(startupOptions) {

    /**
     * Uses model { offset: .., limit: .., total: .. } to display a pager and figure out what the current page is.
     * Changes offset when pages are clicked. It can be created with a fixedTo view in which case it will fix itself
     * to the bottom of the page lined up with the view.
     */
    this.View = Backbone.Marionette.ItemView.extend({
        template: require("@/app/Pager.handlebars"),

        attributes: { class: "beef-pager" },

        events: {
            "click a": "click"
        },

        modelEvents: {
            "change": "render"
        },

        initialize: function(options) {
            this.fixedTo = options.fixedTo;
            this.fixedToWidthAdust = options.fixedToWidthAdust;
            if (!Number.isFinite(this.fixedToWidthAdust)) this.fixedToWidthAdust = 20;
        },

        templateHelpers: function() {
            var total = this.model.get('total');
            if (!Number.isFinite(total)) return {};

            var buttons = [];

            var offset = this.model.get('offset') || 0;
            var limit = this.model.get('limit') || 30;

            var groupLimit = limit * 10;
            var groupOffset = Math.floor(offset / groupLimit) * groupLimit;

            buttons.push({text: "prev", offset: offset - limit, cls: "prev", disabled: offset <= 0});
            if (groupOffset > 0) buttons.push({text: "...", offset: groupOffset - groupLimit});

            var last = groupOffset + groupLimit;
            if (last > total) last = total;
            for (var o = groupOffset; o < last; o += limit) {
                buttons.push({text: "" + (Math.floor(o / limit) + 1), offset: o,
                    active: offset >= o && offset < (o + limit)});
            }

            if (groupOffset + groupLimit < total) {
                var pagesLeft = Math.floor((total - (groupOffset + groupLimit)) / limit) + 1;
                buttons.push({text: "...", offset: groupOffset + groupLimit, title: pagesLeft + " more page(s)"});
            }
            buttons.push({text: "next", offset: offset + limit, cls: "next", disabled: offset + limit >= total});

            return { buttons: buttons, totalPages: Math.floor((total - 1) / limit) + 1 }
        },

        click: function(ev) {
            ev.preventDefault();
            var $t = $(ev.target);
            if ($t.hasClass("disabled") || this.model.get('fetching')) return;
            var o = parseInt($t.attr('data-offset'));
            if (this.model.get('offset') !== o) {
                this.model.set('offset', o);
            }
        },

        onRender: function() {
            if (this.fixedTo) {
                setTimeout(function(){
                    this.updatePosition();
                    this.$el.toggleClass("beef-pager-fixed", true);
                }.bind(this));
                if (!this.scrollHandler) {
                    this.scrollHandler = function () { this.updatePosition() }.bind(this);
                    $(window).on("scroll", this.scrollHandler);
                    $(window).on("resize", this.scrollHandler);
                }
            }
        },

        onClose: function() {
            if (this.scrollHandler) {
                $(window).off("scroll", this.scrollHandler);
                $(window).off("resize", this.scrollHandler);
                this.scrollHandler = null;
            }
        },

        updatePosition: function() {
            var $m = this.fixedTo.$el;

            var $w = $(window);
            var scrollBottom = $w.scrollTop() + $w.height();
            var scrollHeight = $(document.body)[0].offsetHeight + 20;  // scrollHeight different between firefox and chrome
            var diff = scrollHeight - scrollBottom;

            if (diff > 20) diff = 20;
            else if (diff < 0) diff = 0;

            this.$el.css({bottom: (20 - diff) + "px", left: $m.offset().left, width: $m.width() + 10 - this.fixedToWidthAdust});
        }
    });
});