import {capitalise, isString} from "@/app/utils/StringUtils";
import {createTagConverter} from "@/app/framework/pickers/picker-utils";

/**
 * A picker for choosing an integer value, or a range of integers.
 */
Beef.module("IntegerPicker").addInitializer(function(startupOptions) {

    this.View = Beef.Picker.View.extend({
        attributes: { class: "integer-picker" },

        template: require("@/app/IntegerPicker.handlebars"),

        attribute: 'The value',

        modelEvents: {
            "change": "change"
        },

        events: {
            "click input[name='operation']":    "clicked",
            "keyup .less-than-value":           "lessThanValueUpdated",
            "keyup .greater-than-value":        "greaterThanValueUpdated",
            "keyup .between-begin-value":       "betweenValueUpdated",
            "keyup .between-end-value":         "betweenValueUpdated",
            "focus .less-than-value":           "lessThanValueUpdated",
            "focus .greater-than-value":        "greaterThanValueUpdated",
            "focus .between-begin-value":       "betweenValueUpdated",
            "focus .between-end-value":         "betweenValueUpdated"
        },

        templateHelpers: function() {
            return {
                lowerCaseAttribute: this.attribute.toLowerCase(),
                defaultGreaterThan: this.options.defaultGreaterThan || "10",
                defaultLessThan: this.options.defaultLessThan || "10",
                defaultMin: this.options.defaultMin || "5",
                defaultMax: this.options.defaultMax || "10"
            }
        },

        clicked: function(ev) {
            var p = this.options.parser || defaultParser;
            var value = $(ev.target).val();
            switch (value) {
                case 'less-than':
                    value += '-' + p(this.$('.less-than-value').val());
                    break;
                case 'greater-than':
                    value += '-' + p(this.$('.greater-than-value').val());
                    break;
                case 'between':
                    value += '-' + p(this.$('.between-begin-value').val()) + '-' + p(this.$('.between-end-value').val());
            }
            this.setSelection([value]);
        },


        lessThanValueUpdated: function(ev) {
            this.$('.radio-less-than').click();
        },

        greaterThanValueUpdated: function(ev) {
            this.$('.radio-greater-than').click();
        },

        betweenValueUpdated: function(ev) {
            this.$('.radio-between').click();
        },

        change: function() {
            this.trigger('change', [this.model.get(this.attribute)]);
        },

        initialize: function() {
        },

        onFirstRender: function() {
            this.setupState();
        },

        onClose: function(ev) {
            if (this.popup) this.popup.close();
        },

        mousedown: function(ev) {
            if ($(ev.target).closest(".tag-input").length > 0) {
                Beef.Picker.ignoreNextBlur = true;  // stop our popup from closing when our inputs get focus
            } else {
                ev.preventDefault();                // this stops stuff on the picker from getting focus
                if (this.popup) this.popup.hide();  // hide language picker - it won't see the event to hide itself
            }
        },

        setSelection: function(value) {
            if (this.anyEmpty(value)) {
                this.model.clear({silent: true});
                this.model.set(this.attribute, null);
            } else if (!value || this.validate(value[0])) {
                this.model.clear({silent: true});
                this.previousValue = this.model.get(this.attribute);
                this.model.set(this.attribute, value);
            } else {
                this.setupState();
            }
        },

        isSpecial: function(code) {
            return false;
        },

        getSelection: function() {
            return Beef.Picker.View.prototype.getSelection.call(this);
        },

        keyup: function(ev) {
            // don't let escape go up and close whatever dialog we might be in .. just close our popup instead
            if (ev.keyCode == 27 && this.parentPopup) {
                ev.stopPropagation();
                this.restoreFocusOnClose = true;
                this.parentPopup.hide();
            }
        },

        //-------------------------------------------

        validate: function(item) {
            var bad = false;
            if (item.indexOf('less-than') == 0) {
                if (Beef.IntegerPicker.getLessThanValue(item) == null) bad = true;
            } else if (item.indexOf('greater-than') == 0) {
                if (Beef.IntegerPicker.getGreaterThanValue(item) == null) bad = true;
            } else if (item.indexOf('between') == 0) {
                var betweenValues = Beef.IntegerPicker.getBetweenValues(item);
                if (betweenValues.start == null || betweenValues.end == null) bad = true;
            }

            var $error = this.$('.error-message');
            if (bad) {
                $error.html(this.options.hint || "The value must be a positive whole number");
                $error.show();
                return false;
            }
            $error.hide();
            return true;
        },

        anyEmpty: function(value) {
            if (value && !isString(value)) value = value[0];
            if (!value) return false;

            if (value.indexOf('less') == 0) {
                return Beef.IntegerPicker.getLessThanValue(value) == null;
            }
            if (value.indexOf('greater') == 0) {
                return Beef.IntegerPicker.getGreaterThanValue(value) == null;
            }
            if (value.indexOf('between') == 0) {
                var between = Beef.IntegerPicker.getBetweenValues(value);
                return between.start == null || between.end == null;
            }
            return false;
        },

        setupState: function() {
            var v = this.model.get(this.attribute);
            if (Array.isArray(v)) v = v[0];
            if (v) {
                var f = this.options.formatter || defaultFormatter;
                if (v.indexOf('greater') == 0) {
                    this.$('.greater-than-value').val(f(Beef.IntegerPicker.getGreaterThanValue(v, this.options)));
                    this.$('.radio-greater-than').prop('checked', true);
                }
                if (v.indexOf('less') == 0) {
                    this.$('.less-than-value').val(f(Beef.IntegerPicker.getLessThanValue(v, this.options)));
                    this.$('.radio-less-than').prop('checked', true);
                }
                if (v.indexOf('between') == 0) {
                    var values = Beef.IntegerPicker.getBetweenValues(v, this.options);
                    this.$('.between-begin-value').val(f(values.start));
                    this.$('.between-end-value').val(f(values.end));
                    this.$('.radio-between').prop('checked', true);
                }
            }
        }

    });

    //----------------------------------

    this.getLessThanValue = function(item, options) {
        var p = options && options.parser || defaultParser;
        return item ? p(item.substring(10)) : null;
    };

    this.getGreaterThanValue = function(item, options) {
        var p = options && options.parser || defaultParser;
        return item ? p(item.substring(13)) : null;
    };

    this.getBetweenValues = function(item, options) {
        if (!item) return {start: null, end: null};
        var p = options && options.parser || defaultParser;
        var first = item.indexOf('-') + 1;
        var last = item.lastIndexOf('-') + 1;
        return {
            start:  p(item.substring(first, last - 1)),
            end:    p(item.substring(last))
        }
    };

    this.attachPicker = function(view, selector, attribute, pickerClass, options) {
        options = Object.assign({
            splitter: createSplitter(view.attribute, options),
            forceTagRemove: true
        }, options || {});

        Beef.Picker.attachPicker(view, selector, attribute, pickerClass, options);
    };

    this.createConverter = function(placeholder, attribute, options) {
        if (!attribute) attribute = capitalise(placeholder);
        return createTagConverter({
            items: function(code) { return code; },
            splitter: createSplitter(attribute, options),
            placeholder: placeholder
        });
    };

    var defaultFormatter = function(v) { return "" + v };

    var defaultParser = function(v) {
        var n = parseInt(v);
        return Number.isNaN(n) ? null : n;
    };

    var createSplitter = function(attribute, options) {
        options = options || {};
        var f = options.formatter || defaultFormatter;
        return function(item) {
            var value = attribute;
            if (item.indexOf('less-than') == 0) {
                value += ' is less than ' + f(Beef.IntegerPicker.getLessThanValue(item, options));
            }
            if (item.indexOf('greater-than') == 0) {
                value += ' is greater than ' + f(Beef.IntegerPicker.getGreaterThanValue(item, options));
            }
            if (item.indexOf('between') == 0) {
                var betweenValues = Beef.IntegerPicker.getBetweenValues(item, options);
                value += ' is between ' + f(betweenValues.start) + " and " + f(betweenValues.end);
            }
            return [value];
        }
    };
});
