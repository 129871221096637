<template>
    <div class="metric-tooltip beef-tooltip">
        <section class="metric-tooltip__text">
            <h4>{{metric.title}}</h4>
            <p>
                {{metric.description}}
            </p>
        </section>
        <section v-if="metric.image" class="metric-tooltip__image">
            <img :src="metric.image">
        </section>
    </div>
</template>

<script>
    export default {
        name: "MetricTooltip",

        props: {
            metric: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped lang="sass">
    .metric-tooltip
        display: flex

    img
        height: 100%
        width: 100%
        max-height: 150px
        max-width: 200px
        border-radius: 3px
</style>