var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row-fluid\">\n        <label>"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onlyOne") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":115}}})) != null ? stack1 : "")
    + "</label>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Set credibility to";
},"4":function(container,depth0,helpers,partials,data) {
    return "Include conversation where the source credibility is";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"_options") : depth0)) != null ? lookupProperty(stack1,"noIntroduction") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"row-fluid\">\n<span class=\"span4\">\n<table>\n    <tbody>\n    <tr>\n        <td><input type=\"checkbox\" name=\"0\">0</td>\n        <td><span class=\"help-inline\">Low</span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"1\">1</td>\n        <td><span class=\"help-inline\"></span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"2\">2</td>\n        <td><span class=\"help-inline\"></span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"3\">3</td>\n        <td><span class=\"help-inline\"></span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"4\">4</td>\n        <td><span class=\"help-inline\"></span></td>\n    </tr>\n    <tr>\n        <td colspan=\"3\"><input type=\"checkbox\" name=\"UNKNOWN\">Unknown</td>\n    </tr>\n    </tbody>\n</table>\n</span>\n\n<span class=\"span8\">\n<table>\n    <tbody>\n    <tr>\n        <td><input type=\"checkbox\" name=\"5\">5</td>\n        <td><span class=\"help-inline\">Respected</span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"6\">6</td>\n        <td><span class=\"help-inline\"></span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"7\">7</td>\n        <td><span class=\"help-inline\"></span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"8\">8</td>\n        <td><span class=\"help-inline\"></span></td>\n    </tr>\n    <tr>\n        <td><input type=\"checkbox\" name=\"9\">9</td>\n        <td><span class=\"help-inline\">Authoritative</span></td>\n    </tr>\n    </tbody>\n</table>\n</span>\n</div>\n";
},"useData":true});