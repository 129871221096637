import {createTagConverter} from "@/app/framework/pickers/picker-utils";

Beef.module("InteractionHasResponsePicker").addInitializer(function(startupOptions) {

    this.View = Beef.Picker.View.extend({

        template: require("@/dashboards/filter/pickers/interaction/InteractionHasResponsePicker.handlebars"),

        attributes: { class: "reshare-of-reply-to-picker" },

        initialize: function() {
            let view = this.options.view;
            this.model.accountCode = view.model.getAncestorProperty('accountCode');
            this.cache = view.cache = view.cache || {};
            // share cache with owning view so items are cached across invocations
        },

        change: function() {
            Beef.Picker.View.prototype.change.apply(this, arguments);
        },

        onClose: function(ev) {
            if (this.popup) this.popup.close();
        },

        mousedown: function(ev) {
            if ($(ev.target).closest(".tag-input").length > 0) {
                Beef.Picker.ignoreNextBlur = true; // stop our popup from closing when our inputs get focus
            } else {
                ev.preventDefault();    // this stops stuff on the picker from getting focus
                if (this.popup) this.popup.hide();  // hide language picker - it won't see the event to hide itself
            }
        },

        setSelection: function(value) {
            let op = "";

            if (value) {
                if (value === "true" || value === "false") {
                    op = value;
                }
            } else {
                op = "NA";
            }

            this.model.set({op: op});
        },

        getSelection: function() {
            let op = this.model.get('op');
            let sel = [];
            if (op === "true" || op === "false") {
                sel.push(op);
            }

            return sel;
        },

        isSpecial: function(code) {
            return false;
        },

        keyup: function(ev) {
            // don't let escape go up and close whatever dialog we might be in .. just close our popup instead
            if (ev.keyCode === 27 && this.parentPopup) {
                ev.stopPropagation();
                this.restoreFocusOnClose = true;
                this.parentPopup.hide();
            }
        }
    });

    this.converter =  createTagConverter({
        items: function(code) {
            if (code === "true") return "Interaction has a response"
            if (code === "false") return "Interaction does not have a response"
            return code;
        },
        placeholder: "Interaction has response",
        placeholderPersistent: function(v) { return v && v !== "true" && v !== "false" },
        noNeg: true,
        cls: "tag"
    });

    /**
     * Attach a reply to picker to a view attribute identified by selector. Updates attribute in the view's model.
     */
    this.attach = function(view, selector, attribute) {
        Beef.Picker.attachPicker(view, selector, attribute, this.View);
    };

});
