/**
 * Utilities for dealing with an accounts brands.
 */

import {
    union as setUnion,
    intersection as setIntersection,
    difference as setDifference
} from "./Sets";
import VuexStore from "@/store/vuex/VuexStore";


/**
 * Returns a possibly empty array of the root brands in the current account.
 * This is a light wrapper around the vuex store. The brands need to have been
 * initialised for this to work.
 *
 * @return {[Brand]}
 */
export function getRootBrands() {
    return VuexStore.state.rootBrands || [];
}

/**
 * Gets a brand for the current account, identified by brand ID.
 * This is a light wrapper around the vuex store, and the brands need
 * to have been initialised for this to work.
 *
 * @param {string|number} brandId - The brand to look up. Can be a string. Can also have a negative value.
 * @return {Brand|undefined}
 */
export function getBrand(brandId) {
    if (!brandId) return null;

    brandId = Math.abs(parseInt(brandId));
    // noinspection JSValidateTypes
    return VuexStore.getters.idToBrand.get(brandId);
}

/**
 * Gets the root brand of the given brand for the current account.
 * This is a light wrapper around the vuex store, and the brands need
 * to have been initialised for this to work.
 *
 * @param {string|number} brandId - The brand to look up. Can be a string. Can also have a negative value.
 * @return {Brand|undefined}
 */
export function getRootBrandFor(brandId) {
    let brand = getBrand(brandId);
    while (brand.parent) {
        brand = brand.parent;
    }

    return brand;
}


export function union(lhs, rhs) {
    return setUnion(lhs, rhs, getBrand);
}

export function intersection(lhs, rhs) {
    return setIntersection(lhs, rhs, getBrand);
}

export function difference(lhs, rhs) {
    return setDifference(lhs, rhs, getBrand);
}