import {CustomInlineTool} from "@/dashboards/widgets/commentaryplus/inlineTools/CustomInlineTool";
import {etcBtnOptions} from "@/dashboards/widgets/commentaryplus/inlineTools/EtcInlineTool";

export class IconsInlineTool extends CustomInlineTool {

    renderActions() {
        this.commandOptions = document.createElement("div");
        this.commandOptions.classList.add("commentary-inline-actions");

        this.getBtnOptions().forEach((option) => {
            let addCommandBtn = document.createElement("button");
            if (option.innerHtml){
                addCommandBtn.innerHTML = option.innerHtml;
            } else {
                addCommandBtn.innerText = option.text;
            }
            addCommandBtn.type = "button";
            addCommandBtn.classList.add('commentary-inline-action-btns');

            addCommandBtn.addEventListener("click", () => {
                this.range.deleteContents();

                this.range.insertNode(document.createTextNode("\u200b"));
                const container = document.createElement('span');
                container.contentEditable = 'false';
                container.innerHTML = "<span>" + option.icon + "</span>";
                this.range.insertNode(container);

                this.isActionsOpened = false;

                this.checkState();
            });
            this.commandOptions.appendChild(addCommandBtn);
        });

        return this.commandOptions;
    }

    getBtnOptions() {
        return iconsBtnOptions;
    }

    getBtnName() {
        return "Icons";
    }

    toolTipText() {
        return "Add an icon";
    }

}

export const iconsBtnOptions = [
    {
        text: "X logo",
        command: "{{X}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/x.svg' title='Twitter' " + "> <span>X</span>",
        icon: "<img style='height: 1.2em; margin: 2px;' src='/static/img/networks/x.svg' title='Twitter' " + ">"
    },
    {
        text: "Twitter logo",
        command: "{{twitter}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/twitter.png' title='Twitter' " + "> <span>Twitter</span>",
        icon: "<img style='height: 1.2em; margin: 2px;' src='/static/img/networks/twitter.png' title='Twitter' " + ">"
    },
    {
        text: "TikTok logo",
        command: "{{tiktok}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/tiktok.png' title='TikTok' " + "> <span>Tiktok</span>",
        icon: "<img style='height: 1.2em; margin: 2px;' src='/static/img/networks/tiktok.png' title='TikTok' " + ">"
    },
    {
        text: "Facebook logo",
        command: "{{facebook}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/facebook.png' title='Facebook' " + "> <span>Facebook</span>",
        icon: "<img style='height: 1.2em; margin: 2px;' src='/static/img/networks/facebook.png' title='Facebook' " + ">"
    },
    {
        text: "Instagram logo",
        command: "{{instagram}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/instagram.png' title='Instagram' " + "> <span>Instagram</span>",
        icon: "<img style='height: 1.2em; margin: 2px;'src='/static/img/networks/instagram.png' title='Instagram' " + ">"
    },
    {
        text: "YouTube logo",
        command: "{{youtube}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/youtube.png' title='YouTube' " + "> <span>Youtube</span>",
        icon: "<img style='height: 1.2em; margin: 2px;' src='/static/img/networks/youtube.png' title='YouTube' " + ">"
    },
    {
        text: "LinkedIn logo",
        command: "{{linkedin}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/linkedin.png' title='LinkedIn' " + "> <span>LinkedIn</span>",
        icon: "<img style='height: 1.2em; margin: 2px;' src='/static/img/networks/linkedin.png' title='LinkedIn' " + ">"
    },
    {
        text: "Trustpilot logo",
        command: "{{trustpilot}}",
        innerHtml: "<img class='inline-image' src='/static/img/networks/trustpilot.svg' title='Trustpilot' " + "> <span>Trustpilot</span>",
        icon: "<img style='height: 1.2em; margin: 2px;' src='/static/img/networks/trustpilot.svg' title='Trustpilot' " + ">"
    },
    {
        text: "Negative sentiment",
        command: "{{negative-icon}}",
        innerHtml: "<i class='icon-circle negative-sentiment'></i> <span>Negative</span>",
        icon: "<i class='icon-circle negative-sentiment'></i>"
    },
    {
        text: "Neutral sentiment",
        command: "{{neutral-icon}}",
        innerHtml: "<i class='icon-circle neutral-sentiment'></i> <span>Neutral</span>",
        icon: "<i class='icon-circle neutral-sentiment'></i>"
    },
    {
        text: "Positive sentiment",
        command: "{{positive-icon}}",
        innerHtml: "<i class='icon-circle positive-sentiment'></i> <span>Positive</span>",
        icon: "<i class='icon-circle positive-sentiment'></i>"
    },
    {
        text: "Risk",
        command: "{{risk}}",
        innerHtml: "<be-rpcs-icon full code='RISK'></be-rpcs-icon>",
        icon: "<be-rpcs-icon full code='RISK'></be-rpcs-icon>"
    },
    {
        text: "Purchase",
        command: "{{purchase}}",
        innerHtml: "<be-rpcs-icon full code='PURCHASE'></be-rpcs-icon>",
        icon: "<be-rpcs-icon full code='PURCHASE'></be-rpcs-icon>"
    },
    {
        text: "Cancel",
        command: "{{cancel}}",
        innerHtml: "<be-rpcs-icon full code='CANCEL'></be-rpcs-icon>",
        icon: "<be-rpcs-icon full code='CANCEL'></be-rpcs-icon>"
    },
    {
        text: "Service",
        command: "{{service}}",
        innerHtml: "<be-rpcs-icon full code='SERVICE'></be-rpcs-icon>",
        icon: "<be-rpcs-icon full code='SERVICE'></be-rpcs-icon>"
    },
];