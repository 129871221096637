import {MentionQLexer} from '../mentionq/mentionq'
import {getAllSegmentLists, getAllTopicTrees} from "../app/utils/Segments";
import {deprecatedBrandsStore as brandStore} from "../store/deprecated/Stores"
import {getBrandsInFilter, parseFilterString, removeNodes} from "@/dashboards/filter/FilterParser";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import {isString, splitAtSpaces} from "@/app/utils/StringUtils";

Beef.module("SendToCrowd").addInitializer(function(startupOptions) {

    let segmentPicker = null;
    let topicTreePicker = null;

    this.showInPopup = async function(accountCode, sel, parent) {
        if (!sel.total) {
            window.alert("Please select some mentions to send");
            return;
        }

        const segments = await getAllSegmentLists();
        let segmentPicker_items = {};
        segments.forEach(function(s) {
            if (s.global) return;
            segmentPicker_items[s.id] = {
                name: `${s.name} » ${s.subtitle } (${s.id})`,
                description: s.description
            };
        });
        segmentPicker = Beef.DynamicPicker.create(segmentPicker_items, {placeholder: "Segment", alwaysOne: false})

        const topicTrees = await getAllTopicTrees()
        let topicTreePicker_items = {}
        topicTrees.forEach(function(s) {
            topicTreePicker_items[s.id] = {
                name: `${s.name} (${s.id})`,
                description: s.description
            };
        });
        topicTreePicker = Beef.DynamicPicker.create(topicTreePicker_items, {placeholder: "Topic tree", alwaysOne: false})

        var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"] });
        popup.setTarget($('body'));

        var m = new Backbone.Model({
            code: accountCode,
            sel: sel,
            crowdPriority: 'RETROSEND',
            allBrands: true,
            doNotCreateDerivedJobs: false,
            reprocess: false,
            mentionIds: ''
        });
        var view = new View({model: m, parent: parent});
        view.on("close", function(){ popup.hide(); });
        popup.show(view);
    };

    var spinner = "<span class='spinner'/>";


    var View = Beef.BoundItemView.extend({

        template: require("@/mentions/SendToCrowd.handlebars"),

        attributes: { class: "send-to-crowd dialog" },

        bindings: function() {
            return {
                crowdregion: { converterFactory: Beef.CrowdRegionPicker.converterFactory, elAttribute: "data-value" },
                jobtypes: { converter: Beef.CrowdJobTypePicker.converter, elAttribute: "data-value" },
                segment: { converter: segmentPicker.converter, elAttribute: "data-value" },
                topicTree: { converter: topicTreePicker.converter, elAttribute: "data-value" }
            };
        },

        templateHelpers: function() {
            let sel = this.model.get('sel')
            var subset = Beef.EditSelectedMentions.getSubsetBrands(this.options.parent.model, sel.filter);
            var brands = "";
            let brandIds
            if (subset) {
                for (var i = 0; i < subset.length; i++) {
                    if (i > 0) {
                        if (i == subset.length - 1) brands += " and ";
                        else brands += ", ";
                    }
                    brands += subset[i].name;
                }
                brandIds = subset.map(b => b.id)
            } else {
                let ids = sel.ids
                if (isString(ids)) ids = ids.split(",");
                if (ids && ids.length) brandIds = [parseInt(ids[0].split('-')[0])]
                else brandIds = getBrandsInFilter(sel.filter).include
            }

            let essentials = []
            brandIds.forEach(id => {
                let b = brandStore.get(id)
                if (b.tier === "ESSENTIALS") essentials.push(b)
            })
            let essentialsWarning = ''
            if (essentials.length) {
                essentials.forEach((b,i) => {
                    if (!essentialsWarning) essentialsWarning = "Brand" + (essentials.length == 1 ? " " : "s ")
                    else if (i === essentials.length - 2) essentialsWarning += " and "
                    else if (i < essentials.length - 2) essentialsWarning += ", "
                    essentialsWarning += (b.shortName || b.name)
                })
                essentialsWarning += (essentials.length == 1 ? " is" : " are") + " on ESSENTIALS so mentions cannot " +
                    "get sentiment data!"
            }
            this._essentialsWarning = essentialsWarning

            return {
                // this can't be called 'brands' or the handlebars loader find 'Brands.js' and tries include that
                brands_: brands,
                essentialsWarning: essentialsWarning
            }
        },

        onFirstRender: function() {
            Beef.CrowdRegionPicker.attach(this, ".crowdregion", "crowdregion", {onlyOne: true});
            Beef.CrowdJobTypePicker.attach(this, ".jobtypes", "jobtypes");
            segmentPicker.attach(this, ".segment", "segment");
            topicTreePicker.attach(this, ".topicTree", "topicTree");
            this.crowdPriorityToView();
        },

        events: {
            "click .dialog-title .close":   "close",
            "click .cancel":                "close",
            "click .ok":                    "ok",
            "click .crowd-priority button": "crowdPriorityToModel",
            "mousedown": "mousedown"
        },

        modelEvents: {
            "change:_busy": "renderBusy",
            "change:crowdPriority": "crowdPriorityToView",
            "change:jobtypes": "updateEssentialsWarning",
        },

        renderBusy: function() {
            var busy = this.model.get('_busy');
            var $ok = this.$('.ok');
            $ok.toggleClass('disabled', busy);
            if (busy) $ok.html(spinner);
            else $ok.text('Send');
        },

        updateEssentialsWarning: function() {
            let jobTypes = this.model.get('jobtypes')
            this.$(".essentials-warning").toggle(!jobTypes)
        },

        close: function() {
            if (this.popup) this.popup.close();
            if (!this.$el[0].parentElement) return; // already closed
            Beef.BoundItemView.prototype.close.call(this);
        },

        ok: function(ev) {
            ev.preventDefault();
            if (this.model.get('_busy')) return;

            let jobTypes = this.model.get('jobtypes');
            if ((!jobTypes || jobTypes.indexOf("RATING") >= 0) && this._essentialsWarning) {
                return showErrorDialog("Mentions for brands on ESSENTIALS cannot be sent to the crowd for sentiment");
            }

            const reason = (this.$('.reason').val() || "").trim();
            if (!reason) return showErrorDialog("Please supply a reason and details concerning this retrosend");

            var that = this;
            var sel = this.model.get('sel');

            var ids = this.model.get('mentionIds')
            var idsByBrand
            if (ids) {
                let keep = []
                idsByBrand = { }
                for (let id of ids.split("\n")) {
                    if (!id) continue
                    if (!/[0-9]+-[0-9]+/.test(id)) return showErrorDialog("Invalid mention ID [" + id + "]")
                    let brandId = parseInt(id.substring(0, id.indexOf('-')))
                    if (brandStore.existsSynchronous(brandId)) {
                        let list = idsByBrand[brandId]
                        if (!list) idsByBrand[brandId] = list = []
                        list.push(id)
                        keep.push(id)
                    }
                }
                if (!keep.length) return showErrorDialog("No mention IDs match brands in this account")
                ids = keep
            } else {
                ids = sel.ids;
                if (isString(ids)) ids = ids.split(",");
            }

            this.model.set('_busy', true);
            var $progress = this.$('.progress-msg');
            $progress.text('');

            var url = "/api/crowd";

            var data = {code: this.model.get("code"), reason: reason};
            if (ids) data.ids = ids;

            if (jobTypes) data.jobTypes = splitAtSpaces(jobTypes);

            var crowdregion = this.model.get('crowdregion');
            if (crowdregion) data.crowdId = crowdregion;

            var crowdPriority = this.model.get('crowdPriority');
            if (crowdPriority) data.crowdPriority = crowdPriority;

            var segment = this.model.get("segment");
            if (segment) data.segmentId = parseInt(segment);

            var topicTree = this.model.get("topicTree");
            if (topicTree) data.topicTreeId = parseInt(topicTree);

            data.doNotCreateDerivedJobs = this.model.get('doNotCreateDerivedJobs')
            data.reprocess = this.model.get('reprocess')

            // there are never any brands for a V3 account
            var brands = this.model.get('allBrands')
                ? Beef.EditSelectedMentions.getSubsetBrands(this.options.parent.model, sel.filter)
                : null;
            var calls = [];
            if (brands) {
                var baseFilter = removeNodes(parseFilterString(sel.filter), function (node) {
                    return node.operandType === MentionQLexer.BRAND
                }).toString();
                brands.forEach(function (b) {
                    calls.push({
                        label: b.name,
                        url: url,
                        data: Object.assign({brand: b.id}, data,
                            {filter: baseFilter + " and brand isorchildof " + b.id})
                    });
                });
            } else if (idsByBrand) {
                for (let brandId of Object.keys(idsByBrand)) {
                    let b = brandStore.get(brandId)
                    let d = {...data}
                    d.brand = brandId
                    d.ids = idsByBrand[brandId]
                    calls.push({label: 'Sending ' + b.name, url: url, data: d});
                }
            } else {
                data.filter = sel.filter;
                let brand = null;
                if (sel.filter) {
                    let brands = getBrandsInFilter(sel.filter);
                    if (brands.include && brands.include.length === 1) {
                        brand = brands.include[0];
                    }
                } else if (data.ids && data.ids.length) {
                    brand = parseInt(data.ids[0].split('-')[0]);
                }
                calls.push({ label: 'Sending', url: url, data: Object.assign({brand: brand}, data) });
            }

            var tagDescr;
            if (idsByBrand) {
                tagDescr = ids.length  + " specific mention(s) sent to The Crowd"
            } else if (sel.ids.length > 0) {
                tagDescr = sel.total + " mention" + (sel.total == 1 ? "" : "s") + " sent to The Crowd"
            } else {
                tagDescr = "Mentions sent to the crowd matching " + sel.filter;
            }

            var pos = 0;
            var tagId;
            var nextCall = function() {
                if (pos >= calls.length) {
                    that.model.set('_busy', false);
                    return;
                }

                var call = calls[pos++];
                var $li = $("<li></li>");
                $li.text(call.label);
                $li.append(spinner);
                $progress.append($li);
                if (call.data && tagId) call.data.tagId = tagId;
                $.ajax({
                    url: call.url,// + "&tagId=" + tagId,
                    type: "POST",
                    contentType: "application/json",
                    dataType: 'json',
                    processData: false,
                    data: call.data ? JSON.stringify(call.data) : null,
                    timeout: 300 * 1000,
                    success: function(data) {
                        $li.text(call.label + ": " + data.message);
                        nextCall();
                    },
                    error: function(xhr, textStatus, msg) {
                        that.model.set('_busy', false);
                        $li.text(call.label + ": Error: " + msg);
                    },
                    complete: function() { that.model.set('_busy', false);  }
                });
            };

            var $li = $("<li>Creating tag " + spinner + "</li>");
            $progress.append($li);
            $.ajax({
                url: '/api/crowd/retrosend-tag',
                type: "POST",
                data: { code: this.model.get("code"), description: tagDescr },
                success: function(data) {
                    tagId = data.id;
                    $li.text("Tagging mentions with " + tagId + ": " + data.name);
                    nextCall();
                },
                error: function(xhr, textStatus, msg) {
                    that.model.set('_busy', false);
                    $progress.text("Error creating tag");
                }
            });
        },

        mousedown: function(ev) {
            Beef.Popup.closePopups(this);
        },

        crowdPriorityToView: function() {
            this.$el.find(".crowd-priority button").toggleClass("active", false);
            this.$el.find(".crowd-priority button[data-id='" + this.model.get('crowdPriority') + "']").toggleClass("active", true);
        },

        crowdPriorityToModel: function(ev) {
            var id = $(ev.target).attr("data-id");
            if (id) this.model.set("crowdPriority", id);
        }
    });
});