/**
 * Use this to navigate to the mentions panel using a particular filter. The filter will be url encoded automatically.
 */
import VuexStore from "@/store/vuex/VuexStore";

/**
 * Opens the mention panel.
 * @param {String, optional} filter
 * @param {String, optional} orderby
 * @param {Boolean, optional} [newTab = false]
 */
export function gotoMentionPanel(filter, orderby, newTab) {
    let uri = `/${VuexStore.state.account.code}/mentions`;

    const args = {};
    if (filter) args.filter = filter;
    if (orderby) args.orderby = orderby;

    if (Object.keys(args).length) {
        uri += "?" + Object.entries(args)
            .map(e => `${e[0]}=${encodeURIComponent(e[1])}`)
            .join("&");
    }
    
    if (newTab) window.open(uri);
    else Beef.router.navigate(uri, {trigger: true});
}