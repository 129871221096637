/**
 * A module for creating token-expiry warnings, by showing a popup.
 */
import moment from "moment";
import {deprecatedUserObject} from "@/store/deprecated/Stores";
import {DAY_MS, hasExpiredOrExpiresSoon} from "@/app/popup/token-expire/TokenUtilities";
import {isFunction} from "@/app/utils/Util";

Beef.module("TokenExpirePopup").addInitializer(function(startupOptions) {

    var code = startupOptions["account"]["code"],
        checkFrequencyMs = DAY_MS,
        displayFrequencyMs = 3 * DAY_MS,
        storageKey = "beef:tokens:expire:facebook:" + code,
        that = this;

    /**
     * @param dateString A date String, e.g. "2016-04-06T08:22:13.751+0000".
     * @return {boolean} True if the supplied date is before the current date. False if otherwise, or if the date
     *     String is invalid.
     */
    this.hasExpired = function(dateString) {
        var hasExpired = false;
        if (!dateString) return hasExpired;
        try {
            var expireDate = new moment(dateString);
            if (expireDate.isValid()) {
                var expireMs = expireDate.valueOf(),
                    nowMs = new Date().getTime();
                hasExpired = expireMs <= nowMs;
            }
        } catch (e) {
            console.error("Could not check whether expire date has been reached:", e.message);
        }
        return hasExpired;
    };

    /**
     * @return {*} JSON stored by this popup in localStorage. If no data is found or the data is malformed an
     *     empty JSON is returned.
     */
    var getPopupStorage = function () {
        var storage;
        try {
            storage = JSON.parse(localStorage.getItem(storageKey)) || {};
        } catch (e) {
            storage = {};
            console.error("Could not load token-expire popup history from local storage:", e.message);
        }
        return storage;
    };

    /**
     * @param data A response from the REST online-profile endpoint of Mash.
     * @return {Array} An array of online profiles whose tokens will soon expire or have expired recently.
     *     Returns an empty array if none are found.
     */
    function findExpiredProfiles(data) {
        // a profile will only reveal a token's expiry date if the logged-in user is allowed access to the token
        var profiles = [];
        try {
            for (var i = 0; i < data.length; ++i) {
                if (data[i] && hasExpiredOrExpiresSoon(data[i]["tokenExpire"])) {
                    profiles.push(data[i]);
                }
            }
        } catch (e) {
            console.error("Unable to parse online-profile response:", e.message);
            profiles = [];
        }
        return profiles;
    }

    /**
     * Fetches online profiles from Mash and shows a popup if there are any recently expired or soon-to-expire tokens.
     * @param model A Backbone model.
     * @param showImpl A function that creates a new popup.
     */
    var fetchProfiles  = function(model, showImpl) {
        var endpoint = "accounts/" + code + "/online-profiles",
            params = null;

        var success = function(data) {
            if (!isFunction(showImpl)) return;
            var profiles = findExpiredProfiles(data);
            if (profiles.length > 0) showImpl(model);
        };

        var error = function() {
            console.error("Could not fetch online profiles.");
        };

        Beef.Sync.mashGET(endpoint, params, success, error);
    };

    /**
     * Tries to show a popup if it has not been shown recently, and only if the account has expired tokens.
     * @return {boolean | undefined} True if the popup was shown. Otherwise, false if not shown or an error occurred.
     *     Returns undefined if the shown status cannot be determined immediately (a call to Mash had to be made before
     *     deciding whether to show the popup).
     */
    this.showIfNotAlreadySeen = function() {
        var shown = false;
        try {
            var storage = getPopupStorage(),
                lastChecked = parseInt(storage.lastChecked),
                nowMs = new Date().getTime();
            if (lastChecked && ((nowMs - lastChecked) < checkFrequencyMs)) {
                shown = false;
            } else {
                // depending on the whether there are expired tokens or not, the popup might not be shown
                storage.lastChecked = new Date().getTime();
                localStorage.setItem(storageKey, JSON.stringify(storage));
                shown = Beef.TokenExpirePopup.show();
            }
        } catch (e) {
            console.error(e.message);
            shown = false;
        }
        return shown;
    };

    /**
     * Shows a token expire popup if the account has expired tokens.
     * @return {boolean | undefined} True if the popup was shown. Otherwise, false if not shown or an error occurred.
     *     Returns undefined if the shown status cannot be determined immediately (a call to Mash had to be made before
     *     deciding whether to show the popup).
     */
    this.show = function() {

        if (!code) {
            console.error("Not showing token-expire popup: no account code.");
            return false;
        }

        var showImpl = function(model) {
            if (!deprecatedUserObject.admin) {
                var storage =  getPopupStorage(),
                    lastDisplayed = parseInt(storage.lastDisplayed),
                    nowMs = new Date().getTime();
                if (!lastDisplayed || ((nowMs - lastDisplayed) > displayFrequencyMs)) {
                    storage.lastDisplayed = new Date().getTime();
                    localStorage.setItem(storageKey, JSON.stringify(storage));
                    var popup = new Beef.Popup.View({ closeOnHide: true, positions: ["center"]});
                    popup.setTarget($("#content"));
                    popup.show(new View({ model: model }));
                }
            }
        };

        var model = new Backbone.Model({});
        fetchProfiles(model, showImpl);
    };

    var View = Backbone.Marionette.ItemView.extend({
        template: require("@/app/popup/token-expire/TokenExpirePopup.handlebars"),

        attributes: { class: "token-expire-popup dialog" },

        events: {
            "click .ok": "ok",
            "click .close-token-expire": "cancel"
        },

        templateHelpers: function() {
            return {
                code: code
            };
        },

        ok: function() {
            this.parentPopup.hide();
        },

        cancel: function() {
            this.parentPopup.hide();
        },

        showSpinner: function() {
            this.$(".dialog-body .info").toggleClass("busy", true);
        },

        hideSpinner: function() {
            this.$(".dialog-body .info").toggleClass("busy", false);
        },

        fetch: function() {
            this.showSpinner();
            fetchProfiles(this.model, this.hideSpinner);
        }
    });
});
