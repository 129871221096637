<template>
    <div class="saving-item">
        <div v-if="!saveComplete">
            <div class="saving-item__error-message" v-if="saveError">
                <i class="symbol-warning"></i>
                <h2>
                    {{ saveErrorMessage }}
                </h2>
            </div>
            <div class="saving-item__status-message-container" v-else>
                <h2>{{ savingMessage }}</h2>
                <spinner-component :size="16"></spinner-component>
            </div>
        </div>
        <div v-else>
            <div  class="saving-item__status-message-container complete">
                <slot name="saveCompleteMessage">
                    <h2>{{ saveCompleteMessage }}</h2>
                </slot>
                <animated-check class="checkmark" :sizePx="50" :animated="saveComplete"></animated-check>
            </div>
            <slot name="additionalActions">

            </slot>
        </div>
    </div>
</template>

<script>
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import SpinnerComponent from "@/components/SpinnerComponent";

export default {
    name: "SavingItem",
    components: {SpinnerComponent, AnimatedCheck},

    props: {
        saveComplete: {
            required: true,
            type: Boolean
        },
        savingMessage: {
            type: String,
            default: "Saving...",
        },
        saveCompleteMessage: {
            type: String,
            default: "Save successful",
        },
        saveErrorMessage: {
            type: String,
            default: "An error occurred while saving. Please refresh the page and try again or contact DataEQ support",
        },
        saveError: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="sass">

.saving-item
    display: flex
    flex-direction: column

    &__status-message-container
        display: flex
        flex-direction: column
        align-items: center

        &.complete
            border-bottom: 1px solid #666

    &__error-message
        color: var(--be-colour-warning)
        text-align: center

        i
            font-size: 2em


    .checkmark
        left: 0 !important
        margin: 10px 0
</style>