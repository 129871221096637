<template>
    <section class="block-space-widget widget-height-inner">
        <div class="block-space-widget__message">
            This space left blank
        </div>
    </section>
</template>

<script>

if (module.hot) window.beWidgetReloaded('BlockSpaceWidget');

import './Spaces.css';

export default {
    name: "BlockSpaceWidget",
    widgetType: {
        height: 2,
        width: 2,
        class: 'widget-hidden'
    },

    data() {
        return {
        }
    }
}

</script>


