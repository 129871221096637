import {notifyUser} from "@/app/framework/notifications/Notifications";
import {getLastTipDateKey} from "@/app/help/tips/tips";

export const pages = {
    reports: "Reports",
    explore: "Explore",
    mentions: "Mentions",
    authors: "Authors",
    setup: "Setup"
};

export const themes = {
    addingMetrics: {
        name: "Adding Metrics",
        description: "Metrics are charts, visualisations and stats that can be added to the sections of your dashboard."
    },
    customisingDashboards: {
        name: "Customising Dashboards",
        description: "Dashboards are a collection of metrics that can be used to create detailed reports for your brand."
    },
    editingMetrics: {
        name: "Editing Metrics",
        description: "A metric's data and visuals are able to be adjusted."
    },
    filteringMentions: {
        name: "Filtering Mentions",
        description: "Filtering mentions allows you to customise exactly which mentions you would like to see."
    },
    notifications: {
        name: "Notifications & Emails",
        description: "Notifications allow you to easily keep up to date with important conversations about your brand."
    },
    exploringMentions: {
        name: "Exploring Mentions",
        description: "Gain more insight into your mentions."
    },
    onlineProfiles: {
        name: "Profiles",
        description: "Manage the profiles that you would like DataEQ to collect data from."
    },
    exploringIntroduction: {
        name: "About",
        description: "See an overview of your account."
    },
};

/** Useful for setting probabilities of tips being shown */
export const chances = {
    STANDARD_CHANCE: 0.25,
    LOW: 0.1,
    MEDIUM: 0.25,
    HIGH: 0.5,
    ALWAYS: 1.0
};


/**
 * Displays the give tip to the user. You likely don't
 * want to call this, but to call #showTip instead.
 */
export function displayTipNotification(tip) {
    setTimeout(() => {
            notifyUser({
                message: `<div class="be-notification-tip">${tip.text}</div>`,
                isEscapedHtml: true,
                icon: '<i class="symbol-tip tip-icon"></i>',
                action: tip.action,
                noDismissTimer: true
            })
        },
        1000);  // Don't show the tip too quickly
}

/**
 * Turn on testing support for tips on the console.
 */
export async function enableTipTesting() {
    const {default: tips} = await import('./tip-text');
    window.listTips = function() {
        console.log(Object.keys(tips));
    };
    window.showTip = function(tipId) {
        displayTipNotification(tips[tipId]);
    };
    window.clearLastTipTime = function() {
        localStorage.removeItem(getLastTipDateKey());
    };


    console.info("Tip testing:");
    console.info("\t• listTips(): list the available tips");
    console.info("\t• showTip(tipId): display the tip with the given ID");
    console.info("\t• clearLastTipTime(): Clear the last time that a tip was recorded as shown");
}

/**
 * Given an ID, this returns the glossary entry.
 * @param {string} id - The ID for a glossary term
 * @return {Promise<Tip|null>}
 */
export async function getGlossaryEntry(id) {
    const {default: tips} = await import('./tip-text');

    const term = tips[id];
    if (!term) throw new Error(`Glossary term ${id} not known`);
    if (!term.isGlossary) throw new Error(`Glossary term ${id} is not a glossary entry`);

    return tips[id];
}

/**
 * Returns a list of all known glossary entries.
 * @return {Promise<Tip[]>}
 */
export async function getAllGlossaryEntries() {
    const {default: tips} = await import('./tip-text');
    return Object
        .values(tips)
        .filter(t => t.isGlossary)
        .sort((lhs, rhs) => lhs.glossaryTerm.localeCompare(rhs.glossaryTerm));
}

/**
 * Returns a list of glossary entries for a particular section.
 * @param {'EXPLORE'} section - A section ID to return glossary entries for
 * @return {Promise<Tip[]>}
 */
export async function getGlossaryEntriesFor(section) {
    const glossary = await getAllGlossaryEntries();
    return glossary.filter(d => d.sections?.includes(section))
}